<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"destination_name" | customTranslate}}</label>
                  <input class="form-control" type="text" name="stageDestinationCode"
                    placeholder="Destination code name" [(ngModel)]="stageDestinationCode.stageDestinationCode">
                  <div class="error" *ngIf="codeNameValidator && codeNameValidator.error">
                    <span>{{codeNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="sel1" class="required">{{"destination_type" | customTranslate}}</label>
                  <select class="form-control" id="sel1" name="destinationType"
                    [(ngModel)]="stageDestinationCode.destinationType">
                    <option value="" disabled selected>Destination Type</option>
                    <option *ngFor="let dt of destinationTypeList; let i = index"
                      [value]="dt.stageDestinationCodeTypeId">
                      {{dt.stageDestinationCodeTypeName}}
                    </option>
                  </select>
                  <div class="error" *ngIf="destinationTypeValidator && destinationTypeValidator.error">
                    <span>{{destinationTypeValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{("description" | customTranslate)}}</label>
                  <input class="form-control" type="text" name="stageDestinationCodeDesc"
                    [(ngModel)]="stageDestinationCode.stageDestinationCodeDesc" placeholder="Description">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{("max_vehicle_limit" | customTranslate)}}</label>
                  <input class="form-control" type="number" name="stageDestinationCodeVehicleLimit"
                    [(ngModel)]="stageDestinationCode.stageDestinationCodeVehicleLimit" placeholder="Vehicle Limit">
                </div>



                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required"> {{("status" | customTranslate)}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageDestinationCodeStatus"
                      [(ngModel)]="stageDestinationCode.stageDestinationCodeStatus" id="stage_status_1" value="ACTIVE">
                    <label for="exampleRadios1">
                      {{("active" | customTranslate)}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageDestinationCodeStatus"
                      [(ngModel)]="stageDestinationCode.stageDestinationCodeStatus" id="stage_status_2"
                      value="INACTIVE">
                    <label for="exampleRadios2">
                      {{("inactive" | customTranslate)}}
                    </label>
                  </div>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{("back" | customTranslate)}}
                  </button>
                  <button class="btn btn-warning" (click)="resetStageDestinationCode()"> {{("reset" | customTranslate)}}
                  </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"
                    *ngIf="userService.permissionMap.get('add_destination_access')==true"> {{buttonName |customTranslate}}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>



      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"stage_destination code list" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"s.no." | customTranslate}} </th>
                    <th>{{'destination_code name'|customTranslate}}</th>
                    <th>{{'destination_code type'|customTranslate}}</th>
                    <th>{{"description" | customTranslate}}</th>
                    <th>{{"vehicle_limit" | customTranslate}}</th>
                    <th>{{"status" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of responseData let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.stage_destination_code}}</td>
                    <td>{{row.stage_destination_code_type_name}}</td>
                    <td>{{row.stage_destination_code_desc}}</td>
                    <td>{{row.stage_destination_code_vehicle_limit}}</td>
                    <td>{{row.stage_destination_code_status}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewStageDestinationCode(row)"
                          *ngIf="userService.permissionMap.get('view_destination_access')==true"> {{"view" | customTranslate}}
                        </button>
                        <button class="btn btn-success" (click)="editStageDestinationCode(row)"
                          *ngIf="userService.permissionMap.get('edit_destination__access')==true"> {{"edit" |
                          customTranslate}} </button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)"> {{"delete" | customTranslate}} </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

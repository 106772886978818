import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { AlertService } from './../../theme/layout/header/alert-message/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { UserService } from 'src/app/@core/services/user.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { TableService } from '../../@core/services/table.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';

@Component({
  selector: 'app-method-management',
  templateUrl: './method-management.component.html',
  styleUrls: ['./method-management.component.scss'],
})
export class MethodManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 7, 'Method Management');
  methodsList: any = [];
  methodName = null;
  companyId = null;
  companyName = null;
  plantId = null;
  plantName = null;
  methodExecution = 0;
  mappingId = null;
  status = 1;
  methodId = null;
  methodNameValidator: any;
  plantNameValidator: any;
  companyNameValidator: any;
  methodSettingValidator: any;
  methodType = 0;
  btnTxt = 'save';
  companyList: any = [];
  plantList: any = [];
  methodSettingName: any = null;
  methodSettingId: any = null;

  constructor(
    public common: CommonService,
    private modalService: NgbModal,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public plantService: PlantService,
    public role: RoleService,
    public alert: AlertService,
    private table: TableService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Methods', url: null },
      { name: 'Method Management', url: '/user/method-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    if (this.role.user_role == 'AXESTRACK') {
      this.methodType = 1;
    } else {
      this.companyId = this.userService._companyId;
      this.plantId = this.userService._loggedInUser.plantId;
    }
  }

  ngOnInit(): void {}
  getActiveTab() {
    this.dtOptions = this.table.options(10, 7, 'Method Management');
    this.dtTrigger.unsubscribe();
    this.dtTrigger.next();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAllMethods();
    this.getCompanyList();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  reset() {
    this.companyId = null;
    this.companyName = null;
    this.plantId = null;
    this.plantName = null;
    this.methodExecution = 0;
    this.methodId = null;
    this.methodName = null;
    this.mappingId = null;
    this.status = 1;
    this.methodNameValidator = null;
    this.methodSettingValidator = null;
    this.methodType = this.role.user_role == 'AXESTRACK' ? 1 : 0;
    this.btnTxt = 'save';
    this.methodSettingId = null;
    this.methodSettingName = null;
    this.companyNameValidator = null;
    this.plantNameValidator = null;
  }

  formValidator() {
    this.methodNameValidator = this.common.nullValidator(
      this.methodName,
      'Method Name is Mandatory'
    );
    this.plantNameValidator = this.common.nullValidator(
      this.plantId,
      'Plant Name is Mandatory'
    );
    this.companyNameValidator = this.common.nullValidator(
      this.companyId,
      'Company Name is Mandatory'
    );

    if (
      !this.methodNameValidator.error &&
      (this.methodType == 1 ||
        (!this.plantNameValidator.error && !this.companyNameValidator.error))
    ) {
      this.save();
    }
  }

  save() {
    let params = {
      id: this.methodId,
      methodName: this.methodName,
      status: this.status,
      methodType: this.methodType,
      plantId: this.plantId,
      mappingId: this.mappingId,
      methodExecution: this.methodExecution,
    };
    this.common.loading = true;
    this.api.post('method/save-method', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alert.success(res.message);
          this.reset();
          this.getAllMethods();
        } else {
          this.alert.info(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectCompany(event) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantListByCompanyId(this.companyId);
  }

  selectPlant(event) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
  }

  getAllMethods() {
    this.common.loading = true;
    this.api.get('method/get-all-methods').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if (res.data) {
            this.methodsList = res.data;
            this.renderTable();
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getCompanyList() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectMethodSetting(setting: any) {
    this.methodSettingName = setting.title;
    this.methodSettingId = setting.id;
  }

  getPlantListByCompanyId(companyId) {
    this.common.loading = true;
    this.api.get('plant/all_plants?id=' + companyId).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantList = res.data;
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  editMethod(item) {
    this.common.gotoTop();
    this.methodId = item.id;
    this.mappingId = item.mappingId;
    this.methodName = item.methodName;
    this.status = item.status;
    this.methodType = item.methodType;
    this.plantId = item.plantId;
    this.companyId = item.companyId;
    this.companyName = item.companyName;
    this.plantName = item.plantName;
    this.methodExecution = item.methodExecutionType;
    this.btnTxt = 'update';
  }

  viewMethod(item?: any) {
    const methodExecutionTypeMap = {
      0: 'Push Method',
      1: 'Pull Method',
      2: 'Entry Method',
      3: 'Communication Method',
      4: 'Verification Method',
      5: 'Username Generation Method',
      6: 'Auto Stage Completion Method',
    };
    const updatedItem = {
      ...item,
      status: item.status === 1 ? 'ACTIVE' : 'INACTIVE',
      methodType: item.methodType === 1 ? 'GLOBAL' : 'LOCAL',
      methodExecutionType: methodExecutionTypeMap[item.methodExecutionType],
    };
    delete updatedItem['id'];
    delete updatedItem['userInfo'];
    this.common.params = { details: [updatedItem], title: 'Method Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="tab_section">
        <ng-container *ngFor="let tabitem of tabData" >
        <div class="tab" *ngIf="tabitem.permission" [class.active]="reportType == tabitem.value"
          (click)="changeTab(tabitem.value)">
          <p>{{tabitem.tabname|customTranslate}}</p>
        </div>
        </ng-container>
      </div>
      <div class="row" *ngIf="reportType != 'Tag Destination'">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{('tat'|customTranslate)+' '+('report'|customTranslate)}}
                </h3>
              </div>
              <div class="note">
                <span class="star">*</span><span class="note_header">{{'Note :'|customTranslate}}</span><span class="note_text">{{'TAT shown below is displayed as Actual/Expected TAT'|customTranslate}}</span>
            </div>
            </div>

            <div class="card-body">
              <div class="page_design">
                <div class="row tab_sec m-0">
                  <div class="col-md-6 col-12 date_div p-0">
                    <div class="row">
                      <div class="col-md-5 date">
                        <label>{{'start_date:'|customTranslate}}</label>
                        <input type="date" [max]="today" class="form-control"
                          [(ngModel)]="startDate">
                      </div>
                      <div class="col-md-5 date">
                        <label>{{'end_date:'|customTranslate}}</label>
                        <input type="date" class="form-control"
                          [min]="startDate" [max]="today" [(ngModel)]="endDate">
                      </div>
                      <div class="col-md-2 btn-block text-right">
                        <button class="btn btn-primary" (click)="getTatReport();">{{"search" | customTranslate}}</button>
                        <!-- <button class="btn btn-primary" (click)="processId == null && tabvalue == 'table' ? getPlantTatReport() : getTatReport();">{{"search" | customTranslate}}</button> -->
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12 buttons text-right p-0">
                    <button type="button" [class.active]="tabvalue == 'table'"
                      (click)="tabvalue = 'table'"><i class="fa fa-table"></i>
                      &nbsp;{{'table_view'|customTranslate}}</button>
                    <!-- <button type="button" [class.active]="tabvalue == 'graph'"
                      (click)="tabvalue = 'graph'"><i class="fa fa-chart-pie"></i>
                      &nbsp;{{'graph_view'|customTranslate}}</button> -->
                  </div>
                </div>

                <div class="view_table" *ngIf="tabvalue == 'table'">
                  <div class="filter_sec">
                    <div class="row">
                      <div class="col-md-3 coll" *ngIf="role.user_role=='AXESTRACK'" class="form-group col-md-3">
                        <label for="my-input" class="required">{{"company" | customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="companyList" display="companyName"
                          [placeholder]="'select_company'|customTranslate" [preSelected]="{companyName:companyName}">
                        </ps-auto-suggestion>
                      </div>

                       <div class="col-md-3 coll" *ngIf="role.user_role=='COMPANYADMIN' || role.user_role == 'AXESTRACK'" class="form-group col-md-3">
                        <label for="my-input" class="required">{{"plant" | customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                          [placeholder]="'select_plant'|customTranslate" [preSelected]="{plantName:plantName}">
                        </ps-auto-suggestion>
                      </div>

                      <div class="col-md-3 coll" *ngIf="reportType =='Stage Wise' || reportType=='Destination Wise' || reportType == 'InBetween'">
                        <label>{{'select_process'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="listOfProcess"
                          display="processName" [placeholder]="'select_process'|customTranslate" name="selectedActionData"
                          inputId="selectedActionData" isMultiSelect="true" [preSelected]="selectedProcessNames">
                        </ps-auto-suggestion>
                      </div>

                      <div class="col-md-3 coll" *ngIf="reportType=='Destination Wise'">
                        <label>{{'select_stage:'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedStage($event)" [data]="stageData" display="stageName"
                          [placeholder]="'select_stage:'|customTranslate" isMultiSelect="true" [preSelected]="selectedStageNames">
                        </ps-auto-suggestion>
                      </div>

                      <div class="col-md-3 col1">
                        <label>{{'trip_status'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectStatus($event)" [data]="statusList" display="status"
                        [placeholder]="'select_status:'|customTranslate" isMultiSelect="true" [preSelected]="selectedStatusNames">
                        </ps-auto-suggestion>
                      </div>

                      <div class="col-md-3 col1">
                        <label>{{'vehicle_number'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectVehicle($event)" [data]="vehicleList" display="vehicle_rsn"
                        [placeholder]="'select_vehicle'|customTranslate" isMultiSelect="true" [preSelected]="selectedVehicleNames" >
                        </ps-auto-suggestion>
                      </div>
                    </div>
                  </div>

                  <div class="form-group action-block mt-2">
                    <div class="btn-block button-block">

                      <div class="toggle">
                        <label for="" class="d-block"> {{'Show Auto Cancelled'|customTranslate}} </label>
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" id='customSwitch1'
                            [(ngModel)]="showAutocancelledFlag" (change)="getTatReport();">
                          <label class="custom-control-label" for="customSwitch1"></label>
                        </div>
                      </div>

                      <div class="toggle">
                        <label for="" class="d-block"> {{reportType == 'Process Wise' || reportType == 'InBetween' ? ('Show Inactive Processes'|customTranslate) : reportType == 'Stage Wise' ? ('Show Inactive Stages'|customTranslate) : ('Show Inactive Destinations' |customTranslate)}} </label>
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" id='customSwitch2'
                            [(ngModel)]="showInactiveProcessFlag" (change)="getTatReport();">
                          <label class="custom-control-label" for="customSwitch2"></label>
                        </div>
                      </div>

                      <button type="button" class="excel-btn excel"
                      (click)="exportAsExcel2(data)">
                      <i class="fas fa-file-excel"></i>
                    </button>
                    <!-- (click)="exportAsExcel2(data,'TAT Report from '+this.startDate+' to '+this.endDate)"> -->

                      <button class="btn btn-warning" (click)="resetPage()"> {{'reset'|customTranslate}} </button>
                    </div>
                  </div>

                  <div class="table_sec">
                    <!-- <table class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th style="width:10%">Date</th>
                          <ng-container *ngFor="let item of headers">
                            <th *ngIf="processId==null">{{item.process_name}} <small style="font-size: 10px;">(Actual /
                                Expected) TAT</small></th>
                            <th *ngIf="stageId==null && processId!=null">{{item.stage_name}} <small
                                style="font-size: 10px;">(Actual / Expected) TAT</small></th>
                            <th *ngIf="stageId!=null && processId!=null">{{item.destination_name}} <small
                                style="font-size: 10px;">(Actual / Expected) TAT</small></th>
                          </ng-container>
                          <th *ngIf="stageId==null && processId!=null">{{'Stage Inbetween Total TAT'}}</th>
                          <th *ngIf="processId==null">Plant TAT <small style="font-size: 10px;">(Actual / Expected)
                              TAT</small></th>
                          <th *ngIf="processId!=null">Date Wise TAT <small style="font-size: 10px;">(Actual / Expected)
                              TAT</small></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let data of responseData, let i = index">
                          <td style="width:10%">{{data.date| date:'dd-MM-yyyy'}}</td>
                          <ng-container *ngFor="let item of data.data">
                            <td><span
                                [ngClass]="item.tat > item.expected_tat ? 'badge over' : 'badge'">{{item.tat}}</span> /
                              <span class="badge info">{{item.expected_tat}}</span>
                              <i *ngIf="item.tat > item.expected_tat" class="fa fa-info-circle"
                                (click)="openModal(data.date,item,item.expected_tat)"
                                style="margin-left: 5px;color: #2d2d81;font-size: 15px"></i>
                            </td>
                          </ng-container>
                          <td *ngIf="stageId==null && processId!==null"><span
                              [ngClass]="dayWiseTatList[i] > data.expected_tat ? 'badge over' :  'badge'">{{data.inbetween_tat}}</span>
                            / <span class="badge info">{{data.inbetween_expected_tat}}</span></td>
                          <td *ngIf="processId!=null"><span
                              [ngClass]="dayWiseTatList[i] > data.expected_tat ? 'badge over' :  'badge'">{{dayWiseTatList[i]}}</span>
                            / <span class="badge info">{{data.expected_tat}}</span></td>
                          <td *ngIf="processId==null"><span
                              [ngClass]="data.actual_plant_tat > data.expected_plant_tat ? 'badge over' :  'badge'">{{data.actual_plant_tat}}</span>
                            / <span class="badge info">{{data.expected_plant_tat}}</span></td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr *ngIf="responseData?.length > 0">
                          <td>Avg. Process Wise TAT</td>
                          <ng-container *ngFor="let item of headers, let i = index">
                            <td><span
                                [ngClass]="totalProcessWiseTatList[i] > item.expected_tat ? 'badge over' : 'badge under'">{{totalProcessWiseTatList[i]}}</span>
                              / <span class="badge info">{{totalProcessWiseExpectedTatList[i]}}</span></td>
                          </ng-container>
                          <td *ngIf="stageId==null && processId!=null"><span
                              [ngClass]="totalInbetweenTotal > totalInbetweenExpected ? 'badge over' :  'badge under'">{{totalInbetweenTotal}}</span>
                            / <span class="badge info">{{totalInbetweenExpected}}</span></td>
                          <td><span
                              [ngClass]="sumOfTotalProcessWiseTat > (sumOfTotalProcessWiseExpectedTat) ? 'badge over' : 'badge under'">{{sumOfTotalProcessWiseTat}}</span>
                            / <span class="badge info">{{sumOfTotalProcessWiseExpectedTat}}</span></td>
                          </tr>
                        </tfoot>
                      </table> -->
                      <app-custom-table [data]="data" [columns]="columns"  [tableType]="TABLE_TYPE"></app-custom-table>
                      <!-- <td><span
                          [ngClass]="sumOfTotalProcessWiseTat > (responseData[0].expected_plant_tat || responseData[0].expected_tat) ? 'badge over' : 'badge under'">{{sumOfTotalProcessWiseTat}}</span>
                        / <span class="badge info">{{responseData[0].expected_plant_tat || responseData[0].expected_tat}}</span></td> -->
                  </div>
                </div>

                <div class="view_graph" *ngIf="tabvalue == 'graph'">
                  <!-- <div class="filter_sec">
                    <div class="row">
                      <div class="col-md-3 coll" *ngIf="role.user_role=='COMPANYADMIN'" class="form-group col-md-3">
                        <label for="my-input" class="required">{{"plant" | customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                          placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'select_process:'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="listOfProcess"
                          display="processName" placeholder="Select Process" name="selectedActionData"
                          inputId="selectedActionData" [preSelected]="{processName:processName}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'select_stage:'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedStage($event)" [data]="stageData" display="stageName"
                          placeholder="Choose Stage" [preSelected]="{stageName:stageName}">
                        </ps-auto-suggestion>
                      </div>
                    </div>
                  </div> -->

                  <div class="graph_div">
                    <ng-container *ngIf="reportType == 'Process Wise'">
                      <div class="row" *ngIf="reportType == 'Process Wise' ">
                        <div class="col-lg-6 col-12 mt-3">
                          <div class="card h-100 dashboard-card">
                            <div class="card-header">
                              <h3 class="card-title text-uppercase">{{'date_wise_process_total_tat'|customTranslate}}</h3>
                            </div>
                            <div class="card-body">
                              <canvas baseChart [datasets]="dateWiseTotalTat" width="100%" height="50px"
                                [labels]="dateLabels" [options]="barChartOptions" [plugins]="dateWiseChartPlugins"
                                [legend]="dateWiseChartLegend" [chartType]="barChartType" [colors]="barChartColors">
                              </canvas>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-12 mt-3">
                          <div class="card h-100 dashboard-card">
                            <div class="card-header">
                              <h3 class="card-title text-uppercase">{{'process_wise_average_tat'|customTranslate}}</h3>
                            </div>
                            <div class="card-body">
                              <canvas baseChart [data]="processAverageTatData" width="100%" height="50px"
                                [labels]="processLabels" [chartType]="pieChartType" [options]="piechartoptions"
                                class="pie-chart">
                              </canvas>
                            </div>
                          </div>
                        </div>
                        `
                      </div>

                      <div class="row" *ngIf="reportType == 'Process Wise'" style="margin-top: -8px;">
                        <div class="col-lg-12">
                          <div class="card h-100 dashboard-card">
                            <div class="card-header">
                              <h3 class="card-title text-uppercase">{{'date_wise_per_process_tat'|customTranslate}}</h3>
                            </div>
                            <div class="card-body">
                              <canvas baseChart width="1000" height="300px" [datasets]="dateWisePerProcessChartData"
                                [labels]="dateWiseProcessLabels" [options]="barChartOptions"
                                [plugins]="dateWiseChartPlugins" [legend]="dateWiseChartLegend" [chartType]="barChartType"
                                [colors]="barChartColors">
                              </canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>


                    <ng-container *ngIf="reportType == 'Stage Wise'">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="card h-100 dashboard-card">
                            <div class="card-header">
                              <h3 class="card-title text-uppercase">{{'date_wise_stage_total_tat'|customTranslate}}</h3>
                            </div>
                            <div class="card-body">
                              <canvas baseChart [datasets]="dateWiseTotalTat" width="100%" height="50px"
                                [labels]="dateLabels" [options]="barChartOptions" [plugins]="dateWiseChartPlugins"
                                [legend]="dateWiseChartLegend" [chartType]="barChartType" [colors]="barChartColors">
                              </canvas>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="card h-100 dashboard-card">
                            <div class="card-header">
                              <h3 class="card-title text-uppercase">{{'stage_wise_avg_tat'|customTranslate}}</h3>
                            </div>
                            <div class="card-body">
                              <canvas baseChart [data]="processAverageTatData" width="100%" height="50px"
                                [labels]="processLabels" [chartType]="pieChartType" [options]="piechartoptions"
                                class="pie-chart">
                              </canvas>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-3" *ngIf="reportType == 'Stage Wise'">
                        <div class="col-lg-12">
                          <div class="card h-100 dashboard-card">
                            <div class="card-header">
                              <h3 class="card-title text-uppercase">{{'date_wise_per_stage_tat'|customTranslate}}</h3>
                            </div>
                            <div class="card-body">
                              <canvas baseChart width="1000" height="300px" [datasets]="dateWisePerStageChartData"
                                [labels]="dateWiseStageLabels" [options]="barChartOptions"
                                [plugins]="dateWiseChartPlugins" [legend]="dateWiseChartLegend" [chartType]="barChartType"
                                [colors]="barChartColors">
                              </canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>


                    <ng-container *ngIf="reportType == 'Destination Wise'">
                      <div class="row" >
                        <div class="col-lg-6">
                          <div class="card h-100 dashboard-card">
                            <div class="card-header">
                              <h3 class="card-title text-uppercase">{{'date_wise_destination_total_tat'|customTranslate}}</h3>
                            </div>
                            <div class="card-body">
                              <canvas baseChart [datasets]="dateWiseTotalTat" width="100%" height="50px"
                                [labels]="dateLabels" [options]="barChartOptions" [plugins]="dateWiseChartPlugins"
                                [legend]="dateWiseChartLegend" [chartType]="barChartType" [colors]="barChartColors">
                              </canvas>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="card h-100 dashboard-card">
                            <div class="card-header">
                              <h3 class="card-title text-uppercase">{{'destination_wise_avg_tat'|customTranslate}}</h3>
                            </div>
                            <div class="card-body">
                              <canvas baseChart [data]="processAverageTatData" width="100%" height="50px"
                                [labels]="processLabels" [chartType]="pieChartType" [options]="piechartoptions"
                                class="pie-chart">
                              </canvas>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-lg-12">
                          <div class="card h-100 dashboard-card">
                            <div class="card-header">
                              <h3 class="card-title text-uppercase">{{'date_wise_per_destination_tat'|customTranslate}}</h3>
                            </div>
                            <div class="card-body">
                              <canvas baseChart width="1000" height="300px" [datasets]="dateWisePerDestinationChartData"
                                [labels]="dateWiseDestinationLabels" [options]="barChartOptions"
                                [plugins]="dateWiseChartPlugins" [legend]="dateWiseChartLegend" [chartType]="barChartType"
                                [colors]="barChartColors">
                              </canvas>
                            </div>
                          </div>
                        </div>
                      </div>

                    </ng-container>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="reportType == 'Tag Destination'">
        <app-tag-destination-tat-report></app-tag-destination-tat-report>
      </div>
    </div>
  </div>
</div>

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import _ from 'lodash';

@Component({
  selector: 'app-view-checklist',
  templateUrl: './view-checklist.component.html',
  styleUrls: ['./view-checklist.component.scss'],
})
export class  ViewChecklistComponent implements OnInit {
  actionsAndFields: any[] = [];
  checklist_title: any = '';
  checklist_description: any = null;
  image_name: any = '';
  image_ext: any = '';
  image_url: any = '';
  checklist_id: any = null;
  log_id: any = null;
  modal:boolean=true;


  @ViewChild('printableContent', { static: false }) printableContent: ElementRef;



  constructor(
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public user: UserService,
    public tableSerivice: TableService,
    private modalService: NgbModal,
    public role: RoleService,
    public alert: AlertService,
    private route: ActivatedRoute
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
    if (
      this.route.snapshot.queryParams.secret &&
      this.route.snapshot.queryParams.secretLog) {
      this.checklist_id = atob(this.route.snapshot.queryParams.secret);
      this.log_id = atob(this.route.snapshot.queryParams.secretLog);
      this.getCheckListActionsAndFields();

    } else if (this.common.params != null) {
      this.log_id = this.common.params;
      this.getCheckListActionsAndFields();
      this.modal=false;
    } else {
      this.alert.error('secret key is incorrect....');
    }
  }

  ngOnInit(): void { }

  printPage() {
    window.print()
  }
  showAction(data: any) {
    if (data.action_operation_type == 'boolean') {
      return true;
    } else {
      let count = data.fields.filter((field) =>
        field.hasOwnProperty('sel_value')
      ).length;
      if (count >= 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  getSelValue(value: any) {
    if (typeof value == 'string') {
      return value;
    } else {
      if (value.length == 0) return "--";
      return value[0];
    }
  }

  getCheckListActionsAndFields() {
    this.common.loading = true;
    let params = {
      checklistLogId : this.log_id
    }
    this.api.get('checklist-dashboard/get-checklist-response-data-for-view',params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.actionsAndFields = _.cloneDeep(res.data);
          if (res.data.length) {
            this.checklist_title = res.data[0].checklist_title;
            this.checklist_description = res.data[0].checklist_description;
          }
        },
        (err: any) => {
          this.common.loading = false;
          // console.error('Error: ', err);
        }
      );
  }

  displayImage(url) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }
  formatNames(dataArray: any): string {
    if (dataArray.length == 0) return "--";
    return dataArray.join(', ');
  }
}

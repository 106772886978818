import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitorComponent } from './visitor.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ThemeModule } from 'src/app/theme/theme.module';
import { SharedModule } from '../shared/shared.module';
import { VisitorRoutingModule } from './visitor.routing';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [VisitorComponent],
  imports: [
    CommonModule,
    FormsModule,
    VisitorRoutingModule,
    SharedModule,
    NgSelectModule,
    TranslateModule,
  ],
})
export class VisitorModule { }
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../dashboard/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { Color } from 'ng2-charts';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { DashboardModalComponent } from '../dashboard-modal/dashboard-modal.component';

@Component({
  selector: 'app-stage-vehicles',
  templateUrl: './stage-vehicles.component.html',
  styleUrls: [
    '../dashboard/dashboard.component.scss',
    './stage-vehicles.component.scss',
  ],
})
export class StageVehiclesComponent implements OnInit {
  vehicelInfo: any[] = [];
  tatcarddata: any[] = [];
  tatflowdata: any[] = [];
  allvehicles: any[] = [];
  plantProcessData = [];
  VehicleStageCount = [];
  dashboardStageCard: any[] = [];

  //modal data
  modalTitle:string='';
  tableModalData:any[]=[];
  flagShow: boolean = false;
  process = {
    id: 32,
    name: 'Loading Process',
  };
  processName: string = '';
  public barChartColors: Color[] = [
    { backgroundColor: this.common.graphsecondary },
    { backgroundColor: this.common.graphsuccess },
    { backgroundColor: this.common.graphdanger },
  ];

  tatpush = [
    {
      color: 'rgba(137, 80, 252, 0.8)',
      icon: 'fas fa-calendar-day',
    },
    {
      color: 'rgba(255, 168, 0, 0.8)',
      icon: 'fas fa-calendar-day',
    },
    {
      color: 'rgb(0, 245, 212)',
      icon: 'fas fa-calendar-day',
    },
    {
      color: '#ced700',
      icon: 'fas fa-calendar-day',
    },
  ];

  colorArray = [
    '#3dc03d',
    '#4076bd',
    '#c3c339',
    '#cd4040',
    '#33bbb9',
    '#7741d7',
    '#c33979',
    '#3ab962',
  ];

  bgColorArray = [
    '#efffef',
    '#f1f7ff',
    '#ffffef',
    '#fff2f2',
    '#eeffff',
    '#f5efff',
    '#fff1f8',
    '#f0fff5',
  ];


  // modal open variales

  constructor(
    public workflow: DashboardService,
    public common: CommonService,
    public userService: UserService,
    public api: ApiService,
    private modalService: NgbModal

  ) {
    this.getPlantProcess();
  }

  selectedProcess(event: any) {
    this.plantProcessData.forEach((process) => {
      if (process == event) {
        this.process.id = process.processId;
        this.process.name = process.processName;
        this.processName = this.process.name;
      }
    });
    this.getDashboardStageCard(this.process.id);
  }

  // gettatcarddata() {
  //   this.workflow.getworkflowtat().subscribe(
  //     (item: any) => {
  //       if (item.status) {
  //         this.tatflowdata = item.data ?? [];
  //         if (this.tatflowdata.length) {
  //           this.tatflowdata.map((i, index) => {
  //             this.tatcarddata.push({
  //               id: i.id,
  //               tat: i.tat,
  //               name: i.name,
  //               color: this.tatpush[index].color,
  //               icon: this.tatpush[index].icon,
  //             });
  //           });
  //         }
  //       }
  //     },
  //     (err) => {}
  //   );
  // }

  // getstagewisevehicles() {
  //   let Colors = ['red', 'green', 'blue', 'orange', 'darkblue', 'aliceblue'];
  //   this.workflow.stagewisevehicles().subscribe(
  //     (item: any) => {
  //       if (item.status) {
  //         item.data.forEach((element) => {
  //           if (element.vehicleCount != 0) {
  //             this.vehicelInfo.push(element);
  //           }
  //         });
  //         this.vehicelInfo.map((items: any, index: number) => {
  //           items.color = this.colorArray[index];
  //         });
  //       }
  //     },

  //     (err) => {}
  //   );
  // }
  // converToFloat(item) {
  //   return parseFloat(item);
  // }

  ngOnInit(): void {
    // this.getstagewisevehicles();
    // this.gettatcarddata();
  }

  getPlantProcess() {
    this.common.loading = true;
    this.api.get('process/get-all-process?processActive=true').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.plantProcessData = res.data;
          this.process.name = res.data[0].processName;
          this.processName = this.process.name;
          this.process.id = res.data[0].processId;
          this.getDashboardStageCard(this.process.id);
          // this.getVehicleStageCount();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  viewDashboardMoadal(headers:any,keys:any,tableModalData:any){
    const activeModal = this.modalService.open(DashboardModalComponent, {
      size: 'xl',
      windowClass:'custom-modal-width'
    });
    activeModal.componentInstance.refData={
      header:headers,
      keys:keys,
      tableModalData:tableModalData,
      modalTitle:this.modalTitle,
      modalType:'User'
    }
  }

  getStageProcessWiseVhicles(stageId:number) {
    this.common.loading = true;
    this.api
      .get(
        'dashboard/stage-process-wise-vehicles?stageId=' +
          stageId +
          '&processId=' +
          this.process.id
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.tableModalData = res.data;
            let headers=['Vehicle No','Process Name','Start Time','End Time','TAT','Status','Info'];
            let keys=['vehicle_rc_no','process_name','start_time','end_time','tat','stage_workflow_status']
            this.viewDashboardMoadal(headers,keys,this.tableModalData);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  // getVehicleStageCount() {
  //   let params = '?processId=' + this.process.id;
  //   this.api.get('dashboard/get-vehicle-stage-count' + params).subscribe(
  //     (res: any) => {
  //       this.common.loading = false;
  //       if (res.status) {
  //         this.VehicleStageCount = res.data || [];
  //         this.VehicleStageCount = this.VehicleStageCount.map(
  //           (item, index) => ({ ...item, color: this.colorArray[index % 8], bgColor : this.bgColorArray[index % 8] })
  //         );
  //       }
  //     },
  //     (err: any) => {
  //       console.error('Error: ', err);
  //       this.common.loading = false;
  //     }
  //   );
  // }

  getDashboardStageCard(processId: number) {
    let params = {
      processId: processId,
    };
    this.api.get('dashboard/get-dashboard-stage-card', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.dashboardStageCard = res.data.map((item, index) => ({
            ...item,
            color: this.colorArray[index % 8],
            bgColor: this.bgColorArray[index % 8],
            tat_percentage:this.calculateDurationPercentage(item.expected_tat,item.total_tat)
          }));
          console.log(this.dashboardStageCard)
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  calculateDurationPercentage(duration1, duration2) {
    // Convert durations to seconds
    const seconds1 = this.convertDurationToSeconds(duration1);
    const seconds2 = this.convertDurationToSeconds(duration2);

    // Check for division by zero
    if (seconds1 === 0) {
      return { percentage: 0.0, increase: false };
    }

    // Calculate percentage
    const percentage = (seconds2 / seconds1) * 100;

    // Round to two decimal places
    let roundedPercentage:any = this.roundToTwoDecimals(percentage/60); //division by 60 for Minutes Ratio

    // Determine increase or decrease
    const increase = roundedPercentage > 0;
    roundedPercentage = increase || roundedPercentage == 0 ? "+"+roundedPercentage+"%" : "-"+roundedPercentage+"%"

    return { percentage: roundedPercentage, increase };
  }

  convertDurationToSeconds(duration) {
    const parts = duration.split(":");
    const hours = parseInt(parts[0], 10) || 0;
    const minutes = parseInt(parts[1], 10) || 0;
    const seconds = parseInt(parts[2], 10) || 0;
    return hours * 3600 + minutes * 60 + seconds;
  }

  roundToTwoDecimals(number) {
    return Math.round(number * 100) / 100;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { VehicleInfo } from 'src/app/dataType/vehicleInterface';

@Component({
  selector: 'app-vehicle-ban',
  templateUrl: './vehicle-ban.component.html',
  styleUrls: ['./vehicle-ban.component.scss']
})
export class VehicleBanComponent implements OnInit {


  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(
    10,
    4,
    'Vehicle Restriction Allowance List'
  );
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  title = 'Ban Vehicle ';
  btn = 'Ban';
  restrictionScope: string = null;
  companyName: string = null;
  plantName: string = null;
  companyList: any = [];
  plantList: any = [];
  companyId: number = null;
  plantId: number = null;
  vehicleList: VehicleInfo[] = [];
  priorityLevel:number = -1;

  constructor(
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public api: ApiService,
    public alertService: AlertService,
    public role: RoleService,
    private plantService: PlantService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Vehicle', url: null },
      {
        name: 'Vehicle',
        link: '/user/ban-vehicle',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
      this.restrictionScope = 'AXESTRACK';
      this.priorityLevel = 2;
      this.getVehicleList(-1,-1);
    } else if (
      this.role.user_role == 'COMPANYADMIN' ||
      this.role.user_role == 'COMPANYUSER'
    ) {
      this.companyId = this.userService._loggedInUser.companyId;
      this.getPlantById(this.userService._loggedInUser.companyId);
      this.restrictionScope = 'COMPANY';
      this.priorityLevel = 1;
      this.getVehicleList(this.companyId,-1);
    } else {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
      this.restrictionScope = 'PLANT';
      this.priorityLevel = 0;
      this.getVehicleList(this.companyId,this.plantId);
    }
  }

  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        id: id,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.plantList = res.data;
            resolve(this.plantList);
          }
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.getVehicleList(this.companyId,-1);
    this.plantName = null;
    this.plantId = null;
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getVehicleList(this.companyId,this.plantId);
  }


  getVehicleList(companyId:number,plantId:number) {
    let params = {
      companyId: (companyId == 1 ? -1 :companyId),
      plantId:plantId
    }
    this.common.loading = true;
    this.api.get('vehicle/get-vehicle-by-plant',params).subscribe(
      (res: any) => {
        this.vehicleList = res.data;
        this.common.loading = false;
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedRestriction(scope: any) {
    scope = scope.target.value;
    if(scope=='AXESTRACK'){
      this.priorityLevel = 2;
      this.companyId = -1;
      this.plantId = -1;
      this.getVehicleList(-1,-1);
    }else if (scope == 'COMPANY') {
      this.priorityLevel = 1;
      this.plantId = null;
      this.plantName = null;
      this.plantList = null;
      this.getVehicleList(this.companyId,-1);
    }else {
      this.priorityLevel = 0;
    }
    this.restrictionScope = scope;
  }

  saveVehicleRestriction(ban:boolean=true,vehicleId:number=-1) {
    let params ={
      ban:ban,
      vehicleId:vehicleId,
      plantId:this.plantId ?? -1,
      companyId:  (this.companyId == 1 ? -1 : this.companyId) ?? -1
    }
    this.api.post('vehicle/ban-vehicle', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertService.success(ban ? 'Vehicle Successfully Banned !!' : 'Vehicle Successfully Unbanned !!');
        }
        this.getVehicleList(this.companyId ?? -1,this.plantId ?? -1);
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Vehicle Restrction',
      description: `<b>&nbsp;` + 'Are you sure you want to  '+(row.ban ? 'Unban this Vehicle' : 'ban this Vehicle')+' ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.saveVehicleRestriction(!row.ban,row.vehicle_id);

        if(!row.ban){
          this.generateBanCompliance(row.vehicle_id,row.vehicle_rc_no);
        }
        else{
          this.deleteBanCompliance(row.vehicle_id);
        }
      }
    });
  }

  async resetDetail() {
    this.btn = 'Ban';
    if (this.role.user_role == 'AXESTRACK') {
      this.restrictionScope = 'AXESTRACK';
      this.priorityLevel = 2;
      this.plantId = null;
      this.plantName = null;
      this.plantList = null;
    }
     else if (
      this.role.user_role == 'COMPANYADMIN' ||
      this.role.user_role == 'COMPANYUSER' || this.role.user_role == 'AXESTRACK'
    ) {
      this.companyId = this.userService._loggedInUser.companyId;
      this.priorityLevel = 1;
      this.plantId = null;
      this.plantName = null;
      this.plantList = null;
      this.restrictionScope = 'COMPANY';
      await this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.priorityLevel = 0;
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
      this.restrictionScope = 'PLANT';
    }
  }

  saveValidator() {
    if (this.role.user_role == 'AXESTRACK' && this.restrictionScope == 'COMPANY' && this.companyId == null)
      return this.alertService.warn('Company is not selected');
    if (
      (this.role.user_role == 'COMPANYADMIN' ||
        this.role.user_role == 'COMPANYUSER' ||
        this.role.user_role == 'AXESTRACK') &&
      this.plantId == null &&
      this.restrictionScope == 'PLANT'
    )
      return this.alertService.warn('Plant is not selected');
    this.saveVehicleRestriction();
  }



  // ban vehicle
 generateBanCompliance(vehicleId,vehcileName){
  let params={
    refId:vehicleId,
    complianceData:{
        vehicle_name:vehcileName,
        remark:'Vehicle Banned'
    },
    complianceCategoryName:'Vehicle',
    complianceSubCategoryName:'Ban'
  }
  this.api.post('compliance/save_compliance', params).subscribe(
    (res: any) => {
      console.log('Success')
    },
    (err: any) => {
      console.error('Error: ', err);
    }
  );
}


deleteBanCompliance(vehicleId){
  let params={
    refId:vehicleId,
    complianceCategoryName:'Vehicle',
    complianceSubCategoryName:'Unban'
  }
  this.api.post('compliance/save_compliance', params).subscribe(
    (res: any) => {
      console.log('Success')
    },
    (err: any) => {
      console.error('Error: ', err);
    }
  );
}
}





<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{"override" | translate}} {{"status"|translate}}</h5>

  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1)">
    <span aria-hidden="true">&times;</span>
  </button>
  <app-trip-comment-trail [tripId]="actionDetail.transactionId" style="margin-right: 25px;filter: brightness(10.5); "></app-trip-comment-trail>
  <i class="fa fa-info-circle icon_info" (click)="openTripDetail()"></i>
</div>

<div class="modal-body">
  <div class="override_card">
    <div class="override_sec">
      <div class="left_sec">{{"action_name" | translate}} :</div>
      <div class="right_sec">{{actionDetail.actionName}}</div>
    </div>
    <div class="override_sec">
      <div class="left_sec">{{"current_response" | translate}} :</div>
      <div class="right_sec">{{actionDetail.responseStatus}}</div>
    </div>
    <div class="override_sec">
      <div class="left_sec">{{"done_by" | translate}} :</div>
      <div class="right_sec">{{actionDetail.modifiedBy}}</div>
    </div>
  </div>

  <div class="form_Sec" *ngIf="this.currentActionData!=[]">
    <div class="form_inside_sec" *ngIf="this.currentActionData[0]">
      <div class="form_title">
        <h5>{{"action_response" | translate}}</h5>
      </div>
      <div class="form_body">
        <h6 *ngIf="this.currentActionData[0].response_status==null" class="white">{{'PENDING'}}</h6>
        <div class="form_sec" *ngIf="this.currentActionData[0].response_value.length">
          <div class="row">
            <ng-container *ngFor="let response of this.currentActionData[0].response_value">
              <div class="col-md-12" *ngIf="response.fieldType == 'LABEL'">
                <p [innerHTML]="response.values"></p>
              </div>
              <div class="col-md-6"
                *ngIf="response.fieldType=='FILE'||response.fieldType=='SIGNATURE'">
                <div class="form-group">
                  <label for="email">{{response.name+" "}}</label>
                  {{imageName(response.values)}}
                  <div class="image_div" *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                    <div class="image" (click)="displayImage(response.values)">
                      <img [src]="image_url" alt="">
                    </div>
                    <div class="img_name">{{" "+this.image_name}}</div>
                  </div>

                  <div class="image_div" *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                    <div class="image" (click)="newTab(response.values)">
                      <img src="assets/images/document_img.png">
                    </div>
                    <div class="img_name">{{" "+this.image_name}}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6"
                *ngIf="response.fieldType=='RFIDCODEWITHIMAGE'||response.fieldType=='BARCODEWITHIMAGE'||response.fieldType=='GEOCODEDIMAGE'">
                <div class="form-group">
                  <label for="email">{{response.name}}</label>
                  {{imageName(response.values[0].fileUrl)}}
                  <div class="image_div" *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                    <div class="image" (click)="displayImage(response.values[0].fileUrl)">
                      <img [src]="image_url" alt="">
                    </div>
                    <div class="map_id">
                      <img (click)="location(response.values[0].lat,response.values[0].lng)"
                        style="cursor:pointer; padding: 0px; background-color: transparent; width: auto;" height="15"
                        width="15" src="assets/images/location.png" />

                      <div class="row id_class" *ngIf="response.fieldType!='GEOCODEDIMAGE'"><strong>Id: </strong>
                        <div class="img_name">{{" "+response.values[0].value}}</div>
                      </div>
                    </div>
                  </div>

                  <div class="image_div" *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                    <div class="image" (click)="newTab(response.values[0].fileUrl)">
                      <img src="assets/images/document_img.png">
                    </div>
                    <div class="map_id">
                      <img (click)="location(response.values[0].lat,response.values[0].lng)"
                        style="cursor:pointer; padding: 0px; background-color: transparent; width: auto;" height="15"
                        width="15" src="assets/images/location.png" />

                      <div class="row id_class" *ngIf="response.fieldType!='GEOCODEDIMAGE'"><strong>Id: </strong>
                        <div class="img_name">{{" "+response.values[0].value}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6"
                *ngIf="response.fieldType!='LABEL'&&response.fieldType!='FILE'&&response.fieldType!='RFIDCODEWITHIMAGE'&&response.fieldType!='BARCODEWITHIMAGE'&&response.fieldType!='GEOCODEDIMAGE'&&response.fieldType!='SIGNATURE'">
                <div class="form-group" *ngIf="response.fieldType!='RFIDCODE'&& response.fieldType!='BARCODE'">
                  <label for="email">{{response.name}}</label>
                  <div class="text_div">
                    <input type="text" class="form-control" [value]="response.values" disabled>
                  </div>
                </div>
                <div class="form-group" *ngIf="response.fieldType=='RFIDCODE'||response.fieldType=='BARCODE'">
                  <label for="email">{{response.name}}</label>
                  <div class="text_div">
                    <input type="text" class="form-control" [value]="response.values[0].value" disabled>
                  </div>
                  <div class="map_id">
                    <img (click)="location(response.values[0].lat,response.values[0].lng)"
                      style="cursor:pointer; padding: 0px; background-color: transparent; width: auto;" height="15"
                      width="15" src="assets/images/location.png" />
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
      <div class="form-group">
        <label for="message-text" class="col-form-label">Overriding Remark</label>
        <textarea class="form-control" id="message-text" type="text" [(ngModel)]="commentMessage" name="message-text" #name="ngModel" placeholder="Enter Remark"></textarea>
      </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-success" type="button" outline (click)="updateOverridingStatus('PASS')" id="cancel"
    style="cursor: pointer;">{{"approve" | translate}}</button>
  <button class="btn btn-danger" type="button" outline (click)="updateOverridingStatus('FAIL')" id="submit"
    style="cursor: pointer;">{{"reject" | translate}}</button>

</div>

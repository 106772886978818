import {
  Injectable
} from '@angular/core';
import {
  Observable
} from 'rxjs';
import {
  ApiService
} from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class StagesettingService extends ApiService {

  stagedata: any;

  getstagessettingfields(stageId: number,processId:number): Observable < any > {
    let apiSubUrl = "fields/get-master-field-by-stage-id";
    return super.get(`${apiSubUrl}?stageId=${stageId}&processId=${processId}`);
  }

  getstagessettingfieldsbyactionid(actionId:number, processId: number, type: string): Observable < any > {
    let apiSubUrl = "fields/get-master-field-by-action-id";
    return super.get(`${apiSubUrl}?actionId=${actionId}&processId=${processId}&type=${type}`);
  }

  getFormFieldByActionIdAndStageIdAndProcessId(actionId:number, processId: number, stageId: number): Observable < any > {
    let apiSubUrl = "dashboard/get-dashboard-actions-form-fields";
    return super.get(`${apiSubUrl}?actionId=${actionId}&processId=${processId}&stageId=${stageId}`);
  }


  savestagesetting(params: any) {
    let apiSubUrl = "fields/save-field-mapping";
    return super.post(apiSubUrl, params);
  }

  getstageById(processId:any): Observable < any > {
    let apiSubUrl = "stage/get-all-stage";
    return super.get(`${apiSubUrl}?id=${processId}&allStage=false`);
  }

}

<div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">{{'assign_product'|customTranslate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal(null,-1)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="productNames.length" class="product_name">
      <span *ngFor="let name of productNames" class="status-badge badge-success">{{name.product_name}}</span>
    </div>
    <div class="form-group">
      <label for="my-input" class="required">{{'select_product'|customTranslate}}</label>
      <ps-auto-suggestion (onSelected)="selectProduct($event)" [data]="productList" display="product_name"
        placeholder="Choose Product">
      </ps-auto-suggestion>
      <div class="error" *ngIf="productValidator && productValidator.error">
        <span>{{productValidator.msg}} </span>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-success" type="button" outline (click)="formValidator()"
        style="cursor: pointer;">{{'assign'|customTranslate}}</button>
    </div>

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ refData.hardwareName }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="viewType=='Table'">
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive pt-2">
        <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th>{{ "from_version" | customTranslate }}</th>
              <th>{{ "to_version" | customTranslate }}</th>
              <th>{{ "update_type" | customTranslate }}</th>
              <th>{{ "update_initiated_at" | customTranslate }}</th>
              <th>{{ "update_completed_at" | customTranslate }}</th>
              <th>{{ "update_time_taken" | customTranslate }}</th>
              <th>{{ "update_status" | customTranslate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of hardwareOtaLogs; index as i">
              <td>{{ item.from_version }}</td>
              <td>{{ item.to_version }}</td>
              <td>{{ item.update_type }}</td>
              <td>{{ item.update_initiated_at }}</td>
              <td>{{ item.update_completed_at }}</td>
              <td>{{ item.update_time_taken }}</td>
              <td>{{ item.ota_status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="action_data_div" *ngIf="this.hardwareOtaLogs.length == 0">
        <p style="color: red; display: flex; justify-content: center">
          No Record Found !!
        </p>
      </div>
    </div>
  </div>
</div>
<div class="modal-body" *ngIf="viewType=='Chart'">
  <div class="row" style="justify-content:flex-end">
    <div class="col-lg-4">
      <select class="form-control" name="iterationType" (change)="getHardwareHealth($event)">
        <option *ngFor="let item of intervalType" [value]="item">{{item}}</option>
      </select>
    </div>
  </div>
  <div class="view_graph">
    <div class="row">
      <div class="col-lg-6 col-12 mt-3 pt-0 pb-0">
        <div style="position: relative;" id='graph-1147'></div>
      </div>
      <div class="col-lg-6 col-12 mt-3 pt-0 pb-0">
        <div style="position: relative;" id='graph-1148'></div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12 mt-3 pt-0 pb-0">
        <div style="position: relative;" id='graph-1149'></div>
      </div>
      <div class="col-lg-6 col-12 mt-3 pt-0 pb-0">
        <div style="position: relative;" id='graph-1150'></div>
      </div>
    </div>
  </div>
</div>

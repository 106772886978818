<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="top_head">
                <div class="logo_visitor">
                  <img src="assets/images/vms_logo.png">
                </div>
              </div>
            </div>

            <div class="card-body">
              <section class="visitor_dashboard">
                <div class="count_visitor" *ngIf="changeDiv">
                  <div class="card_visitor">
                    <div class="card_seprate" *ngFor="let visitor of countVisitors;index as i"
                      [ngClass]="'borderColor-'+(i+1)">
                      <div class="icon_sec">
                        <i [ngClass]="'bgColor-'+(i+1)" [class]='visitor.icon'></i>
                      </div>

                      <div class="content_sec">
                        <h6 [ngClass]="'color-'+(i+1)">{{visitor.title}}</h6>
                        <p [ngClass]="'bgColor-'+(i+1)">{{visitor.count}}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="stage_visitor" *ngIf="!changeDiv">
                  <div class="row stage_box">
                    <div class="col-md-3 in_out_visitor">
                      <h6>In Visitors</h6>

                      <ul>
                        <li *ngFor="let vdata of stageIn" (click)="getActions(vdata)"><i class="fa fa-user"></i>
                          {{vdata.visitor_name}} </li>
                      </ul>

                    </div>

                    <div class="col-md-6">
                      <div class="col-md-12 v_card_div">
                        <i class="fa fa-caret-right change_arrow" (click)="togglSec = !togglSec"
                          [ngClass]="(togglSec == true? 'rotate_icon' : '')"></i>
                        <div class="row">
                          <div class="col-md-6 pre_post pr-2" *ngIf="togglSec">
                            <h6>Pre Action</h6>
                            <ul>
                              <li *ngFor="let adata of preActionData">
                                <div class="content">
                                  <span
                                    [ngClass]="{'bg_green':adata.status === 'completed', 'bg_red':adata.status === 'rejected', 'bg_gray': adata.status === ''}">{{adata.name}}</span>
                                </div>
                                <i [ngClass]="adata.clip_icon"></i>
                                <i [ngClass]="adata.info_icon"></i>
                              </li>
                            </ul>
                          </div>

                          <div class="visitor_card_sec" [ngClass]="togglSec ? 'col-md-6':'col-md-12'">
                            <div class="card_design" id="visitorcard">
                              <div class="card_top">
                                <h5>VISITOR CARD</h5>
                              </div>


                              <div class="card_sec">
                                <div class="card_left" [ngStyle]="togglSec && {'width': '100%'}">
                                  <img src="assets/images/visitor_img.png">
                                </div>


                                <div class="card_right" *ngIf="!togglSec">
                                  <div class="data_line">
                                    <h6>Name:</h6>
                                    <p>{{visitorCardDetails[0]?.name}}</p>
                                  </div>
                                  <div class="data_line">
                                    <h6>Mobile:</h6>
                                    <p>{{visitorCardDetails[0]?.mobileNO}}</p>
                                  </div>
                                  <div class="data_line">
                                    <h6>To Meet:</h6>
                                    <p>{{visitorCardDetails[0]?.meetWith}}</p>
                                  </div>
                                  <div class="data_line">
                                    <h6>Purpose:</h6>
                                    <p>{{visitorCardDetails[0]?.visitType}}</p>
                                  </div>
                                </div>
                              </div>


                              <div class="card_bottom">
                                <p>VID:- {{visitorCardDetails[0]?.orderId}}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="col-md-12 action_visitor">
                        <h6>TODO</h6>
                        <ul>
                          <li *ngFor="let adata of addVisitorActionsFields">
                            <div class="content">
                              <span
                                [ngClass]="{'bg_green':adata.response_status == 'PASS', 'bg_red':adata.response_status == 'FAIL', 'bg_gray': adata.response_status == 'PENDING'}">{{adata.action_name}}</span>
                            </div>
                            <i class="fa fa-clipboard"
                              *ngIf="adata.action_operation_type=='form' && adata.response_status == 'PENDING' && prePass "
                              (click)="openActionForm(adata)"></i>
                            <!-- <i  class="fa fa-clipboard"
                              (click)="openActionForm(adata)"></i> -->
                            <div class="toggle_box clip_pass"
                              *ngIf="adata.action_operation_type=='boolean' &&  adata.response_status == 'PENDING' && prePass">
                              <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                                <mat-button-toggle value="yes" (click)="storeBooleanActionResult(adata,'PASS')">Pass
                                </mat-button-toggle>
                                <mat-button-toggle value="no" (click)="storeBooleanActionResult(adata,'FAIL')">Fail
                                </mat-button-toggle>
                              </mat-button-toggle-group>
                            </div>
                            <i class="fa fa-info" (click)="actionDetail(adata)"></i>
                          </li>
                        </ul>
                        <div class="stage_btn_group">
                          <button type="button" class="btn btn-success" [disabled]="!allPass"
                            (click)="stageApproved(approvecontent)">Approve</button>
                          <button type="button" class="btn btn-danger" [disabled]="!allAttend"
                            (click)="rejectStage(rejectContent)">Reject</button>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 in_out_visitor">
                      <h6>Out Visitors</h6>
                      <ul>
                        <li *ngFor="let vdata of stageOut"><i class="fa fa-user"></i> {{vdata.visitor_name}} </li>
                      </ul>
                    </div>
                  </div>
                </div>


                <div class="table_viditor" *ngIf="changeDiv">
                  <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead class="thead-light">
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Meeth With</th>
                        <th>Meeting Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let tbldata of stageIn; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{tbldata.visitor_name}}</td>
                        <td>{{tbldata.visitor_no}}</td>
                        <td>{{tbldata.meetwith}}</td>
                        <td>{{tbldata.process_name}}</td>
                        <td class="status_class">
                          <span class="fa" [ngClass]="{
                            'fa-check class_green': tbldata.status == 'PASS' && tbldata.trip_Status == 'INPROGRESS',
                            'fa-times class_red': tbldata.status == 'FAIL' || tbldata.trip_Status == 'CANCELLED',
                            'fa-sync class_yellow': tbldata.status === 'PENDING'}">
                            {{tbldata.status == 'PASS' && tbldata.trip_Status == 'INPROGRESS' ? 'INPROGRESS':
                            (tbldata.status == 'PENDING' ? 'APPROVAL PENDING' : 'Rejected')}}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="add_visit" *ngIf="changeDiv">
                  <button class="btn_visit" (click)="getStageActions(content,null)">+ Add New Visit</button>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Visitor Form</h6>
    <div aria-label="Close" (click)="modal.dismiss('Cross click');resetStepper()"><i class="fa fa-times"></i></div>
  </div>

  <div class="modal-body add_visitor">
    <div class="row" *ngIf="showId==false">
      <div class="col-md-3 left_sec">
        <div class="line"></div>
        <div class="circle_div" *ngFor="let action of addVisitorActionsFields; index as i">
          <p [ngClass]="{'activeClass': activeForm == i && i != (addVisitorActionsFields.length - 1),'activeLastClass': activeForm == i && i == (addVisitorActionsFields.length - 1)}"
            [ngStyle]="{'margin-bottom': i == (addVisitorActionsFields.length - 1) ? '0px': ''}">{{i+1}}
            <span>{{action.action_name}}</span>
          </p>
        </div>
      </div>

      <div class="col-md-1">

      </div>

      <div class="col-md-8 right_sec">
        <form>
          <ng-container *ngFor="let field of addVisitorActionsFields[index].fields; index as i">
            <div class="form_sec" *ngIf="activeForm == index">
              <label>{{field.field_name}}</label>
              <div class="field">
                <ng-container *ngIf="field.field_type!='LABEL'">
                  <ng-container *ngIf="field.field_type=='TEXTFIELD' && field.sel_value==null">
                    <input
                      *ngIf="responses.length==0 || responses[0].action_id!=addVisitorActionsFields[index].action_id"
                      type="text" placeholder={{field.field_name}} class="field_class"
                      (change)="enterValues($event , field , i)" required>
                    <ng-container
                      *ngIf="responses.length && responses[0].action_id==addVisitorActionsFields[index].action_id">
                      <ng-container *ngFor="let res of responses">
                        <input *ngIf="res.name==field.field_name" type="text" placeholder={{field.field_name}}
                          [value]="res.value[0]" class="field_class" (change)="enterValues($event , field , i)">
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="field.field_type=='TEXTFIELD' && field.sel_value!=null">
                    <input type="text" class="field_class" [value]="field.sel_value" disabled>
                  </ng-container>

                  <ng-container *ngIf="field.field_type=='NUMBERFIELD'&& field.sel_value==null">
                    <input
                      *ngIf="responses.length==0 || responses[0].action_id!=addVisitorActionsFields[index].action_id"
                      type="number" placeholder={{field.field_name}} class="field_class"
                      (change)="enterValues($event , field , i)" required>
                    <ng-container
                      *ngIf="responses.length&& responses[0].action_id==addVisitorActionsFields[index].action_id">
                      <ng-container *ngFor="let res of responses">
                        <input *ngIf="res.name==field.field_name" type="number" placeholder={{field.field_name}}
                          [value]="res.value[0]" class="field_class" (change)="enterValues($event , field , i)">
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="field.field_type=='NUMBERFIELD'&& field.sel_value!=null">
                    <input type="number" class="field_class" [value]="field.sel_value" disabled>
                  </ng-container>

                  <ng-container *ngIf="field.field_type=='TIME'&& field.sel_value==null">
                    <input type="time"
                      *ngIf="responses.length==0 || responses[0].action_id!=addVisitorActionsFields[index].action_id"
                      placeholder={{field.field_name}} class="form-control" (change)="enterValues($event , field , i)"
                      required>
                    <ng-container
                      *ngIf="responses.length && responses[0].action_id==addVisitorActionsFields[index].action_id">
                      <ng-container *ngFor="let res of responses">
                        <input type="time" *ngIf="res.name==field.field_name" placeholder={{field.field_name}}
                          [value]="res.value[0]" class="form-control" (change)="enterValues($event , field , i)">
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="field.field_type=='TIME'&& field.sel_value!=null">
                    <input type="time" class="field_class" [value]="field.sel_value" disabled>
                  </ng-container>

                  <ng-container *ngIf="field.field_type=='URL'&& field.sel_value==null">
                    <input type="url"
                      *ngIf="responses.length==0|| responses[0].action_id!=addVisitorActionsFields[index].action_id"
                      placeholder={{field.field_name}} class="form-control" (change)="enterValues($event , field , i)"
                      required>
                    <ng-container
                      *ngIf="responses.length && responses[0].action_id==addVisitorActionsFields[index].action_id">
                      <ng-container *ngFor="let res of responses">
                        <input type="url" *ngIf="res.name==field.field_name" placeholder={{field.field_name}}
                          [value]="res.value[0]" class="form-control" (change)="enterValues($event , field , i)">
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="field.field_type=='URL'&& field.sel_value!=null">
                    <input type="url" class="field_class" [value]="field.sel_value" disabled>
                  </ng-container>

                  <ng-container *ngIf="field.field_type=='DATETIMEPICKER'&& field.sel_value==null">
                    <input type="datetime-local"
                      *ngIf="responses.length==0|| responses[0].action_id!=addVisitorActionsFields[index].action_id"
                      placeholder={{field.field_name}} class="form-control" (change)="enterValues($event , field , i)"
                      required>
                    <ng-container
                      *ngIf="responses.length && responses[0].action_id==addVisitorActionsFields[index].action_id">
                      <ng-container *ngFor="let res of responses">
                        <input type="datetime-local" *ngIf="res.name==field.field_name" placeholder={{field.field_name}}
                          [value]="res.value[0]" class="form-control" (change)="enterValues($event , field , i)">
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="field.field_type=='DATETIMEPICKER'&& field.sel_value!=null">
                    <input type="datetime-local" class="field_class" [value]="field.sel_value" disabled>
                  </ng-container>

                  <ng-container *ngIf="field.field_type=='FILE'&& field.sel_value==null">
                    <app-camera-component [retake]="retakeImage" (getPicture)="handleImage($event, field,i)">
                    </app-camera-component>
                    <div style="display: flex; flex-direction: column; align-items:center;"
                      *ngIf="capturedImages[field.database_field_name]">
                      <p>Upload Done! <button class="btn btn-warning"
                          (click)="retakeImage = !retakeImage; capturedImages[field.database_field_name] = null;">Retake</button>
                      </p>
                      <img style="height: 200px;" [src]="capturedImages[field.database_field_name].imageAsDataUrl" />
                    </div>
                  </ng-container>

                  <ng-container *ngIf="field.field_type=='TEXTAREA'&& field.sel_value==null">
                    <textarea rows="3"
                      *ngIf="responses.length==0|| responses[0].action_id!=addVisitorActionsFields[index].action_id"
                      placeholder={{field.field_name}} cols="50" class="form-control"
                      (change)="enterValues($event , field , i)" required></textarea>
                    <ng-container
                      *ngIf="responses.length && responses[0].action_id==addVisitorActionsFields[index].action_id">
                      <ng-container *ngFor="let res of responses">
                        <textarea rows="3" *ngIf="res.name==field.field_name" placeholder={{field.field_name}} cols="50"
                          class="form-control" [value]="res.value[0]"
                          (change)="enterValues($event , field , i)"></textarea>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="field.field_type=='TEXTAREA'&& field.sel_value!=null">
                    <textarea rows="3" cols="50" [value]="field.sel_value" disabled class="textarea_class"></textarea>
                  </ng-container>

                  <ng-container *ngIf="field.field_type=='DATEPICKER'&& field.sel_value==null">
                    <input type="date"
                      *ngIf="responses.length==0|| responses[0].action_id!=addVisitorActionsFields[index].action_id"
                      placeholder={{field.field_name}} class="form-control" (change)="enterValues($event , field , i)"
                      required>
                    <ng-container
                      *ngIf="responses.length && responses[0].action_id==addVisitorActionsFields[index].action_id">
                      <ng-container *ngFor="let res of responses">
                        <input type="date" *ngIf="res.name==field.field_name" placeholder={{field.field_name}}
                          [value]="res.value[0]" class="form-control" (change)="enterValues($event , field , i)">
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="field.field_type=='DATEPICKER'&& field.sel_value!=null">
                    <input type="date" class="field_class" [value]="field.sel_value" disabled>
                  </ng-container>

                  <ng-container *ngIf="field.field_type=='RADIOBUTTON'&& field.sel_value==null">
                    <ng-container *ngFor="let opt of field.options;" class="radio_check">
                      <input type="radio"
                        *ngIf="responses.length==0|| responses[0].action_id!=addVisitorActionsFields[index].action_id"
                        placeholder={{field.field_name}} name="option" value="{{opt}}"
                        (change)="enterValues($event , field , i)" required>&nbsp;{{opt.name}}
                      <ng-container
                        *ngIf="responses.length && responses[0].action_id==addVisitorActionsFields[index].action_id">
                        <div *ngFor="let res of responses">
                          <input type="radio" *ngIf="res.name==field.field_name" placeholder={{field.field_name}}
                            name="option" [checked]="opt.name == res.value[0]"
                            (change)="enterValues($event , field , i)">&nbsp;{{res.value[0]}}
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="field.field_type=='RADIOBUTTON'&& field.sel_value!=null">
                    <ng-container *ngFor="let opt of field.options" class="radio_check">
                      <input type="radio" name="option" [checked]="field.sel_value == opt.name"
                        disabled>&nbsp;{{opt.name}}
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="field.field_type=='CHECKBOX'&& field.sel_value==null">
                    <ng-container *ngFor="let opt of field.options" class="radio_check">
                      <input type="checkbox"
                        *ngIf="responses.length==0|| responses[0].action_id!=addVisitorActionsFields[index].action_id"
                        placeholder={{field.field_name}} [value]="opt.name" [name]="'field'+i"
                        (change)="enterValues($event , field , i)" required>&nbsp;{{opt.name}}
                      <ng-container
                        *ngIf="responses.length && responses[0].action_id==addVisitorActionsFields[index].action_id">
                        <ng-container *ngFor="let res of responses">
                          <input type="checkbox" *ngIf="res.name==field.field_name" placeholder={{field.field_name}}
                            [name]="'field'+i" [checked]="opt.name == res.value[0]"
                            (change)="enterValues($event , field , i)">&nbsp;{{opt.name}}
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="field.field_type=='CHECKBOX'&& field.sel_value!=null">
                    <ng-container *ngFor="let opt of field.options" class="radio_check">
                      <input type="checkbox" [checked]="field.sel_value == opt.name" disabled>&nbsp;{{opt.name}}
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="field.field_type=='SELECTLISTSINGLE'&& field.sel_value==null ">
                    <ng-container
                      *ngIf="responses.length==0|| responses[0].action_id!=addVisitorActionsFields[index].action_id">
                      <ps-auto-suggestion (onSelected)="enterValues($event, field, i)" [data]="field.options"
                        [display]="field.label" placeholder="Choose option">
                      </ps-auto-suggestion>
                    </ng-container>
                    <ng-container
                      *ngIf="responses.length && responses[0].action_id==addVisitorActionsFields[index].action_id">
                      <ng-container *ngFor="let res of responses">
                        <select class="form-control" (change)="enterValues($event , field , i)"
                          *ngIf="res.name==field.field_name ">
                          <option *ngFor="let opt of field.options" [selected]="opt[field.store_value]==res.value[0]"
                            [value]="opt[field.store_value]">{{opt[field.label]}}</option>
                        </select>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="field.field_type=='SELECTLISTSINGLE'&& field.sel_value!=null">
                    <select class="field_class" [(ngModel)]="field.database_field_name" disabled>
                      <option *ngFor="let opt of field.options" [value]="opt[field.store_value]">{{opt[field.label]}}
                      </option>
                    </select>
                  </ng-container>

                  <ng-container *ngIf="field.field_type=='SELECTLISTMULTIPLE'&& field.sel_value==null ">
                    <ps-auto-suggestion isMultiSelect="true" [data]="field.options" [display]="field.label"
                      placeholder={{field.field_name}} (onSelected)="enterValues($event , field , i)">
                    </ps-auto-suggestion>
                  </ng-container>
                  <ng-container *ngIf="field.field_type=='SELECTLISTMULTIPLE'&& field.sel_value!=null">
                    <ps-auto-suggestion isMultiSelect="true" (onSelected)="enterValues($event, field, i)"
                      [data]="field.options" [display]="field.label" placeholder="Choose option">
                    </ps-auto-suggestion>

                  </ng-container>
                </ng-container>
                <ng-container *ngIf="field.field_type=='LABEL'">
                  {{insertLabel(field, i)}}
                  <ng-container>
                    <p style="font-size: small" [innerHTML]="field.options[0].name"></p>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <div class="buttons">
            <input type="button" class="button btn_gray" *ngIf="this.index!=0" value="Back"
              (click)="activeForm = activeForm - 1;index=index-1;showContent(index)">
            <input type="button" class="button btn_blue" *ngIf="this.index!=addVisitorActionsFields.length-1"
              value="Next" (click)="activeForm = activeForm + 1;index=index+1;formVisitorDataJson(index-1,false);">
            <input type="button" class="button btn_green" *ngIf="this.index==addVisitorActionsFields.length-1"
              (click)="formVisitorDataJson(index,true);storeVisitorDetails(this.index,false,true);showId=true"
              value="Submit">
            <input type="button" class="button btn_red" *ngIf="this.index==addVisitorActionsFields.length-1"
              value="Reset" (click)="resetStepper()">
          </div>
        </form>
      </div>
    </div>
    <div class="visitor_card_container" *ngIf="showId==true">
      <div class="col-md-9 visitor_card_sec">
        <div class="visitor_card" id="visitorcard">
          <div class="card_design">
            <div class="card_top">
              <h5>VISITOR CARD</h5>
            </div>
            <!-- {{storeFieldName()}} -->
            <div class="card_sec">
              <div class="card_left">
                <img *ngIf="capturedImages" style="padding: 0px;" [src]="capturedImages['v_img_url'].imageAsDataUrl" />
                <img *ngIf="!capturedImages" src="assets/images/visitor_img.png">
              </div>

              <div class="card_right">
                <div class="data_line">
                  <h6>Name:</h6>
                  <p>{{visitorName}}</p>
                </div>
                <div class="data_line">
                  <h6>Mobile:</h6>
                  <p>{{visitorMobileNo}}</p>
                </div>
                <div class="data_line">
                  <h6>Meet With:</h6>
                  <p>{{meetWith}}</p>
                </div>
                <div class="data_line">
                  <h6>Purpose:</h6>
                  <p>{{visitorMeetingPurpose}}</p>
                </div>
              </div>
            </div>

            <div class="card_bottom">
              <p>VID:- {{orderId}}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn-secondary"><i class="fa fa-print"></i> PRINT VISITOR CARD</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #rejectContent let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Next Stage</h6>
    <div aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-times"></i></div>
  </div>
  <div class="row modal-body" id="boxes">
    <div class="stage_boxx">
      <div class="stageBox" *ngFor="let stage of this.selectedActionData.nextRejectStage; let i=index">

        <div class="stageName"
          [style.background-color]="stage.stage_id==selectedRejectedNextStageId? '#696262' : '#b4b4b4'"
          (click)="selectedRejectedNextStageId=this.selectedActionData.stage_id">{{stage.stage_name}}</div>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="nextRejectFlowStage(modal)">Reject</button>
  </div>
</ng-template>


<ng-template #approvecontent let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Next Stage</h6>
    <div aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-times"></i></div>
  </div>
  <div class="row modal-body" id="boxes">
    <div class="stage_boxx">
      <div class="stageBox" *ngFor="let stage of this.selectedActionData.nextStages let i=index">

        <div class="stageName" [style.background-color]="stage.stage_id==selectedNextStageId? '#696262' : '#b4b4b4'"
          (click)="selectedNextStageId=stage.stage_id">{{stage.stage_name}}</div>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="stageApproved(approvecontent)">Approve</button>
    <button type="button" class="btn btn-danger"
      (click)="modal.dismiss('Cross click');rejectStage(rejectContent)">Reject</button>
  </div>
</ng-template>

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AddComplianceModalComponent } from 'src/app/modals/add-compliance-modal/add-compliance-modal.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent implements OnInit {

  @Input() refData : any = {}

  constructor( private api: ApiService,
    private modalService: NgbModal,
    public table: TableService,
    public userService: UserService,
    private common: CommonService,
    public alertService: AlertService,
    public breadcrumbService: BreadcrumbService,
    private user: UserService) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!this.refData)
    {
      this.complainceAlertLog= null;
    }

      if(this.refData?.visitor_status!=null)
      {
        this.IN=this.refData.visitor_status=='IN'?true: false
      }

      if(this.refData && 'driverId' in this.refData)
      {
        this.IN= true
        console.log("driverId");
      }

      if(this.refData)
      this.getComplianceAlertLogs();
  }


  complainceAlertLog : any
  IN : boolean= false
  getComplianceAlertLogs(){

    console.log('gcal')
    this.common.loading = true;

    this.api.get('compliance/get-complaince-alert-logs',{txnId:this.refData.txnId}).subscribe(
      (res: any) => {
        if (res.status) {
          this.complainceAlertLog = res.data;
        }
        this.common.loading = false;

      },
      (err) => {
        this.common.loading = false;
      }
    );
  }

  view(row?: any) {

    let obj = {
      'Driver Name': row.log_data.driverName,
      'Transporter Name':  row.log_data.transporterName,
      'Vehicle Number':  row.log_data.vehicleRsn,
      'Stage Name': row.log_data.stageName,
      'Process Name': row.log_data.processName,
      'Remarks': row.log_data.remark,
      'Created By': row.created_by_name,
      'TAT(Actual/Expected)': row.log_data.tat?(row.log_data.tat+' / '+row.log_data.expectedTat):null,
      'Doc Expiry Date': row.expiryDate,
      'Document Type': row.documentType,
      'Created At':row.modified_at,
      'Visitor Name' : row.log_data.name,
    }

    this.common.params = {
      details: [obj],
      title: row.log_type + ' Details',
      imgUrl:(row.log_type=='compliance'?row.log_data.imageFile:null)
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  saveComplianceLog(row){
    let params = {
      logId:row.log_id,
    }
    console.log("par",params)
    this.common.loading = true;
    this.api.post('compliance/save_compliance',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertService.success(res.message);
        }
        this.common.loading = false;
      },
      (err) => {
        this.common.loading = false;
        console.log("Error: " + err);
      }
    );
  }

  addCompliance(){
    const activeModal = this.modalService.open(AddComplianceModalComponent, {
      size: 'md',
    });
    activeModal.componentInstance.refData= this.refData
    console.log("user dashboard",activeModal.componentInstance.refData);
    activeModal.result.then(() => {
      this.getComplianceAlertLogs();
    });
  }

  tripModal(vehicle) {

    console.log("vehicle")
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.prev_txn_id,
      processName: vehicle.prev_process,
      processId: vehicle.prev_process_id,
    };
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { StageService } from '../stage/stage.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-assign-user-modal',
  templateUrl: './assign-user-modal.component.html',
  styleUrls: ['./assign-user-modal.component.scss']
})
export class AssignUserModalComponent implements OnInit {
  stagelist: any[] = [];
  selectedStageId:any=null;
  selectedStageName:any = null;
  shiftUsersList:any[] = [];
  selectedUserIds:any[]=[];
  selectedUserNames:any[]=[];
  stageValidator:any=null;
usersValidator:any=null;


  @Input() public refData: any;
  constructor(
    public activeModal: NgbActiveModal,
    private api: ApiService,
    public common: CommonService,
    public stageService: StageService,
    public alert: AlertService
  ) {}

  ngOnInit() {
    this.getStageByProcessId(this.refData.processId);
  }

  selectedStage(event:any){
    this.selectedStageId= event.stageId;
    this.selectedStageName = event.stageName;
    if(this.refData.assignedUserId!=null){
      this.getNextShiftUsersBySelectedStage(this.selectedStageId);
    }else{
      this.getShiftUsersBySelectedStage(this.selectedStageId);
    }

  }

  selectedUser(event:any){
    this.selectedUserIds = event.map(e => e.user_id);
  }

  getStageByProcessId(id?: any) {
    this.common.loading = true;
    let params = {
      processId: id,
    }
    this.stageService.getStageByProcessAndPlant(params).toPromise()
      .then((res: any) => {
        this.common.loading = false;
        this.stagelist = res.data.map(stage =>{
          stage.stageName = stage.stage_name;
          stage.stageId = stage.stage_id;
          return stage;
        });
        this.selectedStage({"stageId":this.refData.stageId,"stageName":this.refData.stageName})
      }, (err: any) => {
        console.error('Error', err);
        this.common.loading = false;

      });
  }

  getShiftUsersBySelectedStage(stageId?: any) {
    this.common.loading = true;
    let params = {
      stageId: stageId,
    }
    this.api.get('shift/get-shift-users-by-stageId',params).subscribe((res: any) => {
        this.common.loading = false;
        this.shiftUsersList=res.data || [];
      }, (err: any) => {
        console.error('Error', err);
        this.common.loading = false;

      });
  }

  getNextShiftUsersBySelectedStage(stageId?: any) {
    this.common.loading = true;
    let params = {
      stageId: stageId,
    }
    this.api.get('shift/get-next-shift-users-by-stageId',params).subscribe((res: any) => {
        this.common.loading = false;
        this.shiftUsersList=res.data || [];
      }, (err: any) => {
        console.error('Error', err);
        this.common.loading = false;

      });
  }

  saveData(){
    this.common.loading = true;
    let params = {
      txnIds: [this.refData.txnId],
      stageId: this.selectedStageId,
      userIds: this.selectedUserIds,
      logOutFlag: this.refData.assignedUserId!=null ? true :false,
      assignedUserId:this.refData.assignedUserId,
    }

    this.api.post('dashboard/assign-user-for-trip',params)
      .subscribe((res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alert.success(res.message)
          let sameStage = false;
          sameStage = this.refData.assignedUserId==null ? this.selectedStageId==this.refData.stageId : sameStage
          this.closeModal(true,1,sameStage);
        }
        else{
          this.alert.error(res.message);
        }
      }, (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      });
  }

  closeModal(response?: any, apiHit?: any, sameStage?:boolean) {
    this.activeModal.close({ response: response, apiHit: apiHit,sameStage:sameStage });
  }



formValidator(){
  this.stageValidator = this.common.nullValidator(this.selectedStageId, "Stage Mandatory");
  this.usersValidator = this.common.listValidator(this.selectedUserIds, 'User Mandatory');
  if (!this.stageValidator.error && !this.usersValidator.error) {
    this.saveData();
  }
}
}

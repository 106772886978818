<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title text-uppercase font-weight-bold">{{this.refData.userType + ' Tracking'|customTranslate}}</h4>
</div>
<div class="tab_section">
  <ng-container *ngFor="let tabitem of tabData">
    <div *ngIf="(this.refData.userType == 'ASSET' && tabitem.value != 'user_productivity') || (this.refData.userType != 'ASSET' && tabitem.value != 'asset_view')" [class.active]="tabValue == tabitem.value"  class="tab" style="margin-right:5px">
      <p (click)="tabValue = tabitem.value;changeTab()">{{tabitem.tabname|customTranslate}}</p>
    </div>
  </ng-container>

</div>
<div [class.modal-body]="true">
  <div class="container-fluid">
    <div class="container-card">
      <div class="container-card-body">
        <div class="card">
          <div class="card-body" *ngIf="tabValue=='user_trail'">
            <section class="timeline_area section_padding_130">
              <div class="container">
                <div class="row">
                  <div class="main-timeline-area">
                    <div class="single-timeline-area" *ngFor="let data of userLocationLog;let i=index;let last = last"
                      [style]="'--border-color:'+'#5ba8ff'+';--color:'+'#c4e8ff'+'85'">
                      <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s"
                        style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                        <button class="button-45" role="button" style="background: #c4e8ff;">{{data.zone_name}}</button>
                      </div>
                      <div class="row" style="margin-left:20px">
                        <div class="col-12">
                          <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s"
                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                            <div class="timeline-text" style="display: flex;flex-direction: column;width:100%">
                              <div
                                style="display: flex;width: 100%;justify-content: space-between;margin-bottom: 10px;">
                                <span style="font-size: 14px;margin-bottom: 4px;font-weight: 600;">Stage :
                                  {{data.stage_name}}</span>
                                <span *ngIf="this.refData.userType != 'ASSET'"
                                  [ngClass]="{'early':data.zone_allocation == 'assigned','delay':data.zone_allocation != 'assigned'}"
                                  style="text-transform: capitalize;"> {{data.zone_allocation}}</span>
                              </div>
                              <div style="display: flex;width: 100%;justify-content: space-between;">
                                <div style="font-size:12px">
                                  <span>In Time : <span class="info-jellybean"
                                      style="padding:2px 0.8em !important">{{data.start_time}} </span></span>
                                  <span style='margin-left:10px'>Out Time : <span class="info-jellybean"
                                      style="padding:2px 0.8em !important">{{data.end_time}} </span></span>
                                </div>
                                <span class="info-jellybean-2" style="padding:1.5px 0.8em !important"><i
                                    class="fa fa-clock-o mr-2"></i>{{data.user_tat}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="card- p-0" *ngIf="tabValue=='user_productivity' && refData.type!='ASSET'">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Zone Name</th>
                  <th>Stage Name</th>
                  <th>Zone Allocation</th>
                  <th>First Ping</th>
                  <th>Last Ping</th>
                  <th>Total Hours</th>
                  <th>Productivity %</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let user of userProductivityLog;let i=index">
                  <tr>
                    <td>{{user.zone_name}}</td>
                    <td>{{user.stage_name}}</td>
                    <td><span
                        [ngClass]="{'early':user.zone_allocation == 'assigned','delay':user.zone_allocation != 'assigned'}"
                        style="text-transform: capitalize;">{{user.zone_allocation}}</span></td>
                    <td><span>{{user.first_ping}}</span></td>
                    <td><span>{{user.last_ping}}</span></td>
                    <td style="text-align: center;"><span class="info-jellybean"
                        style="padding:2px 0.8em !important">{{user.productive_hours}}</span></td>
                    <td style="text-align: center;"><span
                        [ngClass]="{'green-jellybean':user.productivity_percentage != 0,'red-jellybean':user.productivity_percentage == 0}"
                        class="green-jellybean">{{user.productivity_percentage | number: '.0-2'}} %</span></td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="card- p-0" *ngIf="tabValue=='user_path_tracking'">
            <app-user_path_tracking [extEntityId]="this.refData.userId"
              [extEntityType]="this.refData.userType" [extLogDate]="this.refData.logDate"></app-user_path_tracking>
          </div>
          <div class="card- p-0" *ngIf="tabValue=='asset_view'" (click)="stopevent($event)">
            <app-asset-view [assetId]="this.refData.userId"></app-asset-view>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

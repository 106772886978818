<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Overriding Actions Permission" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="user" class="required"> {{"select_user:" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedUser($event)" [data]="userData" display="name" id="user"
                    placeholder="Choose User" [preSelected]="{name:overridingData.userName}">

                  </ps-auto-suggestion>
                  <div class="error" *ngIf="userValidator && userValidator.error">
                    <span>{{userValidator.msg}} </span>
                  </div>

                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="process"> {{"select_process" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="processList" display="processName"
                    id="process" placeholder="Choose Process" [preSelected]="{processName:overridingData.processName}">
                  </ps-auto-suggestion>

                </div>
                <div *ngIf="overridingData.processId!=null" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="stage"> {{"select_stage:" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedStage($event)" [data]="stageList" display="stageName"
                    id="stageName" placeholder="Choose Stage" [preSelected]="{stageName:overridingData.stageName}">
                  </ps-auto-suggestion>

                </div>
                <div *ngIf="overridingData.processId!=null && overridingData.stageId!=null && editFlag==0"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="action" class="required"> {{("select" | customTranslate)+" "+("action" | customTranslate)}} </label>
                  <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedAction($event)"
                    [data]="actionsByStageList" display="actionName" id="action" placeholder="Choose Action">
                  </ps-auto-suggestion>

                </div>
                <div *ngIf="overridingData.processId!=null && overridingData.stageId!=null && editFlag==1"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="action" class="required"> {{("select" | customTranslate)+" "+("action" | customTranslate)}} </label>
                  <ps-auto-suggestion (onSelected)="selectedAction($event)" [data]="actionsByStageList"
                    display="actionName" id="action" placeholder="Choose Action"
                    [preSelected]="{actionName:actionNameAfterEdit}">
                  </ps-auto-suggestion>

                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-warning" (click)="resetDetail()"> {{"reset" | customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidations()"> {{(btn | lowercase) |
                    customTranslate}} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"overriding_actions_permission_list" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{("s.no." | customTranslate)}}</th>
                    <th>{{("user" | customTranslate)+" "+("name" | customTranslate)}}</th>
                    <th>{{("process" | customTranslate)+" "+("name" | customTranslate)}}</th>
                    <th>{{("stage" | customTranslate)+" "+("name" | customTranslate)}}</th>
                    <th>{{("action" | customTranslate)+" "+("name" | customTranslate)}}</th>
                    <th class="action">{{("action" | customTranslate)}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of overridingActionList, let i = index">
                    <td>{{i+1}}</td>
                    <td>{{row.userName}}</td>
                    <td>{{row.processName? row.processName: "All"}}</td>
                    <td>{{row.stageName?row.stageName: "All"}}</td>
                    <td>{{row.actionName?row.actionName: "All"}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewDetails(row)"
                          *ngIf="userService.permissionMap.get('view_overriding_actions_access')==true"> {{"view" |
                          customTranslate}} </button>
                        <button class="btn btn-success" (click)="editDetails(row)"
                          *ngIf="userService.permissionMap.get('edit_overriding_actions_access')==true"> {{"edit" |
                          customTranslate}} </button>
                        <button class="btn btn-danger" (click)="confirmAlert(row)"
                          *ngIf="userService.permissionMap.get('delete_overriding_actions_access')==true"> {{"delete" |
                          customTranslate}} </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Base64ToFileService {
  constructor() { }

  base64toFile(base64ImageData: string, filename: string): File {
    const base64Data =base64ImageData.includes(',')?base64ImageData.split(',')[1]:base64ImageData;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'image/jpeg' });
    return new File([blob], filename, { type: 'image/jpeg' });
  }
}


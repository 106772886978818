<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body process-form">

      <div class="row">
        <div class="col-md-12">
          <div class="card" *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Ban Transporter'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body" *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input" class="required">{{"Restriction Scope" | customTranslate}}</label>
                  <select name="status_scope" id="#status_scope" class="form-control"
                    [(ngModel)]="restrictionScope">
                    <option selected="GLOBAL" value="GLOBAL">GLOBAL</option>
                    <option value="COMPANY">COMPANY</option>
                    <option value="PLANT">PLANT</option>
                  </select>
                </div>

                  <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="restrictionScope == 'COMPANY' || restrictionScope == 'PLANT'">
                      <label for="my-input">{{"company" | customTranslate}}</label>
                      <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="companyList" display="companyName"
                        placeholder="Select Company" [preSelected]="{companyName:companyName}">
                      </ps-auto-suggestion>
                    </div>

                  <div *ngIf="restrictionScope == 'PLANT' || this.role.user_role == 'COMPANYADMIN'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                    <label for="my-input">{{"plant" | customTranslate}}</label>
                    <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantList"
                      display="plantName" placeholder="Select Plant" [preSelected]="{plantName:plantName}">
                    </ps-auto-suggestion>
                  </div>

                <!-- <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"transporter" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedTransporter($event)" class="required" [data]="trasporterList"
                    display="transporterName" placeholder="Select Transporter" [preSelected]="{transporterName:transporterName}">
                  </ps-auto-suggestion>
                </div> -->
              </div>

              <!-- <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)=" common.backClicked()"> {{'back'|customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="reset()"> {{'reset'|customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="save()"> {{'Ban'|customTranslate}}
                  </button>
                </div>
              </div> -->
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Transporters List'|customTranslate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{'serial_no'|customTranslate}}</th>
                    <th>{{'Transpoter Name'|customTranslate}}</th>
                    <th>{{'Transporter Phone'|customTranslate}}</th>
                    <th>{{'Status'|customTranslate}}</th>
                    <th class="action">{{'Actions'|customTranslate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of bannedTransporters let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.transporterName}}</td>
                    <td>{{row.transporterPhone}}</td>
                    <td><span class="pill" [ngClass]="{'bg-green-200 text-green-800':row.status== 'notBanned' , 'bg-blue-100 text-blue-800':row.status == 'plantLevel','bg-red-200 text-red-800':row.status=='globalLevel','bg-yellow-200 text-yellow-800':row.status=='companyLevel'}">
                      {{row.status == 'plantLevel' ? 'Plant Level Banned' : row.status == 'companyLevel' ? 'Company Level Banned' : row.status == 'globalLevel' ? 'Globally Banned' : 'Not Banned'}}
                    </span></td>
                    <!-- <td>{{row.status == 'plantLevel' ? 'Plant Level Banned' : row.status == 'companyLevel' ? 'Company Level Banned' : row.status == 'globalLevel' ? 'Globally Banned' : 'Not Banned'}}</td> -->
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn" style="padding: 4px 8px 4px 8px!important;background-color:red;" *ngIf="row.status == 'notBanned'" (click)="banTransporter(row.id,'ban',row.transporterName)"> {{'Ban'|customTranslate}} </button>
                        <button class="btn" style="padding: 4px 8px 4px 8px!important;background-color:green;" *ngIf="(role.user_role=='AXESTRACK' && row.status != 'notBanned') ||
                        (role.user_role == 'COMPANYADMIN' && (row.status=='companyLevel' ||  row.status=='plantLevel')) || (plantLevelRoles.includes(role.user_role)
                         && row.status == 'plantLevel') " (click)="banTransporter(row.id,'unban',row.transporterName)">
                          {{'Unban'|customTranslate}} </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div id="printview" class="container-fluid" >
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Checklist'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body" style="background: #f0f0f0;">
              <div [class]="modal?'view_checklist_page view-page':'view_checklist_page'">
                <div class="border_class">
                  <div class="page_name">
                    <h3>{{checklist_title}}</h3>
                    <p>{{checklist_description}}</p>
                  </div>

                  <ng-container *ngIf="actionsAndFields.length!=0 && !common.loading;else notFoundData">
                  <div class="field_sec" *ngFor="let data of actionsAndFields">
                    <ng-container *ngIf="showAction(data)">
                      <div class="field_title"
                        [hidden]="data.action_operation_type=='boolean' && data.sel_value=='PENDING'">
                        {{data.action_name}}
                      </div>

                      <div class="field_content">
                        <ng-container *ngIf="data.action_operation_type=='form'">
                          <div class="row field_row" *ngFor="let item of data.fields">
                            <!-- <ng-container *ngIf="item.hasOwnProperty('sel_value')"> -->
                              <div class="col-md-4" style=" background-color: #e7e7e7;">
                                <div class="head">{{item.field_name}}</div>
                              </div>
                              <div class="col-md-8"
                                *ngIf="item.field_type=='CHECKBOX'||item.field_type=='SELECTLISTMULTIPLE'">
                                <div class="value"><span>{{ formatNames(item.sel_value) }} </span></div>
                              </div>
                              <div class="col-md-8" *ngIf="item.field_type=='LABEL'">
                                <div class="value">
                                  <p [innerHTML]="item.sel_value"></p>
                                </div>
                              </div>
                              <div class="col-md-8"
                                *ngIf="item.field_type=='FILE'||item.field_type=='RFIDCODE'||item.field_type=='RFIDCODEWITHIMAGE'||item.field_type=='BARCODEWITHIMAGE'||item.field_type=='BARCODE'||item.field_type=='GEOCODEDIMAGE'">
                                <div class="value">{{item.sel_value.length==0?'--': imageName(item.sel_value[0].fileUrl)}}
                                  <ng-container *ngIf="item.sel_value.length!=0 && (image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg')">
                                    <div class="image" (click)="displayImage(item.sel_value[0].fileUrl)">
                                      <img [src]="item.sel_value[0].fileUrl" alt="" style="width: 150px;">
                                    </div>
                                    <div style="font-size:small">{{" "+this.image_name}}</div>
                                  </ng-container>
                                  <ng-container *ngIf="item.sel_value.length!=0 && image_ext!='' && image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                                    <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                    text-decoration-line: underline;" (click)="newTab(item.sel_value[0].fileUrl)">{{"
                                      "+this.image_name}}</span>
                                  </ng-container>
                                </div>
                              </div>
                              <div class="col-md-8" *ngIf="item.field_type=='DATETIMEPICKER' || item.field_type=='DATEPICKER'"><div class="value">{{item.sel_value.length==0?'--':(item.sel_value| date:'dd-MM-yyyy')}}</div></div>
                              <div class="col-md-8"
                                *ngIf="item.field_type!='CHECKBOX'&& item.field_type!='SELECTLISTMULTIPLE' && item.field_type!='FILE' && item.field_type!='LABEL' && data.action_operation_type=='form'&& item.field_type!='RFIDCODE'&& item.field_type!='RFIDCODEWITHIMAGE'&& item.field_type!='BARCODEWITHIMAGE'&& item.field_type!='BARCODE' && item.field_type!='GEOCODEDIMAGE' && item.field_type!='DATEPICKER' && item.field_type!='DATETIMEPICKER'">
                                <div class="value">{{getSelValue(item.sel_value)}}</div>
                              </div>

                            <!-- </ng-container> -->
                          </div>
                          <ng-container *ngIf="data.fields.length==0 ">
                            <div class="row field_row">
                              <div class="head" style="color: red;">No fields exist for the action !!</div>
                            </div>
                          </ng-container>

                        </ng-container>
                        <ng-container *ngIf="data.action_operation_type=='boolean' && data.sel_value!='PENDING'">
                          <div class="row field_row">
                            <div class="col-md-4" style=" background-color: #e7e7e7;">
                              <div class="head">{{'User Response'}}</div>
                            </div>
                            <div class="col-md-8">
                              <div class="value">{{data.sel_value}}</div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>

                  </div>

                  <div class="signature_print_bottom">
                    <div class="signature">
                      <ng-container>
                        <div class="image" (click)="displayImage(actionsAndFields[0].user_signature)">
                          <img [src]="actionsAndFields[0].user_signature" alt="">
                        </div>
                      </ng-container>
                    </div>
                    <p class="performed_at">{{actionsAndFields[0].addtime}}</p>
                    <button class="print-button" (click)="printPage()">{{"print"|customTranslate}}</button>
                  </div>
                </ng-container>
                <ng-template #notFoundData><span class="head" style="color:red">No Data found !!</span></ng-template>

                  <div class="clearfix"></div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';


@Component({
  selector: 'app-seal-report-alert-modal',
  templateUrl: './seal-report-alert-modal.component.html',
  styleUrls: ['./seal-report-alert-modal.component.scss']
})
export class SealReportAlertModalComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  isdisable = false;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'ViewDetails Table');

  @Input() public refData;

  constructor(
    public table: TableService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  closeModal(res) {
    this.activeModal.close(res);
  }

}

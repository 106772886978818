<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'App Version Check'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'select_company'|customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectCompany($event)" [data]="companyData" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                </div>
                <div class="col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'select_plant'|customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectPlant($event)" [data]="plantList" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                </div>
                <div class="col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'select_username:'|customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedUser($event)" [data]="allUsers" display="name"
                    placeholder="Choose User" [preSelected]="{name:userName}">
                  </ps-auto-suggestion>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="header-block">
              <h3 class="card-title">
                {{('App Version Check'|customTranslate)+' '+('list'|customTranslate)}}
              </h3>
            </div>
          </div>

          <div class="card-body">
            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead class="thead-light">
                <tr>
                  <th>{{'serial_no'|customTranslate}}</th>
                  <th>{{'user_id'|customTranslate}}</th>
                  <th>{{'username'|customTranslate}}</th>
                  <th>{{'current_version'|customTranslate}}</th>
                  <th>{{'updated_version'|customTranslate}}</th>
                  <th>{{'last_updated_verson'|customTranslate}}</th>
                  <th>{{'force_update'|customTranslate}}</th>
                  <th class="action"> {{'Actions'|customTranslate}} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor=" let item of usersList; let i = index">
                  <td>{{i+1}}</td>
                  <td>{{item.userId}}</td>
                  <td>{{item.userName}}</td>
                  <td>{{item.currentApkVersion}}</td>
                  <td> <ps-auto-suggestion (onSelected)="selectVersion($event,item)" [data]="versionsList"
                      display="appVersion" placeholder="Choose version"
                      [preSelected]="{appVersion:(item.updatedVersion)}">
                    </ps-auto-suggestion></td>
                  <td>{{item.lastApkUpdate|date:'short'}}</td>
                  <td><label class="switch">
                      <input type="checkbox" [checked]="item.forceApkUpdate"
                        (change)="item.forceApkUpdate=!item.forceApkUpdate">
                      <span class="slider round"></span>
                    </label></td>
                  <td class="action">
                    <div class="btn-block">
                      <button class="btn btn-primary"
                        (click)="updateVersion(item)">{{'updated_version'|customTranslate}}</button>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"tmg_dashboard" | translate}}
            </h3>
          </div>
        </div>
        <div class="card-body TMG-dashboard">
          <mat-tab-group animationDuration="0" class="dashboard-tabs" (selectedTabChange)="dashboardChange($event)">
            <mat-tab [label]="item.name | translate" *ngFor="let item of dashboard;index as i">
              <ng-template matTabContent>
                <ng-container *ngIf="item.name == 'Process Dashboard'">
                  <app-vehicle-dashboard *ngIf="item.name == 'Process Dashboard'"></app-vehicle-dashboard>
                </ng-container>
                <ng-container *ngIf="item.name != 'Process Dashboard'">
                  <mat-tab-group animationDuration="0" class="processTabs" (selectedTabChange)="processChange($event)"
                    [(selectedIndex)]="tmgdashboard.currentProcessIndex">
                    <mat-tab [label]="process.processName+' - Process'" *ngFor="let process of Allprocess">
                      <ng-template matTabContent>
                        <!-- <ng-template #dashboard></ng-template> -->

                        <app-live-dashboard *ngIf="item.name == 'Live Dashboard'"></app-live-dashboard>
                        <app-trip-dashboard *ngIf="item.name == 'Trip Dashboard'"></app-trip-dashboard>
                        <app-stage-dashboard *ngIf="item.name == 'Stage Dashboard'"></app-stage-dashboard>
                      </ng-template>
                    </mat-tab>
                  </mat-tab-group>

                </ng-container>
              </ng-template>

            </mat-tab>
          </mat-tab-group>
        </div>

      </div>

    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input" class="required">{{"Status Scope" | customTranslate}}</label>
                  <select name="status_scope" id="#status_scope" class="form-control"
                    [(ngModel)]="statusScope">
                    <option selected="GLOBAL" value="GLOBAL">GLOBAL</option>
                    <option value="COMPANY">COMPANY</option>
                    <option value="PLANT">PLANT</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'COMPANYADMIN'">
                  <label for="my-input" class="required">{{"Status Scope" | customTranslate}}</label>
                  <select name="status_scope" id="#status_scope" class="form-control"
                    [(ngModel)]="statusScope">
                    <option selected="COMPANY" value="COMPANY">COMPANY</option>
                    <option value="PLANT">PLANT</option>
                  </select>
                </div>

                <div *ngIf="role.user_role == 'AXESTRACK' && statusScope != 'GLOBAL'"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"company" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyList"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyValidator && companyValidator.error">
                    <span>{{companyValidator.msg}} </span>
                  </div>
                </div>

                <div *ngIf="statusScope == 'PLANT'"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantList"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantValidator && plantValidator.error">
                    <span>{{plantValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"Status Name" | customTranslate}}</label>
                  <input type="text" class="form-control reqi" name="statusName" required placeholder="Enter Status Name"
                    [(ngModel)]="status.statusName" #firstName="ngModel" autocomplete="off">
                    <div class="error" *ngIf="statusNameValidator && statusNameValidator.error">
                      <span>{{statusNameValidator.msg}} </span>
                    </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"Status Description" | customTranslate}}</label>
                  <input type="text" class="form-control" name="description" placeholder="Enter Status Description"
                    [(ngModel)]="status.description" #name="ngModel" autocomplete="off">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"Status Type" | customTranslate}}</label>
                  <select name="gender" id="#gender" class="form-control"
                    [(ngModel)]="status.statusType">
                    <option selected="Asset" value="Asset">Asset</option>
                    <option value="People">People</option>
                    <option value="Department">Department</option>
                    <option value="Asset Maintenance">Asset Maintenance</option>
                  </select>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"status" | customTranslate}}</label>
                  <select name="user_status" id="#user_status" class="form-control"
                    [(ngModel)]="status.status">
                    <option selected="ACTIVE" value="1">ACTIVE</option>
                    <option value="0">INACTIVE</option>
                  </select>
                </div>


                </div>


              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    customTranslate}}</button>
                  <button class="btn btn-warning" (click)="resetDetail()">{{"reset" | customTranslate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn}} </button>
                </div>
              </div>
            </div>

          </div>
        </div>




      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Status List" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{"s.no." | customTranslate}}</th>
                    <th>{{"status_name" | customTranslate}}</th>
                    <th>{{"description" | customTranslate}}</th>
                    <th>{{"status" | customTranslate}}</th>
                    <th>{{"status_type" | customTranslate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">{{"plant" | customTranslate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK'">{{"company" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of statusList; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{row.status_name}}</td>
                    <td>{{row.status_description}}</td>
                    <td>{{row.status == 1 ? 'ACTIVE' : 'INACTIVE'}}</td>
                    <td>{{row.status_type}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">{{row.plant_name}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK'">{{row.company_name}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewDetails(row)">
                          {{ "view" | customTranslate }}
                        </button>
                        <button class="btn btn-success" *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                        || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1))"
                         (click)="editRow(row)"> {{"edit" | customTranslate}}
                        </button>
                        <button class="btn btn-danger" *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                        || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1)) && row.userStatus!='DELETED'"
                        (click)="confirmAlert(row)">{{"delete"| customTranslate}}</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

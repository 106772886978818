<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{hardwareTitle | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="hardwareName" class="required">{{"hardware_name" | customTranslate}} </label>
                  <input class="form-control" type="text" name="hardwareName" id="hardwareName" required
                    [(ngModel)]="Hardware.hardwareName" #name="ngModel" placeholder="Enter Hardware Name">
                  <div class="error" *ngIf="hardwareNameValidator && hardwareNameValidator.error">
                    <span>{{hardwareNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"hardware_description" | customTranslate}} </label>
                  <input class="form-control" type="text" name="hardwareDescription" id="hardwareDescription"
                    [(ngModel)]="Hardware.hardwareDescription" #name="ngModel" required
                    placeholder="Enter Hardware Description" autocomplete="off">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"hardware_company name" | customTranslate}} </label>
                  <input class="form-control" type="text" name="hardwareCompanyName" id="hardwareCompanyName"
                    [(ngModel)]="Hardware.hardwareCompanyName" #name="ngModel"
                    placeholder="Enter Hardware Company Name">
                  <div class="error" *ngIf="hardwareCompanyNameValidator && hardwareCompanyNameValidator.error">
                    <span>{{hardwareCompanyNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"ip_address" | customTranslate}} </label>
                  <input class="form-control" type="text" name="ipAddress" [(ngModel)]="Hardware.ipAddress"
                    #name="ngModel" placeholder="e.g 127.0.0.1">
                  <div class="error" *ngIf="ipAddressValidator && ipAddressValidator.error">
                    <span>{{ipAddressValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"status" | customTranslate}} </label>
                  <select name="status" id="#status" [(ngModel)]="Hardware.status" #name="ngModel" class="form-control"
                    placeholder="Select Hardware Status">
                    <option value="ACTIVE">{{"active" | customTranslate}} </option>
                    <option value="INACTIVE">{{"inactive" | customTranslate}} </option>
                    <option value="MAINTAINENCE">{{"maintainence" | customTranslate}} </option>
                  </select>
                  <div class="error" *ngIf="hardwareStatusValidator && hardwareStatusValidator.error">
                    <span>{{hardwareStatusValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"running_status" | customTranslate}} </label>
                  <select name="currentStatus" id="#currentStatus" [(ngModel)]="Hardware.hardwareCurrentStatus"
                    #name="ngModel" class="form-control">
                    <option value="STARTED">{{"started" | customTranslate}} </option>
                    <option value="STOPPED">{{"stopped" | customTranslate}} </option>
                    <option value="NONE">{{"none" | customTranslate}} </option>
                  </select>
                  <div class="error" *ngIf="hardwareCurrentStatusValidator && hardwareCurrentStatusValidator.error">
                    <span>{{hardwareStatusValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"port" | customTranslate}} </label>
                  <input type="text" class="form-control" name="port" placeholder="Enter Port"
                    [(ngModel)]="Hardware.port" #name="ngModel">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input"  class="required">{{"mac_address" | customTranslate}} </label>
                  <input class="form-control" type="text" name="macAddress" [(ngModel)]="Hardware.macAddress"
                    #name="ngModel" placeholder="e.g. abc.111.aaa.222">
                    <div class="error" *ngIf="hardwareMacAddress && hardwareMacAddress.error">
                      <span>{{hardwareMacAddress.msg}} </span>
                    </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"connection_id" | customTranslate}} </label>
                  <input class="form-control" type="text" name="connectionId" [(ngModel)]="Hardware.connectionId"
                    #name="ngModel" placeholder="Enter Connection Id">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"antenna_number" | customTranslate}} </label>
                  <input class="form-control" type="text" name="antennaNumber" [(ngModel)]="Hardware.antennaNumber"
                    #name="ngModel" placeholder="Enter Antenna Number">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"read_type" | customTranslate}} </label>
                  <select name="readType" id="#readType" [(ngModel)]="Hardware.readType" #name="ngModel"
                    class="form-control" placeholder="Select Read Type">
                    <option value="SINGLE">Single</option>
                    <option value="INVENTORY">Inventory</option>
                  </select>
                  <div class="error" *ngIf="readTypeValidator && readTypeValidator.error">
                    <span>{{readTypeValidator.msg}} </span>
                  </div>

                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"rssi" | customTranslate}} </label>
                  <input class="form-control" type="text" name="rssi" [(ngModel)]="Hardware.rssi" #name="ngModel"
                    placeholder="Enter RSSI">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"hardware_type" | customTranslate}} </label>
                  <select name="hardwareType" id="#hardwareType" class="form-control"
                    [(ngModel)]="Hardware.hardwareType" #name="ngModel" placeholder="Select Hardware Type">
                    <option selected="RFID_READER" value="RFID_READER">RFID Reader</option>
                    <option selected="ACTIVE_READER" value="ACTIVE_READER">Active Reader Controller</option>
                    <option selected="BLE_GATEWAY" value="BLE_GATEWAY">BLE Gateway</option>
                    <option value="WEIGHING_SENSOR">Weighing Sensor</option>
                    <option value="TRAFFIC_LIGHT">Traffic Light</option>
                    <option value="SPEAKER">Speaker</option>
                    <option value="DISPLAY">Display</option>
                    <option value="AT_CONTROLLER">AT Controller</option>
                    <option value="BOOM_BARRIER">Boom Barrier</option>
                    <option value="BIOMETRIC_CONTROLLER">Biometric Controller</option>
                  </select>
                  <div class="error" *ngIf="hardwareTypeValidator && hardwareTypeValidator.error">
                    <span>{{hardwareTypeValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"hardware_unique id" | customTranslate}}</label>
                  <input class="form-control" type="text" name="hardwareUniqueId"
                    [(ngModel)]="Hardware.hardwareUniqueId" #name="ngModel" placeholder="Enter Unique Id">
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"hardware_version" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectVersion($event)" [data]="verisonList" display="appVersion"
                    placeholder="Choose version" [preSelected]="{appVersion:(Hardware.version)}"></ps-auto-suggestion>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-muted" (click)="common.backClicked()"> {{"back" | customTranslate}} </button>
                  <button class="btn btn-warning" (click)="reset()"> {{"reset" | customTranslate}} </button>
                  <button type="submit" (click)="formValidator()" class="btn btn-submit"> {{btn | customTranslate}} </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="header-block">
              <h3 class="card-title">
                {{"hardware_list" | customTranslate}}
              </h3>
            </div>
          </div>

          <div class="card-body">
            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>{{"s.no." | customTranslate}}</th>
                  <th>{{"hardware_name" | customTranslate}}</th>
                  <th>{{"hardware_company name" | customTranslate}} </th>
                  <th>{{"ip_address" | customTranslate}}</th>
                  <th>{{"status" | customTranslate}}</th>
                  <th>{{"hardware_type" | customTranslate}}</th>
                  <th class="action"> {{"action" | customTranslate}} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor=" let item of HardwareData; let i = index">
                  <td>{{i+1}}</td>
                  <td>{{item.hardwareName}}</td>
                  <td>{{item.hardwareCompanyName}}</td>
                  <td>{{item.ipAddress}}</td>
                  <td>{{item.status}}</td>
                  <td>{{item.hardwareType}}</td>
                  <td class="action">
                    <div class="btn-block">
                      <button class="btn btn-primary" (click)="setActiveComponent(item)"> {{"view" | customTranslate}}
                      </button>
                      <button class="btn btn-success" (click)="editHardware(item)"> {{"edit" | customTranslate}} </button>
                      <button class="btn btn-danger" (click)="deleteHardware(item)"> {{"delete" | customTranslate}} </button>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"alert_title" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedAlert($event)" [data]="alerts" display="alertTitle"
                    placeholder="Choose Alert" [preSelected]="{alertTitle:alertTitle}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="alertTitleValidator && alertTitleValidator.error">
                    <span>{{alertTitleValidator.msg}} </span>
                  </div>
                </div>

              </div>

              <div class="form-group col-xl-12 col-lg-12 col-sm-12 p-0">
                <div class="field-selection">
                  <label for="my-input" class="required">{{"select_fields" | customTranslate}} </label>
                  <div *ngFor="let value of masterField.values; let i=index" class="mb-2">
                    <div class="row">
                      <div class="col-md-3">
                        <ps-auto-suggestion (onSelected)="selectedUser($event,i)" [data]="data" display="name"
                          placeholder="Choose User Name" [preSelected]="{name:masterField.values[i].name}">
                        </ps-auto-suggestion>
                        <div class="error" *ngIf="userValidator[i] && userValidator[i].error">
                          <span>{{ userValidator[i]?.msg }}</span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <select class="form-control" name="alertType" [(ngModel)]="masterField.values[i].alertType"
                          placeholder="select type">
                          <option *ngFor="let item of alertTypes" [value]="item.value">{{item.type | customTranslate}}
                          </option>
                        </select>
                        <div class="error" *ngIf="alertTypeValidator[i] && alertTypeValidator[i].error">
                          <span>{{ alertTypeValidator[i]?.msg }}</span>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="plus_minus_icon">
                          <span class="input-group-text remove-field" id="my-addon" *ngIf="i !== 0"
                            (click)="masterField.values.splice(i,1);this.processWiseShifts.splice(i,1);deleteMapping(value.mappingId)">
                            <i class="fa fa-trash remove"></i>
                          </span>
                          <span class="input-group-text add-field" id="my-addon"
                            *ngIf="i == masterField.values.length-1" (click)="addValues()">
                            <i class="fa fa-plus add"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="resetDetail()"> {{"reset" | customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}}
                  </button>
                </div>
              </div>
            </div>


          </div>


          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <div class="header-block">
                    <h3 class="card-title">
                      {{"list_of shifts" | customTranslate}}
                    </h3>
                  </div>
                </div>

                <div class="card-body">
                  <!-- table -->
                  <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                      <tr>
                        <th>{{"s.no." | customTranslate}}</th>
                        <th>{{"user_name" | customTranslate}}</th>
                        <th>{{"alert_title" | customTranslate}}</th>
                        <th>{{"Alert Type" | customTranslate}}</th>
                        <th class="action">{{"action" | customTranslate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of alertUserMappings; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{row.user_name}}</td>
                        <td>{{row.title}}</td>
                        <td>{{row.mapping_type}}</td>
                        <td class="action">
                          <div class="btn-block">
                            <button class="btn btn-primary" (click)="viewDetails(row)"> {{"view" | customTranslate}} </button>
                            <button class="btn btn-success" (click)="edit(row)"> {{"edit" | customTranslate}} </button>
                            <button class="btn btn-danger" (click)="confirmAlert(row.id)"> {{"delete" | customTranslate}}
                            </button>
                          </div>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>



        </div>
      </div>
    </div>

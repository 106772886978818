import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { TableService } from '../../@core/services/table.service';
import { UserService } from '../../@core/services/user.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { WidgetType, XSightsCoreService } from 'x-sights-core';
import { TripReportModalComponent } from 'src/app/modals/trip-report-modal/trip-report-modal.component';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { CompanyService } from '../companies/companies.service';
import * as XLSX from 'xlsx';
import * as filesaver from 'file-saver';
import _ from 'lodash';
import { COMMON_ACTION_BUTTON, COMMON_TRIPS_ACTION_BUTTON, TABLE_TYPE, TableActions, TableColumns } from 'src/app/dataType/customTableInterface';
import { TranslateService } from '@ngx-translate/core';
import { TripCommentsComponent } from 'src/app/modals/trip-comment-trail/trip-comments/trip-comments.component';

@Component({
  selector: 'app-trip-report',
  templateUrl: './trip-report.component.html',
  styleUrls: ['./trip-report.component.scss'],
})
export class TripReportComponent implements OnInit {
  // Datatable Configs
  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  loading: boolean = false;
  columns: TableColumns[] = [];
  data: any = [];

  tabvalue: any = 'table';
  graphtype: any;
  id: string;
  fromDate: any = new Date(new Date().setDate(new Date().getDate() - 7));
  toDate: any = new Date();
  allGraphIds: any = [464, 465, 466];
  @Input() public refData;
  plantData: any = [];
  plantId: number = null;
  plantName: string = null;
  companyId: any;
  companyName: any;
  companyList: any = [];
  tabData: any = [
    { tabname: 'Date Wise Trips', value: 'Date Wise Trips' },
    { tabname: 'All Trips', value: 'All Trips' },
  ];
  reportType: any = 'Date Wise Trips';

  constructor(
    private breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public alertService: AlertService,
    public userService: UserService,
    private tableService: TableService,
    private xsights: XSightsCoreService,
    private modalService: NgbModal,
    public plantService: PlantService,
    public role: RoleService,
    public companyService: CompanyService,
    private translateService: TranslateService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Trip Report', link: '/user/trip-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
    this.fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.toDate = new Date();
    this.fromDate = this.common.dateFormatter(
      this.fromDate,
      'YYYYMMDD',
      false
    );
    this.toDate = this.common.dateFormatter(this.toDate, 'YYYYMMDD', false);
  }

  ngAfterViewInit() {
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.plantId = this.userService._loggedInUser.plantId;
      this.getTripDetails();
    }
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        this.common.loading = false;
        res.data.forEach((element) => {
          if (element.companyStatus == 'ACTIVE') this.companyList.push(element);
        });
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    if (this.reportType != 'All Trips') {
      this.getTripDetails();
    }
  }

  drawGraphs() {
    this.common.loading = true;
    this.allGraphIds.forEach((graph) => {
      this.api
        .get(
          'graphs/get-graph-by-id',
          {
            graphId: graph,
          },
          'XSIGHT'
        )
        .toPromise()
        .then((res: any) => {
          const structureJ = JSON.parse(res.data[0].structure);
          let data = {
            graphId:
              this.refData != null
                ? 'secgraph-' + +res.data[0].id
                : 'graph-' + res.data[0].id,
            graphTitle: res.data[0].name,
            rows: structureJ.xAxis,
            columns: structureJ.yAxis,
            graphTypes: structureJ.chartType,
            graphData: this.data,
            aggregationFunctions: structureJ.aggreagations,
            filter: structureJ.filter,
            colors: structureJ.colColours,
            customVariable: structureJ.derivedVariables,
            dataFormat: [],
            lastLevelColumns: structureJ.lastLevelData,
          };
          this.xsights.build(WidgetType.GRAPH, data);
          this.common.loading = false;
        });
    });
  }

  reset() {
    this.fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.toDate = new Date();
    this.fromDate = this.common.dateFormatter(
      this.fromDate,
      'YYYYMMDD',
      false
    );
    this.toDate = this.common.dateFormatter(this.toDate, 'YYYYMMDD', false);
  }

  getTripDetails() {

    if (this.fromDate > this.toDate) {
      this.alertService.warn('Start Date Should be Smaller than End Date!!');
      return;
    }
    this.common.loading = true;
    let params = {
      startDate: this.common.dateFormatter(
        this.fromDate,
        'YYYYMMdd',
        false,
        '-'
      ),
      endDate: this.common.dateFormatter(this.toDate, 'YYYYMMdd', false, '-'),
      plantId: this.plantId,
    };

    this.api.get('report/get-datewise-trip-report', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.data = res.data.map(d => {
          let tripData = JSON.parse(d.trip_details);
          d = {
            ...d,
            trip_details: tripData,
            ..._.groupBy(tripData, "process_name")
          }
          return d;
        });
        console.log('this.data: ', this.data);
        this.columns = [
          {
            columnLabel: "Trip Date",
            columnName: "trip_date",
          },
          ...this.data[0].trip_details.map(el => ({
            columnLabel: el.process_name,
            columnName: "",
            extraData: el,
            columnFormatter: (column: TableColumns, index: number) => {
              return column.columnLabel + (column.extraData?.process_status != 'ACTIVE' ? (`<span class="badge badge-danger"> Inactive</span>`) : "")
            },
            dataFormatter: (row: any, column: TableColumns, index: number) => {
              return row[column.columnLabel][0].count;
            },
          } as TableColumns)) as TableColumns[],
          {
            columnLabel: this.translateService.instant("Action"),
            columnName: null,
            dataActions: [
              ...this.tableService.getTableActionButtons([
                COMMON_ACTION_BUTTON.VIEW
              ], (column: TableColumns, row: any, index: number) => {
                this.openModal(row);
              }, null, null , (column: TableColumns, row: any, index: number, actionName: string) => {
                return true
              })
            ] as TableActions[]
          }
        ]
        console.log('this.columns: ', this.columns);

      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;

      }
    );
  }

  openModal(row: any) {
    let activeModal = this.modalService.open(TripReportModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      modalView: true,
      fromDate: row.trip_date.split(" ") + ' 00:00:00',
      toDate: row.trip_date.split(" ") + ' 23:59:59',
      plantId: this.plantId,
    };
    activeModal.closed.subscribe((res: any) => { });
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    if (event.companyId) {
      this.getPlantById(event.companyId);
    }
    this.plantName = null;
    this.plantId = null;
  }

  changeTab(value) {
    this.reportType = value;
    if (this.reportType == 'All Trips') {
      this.fromDate = this.common.dateFormatter(
        this.fromDate,
        'YYYYMMDD',
        true
      );
      this.toDate = this.common.dateFormatter(this.toDate, 'YYYYMMDD', true);
    } else {
      this.fromDate = this.common.dateFormatter(
        this.fromDate,
        'YYYYMMDD',
        false
      );
      this.toDate = this.common.dateFormatter(this.toDate, 'YYYYMMDD', false);
    }

    if (this.reportType != 'All Trips') {
      this.getTripDetails();
    }
  }

  exportAsExcel(
    data: any[],
    fileName: string,
    allTrips: boolean = false
  ): void {
    let worksheet = null;
    let value = _.cloneDeep(data);
    if (!allTrips) {
      value.map((res) => {
        res['Trip Date'] = res.trip_date;
        res['Total Trips'] = res.trip_count;
        res.trip_details.forEach((element) => {
          res[element.process_name] = element.count;
        });
        delete res.trip_details;
        delete res.trip_date;
        delete res.trip_count;
      });
      worksheet = XLSX.utils.json_to_sheet(value);
    } else {
      let actionFailRemarks = JSON.parse(data[0].actionFailComments || '{}');
      let tripRejectComments = JSON.parse(data[0].tripRejectComments || '{}');
      // Create worksheet
      const filteredKeys = Object.keys(data[0]).filter(
        (key) => key !== 'actionFailComments' && key !== 'tripRejectComments'
      );
      const actionFailHeaders = Object.keys(actionFailRemarks).map(key => `${key} action failure remark`);
      const tripRejectHeaders = Object.keys(tripRejectComments).map(key => `${key} stage rejection remark`);
      worksheet = XLSX.utils.aoa_to_sheet([
        [
          ...filteredKeys,
          ...actionFailHeaders,
          ...tripRejectHeaders,
        ],
      ]);
      console.log('worksheet: ', worksheet);

      // Fill data
      data.forEach((rowData) => {
        const rowValues = [
          ...filteredKeys.map(key => rowData[key]),
          ...actionFailHeaders.map(header => {
            const key = header.replace(' Action Failure Remark', '');
            return JSON.parse(rowData.actionFailComments || '{}')[key] || '';
          }),
          ...tripRejectHeaders.map(header => {
            const key = header.replace(' Stage Rejection Remark', '');
            return JSON.parse(rowData.tripRejectComments || '{}')[key] || '';
          }),
        ];
        XLSX.utils.sheet_add_aoa(worksheet, [rowValues], { origin: -1 });
      });
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Trip Report');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    filesaver.saveAs(blob, fileName + '.xlsx');
  }


  searchAction(tabvalue) {
    if (this.reportType != 'All Trips') {
      this.getTripDetails();
    }
  }
}

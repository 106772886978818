<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="row p-3" *ngIf="role.user_role != 'PLANTADMIN' && role.user_role != 'PLANTUSER'">
              <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role != 'PLANTADMIN' && role.user_role != 'PLANTUSER'">
                <label for="my-input" class="required">{{"Restriction Scope" | translate}}</label>
                <select name="status_scope" id="#status_scope" class="form-control" [(ngModel)]="restrictionScope"
                  (change)="selectedRestriction($event)">
                  <option value="AXESTRACK" *ngIf="role.user_role == 'AXESTRACK'">AXESTRACK</option>
                  <option value="COMPANY" *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">COMPANY</option>
                  <option value="PLANT" *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">PLANT</option>
                </select>
              </div>

              <div *ngIf="role.user_role == 'AXESTRACK' && restrictionScope != 'AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input">{{"company" | translate}}</label>
                <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyList"
                  display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                </ps-auto-suggestion>
              </div>

              <div
                *ngIf="restrictionScope == 'PLANT' && role.user_role != 'PLANTADMIN' && role.user_role != 'PLANTUSER'"
                class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input">{{"plant" | translate}}</label>
                <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantList"
                  display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                </ps-auto-suggestion>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">

                <thead>
                  <tr>
                    <th>{{"s.no." | translate}}</th>
                    <th>{{"visitor_name" | translate}}</th>
                    <!-- <th>{{"License Number" | translate}}</th> -->
                    <th >{{"mobile_no." | translate}}</th>
                    <th >{{"appointment_count" | translate}}</th>
                    <th class="action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of VisitorList; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{row.visitor_name}}</td>
                    <td>{{row.mobile_number}}</td>
                    <td>{{row.appointment_count}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn" [disabled]="row.priority > priorityLevel" [style]="'padding: 4px 8px 4px 8px!important;background-color:'+(row.is_banned && row.priority <= priorityLevel ? 'green' :'red')" (click)="confirmAlert(row)">{{ row.is_banned ? (row.priority <= priorityLevel ? "UNBAN" : "Banned By Admin") : "BAN" | translate}}</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

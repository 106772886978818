import { CommonService } from 'src/app/@core/services/common.service';
import { Label, Color } from 'ng2-charts';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { DashboardService } from './../dashboard/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/@core/services/user.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { DatePipe } from '@angular/common';
import lodash from 'lodash';
import { EmployeeDetailModalComponent } from '../employee-detail-modal/employee-detail-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { Chart } from 'chart.js';

import highcharts3D from 'highcharts/highcharts-3d';
import { AlertCardModalComponent } from '../alert-card-modal/alert-card-modal.component';
import { DashboardModalComponent } from '../dashboard-modal/dashboard-modal.component';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
highcharts3D(Highcharts);

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: [
    '../dashboard/dashboard.component.scss',
    './user-dashboard.component.scss',
  ],
})
export class UserDashboardComponent implements OnInit {
  employeeCount: any = {};
  employeeViewData: any[] = [];
  dockData: any[] = [];
  public lineChartType: ChartType = 'line';
  vehicleData = null;
  transaction = null;
  vehicle = {
    id: null,
    name: '',
  };
  vehicleTransactionData = [];
  vehicleTransactionStatus = [];
  vehiclecompliancedata: any[] = [];
  allhardwares: any[] = [];
  showallhardwares: boolean = false;
  allfiltereddata: any[] = [];
  hardwarestatus: object = {};
  hardwaretypefilter: string[] = [];
  statusFilter: string[] = [];
  vehicleID: number = 0;
  vehiclelist: any[] = [];
  hardwarestage: number = 0;
  hardwaretype: number = 0;
  single: any[] = [];
  view: any[] = [700, 400];
  currentprocess: any;
  allComplete: boolean = false;
  allProcess: any = [];
  hardwareInfo: any[] = [];
  vehicletimeline: any[] = [];
  activehardewares: any[] = [];
  inactivehardwares: any[] = [];
  stagefilter: string[] = [];
  vendors: any[] = [];
  tripindex: number = 0;
  processIndex: number = 0;
  tripprocessindex: number = 0;
  processName: string = '';
  processId: number = 32;
  triprocessname = '';
  public selected = 32;
  defaultprocess: string = '';
  processTrips: any = [];

  // from here detail modal variables
  stageIdSlelected:number=null;
  dashboardParkingCardData: any[] = [];
  AllvehicleTransactionData = [];


  //for modal opeaning

  modalTitle:string='';
  tableModalData:any[]=[];



  public vehicleComplianceChartType: ChartType = 'line';
  public vehicleComplianceChartLegend = true;
  public vehicleComplianceChartPlugins = [];
  public barChartColors: Color[] = [
    { backgroundColor: this.common.graphsuccess },
    { backgroundColor: this.common.graphsecondary },
    { backgroundColor: this.common.graphdanger },
  ];
  loaderinfolen: number = 0;
  vehicleComplianceChartData: ChartDataSets[] = [];
  indication = [
    {
      id: 1,
      name: 'MAINTENANCE',
      className: 'danger',
    },
    {
      id: 2,
      name: 'FREE',
      className: 'success',
    },
  ];

  parkingSlotindication = [
    {
      id: 1,
      name: 'MAINTENANCE',
      className: 'danger',
    },
    {
      id: 2,
      name: 'FREE',
      className: 'success',
    },
    {
      id: 3,
      name: 'OCCUPIED',
      className: 'warning',
    },
  ];

  activeAlert: string = 'Critical';
  dockvalue: any = {};

  cAlertArray:any = [];

  hAlertArray = [
    {
      alertname: 'Process Alert',
      violation: 'Tat Above',
      status: 'InProgress',
      createdat: '29-05-2024 15:30PM',
    },
    {
      alertname: 'Process Alert',
      violation: 'Tat Above',
      status: 'Pending',
      createdat: '29-05-2024 15:30PM',
    },
    {
      alertname: 'Process Alert',
      violation: 'Tat Above',
      status: 'Pending',
      createdat: '29-05-2024 15:30PM',
    },
    {
      alertname: 'Process Alert',
      violation: 'Tat Above',
      status: 'Success',
      createdat: '29-05-2024 15:30PM',
    },
    {
      alertname: 'Process Alert',
      violation: 'Tat Above',
      status: 'InProgress',
      createdat: '29-05-2024 15:30PM',
    },
    {
      alertname: 'Process Alert',
      violation: 'Tat Above',
      status: 'Success',
      createdat: '29-05-2024 15:30PM',
    },
  ];

  parkingSlotProcess: number = null;
  parkingStates: any[] = [];
  parkingDefaultProcess: string = '';
  statusWiseStageVehicleCount: any = [];
  processIdSelected: number = null;
  processNameSelected: string = null;
  dayFilter: string = 'today';
  worstThreeStagesTatWise: any[] = [];
  worstDayFilter: string = 'last 7 days';
  worstStageProcessId: number = null;
  worstStageProcessName: string = null;
  worstTripProcessId: number = null;
  worstTripProcessName: string = null;
  worstTripsDayFilter: string = 'last 7 days';
  worstThreeTripsTatWise: any[] = [];
  // showNoRecord: boolean=false;
  public randomize(index: number): void {
    this.lineChartType = index == 0 ? 'bar' : 'line';
  }
  public barChartOptions: ChartOptions = {
    plugins: {
      datalabels: {
        formatter(value, context) {
          return epoch_to_hh_mm_ss(value);
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Avg TAT',
            fontSize: 12,
            fontStyle: 'bold',
            fontFamily: ' sans-serif',
          },

          //  time: {
          //   unit: 'minute',
          //   unitStepSize: 10,
          // },
          time: {
            unit: 'hour',
            displayFormats: {
              second: 'HH:mm',
              minute: 'HH:mm',
              hour: 'HH:mm',
            },
            // displayFormats: {"HH:mm"},
            tooltipFormat: 'HH:mm',
          },
          ticks: {
            callback: function (v) {
              return epoch_to_hh_mm_ss(v);
            },
            // stepSize: 30 * 120
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ': ' +
            epoch_to_hh_mm_ss(tooltipItem.yLabel)
          );
        },
      },
    },
  };

  public vehicleComplianceChartLabels: Label[] = [];
  // filters: any[] = ['today', 'last 7 days', 'last 30 days', 'last 365 days'];
  filters: any[] = [
    { filter: 'today' },
    { filter: 'last 7 days' },
    { filter: 'last 30 days' },
    { filter: 'last 365 days' },
  ];

  constructor(
    public workflow: DashboardService,
    public common: CommonService,
    public userService: UserService,
    public api: ApiService,
    private modalService: NgbModal
  ) {
    this.getprocessWiseTat();
    this.gettatcarddata();
    this.getAllProcess();
    this.getTripVehicleList();
    this.getAlertDashboardData();
    this.getEmployeeViewData();
    this.getDashboardTripCard(this.userService._loggedInUser.plantId);
    // this.getDashboardDockCard(65,98);
  }

  //for modal openaning

  getfilter(index: any) {
    this.dayFilter = index.filter;
    this.getStatusWiseStageVehicleCount();
  }

  getWorstStagefilter(event: any) {
    this.worstDayFilter = event.filter;
    this.getWorstThreeStagesTatWise();
  }

  getWorstTripsfilter(event: any) {
    this.worstTripsDayFilter = event.filter;
    this.getWorstThreeTripsTatWise();
  }

  showAll() {
    this.allhardwares = this.allfiltereddata;
    this.showallhardwares = false;
  }

  getprocessWiseTat() {
    this.api.get('process/process-wise-tat').subscribe((res: any) => {
      if (res.status) {
        const item = res.data;
        this.vehiclecompliancedata = JSON.parse(item);
        this.vehiclecompliancedata.sort((a: any, b: any) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
        this.vehiclecompliancedata.forEach((e) => {
          const date = new Date(e.date);
          const datePipe = new DatePipe('en');
          e.date = datePipe.transform(date, 'dd-MM-yyyy');
        });
        for (let i = 0; i < this.vehiclecompliancedata[0].values.length; i++) {
          this.vehicleComplianceChartData.push({
            data: [],
            label: '',
          });
        }
        this.vehiclecompliancedata.map((vehicles: any, index: number) => {
          vehicles.values.map((values: any, i: number) => {
            let abc = values.avg.split(':');
            let sec = parseInt(abc[0]) * 3600 + parseInt(abc[1]) * 60;
            this.vehicleComplianceChartData[i].data?.push(sec);
          });
          this.vehicleComplianceChartLabels.push(vehicles.date);
          this.vehiclecompliancedata = JSON.parse(item);
        });

        this.vehiclecompliancedata[0].values.map(
          (label: any, index: number) => {
            this.vehicleComplianceChartData[index].label = label.processName;
          }
        );
      }
    });
  }

  selectedprocess(event: any) {
    this.processId = event.processId;
    this.getHardwareStatus(event.processId);
  }

  isTimeGreater(strr1: string, strr2: string): boolean {
    let i = strr1.lastIndexOf('.');
    let i2 = strr2.lastIndexOf('.');
    let str1;
    let str2;
    if (i != -1) {
      str1 = strr1.substring(0, i);
    } else {
      str1 = strr1;
    }
    if (i2 != -1) {
      str2 = strr2.substring(0, i2);
    } else {
      str2 = strr2;
    }
    let temp1 = null;
    let temp2 = null;
    if (str1.includes('days')) {
      let i = str1.indexOf(' ');
      temp1 = strr1.substring(0, i);
    }
    if (str2.includes('days')) {
      let i = str2.indexOf(' ');
      temp2 = strr2.substring(0, i);
    }
    let arr = str1.split(':');
    let totalMinutes1;
    if (temp1 != null) {
      let ind = arr[0].lastIndexOf(' ');
      let hour = arr[0].substring(ind + 1, ind + 3);
      totalMinutes1 = Number(hour) * 60 + Number(arr[1]);
      totalMinutes1 = totalMinutes1 + temp1 * 24 * 60;
    } else {
      totalMinutes1 = Number(arr[0]) * 60 + Number(arr[1]);
    }

    let totalMinutes2;
    let arr2 = str2.split(':');
    if (temp2 != null) {
      let ind = arr2[0].lastIndexOf(' ');
      let hour = arr2[0].substring(ind + 1, ind + 3);
      totalMinutes2 = Number(hour) * 60 + Number(arr2[1]);
      totalMinutes2 = totalMinutes2 + temp2 * 24 * 60;
    } else {
      totalMinutes2 = Number(arr2[0]) * 60 + Number(arr2[1]);
    }

    return totalMinutes1 > totalMinutes2;
  }

  selectedparkingSlotProcess(event: any) {
    this.processId=event.processId;
    this.parkingSlotProcess = event.processId;
    this.getDashboardParkingCard(this.parkingSlotProcess);
  }

  getParkingStates(parkingSlotProcess: number) {
    this.common.loading = true;
    this.api
      .get('dashboard/get-parking-states?processId=' + parkingSlotProcess)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.parkingStates = res.data;
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  // Vechile Tracking

  tripTrackingModal(worstStage) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: worstStage.ipa_transaction_id,
      vehicleName: worstStage.vehicle_rc_no + '-' + worstStage.process_name,
    };
  }

  vechileData(event: any) {
    this.vehicle.id = event.vehicle_id;
    this.vehicle.name = event.name;
    this.getVehicleTransactionStatus(event);
  }

  selecttransaction(event) {
    this.transaction = event.ipa_transaction_id;
    this.getVehicleTransactionStatus(event);
  }

  viewDashboardMoadal(headers:any,keys:any,tableModalData:any){
    const activeModal = this.modalService.open(DashboardModalComponent, {
      size: 'xl',
      windowClass:'custom-modal-width'
    });
    // console.log('activeModal.result: ', activeModal.result);
    activeModal.componentInstance.refData={
      header:headers,
      keys:keys,
      tableModalData:tableModalData,
      modalTitle:this.modalTitle,
      modalType:'User'
    }
  }

  getOverTatVehicleTransactionList() {
    this.common.loading = true;
    this.api.get('dashboard/get-over-tat-vehicle-transaction-list').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.tableModalData = res.data;
          let headers=['Vehicle No','Process Name','Stage Name','Start Time','End Time','TAT','Status','Info'];
          let keys=['vehicle_rc_no','process_name','stage_name','start_time','end_time','tat','stage_workflow_status']
          this.viewDashboardMoadal(headers,keys,this.tableModalData);
          console.log('this.tableModalData: ', this.tableModalData);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllVehicleTransactionList(status:string) {
  console.log('status: ', status);
    this.common.loading = true;
    let params={
      status:status
    }
    this.api.get('dashboard/get-all-vehicle-transaction-list',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.tableModalData = res.data;
          let headers=['Vehicle No','Process Name','Stage Name','Start Time','End Time','TAT','Status','Info'];
          let keys=['vehicle_rc_no','process_name','stage_name','start_time','end_time','tat','stage_workflow_status']
          this.viewDashboardMoadal(headers,keys,this.tableModalData);
          console.log('this.tableModalData: ', this.tableModalData);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  getVehicleTransaction() {
    this.common.loading = true;
    this.api.get('transaction/get-vehicle-transaction-list').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.vehicleTransactionData = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getStatusWiseStageVehicleCount() {
    this.common.loading = true;
    this.api
      .get(
        'dashboard/stage-vehicle-status-wise-count?dayFilter=' +
          this.dayFilter +
          '&processId=' +
          this.processIdSelected
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.statusWiseStageVehicleCount = res.data;
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getWorstThreeStagesTatWise() {
    this.common.loading = true;
    this.api
      .get(
        'dashboard/worst-three-stages-tat-wise?dayFilter=' +
          this.worstDayFilter +
          '&processId=' +
          this.worstStageProcessId
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.worstThreeStagesTatWise = res.data;
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getWorstThreeTripsTatWise() {
    this.common.loading = true;
    this.api
      .get(
        'dashboard/worst-three-trips-tat-wise?dayFilter=' +
          this.worstTripsDayFilter +
          '&processId=' +
          this.worstTripProcessId
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.worstThreeTripsTatWise = res.data;
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  reset() {
    this.transaction = null;
  }
  getVehicleTransactionStatus(event) {
    let params = '?id=' + event.ipa_transaction_id;
    this.api
      .get('transaction/get-vehicle-transaction-status' + params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.vehicleTransactionStatus = res.data;
            this.reset();
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  // Vechile Tracking End


  getTripVehicleList() {
    this.workflow.getTripVehicleList().subscribe((item: any) => {
      if (item.status) {
        item = item.data.length ? item.data : [];
        if (item.length) {
          item.map((items: any) => {
            this.vehiclelist = [];
            this.vehiclelist.push({
              id: items.trip_id,
              tripId: items.trip,
            });
          });
        } else {
          this.vehiclelist = [];
          this.vehiclelist.push({
            id: 0,
            tripId: 0,
          });
        }
        //Todo Timemout
        this.selectvehicle(this.vehiclelist[0]);
        // setTimeout(() => {
        // }, 1000);
      }
    });
  }

  dtOptions2: any = {
    pagingType: 'full_numbers',
    pageLength: 5,
    lengthMenu: [5, 10, 25],
    processing: true,
  };

  getselectedstage(index: any) {
    this.showallhardwares = true;
    let stagefilter = this.stagefilter[index];

    this.allhardwares = this.allfiltereddata.filter((item) => {
      return item['Stage Name'] == stagefilter;
    });
  }

  getselectedtype(index: number) {
    this.showallhardwares = true;
    let typerfilter = this.hardwaretypefilter[index];
    this.allhardwares = this.allfiltereddata.filter((item) => {
      return item['Hardware Type'] == typerfilter;
    });
  }

  filterbystatus(index: number) {
    this.showallhardwares = true;
    let statusfilter = this.statusFilter[index];
    this.allhardwares = this.allfiltereddata.filter((item) => {
      return item['Hardware Current Status'] == statusfilter;
    });
  }

  selectvehicle(item: any) {
    this.vehicleID = item.id;
    // this.getvehiclestatus();
  }

  getAllProcess() {
    this.workflow.getAllProcess().subscribe((item: any) => {
      if (item.status) {
        this.processIdSelected = item.data[0].processId;
        this.processNameSelected = item.data[0].processName;
        this.worstStageProcessId = item.data[0].processId;
        this.worstStageProcessName = item.data[0].processName;
        this.worstTripProcessId = item.data[0].processId;
        this.worstTripProcessName = item.data[0].processName;
        this.allProcess = item.data;
        this.processName = item.data[0].processName;
        this.processId = item.data[0].processId;
        this.selected = this.processId;
        // this.getParkingStates(this.processId);
        this.getDashboardParkingCard(this.processId);
        // this.getloaderstatus();
        this.gettripstatus(this.allProcess[0].processId);
        this.getDockOverviewData(this.processId);
        this.getStatusWiseStageVehicleCount();
        this.getWorstThreeStagesTatWise();
        this.getWorstThreeTripsTatWise();
        this.getHardwareStatus(this.processId);
        this.triprocessname = this.allProcess[0].processName;
        this.defaultprocess = this.allProcess[0].processName;
        this.parkingDefaultProcess = this.allProcess[0].processName;
      }
    });
  }

  getColor(differ: string) {
    return differ.includes('-') ? true : false;
  }

  selectedDock(selectedDock: any) {
    this.dockvalue = selectedDock;
    // this.getDashboardDockCard()
  }

  getprocessindex(event: any) {
    this.processIndex = event.process_id;
    this.triprocessname = event.process_name;
    this.getDashboardDockCard(event.process_id, this.dockvalue);
  }

  getprocessSelected(event: any) {
    this.processIdSelected = event.processId;
    this.processNameSelected = event.processName;
    this.getStatusWiseStageVehicleCount();
  }

  getworststageprocessSelected(event: any) {
    this.worstStageProcessId = event.processId;
    this.worstStageProcessName = event.processName;
    this.getWorstThreeStagesTatWise();
  }

  getworsttripsprocessSelected(event: any) {
    this.worstTripProcessId = event.processId;
    this.worstTripProcessName = event.processName;
    this.getWorstThreeTripsTatWise();
  }

  gettripstatus(processIndex: number) {
    this.workflow.getProcessOverView(processIndex).subscribe((item: any) => {
      if (item.status) {
        this.processTrips = item.data;
        this.processTrips.map((item: any) => {
          if (item.vehicle_count <= 20) {
            item.color = 'success';
          }
          if (item.vehicle_count > 20 && item.vehicle_count <= 50) {
            item.color = 'warning';
          }

          if (item.vehicle_count > 50) {
            item.color = 'danger';
          }
        });
      }
    });
  }

  getHardwareStatus(processId:number) {
    this.common.loading = true;
    this.api.get('dashboard/get-dashboard-hardware-status',{processId:processId}).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.hardwareInfo = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getloaderlen() {
    this.loaderinfolen = this.hardwareInfo.length;
  }

  getDockOverviewData(processId: any) {
    let pararms = {
      processId: processId,
    };
    this.common.loading = true;
    this.api.get('dashboard/get-dock-overview-data', pararms).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.dockData = res.data;
          this.dockData.forEach((element) => {
            element.tat_percentage = this.calculateDurationPercentage(
              element.exp_tat,
              element.avg_tat
            );
            element.process_data = JSON.parse(element.process_data);
          });
          this.selectedDockData(this.dockData[0], 0);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedDockData(dock: any, process_index) {
    this.dockvalue = dock.destination_id;
    this.getDashboardDockCard(
      dock.process_data[process_index].process_id,
      dock
    );
  }

  calculateDurationPercentage(duration1, duration2) {
    // Convert durations to seconds
    const seconds1 = this.convertDurationToMinutes(duration1);
    const seconds2 = this.convertDurationToMinutes(duration2);

    // Check for division by zero
    if (seconds1 === 0) {
      return { percentage: 0.0, increase: false };
    }

    // Calculate percentage
    const percentage = (seconds2 / seconds1) * 100;

    // Round to two decimal places
    let roundedPercentage: any = this.roundToTwoDecimals(percentage / 60); //division by 60 for Minutes Ratio

    // Determine increase or decrease
    const increase = roundedPercentage > 0;
    roundedPercentage =
      increase || roundedPercentage == 0
        ? '+' + roundedPercentage + '%'
        : '-' + roundedPercentage + '%';

    return { percentage: roundedPercentage, increase };
  }

  convertDurationToMinutes(duration) {
    const parts = duration.split(':');
    const hours = parseInt(parts[0], 10) || 0;
    const minutes = parseInt(parts[1], 10) || 0;
    // const seconds = parseInt(parts[2], 10) || 0;
    return hours * 3600 + minutes * 60;
  }

  roundToTwoDecimals(number) {
    return Math.round(number * 100) / 100;
  }

  ngOnInit(): void {}

  groupedDataByType: any = [];
  alertDashboardData: any[] = [];
  tabCardData: any[] = [];
  activeIndex: number = 0;
  groupedDataByEntity: any;
  tatcarddata: any[] = [];
  tatflowdata: any[] = [];
  tatpush = [
    {
      color: '#c3c339',
      icon: 'fas fa-calendar-day',
    },
    {
      color: '#3dc03d',
      icon: 'fas fa-calendar-day',
    },
    {
      color: '#cd4040',
      icon: 'fas fa-calendar-day',
    },
    {
      color: '#4076bd',
      icon: 'fas fa-calendar-day',
    },
  ];

  getGroupDataByType(data: any, key: any) {
    return lodash.groupBy(data, key);
  }

  gettatcarddata() {
    this.workflow.getworkflowtat().subscribe(
      (item: any) => {
        if (item.status) {
          this.tatflowdata = item.data ?? [];
          if (this.tatflowdata.length) {
            this.tatflowdata.map((i, index) => {
              this.tatcarddata.push({
                id: i.id,
                tat: i.tat,
                name: i.name,
                color: this.tatpush[index].color,
                icon: this.tatpush[index].icon,
              });
            });
          }
        }
      },
      (err) => {}
    );
  }

  getAlertDashboardData(alertTypeId: number = -1) {
    this.common.loading = true;
    let params = {
      onlyCount: alertTypeId == -1 ? true : null,
      alertTypeId: alertTypeId,
    };
    this.api.get('alert/get-alert-dashboard-data', params).subscribe(
      (res: any) => {
        this.common.loading = false;

        if (res.status) {
          if (alertTypeId != -1) {
            this.cAlertArray = res.data;
          } else {
            this.groupedDataByType = res.data;

            let dataAlert = [];
            res.data.forEach((element) => {
              dataAlert.push({
                name: element.type_name,
                y: element.type_name_count,
                color: element.type_color,
              });
            });
            this.createDonut3DChart(dataAlert);
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getTabCardData() {
    Object.entries(this.groupedDataByType).forEach((el: any) => {
      let tabCard = {
        title: el[0],
        value: el[1].length,
        cardBgColor: this.getLightColor(el[1][0].type_color, 100),
        textColor: el[1][0].type_color,
        iconBgColor: el[1][0].type_color,
      };
      this.tabCardData.push(tabCard);
    });
  }

  getLightColor(color: string, lightness: number): string {
    // Convert the color to RGB format
    const rgbColor = this.hexToRgb(color);

    // Calculate the light color by increasing the RGB values
    const lightR = Math.min(rgbColor.r + lightness, 255);
    const lightG = Math.min(rgbColor.g + lightness, 255);
    const lightB = Math.min(rgbColor.b + lightness, 255);

    // Convert the light color back to hexadecimal format
    const lightHex = this.rgbToHex(lightR, lightG, lightB);

    return lightHex;
  }

  hexToRgb(hex: string): { r: number; g: number; b: number } {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }

  rgbToHex(r: number, g: number, b: number): string {
    return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}80`;
  }

  getEmployeeViewData() {
    this.common.loading = true;
    this.api.get('dashboard/get-active-employees-by-plantId').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.employeeCount = res.data[0] ?? { inusers: 0, total_users: 0 };
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getEmployeeDetails(countType) {
    const activeModal = this.modalService.open(EmployeeDetailModalComponent, {
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      countType: countType,
    };
  }

  createDoughnutChart() {
    var myDoughnutChart = new Chart('myDoughnutChart', {
      type: 'doughnut',
      data: {
        labels: ['CRITICAL', 'HIGH', 'LOW', 'MEDIUM'],
        datasets: [
          {
            label: 'My First Dataset',
            data: [18, 12, 2, 5],
            backgroundColor: ['#cc0000', '#e56666', '#d8d33b', '#eca29c'],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
            position: 'right',
            labels: {
              padding: 20,
              textAlign: 'right',
              font: {
                size: 10,
              },
              boxWidth: 12,
              boxPadding: 6,
              borderRadius: 4,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  }

  dashboardParkingCard: any[] = [];
  dashboardDockCard: any = {};
  dashboardStageCard: any[] = [];
  dashboardTripCard: any = {};

  getDashboardTripCard(plantId: number) {
    let params = {
      plantId: plantId,
    };
    this.api.get('dashboard/get-dashboard-trip-card', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.dashboardTripCard = res.data[0];
          this.dashboardTripCard.tat_trend_percentage = Math.floor((this.dashboardTripCard.tat_trend_percentage/60)/60);
        }
        let tripData = [
          {
            name:'Inprogress',
            y:Math.ceil((this.dashboardTripCard.inprogress/this.dashboardTripCard.total)*100),
            color:'#FFCE60'
          },
          {
            name:'Completed',
            y:Math.ceil((this.dashboardTripCard.completed/this.dashboardTripCard.total)*100),
            color:'#097969'
          },
          {
            name:'Cancelled',
            y:Math.ceil((this.dashboardTripCard.cancelled/this.dashboardTripCard.total)*100),
            color:'#FF7C60'
          },
          {
            name:'Maintenance',
            y:Math.ceil((this.dashboardTripCard.maintainence/this.dashboardTripCard.total)*100),
            color:'#FFBB60'
          },
        ]
        this.createTripActivityGraph(tripData)
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getDashboardDockCard(processId: number, selectedDock: any) {
    let params = {
      processId: processId,
      destinationId: selectedDock.destination_id,
    };
    this.api.get('dashboard/get-dashboard-dock-card', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.dashboardDockCard = { ...res.data[0], ...selectedDock };
        }
        let chartData = [
          {
            name: 'Booked',
            y: this.dashboardDockCard.booked,
          },
          {
            name: 'Completed',
            y: this.dashboardDockCard.completed,
          },
          {
            name: 'Cancelled',
            y: this.dashboardDockCard.cancelled,
          },
          {
            name: 'In Queue',
            y: this.dashboardDockCard.inprogress,
            // sliced: true,
            // selected: true,
          },
          {
            name: 'Loading',
            y: this.dashboardDockCard.loading,
          },
        ];
        this.createPieChart(chartData);
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getDashboardParkingCard(processId: number) {
    let params = {
      processId: processId,
    };
    this.api.get('dashboard/get-dashboard-parking-card', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.dashboardParkingCard = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  //with order 1, witout order -1,all 0
  getDashboardParkingCardData(process_id:number,stage_workflow_status:string,document_type_name:string,order_variable:number) {
  console.log('process_id: ', process_id);

    let params = {
      process_id:process_id,
      stage_workflow_status:stage_workflow_status,
      document_type_name:document_type_name,
      order_variable:order_variable
    };
    this.api.get('dashboard/get-dashboard-parking-card-data', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.tableModalData= res.data;
          let headers= document_type_name == 'All' ? ['Vehicle No','Destination Name','Start Time','End Time','TAT','Status','Info'] : ['Vehicle No','Destination Name','Expiry Date','Start Time','End Time','TAT','Status','Info'];
          let keys=document_type_name == 'All' ? ['vehicle_rc_no','stage_destination_code','start_time','end_time','tat','stage_workflow_status'] :['vehicle_rc_no','stage_destination_code','expiry_date','start_time','end_time','tat','stage_workflow_status']
          this.viewDashboardMoadal(headers,keys,this.tableModalData);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  getDashboardParkingCardOverTatData(process_stage_order_id:number) {
    let params = {
      process_stage_order_id:process_stage_order_id
    };
    this.api.get('dashboard/get-dashboard-parking-card-over-tat-data', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.tableModalData= res.data;
          let headers=['Vehicle No','Destination Name','Start Time','End Time','Stage TAT','TAT','Status','Info'];
          let keys=['vehicle_rc_no','stage_destination_code','start_time','end_time','stage_tat','tat','stage_workflow_status']
          this.viewDashboardMoadal(headers,keys,this.tableModalData);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  alertDonut: any;
  createDonut3DChart(data: any) {
    const chartHoverEvent = new CustomEvent('chartHover', {
      detail: { sliceIndex: 2 },
    }); // Replace 2 with your desired slice index

    let options: any = {
      exporting: { enabled: false },
      credits: {
        style: {
          display: 'none',
        },
      },
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 40,
          margin: 0,
          padding: 0,
        },
        height: 250,
        padding: 0, // Set all margins to zero,
        margin: 0,
        plotBorderWidth: null,
        plotShadow: false,
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
        enabled: false,
      },
      subtitle: {
        text: '',
        style: {
          display: 'none',
        },
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          innerSize: 90,
          depth: 55,
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'Alert Count',
          data: data,
        },
      ],
    };
    this.alertDonut = Highcharts.chart('alert-donut', options);
    this.selectedAlert(this.groupedDataByType[0],0);
    // this.alertDonut.series[0].points[0].onMouseOver();
    // this.alertDonut.tooltip.refresh(this.alertDonut.series[0].points[0]);
  }

  createPieChart(dataChart) {
    let options: any = {
      exporting: { enabled: false },
      credits: {
        style: {
          display: 'none',
        },
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        // padding: 0, // Set all margins to zero,
        // margin: [20 ,0 , 0, 0],
        height: 350,
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
        enabled: false,
      },
      subtitle: {
        text: '',
        style: {
          display: 'none',
        },
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          // dataLabels: {
          //   enabled: false,
          //   format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          //   style: {
          //     color: 'black',
          //   },
          // },
          events: {
            mouseOver: function (event) {
              const desiredSliceIndex = event.target.index; // Get hovered slice index
              this.points[desiredSliceIndex].sliced = true;
            },
          },
          showInLegend: true,
          dataLabels: {
            enabled: true,
            distance: -40,
            format: '{point.percentage:.1f} %',
            style: {
              fontSize: '12px',
              textOutline: 'none',
              opacity: 1,
            },
            filter: {
              operator: '>',
              property: 'percentage',
              value: 0,
            },
          },
        },
      },
      series: [
        {
          name: 'Dock',
          colorByPoint: true,
          data: dataChart,
        },
      ],
    };

    Highcharts.chart('pie-chart-dock', options);
  }

  createTripActivityGraph(tripData:any) {
    Highcharts.Series.prototype.setState = function () {};
    let options: any = {
      exporting: { enabled: false },
      credits: {
        style: {
          display: 'none',
        },
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
        enabled: false,
      },
      subtitle: {
        text: '',
        style: {
          display: 'none',
        },
        enabled: false,
      },
      chart: {
        height: 230,
        backgroundColor: 'transparent',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        marginBottom: 0,
        marginTop: 0,
        animation: false,
        events: {
          load: function () {
            var chart = this,
              legend = chart.legend,
              point = chart.series.chart;

            // starte with first slice hovered
            chart.series[0].points[0].onMouseOver();

            // Legend hover show tooltip
            for (var i = 0, len = legend.allItems.length; i < len; i++) {
              (function (i) {
                var item = legend.allItems[i].legendItem;
                item
                  .on('mouseover', function (e) {
                    //show custom tooltip here
                    chart.tooltip.refresh(chart.series[0].points[i]);
                  })
                  .on('mouseout', function (e) {
                    //show first slice tooltip on mouse out
                    chart.tooltip.refresh(chart.series[0].points[0]);
                  });
              })(i);
            }
          },
        },
      },
      yAxis: {},
      plotOptions: {
        pie: {
          shadow: false,
          innerSize: '75%',
          borderWidth: 0,
        },
        series: {
          events: {
            mouseOut: function () {
              this.points[0].onMouseOver();
            },
          },
          point: {
            events: {
              //disable click
              legendItemClick: function () {
                return false;
              },
              mouseOut: function () {},
            },
          },
        },
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        borderWidth: 0,
        backgroundColor: 'none',
        headerFormat: '',
        shadow: false,
        hideDelay: 999999999, //keeps tooltip displaying in middle
        delayForDisplay: 999999999, //keeps tooltip displaying in middle
        style: {
          fontSize: '55px',
          textAlign: 'left',
          color: '#000',
          fontWeight: '400',
        },
        formatter: function () {
          return `<div style="white-space: normal;"><span style="font-size:.80em; font-weight: 500;">${Number.isNaN(this.y) ? 0 : this.y}%</span><div style="font-size:.32em;">${this.point.name}</div></div>`;
        },
        positioner: function (labelWidth, labelHeight) {
          return {
            x: (this.chart.plotWidth - labelWidth + 30) / 2,
            y: (this.chart.plotHeight - labelHeight) / 2,
          };
        },
      },
      legend: {
        floating: false,
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        y: 23,
        width: 320,
        itemWidth: 160,

        symbolHeight: 13,
        symbolWidth: 18,
        symbolRadius: 0,
        squareSymbol: false,

        itemStyle: {
          color: '#000',
          fontSize: '14px',
          fontWeight: '400',
        },
        itemHoverStyle: {
          color: '#000',
        },
        labelFormat: '{name} {y:.0f}%',
        itemMarginTop: 5,
      },
      series: [
        {
          data: tripData,

          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 230,
            },
            chartOptions: {
              tooltip: {
                formatter: function () {
                  var width = this.series.chart.plotSizeY;
                  return `<div style="width:calc(${width}px - 150px );margin-left:40px;white-space: normal;"><span style="font-size:.7em; font-weight: 500;">${Number.isNaN(this.y) ? 0 : this.y}%</span><br><div style="font-size:.25em; line-height: 18px;"> ${this.point.name}</div></div>`;
                },
                positioner: function (labelWidth, labelHeight) {
                  return {
                    x: (this.chart.plotWidth - labelWidth + 30) / 2,
                    y: (this.chart.plotHeight - labelHeight + 20) / 2,
                  };
                },
              },
              legend: {
                width: '270',
                itemWidth: 135,
                itemStyle: {
                  fontSize: '12px',
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 300,
            },
            chartOptions: {},
          },
        ],
      },
    };
    Highcharts.chart('trip-activity', options);
  }

  selectedAlert(alertCard, index) {
    this.activeAlert = alertCard.type_name;
    this.alertDonut.series[0].points[index].onMouseOver();
    this.alertDonut.tooltip.refresh(this.alertDonut.series[0].points[index]);
    this.getAlertDashboardData(alertCard.alert_type_id);
  }

  viewAlert(alertData) {
    let data = JSON.parse(alertData);
    const activeModal = this.modalService.open(AlertCardModalComponent, {
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      alert_type_name: data.title,
      alert_remark:data.remark,
      alert_data: data.data,
      showAlertData : true
    };
  }
}

function epoch_to_hh_mm_ss(epoch) {
  let min = Math.floor(epoch / 60);
  return (
    String(Math.floor(min / 60)).padStart(2, '0') +
    ':' +
    String(min % 60).padStart(2, '0')
  );
}

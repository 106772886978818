<div class="card">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{"depreciation" | customTranslate}}
      </h3>
    </div>
    <button type="button" class="btn btn-success" data-toggle="modal" data-target="#exampleModal" (click)="onManage(assetDepreciationList[0])">+ {{"manage" | customTranslate}}</button>
  </div>

  <div class="card-body">
    <div class="message" *ngIf="assetDepreciationList.length==0  && !common.loading" style="display: flex;text-align: center;font-weight: bold;color: #337ab7;justify-content: space-around;">
      {{"add_depreciation_values_for_asset" | customTranslate}} !
    </div>

    <table class="custom-table" *ngIf="assetDepreciationList.length>0">
      <thead class="thead-dark">
        <tr>
          <th>{{"depreciation_type" | customTranslate}} </th>
          <th>{{"depreciation_cost" | customTranslate}}</th>
          <th>{{"salvage_value" | customTranslate}}</th>
          <th>{{"asset_life_months" | customTranslate}}</th>
          <th>{{"date_acquired" | customTranslate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ assetDepreciationList[0].depriciationType}}</td>
          <td>{{ assetDepreciationList[0].depriciableCost}}</td>
          <td>{{ assetDepreciationList[0].salvageValue}}</td>
          <td>{{ assetDepreciationList[0].assetLifeInMonths}}</td>
          <td>{{ assetDepreciationList[0].dateOfAcquired}}</td>
        </tr>
      </tbody>
    </table>

    <div class="dashboard-card" >
      <div class="card-header">
        <h3 class="card-title text-uppercase">{{"depreciation_each_year" | customTranslate}}</h3>
      </div>
      <div class="card-body">
        <canvas width="100%" height="20px" id="depreciationChart">
        </canvas>
      </div>
    </div>

    <table class="custom-table" *ngIf="assetDepreciationList.length && assetDepreciationList[0].depreciationData?.length>0">
      <thead class="thead-dark">
        <tr>
          <th>{{"year" | customTranslate}}</th>
          <th>Depreciation Expense</th>
          <th>Accumulated Depreciation</th>
          <th>sell Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>₹{{ mergedData[0]?.bookValue + mergedData[0]?.accumulatedDepreciation }}</td>
        </tr>
        <tr *ngFor="let item of mergedData">
          <td>{{ item.year }}</td>
          <td>₹{{ item.depreciationExpense }}</td>
          <td>₹{{ item.accumulatedDepreciation }}</td>
          <td>₹{{ item.bookValue }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{"manage_asset"  | customTranslate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="form-group col-12 mb-0">
            <label for="my-input" class="required">{{"depreciable_cost" | customTranslate}}</label>
            <input class="form-control" type="number" step="0.01" name="depreciableCost" [(ngModel)]="assetDepreciation.depreciableCost" placeholder="Enter Depreciable Cost">
            <div class="error" *ngIf="depreciableCostValidator && depreciableCostValidator.error">
              <span>{{depreciableCostValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-12 mb-0">
            <label for="my-input" class="required">{{'salvage_value' | customTranslate}}</label>
            <input class="form-control" type="number" step="0.01" name="salvageValue" [(ngModel)]="assetDepreciation.salvageValue" min="0" placeholder="Enter Salvage Value ">
            <div class="error" *ngIf="salvagevalueValidator && salvagevalueValidator.error">
              <span>{{salvagevalueValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-12 mb-0">
            <label for="my-input" class="required">{{"asset_life_months"|customTranslate}}</label>
            <input class="form-control" type="number" name="assetLifeInMonths" [(ngModel)]="assetDepreciation.assetLifeInMonths" placeholder="Enter Asset Life  ">
            <div class="error" *ngIf="assetLifeInMonthsValidator && assetLifeInMonthsValidator.error">
              <span>{{assetLifeInMonthsValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-12 mb-0">
            <label for="my-input" class="required">{{"depreciation_type" | customTranslate}}</label>
            <select name="depreciationType" id="depreciationType" class="form-control" [(ngModel)]="assetDepreciation.depreciationType">
              <option [ngValue]="null" disabled selected>{{ 'Choose Depreciation Method' | customTranslate }}</option>
              <option value="Straight Line">Straight Line</option>
              <option value="Declining Balance">Declining Balance</option>
              <option value="Double Declining Balance">Double Declining Balance</option>
              <option value="Sum of the Years Digits">Sum of the Years' Digits</option>
            </select>
            <div class="error" *ngIf="assetLifeInMonthsValidator && assetLifeInMonthsValidator.error">
              <span>{{assetLifeInMonthsValidator.msg}} </span>
            </div>
          </div>


          <div class="form-group col-12 mb-0">
            <label for="my-input" class="required">{{"date_acquired"|customTranslate}}</label>
            <input class="form-control" type="date" name="dateOfAcquired" [(ngModel)]="assetDepreciation.dateOfAcquired">
            <div class="error" *ngIf="dateOfAcquiredValidator && dateOfAcquiredValidator.error">
              <span>{{dateOfAcquiredValidator.msg}} </span>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{"close" | customTranslate}}</button>
        <button type="button" class="btn btn-primary" (click)="formValidator()">{{"update_details" | customTranslate}}</button>
      </div>
    </div>
  </div>
</div>

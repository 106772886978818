import { Label } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TmgchartService {
  lineChartcolors = {
    primary: {
      backgroundColor: 'rgb(0, 126, 225, 0.07)',
      borderColor: 'rgb(54 153 255)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },

    secondary: {
      backgroundColor: 'rgba(148,159,177,0.05)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },

    warning: {
      backgroundColor: 'rgba(255, 153, 20,0.05)',
      borderColor: 'rgb(255, 153, 20)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },

    danger: {
      backgroundColor: 'rgba(242, 27, 63,0.05)',
      borderColor: 'rgb(242, 27, 63)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(221, 0, 0, 0.8)',
    },

    success: {
      backgroundColor: 'rgba(40, 167, 69,0.05)',
      borderColor: 'rgb(40, 167, 69)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },

    info: {
      backgroundColor: 'rgba(23, 162, 184,0.05)',
      borderColor: 'rgb(23, 162, 184)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  };

  barChartcolors = {
    primary: {
      backgroundColor: 'rgb(0, 126, 225, 0.7)',
      borderColor: 'rgb(54 153 255)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },

    secondary: {
      backgroundColor: 'rgba(148,159,177,1)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },

    warning: {
      backgroundColor: 'rgba(255, 153, 20,1)',
      borderColor: 'rgb(255, 153, 20)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },

    danger: {
      backgroundColor: 'rgba(242, 27, 63,0.7)',
      borderColor: 'rgb(242, 27, 63)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(221, 0, 0, 0.8)',
    },

    success: {
      backgroundColor: 'rgba(40, 167, 69,1)',
      borderColor: 'rgb(40, 167, 69)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },

    info: {
      backgroundColor: 'rgba(23, 162, 184,1)',
      borderColor: 'rgb(23, 162, 184)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  };

  chartOptions = {
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            precision: 0,
          },
        },
      ],
    },
  };

  options = {
    primary: {},

    secondary: {},

    warning: {},

    danger: {},

    success: {},
  };

  scales = {
    left: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            precision: 0,
          },
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },

          ticks: {
            fontColor: 'red',
            precision: 0,
          },
        },
      ],
    },

    right: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            precision: 0,
          },
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
            precision: 0,
          },
        },
      ],
    },

    center: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            precision: 0,
          },
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
            precision: 0,
          },
        },
      ],
    },

    rightdisabled: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            precision: 0,
          },
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
            precision: 0,
          },
        },
      ],
    },
  };

  constructor() {}

  chartDataTransform(label: any, array: any) {
    // set array to blank before push
    let chartData: any = [];
    let labelArray: any = [];
    let labels: any[] = [];

    // label extract
    array.map((item: any) => {
      labelArray.push({
        [label]: item[label],
      });

      // remove label item from array and created new array
      delete item[label];
    });

    //  Main label Array Creation
    labels.push(Object.keys(array[0]));

    // chart data creation by length
    labels[0].map((item: any, index: number) => {
      chartData.push({
        data: [],
        label: '',
      });
      chartData[index].label = item;
    });

    //  chart data object values creation
    array.map((item: any, index: number, array: any) => {
      chartData.filter((items: any, itemindex: number, arr: any) => {
        let itemlabel = items.label;
        arr[itemindex].data.push(array[index][itemlabel]);
      });
    });
    return chartData;
  }

  chartlabelTransform(label: any, array: any) {
    let labelArray: any = [];
    array.map((item: any) => {
      labelArray.push(item[label]);
    });
    return labelArray;
  }
}

<nav mat-tab-nav-bar>
  <a mat-tab-link [active]="activeIndex == 0" (click)="getProcessVehicles(null,0)">
    All Process </a>
  <a mat-tab-link *ngFor="let process of processList;index as i" [active]="activeIndex == i+1"
    (click)="getProcessVehicles(process.processId,i+1)">
    {{process.processName}} </a>
</nav>



<div class="row no-gutters">
  <div class="col-md-12 mt-2 map-container">
    <div class="map" id="map"></div>
  </div>
</div>



<div class="modal" id="detailModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content shadow">
      <div class="modal-header">
        <h4 class="modal-title">{{modalDetailTitle}} - {{detailType == 1 ? 'Plant': detailType == 2 ?
          'Stage':'Destination' }} </h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <div class="col-md-12 text-right">
            <input type="search" class="form-control" [(ngModel)]="vehicleNumber" placeholder="Search Vehicle"
              (input)="SearchVehicle()">
          </div>
        </div>

        <div class="detail-container">
          <div *ngIf="detailData?.length">
            <div class="row vehicle-detail" *ngFor="let vehicle of detailData;index as i">
              <div class="col-md-12">
                <div class="vehicle-info">
                  <div class="vehicle-data">
                    <p class="ml-2 mb-0"> {{i + 1}} </p>
                    &nbsp;&nbsp;&nbsp;
                    <img [src]="detailImages[detailType]" class="vehicle-icon" alt="">
                    <p class="mb-0 ml-4" appEllipsisView style="width:100px"> {{vehicle.vehicle_rc_no}} </p>
                  </div>

                  <div class="v_trip_image pull-right">
                    <img class="icon" src="assets/images/trip-details(3).png" alt="" (click)="tripModal(vehicle)">
                    <img class="icon" src="assets/images/trip-tracking(3).png" alt=""
                      (click)="tripTrackingModal(vehicle)">
                      <app-trip-comment-trail [tripId]="vehicle.transaction_id"></app-trip-comment-trail>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div *ngIf="!detailData?.length">
          <p style="color:red;display: flex;justify-content: center;">No Data Found !!</p>
        </div>

        <div class="row">
          <div class="col-md-12 text-right mt-3 mb-1">
            <button type="button" class="btn btn-danger" (click)="closeModal(false)">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

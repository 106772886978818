<div class="container-fluid">
    <div class="container-card">
      <div class="container-card-body process-form">

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="header-block">
                  <h3 class="card-title">
                    {{'Product Management'|customTranslate}}
                  </h3>
                </div>
              </div>

              <div class="card-body">
                <div class="row">

                    <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                        <label for="my-input">{{"company" | customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="company" display="companyName"
                          placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                        </ps-auto-suggestion>
                        <div class="error" *ngIf="companyValidator && companyValidator.error">
                          <span>{{companyValidator.msg}} </span>
                        </div>
                      </div>

                      <div *ngIf="(role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN')"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantData"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantValidator && plantValidator.error">
                    <span>{{plantValidator.msg}} </span>
                  </div>
                </div>

                  <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                    <label for="my-input" class="required">{{'Product Name'|customTranslate}}</label>
                    <input class="form-control" type="text" name="productName" [(ngModel)]="product.productName">
                    <div class="error" *ngIf="productNameValidator && productNameValidator.error">
                      <span>{{productNameValidator.msg}} </span>
                    </div>
                  </div>



                  <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                    <label for="my-input" class="required">{{"product_type" | customTranslate}}</label>
                    <ps-auto-suggestion (onSelected)="selectedProductType($event)" class="required" [data]="productTypeData"
                      display="label" placeholder="Choose Product Type"
                      [preSelected]="{ label: productTypeName}">
                    </ps-auto-suggestion>
                    <div class="error" *ngIf="productTypeValidator && productTypeValidator.error">
                      <span>{{productTypeValidator.msg}} </span>
                    </div>
                  </div>

                  <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                    <label for="" class="d-block" class="required"> {{'status'|customTranslate}} </label>
                    <div class="form-check-inline">
                      <input class="form-check-input" type="radio" name="productStatus"
                        [(ngModel)]="product.productStatus" id="product_status_1" value="ACTIVE" checked>
                      <label for="product_status_radios1">
                        {{'active'|customTranslate}}
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <input class="form-check-input" type="radio" name="productStatus"
                        [(ngModel)]="product.productStatus" id="productStatus" value="INACTIVE">
                      <label for="product_status_radio2">
                        {{'inactive'|customTranslate}}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group action-block">
                  <div class="btn-block">
                    <button type="submit" class="btn btn-muted" (click)=" common.backClicked()"> {{'back'|customTranslate}}
                    </button>
                    <button class="btn btn-warning" (click)="resetWorkType()"> {{'reset'|customTranslate}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{'save'|customTranslate}}
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="header-block">
                  <h3 class="card-title">
                    {{'product_list'|customTranslate}}
                  </h3>
                </div>
              </div>
              <div class="card-body">
                <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                  <thead class="thead-light">
                    <tr>
                      <th>{{'serial_no'|customTranslate}}</th>
                      <th>{{'plant'| customTranslate}}</th>
                      <th>{{'company'| customTranslate}}</th>
                      <th>{{'Product Name'|customTranslate}}</th>
                      <th>{{'product_type'|customTranslate}}</th>
                      <!-- <th>{{'created at'|customTranslate}}</th> -->
                      <th>{{'status'|customTranslate}}</th>
                      <th class="action">{{'Actions'|customTranslate}} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of responseData let i = index">
                      <td>{{ i+1 }}</td>
                      <td>{{row.plant_name==null?'-':row.plant_name}}</td>
                      <td>{{row.company_name}}</td>
                      <td>{{row.product_name}}</td>
                      <td>{{row.product_type_name}}</td>
                      <!-- <td>{{row.created_date}}</td> -->
                      <td>{{row.status==1?'ACTIVE':'INACTIVE'}}</td>
                      <td class="action">
                        <div class="btn-block">
                          <button class="btn btn-primary" (click)="view(row)" > {{'view'|customTranslate}}
                          </button>
                          <button class="btn btn-success" *ngIf="checkEdit(row.company_id,row.plant_id)" (click)="edit(row)"> {{'edit'|customTranslate}}
                          </button>
                          <button class="btn btn-danger" *ngIf="checkEdit(row.company_id,row.plant_id)" (click)="confirmDelete(row)">
                            {{'delete'|customTranslate}} </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

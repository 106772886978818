<div class="modal-header" [hidden]="remarkModal">
  <h5 class="modal-title" id="modal-basic-title">{{formName}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1,null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [hidden]="remarkModal">

  <div class="col-md-12 visitor_card_sec"
    *ngIf="showVisitorCard==true && showAssignDock==false && showAssignParkingSlot==false">
    <div class="visitor_card">
      <div class="card_design">
        <div class="card_top">
          <h5>VISITOR CARD</h5>
        </div>
        <div class="card_sec">
          <div class="card_left">
            <img src="assets/images/visitor_img.png">
          </div>

          <div class="card_right">
            <div class="data_line">
              <h6>Name:</h6>
              <p>{{this.visitorCardDetails[0].name}}</p>
            </div>
            <div class="data_line">
              <h6>Mobile:</h6>
              <p>{{this.visitorCardDetails[0].mobileNO}}</p>
            </div>
            <div class="data_line">
              <h6>To Meet:</h6>
              <p>{{visitorName}}</p>
            </div>
            <div class="data_line">
              <h6>Visit Type:</h6>
              <p>{{this.visitorCardDetails[0].visitType}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="vehicle_form_box" *ngIf="showAssignDock==false && showAssignParkingSlot==false">
    <div class="row" style="width: 100%; margin: auto;" *ngIf="mobileFieldsCount==0">
      <ng-container *ngFor="let field of actionFormDetailList;index as i">
        <div class="col-md-6 modal_col" *ngIf="field.field_type!='LABEL'&& field.field_type!='FIELDGROUP'">
          <div *ngIf="field.is_mandatory==1" style="color:red">*</div>
          <div class="field" *ngIf="field.field_type=='TEXTFIELD' && ((field.sel_value==null || field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <input type="text" class="field_class" (change)="enterValues($event , field , field.index,null,i)" [value]="field.draft_data!=null?field.draft_data[0]:''">
          </div>

          <div class="field" *ngIf="field.field_type=='TEXTFIELD' && (field.sel_value!=null || disabledFields==true)">
            <label>{{field.field_name}}</label>
            <input type="text" class="field_class" [value]="field.sel_value" disabled>
          </div>

          <div class="field" *ngIf="field.field_type=='NUMBERFIELD'&& ((field.sel_value==null|| field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <input type="number" class="field_class" (change)="enterValues($event , field , field.index,null,i)" [value]="field.draft_data!=null?field.draft_data[0]:''">
          </div>

          <div class="field" *ngIf="field.field_type=='NUMBERFIELD'&& (field.sel_value!=null || disabledFields==true)">
            <label>{{field.field_name}}</label>
            <input type="number" class="field_class" [value]="field.sel_value" disabled>
          </div>

          <div class="field" *ngIf="field.field_type=='TIME'&& ((field.sel_value==null || field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <input type="time" class="field_class" (change)="enterValues($event , field , field.index,null,i)" [value]="field.draft_data!=null?field.draft_data[0]:''">
          </div>

          <div class="field" *ngIf="field.field_type=='TIME'&& (field.sel_value!=null || disabledFields==true)">
            <label>{{field.field_name}}</label>
            <input type="time" class="field_class" [value]="field.sel_value" disabled>
          </div>

          <div class="field" *ngIf="field.field_type=='URL'&& ((field.sel_value==null || field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <input type="url" class="field_class" (change)="enterValues($event , field , field.index,null,i)" [value]="field.draft_data!=null?field.draft_data[0]:''">
          </div>

          <div class="field" *ngIf="field.field_type=='URL'&& (field.sel_value!=null || disabledFields==true)">
            <label>{{field.field_name}}</label>
            <input type="url" class="field_class" [value]="field.sel_value" disabled>
          </div>

          <div class="field"
            *ngIf="field.field_type=='DATETIMEPICKER'&& ((field.sel_value==null  || field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <input type="datetime-local" class="field_class"
              (change)="enterValues($event , field , field.index,null,i)" [value]="field.draft_data!=null?field.draft_data[0]:''">
          </div>

          <div class="field"
            *ngIf="field.field_type=='DATETIMEPICKER'&& (field.sel_value!=null || disabledFields==true)">
            <label>{{field.field_name}}</label>
            <input type="datetime-local" class="field_class" [value]="field.sel_value" disabled>
          </div>

          <div class="field" *ngIf="field.field_type=='FILE'&& ((field.sel_value==null || field.draft_data!=null)&& disabledFields==false)">
            <label>{{field.field_name}}</label>
            <ng-container *ngIf="field.draft_data!=null">
              {{imageName(field.draft_data[0])}}
            <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
              <img [src]="image_url" alt="" style="width: 226px;"><span style="font-size:small">{{"
                "+this.image_name}}</span>
            </ng-container>
            <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
              <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                text-decoration-line: underline;" (click)="newTab(image_url)">{{"
                "+this.image_name}}</span>
            </ng-container>
            </ng-container>
            <input type="file" accept=".jpg,.png,.jpeg,.pdf,.xlsx,.xlsm,.xls" class="field_class file_class" multiple
              (change)="enterValues($event , field , i,null,i)">
          </div>

          <div class="field"
            *ngIf="(field.field_type=='FILE'||field.field_type=='GEOCODEDIMAGE')&& (field.sel_value!=null || disabledFields==true)">
            <label>{{field.field_name}}</label>
            {{imageName(field.sel_value)}}
            <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
              <img [src]="image_url" alt="" style="width: 226px;"><span style="font-size:small">{{"
                "+this.image_name}}</span>
            </ng-container>
            <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
              <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                text-decoration-line: underline;" (click)="newTab(image_url)">{{"
                "+this.image_name}}</span>
            </ng-container>
          </div>

          <div class="field"
            *ngIf="(field.field_type=='GEOCODEDIMAGE')&& ((field.sel_value==null|| field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <ng-container *ngIf="field.draft_data!=null">
              {{imageName(field.draft_data[0]?.fileUrl)}}
            <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
              <img [src]="image_url" alt="" style="width: 226px;"><span style="font-size:small">{{"
                "+this.image_name}}</span>
            </ng-container>
            <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
              <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                text-decoration-line: underline;" (click)="newTab(image_url)">{{"
                "+this.image_name}}</span>
            </ng-container>
            </ng-container>
            <app-camera-component *ngIf="this.formValues[field.index].values.length==0" [retake]="retakeImage" (getPicture)="handleImage($event, field,field.index)">
            </app-camera-component>
            <div style="display: flex; flex-direction: column; align-items:center;"
              *ngIf="this.formValues[field.index].values.length && field.field_name==this.formValues[field.index].name">
              <p>Upload Done! <button class="btn btn-warning"
                  (click)="retakeImage = !retakeImage;this.formValues[field.index].values=[];">Retake</button>
              </p>
              <!-- <img style="height: 200px;" [src]="this.formValuesList[ind].formValues[i].values[0]" /> -->
            </div>
          </div>
          <div class="field"
            *ngIf="(field.field_type=='FILE'||field.field_type=='IMAGE')&& (field.sel_value!=null || disabledFields==true)">
            <label>{{field.field_name}}</label>
            {{imageName(field.sel_value)}}
            <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
              <img [src]="image_url" alt="" style="width: 226px;"><span style="font-size:small">{{"
                "+this.image_name}}</span>
            </ng-container>
            <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
              <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                text-decoration-line: underline;" (click)="newTab(image_url)">{{"
                "+this.image_name}}</span>
            </ng-container>
          </div>

          <div class="field"
            *ngIf="(field.field_type=='IMAGE')&& ((field.sel_value==null || field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <ng-container *ngIf="field.draft_data!=null">
              {{imageName(field.draft_data[0])}}
            <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
              <img [src]="image_url" alt="" style="width: 226px;"><span style="font-size:small">{{"
                "+this.image_name}}</span>
            </ng-container>
            <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
              <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                text-decoration-line: underline;" (click)="newTab(image_url)">{{"
                "+this.image_name}}</span>
            </ng-container>
            </ng-container>
            <app-camera-component [retake]="retakeImage" (getPicture)="handleImage($event, field,field.index)">
            </app-camera-component>
            <div style="display: flex; flex-direction: column; align-items:center;"
              *ngIf="this.formValues[field.index].values.length && field.field_name==this.formValues[field.index].name">
              <p>Upload Done! <button class="btn btn-warning"
                  (click)="retakeImage = !retakeImage;this.formValues[field.index].values=[];">Retake</button>
              </p>
              <!-- <img style="height: 200px;" [src]="this.formValuesList[ind].formValues[i].values[0]" /> -->
            </div>
          </div>


          <div class="field"
            *ngIf="(field.field_type=='CAMERASTREAMING')&& (field.sel_value==null && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <app-live-streaming [isCamera]="true" [fieldId]="field.master_field_id"
              (getStreamValue)="handleStreaming($event, field,field.index)">
            </app-live-streaming>
          </div>
          <div class="field"
            *ngIf="(field.field_type=='VIDEOSTREAMING')&& (field.sel_value==null && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <app-live-streaming [isCamera]="false" [fieldId]="field.master_field_id"
              (getStreamValue)="handleStreaming($event, field,field.index)">
            </app-live-streaming>
          </div>

          <div class="field" *ngIf="field.field_type=='TEXTAREA'&& ((field.sel_value==null|| field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <textarea rows="3" cols="50" (change)="enterValues($event , field , field.index,null,i)" [value]="field.draft_data!=null?field.draft_data[0]:''"
              class="field_class"></textarea>
          </div>

          <div class="field" *ngIf="field.field_type=='TEXTAREA'&& (field.sel_value!=null || disabledFields==true)">
            <label>{{field.field_name}}</label>
            <textarea rows="3" cols="50" [value]="field.sel_value" disabled class="field_class"></textarea>
          </div>

          <div class="field" *ngIf="field.field_type=='DATEPICKER'&& ((field.sel_value==null || field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <input type="date" class="field_class" (change)="enterValues($event , field , field.index,null,i)" [value]="field.draft_data!=null?field.draft_data[0]:''">
          </div>

          <div class="field" *ngIf="field.field_type=='DATEPICKER'&& (field.sel_value!=null || disabledFields==true)">
            <label>{{field.field_name}}</label>
            <input type="date" class="field_class" [value]="field.sel_value" disabled>
          </div>

          <div class="field r_field"
            *ngIf="field.field_type=='RADIOBUTTON'&& ((field.sel_value==null || field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <div *ngFor="let opt of field.options;index as j" class="radio_check">
              <input type="radio" [name]="'field-' + field.master_field_id" [id]="'field-' + j + field.master_field_id" [checked]="field.draft_data!=null?field.draft_data[0] == opt.name:false"
                value="{{opt.name}}" (change)="enterValues($event , field , field.index,null,i)">&nbsp;<label
                [for]="'field-' + j + field.master_field_id">{{opt.name}}</label>
            </div>
          </div>

          <div class="field r_field"
            *ngIf="field.field_type=='RADIOBUTTON'&& (field.sel_value!=null || disabledFields==true)">
            <label>{{field.field_name}}</label>
            <div *ngFor="let opt of field.options;index as j" class="radio_check">
              <input type="radio" [name]="'field-'+ j + field.master_field_id"
                [id]="'field-'+ j + field.master_field_id" [checked]="field.sel_value == opt.name" disabled>&nbsp;<label
                [for]="'field-'+ j + field.master_field_id">{{opt.name}}</label>
            </div>
          </div>

          <div class="field c_field"
            *ngIf="field.field_type=='CHECKBOX'&& ((field.sel_value==null|| field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <div *ngFor="let opt of field.options" class="radio_check">
              <input type="checkbox" value="{{opt.name}}" [name]="'field'+field.index + field.master_field_id" [checked]="field.draft_data!=null?field.draft_data.includes(opt.name):false"
                [id]="'field'+field.index + field.master_field_id"
                (change)="enterValues($event  , field , field.index,null,i)">&nbsp;<label
                [for]="'field'+field.index + field.master_field_id">{{opt.name}}</label>
            </div>
          </div>

          <div class="field c_field"
            *ngIf="field.field_type=='CHECKBOX'&& (field.sel_value!=null || disabledFields==true)">
            <label>{{field.field_name}}</label>
            <div *ngFor="let opt of field.options" class="radio_check">
              <input type="checkbox" [checked]="field.sel_value == opt.name" disabled>&nbsp;<label>{{opt.name}}</label>
            </div>
          </div>

          <div class="field c_field"
            *ngIf="field.field_type=='SIGNATURE'&& ((field.sel_value==null|| field.draft_data!=null) && disabledFields==false)"
            (mouseleave)="saveSignature(field.index,field)">
            <ng-container *ngIf="field.draft_data!=null">
              {{imageName(field.draft_data[0])}}
              <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                <img [src]="field.draft_data[0]" alt="" style="width: 226px;">
              </ng-container>
            </ng-container>
            <app-signature></app-signature>
          </div>

          <div class="field c_field"
            *ngIf="field.field_type=='SIGNATURE'&& (field.sel_value!=null || disabledFields==true)">
            {{imageName(field.sel_value)}}
            <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
              <img [src]="field.sel_value" alt="" style="width: 226px;">
            </ng-container>
          </div>

          <div class="field"
            *ngIf="field.field_type=='SELECTLISTSINGLE'&& ((field.sel_value==null || field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <ps-auto-suggestion (onSelected)="enterValues($event, field, field.index,null,i)" [data]="field.options"
              [display]="field.label" placeholder="Choose option" [preSelected]="getPreSelectedValue(field.label,field.index,null,field.field_type)">
            </ps-auto-suggestion>
          </div>

          <div class="field"
            *ngIf="field.field_type=='SELECTLISTSINGLE'&& (field.sel_value!=null && disabledFields==true)">
            <label>{{field.field_name}}</label>
            <select class="field_class" disabled>
              <option *ngFor="let opt of field.options" [selected]="opt[field.store_value]==field.sel_value"
                [value]="opt[field.store_value]">
                {{opt[field.label]}}</option>
            </select>
          </div>

          <div class="field"
            *ngIf="field.field_type=='SELECTLISTSINGLE'&& (field.sel_value==null && disabledFields==true)">
            <label>{{field.field_name}}</label>
            <select class="field_class" *ngIf="field.sel_value==null" [(ngModel)]="selectedOption"
              (change)="handleSingleSelectDBField(selectedOption, field, field.index)">
              <option></option>
              <option *ngFor="let opt of field.options" [value]="opt[field.store_value]">{{opt[field.label]}}</option>
            </select>
          </div>

          <div class="field"
            *ngIf="field.field_type=='SELECTLISTMULTIPLE'&& ((field.sel_value==null||field.draft_data!=null) && disabledFields==false)">
            <label>{{field.field_name}}</label>
            <ps-auto-suggestion isMultiSelect="true" (onSelected)="enterValues($event, field, field.index,null,i)"
              [data]="field.options" [display]="field.label" placeholder="Choose option" [preSelected]="this.formValues[field.index].preSelected">
            </ps-auto-suggestion>

          </div>
          <div class="field"
            *ngIf="field.field_type=='SELECTLISTMULTIPLE'&& (field.sel_value!=null || disabledFields==true)">

            <label>{{field.field_name}}</label>

            <ps-auto-suggestion isMultiSelect="true" (onSelected)="enterValues($event, field, field.index,null,i)"
              [data]="field.multiValueOptions" [display]="field.label" placeholder="Choose option">
            </ps-auto-suggestion>

          </div>
        </div>
        <div class="col-md-12 modal_col" *ngIf="field.field_type=='LABEL'">
          {{insertLabel(field, field.index)}}
          <div class="field">
            <label>{{field.field_name}}</label>
            <p style="font-size: small" [innerHTML]="field.options[0].name"></p>
          </div>
        </div>
        <ng-container *ngIf="field.field_type=='FIELDGROUP'">
          <div class="col-md-12 modal_col" *ngFor="let fields of field.group_fields;index as ind">
            <div class="col-md-6 modal_col" *ngFor="let groupField of fields">
              <div *ngIf="groupField.is_mandatory==1" style="color:red">*</div>
              <div class="field"
                *ngIf="groupField.field_type=='TEXTFIELD' && ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <input type="text" class="field_class" [id]="'field'+groupField.index+ind"
                  (change)="enterValues($event , groupField , groupField.index,field,ind)" [value]="groupField.draft_data!=null?groupField.draft_data[0]:''">
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='TEXTFIELD' && (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <input type="text" class="field_class" [value]="groupField.sel_value" disabled>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='NUMBERFIELD'&& ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <input type="number" class="field_class" [id]="'field'+groupField.index+ind"
                  (change)="enterValues($event , groupField , groupField.index,field,ind)" [value]="groupField.draft_data!=null?groupField.draft_data[0]:''">
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='NUMBERFIELD'&& (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <input type="number" class="field_class" [value]="groupField.sel_value" disabled>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='TIME'&& ((groupField.sel_value==null || groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <input type="time" class="field_class" [id]="'field'+groupField.index+ind"
                  (change)="enterValues($event , groupField , groupField.index,field,ind)"  [value]="groupField.draft_data!=null?groupField.draft_data[0]:''">
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='TIME'&& (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <input type="time" class="field_class" [value]="groupField.sel_value" disabled>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='URL'&& ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <input type="url" class="field_class" [id]="'field'+groupField.index+ind"
                  (change)="enterValues($event , groupField , groupField.index,field,ind)" [value]="groupField.draft_data!=null?groupField.draft_data[0]:''">
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='URL'&& (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <input type="url" class="field_class" [value]="groupField.sel_value" disabled>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='DATETIMEPICKER'&& ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <input type="datetime-local" class="field_class" [id]="'field'+groupField.index+ind"
                  (change)="enterValues($event , groupField , groupField.index,field,ind)" [value]="groupField.draft_data!=null?groupField.draft_data[0]:''">
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='DATETIMEPICKER'&& (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <input type="datetime-local" class="field_class" [value]="groupField.sel_value" disabled>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='FILE'&& ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <ng-container *ngIf="groupField.draft_data!=null">
                  {{imageName(groupField.draft_data[0])}}
                <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                  <img [src]="image_url" alt="" style="width: 226px;"><span style="font-size:small">{{"
                    "+this.image_name}}</span>
                </ng-container>
                <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                  <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                    text-decoration-line: underline;" (click)="newTab(image_url)">{{"
                    "+this.image_name}}</span>
                </ng-container>
                </ng-container>
                <input type="file" accept=".jpg,.png,.jpeg,.pdf,.xlsx,.xlsm,.xls" class="field_class file_class"
                  multiple (change)="enterValues($event , groupField , groupField.index,field,ind)">
              </div>

              <div class="field"
                *ngIf="(groupField.field_type=='FILE'||groupField.field_type=='GEOCODEDIMAGE')&& (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                {{imageName(groupField.sel_value)}}
                <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                  <img [src]="image_url" alt="" style="width: 226px;"><span style="font-size:small">{{"
                    "+this.image_name}}</span>
                </ng-container>
                <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                  <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                text-decoration-line: underline;" (click)="newTab(image_url)">{{"
                    "+this.image_name}}</span>
                </ng-container>
              </div>

              <div class="field"
                *ngIf="(groupField.field_type=='GEOCODEDIMAGE')&& ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <ng-container *ngIf="groupField.draft_data!=null">
                  {{imageName(groupField.draft_data[0]?.fileUrl)}}
                <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                  <img [src]="image_url" alt="" style="width: 226px;"><span style="font-size:small">{{"
                    "+this.image_name}}</span>
                </ng-container>
                <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                  <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                    text-decoration-line: underline;" (click)="newTab(image_url)">{{"
                    "+this.image_name}}</span>
                </ng-container>
                </ng-container>
                <app-camera-component [retake]="retakeImage"
                  (getPicture)="handleImage($event, groupField,groupField.index)">
                </app-camera-component>
                <div style="display: flex; flex-direction: column; align-items:center;"
                  *ngIf="this.formValues[groupField.index].values.length && groupField.field_name==this.formValues[groupField.index].name">
                  <p>Upload Done! <button class="btn btn-warning"
                      (click)="retakeImage = !retakeImage;this.formValues[groupField.index].values=[];">Retake</button>
                  </p>
                  <!-- <img style="height: 200px;" [src]="this.formValuesList[ind].formValues[i].values[0]" /> -->
                </div>
              </div>
              <div class="field"
                *ngIf="(groupField.field_type=='FILE'||groupField.field_type=='IMAGE')&& (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                {{imageName(groupField.sel_value)}}
                <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                  <img [src]="image_url" alt="" style="width: 226px;"><span style="font-size:small">{{"
                    "+this.image_name}}</span>
                </ng-container>
                <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                  <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                text-decoration-line: underline;" (click)="newTab(image_url)">{{"
                    "+this.image_name}}</span>
                </ng-container>
              </div>

              <div class="field"
                *ngIf="(groupField.field_type=='IMAGE')&& ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <ng-container *ngIf="groupField.draft_data!=null">
                  {{imageName(groupField.draft_data[0])}}
                <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                  <img [src]="image_url" alt="" style="width: 226px;"><span style="font-size:small">{{"
                    "+this.image_name}}</span>
                </ng-container>
                <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                  <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                    text-decoration-line: underline;" (click)="newTab(image_url)">{{"
                    "+this.image_name}}</span>
                </ng-container>
                </ng-container>
                <app-camera-component [retake]="retakeImage"
                  (getPicture)="handleImage($event, groupField,groupField.index)">
                </app-camera-component>
                <div style="display: flex; flex-direction: column; align-items:center;"
                  *ngIf="this.formValues[groupField.index].values.length && groupField.field_name==this.formValues[groupField.index].name">
                  <p>Upload Done! <button class="btn btn-warning"
                      (click)="retakeImage = !retakeImage;this.formValues[groupField.index].values=[];">Retake</button>
                  </p>
                  <!-- <img style="height: 200px;" [src]="this.formValuesList[ind].formValues[i].values[0]" /> -->
                </div>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='TEXTAREA'&& ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <textarea rows="3" cols="50" [id]="'field'+groupField.index+ind"
                  (change)="enterValues($event , groupField , groupField.index,field,ind)" [value]="groupField.draft_data!=null?groupField.draft_data[0]:''"
                  class="field_class"></textarea>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='TEXTAREA'&& (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <textarea rows="3" cols="50" [value]="groupField.sel_value" disabled class="field_class"></textarea>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='DATEPICKER'&& ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <input type="date" class="field_class" [id]="'field'+groupField.index+ind"
                  (change)="enterValues($event , groupField , groupField.index,field,ind)" [value]="groupField.draft_data!=null?groupField.draft_data[0]:''">
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='DATEPICKER'&& (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <input type="date" class="field_class" [value]="groupField.sel_value" disabled>
              </div>

              <div class="field r_field"
                *ngIf="groupField.field_type=='RADIOBUTTON'&& ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <div *ngFor="let opt of groupField.options;index as j" class="radio_check">
                  <input type="radio" [name]="'field-' + groupField.master_field_id" [checked]="groupField.draft_data!=null?groupField.draft_data[0] == opt.name:false"
                    [id]="'field-' + j + groupField.master_field_id" value="{{opt.name}}"
                    (change)="enterValues($event , groupField , groupField.index,field,ind)">&nbsp;<label
                    [for]="'field-' + j + groupField.master_field_id">{{opt.name}}</label>
                </div>
              </div>

              <div class="field r_field"
                *ngIf="groupField.field_type=='RADIOBUTTON'&& (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <div *ngFor="let opt of groupField.options;index as j" class="radio_check">
                  <input type="radio" [name]="'field-'+ j + groupField.master_field_id"
                    [id]="'field-'+ j + groupField.master_field_id" [checked]="groupField.sel_value == opt.name"
                    disabled>&nbsp;<label [for]="'field-'+ j + groupField.master_field_id">{{opt.name}}</label>
                </div>
              </div>

              <div class="field c_field"
                *ngIf="groupField.field_type=='CHECKBOX'&& ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <div *ngFor="let opt of groupField.options" class="radio_check">
                  <input type="checkbox" value="{{opt.name}}"
                    [name]="'field'+groupField.index + groupField.master_field_id"
                    [id]="'field'+groupField.index + groupField.master_field_id" [checked]="groupField.draft_data!=null?groupField.draft_data.includes(opt.name):false"
                    (change)="enterValues($event  , groupField , groupField.index,field,ind)">&nbsp;<label
                    [for]="'field'+groupField.index + groupField.master_field_id">{{opt.name}}</label>
                </div>
              </div>

              <div class="field c_field"
                *ngIf="groupField.field_type=='CHECKBOX'&& (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <div *ngFor="let opt of groupField.options" class="radio_check">
                  <input type="checkbox" [checked]="groupField.sel_value == opt.name"
                    disabled>&nbsp;<label>{{opt.name}}</label>
                </div>
              </div>

              <div class="field c_field"
                *ngIf="groupField.field_type=='SIGNATURE'&& ((groupField.sel_value==null|| groupField.draft_data!=null) && disabledFields==false)"
                (mouseleave)="saveSignature(groupField.index,groupField)">
                <ng-container *ngIf="groupField.draft_data!=null">
                  {{imageName(groupField.draft_data[0])}}
                  <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                    <img [src]="field.draft_data[0]" alt="" style="width: 226px;">
                  </ng-container>
                </ng-container>
                <app-signature></app-signature>
              </div>

              <div class="field c_field"
                *ngIf="groupField.field_type=='SIGNATURE'&& (groupField.sel_value!=null || disabledFields==true)">
                {{imageName(groupField.sel_value)}}
                <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                  <img [src]="groupField.sel_value" alt="" style="width: 30px;">
                </ng-container>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='SELECTLISTSINGLE'&& (groupField.sel_value==null && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <ps-auto-suggestion [inputId]="'field'+groupField.index+ind"
                  (onSelected)="enterValues($event, groupField, groupField.index,field,ind)" [data]="groupField.options"
                  [display]="groupField.label" placeholder="Choose option"
                  [preSelected]="getPreSelectedValue(groupField.label,groupField.index,null,groupField.field_type)">
                </ps-auto-suggestion>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='SELECTLISTSINGLE'&& (groupField.sel_value!=null && disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <select class="field_class" disabled>
                  <option *ngFor="let opt of groupField.options"
                    [selected]="opt[groupField.store_value]==groupField.sel_value"
                    [value]="opt[groupField.store_value]">
                    {{opt[groupField.label]}}</option>
                </select>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='SELECTLISTSINGLE'&& (groupField.sel_value==null && disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <select class="field_class" *ngIf="groupField.sel_value==null" [(ngModel)]="selectedOption"
                  (change)="handleSingleSelectDBField(selectedOption, groupField, groupField.index)">
                  <option></option>
                  <option *ngFor="let opt of groupField.options" [value]="opt[groupField.store_value]">
                    {{opt[groupField.label]}}</option>
                </select>
              </div>

              <div class="field"
                *ngIf="groupField.field_type=='SELECTLISTMULTIPLE'&& (groupField.sel_value==null && disabledFields==false)">
                <label>{{groupField.field_name}}</label>
                <ps-auto-suggestion isMultiSelect="true" [inputId]="'field'+groupField.index+ind"
                  (onSelected)="enterValues($event, groupField, groupField.index,field,ind)" [data]="groupField.options"
                  [display]="groupField.label" placeholder="Choose option"
                  [preSelected]="this.formValues[groupField.index].preSelected">
                </ps-auto-suggestion>

              </div>
              <div class="field"
                *ngIf="groupField.field_type=='SELECTLISTMULTIPLE'&& (groupField.sel_value!=null || disabledFields==true)">
                <label>{{groupField.field_name}}</label>
                <ps-auto-suggestion isMultiSelect="true" [inputId]="'field'+groupField.index+ind"
                  (onSelected)="enterValues($event, groupField, groupField.index,field,ind)"
                  [data]="groupField.multiValueOptions" [display]="groupField.label" placeholder="Choose option"
                  [preSelected]="this.formValues[groupField.index].preSelected">
                </ps-auto-suggestion>

              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="row" *ngIf="mobileFieldsCount>0" style="display: flex;">
      <p style="font-weight: bold;margin-bottom: 0;font-size: 10px;text-align: center;padding: 5px;">
        <span style="color:red">*</span><span style="color:red">*</span><span
          style="color:red">*</span>{{'app_action_message'|translate}}
        <span style="color:red">*</span><span style="color:red">*</span><span style="color:red">*</span>
      </p>
    </div>
  </div>

  <div class="vehicle_form_box" *ngIf="showAssignDock==true">
    <ng-container class="col-md-6 modal_col">
      <div class="field">

        <!-- for already assigned dock -->
        <div class="box-wrapper" *ngIf="!isEditTxn">
          <div class="header">
            <div style="width:80%;padding-left: 9pc">Assigned Dock</div>
          </div>
          <div class="dock">
            <ng-container
            *ngFor="let item of dockData">
            <ng-container *ngIf="selectedDockId==null|| item.stage_destination_code_id==selectedDockId">
              <div class="assigned_dock_slot" *ngIf="item.is_assigned==true"
                [ngStyle]="{'background-color': item.stage_destination_code_id === stage_destination_code_id ? '#ebefd8' : '#eaf1b0'}"

                >
                <div class="action" style="display:flex;justify-content:end;margin-left:17pc;margin-top:3px" [hidden]="item.stage_destination_code_id==inStageDestinatonCodeId">
                  <button class="btn btn-success mr-1 ml-2" (click)="selectEdit(item.stage_destination_code_id,item.id);selectedDock(item)" [ngClass]="!isEditTxn?'editButton':'closeButton'"><span [ngClass]="isEditTxn ? 'fa fa-close':'fa fa-edit'"></span></button>
                  <button class="btn btn-danger mr-2 closeButton" style="background-color:#f31c40" (click)="selectDelete(true,item);"><span class="fa fa-trash"></span></button>
                </div>
                <div class="header-slot" [ngStyle]="{'margin-top':item.stage_destination_code_id==inStageDestinatonCodeId ? '5px':'-30px'}">{{ item.stage_destination_code }}
                  <span class="badge badge-info" *ngIf="item.stage_destination_code_id == currentDockId">
                    current
                  </span>
                  <span *ngIf="item.stage_destination_code_id == stage_destination_code_id && isEditTxn"
                    style="text-align:cente;display:inline-flex;padding-left:10px;font-size: 12px;">
                    <input type="checkbox" name="dock" [checked]="isDockPriority" [ngModel]="isDockPriority"
                      (change)="changeDockPriority($event)" style="margin-right:3px"> Priority
                  </span>
                </div>

                <div class="slot details row">
                  <div>
                    <!-- <span class="badge badge-info mr-2" *ngIf="item.stage_destination_code_id == currentDockId">
                      current
                    </span>
                    <span class="badge badge-info" *ngIf="isDockPriority==true">
                      priority
                    </span> -->
                    <span><i class="fas fa-truck"></i> AL:{{ item.vehicle_limit }}</span>
                    <span><i class="fas fa-truck"></i> CV:{{ item.current_vehicle }}</span>
                  </div>
                  <div style="font-size: small;
                  font-weight: 600;">
                    <span class="status-badge badge-success" title={{item.product_name}} *ngIf="item.product_name!=null">{{item.product_name| slice:0:9}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
            </ng-container>

            <div class="not-found" *ngIf="assignedCount == 0">
              No Assigned Dock
            </div>
          </div>
          <!-- <div class="info-header">
            <span class="badge badge-info">AL: Allowed Vehicle Limit</span>
            <span class="badge badge-info">CV: Current Vehicle</span>
          </div> -->
        </div>



        <div class="box-wrapper" *ngIf="!isEditTxn">
          <div class="header">
            Select New Dock
          </div>
          <div class="dock">
            <ng-container *ngFor="let item of dockData">
              <div class="dock_slot" *ngIf="item.is_assigned==false"
                [ngStyle]="{'background-color': item.stage_destination_code_id === stage_destination_code_id ? '#d8efd8' : item.vehicle_limit <= item.current_vehicle ? '#ff9494' : '#b0f1b0'}"
                (click)="selectedDock(item)"
                [class.active]="item.stage_destination_code_id === stage_destination_code_id">
                <div class="header-slot">{{ item.stage_destination_code }}
                  <span class="badge badge-info" *ngIf="item.stage_destination_code_id == currentDockId">
                    current
                  </span>
                  <span *ngIf="item.stage_destination_code_id == stage_destination_code_id"
                    style="text-align:cente;display:inline-flex;padding-left:10px;font-size: 12px;">
                    <input type="checkbox" name="dock" [checked]="isDockPriority" [ngModel]="isDockPriority"
                      (change)="changeDockPriority($event)" style="margin-right:3px"> Priority
                  </span>
                </div>

                <div class="slot details row">
                  <div>
                    <span><i class="fas fa-truck"></i> AL:{{ item.vehicle_limit }}</span>
                    <span><i class="fas fa-truck"></i> CV:{{ item.current_vehicle }}</span>
                  </div>
                  <div style="font-size: small;
                  font-weight: 600;">
                    <span class="status-badge badge-success" title={{item.product_name}} *ngIf="item.product_name!=null">{{item.product_name| slice:0:9}}</span>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="not-found" *ngIf="unassignedCount == 0">
              No New Dock Found
            </div>
          </div>
          <div class="info-header">
            <span class="badge badge-info">AL: Allowed Vehicle Limit</span>
            <span class="badge badge-info">CV: Current Vehicle</span>
          </div>
        </div>



        <div class="box-wrapper" *ngIf="isEditTxn">
          <div class="header" style="display:flex">
            <div style="width:90%;padding-left: 5pc">Edit Dock</div>
            <button type="button" style="width:10%"class="pill1 bg-green-400 text-green-900" (click)="selectEdit(selectedDockId,selectedSlotQueueId)">
              {{ 'Back' }}
            </button>
          </div>
          <div class="dock">
            <ng-container *ngFor="let item of dockData">
              <div class="dock_slot" *ngIf="item.is_assigned==false || item.stage_destination_code_id==selectedDockId"
                [ngStyle]="{'background-color': item.stage_destination_code_id === stage_destination_code_id ? '#d8efd8' : item.vehicle_limit <= item.current_vehicle ? '#ff9494' : '#b0f1b0'}"
                (click)="selectedDock(item)"
                [class.active]="item.stage_destination_code_id === stage_destination_code_id">
                <div class="header-slot">{{ item.stage_destination_code }}
                  <span class="badge badge-info" *ngIf="item.stage_destination_code_id == selectedDockId">
                    current
                  </span>
                  <span *ngIf="item.stage_destination_code_id == stage_destination_code_id"
                    style="text-align:cente;display:inline-flex;padding-left:10px;font-size: 12px;">
                    <input type="checkbox" name="dock" [checked]="isDockPriority" [ngModel]="isDockPriority"
                      (change)="changeDockPriority($event)" style="margin-right:3px"> Priority
                  </span>
                </div>

                <div class="slot details row">
                  <div>
                    <span><i class="fas fa-truck"></i> AL:{{ item.vehicle_limit }}</span>
                    <span><i class="fas fa-truck"></i> CV:{{ item.current_vehicle }}</span>
                  </div>
                  <div style="font-size: small;
                  font-weight: 600;">
                    <span class="status-badge badge-success" title={{item.product_name}} *ngIf="item.product_name!=null">{{item.product_name| slice:0:9}}</span>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="not-found" *ngIf="dockData.length == 0">
              No Dock Found
            </div>
          </div>
          <div class="info-header">
            <span class="badge badge-info">AL: Allowed Vehicle Limit</span>
            <span class="badge badge-info">CV: Current Vehicle</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="vehicle_form_box" *ngIf="showAssignParkingSlot==true">
    <div class="field col-md-4 col-sm-12" *ngIf="plantParkingType=='Both'">
      <label for="my-input"> {{("plant" | translate)+" "+("parking" | translate)+" "+("type" | translate)}}</label>
      <select name="plantParkingType" id="#fieldType" class="form-control" [(ngModel)]="parkingType"
        (change)="changeParkingType()">
        <option value="Open_Parking">Open Parking</option>
        <option value="Slot_Parking">Slot Parking</option>
      </select>
    </div>
    <div class="field col-md-4 col-sm-12" *ngIf="parkingType=='Slot_Parking'">
      <label for="my-input"> {{("parking" | translate)+" "+("type" | translate)}}</label>
      <select name="parkingOn" id="#fieldType" class="form-control" (change)="changeParkingList(parkingOn)"
        [(ngModel)]="parkingOn">
        <option value="stage">Stage</option>
        <option value="zone">Zone</option>
      </select>
    </div>
    <div class="field col-md-4 col-sm-12" *ngIf="parkingType=='Slot_Parking'&& parkingOn=='stage'">
      <label>Select Stage</label>
      <ps-auto-suggestion (onSelected)="selectedParkingType($event)" [data]="parkingTypeList" display="stage_name"
        placeholder="Choose stage" [preSelected]="{stage_name:selectedParkingTypeName}">
      </ps-auto-suggestion>
    </div>
    <div class="field col-md-4 col-sm-12" *ngIf="parkingType=='Slot_Parking'&& parkingOn=='zone'">
      <label>Select Zone</label>
      <ps-auto-suggestion (onSelected)="selectedParkingType($event)" [data]="parkingTypeList" display="zone_name"
        placeholder="Choose zone" [preSelected]="{zone_name:selectedParkingTypeName}">
      </ps-auto-suggestion>
    </div>
    <div class="field col-md-6 col-sm-12" *ngIf="parkingType=='Open_Parking'">
      <label>Select Stage</label>
      <ps-auto-suggestion (onSelected)="selectedParkingType($event)" [data]="parkingTypeList" display="stage_name"
        placeholder="Choose stage" [preSelected]="{stage_name:selectedParkingTypeName}">
      </ps-auto-suggestion>
    </div>

  </div>
  <div class="box-wrapper" *ngIf="showAssignParkingSlot==true && availableParkings.length">
    <div class="header">
      Select Parking
    </div>
    <div class="box-container">
      <ng-container *ngFor="let item of availableParkings">
        <div class="parking_slot"
          [ngClass]="{'box': true, 'selected': item.stage_destination_code_id === selectedParking}"
          (click)="selectParking(item)" *ngIf="parkingType=='Slot_Parking'">
          <i class="fas fa-truck"></i>
          <span>{{ item.stage_destination_code }}</span>
        </div>
        <div class="parking_yard"
          [ngClass]="{'box': true, 'selected': item.stage_destination_code_id === selectedParking}"
          (click)="selectParking(item)" *ngIf="parkingType=='Open_Parking'">
          <i class="fas fa-truck"></i>
          <span>{{ item.stage_destination_code }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="mobileFieldsCount==0" [hidden]="remarkModal">
  <button class="btn btn-secondary" type="button" outline (click)="draftDetails()" id="cancel"
  *ngIf="showAssignDock==false && showAssignParkingSlot==false && !formDetails.txnDetailId" style="cursor: pointer;"
  [disabled]="activeTab=='rejected'||activeTab=='completed'">{{'draft'|translate}}</button>
  <button class="btn btn-success" type="button" outline (click)="saveDetails('PASS')" id="cancel"
    *ngIf="showAssignDock==false && showAssignParkingSlot==false" style="cursor: pointer;"
    [disabled]="activeTab=='rejected'||activeTab=='completed'">{{'approve'|translate}}</button>
  <button class="btn btn-danger" type="button" outline id="submit"
    *ngIf="showAssignDock==false && showAssignParkingSlot==false" style="cursor: pointer;"
    [disabled]="activeTab=='rejected'||activeTab=='completed'" data-toggle="modal" data-target="#exampleModal1"
    (click)="remarkModal=true">{{'reject'|translate}}</button>
  <button class="btn btn-success" type="button" outline id="cancel" (click)="assignDock()"
    *ngIf="(showAssignDock==true && dockData.length != 0)||showAssignParkingSlot==true" style="cursor: pointer;"
    [disabled]="activeTab=='rejected'||activeTab=='completed'">{{'assign'|translate}}</button>
</div>

<div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{formName}} Fail Remark</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="remarkModal=false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="message-text" class="col-form-label">Message:</label>
            <textarea class="form-control" id="message-text" type="text" [(ngModel)]="commentMessage" name="message-text" #name="ngModel" placeholder="Enter Remark"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="addCommentForTrip()">Skip</button>
        <button type="button" class="btn btn-primary" (click)="addCommentForTrip()" data-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>

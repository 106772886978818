import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AssetLabelService extends ApiService{

  saveAssetLabel(data:any):Observable<any>{
    console.log("params",data);
    let _url="asset_labels/create-label";
    return super.post(_url,data);
  }

  getAssetLabel(params:any):any{
    return super.get("asset_labels/get-all-labels",params);
  }
}

import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';

declare var $ : any;
@Component({
  selector: 'app-asset-document',
  templateUrl: './asset-document.component.html',
  styleUrls: ['./asset-document.component.scss']
})
export class AssetDocumentComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'Document List');


  documentTypeList:any = [];
  assetDocumentList:any = [];
  documentType:string = null;
  documentUrl:string = null;
  fileEvent: any = [];
  expiryDate:any=null;
  documentName:string = null;
  @Input() public assetId:number;






  constructor(
    private api: ApiService,
    public table: TableService,
    private common: CommonService,
    public modalService: NgbModal
  ) {
    console.log('this.assetId : ', this.assetId );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getDocumentType()
    this.getAssetDocumentData()
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  uploadFile(event: any) {
    let frmData = new FormData();
    for (const file of event.target.files) {
      frmData.append('files', file);
      this.fileEvent.inProgress = true;
      this.api
        .post('functional/upload-file?methodId=' + 17, frmData)
        .subscribe((res: any) => {
          switch (res.data.type) {
            case HttpEventType.UploadProgress:
              this.fileEvent.progress = Math.round(
                (res.data.loaded * 100) / res.data.total
              );
              break;
            case HttpEventType.Response:
              return res.data;
          }
          res.data.forEach((e) => {
            this.documentUrl = e.fileUrl;
          });
        });
      catchError((error: HttpErrorResponse) => {
        this.fileEvent.inProgress = false;
        return `${this.fileEvent.data.name} upload failed.`;
      });
    }
  }

  getDocumentType(){
    this.api.get('document/get-document-type').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.documentTypeList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );

  }

  saveDocument(){
    let data = {
     document : JSON.stringify([this.documentUrl]),
     documentTypeId: this.documentType,
     documentExpiryDate : this.expiryDate,
     entityType : "Asset",
     refId:this.assetId
    }
    this.api.post('document/save-document',data).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.documentUrl = null;
          this.documentType = null;
          this.expiryDate = null;
          $('#exampleModal').modal('hide');
          this.getAssetDocumentData();

        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );

  }

  selectedDocument(event:any){
    this.documentName = event.type_name;
    this.documentType = event.id;
  }

  getAssetDocumentData(){
    this.api.get('document/get-document-by-asset',{assetId:this.assetId}).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.assetDocumentList = res.data;
          // this.renderTable();
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  detachDocument(documentId:number){
    this.api.get('document/detach-asset-document',{documentId:documentId}).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.getAssetDocumentData();
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  confirmDeleteMapping(row:any){
    this.common.params = {
      title: 'Detach Document ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to detach ' +
        row.document[0]+
        ' ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.detachDocument(row.document_id);
      }
    });
  }
}

import { Observable } from 'rxjs';
import { ApiService } from '../../@core/services/api.service';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TmgService {
  dateObject: any = [];
  apiParameter: any = [];
  startdate: any;
  EndDate: any;
  processArray: any = [];
  processId: number = 0;

  //  processname

  constructor(public datePipe: DatePipe, public api: ApiService) {}

  processChange(event: any) {
    //console.log('processArray',this.processArray);
    this.processId = this.processArray[event.index].processId;
    //console.log('this.processId: ', this.processId);
  }

  getlastWeek(date: any) {
    let lastday = date.getDate() - date.getDay();
    return new Date(date.setDate(lastday));
  }

  DateResponse(type: string, number?: number) {
    if (number) {
      if (type == 'Days') {
        let date: any = new Date();
        this.EndDate = date - 1000 * 60 * 60 * 24 * 1;
        this.startdate = this.EndDate - 1000 * 60 * 60 * 24 * (number - 1);
      }

      if (type == 'Live') {
        let date: any = new Date();
        this.EndDate = date;
        this.startdate = this.EndDate;
      }

      if (type == 'Week') {
        let date = new Date();
        this.EndDate = this.getlastWeek(date);
        this.startdate =
          this.EndDate - 1000 * 60 * 60 * 24 * (number * (7 - 1));
      }

      if (type == 'Month') {
        let date = new Date();
        this.startdate = new Date(
          date.getFullYear(),
          date.getMonth() - number,
          1
        );
        this.EndDate = new Date(date.getFullYear(), date.getMonth(), 0);
      }

      this.dateObject = [
        {
          fromDate: `${this.datePipe.transform(
            this.startdate,
            'yyyy-MM-dd'
          )} 00:00:00`,
        },
        {
          toDate: `${this.datePipe.transform(
            this.EndDate,
            'yyyy-MM-dd'
          )} 23:59:59`,
        },
      ];
    } else {
      this.dateObject = [
        {
          fromDate: `${this.datePipe.transform(
            this.startdate,
            'yyyy-MM-dd'
          )} 00:00:00`,
        },
        {
          toDate: `${this.datePipe.transform(
            this.EndDate,
            'yyyy-MM-dd'
          )} 23:59:59`,
        },
      ];
    }

    this.apiParameter = [];
    this.apiParameter.push({
      ...this.dateObject[0],
      ...this.dateObject[1],
      processId: this.processId,
      type: type,
    });

    return this.apiParameter[0];
  }

  DefaultDateRangeCompare(type: string, number?: number) {
    let fromDate1: any;
    let toDate1: any;
    let fromDate2: any;
    let toDate2: any;

    if (type == 'Month') {
      let date = new Date();
      fromDate1 = new Date(date.getFullYear(), date.getMonth() - 2, 1);
      toDate1 = new Date(date.getFullYear(), date.getMonth() - 1, 0);
      fromDate2 = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      toDate2 = new Date(date.getFullYear(), date.getMonth(), 0);
    }

    if (type == 'Week') {
      let date = new Date();
      let lastday = date.getDate() - date.getDay();
      fromDate1 = new Date(date.setDate(lastday - 13));
      toDate1 = new Date(date.setDate(lastday - 7));
      fromDate2 = new Date(date.setDate(lastday - 6));
      toDate2 = new Date(date.setDate(lastday));
    }

    this.dateObject = [
      {
        fromDate1: `${this.datePipe.transform(
          fromDate1,
          'yyyy-MM-dd'
        )} 00:00:00`,
      },
      { toDate1: `${this.datePipe.transform(toDate1, 'yyyy-MM-dd')} 23:59:59` },
      {
        fromDate2: `${this.datePipe.transform(
          fromDate2,
          'yyyy-MM-dd'
        )} 00:00:00`,
      },
      { toDate2: `${this.datePipe.transform(toDate2, 'yyyy-MM-dd')} 23:59:59` },
    ];

    this.apiParameter = [];

    this.apiParameter.push({
      ...this.dateObject[0],
      ...this.dateObject[1],
      ...this.dateObject[2],
      ...this.dateObject[3],
      processId: this.processId,
      type: type,
    });

    return this.apiParameter[0];
  }

  DateResponseCompare(
    type: string,
    fromDate1: string,
    toDate1: string,
    fromDate2: string,
    toDate2: string
  ) {
    this.dateObject = [
      {
        fromDate1: `${this.datePipe.transform(
          fromDate1,
          'yyyy-MM-dd'
        )} 00:00:00`,
      },
      { toDate1: `${this.datePipe.transform(toDate1, 'yyyy-MM-dd')} 23:59:59` },
      {
        fromDate2: `${this.datePipe.transform(
          fromDate2,
          'yyyy-MM-dd'
        )} 00:00:00`,
      },
      { toDate2: `${this.datePipe.transform(toDate2, 'yyyy-MM-dd')} 23:59:59` },
    ];

    this.apiParameter = [];
    this.apiParameter.push({
      ...this.dateObject[0],
      ...this.dateObject[1],
      ...this.dateObject[2],
      ...this.dateObject[3],
      processId: this.processId,
      type: type,
    });

    return this.apiParameter[0];
  }
}

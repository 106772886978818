<div class="add_compliance" id="exampleModal">
  <div class="modal_header">
    <h5>{{'Add Compliance'|customTranslate}}</h5>
  </div>
  <div class="modal_body">
    <div class="table_format">


      <div class="right_side">

        <div class="tab_content">
          <div class="field">
            <ps-auto-suggestion *ngIf="complianceList.length != 0" (onSelected)="selectCompliance($event,'select')" (onChange)="selectCompliance($event,'input')" class="required" [data]="complianceList"
           display="title" placeholder="Select Compliance Title"  [preSelected]="{title:complianceError.title}"
          >
        </ps-auto-suggestion>
        <input type="text" class="required field_class" [(ngModel)]="complianceError.title" placeholder="{{'Select Compliance Title' | customTranslate}}"
        *ngIf="complianceList.length == 0">
        <div class="error" *ngIf="complianceValidator && complianceValidator.error">
          <span>{{complianceValidator.msg}} </span>
        </div>
          </div>
        </div>
        <div class="message_box">
          <textarea rows="3" class="form-control" placeholder="{{'Enter Remarks...'| customTranslate}}"  [(ngModel)]="complianceError.complianceData.remark"></textarea>
          <div class="upload-btn-wrapper">
            <button class="btn-upload"><i class="fas fa-paperclip" (click)="myInput.click()"></i></button>
            <span
            *ngIf="complianceError.complianceData.imageFile!=null">{{imageName(complianceError.complianceData.imageFile)}}</span>
            <input type="file"  #myInput accept=".jpg,.png,.jpeg,.pdf" (change)="onSelect($event);" name="myfile" />
          </div>
        </div>
        <div class="error" *ngIf="remarkValidator && remarkValidator.error" style="padding-left: 10px;top:-5px;">
          <span>{{remarkValidator.msg}} </span>
        </div>
      </div>
    </div>

    <div class="submit_btn">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="activeModal.close();">{{'close'|customTranslate}}</button>
      <button type="button" class="btn btn-success" (click)="saveComplianceError()">{{'save'|customTranslate}}</button>
    </div>
  </div>
</div>

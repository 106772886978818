<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">{{'Action Data'|translate}}</h6>
  <div aria-label="Close" (click)="closeModal()"><i class="fa fa-times"></i></div>
</div>
<div class="row modal-body" id="action_id">
  <div class="action_name_div" *ngIf="this.currentActionData!=[] && actionType=='pre'">
    <div *ngFor="let data of currentActionData">
      <div class="action_title_div">
        <h5>{{actionName}}</h5>
        <span class="badge status-badge" *ngIf="data.response_status!=null"
          [ngClass]="{'badge-success':data.response_status == 'PASS','badge-warning':data.response_status == 'OVERRIDED','badge-danger':data.response_status == 'FAIL','badge-secondary':data.response_status == 'PENDING' || data.response_status == 'PREPENDING'}">{{data.response_status!='PREPENDING'
          ? (data.response_status|translate) : "PENDING"}}</span>
        <span class="badge status-badge" *ngIf="data.response_status==null"
          [ngClass]="{'badge-secondary':'PENDING'}">{{'PENDING'|translate}}</span>
      </div>

      <div class="form_sec" *ngIf="data.response_value.length">
        <div class="data-view">
          <ng-container *ngFor="let response of data.response_value">
            <div class="col-md-12" *ngIf="response.fieldType=='LABEL'">
              <label for="email">{{response.name}}</label>
              <p style="font-size:small" [innerHTML]="response.values"></p>
            </div>
            <div *ngIf="response.fieldType=='FILE'||response.fieldType=='SIGNATURE'">
              <div class="form-group">
                <label for="email">{{response.name}}</label>
                {{imageName(response.values)}}
                <div class="image_div" *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                  <div class="image" (click)="displayImage(response.values)">
                    <img [src]="image_url" alt="">
                  </div>
                  <div class="img_name">{{" "+this.image_name}}</div>
                </div>

                <div class="image_div" *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                  <div class="image" (click)="newTab(response.values)">
                    <img src="assets/images/document_img.png">
                  </div>
                  <div class="img_name">{{" "+this.image_name}}</div>
                </div>
              </div>
            </div>
            <div
              *ngIf="response.fieldType=='RFIDCODEWITHIMAGE'||response.fieldType=='BARCODEWITHIMAGE'||response.fieldType=='GEOCODEDIMAGE'||response.fieldType=='CAMERASTREAMING'||response.fieldType=='IMAGE'">
              <div class="form-group">
                <label for="email">{{response.name}}</label>
                {{response.fieldType=='CAMERASTREAMING'||response.fieldType=='IMAGE' ? imageName(response.values[0])
                :imageName(response.values[0]?.fileUrl)}}
                <div class="image_div" *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                  <div class="image"
                    (click)="displayImage(response.fieldType=='CAMERASTREAMING'||response.fieldType=='IMAGE' ? response.values[0] :response.values[0].fileUrl)">
                    <img [src]="image_url" alt="">
                  </div>
                  <div class="map_id" *ngIf="response.fieldType!='CAMERASTREAMING' && response.fieldType!='IMAGE'">
                    <img (click)="location(response.values[0].lat,response.values[0].lng)"
                      src="assets/images/google_map_icon.png" />

                    <div class="row id_class"
                      *ngIf="response.fieldType!='GEOCODEDIMAGE' && response.fieldType!='CAMERASTREAMING'"><strong>Id:
                      </strong>
                      <div class="img_name">{{" "+ response.values[0].value}}</div>
                    </div>
                  </div>
                </div>

                <div class="image_div" *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                  <div class="image"
                    (click)="newTab(response.fieldType=='CAMERASTREAMING' ||response.fieldType=='IMAGE' ? response.values[0] :response.values[0]?.fileUrl)">
                    <img src="assets/images/document_img.png">
                  </div>
                  <div class="map_id" *ngIf="response.fieldType!='CAMERASTREAMING'&&response.fieldType!='IMAGE'">
                    <img (click)="location(response.values[0].lat,response.values[0].lng)"
                      src="assets/images/google_map_icon.png" />

                    <div class="row id_class"
                      *ngIf="response.fieldType!='GEOCODEDIMAGE' && response.fieldType!='CAMERASTREAMING'"><strong>Id:
                      </strong>
                      <div class="img_name">{{" "+response.values[0].value}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="response.fieldType=='VIDEOSTREAMING'">
              <div class="form-group">
                <label for="email">{{response.name}}</label>
                {{imageName(response.values[0])}}
                <video [height]="180" [width]="250" controls preload="auto">
                  <source [src]="response.values[0]">
                </video>
              </div>
            </div>
            <div
              *ngIf="response.fieldType!='LABEL'&&response.fieldType!='FILE'&&response.fieldType!='RFIDCODEWITHIMAGE'&&response.fieldType!='BARCODEWITHIMAGE'&&response.fieldType!='GEOCODEDIMAGE'&&response.fieldType!='SIGNATURE'&&response.fieldType!='CAMERASTREAMING'&&response.fieldType!='VIDEOSTREAMING'&&response.fieldType!='IMAGE'">
              <div class="form-group" *ngIf="response.fieldType!='RFIDCODE'&& response.fieldType!='BARCODE'">
                <label for="email">{{response.name}}</label>
                <div class="text_div">
                  <input type="text" class="form-control" [value]="response.values" disabled>
                </div>
              </div>
              <div class="form-group" *ngIf="response.fieldType=='RFIDCODE'||response.fieldType=='BARCODE'">
                <label for="email">{{response.name}}</label>
                <div class="text_div">
                  <input type="text" class="form-control" [value]="response.values[0].value" disabled>
                </div>
                <div class="map_id">
                  <img (click)="location(response.values[0].lat,response.values[0].lng)"
                    src="assets/images/google_map_icon.png" />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="action_data_div" *ngIf="this.preActionsData.length!=0">
    <div class="action_title_div">
      <h5>{{'pre_action_data'|translate}}</h5>
    </div>
    <div *ngFor="let preData of preActionsData;let i=index">
      <div class="action_colapse_div">
        <div class="action_name">
          <h5>{{preData.action_name}}</h5>
          <div class="badge_div">
            <span class="badge status-badge" *ngIf="preData.response_status!=null"
              [ngClass]="{'badge-success':preData.response_status == 'PASS','badge-warning':preData.response_status == 'OVERRIDED','badge-danger':preData.response_status == 'FAIL','badge-secondary':preData.response_status == 'PENDING' || preData.response_status == 'PREPENDING'}">{{preData.response_status!='PREPENDING'
              ? (preData.response_status|translate) : "PENDING"}}</span>
            <span class="badge status-badge" *ngIf="preData.response_status==null"
              [ngClass]="{'badge-secondary':'PENDING'}">{{'PENDING'|translate}}</span>
          </div>
          <i class="fa fa-info-circle" (click)="accordion1(i)" *ngIf="preData.action_operation_type!='boolean'"></i>
        </div>
        <div class="accordion_sec" *ngIf="hideShow1[i].boolean && preData.response_value.length ">
          <div class="form_sec">
            <div class="row">
              <ng-container *ngFor="let res of preData.response_value">
                <div class="col-md-12" *ngIf="res.fieldType=='LABEL'">
                  <label for="email">{{res.name}}</label>
                  <p style="font-size:small" [innerHTML]="res.values"></p>
                </div>
                <div *ngIf="res.fieldType=='FILE'||res.fieldType=='SIGNATURE'">
                  <div class="form-group">
                    <label for="email">{{res.name+" "}}</label>
                    {{imageName(res.values)}}
                    <div class="image_div" *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                      <div class="image" (click)="displayImage(res.values)">
                        <img [src]="image_url" alt="">
                      </div>
                      <div class="img_name">{{" "+this.image_name}}</div>
                    </div>

                    <div class="image_div" *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                      <div class="image" (click)="newTab(res.values)">
                        <img src="assets/images/document_img.png">
                      </div>
                      <div class="img_name">{{" "+this.image_name}}</div>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="res.fieldType=='RFIDCODEWITHIMAGE'||res.fieldType=='BARCODEWITHIMAGE'||res.fieldType=='GEOCODEDIMAGE'||res.fieldType=='CAMERASTREAMING'||res.fieldType=='IMAGE'">
                  <div class="form-group">
                    <label for="email">{{res.name}}</label>
                    {{res.fieldType=='CAMERASTREAMING'||res.fieldType=='IMAGE' ? imageName(res.values[0])
                    :imageName(res.values[0]?.fileUrl)}}
                    <div class="image_div" *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                      <div class="image"
                        (click)="displayImage(res.fieldType=='CAMERASTREAMING'||res.fieldType=='IMAGE' ? res.values[0] :res.values[0].fileUrl)">
                        <img [src]="image_url" alt="">
                      </div>
                      <div class="map_id" *ngIf="res.fieldType!='CAMERASTREAMING'&&res.fieldType!='IMAGE'">
                        <img (click)="location(res.values[0].lat,res.values[0].lng)"
                          src="assets/images/google_map_icon.png" />

                        <div class="row id_class"
                          *ngIf="res.fieldType!='GEOCODEDIMAGE' && res.fieldType!='CAMERASTREAMING'"><strong>Id:
                          </strong>
                          <div class="img_name">{{" "+res.values[0].value}}</div>
                        </div>
                      </div>
                    </div>

                    <div class="image_div" *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                      <div class="image"
                        (click)="newTab(res.fieldType=='CAMERASTREAMING'||res.fieldType=='IMAGE' ? res.values[0] :res.values[0].fileUrl)">
                        <img src="assets/images/document_img.png">
                      </div>
                      <div class="map_id" *ngIf="res.fieldType!='CAMERASTREAMING' && res.fieldType!='IMAGE'">
                        <img (click)="location(res.values[0].lat,res.values[0].lng)"
                          src="assets/images/google_map_icon.png" />

                        <div class="row id_class"
                          *ngIf="res.fieldType!='GEOCODEDIMAGE' && res.fieldType!='CAMERASTREAMING'"><strong>Id:
                          </strong>
                          <div class="img_name">{{" "+res.values[0].value}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="res.fieldType=='VIDEOSTREAMING'">
                  <div class="form-group">
                    <label for="email">{{res.name}}</label>
                    {{imageName(res.values[0])}}
                    <video [height]="180" [width]="250" controls preload="auto">
                      <source [src]="res.values[0]">
                    </video>
                  </div>
                </div>
                <div
                  *ngIf="res.fieldType!='LABEL'&& res.fieldType!='FILE'&&res.fieldType!='RFIDCODEWITHIMAGE'&&res.fieldType!='BARCODEWITHIMAGE'&&res.fieldType!='GEOCODEDIMAGE'&&res.fieldType!='SIGNATURE'&&res.fieldType!='CAMERASTREAMING'&&res.fieldType!='VIDEOSTREAMING'&&res.fieldType!='IMAGE'">
                  <div class="form-group" *ngIf="res.fieldType!='RFIDCODE'&& res.fieldType!='BARCODE'">
                    <label for="email">{{res.name}}</label>
                    <div class="text_div">
                      <input type="text" class="form-control" [value]="res.values" disabled>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="res.fieldType=='RFIDCODE'||res.fieldType=='BARCODE'">
                    <label for="email">{{res.name}}</label>
                    <div class="text_div">
                      <input type="text" class="form-control" [value]="res.values[0].value" disabled>
                    </div>
                    <div class="map_id">
                      <img (click)="location(res.values[0].lat,res.values[0].lng)"
                        src="assets/images/google_map_icon.png" />

                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="action_data_div"
    *ngIf="this.response.length==0 || (this.currentActionData.length==0 && this.preActionsData.length==0 && actionType=='pre'&& !common.loading)">
    <p style="color:red;margin-left: 160px;">{{'no_record_found'|translate}}</p>
  </div>

  <div class="action_data_div" *ngIf="this.preActionsData.length==0 && actionType=='post'&& !common.loading">
    <p style="color:red;margin-left: 160px;">{{"no_record_found"|translate}}</p>
  </div>

</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="tab_section">
        <ng-container *ngFor="let tabitem of tabData">
          <div class="tab" [class.active]="activeSection == tabitem.value"
            (click)="activeSection = tabitem.value; reset()">
            <p>{{tabitem.tabname|translate}}</p>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{('visitor'|translate)+' '+('report'|translate)}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <section class="open_sec">
                <div class="page_design">
                  <div class="row tab_sec m-0">
                    <div class="col-md-12 col-12 date_div p-0">
                      <div class="row">

                        <div class="col-md-3 date">
                          <label>{{'start_date:'|translate}}</label>
                          <input type="date" class="form-control" [(ngModel)]="startDate" [max]="endDate">
                        </div>
                        <div class="col-md-3 date">
                          <label>{{'end_date:'|translate}}</label>
                          <input type="date" class="form-control" [min]="startDate" [(ngModel)]="endDate">
                        </div>
                        <div class="col-md-1 btn-block text-right">
                          <button class="btn btn-primary" (click)="getVisitorReport()">{{"search" | translate}}</button>
                        </div>
                      </div>


                    </div>
                  </div>


                  <div class="view_table">
                    <div class="row" style="display: flex; align-items: flex-end;">

                      <div *ngIf="activeSection=='visit'" class="col-md-3 date">
                        <label>{{'select_process:'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="listOfProcess"
                          display="processName" placeholder="Select Process" [preSelected]="{processName:processName}">
                        </ps-auto-suggestion>
                      </div>


                      <div class="col-md-3 date">
                        <label>{{'meet_with'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedHost($event)" [data]="hostList" display="hostName"
                          placeholder="Meet with">
                        </ps-auto-suggestion>
                      </div>


                      <div class="col-md-3 date">
                        <label>{{'select_visitor'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedVisitor($event)" [data]="visitorList"
                          display="visitorName" placeholder="Select Visitor">
                        </ps-auto-suggestion>
                      </div>

                      <div class="col-md-3 date">
                        <div class="btn-block">

                          <button class="btn btn-warning" (click)="reset()"> {{ "reset" | translate }} </button>

                        </div>
                      </div>


                    </div>



                    <div class="table_sec">
                      <table class="custom-table" *ngIf="activeSection=='visit'">
                        <thead>
                          <tr>
                            <th>S.NO.</th>
                            <th>Visitor Name</th>
                            <th>Meet With</th>
                            <th>Visit From</th>
                            <th>Visit Date</th>
                            <th>Visit Status</th>
                            <th>In Time</th>
                            <th>Out Time</th>
                            <th>Visit TAT/Expected TAT</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let data of visitorReportData, let i = index">
                            <ng-container
                              *ngIf="allFilters || ( (!hostId || hostId == data.visit_to_id) && (!visitorId || visitorId == data.staff_id) )">
                              <td>{{i+1}}</td>
                              <td>{{data.visitor_name}}</td>
                              <td>{{data.visit_to}}</td>
                              <td>{{data.visit_from}}</td>
                              <td>{{(common.dateFormatter(data.visit_date,"ddMMYYYY",false))}}</td>
                              <td style="text-align: center;"><span class="pill"
                                  [ngClass]="{'bg-green-200 text-green-800':data.status=='APPROVED','bg-yellow-200 text-yellow-800':data.status=='IN','bg-indigo-100 text-indigo-800':(data.status=='OUT'),'bg-red-200 text-red-800':(data.status=='REJECTED'),'bg-blue-200 text-blue-800':(data.status=='BOOKED')}">
                                  {{data.status}}
                                </span></td>
                              <td>{{data.in_time ? data.in_time : "-"}}</td>
                              <td>{{data.end_time ? data.end_time : "-"}}</td>
                              <td><span
                                  [ngClass]="data.tat > data.expected_tat ? 'badge over' : 'badge'">{{data.tat}}</span>
                                /
                                <span class="badge info">{{data.expected_tat}}</span>
                              </td>
                              <td>
                                <div *ngIf="data.ipa_transaction_id!=null" style="display: flex;">
                                  <img class="icon image" src="assets/images/trip-details(3).png" alt=""
                                    (click)="tripModal(data)" matTooltip="Trip Details">
                                  <img class="icon image" src="assets/images/trip-tracking(3).png" alt=""
                                    (click)="tripTrackingModal(data)">
                                  <img class="icon image" src="assets/images/user-path-tracking.png" alt=""
                                    (click)="openUserTrackingModal(data.staff_id)">
                                </div>
                              </td>
                              <!-- *ngIf="all || ( (!hostId || hostId == data.visit_to) && (!visitorId || checkVisitor(data) ) )"> -->
                              </ng-container>
                            </tr>
                          </table>

                      <table class="custom-table" *ngIf="activeSection=='appointment'">
                        <thead>
                          <tr>
                            <th>S.NO.</th>
                            <th>Meet With</th>
                            <th>Visit Start Time</th>
                            <th>Visit End Time</th>
                            <th>Visit Purpose</th>
                            <th>NO. of Visitors</th>
                            <th>Visit Status</th>
                            <th>Visitor Info</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let data of visitorReportDataByAppointment, let i = index">
                            <ng-container
                              *ngIf="allFilters || ( (!hostId || hostId == data.visit_to_id) && (!visitorId || checkVisitor(data) ) )">
                              <td>{{i+1}}</td>
                              <td>{{data.visit_to}}</td>
                              <td>{{data.start_time}}</td>
                              <td>{{data.end_time}}</td>
                              <td>{{data.purpose}}</td>

                              <td>{{data.no_of_visitors}}</td>
                              <td style="text-align: center;"><span class="pill"
                                  [ngClass]="{'bg-green-200 text-green-800':data.status=='APPROVED','bg-yellow-200 text-yellow-800':data.status=='INPROGRESS','bg-indigo-100 text-indigo-800':(data.status=='COMPLETED'),'bg-red-200 text-red-800':(data.status=='REJECTED'),'bg-blue-200 text-blue-800':(data.status=='BOOKED')}">
                                  {{data.status}}
                                </span></td>
                              <td data-toggle="modal" data-target="#exampleModal"
                                (click)="modalData = data.visitor_data"><img class="icon image"
                                  src="assets/images/trip-details(3).png" alt=""></td>
                            </ng-container>
                          </tr>
                        </table>
                    </div>
                  </div>
                </div>
              </section>


              <div class="modal fade" #detail id="exampleModal" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content" style="width: 100%">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Visitor Info</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <ng-container *ngFor="let visit of modalData; let i = index">
                        <div class="single_card">
                          <div class="show_data">
                            <div class="start">
                              <div class="image1 icon">
                                <img
                                  [src]="visit.visitor_image!=null && visit.visitor_image!=''?visit.visitor_image:'assets/images/user_whatsapp.jpg'" style="aspect-ratio: 1" >
                                </div>
                              </div>
                              <div class="mid">
                              <div class="name"><strong>Name : </strong>{{visit.visitor_name}}</div>
                              <div class="name"><strong>In : </strong>{{visit.in_time|date:'dd-MM-yyyy hh:mm'}}</div>
                              <div><strong>Phone : </strong>{{visit.mobile_no}}</div>
                            </div>

                            <div class="end">
                              <div class="name"><strong>Status : </strong> <span class="pill"
                                  [ngClass]="{'bg-green-200 text-green-800':visit.visitor_status=='APPROVED','bg-yellow-200 text-yellow-800':visit.visitor_status=='IN','bg-indigo-100 text-indigo-800':(visit.visitor_status=='OUT'),'bg-red-200 text-red-800':(visit.visitor_status=='REJECTED'),'bg-blue-200 text-blue-800':(visit.visitor_status=='BOOKED')}">{{visit.visitor_status}}</span>
                              </div>
                              <div class="name" style="font-size: 14px; font-weight: 600;"><strong>Out:
                                </strong>{{visit.out_time|date:'dd-MM-yyyy hh:mm'}}</div>
                              <div class="last_bottom">
                                <div class="ellipsis_sec">


                                  <img src="assets/images/trip-details(3).png" alt="" (click)="tripModal(visit)"
                                    title="Trip Detail" style="height: 15px; width: 15px; margin-right: 5px;"
                                    matTooltipPosition="below">
                                  <img (click)="tripTrackingModal(visit)" src="assets/images/trip-tracking(3).png"
                                    alt="" style="height: 15px; width: 15px; margin-right: 5px;"
                                    matTooltipPosition="below">

                                  <img src="assets/images/user-path-tracking.png" alt="" title="Trip Detail"
                                    (click)="openUserTrackingModal(visit.visitor_id)"
                                    style="height: 15px; width: 15px;">

                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group"
  *ngIf="field.field_type=='TEXTFIELD' && (selvalue==null || customFieldValues[i]?.values.length)">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <input type="text" class="form-control" [id]="'field'+i" (input)="onValueChanged($event)"
    [value]="customFieldValues[i]?.values.length?customFieldValues[i]?.values[0]:''" [disabled]="readOnlyFlag">
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group"
  *ngIf="field.field_type=='NUMBERFIELD'&& (selvalue==null || customFieldValues[i]?.values.length )">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <input type="number" class="form-control" [id]="'field'+i" [disabled]="readOnlyFlag" (input)="onValueChanged($event)"
    [value]="customFieldValues[i]?.values.length?customFieldValues[i]?.values[0]:''">
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group" *ngIf="field.field_type=='TIME'&& (selvalue==null || customFieldValues[i]?.values.length )">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <input type="time" class="form-control" [id]="'field'+i" (input)="onValueChanged($event)"
    [value]="customFieldValues[i]?.values.length?customFieldValues[i]?.values[0]:''" [disabled]="readOnlyFlag">
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group" *ngIf="field.field_type=='URL'&& (selvalue==null || customFieldValues[i]?.values.length )">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <input type="url" class="form-control" [id]="'field'+i" (input)="onValueChanged($event)"
    [value]="customFieldValues[i]?.values.length?customFieldValues[i]?.values[0]:''" [disabled]="readOnlyFlag">
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group"
  *ngIf="field.field_type=='DATETIMEPICKER'&& (selvalue==null || customFieldValues[i]?.values.length )">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <input type="datetime-local" class="form-control" [id]="'field'+i"
    [value]="customFieldValues[i]?.values.length?customFieldValues[i]?.values[0]:''" (input)="onValueChanged($event)"
    [disabled]="readOnlyFlag">
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group" *ngIf="(field.field_type=='FILE' || field.field_type=='IMAGE') && (selvalue==null || customFieldValues[i]?.values.length )">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <div class="image_div" *ngIf="customFieldValues[i]?.values.length  && readOnlyFlag">
    {{imageName(customFieldValues[i]?.values[0])}}
    <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
      <img [src]="image_url" alt="" style="height: 50px; width: 50px;border-radius:10px"><span
        style="font-size:small">{{"
        "+this.image_name}}</span>
    </ng-container>
    <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
      <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
              text-decoration-line: underline;" (click)="newTab(image_url)">{{"
        "+this.image_name}}</span>
    </ng-container>
  </div>
  <div *ngIf="!customFieldValues[i]?.values.length || !readOnlyFlag">
    <input type="file" accept=".jpg,.png,.jpeg,.pdf,.xlsx,.xlsm,.xls" class="field_class file_class" multiple
      [id]="'field'+i" (input)="onValueChanged($event)">
  </div>
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group"
  *ngIf="(field.field_type=='GEOCODEDIMAGE')&& (selvalue==null || customFieldValues[i]?.values.length ) && !manualStamping">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <div class="image_div" *ngIf="customFieldValues[i]?.values.length && retakeImage==false">
    {{imageName(customFieldValues[i]?.values[0])}}
    <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
      <img [src]="image_url" alt="" style="width: 50px;height:50px;border-radius:10px;"><span
        style="font-size:small">{{"
        "+this.image_name}}</span>
    </ng-container>
    <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
      <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
              text-decoration-line: underline;" (click)="newTab(image_url)">{{"
        "+this.image_name}}</span>
    </ng-container>
  </div>
  <app-camera-component [retake]="retakeImage" [id]="'field'+i" (getPicture)="onValueChanged($event)">
  </app-camera-component>
  <div style="display: flex; flex-direction: column; align-items:center;"
    *ngIf="this.customFieldValues[i].values.length && field.field_name==this.customFieldValues[i].name">
    <p>Upload Done! <button class="btn btn-warning"
        (click)="retakeImage = !retakeImage;this.customFieldValues[i].values=[];">Retake</button>
    </p>
  </div>
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>


<div class="form-group" *ngIf="field.field_type=='TEXTAREA'&& (selvalue==null || customFieldValues[i]?.values.length )">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <textarea rows="1" cols="50" [id]="'field'+i"
    [value]="customFieldValues[i]?.values.length?customFieldValues[i]?.values[0]:''" (input)="onValueChanged($event)"
    class="form-control" [disabled]="readOnlyFlag"></textarea>
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group"
  *ngIf="field.field_type=='DATEPICKER'&& (selvalue==null || customFieldValues[i]?.values.length )">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <input type="date" class="form-control" [id]="'field'+i"
    [value]="customFieldValues[i]?.values.length?customFieldValues[i]?.values[0]:''" (input)="onValueChanged($event)"
    [disabled]="readOnlyFlag">
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group"
  *ngIf="field.field_type=='RADIOBUTTON'&& (selvalue==null || customFieldValues[i]?.values.length)">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <div *ngIf="field.options && !readOnlyFlag">
    <ng-container *ngFor="let opt of field.options;index as j">
      <input type="radio" [name]="'field-' + field.master_field_id" [id]="'field'+i" value="{{opt.name}}"
        [checked]="customFieldValues[i]?.values.length ?customFieldValues[i]?.values[0] == opt.name:false"
        (input)="onValueChanged($event)">&nbsp;<label [for]="'field-' + j + field.master_field_id"
        style="margin-right:3px">{{opt.name}}</label>
    </ng-container>
  </div>
  <input type="text" class="form-control" *ngIf="manualStamping && readOnlyFlag"
    [value]="customFieldValues[i]?.values" disabled>
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group" *ngIf="field.field_type=='CHECKBOX' && (selvalue==null || customFieldValues[i]?.values.length)">
  <!-- [ngClass]="{'required':field.is_mandatory==true}" -->
  <label>{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <div *ngIf="field.options && !readOnlyFlag" style="display: flex;">
    <ng-container *ngFor="let opt of field.options;index as j">
      <input type="checkbox" value="{{opt.name}}" [name]="'field'+i + field.master_field_id" [id]="'field'+i"
        [checked]="customFieldValues[i]?.values.length ?customFieldValues[i]?.values.includes(opt.name):false"
        (input)="onValueChanged($event)" [disabled]="readOnlyFlag" style="font-size: 16px;border-radius: 10px;">&nbsp;
      <label [for]="'field'+i + field.master_field_id" style="margin-right: 3px;">{{opt.name}}</label>
    </ng-container>
  </div>
  <input type="text" class="form-control" *ngIf="manualStamping && readOnlyFlag"
    [value]="customFieldValues[i]?.values" disabled>
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group" *ngIf="field.field_type=='SIGNATURE'&& (selvalue==null || customFieldValues[i]?.values.length )"
  (mouseleave)="onValueChanged($event)" style="order:1">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <div class="image_div" *ngIf="customFieldValues[i]?.values.length">
    <img [src]="customFieldValues[i]?.values" alt="" style="width:150px;height:50px;" [id]="'field'+i">
  </div>
  <div *ngIf="!customFieldValues[i]?.values.length || !readOnlyFlag">
    <app-signature (getSignature)="signature.signatureImage=$event"></app-signature>
  </div>

</div>

<div class="form-group"
  *ngIf="field.field_type=='SELECTLISTSINGLE'&& (selvalue==null || customFieldValues[i]?.values.length)">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <ps-auto-suggestion (onSelected)="onValueChanged($event)" [data]="field.options" [display]="field.label"
    [preSelected]="getPreSelected(field.label,i)" placeholder="Choose option" [readonly]="readOnlyFlag">
  </ps-auto-suggestion>
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group"
  *ngIf="field.field_type=='SELECTLISTSINGLE'&& (field.sel_value!=null && !customFieldValues[i]?.values.length)">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <select class="form-control" disabled>
    <option *ngFor="let opt of field.options" [selected]="opt[field.store_value]==field.sel_value"
      [value]="opt[field.store_value]">
      {{opt[field.label]}}</option>
  </select>
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group"
  *ngIf="field.field_type=='SELECTLISTMULTIPLE'&& (selvalue==null || customFieldValues[i]?.values.length )">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <ps-auto-suggestion isMultiSelect="true" (onSelected)="onValueChanged($event)"
    [data]="field.options==null ? [] : field.options" [display]="field.label" placeholder="Choose option"
    [preSelected]="getPreSelectedMulti(field.label,i)" [readonly]="readOnlyFlag">
  </ps-auto-suggestion>
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group"
  *ngIf="field.field_type=='SELECTLISTMULTIPLE'&& (field.sel_value!=null && !customFieldValues[i]?.values.length )">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <ps-auto-suggestion isMultiSelect="true" (onSelected)="onValueChanged($event)"
    [data]="field.multiValueOptions==null ? [] :field.multiValueOptions" [display]="field.label"
    placeholder="Choose option" [preSelected]="getPreSelectedMulti(field.label,i)">
  </ps-auto-suggestion>
  <div class="error" *ngIf="field.master_field_id==notUniqueFieldId">
    <span>{{ "Value isn't unique !!"|translate}}</span>
  </div>
</div>

<div class="form-group" *ngIf="field.field_type=='RFIDCODE' || field.field_type=='BARCODE'">
  <label [ngClass]="{'required':field.is_mandatory==true}">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  <div class="text_div" style="display:flex">
    <input type="text" class="form-control mr-2" [value]="customFieldValues[i]?.values[0]?.value"
      [disabled]="readOnlyFlag" (input)="onValuesEntered($event, 'text')">
    <img (click)="location(customFieldValues[i]?.values[0]?.lat,customFieldValues[i]?.values[0]?.lng)"
      style="height: 20px; width: 20px;" src="assets/images/google_map_icon.png" />
  </div>
</div>

<div class="form-group"
  *ngIf="(field.field_type=='RFIDCODEWITHIMAGE'|| field.field_type=='BARCODEWITHIMAGE' || field.field_type == 'GEOCODEDIMAGE') && manualStamping ">
  <label for="email">{{field.field_name}}
    <i *ngIf="manualStamping && readOnlyFlag" (click)="editTriggerEmit(false)" class="fa fa-edit"></i>
  </label>
  {{imageName(customFieldValues[i]?.values[0]?.fileUrl)}}
  <div>
    <div class="image_div" *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
      <div class="text_div" style="display:flex">
        <input *ngIf="field.field_type != 'GEOCODEDIMAGE'" type="text" class="form-control mr-2" [value]="customFieldValues[i]?.values[0]?.value"
          [disabled]="readOnlyFlag" (input)="onValuesEntered($event, 'text')">
        <img (click)="location(customFieldValues[i]?.values[0]?.lat,customFieldValues[i]?.values[0]?.lng)"
          style="height: 20px; width: 20px;" src="assets/images/google_map_icon.png" />
      </div>
      <div class="image" *ngIf="customFieldValues[i]?.values.length && readOnlyFlag" (click)="displayImage(customFieldValues[i]?.values[0]?.fileUrl)">
        <img [src]="image_url" alt="" style="height: 50px; width: 50px;">
      </div>
      <!-- <div class="map_id" style="display: flex;flex-direction: column;align-items: flex-end;">
        <img (click)="location(customFieldValues[i]?.values[0]?.lat,customFieldValues[i]?.values[0]?.lng)"
          style="height: 20px; width: 20px;float:right" src="assets/images/google_map_icon.png" />
        <div class="row id_class mr-auto" *ngIf="field.field_type != 'GEOCODEDIMAGE'">
          <strong>{{'Value: '}}</strong>
          <div class="img_name">{{" "+ customFieldValues[i]?.values[0]?.value}}</div>
        </div>
      </div> -->
      <div class="image-div" *ngIf="!customFieldValues[i]?.values.length || !readOnlyFlag">
        <input type="file" accept=".jpg,.png,.jpeg" class="field_class file_class" multiple [id]="'field'+i"
          (input)="onValuesEntered($event, 'file')">
      </div>
    </div>

    <div class="image_div" *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
      <div class="text_div" style="display:flex">
        <input *ngIf="field.field_type != 'GEOCODEDIMAGE'" type="text" class="form-control mr-2" [value]="customFieldValues[i]?.values[0]?.value"
          [disabled]="readOnlyFlag" (input)="onValuesEntered($event, 'text')">
        <img (click)="location(customFieldValues[i]?.values[0]?.lat,customFieldValues[i]?.values[0]?.lng)"
          style="height: 20px; width: 20px;" src="assets/images/google_map_icon.png" />
      </div>
      <div class="image" *ngIf="customFieldValues[i]?.values.length && readOnlyFlag" (click)="newTab(customFieldValues[i]?.values[0]?.fileUrl)">
        <img src="assets/images/document_img.png" style="height: 20px; width: 20px;">
      </div>
      <!-- <div class="map_id" style="display: flex;flex-direction: column;align-items: flex-end;">
        <img (click)="location(customFieldValues[i]?.values[0]?.lat,customFieldValues[i]?.values[0]?.lng)"
          style="height: 20px; width: 20px; float:right" src="assets/images/google_map_icon.png" />

        <div class="row id_class mr-auto" *ngIf="field.field_type != 'GEOCODEDIMAGE'">
          <strong>>{{'Value: '}}</strong>
          <div class="img_name">{{" "+customFieldValues[i]?.values[0]?.value}}</div>
        </div>
      </div> -->
    </div>
  </div>

  <!-- <div style="display: flex;" *ngIf="!customFieldValues[i]?.values.length || !readOnlyFlag">
    <div class="image-div">
      <input type="file" accept=".jpg,.png,.jpeg" class="field_class file_class" multiple [id]="'field'+i"
        (input)="onValuesEntered($event, 'file')">
    </div>
    <div class="value-div" *ngIf="field.field_type != 'GEOCODEDIMAGE'">
      <input type="text" class="form-control" [id]="'field'+i" (input)="onValuesEntered($event, 'text')" placeholder="Enter Value">
    </div>
  </div> -->

</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { PlantService } from '../plant/plant.service';
import { ProcessService } from '../process/process.service';
import { CommonService } from '../../@core/services/common.service';
import { TableService } from '../../@core/services/table.service';
import { UserService } from '../../@core/services/user.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { ShiftMasterService } from './shift-master.service';
import { Options } from '@angular-slider/ngx-slider';
import { StageService } from '../stage/stage.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-shift-master',
  templateUrl: './shift-master.component.html',
  styleUrls: ['./shift-master.component.scss'],
})
export class ShiftMasterComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'List of Shifts');
  stageName: any = [];
  stageIds: any = [];
  stageData: any = [];
  edit: boolean;
  flag: boolean;
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getListShift();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  shiftName: any = '';

  shift = {
    shiftId: null,
    shiftName: null,
    shiftDuration: 24,
    shiftStatus: 'ACTIVE',
    shiftStartTime: '00:00',
    process: [],
    num: 1,
    processSave: [],
    processRemove: [],
  };
  exportTime = { hour: 7, minute: 15, meriden: 'PM', format: 24 };

  savedProcess: any[] = [];
  processName: null;
  preSelectedProcess: any = [];
  process: any[] = [];
  title = 'Add Shift';
  btn = 'save';
  data: any[] = [];
  selectedShiftName: any = null;
  removeShiftMappingIds: any[] = [];

  constructor(
    private shiftMasterService: ShiftMasterService,
    private processService: ProcessService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public stageService: StageService,
    public alert: AlertService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Shift Master', url: null },
      { name: 'Shift Management', url: '/user/shift-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getAllProcess();
  }
  ngViewInit() { }

  selectedProcess(event: any) {
    this.shift.processSave = [];
    event.forEach((e) => {
      this.shift.processSave.push(e.order_id);
    });
  }

  unselectedProcess(event: any) {
    if (event != null) {
      this.shift.processRemove?.push(event.order_id);
    }
  }

  selectshift(event: any) {
    this.selectedShiftName = event.shiftName;
  }

  options: Options = {
    floor: 1,
    ceil: 6,
    step: 1,
    //   showTicks: true,
    showTicksValues: true,
  };

  durationOptions: Options = {
    floor: 0,
    ceil: 24,
    step: 2,
    showTicksValues: true,
  };

  getCount(params: any) {
    this.shift.num = params;
    this.shift.shiftDuration = 24 / params;
  }

  getDuration(event: any) {
    this.shift.shiftDuration = event;
  }

  getTime(event: any) {
    this.shift.shiftStartTime = event;
  }

  getListShift() {
    this.common.loading = true;
    this.shiftMasterService.getListShift().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.data = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllProcess() {
    this.common.loading = true;
    this.processService.getAllProcessStage().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.process = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  saveShift() {
    this.common.loading = true;
    let params: any;
    params = {
      shiftId: this.shift.shiftId,
      shiftName: this.shift.shiftName,
      shiftDuration: this.shift.shiftDuration,
      shiftStatus: this.shift.shiftStatus,
      shiftStartTime: this.shift.shiftStartTime,
      processStage: this.shift.processSave,
      processStageRemove: this.btn == 'save' ? [] : this.shift.processRemove,
    };
    this.shiftMasterService.saveShift(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alert.success(res.message);
          this.resetDetail();
          this.getListShift();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  resetDetail() {
    this.shift = {
      shiftId: null,
      shiftName: null,
      shiftDuration: 24,
      shiftStatus: 'ACTIVE',
      shiftStartTime: '00:00',
      process: [],
      num: 1,
      processSave: [],
      processRemove: [],
    };
    this.getCount(this.shift.num);
    this.processName = null;
    this.btn = 'save';
    this.title = 'Add Shift';
    // this.getTime();
    this.shiftNameValidator = null;
    this.statusValidator = null;
    this.durationValidator = null;
    this.processValidator = null;
    this.antenavalidator = null;
    this.stageName = [];
    this.preSelectedProcess = [];
    this.stageIds = [];
  }

  async editShift(row?: any) {
    this.common.gotoTop();
    this.resetDetail();
    this.title = 'Edit Shift Details';
    this.btn = 'update';
    this.shift.shiftId = row.shiftId;
    this.shift.shiftName = row.shiftName;
    this.shift.shiftDuration = row.shiftDuration;
    this.shift.shiftStatus = row.shiftStatus;
    this.shift.num = 24 / row.shiftDuration;
    this.shift.shiftStartTime = row.shiftStartTime;
    this.shift.process = row.info.map((info) => info.order_id);
    this.preSelectedProcess = this.process.filter(
      (p) => this.shift.process.indexOf(p.order_id) != -1
    );
    this.stageIds = [];
  }

  viewDetails(row?: any) {
    row.mapped_on_stages = this.getProcessStageNames(row).join(',');
    this.common.params = { details: [row], title: 'info' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  shiftNameValidator: any;
  statusValidator: any;
  durationValidator: any;
  processValidator: any;
  stageValidator: any;
  antenavalidator: any;
  processValidatorUpdate: any;

  formValidator() {
    let processUpdate: any = [];
    if (this.btn == 'update') {
      if (
        this.preSelectedProcess.length != 0 ||
        this.shift.processSave.length != 0
      ) {
        processUpdate.push(1);
      }
      this.processValidatorUpdate = this.common.nullValidator(
        processUpdate,
        'Process Mandatory'
      );
    }
    this.shiftNameValidator = this.common.nullValidator(
      this.shift.shiftName,
      'Shift Name Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.shift.shiftStatus,
      'Shift Status Mandatory'
    );
    this.durationValidator = this.common.nullValidator(
      this.shift.shiftDuration,
      'Shift Duration Mandatory'
    );
    this.processValidator = this.common.nullValidator(
      this.shift.processSave,
      'Process Mandatory'
    );
    if (
      this.btn == 'save' &&
      !this.shiftNameValidator.error &&
      !this.statusValidator.error &&
      !this.processValidator.error &&
      !this.durationValidator.error
    ) {
      this.saveShift();
    } else if (
      this.btn == 'update' &&
      !this.shiftNameValidator.error &&
      !this.statusValidator.error &&
      !this.processValidatorUpdate.error &&
      !this.durationValidator.error
    ) {
      this.saveShift();
    }
  }

  getProcessStageNames(row: any) {
    return row.info.map((el) => el.order_name);
  }

  deleteShift(shiftId) {
    this.common.params = {
      title: 'Delete Shift',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.common.loading = true;
        let params = {
          shiftId: shiftId
        };
        this.shiftMasterService.deleteShift(params).subscribe(
          (res: any) => {
            this.common.loading = false;
            if (res.status) {
              this.alert.success(res.message);
              this.resetDetail();
              this.getListShift();
            } else {
              this.alert.error(res.message);
            }
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
          }
        );
      }
    });
  }
}

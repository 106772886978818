<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card" *ngIf="userService.permissionMap.get('add__communication_template_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Communication Template" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="commu_page">
                <div class="left_right_sec row">
                  <div class="col-md-10 left_sec">
                    <div class="row">
                      <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                        <label for="my-input" class="required">{{"company" | translate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="company"
                          display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                        </ps-auto-suggestion>
                        <div class="error" *ngIf="companyValidator && companyValidator.error">
                          <span>{{companyValidator.msg}} </span>
                        </div>
                      </div>

                      <div *ngIf="role.user_role == 'AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                        <label for="my-input" class="required">{{"plant" | translate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                          placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                        </ps-auto-suggestion>
                        <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                          <span>{{plantNameValidator.msg}} </span>
                        </div>
                      </div>

                      <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                        <label for="my-input" class="required">{{"communication_type" | translate}} </label>
                        <ps-auto-suggestion (onSelected)="selectedCommunicationType($event)" [data]="communication"
                          display="communicationTypeName" placeholder="Choose Communication Type"
                          [preSelected]="{communicationTypeName:selectedOption}">
                        </ps-auto-suggestion>
                        <div class="error" *ngIf="communicationValidator && communicationValidator.error">
                          <span>{{communicationValidator.msg}} </span>
                        </div>
                      </div>
                      <div class="form-group col-xl-3 col-lg-6 col-sm-12"
                        *ngIf=" selectedOption == 'SMS' || selectedOption=='Whatsapp' ">
                        <div class="drop_down">
                          <label for="my-input" class="required">{{"templates" | translate}} </label>
                          <ps-auto-suggestion placeholder="Choose Template" (onSelected)="selectedTemplate($event)"
                            [data]="templateList" display="template_name"
                            [preSelected]="{template_name:selectedTemplateName}">
                          </ps-auto-suggestion>
                          <div class="error" *ngIf="templateValidator && templateValidator.error">
                            <span>{{templateValidator.msg}} </span>
                          </div>
                        </div>

                      </div>
                      <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="selectedOption != null">
                        <label for="my-input" class="required">{{"title" | translate}}</label>
                        <input type="text" class="form-control" id="title" name="titleName" required
                          placeholder="Enter Title" [(ngModel)]="titleName" #name="ngModel" autocomplete="off">
                        <div class="error" *ngIf="titleValidator && titleValidator.error">
                          <span>{{titleValidator.msg}} </span>
                        </div>
                      </div>
                      <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                        <label for="" class="d-block" class="required"> {{"status" | translate}} </label>
                        <div class="form-check-inline">
                          <input class="form-check-input" type="radio" name="actionStatus" [(ngModel)]="status"
                            id="stage_status_1" value="true">
                          <label for="exampleRadios1">
                            {{"active" | translate}}
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <input class="form-check-input" type="radio" name="actionStatus" [(ngModel)]="status"
                            value="false" id="stage_status_2">
                          <label for="exampleRadios2">
                            {{"inactive" | translate}}
                          </label>
                        </div>
                        <div class="error" *ngIf="statusValidator && statusValidator.error">
                          <span>{{statusValidator.msg}} </span>
                        </div>
                      </div>
                    </div>
                    <div class="text_area" *ngIf="selectedOption">
                      <label for="my-input" class="required">{{"query_variable" | translate}}</label>
                      <textarea rows="4" class="form-control required" id="title" name="queryVariable"
                        placeholder="Write Query here...." [(ngModel)]="queryVariable" #name="ngModel"
                        autocomplete="off"> </textarea>
                    </div>

                    <div class="ck_editor" *ngIf="selectedOption =='Email'">
                      <ckeditor [editor]="Editor" [(ngModel)]="detail"></ckeditor>
                    </div>

                    <div class="text_area"
                      *ngIf="['SMS','Notification','Whatsapp','IVR Call'].includes(selectedOption)">
                      <label for="my-input" class="required">{{"message" | translate}}</label>
                      <textarea rows="5" class="form-control" [(ngModel)]="detail"
                        [disabled]="(selectedOption == 'SMS' || selectedOption == 'Whatsapp' ? true : false) ">{{selectedOption}}</textarea>
                      <div class="error" *ngIf="MessageValidator && MessageValidator.error">
                        <span>{{MessageValidator.msg}} </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 right_sec"
                    *ngIf="selectedOption == 'Email' || selectedOption  == 'IVR Call' || selectedOption == 'Notification'">
                    <div class="right_sec_con">
                      <p>{{"variable" | translate}}</p>
                      <ul>
                        <li *ngFor="let row of title"><a (click)="addDetail(row)">{{row.databaseFieldName}}</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="row form-group action-block mb-3">
                    <div class="btn-block">
                      <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | translate}}
                      </button>
                      <button class="btn btn-warning" (click)="resetDetail()"> {{"reset" | translate}} </button>
                      <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | translate}}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="table_div">
                  <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead class="thead-light">
                      <tr>
                        <th>{{"s.no." | translate}}</th>
                        <th *ngIf="role.user_role == 'AXESTRACK'">{{"company" | translate}}</th>
                        <th *ngIf="role.user_role == 'AXESTRACK'">{{"plant" | translate}}</th>
                        <th>{{"title" | translate}}</th>
                        <th>{{"communication_type" | translate}}</th>
                        <th>{{"details" | translate}}</th>
                        <th>{{"status" | translate}}</th>
                        <th class="action"> {{"action" | translate}} </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of templates; let i = index">
                        <td>{{ i+1 }}</td>
                        <td *ngIf="role.user_role == 'AXESTRACK'">{{row.companyName}}</td>
                        <td *ngIf="role.user_role == 'AXESTRACK'">{{row.plantName}}</td>
                        <td>{{row.title}}</td>
                        <td>{{row.communicationType}}</td>
                        <td>{{row.detail}}</td>
                        <td>{{(row.isActive==true?'Active':'Inactive')}}</td>
                        <td class="action">
                          <div class="btn-block">
                            <button class="btn btn-primary" (click)="viewCommunicationTemplate(row)"
                              *ngIf="userService.permissionMap.get('view_communication_template_access')==true">
                              {{"view" | translate}} </button>
                            <button class="btn btn-success" (click)="editCommunicationTemplate(row)"
                              *ngIf="userService.permissionMap.get('edit_communication_template_access')==true">
                              {{"edit" | translate}} </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-driver-ivr-call',
  templateUrl: './driver-ivr-call.component.html',
  styleUrls: ['./driver-ivr-call.component.scss'],
})
export class DriverIvrCallComponent implements OnInit {
  @Input() public refData;
  message: any = '';
  activeTab: any = 1;
  suggestions: any = [
    '{{vehicle_rc_no}} move back',
    '{{vehicle_rc_no}} you can proceed further',
    '{{vehicle_rc_no}} come to gate',
  ];
  // previousIVRData: any = []
  previousMsg: any = [];

  constructor(
    public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal
  ) {
    this.previousMsg = JSON.parse(
      localStorage.getItem('IVR_User_Dashboard_Data')
    )
      ? JSON.parse(localStorage.getItem('IVR_User_Dashboard_Data'))
      : [];
  }

  ngOnInit(): void {
    this.suggestions = this.suggestions.map((suggestion) =>
      suggestion.replaceAll(
        '{{vehicle_rc_no}}',
        this.refData.vehicle.vehicle_rc_no
      )
    );
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  sendIvrCall() {
    let data = {
      txnId: this.refData.vehicle.txnid,
      message: this.message,
    };
    this.previousMsg.push(this.message);
    localStorage.setItem(
      'IVR_User_Dashboard_Data',
      JSON.stringify(this.previousMsg)
    );
    this.api
      .post('transaction/call-driver', data)
      .toPromise()
      .then((res: any) => {
        if (res.status) {
          this.activeModal.close({ response: 'Call Send Successfully' });
        }
      });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { StageService } from '../stage/stage.service';
import { CommonService } from '../../@core/services/common.service';
import { TableService } from '../../@core/services/table.service';
import { UserService } from '../../@core/services/user.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { ApiService } from '../../@core/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-user-mapping',
  templateUrl: './alert-user-mapping.component.html',
  styleUrls: ['./alert-user-mapping.component.scss']
})
export class AlertUserMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'List of Users');
  title = "Alert User Mapping";
  processId: any;
  stageData: any = [];
  process: any;
  btn = "save";
  // processName: any=null;
  data: any = [];
  // name: any[] = [];
  alertType: any[] = [];
  alertTitle: string = null;
  masterField = {
    values: [{ name: null, userId: null, alertType: null ,mappingId:null}]
  }
  processWiseShifts: any[] = []
  userId: any = null;
  shifts: any = [];
  allUserShifts: any = [];
  alerts: any[] = [];
  alertId: number = null;
  alertUserMappings: any[] = [];
  mappingId: number = null;

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  constructor(
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public stageService: StageService,
    private api: ApiService,
    private alertService: AlertService
  ) {

    /** breadcums implementation start*/
    let breadcumbs = [
      { name: "Home", url: "/user/dashboard" },
      { name: "Alert", url: null },
      { name: "Alert User Mapping", url: "/user/alert-user-mapping" },
    ]
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getData();
    this.getAllAlerts();
    this.getAllAlertUserMappings();
  }

  alertTypes = [
    { type: 'Info', value: 'INFO' },
    { type: 'Task', value: 'TASK' },
  ]

  selectedUser(event: any, index: any) {
    this.masterField.values[index].name = event.name;
    this.masterField.values[index].userId = event.userId;
  }

  selectedAlert(event: any) {
    this.alertId = event.alert_id;
    this.alertTitle = event.alertTitle;
    this.masterField.values = []; // Clear the masterField.values array if needed

    this.alertUserMappings.forEach(mapping => {
      if (mapping.title === event.alertTitle) {
        // Push the new object into masterField.values
        this.masterField.values.push({
          name: mapping.user_name,
          userId: mapping.user_id,
          alertType: mapping.mapping_type,
          mappingId:mapping.id
        });
      }
    });

    if(this.masterField.values.length==0){
      this.addValues();
    }


  }

  getData() {
    this.common.loading = true;
    this.api.get('user/all_user')
      .subscribe((res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.data = res.data;
        }
      }, (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;

      });

  }
  addValues() {
    this.masterField.values.push({ name: null, userId: null, alertType: null,mappingId:null });
  }

  saveMappings() {
    let params = {
      mappingId: this.mappingId,
      alertId: this.alertId,
      userAlertMappings: this.masterField.values
    }
    this.common.loading = true;
    this.api.post('alert/save-alert-user-mapping', params).subscribe((res: any) => {
      if (res.status) {
        this.alertService.success(res.message)
        this.common.loading = false;
        this.resetDetail();
        this.getAllAlertUserMappings();
      }
    }, (err: any) => {
      console.error('Error: ', err);
      this.common.loading = false;
    });

  }

  edit(row: any) {
  console.log('row: ', row);
    this.alertId = row.alert_id;
    this.alertTitle = row.title;
    this.masterField.values = [];
    this.masterField.values.push({ name: row.name, userId: row.user_id, alertType: row.mapping_type,mappingId:null });
    this.mappingId = row.id;
    console.log('this.masterField.values: ', this.masterField.values);

  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Shift ',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    }
    const activeModal = this.modalService.open(ConfirmationDialogComponent, { size: 'md' });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteMapping(row);
      }
    });
  }

  deleteMapping(id: number) {
    this.common.loading = true;
    this.api.get('alert/delete-alert-user-mapping?mappingId=' + id)
      .subscribe((res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.getAllAlertUserMappings();
        }
      }, (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      });
  }

  getAllAlerts() {
    this.common.loading = true;
    this.api.get('alert/get_all_alerts')
      .subscribe((res: any) => {
        this.common.loading = false;
        this.alerts = res.data;
      }, (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);

      });
  }

  getAllAlertUserMappings() {
    this.common.loading = true;
    this.api.get('alert/get-alert-user-mappings')
      .subscribe((res: any) => {
        this.common.loading = false;
        this.alertUserMappings = res.data;
        this.renderTable();
      }, (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);

      });
  }


  resetDetail() {
    this.btn = 'save';
    this.alertId = null;
    this.alertTitle = null;
    this.masterField.values = [{ name: null, userId: null, alertType: null ,mappingId:null}];
    this.userValidator = [];
    this.alertTitleValidator = null;
    this.alertTypeValidator = [];
    this.mappingId = null;
  }


  viewDetails(row?: any) {
    let obj = {
      name: row.name,
      alertType: row.mapping_type,
      alertTitle: row.title
    }
    this.common.params = { details: [obj], title: 'info' }
    const activeModal = this.modalService.open(ViewDetailsComponent, { size: 'lg' });

  }

  userValidator: any[] = [];
  alertTitleValidator: any;
  alertTypeValidator: any[] = [];
  formValidator() {
    this.alertTitleValidator = this.common.nullValidator(this.alertId, "Alert Mandatory");
    this.masterField.values.forEach((e, index) => {
      this.userValidator[index] = this.common.nullValidator(e.userId, "User Name Mandatory");
      this.alertTypeValidator[index] = this.common.nullValidator(e.alertType, "Alert Type Mandatory");
    });

    if (!this.userValidator.some((validator) => validator.error) && !this.alertTypeValidator.some((validator) => validator.error) && !this.alertTitleValidator.error) {
      this.saveMappings();
    }
  }


}

import { UserRoutingModule } from './user.routing';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ThemeModule } from 'src/app/theme/theme.module';
import { SharedModule } from '../shared/shared.module';
import { UserComponent } from './user.component';
import { AssignMaterialComponent } from 'src/app/assign-material/assign-material.component';
import { ResetPasswordComponent } from 'src/app/auth/reset-password/reset-password.component';
import { ActionActionMappingComponent } from 'src/app/components/action-action-mapping/action-action-mapping.component';
import { ActionsComponent } from 'src/app/components/actions/actions.component';
import { AddAssetRequestComponent } from 'src/app/components/add-asset-request/add-asset-request.component';
import { AddChecklistComponent } from 'src/app/components/add-checklist/add-checklist.component';
import { AddDriverTransporterComponent } from 'src/app/components/add-driver-transporter/add-driver-transporter.component';
import { AddInsuranceComponent } from 'src/app/components/add-insurance/add-insurance.component';
import { AdminDashboardComponent } from 'src/app/components/admin-dashboard/admin-dashboard.component';
import { AlertCommunicationMappingComponent } from 'src/app/components/alert-communication-mapping/alert-communication-mapping.component';
import { AlertDashboardComponent } from 'src/app/components/alert-dashboard/alert-dashboard.component';
import { AlertLogInfoModalComponent } from 'src/app/components/alert-log-info-modal/alert-log-info-modal.component';
import { AlertManagementComponent } from 'src/app/components/alert-management/alert-management.component';
import { AlertReportComponent } from 'src/app/components/alert-report/alert-report.component';
import { AlertTaskDashboardComponent } from 'src/app/components/alert-task-dashboard/alert-task-dashboard.component';
import { AlertTypeComponent } from 'src/app/components/alert-type/alert-type.component';
import { AlertUserMappingComponent } from 'src/app/components/alert-user-mapping/alert-user-mapping.component';
import { AlertViewDetailsModalComponent } from 'src/app/components/alert-view-details-modal/alert-view-details-modal.component';
import { AppManagementComponent } from 'src/app/components/app-management/app-management.component';
import { AppVersionDataComponent } from 'src/app/components/app-version-data/app-version-data.component';
import { ApprovalFlowComponent } from 'src/app/components/approval-flow/approval-flow.component';
import { AssetCategoryFieldComponent } from 'src/app/components/asset-category-field/asset-category-field.component';
import { AssetCategoryComponent } from 'src/app/components/asset-category/asset-category.component';
import { AssetDepreciationComponent } from 'src/app/components/asset-depreciation/asset-depreciation.component';
import { AssetDetailComponent } from 'src/app/components/asset-detail/asset-detail.component';
import { AssetDocumentComponent } from 'src/app/components/asset-document/asset-document.component';
import { AssetEventsComponent } from 'src/app/components/asset-events/asset-events.component';
import { AssetHistoryComponent } from 'src/app/components/asset-history/asset-history.component';
import { AssetInsuranceComponent } from 'src/app/components/asset-insurance/asset-insurance.component';
import { AssetLabelMappingComponent } from 'src/app/components/asset-label-mapping/asset-label-mapping.component';
import { AssetLabelComponent } from 'src/app/components/asset-label/asset-label.component';
import { AssetLinkingComponent } from 'src/app/components/asset-linking/asset-linking.component';
import { AssetMaintenanceComponent } from 'src/app/components/asset-maintenance/asset-maintenance.component';
import { AssetRequestRemarkComponent } from 'src/app/components/asset-request-remark/asset-request-remark.component';
import { AssetRequestComponent } from 'src/app/components/asset-request/asset-request.component';
import { AssetReserveComponent } from 'src/app/components/asset-reserve/asset-reserve.component';
import { AssetTrackingReportComponent } from 'src/app/components/asset-tracking-report/asset-tracking-report.component';
import { AssetViewComponent } from 'src/app/components/asset-view/asset-view.component';
import { AssetWarrantyComponent } from 'src/app/components/asset-warranty/asset-warranty.component';
import { AssetsTrackingDasboardComponent } from 'src/app/components/assets-tracking-dasboard/assets-tracking-dasboard.component';
import { AssetsTrackingViewComponent } from 'src/app/components/assets-tracking-view/assets-tracking-view.component';
import { AssetsComponent } from 'src/app/components/assets/assets.component';
import { AssignDriverTransporterComponent } from 'src/app/components/assign-driver-transporter/assign-driver-transporter.component';
import { AssignUserModalComponent } from 'src/app/components/assign-user-modal/assign-user-modal.component';
import { AssignedTripInfoModalComponent } from 'src/app/components/assigned-trip-info-modal/assigned-trip-info-modal.component';
import { AssignedTripsDashboardComponent } from 'src/app/components/assigned-trips-dashboard/assigned-trips-dashboard.component';
import { AuditLogsComponent } from 'src/app/components/audit-logs/audit-logs.component';
import { AuditManagementComponent } from 'src/app/components/audit-management/audit-management.component';
import { AverageTatComponent } from 'src/app/components/average-tat/average-tat.component';
import { BanTransporterComponent } from 'src/app/components/ban-transporter/ban-transporter.component';
import { CameraComponentComponent } from 'src/app/components/camera-component/camera-component.component';
import { CheckInCheckOutModalComponent } from 'src/app/components/check-in-check-out-modal/check-in-check-out-modal.component';
import { ChecklistActionsMappingComponent } from 'src/app/components/checklist-actions-mapping/checklist-actions-mapping.component';
import { ChecklistAdminDashboardComponent } from 'src/app/components/checklist-admin-dashboard/checklist-admin-dashboard.component';
import { ChecklistDashbaordComponent } from 'src/app/components/checklist-dashbaord/checklist-dashbaord.component';
import { ChecklistHistoryComponent } from 'src/app/components/checklist-history/checklist-history.component';
import { ChecklistQrModalComponent } from 'src/app/components/checklist-qr-modal/checklist-qr-modal.component';
import { ChecklistComponent } from 'src/app/components/checklist/checklist.component';
import { CommunicationTemplateComponent } from 'src/app/components/communication-template/communication-template.component';
import { CommunicationTypeComponent } from 'src/app/components/communication-type/communication-type.component';
import { CommunicationComponent } from 'src/app/components/communication/communication.component';
import { CompaniesComponent } from 'src/app/components/companies/companies.component';
import { CompanyAdminDashboardComponent } from 'src/app/components/company-admin-dashboard/company-admin-dashboard.component';
import { ComponenttComponent } from 'src/app/components/componentt/componentt.component';
import { CustomFieldComponent } from 'src/app/components/custom-field/custom-field.component';
import { CustomerDashboardComponent } from 'src/app/components/customer-dashboard/customer-dashboard.component';
import { DashboardModalComponent } from 'src/app/components/dashboard-modal/dashboard-modal.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { DepartmentManagementComponent } from 'src/app/components/department-management/department-management.component';
import { DepartmentUserMappingComponent } from 'src/app/components/department-user-mapping/department-user-mapping.component';
import { DestinationStageMappingComponent } from 'src/app/components/destination-stage-mapping/destination-stage-mapping.component';
import { DockAppointmentReportComponent } from 'src/app/components/dock-appointment-report/dock-appointment-report.component';
import { DockAppointmentComponent } from 'src/app/components/dock-appointment/dock-appointment.component';
import { DockBookingModalComponent } from 'src/app/components/dock-booking-modal/dock-booking-modal.component';
import { DockDashboardComponent } from 'src/app/components/dock-dashboard/dock-dashboard.component';
import { DockManagementComponent } from 'src/app/components/dock-management/dock-management.component';
import { DocumentTypeComponent } from 'src/app/components/document-type/document-type.component';
import { DriverBanComponent } from 'src/app/components/driver-ban/driver-ban.component';
import { DriverIvrCallComponent } from 'src/app/components/driver-ivr-call/driver-ivr-call.component';
import { DriverTrackerComponent } from 'src/app/components/driver-tracker/driver-tracker.component';
import { EmployeeDetailModalComponent } from 'src/app/components/employee-detail-modal/employee-detail-modal.component';
import { EmployeeReportComponent } from 'src/app/components/employee-report/employee-report.component';
import { EntryFormComponent } from 'src/app/components/entry-form/entry-form.component';
import { PlantFieldMappingComponent } from 'src/app/components/field-method-mapping/plant-field-method-mapping.component';
import { FieldToleranceMappingComponent } from 'src/app/components/field-tolerance-mapping/field-tolerance-mapping.component';
import { GroupManagementComponent } from 'src/app/components/group-management/group-management.component';
import { GroupRoleMappingComponent } from 'src/app/components/group-role-mapping/group-role-mapping.component';
import { HardwareHealthComponent } from 'src/app/components/hardware-health/hardware-health.component';
import { HardwareMappingComponent } from 'src/app/components/hardware-mapping/hardware-mapping.component';
import { HardwareSettingComponent } from 'src/app/components/hardware-setting/hardware-setting.component';
import { HardwareStatusComponent } from 'src/app/components/hardware-status/hardware-status.component';
import { HardwareVehicleLogComponent } from 'src/app/components/hardware-vehicle-log/hardware-vehicle-log.component';
import { HardwareOTALogsComponent } from 'src/app/components/HardwareOTALogs/HardwareOTALogs.component';
import { HelpInfoComponent } from 'src/app/components/help-info/help-info.component';
import { HelpSubmenuComponent } from 'src/app/components/help-submenu/help-submenu.component';
import { HelpComponent } from 'src/app/components/help/help.component';
import { InventoryManagerChecklistComponent } from 'src/app/components/inventory-manager-checklist/inventory-manager-checklist.component';
import { LiveStreamingComponent } from 'src/app/components/live-streaming/live-streaming.component';
import { LoaderViewComponent } from 'src/app/components/loader-view/loader-view.component';
import { ManageHardwareComponent } from 'src/app/components/manage-hardware/manage-hardware.component';
import { ManualStampingFormComponent } from 'src/app/components/manual-stamping-form/manual-stamping-form.component';
import { ManufacturerComponent } from 'src/app/components/manufacturer/manufacturer.component';
import { MapViewModalComponent } from 'src/app/components/map-view-modal/map-view-modal.component';
import { MasterFieldComponent } from 'src/app/components/master-field/master-field.component';
import { MethodCommunicationMappingComponent } from 'src/app/components/method-communication-mapping/method-communication-mapping.component';
import { MethodManagementComponent } from 'src/app/components/method-management/method-management.component';
import { MethodMappingComponent } from 'src/app/components/method-mapping/method-mapping.component';
import { NotificationListComponent } from 'src/app/components/notification-list/notification-list.component';
import { OtaManagementComponent } from 'src/app/components/ota-management/ota-management.component';
import { OverridingActionsComponent } from 'src/app/components/overriding-actions/overriding-actions.component';
import { OverridingLogsComponent } from 'src/app/components/overriding-logs/overriding-logs.component';
import { ParkingDashboardComponent } from 'src/app/components/parking-dashboard/parking-dashboard.component';
import { ParkingCreationComponent } from 'src/app/components/parking-slot-managment/parking-slot-management.component';
import { ParkingZoneManagementComponent } from 'src/app/components/parking-zone-management/parking-zone-management.component';
import { PendingTasksForUserModalComponent } from 'src/app/components/pending-tasks-for-user-modal/pending-tasks-for-user-modal.component';
import { PlantGeofenceComponent } from 'src/app/components/plant-geofence/plant-geofence.component';
import { PlantHardwareViewComponent } from 'src/app/components/Plant-Hardware-View/Plant-Hardware-View.component';
import { PlantLiveViewModalComponent } from 'src/app/components/plant-live-view-modal/plant-live-view-modal.component';
import { PlantLiveViewComponent } from 'src/app/components/plant-live-view/plant-live-view.component';
import { PlantSettingComponent } from 'src/app/components/plant-setting/plant-setting.component';
import { PlantComponent } from 'src/app/components/plant/plant.component';
import { InBetweenVehiclesComponent } from 'src/app/components/process-stage/in-between-vehicles/in-between-vehicles.component';
import { ProcessStageComponent } from 'src/app/components/process-stage/process-stage.component';
import { TripDetailComponent } from 'src/app/components/process-stage/trip-detail/trip-detail.component';
import { TripFlowComponent } from 'src/app/components/process-stage/trip-flow/trip-flow.component';
import { TripMapViewComponent } from 'src/app/components/process-stage/trip-map-view/trip-map-view.component';
import { ProcessComponent } from 'src/app/components/process/process.component';
import { ProductDockLinkingComponent } from 'src/app/components/product-dock-linking/product-dock-linking.component';
import { ProductManagementComponent } from 'src/app/components/product-management/product-management.component';
import { ProductTypeComponent } from 'src/app/components/product-type/product-type.component';
import { ProductivityReportComponent } from 'src/app/components/productivity-report/productivity-report.component';
import { SetUserPermissionComponent } from 'src/app/components/set-user-permission/set-user-permission.component';
import { ShiftCalendarComponent } from 'src/app/components/shift-calendar/shift-calendar.component';
import { ShiftMasterComponent } from 'src/app/components/shift-master/shift-master.component';
import { ShiftReportComponent } from 'src/app/components/shift-report/shift-report.component';
import { SignatureComponent } from 'src/app/components/signature/signature.component';
import { SlotManagementComponent } from 'src/app/components/slot-management/slot-management.component';
import { StageActionComponent } from 'src/app/components/stage-action/stage-action.component';
import { StageDestinationCodeOccupancyComponent } from 'src/app/components/stage-destination-code-occupancy/stage-destination-code-occupancy.component';
import { StageDestinationCodeTypeComponent } from 'src/app/components/stage-destination-code-type/stage-destination-code-type.component';
import { StageDestinationCodeComponent } from 'src/app/components/stage-destination-code/stage-destination-code.component';
import { StageSettingComponent } from 'src/app/components/stage-setting/stage-form-setting/stage-setting.component';
import { stagetatTableComponent } from 'src/app/components/stage-tat/stagetat-table.component';
import { StageTypeComponent } from 'src/app/components/stage-type/stage-type.component';
import { StageVehiclesComponent } from 'src/app/components/stage-vehicles/stage-vehicles.component';
import { StageWiseUserDashboardComponent } from 'src/app/components/stage-wise-user-dashboard/stage-wise-user-dashboard.component';
import { StageZoneMappingComponent } from 'src/app/components/stage-zone-mapping/stage-zone-mapping.component';
import { StageComponent } from 'src/app/components/stage/stage.component';
import { StagesComponent } from 'src/app/components/stages/stages.component';
import { StatusManagementComponent } from 'src/app/components/status-management/status-management.component';
import { StatusWiseChecklistLogsComponent } from 'src/app/components/status-wise-checklist-logs/status-wise-checklist-logs.component';
import { SupplierComponent } from 'src/app/components/supplier/supplier.component';
import { TagDestinationTatReportComponent } from 'src/app/components/tag-destination-tat-report/tag-destination-tat-report.component';
import { TatInbetweenReportComponent } from 'src/app/components/tat-inbetween-report/tat-inbetween-report.component';
import { TatReportsComponent } from 'src/app/components/tat-reports/tat-reports.component';
import { TimeCounterComponent } from 'src/app/components/time-counter/time-counter.component';
import { DetailModalComponent } from 'src/app/components/tmg-dashboard/detail-modal/detail-modal.component';
import { LiveDashboardComponent } from 'src/app/components/tmg-dashboard/live-dashboard/live-dashboard.component';
import { SmartHeaderComponent } from 'src/app/components/tmg-dashboard/smart-header/smart-header.component';
import { StageDashboardComponent } from 'src/app/components/tmg-dashboard/stage-dashboard/stage-dashboard.component';
import { TMGDashboardComponent } from 'src/app/components/tmg-dashboard/tmg-dashboard.component';
import { TripDashboardComponent } from 'src/app/components/tmg-dashboard/trip-dashboard/trip-dashboard.component';
import { VehicleDashboardComponent } from 'src/app/components/tmg-dashboard/vehicle-dashboard/vehicle-dashboard.component';
import { TransporterBookingsComponent } from 'src/app/components/transporter-bookings/transporter-bookings.component';
import { TripHistoryComponent } from 'src/app/components/trip-history/trip-history.component';
import { TripReportComponent } from 'src/app/components/trip-report/trip-report.component';
import { TripScheduleComponent } from 'src/app/components/trip-schedule/trip-schedule.component';
import { TripactivityComponent } from 'src/app/components/tripactivity/tripactivity.component';
import { UserActionMappingComponent } from 'src/app/components/user-action-mapping/user-action-mapping.component';
import { UserChecklistMappingComponent } from 'src/app/components/user-checklist-mapping/user-checklist-mapping.component';
import { UserDashboardComponent } from 'src/app/components/user-dashboard/user-dashboard.component';
import { UserGroupAssociationComponent } from 'src/app/components/user-group-association/user-group-association.component';
import { UserManagementComponent } from 'src/app/components/user-management/user-management.component';
import { UserShiftMappingComponent } from 'src/app/components/user-shift-mapping/user-shift-mapping.component';
import { UserTagMappingComponent } from 'src/app/components/user-tag-mapping/user-tag-mapping.component';
import { UserTrackingDashboardComponent } from 'src/app/components/user-tracking-dashboard/user-tracking-dashboard.component';
import { UserTrackingReportComponent } from 'src/app/components/user-tracking-report/user-tracking-report.component';
import { UserTrackingViewComponent } from 'src/app/components/user-tracking-view/user-tracking-view.component';
import { User_path_trackingComponent } from 'src/app/components/user_path_tracking/user_path_tracking.component';
import { VehicleBanComponent } from 'src/app/components/vehicle-ban/vehicle-ban.component';
import { VehicleReportComponent } from 'src/app/components/vehicle-report/vehicle-report.component';
import { VehicleRouteConfigurationComponent } from 'src/app/components/vehicle-route-configuration/vehicle-route-configuration.component';
import { VehicleTagMappingComponent } from 'src/app/components/vehicle-tag-mapping/vehicle-tag-mapping.component';
import { VehicleWhitelistBlacklistComponent } from 'src/app/components/vehicle-whitelist-blacklist/vehicle-whitelist-blacklist.component';
import { ViewChecklistComponent } from 'src/app/components/view-checklist/view-checklist.component';
import { VisitorReportByEmpNoComponent } from 'src/app/components/visitor-reports/visitor-report-by-emp-no/visitor-report-by-emp-no.component';
import { VisitorReportsComponent } from 'src/app/components/visitor-reports/visitor-reports.component';
import { VisitorVisitsComponent } from 'src/app/components/visitor-visits/visitor-visits.component';
import { VisitorDashboardComponent } from 'src/app/components/vms/visitor-dashboard/visitor-dashboard.component';
import { WorkTypeComponent } from 'src/app/components/work-type/work-type.component';
import { ZoneAllocationComponent } from 'src/app/components/zone-allocation/zone-allocation.component';
import { ZoneDashboardComponent } from 'src/app/components/zone-dashboard/zone-dashboard.component';
import { ZoneManagementComponent } from 'src/app/components/zone-management/zone-management.component';
import { ZoneSlotMappingComponent } from 'src/app/components/zone-slot-mapping/zone-slot-mapping.component';
import { ZoneStageMappingComponent } from 'src/app/components/zone-stage-mapping/zone-stage-mapping.component';
import { ZoneTypeComponent } from 'src/app/components/zone-type/zone-type.component';
import { DatatableComponent } from 'src/app/directives/datatable/datatable.component';
import { DatetimePickerComponent } from 'src/app/directives/datetime-picker/datetime-picker.component';
import { NgSmartTableComponent } from 'src/app/directives/ng-smart-table/ng-smart-table.component';
import { SmartTableComponent } from 'src/app/directives/smart-table/smart-table.component';
import { LogoutModalComponent } from 'src/app/logout-modal/logout-modal.component';
import { MisReportComponent } from 'src/app/mis-report/mis-report.component';
import { ActionDashboardOverrideComponent } from 'src/app/modals/action-dashboard-override/action-dashboard-override.component';
import { ActionFormComponent } from 'src/app/modals/action-form/action-form.component';
import { AddFieldModalComponent } from 'src/app/modals/add-field-modal/add-field-modal.component';
import { AddVehicleComponent } from 'src/app/modals/add-vehicle/add-vehicle.component';
import { AlertRemarkComponent } from 'src/app/modals/alert-remark/alert-remark.component';
import { AssignProcessComponent } from 'src/app/modals/assign-process/assign-process.component';
import { AuditModalComponent } from 'src/app/modals/audit-modal/audit-modal.component';
import { ChangePasswordComponent } from 'src/app/modals/change-password/change-password.component';
import { ChecklistHistoryDataComponent } from 'src/app/modals/checklist-history-data/checklist-history-data.component';
import { DriverNavigationComponent } from 'src/app/modals/driver-navigation/driver-navigation.component';
import { EllipsisModalComponent } from 'src/app/modals/ellipsis-modal/ellipsis-modal.component';
import { GeofenceModalComponent } from 'src/app/modals/geofence-modal/geofence-modal.component';
import { HardwareStatusChangeComponent } from 'src/app/modals/hardware-status-change/hardware-status-change.component';
import { ProcessVehicleViewComponent } from 'src/app/modals/process-vehicle-view/process-vehicle-view.component';
import { RemarkComponent } from 'src/app/modals/remark/remark.component';
import { SlotRangeModalComponent } from 'src/app/modals/slot-range-modal/slot-range-modal.component';
import { TripCommentTrailComponent } from 'src/app/modals/trip-comment-trail/trip-comment-trail.component';
import { TripReportModalComponent } from 'src/app/modals/trip-report-modal/trip-report-modal.component';
import { UserTrackingInfoModalComponent } from 'src/app/modals/user-tracking-info-modal/user-tracking-info-modal.component';
import { VehicleHistoryComponent } from 'src/app/modals/vehicle-history/vehicle-history.component';
import { VehicleStatusChangeComponent } from 'src/app/modals/vehicle-status-change/vehicle-status-change.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { ViewRemarkComponent } from 'src/app/modals/view-remark/view-remark.component';
import { ActionDashboardComponent } from 'src/app/components/action-dashboard/action-dashboard.component';
import { AddVisitorComponent } from 'src/app/components/add-visitor/add-visitor.component';
import { FormFieldMappingComponent } from 'src/app/components/form-field-mapping/form-field-mapping.component';
import { FormManagementComponent } from 'src/app/components/form-management/form-management.component';
import { FormTypeManagementComponent } from 'src/app/components/form-type-management/form-type-management.component';
import { VerifyVisitorModalComponent } from 'src/app/components/verify-visitor-modal/verify-visitor-modal.component';
import { VisitorAdminDashboardComponent } from 'src/app/components/visitor-admin-dashboard/visitor-admin-dashboard.component';
import { AddFormFieldModalComponent } from 'src/app/modals/add-form-field-modal/add-form-field-modal.component';
import { VisitorReportComponent } from 'src/app/components/visitor-report/visitor-report.component';
import { VisitorBanComponent } from 'src/app/components/visitor-ban/visitor-ban.component';
import { DataNotFoundComponent } from 'src/app/modals/data-not-found/data-not-found.component';
import { ComplianceSeverityMappingComponent } from 'src/app/components/compliance-severity-mapping/compliance-severity-mapping.component';
import { TripTrackingLogsComponent } from 'src/app/modals/trip-tracking-logs/trip-tracking-logs.component';
import { AddComplianceModalComponent } from 'src/app/modals/add-compliance-modal/add-compliance-modal.component';
import { VehicleDetailsComponent } from 'src/app/components/vehicle-details/vehicle-details.component';
import { DriverDetailsComponent } from 'src/app/components/driver-details/driver-details.component';
import { TransporterDetailsComponent } from 'src/app/components/transporter-details/transporter-details.component';
import { CustomFieldNewComponent } from 'src/app/components/custom-field-new/custom-field-new.component';
import { FormComponent } from 'src/app/components/form/form.component';
import { ComplianceComponent } from 'src/app/components/compliance/compliance.component';
import { ActiveVisitsComponent } from 'src/app/components/active-visits/active-visits.component';
import { VisitorTrackingComponent } from 'src/app/components/visitor-tracking/visitor-tracking.component';
import { VisitDateWiseComponentComponent } from 'src/app/components/visit-date-wise-component/visit-date-wise-component.component';
import { VisitDateWiseReportModalComponent } from 'src/app/components/visit-date-wise-report-modal/visit-date-wise-report-modal.component';
import { VisitorCardFieldMappingComponent } from 'src/app/components/visitor-card-field-mapping/visitor-card-field-mapping.component';

@NgModule({
  declarations: [
    UserComponent,
    DashboardComponent,
    CompaniesComponent,
    UserManagementComponent,
    ShiftCalendarComponent,
    MasterFieldComponent,
    StageWiseUserDashboardComponent,
    GroupManagementComponent,
    CompaniesComponent,
    PlantComponent,
    ProcessComponent,
    StageTypeComponent,
    ViewDetailsComponent,
    WorkTypeComponent,
    UserGroupAssociationComponent,
    UserActionMappingComponent,
    ProcessStageComponent,
    StageActionComponent,
    StageDestinationCodeComponent,
    StageDestinationCodeOccupancyComponent,
    ActionsComponent,
    StagesComponent,
    StageComponent,
    CommunicationTemplateComponent,
    SetUserPermissionComponent,
    CommunicationTypeComponent,
    stagetatTableComponent,
    TripactivityComponent,
    StageVehiclesComponent,
    AverageTatComponent,
    TripactivityComponent,
    StageSettingComponent,
    HelpComponent,
    StageDestinationCodeTypeComponent,
    ChangePasswordComponent,
    DriverTrackerComponent,
    GroupRoleMappingComponent,
    AdminDashboardComponent,
    UserDashboardComponent,
    CustomerDashboardComponent,
    ProcessStageComponent,
    ComponenttComponent,
    ShiftMasterComponent,
    TripDetailComponent,
    TMGDashboardComponent,
    StageDashboardComponent,
    TripDashboardComponent,
    AssetLabelComponent,
    AssetsComponent,
    VehicleDashboardComponent,
    LiveDashboardComponent,
    DetailModalComponent,
    SmartHeaderComponent,
    ResetPasswordComponent,
    StageWiseUserDashboardComponent,
    UserShiftMappingComponent,
    PlantGeofenceComponent,
    CommunicationComponent,
    ActionFormComponent,
    AuditManagementComponent,
    ActionActionMappingComponent,
    MethodManagementComponent,
    AuditLogsComponent,
    PlantSettingComponent,
    OverridingActionsComponent,
    AuditModalComponent,
    GeofenceModalComponent,
    PlantFieldMappingComponent,
    ActionDashboardOverrideComponent,
    TripMapViewComponent,
    VisitorDashboardComponent,
    VisitorReportsComponent,
    VisitorVisitsComponent,
    TatReportsComponent,
    TripReportComponent,
    OverridingLogsComponent,
    EmployeeReportComponent,
    ShiftReportComponent,
    VisitorReportByEmpNoComponent,
    DriverNavigationComponent,
    AddVehicleComponent,
    HardwareStatusComponent,
    ManageHardwareComponent,
    HardwareMappingComponent,
    PlantHardwareViewComponent,
    DestinationStageMappingComponent,
    FieldToleranceMappingComponent,
    VehicleStatusChangeComponent,
    MethodCommunicationMappingComponent,
    AssignProcessComponent,
    PlantLiveViewComponent,
    LoaderViewComponent,
    AlertManagementComponent,
    MethodMappingComponent,
    HardwareSettingComponent,
    OtaManagementComponent,
    AlertTypeComponent,
    AlertCommunicationMappingComponent,
    AlertDashboardComponent,
    VehicleHistoryComponent,
    AlertTypeComponent,
    CompanyAdminDashboardComponent,
    TripHistoryComponent,
    AppManagementComponent,
    AppVersionDataComponent,
    TripFlowComponent,
    NotificationListComponent,
    DriverIvrCallComponent,
    LogoutModalComponent,
    ProcessVehicleViewComponent,
    HardwareOTALogsComponent,
    HardwareStatusChangeComponent,
    ParkingCreationComponent,
    DockManagementComponent,
    ParkingDashboardComponent,
    ZoneManagementComponent,
    ZoneSlotMappingComponent,
    UserChecklistMappingComponent,
    AddChecklistComponent,
    ChecklistActionsMappingComponent,
    ChecklistComponent,
    ChecklistDashbaordComponent,
    TimeCounterComponent,
    ViewChecklistComponent,
    DockDashboardComponent,
    ChecklistQrModalComponent,
    TimeCounterComponent,
    SlotManagementComponent,
    SignatureComponent,
    PlantLiveViewModalComponent,
    SignatureComponent,
    PlantLiveViewModalComponent,
    StageZoneMappingComponent,
    ZoneDashboardComponent,
    HelpSubmenuComponent,
    HelpInfoComponent,
    TripReportModalComponent,
    EmployeeDetailModalComponent,
    ProductivityReportComponent,
    TatInbetweenReportComponent,
    ChecklistAdminDashboardComponent,
    StatusWiseChecklistLogsComponent,
    ChecklistHistoryComponent,
    SlotRangeModalComponent,
    ChecklistHistoryDataComponent,
    AssignUserModalComponent,
    PendingTasksForUserModalComponent,
    RemarkComponent,
    ViewRemarkComponent,
    EntryFormComponent,
    AssignedTripsDashboardComponent,
    AssignedTripInfoModalComponent,
    AlertUserMappingComponent,
    AlertTaskDashboardComponent,
    AlertRemarkComponent,
    AlertReportComponent,
    AlertViewDetailsModalComponent,
    AlertLogInfoModalComponent,
    VehicleReportComponent,
    TripScheduleComponent,
    StatusManagementComponent,
    DepartmentManagementComponent,
    DepartmentUserMappingComponent,
    LiveStreamingComponent,
    EllipsisModalComponent,
    ManufacturerComponent,
    SupplierComponent,
    AssetCategoryComponent,
    AssetLabelComponent,
    AssetCategoryFieldComponent,
    AddFieldModalComponent,
    AssetRequestComponent,
    AssetLabelMappingComponent,
    CustomFieldComponent,
    InventoryManagerChecklistComponent,
    UserTrackingReportComponent,
    DocumentTypeComponent,
    AssetDocumentComponent,
    AssetViewComponent,
    AddAssetRequestComponent,
    AssetRequestRemarkComponent,
    UserTagMappingComponent,
    AssetWarrantyComponent,
    AssetInsuranceComponent,
    AddInsuranceComponent,
    AssetDetailComponent,
    UserTrackingDashboardComponent,
    AssetsTrackingDasboardComponent,
    AssetDepreciationComponent,
    AssetMaintenanceComponent,
    ZoneStageMappingComponent,
    ZoneAllocationComponent,
    ParkingZoneManagementComponent,
    ZoneTypeComponent,
    AssetLinkingComponent,
    AssetDepreciationComponent,
    AssetHistoryComponent,
    CheckInCheckOutModalComponent,
    AssetEventsComponent,
    AssetReserveComponent,
    UserTrackingDashboardComponent,
    AssetsTrackingDasboardComponent,
    AddDriverTransporterComponent,
    AssignDriverTransporterComponent,
    AssetsTrackingDasboardComponent,
    ManualStampingFormComponent,
    VehicleWhitelistBlacklistComponent,
    VehicleTagMappingComponent,
    MapViewModalComponent,
    AssignMaterialComponent,
    ProductManagementComponent,
    ProductTypeComponent,
    ProductDockLinkingComponent,
    MisReportComponent,
    VehicleRouteConfigurationComponent,
    DockAppointmentComponent,
    TripCommentTrailComponent,
    HardwareHealthComponent,
    DockBookingModalComponent,
    TransporterBookingsComponent,
    HardwareVehicleLogComponent,
    DockAppointmentReportComponent,
    User_path_trackingComponent,
    AssetViewComponent,
    InBetweenVehiclesComponent,
    TagDestinationTatReportComponent,
    DashboardModalComponent,
    UserTrackingInfoModalComponent,
    AssetTrackingReportComponent,
    BanTransporterComponent,
    DriverBanComponent,
    VehicleBanComponent,
    UserTrackingViewComponent,
    AssetsTrackingViewComponent,
    ApprovalFlowComponent,
    VisitorVisitsComponent,
    VisitorDashboardComponent,
    VerifyVisitorModalComponent,
    FormTypeManagementComponent,
    FormManagementComponent,
    FormFieldMappingComponent,
    AddFormFieldModalComponent,
    AddVisitorComponent,
    ActionDashboardComponent,
    VisitorAdminDashboardComponent,
    VisitorReportComponent,
    VisitorBanComponent,
    ComplianceSeverityMappingComponent,
    TripTrackingLogsComponent,
    AddComplianceModalComponent,
    VehicleDetailsComponent,
    DriverDetailsComponent,
    TransporterDetailsComponent,
    ActiveVisitsComponent,
    VisitorTrackingComponent,
    ComplianceComponent,
    VisitDateWiseComponentComponent,
    VisitDateWiseReportModalComponent,
    VisitorCardFieldMappingComponent
  ],
  imports: [
    UserRoutingModule,
    SharedModule
  ],
})
export class UserModule {}

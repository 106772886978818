<div class="card card-dashbaord stage_vehicle" *ngIf="userService.permissionMap.get('view_-stagevehicles_access')==true">
  <div class="card-header">
    <h3 class="card-title">{{"stage_vehicles"| translate}}</h3>
    <div class="input_class">
      <div class="form-group" >
        <div style="display:flex">
          <label style="font-size: 13px;margin-right: 3px;margin-bottom: 0px;">{{'select_process'|translate}} :</label>
          <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="plantProcessData" display="processName"
            placeholder="Select Process" name="processName" [preSelected]="{processName:processName}">
          </ps-auto-suggestion>
        </div>
      </div>
    </div>
  </div>


  <div class="card-body">
    <div class="stage_vehicle_row" [ngClass]="{'info-group-long':dashboardStageCard.length > 5}">

      <!-- [ngClass]="'bg-'+item.color" [style.background-color]="item.color" -->
      <div class="stage_vehicle_box" *ngFor="let item of dashboardStageCard" [style.background-color]="item.bgColor" (click)="getStageProcessWiseVhicles(item.stage_id);modalTitle=item.stage_name">
        <div class="top_part">
          <h4>{{item.stage_vehicle}} <span>/ {{item.max_vehicle_count}}</span></h4>
          <!-- [ngClass]="'bg-'+item.color" -->
          <i class="fa fa-truck" [style.color]="item.color"></i>
        </div>

        <div class="bottom_part">
          <p [title]="item.stage_name">{{item.stage_name}}</p>
          <div class="time_des">{{item.total_tat}}<span style='margin-left:5px' [ngClass]="item.tat_percentage?.increase ? 'fluctuate_minus' : 'fluctuate_plus'">{{item.tat_percentage?.percentage}}</span></div>
          <!-- <div class="time_des">09:00 <span class="fluctuate_minus">- 0.25%</span></div> -->
        </div>
      </div>
    </div>
    <app-data-not-found *ngIf="!dashboardStageCard.length && !common.loading"></app-data-not-found>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body process-form">

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'assets'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input">{{"company" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="company" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                </div>

                <div *ngIf="(role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN')"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantData"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'asset_name'|customTranslate}}</label>
                  <input class="form-control" type="text" name="assetName" [(ngModel)]="asset.assetName" (change)="getAssetCode()">
                  <div class="error" *ngIf="assetNameValidator && assetNameValidator.error">
                    <span>{{assetNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{'asset_desc'|customTranslate}}</label>
                  <input class="form-control" type="text" name="assetDesc" [(ngModel)]="asset.assetDesc">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"asset_category" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCategory($event)" class="required" [data]="categoryData"
                    display="asset_category_name" placeholder="Choose Category"
                    [preSelected]="{asset_category_name : CategoryName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="assetCategoryValidator && assetCategoryValidator.error">
                    <span>{{assetCategoryValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{'asset_image'|customTranslate}}</label>
                  <img *ngIf="asset.assetImage!=null" [src]="asset.assetImage">
                  <input type="file" #myInput accept=".jpg,.png,.jpeg,.pdf,.xlsx,.xlsm,.xls" class="field_class file_class"
                   multiple (change)="onSelectimage($event,null);" [ngClass]="{'has-file':asset.assetImage!=null}"><span *ngIf="asset.assetImage!=null">{{imageName(asset.assetImage)}}</span>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required"> {{'status'|customTranslate}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="assetStatus" [(ngModel)]="asset.assetStatus"
                      id="asset_status_1" value="ACTIVE" checked>
                    <label for="asset_status_radios1">
                      {{'active'|customTranslate}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="assetStatus" [(ngModel)]="asset.assetStatus"
                      id="assetStatus" value="INACTIVE">
                    <label for="asset_status_radio2">
                      {{'inactive'|customTranslate}}
                    </label>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"asset_manufacturer" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedManufacturer($event)" class="required"
                    [data]="manufacturerData" display="manufacturer_name" placeholder="Choose Manufacturer"
                    [preSelected]="{manufacturer_name: ManufacturerName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="manufacturerValidator && manufacturerValidator.error">
                    <span>{{manufacturerValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"asset_supplier" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedSupplier($event)" class="required" [data]="supplierData"
                    display="supplier_first_name" placeholder="Choose Supplier"
                    [preSelected]="{supplier_first_name : SupplierName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="supplierValidator && supplierValidator.error">
                    <span>{{supplierValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'asset_cost'|customTranslate}}</label>
                  <input class="form-control" type="number" name="assetCost" [(ngModel)]="asset.assetCost">
                  <div class="error" *ngIf="assetCostValidator && assetCostValidator.error">
                    <span>{{assetCostValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{'unique_tag_code'|customTranslate}}</label>
                  <input class="form-control" type="text" name="uniqueTagCode" [(ngModel)]="asset.uniqueTagCode">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{'unique_tag_type'|customTranslate}}</label>
                  <select class="form-control" [(ngModel)]="asset.uniqueTagType">
                    <option *ngFor="let tag of tagType" [value]="tag">{{ tag }}</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" >
                  <label for="my-input">{{"parent_asset" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedParentAsset($event)"
                    [data]="asset.assetId?newParentData:parentAssetData" display="asset_name"
                    placeholder="Choose Asset group Parent" [preSelected]="{asset_name: ParentAssetName}" [readonly]="editParent">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{'expiry_date'|customTranslate}}</label>
                  <input class="form-control" [min]="minimumTimestamp" type="datetime-local" name="timestamp" step="1"
                    [(ngModel)]="asset.expiryDate">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{'warranty_expire_date'|customTranslate}}</label>
                  <input class="form-control" [min]="minimumTimestamp" type="datetime-local" name="timestamp" step="1"
                    [(ngModel)]="asset.warrantyExpireDate">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{'asset_code'|customTranslate}}</label>
                  <input class="form-control" type="text" name="uniqueTagCode" [(ngModel)]="asset.assetCode" disabled>
                </div>
              </div>

              <div class="row">
                <ng-container *ngFor="let field of customFields; index as i">
                  <app-custom-field class="col-md-3 col-lg-3" [field]="field" [i]="i" [selvalue]="null" [customFieldValues]="customFieldValues" [notUniqueFieldId]="notUniqueFieldId"
                    (valueChange)="field.field_type=='GEOCODEDIMAGE'?handleImage($event,field,i):(field.field_type=='SIGNATURE'?saveSignature($event,i,field):(field.field_type=='LABEL'?insertLabel(field,i):enterValues($event,field,i)))"></app-custom-field>
                </ng-container>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)=" common.backClicked()"> {{'back'|customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="resetAsset()"> {{'reset'|customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{'save'|customTranslate}}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'asset_list'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{'serial_no'|customTranslate}}</th>
                    <th>{{'asset_name'|customTranslate}}</th>
                    <th>{{"asset_category" | customTranslate}}</th>
                    <th>{{"asset_manufacturer" | customTranslate}}</th>
                    <th>{{"asset_supplier" | customTranslate}}</th>
                    <th>{{"asset_parent" | customTranslate}}</th>
                    <th>{{"asset_code" | customTranslate}}</th>
                    <th>{{'status'|customTranslate}}</th>
                    <th class="action">{{'Actions'|customTranslate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of responseData let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.asset_name}}</td>
                    <td>{{row.asset_category_name}}</td>
                    <td>{{row.manufacturer_name}}</td>
                    <td>{{row.supplier_name}}</td>
                    <td>{{row.asset_parent}}</td>
                    <td>{{row.asset_code}}</td>
                    <td>{{row.status}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewAsset(row)"> {{'view'|customTranslate}}
                        </button>
                        <button class="btn btn-success" *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                        || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1))"
                          (click)="editAsset(row)"> {{'edit'|customTranslate}}
                        </button>
                        <button class="btn btn-danger" *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                        || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1))"
                          (click)="confirmDelete(row)">
                          {{'delete'|customTranslate}} </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { Location } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { Angular5Csv } from "angular5-csv/dist/Angular5-csv";
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
// import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {


  private tripIdSubject = new BehaviorSubject('Basic Approval is required!');
  private isVisible = new BehaviorSubject('Post & Require Action Visibility');
  swiftTripId = this.tripIdSubject.asObservable();
  actionVisible = this.isVisible.asObservable();
  usermenu: any = [];
  params: any;
  loading = false;
  screenloading: boolean = true;
  dashbordClickedIcon: any = localStorage.getItem('customer-route');
  dashboardparam: string = '';
  graphprimary: string = '#3699ff';
  graphsecondary: string = '#ffa800cc';
  graphsuccess: string = '#28a745cc';
  graphdanger: string = '#f64e60cc';
  tripdetail: any = [];
  tripId:any=null;
  menuToggled: boolean;
  activeMenuset: boolean = false;
  currentYear: number = new Date().getFullYear();
  driverView: boolean = false;
  commondashboardNavigate: boolean = false;
  commondashboardSubmenu: any;
  companyNameValidator: any;
  plantNameValidator: any;
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  language= new Subject();
  constructor(public http: HttpClient,
    public user: UserService,
    public translateService: TranslateService,
    private _location: Location,
    private router: Router) {
    }

    setLanguage(language: any){
      localStorage.setItem("language",language);
      this.translateService.use(language);
      this.language.next(language);
      window.location.reload();
    }




  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  nullValidator(txt: any, msg?: any) {

    let obj = {
      msg: null,
      error: false
    }
    if (txt == null || txt == '' ) {
      obj.msg = msg ? msg : "Field Mandatory";
      obj.error = true;
    }
    return obj;
  }

  listValidator(list: any, msg?: any) {

    let obj = {
      msg: null,
      error: false
    }
    if (list == null || list.length == 0 ) {

      obj.msg = msg ? msg : "Field Mandatory";
      obj.error = true;
    }
    return obj;
  }

  updateTripId(tripId) {
    this.tripIdSubject.next(tripId);
  }

  updateIsVisible(isVisible){
    this.isVisible.next(isVisible);
  }


  mobileNoValidator(mobileNo: any, msg: any) {
    let obj = {
      msg: '',
      error: false
    }
    if (mobileNo == null || mobileNo == '') {
      obj.msg = "Mobile No. Mandatory";
      obj.error = true;
    }
    else {
      var mob = /^[6-9]{1}[0-9]{9}$/;
      if (mob.test(mobileNo) == false) {
        obj.msg = msg ? msg : "Mobile No. Invalid";
        obj.error = true;
      }
    }
    return obj;
  }

  emailValidator(email: any, msg: any) {
    let obj = {
      msg: null,
      error: false
    }
    var em = /^[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}$/;
    if (em.test(email) == false) {
      obj.msg = msg;
      obj.error = true;
    }
    return obj;
  }

  startEndDateValidator(startDate:Date,endDate:Date,allowEqual:Boolean) {
    let obj = {
      msg: null,
      error: false
    }

    // if (!startDate || !endDate) {
    //   obj.msg="Start date or End date Mandatory";
    //   obj.error= true;
    // }
    // else{
      if(!allowEqual){
        if (endDate <= startDate) {
          obj.msg="End date should be greater than Start date";
          obj.error= true;
        }
      // }
    }
    return obj;
  }

  startEndTimeValidator(startTime:string,endTime:string,allowEqual:Boolean) {
    let obj = {
      msg: null,
      error: false
    }
    // if (!startTime || !endTime) {
    //   obj.msg="Start time or End time Mandatory";
    //   obj.error= true;
    // }
    // else{
      if(!allowEqual){
        if (endTime <= startTime) {
          obj.msg="End time should be greater than Start time";
          obj.error= true;
        }
      // }
    }
    return obj;
  }

  graphcolorhandler() {

  }

  numericValidator(txt: any, msg?: any) {

    let obj = {
      msg: null,
      error: false
    }
    if ((txt != null || txt != "") && isNaN(txt)) {
      obj.msg = msg ? msg : "Invalid Input";
      obj.error = true;
    }
    return obj;
  }


  backClicked() {
    this.router.navigateByUrl('/user/dashboard');
    this.activeMenuset = true;
    this.commondashboardNavigate = false;
  }


  tripview(trip: any[]) {
    this.tripdetail = trip;
    this.router.navigate(['/user/trip-detail']);
  }

  objecttoarray(object: any) {
    let array: any = [];
    for (const [key, value] of Object.entries(object)) {
      array.push({
        name: `${key}`,
        value: `${value}`
      })
    }
    return array;
  }

  yearValidator(searchvalue?: any) {
    let obj = {
      msg: 'null',
      error: false
    }
    if (searchvalue > this.currentYear || searchvalue < '1800') {
      obj.msg = "Invalid Input Year";
      obj.error = true;
    }
    return obj;
  }

  sizeValidator(txt: any, msg?: any) {

    let obj = {
      msg: null,
      error: false
    }
    var mob = /^[0-9]{12}$/;
    if (mob.test(txt) == false) {

      obj.msg = msg ? msg : "Invalid Input";
      obj.error = true;
    }
    return obj;
  }


  //  function taken form Dost for Dynamic Graphical Report

  shuffle(array: any) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  HSLToHex(h: number, s: number, l: number) {
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs((h / 60) % 2 - 1)),
      m = l - c / 2,
      r = 0,
      g = 0,
      b = 0;

    if (0 <= h && h < 60) {
      r = c; g = x; b = 0;
    } else if (60 <= h && h < 120) {
      r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
      r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
      r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
      r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
      r = c; g = 0; b = x;
    }
    // Having obtained RGB, convert channels to hex
    let rs = Math.round((r + m) * 255).toString(16);
    let gs = Math.round((g + m) * 255).toString(16);
    let bs = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (rs.length == 1)
      rs = "0" + rs;
    if (gs.length == 1)
      gs = "0" + gs;
    if (bs.length == 1)
      bs = "0" + bs;

    return "#" + rs + gs + bs;
  }

  timeFormatter(date: any) {
    let d = new Date(date);
    let hours = d.getHours() <= 9 ? "0" + d.getHours() : d.getHours();
    let minutes = d.getMinutes() <= 9 ? "0" + d.getMinutes() : d.getMinutes();
    let seconds = d.getSeconds() <= 9 ? "0" + d.getSeconds() : d.getSeconds();

    return hours + ":" + minutes + ":" + seconds;
  }

  dateFormatter(date: any, type = "YYYYMMDD", isTime = true, separator = "-"): any {
    let d = new Date(date);
    let year = d.getFullYear();
    let month = d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    let dat = d.getDate() <= 9 ? '0' + d.getDate() : d.getDate();

    if (type == "ddMMYYYY") {
      return (
        dat +
        separator +
        month +
        separator +
        year +
        (isTime ? " " + this.timeFormatter(date) : "")
      );
    } else {
      return (
        year +
        separator +
        month +
        separator +
        dat +
        (isTime ? " " + this.timeFormatter(date) : "")
      );
    }
  }

  getDate(days = 0, formatt?: any) {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + days);
    if (formatt) {
      return this.dateFormatter(currentDate, formatt);
    }
    return currentDate;
  }


  generateArray(length) {
    let generatedArray = [];
    for (let i = 0; i < length; i++) {
      generatedArray.push(i + 1);
    }
    return generatedArray;
  }

  ucWords(str) {
    str = str.toLowerCase();
    var words = str.split(' ');
    str = '';
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      word = word.charAt(0).toUpperCase() + word.slice(1);
      if (i > 0) { str = str + ' '; }
      str = str + word;
    }
    return str;
  }


  distanceFromAToB(lat1, lon1, lat2, lon2, unit, isFixed = true, isMultiply = true): any {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      let radlat1 = (Math.PI * lat1) / 180;
      let radlat2 = (Math.PI * lat2) / 180;
      let theta = lon1 - lon2;
      let radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344 * 1000;
      if (isMultiply)
        dist = this.odoMultiplierWithMeter(dist);
      dist /= 1.609344 * 1000;

      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "Mt") {
        dist = dist * 1.609344 * 1000;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }

      if (!isFixed) {
        return parseFloat(dist.toFixed(2));
      }

      return parseInt(dist.toFixed(0));
    }
  }

  odoMultiplierWithMeter(distance: number) {
    if (distance < 200) {
      distance = distance * 1.02;
    } else if (distance > 200 && distance < 1000) {
      distance = distance * 1.03;
    } else if (distance > 1000 && distance < 10000) {
      distance = distance * 1.05;
    } else if (distance > 10000 && distance < 50000) {
      distance = distance * 1.06;
    } else if (distance > 50000 && distance < 200000) {
      distance = distance * 1.10;
    } else {
      distance = distance * 1.15;
    }
    return distance;



  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  //  End function taken form Dost for Dynamic Graphical Report


  handleModalSize(type, name, size, sizeType = "px", position = 0) {
    setTimeout(() => {
      if (type == "class" && document.getElementsByClassName(name)[position]) {
        if (document.getElementsByClassName(name)[position]["style"]) {
          document.getElementsByClassName(name)[position]["style"].maxWidth =
            size + sizeType;
        }
      }
    }, 10);
  }
  handleModalheight(type, name, size, sizeType = "px", position = 0) {
    setTimeout(() => {
      if (type == "class") {
        document.getElementsByClassName(name)[position]["style"].minHeight =
          size + sizeType;
      }
    }, 10);
  }


  sidebarToggled(toggled = true) {
    if (toggled) {
      document.body.classList.add('sidebar-toggled');
      this.menuToggled = false;
    }

    else {
      document.body.classList.remove('sidebar-toggled');
      this.menuToggled = true;
    }
  }

  // start: csv export from data
  getCSVFromDataArray(dataArray, dataHeader, fileName, titles?, doNotIncludes?) {
    let organization = { "elogist Solutions": "elogist Solutions" };
    let name = (fileName && fileName != "") ? fileName : 'report';

    let info = [];
    let blankline = { "": "" };
    if (titles && titles.length > 0) {
      info.push(titles);
      info.push(blankline);
    }
    // console.log("given data array:", dataArray);
    // console.log("dataHeader:", dataHeader);
    if (dataArray.length > 0) {
      let objectKeys = Object.keys;
      let thArray = [];
      let thArg = [];
      for (let heading of objectKeys(dataHeader)) {
        // console.log("heading in array:", heading);
        let dataHeaderTemp = dataHeader[heading].title;
        if (dataHeaderTemp == '' || dataHeaderTemp == 'Action' || dataHeaderTemp == 'action')
          continue;

        thArg.push(dataHeaderTemp);
        thArray.push(this.formatTitle(dataHeaderTemp));
      }

      info.push(thArray);

      dataArray.map(column => {
        let tdArray = [];
        for (let heading of thArg) {
          let columnTemp = (column[heading]) ? column[heading] : '-';
          tdArray.push(columnTemp);
        }
        info.push(tdArray);
      })
    }
    new Angular5Csv(info, name);
  }

  formatTitle(strval) {
    let pos = strval.indexOf('_');
    if (pos > 0) {
      return strval.toLowerCase().split('_').map(x => x[0].toUpperCase() + x.slice(1)).join(' ')
    } else {
      return strval.charAt(0).toUpperCase() + strval.substr(1);
    }
  }

  getCSVFromTableId(tblEltId, left_heading?, center_heading?, doNotIncludes?, time?, lower_left_heading?, reportName = 'report') {
    // console.log(arguments);
    let tblelt = document.getElementById(tblEltId);
    if (tblelt.nodeName != "TABLE") {
      tblelt = document.querySelector("#" + tblEltId + " table");
    }

    let organization = { "elogist Solutions": "elogist Solutions" };
    let blankline = { "": "" };

    let leftData = { left_heading };
    let centerData = { center_heading };
    // let lowerLeft = lower_left_heading ? { lower_left_heading } : {};
    let doctime = { time };

    let info = []; lower_left_heading
    let hdgs = {};
    let arr_hdgs = [];
    // info.push(organization);
    // info.push(blankline);
    // info.push(leftData);
    // info.push(centerData, doctime);
    // info.push(lowerLeft);
    let hdgCols = tblelt.querySelectorAll('th');
    if (hdgCols.length >= 1) {
      for (let i = 0; i < hdgCols.length; i++) {
        let isBreak = false;
        for (const donotInclude in doNotIncludes) {
          if (doNotIncludes.hasOwnProperty(donotInclude)) {
            const thisNotInclude = doNotIncludes[donotInclude];
            // console.log('thisNotInclude:', thisNotInclude)
            if (hdgCols[i].innerHTML.toLowerCase().includes("title=\"" + thisNotInclude.toLowerCase() + "\"")) {
              isBreak = true;
              break;
            }
          }
        }
        if (isBreak)
          continue;


        if (hdgCols[i].innerHTML.toLowerCase().includes(">image<"))
          continue;
        if (hdgCols[i].classList.contains('del'))
          continue;
        let elthtml = hdgCols[i].innerHTML;
        if (elthtml.indexOf('<input') > -1) {
          let eltinput = hdgCols[i].querySelector("input");
          let attrval = eltinput.getAttribute("placeholder");
          hdgs[attrval] = attrval;
          arr_hdgs.push(attrval);
        } else if (elthtml.indexOf('<img') > -1) {
          let eltinput = hdgCols[i].querySelector("img");
          let attrval = eltinput.getAttribute("title");
          hdgs[attrval] = attrval;
          arr_hdgs.push(attrval);
        } else if (elthtml.indexOf('href') > -1) {
          let strval = hdgCols[i].innerHTML;
          hdgs[strval] = strval;
          arr_hdgs.push(strval);
        } else {
          let plainText = elthtml.replace(/<[^>]*>/g, '');
          let plainIndex = (tblEltId == "attendanceSummary") ? '"' + plainText + '"' : plainText;
          hdgs[plainIndex] = plainText;
          arr_hdgs.push(plainIndex);
        }
      }
    }
    info.push(hdgs);

    let tblrows = tblelt.querySelectorAll('tbody tr');
    if (tblrows.length >= 1) {
      for (let i = 0; i < tblrows.length; i++) {
        if (tblrows[i].classList.contains('cls-hide'))
          continue;
        let rowCols = tblrows[i].querySelectorAll('td');
        let rowdata = [];
        for (let j = 0; j < rowCols.length; j++) {
          if (rowCols[j].classList.contains('del'))
            continue;
          let colhtml = rowCols[j].innerHTML;
          if (colhtml.indexOf('input') > -1) {
            let eltinput = rowCols[j].querySelector("input");
            let attrval = eltinput.getAttribute('placeholder');
            rowdata[arr_hdgs[j]] = attrval;
          } else if (colhtml.indexOf('img') > -1) {
            let eltinput = rowCols[j].querySelector("img");
            let attrval = eltinput && eltinput.getAttribute('title');
            rowdata[arr_hdgs[j]] = attrval;
          } else if (colhtml.indexOf('href') > -1) {
            let strval = rowCols[j].innerHTML;
            rowdata[arr_hdgs[j]] = strval;
          } else if (colhtml.indexOf('</i>') > -1) {
            let pattern = /<i.* title="([^"]+)/g;
            let match = pattern.exec(colhtml);
            if (match != null && match.length)
              rowdata[arr_hdgs[j]] = match[1];
          } else {
            let plainText = colhtml.replace(/<[^>]*>/g, '');
            let tdIndexTemp = (tblEltId == "attendanceSummary") ? '"' + arr_hdgs[j] + '"' : arr_hdgs[j];
            rowdata[tdIndexTemp] = plainText;
          }
        }
        info.push(rowdata);
      }
    }
    new Angular5Csv(info, reportName);
  }

  createOnline$() {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
  checkCompanyPlantAccToUser(type:string,logginUserType:string,company:any,plant:any){
    let companyNameValidator = this.nullValidator(company, "Company Name Mandatory");
    let plantNameValidator = this.nullValidator(plant, "Plant Name Mandatory");
    let flag = {
      company: true,
      plant: true
    }
    if( type == 'COMPANYADMIN'){
      if(logginUserType=='AXESTRACK'){
        if (companyNameValidator.error) {
          flag.company = false;
        }
      }
    }
    else if(type == 'COMPANYUSER'){
      if(logginUserType=='AXESTRACK'){
        if (companyNameValidator.error) {
          flag.company = false;
        }
        if(plantNameValidator.error){
          flag.plant = false;
        }
      }else{
        if (plantNameValidator.error) {
         flag.plant=true
        }
      }
    }
    else if(type=='PLANTADMIN' || type=='PLANTUSER'){
      if(logginUserType=='AXESTRACK'){
        if (companyNameValidator.error) {
          flag.company = false;
        }
        if(plantNameValidator.error){
          flag.plant = false;
        }
      }
      else if(logginUserType=='COMPANYADMIN'){
        if(plantNameValidator.error){
          flag.plant = false;
        }
      }
    }
    return flag;
  }

   exportExcel(jsonData: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

   saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  minutesToHours(minutes: number): string {
    if (isNaN(minutes)) {
      return '00:00';
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursString = (hours < 10 ? '0' : '') + hours;
    const minutesString = (remainingMinutes < 10 ? '0' : '') + remainingMinutes;

    return `${hoursString}:${minutesString}`;
  }
}

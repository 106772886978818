import { UserService } from 'src/app/@core/services/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  dt = new Date();
  dt1 = '';
  constructor(public userService: UserService) {
    this.display_c5();
  }

  ngOnInit(): void {}

  display_ct5() {
    var x = new Date();
    var ampm = x.getHours() >= 12 ? ' PM' : ' AM';
    var x1 = x.getMonth() + 1 + '/' + x.getDate() + '/' + x.getFullYear();
    this.dt1 =
      x1 +
      ' ' +
      x.getHours() +
      ':' +
      x.getMinutes() +
      ':' +
      x.getSeconds() +
      ' ';
  }
  display_c5() {
    var refresh = 1000; // Refresh rate in milli seconds
    setInterval(() => {
      this.display_ct5();
    }, refresh);
  }
}

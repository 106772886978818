<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"zone_dashboard" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="select_row">
              <div class="row m-0">
                <div class="col-md-8">

                </div>

                <div class="col-md-4">
                  <div class="form-group mb-0">
                    <div class="row align-items-center">
                      <div class="col-md-5">
                        <label for="my-input" class="required"> {{"process -> stage" | customTranslate}} </label>
                      </div>

                      <div class="col-md-7">
                        <ps-auto-suggestion (onSelected)="selectedStage($event)" [data]="processStages"
                          display="order_name" placeholder="Choose Process->Stage"
                          [preSelected]="{order_name:selectedOrderName}">
                        </ps-auto-suggestion>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <div class="row zone_dashboard" *ngIf="zones.length ">
                    <div class="col-md-3" *ngFor="let zone of zones; let i = index"
                      (click)="openParkingDashboard(zone.zone_id)">
                      <div class="zone" [class]="'zone-'+((i)%4)">
                        <div class="zone_title">
                          <h5>{{ zone.zone_name }}</h5>
                        </div>

                        <div class="all_slot">
                          <div class="slot_info">
                            <p>{{'total'|customTranslate}}</p>
                            <h6> {{ zone.total_slots_count }} </h6>
                          </div>
                          <div class="slot_info">
                            <p>{{'available'|customTranslate}}</p>
                            <h6>{{ zone.total_slots_count - zone.maintenance_slots_count - zone.occupied_slots_count }}
                            </h6>
                          </div>
                          <div class="slot_info">
                            <p>{{'maintainence'|customTranslate}}</p>
                            <h6>{{ zone.maintenance_slots_count }}</h6>
                          </div>
                          <div class="slot_info">
                            <p>{{'occupied'|customTranslate}}</p>
                            <h6>{{ zone.occupied_slots_count }}</h6>
                          </div>
                          <div class="slot_info">
                            <p>{{'zone_slot_limit'|customTranslate}}</p>
                            <h6>{{ zone.slot_limit }}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row zone_dashboard" [class.active]="true" *ngIf="!zones.length && !common.loading">
                    <app-data-not-found></app-data-not-found>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{('vehicle'|translate)+' '+('report'|translate)}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="page_design">
                <div class="row tab_sec m-0">
                  <div class="col-md-12 col-12 date_div p-0">
                    <div class="row">
                      <div class="col-md-3 date" *ngIf="role.user_role=='COMPANYADMIN'|| role.user_role == 'AXESTRACK'">
                        <label for="my-input" class="required">{{"select_plant" | translate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                          placeholder="Choose Plant">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 date">
                        <label>{{'start_date:'|translate}}</label>
                        <input type="date" class="form-control" [(ngModel)]="startDate">
                      </div>
                      <div class="col-md-3 date">
                        <label>{{'end_date:'|translate}}</label>
                        <input type="date" class="form-control" [min]="startDate" [(ngModel)]="endDate">
                      </div>
                      <div class="col-md-1 btn-block text-right">
                        <button class="btn btn-primary" (click)="getVehicleReport()">{{"search" | translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="view_table">
                  <div class="col-md-3 date">
                    <label>{{'select_process:'|translate}}</label>
                    <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="listOfProcess"
                      display="processName" placeholder="Select Process" [preSelected]="{processName:processName}">
                    </ps-auto-suggestion>
                  </div>


                  <div class="table_sec">
                    <table class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>Txn Id</th>
                          <th>Vehicle No</th>
                          <th>Trip Date</th>
                          <!-- <th>Trip End Date</th> -->
                          <th>Trip Status</th>
                          <ng-container *ngFor="let item of headers">
                            <th>{{item.stage_name}} <small style="font-size: 10px;">(Actual / Expected) TAT</small></th>
                          </ng-container>
                          <!-- <th>Trip Stage TAT <small style="font-size: 10px;">(Actual / Expected) TAT</small></th> -->
                          <th>Trip TAT <small style="font-size: 10px;">(Stage TAT + Inbetween TAT = Total TAT)</small>
                          </th>
                          <!-- <th>Trip Inbetween TAT</th>
                          <th>Trip Total TAT</th> -->

                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let data of vehicleReportData, let i = index">
                          <td>{{data.txn_id}}</td>
                          <td>{{data.vehicle_no}}</td>
                          <td>{{data.txn_start_date| date:'dd-MM-yyyy'}}</td>
                          <td><span class="pill"
                            [ngClass]="{'bg-green-200 text-green-800':data.stage_workflow_status=='COMPLETED','bg-yellow-200 text-yellow-800':data.stage_workflow_status=='INPROGRESS','bg-pink-200 text-pink-800':(data.stage_workflow_status=='CANCELLED'),'bg-orange-200 text-orange-800':(data.stage_workflow_status=='AUTO CANCELLED')}">
                            {{data.stage_workflow_status}}
                            </span></td>
                          <ng-container *ngFor="let item of data.stage_data">
                            <td><span
                                [ngClass]="item.actual_tat > item.expected_tat ? 'badge over' : 'badge'">{{item.actual_tat}}</span>
                              /
                              <span class="badge info">{{item.expected_tat}}</span>
                              <!-- <i *ngIf="item.tat > item.expected_tat" class="fa fa-info-circle"
                                (click)="openModal(data.date,item,item.expected_tat)"
                                style="margin-left: 5px;color: #2d2d81;font-size: 15px"></i> -->
                            </td>
                          </ng-container>
                          <td> <span class="badge">{{tripStageTatList[i]}}</span>+<span
                              class="badge">{{totalInbetweenTatList[i]}}</span> = <span
                              class="badge">{{data.trip_total_tat}}</span></td>
                          <!-- / <span class="badge info">{{expected_tat}}</span></td> -->
                          <!-- <td><span
                              [ngClass]="totalInbetweenTatList[i] > expected_tat ? 'badge over' :  'badge'">{{totalInbetweenTatList[i]}}</span></td> -->
                          <!-- / <span class="badge info">{{expected_tat}}</span></td> -->
                          <!-- <td><span
                              [ngClass]="data.trip_total_tat > expected_tat ? 'badge over' :  'badge'">{{data.trip_total_tat}}</span></td> -->
                          <!-- / <span class="badge info">{{expected_tat}}</span></td> -->
                        </tr>
                      </tbody>

                      <!-- <tfoot>
                        <tr>
                          <td>Total Stage TAT</td>
                          <td></td>
                          <ng-container *ngFor="let item of headers, let i = index">
                            <td><span
                                [ngClass]="totalStageWiseTatList[i] > item.expected_tat ? 'badge over' : 'badge under'">{{totalStageWiseTatList[i]}}</span>
                              / <span class="badge info">{{item.expected_tat}}</span></td>
                          </ng-container>
                          <td><span
                              [ngClass]="sumOfTotalStageWiseTat > expected_tat ? 'badge over' : 'badge under'">{{sumOfTotalStageWiseTat}}</span>
                            / <span class="badge info">{{expected_tat}}</span></td>
                        </tr>
                      </tfoot> -->
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


import { UserService } from '../../@core/services/user.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { TableService } from '../../@core/services/table.service';
import { ProcessStageService } from './process-stage.service';
import { CommonService } from '../../@core/services/common.service';
import { ProcessService } from './../process/process.service';
import { DataTableDirective } from 'angular-datatables';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NextStageComponent } from 'src/app/modals/next-stage/next-stage.component';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { ActivatedRoute } from '@angular/router';
import { InBetweenVehiclesComponent } from './in-between-vehicles/in-between-vehicles.component';
import { UserTrackingInfoModalComponent } from 'src/app/modals/user-tracking-info-modal/user-tracking-info-modal.component';
import { TripCommentsComponent } from 'src/app/modals/trip-comment-trail/trip-comments/trip-comments.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-process-stage',
  templateUrl: './process-stage.component.html',
  styleUrls: ['./process-stage.component.scss'],
})
export class ProcessStageComponent implements OnInit, AfterViewInit {
  @Input() entityType:string = 'trip'

  buttonView:Record<string,string>=
    {
      '0Kanban View':"kanban_view__access",
      '1Table View':"table_view__access",
      '2Trips View':"trip_view_access",
      '3Map View':"map_view__access",
      '4Plant View':"plant_view_access",
      '5Hardware View':"plant_hardware_view_access",
      '6Loader View':"loader_view_access"};
  stagecolors = [
    '#FF471F',
    '#085100',
    '#0D0039',
    '#DC7800',
    '#0073DB',
    '#AA00DB',
    '#DA00C2',
    '#01BA76',
  ];
  viewPage: string = 'Kanban View';

  @ViewChild(DataTableDirective, { static: false })
  dtTrigger: any = new Subject();
  dtTrigger1: any = new Subject();
  dtOptions: any = this.table.options(10, 5);
  dtElement: any;
  stageoption: any = this.table.options(10, 5);

  activeButton: string = 'Kanban View';
  vehicledata: any = [];
  processelement: any;
  currentIndex: number = -1;
  Index: number = 0;
  checked: boolean = false;
  updateEnabled: boolean = false;
  processData: any[] = [];
  ProcessResponse: any = [];
  runningworkflow: any = [];
  // vehicleData = require('./vehicle_api.json');
  vehicleData = [];
  vehicles: any[] = [];
  stages: any = [];
  currentProcess: any;
  currentProcessIndex: number = 0;
  vehicleFilter: any;
  allPlantData: any;
  allCompanies: any;
  company: any = {
    id: null,
    name: null,
  };
  plantData: any[] = [];
  plant: any = {
    id: null,
    name: null,
  };
  isSuperAdmin: boolean;
  tripData: any;
  vehicleFlowData: any[] = [];
  allVehicles: any;
  tatViewEnabled: boolean;
  @ViewChild('stageBox') stageBox: ElementRef;
  stageBoxWidth: any;
  tabIndex: number = 0;
  pageTrigger = new Subject();
  txnDetails: any = [];
  closeResult: string = '';
  stageTrigger = new Subject();
  workFlowStatus: any[] = [];
  hideStages: any[] = [];
  tempVehicleFlowData: any[] = [];
  lastSelectedNextStage: number = null;
  cardVehicles: any[] = [];

  // @Output() plantId:EventEmitter<any>=new EventEmitter();


  processList:any[]=[];
  processId:any;
  processName:any;
  allVehiclesOfProcess:any[]=[];
  plantList:any[]=[];
  selectedPlant:any;
  showTabs:boolean = true;
  updatedViewArray:any[]=[];
  // ellipseIndex: string = '';

  @Output() plantSelected: EventEmitter<number> = new EventEmitter<number>();
  triggerTripsView: boolean=false;



  // show on the way on toggle
  showOnTheWay:boolean=false;

  ngOnInit(): void {
    console.log("This is entityType", this.entityType)
    this.role.user_role == 'COMPANYADMIN'
    ? this.getPlantById()
    : this.getAllProcess();
    if(this.route.snapshot.queryParams.tab != null){
      this.showTabs = false;
      this.getButtonIndex(this.route.snapshot.queryParams.tab+" View");
    }

    let breadcrumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: this.entityType === 'trip' ? 'Trips' : 'Visitor', url: null },
      this.entityType === 'trip'
        ? { name: 'Active Trips', link: '/user/active-trips' }
        : { name: 'Active Visits', link: '/user/active-visits' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcrumbs);
  }

  ngAfterViewInit() {
    if (this.viewPage == 'Kanban View') {
      this.triggerStageBoxSize();
    }
  }

  triggerStageBoxSize() {
    this.stageTrigger.subscribe(() => {
      if (this.stageBox?.nativeElement) {
        this.stageBoxWidth =
          (this.stageBox.nativeElement.clientWidth - 6 * 10) / 7.5;
      }
    });
  }

  ngOnDestroy(): void {
    // this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  renderprocessTable() {
    this.processelement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger1.next();
    });
  }

  constructor(
    public dialog: MatDialog,
    public breadcrumbService: BreadcrumbService,
    public process: ProcessService,
    public common: CommonService,
    public stage: ProcessStageService,
    public table: TableService,
    public api: ApiService,
    public user: UserService,
    private modalService: NgbModal,
    public plantService: PlantService,
    public role: RoleService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private alertService:AlertService
  ) {
    /** breadcrumb implementation start*/

    if (this.common.dashboardparam != '') {
      this.viewPage =
        this.common.dashboardparam == 'Active Trips'
          ? 'Kanban View'
          : this.common.dashboardparam;
    }
    this.isSuperAdmin = !!(this.user._loggedInUser.companyId == 1);
    // this.role.user_role == 'COMPANYADMIN'
    //   ? this.getPlantById()
    //   : this.getAllProcess();
    //   if(this.route.snapshot.queryParams.tab != null){
    //     this.showTabs = false;
    //     this.getButtonIndex(this.route.snapshot.queryParams.tab+" View");
    //   }
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantList = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  onSelectPlant(e: any) {
    this.selectedPlant = e.value;
    console.log("in trip",this.selectedPlant);
    localStorage.setItem('selectedPlantId', this.selectedPlant);
    this.getAllProcess();
  }

  getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.selectedPlant,
      processType: this.entityType=='visit'?'Visit':null
    };
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.processList = res.data;
          this.processId = this.processList[0].processId;
          this.processName = this.processList[0].processName;
          this.getRunningWorkflow();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  onProcessChange(event: any) {
    this.processId = this.processList[event.index].processId;
    this.processName = this.processList[event.index].processName;
    this.getRunningWorkflow();
    this.stageTrigger.next(true);
    this.triggerStageBoxSize();
  }

  inBetweenTat(stage: any): string {
    // const inBetweenTat = '00:00';
    const previousStage = this.updatedViewArray.length > 0
    ? this.updatedViewArray
    .filter(element => element.stage_ranking_index < stage.stage_ranking_index)
    .reduce((prev, curr) => {
      return  prev!=null ? (prev.stage_ranking_index > curr.stage_ranking_index ? prev : curr) : curr;
    }, null) : null

    const inBetweenTat = previousStage!=null?
    (previousStage.next_stages.find((el) => stage.stage_name === el.stage_name)
    ?.inbetween_tat || '00:00') : '00:00';
    return inBetweenTat;
  }

  getRunningWorkflow() {
    this.common.loading = true;
    let params={
      processId : this.processId,
      entityType: this.entityType
    }
    this.api.get('trip/get-running-workflow-status',params).subscribe((res: any) => {
      this.common.loading = false;
      if(res.status){
        this.vehicleFlowData = res.data.map(item => ({ ...item, next_stages: JSON.parse(item.next_stages), trips: JSON.parse(item.trips),inEntries:JSON.parse(item.trips).filter((a)=>a.time_in!=null).length })) || [];
        console.log('this.vehicleFlowData: ', this.vehicleFlowData);
        this.updatedViewArray = _.cloneDeep(this.vehicleFlowData);
        this.allVehiclesOfProcess = this.vehicleFlowData.reduce((mergedTrips, element) => {
          return mergedTrips.concat(element.trips.map(trip => ({ ...trip, stage_name: element.stage_name })));
        }, []);
        this.triggerTripsView=this.viewPage=="Trips View"?true:false
        this.cdRef.detectChanges();
      }});
  }

  switchStage(a) { //a is the index of stage from which flow is changing
    let activeModal = this.modalService.open(NextStageComponent, { ariaLabelledBy: 'modal-basic-title' });
    activeModal.componentInstance.refData = {
      next_stages: this.updatedViewArray[a].next_stages,
      active_next_stage_id:this.updatedViewArray[a].selectedNextStageId == null ? this.updatedViewArray[a+1].stage_id : this.updatedViewArray[a].selectedNextStageId,
    };
    activeModal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      //if next stage is selected
      if (result.response == true) {
        this.changeStageWorkFlow(result.nextStageId, a);
      }
    });
  }


  changeStageWorkFlow(nextStageId:any,index:any){
    this.updatedViewArray[index].selectedNextStageId = nextStageId;

    let arr =[];
    for (let i = 0; i < this.vehicleFlowData.length; i++) {
      if(nextStageId == this.vehicleFlowData[i].stage_id){
        arr = this.addRemainingElements(i);
        break ;
      }
    }
    this.updatedViewArray = this.updatedViewArray.slice(0, index + 1);
    this.updatedViewArray.push(...arr)

    // updating trips in updatedViewArray by checking every trip's previous_stage_id
    for (let i = 1; i < this.updatedViewArray.length; i++) {
        if(this.updatedViewArray[i].trips.length){
          this.updatedViewArray[i].trips.forEach(element => {
            if (element.previous_stage_id !== this.updatedViewArray[i - 1].stage_id) {
              this.updatedViewArray[i].trips.splice(this.updatedViewArray[i].trips.indexOf(element), 1);
            }
        });
        }
      }
  }

  addRemainingElements(index){
    let arr = []
    let tempArr = _.cloneDeep(this.vehicleFlowData);
    for (let i = index; i < tempArr.length; i++) {
      arr.push(tempArr[i]);
    }
    return arr;
  }


  getButtonIndex(value:string) {
    //Todo TimeOut
    let tabLabels: any = document.querySelectorAll('.mat-tab-labels');
    this.viewPage = value;
    setTimeout(() => {
      if (tabLabels?.length) {
        tabLabels[0].style.display =
        this.viewPage == 'Map View' ? 'none' : 'block';
      }
    }, 100);
    this.activeButton = value;
    this.updateEnabled = false;
    const breadcrumbShow = [
      'Kanban View',
      'Table View',
      'Trips View',
      'Map View',
    ].includes(this.viewPage);
    this.breadcrumbService.setActiveBreadCrumb(!!breadcrumbShow);

    if (this.viewPage == 'Map View') {
      this.pageTrigger.next(true);
    }

    if (this.viewPage == 'Kanban View' && this.stageBox) {
      //Todo TimeOut
      setTimeout(() => {
        this.stageBoxWidth =
        (this.stageBox.nativeElement.clientWidth - 6 * 10) / 7;
      }, 500);
    }

    if(this.viewPage!='Trips View'){
      this.triggerTripsView=false;
    }
  }

  sizeHandler(index: number) {
    let className;
    let size: any = index + 1;
    let indexlen: number = size.toString().length;
    className = indexlen > 1 ? (indexlen > 2 ? 'lg' : 'md') : 'sm';
    return className;
  }

  ViewDetail(index: number, row: any) {
    let data = this.vehicles[index];
    this.stage.tripviewbase = 'table';
    localStorage.setItem('trip', JSON.stringify(data));
    this.common.tripview(row);
    localStorage.setItem('tripViewbase', 'table');
    localStorage.setItem('tripId', row.trip_id);
  }

   isOverTat(tat1: any, tat2: any): boolean {
    let minutes1 = tat1 != null ? tat1.split(':') : ['00', '00'];
    let minutes2 = tat2 != null ? tat2.split(':') : ['00', '00'];
    let totalTat1 = parseInt(minutes1[0]) * 60 + parseInt(minutes1[1]);
    let totalTat2 = parseInt(minutes2[0]) * 60 + parseInt(minutes2[1]);
    return totalTat1 > totalTat2;
  }

  getOnTheWayTrips(trips: any) {
    return trips.filter((t) => t.time_in == null);
  }

  tripModalKanbanView(vehicle) {
    console.log(vehicle)
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    console.log("This is Process name for",this.entityType,this.processName)

    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.trip_id,
      processName: this.processName,
      txnType: this.entityType=='visit'?'Visit':null
    };
  }

  tripTrackingModalKanbanView(vehicle) {
    console.log(vehicle)
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.trip_id,
      vehicleName: vehicle.vehicle_rc_no + '-' + this.processName,
      personIcon:this.entityType=='visit'?true:false
    };
  }

  pathTrackingModal(staffId: number) {
    this.common.params = {
      userId: staffId,
      userType: 'STAFF',
    };
    const activeModal = this.modalService.open(UserTrackingInfoModalComponent, {
      size: 'xl',
    });
  }

  tripCommentTrail(txnId: number) {
    let activeModal = this.modalService.open(TripCommentsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
    activeModal.componentInstance.tripId = txnId;
    activeModal.componentInstance.entityType = 'Visit';
    activeModal.result.then((res) => {
      if (res.response) {
        this.alertService.success(res.response);
      }
    });
  }
  openModal(data:any,inBetweenTat:any){
    let activeModal = this.modalService.open(InBetweenVehiclesComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = data;
    activeModal.componentInstance.extraData = {process:this.processName,inBetweenTat:inBetweenTat,entityType:this.entityType};
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">

              <div class="card-body body_div">
                <div *ngIf="role.user_role=='COMPANYADMIN'" class="form-group col-md-3">
                  <label for="my-input" class="required">{{"plant" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                </div>
                <div class="tab_content">
                  <div class="cards_dash" #alerts>
                    <div class="card" [ngStyle]="{ 'background-color': cards.cardBgColor }"
                      *ngFor="let cards of tabCardData; index as i" (click)="getContentCardData(i); activeIndex = i;"
                      [class.active]="activeIndex == i" id="alert-card">
                      <div class="content_part" [class.active]="activeIndex == i">
                        <p>{{ cards.title }} </p>
                        <h4>{{ cards.value }} </h4>
                      </div>
                      <div class="icon_part">
                        <div class="gif_icon" [ngStyle]="{ 'background-color': cards.iconBgColor }"
                          (ngModel)="activeColor=cards.iconBgColor">
                          <img src="assets/images/alert-gif.gif" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="cards_row">
                    <div class="sub_tab">
                      <div class="card"
                        *ngFor="let data of ParentcontentData[tabCardData[activeIndex]?.title] | keyvalue ; index as i"
                        (click)="getChildContentCardData(data.key); activeCardIndex = i;"
                        [class.active]="activeCardIndex == i" [ngStyle]="{'color':darkColor}">
                        <p style="color:black">{{ data.key }}</p>
                        <h3 [ngStyle]="{ 'color': darkColor }">{{getArrayLenght(data.value)}}</h3>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table-responsive-alert">
                        <tr class="table-alert" style="text-align: center;">
                          <th>{{"S.No." | translate}}</th>
                          <th>{{"alert_name" | translate}}</th>
                          <th>{{"Alert On" | translate}}</th>
                          <th>{{("Alert Condition" | translate)}}</th>
                          <th>{{("Violation" | translate)}}</th>
                          <th>{{"Status" | translate}}</th>
                          <th>{{'Created At'|translate}}</th>
                          <th> Info</th>
                        </tr>
                        <tbody class="table-alert-list">
                          <tr *ngFor="let contents of contentData;index as i" style="text-align: center;">
                            <td>{{i+1}}</td>
                            <td> {{contents.content_title_alert}}</td>
                            <td appEllipsisView> {{contents.content_title}}</td>
                            <td style="text-transform: capitalize;" appEllipsisView>{{contents.content}}</td>
                            <td>
                              <span class="pill" style="background-color: #fac9c9;color: #a31515;" appEllipsisView>
                                {{['Stage','Process','Destination'].includes(contents.content_entity)  ? contents.content_range : contents.content}}</span>
                            </td>
                            <td><span class="pill"
                                [ngClass]="{'bg-green-200 text-green-800':contents.content_status=='ACTIVE','bg-yellow-200 text-yellow-800':contents.content_status=='INPROGRESS'}">
                                {{contents.content_status}}
                              </span></td>
                            <td>{{contents.content_date}}</td>
                            <td>
                              <img class="icon img-icon" src="assets/images/trip-details(3).png" alt=""
                                (click)="viewAlert(contents.content_data_view)">
                              <img
                                *ngIf="contents.content_entity == 'Stage' || contents.content_entity == 'Process' || contents.content_entity == 'Destination'"
                                class="icon img-icon" src="assets/images/trip-details(3).png" alt=""
                                (click)="viewPlantLiveView(contents.content_process)">
                              <!-- <img
                                *ngIf="contents.content_entity == 'Vehicle' || contents.content_entity == 'Transaction'"
                                class="icon img-icon" src="assets/images/trip-tracking(3).png" alt=""
                                (click)="tripTrackingModal(contents.content_data)"> -->
                              <!-- <img
                                *ngIf="contents.content_entity == 'Vehicle' || contents.content_entity == 'Action' || contents.content_entity == 'Driver'"
                                class="icon img-icon" src="assets/images/trip-details(3).png" alt=""
                                (click)="tripModal(contents.content_data)"> -->
                              <img *ngIf="contents.content_entity == 'Audit'" class="icon img-icon"
                                src="assets/images/trip-details(3).png" alt=""
                                (click)="viewDetails(contents.content_data)">
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bar_chart card">
                <div class="title">
                  {{ "alerts_by_alert_condition" | translate }}
                </div>
                <div class="choose_dropdown" style="margin-bottom: 5px;">
                  <select (change)="setSelectedEntity(selectedEntity)" [(ngModel)]="selectedEntity">
                    <option *ngFor="let item of groupedDataByEntity | keyvalue" [value]="item.key">{{ item.key }}
                    </option>
                  </select>
                </div>
                <div class="card-body" id="divChart_bar"><canvas [id]="'barChart-' + selectedEntity" baseChart
                    width="1000" height="250"></canvas></div>
              </div>

              <div class="graph_tab_sec">
                <div class="title">{{ "alert_on entities" | translate }}</div>
                <div class="graph_sec">
                  <div class="garph_div" *ngFor="
                      let chart of groupedDataByEntity | keyvalue;
                      index as i
                    " [class.active]="tabvalue === chart.key" (click)="setTabValue(chart.key)">
                    <div id="divChart_doughnut" class="chart-div">
                      <canvas [id]="'chart-' + chart.key" baseChart></canvas>
                    </div>
                    <p>{{ chart.key }}</p>
                    <div class="position_number">
                      {{ ("total" | translate) + " " + ("alert" | translate)
                      }}<br /><span>{{ getObjectLength(chart.value) }}</span>
                    </div>
                  </div>
                </div>

                <div class="graph_content_sec">
                  <table class="table-responsive-alert">
                    <tr class="table-alert">
                      <th>{{ "s.no." | translate }}</th>
                      <th *ngFor="let header of entityData['headers']">
                        {{ header.title }}
                      </th>
                    </tr>
                    <tbody class="table-alert-list">
                      <ng-container *ngFor="let value of entityData['values']; index as i">
                        <tr>
                          <td style="min-width: 100px">
                            <i class="fa fa-chevron-right" *ngIf="!value.isExpand" (click)="
                              value.isExpand = true;
                              createExpandTableData(value)
                            "></i>
                            <i class="fa fa-chevron-down" *ngIf="value.isExpand" (click)="value.isExpand = false"></i>
                            &nbsp;&nbsp;&nbsp;
                            {{ i + 1 }}
                          </td>
                          <ng-container *ngFor="let header of entityData['headers']">
                            <td *ngIf="isArray(value[header.title])">
                              <span class="pill"
                                [ngStyle]="{'font-size' :'14px','background-color':header.lightColor,'color': header.darkColor}">{{ getObjectLength(value[header.title]) }}</span>
                            </td>
                            <td *ngIf="!isArray(value[header.title])">
                              {{ value[header.title] }}
                            </td>
                          </ng-container>
                        </tr>
                        <tr *ngIf="value.isExpand">
                          <td [attr.colspan]="entityData['headers'].length + 1">
                            <div style="display: flex">
                              <div class="condtion-card" *ngFor="
                                let element of filteredValueByCondition
                                  | keyvalue
                              ">
                                <div class="condition-header"
                                  [ngStyle]="{'background-color': getColor(element.key,'cardBgColor'),'color': getColor(element.key,'iconBgColor')}">
                                  {{ element.key }}
                                </div>
                                <div class="content">
                                  <div class="type-row-div" *ngIf="isObjectEmpty(element.value)" style="
                                    color: red;
                                    display: flex;
                                    justify-content: center;
                                  ">
                                    No Alerts Found !!
                                  </div>
                                  <ng-container *ngIf="!isObjectEmpty(element.value)">
                                    <div class="type-row-div" *ngFor="
                                      let item of element.value | keyvalue
                                    ">
                                      <span>{{ item.key }}</span>
                                      <div>

                                        <span class="pill"
                                          [ngStyle]="{'font-size':'12px','background-color': getColor(element.key,'cardBgColor'),'color': getColor(element.key,'iconBgColor')}">
                                          {{
                                            getObjectLength(item.value)
                                          }}

                                        </span>
                                        <i class="fa fa-info" style="cursor: pointer;padding: 6px;font-size: 14px;"
                                          aria-hidden="true" (click)=" getAlertCardDetails(item.key,item.value)"></i>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="line_chart">
                <div class="title">
                  {{ "hourly_alerts_report" | translate }}
                </div>
                <div class="right-side">
                  <select (change)="setSelectedTime(selectedTime)" [(ngModel)]="selectedTime">
                    <option [value]=7> Last 7 hours </option>
                    <option [value]=24> Last 24 hours </option>
                  </select>
                </div>
                <div class="garph_div">
                  <div id="tab_divChart_line">
                    <canvas [id]="'tabLineChart'" baseChart width="1000" height="300"></canvas>
                  </div>
                </div>
              </div>

              <div class="line_chart">
                <div class="title">
                  {{ "daywise_alerts_report" | translate }}
                </div>
                <div class="right-side">
                  <select (change)="setSelectedDay(selectedDay)" [(ngModel)]="selectedDay">
                    <option [value]=7> Last 7 days </option>
                    <option [value]=24> Last 30 days </option>
                  </select>
                </div>
                <div id="divChart_line"><canvas id="myLineChart" baseChart width="1000" height="300"></canvas></div>
              </div>

              <div class="sticky_div" *ngIf="showStickyDiv">
                <div class="row_div">
                  <div class="column_div" [ngStyle]="{
                      'background-color': cards.cardBgColor,
                      'mix-blend-mode': 'lighten'
                    }" *ngFor="let cards of tabCardData; index as i"
                    (click)="scrollToAlert();getContentCardData(i); activeIndex = i;">
                    <p>{{ cards.title }}</p>
                    <h4 [ngStyle]="{ 'background-color': cards.iconBgColor }">
                      {{ cards.value }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

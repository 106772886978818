import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { CommonService } from '../../@core/services/common.service';
import { TableService } from '../../@core/services/table.service';
import { UserService } from '../../@core/services/user.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { ComponenttService } from './componentt.service';

@Component({
  selector: 'app-componentt',
  templateUrl: './componentt.component.html',
  styleUrls: ['./componentt.component.scss'],
})
export class ComponenttComponent implements OnInit {
  component = {
    componentId: '',
    componentName: '',
    parentComponentId: '',
    componentType: 'SUB_MENU',
    componentStatus: 'ACTIVE',
    componentApiUrl: '',
    componentCode: '',
    platform: 'WEB',
  };

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'Hardware LIST');
  webPlatformApiUrl: string;
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getComponent(this.component.componentType);
    this.getAllComponent('all');
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  componentName: null;
  title = 'Add Component';
  componentData: any[] = [];
  allComponentData: any[] = [];
  btn = 'save';

  constructor(
    private componentService: ComponenttService,
    private modalService: NgbModal,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public alert: AlertService,
    public table: TableService,
    public userService: UserService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Component', url: null },
      { name: 'Manage Component', url: '/user/component' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngViewInit() {}

  onComponentTypeChange(event: any) {
    this.component.componentType = event.target.value;
    this.getComponent(this.component.componentType);
  }

  selectedParentComponent(event: any) {
    this.component.parentComponentId = event.componentId;
  }

  apiUrlChange() {
    if (this.component.platform == 'WEB') {
      this.webPlatformApiUrl = this.component.componentApiUrl;
    }
  }

  setComponentApiUrl() {
    if (this.component.platform == 'APP') {
      this.component.componentApiUrl = this.component.componentName
        ? `www.${this.component.componentName}.com`
        : '';
    } else {
      this.component.componentApiUrl = this.webPlatformApiUrl;
    }
  }

  setComponentCode() {
    this.setComponentApiUrl();
    if (this.component.platform == 'WEB') {
      this.component.componentCode = this.acronymGenerator(
        this.component.componentName
      );
    } else {
      this.component.componentCode = this.component.componentName
        ? this.acronymGenerator(this.component.componentName + '_app')
        : '';
    }
  }

  acronymGenerator(text: any) {
    // text = text+'';
    var words, acronym, nextWord;
    text = text.toLowerCase();
    words = text.split(' ');
    acronym = '';
    let index = 0;
    while (index < words.length) {
      nextWord = words[index];
      if (index == words.length - 1) {
        acronym = acronym + nextWord;
      } else {
        acronym = acronym + nextWord + '_';
      }
      index = index + 1;
    }
    return acronym;
  }

  getAllComponent(componentType: any) {
    this.common.loading = true;
    let params = {
      componentType: componentType,
    };
    this.componentService.getComponent(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.renderTable();
          this.allComponentData = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  getComponent(componentType: any) {
    this.common.loading = true;
    let params = {
      componentType: componentType,
    };
    this.componentService.getComponent(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.renderTable();
          this.componentData = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  saveComponent(): void {
    this.common.loading = true;
    let params = {
      componentId: this.component.componentId,
      componentName: this.component.componentName,
      parentComponentId: this.component.parentComponentId,
      componentType: this.component.componentType,
      componentStatus: this.component.componentStatus,
      componentCode: this.component.componentCode,
      componentApiUrl: this.component.componentApiUrl,
      platform: this.component.platform,
    };

    this.componentService.saveComponent(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alert.success(res.message);
          this.resetDetail();
          this.getAllComponent('all');
          this.getComponent(this.component.componentType);
        } else {
          this.alert.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  resetDetail() {
    this.component = {
      componentId: '',
      componentName: '',
      parentComponentId: '',
      componentType: 'SUB_MENU',
      componentStatus: 'ACTIVE',
      componentCode: '',
      componentApiUrl: '',
      platform: 'web',
    };
    this.btn = 'save';
    this.title = 'Add Component';
    this.componentName = null;
    this.componentNameValidator = null;
    this.parentValidator = null;
    this.componentCodeValidator = null;
  }

  setActiveComponent(item?: any) {
    this.common.params = { details: [item], title: 'Component Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  openConfirmAlert(item?: any) {
    this.common.params = {
      title: 'Delete Component',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteComponent(item);
      }
    });
  }

  deleteComponent(item?: any) {
    this.common.loading = true;
    let params = {
      componentId: item.componentId,
      componentName: item.componentName,
      parentComponentId: item.parentComponentId,
      componentStatus: 'DELETED',
      componentType: item.componentType,
      componentCode: item.componentCode,
      componentApiUrl: item.componentApiUrl,
      platform: item.platformType,
    };

    this.componentService.saveComponent(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alert.info(res.message);
        if (res.status) {
          this.getAllComponent('all');
        }
      },
      (error: any) => {}
    );
  }

  editComponent(item?: any) {
    this.common.gotoTop();
    this.component.componentId = item.componentId;
    this.component.componentName = item.componentName;
    this.component.parentComponentId = item.parentComponentId;
    this.component.componentStatus = item.componentStatus;
    this.component.componentType = item.componentType;
    this.component.componentCode = item.componentCode;
    this.component.componentApiUrl = item.componentApiUrl;
    this.component.platform = item.platformType;
    this.btn = 'update';
    this.title = 'Edit Component Details';
    this.componentName = item.parentComponentName;
  }

  componentNameValidator: any;
  parentValidator: any;
  componentCodeValidator: any;
  formValidator() {
    this.componentNameValidator = this.common.nullValidator(
      this.component.componentName,
      'Component Name Mandatory'
    );
    this.parentValidator = this.common.nullValidator(
      this.component.parentComponentId,
      'Parent Component Mandatory'
    );
    this.componentCodeValidator = this.common.nullValidator(
      this.component.componentCode,
      'Component Code Mandatory'
    );
    if (
      !this.componentNameValidator.error &&
      !this.parentValidator.error &&
      !this.componentCodeValidator.error
    ) {
      this.saveComponent();
    }
  }

  ngOnInit(): void {}
}

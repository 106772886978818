import { CommonService } from '../../../@core/services/common.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-screen-loader',
  templateUrl: './screen-loader.component.html',
  styleUrls: ['./screen-loader.component.scss'],
})
export class ScreenLoaderComponent implements OnInit {
  constructor(
    public common: CommonService,
    public spinner: NgxSpinnerService
  ) {}

  getloadingstatus() {
    this.common.loading ? this.spinner.show() : this.spinner.hide();
  }

  ngOnInit(): void {
    this.getloadingstatus();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ChecklistDataList, ChecklistUserMapping } from 'src/app/dataType/checklistInterface';
import { UserList } from 'src/app/dataType/userInterface';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-user-checklist-mapping',
  templateUrl: './user-checklist-mapping.component.html',
  styleUrls: ['./user-checklist-mapping.component.scss'],
})
export class UserChecklistMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'User Checklist Mapping');

  selectedData: any = {
    user: [],
    userId: [],
    checklist: [],
    checklistId: [],
  };
  isMultiSelect:boolean = true;
  user: UserList[] = [];
  status: string = 'true';
  userName:string= '';
  buttonName:string = 'save';
  checklistData: ChecklistDataList[] = [];
  tableData: ChecklistUserMapping[] = [];

  constructor(
    public userService: UserService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public alert: AlertService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Checklist', url: null },
      { name: 'User Checklist Mapping', link: '/user/user-checklist-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit() {
    this.getAllChecklists();
    this.getUser();
    this.getUserChecklistMappingData();
    this.status = 'true';
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }


  selectedCheckList(event: any) {
    let selectedData: any = [];
    let data: any = [];
    event.forEach((element) => {
      data.push(element);
      selectedData.push(element.checkListId);
    });
    this.selectedData.checklist = data;
    this.selectedData.checklistId = selectedData;
  }

  selectedUser(event: any) {
    let selectedUser: any = [];
    let data: any = [];
    event.forEach((element) => {
      selectedUser.push(element.userId);
      data.push(element);
    });
    this.selectedData.user = data;
    this.selectedData.userId = selectedUser;
  }

  viewUserActionMapping(row?: any) {
    let obj = {
      userName: row.user.userName,
      checklist: this.getChecklistString(row.checklist),
    };
    this.common.params = { details: [obj], title: 'View Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  editUserActionMapping(row?: any) {
  console.log('row: ', row);
    this.reset();
    this.buttonName = 'Update';
    this.common.gotoTop();
    // this.selectedData.user = [row.user];
    this.selectedData.user = [row.user].map((user) => ({
      name: user.userName,
      userId: user.userId,
    }));
    this.selectedData.checklist = row.checklist;
    this.selectedData.userId = [row.user].map((item) => item.userId);
    this.selectedData.checklistId = row.checklist.map(
      (item) => item.checkListId
    );
  }

  saveUserAction(): void {
    let params = {
      userlist: this.selectedData.userId,
      checkList: this.selectedData.checklistId,
      type: this.buttonName,
    };
    this.api.post('checklist/save-user-checklist-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alert.info(res.message);
          this.common.loading = false;
          this.reset();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  deleteUserCheckList(userId: any) {
    let params = {
      deleteUserId: userId,
    };
    this.api.get('checklist/delete-user-checklist', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.reset();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Checklist Mapping ',
      description:
        `<b>&nbsp;` + 'Are you sure, Do you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteUserCheckList(row.user.userId);
      }
    });
  }

  reset() {
    this.status = 'true';
    this.buttonName = 'save';
    this.isMultiSelect = true;
    this.selectedData = {
      user: [],
      userId: [],
      checklist: [],
      checklistId: [],
    };
    this.getUserChecklistMappingData();
  }

  getAllChecklists() {
    this.common.loading = true;
    let params = {
      checklistActive : true
    }
    this.api.get('checklist/get-all-checklists',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.checklistData = res.data.map((item) => ({
            checkListTitle: item.checklist_title,
            checkListId: item.id,
          }));
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getUser() {
    this.common.loading = true;
    this.api.get('user/all_user').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.user = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getUserChecklistMappingData() {
    this.common.loading = true;
    this.api.get('checklist/get-user-checklist-mapping').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.tableData = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getChecklistString(data): string {
    let checklistTitles = data.map((item) => item.checkListTitle);
    return checklistTitles.join(', ');
  }

  statusValidator: any;
  checklistValidator: any;
  userValidator: any;
  formValidator() {
    this.checklistValidator = this.common.nullValidator(
      this.selectedData.checklist,
      'action mandatory'
    );
    this.userValidator = this.common.nullValidator(
      this.selectedData.user,
      'user mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.status,
      'status mandatory'
    );
    if (
      !this.checklistValidator.error &&
      !this.statusValidator.error &&
      !this.userValidator.error
    ) {
      this.saveUserAction();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-check-in-check-out-modal',
  templateUrl: './check-in-check-out-modal.component.html',
  styleUrls: ['./check-in-check-out-modal.component.scss']
})
export class CheckInCheckOutModalComponent implements OnInit {
  returnDate:any=new Date().toISOString().split('T')[0];
  returnDateValidator:any;
  remarks:any;
  minimumTimestamp:Date=new Date();
  selectedAssigneeId:any=null;
  selectedAssigneeName:any=null;
  assigneeValidator:any=null;
  assigneeType:any=null;
  dataList:any[]=[];
  constructor(public common : CommonService,public api:ApiService,public alert:AlertService,private activeModal: NgbActiveModal) {

    console.log("gyuhnjm",common.params.checkInFlag,common.params.userType)
  }

  updateRequestStatus(reqId: number, curStatus: any, assetList: any) {
    let param = {
      status: curStatus,
      requestId: reqId,
      assetList: assetList,
      remarks:this.remarks,
      returnDate:this.returnDate,
      checkoutBy:this.selectedAssigneeId,
      userType:this.assigneeType
    }
    this.api.post('asset_requests/update-request-status', param).subscribe(
      (res: any) => {
        this.alert.info("Request Status updated Successfully !!")
        this.activeModal.close();
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  ngOnInit(): void {
    if(!this.common.params.checkInFlag && this.common.params.userType && (this.common.params.userType=='Group' || this.common.params.userType=='Department')){
      this.getAssigneeList();
    }
  }

  closeModal(){
    this.activeModal.close();
  }

  formValidator(){
    this.returnDateValidator = this.common.nullValidator(
      this.returnDate,
      'Date Mandatory'
    );
    this.assigneeValidator = this.common.nullValidator(
      this.selectedAssigneeId,
      'User Mandatory'
    );
    if ((!this.returnDateValidator.error && this.common.params.checkInFlag)||(!this.assigneeValidator.error && !this.common.params.checkInFlag && (this.common.params.userType=='Group' || this.common.params.userType=='Department'))||(!this.common.params.checkInFlag && (this.common.params.userType!='Group' && this.common.params.userType!='Department'))) {
      this.updateRequestStatus(this.common.params.reqId,this.common.params.curStatus,this.common.params.assetList);
    }
  }

  getAssigneeList(){
    this.common.loading=true;
    let params={
      entityId:this.common.params.entityId,
      type:this.common.params.userType
    }
    this.api.get('asset_requests/get-all-users-for-department-group',params).subscribe(
      (res: any) => {
        this.common.loading=false;
        this.dataList=res.data;
      },
      (err: any) => {
        this.common.loading=false;
        console.error('Error: ', err);
      }
    );
  }

  onSelectAssignee(event:any){
    this.selectedAssigneeId=event.user_id;
    this.selectedAssigneeName=event.name;
    this.assigneeType = event.type;
  }

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TableService } from 'src/app/@core/services/table.service';
import { VehicleDetailComponent } from '../../vehicle-detail/vehicle-detail.component';
import { DriverTrackerComponent } from '../../driver-tracker/driver-tracker.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-in-between-vehicles',
  templateUrl: './in-between-vehicles.component.html',
  styleUrls: ['./in-between-vehicles.component.scss']
})
export class InBetweenVehiclesComponent implements OnInit {
  @Input() public refData;
  @Input() public extraData;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'In Between Vehciles');
  ngAfterViewInit() {
    this.dtTrigger.next();
    // this.getTripReport(0);
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(public table: TableService,private modalService: NgbModal) { }

  ngOnInit(): void {
  }
  tripModal(row) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: row.trip_id,
      processName: this.extraData.process,
      txnType: this.extraData.entityType=='visit'?'Visit':null
    };
  }

  tripTrackingModal(row) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: row.trip_id,
      vehicleName: row.vehicle_rc_no + '-' + this.extraData.process,
      personIcon:this.extraData.entityType=='visit'?true:false
    };
  }

}

import { SharedService } from './../shared.service';
import { AddDriverTransporterComponent } from 'src/app/components/add-driver-transporter/add-driver-transporter.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { AddVehicleComponent } from '../../modals/add-vehicle/add-vehicle.component';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { ActionFormComponent } from '../../modals/action-form/action-form.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ActionInfoModalComponent } from '../../modals/action-info-modal/action-info-modal.component';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { AssignProcessComponent } from 'src/app/modals/assign-process/assign-process.component';
import { DriverIvrCallComponent } from '../driver-ivr-call/driver-ivr-call.component';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { LocationService } from 'src/app/@core/services/location.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/@core/services/user.service';
import { EventSourceService } from 'src/app/@core/services/event-source.service';
import { AssignUserModalComponent } from '../assign-user-modal/assign-user-modal.component';
import { RemarkComponent } from 'src/app/modals/remark/remark.component';
import { AssignDriverTransporterComponent } from '../assign-driver-transporter/assign-driver-transporter.component';
import { AssignMaterialComponent } from 'src/app/assign-material/assign-material.component';
import { TripCommentTrailComponent } from 'src/app/modals/trip-comment-trail/trip-comment-trail.component';
import { promise } from 'protractor';
import { resolve } from 'dns';
import { AddComplianceModalComponent } from 'src/app/modals/add-compliance-modal/add-compliance-modal.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { capitalize } from 'lodash';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { HttpEventType } from '@angular/common/http';
import { complainceAlertLog } from 'src/app/dataType/complianceInterface';
declare var $: any;


@Component({
  selector: 'app-stage-wise-user-dashboard',
  templateUrl: './stage-wise-user-dashboard.component.html',
  styleUrls: ['./stage-wise-user-dashboard.component.scss'],
})
export class StageWiseUserDashboardComponent {
  userLocationState = {
    latitude: null,
    longitude: null,
    isGeoBounded: false,
    isOnEntryStage: false,
    parkingAlotmentType: null,
    dockAlotmentType: null,
    lateRemarkMandatory: false,
    rfidEnabled: false,
    strictQueue: false,
    userEntryStages: [],
    enableCurrentActions: true,
    canApprove: true,
    canReject: true,
    dockAssigned: false,
    parkingAssigned: false,
    tagAssigned: false,
    userAssigned: false,
    driverAssigned: false,
    transporterAssigned: false,
    productAssigned: false,
    canEditProcess: true,
  };
  detailsOf: string = 'Driver';
  loaderState = {
    in: false,
    out: false,
    preAction: false,
    postAction: false,
    currAction: false,
    stageForm: false,
  };
  vehicleList = { in: [], out: [] };
  actionList = { pre: [], post: [], curr: [] }
  tempList = { in: [], out: [] };
  activeSearchBar = { in: false, out: false };
  vehicleStageFlow: string = 'nextStages';
  lastTripActions: any = [];
  selectedVehicleIndex: any = null;
  remarkResponse: any = null;
  dockData: any = [];
  processStageList: any = [];
  boomBarrierStageId: number = null;
  boomBarrierStageName: string = null;
  processList: any[] = [];
  processEditFlag: boolean = false;
  currentAction: any = null;
  commentMessage: string = '';
  selectedVehicle:any= null;
  txnFailRemark:Boolean = false;
  selectToggle: boolean = true;
  complainceAlertLog:complainceAlertLog[]=[];
  currentCompliance:{
    processName : string,
    stageName: string,
    stageId: number,
    processId: number,
    driverId: number,
    transporterId: number,
    vehicleId: number,
    txnId:number,
    vehicleRsn: number,
    driverName:number,
    transporterName:string,
    refType: string
  }
  editable = false

  @ViewChild('remarkModal') remarkModal: any;


  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    public common: CommonService,
    private locationService: LocationService,
    public userService: UserService,
    private alertService: AlertService,
    private eventService: EventSourceService,
    private breadcrumbService: BreadcrumbService,
    private SharedService: SharedService
  ) {
    // Hidding Breadcrumb
    this.breadcrumbService.setActiveBreadCrumb(false);
  }

  ngOnInit() {
    // console.log("data shared",this.SharedService.data$)
    this.SharedService.dataSubject.asObservable().subscribe((data) => {

      this.vehicleList['in'][this.selectedVehicleIndex].refType = 'TRIP'
      if (data != null) {
        if (data.assignTo == 'Driver') {
          this.userLocationState.driverAssigned = true;
          this.vehicleList['in'][this.selectedVehicleIndex] = {
            ...this.vehicleList['in'][this.selectedVehicleIndex],
            driver_id: data.id,
            driver_name: data.name.includes('-')
              ? data.name.split('-')[0]
              : data.name,
              refType:'TRIP'
          };
        } else {
          this.userLocationState.transporterAssigned = true;
          this.vehicleList['in'][this.selectedVehicleIndex] = {
            ...this.vehicleList['in'][this.selectedVehicleIndex],
            transporter_id: data.id,
            transporter_name: data.name.includes('-')
              ? data.name.split('-')[0]
              : data.name,
          };
        }
      }
    });

    this.SharedService.vehicleSubject.subscribe((data: any) => {
      this.selectVehicle(this.vehicleList['in'].findIndex(
        (vehicle) => vehicle.vehicle_id == data.vehicleId
      ))
    })
  }

  ngAfterViewInit() {
    // Initialize Dashboard By User Position
    this.getUserPosition();
    this.getProcessStageByUserShift();
  }

  getAllProcess() {
    this.processEditFlag = true;
    this.common.loading = true;
    this.api.get('process/get-all-process?processActive=true').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.processList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedProcess(event: any) {
    this.processEditFlag = false;
    this.vehicleList.in[this.selectedVehicleIndex].process_id = event.processId;
    this.vehicleList.in[this.selectedVehicleIndex].process_name =
      event.processName;
    this.selectVehicle(this.selectedVehicleIndex);
  }

  assignDriverOrTransporter(
    assignTo: string,
    name: string,
    id: number,
    index: number
  ) {
    this.common.params = {
      assign: assignTo,
      transaction_id: this.vehicleList.in[this.selectedVehicleIndex].txnid,
      name: name,
      id: id,
    };
    const activeModal = this.modalService.open(
      AssignDriverTransporterComponent,
      {
        size: 'lg',
      }
    );
    activeModal.result.then((res) => {
      if (res.response != null) {
        if (assignTo == 'Driver') {
          this.userLocationState.driverAssigned = true;
          this.vehicleList['in'][this.selectedVehicleIndex] = {
            ...this.vehicleList['in'][this.selectedVehicleIndex],
            driver_id: res.id,
            driver_name: res.response.includes('-')
              ? res.response.split('-')[0]
              : res.response,
          };
        } else {
          this.userLocationState.transporterAssigned = true;
          this.vehicleList['in'][this.selectedVehicleIndex] = {
            ...this.vehicleList['in'][this.selectedVehicleIndex],
            transporter_id: res.id,
            transporter_name: res.response.includes('-')
              ? res.response.split('-')[0]
              : res.response,
          };
        }

      }
    });
  }

  showDetails(detailsOf: string, id: number, detail: any) {
    this.detailsOf = detailsOf;
    const activeModal = this.modalService.open(detail, {
      size: 'lg',
    });
  }

  subscribeStageEvents() {
    // Subscribing Event for Auto vehicle Selection
    this.eventService.connectEventSource(
      environment.hardwareUrl +
        'rfidreader/subscribe-stages?userId=' +
        this.userService._loggedInUser.id
    );
    this.eventService.getMessages().subscribe((msg: any) => {
      // Reading Message from event
      let data = JSON.parse(msg.data);
      if (
        data.vehicleId &&
        (this.selectedVehicleIndex == null || this.vehicleList.in[this.selectedVehicleIndex].vehicle_id !=
          data.vehicleId)
      ) {
        this.selectVehicle(
          this.vehicleList['in'].findIndex(
            (vehicle) => vehicle.vehicle_id == data.vehicleId
          )
        );
      }
    });
  }

  searchVehicle(event: any, type: string) {
    if ( event == null || event.target.value == '')
      this.vehicleList[type] = this.tempList[type]; // Reseting Vehicle List
    else {
      this.vehicleList[type] = this.vehicleList[type].filter((vehicle) =>
        vehicle.vehicle_rc_no
          .toLowerCase()
          .includes(event.target.value.trim().toLowerCase())
      ); // Filtering vehicle List based on user input
    }
  }

  getUserPosition() {
    this.common.loading = true;
    this.api.get('dashboard/get-user-position').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          // Initailize User Position
          if (
            res.data[0].is_on_first_stage == true &&
            res.data[0].mannual_entry_enable == true
          ) {
            this.userLocationState.isOnEntryStage = true;
          }
          this.userLocationState.userEntryStages = res.data[0].stage_data;
          this.userLocationState.rfidEnabled = res.data[0].is_rfid_enabled;
          this.userLocationState.parkingAlotmentType =
            res.data[0].is_parking_allotment_manual;
          this.userLocationState.isGeoBounded = res.data[0].is_geobounded;
          this.userLocationState.strictQueue = res.data[0].is_strict_queue;
          this.userLocationState.lateRemarkMandatory =
            res.data[0].is_late_remark_mandatory;

          // Fetching User Geo-coordinates
          if (this.userLocationState.isGeoBounded) {
            this.userLocationState.latitude =
              this.locationService._position.latitude;
            this.userLocationState.longitude =
              this.locationService._position.longitude;

            // Subscribe to change in location to auto-update lat-long
            this.locationService.position.subscribe((position: any) => {
              this.userLocationState.latitude = position.latitude;
              this.userLocationState.longitude = position.longitude;
            });
          } else {
            this.userLocationState.latitude = -1;
            this.userLocationState.longitude = -1;
          }
          // Fetching Vehicle List
          this.getVehiclesList();
          // Subscribing Event for Stages
          this.subscribeStageEvents();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getDashboardActions(actionStatus: string) {
    // this.preActionsList = [];
    let params = {
      processId: this.vehicleList.in[this.selectedVehicleIndex].process_id,
      stageId: this.vehicleList.in[this.selectedVehicleIndex].stage_id,
      stageActionStatus:
        actionStatus == 'pre' ? -1 : actionStatus == 'post' ? 1 : 0,
      flowType: this.vehicleList.in[this.selectedVehicleIndex].flow_type,
      vehicleId: this.vehicleList.in[this.selectedVehicleIndex].vehicle_id,
      transactionId: this.vehicleList.in[this.selectedVehicleIndex].txnid,
    };
    this.loaderState[actionStatus + 'Action'] = true;

    this.api.get('dashboard/get-dashboard-actions', params).subscribe(
      (res: any) => {
        this.loaderState[actionStatus + 'Action'] = false;
        if (res.status) {
          //for preAction List
          this.actionList[actionStatus] = res.data == null ? [] : res.data;
          // console.log(
          //   'this.actionList[actionStatus]: ',
          //   this.actionList[actionStatus]
          // );
          if (actionStatus == 'curr') {
            // Check Pre Action List
            this.checkActionState();
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.loaderState[actionStatus + 'Action'] = false;
      }
    );
  }

  checkActionState() {
    this.userLocationState.enableCurrentActions = true;
    this.userLocationState.canApprove = true;
    this.userLocationState.canReject = true;
    this.actionList['pre'].forEach((action) => {
      if (
        action.response_status.toLowerCase() != 'pass' &&
        action.isMandatory
      ) {
        this.userLocationState.enableCurrentActions = false;
        this.userLocationState.canApprove = false;
        if (action.response_status.toLowerCase().includes('pending'))
          this.userLocationState.canReject = false;
      }
    });
    if (!this.userLocationState.enableCurrentActions) return;
    // Check Current Action List
    this.actionList['curr'].forEach((action) => {
      const responseStatus = action.response_status.toLowerCase();
      if (responseStatus === 'pass' || responseStatus === 'fail') {
        this.userLocationState.canEditProcess = false;
      }
      if (responseStatus != 'pass' && action.isMandatory) {
        this.userLocationState.canApprove = false;
        if (responseStatus.includes('pending')) {
          this.userLocationState.canReject = false;
        }
      }
    });

  }
  getAllDashboardActions() {
    ['pre', 'curr', 'post'].forEach((staus) => {
      this.getDashboardActions(staus);
    });
  }

  getDashboardLastTripActions() {
    this.loaderState.stageForm = true;
    this.api
      .get(
        'transaction/last-trip-failed-actions?vehicleId=' +
          this.vehicleList.in[this.selectedVehicleIndex].vehicle_id
      )
      .subscribe(
        (res: any) => {
          this.loaderState.stageForm = false;
          if (res.status) {
            this.lastTripActions = res.data;
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.loaderState.stageForm = false;
        }
      );
  }
  openAction(action: any) {
    this.common.params = {
      rowDetail: {
        processId: this.vehicleList.in[this.selectedVehicleIndex].process_id,
        stageId: action.actionStageId,
        stageName: this.selectedVehicle.stage_name,
        actionId: action.action_id,
        transactionDetailId: null,
        transactionStageDetailId: action.stageDetailId,
        actionName: action.action_name,
        stageActionId: action.stage_action_configuration_id,
        transactionId: this.vehicleList.in[this.selectedVehicleIndex].txnid,
        actionType: action.audittype,
        flowType: this.vehicleList.in[this.selectedVehicleIndex].flow_type,
        transaction_type: 'trip',
        meetWith: null,
        vehicleId: this.vehicleList.in[this.selectedVehicleIndex].vehicle_id,
        nextStageId: this.vehicleList.in[this.selectedVehicleIndex].stage_id,
        stageType:
          this.vehicleList.in[this.selectedVehicleIndex].stage_type_name,
        stageDestinationCode:
          this.vehicleList.in[this.selectedVehicleIndex].stageDestinationId,
        txnDetailId: action.save
      },
    };
    this.openActionForm();
  }

  async openDock() {
    await this.getDockCount(); // Getting Dock Data
    this.common.params = {
      rowDetail: 'Dock',
      dockData: this.dockData,
      txnId: this.vehicleList.in[this.selectedVehicleIndex].txnid,
      flowType: this.vehicleList.in[this.selectedVehicleIndex].flow_type,
      processId: this.vehicleList.in[this.selectedVehicleIndex].process_id,
      stageId: this.vehicleList.in[this.selectedVehicleIndex].stage_id,
      vehicleId: this.vehicleList.in[this.selectedVehicleIndex].vehicle_id,
      stageType: this.vehicleList.in[this.selectedVehicleIndex].stage_type_name,
      dockId: this.vehicleList.in[this.selectedVehicleIndex].dock_id,
      dockName: this.vehicleList.in[this.selectedVehicleIndex].dock_name,
      isPriority: this.vehicleList.in[this.selectedVehicleIndex].ispriority,
      nextStageId: this.vehicleList.in[this.selectedVehicleIndex].stage_id,
      stageDestinationCode:
        this.vehicleList.in[this.selectedVehicleIndex].stageDestinationCode,
      inStageDestinationCodeId:this.vehicleList.in[this.selectedVehicleIndex].stageDestinationId,
      extendOrderId:this.vehicleList.in[this.selectedVehicleIndex].extend_order_id,
      current_dock:this.dockData[0]?.stage_destination_code_id??null
    };
    this.openActionForm();
  }
  openParking() {
    this.common.params = {
      rowDetail: 'Parking',
      txnId: this.vehicleList.in[this.selectedVehicleIndex].txnid,
      flowType: this.vehicleList.in[this.selectedVehicleIndex].flow_type,
      processId: this.vehicleList.in[this.selectedVehicleIndex].process_id,
      stageId: this.vehicleList.in[this.selectedVehicleIndex].stage_id,
      vehicleId: this.vehicleList.in[this.selectedVehicleIndex].vehicle_id,
      nextStageId: this.vehicleList.in[this.selectedVehicleIndex].stage_id,
    };
    this.openActionForm();
  }

  openActionForm() {
    const activeModal = this.modalService.open(ActionFormComponent, {
      size: this.common.params.rowDetail=='Dock' ? 'lg' : 'lg',
    });
    activeModal.result.then((res) => {
      let result = res.result;
      if (res.response == 'dockAssigned') {
        this.userLocationState.dockAssigned = true;
        this.vehicleList['in'][this.selectedVehicleIndex] = {
          ...this.vehicleList['in'][this.selectedVehicleIndex],
          dock_id: result.dock_id,
          dock_name: result.dock_name,
          ispriority: result.ispriority,
        };
      } else if (res.response == 'parkingSlotAssigned') {
        this.userLocationState.parkingAssigned = true;
        this.vehicleList['in'][this.selectedVehicleIndex] = {
          ...this.vehicleList['in'][this.selectedVehicleIndex],
          parking_slot_id: result.parking_id,
          parking_slot_name: result.parking_name,
        };
      } else {
        if(result!=null){
        this.actionList['curr'] = this.actionList['curr'].map((action) => {
          // console.log('action: ', action);
          // console.log('this.common.params: ', this.common.params);
          this.getDashboardActions('curr');
          if (action.action_id == this.common.params.rowDetail.actionId) {
            action.response_status = result.status;
          }
          return action;
        });
      }
        // console.log('this.actionList: ', this.actionList);
      }
      this.checkActionState();
      this.getDashboardActions("curr");
    });
  }

  addVehicle(selectedVehicleTrue: boolean = false) {
    let stageData = this.userLocationState.userEntryStages;
    if (this.userLocationState.userEntryStages.length == 0) {
      stageData = [
        {
          label: `${
            this.vehicleList.in[this.selectedVehicleIndex].stage_name
          }(${this.vehicleList.in[this.selectedVehicleIndex].process_name})`,
          stageId: this.vehicleList.in[this.selectedVehicleIndex].stage_id,
          processId: this.vehicleList.in[this.selectedVehicleIndex].process_id,
          stageName: this.vehicleList.in[this.selectedVehicleIndex].stage_name,
          processName:
            this.vehicleList.in[this.selectedVehicleIndex].process_name,
        },
      ];
    }
    if (selectedVehicleTrue) {
      this.common.params = {
        stage_data: stageData,
        rfIdEnabled: this.userLocationState.rfidEnabled,
        currentVehicleId:
          this.vehicleList.in[this.selectedVehicleIndex].vehicle_id,
        currentVehicleNumber:
          this.vehicleList.in[this.selectedVehicleIndex].vehicle_rc_no,
        currentStageId: this.vehicleList.in[this.selectedVehicleIndex].stage_id,
        currentProcessId:
          this.vehicleList.in[this.selectedVehicleIndex].process_id,
      };
    } else {
      this.common.params = {
        stage_data: stageData,
        rfIdEnabled: this.userLocationState.rfidEnabled,
      };
    }
    const activeModal = this.modalService.open(AddVehicleComponent, {
      size: 'sm',
    });
    activeModal.result.then((res) => {
      if (res.apiHit == -1) {
        if (res.message == 'Active Trip with this Vehicle already Exist') {
          this.alertService.warn(res.message);
        } else if (res.message == 'RFID Mapped Successfully ...') {
          this.alertService.info(res.message);
        } else {
          this.alertService.info('Trip initiated Successfully...');
        }
        this.getVehiclesList(res.vehicleNo);
        if (res.destinationAlloted == false) {
          this.alertService.error(res.message);
        }
      }
    });
  }

  checkVehicleActionAllowed(
    vehicleId: number,
    processId: number,
    destinationId: number,
    queueIndex: number
  ) {
    return new Promise((resolve, reject) => {
      let params = {
        vehicleId: vehicleId,
        processId: processId,
        destinationId: destinationId,
        selectedVehicleIndex: queueIndex,
      };
      this.api.get('transaction/get_vehicle_action_allowed', params).subscribe(
        (res: any) => {
          if (res.status) {
            resolve(res.data[0].is_vehicle_allowed);
          } else {
            resolve(false);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  async selectVehicle(index) {
    this.selectedVehicle = this.vehicleList.in[index];
    this.userLocationState.driverAssigned = false;
    this.userLocationState.transporterAssigned = false;
    this.userLocationState.productAssigned = false;
    if (index == -1) return;
    // Reset Fields
    this.resetFields();

    let vehicle = this.vehicleList.in[index]; // Fetching Vehicle Data
    if (
      this.userLocationState.strictQueue &&
      vehicle.stage_type_name == 'Dock Stage' &&
      vehicle.in_time == null &&
      !(await this.checkVehicleActionAllowed(
        vehicle.vehicle_id,
        vehicle.process_id,
        vehicle.stageDestinationId,
        vehicle.queue_index
      ))
    ) {
      this.alertService.warn(
        `A prior vehicle ${vehicle.vehicle_rc_no} exist in the Queue`
      ); // Restricting when Strict Queue Enable

      return;
    }

    if (vehicle.txnStageId == 0) this.assignProcess(vehicle);
    else {
      this.selectedVehicleIndex = index;
      if (vehicle.in_time == null) vehicle.in_time = new Date().toISOString();

      this.getAllDashboardActions(); // Fetching Dashboard Actions
      this.getDashboardLastTripActions(); // Fetching Dashboard Last Trip Actions
     // this.getComplianceAlertLogs(); //fetching compliance and alert logs
    }

    this.boomBarrierStageName = this.processStageList.filter(
      (res) =>
        res.stage_id == vehicle.stage_id && res.process_id == vehicle.process_id
    )[0]?.process_stage;
    this.boomBarrierStageId = vehicle.stage_id;


    this.currentCompliance={
      processName : this.selectedVehicle.process_name,
      stageName : this.selectedVehicle.stage_name,
      stageId: this.selectedVehicle.stage_id,
      processId: this.selectedVehicle.process_id,
      driverId: this.selectedVehicle.driver_id,
      transporterId: this.selectedVehicle.transporter_id,
      vehicleId: this.selectedVehicle.vehicle_id,
      txnId:this.selectedVehicle.txnid,
      vehicleRsn: this.selectedVehicle.vehicle_rc_no,
      driverName:this.selectedVehicle.driver_name,
      transporterName:this.selectedVehicle.transporter_name,
      refType : 'TRIP'
    }

  }

  getDockCount():Promise<any> {
    return new Promise((resolve,reject)=>{
    let params = {
      processId: this.vehicleList.in[this.selectedVehicleIndex].process_id,
      txnId: this.vehicleList.in[this.selectedVehicleIndex].txnid
    };
    this.api.get('dashboard/check-dock-type-destination', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.dockData = res.data;
          resolve(this.dockData);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        reject(null);
      }
    );
  });
  }

  resetFields() {
    this.loaderState = {
      in: false,
      out: false,
      currAction: false,
      preAction: false,
      postAction: false,
      stageForm: false,
    };
    this.selectedVehicleIndex = null;
    this.actionList = {
      pre: [],
      post: [],
      curr: [],
    };
    this.userLocationState = {
      ...this.userLocationState,
      enableCurrentActions: true,
      canApprove: true,
      canReject: true,
      dockAssigned: false,
      parkingAssigned: false,
      tagAssigned: false,
      userAssigned: false,
      canEditProcess: true,
    };
    this.lastTripActions = [];
    this.remarkResponse = null;
    this.activeSearchBar = { in: false, out: false };
    this.vehicleStageFlow = 'nextStages';
    this.boomBarrierStageId = null;
    this.boomBarrierStageName = null;
  }

  storeBooleanActionResult(curr: any, res: any) {
    let params = {
      stageActionId: curr.action_id,
      tagStageWorkflowDetailId: null,
      stageDetailId: curr.stageDetailId,
      status: res,
      transactionId: this.vehicleList.in[this.selectedVehicleIndex].txnid,
      actionType: curr.audittype,
      stageId: curr.actionStageId,
      flowType: this.vehicleList.in[this.selectedVehicleIndex].flow_type,
      lat: this.userLocationState.latitude,
      lng: this.userLocationState.longitude,
      nextStageId: this.vehicleList.in[this.selectedVehicleIndex].stage_id,
      vehicleId: this.vehicleList.in[this.selectedVehicleIndex].vehicle_id,
      transactionDetailsId:curr.save

    };



    // this.addCommentForTrip(curr);
    this.api.post('transaction/save-transaction-detail', params).subscribe(
      (result: any) => {
        this.getDashboardActions('curr');
        this.currentAction = null;
        if (result.message == 'NOT INSIDE GEOFENCE AREA')
          this.alertService.error(result.message);
        else
          this.actionList['curr'] = this.actionList['curr'].map((action) => {
            if (action.action_id == curr.action_id) {
              action.response_status = res;
            }
            return action;
          });
        this.checkActionState();
        this.getDashboardActions("curr")
        console.log("paramsssssssssss",params)
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  openRemarkModal(){
    $('#exampleModal').modal('show');
  }

  async checkNextStages(flow: string, content) {
    this.vehicleStageFlow = flow;
    if (this.vehicleList.in[this.selectedVehicleIndex][flow].length > 1) {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
      return;
    }
    if (
      !this.checkStageTatExceeded() &&
      this.userLocationState.lateRemarkMandatory &&
      !(await this.remark())
    ) {
      return;
    }
    this.assignNextStage();
  }

  assignNextStage() {
    let flowtype = this.vehicleStageFlow == 'nextStages' ? 1 : 0;
    let data = {
      vehicleId: this.vehicleList.in[this.selectedVehicleIndex].vehicle_id,
      permitVehicle: flowtype,
      currStageId:this.vehicleList.in[this.selectedVehicleIndex].stage_id,
      nextStageId:
        this.vehicleList.in[this.selectedVehicleIndex].sel_next_stage_id ??
        null,
      lateRemark:
        this.remarkResponse == null
          ? null
          : JSON.stringify(this.remarkResponse),
    };

    this.api.post('transaction/assign-next-stage', data).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.data[0].success == false) {
          this.alertService.error(res.data[0].status);
        } else {
          this.getVehiclesList(null);
          this.resetFields();
          this.alertService.success(res.data[0].status);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  checkStageTatExceeded() {
    let vehicle = this.vehicleList.in[this.selectedVehicleIndex];
    if (vehicle.in_time == null || vehicle.stage_tat == null) return true;

    let currentTime = new Date();
    let inTime = new Date(vehicle.in_time.split(' ').join('T'));

    // Parse the time duration string into hours, minutes, and seconds
    const [hoursStr, minutesStr, secondsStr] = vehicle.stage_tat.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    const seconds = parseInt(secondsStr, 10);

    // Create a new date by adding the time duration to the original date
    const resultDate = new Date(
      inTime.getFullYear(),
      inTime.getMonth(),
      inTime.getDate(),
      inTime.getHours() + hours,
      inTime.getMinutes() + minutes,
      inTime.getSeconds() + seconds
    );
    return !(
      currentTime > resultDate && this.userLocationState.lateRemarkMandatory
    );
  }

  async remark() {
    let refData = this.actionList.curr.map((item) => ({
      actionId: item.action_id,
      actionName: item.action_name,
    }));
    this.common.params = {
      refData: refData,
    };
    const activeModal = this.modalService.open(RemarkComponent, { size: 'lg' });

    const res = await activeModal.result;
    if (res.apiHit == 1) {
      this.remarkResponse = res.result;
    }
    return res.response;
  }

  getVehiclesList(preSelectedVehicle: string = null) {
    // Fetching In Vehicles
    this.getVehicleListByType(preSelectedVehicle);
    // Fetching Out Vehicles
    this.getVehicleListByType(null, 'out');
  }

  getVehicleListByType(preSelectedVehicle: any = null, type: any = 'in') {
    if (
      type == 'in' &&
      this.userLocationState.isGeoBounded &&
      (this.userLocationState.latitude == undefined ||
        this.userLocationState.latitude == -1 ||
        this.userLocationState.latitude == null)
    )
      return this.alertService.warn('Location is Not Enabled');

    this.loaderState[type] = true;
    let params = {
      type: type == 'in' ? 0 : 1,
      lat: this.userLocationState.latitude,
      lng: this.userLocationState.longitude,
    };
    this.api.get('dashboard/get-dashboard-vehicles', params).subscribe(
      (res: any) => {
        this.loaderState[type] = false;
        if (res.status) {
          this.vehicleList[type] = res.data;
          console.log('this.vehicleList: ', this.vehicleList);
          this.tempList[type] = this.vehicleList[type];
          if (type == 'in' && preSelectedVehicle != null) {
            // Selecting default vehicle
            const index = res.data.findIndex((vehicle) =>vehicle.vehicle_rc_no.trim() === preSelectedVehicle.trim());
            if (index !== -1) {
              this.selectVehicle(index);
            } else {
              this.selectVehicle(Math.floor(Math.random() * res.data.length));
            }
          }
        }
      },
      (err: any) => {
        this.loaderState[type] = false;
      }
    );
  }

  async actionDetail(
    action,
    actionType: any,
    action_type: number,
    lastTrip: boolean
  ) {
    let activeModal = this.modalService.open(ActionInfoModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
    });
    activeModal.componentInstance.refData = {
      actionName: action.action_name,
      actionType: actionType,
      stageId: action.actionStageId,
      txnId:
        lastTrip == false
          ? this.vehicleList.in[this.selectedVehicleIndex].txnid
          : action.tripId,
      actionId: action.action_id,
      action_type: action_type,
      txnStageDetailId: action.stageDetailId,
    };
  }

  tripModal(vehicle) {

    console.log("vehicle")
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.txnid,
      processName: vehicle.process_name,
      processId: vehicle.process_id,
    };
  }

  tripTrackingModal(vehicle) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.txnid,
      vehicleName: vehicle.vehicle_rc_no + '-' + vehicle.process_name,
    };
  }

  driverIVRCallModal(vehicle) {
    let activeModal = this.modalService.open(DriverIvrCallComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
    activeModal.componentInstance.refData = {
      vehicle: vehicle,
    };
    activeModal.result.then((res) => {
      if (res.response) {
        this.alertService.success(res.response);
      }
    });
  }


  tripCommentTrail(vehicle) {
    let activeModal = this.modalService.open(TripCommentTrailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
    activeModal.componentInstance.tripId = vehicle.txnid;
    activeModal.result.then((res) => {
      if (res.response) {
        this.alertService.success(res.response);
      }
    });
  }

  assignProcess(vehicle, editProcessFlag: any = false) {
    const activeModal = this.modalService.open(AssignProcessComponent, {
      size: 'sm',
    });
    activeModal.componentInstance.refData = {
      txnId: vehicle.txnid,
      vehicleId: vehicle.vehicle_id,
      stageId: vehicle.stage_id,
      vehicleRc: vehicle.vehicle_rc_no,
      destinationId: vehicle.stageDestinationId,
      editProcessFlag: editProcessFlag,
    };
    activeModal.result.then((res) => {
      if (res.response) {
        this.getVehicleListByType(vehicle.vehicle_rc_no);
      }
    });
  }

  overrideAction(curr: any) {
    this.common.loading = true;
    this.api
      .get(
        'dashboard/update-overriding-status?transactionDetailId=' +
          curr.save +
          '&status=PENDING' +
          '&actionId=' +
          curr.action_id +
          '&transactionId=' +
          this.vehicleList.in[this.selectedVehicleIndex].txnid
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.addCommentForTrip(curr)
            this.alertService.success('Overriding Permission Alloted...');
            this.getDashboardActions('curr');
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  addCommentForTrip(curr: any, action: boolean = false,txnFailRemark:Boolean = false,content:any=false) {
    if (action && !txnFailRemark) this.storeBooleanActionResult(curr, 'FAIL');
    let params = {
      entityId: this.vehicleList.in[this.selectedVehicleIndex].txnid,
      entityType: 'TRIP',
      commentType: action && !txnFailRemark ? 'ACTION FAIL' : !action && txnFailRemark ? 'TRANSACTION REJECTED' :  'ACTION OVERRIDED',
      comment: action || txnFailRemark ? (this.commentMessage == '' ? 'N/A' : this.commentMessage) : this.userService._name + " requested Overriding for "+curr.action_name + " !!",
      refData: action && !txnFailRemark ? JSON.stringify({stageName:this.selectedVehicle.stage_name,stageId:this.boomBarrierStageId,title: '"'+curr.action_name+'" Failed !!',actionId : curr.action_id , actionName : curr.action_name}) : !action && txnFailRemark ?
      JSON.stringify({stageName:this.selectedVehicle.stage_name,stageId:this.boomBarrierStageId,title:'Transaction is Cancelled'}):
      JSON.stringify({stageName:this.selectedVehicle.stage_name,stageId:this.boomBarrierStageId}),
    };
     this.api.post('comment/add-comment', params).subscribe(
      (res: any) => {
        this.commentMessage = '';
        if(txnFailRemark){
          this.checkNextStages('nextRejectStage',content);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  openAssignUserModal() {
    let activeModal = this.modalService.open(AssignUserModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
    });
    activeModal.componentInstance.refData = {
      processId: this.vehicleList.in[this.selectedVehicleIndex].process_id,
      txnId: this.vehicleList.in[this.selectedVehicleIndex].txnid,
      stageId: this.vehicleList.in[this.selectedVehicleIndex].stage_id,
      stageName: this.vehicleList.in[this.selectedVehicleIndex].stage_name,
      assignedUserId: null,
    };

    activeModal.result.then((res) => {
      if (res.apiHit == 1) {
        this.userLocationState.userAssigned = true;
        // this.userAssignmentCompleted=true;
        if (res.sameStage == true) {
          this.getVehicleListByType();
        }
      }
    });
  }

  getProcessStageByUserShift() {
    this.common.loading = true;

    this.api.get('dashboard/get-process-stage-by-usershift').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.processStageList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  boomBarrierAction(handleBarrier: boolean) {
    this.common.loading = true;
    let params = {
      stageId: this.boomBarrierStageId,
      boomBarrierState: handleBarrier,
    };
    this.api.get('transaction/handle-boom-barrier', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        let msgValue = handleBarrier ? 'Opened' : 'Closed';
        this.alertService.info('boom Barrier Successfully ' + msgValue);
        this.boomBarrierStageId = null;
        this.boomBarrierStageName = null;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }



  getComplianceAlertLogs(){
    let params = {
      driverId:this.vehicleList.in[this.selectedVehicleIndex].driver_id,
      processId:this.vehicleList.in[this.selectedVehicleIndex].process_id,
      transporterId:this.vehicleList.in[this.selectedVehicleIndex].transporter_id,
      vehicleId:this.vehicleList.in[this.selectedVehicleIndex].vehicle_id,
      txnId:this.vehicleList.in[this.selectedVehicleIndex].txnid
    }
    console.log("compliance data",params)
    this.common.loading = true;
    this.api.get('compliance/get-complaince-alert-logs',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.complainceAlertLog = res.data;
        }
        this.common.loading = false;
      },
      (err) => {
        this.common.loading = false;
        console.log("Error: " + err);
      }
    );
  }

}


import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import * as filesaver from 'file-saver';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TableService } from 'src/app/@core/services/table.service';
import _ from 'lodash';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';

@Component({
  selector: 'app-asset-tracking-report',
  templateUrl: './asset-tracking-report.component.html',
  styleUrls: ['./asset-tracking-report.component.scss']
})
export class AssetTrackingReportComponent implements OnInit {

  headers:any[]=[];
  zoneList:any[]=[];
  stageList:any[]=[];
  // destinationList:any[]=[];
  selectedZoneId:any[]=[];
  selectedStageId:any[]=[];
  selectedDestinationId:any[]=[];
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'ASSET TRACKING REPORT');
  assetTrackingReportData:any[] = [];
  reportDate: any = new Date(new Date().setDate(new Date().getDate()));
  temp:any [] = [];


  ngAfterViewInit() {
    // this.getTagDestinationTATReport();
    // if(data)this.renderTable();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  constructor(private table:TableService, private breadcrumbService:BreadcrumbService, private activatedRoute: ActivatedRoute,private common:CommonService,private api:ApiService) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Asset Tracking Report', url: '/user/asset-tracking-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
    this.reportDate = this.common.dateFormatter(this.reportDate, 'YYYYMMDD', false);
    this.getAllZones();
    this.getAssetTrackingReport();
  }

  selectZone(event){
    this.headers = [];
    this.selectedZoneId = event.map((e) => e.id);
    console.log('this.selectedZoneId after: ', this.selectedZoneId);
    this.getAssetTrackingReport();
    this.getAllStages();
  }

  onDateSelect(){
    this.getAssetTrackingReport();
  }

  selectStage(event){
    this.headers = [];
    this.selectedStageId = event.map((e) => e.stage_id);
    console.log('this.selectedStageId after: ', this.selectedStageId);
    this.getAssetTrackingReport();
    // this.getAllDestinations();
  }

  selectDestination(event){
    this.selectedDestinationId.push(event[event.length - 1].stage_destination_code_id);
  }

  exportAsExcel(data: any[], fileName: string,allTrips:boolean=false): void {
    let value = _.cloneDeep(data);
    if(!allTrips){
     value.map(res => {
       res['Date']=res.created_at;
       res['Tag']=res.tag_id;
       res['Vehicle']=res.vehicle_rsn;
       res['Plant In']=res.plant_in;
     let destinations = res.destination_tat;
     Object.keys(destinations).forEach((destination)=>{
       res[destination]=destinations[destination];
     })
     res['Plant Out']=res.plant_out;
     res['Total TAT']=res.total_tat;
       delete res.data;
       delete res.destination_tat;
       delete res.created_at;
       delete res.tag_id;
       delete res.vehicle_rsn;
       delete res.plant_in;
       delete res.plant_out;
       delete res.total_tat;
       delete res.vehicle_id;
     });
    }
     const worksheet = XLSX.utils.json_to_sheet(value);
     const workbook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(workbook, worksheet, 'Tag Destination TAT Report');
     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
     const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     filesaver.saveAs(blob, fileName + '.xlsx');
   }

  resetPage(){
    this.temp = [];
    this.selectedStageId = [];
    this.selectedZoneId = [];
  }

  zero(){return 0}

  getAllZones(){
    this.common.loading = true;
    this.api.get("zone/get-all-zone-stage?allZones=false").subscribe((res: any) => {
      if (res.status) {
        this.zoneList = res.data;
        this.common.loading = false;
      }
    },(err: any) => {
      console.error('Error', err);
      this.common.loading = false;
    });
  }

  getAllStages(){
    this.common.loading = true;
    let params = {
      zoneId : this.selectedZoneId
    }
    this.api.get("zone/get-zone-stage-mapping",params).subscribe((res: any) => {
      if (res.status) {
        this.stageList = res.data;
        this.common.loading = false;
      }
    },(err: any) => {
      console.error('Error', err);
      this.common.loading = false;
    });
  }

  // getAllDestinations(){
  //   this.common.loading = true;
  //   this.api.get("destination/list_stage_destination_code?allStatus=false").subscribe((res: any) => {
  //     if (res.status) {
  //       this.destinationList = res.data;
  //       this.common.loading = false;
  //     }
  //   },(err: any) => {
  //     console.error('Error', err);
  //     this.common.loading = false;
  //   });
  // }

  getAssetTrackingReport(){
    console.log('this.selectedZoneId: ', this.selectedZoneId);
    console.log('this.selectedStageId: ', this.selectedStageId);
    this.common.loading = true;
    let params = {
      date : this.reportDate,
      zoneIds : this.selectedZoneId,
      stageIds : this.selectedStageId
      // plantId : null,
    }
    this.api.post("report/get-asset-tracking-report",params).subscribe((res: any) => {
      if (res.status) {
        this.assetTrackingReportData = res.data;
        console.log('this.assetTrackingReportData : ', this.assetTrackingReportData );
        if(this.assetTrackingReportData.length){
          this.assetTrackingReportData.forEach(element => {
            element.tracking_data= JSON.parse(element.tracking_data);
            element.total_tat = this.getTotalTAT(element.plant_out,element.plant_in,true);
            element.untracked_hrs = this.getTotalTAT(element.total_tat,element.tracked_hrs,false);
          });
          console.log('this.assetTrackingReportData modified: ', this.assetTrackingReportData );
          Object.keys(this.assetTrackingReportData[0].tracking_data).forEach((key: any) => {
            this.headers.push(key)
          });
          console.log('this.headers: ', this.headers);
        }
        this.common.loading = false;
      }
    },(err: any) => {
      console.error('Error', err);
      this.common.loading = false;
    });
  }


  getTotalTAT(plantOut: string, plantIn: string, isDateTime: boolean): string {
    if (!plantOut || !plantIn) {
        return '';
    }

    // Parse the datetime or time strings into Date objects
    const outDate = this.parseDateTimeOrTime(plantOut, isDateTime);
    const inDate = this.parseDateTimeOrTime(plantIn, isDateTime);

    // Calculate the difference in milliseconds
    const diffMs = outDate.getTime() - inDate.getTime();

    // Convert milliseconds to hours, minutes, and seconds
    const diffSecs = Math.floor(diffMs / 1000);
    const hours = Math.floor(diffSecs / 3600);
    const minutes = Math.floor((diffSecs % 3600) / 60);
    const seconds = Math.floor(diffSecs % 60);

    // Format the result as 'HH:mm:ss'
    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
}

parseDateTimeOrTime(dateTimeStr: string, isDateTime: boolean): Date {
    if (isDateTime) {
        // Parse full datetime string
        const [datePart, timePart] = dateTimeStr.split(' ');
        const [day, month, year] = datePart.split('-').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        return new Date(year, month - 1, day, hours, minutes, seconds);
    } else {
        // Parse time string
        const [hours, minutes, seconds] = dateTimeStr.split(':').map(Number);
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, seconds);
    }
}

// Helper function to pad single digit numbers with leading zero
pad(number: number): string {
    return number < 10 ? '0' + number : number.toString();
}

}

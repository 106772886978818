import { StagesettingService } from './../stage-setting.service';
import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { StageActionService } from '../../stage-action/stage-action.service';
import { StageService } from '../../stage/stage.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-stage-setting',
  templateUrl: './stage-setting.component.html',
  styleUrls: ['./stage-setting.component.scss'],
})
export class StageSettingComponent implements OnInit {
  stagesetting = [];
  formsettingtData: any[] = [];
  stagelist: any[] = [];
  stageid: number = null;
  stageformfields: any[] = [];
  stageformdata: any[] = [];
  allItems: any[] = [];
  processId: null;
  processList: any = [];
  selectedField: any = 'STAGE';
  actionList: any = [];
  actionId: any = null;
  actionName: any;
  fields: any[] = [];
  tempFields: any[] = [];
  linking: any[] = [];
  searchText: string = '';

  constructor(
    public Stage: StagesettingService,
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    private stageActionService: StageActionService,
    public stageService: StageService,
    public userService: UserService,
    public alert: AlertService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Fields', url: null },
      { name: 'Form Layout Management', link: '/user/stage-form-setting' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcu;ms implementation end*/
    let plantId = this.userService._loggedInUser.plantId;
    if (plantId != null) {
      this.getPlantSettingByPlantId(plantId);
    } else {
      this.linking = [
        { value: 'STAGE', viewValue: 'Stage' },
        { value: 'ACTION', viewValue: 'Action' },
        { value: 'CHECKLIST', viewValue: 'CheckList' },
      ];
    }
    this.getAllProcess();
  }

  ngOnInit(): void {
    this.getsetting();
  }

  getPlantSettingByPlantId(plantId: any) {
    this.common.loading = true;
    this.api
      .get('plant/get-plant-setting-by-plant-id?plantId=' + plantId)
      .subscribe(
        (response: any) => {
          this.common.loading = false;
          let entryFormType = response.data[0].entryType == 3 ? true : false;
          if (entryFormType == true) {
            this.linking = [
              { value: 'STAGE', viewValue: 'Stage' },
              { value: 'ACTION', viewValue: 'Action' },
              { value: 'CHECKLIST', viewValue: 'CheckList' },
              { value: 'ENTRY FORM', viewValue: 'Entry form' },
            ];
          } else {
            this.linking = [
              { value: 'STAGE', viewValue: 'Stage' },
              { value: 'ACTION', viewValue: 'Action' },
              { value: 'CHECKLIST', viewValue: 'CheckList' },
            ];
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getAllProcess() {
    this.common.loading = true;
    let apiSubUrl = 'process/get-all-process?processActive=true';
    this.api.get(apiSubUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.processList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  async selectProcess(item: any) {
    this.stageid = null;
    this.processId = null;
    this.resetData();
    this.processId = item.processId;
    if (this.selectedField == 'STAGE' || this.selectedField == 'CHECKLIST') {
      this.stagelist = [];
      this.getStageById(this.processId);
    } else if (this.selectedField == 'ACTION') {
      this.actionList = [];
      this.getAllProcessAction(this.processId);
    } else {
      await this.getFields();
      this.getStageSettingFieldsByActionId(0, this.processId);
    }
  }

  selectedAction(event: any) {
    this.actionId = event.actionId;
    this.actionName = event.actionName;
    this.resetData();
    this.getFields();
    if (this.selectedField == 'CHECKLIST') {
      this.getStageSettingFieldsByActionId(this.actionId, 0);
    } else {
      this.getStageSettingFieldsByActionId(this.actionId, this.processId);
    }
  }

  async getStageSettingFieldsByActionId(actionId: number, processId: number) {
    this.stageformfields = [];
    this.Stage.getstagessettingfieldsbyactionid(
      actionId,
      processId,
      this.selectedField == 'ENTRY FORM' ? this.selectedField : 'ACTION'
    ).subscribe(async (item) => {
      this.stageformfields = item.data ?? [];
      this.stageformdata = [];
      if (this.stageformfields.length) {
        this.stageformfields.forEach((obj) => {
          this.fieldGroupMethod(obj, true);
        });
        this.stageformdata = this.stageformfields;
        this.allItems = this.stageformdata;
        if (this.selectedField == 'ENTRY FORM') {
          //also check if it already exists in the stageformdata or not..
          let checkFieldAlreadyPresent = this.stageformdata.filter(
            (data) => data.database_field_name == 'vechile_number'
          ).length;

          if (checkFieldAlreadyPresent == 0) {
            this.stageformdata.unshift(
              //check if master fields contains any vehicle_number db linked field or not..
              this.fields.find(
                (field) =>
                  field.database_field_name == 'vechile_number' &&
                  field.field_status == 'ACTIVE'
              )
            );
          }
          if (this.fields.length != 0) {
            this.tempFields = this.fields;
          }
        }
      }
    });
  }

  changeMandatoryStatus(item: any) {
    if (typeof item.mandatory == 'boolean') {
      item.mandatory = !item.mandatory;
    } else {
      item.mandatory = item.mandatory == 0 ? 1 : 0;
    }
  }

  changeShowStatus(item: any, ind: number) {
    let i = item.savegroups.indexOf(ind);
    if (i == -1) {
      item.savegroups.push(ind);
    } else {
      item.savegroups = item.savegroups.filter((element) => element !== ind);
    }
  }

  changeUniqueStatus(item: any, ind: number) {
    let i = item.uniqueGroups.indexOf(ind);
    if (i == -1) {
      item.uniqueGroups.push(ind);
    } else {
      item.uniqueGroups = item.uniqueGroups.filter(
        (element) => element !== ind
      );
    }
  }

  getAllStageAction() {
    this.common.loading = true;
    this.stageActionService.getAllStageAction().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.actionList = res.data.filter((action) => {
            const isActive = action.actionOperationType == 'form';
            return isActive;
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getCheckListActions(event: any) {
    this.selectedField = event.value;
    this.processId = null;
    this.actionId = null;
    this.actionName = null;
    this.resetData();

    if (this.selectedField == 'CHECKLIST') {
      this.getAllStageAction();
    }
  }

  getAllProcessAction(processId: any) {
    this.common.loading = true;
    this.api
      .get(
        'actions/get-all-actions-by-processId?flag=true&processId=' + processId
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.actionList = [];
          res.data.forEach((action) => {
            if (action.action_operation_type == 'form') {
              this.actionList.push({
                plantId: action.plant_id,
                processId: action.process_id,
                actionOperationType: action.action_operation_type,
                actionType: action.action_type,
                isGeoBounded: action.is_geo_bounded,
                actionName: action.action_name,
                actionMapping: action.action_map_on,
                actionDescription: action.action_description,
                actionId: action.action_id,
                actionStatus: action.action_status,
              });
            }
          });
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
        }
      );
  }

  removeFields(index: number) {
    let value = this.allItems[index];
    this.tempFields.push(value);
    this.searchFieldName();
    this.allItems.splice(index, 1);
  }

  drop(event: CdkDragDrop<string[]>, id: string, from: string = '') {
    const draggedItem = event.item.data; // Access the dragged item

    //Find Index based on single list
    let currIndex =
      event.currentIndex * 2 + (event.container.id == 'left' ? 0 : 1);
    let preVindex =
      event.previousIndex * 2 + (event.previousContainer.id == 'left' ? 0 : 1);

    //Removing Element from previous container
    if (event.previousContainer.id == 'outer_list') {
      this.tempFields.splice(this.tempFields.indexOf(draggedItem), 1);
      this.searchFieldName();
    } else {
      this.allItems.splice(preVindex, 1);
    }

    //Adding Element to container
    this.allItems.splice(currIndex, 0, draggedItem);
  }

  searchFieldName(event: any = '', eventTrigger: boolean = false) {
    this.searchText = eventTrigger
      ? event.target.value.trim()
      : this.searchText;
    if (this.searchText != '') {
      this.searchText = eventTrigger
        ? event.target.value.trim()
        : this.searchText;
      this.fields = this.tempFields.filter((field) =>
        field.field_name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    } else {
      this.fields = this.tempFields;
    }
  }

  getStageById(id?: any) {
    this.common.loading = true;
    let params = {
      processId: id,
    };
    this.stageService
      .getStageByProcessAndPlant(params)
      .toPromise()
      .then(
        (res: any) => {
          this.common.loading = false;
          this.stagelist = res.data;
          this.stagelist = res.data.map((stage) => {
            stage.stageName = stage.stage_name;
            stage.stageId = stage.stage_id;
            return stage;
          });
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
        }
      );
  }

  getstageName(event: any) {
    this.stageid = event.stageId;
    this.actionId = null;
    this.resetData();
    this.getFields();
    this.getstagesettingfields();
  }

  getFields() {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        isActive: true,
        fieldLinkType:
          this.selectedField == 'ENTRY FORM' ||
          this.selectedField == 'CHECKFIELD'
            ? ['RFIDCODE', 'BARCODE', 'RFIDCODEWITHIMAGE', 'BARCODEWITHIMAGE']
            : [],
      };
      this.api.post('fields/allMasterFields', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.fields = res.data;
          if (this.fields.length != 0) {
            this.fields.forEach((obj) => {
              this.fieldGroupMethod(obj, false);
              if (!obj.hasOwnProperty('mandatory')) {
                obj.mandatory = 1;
              }
            });
            this.fields = this.fields.filter(
              (field) =>
                !this.stageformfields.some(
                  (setting) => setting.master_field_id === field.master_field_id
                )
            );
            this.tempFields = this.fields;
          }
          resolve(this.fields);
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
          reject(null);
        }
      );
    });
  }

  private fieldGroupMethod(obj: any, setting: boolean) {
    if (obj.field_type === 'FIELDGROUP' && obj.field_group_mapping != null) {
      let field_group_mapping = JSON.parse(obj.field_group_mapping);
      // Group the data by sub_group_name
      const groupedData = field_group_mapping.reduce((acc, obj) => {
        const subGroupName = obj.sub_group_name;
        if (!acc[subGroupName]) {
          acc[subGroupName] = [];
        }
        const newData = {
          fieldName: obj.field_name,
          fieldId: obj.field_id,
        };
        const insertIndex = acc[subGroupName].findIndex(
          (item) => item.field_index > obj.field_index
        );
        if (insertIndex === -1) {
          acc[subGroupName].push(newData);
        } else {
          acc[subGroupName].splice(insertIndex, 0, newData);
        }
        return acc;
      }, {});
      //list of name of sub_groups in fieldgroup...
      obj.subgroups = Object.keys(groupedData);
      if (setting) {
        //when already exists on an action ,assigning already selected subgroups to show
        obj.savegroups =
          obj.visible_sub_groups == null
            ? []
            : JSON.parse(obj.visible_sub_groups);
        obj.uniqueGroups =
          obj.unique_sub_groups == null
            ? []
            : JSON.parse(obj.unique_sub_groups);
      } else {
        //when adding for the first time filling index of every subgroup for show = true
        obj.savegroups = Array.from(
          { length: Object.keys(groupedData).length },
          (_, index) => index
        );
        //when adding for the first time keeping empty array for unique = false
        obj.uniqueGroups = [];
      }
    }
  }

  getstagesettingfields() {
    this.stageformfields = [];
    this.Stage.getstagessettingfields(this.stageid, this.processId).subscribe(
      (item) => {
        this.stageformfields = item.data ?? [];
        this.stageformdata = [];
        if (this.stageformfields.length) {
          this.stageformdata = this.stageformfields;
          this.allItems = this.stageformdata;
        }
      }
    );
  }

  getsetting() {
    this.stagesetting.map((item) => {
      this.formsettingtData.push({
        id: item.masterFieldId,
        fieldName: item.fieldName,
        fieldOrder: item.fieldOrder,
        fieldType: item.fieldType,
      });
    });
  }

  savestagesetting() {
    let flag = true;
    if (this.selectedField == 'ENTRY FORM') {
      let indexOfVehicleNumberField = this.allItems.filter(
        (d) => d.database_field_name == 'vechile_number'
      ).length;
      flag = indexOfVehicleNumberField == 0 ? false : true;
    }

    if (flag == false) {
      this.alert.error('DataBase Linked Vehicle RC No. Field is Mandatory...');
    } else {
      let masterFieldDTO = this.allItems.map((item, index) => {
        return {
          fieldOrder: index + 1,
          fieldType: item.field_type,
          mandatory: item.mandatory ? 1 : 0,
          fieldName: item.field_name,
          masterFieldId: item.master_field_id,
          saveGroups:
            item.field_type == 'FIELDGROUP'
              ? JSON.stringify(item.savegroups)
              : null, //saveGroups contains visible sub groups indices
          uniqueGroups:
            item.field_type == 'FIELDGROUP'
              ? JSON.stringify(item.uniqueGroups)
              : null, //saveGroups contains visible sub groups indices
        };
      });

      let params = {
        linkedWith: this.selectedField,
        processId: this.selectedField == 'CHECKLIST' ? null : this.processId,
        id:
          this.selectedField == 'STAGE'
            ? this.stageid
            : this.selectedField == 'ENTRY FORM'
            ? 0
            : this.actionId,
        masterFieldDTO: masterFieldDTO,
      };
      this.Stage.savestagesetting(params).subscribe((item) => {
        this.alert.info('Form Layout saved successfully...');
      });
    }
  }

  resetData() {
    this.stageformdata = [];
    this.allItems = [];
    this.fields = [];
    this.tempFields = [];
    if (
      ((this.selectedField == 'CHECKLIST' || this.selectedField == 'ACTION') &&
        this.actionId != null) ||
      (this.selectedField == 'STAGE' && this.stageid != null) ||
      (this.selectedField == 'ENTRY FORM' && this.processId != null)
    ) {
      this.getFields();
    }
  }
}

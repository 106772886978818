<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body stageType-form">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'stage_zone_mapping' | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"process->stage" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedStage($event)" [data]="processStages" display="order_name"
                    placeholder="Choose Process->Stage" [preSelected]="{order_name:selectedOrderName}"
                    [readonly]="isEdit">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="stageValidator && stageValidator.error">
                    <span>{{stageValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"Zone" | translate}} </label>
                  <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedZones($event)" [data]="zones"
                    display="zoneName" placeholder="Choose Zone" [preSelected]="selectedZoneNames">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="zoneValidator && zoneValidator.error">
                    <span>{{zoneValidator.msg}} </span>
                  </div>
                </div>


              </div>


              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    translate}}</button>
                  <button class="btn btn-warning" (click)="reset()">{{"reset" | translate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | translate}} </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"stage_zone_mapping_list" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"serial_no" | translate}}</th>
                    <th>{{"process_stage_name" | translate}}</th>
                    <th>{{"zone_name" | translate}}</th>
                    <th>{{"plant_name" | translate}}</th>
                    <th class="action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of groupedDataByProcessStageName | keyvalue; let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.key}}</td>
                    <td>{{formatZoneNames(row.value)}}</td>
                    <td>{{row.value[0]?.plant_name}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="view(row)">{{"view" | translate}}</button>
                        <button class="btn btn-success" (click)="edit(row)">{{"edit" | translate}}</button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)">{{"delete" | translate}}</button>
                      </div>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</div>

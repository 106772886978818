import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';

@Component({
  selector: 'app-employee-report',
  templateUrl: './employee-report.component.html',
  styleUrls: ['./employee-report.component.scss'],
})
export class EmployeeReportComponent implements OnInit {
  activeSection: string = 'attendance';
  // fromDate: any = new Date(new Date().setDate(new Date().getDate() - 7));
  fromDate: any = new Date();
  toDate: any = new Date();
  responseData: any = [];
  plantData: any = [];
  plantId: number = null;
  userList: any = [];
  userId: any = [];
  tabData: any = [
    { tabname: 'attendance', value: 'attendance' },
    { tabname: 'shift', value: 'shift' },
    // { tabname: 'checklist', value: 'checklist' },
  ];
  tabvalue: any = 'table';

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(25,7,'TRIP REPORT LIST');
  ngAfterViewInit() {
    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.getUserList();
      this.getAttendanceReport(1);
    }
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    private breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public alertService: AlertService,
    public userService: UserService,
    private plantService: PlantService,
    public role: RoleService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Employee Report', link: '/user/employee-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.getUserList();
    this.getAttendanceReport(1);
  }

  selectedUser(event: any) {
    this.userId = [];
    event.forEach((e) => {
      this.userId.push(e.userId);
    });
    this.getAttendanceReport(0);
  }

  getUserList() {
    let params = {
      plantId: this.plantId,
    };
    this.api.get('user/all_user', params).subscribe(
      (res: any) => {
        this.userList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  getAttendanceReport(flag: any) {
    let params;
    this.common.loading = true;
    if (this.userId.length == 0) {
      if (flag == 1) {
        this.fromDate = new Date();
        this.fromDate = this.common.dateFormatter(this.fromDate,'YYYYMMdd',false,'-');
        this.toDate = new Date();
        this.toDate = this.common.dateFormatter(this.toDate,'YYYYMMdd',false,'-');
      }
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
      };
    } else if (this.userId.length != 0) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        userIds: this.userId,
        plantId: this.plantId,
      };
    }
    this.api.post('report/get-emp-attendance-details', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.responseData = res.data;
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

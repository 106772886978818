<div class="modal-header" style="width: 550px;">
  <h6 class="modal-title" id="modal-basic-title">{{'Vehicle List'|translate}}</h6>
  <div aria-label="Close" (click)="closeModal()"><i class="fa fa-times"></i></div>
</div>
<div class="card" style="width:550px">
  <div class="row modal-body" id="action_id">
    <div class="vehicle-column" *ngIf="allProcesses?.length">
      <ng-container *ngFor="let vehicle of allProcesses;index as i">

        <div class="row m-0"
          [ngClass]="(vehicle.overtat && vehicle.stage_workflow_status == 'INPROGRESS')? 'vehicle-box over-tat' : (vehicle.stage_workflow_status == 'CANCELLED') ? 'vehicle-box over-tat' : (expected_tat != null && expected_tat < vehicle.tat && expected_tat != '') ? 'vehicle-box over-tat' : 'vehicle-box' ">
          <div class="col-md-9 col-12 left-sec">
            <p class="ml-1 mb-0 index"> {{i + 1}} </p>
            <div class="side-bar">
              <span>
                <p class="mb-0 ml-4" appEllipsisView><i class="fa fa-truck icon_design"></i> {{vehicle.vehicle_rc_no}} </p>
                <p *ngIf="refData.destinationId == -1" appEllipsisView style="min-width:30%" ><i class="fa fa-map icon_design"></i>
                  {{vehicle.destination_code}}</p>
                <p *ngIf=" refData.destinationId != -1"><i class="fa fa-clock icon_design"></i> TAT : {{vehicle.tat}}
                </p>
              </span>
              <span>
                <p class="mb-0 ml-4">Status : {{vehicle.stage_workflow_status}} <i
                    [ngClass]="vehicle.stage_workflow_status == 'INPROGRESS' ? 'fa animate_icon fa-spinner' : vehicle.stage_workflow_status == 'CANCELLED' ? 'fa animate_icon fa-times-circle': 'fa animate_icon fa-check-circle'"></i>
                </p>
                <p *ngIf="refData.destinationId == -1"><i class="fa fa-clock icon_design"></i> TAT : {{vehicle.tat}}</p>
              </span>
            </div>
          </div>
          <div class="col-md-3 col-12 right-sec" style="padding: 0px;">
            <span class="side-panel" (click)="tripModal(vehicle)"> Trip Detail
              <i class="fa fa-info-circle icon_design"></i>
            </span>
            <span class="side-panel" (click)="tripTrackingModal(vehicle)">
              Trip Tracking
              <i class="fa fa-map-marker icon_design"></i>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="!allProcesses?.length">
      <p style="color:red;display: flex;justify-content: center;">No Data Found !!</p>
    </div>



  </div>
  <div class="row  close-button">
    <button type="button" class="btn btn-danger" (click)="closeModal(false)">Close</button>
  </div>

</div>

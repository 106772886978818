import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-user-tracking-info-modal',
  templateUrl: './user-tracking-info-modal.component.html',
  styleUrls: ['./user-tracking-info-modal.component.scss']
})
export class UserTrackingInfoModalComponent implements OnInit {

  tabData: any = [
    { tabname: 'Tracking Trail', value: 'user_trail' },
    { tabname: 'Productivity Logs', value: 'user_productivity'},
    { tabname: 'Path Tracking', value: 'user_path_tracking' },
    { tabname: 'Asset View', value: 'asset_view' },
  ];
  tabValue:any = 'user_trail';
  userLocationLog:any = [];
  userProductivityLog:any=[];
  totalProductivity:number = 0;

  @Input() refData:any;
  constructor(
    private api: ApiService,
    public modalService: NgbModal,
    public alertService: AlertService,
    public common: CommonService,
    private activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.refData = this.common.params;
    this.getUserLocationLogs();
  }

  closeModal(res) {
    this.activeModal.close(res);
  }

  stopevent(event:any){
    // this.activeModal.close();
    // event.stopPropagation();
  }

  changeTab(){
    if(this.tabValue =='user_trail'){
      this.getUserLocationLogs();
    }else if(this.tabValue == 'user_productivity'){
      this.getUserProductivity();
    }
  }


  getUserLocationLogs() {
    this.common.loading = true;
    let params = {
      entityId:this.refData.userId,
      entityType:this.refData.userType,
      logDate:this.refData.logDate

    }
    let apiUrl = 'user-tracking/get-user-location-logs-by-zonestage';
    this.api.get(apiUrl,params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.userLocationLog = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getUserProductivity() {
    this.common.loading = true;
    let params = {
      entityId:this.refData.userId,
      entityType:this.refData.userType,
      logDate:this.refData.logDate
    }
    let apiUrl = 'user-tracking/get-user-location-productivity-by-zonestage';
    this.api.get(apiUrl,params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.userProductivityLog = JSON.parse(res.data[0].data);
          this.totalProductivity = res.data[0].shift_time;
          this.userProductivityLog.map(element => {
            element.productivity_percentage = 0
            if(element.zone_allocation == 'assigned'){
              element.productivity_percentage = (this.productivity_counter(element.productive_hours)/this.totalProductivity)*100;
            }
            return element;
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  productivity_counter(productive_hours:string){
    let time:any = productive_hours.split(":")
    return (time[0]*60*60) + (time[1]*60) + time[2]*1
  }


}

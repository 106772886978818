import { data } from './../automation/config';
import { Component, OnInit, ViewChild, HostBinding } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { StageService } from '../stage/stage.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {VisitorData, VisitorReport,VisitorReportByAppointment } from 'src/app/dataType/visitorInterface';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { UserTrackingInfoModalComponent } from 'src/app/modals/user-tracking-info-modal/user-tracking-info-modal.component';

@Component({
  selector: 'app-visitor-report',
  templateUrl: './visitor-report.component.html',
  styleUrls: ['./visitor-report.component.scss'],
})
export class VisitorReportComponent implements OnInit {
  startDate: any = new Date(new Date().setDate(new Date().getDate() - 7));
  endDate: any = new Date(new Date().setDate(new Date().getDate()));
  @ViewChild(DataTableDirective, { static: false }) dtElement: any;
  @ViewChild(DataTableDirective, { static: false }) dtElement1: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'Tat Report');

  listOfProcess: any = []; // create interface
  processName: string = null;
  processId: number = -1;
  plantId: number = null;
  visitorReportData: VisitorReport[] = [];
  visitorReportDataByAppointment: VisitorReportByAppointment[] = [];
  staffId: number;
  hostList: any[]=[];
  visitorList: any = [];
  hostId : number
  allFilters: boolean=true;
  visitorId: number
  modalHide: boolean;
  tabData  = [
    { tabname: 'Visit', value: 'visit'},
    { tabname: 'Appointment', value: 'appointment' },
  ]
  activeSection: string = 'visit'
  modalData : VisitorData[]=[]

  constructor(
    public table: TableService,
    public common: CommonService,
    public api: ApiService,
    public alert: AlertService,
    public stageService: StageService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public modalService: NgbModal,
    public role: RoleService,
    public plantService: PlantService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Visitor Report', link: '/user/visitor-visits-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }



  ngOnInit() {
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.getAllProcess();
    this.getVisitorReport();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {

    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }



  selectedProcess(event: any) {
    this.processId = event.processId;
    this.processName = event.processName;
    this.reset(true)
  }

  selectedHost(event: any) {
    this.hostId = event.hostId
    this.allFilters = false;
  }

  selectedVisitor(event: any) {
    this.visitorId = event.visitorId
    this.allFilters = false;
  }


  reset(isProcessChange: boolean = false){
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.endDate = new Date();
    this.allFilters = true;
    this.visitorId= null;
    this.hostId= null;

    if(!isProcessChange)
      {this.processId= -1;
    this.processName=null;}

    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);

    this.getVisitorReport()
  }
  getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.plantId,
      processType:'Visit'
    };
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.listOfProcess = res.data;
        this.listOfProcess.unshift({
          processId: -1,
          processName: 'All Processes',
        });
        // this.processId = this.listOfProcess[0].processId;
        // this.processName = this.listOfProcess[0].processName;
      },
      (err: any) => {
        // console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getVisitorReport() {

    if (this.startDate > this.endDate) {
      this.alert.warn('Start Date Should be Smaller than End Date!!');
      return;
    }
    this.common.loading = true;
    let params = {
      processId: this.processId,
      fromDate: this.startDate,
      toDate: this.endDate,
      type:this.activeSection
    };

    this.api.get('visitors/get-visitor-report', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if(this.activeSection=='visit'){
        this.visitorReportData = res.data;
        }
        else{
          this.visitorReportDataByAppointment=res.data.map(res => {
            res.visitor_data= JSON.parse(res.visitor_data);
            res.no_of_visitors=res.visitor_data.length;
            return res;
          });
        }
        let added = [];
        this.visitorList=[];
        this.hostList=[];
        this.visitorReportData.forEach((obj) => {
          console.log(obj.visit_to)
          if (!added.includes(obj.visit_to_id)) {
            this.hostList.push({
              hostId: obj.visit_to_id,
              hostName: obj.visit_to,
            });

            added.push(
              obj.visit_to_id
            )
          }

          if (!added.includes(obj.staff_id)) {
            this.visitorList.push({
              visitorId: obj.staff_id,
              visitorName: obj.visitor_name,
            });

            added.push(
              obj.staff_id
            )
          }
        });
      },
      (err: any) => {
        // console.error('Error:', err);
        this.common.loading = false;
      }
    );
  }

  checkVisitor(data){
    // console.log("fgyui",data,data.visitor_data.some((visitor)=>this.visitorId==visitor.visitor_id))
    return data.visitor_data.some((visitor)=>this.visitorId==visitor.visitor_id)
  }

  tripTrackingModal(currentVisitor) {

    // console.log(currentVisitor.ipa_transaction_id)
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    this.modalHide=true;
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: currentVisitor.txn_id?currentVisitor.txn_id:currentVisitor.ipa_transaction_id,
      vehicleName: currentVisitor.process_name + '-' + (currentVisitor.visitor_name?currentVisitor.visitor_name:(currentVisitor.first_name+' ' +currentVisitor.last_name))

    };
    activeModal.result.finally(()=>{
      this.modalHide=false;
    }).catch((e)=>{
    })
  }

  tripModal(currentVisitor) {
    // console.log(currentVisitor.ipa_transaction_id)
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    this.modalHide=true;
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: currentVisitor.txn_id?currentVisitor.txn_id:currentVisitor.ipa_transaction_id,
      processName: currentVisitor.process_name
    };

    activeModal.result.finally(()=>{
      this.modalHide=false;
    }).catch((e)=>{
    })

  }

  openUserTrackingModal(staffId:number){
    this.common.params = {
      userId:staffId,
      userType:'STAFF'
  }
    const activeModal = this.modalService.open(UserTrackingInfoModalComponent, {
      size: 'xl',
    });
  }
}

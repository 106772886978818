<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{assetData?.asset_name}}
            </h3>
          </div>
          <div style="display: flex;">
            <ps-auto-suggestion class="search" (onSelected)="selectedAsset($event)" [data]="assets" display="asset_name"
              placeholder="Select Asset" name="asset_name" inputId="asset_name"
              [preSelected]="{asset_name:this.selectedAssetName}">
            </ps-auto-suggestion>
            <button type="button" class="btn btn-success ml-2" (click)="editAsset()" style="font-size: 12px;">
              <i class="fa fa-edit"></i> {{'edit Asset'|customTranslate}}
            </button>
          </div>
        </div>

        <div class="card-body">
          <div class="details">
            <div class="row m-0">
              <div class="col-md-10 pl-0">
                <div class="row m-0">
                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"asset_code" | customTranslate}}:</div>
                      <div class="value">{{assetData?.asset_code}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"asset_date" | customTranslate}}:</div>
                      <div class="value">{{assetData?.created_at}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"asset_by" | customTranslate}}:</div>
                      <div class="value">{{assetData?.user_name}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"cost" | customTranslate}}:</div>
                      <div class="value">{{assetData?.asset_cost}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"manufacturer" | customTranslate}}:</div>
                      <div class="value">{{assetData?.manufacturer_name}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"supplier" | customTranslate}}:</div>
                      <div class="value">{{assetData?.supplier_name}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"description" | customTranslate }} :</div>
                      <div class="value">{{assetData?.asset_description}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"tag_code" | customTranslate}}:</div>
                      <div class="value">{{assetData?.tag_id}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"tag_type" | customTranslate}}:</div>
                      <div class="value">{{assetData?.tag_type}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"expiry_date" | customTranslate}}:</div>
                      <div class="value">{{assetData?.date_of_expiry|date:'dd-MM-yyyy'}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"warranty_till" | customTranslate}}:</div>
                      <div class="value">{{assetData?.date_of_waranty_expire}}</div>
                    </div>
                  </div>

                  <div class="col-md-4 px-1">
                    <div class="label_value">
                      <div class="label">{{"status" |  customTranslate}}:</div>
                      <div class="value" style="font-weight: 600;"
                        [ngStyle]="{'color': assetData?.status_name == 'CHECKEDOUT' ? '#b9af27' : (assetData?.status_name == 'AVAILABLE' ? '#32CD32' : '#191970')}">
                        {{assetData?.status_name}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-2" style="padding-top: 6px; padding-bottom: 4px; padding-left: 0; padding-right: 5px;">
                <div class="image">
                  <ng-container *ngIf="assetData?.asset_image; else defaultIcon">
                    <img [src]="assetData?.asset_image ?? ''">
                  </ng-container>
                  <ng-template #defaultIcon>
                    <!-- <div *ngIf="ifDeptWiseData()" class="text-center mt-1 fade-in no-assets-tracked">No Department wise Asset</div> -->
                    <img src="assets/images/empty_image.jpg">
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="tab_sec_div">
            <div class="tab_section">
              <div class="tab" [class.active]="activeTab==i" (click)="activeTab=i"
                *ngFor="let tabName of tablist; let i = index"> {{tabName}}</div>
            </div>

            <ng-container *ngIf="showAssetContainer && assetData">
              <app-asset-detail *ngIf="activeTab==0" [customFieldData]="assetData.fields_data"></app-asset-detail>
            </ng-container>
            <app-asset-events *ngIf="activeTab==1" [assetId]="assetId"></app-asset-events>
            <app-asset-document *ngIf="activeTab==2" [assetId]="assetId"></app-asset-document>
            <app-asset-depreciation *ngIf="activeTab==3" [assetId]="assetId"></app-asset-depreciation>
            <app-asset-warranty *ngIf="activeTab==4" [assetId]="assetId"></app-asset-warranty>
            <app-asset-maintenance *ngIf="activeTab==5" [assetId]="assetId"></app-asset-maintenance>
            <app-asset-insurance *ngIf="activeTab==6" [assetId]="assetId"></app-asset-insurance>
            <app-asset-reserve *ngIf="activeTab==7" [assetId]="assetId"></app-asset-reserve>
            <app-asset-history *ngIf="activeTab==8" [assetId]="assetId"></app-asset-history>
            <app-asset-linking *ngIf="activeTab==9" [assetId]="assetId"></app-asset-linking>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { MapModalComponent } from 'src/app/modals/map-modal/map-modal.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-audit-modal',
  templateUrl: './audit-modal.component.html',
  styleUrls: ['./audit-modal.component.scss'],
})
export class AuditModalComponent implements OnInit {
  process: string;
  stage: string;
  vehicleNumber: string;
  tripId: number;
  actionName: string = '';
  actionId: number;
  auditInfo: any;
  @Input() public refData;

  logsReport: any;
  auditStages: any;
  status: any = null;
  image_url: any = '';
  image_name: any = '';
  image_ext: any = '';
  image: boolean = false;
  latlongList: any = [];
  auditStageIds: any;
  constructor(
    public activeModal: NgbActiveModal,
    private common: CommonService,
    public api: ApiService,
    private modalService: NgbModal,
    public alertService: AlertService
  ) {
    this.common.handleModalSize('class', 'modal-xl', '1140');
    if (this.common.params) {
      this.stage = this.common.params?.stage;
      this.process = this.common.params?.process;
      this.vehicleNumber = this.common.params?.vehicleNumber;
      this.tripId = this.common.params?.param?.tripId;
      this.actionId = this.common.params?.param?.actionId;
      this.actionName = this.common.params?.actionName;
      this.getAuditReports();
      this.auditInfo = {
        process: this.process,
        'vehicle ID': this.vehicleNumber,
        Action: this.actionName,
        status: this.status,
      };
    }
  }

  ngOnInit(): void {}

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }

  getAuditReports() {
    this.common.loading = true;
    let apiUrl = 'audit/get-audit-logs-report';
    let params = `?actionId=${this.actionId}&tripId=${this.tripId}`;
    this.api.get(apiUrl + params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.data.length == 2) {
          this.status = 'Completed';
          this.auditInfo.status = 'Completed';
        } else {
          this.status = 'Pending';
          this.auditInfo.status = 'Pending';
        }
        this.auditStages = res.data.map((item) => ({
          stage_name: item._stage_name,
        }));
        this.auditStageIds = res.data.map((item) => ({
          stage_id: item._stage_id,
        }));
        let data = res.data?.map((item) =>
          this.ObjectTransform(item, item._stage_id)
        );
        let objFilter = (obj) =>
          _.pickBy(obj, (value, key) => !_.startsWith(key, '_'));
        let logsReportData = data.map((item) => objFilter(item));
        this.logsReport = logsReportData.map((item) => {
          Object.entries(item).reduce((acc, [key, value]: any) => {
            if (key == 'addtime')
              value = this.common.dateFormatter(value, 'ddMMYYYY', true);
            key = key.replaceAll('_', ' ');
            acc[key] = value;
            item = acc;
            return acc;
          }, {});
          return item;
        });
        console.log("logReport",this.logsReport);
      },
      (err) => {
        this.common.loading = false;
        console.error(err);
      }
    );
  }

  checkImageType(url: any) {
    if (url) {
      const arrays = url.split(':');
      let imageType = arrays[0];
      if (imageType == 'https') {
        this.image = true;
      } else {
        this.image = false;
      }
    }
  }

  displayImage(url) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  ObjectTransform(object: any, stage_id: any) {
    let data: any = Object.entries(object).reduce((acc, [key, value]: any) => {
      if (key == 'data' && value?.length) {
        let parsed = JSON.parse(value).reduce((data, current) => {
          if (
            current.fieldType != 'RFIDCODEWITHIMAGE' &&
            current.fieldType != 'BARCODEWITHIMAGE' &&
            current.fieldType != 'GEOCODEDIMAGE' &&
            current.fieldType != 'BARCODE' &&
            current.fieldType != 'RFIDCODE'
          ) {
            data[current.name] = current.values.join(',');
          } else if (
            current.fieldType == 'RFIDCODEWITHIMAGE' ||
            current.fieldType == 'BARCODEWITHIMAGE' ||
            current.fieldType == 'GEOCODEDIMAGE'
          ) {
            data[current.name] = current.values[0].fileUrl;
            let obj = {
              fieldName: current.name,
              lat: current.values[0].lat,
              lng: current.values[0].lng,
              stageId: stage_id,
            };
            this.latlongList.push(obj);
          } else if (
            current.fieldType == 'RFIDCODE' ||
            current.fieldType == 'BARCODE'
          ) {
            data[current.name] = current.values[0].value;
            let obj = {
              fieldName: current.name,
              lat: current.values[0].lat,
              lng: current.values[0].lng,
              stageId: stage_id,
            };
            this.latlongList.push(obj);
          }
          return data;
        }, {});
        Object.entries(parsed).forEach((values) => {
          acc[values[0]] = values[1];
        });
      } else if (key == 'user_details') {
        Object.entries(JSON.parse(value)).forEach((values) => {
          acc[values[0]] = values[1];
        });
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
    if (data['data'] == null) {
      delete data['data'];
    }
    return data;
  }

  camel2title(camelCase: any) {
    // no side-effects
    return (
      camelCase
        // inject space before the upper case letters
        .replace(/([A-Z])/g, function (match: any) {
          return ' ' + match;
        })
        // replace first char with upper case
        .replace(/^./, function (match: any) {
          return match.toUpperCase();
        })
    );
  }

  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  location(lat, lng) {
    this.common.params = {
      lat: lat,
      lng: lng,
    };
    const activeModal = this.modalService.open(MapModalComponent, {
      size: 'lg',
    });
  }

  updateAuditedActionStatus(status: any) {
    this.common.loading = true;
    let params = {
      id: this.refData.id,
      status: status,
    };
    this.api.get('audit/update-audited-action-status', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.type == 'Success') {
          this.alertService.success(res.message);
          // this.flag=true;
          this.closeModal();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

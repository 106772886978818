import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AddFormFieldModalComponent } from '../../modals/add-form-field-modal/add-form-field-modal.component';
import { RoleService } from 'src/app/@core/services/role.service';
import _ from 'lodash';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-form-field-mapping',
  templateUrl: './form-field-mapping.component.html',
  styleUrls: ['./form-field-mapping.component.scss'],
})
export class FormFieldMappingComponent implements OnInit {
  formTypesData: any;
  customFormsData: any;

  customformFieldData: any;
  formTypeName: string = null;
  customFormName: string;
  customFormId: number = null;
  lastRankingIndex: number = null;
  allSelectedItems: any[] = [];
  allItems: any[] = [];
  customformData: any[] = [];
  tempFields: any[] = [];
  removedFieldsMappingId: number[] = [];
  mappedFields = {
    FIXED: [],
    REMOVABLE: [],
  };

  searchText: string = '';
  isDisabled: boolean = true;

  restrictionScope: string = null;
  companyName: string = null;
  plantName: string = null;
  companyList: any = [];
  plantList: any = [];
  companyId: number = null;
  plantId: number = null;

  constructor(
    public userService: UserService,
    public common: CommonService,
    public api: ApiService,
    public modalService: NgbModal,
    public role: RoleService,
    public alert: AlertService,
    public breadcrumbService: BreadcrumbService,
    public plantService: PlantService
  ) {}

  ngOnInit(): void {
    this.getAllFormTypes();
    // this.getAllMasterFields();
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'form', url: null },
      {
        name: 'Form Field Mapping',
        link: null,
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);

    if (this.role.user_role == 'AXESTRACK') {
      this.restrictionScope = 'AXESTRACK';
      this.selectedRestriction(this.restrictionScope)
    } else if (
      this.role.user_role == 'COMPANYADMIN' ||
      this.role.user_role == 'COMPANYUSER'
    ) {
      this.companyId = this.userService._loggedInUser.companyId;
      this.restrictionScope = 'COMPANY';
      this.selectedRestriction(this.restrictionScope)
    } else {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
      this.restrictionScope = 'PLANT';
      this.selectedRestriction(this.restrictionScope)
    }
  }

  getAllMasterFields(companyId: number = -1, plantId: number = -1) {
    let body = {
      isActive: true,
      fieldEntity: 'CUSTOM FORM',
      plantId:plantId,
      companyId:companyId
    };
    this.common.loading = true;
    this.api.post('fields/allMasterFields', body).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.allSelectedItems = res.data;
        if (this.customFormId != null) {
          this.allSelectedItems = this.allSelectedItems.filter(
            (field) =>
              !this.customformFieldData.some(
                (setting) => setting.master_field_id == field.master_field_id
              )
          );
        }
        this.tempFields = this.allSelectedItems;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        id: id,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.plantList = res.data;
            resolve(this.plantList);
          }
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = -1;
    this.selectedRestriction('COMPANY');
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.selectedRestriction('PLANT');
  }

  selectedFormtype(selectedFormType: any) {
    this.formTypeName = selectedFormType.form_type_name;
    this.customFormName = null;
    this.getAllCustomForms();
  }
  selectedCustomForm(selectedCustomForm: any) {
    this.customFormName = selectedCustomForm.form_title;
    this.customFormId = selectedCustomForm.id;
    this.isDisabled = false;
    this.getLastRankingIndex();
    this.getFormFields();
  }
  getLastRankingIndex() {
    this.common.loading = true;
    let params = {
      formId: this.customFormId,
    };
    this.api
      .get('form/get-last-ranking-index-by-custom-form-id', params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.lastRankingIndex = res.data[0];
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  addEditField(editData: any) {
    const activeModal = this.modalService.open(AddFormFieldModalComponent, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
    });
    activeModal.componentInstance.refData = {
      userid: this.role.user_role_id,
      plantId: this.plantId,
      companyId: this.companyId,
      formId: this.customFormId,
      lastRankingIndex: this.lastRankingIndex,
    };
    activeModal.closed.subscribe((res: any) => {
      this.resetData();
      // this.getAllMasterFields(this.companyId,this.plantId);
    });
  }

  getAllFormTypes() {
    let params = {
      isActive: true,
    };
    this.common.loading = true;
    this.api.get('form/get-all-form-types', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.formTypesData = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllCustomForms() {
    let params = {
      formType: this.formTypeName,
      companyId: this.companyId,
      plantId: this.plantId,
      isActive:true
    };
    this.common.loading = true;
    this.api.get('form/get-custom-forms', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.customFormsData = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getFormFields() {
    let params = {
      formId: this.customFormId,
      plantId:this.plantId,
      companyId:this.companyId
    };
    this.common.loading = true;
    this.api.get('form/get-mapped-form-fields', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.customformFieldData = res.data;
        this.removedFieldsMappingId = [];
        this.groupingFormFieldsByFixedandRemovable(res.data);
        this.getAllMasterFields(this.companyId,this.plantId);
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  groupingFormFieldsByFixedandRemovable(formFields: any) {
    this.mappedFields = {
      FIXED: [],
      REMOVABLE: [],
    };

    if(this.restrictionScope == 'AXESTRACK'){
      this.mappedFields.REMOVABLE = formFields;
      this.allItems = this.mappedFields.REMOVABLE;
      return;
    }

    // formFields.forEach((field) => {
    //   if (field.type == 'FIXED') {
    //     this.mappedFields.FIXED.push(field);
    //   } else {
    //     this.mappedFields.REMOVABLE.push(field);
    //   }
    // });

    formFields.forEach((field) => {
      if (field.type == 'FIXED' && ((this.restrictionScope == 'COMPANY' && field.mapping_company_id == 1) || this.restrictionScope == 'PLANT') ) {
        this.mappedFields.FIXED.push(field);
      } else {
        this.mappedFields.REMOVABLE.push(field);
      }
    });
    this.allItems = this.mappedFields.REMOVABLE;
  }

  drop(event: CdkDragDrop<string[]>, id: string, from: string = '') {
    const draggedItem = event.item.data; // Access the dragged item

    //Storing Removed Mapping Ids if Exist
    if (this.removedFieldsMappingId.indexOf(draggedItem.form_mapping_id) != -1)
      this.removedFieldsMappingId.splice(
        this.removedFieldsMappingId.indexOf(draggedItem.form_mapping_id),
        1
      );

    //Find Index based on single list
    let currIndex =
      event.currentIndex * 2 + (event.container.id == 'left' ? 0 : 1);
    let preVindex =
      event.previousIndex * 2 + (event.previousContainer.id == 'left' ? 0 : 1);

    //Removing Element from previous container
    if (event.previousContainer.id == 'outer_list') {
      this.tempFields.splice(this.tempFields.indexOf(draggedItem), 1);
      this.searchFieldName();
    } else {
      this.allItems.splice(preVindex, 1);
    }

    //Adding Element to container
    this.allItems.splice(currIndex, 0, draggedItem);
  }

  removeFields(item: any, index: number) {
    let value = this.allItems[index];
    this.removedFieldsMappingId.push(item.form_mapping_id);
    this.tempFields.push(value);
    this.searchFieldName();
    this.allItems.splice(index, 1);
  }

  searchFieldName(event: any = '', eventTrigger: boolean = false) {
    this.searchText = eventTrigger
      ? event.target.value.trim()
      : this.searchText;
    if (this.searchText != '') {
      this.allSelectedItems = this.tempFields.filter((field) =>
        field.field_name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    } else {
      this.allSelectedItems = this.tempFields;
    }
  }

  changeStatus(item: any, key: string) {
    item[key] = !item[key];
  }

  changeType(item: any, key: string) {
    item.type = item[key] == 'FIXED' ? 'REMOVABLE' : 'FIXED';
  }

  changeShowStatus(item: any, ind: number) {
    let i = item.savegroups.indexOf(ind);
    if (i == -1) {
      item.savegroups.push(ind);
    } else {
      item.savegroups = item.savegroups.filter((element) => element !== ind);
    }
  }

  changeUniqueStatus(item: any, ind: number) {
    let i = item.uniqueGroups.indexOf(ind);
    if (i == -1) {
      item.uniqueGroups.push(ind);
    } else {
      item.uniqueGroups = item.uniqueGroups.filter(
        (element) => element !== ind
      );
    }
  }

  resetData() {
    this.customFormId == null ? this.selectedRestriction(this.restrictionScope,false) : this.selectedCustomForm({
      form_title: this.customFormName,
      id: this.customFormId,
    });
  }

  saveAPI() {
    if (this.customFormId == null)
      return this.alert.warn('Please Select Form First !!');

    let params = {
      formFieldMappings: this.allItems.map((item, index) => {
        return {
          fieldRankingIndex: this.mappedFields.FIXED.length + index + 1,
          type: item.type ? item.type : "REMOVABLE",
          isMandatory: item.is_mandatory,
          isUnique: item.is_unique,
          fieldId: item.master_field_id,
          formId: this.customFormId,
        };
      }),
      removeMappingIds: this.removedFieldsMappingId,
      companyId: this.companyId,
      plantId: this.plantId,
      formId: this.customFormId,
    };
    this.api.post('form/save-multi-form-field-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alert.success(res.message);
        } else {
          this.alert.info(res.message);
        }
        this.common.loading = false;
        this.resetData();
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedRestriction(scope: any,reset:boolean=true) {
    reset ? this.resetFormLayout() : true;
    scope = scope;
    if (scope == 'AXESTRACK') {
      this.companyId = 1;
      this.plantId = 1;
      this.companyName = null;
      this.getCompany();
      this.plantList = null;
      this.getAllMasterFields(-1, -1);
    } else if (scope == 'COMPANY') {
      this.plantId = null;
      this.plantName = null;
      this.plantList = null;
      this.getAllMasterFields(this.companyId, -1);
      this.getPlantById(this.companyId)
    } else {
      this.getAllMasterFields(this.companyId, this.plantId);
    }
    this.restrictionScope = scope;
  }

  resetFormLayout(){
    this.mappedFields = {
      FIXED: [],
      REMOVABLE: [],
    };
    this.allItems = [];
    this.tempFields = [];
    this.customFormId = null;
    this.customFormName = null;
    this.removedFieldsMappingId = [];
    this.formTypeName = null;
  }

  async resetDetail() {
    this.resetFormLayout();
    if (this.role.user_role == 'AXESTRACK') {
      this.restrictionScope = 'AXESTRACK';
      this.plantId = 1;
      this.companyId = 1;
      this.plantName = null;
      this.plantList = null;
      this.getCompany()
      this.getAllMasterFields(-1, -1);
    } else if (
      this.role.user_role == 'COMPANYADMIN' ||
      this.role.user_role == 'COMPANYUSER') {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = null;
      this.plantName = null;
      this.plantList = null;
      this.restrictionScope = 'COMPANY';
      await this.getPlantById(this.userService._loggedInUser.companyId);
      this.getAllMasterFields(this.companyId, -1);
    } else {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
      this.restrictionScope = 'PLANT';
      this.getAllMasterFields(this.companyId, this.plantId);
    }
  }
}

<div class="card table-card">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">{{"status_wise_overview" | customTranslate}}</h3>
    </div>
  </div>

  <div class="card-body">
    <div class="right-menu">
      <mat-form-field appearance="fill">
        <mat-label>{{"assign_by" | customTranslate}}</mat-label>
        <mat-select [(value)]="selectedAssignByName" (selectionChange)="selectedAssignBy($event)">
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Assign By" ngModel (ngModelChange)="filterUserList($event)"
              noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let  item of userList;index as i" [value]="item.userName">
            {{item.userName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{"assign_to" | customTranslate}}</mat-label>
        <mat-select [(value)]="selectedAssignToUser" (selectionChange)="selectedAssignTo($event)">
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Assign To" ngModel (ngModelChange)="filterUserList($event)"
              noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let  item of userList;index as i" [value]="item.userName">
            {{item.userName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{"status" | customTranslate}}</mat-label>
        <mat-select [(value)]="selectedStatus">
          <mat-option (click)="getStatusWiseLogs('Pending')" value="Pending">
            {{'Pending'}}
          </mat-option>
          <mat-option (click)="getStatusWiseLogs('Approved')" value="Approved">
            {{'Approved'}}
          </mat-option>
          <mat-option (click)="getStatusWiseLogs('Rejected')" value="Rejected">
            {{'Rejected'}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <table class="custom-table table-scroll tat-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th>{{"serial_no" | customTranslate}}</th>
          <th>{{("Checklist" | customTranslate)+' '+("title"|customTranslate)}}</th>
          <th>{{"assign_to" | customTranslate}}</th>
          <th>{{"assign_by" | customTranslate}}</th>
          <th>{{"start_time" | customTranslate}}</th>
          <th>{{"end_time" | customTranslate}}</th>
          <th>{{"tolerance" | customTranslate}}</th>
          <th>{{"perform_date_time" | customTranslate}}</th>
          <th>{{"perform_status" | customTranslate}}</th>
          <th>{{"Actions" | customTranslate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of statusWiseLogs;index as i">
          <td>{{i+1}}</td>
          <td> {{item.checklist_title}} </td>
          <td>{{item.assigned_to_user}}</td>
          <td>{{item.assigned_by_user}}</td>
          <td>{{item.start_time}}</td>
          <td>{{item.end_time}}</td>
          <td>{{common.minutesToHours(item.tolerance)}}</td>
          <td>{{selectedStatus=='Pending'?'-':item.perform_time| date:'dd-MM-YYYY hh:mm'}}</td>
          <td><span class="badge status-badge" style="background-color: #f7d5d5;"
              [ngClass]="{'badge-success':item.perform_status == 'on-time','badge-danger':item.perform_status== 'delay','badge-secondary':selectedStatus== 'Pending'}">{{(selectedStatus=='Pending'?(selectedStatus|customTranslate):(item.perform_status|customTranslate)+'
              '+getDelayedTime(item.perform_status,item.perform_time,item.end_time))}}</span></td>
          <td><span *ngIf="selectedStatus!='Pending'"><button class="btn btn-primary"
                (click)="viewCheckList(item)">{{"view"|customTranslate}}</button> <button *ngIf="item.late_remark_id!=null"
                class="btn btn-danger" style="margin-left: 5px;
                  background-color: #e85151;" (click)="viewRemark(item)"> {{"remark" | customTranslate}} </button></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

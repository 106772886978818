<div class="modal-body">
  <div class="icon_img">
    <img src="assets/images/logout_check.png">
  </div>

  <!-- [innerHTML]="description|customTranslate" -->
  <div class="text_logout">
    <h1>Are You Sure?</h1>
    <p>Do you really want to logout</p>
  </div>

  <div class="button_logout">
    <button class="btn_cancel" type="button" outline (click)="closeModal(false,0)" id="cancel"
      style="cursor: pointer;">{{btn2|customTranslate}}</button>
    <button class="btn_confirm" type="button" outline (click)="closeModal(true,0)" id="submit"
      style="cursor: pointer;">{{btn1|customTranslate}}</button>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';

@Component({
  selector: 'app-assigned-trip-info-modal',
  templateUrl: './assigned-trip-info-modal.component.html',
  styleUrls: ['./assigned-trip-info-modal.component.scss']
})
export class AssignedTripInfoModalComponent implements OnInit {

  @Input() public refData: any;
  constructor(
    public activeModal: NgbActiveModal,
    public common: CommonService,
  ) { }

  ngOnInit() {
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

}

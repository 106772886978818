import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { PlantService } from '../plant/plant.service';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { validator } from 'src/app/dataType/commonInterface';
import { CompanyData, Manufacturer, PlantData } from 'src/app/dataType/assetInterface';

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.scss']
})
export class ManufacturerComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Manufacturer List');

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }


  // declaring variables
  plantLevelRoles = ['AXESTRACKUSER' , 'COMPANYUSER' , 'PLANTADMIN', 'PLANTUSER'];
  manufacturer={
    manufacturerId:null,
    manufacturerName:'',
    manufacturerDescription:'',
    status:'ACTIVE',
    plantId:null,
    companyId:null
  }
    manufacturerList:Manufacturer[]=[];
    companyData:CompanyData[]=[];
    companyName:String='';
    plantData:PlantData[] = [];
    plantName:String='';
    buttonName='save';
    manufacturerNameValidator:validator=null;



    constructor(
      public userService: UserService,
      public common: CommonService,
      public table: TableService,
      public api: ApiService,
      public modalService: NgbModal,
      public breadcrumbService: BreadcrumbService,
      public role: RoleService,
      public plantService : PlantService,
      public alert : AlertService,
    ) {
      let breadcumbs = [
        { name: 'Home', url: '/user/dashboard' },
        { name: 'Assets', url: null },
        { name: 'Manufacturer', link: '/user/manufacturer' },
      ];
      this.breadcrumbService.getBreadcrumbs(breadcumbs);
     }


// nghooks
    ngAfterViewInit() {
      this.dtTrigger.next();
    }
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
      this.getManufacturer();
      // first calling the company;
      if(this.role.user_role=='AXESTRACK'){
        this.getCompany();
      }
      if(this.role.user_role=='COMPANYADMIN'){
        this.getPlants(this.userService._loggedInUser.companyId);
      }
    }



// custom functions
  getCompany(){
    this.common.loading=true;
    let params={
      all_company:false
    }
    this.api.get('company/all_company',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
      );
    }


  selectedCompany(event){
    this.manufacturer.companyId=event.companyId;
    this.companyName = event.companyName;
    this.getPlants(event.companyId);
  }

  selectedPlant(event){
    this.manufacturer.plantId=event.plantId;
    this.plantName = event.plantName;
  }

  getPlants(id?:number) {
      this.common.loading = true;
      let params = {
        id: id
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.plantData = res.data;
          console.log(this.plantData)
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
        }
    );
  }

  getManufacturer(){
    this.common.loading = true;
    let params={
      allManufacturers:true
    }
    this.api.get("manufacturer/get-manufacturer",params).subscribe(
      (res:any)=>{
        this.common.loading = false;
        this.manufacturerList=res.data;
        this.renderTable();
      },
      (err:any)=>{
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  saveManufacturer(){
    let params= {
      manufacturerId : this.manufacturer.manufacturerId,
      companyId: this.manufacturer.companyId,
      plantId:this.manufacturer.plantId,
      manufacturerName:this.manufacturer.manufacturerName.trim(),
      manufacturerDescription:this.manufacturer.manufacturerDescription.trim(),
      status:this.manufacturer.status,
      userRole :  this.role.user_role
    }
    this.api.post('manufacturer/save-manufacturer',params).subscribe(
      (res: any) => {
        if(res.status){
          if(res.message=="Name Already Exists"){
            this.alert.warn(res.message);
          }
          else{
            this.alert.success(res.message);
            this.reset();
          }
        }
        this.common.loading = false;
        this.getManufacturer();
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );

  }

  reset(){
    this.manufacturer={
        manufacturerId:null,
        manufacturerName:'',
        manufacturerDescription:'',
        status:'ACTIVE',
        plantId:null,
        companyId:null
      }

    this.manufacturer.manufacturerId=null;
    this.manufacturer.manufacturerName='';
    this.manufacturer.manufacturerDescription='';
    this.manufacturer.status='ACTIVE';
    this.manufacturer.plantId=null;
    this.manufacturer.companyId=null;
    this.manufacturerNameValidator=null;
    this.plantData=null;
    this.companyName = null;
    this.plantName = null;
    this.buttonName='save';
  }

  viewManufacturerDetails(row?:any){
  console.log('row: ', row);
    const ManufacturerDetails={
      company_name: row.company_name,
      plant_name: row.plant_name,
      status: row.status,
      manufacturer_description: row.manufacturer_description,
      manufacturer_name: row.manufacturer_name,
    }
    this.common.params = { details: [ManufacturerDetails], title: 'Manufacturer Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

    editManufacturer(row?:any){
     this.reset();
     this.common.gotoTop();
     this.buttonName='update';
     this.manufacturer.companyId=row.company_id;
     this.manufacturer.plantId=row.plant_id;
     this.manufacturer.manufacturerId=row.manufacturer_id;
     this.manufacturer.manufacturerName=row.manufacturer_name;
     this.manufacturer.manufacturerDescription=row.manufacturer_description;
     this.manufacturer.status=row.status;
    if(!(row.company_id==1 && row.plant_id==1)){
      this.plantName = row.plant_name;
      this.companyName = row.company_name;
    }
    this.role.user_role=='AXESTRACK'?this.getPlants(row.company_id):(this.role.user_role=="COMPANYADMIN"?this.getPlants(this.userService._loggedInUser.companyId):null);
  }

  confirmDeleteManufacturer(row?:any){
    this.common.params = {
      title: 'Delete Manufacturer ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row.manufacturer_name +
        ' ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });
  }

  delete(row: any) {
    let param={
      manufacturerId:row.manufacturer_id
    }
    this.api.get("manufacturer/delete-manufacturer",param).subscribe(
      (res:any)=>{
        this.common.loading=false;
        if(res.status){
        console.log('res.status: ', res.status);
          if(res.message==="Manufacturer Mapped to Some Asset"){
            this.alert.warn(res.message);
          }
          else{
            this.alert.success(res.message);
          }
          this.getManufacturer();
        }
      },
      (err:any)=>{
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }


  formValidator(){
    this.manufacturerNameValidator=this.common.nullValidator(
      this.manufacturer.manufacturerName.trim(),'Manufacturer Name Mandatory'
      );
    if(!this.manufacturerNameValidator.error){
      this.saveManufacturer();
    }

  }

}

import { TokenStorageService } from './../../@core/services/token-storage.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { CryptoService } from 'src/app/@core/services/crypto.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { VisitorAppointmentComponent } from '../visitor-appointment/visitor-appointment.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-visitor-login',
  templateUrl: './visitor-login.component.html',
  styleUrls: ['./visitor-login.component.scss'],
})
export class VisitorLoginComponent implements OnInit {
  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(
    evt: KeyboardEvent
  ) {
    if(this.showGenerateOtp || !this.isShow){
      this.generateAndResetOtp();
    }else{
      this.verifyOtp();
    }
  }
  public mobileNumber: string;
  public enteredOtp: String;
  public isShow: Boolean = false;
  companyId: number = null;
  plantId: number = null;
  disableMobileNumber: boolean = false;
  showWalkIn: boolean = false;
  walkInMode: any = false;
  disableOtpInput:any=false

  constructor(
    private api: ApiService,
    private router: Router,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private cryptoService: CryptoService,
    private common: CommonService,
    private modalService:NgbModal,
    private tokenStorageService:TokenStorageService
  ) {
    this.userService.setUserDetail();
    this.breadcrumbService.setActiveBreadCrumb(false);
    this.decryptCompanyAndPlantId();
  }
  visitorId: number = null;
  showGenerateOtp: boolean = true;
  timeLeft: number = 180;
  timerInterval: any;
  isTimerActive: boolean = false;
  showOtpInput:boolean = false
  showVerifyOtp:boolean = false
  hideWalkIn:boolean = false

  ngOnInit(): void {}

  async decryptCompanyAndPlantId() {
    this.common.loading = true;

    let refToken = decodeURI(this.route.snapshot.queryParams.ref);
    if (refToken != null && refToken!='undefined' && refToken!=undefined ){
      this.hideWalkIn=true
      this.companyId = Number.parseInt(
        await this.cryptoService.decryptData(refToken.split('@@')[0])
      );

      this.plantId = Number.parseInt(
        await this.cryptoService.decryptData(refToken.split('@@')[1])
      );
      if (this.companyId == null || this.plantId == null) this.routeNagivate();
    } else if (this.userService._walkinMode) {
      this.companyId = this.userService._companyId;
      this.plantId = this.userService._plantId;
      if (this.companyId == null || this.plantId == null)
        this.routeNagivate(false);
    } else {
      this.routeNagivate(false);
    }

    this.common.loading = false;
  }
  handleWalkIn() {
    this.walkInMode = !this.walkInMode;
  }

  startTimer() {
    clearInterval(this.timerInterval);
    this.isTimerActive = true
    this.timerInterval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.isShow = false;
        this.disableOtpInput = true
      }
    }, 1000);
  }

  getFormattedTime() {
    const minutes: number = Math.floor(this.timeLeft / 60);
    const seconds: number = this.timeLeft % 60;
    return `${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  padZero(num: number) {
    return num < 10 ? `0${num}` : num;
  }

  generateAndResetOtp() {
    this.timeLeft = 180;
    if (this.mobileNumber == null || this.mobileNumber.length != 10) {
      return this.alertService.error('Please Enter Valid Mobile Number !!');
    }
    let body = {
      mobileNo: this.mobileNumber,
      plantId: this.plantId,
      companyId: this.companyId,
      walkInMode:this.userService._walkinMode
    };
    this.common.loading = true
    this.api.post('visitors/add_visitor_login', body).subscribe(
      (res: any) => {
        if (res.data[0].otpStatus != false) {
          this.common.loading = false;
          this.isShow = true;
          this.showGenerateOtp = false;
          this.showOtpInput = true;
          this.alertService.success('OTP SENT');
          this.startTimer();
          this.disableMobileNumber = true;
          this.showVerifyOtp = true;
        } else {
          this.alertService.warn(res.data[0].message);
        }
      },
      (err: any) => {
          this.common.loading = false
      }
    );
  }
  verifyOtp() {
    if (this.enteredOtp == null) {
      return this.alertService.error('Please Enter OTP');
    }
    if (this.enteredOtp.trim()) {
      let params = {
        mobileNumber: this.mobileNumber,
        plantId: this.plantId,
        companyId: this.companyId,
        enteredOtp: this.enteredOtp.trim(),
      };

      this.common.loading = true
      this.api
        .get('visitors/get_otp_by_mob_no', params)
        .subscribe((res: any) => {
          this.common.loading = false;
          if (res.status && res.data[0].responseType == 'success') {
            this.setUserTypeAndLocalStorage(res.data[0]);
            this.alertService.success('Login Successfully !!');
          } else {
            this.alertService.error('Invalid Otp');
          }
        },
        (err: any) => {
            this.common.loading = false
        });
    }
  }

  routeNagivate(cplNagvigate: boolean = true) {
    if (cplNagvigate) {
    this.tokenStorageService.signOut();

    return this.router.navigate(['auth/login']);
  }
    return this.router.navigate(['auth/visitor-login']);
  }

  setUserTypeAndLocalStorage(data: any) {
    this.userService._details = data;
    this.userService._token = data.accessToken;
    this.userService._name = data.username;
    this.userService._loginTime = data.loginTime;
    this.userService._sessionId = data.sessionId;
    this.userService._userType = data.userType;

    this.userService._companyId = data.companyId;
    this.userService._plantId = data.plantId;
    this.userService._details._companyId = data.companyId;
    this.userService._details._plantId = data.plantId;
    //Setting Default Login Credentials
    this.userService._details = data;
    this.userService._loggedInUser.id = data.id;
    this.userService._token = data.accessToken;
    this.userService._name = data.username;
    this.userService._loginTime = data.loginTime;
    this.userService._sessionId = data.sessionId;
    this.userService._userType = data.userType;
    this.userService._isStaffLoggined = true;
    // this.userService._walkinMode = this.walkInMode

    this.userService._details._companyId = data.companyId;
    this.userService._details._plantId = data.plantId;
    // const now = new Date();
    // const item = {expiry: now.getTime() + 900000,};
    localStorage.setItem('userName', data.username);
    localStorage.setItem('loginTime', data.loginTime);
    localStorage.setItem(
      'xsights_authkey',
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MzEzNjUsImZvaWQiOjEsIm5hbWUiOiJTYWxvbmkgQmFuc2FsIiwibW9iaWxlbm8iOjc3MzczNTk2OTUsImVtYWlsIjoic2Fsb25pLmJhbnNhbEBheGVzdHJhY2suY29tIiwidGltZSI6IjIwMjItMTItMDdUMTQ6Mzc6NTArMDU6MzAifQ.80BOFsdlr81fj_gV41BqcbjDQfPlqyKDDiyaqceBgRE'
    );
    localStorage.setItem('xsights_entrymode', '1');
    localStorage.setItem('VISITOR_TOKEN', this.userService._token);
    localStorage.setItem('USER_TOKEN', null);
    // localStorage.setItem('expiryTime', JSON.stringify(item));
    // localStorage.setItem('visitor_company_id', data.companyId);
    // localStorage.setItem('visitor_plant_id', data.plantId);
    localStorage.setItem('walkin_mode',this.walkInMode)
    localStorage.setItem('user_type', data.userType);
    localStorage.setItem(
      'USER_DETAILS',
      JSON.stringify(this.userService._details)
    );

    if(this.userService._walkinMode){
      console.log("hello1")
        this.openAddVisitor();
    }
    else if(this.walkInMode){
      console.log("hello2")

      window.location.reload();
    }
    else{
      console.log("hello3")

      this.router.navigate(['/visitor/visitor-appointment']);
      console.log("hello4")

    }

  }


openAddVisitor() {
  let activeModal = this.modalService.open(VisitorAppointmentComponent, {
    ariaLabelledBy: 'modal-basic-title',
    size: 'l',
    backdrop:'static',
    keyboard:false
  });
  // this.modalHide=true;

  activeModal.componentInstance.toggle = true;
  activeModal.result.then((data: any) => {
    //for submit
      localStorage.removeItem('VISITOR_TOKEN');
      // window.location.reload();
      this.reInitialisePage();


  });
}

reInitialisePage(){
  this.mobileNumber=null ;
  this.enteredOtp=null ;
  this.isShow = false;
  this.disableMobileNumber = false;
  this.showWalkIn = false;
  this.walkInMode = false;
  this.disableOtpInput=false
  this.visitorId = null;
  this.showGenerateOtp = true;
  this.timeLeft = 180;
  this.timerInterval=null
  this.isTimerActive = false;
  this.showOtpInput = false
  this.showVerifyOtp = false
}
signOutWalkInMode(){
  this.tokenStorageService.signOut();
  window.location.reload();
}
}

import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { PlantService } from '../plant/plant.service';
import { UserService } from 'src/app/@core/services/user.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-user-tag-mapping',
  templateUrl: './user-tag-mapping.component.html',
  styleUrls: ['./user-tag-mapping.component.scss']
})
export class UserTagMappingComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtTrigger1: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'user list');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getUserList();
    this.getTableData();
  }

  ngOnDestroy(): void {
    // this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  renderStaffTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger1.next();
    });
  }

  title = 'User Tag Mapping';
  btn = 'save';
  userList: any = [];

  uniqueTagCode:'';
  uniqueTagType:'';
  tagType = ['PASSIVETAGS', 'BLETAGS', 'ACTIVETAGS'];

  plantId:null;
  companyId:null;
  companyName: null;
  plantName: null;
  companyData: any = [];
  plantData: any = [];
  userId:number = null;
  userName:String =null;


  staffType:any='USER';
  staffTypeName:String = null;
  staffName:String = null;
  staffTypeList:any[]=[];
  staffTypeId:number;
  staffList:any[]=[];
  staffId:number=null;
  userMappingList:any[]=[];
  staffMappingList:any[]=[];
  tabData: any = [
    { tabname: 'User', value: 'USER' },
    { tabname: 'Staff', value: 'STAFF' },
  ];

  constructor(
    private alertService: AlertService,
    private api: ApiService,
    private plantService: PlantService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService
  ) {
    this.getCompany();
    this.btn = 'save';
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'User-Group', url: null },
      { name: 'User Tag Mapping', url: '/user/users' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/


    if (this.role.user_role != 'AXESTRACK') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
  }

  ngOnInit(): void {
    this.dtTrigger.next();
    this.getCompany();
  }


 selectedUser(event:any){
  this.userName=event.userName;
  this.userId=event.userid;
 }
 onSelectEmployeeType(event:any){
  console.log("event",event);
    this.staffTypeId=event.id;
    this.staffTypeName=event.type_name;
    this.getUserList();
 }
 onSelectEmployee(event:any){
  console.log(event,"this the event")
    this.staffName=event.first_name;
    this.staffId=event.staff_id;
 }
  onSelectStaff(){
     this.resetDetail();
     this.getCompany();
     this.getStaffTypeList();
     this.getUserList();
     this.getTableData();
  }
  getStaffTypeList(){
    this.common.loading=true;
    let params={
      allTypes:true
    }
    this.api.get('staff/get-staff-type-list',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.staffTypeList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }


  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
          resolve(this.plantData);
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
        reject(null);
      }
    );
  });
  }



  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = null;
    this.getUserList();
  }



  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getUserList();

  }

  getUserList() {
    this.common.loading = true;

    if(this.staffType=='USER'){
      let params={
         companyId:this.companyId,
         plantId:this.plantId,
         userRole:this.role.user_role
      }
      this.api.get('user/get-user-list',params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.userList = res.data;
            console.log("this.userList",this.userList);
          }
          this.renderTable();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    }
    else{
      let params={
        allStaff:true,
        staffTypeId:this.staffTypeId
      }
      console.log("params",params);
      this.api.get('staff/all-staff',params).subscribe(
        (res:any)=>{
          if(res.status){
            this.common.loading=false;
            this.staffList = res.data.map(item => ({ ...item , departments : JSON.parse(item.departments) })) || [];
            console.log("this.staffList",this.staffList);
          }
          this.renderTable();
        },
        (err:any)=>{
          console.error('Error:',err);
          this.common.loading=false;
        }
      )
    }
  }

  getTableData(){
    this.common.loading = true;
    if(this.staffType=='USER'){
      console.log("3e4rt567");
      let params={
        entityType:'USER'
      }
      this.api.get('tag-mapping/get-tag-mapping-list',params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.userMappingList = res.data;
            console.log("this.userMappingList",this.userMappingList);
          }
          this.renderTable();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    }
    else{
      let params={
        entityType:'STAFF'
      }
      this.api.get('tag-mapping/get-tag-mapping-list',params).subscribe(
        (res:any)=>{
          if(res.status){
            this.common.loading=false;
            this.staffMappingList = res.data;
            console.log("this.staffMappingList",this.staffMappingList);
          }
          this.renderTable();
        },
        (err:any)=>{
          console.error('Error:',err);
          this.common.loading=false;
        }
      )
    }
  }


  saveData() {
    this.common.loading = true;
    let params:any;
    let apiUrl:any;
    if(this.staffType=='USER'){
       params = {
         entityId:this.userId,
         tagId:this.uniqueTagCode,
         entityType:'USER',
         tagType:this.uniqueTagType,
         userId:this.userService._loggedInUser.id

      };
       apiUrl = 'tag-mapping/save-tag-mapping';
    }
    else{
      params={
        entityId:this.staffId,
        tagId:this.uniqueTagCode,
        entityType:'STAFF',
        tagType:this.uniqueTagType,
        userId:this.userService._loggedInUser.id
      }
      apiUrl = 'tag-mapping/save-tag-mapping';
    }
    console.log(apiUrl,"this is apiurl");
    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertService.success(res.message);
          this.resetDetail();
          this.getTableData();
        } else {
          this.common.loading = false;
          this.alertService.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  resetDetail() {
    console.log("this.ScompanyNamedfghj", this.companyName);
    if(this.staffType=='USER'){
      this.title = 'User Tag Mapping';
      this.btn = 'save';
      this.userList = [];

      this.uniqueTagCode='';
      this.uniqueTagType='';

      this.plantId=null;
      this.companyId=null;
      this.companyName= null;
      this.plantName= null;
      this.companyData = [];
      this.plantData = [];
      this.userId = null;
      this.userName=null;
      this.staffType='USER';
      this.getCompany();
      this.getUserList();
      this.typeValidator=null;
      this.uniqueTagCodeValidator=null;
      this.uniqueTagTypeValidator=null;

    }
    else{

      this.title = 'Staff Tag Mapping';
      this.btn = 'save';
      this.userList
      this.uniqueTagCode='';
      this.uniqueTagType='';

      this.plantId=null;
      this.companyId=null;
      this.companyName= null;
      this.plantName= null;
      this.companyData = [];
      this.plantData = [];
      this.staffType='STAFF';
      this.staffTypeName=null;
      this.staffName=null;
      this.staffTypeList=[];
      this.staffTypeId=null;
      this.staffList=[];
      this.staffId=null;
      this.getCompany();
      this.getStaffTypeList();
      this.getUserList();
      this.staffNameValidator=null;
      this.typeValidator=null;
      this.uniqueTagCodeValidator=null;
      this.uniqueTagTypeValidator=null;

    }

  }

  confirmAlert(row?: any) {
  console.log('delete row: ', row);
    this.common.params = {
      title: 'Delete User',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteData(row);
      }
    });
  }


  deleteData(row?: any) {
    console.log(row,"this.is delete row");
    this.common.loading = true;
    let params={
      entityId:row.user_id,
      tagId:row.tag_id,
      entityType:this.staffType=='USER' ? 'USER' : 'STAFF',
      tagType:row.tag_type,
    }
    this.api.post('tag-mapping/delete-tag-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertService.success(res.message);
          this.getTableData();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.alertService.warn(err.error.message);
        this.common.loading = false;
      }
    );
  }



  async editUser(row?: any) {
    console.log(row,"this is the row")
    this.common.gotoTop();
    this.resetDetail();
    await this.getPlantById(row.company);
    this.companyName = row.company_name
    this.plantName = row.plant_name
    this.companyId = row.company_id
    this.plantId = row.plant_id;
    this.uniqueTagCode=row.tag_id;
    this.uniqueTagType=row.tag_type;
    this.userId=row.user_id;
    this.userName=row.user_name;
    this.btn = 'update';
    this.title = 'Edit User Tag Mapping';
  }

  async editEmployee(row?:any){
    this.common.gotoTop();
    this.resetDetail();
    // this.staffType='STAFF';
    await this.getPlantById(row.company_id);
    this.companyId = row.company_id;
    this.plantId = row.plant_id;
    this.companyName = row.company_name;
    this.plantName = row.plant_name;
    this.uniqueTagCode=row.tag_id;
    this.uniqueTagType=row.tag_type;
    this.staffTypeId=row.staff_type_id;
    this.staffName=row.staff_name;
    this.staffId=row.staff_id;
    this.staffTypeName=row.type_name;
    this.btn = 'update';
    this.title = 'Edit Staff Tag Mapping';
  }
  staffNameValidator:any;
  typeValidator: any;
  uniqueTagCodeValidator: any;
  uniqueTagTypeValidator: any = false;

  formValidator() {
    console.log('sdfg',this.staffType);

    if(this.staffType=='STAFF'){

      this.typeValidator = this.common.nullValidator(
        this.staffTypeName,
        'Staff Type Mandatory'
      );
      this.staffNameValidator = this.common.nullValidator(
        this.staffName,
        'User Name Mandatory'
      );
      this.uniqueTagCodeValidator = this.common.nullValidator(
        this.uniqueTagCode,
        'Unique Tag Code Mandatory'
      );
      this.uniqueTagTypeValidator = this.common.nullValidator(
        this.uniqueTagType,
        'Unique Tag Type Mandatory'
      );
console.log("this .is staff form validator")
      if(!this.typeValidator.error&&
        !this.staffNameValidator.error&&
        !this.uniqueTagCodeValidator.error&&
        !this.uniqueTagTypeValidator.error){
         this.saveData();
    }

    }
    else{

      this.typeValidator = this.common.nullValidator(
        this.userId,
        'User Name Mandatory'
      );

      this.uniqueTagCodeValidator = this.common.nullValidator(
        this.uniqueTagCode,
        'Unique Tag Code Mandatory'
      );
      this.uniqueTagTypeValidator = this.common.nullValidator(
        this.uniqueTagType,
        'Unique Tag Type Mandatory'
      );

    if(!this.typeValidator.error&&
        !this.uniqueTagCodeValidator.error&&
        !this.uniqueTagTypeValidator.error){
        this.saveData();
      }

      }

}
}

<div class="card">
<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">

          <div class="card-header mb-1">
            <div class="header-block mb-2">
              <h3 class="card-title">
                {{title | translate}}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row">



              <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input" class="required">{{"first_name" | translate}}</label>
                <ng-container>
                  <input type="text" class="form-control" name="firstName" required placeholder="Enter First Name"
                    [(ngModel)]="visitor.firstName" autocomplete="off" (change)="setUserName()">
                </ng-container>
                <div class="error" *ngIf="staffFirstNameValidator && staffFirstNameValidator.error">
                  <span>{{staffFirstNameValidator.msg}}</span>
                </div>
              </div>



              <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input" class="required">{{"last_name" | translate}}</label>
                <ng-container>
                  <input type="text" class="form-control" name="lastName" placeholder="Enter Last Name"
                    [(ngModel)]="visitor.lastName" autocomplete="off" (change)="setUserName()">
                </ng-container>

                <div class="error" *ngIf="staffLastNameValidator && staffLastNameValidator.error">
                  <span>{{staffLastNameValidator.msg}}</span>
                </div>
              </div>


              <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input" class="required">{{"Gender" | translate}}</label>
                <select name="gender" id="#gender" class="form-control" [disabled]="editFlag" [(ngModel)]="visitor.gender">
                  <option selected="Male" value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input" class="required">{{"mobile_no." | translate}}</label>
                <input type="tel" class="form-control" name="mobile" placeholder="Enter Mobile No."
                  [(ngModel)]="visitor.mobile" required #mobile="ngModel" autocomplete="off" maxlength="10">
                <div class="error" *ngIf="mobileValidator && mobileValidator.error">
                  <span>{{mobileValidator.msg}} </span>
                </div>
              </div>

              <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input">{{"email_id" | translate}}</label>
                <input type="email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" class="form-control"
                  autocomplete="off" placeholder="Enter Email" [(ngModel)]="visitor.email" required name="email"
                  #email=ngModel>
                <div class="error" *ngIf="emailValidator && emailValidator.error">
                  <span>{{emailValidator.msg}} </span>
                </div>
              </div>

              <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input" class="required">{{"status" | translate}}</label>
                <select name="user_status" id="#user_status" class="form-control" [disabled]="editFlag"
                  [(ngModel)]="visitor.status">
                  <option selected="ACTIVE" value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>

                </select>
              </div>

              <div class="error" *ngIf="statusValidator && statusValidator.error">
                <span>{{statusValidator.msg}} </span>
              </div>





              <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input"> {{"Department" | translate}} </label>
                <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedDepts($event)" [data]="deptList"
                  display="dept_name" placeholder="Choose Department" [preSelected]="selectedDeptNames">
                </ps-auto-suggestion>
              </div>


            </div>

            <div class="form-group action-block">
              <div class="btn-block">
                <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    translate}}</button>
                <button class="btn btn-warning" (click)="resetDetail()">{{"reset" | translate}}</button>
                <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn}} </button>
              </div>
            </div>
          </div>



          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <div class="header-block">

                    <h3 class="card-title">
                      {{"Visitor List" | translate}}
                    </h3>
                  </div>
                </div>

                <div class="card-body">

                  <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead class="thead-light">
                      <tr>
                        <th>{{"S.NO" | translate}}</th>
                        <th>{{"name" | translate}}</th>
                        <th>{{"mobile_no." | translate}}</th>
                        <th>{{"Department"|translate}}</th>

                        <th>{{"status" | translate}}</th>
                        <th class="action">{{"action" | translate}}</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of tableData let i = index">
                        <td>{{i+1}}</td>
                        <td>{{row.first_name+" "+ row.last_name}}</td>
                        <td>{{row.mobile_number}}</td>
                        <td>{{formatZoneNames(row.departments)}}</td>



                        <td ><span class="pill"
                            [ngClass]="{'bg-yellow-100 text-yellow-800':row.staff_status=='INACTIVE','bg-green-100 text-green-800':row.staff_status=='ACTIVE','bg-red-100 text-red-800':row.staff_status=='BLOCKED'}">

                            {{row.staff_status}}
                          </span></td>
                        <td class="action">
                          <div class="btn-block">
                            <button class="btn btn-primary" [style]='"background-color:rgb(216 3 3)"'
                              *ngIf="staffType!='STAFF' && row.staff_status!='BLOCKED'"
                              (click)="confirmAlertForUserBan(row.staff_id,'block') ">{{"BLOCK" |
                          translate}}</button>
                            <button class="btn btn-primary" [style]='"background-color:rgb(216 3 3)"'
                              *ngIf="staffType!='STAFF' && row.staff_status=='BLOCKED'"
                              (click)="confirmAlertForUserBan(row.staff_id,'unblock') ">{{"UNBLOCK" |
                          translate}}</button>
                            <button class="btn btn-success" (click)=" editEmployee(row)"
                              *ngIf="userService.permissionMap.get('edit_user_access')==true"> {{"edit" | translate}}
                            </button>

                            <button class="btn btn-danger" (click)="confirmAlert(row)"
                              *ngIf="row.userStatus!='DELETED' && userService.permissionMap.get('delete_user_access')==true">{{"delete"
                          | translate}}</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>


                </div>

              </div>
            </div>




          </div>

        </div>
      </div>
    </div>


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AssetsService extends ApiService{

  saveAsset(data:any):Observable<any>{
    let _url="assets/save-assets";
    return super.post(_url,data);
  }

  getAsset(params):any{
    return super.get("assets/get-all-assets",params);
  }

  getParentAssets():any{
    return super.get("assets/get-parent-assets");
  }
}

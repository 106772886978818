import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UserComponent } from "../user/user.component";
import { VisitorLoginComponent } from "src/app/components/visitor-login/visitor-login.component";
import { VisitorAppointmentComponent } from "src/app/components/visitor-appointment/visitor-appointment.component";
import { VisitorComponent } from "./visitor.component";

const routes: Routes = [
  {
    path: '',
    component: VisitorComponent,
    children: [
      {path:'visitor-appointment',component:VisitorAppointmentComponent},
    ]
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VisitorRoutingModule {}

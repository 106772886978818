import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { AssignedTripInfoModalComponent } from '../assigned-trip-info-modal/assigned-trip-info-modal.component';
import { AssignUserModalComponent } from '../assign-user-modal/assign-user-modal.component';

@Component({
  selector: 'app-assigned-trips-dashboard',
  templateUrl: './assigned-trips-dashboard.component.html',
  styleUrls: ['./assigned-trips-dashboard.component.scss'],
})
export class AssignedTripsDashboardComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'Assigned Trips');

  constructor(
    public table: TableService,
    public api: ApiService,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public modal: NgbModal,
    public alert: AlertService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Action Dashboard', link: '/user/action-dashboard' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.breadcrumbService.setActiveBreadCrumb(false);
    /** breadcums implementation end*/
  }

  ngOnInit() {
    this.getAssignedTripsInfo();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  cardLabels: any[] = [
    {
      tabName: 'assigned trips',
      tab_icon: 'thumbs-up',
      tabBgColor: '#b0f6b0',
      tabIconBg: '#00bd00',
    },
  ];

  activeTab: number = 0;
  assignedTrips: any[] = [];

  getAssignedTripsInfo() {
    this.common.loading = true;
    this.api.get('dashboard/get-assigned-trips-by-plantId').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.assignedTrips = res.data || [];
        this.assignedTrips.forEach((element) => {
          element.history = JSON.parse(element.history);
        });
        this.renderTable();
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  viewHistory(item: any) {
    let activeModal = this.modal.open(AssignedTripInfoModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 's',
    });
    activeModal.componentInstance.refData = {
      txnId: item.txn_id,
      processName: item.process_name,
      stageName: item.stage_name,
      history: item.history,
    };
  }

  openAssignUserModal(item: any) {
    let activeModal = this.modal.open(AssignUserModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 's',
    });
    activeModal.componentInstance.refData = {
      processId: item.process_id,
      txnId: item.txn_id,
      stageId: item.stage_id,
      stageName: item.stage_name,
      assignedUserId: item.user_id,
    };
    activeModal.result.then((res) => {
      if (res.apiHit == 1) {
        this.getAssignedTripsInfo();
      }
    });
  }

  saveData(txnId: any, stageId: any, userId: any) {
    this.common.loading = true;
    let params = {
      txnIds: [txnId],
      stageId: stageId,
      userIds: [],
      assignedUserId: userId,
      logOutFlag: true,
    };
    this.api.post('dashboard/assign-user-for-trip', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alert.success(res.message);
          this.getAssignedTripsInfo();
        } else {
          this.alert.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

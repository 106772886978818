<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body stageType-form">
      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_stage_type_access')==true ||userService.permissionMap.get('edit_stage_type_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"stage_type_name" | customTranslate}}</label>
                  <input class="form-control" type="text" name="stageTypeName" [(ngModel)]="stageType.stageTypeName">
                  <div class="error" *ngIf="stageTypeNameValidator && stageTypeNameValidator.error">
                    <span>{{stageTypeNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"vehicle_max_limit_at_stage" | customTranslate}}</label>
                  <input class="form-control" type="number" name="stageTypeVehicleMaxLimit"
                    [(ngModel)]="stageType.stageTypeVehicleMaxLimit">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required">{{"status" | customTranslate}}</label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageTypeStatus"
                      [(ngModel)]="stageType.stageTypeStatus" id="stageType_status_1" value="ACTIVE" checked>
                    <label for="exampleRadios1">
                      {{"active" | customTranslate}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageTypeStatus"
                      [(ngModel)]="stageType.stageTypeStatus" id="stageType_status_2" value="INACTIVE">
                    <label for="exampleRadios2">
                      {{"inactive" | customTranslate}}
                    </label>
                  </div>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    customTranslate}}</button>
                  <button class="btn btn-warning" (click)="resetStage()">{{"reset" | customTranslate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{buttonName | customTranslate}}
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"stage_type_list" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"serial_no" | customTranslate}}</th>
                    <th>{{"stage_type_name" | customTranslate}}</th>
                    <th>{{"vehicle_max_limit" | customTranslate}}</th>
                    <th>{{"status" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of stageTypeList let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.stageTypeName}}</td>
                    <td>{{row.stageTypeVehicleMaxLimit}}</td>
                    <td>{{row.stageTypeStatus}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewStage(row)"
                          *ngIf="userService.permissionMap.get('view_stage_type_access')==true">{{"view" |
                          customTranslate}}</button>
                        <button class="btn btn-success" (click)="editStage(row)"
                          *ngIf="userService.permissionMap.get('edit_stage_type_access')==true">{{"edit" |
                          customTranslate}}</button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)"
                          *ngIf="userService.permissionMap.get('delete_stage_type_access')==true">{{"delete" |
                          customTranslate}}</button>
                      </div>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</div>

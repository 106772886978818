import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { UserService } from 'src/app/@core/services/user.service';
import { RoleService } from 'src/app/@core/services/role.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['../auth/auth.component.scss', './forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
  user = {
    userName: null,

    otp: null,
    newPassword: null,
    checkPassword: null,
  };

  userNameValidator: any;
  passwordValidator: any;

  otpenabled: boolean = false;
  passwordresetEnabled: boolean = false;
  successLogin: boolean = false;
  btndisabled: boolean = false;
  loginFailedFlag = false;
  showmessage: boolean = false;
  loginfail: boolean = false;
  loginmessage: string = '';
  message: string = '';
  constructor(
    public common: CommonService,
    public api: ApiService,
    public router: Router,
    public userService: UserService,
    public cookieServive: CookieService,
    public http: HttpClient,
    public role: RoleService
  ) {}

  //  loginform = new FormGroup ({
  //     username: new FormControl(),
  //     password: new FormControl(),

  //   });

  userVerify() {
    this.showmessage = false;
    this.loginfail = false;
    this.loginmessage = '';
    let params = {
      userName: this.user.userName,
    };
    let apiUrl = 'user/generate-otp';

    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        if (res.message == 'Valid') this.otpenabled = true;
        else {
          this.showmessage = true;
          this.loginfail = true;
          this.loginmessage = 'Invalid Username';
        }
      },
      (err) => {
        console.error('Error: ', err);
      }
    );
  }

  otpVerify() {
    this.showmessage = false;
    this.loginfail = false;
    this.loginmessage = '';
    let params = {
      userName: this.user.userName,
      otp: this.user.otp,
    };
    let apiUrl = 'user/verify-otp';

    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        if (res.message == 'Valid') this.passwordresetEnabled = true;
        else {
          this.showmessage = true;
          this.loginfail = true;
          this.loginmessage = 'Invalid OTP';
        }
      },
      (err) => {
        console.error('Error: ', err);
      }
    );
  }

  Changepassword() {
    if (this.user.newPassword == this.user.checkPassword) {
      this.message = '';
      let params = {
        userName: this.user.userName,
        newPassword: this.user.newPassword,
      };
      let apiUrl = 'user/change-password';
      this.message = '';
      this.loginmessage = '';
      this.api.post(apiUrl, params).subscribe(
        (res: any) => {
          this.showmessage = true;
          this.loginfail = false;
          this.loginmessage = 'Password Changed Successfully';
          this.successLogin = true;
          this.user.newPassword = null;
          this.user.checkPassword = null;
          this.router.navigate(['/auth/login']);
        },
        (err) => {
          console.error('Error: ', err);
        }
      );
    } else {
      this.message = 'Password does not match';
    }
  }

  formValidator() {
    // this.userNameValidator = this.common.nullValidator(this.user.userName, "User Name Mandatory");
    // this.passwordValidator = this.common.nullValidator(this.user.password, "Password Mandatory");
    if (!this.userNameValidator.error && !this.passwordValidator.error) {
      // this.login();
    }
  }

  ngOnInit(): void {}
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import lodash from 'lodash';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { PlantLiveViewModalComponent } from '../plant-live-view-modal/plant-live-view-modal.component';
import { AuditModalComponent } from 'src/app/modals/audit-modal/audit-modal.component';

@Component({
  selector: 'app-alert-card-modal',
  templateUrl: './alert-card-modal.component.html',
  styleUrls: [
    './alert-card-modal.component.scss',
    '../alert-dashboard/alert-dashboard.component.scss',
  ],
})
export class AlertCardModalComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  isdisable = false;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'Alert Card Modal Table');
  alertDashboardData: any[] = [];
  groupedDataByType: any = [];
  groupedDataByEntity: any = [];
  refDataChild:any =[];

  @Input() public refData;

  constructor(
    public table: TableService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {console.log("refData",this.refData)}

  ngOnInit() {
    // console.log(this.refData,'thiles is data');
  }

  getGroupDataByType(data: any, key: any) {
    return lodash.groupBy(data, key);
  }

  closeModal(res) {
    this.activeModal.close(res);
  }

  tripModal(vehicle) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.ref_id,
    };
  }

  tripTrackingModal(vehicle) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.ref_id,
      vehicleName: vehicle.ref_title + '-' + vehicle.process_name,
    };
  }

  viewPlantLiveView(viewData: string) {
    let activeModal = this.modalService.open(PlantLiveViewModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.modalProcessName = viewData;
  }

  viewDetails(row: any) {
    const lastIndex = row.ref_title.lastIndexOf('-');

    const vehicleNo = row.ref_title.substring(0, lastIndex);
    const actionName = row.ref_title.substring(lastIndex + 1);
    let data = {
      process: row.process_name,
      stage: row.stage_name,
      vehicleNumber: vehicleNo,
      actionName: actionName,
      param: {
        actionId: row.action_id,
        tripId: row.ipa_transaction_id,
      },
    };
    this.common.params = data;
    const activeModal = this.modalService.open(AuditModalComponent, {
      size: 'xl',
      backdrop: 'static',
      windowClass: 'audit-modal',
    });
    activeModal.componentInstance.refData = {
      id: row.id,
      status: row.status,
      displayBtn: false,
    };
  }

  isArray(value: any): boolean {
    try {
      let data = Array.isArray(value) ? true : Array.isArray(JSON.parse(value))
      if(data) this.refDataChild = Array.isArray(value) ? value : JSON.parse(value)
      return !data
    } catch (error) {
      return true;
    }
  }

  checkObject(value:any,key:any){
    let ref_name = key?.toLowerCase();
    return value != null && !ref_name?.includes('_') && !ref_name?.includes("type","field") && !ref_name?.includes("mandatory") && !ref_name?.includes("id");
  }
}

<div class="container-fluid">
    <div class="container-card">
      <div class="container-card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="loader_section">
                  <div class="row tab_title">
                    <div class="col-md-2 p-0">
                      <div class="title_page">
                        <p>{{'Product Dock Linking'|translate}}</p>
                      </div>
                    </div>
                    <!-- <div class="col-md-10 p-0">
                      <div class="tab_view">
                        <p *ngFor="let data of tabData" [class.activeTab]="activeSection == data.process_id"
                          (click)="selectedTab(data)">
                          <i [class]="data.icon" style="transform: rotateY(180deg); text-transform:capitalize;"
                            onselect="selectedTab($event)"></i>
                          {{data.process_name}}
                        </p>
                      </div>
                    </div> -->
                  </div>

                  <div class="kanban_view">
                    <!-- <div class="total_div">
                      {{selectedProcess}}
                      <span class="badge badge-primary ml-1">{{vehicleCount}}</span>
                    </div>

                    <div class="search_box">
                      <div class="search_input">
                        <input type="text" class="form-control search-input" [(ngModel)]="term"
                          (keyup)="searchVehicle(term)" placeholder="Search Vehicle">
                        <i class="fa fa-search"></i>
                      </div>
                    </div> -->

                    <div class="loader_div" *ngIf="responseData.length">
                      <div class="column_view" [class]="'column_view-'+((i)%6)"
                        *ngFor="let item of responseData;index as i">
                        <div class="title">
                          {{item.stage_destination_code}}
                        </div>

                        <div class="box_column">
                            <div class="box">
                              <div class="top">
                                <!-- <div class="image_hardware">
                                  <img src="assets/images/truck-loading.gif">
                                </div> -->
                                <div class="data">
                                  <span> {{item.stage_destination_code_desc}}</span>
                                  <ps-auto-suggestion (onSelected)="selectProduct($event,i)" class="required" [data]="productList"
                                    display="product_name" placeholder="Choose Product"
                                    [preSelected]="{ product_name:responseData[i].product_name}">
                                </ps-auto-suggestion>
                                </div>
                              </div>
                              <!-- <div class="detail">
                                <span class="span-detail left" (click)="tripModal(data)">{{'Trip Detail'|translate}}
                                  <i class="fa fa-info-circle icon_design"></i>
                                </span>
                                <span class="span-detail right" (click)="tripTrackingModal(data)">
                                  {{'Trip Tracking'|translate}}
                                  <i class="fa fa-map-marker icon_design"></i>
                                </span>
                              </div> -->
                            </div>
                        </div>
                      </div>

                    </div>
                    <app-data-not-found *ngIf="!responseData.length" class="loader_div"></app-data-not-found>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TableService } from 'src/app/@core/services/table.service';
import { TableColumns, TableOptions, COLUMN_ORDER } from 'src/app/dataType/customTableInterface';

@Component({
  selector: 'app-custom-datatable',
  templateUrl: './custom-datatable.component.html',
  styleUrls: ['./custom-datatable.component.scss']
})
export class CustomDatatableComponent implements OnInit {
  @ViewChild("customtable", {static: false}) myTable: ElementRef;
  @ViewChild(DataTableDirective, {static: false}) dtElement: any;
  dtOptions: any;  // options for DataTables rendering
  trigger: any = new Subject();  // To trigger DataTables rendering
  @Input() columns: TableColumns[] = [];
  @Input() data: any[] = [];
  @Input() options: TableOptions = {
    defaultLength: 15,
    exportColumns: 10,
    tableTitle: "Table",
    orderColumnIndx: 0,
    order: COLUMN_ORDER.ASC
  };
  @Input() showSerialNo: boolean = true;

  constructor(private tableService: TableService) {
    this.dtOptions = this.tableService.options(this.options.defaultLength, this.options.exportColumns, this.options.tableTitle, null, this.options.orderColumnIndx, this.options.order)
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.trigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.trigger.unsubscribe();
  }
}

import { CommonModule, DatePipe } from '@angular/common';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthComponent } from './auth/auth/auth.component';
import { DataTablesModule } from 'angular-datatables';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationDialogComponent } from '../app/modals/confirmation-dialog/confirmation-dialog.component';
import { environment } from '../environments/environment';
import { DataMapperInterceptor } from './@core/interceptor/data-mapper.interceptor';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import firebase from 'firebase/app';

//Translator
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DriverLiveUrlComponent } from './components/driver-live-url/driver-live-url.component';
import { XSightsCoreModule } from 'x-sights-core';
import { DisplayImageComponent } from './modals/display-image/display-image.component';
import { PublicVechileDetailComponent } from './components/public-vechile-detail/public-vechile-detail.component';
import { VehicleDetailComponent } from './components/vehicle-detail/vehicle-detail.component';
import { ActionInfoModalComponent } from './modals/action-info-modal/action-info-modal.component';
import { FieldAuditReportComponent } from './components/field-audit-report/field-audit-report.component';
import { PublicFieldReportComponent } from './components/public-field-report/public-field-report.component';
import { ThemeModule } from './theme/theme.module';
import { MapModalComponent } from './modals/map-modal/map-modal.component';
import { LoginCredentialsComponent } from './modals/login-credentials/login-credentials.component';
import { DirectiveModule } from './directives/directive.module';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { ChartsModule } from 'ng2-charts';
import { NextStageComponent } from './modals/next-stage/next-stage.component';
import { NotificationService } from './@core/services/notification.service';
import { SealFieldReportComponent } from './components/seal-field-report/seal-field-report.component';
import { SealReportAlertModalComponent } from './components/seal-report-alert-modal/seal-report-alert-modal.component';
import { AlertCardModalComponent } from './components/alert-card-modal/alert-card-modal.component';
import { SealFieldReportOldComponent } from './components/seal-field-report-old/seal-field-report-old.component';
import { Urltobase64Pipe } from './@core/pipes/urltobase64.pipe';
import { LiveStreamingComponent } from './components/live-streaming/live-streaming.component';
// import { TripCommentTrailComponent } from './modals/trip-comment-trail/trip-comment-trail.component';
import { UserModule, VisitorModule } from './modules';
import { VisitorLoginComponent } from './auth/visitor-login/visitor-login.component';
import { PortLoginComponent } from './auth/port-login/port-login.component';
import { DemoMaterialModule } from './material.module';

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

firebase.initializeApp(environment.firebase);

// const credentials={
//   Token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MzEzNjUsImZvaWQiOjEsIm5hbWUiOiJTYWxvbmkgQmFuc2FsIiwibW9iaWxlbm8iOjc3MzczNTk2OTUsImVtYWlsIjoic2Fsb25pLmJhbnNhbEBheGVzdHJhY2suY29tIiwidGltZSI6IjIwMjItMTItMDdUMTQ6Mzc6NTArMDU6MzAifQ.80BOFsdlr81fj_gV41BqcbjDQfPlqyKDDiyaqceBgRE",
//   EntryMode:"1"
// }



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AuthComponent,
    ConfirmationDialogComponent,
    VisitorLoginComponent,
    PortLoginComponent,
    PublicVechileDetailComponent,
    ForgotComponent,
    DriverLiveUrlComponent,
    ActionInfoModalComponent,
    VehicleDetailComponent,
    DisplayImageComponent,
    FieldAuditReportComponent,
    PublicFieldReportComponent,
    SealFieldReportComponent,
    MapModalComponent,
    LoginCredentialsComponent,
    NextStageComponent,
    SealReportAlertModalComponent,
    AlertCardModalComponent,
    SealFieldReportOldComponent,
    Urltobase64Pipe,
    // TripCommentTrailComponent
   ],
  imports: [
    ChartsModule,
    BrowserModule,
    CommonModule,
    ThemeModule,
    AppRoutingModule,
    DataTablesModule,
    XSightsCoreModule,
    RouterModule,
    HttpClientModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    FormsModule,
    // DirectiveModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    DirectiveModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgSelectModule,
    NgxGraphModule,
    VisitorModule,
    UserModule,
    NgxChartsModule,
    // ServiceWorkerModule.register('./firebase-messaging-sw.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    NgbActiveModal,
    DatePipe,
    Urltobase64Pipe,
    NotificationService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    //   {
    //     provide: InjectableRxStompConfig,
    //     useValue: myRxStompConfig
    //  },
    //  {
    //     provide: RxStompService,
    //     useFactory: rxStompServiceFactory,
    //     deps: [InjectableRxStompConfig]
    //  },
    // { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataMapperInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    this.checkServiceWorkerSupport();
  }
  private checkServiceWorkerSupport() {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.ready.then((registration) => {
        // Check if the user has granted permission for notifications
        if (Notification.permission === 'granted') {
          console.log(
            'Service Worker is supported and notifications are allowed.'
          );

          // Proceed with service worker registration
          this.registerServiceWorker();
        } else {
          console.warn(
            'Notifications are not allowed. Service Worker registration skipped.'
          );
        }
      });
    } else {
      console.warn(
        'Service Worker is not supported or not in production mode. Registration skipped.'
      );
    }
  }

  private registerServiceWorker() {
    navigator.serviceWorker
      .register('./firebase-messaging-sw.js')
      .then((registration) => {
        console.log(
          'Service Worker registered with scope:',
          registration.scope
        );
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }
}
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class PlantService extends ApiService{
  savePlant(params: any): Observable<any> {
    let apiSubUrl = "plant/create_plant";
    return super.post(apiSubUrl, params);
  }

  // getPlant(fetchAll: boolean = false): Observable<any> {
  //   let apiSubUrl = "plant/all_plants?fetchAll=" + fetchAll;

  //   return super.get(apiSubUrl);
  // }

  getPlantById(params: any): Observable<any> {
    let apiSubUrl =  "plant/all_plants";

    return super.get(apiSubUrl, params);
  }
}


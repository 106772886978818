<div class="card table-card" *ngIf="userService.permissionMap.get('view_tat_stagewise_access')==true"
style="height: 400px !important">
  <div class="card-header" style="height: min-content;">
    <div class="header-block">
      <h3 class="card-title">{{"stage_tat_overview" | customTranslate}}</h3>
    </div>
    <div class="right-menu">
      <div class="form-group col-xl-6 col-lg-3 col-sm-12" style="margin-bottom: 0px;">
        <label style="font-size: 12px;">{{'select_process'|customTranslate}}</label>
        <ps-auto-suggestion (onSelected)="getprocessindex($event)" [data]="allProcess" display="processName"
          placeholder="Select Process" name="defaultProcess" [preSelected]="{processName:selectedProcess}">
        </ps-auto-suggestion>
      </div>
      <div class="form-group col-xl-6 col-lg-3 col-sm-12" style="margin-bottom: 0px;">
        <label style="font-size: 12px;">{{'filter'|customTranslate}}</label>
        <ps-auto-suggestion (onSelected)="getfilter($event)" [data]="filters" display="filter"
          placeholder="Select Filter" name="filter" [preSelected]="{filter:dayFilter}">
        </ps-auto-suggestion>
      </div>
    </div>
  </div>
  <div class="card-body">
    <table class="custom-table table-scroll tat-table">
      <thead>
        <tr>
          <th style="width: 10%;">{{"serial_no" | customTranslate}}</th>
          <th>{{"stage" | customTranslate}}</th>
          <th>{{"actual_tat" | customTranslate}} <span class="text-lowercase"> (in hours) </span> </th>
          <th>{{"expected_tat" | customTranslate}} <span class="text-lowercase"> (in hours) </span> </th>
        </tr>
      </thead>
      <tbody style="min-height: 220px; border: 1px solid #d6d6d6;">
        <tr *ngFor="let item of stagetat;index as i">
          <td style="width: 10%;">{{i+1}}</td>
          <td> {{item.name}} </td>
          <td>
            <button class="btn float-right" *ngIf="item.tat != null"
              [ngClass]="{'bg-danger':item.tat > item.expected_tat, 'bg-success':item.tat <= item.expected_tat}">
              {{item.tat}}
            </button>
          </td>
          <td>
            <button class="btn float-right" *ngIf="item.expected_tat != null" [ngClass]="{'bg-success':item.tat}">
              {{item.expected_tat}}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{('tag'|customTranslate)+' '+('destination'|customTranslate)+' '+('tat'|customTranslate)+' '+('report'|customTranslate)}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="page_design">
                <div class="row tab_sec m-0">
                  <div class="col-md-6 col-12 date_div p-0">
                    <div class="row">
                      <div class="col-md-5 date">
                        <label>{{'start_date:'|customTranslate}}</label>
                        <input type="date" class="form-control"
                          [(ngModel)]="startDate">
                      </div>
                      <div class="col-md-5 date">
                        <label>{{'end_date:'|customTranslate}}</label>
                        <input type="date" class="form-control"
                          [min]="startDate" [(ngModel)]="endDate">
                      </div>
                      <div class="col-md-2 btn-block text-right">
                        <button class="btn btn-primary" (click)="getTagDestinationTATReport()">{{"search" | customTranslate}}</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="view_table">
                  <div class="filter_sec">
                    <div class="row">
                      <div class="col-md-3 coll">
                        <label>{{'select_tag:'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedTag($event)" [data]="tagList" display="tag_id"
                          [placeholder]="'Select Tag'|customTranslate" [preSelected]="{tag_id:selectTag}">
                        </ps-auto-suggestion>
                      </div>
                      <ng-container *ngIf="!unmappedTag">
                        <div class="col-md-3 coll">
                          <label>{{'select_vehicle'|customTranslate}}</label>
                          <ps-auto-suggestion (onSelected)="selectedVehicle($event)" [data]="vehicleList"
                            display="vehicle_rsn" [placeholder]="'select_vehicle'|customTranslate"
                            [preSelected]="{vehicle_rsn:selectedVehicleRc}">
                          </ps-auto-suggestion>
                        </div>
                        <div class="col-md-3">
                          <label>{{'select_process'|customTranslate}}</label>
                          <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="listOfProcess"
                            display="processName" [placeholder]="'select_process'|customTranslate" [preSelected]="{processName:processName}">
                          </ps-auto-suggestion>
                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="form-group action-block mt-2">
                    <div class="btn-block button-block">

                      <div class="toggle">
                        <label for="" class="d-block"> {{'Show Unmapped Tag'|customTranslate}} </label>
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" id='customSwitch1'
                            [(ngModel)]="unmappedTag" (change)="showUnmappedTag()">
                          <label class="custom-control-label" for="customSwitch1"></label>
                        </div>
                      </div>

                      <div class="toggle">
                        <label for="" class="d-block"> {{'Timestamp View'|customTranslate}} </label>
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" id='customSwitch2'
                            [(ngModel)]="showTime" (change)="getTagDestinationTATReport()">
                          <label class="custom-control-label" for="customSwitch2"></label>
                        </div>
                      </div>

                      <button type="button" class="excel-btn excel"
                      (click)="exportAsExcel(responseData,'Tag Destination TAT Report')">
                      <i class="fas fa-file-excel"></i>
                      </button>

                      <button class="btn btn-warning" (click)="resetPage()"> {{'reset'|customTranslate}} </button>
                    </div>
                  </div>

                  <div class="table_sec">
                    <app-custom-table [data]="filteredData" [columns]="filterHeaders"  [tableType]="TABLE_TYPE"></app-custom-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

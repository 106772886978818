<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="card main-card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              Trip Detail
            </h3>
          </div>


          <div class="title-block d-flex" *ngIf="txnDetailsAndHistory.length">
            <p> Process: <span> {{txnDetailsAndHistory[0]?.process_name}} </span></p>
            <p class="ml-3"> Stage : <span> {{txnDetailsAndHistory[0]?.stage_name}} </span> </p>

          </div>

        </div>
        <div class="col-md-12 form-group text-right">
          <button class="btn btn-secondary" (click)="vehicleHistoryModal()"> Vehicle History </button>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12" *ngIf="tripviewbase == 'map'">

              <div class="card stage-detail">
                <div class="card-header pb-2">
                  <div class="header-block">
                    <h3 class="card-title">
                      stage Detailed View
                    </h3>
                  </div>
                </div>
              </div>

            </div>

            <div [ngClass]="tripviewbase == 'map' ? 'col-lg-7 col-md-7 col-sm-12':'col-lg-12 col-md-12'">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="card vehicle-detail">
                    <div class="card-header pb-2">
                      <div class="header-block">
                        <h3 class="card-title">
                          <i class="fas fa-truck"></i> Vehicle Detail
                        </h3>
                      </div>
                    </div>

                    <div class="card-body">

                      <div class="detail-block" *ngIf="txnDetailsAndHistory.length">
                        <div *ngIf="txnDetailsAndHistory[0].in_time!=null">
                          <p>In Time</p> <span>
                            {{common.dateFormatter(txnDetailsAndHistory[0].in_time,'ddMMYYYY',true)}} </span>
                        </div>
                        <div *ngIf="txnDetailsAndHistory[0].stage!=null">
                          <p> Stage </p> <span> {{txnDetailsAndHistory[0].stage}} </span>
                        </div>
                        <div *ngIf="txnDetailsAndHistory[0].trip_id!=null">
                          <p> Trip Id </p> <span> {{txnDetailsAndHistory[0].transaction_name}} </span>
                        </div>
                        <div *ngIf="txnDetailsAndHistory[0].vehicle_reg_no!=null">
                          <p> Vehicle Registration No </p> <span> {{txnDetailsAndHistory[0].vehicle_reg_no}} </span>
                        </div>
                      </div>

                    </div>

                  </div>


                  <div class="card order-detail">
                    <div class="card-header pb-2">
                      <div class="header-block">
                        <h3 class="card-title">
                          <i class="fas fa-list"></i> order detail
                        </h3>
                      </div>
                    </div>

                    <div class="card-body">
                      <div class="detail-block" *ngIf="txnDetailsAndHistory.length">
                        <div *ngIf="txnDetailsAndHistory[0].order_name!=null">
                          <p> Order Name </p> <span> {{txnDetailsAndHistory[0].order_name}} </span>
                        </div>
                        <div *ngIf="txnDetailsAndHistory[0].transporter_name!=null">
                          <p> Transporter Name </p> <span> {{txnDetailsAndHistory[0].transporter_name}} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">

                  <div class="card driver-detail">
                    <div class="card-header pb-2">
                      <div class="header-block">
                        <h3 class="card-title">
                          <i class="fa fa-user" aria-hidden="true"></i> driver detail
                        </h3>
                      </div>
                    </div>

                    <div class="card-body">
                      <div class="detail-block" *ngIf="txnDetailsAndHistory.length">

                        <div *ngIf="txnDetailsAndHistory[0].driver_name!=null">
                          <p> Driver Name </p> <span> {{txnDetailsAndHistory[0].driver_name}} </span>
                        </div>

                        <div *ngIf="txnDetailsAndHistory[0].driver_email!=null">
                          <p> Driver Email </p> <span> {{txnDetailsAndHistory[0].driver_email}} </span>
                        </div>

                        <div *ngIf="txnDetailsAndHistory[0].driver_phone!=null">
                          <p> Driver Mobile No. </p> <span> {{txnDetailsAndHistory[0].driver_phone}} </span>
                        </div>


                      </div>

                    </div>

                  </div>

                  <div class="card trip-detail">
                    <div class="card-header pb-2">
                      <div class="header-block">
                        <h3 class="card-title">
                          <i class="fas fa-truck"></i> Trip History
                        </h3>
                      </div>
                    </div>

                    <div class="card-body">
                      <div class="detail-block">
                        <div class="history-heading">
                          <p> Stage </p>
                          <p> Arrival Time </p>
                        </div>


                        <div class="history-detail" *ngFor="let item of txnDetailsAndHistory[0]?.trip_history">
                          <p> {{item.Stage}} </p>
                          <p *ngIf="item.Arrival_Time!=null">
                            {{common.dateFormatter(item.Arrival_Time,'ddMMYYYY',true)}} </p>
                          <p *ngIf="item.Arrival_Time==null"> -- </p>
                        </div>


                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>


      </div>
    </div>
  </div>
</div>

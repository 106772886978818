import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';

@Component({
  selector: 'app-alert-log-info-modal',
  templateUrl: './alert-log-info-modal.component.html',
  styleUrls: ['./alert-log-info-modal.component.scss'],
})
export class AlertLogInfoModalComponent implements OnInit {
  @Input() public refData;
  alertLogDetails: any[] = [];
  image_url: any = null;
  image_name: any = null;
  image_ext: any = null;

  constructor(
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.getAlertLogInfo();
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  getAlertLogInfo() {
    this.common.loading = true;
    let params = {
      alertLogId: this.refData.alertLogId,
    };
    this.api.get('report/get-alert-log-info', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alertLogDetails = res.data;
        this.alertLogDetails[0].assigned_users = JSON.parse(
          this.alertLogDetails[0].assigned_users
        );
        this.alertLogDetails[0].remark_data = JSON.parse(
          this.alertLogDetails[0].remark_data
        );
        this.alertLogDetails[0].pickedbyuser = JSON.parse(
          this.alertLogDetails[0].pickedbyuser
        );
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    );
  }

  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }

  displayImage(url) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }
}

<div class="modal-header" >
    <h5 class="modal-title" id="modal-basic-title">{{'assign'|translate}} {{assign|translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal(null,-1,null)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row" *ngIf="assign=='Driver'" style="margin: 1px 16px 26px;">
      <div style="width: 70%;">
      <label for="my-input" class="required" >{{"select"|translate}} {{assign|translate}}</label>
      <ps-auto-suggestion (onSelected)="selectAssignTo($event)" [data]="assignToList" display="{{DisplayName}}"
        [placeholder]="'Select '+assign" [preSelected]="{driverName:newEntry}">
      </ps-auto-suggestion>
      <div class="error" *ngIf="assigneeValidator && assigneeValidator.error">
        <span>{{assigneeValidator.msg}} </span>
      </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="assign=='transporter'" style="margin: 1px 16px 26px;">
      <div style="width: 70%;">
        <label for="my-input" class="required">{{"select"|translate}} {{assign|translate}}</label>
        <ps-auto-suggestion (onSelected)="selectAssignTo($event)" [data]="assignToList" display="{{DisplayName}}"
        [placeholder]="'Select '+assign" [preSelected]="{transporterName:newEntry}">
        </ps-auto-suggestion>
        <div class="error" *ngIf="assigneeValidator && assigneeValidator.error">
          <span>{{assigneeValidator.msg}} </span>
        </div>
        </div>
      </div >
    <div class="add_new_box"><span class="driver_not_found">{{assign|translate}}{{"not_found"|translate}}</span><span (click)="addNewAssignee(detail2,'add')" class="add_new_driver">{{'add_new'|translate}} {{assign|translate}}</span></div>

    <div *ngIf="assigningTo!=null && details.length" style="display: flex;width:100%;border:1px solid rgb(209, 209, 209);border-radius:10px;justify-content: space-between;margin:auto">
        <!-- <app-add-driver-transporter [editable]="false" [id]="assigningTo" [assign]="assign" ></app-add-driver-transporter> -->
        <div class="image">
                <img   *ngIf="assign=='Driver'?details[0].driverImage!=null:details[0].transporterImage!=null" [src]="assign=='Driver'?details[0].driverImage:details[0].transporterImage" alt="" height="65px" (click)="displayImage(assign=='Driver'?details[0].driverImage:details[0].transporterImage,assign)">
                <img  *ngIf="assign=='Driver'?details[0].driverImage==null:details[0].transporterImage==null" src="assets/images/blank-profile-picture.jpg" alt="" height="65px" >
        </div>
        <div class="info">
          <div style="display:flex;flex-direction:column">
            <span><i class="fa fa-user mr-1"></i>: {{this.assign=='Driver'?this.details[0].driverName:this.details[0].transporterName}}</span>
            <span><i class="fa fa-phone mr-1"></i>: {{this.assign=='Driver'?this.details[0].driverPhone:this.details[0].transporterPhone}}</span>
            <span *ngIf="this.details[0].altMobileNo!=null && this.details[0].altMobileNo!=''"><i class="fa fa-phone mr-1"></i>: {{this.details[0].altMobileNo}}</span>
            <span *ngIf="this.assign=='Driver'"><i class="fa fa-id-card-o mr-1"></i>: {{this.details[0].driverLicenseNo}}</span>
          </div>

        <div *ngIf="assigningTo!=null" style="display:flex;flex-direction: row-reverse;width: 100%;margin:4px;border-radius:10px">
          <button class="bt btn-primary" (click)="addNewAssignee(detail2,'update')">{{"Edit"|translate}} {{assign|translate}}</button>
        </div>
        </div>
    </div>
    <div class="modal-footer" style="border:none">
      <button class="btn btn-success" type="button" outline (click)="formValidator()"
        style="cursor: pointer;">{{'Assign'|translate}}</button>
    </div>

    <!-- add or edit assignee modal body -->
    <ng-template #detail2 id="detail2" let-modal2>
      <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">{{flag=='add'?"Add":"Update"|translate}} {{assign|translate}} {{'Details'|translate}}</h6>
        <div aria-label="Close" (click)="modal2.dismiss('Cross click');openCurrentModal()" ><i class="fa fa-times"></i></div>
      </div>
      <div class="modal-body" id="boxes">
      <app-add-driver-transporter (saveChange)="modal2.close($event)" [assign]="assign" [id]="flag=='add'?null:assigningTo" [editable]="true" ></app-add-driver-transporter>
      </div>
    </ng-template>
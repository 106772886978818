import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AssetWarrantyList } from 'src/app/dataType/assetInterface';
import { validator } from 'src/app/dataType/commonInterface';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

declare var $ : any;


@Component({
  selector: 'app-asset-warranty',
  templateUrl: './asset-warranty.component.html',
  styleUrls: ['./asset-warranty.component.scss']
})
export class AssetWarrantyComponent implements OnInit {
  @Input() public assetId:number;

// Global Variable
  assetWarranty:any = {
    id:null,
    warrantyPlan:null,
    dueDate:null,
    renewalAmount : null,
    deductableAmount: null,
    notes : null
  }
  assetWarrantyList :AssetWarrantyList[] = [];
  minStartDate:string =  this.datePipe.transform(new Date(),'dd-MM-YYYY')
  warrantyPlanValidator: validator = null;
  dueDateValidator: validator = null;
  renewalAmountValidator: validator = null;
  deductableAmountValidator: validator = null;



// Constructor
  constructor(
    private api: ApiService,
    public table: TableService,
    public common: CommonService,
    public modalService: NgbModal,
    public alertService :AlertService,
    public datePipe:DatePipe
  ) {
   }

// ng Hooks
  ngOnInit() {
    this.getAssetWarrantyList();
  }

  saveAssetWarranty(){
    let data = {
      id:this.assetWarranty.id,
      assetId : this.assetId,
      warrantyPlan : this.assetWarranty.warrantyPlan?.trim(),
      warrantyDueDate : this.assetWarranty.dueDate,
      notes : this.assetWarranty.notes?.trim(),
      renewalAmount : this.assetWarranty.renewalAmount,
      deductableAmount : this.assetWarranty.deductableAmount
    }
    this.common.loading=true;
    this.api.post('asset-warranty/add-asset-warranty',data).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertService.success(res.message);
          this.common.loading = false;
          this.reset();
          $('#exampleModal').modal('hide');
          this.getAssetWarrantyList();

        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );

  }

  getAssetWarrantyList(){
    this.common.loading=true
    this.api.get('asset-warranty/get-asset-warranty-list',{assetId:this.assetId}).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.assetWarrantyList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  viewDetails(row?:any){
    const warrantyDetails = {
      asset_name: row.asset_name,
      asset_code: row.asset_code,
      warranty_plan: row.warranty_plan,
      warranty_due_date: row.warranty_due_date,
      deductable_amount: row.deductable_amount,
      renewal_amount: row.renewal_amount,
      created_at: this.common.dateFormatter(row.created_at,'DDMMYYYY',false) ,
      modified_at: this.common.dateFormatter(row.modified_at,'DDMMYYYY',false) ,
      status: row.status
    };
    this.common.params = { details: [warrantyDetails], title: 'Warranty Details' };
    this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  reset(){
    this.assetWarranty = {
      id:null,
      assetId:this.assetId,
      warrantyPlan:null,
      dueDate:null,
      renewalAmount : null,
      deductableAmount : null,
      notes : null
    }
}


confirmAlert(row: any) {
  this.common.params = {
    title: 'Delete  Asset Warranty',
    description:
      `<b>&nbsp;` + 'Are you sure you want to delete Asset Warranty?' + `<b>`,
  };
  const activeModal = this.modalService.open(ConfirmationDialogComponent, {
    size: 'md',
  });
  activeModal.result.then((data: any) => {
    if (data.response) {
      this.delete(row);
    }
  });

}

delete(row?: any) {
console.log('row: ', row);
  let params = {
    warrantyId: row.id,
  };
  this.common.loading=true;
  this.api.get('asset-warranty/delete-asset-warranty', params).subscribe(
    (res: any) => {
      if (res.status) {
        this.common.loading = false;
        this.reset();
        this.getAssetWarrantyList();
        this.alertService.info(res.message);
      }
    },
    (err: any) => {
      console.error('Error: ', err);
      this.common.loading = false;
    }
  );
}


formValidator() {
  this.warrantyPlanValidator = this.common.nullValidator(
    this.assetWarranty.warrantyPlan.trim(),
    'Warranty Plan Mandatory'
    );
    console.log(' this.warrantyPlanValidator: ',  this.warrantyPlanValidator);
  this.dueDateValidator = this.common.nullValidator(
    this.assetWarranty.dueDate,
    'Due Date Mandatory'
  );
  this.renewalAmountValidator = this.common.nullValidator(
    this.assetWarranty.renewalAmount,
    'Renewal Amount Mandatory'
  );
  this.deductableAmountValidator = this.common.nullValidator(
    this.assetWarranty.deductableAmount,
    'Deductable Amount Mandatory'
  );
  if (!this.warrantyPlanValidator.error && !this.dueDateValidator.error && !this.renewalAmountValidator.error && !this.deductableAmountValidator.error) {
    this.saveAssetWarranty();
  }
}

}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { MapService } from 'src/app/@core/services/map.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import * as _ from 'lodash';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { EntityLog,GeofenceData, TrackingLog } from 'src/app/dataType/entityTrackingInterface';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserTrackingInfoModalComponent } from 'src/app/modals/user-tracking-info-modal/user-tracking-info-modal.component';

declare var google: any;

@Component({
  selector: 'app-user-tracking-view',
  templateUrl: './user-tracking-view.component.html',
  styleUrls: ['./user-tracking-view.component.scss'],
})
export class UserTrackingViewComponent implements OnInit {
  constructor(
    public common: CommonService,
    public table: TableService,
    public userService: UserService,
    public alertService: AlertService,
    public api: ApiService,
    public role: RoleService,
    private mapService: MapService,
    public breadcrumbService: BreadcrumbService,
    public modalService: NgbModal
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
  }

  @Input() public staffType:string = 'ALL';

  userTrackingData: EntityLog[] = [];
  groupData:{
    zoneData: {[key:string]:EntityLog[]},
    stageData: {[key:string]:EntityLog[]},
    hardwareData: {[key:string]:EntityLog[]}
  } = {zoneData:{},stageData:{},hardwareData:{}};

  userSelected: EntityLog = null;
  filterRow = {
    userFilter: [],
    zoneFilter: [],
    stageFilter: [],
  };
  allGeoFence : {
    plant_data: GeofenceData,
    zone_data:GeofenceData,
    stage_data:GeofenceData,
    hardware_data:GeofenceData,
    plantLatLong: any[]
  };
  viewMarkerData: EntityLog[] = [];
  latLng: any = [];
  markers: any = [];
  userPath: any;
  userLogs: TrackingLog[] = [];
  dueDate = new Date().toISOString().split('T')[0];
  filterData = {
    userNames: [],
    zoneIds: [],
    stageIds: [],
  };

  ngOnInit() {
    this.getUsersTrackingData(this.dueDate);
    this.common.sidebarToggled(this.common.menuToggled);
  }

  ngOnDestory(): void {
    this.mapService.clearAll();
  }

  ngAfterViewInit() {
    this.mapService.mapIntialize('map-tracking', 12, 25, 75, false);
    setTimeout(() => {
      this.mapService.setMapType(0);
    }, 2000);
  }

  @ViewChild('detailModal') detailModal: ElementRef;

  selectedDate(date: any) {
    this.dueDate = date;
    this.mapService.clearAll();
    this.userSelected = null;
    this.filterData.userNames = [];
    this.getUsersTrackingData(this.dueDate);
  }

  filterUser(event: any) {
    let userName: any = [];
    event.forEach((user) => userName.push(user.entity_name));
    this.filterData.userNames = userName;
    this.LoadFilterGeoFence();
  }

  filterZone(event: any) {
    let zoneIds: any = [];
    event.forEach((zone) => zoneIds.push(zone.zone_id));
    this.filterData.zoneIds = zoneIds;
    this.LoadFilterGeoFence();
  }

  filterStage(event: any) {
    let stageIds: any = [];
    event.forEach((stage) => stageIds.push(stage.stage_id));
    this.filterData.stageIds = stageIds;
    this.LoadFilterGeoFence();
  }

  selectedUser(user:EntityLog) {
    if (this.userSelected == user) {
      this.userSelected = null;
      this.allGeoFence.plantLatLong = this.getSiteGeoFenceLatLong(
        this.allGeoFence
      );
    } else {
      this.userSelected = user;
      this.allGeoFence.plantLatLong = this.getSiteGeoFenceLatLong(
        this.allGeoFence,
        true
      );
      this.getUserLogs(user.entity_id, user.entity_type);
    }
    this.viewGeoFence(this.allGeoFence);
  }

  LoadFilterGeoFence() {
    this.createGroupedData(this.userTrackingData);
    this.allGeoFence.plantLatLong = this.getSiteGeoFenceLatLong(
      this.allGeoFence
    );
    this.viewGeoFence(this.allGeoFence);
  }

  resetDetails(){
    this.clearMapServices();
    this.dueDate = new Date().toISOString().split('T')[0];
    this.groupData = {zoneData:{},stageData:{},hardwareData:{}};
    this.userSelected = null;
    this.filterData = {
      userNames: [],
      zoneIds: [],
      stageIds: [],
    };
    this.userLogs = [];
    this.getUsersTrackingData(this.dueDate);
  }

  getUsersTrackingData(date: string) {
    let params = {
      date: date,
      entityType:this.staffType
    };
    this.api.get('user-tracking/get-users-location-log', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.userTrackingData = res.data;
          this.createGroupedData(res.data);
          this.createFilterSuggestionData();
          this.getGeoFence();
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  displayImage(url: string) {
    console.log(url);
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  openUserTrackingModal(userId:number,userType:string){
    this.common.params = {
      userId:userId,
      userType:userType == 'USER' ? 'USER' : 'STAFF',
      logDate:this.dueDate
  }
    const activeModal = this.modalService.open(UserTrackingInfoModalComponent, {
      size: 'xl',
    });
}

  createGroupedData(ungroupedData: any[]) {
    ungroupedData = ungroupedData.filter(
      (item) =>
        ((this.filterData.userNames.length &&
          this.filterData.userNames.includes(item.entity_name)) ||
          !this.filterData.userNames.length) &&
        ((this.filterData.zoneIds.length &&
          this.filterData.zoneIds.includes(item.zone_id)) ||
          !this.filterData.zoneIds.length) &&
        ((this.filterData.stageIds.length &&
          this.filterData.stageIds.includes(item.stage_id)) ||
          !this.filterData.stageIds.length)
    );
    this.groupData.hardwareData = _.groupBy(ungroupedData, 'hardware_id');
    this.groupData.stageData = _.groupBy(ungroupedData, 'stage_id');
    this.groupData.zoneData = _.groupBy(ungroupedData, 'zone_id');
  }

  createFilterSuggestionData() {
    this.filterRow.userFilter = this.userTrackingData;
    this.filterRow.zoneFilter = [];
    this.filterRow.stageFilter = [];

    Object.entries(this.groupData.zoneData).forEach((ele) => {
      if (ele[0] != 'null')
        this.filterRow.zoneFilter.push({
          zone_id: parseInt(ele[0]),
          zone_name: ele[1][0].zone_name,
        });
    });
    Object.entries(this.groupData.stageData).forEach((ele) => {
      if (ele[0] != 'null')
        this.filterRow.stageFilter.push({
          stage_id: parseInt(ele[0]),
          stage_name: ele[1][0].stage_name,
        });
    });
  }

  convertToString(userLogs: any): string {
    if (userLogs.length === 0) {
      return 'N/A';
    }

    const string = userLogs.join(',');
    return string;
  }

  // from here map view
  getGeoFence() {
    this.common.loading = true;
    let apiUrl = 'geofence/get-tracking-geofence';
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.allGeoFence = res.data[0];
        this.allGeoFence.plantLatLong = this.getSiteGeoFenceLatLong(
          this.allGeoFence
        );
        this.viewGeoFence(this.allGeoFence);
      },
      (err) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  viewGeoFence(row: any, event = false) {
    let latLong = {
      lat: row.plantLatLong[0].lat,
      lng: row.plantLatLong[0].lng,
    };
    this.clearMapServices();
    this.mapService.centerAt(latLong);
    // this.mapService.zoomAt(latLong, 14);
    if (this.markers) {
      this.markers.forEach((marker) => {
        marker.setMap(null);
      });
      this.markers = [];
    }

    if (this.userPath) {
      this.userPath.setMap(null);
      this.userPath = null;
    }
    this.mapService.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    this.LoadGeoFence(row.plantLatLong, event);
    let plantGeoFence = row.plantLatLong.find((item) => item.type == 5).data;
    plantGeoFence.forEach((element) => {
      this.mapService.setBounds(
        this.mapService.createLatLng(element.lat, element.lng)
      );
    });
    let thisClass = this;

    this.mapService.markers.forEach((marker: any) => {
      this.mapService.addListerner(marker, 'click', function (event: any) {
        this.mapDetailId = marker.id;
        this.mapDetailLabel = marker.label.text;
        thisClass.viewMarkerData = marker.ref_data ?? [];
        setTimeout(() => {
          let modal = document.getElementById('detailModal');
          modal.classList.add('d-block');
        }, 50);
      });
    });
  }

  closeModal() {
    let modal = document.getElementById('detailModal');
    modal.classList.remove('d-block');
  }

  LoadGeoFence(data, event = false) {
    let latLngsArray = [];
    if (data.length > 0) {
      latLngsArray.push(data);
      this.mapService.createPlantGeoFence(data, event, false, null, true);
    }
  }

  addArrowsBetweenMarkers(pathCoordinates) {
    for (let i = 0; i < pathCoordinates.length - 1; i++) {
      const arrow = new google.maps.Marker({
        position: this.calculateMidpoint(pathCoordinates[i + 1]),
        map: this.mapService.map,
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 4, // Adjust the size of the arrow
          fillColor: 'black',
          fillOpacity: 1,
          strokeColor: '#FFFFFF', // White border color
          strokeWeight: 1, // Thickness of the border
          strokeOpacity: 1,
          rotation: google.maps.geometry.spherical.computeHeading(
            this.mapService.createLatLng(
              pathCoordinates[i].lat,
              pathCoordinates[i].lng
            ),
            this.mapService.createLatLng(
              pathCoordinates[i + 1].lat,
              pathCoordinates[i + 1].lng
            )
          ), // Calculate the rotation angle for the arrow
        },
      });

      this.markers.push(arrow);
    }
  }

  calculateMidpoint(p2) {
    return {
      lat: parseFloat(p2.latitude),
      lng: parseFloat(p2.longitude),
    };
  }

  createPathfromUserLogs(userLogs) {
      if (this.markers) {
        this.markers.forEach(marker => {
          marker.setMap(null);
        });
        this.markers = [];
      }

      if (this.userPath) {
        this.userPath.setMap(null);
        this.userPath=null;
      }

      const lineSymbol = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      };

        this.latLng=userLogs.map((log)=>({
          lat:parseFloat(log.latitude),
          lng:parseFloat(log.longitude),
          ...log
        }));
        this.userPath = new google.maps.Polyline({
          path: [],
          geodesic: true,
          icons: [
                {
                  icon: lineSymbol,
                  offset: "100%",
                },
              ],
          map: this.mapService.map
        });

        let index = 0;
        const interval = setInterval(() => {
          if (index < this.latLng.length) {
            this.userPath.getPath().push(this.mapService.createLatLng(this.latLng[index].lat,this.latLng[index].lng));
            index++;
          } else {
            clearInterval(interval); // Stop the interval once all points are added
          }
        }, 200);

        this.addArrowsBetweenMarkers(this.latLng);

        if(this.latLng.length>0){
          const firstLatLng=this.latLng[0];
          this.mapService.map.setCenter(firstLatLng);
          this.mapService.map.setZoom(14);
        }

        this.latLng.forEach((latlng,index)=>{
          const marker=new google.maps.Marker({
            position:latlng,
            map: this.mapService.map
          });

        const infowindow = new google.maps.InfoWindow({
          content: `
            <div style="font-family: Arial, sans-serif; font-size: 10px; margin: 0; padding: 0;">
            <div style="font-weight: bold; background-color: #f1f1f1; padding: 5px; border-bottom: 1px solid #ccc; margin: 0;">
              ${latlng.zone_name}
            </div>
            <div style="padding: 5px; margin: 0;">
              ${latlng.end_time}
            </div>
          </div>
            `,
        });

      infowindow.open(this.mapService.map, marker);

      if (index == this.latLng.length - 1) {
        marker.setAnimation(google.maps.Animation.BOUNCE);
      }
      this.markers.push(marker);
    });
  }

  getSiteGeoFenceLatLong(data: any, path_tracking: boolean = false) {
    let latLongs = [];
    if (data.plant_data && data.plant_data.length) {
      latLongs.push({
        type: 1,
        label: data.plant_data[0].plant_name,
        lat: data.plant_data[0].lat,
        lng: data.plant_data[0].lng,
        radius: data.plant_data[0].radius,
        data: this.getLatLongFromPolygon(data.plant_data[0].pos),
        id: data.plant_id,
      });
    }

    if (data.stage_data && data.stage_data.length) {
      data.stage_data.forEach((stage) => {
        if (stage.pos) {
          latLongs.push({
            type: 2,
            lat: stage.lat,
            lng: stage.lng,
            radius: stage.radius,
            data: this.getLatLongFromPolygon(stage.pos),
            id: stage.stage_id,
            ref_data: this.groupData.stageData[stage.stage_id] ?? [],
            label: path_tracking || (this.groupData.stageData[stage.stage_id]?.length ?? 0) == 0
              ? `${stage.stage_name}`
              : `${stage.stage_name} 👤${this.groupData.stageData[stage.stage_id]?.length ?? 0}`,
          });
        }
      });
    }

    if (data.zone_data && data.zone_data.length) {
      data.zone_data.forEach((zone) => {
        if (zone.pos) {
          latLongs.push({
            type: 5,
            lat: zone.lat,
            lng: zone.lng,
            radius: zone.radius,
            data: this.getLatLongFromPolygon(zone.pos),
            id: zone.zone_id,
            ref_data: this.groupData.zoneData[zone.zone_id] ?? [],
            label: path_tracking || (this.groupData.zoneData[zone.zone_id]?.length ?? 0)== 0
              ? `${zone.zone_name}`
              : `${zone.zone_name} 👤${this.groupData.zoneData[zone.zone_id]?.length ?? 0}`,
          });
        }
      });
    }

    if (data.hardware_data && data.hardware_data.length && !path_tracking) {
      data.hardware_data.forEach((hardware) => {
        if (hardware.pos) {
          if ((this.groupData.hardwareData[hardware.hardware_id]?.length ?? 0) !=0) {
            latLongs.push({
              type: 6,
              lat: hardware.lat,
              lng: hardware.lng,
              radius: hardware.radius,
              data: this.getLatLongFromPolygon(hardware.pos),
              id: hardware.hardware_id,
              ref_data: this.groupData.hardwareData[hardware.hardware_id] ?? [],
              label: `👤${this.groupData.hardwareData[hardware.hardware_id]?.length ?? 0}`,
            });
          }
        }
      });
    }
    return latLongs;
  }

  getLatLongFromPolygon(pos) {
    let latLng =
      pos.type == 'GeometryCollection' ? pos.geometries : pos.coordinates;
    return latLng.length > 0
      ? latLng[0].map((ele) => {
          return { lat: ele[0], lng: ele[1] };
        })
      : [];
  }

  clearMapServices() {
    this.mapService.clearAll();
    this.mapService.resetMarker();
    this.mapService.resetPolygons();
  }

  getUserLogs(entityId, entityType) {
    this.common.loading = true;
    let params = {
      entity_id: entityId,
      dueDate: this.dueDate,
      Type: entityType=='USER'?entityType:'STAFF',
    };
    this.api.get('user-tracking/get-User-asset-all-logs', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.userLogs = res.data ?? [];
          this.userLogs.map((ele) => {
            ele.zone_name = this.convertToString(ele.zone_name);
            return ele;
          });
          this.createPathfromUserLogs(this.userLogs);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-pending-tasks-for-user-modal',
  templateUrl: './pending-tasks-for-user-modal.component.html',
  styleUrls: ['./pending-tasks-for-user-modal.component.scss'],
})
export class PendingTasksForUserModalComponent implements OnInit {
  selectedUserId: any;
  nextShiftUsersList: any[] = [];
  nextShiftUsersListForAllStages: any[] = [];
  activeTxnsForStages: any[] = [];
  userInputFlags: boolean[] = [];
  userValidator: any = {
    msg: null,
    error: false,
  };
  pendingChecklists: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private api: ApiService,
    public common: CommonService,
    public alert: AlertService,
    public user: UserService,
    public router: Router
  ) {}

  ngOnInit() {
   this.getPendingData();
  }

  selectedUser(event: any) {
    this.selectedUserId = event.user_id;
  }

  getNextShiftUsersBySelectedStage(stageId?: any) {
    this.common.loading = true;
    let params = {
      stageId: stageId,
    };
    this.api.get('shift/get-next-shift-users-by-stageId', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.nextShiftUsersList = res.data || [];
        this.nextShiftUsersListForAllStages.push(this.nextShiftUsersList);
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  setUserInputFlag(index: any, stageId: any) {
    this.userInputFlags[index] = !this.userInputFlags[index];
    this.getNextShiftUsersBySelectedStage(stageId);
  }

  saveData(txnIds: any, stageId: any, key: any) {
    if (key == 1) {
      this.userValidator = this.common.nullValidator(
        this.selectedUserId,
        'User Mandatory'
      );
    }
    if (!this.userValidator.error) {
      this.common.loading = true;
      let params = {
        txnIds: txnIds,
        stageId: stageId,
        userIds: key == 1 ? [this.selectedUserId] : [],
        logOutFlag: true,
      };
      this.api.post('dashboard/assign-user-for-trip', params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.alert.success(res.message);
            this.selectedUserId = null;
            this.getAssignedTxnForEachStage();
          } else {
            this.alert.error(res.message);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    } else {
      this.alert.warn('Please Select User');
    }
  }

  async getPendingData(){
    await this.getAssignedTxnForEachStage();
    await this.getPendingChecklists();
    if (this.activeTxnsForStages.length == 0 && this.pendingChecklists.length == 0) {
      this.closeModal(true, 1);
    }
  }

  getAssignedTxnForEachStage(): Promise<any> {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    this.api.get('dashboard/get-active-user-txn-mappings-for-stages').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.activeTxnsForStages = res.data || [];
        this.activeTxnsForStages.forEach((element) => {
          element.transactions = JSON.parse(element.transactions);
          element.txnIds = element.transactions.map(
            (transaction) => transaction.txn_id
          );
        });
        resolve(this.activeTxnsForStages);
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
        reject(null);
      }
    );
  });
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  async getPendingChecklists() : Promise<any> {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    this.api.get('checklist/get-pending-checklists-by-userId').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.data.length == 0) {
          this.closeModal(true, 1);
        }
        this.pendingChecklists = res.data || [];
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  });
  }

  reDirectToChecklistDashboard(){
    this.closeModal();
    this.router.navigate(['/user/checklist-dashboard']);
  }

}

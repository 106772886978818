import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { StageTypeService } from '../stage-type/stage-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { UserService } from 'src/app/@core/services/user.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-stage-type',
  templateUrl: './stage-type.component.html',
  styleUrls: ['./stage-type.component.scss'],
})
export class StageTypeComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'Stage Type List');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAllStageType();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  stageType = {
    stageTypeId: '',
    stageTypeName: '',
    stageTypeStatus: 'ACTIVE',
    stageTypeVehicleMaxLimit: '',
  };
  buttonName = 'save';
  title = 'Add Stage Type';
  responseMessage = '';
  responseData: any[] = [];
  stageTypeList: any[] = [];
  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    private stageTypeService: StageTypeService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public alertService: AlertService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Stage', url: null },
      { name: 'Stage Type', link: '/user/list_stage_type' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {}

  // Save Work Type
  saveStageType(): void {
    let data = {
      stageTypeId: this.stageType.stageTypeId,
      stageTypeName: this.stageType.stageTypeName,
      stageTypeStatus: this.stageType.stageTypeStatus,
      stageTypeVehicleMaxLimit: this.stageType.stageTypeVehicleMaxLimit,
    };

    this.stageTypeService.saveStageType(data).subscribe(
      (response) => {
        if (response.status) {
          this.common.loading = false;
          this.alertService.success(response.message);
          this.resetStage();
          this.getAllStageType();
        }
      },
      (error) => {
        this.common.loading = false;
        console.error(error);
      }
    );
  }

  getAllStageType() {
    this.common.loading = true;
    this.stageTypeService.getAllStageType().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.stageTypeList = res.data;
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  viewStage(row?: any) {
    this.common.params = { details: [row], title: 'Stage Type Details' };

    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Stage Type ',
      description:
        `<b>&nbsp;` + 'Are you sure, Do you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteStage(row);
      }
    });
  }

  deleteStage(row?: any) {
    this.common.loading = true;
    let data = {
      stageTypeId: row.stageTypeId,
      stageTypeName: row.stageTypeName,
      stageTypeStatus: 'DELETED',
      stageTypeVehicleMaxLimit: row.stageTypeVehicleMaxLimit,
    };

    this.stageTypeService.saveStageType(data).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alertService.success(res.message);
        this.getAllStageType();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  editStage(row?: any) {
    this.common.gotoTop();
    this.resetStage();
    this.stageType.stageTypeId = row.stageTypeId;
    this.stageType.stageTypeName = row.stageTypeName;
    this.stageType.stageTypeStatus = row.stageTypeStatus;
    this.stageType.stageTypeVehicleMaxLimit = row.stageTypeVehicleMaxLimit;

    this.buttonName = 'update';
    this.title = 'Edit Stage Type';
  }

  resetStage() {
    this.stageType = {
      stageTypeId: '',
      stageTypeName: '',
      stageTypeStatus: 'ACTIVE',
      stageTypeVehicleMaxLimit: '',
    };
    this.stageTypeNameValidator = null;
    this.statusValidator = null;
    this.buttonName = 'save';
    this.title = 'Add Stage Type';
  }

  stageTypeNameValidator: any;
  statusValidator: any;
  formValidator() {
    this.stageTypeNameValidator = this.common.nullValidator(
      this.stageType.stageTypeName,
      'Stage Type Name Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.stageType.stageTypeStatus,
      'Stage Type Status Mandatory'
    );
    if (!this.stageTypeNameValidator.error && !this.statusValidator.error) {
      this.saveStageType();
    }
  }
}

import { SharedService } from './../shared.service';
import { HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChanges } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { WebcamImage } from 'ngx-webcam';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/@core/services/api.service';
import { Base64ToFileService } from 'src/app/@core/services/base64-to-file-service.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-add-driver-transporter',
  templateUrl: './add-driver-transporter.component.html',
  styleUrls: ['./add-driver-transporter.component.scss']
})
export class AddDriverTransporterComponent implements OnInit {
  @ViewChild('numberInput', { static: true }) numberInput: NgModel;
  @ViewChild('altNumberInput', { static: true }) altNumberInput: NgModel;

  @Input() assign: string;
  name:string;
  number:string;
  @Input() id:number;
  @Input() editable: boolean;
  @Input() closeFlag:boolean=false;
  details:any[]=[];
  driverLiscenceNumber:string=null;
  image_name: any = '';
  image_url: any = '';
  image_ext: any = '';
  retakeImage: boolean = false;
  retakeLiscence:boolean=false;
  fileEvent: any=[];
  driverLiscenceImage: any=null;
  image: any=null;
  @Output() saveChange: EventEmitter<any> = new EventEmitter<any>();
  altMobileNumber:string=null;

  constructor(  public alertService: AlertService,
    public api: ApiService,public router: Router,private modalService:NgbModal, public route: ActivatedRoute,
    public common: CommonService,
    private activeModal: NgbActiveModal,
    private base64ToFileService: Base64ToFileService,
    private imageCompress: NgxImageCompressService,
    private SharedService:SharedService) {

     }

  ngOnInit(): void {
    if(this.id!=null){
    this.getDetails();
    }
  }


  ngOnChanges(simple: SimpleChanges){
    this.getDetails();
  }

  getDetails(){
    let url=this.assign=='Driver'?'driver/get-driver-details?driverId='+this.id:'transporter/get-transporter-details?transporterId='+this.id;
  this.api.get(url).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.details = res.data;
          if(this.id!=null){
           this.name=this.details[0].name;
           this.number=this.details[0].phone_number;
           this.image=this.details[0].image;
           this.driverLiscenceImage=this.details[0].driver_liscence_image;
           this.driverLiscenceNumber=this.details[0].driver_license_no;
           this.altMobileNumber=this.details[0].alt_mobile_no;
          }
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }

  handleImage(webcamImage: WebcamImage,name:string) {
    console.log("First",webcamImage);
    this.onSelect(webcamImage, null,name);
  }

  onSelect(
    e,
    methodId,name
  ) {
    console.log("Second",e);

    let frmData = new FormData();
    let imgResultAfterCompression: DataUrl;
      let file;
        this.imageCompress
          .compressFile(e._imageAsDataUrl, -2, 50, 50) // 50% ratio, 50% quality
          .then((compressedImage) => {
        console.log("Image Result After Compression",compressedImage)
            imgResultAfterCompression = compressedImage;
            file = this.base64ToFileService.base64toFile(
              imgResultAfterCompression,
              name+'.jpg'
            );
            console.log("file",file)
            console.log("frmData",frmData)
            frmData.append('files', file);
            this.fileEvent.inProgress = true;
            this.uploadFile(frmData,name);
          });
  }

  uploadFile(frmData: any,name:string) {
    const headers = new HttpHeaders();
    this.api
      .post('functional/upload-file?methodId=' + 17, frmData)
      .subscribe((res: any) => {
        switch (res.data.type) {
          case HttpEventType.UploadProgress:
            this.fileEvent.progress = Math.round(
              (res.data.loaded * 100) / res.data.total
            );
            break;
          case HttpEventType.Response:
            return res.data;
        }
        res.data.forEach((e) => {
          if(name=='driver_image'){
            console.log("Image url",e.fileUrl)
          this.image=e.fileUrl;
          // this.image="C:\Users\aashi\Downloads\istockphoto-1073597286-1024x1024.jpg";
          }else{
          this.driverLiscenceImage = e.fileUrl;
          // this.driverLiscenceImage = "C:\Users\aashi\Downloads\istockphoto-1073597286-1024x1024.jpg";
          }
        });
      });
    catchError((error: HttpErrorResponse) => {
      this.fileEvent.inProgress = false;
      this.common.loading = false;
      return `${this.fileEvent.data.name} upload failed.`;
    });
  }

  AddNew(){
    if (this.numberInput && this.numberInput.errors && this.numberInput.errors.pattern) {
      this.alertService.error('Invalid contact number format. Please enter a 10-digit number.');
    }else if(this.altNumberInput && this.altNumberInput.errors && this.altNumberInput.errors.pattern){
      this.alertService.error('Invalid Alternate contact number format. Please enter a 10-digit number.');
    }
    else{
    this.common.loading = true;
    let url;
    let params;
    if(this.assign=='Driver'){
      url='driver/add-new-driver';
      params={
        driver_name:this.name,
        mobile_no:this.number,
        driver_liscence_number:this.driverLiscenceNumber,
        driver_liscence_image:this.driverLiscenceImage,
        driver_image:this.image,
        id:this.id,
        alt_mobile_no:this.altMobileNumber

      }
    }else{
      url='transporter/add-new-transporter';
      params={
        transporter_name:this.name,
        transporter_number:this.number,
        transporter_image:this.image,
        id:this.id,
        alt_mobile_no:this.altMobileNumber
      }
    }
    this.api.post(url,params).subscribe((response:any) => {
      this.common.loading = false;
      if (response.status) {
        this.alertService.success(response.message);
        if(!this.closeFlag) this.closeModal(this.name,response.data[0].id);
        let obj={
          name:this.name,
          id:response.data[0].id,
          assignTo:this.assign,
        }
        this.SharedService.updateData(obj);
        this.saveChange.emit(obj);
      }else{
        this.alertService.success(response.message);
      }
    }, (error) => {
      this.common.loading = false;
      console.log(error);
    });
  }
}

  displayImage(url,imageOf:string) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = imageOf+' Image -'+this.name;
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  closeModal(response?: any,id?:any) {
    this.activeModal.close({ response: response,id:id});
  }

  formValidator(){
    this.nameValidator = this.common.nullValidator(
      this.name,
      'Name Mandatory'
    );
    this.numberValidator = this.common.nullValidator(
      this.number,
      'Contact Number Mandatory'
    );
    this.driverLiscenceNumberValidator = this.common.nullValidator(
      this.driverLiscenceNumber,
      'Driver Liscence Number Mandatory'
    );
    this.driverLiscenceImageValidator = this.common.nullValidator(
      this.driverLiscenceImage,
      'Driver Liscence Image Mandatory'
    );
    this.driverImageValidator = this.common.nullValidator(
      this.image,
      'Driver Image Mandatory'
    );
    if (!this.nameValidator.error && !this.numberValidator.error && ((this.assign=='Driver' && !this.driverLiscenceNumberValidator.error && !this.driverLiscenceImageValidator.error  && !this.driverImageValidator.error)|| this.assign=='transporter' )) {
      this.AddNew();
    }
  }
  nameValidator:any;
  numberValidator:any;
  driverLiscenceNumberValidator:any;
  driverLiscenceImageValidator:any;
  driverImageValidator:any;
}

import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { StageService } from '../stage/stage.service';

@Component({
  selector: 'app-overriding-actions',
  templateUrl: './overriding-actions.component.html',
  styleUrls: ['./overriding-actions.component.scss'],
})
export class OverridingActionsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;

  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'List of Overrriding Actions');

  userData: any = [];
  processList: any = [];
  stageList: any = [];
  actionList: any = [];
  overridingActionList: any = [];
  overridingData = {
    id: null,
    userId: null,
    userName: null,
    processId: null,
    processName: null,
    stageId: null,
    stageName: null,
    actionId: [],
  };
  actionSelect: any = [];
  preSelectedAction: any = [];
  actionIdForPreselected: any = [];
  actionList1: any = [];
  editFlag = 0;
  actionIdAfterEdit: null;
  actionNameAfterEdit: any = null;
  btn = 'Save';
  actionsByStageList: any[] = [];

  constructor(
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public alertService: AlertService,
    public api: ApiService,
    public stageService: StageService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Actions', url: null },
      {
        name: 'Overriding Actions Permission',
        url: '/user/overriding-actions',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);

    this.getUserData();
    this.getAllProcess();
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getOverridingActionList();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  getUserData() {
    this.common.loading = true;
    this.api.get('user/all_user').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.userData = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedUser(event) {
    this.overridingData.userId = event.userId;
    this.overridingData.userName = event.name;
  }

  getAllProcess() {
    this.common.loading = true;
    this.api.get('process/get-all-process?processActive=true').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.processList = res.data;
          this.processList.unshift({
            processId: null,
            processName: 'All',
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedProcess(event) {
    this.overridingData.processId = event.processId;
    this.overridingData.processName = event.processName;
    this.overridingData.stageId = null;
    this.overridingData.stageName = null;
    this.overridingData.actionId = [];
    this.preSelectedAction = [];
    if (this.overridingData.processId != null) {
      this.getStagesByProcessId();
    }
  }

  getStagesByProcessId(): Promise<any> {
    if (this.overridingData.processId != null) {
      return new Promise((resolve, reject) => {
        this.common.loading = true;
        let params = {
          processId: this.overridingData.processId,
        };
        this.stageService.getStageByProcessAndPlant(params).subscribe(
          (res: any) => {
            this.common.loading = false;
            if (res.status) {
              // this.stageList = res.data;
              this.stageList = res.data.map((stage) => {
                stage.stageName = stage.stage_name;
                stage.stageId = stage.stage_id;
                return stage;
              });
              this.stageList.unshift({
                stageId: null,
                stageName: 'All',
              });
              resolve(this.stageList);
            }
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
      });
    }
  }

  selectedStage(event) {
    this.overridingData.stageId = event.stageId;
    this.overridingData.stageName = event.stageName;
    if (event.stageName == 'All') {
      this.overridingData.actionId = [];
      this.actionSelect = [
        {
          actionId: null,
          actionName: null,
        },
      ];
    }
    if (this.overridingData.stageId != null) {
      this.getActionsByStageId();
    }
  }

  getActionsByStageId(): Promise<any> {
    if (this.overridingData.stageId != null) {
      return new Promise((resolve, reject) => {
        this.common.loading = true;
        let params = {
          stageId: this.overridingData.stageId,
        };
        this.api.get('actions/get-actions-by-stage-id', params).subscribe(
          (res: any) => {
            this.common.loading = false;
            if (res.status) {
              this.actionsByStageList = res.data;
            }
            resolve(this.actionsByStageList);
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
      });
    }
  }

  actionFlag = 0;
  selectedAction(event) {
    this.actionSelect = event;
    this.actionFlag = 1;
  }

  resetDetail() {
    this.overridingData = {
      id: null,
      userId: null,
      userName: null,
      processId: null,
      processName: null,
      stageId: null,
      stageName: null,
      actionId: [],
    };
    this.actionSelect = [];
    this.preSelectedAction = [];
    this.btn = 'Save';
    this.editFlag = 0;
    this.actionFlag = 0;
  }

  saveDetails() {
    if (this.editFlag == 0) {
      this.actionSelect.forEach((element) => {
        if (element.actionId != null) {
          this.overridingData.actionId.push(element.actionId);
        }
      });
    } else if (this.actionFlag == 1 && this.editFlag == 1) {
      if (this.actionSelect.actionId != null) {
        this.overridingData.actionId.push(this.actionSelect.actionId);
      }
    } else {
      if (this.actionSelect[0].actionId != null) {
        this.overridingData.actionId.push(this.actionSelect[0].actionId);
      }
    }
    let params = {
      id: this.overridingData.id,
      userId: this.overridingData.userId,
      processId: this.overridingData.processId,
      stageId: this.overridingData.stageId,
      actionIds: this.overridingData.actionId,
      plantId: this.userService._loggedInUser.plantId,
    };
    this.api.post('actions/save-action-overriding', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.getOverridingActionList();
          this.renderTable();
          this.resetDetail();
          this.editFlag = 0;
          this.actionFlag = 0;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getOverridingActionList() {
    this.common.loading = true;
    this.api.get('actions/get-all-overriding-actions').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.overridingActionList = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  userValidator: any;
  formValidations() {
    this.userValidator = this.common.nullValidator(
      this.overridingData.userId,
      'User is Mandatory'
    );
    if (!this.userValidator.error) {
      this.saveDetails();
    }
  }

  viewDetails(row?: any) {
    const tempObj = { ...row };
    delete tempObj['id'];
    this.common.params = { details: [tempObj], title: 'info' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmAlert(row: any) {
    this.common.params = {
      title: 'Delete Overriding Action ',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteData(row);
      }
    });
  }

  async editDetails(row: any) {
    this.overridingData.processId = row.processId;
    this.overridingData.stageId = row.stageId;
    await this.getActionsByStageId();
    this.btn = 'Update';
    this.overridingData.actionId = [];
    this.actionSelect = [];
    this.editFlag = 1;
    this.common.gotoTop();
    this.overridingData.id = row.id;
    this.overridingData.userId = row.userId;
    if (row.processName == null) {
      this.overridingData.processName = 'All';
    } else {
      this.overridingData.processName = row.processName;
    }
    if (row.stageName == null) {
      this.overridingData.stageName = 'All';
    } else {
      await this.getStagesByProcessId();
      this.overridingData.stageName = row.stageName;
    }
    this.overridingData.actionId = [];
    this.overridingData.userName = row.userName;
    this.actionIdAfterEdit = row.actionId;
    if (row.actionId != null) {
      this.actionNameAfterEdit = row.actionName;
      this.actionSelect.push({
        actionId: row.actionId,
        actionName: row.actionName,
      });
    } else {
      this.actionNameAfterEdit = 'All';
    }
  }

  deleteData(row) {
    this.common.loading = true;
    let params = 'id=' + row.id;
    this.api.get('actions/delete-overriding-actions?' + params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.getOverridingActionList();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"manufacturer_management"| customTranslate }}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div *ngIf="role.user_role=='AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                    <label for="my-input">{{ "select_company" | customTranslate }}</label>
                    <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  </div>
                <div *ngIf="role.user_role=='AXESTRACK' || role.user_role=='COMPANYADMIN'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{ "select_plant" | customTranslate }}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)"
                    [data]="plantData" display="plantName" placeholder="Choose Plant"
                    [preSelected]="{ plantName: plantName }">
                  </ps-auto-suggestion>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{ "manufacturer_name" | customTranslate }}</label>
                  <input class="form-control" type="text" name="manufacturerName" id="manufacturerName"
                   [(ngModel)]="manufacturer.manufacturerName" #name="ngModel" placeholder="Enter Manufacturer Name">
                  <div class="error" *ngIf="manufacturerNameValidator && manufacturerNameValidator.error">
                    <span>{{ manufacturerNameValidator.msg }} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{ "manufacturer_description" | customTranslate }}</label>
                  <input class="form-control" name="manufacturerDescription" id="manufacturerDescription"
                    [(ngModel)]="manufacturer.manufacturerDescription" #description="ngModel"
                    placeholder="Enter Manufacturer Description"/>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                    <label for="my-input" class="required">{{"status" | customTranslate}}</label>
                    <select name="manufacturer_status" id="#manufacturer_status" class="form-control"
                      [(ngModel)]="manufacturer.status">
                      <option selected="ACTIVE" value="ACTIVE">{{"active" | customTranslate}}</option>
                      <option value="INACTIVE">{{"inactive" | customTranslate}}</option>
                    </select>
                  </div>
              </div>
              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    customTranslate}}</button>
                  <button class="btn btn-warning" (click)="reset()"> {{ "reset" | customTranslate }} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()">
                    {{ buttonName | customTranslate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{ "Manufacturers List" | customTranslate }}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{ "s.no." | customTranslate }}</th>
                    <th>{{ "Manufacturer Name" | customTranslate }}</th>
                    <th>{{ "Manufacturer Description" | customTranslate }}</th>
                    <th>{{ "status" | customTranslate }}</th>
                    <th *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{ "plant_name" | customTranslate }}</th>
                    <th *ngIf="role.user_role=='AXESTRACK'">{{ "company_name" | customTranslate }}</th>
                    <th class="action">{{ "action" | customTranslate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of manufacturerList; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ row.manufacturer_name }}</td>
                    <td>{{ row.manufacturer_description }}</td>
                    <td>{{ row.status }}</td>
                    <td *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{ row.plant_name }}</td>
                    <td *ngIf="role.user_role=='AXESTRACK'">{{ row.company_name }}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewManufacturerDetails(row)">
                          {{ "view" | customTranslate }}
                        </button>
                        <button  class="btn btn-success" *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                        || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1))"
                       (click)="editManufacturer(row)">{{ "edit" | customTranslate }}
                        </button>
                        <button class="btn btn-danger" *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                        || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1))"
                        (click)="confirmDeleteManufacturer(row)"> {{ "delete" | customTranslate }}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card" *ngIf="userService.permissionMap.get('add_communication_type_access')==true ||userService.permissionMap.get('edit_communication_type_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body" >
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{("communication_type" | customTranslate)+" "+("name" | customTranslate)}} </label>
                  <input type="text" class="form-control" name="name" required
                    placeholder="Enter Communication Type Name" [(ngModel)]="communicationType.communicationTypeName"
                    #name="ngModel" autocomplete="off">
                  <div class="error" *ngIf="communicationTypeNameValidator && communicationTypeNameValidator.error">
                    <span>{{communicationTypeNameValidator.msg}} </span>
                  </div>
                </div>



                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{("communication_type" | customTranslate)+" "+("description" | customTranslate)}} </label>
                  <input type="text" class="form-control" name="communicationTypeDesc" required
                    placeholder="Enter Communication Type Desc" [(ngModel)]="communicationType.communicationTypeDesc"
                    #name="ngModel" autocomplete="off">
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{("communication_type" | customTranslate)+" "+("status" | customTranslate)}} </label>
                  <select name="communicationTypeStatus" id="#communicationTypeStatus" class="form-control"
                    [(ngModel)]="communicationType.communicationTypeStatus">
                    <option selected="ACTIVE" value="ACTIVE">{{("active" | customTranslate)}} </option>
                    <option value="INACTIVE">{{("inactive" | customTranslate)}} </option>
                    <option value="DELETED">{{("deleted" | customTranslate)}} </option>
                  </select>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>

                <div class="row form-group action-block">
                  <div class="btn-block">
                    <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{("back" | customTranslate)}}  </button>
                    <button class="btn btn-warning" (click)="resetDetail()"> {{("reset" | customTranslate)}}  </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}} </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{("communication_type" | customTranslate)+" "+("table" | customTranslate)}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <!-- table -->
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{("s.no." | customTranslate)}}</th>
                    <th> {{("name" | customTranslate)}}</th>
                    <th>{{("description" | customTranslate)}}</th>
                    <th>{{("status" | customTranslate)}}</th>
                    <th class="action">{{("action" | customTranslate)}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of responseData;let i=index">
                    <td>{{i+1}}</td>
                    <td>{{row.communicationTypeName}}</td>
                    <td>{{row.communicationTypeDesc}}</td>
                    <td>{{row.communicationTypeStatus}}</td>
                    <td class="action">
                      <div class="btn-block">

                        <button class="btn btn-primary" (click)="viewDetails(row)" *ngIf="userService.permissionMap.get('view_communication_type_access')==true"> {{("view" | customTranslate)}} </button>
                        <button class="btn btn-success" (click)="editRow(row)" *ngIf="userService.permissionMap.get('edit_communication_type_access')==true"> {{("edit" | customTranslate)}} </button>
                        <button class="btn btn-danger" (click)="confirmAlert(row)" *ngIf="userService.permissionMap.get('delete_communication_type_access')==true"> {{("delete" | customTranslate)}} </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { List } from 'lodash';
import Chart, { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import _ from 'lodash';
import { MapService } from 'src/app/@core/services/map.service';
import { Color } from 'ng2-charts';
import { Router } from '@angular/router';
import { AlertCardModalComponent } from '../alert-card-modal/alert-card-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserTrackingInfoModalComponent } from 'src/app/modals/user-tracking-info-modal/user-tracking-info-modal.component';


declare var google: any;
@Component({
  selector: 'app-assets-tracking-dasboard',
  templateUrl: './assets-tracking-dasboard.component.html',
  styleUrls: ['./assets-tracking-dasboard.component.scss']
})
export class AssetsTrackingDasboardComponent implements OnInit {

  @ViewChild('chartContainer1', { static: true }) chartContainer1: ElementRef<HTMLDivElement>;
  @ViewChild('chartContainer2', { static: true }) chartContainer2: ElementRef<HTMLDivElement>;

  dashBoardData: any[] = [];
  totalAssets: number = 0;
  allAssets: any[] = [];
  statusWiseData: Record<string, any[]>;
  statusColors: { [key: string]: List<string> } = {
    'AVAILABLE': ['#32CD32', 'fa fa-check-circle'],
    'UNAVAILABLE': ['#FF4500', 'fa fa-exclamation-circle'],
    'ASSIGNED': ['#191970', 'fa fa-user'],
    'CHECKEDIN': ['#DEB887', 'fa fa-check'],
    'CHECKEDOUT': ['#b9af27', 'fa fa-times']
  };
  categoryColors: { [key: string]: string } = {};
  categoryWiseData: Record<string, any> = [];
  noCategoryFound: boolean = false;
  filteredCategories: Record<string, any> = [];
  maintenanceData: any[] = [];
  maintenanceUpcomingData: any[] = [];
  warrantyData: any[] = [];
  warrantyUpcomingData: any[] = [];
  depreciationData: any[] = [];
  depreciationUpcomingData: any[] = [];
  insuranceData: any[] = [];
  insuranceUpcomingData: any[] = [];
  searchTerm: string = '';
  deptWiseData: Record<string, any> = [];
  grpWiseData: Record<string, any> = [];
  mostUsedAssets: any = [];
  leastUsedAssets: any = [];
  deptDoNutData: any[] = [];
  grpDoNutData: any[] = [];
  checkedOutPending: any[] = [];
  checkedOutUpcoming: any[] = [];
  checkedInPending: any[] = [];
  checkedInUpcoming: any[] = [];
  deptColors: { [key: string]: string } = {};
  grpColors: { [key: string]: string } = {};
  trendGraphData: any = [];
  assetLocationLog: any = [];
  tableModalData: any = [];
  zonedata: any = [];
  zoneGroupById: any = [];
  hardwareData: any = {};
  statusWiseMaintenance: any = [];
  days: number = 7;
  zoneColors: string[] = ['#7741d7', '#33bbb9', '#ab8800', '#c33979', '#378737'];
  totalAssetsList: any[] = [];
  modalTitle: any = '';
  allAssetsWithIdkey: Record<string, any> = [];
  pendingData: Record<string, any> = [];
  pendingAlerts: any[] = [];
  resolvedAlerts: any[] = [];
  warrantyExpiredData: any[] = [];
  insuranceExpiredData: any[] = [];
  miantenanceExpiredData: any[] = [];
  assets: any[] = [];
  geofenceId: number = null;

  constructor(
    private modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public api: ApiService,
    public alert: AlertService,
    public role: RoleService,
    public table: TableService,
    private mapService: MapService,
    public router: Router,
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.setActiveBreadCrumb(false);
    this.common.sidebarToggled(this.common.menuToggled);
    this.getDashBoardData();
    this.getAllAssets();
  }


  public areaChartLegend = true;
  public areaChartType: ChartType = 'line';
  public areaChartData: ChartDataSets[] = []

  public areaChartOptions: ChartOptions = {
    responsive: true,
    title: {
      display: false,
    },
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'DD MMM YYYY'
          }
        },
        ticks: {
          source: 'data'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Count',
          fontSize: 12,
          fontStyle: 'bold',
          fontFamily: ' sans-serif',
        },
        ticks: {
          beginAtZero: true,
          stepSize: 1

        }
      }]
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += tooltipItem.yLabel;
          return label;
        },
        title: function (tooltipItem, data) {
          const date = new Date(tooltipItem[0].xLabel);
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        }
      }
    }
  };
  public areaChartColors1: Color[] = [
    { backgroundColor: 'rgba(54, 162, 235, 0.2)', borderColor: 'rgba(54, 162, 235, 1)' },
    { backgroundColor: 'rgba(255, 99, 132, 0.2)', borderColor: 'rgba(255, 99, 132, 1)' },
    { backgroundColor: 'rgba(255, 206, 86, 0.2)', borderColor: 'rgba(255, 206, 86, 1)' },
    // Add more colors as needed
  ];
  public areaChartLegend1 = false;
  public areaChartType1: ChartType = 'bar'; // Change to 'bar' type for bar graph
  public areaChartData1: ChartDataSets[] = [];
  public areaChartLabels1: any = [];
  public areaChartOptions1 : ChartOptions = {
      responsive: true,
      title: {
        display: false,
        text: 'Maintenance Count by Status' // Change the title accordingly
      },
      maintainAspectRatio: true,
      animation: {
        duration: 1000,
        easing: 'linear',
        animateRotate: true,
        animateScale: true,
      },
      plugins: {
        legend: {
          display: false,
          position: 'right',
        },
      },
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Status', // X-axis label
            fontSize: 12,
            fontStyle: 'bold',
            fontFamily: 'sans-serif',
            padding: 0
          },
          ticks: {
            padding: 5, // Reduce padding between the ticks and labels
            lineHeight: 1.2, // Adjust line height to bring labels closer
            fontSize: 10
          },
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Count', // Y-axis label
            fontSize: 12,
            fontStyle: 'bold',
            fontFamily: 'sans-serif',
          },
          ticks: {
            beginAtZero: true,
            stepSize: 5,
            min: 0,
          }
        }]
      }
    };

  getAllAssets() {
    this.common.loading = true;
    let apiUrl = 'assets/get-all-assets?allAssets=false&categoryId=' + -1;
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.data.length) {
          this.assets = res.data;
          console.log("assets", this.assets)
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  findKeyWithAssetId(data: any, assetId: number): number | null {
    for (const key in data) {
      if (data[key].some((asset: any) => asset.asset_id === assetId)) {
        return Number(key);
      }
    }
    return null;
  }

  selectedAsset(event: any) {
    console.log("event", event)
    if (this.geofenceId != null) {
      this.mapService.callingToggleBounce(this.geofenceId, 2)
    }
    let hardwareId = this.findKeyWithAssetId(this.hardwareData, event.asset_id);
    console.log("hardwareId", hardwareId);
    this.geofenceId = this.allGeoFence[0]?.hardware.filter(hardware => hardware.ipa_hardware_id == hardwareId)[0]?.geofence_id ?? null;
    console.log("geofenceId", this.geofenceId);
    //stopping old animation if exists

    //starting new search animation
    this.mapService.callingToggleBounce(this.geofenceId);
  }


  filterCategories(): void {
    // If search term is empty, display all categories
    if (!this.searchTerm.trim()) {
      this.filteredCategories = this.categoryWiseData;
      this.noCategoryFound = false;
      console.log('this.filteredCategories: ', this.filteredCategories);
    } else {
      // Filter categories based on the search term
      this.filteredCategories = {};
      this.noCategoryFound = true;
      for (const category in this.categoryWiseData) {
        if (category.toLowerCase().includes(this.searchTerm.toLowerCase())) {
          this.filteredCategories[category] = this.categoryWiseData[category];
          this.noCategoryFound = false;
        }
      }
    }
  }

  isZoneDataEmpty(zone: any): boolean {
    return zone['zone_data'] != null ? Object.keys(zone['zone_data']).length === 0 : false;
  }
  ifDeptWiseData(): boolean {
    return this.deptWiseData != null ? Object.keys(this.deptWiseData).length === 0 : false;
  }
  ifGrpWiseData(): boolean {
    return this.grpWiseData != null ? Object.keys(this.grpWiseData).length === 0 : false;
  }


  viewAlert(alertData, alert_condition, type_name, alert, alert_name) {
    console.log("alertData", alertData)
    // let data = this.reorderAlertData(JSON.parse(alertData));
    let data = JSON.parse(alertData);
    console.log("data", data)
    const activeModal = this.modalService.open(AlertCardModalComponent, {
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      alert_type_name: data.title,
      alert_remark: data.remark,
      alert_data: data.data,
      showAlertData: true,
      alert_condition: alert_condition,
      type_name: type_name,
      asset: true,
      alert: alert,
      alert_name: alert_name
    };
    console.log("refData", activeModal.componentInstance.refData)
  }



  handleModalClose() {
    this.tableModalData = [];
  }

  viewAsset(row?: any) {
    // const secretKey = btoa(row.asset_id);
    // this.router.navigate(['user/asset-view'], {
    //   queryParams: { secretKey },
    // });
      this.common.params = {
        userId:row.asset_id,
        userType:'ASSET'
    }
      const activeModal = this.modalService.open(UserTrackingInfoModalComponent, {
        size: 'xl',
      });
  }

  onSearchTermChange(): void {
    this.filterCategories();
  }

  generateRandomColor(): string {
    // Generate random RGB values
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    // Construct the color string
    return `rgb(${r},${g},${b})`;
  }
  getTrendChartData() {
    let params = {
      days: this.days
    };
    this.api.get('assets-tracking/get-trend-chart-data', params).subscribe(
      (res: any) => {
        if (res.status) {
          let data = res.data[0];
          this.trendGraphData = data;
          console.log('this.trendGraphData: ', this.trendGraphData);
          this.makeAreaChartData();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );

  }
  makeAreaChartData() {
    this.areaChartData = [
      {
        data: this.trendGraphData.map(item => ({ x: new Date(item.date), y: item.request })),
        label: 'Request',
        fill: false,
        pointBackgroundColor: 'rgba(255, 99, 132, 0.5)', // Pink
        pointBorderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 14,
        lineTension: 0
      },
      {
        data: this.trendGraphData.map(item => ({ x: new Date(item.date), y: item.checked_in })),
        label: 'Checked In',
        fill: false,
        pointBackgroundColor: 'rgba(54, 162, 235, 0.2)', // Blue
        pointBorderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 2,
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 14,
        lineTension: 0
      },
      {
        data: this.trendGraphData.map(item => ({ x: new Date(item.date), y: item.checked_out })),
        label: 'Checked Out',
        fill: false,
        pointBackgroundColor: 'rgba(255, 206, 86, 0.2)', // Yellow
        pointBorderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 2,
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 14,
        lineTension: 0
      }
    ];
  }

  // Convert the object into an array of its entries (key-value pairs)
  sortZoneDataByAssetLength(zoneData: Record<string, any>): Record<string, any> {
    // Convert the object into an array of its entries (key-value pairs)
    const entries = Object.entries(zoneData);
    console.log("entries", zoneData, entries);
    // Sort the array based on the length of the `asset` array for each key
    const sortedEntries = entries.sort((a, b) => (b[1].asset?.length ?? 0) - (a[1].asset?.length ?? 0));
    console.log("sortedEntries", sortedEntries);
    // Convert the sorted array back into an object
    const sortedZoneData = Object.fromEntries(sortedEntries);
    console.log("sortedZoneData", sortedZoneData);
    return sortedZoneData;
  }


  processData(dataArray){
    for(const array of dataArray){
     if(array['asset_id'] in this.allAssetsWithIdkey){
       const assetInfo=this.allAssetsWithIdkey[array['asset_id']];
       array['zone_name']=assetInfo[0]['zone_name'];
       array['lastping']=assetInfo[0]['lastping'];
     }
     else{
       array['zone_name']='-';
       array['lastping']='-';
     }
    }
 }

  getDashBoardData() {
    let params = {
      days: this.days
    }
    this.api.get('assets-tracking/get-dashboard-data', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.dashBoardData = res.data;
          console.log('this.dashBoardData: ', this.dashBoardData);
          this.statusWiseData = this.dashBoardData[0].statusWiseData;
          this.categoryWiseData = this.dashBoardData[0].categoryWiseData;
          for (const [key, value] of Object.entries(this.categoryWiseData)) {
          console.log(' this.categoryColor  s: ', value, value['asset']);
          }
          this.checkedOutPending = this.dashBoardData[0].assetLifeData.checkedOutPendingList;
          this.checkedOutUpcoming = this.dashBoardData[0].assetLifeData.checkedOutDueList;
          console.log('this.checkedOutUpcoming: ', this.checkedOutUpcoming);
          console.log('this.checkedOutPending: ', this.checkedOutPending);
          this.checkedInPending = this.dashBoardData[0].assetLifeData.checkedInPendingList;
          this.checkedInUpcoming = this.dashBoardData[0].assetLifeData.checkedInDueList;
          console.log('this.checkedInUpcoming: ', this.checkedInUpcoming);
          console.log('this.checkedInPending: ', this.checkedInPending);
          this.maintenanceData = this.dashBoardData[0].assetLifeData.maintenanceData;
          this.maintenanceUpcomingData = this.dashBoardData[0].assetLifeData.maintenanceOverdueData;
          console.log('this.maintenanceUpcomingData: ', this.maintenanceUpcomingData);
          console.log('this.maintenanceData: ', this.maintenanceData);
          this.warrantyData = this.dashBoardData[0].assetLifeData.warrantyData;
          this.warrantyUpcomingData = this.dashBoardData[0].assetLifeData.warrantyDueData;
          console.log('this.warrantyUpcomingData: ', this.warrantyUpcomingData);
          this.insuranceData = this.dashBoardData[0].assetLifeData.insuranceData;
          this.insuranceUpcomingData = this.dashBoardData[0].assetLifeData.insuranceDueData;
          console.log('this.insuranceUpcomingData: ', this.insuranceUpcomingData);
          this.depreciationData = this.dashBoardData[0].assetLifeData.depreciationData;
          this.depreciationUpcomingData = this.dashBoardData[0].assetLifeData.depreciationDueData;
          console.log('this.depreciationUpcomingData: ', this.depreciationUpcomingData);
          console.log('this.depreciationData: ', this.depreciationData);
          this.trendGraphData = this.dashBoardData[0].trendGraphData;
          console.log('this.trendGraphData: ', this.trendGraphData);
          this.assetLocationLog = this.dashBoardData[0].assetLocationLog;
          console.log('this.assetLocationLog: ', this.assetLocationLog);
          this.statusWiseMaintenance = this.dashBoardData[0].statusWiseMaintenance;
          console.log('this.statusWiseMaintenance: ', this.statusWiseMaintenance);
          this.mostUsedAssets = this.dashBoardData[0].mostUsedAssets;
          console.log('this.mostUsedAssets: ', this.mostUsedAssets);
          this.leastUsedAssets = this.dashBoardData[0].leastUsedAssets;
          console.log('this.leastUsedAssets: ', this.leastUsedAssets);
          console.log('this.categoryWiseData: ', this.categoryWiseData);
          console.log('this.statusWiseData: ', this.statusWiseData);
          this.dashBoardData[0].allAlerts.forEach((data) => {
            data['new_alert_data'] = JSON.parse(data.alert_data)
          })
          this.pendingAlerts = this.dashBoardData[0].allAlerts.filter(data => data.status === 'INPROGRESS' || data.status === 'ACTIVE');
          console.log('this.pendingAlerts: ', this.pendingAlerts.length);
          this.resolvedAlerts = this.dashBoardData[0].allAlerts.filter(data => data.status === 'COMPLETED');
          this.resolvedAlerts.forEach((data) => {
            data.remark_data = JSON.parse(data.remark_data);
          })
          console.log(this.resolvedAlerts, 'resoved')
          this.zonedata = this.dashBoardData[0].assetLocationLog;
          this.zonedata.forEach((zone) => {
            this.zoneGroupById[zone.zone_id] = zone.zone_data;
            zone.zone_data.forEach((assets) => {
              if (this.hardwareData[assets.hardware_id] == null) {
                this.hardwareData[assets.hardware_id] = [assets];
              }
              else {
                this.hardwareData[assets.hardware_id].push(assets);
              }
            })
          })

          console.log('this.zoneGroupById: ', this.zoneGroupById, this.hardwareData);
          // this.getGeoFence();

          //  console.log
          this.assetLocationLog.forEach(zone => {
            zone.total_asset = zone.zone_data.length;
            zone.zone_data.forEach(asset => {
              asset.zone_id = zone.zone_id;
              asset.zone_name = zone.zone_name;
              this.allAssets.push(asset);
            })
            const new_category_key = {}
            zone.zone_data = _.groupBy(zone.zone_data, 'asset_category_name');
            for (const [key, value] of Object.entries(zone.zone_data)) {
              if (zone.zone_data.hasOwnProperty(key)) {
                let obj = {
                  "asset_category_image": value[0].asset_category_image,
                  "asset": value
                }
                zone.zone_data[key] = obj;
              }
            };
          });

          //adding all categories in assetLocationLog with empty array
          // const categories = Object.entries(this.categoryWiseData);

          this.assetLocationLog.forEach(zone => {
            for (const [key, value] of Object.entries(this.categoryWiseData)) {
              if (!zone.zone_data.hasOwnProperty(key)) {
                let obj = {
                  "asset_category_image": value.asset_category_image,
                  "asset": []
                }
                zone.zone_data[key] = obj;
              }
            };
            zone.zone_data = this.sortZoneDataByAssetLength(zone.zone_data);
            console.log("zone_data", zone.zone_data);
          })

          console.log('this.assetLocationLog: ', this.assetLocationLog);
          this.allAssetsWithIdkey = _.groupBy(this.allAssets, 'asset_id');

          // giving assigned to tag

          console.log(' this.allAssetsWithIdkey: ', this.allAssetsWithIdkey);

          this.areaChartData1 = [{
            data: this.statusWiseMaintenance == null ? [] : this.statusWiseMaintenance.map(item => item.count), // Y-axis data (counts)
            label: 'Maintenance Count', // Legend  label
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 2,
            barThickness: 45 // Set the bar width to 20px
          }];


          // Define x-axis labels based on status names
          this.areaChartLabels1 = this.statusWiseMaintenance == null ? [] : this.statusWiseMaintenance.map(item => item.maintenance_status);
          this.makeAreaChartData();
          this.filterCategories();

            for (const array of this.maintenanceData) {
              if (array['asset_id'] in this.allAssetsWithIdkey) {
                const assetInfo = this.allAssetsWithIdkey[array['asset_id']];
                array['zone_name'] = assetInfo[0]['zone_name'];
                array['lastping'] = assetInfo[0]['lastping'];
              }
              else {
                array['zone_name'] = '-';
                array['lastping'] = '-';
              }
              if (array['data'].length) {
                array['data'].forEach(element => {
                  let obj = {
                    asset_name: array['asset_name'],
                    asset_category_name: array['asset_category_name'],
                    zone_name: array['zone_name'],
                    lastping: array['lastping'],
                    asset_id: array['asset_id'],
                    name: element.name,
                    due_date: element.due_date,
                    user_name: element.user_name,
                  }
                  console.log('element: ', element, obj);
                  this.miantenanceExpiredData.push(obj);
                });
              } else {
                let obj = {
                  asset_name: array['asset_name'],
                  asset_category_name: array['asset_category_name'],
                  zone_name: array['zone_name'],
                  lastping: array['lastping'],
                  asset_id: array['asset_id'],
                  name: '-',
                  due_date: '-',
                  user_name: '-',
                }
                this.miantenanceExpiredData.push(obj);
              }
              console.log("maintennce", this.miantenanceExpiredData);
            }

            for (const array of this.warrantyData) {
              if (array['asset_id'] in this.allAssetsWithIdkey) {
                const assetInfo = this.allAssetsWithIdkey[array['asset_id']];
                array['zone_name'] = assetInfo[0]['zone_name'];
                array['lastping'] = assetInfo[0]['lastping'];
              }
              else {
                array['zone_name'] = '-';
                array['lastping'] = '-';
              }
              let obj = {
                asset_name: array['asset_name'],
                asset_category_name: array['asset_category_name'],
                zone_name: array['zone_name'],
                lastping: array['lastping'],
                asset_id: array['asset_id'],
              }
              if (array['data'].length) {
                array['data'].forEach(element => {
                  let obj = {
                    asset_name: array['asset_name'],
                    asset_category_name: array['asset_category_name'],
                    zone_name: array['zone_name'],
                    lastping: array['lastping'],
                    asset_id: array['asset_id'],
                    name: element.name,
                    due_date: element.due_date,
                  }
                  console.log('element: ', element, obj);
                  this.warrantyExpiredData.push(obj);
                });
              } else {
                let obj = {
                  asset_name: array['asset_name'],
                  asset_category_name: array['asset_category_name'],
                  zone_name: array['zone_name'],
                  lastping: array['lastping'],
                  asset_id: array['asset_id'],
                  name: '-',
                  due_date: '-',
                }
                this.warrantyExpiredData.push(obj);
              }
              console.log("warrantyExpiredData", this.warrantyExpiredData);
            }
            for (const array of this.insuranceData) {
              if (array['asset_id'] in this.allAssetsWithIdkey) {
                const assetInfo = this.allAssetsWithIdkey[array['asset_id']];
                array['zone_name'] = assetInfo[0]['zone_name'];
                array['lastping'] = assetInfo[0]['lastping'];
              }
              else {
                array['zone_name'] = '-';
                array['lastping'] = '-';
              }

              if (array['data'].length) {
                array['data'].forEach(element => {
                  let obj = {
                    asset_name: array['asset_name'],
                    asset_category_name: array['asset_category_name'],
                    zone_name: array['zone_name'],
                    lastping: array['lastping'],
                    asset_id: array['asset_id'],
                    name: element.name,
                    due_date: element.due_date,
                  }
                  console.log('element: ', element, obj);
                  this.insuranceExpiredData.push(obj);
                });
              } else {
                let obj = {
                  asset_name: array['asset_name'],
                  asset_category_name: array['asset_category_name'],
                  zone_name: array['zone_name'],
                  lastping: array['lastping'],
                  asset_id: array['asset_id'],
                  name: '-',
                  due_date: '-',
                }
                this.insuranceExpiredData.push(obj);
              }
              console.log("insurance", this.insuranceExpiredData);
            }


            this.processData(this.depreciationData);
            this.processData(this.checkedInPending);
            this.processData(this.checkedOutPending);
            this.processData(this.maintenanceUpcomingData);
            this.processData(this.warrantyUpcomingData);
            this.processData(this.insuranceUpcomingData);
            this.processData(this.depreciationUpcomingData);
            this.processData(this.checkedOutUpcoming);
            this.processData(this.checkedInUpcoming);
            this.processData(this.mostUsedAssets);
            this.processData(this.leastUsedAssets);
          // filling the colors


          for (const [key, value] of Object.entries(this.categoryWiseData)) {
            console.log(' this.categoryColors: ', value, value['asset']);
            this.categoryColors[key] = this.generateRandomColor();
            this.processData(value['asset']);
          }
          console.log(' this.categoryColors: ', this.categoryColors);
          // counting total assets
          let total = 0;
          for (const [key, value] of Object.entries(this.statusWiseData)) {
            total += this.statusWiseData[key].length;
            this.totalAssetsList.push(...value);
            this.processData(value);
          }
          this.assetLocationLog.forEach
          this.totalAssets = total;
        }
        console.log('this.allAssets: ', this.allAssets);
        console.log('this.allAssets: ', this.totalAssetsList);
        this.totalAssetsList.forEach(asset => {
          console.log('asset: ', asset);
          const assetId = asset.asset_id;
          const user_name = asset.user_name;
          console.log('this.allAssetsWithIdkey[assetId]: ', this.allAssetsWithIdkey[assetId]);
          if (this.allAssetsWithIdkey[assetId]) {
            this.allAssetsWithIdkey[assetId].forEach(assetItem => {
              assetItem['user_name'] = user_name;
            })
          }
        })



        console.log("tdashboardData", this.dashBoardData[0]);
        this.deptWiseData = this.dashBoardData[0].deptWiseData;
        Object.entries(this.deptWiseData).forEach((el: any) => {
          let data = {
            label: el[0],
            value: el[1].length
          };
          this.deptDoNutData.push(data);
        });
        this.drawDonutChart(this.deptDoNutData, this.chartContainer1.nativeElement, 'dept');

        this.grpWiseData = this.dashBoardData[0].grpWiseData;
        Object.entries(this.grpWiseData).forEach((el: any) => {
          let data = {
            label: el[0],
            value: el[1].length
          };
          this.grpDoNutData.push(data);
        });

        for (const [key, value] of Object.entries(this.deptWiseData)) {
          // this.categoryColors[key] = this.generateRandomColor();
          this.processData(value);
        }
        for (const [key, value] of Object.entries(this.grpWiseData)) {
          // this.categoryColors[key] = this.generateRandomColor();
          this.processData(value);
        }

        this.drawDonutChart(this.grpDoNutData, this.chartContainer2.nativeElement, 'grp');
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );

  }

  returnMapKeyValue(value: any) { return value as any }

  drawDonutChart(chartData: any[], container: HTMLDivElement, flag: any) {
    console.log('chartData: ', chartData);
    console.log('container: ', container);
    const colorPalette = [
      'rgba(0, 34, 77, 0.8)',     // Dark Midnight Blue
      'rgba(67, 10, 93, 0.8)',    // Dark Purple
      'rgba(27, 66, 66, 0.8)',    // Dark Cyan
      'rgba(80, 60, 60, 0.8)',    // Dark Red
      'rgba(0, 51, 124, 0.8)',    // Oxford Blue
      'rgba(7, 10, 82, 0.8)',     // Dark Blue
      'rgba(149, 1, 1, 0.8)',     // Dark Candy Apple Red
      'rgba(40, 83, 84, 0.8)',    // Smoky Black
      'rgba(79, 59, 119, 0.8)',   // Purple Taupe
      'rgba(19, 94, 94, 0.8)',    // Teal Blue
      'rgba(95, 81, 109, 0.8)',   // Davy's Grey
      'rgba(28, 32, 80, 0.8)',    // Dark Blue-Gray
      'rgba(77, 0, 77, 0.8)',     // Dark Violet
      'rgba(46, 64, 128, 0.8)',   // Medium Slate Blue
      'rgba(0, 29, 64, 0.8)',     // Dark Blue
      'rgba(13, 78, 78, 0.8)',    // Dark Jungle Green
      'rgba(53, 94, 59, 0.8)',    // Dark Green
      'rgba(139, 69, 19, 0.8)',   // Saddle Brown
      'rgba(128, 0, 128, 0.8)',   // Dark Magenta
      'rgba(128, 0, 0, 0.8)',     // Dark Red
      'rgba(153, 153, 0, 0.8)',   // Dark Olive Green
      'rgba(255, 204, 0, 0.8)',   // Golden Yellow
      'rgba(255, 153, 51, 0.8)',  // Mango Tango
      'rgba(255, 102, 102, 0.8)', // Pastel Red
      'rgba(119, 65, 215, 0.8)',  // Dark Purple
      'rgba(51, 187, 185, 0.8)',  // Turquoise
      'rgba(171, 136, 0, 0.8)',   // Dark Goldenrod
      'rgba(195, 57, 121, 0.8)',  // Medium Red Violet
      'rgba(55, 135, 55, 0.8)'    // Dark Sea Green
      // Add more colors as needed
    ];
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 150;
    canvas.height = 150;
    container.appendChild(canvas);

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(canvas.width, canvas.height) / 2;

    let total = 0;
    chartData.forEach(data => (total += data.value));

    let startAngle = 0;
    chartData.forEach(data => {
      const sliceAngle = (2 * Math.PI * data.value) / total;
      // const colorIndex = Math.floor(Math.random() * colorPalette.length);
      let colorIndex;

      do {
        colorIndex = Math.floor(Math.random() * colorPalette.length);
      } while (flag === 'dept' && this.deptColors[data.label] === colorPalette[colorIndex]);
      do {
        colorIndex = Math.floor(Math.random() * colorPalette.length);
      } while (flag === 'grp' && this.grpColors[data.label] === colorPalette[colorIndex]);

      ctx.fillStyle = colorPalette[colorIndex];

      // ctx.fillStyle = this.getRandomColor();
      console.log('ctx.fillStyle: ', ctx.fillStyle);
      if (flag == 'dept') {
        this.deptColors[data.label] = ctx.fillStyle

      } else {

        this.grpColors[data.label] = ctx.fillStyle

      }
      console.log('this.deptColors: ', this.deptColors);
      console.log('this.grpColors: ', this.grpColors);

      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, radius, startAngle, startAngle + sliceAngle);
      ctx.closePath();
      ctx.fill();
      startAngle += sliceAngle;
    });

    // Draw the inner circle to create the donut shape
    ctx.fillStyle = '#fff';
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius * 0.6, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();

    const chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: chartData.map(data => data.label),
        datasets: [{
          data: chartData.map(data => data.value),
          backgroundColor: chartData.map(data => {
            if (flag == 'dept') {
              return this.deptColors[data.label];
            }
            else {
              return this.grpColors[data.label];
            }
          }),
          hoverBackgroundColor: chartData.map(data => {
            if (flag == 'dept') {
              return this.deptColors[data.label];
            }
            else {
              return this.grpColors[data.label];
            }
          })
        }]
      },
      options: {
        legend: {
          display: false,
        },
        cutoutPercentage: 60,
        animation: {
          animateRotate: true, // Enable rotation animation
          animateScale: true, // Enable scale animation
          easing: 'easeOutSine', // Set easing function
          duration: 1000 // Set animation duration in milliseconds
        }
      }
    });
  }

  getRandomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  drawEmptyDonutChart(container: HTMLDivElement) {
    const canvas = document.createElement('canvas');
    canvas.width = 150;
    canvas.height = 150;
    container.appendChild(canvas);

    const ctx = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(canvas.width, canvas.height) / 2;

    ctx.fillStyle = '#fff'; // Set color for the empty circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius * 0.6, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();
  }

  allGeoFence: any;
  modalDetailTitle: string = '';
  detailData: any[] = [];
  detailAllData: any[] = [];
  detailType: any;
  path: any[];

  getGeoFence(processId = null) {
    this.common.loading = true;
    let apiUrl = 'geofence/get-plant-geofence-by-processId';
    if (processId) {
      apiUrl = `geofence/get-plant-geofence-by-processId?processId=${processId}`;
    }
    this.api.get(apiUrl, { callingFromGeofence: false }).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.allGeoFence = res.data || [];
        if (this.allGeoFence.length) {
          this.allGeoFence.forEach((element) => {
            element.plantLatLong = this.getSiteGeoFenceLatLong(element, this.hardwareData);
          });
          console.log('this.allGeoFence: ', this.allGeoFence);

          setTimeout(() => {
            this.viewGeoFence(this.allGeoFence[0]);
          }, 100);
        } else {
          this.mapService.clearAll();
        }
      },
      (err) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  viewGeoFence(row: any, event = false) {
    let latLong = { lat: row.lat, lng: row.long };
    this.clearMapServices();
    this.mapService.centerAt(latLong);
    this.mapService.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    this.LoadGeoFence(row.plantLatLong, event);
    let plantGeoFence = row.plantLatLong.find((item) => item.type == 5).data;

    plantGeoFence.forEach((element) => {
      this.mapService.setBounds(
        this.mapService.createLatLng(element.lat, element.lng)
      );
    });

    let thisClass = this;
    this.mapService.zoomAt(latLong, 12);
    this.mapService.markers.forEach((marker: any) => {
      this.mapService.addListerner(marker, 'click', function (event: any) {
        this.mapDetailId = marker.id;
        this.mapDetailLabel = marker.label.text;
        thisClass.modalDetailTitle = marker.labelName;
        thisClass.detailData = row.plantLatLong.find(
          (item) =>
            item.id == this.mapDetailId && item.label == this.mapDetailLabel
        ).trips;
        thisClass.detailAllData = row.plantLatLong.find(
          (item) =>
            item.id == this.mapDetailId && item.label == this.mapDetailLabel
        ).trips;
        thisClass.detailType = marker.type;
        thisClass.tableModalData = marker?.ref_data ?? [];
        console.log('marker.ref_data: ', marker.ref_data);
        setTimeout(() => {
          let modal = document.getElementById('detailModal');
          modal.classList.add('d-block');
        }, 50);
      });
    });
  }

  closeModal(res: boolean) {
    let modal = document.getElementById('detailModal');
    modal.classList.remove('d-block');
    this.tableModalData = [];
  }

  getSiteGeoFenceLatLong(data: any, hardware_data: any) {
    let latLongs = [];
    if (data.pzone && data.pzone.length) {
      data.pzone.forEach((zone) => {
        if (zone.pos) {
          latLongs.push({
            type: 5,
            label: `${zone.zone_name} (${this.zoneGroupById[zone.id]?.length})`,
            // label: zone.zone_name,
            lat: zone.lat,
            lng: zone.long,
            radius: zone.radius,
            data: this.getLatLongFromPolygon(zone.pos),
            id: zone.geofence_id,
            zoneId: zone.id,
            ref_data: this.zoneGroupById[zone.id],
            labelName:`${zone.zone_name} (${this.zoneGroupById[zone.id]?.length})`,
          });
        }
      });
    }
    /// get verified after adding zone api of saloni branch
    if (data.hardware && data.hardware.length) {
      data.hardware.forEach((hardware) => {
        let asset_count = hardware_data[hardware.ipa_hardware_id] != null ? hardware_data[hardware.ipa_hardware_id].length : 0
        let hardware_asset_data = hardware_data[hardware.ipa_hardware_id] != null ? hardware_data[hardware.ipa_hardware_id] : []
        console.log('hardware_asset_data: ', hardware_asset_data);
        if (hardware.pos) {
          latLongs.push({
            type: 7,
            label: `(${asset_count})`,
            lat: hardware.lat,
            lng: hardware.long,
            radius: hardware.radius,
            data: this.getLatLongFromPolygon(hardware.pos),
            id: hardware.geofence_id,
            hardWareId: hardware.ipa_hardware_id,
            ref_data: hardware_asset_data,
            labelName:`${hardware.hardware_name}(${asset_count})`
          });
        }
      });
    }
    return latLongs;
  }
  getLatLongFromPolygon(str) {
    let latLong = [];

    if (!str.includes('EMPTY') && !str.includes('OLLECTION EMP')) {
      let latLongString = String(str).split(',');
      latLongString.forEach((item) => {
        let latLongs = item.split(' ').map((item) => Number(item));
        latLong.push({ lat: latLongs[0], lng: latLongs[1] });
      });
    }
    return latLong;
  }

  LoadGeoFence(data, event = false) {
    let latLngsArray = [];
    if (data.length > 0) {
      latLngsArray.push(data);
      console.log("latLngsArray", latLngsArray)
      this.mapService.createPlantGeoFence(data, event, false);
    }

  }

  ngAfterViewInit() {
    this.common.sidebarToggled(this.common.menuToggled);
    this.mapService.mapIntialize('map');
    setTimeout(() => {
      this.mapService.setMapType(0);
    }, 2000);
  }

  clearMapServices() {
    this.mapService.resetPolygons();
    this.mapService.clearAll();
    this.mapService.resetMarker();
    this.path = [];
  }


}

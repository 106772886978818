<div class="container-fluid" #dataToExport>
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card" id="dashboardScreen">
              <div class="header-block w-100" style="display: flex; justify-content:flex-start;flex-direction: row;align-items: center;">
                <h3 class="card-title" style="font-size: 20px;font-weight: 700;padding-top: 10px;padding-left: 10px;">
                  {{'Sealing Report'|translate}}
                </h3>
                <i class="fa fa-download" style="margin-left: 10px;font-size: 20px;color:rgb(38, 38, 145);cursor: pointer;" (click)="downloadAsPdf()"></i>
              </div>

            <div class="top-boxes" *ngIf="common.loading == false">
              <div class="top-box" [ngClass]="{'highlighted-box-red': sealingCount != unSealingCount ,
              'highlighted-box-green': sealingCount == unSealingCount}"
                *ngIf="reportData.actionsWithPreActions" [hidden]="!barcodeExist">
                <div class="box-column">
                  <h4>Sealing</h4>
                  <p>{{sealingCount}}</p>
                </div>
                <div class="box-column">
                  <h4>Unsealing</h4>
                  <p>{{unSealingCount}}</p>
                </div>
              </div>

              <div class="top-box highlighted-box-red" *ngFor="let log of reportData.alertCards"
                (click)="viewAlert(log.alert_data)" [style.background-color]="log.type_color">
                <div class="box-column">
                  <h4 style="line-height: 25px;">{{log.alert_data.title}}</h4>
                  <p><ng-container *ngIf="isArray(log.alert_data.data.response); else stringContent">
                      {{ log.alert_data.data.response.length }}
                    </ng-container>
                    <ng-template #stringContent>
                      {{ log.alert_data.data.response }}
                    </ng-template>
                  </p>
                </div>
              </div>

            </div>

            <div class="vehicle_detail" width="100%" style="margin-top: 0px;"  *ngIf="common.loading == false">
              <div class="row">
                <div class="col-md-3 row_col">
                  <div class="key_value">
                    <span class="key">Trip Id: </span>
                    <span class="value">{{reportData?.extTripId}}</span>
                  </div>
                </div>
                <div class="col-md-3 row_col">
                  <div class="key_value">
                    <span class="key">Invoice No.: </span>
                    <span class="value">{{invoiceTripNumber}}</span>
                  </div>
                </div>
                <div class="col-md-3 row_col">
                  <div class="key_value">
                    <span class="key">Vehicle No.: </span>
                    <span class="value">{{reportData?.vehicleRCNo}}</span>
                  </div>
                </div>
                <div class="col-md-3 row_col">
                  <div class="key_value" [ngClass]="{'bg-green-200 text-green-800':reportData['tripStatus']=='COMPLETED','bg-yellow-200 text-yellow-800':reportData['tripStatus']=='INPROGRESS','bg-pink-200 text-pink-800':(reportData['tripStatus']!='INPROGRESS' && reportData['tripStatus']!='COMPLETED')}">
                    <span class="key" style="color:black;width:60%;">{{reportData?.processName}} Status: </span>
                    <span style="font-weight: 600;font-size: 15px;width:40%;margin-left: 5%">
                      {{reportData?.tripStatus}}
                    </span>
                  </div>
                </div>
                <!-- <div class="col-md-3 row_col">
                  <div class="key_value">
                    <span class="key">{{'Start Time'}}: </span>
                    <span class="value">{{reportData?.startTime}}</span>
                  </div>
                </div> -->
                <!-- <div class="col-md-3 row_col">
                  <div class="key_value">
                    <span class="key">{{'End Time'}}: </span>
                    <span class="value">{{reportData?.endTime}}</span>
                  </div>
                </div> -->
              </div>
            </div>

            <div class="card-body">
              <div class="trip_detail">
                <div class="field_audit_report">
                  <div class="tabel_audit_report" *ngFor="let action of reportData.actionsWithPreActions">
                    <div class="table-responsive">
                      <div class="table-name">{{ action.preActionName }} → {{ action.actionName }}</div>
                      <table class="custom-table">
                        <thead>
                          <tr style="text-align: center;border-radius: 5px;border: none !important;height: 40px;">
                            <th *ngFor="let column of action.table_col"
                              style="font-size: 16px; text-align: center;background-color: #3c69b8;color:white !important">
                              <ng-container *ngIf="column === 'title'; else otherContent">
                                {{ column }}
                              </ng-container>
                              <ng-template #otherContent>
                                {{
                                column === action.preActionName
                                ? column + ' (' + (action.preActionCompletionTime ? action.preActionCompletionTime : '')
                                + ')'
                                : column + ' (' + (action.actionCompletionTime ? action.actionCompletionTime : '') + ')'
                                }}
                              </ng-template>
                            </th>

                          </tr>
                        </thead>
                        <tbody>

                          <tr *ngFor="let rowData of action.table_data"
                            [ngClass]="{'row-color-red': rowData['cell_color'] == 'danger'}" style="height: 65px;">
                            <td *ngFor="let column of action.table_col">
                              <div *ngIf="column == 'title'"
                                style="display: flex;width: 95%;justify-content: space-between;align-items: center;margin-left: 20px;">
                                <div class="fieldName"
                                  style="background-color: #d7e0f0;font-size: 18px;color: #10316b;text-align: center;">
                                  {{ rowData[column] }}
                                </div>
                                <img class="compare_icon" src="assets/images/compare.png" title="Compare"
                                  (click)="contentModal(content,rowData,action.preActionName, action.actionName)">
                              </div>

                              <ng-container *ngIf="column != 'title' && rowData[column]['fieldType']">
                                <ng-container *ngIf="objTypeValue.includes(rowData[column]['fieldType'])">
                                  <div
                                    style="display: flex;margin-left: 5px;width: 100%;justify-content: space-between;">
                                    <div
                                      style="display: flex; flex-direction: column;width: max-content;justify-content: center;"
                                      *ngIf="rowData[column]['values'][0]['value']!=''">
                                      <div
                                        style="padding: 5px;width: 100%;border-top-left-radius: 5px;border-top-right-radius: 5px;">
                                        <span
                                          style="font-size: 18px;font-weight: 500;width: 100%;display: inline-flex;padding:2px;border-radius: 5px;"
                                          *ngIf="rowData[column]['values'][0]['value']"> Value :
                                          {{rowData[column]['values'][0]['value']}}</span>
                                      </div>
                                      <div
                                        style="padding: 5px;width: 100%;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;">
                                        <span
                                          style="font-size: 18px;font-weight: 500;width: 100%;display: inline-flex;padding:2px;border-radius: 5px;">
                                          Location :
                                          <img *ngIf="rowData[column]['values'][0]['lat']!=''"
                                            (click)="openMap(rowData[column]['values'][0]['lat'], rowData[column]['values'][0]['lng'])"
                                            style="cursor:pointer;float: right; padding: 0px; background-color: transparent; width: auto;margin-left: 5px;"
                                            height="30" width="30" src="assets/icons/map.png" />
                                        </span>
                                      </div>
                                    </div>
                                    <!-- <img
                                      style="margin-right: 3px;margin-left: 10px;width: 80px;height:80px;border-radius: 5px"
                                      *ngIf="isImgTypeFile(rowData[column]['values'][0]['fileUrl'])"
                                      [src]="rowData[column]['values'][0]['fileUrl'] | urltobase64 | async"
                                      (click)="displayImage(rowData[column]['values'][0]['fileUrl'])"> -->
                                    <img *ngIf="rowData[column]['values'][0]['fileUrl']!=null" style="margin-right: 3px;margin-left: 10px;width: 80px;height:80px;border-radius: 5px"
                                      [src]="rowData[column]['values'][0]['fileUrl']"
                                      (click)="displayImage(rowData[column]['values'][0]['fileUrl'])" crossorigin="anonymous">
                                    <!-- <div style="margin-right: 3px;width: 40%;margin-left: 10px;">
                                    </div> -->
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="rowData[column]['fieldType']=='RADIOBUTTON'"
                                  style="font-size:18px;text-align: center;">
                                  <!-- <span [ngClass]="rowData[column]['values'][0].toLowerCase() == 'yes' ? 'badge badge-danger' : 'badge badge-success '" style="padding:5px"> -->
                                  <span class="badge badge-info" style="padding:10px;font-size: 18px;">
                                    {{ rowData[column]['values'][0]}}
                                  </span>
                                </ng-container>
                                <ng-container *ngIf="rowData[column]['fieldType']=='FILE' || rowData[column]['fieldType']=='IMAGE'">
                                  <div *ngIf="isImgTypeFile(rowData[column]['values'][0]) && rowData[column]['values'][0] != null"  (click)="displayImage(rowData[column]['values'][0])">
                                    <!-- <img style="margin-right: 3px;margin-left: 10px;width: 80px;height:80px;border-radius: 5px"
                                    [src]="rowData[column]['values'][0] | urltobase64 | async"
                                   > -->
                                    <img style="margin-right: 3px;margin-left: 10px;width: 80px;height:80px;border-radius: 5px"
                                    [src]="rowData[column]['values'][0]" crossorigin="anonymous" >
                                    <span style="font-size:18px;text-align: center;">{{ getFileName(rowData[column]['values'][0]) }}</span>
                                  </div>

                                  <div (click)="openFileInNewTab(rowData[column]['values'][0])"  *ngIf="!isImgTypeFile(rowData[column]['values'][0])">
                                    <img style="margin-right: 3px;margin-left: 10px;height:80px;border-radius: 5px"
                                    src="assets/images/file-icon.png">
                                    <span style="font-size:18px;text-align: center;">{{ getFileName(rowData[column]['values'][0]) }}</span>
                                  </div>
                              </ng-container>
                                <ng-container *ngIf="!objTypeValue.includes(rowData[column]['fieldType']) && rowData[column]['fieldType']!='RADIOBUTTON'
                                  && rowData[column]['fieldType']!='FILE' && rowData[column]['fieldType']!='IMAGE'">
                                  <span style="font-size:18px;text-align: center;">{{
                                    rowData[column]['values'][0]}}</span>
                                </ng-container>

                              </ng-container>
                              <ng-container *ngIf="column != 'title' && !rowData[column]['fieldType']">
                                <span style="font-size:18px;text-align: center;">{{ rowData[column] }}</span>
                              </ng-container>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                  <div class="remaining-actions" *ngIf="hasOtherActions()">
                    <div class="table-name">Other Actions</div>
                    <mat-tab-group animationDuration="10ms" (selectedTabChange)="changeTabs($event)">
                      <mat-tab label="{{ stage.key }}"
                        *ngFor="let stage of reportData.actionsWithoutPreActions | keyvalue">
                      </mat-tab>
                    </mat-tab-group>
                    <div class="table-responsive pt-4">
                      <table class="custom-table table-header" *ngIf="filteredActions.length > 0">
                        <thead>
                          <tr>
                            <th style="width:5%">S.No.</th>
                            <th>Action Name</th>
                            <th>Stage</th>
                            <th>Vehicle No</th>
                            <th>Completion Time</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let item of filteredActions; index as i">
                            <tr>
                              <td>{{ i + 1 }}</td>
                              <td>{{ item['actionName'] ? item['actionName'] : '-' }}</td>
                              <td>{{ item['stageName'] ? item['stageName'] : '-' }}</td>
                              <td>{{ reportData.vehicleRCNo }}</td>
                              <td>{{ item['actionCompletionTime'] ? item['actionCompletionTime'] : '-' }}</td>
                              <td style="text-align: center;">
                                <button class="btn btn-info-icon" aria-hidden="true" title="Form"
                                  style="background-color: black;" (click)="actionDetail(item, 'pre', tripId)">
                                  <i class="fa fa-info-circle"></i>
                                </button>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                      <div class="info-text" *ngIf="filteredActions.length == 0">
                        <span>No actions have been performed at this stage yet!!</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Compare {{fieldName}}</h6>
    <div aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-times"></i></div>
  </div>
  <div class="modal-body">
    <div class="row images_row">
      <ng-container *ngFor="let data of modalData;">
        <div class="col-md-4 image_div" *ngIf="isImgTypeFile(data.fileUrl)">
          <h6 style="font-weight: bold; text-align: center;">{{data.actionName}}</h6>
          <img [src]="data.fileUrl">
        </div>
      </ng-container>

    </div>
  </div>
</ng-template>

import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-trip-schedule',
  templateUrl: './trip-schedule.component.html',
  styleUrls: ['./trip-schedule.component.scss']
})
export class TripScheduleComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(50, 9, 'Trip Schedule');
  status:string = "all"
  tabActive:boolean = false;

  actionLabels: any[] = [
    {
      tabName: 'OnTime',
      tab_icon: 'truck',
      tabBgColor: '#b0f6b0',
      tabIconBg: '#2fbf71',
      color:'#ffffff',
      key:'is_early'
    },
    {
      tabName: 'Delayed',
      tab_icon: 'clock-o',
      tabBgColor: '#ff8fa3',
      tabIconBg: '#e71d36',
      color:'#ffffff',
      key:'is_delay'
    },
    {
      tabName: 'Cancelled',
      tab_icon: 'times-circle',
      tabBgColor: '#bfd5d2',
      tabIconBg: '#7faba4',
      color:'#ffffff',
      key:'cancelled'
    },
    {
      tabName: 'Maintenance',
      tab_icon: 'gear',
      tabBgColor: '#ebeb98',
      tabIconBg: '#e4b61a',
      color:'#ffffff',
      key:'maintainence'
    }
  ];
  tripScheduleData: any[] = [];

  constructor(
    public table: TableService,
    public api: ApiService,
    public common: CommonService,
    private breadcrumbService: BreadcrumbService,
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Trips', url: null },
      { name: 'Trip Schedule', link: '/user/trip-schedule' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit() {
    this.getTripSchedule();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  getTripSchedule() {
    this.common.loading = true;
    this.api.get('trip/get-trip-schedule').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.tripScheduleData = res.data[0];
        this.renderTable();
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    );
  }

  filterDataByTab(status:string){
    this.tabActive = this.status == status ? !this.tabActive : true;
    this.status = this.tabActive ? status : 'all';
  }
}

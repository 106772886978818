import { TmgService } from '../../components/tmg-dashboard/tmg.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
})
export class DatetimePickerComponent implements OnInit {
  fromDate: any;
  ToDate: any;

  btnactiveIndex: number = -1;
  optionactiveindex: number = -1;
  dateactive: boolean = false;

  @Output() onSelected = new EventEmitter();
  @Output() onReset = new EventEmitter();
  @Output() onDetailView = new EventEmitter();

  @Input() data: any;
  @Input() optionmenu: any;
  @Input() title: any;
  @Input() DataSet: any;
  @Input() ParamType: any;

  ResetData: boolean = false;
  options: boolean = false;
  menuoptions: boolean = false;
  btnOptions: boolean = false;
  datePicker: boolean = false;
  monthpicker: boolean = false;
  selectHeader: boolean = false;

  resetView: boolean = false;
  detailbtnView: boolean = false;

  dateRangepicker: boolean = false;
  dateCompareRangepicker: boolean = false;

  month1: any;
  month2: any;

  fromDate1: string = '';
  toDate1: string = '';

  toDate2: string = '';
  fromDate2: string = '';

  constructor(public tmg: TmgService) {}

  resetdataValues() {
    if (this.ResetData) {
      this.optionactiveindex = -1;
      this.btnactiveIndex = -1;
      this.dateactive = false;
      this.fromDate = '';
      this.ToDate = '';
      this.fromDate1 = '';
      this.toDate1 = '';
      this.fromDate2 = '';
      this.toDate2 = '';
    }
  }

  resetData() {
    this.ResetData = true;
    this.resetdataValues();
    this.onReset.emit();
  }

  detailView() {
    this.onDetailView.emit();
  }

  startdate(event: MatDatepickerInputEvent<Date>) {
    this.fromDate = event.value;
  }

  endDate(event: MatDatepickerInputEvent<Date>) {
    this.ResetData = false;
    this.ToDate = event.value;
    this.tmg.startdate = this.fromDate;
    this.tmg.EndDate = this.ToDate;

    if (this.tmg.EndDate != null) {
      this.DateExtract('DateRange');
      this.dateactive = true;
      this.btnactiveIndex = -1;
      this.optionactiveindex = -1;
    }
  }

  dateparse(type: any, number: number, index: number) {
    this.ResetData = false;
    this.btnactiveIndex = index;
    this.optionactiveindex = -1;
    this.dateactive = false;
    this.fromDate = '';
    this.ToDate = '';
    this.DateExtract(type, number);
  }

  menudateParse(type: any, number: number, index: number) {
    this.ResetData = false;
    this.fromDate = '';
    this.ToDate = '';
    this.dateactive = false;
    this.btnactiveIndex = -1;
    this.optionactiveindex = index;
    this.DateExtract(type, number);
  }

  DateExtract(type: any, number?: number) {
    let responseObject = this.tmg.DateResponse(type, number);
    this.onSelected.emit(responseObject);
  }

  fromDate1Change(event: any) {
    this.fromDate1 = event.value;
  }

  toDate1Change(event: any) {
    this.toDate1 = event.value;
  }

  fromDate2Change(event: any) {
    this.fromDate2 = event.value;
  }

  toDate2Change(event: any) {
    this.toDate2 = event.value;
  }

  renderdatepicker() {
    this.menuoptions = this.optionmenu == null ? true : false;
    this.btnOptions = this.data == null ? false : true;
  }

  ngOnInit(): void {}
}

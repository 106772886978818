<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{'assign'|translate}} {{'process'|translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="my-input" class="required">{{'select_process'|translate}}</label>
    <ps-auto-suggestion (onSelected)="selectProcess($event)" [data]="processList" display="processName"
      placeholder="Choose Process">
    </ps-auto-suggestion>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success" type="button" outline (click)="refData.editProcessFlag ? changeProcess() :   saveProcess()"
      style="cursor: pointer;">{{'save'|translate}}</button>
  </div>

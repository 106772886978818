<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{'alert_tasks'|translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="this.common.params.type=='PERFORM'">
    <div class="form-group">
      <div class="accorion_class">
        <label for="my-input">Upload Image</label>
        <div class="icon_right">
          <i class="fa fa-upload" (click)="imageUploadAcco=!imageUploadAcco" *ngIf="imageUploaded==false"></i>
        </div>
      </div>

      <div class="accordion_data" *ngIf="imageUploadAcco==true">
        <div class="webcam_class">
          <app-camera-component [retake]="retakeImage" (getPicture)="uploadFile($event);">
          </app-camera-component>
          <div style="display: flex; flex-direction: column; align-items:center;" *ngIf="imageUploaded==true">
            <p>Upload Done! <button class="btn btn-warning"
                (click)="retakeImage = !retakeImage;this.formValues[0].fileUrl=null;imageUploaded=false">Retake</button>
            </p>
          </div>
        </div>
      </div>

      <div class="accorion_class" style="margin-top: 8px;">
        <label for="my-input">Remark</label>
      </div>

      <div class="accordion_data">
        <div class="input_flex">
          <textarea class="form-control" rows="3" cols="50" (change)="enterValues($event)"
            class="field_class"></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-success" type="button" outline (click)="updateStatus('COMPLETED')"
        style="cursor: pointer;">{{'COMPLETED'|translate}}</button>
      <button class="btn btn-danger" type="button" outline (click)="updateStatus('CANCELLED')"
        style="cursor: pointer;background-color: red!important;">{{'CANCELLED'|translate}}</button>
    </div>
  </ng-container>

  <div>

import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ProcessService } from '../process/process.service';
import { WorkTypeService } from '../work-type/work-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { ApiService } from 'src/app/@core/services/api.service';

@Component({
  selector: 'process-management',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss'],
})
export class ProcessComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'PROCESS LIST');
  ngAfterViewInit() {
    this.dtTrigger.next();
    if(this.role.user_role != 'COMPANYADMIN')this.getAllProcess();
    else this.getPlantList();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  process = {
    processId: '',
    processName: '',
    processStatus: 'ACTIVE',
    processDescription: '',
    workType: '',
  };
  buttonName = 'save';
  title = 'Add Process';
  submitted = false;
  message = '';
  responseData: any[] = [];
  workTypeList: any[] = [];
  workTypeName = null;
  plantId:any;
  plantName:any;
  plantNameValidator:any;
  plantList:any[];
  constructor(
    private alertService: AlertService,
    private modalService: NgbModal,
    public common: CommonService,
    private processService: ProcessService,
    private workTypeService: WorkTypeService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public role:RoleService,
    public api:ApiService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Process', url: null },
      { name: 'Process Management', url: '/user/process-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getAllWorkType();
  }
  // Save Work Type
  saveProcess(): void {
    let data = {
      processId: this.process.processId,
      processName: this.process.processName,
      processDescription: this.process.processDescription,
      processStatus: this.process.processStatus,
      workType: this.process.workType,
      plantId:this.plantId
    };

    this.processService.saveProcess(data).subscribe(
      (response) => {
        if (response.status) {
          this.alertService.success(response.message);
        }
        this.submitted = true;
        this.process = {
          processId: '',
          processName: '',
          processStatus: '',
          processDescription: '',
          workType: '',
        };
        if(this.role.user_role != 'COMPANYADMIN')this.getAllProcess();
        else this.resetData();
        this.resetProcess();
      },
      (error) => {}
    );
  }
  selectedWorkType(event) {
    this.process.workType = event.workTypeId;
    this.workTypeName = event.workTypeName;
  }
  getAllWorkType() {
    this.common.loading = true;
    this.workTypeService.getAllWorkType().subscribe(
      (res: any) => {
        this.common.loading = false;
        res.data.forEach((element) => {
          if (element.workTypeStatus == 'ACTIVE') {
            this.workTypeList.push(element);
          }
        });
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllProcess(plantId?) {
    this.common.loading = true;
    this.processService.getAllActiveAndInactiveProcessList(plantId?plantId:null).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.responseData = res.data;
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  viewProcess(row?: any) {
    const tempObj = { ...row };
    delete tempObj['plant_id'];
    delete tempObj['plant_name'];
    this.common.params = { details: [tempObj], title: 'Process Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Process ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row.processName +
        ' ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteProcess(row);
      }
    });
  }

  deleteProcess(row?: any) {
    this.common.loading = true;
    let data = {
      processId: row.processId,
      processName: row.processName,
      processStatus: 'DELETED',
      processDescription: row.processDescription,
      workType: row.workTypeId,
      plantId:this.plantId
    };

    this.processService.saveProcess(data).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alertService.success(res.message);
        if(this.role.user_role != 'COMPANYADMIN')this.getAllProcess();
        else this.resetData();
        this.resetProcess();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  editProcess(row?: any) {
    this.common.gotoTop();
    this.resetProcess();
    this.process.processId = row.processId;
    this.process.processName = row.processName;
    this.process.processStatus = row.processStatus;
    this.process.processDescription = row.processDescription;
    this.process.workType = row.workTypeId;
    this.workTypeName = row.workTypeName;
    this.plantId=row.plant_id;
    this.plantName=row.plant_name;
    this.buttonName = 'update';
    this.title = 'Edit Process';
  }

  resetProcess() {
    this.process = {
      processId: '',
      processName: '',
      processStatus: 'ACTIVE',
      processDescription: '',
      workType: '',
    };
    this.workTypeName = null;
    this.processNameValidator = null;
    this.statusValidator = null;
    this.workTypeNameValidator = null;
    this.buttonName = 'save';
    this.title = 'Add Process';
    this.plantId=null;
    this.plantName=null;
    this.plantNameValidator=null;
  }

  processNameValidator: any;
  statusValidator: any;
  workTypeNameValidator: any;

  formValidator() {
    if(this.role.user_role == 'COMPANYADMIN'){
      this.plantNameValidator=this.common.nullValidator(
        this.plantId,
        'Plant Mandatory'
      );
    }
    this.processNameValidator = this.common.nullValidator(
      this.process.processName,
      'Process Mandatory'
    );
    this.workTypeNameValidator = this.common.nullValidator(
      this.process.workType,
      'WorkType Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.process.processStatus,
      'Category Status Mandatory'
    );
    if (
      (!this.processNameValidator.error &&
      !this.statusValidator.error &&
      !this.workTypeNameValidator.error) &&((this.role.user_role=='COMPANYADMIN' && !this.plantNameValidator.error) || this.role.user_role!='COMPANYADMIN')
    ) {
      this.saveProcess();
    }
  }

  resetData(){
    this.responseData=[];
    this.renderTable();
  }


  selectPlant(event) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getAllProcess(this.plantId);
  }

  getPlantList() {
    this.common.loading = true;
    this.api.get('plant/all_plants?id=' + this.userService._loggedInUser.companyId).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantList = res.data;
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import * as Highcharts from 'highcharts';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertCardModalComponent } from '../alert-card-modal/alert-card-modal.component';
import { DashboardModalComponent } from '../dashboard-modal/dashboard-modal.component';
import { AlertData, AlertType, ComplianceData, DocumentData, GraphData, OrderData, StatusWiseCount } from 'src/app/dataType/entityDetailsInterface';
import { COMMON_TRIPS_ACTION_BUTTON, TABLE_TYPE, TableActions, TableColumns } from 'src/app/dataType/customTableInterface';
import { TableService } from 'src/app/@core/services/table.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewEncapsulation } from '@angular/core';
import { TransporterList } from 'src/app/dataType/transporterInterface';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';


@Component({
  selector: 'app-transporter-details',
  templateUrl: './transporter-details.component.html',
  styleUrls: ['./transporter-details.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class TransporterDetailsComponent implements OnInit {
  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  loading: boolean = false;
  orderDataColumns: TableColumns[] = [];
  chart: any;
  ellipseIndex: string = '';
  graphTitle: string = 'Date Wise TAT Trend';
  dateWiseGraphData: GraphData[];
  tabvalue: string = 'tat';
  entityName: string;
  activeAlert: string = 'Critical';
  alertTypeWiseCount: AlertType[];
  alertData: AlertData[] = [];
  complianceData: ComplianceData[] = [];
  documentData: DocumentData[] = [];
  orderData: OrderData[] = [];
  tripCount: StatusWiseCount[];
  lastTxnDriver: string;
  lastTxnVehicle: string;
  lastTxnLocation: string;
  isBanned: boolean;
  entityId: number;
  transporterList:TransporterList[] = [];
  transporterImage: string = null;
  entityPhoneNo: string;
  filteredAlertData: AlertData[] = [];
  dashboardStageCard = [
    {
      name: 'Total', color: '#33bbb9',
      bgColor: '#eeffff'
    },
    {
      name: 'Completed', color: '#3ab962',
      bgColor: '#f0fff5'
    },
    {
      name: 'Cancelled', color: '#c33979',
      bgColor: '#fff1f8'
    },
    {
      name: 'Maintainence', color: '#c3c339',
      bgColor: '#ffffef'
    },
    {
      name: 'Auto Cancelled', color: '#cd4040',
      bgColor: '#fff2f2'
    },
  ]

  constructor(public api: ApiService, public common: CommonService, public modalService: NgbModal, public userService: UserService, public alertService: AlertService, public tableService: TableService, public translateService: TranslateService, public breadcrumbService:BreadcrumbService) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Transporter Details', link: '/user/transporter-details' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
    this.getAllTransporter();
  }

  getAllTransporter() {
    this.common.loading = true;
    this.api.get('transporter/get-all-transporters').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.transporterList = res.data;
          this.selectTransporter(this.transporterList[0]);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectTransporter(event) {
    this.entityId = event.id;
    let data = event.transporterName.split('-');
    this.entityName = data[0];
    this.entityPhoneNo = data[1];
    this.transporterImage = event.transporterImage;
    this.getOrderData();
    this.getAlertData();
    this.getComplianceData();
    this.getDocumentData();
    this.getStatusWiseTripCount();
    this.getDateWiseTripTat();
  }

  getOrderData() {
    this.loading = true;
    let params = {
      entityId: this.entityId,
      entityType: 'Transporter'
    };
    this.common.loading = true;
    this.api.get('vehicle/get-order-details', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.orderData = res.data[0].order_data;
          this.lastTxnDriver = res.data[0].last_driver;
          this.lastTxnLocation = res.data[0].last_plant;
          this.lastTxnVehicle = res.data[0].last_vehicle;
          this.isBanned = res.data[0].is_banned;

          this.orderDataColumns = [
            {
              columnLabel: "Order Id",
              columnName: "order_name",
            }, {
              columnLabel: "Vehicle",
              columnName: "vehicle_rc_no",
            }, {
              columnLabel: "Driver",
              columnName: "driver_name",
            }, {
              columnLabel: "Tare Weight",
              columnName: "tare_weight",
            }, {
              columnLabel: "Gross Weight",
              columnName: "gross_weight",
            }, {
              columnLabel: "Order Weight",
              columnName: "order_weight",
            },
            {
              columnLabel: this.translateService.instant("Action"),
              columnName: null,
              dataActions: [
                ...this.tableService.getTripsButtons([
                  COMMON_TRIPS_ACTION_BUTTON.TRIP_DETAIL, COMMON_TRIPS_ACTION_BUTTON.TRIP_TRACKING, COMMON_TRIPS_ACTION_BUTTON.TRIP_REMARK
                ])
              ] as TableActions[]
            }
          ]
          this.loading = false;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getStatusWiseTripCount() {
    let params = {
      entityId: this.entityId,
      entityType: 'Transporter'
    };
    this.common.loading = true;
    this.api.get('transaction/get-status-wise-trip-count', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.tripCount = res.data[0];
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAlertData() {
    let params = {
      entityId: this.entityId,
      entityType: 'Transporter'
    };
    this.common.loading = true;
    this.api.get('alert/get-alerts-by-entityType', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertData = res.data[0].alert_data;
          this.filteredAlertData = [];
          if (res.data[0].type_count != null) {
            this.selectAlert(Object.keys(res.data[0].type_count)[0]);
          }
          this.alertTypeWiseCount = res.data[0].type_count;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectAlert(alertType) {
    this.activeAlert = alertType;
    this.filteredAlertData = this.alertData.filter(alert => alert.type_name == this.activeAlert);
  }

  getComplianceData() {
    let params = {
      entityId: this.entityId,
      entityType: 'Transporter'
    };
    this.common.loading = true;
    this.api.get('compliance/get-compliance-by-entityType', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.complianceData = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getDocumentData() {
    let params = {
      entityId: this.entityId,
      entityType: 'Transporter'
    };
    this.common.loading = true;
    this.api.get('document/get-documents-by-entityType', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.documentData = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }


  viewAlert(alertData) {
    const activeModal = this.modalService.open(AlertCardModalComponent, {
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      alert_type_name: alertData.title,
      alert_remark: alertData.remark,
      alert_data: alertData.data,
      showAlertData: true
    };
  }


  viewCompliance(row?: any) {
    let data = JSON.parse(row.compliance_data);
    this.common.params = {
      details: [data],
      title: 'Compliance Details',
      imgUrl: data.imageFile
    };
    this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  viewDoc(doc) {
    this.common.params = {
      details: [doc],
      title: doc.type_name + ' Details',
      imgUrl: (JSON.parse(doc.documents)[0])
    };

    this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmAlert() {
    this.common.params = {
      title: 'Delete Transporter Restriction',
      description: `<b>&nbsp;` + 'Are you sure you want to  ' + (this.isBanned ? 'Unban this Transporter' : 'Ban this Transporter') + ' ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.saveTransporterRestriction(!this.isBanned, this.entityId);
      }
    });
  }


  saveTransporterRestriction(ban: boolean = true, transporterId: number = -1) {
    this.common.loading = true;
    let params = {
      updateStatus: ban ? 'ban' : 'unban',
      transporterId: transporterId,
      plantId: this.userService._loggedInUser.plantId,
      companyId: this.userService._loggedInUser.companyId
    }
    this.api.post('transporter/ban-transporter', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertService.success(ban ? 'Transporter Successfully Banned !!' : 'Transporter Successfully Unbanned !!');
          this.getOrderData();
        }
        this.common.loading = false;
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }


  getDateWiseTripTat() {
    this.common.loading = true;
    let params = {
      entityId: this.entityId,
      entityType: 'Transporter'
    };
    this.api.get('transaction/get-date-wise-trip-TAT', params).subscribe((res: any) => {
      if (res.status) {
        // Initialize data arrays
        this.dateWiseGraphData = res.data;
        this.tabvalue = 'tat';
        this.setGraphDataTAT();
      }
      this.common.loading = false;
    },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  setGraphDataTAT() {
    const data = this.dateWiseGraphData.map((val: any) => {
      // Convert TAT from "hh:mm" to total minutes for the y-axis
      return {
        x: new Date(val.date_val).getTime(), // Convert date to timestamp for x-axis
        y: this.convertTATToMinutes(val.tat), // Convert TAT to minutes
      };
    });

    this.updateChartOptions('Avg TAT', data, 'Avg TAT (hh:mm)', true);
  }

  setGraphDataCount() {
    const data = this.dateWiseGraphData.map((val: any) => {
      return {
        x: new Date(val.date_val).getTime(), // Convert date to timestamp for x-axis
        y: val.trip_count, // Use trip count directly for y-axis
      };
    });

    this.updateChartOptions('Trip Count', data, 'Trip Count', false);
  }

  updateChartOptions(seriesName: string, data: any[], yAxisTitle: string, isTAT: boolean) {
    const options: any = {
      exporting: { enabled: false },
      credits: { style: { display: 'none' } },
      chart: {
        type: 'line',
        height: 250,
      },
      title: { text: '', enabled: false },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date',
        },
        dateTimeLabelFormats: {
          day: '%e %b',
        },
      },
      yAxis: {
        title: { text: yAxisTitle },
        min: 0,
        labels: {
          formatter: function () {
            return isTAT
              ? `${Math.floor(this.value / 60)}:${this.value % 60 < 10 ? '0' : ''}${this.value % 60}`
              : this.value;
          },
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',  // Set the legend to appear at the top
        y: -5,                 // Adjust the vertical positioning
        floating: true,        // Allow the legend to float within the chart
        borderWidth: 0,         // Optional: Remove border from the legend
        itemStyle: {           // Optional: Customize the legend text style
          fontWeight: 'bold',
        },
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let tooltipText = `<b>Date: ${Highcharts.dateFormat('%e %b %Y', this.x)}</b><br/>`;
          this.points.forEach(point => {
            if (isTAT) {
              const hours = Math.floor(point.y / 60);
              const minutes = point.y % 60;
              tooltipText += `${point.series.name}: ${hours}:${minutes < 10 ? '0' : ''}${minutes} (hh:mm)<br/>`;
            } else {
              tooltipText += `${point.series.name}: ${point.y} trips<br/>`;
            }
          });
          return tooltipText;
        },
      },
      series: [{ name: seriesName, data }],
    };

    this.chart = Highcharts.chart('myChart', options);
  }


  changeTab() {
    if (this.tabvalue === 'tat') {
      this.graphTitle = 'Date Wise TAT Trend';
      this.setGraphDataTAT();
    } else {
      this.graphTitle = 'Date Wise Trip Count';
      this.setGraphDataCount();
    }
  }

  convertTATToMinutes(tat: string): number {
    const [hours, minutes] = tat.split(':').map(Number);
    return hours * 60 + minutes;
  }

  getStatusWiseTripData(status) {
    this.common.loading = true;
    let params = {
      entityId: this.entityId,
      entityType: 'Transporter',
      status: status.toUpperCase()
    };
    this.api.get('transaction/get-status-wise-trip-data', params).subscribe((res: any) => {
      if (res.status) {
        let headers = ['Vehicle No', 'Process Name', 'Stage Name', 'Status', 'Start Time', 'End Time', 'TAT', 'Info'];
        let keys = ['vehicle_rc_no', 'process_name', 'stage_name', 'stage_workflow_status', 'start_time', 'end_time', 'tat'];
        this.viewDashboardMoadal(headers, keys, res.data, status);
      }
      this.common.loading = false;
    });
  }

  viewDashboardMoadal(headers: any, keys: any, tableModalData: any, modalTitle: string) {
    const activeModal = this.modalService.open(DashboardModalComponent, {
      size: 'xl',
      windowClass: 'custom-modal-width'
    });
    activeModal.componentInstance.refData = {
      header: headers,
      keys: keys,
      tableModalData: tableModalData,
      modalTitle: modalTitle + ' Trips',
      modalType: 'User'
    }
  }

}
import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LocationService } from 'src/app/@core/services/location.service';
import { WebcamImage } from 'ngx-webcam';
import { Base64ToFileService } from 'src/app/@core/services/base64-to-file-service.service';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-entry-form',
  templateUrl: './entry-form.component.html',
  styleUrls: ['./entry-form.component.scss'],
})
export class EntryFormComponent implements OnInit {
  // @ViewChild(SignatureComponent, { static: false }) signature: SignatureComponent;
  processList: any = [];
  tempProcessList: any[] = [];
  processId: any = null;
  retakeImage: boolean = false;
  image_name: any = '';
  image_url: any = '';
  image_ext: any = '';
  mappedFields: any[] = [];
  getSignature: any = null;
  selectedOption: string;
  fileURL: any = [];
  fileEvent: any = [];
  formValues: any = [
    {
      name: '',
      values: [],
      fieldType: '',
      _dbField: '',
    },
  ];
  formId: any = null;
  selectedProcessName: string = null;

  constructor(
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public alert: AlertService,
    private locationService: LocationService,
    private activeModal: NgbActiveModal,
    private base64ToFileService: Base64ToFileService,
    private imageCompress: NgxImageCompressService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.getAllProcess();
  }

  getAllProcess() {
    this.common.loading = true;
    let apiSubUrl = 'process/get-all-process?processActive=true';
    this.api.get(apiSubUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.processList = res.data;
        this.tempProcessList = res.data;
        this.setDefaultForm();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  private setDefaultForm() {
    this.processId = this.processList[0].processId;
    this.selectedProcessName = this.processList[0].processName;
    this.getProcessWiseMappedFields(this.processId);
  }

  filterProcess(event: any) {
    this.processList = this.tempProcessList.filter((p) =>
      p.processName.toLowerCase().includes(event.trim().toLowerCase())
    );
  }

  selectProcess(item: any) {
    this.formValues = [
      {
        name: '',
        values: [],
        fieldType: '',
        _dbField: '',
      },
    ];
    this.processId = item.processId;
    this.selectedProcessName = item.processName;
    this.getProcessWiseMappedFields(this.processId);
  }

  getProcessWiseMappedFields(processId: any) {
    this.common.loading = true;
    let apiSubUrl =
      'customForm/process-wise-mapped-fields?processId=' + processId;
    this.api.get(apiSubUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.mappedFields = res.data[0].fields ?  res.data[0].fields : [];
        this.formId = res.data[0].form_id;
        this.addJsonToFormValues();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  insertLabel(field: any, index: any) {
    if (
      this.formValues.length >= index &&
      this.formValues[index].values.length == 0
    ) {
      this.formValues[index].name = field.field_name;
      this.formValues[index].values.push(field.options[0].name);
      this.formValues[index].fieldType = field.field_type;
    }
  }

  addJsonToFormValues() {
    let size = this.mappedFields.length - 1;
    for (let i = 0; i < size; i++) {
      this.formValues.push({
        name: '',
        values: [],
        fieldType: '',
        _dbField: '',
      });
    }
    this.saveDataBaseLinked();
  }

  handleImage(webcamImage: WebcamImage, field: any, i: number) {
    this.formValues[i].values = [];
    this.formValues[i].name = field.field_name;
    this.onSelect(webcamImage, null, i, true, false, field.field_type);
    this.formValues[i].fieldType = field.field_type;
    this.formValues[i]._dbField = field.database_field_name;
  }

  handleSingleSelectDBField(event: any, field: any, i: number) {
    this.formValues[i].values = [];
    this.formValues[i].values.push(event);
    this.formValues[i].name = field.field_name;
    this.formValues[i].fieldType = field.field_type;
    this.formValues[i]._dbField = field.database_field_name;
  }

  enterValues(event: any, field: any, i: any) {
    if (
      field.field_type != 'CHECKBOX' &&
      field.field_type != 'SELECTLISTMULTIPLE' &&
      field.field_type != 'FILE' &&
      field.field_type != 'SELECTLISTSINGLE'
    ) {
      this.formValues[i].values = [];
      this.formValues[i].name = field.field_name;
      this.formValues[i].values.push(event.target.value);
      this.formValues[i].fieldType = field.field_type;
      this.formValues[i]._dbField = field.database_field_name;
    }
    if (field.field_type == 'SELECTLISTSINGLE') {
      this.formValues[i].values = [];
      this.formValues[i].values.push(event[field.store_value]);
      this.formValues[i].name = field.field_name;
      this.formValues[i].fieldType = field.field_type;
      this.formValues[i]._dbField = field.database_field_name;
    }

    if (field.field_type == 'CHECKBOX') {
      if (event.target.checked) {
        this.formValues[i].name = field.field_name;
        this.formValues[i].values.push(event.target.value);
        this.formValues[i].fieldType = field.field_type;
        this.formValues[i]._dbField = field.database_field_name;
      } else {
        let index = this.formValues[i].values.indexOf(event.target.value);
        this.formValues[i].values.splice(index, 1);
      }
    }
    if (field.field_type == 'SELECTLISTMULTIPLE') {
      this.formValues[i].values = [];
      event.forEach((opt) => {
        this.formValues[i].values.push(opt[field.store_value]);
      });
      this.formValues[i].name = field.field_name;
      this.formValues[i].fieldType = field.field_type;
      this.formValues[i].values = [...new Set(this.formValues[i].values)];
      this.formValues[i]._dbField = field.database_field_name;
    }

    if (field.field_type == 'FILE') {
      let methodId = field.method_id;
      this.onSelect(event, methodId, i, false, false, field.field_type);
      this.formValues[i].values = [];
      this.formValues[i].name = field.field_name;
      this.formValues[i].fieldType = field.field_type;
      this.formValues[i]._dbField = field.database_field_name;
    }
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }

  onSelect(
    e,
    methodId,
    i,
    webcamImageType: boolean,
    signature: boolean,
    fieldType: string
  ) {
    let frmData = new FormData();
    let imgResultAfterCompression: DataUrl;
    if (webcamImageType == false) {
      for (const file of e.target.files) {
        frmData.append('files', file);
        this.fileEvent.inProgress = true;
        this.uploadFile(frmData, fieldType, i);
      }
    } else {
      let file;
      if (signature == false) {
        this.imageCompress
          .compressFile(e._imageAsDataUrl, -2, 50, 50) // 50% ratio, 50% quality
          .then((compressedImage) => {
            imgResultAfterCompression = compressedImage;
            file = this.base64ToFileService.base64toFile(
              imgResultAfterCompression,
              'image' + i + '.jpg'
            );
            frmData.append('files', file);
            this.fileEvent.inProgress = true;
            this.uploadFile(frmData, fieldType, i);
          });
      } else {
        this.imageCompress
          .compressFile(e, -2, 50, 50) // 50% ratio, 50% quality
          .then((compressedImage) => {
            imgResultAfterCompression = compressedImage;
            file = this.base64ToFileService.base64toFile(
              imgResultAfterCompression,
              'signature.jpg'
            );
            frmData.append('files', file);
            this.fileEvent.inProgress = true;
            this.uploadFile(frmData, fieldType, i);
          });
      }
    }

    // }
  }

  uploadFile(frmData: any, fieldType: string, i: number) {
    const headers = new HttpHeaders();
    this.api
      .post('functional/upload-file?methodId=' + 17, frmData)
      .subscribe((res: any) => {
        switch (res.data.type) {
          case HttpEventType.UploadProgress:
            this.fileEvent.progress = Math.round(
              (res.data.loaded * 100) / res.data.total
            );
            break;
          case HttpEventType.Response:
            return res.data;
        }
        res.data.forEach((e) => {
          if (fieldType != 'GEOCODEDIMAGE') {
            this.formValues[i].values.push(e.fileUrl);
          } else {
            const position = this.locationService._position;
            let obj = {
              lat: position.latitude,
              lng: position.longitude,
              time: new Date(),
              fileUrl: e.fileUrl,
            };
            this.formValues[i].values.push(obj);
          }
        });
      });
    catchError((error: HttpErrorResponse) => {
      this.fileEvent.inProgress = false;
      return `${this.fileEvent.data.name} upload failed.`;
    });
  }

  saveDataBaseLinked() {
    this.mappedFields.forEach((element) => {
      if (element.sel_value != null) {
        let i = this.mappedFields.indexOf(element);
        this.formValues[i].name = element.field_name;
        this.formValues[i].values.push(element.sel_value);
        this.formValues[i].fieldType = element.field_type;
        this.formValues[i]._dbField = element.database_field_name;
      }
      element['multiValueOptions'] = [];
      if (element.field_type == 'SELECTLISTMULTIPLE') {
        element.options.forEach((ele) => {
          let obj = {
            option: ele.master_field_value,
          };
          element.multiValueOptions.push(obj);
        });
      }
    });
  }

  saveSignature(index: number, field: any) {
    if (this.getSignature != null) {
      this.formValues[index].values = [];
      this.onSelect(this.getSignature, null, index, true, true, 'SIGNATURE');
      this.formValues[index].name = field.field_name;
      this.formValues[index].fieldType = field.field_type;
      this.formValues[index]._dbField = field.database_field_name;
    }
  }

  saveFormResponse() {
    let i = this.formValues.findIndex(
      (e) => e.name == '' && e.field_type != 'LABEL'
    );
    let vehicleNumberCount = this.formValues.filter(
      (e) => e._dbField == 'vechile_number'
    ).length;
    if (i != -1) {
      this.alert.error('All fields need to be filled...');
    } else if (vehicleNumberCount == 0) {
      this.alert.error(
        'There must be any vehicle_number database linked field mapped'
      );
    } else {
      let params = {
        formValues: JSON.stringify(this.formValues),
        formId: this.formId,
        refId: this.processId,
      };
      this.common.loading = true;
      this.api.post('customForm/save-form-responses', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.formValues = [
              {
                name: '',
                values: [],
                fieldType: '',
                _dbField: '',
              },
            ];
            this.setDefaultForm();
            this.alert.success('Saved Successfully !!');
            this.selectedOption = null;
            this.retakeImage = false;
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    }
  }
}

import { Component, OnInit } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-field-audit-report',
  templateUrl: './field-audit-report.component.html',
  styleUrls: ['./field-audit-report.component.scss'],
})
export class FieldAuditReportComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}

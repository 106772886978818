import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/@core/services/common.service';
import { MapService } from '../../../@core/services/map.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { Component, Input, OnInit, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { DriverTrackerComponent } from '../../driver-tracker/driver-tracker.component';
import { VehicleDetailComponent } from '../../vehicle-detail/vehicle-detail.component';
declare var google: any;
@Component({
  selector: 'app-trip-map-view',
  templateUrl: './trip-map-view.component.html',
  styleUrls: ['./trip-map-view.component.scss'],
})
export class TripMapViewComponent implements OnInit {
  allTrips: any = [];
  path: any[];
  allGeoFence: any;
  plantIndex: number;
  mapDetailId: number = -1;
  mapDetailLabel: string = '';
  modalDetailTitle: string = '';
  @ViewChild('detailModal') detailModal: ElementRef;
  modalTrigger: any = new Subject();
  backdrop: any;
  detailData: any[] = [];
  detailType: any;
  detailAllData: any[] = [];
  centreAtFlag = false;
  detailImages = {
    1: 'assets/images/truck-plant.png',
    2: 'assets/images/truck-stage.png',
    3: 'assets/images/truck-destination.png',
    4: 'assets/images/truck-destination.png',
    5: 'assets/images/truck-destination.png',
    6: 'assets/images/truck-stage.png',
  };

  vehicleNumber: string;
  // allProcesses: any;
  activeIndex: number = 0;
  currentProcess: any;
  @Input() processList: any[] = [];
  @Input() plantId : any=null;
  allProcesses: any[] = [];

  constructor(
    private api: ApiService,
    private mapService: MapService,
    public modalService: NgbModal,
    private common: CommonService
  ) {
  }


  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes['plantId']){
      this.getGeoFence();
    }
  }

  ngOnInit(): void {
    this.getGeoFence();
  }

  getProcessVehicles(processId, index) {
    this.activeIndex = index;
    this.getGeoFence(processId);
  }

  clearMapServices() {
    this.mapService.clearAll();
    this.mapService.resetMarker();
    this.mapService.resetPolygons();
    this.mapService.clearAll();
    this.mapService.resetMarker();
    this.path = [];
  }


  getGeoFence(processId = null) {

    this.common.loading = true;
    let apiUrl = 'geofence/get-plant-geofence-by-processId';
    if (processId) {
      apiUrl = `geofence/get-plant-geofence-by-processId?processId=${processId}`;
      if(this.plantId){
        apiUrl = apiUrl+"&plantId="+this.plantId;
      }
    }
    else if(this.plantId){
      apiUrl = apiUrl+"?plantId="+this.plantId;
    }
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.allGeoFence = res.data || [];
        if (this.allGeoFence.length) {
          this.allGeoFence.forEach((element) => {
            element.plantLatLong = this.getSiteGeoFenceLatLong(element);
          });
          setTimeout(() => {
            this.viewGeoFence(this.allGeoFence[0]);
          }, 100);
        } else {
          this.mapService.clearAll();
        }
      },
      (err) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  SearchVehicle() {
    if (this.vehicleNumber.trim()) {
      this.detailData = this.detailAllData.filter((item) =>
        item.vehicle_rc_no
          .toLowerCase()
          .includes(this.vehicleNumber.trim().toLowerCase())
      );
    } else {
      this.detailData = this.detailAllData;
    }
  }

  viewGeoFence(row: any, event = false) {
    this.centreAtFlag=false;
    if(row.lat && row.long){
      this.centreAtFlag = true;
      let latLong = { lat: row.lat, lng: row.long };
    this.mapService.centerAt(latLong);
    this.mapService.zoomAt(latLong, 12);
   };
    this.clearMapServices();
    // this.mapService.centerAt(latLong);
    // this.mapService.zoomAt(latLong, 12);
    this.mapService.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    this.LoadGeoFence(row.plantLatLong, event);
    let exists = row.plantLatLong.find((item) => item.type == 1);
    if(exists){
    let plantGeoFence = row.plantLatLong.find((item) => item.type == 1).data;

    plantGeoFence.forEach((element) => {
      this.mapService.setBounds(
        this.mapService.createLatLng(element.lat, element.lng)
      );
    });}
    let thisClass = this;
    this.mapService.markers.forEach((marker: any) => {
      this.mapService.addListerner(marker, 'click', function (event: any) {
        this.mapDetailId = marker.id;
        this.mapDetailLabel = marker.label.text;
        thisClass.modalDetailTitle = marker.label.text;
        thisClass.detailData = row.plantLatLong.find(
          (item) =>
            item.id == this.mapDetailId && item.label == this.mapDetailLabel
        ).trips;
        thisClass.detailAllData = row.plantLatLong.find(
          (item) =>
            item.id == this.mapDetailId && item.label == this.mapDetailLabel
        ).trips;
        thisClass.detailType = marker.type;
        setTimeout(() => {
          let modal = document.getElementById('detailModal');
          modal.classList.add('d-block');
        }, 50);
      });
    });
  }

  closeModal(res: boolean) {
    let modal = document.getElementById('detailModal');
    modal.classList.remove('d-block');
  }

  showBackdrop() {
    this.backdrop = document.createElement('div');
    this.backdrop.classList.add('modal-backdrop');
    this.backdrop.classList.add('show');
    document.body.appendChild(this.backdrop);
  }

  getSiteGeoFenceLatLong(data) {
    let latLongs = [];
    // let shorter = s => s.split(' ').map(item => item[0]).join('');
    if (data.destinations && data.destinations.length) {
      data.destinations.forEach((destination) => {
        if (destination.pos) {
          latLongs.push({
            type: 3,
            label: `${destination.destination_name} (${destination.active_trips})`,
            data: this.getLatLongFromPolygon(destination.pos),
            id: destination.destination_id,
            trips: destination.trips,
            lat: destination.lat,
            lng: destination.long,
            radius: destination.radius,
          });
        }
      });
    }

    if (data.stages && data.stages.length) {
      data.stages.forEach((stage) => {
        if (stage.pos) {
          latLongs.push({
            type: 2,
            label: `${stage.stage_name} (${stage.active_trips})`,
            data: this.getLatLongFromPolygon(stage.pos),
            id: stage.stage_id,
            trips: stage.trips,
            lat: stage.lat,
            lng: stage.long,
            radius: stage.radius,
          });
        }
      });
    }

    if (data.zones && data.zones.length) {
      data.zones.forEach((zone) => {
        if (zone.pos) {
          latLongs.push({
            type: 4,
            label: zone.zone_name,
            lat: zone.lat,
            lng: zone.long,
            radius: zone.radius,
            data: this.getLatLongFromPolygon(zone.pos),
            id: zone.geofence_id,
            parkingZoneId: zone.id,
          });
        }
      });
    }
    if (data.pzone && data.pzone.length) {
      data.pzone.forEach((zone) => {
        if (zone.pos) {
          latLongs.push({
            type: 5,
            label: zone.zone_name,
            lat: zone.lat,
            lng: zone.long,
            radius: zone.radius,
            data: this.getLatLongFromPolygon(zone.pos),
            id: zone.geofence_id,
            zoneId: zone.id,
          });
        }
      });
    }/// get verified after adding zone api of saloni branch
    if (data.hardware && data.hardware.length) {
      data.hardware.forEach((hardware) => {
        if (hardware.pos) {
          latLongs.push({
            type: 6,
            label: hardware.hardware_name,
            lat: hardware.lat,
            lng: hardware.long,
            radius: hardware.radius,
            data: this.getLatLongFromPolygon(hardware.pos),
            id: hardware.geofence_id,
            hardWareId: hardware.ipa_hardware_id,
          });
        }
      });
    }
    if (data.pos) {
      latLongs.push({
        type: 1,
        label: `${data.plant_name} (${data.active_trips})`,
        data: this.getLatLongFromPolygon(data.pos),
        id: data.plant_id,
        trips: data.trips,
        lat: data.lat,
        lng: data.long,
        radius: data.radius,
      });
    }
    return latLongs;
  }

  getLatLongFromPolygon(str) {
    let latLong = [];

    if (!str.includes('EMPTY') && !str.includes('OLLECTION EMP')) {
      let latLongString = String(str).split(',');
      latLongString.forEach((item) => {
        let latLongs = item.split(' ').map((item) => Number(item));
        latLong.push({ lat: latLongs[0], lng: latLongs[1] });
      });
    }
    return latLong;
  }

  LoadGeoFence(data, event = false) {
    let latLngsArray = [];
    if (data.length > 0) {
      latLngsArray.push(data);
      if(!this.centreAtFlag){
        let latLong = { lat: data[0].lat, lng: data[0].lng };
        this.mapService.centerAt(latLong);
        this.mapService.zoomAt(latLong, 13);
      }
      this.mapService.createPlantGeoFence(data, event, false);
    }
  }

  ngAfterViewInit() {
    this.mapService.mapIntialize('map');

    this.mapService.addListerner(this.mapService.map, 'click', (event) => {
      if (this.mapService.isDrawMarker) {
        this.mapService.resetMarker();
        this.mapService.markers = [];
        this.mapService.placeSingleMarker(event.latLng);
      }
    });
    setTimeout(() => {
      this.mapService.setMapType(0);
    }, 2000);
    this.mapService.createPolygonPath();
  }

  tripModal(vehicle) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.transaction_id,
      isModal: true,
      processName: 'processName',
    };
  }

  tripTrackingModal(vehicle) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.transaction_id,
      vehicleName: vehicle.vehicle_rc_no + '-' + 'processName',
      isModal: true,
    };
  }
}

<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">{{'pending_tasks'| translate}}</h5>
</div>
<div class="modal-body pending_task_modal">
  <div class="msg">
    {{'pending_task_msg' | translate}}
  </div>
  <ng-container *ngFor="let item of activeTxnsForStages; let i = index">
    <div class="process-stage-block">
      <div class="label_div">{{ item.processstagename }}</div>
      <div class="vehicle-rc-numbers">
        <ng-container *ngFor="let txn of item.transactions;">
          <div class="rc_number_row" appEllipsisView>{{ txn.vehicle_rc_no }}
            <span class="badge status-badge">{{txn.stage_workflow_status}}</span>
          </div>
        </ng-container>
      </div>

      <div *ngIf="userInputFlags[i]" class="select_user">
        <ps-auto-suggestion (onSelected)="selectedUser($event)" [data]="nextShiftUsersListForAllStages[i]"
          display="user_name_with_shift" placeholder="Select User" [preSelected]="" class="auto_suggestion">
        </ps-auto-suggestion>
        <button type="button" class="btn btn-success" (click)="saveData(item.txnIds, item.stage_id,1);">{{'assign_user'
          | translate}}</button>
      </div>

      <div class="user-div">
        <button type="button" class="btn btn-secondary" *ngIf="!userInputFlags[i]"
          (click)="setUserInputFlag(i,item.stage_id)">{{'assign_user' | translate}}</button>
        <button type="button" class="btn btn-success" *ngIf="!userInputFlags[i]"
          (click)="saveData(item.txnIds, item.stage_id,0);">{{'assign_manager' | translate}}</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="pendingChecklists.length > 0" >
    <div class="process-stage-block">
    <div class="label_div">Pending Checklists</div>
    <div class="vehicle-rc-numbers">
      <ng-container *ngFor="let item of pendingChecklists; let i = index">
        <div class="rc_number_row" appEllipsisView>{{ item.checklist_title }}
          <span class="badge status-badge">{{item.status}}</span>
        </div>
      </ng-container>
    </div>
    <div class="user-div">
      <button type="button" class="btn btn-success"
        (click)="reDirectToChecklistDashboard()">{{'Go to Checklist Dashboard' | translate}}</button>
    </div>
    </div>
  </ng-container>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Dock Appointment Report' | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="page_design">
                <div class="tab_sec">
                  <div class="date_div">
                    <div class="row">
                      <!-- <div class="col-md-2 date" *ngIf="role.user_role=='COMPANYADMIN'">
                        <label>{{'plant'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                          placeholder="Choose Plant">
                        </ps-auto-suggestion>
                      </div> -->
                      <div class="col-md-3 date">
                        <label>{{'start_date:'|translate}}</label>
                        <input type="date" class="form-control" [(ngModel)]="startDate">
                      </div>
                      <div class="col-md-3 date">
                        <label>{{'end_date:'|translate}}</label>
                        <input type="date" class="form-control" [min]="startDate" [(ngModel)]="endDate">
                      </div>
                      <div class="col-md-1 btn-block text-right">
                        <button class="btn btn-primary" (click)="getDockAppointmentReportData()">{{"search" |
                          translate}}</button>
                      </div>
                      <!-- <button type="button"
                      (click)="exportAsExcel(dockReportData,'Dock Appointmemt Report'+this.startDate+' to '+this.endDate)"
                      class="fa fa-file-excel"
                      style="margin-left: 10px;color:rgb(57 117 22);cursor: pointer;border:.8px green solid;font-size:18px"></button> -->
                    </div>
                  </div>

                </div>

                <div class="view_table">
                  <div class="filter_sec">
                    <div class="row">
                      <div class="col-md-3 coll">
                        <label>{{'Select Transporter'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="onSelectTransporter($event)" [data]="transporterList"
                          display="transporterName" placeholder="Choose Transporter"
                          [preSelected]="{transporterName:transporterName}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'Select Product'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="onSelectProduct($event)" [data]="productList" display="product_name"
                          placeholder="Choose Product" [preSelected]="{product_name:productName}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'Select Driver'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="onSelectDriver($event)" [data]="driverList" display="driverName"
                          placeholder="Choose Driver" [preSelected]="{driverName:driverName}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'Select Vehicle'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="onSelectVehicle($event)" [data]="vehicleList" display="vehicle_rsn"
                          placeholder="Choose Vehicle" [preSelected]="{vehicle_rsn:vehicleRc}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'Select Status'|translate}}</label>
                        <select name="gender" id="#gender" class="form-control" [(ngModel)]="status" (change)="getDockAppointmentReportData()">
                          <option value="Booked">Booked</option>
                          <option value="Inprogress">Inprogress</option>
                          <option value="Loaded">Loaded</option>
                          <option value="Cancelled">Cancelled</option>
                          <option value="Completed">Completed</option>

                        </select>
                      </div>
                      <div class="btn-block" style="margin-left: 95%;">
                        <button class="btn btn-warning" (click)="reset()"> {{'reset'|translate}} </button>
                      </div>
                    </div>

                  </div>

                  <div class="form-group action-block mt-2 mb-2">

                    <!-- <button type="button" class="excel-btn excel"
                      (click)="exportAsExcel(dockReportData,'Dock Appointmemt Report'+this.startDate+' to '+this.endDate)">
                      <i class="fas fa-file-excel"></i>
                      </button> -->
                  </div>

                  <div class="table_sec">
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{'serial_no' | translate}}</th>
                          <th>{{'date'|translate}}</th>
                          <th>{{'Vehicle No' | translate}}</th>
                          <th>{{'Product' | translate}}</th>
                          <th>{{'Transporter' | translate}}</th>
                          <th>{{'Driver' | translate}}</th>
                          <th>{{'Dock In'|translate}}</th>
                          <th>{{'Dock Out' | translate}}</th>
                          <th>{{'Status' | translate}}</th>
                          <th>{{'Plant In' | translate}}</th>
                          <th>{{'Plant Out'|translate}}</th>
                          <!-- <th>{{'Action'|translate}}</th> -->
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let item of this.dockReportData, let i = index">
                          <td>{{i+1}}</td>
                          <td style="width:10%">{{item.booking_date| date:'dd-MM-yyyy'}}</td>
                          <td>{{item.vehicle_rsn}}</td>
                          <td>{{item.product_name}}</td>
                          <td>{{item.transporter_name}}</td>
                          <td>{{item.driver_name}}</td>
                          <td>{{item.dock_slot_in_time}}</td>
                          <td>{{item.dock_slot_out_time}}</td>
                          <td><span class="pill" [ngClass]="{'bg-blue-100 text-blue-800':item.status=='Booked','bg-green-200 text-green-800':item.status=='Completed','bg-red-200 text-red-800':item.status=='Cancelled','bg-yellow-200 text-yellow-800':item.status=='Inprogress','bg-teal-200 text-teal-800':item.status=='Loaded'}">
                           {{item.status}}
                          </span></td>
                          <td>{{item.plant_in_time}}</td>
                          <td>{{item.plant_out_time}}</td>
                          <!-- <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-success" (click)="viewDetails(item)">{{"view_details" |
                                translate}}</button>
                            </div>
                          </td> -->

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{hardwareTitle | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input" class="required">{{"select_company" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectCompany($event)" [data]="company" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:selectedCompanyName}">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input" class="required">{{"select_plant" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantsData" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{plantName:selectedPlantName}">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"destination" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedDestination($event)" [data]="destinationList"
                    display="stage_destination_code" placeholder="Choose Destination"
                    [preSelected]="{stage_destination_code:destinationName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="destinationValidator && destinationValidator.error">
                    <span>{{destinationValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"hardware" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedHardware($event)" [data]="hardwareList"
                    display="hardwareName" placeholder="Choose Hardware" isMultiSelect="true"
                    [preSelected]="hardwareNameMulti">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="hardwareNameValidator && hardwareNameValidator.error">
                    <span>{{hardwareNameValidator.msg}} </span>
                  </div>
                </div>

              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-muted" (click)="common.backClicked()"> {{"back" | translate}} </button>
                  <button class="btn btn-warning" (click)="reset()"> {{"reset" | translate}} </button>
                  <button type="submit" (click)="formValidator()" class="btn btn-submit"> {{btn | translate}} </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="header-block">
              <h3 class="card-title">
                {{"hardware_mapping list" | translate}}
              </h3>
            </div>
          </div>

          <div class="card-body">
            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead class="thead-light">
                <tr>
                  <th>{{"s.no." | translate}}</th>
                  <th>{{"hardware_name" | translate}}</th>
                  <th>{{"destination_code" | translate}}</th>
                  <th class="action"> {{"action" | translate}} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor=" let item of hardwareMappingData; let i = index">
                  <td>{{i+1}}</td>
                  <td>{{item.hardwareNameTable}}</td>
                  <td>{{item.destinationCode}}</td>
                  <td class="action">
                    <div class="btn-block">
                      <button class="btn btn-primary" (click)="setActiveComponent(item)"> {{"view" | translate}}
                      </button>
                      <button class="btn btn-success" (click)="editMapping(item)"> {{"edit" | translate}} </button>
                      <button class="btn btn-danger" (click)="deleteMapping(item)"> {{"delete" | translate}} </button>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

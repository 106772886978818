<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block" style="display: flex; flex-direction: row">
                <h3 class="card-title">
                  {{('visitor'|customTranslate)+' '+('report'|customTranslate)}}
                </h3>
                <button  type="button" class="close right-tag" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <section class="open_sec">
              <div class="view_table">
                <div class="filter_sec" style="margin-bottom: 30px;">
                  <div class="row" style="display: flex; align-items: flex-end;">
                    <div class="col-md-3">
                      <label>{{'status'|customTranslate}}</label>
                      <select class="form-control" name="status" [(ngModel)]="tripStatus"
                        (ngModelChange)="filterDataByCondition(tripStatus)">
                        <option value="ALL"> ALL </option>
                        <option value="ALERT">ALERT</option>
                        <option value="RESTRICTED">RESTRICTED</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="table_sec">
                  <app-custom-table [data]="reportDisplayList" [columns]="tableColumns"  [tableType]="TABLE_TYPE"></app-custom-table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



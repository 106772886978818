import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { CompanyService } from '../companies/companies.service';
import { PlantService } from '../plant/plant.service';
import { TableService } from 'src/app/@core/services/table.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { ApiService } from 'src/app/@core/services/api.service';

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.scss']
})
export class ProductManagementComponent implements OnInit {

  product = {
    productId: null,
    productName: null,
    productStatus: 'ACTIVE',
    companyId: null,
    plantId: null,
    productTypeId:null
  }
  company: any[] = [];
  plantData: any = [];
  companyName: null;
  plantName: null;
  productTypeName:null;
  productNameValidator: any;
  productTypeValidator:any;
  responseData: any;
  productTypeData:any[];
  plantValidator:any;
  companyValidator:any;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'PRODUCT LIST');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getProduct();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  constructor(public common: CommonService, private userService: UserService, public breadcrumbService: BreadcrumbService, private modalService: NgbModal,
    private alertService: AlertService, public role: RoleService, private companyService: CompanyService, private plantService: PlantService, public table: TableService,
  public api:ApiService) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Product', url: null },
      { name: 'product', url: '/user/product-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);

    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
    this.getAllProductType();
  }

  checkEdit(company_id: any, plant_id: any) {
    if (this.role.user_role == "AXESTRACK") {
      return true;
    }
    else if (this.role.user_role == "AXESTRACKUSER" || this.role.user_role == "COMPANYADMIN") {
      return (company_id == 1) ? false : true;
    } else {
      return (company_id == 1 || !plant_id) ? false : true;
    }
  }

  getAllProductType() {
    this.common.loading = true;

    this.api.get("product/get-product-type").subscribe(
      (res: any) => {
        this.common.loading = false;

        this.productTypeData = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  resetWorkType(): void {
    this.product.productName = null;
    this.product.productStatus = 'ACTIVE';
    this.product.companyId = null;
    this.product.productTypeId = null;
    this.companyName = null;
    this.plantName = null;
    this.product.plantId = null;
    this.product.productId = null,
      this.productNameValidator = null;
      this.productTypeName=null;
    if (this.role.user_role == 'AXESTRACK') {
      this.plantData = [];
    }
  }

  selectedCompany(event: any) {
    this.product.companyId = event.companyId;
    this.companyName = event.companyName;
    this.plantName = null;
    this.product.plantId = null;
    this.getPlantById(event.companyId);
  }


  selectedPlant(event: any) {
    this.product.plantId = event.plantId;
    this.plantName = event.plantName;
  }

  ngOnInit(): void {

  }

  getProduct() {
    let params = {
      plantId:this.product.plantId!=null?this.product.plantId:null,
      companyId:this.product.companyId!=null?this.product.companyId:null,
      allProducts:true
    }
    this.api.get("product/get-product-list",params).subscribe((res: any) => {
      if (res.status) {
        this.responseData = res.data;
        this.renderTable();
      }
    });

  }

  selectedProductType(event:any){
    this.product.productTypeId = event.value;
    this.productTypeName = event.label;
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          res.data.forEach((element) => {
            if (element.companyStatus == 'ACTIVE') this.company.push(element);
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  view(row?: any) {

    let obj = {
      'Product Name': row.product_name,
      'Company Name': row.company_name,
      'Plant Name': row.plant_name,
      'Product Type':row.product_type_name
    }

    this.common.params = {
      details: [obj],
      title: 'Product Details',
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Product',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row.product_name +
        '  ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteProduct(row);
      }
    });
  }

  deleteProduct(row) {
    this.api.get("product/delete-product?productId="+row.id).subscribe((res: any) => {
      if (res.status) {
        this.getProduct();
      }
    });
  }

  edit(row: any) {
    this.product.productId = row.id;
    this.product.productName = row.product_name;
    this.product.productStatus = row.status==1?'ACTIVE':'INACTIVE';
    this.product.companyId = row.company_id;
    this.product.plantId = row.plant_id;
    this.product.productTypeId = row.product_type_id;
    this.productTypeName = row.product_type_name;
    if (!(row.company_id == 1 && row.plant_id == 1)){
      this.plantName = row.plant_name;
      this.companyName = row.company_name;
    }else{
      this.plantName = null;
      this.companyName = null;
    }
    this.role.user_role == 'AXESTRACK' ? this.getPlantById(row.company_id) : (this.role.user_role == "COMPANYADMIN" ? this.getPlantById(this.userService._loggedInUser.companyId) : null);

  }

  formValidator() {
    this.productNameValidator = this.common.nullValidator(
      this.product.productName!=null && this.product.productName.trim(),
      'Product Name Mandatory'
    );
    this.productTypeValidator = this.common.nullValidator(
      this.product.productTypeId!=null,
      'Product Type Mandatory'
    );
    if(this.uniqueNamecheck())return this.alertService.error("Product Name should be unique.");
    if (!this.productNameValidator.error && !this.productTypeValidator.error) {
      // console.log("savinggg")
      this.saveProduct();
    }
  }

  private saveProduct() {
    this.common.loading = true;
    this.api.post("product/save-product",this.product).subscribe((response:any) => {
      this.common.loading = false;
      if (response.status) {
        this.alertService.success(response.message);
        this.resetWorkType();
        this.getProduct();
      } else {
        this.alertService.error(response.message);
      }

    }, (error) => {
      this.common.loading = false;
    });
  }

  uniqueNamecheck(){
    return this.responseData.find((event)=>{
     return ((this.role.user_role == 'AXESTRACK' && ((event.plant_id == this.product.plantId && event.company_id == this.product.companyId)||(event.plant_id == 1 && event.company_id == 1)))||(this.role.user_role == 'COMPANYADMIN' && (event.plant_id == this.product.plantId || event.plant_id==1))||(this.role.user_role != 'COMPANYADMIN' && this.role.user_role != 'AXESTRACK')) && (event.product_name == this.product.productName.trim() && this.product.productId != event.id)
    });
  }
}

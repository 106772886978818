import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DashboardService } from '../dashboard/dashboard.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/@core/services/user.service';
import { PlantService } from '../plant/plant.service';
import { TableService } from 'src/app/@core/services/table.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['../dashboard/dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  allCompany: any[] = [];
  companyinfoColor = [
    'bg-primary',
    'bg-success',
    'bg-warning',
    'bg-info',
    'bg-danger',
  ];
  companyinfoicons: any = [
    {
      'Total Plants': 'fa fa-industry',
      'Total Stages': 'fa fa-signal',
      'Total Companies': 'fas fa-building',
      'Total Vehicle': 'fa fa-truck',
      'Total Process': 'fa fa-cog',
    },
  ];

  allcompanySectors: any[] = [];
  @ViewChild(DataTableDirective, {
    static: false,
  })
  CompanyElement: any;
  CompanyTrigger: any = new Subject();
  companyOptions: any = this.table.options(5, 3);

  constructor(
    public workflow: DashboardService,
    public userService: UserService,
    public plantService: PlantService,
    public table: TableService
  ) {}

  ngAfterViewInit() {
    this.CompanyTrigger.next();
  }
  ngOnDestroy(): void {
    this.CompanyTrigger.unsubscribe();
  }
  renderCompanyTable() {
    this.CompanyElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.CompanyTrigger.next();
    });
  }
  getallcommoncount() {
    this.workflow.getallcommoncount().subscribe((item: any) => {
      if (item.status) {
        this.allCompany = item.data ?? [];
        if (this.allCompany.length) {
          this.allCompany.map((i: any, index: number) => {
            (i.class = this.companyinfoColor[index]),
              (i.icon = this.companyinfoicons[0][i.name]);
          });
        }
      }
    });
  }

  getallcompanytype() {
    this.workflow.getallcompanysectors().subscribe((item: any) => {
      if (item.status) {
        this.allcompanySectors = item.data ?? [];
        this.renderCompanyTable();
      }
    });
  }

  ngOnInit(): void {
    this.getallcommoncount();
    this.getallcompanytype();
  }
}

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import _ from 'lodash';
import { companyData, CustomFieldWithMapping, customForms, plantsData } from 'src/app/dataType/formInterface';
import { FIELD_TYPE_ICONS } from './data';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-visitor-card-field-mapping',
  templateUrl: './visitor-card-field-mapping.component.html',
  styleUrls: ['./visitor-card-field-mapping.component.scss']
})
export class VisitorCardFieldMappingComponent implements OnInit {
  companyId: number = null
  companyName: string = null
  plantId: number = null
  plantName: string = null
  formId: number = null
  formName: string = null

  attachVisitorImg: boolean = false;
  fieldTypes = FIELD_TYPE_ICONS;

  companyData: companyData[] = []
  plantData: plantsData[] = []
  formsList: customForms[] = []

  allFields: CustomFieldWithMapping[] = [];
  fieldList: CustomFieldWithMapping[] = [];

  mappedFields: CustomFieldWithMapping[] = [];

  constructor(
    private common: CommonService,
    private api: ApiService,
    public role: RoleService,
    private translateService: TranslateService,
    private alert: AlertService,
    private breadcrumbService: BreadcrumbService,
  ) { }

  ngOnInit() {

    // Setting Page Breadcrumb
    this.breadcrumbService.getBreadcrumbs([
      { name: 'visitors', url: null },
      {
        name: 'Visitor Card Design',
        link: null,
      },
    ]);

    if (this.role.user_role == 'AXESTRACK') {
      // Get All Company List
      this.getCompany();
    } else if (this.role.user_role == 'COMPANYADMIN') {
      // Get All Plant List by company
      this.getPlants(null);
    }

    // Get All Global Form List
    this.getAllCustomForms();
  }


  /*
    Get all comapny list
  */
  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.companyData = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  /*
    Get all plant list by company
  */
  getPlants(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.api.get('plant/all_plants', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  /*
    Get Forms (Global/ Company/ Plant level)
  */
  getAllCustomForms() {
    this.common.loading = true;
    let params = {
      formType: 'Visitor Form',
      companyId: this.companyId,
      plantId: this.plantId,
      isActive: true
    };
    this.api.get('form/get-custom-forms', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.formsList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }


  /*
    Reset Card
  */
  resetCard() {
    this.mappedFields = [];
    this.allFields = [];
    this.fieldList = [];
    this.attachVisitorImg = false;
  }

  /*
    Handle Company Select
  */
  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;

    // Reset Child Fields
    this.plantId = null;
    this.plantName = null;
    this.formId = null;
    this.formName = null;

    // Reset Visitor Card
    this.resetCard();

    // Get All Plant by Company
    this.getPlants(event.companyId);

    // Get All Company + Global Forms
    this.getAllCustomForms();
  }

  /*
    Handle Plant Select
  */
  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;

    // Reset Child Fields
    this.formId = null;
    this.formName = null;

    // Reset Visitor Card
    this.resetCard();

    // Get All Plant + Company + Global Forms
    this.getAllCustomForms();
  }

  /*
    Handle Form Select
  */
  selectedForm(event: customForms) {
    this.formId = event.id;
    this.formName = event.form_title;

    // Reset Visitor Card
    this.resetCard();

    /*
      Get All Fields Mapped on form
      Global Level (Axestrack Admin)
      Company + Global Level (Company Admin)
      Plant + Company + Global Level (Plant Admin)
     */
    this.getVisitorFormFieldsList()
  }


  /*
    Get Fields by form and login level (with mapped or unmapped status)
  */
  getVisitorFormFieldsList() {
    this.common.loading = true;
    let params = {
      formId: this.formId,
      companyId: this.companyId,
      plantId: this.plantId
    };
    this.api.get('visitors/get-visitor-card-fields', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.allFields = res.data;
        this.fieldList = res.data;

        // Filtering Already Mapped fields
        this.mappedFields = res.data.filter((f: CustomFieldWithMapping) => {

          // Check is visitor_image is mapped or not
          if (f.database_field_name == 'visitor_image') this.attachVisitorImg = true
          return f.is_mapped
        });
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  /*
    Handle Field Search
  */
  searchFieldName(event: any = '') {
    if (event.target.value.trim() != '') {
      this.fieldList = this.allFields.filter((field) =>
        field.field_name
          .toLowerCase()
          .includes(
            event.target.value.trim().toLowerCase()
          )
      );
    } else {
      this.fieldList = this.allFields;
    }
  }

  /*
    Handle Field Add or Remove over Visitor card
  */
  handleFieldChange(event: any, field: CustomFieldWithMapping) {
    if (event.target.checked) {
      if (this.mappedFields.length < 6 || (this.mappedFields.length == 6 && this.attachVisitorImg) || (!this.attachVisitorImg && field.database_field_name == 'visitor_image')) {
        if (field.database_field_name == 'visitor_image') {
          this.attachVisitorImg = true;
        }
        field.is_mapped = true
        this.mappedFields.push(field);
      } else {
        event.target.checked = false;
        this.alert.error(this.translateService.instant("visitor_card_mapping_note"))
      }
    } else {
      if (field.database_field_name == 'visitor_image') {
        this.attachVisitorImg = false;
      }
      field.is_mapped = false
      this.mappedFields = this.mappedFields.filter(f => f.master_field_id != field.master_field_id);
    }
  }

  /*
    Save Card and field Mapping
  */
  saveMapping() {
    this.common.loading = true;
    let params = {
      fieldIds: this.mappedFields.map(fields => fields.master_field_id),
      formId: this.formId,
      companyId: this.companyId,
      plantId: this.plantId,
    }
    this.api.post('visitors/save-visitor-card-fields-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alert.success(res.message);
        } else {
          this.alert.info(res.message);
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

}

import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable()
export class DataMapperInterceptor implements HttpInterceptor {
  onlineMessage;

  constructor(private alertService:AlertService, private common:CommonService, private tokenService: TokenStorageService) {
    // this.checkConnectionStatus();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //console.log("Mapp data");
    // console.log('api call');

    // this.checkConnectionStatus();
    return next.handle(request).pipe(
      map((resp: any) => {
        if (resp instanceof HttpResponse) {
            if(resp.body.type == "FORCELOGOUT"){
              this.tokenService.signOut();
            }else if(resp.body.type == "BLOCKUSER"){
              this.alertService.error("Your Account has been blocked by Admin");
              this.tokenService.signOut();
            }else{
              return resp.clone({body: resp.body});
            }
        }

        return resp;
      })
    );
  }

}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body stageType-form">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div *ngIf="role.user_role == 'AXESTRACK'"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"company" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyValidator && companyValidator.error">
                    <span>{{companyValidator.msg}} </span>
                  </div>
                </div>

                <div *ngIf="(role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN')"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantData"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantValidator && plantValidator.error">
                    <span>{{plantValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"Zone" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedZone($event)" [data]="zoneList" a
                    display="zone_name" placeholder="Choose Zone" [readonly]="isEdit" [preSelected]="{zone_name:selectedZoneName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="zoneValidator && zoneValidator.error">
                    <span>{{zoneValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"Mapping Type" | customTranslate}}</label>
                  <select name="gender" [disabled]="isEdit" id="#gender" class="form-control"
                    [(ngModel)]="mappingType" (click)="onMappingTypeChange(mappingType)">
                    <option selected="USER" value="USER">User</option>
                    <option value="STAFF">Staff</option>
                    <option value="DEPARTMENT">Department</option>
                    <option value="GROUP">Group</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="mappingType == 'USER'">
                  <label for="my-input" class="required"> {{"User" | customTranslate}} </label>
                  <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedUsers($event)"
                    [data]="userList" display="name" placeholder="Choose User"
                    [preSelected]="selectedUserList">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="entityValidator && entityValidator.error">
                    <span>{{entityValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="mappingType == 'STAFF'">
                  <label for="my-input" class="required"> {{"Staff Type" | customTranslate}} </label>
                  <ps-auto-suggestion  (onSelected)="onSelectStaffType($event)" [data]="staffTypeList"
                    display="type_name" placeholder="Choose Staff Type" [readonly]="isEdit" [preSelected]="{type_name:staffTypeName}">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="mappingType == 'STAFF'">
                  <label for="my-input" class="required"> {{"Staff" | customTranslate}} </label>
                  <ps-auto-suggestion isMultiSelect="true"  (onSelected)="onStaffSelection($event)" [data]="staffList"
                    display="staff_name" placeholder="Choose Staff" [preSelected]="selectedStaffList">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="entityValidator && entityValidator.error">
                    <span>{{entityValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="mappingType == 'DEPARTMENT'">
                  <label for="my-input" class="required"> {{"Department" | customTranslate}} </label>
                  <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedDepartment($event)" [data]="deptList"
                    display="dept_name" placeholder="Choose Department"
                    [preSelected]="selectedDeptList" >
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="entityValidator && entityValidator.error">
                    <span>{{entityValidator.msg}} </span>
                  </div>
                </div>



          <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="mappingType == 'GROUP'">
            <label for="my-input" class="required"> {{"Group" | customTranslate}} </label>
            <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedGroup($event)" [data]="groupList" display="group_name"
            placeholder="Select Group" [preSelected]="selectedGroupList" inputId="name">
          </ps-auto-suggestion>
          <div class="error" *ngIf="entityValidator && entityValidator.error">
            <span>{{entityValidator.msg}} </span>
          </div>
          </div>

              </div>


              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    customTranslate}}</button>
                  <button class="btn btn-warning" (click)="reset()">{{"reset" | customTranslate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Zone Allocation List" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"serial_no" | customTranslate}}</th>
                    <th>{{"zone_name" | customTranslate}}</th>
                    <th>{{"Mapping Type" | customTranslate}}</th>
                    <th>{{"Mapped Entities" | customTranslate}}</th>
                    <th *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{"plant_name" | customTranslate}}</th>
                    <th *ngIf="role.user_role=='AXESTRACK'">{{"company_name" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of groupedDataByZone | keyvalue; let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.value[0].zone_name}}</td>
                    <td>{{ row.value[0].entity_type }}{{ row.value[0].entity_type === 'STAFF' ? ' (' + row.value[0].type_name + ')' : '' }}</td>
                    <td>{{ row.value | formatNames: { key: 'entity_name' } }}</td>
                    <td *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{row.value[0]?.plant_name}}</td>
                    <td *ngIf="role.user_role=='AXESTRACK'">{{row.value[0]?.company_name}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="view(row)">{{"view" | customTranslate}}</button>
                        <button class="btn btn-success" (click)="edit(row)">{{"edit" | customTranslate}}</button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)">{{"delete" | customTranslate}}</button>
                      </div>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

export const iterationType=[
    { type: 'Daily', value: 'Daily' },
    { type: 'Weekly', value: 'Weekly' },
    { type: 'Monthly', value: 'Monthly' },
    { type: 'Yearly', value: 'Yearly' },
]

export const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  export const monthOfYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

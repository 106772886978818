import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { UserService } from 'src/app/@core/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { Subject } from 'rxjs';
import { TableService } from 'src/app/@core/services/table.service';
import { DataTableDirective } from 'angular-datatables';
import { AssetsService } from './assets.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { AssetCategoryServiceService } from '../asset-category/asset-category-service.service';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { WebcamImage } from 'ngx-webcam';
import { Base64ToFileService } from 'src/app/@core/services/base64-to-file-service.service';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import _ from 'lodash';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Asset, AssetCategory, CompanyData, customFields, Manufacturer, ParentAsset, PlantData, Supplier } from 'src/app/dataType/assetInterface';
import { validator } from 'src/app/dataType/commonInterface';


@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {
  @ViewChild('myInput') myInputVariable: ElementRef;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'ASSET  LIST');

  //global variables
  plantLevelRoles = ['AXESTRACKUSER' , 'COMPANYUSER' , 'PLANTADMIN', 'PLANTUSER'];
  customFields: any[] = [];
  customFieldValues: any[] = [];
  fileEvent: any = [];
  notUniqueFieldId: number = null;
  company: CompanyData[] = [];
  plantData: PlantData[] = [];
  categoryData: AssetCategory[] = [];
  manufacturerData: Manufacturer[] = [];
  parentAssetData: ParentAsset[] = [];
  supplierData:Supplier[] = [];
  companyName: string = null;
  plantName: string = null;
  CategoryName: string = null;
  ManufacturerName: string = null;
  SupplierName: string = null;
  ParentAssetName: string = null;
  minimumTimestamp: string = this.datePipe.transform(new Date(), 'yyyy-MM-ddThh:mm:ss');
  asset = {
    assetId: null,
    assetName: null,
    assetDesc: null,
    assetCategoryId: null,
    manufacturerId: null,
    supplierId: null,
    assetCost: null,
    uniqueTagCode: null,
    uniqueTagType: null,
    assetStatus: 'ACTIVE',
    companyId: null,
    plantId: null,
    parentAssetId: null,
    expiryDate: null,
    warrantyExpireDate: null,
    deleteFlag: false,
    assetCode: null,
    assetImage:null
  }
  tagType = ['PASSIVETAGS', 'BLETAGS', 'ACTIVETAGS'];
  newParentData: ParentAsset[]=[];
  responseData: Asset[];
  assetViewFlag:boolean;
  editParent: boolean = false;

  //validators

  assetNameValidator: validator;
  assetCategoryValidator: validator;
  manufacturerValidator: validator;
  supplierValidator: validator;
  assetCostValidator: validator;

  constructor(public common: CommonService,
    private userService: UserService,
    private breadcrumbService: BreadcrumbService,
    private assetsService: AssetsService,
    private modalService: NgbModal,
    private alertService: AlertService,
    public role: RoleService,
    private plantService: PlantService,
    public table: TableService,
    public api: ApiService,
    private categoryService: AssetCategoryServiceService,
    private base64ToFileService: Base64ToFileService,
    private imageCompress: NgxImageCompressService,
    private datePipe: DatePipe,
    public router: Router) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'assets', url: null },
      { name: 'assets', url: '/user/asset-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
  }

  ngOnInit(): void {
    this.apiCalling();
    this.assetViewFlag = history.state.flag;
    if(this.assetViewFlag){
      this.editAsset(history.state.data);
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  apiCalling() {
    this.getSupplier();
    this.getAssetCategory();
    this.getManufacturer();
    this.getAsset();
    this.getParentAsset();
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  resetAsset(): void {
    this.asset = {
      assetId: null,
      assetName: null,
      assetDesc: null,
      assetCategoryId: null,
      manufacturerId: null,
      supplierId: null,
      assetCost: null,
      uniqueTagCode: null,
      uniqueTagType: null,
      assetStatus: 'ACTIVE',
      companyId: null,
      plantId: null,
      parentAssetId: null,
      expiryDate: null,
      warrantyExpireDate: null,
      deleteFlag: false,
      assetCode: null,
      assetImage:null
    }
    this.editParent = false;
    this.assetCostValidator = null;
    this.CategoryName = null;
    this.asset.assetDesc = null;
    this.ManufacturerName = null;
    this.ParentAssetName = null;
    this.SupplierName = null;
    this.manufacturerValidator = null;
    this.supplierValidator = null;
    this.companyName = null;
    this.plantName = null;
    this.customFields = [];
    if (this.role.user_role == 'AXESTRACK') {
      this.plantData = [];
    }
    this.customFieldValues = [];
    this.apiCalling();
  }

  selectedCompany(event: any) {
    this.asset.companyId = event.companyId;
    this.companyName = event.companyName;
    this.plantName = null;
    this.asset.plantId = null;
    this.getPlantById(event.companyId);
    this.getManufacturer();
    this.getSupplier();
  }

  getParentAsset() {
    this.common.loading = true;
    this.assetsService.getParentAssets().subscribe((res: any) => {
      if (res.status) {
        this.common.loading = false;
        this.parentAssetData = res.data;
      }
    }, (err: any) => {
      console.error('Error: ', err);
      this.common.loading = false;
    });
  }

  selectedPlant(event: any) {
    this.asset.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getManufacturer();
    this.getSupplier();
  }

  selectedCategory(event: any) {
    this.asset.assetCategoryId = event.asset_category_id;
    this.CategoryName = event.category_name;
    this.customFieldValues = [];
    this.customFields = [];
    this.getCategoryWiseCustomFields(event.asset_category_id);
  }

  async getCategoryWiseCustomFields(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.api.get("assets/get-category-wise-custom-fields",{categoryId:id}).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.customFields = res.data;
          if (this.customFields.length) {
            this.addJsonToFormValues();
          }
          resolve();
        },
        (err: any) => {
          reject(null)
          console.error('Error', err);
          this.common.loading = false;
        }
      )
    });
  }

  saveSignature(signatureImage: any, index: number, field: any) {
    if (signatureImage) {
      this.customFieldValues[index].values = [];
      this.onSelect(signatureImage, null, index, true, true, 'SIGNATURE');
      this.customFieldValues[index].name = field.field_name;
      this.customFieldValues[index].field_id = field.master_field_id;
      this.customFieldValues[index].fieldType = field.field_type;
      this.customFieldValues[index]._dbField = field.database_field_name;
    }
  }


  addJsonToFormValues() {
    this.customFields.forEach((field) => {
      this.customFieldValues.push({
        name: '',
        values: [],
        fieldType: '',
        _dbField: '',
        mandatory: field.is_mandatory,
        unique: field.is_unique,
      });
    })
    this.saveDataBaseLinked();
  }

  insertLabel(field: any, index: any) {
    if (this.customFieldValues[index].values.length == 0) {
      this.customFieldValues[index].name = field.field_name;
      this.customFieldValues[index].field_id = field.master_field_id;
      this.customFieldValues[index].values.push(field.options[0].name);
      this.customFieldValues[index].fieldType = field.field_type;
    }
  }

  saveDataBaseLinked() {
    this.customFields.forEach((element) => {
      if (element.sel_value != null) {
        let i = this.customFields.indexOf(element);
        this.customFieldValues[i].name = element.field_name;
        this.customFieldValues[i].field_id = element.master_field_id;
        this.customFieldValues[i].values.push(element.sel_value);
        this.customFieldValues[i].fieldType = element.field_type;
        this.customFieldValues[i]._dbField = element.database_field_name;
      }
      element['multiValueOptions'] = [];
      if (element.field_type == 'SELECTLISTMULTIPLE') {
        element.options.forEach((ele) => {
          let obj = {
            option: ele.master_field_value,
          };
          element.multiValueOptions.push(obj);
        });
      }
    });
  }

  handleImage(webcamImage: WebcamImage, field: any, i: number) {
    this.customFieldValues[i].values = [];
    this.customFieldValues[i].name = field.field_name;
    this.customFieldValues[i].field_id = field.master_field_id;
    this.onSelect(webcamImage, null, i, true, false, field.field_type);
    this.customFieldValues[i].fieldType = field.field_type;
    this.customFieldValues[i]._dbField = field.database_field_name;
  }

  handleSingleSelectDBField(event: any, field: any, i: number) {
    this.customFieldValues[i].values = [];
    this.customFieldValues[i].values.push(event);
    this.customFieldValues[i].name = field.field_name;
    this.customFieldValues[i].field_id = field.master_field_id;
    this.customFieldValues[i].fieldType = field.field_type;
    this.customFieldValues[i]._dbField = field.database_field_name;
  }

  enterValues(event: any, field: any, i: number) {
    if (
      field.field_type != 'CHECKBOX' &&
      field.field_type != 'SELECTLISTMULTIPLE' &&
      field.field_type != 'FILE' &&
      field.field_type != 'SELECTLISTSINGLE'
    ) {
      this.customFieldValues[i].values = [];
      this.customFieldValues[i].name = field.field_name;
      this.customFieldValues[i].field_id = field.master_field_id;
      this.customFieldValues[i].values.push(event.target.value);
      this.customFieldValues[i].fieldType = field.field_type;
      this.customFieldValues[i]._dbField = field.database_field_name;
    }
    if (field.field_type == 'SELECTLISTSINGLE') {
      this.customFieldValues[i].values = [];
      this.customFieldValues[i].values.push(event[field.store_value]);
      this.customFieldValues[i].field_id = field.master_field_id;
      this.customFieldValues[i].name = field.field_name;
      this.customFieldValues[i].fieldType = field.field_type;
      this.customFieldValues[i]._dbField = field.database_field_name;
    }

    if (field.field_type == 'CHECKBOX') {
      if (event.target.checked) {
        this.customFieldValues[i].name = field.field_name;
        this.customFieldValues[i].field_id = field.master_field_id;
        this.customFieldValues[i].values.push(event.target.value);
        this.customFieldValues[i].fieldType = field.field_type;
        this.customFieldValues[i]._dbField = field.database_field_name;
      } else {
        let index = this.customFieldValues[i].values.indexOf(event.target.value);
        this.customFieldValues[i].values.splice(index, 1);
      }
    }
    if (field.field_type == 'SELECTLISTMULTIPLE') {
      this.customFieldValues[i].values = [];
      event.forEach((opt) => {
        this.customFieldValues[i].values.push(opt[field.store_value]);
      });
      this.customFieldValues[i].name = field.field_name;
      this.customFieldValues[i].field_id = field.master_field_id;
      this.customFieldValues[i].fieldType = field.field_type;
      this.customFieldValues[i].values = [...new Set(this.customFieldValues[i].values)];
      this.customFieldValues[i]._dbField = field.database_field_name;
    }

    if (field.field_type == 'FILE') {
      let methodId = field.method_id;
      this.onSelect(event, methodId, i, false, false, field.field_type);
      this.customFieldValues[i].values = [];
      this.customFieldValues[i].name = field.field_name;
      this.customFieldValues[i].field_id = field.master_field_id;
      this.customFieldValues[i].fieldType = field.field_type;
      this.customFieldValues[i]._dbField = field.database_field_name;
    }
  }

  onSelect(
    e,
    methodId,
    i,
    webcamImageType: boolean,
    signature: boolean,
    fieldType: string
  ) {
    let frmData = new FormData();
    let imgResultAfterCompression: DataUrl;
    if (webcamImageType == false) {
      for (const file of e.target.files) {
        frmData.append('files', file);
        this.fileEvent.inProgress = true;
        this.uploadFile(frmData, fieldType, i);
      }
    } else {
      let file;
      if (signature == false) {
        this.imageCompress
          .compressFile(e._imageAsDataUrl, -2, 50, 50) // 50% ratio, 50% quality
          .then((compressedImage) => {
            imgResultAfterCompression = compressedImage;
            file = this.base64ToFileService.base64toFile(
              imgResultAfterCompression,
              'image' + i + '.jpg'
            );
            frmData.append('files', file);
            this.fileEvent.inProgress = true;
            this.uploadFile(frmData, fieldType, i);
          });
      } else {
        this.imageCompress
          .compressFile(e, -2, 50, 50) // 50% ratio, 50% quality
          .then((compressedImage) => {
            imgResultAfterCompression = compressedImage;
            file = this.base64ToFileService.base64toFile(
              imgResultAfterCompression,
              'signature.jpg'
            );
            frmData.append('files', file);
            this.fileEvent.inProgress = true;
            this.uploadFile(frmData, fieldType, i);
          });
      }
    }
  }


  uploadFilee(frmData: any) {
    console.log("jhkj",frmData);
    const headers = new HttpHeaders();
    this.common.loading = true;
    this.api
      .post('functional/upload-file?methodId=' + 17, frmData)
      .subscribe((res: any) => {
        this.common.loading = false;
        switch (res.data.type) {
          case HttpEventType.UploadProgress:
            this.fileEvent.progress = Math.round(
              (res.data.loaded * 100) / res.data.total
            );
            break;
          case HttpEventType.Response:
            return res.data;
        }
        res.data.forEach((e) => {
            this.asset.assetImage=e.fileUrl;
            console.log(this.asset.assetImage);
        });
      });
    catchError((error: HttpErrorResponse) => {
      this.fileEvent.inProgress = false;
      this.common.loading = false;
      return `${this.fileEvent.data.name} upload failed.`;
    });
    // this.asset.assetImage="assets/images/good-afternoon.png";
    this.myInputVariable.nativeElement.value = '';
  }


  uploadFile(frmData: any, fieldType: string, i: number) {
    const headers = new HttpHeaders();
    this.api
      .post('functional/upload-file?methodId=' + 17, frmData)
      .subscribe((res: any) => {
        switch (res.data.type) {
          case HttpEventType.UploadProgress:
            this.fileEvent.progress = Math.round(
              (res.data.loaded * 100) / res.data.total
            );
            break;
          case HttpEventType.Response:
            return res.data;
        }
        res.data.forEach((e) => {
          this.customFieldValues[i].values.push(e.fileUrl);
        });
      });
    catchError((error: HttpErrorResponse) => {
      this.fileEvent.inProgress = false;
      this.common.loading = false;
      return `${this.fileEvent.data.name} upload failed.`;
    });
  }

  selectedManufacturer(event: any) {
    this.asset.manufacturerId = event.manufacturer_id;
    this.ManufacturerName = event.manufacturer_name;
  }

  selectedSupplier(event: any) {
    this.asset.supplierId = event.supplier_id;
    this.SupplierName = event.supplier_first_name;
  }
  selectedParentAsset(event: any) {
    this.asset.parentAssetId = event.asset_id;
    this.ParentAssetName = event.asset_name;
  }

  getAsset() {
    this.common.loading = true;
    let params = {
      allAssets: true,
      companyId: this.asset.companyId,
      plantId: this.asset.plantId
    }
    this.assetsService.getAsset(params).subscribe((res: any) => {
      if (res.status) {
        this.common.loading = false;
        this.responseData = res.data;
        this.renderTable();
      }
    }, (err: any) => {
      console.error('Error: ', err);
      this.common.loading = false;
    });
  }


  getCompany(){
    this.common.loading=true;
    let params={
      all_company:false
    }
    this.api.get('company/all_company',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.company= res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
      );
    }


  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getAssetCategory() {
    this.common.loading = true;
    let params = {
      allCategories: false,
      companyId: this.asset.companyId,
      plantId: this.asset.plantId,
    }
    this.categoryService.getAssetCategory(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.categoryData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getManufacturer() {
    this.common.loading = true;
    let params = {
      allManufacturers: false,
      plantId: this.asset.plantId,
      companyId: this.asset.companyId
    }
    this.api.get("manufacturer/get-manufacturer", params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.manufacturerData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getSupplier() {
    this.common.loading = true;
    let params = {
      allSuppliers: false,
      plantId: this.asset.plantId,
      companyId: this.asset.companyId
    }
    this.api.get("suppliers/get-suppliers", params).subscribe(

      (res: any) => {
        this.common.loading = false;
        this.supplierData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  onSelectimage(
    e,
    methodId,
  ) {
    let frmData = new FormData();
    let imgResultAfterCompression: DataUrl;
      for (const file of e.target.files) {
        frmData.append('files', file);
        this.fileEvent.inProgress = true;
        this.uploadFilee(frmData);
      }
  }

  viewAsset(row?: any) {
      const secretKey = btoa(row.asset_id);
      this.router.navigate(['user/asset-view'], {
        queryParams: { secretKey },
      });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row.asset_name +
        '  ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteAsset(row);
      }
    });
  }

  deleteAsset(row) {
    this.asset.assetId = row.asset_id;
    this.asset.assetName = row.asset_name;
    this.asset.assetStatus = 'DELETED';
    this.asset.companyId = row.company_id;
    this.asset.assetImage = row.asset_image;
    this.asset.plantId = row.plant_id;
    this.asset.assetCategoryId = row.asset_category_id;
    this.asset.manufacturerId = row.manufacture_id;
    this.asset.supplierId = row.supplier_id;
    this.asset.assetCost = row.asset_cost;
    this.asset.assetDesc = row.asset_description;
    this.asset.uniqueTagCode = row.tag_id;
    this.asset.uniqueTagType = row.tag_type;
    this.asset.parentAssetId = row.parent_asset_id;
    this.asset.expiryDate = row.date_of_expiry;
    this.asset.warrantyExpireDate = row.date_of_waranty_expire;
    this.ManufacturerName = row.manufacturer_name;
    this.SupplierName = row.supplier_name;
    this.CategoryName = row.asset_category_name;
    this.ParentAssetName = row.asset_parent;
    this.asset.deleteFlag = true;
    this.asset.assetCode = row.asset_code;
    this.saveAsset();
  }

  async editAsset(row: any) {
    this.customFieldValues = [];
    this.asset.assetCategoryId = row.asset_category_id;
    await this.getCategoryWiseCustomFields(row.asset_category_id).then(() => {
      if (this.customFields.length && JSON.parse(row.fields_data).length) {
        this.customFields.forEach((el, index) => {
          this.customFieldValues[index].name = el.field_name;
          this.customFieldValues[index].field_id = el.master_field_id;
          this.customFieldValues[index].fieldType = el.field_type;
          this.customFieldValues[index]._dbField = el.database_field_name;
        })

        //for multi select fields like checkbox or selectlistmultiple ,there comes two entries from the db ,so to push them to their existing customFieldValues which contains their fieldId.
        JSON.parse(row.fields_data).forEach(fieldData => {
          const customFieldIndex = this.customFieldValues.findIndex(field => field.field_id === fieldData.field_id);
          if (customFieldIndex !== -1) {
            // Check if the value is not empty
            if (fieldData.value !=null && fieldData.value.trim() !== "") {
              // If the value is not empty, push it to the values array
              this.customFieldValues[customFieldIndex].values.push(fieldData.value);
            }
          }
        });
      }
    });
    this.asset.assetCategoryId = row.asset_category_id;
    this.asset.manufacturerId = row.manufacture_id;
    this.asset.supplierId = row.supplier_id;
    this.asset.assetCost = row.asset_cost;
    this.asset.assetDesc = row.asset_description;
    this.asset.uniqueTagCode = row.tag_id;
    this.asset.uniqueTagType = row.tag_type;
    this.asset.parentAssetId = row.parent_asset_id;
    this.asset.expiryDate = row.date_of_expiry;
    this.asset.warrantyExpireDate = row.date_of_waranty_expire;
    this.asset.assetId = row.asset_id;
    this.asset.assetName = row.asset_name;
    this.asset.assetStatus = row.status;
    this.asset.companyId = row.company_id;
    this.asset.plantId = row.plant_id;
    this.asset.assetCode = row.asset_code;
    this.asset.assetImage = row.asset_image;
    this.editParent = row.flag;
    if (!(row.company_id == 1 && row.plant_id == 1)) {
      this.plantName = row.plant_name;
      this.companyName = row.company_name;
    }
    else {
      this.plantName = null;
      this.companyName = null;
    }
    this.ManufacturerName = row.manufacturer_name;
    this.SupplierName = row.supplier_name;
    this.CategoryName = row.asset_category_name;
    this.ParentAssetName = row.asset_parent;
    this.newParentData = this.parentAssetData.filter(option => option.asset_id !== row.asset_id);
    this.role.user_role == 'AXESTRACK' ? this.getPlantById(row.company_id) : (this.role.user_role == "COMPANYADMIN" ? this.getPlantById(this.userService._loggedInUser.companyId) : null);
    this.asset.deleteFlag = false;
  }

  checkEdit(company_id: any, plant_id: any) {
    if (this.role.user_role == "AXESTRACK") {
      return true;
    }
    else if (this.role.user_role == "AXESTRACKUSER" || this.role.user_role == "COMPANYADMIN") {
      return (company_id == 1) ? false : true;
    } else {
      return (company_id == 1 || !plant_id) ? false : true;
    }
  }

  getAssetCode() {
    let params = {
      assetName: this.asset.assetName,
      companyId: this.asset.companyId,
      plantId: this.asset.plantId
    }
    this.api.post("assets/get-asset-code", params).subscribe(
      (res: any) => {
        this.asset.assetCode = res.data[0];
      },
      (err: any) => {
        console.error('Error', err);
      }
    );

  }

  formValidator() {
    this.assetNameValidator = this.common.nullValidator(
      this.asset.assetName!=null && this.asset.assetName.trim(),
      'Asset  Name Mandatory'
    );
    this.assetCategoryValidator = this.common.nullValidator(
      this.asset.assetCategoryId,
      'Asset Category Mandatory'
    );
    this.manufacturerValidator = this.common.nullValidator(
      this.asset.manufacturerId,
      'Asset Manufacturer Mandatory'
    );
    this.supplierValidator = this.common.nullValidator(
      this.asset.supplierId,
      'Asset Supplier Mandatory'
    );
    this.assetCostValidator = this.common.nullValidator(
      this.asset.assetCost,
      'Asset Cost Mandatory'
    );
    if (!this.assetNameValidator.error && !this.assetCategoryValidator.error && !this.manufacturerValidator.error && !this.supplierValidator.error && !this.assetCostValidator.error) {
      this.saveAsset();
    }
  }

  imageName(url: any) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    return arrays[arrays.length - 1];
  }

  private saveAsset() {
    if (this.asset.deleteFlag == false) {
      let i = this.customFieldValues.findIndex(
        (e) => (e.name == '' || e.values.length == 0 || e.values[0] == '') && e.fieldType != 'LABEL' && e.mandatory == true);
      if (i != -1) {
        return this.alertService.error('Mandatory fields need to be filled...');
      } else {
        this.customFieldValues = this.customFieldValues.filter(
          (item) => item.mandatory !== false || item.name !== ''
        );

      }
    }
    this.asset["customFields"] = this.customFieldValues;
    this.common.loading = true;
    this.assetsService.saveAsset(this.asset).subscribe((response) => {
      this.common.loading = false;
      if (response.status) {
        this.alertService.success(response.message);
        this.resetAsset();
        this.getAsset();
      } else {
        this.alertService.error(response.message);
        this.notUniqueFieldId = response.data[0];
        console.log('this.notUniqueFieldId : ', this.notUniqueFieldId );
      }
    }, (error) => {
      this.common.loading = false;
    });

  }
}


<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">

          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Workflow Action Management" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div *ngIf="process.length">
                <mat-tab-group animationDuration="10ms" (selectedTabChange)="getEvent($event)" panelClass="custom">
                  <mat-tab [label]="item.name" [label]="item.name" *ngFor="let item of process">
                    <table class="custom-table automation-table action-table mt-2">
                      <thead>
                        <tr>
                          <th scope="col">{{"order" | translate}}</th>
                          <th scope="col"> {{"pre_action" | translate}} </th>
                          <th scope="col"> {{("current_stage" | translate)+" "+("action" | translate)}} </th>
                          <th scope="col"> {{"post_action" | translate}} </th>
                        </tr>
                      </thead>
                      <tbody class="example-list">
                        <tr *ngFor="let item of actions; let i = index">
                          <th class="col-xs no-bg">
                            <div class="drag-handle">
                              {{ i+1 }}
                            </div>
                            <!--condition-->
                          <td>
                            <div class="multiselect">
                              <ng-select [items]="actionsList" bindLabel="action_name" [addTag]="true" [multiple]="true" [disabled]="item.quickOut"
                                dropdownPosition="bottom" [hideSelected]="true"
                                (remove)="onActionRemove($event,-1, item.order_id)" highlightColor="red"
                                (add)="onActionAdd($event,-1, item.order_id)" bindValue="action_id"
                                [placeholder]="item.quickOut==false ? ('pre_action' | translate) :(('pre_action' | translate)+' (Disabled)')" appendTo="body"
                                [(ngModel)]="item.pre_actions">
                              </ng-select>
                            </div>
                          </td>

                          <td class="col-md stage">
                            <mat-form-field class="example-full-width">
                              <input matInput placeholder='{{("current_stage" | translate)}}' readonly
                                value="{{item.name}}">
                            <span *ngIf="item.quickOut==true" class="quickOutNote">{{"quickOutNote"|translate}}</span>
                            </mat-form-field>

                            <ng-select [hideSelected]="true" [markFirst]="true" [addTag]="true" [items]="actionsList" [disabled]="item.quickOut"
                              bindValue="action_id" bindLabel="action_name"
                              [placeholder]="item.quickOut==false ? ('current_action' | translate) :(('current_action' | translate)+' (Disabled)')"
                              (add)="onActionAdd($event,0, item.order_id)"
                              (remove)="onActionRemove($event,0, item.order_id)" dropdownPosition="bottom"
                              appendTo="body" [multiple]="true" cdkDropList (cdkDropListDropped)="onDrop($event)"
                              [(ngModel)]="item.current_actions">
                            </ng-select>
                          </td>
                          <!-- function-->
                          <td>
                            <div class="multiselect">
                              <ng-select [items]="actionsList" bindLabel="action_name" bindValue="action_id" [disabled]="item.quickOut"
                                [multiple]="true" [addTag]="true" dropdownPosition="bottom" [hideSelected]="true"
                                [placeholder]="item.quickOut==false ? ('post_action' | translate) :(('post_action' | translate)+' (Disabled)')" appendTo="body"
                                (add)="onActionAdd($event,1, item.order_id)"
                                (remove)="onActionRemove($event,1, item.order_id)" [(ngModel)]="item.post_actions">
                              </ng-select>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </mat-tab>
                </mat-tab-group>
              </div>
              <app-data-not-found *ngIf="!process.length"></app-data-not-found>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class StageDestinationCodeOccupancyService extends ApiService{

  saveStageDestinationCodeOccupancy(data: any): Observable<any> {
    let apiSubUrl = "destination/save_stage_destination_code_occupancy";
    return super.post(apiSubUrl, data);
  }

  getAllOccupancy(): Observable<any>{
    let apiSubUrl = "destination/list_all_occupancy";
    return super.get(apiSubUrl);
  }


}

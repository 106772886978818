<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12" [ngStyle]="{'padding-left.px': isModalOpen ? 0 : null, 'padding-right.px': isModalOpen ? 0 : null, 'margin-bottom.px': isModalOpen ? -4 : null}" >
          <div class="card" >
            <div [ngClass]="{'modal-header': isModalOpen, 'card-header': !isModalOpen}">
              <div class="header-block">
                <button type="button" class="close" aria-label="Close" (click)="closeModal()" *ngIf="isModalOpen">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 [ngClass]="{'modal-title': isModalOpen, 'card-title': !isModalOpen}">
                  {{ "add_insurance" | translate }}
                </h3>
              </div>
            </div>

            <div [ngClass]="{'modal-body': isModalOpen, 'card-body': !isModalOpen}" >
              <div class="row" style="display: flex; flex-direction: column; align-items: flex-start; margin-top: 4px;">
                <div class="form-group col-12 mb-0" *ngIf="this.isModalOpen">
                  <div class="combine">
                    <label for="my-input" class="required" [ngStyle]="{'pointer-events': showFields ? 'none' : 'auto', 'opacity': showFields ? '0.5' : '1'}"> {{"insurance" | translate}} </label>
                    <button type="button" class="pill1 bg-green-200 text-green-800" (click)="onToggle()" *ngIf="this.isModalOpen">
                      {{ showFields ? "add_existing_insurance" : "add_new_insurance" | translate }}
                    </button>
                  </div>
                  <ps-auto-suggestion (onSelected)="onSelectInsurance($event)" [data]="insuranceList" display="insurance_company_name"
                                      placeholder="Choose Insurance" [preSelected]="{insurance_company_name:insuranceCompanyName1}" [ngStyle]="{'pointer-events': showFields ? 'none' : 'auto', 'opacity': showFields ? '0.5' : '1'}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="insuranceIdValidator && insuranceIdValidator.error" [ngStyle]="{'pointer-events': showFields ? 'none' : 'auto', 'opacity': showFields ? '0.5' : '1'}">
                    <span>{{insuranceIdValidator.msg}} </span>
                  </div>
                </div>
              </div>



              <div class="row" [hidden]="!showFields && isModalOpen">
                <div class="form-group"
       [ngClass]="{'col-xl-3 col-lg-6 col-sm-12': !isModalOpen, 'col-xl-12 col-lg-12 col-sm-12': isModalOpen}">
                  <label for="my-input" class="required">{{ "insurance_company_name" | translate }}</label>
                  <input class="form-control" type="text" name="InsuranceCompanyName" id="InsuranceCompanyName"
                   [(ngModel)]="insurance.insuranceCompanyName" #name="ngModel" placeholder="Enter Insurance Company Name">
                  <div class="error" *ngIf="companyNameValidator && companyNameValidator.error">
                    <span>{{ companyNameValidator.msg }} </span>
                  </div>
                </div>
                <div class="form-group"
       [ngClass]="{'col-xl-3 col-lg-6 col-sm-12': !isModalOpen, 'col-xl-12 col-lg-12 col-sm-12': isModalOpen}">
                  <label for="my-input" class="required">{{ "policy_no" | translate }}</label>
                  <input class="form-control" type="text" name="PolicyNo" id="Policy No"
                   [(ngModel)]="insurance.policyNo" #name="ngModel" placeholder="Enter Policy Number">
                  <div class="error" *ngIf="policyNoValidator && policyNoValidator.error">
                    <span>{{ policyNoValidator.msg }} </span>
                  </div>
                </div>
                <div class="form-group"
       [ngClass]="{'col-xl-3 col-lg-6 col-sm-12': !isModalOpen, 'col-xl-12 col-lg-12 col-sm-12': isModalOpen}">
                  <label for="my-input" class="required">{{ "contact_person_name" | translate }}</label>
                  <input class="form-control" type="text" name="ContactPersonName" id="ContactPersonName"
                   [(ngModel)]="insurance.contactPersonName" #name="ngModel" placeholder="Enter Contact Person Name">
                  <div class="error" *ngIf="contactPersonValidator && contactPersonValidator.error">
                    <span>{{ contactPersonValidator.msg }} </span>
                  </div>
                </div>
                <div class="form-group"
       [ngClass]="{'col-xl-3 col-lg-6 col-sm-12': !isModalOpen, 'col-xl-12 col-lg-12 col-sm-12': isModalOpen}">
                  <label for="my-input" class="required">{{ "contact_person_no" | translate }}</label>
                  <input class="form-control" type="text" name="ContactPersonNo" id="ContactPersonNo"
                   [(ngModel)]="insurance.contactPersonNo" #name="ngModel" placeholder="Enter Contact Person  No" maxlength="10">
                  <div class="error" *ngIf="contactPersonNoValidator && contactPersonNoValidator.error">
                    <span>{{ contactPersonNoValidator.msg }} </span>
                  </div>
                </div>
                <div class="form-group"
       [ngClass]="{'col-xl-3 col-lg-6 col-sm-12': !isModalOpen, 'col-xl-12 col-lg-12 col-sm-12': isModalOpen}">
                  <label for="my-input" class="required">{{ "start_date" | translate }}</label>
                  <input class="form-control" type="date" name="StartDate" id="StartDate"
                   [(ngModel)]="insurance.startDate" #name="ngModel" placeholder="Enter Start Date " [min]="minStartTime">
                  <div class="error" *ngIf="startDateValidator && startDateValidator.error">
                    <span>{{ startDateValidator.msg }} </span>
                  </div>
                </div>
                <div class="form-group"
       [ngClass]="{'col-xl-3 col-lg-6 col-sm-12': !isModalOpen, 'col-xl-12 col-lg-12 col-sm-12': isModalOpen}">
                  <label for="my-input" class="required">{{ "end_date" | translate }}</label>
                  <input class="form-control" type="date" name="EndDate" id="EndDate"
                   [(ngModel)]="insurance.endDate" #name="ngModel" placeholder="Enter End Date " [min]="insurance.startDate">
                  <div class="error" *ngIf="endDateValidator && endDateValidator.error">
                    <span>{{ endDateValidator.msg }} </span>
                  </div>
                </div>


                <div class="form-group"
       [ngClass]="{'col-xl-3 col-lg-6 col-sm-12': !isModalOpen, 'col-xl-12 col-lg-12 col-sm-12': isModalOpen}">
                  <label for="my-input" class="required">{{ "deductable_amount" | translate }}</label>
                  <input class="form-control" type="number" step="0.01" name="deductableAmount" id="deductableAmount"
                   [(ngModel)]="insurance.deductableAmount" #name="ngModel" placeholder="Enter Deductable Amount" min="0">
                  <div class="error" *ngIf="deductableAmountValidator && deductableAmountValidator.error">
                    <span>{{ deductableAmountValidator.msg }} </span>
                  </div>
                </div>

                <div class="form-group"
       [ngClass]="{'col-xl-3 col-lg-6 col-sm-12': !isModalOpen, 'col-xl-12 col-lg-12 col-sm-12': isModalOpen}">
                  <label for="my-input" class="required">{{ "premium_amount" | translate }}</label>
                  <input class="form-control" type="number" step="0.01" name="PremiumAmount" id="PremiumAmount"
                   [(ngModel)]="insurance.premiumAmount" #name="ngModel" placeholder="Enter Premium Amount" min="0">
                  <div class="error" *ngIf="primumAmountValidator && primumAmountValidator.error">
                    <span>{{ primumAmountValidator.msg }} </span>
                  </div>
                </div>

                <div class="form-group"
       [ngClass]="{'col-xl-3 col-lg-6 col-sm-12': !isModalOpen, 'col-xl-12 col-lg-12 col-sm-12': isModalOpen}">
                  <label for="my-input" class="required">{{"status" | translate}}</label>
                  <select name="status" id="#status" class="form-control"
                    [(ngModel)]="insurance.status">
                    <option selected="ACTIVE" value="ACTIVE">{{'active' | translate}}</option>
                    <option value="INACTIVE">{{"inactive" | translate}}</option>
                    <option value="DELETED">{{"deleted" | translate}}</option>
                  </select>
                </div>

                <div class="form-group"
       [ngClass]="{'col-xl-3 col-lg-6 col-sm-12': !isModalOpen, 'col-xl-12 col-lg-12 col-sm-12': isModalOpen}">
                  <label for="my-input" >{{ "limit " | translate }}</label>
                  <textarea rows="2" cols="30"  class="form-control" type="text" name="Limit" id="Limit"
                   [(ngModel)]="insurance.limit" #name="ngModel" placeholder="Enter Limit"></textarea>

                </div>
                <div class="form-group" [ngClass]="{'col-xl-3 col-lg-6 col-sm-12': !isModalOpen, 'col-xl-12 col-lg-12 col-sm-12': isModalOpen}">
                  <label for="my-input" >{{ "coverage" | translate }}</label>
                  <textarea rows="2" cols="30" class="form-control" type="text" name="Coverage" id="Coverage"
                   [(ngModel)]="insurance.coverage" #name="ngModel" placeholder="Enter Coverage"></textarea>
                </div>

              </div>
              <div class="modal-footer" style="padding:5px;margin-right:-8px;border-top: none;" *ngIf="isModalOpen">
                <button type="button" class="btn btn-secondary" (click)="closeModal()">{{"close" | translate}}</button>
                <button type="button" class="btn btn-warning" style="background-color: #337ab7;" (click)="reset()">{{"reset" | translate}}</button>
                <button type="button" class="btn btn-primary" (click)="formValidator()">{{"save_changes" | translate}}</button>
              </div>
              <div class="form-group action-block" *ngIf="!isModalOpen">
                <div class="btn-block" >
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()" >{{"back" |
                    translate}}</button>
                  <button class="btn btn-warning" (click)="reset()" > {{ "reset" | translate }} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()" >
                    {{ buttonName | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card" *ngIf="!this.isModalOpen">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{ "insurance_list" | translate }}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{ "s_no" | translate }}</th>
                    <th>{{ "insurance_company" | translate }}</th>
                    <th>{{ "policy_no" | translate }}</th>
                    <th>{{ "contact_person" | translate }}</th>
                    <th>{{ "contact_person_no"| translate }}</th>
                    <th>{{ "start_date"| translate }}</th>
                    <th>{{ "end_date" | translate }}</th>
                    <th>{{ "premium_amount" | translate }}</th>
                    <th>{{ "status" | translate }}</th>
                    <th class="action">{{ "action" | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of insuranceList; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ row.insurance_company_name }}</td>
                    <td>{{ row.policy_no }}</td>
                    <td>{{ row.contact_person_name }}</td>
                    <td>{{ row.contact_person_no }}</td>
                    <td>{{ row.start_date }}</td>
                    <td>{{ row.end_date }}</td>
                    <td>{{ row.premium_amount }}</td>
                    <td><span class="pill"
                      [ngClass]="{'bg-green-200 text-green-800':row.status=='ACTIVE','bg-yellow-200 text-yellow-800':row.status=='INACTIVE'}">
                      {{ row.status }}
                       </span></td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewInsuranceDetails(row)">
                          {{ "view" | translate }}
                        </button>
                        <button  class="btn btn-success"
                       (click)="editManufacturer(row)">{{ "edit" | translate }}
                        </button>
                        <button class="btn btn-danger"
                        (click)="confirmDeleteManufacturer(row)"> {{ "delete" | translate }}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




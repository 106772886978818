<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"Restriction Scope" | customTranslate}}</label>
                  <select name="status_scope" id="#status_scope" class="form-control" [(ngModel)]="restrictionScope"
                    (change)="selectedRestriction($event)">
                    <option value="COMPANY" *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">
                      COMPANY</option>
                    <option value="PLANT">PLANT</option>
                    <option value="STAGE">STAGE</option>
                    <option value="DESTINATION">DESTINATION</option>
                  </select>
                </div>

                <div *ngIf="role.user_role == 'AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"company" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyList"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <!-- <div class="error" *ngIf="companyValidator && companyValidator.error">
                    <span>{{companyValidator.msg}} </span> -->
                </div>

                <div
                  *ngIf="restrictionScope != 'COMPANY' && role.user_role != 'PLANTADMIN' && role.user_role != 'PLANTUSER'"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantList"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <!-- <div class="error" *ngIf="plantValidator && plantValidator.error">
                    <span>{{plantValidator.msg}} </span> -->
                </div>
                <div *ngIf="restrictionScope == 'STAGE' || restrictionScope == 'DESTINATION'"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"stage" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedStage($event)" class="required" [data]="stageList"
                    display="stageName" placeholder="Choose Stage" [preSelected]="stageData" [isMultiSelect]="true"
                    *ngIf="restrictionScope == 'STAGE'">
                  </ps-auto-suggestion>
                  <ps-auto-suggestion (onSelected)="selectedStage($event)" class="required" [data]="stageList"
                    display="stageName" placeholder="Choose Stage" [preSelected]="{stageName:stageName}"
                    *ngIf="restrictionScope == 'DESTINATION'">
                  </ps-auto-suggestion>
                  <!-- <div class="error" *ngIf="stage && plantValidator.error">
                    <span>{{plantValidator.msg}} </span> -->
                </div>
                <div *ngIf="restrictionScope == 'DESTINATION'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"destination" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedDestination($event)" class="required"
                    [data]="destinationList" display="stage_destination_code" placeholder="Choose Destination"
                    isMultiSelect="true" [preSelected]="destinationData">
                  </ps-auto-suggestion>
                  <!-- <div class="error" *ngIf="stage && plantValidator.error">
                    <span>{{plantValidator.msg}} </span> -->
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"Whitelisted Vehicles" | customTranslate}} </label>
                  <!-- <div class="row">

                    <div class="col-md-7">
                      <ps-auto-suggestion (onSelected)="selectVehicle($event,'select','WHITELISTED')" [data]="vehicleList"
                        display="vehicle_rsn" placeholder="Choose Vehicle" (onChange)="selectVehicle($event,'input','WHITELISTED')"
                        [preSelected]="{vehicle_rsn: whiteListedvehicleNo}">
                      </ps-auto-suggestion>
                    </div>
                    <div class="col-md-3 plus_minus_icon">
                      <span class="input-group-text add-field" (click)="addVehicle('WHITELISTED')">
                        <i class="fa fa-plus add"></i>
                      </span>
                    </div>
                  </div> -->
                  <div *ngFor="let item of whiteListVehicle; let i=index; let last = last" class="mb-2">
                    <div class="row">
                      <div class="col-md-7">
                        <ps-auto-suggestion (onSelected)="updateVehicle(i,'WHITELISTED',$event)" [data]="vehicleList"
                          (onChange)="updateVehicleOnInput(i,'WHITELISTED',$event)" display="vehicle_rsn"
                          placeholder="Choose Vehicle" [preSelected]="{vehicle_rsn: item.vehicleRsn}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 plus_minus_icon">
                        <span class="input-group-text add-field" (click)="addVehicle('WHITELISTED')" *ngIf="last">
                          <i class="fa fa-plus add"></i>
                        </span>
                        <span class="input-group-text remove-field" id="my-addon" *ngIf="!last"
                          (click)="removeVehicle(i,'WHITELISTED')">
                          <i class="fa fa-trash remove"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"Blacklisted Vehicles" | customTranslate}} </label>
                  <!-- <div class="row">

                    <div class="col-md-7">
                      <ps-auto-suggestion (onSelected)="selectVehicle($event,'select','BLACKLISTED')" [data]="vehicleList"
                        display="vehicle_rsn" placeholder="Choose Vehicle" (onChange)="selectVehicle($event,'input','BLACKLISTED')"
                        [preSelected]="{vehicle_rsn: blackListedvehicleNo}">
                      </ps-auto-suggestion>
                    </div>
                    <div class="col-md-3 plus_minus_icon">
                      <span class="input-group-text add-field" (click)="addVehicle('BLACKLISTED')">
                        <i class="fa fa-plus add"></i>
                      </span>
                    </div>
                  </div> -->
                  <div *ngFor="let item of blackListVehicle; let i=index; let last = last" class="mb-2">
                    <div class="row">
                      <div class="col-md-7">
                        <ps-auto-suggestion (onSelected)="updateVehicle(i,'BLACKLISTED',$event)" [data]="vehicleList"
                          (onChange)="updateVehicleOnInput(i,'BLACKLISTED',$event)" display="vehicle_rsn"
                          placeholder="Choose Vehicle" [preSelected]="{vehicle_rsn: item.vehicleRsn}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 plus_minus_icon">
                          <span class="input-group-text add-field" (click)="addVehicle('BLACKLISTED')" *ngIf="last">
                            <i class="fa fa-plus add"></i>
                          </span>
                        <span class="input-group-text remove-field" id="my-addon"
                          (click)="removeVehicle(i,'BLACKLISTED')" *ngIf="!last">
                          <i class="fa fa-trash remove"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    customTranslate}}</button>
                  <button class="btn btn-warning" (click)="resetDetail()">{{"reset" | customTranslate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="saveValidator()"> {{btn}} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Vehicle Restriction List" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <!-- table -->
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">

                <thead>
                  <tr>
                    <th>{{"s.no." | customTranslate}}</th>
                    <th>{{"Restriction Scope" | customTranslate}}</th>
                    <th>{{"Company Name" | customTranslate}}</th>
                    <th>{{"Plant Name" | customTranslate}}</th>
                    <th>{{"Stage Name" | customTranslate}}</th>
                    <th>{{"Destination Name" | customTranslate}}</th>
                    <th>{{"White List Vehicles" | customTranslate}}</th>
                    <th>{{"Black List Vehicles" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of restrictionVehicleTableData; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{row.restriction_scope}}</td>
                    <td>{{row.company_name}}</td>
                    <td>{{row.plant_name}}</td>
                    <td>{{row.stage_name}}</td>
                    <td>{{row.stage_destination_code}}</td>
                    <td><ng-container *ngFor="let vehicle of row.whitelist_vehicle_data"><span
                          class="badge badge-info m-1">{{vehicle.vehicleRsn}}</span></ng-container></td>
                    <td><ng-container *ngFor="let vehicle of row.blacklist_vehicle_data"><span
                          class="badge badge-info m-1">{{vehicle.vehicleRsn}}</span></ng-container></td>
                    <td class="action">
                      <div class="btn-block">
                        <!-- <button class="btn btn-primary" (click)="viewDetails(row)">
                          {{ "view" | customTranslate }}
                        </button> -->
                        <button class="btn btn-success" (click)="editVehicleRestriction(row)"> {{"edit" | customTranslate}}
                        </button>
                        <button class="btn btn-danger" (click)="confirmAlert(row)">{{"delete"| customTranslate}}</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

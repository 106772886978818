import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @Input() formType: string;
  mappedFields: any[] = [];
  formDetails = {
    formId: null,
    formTitle: null,
    formDesc: null,
    formValues: [
      {
        name: '',
        values: [],
        fieldType: '',
        _dbField: '',
        mandatory: false,
        group_id: null,
        sub_group_index: null,
        sub_group_name: '',
        field_id: null,
      },
    ],
  };
  multivalueIndex: number = 0;
  fileEvent: any = [];

  constructor(
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public alert: AlertService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    console.log('new2', this.formType);
    // if(this.formId!=null){
    //   this.getMappedFields(this.formId);
    // }
    // else{
    //   this.alert.error("Form doesn't exist");
    // }
    this.getMappedFields();
  }

  addValue(field: any, index: number, multivalueIndex: number) {
    console.log('multiValueIndex: ', this, multivalueIndex);
    console.log('this.mappedFields[index]: ', this.mappedFields[index], index);
    console.log(this.formDetails.formValues , "fcghjk");
    this.multivalueIndex = multivalueIndex;
    if (Array.isArray(this.mappedFields[index])) {
      this.mappedFields[index].push(field); // Add new field to the array
    } else {
      this.mappedFields[index] = [field]; // Convert to array if it's the first time adding
    }

    // Add to formDetails with default values
    this.formDetails.formValues.push({
      ...field.extra_data,
      name: '',
      values: [],
      fieldType: '',
      _dbField: '',
      mandatory: false,
      field_id: null,
    });
  }

  removeValue(index: number, subIndex: number) {
    if (this.mappedFields[index].length > 1) {
      this.mappedFields[index].splice(subIndex, 1);
      this.formDetails.formValues.splice(subIndex, 1);
    }
  }

  getMappedFields() {
    this.common.loading = true;
    let params = {
      formType: this.formType,
    };
    this.api.get('customForm/get-form-fields', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.formDetails.formId = res.data[0].form_id;
        this.formDetails.formTitle = res.data[0].form_title;
        this.formDetails.formDesc = res.data[0].form_description;
        //   this.mappedFields.push({
        //     field_id: 1445,
        //     field_name: "barcode",
        //     field_type: "BARCODEWITHIMAGE",
        //     field_ranking_index: 0,
        //     is_multivalue : false,
        //     label: "name",
        //     store_value: "name",
        //     options: [],
        //     database_field_name: "visitor_image",
        //     mandatory: null,
        //     unique: null,
        //     values : [{
        //       lat	:	26.8715559,
        //       lng	:	75.7758848,
        //       time	:	2023-12-22,
        //       value	:	9771234567003,
        //       fileUrl	:	'https://elogist-prime.s3.ap-south-1.amazonaws.com/vms/1703159405724CAP8552933314871369938_compressed.jpg'
        //     }],
        //     regex: null,
        //     pattern_prefix: null,
        //     pattern_postfix: null,
        //     database_linked_id: null,
        //     sub_group_name: null,
        // })
        // this.mappedFields.forEach(field => {
        //   if(field.sel_value != null){
        //     if(field.field_type != 'FIELDGROUP'){
        //       if (!field.values) {
        //         field.values = [];
        //       }
        //       field.values = field.values.push(field.sel_value);
        //     }else{
        //       field.group_fields.forEach(element => {
        //         if (!element.values) {
        //           element.values = [];
        //         }
        //         element.values = element.values.push(element.sel_value)
        //       });
        //     }
        //   }
        // });
        this.mappedFields = res.data[0].fields
          ? res.data[0].fields.map((field) => {
            if (field.is_multivalue) {
              return [field];
            }
              return field;
            })
          : [];
        if (this.formType == 'Visitor Form' && this.userService._walkinMode) {
          const startTimeField = this.mappedFields.find((e) => e.field_name === 'Start Time');
          if (startTimeField) {
            startTimeField.sel_value = new Date().toISOString().slice(0, 19).replace('T', ' ');
          }
        }

        console.log("this.mappedFields",this.mappedFields);
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  onEdit(field: any) {
    field.isEditable = false;
    field.isDisabled = false;
    if (
      field != null &&
      (field.field_type == 'RADIOBUTTON' ||
        field.field_type == 'CHECKBOX' ||
        field.field_type == 'SELECTLISTSINGLE' ||
        field.field_type == 'SELECTLISTMULTIPLE')
    ) {
      this.getFieldInfoByFieldId(field);
    }
  }

  onValueChange(index, grpFieldIndex, multiValueIndex, grpFieldsLength, event) {
    this.formDetails.formValues[
      index + grpFieldIndex + multiValueIndex * grpFieldsLength
    ] = event;
    console.log('saveArr', this.formDetails.formValues);
  }

  // saveFormResponse() {
  //   console.log("saving...........",this.formDetails.formValues);
  //   // this.formDetails.formValues = this.formDetails.formValues.filter(e => {
  //   //   e.values[0] != ''
  //   // })
  //   const missingMandatoryFields = this.mappedFields.some((field) => {
  //     let modifiedField = this.isArray(field) ? field[0] : field;
  //     if (modifiedField.mandatory) {
  //       let formValue: any;
  //       let responses: any[] = [];
  //       if (modifiedField.field_type == 'FIELDGROUP') {
  //        const grpFieldRes =  modifiedField.group_fields.some((grpField) => {
  //           console.log('grpField: ', grpField);
  //           if (modifiedField.is_multivalue) {
  //             responses = this.formDetails.formValues.filter(
  //               (gfv) => gfv?.field_id === grpField.field_id
  //             );
  //             console.log('this', this.formDetails.formValues, responses.length , (this.multivalueIndex+1));
  //             if (responses.length != (this.multivalueIndex + 1)) {
  //               return true;
  //             } else {
  //               responses.some((resp) => {
  //                 return resp.values == null || resp.values.length === 0;
  //               });
  //             }
  //           } else {
  //             formValue = this.formDetails.formValues.find(
  //               (gfv) => gfv?.field_id === grpField.field_id
  //             );
  //             console.log('formValue: ', formValue);
  //             return (
  //               !formValue ||
  //               formValue.values == null ||
  //               formValue.values.length === 0
  //             );
  //           }
  //         });
  //         if(grpFieldRes){
  //           return true
  //         }
  //       } else {
  //         if (modifiedField.is_multivalue) {
  //           responses = this.formDetails.formValues.filter(
  //             (fv) => fv?.field_id === modifiedField.field_id
  //           );
  //           if (responses.length != (this.multivalueIndex + 1)) {
  //             return true;
  //           } else {
  //             responses.some((resp) => {
  //               return resp.values == null || resp.values.length === 0;
  //             });
  //           }
  //         } else {
  //           formValue = this.formDetails.formValues.find(
  //             (fv) => fv?.field_id === modifiedField.field_id
  //           );
  //           return (
  //             !formValue ||
  //             formValue.values == null ||
  //             formValue.values.length === 0
  //           );
  //         }
  //       }
  //     }
  //     return false;
  //   });

  //   if (missingMandatoryFields) {
  //     this.alert.error(
  //       'Please fill in all mandatory fields before submitting the form.'
  //     );
  //     return;
  //   }

  //   const uniqueFields = this.formDetails.formValues.some((field) => {
  //     if (field.group_id != null) {
  //       let exculdedFieldTypes = [
  //         'GEOCODEDIMAGE',
  //         'FILE',
  //         'SIGNATURE',
  //         'RADIOBUTTON',
  //         'CHECKBOX',
  //         'CAMERASTREAMIMG',
  //         'VIDEOSTREAMING',
  //         'BARCODEWITHIMAGE',
  //         'RFIDCODEWITHIMAGE',
  //       ]; //don't check unique for these field types
  //       if (exculdedFieldTypes.indexOf(field.fieldType) != -1) {
  //         return true;
  //       } else {
  //         const isValueUnique = (
  //           formValues: any[],
  //           value: any,
  //           groupId: number,
  //           subGroupIndex: number
  //         ): boolean => {
  //           // Filter the formValues based on groupId and subGroupIndex
  //           const filteredValues = formValues.filter(
  //             (item) =>
  //               item.group_id === groupId &&
  //               item.sub_group_index === subGroupIndex
  //           );

  //           // If no matching group_id and sub_group_index found, return true
  //           if (filteredValues.length === 0) {
  //             return true;
  //           }
  //           if (field.fieldType == 'SELECTLISTMULTIPLE') {
  //             let flag = true;
  //             filteredValues.some((item) => {
  //               if (item.values.length > 0 && item.values.includes(value)) {
  //                 flag = false;
  //               }
  //             });
  //             return flag;
  //           } else {
  //             return !filteredValues.some(
  //               (item) =>
  //                 item.values.length > 0 &&
  //                 item.values[0].toLowerCase() === value.toLowerCase()
  //             );
  //           }
  //         };
  //         const isUnique = isValueUnique(
  //           this.formDetails.formValues,
  //           field.values[0],
  //           field.field_id,
  //           field.sub_group_index
  //         );
  //         return isUnique;
  //       }
  //     } else {
  //       return true;
  //     }
  //   });

  //   if (!uniqueFields) {
  //     this.alert.error(
  //       'Please fill in unique values before submitting the form.'
  //     );
  //     return;
  //   }
  //   console.log("this.formdetails",this.formDetails.formValues);
  //   // this.formDetails.formValues = this.formDetails.formValues.filter((e=>{}))
  //   let params = {
  //     formValues: JSON.stringify(this.formDetails.formValues),
  //     formId: this.formDetails.formId,
  //     formType: 'Visitor Form',
  //     refType: 'STAFF',
  //   };
  //   this.common.loading = true;
  //   this.api.post('customForm/save-form-responses', params).subscribe(
  //     (res: any) => {
  //       this.common.loading = false;
  //       if (res.status) {
  //         // this.setDefaultForm();
  //         this.alert.success(res.message);
  //         this.formDetails.formValues = [];
  //         // this.selectedOption = null;
  //         // this.retakeImage = false;
  //       }
  //       else{
  //         this.alert.error(res.message);
  //       }
  //     },
  //     (err: any) => {
  //       console.error('Error: ', err);
  //       this.common.loading = false;
  //     }
  //   );
  // }

  saveFormResponse() {
    console.log('saving...........', this.formDetails.formValues);
    const isFieldEmpty = (values: any) => {
      return (
        values == null ||
        values.length === 0 ||
        (Array.isArray(values) &&
          values.every((val) => val === '' || val === null))
      );
    };
    const isMultivalueValid = (responses: any[], index: number) => {
      if (responses.length !== index + 1) {
        return false;
      }
      return !responses.some((resp) => isFieldEmpty(resp.values));
    };

    // Check for missing mandatory fields
    const missingMandatoryFields = this.mappedFields.some((field) => {
      let modifiedField = this.isArray(field) ? field[0] : field;
      if (modifiedField.mandatory) {
        let formValue: any;
        let responses: any[] = [];

        if (modifiedField.field_type === 'FIELDGROUP') {
          return modifiedField.group_fields.some((grpField) => {
            if (modifiedField.is_multivalue) {
              responses = this.formDetails.formValues.filter(
                (gfv) => gfv?.field_id === grpField.field_id
              );
              return !isMultivalueValid(responses, this.multivalueIndex);
            } else {
              formValue = this.formDetails.formValues.find(
                (gfv) => gfv?.field_id === grpField.field_id
              );
              return isFieldEmpty(formValue?.values);
            }
          });
        } else {
          if (modifiedField.is_multivalue) {
            responses = this.formDetails.formValues.filter(
              (fv) => fv?.field_id === modifiedField.field_id
            );
            return !isMultivalueValid(responses, this.multivalueIndex);
          } else {
            formValue = this.formDetails.formValues.find(
              (fv) => fv?.field_id === modifiedField.field_id
            );
            return isFieldEmpty(formValue?.values);
          }
        }
      }
      return false;
    });

    if (missingMandatoryFields) {
      this.alert.error(
        'Please fill in all mandatory fields before submitting the form.'
      );
      return;
    }

    // unique check
    const isValueUnique = (
      formValues: any[],
      value: any,
      groupId: number,
      subGroupIndex: number
    ) => {
      const filteredValues = formValues.filter(
        (item) =>
          item.group_id === groupId && item.sub_group_index === subGroupIndex
      );
      if (filteredValues.length === 0) {
        return true;
      }
      if (
        filteredValues.some(
          (item) =>
            item.values.length > 0 &&
            item.values[0].toLowerCase() === value.toLowerCase()
        )
      ) {
        return false;
      }
      return true;
    };

    const excludedFieldTypes = [
      'GEOCODEDIMAGE',
      'FILE',
      'SIGNATURE',
      'RADIOBUTTON',
      'CHECKBOX',
      'CAMERASTREAMIMG',
      'VIDEOSTREAMING',
      'BARCODEWITHIMAGE',
      'RFIDCODEWITHIMAGE',
    ];

    // Check for unique field values
    const hasDuplicateFields = this.formDetails.formValues.some((field) => {
      if (
        field.group_id != null &&
        !excludedFieldTypes.includes(field.fieldType)
      ) {
        return !isValueUnique(
          this.formDetails.formValues,
          field.values[0],
          field.field_id,
          field.sub_group_index
        );
      }
      return false;
    });

    if (hasDuplicateFields) {
      this.alert.error(
        'Please fill in unique values before submitting the form.'
      );
      return;
    }

    console.log('this.formdetails', this.formDetails.formValues);
    // this.formDetails.formValues = this.formDetails.formValues.filter((e) => {
    //   e != null;
    // });
    console.log('filtered this.formDetails.formValues : ', this.formDetails.formValues );
    const params = {
      formValues: JSON.stringify(this.formDetails.formValues),
      formId: this.formDetails.formId,
      formType: 'Visitor Form',
      refType: 'USER',
    };

    this.common.loading = true;
    this.api.post('customForm/save-form-responses', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alert.success(res.message);
          this.formDetails.formValues = [];
        } else {
          this.alert.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getFieldInfoByFieldId(field: any) {
    let params = {
      fieldId: field.field_id,
    };
    this.common.loading = true;
    this.api.get('customForm/get-form-fields', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          field.options = res.data[0]?.options;
          this.alert.success(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  checkUnique(event: any, field: any, parentField: any): boolean {
    let exculdedFieldTypes = [
      'GEOCODEDIMAGE',
      'FILE',
      'SIGNATURE',
      'RADIOBUTTON',
      'CHECKBOX',
    ]; //don't check unique for these field types
    if (exculdedFieldTypes.indexOf(field.field_type) != -1) {
      return true;
    } else {
      const isValueUnique = (
        formValues: any[],
        value: any,
        groupId: number,
        subGroupIndex: number
      ): boolean => {
        // Filter the formValues based on groupId and subGroupIndex
        const filteredValues = formValues.filter(
          (item) =>
            item.group_id === groupId && item.sub_group_index === subGroupIndex
        );

        // If no matching group_id and sub_group_index found, return true
        if (filteredValues.length === 0) {
          return true;
        }
        // Check if any of the filtered items have values[0] matching the given value
        if (field.field_type == 'SELECTLISTMULTIPLE') {
          let flag = true;
          filteredValues.some((item) => {
            if (item.values.length > 0 && item.values.includes(value)) {
              flag = false;
            }
          });
          return flag;
        } else {
          return !filteredValues.some(
            (item) =>
              item.values.length > 0 &&
              item.values[0].toLowerCase() === value.toLowerCase()
          );
        }
      };

      let value =
        field.field_type == 'SELECTLISTSINGLE'
          ? event.name
          : field.field_type == 'SELECTLISTMULTIPLE'
          ? event[event.length - 1].name
          : event.target.value;
      const isUnique = isValueUnique(
        this.formDetails.formValues,
        value,
        parentField.master_field_id,
        field.sub_group_index
      );
      return isUnique;
    }
  }
}

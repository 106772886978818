<div class="live_url_page">
  <div class="row m-0">
    <div class="col-md-6 left_area">
      <div class="image">
        <img src="assets/images/plant_img.jpg">
      </div>
    </div>

    <div class="col-md-6 right_area">
      <div class="design_right">
        <h4>My Trip <span class="badge badge-pill badge-success">{{vehicleNo}}</span><span> <i
              *ngIf="response[0].flow_type==1" style="color: lime;" class="fa fa-check-circle fa-2x"></i>
            <i *ngIf="response[0].flow_type==0" style="color: #ff6868;" class="fa fa-times-circle fa-2x"></i></span> <i
            class="fa fa-sync refresh"></i></h4>
        <div class="box" style="margin-bottom: 10px;">
          {{response[0].process_name}}
        </div>
        <div class="box">
          <h5>{{response[0].current_stage_name}}</h5>
          <div class="time_div">
            <div class="time">
              <p>In Time</p>
              <h6 style="background-color: #a6ffa6;font-size: 12px">{{response[0].time_in | date :'short'}}</h6>
            </div>
            <div class="time">
              <p>Out Time</p>
              <h6 style="background-color: #ffaeae;font-size: 12px">{{response[0].time_out!=null?(response[0].time_out |
                date :'short'):'--:--'}}</h6>
            </div>
          </div>
          <div class="current_stage" *ngIf="response[0].time_out==null">Current Stage</div>
          <div class="stage-data">
            <div class="data_right badge badge-info">
              <span>{{response[0].stage_avg_tat.substring(0,response[0].stage_avg_tat.lastIndexOf(":"))}}</span>
              <span>-----</span>
              <span>Stage Average Tat</span>
            </div>

            <div class="data_right badge badge-info">
              <span>{{response[0].trip_avg_tat.substring(0,response[0].trip_avg_tat.lastIndexOf(":"))}}</span>
              <span>-----</span>
              <span>Trip Average Tat</span>
            </div>
          </div>
        </div>

        <div class="road" *ngIf="response[0].next_stage_id!=null">
          <div class="strip"></div>
          <div class="strip"></div>
          <div class="strip"></div>
          <div class="strip"></div>
          <div class="strip"></div>
          <div class="strip"></div>
          <div class="strip"></div>
          <img *ngIf="response[0].time_out!=null " src="assets/images/truck_icon.png">
          <div class="current_stage" *ngIf="response[0].time_out!=null">Current Stage</div>
        </div>

        <div class="box" *ngIf="response[0].next_stage_id!=null">
          <h5>{{response[0].next_stage_name}}</h5>
          <ng-container *ngFor="let des of destinationName;index as i">
            <h6>{{des.status==true?'On The Destination':"Alloted Destination"}}</h6>
            <div class="substage_name">{{des.destination}}</div>
          </ng-container>
          <div class="next_stage">Next Stage</div>
        </div>
        <div *ngIf="response[0].next_stage_id==null">
          <div class="next_stage" style="color: green;margin-top: 13px;
          font-weight: 700;">Your Trip is Completed ...</div>
        </div>
      </div>
      <div class="navigation_icon" (click)="openNavigation()">
        <i class="fa fa-location-arrow"></i>
      </div>
    </div>
  </div>
</div>

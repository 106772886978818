import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-vehicle-status-change',
  templateUrl: './vehicle-status-change.component.html',
  styleUrls: ['./vehicle-status-change.component.scss'],
})
export class VehicleStatusChangeComponent implements OnInit {
  data: any[] = [];
  vehicle_name: any = null;
  vehicle_id: any = null;
  vehicle_status: any = null;
  txn_id: any = null;
  allStatus: any[] = [];
  selectedPlantId: string;
  plantData: any[]=[];
  plantName: string;

  constructor(
    public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal,
    public user:UserService
  ) {
    if(this.user._loggedInUser.plantId==0){
      this.getAllPlant();
    }
  }

  selectPlant(event: any) {
    this.plantName = event.plantName;
    this.selectedPlantId = event.plantId;
    this.getVehicles();
  }

  ngOnInit(): void {
    this.getVehicles();
  }

  getAllPlant() {
    this.common.loading = true;
    let apiUrl = 'plant/all_plants';
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data || [];
      },
      (err: any) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  selectedVehicle(item: any) {
    this.vehicle_name = item.vehicle_name;
    this.vehicle_id = item.vehicle_id;
    this.vehicle_status = item.status;
    this.txn_id = item.ipa_transaction_id;
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  changeStatus() {
    this.common.loading = true;
    this.api
      .get(
        'navbar/update-vehicle-status?txnId=' +
          this.txn_id +
          '&status=' +
          this.vehicle_status
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.closeModal();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getVehicles() {
    this.common.loading = true;
    let apiUrl;
    if(this.user._loggedInUser.plantId==0){
      apiUrl='navbar/get-vehicles-inside-plant?plantId='+this.selectedPlantId
    }else{
      apiUrl='navbar/get-vehicles-inside-plant'
    }
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.data = res.data[0].vehiclesList;
        this.vehicle_name = res.data[0].vehiclesList[0].vehicle_name;
        this.vehicle_id = res.data[0].vehiclesList[0].vehicle_id;
        this.vehicle_status = res.data[0].vehiclesList[0].status;
        this.txn_id = res.data[0].vehiclesList[0].ipa_transaction_id;
        this.allStatus = res.data[0].statusList;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

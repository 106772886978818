<div class="card-header card-select-header">
  <div class="filters">
    <div class="date-filter">
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="tripcancelpicker">
          <input matStartDate placeholder="Start Date" (dateChange)="startdate($event)" [(ngModel)]="fromDate">
          <input matEndDate placeholder="End Date" (dateChange)="endDate($event)" [(ngModel)]="ToDate">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="tripcancelpicker"></mat-datepicker-toggle>
        <mat-date-range-picker #tripcancelpicker></mat-date-range-picker>
      </mat-form-field>
      <div class="option-filter" *ngIf="menuoptions">
        <button mat-button [matMenuTriggerFor]="menu" class="select-menu"><i class="fa fa-bars"
            aria-hidden="true"></i></button>
        <mat-menu #menu="matMenu" class="select-menu-options">
          <button mat-menu-item *ngFor="let item of optionmenu;index as i"
            (click)="menudateParse(item.type,item.number,i)"
            [class]="optionactiveindex == i ? 'active':''">{{item.name}}</button>
        </mat-menu>
      </div>
    </div>

  </div>
</div>
<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{title | translate}}
            </h3>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
              <label for="my-input" class="required">{{"select_process" | translate}} :</label>
              <ps-auto-suggestion *ngIf="processDisable==false" (onSelected)="selectedProcess($event)"
                [data]="listOfProcess" display="processName" placeholder="Select Process" name="selectedActionData"
                inputId="selectedActionData" [preSelected]="{processName:auditMapping.processName}">
              </ps-auto-suggestion>
              <input *ngIf="processDisable == true" type="text" [value]="auditMapping.processName" disabled>
              <div class="error" *ngIf="actionAuditValidator && actionAuditValidator.error">
                <span>{{actionAuditValidator.msg}} </span>
              </div>
            </div>

            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
              <label for="my-input" class="required">{{"action_to audit" | translate}}</label>
              <ps-auto-suggestion (onSelected)="selectedUser($event)" [data]="actionData" display="tag_action_name"
                placeholder="Select Action Audit" name="selectedActionData" inputId="selectedActionData"
                [preSelected]="{tag_action_name:auditMapping.actionName}">
              </ps-auto-suggestion>
              <div class="error" *ngIf="actionAuditValidator && actionAuditValidator.error">
                <span>{{actionAuditValidator.msg}} </span>
              </div>
            </div>


            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
              <label for="my-input" class="required">{{"frequency"| translate}} (in %)</label>
              <input type="number" min=0 max="100" [(ngModel)]="auditMapping.frequency"
                [ngModelOptions]="{standalone: true}" class="form-control">
              <div class="error" *ngIf="frequencyValidator && frequencyValidator.error">
                <span>{{frequencyValidator.msg}} </span>
              </div>
            </div>


            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
              <label for="my-input" class="required"> {{"action_stage mapping" | translate}} </label>
              <ps-auto-suggestion [data]="actionData1" display="tag_action_name" seperator="-"
                placeholder="Select Stages" (onSelected)="stageName = $event" [preSelected]="stageName" name="stageName"
                inputId="stageName" isMultiSelect="true"></ps-auto-suggestion>
              <div class="error" *ngIf="stageValidator && stageValidator.error">
                <span>{{stageValidator.msg}} </span>
              </div>
            </div>
          </div>

          <div class="form-group action-block block-margin">
            <div class="btn-block">
              <button class="btn btn-warning" (click)="reset()"> {{"reset" | translate}} </button>
              <button type="submit" class="btn btn-submit" (click)="formValidator()"
                *ngIf="userService.permissionMap.get('add_audit_management_access')==true">{{btn | translate}}</button>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"list_of audit" | translate}}
                </h3>
              </div>
            </div>
            <div class="card-body">

              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{"s.no." | translate}} </th>
                    <th>{{"process_name" | translate}} </th>
                    <th>{{"action_name" | translate}}</th>
                    <th>{{"frequency" | translate}}</th>
                    <th>{{"stage_mapping" | translate}}</th>
                    <th class="action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of auditAllTableData,, let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.process_name}}</td>
                    <td>{{item.audit_action_name}}</td>
                    <td>{{item.frequency}}</td>
                    <td *ngIf="item.tag_actions.length!=0">
                      <div *ngFor="let i of item.tag_actions; let last = last">
                        {{i.tag_action_name}}{{!last ? ',' : ''}}
                      </div>
                    </td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewUserActionMapping(item)"> {{"view" | translate}}
                        </button>
                        <button class="btn btn-success" (click)="getAuditEdit(item)"
                          *ngIf="userService.permissionMap.get('edit_audit_management_access')==true"> {{"edit" |
                          translate}} </button>
                        <button class="btn btn-danger" (click)="confirmDeleteAction(item.id)"> {{"delete" | translate}}
                        </button>
                      </div>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header" style="display: flex;justify-content: end; padding-bottom:0px;width:100%">

              <div class="process" style="display:flex;justify-content: space-between;align-items: baseline;width: 80%;">
                <ngb-carousel [interval]="carouselTime" [pauseOnHover]="false" [keyboard]="true" [showNavigationArrows]="false" [showNavigationIndicators]="false" #process  (slide)="onSlide($event)" style="display:flex;justify-content: space-around;align-items: baseline;width: 80%;">
                  <ng-template ngbSlide *ngFor="let i of processData;let index=index" [attr.id]="'ngb-slide-'+ index">
                     <div class="t" style="display:flex; justify-content: space-between;">
                      <div class="card_box_title">
                        {{selectedProcessName?selectedProcessName:i?.process_name}}
                      </div>

                      <div class="m flex" >
                        <div class="status ml-3">
                          <div>
                            <div class="small_text" style="color: teal;">Resolved Alerts</div>

                          <div class="status_num_graph">
                            <span class="small_num mr-1">{{i?.resolved_alert}}</span>
                            <i class="fa fa-exclamation-triangle" style="color: teal;"></i>
                          </div>
                          </div>
                          </div>

                        <div class="status ml-3">
                          <div>
                            <div class="small_text" style="color:#e65050;;">Pending Alerts</div>

                          <div class="status_num_graph">
                            <span class="small_num mr-1">{{i?.process_alert}}</span>
                            <i class="fa fa-exclamation-triangle" style="color:#e65050;;"></i>
                          </div>
                        </div>
                        </div>

                        <div class="status ml-3 mr-3">
                          <div>
                            <div class="small_text" style="color:#e6aa50;;">Vehicle</div>

                          <div class="status_num_graph">
                            <span class="small_num mr-1">{{i?.process_vehicle}}</span>
                            <i class="fa fa-truck" style="color:#e6aa50;"></i>
                          </div>
                        </div>
                        </div>

                        <div class="status ml-3 mr-5">
                          <div>
                            <div class="small_text" style="color:#50a3e6;;">Tat</div>

                          <div class="status_num_graph">
                            <span class="small_num mr-1">{{i?.process_tat}}</span>
                            <i class="fa fa-clock" style="color:#50a3e6;;"></i>
                          </div>
                        </div>
                        </div>
                      </div>
                     </div>
                  </ng-template>
                  </ngb-carousel>
              </div>

              <div class="a" style="display:flex;justify-content: space-around;align-items: baseline;width: 40%;">


                <div class="form-group mr-1" *ngIf="role.user_role=='COMPANYADMIN' && refData.plantId == null">
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantList" display="plantName"
                    placeholder="Choose Plant">
                  </ps-auto-suggestion>
                </div>
                <div class="form-group">
                  <ps-auto-suggestion (onSelected)="selectProcess($event)" [data]="processList" display="processName"
                    placeholder="Choose Process">
                  </ps-auto-suggestion>
                </div>

                <div class="box1" style="display: flex;">
                  <mat-label for="" style="margin-right:6px;margin-top:3px !important;font-size: 14px;">
                    {{'Slider'|translate}} </mat-label>
                  <div class="custom-control custom-switch" style="margin-top: 3px;">
                    <input type="checkbox" class="custom-control-input" id='customSwitchesMandatory'
                      [(ngModel)]="runCarousel" (change)="startCarousel()">
                    <label class="custom-control-label" for="customSwitchesMandatory"></label>
                  </div>
                </div>

                <div [ngClass]="openDialog?'fa fa-close':'fa fa-cog'" (click)="toggleDialog()"></div>


                <ng-template #dialogTemplate>
                  <h3 mat-dialog-title>Slide Settings</h3>
                  <mat-dialog-content>
                    <div class="form-group">
                      <select name="carousel_time" id="#carousel_time" class="form-control"
                        (change)="UpdateRefreshTime($event)">
                        <option value="" selected disabled>Choose Refresh Time</option>
                        <option value="5s">5 sec</option>
                        <option value="30s">30 sec</option>
                        <option value="1m">1 Min</option>
                        <option value="3m">3 Min</option>
                        <option value="5m">5 Min</option>
                        <option value="10m">10 Min</option>
                        <option value="30m">30 Min</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select name="carousel_time" id="#carousel_time" class="form-control"
                        (change)="updateCarousel($event)">
                        <option value="" selected disabled>Choose Slide Time</option>
                        <option value="5s">5 sec</option>
                        <option value="30s">30 sec</option>
                        <option value="1m">1 Min</option>
                        <option value="3m">3 Min</option>
                        <option value="5m">5 Min</option>
                        <option value="10m">10 Min</option>
                        <option value="30m">30 Min</option>
                      </select>
                    </div>
                  </mat-dialog-content>
                </ng-template>




                <!-- <h3 class="card-title">
                  {{"plant_live_view" | translate}}
                </h3> -->
              </div>
            </div>


            <div class="card-body" style="position: relative;">
              <ngb-carousel [interval]="carouselTime" [pauseOnHover]="false" [keyboard]="true" #carousel  (slide)="onSlide($event)">
                <ng-template ngbSlide *ngFor="let i of processData;let index=index" [attr.id]="'ngb-slide-'+ index" >

                  <div class="plant_live_dash" >


                    <!-- <div class="card_box_title">
                      {{selectedProcessName?selectedProcessName:i?.processName}}
                    </div> -->

                    <div class="card_box">
                      <div class="card_one" *ngFor="  let stage of i?.process_data">
                        <div class="top_row">
                          <div class="stage_name">
                            {{stage.stage_name}}
                          </div>

                          <div class="over_tat_alert">
                            <span>Over TAT Vehicle: {{stage.overtat_count}}</span>
                          </div>
                        </div>

                        <div class="veh_tat_large">
                          <div class="detail">
                            <i class="fa fa-truck"></i>
                            <div class="current_text">{{stage.stage_vehicle_count}}</div>
                            <div class="max_text">/ {{stage.max_vechile_count}}</div>
                          </div>

                          <!-- <div class="detail">
                        <i class="fa fa-truck"></i>
                        <div class="current_text">04</div>
                        <div class="max_text">/ 07</div>
                      </div> -->

                          <div class="detail">
                            <i class="fa fa-clock"></i>
                            <div class="current_text">{{stage.avg}}</div>
                            <div class="max_text">/ {{stage.expected_tat }}</div>
                          </div>
                        </div>

                        <div class="small_boxes">
                          <div class="box">
                            <div class="text">Pending Alert</div>
                            <div>
                              <img src="assets/images/stage_alert.png" class="stage_alert">
                              <div class="box_text">{{stage.pending_alerts}}</div>
                            </div>
                          </div>

                          <div class="box">
                            <div class="text">Pending Overides</div>
                            <div>
                              <img src="assets/images/pending_img.png" class="pending_overides">
                              <div class="box_text">{{stage.override_pending}}</div>
                            </div>
                          </div>

                          <div class="box">
                            <div class="text">Maintainace Hardware</div>
                            <div>
                              <img src="assets/images/damage_hardware.png" class="damage_hardware">
                              <div class="box_text">{{stage.hardware_maintenance}}</div>
                            </div>
                          </div>

                          <div class="box">
                            <div class="text">Maintainace Vehicle</div>
                            <div>
                              <img src="assets/images/damage_vehicle.png" class="damage_vehicle">
                              <div class="box_text">{{stage.maintenance_vehicle}}</div>
                            </div>

                          </div>
                        </div>

                        <div class="inbetween_vehicle">
                          <div class="inbetween_title">In-Between Vehicles</div>

                          <div class="height_box">
                            <div class="inbetween_row"  *ngFor="let prev of stage.inbetween">
                              <div class="text" appEllipsisView>{{prev.stage_name}}</div>
                              <div class="content">
                                <div class="data">
                                  <i class="fa fa-truck"></i>
                                  <div class="current_text">{{prev.count}}</div>
                                  <div class="max_text">/ {{prev.vehicle_limit}}</div>
                                </div>

                                <div class="data">
                                  <i class="fa fa-clock"></i>
                                  <div class="current_text">{{prev.inbetween_tat}}</div>
                                  <div class="max_text">/ {{prev.expected_tat}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

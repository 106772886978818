import { TableService } from '../../@core/services/table.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { CommonService } from '../../@core/services/common.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { StageDestinationCodeTypeService } from './stage-destination-code-type.service';
import { UserService } from '../../@core/services/user.service';

@Component({
  selector: 'app-stage-destination-code-type',
  templateUrl: './stage-destination-code-type.component.html',
  styleUrls: ['./stage-destination-code-type.component.scss'],
})
export class StageDestinationCodeTypeComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(
    10,
    4,
    'Stage Destination Code Type List'
  );
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAllStageDestinationCodeType();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  stageDestinationCodeType = {
    stageDestinationCodeTypeId: null,
    stageDestinationCodeTypeName: '',
    // stageDestinationCodeTypeValue: '',
    stageDestinationCodeTypeStatus: 'ACTIVE',
  };
  title = 'Add Stage Destination Code Type';
  btn = 'save';
  data: any[] = [];

  constructor(
    private stageDestinationCodeTypeService: StageDestinationCodeTypeService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Destinations', url: null },
      {
        name: 'Stage Destination Code Type',
        link: '/user/stage_destination_code_type_list',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getAllStageDestinationCodeType();
  }
  ngViewInit() {}

  getAllStageDestinationCodeType() {
    this.common.loading = true;
    let params = {
      listType: 'all',
    };
    this.stageDestinationCodeTypeService
      .getAllStageDestinationCodeType(params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;

          this.data = res.data;
          this.renderTable();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          //
        }
      );
  }

  saveStageDestinationCodeType() {
    this.common.loading = true;
    let params = {
      stageDestinationCodeTypeId:
        this.stageDestinationCodeType.stageDestinationCodeTypeId,
      stageDestinationCodeTypeName:
        this.stageDestinationCodeType.stageDestinationCodeTypeName,
      stageDestinationCodeTypeStatus:
        this.stageDestinationCodeType.stageDestinationCodeTypeStatus,
    };

    this.stageDestinationCodeTypeService
      .saveStageDestinationCodeType(params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.getAllStageDestinationCodeType();
          this.resetDetail();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  resetDetail() {
    this.stageDestinationCodeType = {
      stageDestinationCodeTypeId: null,
      stageDestinationCodeTypeName: '',
      stageDestinationCodeTypeStatus: 'ACTIVE',
    };
    this.codeTypeKeyValidator = null;
    this.codeTypeValidator = null;
    this.statusValidator = null;
    this.btn = 'save';
    this.title = 'Add Stage Destination Code Type';
  }

  editStageDestinationCodeType(row?: any) {
    this.common.gotoTop();
    this.resetDetail();
    this.stageDestinationCodeType.stageDestinationCodeTypeId =
      row.stageDestinationCodeTypeId;
    this.stageDestinationCodeType.stageDestinationCodeTypeName =
      row.stageDestinationCodeTypeName;
    this.stageDestinationCodeType.stageDestinationCodeTypeStatus =
      row.stageDestinationCodeTypeStatus;
    this.btn = 'update';
    this.title = 'Edit Stage Destination Code Type Details';
  }
  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'info' };

    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  codeTypeKeyValidator: any;
  codeTypeValidator: any;
  statusValidator: any;
  formValidator() {
    this.codeTypeKeyValidator = this.common.nullValidator(
      this.stageDestinationCodeType.stageDestinationCodeTypeName,
      'Stage Destination Code Type Key Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.stageDestinationCodeType.stageDestinationCodeTypeStatus,
      'Stage Destination Code Type Status Mandatory'
    );
    if (!this.codeTypeKeyValidator.error && !this.statusValidator.error) {
      this.saveStageDestinationCodeType();
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AssetCategoryServiceService } from 'src/app/components/asset-category/asset-category-service.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { fieldTypes } from './static-data';
import { validator } from 'src/app/dataType/commonInterface';
import { categ, categoryList } from 'src/app/dataType/assetInterface';

@Component({
  selector: 'app-add-field-modal',
  templateUrl: './add-field-modal.component.html',
  styleUrls: ['./add-field-modal.component.scss']
})
export class AddFieldModalComponent implements OnInit {
  @Input() public refData;


  fieldTypes=fieldTypes
  fieldStatus:String='';
  customField = {
    id: null,
    name: null,
    status: 'ACTIVE',
    type: 'TEXTFIELD',
    values: [],
    hardwareResponse: null,
    fieldValue: 'USERINPUT',
    databaseField: null,
  };
  detail = '';
  btn = 'save';
  title = 'Add Field'
  fieldNameValidator : validator = null;
  lastRankingIndex: number = 1;


  //
  categ:categ[] = [];
  previousLength:number=0;
  categoryValue:number = 1;
  hideRadio:boolean=false;
  showCategory:boolean=false;
  categoryList:categoryList[]=[];
  categories=[
    {
      "asset_category_id":-1,
      "asset_category_name":null,
      "is_mandatory" : false,
      "is_unique" : false,
      "ranking_index" : 1
    }
  ]
  allMandatory:boolean=false;
  allUnique:boolean=false;


  constructor(
    public activeModal:NgbActiveModal,
    public common : CommonService,
    public api : ApiService,
    public alert : AlertService,
    public role : RoleService,
    public table: TableService,
    private assestCategoryService: AssetCategoryServiceService
  ) { }


  ngOnInit(): void {
    if(this.refData.field!=null){
      this.editRow(this.refData.field);
    }
  }


  closeModal(response?: any, apiHit?: any, sameStage?:boolean) {
    this.activeModal.close({ response: response, apiHit: apiHit,sameStage:sameStage });
  }

  selectFieldType(fieldType:any){
  console.log('fieldType: ', fieldType);
    this.customField.type = fieldType;
  }

  getLastRankingIndex(categoryId:any): Promise <any>{
    return new Promise ((resolve , reject) =>{
      let params = {
        categoryId : categoryId
      }
      this.api.get('asset_categories/get-last-ranking-index-by-category-id',params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.lastRankingIndex = res.data[0];
            resolve(true);
            console.log('this.lastRankingIndex: ', this.lastRankingIndex);
          }
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
          reject(false);
        }
        );
      });
    }


  selectedCategoryType(){
    if( this.categoryValue==0){
      this.categ.length ? true : this.getCategory();
      this.showCategory=true;
      this.hideRadio=true;
    }
    if(this.categoryValue==1){
      this.showCategory=false;
      this.hideRadio=false;
      this.categ = []

    }
}

getCategory(): Promise<any> {
  return new Promise((resolve, reject) => {
  this.common.loading=true;
  let params={
    allCategories:false,
    plantId:this.refData.plantId,
    companyId:this.refData.companyId
  }
  this.assestCategoryService.getAssetCategory(params).subscribe(
    (res:any)=>{
       this.common.loading=false;
       this.categoryList =  res.data;
       resolve(true);
    },
    (err:any)=>{
      console.error('Error', err);
      this.common.loading = false;
      reject(false);
    }
  )
})
};

createList( asset_category_id:any){
  const index = this.categories.indexOf(asset_category_id);
  if (index === -1) {
    //add in list
    this.categories.push(asset_category_id);
    console.log("Category added to the list:", asset_category_id);
  } else {
    //remove from list
    this.categories.splice(index, 1);
    console.log("Category removed from the list:", asset_category_id);
  }
  console.log("Updated categories list:", this.categories);
}

onAllMandatory(allMandatory:any){
console.log('allMandatory: ', allMandatory);
  this.categ.forEach((res) => {
    res.is_mandatory = allMandatory})
    console.log('this.categ: ', this.categ);
}

onAllUnique(allUnique:any){
console.log('allUnique: ', allUnique);
  this.categ.forEach((res) => {
    res.is_unique = allUnique})
}
  addValues() {
    this.customField.values.push({ name: null });
  }

  saveMapping(){
  console.log('saveMapping ');
  console.log("categories", this.categories);
  console.log("categ", this.categ);
    // this.common.loading=true;
    let masterFieldValues: any = [];
    if (
      (this.customField.type == 'SELECTLISTMULTIPLE' &&
        this.customField.values) ||
      (this.customField.type == 'SELECTLISTSINGLE' &&
        this.customField.values) ||
      (this.customField.type == 'RADIOBUTTON' && this.customField.values) ||
      (this.customField.type == 'CHECKBOX' && this.customField.values)
    ) {
      this.customField.values.map((mf) => {
        masterFieldValues.push(mf.name);
      });
    }
    let masterField = {
      masterFieldId: this.customField.id,
      fieldName: this.customField.name.trim(),
      fieldStatus: this.customField.status,
      fieldType: this.customField.type,
      masterFieldValues: masterFieldValues,
      hardwareResponse: this.customField.hardwareResponse,
      databaseLinkedId: this.customField.databaseField,
      fieldValue: this.customField.fieldValue,
      subGroups: null,
      fieldEntity: 'ASSET'
    };

    let params= {
      isEdit:this.refData.field != null ? true : false,
      categories: this.categoryValue == 0 ? this.categ : this.categories,
      masterFieldDTO : masterField,
      userRole : this.role.user_role,
      plantId : this.refData.plantId,
      companyId : this.refData.companyId
    }
    this.api.post('asset_categories/save-category-field-mapping',params).subscribe(
      (res: any) => {
        if(res.status){
          this.alert.success(res.message);
        }else{
          this.alert.info(res.message);
        }
        this.common.loading = false;
        this.resetDetail();
        this.closeModal();
        console.log('success');
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }


  resetDetail() {
    this.customField = {
      id: null,
      name: null,
      status: 'ACTIVE',
      type: 'TEXTFIELD',
      values: [],
      hardwareResponse: null,
      fieldValue: 'USERINPUT',
      databaseField: null,
    };
    this.fieldNameValidator = null;
    this.selectiveCategoryValidator = null;
    this.detail = '';
    this.categ=[];
    this.allMandatory=false;
    this.allUnique=false;
    this.showCategory=false;
    this.categoryValue=1;
    this.categories[0].is_mandatory=false;
    this.categories[0].is_unique=false;
  }


  async editRow(row?: any) {
  console.log('row: ', row);
    this.common.gotoTop();
    this.resetDetail();
    let values: any = [];
    let fieldData = row.masterFieldDTO;
    console.log('fieldData: ', fieldData);

    if (
      (fieldData.fieldType == 'SELECTLISTSINGLE' && fieldData.masterFieldValues) ||
      (fieldData.fieldType == 'SELECTLISTMULTIPLE' && fieldData.masterFieldValues) ||
      (fieldData.fieldType == 'RADIOBUTTON' && fieldData.masterFieldValues) ||
      (fieldData.fieldType == 'CHECKBOX' && fieldData.masterFieldValues)
    ) {
      const fieldValues = fieldData.masterFieldValues.split(',');
      console.log('fieldValues: ', fieldValues);
      for (let index = 0; index < fieldValues.length; index++) {
        values.push({ name: fieldValues[index] });
      }
    }

    this.customField.id = fieldData.masterFieldId;
    this.customField.name = fieldData.fieldName;
    this.customField.type = fieldData.fieldType;
    this.customField.status = fieldData.fieldStatus;
    this.customField.hardwareResponse = fieldData.hardwareResponse;
    this.customField.values = values;
    this.btn = 'update';
    this.title = 'Edit Field';
    console.log("fghjk", this.customField);


    // fill categories data

    let categoriesData = row.categories;
    if(categoriesData[0].asset_category_id == -1){
      this.categories = categoriesData
      this.categoryValue = 1;
    }else{
      await this.getCategory()
      this.categ = categoriesData;
      this.previousLength = this.categ.length;
      this.categoryValue = 0;
      this.showCategory = true;
    }
    console.log("categories", this.categories);
    console.log("categ", this.categ);
  }


  createdField(event:any){

    this.customField.values = event;
    console.log("this.customField.values",this.customField.values);

  }

  async selectedCategory(event:any){
    console.log("event", event.length);
    if(event.length > 0){
      await this.getLastRankingIndex(event[event.length - 1].asset_category_id);
    }
    if(event.length > this.previousLength){
    let categories={
          "is_mandatory" : false,
          "is_unique" : false,
          "ranking_index" : this.lastRankingIndex,
          "suggested_index":this.lastRankingIndex,
          "is_ranking_changed":false,
          ...event[event.length-1]
        }
    event[event.length-1] = categories
    }
    this.categ= event;
    console.log('this.categ: ', this.categ);
    this.previousLength = this.categ.length;
    console.log('this.previousLength: ', this.previousLength);
  }

  checkChanged(item:any){
    if(item.suggested_index>item.ranking_index){
      item.is_ranking_changed=true;
    }
    else{
      item.is_ranking_changed=false;
    }
    console.log(this.categ,"this is list to check")
  }

  onMandatoryFalse(event:any){
    if(event.target.checked==false){
      this.allMandatory=false;
    }
  }
  onUniqueFalse(event:any){
    console.log(event.target.checked==false,"event.target.checked==false")
    if(event.target.checked==false){
      this.allUnique=false;
    }
  }

  selectiveCategoryValidator = {
    error :false,
    msg : null
  };
  categoryListValidator:any[]=[];
  choiceValidator :any  = {
    error :false,
    msg : null
  };
  rankingIndexValidator:any[]=[];


  formValidator(){
    this.fieldNameValidator=this.common.nullValidator(
      this.customField.name,"Field Name Mandatory"
    )
    if(this.categoryValue == 0 ){
      this.selectiveCategoryValidator =  this.common.listValidator(
        this.categ , "Select atleast one category"
      )
      this.categ.forEach((e,index)=>{
        this.rankingIndexValidator[index]=this.common.nullValidator(
          e.ranking_index,'Ranking index Mandatory'
          );
      });
    }
    else
    if(this.customField.type=='SELECTLISTSINGLE'|| this.customField.type=='SELECTLISTMULTIPLE'|| this.customField.type=='RADIOBUTTON'|| this.customField.type=='CHECKBOX'){
      this.choiceValidator = this.common.nullValidator(this.customField.values , 'Enter atleast one choice');
    }
    if(this.categoryValue == 0){
      if(!this.fieldNameValidator.error && !this.selectiveCategoryValidator.error && !this.choiceValidator.error && !this.rankingIndexValidator.some((validator)=>validator.error)){
        this.saveMapping();
      }
    }else{
      if(!this.fieldNameValidator.error && !this.choiceValidator.error){
        this.saveMapping();
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AutomationService extends ApiService {
  saveActions(data: any): Observable<any> {
    let apiSubUrl = "actions/update-action-workflow";
    return super.post(apiSubUrl, data);
  }



  deleteActions(data: any): Observable<any> {
    let apiSubUrl = "stage/list_stage_configuration";
    return super.post(apiSubUrl, data);
  }

  saveStage(data: any): Observable<any> {
    let apiSubUrl = "stage/save_stage_configuration";


    return super.post(apiSubUrl, data);
  }

  getAllStages(processId: number): Observable<any> {
    let apiSubUrl = "stage/list_stage_configuration";
    return super.get(`${apiSubUrl}?processId=${processId}`);
  }


  getallstageaction(processId: number){
  let apiSubUrl = "actions/list_stage_condition_action_function_configuration";
    return super.get(`${apiSubUrl}?processId=${processId}`);
  }

  getAllActionsByProcessId(processId: number){
  let apiSubUrl = "actions/get-all-actions-by-processId";
    return super.get(`${apiSubUrl}?flag=true&processId=${processId}`);
  }



  getAllProcess(): Observable<any> {
    let apiSubUrl = "process/get-all-process?processActive=true";
    return super.get(apiSubUrl);
  }

  deleteStage(data: any): Observable<any> {
    let apiSubUrl = "stage/list_stage_configuration";
    return super.post(apiSubUrl, data);
  }


  saveStageConfigurationModalData(data:any): Observable<any>{
    let apiSubUrl="stage/save-modal-stage-configuration"
    return super.post(apiSubUrl,data);
  }

  getStageConfigurationModalData(processId:number,stageId:number): Observable<any>{
    let apiSubUrl="stage/get-modal-stage-configuration"
    return super.get(`${apiSubUrl}?processId=${processId}&stageId=${stageId}`);
  }
}

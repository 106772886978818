import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { Base64ToFileService } from 'src/app/@core/services/base64-to-file-service.service';

@Component({
  selector: 'app-alert-remark',
  templateUrl: './alert-remark.component.html',
  styleUrls: ['./alert-remark.component.scss'],
})
export class AlertRemarkComponent implements OnInit {
  refData: any = null;
  retakeImage: boolean = false;
  formValues: any[] = [
    {
      remark: null,
      fileUrl: null,
      reference_id: null,
      reference_type: 'alert',
    },
  ];
  fileURL: any = [];
  fileEvent: any = [];
  remarkData: any[] = [];
  image_name: any = '';
  image_url: any = '';
  image_ext: any = '';
  imageUploaded: boolean = false;
  imageUploadAcco: boolean = false;

  constructor(
    public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal,
    private base64ToFileService: Base64ToFileService,
    private imageCompress: NgxImageCompressService
  ) {
    this.refData = this.common.params;
  }

  ngOnInit(): void {}

  updateStatus(status: string) {
    if (this.formValues[0].remark == null || this.formValues[0].remark == '') {
      this.alertService.error('Remark is Mandatory');
    } else {
      let statusId = this.refData.alertStatus.filter(
        (action) => action.status == status
      )[0].id;
      // this.common.loading = true;
      let params = {
        type: 'PERFORM',
        alertLogId: this.refData.item.alert_log_id,
        updatedStatus: status,
        statusId: statusId,
        remarkData: JSON.stringify(this.formValues),
      };
      this.api.post('alert/update-alert-status', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.closeModal(true);
          }
        },
        (err: any) => {
          this.common.loading = false;
          this.closeModal(false);
        }
      );
    }
  }

  enterValues(event: any) {
    this.formValues[0].remark = event.target.value;
    this.formValues[0].reference_id = this.common.params.item.alert_log_id;
  }

  uploadFile(file: any) {
    let frmData = new FormData();
    let imgResultAfterCompression: DataUrl;
    this.imageCompress
      .compressFile(file._imageAsDataUrl, -2, 50, 50) // 50% ratio, 50% quality
      .then((compressedImage) => {
        imgResultAfterCompression = compressedImage;
        let fileData = this.base64ToFileService.base64toFile(
          imgResultAfterCompression,
          'image' + '.jpg'
        );
        frmData.append('files', fileData);
        this.fileEvent.inProgress = true;
        const headers = new HttpHeaders();
        this.api
          .post('functional/upload-file?methodId=' + 17, frmData)
          .subscribe((res: any) => {
            switch (res.data.type) {
              case HttpEventType.UploadProgress:
                this.fileEvent.progress = Math.round(
                  (res.data.loaded * 100) / res.data.total
                );
                break;
              case HttpEventType.Response:
                return res.data;
            }
            this.formValues[0].fileUrl = res.data[0].fileUrl;
            this.imageUploaded = true;
          });
        catchError((error: HttpErrorResponse) => {
          this.fileEvent.inProgress = false;
          return `${this.fileEvent.data.name} upload failed.`;
        });
      });
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }
}

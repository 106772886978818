<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{('tat InBetween'|translate)+' '+('report'|translate)}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="page_design">
                <div class="row tab_sec m-0">
                  <div class="col-md-3 date" *ngIf="role.user_role=='COMPANYADMIN'">
                    <label for="my-input" class="required">{{"select_plant" | translate}}</label>
                    <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                      placeholder="Choose Plant">
                    </ps-auto-suggestion>
                  </div>
                  <div class="col-md-3 date">
                    <label>{{'start_date:'|translate}}</label>
                    <input type="date" class="form-control" [(ngModel)]="startDate">
                  </div>
                  <div class="col-md-3 date">
                    <label>{{'end_date:'|translate}}</label>
                    <input type="date" class="form-control" [min]="startDate" [(ngModel)]="endDate">
                  </div>
                  <div class="col-md-1 btn-block text-right">
                    <button class="btn btn-primary" (click)="getPlantTatReport()">{{"search" | translate}}</button>
                  </div>
                </div>


                <div class="view_table">
                  <div class="row tab_sec">

                    <div class="col-md-4 date">
                      <label class="mr-1">{{'select_process:'|translate}}</label>
                      <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="listOfProcess"
                        display="processName" placeholder="Select Process" name="selectedActionData"
                        inputId="selectedActionData" [preSelected]="{processName:processName}">
                      </ps-auto-suggestion>
                    </div>

                    <div class="col-md-8 col-8 buttons text-right">
                      <button type="button" class="excel-btn excel"
                      (click)="exportAsExcel(responseData,'TAT Inbetween Report')">
                      <i class="fas fa-file-excel"></i>
                      </button>
                      <button type="button" [class.active]="tabvalue == 'table'"
                        (click)="tabvalue = 'table';getPlantTatReport()"><i class="fa fa-table"></i>
                        &nbsp;{{'table_view'|translate}}</button>
                      <button type="button" [class.active]="tabvalue == 'graph'" (click)="tabvalue = 'graph'"><i
                          class="fa fa-chart-pie"></i> &nbsp;{{'graph_view'|translate}}</button>
                    </div>
                  </div>

                  <div class="table_sec" *ngIf="tabvalue == 'table' && responseData?.length > 0">
                    <table class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th style="width:10%">Date</th>
                          <ng-container *ngFor="let item of headers">
                            <th>{{item.stage_name}} <small style="font-size: 10px;">(Actual / Expected) TAT</small></th>
                          </ng-container>
                          <th>Date Wise TAT <small style="font-size: 10px;">(Actual / Expected) TAT</small></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let data of responseData, let i = index">
                          <td style="width:10%">{{data.date| date:'dd-MM-yyyy'}}</td>
                          <ng-container *ngFor="let item of data.data">
                            <td><span
                                [ngClass]="item.tat > item.expected_tat ? 'badge over' : 'badge'">{{item.tat}}</span> /
                              <span class="badge info">{{item.expected_tat}}</span>
                              <i *ngIf="item.tat > item.expected_tat" class="fa fa-info-circle"
                                (click)="openModal(data.date,item,item.expected_tat)"
                                style="margin-left: 5px;color: #2d2d81;font-size: 15px"></i>
                            </td>
                          </ng-container>
                          <td><span
                              [ngClass]="dayWiseTatList[i] > expected_tat ? 'badge over' :  'badge'">{{dayWiseTatList[i]}}</span>
                            / <span class="badge info">{{expected_tat}}</span></td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <td>Total Process Wise TAT</td>
                          <ng-container *ngFor="let item of headers, let i = index">
                            <td><span
                                [ngClass]="totalProcessWiseTatList[i] > item.expected_tat ? 'badge over' : 'badge under'">{{totalProcessWiseTatList[i]}}</span>
                              / <span class="badge info">{{item.expected_tat}}</span></td>
                          </ng-container>
                          <td><span
                              [ngClass]="sumOfTotalProcessWiseTat > expected_tat ? 'badge over' : 'badge under'">{{sumOfTotalProcessWiseTat}}</span>
                            / <span class="badge info">{{expected_tat}}</span></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <!-- </div> -->

                  <!-- <div class="view_graph" *ngIf="tabvalue == 'graph'"> -->
                  <div class="graph_div" *ngIf="tabvalue == 'graph'">
                    <div class="row" *ngIf="processName!=null ">
                      <div class="col-lg-6 col-12 mt-3">
                        <div class="card h-100 dashboard-card">
                          <div class="card-header">
                            <h3 class="card-title text-uppercase">{{'date_wise_inbetween_total_tat'|translate}}</h3>
                          </div>
                          <div class="card-body">
                            <canvas baseChart [datasets]="dateWiseTotalTat" width="100%" height="50px"
                              [labels]="dateLabels" [options]="barChartOptions" [plugins]="dateWiseChartPlugins"
                              [legend]="dateWiseChartLegend" [chartType]="barChartType" [colors]="barChartColors">
                            </canvas>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-12 mt-3">
                        <div class="card h-100 dashboard-card">
                          <div class="card-header">
                            <h3 class="card-title text-uppercase">{{'Inbetween_stage_wise_average_tat'|translate}}</h3>
                          </div>
                          <div class="card-body">
                            <canvas baseChart [data]="processAverageTatData" width="100%" height="50px"
                              [labels]="processLabels" [chartType]="pieChartType" [options]="piechartoptions"
                              class="pie-chart">
                            </canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card card-dashbaord">
      <div class="card-header">
        <h3 class="card-title"> {{ "plant" | customTranslate}} - {{"Vehicles" | customTranslate}} </h3>
      </div>

      <div class="card-body">
        <div class="p-0">
          <div class="row info-group" [ngClass]="{'info-group-long':vehicleList.length > 5}"
            *ngIf="vehicleList.length;">
            <div class="col circle-col" *ngFor="let item of vehicleList; let i = index">
              <div class="info-circle" [ngClass]="(companyinfoColor[i % 5])">
                <div class="info-block left">
                  <h3 class="info-number">
                    {{item.vehicle_count}}
                  </h3>

                  <div class="info">
                    <p>{{item.plant_name}}</p>
                  </div>
                </div>

                <div class="info-block right">
                  <div class="icon" [ngClass]="(companyinfoColor[i % 5])">
                    <i class="fa fa-truck"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-data-not-found *ngIf="!vehicleList.length"></app-data-not-found>

        </div>
      </div>

    </div>
  </div>

</div>

<div class="row">

  <div class="col-md-6 mt-4">

    <div class="card">

      <div class="card-header">
        <div class="header-block">
          <h3 class="card-title">
            {{ "plant" | customTranslate}} {{"Type" | customTranslate }}
          </h3>
        </div>
      </div>

      <div class="card-body">
        <table class="custom-table">
          <thead>
            <tr>
              <th>{{"s.no." | customTranslate}}</th>
              <th>{{"plant" | customTranslate}}</th>
              <th>{{"Tat" | customTranslate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sector of plantTatList;index as i">
              <td> {{i+1}} </td>
              <td>{{sector.plant_name}}</td>
              <td>{{sector.tat}}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>

  <div class="col-md-6 mt-4">

    <div class="card">

      <div class="card-header">
        <div class="header-block">
          <h3 class="card-title">
            {{"PLANT-WISE AVG TAT" | customTranslate}}
          </h3>
        </div>
      </div>

      <div class="card-body">
        <table class="custom-table">
          <thead>
            <tr>
              <th>{{"s.no." | customTranslate}}</th>
              <th>{{ "plant" | customTranslate}} {{"Type" | customTranslate }}</th>
              <th>{{"Count" | customTranslate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let plant of sectorWisePlantData;index as i">
              <td> {{i+1}} </td>
              <td>{{plant.plant_type}}</td>
              <td>{{plant.count}}</td>
            </tr>
          </tbody>
        </table>



      </div>

    </div>

  </div>
  <div class="col-md-12" style="margin-top: 23px;">
    <div class="card">
      <div class="card-header">
        <div class="header-block">
          <h3 class="card-title">{{"Plants Average TAT" | customTranslate}}</h3>
        </div>
        <div class="chart-toggler" *ngIf="reportType.length">
          <div>
            <mat-form-field appearance="fill">
              <mat-label> {{"filter" | customTranslate}} </mat-label>
              <mat-select [(value)]="tattype">
                <mat-option *ngFor="let type of reportType;index as i" (click)="gettatindex(i)" [value]="type">
                  {{type == 'Weekly' ? 'Weekly':type=='Monthly'?'Monthly':'Yearly' }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
      </div>
      <div class="card-body">
        <div *ngIf="lineChartData.length">
          <div class="chart-info">
            <p class="info"> <span> {{"chart type" | customTranslate}}: </span> <span class="labels"> {{lineChartType}}</span>
            </p>
            <p class="info"> <span> {{"report" | customTranslate}}: </span> <span class="labels"> {{ tatfilter }}</span> </p>
          </div>
          <div style="width:100%;overflow-x: auto;height: 300px;">
            <canvas baseChart width="1000" height="250" [datasets]="
            lineChartData" [labels]="lineChartLabels" [options]="barChartOptions" [colors]="lineChartColors"
              [legend]="lineChartLegend" [chartType]="lineChartType" [colors]="barChartColors"></canvas>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

import { TmgService } from './../tmg.service';
import { optionFilter, optionmenu, TAToptionFilter } from './../data';
import { TmgDashboardService } from './../tmg-dashboard.service';
import { TmgchartService } from './../tmgchart.service';
import { DetailService } from './../detail-modal/detail.service';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TableService } from 'src/app/@core/services/table.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import {triplist,vendortrip,besttrips,producttripoptions,productwisetrip,tripcountfilter} from '../data';
import { UserService } from 'src/app/@core/services/user.service';
@Component({
  selector: 'app-trip-dashboard',
  templateUrl: './trip-dashboard.component.html',
  styleUrls: [
    './trip-dashboard.component.scss',
    '../tmg-dashboard.component.scss',
  ],
})
export class TripDashboardComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 3, 'Method Management');
  triplist = triplist;
  optionFilter = optionFilter;
  optionmenu = optionmenu;
  vendortrip = vendortrip;
  besttrips = besttrips;
  producttripfilter = producttripoptions;
  productwisetrip = productwisetrip;
  selected: string = '';
  tripchart: any = [];
  TAToptionFilter = TAToptionFilter;

  worsttrip: any = [];
  plannedtat: any = [];
  daywisetrip: any = [];
  tripstatus: any = [];
  canceledTrips: any = [];
  triplabels: any = [];

  worstTripViewParam: any;
  worstTripDetailView: any = [];

  tripStatusDetailParam: any;
  tripStatusDetail: any = [];

  TripstatusdayWiseDetailparam: any;
  CanceledTripsParam: any;
  CenceledTripsDetail: any = [];
  completeVsCancelledTrip: any = [];

  @ViewChild(DataTableDirective, { static: false })
  tripcountfilter = tripcountfilter;

  tripChartLabels: Label[] = [];
  tripChartOptions: ChartOptions & {} = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            precision: 0,
            beginAtZero: true,
            // stepSize:5,
          },
        },
      ],
    },
  };
  tripChartColors: Color[] = [
    this.chart.barChartcolors.danger,
    this.chart.barChartcolors.primary,
  ];

  tripChartLegend = true;
  tripChartType: ChartType = 'bar';

  tripChartData: ChartDataSets[] = [
    { data: [], label: '' },
    { data: [], label: '' },
  ];

  public TATChartData: ChartDataSets[] = [];
  public TATChartLabels: Label[] = [];
  public TATChartOptions: ChartOptions & {} = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            precision: 0,
            beginAtZero: true,
            // stepSize:5,
          },
        },
      ],
    },
  };
  public TATChartColors: Color[] = [
    this.chart.lineChartcolors.primary,
    this.chart.lineChartcolors.danger,
  ];
  public TATChartLegend = true;
  public TATChartType: ChartType = 'line';

  constructor(
    public chart: TmgchartService,
    public detail: DetailService,
    public tmg: TmgService,
    public tmgdashboard: TmgDashboardService,
    public api: ApiService,
    private table: TableService,
    public userService: UserService
  ) {
    this.selected = this.producttripfilter[0];
  }
  getActiveTab(event) {
    this.dtOptions = this.table.options(10, 3, 'Worst Trip Status');
    this.dtTrigger.unsubscribe();
    this.dtTrigger.next();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  //  default worst trip data
  getWorstTripdata() {
    let paramsdata = this.tmg.DateResponse('Days', 7);

    this.getworstTrip(paramsdata);
  }

  getPlannedtatdata() {
    let { toDate, fromDate, processId } = this.tmg.DateResponse('Month', 3);
    let param = { toDate, fromDate, processId };

    this.getplannedtat(param);
  }

  // default daywise trip status
  daywisetripstatus() {
    let { toDate, fromDate, processId } = this.tmg.DateResponse('Days', 7);
    let param = {
      toDate,
      fromDate,
      processId,
      type: 'limit',
    };

    this.tmgdashboard.getWorstTrip(param).subscribe((item:any) => {
      if(item.status){
      this.worsttrip = item.data ?? [];
      }
    });
  }

  // default tripchart View
  gettripchartdetail() {
    let params = this.tmg.DateResponse('Month', 3);
    this.gettripchartdata(params);
  }

  gettripchartdata(event: any) {
    this.tripChartLabels = [];
    this.completeVsCancelledTrip = [];
    this.api
      .get(
        'api/complete_vs_cancelled_trips_weekly?processId=' + event.processId
      )
      .subscribe((item: any) => {
        if(item.status){
        this.completeVsCancelledTrip = item.data ?? [];
        this.completeVsCancelledTrip.map((trips: any, index: number) => {
          trips.values.map((values: any, i: number) => {
            this.tripChartData[i].data?.push(values.statusCount);
          });
          this.tripChartLabels.push(trips.date);
          this.completeVsCancelledTrip = item.data;
        });
        this.completeVsCancelledTrip[0].values.map(
          (label: any, index: number) => {
            this.tripChartData[index].label = label.status;
          }
        );
      }
      });

  }

  getworstTrip(event: any) {
    this.api
      .get('api/worst_trip_status?processId=' + event.processId)
      .subscribe((item: any) => {
        if(item.status){
        this.worsttrip = item.data ?? [];
        }
      });
  }

  getplannedtat(event: any) {
    this.api
      .get('api/tat_vs_planned_weekly?processId=' + event.processId)
      .subscribe((item: any) => {
        if(item.status){
        this.plannedtat = item.data ?? [];
        this.TATChartLabels = this.chart.chartlabelTransform(
          'date',
          this.plannedtat
        );

        this.TATChartLabels = [];
        this.TATChartData = [];
        this.TATChartLabels = this.chart.chartlabelTransform(
          'date',
          this.plannedtat
        );
        let chartdata = this.chart.chartDataTransform('date', this.plannedtat);
        chartdata.map((item: any) => {
          this.TATChartData.push({
            data: item.data,
            label: item.label,
          });
        });
      }
      });
  }

  getTripstatusDaywiseData() {
    let param = this.tmg.DateResponse('Days', 3);
    this.gettripstatusDaywise(param);
  }

  gettripstatusDaywise(event: any) {
    this.tripStatusDetailParam = event;
    delete event.type;
    this.tmgdashboard.gettripstatusDaywise(event).subscribe((item:any) => {
      if(item.status){
      this.daywisetrip = item.data;
      }
    });
  }

  getTripstatusdayWiseDetail(selector: any) {
    this.tripStatusDetailParam.type = 'all';
    this.tmgdashboard
      .gettripstatusDaywise(this.tripStatusDetailParam)
      .subscribe((item:any) => {
        if(item.status){
        this.tripStatusDetail = item.data ?? [];
        this.detail.openDialog(
          this.tripStatusDetail,
          'Snapshot - last 3 Day Detail',
          selector
        );
      }
      });
  }

  getTripStatusData() {
    let param = this.tmg.DateResponse('Days', 1);
    this.gettripstatus(param);
  }

  gettripstatus(event: any) {
    this.tripStatusDetailParam = event;
    delete event.type;
    this.tmgdashboard.gettripStatus(event).subscribe((item: any) => {
      if(item.status){
      this.tripstatus = item.data;
      }
    });
  }

  getTripStatusDetail(selector: any) {
    this.tripStatusDetailParam.type = 'all';
    this.tmgdashboard
      .gettripStatus(this.tripStatusDetailParam)
      .subscribe((item: any) => {
        if(item.status){
        this.tripStatusDetail = item.data ?? [];
        this.detail.openDialog(
          this.tripStatusDetail,
          'Live Snapshot - 24 hours Detail',
          selector
        );
        }
      });
  }

  selectedate(event: any) {}

  worsttrips(event: any) {
    delete event.type;
  }

  tripcanceledData() {
    let param = this.tmg.DateResponse('Days', 7);
    this.tripcanceled(param);
  }

  tripcanceled(event: any) {
    this.CanceledTripsParam = event;

  }

  tripcanceledDetail(selector: any) {
    this.CanceledTripsParam.type = 'all';
  }

  ngOnInit(): void {
    this.gettripchartdetail();
    this.getTripStatusData();
    this.getWorstTripdata();
    this.daywisetripstatus();
    this.getPlannedtatdata();
    this.getTripstatusDaywiseData();
    this.tripcanceledData();
    this.dtTrigger.next();
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div>
                <div class="phl-dabz">
                  <h1 style="color: white;">Can we help you?</h1>
                  <div class="search_box">
                    <input type="text" placeholder="Search For Menu">
                    <button>Search</button>
                  </div>
                </div>

                <div class="second-box">
                  <div class="left-box"></div>
                  <span class="center-text">Congratulations</span>
                  <div class="right-box"></div>
                </div>
              </div>

              <div class="accordion">
                <div class="accordion-section" *ngFor="let section of accordionSections"
                  [ngClass]="{'active': section.active}">
                  <div class="accordion-title" [ngClass]="{'active-title': section.active}"
                    (click)="toggleAccordion(section)">
                    Plant
                    <span [ngClass]="{'rotate': section.active}">
                      <i class="fa fa-chevron-down"></i>
                    </span>
                  </div>
                  <div class="accordion-content" *ngIf="section.active">
                    <ul class="dropdown-options">
                      <li _ngcontent-car-c1102="" class="ng-star-inserted" (click)="openHelpInfo()">Plant Setting</li>
                      <li _ngcontent-car-c1102="" class="ng-star-inserted" (click)="openHelpInfo()">Loader View</li>
                      <li _ngcontent-car-c1102="" class="ng-star-inserted" (click)="openHelpInfo()">Yard View</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { validator } from 'src/app/dataType/commonInterface';
import { companyData, customForms, formTypes, plantsData } from 'src/app/dataType/formInterface';
import { Process} from 'src/app/dataType/commonInterface';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { UserService } from 'src/app/@core/services/user.service';
import { COMMON_ACTION_BUTTON, TABLE_TYPE, TableActions, TableColumns } from 'src/app/dataType/customTableInterface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-management',
  templateUrl: './form-management.component.html',
  styleUrls: ['./form-management.component.scss'],
})
export class FormManagementComponent implements OnInit {
  TABLE_TYPE = TABLE_TYPE.DATATABLE
  loading: boolean = false;
  columns: TableColumns[] = [];
  id: number = null;
  formTitle: string;
  formDescription: string;
  formTypeValidator: validator;
  formTitleValidator: validator;
  formDescriptionValidator: validator;
  processValidator: validator;
  formStatusValidator:validator
  customForms: customForms[];
  processes: Process[];
  processId: number;
  processName: string;
  formTypeName: string
  formTypes: formTypes[] =[];
  formTypeId: number;
  showProcesses: boolean = false;
  companyId:number = null
  companyName:string = null
  plantId:number = null
  plantName:string = null
  companyData:companyData[] = []
  plantData:plantsData[] = []
  formStatus:string = "ACTIVE"
  isEdit:boolean = false
  constructor(
    private table: TableService,
    private common: CommonService,
    private api: ApiService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private breadcrumbService:BreadcrumbService,
    public role:RoleService,
    public plantService:PlantService,
    private userService:UserService,
  ) {}

  ngOnInit(): void {
    this.getAllCustomForms();
    this.getAllFormTypes();
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'form', url: null },
      {
        name: 'Form Management',
        link: null,
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    }
    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlants(this.userService._loggedInUser.companyId);
    }
    this.companyId = this.userService._companyId
    this.plantId = this.userService._plantId
  }

  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlants(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }
  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlants(event.companyId);
    this.plantId = null
    this.plantName = null
    this.getAllCustomForms();
  }
  selectedPlant(event: any) {
    console.log(this.plantId)
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getAllCustomForms();
  }
  getAllFormTypes() {
    let params = {
      isActive:true
    }
    this.common.loading = true;
    this.api.get('form/get-all-form-types',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.formTypes = res.data;
        if (this.formTypes && this.formTypes.length > 0) {
          this.formTypeName = this.formTypes[0].form_type_name;
          this.formTypeId = this.formTypes[0].id;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllProcesses() {
    let params = {
      processActive: true,
      plantId:this.plantId
    };
    this.common.loading = true;
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.processes = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  reset() {
    this.id = null;
    this.formTitle = null;
    this.formDescription = null;
    this.processName = null;
    this.showProcesses = false;
    this.formTypeValidator = null
    this.formTitleValidator = null
    this.processValidator = null
    if(this.role.user_role=='AXESTRACK'){
    this.companyId = null
    this.companyName = null
    }
    if(this.role.user_role=='AXESTRACK'||this.role.user_role=='COMPANYADMIN'){
    this.plantId = null
    this.plantName = null
    this.plantData=null
    if(this.companyId!=null){
      this.getPlants()
    }
    this.formStatus = "ACTIVE"
  }
  if(!this.isEdit){
    this.getAllCustomForms();
    this.getAllFormTypes();
  }else{
    this.isEdit = !this.isEdit
  }
  }

  save() {
    this.formTitle = this.formTitle? this.formTitle.trim():this.formTitle;
    this.formDescription = this.formDescription? this.formDescription.trim():this.formDescription;
    let body = {
      id: this.id,
      formTitle: this.formTitle,
      formDescription: this.formDescription,
      formTypeId: this.formTypeId,
      extraData: { ref_id: this.formTypeName=='Visitor Form'?null: this.processId },
      plantId:this.plantId,
      companyId:this.companyId,
      status:this.formStatus
    };
    this.common.loading = true;
    this.api.post('form/save-custom-form', body).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.reset();
          // this.getAllCustomForms();
          this.getAllFormTypes();
        }else{
          this.alertService.error(res.message)
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  selectedFormType(selectedItem: any) {
    if(selectedItem.form_type_name!='Visitor Form'){
      this.showProcesses = true
      this.getAllProcesses();
      console.log(this.companyId,this.plantId)
      if((this.role.user_role=='AXESTRACK'||this.role.user_role=='COMPANYADMIN') &&(this.companyName ==null || this.plantName ==null)){
        this.alertService.warn("Select company and plant fields")
      }
    }
    // selectedItem.form_type_name == 'Visitor Form' ? false : this.getAllProcesses();
    this.formTypeName = selectedItem.form_type_name;
    this.formTypeId = selectedItem.id;
    this.processId = null

  }
  selectedProcess(selectedItem: any) {
    this.processId = selectedItem.processId;
    this.processName = selectedItem.processName;
  }

  getAllCustomForms() {
    this.loading = true
    let params = {
      formType : 'all',
      companyId:this.companyId,
      plantId:this.plantId,
      isActive:false
    }
    console.log(params)
    this.common.loading = true;
    this.api.get('form/get-custom-forms',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.customForms = res.data;
        this.columns = [
          {
            columnLabel: "Form Type",
            columnName: "form_type_name",
          },
          {
            columnLabel: "form_title",
            columnName: "form_title",
          },
          {
            columnLabel: "status",
            columnName: "status",
          },
          {
            columnLabel: "form_description",
            columnName: "form_description",
          },
          {
            columnLabel: "Process",
            columnName: "process_name",
          },
          {
            columnLabel: "Action",
            columnName: null,
            dataActions: [
              ...this.table.getTableActionButtons([
                COMMON_ACTION_BUTTON.VIEW,
                COMMON_ACTION_BUTTON.EDIT,
                COMMON_ACTION_BUTTON.DELETE
              ],(column: TableColumns, row: any, index: number, actionname: COMMON_ACTION_BUTTON) => {
                if(actionname == COMMON_ACTION_BUTTON.EDIT) {
                  console.log("Hello Edit ")
                  this.editForm(row);
                } else if(actionname == COMMON_ACTION_BUTTON.VIEW) {
                  this.viewForm(row);
                } else {
                  this.confirmAlert(row);
                }
              }, null, null, (column: TableColumns, row: any, index: number, actionname: COMMON_ACTION_BUTTON) => {
                if(actionname == COMMON_ACTION_BUTTON.VIEW) return true;
                if(this.role.user_role =='AXESTRACK') {
                  return true;
                }else if(((this.companyId==row.company_id && row.plant_id==this.plantId)||(this.companyId==row.company_id && row.plant_id==null)) && this.role.user_role=='COMPANYADMIN') {
                  return true;
                }
                else if(this.companyId==row.company_id && row.plant_id==this.plantId && this.role.user_role!='AXESTRACK' && this.role.user_role!='COMPANYADMIN'){
                  return true
                }
                else {
                  return false;
                }
              })
            ] as TableActions[]
          }
        ]
        // this.columns = this.columns.map(column=>{
        //   if(column.dataActions){
        //     return {
        //       ...column,
        //       // dataActions:column.dataActions.filter(action=>{
        //       //   if(this.userService._companyId==)
        //       // })
        //     };
        //   }
        // })

      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;


      }
    );
  }

  viewForm(item: any) {
    let updatedItem = {
      formType: item.form_type_name,
      formTitle: item.form_title,
      formDescription: item.form_description,
      processName: item.process_name,
      formStatus:item.status
    };
    this.common.params = {
      details: [updatedItem],
      title: 'Form Details',
    };
   this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  editForm(item: any) {
    this.isEdit = true
    this.common.gotoTop();
    this.reset();
    this.id = item.id;
    this.formTitle = item.form_title;
    this.formDescription = item.form_description;
    this.formTypeId = item.form_type_id
    if(item.form_type_name!='Visitor Form'){
      this.showProcesses = true;
      this.getAllProcesses();
      this.processName = item.process_name;
    }
    this.formTypeName = item.form_type_name;
  }
  confirmAlert(item: any) {
    this.common.params = {
      title: 'Delete Form',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.id = item.id;
        this.deleteCustomForm();
      }
    });
  }

  deleteCustomForm() {
    let params = {
      id: this.id,
    };
    this.common.loading = true;
    this.api.get('form/delete-custom-form', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.reset();
          // this.getAllCustomForms();

        }else{
          this.alertService.error(res.message)
          this.reset();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  formValidator() {
    this.formTypeValidator = this.common.nullValidator(
      this.formTypeName,
      'Form Type Mandatory'
    );
    this.formStatusValidator = this.common.nullValidator(
      this.formStatus,
      'Form Status Mandatory'
    );
    if(this.formTitle!=null){
      this.formTitle = this.formTitle.trim();
    }
    this.formTitleValidator = this.common.nullValidator(
      this.formTitle,
      'Form Title Mandatory'
    );
    if(this.showProcesses){
      this.processValidator = this.common.nullValidator(
      this.processName,
      'Process Name Mandatory'
    );
  }
  console.log(this.formTypeValidator,this.formTitleValidator)
    if (
      !this.formTitleValidator.error &&
      (this.showProcesses ? !this.processValidator.error : true)
    ) {
      this.save();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-product-dock-linking',
  templateUrl: './product-dock-linking.component.html',
  styleUrls: ['./product-dock-linking.component.scss']
})
export class ProductDockLinkingComponent implements OnInit {
  responseData:any=[];
  productList:any=[];
  mapping={
    productId:null,
    destinationId:null
  }
  constructor(public api:ApiService, public alert:AlertService) {
    this.getDockList();
    this.getProductList();
   }

  ngOnInit(): void {
  }

  getDockList(){
    this.api.get("dock/get-all-active-docks").subscribe((res: any) => {
      if (res.status) {
        this.responseData = res.data;
      }
    });
  }
  getProductList(){
    this.api.get("product/get-product-list?allProducts=true").subscribe((res: any) => {
      if (res.status) {
        this.productList = res.data;
      }
    });
  }
  selectProduct(event:any,i:any){
    this.mapping.productId=event.id;
    this.mapping.destinationId=this.responseData[i].stage_destination_code_id;
    this.responseData[i].product_name=event.product_name;
    this.responseData[i].id=event.id;
    this.saveMapping();
  }
  saveMapping(){
    this.api.post("dock/save-product-dock-mapping",this.mapping).subscribe((response:any) => {
      if (response.status) {
        this.alert.success(response.message);
      } else {
        this.alert.error(response.message);
      }

    }, (error) => {
      console.log(error);
    });
  }

}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <ps-auto-suggestion class="search" [data]="filterRow.assetFilter" display="entity_name"
                    (onSelected)="filterAsset($event)" placeholder="Choose Assets" isMultiSelect="true">
                  </ps-auto-suggestion>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <ps-auto-suggestion class="search" [data]="filterRow.zoneFilter" display="zone_name"
                    (onSelected)="filterZone($event)" placeholder="Choose Zone" isMultiSelect="true">
                  </ps-auto-suggestion>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <ps-auto-suggestion class="search" [data]="filterRow.stageFilter" display="stage_name"
                    (onSelected)="filterStage($event)" placeholder="Choose Stage" isMultiSelect="true">
                  </ps-auto-suggestion>
                </div>
                <div class="form-group col-xl-2 col-lg-4 col-sm-8">
                  <input type="date" class="search-date search" name="dueDate" [(ngModel)]="dueDate"
                    placeholder="Choose Date" (change)="selectedDate(dueDate)">
                </div>
                <div class="form-group col-xl-1 col-lg-2 col-sm-4">
                  <button class="btn btn-warning" (click)="resetDetails()"><i class="fa fa-repeat mr-2"></i>reset</button>
                </div>
              </div>
            </div>
            <div class="card-body checklist_admin_dash" style="margin-top: -15px;">
              <div class="row my-2 mx-0">
                <div class="col-12 p-0"
                  style="box-sizing: border-box;display: inline-flex;margin: 4px 0px;box-sizing: border-box;border-radius: 5px;padding-right:10px !important;">
                  <div class="dock_over_left entity-list">
                    <ng-container *ngFor="let item of assetTrackingData;let i = index">
                      <div class='dock_over_card' [ngClass]="{'active_card_dock': assetSelected != null && item.entity_name == assetSelected.entity_name}" *ngIf="(filterData.assetNames.length && filterData.assetNames.includes(item.entity_name) || !filterData.assetNames.length) &&
                      (filterData.zoneIds.length && filterData.zoneIds.includes(item.zone_id) || !filterData.zoneIds.length) &&
                      (filterData.stageIds.length && filterData.stageIds.includes(item.stage_id) || !filterData.stageIds.length)" (click)="selectedAsset(item)">
                        <div class="dock_row">
                          <p class="header"><i class="fa fa-archive mr-1"></i>{{item.entity_name}}</p>
                          <span [ngClass]="{'early mr-2':item.prod_hour != null,'delay mr-2':item.prod_hour == null}"
                            style="font-size: 11px!important;">{{item.prod_hour == null ? '00:00' : item.prod_hour}}
                            <i class="fa fa-clock"></i></span>
                        </div>

                        <div class="dock_row mt-3">
                          <span style="font-size:11px" *ngIf="item.last_log != null">Last Log : <span
                              class="info-jellybean ml-1 p-1"
                              style="font-size: 10px !important;padding: 1px 4px !important;">{{item.last_log}}
                              <i class="fa fa-clock ml-1"></i></span> <span
                              class="badge status-badge badge-overdue ml-2">{{item.stage_name}}
                              ({{item.zone_name}})</span> </span>
                          <span *ngIf="item.last_log == null" style="font-size:11px !important"> Last Log :<span
                              class="badge status-badge badge-overdue ml-2"> N/A</span> </span>
                        </div>
                      </div>
                    </ng-container>
                  </div>

                  <div class="dock_over_right col-9">
                    <div class="map" id="map-asset" style="width: 98%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="modal" id="detailModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="cards_row" style="background-color: #ececec;border-radius: 12px;">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
              <span>&times;</span>
            </button>
            <div class="table-responsive" *ngIf="viewMarkerData.length>0">
              <table class="table-responsive-alert" style="max-height: 450px;overflow-y: scroll;display: block;">
                <tr class="table-alert" style="position: sticky;top:0">
                  <th>Asset Name</th>
                  <th>Productive Hours</th>
                  <th>Last Ping</th>
                </tr>
                <tbody class="table-alert-list">
                  <tr *ngFor="let asset of viewMarkerData">
                    <td>{{asset.entity_name}}</td>
                    <td>{{asset.prod_hour == null ? '00:00' : asset.prod_hour}}</td>
                    <td>{{asset.last_log}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="viewMarkerData.length===0" class="text-center mt-1 fade-in"
              style="font-weight: 600; color: #cd4040;font-size: larger;">No Asset Tracked!</div>
          </div>
        </div>
      </div>
    </div>
  </div>

import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Label, ThemeService } from 'ng2-charts';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { AlertCardModalComponent } from '../alert-card-modal/alert-card-modal.component';
import { DatePipe } from '@angular/common';
import lodash from 'lodash';
import { ChartDataSets, ChartType, ChartOptions,Chart } from 'chart.js';
import Highcharts, { chart } from 'highcharts';
import { DashboardModalComponent } from '../dashboard-modal/dashboard-modal.component';
import { DashboardService } from '../dashboard/dashboard.service';
import { AlertData, cAlertArray, dashboardData, DeptDoNutData, Deptwisedata, groupwisedata, MostVisitsStaff, tableData, VehicleComplianceData, VisitorStatusCount } from 'src/app/dataType/visitorInterface';
import {  StaticChartData, STATUS_CARD } from './data';
import { DonutChartOptions, StaticDonutChartData, StaticDonutChartOptions } from '../static-chart-data';
import { Process } from 'src/app/dataType/commonInterface';


@Component({
  selector: 'app-visitor-admin-dashboard',
  templateUrl: './visitor-admin-dashboard.component.html',
  styleUrls: ['./visitor-admin-dashboard.component.scss']
})
export class VisitorAdminDashboardComponent implements OnInit {
  @ViewChild('deptChartContainer', { static: true }) deptChartContainer: ElementRef<HTMLDivElement>;
  @ViewChild('grpChartContainer', { static: true }) grpChartContainer: ElementRef<HTMLDivElement>;
  activeSection: number = 0;
  tabvalue: String = 'staff';
  MostVisitTitle:string="Most Visited Staff"
  cardData = STATUS_CARD;


   //global variables
    dashboardData:dashboardData[]=[];
    statusWiseVisitorCount:VisitorStatusCount[]=[];
    mostVisitData:MostVisitsStaff[]=[];
    deptWiseData: Deptwisedata[] = [];
    grpWiseData: groupwisedata[] = [];
    deptDoNutData: DeptDoNutData[] = [];
    grpDoNutData: DeptDoNutData[] = [];
    deptColors: { [key: string]: string } = {};
    grpColors: { [key: string]: string } = {};
    groupedDataByType: AlertData[] = [];
    alertDashboardData: cAlertArray[] = [];
    activeAlert: string = 'Critical';
    cAlertArray:cAlertArray[] = [];
    visitorChart:Chart|null=null;
    stagewisetat:Chart|null=null;
    allProcess: Process[] = [];
    parkingDefaultProcess: string = '';
    processId:number=null;
    alertDonut: Highcharts.Chart;



    //visitor modal
    tableModalData:tableData[]=[];
    modalTitle:string='';


    vehicleComplianceChartData: any[] = [];
    public vehicleComplianceChartLabels: Label[] = [];
    public vehicleComplianceChartPlugins = [];
    public vehicleComplianceChartType: ChartType = 'line';
    public vehicleComplianceChartLegend = true;


    public barChartOptions: ChartOptions = {
      plugins: {
        datalabels: {
          formatter: (value, context) => {
            return this.datePipe.transform(value,'HH:mm');
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,

      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Avg TAT',
              fontSize: 12,
              fontStyle: 'bold',
              fontFamily: ' sans-serif',
            },

            time: {
              unit: 'hour',
              displayFormats: {
                second: 'HH:mm',
                minute: 'HH:mm',
                hour: 'HH:mm',
              },
              // displayFormats: {"HH:mm"},
              tooltipFormat: 'HH:mm',
            },
            ticks: {
              callback:  (v)=> {
                return this.datePipe.transform(v,'HH:mm ');
              },
              // stepSize: 30 * 120
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label:  (tooltipItem, data)=> {
          console.log('tooltipIte: ', tooltipItem);
            return (
              data.datasets[tooltipItem.datasetIndex].label +
              ': ' +
              this.datePipe.transform(tooltipItem.yLabel,'HH:mm')
            );
          },
        },
      },
    };

  constructor(
      public userService: UserService,
      public common: CommonService,
      public table: TableService,
      public api: ApiService,
      public modalService: NgbModal,
      public breadcrumbService: BreadcrumbService,
      public role: RoleService,
      public plantService : PlantService,
      public alert : AlertService,
      public workflow: DashboardService,
      public datePipe:DatePipe
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
    this.getAlertDashboardData();
  }


  ngOnInit():void {

  }

  ngAfterViewInit(){
    this.getDashboardCount();
    this.getAllProcess();
    this.changeTab('staff');
  }


  getAllProcess() {
    this.common.loading = true;
    let params={
      processActive:true,
      processType:'Visit'
    }
    this.api.get('process/get-all-process',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.allProcess = res.data;
          this.parkingDefaultProcess=this.allProcess[0].processName;
          this.getStageWiseTat(this.allProcess[0].processId)
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }


  selectedProcess(event){
    this.processId=event.processId;
    this.getStageWiseTat(this.processId);
  }
  //modal for visitor dashboard


  getStatusWisevisitordata(status,modalTitle) {
    let params={
      status:status
    }
    this.modalTitle=modalTitle;
    this.common.loading = true;
    this.api.get('visitor-admin/get-status-wise-visitor-data',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.tableModalData = res.data;
          let headers=['Visitor Name','Meet With','Status','visit From','Start Time','End Time','Purpose','Info'];
          let keys=['visitor_name','meet_with','status','visit_from','start_time','end_time','purpose']
          this.viewDashboardMoadal(headers,keys,this.tableModalData);
          console.log('this.tableModalData: ', this.tableModalData);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }


  getDeptGrpWisevisitordata(id,modalTitle,type) {
        let params={
          id:id,
          type:type
        }
        this.modalTitle=modalTitle;
        this.common.loading = true;
        this.api.get('visitor-admin/get-dept-grp-wise-visitor-data',params).subscribe(
          (res: any) => {
            this.common.loading = false;
            if (res.status) {
              this.tableModalData = res.data;
              let headers=['Visitor Name','Meet With','Status','visit From','Start Time','End Time','Purpose','Info'];
              let keys=['visitor_name','meet_with','status','visit_from','start_time','end_time','purpose']
              this.viewDashboardMoadal(headers,keys,this.tableModalData);
            }
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
          }
        );
  }


  viewDashboardMoadal(headers:any,keys:any,tableModalData:any){
    const activeModal = this.modalService.open(DashboardModalComponent, {
      size: 'xl',
      windowClass:'custom-modal-width'
    });
    activeModal.componentInstance.refData={
      header:headers,
      keys:keys,
      tableModalData:tableModalData,
      modalTitle:this.modalTitle,
      modalType:'Visit'
    }
  }



  getStageWiseTat(processId) {
  console.log('processId: ', processId);
    let params={
      processId:processId
    }
    this.api.get('stage/stage-wise-tat', params).subscribe((res: any) => {
      if (res.status) {
        this.vehicleComplianceChartData = res.data[0].values.map((value: any, i: number) => ({
          data: res.data.map((vehicle: any) => vehicle.values[i].avg),
          label: value.stage_name,
          backgroundColor: StaticChartData.backgroundColors[i],
          borderColor: StaticChartData.borderColors[i]
        }));

        this.vehicleComplianceChartLabels = res.data.map((vehicle: any) => vehicle.date);
        this.loadStageWiseTatChart();
      }
    });
  }

  loadStageWiseTatChart(){
    this.stagewisetat?.destroy()
    const config:any={
      type:'line',
      data: {
        labels: this.vehicleComplianceChartLabels,
        datasets: this.vehicleComplianceChartData,
      },
      options: this.barChartOptions, // You can keep the existing chart options
    };
     this.stagewisetat=new Chart(document.getElementById('stagewisetat') as HTMLCanvasElement,config)
    }


  loadChart(graph_data): void {

    const config: any = {
      type: 'bar',
      data: {
        labels:  graph_data.map(item => item.month),
        datasets: [{
            label: 'Number of Visitors',
            data: graph_data.map(item => item.count),
            backgroundColor: StaticChartData.backgroundColors,
            borderColor: StaticChartData.borderColors,
            borderWidth: 1,
            barThickness:40,
        }]
    },
      options: StaticChartData.options
    };

    this.visitorChart?.destroy();
    this.visitorChart=new Chart( ( document.getElementById('visitorsChart') as HTMLCanvasElement ).getContext('2d') , config);
  }


  getDashboardCount(){
    this.common.loading = true;
    this.api.get("visitor-admin/get-visitor-admin-dashboard-count").subscribe(
      (res:any)=>{
        this.common.loading = false;
        this.dashboardData=res.data[0];
        this.setData(this.dashboardData);
      },
      (err:any)=>{
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  changeTab(value:string){
    this.tabvalue=value;
    this.common.loading = true;
    let params={
      visitFor:value
    }
    this.api.get("visitor-admin/get-most-visit-data",params).subscribe(
      (res:any)=>{
        this.common.loading = false;
        this.mostVisitData=res.data
        this.loadChart(this.mostVisitData[0]['graph_data'])
      },
      (err:any)=>{
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }


  setData(DashboardData){
     this.statusWiseVisitorCount=DashboardData["VisitorStatusCount"];
     this.deptWiseData = DashboardData["DeptwiseCount"];
     this.deptWiseData.forEach((el: any) => {
       let data = {
         label: el.dept_name,
         value: el.visitor_count
       };
       this.deptDoNutData.push(data);
     });
     this.drawDonutChart(this.deptDoNutData, this.deptChartContainer.nativeElement, 'dept');
     this.grpWiseData = DashboardData["GroupwiseCount"];
     this.grpWiseData.forEach((el: any) => {
       let data = {
         label: el.group_name,
         value: el.visitor_count
       };
       this.grpDoNutData.push(data);
     });
     this.drawDonutChart(this.grpDoNutData, this.grpChartContainer.nativeElement, 'grp');
  }



  drawDonutChart(chartData: any[], container: HTMLDivElement, flag: any) {
  console.log('chartData: ', chartData);
  const colorPalette = StaticDonutChartData.colorPalette;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = 150;
  canvas.height = 150;
  container.appendChild(canvas);

  const chart = new Chart(ctx, {
    type: 'doughnut',
    data: {
      labels: chartData.map(data => data.label),
      datasets: [{
        data: chartData.map(data => data.value),
        backgroundColor: chartData.map((data, index) => {
          if (flag == 'dept') {
            if (!this.deptColors[data.label]) {
              this.deptColors[data.label] = colorPalette[index % colorPalette.length];
            }
            return this.deptColors[data.label];
          } else {
            if (!this.grpColors[data.label]) {
              this.grpColors[data.label] = colorPalette[index % colorPalette.length];
            }
            return this.grpColors[data.label];
          }
        }),
        hoverBackgroundColor: chartData.map((data, index) => {
          if (flag == 'dept') {
            return this.deptColors[data.label];
          } else {
            return this.grpColors[data.label];
          }
        })
      }]
    },
    options: DonutChartOptions
  });
  }

  selectedAlert(alertCard, index) {
    this.activeAlert = alertCard.type_name;
    this.alertDonut.series[0].points[index].onMouseOver();
    this.alertDonut.tooltip.refresh(this.alertDonut.series[0].points[index]);
    this.getAlertDashboardData(alertCard.alert_type_id);
  }

  getAlertDashboardData(alertTypeId: number = -1) {
    this.common.loading = true;
    let params = {
      onlyCount: alertTypeId == -1 ? true : null,
      alertTypeId: alertTypeId,
      mapOn:"Visitor"
    };
    this.api.get('alert/get-alert-dashboard-data', params).subscribe(
      (res: any) => {
        this.common.loading = false;

        if (res.status) {
          if (alertTypeId != -1) {
            this.cAlertArray = res.data;
          } else {
            this.groupedDataByType = res.data;
            console.log('this.groupedDataByType: ', this.groupedDataByType);

            let dataAlert = [];
            res.data.forEach((element) => {
              dataAlert.push({
                name: element.type_name,
                y: element.type_name_count,
                color: element.type_color,
              });
            });
            this.createDonut3DChart(dataAlert);
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  createDonut3DChart(data: any) {
    const options: Highcharts.Options = { ...StaticDonutChartOptions };

    options.series = [
      {
        name: 'Alert Count',
        data: data, // Your dynamic data
      } as Highcharts.SeriesPieOptions
    ];

    // Initialize the chart
    this.alertDonut = Highcharts.chart('alert-donut', options);
    this.selectedAlert(this.groupedDataByType[0], 0);
  }

  viewAlert(alertData) {
    let data = JSON.parse(alertData);
    const activeModal = this.modalService.open(AlertCardModalComponent, {
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      alert_type_name: data.title,
      alert_remark:data.remark,
      alert_data: data.data,
      showAlertData : true
    };
  }

}

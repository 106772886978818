<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body stageType-form">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Hardware Health" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"Log Start Date" | translate}} </label>
                  <input type="date" class="form-control" [(ngModel)]="logStartDate" (ngModelChange)="dateValidator()">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"Log End Date" | translate}} </label>
                  <input type="date" class="form-control" [(ngModel)]="logEndDate" (ngModelChange)="dateValidator()">
                </div>

                <div *ngIf="role.user_role == 'AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"company" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                </div>

                <div *ngIf="(role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN')"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantData"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                </div>
              </div>


              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-warning" (click)="validators()">{{"Find" |
                    translate}}</button>
                </div>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{'Sr.No' | translate}}</th>
                    <th>{{"Vehicle No." | translate}}</th>
                    <th>{{"First log" | translate}}</th>
                    <th>{{"First Tracked Destination" | translate}}</th>
                    <th>{{"Last log" | translate}}</th>
                    <th>{{"Last Tracked Destination" | translate}}</th>
                    <th>{{"Tag" | translate}}</th>
                    <th>{{"Action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of hardwareData;let i = index">
                    <td>{{i+1}}</td>
                    <td appEllipsisView>{{row.vehicle_rc_no ?? '-'}}</td>
                    <td>{{row.firstLog}}</td>
                    <td>{{row.firstTrackedDestination ?? '-'}}</td>
                    <td>{{row.lastLog ?? '-'}}</td>
                    <td>{{row.lastTrackedDestination ?? '-'}}</td>
                    <td>{{ row.tag_id }}</td>
                    <td class="action"><button class="btn btn-success" (click)="openLogData(content,row)"> {{'View Details'|translate}}
                    </button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content modal>
  <div class="card">
    <div class="card-body">
      <div class="card-header">
        <div class="header-block" style="display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;">
          <h3 class="card-title">
            {{"Trip Tag Log"}}
          </h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <section class="timeline_area section_padding_130">
        <div class="container">
          <div class="row">
            <!-- <div class="col-12"> -->
            <div class="main-timeline-area">
              <div class="single-timeline-area" *ngFor="let data of viewLogData;let i=index;let last = last"
                [style]="'--border-color: #8CC1FF;--color: #8CC1FF85'">
                <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s"
                  style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                  <button class="button-45" role="button">{{data.addtime}}</button>
                </div>
                <div class="row" style="margin-left:20px">
                  <div class="col-12">
                    <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s"
                      style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                      <div class="timeline-text" style="display: flex;flex-direction: column;">
                        <span style="font-size: 12px;margin-bottom: 4px;width:fit-content"><span
                            style="font-size: 12px;margin-right:3px;font-weight: 600;">Destination :</span> {{data.destination}}</span>
                        <div style="display:flex">
                          <span
                            style="font-size: 12px;margin-right:3px;font-weight: 600;">Stages :</span><ng-container *ngFor="let stageData of data.stage_data"><span class="info-jellybean" style="font-size:10px;margin: 1px;">{{stageData.stage_name}}</span></ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</ng-template>

<div class="container-fluid">

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="container-card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <div class="header-block">
                    <h3 class="card-title">
                      {{'asset_request_list'|customTranslate}}
                    </h3>
                  </div>
                </div>

                <div class="card-body">
                  <div class="tab_section" *ngIf="listActionCount.length">
                    <div class="tab" [class.active]="activeTab==3"
                      (click)="getAssetRequestData('PENDING');activeTab=3;setActiveStatus()">{{"Pending"
                      | customTranslate}} ({{listActionCount['PENDING']}})</div>
                    <div class="tab" [class.active]="activeTab==1"
                      (click)="getAssetRequestData('APPROVED');activeTab=1;setActiveStatus()">{{"Approved" |
                      customTranslate}} ({{listActionCount['APPROVED']}})</div>
                    <div class="tab" [class.active]="activeTab==2"
                      (click)="getAssetRequestData('REJECTED');activeTab=2;setActiveStatus()">{{"Rejected" |
                      customTranslate}} ({{listActionCount['REJECTED']}})</div>
                    <div class="tab" [class.active]="activeTab==4"
                      (click)="getAssetRequestData('ASSIGNED');activeTab=4;setActiveStatus()">{{"Assigned" |
                      customTranslate}} ({{listActionCount['ASSIGNED']}})</div>
                    <div class="tab" [class.active]="activeTab==6"
                      (click)="getAssetRequestData('CHECKEDOUT');activeTab=6;setActiveStatus()">{{"CheckedOut" |
                      customTranslate}} ({{listActionCount['CHECKEDOUT']}})</div>
                    <div class="tab" [class.active]="activeTab==5"
                      (click)="getAssetRequestData('CHECKEDIN');activeTab=5;setActiveStatus()">{{"CheckedIn" |
                      customTranslate}} ({{listActionCount['CHECKEDIN']}})</div>
                  </div>

                  <div class="tab_content">
                    <div class="checklist_sec">
                      <div class="row" *ngIf="activeTab == 5 || activeTab == 2"
                        style="margin-bottom: 10px;">
                        <div class="col-md-3">
                          <label>{{'start_date:'|customTranslate}}</label>
                          <input type="date" class="form-control" (change)="getAssetRequestData(activeStatus)"
                            [(ngModel)]="startDate">
                        </div>
                        <div class="col-md-3">
                          <label>{{'end_date:'|customTranslate}}</label>
                          <input type="date" class="form-control" (change)="getAssetRequestData(activeStatus)"
                            [min]="startDate" [(ngModel)]="endDate">
                        </div>
                      </div>
                    </div>

                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{"s.no." | customTranslate}}</th>
                          <th>{{"from_date" | customTranslate}}</th>
                          <th>{{"to_date" | customTranslate}}</th>
                          <th>{{"Requesting For" | customTranslate}}</th>
                          <th>{{"Assets" | customTranslate}}</th>
                          <th>{{"priority" | customTranslate}}</th>
                          <th class="action">{{"action" | customTranslate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of requests; let i = index">
                          <td>{{i+1}}</td>
                          <td>{{row.from_date|date:'dd-MM-yyyy'}}</td>
                          <td>{{row.end_date|date:'dd-MM-yyyy'}}</td>
                          <td>{{row.req_for_entity+' : '+row.ref_entity_name}}</td>
                          <td><span class="badge badge-success"
                            *ngFor="let Asset of row.asset_name">{{Asset}}
                            </span></td>
                          <td><span
                              [ngClass]="row.priority=='Low'? 'badge badge-pill badge-low':(row.priority=='Critical'?'badge badge-pill badge-danger':(row.priority=='High'?'badge badge-pill badge-warning':'badge badge-pill badge-primary'))">{{row.priority}}</span>
                          </td>
                          <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-success" *ngIf="activeTab==1"
                                (click)="updateRequestStatus(row.asset_request_id,'ASSIGNED',row.assets_id)"> {{'Assign'|customTranslate}}
                              </button>
                              <button class="btn btn-success" *ngIf="activeTab==6"
                                (click)="openCheckInCheckOutModal(true,row.asset_request_id,'CHECKEDIN',row.assets_id,row.req_for_entity,row.ref_id)"> {{'CheckIn'|customTranslate}}
                              </button>
                              <button class="btn btn-success" *ngIf="activeTab==4"
                                (click)="openCheckInCheckOutModal(false,row.asset_request_id,'CHECKEDOUT',row.assets_id,row.req_for_entity,row.ref_id)"> {{'CheckOut'|customTranslate}}
                              </button>
                              <button class="btn btn-danger" *ngIf="activeTab==1 || activeTab==4"
                                (click)="updateRequestStatus(row.asset_request_id,'REJECTED',row.assets_id)"> {{'Reject'|customTranslate}}
                              </button>
                              <button class="btn btn-primary" [style]="{'background-color':'#0e56b7'}"
                                (click)="viewRemarks(row)"> {{'View Details'|customTranslate}}
                              </button>
                              <button class="btn btn-info" (click)="linkCopy(row)">
                                {{'Copy'|customTranslate}}
                              </button>
                            </div>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h4 class="card-title" *ngIf="mappedFields.length!=0">
                  {{'entry_form'|translate}}
                </h4>
                <h5 class="card-sub-title" *ngIf="mappedFields.length!=0 && mappedFields[0].form_description!=null">
                  {{mappedFields[0].form_description}}
                </h5>
              </div>

              <div class="col-md-5 d-flex pull-right" class="ml-2">
                <div class="form-group col-xl-12 col-lg-12 col-sm-12">
                      <label style="font-size: 12px;">{{'process'|translate}}</label>
                      <ps-auto-suggestion (onSelected)="selectProcess($event)" [data]="processList" display="processName"
                        placeholder="Select Process" [preSelected]="{processName:selectedProcessName}">
                      </ps-auto-suggestion>
                    </div>
              </div>
            </div>
            <div class="card-body" style="background-color: #f1f1f1;">

              <!-- <div class="row"> -->
              <div class="form_layout" *ngIf="mappedFields.length">
                <div class="border">
                  <div class="page_name" *ngIf="mappedFields.length">
                    <h3>{{selectedProcessName}}</h3>
                  </div>
                  <ng-container *ngFor="let field of mappedFields ; index as i">
                    <div class="col-md-12 modal_col" *ngIf="field.field_type!='LABEL'">
                      <div class="field" *ngIf="field.field_type=='TEXTFIELD' && (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <input type="text" class="field_class" (change)="enterValues($event , field , i)">
                      </div>

                      <div class="field"
                        *ngIf="field.field_type=='TEXTFIELD' &&(field.sel_value!=null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}</label>
                        <input type="text" class="field_class" [value]="field.sel_value" disabled>
                      </div>

                      <div class="field" *ngIf="field.field_type=='NUMBERFIELD'&& (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <input type="number" class="field_class" (change)="enterValues($event , field , i)">
                      </div>

                      <div class="field"
                        *ngIf="field.field_type=='NUMBERFIELD'&&(field.sel_value!=null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}</label>
                        <input type="number" class="field_class" [value]="field.sel_value" disabled>
                      </div>

                      <div class="field" *ngIf="field.field_type=='TIME'&& (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <input type="time" class="field_class" (change)="enterValues($event , field , i)">
                      </div>

                      <div class="field"
                        *ngIf="field.field_type=='TIME'&&(field.sel_value!=null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}</label>
                        <input type="time" class="field_class" [value]="field.sel_value" disabled>
                      </div>

                      <div class="field" *ngIf="field.field_type=='URL'&& (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <input type="url" class="field_class" (change)="enterValues($event , field , i)">
                      </div>

                      <div class="field"
                        *ngIf="field.field_type=='URL'&&(field.sel_value!=null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}</label>
                        <input type="url" class="field_class" [value]="field.sel_value" disabled>
                      </div>

                      <div class="field" *ngIf="field.field_type=='DATETIMEPICKER'&& (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <input type="datetime-local" class="field_class" (change)="enterValues($event , field , i)">
                      </div>

                      <div class="field"
                        *ngIf="field.field_type=='DATETIMEPICKER'&&(field.sel_value!=null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}</label>
                        <input type="datetime-local" class="field_class" [value]="field.sel_value" disabled>
                      </div>

                      <div class="field" *ngIf="field.field_type=='FILE'&& (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <input type="file" accept=".jpg,.png,.jpeg,.pdf,.xlsx,.xlsm,.xls" class="field_class file_class"
                          multiple (change)="enterValues($event , field , i)">
                      </div>

                      <div class="field"
                        *ngIf="(field.field_type=='FILE'||field.field_type=='GEOCODEDIMAGE')&&(field.sel_value!=null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}</label>
                        {{imageName(field.sel_value)}}
                        <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                          <img [src]="image_url" alt="" style="width: 30px;"><span style="font-size:small">{{"
                            "+this.image_name}}</span>
                        </ng-container>
                        <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                          <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                            text-decoration-line: underline;" (click)="newTab(image_url)">{{"
                            "+this.image_name}}</span>
                        </ng-container>
                      </div>

                      <div class="field"
                        *ngIf="(field.field_type=='GEOCODEDIMAGE'||field.field_type=='FILE')&& (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <app-camera-component class="camera" [retake]="retakeImage"
                          (getPicture)="handleImage($event, field,i)">
                        </app-camera-component>
                        <div style="display: flex; flex-direction: column; align-items:center;"
                          *ngIf="this.formValues[i]?.values?.length">
                          <p>Upload Done! <button class="btn btn-warning"
                              (click)="retakeImage = !retakeImage;this.formValues[i].values=[];">Retake</button>
                          </p>
                          <!-- <img style="height: 200px;" [src]="this.formValuesList[ind].formValues[i].values[0]" /> -->
                        </div>
                      </div>

                      <div class="field" *ngIf="field.field_type=='TEXTAREA'&& (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <textarea rows="3" cols="50" (change)="enterValues($event , field , i)"
                          class="field_class"></textarea>
                      </div>

                      <div class="field"
                        *ngIf="field.field_type=='TEXTAREA'&&(field.sel_value!=null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}</label>
                        <textarea rows="3" cols="50" [value]="field.sel_value" disabled class="field_class"></textarea>
                      </div>

                      <div class="field" *ngIf="field.field_type=='DATEPICKER'&& (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <input type="date" class="field_class" (change)="enterValues($event , field , i)">
                      </div>

                      <div class="field"
                        *ngIf="field.field_type=='DATEPICKER'&&(field.sel_value!=null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}</label>
                        <input type="date" class="field_class" [value]="field.sel_value" disabled>
                      </div>

                      <div class="field r_field" *ngIf="field.field_type=='RADIOBUTTON'&& (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <div *ngFor="let opt of field.options;index as j" class="radio_check">
                          <input type="radio" [name]="'field-' + field.master_field_id"
                            [id]="'field-' + j + field.master_field_id" value="{{opt.name}}"
                            (change)="enterValues($event , field , i)">&nbsp;<label
                            [for]="'field-' + j + field.master_field_id">{{opt.name}}</label>
                        </div>
                      </div>

                      <div class="field r_field"
                        *ngIf="field.field_type=='RADIOBUTTON'&&(field.sel_value!=null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}</label>
                        <div *ngFor="let opt of field.options;index as j" class="radio_check">
                          <input type="radio" [name]="'field-'+ j + field.master_field_id"
                            [id]="'field-'+ j + field.master_field_id" [checked]="field.sel_value == opt.name"
                            disabled>&nbsp;<label [for]="'field-'+ j + field.master_field_id">{{opt.name}}</label>
                        </div>
                      </div>

                      <div class="field c_field" *ngIf="field.field_type=='CHECKBOX'&& (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <div *ngFor="let opt of field.options" class="radio_check">
                          <input type="checkbox" value="{{opt.name}}" [name]="'field'+i + field.master_field_id"
                            [id]="'field'+i + field.master_field_id"
                            (change)="enterValues($event  , field , i)">&nbsp;<label
                            [for]="'field'+i + field.master_field_id">{{opt.name}}</label>
                        </div>
                      </div>

                      <div class="field c_field"
                        *ngIf="field.field_type=='CHECKBOX'&&(field.sel_value!=null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}</label>
                        <div *ngFor="let opt of field.options" class="radio_check">
                          <input type="checkbox" [checked]="field.sel_value == opt.name"
                            disabled>&nbsp;<label>{{opt.name}}</label>
                        </div>
                      </div>

                      <div class="field c_field" *ngIf="field.field_type=='SIGNATURE'&& (field.sel_value==null )"
                        (mouseleave)="saveSignature(i,field)">
                        <label>{{field.field_name}}</label>
                        <app-signature class="signature" (getSignature)="getSignature=$event"></app-signature>
                      </div>

                      <div class="field c_field"
                        *ngIf="field.field_type=='SIGNATURE'&&(field.sel_value!=null && field.database_linked_id!=null)">
                        {{imageName(field.sel_value)}}
                        <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                          <img [src]="field.sel_value" alt="" style="width: 30px;">
                        </ng-container>
                      </div>

                      <div class="field"
                        *ngIf="field.field_type=='SELECTLISTSINGLE'&& (field.sel_value==null && field.database_linked_id==null)">
                        <label>{{field.field_name}}</label>
                        <ps-auto-suggestion (onSelected)="enterValues($event, field, i)" [data]="field.options"
                          [display]="field.label" placeholder="Choose option">
                        </ps-auto-suggestion>
                      </div>

                      <div class="field"
                        *ngIf="field.field_type=='SELECTLISTSINGLE'&& (field.sel_value!=null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}+"s"</label>
                        <select class="field_class" disabled>
                          <option *ngFor="let opt of field.options" [selected]="opt[field.store_value]==field.sel_value"
                            [value]="opt[field.store_value]">
                            {{opt[field.label]}}</option>
                        </select>
                      </div>

                      <div class="field"
                        *ngIf="field.field_type=='SELECTLISTSINGLE'&& (field.sel_value==null && field.database_linked_id!=null)">
                        <label>{{field.field_name}}</label>
                        <select class="field_class" *ngIf="field.sel_value==null" [(ngModel)]="selectedOption"
                          (change)="handleSingleSelectDBField(selectedOption, field, i)">
                          <option></option>
                          <option *ngFor="let opt of field.options" [value]="opt[field.store_value]">
                            {{opt[field.label]}}</option>
                        </select>
                      </div>

                      <div class="field" *ngIf="field.field_type=='SELECTLISTMULTIPLE'&& (field.sel_value==null )">
                        <label>{{field.field_name}}</label>
                        <ps-auto-suggestion isMultiSelect="true" (onSelected)="enterValues($event, field, i)"
                          [data]="field.options" [display]="field.label" placeholder="Choose option">
                        </ps-auto-suggestion>

                      </div>
                      <div class="field"
                        *ngIf="field.field_type=='SELECTLISTMULTIPLE'&&(field.sel_value!=null && field.database_linked_id!=null)">

                        <label>{{field.field_name}}</label>

                        <ps-auto-suggestion isMultiSelect="true" (onSelected)="enterValues($event, field, i)"
                          [data]="field.multiValueOptions" [display]="field.label" placeholder="Choose option">
                        </ps-auto-suggestion>

                      </div>
                    </div>
                    <div class="col-md-12 modal_col" *ngIf="field.field_type=='LABEL'">
                      {{insertLabel(field, i)}}
                      <div class="field">
                        <label>{{field.field_name}}</label>
                        <p style="font-size: small" [innerHTML]="field.options[0].name"></p>
                      </div>
                    </div>
                  </ng-container>


                  <div class="stage-setting-footer" *ngIf="mappedFields.length">
                    <div class="btn-block d-flex  justify-content-end">
                      <button class="btn ml-2 btn-success text-uppercase"
                        (click)="saveFormResponse()">{{'save'|translate}} </button>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="mappedFields.length==0">
                <app-data-not-found></app-data-not-found>
              </ng-container>
              <!-- </div> -->

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


</div>

import { ApiService } from './../../@core/services/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-zone-type',
  templateUrl: './zone-type.component.html',
  styleUrls: ['./zone-type.component.scss'],
})
export class ZoneTypeComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'Zone Type List');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  zoneType = {
    zoneTypeId: null,
    zoneTypeName: '',
    status: 'ACTIVE',
  };
  title = 'Add Zone Type';
  btn = 'save';
  data: any[] = [];

  constructor(
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public api: ApiService,
    public alertService: AlertService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Zone', url: null },
      {
        name: 'Zone Type',
        link: '/user/zone-type-management',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getAllZoneType();
  }

  ngViewInit() {}

  getAllZoneType() {
    this.common.loading = true;
    this.api.get('zone/get-all-zone-type').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.data = res.data;
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  saveZoneType() {
    this.common.loading = true;
    this.api.post('zone/save-zone-type', this.zoneType).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alertService.info(res.message);
        this.getAllZoneType();
        this.resetDetail();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  resetDetail() {
    this.zoneType = {
      zoneTypeId: null,
      zoneTypeName: '',
      status: 'ACTIVE',
    };
    this.zoneTypeValidator = null;
    this.statusValidator = null;
    this.btn = 'save';
    this.title = 'Add Zone Type';
  }

  editZoneType(row?: any) {
    this.common.gotoTop();
    this.resetDetail();
    this.zoneType.zoneTypeId = row.id;
    this.zoneType.zoneTypeName = row.typeName;
    this.zoneType.status = row.status;
    this.btn = 'update';
    this.title = 'Edit Zone Type';
  }
  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'info' };

    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  zoneTypeValidator: any;
  statusValidator: any;
  formValidator() {
    this.zoneType.zoneTypeName = this.zoneType.zoneTypeName.trim();
    this.zoneTypeValidator = this.common.nullValidator(
      this.zoneType.zoneTypeName,
      'Zone Type is Mandatory'
    );
    if (!this.zoneTypeValidator.error) {
      this.saveZoneType();
    }
  }

  deleteZoneType(row: any) {
    let params = {
      zoneTypeId: row.id,
      zoneTypeName: row.typeName,
      status: 'DELETED',
    };
    this.api.post('zone/save-zone-type', params).subscribe(
      (res: any) => {
        if (res.status) {
          if ((res.messsage = 'Zone Type is Mapped with Zone')) {
            this.alertService.warn(res.message);
          } else this.alertService.info(res.message);
          this.getAllZoneType();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Zone ',
      description:
        `<b>&nbsp;` + 'Are you sure you want to delete zone?' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteZoneType(row);
      }
    });
  }
}

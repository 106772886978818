<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'asset tracking report' |customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="page_design">
                <div class="row tab_sec m-0">
                  <div class="col-md-6 col-12 date_div p-0">
                    <div class="row">
                        <div class="col-md-5 date">
                          <label>{{'Date:'|customTranslate}}</label>
                          <input type="date" class="form-control"
                            [(ngModel)]="reportDate" (change)="getAssetTrackingReport()">
                        </div>

                    </div>
                  </div>
                </div>

                <div class="view_table">
                  <div class="filter_sec">
                    <div class="row">
                      <!-- <div class="col-md-3 coll" *ngIf="role.user_role=='COMPANYADMIN'" class="form-group col-md-3">
                        <label for="my-input" class="required">{{"plant" | customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                          placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                        </ps-auto-suggestion>
                      </div> -->
                      <div class="col-md-3 coll">
                        <label>{{'select zone:'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectZone($event)" [data]="zoneList" isMultiSelect="true"
                          display="zone_name" placeholder="Select Zone" [preSelected]="temp">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'select stage:'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectStage($event)" [data]="stageList" display="stage_name" isMultiSelect="true"
                          placeholder="Select Stage" [preSelected]="temp">
                        </ps-auto-suggestion>
                      </div>
                    </div>
                  </div>

                  <div class="form-group action-block mt-2">
                    <div class="btn-block">
                      <button type="button" class="excel-btn excel"
                      (click)="exportAsExcel(assetTrackingReportData,'Tag Destination TAT Report')">
                      <i class="fas fa-file-excel"></i>
                      </button>
                      <button class="btn btn-warning" (click)="resetPage()"> {{'reset'|customTranslate}} </button>
                    </div>
                  </div>

                  <div class="table_sec">
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>S. No.</th>
                          <th>{{'Asset'|customTranslate}}</th>
                          <th>{{'Assigned To'|customTranslate}}</th>
                          <th>{{'First Ping'|customTranslate}}</th>
                          <th *ngFor="let header of headers">{{header}}  </th>
                          <th>{{'Last Ping'|customTranslate}}</th>
                          <th>{{'Tracked Hrs'|customTranslate}}</th>
                          <th>{{'Untracked Hrs'|customTranslate}}</th>
                          <th>{{'Total TAT'|customTranslate}}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let item of assetTrackingReportData;let i=index">
                           <td>{{i+1}}</td>
                           <td style="min-width: 190px;">{{item.asset_name}}</td>
                           <td>{{item.requestedentity}}</td>
                           <td>{{item.plant_in}}</td>
                           <!-- <td *ngFor="let dest of headers">
                            <span [ngClass]="item.tat_data[dest[valueKey]]?.zone_allocation=='not assigned'?'badge over': 'badge info'">{{item.tat_data[dest[valueKey]]?.spend_hours ?? '00:00:00'}}</span>
                           </td>
                           <td> <span class="badge over">{{item.tat_data[0]?.spend_hours ?? '00:00:00'}}</span></td> -->
                           <td *ngFor="let header of headers">
                            <span [ngClass]="item.tracking_data[header]== '00:00:00'?'badge over': 'badge info'">{{item.tracking_data[header] || 'N/A'}}</span>

                            <!-- <span class="badge over">{{item.tracking_data[header] || 'N/A'}}</span> -->
                          </td>
                           <td>{{item.plant_out}}</td>
                           <td><span class="badge tracked">{{item.tracked_hrs}}</span></td>
                           <td><span class="badge untracked">{{item.untracked_hrs}}</span></td>
                           <td><span class="badge info">{{item.total_tat}}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

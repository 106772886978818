import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class StageService extends ApiService{

  saveStage(data: any): Observable<any> {
    let apiSubUrl = "stage/save_stage";

    return super.post(apiSubUrl, data);
  }
  getAllStage(): Observable<any> {
    let apiSubUrl = "stage/get-all-stage";

    return super.get(apiSubUrl);
  }

  getStageById(params: any): Observable<any> {
    let apiSubUrl =  "stage/get-all-stage";
    params.allStage = false;
    return super.get(apiSubUrl, params);
  }

  getAllActiveAndInactiveStages(plantId?){
    let apiSubUrl = "stage/get-all-stage?allStage=true";
    if(plantId){
      apiSubUrl = apiSubUrl + '&plantId='+plantId;
    }
    return super.get(apiSubUrl);
  }

  getStageByProcessAndPlant(params:any){
      let apiSubUrl = "stage/stage_by_process";
      return super.get(apiSubUrl,params);
  }



}

<table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
  <thead>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Website</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let group of data">
      <td>{{group.name}}</td>
      <td>{{group.email}}</td>
      <td>{{group.website}}</td>
    </tr>
  </tbody>
</table>

<div class="container-fluid">
  <div class="row" *ngIf="tripGraphShow">
    <div class="col-md-12">

      <form class="settings mt-3" *ngIf="showSettings">
        <div class="row">
          <div class="col-md-2">
            <mat-form-field>
              <mat-select placeholder="Layout" [ngModel]="layout" (ngModelChange)="setLayout($event)" name="layout">
                <mat-option *ngFor="let l of layouts" [value]="l.value">
                  {{l.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-select placeholder="Line Curve Interpolation" [ngModel]="curveType"
                (ngModelChange)="setInterpolationType($event)" name="curve">
                <mat-option *ngFor="let curveType of interpolationTypes" [value]="curveType">
                  {{curveType}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <div class="setting-checks">
              <mat-checkbox [(ngModel)]="panOnZoom" class="d-flex" name="panOnZoom">Pan while zooming</mat-checkbox>
              <mat-checkbox [(ngModel)]="autoZoom" class="d-flex" name="autoZoom">Zoom to Fit </mat-checkbox>
              <mat-checkbox [(ngModel)]="autoCenter" class="d-flex" name="autoCenter">Auto Center </mat-checkbox>
            </div>

          </div>
        </div>


      </form>
    </div>
    <div class="col-md-12">
      <div class="page">
        <ngx-graph [links]="links" [nodes]="nodes" [clusters]="clusters"
          [curve]="curve" [draggingEnabled]="false" [panningEnabled]="panningEnabled" [enableZoom]="zoomEnabled"
          [zoomSpeed]="zoomSpeed" [minZoomLevel]="minZoomLevel" [maxZoomLevel]="maxZoomLevel" [panOnZoom]="panOnZoom"
          [autoZoom]="autoZoom" [autoCenter]="autoCenter" [update$]="update$" [center$]="center$"
          [zoomToFit$]="zoomToFit$">

          <ng-template #defsTemplate>
            <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4"
              orient="auto">
              <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
            </svg:marker>
          </ng-template>

          <ng-template #nodeTemplate let-node let-index>
            <svg:g class="node" (click)="getNode(node,detailModal)">
              <svg:rect [attr.width]="node.dimension.width + 60" [attr.height]="150"
                style="stroke:pink;stroke-width:5;opacity:0.5" [attr.fill]="nodeColors[nodeIndex(node.id) % 7]" />
              <svg:text font-weight="bold" font-size="20px" [attr.x]="40" [attr.y]="60">
                {{node.label}}
              </svg:text>
              <svg:text [attr.x]="getXPosition(node.dimension.width)" [attr.y]="90">
                Exp. TAT: {{node.expected_tat}}
              </svg:text>

              <!-- <svg:text [attr.x]="getXPosition(node.dimension.width)" [attr.y]="110">
                Act. TAT: {{node.average_tat}}
              </svg:text> -->
            </svg:g>
          </ng-template>

          <ng-template #linkTemplate let-link>
            <svg:g class="edge">
              <svg:path class="line"
                [attr.d]="'M ' + (link.source.x + 60) + ' ' + link.source.y + ' L ' + (link.target.x + 50) + ' ' + link.target.y"
                stroke-width="4" marker-end="url(#arrow)">
              </svg:path>
            </svg:g>
          </ng-template>
        </ngx-graph>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!tripGraphShow">
    <div class="col-md-12 pt-3 pb-1">
      <app-data-not-found></app-data-not-found>
    </div>
  </div>
</div>

<ng-template #detailModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title"> Trip Detail </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12 text-right">
        <input type="search" class="form-control" [(ngModel)]="vehicleNumber" placeholder="Search Vehicle"
          (input)="SearchVehicle()">
      </div>
    </div>

    <div class="detail-container">
      <div *ngIf="detailData?.length">
        <div class="row vehicle-detail" *ngFor="let vehicle of detailData;index as i">
          <div class="col-md-12">
            <div class="vehicle-info">
              <div class="vehicle-data">
                <p class="ml-2 mb-0"> {{i + 1}} </p>
                &nbsp;&nbsp;&nbsp;
                <img src="assets/images/truck-plant.png" class="vehicle-icon" alt="">
                <p class="mb-0 ml-4" appEllipsisView> {{vehicle.vehicle_rc_no}} </p>
              </div>

              <div class="v_trip_image pull-right">
                <img class="icon" src="assets/images/trip-details(3).png" alt="" (click)="tripModal(vehicle)">
                <img class="icon" src="assets/images/trip-tracking(3).png" alt="" (click)="tripTrackingModal(vehicle)">
                <app-trip-comment-trail [tripId]="vehicle.trip_id"></app-trip-comment-trail>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!detailData?.length">
      <p style="color:red;display: flex; justify-content:center;">No Data Found !!</p>
    </div>

    <div class="row">
      <div class="col-md-12 text-right mt-3 mb-1">
        <button type="button" class="btn btn-danger" (click)="c('Close click')">Close</button>
      </div>
    </div>
  </div>
</ng-template>

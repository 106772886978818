<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <!-- <div class="card-header">
              <h4 class="card-title">
                User Tracking Dashboard
              </h4>
            </div> -->

            <div class="card-body">
               <div class="col-md-12 pl-1 pr-0 mt-1">
                <div class="table_section">
                  <div  *ngIf="extEntityId==null" class="subtitle">Map View</div>
                  <div class="table-responsive" style="height:650px">
                    <div class="row no-gutters">
                      <div class="col-md-12 mt-2 map-container">
                        <ps-auto-suggestion *ngIf="extEntityId==null" class="search" (onSelected)="selectedUser($event)" [data]="users" display="userName"
                        placeholder="Choose User">
                      </ps-auto-suggestion>
                      <input type="date" *ngIf="extEntityId==null" (change)="selectedDate(dueDate)" class="search1" name="dueDate" [(ngModel)]="dueDate" placeholder="Choose Date" >
                        <div class="map" id="map-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


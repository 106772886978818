import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlantService } from '../plant/plant.service';
import { StageService } from '../stage/stage.service';
import { CommonService } from '../../@core/services/common.service';
import { TableService } from '../../@core/services/table.service';
import { UserService } from '../../@core/services/user.service';
import { ShiftMasterService } from '../shift-master/shift-master.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { ApiService } from '../../@core/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { CompanyService } from '../companies/companies.service';

@Component({
  selector: 'app-user-shift-mapping',
  templateUrl: './user-shift-mapping.component.html',
  styleUrls: ['./user-shift-mapping.component.scss'],
})
export class UserShiftMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'List of Shifts');
  title = 'Shift Allocation';
  processId: any;
  stageData: any = [];
  process: any = [];
  processStages: any[] = [];
  btn = 'save';
  // processName: any=null;
  data: any = [];
  userName: any = null;
  masterField = {
    values: [
      { processName: null, processId: null, shiftName: null, shiftId: null },
    ],
  };

  processWiseShifts: any[] = [];
  userId: any = null;
  companyId: any = null;
  shifts: any = [];
  company: any = [];
  allUserShifts: any = [];
  minStartDate: any = new Date();
  plantList: any[] = [];
  selectedPlant: any[] = [
    {
      plantId: null,
      plantName: null,
    },
  ];

  ngAfterViewInit() {
    this.dtTrigger.next();
    if(this.roleService.user_role != 'AXESTRACK'){
      this.getAllUserShifts();
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  constructor(
    private shiftMasterService: ShiftMasterService,
    private plantService: PlantService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public stageService: StageService,
    private api: ApiService,
    private alertService: AlertService,
    public roleService: RoleService,
    public companyService : CompanyService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Shift Master', url: null },
      { name: 'Shift Allocation', url: '/user/user-shift-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    this.minStartDate.setDate(this.minStartDate.getDate());
    this.minStartDate = `${this.minStartDate.getFullYear()}-${String(
      this.minStartDate.getMonth() + 1
    ).padStart(2, '0')}-${String(this.minStartDate.getDate()).padStart(
      2,
      '0'
    )}`;
  }

  ngOnInit(): void {
    this.getData();
    if (this.roleService.user_role == 'AXESTRACK') {
      this.getCompany();
    }else{
    this.roleService.user_role == 'COMPANYADMIN'
      ? this.getPlantById()
      : this.getAllProcessStages(null, 0);
    }
  }

  shift = {
    shiftStartTime: null,
    shiftEndTime: null,
  };

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          res.data.forEach((element) => {
            if (element.companyStatus == 'ACTIVE') this.company.push(element);
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedCompany(event: any,index:number) {
    this.companyId = event.companyId;
    this.getPlantById(this.companyId,index);
  }

  getPlantById(id?: any,index:any=null) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if(this.roleService.user_role == 'AXESTRACK'){
            this.selectedPlant[index].plantList = res.data;
          }else{
            this.plantList = res.data;
          }
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  onSelectPlant(e: any, index: any) {
    this.selectedPlant[index].plantId = e.plantId;
    this.selectedPlant[index].plantName = e.plantName;
    this.getAllProcessStages(this.selectedPlant[index].plantId, index);
  }

  selectedProcess(event: any, index: any) {
    this.masterField.values[index].processId = event.order_id;
    this.masterField.values[index].processName = event.order_name;
    this.masterField.values[index].shiftId = null;
    this.masterField.values[index].shiftName = '';
    this.getListShiftByProcessStage(event.order_id, index);
  }

  selectedUser(event: any) {
    this.userId = event.userId;
    this.userName = event.name;
  }

  selectedShift(event: any, index: any) {
    this.masterField.values[index].shiftId = event.shift_id;
    this.masterField.values[index].shiftName = event.shift_name;
  }

  getAllProcessStages(selectedPlantId: any, index: any) {
    this.common.loading = true;
    let params = {
      plantId: selectedPlantId,
    };
    this.api.get('process/get-process-stage', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.process = res.data || [];
          if (!this.processStages[index]) {
            this.processStages[index] = this.process;
          } else {
            // Update the array at the index with the new shifts
            this.processStages[index].splice(
              0,
              this.processStages[index].length,
              ...this.process
            );
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getData() {
    this.common.loading = true;
    let apiUrl =
      this.roleService.user_role == 'COMPANYADMIN'
        ? 'user/all_user?userType=COMPANYADMIN'
        : 'user/all_user';
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.data = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  addValues() {
    this.masterField.values.push({
      processName: null,
      processId: null,
      shiftName: null,
      shiftId: null,
    });
    this.selectedPlant.push({ plantId: null, plantName: null });
  }

  getListShiftByProcessStage(orderId: any, index: any) {
    this.common.loading = true;
    this.shiftMasterService.getListShiftByProcessStage(orderId).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.shifts = res.data || [];
          if (!this.processWiseShifts[index]) {
            this.processWiseShifts[index] = this.shifts;
          } else {
            // Update the array at the index with the new shifts
            this.processWiseShifts[index].splice(
              0,
              this.processWiseShifts[index].length,
              ...this.shifts
            );
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }
  saveUserShifts() {
    let params = {
      shiftStartTime: this.shift.shiftStartTime,
      shiftEndTime: this.shift.shiftEndTime,
      userId: this.userId,
      values: this.masterField.values,
    };
    if (params.values.length == 0) {
      this.alertService.error('Please select process stage and shift');
    } else {
      this.common.loading = true;
      this.api.post('shift/add_user_shift_mapping', params).subscribe(
        (res: any) => {
          if (res.status) {
            this.alertService.success(res.message);
            this.common.loading = false;
            this.getAllUserShifts();
            this.resetDetail();
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    }
  }

  getAllUserShifts() {
    this.common.loading = true;
    this.api.get('shift/all_user_shift_mapping').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.allUserShifts = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Shift ',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteShift(row);
      }
    });
  }

  deleteShift(id?: any) {
    this.common.loading = true;
    this.api.get('shift/delete_user_shift_mapping?id=' + id).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertService.success(res.message);
          this.getAllUserShifts();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  resetDetail() {
    this.shift = {
      shiftStartTime: null,
      shiftEndTime: null,
    };
    // this.processName = null;
    this.btn = 'save';
    this.title = 'Shift Allocation';
    this.userId = null;
    this.userName = null;
    if(this.roleService.user_role=='COMPANYADMIN' || this.roleService.user_role == 'AXESTRACK'){
      this.processStages = [];
      this.selectedPlant=[
        {
          plantId: null,
          plantName: null,
        },
      ];
    }
    this.processWiseShifts = [];
    this.masterField.values = [
      { processName: null, processId: null, shiftName: null, shiftId: null },
    ];
    this.userValidator = null;
    this.shiftStartTimeValidator = null;
    this.shiftEndTimeValidator = null;
    this.processStageValidator = [];
    this.shiftValidator = [];
  }

  viewDetails(row?: any) {
    const { id, process_stage_id, shift_id, user_id, ...updatedItem } = row;
    this.common.params = { details: [updatedItem], title: 'info' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  userValidator: any;
  shiftStartTimeValidator: any;
  shiftEndTimeValidator: any;
  processStageValidator: any[] = [];
  shiftValidator: any[] = [];
  formValidator() {
    if (
      this.shift.shiftStartTime < this.minStartDate ||
      this.shift.shiftEndTime < this.shift.shiftStartTime
    ) {
      return this.alertService.error('Shift Time Incorrect...');
    }
    this.userValidator = this.common.nullValidator(
      this.userName,
      'Username Mandatory'
    );
    this.shiftStartTimeValidator = this.common.nullValidator(
      this.shift.shiftStartTime,
      'Shift Start Time Mandatory'
    );
    this.shiftEndTimeValidator = this.common.nullValidator(
      this.shift.shiftEndTime,
      'Shift End Time Mandatory'
    );
    this.masterField.values.forEach((e, index) => {
      this.processStageValidator[index] = this.common.nullValidator(
        e.processId,
        'Process Stage Mandatory'
      );
      this.shiftValidator[index] = this.common.nullValidator(
        e.shiftId,
        'Choose Shift Mandatory'
      );
    });
    if (
      !this.userValidator.error &&
      !this.shiftEndTimeValidator.error &&
      !this.shiftStartTimeValidator.error &&
      !this.processStageValidator.some((validator) => validator.error) &&
      !this.shiftValidator.some((validator) => validator.error)
    ) {
      this.saveUserShifts();
    }
  }
}

import { MapModalComponent } from '../map-modal/map-modal.component';
import { DisplayImageComponent } from '../display-image/display-image.component';
import { Component, Input, OnInit } from '@angular/core';
import {
  NgbDateStruct,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';

@Component({
  selector: 'app-action-info-modal',
  templateUrl: './action-info-modal.component.html',
  styleUrls: ['./action-info-modal.component.scss'],
})
export class ActionInfoModalComponent implements OnInit {
  model: NgbDateStruct;
  actionData: any = null;
  actionType: any = null;
  txnStageDetailId: any = null;
  stageId: any = 0;
  txnId: any = 0;
  currentActionData: any = [];
  actionName: any = '';
  preActionsData: any = [];
  hideShow1: any = [];
  actionId: any = null;
  show = false;
  @Input() public refData;
  image_name: any = '';
  image_url: any = '';
  image_ext: any = '';
  response: any[] = [];
  constructor(
    public common: CommonService,
    private api: ApiService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.actionName = this.refData.actionName;
    this.actionType = this.refData.actionType;
    this.stageId = this.refData.stageId;
    this.txnStageDetailId = this.refData.txnStageDetailId;
    this.txnId = this.refData.txnId;
    this.actionId = this.refData.actionId;
    this.getActionData();
  }

  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }
  getActionData() {
    this.common.loading = true;
    this.api
      .get(
        'dashboard/get-action-details?txnId=' +
          this.txnId +
          '&actionId=' +
          this.actionId +
          '&stageId=' +
          this.stageId +
          '&actionType=' +
          this.refData.action_type +
          '&txnStageDetailId=' +
          this.txnStageDetailId
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.response = res.data;
          console.log('this.response: ', this.response);
          this.preActionsData = res.data[0].preActionsData;
          this.currentActionData = res.data[0].currentActionData;
          this.hideShow1 = [];
          this.preActionsData.forEach((el) => {
            let obj = {
              id: this.hideShow1.length,
              boolean: false,
            };
            this.hideShow1.push(obj);
          });
        },
        (err: any) => {
          this.common.loading = false;
        }
      );
  }
  accordion1(i: any) {
    this.hideShow1.forEach((element) => {
      if (element.id == i && element.boolean == false) {
        element.boolean = true;
      } else if (element.id == i && element.boolean == true) {
        element.boolean = false;
      } else {
        this.activeModal.close();
      }
    });
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  displayImage(url) {
    console.log(url)
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  location(lat, lng) {
    this.common.params = {
      lat: lat,
      lng: lng,
    };
    const activeModal = this.modalService.open(MapModalComponent, {
      size: 'lg',
    });
  }
}

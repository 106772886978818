<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Form Type Management'|translate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" >
                  <label for="my-input" class="required">{{'Form Type'|translate}}</label>
                  <input class="form-control" type="text"  required
                  placeholder="Enter Form Type" [(ngModel)]="formTypeName" #name="ngModel"
                  autocomplete="off">
                  <div class="error" *ngIf="formTypeNameValidator && formTypeNameValidator.error">
                    <span>{{formTypeNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'status'|translate}}</label>
                  <select class="form-control" id="methodType" name="methodType" [(ngModel)]="formStatus">
                    <option value="INACTIVE">INACTIVE</option>
                    <option value="ACTIVE">ACTIVE</option>
                  </select>
                  <div class="error" *ngIf="formStatusValidator && formStatusValidator.error">
                    <span>{{formStatusValidator.msg}} </span>
                  </div>
                </div>
                <div class="row form-group action-block">
                  <div class="btn-block">
                    <button class="btn btn-warning" (click)="reset()"> {{'reset'|translate}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{"Save"|translate}}
                    </button>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive pt-4">
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{'serial_no'|translate}}</th>
                          <th>{{('Form Type'|translate)+' '+('name'|translate)}}</th>
                          <th>{{('status'|translate)}}</th>
                          <th class="action">{{'Actions'|translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let formType of formTypesData;index as i">
                          <td>{{i+1}}</td>
                          <td> {{formType.form_type_name}}</td>
                          <td>{{formType.status}}</td>
                          <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-primary" (click)="viewFormType(formType)">{{'view'|translate}}</button>
                              <button class="btn btn-success" (click)="editFormType(formType)"> {{'edit'|translate}}</button>
                              <button class="btn btn-danger" (click)="confirmAlert(formType)"> {{'delete'|translate}}</button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { UserService } from 'src/app/@core/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { AssetLabelService } from './asset-label.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { TableService } from 'src/app/@core/services/table.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { AssetLabel, CompanyData, PlantData } from 'src/app/dataType/assetInterface';
import { validator } from 'src/app/dataType/commonInterface';
import { ApiService } from 'src/app/@core/services/api.service';

@Component({
  selector: 'app-asset-label',
  templateUrl: './asset-label.component.html',
  styleUrls: ['./asset-label.component.scss'],
})
export class AssetLabelComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'ASSET LABEL LIST');


  //global variables
  plantLevelRoles = ['AXESTRACKUSER' , 'COMPANYUSER' , 'PLANTADMIN', 'PLANTUSER'];
  assetLabel = {
    assetLabelId: null,
    assetLabelName: null,
    assetLabelDesc: null,
    assetLabelStatus: 'ACTIVE',
    companyId: null,
    plantId: null,
    deleteFlag: false,
  };
  company: CompanyData[] = [];
  plantData: PlantData[] = [];
  companyName: string=null;
  plantName: string =null;
  assetLabelNameValidator: validator;
  responseData: AssetLabel[] = [];



  constructor(
    public common: CommonService,
    private userService: UserService,
    private breadcrumbService: BreadcrumbService,
    private assetLabelService: AssetLabelService,
    private modalService: NgbModal,
    private alertService: AlertService,
    public role: RoleService,
    private plantService: PlantService,
    public api:ApiService,
    public table: TableService
  ) {
    /** breadcums implementation start*/
  }

  ngOnInit(): void {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'assets', url: null },
      { name: 'label', url: '/user/asset-label' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);

    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAssetLabels();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  resetWorkType(): void {
    this.assetLabel = {
      assetLabelId: null,
      assetLabelName: null,
      assetLabelDesc: null,
      assetLabelStatus: 'ACTIVE',
      companyId: null,
      plantId: null,
      deleteFlag: false,
    };
    this.companyName = null;
    this.plantName = null;
    if (this.role.user_role == 'AXESTRACK') {
      this.plantData = [];
    }
  }

  selectedCompany(event: any) {
    this.assetLabel.companyId = event.companyId;
    this.companyName = event.companyName;
    this.plantName = null;
    this.assetLabel.plantId = null;
    this.getPlantById(event.companyId);
  }

  selectedPlant(event: any) {
    this.assetLabel.plantId = event.plantId;
    this.plantName = event.plantName;
  }

  getAssetLabels() {
    this.common.loading=true
    let params = {
      status: true,
    };
    this.assetLabelService.getAssetLabel(params).subscribe(
      (res: any) => {
      if (res.status) {
        this.common.loading=false;
        this.responseData = res.data;
        this.renderTable();
      }
    },
    (err:any)=>{
      console.log('err: ', err);
      this.common.loading=false;
    }
  );
  }

  getCompany(){
    this.common.loading=true;
    let params={
      all_company:false
    }
    this.api.get('company/all_company',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.company = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
      );
    }


  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  view(row?: any) {
    let obj = {
      'Asset Label Name': row.asset_label_name,
      'Asset Label Description': row.asset_label_description,
      'Company Name': row.company_name,
      'Plant Name': row.plant_name,
    };

    this.common.params = {
      details: [obj],
      title: 'Asset Label Details',
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Asset Label',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row.asset_label_name +
        '  ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteLabel(row);
      }
    });
  }

  deleteLabel(row) {
    this.assetLabel.assetLabelId = row.asset_label_id;
    this.assetLabel.assetLabelStatus = 'DELETED';
    this.assetLabel.companyId = row.company_id;
    this.assetLabel.plantId = row.plant_id;
    this.assetLabel.deleteFlag = true;
    this.saveLabel();
  }

  edit(row: any) {
    this.assetLabel.assetLabelId = row.asset_label_id;
    this.assetLabel.assetLabelName = row.asset_label_name;
    this.assetLabel.assetLabelDesc = row.asset_label_description;
    this.assetLabel.assetLabelStatus = row.status_name;
    this.assetLabel.companyId = row.company_id;
    this.assetLabel.plantId = row.plant_id;
    if (!(row.company_id == 1 && row.plant_id == 1)) {
      this.plantName = row.plant_name;
      this.companyName = row.company_name;
    } else {
      this.plantName = null;
      this.companyName = null;
    }
    this.role.user_role == 'AXESTRACK'
      ? this.getPlantById(row.company_id)
      : this.role.user_role == 'COMPANYADMIN'
      ? this.getPlantById(this.userService._loggedInUser.companyId)
      : null;
    this.assetLabel.deleteFlag = false;
  }

  formValidator() {
    this.assetLabelNameValidator = this.common.nullValidator(
      this.assetLabel.assetLabelName != null &&
        this.assetLabel.assetLabelName.trim(),
      'Asset Label Name Mandatory'
    );
    if (!this.assetLabelNameValidator.error) {
      this.saveLabel();
    }
  }

  saveLabel() {
    this.common.loading = true;
    this.assetLabelService.saveAssetLabel(this.assetLabel).subscribe(
      (response) => {
        if (response.status) {
          this.common.loading = false;
          this.alertService.success(response.message);
          this.resetWorkType();
          this.getAssetLabels();
        } else {
          this.alertService.error(response.message);
        }
      },
      (error) => {
        console.log('error: ', error);
        this.common.loading = false;
      }
    );
  }
}

import { FormGroup, FormControl } from '@angular/forms';
import { RoleService } from '../../@core/services/role.service';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginCredentialsComponent } from 'src/app/modals/login-credentials/login-credentials.component';
import { LocationService } from 'src/app/@core/services/location.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth/auth.component.scss','./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // longitude: number;
  // latitude: number;
  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(
    evt: KeyboardEvent
  ) {
    this.formValidator();
  }
  user = {
    userName: null,
    password: null,
  };
  constructor(
    public common: CommonService,
    public api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public userService: UserService,
    public cookieServive: CookieService,
    public http: HttpClient,
    private locationService: LocationService,
    public role: RoleService,
    private modalService: NgbModal
  ) {
    if (this.route.snapshot.queryParams.authkey != null) {
      this.api
        .post(
          'user/auto-login?authkey=' +
            encodeURIComponent(this.route.snapshot.queryParams.authkey),
          {},
          null,
          'MAIN',
          this.route.snapshot.queryParams.authkey
        )
        .subscribe(
          (res: any) => {
            this.common.loading = false;
            if (res.status) {
              this.locationService.watchUserLocation();
              const data = res.data[0];
              this.userService._details = data;
              this.userService._token = data.accessToken;
              this.userService._name = data.username;
              this.userService._loginTime = data.loginTime;
              this.userService._sessionId = data.sessionId;
              this.userService._userType = data.userType;

              this.userService._companyId = data.companyId;
              this.userService._plantId = data.plantId;
              this.btndisabled = false;
              this.userService._details._companyId = data.companyId;
              this.userService._details._plantId = data.plantId;

              if (['AXESTRACKUSER', 'COMPANYUSER'].includes(data.userType)) {
                this.userService._companyId =
                  data.userType == 'COMPANYUSER'
                    ? data.companyId
                    : data.externalCompanyId;
                this.userService._details._companyId =
                  this.userService._companyId;
                this.userService._plantId = data.externalPlantId;
                this.userService._details.plantId = data.externalPlantId;
                if (
                  this.userService._companyId == null ||
                  this.userService._plantId == null
                ) {
                  this.openLoginCredentialModal(data);
                  return;
                }
              }
              this.setUserTypeAndLocalStorage(data);
            } else {
              this.loginFailedFlag = true;
              this.showmessage = true;
              this.loginfail = true;
              if (res.data[0] == true) {
                this.loginmessage = 'Invalid Credentials';
              } else {
                this.loginmessage = res.message;
              }
              this.btndisabled = false;
            }
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
            this.showmessage = true;
            this.loginfail = true;
            if (
              err.error.data &&
              err.error.data[0].message == 'INVALID_CREDENTIALS'
            ) {
              this.loginmessage = 'Invalid Credentials';
              this.btndisabled = false;
            } else {
              if (!navigator.onLine) {
                this.loginmessage = 'Check Your Network Connection';
              } else {
                this.loginmessage = 'Something Went Wrong';
              }
            }
            this.btndisabled = false;
          }
        );
    }
  }

  public target: any;

  ngOnInit(): void {
    // this.latitude = this.locationService._position.latitude;
    // this.longitude = this.locationService._position.longitude;
    // this.locationService.position.subscribe((position: any) => {
    //   this.latitude = position.latitude;
    //   this.longitude = position.longitude;
    // });
  }

  userNameValidator: any;
  passwordValidator: any;

  loginform = new FormGroup({
    fusername: new FormControl(),
    password: new FormControl(),
  });

  formValidator() {
    this.userNameValidator = this.common.nullValidator(
      this.user.userName,
      'User Name Mandatory'
    );
    this.passwordValidator = this.common.nullValidator(
      this.user.password,
      'Password Mandatory'
    );
    if (!this.userNameValidator.error && !this.passwordValidator.error) {
      this.login();
    }
  }

  btndisabled: boolean = false;
  loginFailedFlag = false;
  showmessage: boolean = false;
  loginfail: boolean = false;
  loginmessage: string = '';

  login() {
    this.common.loading = true;
    this.btndisabled = true;
    let params = {
      userName: this.user.userName,
      password: this.user.password,
      browser: this.getBrowserName(),
      ipAddress: this.getIpAddress(),
      macAddress: 'fsf:sd:sfs:fs',
      // lat: this.latitude,
      // lng: this.longitude,
      // lat:26.867255499883754,
      // lng:75.78471552735567
    };

    //  localStorage.setItem('USER_TOKEN', "");
    if (!navigator.onLine) {
      this.showmessage = true;
      this.loginfail = true;
      this.loginmessage = 'Check Your Network Connection';
      this.btndisabled = false;
      return false;
    }
    this.api.post('user/login', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.locationService.watchUserLocation();
          const data = res.data[0];
          this.userService._details = data;
          this.userService._token = data.accessToken;
          this.userService._name = data.username;
          this.userService._loginTime = data.loginTime;
          this.userService._sessionId = data.sessionId;
          this.userService._userType = data.userType;

          this.userService._companyId = data.companyId;
          this.userService._plantId = data.plantId;
          this.btndisabled = false;
          this.userService._details._companyId = data.companyId;
          this.userService._details._plantId = data.plantId;

          if (['AXESTRACKUSER', 'COMPANYUSER'].includes(data.userType)) {
            this.userService._companyId =
              data.userType == 'COMPANYUSER'
                ? data.companyId
                : data.externalCompanyId;
            this.userService._details._companyId = this.userService._companyId;
            this.userService._plantId = data.externalPlantId;
            this.userService._details.plantId = data.externalPlantId;
            if (
              this.userService._companyId == null ||
              this.userService._plantId == null
            ) {
              this.openLoginCredentialModal(data);
              return;
            }
          }
          this.setUserTypeAndLocalStorage(data);
        } else {
          this.loginFailedFlag = true;
          this.showmessage = true;
          this.loginfail = true;
          if (res.data[0] == true) {
            this.loginmessage = 'Invalid Credentials';
          } else {
            this.loginmessage = res.message;
          }
          this.btndisabled = false;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        this.showmessage = true;
        this.loginfail = true;
        if (
          err.error.data &&
          err.error.data[0].message == 'INVALID_CREDENTIALS'
        ) {
          this.loginmessage = 'Invalid Credentials';
          this.btndisabled = false;
        } else {
          if (!navigator.onLine) {
            this.loginmessage = 'Check Your Network Connection';
          } else {
            this.loginmessage = 'Something Went Wrong';
          }
        }
        this.btndisabled = false;
      }
    );
  }
  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getIpAddress() {
    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((res: any) => {
        return res.ip;
      });
  }

  openLoginCredentialModal(data: any) {
    let activeModal = this.modalService.open(LoginCredentialsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
      backdrop: false,
    });
    activeModal.result.then(() => {
      this.setUserTypeAndLocalStorage(data);
    });
  }

  setUserTypeAndLocalStorage(data: any) {
    const now = new Date();
    const item = {
      expiry: now.getTime() + 900000,
    };
    this.userService._tokenExpiryTime = item;
    this.cookieServive.set('JSESSIONID', data.sessionId);
    localStorage.setItem('sessionId', this.cookieServive.get('SESSION'));
    this.cookieServive.set('JSESSIONID', data.sessionId);
    localStorage.setItem('JSESSIONID', data.sessionId);
    localStorage.setItem('userName', data.username);
    localStorage.setItem('permission', JSON.stringify(data.permission));
    this.userService.setPermission();
    localStorage.setItem('loginTime', data.loginTime);
    localStorage.setItem(
      'xsights_authkey',
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MzEzNjUsImZvaWQiOjEsIm5hbWUiOiJTYWxvbmkgQmFuc2FsIiwibW9iaWxlbm8iOjc3MzczNTk2OTUsImVtYWlsIjoic2Fsb25pLmJhbnNhbEBheGVzdHJhY2suY29tIiwidGltZSI6IjIwMjItMTItMDdUMTQ6Mzc6NTArMDU6MzAifQ.80BOFsdlr81fj_gV41BqcbjDQfPlqyKDDiyaqceBgRE'
    );
    localStorage.setItem('xsights_entrymode', '1');
    localStorage.setItem('USER_TOKEN', this.userService._token);
    localStorage.setItem(
      'USER_DETAILS',
      JSON.stringify(this.userService._details)
    );
    localStorage.setItem('expiryTime', JSON.stringify(item));
    this.showmessage = true;
    this.loginfail = false;
    this.loginmessage = 'Login Successfully';
    localStorage.setItem('user_role', data.companyId);
    localStorage.setItem('user_type', data.userType);
    let user_role = localStorage.getItem('user_role');
    let user_type = localStorage.getItem('user_type');
    this.userService.setUserDetail();
    this.role.user_role = user_type;
    this.userService._isLoggined = true;
    window.location.href =
      environment.websiteUrl +
      (this.route.snapshot.queryParams.re || 'user/dashboard');
    // this.router.navigate([this.route.snapshot.queryParams.re || '/user/dashboard']);
  }

  routeNagivate(cplNagvigate: boolean = true) {
    window.location.href =
    environment.websiteUrl +'auth/visitor-login';
    // return this.router.navigate(['auth/visitor-login']);
  }
}

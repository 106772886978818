import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { Calendar } from '@fullcalendar/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetRequestRemarkComponent } from '../asset-request-remark/asset-request-remark.component';


@Component({
  selector: 'app-asset-reserve',
  templateUrl: './asset-reserve.component.html',
  styleUrls: ['./asset-reserve.component.scss']
})
export class AssetReserveComponent implements OnInit {

  @Input() public assetId:number;
  events:any=[];
  calendarOptions: CalendarOptions = {
    plugins:[dayGridPlugin,interactionPlugin],
    initialView: 'dayGridMonth',
    eventContent: this.customEventContent.bind(this),
    themeSystem: 'bootstrap',
    height: '500px',
    events:[],
    eventClick:(arg)=>{
      this.openRemarkModal(arg);
    },
  };
  constructor(private api:ApiService,private common:CommonService,public modalService:NgbModal) {
    Calendar.name;
   }

  ngOnInit(): void {
      this.getAssetReservations(this.assetId);
  }

  getStatusColor(status){
    if(status=='ASSIGNED')return '#97cbf4';
    else if(status=='CHECKEDOUT') return '#a3e39f';
    else return null;
  }


  getAssetReservations(assetId:any){
    this.common.loading = true;
    let params ={
      assetId:assetId
    }
    this.api.get('asset-reserve/get-asset-reservations',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          res.data.forEach(request => {
            var nextDay = new Date(request.end_date);
            nextDay.setDate(nextDay.getDate() + 1);
            let newDate = nextDay.toISOString().split('T')[0];
            this.events.push({ start: (request.status_name=='CHECKEDOUT'?request.checkout_date:request.from_date),
            end:newDate,
            title: `${request.entity_name}   -  ${request.req_for_entity}`,
            // display:'background',
            backgroundColor: this.getStatusColor(request.status_name),
            id:request.id,
            borderColor:this.getStatusColor(request.status_name),
          })
          });
          this.calendarOptions.events=this.events;
        }
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    )
  }

  customEventContent(arg) {
    return {
      html: `<i style="color:black;padding:12px">${arg.event.title}</i>`
    };
  }

  openRemarkModal(field:any) {
    const activeModal=this.modalService.open(AssetRequestRemarkComponent,{
      size:'xl'
    })
    activeModal.componentInstance.refData={
      request_id:field.event.id,
      calling:false,
      from_page:true
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';

@Component({
  selector: 'app-group-role-mapping',
  templateUrl: './group-role-mapping.component.html',
  styleUrls: ['./group-role-mapping.component.scss'],
})
export class GroupRoleMappingComponent implements OnInit {
  groupList = [];
  roleList: any = [];
  groupId: null;
  groupName: null;
  companyId = null;
  plantId = null;
  companyName: null;
  plantName: null;
  companyData: any = [];
  plantData: any = [];
  selectedRoleId: any = [];
  groupRoleType: any = 'Plant';

  groupTypesList: any = {
    AXESTRACK: [
      'AXESTRACK',
      'AXESTRACKUSER',
      'COMPANYADMIN',
      'COMPANYUSER',
      'PLANTADMIN',
      'PLANTUSER',
    ],
    AXESTRACKUSER: ['PLANTADMIN', 'PLANTUSER'],
    COMPANYADMIN: ['COMPANYADMIN', 'COMPANYUSER', 'PLANTADMIN', 'PLANTUSER'],
    COMPANYUSER: ['PLANTADMIN', 'PLANTUSER'],
    PLANTADMIN: ['PLANTADMIN', 'PLANTUSER'],
    PLANTUSER: ['PLANTUSER'],
  };

  constructor(
    public alertService: AlertService,
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public plantService: PlantService,
    public role: RoleService
  ) {
    this.setCompanyPlantId();
    this.init();
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'User-Group', url: null },
      { name: 'Group Role Mapping', url: '/user/group-role-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  setCompanyPlantId() {
    if (this.role.user_role == 'COMPANYADMIN') {
      this.companyId = this.userService._loggedInUser.companyId;
      this.getPlantById(this.companyId);
    } else if (
      this.role.user_role == 'COMPANYUSER' ||
      this.role.user_role == 'PLANTADMIN' ||
      this.role.user_role == 'PLANTUSER'
    ) {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
    }
  }

  ngOnInit(): void {}

  init() {
    this.groupRoleType = this.role.user_role;
    if (this.role.user_role != 'PLANTUSER') {
      this.getGroupsByGroupRoleType();
    } else {
      this.plantId = this.userService._loggedInUser.plantId;
      this.getGroupList();
    }
    this.getRoleList();
    if (
      this.role.user_role == 'AXESTRACK' ||
      this.role.user_role == 'AXESTRACKUSER'
    ) {
      this.getCompany();
    }
  }

  selectedGroupType() {
    this.selectedRoleId = [];
    this.companyId = null;
    this.companyName = null;
    this.plantName = null;
    this.plantId = null;
    this.groupList = [];
    this.setCompanyPlantId();
    this.getGroupsByGroupRoleType();
    this.getRoleList();
  }

  getCompany() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = null;
    this.getGroupsByGroupRoleType();
    this.getRoleList();
  }
  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.groupId = null;
    this.groupName = null;
    this.getGroupsByGroupRoleType();
    this.getRoleList();
  }

  getRoleList() {
    let params: any;
    params = {
      userType: this.role.user_role,
      groupRoleType: this.groupRoleType,
      companyId: this.companyId,
      plantId: this.plantId,
    };

    this.common.loading = true;
    this.api.get('role/get-all-roles-by-userType', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.roleList = res.data || [];
          this.roleList.forEach((item) => (item.accessId = null));
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getGroupList() {
    this.common.loading = true;
    let params = {
      plantId: this.plantId,
      plantType: true,
    };
    this.api.get('group/all-group', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.groupList = res.data || [];
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getGroupsByGroupRoleType() {
    this.common.loading = true;
    let params: any;
    params = {
      userType: this.role.user_role,
      groupRoleType: this.groupRoleType,
      company: this.companyId,
      plant: this.plantId,
    };
    this.api.get('group/groups-by-group-role-type', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.groupList = [];
          this.groupList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getGroup(event: any) {
    this.groupId = event.groupId;
    this.groupName = event.groupName;
    this.common.loading = true;
    this.api
      .get('group/group_roles_by_group_id?groupId=' + this.groupId)
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            if (res.data) {
              this.setRoleDetails(res.data);
            } else {
              this.getRoleList();
            }
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  setRoleDetails(data: any) {
    this.selectedRoleId = [];
    data.forEach((e) => {
      this.selectedRoleId.push(e.roleId);
    });
  }

  selectedRole(event: any) {
    if (
      this.groupRoleType != 'AXESTRACKUSER' &&
      this.groupRoleType != 'COMPANYUSER'
    ) {
      this.selectedRoleId = [];
      this.selectedRoleId.push(event.value);
    } else {
      let i = this.selectedRoleId.indexOf(event);
      if (i == -1) {
        this.selectedRoleId.push(event);
      } else {
        this.selectedRoleId.splice(i, 1);
      }
    }
  }

  getRoleStatus(id: any) {
    if (this.selectedRoleId.indexOf(id) != -1) {
      return true;
    }
  }

  saveAssociation() {
    if (this.selectedRoleId.length == 0) {
      this.alertService.error('Please select any role...');
    } else {
      const params = {
        groupId: this.groupId,
        roleId: this.selectedRoleId,
      };
      this.common.loading = true;
      this.api.post('group/create_group_role_mapping', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.alertService.success(res.message);
            this.resetData();
          }
        },
        (err: any) => {
          this.common.loading = false;
        }
      );
    }
  }

  resetData() {
    this.groupId = null;
    this.groupName = null;
    this.companyId = null;
    this.companyName = null;
    this.plantId = null;
    this.plantName = null;
    this.plantData = null;
    if (
      this.role.user_role == 'AXESTRACK' ||
      this.role.user_role == 'AXESTRACKUSER'
    ) {
      this.groupList = [];
      this.roleList = [];
    }
    this.selectedRoleId = [];
    this.groupRoleType = this.role.user_role;
    this.getGroupsByGroupRoleType();
    this.getRoleList();
    this.companyValidator = null;
    this.plantValidator = null;
    this.roleValidator = null;
    this.typeValidator = null;
    this.groupValidator = null;
  }

  companyValidator: any;
  plantValidator: any;
  groupValidator: any;
  roleValidator: any;
  checkValidator: any;
  typeValidator: any;
  formValidator() {
    this.groupValidator = this.common.nullValidator(
      this.groupName,
      'Group Mandatory'
    );
    this.typeValidator = this.common.nullValidator(
      this.groupRoleType,
      'Group Type Mandatory'
    );
    this.roleValidator = this.common.nullValidator(
      this.selectedRoleId,
      'Select Any Role To Assign'
    );

    let temp = this.common.checkCompanyPlantAccToUser(
      this.groupRoleType,
      this.role.user_role,
      this.companyId,
      this.plantId
    );
    if (!temp.company) {
      this.companyValidator = this.common.nullValidator(
        this.companyId,
        'Company Mandatory'
      );
      return;
    }

    if (!temp.plant && this.groupRoleType != 'COMPANYUSER') {
      this.plantValidator = this.common.nullValidator(
        this.plantId,
        'Plant Mandatory'
      );
      return;
    }

    if (
      temp.company &&
      (temp.plant || this.groupRoleType == 'COMPANYUSER') &&
      !this.groupValidator.error &&
      !this.typeValidator.error &&
      !this.roleValidator.error
    ) {
      this.saveAssociation();
    }
  }
}

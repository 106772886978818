import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  user_role:string = '';
  user_role_id:string = '';
  constructor() { }
}

<div class="login_page">
  <div class="row login_block">
    <div class="col-md-5 login_section">
      <img src="assets/images/logo.png">
      <div class="welcome">Forgot Your Password</div>
      <div class="cpl_name">Connected Plant Logistics</div>

      <div class="alert" [ngClass]="{'alert-danger':loginfail,'alert-success':!loginfail}" role="alert"
        *ngIf="showmessage">
        <p class="mb-0"> {{loginmessage}} </p>
      </div>

      <div class="login_form">
        <mat-form-field class="form-group col-sm-12" *ngIf="!otpenabled">
          <div class="icon">
            <i class="fa fa-user" aria-hidden="true"></i>
          </div>

          <input matInput type="text" placeholder="Enter Username" [(ngModel)]="user.userName" name="userName"
            aria-label="username" aria-describedby="email">
          <div class="error" *ngIf="userNameValidator && userNameValidator.error">
            <span>{{userNameValidator.msg}} </span>
          </div>
        </mat-form-field>

        <mat-form-field class="form-group col-sm-12" *ngIf="otpenabled && !passwordresetEnabled">
          <div class="icon">
            <i class="fa fa-key" aria-hidden="true"></i>
          </div>

          <input matInput type="numeric" placeholder="Enter OTP" [(ngModel)]="user.otp" name="otp" aria-label="password"
            aria-describedby="password">
          <div class="error" *ngIf="passwordValidator && passwordValidator.error">
            <span>{{passwordValidator.msg}} </span>
          </div>
        </mat-form-field>

        <div *ngIf="!passwordresetEnabled">
          <a class="send_link" href="javascript:void" (click)="userVerify()"> Resend OTP </a>
        </div>

        <div class="auth-action" *ngIf="!otpenabled">
          <button type="button" class="btn btn-submit" (click)="userVerify()"> Generate OTP </button>
        </div>

        <div class="auth-action" *ngIf="otpenabled && !passwordresetEnabled">
          <button type="button" class="btn btn-submit" (click)="otpVerify()"> Verify OTP </button>
        </div>

        <mat-form-field class="form-group col-sm-12" *ngIf="passwordresetEnabled">
          <div class="icon">
            <i class="fa fa-key" aria-hidden="true"></i>
          </div>

          <input matInput type="password" placeholder="Enter Password" [(ngModel)]="user.newPassword" name="newPassword"
            aria-label="username" aria-describedby="email">
          <div class="error" *ngIf="userNameValidator && userNameValidator.error">
            <span>{{userNameValidator.msg}} </span>
          </div>
        </mat-form-field>

        <mat-form-field class="form-group col-sm-12" *ngIf="passwordresetEnabled">
          <div class="icon">
            <i class="fa fa-key" aria-hidden="true"></i>
          </div>

          <input matInput type="password" placeholder="Confirm Password" [(ngModel)]="user.checkPassword"
            name="checkPassword" aria-label="username" aria-describedby="email">
          <div class="error" *ngIf="userNameValidator && userNameValidator.error">
            <span>{{userNameValidator.msg}} </span>
          </div>
          <div class="error" *ngIf="message">
            <span>{{message}} </span>
          </div>
        </mat-form-field>

        <div class="auth-action mt-4" *ngIf="passwordresetEnabled">
          <button type="button" class="btn btn-submit" (click)="Changepassword()"> Change Password </button>
        </div>

        <div *ngIf="!passwordresetEnabled">
          <a class="back_link" [routerLink]="['/auth/login']"> &lt;&lt; Back to Login </a>
        </div>

        <div *ngIf="successLogin && passwordresetEnabled">
          <a class="back_link" [routerLink]="['/auth/login']"> &lt;&lt; Back to Login </a>
        </div>
      </div>

      <div class="powered"><a href="https://axestrack.com/">powered by Axestrack</a></div>
    </div>

    <div class="col-md-6 offset-md-1 gif_sec">
      <img src="assets/images/plant_gif.gif">
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{'Asset Details'|translate}}
      </h3>
    </div>
  </div>

  <div class="card-body">
    <div class="row m-0" *ngIf="groupedValues.length!=0;else notFound">
      <div class="col-md-3 px-1" *ngFor="let field of groupedValues">
        <div class="view-table-custom">
          <div class="label">{{field.field_name | uppercase }}:</div>
          <div class="value">{{(field.value==null || field.value=='')?' -- ':field.value}}</div>
        </div>
      </div>
    </div>

    <ng-template #notFound >
      <div class="message">
        <img src="assets/images/icons8-nothing-found-80.png">
        <span style="margin-bottom: 10px;">{{'No custom fields exists for the asset!'|translate}}</span>
      </div>
    </ng-template>
  </div>
</div>


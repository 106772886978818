import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { Router } from '@angular/router';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';

@Component({
  selector: 'app-assign-driver-transporter',
  templateUrl: './assign-driver-transporter.component.html',
  styleUrls: ['./assign-driver-transporter.component.scss']
})
export class AssignDriverTransporterComponent implements OnInit {
  @ViewChild('myTemplate', { static: true }) modalContent: ElementRef<any>;
  @ViewChild('detail2') detail2Template: any;
  @Output() modalClosed = new EventEmitter<void>();

  assign: string;
  assignToList: any[] = [];
  DisplayName: string;
  assigningTo: number = null;
  newEntry: string = null;
  txnId: number;
  flag: string = 'add';
  details: any[] = [];
  constructor(public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal, public router: Router,
  ) {
    this.assign = this.common.params.assign;
    this.txnId = this.common.params.transaction_id;
    this.assigningTo = this.common.params.id;
    this.newEntry = this.common.params.name;
    if (this.assign == 'Driver') {
      this.getAllDriver();
      this.DisplayName = "driverName";
    } else {
      this.getAllTransporter();
      this.DisplayName = "transporterName";
    }
  }

  ngOnInit(): void {
  }

  closeModal(response?: any, apiHit?: any, id?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit, id: id });
  }

  selectAssignTo(event: any) {
    this.assigningTo = event.id;
    this.newEntry = event[this.DisplayName];
    if (this.assign == 'Driver') {
      this.getAllDriver();
      this.DisplayName = "driverName";
    } else {
      this.getAllTransporter();
      this.DisplayName = "transporterName";
    }

  }

  getAllDriver() {
    this.common.loading = true;
    this.api.get('driver/get-all-drivers').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.assignToList = res.data;
        if (this.assigningTo != null && !this.newEntry.includes('-')) {
          this.newEntry = this.newEntry + '-' + (this.assignToList.filter((e) => e.id == this.assigningTo)[0].driverPhone);
        }
        if (this.assigningTo != null) {
          this.details = this.assignToList.filter((e) => e.id == this.assigningTo);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  displayImage(url, imageOf: string) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = imageOf + ' Image -' + (this.assign == 'Driver' ? this.details[0].driverName : this.details[0].transporterName);
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  getAllTransporter() {
    this.common.loading = true;
    this.api.get('transporter/get-all-transporters').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.assignToList = res.data;
        if (this.assigningTo != null && !this.newEntry.includes('-')) {
          this.newEntry = this.newEntry + '-' + (this.assignToList.filter((e) => e.id == this.assigningTo)[0].transporterPhone)
        }
        if (this.assigningTo != null) {
          this.details = this.assignToList.filter((e) => e.id == this.assigningTo);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  assigneeValidator: any;
  formValidator() {
    this.assigneeValidator = this.common.nullValidator(
      this.assigningTo,
      'Field Mandatory'
    );
    if (!this.assigneeValidator.error) {
      this.assignDriverOrTransporter();
    }

  }

  assignDriverOrTransporter() {
    this.common.loading = true;
    let url;
    let params;
    if (this.assign == 'Driver') {
      url = 'driver/assign-driver-to-vehicle';
      params = {
        transaction_id: this.txnId,
        driver_id: this.assigningTo
      }
    } else {
      url = 'transporter/assign-transporter-to-vehicle';
      params = {
        transaction_id: this.txnId,
        transporter_id: this.assigningTo
      }
    }
    this.api.post(url, params).subscribe((response: any) => {
      this.common.loading = false;
      if (response.status) {
        this.closeModal(this.newEntry, 1, this.assigningTo);
      } else {
        this.alertService.success(response.message);
      }
    }, (error) => {
      this.common.loading = false;
      console.log(error);
    });
  }

  addNewAssignee(detail: any, flag: string) {
    console.log("checkinggg",detail,flag);
    this.flag = flag;
    this.closeModal(this.newEntry, 1, this.assigningTo);
    const activeModal = this.modalService.open(detail, {
      size: 'lg',
    });
  }

  openCurrentModal() {
    this.common.params = {
      assign: this.assign,
      transaction_id: this.txnId,
      name: this.newEntry,
      id: this.assigningTo,
    }
    const activeModal = this.modalService.open(AssignDriverTransporterComponent, {
      size: 'lg',
    });
  }


}

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { validator } from 'src/app/dataType/commonInterface';
import { alertType, complianceList } from 'src/app/dataType/complianceInterface';
import { COMMON_ACTION_BUTTON, TABLE_TYPE, TableActions, TableColumns } from 'src/app/dataType/customTableInterface';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-compliance-severity-mapping',
  templateUrl: './compliance-severity-mapping.component.html',
  styleUrls: ['./compliance-severity-mapping.component.scss']
})
export class ComplianceSeverityMappingComponent implements OnInit {

  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  loading: boolean = false;
  tableColumns: TableColumns[] = [];
  plantLevelRoles = ['AXESTRACKUSER' , 'COMPANYUSER' , 'PLANTADMIN', 'PLANTUSER'];

    complianceList:complianceList[]=[];
    mappingList:complianceList[]=[];
    alertTypeList:alertType[]=[];
    alertTypeId:number=null;
    selectedConditionName:string=null;
    selectedAlertType:string=null;
    companyData:any[]=[];
    companyName:String='';
    plantData:any[] = [];
    plantName:string='';
    buttonName:string= 'save';
    isDeleted:boolean=false;
    plantComplianceId:number=null;
    plantId:number=null;
    companyId:number=null;
    complianceValidator:validator=null;
    alertTypeValidator:validator=null;


    constructor(
      public userService: UserService,
      public common: CommonService,
      public table: TableService,
      public api: ApiService,
      public modalService: NgbModal,
      public breadcrumbService: BreadcrumbService,
      public role: RoleService,
      public plantService : PlantService,
      public alert : AlertService,
      public translateService:TranslateService
    ) {

      let breadcumbs = [
        { name: 'Home', url: '/user/dashboard' },
        { name: 'Compliance', url: null },
        { name: 'Compliance Severity Mapping', link: '/user/compliance-severity-mapping' },
      ];
      this.breadcrumbService.getBreadcrumbs(breadcumbs);
     }

    ngOnInit(): void {
      this.getAlertType();
      // first calling the company;
      if(this.role.user_role=='AXESTRACK'){
        this.getCompany();
      }
      else if(this.role.user_role=='COMPANYADMIN'){
        this.getPlants(this.userService._loggedInUser.companyId);
      }else{
        this.getComplianceList();
      }
    }



// custom functions
  getCompany(){
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
      );
    }


  selectedCompany(event:any){
    this.companyName = event.companyName;
    this.getPlants(event.companyId);
  }

  selectedPlant(event:any){
    this.plantName = event.plantName;
    this.getComplianceList();
  }

  selectedComplianceConditon(event:any,type:string){
    if(type=='select' && event.id!=null){
      this.plantComplianceId = event.id;
      this.selectedConditionName = event.title;
    }
    else{
      this.selectedConditionName = event;
      if(this.buttonName=='save')this.plantComplianceId = null;
    }
  }

  selectedSeverity(event:any){
    this.alertTypeId=event.id;
    this.selectedAlertType=event.type_name;
  }

  getPlants(id?:any) {
      this.common.loading = true;
      let params = {
        id: id
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.plantData = res.data;
          console.log(this.plantData)
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
        }
    );
  }

  getComplianceList() {
    this.common.loading = true;

    let params = {
      plantId: this.plantId
    };
    this.api.get('compliance/get-complaince-errors',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.complianceList = res.data;
          this.mappingList = res.data.filter(event=>event.severity_id!=null);

          this.tableColumns = [
            {
              columnLabel: "Compliance Condition",
              columnName: "title",
            },
            {
              columnLabel: "Severity",
              columnName: "type_name",
            },
            {
              columnLabel: "Colour",
              columnName: "type_color",
              dataFormatter: (row: any, column: TableColumns, index: number) => {
                return `<div style = "padding: 13px;color: white;margin-bottom: 10px;width: 95%;background-color: ${row.type_color};">
                </div>`
              },
            },{
              columnLabel: this.translateService.instant("Action"),
              columnName: null,
              dataActions: [
                {
                  actionName: COMMON_ACTION_BUTTON.EDIT,
                  handleClick: (column: TableColumns, row: any, index: number) => {
                    this.editComplianceSeverity(row);
                  },
                  actionFormatter: (column: TableColumns, row: any, index: number) => {
                    return `<div class="btn-block">
                      <button class="btn btn-success" style="background-color: #28a745; position: relative; color: #fff; border: none; font-size: 12px;"><span style="font-family: 'FontAwesome'; position: absolute; top: 3px; left: 8px; font-size: 13px;">
                      &#xf044;
                      </span><span style="margin-left:12px">${this.translateService.instant("edit")}</span></button>
                      </div>`;
                  }
                },
                {
                  actionName: COMMON_ACTION_BUTTON.DELETE,
                  handleClick: (column: TableColumns, row: any, index: number) => {
                    this.confirmDelete(row);
                  },
                  actionFormatter: (column: TableColumns, row: any, index: number) => {
                    return `<div class="btn-block">
                      <button class="btn btn-danger" style="background-color: red; position: relative; color: #fff; border: none; font-size: 12px;"><span style="font-family: 'FontAwesome'; position: absolute; top: 3px; left: 8px; font-size: 13px;">
                      &#xf1f8;
                      </span><span style="margin-left:12px">${this.translateService.instant("delete")}</span></button>
                      </div>`;
                  }
                }
              ] as TableActions[]
            }
          ]
          this.tableColumns = this.tableColumns.map(column => {
            if (column.dataActions) {
              return {
                ...column,
                dataActions: column.dataActions.filter(action => {
                  return ((action.actionName !== COMMON_ACTION_BUTTON.DELETE || this.userService.permissionMap.get('delete_compliance_severity_mapping_access')) && (action.actionName !== COMMON_ACTION_BUTTON.EDIT) || this.userService.permissionMap.get('edit_compliance_severity_mapping_access'))
                })
              };
            }
            return column;
        });

        }
        this.common.loading = false;

      },
      (err) => {
        this.common.loading = false;
        this.loading =false;
        console.log("Error: " + err);
      }
    );
  }


  getAlertType(){
    this.common.loading = true;
    this.api.get("alert/get_alert_type").subscribe(
      (res:any)=>{
        this.common.loading = false;
        this.alertTypeList=res.data;
      },
      (err:any)=>{
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  saveCompliancSeverityMapping(){
    let params= {
      complianceSeverityId:this.alertTypeId,
      isDelete:this.isDeleted,
      complianceCategoryId:this.plantComplianceId,
      title:this.selectedConditionName,
      saveLogFlag:false,
      plantId:this.plantId,
    }
    console.log("dftyuij",params)
    this.api.post('compliance/save-plant-complaince',params).subscribe(
      (res: any) => {
        if(res.status){
            this.alert.success(res.message);
            this.reset();
            this.getComplianceList();
        }
        else{
          this.alert.error(res.message);
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );

  }

  reset(){
    this.complianceValidator=null;
    this.alertTypeValidator=null;
    this.selectedAlertType=null;
    this.selectedConditionName=null;
    this.plantComplianceId= null;
    this.isDeleted=false;
    this.alertTypeId=null;
    if(this.role.user_role=='AXESTRACK'){
        this.plantData=null;
        this.companyId = null;
      this.companyName = null;
    }
    this.plantName = null;
    this.plantId = null;
    this.buttonName='save';
  }

    editComplianceSeverity(row?:any){
     this.common.gotoTop();
     this.buttonName='update';
     this.alertTypeId=row.severity_id;
     this.selectedAlertType=row.type_name;
     this.selectedConditionName=row.title;
     this.plantComplianceId=row.id;
     this.complianceValidator=null;
    this.alertTypeValidator=null;
    this.isDeleted=false;
  }


  confirmDelete(row?:any){
    this.common.params = {
      title: 'Delete Compliance Severity Mapping ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.plantComplianceId=row.id;
        this.isDeleted=true;
        this.saveCompliancSeverityMapping();
      }
    });
  }

  formValidator(){
    this.selectedConditionName = this.selectedConditionName?this.selectedConditionName.trim():null;
    this.complianceValidator=this.common.nullValidator(
      this.selectedConditionName,'Compliance Title Mandatory'
      );
    this.alertTypeValidator=this.common.nullValidator(
      this.alertTypeId,'Severity Type Mandatory'
    )
    if(!this.complianceValidator.error && !this.alertTypeValidator.error){
      this.saveCompliancSeverityMapping();
    }

  }

}










import { AutoSuggestionComponent } from './../../directives/auto-suggestion/auto-suggestion.component';
import { filter } from 'rxjs/operators';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { StageService } from '../stage/stage.service';
import { UserService } from 'src/app/@core/services/user.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-master-field',
  templateUrl: './master-field.component.html',
  styleUrls: ['./master-field.component.scss'],
})
export class MasterFieldComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'Master Fields');
  detail = '';
  subgroupsCount = 1;
  subgroupCountRange = Array.from({ length: this.subgroupsCount }, (_, index) => index + 1);
  filteredMasterFields: any[] = [];
  elRef: any;
  ngAfterViewInit() {
    this.dtTrigger.next();
    if(this.role.user_role!='COMPANYADMIN')this.getData();
    else this.getPlantList();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  subgroupsName: any = [{
    0: {
      name: null,
      values: { fieldName: null, fieldId: null, fieldType: null }
    }
  }]
  masterField = {
    id: null,
    name: null,
    status: 'ACTIVE',
    type: 'TEXTFIELD',
    values: [{ name: null }],
    hardwareResponse: null,
    fieldValue: 'USERINPUT',
    databaseField: null,
    pattern_prefix: null,
    pattern_postfix: null,
    regex_pattern :null,
    matchingMethod : 'random'
  };
  plantNameValidator:any;
  plantId:any;
  plantList:any[];
  plantName:any;
  databaseFields: any = [];
  hardwareType = null;
  hardwareResponsesData: any = [];
  companyData: any[] = [];
  public Editor = ClassicEditor;
  title = 'Add Master Field';
  btn = 'save';
  data: any[] = [];
  fieldValidationGrp = ['TEXTFIELD', 'NUMBERFIELD', 'TEXTAREA', 'BARCODE', 'BARCODEWITHIMAGE' ,'RFIDCODE' , 'RFIDCODEWITHIMAGE','DATEPICKER' , 'DATETIMEPICKER' , 'TIME'];
  regExValidationGrp = ['DATEPICKER' , 'DATETIMEPICKER' , 'TIME'];
  constructor(
    private api: ApiService,
    public common: CommonService,
    public modalService: NgbModal,
    public stageService: StageService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService,
    private alterService: AlertService,
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Fields', url: null },
      { name: 'Field Management', url: '/user/master-field' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);

  }

  ngOnInit(): void {
    this.getDBFields();
  }

  getDBFields() {
    this.common.loading = true;
    this.api.get('fields/list-database-fields').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.databaseFields = res.data;
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantList() {
    this.common.loading = true;
    this.api.get('plant/all_plants?id=' + this.userService._loggedInUser.companyId).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantList = res.data;
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectPlant(event) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getData(this.plantId);
  }

  getCompany() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.companyData = res.data;
        this.renderTable();
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }
  getData(plantId?) {
    let body = {
      plantId: plantId,
      excludedFieldTypes:["FIELDGROUP"]
    }
    this.common.loading = true;
    this.api.post('fields/allMasterFields',body).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.data = res.data;
        this.filteredMasterFields = res.data
        this.renderTable();
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  changeNoOfSubGroups(event: any) {
    if (event.target.value <= 4 && event.target.value > 0) {
      this.subgroupsCount = event.target.value;
      const currentLength = this.subgroupCountRange.length;
      const newLength = this.subgroupsCount;
      if (currentLength < newLength) {
        // Add new items at the end
        this.subgroupCountRange.push(...Array.from({ length: newLength - currentLength }, (_, index) => currentLength + index + 1));
        // Add new items to subgroupsName
        let count = newLength - currentLength;
        let indexLegth = currentLength;
        this.subgroupsName.forEach(row => {
          for (let i = 0; i < count; i++) {
            row[indexLegth] = {
              name: null,
              values: { fieldName: null, fieldId: null, fieldType: null }
            };
            indexLegth++;
          }
          indexLegth = currentLength;
        })
      } else if (currentLength > newLength) {
        // Remove items from the end
        this.subgroupCountRange.splice(newLength);
        // Remove items from subgroupsName
        let count = currentLength - newLength;
        // Loop through each object in subgroupsName
        this.subgroupsName.forEach(obj => {
          // Get the keys (0, 1, 2, ...) and convert them to an array
          let keys = Object.keys(obj);

          // Determine the ending index for splicing
          let endIdx = Math.max(0, keys.length - count);

          // Remove count no. of objects from the end
          keys.splice(0, endIdx);

          // Remove properties from the object based on the modified keys array
          keys.forEach(key => delete obj[key]);
        });
      }
    } else {
      this.alterService.error("Sub Groups Count should be less than or equals to 4 and not equals to 0 !!");
      (document.getElementById("subgroupscount") as any).value = this.subgroupsCount.toString();
    }
  }

  checkSubGroupNameExists(subgroups, subgroupname) {
    const namesSet = new Set();
    for (const subgroup of subgroups) {
      for (const key in subgroup) {
        if (Object.prototype.hasOwnProperty.call(subgroup, key)) {
          const name = subgroup[key].name;
          namesSet.add(name);
        }
      }
    }
    if (namesSet.has(subgroupname)) {
      return true;
    }
    return false;
  }

  enterSubGroupName(id: any, event: any) {
    //checking if same subgroup name already exists or not
    if (!this.checkSubGroupNameExists(this.subgroupsName, event.target.value)) {
      //required because if any extra rows are added after entering subgroup name then for new row objects should also have their respective sub group names..
      // Loop through each object in subgroupsName
      for (let i = 0; i < this.subgroupsName.length; i++) {
        let obj = this.subgroupsName[i];
        // Loop through the keys of the current object
        for (let key in obj) {
          if (obj.hasOwnProperty(key) && key == id) {
            obj[key].name = event.target.value;
          }
        }
      }
    } else {
      this.alterService.error("Sub Group Name occurs more than once !!");
      (document.getElementById('sub' + id) as any).value = this.subgroupsName[0][id].name;
    }
  }

  // hasDuplicateNames(subgroups) {
  //   const namesSet = new Set();
  //   for (const subgroup of subgroups) {
  //     for (const key in subgroup) {
  //       if (Object.prototype.hasOwnProperty.call(subgroup, key)) {
  //         const name = subgroup[key].name;
  //         if (namesSet.has(name)) {
  //           return true; // Found duplicate name
  //         }
  //         namesSet.add(name);
  //       }
  //     }
  //     namesSet.clear();
  //   }
  //   return false;
  //   // No duplicate name found
  // }

  // hasDuplicateFields(subgroups) {
  //   //Making object such that key is the fieldId's and value will be it's number of occurances
  //   const fieldIdOccurrences: { [key: number]: number } = subgroups.reduce((acc, subgroup) => {
  //     Object.keys(subgroup).forEach((key: string) => {
  //         const item = subgroup[key];
  //         const fieldId = item.values.fieldId;
  //         acc[fieldId] = (acc[fieldId] || 0) + 1;
  //     });
  //     return acc;
  // }, {});

  // //checking if any key in the above created object has value >1
  // const hasRepeatedFieldId = Object.values(fieldIdOccurrences).some(value => value > 1);
  // return hasRepeatedFieldId;
  // }

  saveData() {
    console.log(this.subgroupsName)
    // let flag = true;
    // let message ="";
    // if (this.masterField.type == 'FIELDGROUP') {
    //   //checking if any subgroup name is similar...
    //   if (this.hasDuplicateNames(this.subgroupsName)) {
    //     flag = false;
    //     message="Sub Group Name occurs more than once."
    //   }

    //   //checking if any field is repeated in the field group
    //   if(flag==true && this.hasDuplicateFields(this.subgroupsName)){
    //     flag = false;
    //     message="Duplicate Fields are selected."
    //   }
    // }
    // if (flag == true) {
    this.common.loading = true;
    let masterFieldValues: any = [];
    if (
      (this.masterField.type == 'SELECTLISTMULTIPLE' &&
        this.masterField.values) ||
      (this.masterField.type == 'SELECTLISTSINGLE' &&
        this.masterField.values) ||
      (this.masterField.type == 'RADIOBUTTON' && this.masterField.values) ||
      (this.masterField.type == 'CHECKBOX' && this.masterField.values) ||
      (this.masterField.type == 'CAMERASTREAMING' || this.masterField.type == 'VIDEOSTREAMING')
    ) {
      this.masterField.values.map((mf) => {
        masterFieldValues.push(mf.name);
      });
    }

    if (this.masterField.type == 'LABEL') {
      masterFieldValues.push(this.detail);
    }
    // let regex = new RegExp(this.masterField.regex_pattern);

    let params = {
      masterFieldId: this.masterField.id,
      fieldName: this.masterField.name,
      fieldStatus: this.masterField.status,
      fieldType: this.masterField.type,
      masterFieldValues: masterFieldValues,
      hardwareResponse: this.masterField.hardwareResponse,
      databaseLinkedId: this.masterField.databaseField,
      fieldValue: this.masterField.fieldValue,
      patternPrefix: this.masterField.pattern_prefix == '' ? null : this.masterField.pattern_prefix,
      patternPostfix: this.masterField.pattern_postfix== '' ? null : this.masterField.pattern_postfix,
      // regexPattern:  regex.source,
      regexPattern:  this.masterField.regex_pattern == '' ? null : this.masterField.regex_pattern,
      matchingMethod: this.masterField.matchingMethod,
      subGroups: this.subgroupsName,
      plantId:this.plantId
    };
    let apiUrl = 'fields/addMasterField';
    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status == true) {
          this.alterService.info(res.message);
          if(this.role.user_role!='COMPANYADMIN')this.getData();
          else this.resetData();
          this.resetDetail();
        } else {
          this.alterService.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
    this.common.loading = false;
  }

  resetData(){
    console.log("jhkl");
    this.data=[];
    this.renderTable();
  }

  resetDetail() {
    this.masterField = {
      id: null,
      name: null,
      status: 'ACTIVE',
      type: 'TEXTFIELD',
      values: [{ name: null }],
      hardwareResponse: null,
      fieldValue: 'USERINPUT',
      databaseField: null,
      pattern_prefix: null,
      pattern_postfix: null,
      regex_pattern : null,
      matchingMethod : 'random'
    };
    this.btn = 'save';
    this.title = 'Add Field';
    this.fieldNameValidator = null;
    this.fieldTypeValidator = null;
    this.statusValidator = null;
    this.detail = '';
    this.subgroupsCount = 1;

    this.plantId=null;
    this.plantName=null;
    this.plantNameValidator=null;
    this.subgroupCountRange = Array.from({ length: this.subgroupsCount }, (_, index) => index + 1);
    this.subgroupsName = [{
      0: {
        name: null,
        values: { fieldName: null, fieldId: null, fieldType: null }
      }
    }]
  }

  showDelete(id: number) {
    if (id + 1 == this.subgroupsCount) {//if last column
      return true;
    } else {
      return false;
    }
  }

  showAdd(id: number) {
    if (id + 1 == this.subgroupsCount) {//if last column
      return true;
    } else {
      return false;
    }
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Field ',
      description: `<b>&nbsp;` + 'Are you sure want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteData(row);
      }
    });
  }
  deleteData(row?: any) {
    this.common.loading = true;
    let values: any = [];

    if (
      (row.field_type == 'SELECTLISTSINGLE' && row.master_field_values) ||
      (row.field_type == 'SELECTLISTMULTIPLE' && row.master_field_values) ||
      (row.field_type == 'RADIOBUTTON' && row.master_field_values) ||
      (row.field_type == 'CHECKBOX' && row.master_field_values) ||
      (row.field_type == 'CAMERASTREAMING' || row.field_type == 'VIDEOSTREAMING')
    ) {
      const fieldValues = row.master_field_values.split(',');
      for (let index = 0; index < fieldValues.length; index++) {
        values.push(fieldValues[index]);
      }
    }
    let linkedWith;
    let params = {};
    params = {
      masterFieldId: row.master_field_id,
      fieldName: row.field_name,
      fieldStatus: 'DELETED',
      fieldType: row.field_type,
      masterFieldValues: values,
      hardwareResponse: row.hardware_response,
      databaseLinkedId: row.database_linked,
      fieldLinked: row.database_linked,
      subGroups: this.subgroupsName,
      patternPrefix: row.pattern_prefix,
      patternPostfix: row.pattern_postfix,
      matchingMethod : row.matching_technique,
      plantId:row.plant_id
    };

    let apiUrl = 'fields/addMasterField';
    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alterService.info(res.message);
        } else {
          this.alterService.error(res.message);
        }
        if(this.role.user_role!='COMPANYADMIN')this.getData();
        else this.resetData();
        this.resetDetail();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedField(event: any, i: number, id: number) {
    if (!this.hasDuplicateFields(this.subgroupsName, event.master_field_id)) {
      //if duplicate field does not exists..
      //checking if the field selected matches with the other field type of other selected fields in the same subgroup or not
      const checkFieldTypeMatch = (list: any[], key: string, fieldType: string): boolean => {
        return list.some((obj: any,ind:number) => {
          const { [key]: item } = obj;
          //checking if field type matches
          if (item && item.values.fieldType == fieldType) {
            return true;
          }
          else if(item && item.values.fieldType!=null && ind==i && (this.subgroupsName.length==1 || this.checkIfAllOthersNull(this.subgroupsName,id.toString(),i))){
            //checking when editing the field that if any other field is selected of previous type or not or all other fields are null except the one editing then only field can be edited...
            return true;
          }
          return checkAllNull(this.subgroupsName, id.toString(), event.field_type)
        });
      };

      //if matches with none check if all the fields are null in the same subgroup or not
      const checkAllNull = (list: any[], key: string, fieldType: string): boolean => {
        let hasNonNull = false; // Flag to track if a non-null fieldType has been encountered

        for (const obj of list) {
          const { [key]: item } = obj;
          if (item && item.values.fieldType !== null) {
            hasNonNull = true; // Set the flag to true if a non-null fieldType is encountered
            break; // Exit the loop if a non-null fieldType is encountered
          }
        }

        return !hasNonNull; // Return true if no non-null fieldType was encountered, false otherwise
      };
      if (checkFieldTypeMatch(this.subgroupsName, id.toString(), event.field_type)) {
        //if matches
        this.subgroupsName[i][id].values.fieldName = event.field_name;
        this.subgroupsName[i][id].values.fieldId = event.master_field_id;
        this.subgroupsName[i][id].values.fieldType = event.field_type;
      } else {
        //if not matches
        this.revertSelectedField(i, id, event,"Field Type selected should be similar in same subgroup");
      }
    } else {
      //if duplicate field does exist..
      this.revertSelectedField(i, id, event,"Duplicate Fields are selected !!");
    }
  }

  checkIfAllOthersNull(subgroups,key,i){
    let flag=true;
     subgroups.some((obj: any,ind:number) => {
      const { [key]: item } = obj;
      if(ind!=i && item && item.values.fieldType !=null){
        flag = false
      }
    });
    return flag;
  }

  private revertSelectedField(i: number, id: number, event: any,message:string) {
    let prev = this.subgroupsName[i][id].values.fieldName;
    this.subgroupsName[i][id].values.fieldName = event.field_name;
    this.alterService.error(message);
    setTimeout(() => {
      this.subgroupsName[i][id].values.fieldName = prev;
    }, 100);
  }

  hasDuplicateFields(subgroups, fieldId) {
    const hasFieldId = (list: any[], fieldId: number): boolean => {
      return list.some((obj) => Object.values(obj).some((innerObj: any) => innerObj.values.fieldId === fieldId));
    };
    return hasFieldId(subgroups, fieldId);
  }

  async editRow(row?: any) {
    this.common.gotoTop();
    this.resetDetail();
    let values: any = [];

    if (
      (row.field_type == 'SELECTLISTSINGLE' && row.master_field_values) ||
      (row.field_type == 'SELECTLISTMULTIPLE' && row.master_field_values) ||
      (row.field_type == 'RADIOBUTTON' && row.master_field_values) ||
      (row.field_type == 'CHECKBOX' && row.master_field_values) ||
      (row.field_type == 'CAMERASTREAMING' || row.field_type == 'VIDEOSTREAMING')
    ) {
      const fieldValues = row.master_field_values.split(',');
      for (let index = 0; index < fieldValues.length; index++) {
        values.push({ name: fieldValues[index] });
      }
    }

    if (row.field_type == 'LABEL' && row.master_field_values) {
      this.detail = row.master_field_values;
    }

    if (row.field_type == 'FIELDGROUP' && row.field_group_mapping != null) {
      let field_group_mapping = JSON.parse(row.field_group_mapping);
      // Group the data by sub_group_name
      const groupedData = field_group_mapping.reduce((acc, obj) => {
        const subGroupName = obj.sub_group_name;
        if (!acc[subGroupName]) {
          acc[subGroupName] = [];
        }
        const newData = {
          fieldName: obj.field_name,
          fieldId: obj.field_id,
          fieldType: obj.field_type
        };
        const insertIndex = acc[subGroupName].findIndex(item => item.field_index > obj.field_index);
        if (insertIndex === -1) {
          acc[subGroupName].push(newData);
        } else {
          acc[subGroupName].splice(insertIndex, 0, newData);
        }
        return acc;
      }, {});

      //preparing data in the form of subgroupsName
      const numberOfObjects = groupedData[Object.keys(groupedData)[0]].length;
      let subgroups = [];
      for (let i = 0; i < numberOfObjects; i++) {
        let obj = {};
        for (let j = 0; j < Object.keys(groupedData).length; j++) {
          obj[j] = {
            name: Object.keys(groupedData)[j],
            values: { fieldName: groupedData[Object.keys(groupedData)[j]][i].fieldName, fieldId: groupedData[Object.keys(groupedData)[j]][i].fieldId, fieldType: groupedData[Object.keys(groupedData)[j]][i].fieldType }
          }
        }
        subgroups.push(obj);
      }
      this.subgroupsName = subgroups;
      //count of no. of subgroups to show
      this.subgroupsCount = Object.keys(this.subgroupsName[0]).length;
      //empty array to loop on for sub group columns
      this.subgroupCountRange = Array.from({ length: this.subgroupsCount }, (_, index) => index + 1);
    }

    this.masterField.id = row.master_field_id;
    this.masterField.name = row.field_name;
    this.masterField.type = row.field_type;
    this.masterField.status = row.field_status;
    this.masterField.hardwareResponse = row.hardware_response;
    this.masterField.values = values;
    this.masterField.pattern_prefix = row.pattern_prefix;
    this.masterField.pattern_postfix = row.pattern_postfix
    this.masterField.regex_pattern = row.regex;
    this.masterField.matchingMethod = row.matching_technique;
    this.plantId=row.plant_id;
    this.plantName=row.plant_name;
    this.btn = 'update';
    this.title = 'Edit Field';

    this.addValues();

    if (row.database_linked_id == null) {
      this.masterField.fieldValue = 'USERINPUT';
    } else {
      this.masterField.fieldValue = 'DATABASELINKED';
    }

    this.masterField.databaseField = row.database_linked_id;
    this.databaseFields.forEach((e) => {
      if (e.databaseFieldId == parseInt(row.database_linked_id)) {
        this.masterField.databaseField = e.databaseFieldName;
      }
    });
  }
  viewDetails(row?: any) {
    let obj = {
      pattern_postfix: row.pattern_postfix,
      pattern_prefix: row.pattern_prefix,
      field_name: row.field_name,
      field_status: row.field_status,
      field_type: row.field_type,
      database_field_name: row.database_field_name,
      master_field_values: row.master_field_values,
      matchingMethod : row.matching_technique
    }
    this.common.params = { details: [obj], title: 'info' };

    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }
  addValues() {
    this.masterField.values.push({ name: null });
  }

  spliceRow(i: number,id:number) {
    this.subgroupsName.splice(i, 1);
  }

  addSubGroupValues(id: number) {
    let obj = {};
    for (let i = 0; i < this.subgroupsCount; i++) {
      obj[i] = {
        name: this.subgroupsName[0][i].name != null ? this.subgroupsName[0][i].name : null,
        values: { fieldName: null, fieldId: null, fieldType: null }
      }
    }
    this.subgroupsName.push(obj);
  }

  fieldNameValidator: any;
  fieldTypeValidator: any;
  statusValidator: any;
  formValidator() {
    if(this.role.user_role=='COMPANYADMIN'){
      this.plantNameValidator=this.common.nullValidator(
        this.plantId,
        'Plant Mandatory'
      );
    }
    this.fieldNameValidator = this.common.nullValidator(
      this.masterField.name,
      'Field Name Mandatory'
    );
    this.fieldTypeValidator = this.common.nullValidator(
      this.masterField.type,
      'Field Type Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.masterField.status,
      'Status Mandatory'
    );
    if (
      (this.masterField.type == 'SELECTLISTSINGLE' ||
        this.masterField.type == 'SELECTLISTMULTIPLE' ||
        this.masterField.type == 'RADIOBUTTON' ||
        this.masterField.type == 'CHECKBOX') &&
      this.masterField.fieldValue != 'DATABASELINKED'
    ) {
      this.masterField.values.forEach((res) => {
        if (res.name == null) {
          return this.alterService.warn(
            'Field Value Name should not be Empty !!'
          );
        }
      });
    }

    if (this.masterField.type == 'FIELDGROUP') {
      //checking if any of the object contains null value
      let hasNullValue = this.subgroupsName.some(obj =>
        Object.values(obj).some((subgroup: { name: string | null; values: { fieldName: string | null; fieldId: string | null } }) =>
          subgroup.name === null ||
          subgroup.values.fieldName === null ||
          subgroup.values.fieldId === null
        )
      );

      if (hasNullValue) {
        return this.alterService.warn("Please Fill All Empty Fields !!")
      }
    }
    if (
      (!this.fieldNameValidator.error &&
      !this.fieldTypeValidator.error &&
      !this.statusValidator.error) &&((this.role.user_role=='COMPANYADMIN' && !this.plantNameValidator.error) || this.role.user_role!='COMPANYADMIN')
    ) {
      this.saveData();
    }
  }
}

import { Component, Input, OnInit, Output, EventEmitter, HostListener, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import * as _ from 'lodash';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @ViewChild('saveButton') saveButton!: ElementRef;
  @Input() formType: string;
  @Input() toggle:boolean = false
  @Output() saveTrigger: EventEmitter<any> = new EventEmitter<any>();
  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(
    evt: KeyboardEvent
  ) {
    if(this.userService._walkinMode==true){
      evt.preventDefault()
      this.saveFormResponse()
    }
  }
  mappedFields: any[] = [];
  formDetails = {
    formId: null,
    formTitle: null,
    formDesc: null
  };
  multivalueIndex: number = 0;

  constructor(
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public alert: AlertService,
    private modalService: NgbModal,
    public activeModal:NgbActiveModal
  ) {}

  ngAfterViewInit(): void {
    // this.saveButton.nativeElement.focus();
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    // if(this.formId!=null){
    //   this.getMappedFields(this.formId);
    // }
    // else{
    //   this.alert.error("Form doesn't exist");
    // }
    this.getMappedFields();
  }

  confirmCloseModalInWalkinMode() {
    this.common.params = {
      title: 'Are You Sure?',
      description:'All filled data will be erased',
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.closeModal();
      }
    });
  }

  closeModal(response?: any, apiHit?: any, sameStage?:boolean) {
    this.activeModal.close({ response: response, apiHit: apiHit,sameStage:sameStage });
  }

  addValue(field: any, index: number, multivalueIndex: number) {
    this.multivalueIndex = multivalueIndex + 1;
    if (this.isArray(this.mappedFields[index])) {
      this.mappedFields[index].push(_.cloneDeep(field)); // Add new field to the array
    } else {
      this.mappedFields[index] = [field]; // Convert to array if it's the first time adding
    }
    console.log("mappedField after add", this.mappedFields)
  }

  removeValue(index: number, subIndex: number,item:any) {
    if (this.mappedFields[index].length > 1) {
      this.mappedFields[index].splice(subIndex, 1);
      console.log("this.mappedFields afterrr", this.mappedFields);
    }
  }

  onValueChange(index, grpFieldIndex, multiValueIndex, grpFieldsLength, event, isMultiValueField , isFieldGroupField) {
    if(isMultiValueField){
      if(isFieldGroupField){
        this.mappedFields[index][multiValueIndex].group_fields[grpFieldIndex].values = event.values;
        this.mappedFields[index][multiValueIndex].group_fields[grpFieldIndex].sel_value = event.values[0];
      }else{
        this.mappedFields[index][multiValueIndex].sel_value = event.values[0];
        this.mappedFields[index][multiValueIndex].values = event.values;
      }
    }else{
      this.mappedFields[index].values = event.values
    }
    console.log("checklkk",this.mappedFields)
  }

  getMappedFields() {
    this.common.loading = true;
    let params = {
      formType: this.formType,
    };
    this.api.get('customForm/get-form-fields', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.formDetails.formId = res.data[0].form_id;
        this.formDetails.formTitle = res.data[0].form_title;
        this.formDetails.formDesc = res.data[0].form_description;
        this.mappedFields = res.data[0].fields
        ? res.data[0].fields.map((field) => {
          if (field.is_multivalue) {
            return [field];
          }
          return field;
        })
        : [];
        console.log("this.mappedFields",this.mappedFields)
        if (this.formType == 'Visitor Form' && this.userService._walkinMode) {
          const startTimeField = this.mappedFields.find((e) => e.database_field_name === 'start_time');
          if (startTimeField) {
            startTimeField.sel_value = new Date().toISOString().slice(0, 16);
            startTimeField.is_disabled = true;
          }

        //   if (startTimeField) {
        //     const now = new Date();
        //     now.setMinutes(now.getMinutes() + 330);
        //     startTimeField.sel_value = now.toISOString().slice(0, 16);
        // }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  onEdit(field: any) {
    field.isEditable = false;
    field.isDisabled = false;
    if (
      field != null &&
      (field.field_type == 'RADIOBUTTON' ||
        field.field_type == 'CHECKBOX' ||
        field.field_type == 'SELECTLISTSINGLE' ||
        field.field_type == 'SELECTLISTMULTIPLE')
    ) {
      this.getFieldInfoByFieldId(field);
    }
  }

  reset(){
    let tempFields = _.cloneDeep(this.mappedFields)
    tempFields.forEach((field, index) => {
      if (this.isArray(field)) {
        tempFields[index] = [field[0]]; // Keeping the first object only
        if(field[0].field_type === 'FIELDGROUP'){
          field[0].group_fields.forEach(element => {
            element.values = null;
            if(!element.is_disabled){
              element.sel_value = null;
            }
          });
        }else{
          field[0].sel_value = null;
          field[0].values = null;
        }
      }else{
        field.values = null;
      }
    });
    this.multivalueIndex = 0;
    this.mappedFields = tempFields;
  }

  saveFormResponse() {
    console.log('saving...........', this.mappedFields);
    const combinedFields = [];
    this.mappedFields.forEach((item, index) => {
      if (this.isArray(item)) {
        if(item[0].field_type == 'FIELDGROUP'){
          item.forEach((field) => {
            field.group_fields.forEach((grpField) =>{
              combinedFields.push({
                name: grpField.field_name || '',
                values: grpField.values || [],
                fieldType: grpField.field_type || '',
                _dbField: grpField.database_field_name || '',
                mandatory: grpField.mandatory || false,
                group_id: grpField.group_id || null,
                sub_group_index: grpField.sub_group_index || null,
                sub_group_name: grpField.sub_group_name || null,
                field_id: grpField.field_id || null,
              })
            })
          });
        }else{
          item.forEach((field) => {
              combinedFields.push({
                  name: field.field_name || '',
                  values: field.values || [],
                  fieldType: field.field_type || '',
                  _dbField: field.database_field_name || '',
                  mandatory: field.mandatory || false,
                  group_id: field.group_id || null,
                  sub_group_index: field.sub_group_index || null,
                  sub_group_name: field.sub_group_name || null,
                  field_id: field.field_id || null,
              });
          });
        }
      } else {
          combinedFields.push({
            name: item.field_name || '',
            values: item.values || [],
            fieldType: item.field_type || '',
            _dbField: item.database_field_name || '',
            mandatory: item.mandatory || false,
            group_id: item.group_id || null,
            sub_group_index: item.sub_group_index || null,
            sub_group_name: item.sub_group_name || null,
            field_id: item.field_id || null,
          });
      }
  });

  console.log("fghjk",combinedFields)
  const isFieldEmpty = (values: any) => {
      return (
        values == null ||
        values.length === 0 ||
        (this.isArray(values) &&
          values.every((val) => val === '' || val === null))
      );
    };
    const isMultivalueValid = (responses: any[], index: number) => {
      if (responses.length !== index + 1) {
        return false;
      }
      return !responses.some((resp) => isFieldEmpty(resp.values));
    };

    // Check for missing mandatory fields
    const missingMandatoryFields = this.mappedFields.some((field) => {
      let modifiedField = this.isArray(field) ? field[0] : field;
      if (modifiedField.mandatory) {
        let formValue: any;
        let responses: any[] = [];

        if (modifiedField.field_type === 'FIELDGROUP') {
          return modifiedField.group_fields.some((grpField) => {
            if (modifiedField.is_multivalue) {
              responses = combinedFields.filter(
                (gfv) => gfv?.field_id === grpField.field_id
              );
              return !isMultivalueValid(responses, this.multivalueIndex);
            } else {
              formValue = combinedFields.find(
                (gfv) => gfv?.field_id === grpField.field_id
              );
              return isFieldEmpty(formValue?.values);
            }
          });
        } else {
          if (modifiedField.is_multivalue) {
            responses = combinedFields.filter(
              (fv) => fv?.field_id === modifiedField.field_id
            );
            return !isMultivalueValid(responses, this.multivalueIndex);
          } else {
            formValue = combinedFields.find(
              (fv) => fv?.field_id === modifiedField.field_id
            );
            return isFieldEmpty(formValue?.values);
          }
        }
      }
      return false;
    });

    if (missingMandatoryFields) {
      this.alert.error(
        'Please fill in all mandatory fields before submitting the form.'
      );
      return;
    }

    // unique check
    const isValueUnique = (
      formValues: any[],
      value: any,
      groupId: number,
      subGroupIndex: number
    ) => {
      const filteredValues = formValues.filter(
        (item) =>
          item.group_id === groupId && item.sub_group_index === subGroupIndex
      );
      if (filteredValues.length === 0) {
        return true;
      }
      if (
        filteredValues.some(
          (item) =>
            item.values.length > 0 &&
            item.values[0].toLowerCase() === value.toLowerCase()
        )
      ) {
        return false;
      }
      return true;
    };

    const excludedFieldTypes = [
      'GEOCODEDIMAGE',
      'FILE',
      'SIGNATURE',
      'RADIOBUTTON',
      'CHECKBOX',
      'CAMERASTREAMIMG',
      'VIDEOSTREAMING',
      'BARCODEWITHIMAGE',
      'RFIDCODEWITHIMAGE',
    ];

    // Check for unique field values
    const hasDuplicateFields = combinedFields.some((field) => {
      if (
        field.group_id != null &&
        !excludedFieldTypes.includes(field.fieldType)
      ) {
        return !isValueUnique(
          combinedFields,
          field.values[0],
          field.field_id,
          field.sub_group_index
        );
      }
      return false;
    });

    if (hasDuplicateFields) {
      this.alert.error(
        'Please fill in unique values before submitting the form.'
      );
      return;
    }

    let saveFormValues = combinedFields.filter(
      (e) => e !== null && typeof e === 'object' && e.field_id !== null && e.values.length !=0 && e.values?.[0].length !=0
    );
    console.log("after filter", saveFormValues);

    const params = {
      formValues: JSON.stringify(saveFormValues),
      formId: this.formDetails.formId,
      formType: 'Visitor Form',
      walkInMode : this.userService._walkinMode
    };

    this.common.loading = true;
    this.api.post('customForm/save-form-responses', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alert.success(res.message);
          this.reset();
          this.saveTrigger.emit(true);
        } else {
          this.alert.error(res.message);
          // this.formDetails.formValues = tempValues;
          // console.log("not save",this.formDetails.formValues)
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
    this.closeModal()
  }

  getFieldInfoByFieldId(field: any) {
    let params = {
      fieldId: field.field_id,
    };
    this.common.loading = true;
    this.api.get('customForm/get-form-fields', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          field.options = res.data[0]?.options;
          this.alert.success(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  // checkUnique(event: any, field: any, parentField: any): boolean {
  //   let exculdedFieldTypes = [
  //     'GEOCODEDIMAGE',
  //     'FILE',
  //     'SIGNATURE',
  //     'RADIOBUTTON',
  //     'CHECKBOX',
  //   ]; //don't check unique for these field types
  //   if (exculdedFieldTypes.indexOf(field.field_type) != -1) {
  //     return true;
  //   } else {
  //     const isValueUnique = (
  //       formValues: any[],
  //       value: any,
  //       groupId: number,
  //       subGroupIndex: number
  //     ): boolean => {
  //       // Filter the formValues based on groupId and subGroupIndex
  //       const filteredValues = formValues.filter(
  //         (item) =>
  //           item.group_id === groupId && item.sub_group_index === subGroupIndex
  //       );

  //       // If no matching group_id and sub_group_index found, return true
  //       if (filteredValues.length === 0) {
  //         return true;
  //       }
  //       // Check if any of the filtered items have values[0] matching the given value
  //       if (field.field_type == 'SELECTLISTMULTIPLE') {
  //         let flag = true;
  //         filteredValues.some((item) => {
  //           if (item.values.length > 0 && item.values.includes(value)) {
  //             flag = false;
  //           }
  //         });
  //         return flag;
  //       } else {
  //         return !filteredValues.some(
  //           (item) =>
  //             item.values.length > 0 &&
  //             item.values[0].toLowerCase() === value.toLowerCase()
  //         );
  //       }
  //     };

  //     let value =
  //       field.field_type == 'SELECTLISTSINGLE'
  //         ? event.name
  //         : field.field_type == 'SELECTLISTMULTIPLE'
  //         ? event[event.length - 1].name
  //         : event.target.value;
  //     const isUnique = isValueUnique(
  //       this.formDetails.formValues,
  //       value,
  //       parentField.master_field_id,
  //       field.sub_group_index
  //     );
  //     return isUnique;
  //   }
  // }
}

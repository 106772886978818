<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{title|customTranslate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="showFields==true">
    <div class="field">
      <label for="stage" class="required">{{'select_stage:'|customTranslate}}</label>
      <ps-auto-suggestion (onSelected)="selectStage($event)" [data]="stageData" display="label"
        placeholder="Choose Stage" [readonly]="readOnly" [preSelected]="{label: label}">
      </ps-auto-suggestion>
    </div>

    <div class="field">
      <label for="stage" class="required">{{'select_vehicle no:'|customTranslate}}</label>
      <ps-auto-suggestion (onSelected)="selectVehicle($event,'select')" [data]="availableVehicleList"
        display="vehicle_rsn" placeholder="Choose Vehicle" (onChange)="selectVehicle($event,'input')"
        [readonly]="readOnly" [preSelected]="{vehicle_rsn: vehicle_no}">
      </ps-auto-suggestion>
      <input type="text" class="field_class" [(ngModel)]="vehicle_no" placeholder="Add Vehicle RC Number"
        *ngIf="availableVehicleList.length == 0 && !readOnly">
    </div>
  </div>

  <div *ngIf="this.rfIdEnabled == true" class="field" style="margin-top: 10px;">
      <label for="my-input" class="required" style="width: 100%">{{'select_tag_id'|customTranslate}} :
        <i class="fa fa-sync" aria-hidden="true" style="font-size: 14px; float: right; margin-top: 6px;"
          (click)="getVehicleMapping(this.processStageId)" *ngIf="processStageId!=null"></i>
        </label>
      <ps-auto-suggestion (onSelected)="selectedField($event)" [data]="getVehicleTag" display="tag_id"
        placeholder="Select Tag" name="selectedActionData" inputId="selectedActionData"
        [preSelected]="{tag_id:vehicleTagName}">
      </ps-auto-suggestion>
  </div>
</div>
<div class="modal-footer" *ngIf="showFields==true">
  <button class="btn btn-success" type="button" outline (click)="Add()"
    style="cursor: pointer;">{{button|customTranslate}}</button>
</div>

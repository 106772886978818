<div class="backdrop" *ngIf="showPicker" (click)="showPicker = false">
</div>
<div class="time-picker-container">
  <p>{{hrsLabelArr[hrsActiveIndex]}}:{{minLabelArr[minActiveIndex]}}
    <ng-container *ngIf="secLabelArr.length">
      :{{secLabelArr[secActiveIndex]}}
    </ng-container>
  </p>
  <i class="fa fa-clock" (click)="showPicker = !showPicker"></i>
  <div class="picker" *ngIf="showPicker">
    <ul class="list-group">
      <ng-container *ngFor="let label of hrsLabelArr; let i = index">
        <li class="list-group-item" (click)="hrsActiveIndex = i;emitOutput()"
          [ngClass]="{'active': hrsActiveIndex == i}">{{label}}</li>
      </ng-container>
    </ul>
    <ul class="list-group">
      <ng-container *ngFor="let label of minLabelArr; let i = index">
        <li class="list-group-item" (click)="minActiveIndex = i;emitOutput()"
          [ngClass]="{'active': minActiveIndex == i}">{{label}}</li>
      </ng-container>
    </ul>
    <ul class="list-group">
      <ng-container *ngFor="let label of secLabelArr; let i = index">
        <li class="list-group-item" (click)="secActiveIndex = i;emitOutput()"
          [ngClass]="{'active': secActiveIndex == i}">{{label}}</li>
      </ng-container>
    </ul>
    <!-- <ul class="list-group">
            <li class="list-group-item">00</li>
        </ul> -->
    <!-- <ul class="list-group">
            <li class="list-group-item">0</li>
        </ul> -->
  </div>
</div>

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dock-booking-modal',
  templateUrl: './dock-booking-modal.component.html',
  styleUrls: ['./dock-booking-modal.component.scss'],
})
export class DockBookingModalComponent implements OnInit {
  @Input() public refData;
  @ViewChild('detail2') detail2Template: any;

  vehicleList: any[] = [];
  driverList: any[] = [];
  selectedVehicleName: string = null;
  selectedDriverName: string = null;
  selectedVehicleId: number = null;
  selectedDriverId: number = null;
  driverValidator: any = null;
  vehicleValidator: any = null;
  assign: string = 'Driver';
  existingTransaction: any = [];
  showAddDriver: boolean = false;
  loaderInTime: any;
  loaderOutTime: any;
  currentInd: number = 0;
  currentState: boolean = false;
  bookingFlag: number = 0;
  showButton: boolean = true;
  approxInTime: any;
  productList:any=[];
  prevTxnId:number=null;
  txnId:number=null;

  constructor(
    public activeModal: NgbActiveModal,
    public common: CommonService,
    public api: ApiService,
    public alert: AlertService,
    public modalService: NgbModal,
    public datePipe: DatePipe
  ) {}

  async ngOnInit() {
    console.log("ertyui",this.refData);
    this.getVehicleList();
    await this.getAllDriver();
    if(this.refData.editFlag==true){
      this.selectedVehicleId=this.refData.vehicleId;
      this.selectedVehicleName=this.refData.vehicleName;
      let driverName=this.driverList.find((driver)=>{
        return driver.id==this.refData.driverId
      }).driverName;
      this.selectedDriverId=this.refData.driverId;
      this.selectedDriverName=driverName;
      this.currentState=true;
      this.showAddDriver=true;
      this.prevTxnId=this.refData.transaction_id;
      this.txnId=this.refData.transaction_id;
      // this.getExistingTransaction();
    }
    if (this.refData.bookingsFlag && this.refData.bookingsFlag == true) {
      this.loaderInTime = this.refData.startTime.substr(11, 5);
      this.loaderOutTime = this.refData.endTime.substr(11, 5);
    } else this.getLoaderTime();
    const combinedDateTimeString = `${this.refData.activeDate}T${this.loaderInTime}:00`;
    this.approxInTime = new Date(combinedDateTimeString);
    this.approxInTime = this.common.dateFormatter(this.approxInTime,'YYYY-MM-DD HH:MI:SS');
  }

  // onSelectVehicle(event:any){
  //   this.selectedVehicleId = event.vehicle_id;
  //   this.selectedVehicleName =  event.vehicle_rsn;
  // }

  onSelectVehicle(event: any, type: string) {
    this.currentState=false;
    //GJ26-SF-8612 (Already Booked)
    if (type == 'select') {
      this.selectedVehicleId = event.vehicle_id;
      this.selectedVehicleName = event.vehicle_rsn;
      // this.bookingFlag = this.checkBookingStatus(event.vehicle_rsn);
      this.bookingFlag = event.status=='Inprogress'?2:(event.status == 'Booked'?1:0);
      if(this.bookingFlag==1 || this.bookingFlag==2){
        this.existingTransaction=event;
        this.productList= event.products!=null?JSON.parse(event.products):[];
        // this.existingTransaction.product_name=JSON.parse(event.products);
        // this.existingTransaction.product_id=JSON.parse(event.product_id);
        // let exists = this.existingTransaction.product_id.some(field=>field==this.refData.productId);
        let exists = this.productList.some(field=>field.product_id==this.refData.productId);
        if(exists){
            //   console.log("ijwex");
          this.showButton=false;
        }else this.showButton=true;
        // if(event.product_id==this.refData.productId){
        //   console.log("ijwex");
        //   this.showButton=false;
        // }
        this.showAddDriver=false;
        // console.log("bhwexjw",this.showButton);
      }
      else{
        this.showAddDriver=true;
      }
    } else {
      this.selectedVehicleId = null;
      this.selectedVehicleName = event;
      this.showAddDriver = true;
    }
    this.vehicleValidator = this.common.nullValidator(
      this.selectedVehicleName!=null && this.selectedVehicleName.trim(),
      'Vehicle Mandatory'
    );
    if(!this.vehicleValidator.error)this.currentState = true;
    this.txnId=event.transaction_id;
  }

  // checkBookingStatus(str) {
  //   let parts = str.split(' (');
  //   return parts.length == 1 ? 0 : parts[1] == 'Already Booked)' ? 1 : 2;
  // }

  onSelectDriver(event: any, type: string) {
    if (type == 'select') {
      this.selectedDriverId = event.id;
      this.selectedDriverName = event.driverName;
    } else {
      this.selectedDriverId = null;
      this.selectedDriverName = null;
    }
  }

  getVehicleList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api
        .get(
          'vehicle/get-all-vehicles?existTransaction=true&dockId=' +
            this.refData.destinationId +
            '&bookingDate=' +
            this.refData.activeDate
        )
        .subscribe(
          (res: any) => {
            this.vehicleList = res.data;
            this.common.loading = false;
            resolve(this.vehicleList);
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
    });
  }

  getAllDriver() : Promise<any> {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    this.api.get('driver/get-all-drivers').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.driverList = res.data;
        resolve(this.driverList);
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        reject(null);
      }
    );
    });
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  saveData() {
    // const combinedDateTimeString = `${this.refData.activeDate}T${this.loaderInTime}:00`;
    // let combinedDateTime = new Date(combinedDateTimeString);
    // combinedDateTime = this.common.dateFormatter(combinedDateTime , "DD/MM/YY HH:MI:SS");
    this.common.loading = true;
    let params = {
      vehicleId: this.selectedVehicleId,
      driverId: this.selectedDriverId,
      vehicleRsn: this.selectedVehicleName,
      driverName: this.selectedDriverName,
      destinationId: this.refData.destinationId,
      dailySlotId: this.refData.dailySlotId,
      queueIndex: this.refData.slotIndex + 1,
      bookingDate: this.refData.activeDate.toString(),
      id: this.refData.editFlag == true ? this.refData.bookingId : null,
      slotQueueId:this.refData.editFlag == true ? this.refData.slotQueueId : null,
      approxInTime: this.approxInTime,
      prevTransactionId:this.prevTxnId,
      transactionId:this.txnId
    };
    this.api.post('dock/add-dock-appointment', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alert.success(res.message);
        } else {
          this.alert.error(res.message);
        }
        this.closeModal();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  formValidator() {
    this.driverValidator = this.common.nullValidator(
      this.selectedDriverName,
      'Driver Mandatory'
    );
    if (!this.driverValidator.error) {
      this.saveData();
    }
  }

  getLoaderTime() {
    //converting time to seconds using reduce;
    const toSeconds = (time: string) =>
      time.split(':').reduce((acc, time) => 60 * acc + +time, 0);
    //converting sseconds to hh:mm time
    const toTimeString = (seconds: number) =>
      new Date(seconds * 1000).toISOString().substr(11, 5);

    const initialSeconds = toSeconds(this.refData.startTime);
    const factorSeconds = toSeconds(this.refData.tat);
    const multipliedSeconds = factorSeconds * this.refData.slotIndex;
    const totalSeconds = initialSeconds + multipliedSeconds;
    this.loaderInTime = toTimeString(totalSeconds);
    this.loaderOutTime = toTimeString(totalSeconds + factorSeconds);
  }

  openAssignDriver(detail, assignee: string) {
    this.assign = assignee;
    const activeModal = this.modalService.open(detail, {
      size: 'lg',
    });
  }
  savingChanges(event) {
    this.onSelectDriver({ id: event.id, driverName: event.name }, 'select');
    this.getAllDriver();
  }

  onCreateNew(){
    this.currentInd=1;
    this.txnId=null;
    this.selectedDriverId=null;
    this.selectedDriverName=null;
  }

  onNext(){
    if(this.prevTxnId!=null && this.prevTxnId!=this.txnId){
      this.selectedDriverId=null;
      this.selectedDriverName=null;
    }
  }

  // getExistingTransaction(){
  //   this.common.loading = true;
  //   this.api.get("transaction/get-existing-transaction?vehicleId="+this.selectedVehicleId + "&dockId=" + this.refData.destinationId).subscribe(
  //     (res: any) => {
  //       this.common.loading = false;
  //       this.existingTransaction=res.data.length!=0?res.data[0]:[];
  //       this.showAddDriver=this.existingTransaction.length==0;
  //       console.log("current state",this.currentState);
  //       if(this.existingTransaction.length==0){
  //         this.checkFlag();
  //         // this.alert.error("Booking already exists for the vehicle. Choose another vehicle.");
  //         // if(flag && flag==true){
  //         //   this.currentState=false;
  //         // }
  //       }
  //       else{
  //       this.currentState=false;
  //       }
  //     },
  //     (err: any) => {
  //       console.error('Error: ', err);
  //       this.common.loading = false;
  //     }
  //   );
  // }

  // checkFlag() {
  //   let flag = this.vehicleList.find((vehicle) => {
  //     return vehicle.vehicle_id == this.selectedVehicleId;
  //   }).booking_flag;
  //   if (flag == true) {
  //     this.currentState = false;
  //     this.alert.error(
  //       'Booking already exists for the vehicle. Choose another vehicle.'
  //     );
  //   } else {
  //     this.currentState = true;
  //     this.currentInd = 1;
  //   }
  // }

  linktoExisting() {
    this.common.loading = true;
    let params = {
      vehicleId: this.selectedVehicleId,
      destinationId: this.refData.destinationId,
      dailySlotId: this.refData.dailySlotId,
      queueIndex: this.refData.slotIndex + 1,
      stageId: this.existingTransaction.stage_id,
      transactionId: this.existingTransaction.transaction_id,
      driverId: this.existingTransaction.driver_id,
      bookingDate: new Date().toISOString(),
      id: this.refData.editFlag == true ? this.refData.bookingId : null,
      slotQueueId: this.refData.editFlag == true ? this.refData.slotQueueId : null,
      mappingType: this.bookingFlag == 1 ? 'AlreadyBooked' : 'ActiveTrip',
      approxInTime: this.approxInTime,
      prevTransactionId:this.prevTxnId
    };
    this.api.post('dock/linking-to-existing-transaction', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alert.success(res.message);
          this.closeModal();
        } else {
          this.alert.error(res.message);
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  tripTrackingModal() {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: this.existingTransaction.transaction_id,
      vehicleName:
        this.selectedVehicleName + '-' + this.existingTransaction.process_name,
    };
  }
}

<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"User CheckList Mapping"| translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{("user" | translate)}} </label>
                  <ps-auto-suggestion [isMultiSelect]="isMultiSelect" (onSelected)="selectedUser($event)" [data]="user"
                    display="name" id="user" placeholder="Choose User" [preSelected]="selectedData.user">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="userValidator && userValidator.error">
                    <span>{{userValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{("CheckList" | translate)}} </label>
                  <ps-auto-suggestion [isMultiSelect]="isMultiSelect" (onSelected)="selectedCheckList($event)"
                    [data]="checklistData" display="checkListTitle" id="checkListTitle" placeholder="Select CheckList"
                    [preSelected]="selectedData.checklist">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="checklistValidator && checklistValidator.error">
                    <span>{{checklistValidator.msg}} </span>
                  </div>
                </div>

              </div>
              <div class="form-group action-block">
                <div class="btn-block">
                  <div class="note">
                    <div><label class="required">{{"note"|translate}}</label></div>
                    <div class="note_text">The Changes will be Appilcable On Next Day</div>
                  </div>
                  <div>
                    <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{("back" | translate)}}
                    </button>
                    <button class="btn btn-warning" (click)="reset()"> {{("reset" | translate)}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"
                      *ngIf="userService.permissionMap.get('add_user_action_mapping_access')==true"> {{buttonName
                      |translate}}</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>



      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"user checklist mapping list" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"s.no." | translate}}</th>
                    <th>{{"user name" | translate}}</th>
                    <th>{{"checklist" | translate}}</th>
                    <!-- <th>{{"status" | translate}}</th> -->
                    <th class="action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of tableData let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.user.userName}}</td>
                    <td>{{getChecklistString(row.checklist)}}</td>
                    <!-- <td>{{(row.isActive==true?'Active':'Inactive')}}</td> -->
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewUserActionMapping(row)"
                          *ngIf="userService.permissionMap.get('view_user_action_mapping_access')==true"> {{("view" |
                          translate)}} </button>
                        <button class="btn btn-success" (click)="editUserActionMapping(row)"
                          *ngIf="userService.permissionMap.get('edit_user_action_mapping_access')==true"> {{("edit" |
                          translate)}} </button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)"> {{("delete" | translate)}}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

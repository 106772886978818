<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'stage_dashboard'|customTranslate}}
                </h3>
              </div>
              <div style="display: flex; position: relative;">
                <ng-container
                  *ngIf="processStageList?.length >0 && userService.permissionMap.get('handle_hardware_access')">
                  <ps-auto-suggestion [data]="processStageList" display="process_stage"
                    placeholder="Choose Process Stage" style="margin-right: 5px;"
                    (onSelected)="boomBarrierStageId=$event.stage_id;boomBarrierStageName=$event.process_stage"
                    [preSelected]="{process_stage:boomBarrierStageName}">
                  </ps-auto-suggestion>
                  <button type="button" class="btn boom_barrier" matTooltip="Open Boom Barrier"
                    matTooltipPosition="below" (click)="boomBarrierAction(true)"
                    style="margin-right: 5px;background-color: #a2ffa2ad;" [disabled]="boomBarrierStageId==null"><img
                      src="assets/images/boom_barrier_up.png"
                      style="width:20px;filter: contrast(500%);zoom: 120%;"></button>
                  <button type="button" class="btn boom_barrier" [disabled]="boomBarrierStageId==null"
                    style="margin-right: 10px;margin-left: 2px;background-color: #ffa2a2ad;"
                    (click)="boomBarrierAction(false)" matTooltip="Close Boom Barrier" matTooltipPosition="below"><img
                      src="assets/images/boom_barrier_down.png"
                      style="width:20px;filter: contrast(500%);zoom: 130%;"></button>
                </ng-container>
                <img src="assets/images/button.png" alt="" matTooltip="Add Vehicle" matTooltipPosition="below"
                  *ngIf="userLocationState.isOnEntryStage" (click)="addVehicle()" height="30px" width="30px">
                <button class="caret_icon" (click)="selectToggle = !selectToggle"><i
                    class="fa fa-caret-left"></i></button>
              </div>
            </div>

            <div class="card-body">
              <div class="dash_design">
                <div class="box_sepration box_sepration_sidebar box_in">
                  <h6>
                    <div>
                      <i class="fa search_vehicle" matTooltip="Search Vehicle" matTooltipPosition="below"
                        (click)="activeSearchBar.in = !activeSearchBar.in;searchVehicle(null, 'in')"
                        [ngClass]="activeSearchBar.in ? 'fa-close':'fa-search'"></i>
                      <span *ngIf="!activeSearchBar.in">{{'vehicle_in'|customTranslate}}</span><i class="fa fa-sync"
                        aria-hidden="true" matTooltip="Refresh List" matTooltipPosition="below"
                        style="font-size: 18px; float: right; padding-top: 3px; padding-right: 8px;"
                        (click)="getVehicleListByType(selectedVehicle?.vehicle_rc_no)"></i>
                    </div>
                    <div>
                      <input class="search_input" name="inVehicleSearch" id="inVehicleSearch" type="text"
                        (keyup)="searchVehicle($event, 'in')" *ngIf="activeSearchBar.in" placeholder="Vehicle RC NO. / Token No." />
                    </div>
                  </h6>

                  <span class="loader" *ngIf="loaderState.in"></span>
                  <ul *ngIf="!loaderState.in" style="width: 100%;">
                    <li *ngFor="let vehicle of vehicleList['in']; let i = index" id="myDiv"
                      [style.background-color]="vehicle.vehicle_id == selectedVehicle?.vehicle_id ? '#99e9ff' : vehicle.flow_type == 0 ? '#ff9fa8' : 'white'"
                      (click)="selectVehicle(i);">
                      <div class="top-row d-flex justify-content-between my-1 mx-2">
                        <div class="left_sec d-flex">
                          <i class="fa fa-truck icon_vehicle"></i>
                          <span class="vehicle_number">{{vehicle.vehicle_rc_no}} </span>
                        </div>
                        <div class="right_sec" (click)="$event.stopPropagation()">
                          <img src="assets/images/call-icon-01.png" alt="" matTooltip="Call Driver"
                            matTooltipPosition="below" (click)="driverIVRCallModal(vehicle)">
                          <!-- <img src="assets/images/chat-icon.png" alt="" (click)="tripCommentTrail(vehicle)"> -->
                          <app-trip-comment-trail (callGetCompliance)="getComplianceAlertLogs()" [tripId]="vehicle.txnid" [complainceData]="vehicle" [entityType]="'TRIP'"></app-trip-comment-trail>
                          <img src="assets/images/trip-details(3).png" alt="" matTooltip="Trip Detail"
                            matTooltipPosition="below" (click)="tripModal(vehicle)">
                          <img src="assets/images/trip-tracking(3).png" alt="" matTooltip="Trip Tracking"
                            matTooltipPosition="below" (click)="tripTrackingModal(vehicle)">
                        </div>
                      </div>
                      <div class="bottom-row d-flex justify-content-between my-1  py-2 mx-2">
                        <div class="left_sec">
                          <p>Stage: <span>{{vehicle.stage_name}}</span> </p>
                          <p *ngIf="vehicle?.driver_name != null">Driver: <span
                              (click)="showDetails('Driver',vehicle?.driver_id,detail)"
                              style="color:#3f7ac7;cursor: pointer;">{{vehicle?.driver_name| slice:0:20}}</span> </p>
                            </div>
                        <div class="right_sec">
                          <p>Token: <span class="info-jellybean p-1 text-xs my-1">#{{userLocationState.txnPrefix + vehicle?.txnid + userLocationState.txnPostfix}}</span></p>
                          <!-- <p *ngIf="vehicle.dock_name!=null">Dock: <span>{{vehicle.dock_name}}</span> </p>
                          <p *ngIf="vehicle.parking_slot_name!=null">Parking: <span>{{vehicle.parking_slot_name}}</span> -->
                          <!-- </p> -->
                        </div>
                        <div class="que_count" [class.active]="vehicle.isPriority">
                          {{vehicle.isPriority ? "Priority - " + vehicle.queue_index : vehicle.queue_index}}
                        </div>
                      </div>
                    </li>
                  </ul>
                  <img src="assets/images/no-vehicle-found_2.gif" alt="" class="norecord" width="50px" height="auto"
                    *ngIf="vehicleList['in'].length == 0 && !loaderState.in">
                </div>

                <div class="box_sepration box_sepration_mid">
                  <div style="background-color: #cfcfcf;">
                    <p
                      style="margin-bottom: 0;font-size: 12px;text-align: center;padding: 5px;font-weight: 600;color: red;">
                      <span *ngIf="userLocationState.enableCurrentActions==false"
                        style="color:red">***{{'user_dashboard_message'|customTranslate}}***</span>
                      <span *ngIf="selectedVehicleIndex==null"
                        style="color:red">***{{'select_vehicle_messsage'|customTranslate}}***</span>
                    </p>
                  </div>
                  <div *ngIf="selectedVehicleIndex!=null"
                    style="background-color: #cfcfcf;display: flex;justify-content: space-around;">
                    <p style="margin-bottom: 0; font-size: 14px; padding: 4px;" *ngIf="!processEditFlag">
                      <span style="font-weight: bold;">{{'process'|customTranslate}}:</span>
                      {{selectedVehicle?.process_name}}
                      <i class="fa fa-pencil-square-o" aria-hidden="true" *ngIf="selectedVehicle?.stage_index== 1 &&
                      userLocationState.canEditProcess" (click)="assignProcess(selectedVehicle, true)"></i>
                    </p>
                    <p style="margin-bottom: 0;font-size: 14px;padding: 4px;">
                      <span style="font-weight: bold;">{{'stage'|customTranslate}}:
                      </span>{{selectedVehicle?.stage_name}}
                    </p>
                  </div>
                  <!-- <div style="background-color: #cfcfcf;">
                    <p
                      style="margin-bottom: 0;font-size: 12px;text-align: center;padding: 5px;font-weight: 600;color: red;">
                      <span *ngIf="lastTripActions.length"
                        style="color:black">***{{'last_trip_msg'|customTranslate}}***</span>
                    </p>
                  </div>

                  <span class="loader" *ngIf="loaderState.stageForm" style="margin-top: -100px;"></span>

                  <div class="vehicle_form_box">
                    <div *ngFor="let action of lastTripActions; let i=index">
                     <div style="display: flex;
                     justify-content: space-between;
                     margin: 15px;">
                      <div>
                      <span style="font-size: small;">{{action.action_name}}</span>
                      </div>
                      <div>
                        <span style="margin-right: 10px;" class="status-badge" [ngClass]="{'badge-danger':action.response_status=='FAIL','badge-secondary':action.response_status=='OVERRIDED PASS'}">{{action.response_status}}</span>
                        <i class="fa fa-info-circle icon_info" matTooltip="Action Data" matTooltipPosition="below" (click)="actionDetail(action,'pre',action.action_type,true)"></i>
                      </div>
                     </div>

                    </div>
                  </div> -->

                  <div class="button_sec">
                    <button type="button" [disabled]="!userLocationState.canApprove" *ngIf="selectedVehicle"
                      class="btn btn-success"
                      (click)="checkNextStages('nextStages',content)">{{'approve'|customTranslate}}</button>
                    <button type="button" class="btn btn-danger" style="background-color:red" *ngIf="selectedVehicle"
                      (click)="openRemarkModal(); txnFailRemark = true">{{'reject'|customTranslate}}</button>

                  </div>
                  <!-- <div class="button_sec" *ngIf="selectedVehicle?.flow_type == 0">
                    <button type="button" [disabled]="!userLocationState.canReject" class="btn btn-danger"
                      (click)="checkNextStages('nextRejectStage',content)">{{'move_out'|customTranslate}}</button>
                  </div> -->

                  <div class="box_sepration_box">
                    <h6>{{'my_action'|customTranslate}} <i class="fa fa-sync" matTooltip="Refresh List"
                        matTooltipPosition="below" aria-hidden="true"
                        style="font-size: 18px; float: right; padding-top: 3px; padding-right: 8px;"
                        (click)="getDashboardActions('curr')"></i></h6>
                    <span class="loader" *ngIf="loaderState.currAction" style="margin-top: 130px"></span>
                    <ul>
                      <ng-container *ngIf="actionList.curr.length">
                        <li
                          [ngStyle]="{'background-color':curr.response_status.toLowerCase()=='pass'?'#d1ffd1':(curr.response_status.toLowerCase()=='pending' ||curr.response_status.toLowerCase()=='prePending')?'white':curr.response_status.toLowerCase()=='fail'?'#ff9e9e':'rgb(248 226 187)'}"
                          *ngFor="let curr of actionList.curr;let i = index">
                          <p>

                            <img class="action_gif" *ngIf="curr.response_status.toLowerCase() == 'pass'"
                              src="assets/images/approve_gif.gif">
                            <img class="action_gif" *ngIf="curr.response_status.toLowerCase() == 'fail'"
                              src="assets/images/rejected_gif.gif">
                            <img class="action_gif" *ngIf="curr.response_status.toLowerCase() == 'overrided'"
                              src="assets/images/override_gif.gif">
                            <i class="fa fa-circle-thin" aria-hidden="true"
                              *ngIf="curr.response_status.toLowerCase() == 'prepending' || curr.response_status.toLowerCase() == 'pending'"></i>
                            {{curr.action_name}}
                            <span *ngIf="!curr.isMandatory" class="pill bg-yellow-100 text-yellow-800">
                              Optional</span>
                            <span *ngIf="curr.response_status.toLowerCase()=='prepending'"
                              style="color:#cfcfcf">{{('pre_action_pending'|customTranslate).toUpperCase()}}</span>
                          </p>
                          <i *ngIf=" curr.response_status.toLowerCase() != 'overrided' && curr.isEditable && editable==false && curr.response_status!='pending'" class="fa fa-edit icon_edit" matTooltip="Edit Action" matTooltipPosition="below"
                          (click)="curr.response_status='pending';curr.action_operation_type=='form'?openAction(curr): null "></i>
                          <i class="fa fa-window-restore icon_override"
                            *ngIf="curr.response_status.toLowerCase()=='fail'" matTooltip="Action Overriding"
                            matTooltipPosition="below" (click)="overrideAction(curr)"></i>
                          <div class="toggle_box clip_pass"
                            *ngIf="curr.action_operation_type=='boolean'&& userLocationState.enableCurrentActions==true && (curr.response_status=='pending' || editable==true)">
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                              <mat-button-toggle class="pass_btn_green" value="yes"
                                (click)="storeBooleanActionResult(curr,'PASS');editable = false"><i class="fa fa-check"></i>
                                {{'PASS'|customTranslate}}
                              </mat-button-toggle>
                              <mat-button-toggle class="fail_btn_red" value="no" (click)="currentAction=curr;editable = false"
                                data-toggle="modal" data-target="#exampleModal"><i class="fa fa-times"></i>
                                {{'FAIL'|customTranslate}}
                              </mat-button-toggle>
                            </mat-button-toggle-group>
                          </div>
                          <i *ngIf="curr.action_operation_type=='form'&& userLocationState.enableCurrentActions && curr.response_status=='pending'"

                            class="fa fa-clipboard clip_pass" matTooltip="Form" matTooltipPosition="below"
                            aria-hidden="true" (click)="openAction(curr)"></i>
                          <i class="fa fa-info-circle icon_info" matTooltip="Action Data" matTooltipPosition="below"
                            (click)="actionDetail(curr,'pre',1,false)"></i>

                        </li>
                      </ng-container>
                      <ng-container *ngIf="selectedVehicleIndex != null">

                        <li
                          *ngIf="userService.permissionMap.get('assign_dock_access')==true && selectedVehicle?.flow_type != 0"
                          [ngStyle]="{'background-color':userLocationState.dockAssigned ?'#d1ffd1':'white'}"
                          class="custom_shadow">
                          <p><i class="fa fa-circle-thin" aria-hidden="true"></i>{{"assign_dock" | customTranslate}}
                            <span class="pill bg-yellow-100 text-yellow-800">
                              Optional</span>
                          </p>

                          <i class="fa fa-clipboard clip_pass" matTooltip="Form" matTooltipPosition="below"
                            aria-hidden="true" (click)="openDock()"></i>
                        </li>
                        <div class="parking_name" *ngIf="selectedVehicle?.dock_name != null">
                          {{"alloted_dock" | customTranslate}}:{{selectedVehicle?.dock_name}}</div>

                        <li class="custom_shadow"
                          *ngIf="selectedVehicle?.flow_type !=0 && userService.permissionMap.get('assign_parking_slot_access')==true"
                          [ngStyle]="{'background-color':userLocationState.parkingAssigned ?'#d1ffd1':'white'}">
                          <p><i class="fa fa-circle-thin" aria-hidden="true"></i>{{"assign_parking_slot" | customTranslate}}
                            <span class="pill bg-yellow-100 text-yellow-800">
                              Optional</span>
                          </p>
                          <i class="fa fa-clipboard clip_pass" matTooltip="Form" matTooltipPosition="below"
                            aria-hidden="true" (click)="openParking()"></i>
                        </li>
                        <div class="parking_name"
                          *ngIf="selectedVehicle?.parking_slot_name!=null &&  selectedVehicle?.flow_type != 0">
                          {{"alloted_parking_slot" |
                          customTranslate}}:{{selectedVehicle?.parking_slot_name}}</div>

                        <li class="custom_shadow" *ngIf="userService.permissionMap.get('assign_user_access')"
                          [ngStyle]="{'background-color':userLocationState.userAssigned ?'#d1ffd1':'white'}">
                          <p><i class="fa fa-circle-thin" aria-hidden="true"></i>{{"assign_user" | customTranslate}}
                            <span class="pill bg-yellow-100 text-yellow-800">
                              Optional</span>
                          </p>
                          <i class="fa fa-clipboard clip_pass" matTooltip="Form" matTooltipPosition="below"
                            aria-hidden="true" (click)="openAssignUserModal()"></i>
                        </li>

                        <li class="custom_shadow"
                          *ngIf="userLocationState.rfidEnabled && userService.permissionMap.get('assign_tag_access')==true"
                          [ngStyle]="{'background-color':userLocationState.tagAssigned ?'#d1ffd1':'white'}">
                          <p><i class="fa fa-circle-thin" aria-hidden="true"></i>{{"assign_tag" | customTranslate}}
                            <span class="pill bg-yellow-100 text-yellow-800">
                              Optional</span>
                          </p>
                          <i class="fa fa-clipboard clip_pass" matTooltip="Form" matTooltipPosition="below"
                            aria-hidden="true" (click)="addVehicle(true)"></i>
                        </li>

                        <li class="custom_shadow" *ngIf="userService.permissionMap.get('assign_driver_access')==true"
                          [ngStyle]="{'background-color':userLocationState.driverAssigned ?'#d1ffd1':'white'}">
                          <p><i class="fa fa-circle-thin" aria-hidden="true"></i>{{"assign_driver" | customTranslate}}
                            <span class="pill bg-yellow-100 text-yellow-800">
                              Optional</span>
                          </p>
                          <i class="fa fa-clipboard clip_pass" aria-hidden="true" matTooltip="Form"
                            matTooltipPosition="below"
                            (click)="assignDriverOrTransporter('Driver',selectedVehicle?.driver_name,selectedVehicle?.driver_id,selectedVehicleIndex)"></i>
                        </li>
                        <div class="parking_name" *ngIf="selectedVehicle?.driver_name != null" style="display: flex;
                    justify-content: space-between;">
                          <div>{{"Assigned" | customTranslate}} {{'Driver'|customTranslate}}:{{selectedVehicle?.driver_name}}
                          </div>
                          <div class="details" (click)="showDetails('Driver',selectedVehicle?.driver_id,detail)"> <i
                              class="fa fa-info-circle fa-lg"></i></div>
                        </div>

                        <li class="custom_shadow"
                          *ngIf="userService.permissionMap.get('assign_transporter_access')==true"
                          [ngStyle]="{'background-color':userLocationState.transporterAssigned ?'#d1ffd1':'white'}">
                          <p><i class="fa fa-circle-thin" aria-hidden="true"></i>{{"assign_transporter" | customTranslate}}
                            <span class="pill bg-yellow-100 text-yellow-800">
                              Optional</span>
                          </p>
                          <i class="fa fa-clipboard clip_pass" aria-hidden="true" matTooltip="Form"
                            matTooltipPosition="below"
                            (click)="assignDriverOrTransporter('transporter',selectedVehicle?.transporter_name,selectedVehicle?.transporter_id,selectedVehicleIndex)"></i>
                        </li>
                        <div class="parking_name" style="display: flex;
                    justify-content: space-between;" *ngIf="selectedVehicle?.transporter_name != null">
                          <div>{{"Assigned" | customTranslate}}
                            {{'transporter'|customTranslate}}:{{selectedVehicle?.transporter_name}}</div>
                          <div class="details"
                            (click)="showDetails('Transporter',selectedVehicle?.transporter_id,detail)"> <i
                              class="fa fa-info-circle fa-lg"></i></div>
                        </div>

                        <!-- <li class="custom_shadow" *ngIf="userService.permissionMap.get('assign_product_access')==true"
                          [ngStyle]="{'background-color':userLocationState.productAssigned ?'#d1ffd1':'white'}">
                          <p><i class="fa fa-circle-thin" aria-hidden="true"></i>{{"assign_product" | customTranslate}}
                            ({{"optional" | customTranslate}})</p>
                          <i class="fa fa-clipboard clip_pass" aria-hidden="true" matTooltip="Form" matTooltipPosition="below" (click)="assignProduct(selectedVehicle?.product_name,selectedVehicle?.product_id)"></i>
                        </li> -->
                        <div class="parking_name" *ngIf="selectedVehicle?.product_name!=null">
                          {{"alloted_material" |
                          customTranslate}}: {{selectedVehicle?.product_name}}</div>
                      </ng-container>

                    </ul>
                    <img src="assets/images/no-action-found.gif" alt="" class="norecord" style="margin-top:150px ;height: 73px;
                    width: 68px;" alt="" *ngIf="actionList.curr.length==0 && !loaderState.currAction">
                  </div>
                </div>

                <!-- <div class="box_sepration box_sepration_box">

                  <div class="required_div">
                    <h6>{{'required_condition'|customTranslate}}<i class="fa fa-sync" aria-hidden="true" matTooltip="Refresh List" matTooltipPosition="below"
                        style="font-size: 18px; float: right; padding-top: 3px; padding-right: 8px;"
                        (click)="getDashboardActions('pre')"></i></h6>
                    <span class="loader" *ngIf="loaderState.preAction"></span>
                    <ul *ngIf="actionList.pre.length">
                      <li
                        [ngStyle]="{'background-color':pre.response_status.toLowerCase()=='pass'?'#d1ffd1':(pre.response_status.toLowerCase()=='pending'||pre.response_status=='prePending')?'white':pre.response_status.toLowerCase()=='fail'?'#ff9e9e':'rgb(248 226 187)'}"
                        *ngFor="let pre of actionList.pre">
                        <p>{{pre.action_name}}</p>
                        <i class="fa fa-info-circle" matTooltip="Action Data" matTooltipPosition="below" placement="top" (click)="actionDetail(pre,'pre',-1,false)"></i>
                      </li>
                    </ul>
                    <img src="assets/images/no-action-found.gif" alt="" class="norecord" alt=""
                      *ngIf="actionList.pre.length == 0 && !loaderState.preAction" style="height: 73px;
                      width: 68px;">
                  </div>

                  <div class="post_div">
                    <h6>{{'post_action'|customTranslate}}<i class="fa fa-sync" aria-hidden="true" matTooltip="Refresh List" matTooltipPosition="below"
                        style="font-size: 18px; float: right; padding-top: 3px; padding-right: 8px;"
                        (click)="getDashboardActions('post')"></i></h6>
                    <span class="loader" *ngIf="loaderState.postAction"></span>
                    <ul *ngIf="actionList.post.length ">
                      <li class=""
                        [ngStyle]="{'background-color':post.response_status.toLowerCase()=='pass'?'#d1ffd1':(post.response_status.toLowerCase()=='pending'||post.response_status=='prePending')?'white':post.response_status.toLowerCase()=='fail'?'#ff9e9e':'rgb(248 226 187)'}"
                        *ngFor="let post of actionList.post">
                        <p>{{post.action_name}}</p>
                        <i *ngIf="userLocationState.canApprove && post.response_status!='pass'"
                          class="fa fa-sync refresh_icons mr-1" matTooltip="Refresh List" matTooltipPosition="below" style="color:green" aria-hidden="true"></i>
                        <i class="fa fa-info-circle" matTooltip="Action Data" matTooltipPosition="below" (click)="actionDetail(post,'pre',1,false)"></i>
                      </li>
                    </ul>
                    <img src="assets/images/no-action-found.gif" class="norecord" alt=""
                      *ngIf="actionList.post.length==0 && !loaderState.postAction" style="height: 73px;
                      width: 68px;">
                  </div>
                </div> -->

                <!-- <div class="compliance_error box_out">
                  <div class="title_row">
                    <p>Compliance Error</p>
                    <i class="fa fa-sync" matTooltip="Refresh List" matTooltipPosition="below"
                      (click)="getComplianceAlertLogs()"></i>
                  </div>
                  <div class="error_div" *ngIf="selectedVehicleIndex != null">
                    <ng-container *ngFor="let compliance of complainceAlertLog">
                      <div class="error_row" *ngIf="compliance.log_type!='prev_process'">
                        <div class="error_left">{{compliance.subcategory_or_condition_name == 'Custom
                          Field'?compliance.title:compliance.subcategory_or_condition_name + '
                          ('+compliance.category_or_alert_name+')'}}
                          <span class="pill"
                            [ngStyle]="{'background-color':compliance.type_color,'color':'white'}">{{compliance.severity_or_status_name}}</span>

                        </div>
                        <i class="fa fa-info-circle" (click)="view(compliance)"></i>
                        <button type="button" class="error_btn"
                          *ngIf="compliance.mapping_type!=null && compliance.mapping_type!=0"
                          (click)="saveComplianceLog(compliance)">ReActive</button>
                      </div>
                      <div class="error_row" *ngIf="compliance.log_type=='prev_process' && !compliance.same_process">
                        <div class="error_left">Previous Trip of vehicle was for {{compliance.prev_process}}</div>
                      </div>
                    </ng-container>
                  </div>

                  <div class="add_compliance">
                    <button [disabled]="selectedVehicleIndex == null" (click)="addCompliance()">+ Add
                      Compliance</button>
                  </div>
                </div> -->

                <app-compliance [refData]="currentCompliance" style="width:30%"></app-compliance>

                <div class="overlay" *ngIf="!selectToggle">
                  <div class="box_sepration box_sepration_sidebar box_click">
                    <h6>
                      <div class="close_icon" (click)="selectToggle = true"><i class="fa fa-times"></i></div>
                      <div>
                        <i class="fa search_vehicle" matTooltip="Search Vehicle" matTooltipPosition="below"
                          (click)="activeSearchBar.out = !activeSearchBar.out;searchVehicle(null,'out')"
                          [ngClass]="activeSearchBar.out ? 'fa-close':'fa-search'"></i>
                        <span *ngIf="!activeSearchBar.out">{{'vehicle_out'|customTranslate}}</span><i class="fa fa-sync"
                          matTooltip="Refresh List" matTooltipPosition="below" aria-hidden="true"
                          style="font-size: 18px; float: right; padding-top: 3px; padding-right: 8px;"
                          (click)="getVehicleListByType(null, 'out')"></i>
                      </div>
                      <div>
                        <input class="search_input" name="outVehicleSearch" id="outVehicleSearch" type="text"
                          (keyup)="searchVehicle($event, 'out')" *ngIf="activeSearchBar.out"
                          placeholder="Vehicle RC NO." />
                      </div>
                    </h6>
                    <span class="loader" *ngIf="loaderState.out"></span>
                    <ul *ngIf="vehicleList.out.length && !loaderState.out" style="width: 100%;">
                      <li *ngFor="let vin of vehicleList['out']; let i = index;">
                        <div appEllipsisView>
                          <i class="fa fa-truck icon_vehicle"></i>
                          {{ vin.vehicle_rc_no }}
                        </div>
                        <div class="right_img_icon">
                          <img src="assets/images/trip-details(3).png" matTooltip="Trip Detail"
                            matTooltipPosition="below" alt="" (click)="tripModal(vin);">
                          <app-trip-comment-trail (callGetCompliance)="getComplianceAlertLogs()" [tripId]="vin.txnid" [complainceData]="vin" [entityType]="'TRIP'"></app-trip-comment-trail>
                          <img src="assets/images/trip-tracking(3).png" matTooltip="Trip Tracking"
                            matTooltipPosition="below" alt="" (click)="tripTrackingModal(vin)">

                        </div>
                      </li>
                    </ul>
                    <img src="assets/images/no-vehicle-found_2.gif" alt="" class="norecord" width="50px" height="auto"
                      *ngIf="vehicleList['out'].length == 0 && !loaderState.out">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{'next_stage'|customTranslate}}</h6>
    <div aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-times"></i></div>
  </div>
  <div class="row modal-body" id="boxes">
    <div class="stage_boxx">
      <div class="stageBox" *ngFor="let stage of selectedVehicle[vehicleStageFlow]; let i=index">
        <div class="stageName" [ngClass]="{'active_stage': stage.stage_id==selectedVehicle?.sel_next_stage_id}"
          (click)="selectedVehicle.sel_next_stage_id = stage.stage_id">{{stage.stage_name}}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" *ngIf="vehicleStageFlow == 'nextStages'"
      (click)="assignNextStage();modal.dismiss('Cross click');">{{'approve' | customTranslate}}</button>
    <button type="button" class="btn btn-success" *ngIf="vehicleStageFlow != 'nextStages'"
      (click)="assignNextStage();modal.dismiss('Cross click');">{{'reject' | customTranslate}}</button>
  </div>
</ng-template>

<ng-template #detail let-modal2>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{detailsOf|customTranslate}} {{'Details'|customTranslate}}</h6>
    <div aria-label="Close" (click)="modal2.dismiss('Cross click')"><i class="fa fa-times"></i></div>
  </div>
  <div class="modal-body" id="boxes">
    <app-add-driver-transporter [assign]="detailsOf"
      [id]="detailsOf=='Driver'?selectedVehicle?.driver_id:selectedVehicle?.transporter_id"
      [editable]="false"></app-add-driver-transporter>
  </div>
</ng-template>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" #remarkModal
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{!txnFailRemark ? (currentAction?.action_name + ' Fail Remark')
          : 'Transaction Fail Remark' }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="message-text" class="col-form-label">Message:</label>
            <textarea class="form-control" id="message-text" type="text" [(ngModel)]="commentMessage"
              name="message-text" #name="ngModel" placeholder="Enter Remark"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          (click)="addCommentForTrip(currentAction,!txnFailRemark,txnFailRemark,content)">Skip</button>
        <button type="button" class="btn btn-primary"
          (click)="addCommentForTrip(currentAction,!txnFailRemark,txnFailRemark,content)"
          data-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { StageService } from '../stage/stage.service';
import { VisitDateWiseReportModalComponent } from '../visit-date-wise-report-modal/visit-date-wise-report-modal.component';
import { DateWisVisitorReportData, displayDateWiseVisitReport, ProcessList } from 'src/app/dataType/visitorInterface';
import { COMMON_ACTION_BUTTON, TABLE_TYPE, TableColumns,TableActions } from 'src/app/dataType/customTableInterface';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-visit-date-wise-component',
  templateUrl: './visit-date-wise-component.component.html',
  styleUrls: ['./visit-date-wise-component.component.scss']
})
export class VisitDateWiseComponentComponent implements OnInit {
  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  loading: boolean = false;
  tableColumns: TableColumns[] = [];
  startDate: Date = new Date(new Date().setDate(new Date().getDate() - 7));
  endDate: Date = new Date(new Date().setDate(new Date().getDate()));
  listOfProcess: ProcessList[]
  processName: string = null;
  plantId: number = null;
  dateWisVisitorReportData:DateWisVisitorReportData;
  tripStatus: string;
  processFilterList:number[]=[];
  processFilterName: string[]=[];
  answer: displayDateWiseVisitReport[]
  allProcesses:number[]=[];
  result: displayDateWiseVisitReport[]

  constructor(
    private activeModal: NgbActiveModal,
    public table: TableService,
    public common: CommonService,
    public api: ApiService,
    public alert: AlertService,
    public stageService: StageService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public modalService: NgbModal,
    public role: RoleService,
    public plantService: PlantService,
    private translateService: TranslateService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'visit_date_wise_report', link: '/user/visit-date-wise-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }



  ngOnInit() {
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.getAllProcess();
    this.getDateWiseVisitorReport();
    console.log('p',this.processFilterList)
  }

  selectedProcess(event: any) {


        this.processFilterList=event.map(e=>e.processId)
        this.filterDataByCondition(this.tripStatus)
        this.getDateWiseVisitorReport()
  }

  filterDataByCondition(condition: string){

    if(condition=='ALERT')

      {
        this.result=this.answer.filter(data=>data.alert_count>0)
      }

      if(condition=='RESTRICTED')

      this.result=this.answer.filter(data=>data.restricted_count>0)

      if(condition=='ALL')
      this.result= this.answer

  }



  reset(){


    this.startDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.endDate = new Date();
    this.tripStatus='ALL'
    this.filterDataByCondition(this.tripStatus)
    this.processFilterList=[]
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.getDateWiseVisitorReport()
  }

   getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.plantId,
      processType:'Visit'
    };
      this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.listOfProcess = res.data;

       this.allProcesses = this.listOfProcess.map(process => process.processId);
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  getDateWiseVisitorReport() {

    this.loading= true
    if (this.startDate > this.endDate) {
      this.alert.warn('Start Date Should be Smaller than End Date!!');
      return;
    }
    this.common.loading = true;
    let params = {
      fromDate: this.startDate,
      toDate: this.endDate,
      processFilterList: this.processFilterList?.length==0?[-1]: this.processFilterList
    };


    this.api.get('visitors/get-visit-date-wise-report', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.tripStatus='ALL'
          this.filterDataByCondition(this.tripStatus)
          this.common.loading = false;
          this.dateWisVisitorReportData = res.data[0]

          const totalExpectedTat = this.dateWisVisitorReportData.expectedTat
          .reduce((acc, time) => acc + this.convertToMinutes(time), 0);

          const totalTatInHHMM = this.convertToHHMM(totalExpectedTat);




  const result = this.dateWisVisitorReportData.getVisitWiseReport.map((report) => {

  return {
    date: report.date,
    alert_count: report.alert_count,
    restricted_count: report.restricted_count,
    expected_tat: totalTatInHHMM,
    tat:report.total_tat,

  };
});

this.answer = result
this.result = this.answer


          this.tableColumns = [
            {
              columnLabel: "visit_date",
              columnName: "date",
            },
            {
              columnLabel: "alert_count",
              columnName: "alert_count",
            },
            {
              columnLabel: "restricted_count",
              columnName: "restricted_count",
            },
             {
              columnLabel: "over_tat",
              columnName: "",
              dataFormatter: (row: any, column: TableColumns, index: number) => {
                return (`<span class="${row.tat > row.expected_tat ? 'badge over' : 'badge'}">${row.tat?row.tat.toString():'0:0'}</span> /
                          <span class="badge info">${row.expected_tat}</span>
                        `)
              }

            },
            {
              columnLabel: this.translateService.instant("Action"),
              columnName: null,
              dataActions:[
                {
                  actionName: COMMON_ACTION_BUTTON.VIEW,
                  handleClick: (column: TableColumns, row: any, index: number) => {
                    this.viewVisitDetails(row);
                  },
                  actionFormatter: (column: TableColumns, row: any, index: number) => {
                    return `<div class="btn-block">
                      <button class="btn btn-info" style=" position: relative; color: #fff; border: none; font-size: 12px;">

                    ${this.translateService.instant("view")}</button>
                      </div>`
                  }
                }
              ]

            }
          ]


        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  viewVisitDetails(visit: any){

    const activeModal = this.modalService.open(VisitDateWiseReportModalComponent,{
      size:'xl'
    });
    activeModal.componentInstance.refData = {visit:visit,processFilterList:this.processFilterList}
    activeModal.result.then(async (res) => {

    });
  }


 convertToMinutes(time: any): number {
  const [hours, minutes] = time.expected_tat.split(':').map(Number);
  return hours * 60 + minutes;
}


 convertToHHMM(totalMinutes: number): string {
  const hours = (Math.floor((totalMinutes / 60)/this.dateWisVisitorReportData.expectedTat.length));
  const minutes = Math.floor((totalMinutes % 60)/this.dateWisVisitorReportData.expectedTat.length);
  return (hours.toString().padStart(2, '0').concat(':',minutes.toString().padStart(2, '0')))
}


}

import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';

@Component({
  selector: 'app-ellipsis-modal',
  templateUrl: './ellipsis-modal.component.html',
  styleUrls: ['./ellipsis-modal.component.scss']
})
export class EllipsisModalComponent implements OnInit {

  constructor(public common:CommonService) { }

  ngOnInit() {
  }

}

<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{(title | lowercase) | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input"> {{"process->stage" | translate}} </label>
                  <ps-auto-suggestion (onChange)="emptyprocess($event)" (onSelected)="selectedProcess($event)"
                    [data]="process" display="order_name" placeholder="Choose Process Stage"
                    [preSelected]="{order_name:processName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error">
                    <span>{{processValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{("action" | translate)}} </label>
                  <ps-auto-suggestion (onSelected)="selectedAction($event)" [data]="filtered_action"
                    display="actionName" placeholder="Choose Action" [preSelected]="{actionName:actionName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="actionValidator && actionValidator.error">
                    <span>{{actionValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{("user" | translate)}} </label>
                  <ps-auto-suggestion [isMultiSelect]="isMultiSelect" (onSelected)="selectedUser($event)" [data]="user"
                    display="name" id="user" placeholder="Choose User" [preSelected]="preSelectedUser">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="userValidator && userValidator.error">
                    <span>{{userValidator.msg}} </span>
                  </div>
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required"> {{("status" | translate)}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageDestinationCodeStatus" [(ngModel)]="status"
                      id="stage_status_1" value="true">
                    <label for="exampleRadios1">
                      {{("active" | translate)}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageDestinationCodeStatus" [(ngModel)]="status"
                      id="stage_status_2" value="false">
                    <label for="exampleRadios2">
                      {{("inactive" | translate)}}
                    </label>
                  </div>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>

                <div class="row form-group action-block">
                  <div class="btn-block">
                    <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{("back" | translate)}}
                    </button>
                    <button class="btn btn-warning" (click)="reset()"> {{("reset" | translate)}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"
                      *ngIf="userService.permissionMap.get('add_user_action_mapping_access')==true"> {{buttonName
                      |translate}}
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>



      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"user_action mapping list" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"s.no." | translate}}</th>
                    <th>{{"process_stage name" | translate}}</th>
                    <th>{{"action" | translate}}</th>
                    <th>{{"user" | translate}}</th>
                    <th>{{"status" | translate}}</th>
                    <th class="action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of responseData let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.processOrderName}}</td>
                    <td>{{row.actionName}}</td>
                    <td>{{row.userName}}</td>
                    <td>{{(row.isActive==true?'Active':'Inactive')}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewUserActionMapping(row)"
                          *ngIf="userService.permissionMap.get('view_user_action_mapping_access')==true"> {{("view" |
                          translate)}} </button>
                        <button class="btn btn-success" (click)="editUserActionMapping(row)"
                          *ngIf="userService.permissionMap.get('edit_user_action_mapping_access')==true"> {{("edit" |
                          translate)}} </button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)"> {{("delete" | translate)}}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../@core/services/common.service';
import { MapService } from '../../@core/services/map.service';
import { ApiService } from '../../@core/services/api.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { UserService } from '../../@core/services/user.service';
import { RoleService } from '../../@core/services/role.service';
import { DataTableDirective } from 'angular-datatables';
import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-app-version-data',
  templateUrl: './app-version-data.component.html',
  styleUrls: ['./app-version-data.component.scss'],
})
export class AppVersionDataComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'USERS APP VERSION LIST');
  radioValue = [
    { radio: 'Company', id: 1 },
    { radio: 'Plant', id: 2 },
    { radio: 'User', id: 3 },
  ];
  selectedradio: number = 1;
  usersList: any[] = [];
  selectedId: any = null;
  companyData: any[] = [];
  plantList: any[] = [];
  companyId: any = null;
  companyName: any = null;
  plantId: any = null;
  plantName: any = null;
  allUsers: any[] = [];
  userId: any = null;
  userName: any = null;
  versionsList: any[] = [];
  constructor(
    public common: CommonService,
    public api: ApiService,
    public mapService: MapService,
    public alertService: AlertService,
    public user: UserService,
    public table: TableService,
    public datepipe: DatePipe,
    public role: RoleService,
    private breadcrumbService: BreadcrumbService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'App', url: null },
      { name: 'App Version Check', link: '/user/app-version-check' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getCompany();
    this.getAppVersions();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  getCompany() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
          this.selectedId = this.companyData[0].companyId;
          this.companyId = this.companyData[0].companyId;
          this.companyName = this.companyData[0].companyName;
          this.selectedradio = 1;
          this.getUsers();
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  selectCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantListByCompanyId(this.companyId);
    this.selectedId = this.companyId;
    this.selectedradio = 1;
    this.userId = null;
    this.userName = null;
    this.plantId = null;
    this.plantName = null;
    this.getUsers();
  }

  getPlantListByCompanyId(companyId) {
    this.common.loading = true;
    this.api.get('plant/all_plants?id=' + companyId).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantList = res.data;
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.selectedId = this.plantId;
    this.selectedradio = 2;
    this.userId = null;
    this.userName = null;
    this.getUsers();
    this.getAllUsers();
  }

  getUsers() {
    let params = {
      type: this.selectedradio,
      id: this.selectedId,
    };
    this.common.loading = true;
    this.api.get('apk/get-filter-wise-user', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.usersList = res.data;
        this.renderTable();
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  selectedUser(event: any) {
    this.userId = event.userId;
    this.userName = event.name;
    this.selectedId = this.userId;
    this.selectedradio = 3;
    this.getUsers();
  }

  getAllUsers() {
    let params = {
      plantId: this.plantId,
    };
    this.common.loading = true;
    this.api.get('user/all_user', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.allUsers = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAppVersions() {
    this.common.loading = true;
    let params = {
      versionType: 'APK',
    };
    this.api.get('apk/get-app-details', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.versionsList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  selectVersion(event: any, item: any) {
    item.updatedVersion = event.appVersion;
  }

  updateVersion(item: any) {
  console.log('item: ', item.userId);
    let params = {
      userId: item.userId,
      forceUpdate: item.forceApkUpdate,
      updatedVersion: item.updatedVersion,
    };
    console.log('params: ', params);
    this.common.loading = true;
    this.api.get('apk/update-user-app-details', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.info(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

import { TableService } from '../../@core/services/table.service';
import { AlertService } from './../../theme/layout/header/alert-message/alert.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { CommunicationService } from './communication.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { CompanyService } from '../companies/companies.service';
import { PlantService } from '../plant/plant.service';

interface UserInfo {
  userType: string;
  ref: number | string;
}

interface CommunicationSetting {
  userInfo: UserInfo[];
  typeId: number;
  isActive: string;
}

interface UpdateCommunicationSetting {
  csId: number;
  typeId: number;
  userType: string;
  ref: number | string;
  isActive: string;
}

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss'],
})
export class CommunicationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  isdisable = false;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'communication type table');

  ngAfterViewInit() {
    this.dtTrigger.next();
    //  this.getData();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  userType = {
    userTypeName: '',
  };

  communicationSetting: CommunicationSetting = {
    userInfo: [],
    typeId: 0,
    isActive: 'true',
  };

  updateCommunicationSetting: UpdateCommunicationSetting = {
    csId: 0,
    typeId: 0,
    userType: '',
    ref: '',
    isActive: 'true',
  };
  communicationName: null;
  isMultiSelect = true;
  groupName: any = [];
  userName: any = [];
  groupId = null;
  preSelectedUser: any = [];
  preSelectedGroup: any = [];
  title = 'Communication Setting';
  btn = 'Save';
  ids = null;
  data: any[] = [];
  communication: any[] = [];
  group: any[] = [];
  communicationSettingsData = [];
  user: any[] = [];
  masterField = [{ customName: '' }];

  commonSetting = {
    user: [],
    group: [],
    customData: [],
    entity: [{ transporter: false, driver: false }],
    isActive: 'true',
  };
  finalObjData = [];
  titleSettings = '';
  plantId = null;
  companyId = null;
  company: any[] = [];
  plantData: any[] = [];
  companyName: null;
  plantName: null;
  companyValidator: any = null;
  plantNameValidator: any = null;
  groupIds: any = [];
  userId: any = [];

  constructor(
    private communicationService: CommunicationService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public alert: AlertService,
    public table: TableService,
    public userService: UserService,
    public api: ApiService,
    public role: RoleService,
    public companyService: CompanyService,
    public plantService: PlantService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Communication', url: null },
      { name: 'Communication Setting', url: '/user/communication-comp' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.getAllCommunicationSetting();
  }

  ngOnInit(): void {
    this.getCommunication();
    // this.getGroup();
    // this.getUser();
    this.getAllCommunicationSetting();
    this.getCompany();
    this.getUser();

    if (this.role.user_role != 'AXESTRACK') {
      this.getGroup();
    }
  }
  ngViewInit() {}

  addValues() {
    this.masterField.push({ customName: '' });
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = null;
    this.companyValidator = null;
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.plantNameValidator = null;
    this.titleSettingsValidator = null;
    this.getGroup();
  }

  selectedCommunicationType(event: any) {
    this.communicationName = event.communicationTypeName;
    this.communicationSetting.typeId = event.communicationTypeId;
    this.updateCommunicationSetting.typeId = this.communicationSetting.typeId;
    this.communicationValidator = null;
  }

  selectedGroup(event: any) {
    this.commonSetting.group = [];
    let data = [];
    let name = [];
    event.forEach((item) => {
      data.push(item.groupId);
    });
    this.groupIds = data;
    event.forEach((element) => {
      name.push(element.groupName);
      // this.updateCommunicationSetting.userType = "Group";
      // this.updateCommunicationSetting.ref = element.group_id;
      let groups = {
        groupName: element.groupName,
        groupId: element.groupId,
      };
      let existingIndex = this.commonSetting.group.findIndex(
        (info) => info == element.group_id && info.userType == 'Group'
      );
      if (existingIndex == -1) {
        this.commonSetting.group.push(groups);
      }
    });
    this.groupName = name;
    // this.getUser();
  }

  selectedUser(event: any) {
    // this.displayName="name";
    this.commonSetting.user = [];
    let data = [];
    let user = [];
    event.forEach((item) => {
      data.push(item.userId);
    });
    this.userId = data;
    event.forEach((element) => {
      user.push(element.name);
      // this.updateCommunicationSetting.userType = "User";
      // this.updateCommunicationSetting.ref = element.userId;
      let users = {
        userName: element.name,
        userId: element.userId,
      };
      let existingIndex = this.commonSetting.user.findIndex(
        (info) => info == element.userId && info.userType == 'User'
      );
      if (existingIndex == -1) {
        this.commonSetting.user.push(users);
      }
    });
    this.userName = user;
  }

  getPlantById(id?: any) {
    this.common.loading = true;

    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          res.data.forEach((element) => {
            if (element.companyStatus == 'ACTIVE') this.company.push(element);
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  getCommunication() {
    this.common.loading = true;
    this.communicationService.getCommunication().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.communication = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getGroup() {
    let param: any;
    if (this.role.user_role == 'AXESTRACK') {
      param = {
        plantId: this.plantId,
        plantType: true,
      };
    } else {
      param = {
        plantId: this.userService._loggedInUser.plantId,
        plantType: true,
      };
    }
    this.common.loading = true;
    this.communicationService.getGroupByPlantId(param).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.group = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getUser() {
    this.common.loading = true;
    this.api.get('user/all_user').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.user = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  editRow(row) {
    this.ids = row.id;
    this.common.gotoTop();
    this.masterField = [{ customName: '' }];
    this.btn = 'Update';
    this.titleSettings = row.title;
    this.communicationName = row.communication_type_name;
    this.communicationSetting.typeId = row.ipa_communication_type_id;
    this.commonSetting.isActive = row.is_active == true ? 'true' : 'false';
    this.companyId = row.company_id;
    this.companyName = row.company_name;
    this.plantId = row.plant_id;
    this.plantName = row.plant_name;
    // this.group=row.setting_info.group;
    // this.user=row.setting_info.users;
    if (row.setting_info.custom.length > 0) {
      this.masterField = [];
      row.setting_info.custom.forEach((item) => {
        this.masterField.push({
          customName: item.customName,
        });
      });
    }
    this.preSelectedGroup = [];
    row.setting_info.group.forEach((data) => {
      this.preSelectedGroup.push(data);
      this.commonSetting.group.push(data);
    });
    this.preSelectedUser = [];
    row.setting_info.users.forEach((element) => {
      this.preSelectedUser.push({userId:element.userId,name:element.userName});
      this.commonSetting.user.push(element);
    });
    console.log("dfrtyhj",this.preSelectedUser)
    if (row.setting_info.entity.length > 0) {
      this.commonSetting.entity = row.setting_info.entity;
    }
  }

  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'View Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'xl',
    });
  }

  resetDetail(callGroupApi: any = true, callUserApi: any = true) {
    this.ids = null;
    this.commonSetting = {
      user: [],
      group: [],
      customData: [],
      entity: [{ transporter: false, driver: false }],
      isActive: 'true',
    };
    this.finalObjData = [];
    this.titleSettings = '';
    this.userType = {
      userTypeName: '',
    };
    this.masterField = [{ customName: '' }];

    this.isdisable = false;
    this.communicationName = null;
    this.groupName = null;
    this.userName = null;
    this.communicationSetting.isActive = 'true';
    this.btn = 'Save';
    this.title = 'Communication Setting';
    this.communicationValidator = null;
    this.groupValidator = null;
    this.userValidator = null;
    this.communicationSetting = {
      userInfo: [],
      typeId: 0,
      isActive: 'true',
    };
    this.statusValidator = null;
    this.isMultiSelect = true;
    this.preSelectedGroup = [];
    this.preSelectedUser = [];
    this.companyId = null;
    this.companyName = null;
    this.plantId = null;
    this.plantName = null;
    this.companyValidator = null;
    this.plantNameValidator = null;
    this.getCommunication();
    this.plantData = [];
  }

  getAllCommunicationSetting() {
    this.common.loading = true;
    this.communicationService.getAllCommunicationSettingData().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.communicationSettingsData = res.data;
          this.communicationSettingsData.forEach((element) => {
            element.setting_info = JSON.parse(element.setting_info);
          });
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  saveData() {
    let params: any;
    this.finalObjData = [];
    let obj = {};
    obj = {
      users: this.commonSetting.user,
      group: this.commonSetting.group,
      custom: this.commonSetting.customData,
      entity: this.commonSetting.entity,
    };
    this.finalObjData.push(obj);
    if (this.role.user_role == 'AXESTRACK') {
      params = {
        id: this.ids,
        communicationTypeid: this.communicationSetting.typeId,
        titleSetting: this.titleSettings,
        settingInfo: JSON.stringify(obj),
        isActive: this.commonSetting.isActive,
        plantId: this.plantId,
        companyId: this.companyId,
      };
    } else {
      params = {
        id: this.ids,
        communicationTypeid: this.communicationSetting.typeId,
        titleSetting: this.titleSettings,
        settingInfo: JSON.stringify(obj),
        isActive: this.commonSetting.isActive,
        plantId: this.userService._loggedInUser.plantId,
        companyId: this.userService._loggedInUser.companyId,
      };
    }
    this.communicationService.saveCommunicationSetting(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alert.info('Data Saved Successfully');
          this.resetDetail();
          this.getAllCommunicationSetting();
        }
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  communicationValidator: any;
  userValidator: any;
  groupValidator: any;
  statusValidator: any;
  titleSettingsValidator: any;

  formValidator() {
    this.companyValidator = this.common.nullValidator(
      this.companyId,
      'Company Mandatory'
    );
    this.plantNameValidator = this.common.nullValidator(
      this.plantName,
      'Plant Name Mandatory'
    );
    this.updateCommunicationSetting.isActive =
      this.communicationSetting.isActive;
    this.communicationValidator = this.common.nullValidator(
      this.communicationName,
      'Communication Type Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.communicationSetting.isActive,
      'Status Mandatory'
    );
    this.titleSettingsValidator = this.common.nullValidator(
      this.titleSettings,
      'Title Settings Mandatory'
    );
    if (this.masterField.length > 0) {
      this.masterField.forEach((element) => {
        if (element.customName.trim() != '') {
          let custom = {
            customName: element.customName,
          };
          let existingIndex = this.commonSetting.customData.findIndex(
            (info) => info == element.customName && info.userType == 'Custom'
          );
          if (existingIndex == -1) {
            this.commonSetting.customData.push(custom);
          }
        }
      });
    }
    if (this.role.user_role == 'AXESTRACK') {
      if (
        !this.companyValidator.error &&
        !this.plantNameValidator.error &&
        !this.communicationValidator.error &&
        !this.statusValidator.error &&
        !this.titleSettingsValidator.error &&
        (this.btn == 'Save' || this.btn == 'Update')
      ) {
        this.saveData();
      }
    } else {
      if (
        !this.communicationValidator.error &&
        !this.statusValidator.error &&
        !this.titleSettingsValidator.error &&
        (this.btn == 'Save' || this.btn == 'Update')
      ) {
        this.saveData();
      }
    }
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{'field_tolerance_mapping'|translate}}
            </h3>
          </div>
        </div>
        <div class="card-body ">
          <div class="row">
            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
              <label for="my-input" class="required">{{'select_field :'|translate}}</label>
              <ps-auto-suggestion (onSelected)="selectedField($event)" [data]="getSelectionFields" display="field_name"
                placeholder="Select Field" name="selectedActionData" inputId="selectedActionData"
                [preSelected]="{field_name:toleranceMapping.fieldName}">
              </ps-auto-suggestion>
            </div>

            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
              <label for="my-input">{{'tolerance_type'|translate}}</label>
              <select [(ngModel)]="this.toleranceMapping.toleranceType" name="hardwareType" id="#fieldType"
                class="form-control">
                <option value="0">Percentage</option>
                <option value="1">Raw Value</option>
              </select>
            </div>

            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
              <label for="my-input">{{'tolerance_value'|translate}}</label>
              <input type="number" class="form-control" [(ngModel)]="this.toleranceMapping.toleranceValue">
            </div>
          </div>
          <div class="form-group action-block mb-3">
            <div class="btn-block">
              <button class="btn btn-muted" (click)="common.backClicked()"> {{'back'|translate}} </button>
              <button class="btn btn-warning" (click)="resetDetail()"> {{'reset'|translate}} </button>
              <button type="submit" class="btn btn-submit" (click)="saveToleranceMapping()"> {{btn | translate}}
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="header-block">
                  <h3 class="card-title">
                    {{'list_of_tolerance'|translate}}
                  </h3>
                </div>
              </div>

              <div class="card-body">
                <!-- table -->
                <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                  <thead>
                    <tr>
                      <th>{{'serial_no'|translate}}</th>
                      <th>{{'Field Name'|translate}}</th>
                      <th>{{'tolerance_type'|translate}}</th>
                      <th>{{'tolerance_value'|translate}}</th>
                      <th class="action">{{'Actions'|translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of toleranceFieldMapping ,let i = index">
                      <td>{{i+1}}</td>
                      <td>{{item.field_name}}</td>
                      <td>{{item.tolerance_type == '0'?'Percentage':'Raw Value'}}</td>
                      <td>{{item.tolerance}}</td>
                      <td class="action">
                        <div class="btn-block">
                          <button class="btn btn-primary" (click)="viewDetails(item)"> {{'view'|translate}} </button>
                          <button class="btn btn-success" (click)="toleranceEdit(item)">{{'edit'|translate}}</button>
                          <button class="btn btn-danger"
                            (click)="confirmDeleteAction(item)">{{'delete'|translate}}</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { CompanyService } from '../companies/companies.service';
import { PlantService } from '../plant/plant.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-method-mapping',
  templateUrl: './method-mapping.component.html',
  styleUrls: ['./method-mapping.component.scss'],
})
export class MethodMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  isdisable = false;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'communication type table');

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  processName: any = null;
  processId: any = null;
  stageId: any = null;
  stageName: any = null;
  processOrderName: any = null;
  processOrderId: any = null;
  selectedMappingName: any = 'Stage';
  btn = 'save';
  mappingData: any[] = [{ mappingName: 'Stage' }, { mappingName: 'Action' }];
  masterField: any[] = [[{}], [{}], [{}]];
  processStageOrder: any[] = [];
  dataMethodNameStage: any[] = [
    {
      methodvalue: 1,
      methodName: 'on_approve method',
      style: 'background-color:#b6ffb5',
      border: 'border-color:#e8e8e8',
    },
    {
      methodvalue: 0,
      methodName: 'on_reject method',
      style: 'background-color:#ffb8b8',
      border: 'border-color:#e8e8e8',
    },
  ];
  dataMethodNameAction: any[] = [
    {
      methodvalue: -1,
      methodName: 'pre_methods',
      style: 'background-color:#b8d6ff',
      border: 'border-color:#e8e8e8',
    },
    {
      methodvalue: 1,
      methodName: 'on_pass_method',
      style: 'background-color:#b6ffb5;',
      border: 'border-color:#e8e8e8;',
    },
    {
      methodvalue: 0,
      methodName: 'on_fail_method',
      style: 'background-color:#ffb8b8',
      border: 'border-color:#e8e8e8',
    },
  ];
  actionsList: any = [];
  methodsList: any = [];
  methodMappingList: any = [];
  selectedMethodName = null;
  selectedActionName = null;
  selectedActionId = null;
  methodMappingId: any[] = [];
  stages: any[] = [];
  selectedStageName: any = null;
  selectedStageId: any = null;
  processList = [];
  plantId = null;
  companyId = null;
  company: any[] = [];
  plantData: any[] = [];
  companyName: null;
  plantName: null;
  companyValidator: any = null;
  plantNameValidator: any = null;

  constructor(
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public alert: AlertService,
    public table: TableService,
    public userService: UserService,
    public api: ApiService,
    public role: RoleService,
    public companyService: CompanyService,
    public plantService: PlantService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Methods', url: null },
      { name: 'Method Mapping', url: '/user/method-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
    if(this.role.user_role != 'AXESTRACK' && this.role.user_role != 'COMPANYADMIN'){
      this.getAllMethods();
    }
    this.getCompany();
    this.getMappingMethodData();
    if (this.role.user_role != 'AXESTRACK') {
      this.getAllProcess();
      this.getAllProcessStage();
    }
    if(this.role.user_role=='COMPANYADMIN'){
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
  }
  ngViewInit() {}

  addValues(index: any) {
    this.masterField[index].push({});
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = null;
    this.companyValidator = null;
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.plantNameValidator = null;
    this.processValidator = null;
    this.getAllMethods(this.plantId)
    this.getAllProcess();
    this.getAllProcessStage();
  }

  selectedStage(event: any) {
    this.selectedStageName = event.stage_name;
    this.selectedStageId = event.order_id;
    let row = this.methodMappingList.filter(res => res.mapping_on == 0 && res.process_id == this.processId && res.orderId == this.selectedStageId);
    if(row.length != 0){
      this.masterField[0] = row[0].on_approve.length == 0 ? [{}] : row[0].on_approve;
      this.masterField[1] = row[0].on_reject.length == 0 ? [{}] : row[0].on_reject;
    }
  }

  selectedMapping(event: any) {
    this.selectedMappingName = event.mappingName;
  }
  getPlantById(id?: any) {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        id: id,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.plantData = res.data;
            resolve(this.plantData);
          }
        },
        (err: any) => {
          reject(null);
          this.common.loading = false;
        }
      );
    });
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          res.data.forEach((element) => {
            if (element.companyStatus == 'ACTIVE') this.company.push(element);
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllProcess() {
    return new Promise((resolve, reject) => {
      let params = {
        processActive:true,
        plantId: this.plantId,
      };
      this.common.loading = true;
      this.api
        .get('process/get-all-process',params)
        .subscribe(
          (res: any) => {
            this.common.loading = false;
            this.processList = res.data;
            resolve(this.processList);
          },
          (err: any) => {
            this.common.loading = false;
            reject(null);
          }
        );
    });
  }

  getAllStage(plantId = null) {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let apiUrl;
      if (plantId) {
        apiUrl = `stage/stage_by_process?plantId=${plantId}&processId=${this.processId}`;
      } else {
        apiUrl = `stage/stage_by_process?processId=${this.processId}`;
      }
      this.api.get(apiUrl).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res?.data?.length) {
            this.stages = res.data;
          } else {
            // this.alert.info(`Stages not found`);
          }
          resolve(this.stages);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  selectedAction(event: any) {
    this.masterField = [[{}], [{}], [{}]];
    this.selectedActionId = event.actionid;
    this.selectedActionName = event.actionname;
    let row = this.methodMappingList.filter(res => res.mapping_on == 1 && res.orderId == this.processOrderId && res.action_id == this.selectedActionId);
    if(row.length != 0){
      this.masterField[0] = row[0].on_pre.length == 0 ? [{}] : row[0].on_pre;
      this.masterField[1] = row[0].on_approve.length == 0 ? [{}] : row[0].on_approve;
      this.masterField[2] = row[0].on_reject.length == 0 ? [{}] : row[0].on_reject;
    }
  }

  async selectedProcess(event: any) {
    this.processId = event.processId;
    this.processName = event.processName;
    this.getAllStage(this.plantId);
    // this.getActionsList(this.plantId);
  }

  selectedProcessStage(event: any) {
    this.masterField = [[{}], [{}], [{}]];
    this.processOrderName = event.order_name;
    this.processOrderId = event.order_id;
    this.processId = event.process_id;
    this.getActionsList(this.plantId);
  }

  selectedMethod(event: any, item: any, index: any, methodIndex: any) {
    this.masterField[methodIndex].splice(index, 1);
    this.masterField[methodIndex].push({
      methodName: event.methodName,
      methodId: event.methodId,
      methodType: item.methodvalue,
    });
    let arr: any[] = [];
    this.masterField[methodIndex].forEach((item: any, idx: any) => {
      item.rankingIndex = idx + 1;
      arr.push(item);
    });
    this.masterField[methodIndex] = arr;
  }

  getActionsList(plantId = null) {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let apiUrl;
      if (plantId) {
        apiUrl = `actions/action_by_process?plantId=${plantId}&processId=${this.processId}`;
      } else {
        apiUrl = `actions/action_by_process?processId=${this.processId}`;
      }
      this.api.get(apiUrl).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.actionsList = res.data;
            resolve(this.actionsList);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  getAllMethods(plantId:number=null) {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    let params = {
      plantId:plantId
    };
    this.api.get('method/get-all-methods-by-type', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if (res.data) {
            this.methodsList = res.data;
          }
          resolve(true);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        reject(null)
      }
    );
  });
}

  getMappingMethodData() {
    this.common.loading = true;
    let params = {};
    if (this.role.user_role == 'AXESTRACK') {
      params = {
        plantId: -1,
      };
    }else  if (this.role.user_role == 'COMPANYADMIN') {
      params = {
        plantId: -1,
        companyId:this.userService._loggedInUser.companyId
      };
    }
    this.api.get('method/method_mapping_data', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if (res.data) {
            this.methodMappingList = res.data;
            this.renderTable();
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllProcessStage() {
    return new Promise((resolve, reject) => {
      let params = {
        plantId: this.plantId,
      };
      this.common.loading = true;
      this.api.get('process/get-process-stage', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.processStageOrder = res.data;
          resolve(this.processStageOrder);
        },
        (err: any) => {
          console.error('Error: ', err);
          reject(null);
          this.common.loading = false;
        }
      );
    });
  }
  onDrop(event: CdkDragDrop<string[]>, index: any) {
    let arr: any[] = [];
    moveItemInArray(
      this.masterField[index],
      event.previousIndex,
      event.currentIndex
    );
    this.masterField[index].forEach((item: any, idx: any) => {
      item.rankingIndex = idx + 1;
      arr.push(item);
    });
    this.masterField[index] = arr;
  }

  async editRow(row) {
    this.resetDetail();
    this.common.gotoTop();
    this.btn = 'update';
    if (row.mapping_on == 0) {
      this.selectedMappingName = 'Stage';
    } else {
      this.selectedMappingName = 'Action';
    }
    if (this.role.user_role == 'AXESTRACK') {
      this.companyId = row.company_id;
      this.companyName = row.company_name;
      await this.getPlantById(row.plant_id);
      this.plantId = row.plant_id;
      this.plantName = row.plant_name;
    }else if(this.role.user_role == 'COMPANYADMIN'){
      this.plantId=row.plant_id;
      this.plantName=row.plant_name;
    }
    await this.getAllMethods(row.plantId);
    await this.getAllProcessStage();
    this.processOrderId = row.orderId;
    this.methodMappingId = row.method_mapping_id;
    this.processOrderName = this.processStageOrder.filter(
      (p) => p.order_id == row.orderId
    )[0].order_name;
    this.processName = row.process_name;
    this.processId = row.process_id;
    await this.getAllStage();
    await this.getActionsList();
    this.selectedStageId = row.orderId;
    this.selectedStageName = row.stage_name;
    this.selectedActionId = row.action_id;
    this.selectedActionName = row.action_name;
    if (row.mapping_on == 1) {
      this.masterField[0] = row.on_pre.length == 0 ? [{}] : row.on_pre;
      this.masterField[1] = row.on_approve.length == 0 ? [{}] : row.on_approve;
      this.masterField[2] = row.on_reject.length == 0 ? [{}] : row.on_reject;
    } else {
      this.masterField[0] = row.on_approve.length == 0 ? [{}] : row.on_approve;
      this.masterField[1] = row.on_reject.length == 0 ? [{}] : row.on_reject;
    }
  }

  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'View Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  resetDetail() {
    this.getMappingMethodData();
    this.selectedStageName = null;
    this.processName = '';
    this.masterField = [
      [{ customName: '' }],
      [{ customName: '' }],
      [{ customName: '' }],
    ];
    this.btn = 'save';
    this.selectedMappingName = 'Stage';
    this.processOrderId = null;
    this.processOrderName = null;
    this.methodMappingId = [];
    this.processValidator = null;
    this.stageValidator = null;
    this.companyId = null;
    this.companyName = null;
    this.plantId = null;
    this.plantName = null;
    this.companyValidator = null;
    this.plantNameValidator = null;
    this.selectedActionId = null;
    this.selectedActionName = null;
    this.selectedStageId = null;
    this.stages = [];
    this.actionsList = [];
  }

  delete(row) {
    this.common.loading = true;
    this.api
      .post('method/delete_method_mapping', row.method_mapping_id)
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.alert.info('Data Successfully Deleted');
            this.common.loading = false;
            this.resetDetail();
          }
        },
        (err) => {
          this.common.loading = false;
        }
      );
  }

  saveData() {
    let data = {};
    let arr: any[] = [];
    this.masterField.forEach((res: any) => {
      res.forEach((item: any) => {
        item.actionId = this.selectedActionId;
        item.processStageId =
          this.selectedMappingName == 'Stage'
            ? this.selectedStageId
            : this.processOrderId;
        item.mappingOn = this.selectedMappingName == 'Stage' ? 0 : 1;
        arr.push(item);
      });
    });
    data = {
      processStageId:
        this.selectedMappingName == 'Stage'
          ? this.selectedStageId
          : this.processOrderId,
      methodData: arr,
      actionId: this.selectedActionId,
      methodMappingId: this.methodMappingId,
      mappingOn: this.selectedMappingName == 'Stage' ? 0 : 1,
    };
    this.api.post('method/save_method_mapping', data).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alert.info('Data Saved Successfully');
          this.resetDetail();
        }
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Driver ',
      description: `<b>&nbsp;` + 'Are you sure want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });
  }

  stageValidator: any;
  processValidator: any;

  formValidator() {
    this.companyValidator = this.common.nullValidator(
      this.companyId,
      'Company Mandatory'
    );
    this.plantNameValidator = this.common.nullValidator(
      this.plantName,
      'Plant Name Mandatory'
    );
    this.stageValidator = this.common.nullValidator(
      this.selectedStageName,
      'Stage is Mandatory'
    );
    this.processValidator = this.common.nullValidator(
      this.selectedProcess,
      'Process is Mandatory'
    );
    if (this.role.user_role == 'AXESTRACK') {
      if (
        !this.companyValidator.error &&
        !this.plantNameValidator.error &&
        !this.processValidator.error &&
        (this.btn == 'save' || this.btn == 'update')
      ) {
        this.saveData();
      }
    } else {
      if (
        !this.stageValidator.error &&
        !this.processValidator.error &&
        (this.btn == 'save' || this.btn == 'update')
      ) {
        this.saveData();
      }
    }
  }
}

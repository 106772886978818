<div class="page_design">
  <div class="row tab_sec">
    <div class="col-md-8 date_div">
      <div class="row">
        <div class="col-md-5 date">
          <label>{{'start_date:'|translate}}</label>
          <input class="form-control" type="date" name="startDate" [(ngModel)]="fromDate">
        </div>
        <div class="col-md-5 date">
          <label>{{'end_date:'|translate}}</label>
          <input class="form-control" type="date" name="endDate" [(ngModel)]="toDate">
        </div>
        <div class="col-md-1 btn-block text-right">
          <button class="btn btn-primary" (click)="getEmpShiftReport(0)">{{"search" | translate}}</button>
        </div>
      </div>
    </div>

    <div class="col-md-4 buttons text-right">
      <button type="button" [class.active]="tabvalue == 'table'" (click)="tabvalue = 'table'; renderTable()"><i
          class="fa fa-table"></i> &nbsp;{{'table_view'|translate}}</button>
      <button type="button" [class.active]="tabvalue == 'graph'" (click)="tabvalue = 'graph'; drawGraphs()"><i
          class="fa fa-chart-pie"></i> &nbsp;{{'graph_view'|translate}}</button>

    </div>
  </div>

  <div class="view_table" [hidden]="(tabvalue != 'table')">
    <div class="filter_sec">
      <div class="row">
        <div class="col-md-3 coll" *ngIf="role.user_role=='COMPANYADMIN'">
          <label>{{'plant:'|translate}}</label>
          <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
            placeholder="Choose Plant">
          </ps-auto-suggestion>
        </div>
        <div class="col-md-3 coll">
          <label>{{'select_username:'|translate}}</label>
          <ps-auto-suggestion [data]="userList" display="name" seperator="-" placeholder="Select User"
            (onSelected)="selectedUser($event)" inputId="userName" name="userName" [preSelected]="null"
            isMultiSelect="true"></ps-auto-suggestion>
        </div>
        <div class="col-md-3 coll">
          <label>{{'select_process:'|translate}}</label>
          <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedProcess($event)" [data]="listOfProcess"
            display="processName" placeholder="Select Process" name="selectedActionData" inputId="selectedActionData">
          </ps-auto-suggestion>
        </div>
        <div class="col-md-3 coll">
          <label>{{'select_stage:'|translate}}</label>
          <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedStage($event)" [data]="stageList"
            display="stageName" placeholder="Choose Stage">
          </ps-auto-suggestion>
        </div>
      </div>
    </div>

    <div class="table_sec">
      <table class="table table-bordered table-striped" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead>
          <tr>
            <th>{{'serial_no'|translate}}</th>
            <th>{{'date'|translate}}</th>
            <th>{{'user_name'|translate}}</th>
            <th>{{('shift'|translate)+' '+('time'|translate)}}</th>
            <th>{{'Process'|translate}}</th>
            <th>{{'Stage'|translate}}</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let row of empShiftReports  let i = index">
            <td>{{ i+1 }}</td>
            <td>{{row.date | date:'dd-MM-yyyy'}}</td>
            <td>{{row.name}}</td>
            <td>{{row.shift_start_time}} - {{row.shift_end_time}}</td>
            <td>{{row.process_name}}</td>
            <td>{{row.stage_name}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="view_graph" [hidden]="(tabvalue != 'graph')">
    <!-- <p>graph will be here</p> -->
    <div class="row">
      <div class="col-lg-6 mt-3">
        <div style="position: relative;" id="graph-468"></div>
      </div>
      <div class="col-lg-6 mt-3">
        <div style="position: relative;" id="graph-469"></div>
      </div>

    </div>
    <div class="row" style="margin-top: -15px; padding-bottom: 15px;">
      <div class="col-lg-12">
        <div style="position: relative; margin-top:30px;" id="graph-467"></div>
      </div>
    </div>
  </div>
</div>

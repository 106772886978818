<div class="container-fluid">
    <div class="container-card">
        <div class="container-card-body">
            <div class="row">
                <div class="col-md-12">
                    <!-- <div class="card" style="padding: 12px;"> -->

                        <div class="field">
                            <label for="my-input" class="{{ assign == 'Driver'? 'required' : '' }}">{{assign|customTranslate}} {{"image"|customTranslate}}</label>
                            <div *ngIf="(editable==false && retakeImage==false && image!=null)||(editable==true && id!=null && image!=null)">
                                {{imageName(image)}}
                                <div class="image_div" *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                                    <div class="image" (click)="displayImage(image,assign)">
                                        <img class="align-image" [src]="image_url" alt="" height="65px">
                                    </div>
                                </div>
                                <div class="image_div" *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                                    <div class="image" (click)="newTab(image)">
                                        <img class="align-image" src="assets/images/document_img.png" height="65px">
                                    </div>
                                </div>
                            </div>
                            <div class="image_div" *ngIf="editable==false && retakeImage==false && image==null">
                                <div class="image" >
                                    <img class="align-image" src="assets/images/blank-profile-picture.jpg" height="65px">
                                </div>
                            </div>
                            <div *ngIf="editable==true">
                                <app-camera-component [retake]="retakeImage" [id]="'field'"
                                    (getPicture)="handleImage($event,'driver_image')" *ngIf="image==null">
                                </app-camera-component>
                                <div style="display: flex; flex-direction: column; align-items:center;"
                                    *ngIf="image!=null">
                                    <p>Upload Done! <button class="btn btn-warning"
                                            (click)="retakeImage = !retakeImage;image=null;">Retake</button>
                                    </p>
                                </div>
                            </div>
                            <div class="error"
                            *ngIf="driverImageValidator && driverImageValidator.error">
                            <span>{{driverImageValidator.msg}} </span>
                        </div>
                        </div>

                        <div class="field">
                            <label for="my-input" class="required">{{assign|customTranslate}} {{"name"|customTranslate}}</label>
                            <input type="text" class="field_class" [(ngModel)]="name" [placeholder]="(assign|customTranslate)+' Name'"
                                [disabled]="!editable">
                            <div class="error" *ngIf="nameValidator && nameValidator.error">
                                <span>{{nameValidator.msg}} </span>
                            </div>
                        </div>

                        <div class="field">
                            <label for="my-input" class="required">{{assign|customTranslate}} {{"number"|customTranslate}}</label>
                            <input type="text" class="field_class" #numberInput="ngModel" [(ngModel)]="number"
                                placeholder="Contact Number" [disabled]="!editable" required pattern="[0-9]{10}" maxlength="10">
                            <div class="error" *ngIf="numberValidator && numberValidator.error">
                                <span>{{numberValidator.msg}} </span>
                            </div>
                        </div>

                        <div class="field">
                            <label for="my-input">{{assign|customTranslate}} {{"alt_number"|customTranslate}}</label>
                            <input type="text" class="field_class" #altNumberInput="ngModel" [(ngModel)]="altMobileNumber"
                                placeholder="Alternate Contact Number" [disabled]="!editable" required pattern="[0-9]{10}" maxlength="10">
                        </div>

                        <div class="field" *ngIf="assign=='Driver'">
                            <label for="my-input" class="required">{{"driver_liscence_number"|customTranslate}}</label>
                            <input type="text" class="field_class" [(ngModel)]="driverLiscenceNumber"
                                placeholder="Driver Liscence Number" [disabled]="!editable">
                            <div class="error"
                                *ngIf="driverLiscenceNumberValidator && driverLiscenceNumberValidator.error">
                                <span>{{driverLiscenceNumberValidator.msg}} </span>
                            </div>
                        </div>

                        <div class="field" *ngIf="assign=='Driver'">
                            <label for="my-input" class="required">{{"licence"|customTranslate}} {{"image"|customTranslate}}</label>
                            <div *ngIf="(editable==false && retakeLiscence==false && driverLiscenceImage!=null) || (editable==true && id!=null && driverLiscenceImage!=null)">
                                {{imageName(driverLiscenceImage)}}
                                <div class="image_div" *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                                    <div class="image" (click)="displayImage(driverLiscenceImage,assign+' Licence')">
                                        <img class="align-image" [src]="image_url" alt="" height="65px">
                                    </div>
                                </div>
                                <div class="image_div" *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                                    <div class="image" (click)="newTab(driverLiscenceImage)">
                                        <img class="align-image" src="assets/images/document_img.png" height="65px">
                                    </div>
                                </div>
                            </div>
                            <div class="image_div" *ngIf="editable==false && retakeLiscence==false && driverLiscenceImage==null">
                                <div class="image" >
                                    <img class="align-image" src="assets/images/document_img.png" height="65px">
                                </div>
                            </div>
                            <div *ngIf="editable==true">
                                <app-camera-component [retake]="retakeLiscence" [id]="'field'"
                                    (getPicture)="handleImage($event,'driver_liscence')"
                                    *ngIf="driverLiscenceImage==null">
                                </app-camera-component>
                                <div style="display: flex; flex-direction: column; align-items:center;"
                                    *ngIf="driverLiscenceImage!=null">
                                    <p>Upload Done! <button class="btn btn-warning"
                                            (click)="retakeLiscence = !retakeLiscence;driverLiscenceImage=null;">Retake</button>
                                    </p>
                                </div>
                            </div>
                            <div class="error"
                                *ngIf="driverLiscenceImageValidator && driverLiscenceImageValidator.error">
                                <span>{{driverLiscenceImageValidator.msg}} </span>
                            </div>
                        </div>


                        <div class="form-group action-block" *ngIf="editable==true">
                            <div class="btn-block">
                                <button type="submit" class="btn btn-submit" (click)="formValidator()">
                                    {{id!=null?'update':'Add'|customTranslate}}
                                </button>
                            </div>
                        </div>
                    <!-- </div> -->

                </div>
            </div>
        </div>
    </div>


</div>
import { RoleService } from '../../@core/services/role.service';
import { DashboardService } from './dashboard.service';

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from 'src/app/@core/services/user.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

import { CommonService } from 'src/app/@core/services/common.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  allvehicles: any[] = [];
  vehiclecompliancedata: any[] = [];
  vehicleID: number = 0;
  classname: string = '';
  constructor(
    public breadcrumbService: BreadcrumbService,
    public user: UserService,
    public workflow: DashboardService,
    public common: CommonService,
    public role: RoleService
  ) {

    /** breadcums implementation start*/
    let breadcumbs = [
      {
        name: 'Home',
        url: '/user/dashboard',
      },
      {
        name: 'Dashboard',
        url: '/user/dashboard',
      },
    ];

    // this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.breadcrumbService.setActiveBreadCrumb(false);
    /** breadcums implementation end*/
  }

  layout() {
    let services = document.querySelectorAll('.services .row .card-column');
    let length = services.length;

    if (length <= 15) {
      this.classname = 'fixedview';
    } else {
      this.classname = 'maxview';
    }
  }

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.layout();
    this.common.sidebarToggled(true);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftMasterService extends ApiService{
  saveShift(params: any): Observable<any> {
    let apiSubUrl = "shift/add_shift";

    return super.post(apiSubUrl, params);
  }

  getListShift(): Observable<any> {
    let apiSubUrl = "shift/all_shift";
    return super.get(apiSubUrl);
  }


  getListShiftByProcessStage(orderId: any): Observable<any> {
    let apiSubUrl = "shift/get_shift_by_process_stage?processStageId=" + orderId;
    return super.get(apiSubUrl);
  }

  deleteShift(params):Observable<any>{
    let apiSubUrl = "shift/delete_shift";
    return super.get(apiSubUrl,params);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends ApiService {
  getworkflowStatus(dayFilter: string, processId: number): Observable<any> {
    let apiSubUrl = 'dashboard/workflow-status-by-date';

    return super.get(
      `${apiSubUrl}?dayFilter=${dayFilter}&processId=${processId}`
    );
  }

  getworkflowtat(): Observable<any> {
    let apiSubUrl = 'dashboard/workflow-tat';

    return super.get(`${apiSubUrl}`);
  }

  getAllProcess(): Observable<any> {
    let apiSubUrl = 'process/get-all-process?processActive=true';
    return super.get(apiSubUrl);
  }

  getalltatdata(filter: string): Observable<any> {
    let apiSubUrl = 'dashboard/workflow-tat-graph';

    return super.get(`${apiSubUrl}?filter=${filter}`);
  }

  getTripVehicleList(): Observable<any> {
    let apiSubUrl = 'dashboard/trip_list';
    return super.get(`${apiSubUrl}`);
  }

  getAlltatbystate(): Observable<any> {
    let apiSubUrl = 'dashboard/workflow-tat';
    return super.get(`${apiSubUrl}`);
  }

  getstagetat(dayFilter: string, processId: number): Observable<any> {
    let apiSubUrl = 'dashboard/stage-wise-tat';

    return super.get(
      `${apiSubUrl}?dayFilter=${dayFilter}&processId=${processId}`
    );
  }

  stagewisevehicles(): Observable<any> {
    let apiSubUrl = 'dashboard/stage-wise-vehicles';

    return super.get(`${apiSubUrl}`);
  }

  getloaderstatus(data: any): Observable<any> {
    let apiSubUrl = 'destination/list_loader_status?processId=' + data;
    return super.get(`${apiSubUrl}`);
  }

  getallcommoncount() {
    let apiSubUrl = 'dashboard/get-company-plant-process-stage-count';

    return super.get(`${apiSubUrl}`);
  }

  getallcompanysectors() {
    let apiSubUrl = 'dashboard/get-company-count-sector-wise';

    return super.get(`${apiSubUrl}`);
  }

  getProcessOverView(processId: number): Observable<any> {
    let apiSubUrl = 'dashboard/process-wise-stage-vehicles';
    return super.get(`${apiSubUrl}?processId=${processId}`);
  }
}

import { List } from 'lodash';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ProcessService } from '../components/process/process.service';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import * as filesaver from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-mis-report',
  templateUrl: './mis-report.component.html',
  styleUrls: ['./mis-report.component.scss']
})
export class MisReportComponent implements OnInit, AfterViewInit {
  activeSection: string = 'Trips';
  fromDate: any = this.common.dateFormatter(new Date(), 'YYYYMMdd', false);
  toDate: any = this.common.dateFormatter(new Date(), 'YYYYMMdd', false);
  responseData: any = [];
  tabData: any = [
    { tabname: 'Trips', value: 'Trips' },
    { tabname: 'Transporter', value: 'Transporter' },
    { tabname: 'checklist', value: 'checklist' },
  ];
  processId: any[] = [];
  processList: any[] = [];
  stageList: any[] = [];
  stageId: any[] = [];
  headers: { action_id: any; action_name: any; stage_id: any; process_id: any; }[];
  data: any[] = [];
  transporters: any[] = [];
  transporterId: any[] = [];
  processSelected: any[] = [];
  stageSelected: any[] = [];
  transporterSelected: any[] = [];
  checklistId: any[] = [];
  checklistSelected: any[] = [];
  checklistData: any[] = [];
  vehicleList: any[] = [];
  vehicleId: any[] = [];
  vehicleSelected: any[] = [];
  statusList = [{ id: 1, status: 'PASS' }, { id: 2, status: 'FAIL' }, { id: 3, status: 'OVERRIDED PASS' }, { id: 4, status: 'OVERRIDED FAIL' }];
  status: any[] = [];
  statusSelected: any[] = [];
  constructor(
    private breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public alert: AlertService,
    public userService: UserService,
    public role: RoleService,
    private processService: ProcessService,

  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'MIS Report', link: '/user/mis-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }
  ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.getTripsReport();//for fetching default trips mis-report data with date filter
    this.getAllProcess();//for fetching all process
    this.getAllStageByProcess([-1]);//for fetching all stages
    this.getAllVehicles();
  }

  async tabChange() {
    // this.getTripsReport(0);//for fetching default trips mis-report data with date filter
    this.processId = [];
    this.stageId = [];
    this.processSelected = [];
    this.stageSelected = [];
    this.transporterSelected = [];
    this.transporterId = [];
    this.checklistId = [];
    this.checklistSelected = [];
    this.vehicleId = [];
    this.vehicleSelected = [];
    this.status = [];
    this.statusSelected = [];
    this.getAllProcess();//for fetching all process
    this.getAllStageByProcess([-1]);//for fetching all stages
    if (this.activeSection == 'Trips') {
      this.getAllVehicles();
    }
    this.headers = await this.makeHeaders(this.responseData, this.processId, this.stageId);
    this.data = await this.transformData(this.responseData, this.processId, this.stageId, this.transporterId, this.checklistId, this.vehicleId);
    if (this.activeSection == 'Transporter') {
      this.getAllTransporter();
    }
    if (this.activeSection == 'checklist') {
      this.checklistData = this.data;
    }
  }

  @ViewChild('tableToExport', { static: false }) tableToExport: ElementRef;

  downloadPdf(): void {
    const tableElement = this.tableToExport.nativeElement;

    // Set initial scroll positions
    let scrollX = 0;
    let scrollY = 0;

    // Capture function for horizontal sections
    const captureHorizontalSection = () => {
      return html2canvas(tableElement, {
        scrollX,
        scrollY,
        width: tableElement.clientWidth,
        height: tableElement.clientHeight
      });
    };

    // Capture function for vertical sections
    const captureVerticalSection = async () => {
      const horizontalSections: HTMLCanvasElement[] = [];
      let totalWidth = 0;

      while (scrollX < tableElement.scrollWidth) {
        const canvas = await captureHorizontalSection();
        horizontalSections.push(canvas);
        totalWidth += canvas.width;
        scrollX += canvas.width;
      }

      // Reset scrollX for next vertical section
      scrollX = 0;

      return { horizontalSections, totalWidth };
    };

    // Capture function for the entire table
    const captureTable = async () => {
      const verticalSections: HTMLCanvasElement[] = [];
      let totalHeight = 0;

      while (scrollY < tableElement.scrollHeight) {
        const { horizontalSections, totalWidth } = await captureVerticalSection();
        const canvas = document.createElement('canvas');
        canvas.width = totalWidth;
        canvas.height = horizontalSections[0].height;
        const ctx = canvas.getContext('2d');

        let offsetX = 0;
        horizontalSections.forEach(section => {
          ctx.drawImage(section, offsetX, 0);
          offsetX += section.width;
        });

        verticalSections.push(canvas);
        totalHeight += canvas.height;
        scrollY += canvas.height;
      }

      // Reset scrollY for next capture
      scrollY = 0;

      return { verticalSections, totalHeight };
    };

    // Start capturing the table
    captureTable().then(({ verticalSections, totalHeight }) => {
      const pdf = new jsPDF('l', 'pt', [totalHeight, tableElement.scrollWidth]);
      let offsetY = 0;

      verticalSections.forEach(section => {
        pdf.addImage(section.toDataURL('image/png'), 'PNG', 0, offsetY, section.width, section.height);
        offsetY += section.height;
      });

      pdf.save('MIS_' + this.activeSection + '_Report.pdf');
    });
  }

  async selectedProcess(event: any) {
    if (event.length) {//if any selected process present
      this.processId = event.map(item => item.processId);
      this.processSelected = event;
      await this.getAllStageByProcess(this.processId);
    } else {
      //if no selected process left
      if (this.stageId.length == 0) {
        //and no stage selected left than fetch all stage list
        await this.getAllStageByProcess([-1]);
      }
      this.processId = [];
      this.processSelected = [];
    }
    //set headers and data again according to the filter..
    this.headers = await this.makeHeaders(this.responseData, this.processId, this.stageId);
    this.data = await this.transformData(this.responseData, this.processId, this.stageId, this.transporterId, this.checklistId, this.vehicleId);
  }

  async selectTransporter(event: any) {
    if (event.length) {//if any selected transporter present
      this.transporterId = event.map(item => item.id);
      this.transporterSelected = event;
    } else {
      this.transporterId = [];
      this.transporterSelected = [];
    }
    //set headers and data again according to the filter..
    this.headers = await this.makeHeaders(this.responseData, this.processId, this.stageId);
    this.data = await this.transformData(this.responseData, this.processId, this.stageId, this.transporterId, this.checklistId, this.vehicleId);
  }

  async selectVehicle(event: any) {
    if (event.length) {//if any selected Vehicle present
      this.vehicleId = event.map(item => item.vehicle_id);
      this.vehicleSelected = event;
    } else {
      this.vehicleId = [];
      this.vehicleSelected = [];
    }
    //set headers and data again according to the filter..
    this.headers = await this.makeHeaders(this.responseData, this.processId, this.stageId);
    this.data = await this.transformData(this.responseData, this.processId, this.stageId, this.transporterId, this.checklistId, this.vehicleId);
  }

  async selectStatus(event: any) {
    if (event.length) {//if any selected status present
      this.status = event.map(item => item.status);
      this.statusSelected = event;
    } else {
      this.status = [];
      this.statusSelected = [];
    }
    //set headers and data again according to the filter..
    this.headers = await this.makeHeaders(this.responseData, this.processId, this.stageId);
    this.data = await this.transformData(this.responseData, this.processId, this.stageId, this.transporterId, this.checklistId, this.vehicleId, this.status);
  }

  async selectChecklist(event: any) {
    if (event.length) {//if any selected action present
      this.checklistId = event.map(item => item.action_id);
      this.checklistSelected = event;
    } else {
      this.checklistId = [];
      this.checklistSelected = [];
    }
    //set headers and data again according to the filter..
    this.headers = await this.makeHeaders(this.responseData, this.processId, this.stageId);
    this.data = await this.transformData(this.responseData, this.processId, this.stageId, this.transporterId, this.checklistId, this.vehicleId);
  }

  getActionFailPercentage(headerActionId: number, response: any) {
    const totalCount = response.filter(element => element.action_id === headerActionId).length;
    if (totalCount === 0) return 0;

    const failCount = response.reduce((count, element) => {
      return count + (element.action_id === headerActionId && element.response === 'FAIL' ? 1 : 0);
    }, 0);

    return (failCount / totalCount) * 100;
  }

  async selectedStage(event: any) {
    if (event.length) {//selecting stage
      this.stageId = event.map(item => item.stage_id);
      this.stageSelected = event;
    } else {//if no selected stage present
      this.stageId = [];
      this.stageSelected = [];
      if (this.processId.length == 0) {
        //and no selected process present ....fetch all stage list with -1
        this.getAllStageByProcess([-1]);
      }
    }
    //set the headers and response again according to the filter
    this.headers = await this.makeHeaders(this.responseData, this.processId, this.stageId);
    if (this.activeSection == 'checklist') {
      this.data = await this.transformData(this.responseData, this.processId, this.stageId, this.transporterId, this.checklistId, this.vehicleId);
    }
  }

  //fetching stages according to the process ids selected...
  async getAllStageByProcess(processId: any[]) {
    return new Promise((resolve, reject) => {
      if (processId != null) {
        this.common.loading = true;
        let apiUrl = `stage/stage_by_process?processIds=${processId}`;
        this.api.get(apiUrl).subscribe(
          (res: any) => {
            this.common.loading = false;
            if (res?.data?.length) {
              this.stageList = res.data;
              //if one of the process is unselected than after this api is called it's corresponding stages are removed but it remains in the stageId list...
              //we are removing those selected stages from stageId list that are no more present in the stageList..
              this.stageId = this.stageId.filter(id => this.stageList.some(stage => stage.stage_id === id));
              resolve(this.stageList);
            } else {
              this.alert.info(`Stages not found`);
            }
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
      }
    });
  }

  //getiing all process list...
  getAllProcess() {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.processService.getAllProcessList().subscribe(
        (res: any) => {
          this.common.loading = false;
          this.processList = res.data;
          resolve(this.processList);

        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  getAllTransporter() {
    this.common.loading = true;
    this.api.get('transporter/get-all-transporters').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.transporters = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllVehicles() {
    this.common.loading = true;
    this.api.get('vehicle/get-all-vehicles').subscribe(
      (res: any) => {
        this.vehicleList = res.data;
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }


  //fetching trips mis-report data..
  getTripsReport() {
    let params = {
      startTime: this.fromDate,
      endTime: this.toDate,
    };
    this.common.loading = true;

    this.api.get('report/get-mis-trips-report', params).subscribe(
      async (res: any) => {
        this.common.loading = false;
        this.responseData = res.data;
        //making headers of table
        this.headers = await this.makeHeaders(res.data, this.processId, this.stageId);
        //making data of table
        this.data = await this.transformData(res.data, this.processId, this.stageId, this.transporterId, this.checklistId, this.vehicleId);
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  exportAsExcel(data: any[], headers: any[], fileName: string): void {
    let worksheet =null;
    if(this.activeSection=='Trips'){
    // Create worksheet
     worksheet = XLSX.utils.aoa_to_sheet([
      ["vehicle_number", "txn_id", "process", "trip_status", ...headers.map(header => header.action_name)]
    ]);

    // Fill data
    data.forEach((rowData, rowIndex) => {
      const rowValues = [
        rowData.vehicle_rc_no,
        rowData.transaction_name,
        rowData.process_name,
        rowData.trip_status,
        ...headers.map(header => rowData[header.action_id])
      ];
      XLSX.utils.sheet_add_aoa(worksheet, [rowValues], { origin: -1 });
    });
  }else if(this.activeSection=='Transporter'){
      // Create worksheet
       worksheet = XLSX.utils.aoa_to_sheet([
        ["Transporter", "Total Vehicle", "inprogress", "Cancelled","completed", ...headers.map(header => header.action_name)]
      ]);

      // Fill data
      data.forEach((rowData, rowIndex) => {
        const rowValues = [
          rowData.transporter_name,
          rowData.total_vehicles,
          rowData.inprogress,
          rowData.cancelled,
          rowData.completed,
          ...headers.map(header =>rowData.action[header.action_id]+'%')
        ];
        XLSX.utils.sheet_add_aoa(worksheet, [rowValues], { origin: -1 });
      });
  }else{
     // Create worksheet
     worksheet = XLSX.utils.aoa_to_sheet([
      ["action_name", "compliance_pass", "compliance_fail", "compliance_pass_count","compliance_fail_count","Total Vehicle"]
    ]);

    // Fill data
    data.forEach((rowData, rowIndex) => {
      const rowValues = [
        rowData.action_name,
        rowData.pass_percentage,
        rowData.fail_percentage,
        rowData.total_pass,
        rowData.total_fail,
        rowData.total_actions,
      ];
      XLSX.utils.sheet_add_aoa(worksheet, [rowValues], { origin: -1 });
    });
  }

    // Creating workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Writing workbook to Excel buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Creating blob
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Saving blob as Excel file
    filesaver.saveAs(blob, fileName + '.xlsx');
  }

  transformData(data: any[], processId: number[], stageId: number[], transporterId: number[] = [], checkListId: number[] = [], vehicleId: number[] = [], status: string[] = []): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (this.activeSection == 'Trips') {
        // Group data by ipa_transaction_id
        let filteredData = data;

        if (processId.length > 0 || stageId.length > 0) {
          filteredData = _.filter(data, item => {
            return (processId.length === 0 || processId.includes(item.process_id)) &&
              (stageId.length === 0 || stageId.includes(item.stage_id));
          });
        }

        const groupedByTransaction = _.groupBy(filteredData, 'ipa_transaction_id');
        const transformedData = [];
        // Iterate over each group of transactions
        for (const transactionId in groupedByTransaction) {
          if (groupedByTransaction.hasOwnProperty(transactionId)) {
            const transactions = groupedByTransaction[transactionId];
            // Extract common metadata from the first transaction
            const commonMetadata = {
              process_id: transactions[0].process_id,
              driver_id: transactions[0].driver_id,
              vehicle_rc_no: transactions[0].vehicle_rc_no,
              stage_id: transactions[0].stage_id,
              vehicle_id: transactions[0].vehicle_id,
              driver_name: transactions[0].driver_name,
              transporter_name: transactions[0].transporter_name,
              stage_name: transactions[0].stage_name,
              process_name: transactions[0].process_name,
              ipa_transaction_id: transactions[0].ipa_transaction_id,
              transporter_id: transactions[0].transporter_id,
              transaction_name: transactions[0].transaction_name,
              trip_status: transactions[0].stage_workflow_status
            };

            const uniqueActions = {};

            // Iterate over each transaction to collect unique actions
            transactions.forEach(transaction => {
              if (!uniqueActions.hasOwnProperty(transaction.action_id)) {
                let actionStatus = transaction.response_status;
                if (transaction.overriding_status != null && transaction.overriding_status == 'PASS') {
                  actionStatus = 'OVERRIDED PASS';
                } else if (transaction.overriding_status != null && transaction.overriding_status == 'FAIL') {
                  actionStatus = 'OVERRIDED FAIL';
                }
                if (status.length == 0) {
                  uniqueActions[transaction.action_id] = actionStatus;
                } else if (status.includes(actionStatus)) {
                  uniqueActions[transaction.action_id] = actionStatus;
                }
              }
            });

            // Construct the transformed transaction object
            const transformedTransaction = {
              ...commonMetadata,
              // actions: Object.values(uniqueActions)
              ...uniqueActions
            };
            // Apply filtering based on processId and stageId
            if (processId.length === 0 && stageId.length === 0 && vehicleId.length === 0) {
              // If both processId and stageId arrays are empty, include the transaction
              transformedData.push(transformedTransaction);
            } else {
              // Filter transactions based on processId and stageId
              const processIdFilter = processId.length === 0 || processId.includes(transformedTransaction.process_id);
              const stageIdFilter = stageId.length === 0 || stageId.includes(transformedTransaction.stage_id);
              const vehicleIdFilter = vehicleId.length === 0 || vehicleId.includes(transformedTransaction.vehicle_id);
              if (processIdFilter && stageIdFilter && vehicleIdFilter) {
                transformedData.push(transformedTransaction);
              }
            }
          }
        }
        resolve(transformedData);
      } else if (this.activeSection == 'Transporter') {
        let filteredData = data;

        if (processId.length > 0 || stageId.length > 0) {
          filteredData = _.filter(data, item => {
            return (processId.length === 0 || processId.includes(item.process_id)) &&
              (stageId.length === 0 || stageId.includes(item.stage_id));
          });
        }
        // Group the data by transporter_id
        const groupedByTransporter = _.groupBy(filteredData, 'transporter_id');
        // Initialize an array to store the transformed data
        const transformedData = [];

        // Iterate over each group of transactions
        for (const transporterId in groupedByTransporter) {
          if (groupedByTransporter.hasOwnProperty(transporterId)) {
            const transporterData = groupedByTransporter[transporterId];
            // Calculate the total number of vehicles
            const totalVehicles = _.size(_.mapValues(_.groupBy(transporterData, 'vehicle_id'), entries => _.uniqBy(entries, 'vehicle_id')));
            // Calculate the number of vehicles in progress
            const inProgressData = _.filter(transporterData, { 'stage_workflow_status': 'INPROGRESS' });

            // Count the occurrences of 'vehicle_id' while considering each 'vehicle_id' only once
            const vehicleCounts = _.chain(inProgressData)
              .map('vehicle_id')
              .uniq()
              .size()
              .value();
            const inProgress = vehicleCounts;
            // Calculate the number of vehicles cancelled
            const cancelledData = _.filter(transporterData, { 'stage_workflow_status': 'CANCELLED' });

            // Count the occurrences of 'vehicle_id' while considering each 'vehicle_id' only once
            const vehicleCountscancelled = _.chain(cancelledData)
              .map('vehicle_id')
              .uniq()
              .size()
              .value();
            const cancelled = vehicleCountscancelled;
            // Calculate the number of vehicles completed
            const completedData = _.filter(transporterData, { 'stage_workflow_status': 'COMPLETED' });

            // Count the occurrences of 'vehicle_id' while considering each 'vehicle_id' only once
            const vehicleCountscompleted = _.chain(completedData)
              .map('vehicle_id')
              .uniq()
              .size()
              .value();
            const completed = vehicleCountscompleted;
            // Get the transporter name
            const transporterName = transporterData[0].transporter_name;
            const processId = transporterData[0].process_id;
            const stageId = transporterData[0].stage_id;

            // Group the data by action_id and response_status
            const groupedData = _.groupBy(transporterData, 'action_id');
            const result = {};

            // Calculate the percentage of objects with "FAIL" response_status for each action_id
            _.forEach(this.headers, (header) => {
              const actionId = header.action_id;
              if (!result.hasOwnProperty(actionId)) {
                const group = groupedData[actionId];
                if (group) {
                  const total = group.length;
                  const failCount = _.filter(group, { 'response_status': 'FAIL' }).length;
                  result[actionId] = Math.round((failCount / total) * 100);
                } else {
                  // If action_id not found in groupedData, assign 0
                  result[actionId] = 0;
                }
              }
            });

            // Construct the transformed object
            const transformedObject = {
              transporter_id: parseInt(transporterId),
              transporter_name: transporterName,
              process_id: processId,
              stage_id: stageId,
              total_vehicles: totalVehicles,
              inprogress: inProgress,
              completed: completed,
              cancelled: cancelled,
              action: result
              // actions: Object.values(uniqueActions)
            };

            // Apply filtering based on processId and stageId
            if (this.processId.length === 0 && this.stageId.length === 0 && this.transporterId.length === 0) {
              // If both processId and stageId arrays are empty, include the transaction
              if (transformedObject.transporter_name != null) {
                transformedData.push(transformedObject);
              }
            } else {
              // Filter transactions based on processId and stageId
              const processIdFilter = this.processId.length === 0 || this.processId.includes(transformedObject.process_id);
              const stageIdFilter = this.stageId.length === 0 || this.stageId.includes(transformedObject.stage_id);
              const transporterIdIdFilter = this.transporterId.length === 0 || this.transporterId.includes(transformedObject.transporter_id);
              if (processIdFilter && stageIdFilter && transporterIdIdFilter) {
                if (transformedObject.transporter_name != null) {
                  transformedData.push(transformedObject);
                }
              }
            }
          }
        }
        resolve(transformedData);
        // Return the transformed data
      } else {
        let filteredData = data;

        if (processId.length > 0 || stageId.length > 0 || checkListId.length > 0) {
          filteredData = data.filter(item => {
            return (processId.length === 0 || processId.includes(item.process_id)) &&
              (stageId.length === 0 || stageId.includes(item.stage_id)) && (checkListId.length === 0 || checkListId.includes(item.action_id));
          });
        }

        const actionMap = new Map<number, { pass: number, fail: number, action_name: string }>();

        filteredData.forEach(item => {
          const { action_id, response_status, action_name } = item;
          if (actionMap.has(action_id)) {
            if (response_status === 'PASS') {
              actionMap.get(action_id).pass++;
            } else if (response_status === 'FAIL') {
              actionMap.get(action_id).fail++;
            }
          } else {
            actionMap.set(action_id, { pass: response_status === 'PASS' ? 1 : 0, fail: response_status === 'FAIL' ? 1 : 0, action_name: action_name });
          }
        });

        const resultList = [];
        let totalActions = 0;
        let totalPass = 0;
        let totalFail = 0;

        actionMap.forEach((value, key) => {
          const total = value.pass + value.fail;
          const passPercentage = value.pass !== 0 ? Math.round((value.pass / total) * 100) + '%' : '0%';
          const failPercentage = value.fail !== 0 ? Math.round((value.fail / total) * 100) + '%' : '0%';
          resultList.push({
            action_id: key,
            action_name: value.action_name,
            pass_percentage: passPercentage,
            fail_percentage: failPercentage,
            total_actions: total,
            total_pass: value.pass,
            total_fail: value.fail
          });

          totalActions += total;
          totalPass += value.pass;
          totalFail += value.fail;
        });

        resolve(resultList);
      }
    });
  }

  makeHeaders(data: any[], processId: number[] = [], stageId: number[] = []): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (processId.length === 0 && stageId.length === 0) {
        // If both processId and stageId are empty, return original data
        resolve(_.uniqBy(data, (transaction: any) => transaction.action_id)
          .map((transaction: any) => ({
            action_id: transaction.action_id,
            action_name: transaction.action_name,
            stage_id: transaction.stage_id,
            process_id: transaction.process_id
          })));
      } else {
        // Filter data based on processId and stageId
        let filteredData = data;
        if (processId.length > 0) {
          filteredData = filteredData.filter((transaction: any) => processId.includes(transaction.process_id));
        }
        if (stageId.length > 0) {
          filteredData = filteredData.filter((transaction: any) => stageId.includes(transaction.stage_id));
        }


        // Return unique headers
        resolve(_.uniqBy(filteredData, (transaction: any) => transaction.action_id)
          .map((transaction: any) => ({
            action_id: transaction.action_id,
            action_name: transaction.action_name,
            stage_id: transaction.stage_id,
            process_id: transaction.process_id
          }))
        );

      }
    });
  }


}

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as shape from 'd3-shape';
import { Edge, ClusterNode, Layout } from '@swimlane/ngx-graph';
import { Subject } from 'rxjs';
import { VehicleDetailComponent } from '../../vehicle-detail/vehicle-detail.component';
import { DriverTrackerComponent } from '../../driver-tracker/driver-tracker.component';
import { GraphComponent } from '@swimlane/ngx-graph';

@Component({
  selector: 'app-trip-flow',
  templateUrl: './trip-flow.component.html',
  styleUrls: ['./trip-flow.component.scss'],
})
export class TripFlowComponent implements OnInit {
  @ViewChild('graph') graph: GraphComponent;
  name = 'NGX-Graph Demo';
  @Input() processId: number;
  @Input() currentProcess: string;
  @Input() tripViewData: any[] = [];
  hierarchialGraph = { nodes: [], links: [] };
  showSettings: boolean = false;
  vehicleNumber: string;
  allProcesses: any;
  activeIndex: number = 0;
  detailData: any[] = [];
  detailType: any;
  detailAllData: any[] = [];
  modalDetailTitle: string = '';
  detailImages = {
    1: 'assets/images/truck-plant.png',
    2: 'assets/images/truck-stage.png',
    3: 'assets/images/truck-destination.png',
  };
  @ViewChild('detailModal') detailModal: ElementRef;

    ngOnInit() {
      console.log("inside trips")
    this.setInterpolationType(this.curveType);
  }

  nodeColors = [
    '#6f7ae9',
    '#c265e4',
    '#8f8f8f',
    '#d7ba98',
    '#92b2d0',
    '#a68fb7',
    '#e17171',
  ];
  nodes: any[] = [];
  clusters: ClusterNode[] = [];

  links: Edge[] = [];

  layout: String | Layout = 'dagreCluster';
  layouts: any[] = [
    {
      label: 'Dagre',
      value: 'dagre',
    },
    {
      label: 'Dagre Cluster',
      value: 'dagreCluster',
      isClustered: true,
    },
    {
      label: 'Cola Force Directed',
      value: 'colaForceDirected',
      isClustered: true,
    },
    {
      label: 'D3 Force Directed',
      value: 'd3ForceDirected',
    },
  ];

  // line interpolation
  curveType: string = 'Linear';
  curve: any = shape.curveLinear;
  interpolationTypes = [
    'Bundle',
    'Cardinal',
    'Catmull Rom',
    'Linear',
    'Monotone X',
    'Monotone Y',
    'Natural',
    'Step',
    'Step After',
    'Step Before',
  ];

  draggingEnabled: boolean = false;
  panningEnabled: boolean = true;
  zoomEnabled: boolean = true;

  zoomSpeed: number = 0.1;
  minZoomLevel: number = 0.1;
  maxZoomLevel: number = 4.0;
  panOnZoom: boolean = true;

  autoZoom: boolean = true;
  autoCenter: boolean = true;

  update$: any = new Subject();
  center$: any = new Subject();
  zoomToFit$: any = new Subject();
  dynamicData: boolean = true;
  tripGraphShow: boolean = false;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('tripViewData changes:', this.tripViewData);
    this.nodes = [];
    this.links = [];
    this.getDynamicData();
    this.refreshChart();
    // this.switchGraphData();
  }

  getDynamicData() {
    setTimeout(() => {
      this.tripGraphShow = !!this.tripViewData.length;
      if (this.tripGraphShow) {
        this.tripViewData.forEach((stage) => {
          this.nodes.push({
            id: String(stage.stage_id),
            label: `${stage.stage_name}`,
            expected_tat: String(stage.expected_tat),
            average_tat: String(stage.actual_tat),
          });
          if (stage.next_stages?.length) {
            stage.next_stages.forEach((link) => {
              this.links.push({
                id: this.getUniqueRandomString(),
                source: String(stage.stage_id),
                target: String(link.next_stage_id),
              });
            });
          }
        });
        this.refreshChart();
      }
    }, 100);
  }

  nodeIndex(nodeId: string): number {
    return this.nodes.findIndex((node) => node.id === nodeId);
  }

  getNode(node, detailModal) {
    console.log("tripViewData",this.tripViewData)
    this.detailData = this.tripViewData.find(
      (item) => item.stage_id == +node.id
    ).trips;
    this.detailAllData = JSON.parse(JSON.stringify(this.detailData));
    this.modalService.open(detailModal, {
      size: 'lg',
      backdrop: 'static',
      backdropClass: 'detail-modal-backdrop',
    });
  }

  switchGraphData() {
    this.dynamicData = !this.dynamicData;
    this.nodes = [];
    this.links = [];
    if (this.dynamicData) {
      this.getDynamicData();
    }

    this.refreshChart();
  }



  setInterpolationType(curveType) {
    this.curveType = curveType;
    if (curveType === 'Bundle') {
      this.curve = shape.curveBundle.beta(1);
    }
    if (curveType === 'Cardinal') {
      this.curve = shape.curveCardinal;
    }
    if (curveType === 'Catmull Rom') {
      this.curve = shape.curveCatmullRom;
    }
    if (curveType === 'Linear') {
      this.curve = shape.curveLinear;
    }
    if (curveType === 'Monotone X') {
      this.curve = shape.curveMonotoneX;
    }
    if (curveType === 'Monotone Y') {
      this.curve = shape.curveMonotoneY;
    }
    if (curveType === 'Natural') {
      this.curve = shape.curveNatural;
    }
    if (curveType === 'Step') {
      this.curve = shape.curveStep;
    }
    if (curveType === 'Step After') {
      this.curve = shape.curveStepAfter;
    }
    if (curveType === 'Step Before') {
      this.curve = shape.curveStepBefore;
    }
  }

  setLayout(layoutName: string): void {
    const layout = this.layouts.find((l) => l.value === layoutName);
    this.layout = layoutName;
    if (!layout.isClustered) {
      this.clusters = undefined;
    }
  }

  getUniqueRandomString() {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let array = new Uint8Array(10);
    window.crypto.getRandomValues(array);
    array = array.map((x) => validChars.charCodeAt(x % validChars.length));
    const randomState = String.fromCharCode.apply(null, array);
    return randomState;
  }

  refreshChart() {
    this.update$.next(true);
    this.zoomToFit$.next(true);
  }

  closeModal(response) {
    this.activeModal.close(response);
  }

  tripModal(vehicle) {
    let processName = this.currentProcess;
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.trip_id,
      processName: processName,
      isModal: true,
    };
  }

  tripTrackingModal(vehicle) {
    let processName = this.currentProcess;
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.trip_id,
      vehicleName: vehicle.vehicle_rc_no + '-' + processName,
      isModal: true,
    };
  }

  SearchVehicle() {
    if (this.vehicleNumber.trim()) {
      this.detailData = this.detailAllData.filter((item) =>
        item.vehicle_rc_no
          .toLowerCase()
          .includes(this.vehicleNumber.trim().toLowerCase())
      );
    } else {
      this.detailData = this.detailAllData;
    }
  }

  getXPosition(initPosition) {
    let temp = 128;
    let remain = initPosition - temp;
    if (remain > 0) {
      return 37 + remain / 2;
    } else {
      return 37 - Math.abs(remain) / 2;
    }
  }
  getNodeWidth(width) {
    width = width + 60;
    return width;
  }
}

<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{ ("Zone Stage Mapping" | lowercase) | translate }}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div *ngIf="role.user_role=='AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                    <label for="my-input" class="required">{{ "select_company" | translate }}</label>
                    <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyList"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}" [readonly]="isEdit">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyNameValidator && companyNameValidator.error">
                    <span>{{ companyNameValidator.msg }} </span>
                  </div>
                  </div>

                <div *ngIf="role.user_role=='AXESTRACK' || role.user_role=='COMPANYADMIN'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{ "select_plant" | translate }}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)"
                    [data]="plantList" display="plantName" placeholder="Choose Plant"
                    [preSelected]="{ plantName: plantName }" [readonly]="isEdit">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                  <span>{{ plantNameValidator.msg }} </span>
                </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{ "Select Zones" | translate }}</label>
                  <ps-auto-suggestion (onSelected)="selectedZone($event)"
                    [data]="zoneData" display="zone_name" placeholder="Choose zone"
                    [preSelected]="{ zone_name: zoneName }" [readonly]="isEdit">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="zoneValidator && zoneValidator.error">
                  <span>{{ zoneValidator.msg }} </span>
                </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"P>{{"Select Stage"| translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedStages($event)" [data]='stageList' display="stageName" [preSelected]="selectedStage"
                    placeholder="Choose Stage" isMultiSelect="true">
                    </ps-auto-suggestion>
                    <div class="error" *ngIf="stageValidator && stageValidator.error">
                      <span>{{ stageValidator.msg }} </span>
                    </div>
                </div>

              </div>
              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    translate}}</button>
                  <button class="btn btn-warning" (click)="reset()"> {{ "reset" | translate }} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()">
                    {{ buttonName | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{ "Zone Stage Mappings " | translate }}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{ "s.no." | translate }}</th>
                    <th>{{ "zone" | translate }}</th>
                    <th style="min-width: 30%;">{{ "Stages " | translate }}</th>
                    <!-- <th>{{ "status" | translate }}</th> -->
                    <th *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{ "plant_name" | translate }}</th>
                    <th *ngIf="role.user_role=='AXESTRACK'">{{ "company_name" | translate }}</th>
                    <th class="action">{{ "action" | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of groupedDataArray; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ row[0].zone_name }}</td>
                    <td>{{ row | formatNames: { key: 'stage_name' } }}</td>
                    <!-- <td>{{ row[0].status }}</td> -->
                    <td *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{ row[0].plant_name }}</td>
                    <td *ngIf="role.user_role=='AXESTRACK'">{{ row[0].company_name }}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewZoneStageMappingDetails(row)">
                          {{ "view" | translate }}
                        </button>
                        <button  class="btn btn-success"
                       (click)="editZoneStageMapping(row)">{{ "edit" | translate }}
                        </button>
                        <button class="btn btn-danger"
                        (click)="confirmDeleteZoneStageMapping(row)"> {{ "delete" | translate }}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

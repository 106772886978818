<div class="container-fluid">
  <div class="error">{{ responseMessage }}
  </div>
  <div class="container-card">
    <div class="container-card-body process-form">

      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_process_type_access')==true ||userService.permissionMap.get('edit_process_type_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'process_type'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'process_type name'|customTranslate}}</label>
                  <input class="form-control" type="text" name="workTypeName" [(ngModel)]="workType.workTypeName">
                  <div class="error" *ngIf="workTypeNameValidator && workTypeNameValidator.error">
                    <span>{{workTypeNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required"> {{'status'|customTranslate}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="workTypeStatus"
                      [(ngModel)]="workType.workTypeStatus" id="work_type_status_1" value="ACTIVE" checked>
                    <label for="work_type_status_radios1">
                      {{'active'|customTranslate}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="workTypeStatus"
                      [(ngModel)]="workType.workTypeStatus" id="workTypeStatus" value="INACTIVE">
                    <label for="work_type_status_radio2">
                      {{'inactive'|customTranslate}}
                    </label>
                  </div>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)=" common.backClicked()"> {{'back'|customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="resetWorkType()"> {{'reset'|customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{buttonName|customTranslate}}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'process_type list'|customTranslate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{'serial_no'|customTranslate}}</th>
                    <th>{{'process_type'|customTranslate}}</th>
                    <th>{{'status'|customTranslate}}</th>
                    <th>{{'created_date'|customTranslate}}</th>
                    <th class="action">{{'Actions'|customTranslate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of responseData let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.workTypeName}}</td>
                    <td>{{row.workTypeStatus}}</td>
                    <td>{{row.createdDate | date:'dd-MM-yyyy'}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewWorkType(row)"
                          *ngIf="userService.permissionMap.get('view_process_type_access')==true"> {{'view'|customTranslate}}
                        </button>
                        <button class="btn btn-success" (click)="editWorkType(row)"
                          *ngIf="userService.permissionMap.get('edit_process_type_access')==true"> {{'edit'|customTranslate}}
                        </button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)"
                          *ngIf="userService.permissionMap.get('delete_process_type_access')==true">
                          {{'delete'|customTranslate}} </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

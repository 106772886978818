<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body process-form">
      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_process_access')==true ||userService.permissionMap.get('edit_process_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'process_management'|customTranslate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'COMPANYADMIN'">
                  <label for="my-input" class="required">{{"select_plant" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectPlant($event)" [data]="plantList"
                    display="plantName" placeholder="Choose Plant"
                    [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{plantNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'process_name'|customTranslate}}</label>
                  <input class="form-control" type="text" name="processName" [(ngModel)]="process.processName">
                  <div class="error" *ngIf="processNameValidator && processNameValidator.error">
                    <span>{{processNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{'process_description'|customTranslate}}</label>
                  <input class="form-control" type="email" name="processDescription"
                    [(ngModel)]="process.processDescription">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label class="required" for="sel1">{{'process_type'|customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedWorkType($event)" [data]="workTypeList"
                    display="workTypeName" placeholder="Choose Process Type"
                    [preSelected]="{workTypeName:workTypeName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="workTypeNameValidator && workTypeNameValidator.error">
                    <span>{{workTypeNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="required"> {{'status'|customTranslate}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="processStatus"
                      [(ngModel)]="process.processStatus" id="process_status_1" value="ACTIVE" checked>
                    <label for="exampleRadios1">
                      {{'active'|customTranslate}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="processStatus"
                      [(ngModel)]="process.processStatus" id="process_status_2" value="INACTIVE">
                    <label for="exampleRadios2">
                      {{'inactive'|customTranslate}}
                    </label>
                  </div>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{'back'|customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="resetProcess()"> {{'reset'|customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{buttonName|customTranslate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'process_list'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{'serial_no'|customTranslate}}</th>
                    <th>{{'name'|customTranslate}}</th>
                    <th>{{'process_type'|customTranslate}}</th>
                    <th>{{'status'|customTranslate}}</th>
                    <th>{{'created_date'|customTranslate}}</th>
                    <th class="action">{{'Actions'|customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of responseData; let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.processName}}</td>
                    <td>{{row.workTypeName}}</td>
                    <td>{{row.processStatus}}</td>
                    <td>{{row.createdDate | date:'dd-MM-yyyy'}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewProcess(row)"
                          *ngIf="userService.permissionMap.get('view_process_access')==true"> {{'view'|customTranslate}}
                        </button>
                        <button class="btn btn-success" (click)="editProcess(row)"
                          *ngIf="userService.permissionMap.get('edit_process_access')==true"> {{'edit'|customTranslate}}
                        </button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)"
                          *ngIf="userService.permissionMap.get('delete_process_access')==true"> {{'delete'|customTranslate}}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

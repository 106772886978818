<div class="container-fluid">
    <div class="container-card group-card">
      <div class="container-card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="header-block">
                  <h3 class="card-title">
                    {{ "supplier_management" | customTranslate }}
                  </h3>
                </div>
              </div>
              <div class="card-body">
            <div class="row">
            <div *ngIf="role.user_role=='AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input" >{{ "select_company" | customTranslate }}</label>
                <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyList"
                                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                </ps-auto-suggestion>
            </div>
            <div *ngIf="(role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN')" class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input" >{{ "select_plant" | customTranslate }}</label>
                <ps-auto-suggestion (onSelected)="selectedPlant($event)"
                                    [data]="plantList" display="plantName" placeholder="Choose Plant"
                                    [preSelected]="{ plantName: plantName }">
                </ps-auto-suggestion>
            </div>
            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input" class="required">{{ "supplier_first_name" | customTranslate }}</label>
                <input class="form-control" type="text" name="supplierFirstName" id="supplierFirstName"
                    [(ngModel)]="supplier.supplierFirstName" #supplierFirstName="ngModel"
                    placeholder="Enter Supplier First Name">
                    <div class="error" *ngIf="supplierFirstNameValidator && supplierFirstNameValidator.error">
                        <span>{{ supplierFirstNameValidator.msg }} </span>
                    </div>
            </div>
            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input" class="required">{{ "supplier_last_name" | customTranslate }}</label>
                <input class="form-control" type="text" name="supplierLastName" id="supplierLastName"
                    [(ngModel)]="supplier.supplierLastName" #supplierLastName="ngModel"
                    placeholder="Enter Supplier Last Name">
                    <div class="error" *ngIf="supplierLastNameValidator && supplierLastNameValidator.error">
                        <span>{{ supplierLastNameValidator.msg }} </span>
                    </div>
            </div>
            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input" class="required">{{ "supplier_contact_no" | customTranslate }}</label>
                <input class="form-control" type="text" name="supplierContactNo" id="supplierContactNo"
                    [(ngModel)]="supplier.supplierContactNo" #supplierContactNo="ngModel"
                    placeholder="Enter Supplier Contact No" maxlength="10">
                    <div class="error" *ngIf="supplierContactNoValidator && supplierContactNoValidator.error">
                        <span>{{ supplierContactNoValidator.msg }} </span>
                    </div>
            </div>
            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input">{{ "supplier_email" | customTranslate }}</label>
                <input class="form-control" type="text" name="supplierEmail" id="supplierEmail"
                    [(ngModel)]="supplier.supplierEmail" #supplierEmail="ngModel"
                    placeholder="Enter Supplier Email">
                    <div class="error" *ngIf="supplierEmailValidator && supplierEmailValidator.error">
                        <span>{{ supplierEmailValidator.msg }} </span>
                    </div>
            </div>
            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input">{{ "supplier_address" | customTranslate }}</label>
                <input class="form-control" type="text" name="supplierAddress" id="supplierAddress"
                    [(ngModel)]="supplier.supplierAddress" #supplierAddress="ngModel"
                    placeholder="Enter Supplier Address">
                <!-- Add validation error message if needed -->
            </div>
            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input">{{ "supplier_status" | customTranslate }}</label>
                <select name="supplierStatus" id="#supplierStatus" class="form-control"
                        [(ngModel)]="supplier.status">
                <option selected="ACTIVE" value="ACTIVE">{{"active" | customTranslate}}</option>
                <option value="INACTIVE">{{"inactive" | customTranslate}}</option>
                </select>
            </div>
            </div>

            <!-- Action Block -->
            <div class="form-group action-block">
            <div class="btn-block">
                <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    customTranslate}}</button>
                <button class="btn btn-warning" (click)="reset()"> {{ "reset" | customTranslate }} </button>
                <button type="submit" class="btn btn-submit" (click)="formValidator()">
                {{ buttonName | customTranslate }}
                </button>
            </div>
            </div>
        </div>
    </div>
</div>
</div>

        <!-- Suppliers List Section -->
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="header-block">
                  <h3 class="card-title">
                    {{ "suppliers_list" | customTranslate }}
                  </h3>
                </div>
              </div>
              <div class="card-body">
                <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                  <thead>
                    <tr>
                      <th>{{ "s_no" | customTranslate }}</th>
                      <th>{{ "supplier_name" | customTranslate }}</th>
                      <th>{{ "supplier_contact_no" | customTranslate }}</th>
                      <th>{{ "supplier_email" | customTranslate }}</th>
                      <th>{{ "supplier_address" | customTranslate }}</th>
                      <th>{{ "supplier_status" | customTranslate }}</th>
                      <th *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{ "plant_name" | customTranslate }}</th>
                      <th *ngIf="role.user_role=='AXESTRACK'">{{ "company_name" | customTranslate }}</th>
                      <th class="action">{{ "action" | customTranslate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of supplierList; let i = index">
                      <td>{{ i + 1 }}</td>
                      <td>{{ row.supplier_first_name }} {{ row.supplier_last_name }}</td>
                      <td>{{ row.supplier_contact_no }}</td>
                      <td>{{ row.supplier_email }}</td>
                      <td>{{ row.supplier_address }}</td>
                      <td>{{ row.status }}</td>
                      <td *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{ row.plant_name }}</td>
                      <td *ngIf="role.user_role=='AXESTRACK'">{{ row.company_name }}</td>
                      <td class="action">
                        <div class="btn-block">
                          <button class="btn btn-primary" (click)="viewSupplierDetails(row)">
                            {{ "view" | customTranslate }}
                          </button>
                          <button  class="btn btn-success" *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                          || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1))"
                         (click)="editSupplier(row)">{{ "edit" | customTranslate }}
                          </button>
                          <button class="btn btn-danger" *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                          || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1))"
                          (click)="confirmDeleteSupplier(row)"> {{ "delete" | customTranslate }}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div>
                <div class="phl-dabz">
                  <h1 style="color: white;">We are here to Help you.</h1>
                  <p class="text-white">Advice and business from the Team</p>

                  <div class="search_box">
                    <input type="text" placeholder="Search For Menu">
                    <button>Search</button>
                  </div>
                </div>

                <div class="dusra-dabba">

                  <div class="box" *ngFor="let item of menu">
                    <div class="icon">
                      <img src="{{item.img}}" alt="">
                      <i class="fas fa-action1"></i>
                    </div>
                    <div class="box-text" (click)="openHelpSubMenuPage()">
                      <span>{{item.name}}</span>
                      <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

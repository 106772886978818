import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CommonService } from './common.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  position = new Subject();
  _position: any = {};
  private _watchId: any = null;
  constructor(
    private modalService: NgbModal, private common: CommonService,private router: Router) {

    }


  watchUserLocation = () => {
    // console.log("swerf")
    if("geolocation" in navigator){

      // if(this._watthis.common.params = {
    //   title: 'Delete Action ',
    //   description: `<b>&nbsp;` + 'Are you sure, Do you want to delete ? ' + `<b>`,
    // }chId == null){
      //   navigator.geolocation.getCurrentPosition(() => {
      //     this._watchId = navigator.geolocation.watchPosition((position) => {
      //       this._latitude = position.coords.latitude;
      //       this._longitude = position.coords.longitude;
      //     })
      //   })
      // }else{
      if(this._watchId == null){
        this._watchId = navigator.geolocation.watchPosition((position) => {
          this._position = {latitude: position.coords.latitude, longitude: position.coords.longitude}
          this.position.next(this._position);
        }, (err) => {
          // console.log("Position Err " , err)
        }, {
          enableHighAccuracy: true,
          maximumAge: 30000,
          timeout: 2000,
        })

      }
      // }
    }else{
      window.alert("CPL is not supported in your browser");
    }
  }

  checkLocationPermission(){
    if("geolocation" in navigator && navigator.permissions){

  navigator.permissions.query({ name: "geolocation" }).then((result) => {
    // result.addEventListener("change", (event) => {
    //   this.handlePermissionChange(event);
    // });
    // console.log('result: ', result);
    if (result.state !== "granted") {
      // this.common.params = {
      //   title: 'Location Required ',
      //   description: `<b>&nbsp;` + 'Please allow location permisssion in browser setting' + `<b>`,
      //   btn1: 'Allowed'
      // }
      // const activeModal = this.modalService.open(ConfirmationDialogComponent, { size: 'lg' });
      // activeModal.result.then((data: any) => {
      //   if (data.response && (this._position.latitude == null || this._position.longitude == null)) {
      //     this.checkLocationPermission();
      //   }else if(data.response){
      //     const currentUrl = this.router.url;
      //       this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      //         this.router.navigateByUrl(currentUrl);
      //       });
      //   }
      // });
    }
  })
}
  }

  handlePermissionChange(pos){
    // console.log('pos.curcurrentTarget: ', pos.target);
    if(pos.target.state == "granted"){
      this.modalService.dismissAll();
    }
  }

  stopLocationWatch = () => {
    navigator.geolocation.clearWatch(this._watchId);
    this._watchId = null;
  }
}

import * as lodash from 'lodash';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import Chart from 'chart.js';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { TableService } from '../../@core/services/table.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertCardModalComponent } from '../alert-card-modal/alert-card-modal.component';
import { AuditModalComponent } from 'src/app/modals/audit-modal/audit-modal.component';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { PlantLiveViewModalComponent } from '../plant-live-view-modal/plant-live-view-modal.component';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-alert-dashboard',
  templateUrl: './alert-dashboard.component.html',
  styleUrls: ['./alert-dashboard.component.scss'],
})
export class AlertDashboardComponent implements OnInit, AfterViewInit {
  contentData: any[] = [];
  ParentcontentData: any = {};
  activeCardIndex: any = 0;
  activeIndex: number = 0;
  activeColor: string = '';
  darkColor: string = '';
  alertDashboardData = [];
  groupedDataByType: any = [];
  tabCardData: any[] = [];
  contentCardData: any[] = [];
  groupedDataByEntity: any;
  entityData = {};
  tabvalue: any = '';
  filteredValueByCondition = {};
  selectedEntity = '';
  selectedTime: number = 7;
  selectedDay: number = 7;
  myBarChart: any;
  chartCreated: boolean = false;
  plantData: any = [];
  plantId: number = null;
  plantName: string = null;

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'Action Dashboard');
  showStickyDiv: boolean = false;

  @ViewChild('alerts') myDiv: ElementRef;

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    const elementPosition = this.myDiv.nativeElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Check if the element is out of the window by scrolling
    if (elementPosition.bottom < 0 || elementPosition.top > windowHeight) {
      this.showStickyDiv = true;
      // Emit your custom event or perform some action
    } else {
      this.showStickyDiv = false;
    }
  }

  constructor(
    private table: TableService,
    private breadcrumbService: BreadcrumbService,
    private common: CommonService,
    public plantService: PlantService,
    private api: ApiService,
    public role: RoleService,
    public userService: UserService,
    private modalService: NgbModal
  ) {
    breadcrumbService.setActiveBreadCrumb(false);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.getAlertDashboardData();
    }
  }

  setTabValue(tabvalue: any) {
    this.tabvalue = tabvalue;
    setTimeout(() => {
      this.createTableByEntity(tabvalue);
    }, 600);
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getAlertDashboardData(this.plantId);
  }

  getAlertDashboardData(plantId: any = null) {
    this.common.loading = true;
    this.api
      .get('alert/get-alert-dashboard-data', { plantId: plantId })
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.alertDashboardData = res.data;
            this.groupedDataByType = this.getGroupDataByType(
              this.alertDashboardData,
              'type_name'
            );
            this.groupedDataByEntity = this.getGroupDataByType(
              this.alertDashboardData,
              'entity'
            );
            this.tabvalue = Object.keys(this.groupedDataByEntity)[0];
            this.selectedEntity = Object.keys(this.groupedDataByEntity)[0];
            this.getTabCardData();
            setTimeout(() => {
              this.createDoughnutChart();
              this.createBarChart(this.selectedEntity);
              this.createTabLineChart(this.selectedTime);
            }, 600);
            this.createLineChart(this.selectedDay);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getGroupDataByType(data: any, key: any) {
    return lodash.groupBy(data, key);
  }

  getTabCardData() {
    Object.entries(this.groupedDataByType).forEach((el: any) => {
      let tabCard = {
        title: el[0],
        value: el[1].length,
        cardBgColor: this.getLightColor(el[1][0].type_color, 50),
        iconBgColor: el[1][0].type_color,
      };
      this.tabCardData.push(tabCard);
    });
    this.getContentCardData();
  }

  getLightColor(color: string, number: number): string {
    // Convert the color to RGB format
    const rgbColor = this.hexToRgb(color);

    // Calculate the light color by increasing the RGB values
    const lightR = Math.min(rgbColor.r + number, 255);
    const lightG = Math.min(rgbColor.g + number, 255);
    const lightB = Math.min(rgbColor.b + number, 255);

    // Convert the light color back to hexadecimal format
    const lightHex = this.rgbToHex(lightR, lightG, lightB);

    return lightHex;
  }

  hexToRgb(hex: string): { r: number; g: number; b: number } {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }

  rgbToHex(r: number, g: number, b: number): string {
    return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}80`;
  }

  getContentCardData(index = 0) {
    let dataObj = {};
    this.activeIndex = index;
    Object.entries(this.groupedDataByType).forEach((el: any) => {
      let contentCardData = [];
      let typeName = el[0];
      let alerts = el[1];
      alerts.forEach((element) => {
        let contentCard = {
          content:
            typeName +
            ' alert of ' +
            element.alert_condition +
            ' on "' +
            element.ref_title +
            '" ' +
            ' ' +
            element.entity,
          contentCardBg: element.type_color,
        };
        contentCardData.push(contentCard);
      });
      if (!dataObj[typeName]) {
        dataObj[typeName] = [];
      }
      dataObj[typeName] = contentCardData;
    });
    Object.entries(this.groupedDataByType).forEach((data) => {
      this.ParentcontentData[data[0]] = this.getGroupDataByType(
        data[1],
        'entity'
      );
    });
    this.contentData = dataObj[this.tabCardData[index]?.title];
    this.activeColor = this.tabCardData[index]?.cardBgColor;
    this.darkColor = this.tabCardData[index]?.iconBgColor;

    this.activeCardIndex = 0;
    this.getChildContentCardData(
      Object.keys(
        this.sortByKeyAlphabetically(
          this.ParentcontentData[this.tabCardData[index]?.title]
        )
      )[0]
    );
  }

  sortByKeyAlphabetically(obj) {
    const keys = Object.keys(obj);
    keys.sort();

    const sortedObj = {};
    for (const key of keys) {
      sortedObj[key] = obj[key];
    }

    return sortedObj;
  }

  getChildContentCardData(key: any) {
    let typeName = this.tabCardData[this.activeIndex]?.title;
    this.contentData = [];
    this.ParentcontentData[typeName][key].forEach((element: any) => {
      let contentCard = {
        content: element.alert_condition,
        contentCardBg: element.type_color,
        content_title: element.ref_title,
        content_entity: element.entity,
        content_date: element.created_at,
        content_range: element.ranges,
        content_user: element.user_Name,
        content_status: element.status,
        content_data: element,
        content_title_alert: element.alert_title,
        content_process: element.process_name,
        content_data_view:element.alert_data
      };
      this.contentData.push(contentCard);
    });
  }

  getArrayLenght(data: any) {
    return data.length;
  }
  createBarChart(entity: any) {
    // var myBarChart=null;
    this.selectedEntity = entity;
    let filteredDataByEntity = this.groupedDataByEntity[entity];
    let conditionGroupedData = this.getGroupDataByType(
      filteredDataByEntity,
      'alert_condition'
    );
    let typeWiseData = this.getGroupDataByType(
      filteredDataByEntity,
      'type_name'
    );
    let dataSets = [];
    Object.entries(typeWiseData).forEach((el: any) => {
      let typename = el[0];
      let tempData = el[1];
      let groupByCondition = this.getGroupDataByType(
        tempData,
        'alert_condition'
      );
      let values = [];
      Object.keys(conditionGroupedData).forEach((key: any) => {
        values.push(groupByCondition[key] ? groupByCondition[key].length : 0);
      });
      dataSets.push({
        label: typename,
        data: values,
        backgroundColor: this.getLightColor(tempData[0].type_color, 50),
        borderColor: tempData[0].type_color,
        borderWidth: 1,
        barPercentage: 0.5,
        minBarLength: 2,
      });
    });

    const barChartId = `barChart-${entity}`;

    if (this.chartCreated) {
      this.myBarChart.destroy();
    }
    setTimeout(() => {
      this.myBarChart = new Chart(barChartId, {
        type: 'bar',
        data: {
          labels: Object.keys(conditionGroupedData),
          datasets: dataSets,
        },
        options: {
          maintainAspectRatio:true,
          animation: {
            duration: 1000,
            easing: 'linear',
            animateRotate: true,
            animateScale: true,
          },
          responsive: true,
          plugins: {
            legend: {
              display: false,
              position: 'right',
              labels: {
                padding: 10,
                textAlign: 'right',
                font: {
                  size: 12,
                },
                borderRadius: 4,
              },
            },
          },
        },
      });
    });
    this.chartCreated = true;
  }

  createDoughnutChart() {
    Object.entries(this.groupedDataByEntity).forEach((el: any, index) => {
      const canvasId = `chart-${el[0]}`;
      const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
      const ctx = canvas.getContext('2d');
      let dataSets = [];
      let alertTypes = [];
      let tempData = el[1];
      let groupByType = this.getGroupDataByType(tempData, 'type_name');
      let values = [];
      Object.keys(groupByType).forEach((key: any) => {
        values.push(groupByType[key] ? groupByType[key].length : 0);
        alertTypes.push(key);
      });
      let groupByTypeColor = this.getGroupDataByType(tempData, 'type_color');
      let colors = [];
      let borderColors = [];
      Object.keys(groupByTypeColor).forEach((key: any) => {
        colors.push(this.getLightColor(key, 50));
        // colors.push(key);
        borderColors.push(key);
      });

      dataSets.push({
        label: '',
        data: values,
        backgroundColor: colors,
        borderColor: borderColors,
        borderWidth: 1,
      });

      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: alertTypes,
          datasets: dataSets,
        },
        options: {
          responsive: true,
          plugins: {
            font: {
              weight: 'bold',
              size: 12,
            },
            legend: {
              display: false,
              position: 'right',
              labels: {
                padding: 20,
                textAlign: 'right',
                font: {
                  size: 10,
                },
                boxWidth: 12,
                boxPadding: 6,
                borderRadius: 4,
              },
            },
          },
        },
      });
    });
    this.createTableByEntity(this.tabvalue);
  }

  createTabLineChart(timeDuration: any) {
    // creating labels for chart
    let hoursLabels = [];
    let dataSets = [];
    const currentTime = new Date();
    const startTime7Hrs = new Date(currentTime.getTime() - 7 * 60 * 60 * 1000); // 7 hours in milliseconds
    const startTime24Hrs = new Date(
      currentTime.getTime() - 24 * 60 * 60 * 1000
    );

    if (timeDuration == 7) {
      for (let i = 1; i <= 7; i++) {
        const hour = (startTime7Hrs.getHours() + i) % 24;
        const nextHour = (hour + 1) % 24;
        const label = `${hour.toString().padStart(2, '0')}:00 - ${nextHour
          .toString()
          .padStart(2, '0')}:00`;
        hoursLabels.push(label);
      }
    } else {
      for (let i = 1; i <= 24; i++) {
        const hour = (startTime24Hrs.getHours() + i) % 24;
        const nextHour = (hour + 1) % 24;
        // const formattedHour = hour.toString().padStart(2, '0');
        // const formattedNextHour = nextHour.toString().padStart(2, '0');
        const label = `${hour.toString().padStart(2, '0')}:00 - ${nextHour
          .toString()
          .padStart(2, '0')}:00`;
        hoursLabels.push(label);
      }
    }

    //creating dataset for chart
    let tempDataByEntity = this.alertDashboardData;

    //filtering data for 7 hours

    const currentDateTime = new Date();
    const sevenHoursAgo = new Date(
      currentDateTime.getTime() - 7 * 60 * 60 * 1000
    );
    const twentyFourHrsAgo = new Date(
      currentDateTime.getTime() - 24 * 60 * 60 * 1000
    );

    const filteredData = tempDataByEntity.filter((item) => {
      const createdAtParts = item.created_at.split(' ');
      const createdAtTime = createdAtParts[1];
      const createdAtHours = parseInt(createdAtTime.split(':')[0], 10);

      const createdAtDateParts = createdAtParts[0].split('-');
      const createdAtDay = parseInt(createdAtDateParts[0], 10);
      const createdAtMonth = parseInt(createdAtDateParts[1], 10);
      const createdAtYear = parseInt(createdAtDateParts[2], 10);
      const createdAtDateTime = new Date(
        createdAtYear,
        createdAtMonth - 1,
        createdAtDay,
        createdAtHours,
        0,
        0
      );
      if (timeDuration == 7) {
        return (
          createdAtDateTime >= sevenHoursAgo &&
          createdAtDateTime <= currentDateTime
        );
      } else {
        return (
          createdAtDateTime >= twentyFourHrsAgo &&
          createdAtDateTime <= currentDateTime
        );
      }
    });

    let filteredDataByType = this.getGroupDataByType(filteredData, 'type_name');

    Object.entries(filteredDataByType).forEach((el: any) => {
      let typename = el[0];
      let tempData = el[1];
      let groupByCreatedAt = this.getGroupDataByType(tempData, 'created_at');
      let rangeObject = {};
      hoursLabels.forEach((element) => {
        rangeObject[element] = [];
      });

      let values: number[] = [];
      Object.keys(groupByCreatedAt).forEach((key) => {
        // finding time range where alert belongs
        let rangeOfKey = this.getRangeByKey(key, hoursLabels);
        if (rangeOfKey != null) {
          rangeObject[rangeOfKey].push(...groupByCreatedAt[key]);
        }
      });

      Object.keys(rangeObject).forEach((key) => {
        values.push(rangeObject[key].length ? rangeObject[key].length : 0);
        // values.push(100);
      });

      dataSets.push({
        label: typename,
        data: values,
        backgroundColor: tempData[0].type_color,
        borderColor: tempData[0].type_color,
        pointBorderColor: tempData[0].type_color,
        pointBackgroundColor: tempData[0].type_color,
        pointBorderWidth: 5,
        borderWidth: 2,
        fill: false,
      });
    });

    const lineChartId = `tabLineChart`;
    setTimeout(() => {
      var myLineChart = new Chart(lineChartId, {
        type: 'line',

        data: {
          labels: hoursLabels,
          datasets: dataSets,
        },
        options: {
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0, // it is for ignoring negative step.
                  beginAtZero: true,
                  stepSize: 1,
                },
              },
            ],
          },
          plugins: {
            legend: {
              display: false,
              position: 'right',
              labels: {
                padding: 20,
                textAlign: 'right',
                font: {
                  size: 10,
                },
                boxWidth: 12,
                boxPadding: 6,
                borderRadius: 4,
              },
            },
          },
        },
      });
    });
  }

  getRangeByKey(key: any, ranges: any) {
    let range: any = null;
    const [keyhrs, keyminutes] = key.split(' ')[1].split(':');
    let keyTimeInSeconds = keyhrs * 3600 + keyminutes * 60;
    ranges.forEach((el) => {
      const [lowerTime, UpperTime] = el.split('-');
      const [lowerHrs, lowerMinutes] = lowerTime.split(':');
      const [upperHrs, upperMinutes] = UpperTime.split(':');
      let lowerTimeInSeconds = lowerHrs * 3600 + lowerMinutes * 60;
      let UpperTimeInSeconds = upperHrs * 3600 + upperMinutes * 60;
      if (
        lowerTimeInSeconds <= keyTimeInSeconds &&
        keyTimeInSeconds <= UpperTimeInSeconds
      ) {
        range = el;
      }
    });
    return range;
  }

  createLineChart(day: any) {
    //genarating labels
    let dataSets = [];
    const currentDate = new Date(); // Get the current date
    const dateLabels = [];
    if (day == 7) {
      for (let i = 7; i >= 0; i--) {
        const date = new Date(currentDate);
        date.setDate(date.getDate() - i);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const label = `${day}-${month}-${year}`;
        dateLabels.push(label);
      }
    } else {
      for (let i = 30; i >= 0; i--) {
        const date = new Date(currentDate);
        date.setDate(date.getDate() - i);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const label = `${day}-${month}-${year}`;
        dateLabels.push(label);
      }
    }

    //generating datsets
    const currentDateForData = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const startDate = new Date(currentDateForData);

    if (day == 7) {
      startDate.setDate(startDate.getDate() - 7);
    } else {
      startDate.setDate(startDate.getDate() - 30);
    }

    const endDate = new Date(currentDate);
    const filteredData = this.alertDashboardData.filter((item) => {
      const dateParts = item.created_at.split(' ')[0].split('-');
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const year = dateParts[2];
      const itemDate = new Date(year, month - 1, day);

      return itemDate >= startDate && itemDate <= endDate;
    });
    let filteredDataByType = this.getGroupDataByType(filteredData, 'type_name');

    Object.entries(filteredDataByType).forEach((element) => {
      let typeName = element[0];
      let tempData = element[1];
      let groupedData = {};
      dateLabels.forEach((el) => {
        // el = el.substring(0, 6) + el.substring(8, el.length);
        groupedData[el] = [];
      });
      tempData.forEach((item) => {
        let createdDate = item['created_at'].split(' ')[0];
        // createdDate = createdDate.replaceAll(':', '-');
        if (!groupedData.hasOwnProperty(createdDate)) {
          groupedData[createdDate] = [];
        }
        groupedData[createdDate].push(item);
      });

      let values = [];
      Object.keys(groupedData).forEach((key) => {
        values.push(groupedData[key].length ? groupedData[key].length : 0);
      });

      dataSets.push({
        label: typeName,
        data: values,
        backgroundColor: tempData[0]['type_color'],
        borderColor: tempData[0]['type_color'],
        pointBorderColor: tempData[0]['type_color'],
        pointBackgroundColor: tempData[0]['type_color'],
        pointBorderWidth: 5,
        borderWidth: 2,
        fill: false,
      });
    });

    var myLineChart = new Chart('myLineChart', {
      type: 'line',
      data: {
        labels: dateLabels,
        datasets: dataSets,
      },
      options: {
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0, // it is for ignoring negative step.
                beginAtZero: true,
                stepSize: 1,
              },
            },
          ],
        },
        plugins: {
          legend: {
            display: false,
            position: 'right',
            labels: {
              padding: 20,
              textAlign: 'right',
              font: {
                size: 10,
              },
              boxWidth: 12,
              boxPadding: 6,
              borderRadius: 4,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  }

  getObjectLength(obj: any): number {
    return Object.keys(obj).length;
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  isObjectEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  createTableByEntity(tabName: any) {
    let tempData = this.groupedDataByEntity[tabName];
    let headers = [];
    let values = [];
    let head = {
      title: tabName + ' Name',
      dataKey: 'entity',
    };
    headers.push(head);
    let groupByType = this.getGroupDataByType(tempData, 'type_name');
    Object.keys(groupByType).forEach((key: any) => {
      let result = null;
      this.tabCardData.forEach((item) => {
        if (item.title === key) {
          result = {
            cardBgColor: item.cardBgColor,
            iconBgColor: item.iconBgColor,
          };
          return;
        }
      });
      let head = {
        title: key,
        dataKey: 'type_name',
        darkColor: result.iconBgColor,
        lightColor: result.cardBgColor,
      };
      headers.push(head);
    });
    let groupByRefTitle = this.getGroupDataByType(tempData, 'ref_title');

    Object.keys(groupByRefTitle).forEach((key: any) => {
      let groupByRefTitleData = groupByRefTitle[key];
      let typedGroup = this.getGroupDataByType(
        groupByRefTitleData,
        'type_name'
      );
      let conditionedGroup = this.getGroupDataByType(
        groupByRefTitleData,
        'alert_condition'
      );
      let tempArray = {
        type_name: typedGroup,
        alert_condition: conditionedGroup,
      };
      let value = {
        isExpand: false,
      };
      headers.forEach((element, index) => {
        if (index == 0) {
          value[element.title] = key;
        } else {
          value[element.title] = tempArray[element.dataKey][element.title]
            ? tempArray[element.dataKey][element.title]
            : [];
        }
      });
      values.push(value);
    });
    this.entityData['headers'] = headers;
    this.entityData['values'] = values;
  }

  getColor(key: string, value: string) {
    let result = null;
    this.tabCardData.forEach((item) => {
      if (item.title === key) {
        result = item[value];
        return;
      }
    });
    return result;
  }

  setSelectedEntity(entity: any) {
    this.selectedEntity = entity;
    this.createBarChart(this.selectedEntity);
  }

  setSelectedTime(timeDuration: any) {
    this.selectedTime = timeDuration;
    this.createTabLineChart(this.selectedTime);
  }

  setSelectedDay(day: any) {
    this.selectedDay = day;
    this.createLineChart(this.selectedDay);
  }

  createExpandTableData(value: any) {
    this.filteredValueByCondition = {};
    let filteredvalue = {};
    Object.keys(value).forEach((key: any) => {
      if (this.isArray(value[key])) {
        filteredvalue[key] = value[key];
      }
    });
    // let filteredValueByCondition={};
    Object.keys(filteredvalue).forEach((key: any) => {
      let filteredvalueData = filteredvalue[key];
      this.filteredValueByCondition[key] = this.getGroupDataByType(
        filteredvalueData,
        'alert_condition'
      );
    });
  }

  getAlertCardDetails(typeName, alertData) {
    const activeModal = this.modalService.open(AlertCardModalComponent, {
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      alert_type_name: typeName,
      alert_data: alertData,
      showAlertData : false
    };
  }

  tripModal(vehicle) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.ref_id,
    };
  }

  tripTrackingModal(vehicle) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.ref_id,
      vehicleName: vehicle.ref_title + '-' + vehicle.process_name,
    };
  }

  viewPlantLiveView(viewData: any) {
    let activeModal = this.modalService.open(PlantLiveViewModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.modalProcessName = viewData;
  }

  viewDetails(row: any) {
    const lastIndex = row.ref_title.lastIndexOf('-');

    const vehicleNo = row.ref_title.substring(0, lastIndex);
    const actionName = row.ref_title.substring(lastIndex + 1);
    let data = {
      process: row.process_name,
      stage: row.stage_name,
      vehicleNumber: vehicleNo,
      actionName: actionName,
      param: {
        actionId: row.action_id,
        tripId: row.ipa_transaction_id,
      },
    };
    this.common.params = data;
    const activeModal = this.modalService.open(AuditModalComponent, {
      size: 'xl',
      backdrop: 'static',
      windowClass: 'audit-modal',
    });
    activeModal.componentInstance.refData = {
      id: row.id,
      status: row.status,
      displayBtn: false,
    };
  }

  scrollToAlert() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  viewAlert(alertData) {
    let data = JSON.parse(alertData);
    const activeModal = this.modalService.open(AlertCardModalComponent, {
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      alert_type_name: data.title,
      alert_remark:data.remark,
      alert_data: data.data,
      showAlertData : true
    };
  }
}

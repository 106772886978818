<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{"Hardware_Status_Change" | translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="vehicle_form_box">
    <ng-container class="col-md-6 modal_col">
      <div class="field">
        <label for="sel1" class="required">{{"Hardware Status" | translate}}:</label>
        <select class="form-control" name="status" [(ngModel)]="vehicle_status">
          <option *ngFor="let opt of allStatus" [selected]="opt['value']==vehicle_status">{{opt['label']}}</option>
        </select>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" outline (click)="changeStatus()" id="submit"
    style="cursor: pointer;">{{'save'|translate}}</button>
  <button class="btn btn-danger" type="button" outline (click)="closeModal()" id="cancel"
    style="cursor: pointer;">{{'Cancel'|translate}}</button>
</div>

<div class="card" [class]="className">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{title}}
      </h3>
    </div>

    <div class="form-group search_filter">
      <input type="search" class="form-control searchbar" placeholder="Search" name="" id="" #searchvalue
        (keyup)="filter(searchvalue)">
    </div>
  </div>
  <div class="card-body">
    <div class="table-container" [ngClass]="values.length ? 'datatable':'no-data-table'">
      <table class="custom-table" *ngIf="values.length" datatable [dtOptions]="dtOptions">
        <thead>
          <tr *ngFor="let item of keys;index as i">
            <th *ngFor="let head of keys[i]" [style.width.px]="tbodywidth / item.length"> {{head}} </th>
          </tr>
        </thead>
        <!-- <tbody *ngIf="values.length > 0" [style.height.px]="tbodyheight"> -->
               <tbody *ngIf="values.length > 0">
          <tr *ngFor="let item of values;index as i">
            <td *ngFor="let val of values[i]" [style.width.px]="tbodywidth / item.length"> {{val}} </td>
          </tr>
        </tbody>
      </table>
      <app-data-not-found *ngIf="!values.length"></app-data-not-found>
    </div>
  </div>
  <div mat-dialog-actions class="modal-action">
    <button mat-button mat-dialog-close class="close-btn">Close</button>
  </div>
</div>
<div>
</div>

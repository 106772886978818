<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                 {{'Checklist'|translate}}
                </h3>
              </div>
            </div>

            <div class="card-body" style="background: #f0f0f0;">
              <div class="checklist_page">
                <div class="border_class">
                  <div class="page_name">
                    <h3>{{checkListTitle}}</h3>
                    <p *ngIf="checklist_description!=null">{{checklist_description}}</p>
                  </div>

                  <div class="field_sec" *ngFor="let data of actionsAndFields;let ind = index">
                    <div class="field_title">
                      {{data.action_name}}
                      <span style="float: right;">
                      <i *ngIf="data.is_mandatory==1" style="color: #28a745;" class="fa fa-check-circle"></i>
                      <i *ngIf="data.is_mandatory==0" style="color: black;" class="fa fa-times-circle"></i>
                      {{'Mandatory'}}
                    </span>
                    </div>

                    <div class="field_content" *ngIf="data.action_operation_type=='form'">
                      <ng-container *ngFor="let field of data.fields ; index as i">
                        <div class="all-fields">

                          <div class="field-label"> {{ field.field_name }} <span class="mandatory-indicator" *ngIf="field.is_mandatory == 1">*</span></div>
                          <div class="modal_col" *ngIf="field.field_type!='LABEL'">
                            <div class="form-group" *ngIf="field.field_type=='TEXTFIELD' && (field.database_linked_id==null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="text" class="form-control" (change)="enterValues($event , field , i,ind)">
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='TEXTFIELD' && (field.database_linked_id!=null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="text" class="form-control"  [value]="field.sel_value" disabled>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='NUMBERFIELD'&& (field.database_linked_id==null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="number" class="form-control" (change)="enterValues($event , field , i,ind)">
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='NUMBERFIELD'&& ( field.database_linked_id!=null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="number" class="form-control"  [value]="field.sel_value" disabled>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='TIME'&& (field.database_linked_id==null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="time" class="form-control"  (change)="enterValues($event , field , i,ind)">
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='TIME'&& (field.database_linked_id!=null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="time" class="form-control" [value]="field.sel_value" disabled>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='URL'&& ( field.database_linked_id==null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="url" class="form-control"  (change)="enterValues($event , field , i,ind)">
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='URL'&& ( field.database_linked_id!=null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="url" class="form-control" [value]="field.sel_value" disabled>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='DATETIMEPICKER'&& (field.database_linked_id==null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="datetime-local" class="form-control"  (change)="enterValues($event , field , i,ind)">
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='DATETIMEPICKER'&& ( field.database_linked_id!=null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="datetime-local" class="form-control" [value]="field.sel_value" disabled>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='FILE'&& ( field.database_linked_id==null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="file" accept=".jpg,.png,.jpeg,.pdf,.xlsx,.xlsm,.xls"  class="field_class file_class" multiple (change)="enterValues($event , field , i,ind)" >
                          </div>

                          <div class="form-group" *ngIf="(field.field_type=='RFIDCODEWITHIMAGE'||field.field_type=='BARCODEWITHIMAGE'||field.field_type=='GEOCODEDIMAGE')&& ( field.database_linked_id==null)">
                            <!-- <label>{{field.field_name}}</label> -->
                            <app-camera-component [retake]="retakeImage" (getPicture)="handleImage($event, field,i,ind)">
                            </app-camera-component>
                            <div style="display: flex; flex-direction: column; align-items:center;"
                            *ngIf="this.formValuesList[ind].formValues!=null && this.formValuesList[ind].formValues.length!=0 && this.formValuesList[ind].formValues[i]!=null && this.formValuesList[ind].formValues[i].values.length">
                            <p>Upload Done! <button class="btn btn-warning"
                                (click)="retakeImage = !retakeImage;this.formValuesList[ind].formValues[i].values=[];">Retake</button>
                            </p>
                          </div>
                        </div>

                          <div class="form-group" *ngIf="(field.field_type=='FILE'||field.field_type=='RFIDCODE'||field.field_type=='RFIDCODEWITHIMAGE'||field.field_type=='BARCODEWITHIMAGE'||field.field_type=='BARCODE'||field.field_type=='GEOCODEDIMAGE')&& ( field.database_linked_id!=null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              {{imageName(field.sel_value)}}
                              <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                              <img [src]="image_url" alt="" style="width: 30px;"><span style="font-size:small">{{" "+this.image_name}}</span>
                              </ng-container>
                              <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
                                  <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                                  text-decoration-line: underline;" (click)="newTab(image_url)">{{" "+this.image_name}}</span>
                              </ng-container>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='TEXTAREA'&& ( field.database_linked_id==null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <textarea rows="3" cols="50"  (change)="enterValues($event , field , i,ind)" class="form-control"></textarea>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='TEXTAREA'&& (field.database_linked_id!=null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <textarea rows="3" cols="50" [value]="field.sel_value" disabled class="form-control"></textarea>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='DATEPICKER'&& ( field.database_linked_id==null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="date" class="form-control"  (change)="enterValues($event , field , i,ind)">
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='DATEPICKER'&& (field.database_linked_id!=null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <input type="date" class="form-control" [value]="field.sel_value" disabled>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='RADIOBUTTON'&& ( field.database_linked_id==null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <div *ngFor="let opt of field.options;" >
                                  <input type="radio" name="option" value="{{opt.name}}" (change)="enterValues($event , field , i,ind)">&nbsp;{{opt.name}}
                              </div>
                          </div>

                          <div class="form-group"
                              *ngIf="field.field_type=='RADIOBUTTON'&& (field.database_linked_id!=null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <div *ngFor="let opt of field.options" >
                                  <input type="radio" name="option" [checked]="field.sel_value == opt.name" disabled>&nbsp;{{opt.name}}
                              </div>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='CHECKBOX'&& ( field.database_linked_id==null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <div *ngFor="let opt of field.options" >
                                  <input type="checkbox" value="{{opt.name}}" [name]="'field'+i"  (change)="enterValues($event  , field , i,ind)">&nbsp;{{opt.name}}
                              </div>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='CHECKBOX'&& ( field.database_linked_id!=null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <div *ngFor="let opt of field.options" >
                                  <input type="checkbox" [checked]="field.sel_value == opt.name" disabled>&nbsp;{{opt.name}}
                              </div>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='SIGNATURE'&& (field.database_linked_id==null)" (mouseleave)="saveSignature(i,ind,field)">
                                <app-signature (getSignature)="getSignature=$event"></app-signature>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='SIGNATURE'&& (field.database_linked_id!=null)">
                                {{imageName(field.sel_value)}}
                                <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
                                    <img [src]="field.sel_value" alt="" style="width: 30px;">
                                </ng-container>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='SELECTLISTSINGLE'&& ( field.database_linked_id==null)" >
                              <!-- <label>{{field.field_name}}</label> -->
                              <ps-auto-suggestion (onSelected)="enterValues($event, field, i,ind)" [data]="field.options"
                              [display]="field.label" placeholder="Choose option" >
                          </ps-auto-suggestion>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='SELECTLISTSINGLE'&& ( field.database_linked_id!=null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <select class="form-control" *ngIf="field.sel_value!=null" disabled>
                                  <option *ngFor="let opt of field.options" [selected]="opt[field.store_value]==field.sel_value" [value]="field.sel_value">{{opt[field.label]}}</option>
                              </select>
                              <select class="form-control" *ngIf="field.sel_value==null" [(ngModel)]="selectedOption" (change)="handleSingleSelectDBField(selectedOption, field, i, ind)">
                                <option></option>
                                <option *ngFor="let opt of field.options" [value]="opt[field.store_value]">{{opt[field.label]}}</option>
                            </select>
                          </div>

                          <div class="form-group" *ngIf="field.field_type=='SELECTLISTMULTIPLE'&& ( field.database_linked_id==null)">
                              <!-- <label>{{field.field_name}}</label> -->
                              <ps-auto-suggestion isMultiSelect="true" (onSelected)="enterValues($event, field, i,ind)" [data]="field.options"
                                  [display]="field.label" placeholder="Choose option" >
                              </ps-auto-suggestion>

                          </div>
                          <div class="form-group" *ngIf="field.field_type=='SELECTLISTMULTIPLE'&& ( field.database_linked_id!=null)">

                              <!-- <label>{{field.field_name}}</label> -->

                              <ps-auto-suggestion isMultiSelect="true" (onSelected)="enterValues($event, field, i,ind)" [data]="field.multiValueOptions"
                              [display]="field.label" placeholder="Choose option"  >
                          </ps-auto-suggestion>

                          </div>
                          </div>
                          <div class="modal_col" *ngIf="field.field_type=='LABEL'">
                            {{insertLabel(field, i,ind)}}
                            <div class="form-group">
                                <!-- <label>{{field.field_name}}</label> -->
                                <p style="font-size: small" [innerHTML]="field.options[0].name"></p>
                            </div>
                          </div>
                        </div>
                    </ng-container>
                    </div>
                    <div class="field_content" *ngIf="data.action_operation_type=='boolean'">
                      <div class="form-group">
                        <input type="radio" [checked]="this.formValuesList[ind].formValues!=null && this.formValuesList[ind].formValues.length!=0 &&  this.formValuesList[ind].formValues[0].values.length && this.formValuesList[ind].formValues[0].values[0]=='PASS'"  (change)="enterBooleanValue('PASS',ind)"> <span>Yes</span>
                        <input type="radio" class="ml-5"  [checked]="this.formValuesList[ind].formValues!=null && this.formValuesList[ind].formValues.length!=0 &&  this.formValuesList[ind].formValues[0].values.length && this.formValuesList[ind].formValues[0].values[0]=='FAIL'" (change)="enterBooleanValue('FAIL' ,ind)"> <span>No</span>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="actionsAndFields.length" >
                    <p class="sign_title">Draw your signature into the box below.</p>
                    <app-signature (getSignature)="onuploadSignature($event)"></app-signature>
                </div>

                  <div class="button_div text-right" *ngIf="actionsAndFields.length">
                    <!-- [disabled]="this.disableApprove" -->
                    <button class="btn btn-success" (click)="formValidator('PASS')" >{{'Submit'|translate}}</button>
                    <button class="btn btn-danger ml-2"(click)="openRemarkModal()" >{{'Decline'|translate}}</button>
                  </div>


                  <div *ngIf="!actionsAndFields.length" class="no_action_found">
                    <img src="assets/images/no-action-found.gif" alt="" class="norecord" alt=""style="height: 105px;
                    width: 105px;">
                    <p>{{alertMsg}}</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{checkListTitle}} Checklist Fail Remark</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="message-text" class="col-form-label">Message:</label>
            <textarea class="form-control" id="message-text" type="text" [(ngModel)]="commentMessage" name="message-text" #name="ngModel" placeholder="Enter Remark"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="addCommentForTrip()">Skip</button>
        <button type="button" class="btn btn-primary" (click)="addCommentForTrip()" data-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>

import { ApiService } from 'src/app/@core/services/api.service';
import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { StageDestinationCodeOccupancyService } from '../stage-destination-code-occupancy/stage-destination-code-occupancy.service';
import { StageDestinationCodeService } from '../stage-destination-code/stage-destination-code.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-stageDestinationCodeOccupancy',
  templateUrl: './stage-destination-code-occupancy.component.html',
  styleUrls: ['./stage-destination-code-occupancy.component.scss'],
})
export class StageDestinationCodeOccupancyComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(
    10,
    4,
    'Stage Destination Code Occupancy List'
  );
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAllOccupancy();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  stageDestinationCodeOccupancy = {
    stageDestinationCodeOccupancyId: '',
    stageDestinationCodeOccupancyStatus: 'FREE',
    stageDestinationCode: '',
    stageOccupancyId: '',
  };
  buttonName = 'save';
  title = 'Add Stage Destination Code Occupancy';
  submitted = false;
  responseMessage = '';
  processName: any = '';
  destinationName = '';
  process: [];
  responseData: any[] = [];
  occupancyData: any[] = [];
  stageDestinationCodeOccupancyList: any[] = [];
  stageDestinationCodeList: any[] = [];
  stageList: any[] = [];
  filtered_destination: any[] = [];
  stage_Process_Order_Id: null;
  stage_destination_code_id: null;
  orderId: any = null;

  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    private stageDestinationCodeOccupancyService: StageDestinationCodeOccupancyService,
    private stageDestinationCodeService: StageDestinationCodeService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public alert: AlertService,
    public userService: UserService,
    public api: ApiService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Destinations', url: null },
      {
        name: 'Destination Occupancy Management',
        link: '/user/stage_destination_code_occupancy_list',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getAllProcess();
    this.getAllOccupancy();
  }
  // Save Work Type
  saveStageDestinationCodeOccupancy(): void {
    let data = {
      codeOccupancyId: this.stageDestinationCodeOccupancy.stageOccupancyId,
      destinationId:
        this.stageDestinationCodeOccupancy.stageDestinationCodeOccupancyId,
      status:
        this.stageDestinationCodeOccupancy.stageDestinationCodeOccupancyStatus,
      orderId: this.orderId,
    };
    this.stageDestinationCodeOccupancyService
      .saveStageDestinationCodeOccupancy(data)
      .subscribe(
        (response) => {
          this.alert.success(response.message);
          this.submitted = true;
          this.resetStageDestinationCodeOccupancy();
        },
        (error) => {}
      );
  }

  selectedProcess(event: any) {
    this.processName = event.order_name;
    if (event.order_id != null) {
      let Id = event.order_id;
      this.orderId = event.order_id;
      this.getDestinationsByOrderId(Id);
    } else {
      this.filtered_destination = null;
    }
  }
  getDestinationsByOrderId(Id: any) {
    this.common.loading = true;
    this.api
      .get('destination/get_destination_codes_by_order_id?OrderId=' + Id)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.filtered_destination = res.data;
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  selectedDestination(event: any) {
    this.stageDestinationCodeOccupancy.stageDestinationCodeOccupancyId =
      event.destination_id;
    this.stageDestinationCodeOccupancy.stageDestinationCode =
      event.stage_destination_code;
  }

  getAllProcess() {
    this.common.loading = true;
    this.stageDestinationCodeService.getAllProcess().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.process = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllOccupancy() {
    this.common.loading = true;
    this.stageDestinationCodeOccupancyService.getAllOccupancy().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.occupancyData = res.data;
        if (res.data) {
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  viewStageDestinationCodeOccupancy(row?: any) {
    let obj = {
      process_name: row.process_name,
      stage_name: row.stage_name,
      code_name: row.code_name,
      status: row.status,
    };
    this.common.params = {
      details: [obj],
      title: 'StageDestinationCodeOccupancy Details',
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  editStageDestinationCodeOccupancy(row?: any) {
    //Todo AsyncAwait
    this.getDestinationsByOrderId(row.order_id);
    this.common.gotoTop();
    this.resetStageDestinationCodeOccupancy();
    this.stageDestinationCodeOccupancy.stageOccupancyId = row.id;
    setTimeout(() => {
      this.processName = row.process_name + '-> ' + row.stage_name;
      // this.filtered_destination =  this.stageDestinationCodeList.filter(person => person.stage_process_order_id === row.order_id);
      this.destinationName = row.code_name;
    }, 2000);

    this.stageDestinationCodeOccupancy.stageDestinationCodeOccupancyStatus =
      row.status;
    this.stageDestinationCodeOccupancy.stageDestinationCodeOccupancyId =
      row.code_id;
    this.stageDestinationCodeOccupancy.stageDestinationCode = row.code_name;
    this.orderId = row.order_id;
    this.buttonName = 'update';
    this.title = 'Edit Stage Destination Code Occupancy';
  }

  resetStageDestinationCodeOccupancy() {
    this.stageDestinationCodeOccupancy = {
      stageDestinationCodeOccupancyId: '',
      stageDestinationCodeOccupancyStatus: 'FREE',
      stageDestinationCode: '',
      stageOccupancyId: '',
    };
    this.processName = '';
    // this.filtered_destination=[];
    this.processValidator = null;
    this.destinationValidator = null;
    this.destinationName = '';
    this.statusValidator = null;
    this.buttonName = 'save';
    this.orderId = null;
    this.title = 'Add Stage Destination Code Occupancy';
    this.getAllOccupancy();
    this.getAllProcess();
  }

  processValidator: any;
  destinationValidator: any;
  statusValidator: any;
  formValidator() {
    this.processValidator = this.common.nullValidator(
      this.processName,
      ' process Mandatory'
    );
    this.destinationValidator = this.common.nullValidator(
      this.stageDestinationCodeOccupancy.stageDestinationCode,
      'Stage Destination Code Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.stageDestinationCodeOccupancy.stageDestinationCodeOccupancyStatus,
      'Destination Code Occupancy Status Mandatory'
    );
    if (
      !this.processValidator.error &&
      !this.statusValidator.error &&
      !this.destinationValidator.error
    ) {
      this.saveStageDestinationCodeOccupancy();
    }
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card" *ngIf="userService.permissionMap.get('add_shift_allocation_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"user" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedUser($event)" [data]="data" display="name"
                    placeholder="Choose User" [preSelected]="{name:userName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="userValidator && userValidator.error">
                    <span>{{userValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="appt" class="required">{{"select_shift start date" | customTranslate}}</label>
                  <input type="date" class="form-control" [min]="minStartDate" name="shiftStartTime"
                    [(ngModel)]="shift.shiftStartTime">
                  <div class="error" *ngIf="shiftStartTimeValidator && shiftStartTimeValidator.error">
                    <span>{{shiftStartTimeValidator.msg}} </span>
                  </div>
                  <!-- </form> -->

                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="appt" class="required">{{"select_shift end date" | customTranslate}}</label>
                  <input type="date" class="form-control" name="shiftEndTime" *ngIf="shift.shiftStartTime!=null"
                    [(ngModel)]="shift.shiftEndTime" [min]="shift.shiftStartTime">
                  <input type="date" class="form-control" name="shiftEndTime" *ngIf="shift.shiftStartTime==null"
                    [(ngModel)]="shift.shiftEndTime" [min]="shift.shiftStartTime" disabled>
                  <div class="error" *ngIf="shiftEndTimeValidator && shiftEndTimeValidator.error">
                    <span>{{shiftEndTimeValidator.msg}} </span>
                  </div>
                  <!-- </form> -->

                </div>

                <div class="form-group col-xl-12 col-lg-12 col-sm-12">
                  <div class="field-selection">
                    <label for="my-input" class="required">{{"select_fields" | customTranslate}} </label>
                    <div *ngFor="let value of masterField.values; let i=index" class="mb-2">
                      <div class="row">
                        <div class="form-group col-xl-2 col-lg-6 col-sm-12" *ngIf="roleService.user_role == 'AXESTRACK'">
                          <ps-auto-suggestion (onSelected)="selectedCompany($event,i)" [data]="company" display="companyName"
                            placeholder="Choose Company">
                          </ps-auto-suggestion>
                        </div>

                        <div class="col-md-2" *ngIf="roleService.user_role == 'COMPANYADMIN' || roleService.user_role == 'AXESTRACK'">
                          <ps-auto-suggestion (onSelected)="onSelectPlant($event,i)" [data]="roleService.user_role == 'AXESTRACK' ? selectedPlant[i].plantList : plantList"
                            display="plantName" placeholder="Choose Plant"
                            [preSelected]="{plantName:selectedPlant[i].plantName}">
                          </ps-auto-suggestion>

                        </div>


                        <div class="col-md-3">
                          <ps-auto-suggestion (onSelected)="selectedProcess($event,i)"
                            [data]="roleService.user_role == 'COMPANYADMIN' || roleService.user_role == 'AXESTRACK' ? processStages[i] : processStages[0]"
                            display="order_name" placeholder="Choose Process Stage"
                            [preSelected]="{order_name:masterField.values[i].processName}">
                          </ps-auto-suggestion>
                          <div class="error" *ngIf="processStageValidator[i] && processStageValidator[i].error">
                            <span>{{ processStageValidator[i]?.msg }}</span>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <ps-auto-suggestion (onSelected)="selectedShift($event,i)" [data]="processWiseShifts[i]"
                            display="shift_name" placeholder="Choose Shift"
                            [preSelected]="{shift_name:masterField.values[i].shiftName}">
                          </ps-auto-suggestion>
                          <div class="error"
                            *ngIf="shiftValidator[i] && shiftValidator[i].error && !processStageValidator[i].error">
                            <span>{{ shiftValidator[i]?.msg }}</span>
                          </div>
                        </div>

                        <div class="col-md-2">
                          <div class="plus_minus_icon">
                            <span class="input-group-text remove-field" id="my-addon" *ngIf="i !== 0"
                              (click)="masterField.values.splice(i,1);selectedPlant.splice(i,1);processStages.splice(i,1); processWiseShifts.splice(i,1);">
                              <i class="fa fa-trash remove"></i>
                            </span>
                            <span class="input-group-text add-field" id="my-addon"
                              *ngIf="i == masterField.values.length-1" (click)="addValues()">
                              <i class="fa fa-plus add"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="resetDetail()"> {{"reset" | customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}}
                  </button>
                </div>
              </div>

            </div>


            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <div class="header-block">
                      <h3 class="card-title">
                        {{"list_of shifts" | customTranslate}}
                      </h3>
                    </div>
                  </div>

                  <div class="card-body">
                    <!-- table -->
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{"s.no." | customTranslate}}</th>
                          <th>{{"user_name" | customTranslate}}</th>
                          <th>{{"shift_start on" | customTranslate}}</th>
                          <th>{{"shift_end on" | customTranslate}}</th>
                          <th>{{"shift_name" | customTranslate}}</th>
                          <th>{{"process_name" | customTranslate}}</th>
                          <th>{{"stage_name" | customTranslate}}</th>
                          <th class="action">{{"action" | customTranslate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of allUserShifts; let i = index">
                          <td>{{i+1}}</td>
                          <td>{{row.user_name}}</td>
                          <td>{{row.shift_start_time}}</td>
                          <td>{{row.shift_end_time}}</td>
                          <td>{{row.shift_name}}</td>
                          <td>{{row.process_name}}</td>
                          <td>{{row.stage_name}}</td>
                          <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-primary" (click)="viewDetails(row)"
                                *ngIf="userService.permissionMap.get('view_shift_allocation_access')==true "> {{"view" |
                                customTranslate}} </button>
                              <button class="btn btn-danger" (click)="confirmAlert(row.id)"
                                *ngIf="userService.permissionMap.get('delete_shift_allocation_access')==true ">
                                {{"delete" | customTranslate}} </button>
                            </div>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>



          </div>
        </div>
      </div>

<div class="col-12 px-2">
  <label class="heading">{{ "share " + communicationType| translate}}</label>
  <form class="comment-form">
    <div class="form-group">

      <div class="col-md-12 form-group">
        <label for="my-input" class="required">{{'Enter Contact Details'}}</label>
        <ps-auto-suggestion style="margin: 5px;" (onSelected)="onValueChanged($event)" [data]='[]' display="name"
        [preSelected]="contactInfo" placeholder="Enter Contact Details" isMultiSelect="true" [onInputTrigger]="true" pattern="[0-9]*">
      </ps-auto-suggestion>
      <div class="error" *ngIf="contactInfoValidator && contactInfoValidator.error">
        <span>{{contactInfoValidator.msg}} </span>
      </div>
      </div>

      <div class="col-md-12 form-group">
        <label for="my-input" class="required">{{'Enter Content'}}</label>
      <textarea *ngIf="communicationType != 'email'"class="form-control message-text  required" placeholder="Enter content for this message" [(ngModel)]="content" name="contentText"></textarea>
      <div class="ck_editor" *ngIf="communicationType == 'email'">
        <ckeditor  [editor]="Editor" [(ngModel)]="content" style="width: 100%;" name="contentCkEditor"></ckeditor>
      </div>
        <div class="error" *ngIf="contentValidator && contentValidator.error" >
          <span>{{contentValidator.msg}}</span>
        </div>
      </div>
    </div>
  </form>
  <div class="buttons">
    <button type="button" class="btn btn-primary send-button" (click) = "formValidator()">Send</button>
    <button type="button" class="btn btn-warning reset-button" (click) = "reset()">Reset</button>
  </div>
</div>

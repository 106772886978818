import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { History } from 'src/app/dataType/assetInterface';

@Component({
  selector: 'app-asset-history',
  templateUrl: './asset-history.component.html',
  styleUrls: ['./asset-history.component.scss']
})
export class AssetHistoryComponent implements OnInit {
  @Input() public assetId:number;
  history: History[]=[];
  constructor(private api:ApiService,private common:CommonService) { }

  ngOnInit(): void {
    this.getHistoryOfAssets();
  }

  getHistoryOfAssets(){
    let params={
      assetId:this.assetId
    }
    this.api.get('asset-history/get-history-logs-of-asset',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          res.data.forEach((element) => {
            element.logData = JSON.parse(element.logData);
          });
          this.history=res.data;
          console.log("history",this.history)
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { Router } from '@angular/router';
import { CheckInCheckOutModalComponent } from '../check-in-check-out-modal/check-in-check-out-modal.component';
import { AssetRequestRemarkComponent } from '../asset-request-remark/asset-request-remark.component';
import { RequestOfUser } from 'src/app/dataType/assetInterface';

@Component({
  selector: 'app-inventory-manager-checklist',
  templateUrl: './inventory-manager-checklist.component.html',
  styleUrls: ['./inventory-manager-checklist.component.scss']
})
export class InventoryManagerChecklistComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'ASSET LABEL LIST');
  startDate: any = new Date(new Date().setDate(new Date().getDate() - 7));
  endDate: any = new Date();
  listActionCount: Record<string,any>
  requests: RequestOfUser[] = [];
  activeTab: number = 3;
  activeStatus: string = 'PENDING';



  constructor(private breadcrumbService: BreadcrumbService,
    private table: TableService,
    public common: CommonService,
    public api: ApiService,
    public userService: UserService,
    public alert: AlertService,
    private modalService: NgbModal,
    public router: Router) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'assets', url: null },
      { name: 'Inventory-Manager-Dashboard', url: '/user/inventory-manager-checklist' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.getAssetRequestCount()
  }

  ngOnInit(): void {
    this.getAssetRequestData(this.activeStatus);
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }


  openCheckInCheckOutModal(flag:any,reqId: number, curStatus: any, assetList: any,userType:any,ref_id:any) {
    this.common.params={
      checkInFlag:flag,
      reqId:reqId,
      curStatus:curStatus,
      assetList:(assetList != null ? JSON.parse(assetList) : null),
      userType:userType,
      entityId:ref_id
    }
    const activeModal = this.modalService.open(CheckInCheckOutModalComponent, {
      size: 'lg',
    });
    activeModal.result.then(() => {
    this.getAssetRequestData(this.activeStatus);
    this.getAssetRequestCount();
    });
  }

  viewRemarks(row: any) {
    const activeModal=this.modalService.open(AssetRequestRemarkComponent,{
      size:'xl'
    })
    activeModal.componentInstance.refData={
      request_id:row.asset_request_id,
      calling:true,
      from_page:true
    }
  }

  linkCopy(row:any){
    const secretKey = btoa(row.asset_request_id);
    const calling = this.router.url;
    const link=`${window.location.origin}/#/user/request-info?secretKey=${secretKey}&calling=${calling}`;
    navigator.clipboard.writeText(link).then(() => {
      this.alert.success('Link copied to clipboard');
      console.log('Link copied to clipboard:', link);
    }).catch(err => {
      this.alert.error('Failed to copy the link');
      console.error('Failed to copy the link:', err);
    });
  }

  getAssetRequestData(status: any) {
    this.common.loading = true;
    let params={
      status:status,
      callingForinventory:true,
      startDate:this.startDate,
      endDate:this.endDate
    }
    this.api.get('asset_requests/get-asset-requests-of-user',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.requests = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  setActiveStatus() {
    this.activeStatus = this.activeTab == 1 ? 'APPROVED' : (this.activeTab == 2 ? 'REJECTED' : (this.activeTab == 3 ? 'PENDING' : (this.activeTab == 4 ? 'ASSIGNED' : (this.activeTab == 5 ? 'CHECKEDIN' : 'CHECKEDOUT'))));
  }

  updateRequestStatus(reqId: number, curStatus: any, assetList: any) {
    this.common.loading=true
    let params = {
      status: curStatus,
      requestId: reqId,
      assetList: (assetList != null ? JSON.parse(assetList) : null)
    }
    this.api.post('asset_requests/update-request-status', params).subscribe(
      (res: any) => {
        this.common.loading=false
        this.alert.info("Request Status updated Successfully !!")
        this.getAssetRequestData(this.activeStatus);
        this.getAssetRequestCount();
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }


  getAssetRequestCount() {
    this.common.loading = true;
    let params = {
      status: false,
      startDate: this.startDate,
      endDate: this.endDate
    }
    this.api.get('asset_requests/get-asset-request-count', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.listActionCount = res.data.reduce((acc,obj)=> {acc[obj.status_name]=obj.count;
        return acc},{} as Record<string,number> );
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_plant_access')==true ||userService.permissionMap.get('edit_plant_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input" class="required">{{"company" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="company" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyValidator && companyValidator.error">
                    <span>{{companyValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"plant_name" | translate}}</label>
                  <input type="text" class="form-control" name="name" required placeholder="Enter Plant Name"
                    [(ngModel)]="plant.name" #name="ngModel" autocomplete="off">
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{plantNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant_address" | translate}}</label>
                  <input type="text" class="form-control" name="name" required placeholder="Enter Plant Address"
                    [(ngModel)]="plant.address" #name="ngModel" autocomplete="off">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant_city" | translate}} </label>
                  <input type="text" class="form-control" name="name" required placeholder="Enter Plant City"
                    [(ngModel)]="plant.city" #name="ngModel" autocomplete="off">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant_state" | translate}} </label>
                  <input type="text" class="form-control" name="name" required placeholder="Enter Plant State"
                    [(ngModel)]="plant.state" #name="ngModel" autocomplete="off">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant_country" | translate}} </label>
                  <input type="text" class="form-control" name="name" required placeholder="Enter Plant Country"
                    [(ngModel)]="plant.country" #name="ngModel" autocomplete="off">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"plant_type" | translate}} </label>
                  <select name="plant_type" id="#plant_type" class="form-control" [(ngModel)]="plant.type">
                    <option selected="CEMENT" value="CEMENT">Cement</option>
                    <option value="STEEL">Steel</option>
                    <option value="AUTOMOBILE">Automobile</option>
                    <option value="PRINTING">Printing</option>
                    <option value="WAREHOUSE">Warehouse</option>
                  </select>
                  <div class="error" *ngIf="planttypeValidator && planttypeValidator.error">
                    <span>{{planttypeValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant_description" | translate}}</label>
                  <input type="text" class="form-control" name="name" required placeholder="Enter Plant Description"
                    [(ngModel)]="plant.description" #name="ngModel" autocomplete="off">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"plant_status" | translate}}</label>
                  <select name="plant_status" id="#plant_status" class="form-control" [(ngModel)]="plant.status">
                    <option selected="ACTIVE" value="ACTIVE">{{"active" | translate}}</option>
                    <option value="INACTIVE">{{"inactive" | translate}}</option>
                    <!-- <option value="DELETED">Deleted</option> -->
                  </select>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-muted" (click)="common.backClicked()"> {{"back" | translate}} </button>
                  <button class="btn btn-warning" (click)="resetDetail()">{{"reset" | translate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | translate}} </button>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"plant_list" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="card-body">
                <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                  <thead>
                    <tr>
                      <th>{{("s.no." | translate)}}</th>
                      <th>{{("plant" | translate)+" "+("name" | translate)}}</th>
                      <th>{{("plant" | translate)+" "+("type" | translate)}}</th>
                      <th>{{("company" | translate)}}</th>
                      <th>{{("status" | translate)}}</th>
                      <th class="action">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of data, let i = index">
                      <td>{{i+1}}</td>
                      <td>{{row.plantName}}</td>
                      <td>{{row.plantType}}</td>
                      <td>{{row.companyName}}</td>
                      <td>{{row.plantStatus}}</td>
                      <td class="action">
                        <div class="btn-block">
                          <button class="btn btn-primary" (click)="viewDetails(row)"> {{"view" | translate}} </button>
                          <button class="btn btn-success" (click)="editPlant(row)"
                            *ngIf="userService.permissionMap.get('edit_plant_access')==true && row.plantId != -1">
                            {{"edit" | translate}} </button>
                          <button class="btn btn-danger" (click)="confirmAlert(row)"
                            *ngIf="userService.permissionMap.get('delete_plant_access')==true && row.plantId != -1">
                            {{"delete" | translate}} </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>
          </div>

        </div>

      </div>

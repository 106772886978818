import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/@core/services/common.service';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() menu: any[] = [];
  activeMenuIndex: number = -1;
  isBoop: number = -1;
  active: number = -1;
  toggled: boolean = true;
  menutoggled: boolean = false;
  activeSubmenuIndex: number = -1;
  config = {
    paddingAtStart: true,
    classname: 'my-custom-class',
    listBackgroundColor: 'rgb(208, 241, 239)',
    fontColor: 'rgb(8, 54, 71)',
    backgroundColor: 'rgb(208, 241, 239)',
    selectedListFontColor: 'red',
  };

  constructor(public common: CommonService, public route: Router, public translateService: TranslateService) {
    this.common.menuToggled = true;
    //  this.menuSelection(this.activeMenuIndex);
    let language = localStorage.getItem("language");
    translateService.addLangs(["en", "hn"])
    translateService.setDefaultLang(language == null ? "en" : language)

    this.common.language.subscribe((lang: any) => {
      this.translateService.use(lang);
    })

  }


  activemenuSet() {
    let activemenu: any = localStorage.getItem('currentMenuIndex')
    this.activeMenuIndex = parseInt(activemenu);


    let activeMenuIndex: any = localStorage.getItem('subMenuIndex');
    this.activeSubmenuIndex = parseInt(activeMenuIndex);

    // this.route.events.subscribe(val => {
    //   if (val instanceof NavigationEnd) {
    //       let active: any = localStorage.getItem('currentMenuIndex');
    //       this.activeMenuIndex = parseInt(active);

    //       let activeMenuIndex: any = localStorage.getItem('subMenuIndex');
    //       this.activeSubmenuIndex = parseInt(activeMenuIndex);
    //   }
    // });


  }



  // ngOnChanges(changes: any) {
    // if (changes.menu) {
    //   this.menu = changes.menu.currentValue;
    // }
  // }

  menuSelection(index: number) {
    let currentIndex: any = index;
    localStorage.setItem('currentMenuIndex', currentIndex);
    this.route.navigate([this.menu[index].childs[0].link])
  }

  activeMenu(index: number, parentMenuIndx: number) {
    this.activeMenuIndex = parentMenuIndx;
    this.activeSubmenuIndex = index;
    localStorage.setItem('subMenuIndex', index.toString())
    this.common.dashboardparam = '';
  }

  hovertoggle(event: boolean = false) {
    // console.log(event)
    this.common.sidebarToggled(event);
  }

  ngOnInit(): void {
    this.activemenuSet();
  }

}

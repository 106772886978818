import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { AddComplianceModalComponent } from '../../add-compliance-modal/add-compliance-modal.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-trip-comments',
  templateUrl: './trip-comments.component.html',
  styleUrls: ['./trip-comments.component.scss']
})
export class TripCommentsComponent implements OnInit {
  @Input() tripId: number;
  @Input() entityType: String='TRIP';
  @Input() showModal: boolean = false;
  @Input() toggle: boolean = false;
  @Input() complainceData:any;
  @Input() title:string
  tripCommentTrail: any[] = [];
  commentMessage: string = null;
  activeModal: any;

  constructor(public common: CommonService,
    private api: ApiService,
    public modalService: NgbModal,
    public alertService:AlertService
  ) { }

  ngOnInit(): void {
    this.entityType = this.entityType==null?'TRIP':this.entityType
    this.getTripTrail();
    // console.log("CoomentCompliance",this.complainceData)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['toggle']) {
      this.getTripTrail();
    }
  }

  getTripTrail() {
    let params = {
      tripId: this.tripId,
      entityType: this.entityType!='ASSET'?[this.entityType.toUpperCase(),'COMPLIANCE']:[this.entityType]
    };
    this.common.loading = true;
    this.api.get('comment/get-trip-comment-trail', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.tripCommentTrail = res.data;
        console.log("tripcomtrail",this.tripCommentTrail)
        this.scrollToBottom();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  addCommentForTrip() {
    if(!this.commentMessage){
      return this.alertService.error("Invalid Remarks");

    }
    let params = {
      entityId: this.tripId,
      entityType: this.entityType ? this.entityType.toUpperCase(): 'TRIP',
      commentType: 'REMARK',
      comment: this.commentMessage,
      refData: null,
    };
    this.api.post('comment/add-comment', params).subscribe(
      (res: any) => {
        this.commentMessage = null;
        this.getTripTrail();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        document.getElementById('lastMsg')?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }, 10);
    } catch (err) { }
  }

  closeModal() {
     this.modalService.dismissAll();
  }
  addCompliance(){
    const activeModal = this.modalService.open(AddComplianceModalComponent, {
      size: 'md',
    });

    activeModal.componentInstance.refData=this.complainceData;
    activeModal.result.then(() => {
      this.getTripTrail();
    });
  }
}

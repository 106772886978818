<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"plant_setting" | translate}}
            </h3>
          </div>
          <div class="col-md-5d-flex pull-right" class="ml-2"
            *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN' ">

            <mat-form-field appearance="fill" class="mr-4" *ngIf="role.user_role == 'AXESTRACK'">
              <mat-label>{{"select_company" | translate}}</mat-label>
              <mat-select [(ngModel)]="companyId">
                <mat-option *ngFor="let  item1 of companyList;index as i" (click)="selectCompany(item1)"
                  [value]="item1.companyId">
                  {{item1.companyName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{"select_plant" | translate}}</mat-label>
              <mat-select [value]="plantName">
                <mat-option *ngFor="let item of plantList;index as i" (click)="selectedPlant(item)"
                  [value]="item.plantName">
                  {{item.plantName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="card-body ">
          <div class="tab_section">
            <div class="tab" [class.active]="activeTab==1" (click)="activeTab=1;">{{"transaction_setting" | translate}}</div>
            <div class="tab" [class.active]="activeTab==4" (click)="activeTab=4;">{{"general_setting" | translate}}</div>
            <div class="tab" [class.active]="activeTab==2" (click)="activeTab=2;">{{"plant_entry setting" | translate}}</div>
            <div class="tab" [class.active]="activeTab==3" (click)="activeTab=3;">{{"user_setting" | translate}}</div>
            <div class="tab" [class.active]="activeTab==5" (click)="activeTab=5;">{{"parking_setting" | translate}}</div>
            <div class="tab" [class.active]="activeTab==6" (click)="activeTab=6;">{{"dock_allotment_setting" | translate}}</div>
            <div class="tab" [class.active]="activeTab==6" (click)="activeTab=7;">{{"Visitor Form Setting" | translate}}</div>
          </div>

          <div class="tab_content" *ngIf="activeTab==1">
            <div class="row">
              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input">{{"prefix_setting" | translate}}</label>
                <input type="text" placeholder="Select prefix" class="form-control" [(ngModel)]="plantSetting.preFix">
              </div>

              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input">{{"order_id" | translate}}</label>
                <input type="text" class="form-control" disabled placeholder="Order Id">
              </div>


              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input"> {{"postfix_setting" | translate}} </label>
                <input type="text" class="form-control" placeholder="Select PostFIx" [(ngModel)]="plantSetting.postFix">
              </div>

              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input" class="required"> {{"avg_trancation count" | translate}}</label>
                <input type="number" class="form-control" placeholder="Avg Transaction Count"
                  [(ngModel)]="plantSetting.avgTransactionCount">
              </div>

              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input"> {{"Compliance View Limit" | translate}}</label>
                <input type="number" class="form-control" [min]="0" placeholder="Compliance View Limit"
                  [(ngModel)]="plantSetting.complianceLimit">
              </div>

              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input">{{"Late Remark Mandatory" | translate}}<input type="checkbox"
                    class="form-control set-checkbox" [(ngModel)]="lateRemarkType">
                </label>
              </div>
              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input">{{"Auto Close Pending Trip" | translate}}<input type="checkbox"
                    class="form-control set-checkbox" [(ngModel)]="autoClosePendingTrip">
                </label>
              </div>
            </div>
          </div>

          <div class="tab_content" *ngIf="activeTab==2">
            <div class="row">
              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input"> {{("entry" | translate)+" "+("type" | translate)}}</label>
                <select name="hardwareType" id="#fieldType" class="form-control" [(ngModel)]="entryType">
                  <option value="0">API</option>
                  <option value="1">Manual</option>
                  <option value="2">FASTag</option>
                  <option value="3">Entry Form</option>
                </select>
              </div>

              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input">{{"Vehicle Verification Method" | translate}}</label>
                <ps-auto-suggestion (onSelected)="selectVehicleVerificationMethod($event)" [data]="verificationMethodList"
                  display="methodName" placeholder="Choose Vehicle Verification Method"
                  [preSelected]="{methodName:vehicleVerificationMethodName}" [(ngModel)]="vehicleVerificationMethodName">
                </ps-auto-suggestion>
              </div>

              <div class="form-group col-md-3 col-sm-12" *ngIf="this.entryType=='0'">
                <label for="my-input">{{"entry" | translate}} {{"method" | translate}}</label>
                <ps-auto-suggestion (onSelected)="selectMethod($event)" [data]="entryTypeMethodList" display="methodName"
                  placeholder="Choose Method" [preSelected]="{methodName:methodEntryName}">
                </ps-auto-suggestion>
              </div>

              <div class="form-group col-md-2 col-sm-12">
                <label for="my-input">{{"OCR Enabled" | translate}}<input type="checkbox"
                    class="form-control set-checkbox" [(ngModel)]="ocrEnable">
                </label>
              </div>

              <div class="form-group col-md-2 col-sm-12">
                <label for="my-input">{{"Auto Assign Process" | translate}}<input type="checkbox"
                    class="form-control set-checkbox" [(ngModel)]="autoAssignProcess">
                </label>
              </div>

            </div>
          </div>

          <div class="tab_content" *ngIf="activeTab==3">
            <div class="row">
              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input">{{"username generation methods" | translate}} </label>
                <ps-auto-suggestion (onSelected)="selectUserNameTypeMethod($event)" [data]="usernameGenerationMethodList"
                  display="methodName" placeholder="Choose UserName Type Method"
                  [preSelected]="{methodName:userNameTypeMethodName}" [(ngModel)]="userNameTypeMethodName">
                </ps-auto-suggestion>
              </div>

              <div class="form-group col-md-3 col-sm-12" *ngIf="userNameTypeMethodId==125 || userNameTypeMethodId==127">
                <label for="my-input">{{"username_numeric_prefix" | translate}} </label>
                <input type="number" min="0" class="form-control" placeholder="Username Numeric Prefix"
                  [(ngModel)]="plantSetting.usernamePrefix">
              </div>

              <div class="form-group col-md-3 col-sm-12">
                <label for="" class="d-block" class="required"> {{"geofence_bounded" | translate}}</label>
                <div mt-2>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isGeoBounded" [(ngModel)]="isGeoBounded"
                      id="exampleRadios3" [value]="true">
                    <label for="exampleRadios3">
                      Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isGeoBounded" [(ngModel)]="isGeoBounded"
                      [value]="false" id="exampleRadios4" checked>
                    <label for="exampleRadios4">
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group col-md-3 col-sm-12" >
                <label for="my-input">{{"avg_shift_duration" | translate}} </label>
                <input type="number" min="0" class="form-control" placeholder="Average Shift Duration"
                  [(ngModel)]="plantSetting.avgShiftDuration">
              </div>
            </div>
          </div>

          <div class="tab_content" *ngIf="activeTab==4">
            <div class="row">
              <div class="form-group col-md-3 col-sm-12">
                <label for="" class="d-block" class="required"> {{"overtime_allowed" | translate}}</label>
                <div mt-2>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="allowedThreshold" [(ngModel)]="allowedThreshold"
                      id="exampleRadios3" [value]="true">
                    <label for="exampleRadios3">
                      Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="allowedThreshold" [(ngModel)]="allowedThreshold"
                      [value]="false" id="exampleRadios4" checked>
                    <label for="exampleRadios4">
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group col-md-3 col-sm-12" >
                <label for="my-input">{{"max_overtime_duration" | translate}}</label>
                <input type="number" placeholder="Enter Threshold" class="form-control" [(ngModel)]="threshold" [disabled]="!allowedThreshold">
              </div>

              <div class="form-group col-md-3 col-sm-12" >
                <label for="my-input">{{"max_streaming_duration" | translate}}</label>
                <input type="number" placeholder="Enter Threshold" class="form-control" [(ngModel)]="maxStreamingDuration" (change)="checkStepSize()" min="1" max="10">
              </div>

              <div class="form-group col-md-3 col-sm-12">
                <label for="" class="d-block" class="required"> {{"Visitor Tracking Enabled" | translate}}</label>
                <div mt-2>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isVisitorTrackingEnabled" [(ngModel)]="isVisitorTrackingEnabled"
                      id="exampleRadios3" [value]="true">
                    <label for="exampleRadios3">
                      Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isVisitorTrackingEnabled" [(ngModel)]="isVisitorTrackingEnabled"
                      [value]="false" id="exampleRadios4" checked>
                    <label for="exampleRadios4">
                      No
                    </label>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="tab_content" *ngIf="activeTab==5">
            <div class="row">
              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input">{{ "parking" | translate }} {{ "type" | translate }}</label>
                <select name="hardwareType" id="fieldType" class="form-control" [(ngModel)]="parkingType">
                  <option value="0">Open parking</option>
                  <option value="1">Slot Parking</option>
                  <option value="2">Both</option>
                </select>
              </div>

              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input"> {{("parking_allotment" | translate)+" "+("type" | translate)}}</label>
                <select name="hardwareType" id="#fieldType" class="form-control" [(ngModel)]="parkingAllotmentType">
                  <option value="1">Scheduling</option>
                  <option value="0">Manual</option>
                </select>
              </div>

              <div class="form-group col-md-3 col-sm-12">
                <label for="my-input">{{"parking_slot_prefix" | translate}}</label>
                <input type="text" maxlength="2" placeholder="Select prefix" class="form-control"
                  [(ngModel)]="parkingAllotmentPrefix">
              </div>
            </div>
          </div>

          <div class="tab_content" *ngIf="activeTab==6">
            <div class="row">
              <div class="form-group col-md-4 col-sm-12">
                <label for="my-input"> {{("dock_allotment" | translate)+" "+("type" | translate)}}</label>
                <select name="hardwareType" id="#fieldType" class="form-control" [(ngModel)]="dockAllotmentType">
                  <option value="1">Scheduling</option>
                  <option value="0">Manual</option>
                </select>
              </div>


              <div class="form-group col-md-4 col-sm-12">
                <label for="my-input">{{"dock_prefix" | translate}}</label>
                <input type="text" placeholder="Select prefix" class="form-control" [(ngModel)]="dockAllotmentPrefix">
              </div>
              <div class="form-group col-md-4 col-sm-12">
                <label for="my-input">{{"Future Booking Allowed Before " | translate}}</label>
                <input type="number" placeholder="Select Number of Days" class="form-control"
                  [(ngModel)]="dockFutureBookingDate">
              </div>

              <div class="form-group col-md-4 col-sm-12">
                <label for="my-input">{{"Strict Queue Management" | translate}}<input type="checkbox"
                    class="form-control set-checkbox" [(ngModel)]="dockStrictQueueType"> </label>
              </div>
            </div>
          </div>

<!--           Default plant setting -->
          <div class="tab_content" *ngIf="activeTab==7">
            <div class="row">
              <div class="form-group col-md-4 col-sm-12">
                <label for="my-input"> {{("Select Visitor Form" | translate)+" "+("type" | translate)}}</label>
                <ps-auto-suggestion (onSelected)="selectedCustomForm($event)" [data]="customForms"
                  display="form_title" placeholder="Select Custom Form" [preSelected]="{ form_title:customFormName }">
                </ps-auto-suggestion>
              </div>


              <!-- <div class="form-group col-md-4 col-sm-12">
                <label for="my-input">{{"dock_prefix" | translate}}</label>
                <input type="text" placeholder="Select prefix" class="form-control" [(ngModel)]="dockAllotmentPrefix">
              </div> -->
              <!-- <div class="form-group col-md-4 col-sm-12">
                <label for="my-input">{{"Future Booking Allowed Before " | translate}}</label>
                <input type="number" placeholder="Select Number of Days" class="form-control"
                  [(ngModel)]="dockFutureBookingDate">
              </div> -->

              <!-- <div class="form-group col-md-4 col-sm-12">
                <label for="my-input">{{"Strict Queue Management" | translate}}<input type="checkbox"
                    class="form-control set-checkbox" [(ngModel)]="dockStrictQueueType"> </label>
              </div> -->
            </div>
          </div>

          <div class="form-group action-block mt-2">
            <div class="btn-block">
              <button type="submit" class="btn btn-submit" (click)="savePlantSetting()"
                *ngIf="user.permissionMap.get('update_plant_setting_access')==true">{{btn | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="header-block">
              <h3 class="card-title">
                {{"list_of_setting" | translate}}
              </h3>
            </div>
          </div>

          <div class="card-body">
            <!-- table -->
            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>{{("s.no." | translate)}}</th>
                  <th>{{("plant" | translate)+" "+("name" | translate)}}</th>
                  <th>{{("prefix" | translate)}}</th>
                  <th>{{("postfix" | translate)}}</th>
                  <th>{{"avg_count" | translate}}</th>
                  <th>{{"entry_method" | translate}}</th>
                  <th>{{"vehicle_verification_method" | translate}}</th>
                  <th>{{"username_generation_method" | translate}}</th>
                  <th>{{"username_numeric_prefix" | translate}}</th>
                  <th>{{"geofence_bounded" | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of allPlantSettingData;let i=index">
                  <td>{{i+1}}</td>
                  <td>{{item.plantname}}</td>
                  <td>{{item.pre_fix}}</td>
                  <td>{{item.post_fix}}</td>
                  <td>{{item.avg_count}}</td>
                  <td>{{item.entry_method_name}}</td>
                  <td>{{item.vehicle_verification_method_name}}</td>
                  <td>{{item.username_method_name}}</td>
                  <td>{{item.usernamenumericvalue}}</td>
                  <td>{{item.isgeobounded}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

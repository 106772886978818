import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'ng-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss'],
})
export class SmartTableComponent implements OnInit, AfterViewInit {
  @Input() data: any;

  dataheading: any = [];
  datavalues: any = [];

  constructor() {}

  renderTable() {
    this.dataheading = [];
    this.datavalues = [];
    this.data = this.data ?? [];
    if (this.data.length) {
      this.dataheading = Object.keys(this.data[0]);
      this.data.map((item: any) => {
        this.datavalues.push(Object.values(item));
      });
    }
  }

  ngOnInit(): void {
    this.renderTable();
  }

  ngOnChanges(): void {
    this.renderTable();
  }

  ngAfterViewInit(): void {
    this.renderTable();
  }
}

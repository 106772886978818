import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { WidgetType, XSightsCoreService } from 'x-sights-core';

@Component({
  selector: 'app-HardwareOTALogs',
  templateUrl: './HardwareOTALogs.component.html',
  styleUrls: ['./HardwareOTALogs.component.scss'],
})
export class HardwareOTALogsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  isdisable = false;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'Hardware OTA Logs Table');
  hardwareOtaLogs: any[] = [];
  viewType: string = '';
  hardwareHealthData: any = {};
  allGraphIds: any = [1147, 1148, 1149, 1150];
  connectionId: string = '';
  intervalType = ['12 Hours', '24 Hours', '48 Hours', '72 Hours'];
  @Input() public refData;

  chartData: any = [];

  constructor(
    public table: TableService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal,
    private xsights: XSightsCoreService,
    private apiService: ApiService,
    private alert: AlertService
  ) {}

  ngOnInit() {
    this.viewType = this.refData.viewType;
    this.connectionId = this.refData.connectionId;
  }
  ngAfterViewInit(): void {
    if (this.refData.viewType == 'Table') {
      this.getHardwareDetails();
    } else {
      this.getHardwareHealth(null);
    }
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  getHardwareDetails() {
    this.common.loading = true;
    this.api
      .get(
        'hardware/get-ota-logs-by-hardwareId?hardwareId=' +
          this.refData.hardwareId
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.hardwareOtaLogs = res.data;
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  closeModal(res) {
    this.activeModal.close(res);
  }
  drawGraphs() {
    this.common.loading = true;
    this.allGraphIds.forEach((graph) => {
      this.api
        .get(
          'graphs/get-graph-by-id',
          {
            graphId: graph,
          },
          'XSIGHT'
        )
        .toPromise()
        .then((res: any) => {
          const structureJ = JSON.parse(res.data[0].structure);
          let data = {
            graphId: 'graph-' + res.data[0].id,
            graphTitle: res.data[0].name,
            rows: structureJ.xAxis,
            columns: structureJ.yAxis,
            graphTypes: structureJ.chartType,
            graphData: this.hardwareHealthData,
            aggregationFunctions: structureJ.aggreagations,
            filter: structureJ.filter,
            colors: structureJ.colColours,
            customVariable: structureJ.derivedVariables,
            dataFormat: [],
            lastLevelColumns: structureJ.lastLevelData,
          };
          this.xsights.build(WidgetType.GRAPH, data);
          this.common.loading = false;
        });
    });
  }

  getHardwareHealth(event: any) {
    this.common.loading = true;
    let params = {
      connectionId: this.connectionId,
      interval: event != null ? event.target.value : '12 Hours',
    };
    this.apiService
      .get('hardware/get-hardware-health-by-connectionId', params)
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.hardwareHealthData = res.data;
            this.common.loading = false;
            this.drawGraphs();
          }
        },
        (err) => {
          this.common.loading = false;
          this.alert.error();
        }
      );
  }
}

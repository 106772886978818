<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Vehicle Tag Mapping" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div *ngIf="role.user_role == 'AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"company" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyList"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                </div>

                <div *ngIf="role.user_role != 'PLANTADMIN' && role.user_role != 'PLANTUSER'"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantList"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"process->stage" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedProcessStage($event)"
                    [data]="processStageList" display="order_name" placeholder="Choose Process"
                    [preSelected]="{order_name:processStageName}">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="stage" class="required">{{'select_vehicle no:'|translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectVehicle($event,'select')" [data]="vehicleList"
                    display="vehicle_rsn" placeholder="Choose Vehicle" (onChange)="selectVehicle($event,'input')"
                    [preSelected]="{vehicle_rsn: vehicleRsn}">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required" style="width: 100%">{{'select_tag_id'|translate}} :
                    <i class="fa fa-sync" aria-hidden="true" style="font-size: 14px; float: right; margin-top: 6px;"
                      (click)="getVehicleMapping(this.processStageId)" *ngIf="processStageId!=null"></i>
                  </label>
                  <ps-auto-suggestion (onSelected)="selectedField($event)" [data]="tagData" display="tag_id"
                    placeholder="Select Tag" name="selectedActionData" inputId="selectedActionData"
                    [preSelected]="{tag_id:tagId}">
                  </ps-auto-suggestion>
                </div>
              </div>


              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | translate}}
                  </button>
                  <button class="btn btn-warning" (click)="reset()"> {{"reset" | translate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="save()"> {{buttonName | translate}}
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>



      </div>

    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/@core/services/common.service';

@Component({
  selector: 'app-public-vechile-detail',
  templateUrl: './public-vechile-detail.component.html',
  styleUrls: ['./public-vechile-detail.component.scss'],
})
export class PublicVechileDetailComponent implements OnInit {
  constructor(public common: CommonService, private route: ActivatedRoute) {
    this.common.setLanguage('en');
    if (this.route.snapshot.queryParams.tripId) {
      let tripId = this.route.snapshot.queryParams.tripId;
      let isVisible = this.route.snapshot.queryParams.isVisible;
      this.common.updateTripId(tripId);
      this.common.updateIsVisible(isVisible);
    }
  }

  ngOnInit(): void {}
}

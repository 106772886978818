import { Component, OnInit, ViewChild } from '@angular/core';
import { TableService } from '../../@core/services/table.service';
import { AlertService } from './../../theme/layout/header/alert-message/alert.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';
import { CommunicationTempService } from './communication-temp.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { RoleService } from 'src/app/@core/services/role.service';
import { CompanyService } from '../companies/companies.service';
import { PlantService } from '../plant/plant.service';
import { ApiService } from 'src/app/@core/services/api.service';

@Component({
  selector: 'app-communication-template',
  templateUrl: './communication-template.component.html',
  styleUrls: ['./communication-template.component.scss'],
})
export class CommunicationTemplateComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Stage Destination Code List');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAllTemplates();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  public Editor = ClassicEditor;

  constructor(
    private communicationTempService: CommunicationTempService,
    public modalService: NgbModal,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public alert: AlertService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService,
    public companyService: CompanyService,
    public plantService: PlantService,
    public apiService: ApiService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Communication', url: '#' },
      {
        name: 'Communication Template',
        url: '/user/communication-template',
      },
    ];

    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  communication: any[] = [];
  templates: any[] = [];
  title: any[] = [];
  btn = 'save';
  communicationTypeName: null;
  titleName = '';
  queryVariable = '';
  status = 'true';
  communicationTypeId: null;
  detail = '';
  id = null;
  submitted = false;
  selectedOption: any = null;
  selectedTemplateName: any = null;
  selectedTemplateId: any = null;
  templateList: any[] = [];
  plantId = null;
  companyId = null;
  company: any[] = [];
  plantData: any[] = [];
  companyName: null;
  plantName: null;
  companyValidator: any = null;
  plantNameValidator: any = null;
  data: any[] = [];

  public model = {
    name: 'Hardik',
    description: 'This is a sample form using CKEditor 4.',
  };

  ngOnInit(): void {
    this.getCommunication();
    this.status = 'true';
    this.getAllTemplates();
    this.getVariables();
    this.getCompany();
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = null;
    this.companyValidator = null;
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.plantNameValidator = null;
    this.titleName = '';
  }

  getPlantById(id?: any) {
    this.common.loading = true;

    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          res.data.forEach((element) => {
            if (element.companyStatus == 'ACTIVE') this.company.push(element);
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  getCommunication() {
    this.common.loading = true;
    this.communicationTempService.getCommunication().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.communication = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getVariables() {
    this.common.loading = true;
    this.communicationTempService.getVariables().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.title = res.data;
        }
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    );
  }

  selectedCommunicationType(event: any) {
    this.selectedOption = event.communicationTypeName;
    this.communicationTypeId = event.communicationTypeId;
    this.communicationValidator = null;
    if (this.selectedOption == 'SMS' || this.selectedOption == 'Whatsapp') {
      this.getMessageTemplate();
    }
  }

  selectedTemplate(event: any) {
    this.selectedTemplateName = event.template_name;
    this.selectedTemplateId = event.template_id;
    this.detail = event.template_data;
  }

  getMessageTemplate(): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = {
        typeId: this.selectedOption == 'SMS' ? 0 : 1,
      };
      this.apiService
        .get('communication/get-message-template-by-typeId', params)
        .subscribe(
          (res: any) => {
            if (res.status == true) {
              this.templateList = res.data;
              resolve(this.templateList);
            }
          },
          (error: any) => {
            reject(null);
          }
        );
    });
  }

  viewCommunicationTemplate(row?: any) {
    this.common.params = { details: [row], title: 'View Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  async editCommunicationTemplate(row?: any) {
    this.common.gotoTop();
    this.id = row.id;
    this.communicationTypeId = row.communicationTypeId;
    this.communicationTypeName = row.communicationType;
    this.selectedOption = row.communicationType;
    if (this.selectedOption == 'SMS' || this.selectedOption == 'Whatsapp') {
      await this.getMessageTemplate().then((res) => {
        this.selectedTemplateId = row.messageTemplateId;
        this.selectedTemplateName = res
          .filter((stu) => stu.template_id == row.messageTemplateId)
          .map((stu) => stu.template_name)[0];
      });
    }
    this.titleName = row.title;
    this.status = row.isActive;
    this.queryVariable = row.queryVariable;
    this.detail = row.detail;
    this.btn = 'update';
    this.companyId = row.companyId;
    this.companyName = row.companyName;
    this.plantId = row.plantId;
    this.plantName = row.plantName;
  }

  saveTemplate(): void {
    let data: any;
    if (this.role.user_role == 'AXESTRACK') {
      data = {
        id: this.id,
        communicationTypeId: this.communicationTypeId,
        title: this.titleName,
        detail: this.detail,
        status: this.status,
        plantId: this.plantId,
        companyId: this.companyId,
        queryVariable: this.queryVariable,
        messageTemplateId: this.selectedTemplateId,
      };
    } else {
      data = {
        id: this.id,
        communicationTypeId: this.communicationTypeId,
        title: this.titleName,
        detail: this.detail,
        status: this.status,
        queryVariable: this.queryVariable,
        messageTemplateId: this.selectedTemplateId,
      };
    }
    this.communicationTempService.saveTemplate(data).subscribe(
      (res: any) => {
        if (res.status == true) {
          this.submitted = true;
          this.alert.success(res.message);
          this.resetDetail();
          this.getAllTemplates();
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  resetDetail() {
    this.titleName = '';
    this.communicationTypeName = null;
    this.status = 'true';
    this.btn = 'save';
    this.detail = '';
    this.communicationValidator = null;
    this.statusValidator = null;
    this.titleValidator = null;
    this.communicationTypeId = null;
    this.selectedOption = null;
    this.companyId = null;
    this.companyName = null;
    this.plantId = null;
    this.plantName = null;
    this.companyValidator = null;
    this.plantNameValidator = null;
    this.queryVariable = '';
    this.templateList = [];
    this.selectedTemplateName = null;
    this.selectedTemplateId = null;
    this.id = null;
    this.getAllTemplates();
  }

  addDetail(row?: any) {
    this.detail = this.detail + ' {{' + row.databaseFieldName + '}}';
  }

  getAllTemplates() {
    this.common.loading = true;
    this.plantId = this.userService._loggedInUser.plantId;
    this.communicationTempService.getAllTemplate().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.templates = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  communicationValidator: any;
  statusValidator: any;
  titleValidator: any;
  templateValidator: any;
  queryValidator: any;
  MessageValidator: any;

  formValidator() {
    if (
      this.communicationTypeName == 'Whatsapp' ||
      this.communicationTypeName == 'SMS'
    ) {
      this.templateValidator = this.common.nullValidator(
        this.selectedTemplateId,
        'Template Mandatory'
      );
    } else {
      this.templateValidator = {
        error: false,
        msg: null,
      };
    }
    this.companyValidator = this.common.nullValidator(
      this.companyId,
      'Company Mandatory'
    );
    this.plantNameValidator = this.common.nullValidator(
      this.plantName,
      'Plant Name Mandatory'
    );
    this.communicationValidator = this.common.nullValidator(
      this.communicationTypeId,
      'Communication Type Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.status,
      'Status Mandatory'
    );
    this.titleValidator = this.common.nullValidator(
      this.titleName,
      'Title Mandatory'
    );
    this.queryValidator = this.common.nullValidator(
      this.queryVariable,
      'Query Mandatory'
    );
    this.MessageValidator = this.common.nullValidator(
      this.detail,
      'Message Mandatory'
    );
    if (this.role.user_role == 'AXESTRACK') {
      if (
        !this.companyValidator.error &&
        !this.plantNameValidator.error &&
        !this.titleValidator.error &&
        !this.communicationValidator.error &&
        !this.statusValidator.error &&
        !this.MessageValidator.error &&
        !this.templateValidator.error &&
        (this.btn == 'save' || this.btn == 'update')
      ) {
        this.saveTemplate();
      }
    } else {
      if (
        (!this.titleValidator.error &&
          !this.communicationValidator.error &&
          !this.statusValidator.error &&
          this.btn == 'save') ||
        this.btn == 'update'
      ) {
        this.saveTemplate();
      }
    }
  }
}

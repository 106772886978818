import { filter } from 'rxjs/operators';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import lodash from 'lodash';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { MapService } from 'src/app/@core/services/map.service';
import { AlertCardModalComponent } from '../alert-card-modal/alert-card-modal.component';
import _ from 'lodash';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserTrackingInfoModalComponent } from 'src/app/modals/user-tracking-info-modal/user-tracking-info-modal.component';
import { dA } from '@fullcalendar/core/internal-common';

declare var google: any;


@Component({
  selector: 'app-user-tracking-dashboard',
  templateUrl: './user-tracking-dashboard.component.html',
  styleUrls: ['./user-tracking-dashboard.component.scss'],
})


export class UserTrackingDashboardComponent implements OnInit {
  users: any[]=[];
  geofenceId: number=null;
  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public alertService: AlertService,
    public api: ApiService,
    public role: RoleService,
    private mapService: MapService,
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
    this.common.sidebarToggled(this.common.menuToggled);

   }

  ngOnInit() {
    this.getUserTrackingDashboardData();
    this.getUsers();
    // this.common.sidebarToggled(this.common.menuToggled);
  }
  @ViewChild('detailModal') detailModal: ElementRef;
  @ViewChild('exampleModal') modal: ElementRef;
  @ViewChild('exampleModal2') modal2: ElementRef;
  @ViewChild('exampleModal3') modal3: ElementRef;




  total_user_card = {
    total_user: 0,
    present_user: 0,
    outside_user: 0,
    percentage: 0,
    outsideData:[]
  };

  detail_info_card = {
    unauthorized_movement: 0,
    resolved_alert: 0,
    total_alert: 0,
    total_visitors: 0,
    sos_alert: 0,
    unauthorisedData: [],
    visitorData: []
  };

  restricted_zone = {};
  deparmentData: any = [];
  zoneData: any = [];
  worstzoneData: any = [];
  trackingData: any = [];
  stageZoneData: any = [];
  tableModalData: any = [];
  onDutyData: any = [];
  dailyProductivity:any=[];
  textArea:String='';
  modalTitle: string = '';
  actionType: string = '';
  days:number=7;
  totalUserData:any[]=[];
  totalPlantUser:number=null;
  allUserType:any[]=[];
  pendingAlerts:any[]=[];
  resolvedAlerts:any[]=[];
  stageprod:any={};
  zoneprod:any={};
  deptprod:any={};
  newDeptData:any[] = [];
  stageUsersCount:any[] = [];
  newZoneAllocationDate:any=[];
  worstProductiveUser:any=[];


  zoneColors: string[] = ['#7741d7', '#33bbb9', '#ab8800', '#c33979', '#378737'];


  public areaChartLegend = true;
  public areaChartType: ChartType = 'line';
  public areaChartData: ChartDataSets[] = []

  public areaChartOptions: ChartOptions = {
    responsive: true,
    title: {
      display: false,
    },
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'DD MMM YYYY'
          }
        },
        ticks: {
          source: 'data'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Productivity%',
          fontSize: 12,
          fontStyle: 'bold',
          fontFamily: ' sans-serif',
        },
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  handleModalClose(){
    this.tableModalData=[];
  }

  isUauthorised(userData:any){
    return (this.restricted_zone.hasOwnProperty(userData.last_ping.last_ping_zone_id) && !this.restricted_zone[userData.last_ping.last_ping_zone_id].includes(userData.user_id));
  }

  getUsers() {
    this.common.loading = true;
    let apiUrl = 'user/all_user_global_company_plant';
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.users = res.data;
          console.log("users",this.users);
          this.getAllStaff();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllStaff() {
      this.common.loading = true;
      let apiUrl = 'staff/all-staff?allStaff=false';
      this.api.get(apiUrl).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res?.data?.length) {
            res.data.forEach(el => {
              let obj={
                userId:el.staff_id,
                userName:el.first_name+' '+el.last_name,
              }
              this.users.push(obj);
            });
          console.log("new Users",this.users)
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  selectedUser(event: any) {
      if(this.geofenceId!=null){
    this.mapService.callingToggleBounce(this.geofenceId,2)
      }
    console.log("event",event)
    // this.mapService.toggleBounceMF(0);
    let hardwareId = this.trackingData.filter(user=>user.user_id==event.userId && this.isRecentPing(user.last_ping?.last_ping_at))[0]?.last_ping?.last_ping_hardware_id;
    console.log("hardwareId",hardwareId);
    this.geofenceId = this.allGeoFence[0]?.hardware.filter(hardware=>hardware.ipa_hardware_id==hardwareId)[0]?.geofence_id??null;
    console.log("geofenceId",this.geofenceId);
    //stopping old animation if exists

    //starting new search animation
    this.mapService.callingToggleBounce(this.geofenceId);

    }



  // Set the modal title and action type based on the icon clicked
  openModal(action: string) {
    this.modalTitle = `Send ${action}`;
    this.actionType = action;
    this.textArea='';
  }

  openUserTrackingModal(userId:number,userType:string,data:any){
    this.common.params = {
      userId:userId,
      userType:userType == 'USER' ? 'USER' : 'STAFF'
  }
    const activeModal = this.modalService.open(UserTrackingInfoModalComponent, {
      size: 'xl',
    });
}


  setModalTitle(str:string){
    this.modalTitle=str;
  }

  isRecentPing(lastPingAt: string): boolean {
    if (!lastPingAt) {
      return false;
    }
    const lastPingDate = new Date(lastPingAt);
    const now = new Date();
    const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000);

    return lastPingDate > fiveMinutesAgo;
  }


  getDailyProdData(){
    this.common.loading = true;
    let params={
      days:this.days
    };
    this.api.get('user-tracking/get-daily-prod-data',params).subscribe(
      (res:any)=>{
        this.common.loading = false;
        if(res.status){
          let data=res.data[0];
          this.dailyProductivity=data;
          console.log('this.dailyProductivity: ', this.dailyProductivity);
          this.areaChartData = [{
            data: this.dailyProductivity.map(item => ({ x: new Date(item.gt), y: item.productivity_percent })),
            label: 'Productivity%',
            fill: true,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 2
          }];
        }
      },
      (err:any)=>{
        console.error('Error: ', err);
      }
    );

  }


  getUserTrackingDashboardData() {
    this.common.loading = true;
    let params={
      days:this.days
    };
    this.api.get('user-tracking/get-user-tracking-data',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          let data = res.data[0];
          this.dailyProductivity=(data['daily_prod']);
          this.deparmentData = data['department_data'];
          this.zoneData = data['zone_data'];
          this.trackingData = data['tracking_data'];
          this.stageZoneData = data['zone_stage_data'];
          this.totalUserData=data['totalUser'];
          this.totalPlantUser=this.totalUserData.length;
          this.allUserType=data['allUserTypes'];
          this.pendingAlerts=data['pendingAlerts'];
          this.resolvedAlerts=data['ResolvedAlerts'];
          if(this.resolvedAlerts?.length>0){
          this.resolvedAlerts.forEach((data)=>{
            data.remark_data=JSON.parse(data.remark_data);
          })}
          this.newDeptData=data['new_department_data'];
          this.stageUsersCount=data['stageUsersCount'];
          this.newZoneAllocationDate=data['new_zone_allocation_data'];
          console.log('this.stageUsersCount: ', this.stageUsersCount);
          console.log('this.newDeptData: ', this.newDeptData);
          console.log('this.resolvedAlerts: ', this.resolvedAlerts);
          console.log('this.totalPlantUser: ', this.totalPlantUser);
          console.log('this.totalUserData.length: ', this.totalUserData.length);
          this.areaChartData = [{
            data: this.dailyProductivity.map(item => ({ x: new Date(item.gt), y: item.productivity_percent })),
            label: 'Productivity%',
            fill: true,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 2
          }];
          console.log(this.areaChartData,"this,areachart data data")
          this.setDashboardData(data);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
    );
  }

  setDashboardData(data: any) {
    let users = [];
    let userNameData:any={};
    let stageZoneTemp = data['zone_stage_data'];

    //creating object {dep_id:value}
    let groupByIdDeparment = {};
    this.deparmentData.forEach((element) => {
      element.productivity = 0;
      element.users = [];
      groupByIdDeparment[element.dept_id] = element;
    });

    //creating object {zone_id:value}
    let groupByIdZone = {};
    this.zoneData.forEach((element) => {
      element.present_user = 0;
      element.unauthorized_movement = 0;
      element.user_data = {};
      element.productivity = 0;
      element.active_user=[];
      element.new_productivity=0;
      groupByIdZone[element.id] = element;
    });
    console.log('groupByIdZone: ', groupByIdZone);

    let activeUsers = 0;

    //creating list of all Users from zone
    this.zoneData.forEach((data) => {
      users = [...users, ...data.users];
      userNameData={...userNameData,...data.user_name_data};
      //creating restriceted zone object {zone_id:users_array}
      if (data.is_restricted_zone) {
        this.restricted_zone[data.id] = data.users;
      }
    });
    console.log('userNameData: ', userNameData);
    console.log('this.zoneData: ', this.zoneData);
    console.log('this.restricted_zone: ', this.restricted_zone);
    let hardwareData = {};
    this.total_user_card.total_user = new Set(users).size; //Cast to Set to remove duplicate users from other zone

    this.trackingData.forEach((userData) => {

      //initial productivity of user
      //userData['total_productivity'] = 0;

      //active user count
      if (userData.is_active){
        activeUsers++;
        delete userNameData[userData.user_id];
        console.log('userNameData: ', userNameData);
        console.log('userNameData: ', delete userNameData[userData.user_id]);
      }

      //visitor user count
      if (userData.user_type == 'Visitor') {
        this.detail_info_card.total_visitors++;
      }

      // to avoid operation on same zone
      let zoneReaded = [];

      //to avoid operation on same user
      let userReaded = [];
      let stageUserData: any;
      let unauthorisedUser: boolean = false;
      let hardwareId = null;
      let count=0;

       //unauthorized movement
       if (this.restricted_zone.hasOwnProperty(userData.last_ping.last_ping_zone_id) && !this.restricted_zone[userData.last_ping.last_ping_zone_id].includes(userData.user_id)) {

        // to discuss
        if(this.isRecentPing(userData.last_ping.last_ping_at)){
         count++;
         this.detail_info_card.unauthorized_movement++;

        // for over all plant unauthorised users
         let data=groupByIdZone[userData.last_ping.last_ping_zone_id]['user_data']['Unauthorised']==null?{ count: 1, users: [userData] } : groupByIdZone[userData.last_ping.last_ping_zone_id]['user_data']['Unauthorised'];
         if (groupByIdZone[userData.last_ping.last_ping_zone_id]['user_data']['Unauthorised'] != null) {
          data.count++;
          data.users.push(userData);
        }
          groupByIdZone[userData.last_ping.last_ping_zone_id]['user_data']['Unauthorised'] = data;


         //  this.detail_info_card.unauthorisedData.push(userData);
         groupByIdZone[userData.last_ping.last_ping_zone_id]['active_user'].push(userData);

         // for showing zone wise unauthorised
         groupByIdZone[userData.last_ping.last_ping_zone_id]['unauthorised_user']==null?groupByIdZone[userData.last_ping.last_ping_zone_id]['unauthorised_user']=1:groupByIdZone[userData.last_ping.last_ping_zone_id]['unauthorised_user']++;


         console.log('this.detail_info_card.unauthorized_movement: ', this.detail_info_card.unauthorized_movement);
         unauthorisedUser = true;
        }
       }

      // userData['total_productivity']=0;
      if(this.isRecentPing(userData.last_ping.last_ping_at)) hardwareId = userData.last_ping?.last_ping_hardware_id;


      userData.zone_data.forEach((userZoneData) => {

        //setting last detected zone for user

        if (userData.last_ping?.last_ping_at == userZoneData.last_log) {
          userData.last_tracked_zone_id = userZoneData.zone_id;
          userData.last_tracked_zone_name = userZoneData.zone_name;
        }
        stageUserData = {
          user_name: userData.user_name,
          user_id: userData.user_id,
          zone_id: userZoneData.zone_id,
          zone_name: userZoneData.zone_name,
          productive_hours: userZoneData.productive_hours,
          // total_productivity: userZoneData.productive_percentage,
          stage_id: userZoneData.stage_id,
          stage_name: userZoneData.stage_name,
          user_type: userData.user_type,
          last_ping: userData.last_ping,
          last_tracked_zone_id: userData.last_ping.last_ping_zone_id,
          last_tracked_zone_name: userData.last_ping.last_ping_zone_name,
          is_active:userData.is_active,
          productivity_percent:userData.productivity_percent
        };

        if (userZoneData.is_active) {
          //user data zone stage wise

          if (!userReaded.includes(userData.user_id) && !zoneReaded.includes(userZoneData.zone_id)) {
            userReaded.push(userData.user_id);
            zoneReaded.push(userZoneData.zone_id);
            groupByIdZone[userZoneData.zone_id]['present_user'] = groupByIdZone[userZoneData.zone_id]['present_user'] + 1;


            if (groupByIdZone[userZoneData.zone_id]['user_data'][userData.user_type] == null &&
             groupByIdZone[userZoneData.zone_id]['users'].includes(userData.user_id)) {

              groupByIdZone[userZoneData.zone_id]['user_data'][userData.user_type] = { count: 1, users: [stageUserData] };
              delete groupByIdZone[userZoneData.zone_id]['user_name_data'][userData.user_id];
              groupByIdZone[userZoneData.zone_id]['active_user'].push(stageUserData);


            }
            else if (groupByIdZone[userZoneData.zone_id]['users'].includes(userData.user_id)) {

              let usertypeData = groupByIdZone[userZoneData.zone_id]['user_data'][userData.user_type];
              usertypeData.count++;
              usertypeData.users.push(stageUserData);
              groupByIdZone[userZoneData.zone_id]['user_data'][userData.user_type] = usertypeData;
              delete groupByIdZone[userZoneData.zone_id]['user_name_data'][userData.user_id];
              groupByIdZone[userZoneData.zone_id]['active_user'].push(stageUserData);


            } else {

              let data = groupByIdZone[userZoneData.zone_id]['user_data']['Unauthorised'] == null ? { count: 1, users: [stageUserData] } : groupByIdZone[userZoneData.zone_id]['user_data']['Unauthorized'];
              groupByIdZone[userZoneData.zone_id]['active_user'].push(stageUserData);
              if (groupByIdZone[userZoneData.zone_id]['user_data']['Unauthorised'] != null) {
                data.count++;
                data.users.push(stageUserData);
              }
              groupByIdZone[userZoneData.zone_id]['user_data']['Unauthorised'] = data;
              groupByIdZone[userZoneData.zone_id]['unauthorized_movement'] = groupByIdZone[userZoneData.zone_id]['unauthorized_movement'] + 1;
            }
          }



        }
        console.log(groupByIdZone,"group by id ozne")


        if(!this.zoneprod[userZoneData.zone_id] && userData.user_type!='Visitor'){
          this.zoneprod[userZoneData.zone_id]=[];
        }
        if(userData.user_type!='Visitor'){
          this.zoneprod[userZoneData.zone_id].push( userZoneData.productive_percentage);
        }

        console.log(this.stageprod,'stageprod');
        console.log(this.zoneprod,'zoneprod');

      });

      if(userData.is_active==true){
                console.log('this...',userData.user_id)
                this.total_user_card.present_user++;
                this.onDutyData.push(userData);
                this.totalUserData.forEach((data,index)=>{
                if(data.id==userData.user_id){
                 this.totalUserData.splice(index,1);
                }});
      }


      console.log('hardwareId: ', hardwareId,userData.user_id);
      if(hardwareId != null) {
        if(hardwareData[hardwareId]==null){
          hardwareData[hardwareId]=[userData]
        }
        else{
          hardwareData[hardwareId].push(userData);
        }
      }
       console.log("hardwareData",hardwareData)

      // is active chaeck lagana hai.
      console.log('this.detail_info_card.visitorData: ',userData.user_type == 'Visitor', this.detail_info_card.visitorData);
      if (userData.user_type == 'Visitor') {
        this.detail_info_card.visitorData.push(userData);
      }


      //departmentData
      console.log('groupByIdDeparment: ', groupByIdDeparment);
      userData.department.forEach((department) => {

        if(!this.deptprod[department] && userData.user_type!='Visitor'){
          this.deptprod[department]=[];
        }
        if(userData.user_type!='Visitor'){
          this.deptprod[department].push(userData.productivity_percent);
        }
      console.log('department: ', department);
      console.log('groupByIdDeparment[department]: ', groupByIdDeparment[department]);
      console.log('groupByIdDeparment[department] ', groupByIdDeparment[department]['users']);
          if (groupByIdDeparment[department]['users'] == null|| groupByIdDeparment[department]['users'].length==0) {
            groupByIdDeparment[department]['users'] = [userData];
            groupByIdDeparment[department]['productivity'] = userData['productivity_percent'];

            console.log('groupByIdDeparment[department]]: ', groupByIdDeparment[department]['productivity']);
          } else {
            groupByIdDeparment[department]['users'].push(userData);
            groupByIdDeparment[department]['productivity'] = this.average(groupByIdDeparment[department]['productivity'], userData['productivity_percent']);
            if(!this.deptprod[department]){
              this.deptprod[department]=[];
            }
          // }
          console.log('groupByIdDeparment[department]]: ', groupByIdDeparment[department]['productivity']);
          console.log('deptprod',this.deptprod);

        }

      });


      userData['unauthorised_count']=count;
      if (unauthorisedUser) {
        this.detail_info_card.unauthorisedData.push(userData);
        console.log('this.detail_info_card.unauthorisedData: ', this.detail_info_card.unauthorisedData);
      }

    });

    this.total_user_card.outside_user = this.totalPlantUser - this.total_user_card.present_user;
    this.total_user_card.percentage = (this.total_user_card.present_user * 100) / this.totalPlantUser;

    this.total_user_card.outsideData=this.totalUserData;

    this.deparmentData = this.productivitySorter(groupByIdDeparment, 'productivity','desc');
    console.log('this.deparmentData: ', this.deparmentData);

    this.trackingData = lodash.sortBy(this.trackingData, 'productivity_percent','asc');
    this.worstProductiveUser = this.trackingData.filter(data=> new Date(data.last_ping?.last_ping_at).toDateString()==new Date().toDateString()).slice(0,10);
    console.log('this.trackingData: ', this.trackingData,this.worstProductiveUser);
    this.zoneData = groupByIdZone;
    for (const zoneId in this.zoneData) {
      if (this.zoneData.hasOwnProperty(zoneId)) {
        const zone = this.zoneData[zoneId];

        // Iterate over each user type in allUserTypes
        console.log('this.allUserType: ', this.allUserType);
        this.allUserType.forEach(userType=>{
          if(!zone.user_data.hasOwnProperty(userType)){
            console.log('zone.user_data[userType]: ', zone.user_data[userType]);
            zone.user_data[userType] = { count: 0, users: [] };
          }
          if(!zone.user_data.hasOwnProperty('Unauthorised')){
            zone.user_data['Unauthorised']={count:0,users:[]};
          }
        })
      }
    }
    for(const zoneId in this.zoneData){
      const zone=this.zoneData[zoneId];
      this.allUserType.forEach(userType=>{
        if(!zone.user_data.hasOwnProperty(userType)){
          zone.user_data[userType]['count']=0;
          zone.user_data[userType]['users']=[];
        }
      })
    }
    this.newZoneAllocationDate=_.groupBy(this.newZoneAllocationDate,'zone_id');
    console.log(this.newZoneAllocationDate,'this.newZoneAllocationDate')
    Object.keys(this.zoneprod).forEach((zone_id)=>{
      console.log('zone_id: ', zone_id);
      console.log('this.zoneData[index][zone_id]',this.zoneData[zone_id],this.newZoneAllocationDate[zone_id],this.zoneData[zone_id]['new_productivity']);
      this.zoneData[zone_id]['new_productivity']=(_.sum(this.zoneprod[zone_id]))/(this.newZoneAllocationDate[zone_id][0]['users'].length>0?this.newZoneAllocationDate[zone_id][0]['users'].length:1);
      })
    Object.keys(this.deptprod).forEach((dept_id)=>{
    console.log('dept_id: ', dept_id);
    this.deparmentData.forEach((data,index)=>{
      if(data.dept_id==dept_id){
        console.log('data.dept_id: ', data.dept_id);
        console.log('this.newDeptData.filter(e=>e.dept_id==data.dept_id): ', this.newDeptData.filter(e=>e.dept_id==data.dept_id));
        let usersLength = this.newDeptData.filter(e=>e.dept_id==data.dept_id)[0].users.length;
        this.deparmentData[index]['new_productivity']=(_.sum(this.deptprod[dept_id]))/(usersLength>0?usersLength:1);
        console.log('this.deparmentData[dept_id] ',this.deparmentData[index]);
        }
      })
    })

    // this.getGeoFence(hardwareData);
    console.log('this.zoneData: ', this.zoneData,groupByIdZone);
    this.worstzoneData=lodash.sortBy(this.mapToArray(this.zoneData),'new_productivity','asc') as any[];
    console.log('this.worstzoneData: ', this.worstzoneData);

    this.updateDonutChart('#specificChart',this.dailyProductivity[this.dailyProductivity.length-1].productivity_percent, true);
  }




  productivitySorter(unsortedObject: any, sortingKey: string,order: "asc" | "desc") {
    let data: any = this.mapToArray(unsortedObject);
    return lodash.orderBy(data, (item) => {
      //setting default value 0
      return lodash.get(item, sortingKey) == undefined ? 0 : lodash.get(item, sortingKey);
    }, order);
  }


  mapToArray(object: any) { let data: any = Object.values(object); return data }

  returnMapKeyValue(object: any, keyname: string) { return object[keyname] }

  average(...values: any[]): number {
    const sum = values.reduce((a, b) => a + b, 0);
    return sum / values.length;
  }

  updateDonutChart(el, percent, donut) {
    percent = Math.round(percent);
    if (percent > 100) { percent = 100; }
    else if (percent < 0) { percent = 0; }

    var deg = Math.round(360 * (percent / 100));

    if (percent > 50) {
      $(el + ' .pie').css('clip', 'rect(auto, auto, auto, auto)');
      $(el + ' .right-side').css('transform', 'rotate(180deg)');
    } else {
      $(el + ' .pie').css('clip', 'rect(0, 1em, 1em, 0.5em)');
      $(el + ' .right-side').css('transform', 'rotate(0deg)');
    }

    if (donut) {
      $(el + ' .right-side').css('border-width', '0.1em');
      $(el + ' .left-side').css('border-width', '0.1em');
      $(el + ' .shadow').css('border-width', '0.1em');
    } else {
      $(el + ' .right-side').css('border-width', '0.5em');
      $(el + ' .left-side').css('border-width', '0.5em');
      $(el + ' .shadow').css('border-width', '0.5em');
    }

    $(el + ' .num').text(percent);
    $(el + ' .left-side').css('transform', 'rotate(' + deg + 'deg)');
  }

    viewAlert(alertData,alert_condition,type_name,alert_name,alert) {
      console.log("alertData",alertData)
      let data = JSON.parse(alertData);
      const activeModal = this.modalService.open(AlertCardModalComponent, {
        size: 'xl',
      });
      activeModal.componentInstance.refData = {
        alert_type_name: data.title,
        alert_remark:data.remark,
        alert_data: data.data,
        showAlertData : true,
        alert_condition: alert_condition,
        type_name:type_name,
        zone:true,
        alert_name:alert_name,
        alert:alert
      };
    }


  // from here map view

  allGeoFence: any;
  modalDetailTitle: string = '';
  detailData: any[] = [];
  detailAllData: any[] = [];
  detailType: any;
  path: any[];
  isTotalUserData:boolean=false;

  getGeoFence(hardwareData:any) {
    this.common.loading = true;
    let apiUrl = 'geofence/get-plant-geofence-by-processId';
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.allGeoFence = res.data || [];
        if (this.allGeoFence.length) {
          this.allGeoFence.forEach((element) => {
            element.plantLatLong = this.getSiteGeoFenceLatLong(element,hardwareData);
          });
          console.log('this.allGeoFence: ', this.allGeoFence);

          // setTimeout(() => {
            this.viewGeoFence(this.allGeoFence[0],hardwareData);
          // }, 2000);
          console.log('viewGeoFence');

        } else {
          this.mapService.clearAll();
        }
      },
      (err) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  ngAfterViewInit() {
    console.log("inside")
    this.mapService.mapIntialize('map-1');
    console.log("map initialised")
      setTimeout(() => {
        this.mapService.setMapType(0);
      }, 2000);
  }

    viewGeoFence(row: any, hardwareData:any,event = false) {
      let latLong = { lat: row.lat, lng: row.long };
      this.clearMapServices();
      console.log("cleared")
      this.mapService.centerAt(latLong);
      console.log("centre")
      console.log("zoom at")
      this.mapService.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      console.log("maptype")
      this.LoadGeoFence(row.plantLatLong, event);
      console.log("load")
      let plantGeoFence = row.plantLatLong.find((item) => item.type == 5).data;
      console.log("plantGeofence")
      plantGeoFence.forEach((element) => {
        this.mapService.setBounds(
          this.mapService.createLatLng(element.lat, element.lng)
        );
      });
      this.mapService.zoomAt(latLong, 12);
      console.log("set latlng")
      let thisClass = this;
      this.mapService.markers.forEach((marker: any) => {
        this.mapService.addListerner(marker, 'click', function (event: any) {
          console.log('marker: ', marker);
          console.log("ejkhsfoihfoihfosho")
          this.mapDetailId = marker.id;
          this.mapDetailLabel = marker.label.text;
          thisClass.modalDetailTitle = marker.labelName;
          thisClass.detailData = row.plantLatLong.find(
            (item) =>
              item.id == this.mapDetailId && item.label == this.mapDetailLabel
          ).trips;
          thisClass.detailAllData = row.plantLatLong.find(
            (item) =>
              item.id == this.mapDetailId && item.label == this.mapDetailLabel
          ).trips;
          thisClass.detailType = marker.type;
          thisClass.tableModalData = marker.ref_data;
          thisClass.isTotalUserData=marker.ref_data===thisClass.totalUserData


          console.log('marker.ref_data: ', marker.ref_data);


          setTimeout(() => {
            let modal = document.getElementById('detailModal');
            modal.classList.add('d-block');
          }, 50);
        });
      });
    }

  closeModal(res: boolean) {
    let modal = document.getElementById('detailModal');
    modal.classList.remove('d-block');
    this.tableModalData=[];
  }


  LoadGeoFence(data, event = false) {
    let latLngsArray = [];
    if (data.length > 0) {
      latLngsArray.push(data);
      this.mapService.createPlantGeoFence(data, event, false);
    }
  }

  getSiteGeoFenceLatLong(data:any,user_data:any) {
    let latLongs = [];
    if (data.pzone && data.pzone.length) {
      data.pzone.forEach((zone) => {
      console.log('zone: ', zone);
        if (zone.pos) {
          latLongs.push({
            type: 5,
            label:`${zone.zone_name} (${(this.zoneData[zone.id]?.present_user??0)+(this.zoneData[zone.id]?.unauthorised_user??0)})`,
            lat: zone.lat,
            lng: zone.long,
            radius: zone.radius,
            data: this.getLatLongFromPolygon(zone.pos),
            id: zone.geofence_id,
            zoneId: zone.id,
            ref_data:this.zoneData[zone.id]?.active_user??[],
            labelName:`${zone.zone_name} (${(this.zoneData[zone.id]?.present_user??0)+(this.zoneData[zone.id]?.unauthorised_user??0)})`,
          });
        }
      });
    }

    if (data.hardware && data.hardware.length) {
      data.hardware.forEach((hardware) => {
        let user_count=user_data[hardware.ipa_hardware_id]!=null?user_data[hardware.ipa_hardware_id].length:0
        let hardware_user_data=user_data[hardware.ipa_hardware_id]!=null?user_data[hardware.ipa_hardware_id]:[]
        if (hardware.pos) {
          latLongs.push({
            type: 7,
            label: `(${user_count})`,
            ref_data:hardware_user_data,
            lat: hardware.lat,
            lng: hardware.long,
            radius: hardware.radius,
            data: this.getLatLongFromPolygon(hardware.pos),
            id: hardware.geofence_id,
            hardWareId: hardware.ipa_hardware_id,
            labelName:`${hardware.hardware_name}(${user_count})`
          });
        }
      });
    }
    console.log('latLongs: ', latLongs);
    return latLongs;
  }

  getLatLongFromPolygon(str) {
    let latLong = [];

    if (!str.includes('EMPTY') && !str.includes('OLLECTION EMP')) {
      let latLongString = String(str).split(',');
      latLongString.forEach((item) => {
        let latLongs = item.split(' ').map((item) => Number(item));
        latLong.push({ lat: latLongs[0], lng: latLongs[1] });
      });
    }
    return latLong;
  }
  clearMapServices() {
    this.mapService.clearAll();
    this.mapService.resetMarker();
    this.mapService.resetPolygons();
    this.mapService.clearAll();
    this.mapService.resetMarker();
    this.path = [];
  }

  calculateProductivityPercentage(shift_hours:string,user_hours:string){
    let spentTimeInSeconds = this.parseTimeToSeconds(user_hours);
    let shiftDurationInSeconds = this.parseTimeToSeconds(shift_hours);
    let percentage = (spentTimeInSeconds / shiftDurationInSeconds) * 100;
    return percentage.toFixed(2)
  }

  parseTimeToSeconds(time: string): number {
    let [hours, minutes, seconds] = time.split(':').map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
}

}

import { Component, Input, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-time-counter',
  templateUrl: './time-counter.component.html',
  styleUrls: ['./time-counter.component.scss'],
})
export class TimeCounterComponent implements OnInit {
  // @Input() targetTime: string;
  @Input() targetDate:string;
  duration: string = '00:00:00';
  flag: Boolean = false;
  // intervalFirst: number = 1000;
  private subscription: Subscription;

  ngOnInit(): void {
    const targetDateTime = new Date(this.targetDate);
    this.updateDuration(targetDateTime);

    // Use a consistent interval of 1 second
    this.subscription = interval(1000).subscribe(() => {
      // this.intervalFirst=1000;
      this.updateDuration(targetDateTime);
    });
    // const targetDateTime = new Date();
    // let combinedDate = `${this.targetDate}T${this.targetTime}:00`;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateDuration(targetDateTime) {
    // const [targetHours, targetMinutes] = this.targetTime.split(':').map(Number);
    // targetDateTime.setHours(targetHours, targetMinutes, 0, 0);

      const currentTime = new Date();
      // targetDateTime.getTime() > currentTime.getTime()
      if (targetDateTime > currentTime) {
        // interval(this.intervalFirst).subscribe(() => {
        const durationMs = targetDateTime.getTime() - currentTime.getTime();
        const duration = new Date(durationMs);
        this.duration = this.formatDuration(duration);
        // });
      } else {
        this.duration = 'Overdue';
        this.flag = true;
      }
  }

  private formatDuration(duration: Date): string {
    const hours = this.padNumber(duration.getUTCHours());
    const minutes = this.padNumber(duration.getUTCMinutes());
    const seconds = this.padNumber(duration.getUTCSeconds());

    return `${hours}:${minutes}:${seconds}`;
  }

  private padNumber(value: number): string {
    return value.toString().padStart(2, '0');
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import * as _ from 'lodash';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-loader-view',
  templateUrl: './loader-view.component.html',
  styleUrls: ['./loader-view.component.scss'],
})
export class LoaderViewComponent implements OnInit {
  activeSection: any = 0;
  term: string;
  loaderViewData: any[] = [];
  tabData: any[] = [];
  selectedProcessId: any = -1;
  selectedProcess: any = 'All Processes';
  vehicleCount: any = 0;
  loaderViewFilteredData: any[] = [];
  loaderViewAllData: any[] = [];
  allData: any[] = [];
  plantData: any = [];
  plantId: number = null;
  @Input() refData: any;

  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    public apiservice: ApiService,
    private alert: AlertService,
    private breadcrumbService: BreadcrumbService,
    public plantService: PlantService,
    public role: RoleService,
    public userService: UserService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Plant', url: null },
      { name: 'Plant Loader View', url: '/user/loader-view' },
    ];
    this.breadcrumbService.setActiveBreadCrumb(false);
  }

  ngOnInit(): void {
    if (this.role.user_role == 'COMPANYADMIN') {
      if (this.refData != null) {
        this.plantId = parseInt(localStorage.getItem('selectedPlantId'));
        this.getTabData();
      } else {
        this.getPlantById(this.userService._loggedInUser.companyId);
      }
    } else {
      this.getTabData();
    }
  }

  selectedTab(item: any) {
    this.activeSection = item.stage_id;
    this.selectedProcess = item.process_name;
    this.selectedProcessId = item.process_id;
    this.getLoaderView(this.selectedProcessId);
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.getTabData();
  }

  getLoaderView(processId: any) {
    this.common.loading = true;
    let params = {
      plantId: this.plantId,
      processId: this.selectedProcessId,
    };
    this.apiservice.get('plant/get-stage-inbetween-vehicle', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.loaderViewData = _.cloneDeep(res.data);
        this.allData = _.cloneDeep(res.data);
        this.vehicleCount = this.loaderViewData.reduce((accumulator, element) => {
          return accumulator + element.vehicleCount;
        }, 0);
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  getTabData() {
    this.common.loading = true;
    let params = {
      plantId: this.plantId,
    };
    this.apiservice
      .get('plant/get-process-list-for-loaderview', params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.tabData = res.data;
          this.tabData.unshift({
            process_id: -1,
            process_name: 'All Processes',
          });
          if (this.tabData.length) {
            let first = this.tabData[0];
            this.activeSection = first.process_id;
            this.selectedProcess = first.process_name;
            this.selectedProcessId = first.process_id;
            this.getLoaderView(this.selectedProcessId);
          } else {
            this.alert.error('Processes Not Found');
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          //
        }
      );
  }

  searchVehicle(searchText: string) {
    if (!searchText.trim()) {
      this.loaderViewData = _.cloneDeep(this.allData);
    } else {
      this.loaderViewData.map((data) => {
        data.vehicleData = data.vehicleData.filter((vehicle) =>
          vehicle?.vehicle_no
            .toLowerCase()
            .includes(searchText.trim().toLowerCase())
        );
        return data;
      });
    }
  }

  tripModal(vehicle) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.transaction_id,
      processName: vehicle.process_name,
    };
    this.breadcrumbService.setActiveBreadCrumb(false);
  }

  tripTrackingModal(vehicle) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.transaction_id,
      vehicleName: vehicle.vehicle_no + '-' + vehicle.process_name,
    };
    this.breadcrumbService.setActiveBreadCrumb(false);
  }
}

<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{"Plant Geofence" | translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div id="mapp" style="width: 100%; height:75vh">
  </div>
</div>
<div class="modal-footer">

</div>


<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{('alert'|translate)+' '+('report'|translate)}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="page_design">
                <div class="tab_sec">
                  <div class="date_div">
                    <div class="row">
                      <div class="col-md-2 date" *ngIf="role.user_role=='COMPANYADMIN'">
                        <label>{{'plant'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                          placeholder="Choose Plant">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 date">
                        <label>{{'start_date:'|translate}}</label>
                        <input type="date" class="form-control" [(ngModel)]="startDate">
                      </div>
                      <div class="col-md-3 date">
                        <label>{{'end_date:'|translate}}</label>
                        <input type="date" class="form-control" [min]="startDate" [(ngModel)]="endDate">
                      </div>
                      <div class="col-md-1 btn-block text-right">
                        <button class="btn btn-primary" (click)="onDateChange()">{{"search" | translate}}</button>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="view_table">
                  <div class="filter_sec">
                    <div class="row">
                      <div class="col-md-3 coll">
                        <label>{{'select_alert_type'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="onSelectAlertType($event)" [data]="alertTypeList"
                          display="type_name" placeholder="Select Alert Type" [preSelected]="{type_name:alertTypeName}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'select_alert_entity'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="onSelectAlertEntity($event)" [data]="alertEntityList"
                          display="name" placeholder="Choose Alert Entity" [preSelected]="{name:alertEntity}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'select_alert_condition'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="onSelectAlertCondition($event)" [data]="alertConditionsList"
                          display="value" placeholder="Choose Alert Condition" [preSelected]="{value:alertCondition}">
                        </ps-auto-suggestion>
                      </div>
                    </div>
                  </div>

                  <div class="form-group action-block mt-2 mb-2">
                    <div class="btn-block">
                      <button class="btn btn-warning" (click)="reset()"> {{'reset'|translate}} </button>
                    </div>
                  </div>

                  <div class="table_sec">
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{'serial_no' | translate}}</th>
                          <th>{{'date'|translate}}</th>
                          <th *ngFor="let item of headers">
                            {{item.status|translate}}</th>
                          <th>{{'total'|translate}}</th>
                          <th>{{'Action'|translate}}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let item of this.alertCountByStatus[0].alertreportdata, let i = index">
                          <td>{{i+1}}</td>
                          <td style="width:10%">{{item.date| date:'dd-MM-yyyy'}}</td>
                          <td style="font-size: 13px;"
                            *ngFor="let item of this.alertCountByStatus[0].alertreportdata[i].data">{{item.count}}</td>
                          <td>{{item.data[0].count + item.data[1].count + item.data[2].count +item.data[3].count}}</td>
                          <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-success" (click)="viewDetails(item)">{{"view_details" |
                                translate}}</button>
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from '../../@core/services/table.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { formTypes } from 'src/app/dataType/formInterface';
import { validator } from 'src/app/dataType/commonInterface';
@Component({
  selector: 'app-form-type-management',
  templateUrl: './form-type-management.component.html',
  styleUrls: ['./form-type-management.component.scss']
})
export class FormTypeManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 3, 'Action Dashboard');
  formTypesData: formTypes[] = [];
  formTypeName:string;
  formStatus:string = "ACTIVE";
  id:number = null;
  formTypeNameValidator: validator;
  formStatusValidator: validator;
  constructor(
    public alertService: AlertService,
    public common: CommonService,
    private modalService: NgbModal,
    public api: ApiService,
    private table: TableService,
    private breadcrumbService:BreadcrumbService
  ) {
  }

  ngOnInit(): void {
    this.getAllFormTypes();
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'form', url: null },
      {
        name: 'Form Type Management',
        link: null,
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getAllFormTypes() {
    let params ={
      isActive:false
    }
    this.common.loading = true;
    this.api
      .get('form/get-all-form-types',params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if(res.status){
          this.formTypesData = res.data;
          this.renderTable();
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  save() {
    console.log(this.formTypeName.trim())
    let body = {
      id:this.id,
      formTypeName: this.formTypeName.trim(),
      status: this.formStatus,
    };
    this.common.loading = true;
    this.api.post('form/add-form-type', body).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.getAllFormTypes();
        }else{
          this.alertService.error(res.message);
        }
        this.reset();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  reset() {
    this.id = null;
    this.formTypeName = null;
    this.formTypeNameValidator = null
    this.formStatus = "ACTIVE";
  }

  viewFormType(item: any) {
    let updatedItem = {
      formType : item.form_type_name,
      formStatus :item.status
    }
    this.common.params = {
      details: [updatedItem],
      title: 'Form Type Details',
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  editFormType(item: any) {
    this.common.gotoTop();
    this.reset();
    this.id = item.id;
    this.formTypeName = item.form_type_name;
    this.formStatus = item.status;
}

  confirmAlert(item?: any) {
    this.common.params = {
      title: 'Delete Form Type',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.id = item.id;
        this.deleteFormTypeMethod();
      }
    });
  }

  deleteFormTypeMethod() {
    let params = {
      id:this.id
    };
    this.common.loading = true;
    this.api.get('form/delete-form-type',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.getAllFormTypes();
        }else{
          this.alertService.error(res.message)
        }
        this.reset();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  formValidator() {
    this.formTypeName = this.formTypeName? this.formTypeName.trim() : this.formTypeName;
    this.formTypeNameValidator = this.common.nullValidator(
      this.formTypeName,
      'Form Type Name Mandatory'
    );

    if (
    !this.formTypeNameValidator.error
    ) {
      this.save();
    }
  }
}

<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Compliance Severity Mapping" | customTranslate}}
                </h3>
              </div>
              <div class="col-md-5d-flex pull-right" class="ml-2"
                *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN' ">

                <mat-form-field appearance="fill" class="mr-4" *ngIf="role.user_role == 'AXESTRACK'">
                  <mat-label>{{"select_company" | customTranslate}}</mat-label>
                  <mat-select [(ngModel)]="companyId">
                    <mat-option *ngFor="let  item1 of companyData;index as i" (click)="selectedCompany(item1)"
                      [value]="item1.companyId">
                      {{item1.companyName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill">
                  <mat-label>{{"select_plant" | customTranslate}}</mat-label>
                  <mat-select [(ngModel)]="plantId">
                    <mat-option *ngFor="let item of plantData;index as i" (click)="selectedPlant(item)"
                      [value]="item.plantId">
                      {{item.plantName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{'Compliance Condition'|customTranslate}}</label>
                  <ps-auto-suggestion *ngIf="complianceList.length != 0" (onSelected)="selectedComplianceConditon($event,'select')" (onChange)="selectedComplianceConditon($event,'input')" class="required" [data]="complianceList"
                    display="title" placeholder="Select Compliance Title" [preSelected]="{title:selectedConditionName}">
                  </ps-auto-suggestion>
                  <input type="text" class="required field_class" [(ngModel)]="selectedConditionName" placeholder="Select Compliance Title"
                  *ngIf="complianceList.length == 0">
                  <div class="error" *ngIf="complianceValidator && complianceValidator.error">
                    <span>{{ complianceValidator.msg }} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">Severity Type</label>
                  <ps-auto-suggestion (onSelected)="selectedSeverity($event)" class="required" [data]="alertTypeList"
                    display="type_name" placeholder="Select Severity" [preSelected]="{type_name:selectedAlertType}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="alertTypeValidator && alertTypeValidator.error">
                    <span>{{ alertTypeValidator.msg }} </span>
                  </div>
                </div>
              </div>
              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    customTranslate}}</button>
                  <button class="btn btn-warning" (click)="reset()"> {{ "reset" | customTranslate }} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()">
                    {{ buttonName | customTranslate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{ "Compliance Severity Mapping List" | customTranslate }}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="table_sec" style="overflow: scroll;">
                <app-custom-table [data]="mappingList" [columns]="tableColumns"  [tableType]="TABLE_TYPE"></app-custom-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

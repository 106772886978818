<div class="sidebar" id="sidebar">
  <div class="nav-toggler" id="nav-toggler" (click)="menuToggle()">
    <div class="icon">
      <i class="fa fa-angle-left"></i>
    </div>
  </div>

  <ul class="sidebar-menu" id="sidebar-menu" (mouseover)="hovertoggle(false)" (mouseout)="hovertoggle(false)" (mouseleave)="hovertoggle(true)">
    <li class="treeview" [ngClass]="{'active': activeMenuIndex === i}" *ngFor="let menu of menu; let i = index"
      (click)="menuSelection(i)">
      <a href="javascript:void(0)" [ngClass]="{'boop': isBoop == i}" (mouseover)="isBoop = i"
        (animationend)="isBoop = -1">
        <img *ngIf="menu.img" [src]="menu.img" alt="">
        <i *ngIf="menu.icon" [class]="menu.icon"></i>
        <span>{{menu.name | translate}}</span>
        <i class="toggle fa fa-angle-right" aria-hidden="true"></i>
      </a>
      <ul class="treeview-menu" *ngIf="menu.childs.length && activeMenuIndex === i &&  common.menuToggled">
        <li *ngFor="let child of menu.childs; index  as m" ><a
            [routerLink]="child.link" (click)="activeMenu(m);$event.stopPropagation();" [class]="activeSubmenuIndex ==
            m ? 'active':null"> <i
              class="far fa-dot-circle" aria-hidden="true"></i> {{child.name|translate}}</a></li>
      </ul>
    </li>
  </ul>





</div>

import { AlertService } from './../../theme/layout/header/alert-message/alert.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { ProcessService } from '../process/process.service';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { TableService } from '../../@core/services/table.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
@Component({
  selector: 'app-destination-stage-mapping',
  templateUrl: './destination-stage-mapping.component.html',
  styleUrls: ['./destination-stage-mapping.component.scss'],
})
export class DestinationStageMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(
    10,
    4,
    'Stage Destination Code Occupancy List'
  );
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  selectedOrder: any[] = [];
  process: any[] = [];
  processName: any[] = [];
  buttonName = 'save';
  stageDestinationCodeList: any = [];
  destinationCodes: any = [];
  destinationCode: any = null;
  selectedDestinationId: any = null;
  mappings: any[] = [];
  destinationCodeValidator: any = null;
  processStageValidator: any = null;

  constructor(
    public breadcrumbService: BreadcrumbService,
    private processService: ProcessService,
    public common: CommonService,
    public table: TableService,
    private api: ApiService,
    private modalService: NgbModal,
    private alert: AlertService,
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Destinations', url: null },
      {
        name: 'Destination Stage Mapping',
        link: '/user/destination-stage-mapping',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
    this.getAllProcess();
    this.getAllDestinationCodes();
    this.getAllDestinationStageMappings();
  }

  getAllProcess() {
    this.common.loading = true;
    this.processService.getAllProcessStage().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.process = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedProcess(event: any) {
    this.processName = event;
  }

  updatedTat(event: any, i) {
    this.processName[i] = {...this.processName[i],"tat":event.target.value};
  }

  getAllDestinationCodes() {
    this.common.loading = true;
    this.api
      .get('destination/list_stage_destination_code?allStatus=true')
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.destinationCodes = res.data;
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  selectedDestination(event: any) {
    this.selectedDestinationId = event.stage_destination_code_id;
    this.destinationCode = event.stage_destination_code;
    this.processName = this.mappings.filter(res=>res.stage_destination_code_id == this.selectedDestinationId)[0].mapping_data
  }

  getAllDestinationStageMappings() {
    this.common.loading = true;
    this.api.get('destination/list_destination_stage_mappings').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.mappings = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  reset() {
    this.buttonName = 'save';
    this.destinationCode = null;
    this.selectedDestinationId = null;
    this.processName = [];
    this.destinationCode = null;
  }

  save(isDelete:boolean=false) {
    if(this.processName.length == 0) return this.alert.error('Please select Process->Stage...');
    let params = {
        destinationId: this.selectedDestinationId,
        processStagelist: this.processName,
        toDelete :isDelete
      };
    this.common.loading = true;
    this.api
      .post('destination/save_destination_stage_mapping', params)
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.buttonName = 'save';
            this.reset();
            this.getAllDestinationStageMappings();
            if (res.data.length) {
              this.alert.warn(res.message);
            } else {
              this.alert.success(res.message);
            }
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  view(row?: any) {
    let newObj = {
      Destination: row.stage_destination_code,
      ...row.mapping_data
    };
    this.common.params = {
      details: [newObj],
      title: 'Destination Stage Mapping Details',
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  edit(row: any) {
    this.reset();
    this.buttonName = 'update';
    this.destinationCodeValidator = null;
    this.processStageValidator = null;
    this.processName = row.mapping_data;
    this.selectedDestinationId = row.stage_destination_code_id;
    this.destinationCode = row.stage_destination_code;
     }

  confirmAlert(row?: any) {
      this.common.params = {
        title: 'Delete Destination Stage Mapping',
        description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
      };
      const activeModal = this.modalService.open(ConfirmationDialogComponent, {
        size: 'md',
      });
      activeModal.result.then((data: any) => {
        if (data.response) {
          this.processName = row.mapping_data;
          this.selectedDestinationId = row.stage_destination_code_id
          this.save(true);
        }
      });
  }

  formValidator() {
    this.destinationCodeValidator = this.common.nullValidator(
      this.selectedDestinationId,
      'Destination Code Mandatory'
    );
    this.processStageValidator = this.common.nullValidator(
      this.processName.length,
      'Process -> Stage Mandatory'
    );
    if(this.processName.filter(res=> res.tat == null).length > 0) return this.alert.error('Destination Stage TAT Cannot be Empty !!')

    if (
      !this.destinationCodeValidator.error &&
      !this.processStageValidator.error &&
      this.buttonName == 'save'
    ) {
      this.save()
    } else if (
      !this.destinationCodeValidator.error &&
      !this.processStageValidator.error &&
      this.buttonName == 'update'
    ) {
      this.save();
    }
  }
}

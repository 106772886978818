<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="container-card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="header-block">
                                        <h3 class="card-title">
                                            {{'Asset Request'|customTranslate}}
                                        </h3>
                                    </div>
                                    <button type="button" class="btn btn-primary" (click)="openAssetRequestModal()">
                                        {{'Add Request'|customTranslate}}
                                    </button>
                                </div>

                                <div class="card-body">
                                    <div class="tab_section">
                                        <div class="tab" [class.active]="activeTab==3"
                                            (click)="activeTab=3;getAssetRequests('PENDING');activeStatus='PENDING'">{{"Pending"
                                            | customTranslate}} ({{listActionCount['PENDING']}})</div>
                                        <div class="tab" [class.active]="activeTab==1"
                                            (click)="activeTab=1;getAssetRequests('APPROVED');activeStatus='APPROVED'">{{"Approved"
                                            |
                                            customTranslate}} ({{listActionCount['APPROVED']}})</div>
                                        <div class="tab" [class.active]="activeTab==2"
                                            (click)="activeTab=2;getAssetRequests('REJECTED');activeStatus='REJECTED'">{{"Rejected"
                                            |
                                            customTranslate}} ({{listActionCount['REJECTED']}})</div>
                                        <div class="tab" [class.active]="activeTab==7"
                                            (click)="activeTab=7;getAssetRequests('DELETED');activeStatus='DELETED'">{{"Decline"
                                            |
                                            customTranslate}} ({{listActionCount['DELETED']}})</div>
                                        <div class="tab" [class.active]="activeTab==4"
                                            (click)="activeTab=4;getAssetRequests('ASSIGNED');activeStatus='ASSIGNED'">{{"Assigned"
                                            |
                                            customTranslate}} ({{listActionCount['ASSIGNED']}})</div>
                                        <div class="tab" [class.active]="activeTab==5"
                                            (click)="activeTab=5;getAssetRequests('CHECKEDIN');activeStatus='CHECKEDIN'">{{"CheckedIn"
                                            |
                                            customTranslate}} ({{listActionCount['CHECKEDIN']}})</div>
                                        <div class="tab" [class.active]="activeTab==6"
                                            (click)="activeTab=6;getAssetRequests('CHECKEDOUT');activeStatus='CHECKEDOUT'">{{"CheckedOut"
                                            |
                                            customTranslate}} ({{listActionCount['CHECKEDOUT']}})</div>
                                    </div>

                                    <div class="tab_content">
                                        <div class="checklist_sec">
                                            <div class="row" *ngIf="activeTab == 5 || activeTab == 2 || activeTab == 7"
                                                style="margin-bottom: 15px;">
                                                <div class="col-md-3">
                                                    <label>{{'start_date:'|customTranslate}}</label>
                                                    <input type="date" class="form-control" [max]="endDate"
                                                         [(ngModel)]="startDate">
                                                </div>
                                                <div class="col-md-3">
                                                    <label>{{'end_date:'|customTranslate}}</label>
                                                    <input type="date" class="form-control"
                                                         [min]="startDate"
                                                        [(ngModel)]="endDate">
                                                </div>
                                                <div class="col-md-3" style="margin-top: 2%">
                                                    <button class="btn btn-primary" (click)="getAssetRequests(activeStatus)">{{"search" | customTranslate}}</button>
                                                </div>
                                            </div>
                                        </div>

                                        <table class="custom-table" datatable [dtOptions]="dtOptions"
                                            [dtTrigger]="dtTrigger">
                                            <thead>
                                                <tr>
                                                    <th>{{"s.no." | customTranslate}}</th>
                                                    <th>{{"from_date" | customTranslate}}</th>
                                                    <th>{{"to_date" | customTranslate}}</th>
                                                    <th>{{"Requesting For" | customTranslate}}</th>
                                                    <th>{{"priority" | customTranslate}}</th>
                                                    <th class="action">{{"action" | customTranslate}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let row of requests; let i = index">
                                                    <td>{{i+1}}</td>
                                                    <td>{{row.from_date|date:'dd-MM-yyyy'}}</td>
                                                    <td>{{row.end_date|date:'dd-MM-yyyy'}}</td>
                                                    <td>{{row.req_for_entity+' : '+row.ref_entity_name}}</td>
                                                    <td>
                                                        <span
                                                            [ngClass]="row.priority=='Low'? 'badge badge-pill badge-success':(row.priority=='Critical'?'badge badge-pill badge-danger':(row.priority=='High'?'badge badge-pill badge-warning':'badge badge-pill badge-primary'))">{{row.priority}}</span>
                                                    </td>
                                                    <td class="action">
                                                        <div class="btn-block">
                                                            <button class="btn btn-danger"
                                                                *ngIf="activeTab!=7 && activeTab!=5 && activeTab!=6 && activeTab!=2"
                                                                (click)="updateRequestStatus(row.asset_request_id)">
                                                                {{'Decline'|customTranslate}}
                                                            </button>
                                                            <button class="btn btn-success" (click)="viewRemarks(row)">
                                                                {{'View Details'|customTranslate}}
                                                            </button>
                                                            <button class="btn btn-info" (click)="linkCopy(row)" copyM>
                                                                {{'Copy'|customTranslate}}
                                                            </button>
                                                            <!-- <span class="fa fa-copy " (click)="linkCopy(row)">
                                                            </span> -->
                                                        </div>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </div>



</div>
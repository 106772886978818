<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{checklist_title}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="view_remark_page">
                <ng-container *ngIf="!rejectRemark;else rejectRemarks">
                <ng-container *ngFor="let item of actionsAndRemarks">
                  <div class="field_sec" *ngIf="((item.remark!=null && item.remark!='') || item.fileUrl!=null)">
                    <div class="field_title">
                      <span *ngIf="item.reference_type=='action'">{{item.reference_title}}</span>
                      <span *ngIf="item.reference_type=='other'">{{"Other"}}</span>
                    </div>

                    <div class="field_content">
                      <div class="image_class" *ngIf="item.fileUrl!=null">
                        {{imageName(item.fileUrl)}}
                        <div class="image" (click)="displayImage(item.fileUrl)">
                          <img [src]="item.fileUrl" alt="" style="width: 150px;">
                        </div>
                        <div style="font-size:small">{{" "+this.image_name}}</div>
                      </div>

                      <div class="textarea_class" *ngIf="item.remark!=null">
                        <div class="head">{{item.remark}}</div>
                      </div>
                    </div>

                  </div>
                  <!-- <ng-template #notFound>No remarks found!!</ng-template> -->
                </ng-container>
              </ng-container>
              <ng-template #rejectRemarks>
                <div class="field_sec">
                  <div class="field_title">
                    <span>Reject Remark</span>
                  </div>
                  <div class="field_content">
                    <div class="textarea_class">
                      <div class="head" [ngStyle]="{color : colorFlag?'red':'null'}">{{rejectMessage}}</div>
                    </div>
                  </div>

                </div>
              </ng-template>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

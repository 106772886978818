import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { MapModalComponent } from 'src/app/modals/map-modal/map-modal.component';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-public-field-report',
  templateUrl: './public-field-report.component.html',
  styleUrls: ['./public-field-report.component.scss'],
})
export class PublicFieldReportComponent implements OnInit {
  imgTypes: any[] = [
    'FILE',
    'GEOCODEDIMAGE',
    'BARCODEWITHIMAGE',
    'RFIDCODEWITHIMAGE',
    'SIGNATURE',
    'IMAGE'
  ];
  onlyImageType: any[] = ['FILE', 'SIGNATURE'];
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  tripId: any = null;
  vehicleId: any = null;
  dtTrigger: any = new Subject();
  fieldName: any = '';
  transactionVehicles: any = [];
  tempTxnList: any[] = [];
  modalData: any[] = [];
  filterReportByName: any = null;
  filterReportByCount: any = null;
  @Input() isPublic: any = true;
  reportData: any[] = [];
  fieldType: any = '';
  filterReportData: any[] = [];
  stages: any[] = [];
  dtOptions: any = this.table.options(10, 4, 'Action Dashboard');
  processList: any = [];
  tempProcessListData: any[] = [];
  processId: any = null;
  processName: any = null;
  plantData: any = [];
  plantId: any = null;

  constructor(
    public table: TableService,
    public common: CommonService,
    private modalService: NgbModal,
    private apiService: ApiService,
    private route: ActivatedRoute,
    public plantService: PlantService,
    public role: RoleService,
    public userService: UserService
  ) {}

  ngAfterViewInit() {
    if (
      (this.route.snapshot.queryParams.tripId && this.isPublic) ||
      (this.route.snapshot.queryParams.tripId && !this.isPublic)
    ) {
      this.tripId = this.route.snapshot.queryParams.tripId;
      this.getAllStagesByTxnId();
      this.getAuditReport();
    }
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  selectProcess(event: any) {
    this.processList.forEach((process) => {
      if (process == event) {
        this.processId = process.processId;
        this.processName = process.processName;
      }
    });
    this.tripId = null;
    this.vehicleId = null;
    this.getVehicleTransaction(1);
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnInit(): void {
    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.getPlantProcess();
      if (!this.isPublic && this.tripId == null) {
        this.getVehicleTransaction(0);
      }
    }
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.value;
    this.getPlantProcess();
  }

  getPlantProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.plantId,
    };
    this.apiService.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.processList = res.data;
          this.tempProcessListData = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getVehicleTransaction(flag: any) {
    this.common.loading = true;
    let params = {
      plantId: this.plantId,
      processId: flag == 0 ? null : this.processId,
      completedTripsFlag: true,
    };
    this.apiService
      .get('transaction/get-vehicle-transaction-list', params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.transactionVehicles = res.data;
          this.tempTxnList = res.data;
          let temp = null;
          if (this.tripId == null) {
            temp = res.data[0];
          } else {
            temp = res.data.filter(
              (d) => d.ipa_transaction_id == this.tripId
            )[0];
          }
          this.tripId = temp.ipa_transaction_id;
          this.vehicleId = temp.vehiclersn;
          this.getAllStagesByTxnId();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  contentModal(content, data: any) {
    this.modalData = data.perform_on;
    this.fieldType = data.field_type;
    this.fieldName = data.field_name;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  getAuditReport() {
    this.apiService
      .get('report/get-field-audit-report?tripId=' + this.tripId)
      .toPromise()
      .then((res: any) => {
        if (res.status) {
          this.reportData = res.data;
          this.filterReportData = res.data;
          console.log('this.filterReportData: ', this.filterReportData);
        }
      });
  }

  getAllStagesByTxnId() {
    this.apiService
      .get('report/get-process-stage-by-txn?txnId=' + this.tripId)
      .toPromise()
      .then((res: any) => {
        if (res.status) {
          this.stages = res.data;
          this.getAuditReport();
        }
      });
  }

  isImgTypeFile(imgUrl: any, fieldType: any) {
    if (this.onlyImageType.indexOf(fieldType) != -1) {
      const ext = imgUrl[0].substring(imgUrl[0].lastIndexOf('.') + 1);
      return ext == 'png' || ext == 'jpeg' || ext == 'jpg';
    } else {
      const ext = imgUrl[0].fileUrl.substring(
        imgUrl[0].fileUrl.lastIndexOf('.') + 1
      );
      return ext == 'png' || ext == 'jpeg' || ext == 'jpg';
    }
  }
  getImageName(imgUrl: string) {
    const filename = imgUrl[0].substring(imgUrl[0].lastIndexOf('/') + 1);
    return filename;
  }

  filterReport() {
    this.filterReportData = this.reportData.filter((data) => {
      let performOnCount = data.perform_on.filter((el) => {
        return el[Object.keys(el)[0]] != '-';
      }).length;
      return (
        (performOnCount == this.filterReportByCount ||
          this.filterReportByCount == null ||
          this.filterReportByCount == '') &&
          (this.filterReportByName == null ||
            this.filterReportByName.toLowerCase() ==
            data.field_name.toLowerCase() ||
            this.filterReportByName == '')
          );
        });
      console.log('this.filterReportData after : ', this.filterReportData);
  }
  resetReport() {
    this.filterReportByCount = null;
    this.filterReportByName = null;
    this.filterReportData = this.reportData;
  }

  openMap(lat, long) {
    this.common.params = {
      lat: lat,
      lng: long,
    };
    const activeModal = this.modalService.open(MapModalComponent, {
      size: 'lg',
    });
  }

  displayImage(url) {
    let newUrl = decodeURI(typeof url == 'string' ? url : url.fileUrl);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: typeof url == 'string' ? url : url.fileUrl,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }
  filterProcess(event: any) {
    this.processList = this.tempProcessListData.filter((p) =>
      p.processName.toLowerCase().includes(event.trim().toLowerCase())
    );
  }

  filterTxn(event: any) {
    this.transactionVehicles = this.tempTxnList.filter((p) =>
      p.txnid.toLowerCase().includes(event.trim().toLowerCase())
    );
  }

  filterVehicle(event: any) {
    this.transactionVehicles = this.tempTxnList.filter((p) =>
      p.vehiclersn.toLowerCase().includes(event.trim().toLowerCase())
    );
  }
}

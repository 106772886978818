import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { UserService } from 'src/app/@core/services/user.service';
import { TableService } from '../../@core/services/table.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { BreadcrumbService } from './../../theme/layout/header/breadcrumb/breadcrumb.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { ActionDashboardOverrideComponent } from '../../modals/action-dashboard-override/action-dashboard-override.component';
import { ActionFormComponent } from '../../modals/action-form/action-form.component';
import { ActionInfoModalComponent } from '../../modals/action-info-modal/action-info-modal.component';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { LocationService } from 'src/app/@core/services/location.service';
import { stat } from 'fs';

@Component({
  selector: 'app-action-dashboard',
  templateUrl: './action-dashboard.component.html',
  styleUrls: ['./action-dashboard.component.scss'],
})
export class ActionDashboardComponent implements OnInit {
  activeTab: number = 0;
  filtervalue: string = '';
  actionsByUserList: any = [];
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 3, 'Action Dashboard');
  filterList: any = [];
  disabled = false;
  latitude: any = null;
  longitude: any = null;
  activeTabName: string = 'pending';
  closeResult: string = '';
  listActionCount: any;

  actionLabels: any[] = [
    {
      tabName: 'pending',
      tab_icon: 'clock-o',
      tabBgColor: '#ebeb98',
      tabIconBg: '#a7a708',
    },
    {
      tabName: 'completed',
      tab_icon: 'check',
      tabBgColor: '#b0f6b0',
      tabIconBg: '#00bd00',
    },
    {
      tabName: 'rejected',
      tab_icon: 'times',
      tabBgColor: '#f7d5d5',
      tabIconBg: '#d60000',
    },
  ];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private locationService: LocationService,
    private table: TableService,
    public common: CommonService,
    public api: ApiService,
    private modalService: NgbModal,
    public userService: UserService,
    public alert: AlertService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Action Dashboard', link: '/user/action-dashboard' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.breadcrumbService.setActiveBreadCrumb(false);
    /** breadcums implementation end*/
    this.getActionsByStatus('PENDING');
    this.getActionCount();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  ngOnInit(): void {
    this.latitude = this.locationService._position.latitude;
    this.longitude = this.locationService._position.longitude;
    this.locationService.position.subscribe((position: any) => {
      this.latitude = position.latitude;
      this.longitude = position.longitude;
    });
  }

  getActionCount() {
    this.common.loading = true;
    this.api.get('dashboard/get_user_action_count').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.listActionCount = res.data[0];
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getActionsByStatus(status: any) {
    this.common.loading = true;
    this.api.get('dashboard/get-actions-by-user?status='+status).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.filterList = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

   updateStatus(item: any, status: any) {
    let params = {
      stageActionId: item.actionId,
      transactionDetailsId: item.transactionDetailId,
      stageDetailId: item.transactionStageDetailId,
      status: status,
      transactionId: item.transactionId,
      actionType: item.actionType,
      stageId: item.stageId,
      lat: this.latitude,
      lng: this.longitude,
      flowType: item.flow_type,
      autoOutVehicle: false,
      callingForm: 'actionDashboard',
    };
    this.common.loading = true;
    this.api.post('transaction/save-transaction-detail', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.getActionsByStatus('PENDING');
          this.getActionCount();
          setTimeout(() => {
            this.changeTabs(this.activeTab, this.actionLabels[this.activeTab]);
          }, 1000);
        }
        if (res.message == 'NOT INSIDE GEOFENCE AREA') {
          this.alert.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

actionDetail(action, actionType: any, stage_id: any, txnId: any) {
    let activeModal = this.modalService.open(ActionInfoModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });
    activeModal.componentInstance.refData = {
      actionName: action.actionName,
      actionType: actionType,
      stageId: stage_id,
      txnId: txnId,
      actionId: action.actionId,
      txnStageDetailId: action.transactionStageDetailId,
      action_type: 1,
    };
    activeModal.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  tripModal(vehicle) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.transactionId,
      processName: vehicle.processName,
    };
  }

  tripTrackingModal(vehicle) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.transactionId,
      vehicleName: vehicle.vehicleNo + '-' + vehicle.processName,
    };
  }

  changeTabs(i: any, tab: any) {
    this.getActionsByStatus(tab.tabName == 'completed' ? 'PASS' : tab.tabName == 'rejected' ? 'FAIL' : tab.tabName.toUpperCase());
    this.activeTab = i;
    this.activeTabName = tab.tabName;
  }

  openForm(item: any) {
    this.common.params = {
      rowDetail: item,
      showVisitorCard: true,
      disabledField: this.disabled,
      activeTab: this.activeTabName,
    };
    const activeModal = this.modalService.open(ActionFormComponent, {
      size: 'lg',
    });
    activeModal.result.then((data: any) => {
      this.getActionsByStatus('PENDING');
      this.getActionCount();
      this.activeTab = 0;
      this.activeTabName = 'pending';
    });
  }

  updateOverridingStatus(item: any) {
    this.common.params = {
      rowDetail: item,
    };
    const activeModal = this.modalService.open(
      ActionDashboardOverrideComponent,
      { size: 'xl' }
    );
    activeModal.result.then((data: any) => {
      this.getActionsByStatus('PENDING');
      this.getActionCount();
      this.activeTab = 0;
      this.activeTabName = 'pending';
    });
  }
}

import { FullCalendarModule } from '@fullcalendar/angular';
import { SmartTableComponent } from '../../components/tmg-dashboard/smart-table/smart-table.component';
import { NgSmartTableComponent } from '../../directives/ng-smart-table/ng-smart-table.component';
import { DatetimePickerComponent } from '../../directives/datetime-picker/datetime-picker.component';
import { ThemeModule } from './../../theme/theme.module';
import { DemoMaterialModule } from './../../material.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from 'src/app/directives/datatable/datatable.component';
import { DataTablesModule } from 'angular-datatables';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from 'src/app/directives/directive.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CameraComponentComponent } from 'src/app/components/camera-component/camera-component.component';
import { WebcamModule } from 'ngx-webcam';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { FormatNamesPipe } from 'src/app/@core/pipes/formatNames.pipe';
import { VisitorAppointmentComponent } from 'src/app/components/visitor-appointment/visitor-appointment.component';
import { DataNotFoundComponent } from 'src/app/modals/data-not-found/data-not-found.component';
import { CustomDatatableComponent } from 'src/app/@core/components/custom-table/tables/custom-datatable/custom-datatable.component';
import { CustomTableComponent } from 'src/app/@core/components/custom-table/custom-table.component';
import { TripCommentsComponent } from 'src/app/modals/trip-comment-trail/trip-comments/trip-comments.component';
import { HtmlSanitizePipe } from 'src/app/@core/pipes/html-sanitize.pipe';
import { CryptoService } from 'src/app/@core/services/crypto.service';
import { CustomCommunicationComponent } from 'src/app/modals/custom-communication/custom-communication.component';
import { FormComponent } from 'src/app/components/form/form.component';
import { CustomFieldNewComponent } from 'src/app/components/custom-field-new/custom-field-new.component';
import { OpenCameraModalComponent } from 'src/app/modals/open-camera-modal/open-camera-modal.component';

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const components = [
  VisitorAppointmentComponent,
  DatatableComponent,
  CameraComponentComponent,
  DatetimePickerComponent,
  NgSmartTableComponent,
  SmartTableComponent,
  DataNotFoundComponent,
  DataNotFoundComponent,
  CustomDatatableComponent,
  CustomTableComponent,
  TripCommentsComponent,
  FormatNamesPipe,
  HtmlSanitizePipe,
  CustomCommunicationComponent,
  FormComponent,
  CustomFieldNewComponent,
  OpenCameraModalComponent
];

const modules = [
  FullCalendarModule,
  CommonModule,
  WebcamModule,
  ThemeModule,
  DataTablesModule,
  FormsModule,
  ReactiveFormsModule,
  DirectiveModule,
  DemoMaterialModule,
  NgxSliderModule,
  NgxGraphModule,
  NgxQRCodeModule,
  NgxChartsModule,
  CKEditorModule,
];
@NgModule({
  declarations: [...components],
  imports: [
    ...modules,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [...components, ...modules, TranslateModule],
})
export class SharedModule {}

import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ProcessService } from '../process/process.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'src/app/@core/services/role.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-vehicle-route-configuration',
  templateUrl: './vehicle-route-configuration.component.html',
  styleUrls: ['./vehicle-route-configuration.component.scss']
})
export class VehicleRouteConfigurationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'Stage Zone Mapping List');

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    public api: ApiService,
    public common: CommonService,
    public table: TableService,
    public alert : AlertService,
    public alertService: AlertService,
    public processService: ProcessService,
    private modalService: NgbModal,
    public role : RoleService,
    public breadcrumbService: BreadcrumbService,

  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Process', url: null },
      {
        name: 'Flow Route Configuration Management',
        link: '/user/vehicle-route-configuration',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
   }


  processStages: any[] = [];
  destinationList:any[] = [];
  id:number = null;
  selectedOrderId: number = null;
  selectedOrderName: string = null;
  destinationId: number = null;
  destinationName:string = null;
  btn:string = 'save';
  directionSymbol:string = null;
  directionMessage:string = null;
  plantId: number = null;
  routeConfigurationData:any[] = [];
  isEdit : boolean = false;
  color:any='Green';
  displayStyle:any=null;

  colorValidator:any;
  processValidator: any;
  destinationValidator: any;
  directionSymbolValidator :any;
  directionMessageValidator:any


  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnInit() {
    this.getDockTypeStages();
    this.getRouteConfigurations();
  }

  selectedProcessStage(event: any) {
  console.log('event processStage: ', event);
    this.selectedOrderId = event.order_id;
    this.selectedOrderName = event.order_name;
    this.destinationId = null;
    this.destinationName = null;
    this.getDestinationsByOrderId(this.selectedOrderId);
  }

  getDockTypeStages() {
    this.common.loading = true;
    this.processService.getAllProcessStage().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.processStages = res.data.filter(
          (data) => data.stage_type_name == 'Dock Stage'
        );
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedDestination(event: any) {
    this.destinationId = event.destination_id;
    this.destinationName = event.stage_destination_code;
    this.getRouteConfigurations(false);
  }

  getDestinationsByOrderId(Id: any) {
    this.common.loading = true;
    this.api
      .get('destination/get_destination_codes_by_order_id?OrderId=' + Id)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.destinationList = res.data;
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  saveConfiguration(){
    this.common.loading = true;
    let params = {
      id: this.id,
      processStageOrderId: this.selectedOrderId ,
      destinationId: this.destinationId,
      directionSymbol: this.directionSymbol,
      directionMsg: this.directionMessage,
      color:this.color,
      displayStyle:this.displayStyle
    };
    this.api.post('vehicle-route/add-route-configuration', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertService.info(res.message);
          this.reset();
          this.getRouteConfigurations();
          this.common.loading = false;
        }
      },
      (err) => {
        this.common.loading = false;
        console.error(err);
      }
    );
  }

  getRouteConfigurations(updateTableFlag:boolean = true){
    this.common.loading = true;
    let params={
      plantId : this.plantId,
      processStageOrderId : this.selectedOrderId,
      destinationId : this.destinationId
    }
    this.api
      .get('vehicle-route/get-route-configuration-data',params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if(!updateTableFlag){
            this.directionSymbol = res.data[0]?.direction_symbol;
            this.directionMessage =  res.data[0]?.direction_msg;
            this.id = res.data[0]?.id;
          }else{
            this.routeConfigurationData = res.data;
            this.renderTable();
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );

  }

  view(row?: any) {
    this.common.params = {
      details: [row],
      title: 'Stage Destination Code Details',
    };

    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'xl',
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Stage Destination Code ',
      description:
        `<b>&nbsp;` + 'Are you sure, Do you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });
  }

  delete(row?: any) {
    this.common.loading = true;
    this.api.get('vehicle-route/delete-vehicle-route-cnfiguration?id='+row.id).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alert.success(res.message);
        this.getDockTypeStages();
        this.getRouteConfigurations();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  edit(row?: any) {
  console.log('row: ', row);
    this.common.gotoTop();
    this.reset();
    this.id = row.id;
    this.selectedOrderId = row.process_stage_order_id;
    this.selectedOrderName = row.process_stage_name;
    this.destinationId = row.destination_id;
    this.destinationName =  row.stage_destination_code;
    this.directionSymbol = row.direction_symbol;
    this.directionMessage =  row.direction_msg;
    this.plantId = row.plant_id;
    this.color=row.colour;
    this.displayStyle=row.display_style;
    this.btn = 'update';
    this.getDockTypeStages();
    this.getDestinationsByOrderId(this.selectedOrderId);
  }


  reset(){
    this.id = null;
    this.selectedOrderId = null;
    this.selectedOrderName = null;
    this.destinationId = null;
    this.destinationName = null;
    this.directionSymbol = null;
    this.directionMessage = null;
    this.processValidator = null;
    this.destinationValidator = null;
    this.directionSymbolValidator = null;
    this.directionMessageValidator = null;
    this.processStages = [];
    this.destinationList = [];
    this.btn='save'
    this.color='Green';
    this.displayStyle=null;
    this.getDockTypeStages();
  }

  formValidator(){
    console.log("chjk")
    this.processValidator = this.common.nullValidator(this.selectedOrderId, "Process Stage Mandatory");
    this.destinationValidator = this.common.nullValidator(this.destinationId, "Destination Mandatory");
    this.directionSymbolValidator = this.common.nullValidator(this.directionSymbol, "Direction Symbol Mandatory");
    this.directionMessageValidator = this.common.nullValidator(this.directionMessage, "Direction Message Mandatory");
    this.colorValidator=this.common.nullValidator(this.color, "Direction Color Mandatory");
    if(!this.processValidator.error && !this.destinationValidator.error && !this.directionSymbolValidator.error && !this.directionMessageValidator.error && !this.colorValidator.error){
      this.saveConfiguration();
    }
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Version Management" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"App Version" | translate}}</label>
                  <input type="text" class="form-control" name="name" placeholder="Enter App Version"
                    [(ngModel)]="appData.appVersion" autocomplete="off">
                  <div class="error" *ngIf="appVersionValidator && appVersionValidator.error">
                    <span>{{appVersionValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"APP Type" | translate}} </label>
                  <select class="form-control" [(ngModel)]="versionType" #name="ngModel" placeholder="Select App Type">
                    <option value="CONTROLLER">CONTROLLER</option>
                    <option value="CONTROLLER_OTA">CONTROLLER OTA</option>
                    <option value="APK">APK</option>
                  </select>
                </div>

                <!-- <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="versionType == 'APK'">
                  <label for="my-input" class="required">{{"App URL" | translate}}</label>
                  <input type="text" class="form-control" name="name" placeholder="Enter App URL"
                    [(ngModel)]="appData.appUrl" autocomplete="off">
                  <div class="error" *ngIf="appUrlValidator && appUrlValidator.error">
                    <span>{{appUrlValidator.msg}} </span>
                  </div>
                </div> -->

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="versionType == 'APK'">
                  <label for="my-input" class="required">{{"App URL Type" | translate}} </label>
                  <select name="appUrlType" id="#fieldType" class="form-control" [(ngModel)]="appData.appUrlType">
                    <option value="1">Server</option>
                    <option value="2">PlayStore</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="versionType != 'APK'">
                  <label for="my-input" class="required">{{"Upload OTA File" | translate}} </label>
                  <input type="file" accept=".py" (change)="uploadFile($event)">
                  <div class="error" *ngIf="appUrlValidator && appUrlValidator.error">
                    <span>{{appUrlValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="versionType == 'APK'">
                  <label for="my-input" class="required">{{"Upload APK File" | translate}} </label>
                  <input type="file"accept=".apk" (change)="uploadFile($event)">
                  <div class="error" *ngIf="appUrlValidator && appUrlValidator.error">
                    <span>{{appUrlValidator.msg}} </span>
                  </div>
                </div>




              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-muted" (click)="common.backClicked()"> {{"back" | translate}} </button>
                  <button class="btn btn-warning" (click)="resetDetail()"> {{"reset" | translate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | translate}} </button>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"APP Details List" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="card-body">
                <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                  <thead>
                    <tr>
                      <th>{{"s.no." | translate}}</th>
                      <th>{{"App Version" | translate}}</th>
                      <th>{{"App URL" | translate}}</th>
                      <th>{{"App URL Type" | translate}}</th>
                      <th>{{"Release Date" | translate}}</th>
                      <th class="action">{{"action" | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of appDetails, let i = index">
                      <td>{{i+1}}</td>
                      <td>{{row.appVersion}}</td>
                      <td>{{row.appUrl}}</td>
                      <td>{{row.appUrlType}}</td>
                      <td>{{row.releaseDate | date:'dd-MM-yyyy'}}</td>
                      <td class="action">
                        <div class="btn-block">
                          <button class="btn btn-primary" (click)="viewDetails(row)"> {{"view" | translate}} </button>
                          <button class="btn btn-success" (click)="editRow(row)"
                            *ngIf="userService.permissionMap.get('edit_plant_access')==true && row.plantId != -1">
                            {{"edit" | translate}} </button>
                          <button class="btn btn-danger" (click)="confirmAlert(row)"
                            *ngIf="userService.permissionMap.get('delete_plant_access')==true && row.plantId != -1">
                            {{"delete" | translate}} </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>
          </div>

        </div>

      </div>

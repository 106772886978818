import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { PlantService } from '../plant/plant.service';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { AddInsuranceComponent } from '../add-insurance/add-insurance.component';
import { InsuranceData } from 'src/app/dataType/assetInterface';

@Component({
  selector: 'app-asset-insurance',
  templateUrl: './asset-insurance.component.html',
  styleUrls: ['./asset-insurance.component.scss']
})
export class AssetInsuranceComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Insurance List');


  // declaring variables
  @Input() public assetId:number=null;

  insurance={
    insuranceId:null,
    insuranceCompanyName:'',
    policyNo:'',
    contactPersonName:'',
    contactPersonNo:null,
    startDate:null,
    endDate:null,
    coverage:'',
    limit:'',
    premiumAmount:null,
    deductableAmount:null,
    status:'ACTIVE',
  }
    insuranceList:InsuranceData[]=[];
    buttonName:string= 'save';

   // constructor
    constructor(
      public userService: UserService,
      public common: CommonService,
      public table: TableService,
      public api: ApiService,
      public modalService: NgbModal,
      public breadcrumbService: BreadcrumbService,
      public role: RoleService,
      public plantService : PlantService,
      public alert : AlertService,
    ) {

     }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
    this.assetId=this.assetId;
    this.getInsuranceList();
  }

// custom funcitons
  addInsurance(){
    const activeModal = this.modalService.open(AddInsuranceComponent, {
      size: 'md',ariaLabelledBy: 'modal-basic-title'
    });
    activeModal.componentInstance.refData = {
      isModalOpen : true,
      assetId : this.assetId
    };
    console.log('activeModal.componentInstance.refData: ', activeModal.componentInstance.refData);
    activeModal.closed.subscribe((res: any) => {
        this.getInsuranceList();
    });
  }

  getInsuranceList(){
    this.common.loading = true;
    let params={
      assetId:this.assetId
    }
    this.api.get("asset-insurance/get-mapping-list",params).subscribe(
      (res:any)=>{
        this.common.loading = false;
        this.insuranceList=res.data;
        console.log('this.insuranceList: ', this.insuranceList);
      },
      (err:any)=>{
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  reset(){
    this.insurance={
      insuranceId:null,
      insuranceCompanyName:'',
      policyNo:'',
      contactPersonName:'',
      contactPersonNo:null,
      startDate:null,
      endDate:null,
      coverage:'',
      limit:'',
      premiumAmount:null,
      deductableAmount:null,
      status:'ACTIVE',
    }
    this.insuranceList=[]
  }

  viewInsuranceDetails(row?:any){
    const InsuranceDetails = {
      company_name: row.company_name,
      contact_person_name: row.contact_person_name,
      contact_person_no: row.contact_person_no,
      coverage: row.coverage,
      deductable_amount: row.deductable_amount,
      end_date: row.end_date,
      insurance_company_name: row.insurance_company_name,
      insurance_limit: row.insurance_limit,
      plant_name: row.plant_name,
      policy_no: row.policy_no,
      premium_amount: row.premium_amount,
      start_date: row.start_date,
      status: row.status
    };

    this.common.params = { details: [InsuranceDetails], title: 'Insurance Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmDelete(row?:any){
    this.common.params = {
      title: 'Delete Manufacturer ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete Insurance' +
        ' ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });
  }

  delete(row: any) {
    let param={
      assetInsuranceMappingId : row.mapping_id
    }
    this.api.get("asset-insurance/delete-asset-insurance-mapping",param).subscribe(
      (res:any)=>{
        this.common.loading=false;
        if(res.status){
          this.alert.success(res.message);
          this.getInsuranceList();
        }
      },
      (err:any)=>{
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { MapModalComponent } from 'src/app/modals/map-modal/map-modal.component';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-seal-field-report',
  templateUrl: './seal-field-report-old.component.html',
  styleUrls: ['./seal-field-report-old.component.scss']
})


export class SealFieldReportOldComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  tripId: any = null;
  invoiceTripNumber: any = null;
  fieldName: any = '';
  modalData: any[] = [];
  reportData: any;
  stages: any[] = [];
  fileName:string='';
  txnCompletionTime:any;
  dtOptions: any = this.table.options(10, 4, 'Action Dashboard');

  constructor(
    public table: TableService,
    public common: CommonService,
    private modalService: NgbModal,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    console.log('this.route.snapshot.queryParams.tripId: ', this.route.snapshot.queryParams.tripId, this.route.snapshot.queryParams.redirect);
    if (this.route.snapshot.queryParams.tripId ) {
      this.invoiceTripNumber = this.route.snapshot.queryParams.invNo;
      this.tripId = this.route.snapshot.queryParams.tripId;
      this.getTxnCompletionTime(this.tripId);
    }
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  getTxnCompletionTime(txnID:any){
    this.common.loading = true;
    this.apiService.get('transaction/get-txn-completion-time?txnId=' + txnID).subscribe((res: any) => {
        if (res.status) {
          this.txnCompletionTime = res.data[0];
          if(this.txnCompletionTime!=''){
            const specificDate = new Date('2024-02-28T01:30:00.000Z');
            const txnCompletionDate = new Date(this.txnCompletionTime);
            if (txnCompletionDate >= specificDate) {
              const tripId = this.tripId;
              this.router.navigate(['public-field-audit-report'], {
                queryParams: {tripId: tripId,invNo:this.invoiceTripNumber },
              });
            }else{
              this.getAllStagesByTxnId();
              this.getAuditReport();
            }
          }else{
             const tripId = this.tripId;
              this.router.navigate(['public-field-audit-report'], {
                queryParams: {tripId: tripId,invNo:this.invoiceTripNumber },
              });
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      });
  }

  contentModal(content, data: any) {
    this.modalData = data.response;
    this.fieldName = data.fieldName;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    .result.then(
      (result) => {},(reason) => {}
    );
  }

  getAuditReport() {
    this.common.loading = true;
    let params={
      txnIds : [parseInt(this.tripId)],
      alertData:false
    }
    this.apiService.post('report/get-seal-field-report-old',params).subscribe((res: any) => {
        if (res.status) {
          this.reportData = res.data[0];
          this.fileName = this.reportData.processName+"-"+this.reportData.extTripId;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      });
  }

  getAllStagesByTxnId() {
    this.common.loading = true;
    this.apiService
      .get('report/get-process-stage-by-txn?txnId=' + this.tripId).subscribe((res: any) => {
        if (res.status) {
          this.stages = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      });
  }

  isImgTypeFile(fileUrl: any) {
      const ext = fileUrl.substring(fileUrl.lastIndexOf('.') + 1);
      return ext == 'png' || ext == 'jpeg' || ext == 'jpg';
  }


  displayImage(url) {
    let newUrl = decodeURI(typeof url == 'string' ? url : url.fileUrl);
    const arrays = newUrl.split('/');
    // let name = arrays[arrays.length - 1];
    let name = "Image Preview";
    this.common.params = {
      url: typeof url == 'string' ? url : url.fileUrl,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  openMap(lat, long) {
    this.common.params = {
      lat: lat,
      lng: long,
    };
    const activeModal = this.modalService.open(MapModalComponent, {
      size: 'lg',
    });
  }

  @ViewChild('dataToExport', { static: false }) public dataToExport: ElementRef;
  public downloadAsPdf(): void {
    const width = this.dataToExport.nativeElement.scrollWidth;
    const height = this.dataToExport.nativeElement.scrollHeight + 40;

    html2canvas(this.dataToExport.nativeElement, {
      width: width,
      height: height,
      scale: 2, // Increase the scale for higher resolution
      logging: false, // Disable logging for better performance
      allowTaint: true, // Allow tainting of canvas (may help with image loading)
      useCORS: true, // Use CORS to load images (may help with external resources)
      backgroundColor: '#ffffff' // Set background color if needed
       })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg',1.0);

        let doc = new jsPDF({
          orientation: width > height ? 'l' : 'p',
          unit: 'px',
          format: [width, height]
        });

        doc.addImage(imgData, 'JPEG', 0, 0, width, height);
        doc.save(this.fileName+'.pdf');
      })
      .catch((error) => {
        console.error('Error exporting to PDF:', error);
      });
  }
}

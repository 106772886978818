<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">{{("geofence" | translate) + " "+("edit" | translate)}} </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12" *ngFor="let item of allGeoFence">
      <div class="card geofence-card" *ngIf="item.data.length && ((item.type=='plant' && item.data[0].id!=null)||item.type!='plant')">
        <div class="card-header justify-content-center">
          <h5 class="card-title"> {{((item.type | lowercase) | translate)+' '+ ("geofence" | translate)}}</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4 geoFence-column" [ngClass]="item.type =='plant' ? 'col-md-12':'col-md-4'"
              *ngFor="let geoFence of item.data;index as i">
              <div class="row no-gutters">
                <div class="col-9 geofence-subcolumn left-column"> <label for="my-input"
                    class="font-weight-bold mb-0">{{geoFence.name}}</label>
                </div>
                <div class="col-3 geofence-subcolumn right-column">
                  <button class="btn btn-success btn-sm" (click)="editGeoFence(geoFence,item.type)">
                    {{"edit" | translate}}
                  </button>
                  <button *ngIf="item.type !== 'plant'" class="btn btn-danger btn-sm ml-2"
                    (click)="deleteGeoFence(geoFence,item.type)">
                    {{"delete" | translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" *ngIf="!dataLength">
      <app-data-not-found></app-data-not-found>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <button type="button" class="btn btn-danger" (click)="closeModal()">{{"close" | translate}} </button>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import * as filesaver from 'file-saver';
import { DataTableDirective } from 'angular-datatables';
import { ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { Label, SingleDataSet, Color } from 'ng2-charts';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { StageService } from '../stage/stage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessVehicleViewComponent } from 'src/app/modals/process-vehicle-view/process-vehicle-view.component';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import _ from 'lodash';

@Component({
  selector: 'app-tat-inbetween-report',
  templateUrl: './tat-inbetween-report.component.html',
  styleUrls: ['./tat-inbetween-report.component.scss'],
})
export class TatInbetweenReportComponent implements OnInit {
  responseData: any[];
  tabvalue: any = 'table';
  startDate: any = new Date(new Date().setDate(new Date().getDate() - 7));
  endDate: any = new Date();
  destinationCodes: any = [];
  dayWiseTatList: any = [];
  totalProcessWiseTatList: any = [];

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Tat Report');
  listOfProcess: any = [];
  processName: any = null;
  processId: any = null;
  stageData: any = [];
  stageName: any = null;
  stageId: any = null;
  destinationList: any = [];
  sumOfTotalProcessWiseTat: string = '';
  headers: any[] = [];
  inBetweenResponseData: any[] = [];
  inBtwProcessId: any = null;
  inBtwProcessName: any = null;
  inBtwHeaders: any[] = [];
  tripname: string = '';
  dateLabels: Label[] = [];
  public pieChartType: ChartType = 'doughnut';
  public barChartType: ChartType = 'bar';
  public pieChartLegend = true;

  dateWiseTotalTat: ChartDataSets[] = [];
  processLabels: Label[] = [];
  processAverageTat: any[] = [];
  processAverageTatData: SingleDataSet = [];
  dateWiseProcessLabels: Label[] = [];
  dateWisePerProcessChartData: ChartDataSets[] = [];

  dateWiseStageTotalTat: ChartDataSets[] = [];
  stageAverageTat: any[] = [];
  stageLabels: Label[] = [];
  stageAverageTatData: SingleDataSet = [];
  dateWisePerStageChartData: ChartDataSets[] = [];
  dateWiseStageLabels: Label[] = [];

  dateWiseDestinationTotalTat: ChartDataSets[] = [];
  destinationAverageTat: any = [];
  destinationLabels: Label[] = [];
  destinationAverageTatData: SingleDataSet = [];
  dateWisePerDestinationChartData: ChartDataSets[] = [];
  dateWiseDestinationLabels: Label[] = [];
  newPlantTatData: any = [{}];
  totalInbetweenTotal: any = '00:00';
  totalInbetweenExpected: any = '00:00';
  expected_tat: any = '00:00';
  plantData: any = [];
  plantId: number = null;

  public dateWiseChartLegend = true;
  public dateWiseChartPlugins = [];

  public barChartOptions: ChartOptions = {
    animation: {
      duration: 1000,
      easing: 'linear',
      animateRotate: true,
      animateScale: true,
    },
    plugins: {
      datalabels: {
        formatter(value, context) {
          const hours = Math.floor(value / (60 * 60));
          const min = String(value / 60).substring(0, 2);
          return hours == 0 && Number(min) == 0
            ? '0 hr'
            : hours + ':' + min + ' hr';
        },
      },
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Total TAT per date',
            fontSize: 12,
            fontStyle: 'bold',
            fontFamily: ' sans-serif',
          },
          ticks: {
            callback: function (v) {
              return epoch_to_hh_mm_ss(v);
            },
            // stepSize: 60 * 60
          },
        },
      ],
    },

    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ': ' +
            epoch_to_hh_mm_ss(tooltipItem.yLabel) +
            ' hr'
          );
        },
      },
    },
  };

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.getAllProcess();
  }

  public barChartColors: Color[] = [
    { backgroundColor: this.common.graphsecondary },
    { backgroundColor: this.common.graphsuccess },
    { backgroundColor: this.common.graphdanger },
  ];

  public piechartoptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    legend: {
      align: 'center',
    },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#3f7ac7',
        font: {
          weight: 'bold',
          size: 12,
        },
        formatter(value, context) {
          const hours = Math.floor(value / (60 * 60));
          const min = String(value / 60).substring(0, 2);
          return hours == 0 && Number(min) == 0
            ? '0 hr'
            : hours + ':' + min + ' hr';
        },
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return (
            data.labels[tooltipItem.index] +
            ': ' +
            epoch_to_hh_mm_ss(value) +
            ' hr'
          );
        },
      },
    },
  };

  constructor(
    public table: TableService,
    public common: CommonService,
    public api: ApiService,
    public alert: AlertService,
    public stageService: StageService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public modalService: NgbModal,
    public role: RoleService,
    public plantService: PlantService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Tat Report', url: '/user/tat-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    // this.breadcrumbService.setActiveBreadCrumb(false);
  }

  ngOnInit() {
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    // this.getTatReport(1);

    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.getAllProcess();
    }
  }

  selectedProcess(event: any) {
    this.processId = event.processId;
    this.processName = event.processName;
    this.getPlantTatReport();
  }

  resetPage() {
    this.getPlantTatReport();
  }



  getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.plantId,
    };
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.listOfProcess = res.data;
        this.processId = this.listOfProcess[0].processId;
        this.processName = this.listOfProcess[0].processName;

        this.getPlantTatReport();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getPlantTatReport() {
    if (this.startDate > this.endDate) {
      this.alert.warn('Start Date Should be Smaller than End Date!!');
      return;
    }
    this.common.loading = true;
    let params = {
      fromDate: this.startDate,
      toDate: this.endDate,
      processId: this.processId,
    };

    this.api.get('report/get-stage-in-between-tat-report', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.newPlantTatData = res.data;
        this.responseData = res.data;
        this.headers = res.data[0].data;
        this.totalProcessWiseTat(this.responseData);
        this.sumTotalProcessWiseTat();
        this.dayWiseTat(res.data);
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    );
  }

  // getInBetweenTat(type: any) {
  //   if (this.inBtwProcessId != null) {
  //     if (type == 1) {
  //       this.inBtwProcessId = this.listOfProcess[0].processId;
  //       this.inBtwProcessName = this.listOfProcess[0].processName;
  //     }
  //     let params = {
  //       fromDate: this.startDate,
  //       toDate: this.endDate,
  //       processId: this.inBtwProcessId,
  //     };
  //     this.common.loading = true;
  //     this.api.get('report/get-stage-in-between-tat-report', params).subscribe(
  //       (res: any) => {
  //         this.common.loading = false;
  //         this.inBetweenResponseData = res.data ?? [];
  //         this.inBtwHeaders = this.inBetweenResponseData[0].data;
  //         this.dayWiseTat(this.inBetweenResponseData);
  //         this.totalProcessWiseTat(this.inBetweenResponseData);
  //         this.sumTotalProcessWiseTat();
  //       },
  //       (err: any) => {
  //         console.error('Error: ', err);
  //         this.common.loading = false;
  //       }
  //     );
  //   }
  // }

  dayWiseTat(list: any) {
    // this.common.loading = true;
    this.dayWiseTatList = [];
    list.forEach((el) => {
      let sum = 0;
      el.data.forEach((e) => {
        let arr = e.tat.split(':');
        let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
        sum = sum + tat;
      });
      let seconds = sum;
      let numOfHours = (seconds / 3600).toString().split('.');
      seconds = seconds % 3600; // Remaining second after getting the hours
      let numOfMinutes = (seconds / 60).toString().split('.');
      let total =
        numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
      this.dayWiseTatList.push(total);
    });
    this.dayWiseTatList
    console.log('this.dayWiseTatList: ', this.dayWiseTatList);
    this.getDateWiseProcessTat();
    this.getExpectedTat();
    // this.common.loading = false;
  }


  exportAsExcel(data: any[], fileName: string,allTrips:boolean=false): void {
    let value = _.cloneDeep(data);
    let i=0;
    let headers=[];
    headers.push('Date');
    if(!allTrips){
      value.map(res => {
        res['Date']=res.date;
        res.data.forEach(data1=>{
          res[data1.stage_name]=data1.tat;
          if(i==0) headers.push(data1.stage_name);
        })
        res['Date wise TAT']=this.dayWiseTatList[i]
        console.log('this.dayWiseTatList[i]:sdf ', this.dayWiseTatList[i],i);
        delete res.date;
        delete res.data;
        i++;
      });
    }
     headers.push('Date wise TAT')
     console.log(':ergh ',value );
     const worksheet = XLSX.utils.json_to_sheet(value,{header:headers});
     const workbook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(workbook, worksheet, 'TAT Inbetween Report');
     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
     const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     filesaver.saveAs(blob, fileName + '.xlsx');
   }
  getExpectedTat() {
    let sum = 0;
    this.responseData[0].data.forEach((e) => {
      let arr = e.expected_tat.split(':');
      let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
      sum = sum + tat;
    });
    let seconds = sum;
    let numOfHours = (seconds / 3600).toString().split('.');
    seconds = seconds % 3600; // Remaining second after getting the hours
    let numOfMinutes = (seconds / 60).toString().split('.');
    let total =
      numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
    this.expected_tat = total;
  }

  totalProcessWiseTat(data: any) {
    this.totalProcessWiseTatList = [];
    let count = 0;
    while (count != data[0].data.length) {
      let sum = 0;
      data.forEach((e) => {
        let arr = e.data[count].tat.split(':');
        let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
        sum = sum + tat;
      });
      let seconds = sum;
      let numOfHours = (seconds / 3600).toString().split('.');
      seconds = seconds % 3600; // Remaining second after getting the hours
      let numOfMinutes = (seconds / 60).toString().split('.');
      let total =
        numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
      this.totalProcessWiseTatList.push(total);
      count++;
    }
    this.getProcessWiseAverageTatGraph();
  }

  sumTotalProcessWiseTat() {
    console.log('totalInbetweenTotal: ', this.totalInbetweenTotal);
    let tempTat = this.totalInbetweenTotal.split(':');
    let sum = Number(tempTat[0]) * 3600 + Number(tempTat[1]) * 60;
    this.totalProcessWiseTatList.forEach((e) => {
      let arr = e.split(':');
      let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
      sum = sum + tat;
    });
    let seconds = sum;
    let numOfHours = (seconds / 3600).toString().split('.');
    seconds = seconds % 3600; // Remaining second after getting the hours
    let numOfMinutes = (seconds / 60).toString().split('.');
    let total =
      numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
    this.sumOfTotalProcessWiseTat = total;
  }

  // getTotalInbetweenTat(data: any) {
  //   let sum = 0;
  //   this.totalInbetweenExpected = data[0].inbetween_expected_tat;
  //   data.forEach((e) => {
  //     let arr = e.inbetween_tat.split(':');
  //     let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
  //     sum = sum + tat;
  //   });
  //   let seconds = sum;
  //   let numOfHours = (seconds / 3600).toString().split('.');
  //   seconds = seconds % 3600; // Remaining second after getting the hours
  //   let numOfMinutes = (seconds / 60).toString().split('.');
  //   let total =
  //     numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
  //   this.totalInbetweenTotal = total;
  // }

  getDateWiseProcessTat() {
    this.dateLabels = [];
    this.dateWiseTotalTat = [];
    //Labels for processWise Total TAT
    this.responseData.forEach((element) => {
      this.dateLabels.push(element['date']);
    });

    //Data for processWise Total TAT
    this.dateWiseTotalTat.push({
      data: [],
      label: 'All Processes',
    });

    this.dayWiseTatList.forEach((element) => {
      let abc = element.split(':');
      let sec = parseInt(abc[0]) * 3600 + parseInt(abc[1]) * 60;
      this.dateWiseTotalTat[0].data?.push(sec);
    });
  }

  getProcessWiseAverageTatGraph() {
    this.processAverageTat = [];
    this.processLabels = [];
    this.processAverageTatData = [];

    //Labels for processwise average tat graph
    this.headers.forEach((element) => {
      this.processLabels.push(element.stage_name);
    });

    //Data for processwise average tat graph
    this.totalProcessWiseTatList.forEach((element) => {
      let arr = element.split(':');
      let sec = arr[0] * 3600 + '.' + arr[1] * 60;
      this.processAverageTat.push(parseFloat(sec));
    });
    this.processAverageTat.forEach((element) => {
      this.processAverageTatData.push(element / this.dayWiseTatList.length);
    });
  }

  getDateWisePerProcessTatGraphData() {
    this.dateWisePerProcessChartData = [];
    this.dateWiseProcessLabels = [];
    for (let i = 0; i < this.responseData[0].data.length; i++) {
      this.dateWisePerProcessChartData.push({
        data: [],
        label: '',
        borderWidth: 1,
      });
    }
    this.responseData.map((element: any, index: number) => {
      element.data.map((values: any, i: number) => {
        let abc = values.tat.split(':');
        let sec = parseInt(abc[0]) * 3600 + parseInt(abc[1]) * 60;
        this.dateWisePerProcessChartData[i].data?.push(sec);
      });
      this.dateWiseProcessLabels.push(element.date);
    });

    this.responseData[0].data.map((label: any, index: number) => {
      this.dateWisePerProcessChartData[index].label = label.process_name;
    });
  }

  reset() {
    this.processId = null;
  }

  openModal(date: any, process: any, expectedTat: any) {
    let activeModal = this.modalService.open(ProcessVehicleViewComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });
    activeModal.componentInstance.refData = {
      stageId: process.stage_id[1],
      prevStageId: process.stage_id[0],
      destinationId: -1,
      processId: this.processId,
      processName: this.processName,
      date: date,
      expectedTat: expectedTat,
    };
  }
}

function epoch_to_hh_mm_ss(epoch) {
  const hours = Math.floor(epoch / (60 * 60));
  const min = String(epoch / 60).substring(0, 2);
  return hours + ':' + min;
}

import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/@core/services/common.service';
import { Component, Input, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() menu: any[] = [];
  activeMenuIndex: number = -1;
  isBoop: number = -1;
  active: number = -1;
  toggled: boolean = true;
  menutoggled: boolean = false;
  activeSubmenuIndex: number = -1;
  config = {
    paddingAtStart: true,
    classname: 'my-custom-class',
    listBackgroundColor: 'rgb(208, 241, 239)',
    fontColor: 'rgb(8, 54, 71)',
    backgroundColor: 'rgb(208, 241, 239)',
    selectedListFontColor: 'red',
  };

  constructor(public common: CommonService, public route: Router, public translateService: TranslateService) {
    this.common.menuToggled = true;
    this.activemenuSet();
    //  this.menuSelection(this.activeMenuIndex);
  let language = localStorage.getItem("language");
  translateService.addLangs(["en","hn"])
  translateService.setDefaultLang(language == null ? "en" : language)

  this.common.language.subscribe((lang:any) => {
    this.translateService.use(lang);
  })

  }


  activemenuSet() {
    let activemenu: any = localStorage.getItem('currentMenuIndex')
    this.activeMenuIndex = parseInt(activemenu);
    this.activemenuselection(this.activeMenuIndex);

    let activeMenuIndex: any = localStorage.getItem('subMenuIndex');
    this.activeSubMenuSelection(activeMenuIndex);

    this.route.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (this.common.activeMenuset) {
          this.activeMenuIndex = 0;
        }
        else {
          let active: any = localStorage.getItem('currentMenuIndex');
          this.activeMenuIndex = parseInt(active);
        }

      }
    });


  }



  ngOnChanges(changes: any) {
    if (changes.menu) {
      this.menu = changes.menu.currentValue;
    }

  }

  activemenuselection(index: number) {
    this.activeMenuIndex = index;
    //console.log('this.activeMenuIndex: ', this.activeMenuIndex);
  }


  activeSubMenuSelection(index: number) {
    this.activeSubmenuIndex = index;
  }

  menuSelection(index: number) {
    let currentIndex: any = index;
    localStorage.setItem('currentMenuIndex', currentIndex);
    this.activeMenuIndex = this.activeMenuIndex === index ? -1 : index;
    //console.log('this.activeMenuIndex: ', this.activeMenuIndex);
    this.activeSubmenuIndex = 0;
    this.route.navigate([this.menu[index].childs[0].link])
    this.common.activeMenuset = false;
    this.common.commondashboardNavigate = false;

  }



  activeMenu(index: number) {
    let active: any = localStorage.getItem('currentMenuIndex');
    this.activeMenuIndex = parseInt(active);
    this.activemenuselection(this.activeMenuIndex);
    this.activeSubmenuIndex = index;
    let submenuIndex: any = index;
    localStorage.setItem('subMenuIndex', submenuIndex)
    //console.log('this.activeSubmenuIndex: ', this.activeSubmenuIndex);
    this.common.dashboardparam = '';
  }


  menuToggle() {
    this.common.sidebarToggled(this.common.menuToggled);
  }


  hovertoggle(event:boolean=false) {
    // console.log(event)
    this.common.sidebarToggled(event);
  }

  ngOnInit(): void {
  }

}

<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">{{title}}</h5>
</div>

<div class="qr-code-container" #qrcode>
  <ngx-qrcode [elementType]="qrCodeType" [value]="pageUrl" cssClass="aclass" [errorCorrectionLevel]="error">
  </ngx-qrcode>
</div>
<div class="modal-footer" style="display: flex;">
  <i class="fa fa-whatsapp" (click)="handleCommunication('whatsapp')"></i>
  <i class="fa fa-envelope" (click)="handleCommunication('email')"></i>
  <i class="fa fa-paper-plane" (click)="handleCommunication('sms')"></i>
  <button class="print-button" (click)="printQRCode()">Print QR Code</button>
</div>

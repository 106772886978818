<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Hardware OTA Logs" | customTranslate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="row flex-column">
                    <div class="col-md-12">

                      <div class="form-group">
                        <label> {{"select level" | customTranslate}} </label>
                        <ps-auto-suggestion (onSelected)="selectPreference($event.level)" [data]="preferenceLevel"
                          display="level" placeholder="Select Preference" [preSelected]="{level:preferenceType}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="form-group" *ngIf="preferenceType != 'AXESTRACK'">
                        <label for="companySelect"> {{"select_company" | customTranslate}} </label>
                        <ps-auto-suggestion (onSelected)="selectedCompany($event)" id="companySelect"
                          [data]="allCompanies" display="companyName" placeholder="Select Company"
                          [preSelected]="{companyName:companyName}">
                        </ps-auto-suggestion>
                      </div>

                      <div class="form-group" *ngIf="preferenceType == 'PLANT'">
                        <label for="plantSelect"> {{"select_plant" | customTranslate}} </label>
                        <ps-auto-suggestion (onSelected)="selectedPlant($event)" id="plantSelect" [data]="allPlants"
                          display="plantName" placeholder="Select Plant" [preSelected]="{plantName:plantName}">
                        </ps-auto-suggestion>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8" *ngIf="OTAManagementData?.length">
                  <div class="ota-chart-column">
                    <div class="ota-chart">
                      <canvas baseChart [colors]="OTAChartColors" [data]="OTAChartData" [labels]="OtaChartLabels"
                        [chartType]="'pie'" [options]="otaChartOptions" [plugins]="otaChartPlugins" [legend]="true">
                      </canvas>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row action-row">
                <div class="col-md-3 table-action-block">
                  <div class="form-group version-dropdown">
                    <label for="stageSelect"> {{"hardware_version" | customTranslate}} </label>
                    <ps-auto-suggestion (onSelected)="selectVersion($event)" id="stageSelect"
                      [data]="allHardwareVersion" display="appVersion" placeholder="Select Version"
                      [preSelected]="{appVersion:stageName}">
                    </ps-auto-suggestion>
                  </div>
                </div>


                <div class="col-md-3">
                  <div class="form-check form-check-inline">
                    <label class="form-check-label mr-2" *ngFor="let type  of versionType;index as i">
                      <input type="radio" (change)="getVersionList()" class="form-check-input"
                        [(ngModel)]="hardwareVersionType" [name]="" [id]="'hardware-type-'+i" [value]="type.value">
                      {{type.name}}
                    </label>
                  </div>
                </div>


                <div class="col-md-3">
                  <button class="btn btn-primary save-btn" type="button" (click)="updateAllHardware()"> Update All
                  </button>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-12">
                  <smart-table [data]="table.data" [settings]="table.settings" id="Otamanagement"
                    filename="OTA-Management">
                  </smart-table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

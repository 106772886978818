import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { UserService } from 'src/app/@core/services/user.service';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-group-management',
  templateUrl: './group-management.component.html',
  styleUrls: ['./group-management.component.scss'],
})
export class GroupManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'group table');
  groupTypesList: any = {
    AXESTRACK: [
      'AXESTRACK',
      'AXESTRACKUSER',
      'COMPANYADMIN',
      'COMPANYUSER',
      'PLANTADMIN',
      'PLANTUSER',
    ],
    AXESTRACKUSER: ['PLANTADMIN', 'PLANTUSER'],
    COMPANYADMIN: ['COMPANYADMIN', 'COMPANYUSER', 'PLANTADMIN', 'PLANTUSER'],
    COMPANYUSER: ['PLANTADMIN', 'PLANTUSER'],
    PLANTADMIN: ['PLANTADMIN', 'PLANTUSER'],
    PLANTUSER: ['PLANTUSER'],
  };
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getData();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  group = {
    id: null,
    name: null,
    status: 'ACTIVE',
    companyId: null,
    plantId: null,
    groupType: '',
  };

  companyName: any = null;
  plantName: any = null;

  title = 'Add Group';
  btn = 'save';
  data: any = [];

  companyData: any = [];
  plantData: any[] = [];
  userList:any = [];

  selectedUserIds: any[] = [];
  selectedUserNames: any[] = [];



  constructor(
    private alertService: AlertService,
    private api: ApiService,
    public common: CommonService,
    public modalService: NgbModal,
    private plantService: PlantService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService
  ) {
    this.getCompany();
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'User-Group', url: null },
      { name: 'Manage Group', url: '/user/groups' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    this.group.groupType = role.user_role;
  }

  ngOnInit(): void {
    if (
      this.role.user_role == 'COMPANYADMIN' ||
      this.role.user_role == 'COMPANYUSER'
    ) {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
    this.getUserList();
  }
  ngViewInit() {}

  getCompany() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlant() {
    this.common.loading = true;
    this.api.get('plant/all_plants').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedCompany(event: any) {
    this.group.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.group.plantId = null;
    this.companyValidator = null;
    this.getUserList();
  }

  selectedPlant(event: any) {
    this.group.plantId = event.plantId;
    this.plantValidator = null;
    this.getUserList();

  }
  getPlantById(id?: any) {
    this.common.loading = true;

    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedUsers(event: any) {
    this.selectedUserIds = event.map((e) => e.userid);
  }

  getUserList() {
    let params = {
      companyId:this.group.companyId,
      plantId: this.group.plantId,
      userRole : this.role.user_role
    };
    this.common.loading = true;
    this.api.get('user/get-user-list', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.userList = res.data;
          console.log('this.userList: ', this.userList);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getData() {
    this.common.loading = true;
    this.api.get('group/all-group?plantType=false').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.data = res.data.map(item => ({ ...item, heads: JSON.parse(item.heads)})) || [];
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  saveData() {
    this.common.loading = true;
    let params = {
      groupId: this.group.id,
      groupName: this.group.name,
      groupStatus: this.group.status,
      company: this.group.companyId,
      plantId: this.group.plantId,
      userType: this.role.user_role,
      groupType: this.group.groupType,
      headIds : this.selectedUserIds
    };
    this.api.post('group/create_group', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.resetDetail();
          this.getData();
        } else {
          this.alertService.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  resetDetail() {
    this.group = {
      id: null,
      name: null,
      status: 'ACTIVE',
      companyId: null,
      plantId: null,
      groupType: this.role.user_role,
    };
    this.companyName = null;
    this.plantName = null;
    this.btn = 'save';
    this.title = 'Add group';
    this.groupNameValidator = null;
    this.statusValidator = null;
    this.processValidator = null;
    this.companyValidator = null;
    this.plantValidator = null;
    this.typeValidator = null;
    this.selectedUserIds = [];
    this.selectedUserNames = [];

  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete group',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteData(row);
      }
    });
  }

  selectedGroupType(groupType: string) {
    if (groupType == 'AXESTRACK' || groupType == 'AXESTRACKUSER') {
      this.group.companyId = null;
      this.group.plantId = null;
    } else if (groupType == 'COMPANYADMIN' || groupType == 'COMPANYUSER') {
      this.group.plantId = null;
    }
  }

  deleteData(row?: any) {
    let datahead = row.heads;
    let heads=[];
    datahead.forEach((data)=>{
      heads.push(data.headId);
    });
    this.common.loading = true;
    let params = {
      groupId: row.groupId,
      groupName: row.groupName,
      groupStatus: 'DELETED',
      companyId: this.group.companyId,
      plantId: this.group.plantId,
      userType: this.role.user_role,
      groupType: this.group.groupType,
      headIds : heads,
    };

    this.api.post('group/create_group', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertService.info(res.message);
          this.getData();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  async editRow(row?: any) {
  console.log('row: ', row);
    this.common.gotoTop();
    this.resetDetail();
    if (row.company_id != null) {
      //Todo AsyncAwait
      this.getPlantById(row.company_id);
    }
    this.group.groupType = row.groupType;
    this.group.companyId = row.companyId;
    this.group.plantId = row.plantId;
    this.group.id = row.groupId;
    this.group.name = row.groupName;
    this.group.status = row.groupStatus;

    this.companyName = row.companyName;

    this.plantName = row.plantName;
    this.btn = 'update';
    this.title = 'Edit group';
    this.common.gotoTop();
    this.selectedUserIds = row.heads.map((user) => user.headId);
    this.selectedUserNames = row.heads.map((user) => ({
      userid: user.headId,
      name: user.headName
    }));
  }

  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'info' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  formatNames(dataArray: any): string {
    return dataArray.map((item) => item.headName).join(', ');
  }


  groupNameValidator: any;
  statusValidator: any;
  processValidator: any;
  companyValidator: any;
  plantValidator: any;
  typeValidator: any;

  formValidator() {
    this.groupNameValidator = this.common.nullValidator(
      this.group.name,
      'Group Name Mandatory'
    );
    this.typeValidator = this.common.nullValidator(
      this.group.groupType,
      'Group Type Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.group.status,
      'Group Status Mandatory'
    );
    let temp = this.common.checkCompanyPlantAccToUser(
      this.group.groupType,
      this.role.user_role,
      this.group.companyId,
      this.group.plantId
    );
    if (!temp.company) {
      this.companyValidator = this.common.nullValidator(
        this.group.companyId,
        'Company Mandatory'
      );
      return;
    }

    if (this.group.groupType == 'COMPANYUSER') {
      if (
        temp.company &&
        !this.groupNameValidator.error &&
        !this.typeValidator.error &&
        !this.statusValidator.error
      ) {
        this.saveData();
      }
    } else {
      if (!temp.plant) {
        this.plantValidator = this.common.nullValidator(
          this.group.plantId,
          'Plant Mandatory'
        );
        return;
      }

      if (
        temp.company &&
        temp.plant &&
        !this.groupNameValidator.error &&
        !this.typeValidator.error &&
        !this.statusValidator.error
      ) {
        this.saveData();
      }
    }
  }
}

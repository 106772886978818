<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Plant Geofence" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="card-body">
                <div class="row pl-2 pr-2">
                  <div class="col-4 p-0">
                    <div class="left_side">

                      <div class="form-group" *ngIf="isSuperAdmin">
                        <label for="" class="required">{{"select_company" | customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedCompany($event);siteTypeChange();"
                          [data]="allCompanies" display="companyName" placeholder="Choose Company"
                          [preSelected]="{companyName:companyName}" [readonly]="saveBtnText=='update'">
                        </ps-auto-suggestion>
                      </div>

                      <div class="form-group" *ngIf="plantVisible">
                        <label for="" class="required">{{"select_plant" | customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectPlant($event);siteTypeChange();" [data]="plantData"
                          display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}" [readonly]="saveBtnText=='update'">
                        </ps-auto-suggestion>
                      </div>

                      <div class="form-group">
                        <label class="required">{{"site_type" | customTranslate}}</label>
                        <select class="form-control" [(ngModel)]="site.siteType" (change)="resetTypes()" [disabled]="plantVisible && site.plantId==null || saveBtnText=='update'">
                          <option value="0">Select Site Type</option>
                          <option *ngFor="let typeIdx of typeIds;" [value]="typeIdx.id">{{typeIdx.description}}
                          </option>
                        </select>
                      </div>

                      <div class="form-group" *ngIf="site.siteType == 2">
                        <label for="" class="required">{{"select_stage:" | customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="site.stageId = $event.stageId;siteTypeChange();"
                          [data]="stages" display="stageName" placeholder="Choose Stage"
                          [preSelected]="{stageName:stageName}">
                        </ps-auto-suggestion>
                      </div>

                      <div class="form-group" *ngIf="site.siteType == 3">
                        <label for="" class="required">{{"select_destination:" | customTranslate}}</label>
                        <ps-auto-suggestion
                          (onSelected)="site.destinationId = $event.stage_destination_code_id;siteTypeChange();"
                          [data]="allDestinations" display="stage_destination_code" placeholder="Choose Destination"
                          [preSelected]="{stage_destination_code:selectedDestination}">
                        </ps-auto-suggestion>
                      </div>

                      <div class="form-group" *ngIf="site.siteType == 4">
                        <label for="" class="required">{{"select_Parkingzone" | customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="site.parkingZoneId = $event.id;siteTypeChange();" [data]="zoneList"
                          display="zoneName" placeholder="Choose Parking Zone" [preSelected]="{zoneName:selectedZone}">
                        </ps-auto-suggestion>
                      </div>

                      <div class="form-group" *ngIf="site.siteType == 5">
                        <label for="" class="required">{{"select_zone" | customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="site.zoneId = $event.id;siteTypeChange();" [data]="allZoneList"
                          display="zone_name" placeholder="Choose Zone" [preSelected]="{zone_name:zoneSelected}">
                        </ps-auto-suggestion>
                      </div>

                      <div class="form-group" *ngIf="site.siteType == 6">
                        <label for="" class="required">{{"Select Hardware" | customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="site.hardwareId = $event.ipaHardwareId;siteTypeChange();" [data]="hardwareList"
                          display="hardwareName" placeholder="Choose Hardware" [preSelected]="{hardwareName:selectedHardware}">
                        </ps-auto-suggestion>
                      </div>

                      <div class="form-group" *ngIf="site.siteType!=6">
                        <label class="required">{{"geofence_type" | customTranslate}}</label>
                        <select class="form-control" [(ngModel)]="site.geoFenceType" (change)="changeGeoFenceType()" [disabled]="site.siteType==0">
                          <option *ngFor="let geoFence of geoFenceTypes;" [value]="geoFence.type">{{geoFence.name}}
                          </option>
                        </select>
                      </div>

                      <div class="form-group" *ngIf="site.geoFenceType == 2 && site.siteType!=0 && site.siteType!=6">
                        <label class="required">{{"Radius (In Meter)" | customTranslate}}</label>
                        <input type="number" class="form-control" [min]="0" placeholder="Radius" (input)="placeMarkerRadius()"
                          [(ngModel)]="site.radius" >
                        <div class="error" *ngIf="!(site.lat && site.lng)"><span class="error" > {{"Draw Marker First" | customTranslate}} </span></div>
                      </div>

                      <div class="form-group" *ngIf="site.geoFenceType == 2 && site.siteType!=0">
                        <label> {{"Location Center" | customTranslate}} </label>
                        <div class="center-location">
                          <input type="text" class="form-control" (input)="moveMarkerRadius()" placeholder="lat"
                            [(ngModel)]="site.lat">
                          <input type="text" class="form-control" (input)="moveMarkerRadius()" placeholder="long"
                            [(ngModel)]="site.lng">
                        </div>
                      </div>



                      <div class="action-block form-group">
                        <div class="btn-block">
                          <button type="submit" *ngIf="saveBtnText=='Update'" class="btn btn-muted"
                            (click)="cancelMapServices()">{{"cancel" | customTranslate}}</button>
                          <button type="submit" class="btn btn-warning" (click)="resetParams();getGeoFence(null)">{{"clear" |
                            customTranslate}}</button>
                          <button type="submit" class="btn btn-submit" (click)="saveGeoFence();">{{saveBtnText |
                            customTranslate}}</button>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="col-8 pt-0 pr-0 pb-0">

                    <div class="right_side">
                      <div class="form-group location-search position-absolute top-0 end-0 mt-1 me-3 mr-7 ml-2">
                        <!-- <i class="fa fa-search position-absolute top-48 start-0 customTranslate-middle-y ms-5" aria-hidden="true"></i> -->
                        <app-google-map-autocomplete #autocomplete (setAddress)="getAddress($event)">
                        </app-google-map-autocomplete>
                      </div>
                      <div id="map">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-12" >
                    <table class="custom-table" *ngIf="allGeoFenceData.length!=0" datatable [dtOptions]="dtOptions"
                      [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th class="serial-sm">Sr.</th>
                          <th>{{"plant" | customTranslate}}</th>
                          <th>{{"lat_and_long" | customTranslate}}</th>
                          <th class="action">{{"action" | customTranslate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of allGeoFenceData;index as i" [class.active]="plantIndex == i">
                          <td>{{i+1}}</td>
                          <td>{{item.plant_name}}</td>
                          <td>{{ (item.lat ? (item.lat + ',') : '') + (item.long ?? '')}}</td>
                          <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-primary" (click)="openModal(item,i)"
                                *ngIf="user.permissionMap.get('view_plant_geofence_access')==true && plantVisible==true"> {{"view" |
                                customTranslate}} </button>
                              <button class="btn btn-success" (click)="editGeoFence(item,i)"
                                *ngIf="user.permissionMap.get('view_plant_geofence_access')==true"> {{"edit" |
                                customTranslate}} </button>
                              <button class="btn btn-danger" (click)="confirmAlert(item)"
                                *ngIf="user.permissionMap.get('delete_plant_geofence_access')==true && item.geofence_id!=null"> {{"delete" |
                                customTranslate}} </button>
                            </div>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 130%">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{"Plant Geofence" | customTranslate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

          <div class="row no-gutters">
            <div class="col-md-12 mt-2 map-container">
              <div class="map" id="map"></div>
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div> -->

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { MaintenanceSchedule } from 'src/app/dataType/assetInterface';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { daysOfWeek, iterationType, monthOfYear } from '../static-data';
import { staff, staffType, User } from 'src/app/dataType/userInterface';
import { validator } from 'src/app/dataType/commonInterface';
declare var $ : any;


@Component({
  selector: 'app-asset-maintenance',
  templateUrl: './asset-maintenance.component.html',
  styleUrls: ['./asset-maintenance.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.3s', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AssetMaintenanceComponent implements OnInit {
  @Input() public assetId:any;

  assetMaintenance:any = {
    id:null,
    title:null,
    description:null,
    dueDate:null,
    durationType : 0, // FIXED or SCHEDULAR (0 => FIXED and 1 => SCHEDULAR)
    intervalType: 'Daily', // Daily/Monthly/Weekly
    intervalCount: 0, // repeat count
    tolerance : null,
    entityType : 'USER',
    entityid:null,
    intervalData: [ // dayOfWeek / dayOfMonth
      {
        dayOfWeek: null,
        dayOfMonth: null,
        monthOfYear:null,
      },
    ],
  }
  tabData: any = [
    { tabname: 'Scheduled', value: 'SCHEDULED' },
    { tabname: 'Other', value: 'OTHER' },
  ];
  statusType:string='SCHEDULED'
  assetMaintenanceList :MaintenanceSchedule[] = [];
  userList:User[] = [];
  staffTypeList : staffType[] = [];
  staffList : staff[] = [];
  userName:string =null;
  staffTypeId:number=null;
  staffTypeName:string='';
  staffName:string=null;
  status:any;
    //list of iteration type
  iterationType = iterationType
  daysOfWeek =daysOfWeek
  monthOfYear =monthOfYear

  //validator
  titleValidator:validator=null;
  dueDateValidator: validator = null;
  durationTypeValidator: validator = null;
  intervalTypeValidator: validator = null;
  intervalCountValidator: validator = null;
  entityTypeValidator: validator = null;
  entityIdValidator: validator = null;
  staffTypeIdValidator: validator = null;
  intervalDataValidators: validator[] = [];
  dayOfWeekValidator:validator [] = [];
  dayOfMonthValidator:validator [] = [];
  monthOfYearValidator:validator [] = [];


  constructor( private api: ApiService,
    public table: TableService,
    private common: CommonService,
    public modalService: NgbModal,
    public alertService :AlertService,
    public role : RoleService
  ) {}





  ngOnInit() {
    this.getassetMaintenanceList();
    this.getUserList();
  }


  handleTypeChange(type: any) {
    this.assetMaintenance.durationType = type;
    if (type == 0) {
      this.assetMaintenance.data = [];
      this.assetMaintenance.data.push({
        dayOfMonth: null,
        dayOfWeek: null,
        monthOfYear:null,
      });
    this.assetMaintenance.intervalCount=0;
    this.assetMaintenance.intervalData=[];
    }
  }

  handleEntityTypeChange(type:any){
    this.assetMaintenance.entityType = type;
    if(type == 'USER'){
      this.getUserList();
    }else{
      this.getStaffTypeList();
    }
  }

  selectedUsers(event: any) {
    this.assetMaintenance.entityId = event.userid;
    this.userName = event.username;
  }

  selectedStaffType(event:any){
  console.log('event: ', event);
  this.staffName='';
    this.staffTypeId = event.id;
    this.staffTypeName = event.type_name
    this.getStaffList();
  }

  selectedStaff(event: any) {
    this.assetMaintenance.entityId = event.staff_id;
    this.staffName = event.staff_name;
  }

  getStaffTypeList(){

    let params={
      allTypes:true
    }
    this.api.get('staff/get-staff-type-list',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.staffTypeList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  getUserList() {
    let params = {
      userRole : this.role.user_role
    };
    this.api.get('user/get-user-list', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.userList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  getStaffList() {
    let params = {
      allStaff:true,
      staffTypeId:this.staffTypeId
    };
    this.api.get('staff/all-staff', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.staffList = res.data;
          console.log('this.staffList: ', this.staffList);
          this.staffList.forEach(user => {
            user.staff_name = user.name
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }
  createSchedularData() {
    this.assetMaintenance.intervalData = [];
    this.assetMaintenance.intervalCount =
      this.assetMaintenance.intervalType == 'Weekly'
        ? Math.min(this.assetMaintenance.intervalCount, 7)
        : (this.assetMaintenance.intervalType == 'Monthly'
        ? Math.min(this.assetMaintenance.intervalCount, 31):
        this.assetMaintenance.intervalType == 'Yearly'
        ? Math.min(this.assetMaintenance.intervalCount,12)
        : this.assetMaintenance.intervalCount);
    if(this.assetMaintenance.intervalType == 'Daily')return;
    this.assetMaintenance.intervalData = Array(this.assetMaintenance.intervalCount).fill({});
     // Reset the schedularData array
    for (let i = 0; i < this.assetMaintenance.intervalCount; i++) {
      console.log("dfgui");
      this.assetMaintenance.intervalData[i] = {
        dayOfWeek: null,
        dayOfMonth: null,
        monthOfYear:null,
      };
    }
  }

  range(count: number): number[] {
    console.log('Array(count): ', Array(count).fill(0).map((x,i)=>i));
    return Array(count)
      .fill(0)
      .map((x, i) => i);
  }

  checkIntervalCheck(day: any, index) {
    let key=this.assetMaintenance.intervalType=='Yearly'?'monthOfYear':(this.assetMaintenance.intervalType=='Weekly'? 'dayOfWeek' : 'dayOfMonth')
    const count=this.assetMaintenance.intervalData.filter(res=>res[key]==day.target.value).length
    if(count>1){
      this.alertService.error(this.assetMaintenance.intervalType == 'Yearly'?'Month Should Be Different !!':(this.assetMaintenance.intervalType + ' Day Should Be Different !!'))
    }
    this.assetMaintenance.intervalData[index][key]=count==1?day.target.value:''
  }


  saveAssetMaintenance(){
    let data = {
      maintenanceId:this.assetMaintenance.id,
      assetId: this.assetId,
      title : this.assetMaintenance.title.trim(),
      description:this.assetMaintenance.description,
      dueDate:this.assetMaintenance.dueDate,
      durationType : this.assetMaintenance.durationType,
      intervalType: this.assetMaintenance.intervalType,
      intervalCount : this.assetMaintenance.intervalCount,
      toleranceInDays : this.assetMaintenance.tolerance,
      entityType : this.assetMaintenance.entityType,
      entityId:this.assetMaintenance.entityId,
      intervalData: this.assetMaintenance.intervalData
    }
    this.api.post('asset-maintenance/save-asset-maintenance',data).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertService.success(res.message);
          this.common.loading = false;
          this.reset();
          $('#exampleModal').modal('hide');
          this.getassetMaintenanceList();

        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );

  }

  getassetMaintenanceList(){
    this.api.get('asset-maintenance/get-asset-maintenance-list',{assetId:this.assetId}).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.assetMaintenanceList = res.data;
          console.log('this.assetMaintenanceList: ', this.assetMaintenanceList);
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  viewDetails(row?:any){
  console.log('row: ', row);
  const maintenanceDetails={
    complete_at: row.complete_at,
    description: row.description,
    due_date: row.due_date,
    duration_type: row.duration_type,
    entity_name: row.entity_name,
    entity_type: row.entity_type,
    interval_count: row.interval_count,
    interval_data: row.interval_data,
    interval_type: row.interval_type,
    maintenance_cost: row.maintenance_cost,
    remark: row.remark,
    scheduled_at: row.scheduled_at,
    status_name: row.status_name1,
    title: row.title,
    tolerance_in_days: row.tolerance_in_days
  }
    this.common.params = { details: [maintenanceDetails], title: 'Maintenance Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  reset(){
    this.assetMaintenance = {
      id:null,
      title:null,
      description:null,
      dueDate:null,
      durationType : 0, // FIXED or SCHEDULAR (0 => FIXED and 1 => SCHEDULAR)
      intervalType: 'Daily', // Daily/Monthly/Weekly
      intervalCount : null, // repeat count
      intervalData :[
        {
          dayOfMonth: null,
          dayOfWeek: null,
          monthOfYear:null,
        },
      ],
      tolerance : null,
      entityType : 'USER',
      entityid:null
    }
    this.userName=null;
    this.staffTypeId=null;
    this.staffTypeName=null;
    this.staffName=null;
    this.status=null;
    this.titleValidator=null;
    this.dueDateValidator = null;
    this.durationTypeValidator = null;
    this.intervalTypeValidator = null;
    this.intervalCountValidator = null;
    this.entityTypeValidator = null;
    this.entityIdValidator = null;
    this.staffTypeIdValidator = null;
    this.intervalDataValidators = [];
    this.monthOfYearValidator=[];
    this.dayOfWeekValidator=[];
    this.dayOfMonthValidator=[];
    this.getUserList();
}


editLog(row:any){
  this.reset();
   this.assetMaintenance.id=row.id;
   this.assetMaintenance.title=row.title;
   this.assetMaintenance.description=row.description;
   this.assetMaintenance.dueDate=row.due_date;
   this.assetMaintenance.durationType=row.duration_type;
   this.assetMaintenance.intervalType=row.interval_type;
   this.assetMaintenance.intervalCount=row.interval_count;
   this.assetMaintenance.intervalData=row.interval_data;
   this.assetMaintenance.tolerance=row.tolerance_in_days;
   this.assetMaintenance.entityType=row.entity_type;
   this.assetMaintenance.entityId=row.entity_id;
    if(this.assetMaintenance.entityType == 'USER'){
      this.userName=row.entity_name;
      this.getUserList();
    }else{
      this.staffTypeName=row.type_name;
      this.staffName=row.entity_name;
      this.staffTypeId=row.staff_type_id;
      this.getStaffTypeList();
      this.getStaffList();
    }
}


confirmAlert(row: any) {
  this.common.params = {
    title: 'Delete  Asset Maintenance',
    description:
      `<b>&nbsp;` + 'Are you sure you want to delete Asset Maintenance?' + `<b>`,
  };
  const activeModal = this.modalService.open(ConfirmationDialogComponent, {
    size: 'md',
  });
  activeModal.result.then((data: any) => {
    if (data.response) {
      this.delete(row);
    }
  });

}

delete(row?: any) {
  let params = {
    MaintenanceId: row.id
  };
  this.api.get('asset-maintenance/delete-asset-maintenance', params).subscribe(
    (res: any) => {
      if (res.status) {
        this.common.loading = false;
        this.reset();
        this.getassetMaintenanceList();
        this.alertService.info(res.message);
      }
    },
    (err: any) => {
      console.error('Error: ', err);
      this.common.loading = false;
    }
  );
}


formValidator() {
  this.titleValidator = this.common.nullValidator(this.assetMaintenance.title && this.assetMaintenance.title.trim(), 'Title Mandatory');
  this.dueDateValidator = this.common.nullValidator(this.assetMaintenance.dueDate, 'Due Date Mandatory');
  this.intervalCountValidator = this.common.nullValidator(this.assetMaintenance.intervalCount, 'Interval Count Mandatory');
  this.entityIdValidator = this.common.nullValidator(this.assetMaintenance.entityId, this.assetMaintenance.entityType == 'USER' ? 'User Mandatory' : 'Staff Mandatory');
  this.staffTypeIdValidator = this.common.nullValidator(this.staffTypeId, 'Staff type Mandatory');

  if(this.assetMaintenance.durationType==1){
    if(this.assetMaintenance.intervalType == 'Weekly'){
      this.assetMaintenance.intervalData.forEach((e, index) => {
        this.dayOfWeekValidator[index] = this.common.nullValidator(
          e.dayOfWeek,
          'Day Selection Mandatory'
        );
      });
    }else if(this.assetMaintenance.intervalType == 'Yearly'){
      this.assetMaintenance.intervalData.forEach((e, index) => {
        this.monthOfYearValidator[index] = this.common.nullValidator(
          e.monthOfYear,
          'Month Selection Mandatory'
        );
      });
    }else{
      this.assetMaintenance.intervalData.forEach((e, index) => {
        this.dayOfMonthValidator[index] = this.common.nullValidator(
          e.dayOfMonth,
          'Month Date Selection Mandatory'
        );
      });
    }
  }

// no use of !== staff
  if(this.assetMaintenance.durationType==0){
    if(!this.dueDateValidator.error &&
      !this.entityIdValidator.error&&
      (this.assetMaintenance.entityType!=='STAFF'||!this.staffTypeIdValidator.error)
      ){
        this.saveAssetMaintenance();
      }
  }
  else if(this.assetMaintenance.durationType==1 && this.assetMaintenance.intervalType == 'Weekly'){
    if (
      !this.dueDateValidator.error &&
      !this.intervalCountValidator.error &&
      !this.entityIdValidator.error&&
      (this.assetMaintenance.entityType!=='STAFF'||!this.staffTypeIdValidator.error) &&
      !this.dayOfWeekValidator.some((validator) => validator.error)
      ) {
       this.saveAssetMaintenance();
    }
 }else if(this.assetMaintenance.durationType==1 && this.assetMaintenance.intervalType == 'Yearly'){
  if(!this.dueDateValidator.error &&
    !this.intervalCountValidator.error &&
    !this.entityIdValidator.error&&
    (this.assetMaintenance.entityType!=='STAFF'||!this.staffTypeIdValidator.error) &&
    !this.monthOfYearValidator.some((validator) => validator.error)){
      this.saveAssetMaintenance();
    }
 }
 else{
  if (
    !this.dueDateValidator.error &&
    !this.intervalCountValidator.error &&
    !this.entityIdValidator.error&&
    (this.assetMaintenance.entityType!=='STAFF'||!this.staffTypeIdValidator.error) &&
    !this.dayOfMonthValidator.some((validator) => validator.error)
    ) {
     this.saveAssetMaintenance();
    }
  }
}

iterationChange(){
  this.assetMaintenance.intervalCount=0;
  this.assetMaintenance.intervalData = [
    {
      dayOfMonth: null,
      dayOfWeek: null,
      monthOfYear:null
    },
  ];
}

}

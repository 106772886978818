import { ApiService } from '../../@core/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProcessStageService {

constructor(public api:ApiService, public dialog:MatDialog){

}
currentProcess:string = '';
currentStage:string = '';
processIndex:number = 0;
stageindex:number = 0;
tripdata:any[] =  [];
destinationUpdate:boolean = false;
stageUpdate:boolean = false;
tripviewbase:string = '';



}

<div class="login_page">
  <div class="row login_block">
    <div class="col-md-5 login_section">
      <img src="assets/images/logo.png">
      <div class="welcome">Welcome Back</div>
      <div class="cpl_name">Connected Plant Logistics</div>

      <div class="alert" [ngClass]="{'alert-danger':loginfail,'alert-success':!loginfail}" role="alert"
        *ngIf="showmessage">
        <p class="mb-0"> {{loginmessage}} </p>
      </div>

      <div class="login_form">
        <mat-form-field class="form-group col-sm-12">
          <div class="icon">
            <i class="fa fa-user" aria-hidden="true"></i>
          </div>

          <input matInput type="text" placeholder="Username" aria-label="username" aria-describedby="email"
            [(ngModel)]="user.userName">
          <div class="error" *ngIf="userNameValidator && userNameValidator.error">
            <span>{{userNameValidator.msg}} </span>
          </div>
        </mat-form-field>

        <mat-form-field class="form-group col-sm-12">
          <div class="icon">
            <i class="fa fa-key" aria-hidden="true"></i>
          </div>

          <input matInput type="password" placeholder="Password" aria-label="password" aria-describedby="password"
            [(ngModel)]="user.password">
          <div class="error" *ngIf="passwordValidator && passwordValidator.error">
            <span>{{passwordValidator.msg}} </span>
          </div>
        </mat-form-field>

        <a class="send_link" routerLink="/auth/forgot-password">forgot password</a>

        <div class="auth-action">
          <button type="submit" class="btn btn-submit" [disabled]="btndisabled" (click)="formValidator()"> login
          </button>
        </div>
      </div>

      <div class="powered_view">
        <a href="https://axestrack.com/">powered by Axestrack</a>
        <span (click)="routeNagivate()" class="powered" style="text-decoration: underline;
        font-size: 12px;
        margin-top: 12px; cursor: pointer;">VMS Panel</span>
      </div>
    </div>

    <div class="col-md-6 offset-md-1 gif_sec">
      <img src="assets/images/plant_gif.gif">
    </div>
  </div>
</div>

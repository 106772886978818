import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CommunicationTempService  extends ApiService  {

  saveTemplate(data:any):Observable<any>{
    let apiSubUrl = "communication/save-communication-template";
    return super.post(apiSubUrl,data,null);
  }

  updateTemplate(data:any):Observable<any>{
    let apiSubUrl = "communication/update-communication-template";
    return super.post(apiSubUrl,data,null);
  }

  getVariables():Observable<any>{
    let apiSubUrl = "communication/get-all-title";
    return super.get(apiSubUrl,null);
  }

  getAllTemplate():Observable<any>{
    let apiSubUrl = "communication/get-all-communication-template";
    return super.get(apiSubUrl,null);
  }

  getCommunication(){
    let apiSubUrl = "communication/list_communicationType";
    return super.get(apiSubUrl,null);
  }

}

import { map } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ProcessService } from '../process/process.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import * as _ from 'lodash';
import { UserService } from 'src/app/@core/services/user.service';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { FormatNamesPipe } from 'src/app/@core/pipes/formatNames.pipe';

@Component({
  selector: 'app-zone-allocation',
  templateUrl: './zone-allocation.component.html',
  styleUrls: ['./zone-allocation.component.scss'],
})
export class ZoneAllocationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'Stage Zone Mapping List');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    public api: ApiService,
    public common: CommonService,
    public table: TableService,
    public alertService: AlertService,
    public modal: NgbModal,
    public processService: ProcessService,
    public breadcrumbService: BreadcrumbService,
    public role: RoleService,
    private plantService: PlantService,
    private userService: UserService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Zone', url: null },
      { name: 'Zone Allocation', link: '/user/zone-allocation' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  companyId: number = null;
  plantId: number = null;
  companyName: null;
  plantName: null;
  companyData: any = [];
  plantData: any = [];
  selectedZoneId: number = null;
  selectedZoneName: any = null;
  title: string = 'Add Zone Allocation';
  btn: string = 'save';
  zoneAllocationList: any[] = [];
  groupedDataByZone: any;
  mappingType: any = 'USER';
  zoneList: any[] = [];
  staffTypeList: any[] = [];
  userList: any = [];
  staffList: any = [];
  groupList: any = [];
  deptList: any = [];

  staffTypeId: number = null;
  staffTypeName: string = null;

  selectedUserList: any = [];
  selectedStaffList: any = [];
  selectedDeptList: any = [];
  selectedGroupList: any = [];
  isEdit: boolean = false;
  entityIds:any [] = [];

  companyValidator: any = null;
  plantValidator: any = null;
  zoneValidator: any = null;
  entityValidator:any = null;





  ngOnInit() {
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else if (this.role.user_role == 'COMPANYADMIN') {
      this.companyId = this.userService._loggedInUser.companyId;
      this.getPlantById();
    } else {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
      this.getZones();
    }
    this.onMappingTypeChange(this.mappingType);
    this.getAllZoneAllocation();
  }

  async onMappingTypeChange(type: any) {
    if (this.mappingType == 'USER') {
      await this.getUserList();
      this.getMappedEntities(this.mappingType);
    } else if (this.mappingType == 'STAFF') {
      this.getStaffTypeList();
    } else if (this.mappingType == 'DEPARTMENT') {
      await this.getDepartmentList();
      this.getMappedEntities(this.mappingType);
    } else if (this.mappingType == 'GROUP') {
      await this.getGroupList();
      this.getMappedEntities(this.mappingType);
    }
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = null;
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getZones();
  }

  selectedZone(event: any) {
    console.log('event: ', event);
    this.selectedZoneId = event.id;
    this.selectedZoneName = event.zone_name;
  }

  selectedUsers(event: any) {
    console.log('event: ', event);
    this.selectedUserList = event.map((e) => ({
      userid: e.userid,
      name: e.name
    }));
    this.entityIds = this.selectedUserList.map(user => user.userid);
  }

  async onSelectStaffType(event: any) {
    console.log('event', event);
    this.staffTypeId = event.id;
    this.staffTypeName = event.type_name;
    this.selectedStaffList=[];
    await this.getStaffList();
    this.getMappedEntities(this.mappingType);
  }

  onStaffSelection(event: any) {
    console.log('event: ', event);
    this.selectedStaffList = event.map((e) => ({
      staff_id : e.staff_id,
      staff_name: e.staff_name
    }));
    this.entityIds = this.selectedStaffList.map(staff => staff.staff_id);
  }

  selectedDepartment(event: any) {
    console.log('event: ', event);
    this.selectedDeptList = event.map((e) => ({
      dept_id : e.dept_id,
      dept_name: e.dept_name
    }));
    this.entityIds = this.selectedDeptList.map(dept => dept.dept_id);
  }

  selectedGroup(event: any) {
    console.log('event: ', event);
    this.selectedDeptList = event.map((e) => ({
      group_id : e.group_id,
      group_name: e.group_name
    }));
    this.entityIds = this.selectedDeptList.map(group => group.group_id);
  }
  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        id: id,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.plantData = res.data;
            resolve(this.plantData);
          }
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  getZones(id?: any) {
    this.common.loading = true;
    let params = {
      plantId: this.plantId,
      allZones : false
    };
    this.api.get('zone/get-all-zone-stage', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.zoneList = res.data;
        console.log('this.zoneData: ', this.zoneList);
        this.renderTable();
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }
  getUserList(): Promise<any>  {
    return new Promise((resolve, reject) => {
    let params = {
      companyId: this.companyId,
      plantId: this.plantId,
      userRole: this.role.user_role,
    };
    // this.common.loading = true;
    this.api.get('user/get-user-list', params).subscribe(
      (res: any) => {
        if (res.status) {
          // this.common.loading = false;
          this.userList = res.data;
          resolve(this.userList);
        }
      },
      (err: any) => {
        reject(null);
        console.error('Error: ', err);
        // this.common.loading = false;
      }
    );
  });
  }

  getStaffTypeList(): Promise<any> {
    return new Promise((resolve, reject) => {
    // this.common.loading = true;
    let params = {
      allTypes: true,
    };
    this.api.get('staff/get-staff-type-list', params).subscribe(
      (res: any) => {
        if (res.status) {
          // this.common.loading = false;
          this.staffTypeList = res.data;
          resolve(this.staffTypeList);
        }
      },
      (err: any) => {
        // this.common.loading = false;
        reject(null);
        console.error('Error: ', err);
      }
    );
  });
  }

  getStaffList(): Promise<any> {
    return new Promise((resolve, reject) => {
    let params = {
      companyId: this.companyId,
      plantId: this.plantId,
      allStaff: false,
      staffTypeId: this.staffTypeId,
    };
    // this.common.loading = true;
    this.api.get('staff/all-staff', params).subscribe(
      (res: any) => {
        if (res.status) {
          // this.common.loading = false;
          this.staffList = res.data;
          this.staffList.forEach(staff => {
            staff.staff_name = staff.first_name + ' ' + staff.last_name;
          });
          resolve(this.staffList);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        // this.common.loading = false;
        reject(null);
      }
    );
  });
}

  getDepartmentList(): Promise<any> {
    return new Promise((resolve, reject) => {
    // this.common.loading = true;
    let params = {
      companyId: this.companyId,
      plantId: this.plantId,
      allDepartments: false,
    };
    this.api.get('department/get-department-list', params).subscribe(
      (res: any) => {
        if (res.status) {
          console.log("wssqu8qi",this.selectedDeptList);
          // this.common.loading = false;
          this.deptList = res.data;
          resolve(this.deptList);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        // this.common.loading = false;
        reject(null);
      }
    );
  });
  }

  getGroupList() : Promise<any> {
    return new Promise((resolve, reject) => {
    let params = {
      userRole: this.role.user_role,
    };
    // this.common.loading = true;
    this.api.get('group/get-group-list', params).subscribe(
      (res: any) => {
        if (res.status) {
          // this.common.loading = false;
          this.groupList = res.data;
          resolve(this.groupList);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        // this.common.loading = false;
        reject(null);
      }
    );
  });
  }

  saveZoneAllocation() {
    this.common.loading = true;
    let params = {
      zoneId : this.selectedZoneId,
      entityIds: this.entityIds,
      // isEdit: this.isEdit,
      entityType : this.mappingType,
      staffTypeId:this.staffTypeId
    };
    console.log('params: ', params);
      this.api.post('zone/save-zone-allocation', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertService.info(res.message);
          this.reset();
          this.isEdit = false;
          this.getAllZoneAllocation();
          this.common.loading = false;
        }
      },
      (err) => {
        this.common.loading = false;
        console.error(err);
      }
    );
  }

  getAllZoneAllocation() {
    this.common.loading = true;
    this.api.get('zone/get-zone-allocation-list').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.zoneAllocationList = res.data;
          // this.groupedDataByZone = lodash.groupBy(
          //   this.zoneAllocationList,
          //   'zone_name'
          // );
          // this.groupedDataByZone = _.groupBy(this.zoneAllocationList, (item) => {
          //   // return `${item.zone_name}`;
          //   // return item.entity_type=='STAFF'? `${item.zone_name} (${item.type_name})`:`${item.zone_name} (${item.entity_type})`;

          // });
          this.groupedDataByZone = _.groupBy(this.zoneAllocationList, (item) => {
            const baseKey = `${item.zone_name}|${item.entity_type}`;
            return item.entity_type === 'STAFF' ? `${baseKey}|${item.type_name}` : baseKey;
          });

          // Simplify keys if necessary
          this.groupedDataByZone = _.mapKeys(this.groupedDataByZone, (value, key) => {
            const keys = key.split('|');
            return keys.join('_');
          });
          console.log('this.groupedDataByZone: ', this.groupedDataByZone);
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  // formatNames(dataArray: any): string {
  //   return dataArray.map((item) => item.entity_name).join(', ');
  // }

  async edit(row: any) {
  console.log('row: ', row);
    this.reset();
    this.common.gotoTop();
    this.btn = 'Update';
    this.title = 'Edit Zone Allocation';
    this.isEdit = true;
    this.mappingType = row.value[0].entity_type;
    this.selectedZoneId =  row.value[0].zone_id;
    this.selectedZoneName =  row.value[0].zone_name;
    this.companyName=row.value[0].company_name;
    this.companyId=row.value[0].company_id;
    this.plantId=row.value[0].plant_id;
    console.log('this.plantId: ', this.plantId);
    this.plantName=row.value[0].plant_name;
    this.role.user_role=='AXESTRACK'? await this.getPlantById(row.value[0].company_id):(this.role.user_role=='COMPANYADMIN'?this.getPlantById(this.userService._loggedInUser.company_id):null);
    if(row.value[0].entity_type == 'USER'){
      // this.getUserList();
      this.selectedUserList = row.value.map((entity) => ({
        userid: entity.entity_id,
        name: entity.entity_name,
      }));
    } else if(row.value[0].entity_type == 'STAFF'){
      await this.getStaffTypeList();
      this.staffTypeId = row.value[0].staff_type_id;
      this.staffTypeName = row.value[0].type_name;
      await this.getStaffList();
      console.log('row.value: ', row.value);
      this.selectedStaffList = row.value.map((entity) => ({
        staff_id : entity.entity_id,
        staff_name: entity.entity_name
      }));
    }else if(row.value[0].entity_type == 'DEPARTMENT'){
      await this.getDepartmentList();
      console.log('this.selectedDeptList: ',  row.value);
      this.selectedDeptList = row.value.map((entity) => ({
        dept_id: entity.entity_id,
        dept_name: entity.entity_name,
      }));
    }else if(row.value[0].entity_type == 'GROUP'){
      await this.getGroupList();
      this.selectedGroupList = row.value.map((entity) => ({
        group_id: entity.entity_id,
        group_name: entity.entity_name,
      }));
      console.log('this.selectedGroupList: ', this.selectedGroupList);
    }
  }


  reset() {
    this.mappingType ='USER';
    this.plantId=null;
    this.plantName=null;
    this.companyId=null;
    this.companyName=null;
    this.selectedUserList = [];
    this.selectedStaffList = [];
    this.selectedDeptList = [];
    this.selectedGroupList = [];
    this.staffTypeId = null;
    this.staffTypeName = null;
    this.selectedZoneId = null;
    this.selectedZoneName = null;
    // this.selectedOrderId = null;
    // // this.selectedStageName=null;
    // this.selectedOrderName = null;
    // this.selectedZoneIds = [];
    // this.selectedZoneNames = [];
    // this.stageValidator = null;
    this.zoneValidator = null;
    this.companyValidator = null;
    this.plantValidator = null;
    this.entityValidator = null;
    this.btn = 'Save';
    this.isEdit=false;
  }


  view(row?:any){
    // console.log('row: ',new FormatNamesPipe().transform(row.value,'entity_name'));
    // console.log('row: ',row);
    let vals=row.value?row.value.map(item => item.entity_name).join(', '):null;
    console.log(vals);
    let viewObj = {
      zone: row.value[0].zone_name,
      // mappedentities: new FormatNamesPipe().transform(row.value,'entity_name'),
      'mapped entities':vals,
      plant: row.value[0]?.plant_name,
      company: row.value[0]?.company_name,
      entityType : row.value[0]?.entity_type
    };
      this.common.params = { details: [viewObj], title: 'Zone Allocation Details' };
      const activeModal = this.modal.open(ViewDetailsComponent, {
        size: 'lg',
      });
    }

    confirmDelete(row?:any){
      console.log("hgqwu",row);
      this.common.params = {
        title: 'Delete Zone Allocation ',
        description:
          `<b>&nbsp;` +
          'Are you sure you want to delete ' +
          row.value[0].zone_name +
          ' ?' +
          `<b>`,
      };
      const activeModal = this.modal.open(ConfirmationDialogComponent, {
        size: 'md',
      });
      activeModal.result.then((data: any) => {
        if (data.response) {
          this.delete(row);
        }
      });
    }

    delete(row: any) {
    console.log('row: ', row);
      let param={
        zoneId:row.value[0].zone_id,
        entityType:row.value[0].entity_type,
        staffTypeId:row.value[0].staff_type_id
      }
      this.api.get("zone/delete-zone-allocation",param).subscribe(
        (res:any)=>{
          this.common.loading=false;
          if(res.status){
           console.log('res.status: ', res.status);
            this.alertService.success(res.message);
            this.getAllZoneAllocation();
          }
        },
        (err:any)=>{
          this.common.loading = false;
          console.error('Error: ', err);
        }
      );
    }

  formValidator() {
    this.companyValidator = this.common.nullValidator(this.companyId,'Company Mandatory');
    this.plantValidator = this.common.nullValidator(this.plantId,'Plant Mandatory');
    this.zoneValidator = this.common.nullValidator(this.selectedZoneId,'Zone Mandatory');
    console.log("sygsqw",this.selectedStaffList);
    this.entityValidator = this.mappingType=='USER' ? this.common.nullValidator(this.selectedUserList,'User Mandatory') : this.mappingType == 'STAFF' ?
                            this.common.nullValidator(this.selectedStaffList,'Staff Mandatory') : this.mappingType == 'DEPARTMENT' ?
                            this.common.nullValidator(this.selectedDeptList,'Department Mandatory') : this.common.nullValidator(this.selectedGroupList,'Group Mandatory');
    if(this.role.user_role == 'AXESTRACK'){
      if(!this.companyValidator.error && !this.plantValidator.error && !this.zoneValidator.error && !this.entityValidator.error){
        this.saveZoneAllocation();
      }
    }else if(this.role.user_role == 'COMPANYADMIN'){
      if(!this.plantValidator.error && !this.zoneValidator.error && !this.entityValidator.error){
        this.saveZoneAllocation();
      }
    }else{
      if(!this.zoneValidator.error && !this.entityValidator.error){
        this.saveZoneAllocation();
      }
    }

  }

  getMappedEntities(mappingT:any){
    if(mappingT=='GROUP'){
      this.selectedGroupList=this.zoneAllocationList.filter((zone)=>{
        return zone.zone_id==this.selectedZoneId && zone.entity_type==mappingT
      }).map((entity) => ({
        group_id: entity.entity_id,
        group_name: entity.entity_name,
      }));
      console.log("ghjklkjhgfh",this.selectedGroupList);
    }else if(mappingT=='DEPARTMENT'){
      this.selectedDeptList=this.zoneAllocationList.filter((zone)=>{
        return zone.zone_id==this.selectedZoneId && zone.entity_type==mappingT
      }).map((e) => ({
        dept_id : e.entity_id,
        dept_name: e.entity_name
      }));
      console.log("ghjklkjhgfh",this.selectedDeptList);
    }else if(mappingT=='USER'){
      this.selectedUserList=this.zoneAllocationList.filter((zone)=>{
        return zone.zone_id==this.selectedZoneId && zone.entity_type==mappingT
      }).map((e) => ({
        userid: e.entity_id,
        name: e.entity_name
      }))
      console.log("ghjklkjhgfh",this.selectedUserList);
    }else{
      console.log("judhw",this.staffTypeName);
      this.selectedStaffList=this.zoneAllocationList.filter((zone)=>{
        return zone.zone_id==this.selectedZoneId && zone.entity_type==mappingT && zone.type_name==this.staffTypeName
      }).map((e) => ({
        staff_id : e.entity_id,
        staff_name: e.entity_name
      }));
    }
    console.log("ghjklkjhgfh",this.selectedStaffList);
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"Audit Logs" | translate}}
            </h3>
          </div>
        </div>
        <div class="card-body ">
          <div class="row">
            <div class="tab_btnn_sec">
              <div class="tab_btnn" *ngFor="let tab of actionLabels;index as i">
                <button type="button" class="btn btn-block nav-buttons" (click)="changeTabs(i , tab)"
                  [ngClass]="{'btn-inactive':activeTab != i,'btn-active':activeTab == i}" *ngIf="tab.permission"
                  [ngStyle]="{'background-color': tab.tabBgColor}">
                  <div class="left_part">
                    <div class="tab_name">{{tab.tabName | translate}}</div>
                    <div class="tab_number">{{listAuditCount[tab.count]}}</div>
                  </div>

                  <div class="right_part">
                    <div class="icon_div" [ngStyle]="{'background-color': tab.tabIconBg}">
                      <i [class]="'fa fa-'+tab.tab_icon" [ngClass]="tab.tabName"></i>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div class="col-12">

            </div>

            <div class="col-md-12">
              <table #ref class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{"s.no." | translate}} </th>
                    <th>{{"vehicle_number" | translate}} </th>
                    <th>{{("audited" | translate)+" "+("action" | translate)}} </th>
                    <th>{{"stage" | translate}} </th>
                    <th>{{"process" | translate}} </th>
                    <th>{{("audited" | translate)+" "+("on" | translate)+" "+("stage" | translate)}} </th>
                    <th>{{"action" | translate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of filterList; let i = index;">
                    <td>{{i + 1}}</td>
                    <td appEllipsisView>{{item.vehicle_no}}</td>
                    <td>{{item.action_name}}</td>
                    <td>
                      <span class="badge status-badge"
                        style="background-color: #f7d5d5; font-size:12px;">{{item.action_stage_name}}</span>
                    </td>
                    <td> {{item.process_name}} </td>
                    <td>
                      <span class="badge status-badge"
                        style="background-color: #f7d5d5; font-size:12px ;">{{item.mapped_to_stage_name}}</span>
                    </td>
                    <td class="action">
                      <div class="btn-block" *ngIf="item.status=='PENDING' ">
                        <button class="btn btn-danger" (click)="confirmAlert(item)">{{"delete" | translate}}</button>
                      </div>
                      <div class="btn-block" *ngIf="item.status!='PENDING' ">
                        <button class="btn btn-primary text-capitalize" (click)="viewDetails(item)"
                          *ngIf="user.permissionMap.get('view_audit_logs_access')==true"> {{"view" | translate}}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AssetRequestService extends ApiService{

  saveAssetRequest(data:any):Observable<any>{
    console.log("params",data);
    let _url="asset_requests/save-asset-request";
    return super.post(_url,data);
  }
  getRequestedAsset(data:any):Observable<any>{
    return super.get("asset_requests/get-requested-assets",data);
  }
}

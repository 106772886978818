<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1,false)">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">{{ title | translate}}</h5>
</div>

<div class="modal-body">
  <div class="title">{{"field_types"|translate}}</div>

  <div class="field-type">
    <button *ngFor="let field of fieldTypes" (click)="selectFieldType(field.fieldType)"
      [ngClass]="{'selected': field.fieldType === customField.type}">
      <i [class]="'fas fa-'+field.fieldIcon" [ngClass]="field.fieldType"></i>
      <span>{{ field.fieldType }}</span>
    </button>
  </div>

  <div class="row">
    <div class="form-group col-xl-4 col-lg-4 col-sm-12">
      <label for="my-input" class="required">{{'Field Name'|translate}} </label>
      <input type="text" class="form-control" name="name" required placeholder="Enter Master Field Name"
        [(ngModel)]="customField.name" #name="ngModel" autocomplete="off">
      <div class="error" *ngIf="fieldNameValidator && fieldNameValidator.error">
        <span>{{fieldNameValidator.msg}} </span>
      </div>
    </div>

    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
      <div class="form-group">
        <label for="formFieldType" class="required">{{("Form Field Type" | translate)}}</label>
        <select id="actionStatus" class="form-control" name="formFieldType"
          [(ngModel)]="formFieldType">
          <option value="FIXED">{{("FIXED" | translate)}}</option>
          <option value="REMOVABLE">{{("REMOVABLE" | translate)}}</option>
        </select>
      </div>
    </div>

    <!-- //use of ng class for disabled-div class because no direct negation; -->
    <div class="form-group col-xl-4 col-lg-4 col-sm-12" *ngIf = "customField.type =='SELECTLISTSINGLE' || customField.type == 'SELECTLISTMULTIPLE' || customField.type == 'RADIOBUTTON' || customField.type == 'CHECKBOX'">
      <label for="my-input">{{"Choices"| translate}}</label>
      <ps-auto-suggestion (onSelected)="createdField($event)" [data]='[]' display="name"
        [preSelected]="customField.values" placeholder="Enter Choices" isMultiSelect="true" [onInputTrigger]="true">
      </ps-auto-suggestion>
      <div class="error" *ngIf="choiceValidator && choiceValidator.error">
        <span>{{choiceValidator.msg}} </span>
      </div>
    </div>

    <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="customField.type=='FIELDGROUP'">
      <label>{{"num_of_subgroups"| translate}}</label>
      <input type="number" name="subgroups" class="form-control" [value]="subgroupsCount" [min]="1" id="subgroupscount" [max]="4" (change)="changeNoOfSubGroups($event)">
    </div>

    <div *ngIf="customField.type=='FIELDGROUP'" style="display: flex;">
      <div *ngFor="let subgroup of subgroupCountRange;index as id" [ngStyle]="{ 'margin-left.px':id != 0 ? -190 : 17}">
        <div style="width: 50%;">
          <div class="form-group col-md-8" style="background-color:#eee;margin: 0px 12px 0px 20; max-width: fit-content;">
          <label for="my-input">{{'subgroup_name'|translate}}</label>
        <input type="text" style="width: 180px;" placeholder="Enter SubGroup Name" name="subgroupname" class="form-control" (change)="enterSubGroupName(id,$event)" [id]="'sub'+id" [value]="subgroupsName[0][id].name">
      <!-- <div class="form-group col-md-8" style="background-color:#eee;margin: 0px 12px 0px 20;"> -->
        <label for="my-input">{{'select_fields'|translate}}</label>
        <div class="row" *ngFor="let value of subgroupsName; let i=index;">
          <div class="col-md-4 form-group">
            <div class="input-group-append">
                <ps-auto-suggestion (onSelected)="selectedField($event,i,id)" [data]="filteredMasterFields"
                display="field_name" placeholder="Choose Field" [inputId]="'sub'+id+i"
                [preSelected]="{field_name:this.subgroupsName[i][id].values.fieldName}">
              </ps-auto-suggestion>
              <span class="input-group-text remove-field" id="my-addon" *ngIf="(i !== 0 && id + 1 == this.subgroupsCount)||(i==0 && subgroupsName.length>1 && id + 1 == this.subgroupsCount)"
              (click)="spliceRow(i,id)"> <i class="fa fa-trash remove"></i></span>
            <span class="input-group-text add-field" id="my-addon" [ngStyle]="{'margin-left.px':i== 0 ? 30 : 10}"
              *ngIf="i == subgroupsName.length-1 && id + 1 == this.subgroupsCount" (click)="addSubGroupValues(id)">
              <i class="fa fa-plus add"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>



        <div class="form-group col-xl-12 col-lg-12 col-sm-12" >
      <label  for="my-input">{{"Select Database Field"| translate}}</label>
      <ps-auto-suggestion (onSelected)="selectedDBField($event)" [data]='databaseFields' display="databaseFieldName"
      [preSelected]="{databaseFieldName:databaseFieldName}" placeholder = "Select DBfield">
      </ps-auto-suggestion>
      <div class="error" *ngIf="databaseFieldValidator && databaseFieldValidator.error">
        <span>{{databaseFieldValidator.msg}} </span>
      </div>
    </div>

    <div class="form-group col-xl-4 col-lg-6 col-sm-12" *ngIf="customField.type=='LABEL'">
      <label for="my-input">{{('field_value'|translate)+' '+('label'|translate)}}</label>
      <ckeditor [editor]="Editor" [(ngModel)]="detail"></ckeditor>
    </div>

    <div class="form-group col-lg-6 col-sm-12">
      <label for="fieldValidation">{{'Field Validation'|translate}} </label>
      <div class="boxes" style="display: flex; flex-wrap: nowrap;width: 100%;">
      <div class="pattern" style="width:25%;">
        <input type="text" class="form-control" name="prefixPattern" required placeholder="Prefix"
          [(ngModel)]="customField.pattern_prefix" #prefixPattern="ngModel" autocomplete="off" style="border-radius: 0cm;">
      </div>

      <div class="regEx" style="width:50%;">
        <input type="text" class="form-control" name="regexPattern" required placeholder="Regular Expression"
          [(ngModel)]="customField.regex_pattern" #regexPattern="ngModel" autocomplete="off" style="border-radius: 0cm;">
      </div>

      <div class="pattern" style="width:25%;">
        <input type="text" class="form-control" name="postfixPattern" required placeholder="Postfix"
          [(ngModel)]="customField.pattern_postfix" #postfixPattern="ngModel" autocomplete="off" style="border-radius: 0cm;">
      </div>
    </div>
    </div>
  </div>

  <div class="note" *ngIf="customField.type=='FIELDGROUP'">
    <span class="star">*</span><span class="note_header">Note :</span><span class="note_text">{{" Only 4 Sub Groups are recommended."}}</span>
  </div>
  <div class="form-group col-xl-4 col-lg-4 col-sm-12 d-flex justify-content-between" >
    <div>
      <label for="" class="d-block"> {{'mandatory'|translate}} </label>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id='customSwitchesMandatory'
          [(ngModel)]="customField.isMandatory" (click)="changeStatus(customField.isMandatory)">
        <label class="custom-control-label" for="customSwitchesMandatory"></label>
      </div>
    </div>

    <div>
      <label for="" class="d-block"> {{'unique'|translate}} </label>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id='customSwitchesUnique'
          [(ngModel)]="customField.isUnique" (click)="changeStatus(customField.isUnique)">
        <label class="custom-control-label" for="customSwitchesUnique"></label>
      </div>
    </div>

    <div>
      <label for="" class="d-block"> {{'Multivalue'|translate}} </label>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id='customSwitchesMultivalue'
          [(ngModel)]="customField.isMultiValue" (click)="changeStatus(customField.isMultiValue)">
        <label class="custom-control-label" for="customSwitchesMultivalue"></label>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-warning" (click)="resetDetail()"> {{ "reset" | translate }} </button>
  <button type="button" class="btn btn-success" (click)="formValidator()">{{btn | translate}}</button>
</div>

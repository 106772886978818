import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { ViewRemarkComponent } from 'src/app/modals/view-remark/view-remark.component';

@Component({
  selector: 'app-status-wise-checklist-logs',
  templateUrl: './status-wise-checklist-logs.component.html',
  styleUrls: ['./status-wise-checklist-logs.component.scss'],
})
export class StatusWiseChecklistLogsComponent implements OnInit {
  userList: any[] = [];
  tempUsersList: any[] = [];
  statusWiseLogs: any[] = [];
  selectedAssignToId: any = 0;
  selectedAssignToUser: any = 'All';
  selectedAssignById: any = 0;
  selectedAssignByName: any = 'All';
  tempStatusWiseLogs: any[] = [];
  selectedStatus: string = 'Pending';
  pageUrl: string;

  constructor(
    public common: CommonService,
    public userService: UserService,
    public api: ApiService,
    public table: TableService,
    private modalService: NgbModal
  ) {
    this.getStatusWiseLogs('Pending');
    this.getUserList();
  }

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 10, 'TRIP REPORT LIST');
  tempActivePendingChecklists: any[] = [];
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnInit(): void {}

  selectedAssignBy(event: any) {
    this.selectedAssignByName = event.value;
    if (event.value == 'All' && this.selectedAssignToUser == 'All') {
      this.statusWiseLogs = this.tempStatusWiseLogs;
    } else if (event.value == 'All' && this.selectedAssignToUser != 'All') {
      this.statusWiseLogs = this.tempStatusWiseLogs.filter(
        (p) =>
          p.assigned_to_user.toLowerCase() ==
          this.selectedAssignToUser.toLowerCase()
      );
    } else if (event.value != 'All' && this.selectedAssignToUser == 'All') {
      this.statusWiseLogs = this.tempStatusWiseLogs.filter(
        (p) => p.assigned_by_user.toLowerCase() == event.value.toLowerCase()
      );
    } else {
      this.statusWiseLogs = this.tempStatusWiseLogs.filter(
        (p) =>
          p.assigned_by_user.toLowerCase() == event.value.toLowerCase() &&
          p.assigned_to_user.toLowerCase() ==
            this.selectedAssignToUser.toLowerCase()
      );
    }
    this.renderTable();
  }

  viewRemark(item: any) {
    this.common.params = {
      log_id: item.log_id,
      checklist_title: item.checklist_title,
      remarkType: 1,
    };
    this.modalService.open(ViewRemarkComponent, { size: 'xl' });
  }

  selectedAssignTo(event: any) {
    this.selectedAssignToUser = event.value;
    if (event.value == 'All' && this.selectedAssignByName == 'All') {
      this.statusWiseLogs = this.tempStatusWiseLogs;
    } else if (event.value == 'All' && this.selectedAssignByName != 'All') {
      this.statusWiseLogs = this.tempStatusWiseLogs.filter(
        (p) =>
          p.assigned_by_user.toLowerCase() ==
          this.selectedAssignByName.toLowerCase()
      );
    } else if (event.value != 'All' && this.selectedAssignByName == 'All') {
      this.statusWiseLogs = this.tempStatusWiseLogs.filter(
        (p) => p.assigned_to_user.toLowerCase() == event.value.toLowerCase()
      );
    } else {
      this.statusWiseLogs = this.tempStatusWiseLogs.filter(
        (p) =>
          p.assigned_to_user.toLowerCase() == event.value.toLowerCase() &&
          p.assigned_by_user.toLowerCase() ==
            this.selectedAssignByName.toLowerCase()
      );
    }
    this.renderTable();
  }

  filterUserList(event: any) {
    this.userList = this.tempUsersList.filter((p) =>
      p.userName.toLowerCase().includes(event.trim().toLowerCase())
    );
  }

  getUserList() {
    this.api.get('user/all_user').subscribe(
      (res: any) => {
        this.userList = res.data;
        this.userList.unshift({ userId: 0, userName: 'All' });
        this.tempUsersList = _.cloneDeep(this.userList);
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  getStatusWiseLogs(status: String) {
    this.common.loading = true;
    this.api
      .get(
        'checklist-dashboard/get-status-wise-checklist-logs?status=' + status
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.statusWiseLogs = res.data;
          this.tempStatusWiseLogs = _.cloneDeep(this.statusWiseLogs);
          this.selectedAssignByName = 'All';
          this.selectedAssignToUser = 'All';
          this.renderTable();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getDelayedTime(status: string, perform_time: string, end_time: string) {
    let delayed_time: any = '';
    if (status == 'delay') {
      // Assuming you have two time strings
      const startTimeStr = end_time;
      const endTimeStr = perform_time;

      const endDatePart = endTimeStr.split(' ')[0];
      // Convert time strings to Date objects
      const startTime = new Date(`${endDatePart} ${startTimeStr}`);
      const endTime = new Date(endTimeStr);

      // Calculate the time difference in milliseconds
      const timeDifferenceMillis = endTime.getTime() - startTime.getTime();

      // Convert milliseconds to seconds
      let delayMinutes = timeDifferenceMillis / (1000 * 60);
      delayed_time = 'by ' + delayMinutes.toString().split('.')[0] + ' min.';
    }
    return delayed_time;
  }

  viewCheckList(item: any) {
    let url = environment.websiteUrl + 'user/view-checklist?secret=';
    this.pageUrl =
      url + btoa(item.checklist_id) + '&secretLog=' + btoa(item.log_id);
    window.open(this.pageUrl, '_self');
  }
}

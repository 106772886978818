import { ApiService } from 'src/app/@core/services/api.service';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { Asset, assetData } from 'src/app/dataType/assetInterface';

@Component({
  selector: 'app-asset-view',
  templateUrl: './asset-view.component.html',
  styleUrls: ['./asset-view.component.scss']
})
export class AssetViewComponent implements OnInit {

  @Input() assetId: any = null;
  activeTab: number = 0;
  assetData: assetData;
  customFieldData: string;
  tablist = ['Details', 'Requests', 'Documents', 'Depreciation', 'Warranty', 'Maintenance', 'Insurance', 'Reserve', 'History', 'Linking']
  assets: Asset[] = [];
  selectedAssetName:string;
  showAssetContainer: boolean=true;
  constructor(
    private api: ApiService,
    private common: CommonService,
    private cdr: ChangeDetectorRef,public route: ActivatedRoute, private breadcrumbService: BreadcrumbService, public router: Router
  ) {

    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'assets', url: '/user/asset-management' },
      { name: 'asset-view', url: '/user/asset-view' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit() {
    this.getAssets();
    if (this.route.snapshot.queryParams.secretKey || this.assetId != null) {
      this.assetId = this.assetId == null ? atob(this.route.snapshot.queryParams.secretKey) : this.assetId;
    this.getAssetData();
    }
  }

  selectedAsset(event: any) {
    this.assetId = event.asset_id;
    this.getAssetData();
    this.activeTab=0;
    this.selectedAssetName=event.asset_name;
    this.cdr.detectChanges();
  }

  getAssets() {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    let params={
      allAssets:false,
      categoryId:-1
    }
    let apiUrl = 'assets/get-all-assets'
    this.api.get(apiUrl,params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.data.length) {
          this.assets = res.data;
          resolve(this.assets);
          if(!this.route.snapshot.queryParams.secretKey) {
            this.assetId=this.assets[0].asset_id;
            this.selectedAssetName=this.assets[0].asset_name;
            this.getAssetData();
          }else{
            this.selectedAssetName=this.assets.filter(asset=>asset.asset_id==Number(atob(this.route.snapshot.queryParams.secretKey)))[0].asset_name;
          }

        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        reject(null);
      }
    );
  });
  }


  getAssetData() {
    this.api.get('assets/get-asset-data-by-id', { assetId: this.assetId }).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.assetData = res.data[0];
          this.showAssetContainer = false;
          this.cdr.detectChanges();
          this.showAssetContainer = true;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  editAsset() {
    this.router.navigate(['user/asset-management'], {
      state: {
        data: this.assetData,
        flag: true
      }
    });
  }

}

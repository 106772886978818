import {
  Component,
  ElementRef,
  HostListener,
  Injectable,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
@Injectable()
export class SignatureComponent implements OnInit {
  @ViewChild('signatureContainer', { static: true })
  signatureContainer: ElementRef<HTMLDivElement>;
  @ViewChild('signatureCanvas', { static: true })
  signatureCanvas: ElementRef<HTMLCanvasElement>;
  @Output() getSignature = new EventEmitter<any>();
  private context: CanvasRenderingContext2D;
  private isDrawing: boolean = false;
  private lastX: number = 0;
  private lastY: number = 0;
  signatureImage: string = null;
  signatureAdded = false;

  ngOnInit() {}

  ngAfterViewInit() {
    this.context = this.signatureCanvas.nativeElement.getContext('2d');
    this.resizeCanvas();
    this.context.lineWidth = 2;
    this.context.strokeStyle = '#000';
  }

  @HostListener('window:resize')
  onResize() {
    this.resizeCanvas();
  }

  resizeCanvas() {
    const container = this.signatureContainer.nativeElement;
    this.signatureCanvas.nativeElement.width = container.offsetWidth;
    this.signatureCanvas.nativeElement.height = container.offsetHeight;
  }

  @HostListener('mousedown', ['$event'])
  startDrawing(event: MouseEvent) {
    this.isDrawing = true;
    const canvasRect =
      this.signatureCanvas.nativeElement.getBoundingClientRect();
    this.lastX = event.clientX - canvasRect.left;
    this.lastY = event.clientY - canvasRect.top;
  }

  @HostListener('mousemove', ['$event'])
  draw(event: MouseEvent) {
    if (!this.isDrawing) return;

    const canvasRect =
      this.signatureCanvas.nativeElement.getBoundingClientRect();
    const currentX = event.clientX - canvasRect.left;
    const currentY = event.clientY - canvasRect.top;

    this.context.beginPath();
    this.context.moveTo(this.lastX, this.lastY);
    this.context.lineTo(currentX, currentY);
    this.context.stroke();

    this.lastX = currentX;
    this.lastY = currentY;
  }

  @HostListener('mouseup')
  @HostListener('mouseout')
  stopDrawing() {
    this.isDrawing = false;
  }

  clearSignature() {
    const canvasWidth = this.signatureCanvas.nativeElement.width;
    const canvasHeight = this.signatureCanvas.nativeElement.height;
    this.signatureAdded=false;
    this.context.clearRect(0, 0, canvasWidth, canvasHeight);
    this.getSignature.emit(null);
  }

  captureSignature() {
    // this.signatureImage = this.signatureCanvas;
    if (this.signatureAdded == true) {
      this.signatureImage = this.signatureCanvas.nativeElement.toDataURL('signature/png');
      this.getSignature.emit(
        this.signatureCanvas.nativeElement.toDataURL('signature/png')
      );
    }
  }

}

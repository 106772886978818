<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"set_user_permission" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"role_type" | translate}}</label>
                  <select name="user_type" id="#user_type" class="form-control" [(ngModel)]="roleType">
                    <option *ngFor="let type of userTypesList[role.user_role]" [value]="type">{{type}}</option>
                  </select>
                  <div class="error" *ngIf="typeValidator && typeValidator.error">
                    <span>{{typeValidator.msg}} </span>
                  </div>
                </div>
                <div
                  *ngIf="(role.user_role=='AXESTRACK') && (roleType=='AXESTRACKUSER'||roleType=='COMPANYADMIN'||roleType=='COMPANYUSER'||roleType=='PLANTADMIN'||roleType=='PLANTUSER')"
                  class="form-group col-md-3">
                  <label for="my-input" class="required">{{"company" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="companyData" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyNameValidator && companyNameValidator.error">
                    <span>{{companyNameValidator.msg}} </span>
                  </div>
                </div>

                <div
                  *ngIf="(role.user_role=='AXESTRACK' || role.user_role=='COMPANYADMIN')&&(roleType=='PLANTADMIN'||roleType=='PLANTUSER'||roleType=='COMPANYUSER'||roleType=='AXESTRACKUSER')"
                  class="form-group col-md-3">
                  <label for="my-input" class="required">{{"plant" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{plantNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label for="roleName" class="required">{{"role_name" | translate}}</label>
                  <input class="form-control" type="text" name="roleName" id="roleName" placeholder="Enter Role Name"
                    [(ngModel)]="roleName" #name="ngModel" autocomplete="off">
                  <div class="error" *ngIf="roleNameValidator && roleNameValidator.error">
                    <span>{{roleNameValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="roleName">{{"role_status" | translate}}</label>
                  <select [(ngModel)]='roleStatus' class="form-control">
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                    <option value="BLOCKED">BLOCKED</option>
                  </select>
                </div>
                <div class="col-md-12 mt-3">
                  <mat-accordion [multi]="true">
                    <mat-expansion-panel *ngFor="let panel of expansionPanel;index as p" [expanded]="OpenPanels[p]"
                      (closed)="OpenPanels[p] = false;" (opened)="OpenPanels[p] = true">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="py-2">
                          <h5 class="panel-title">{{panel.name|translate}}</h5>
                        </mat-panel-title>

                      </mat-expansion-panel-header>
                      <div class="user-permission-col"
                        *ngIf="(panel.type == 'web' ? WebPages.length : AppPages.length)">
                        <div class="module-container"
                          *ngFor="let module of (panel.type == 'web' ? WebPages : AppPages); let i = index;">
                          <div class="group-parent">
                            <mat-checkbox class="group-box" color="primary" [(ngModel)]=" module.isAccess"
                              (change)="checkOrUnCheckfunctionality(module, i,module.isAccess)">
                              {{module.name}}</mat-checkbox>
                          </div>

                          <div class="group-container">
                            <div class="group-head" *ngFor="let group of module.childs; let j = index">
                              <mat-checkbox [(ngModel)]="group.isAccess" color="primary" (ngModelChange)="changeParentChildAccess(group,module,'parent')">{{group.name}}</mat-checkbox>
                              <div class="group_div" *ngFor="let func of group.functionality; let j = index">
                                <mat-checkbox [(ngModel)]="func.isAccess" color="primary" (ngModelChange)="changeParentChildAccess(group,module,'child')">{{func.name}}</mat-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>

                <div class="form-group action-block col-md-12">
                  <div class="btn-block">
                    <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" | translate}}
                    </button>
                    <button class="btn btn-warning" (click)="resetDetail()"> {{"reset" | translate}} </button>
                    <button type="submit" class="btn btn-submit" (click)=" formValidator()"> {{btnText | translate}}
                    </button>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"permission_table" | translate}} </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th class="serial">{{"serial_no" | translate}}</th>
                    <th>{{"role_name" | translate}}</th>
                    <th>{{"role_type" | translate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK'">{{"company" | translate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK' || 'COMPANYADMIN'">{{"plant" | translate}}</th>
                    <th>{{"role_status" | translate}}</th>
                    <th class="action"> {{"action" | translate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor=" let item of roles; let i = index">
                    <td class="serial">{{i+1}}</td>
                    <td>{{item.roleName}}</td>
                    <td>{{item.roleType}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK'">{{item.companyName}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK' || 'COMPANYADMIN'">{{item.plantName}}</td>
                    <td>{{item.roleStatus}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-success" (click)="editDetail(item)"
                          *ngIf="userService.permissionMap.get('edit_roles_access')==true"> {{"edit" | translate}}
                        </button>
                        <button class="btn btn-danger" (click)="openConfirmAlert(item)"
                          *ngIf="userService.permissionMap.get('delete_roles_access')==true"> {{"delete" | translate}}
                        </button>
                      </div>

                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>


    </div>

  </div>

</div>

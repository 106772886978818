import { UserService } from 'src/app/@core/services/user.service';
import { TmgDashboardService } from '../tmg-dashboard.service';
import { DetailService } from '../detail-modal/detail.service';
import { TmgchartService } from '../tmgchart.service';
import { TmgService } from '../tmg.service';
import { processvehile, processtrip, optionFilter, optionmenu } from '../data';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-dashboard',
  templateUrl: './live-dashboard.component.html',
  styleUrls: [
    './live-dashboard.component.scss',
    '../tmg-dashboard.component.scss',
  ],
})
export class LiveDashboardComponent implements OnInit {
  processvehile = processvehile;
  processtrip = processtrip;
  optionFilter = optionFilter;
  optionmenu = optionmenu;

  tripstatus: any = [];
  transporterTripCancel: any = [];
  worsttrip: any = [];

  WorstTransporterTripCancelParam: any;
  WorstTransporterTripCancelDetailData: any = [];

  tripstatusParam: any;
  tripStatusDetailData: any = [];

  worsttripParam: any;
  worsTripDetailData: any = [];

  constructor(
    public tmg: TmgService,
    public chart: TmgchartService,
    public detail: DetailService,
    public tmgdashboard: TmgDashboardService,
    public userService: UserService
  ) {}

  gettripstatus() {
    let param = this.tmg.DateResponse('Live', 1);
    param.type = 'limit';
    this.tripstatusParam = param;
    this.tmgdashboard.gettripStatus(param).subscribe((item: any) => {
      if(item.status){
      this.tripstatus = item.data;
      }
    });
  }

  gettripstatusDetail(selector: any) {
    this.tripstatusParam.type = 'all';
    this.tmgdashboard
      .gettripStatus(this.tripstatusParam)
      .subscribe((item: any) => {
        if(item.status){
        this.tripStatusDetailData = item.data ?? [];
        this.detail.openDialog(
          this.tripStatusDetailData,
          'Live Snapshot - 24 hours Detail',
          selector
        );
        }
      });

  }

  getworstTrip() {
    let param = this.tmg.DateResponse('Live', 1);
    param.type = 'limit';
    this.worsttripParam = param;
    this.tmgdashboard.getWorstTrip(param).subscribe((item: any) => {
      if(item.status){
      this.worsttrip = item.data;
      }
    });
  }

  getworstTripDetail(selector: any) {
    this.worsttripParam.type = 'all';
    this.tmgdashboard
      .getWorstTrip(this.worsttripParam)
      .subscribe((item: any) => {
        if(item.status){
        this.worsTripDetailData = item.data ?? [];
        this.detail.openDialog(
          this.worsTripDetailData,
          'Worst 3 Trips - TAT wise 7 Days Detail',
          selector
        );
        }
      });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.gettripstatus();
      this.getworstTrip();
    }, 200);
  }
}

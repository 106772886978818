<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header" *ngIf="!toggle">
              <div class="header-block">
                <h3 class="card-title">
                  {{"visitor_appointment"| customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div [ngClass]="'col-md-9'" *ngIf="!toggle">
                  <div class="tab_table_sec">
                    <div class="tab_card_sec">
                      <div class="tab_card" *ngFor="let tab of actionLabels;index as i"
                        (click)="showVisits(tab.tabName); activeIndex = i" [class.active]="activeIndex == i">
                        <div class="left_content">
                          <div class="tab_name">{{tab.tabName | customTranslate}}</div>
                          <div class="tab_number">{{data[tab.tabName]}}</div>
                        </div>

                        <div class="right_icon">
                          <img [src]="tab.tab_icon">
                        </div>

                      </div>
                    </div>


                    <div class="row filter_row"
                      *ngIf="activeTab=='completed' || activeTab=='rejected' || activeTab=='total'">
                      <div class="col-md-4 date pl-0">
                        <label>{{'start_date:'|customTranslate}}</label>
                        <input type="date" class="form-control" [(ngModel)]="startDate"
                          (change)="showVisits(activeTab)">
                      </div>
                      <div class="col-md-4 date">
                        <label>{{'end_date:'|customTranslate}}</label>
                        <input type="date" class="form-control" [min]="startDate" [(ngModel)]="endDate"
                          (change)="showVisits(activeTab)">
                      </div>
                    </div>

                      <div class="table_sec ">
                        <app-custom-table [data]="appointments" [columns]="tableColumns"
                        [tableType]="TABLE_TYPE"></app-custom-table>
                      </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <app-add-visit-modal [title]="title" [toggle]="toggle" (visitSaved)="refreshDashboard($event)"></app-add-visit-modal>
                </div>
                <div class="modal fade" #detail id="exampleModal" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: 100%">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{"visitor_info" | customTranslate}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">

                        <ng-container *ngFor="let visit of modalData; let i = index">

                          <div class="single_card">
                            <div class="show_data">
                              <div class="start">
                                <div class="image1 icon">
                                  <img [src]="visit.visitor_image?visit.visitor_image:'assets/images/user_whatsapp.jpg'"
                                    (click)="openVisitorImage(visit.visitor_image)" style="aspect-ratio: 1 ;">

                                </div>
                              </div>

                              <div class="mid">
                                <div class="name"><strong>{{"name" | customTranslate}}:</strong>{{visit.staff_name}}</div>
                                <div class="name"><strong>{{"in" | customTranslate}} : </strong>{{visit.in_time|date:'dd-MM-yyyy hh:mm'}}</div>
                                <div><strong>{{"phone" | customTranslate}}: </strong>{{visit.mobile_number}}</div>
                              </div>

                              <div class="end">
                                <div class="name"><strong>{{"status" | customTranslate}} : </strong> <span class="pill"
                                    [ngClass]="{'bg-green-200 text-green-800':visit.visitor_status=='APPROVED','bg-yellow-200 text-yellow-800':visit.visitor_status=='IN','bg-indigo-100 text-indigo-800':(visit.visitor_status=='OUT'),'bg-red-200 text-red-800':(visit.visitor_status=='REJECTED'),'bg-blue-200 text-blue-800':(visit.visitor_status=='BOOKED')}">{{visit.visitor_status}}</span>
                                </div>
                                <div class="name" style="font-size: 14px; font-weight: 600;"><strong>{{"out" | customTranslate}}:
                                  </strong>{{visit.out_time|date:'dd-MM-yyyy hh:mm'}}</div>
                                <div class="last_bottom">

                                </div>
                              </div>

                            </div>
                          </div>
                        </ng-container>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-alert-message></app-alert-message>
<!-- <ng-template #addAppointment>
  <div [ngClass]="toggle ? 'col-md-12' :'col-md-3 pl-0'">
    <div class="form_sec">
      <div class="right_form_title">
        <label>{{ title | customTranslate}}</label>
        <i class="fa fa-times closeModal" *ngIf="toggle" (click)="confirmCloseModalInWalkinMode()">
        </i>
      </div>

      <div class="appointment_form">
        <app-form [formType]='"Visitor Form"'></app-form>
      </div>
    </div>
  </div>
</ng-template> -->

<!-- <ng-template #staticForm modal>

  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h5 class="modal-title">Add Visit Appointment</h5>
  </div>
              <div class="card-body" style="overflow: auto;">
                <div class="row form_sec_row">
                  <div class="col-md-12" *ngIf="isVisitor && refData == null">
                    <div class="field camera_option">
                      <label>Capture Image</label>
                      <div class="upload_img" *ngIf="!showCamera">
                        <img src="assets/images/camera_plus.png" (click)="showCamera=true">
                      </div>
                      <div *ngIf="showCamera==true">
                        <app-camera-component [retake]="retakeImage" [id]="'field'"
                          (getPicture)="handleImage($event,'driver_image')" *ngIf="newAppointment.visitorImage==null">
                        </app-camera-component>
                        <div style="display: flex; flex-direction: column; align-items:center;"
                          *ngIf="newAppointment.visitorImage!=null">
                          <img class="circle-frame" style="height: 200px; margin-bottom: 5px;"
                            [src]="newAppointment.visitorImage" />
                          <p>Upload Done! <button class="btn btn-warning"
                              (click)="retakeImage = !retakeImage;newAppointment.visitorImage=null">Retake</button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" *ngIf="isVisitor && refData == null">
                    <div class="field">
                      <label class="required">Name</label>
                      <input type="text" [(ngModel)]="newAppointment.name" class="form-control">
                      <div class="error" *ngIf="nameValidator && nameValidator.error">
                        <span>{{nameValidator.msg}} </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="field">
                      <label class="required">Start Time</label>
                      <input type="date" [value]="newAppointment.startTime"
                        [(ngModel)]="newAppointment.startTime" [min]="minStartDate" class="form-control"
                        (change)="validateStartTime()">
                      <div class="error" *ngIf="startTimeValidator && startTimeValidator.error">
                        <span>{{startTimeValidator.msg}} </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="field">
                      <label class="required">End Time</label>
                      <input type="date" [(ngModel)]="newAppointment.endTime" [min]="newAppointment.startTime"
                        class="form-control"
                        (change)="validateDateTimeRange(newAppointment.startTime,newAppointment.endTime)">
                      <div class="error" *ngIf="endTimeValidator && endTimeValidator.error">
                        <span>{{endTimeValidator.msg}} </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="field">
                      <label class="required">Purpose</label>
                      <input type="text" [(ngModel)]="newAppointment.purpose" class="form-control">
                      <div class="error" *ngIf="purposeValidator && purposeValidator.error">
                        <span>{{purposeValidator.msg}} </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="field">
                      <label class="required">From</label>
                      <input type="text" [(ngModel)]="newAppointment.from" class="form-control">
                      <div class="error" *ngIf="fromValidator && fromValidator.error">
                        <span>{{fromValidator.msg}} </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="field">
                      <label class="required">Meet With</label>
                      <ps-auto-suggestion [data]="allUsers" (onSelected)="selectedUser($event)" display="name"
                        [preSelected]="{name:newAppointment.userName}"
                        [readonly]="true">
                      </ps-auto-suggestion>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="field">
                      <label>Visitor Details</label>
                      <div class="other_visitor">
                        <div class="loop_row" *ngFor="let visitor of newAppointment.visitors; let i = index">
                          <div class="number_field">
                            <input type="text" class="form-control" placeholder="Number" [(ngModel)]="visitor.mobile_number"
                              maxlength="10" (input)="handleMobileNumber(visitor,visitor.mobile_number)">
                          </div>
                          <div class="name_field">
                            <input type="text" class="form-control" placeholder="Name" [(ngModel)]="visitor.staff_name"
                              [disabled]="visitor.isNameDisabled">
                          </div>

                          <div style="display: flex; margin: 10px 0;">
                            <div class="plus_minus_icon">
                              <span
                                class="remove_class "
                                (click)="removeRow(i)"
                                *ngIf="newAppointment.visitors.length > 1">
                                <i class="fa fa-minus"></i>
                              </span>
                            </div>
                            <div class="plus_minus_icon">
                              <span
                                class="add_class"
                                (click)="addValues()">
                                <i class="fa fa-plus"></i>
                              </span>
                            </div>
                          </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-success " (click)="formValidator()">Submit</button>
                <button type="button" class="btn btn-warning" (click)="reset()">Reset</button>
              </div>
</ng-template> -->

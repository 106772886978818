import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { CommonService } from '../../@core/services/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../@core/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-vehicle-history',
  templateUrl: './vehicle-history.component.html',
  styleUrls: ['./vehicle-history.component.scss'],
})
export class VehicleHistoryComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'Vehicle History List');
  @Input() public refData;
  vehicleHistory: any = [];

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getVehicleHistory();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    public table: TableService,
    private activeModal: NgbActiveModal,
    public breadcrumbService: BreadcrumbService,
    public datePipe: DatePipe
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
  }

  ngOnInit(): void {}

  getVehicleHistory() {
    this.common.loading = true;
    let params = {
      vehicleId: this.refData.vehicleId,
      driverId: -1,
    };
    this.api.get('trip/view-vehicle-or-driver-history', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.vehicleHistory = res.data;
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }
}

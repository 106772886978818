import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ProcessService } from '../process/process.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import lodash from 'lodash';

@Component({
  selector: 'app-stage-zone-mapping',
  templateUrl: './stage-zone-mapping.component.html',
  styleUrls: ['./stage-zone-mapping.component.scss'],
})
export class StageZoneMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'Stage Zone Mapping List');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getParkingTypeStages();
    this.getAllStageZoneMappings();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  zones: any[] = [];
  processStages: any[] = [];
  id: any = null;
  selectedZoneIds: any[] = [];
  selectedZoneNames: any[] = [];
  selectedOrderId: number = null;
  selectedStageName: string = null;
  selectedOrderName: string = null;
  stageZoneMappings: any[] = [];
  groupedDataByProcessStageName: any;
  isEdit: boolean = false;
  title = 'Add Stage Zone Mapping';
  btn = 'Save';

  constructor(
    public api: ApiService,
    public common: CommonService,
    public table: TableService,
    public alertService: AlertService,
    public modal: NgbModal,
    public processService: ProcessService,
    public breadcrumbService: BreadcrumbService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Zone', url: null },
      { name: 'Stage Zone Mapping', link: '/user/stage-zone-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit() {
    this.getAllZones();
  }

  selectedZones(event: any) {
    this.selectedZoneIds = event.map((e) => e.id);
  }

  selectedStage(event: any) {
    this.selectedOrderId = event.order_id;
    this.selectedOrderName = event.order_name;
  }

  getParkingTypeStages() {
    this.common.loading = true;
    this.processService.getAllProcessStage().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.processStages = res.data.filter(
          (data) => data.stage_type_name == 'Parking'
        );
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllZones() {
    this.common.loading = true;
    this.api.get('zone/get_all_zones').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.zones = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  saveStageZoneMapping() {
    this.common.loading = true;
    let params = {
      id: this.id,
      zoneIds: this.selectedZoneIds,
      processStageOrderId: this.selectedOrderId,
      isEdit: this.isEdit,
    };
    this.api.post('zone/save-stage-zone-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertService.info(res.message);
          this.reset();
          // this.getAllZones();
          this.isEdit = false;
          this.getAllStageZoneMappings();
          this.common.loading = false;
        }
      },
      (err) => {
        this.common.loading = false;
        console.error(err);
      }
    );
  }

  getAllStageZoneMappings() {
    this.common.loading = true;
    this.api.get('zone/get-all-stage-zone-mappings').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.stageZoneMappings = res.data;
          this.groupedDataByProcessStageName = lodash.groupBy(
            this.stageZoneMappings,
            'process_stage_name'
          );
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  formatZoneNames(dataArray: any): string {
    return dataArray.map((item) => item.zone_name).join(', ');
  }

  reset() {
    this.selectedOrderId = null;
    // this.selectedStageName=null;
    this.selectedOrderName = null;
    this.selectedZoneIds = [];
    this.selectedZoneNames = [];
    this.stageValidator = null;
    this.zoneValidator = null;
    this.btn = 'Save';
  }

  view(row?: any) {
    let viewObj = {
      processStage: row.key,
      zone: this.formatZoneNames(row.value),
      plant: row.value[0]?.plant_name,
    };
    this.common.params = {
      details: [viewObj],
      title: 'Zone Slot Mapping Details',
    };
    const activeModal = this.modal.open(ViewDetailsComponent, { size: 'lg' });
  }

  edit(row: any) {
    this.reset();
    this.common.gotoTop();
    this.btn = 'Update';
    this.title = 'Edit Stage Zone Mapping';
    this.isEdit = true;
    // this.id=row.id;
    this.selectedOrderId = row.value[0].order_id;
    this.selectedOrderName = row.key;
    this.selectedZoneIds = row.value.map((zone) => zone.zone_id);
    this.selectedZoneNames = row.value.map((zone) => ({
      zoneName: zone.zone_name,
      id: zone.zone_id,
    }));
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Stage Zone Mapping ',
      description:
        `<b>&nbsp;` + 'Are you sure you want to delete Mapping?' + `<b>`,
    };
    const activeModal = this.modal.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });
  }

  delete(row?: any) {
    let params = {
      processStageOrderId: row.value[0].order_id,
    };
    this.api.get('zone/delete-stage-zone-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.reset();
          this.getAllStageZoneMappings();
          this.alertService.info(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  stageValidator: any = null;
  zoneValidator: any = null;

  formValidator() {
    this.zoneValidator = this.common.nullValidator(
      this.selectedZoneIds,
      'Zone Mandatory'
    );
    this.stageValidator = this.common.nullValidator(
      this.selectedOrderId,
      'Stage Mandatory'
    );
    if (!this.zoneValidator.error && !this.stageValidator.error) {
      this.saveStageZoneMapping();
    }
  }
}

<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">{{'alert_log_details' | customTranslate}}</h5>
</div>

<div class="modal-body">
  <div class="tab_btnn_sec">
    <div class="tab_btnn" *ngFor="let tab of actionLabels;index as i">
      <button type="button" class="btn btn-block nav-buttons" (click)="changeTabs(i , tab)"
        [ngClass]="{'btn-inactive':activeTab != i,'btn-active':activeTab == i}"
        [ngStyle]="{'background-color': tab.tabBgColor}">
        <div class="left_part">
          <div class="tab_name">{{tab.tabName | customTranslate}}</div>
          <div class="tab_number">{{tab.count}}</div>
        </div>

        <div class="right_part">
          <div class="icon_div" [ngStyle]="{'background-color': tab.tabIconBg}">
            <i [class]="'fa fa-'+tab.tab_icon" [ngClass]="tab.tabName"></i>
          </div>
        </div>
      </button>
    </div>
  </div>

  <div class="col-md-12">
    <table #ref class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th>{{"s.no." | customTranslate}} </th>
          <th>{{"alert_title" | customTranslate}} </th>
          <th>{{"alert_condition" | customTranslate}} </th>
          <th>{{"alert_typee" | customTranslate}} </th>
          <th>{{"entity" | customTranslate}} </th>
          <th>{{'created_at'|customTranslate}}</th>
          <th>{{"action" | customTranslate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of dateWiseAlertDetails; let i = index;">
          <td>{{i + 1}}</td>
          <td>{{item.title}}</td>
          <td>{{item.alert_condition}}</td>
          <td>
            <span class="badge status-badge" [style.background-color]="item.type_color"
              style="font-size: 13px;color: white; padding: 4px 5px 4px 5px;">{{item.type_name}}</span>
          </td>
          <td>{{item.map_on}}</td>
          <td>{{ item.created_at | date: 'dd-MM-yyyy HH:mm' }}</td>
          <td class="action">
            <div class="btn-block">
              <button class="btn btn-primary" (click)="openAlertLogInfoModal(item)">{{"info" | customTranslate}}</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { PlantService } from '../plant/plant.service';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { RoleService } from '../../@core/services/role.service';
import { TableService } from '../../@core/services/table.service';
import { UserService } from '../../@core/services/user.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-hardware-setting',
  templateUrl: './hardware-setting.component.html',
  styleUrls: ['./hardware-setting.component.scss'],
})
export class HardwareSettingComponent implements OnInit {
  hardwareData: any = [];
  hardwareName: any = null;
  hardwareId: any = null;
  selectedCompanyName = '';
  selectedPlantName = '';
  companyId = '';
  plantId = '';
  plantsData: any[] = [];
  company: any = [];
  hardwareNameMulti: any = [];
  hardwareList: any = [];
  btn = 'Save';
  hardwareTypeData: any[] = [];
  hardwareType: any = null;
  hardwareTypeId: any = null;
  hardwareFields: any[] = [];

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'HARDWARE LIST');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    public api: ApiService,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public alertService: AlertService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService,
    private plantService: PlantService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Hardware', url: null },
      { name: 'Hardware Setting', url: '/user/hardware-setting' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
    this.getHardware();
  }

  selectedHardware(event: any) {
    this.hardwareId = event.ipaHardwareId;
    this.hardwareName = event.hardwareName;
    this.getHardwareType();
  }

  selectCompany(event: any) {
    this.reset();
    this.companyId = event.companyId;
    this.getPlantById(this.companyId);
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.selectedPlantName = event.plantName;
  }

  getPlantById(id?: any) {
    this.common.loading = true;

    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.plantsData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getHardware() {
    this.common.loading = true;
    this.api.get('hardware/get_hardware').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.hardwareData = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  getHardwareType() {
    this.common.loading = true;
    this.api
      .get(
        'hardware/get-hardware-type-by-id?' + 'hardwareId=' + this.hardwareId
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.hardwareTypeData = res.data;
          this.hardwareType = this.hardwareTypeData[0].hardware_type;
          this.hardwareTypeId = this.hardwareTypeData[0].ipa_hardware_id;
          this.getHardwareFields();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          //
        }
      );
  }

  getHardwareFields() {
    this.common.loading = true;
    this.api
      .get(
        'hardware/get-hardware-fields-by-type?' +
          'hardwareTypeId=' +
          this.hardwareTypeId
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.hardwareFields = res.data;
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          //
        }
      );
  }

  reset() {
    this.hardwareId = null;
    this.hardwareName = null;
    this.hardwareType = null;
  }

  hardwareNameValidator: any;
  destinationValidator: any;
  formValidator() {
    this.hardwareNameValidator = this.common.nullValidator(
      this.hardwareNameMulti,
      'Hardware Name Mandatory'
    );
  }
}

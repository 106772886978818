import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlantService } from '../../components/plant/plant.service';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-login-credentials',
  templateUrl: './login-credentials.component.html',
  styleUrls: ['./login-credentials.component.scss'],
})
export class LoginCredentialsComponent implements OnInit {
  companyData: any = [];
  plantData: any = [];
  plantName: any = null;
  companyName: any = null;
  extPlantId: any = null;
  extCompanyId: any = null;
  title: any = 'Login';

  constructor(
    private activeModal: NgbActiveModal,
    public common: CommonService,
    public userService: UserService,
    public api: ApiService,
    private plantService: PlantService
  ) {}

  ngOnInit(): void {
    if (this.userService._isLoggined) {
      this.title = 'Change Plant';
    }
    this.getCompany();
  }

  closeModal(pageReload:any=false) {
    if (
      this.userService._companyId != null &&
      this.userService._plantId != null
    )
      this.activeModal.close({ response: pageReload});
  }

  getCompany() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
          if (this.userService._companyId != null) {
            let selCompany = this.companyData.filter(
              (com) => com.companyId == this.userService._companyId
            );
            this.companyName = selCompany[0].companyName;
            this.getPlantById(this.userService._companyId);
          }
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  selectedCompany(event: any) {
    this.extCompanyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantById(event.companyId);
    this.plantName = null;
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
          if (this.userService._companyId != null) {
            let selPlant = this.plantData.filter(
              (plant) => plant.plantId == this.userService._plantId
            );
            this.plantName = selPlant[0]?.plantName || null;
          }
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.extPlantId = event.plantId;
    this.plantName = event.plantName;
  }

  saveExternalLoginIds() {
    if(this.userService._plantId ==
      (this.extPlantId == null ? this.userService._plantId : this.extPlantId)){
        this.closeModal(false)
        return
      }
    this.common.loading = true;
    let params = {
      companyId:
      this.extCompanyId == null
      ? this.userService._companyId
          : this.extCompanyId,
          plantId:
        this.extPlantId == null ? this.userService._plantId : this.extPlantId,
        userId: this.userService._details.id,
        username: '',
        password: '',
        oldCompanyId: this.userService._companyId,
        oldPlantId: this.userService._plantId,
        isLoggedIn: this.userService._isLoggined,
        token: this.userService._token != null ? this.userService._token : '',
        platformType: 'WEB',
    };
    this.api.post('user/store-external-login-details', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.userService._details.companyId =
            this.extCompanyId == null
              ? this.userService._companyId
              : this.extCompanyId;
          this.userService._details.plantId = this.extPlantId;
          this.userService._companyId =
            this.extCompanyId == null
              ? this.userService._companyId
              : this.extCompanyId;
          this.userService._plantId = this.extPlantId;
          this.userService._token = res.data[0].token;

          localStorage.setItem(
            'USER_DETAILS',
            JSON.stringify(this.userService._details)
          );
          localStorage.setItem('USER_TOKEN', this.userService._token);
          localStorage.setItem(
            'permission',
            JSON.stringify(res.data[0].permission)
          );
          this.userService.permissionMap = res.data[0].permission;
          this.userService.setPermission();
          this.closeModal(true);
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }
}

<div class="card average_tat" *ngIf="userService.permissionMap.get('view_average_tat_card_access')">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title"> {{"average_tat_daily_and_monthly" | translate}}</h3>
    </div>
    <div class="input_class" *ngIf="chartTypes.length && reportType.length">
        <div class="form-group">
          <!-- <label style="font-size: 12px;">{{'Chart Type'|translate}}</label> -->
          <!-- <ps-auto-suggestion (onSelected)="changeChartType($event)" [data]="chartTypes" display="chart"
            placeholder="Select Chart Type" [preSelected]="{chart:selectchartType}">
          </ps-auto-suggestion> -->
        </div>
        <div class="form-group">
          <!-- <label style="font-size: 12px;">{{'filter'|translate}}</label> -->
          <ps-auto-suggestion (onSelected)="gettatindex($event)" [data]="reportType" display="filter"
            placeholder="Select Filter" [preSelected]="{filter:tatType}">
          </ps-auto-suggestion>
        </div>
    </div>
  </div>

  <div class="card-body">
    <div *ngIf="lineChartData.length">
      <!-- <div class="chart-info">
        <p class="info"> <span> {{"chart_type" | translate}}: </span> <span class="labels"> {{lineChartType}}</span>
        </p>
        <p class="info"> <span> {{"report" | translate}}: </span> <span class="labels"> {{ tatfilter }}</span> </p>
      </div> -->
      <div class="card-body">
        <canvas baseChart width="1000" height="277" [datasets]="
            lineChartData" [labels]="lineChartLabels" [options]="barChartOptions" [colors]="lineChartColors"
          [legend]="lineChartLegend" [chartType]="lineChartType" [colors]="barChartColors"></canvas>
      </div>
    </div>

  </div>


  <app-data-not-found *ngIf="lineChartData.length > 1"></app-data-not-found>
</div>

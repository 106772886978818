<nav id="navbar" class="navbar navbar-expand-lg navbar-light  fixed-top">
  <div class="connection-status" *ngIf="!isOnline">
    <p class="status-message"> {{'internet_not_connected'|customTranslate}} </p>
  </div>
  <div class="connection-status success" *ngIf="isOnline && removeAlert && internetOffsBefore">
    <p class="status-message"> {{'internet_connected'|customTranslate}}</p>
  </div>
  <ng-container *ngIf="userService._isStaffLoggined; else cpllogo">
    <div class="logo_class" #cpllogo>
      <a href="" class="navbar-brand">
        <img src="assets/images/vms_logo.png" class="vms_logo">
      </a>
      <a class="navbar-brand brand-logo">Visitor Management System <small style="font-size: 10px;">By CPL</small></a>
    </div>
  </ng-container>
  <ng-template #cpllogo>
    <div class="logo_class">
      <a href="" class="navbar-brand">

        <img src="assets/images/logo.png" class="logo-image" alt="">
      </a>
      <a class="navbar-brand brand-logo">{{'connected_plant_logistics'|customTranslate}}</a>
    </div>
  </ng-template>

  <button class="navbar-toggler" data-target="#my-nav" data-toggle="collapse" aria-controls="my-nav"
    aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed ">
    <img src="assets/images/User.png" class="user-img" alt="">
  </button>

  <div class="collapse navbar-collapse" id="navbar-collapse" [ngClass]="{'active': isCollapsed}">
    <div class="ml-auto right-nav d-flex">
      <ul class="navbar-nav contact-list">

        <li class="nav-item dropdown contact_icon">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-share-alt"></i> {{'Visitor Panel'|customTranslate}}
          </a>
          <div class="dropdown-menu contact_icon" aria-labelledby="navbarDropdown" style="left:0%">
            <a class="dropdown-item" style="display: flex;" (click)="redirectLogin()"><i class="fa fa-external-link"></i>Open</a>
            <a class="dropdown-item" style="display: flex;" (click)="handleCommunication('whatsapp')"><i class="fa fa-whatsapp"></i>WhatsApp</a>
            <a class="dropdown-item" style="display: flex;" (click)="handleCommunication('email')"><i class="fa fa-envelope"></i>Email</a>
            <a class="dropdown-item" style="display: flex;" (click)="handleCommunication('sms')"><i class="fa fa-paper-plane"></i> SMS</a>
            <a class="dropdown-item" style="display: flex;" (click)="viewQRModal()"> <i class="fa fa-qrcode"></i> View QR</a>
          </div>
        </li>


        <li class="nav-item" *ngIf="user_role != role && userService.permissionMap.get('call_us_access')==true ">
          <div class="dropdown">
            <a class="dropdown-toggle nav-link" data-toggle="dropdown">
              <div class="icon">
                <img src="assets/images/call-icon-01.png" alt="">
              </div>
              {{'call_us' | customTranslate }}
            </a>

            <div class="dropdown-menu dropdown-menu-right animate slideIn py-0" aria-labelledby="my-dropdown">
              <div class="support-box">

                <div class="support-container">
                  <p> {{'help_message'|customTranslate}} </p>
                  <p> {{'call_us_on_message'|customTranslate}}<b>{{" "}}+7014557297</b> </p>
                  <img class="support-image" src="assets/images/customer-service.svg" alt="">
                  <button class="btn btn-primary">
                    <i class="fa fa-phone mr-2"></i> {{'call_us' | customTranslate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>



        <li class="nav-item" style="display: none;" *ngIf="user_role != role">
          <div class="dropdown">
            <a class="dropdown-toggle nav-link" data-toggle="dropdown">
              <div class="icon">
                <img src="assets/images/suggestions-icon-01.png" alt="">
              </div>
              {{'message_box'|customTranslate}}
            </a>
            <div class="dropdown-menu dropdown-menu-right animate slideIn py-0 " aria-labelledby="my-dropdown">

            </div>
          </div>
        </li>

        <li class="nav-item"
          *ngIf="user_role != role && userService.permissionMap.get('action_dashboard_shortcut_access')==true">
          <a routerLink="/user/action-dashboard" class="nav-link">
            <div class="icon">
              <img src="assets/images/files.png" alt="">
            </div>
            {{'action dashboard'|customTranslate}}

          </a>
        </li>

        <li class="nav-item" *ngIf="user_role == '1'">
          <a routerLink="/user/logger" class="nav-link">
            <div class="icon">
              <img src="assets/images/files.png" alt="">
            </div>
            {{'logs'|customTranslate}}

          </a>
        </li>

        <li class="nav-item"
          *ngIf="user_role != role && userService.permissionMap.get('change_vehicle_status_access')==true && !userService._isStaffLoggined">
          <a class="nav-link" (click)="vehicleStatusChange()">
            <div class="icon">
              <img src="assets/images/vehicle_status.png" alt="" style="width:23px;">
            </div>
            {{'change_vehicle_status'|customTranslate}}

          </a>
        </li>

        <li class="nav-item" *ngIf="user_type == 'AXESTRACKUSER' || user_type == 'COMPANYUSER'">
          <a class="nav-link" (click)="openLoginCredentialModal()">
            <div class="icon">
              <img src="assets/images/vehicle_status.png" alt="" style="width:23px;">
            </div>
            {{'change_plant'|customTranslate}}

          </a>
        </li>

        <li class="nav-item">
          <div class="dropdown">
            <a id="my-dropdown" class="dropdown-toggle nav-link" (click)="readOldNotification()" data-toggle="dropdown">
              <div class="icon">
                <img src="assets/images/bell-icon.png" title="Notifications" alt="">
                <span *ngIf="messages.length > 0" class="dot bg-danger">{{messages.length}}
                </span>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-right animate slideIn py-0 mailbox notification_dropdown"
              aria-labelledby="my-dropdown">
              <header><i class="fa fa-bell"></i>{{'notification'|customTranslate}}</header>
              <div class="mail-container">
                <aside class="sm-side">
                  <div class="user-head">
                    <div class="user-body">
                      <ul>
                        <li *ngFor="let message of messages">
                          <a class="mail-dropdown" [href]="'#' + message.web_link">
                            <div class="noti_row">
                              <div class="left_part">
                                <i class="fa fa-circle"
                                  [ngStyle]="{'color': message.notification_type == 'WARNING' ? '#fbbf2e' : (message.notification_type == 'ALERT' ? message.alert_color : '')}"></i>
                              </div>
                              <div class="right_part">
                                <p><span class="m_title"
                                    [ngStyle]="{'color': message.notification_type == 'WARNING' ? '#fbbf2e' : (message.notification_type == 'ALERT' ? message.alert_color : '')}">{{message.title}}</span>
                                  <span class="m_message">{{message.message}}</span>
                                </p>
                                <small>{{message.send_time | date: "dd-MM-yyyy hh:mm a"}}</small>
                              </div>

                            </div>
                          </a>
                        </li>

                      </ul>

                    </div>

                  </div>




                </aside>
              </div>

              <div class="notification_footer">
                <p (click)="viewNotificationList()">{{'view_all'|customTranslate}}</p>
              </div>

            </div>
          </div>
        </li>

        <li class="nav-item" *ngIf="userService._isStaffLoggined || userService.permissionMap.get('language_change_allow_access')==true">
          <div class="dropdown language_sec">
            <a class="dropdown-toggle nav-link flag_box" data-toggle="dropdown">
              <div class="selected_flag">
                <img [src]="selLanguage.img"> <span>{{selLanguage.title}}</span> <i class="fa fa-sort-desc icon_down"
                  aria-hidden="true"></i>
              </div>
            </a>

            <div class="dropdown-menu dropdown-menu-right animate slideIn py-0 flag_sec" aria-labelledby="my-dropdown">
              <div class="unselected_flag" (click)="chooseLanguage(lang)" *ngFor="let lang of languages;">
                <img [src]="lang.img"> <span>{{lang.title}}</span>
              </div>
            </div>
          </div>
        </li>

        <li class="nav-item">
          <img class="shift-icon" src={{shiftIcon}} />{{'good'|customTranslate}} {{shift|customTranslate}}
          {{userService._name?.replaceAll("_"," ")}}
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/User.png" class="user-img" alt="">
          </a>
          <div class="dropdown-menu dropdown-menu-right animate slideIn" aria-labelledby="navbarDropdown">
            <ng-container *ngIf="!userService._isStaffLoggined;">
              <a class="dropdown-item" [routerLink]="['/user/reset-password']"
                routerLinkActive="router-link-active">{{'change_password'|customTranslate}} </a>
              <!-- <a class="dropdown-item" (click)="getAssignedTxnForLoggedInUser()">{{'sign_out'|customTranslate}}</a> -->
              <a class="dropdown-item" (click)="gethelp()"> {{'help'|customTranslate}} </a>
            </ng-container>
            <a class="dropdown-item" (click)="logout()">{{'sign_out'|customTranslate}}</a>
          </div>

        </li>
      </ul>

    </div>
  </div>

</nav>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="sec_design top_sec">
                <div class="status">
                  <div class="small_text" style="color: #212529;">TOTAL INVENTORY</div>

                  <div class="status_num_graph">
                    <span class="small_num">150</span>
                    <i class="fa fa-bar-chart" style="color: #212529;"></i>
                  </div>
                </div>

                <div class="status">
                  <div class="small_text" style="color: #004586;">INSIDE INVENTORY</div>

                  <div class="status_num_graph">
                    <span class="small_num">75</span>
                    <i class="fa fa-bar-chart" style="color: #004586;"></i>
                  </div>
                </div>

                <div class="status">
                  <div class="small_text" style="color: #7d0020;">OUTSIDE INVENTORY</div>

                  <div class="status_num_graph">
                    <span class="small_num">25</span>
                    <i class="fa fa-bar-chart" style="color: #7d0020;"></i>
                  </div>
                </div>

                <div class="status">
                  <div class="small_text" style="color: #529f1c;">IN MAINTAINANCE</div>

                  <div class="status_num_graph">
                    <span class="small_num">15</span>
                    <i class="fa fa-bar-chart" style="color: #529f1c;"></i>
                  </div>
                </div>

                <div class="status">
                  <div class="small_text" style="color: #ff420e;">IDLE INVENTORY</div>

                  <div class="status_num_graph">
                    <span class="small_num">10</span>
                    <i class="fa fa-bar-chart" style="color: #ff420e;"></i>
                  </div>
                </div>
              </div>

              <div class="sec_design mt-1">
                <div class="header">
                  <div class="header-title">
                    Stage Wise Inventory
                  </div>
                </div>
                <div class="stage_sec">
                  <div class="single_stage_box" style="background: #e7ecff;">
                    <div class="stage_name">
                      <div class="stage_title">Plant In</div>
                      <div class="tat_time"><i class="fa fa-clock-o"></i> 02:10</div>
                    </div>
                    <!-- <div class="ticnometric1" style="background: #f79f5c;"></div>
                    <div class="ticnometric2" style="background: #f79f5c;"></div> -->
                    <div class="stage">
                      <div class="stage_row">
                        <div class="stage_row_toggle" (click)="toggleAccordion = !toggleAccordion">
                          <div class="name"><i class="fa fa-caret-down"></i> Metal Pallet</div>
                          <div class="number">25</div>
                        </div>
                        <div class="stage_row_toggle_data" *ngIf="toggleAccordion" style="background: #dde4ff;">
                          <div class="stage_row_toggle" (click)="toggleAccordion = !toggleAccordion">
                            <div class="name"><i class="fa fa-caret-down"></i>Small</div>
                            <div class="number">5</div>
                          </div><div class="stage_row_toggle" (click)="toggleAccordion = !toggleAccordion">
                            <div class="name"><i class="fa fa-caret-down"></i>Medium</div>
                            <div class="number">6</div>
                          </div><div class="stage_row_toggle" (click)="toggleAccordion = !toggleAccordion">
                            <div class="name"><i class="fa fa-caret-down"></i>Large</div>
                            <div class="number">4</div>
                          </div><div class="stage_row_toggle" (click)="toggleAccordion = !toggleAccordion">
                            <div class="name"><i class="fa fa-caret-down"></i>Double-sided</div>
                            <div class="number">10</div>
                          </div>
                        </div>
                      </div>
                      <div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i> Wood Pallet</div>
                          <div class="number">32</div>
                        </div>
                      </div>
                      <div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i> Plastic Pallet</div>
                          <div class="number">18</div>
                        </div>
                      </div>
                      <div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i> Fork Lift</div>
                          <div class="number">3</div>
                        </div>
                      </div>
                    </div>
                    <div class="view_detail">
                      <i class="fa fa-info-circle"></i> View Details
                    </div>
                  </div>

                  <div class="single_stage_box" style="background: #e7fdff;">
                    <div class="stage_name">
                      <div class="stage_title">Loading</div>
                      <div class="tat_time"><i class="fa fa-clock-o"></i> 00:35</div>
                    </div>
                    <!-- <div class="ticnometric1" style="background: #f79f5c;"></div>
                    <div class="ticnometric2" style="background: #f79f5c;"></div> -->
                    <div class="stage">
                      <div class="stage_row">
                        <div class="stage_row_toggle" (click)="toggleAccordion1 = !toggleAccordion1">
                          <div class="name"><i class="fa fa-caret-down"></i> Fork Lift</div>
                          <div class="number">4</div>
                        </div>

                        <div class="stage_row_toggle_data" *ngIf="toggleAccordion1" style="background: #dde4ff;">
                          <div class="stage_row_toggle" (click)="toggleAccordion1 = !toggleAccordion1">
                            <div class="name"><i class="fa fa-caret-down"></i>Small</div>
                            <div class="number">5</div>
                          </div><div class="stage_row_toggle" (click)="toggleAccordion1 = !toggleAccordion1">
                            <div class="name"><i class="fa fa-caret-down"></i>Medium</div>
                            <div class="number">6</div>
                          </div><div class="stage_row_toggle" (click)="toggleAccordion1 = !toggleAccordion1">
                            <div class="name"><i class="fa fa-caret-down"></i>Large</div>
                            <div class="number">4</div>
                          </div><div class="stage_row_toggle" (click)="toggleAccordion1 = !toggleAccordion1">
                            <div class="name"><i class="fa fa-caret-down"></i>Double-sided</div>
                            <div class="number">10</div>
                          </div>
                        </div>
                      </div>
                      <div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i> JCB</div>
                          <div class="number">2</div>
                        </div>
                      </div>
                    </div>
                    <div class="view_detail">
                      <i class="fa fa-info-circle"></i> View Details
                    </div>
                  </div>

                  <div class="single_stage_box" style="background: #e7fff1;">
                    <div class="stage_name">
                      <div class="stage_title">Warehouse 1</div>
                      <div class="tat_time"><i class="fa fa-clock-o"></i> 00:20</div>
                    </div>
                    <!-- <div class="ticnometric1" style="background: #f79f5c;"></div>
                    <div class="ticnometric2" style="background: #f79f5c;"></div> -->
                    <div class="stage">
                      <div class="stage_row">
                        <div class="stage_row_toggle" (click)="toggleAccordion2 = !toggleAccordion2">
                          <div class="name"><i class="fa fa-caret-down"></i>Metal Pallet</div>
                          <div class="number">24</div>
                        </div>

                        <div class="stage_row_toggle_data" *ngIf="toggleAccordion2" style="background: #dde4ff;">
                          <div class="stage_row_toggle" (click)="toggleAccordion2 = !toggleAccordion2">
                            <div class="name"><i class="fa fa-caret-down"></i>Small</div>
                            <div class="number">5</div>
                          </div><div class="stage_row_toggle" (click)="toggleAccordion2 = !toggleAccordion2">
                            <div class="name"><i class="fa fa-caret-down"></i>Medium</div>
                            <div class="number">6</div>
                          </div><div class="stage_row_toggle" (click)="toggleAccordion2 = !toggleAccordion2">
                            <div class="name"><i class="fa fa-caret-down"></i>Large</div>
                            <div class="number">4</div>
                          </div><div class="stage_row_toggle" (click)="toggleAccordion2 = !toggleAccordion2">
                            <div class="name"><i class="fa fa-caret-down"></i>Double-sided</div>
                            <div class="number">10</div>
                          </div>
                        </div>
                      </div><div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i>Wood Pallet</div>
                          <div class="number">13</div>
                        </div>
                      </div><div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i>Plastic Pallet</div>
                          <div class="number">54</div>
                        </div>
                      </div>
                    </div>
                    <div class="view_detail">
                      <i class="fa fa-info-circle"></i> View Details
                    </div>
                  </div>



                  <div class="single_stage_box" style="background: #ece7ff;">
                    <div class="stage_name">
                      <div class="stage_title">Warehouse 2</div>
                      <div class="tat_time"><i class="fa fa-clock-o"></i> 01:20</div>
                    </div>
                    <!-- <div class="ticnometric1" style="background: #f79f5c;"></div>
                    <div class="ticnometric2" style="background: #f79f5c;"></div> -->
                    <div class="stage">
                      <div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i>Metal Pallet</div>
                          <div class="number">12</div>
                        </div>
                      </div><div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i>Wood Pallet</div>
                          <div class="number">12</div>
                        </div>
                      </div><div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i>Plastic Pallet</div>
                          <div class="number">12</div>
                        </div>
                      </div>
                      <div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i> ForkLift</div>
                          <div class="number">22</div>
                        </div>
                      </div>
                    </div>
                    <div class="view_detail">
                      <i class="fa fa-info-circle"></i> View Details
                    </div>
                  </div>

                  <div class="single_stage_box" style="background: #fff6e7;">
                    <div class="stage_name">
                      <div class="stage_title">Plant Out</div>
                      <div class="tat_time"><i class="fa fa-clock-o"></i> 02:10</div>
                    </div>
                    <!-- <div class="ticnometric1" style="background: #f79f5c;"></div>
                    <div class="ticnometric2" style="background: #f79f5c;"></div> -->
                    <div class="stage">
                      <div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i>Metal Pallet</div>
                          <div class="number">25</div>
                        </div>
                      </div>
                      <div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i>Wood Pallet</div>
                          <div class="number">32</div>
                        </div>
                      </div>
                      <div class="stage_row">
                        <div class="stage_row_toggle">
                          <div class="name"><i class="fa fa-caret-down"></i>Plastic Pallet</div>
                          <div class="number">18</div>
                        </div>
                      </div>
                    </div>
                    <div class="view_detail">
                      <i class="fa fa-info-circle"></i> View Details
                    </div>
                  </div>
                </div>
              </div>

              <div class="double_graph_sec">
                <div class="row m-0">
                  <div class="col-md-4 p-0 pr-1">
                    <div class="sec_design">
                      <div class="header">
                        <div class="header-title">Category Wise Inventory Position</div>
                      </div>
                      <div class="radial_graph">
                        <figure class="highcharts-figure">
                          <div id="radialgraph1"></div>
                        </figure>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-8 p-0">
                    <div class="sec_design">
                      <div class="header">
                        <div class="header-title" style="display: flex;justify-content: space-between;">
                          <div>Category Wise Inventory Trend</div>
                          <div class="dropdown_design">
                            <select>
                              <option>Metal Pallet</option>
                              <option>Wood Pallet</option>
                              <option>Palstic Pallet</option>
                            </select>
                          </div>
                        </div>

                      </div>

                      <div class="line_graph">
                        <figure class="highcharts-figure">
                          <div id="linegraph1"></div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="graph_tbl_sec">
                <div class="row m-0">
                  <div class="col-md-8 p-0 pr-1">
                    <div class="sec_design">
                      <div class="header">
                        <div class="header-title" style="display: flex;justify-content: space-between;">
                          <div>Type Wise Inventory</div>
                          <div class="dropdown_design">
                            <select>
                              <option>Metal Pallet</option>
                              <option>Wood Pallet</option>
                              <option>Palstic Pallet</option>
                            </select>
                          </div>
                        </div>

                      </div>

                      <div class="horizontal_bar_graph">
                        <figure class="highcharts-figure">
                          <div id="horizontalbargraph1"></div>
                        </figure>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 p-0">
                    <div class="sec_design">
                      <div class="header">
                        <div class="header-title">Client Site Inventory</div>
                      </div>
                      <!-- <div class="dropdown_design">
                        <select>
                          <option>Category</option>
                          <option>Category 1</option>
                          <option>Category 2</option>
                          <option>Category 3</option>
                        </select>
                      </div> -->

                      <div class="table_sec">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th>Client Site</th>
                              <th>Metal Pallet</th>
                              <th>Wood Pallet</th>
                              <th>Plastic Pallet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Maruti</td>
                              <td>22</td>
                              <td>18</td>
                              <td>9</td>
                            </tr>
                            <tr>
                              <td>Tata</td>
                              <td>60</td>
                              <td>45</td>
                              <td>32</td>
                            </tr>
                            <tr>
                              <td>Hayundai</td>
                              <td>15</td>
                              <td>12</td>
                              <td>11</td>
                            </tr>
                            <tr>
                              <td>Suzuki</td>
                              <td>35</td>
                              <td>20</td>
                              <td>18</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="only_graph_sec">
                <div class="sec_design">
                  <div class="header">
                    <div class="header-title">Category Wise Inventory Count</div>
                  </div>
                  <!-- <div class="dropdown_design">
                    <select>
                      <option>Category</option>
                      <option>Category 1</option>
                      <option>Category 2</option>
                      <option>Category 3</option>
                    </select>
                  </div> -->

                  <div class="vertical_bar_graph">
                    <figure class="highcharts-figure">
                      <div id="verticalbargraph1"></div>
                    </figure>
                  </div>
                </div>
              </div>

              <div class="active_alert_tbl_sec">
                <div class="row m-0">
                  <div class="col-md-4 p-0 pr-1">
                    <div class="sec_design active_alerts">
                      <div class="subtitle">Active Alerts</div>
                      <!-- <div class="text-center mt-1 fade-in no-assets-tracked">No Pending Alerts</div> -->
                      <div class="alert_boxes_sec">
                        <div class="alert_box">
                          <div class="top_alert_row">
                            <h4>Asset Schedular Alerts</h4>
                            <button class="high">High</button>
                          </div>
                          <div class="bottom_alert_row">
                            <div class="name"><img src="assets\asset_icon2.png"> D54256</div>
                            <div class="time">18-11-2024 04:44</div>
                          </div>
                        </div>

                        <div class="alert_box">
                          <div class="top_alert_row">
                            <h4>Asset Schedular Alerts</h4>
                            <button class="low">Low</button>
                          </div>
                          <div class="bottom_alert_row">
                            <div class="name"><img src="assets\asset_icon2.png"> D54256</div>
                            <div class="time">18-11-2024 04:44</div>
                          </div>
                        </div>

                        <div class="alert_box">
                          <div class="top_alert_row">
                            <h4>Asset Schedular Alerts</h4>
                            <button class="medium">Medium</button>
                          </div>
                          <div class="bottom_alert_row">
                            <div class="name"><img src="assets\asset_icon2.png"> D54256</div>
                            <div class="time">18-11-2024 04:44</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-8 p-0">
                    <div class="sec_design">
                      <div class="header"><div class="header-title">
                        Inventory Location
                      </div></div>
                      <div class="table_sec mt-0" style="height: 262px;">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th>Asset</th>
                              <th>Location</th>
                              <th>Stage Tat</th>
                              <th>Total Tat</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Metal Pallet 15</td>
                              <td>Jaipur</td>
                              <td>01:22</td>
                              <td>00:20</td>
                            </tr>
                            <tr>
                              <td>Metal Pallet 23</td>
                              <td>Mumbai</td>
                              <td>00:30</td>
                              <td>01:44</td>
                            </tr>
                            <tr>
                              <td>Fork Lift 1</td>
                              <td>Indor</td>
                              <td>01:12</td>
                              <td>01:15</td>
                            </tr>
                            <tr>
                              <td>Wood Pallet 41</td>
                              <td>Ahemdabad</td>
                              <td>00:55</td>
                              <td>00:48</td>
                            </tr><tr>
                              <td>Plastic Pallet 12</td>
                              <td>Ahemdabad</td>
                              <td>01:55</td>
                              <td>02:48</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

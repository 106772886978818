<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Field Audit Report'|translate}}
                </h3>
              </div>
            </div>

            <div class="row auto_sugg_class">
              <div class="col-md-4">

              </div>
              <div class="col-md-2">
                <mat-form-field appearance="fill" class="mat_dropdown" *ngIf="role.user_role=='COMPANYADMIN'">
                  <mat-label> {{'select_plant'|translate}} </mat-label>
                  <mat-select (selectionChange)="selectedPlant($event)" [(value)]="plantId">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Select Plant" ngModel
                        (ngModelChange)="filterProcess($event)"
                        noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let plant of plantData;index as i" [value]="plant.plantId">
                      {{plant.plantName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field appearance="fill" class="mat_dropdown" *ngIf="!isPublic">
                  <mat-label> {{'select_process'|translate}} </mat-label>
                  <mat-select (selectionChange)="selectProcess($event)" [(value)]="processId">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Select Process" ngModel
                        (ngModelChange)="filterProcess($event)"
                        noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let process of processList;index as i" [value]="process.processId">
                      {{process.processName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-2">
                <mat-form-field appearance="fill" class="mat_dropdown" *ngIf="!isPublic">
                  <mat-label> {{'select_vehicle'| translate}} </mat-label>
                  <mat-select [(value)]="tripId">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Select Vehicle" ngModel
                        (ngModelChange)="filterVehicle($event)"
                        noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let  item of transactionVehicles;index as i" (click)="getAuditReport()"
                      [value]="item.ipa_transaction_id">
                      {{item.vehiclersn}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-2">
                <mat-form-field appearance="fill" class="mat_dropdown" *ngIf="!isPublic">
                  <mat-label>{{'select_transaction'|translate}}</mat-label>
                  <mat-select [(value)]="tripId">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Select Transaction" ngModel
                        (ngModelChange)="filterTxn($event)" noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let  item of transactionVehicles;index as i" (click)="getAuditReport()"
                      [value]="item.ipa_transaction_id">
                      {{item.txnid}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="card-body">
              <div class="trip_detail">
                <div class="field_audit_report">
                  <div class="filter_sec">
                    <div class="row align-items-center">
                      <div class="col-md-4 coll">
                        <label>{{'Field Name'|translate}}</label>
                        <input type="text" class="form-control" [(ngModel)]="filterReportByName"
                          placeholder="Field Name">
                      </div>

                      <div class="col-md-4 coll">
                        <label>{{'field_audit_count:'|translate}}</label>
                        <input type="text" class="form-control" [(ngModel)]="filterReportByCount"
                          placeholder="Field Audit Count">
                      </div>

                      <div class="col-md-4 action-block text-left">
                        <div class="btn-block text-right">
                          <button class="btn btn-submit" (click)="filterReport()">{{'apply'|translate}}</button>
                          <button class="btn btn-warning" (click)="resetReport()">{{'reset'|translate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tabel_audit_report">
                    <div class="table-responsive">
                      <table class="custom-table">
                        <thead>
                          <tr>
                            <th>{{'Field Name'|translate}}</th>
                            <th *ngFor="let stage of stages">{{stage}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of filterReportData  let i = index">
                            <td>
                              {{data.field_name}}
                              <img class="compare_icon" src="assets/images/compare.png"
                                (click)="contentModal(content, data)" *ngIf="imgTypes.indexOf(data.field_type) != -1"
                                title="Compare">
                            </td>
                            <td *ngFor="let s of stages; let j = index">
                              <span *ngIf="data.perform_on[s] == '-'">-</span>
                              <span *ngIf="imgTypes.indexOf(data.field_type) == -1 && data.perform_on[s] != '-'">
                                {{data.perform_on[s]}} </span>
                              <img
                                *ngIf="imgTypes.indexOf(data.field_type) != -1 && data.perform_on[s] != '-' && !isImgTypeFile(data.perform_on[s],data.field_type) "
                                src="assets/images/document_img.png">
                              <img
                                *ngIf="imgTypes.indexOf(data.field_type) != -1 && data.perform_on[s] != '-'&& isImgTypeFile(data.perform_on[s],data.field_type)"
                                [src]="onlyImageType.indexOf(data.field_type) == -1 ? data.perform_on[s][0].fileUrl : data.perform_on[s]"
                                (click)="displayImage(data.perform_on[s][0])">
                              <ng-container
                                *ngIf="imgTypes.indexOf(data.field_type) != -1 && onlyImageType.indexOf(data.field_type) == -1 && data.perform_on[s] != '-'">
                                <span>&nbsp;&nbsp;&nbsp;{{data.perform_on[s][0].value}}</span>
                                <img (click)="openMap(data.perform_on[s][0].lat,data.perform_on[s][0].long)"
                                  style="cursor:pointer;float: right; padding: 0px; background-color: transparent; width: auto;"
                                  height="20" width="20" src="assets/images/location.png" />
                              </ng-container>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Compare {{fieldName}}</h6>
    <div aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-times"></i></div>
  </div>
  <div class="modal-body">
    <div class="row images_row">
      <ng-container *ngFor="let stage of stages; let i = index;">
        <ng-container *ngIf="imgTypes.indexOf(fieldType) != -1 && onlyImageType.indexOf(fieldType) != -1 ">
          <div class="col-md-4 image_div"
            *ngIf="modalData[stage][0] != '-' && isImgTypeFile(modalData[stage], fieldType)">
            <h6 style="font-weight: bold; text-align: center;">{{stage}}</h6>
            <img [src]="modalData[stage][0]">
            <small style="text-align: center;">{{getImageName(modalData[stage])}}</small>
          </div>
          <div class="col-md-4 image_div"
            *ngIf="modalData[stage][0] != '-' && !isImgTypeFile(modalData[stage], fieldType)">
            <h6 style="font-weight: bold; text-align: center;">{{stage}}</h6>
            <a [href]="modalData[stage][0]">
              <img src="assets/images/document_img.png">
            </a>
            <small style="text-align: center;">{{getImageName(modalData[stage])}}</small>
          </div>
        </ng-container>
        <ng-container *ngIf="imgTypes.indexOf(fieldType) != -1 && onlyImageType.indexOf(fieldType) == -1 ">
          <div class="col-md-4 image_div"
            *ngIf="modalData[stage][0] != '-' && isImgTypeFile(modalData[stage], fieldType)">
            <h6 style="font-weight: bold; text-align: center;">{{stage}}</h6>
            <img [src]="modalData[stage][0].fileUrl">
            <div style="margin: 10px;">
              <span>&nbsp;&nbsp;&nbsp;{{modalData[stage][0].value}}</span>
              <img (click)="openMap(modalData[stage][0].lat,modalData[stage][0].long)"
                style="cursor:pointer;float: right; padding: 0px; background-color: transparent; width: auto;"
                height="20" width="20" src="assets/images/location.png" />
            </div>
          </div>
          <div class="col-md-4 image_div"
            *ngIf="modalData[stage][0] != '-' && !isImgTypeFile(modalData[stage], fieldType)">
            <h6 style="font-weight: bold; text-align: center;">{{stage}}</h6>
            <a [href]="modalData[stage][0].fileUrl">
              <img src="assets/images/document_img.png">
            </a>
            <div style="margin: 10px;">
              <span>&nbsp;&nbsp;&nbsp;{{modalData[stage][0].value}}</span>
              <img (click)="openMap(modalData[stage][0].lat,modalData[stage][0].long)"
                style="cursor:pointer; float: right;padding: 0px; background-color: transparent; width: auto;"
                height="20" width="20" src="assets/images/location.png" />
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </div>
</ng-template>

<div class="toast-container notification-info"
  [ngClass]="{'notification-info': notification.notification_type == 'INFO', 'notification-warning': notification.notification_type == 'WARNING', 'notification-alert': notification.notification_type == 'ALERT'}"
  *ngIf="notification != null">
  <div class="notification-toast  toast-header-icon">
    <div class="notification-header">
      <div class="toast-icon">
        <i class="fa fa-exclamation-triangle" *ngIf="notification.notification_type == 'ALERT'" aria-hidden="true"></i>
        <i class="fa fa-question-circle" *ngIf="notification.notification_type == 'WARNING'" aria-hidden="true"></i>
        <i class="fa fa-info-circle" *ngIf="notification.notification_type == 'INFO'" aria-hidden="true"></i>
      </div>
      &nbsp;&nbsp;
      <div class="toast-title">{{notification.title}}</div>
    </div>
    <div class="toast-message">
      <div class="toast-content">{{notification.message}}</div>
    </div>
  </div>
</div>

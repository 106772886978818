<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Alert Type" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input">{{"select_company" | translate}}</label>
                  <ps-auto-suggestion (onChange)="selectedCompany($event)" (onSelected)="selectedCompany($event)"
                    [data]="company" display="companyName" placeholder="Choose Company"
                    [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                </div>

                <div *ngIf="role.user_role == 'AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"select_plant" | translate}} </label>
                  <ps-auto-suggestion (onChange)="selectedPlant($event)" (onSelected)="selectedPlant($event)"
                    [data]="plantData" display="plantName" placeholder="Choose Plant"
                    [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"alert_type name" | translate}}</label>
                  <input class="form-control" type="text" name="alertName" id="alertName" [(ngModel)]="typeName"
                    #name="ngModel" placeholder="Enter Alert Name">
                  <div class="error" *ngIf="alertTypeNameValidator && alertTypeNameValidator.error">
                    <span>{{alertTypeNameValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"alert_color" | translate}}</label>
                  <input type="color" name="alertColor" id="alertColor" [(ngModel)]="typeColor" #name="ngModel"
                    placeholder="Pick Alert Name">
                  <div class="error" *ngIf="alertTypeColorValidator && alertTypeColorValidator.error">
                    <span>{{alertTypeColorValidator.msg}} </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12" *ngIf="typeColor && typeName">
                  <div class="alert" [style]='"background-color:"+typeColor'>
                    <span class="closebtn">&times;</span>
                    This is Alert Preview!!
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <div class="form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-warning" (click)="reset()"> {{"reset" | translate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{"save" | translate}}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"alert_type table" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{"s.no." | translate}}</th>
                    <th>{{"alert_name" | translate}}</th>
                    <th>{{"alert_color" | translate}}</th>
                    <th>{{"plant_name" | translate}}</th>
                    <th>{{"company_name" | translate}}</th>
                    <th class="action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of alertTypeList;let i=index">
                    <td>{{i+1}}</td>
                    <td>{{row.type_name}}</td>
                    <td>
                      <div class="alert" [style]='"background-color:"+row.type_color'></div>
                    </td>
                    <td>{{row.plant_name}}</td>
                    <td>{{row.company_name}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewDetails(row)"> {{"view" | translate}} </button>
                        <button class="btn btn-success" *ngIf="row.company_id || role.user_role == 'AXESTRACK'"
                          (click)="editAlert(row)"> {{"edit" | translate}} </button>
                        <button class="btn btn-danger" *ngIf="row.company_id || role.user_role == 'AXESTRACK'"
                          (click)="confirmAlert(row)"> {{"delete" | translate}}</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

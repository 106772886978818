import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { BreadcrumbService } from './../../theme/layout/header/breadcrumb/breadcrumb.service';
import { DataTableDirective } from 'angular-datatables';
import { CommonService } from '../../@core/services/common.service';
import { UserService } from '../../@core/services/user.service';
import { ApiService } from '../../@core/services/api.service';
import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuditModalComponent } from 'src/app/modals/audit-modal/audit-modal.component';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class AuditLogsComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective) dataTableElement: DataTableDirective;
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 3, 'Audit Logs');
  startDate: Date = new Date();
  endDate: Date = new Date();
  logsData: any;
  listAuditCount: any = [];

  actionLabels: any[] = [
    {
      tabName: 'pending',
      tab_icon: 'clock-o',
      tabBgColor: '#ebeb98',
      tabIconBg: '#a7a708',
      count:'pending_count',
      permission:this.user.permissionMap.get('view_pending_audits_access')
    },
    {
      tabName: 'performed',
      tab_icon: 'check',
      tabBgColor: '#b9e9fa',
      tabIconBg: '#1da6e0',
      count:'performed_count',
      permission:this.user.permissionMap.get('view_performed_audits_access')
    },
    {
      tabName: 'approved',
      tab_icon: 'thumbs-up',
      tabBgColor: '#b0f6b0',
      tabIconBg: '#00bd00',
      count:'approved_count',
      permission:this.user.permissionMap.get('view_approved_audits_access')
    },
    {
      tabName: 'rejected',
      tab_icon: 'times',
      tabBgColor: '#f7d5d5',
      tabIconBg: '#d60000',
      count:'rejected_count',
      permission:this.user.permissionMap.get('view_rejected_audits_access')
    },
  ];

  activeTab: number = 0;
  filterList: any = [];
  activeIndex: number = -1;
  disabled = false;
  activeTabName: string = 'pending';

  constructor(
    public table: TableService,
    public api: ApiService,
    public user: UserService,
    public common: CommonService,
    public breadCrumb: BreadcrumbService,
    public modal: NgbModal,
    public alert: AlertService
  ) {
    let breadcrumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Audit', url: null },
      { name: 'Audit Logs', link: '/user/audit-logs' },
    ];
    this.breadCrumb.getBreadcrumbs(breadcrumbs);
    this.common.sidebarToggled(true);
    this.startDate = new Date(
      this.startDate.setDate(this.startDate.getDate() - 30)
    );

    // this.getPending();
    this.getAuditCount();
  }

  viewDetails(row) {
    let data = {
      process: row.process_name,
      stage: row.action_stage_name,
      vehicleNumber: row.vehicle_no,
      actionName: row.action_name,
      param: {
        actionId: row.action_id,
        tripId: row.ipa_transaction_id,
      },
    };
    this.common.params = data;
    const activeModal = this.modal.open(AuditModalComponent, {
      size: 'xl',
      backdrop: 'static',
      windowClass: 'audit-modal',
    });
    activeModal.componentInstance.refData = {
      id: row.id,
      status: row.status,
      displayBtn: true,
    };
    activeModal.closed.subscribe((res: any) => {
      this.getAuditCount();
      this.getAuditLogsByStatus(row.status)
    });
  }

  changeTabs(i: any, tab: any) {
    this.getAuditLogsByStatus(tab.tabName.toUpperCase());
    this.activeTab = i;
    this.activeTabName = tab.tabName;
  }

  getAuditLogsByStatus(status: any) {
    this.common.loading = true;
    this.api.get('audit/get-audit-logs?status=' + status).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.filterList = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAuditCount() {
    this.common.loading = true;
    this.api.get('audit/get-audit-count-by-status').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.listAuditCount = res.data[0];
          Object.entries(this.listAuditCount).forEach(([key, value]) => {
            if (value === null) {
              this.listAuditCount[key] = 0;
            }
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAuditLogsByStatus('PENDING');
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.common.sidebarToggled(false);
  }

  renderTable() {
    this.dataTableElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnInit(): void {}

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Audit',
      description: `<b>&nbsp;` + 'Are you sure want to delete ? ' + `<b>`,
    };
    const activeModal = this.modal.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteAudit(row);
      }
    });
  }

  deleteAudit(row: any) {
    this.common.loading = true;
    this.api.get('audit/delete-audit-log?id=' + row.id).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alert.success(res.message);
        this.getAuditLogsByStatus(row.status)
        this.getAuditCount();
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }
}

import { filter } from 'rxjs/internal/operators/filter';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { CompanyService } from '../companies/companies.service';
import { PlantService } from '../plant/plant.service';

@Component({
  selector: 'app-plant-setting',
  templateUrl: './plant-setting.component.html',
  styleUrls: ['./plant-setting.component.scss'],
})
export class PlantSettingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'List of setting');
  allPlantSettingData: any = [];
  title = 'Plant Setting';
  btn = 'update';
  parkingType: number = 0;
  plantSetting = {
    id: null,
    preFix: '',
    postFix: '',
    avgTransactionCount: null,
    usernamePrefix: null,
    avgShiftDuration:8,
    complianceLimit: 0
  };
  methodId = null;
  methodEntryName = null;
  methodEntryId = null;
  vehicleVerificationMethodId = null;
  vehicleVerificationMethodName: any = null;
  userNameTypeMethodId: any = null;
  userNameTypeMethodName: any = null;
  entryType = '1';
  activeTab: any = 1;
  companyList: any[] = [];
  companyId: any = null;
  plantList: any[] = [];
  plantId: any = '';
  plantName: any = null;
  plantSettingData: any = [];
  plantSettingId: any = null;
  methodName: any = null;
  isGeoBounded: boolean = false;
  entryTypeMethodList: any[] = [];
  verificationMethodList: any[] = [];
  usernameGenerationMethodList: any[] = [];
  parkingAllotmentType = '0';
  parkingAllotmentPrefix = null;
  dockAllotmentType = '0';
  dockAllotmentPrefix = null;
  dockFutureBookingDate: number = 0;
  dockStrictQueueType: boolean = false;
  lateRemarkType: boolean = false;
  autoClosePendingTrip: boolean = false;
  allowedThreshold: boolean = false;
  threshold: number = 0;
  maxStreamingDuration:number=1;
  ocrEnable:boolean = false;
  autoAssignProcess:boolean = false;
  customForms:any;
  customFormName:any
  customFormId:number;
  isVisitorTrackingEnabled: boolean = false;

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  constructor(
    public api: ApiService,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public alertService: AlertService,
    private companyService: CompanyService,
    private plantService: PlantService,
    public user: UserService,
    public role: RoleService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Plant', url: null },
      { name: 'Plant Setting', url: '/user/plant-setting' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    if (role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN') {
      this.getPlantSetting();
    } else {
      this.plantId = this.user._loggedInUser.plantId;
      this.getAllMethodsForAllExecutionType(this.plantId);
    }

    if (role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else if (role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.user._loggedInUser.companyId);
    }
  }

  ngOnInit(): void {
    this.getAllVisitorForms();
  }

  selectCompany(event: any) {
    this.reset();
    this.companyId = event.companyId;
    this.getPlantById(this.companyId);
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getAllMethodsForAllExecutionType(this.plantId);
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantList = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectMethod(event: any) {
    this.methodEntryId = event.id;
    this.methodEntryName = event.methodName;
  }

  selectVehicleVerificationMethod(event: any) {
    this.vehicleVerificationMethodId = event.id;
    this.vehicleVerificationMethodName = event.methodName;
  }

  selectUserNameTypeMethod(event: any) {
    this.userNameTypeMethodId = event.id;
    this.userNameTypeMethodName = event.methodName;
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        this.common.loading = false;
        res.data.forEach((element) => {
          if (element.companyStatus == 'ACTIVE') this.companyList.push(element);
        });
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  reset() {
    this.plantSetting = {
      id: null,
      preFix: '',
      postFix: '',
      avgTransactionCount: null,
      usernamePrefix: null,
      avgShiftDuration:8,
      complianceLimit:0
    };
    this.plantSettingId = null;
    if (this.role.user_role == 'AXESTRACK') {
      this.plantId = null;
      this.plantName = null;
      this.companyId = null;
      this.plantList = null;
      this.vehicleVerificationMethodId = null;
      this.vehicleVerificationMethodName = null;
      this.methodEntryId = null;
      this.methodEntryName = null;
      this.entryType = '1';
    }
    this.isGeoBounded = false;
    this.userNameTypeMethodId = null;
    this.userNameTypeMethodName = null;
    this.parkingAllotmentPrefix = null;
    this.parkingAllotmentType = '0';
    this.dockAllotmentPrefix = null;
    this.dockAllotmentType = '0';
    this.ocrEnable = false;
  }

  savePlantSetting() {
    let params = {};
    if (this.plantSettingId == null) {
      this.alertService.error('Please select a plant...');
    } else if (this.parkingAllotmentPrefix == null) {
      this.alertService.error('Please select a parking allotment prefix...');
    }else {
      let entryTypeValue: any = '1';
      let methodIdValue: any = null;

      if (this.entryType == '0') {
        entryTypeValue = 0;
        methodIdValue = this.methodEntryId;
      } else if (this.entryType == '2') {
        entryTypeValue = 2;
      } else if (this.entryType == '3') {
        entryTypeValue = 3;
      }
      params = {
        id: this.plantSettingId,
        preFix: this.plantSetting.preFix,
        postFix: this.plantSetting.postFix,
        avgTransactionCount: this.plantSetting.avgTransactionCount,
        complianceLimit:this.plantSetting.complianceLimit,
        plantId: this.plantId,
        entryType: entryTypeValue,
        methodId: methodIdValue,
        vehicleVerificationMethodId: this.vehicleVerificationMethodId,
        isGeoBounded: this.isGeoBounded,
        userNameMethodId: this.userNameTypeMethodId,
        userNameNumericValue: this.plantSetting.usernamePrefix,
        parkingAllotmentPrefix: this.parkingAllotmentPrefix,
        parkingAllotmentType: this.parkingAllotmentType,
        dockAllotmentPrefix: this.dockAllotmentPrefix,
        dockAllotmentType: this.dockAllotmentType,
        dockFutureBookingDate: this.dockFutureBookingDate,
        dockStrictQueueType: this.dockStrictQueueType,
        lateRemarkType: this.lateRemarkType,
        autoClosePendingTrip: this.autoClosePendingTrip,
        parkingType: this.parkingType,
        shiftThreshold: this.threshold,
        streamingDuration:this.maxStreamingDuration,
        avgShiftDuration:this.plantSetting.avgShiftDuration,
        ocrEnable:this.ocrEnable,
        autoAssignProcess:this.autoAssignProcess,
        defaultVisitorFormId:this.customFormId,
        isVisitorTrackingEnable: this.isVisitorTrackingEnabled
      };
      this.common.loading = true;
      this.api.post('plant/save-plant-setting', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.alertService.success(res.message);
            if (
              this.role.user_role == 'AXESTRACK' ||
              this.role.user_role == 'COMPANYADMIN'
            ) {
              this.getPlantSetting();
            } else {
              this.getPlantSettingByPlantId(this.plantId);
            }
            // this.getPlantSetting();
          } else {
            this.alertService.error(res.message);
          }
        },
        (err) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    }
  }

  getPlantSetting() {
    let companyId =
      this.role.user_role == 'COMPANYADMIN'
        ? this.user._loggedInUser.companyId
        : 0;
    this.common.loading = true;
    this.api.get('plant/get-plant-setting?companyId=' + companyId).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (
          this.role.user_role == 'AXESTRACK' ||
          this.role.user_role == 'COMPANYADMIN'
        ) {
          this.allPlantSettingData = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  editSetting(item) {
    this.plantSetting.id = item.id;
    this.plantSetting.preFix = item.pre_fix;
    this.plantSetting.usernamePrefix = item.usernameNumericValue;
    this.plantSetting.postFix = item.post_fix;
    this.plantSetting.avgTransactionCount = item.avg_count;
    this.plantSetting.avgShiftDuration=item.avgShiftDuration;
    this.plantSetting.complianceLimit = item.complianceLimit;
    this.plantId = item.plantid;
    this.plantSettingId = item.id;
    this.isGeoBounded = item.isGeoBounded;
    this.parkingAllotmentType = item.parkingAllotmentType.toString();
    this.parkingAllotmentPrefix = item.parkingPrefix;
    this.dockAllotmentType = item.dockAllotmentType.toString();
    this.dockAllotmentPrefix = item.dockAllotmentPrefix;
    this.dockFutureBookingDate = item.dockFutureBookingDate;
    this.ocrEnable =item.ocrEnable;
    (this.dockStrictQueueType = item.dockStrictQueueType),
      (this.parkingType =
        item.parkingType == 'Open_Parking'
          ? 0
          : item.parkingType == 'Slot_Parking'
          ? 1
          : 2);
  }

  getPlantSettingByPlantId(plantId: any) {
    this.common.loading = true;
    this.api
      .get('plant/get-plant-setting-by-plant-id?plantId=' + plantId)
      .subscribe(
        (response: any) => {
          const res = response.data;
          this.common.loading = false;
          this.plantSettingData = res[0];
          this.plantSetting.preFix = res[0].transactionPrefix;
          this.plantSetting.postFix = res[0].transactionPostfix;
          this.plantSetting.avgTransactionCount =
            res[0].averageTransactionCount;
            this.plantSetting.complianceLimit =
            res[0].complianceLimit!=null?res[0].complianceLimit:0;
          this.plantSettingId = res[0].id;
          this.entryType =
            res[0].entryType != null
              ? res[0].entryType.toString()
              : res[0].entryType;
          this.isGeoBounded = res[0].isGeoBounded;
          this.userNameTypeMethodId = res[0].usernameMethodId;
          this.plantSetting.usernamePrefix = res[0].usernameNumericValue;
          this.vehicleVerificationMethodId = res[0].vehicleVerificationMethodId;
          this.parkingAllotmentType = res[0].parkingAllotmentType.toString();
          this.parkingAllotmentPrefix = res[0].parkingPrefix;
          this.dockAllotmentType = res[0].dockAllotmentType.toString();
          this.dockAllotmentPrefix = res[0].dockPrefix;
          this.dockFutureBookingDate = res[0].allowedFutureBookingBefore;
          this.dockStrictQueueType = res[0].isStrictQueueManagement;
          this.plantSetting.avgShiftDuration=res[0].avgShiftDuration;
          this.lateRemarkType = res[0].lateRemarkType;
          this.autoClosePendingTrip = res[0].autoClosePendingTrip;
          this.maxStreamingDuration = res[0].streamingDuration;
          this.ocrEnable = res[0].ocrEnable;

          this.customForms.forEach((customForm) => {
            if (customForm.id == res[0].defaultVisitorFormId) {
              this.customFormName = customForm.form_title;
              return;
            }
          });

          this.parkingType =
            res[0].parkingType == 'Open_Parking'
              ? 0
              : res[0].parkingType == 'Slot_Parking'
              ? 1
              : 2;
          this.vehicleVerificationMethodName =
            this.verificationMethodList.filter(
              (e) => e.id == this.vehicleVerificationMethodId
            )[0]?.methodName;
          this.userNameTypeMethodName =
            this.usernameGenerationMethodList.filter(
              (e) => e.id == res[0].usernameMethodId
            )[0]?.methodName;
          if (res[0].entryType == 0) {
            this.methodEntryId = res[0].entryMethodId;
            this.entryTypeMethodList.forEach((e) => {
              if (e.id == this.methodEntryId) {
                this.methodEntryName = e.methodName;
                return;
              }
            });
          } else {
            this.methodId = null;
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getEntryTypeMethod(pId: any) {
    return new Promise((resolve, reject) => {
      let params = {
        plantId: pId,
        methodType: 2,
      };
      this.common.loading = true;
      this.api.get('method/get-all-methods-by-method-type', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.entryTypeMethodList = res.data;
          }
          resolve(res.data);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  getVerificationMethod(pId: any) {
    return new Promise((resolve, reject) => {
      let params = {
        plantId: pId,
        methodType: 4,
      };
      this.common.loading = true;
      this.api.get('method/get-all-methods-by-method-type', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.verificationMethodList = res.data;
          }
          resolve(res.data);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  getUserNameGenerationMethod(pId: any) {
    return new Promise((resolve, reject) => {
      let params = {
        plantId: pId,
        methodType: 5,
      };
      this.common.loading = true;
      this.api.get('method/get-all-methods-by-method-type', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.usernameGenerationMethodList = res.data;
          }
          resolve(res.data);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  async getAllMethodsForAllExecutionType(plantId: any) {
    await this.getEntryTypeMethod(plantId);
    await this.getVerificationMethod(plantId);
    await this.getUserNameGenerationMethod(plantId);
    await this.getPlantSettingByPlantId(plantId);
  }

  checkStepSize() {
    if (!(this.maxStreamingDuration > 0 && this.maxStreamingDuration <= 10)) {
      this.alertService.warn('Streaming Duration Should be inbetween 1 Min to 10 Min !!');
      this.maxStreamingDuration = 1;
    }
  }

  selectedCustomForm(selectedCustomForm:any){
    this.customFormName = selectedCustomForm.form_title;
    this.customFormId = selectedCustomForm.id;
    console.log(selectedCustomForm)
    // console.log(selectedCustomForm);
    // this.customFormId = selectedCustomForm.id
    // this.getLastRankingIndex();
    // this.getFormFields();
}

getAllVisitorForms() {
  let params={
    formType:'Visitor Form'
  }
  this.common.loading = true;
  this.api.get('form/get-custom-forms',params).subscribe(
    (res: any) => {
      this.common.loading = false;
      this.customForms = res.data;
      console.log(res.data);
      // this.renderTable();
    },
    (err: any) => {
      console.error('Error: ', err);
      this.common.loading = false;
    }
  );
}
}

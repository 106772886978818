<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'method_field_mapping'|customTranslate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input">{{('method'|customTranslate)+' '+('type'|customTranslate)}}</label>
                  <select class="form-control" id="methodType" name="methodType" [(ngModel)]="mappingType" [value]=0>
                    <option [value]=0>Local</option>
                    <option [value]=1>Global</option>
                  </select>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12"
                  *ngIf="role.user_role == 'AXESTRACK' && mappingType == 0">
                  <label for="my-input" class="required">{{'select_company'|customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectCompany($event)" [data]="companyList" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyNameValidator && companyNameValidator.error">
                    <span>{{companyNameValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12"
                  *ngIf="role.user_role == 'AXESTRACK' && mappingType == 0">
                  <label for="my-input" class="required">{{'select_plant'|customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectPlant($event)" [data]="plantList" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{plantNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="plantId">
                  <label for="my-input" class="required">{{'select_fields'|customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedField($event)" [data]="FieldList"
                    display="databaseFieldName" placeholder="Choose Field"
                    [preSelected]="{databaseFieldName:selectedFieldName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="databaseFieldNameValidator && databaseFieldNameValidator.error">
                    <span>{{databaseFieldNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'select_method'|customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedFieldMethod($event)" [data]="MethodList"
                    display="method_name" placeholder="Choose Method" [preSelected]="{method_name:selectedMethodName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="methodNameValidator && methodNameValidator.error">
                    <span>{{methodNameValidator.msg}} </span>
                  </div>
                </div>
                <div class="row form-group action-block">
                  <div class="btn-block">
                    <button class="btn btn-warning" (click)="reset()"> {{'reset'|customTranslate}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{ButtonName|customTranslate}}
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive pt-4">
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{'serial_no'|customTranslate}}</th>
                          <th>{{('database_field'|customTranslate)+' '+('name'|customTranslate)}}</th>
                          <th>{{('database_method'|customTranslate)+' '+('name'|customTranslate)}}</th>
                          <th class="action">{{'Actions'|customTranslate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of fieldDataMapping;index as i">
                          <td>{{i+1}}</td>
                          <td> {{item.field_name ? item.field_name : "-"}}</td>
                          <td>{{item.method_name?item.method_name : "-"}}</td>
                          <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-primary" (click)="viewActionActionMapping(item)">
                                {{'view'|customTranslate}} </button>
                              <button class="btn btn-success" (click)="editActionMapping(item)"
                                *ngIf="item.mappingtype != 1 || role.user_role == 'AXESTRACK'"> {{'edit'|customTranslate}}
                              </button>
                              <button class="btn btn-danger" (click)="confirmAlert(item)"
                                *ngIf="item.mappingtype != 1 || role.user_role == 'AXESTRACK'"> {{'delete'|customTranslate}}
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

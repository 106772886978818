import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import {
  COMMON_ACTION_BUTTON,
  TABLE_TYPE,
  TableActions,
  TableColumns,
} from 'src/app/dataType/customTableInterface';
import { TranslateService } from '@ngx-translate/core';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-data-entry',
  templateUrl: './data-entry.component.html',
  styleUrls: ['./data-entry.component.scss'],
})
export class DataEntryComponent implements OnInit {
  tabData = [
    { tabname: 'Invoice', value: 'Invoice', title: 'Invoice Entry',sample:"../../../assets/sample-files/invoice-demo-data.csv" },
    { tabname: 'DO', value: 'DO', title: 'DO Entry',sample:"../../../assets/sample-files/do-dummy-data.csv" },
  ];
  activeTab: any;
  btn: string = 'Save';
  dataEntry = {
    invoiceId: null,
    invoice: null,
    quantity: null,
    quantityUnit: null,
    client: null,
    validityDate: null,
    material: null,
    // materialId: null,
    type: 'INDIVIDUAL',
    email: null,
    vehicleRc: null,
    dispatchOrder: null,
  };
  fileEvent: any = [];
  // productList: any = [];

  // Datatable Configs
  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  loading: boolean = false;
  columns: TableColumns[] = [];
  data: any = [];

  constructor(
    public api: ApiService,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public role: RoleService,
    public table: TableService,
    public alertService: AlertService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Data Entry', link: '/user/data-entry' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit() {
    this.activeTab = this.tabData[0];
    // this.getProductList();
    this.getDataEntryTableData();
  }

  onTabSelect(aciveTab: any) {
    this.activeTab = aciveTab;
    this.resetDetail();
  }

  resetDetail() {
    this.btn = 'save';
    this.dataEntry = {
      invoiceId: null,
      invoice: null,
      quantity: null,
      quantityUnit: null,
      client: null,
      validityDate: null,
      material: null,
      // materialId: null,
      type: 'INDIVIDUAL',
      email: null,
      vehicleRc: null,
      dispatchOrder: null,
    };
    this.getDataEntryTableData();
  }

  formVaildator() {}

  selectMaterial(event: any) {
    // let material = '';
    //   this.dataEntry.material = event.map(res => material.);
  }

  uploadFile(event: any) {
    let frmData = new FormData();
    for (const file of event.target.files) {
      frmData.append('files', file);
      frmData.append('dataEntryType', this.activeTab.value);
      this.fileEvent.inProgress = true;
      this.common.loading = true;
      this.api.post('data-entry/convert-csv-to-json', frmData).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status)
            this.alertService.success(
              this.activeTab.value + ' Saved Successfully !!'
            );
          this.resetDetail();
        },
        (err: any) => {
          this.alertService.error(err.error.message);
          this.common.loading = false;
        }
      );
    }
  }

  // getProductList() {
  //   let params = {
  //     allProducts: false,
  //   };
  //   this.api.get('product/get-product-list', params).subscribe(
  //     (res: any) => {
  //       if (res.status) {
  //         this.productList = res.data;
  //       }
  //     },
  //     (err: any) => {
  //       console.error('Error: ', err);
  //       this.common.loading = false;
  //     }
  //   );
  // }

  saveDataEntry() {
    let url = this.activeTab.value == 'DO' ? 'add-do' : 'add-invoice';
    this.api.post('data-entry/' + url, this.dataEntry).subscribe(
      (res: any) => {
        this.resetDetail();
        if (res.status) {
          this.alertService.success('Data Saved SuccessFully !!');
        } else {
          this.alertService.warn('Data Save Error!!');
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getDataEntryTableData() {

    let url =
      this.activeTab.value == 'DO'
        ? 'get-all-dispatch-order'
        : 'get-all-invoice';
    this.api.get('data-entry/' + url).subscribe(
      (res: any) => {
        if (res.status) {
          this.loadTable(res.data);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        // this.common.loading = false;
      }
    );
  }

  loadTable(tableData: any) {
    let isInvoice = this.activeTab.value != 'DO';
    this.data = tableData;
    this.columns = [
      !isInvoice && {
        columnLabel: this.translateService.instant('do_number'),
        columnName: 'order_name',
      },
      !isInvoice && {
        columnLabel: this.translateService.instant('vehicle_number'),
        columnName: 'vehicle_rc_no',
      },
      {
        columnLabel: this.translateService.instant('invoice_no'),
        columnName: isInvoice ? 'invoice_no' : 'inv_no',
      },
      {
        columnLabel: this.translateService.instant('client'),
        columnName: 'client',
      },
      {
        columnLabel: this.translateService.instant('client_email'),
        columnName: 'client_email',
      },
      {
        columnLabel: this.translateService.instant('product'),
        columnName: 'materials',
      },
      {
        columnLabel: this.translateService.instant('invoice_type'),
        columnName: 'type',
      },
      {
        columnLabel: this.translateService.instant('quantity'),
        columnName: isInvoice ? 'quantity' : 'order_weight',
      },
      isInvoice && {
        columnLabel: this.translateService.instant('quantity_unit'),
        columnName: 'quantity_unit',
      },
      {
        columnLabel: this.translateService.instant('validity_date'),
        columnName: 'validity_date',
      },
      // {
      //   columnLabel: this.translateService.instant("invoice_expired"),
      //   columnName:"validity_date",
      //   dataFormatter: (row: any, column: TableColumns, index: number) => {
      //     return column > current_date;
      //   }
      // },
    ];

    isInvoice &&
      this.columns.push({
        columnLabel: this.translateService.instant('Action'),
        columnName: null,
        dataActions: [
          ...this.table.getTableActionButtons(
            [
              COMMON_ACTION_BUTTON.VIEW,
              COMMON_ACTION_BUTTON.EDIT,
              // COMMON_ACTION_BUTTON.DELETE,
            ],
            (
              column: TableColumns,
              row: any,
              index: number,
              actionname: COMMON_ACTION_BUTTON
            ) => {
              if (actionname == COMMON_ACTION_BUTTON.EDIT) {
                this.editInvoice(row);
              } else if (actionname == COMMON_ACTION_BUTTON.VIEW) {
                this.viewData(row);
              }
            },
            null,
            null,
            (
              column: TableColumns,
              row: any,
              index: number,
              actionName: string
            ) => {
              return true;
            }
          ),
        ] as TableActions[],
      });

    this.columns = this.columns.filter((res: any) => res != false);

  }

  editInvoice(editColumn: any) {
    this.dataEntry = {
      invoiceId: editColumn.id,
      invoice: editColumn.invoice_no,
      quantity: editColumn.quantity,
      quantityUnit: editColumn.quantity_unit,
      client: editColumn.client,
      validityDate: editColumn.validity_date,
      material: editColumn.materials,
      type: editColumn.type,
      email: editColumn.client_email,
      vehicleRc: null,
      dispatchOrder: null,
    };
    this.btn = 'edit';
  }

  viewData(columnData: any) {
    this.common.params = {
      details: [columnData],
      title: this.activeTab.value + ' Details',
    };
    this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }
}

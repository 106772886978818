<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body stageType-form">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Parking Zone Management' | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"zone_name" | customTranslate}}</label>
                  <input class="form-control" placeholder="Enter Zone Name" type="text" name="zoneName"
                    [(ngModel)]="zoneName">
                  <div class="error" *ngIf="zoneNameValidator && zoneNameValidator.error">
                    <span>{{zoneNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"zone_slot_limit" | customTranslate}}</label>
                  <input class="form-control" type="number" min="0" placeholder="Enter Zone Slot Limit"
                    name="zoneSlotLimit" [(ngModel)]="zoneSlotLimit">
                  <div class="error" *ngIf="zoneSlotLimitValidator && zoneSlotLimitValidator.error">
                    <span>{{zoneSlotLimitValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"zone_status" | customTranslate}}</label>
                  <select name="zone_status" class="form-control" [(ngModel)]="zoneStatus">
                    <option selected="ACTIVE" value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </select>
                </div>

              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    customTranslate}}</button>
                  <button class="btn btn-warning" (click)="reset()">{{"reset" | customTranslate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}} </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"zone_list" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"serial_no" | customTranslate}}</th>
                    <th>{{"zone_name" | customTranslate}}</th>
                    <th>{{"zone_slot_limit" | customTranslate}}</th>
                    <th>{{"zone_status" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of zones let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.zoneName}}</td>
                    <td>{{row.slotLimit}}</td>
                    <td>{{row.zoneStatus}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="view(row)">{{"view" | customTranslate}}</button>
                        <button class="btn btn-success" (click)="edit(row)">{{"edit" | customTranslate}}</button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)">{{"delete" | customTranslate}}</button>
                      </div>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</div>

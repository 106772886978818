import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { WorkTypeService } from '../work-type/work-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-work-type',
  templateUrl: './work-type.component.html',
  styleUrls: ['./work-type.component.scss'],
})
export class WorkTypeComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  responseMessage: any = '';
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'PROCESS TYPE LIST');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAllWorkType();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  workType = {
    workTypeId: '',
    workTypeName: '',
    workTypeColor: '',
    workTypeStatus: 'ACTIVE',
    companyId: '',
    plantId: '',
    processId: '',
  };

  buttonName = 'save';
  title = 'Add Process Type';
  submitted = false;
  responseData: any[] = [];

  constructor(
    private alertService: AlertService,
    private modalService: NgbModal,
    public common: CommonService,
    private workTypeService: WorkTypeService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Process', url: null },
      { name: 'Process Type Management', url: '/user/list_work_type' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {}
  // Save Work Type
  saveWorkType(): void {
    let data = {
      workTypeId: this.workType.workTypeId,
      workTypeName: this.workType.workTypeName,
      workTypeColor: this.workType.workTypeColor,
      workTypeStatus: this.workType.workTypeStatus,
    };
    this.common.loading = true;
    this.workTypeService.saveWorkType(data).subscribe(
      (response) => {
        this.common.loading = false;
        if (response.status) {
          this.alertService.success(response.message);
        } else {
          this.alertService.error(response.message);
        }
        this.submitted = true;
        this.getAllWorkType();
        this.resetWorkType();
      },
      (error) => {
        this.common.loading = false;
      }
    );
  }

  getAllWorkType() {
    this.common.loading = true;

    this.workTypeService.getAllWorkType().subscribe(
      (res: any) => {
        this.common.loading = false;

        this.responseData = res.data ?? [];
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  viewWorkType(row?: any) {
    let rowUpdated = row;
    delete rowUpdated['workTypeColor'];
    delete rowUpdated['plant'];
    this.common.params = {
      details: [rowUpdated],
      title: 'Process Type Details',
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Process Type ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row.workTypeName +
        '  ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteWorkType(row);
      }
    });
  }

  deleteWorkType(row?: any) {
    this.common.loading = true;
    row.workTypeStatus = 'DELETED';
    this.workTypeService.saveWorkType(row).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
        } else {
          this.alertService.error(res.message);
        }
        this.getAllWorkType();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  editWorkType(row?: any) {
    this.common.gotoTop();
    this.resetWorkType();
    this.workType.workTypeId = row.workTypeId;
    this.workType.workTypeName = row.workTypeName;
    this.workType.workTypeStatus = row.workTypeStatus;
    this.workType.workTypeColor = row.workTypeColor;
    this.buttonName = 'update';
    this.title = 'Edit Process Type';
  }

  resetWorkType() {
    this.workType = {
      workTypeId: '',
      workTypeName: '',
      workTypeColor: '',
      workTypeStatus: 'ACTIVE',
      companyId: '',
      plantId: '',
      processId: '',
    };
    this.workTypeNameValidator = null;
    this.statusValidator = null;
    this.buttonName = 'save';
    this.title = 'Add Process Type';
  }

  workTypeNameValidator: any;
  statusValidator: any;
  formValidator() {
    this.workTypeNameValidator = this.common.nullValidator(
      this.workType.workTypeName,
      'Work Type Name Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.workType.workTypeStatus,
      'Work Type Status Mandatory'
    );
    if (!this.workTypeNameValidator.error && !this.statusValidator.error) {
      this.saveWorkType();
    }
  }
}

import { CommonService } from '../../@core/services/common.service';
import { PlantService } from '../plant/plant.service';
import { CompanyService } from '../companies/companies.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';
import { ApiService } from '../../@core/services/api.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { Subject, forkJoin } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HardwareOTALogsComponent } from '../HardwareOTALogs/HardwareOTALogs.component';

@Component({
  selector: 'app-ota-management',
  templateUrl: './ota-management.component.html',
  styleUrls: ['./ota-management.component.scss'],
})
export class OtaManagementComponent implements OnInit {
  allCompanies: any[] = [];
  preferenceLevel: any[] = [
    { level: 'AXESTRACK' },
    { level: 'COMPANY' },
    { level: 'PLANT' },
  ];
  preferenceType: string = 'AXESTRACK';
  companyName: string;
  plantName: string;
  allPlants: any[] = [];
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  OTAManagementData: any[] = [];
  hardwareHealthData: any[] = [];

  table = {
    data: {
      headings: {},
      columns: [],
    },
    settings: {
      hideHeader: true,
    },
  };

  hardwareVersionType: string = 'CONTROLLER';
  hardware: any = {
    id: null,
    type: 'AT_CONTROLLER',
    version: 'CONTROLLER',
  };

  hardwareTypes: any[] = [{ name: 'AT Controller', type: 'AT_CONTROLLER' }];

  versionType: any[] = [
    { name: 'Controller', value: 'CONTROLLER' },
    { name: 'OTA Controller', value: 'CONTROLLER_OTA' },
  ];

  selectedVersion: string = '';
  OtaChartLabels: Label[] = ['OTA Status'];
  hardwareOtaLogs: any[] = [];

  public OTAChartData: SingleDataSet = [];
  public OTAChartColors: Color[] = [
    {
      backgroundColor: ['#096c6c', '#3f8f83', '#9d3410', '#b75928', '#089442'],
    },
  ];

  otaChartPlugins: [];
  otaChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    title: {
      fontColor: 'white',
      fontSize: 10,
    },

    legend: {
      position: 'bottom', // Change the legend position here
      labels: {
        fontSize: 10,
        // fontFamily: 'sans-serif',
        fontStyle: 'bold',
      },
    },
    elements: {
      rectangle: {
        borderWidth: 0,
      },
    },
  };
  plantId: number = 0;
  companyId: number = 0;
  plantData: any;
  allStages: any;
  stageName: string;
  allHardwareVersion: any = [];
  statusObject: any = {
    UPDATE_SENT: 0,
    UPDATE_INITIATED: 0,
    UPDATE_FAIL: 0,
    UPDATE_CANCEL: 0,
    UPDATE_COMPLETED: 0,
  };
  OTAcontrollerVersionList: any[] = [];
  controllerVersionList: any[] = [];
  OTATrigger = new Subject();

  resetChart() {
    this.statusObject = {
      UPDATE_SENT: 0,
      UPDATE_INITIATED: 0,
      UPDATE_FAIL: 0,
      UPDATE_CANCEL: 0,
      UPDATE_COMPLETED: 0,
    };
  }

  constructor(
    private breadcrumbService: BreadcrumbService,
    private companyService: CompanyService,
    private plantService: PlantService,
    private common: CommonService,
    private apiService: ApiService,
    private alert: AlertService,
    private modalService: NgbModal
  ) {
    let breadcrumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Hardware', url: null },
      { name: 'OTA Management', link: '/user/hardware-ota-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcrumbs);
    // this.companyId = this.user._loggedInUser.companyId;
    // this.plantId = this.user._loggedInUser.plantId;
  }

  hardwareListCall(apiUrl) {
    return this.apiService.get(apiUrl);
  }

  selectPreference(event: any) {
    this.preferenceType = event;
    this.plantId = 0;
    if (event == 'AXESTRACK') {
      this.companyId = 0;
      this.getOtaData();
    } else if (event == 'COMPANY') {
      this.companyId = 0;
      this.getAllCompanies();
    } else {
      this.getAllPlants(this.companyId);
    }
  }

  getAllHardwareVersionList() {
    let controllerApiUrl = 'apk/get-app-details?versionType=CONTROLLER';
    let OTAControllerApiUrl = 'apk/get-app-details?versionType=CONTROLLER_OTA';
    forkJoin([
      this.hardwareListCall(controllerApiUrl),
      this.hardwareListCall(OTAControllerApiUrl),
    ]).subscribe(
      ([res1, res2]) => {
        // Handle the responses from all API calls
        this.controllerVersionList = res1['data'] || [];
        this.OTAcontrollerVersionList = res2['data'] || [];
        this.OTATrigger.next(true);
      },
      (error) => {
        // Handle any errors that occur during the API calls
        console.error('An error occurred:', error);
      }
    );
  }

  getVersionList() {
    this.common.loading = true;
    let apiUrl = `apk/get-app-details?versionType=${this.hardwareVersionType}`;
    this.apiService.get(apiUrl).subscribe(
      (res: any) => {
        if (res.status) {
          this.allHardwareVersion = res.data;
          this.common.loading = false;
        } else {
          this.alert.error();
        }
      },
      (err) => {
        this.common.loading = false;
        console.error(err);
        this.alert.error();
      }
    );
  }

  getOtaData() {
    this.common.loading = true;
    let apiUrl = 'hardware/get-ota-logs';
    let params = {
      companyId: this.companyId,
      plantId: this.plantId || 0,
      hardwareType: 'AT_CONTROLLER',
    };
    this.apiService.get(apiUrl, params).subscribe(
      (res: any) => {
        this.OTAManagementData = res['data'] || [];
        this.setTable();

        if (this.OTAManagementData?.length) {
          this.setChartData();
        }

        this.common.loading = false;
      },
      (err) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  setChartData() {
    this.resetChart();
    this.OTAManagementData.forEach((item) => {
      this.statusObject[item.ota_status] += 1;
    });
    console.error(this.statusObject);
    this.OTAChartData = [...Object.values(this.statusObject)];
    this.OtaChartLabels = [...Object.keys(this.statusObject)];
  }

  hexToRgba(hex, alpha) {
    // Remove any "#" symbol from the hex code
    hex = hex.replace('#', '');

    // Parse the red, green, and blue color components from the hex code
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);

    // Check that the parsed values are valid
    if (isNaN(r) || isNaN(g) || isNaN(b)) {
      throw new Error('Invalid hexadecimal color code: ' + hex);
    }

    // Construct the RGBA color string with the alpha component
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  }

  getAllCompanies() {
    this.companyService.getCompany().subscribe(
      (res: any) => {
        this.allCompanies = res.data || [];
        if (this.companyId != 0) {
          this.companyName = this.allCompanies.find(
            (item) => item.companyId == this.companyId
          ).companyName;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getAllPlants(id?: any) {
    if (this.companyId == 0) return;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.plantData = res.data || [];
        this.allPlants = res.data || [];
        this.allPlants = this.plantData.filter(
          (item) => item.companyId == this.companyId
        );
        if (this.plantId != 0) {
          this.plantName = this.allPlants.find(
            (item) => item.plantId == this.plantId
          ).plantName;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  ngOnInit(): void {
    // this.getAllCompanies();
    this.getVersionList();
    // this.getAllPlants();
    this.getAllStage();
    this.getAllHardwareVersionList();
    this.OTATrigger.subscribe((res: boolean) => {
      if (res) {
        this.getOtaData();
      }
    });
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    // this.allPlants = this.plantData.filter(item =>
    // item.companyId == this.companyId);
    this.getOtaData();
    if (this.preferenceType == 'PLANT' && this.companyId != 0) {
      this.getAllPlants(this.companyId);
    }
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getOtaData();
  }

  getAllStage() {
    let apiSubUrl = 'stage/get-all-stage';
    this.apiService.get(apiSubUrl).subscribe((res: any) => {
      this.allStages = res?.data;
    });
  }

  changeVersion(row, event) {
    this.hardware.version = event.appVersion;
    this.hardware.downloadlink = event.appUrl;
  }

  setTable() {
    this.table.data = {
      headings: this.generateHeadings(),
      columns: this.getTableColumns(),
    };
    return true;
  }

  generateHeadings() {
    let headings = {};
    for (var key in this.OTAManagementData[0]) {
      if (key.charAt(0) != '_' && key != 'hardware_id' && key != 'plant_id') {
        headings[key] = {
          title: key,
          placeholder: this.common.formatTitle(key),
        };
      }
    }
    headings['action'] = {
      title: 'action',
      placeholder: 'Actions',
      class: 'action-heading',
    };
    return headings;
  }

  getTableColumns() {
    let columns = [];
    this.OTAManagementData.map((ticket, index) => {
      let column = {};
      for (let key in this.generateHeadings()) {
        if (key.toLowerCase() == 'action') {
          column[key] = {
            value: '',
            isHTML: true,
            action: null,
            icons: this.actionIcons(ticket),
            class: 'action-column',
          };
        } else if (
          key == 'hardware_current_version' ||
          key == 'ota_current_version'
        ) {
          column[key] = {
            value: '',
            isAutoSuggestion: true,
            data:
              key == 'hardware_current_version'
                ? this.controllerVersionList
                : this.OTAcontrollerVersionList,
            display: 'appVersion',
            name: `${key}-suggestion-${index}`,
            inputId: `${key}-suggestion-${index}`,
            placeholder:
              key == 'hardware_current_version'
                ? 'Current Version'
                : 'OTA Version',
            isHTML: true,
            class: ticket[key] ? ticket[key]['class'] : '',
            action: '',
            onSelected: this.changeVersion.bind(this, ticket),
            bGConditions: [],
            preSelected: { appVersion: ticket[key] || '' },
          };
        } else {
          column[key] = {
            value: ticket[key],
            class: ticket[key] ? ticket[key]['class'] : '',
            action: '',
          };
        }
      }
      columns.push(column);
    });
    return columns;
  }

  actionIcons(entity) {
    let icons = [
      {
        class: 'btn cursor-pointer',
        txt: 'OTA Detail',
        title: 'View History',
        action: this.getOtaDetails.bind(this, entity),
      },
      {
        class: 'btn cursor-pointer',
        txt: 'Hardware Health',
        title: 'View History',
        action: this.getDetails.bind(this, entity),
      },
    ];

    if (entity.ota_status != 'UPDATE_INITIATED') {
      icons.push(
        {
          class: 'btn cursor-pointer',
          txt: 'Update Main',
          title: 'Update Main Hardware',
          action: this.updateHardware.bind(this, entity, 'CONTROLLER_OTA'),
        },
        {
          class: 'btn cursor-pointer',
          txt: 'Update OTA',
          title: 'Update OTA Hardware',
          action: this.updateHardware.bind(this, entity, 'CONTROLLER'),
        }
      );
    }

    return icons;
  }

  getOtaDetails(data) {
    const activeModal = this.modalService.open(HardwareOTALogsComponent, {
      size: 'lg',
    });
    activeModal.componentInstance.refData = {
      hardwareId: data.hardware_id,
      hardwareName: data.hardware_name,
      viewType: 'Table',
    };
  }

  async getDetails(data: any) {
    const activeModal = this.modalService.open(HardwareOTALogsComponent, {
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      connectionId: data.connection_id,
      viewType: 'Chart',
    };
  }

  selectStage(event) {
    this.stageName = event.stageTypeName;
  }

  selectVersion(event: any) {
    this.hardware.version = event.appVersion;
    this.hardware.downloadlink = event.appUrl;
  }

  updateHardware(row, versionType: string) {
    this.common.loading = true;
    let apiUrl = 'hardware/save_ota_log';
    let params = {
      versionType: versionType,
      version: this.hardware.version,
      downloadLink: this.hardware.downloadlink,
      hardwareIds: [row.hardware_id],
      connectionIds: [row.connection_id],
    };
    this.apiService.post(apiUrl, params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alert.success();
          this.getOtaData();
        } else {
          this.alert.error();
        }
      },
      (err) => {
        this.common.loading = false;
        this.alert.error();
        console.error(err);
      }
    );
  }

  updateAllHardware() {
    if (
      this.hardware.downloadlink == '' ||
      this.hardware.downloadlink == undefined
    ) {
      return this.alert.warn('Select OTA Version !!');
    }
    let apiUrl = 'hardware/save_ota_log';
    let allHardwareIds = this.OTAManagementData.map((item) => item.hardware_id);
    let allConnectionIds =
      this.preferenceType == 'AXESTRACK'
        ? []
        : this.OTAManagementData.map((item) => item.connection_id);
    let params = {
      versionType: this.hardwareVersionType,
      version: this.hardware.version,
      downloadLink: this.hardware.downloadlink,
      hardwareIds: allHardwareIds,
      connectionIds: allConnectionIds,
    };
    this.apiService.post(apiUrl, params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alert.success();
          this.getOtaData();
        } else {
          this.alert.error();
        }
      },
      (err) => {
        this.alert.error();
        console.error(err);
      }
    );
  }

  saveHardwareOTA() {
    this.common.loading = true;
    let api = 'hardware/save_ota_log';
    let params = {
      versionType: this.hardware.type,
      version: this.hardware.version,
      downloadLink: this.hardware.downloadlink,
      hardwareIds: [this.hardware.id],
      connnectionsIds: [this.hardware.connectionId],
    };

    this.apiService.post(api, params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alert.success('Data saved successfully');
          this.resetOta();
          this.getOtaData();
        } else {
          this.alert.error();
        }
      },
      (err) => {
        this.common.loading = false;
        console.error(err);
        this.alert.error();
      }
    );
  }

  resetOta() {
    this.hardware = {
      id: null,
      type: 'CONTROLLER',
      version: '',
      downloadlink: '',
    };
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_communication_setting_access')==true ||userService.permissionMap.get('edit_communication_setting_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input" class="required">{{"company" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="company" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyValidator && companyValidator.error">
                    <span>{{companyValidator.msg}} </span>
                  </div>
                </div>

                <div *ngIf="role.user_role == 'AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"plant" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{plantNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"title_setting" | translate}} </label>
                  <input type="text" class="form-control" placeholder="Enter Title Setting" [(ngModel)]="titleSettings">
                  <div class="error" *ngIf="titleSettingsValidator && titleSettingsValidator.error">
                    <span>{{titleSettingsValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"communication_type" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCommunicationType($event)" [data]="communication"
                    display="communicationTypeName" placeholder="Choose Communication Type"
                    [preSelected]="{communicationTypeName:communicationName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="communicationValidator && communicationValidator.error">
                    <span>{{communicationValidator.msg}} </span>
                  </div>
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"group" | translate}} </label>
                  <ps-auto-suggestion [isMultiSelect]="isMultiSelect" (onSelected)="selectedGroup($event)"
                    [data]="group" display="groupName" id="group" placeholder="Choose Group"
                    [preSelected]="preSelectedGroup">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="groupValidator && groupValidator.error">
                    <span>{{groupValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"user" | translate}} </label>
                  <ps-auto-suggestion [isMultiSelect]="isMultiSelect" (onSelected)="selectedUser($event)" [data]="user"
                    display="name" id="user" placeholder="Choose User" [preSelected]="preSelectedUser">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="userValidator && userValidator.error">
                    <span>{{userValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"custom" | translate}} </label>
                  <div *ngFor="let value of masterField; let i=index" class="mb-1">
                    <input type="text" class="form-control custom_input" id="custom" name="customName"
                      placeholder="Enter Custom Type" [(ngModel)]="value.customName" #name="ngModel" autocomplete="off">
                    <div class="plus_minus_icon">
                      <span class="remove_class" id="my-addon" *ngIf="i !== 0" (click)="masterField.splice(i,1)"> <i
                          class="fa fa-trash remove"></i></span>
                      <span class="add_class" id="my-addon" *ngIf="i == masterField.length-1" (click)="addValues()"><i
                          class="fa fa-plus add"></i></span>
                    </div>
                  </div>
                </div>



                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required"> {{"entity" | translate}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="checkbox" name="actionStatus"
                      [(ngModel)]="commonSetting.entity[0].transporter" id="stage_status_1" value="false">
                    <label>
                      <!-- {{"Send to Transporter" | translate}} -->
                      Send to Transporter
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="checkbox" name="actionStatus"
                      [(ngModel)]="commonSetting.entity[0].driver" value="false" id="stage_status_2">
                    <label>
                      Send to Driver
                    </label>
                  </div>
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required"> {{"status" | translate}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="actionStatus"
                      [(ngModel)]="commonSetting.isActive" id="stage_status_1" value="true">
                    <label for="exampleRadios1">
                      {{"active" | translate}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="actionStatus"
                      [(ngModel)]="commonSetting.isActive" value="false" id="stage_status_2">
                    <label for="exampleRadios2">
                      {{"inactive" | translate}}
                    </label>
                  </div>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>

                <div class="row form-group action-block">
                  <div class="btn-block">
                    <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | translate}}
                    </button>
                    <button class="btn btn-warning" (click)="resetDetail()"> {{"reset" | translate}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | translate}}
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"communication_setting table" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <!-- table -->
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{"s.no." | translate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK'">{{"company" | translate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK'">{{"plant" | translate}}</th>
                    <th>{{"title_setting" | translate}}</th>
                    <th>{{"communication_type" | translate}}</th>
                    <th>{{"group" | translate}}</th>
                    <th>{{"user" | translate}}</th>
                    <th>{{"custom" | translate}}</th>
                    <th>{{"status" | translate}}</th>
                    <th class="action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of communicationSettingsData;let i=index">
                    <td>{{i+1}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK'">{{row.company_name}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK'">{{row.plant_name}}</td>
                    <td>{{row.title}}</td>
                    <td>{{row.communication_type_name}}</td>
                    <td *ngIf="row.setting_info.length!=0">
                      <div *ngFor="let i of row.setting_info.group">
                        {{i.groupName + ','}}
                      </div>
                    </td>

                    <td *ngIf="row.setting_info.length!=0">
                      <div *ngFor="let i of row.setting_info.users">
                        {{i.userName + ','}}
                      </div>
                    </td>
                    <td *ngIf="row.setting_info.length!=0">
                      <div *ngFor="let i of row.setting_info.custom">
                        {{i.customName + ','}}
                      </div>
                    </td>
                    <td>{{(row.is_active==true?'Active':'Inactive')}}</td>
                    <td class="action">
                      <div class="btn-block">

                        <button class="btn btn-primary" (click)="viewDetails(row)"
                          *ngIf="userService.permissionMap.get('view_communication_setting_access')==true"> {{"view" |
                          translate}} </button>
                        <button class="btn btn-success" (click)="editRow(row)"
                          *ngIf="userService.permissionMap.get('edit_communication_setting_access')==true"> {{"edit" |
                          translate}} </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  Overriding Logs
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="page_design">
                <div class="tab_sec">
                  <div class="date_div">
                    <div class="row">
                      <div class="col-md-4 date">
                        <label>Start Date:</label>
                        <input type="date" class="form-control" name="startDate" [(ngModel)]="startTime"
                          (change)="getOverRidingLogs(0)">
                      </div>
                      <div class="col-md-4 date">
                        <label>End Date:</label>
                        <input type="date" class="form-control" name="startDate" [(ngModel)]="endTime"
                          (change)="getOverRidingLogs(0)">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="view_table" *ngIf="tabvalue == 'table'">
                  <div class="table_sec">
                    <table class="table table-bordered table-striped" datatable [dtOptions]="dtOptions"
                      [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>Sr.no</th>
                          <th>Action Name</th>
                          <th>Vehicle Name</th>
                          <th>Process Name</th>
                          <th>Stage Name</th>
                          <th>Pre Status</th>
                          <th>Overriding Status</th>
                          <th>User Name</th>
                          <th>Overriding Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let row of listOfLogs let i = index">
                          <td>{{ i+1 }}</td>
                          <td>{{row.action_name}}</td>
                          <td>{{row.vehicle_name}}</td>
                          <td>{{row.process_name}}</td>
                          <td>{{row.stage_name}}</td>
                          <td>{{row.pre_status}}</td>
                          <td>{{row.overriding_status}}</td>
                          <td>{{row.user_name}}</td>
                          <td>{{row.createdat | date:'dd-MM-yyyy'}}</td>
                          <td>
                            <div class="btn-block">
                              <button class="btn btn-primary" (click)="viewLog(row)">View</button>
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { event } from 'jquery';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageCompressService, DataUrl } from 'ngx-image-compress';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/@core/services/api.service';
import { Base64ToFileService } from 'src/app/@core/services/base64-to-file-service.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { LocationService } from 'src/app/@core/services/location.service';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { MapModalComponent } from 'src/app/modals/map-modal/map-modal.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { SignatureComponent } from '../signature/signature.component';
import { OpenCameraModalComponent } from 'src/app/modals/open-camera-modal/open-camera-modal.component';

@Component({
  selector: 'app-custom-field-new',
  templateUrl: './custom-field-new.component.html',
  styleUrls: ['./custom-field-new.component.scss']
})
export class CustomFieldNewComponent implements OnInit {

  @ViewChild(SignatureComponent, { static: false })
  signature: SignatureComponent;
  @Input() field: any;
  @Input() i: number;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() editTrigger: EventEmitter<any> = new EventEmitter<any>();



  image_name: string = '';
  image_url: string = '';
  image_ext: string = '';
  retakeImage: boolean = false;
  readOnly:boolean = true;
  fileEvent: any = [];
  signatureImage: string = null;
  inputType = {
    "TEXTFIELD" : "text",
    "NUMBERFIELD" : "number",
    "TIME" : "time",
    "URL" : "url",
    "DATETIMEPICKER" : "datetime-local",
    "DATEPICKER" : "date",
  }
  inputTypes = Object.keys(this.inputType)
  getSignature: any = null;
  fieldValueObj:any;
  mobileFields = [ 'RFIDCODE','RFIDCODEWITHIMAGE','BARCODEWITHIMAGE','BARCODE']
  allowedExtensions: { [key: string]: string } = {
    FILE: '.pdf,.doc,.docx,.xlsx,.xls,.zip',
    IMAGE: '.jpg,.jpeg,.png,.gif',
  };
  showCamera:boolean = false;
  validationFields = ['DATEPICKER','DATETIMEPICKER','TIME']


  constructor(
    public common: CommonService,
    private modalService: NgbModal,
    private imageCompress: NgxImageCompressService,
    private base64ToFileService: Base64ToFileService,
    private locationService: LocationService,
    public api: ApiService,
    public alertService : AlertService,
  ) {}

  ngOnInit() {
    if(this.mobileFields.includes(this.field.field_type) && !this.field.isEditable){
      this.alertService.error("You can only fill this form through mobile app");
    }
    this.fieldValueObj = {
      ... this.field?.extra_data,
      field_id: null,
      name: '',
      fieldType: '',
      _dbField: '',
      mandatory: false,
      values: [],
    }
    if (this.field.field_type === 'LABEL') {
        this.insertLabel(this.field,this.i);
    }
    if(this.field.values !=null && this.field.values.length>0){
      this.fieldValueObj.field_id = this.field.field_id;
      this.fieldValueObj.name = this.field.field_name;
      this.fieldValueObj.fieldType = this.field.field_type;
      this.fieldValueObj._dbField = this.field.database_field_name;
      this.fieldValueObj.mandatory = this.field.mandatory;
      this.fieldValueObj.values = this.field.values;
      this.valueChange.emit(this.fieldValueObj);
    }
  }

  // onValueChanged(event: any) {
  //   // Emit the value back to the parent component
  //   // this.valueChange.emit({ index: this.i, value: event.target.value });
  //   if(this.field.field_type=='SIGNATURE'){
  //     if (this.signature?.signatureImage) {
  //       this.signature.captureSignature();
  //       const signatureImage = this.signature.signatureImage;
  //       this.valueChange.emit(signatureImage);
  //     }
  //   }else{
  //   this.valueChange.emit(event);
  //   }
  // }
  getMinDate(fieldType: string): string | null {
    const currentDate = new Date();

    // Convert to local time for min date
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const date = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');

    switch (fieldType) {
      case 'DATETIMEPICKER':
        return `${year}-${month}-${date}T${hours}:${minutes}`; // "yyyy-MM-ddTHH:mm"
      case 'DATEPICKER':
        return `${year}-${month}-${date}`; // "yyyy-MM-dd"
      case 'TIME':
        return `${hours}:${minutes}`; // "HH:mm"
      default:
        return null;
    }
  }



  // Todo: Create custom pipe of it
  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  getPreSelected(label, i) {
    let valueObj = this.field.options.find((e) => e.value == this.fieldValueObj.values[0]);
    if(valueObj) {
      return {
        [label]: valueObj['label']
      }
    } else {
      return {
        [label]: null
      }
    }
  }


  getPreSelectedMulti( label,i) {
    return this.fieldValueObj.values.map(item => {
      return {[label]:item} });
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }

  location(lat, lng) {
    this.common.params = {
      lat: lat,
      lng: lng,
    };
    const activeModal = this.modalService.open(MapModalComponent, {
      size: 'lg',
    });
  }

  displayImage(url) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  onValuesEntered(event:any , inputType:any,field:any){
      console.log('combinedEvent: ', event,inputType);
      this.fieldValueObj.field_id = field.field_id;
      this.fieldValueObj.name = field.field_name;
      this.fieldValueObj.fieldType = field.field_type;
      this.fieldValueObj._dbField = field.database_field_name;
      this.fieldValueObj.mandatory = field.mandatory;
      if (inputType == 'file') {
        const formData = new FormData();
        for (const file of event.target.files) {
          formData.append('files', file);
        }
        this.fileEvent.inProgress = true;
        this.uploadFile(formData,field.fieldType);
      } else {
        this.fieldValueObj.values = [{
          ...this.fieldValueObj.values[0],
          value: event.target.value
        }];
      }
      this.valueChange.emit(this.fieldValueObj);
    }

  editTriggerEmit(field){
    this.editTrigger.emit(field);
  }

  insertLabel(field: any, i: number) {
    if (this.fieldValueObj.values.length == 0) {
      this.fieldValueObj.field_id = field.field_id;
      this.fieldValueObj.name = field.field_name;
      this.fieldValueObj.values.push(
        field.options[0].name
      );
      this.fieldValueObj.fieldType = field.field_type;
      this.fieldValueObj.mandatory = field.mandatory;
    }
    this.valueChange.emit(this.fieldValueObj)
  }

  validateField(value: string, fieldType: string): boolean {
    let pattern ;
    switch (fieldType) {
      case 'DATEPICKER':
        pattern = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
        return pattern.test(value);
      case 'DATETIMEPICKER':
        pattern = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])T([01]\d|2[0-3]):([0-5]\d)$/;
        return pattern.test(value);
      case 'TIME':
        pattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return pattern.test(value);
      default:
        console.error("Unsupported field type");
        return false;
    }
  }


  enterValues(event: any, field: any, i: number) {
    console.log("dfg",field)
    this.fieldValueObj.field_id = field.field_id;
    this.fieldValueObj.name = field.field_name;
    this.fieldValueObj.fieldType = field.field_type;
    this.fieldValueObj._dbField = field.database_field_name;
    this.fieldValueObj.mandatory = field.mandatory;
    let methodId = field.method_id;
    if (event!=null && this.checkValidation(event, field)) {
      if (
        field.field_type != 'CHECKBOX' &&
        field.field_type != 'SELECTLISTMULTIPLE' &&
        field.field_type != 'FILE' &&
        field.field_type != 'SELECTLISTSINGLE' &&
        field.field_type != 'LABEL' &&
        field.field_type != 'RFIDCODEWITHIMAGE' &&
        field.field_type != 'BARCODEWITHIMAGE'&&
        field.field_type != 'GEOCODEDIMAGE' &&
        field.field_type != 'CAMERASTREAMING'&&
        field.field_type != 'VIDEOSTREAMING' &&
        field.field_type != 'SIGNATURE'

      ) {
        //for validating values of input

        // if(this.validationFields.includes(field.field_type)){
        //   if(this.validateField(event.target.value , field.field_type)){
        //     this.fieldValueObj.values = [];
        //     this.fieldValueObj.values.push(event.target.value);
        //   }else{
        //     this.alertService.error('InValid Value Entered !!');
        //     this.fieldValueObj.values = [];
        //   }
        // }else{
        //   this.fieldValueObj.values = [];
        //   this.fieldValueObj.values.push(event.target.value);
        // }

        this.fieldValueObj.values = [];
        this.fieldValueObj.values.push(event.target.value);
      }

      if (field.field_type == 'SELECTLISTSINGLE') {
        this.fieldValueObj.values = [];
        this.fieldValueObj.values.push(event[field.store_value]);
      }

      if (field.field_type == 'CHECKBOX') {
        if (event.target.checked) {
          this.fieldValueObj.values.push(event.target.value);
        } else {
          let index = this.fieldValueObj.values.indexOf(
            event.target.value
          );
          this.fieldValueObj.values.splice(index, 1);
        }
      }

      if (field.field_type == 'SELECTLISTMULTIPLE') {
        this.fieldValueObj.values = [];
        event.forEach((opt) => {
          this.fieldValueObj.values.push(
            opt[field.store_value]
          );
        });
        this.fieldValueObj.values = [
          ...new Set(this.fieldValueObj.values),
        ];
      }

      if (field.field_type == 'FILE') {
        this.fieldValueObj.values = [];
        this.onSelect(event, i, false,field.field_type);
      }

      if(field.field_type == 'RFIDCODEWITHIMAGE' || field.field_type == 'BARCODEWITHIMAGE'|| field.field_type == 'GEOCODEDIMAGE'){
        this.fieldValueObj.values = [];
        this.onSelect(event, i, null,field.field_type);
      }

      if (field.field_type == 'CAMERASTREAMING' || field.field_type == 'VIDEOSTREAMING') {
        this.fieldValueObj.values = [];
        this.uploadFile(event,field.field_type)
      }

      if(field.field_type == 'SIGNATURE'){
        if (this.getSignature != null) {
          this.onSelect(this.getSignature, i, true, field.field_type);
        }
      }
    }
    else{
      this.alertService.error('InValid Value Entered !!');
    }
    console.log("this.fieldValueObj", this.fieldValueObj);
    this.valueChange.emit(this.fieldValueObj);
  }


  async onSelect(e, i, flag,fieldType:string) {
    // flag = true for fieldtype SIGNATURE , flag = null for fieldType imageType(GEOCODEDIMAGE,BARCODEWITHIMAGE,RFIDCODEWITHIMAGE) fields ,  flag = false for fieldType FILE
    let frmData = new FormData();
    let imgResultAfterCompression: DataUrl;
    if (flag == false) {
      for (const file of e.target.files) {
        frmData.append('files', file);
      }
    } else {
      let file;
      let imageName = flag == null ? 'image' + i + '.jpg' : 'signature.jpg'
      imgResultAfterCompression = await this.imageCompress.compressFile(flag == null ? e._imageAsDataUrl : e, -2, 50, 50); // 50% ratio, 50% quality
      file = this.base64ToFileService.base64toFile(imgResultAfterCompression,imageName);
      frmData.append('files', file);
    }
    this.fileEvent.inProgress = true;
    await this.uploadFile(frmData,fieldType);
  }

  // handleStreaming(formData: any, field: any, i: number) {
  //   this.formValues[i].values = [];
  //   this.formValues[i].name = field.field_name;
  //   this.formValues[i].field_id = field.master_field_id;
  //   this.uploadFile(formData, field.field_type, i);
  //   this.formValues[i].fieldType = field.field_type;
  //   this.formValues[i]._dbField = field.database_field_name;
  // }

  uploadFile(frmData: any,fieldType: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.post('functional/upload-file?methodId=' + 17, frmData).subscribe(
        (res: any) => {
          switch (res.data.type) {
            case HttpEventType.UploadProgress:
              this.fileEvent.progress = Math.round(
                (res.data.loaded * 100) / res.data.total
              );
              break;
            case HttpEventType.Response:
              return res.data;
          }
          res.data.forEach((e) => {
            if (fieldType == 'SIGNATURE' || fieldType == 'FILE' || fieldType == 'IMAGE') {
              this.fieldValueObj.values = [e.fileUrl];
            }
            else {
              if(this.fieldValueObj.values[0]){
                this.fieldValueObj.values = [{
                  ...this.fieldValueObj.values[0],
                  fileUrl: e.fileUrl
                }]
              }else{
                const position = this.locationService._position;
                let obj = {
                  lat: position.latitude,
                  lng: position.longitude,
                  time: new Date(),
                  fileUrl: e.fileUrl
                };
                this.fieldValueObj.values.push(obj);
              }
            }
          });
          resolve(true);
        },
        (err) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
      catchError((error: HttpErrorResponse) => {
        this.fileEvent.inProgress = false;
        return `${this.fileEvent.data.name} upload failed.`;
      });
    });
  }

  checkValidation(event: any, field: any) {
    // Array of field types that require validation
    const includedFields = [
      'TEXTFIELD',
      'TEXTAREA',
      'NUMBERFIELD',
      'DATEPICKER',
      'DATETIMEPICKER',
      'TIME',
    ];

    // Check if the field type requires validation
    if (includedFields.includes(field.field_type)) {
      if (['TEXTFIELD', 'TEXTAREA', 'NUMBERFIELD'].includes(field.field_type)) {
        // Check for prefix, postfix, and regex
        if (
          field.pattern_prefix !== null &&
          field.pattern_prefix !== '' &&
          !event.target.value.startsWith(field.pattern_prefix.trim())
        )
          return false;
        if (
          field.pattern_postfix !== null &&
          field.pattern_postfix !== '' &&
          !event.target.value.endsWith(field.pattern_postfix.trim())
        )
          return false;
        let temp = event.target.value.trim();
        if (field.pattern_prefix !== null && field.pattern_prefix !== '') {
          temp = temp.replace(field.pattern_prefix, '');
        }
        if (field.pattern_postfix !== null && field.pattern_postfix !== '') {
          temp = temp.replace(field.pattern_postfix, '');
        }
        let result = temp.split(field.regex)[0];
        if (
          field.regex !== null &&
          !new RegExp(field.regex).test(result.trim())
        )
          return false;
      } else {
        // Check for regex only for other field types
        if (
          field.regex !== null &&
          !new RegExp(field.regex).test(event.target.value.trim())
        )
          return false;
      }
    } else {
      // If field type doesn't require validation, return true
      return true;
    }

    // If all conditions pass, return true
    return true;
  }

  openCamera(field:any,i:number){
    this.showCamera = true;
    let activeModal = this.modalService.open(OpenCameraModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'l',
      backdrop:'static',
      keyboard:false
    });
    activeModal.componentInstance.refData = {
      retakeImage : this.retakeImage
    };
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.enterValues(data.response,field,i);
      }else{
        this.showCamera = false;
      }
    });
  }
}

<!-- <div class="container-fluid"> -->
  <!-- <div class="container-card"> -->
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <!-- <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{('trip_report'|customTranslate) + ' of ' + fromDate + ' to ' + toDate}}
                </h3>
              </div>
            </div> -->


            <div class="card-body">
              <div class="page_design">
                <div class="view_table" >
                  <div class="filter_sec">
                    <div class="row">
                      <div class="col-md-3">
                        <label>{{'select_process:'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="listOfProcess"
                          display="processName" placeholder="Choose Process" [preSelected]="{processName:processName}">
                        </ps-auto-suggestion>
                      </div>

                      <div class="col-md-3">
                        <label>{{'status'|customTranslate}}</label>
                        <select class="form-control" name="status" [(ngModel)]="tripStatus" (ngModelChange)="filterDataByCondition()">
                          <option selected value="ALL"> ALL </option>
                          <option selected value="INPROGRESS"> INPROGRESS </option>
                          <option value="COMPLETED">COMPLETED</option>
                          <option value="REJECTED">REJECTED</option>
                          <option value="CANCELLED">CANCELLED</option>
                          <option value="AUTO CANCELLED">AUTO CANCELLED</option>
                        </select>

                      </div>
                      <div class="col-md-3">
                        <label>{{'select_vehicle no:'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedVehicle($event)" [data]="tripReports"
                          display="vehicle_rc_no" placeholder="Choose Vehicle"
                          [preSelected]="{vehicle_rc_no:vehicle_rc_no}">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-lg-3 inner_table_action">
                        <div class="buttons">
                          <button type="button" [class.active]="tabvalue == 'table'"
                            (click)="tabvalue = 'table'; getTripReport();"><i class="fa fa-table"></i>
                            &nbsp;{{'table_view'|customTranslate}}</button>
                          <button type="button" [class.active]="tabvalue == 'graph'"
                            (click)="tabvalue = 'graph'; drawGraphs();"><i class="fa fa-chart-pie"></i>
                            &nbsp;{{'graph_view'|customTranslate}}</button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group action-block">
                      <div class="btn-block">
                        <button class="btn btn-warning" (click)="reset()" type="button" (click)="getTripReport()">
                          {{'reset'|customTranslate}} </button>
                      </div>
                    </div>
                  </div>

                  <div class="table_sec" [hidden]="(tabvalue != 'table')">
                    <button type="button px-3 m-1"
                        (click)="exportAsExcel(data,'Trip Report from '+this.fromDate+' to '+this.toDate)"
                        class="fa fa-file-excel"
                        style="background-color:transparent; color:rgb(57 117 22);cursor: pointer;border:.8px green solid;font-size:18px; float: right;"
                        *ngIf="tabvalue != 'graph'"></button>
                    </div>
                    <!-- Custom Table -->
                    <app-custom-table [data]="data" [columns]="tableColumns" [options]="tableOptions"  [tableType]="TABLE_TYPE"></app-custom-table>
                  </div>
                </div>

                <div class="view_graph" [hidden]="tabvalue != 'graph'">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div style="position: relative;" [id]="'secgraph-464'"></div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div style="position: relative;" [id]="'secgraph-465'"></div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div style="position: relative; margin-top:30px;" [id]="'secgraph-466'"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
  <!-- </div> -->

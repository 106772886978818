<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">{{'alert_log_details' | translate}}</h5>
</div>

<div class="modal-body">
  <h1>{{refData.remark}}</h1>

  <div class="col-md-12">
    <table #ref class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th>{{"s.no." | translate}} </th>
          <th>{{"field_name" | translate}} </th>
          <th>{{"field_value" | translate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of refData.data; let i = index;">
          <td>{{i + 1}}</td>
          <td>{{item.name}}</td>
          <td>{{item.value}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</div>

import { companyData } from './../../dataType/formInterface';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { databaseFields, masterFieldData } from 'src/app/dataType/formInterface';
import { validator } from 'src/app/dataType/commonInterface';

@Component({
  selector: 'app-add-form-field-modal',
  templateUrl: './add-form-field-modal.component.html',
  styleUrls: ['./add-form-field-modal.component.scss']
})
export class AddFormFieldModalComponent implements OnInit {


  @Input() public refData;
  public Editor = ClassicEditor;
  fieldTypes:{fieldType:string,fieldIcon:string}[]=[
    {
      fieldType:  'TEXTFIELD',
      fieldIcon: 'clock-o',
    },
    {
      fieldType:  'NUMBERFIELD',
      fieldIcon:  'hashtag',
    },{
      fieldType:  'TEXTAREA',
      fieldIcon:  'font',
    },{
      fieldType:  'DATEPICKER',
      fieldIcon: 'calendar days',
    },{
      fieldType:  'DATETIMEPICKER',
      fieldIcon: 'calendar days',
    },
    {
      fieldType:  'TIME',
      fieldIcon: 'clock-o',
    },
    {
      fieldType:  'SELECTLISTSINGLE',
      fieldIcon: 'check',
    },
    {
      fieldType:  'SELECTLISTMULTIPLE',
      fieldIcon:  'check-double',
    },
    {
      fieldType:  'CHECKBOX',
      fieldIcon: 'check-square',
    },
    {
      fieldType:  'FILE',
      fieldIcon:  'file',
    },
    {
      fieldType:  'RADIOBUTTON',
      fieldIcon:  'circle',
    },
    {
      fieldType:  'URL',
      fieldIcon: 'link',
    },
    {
      fieldType:  'GEOCODEDIMAGE',
      fieldIcon: 'image',
    },
    {
      fieldType:  'SIGNATURE',
      fieldIcon:  'signature',
    },
    {
      fieldType:  'FIELDGROUP',
      fieldIcon:  'th-list',
    },
    {
      fieldType:  'LABEL',
      fieldIcon:  'tags',
    },
    {
      fieldType:  'IMAGE',
      fieldIcon: 'image',
    },
    {
      fieldType:  'BARCODE',
      fieldIcon: 'barcode',
    },
    {
      fieldType:  'BARCODEWITHIMAGE',
      fieldIcon: 'image',
    },
    {
      fieldType:  'RFIDCODE',
      fieldIcon: 'signal',
    },
    {
      fieldType:  'RFIDCODEWITHIMAGE',
      fieldIcon: 'image',
    },
    {
      fieldType:  'CAMERASTREAMING',
      fieldIcon: 'camera',
    },
    {
      fieldType:  'VIDEOSTREAMING',
      fieldIcon: 'video',
    }
  ] ;
  fieldStatus:String='';
  customField = {
    id: null,
    name: null,
    status: 'ACTIVE',
    type: 'TEXTFIELD',
    values: [],
    hardwareResponse: null,
    fieldValue: null,
    databaseLinkedId: null,
    isMultiValue : false,
    isMandatory: false,
    isUnique : false,
    fieldRankingIndex:null,
    pattern_prefix: null,
    pattern_postfix: null,
    regex_pattern :null,
  };
  detail:string = '';
  btn:string = 'save';
  title:string = 'Add Field'
  fieldNameValidator : validator = null;
  databaseFieldValidator:validator = null;
  formFieldType:string = "FIXED";
  databaseFields:databaseFields[];
  selectedDatabaseField:string;
  showDbFields:boolean = true;
  subgroupsCount:number = 1
  databaseFieldName:string = null
  subgroupCountRange = Array.from({ length: this.subgroupsCount }, (_, index) => index + 1);
  subgroupsName: any = [{
    0: {
      name: null,
      values: { fieldName: null, fieldId: null, fieldType: null }
    }
  }]
  filteredMasterFields: masterFieldData[] = [];
  selectiveCategoryValidator = {
    error :false,
    msg : null
  };
  categoryListValidator:any[]=[];
  choiceValidator :any  = {
    error :false,
    msg : null
  };
  rankingIndexValidator:any[]=[];
  constructor(
    public activeModal:NgbActiveModal,
    public common : CommonService,
    public api : ApiService,
    public alertService : AlertService,
    public role : RoleService,
    public table: TableService
  ) { }


  ngOnInit(): void {
    this.getDBFields();
    this.getData();
  }

  selectedDBField(selectedDbField: any) {
    this.customField.databaseLinkedId = selectedDbField.databaseFieldName;
    console.log(selectedDbField)
  }
  getDBFields() {
    this.common.loading = true;
    this.api.get('fields/list-database-fields').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.databaseFields = res.data;
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getData(plantId?) {
    let body = {
      plantId:plantId,
      fieldEntity:'CUSTOM FORM',
      excludedFieldTypes:["FIELDGROUP"]
    }
    this.common.loading = true;
    this.api.post('fields/allMasterFields',body).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.filteredMasterFields = res.data;
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }
  spliceRow(i: number,id:number) {
    this.subgroupsName.splice(i, 1);
  }
  addSubGroupValues(id: number) {
    let obj = {};
    for (let i = 0; i < this.subgroupsCount; i++) {
      obj[i] = {
        name: this.subgroupsName[0][i].name != null ? this.subgroupsName[0][i].name : null,
        values: { fieldName: null, fieldId: null, fieldType: null }
      }
    }
    this.subgroupsName.push(obj);
  }
  changeNoOfSubGroups(event: any) {
    if (event.target.value <= 4 && event.target.value > 0) {
      this.subgroupsCount = event.target.value;
      const currentLength = this.subgroupCountRange.length;
      const newLength = this.subgroupsCount;
      if (currentLength < newLength) {
        // Add new items at the end
        this.subgroupCountRange.push(...Array.from({ length: newLength - currentLength }, (_, index) => currentLength + index + 1));
        // Add new items to subgroupsName
        let count = newLength - currentLength;
        let indexLegth = currentLength;
        this.subgroupsName.forEach(row => {
          for (let i = 0; i < count; i++) {
            row[indexLegth] = {
              name: null,
              values: { fieldName: null, fieldId: null, fieldType: null }
            };
            indexLegth++;
          }
          indexLegth = currentLength;
        })
      } else if (currentLength > newLength) {
        // Remove items from the end
        this.subgroupCountRange.splice(newLength);
        // Remove items from subgroupsName
        let count = currentLength - newLength;
        // Loop through each object in subgroupsName
        this.subgroupsName.forEach(obj => {
          // Get the keys (0, 1, 2, ...) and convert them to an array
          let keys = Object.keys(obj);

          // Determine the ending index for splicing
          let endIdx = Math.max(0, keys.length - count);

          // Remove count no. of objects from the end
          keys.splice(0, endIdx);

          // Remove properties from the object based on the modified keys array
          keys.forEach(key => delete obj[key]);
        });
      }
    } else {
      this.alertService.error("Sub Groups Count should be less than or equals to 4 and not equals to 0 !!");
      (document.getElementById("subgroupscount") as any).value = this.subgroupsCount.toString();
    }
  }
  checkSubGroupNameExists(subgroups, subgroupname) {
    const namesSet = new Set();
    for (const subgroup of subgroups) {
      for (const key in subgroup) {
        if (Object.prototype.hasOwnProperty.call(subgroup, key)) {
          const name = subgroup[key].name;
          namesSet.add(name);
        }
      }
    }
    if (namesSet.has(subgroupname)) {
      return true;
    }
    return false;
  }
  enterSubGroupName(id: any, event: any) {
    //checking if same subgroup name already exists or not
    if (!this.checkSubGroupNameExists(this.subgroupsName, event.target.value)) {
      //required because if any extra rows are added after entering subgroup name then for new row objects should also have their respective sub group names..
      // Loop through each object in subgroupsName
      for (let i = 0; i < this.subgroupsName.length; i++) {
        let obj = this.subgroupsName[i];
        // Loop through the keys of the current object
        for (let key in obj) {
          if (obj.hasOwnProperty(key) && key == id) {
            obj[key].name = event.target.value;
          }
        }
      }
    } else {
      this.alertService.error("Sub Group Name occurs more than once !!");
      (document.getElementById('sub' + id) as any).value = this.subgroupsName[0][id].name;
    }
  }
  hasDuplicateFields(subgroups, fieldId) {
    const hasFieldId = (list: any[], fieldId: number): boolean => {
      return list.some((obj) => Object.values(obj).some((innerObj: any) => innerObj.values.fieldId === fieldId));
    };
    return hasFieldId(subgroups, fieldId);
  }

  checkIfAllOthersNull(subgroups,key,i){
    let flag=true;
     subgroups.some((obj: any,ind:number) => {
      const { [key]: item } = obj;
      if(ind!=i && item && item.values.fieldType !=null){
        flag = false
      }
    });
    return flag;
  }
  selectedField(event: any, i: number, id: number) {
    if (!this.hasDuplicateFields(this.subgroupsName, event.master_field_id)) {
      //if duplicate field does not exists..
      //checking if the field selected matches with the other field type of other selected fields in the same subgroup or not
      const checkFieldTypeMatch = (list: any[], key: string, fieldType: string): boolean => {
        return list.some((obj: any,ind:number) => {
          const { [key]: item } = obj;
          //checking if field type matches
          if (item && item.values.fieldType == fieldType) {
            return true;
          }
          else if(item && item.values.fieldType!=null && ind==i && (this.subgroupsName.length==1 || this.checkIfAllOthersNull(this.subgroupsName,id.toString(),i))){
            //checking when editing the field that if any other field is selected of previous type or not or all other fields are null except the one editing then only field can be edited...
            return true;
          }
          return checkAllNull(this.subgroupsName, id.toString(), event.field_type)
        });
      };

      //if matches with none check if all the fields are null in the same subgroup or not
      const checkAllNull = (list: any[], key: string, fieldType: string): boolean => {
        let hasNonNull = false; // Flag to track if a non-null fieldType has been encountered

        for (const obj of list) {
          const { [key]: item } = obj;
          if (item && item.values.fieldType !== null) {
            hasNonNull = true; // Set the flag to true if a non-null fieldType is encountered
            break; // Exit the loop if a non-null fieldType is encountered
          }
        }

        return !hasNonNull; // Return true if no non-null fieldType was encountered, false otherwise
      };
      if (checkFieldTypeMatch(this.subgroupsName, id.toString(), event.field_type)) {
        //if matches
        this.subgroupsName[i][id].values.fieldName = event.field_name;
        this.subgroupsName[i][id].values.fieldId = event.master_field_id;
        this.subgroupsName[i][id].values.fieldType = event.field_type;
      } else {
        //if not matches
        this.revertSelectedField(i, id, event,"Field Type selected should be similar in same subgroup");
      }
    } else {
      //if duplicate field does exist..
      this.revertSelectedField(i, id, event,"Duplicate Fields are selected !!");
    }
  }
  private revertSelectedField(i: number, id: number, event: any,message:string) {
    let prev = this.subgroupsName[i][id].values.fieldName;
    this.subgroupsName[i][id].values.fieldName = event.field_name;
    this.alertService.error(message);
    setTimeout(() => {
      this.subgroupsName[i][id].values.fieldName = prev;
    }, 100);
  }


  closeModal(response?: any, apiHit?: any, sameStage?:boolean) {
    this.activeModal.close({ response: response, apiHit: apiHit,sameStage:sameStage });
  }

  selectFieldType(fieldType:any){
  console.log('fieldType: ', fieldType);
    this.customField.type = fieldType;
    if(fieldType=='FIELDGROUP'){
      this.getData();
    }
  }
  changeStatus(item:any) {
    item = !item
    console.log(this.customField);
    }

  addValues() {
    this.customField.values.push({ name: null });
  }

  saveMapping(){
  console.log('saveMapping ');
  console.log(this.subgroupsName)
    let masterFieldValues: any = [];
    if (
      (this.customField.type == 'SELECTLISTMULTIPLE' &&
        this.customField.values) ||
      (this.customField.type == 'SELECTLISTSINGLE' &&
        this.customField.values) ||
      (this.customField.type == 'RADIOBUTTON' && this.customField.values) ||
      (this.customField.type == 'CHECKBOX' && this.customField.values)
    ) {
      this.customField.values.map((mf) => {
        masterFieldValues.push(mf.name);
      });
    }
    if (this.customField.type == 'LABEL') {
      masterFieldValues.push(this.detail);
    }
    let masterField = {
      masterFieldId: this.customField.id,
      fieldName: this.customField.name.trim(),
      fieldStatus: this.customField.status,
      fieldType: this.customField.type,
      masterFieldValues: masterFieldValues,
      hardwareResponse: this.customField.hardwareResponse,
      databaseLinkedId: this.customField.databaseLinkedId,
      fieldValue: this.customField.databaseLinkedId!=null?'DATABASELINKED' :this.customField.fieldValue,
      subGroups: this.subgroupsName,
      fieldEntity: 'CUSTOM FORM',
      isMultiValue: this.customField.isMultiValue,
      patternPrefix: this.customField.pattern_prefix == '' ? null : this.customField.pattern_prefix,
      patternPostfix: this.customField.pattern_postfix== '' ? null : this.customField.pattern_postfix,
      regexPattern:  this.customField.regex_pattern == '' ? null : this.customField.regex_pattern,
    };

    let params= {
      isEdit:this.refData.field != null ? true : false,
      masterFieldDTO : masterField,
      // userRole : this.role.user_role,
      companyId:this.refData.companyId,
      plantId:this.refData.plantId,
      formId:this.refData.formId,
      fieldRankingIndex:this.refData.lastRankingIndex,
      type:this.formFieldType,
      isMandatory:this.customField.isMandatory,
      isUnique:this.customField.isUnique
    }
    this.api.post('form/save-form-fields-mapping',params).subscribe(
      (res: any) => {
        if(res.data[0].status){
          if(this.refData.formId!=null){
            this.alertService.success("Fields included in the selected custom form")
          }else{
            this.alertService.success("Custom field added")
          }
        }else{
          this.alertService.error(res.data[0].message);
        }
        this.common.loading = false;
        this.resetDetail();
        this.closeModal();
        console.log('success');
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }


  resetDetail() {
    this.customField = {
      id: null,
      name: null,
      status: 'ACTIVE',
      type: 'TEXTFIELD',
      values: [],
      hardwareResponse: null,
      fieldValue: null,
      databaseLinkedId: null,
      isMultiValue:false,
      isMandatory:false,
      isUnique:false,
      fieldRankingIndex:null,
      pattern_prefix: null,
      pattern_postfix: null,
      regex_pattern :null,
    };
    this.fieldNameValidator = null;
    this.customField.isMultiValue=false;
    this.databaseFieldName = null;
    this.formFieldType = 'FIXED';
    this.databaseFieldValidator = null
  }

  createdField(event:any){

    this.customField.values = event;
    console.log("this.customField.values",this.customField.values);

  }

  onMandatoryFalse(event:any){
    if(event.target.checked==false){
      // this.allMandatory=false;
    }
  }
  onUniqueFalse(event:any){
    console.log(event.target.checked==false,"event.target.checked==false")
    if(event.target.checked==false){
      // this.allUnique=false;
    }
  }

  formValidator(){
    this.fieldNameValidator=this.common.nullValidator(
      this.customField.name.trim(),
      "Field Name Mandatory"
    )
    if (this.customField.type == 'FIELDGROUP') {
      //checking if any of the object contains null value
      let hasNullValue = this.subgroupsName.some(obj =>
        Object.values(obj).some((subgroup: { name: string | null; values: { fieldName: string | null; fieldId: string | null } }) =>
          subgroup.name === null ||
          subgroup.values.fieldName === null ||
          subgroup.values.fieldId === null
        )
      );

      if (hasNullValue) {
        return this.alertService.warn("Please Fill All Empty Fields !!")
      }
    }
    if(this.customField.type=='SELECTLISTSINGLE'|| this.customField.type=='SELECTLISTMULTIPLE'|| this.customField.type=='RADIOBUTTON'|| this.customField.type=='CHECKBOX'){
      this.choiceValidator = this.common.nullValidator(this.customField.values , 'Enter atleast one choice');
    }
      if(!this.fieldNameValidator.error &&  !this.choiceValidator.error){
        this.saveMapping();
      }
  }
}

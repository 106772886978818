
import * as Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import { ChartOptions } from 'chart.js';
export const StaticDonutChartData={
    colorPalette:[
        'rgba(0, 34, 77, 0.8)',     // Dark Midnight Blue
        'rgba(67, 10, 93, 0.8)',    // Dark Purple
        'rgba(27, 66, 66, 0.8)',    // Dark Cyan
        'rgba(80, 60, 60, 0.8)',    // Dark Red
        'rgba(0, 51, 124, 0.8)',    // Oxford Blue
        'rgba(7, 10, 82, 0.8)',     // Dark Blue
        'rgba(149, 1, 1, 0.8)',     // Dark Candy Apple Red
        'rgba(40, 83, 84, 0.8)',    // Smoky Black
        'rgba(79, 59, 119, 0.8)',   // Purple Taupe
        'rgba(19, 94, 94, 0.8)',    // Teal Blue
        'rgba(95, 81, 109, 0.8)',   // Davy's Grey
        'rgba(28, 32, 80, 0.8)',    // Dark Blue-Gray
        'rgba(77, 0, 77, 0.8)',     // Dark Violet
        'rgba(46, 64, 128, 0.8)',   // Medium Slate Blue
        'rgba(0, 29, 64, 0.8)',     // Dark Blue
        'rgba(13, 78, 78, 0.8)',    // Dark Jungle Green
        'rgba(53, 94, 59, 0.8)',    // Dark Green
        'rgba(139, 69, 19, 0.8)',   // Saddle Brown
        'rgba(128, 0, 128, 0.8)',   // Dark Magenta
        'rgba(128, 0, 0, 0.8)',     // Dark Red
        'rgba(153, 153, 0, 0.8)',   // Dark Olive Green
        'rgba(255, 204, 0, 0.8)',   // Golden Yellow
        'rgba(255, 153, 51, 0.8)',  // Mango Tango
        'rgba(255, 102, 102, 0.8)', // Pastel Red
        'rgba(119, 65, 215, 0.8)',  // Dark Purple
        'rgba(51, 187, 185, 0.8)',  // Turquoise
        'rgba(171, 136, 0, 0.8)',   // Dark Goldenrod
        'rgba(195, 57, 121, 0.8)',  // Medium Red Violet
        'rgba(55, 135, 55, 0.8)'    // Dark Sea Green
        // Add more colors as needed
      ],

}

export const DonutChartOptions:ChartOptions={
    legend: {
      display: false,
    },
    cutoutPercentage: 60,
    animation: {
      animateRotate: true,
      animateScale: true,
      easing: 'easeOutSine',
      duration: 1000
    }
  }




// Initialize 3D support
Highcharts3D(Highcharts);

export const StaticDonutChartOptions: Highcharts.Options = {
  exporting: { enabled: false },
  credits: {
    style: {
      display: 'none',
    },
  },
  chart: {
    type: 'pie',
    options3d: {
      enabled: true,
      alpha: 40,
    },
    height: 250,
    margin: 0,  // Apply margin here
    plotBorderWidth: null,
    plotShadow: false,
  },
  title: {
    text: '',
    style: {
      display: 'none',
    },
  },
  subtitle: {
    text: '',
    style: {
      display: 'none',
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      innerSize: 90,
      depth: 55,
      dataLabels: {
        enabled: false,
      },
    },
  },
};
<div class="userdashboard">
  <div class="user_dash_row row" *ngIf="userService.permissionMap.get('view_trip_card_access')">
    <!-- All cards section -->
    <div class="col-md-9 mb-0 padding_right">
      <div class="card_full_row">
        <div class="card_full_box"(click)="getAllVehicleTransactionList('All');modalTitle='Total Trips'">
          <div class="left_part"  >
            <i class="fa fa-truck" style="color: #559330; border: 1px solid #559330;"></i>
          </div>

          <div class="right_part">
            <h4>{{dashboardTripCard?.total}} <span
                [ngClass]="{'fluctuate_plus':dashboardTripCard.total_trend > 0,'fluctuate_minus':dashboardTripCard.total_trend < 0}"
                *ngIf="dashboardTripCard.total_trend != 0">{{dashboardTripCard.total_trend}}%</span>
            </h4>
            <p>Total Trips <img
                [src]="dashboardTripCard.total_trend > 0 ? 'assets/images/up_graph.png' : 'assets/images/down_graph.png'"> </p>
          </div>
        </div>

        <div class="card_full_box" data-toggle="modal" data-target="#exampleModal" (click)="getAllVehicleTransactionList('INPROGRESS');modalTitle='In Progress'" >
          <div class="left_part" >
            <i class="fa fa-truck" style="color: #815b14; border: 1px solid #815b14;"></i>
          </div>

          <div class="right_part">
            <h4>{{dashboardTripCard.inprogress}}</h4>
            <p>Inprogress</p>
          </div>
        </div>

        <div class="card_full_box" data-toggle="modal" data-target="#exampleModal" (click)="getAllVehicleTransactionList('CANCELLED');modalTitle='Cancelled'">
          <div class="left_part" >
            <i class="fa fa-truck" style="color: #311db8; border: 1px solid #311db8;"></i>
          </div>

          <div class="right_part">
            <h4>{{dashboardTripCard.cancelled}}
              <!-- <span> {{dashboardTripCard.cancelled_trend}}%</span> -->
            </h4>
            <p>Cancel
              <!-- <img src="assets/images/up_graph.png"> -->
            </p>
          </div>
        </div>

        <div class="card_full_box" data-toggle="modal" data-target="#exampleModal" (click)="getAllVehicleTransactionList('COMPLETED');modalTitle='Completed'">
          <div class="left_part" >
            <i class="fa fa-truck" style="color: #fb3404; border: 1px solid #fb3404;"></i>
          </div>

          <div class="right_part">
            <h4>{{dashboardTripCard.completed}}
              <!-- <span>{{dashboardTripCard.completed_trend}}%</span> -->
            </h4>
            <p>Complete
              <!-- <img src="assets/images/down_graph.png"> -->
            </p>
          </div>
        </div>

        <div class="card_full_box" data-toggle="modal" data-target="#exampleModal" (click)="getAllVehicleTransactionList('MAINTAINENCE');modalTitle='Maintenance'">
          <div class="left_part">
            <i class="fa fa-truck" style="color: #334d37; border: 1px solid #334d37;"></i>
          </div>

          <div class="right_part">
            <h4>{{dashboardTripCard.maintainence}}
              <!-- <span>{{dashboardTripCard.maintenance_trend}}%</span> -->
            </h4>
            <p>Maintenance
              <!-- <img src="assets/images/up_graph.png"> -->
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-7 mb-0 pr-1">
          <div class="card_row">
            <div class="card_box" (click)="getOverTatVehicleTransactionList();modalTitle='Over TAT Trips'">
              <div class="left_part">
                <i class="fa fa-clock" style="color: #33bbb9; border: 1px solid #33bbb9;"></i>
              </div>

              <div class="right_part">
                <h4>{{dashboardTripCard.total_overtat_vehicle}}</h4>
                <p>Over TAT Vehicle</p>
              </div>
            </div>

            <div class="card_box" (click)="getAllVehicleTransactionList('All');modalTitle='Total Trips'">
              <div class="left_part">
                <i class="fa fa-clock" style="color: #c33979; border: 1px solid #c33979;"></i>
              </div>

              <div class="right_part" >
                <h4>{{dashboardTripCard.total_tat}}</h4>
                <p>Current TAT Time</p>
              </div>
            </div>

            <div class="card_box">
              <div class="left_part">
                <i class="fa fa-clock" style="color: #ab8800; border: 1px solid #ab8800;"></i>
              </div>

              <div class="right_part">
                <h4>{{dashboardTripCard.tat_trend_percentage}}%</h4>
                <p>TAT Trend</p>
                <!-- <img src="assets/images/up_graph.png"> -->
                <img src="assets/images/down_graph.png">
              </div>
            </div>
          </div>

          <div class="card_row">
            <div class="card_box" (click)="getEmployeeDetails('total')">
              <div class="left_part">
                <i class="fa fa-user" style="color: #334d37; border: 1px solid #334d37;"></i>
              </div>

              <div class="right_part">
                <h4>{{employeeCount.total_users}}</h4>
                <p>Total Employee</p>
              </div>
            </div>

            <div class="card_box" (click)="getEmployeeDetails('out')">
              <div class="left_part">
                <i class="fa fa-user" style="color: #559330; border: 1px solid #559330;"></i>
              </div>

              <div class="right_part">
                <h4>{{employeeCount.inusers}}</h4>
                <p>Inside Employee</p>
              </div>
            </div>

            <div class="card_box" (click)="getEmployeeDetails('out')">
              <div class="left_part">
                <i class="fa fa-user" style="color: #815b14; border: 1px solid #815b14;"></i>
              </div>

              <div class="right_part">
                <h4>{{employeeCount.total_users - employeeCount.inusers}}</h4>
                <p>Outside Employee</p>
              </div>
            </div>
          </div>
        </div>

        <!-- WORST 3 TRIPS section -->
        <div class="col-md-5 mb-0 padding_left_0">
          <div class="card" style="height: 156px;">
            <div class="card-header align-items-center">
              <div class="header-block">
                <h5 class="card-title">
                  {{"Worst 3 trips - (TAT wise)" | translate }}
                </h5>
              </div>
            </div>

            <div class="card-body">
              <div class="worst_trip_sec">
                <!-- [ngStyle]="{ 'background-color':getLightColor(cards.type_color, 100), 'border': '1px solid '+ cards.type_color}" -->
                <div class="worst_card" *ngFor="let worstStage of dashboardTripCard.worst_trips;index as i"
                  [ngClass]="'notification-'+(i+1)" (click)="tripTrackingModal(worstStage)">
                  <!-- [ngStyle]="{ 'background-color':cards.type_color }" -->
                  <i class="fa fa-truck"></i>

                  <div class="midd_sec">
                    <p [title]="worstStage?.vehicle_rc_no">{{worstStage?.vehicle_rc_no}}</p>
                    <small title="Jaipur, Rajasthan">{{worstStage.process_name}}</small>
                  </div>

                  <!-- [ngStyle]="{ 'color': 'darken('+cards.type_color+',10)' }" -->
                  <div class="tat_actual">
                    <i class="fa fa-clock"></i> {{worstStage?.trip_tat }}
                  </div>
                </div>

                <div *ngIf="worstThreeTripsTatWise.length==0 && !common.loading" style="margin-left: 30%;">
                  <app-data-not-found></app-data-not-found>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Trip activity component -->
    <div class="col-md-3 mb-0 padding_left">
      <div id="trip-activity" style="background-color:white"></div>
      <!-- <app-tripactivity></app-tripactivity> -->
    </div>
  </div>

  <div class="user_dash_row" *ngIf="userService.permissionMap.get('view_stage_card_access')">
    <!-- Stage vehicles component -->
    <app-stage-vehicles></app-stage-vehicles>
  </div>

  <div class="user_dash_row" *ngIf="userService.permissionMap.get('view_alert_card_access')">
    <!-- Alerts overview section -->
    <div class="card">
      <div class="card-header">
        <div class="header-block">
          <h2 class="card-title">{{"alerts_overview" | translate}}</h2>
        </div>
      </div>

      <div class="card-body">
        <div class="row m-0">
          <div class="col-md-3 m-0 pl-0 pr-0">
            <div id="alert-donut"> </div>
          </div>

          <div class="col-md-3 m-0 pl-1 pr-1">
            <div class="tab_sec" style="height:100%">
              <!-- [ngStyle]="{ 'background-color':getLightColor(cards.type_color, 100), 'border': '1px solid '+ cards.type_color}" -->
              <div class="tab_card" *ngFor="let cards of groupedDataByType; index as i"
                [class.active]="activeAlert == cards.type_name" (click)="selectedAlert(cards,i)">

                <div class="gif_icon" [ngStyle]="{ 'background-color':cards.type_color }">
                  <img src="assets/images/alert-gif.gif" />
                </div>

                <div class="alert-type">
                  <p>{{ cards.type_name }}</p>
                </div>

                <div class="alert-type-count" [ngStyle]="{ 'color': 'darken('+cards.type_color+',10)' }">
                  {{ cards.type_name_count }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 m-0 pl-0 pr-0">
            <div class="table_section" style="height:100%">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Alert Name</th>
                      <th>Alert On</th>
                      <th>Violation</th>
                      <th>Created at</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let calert of cAlertArray; let i = index">
                      <td>{{calert.alert_title}}</td>
                      <td class="btn red_clr">{{calert.alert_condition}}</td>
                      <td>{{calert.ranges}}</td>
                      <td>{{calert.created_at}}</td>
                      <td><i class="fa fa-info-circle" style="font-size:16px !important; border:none;color:#0f50a3 " (click)="viewAlert(calert.alert_data)">
                        <!-- <img class="icon img-icon" src="assets/images/trip-details(3).png" alt=""
                        (click)="viewAlert(calert.alert_data)"> -->
                      </i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="user_dash_row" *ngIf="userService.permissionMap.get('view_dock_card_access')">
    <!-- Dock overview section -->
    <div class="card">
      <div class="card-header">
        <div class="header-block">
          <h2 class="card-title">{{ "dock_overview" | translate }} </h2>
        </div>
      </div>

      <div class="card-body dock_overview_sec">
        <div class="row m-0">
          <div class="col-md-3 m-0 pl-0 pr-0">
            <div class="dock_over_left">
              <div class="dock_over_card" *ngFor="let dock of dockData"
                [class.active]="dockvalue == dock.destination_id" (click)="selectedDockData(dock,0)">
                <div class="dock_top">
                  <p>{{dock.stage_destination_code}}</p>
                  <div class="vehicle_num">
                    <i class="fa fa-truck"></i> {{dock.active_count}}
                  </div>
                </div>

                <div class="dock_bottom">
                  <div class="vehicle_tat">
                    <i class="fa fa-clock" style="color: #3dc03d;"></i> {{dock.avg_tat}}
                    <!-- <i class="fa fa-arrow-up"></i> -->
                    <span
                      [ngClass]="{'fluctuate_plus ml-1':dock.tat_percentage?.increase,'fluctuate_minus ml-1':dock.tat_percentage?.increase}"
                      *ngIf="dock.tat_percentage?.percentage != '+0%'">
                      {{dock.tat_percentage?.percentage}}</span>
                  </div>
                  <div class="loader bg-success">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-9 m-0 pl-1 pr-0">
            <div class="dock_over_right">
              <div class="dock_right_top_row">
                <div class="top_left">
                  <p>{{dashboardDockCard.stage_destination_code}}</p>
                  <small>Running</small>
                </div>

                <div class="top_right">
                  <div class="top_right_content">
                    <p>Process</p>
                    <div class="input_class mt-0">
                      <div class="form-group mt-1 ml-0">
                        <div style="display:flex">
                          <label style="font-size: 13px;margin-right: 3px;margin-bottom: 0px;">{{'select_process'|translate}} :</label>
                        <ps-auto-suggestion (onSelected)="getprocessindex($event)"
                          [data]="dashboardDockCard.process_data" display="process_name" placeholder="Select Process"
                          [preSelected]="{process_name:defaultprocess}">
                        </ps-auto-suggestion>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="top_right_content">
                    <p><i class="fa fa-clock" style="color: #3dc03d;"></i> TAT</p>
                    <h4>{{dashboardDockCard.dock_tat}} <span>/ {{dashboardDockCard.exp_avg_tat}}</span></h4>
                  </div>

                  <div class="top_right_content">
                    <p><i class="fa fa-truck" style="color: #3dc03d;"></i> Vehicle Align</p>
                    <h4>{{dashboardDockCard.total}} <span>/ {{dashboardDockCard.vehicle_per_slot}}</span></h4>
                  </div>
                  <div class="top_right_content">
                    <p><i class="fa fa-truck" style="color: #3dc03d;"></i>Total Vehicle</p>
                    <h4>{{dashboardDockCard.inprogress + dashboardDockCard.booked }} <span>/ {{dashboardDockCard.stage_destination_code_vehicle_limit}}</span></h4>
                  </div>
                </div>
              </div>

              <div class="dock_bottom_right_row">
                <div class="row m-0">
                  <div class="col-md-9 mb-0 pl-0 pr-0">
                    <div class="content_data">
                      <div class="content"><i class="fa fa-pie-chart"></i> Available Space: <span
                          class="jelly_btn">{{dashboardDockCard.vehicle_per_slot - dashboardDockCard.total}}</span>
                      </div>
                      <div class="content"><i class="fa fa-dropbox"></i> Runing Material:
                        <span>{{dashboardDockCard.product_name}}</span>
                      </div>
                      <div class="content"><i class="fa fa-user"></i> Appointed User: <span><ng-container
                            *ngFor="let user of dashboardDockCard.users">{{user?.user_name}} ,</ng-container></span>
                      </div>
                    </div>

                    <div class="queue">
                      <div class="queue_title">
                        <p>Normal Queue</p>
                      </div>
                      <div class="queue_content">
                        <div class="image_veh" *ngFor="let normalVehicle of dashboardDockCard.normal_queue">
                          <div class="inside_box green-jellybean">
                            {{normalVehicle.vehicle_rsn}}
                          </div>
                          <!-- <div class="vehicle_number" appEllipsisView>
                            {{normalVehicle.rc_no}}
                          </div> -->
                          <img src="assets/images/green_big_truck.png">
                        </div>
                        <p *ngIf="dashboardDockCard?.normal_queue?.length == 0">No Data Available !</p>
                      </div>
                    </div>

                    <div class="queue">
                      <div class="queue_title">
                        <p>Priority Queue</p>
                      </div>
                      <div class="queue_content">
                        <div class="image_veh" *ngFor="let normalVehicle of dashboardDockCard.priority_queue">
                          <div class="inside_box green-jellybean">
                            {{normalVehicle.vehicle_rsn}}
                          </div>
                          <img src="assets/images/green_big_truck.png">
                        </div>
                        <p *ngIf="dashboardDockCard?.priority_queue?.length == 0">No Data Available !</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 mb-0 pl-1 pr-0">
                    <div id="pie-chart-dock">
                      <!-- <img src="assets/images/pie_chartt.png"> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Dock overview section -->
  <!-- <div class="user_dash_row row">
    <div class="col-md-12 mb-0">
      <div class="card dashboard-card"
        *ngIf="userService.permissionMap.get('view_live_process_overview__access')==true">
        <div class="card-header">
          <div class="header-block">
            <h2 class="card-title">{{ "dock_overview" | translate }} </h2>
          </div>

          <div class="input_class">
            <div class="form-group">
              <ps-auto-suggestion (onSelected)="getprocessindex($event)" [data]="allProcess" display="processName"
                placeholder="Select Process" [preSelected]="{processName:defaultprocess}">
              </ps-auto-suggestion>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="manage_height">
            <div class="box" *ngFor="let dock of dockData let i = index">
              <h6>{{ dock?.stage_destination_code }}</h6>
              <div class="option_col">
                <div class="sepret_col">
                  <div class="count">
                    <div class="box_name">
                      <i class="fa fa-clock"></i> <span>TAT</span>
                    </div>
                    <p [ngClass]="dock.actual_tat > dock.expected_tat ? 'alert-blink' : ''">{{ dock?.actual_tat
                      }}<span>Actual</span></p>
                    <p>{{ dock?.expected_tat }}<span>Expected</span></p>
                  </div>
                </div>

                <div class="sepret_col">
                  <div class="count">
                    <div class="box_name">
                      <i class="fa fa-exclamation-circle"></i> <span>Status</span>
                    </div>
                    <p>{{ dock?.current_vehicle_count }}<span>Current</span></p>
                    <p>{{ dock?.completed_vehicle_count }}<span>Completed</span></p>
                  </div>
                </div>
              </div>

              <div class="option_col">
                <div class="sepret_col">
                  <div class="count">
                    <div class="box_name">
                      <i class="fa fa-truck"></i> <span>Vehicle</span>
                    </div>
                    <p>{{ dock?.vehicle_limit - dock.current_vehicle_count }}<span>Available</span></p>
                    <p>{{ dock?.vehicle_limit }}<span>Total</span></p>
                  </div>
                </div>

                <div class="sepret_col">
                  <div class="count">
                    <div class="box_name">
                      <i class="fa fa-list-ul"></i> <span>Queue</span>
                    </div>
                    <p>{{ dock?.normal_queue_count }}<span>Normal</span></p>
                    <p>{{ dock?.priority_queue_count }}<span>Priority</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="action_data_div" *ngIf="this.dockData.length == 0 && !common.loading">
            <p style="color: red; display: flex; justify-content: center">
              No Record Found !!
            </p>
          </div>

        </div>


      </div>
    </div>
  </div> -->

  <div class="user_dash_row" *ngIf="userService.permissionMap.get('view_parking_card_access')" style="overflow:visible">
    <!-- Parking status section -->
    <div class="card">
      <div class="card-header">
        <div class="header-block">
          <h2 class="card-title">{{("parking" | translate)+' '+("status"|translate)}}</h2>
        </div>

        <div class="input_class">
          <div class="form-group">
            <div style="display:flex">
              <label style="font-size: 13px;margin-right: 3px;margin-bottom: 0px;">{{'select_process'|translate}} :</label>
            <ps-auto-suggestion (onSelected)="selectedparkingSlotProcess($event)" [data]="allProcess"
              display="processName" placeholder="Select Process" [preSelected]="{processName:parkingDefaultProcess}">
            </ps-auto-suggestion>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="parking_status_row" *ngFor="let parking of dashboardParkingCard">
          <div class="subtitle_text">{{parking.stage_name}}</div>

          <div class="status_wise">
            <div class="status">
              <div class="small_text" style="color: #3dc03d;">
                Total Space
              </div>

              <div class="status_num_graph">
                {{parking.destination_size}} <i class="fa fa-bar-chart" style="color: #3dc03d;"></i>
              </div>
            </div>

            <div class="status">
              <div class="small_text" style="color: #4076bd;">
                Available Space
              </div>

              <div class="status_num_graph">
                {{parking.destination_size - parking.total}} <i class="fa fa-bar-chart" style="color: #4076bd;"></i>
              </div>
            </div>

            <div class="status"  data-toggle="modal" data-target="#exampleModal" (click)="getDashboardParkingCardData(processId,'All','All',0);modalTitle='Occupied Vehicles'">
              <div class="small_text" style="color: #c3c339;">
                Occupied Space
              </div>

              <div class="status_num_graph">
                {{parking.total}} <i class="fa fa-bar-chart" style="color: #c3c339;"></i>
              </div>
            </div>

            <div class="status"  data-toggle="modal" data-target="#exampleModal"  (click)="getDashboardParkingCardData(processId,'MAINTAINENCE','All',0);modalTitle='Maintenance Vehicles'">
              <div class="small_text" style="color: #cd4040;">
                Maintenance Vehicles
              </div>

              <div class="status_num_graph">
                {{parking.maintenance_count}} <i class="fa fa-bar-chart" style="color: #cd4040;"></i>
              </div>
            </div>

            <div class="status" data-toggle="modal" data-target="#exampleModal"  (click)="getDashboardParkingCardOverTatData(parking.process_stage_order_id);modalTitle='Over TAT Vehicles'">
              <div class="small_text" style="color: #33bbb9;">
                OverTat Vehicles
              </div>

              <div class="status_num_graph">
                {{parking.over_tat_count}} <i class="fa fa-bar-chart" style="color: #33bbb9;"></i>
              </div>
            </div>

            <div class="status">
              <div class="small_text" style="color: #7741d7;">
                Average TAT
              </div>

              <div class="status_num_graph">
                {{parking.avg_tat}} <i class="fa fa-bar-chart" style="color: #7741d7;"></i>
              </div>
            </div>

            <div class="status"  data-toggle="modal" data-target="#exampleModal"  (click)="getDashboardParkingCardData(processId,'All','vehicle fitness certificate',0);modalTitle='Fitness Expired Vehicles'">
              <div class="small_text" style="color: #c33979;">
                Fitness Expired
              </div>

              <div class="status_num_graph">
                {{parking.total - parking.with_fitness}} <i class="fa fa-bar-chart" style="color: #c33979;"></i>
              </div>
            </div>

            <div class="status"  data-toggle="modal" data-target="#exampleModal"  (click)="getDashboardParkingCardData(processId,'All','vehicle insurance',0);modalTitle='Insurance Expired Vehicles'">
              <div class="small_text" style="color: #3ab962;">
                Insurance Expired
              </div>

              <div class="status_num_graph">
                {{parking.total - parking.with_insurance}} <i class="fa fa-bar-chart" style="color: #3ab962;"></i>
              </div>
            </div>

            <div class="status"  data-toggle="modal" data-target="#exampleModal"  (click)="getDashboardParkingCardData(processId,'All','vehicle poc',0);modalTitle='PUC Expired Vehicles'">
              <div class="small_text" style="color: #ab8800;">
                PUC Expired
              </div>

              <div class="status_num_graph">
                {{parking.total - parking.with_puc}} <i class="fa fa-bar-chart" style="color: #ab8800;"></i>
              </div>
            </div>

            <div class="status"  data-toggle="modal" data-target="#exampleModal"  (click)="getDashboardParkingCardData(processId,'All','vehicle rc',0);modalTitle='RC Expired Vechicles'">
              <div class="small_text" style="color: #c33979;">
                RC Expired
              </div>

              <div class="status_num_graph">
                {{parking.total - parking.with_rc}} <i class="fa fa-bar-chart" style="color: #c33979;"></i>
              </div>
            </div>

            <div class="status"  data-toggle="modal" data-target="#exampleModal"  (click)="getDashboardParkingCardData(processId,'All','All',1);modalTitle='Vechicles With Order'">
              <div class="small_text" style="color: #33bbb9;">
                With Order
              </div>

              <div class="status_num_graph">
                {{parking.total - parking.without_order}} <i class="fa fa-bar-chart" style="color: #33bbb9;"></i>
              </div>
            </div>

            <div class="status"  data-toggle="modal" data-target="#exampleModal"  (click)="getDashboardParkingCardData(processId,'All','All',-1);modalTitle='Vehilces Without Order'">
              <div class="small_text" style="color: #815b14;">
                Without Order
              </div>

              <div class="status_num_graph">
                {{parking.without_order}} <i class="fa fa-bar-chart" style="color: #815b14;"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Parking status section -->
  <!-- <div class="user_dash_row row">
    <div class="col-md-12 mb-0">
      <div class="card" *ngIf="userService.permissionMap.get('view_destination_status_access')==true" style="height: 349px;">
        <div class="card-header align-items-center">
          <div class="header-block">
            <h5 class="card-title">
              {{("parking" | translate)+' '+("status"|translate)}}
            </h5>
          </div>

          <div class="input_class">
            <div class="form-group">
              <ps-auto-suggestion (onSelected)="selectedparkingSlotProcess($event)" [data]="allProcess"
                display="processName" placeholder="Select Process"
                [preSelected]="{processName:parkingDefaultProcess}">
              </ps-auto-suggestion>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="parking-container" [class]="'div-container-'+((i)%6)"
            *ngFor="let state of parkingStates;index as i">
            <div class="parking_heading">
              {{state?.stage_name}}
            </div>
            <div class="parking_block">
              <div class="option_col_park">
                <div class="sepret_col">
                  <div class="count">
                    <div class="box_name" style="background-color: #9bc6ff;">
                      <i class="fa fa-clock"></i> <span>{{'available'|translate}}</span>
                    </div>
                    <p>{{state?.free_count}}</p>
                  </div>
                </div>
                <div class="sepret_col">
                  <div class="count">
                    <div class="box_name" style="background-color: #9bc6ff;">
                      <i class="fa fa-clock"></i> <span>{{'occupied'|translate}}</span>
                    </div>
                    <p>{{state?.occupied_count}}</p>
                  </div>
                </div>
                <div class="sepret_col">
                  <div class="count">
                    <div class="box_name" style="background-color: #9bc6ff;">
                      <i class="fa fa-clock"></i> <span>{{'maintainence'|translate}}</span>
                    </div>
                    <p>{{state?.maintenance_count}}</p>
                  </div>
                </div>
              </div>
              <div class="option_col_park">
                <div class="sepret_col">
                  <div class="count" [ngClass]="state.actual_tat > state.exp_tat ? 'alert-blink' : ''">
                    <div class="box_name" style="background-color: #9bc6ff;">
                      <i class="fa fa-clock"></i> <span>{{'actual_tat'|translate}}</span>
                    </div>
                    <p>{{state?.actual_tat.substring(0,state.exp_tat.lastIndexOf(":"))}}</p>
                    <p class="tat-unit"> Hours</p>
                  </div>
                </div>
                <div class="sepret_col">
                  <div class="count">
                    <div class="box_name" style="background-color: #9bc6ff;">
                      <i class="fa fa-clock"></i> <span>{{'expected_tat'|translate}}</span>
                    </div>
                    <p>{{state?.exp_tat.substring(0,state.exp_tat.lastIndexOf(":"))}}</p>
                    <p class="tat-unit"> Hours</p>
                  </div>
                </div>
              </div>
            </div>
          <div>
        </div>

        <div *ngIf="parkingStates.length==0 && !common.loading" style="margin-left: 30%;">
          <app-data-not-found></app-data-not-found>
        </div>
      </div>
    </div>
  </div>
</div>
  </div> -->

  <div class="user_dash_row row" *ngIf="userService.permissionMap.get('view_process_graph_card_access')">
    <!-- Process wise tat section -->
    <div class="col-md-12 mb-0 padding_class">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"process_wise_tat" | translate}}
            </h3>
          </div>
        </div>

        <div class="card-body">
          <div class="vehicle-compliance" *ngIf="vehicleComplianceChartData.length">
            <canvas baseChart width="1000" height="300px" [datasets]="vehicleComplianceChartData"
              [labels]="vehicleComplianceChartLabels" [options]="barChartOptions"
              [plugins]="vehicleComplianceChartPlugins" [legend]="vehicleComplianceChartLegend"
              [chartType]="vehicleComplianceChartType">
            </canvas>
          </div>

        </div>

      </div>
    </div>
  </div>

  <div class="user_dash_row row">
    <!-- Destination status section -->
    <div class="col-md-6 mb-0 pr-1 padding_class">
      <div class="card destination_status" *ngIf="userService.permissionMap.get('view_hardware_status_access')"
        style="height: 350px;">
        <div class="card-header align-items-center">
          <div class="header-block">
            <h5 class="card-title">
              {{"hardware_status" | translate}}
            </h5>
            <div class="status-block">
              <div class="indication-block" *ngFor="let item of indication">
                <span class="dot" [ngClass]="'bg-'+item.className">
                </span>
                <p class="info"> {{item?.name|translate}} </p>
              </div>
            </div>
          </div>
          <div class="input_class">
            <div class="form-group">
              <!-- <label style="font-size: 12px;">{{'process'|translate}}</label> -->
              <div style="display:flex">
                <label style="font-size: 13px;margin-right: 3px;margin-bottom: 0px;">{{'select_process'|translate}} :</label>
              <ps-auto-suggestion (onSelected)="selectedprocess($event)" [data]="allProcess" display="processName"
                placeholder="Select Process" [preSelected]="{processName:defaultprocess}">
              </ps-auto-suggestion>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="loader-info" [ngClass]="{'min':loaderinfolen < 10,'max':loaderinfolen > 10}">
            <div class="loader-block" *ngFor="let hardware of hardwareInfo;index as i">
              <div class="loader"
                [ngClass]="{'bg-danger':hardware.status != 'ACTIVE' ,'bg-success':hardware.status == 'ACTIVE'}">
              </div>
              <div class="loader-title text-truncate" style="width: 80%">
                {{hardware?.hardware_name}}
              </div>
              <small class="text-truncate info-jellybean" style="width: 80%;">{{hardware?.hardware_type}}</small>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Average tat section -->
    <div class="col-md-6 mb-0 pl-0 padding_class" *ngIf="userService.permissionMap.get('view_average_tat_card_access')">
      <app-average-tat></app-average-tat>
    </div>
  </div>

  <div class="user_dash_row" *ngIf="userService.permissionMap.get('view_trip_tracking_access')==true">
    <!-- Trip tracking component -->
    <div class="card">
      <app-driver-tracker [breadcrumbs]="false" style="margin-top: 10px;"></app-driver-tracker>
    </div>
  </div>

  <!-- <div class="user_dash_row row">
    <diV class="col-md-4>
      <div class="card card-dashbaord" *ngIf="userService.permissionMap.get('view_plant_tat_access')==true"
        style="height: 252px;">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"plant_tat" | translate}}
            </h3>
          </div>
        </div>

        <div class="card-body"
          style="display: flex; flex-wrap: wrap;justify-content: space-evenly; margin:2px; border-radius: 5px;">
          <div class="row tat-group" *ngIf="tatcarddata.length">
            <div class="row-sub" *ngFor="let item of tatcarddata">
              <div class="info-circle" [ngClass]="'bg-'+item.color" [style.background-color]="item.color">
                <div class="info-block left">
                  <h3 class="info-number">
                    <span>{{item.tat == null ? 0:item.tat }}</span>
                  </h3>

                  <div class="info">
                    <p> {{item.name}} </p>
                  </div>
                </div>

                <div class="info-block right">
                  <div class="icon" [ngClass]="'bg-'+item.color">
                    <i class="fa fa-clock"></i>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <app-data-not-found *ngIf="!tatcarddata.length && !common.loading"></app-data-not-found>
        </div>

      </div>
    </diV>

    <diV class="col-md-4">
      <div class="card card-dashbaord" *ngIf="userService.permissionMap.get('view_plant_tat_access')==true"
        style="height: 252px;">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"Employee View" | translate}}
            </h3>
          </div>
        </div>

        <div class="card-body"
          style="display: flex; flex-wrap: wrap;justify-content: space-evenly; margin:2px; border-radius: 5px;">
          <div class="row tat-group">
            <div class="row-sub" (click)="getEmployeeDetails('in')">
              <div class="info-circle" style="background-color: #3dc03d;">
                <div class="info-block left">
                  <h3 class="info-number">
                    <span>{{employeeCount[0]?.inusers }}</span>
                  </h3>

                  <div class="info">
                    <p> {{'Inside Plant'}} </p>
                  </div>
                </div>

                <div class="info-block right">
                  <div class="icon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>

              </div>
            </div>
            <div class="row-sub" (click)="getEmployeeDetails('out')">
              <div class="info-circle" style="background-color: #c3c339;">
                <div class="info-block left">
                  <h3 class="info-number">
                    <span>{{employeeCount[0]?.total_users - employeeCount[0]?.inusers}}</span>
                  </h3>

                  <div class="info">
                    <p> {{'Outside Plant'}} </p>
                  </div>
                </div>

                <div class="info-block right">
                  <div class="icon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>

              </div>
            </div>
            <div class="row-sub" (click)="getEmployeeDetails('total')">
              <div class="info-circle" style="background-color: #4076bd;">
                <div class="info-block left">
                  <h3 class="info-number">
                    <span>{{employeeCount[0]?.total_users }}</span>
                  </h3>

                  <div class="info">
                    <p> {{'Total'}} </p>
                  </div>
                </div>

                <div class="info-block right">
                  <div class="icon">
                    <i class="fa fa-user"></i>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>

      </div>
    </diV>
  </div> -->

  <!-- Stage tat overview component -->
  <!-- <div class="col-md-6 mb-0 pl-1">
    <app-vendor-table></app-vendor-table>
  </div> -->

  <!-- worst three stages -->
  <!-- <div class="col-md-6 mb-0 pr-0">
    <div class="card" style="height:198px">
      <div class="card-header">
        <div class="header-block header">
          <h2 class="card-title">{{ "Worst 3 Stages -(TAT wise)" | translate }} </h2>
        </div>
        <div class="input_class">
          <div class="form-group">
            <label style="font-size: 12px;">{{'process'|translate}}</label>
            <ps-auto-suggestion (onSelected)="getworststageprocessSelected($event)" [data]="allProcess"
              display="processName" placeholder="Select Process" [preSelected]="{processName:worstStageProcessName}">
            </ps-auto-suggestion>
          </div>
          <div class="form-group">
            <label style="font-size: 12px;">{{'filter'|translate}}</label>
            <ps-auto-suggestion (onSelected)="getWorstStagefilter($event)" [data]="filters" display="filter"
              placeholder="Select Filter" [preSelected]="{filter:worstDayFilter}">
            </ps-auto-suggestion>
          </div>
        </div>
      </div>
      <div class="card-body"
        style="display: flex; flex-wrap: wrap;background-color: #ffffff; margin:4px; border-radius: 5px;">
        <div class="parking-container" *ngFor="let worstStage of worstThreeStagesTatWise;index as i"
          style="width:33%;margin-right:1px">
          <div class="parking_heading" style="height:22%;font-size: 14px;background-color: #b90404db;">
            {{worstStage?.stage_name}}
          </div>
          <div class="parking_block">
            <div class="option_col_park" style="flex-direction: row;width:100%;padding: 4px 4px;">
              <div class="sepret_col" style="width:49%;height: 100%;">
                <div class="count">
                  <div class="box_name" style="background-color: #9bc6ff;">
                    <i class="fa fa-clock"></i> <span
                      style="font-size:11px;font-weight:600;">{{'actual_tat'|translate}}</span>
                  </div>
                  <p style="font-size: 14px;">{{worstStage?.actual_tat.split(".")[0] }}</p>
                  <p class="tat-unit"> Hours</p>
                </div>
              </div>
              <div class="sepret_col" style="width:48%;height: 100%;">
                <div class="count">
                  <div class="box_name" style="background-color: #2fbc2f;color: white">
                    <i class="fa fa-clock" style="color: white"></i> <span
                      style="font-size:11px;font-weight:600;">{{'expected_tat'|translate}}</span>
                  </div>
                  <p style="font-size: 14px;">{{worstStage?.expected_tat}}</p>
                  <p class="tat-unit"> Hours</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="this.worstThreeStagesTatWise.length==0 && !common.loading" style="margin-left: 30%;">
          <app-data-not-found></app-data-not-found>
        </div>
      </div>


    </div>
  </div> -->

  <!--worst three trips-->
  <!-- <div class="col-md-6 mb-0 pl-1">
      <div class="card" style="height:198px">
        <div class="card-header">
          <div class="header-block header">
            <h2 class="card-title">{{"Worst 3 trips - (TAT wise)" | translate }} </h2>
          </div>

          <div class="input_class">
            <div class="form-group">
              <label style="font-size: 12px;">{{'process'|translate}}</label>
              <ps-auto-suggestion (onSelected)="getworsttripsprocessSelected($event)" [data]="allProcess"
                display="processName" placeholder="Select Process"
                [preSelected]="{processName:worstTripProcessName}">
              </ps-auto-suggestion>
            </div>
            <div class="form-group">
              <label style="font-size: 12px;">{{'filter'|translate}}</label>
              <ps-auto-suggestion (onSelected)="getWorstTripsfilter($event)" [data]="filters" display="filter"
                placeholder="Select Filter" [preSelected]="{filter:worstTripsDayFilter}">
              </ps-auto-suggestion>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="manage_height">
            <div class="box" *ngFor="let worstStage of worstThreeTripsTatWise;index as i" style="width: 32.3%;">
              <h6>{{worstStage?.vehicle_rc_no}}</h6>
              <div class="option_col">
                <div class="sepret_col" style="width: 98%;">
                  <div class="count">
                    <div class="box_name">
                      <i class="fa fa-clock"></i> <span>TAT</span>
                    </div>
                    <p>{{worstStage?.actual_tat.split(".")[0] }}<span>Actual</span></p>
                    <p>{{worstStage?.expected_tat}}<span>Expected</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="worstThreeTripsTatWise.length==0 && !common.loading" style="margin-left: 30%;">
              <app-data-not-found></app-data-not-found>
            </div>
          </div>
        </div>

      </div>
    </div> -->

  <!-- Status wise stage vehicle count section -->
  <!-- <div class="user_dash_row row">
    <div class="col-md-7 mb-0 pl-1">
      <div class="card" style="height: 265px;">
        <div class="card-header">
          <div class="header-block header">
            <h2 class="card-title">{{ "status_wise_stage_vehicle_count" | translate }}</h2>
          </div>
          <div class="input_class">
            <div class="form-group">
              <ps-auto-suggestion (onSelected)="getprocessSelected($event)" [data]="allProcess" display="processName"
                placeholder="Select Process" [preSelected]="{processName:defaultprocess}">
              </ps-auto-suggestion>
            </div>
            <div class="form-group">
              <ps-auto-suggestion (onSelected)="getfilter($event)" [data]="filters" display="filter"
                placeholder="Select Filter" [preSelected]="{filter:dayFilter}">
              </ps-auto-suggestion>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="manage_height">
            <div class="box" *ngFor="let stage of statusWiseStageVehicleCount let i = index" [class]="'box-'+((i)%6)">
              <h6>{{ stage?.stage_name }}</h6>
              <div class="option_col" style="background-color: white;">
                <div class="sepret_col" style="width: 99%;">
                  <div class="count">
                    <div class="box_name">
                      <i class="fa fa-truck"></i> <span>Vehicle</span>
                    </div>
                    <p>{{ stage?.in_progress_count }}<span>Inprogress</span></p>
                    <p>{{ stage?.cancelled_count}}<span>cancelled</span></p>
                    <p>{{ stage?.maintenance_count }}<span>Maintenance</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="action_data_div" *ngIf="statusWiseStageVehicleCount.length == 0 && !common.loading">
            <p style="color: red; display: flex; justify-content: center">
              No Record Found !!
            </p>
          </div>

        </div>
      </div>
    </div>
  </div> -->

</div>

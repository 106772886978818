<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Hardware Setting" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"select_hardware" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedHardware($event)" [data]="hardwareData"
                    display="hardwareName" placeholder="Choose Hardware" [preSelected]="{hardwareName:hardwareName}">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"hardware_type" | customTranslate}}</label>
                  <input type="text" class="form-control" name="hardwareType" disabled=true [value]="this.hardwareType"
                    autocomplete="off" (focusout)="getHardwareType()">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngFor="let item of hardwareFields;let i=index">
                  <label for="my-input">item.Field Label</label>
                </div>
              </div>


              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="reset()"> {{"reset" | customTranslate}} </button>
                </div>
              </div>

            </div>

          </div>
        </div>


      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title"> Add companies </h3>
        </div>
        <div class="col-md-12">
          <div class="add-companies">
            <div class="row">
              <div class="form-group col-xl-4 col-lg-6 col-sm-12">
                <label for="my-input" class="required"> New Password </label>
                <input class="form-control" type="text" name="newPassword" id="newPassword" required
                  placeholder="Enter New Password" [(ngModel)]="newPassword" #name="ngModel" autocomplete="off">
                <div class="error" *ngIf="newPasswordValidator && newPasswordValidator.error">
                  <span>{{newPasswordValidator.msg}} </span>
                </div>
              </div>

              <div class="form-group col-xl-4 col-lg-6 col-sm-12">
                <label for="my-input" class="required">Confirm Password</label>
                <input class="form-control" type="text" name="confirmPassword" id="confirmPassword" required
                  placeholder="Enter Confirm password" [(ngModel)]="confirmPassword" #name="ngModel" autocomplete="off">
                <div class="error" *ngIf="confirmPasswordValidator && confirmPasswordValidator.error">
                  <span>{{confirmPasswordValidator.msg}} </span>
                </div>
              </div>


              <div class="row form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-muted" (click)="backClicked()"> Back </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> Reset Password </button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>

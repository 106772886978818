<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input" class="required">{{"Department Scope" | customTranslate}}</label>
                  <select name="status_scope" id="#status_scope" class="form-control"
                    [(ngModel)]="departmentScope">
                    <option selected="GLOBAL" value="GLOBAL">GLOBAL</option>
                    <option value="COMPANY">COMPANY</option>
                    <option value="PLANT">PLANT</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'COMPANYADMIN'">
                  <label for="my-input" class="required">{{"Status Scope" | customTranslate}}</label>
                  <select name="status_scope" id="#status_scope" class="form-control"
                    [(ngModel)]="departmentScope">
                    <option selected="COMPANY" value="COMPANY">COMPANY</option>
                    <option value="PLANT">PLANT</option>
                  </select>
                </div>

                <div *ngIf="role.user_role == 'AXESTRACK' && departmentScope != 'GLOBAL'"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"company" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyList"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyValidator && companyValidator.error">
                    <span>{{companyValidator.msg}} </span>
                  </div>
                </div>

                <div *ngIf="departmentScope == 'PLANT'"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantList"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantValidator && plantValidator.error">
                    <span>{{plantValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"Department Name" | customTranslate}}</label>
                  <input type="text" class="form-control" name="deptName" required placeholder="Enter Department Name"
                    [(ngModel)]="department.deptName" #firstName="ngModel" autocomplete="off">
                    <div class="error" *ngIf="deptNameValidator && deptNameValidator.error">
                      <span>{{deptNameValidator.msg}} </span>
                    </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"Department Description" | customTranslate}}</label>
                  <input type="text" class="form-control" name="deptDescription" placeholder="Enter Department Description"
                    [(ngModel)]="department.deptDescription" #name="ngModel" autocomplete="off">
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input"> {{"Head of Department" | customTranslate}} </label>
                  <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedUsers($event)"
                    [data]="userList" display="name" placeholder="Choose User"
                    [preSelected]="selectedUser">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"status" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedStatus($event)" class="required" [data]="deptStatusList"
                    display="status_name" placeholder="Choose Status" [preSelected]="{status_name:statusName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="deptStatusValidator && deptStatusValidator.error">
                    <span>{{deptStatusValidator.msg}} </span>
                  </div>
                </div>
               </div>
              </div>
              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    customTranslate}}</button>
                  <button class="btn btn-warning" (click)="resetDetail()">{{"reset" | customTranslate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn}} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Department List" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <!-- table -->
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">

                <thead>
                  <tr>
                    <th>{{"s.no." | customTranslate}}</th>
                    <th>{{"Department Name" | customTranslate}}</th>
                    <th>{{"Department Description" | customTranslate}}</th>
                    <th>{{"Head of Department" | customTranslate}}</th>
                    <th>{{"Department Status" | customTranslate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">{{"plant" | customTranslate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK'">{{"company" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of departmentList; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{row.dept_name}}</td>
                    <td>{{row.dept_description}}</td>
                    <td>{{formatNames(row.heads)}}</td>
                    <td>{{row.status_name}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">{{row.plant_name}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK'">{{row.company_name}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewDetails(row)">
                          {{ "view" | customTranslate }}
                        </button>
                        <button class="btn btn-success" (click)="editRow(row)"> {{"edit" | customTranslate}}
                        </button>
                          <button class="btn btn-danger" (click)="confirmAlert(row)"
                            *ngIf="row.userStatus!='DELETED'">{{"delete"| customTranslate}}</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

import {
  HttpHeaders,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/@core/services/api.service';
import { Base64ToFileService } from 'src/app/@core/services/base64-to-file-service.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { LocationService } from 'src/app/@core/services/location.service';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { MapModalComponent } from 'src/app/modals/map-modal/map-modal.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { SignatureComponent } from '../signature/signature.component';
import _ from 'lodash';

@Component({
  selector: 'app-manual-stamping-form',
  templateUrl: './manual-stamping-form.component.html',
  styleUrls: ['./manual-stamping-form.component.scss'],
})
export class ManualStampingFormComponent implements OnInit {
  @ViewChild(SignatureComponent, { static: false })
  signature: SignatureComponent;

  @Input() public refData;
  currentActionData: any = [];
  response: any[] = [];
  image_name: any = '';
  image_url: any = '';
  image_ext: any = '';
  fileEvent: any = [];
  readOnlyArray: boolean[] = [];
  fieldValues: any[] = [];
  actionFormFieldsData: any[] = [];
  longitude: number;
  latitude: number;
  statusClass: {
    "PASS": "badge-success",
    "OVERRIDED": ""
  }

  constructor(
    public common: CommonService,
    private api: ApiService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private alert: AlertService,
    private locationService: LocationService,
    private base64ToFileService: Base64ToFileService,
    private imageCompress: NgxImageCompressService
  ) {}

  ngOnInit() {
    console.log('refData', this.refData);
    this.getActionData();
  }

  getActionData() {
    this.common.loading = true;
    let params = {
      txnId: this.refData.txnId,
      actionId: this.refData.actionId,
      stageId: this.refData.stageId,
      actionType: this.refData.actionType,
      txnStageDetailId: this.refData.txnStageDetailId,
    };
    this.api.get('dashboard/get-action-details', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.response = res.data;
        this.currentActionData = res.data[0].currentActionData;
        console.log('this.response: ', this.response);
        if (this.currentActionData[0].response_value.length) {
          this.currentActionData[0].response_value.forEach((element) => {
            element.field_type = element.fieldType;
            element.field_name = element.name;
            element.label = 'name';
            delete element.fieldType;
            delete element.name;
          });
          this.readOnlyArray = Array(
            this.currentActionData[0].response_value.length
          ).fill(true);
        }
        this.addJsonToFormValues();

        // this.hideShow1 = [];
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  insertLabel(field: any, index: any) {
    if (this.fieldValues[index].values.length == 0) {
      this.fieldValues[index].name = field.field_name;
      this.fieldValues[index].field_id = field.field_id;
      this.fieldValues[index].values.push(field.options[0].name);
      this.fieldValues[index].fieldType = field.field_type;
    }
  }

  handleImage(combinedEvent: any, field: any, i: number) {
    console.log('combinedEvent: ', combinedEvent);
    if (combinedEvent.inputType == 'file') {
      const formData = new FormData();
      for (const file of combinedEvent.event.target.files) {
        formData.append('files', file);
      }
      this.fileEvent.inProgress = true;
      this.uploadFile(formData,field.fieldType, i);
    } else {
      console.log("wdejfnbhg")
      this.fieldValues[i].values = [{
        ...this.fieldValues[i].values[0],
        value: combinedEvent.event.target.value
      }];
    }
    console.log('this.fieldValues[i]: ', this.fieldValues[i]);
  }

  enterValues(event: any, field: any, i: number) {
    console.log('field mra: ', field);
    console.log('this.fieldValues enter: ', this.fieldValues);
    console.log('event enter: ', event);
    if (
      field.field_type != 'CHECKBOX' &&
      field.field_type != 'SELECTLISTMULTIPLE' &&
      field.field_type != 'FILE' &&
      field.field_type != 'SELECTLISTSINGLE'
    ) {
      this.fieldValues[i].values = [];
      this.fieldValues[i].name = field.field_name;
      this.fieldValues[i].field_id = field.field_id;
      this.fieldValues[i].values.push(event.target.value);
      this.fieldValues[i].fieldType = field.field_type;
      this.fieldValues[i]._dbField = field.database_field_name;
    }
    if (field.field_type == 'SELECTLISTSINGLE') {
      this.fieldValues[i].values = [];
      console.log('event[field.store_value]: ', event[field.store_value]);
      this.fieldValues[i].values.push(event['name']);
      this.fieldValues[i].field_id = field.field_id;
      this.fieldValues[i].name = field.field_name;
      this.fieldValues[i].fieldType = field.field_type;
      this.fieldValues[i]._dbField = field.database_field_name;
      console.log('this.fieldValues[i]: ', this.fieldValues[i]);
    }

    if (field.field_type == 'CHECKBOX') {
      if (event.target.checked) {
        this.fieldValues[i].name = field.field_name;
        this.fieldValues[i].field_id = field.field_id;
        this.fieldValues[i].values.push(event.target.value);
        this.fieldValues[i].fieldType = field.field_type;
        this.fieldValues[i]._dbField = field.database_field_name;
      } else {
        let index = this.fieldValues[i].values.indexOf(event.target.value);
        this.fieldValues[i].values.splice(index, 1);
      }
    }
    if (field.field_type == 'SELECTLISTMULTIPLE') {
      this.fieldValues[i].values = [];
      event.forEach((opt) => {
        this.fieldValues[i].values.push(opt['name']);
      });
      this.fieldValues[i].name = field.field_name;
      this.fieldValues[i].field_id = field.field_id;
      this.fieldValues[i].fieldType = field.field_type;
      this.fieldValues[i].values = [...new Set(this.fieldValues[i].values)];
      this.fieldValues[i]._dbField = field.database_field_name;
    }

    if (field.field_type == 'FILE' || field.field_type == 'IMAGE') {
      // let methodId = field.method_id;
      this.onSelect(event, i, field.field_type);
      this.fieldValues[i].values = [];
      this.fieldValues[i].name = field.field_name;
      this.fieldValues[i].field_id = field.field_id;
      this.fieldValues[i].fieldType = field.field_type;
      this.fieldValues[i]._dbField = field.database_field_name;
    }
  }

  getActionFormFieldsData(field: any,flag:any) {
    if(flag) return;
    console.log('field for dashboard formFields: ', field);
    this.common.loading = true;
    let params = {
      actionId: this.refData.actionId,
      tripId: this.refData.txnId,
      actionType: this.refData.actionType,
      processId: this.refData.processId,
      stageId: this.refData.stageId,
      txnStageDetailId: this.refData.transactionStageDetailId,
      fieldId: field.field_id,
    };
    this.api
      .get('dashboard/get-dashboard-actions-form-fields', params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.actionFormFieldsData = res.data;
          field.options = JSON.parse(this.actionFormFieldsData[0]?.options);
        },
        (err: any) => {
          this.common.loading = false;
        }
      );
  }

  callMethodOnManualStamping(tripId:number,plantId:number) {
    let params = {
      tripId:tripId,
      plantId:plantId
    }
    this.api
      .get('transaction/manual-stamping-call-method', params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
        },
        (err: any) => {
          this.common.loading = false;
        }
      );
  }

  onEdit(index,flag:any,field:any) {
  console.log('field: ', field);
    this.readOnlyArray[index] = flag;
    if(field!=null && (field.field_type == 'RADIOBUTTON' || field.field_type == 'CHECKBOX' || field.field_type == 'SELECTLISTSINGLE' || field.field_type == 'SELECTLISTMULTIPLE' )  ){
      this.getActionFormFieldsData(field,flag);
    }
  }

  saveChanges() {
    console.log('fieldValues', this.fieldValues);
    this.latitude = this.locationService._position.latitude;
    this.longitude = this.locationService._position.longitude;

    this.locationService.position.subscribe((position: any) => {
      this.latitude = position.latitude;
      this.longitude = position.longitude;
    });
    let i = this.fieldValues.findIndex(
      (e) => e.name == '' && e.field_type != 'LABEL' && e.mandatory == 1
    );
    if (i != -1) {
      this.alert.error('Mandatory fields need to be filled...');
    } else {
      this.fieldValues = this.fieldValues.filter(
        (item) => item.mandatory !== 0 || item.name !== ''
      );
      this.fieldValues = this.fieldValues.map((obj) =>
        _.omit(obj, ['mandatory', 'preSelected'])
      );
      let params = {
        formData: JSON.stringify(this.fieldValues),
        status: this.refData.responseStatus,
        transactionDetailsId: this.refData.txnDetailId,
        stageDetailId: this.refData.txnStageDetailId,
        stageActionId: this.refData.actionId,
        transactionId: this.refData.txnId,
        actionType: this.refData.actionType,
        transactionType: 'trip',
        stageId: this.refData.stageId,
        flowType: 1,
        lat: this.latitude,
        lng: this.longitude,
        vehicleId: this.refData.vehicleId,
        callingForm: 'manualStamping',
      };
      console.log('params: ', params);
      this.common.loading = true;
      this.api.post('transaction/save-transaction-detail', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.alert.success(res.message);
            this.closeModal();
            this.callMethodOnManualStamping(this.refData.txnId,this.refData.plantId)
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    }
  }



  addJsonToFormValues() {
    this.currentActionData[0].response_value.forEach((field) => {
      console.log('abc: ', field);
      this.fieldValues.push({
        field_id: field.field_id,
        name: field.field_name,
        values: field.values,
        fieldType: field.field_type,
        _dbField: field._dbField,
        group_id: field.group_id,
        sub_group_index: field.sub_group_index,
        sub_group_name: field.sub_group_name,
      });
    });
    console.log('fieldValues', this.fieldValues);
    this.saveDataBaseLinked();
  }

  saveDataBaseLinked() {
    this.currentActionData[0].response_value.forEach((element) => {
      if (element.sel_value != null) {
        let i = this.currentActionData[0].response_value.indexOf(element);
        this.fieldValues[i].name = element.field_name;
        this.fieldValues[i].field_id = element._;
        this.fieldValues[i].values.push(element.sel_value);
        this.fieldValues[i].fieldType = element.field_type;
        this.fieldValues[i]._dbField = element.database_field_name;
      }
      element['multiValueOptions'] = [];
      if (element.field_type == 'SELECTLISTMULTIPLE') {
        element.options.forEach((ele) => {
          let obj = {
            option: ele.master_field_value,
          };
          element.multiValueOptions.push(obj);
        });
      }
    });
  }

  displayImage(url) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  location(lat, lng) {
    this.common.params = {
      lat: lat,
      lng: lng,
    };
    const activeModal = this.modalService.open(MapModalComponent, {
      size: 'lg',
    });
  }

  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }

  // enterValues(event: any, field: any, i: any, parentField: any, mainIndex: number) {
  //   if (this.checkValidation(event, field)) {
  //     if (parentField == null || this.checkUnique(event, field, i, parentField)) {
  //       if (
  //         field.field_type != 'CHECKBOX' &&
  //         field.field_type != 'SELECTLISTMULTIPLE' &&
  //         field.field_type != 'FILE' &&
  //         field.field_type != 'SELECTLISTSINGLE'
  //       ) {
  //         this.formValues[i].values = [];
  //         this.formValues[i].name = field.field_name;
  //         this.formValues[i].field_id = field.master_field_id;
  //         this.formValues[i].values.push(event.target.value);
  //         this.formValues[i].fieldType = field.field_type;
  //         this.formValues[i]._dbField = field.database_field_name;
  //       }
  //       if (field.field_type == 'SELECTLISTSINGLE') {
  //         this.formValues[i].values = [];
  //         this.formValues[i].values.push(event[field.store_value]);
  //         this.formValues[i].field_id = field.master_field_id;
  //         this.formValues[i].name = field.field_name;
  //         this.formValues[i].fieldType = field.field_type;
  //         this.formValues[i]._dbField = field.database_field_name;
  //       }

  //       if (field.field_type == 'CHECKBOX') {
  //         if (event.target.checked) {
  //           this.formValues[i].name = field.field_name;
  //           this.formValues[i].field_id = field.master_field_id;
  //           this.formValues[i].values.push(event.target.value);
  //           this.formValues[i].fieldType = field.field_type;
  //           this.formValues[i]._dbField = field.database_field_name;
  //         } else {
  //           let index = this.formValues[i].values.indexOf(event.target.value);
  //           this.formValues[i].values.splice(index, 1);
  //         }
  //       }
  //       if (field.field_type == 'SELECTLISTMULTIPLE') {
  //         this.formValues[i].values = [];
  //         event.forEach((opt) => {
  //           this.formValues[i].values.push(opt[field.store_value]);
  //         });
  //         this.formValues[i].name = field.field_name;
  //         this.formValues[i].field_id = field.master_field_id;
  //         this.formValues[i].fieldType = field.field_type;
  //         this.formValues[i].values = [...new Set(this.formValues[i].values)];
  //         this.formValues[i]._dbField = field.database_field_name;
  //       }

  //       if (field.field_type == 'FILE') {
  //         let methodId = field.method_id;
  //         this.onSelect(event, methodId, i, false, false, field.field_type);
  //         this.formValues[i].values = [];
  //         this.formValues[i].name = field.field_name;
  //         this.formValues[i].field_id = field.master_field_id;
  //         this.formValues[i].fieldType = field.field_type;
  //         this.formValues[i]._dbField = field.database_field_name;
  //       }
  //     } else {

  //       if (field.field_type != 'SELECTLISTSINGLE' && field.field_type != 'SELECTLISTMULTIPLE') {
  //         this.alert.error("Value isn't Unique !!");
  //         (document.getElementById('field' + i + mainIndex) as any).value = this.formValues[i].name;
  //       } else {
  //         let prev = [];
  //         if (field.field_type == 'SELECTLISTSINGLE' || event.length >= this.formValues[i].values.length) {
  //           this.alert.error("Value isn't Unique !!");
  //           prev = this.formValues[i].values.slice();
  //           field.field_type == 'SELECTLISTSINGLE' ? this.formValues[i].values.push(event.name) : this.formValues[i].values.push(event[event.length - 1].name);
  //         } else {
  //           event.forEach(element => {
  //             prev.push(element.name);
  //           });
  //         }
  //         setTimeout(() => {
  //           this.formValues[i].values = prev;
  //           this.getPreSelectedValue(null, i, prev, field.field_type);
  //         }, 100);
  //       }
  //     }
  //   } else {
  //     this.alert.error("InValid Value Entered !!")
  //   }
  // }

  checkValidation(event: any, field: any) {
    // Array of field types that require validation
    const includedFields = [
      'TEXTFIELD',
      'TEXTAREA',
      'NUMBERFIELD',
      'DATEPICKER',
      'DATETIMEPICKER',
      'TIME',
    ];

    // Check if the field type requires validation
    if (includedFields.includes(field.field_type)) {
      if (['TEXTFIELD', 'TEXTAREA', 'NUMBERFIELD'].includes(field.field_type)) {
        // Check for prefix, postfix, and regex
        if (
          field.pattern_prefix !== null &&
          field.pattern_prefix !== '' &&
          !event.target.value.startsWith(field.pattern_prefix.trim())
        )
          return false;
        if (
          field.pattern_postfix !== null &&
          field.pattern_postfix !== '' &&
          !event.target.value.endsWith(field.pattern_postfix.trim())
        )
          return false;
        let temp = event.target.value.trim();
        if (field.pattern_prefix !== null && field.pattern_prefix !== '') {
          temp = temp.replace(field.pattern_prefix, '');
        }
        if (field.pattern_postfix !== null && field.pattern_postfix !== '') {
          temp = temp.replace(field.pattern_postfix, '');
        }
        let result = temp.split(field.regex)[0];
        if (
          field.regex !== null &&
          !new RegExp(field.regex).test(result.trim())
        )
          return false;
      } else {
        // Check for regex only for other field types
        if (
          field.regex !== null &&
          !new RegExp(field.regex).test(event.target.value.trim())
        )
          return false;
      }
    } else {
      // If field type doesn't require validation, return true
      return true;
    }

    // If all conditions pass, return true
    return true;
  }

  getPreSelectedValue(label, index, prev, fieldType: string) {
    if (fieldType == 'SELECTLISTSINGLE') {
      return {
        [label]: this.fieldValues[index].values[0],
      };
    } else {
      let value = [];
      if (prev.length > 0) {
        prev.forEach((el, index) => {
          let obj = {
            id: index,
            name: el,
          };
          value.push(obj);
        });
        // return value;
      }
      this.fieldValues[index].preSelected = value;
    }
  }

  checkUnique(event: any, field: any, i: any, parentField: any): boolean {
    let exculdedFieldTypes = [
      'GEOCODEDIMAGE',
      'FILE',
      'SIGNATURE',
      'RADIOBUTTON',
      'CHECKBOX',
    ]; //don't check unique for these field types
    if (parentField.field_type != 'FIELDGROUP') {
      return true;
    } else if (
      parentField.unique_subgroup_index.length == 0 ||
      !parentField.unique_subgroup_index.includes(field.sub_group_index) ||
      exculdedFieldTypes.indexOf(field.field_type) != -1
    ) {
      return true;
    } else {
      const isValueUnique = (
        fieldValues: any[],
        value: any,
        groupId: number,
        subGroupIndex: number
      ): boolean => {
        // Filter the fieldValues based on groupId and subGroupIndex
        const filteredValues = fieldValues.filter(
          (item) =>
            item.group_id === groupId && item.sub_group_index === subGroupIndex
        );

        // If no matching group_id and sub_group_index found, return true
        if (filteredValues.length === 0) {
          return true;
        }
        // Check if any of the filtered items have values[0] matching the given value
        if (field.field_type == 'SELECTLISTMULTIPLE') {
          let flag = true;
          filteredValues.some((item) => {
            if (item.values.length > 0 && item.values.includes(value)) {
              flag = false;
            }
          });
          return flag;
        } else {
          return !filteredValues.some(
            (item) =>
              item.values.length > 0 &&
              item.values[0].toLowerCase() === value.toLowerCase()
          );
        }
      };

      let value =
        field.field_type == 'SELECTLISTSINGLE'
          ? event.name
          : field.field_type == 'SELECTLISTMULTIPLE'
          ? event[event.length - 1].name
          : event.target.value;
      const isUnique = isValueUnique(
        this.fieldValues,
        value,
        parentField.field_id,
        field.sub_group_index
      );
      return isUnique;
    }
  }

  onSelect(e, i,fieldType: string) {
  console.log('eventtttt: ', e);
    let frmData = new FormData();
    let imgResultAfterCompression: DataUrl;
    let imageName: string;
    let imageUrl: string;
    if (fieldType == 'FILE' || fieldType == 'IMAGE') {
      for (const file of e.target.files) {
        frmData.append('files', file);
        console.log('frmData: ', frmData);
        this.fileEvent.inProgress = true;
        this.uploadFile(frmData, fieldType, i);
      }
    } else {
      imageName = fieldType == 'SIGNATURE' ? 'signature' : 'image' + i;
      imageUrl = fieldType == 'SIGNATURE' ? e : e._imageAsDataUrl
      let file;
      this.imageCompress
        .compressFile(imageUrl, -2, 50, 50) // 50% ratio, 50% quality
        .then((compressedImage) => {
          imgResultAfterCompression = compressedImage;
          file = this.base64ToFileService.base64toFile(
            imgResultAfterCompression,
            imageName + '.jpg'
          );
          frmData.append('files', file);
          this.fileEvent.inProgress = true;
          this.uploadFile(frmData, fieldType, i,);
        });
    }
  }

  uploadFile(frmData: any, fieldType: string, i: number) {
    const headers = new HttpHeaders();
    this.common.loading = true;
    this.api
      .post('functional/upload-file?methodId=' + 17, frmData)
      .subscribe((res: any) => {
        this.common.loading = false;
        switch (res.data.type) {
          case HttpEventType.UploadProgress:
            this.fileEvent.progress = Math.round(
              (res.data.loaded * 100) / res.data.total
            );
            break;
          case HttpEventType.Response:
            return res.data;
        }
        res.data.forEach((e) => {
          if (fieldType == 'SIGNATURE' || fieldType == 'FILE' || fieldType == 'IMAGE') {
            this.fieldValues[i].values = [e.fileUrl];
          }
          else {
            this.fieldValues[i].values = [{
              ...this.fieldValues[i].values[0],
              fileUrl: e.fileUrl
            }]
          }
        });
      });
    catchError((error: HttpErrorResponse) => {
      this.fileEvent.inProgress = false;
      this.common.loading = false;
      return `${this.fileEvent.data.name} upload failed.`;
    });
    console.log("final fieldValues",this.fieldValues)
  }

  saveSignature(signatureImage: any, index: number, field: any) {
    console.log('signatureImage: ', signatureImage);
    if (signatureImage) {
      this.fieldValues[index].values = [];
      this.onSelect(signatureImage, index,  'SIGNATURE');
      this.fieldValues[index].name = field.field_name;
      this.fieldValues[index].field_id = field.field_id;
      this.fieldValues[index].fieldType = field.field_type;
      this.fieldValues[index]._dbField = field.database_field_name;
    }
  }
}

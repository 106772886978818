import { UserService } from 'src/app/@core/services/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { HardwareStatusChangeComponent } from 'src/app/modals/hardware-status-change/hardware-status-change.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';

@Component({
  selector: 'app-Plant-Hardware-View',
  templateUrl: './Plant-Hardware-View.component.html',
  styleUrls: ['./Plant-Hardware-View.component.scss'],
})
export class PlantHardwareViewComponent implements OnInit {
  plantViewData = [];
  hardwareMappingData = [];
  plantData: any = [];
  plantId: number = null;
  @Input() refData: any;

  constructor(
    public api: ApiService,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    private modalService: NgbModal,
    public plantService: PlantService,
    public role: RoleService,
    public userService: UserService
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
    if (this.role.user_role == 'COMPANYADMIN') {
      if (this.refData != null) {
        this.plantId = parseInt(localStorage.getItem('selectedPlantId'));
        this.getHardwareMapping();
      } else {
        this.getPlantById(this.userService._loggedInUser.companyId);
      }
    } else {
      this.getHardwareMapping();
    }
  }

  ngOnInit(): void {
    this.common.sidebarToggled(true);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.common.sidebarToggled(false);
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.getHardwareMapping();
  }

  getHardwareMapping() {
    this.common.loading = true;
    let params = {
      plantId: this.plantId,
    };
    this.api.get('hardware/get_hardware_data', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.data.length == 0) {
          this.hardwareMappingData = res.data;
        } else {
          this.hardwareMappingData = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  openModal(hardwareData: any) {
    let activeModal = this.modalService.open(HardwareStatusChangeComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });
    activeModal.componentInstance.refData = {
      hardwareId: hardwareData.hardware_id,
      status: hardwareData.status,
      hardwareName: hardwareData.hardware_name,
    };
    activeModal.closed.subscribe((res: any) => {
      this.getHardwareMapping();
    });
  }
}

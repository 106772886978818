import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as events from 'events';


@Injectable({
  providedIn: 'root'
})
export class EventSourceService {
  private eventSource: EventSource;
  private messageSubject: Subject<MessageEvent> = new Subject<MessageEvent>();

  constructor() {
  }

  connectEventSource(url){
    this.eventSource = new EventSource(url);
    this.eventSource.addEventListener("notice", (e) => {
      // console.log(e);
    });

    /*
     * Similarly, this will listen for events
     * with the field `event: update`
     */
    this.eventSource.addEventListener("update", (e) => {
      // console.log(e);
    });

    /*
     * The event "message" is a special case, as it
     * will capture events without an event field
     * as well as events that have the specific type
     * `event: message` It will not trigger on any
     * other event type.
     */
    this.eventSource.addEventListener("message", (e) => {
      // console.log('e: ', e);
      this.messageSubject.next(e);
    });

    this.eventSource.onmessage = (event: MessageEvent) => {
    };
  }

  closeEventSource(){
    this.eventSource.close()
  }

  getMessages(): Observable<MessageEvent> {
    return this.messageSubject.asObservable();
  }
}

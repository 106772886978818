import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import * as filesaver from 'file-saver';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { TableService } from 'src/app/@core/services/table.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { COMMON_TRIPS_ACTION_BUTTON, TABLE_TYPE, TableActions, TableColumns } from 'src/app/dataType/customTableInterface';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-tag-destination-tat-report',
  templateUrl: './tag-destination-tat-report.component.html',
  styleUrls: ['./tag-destination-tat-report.component.scss']
})
export class TagDestinationTatReportComponent implements OnInit {
  // Table Type
  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  statusColors = {
    "COMPLETED": "bg-green-200 text-green-800",
    "INPROGRESS": "bg-yellow-200 text-yellow-800",
    "CANCELLED": "bg-pink-200 text-pink-800",
    "AUTO CANCELLED": "bg-orange-200 text-orange-800"
  }
  startDate: any = new Date(new Date().setDate(new Date().getDate() - 3));
  endDate: any = new Date();
  listOfProcess: any = [];
  processName: string = null;
  processId: any = null;
  vehicleList: any[] = [];
  selectedVehicleRc: any = null;
  selectedVehicleId: any = null;
  selectedTagId: any = null;
  selectTag: any = null;
  tagList: any[] = [];
  headers: TableColumns[] = [];
  filterHeaders: TableColumns[] = [];
  responseData: any[] = [];
  loading: boolean = false;
  filteredData: any[] = [];
  filterKey: any = null;
  showTime: boolean = false;
  unmappedTag: boolean = false;
  allowedDestinations: string[] = [];

  constructor(
    public table: TableService, private datePipe: DatePipe, public common: CommonService, public api: ApiService, public breadcrumbService: BreadcrumbService, public alert: AlertService, public cdr: ChangeDetectorRef
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Tag Destination TAT Report', url: '/user/tag-destination-tat-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit() {
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.getVehicleList();
    this.getTagList();
    this.getAllProcess();
  }

  ngAfterViewInit() {
    this.getTagDestinationTATReport();
  }

  getTagList() {
    this.api.get('vehicle/get-all-tags').subscribe(
      (res: any) => {
        this.tagList = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getVehicleList() {
    this.api.get('vehicle/get-all-vehicles').subscribe(
      (res: any) => {
        this.vehicleList = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getTagDestinationTATReport() {
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.alert.warn('Start Date Should be Earlier than End Date!!');
      return;
    }

    this.common.loading = true;
    let params = {
      startDate: this.startDate,
      endDate: this.endDate,
      vehicleId: this.selectedVehicleId,
      tagId: this.selectedTagId
    };
    let _self = this; // Getting this reference to use inside table component
    this.api.get('report/get-tag-destination-tat-report', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.responseData = res.data;

          this.headers = [
            {
              columnLabel: "Vehicle No.",
              columnName: "vehicle_rsn",
              dataFormatter(row, column, index) {
                if (row.vehicle_annotation != null) {
                  return "<b>" + row[column.columnName] + "</b>";
                } else {
                  return "--''--";
                }
              },
            },
            {
              columnLabel: "Tag ID",
              columnName: "tag_id",
              dataFormatter(row, column, index) {
                if (row.vehicle_annotation != null) {
                  return "<b>" + row[column.columnName] + "</b>";
                } else {
                  return "--''--";
                }
              },
            },
            {
              columnLabel: "Date",
              columnName: "created_at",
              dataFormatter(row, column, index) {
                return _self.datePipe.transform(row.created_at, 'dd-MM-yyyy');
              },
            },
            {
              columnLabel: "Trip Start Time",
              columnName: "start_time",
              dataFormatter(row, column, index) {
                return _self.datePipe.transform(row.start_time, 'dd-MM-yyyy HH:mm:ss');
              },
            },
            {
              columnLabel: "Txn Id",
              columnName: "txnId"
            },
            {
              columnLabel: "Process",
              columnName: "process_name"
            },
            ...Object.keys(res.data[0]?.destination_tat).map(el => ({
              columnLabel: el,
              columnName: "-1",
              dataFormatter(row, column, index) {
                if (_self.showTime) {
                  return `
                  ${row.destination_tat[column.columnLabel].firstPing != "--" ? `
                    <span class="badge info" style="border: 1px solid rgb(186, 185, 185);
                          background-color: #c6e6ffad"> First Ping: ${_self.datePipe.transform(row.destination_tat[column.columnLabel].firstPing, 'HH:mm:ss')}</span>
                      <br>
                    ` : ""}
                    ${row.destination_tat[column.columnLabel].lastPing != "--" ? `
                      <span style="margin-top:8px; border: 1px solid rgb(186, 185, 185);
                            background-color: #c6e6ffad" class="badge info"> Last Ping: ${_self.datePipe.transform(row.destination_tat[column.columnLabel].lastPing, 'HH:mm:ss')}
                      </span>
                      ` : ""}
                    `;
                } else {
                  if (row.destination_tat[column.columnLabel].tat != "--") {
                    return `
                      <span class="badge" style="border: 1px solid rgb(186, 185, 185);
                          background-color: #c6e6ffad">${row.destination_tat[column.columnLabel].tat}</span>
                    `
                  }
                  return row.destination_tat[column.columnLabel].tat;
                }
              }
            }) as TableColumns),
            {
              columnLabel: "Trip Status",
              columnName: "status",
              dataFormatter: (row: any, column: TableColumns, index: number) => {
                if (row.stage_workflow_status == null) {
                  return `<span class="pill bg-slate-200 text-slate-800">TRIP NOT MAPPED</span>`;
                }
                const pillClass = this.statusColors[row.stage_workflow_status]
                return `<span class="pill ${pillClass}">${row.stage_workflow_status}</span>`
              }
            },
            {
              columnLabel: "Trip End Time",
              columnName: "end_time",
              dataFormatter(row, column, index) {
                return _self.datePipe.transform(row.end_time, 'dd-MM-yyyy HH:mm:ss');
              },
            },
            {
              columnLabel: "In Between TAT",
              columnName: "total_inbetween_tat"
            },
            {
              columnLabel: "Total Stage TAT",
              columnName: "total_stage_tat"
            },
            {
              columnLabel: "Total TAT",
              columnName: "total_tat"
            }
          ]

          this.dataFilter()
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;

      }
    );
  }

  selectedVehicle(event) {

    this.selectedVehicleId = event.vehicle_id;
    this.selectedVehicleRc = event.vehicle_rsn;
    this.dataFilter();
  }

  selectedTag(event) {

    this.selectedTagId = event.id;
    this.selectTag = event.tag_id;
    this.dataFilter();
  }

  selectedProcess(event: any) {

    this.processId = event.processId;
    this.processName = event.processName;
    this.allowedDestinations = JSON.parse(event.destinations).map((el: any) => el.destination_name);
    this.dataFilter();
  }

  showUnmappedTag() {

    this.dataFilter();
  }

  resetPage() {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 3));
    this.endDate = new Date();
    this.selectedVehicleId = null;
    this.selectedVehicleRc = null;
    this.selectedTagId = null;
    this.selectTag = null;
    this.processId = null;
    this.processName = null;
    this.unmappedTag = false;
    this.showTime = false;
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.getTagDestinationTATReport();
  }

  exportAsExcel(data: any[], fileName: string, allTrips: boolean = false): void {
    let value = _.cloneDeep(data);
    if (!allTrips) {
      value = value.map(res => {
        let d = {
          'Date': res.created_at,
          'Tag ID': res.tag_id,
          'Vehicle No.': res.vehicle_rsn,
          'Trip Start Time': this.datePipe.transform(res.start_time, 'dd-MM-yyyy HH:mm:ss'),
          'Process': res.process_name
        };
        Object.keys(res.destination_tat).forEach((destination) => {
          if(this.showTime) {
            d[destination + ' First Ping'] =  this.datePipe.transform(res.destination_tat[destination].firstPing, 'dd-MM-yyyy HH:mm:ss');
            d[destination + ' Last Ping'] = this.datePipe.transform(res.destination_tat[destination].lastPing, 'dd-MM-yyyy HH:mm:ss');

          } else {
            d[destination] = res.destination_tat[destination].tat
          }
        })
        return {
          ...d,
          'Trip Status': res.stage_workflow_status == null ? "TRIP NOT MAPPED" : res.stage_workflow_status,
          'Trip End Time': this.datePipe.transform(res.end_time, 'dd-MM-yyyy HH:mm:ss'),
          'In Between TAT': res.total_in_between_tat,
          'Total TAT': res.total_tat
        }
      });
    }
    const worksheet = XLSX.utils.json_to_sheet(value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tag Destination TAT Report');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    filesaver.saveAs(blob, fileName + '.xlsx');
  }

  dataFilter() {

    if (this.unmappedTag) {
      const unMapHeaderRemove = ["vehicle_rsn", "start_time", "end_time", "status", "process_name", "txnId"];
      this.filterHeaders = this.headers.filter(header => !unMapHeaderRemove.includes(header.columnName))
      this.filteredData = this.responseData.filter((event) => {
        return event['vehicle_id'] == null
      });
    } else {
      this.filterHeaders = this.headers;
      this.filteredData = this.responseData.filter((event) => {
        return event['vehicle_id'] != null
      });
    }


    if (this.selectTag && this.selectedVehicleId) {
      this.filteredData = this.responseData.filter((event) => {
        return (event['tag_id'] == this.selectTag && event['vehicle_id'] == this.selectedVehicleId)
      });
    }

    if (this.selectTag) {
      this.filteredData = this.responseData.filter((event) => {
        return event['tag_id'] == this.selectTag
      });
    }

    if (this.processId) {
      this.filteredData = this.responseData.filter((event) => {
        return event['process_id'] == this.processId || event['process_id'] == null
      });
      this.filterHeaders = this.filterHeaders.filter(header => header.columnName != "-1" || this.allowedDestinations.includes(header.columnLabel))
    }

    if (this.selectedVehicleId) {
      this.filteredData = this.responseData.filter((event) => {
        return event['vehicle_id'] == this.selectedVehicleId
      });
    }

    setTimeout(() => {

    }, 500)

  }

  getAllProcess() {
    this.common.loading = true;
    this.api.get('process/get-all-process-destinations?processActive=true').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.listOfProcess = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

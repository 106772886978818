<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body stageType-form">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Vehicle Route Configuration Management' | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"process->stage" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedProcessStage($event)" [data]="processStages" display="order_name"
                    placeholder="Choose Process Stage" [preSelected]="{order_name:selectedOrderName}" >
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error">
                    <span>{{processValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"destination_code" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedDestination($event)" [data]="destinationList"
                    display="stage_destination_code" placeholder="Choose Destination Code"
                    [preSelected]="{stage_destination_code:destinationName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="destinationValidator && destinationValidator.error">
                    <span>{{destinationValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                   <label for="actionStatus" class="required">{{("Direction Symbol" | translate)}}</label>
                    <select id="actionStatus" class="form-control" name="actionStatus"
                      [(ngModel)]="directionSymbol">
                      <option value="←">{{("Leftwards Arrow ←" | translate)}}</option>
                      <option value="→">{{("Rightwards Arrow →" | translate)}}</option>
                      <option value="↑">{{("Upwards Arrow ↑" | translate)}}</option>
                      <option value="↓">{{("Downwards Arrow ↓" | translate)}}</option>
                    </select>
                  <div class="error" *ngIf="directionSymbolValidator && directionSymbolValidator.error">
                    <span>{{directionSymbolValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"Direction Message" | translate}} </label>
                  <input class="form-control" type="text" name="directionMessage" id="directionMessage"
                   [(ngModel)]="directionMessage" #name="ngModel" placeholder="Enter Direction Message">
                   <div class="error" *ngIf="directionMessageValidator && directionMessageValidator.error">
                    <span>{{directionMessageValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{("Display Style" | translate)}}</label>
                   <select id="actionStatus" class="form-control" name="actionStatus"
                     [(ngModel)]="displayStyle">
                     <option value="null">Choose Display Style</option>
                     <option selected="1" value="1">Blink</option>
                     <option value="2">Bold</option>
                   </select>
               </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"color" | translate}}</label>
                  <select id="actionStatus" class="form-control" name="actionStatus"
                     [(ngModel)]="color">
                     <option selected="Green" value="Green">Green</option>
                     <option value="Yellow">Yellow</option>
                     </select>
                  <div class="error" *ngIf="colorValidator && colorValidator.error">
                    <span>{{colorValidator.msg}} </span>
                  </div>
                </div>


              </div>


              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    translate}}</button>
                  <button class="btn btn-warning" (click)="reset()">{{"reset" | translate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | translate}} </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Vehicle Route COnfiguration List" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"serial_no" | translate}}</th>
                    <th>{{"process_stage_name" | translate}}</th>
                    <th>{{"Destination" | translate}}</th>
                    <th>{{"Direction Symbol" | translate}}</th>
                    <th>{{"Direction Message" | translate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">{{"Plant" | translate}}</th>
                    <th class="action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of routeConfigurationData ; let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{item.process_stage_name}}</td>
                    <td>{{item.stage_destination_code}}</td>
                    <td>{{item.direction_symbol}}</td>
                    <td>{{item.direction_msg}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">{{item.plant_name}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="view(item)">{{"view" | translate}}</button>
                        <button class="btn btn-success" (click)="edit(item)">{{"edit" | translate}}</button>
                        <button class="btn btn-danger" (click)="confirmDelete(item)">{{"delete" | translate}}</button>
                      </div>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</div>

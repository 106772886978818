import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  messages: any = [];

  constructor(
    public common: CommonService,
    public api: ApiService,
    public breadCrumb: BreadcrumbService
  ) {
    this.getData();
    let breadcrumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Notification' },
    ];
    this.breadCrumb.getBreadcrumbs(breadcrumbs);
  }

  ngOnInit(): void {}

  getData() {
    this.api
      .get('navbar/get-all-notification', null)
      .toPromise()
      .then((res: any) => {
        this.messages = res.data[0] == null ? [] : res.data;
      });
  }
}

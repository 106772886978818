<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"assigned_trips_dashboard" | translate}}
            </h3>
          </div>
        </div>
        <div class="card-body ">
          <div class="row">
            <div class="tab_btnn_sec">
              <div class="tab_btnn" *ngFor="let tab of cardLabels;index as i">
                <button type="button" class="btn btn-block nav-buttons"
                  [ngClass]="{'btn-inactive':activeTab != i,'btn-active':activeTab == i}"
                  [ngStyle]="{'background-color': tab.tabBgColor}">
                  <div class="left_part">
                    <div class="tab_name">{{tab.tabName | translate}}</div>
                    <div class="tab_number">{{assignedTrips.length}}</div>
                  </div>

                  <div class="right_part">
                    <div class="icon_div" [ngStyle]="{'background-color': tab.tabIconBg}">
                      <i [class]="'fa fa-'+tab.tab_icon" [ngClass]="tab.tabName"></i>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div class="col-12">

            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"s.no." | translate}} </th>
                    <th>{{"vehicle_number" | translate}} </th>
                    <th>{{"process" | translate}} </th>
                    <th>{{"stage" | translate}} </th>
                    <th>{{"assigned_by" | translate}} </th>
                    <th>{{"assigned_to" | translate}} </th>
                    <th>{{"trip_current_status" | translate}} </th>
                    <th>{{"action" | translate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of assignedTrips; let i = index;">
                    <td>{{i + 1}}</td>
                    <td appEllipsisView>{{item.vehicle_rc_no}}</td>
                    <td>{{item.process_name}}</td>
                    <td>{{item.stage_name}}</td>
                    <td>{{item.assignedby}}</td>
                    <td>{{item.assignedto}}</td>
                    <td>
                    <span [ngClass]="{'green-jellybean':item.tripcurrentstatus == 'INPROGRESS' , 'red-jellybean' : item.tripcurrentstatus == 'CANCELLED' , 'yellow-jellybean' : item.tripcurrentstatus == 'MAINTAINENCE' }">
                        {{item.tripcurrentstatus}}
                      </span>
                    </td>
                    <td class="action">
                      <div class="btn-block" style="width:max-content">
                        <button class="btn btn-primary text-capitalize" (click)="viewHistory(item)"> {{"info" |
                          translate}} </button>
                          <button type="button" class="btn btn-success"
                          (click)="openAssignUserModal(item)">{{'reassign_user' | translate}}</button>
                          <button type="button" class="btn btn-success"
                          (click)="saveData(item.txn_id, item.stage_id, item.user_id);">{{'reassign_manager' |
                          translate}}</button>
                        </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>

import { element } from 'protractor';
import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-set-user-permission',
  templateUrl: './set-user-permission.component.html',
  styleUrls: ['./set-user-permission.component.scss'],
})
export class SetUserPermissionComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 3, 'permission table');
  WebPages: any[] = [];
  AppPages: any[] = [];
  userTypesList: any = {
    AXESTRACK: [
      'AXESTRACK',
      'AXESTRACKUSER',
      'COMPANYADMIN',
      'COMPANYUSER',
      'PLANTADMIN',
      'PLANTUSER',
    ],
    AXESTRACKUSER: ['PLANTADMIN', 'PLANTUSER'],
    COMPANYADMIN: ['COMPANYADMIN', 'COMPANYUSER', 'PLANTADMIN', 'PLANTUSER'],
    COMPANYUSER: ['PLANTADMIN', 'PLANTUSER'],
    PLANTADMIN: ['PLANTADMIN', 'PLANTUSER'],
    PLANTUSER: ['PLANTUSER'],
  };
  OpenPanels: boolean[] = [true, false];
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  expansionPanel = [
    { name: 'Web Pages', type: 'web' },
    { name: 'App Pages', type: 'app' },
  ];
  userList: any = [];
  userId: any = null;
  allComponents: any = [];
  dashBoardPages: any = [];
  roles: any = [];
  filteredRoles: any = [];
  btnText = 'save';
  roleName = null;
  roleStatus = 'ACTIVE';
  companyId = null;
  companyName = null;
  plantName = null;
  plantId = null;
  companyData: any = [];
  plantData: any = [];
  allPlants: any = [];
  companyNameValidator: any = null;
  plantNameValidator: any = null;
  roleNameValidator: any = null;
  typeValidator: any = null;
  roleType: any = null;
  componentIds: any = [];

  constructor(
    public common: CommonService,
    public api: ApiService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public plantService: PlantService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService,
    public alertService: AlertService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'User-Group', url: null },
      { name: 'Create Roles', url: '/user/set-user-permission' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    this.roleType = role.user_role;

    if (this.role.user_role != 'AXESTRACK') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
  }

  ngOnInit(): void {
    this.getComponent();
    this.getCompany();
    this.getAllPlants();
  }

  getCompany() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getAllPlants() {
    this.common.loading = true;
    this.api.get('plant/all_plants').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.allPlants = res.data;
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = null;
    this.companyNameValidator = null;
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.plantNameValidator = null;
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getRoles() {
    this.common.loading = true;
    this.api.get('role/all_roles').subscribe(
      (res: any) => {
        this.roles = res.data || [];
        this.common.loading = false;
        this.renderTable(); // Render the table outside the condition
      },
      (err: any) => {
        console.error('Error getting roles: ', err);
        this.common.loading = false;
      }
    );
  }

  getComponent() {
    this.dashBoardPages = [];
    this.common.loading = true;
    let params = {
      loginUserType: this.role.user_role,
    };
    this.api.get('component/all_component', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.allComponents = res.data;
        this.dashBoardPages = res.data ?? [];
        this.OpenPanels = [true, false];
        if (this.allComponents.length) {
          this.WebPages = this.allComponents.filter(
            (item) => item.platform == 'WEB'
          );
          this.AppPages = this.allComponents.filter(
            (item) => item.platform == 'APP'
          );
        }
        this.getRoles();
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  updatePreferences() {
    const params = {
      module: JSON.stringify(this.dashBoardPages),
      roleId: this.userId,
      roleName: this.roleName,
      plantId: this.plantId,
      companyId: this.companyId,
      roleType: this.roleType,
      roleStatus: this.roleStatus,
      componentIds: this.componentIds,
    };
    this.common.loading = true;
    this.api.post('role/create-role', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.type == 'Success') {
          this.alertService.success(res.message);
        }
        this.resetDetail();
        this.getRoles();
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  openConfirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete User ',
      description: `<b>&nbsp;` + 'Are you sure want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteData(row);
      }
    });
  }

  deleteData(item?: any) {
    this.roleName = item.roleName;
    this.userId = item.roleId;
    this.roleStatus = 'DELETED';
    this.companyId =
      item.company && item.company['companyId']
        ? item.company['companyId']
        : null;
    this.companyName =
      item.company && item.company['companyName']
        ? item.company['companyName']
        : null;
    this.plantId = item.plant ? item.plant : null;
    this.plantName =
      item.plant && item.plant['plantName'] ? item.plant['plantName'] : null;
    this.updatePreferences();
  }

  checkOrUnCheckfunctionality(module: any, indexmodule: any, isAll: any) {
    if (isAll) {
      for (let i = 0; i < module.childs.length; i++) {
        module.childs[i].isAccess = true;
        for (let j = 0; j < module.childs[i].functionality.length; j++) {
          module.childs[i].functionality[j].isAccess = true;
        }
      }
    } else {
      for (let i = 0; i < module.childs.length; i++) {
        module.childs[i].isAccess = false;
        for (let j = 0; j < module.childs[i].functionality.length; j++) {
          module.childs[i].functionality[j].isAccess = false;
        }
      }
    }
  }

  checkParentModule() {
    return [...this.WebPages, ...this.AppPages].map((module) => {
      let childExist = false;
      for (let i = 0; i < module.childs.length; i++) {
        let funExist = false;
        for (let j = 0; j < module.childs[i].functionality.length; j++) {
          if (module.childs[i].functionality[j].isAccess) {
            funExist = true;
            this.componentIds.push(module.childs[i].functionality[j].id);
          }
        }
        if (module.childs[i].isAccess || funExist) {
          module.childs[i].isAccess = true;
          childExist = true;
          this.componentIds.push(module.childs[i].id);
        }
      }
      module.isAccess = childExist;
      if (module.isAccess) this.componentIds.push(module.id);
      return module;
    });
  }

  changeParentChildAccess(component:any,mainComponent:any,type:string){
    if(type=='child'){
      for(const element of component.functionality){
        if(element.isAccess){
          component.isAccess = true;
          break
        }
        component.isAccess = false;
      }
    } else if(type=='parent'){
      component.functionality.forEach(element => {
        element.isAccess = component.isAccess;
      })
    }
    for(const element of mainComponent.childs){
      if(element.isAccess){
        mainComponent.isAccess = true;
        break
      }
      mainComponent.isAccess = false;
    }
  }

  resetDetail() {
    this.roleName = null;
    this.userId = null;
    this.companyName = null;
    this.plantName = null;
    this.companyId = null;
    this.plantId = null;
    this.roleStatus = 'ACTIVE';
    this.btnText = 'save';
    this.roleType = this.role.user_role;
    this.companyNameValidator = null;
    this.plantNameValidator = null;
    this.typeValidator = null;
    this.roleNameValidator = null;
    this.componentIds = [];
    this.resetUserPages();
  }

  editDetail(item: any) {
    this.OpenPanels = [true, true];
    this.common.gotoTop();
    this.roleName = item.roleName;
    this.userId = item.roleId;
    this.roleStatus = item.roleStatus;
    if (item.roleType != 'COMPANYADMIN') {
      this.companyId = this.allPlants.find(
        (plant) => plant.plantId == item.plant
      ).companyId;
      this.companyName = this.allPlants.find(
        (plant) => plant.plantId == item.plant
      ).companyName;
      this.plantId = item.plant;
      //Todo AsyncAwait
      this.getPlantById(this.companyId);
      setTimeout(() => {
        this.plantName = this.plantData.find(
          (plant) => plant.plantId == item.plant
        ).plantName;
      }, 2000);
    } else {
      this.companyId = this.companyData.filter(
        (cmp) => cmp.companyId == item.companyId
      )[0].companyId;
      this.companyName = this.companyData.filter(
        (cmp) => cmp.companyId == item.companyId
      )[0].companyName;
    }
    this.btnText = 'update';
    this.roleType = item.roleType;
    this.getUserPages(item.roleId);
  }

  getUserPages(roleId: any) {
    this.common.loading = true;
    let params = {
      roleId: roleId,
    };
    this.api.get('role/get-assign-component', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res) {
          this.setUserPages(res.data);
        }
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }
  setUserPages(item: any) {
    this.resetUserPages();
    for (let index = 0; index < item.length; index++) {
      for (let i = 0; i < this.dashBoardPages.length; i++) {
        if (this.dashBoardPages[i].id == item[index]) {
          this.dashBoardPages[i].isAccess = true;
        } else {
          for (let j = 0; j < this.dashBoardPages[i].childs.length; j++) {
            if (this.dashBoardPages[i].childs[j].id == item[index]) {
              this.dashBoardPages[i].childs[j].isAccess = true;
            } else {
              for (
                let k = 0;
                k < this.dashBoardPages[i].childs[j].functionality.length;
                k++
              ) {
                if (
                  this.dashBoardPages[i].childs[j].functionality[k].id ==
                  item[index]
                ) {
                  this.dashBoardPages[i].childs[j].functionality[k].isAccess =
                    true;
                }
              }
            }
          }
        }
      }
    }
  }

  resetUserPages() {
    {
      for (let i = 0; i < this.dashBoardPages.length; i++) {
        this.dashBoardPages[i].isAccess = false;
        for (let j = 0; j < this.dashBoardPages[i].childs.length; j++) {
          this.dashBoardPages[i].childs[j].isAccess = false;
          for (
            let k = 0;
            k < this.dashBoardPages[i].childs[j].functionality.length;
            k++
          ) {
            this.dashBoardPages[i].childs[j].functionality[k].isAccess = false;
          }
        }
      }
    }
  }

  formValidator() {
    this.roleNameValidator = this.common.nullValidator(
      this.roleName,
      'Role Name Mandatory'
    );
    this.typeValidator = this.common.nullValidator(
      this.roleType,
      'Role Type Mandatory'
    );
    let temp = this.common.checkCompanyPlantAccToUser(
      this.roleType,
      this.role.user_role,
      this.companyId,
      this.plantId
    );
    if (!temp.company) {
      this.companyNameValidator = this.common.nullValidator(
        this.companyId,
        'Company Mandatory'
      );
      return;
    }

    if (!temp.plant) {
      this.plantNameValidator = this.common.nullValidator(
        this.plantId,
        'Plant Mandatory'
      );
      return;
    }

    if (
      temp.company &&
      temp.plant &&
      !this.roleNameValidator.error &&
      !this.typeValidator.error
    ) {
      let res = this.checkParentModule();
      this.updatePreferences();
    }
  }
}

export const fieldTypes:any[]=[
    {
      fieldType:  'TEXTFIELD',
      fieldIcon: 'clock-o',
    },
    {
      fieldType:  'NUMBERFIELD',

      fieldIcon:  'hashtag',
    },{
      fieldType:  'TEXTAREA',
      fieldIcon:  'font',
    },{
      fieldType:  'DATEPICKER',
      fieldIcon: 'calendar days',
    },{
      fieldType:  'DATETIMEPICKER',
      fieldIcon: 'calendar days',
    },
    {
      fieldType:  'TIME',
      fieldIcon: 'clock-o',
    },
    {
      fieldType:  'SELECTLISTSINGLE',
      fieldIcon: 'check',
    },
    {
      fieldType:  'SELECTLISTMULTIPLE',
      fieldIcon:  'check-double',
    },
    {
      fieldType:  'CHECKBOX',
      fieldIcon: 'check-square',
    },
    {
      fieldType:  'FILE',
      fieldIcon:  'file',
    },
    {
      fieldType:  'RADIOBUTTON',
      fieldIcon:  'circle',
    },
    {
      fieldType:  'URL',
      fieldIcon: 'link',
    },
    {
      fieldType:  'GEOCODEDIMAGE',
      fieldIcon: 'image',
    },
    {
      fieldType:  'SIGNATURE',
      fieldIcon:  'signature',
    }
  ] ;
import { Component, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from 'src/app/components/plant/plant.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { TripCommentsComponent } from './trip-comments/trip-comments.component';

@Component({
  selector: 'app-trip-comment-trail',
  templateUrl: './trip-comment-trail.component.html',
  styleUrls: ['./trip-comment-trail.component.scss'],
})
export class TripCommentTrailComponent implements OnInit {
  @Input() complainceData:any;
  @Input() tripId: number;
  @Input() entityType:String='TRIP';
  @Input() showModal: boolean=false;
  @Input() title:string = 'Trip'
  @Input() toggle: boolean = false;
  @Output() callGetCompliance:EventEmitter<any> = new EventEmitter<any>();
  tripCommentTrail: any[] = [];
  commentMessage: string = null;
  activeModal:any;
  complianceModalData:any;
  @ViewChild('content') contentTemplateRef: TemplateRef<any>;

  constructor(
    public modalService: NgbModal,
    public alertService: AlertService,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public plantService: PlantService,
    public role: RoleService
  ) {}

  ngOnInit(): void {
    if(this.complainceData){
      this.complianceModalData = {
        processName : this.complainceData.process_name,
        stageName : this.complainceData.stage_name,
        stageId: this.complainceData.stage_id,
        processId: this.complainceData.process_id,
        driverId: this.complainceData.driver_id,
        transporterId: this.complainceData.transporter_id,
        vehicleId: this.complainceData.vehicle_id,
        txnId:this.complainceData.txnid,
        vehicleRsn: this.complainceData.vehicle_rc_no,
        driverName:this.complainceData.driver_name,
        transporterName:this.complainceData.transporter_name,
        refType : this.complainceData.refType?this.complainceData.refType:this.entityType,
        entityType: this.entityType
      }
    }

  }

  openTripRemarkTrail(content) {
    console.log('content',content)
      this.activeModal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' ,size:'xl'});
      this.activeModal.result.finally((res) => {
        this.callGetCompliance.emit(res);
      })
  }

}

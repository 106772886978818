<div class="container-fluid" [ngClass]="{'driver-view':common.driverView}">
  <div class="container-card">
    <div class="container-card-body" [ngClass]="{'driver-view':common.driverView}">
      <app-admin-dashboard *ngIf="role.user_role == 'AXESTRACK'"></app-admin-dashboard>
      <app-user-dashboard
        *ngIf="role.user_role == 'PLANTADMIN' || role.user_role == 'AXESTRACKUSER' || role.user_role == 'COMPANYUSER'"></app-user-dashboard>
      <app-customer-dashboard *ngIf="role.user_role == 'PLANTUSER'"> </app-customer-dashboard>
      <app-company-admin-dashboard *ngIf="role.user_role == 'COMPANYADMIN'"></app-company-admin-dashboard>
    </div>
  </div>

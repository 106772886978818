import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-parking-zone-management',
  templateUrl: './parking-zone-management.component.html',
  styleUrls: ['./parking-zone-management.component.scss']
})
export class ParkingZoneManagementComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'zone List');
  zones: any[] = [];
  btn = 'save';
  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public alertService: AlertService,
    public api: ApiService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Zone', url: null },
      { name: 'Zone Management', link: '/user/zone-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  zoneSlotLimit: number = null;
  zoneName: string = null;
  zoneSlotLimitValidator: any = null;
  zoneNameValidator: any = null;
  zoneId: number = null;
  zoneStatus: any = 'ACTIVE';
  ngOnInit(): void {
    this.getAllZones();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  reset() {
    this.btn = 'save';
    this.zoneName = null;
    this.zoneSlotLimit = null;
    this.zoneNameValidator = null;
    this.zoneSlotLimitValidator = null;
    this.zoneId = null;
    this.zoneStatus = null;
  }

  save() {
    let params = {
      zoneId: this.zoneId,
      zoneName: this.zoneName,
      zoneSlotLimit: this.zoneSlotLimit,
      zoneStatus: this.zoneStatus,
    };
    this.api.post('zone/save_zone', params).subscribe(
      (res: any) => {
        if (res.status) {
          if (res.type == 'INFO') {
            this.alertService.error(res.message);
          } else {
            this.alertService.success(res.message);
            this.reset();
          }
          this.getAllZones();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  edit(row: any) {
    this.btn = 'update';
    this.zoneId = row.id;
    this.zoneName = row.zoneName;
    this.zoneSlotLimit = row.slotLimit;
    this.zoneStatus = row.zoneStatus;
  }

  deleteZone(row: any) {
    this.api.get('zone/delete_zone?zoneId=' + row.id).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertService.info(res.message);
          this.getAllZones();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Zone ',
      description:
        `<b>&nbsp;` + 'Are you sure you want to delete zone?' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteZone(row);
      }
    });
  }

  view(row?: any) {
    let newObj = {
      zoneName: row.zoneName,
      slotLimit: row.slotLimit,
      zoneStatus: row.zoneStatus,
    };
    this.common.params = { details: [newObj], title: 'Zone Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  getAllZones() {
    let params = {
      listType: 'all',
    };
    this.api.get('zone/get_all_zones', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.zones = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  formValidator() {
    this.zoneNameValidator = this.common.nullValidator(
      this.zoneName,
      'Zone Name Mandatory'
    );
    this.zoneSlotLimitValidator = this.common.nullValidator(
      this.zoneSlotLimit,
      'Zone Slot Limit Mandatory'
    );
    if (!this.zoneNameValidator.error && !this.zoneSlotLimitValidator.error) {
      this.save();
    }
  }
}


import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { CommonService } from '../../@core/services/common.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../@core/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { environment } from 'src/environments/environment';
import { ViewRemarkComponent } from '../view-remark/view-remark.component';
import { ViewChecklistComponent } from 'src/app/components/view-checklist/view-checklist.component';
@Component({
  selector: 'app-checklist-history-data',
  templateUrl: './checklist-history-data.component.html',
  styleUrls: ['./checklist-history-data.component.scss'],
})
export class ChecklistHistoryDataComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'Checklist History List');
  refData: any;
  checklistHistoryData: any = [];
  pageUrl: string;

  ngAfterViewInit() {
    this.dtTrigger.next();
    if (this.common.params.type == 'normal') {
      this.getChecklistHistoryData();
    }

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    public table: TableService,
    private activeModal: NgbActiveModal,
    public breadcrumbService: BreadcrumbService,
    public datePipe: DatePipe,
    private modalService: NgbModal
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
    this.refData = this.common.params;
  }

  ngOnInit(): void {}



  viewRemark(item: any) {
    this.common.params = {
      log_id: item.log_id,
      checklist_title: item.checklist_title,
      remarkType: 1,
    };
    this.modalService.open(ViewRemarkComponent, { size: 'xl' });
  }
  viewRejectedRemark(logId: number, checklist_title: string) {
    this.common.params = {
      log_id: logId,
      checklist_title: checklist_title,
      rejectedFlag:true,
    };
    this.modalService.open(ViewRemarkComponent, { size: 'xl' });
  }



  getChecklistHistoryData() {
    this.common.loading = true;
    let params = {
      startDate:this.refData.startDate,
      endDate:this.refData.endDate,
      flag:this.refData.flag,
      userId:this.refData.userId,
      checklistId:this.refData.checklistId
    }
    this.api
      .get(
        'checklist-dashboard/date-and-status-wise-checklist-data',params
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.checklistHistoryData = res.data;
          this.renderTable();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }


  viewCheckList(item:any) {
    this.common.params = item.log_id;
    const activeModal = this.modalService.open(ViewChecklistComponent, {
      size: 'xl',
    });
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  getDelayedTime(status: string, perform_time: string, end_time: string) {
    let delayed_time: any = '';
    if (status == 'delay') {
      // Assuming you have two time strings
      const startTimeStr = end_time;
      const endTimeStr = perform_time;

      const endDatePart = endTimeStr.split(' ')[0];
      // Convert time strings to Date objects
      const startTime = new Date(`${endDatePart} ${startTimeStr}`);
      const endTime = new Date(endTimeStr);

      // Calculate the time difference in milliseconds
      const timeDifferenceMillis = endTime.getTime() - startTime.getTime();

      // Convert milliseconds to seconds
      let delayMinutes = timeDifferenceMillis / (1000 * 60);
      delayed_time = 'by ' + delayMinutes.toString().split('.')[0] + ' min.';
    }
    return delayed_time;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AssetRequestRemarkComponent } from '../asset-request-remark/asset-request-remark.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { responseData } from 'src/app/dataType/assetInterface';
import { STATUS_CARD } from './data';

@Component({
  selector: 'app-asset-events',
  templateUrl: './asset-events.component.html',
  styleUrls: ['./asset-events.component.scss']
})
export class AssetEventsComponent implements OnInit {

  responseData:responseData[]=[];
  requestCount:Record<string,any>;
  statusCard=STATUS_CARD

  @Input() public assetId:any;

  constructor(public common:CommonService,public api:ApiService,public router:Router, private modalService: NgbModal,public alert: AlertService) { }

  ngOnInit(): void {
    if(this.assetId){
      this.getAssetEvent(this.assetId);
      this.getRequestCount(this.assetId);
    }
  }

  getAssetEvent(assetId:any){
    this.common.loading = true;
    let params={
      assetId:assetId
    }
    this.api.get('asset_requests/get-request-for-asset',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.responseData = res.data;
        }
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    )
  }

  getRequestCount(assetId:any){
    this.common.loading = true;
    let params={
      assetId:assetId
    }
    this.api.get('asset_requests/get-request-count-for-asset',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.requestCount = res.data.reduce((acc,obj)=> {acc[obj.status_name]=obj.count;
            return acc},{} as Record<string, number>);
          console.log('this.requestCount: ', this.requestCount);
        }
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    )
  }

  openRemarkModal(field:any) {

    const activeModal=this.modalService.open(AssetRequestRemarkComponent,{
      size:'xl'
    })
    activeModal.componentInstance.refData={
      request_id:field.id,
      calling:true,
      from_page:true
    }

  }

  linkCopy(field:any){
    const secretKey = btoa(field.id);
    const calling = '/user/asset-view'
    const assetId =  btoa(this.assetId);
    const link=`${window.location.origin}/#/user/request-info?secretKey=${secretKey}&calling=${calling}&assetId=${assetId}`;
    navigator.clipboard.writeText(link).then(() =>{
      this.alert.success('Link copied to clipboard');
      console.log('Link copied to clipboard:', link);
    }).catch(err => {
      this.alert.error('Failed to copy the link');
      console.error('Failed to copy the link:', err);
    });
  }
}

import { UserService } from 'src/app/@core/services/user.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { Router } from '@angular/router';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { MapModalComponent } from '../map-modal/map-modal.component';

@Component({
  selector: 'app-action-dashboard-override',
  templateUrl: './action-dashboard-override.component.html',
  styleUrls: ['./action-dashboard-override.component.scss'],
})
export class ActionDashboardOverrideComponent implements OnInit {
  actionData: any = [];
  processId: any;
  vehicleId: any;
  stageId: any;
  currentActionData: any = [];
  hideShow1: any = [];
  preActionsData: any = [];
  actionDetail: any = [];
  formFields: any = [];
  masterFormFields: any = [];
  processName: any;
  stageName: any;
  showStageFormLoader: boolean = true;
  txnId: any = null;
  image_name: any = '';
  image_ext: any = '';
  image_url: any = '';
  txnStageDetailId: number = null;
  commentMessage:string = '';

  constructor(
    public common: CommonService,
    public api: ApiService,
    private modalService: NgbModal,
    public userService: UserService,
    private activeModal: NgbActiveModal,
    public alertService: AlertService,
    private router: Router
  ) {
    this.actionDetail = this.common.params.rowDetail;
    this.processId = this.actionDetail.processId;
    this.vehicleId = this.actionDetail.vehicleId;
    this.stageId = this.actionDetail.stageId;
    this.stageName = this.actionDetail.stageName;
    this.processName = this.actionDetail.processName;
    this.txnId = this.actionDetail.transactionId;
    this.txnStageDetailId = this.actionDetail.transactionStageDetailId;

    this.getActionData(this.actionDetail.actionId);
  }

  ngOnInit(): void {}
  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  updateOverridingStatus(status: any) {
    this.common.loading = true;
    this.addCommentForTrip(status);
    this.api
      .get(
        'dashboard/update-overriding-status?transactionDetailId=' +
          this.actionDetail.transactionDetailId +
          '&status=' +
          status +
          '&actionId=' +
          this.actionDetail.actionId +
          '&transactionId=' +
          this.actionDetail.transactionId
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.type == 'Success') {
            this.alertService.success(res.message);
            this.closeModal();
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  addCommentForTrip(status) {
    let params = {
      entityId: this.txnId,
      entityType: 'TRIP',
      commentType: 'ACTION OVERRIDING '+status,
      comment: this.commentMessage == '' ? 'N/A' : this.commentMessage,
      refData: JSON.stringify({title:this.actionDetail.actionName,stageName:this.stageName,stageId:this.stageId})
    };
    this.api.post('comment/add-comment', params).subscribe(
      (res: any) => {
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  location(lat, lng) {
    this.common.params = {
      lat: lat,
      lng: lng,
    };
    const activeModal = this.modalService.open(MapModalComponent, {
      size: 'lg',
    });
  }

  openTripDetail() {
    this.router.navigate(['/user/vehicle-detail'], {
      state: { txnId: this.actionDetail.transactionId },
    });
    this.closeModal();
  }

  accordion1(i: any) {
    this.hideShow1.forEach((element) => {
      if (element.id == i && element.boolean == false) {
        element.boolean = true;
      } else if (element.id == i && element.boolean == true) {
        element.boolean = false;
      } else {
        element.boolean = false;
      }
    });
  }

  getActionData(actionId: any) {
    this.common.loading = true;
    this.api
      .get(
        'dashboard/get-action-details?txnId=' +
          this.txnId +
          '&actionId=' +
          actionId +
          '&stageId=' +
          this.stageId +
          '&actionType=' +
          1 +
          '&txnStageDetailId=' +
          this.txnStageDetailId
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.preActionsData = res.data[0].preActionsData;
          this.currentActionData = res.data[0].currentActionData;
          this.hideShow1 = [];
          this.preActionsData.forEach((el) => {
            let obj = {
              id: this.hideShow1.length,
              boolean: false,
            };
            this.hideShow1.push(obj);
          });
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }

  displayImage(url) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }
}

<div class="searchBox_row">
  <div class="export-btns" *ngIf="columns.length && exportIcons">
    <button (click)="exportCSV()" class="csv" title="Download CSV">
      <i class="fas fa-download"></i>
    </button>

    <button title="Download Excel" class="excel" (click)="exportExcel()">
      <i class="fas fa-file-excel"></i>
    </button>

    <button (click)="generatePdf()" class="pdf" title="Download Pdf">
      <i class="fa fa-file-pdf text-danger"></i>
    </button>
    <div class="select_column_div"> <button class="select_column" type="button"
        (click)="$event.stopPropagation(); showDropDown = !showDropDown;">
        <i class="fa fa-ellipsis-v"></i>
      </button>
      <div class="select_column_dropdown  smart-table-toggle" *ngIf="showDropDown">
        <div class="filter-block py-1">
          <label class="search-filter"> <input type="text" placeholder="Search..." class="form-control"
              [(ngModel)]="searchText" (ngModelChange)="toggleFilter()"> </label>
          <div class="close-btn" (click)="showDropDown = false">
            <i class="fa fa-window-close text-danger"></i>
          </div>
        </div>
        <label class="dropdown-item" for="select-all" *ngIf="!searchText">
          <input id="select-all" type="checkbox" [(ngModel)]="allSelected" (change)="handleSelection(allSelected)">
          Select All
        </label>
        <label *ngFor="let item of selectionHeading;index as i" [class.d-none]="!item.show" class="dropdown-item"
          [for]="'check-'+i+1">
          <input type="checkbox" [id]="'check-'+i+1" [(ngModel)]="item.checked" (change)="shareCheckedList($event)">
          {{item.value}}
        </label>
      </div>
    </div>
  </div>
  <div class="global-filter" *ngIf="searchFilter">
    <!-- <i *ngIf="globalFilter" class="fa fa-close text-danger filter-remove" (click)="clearAllFilter()"></i> -->
    <input (input)="allFilter()" [(ngModel)]="globalFilter" placeholder="Search" class="form-control" type="search"
      name="">
  </div>
</div>



<!-- <multiselect-checkbox [list]="selectionHeading" class="w-100" (shareCheckedList)="shareCheckedList($event)" display="value">
</multiselect-checkbox> -->


<!-- [ngStyle]="{height: settings.tableHeight || '85%',position: settings.position || ''}" -->
<div [ngClass]="[settings.notFixedHeader  ? '' : 'tableFixHead card-view', pages.count > 1 ? 'paginaion_conditon':'']"
  [id]="tableId">
  <table #table *ngIf="headings && !isTableHide" class="table table-bordered table-fixed">
    <thead *ngIf="!settings.hideHeader">
      <th class="text-white">#</th>
      <th *ngFor="let heading of objectKeys(headings);">{{headings[heading].title}}</th>
    </thead>
    <thead>
      <tr style="background:#f2f2f2;">
        <!-- # -->
        <th>
          <div *ngIf="settings.count">
            <i [class]="settings.count.icon" (click)="settings.count.action && settings.count.action()"></i>
          </div>
          <span *ngIf="!settings.count" class="text-white">#</span>
        </th>
        <!-- # END -->
        <!-- Headings -->
        <th *ngFor="let heading of objectKeys(headings);" [ngClass]="headings[heading].class"
          [class.action-heading]="['action','Action'].includes(headings[heading].value)">
          <div *ngIf="!headings[heading].isHTML && !headings[heading].isCheckbox">
            <!-- Searchable -->
            <div *ngIf="!headings[heading].hideSearch" class="filter-container">
              <!-- Search INPUT -->
              <!--  heading for PDF EXPORT  -->
              <span class="d-none"> {{headings[heading].placeholder}} </span>
              <div class="filter-input-container">
                <input type="text" class="filter-input" [ngClass]="{'search-active': headings[heading].value}"
                  [(ngModel)]="headings[heading].value" (input)="debounceFilter(headings[heading])"
                  [placeholder]="headings[heading].placeholder" [title]="headings[heading].placeholder">
                <i class="fa fa-close text-danger clear-filter" *ngIf="headings[heading].value"
                  (click)="headings[heading].value = null;filterData(headings[heading])"></i>
              </div>
              <!-- Search INPUT END -->
              <!-- Column Sorting -->
              <div class="sort-container">
                <i class="fa fa-sort sort-icon" (click)="sortColumn(heading)"></i>
              </div>
              <!-- Column Sorting END-->
            </div>
            <!-- Searchable END-->
            <!-- Not Seachable -->
            <div *ngIf="headings[heading].hideSearch" class="no-search">
              <span>{{headings[heading].title || headings[heading].placeholder}}</span>
            </div>
            <!-- Not Seachable END -->
          </div>
          <!-- heading as HTML -->
          <div *ngIf="headings[heading].isHTML" [innerHTML]="headings[heading].html"></div>
          <!-- heading as HTML END -->

          <!-- heading as Checkbox -->
          <div *ngIf="headings[heading].isCheckbox">
            <input type="checkbox" (change)="headings[heading].action(headings[heading].value)"
              [(ngModel)]="headings[heading].value">
          </div>
        </th>
        <!-- Headings END -->
        <th class="action-box" *ngIf="edit.row !== -1">Action</th>
      </tr>
    </thead>
    <tbody>

      <tr *ngFor="let column of columns; let i = index;" (mouseleave)="activeActionRow = -1"
        (click)="column.rowActions && column.rowActions.click && handleRowClick($event,column, i)"
        (dblclick)="column.rowActions?.dblclick && column.rowActions.dblclick()"
        [ngClass]="{'active-row': isItActive(column),'highlight' : selectedRow == i, 'highlightcolor' : columns.length-1 == i}"
        [ngStyle]="column.style">

        <!-- Records Count -->
        <td>
          {{(i + 1) + ((pages.active - 1) * pages.limit)}}
        </td>
        <!-- Records Count End -->

        <!-- Smart Table Value -->
        <td *ngFor="let heading of objectKeys(headings); last as isLast"
          [ngClass]="column[heading] ? column[heading].class : ''"
          [class.action-column]="objectKeys(headings)[heading.length - 1] == heading"
          (click)="handleColumnClick($event,column, heading, i)" (dblclick)="handleColDoubleClick(column, heading)"
          (mouseover)="handleMouseHover(column, heading)" (mouseout)="handleMouseOut(column, heading)"
          [ngStyle]="{cursor: (column[heading] && column[heading].action) ? 'pointer' : ''}">

          <!-- Auto Suggestion -->
          <div *ngIf="column[heading] && column[heading].isAutoSuggestion">
            <ps-auto-suggestion (onSelected)="isEventBinding(column[heading], 'onSelected', $event)"
              (unSelected)="isEventBinding(column[heading], 'unSelected', $event)"
              (noDataFound)="isEventBinding(column[heading], 'noDataFound', $event)"
              (onChange)="isEventBinding(column[heading], 'onChange', $event)"
              [url]="isPropertyBinding(column[heading], 'url')"
              [display]="isPropertyBinding(column[heading], 'display')"
              [className]="isPropertyBinding(column[heading], 'suggestionClass')"
              [placeholder]="isPropertyBinding(column[heading], 'placeholder')"
              [preSelected]="isPropertyBinding(column[heading], 'preSelected')"
              [seperator]="isPropertyBinding(column[heading], 'seperator')"
              [data]="isPropertyBinding(column[heading], 'data')"
              [inputId]="isPropertyBinding(column[heading], 'inputId')"
              [name]="isPropertyBinding(column[heading], 'name')"
              [parentForm]="isPropertyBinding(column[heading], 'parentForm')"
              [controlName]="isPropertyBinding(column[heading], 'controlName')"
              [apiHitLimit]="isPropertyBinding(column[heading], 'apiHitLimit')"
              [isNoDataFoundEmit]="isPropertyBinding(column[heading], 'isNoDataFoundEmit')"
              [isMultiSelect]="isPropertyBinding(column[heading], 'isMultiSelect')"
              [bGConditions]="isPropertyBinding(column[heading], 'bGConditions','')">
            </ps-auto-suggestion>
          </div>
          <!-- Auto Suggestion END -->



          <!-- Show HTML -->
          <div [ngClass]="column[heading].action ? 'zoom' : ''" *ngIf="column[heading] && column[heading].isHTML"
            [innerHTML]="column[heading].value">
          </div>
          <!-- Show HTML END -->

          <!-- Checkbox -->
          <div [ngClass]="column[heading].action ? 'zoom' : ''" *ngIf="column[heading] && column[heading].isCheckbox">
            <input type="checkbox" (change)="handleCheckboxChange($event, column[heading].action)"
              [(ngModel)]="column[heading].value">
          </div>
          <!-- CheckBox END -->

          <!-- Show Text -->
          <div [ngClass]="column[heading].action ? 'zoom' : ''"
            *ngIf="column[heading] && !column[heading].isHTML && !column[heading].icons && !column[heading].isCheckbox && !column[heading].isAutoSuggestion">
            <!--  -->
            <span *ngIf="edit.row !== i || !headings[heading].editable"
              [title]="(column[heading].isTitle) ?column[heading].title : ''">{{column[heading].value}}</span>
            <input class="form-control" *ngIf="edit.row === i && headings[heading].editable" type="text"
              [name]="column[heading] + i" [(ngModel)]="column[heading].value">
          </div>
          <!-- Show Text END -->

          <!-- Show Action Icons -->
          <div *ngIf="column[heading]  && column[heading].icons" [class]="column[heading].class || ''">
            <div class="action_icons">
              <div class="action_multi_icons" [class.other-action-block]="!isLast"
                [class.always-show]="column[heading].icons.length <= 5">
                <div *ngFor="let icon of column[heading].icons" [class]="icon.parentClass">
                  <div class="iconn" [class.new-message]="icon.txt">
                    <i [class]="icon.class" [ngClass]="{
                                            'block-icon': icon.txt?.toString().length > 2,
                                            'text-icon':iconCheck(icon.class),
                                            'other-actions':!isLast,
                                            'show-on-hover':activeActionRow == i}"
                      (click)="icon.action && icon.action();" [title]="icon.title || ''">
                      <span *ngIf="icon.txt" class="icon-message">{{icon.txt}}</span>

                    </i>
                  </div>
                </div>
              </div>
              <div class="single_icon" *ngIf="isLast && column[heading].icons.length > 5">
                <i class="fa fa-ellipsis-v" (mouseover)="this.activeActionRow  = i"
                  (click)="activeActionRow == i ? activeActionRow = -1 :activeActionRow = i"></i>
              </div>
            </div>
          </div>

          <!-- {{column[heading].icons | json}} -->
          <!-- Show Action Icons END -->
        </td>
        <!-- Smart Table Value End -->
        <td class="action-box" *ngIf="edit.row !== -1 && edit.row ===  i">
          <i class="fa fa-check" (click)="saveEdit(column)"></i>
          <i class="fa fa-times" (click)="resetColumn()"></i>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- No Record Found -->
  <div *ngIf="headings && !columns.length">
    <div class="no-record-found">No Record Found</div>
  </div>
  <!-- No Record Found END -->
</div>

<!-- Pagination -->
<div class="pagination" *ngIf="settings.pagination || pages.count > 1">
  <!-- Records Per Page -->
  <div class="numer_list">
    <span>items per page: </span>
    <input type="text" class="customPage form-control" name="customPageNo" (keyup.enter)="customPage()"
      [(ngModel)]="pages.limit">
  </div>
  <!-- Records Per Page END -->

  <!-- Page Count -->
  <div class="pagination_links">
    <button class="mr-1" (click)="handlePagination(pages.active - 1)" [disabled]="pages.active == 1">Pre</button>
    <div class="number_div">
      <button *ngFor="let page of common.generateArray(pages.count)" (click)="handlePagination(page)"
        [ngClass]="page == pages.active ? 'active-page' : ''">{{page}}</button>
    </div>
    <button class="ml-1" (click)="handlePagination(pages.active + 1)"
      [disabled]="pages.active == pages.count">Next</button>
  </div>
  <!-- Page Count END -->
</div>
<!-- Pagination END -->

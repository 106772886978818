import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AssetRequestService } from './asset-request.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { validator } from 'src/app/dataType/commonInterface';
import { responseDataRequest } from 'src/app/dataType/assetInterface';
import { staff } from 'src/app/dataType/userInterface';

@Component({
  selector: 'app-asset-request',
  templateUrl: './asset-request.component.html',
  styleUrls: ['./asset-request.component.scss']
})
export class AssetRequestComponent implements OnInit {

  minimumTimestamp = new Date();
  assetRequest = {
    fromDate: null,
    toDate: null,
    requestingForName: null,
    requestForId: null,
    assetList: [],
    criticality: 'Medium',
    remarks: null,
  }
  requestingForId = null;
  crticalityList = [{ level: 'Low' }, { level: 'Medium' }, { level: 'High' }, { level: 'Critical' }];
  requestForId: string=null;
  requestForDisplayName: string =null;
  requestForList: staff[]=[];
  labelText: string;
  staffId: number=null;
  fromDateValidator: validator;
  toDateValidator: validator;
  assetValidator: validator;
  responseData: responseDataRequest[]=[];
  requestingForList= [{ name: 'User', id: null }, { name: 'Department', id: null }, { name: 'Group', id: null }];
  criticalityValidator: validator;
  requestingForValidator: validator;
  requestForValidator: validator;


  constructor(public common: CommonService,
    private activeModal: NgbActiveModal,
    private breadcrumbService: BreadcrumbService,
    private alertService: AlertService,
    public role: RoleService,
    public table: TableService,
    private assetRequestService: AssetRequestService,
    private api: ApiService) {
let breadcumbs = [
  { name: 'Home', url: '/user/dashboard' },
  { name: 'assets', url: null },
  { name: 'label', url: '/user/asset-request' },
];
this.breadcrumbService.getBreadcrumbs(breadcumbs);
this.getStaffTypeList();
}


  ngOnInit(): void {

  }

  getStaffTypeList() {
    let params = {
      allTypes: false
    };
    this.api.get('staff/get-staff-type-list', params).subscribe(
      (res: any) => {
        res.data.forEach(data => {
          this.requestingForList.push({ name: data.type_name, id: data.id });
        })

      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  getStaffList() {
    let params = {
      allStaff: false,
      staffTypeId: this.staffId
    }
    this.api.get('staff/all-staff', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.requestForList = res.data.map(item => ({ ...item, departments: JSON.parse(item.departments) })) || [];
        }
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    )
  }


  selectedRequestFor(event: any) {
    this.assetRequest.requestingForName = event.name;
    this.staffId = event.id;
    this.requestingForId = null;
    this.requestingFor();
  }
  requestingFor() {
    if (this.assetRequest.requestingForName == 'Department') {
      this.requestForDisplayName = 'dept_name';
      this.requestForId = 'dept_id';
      this.labelText = 'Choose Department';
      this.setRequestingForParameteres('department/get-department-list?allDepartments=' + false);
    }
    else if (this.assetRequest.requestingForName == 'User') {
      this.requestForDisplayName = 'userName';
      this.labelText = 'Choose User';
      this.requestForId = 'userId';
      this.setRequestingForParameteres('user/all_user?allUser=false');
    }
    else if (this.assetRequest.requestingForName == 'Group') {
      this.requestForDisplayName = 'groupName';
      this.requestForId = 'groupId';
      this.labelText = 'Choose Group';
      this.setRequestingForParameteres('group/all-group?plantType=false');
    } else {
      this.requestForDisplayName = 'first_name';
      this.requestForId = 'staff_id';
      this.labelText = 'Choose Staff';
      this.getStaffList();
    }
  }

  setRequestingForParameteres(url: string) {
    this.api.get(url).subscribe(
      (res: any) => {
        if (res.status) {
          this.requestForList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  selectedRequest(event: any) {
    this.requestingForId = event[this.requestForDisplayName];
    this.assetRequest.requestForId = event[this.requestForId];
  }

  requestForIdLabel() {
    return { [this.requestForDisplayName]: this.requestingForId };
  }

  resetWorkType(): void {
    this.assetRequest.fromDate = null;
    this.assetRequest.toDate = null;
    this.requestingForId = null;
    this.assetRequest.requestingForName = null;
    this.assetRequest.requestForId = null;
    this.assetRequest.remarks = null;
    this.assetRequest.assetList = [];
    this.assetRequest.criticality = null;
    this.fromDateValidator = null;
    this.toDateValidator = null;
    this.criticalityValidator = null;
    this.assetValidator = null;
    this.requestForValidator = null;
    this.requestingForValidator = null;
  }

  checkEdit(company_id: any, plant_id: any) {
    if (this.role.user_role == "AXESTRACK") {
      return true;
    }
    else if (this.role.user_role == "AXESTRACKUSER" || this.role.user_role == "COMPANYADMIN") {
      return (company_id == 1) ? false : true;
    } else {
      return (company_id == 1 || !plant_id) ? false : true;
    }
  }




  getRequestedAssets() {
    this.fromDateValidator = this.common.nullValidator(
      this.assetRequest.fromDate,
      'From Date Mandatory'
    );
    this.toDateValidator = this.common.nullValidator(
      this.assetRequest.toDate,
      'To Date Mandatory'
    );
    if (!this.fromDateValidator.error && !this.toDateValidator.error) {
      let params = {
        startDate: this.assetRequest.fromDate,
        endDate: this.assetRequest.toDate
      }
      this.assetRequest.assetList = [];
      this.assetRequestService.getRequestedAsset(params).subscribe((res: any) => {
        if (res.status) {
          this.responseData = res.data;
        }
      }, (err: any) => {
        console.log("Error", err);
      });
    }
  }

  selectedAssets(event) {
    this.assetValidator = null;

    this.assetRequest.assetList.push(event[event.length - 1].asset_id);
  }


  formValidator() {
    this.fromDateValidator = this.common.nullValidator(
      this.assetRequest.fromDate,
      'From Date Mandatory'
    );
    this.toDateValidator = this.common.nullValidator(
      this.assetRequest.toDate,
      'To Date Mandatory'
    );
    this.criticalityValidator = this.common.nullValidator(
      this.assetRequest.criticality,
      'Priority Mandatory'
    );
    this.assetValidator = this.common.nullValidator(
      this.assetRequest.assetList,
      'Asset Mandatory'
    );
    this.requestingForValidator = this.common.nullValidator(
      this.assetRequest.requestingForName,
      'Requesting For Mandatory'
    );
    this.requestForValidator = this.common.nullValidator(
      this.assetRequest.requestForId,
      'Field Mandatory'
    );
    if (!this.fromDateValidator.error && !this.toDateValidator.error && !this.criticalityValidator.error && !this.assetValidator.error && !this.requestingForValidator.error && !this.requestForValidator.error) {
      this.saveAssetRequest();
    }
  }

  closeModal() {
    this.activeModal.close();
    this.resetWorkType();
  }

  private saveAssetRequest() {
    this.common.loading = true;
    this.assetRequestService.saveAssetRequest(this.assetRequest).subscribe((response) => {
      this.common.loading = false;
      if (response.status) {
        this.alertService.success(response.message);
        this.resetWorkType();
        this.closeModal();
        this.alertService.info(response.message);
      } else {
        this.alertService.error(response.message);
      }

    }, (error) => {
      this.common.loading = false;
      console.log(error);
    });
  }
}


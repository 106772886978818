import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { validator } from 'src/app/dataType/commonInterface';
import { CompanyData, PlantData, Supplier } from 'src/app/dataType/assetInterface';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Supplier List');

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

//globla variables
  plantLevelRoles = ['AXESTRACKUSER' , 'COMPANYUSER' , 'PLANTADMIN', 'PLANTUSER'];
  supplier={
    supplierId:null,
    supplierFirstName:'',
    supplierLastName:'',
    supplierContactNo:'',
    supplierEmail:'',
    supplierAddress:'',
    status:'ACTIVE',
    plantId:null,
    companyId:null
  }
  supplierList:Supplier[]=[];
  plantName:string=null;
  companyName:string =null;
  companyList: CompanyData[]= [];
  plantList: PlantData[]= [];
  buttonName:string='save';

  //validator
  supplierFirstNameValidator:validator=null;
  supplierLastNameValidator:validator=null;
  supplierContactNoValidator:validator=null;
  supplierEmailValidator: validator = null;

// constructor
  constructor(
    public userService: UserService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public role: RoleService,
    public plantService : PlantService,
    public alert : AlertService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Assets', url: null },
      { name: 'Supplier', link: '/user/supplier' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
   }

   // ng hooks
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
    this.getSupplier();
    if(this.role.user_role=='AXESTRACK'){
      this.getCompany();
    }
    if(this.role.user_role=='COMPANYADMIN'){
      this.getPlants(this.userService._loggedInUser.companyId);
    }
  }


  //custom functions

  selectedCompany(event:any){
    this.supplier.companyId=event.companyId;
    this.companyName=event.companyName;
    this.getPlants(event.companyId);
  }
  selectedPlant(event:any){
    this.supplier.plantId=event.plantId;
    this.plantName=event.plantName;
  }

  getCompany(){
    this.common.loading=true;
    let params={
      all_company:false
    }
    this.api.get('company/all_company',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
      );
  }


  getPlants(id?:number){
      this.common.loading=true;
      let param={
      id:id
      };
      this.plantService.getPlantById(param).subscribe(
      (res:any)=>{
        this.common.loading=false;
        this.plantList=res.data;
      },
      (err:any)=>{
        console.log('Error',err);
        this.common.loading=false;
      }
      );
  }

  getSupplier(){
    this.common.loading=true;
    let param={
      allSuppliers:true
    }
    this.api.get("suppliers/get-suppliers",param).subscribe(
      (res:any)=>{
        this.common.loading = false;
        this.supplierList=res.data;
        this.renderTable();
      },
      (err:any)=>{
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  reset(){
      this.supplier={
        supplierId:null,
        supplierFirstName:'',
        supplierLastName:'',
        supplierContactNo:'',
        supplierEmail:'',
        supplierAddress:'',
        status:'ACTIVE',
        plantId:null,
        companyId:null
      }
      this.plantList=null;
      this.companyName=null;
      this.plantName=null;
      this.supplierFirstNameValidator=null;
      this.supplierLastNameValidator=null;
      this.supplierContactNoValidator=null;
      this.supplierEmailValidator=null ;
      this.buttonName='save';
    }

  saveSupplier(){
    let param={
      supplierId:this.supplier.supplierId,
      supplierFirstName:this.supplier.supplierFirstName.trim(),
      supplierLastName:this.supplier.supplierLastName.trim(),
      supplierContactNo:this.supplier.supplierContactNo.trim(),
      supplierEmail: this.supplier.supplierEmail.trim(),
      supplierAddress:this.supplier.supplierAddress.trim(),
      status:this.supplier.status,
      companyId:this.supplier.companyId,
      plantId:this.supplier.plantId,
      userRole:this.role.user_role
    }
    this.api.post('suppliers/save-supplier',param).subscribe(
      (res: any) => {
        if(res.status){
          if(res.message=="Name Already Exists"){
            this.alert.warn(res.message);
          }
          else{
            this.alert.success(res.message);
            this.reset();
          }

        }
        this.common.loading = false;
        this.getSupplier();
        console.log('success');
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  viewSupplierDetails(row?:any){
    const SupplierDetails={
      Company_name: row.company_name,
      plant_name: row.plant_name,
      status: row.status,
      supplier_address: row.supplier_address,
      supplier_contact_no: row.supplier_contact_no,
      supplier_email: row.supplier_email,
      supplier_first_name: row.supplier_first_name,
      supplier_last_name: row.supplier_last_name,
    }
    this.common.params = { details: [SupplierDetails], title: 'Supplier Details' };

    this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

   editSupplier(row?: any) {
    this.reset();
    this.common.gotoTop();
    this.buttonName = 'update';
    this.supplier.supplierId = row.supplier_id;
    this.supplier.supplierFirstName = row.supplier_first_name;
    this.supplier.supplierLastName = row.supplier_last_name;
    this.supplier.supplierContactNo = row.supplier_contact_no;
    this.supplier.supplierEmail = row.supplier_email;
    this.supplier.supplierAddress = row.supplier_address;
    if(!(row.company_id==1 && row.plant_id==1)){
      this.plantName = row.plant_name;
      this.companyName = row.company_name;
    }
    this.role.user_role=='AXESTRACK'?this.getPlants(row.company_id):(this.role.user_role=="COMPANYADMIN"?this.getPlants(this.userService._loggedInUser.companyId):null);
}



  confirmDeleteSupplier(row?:any){
    this.common.params = {
      title: 'Delete Supplier ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row.supplier_name +
        ' ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });
  }


  delete(row?:any){
    let param={
      supplierId:row.supplier_id
    }
    this.api.get("suppliers/delete-supplier",param).subscribe(
      (res:any)=>{
        this.common.loading=false;
        if(res.status){
          if(res.message=="Supplier Mapped to Some Asset"){
            this.alert.warn(res.message);
          }
          else{
            this.alert.warn(res.message);
          }
          this.getSupplier();
        }
      },
      (err:any)=>{
        this.common.loading = false;
        console.error('Error: ', err);
      }
    )
  }


  formValidator(){
      this.supplierFirstNameValidator=this.common.nullValidator(
        this.supplier.supplierFirstName.trim(),'First Name Mandatory'
      );
      this.supplierLastNameValidator=this.common.nullValidator(
        this.supplier.supplierLastName.trim(),'Last Name Mandatory'
      );
      this.supplierContactNoValidator=this.common.mobileNoValidator(
        this.supplier.supplierContactNo.trim(),null
      );
      if (this.supplier.supplierEmail != null && this.supplier.supplierEmail != '') {
        this.supplierEmailValidator= this.common.emailValidator(
          this.supplier.supplierEmail.trim(),
          'Invalid Email Id'
        );
      }
      if(!this.supplierFirstNameValidator.error&&
        !this.supplierLastNameValidator.error&&
        !this.supplierContactNoValidator.error&&
        !this.supplierEmailValidator.error){
          this.saveSupplier();
        }
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { UserService } from 'src/app/@core/services/user.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { TableService } from '../../@core/services/table.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-field-method-mapping',
  templateUrl: './plant-field-method-mapping.component.html',
  styleUrls: ['./plant-field-method-mapping.component.scss'],
})
export class PlantFieldMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 3, 'Action Dashboard');
  MethodList: any = [];
  FieldList: any = [];
  ButtonName = 'save';
  companyId = null;
  companyName = null;
  plantId = '';
  plantName = null;
  selectedLocalMethodIds: any = [];
  selectedGlobalMethodIds: any = [];
  selectedGlobalMethodNames: any = [];
  selectedFieldName = null;
  selectedFieldId = null;
  selectedMethodName = null;
  selectedMethodId = null;
  fieldDataMapping: any = [];
  plantMethodMappingByPlantId: any = [];
  companyList: any = [];
  plantList: any = [];
  mappingType = 1;
  fieldMappingId: any = null;

  constructor(
    public alertService: AlertService,
    public common: CommonService,
    private modalService: NgbModal,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public plantService: PlantService,
    public role: RoleService,
    private table: TableService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Fields', url: null },
      { name: 'Method-Field-Mapping', url: '/user/method-field-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.plantId = this.userService._loggedInUser.plantId;
    if (this.role.user_role == 'AXESTRACK') {
      this.mappingType = 1;
    } else {
      this.companyId = this.userService._loggedInUser.companyId;
      this.mappingType = 0;
    }
    this.getFieldMethodList();
    this.getFieldData();
    this.getFieldDatabaseList();
  }

  ngOnInit(): void {
    this.getCompanyList();
  }
  getActiveTab(event) {
    this.dtOptions = this.table.options(10, 4, 'Method-Database-Mapping');
    this.dtTrigger.unsubscribe();
    this.dtTrigger.next();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  selectCompany(event) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantListByCompanyId(this.companyId);
  }

  selectPlant(event) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getFieldMethodList();
  }

  getCompanyList() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getPlantListByCompanyId(companyId) {
    this.common.loading = true;
    this.api.get('plant/all_plants?id=' + companyId).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantList = res.data;
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getFieldMethodList() {
    this.common.loading = true;
    this.api
      .get('fields/get-field-method-list?plantId=' + this.plantId)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.MethodList = res.data;
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getFieldDatabaseList() {
    this.common.loading = true;
    this.api.get('fields/list-database-fields').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.FieldList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getFieldData() {
    this.common.loading = true;
    this.api
      .get('fields/get_field_mapping_data?plantId=' + this.plantId)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.fieldDataMapping = res.data;
          this.renderTable();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  selectedField(event: any) {
    this.selectedFieldId = event.databaseFieldId;
    this.selectedFieldName = event.databaseFieldName;
  }

  selectedFieldMethod(event: any) {
    this.selectedMethodName = event.method_name;
    this.selectedMethodId = event.id;
  }

  save() {
    let params = {
      fieldMappingId: this.fieldMappingId,
      fieldId: this.selectedFieldId,
      methodId: this.selectedMethodId,
      plantId: this.plantId,
      fieldMappingType: this.mappingType,
    };
    this.common.loading = true;
    this.api.post('fields/save_field_method_mapping', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.reset();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  reset() {
    this.mappingType = 0;
    this.selectedFieldName = null;
    this.selectedFieldId = null;
    this.selectedMethodName = null;
    this.selectedFieldName = null;
    this.selectedMethodName = null;
    this.selectedFieldId = null;
    (this.fieldMappingId = null), (this.selectedMethodId = null);
    if (this.role.user_role == 'AXESTRACK') {
      this.plantId = this.userService._loggedInUser.plantId;
      this.plantName = null;
      this.companyId = null;
      this.companyName = null;
      this.mappingType = 1;
      this.plantList = null;
    }
    this.ButtonName = 'save';
    this.getFieldData();
    this.databaseFieldNameValidator = null;
    this.methodNameValidator = null;
  }

  viewActionActionMapping(item?: any) {
    let updatedItem = item;
    this.common.params = {
      details: [updatedItem],
      title: 'Method-Field-Mapping Details',
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  editActionMapping(item?: any) {
    this.common.gotoTop();
    this.reset();
    this.mappingType = item.mappingtype;
    if (this.role.user_role == 'AXESTRACK') {
      this.plantId = item.plant_id;
      this.plantName = item.plant_name;
      this.companyId = item.company_id;
      this.companyName = item.company_name;
    }
    this.fieldMappingId = item.field_mapping_id;
    this.selectedFieldName = item.field_name;
    this.selectedMethodName = item.method_name;
    this.selectedFieldId = item.field_id;
    this.selectedMethodId = item.method_id;
    this.ButtonName = 'update';
    this.databaseFieldNameValidator = null;
    this.methodNameValidator = null;
  }

  confirmAlert(item?: any) {
    this.common.params = {
      title: 'Delete Field Method Mapping ',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteFieldMethod(item);
      }
    });
  }

  deleteFieldMethod(item?: any) {
    let params = {
      fieldMappingId: item.field_mapping_id,
      fieldId: item.field_id,
      methodId: item.method_id,
      plantId: item.plant_id,
      fieldMappingType: item.mappingtype,
      toDelete: 1,
    };
    this.common.loading = true;
    this.api.post('fields/save_field_method_mapping', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.reset();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  databaseFieldNameValidator: any;
  methodNameValidator: any;
  plantNameValidator: any;
  companyNameValidator: any;

  formValidator() {
    this.databaseFieldNameValidator = this.common.nullValidator(
      this.selectedFieldName,
      'Database Field Name Mandatory'
    );
    this.methodNameValidator = this.common.nullValidator(
      this.selectedMethodName,
      'Method Name Mandatory'
    );
    this.plantNameValidator = this.common.nullValidator(
      this.plantId,
      'Plant Name is Mandatory'
    );
    this.companyNameValidator = this.common.nullValidator(
      this.companyId,
      'Company Name is Mandatory'
    );

    if (
      !this.methodNameValidator.error &&
      (this.mappingType == 1 ||
        (!this.plantNameValidator.error && !this.companyNameValidator.error)) &&
      !this.databaseFieldNameValidator.error
    ) {
      this.save();
    }
  }
}

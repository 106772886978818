<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"group_role_mapping" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"group_role_type" | customTranslate}} </label>
                  <select name="user_type" id="#user_type" class="form-control" [(ngModel)]="groupRoleType"
                    (change)="selectedGroupType()">
                    <option *ngFor="let type of groupTypesList[role.user_role]" [value]="type">{{type}}</option>
                  </select>
                  <div class="error" *ngIf="typeValidator && typeValidator.error">
                    <span>{{typeValidator.msg}} </span>
                  </div>
                </div>
                <div
                  *ngIf="(role.user_role=='AXESTRACK')&&(groupRoleType=='COMPANYADMIN'||groupRoleType=='COMPANYUSER'||groupRoleType=='PLANTADMIN'||groupRoleType=='PLANTUSER')"
                  class="form-group col-md-3 col-sm-12">
                  <label for="my-input" class="required">{{"company" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="companyData" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyValidator && companyValidator.error">
                    <span>{{companyValidator.msg}} </span>
                  </div>
                </div>

                <div
                  *ngIf="(role.user_role=='AXESTRACK' || role.user_role=='COMPANYADMIN')&&(groupRoleType=='PLANTADMIN'||groupRoleType=='PLANTUSER')"
                  class="form-group col-md-3 col-sm-12">
                  <label for="my-input" class="required">{{"plant" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantValidator && plantValidator.error">
                    <span>{{plantValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-md-3 col-sm-12">
                  <label class="required">{{"choose_group" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="getGroup($event)" [data]="groupList" display="groupName"
                    placeholder="Select Group" [preSelected]="{groupName:groupName}" inputId="name">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="groupValidator && groupValidator.error">
                    <span>{{groupValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="row">


                <div class="form-group col-md-12 col-sm-12" *ngIf="roleList.length>0">
                  <label>{{"roles" | customTranslate}}</label>
                  <div class="row pl-2">
                    <div class="col-md-4 mb-2" *ngFor="let role of roleList; let i= index">
                      <ng-container><label class="plant_name">{{role.plant_name}}</label></ng-container>
                      <ng-container *ngIf="role.plant_id==null"><label
                          class="plant_name">{{role.company_name}}</label></ng-container>
                      <ng-container *ngIf="this.groupRoleType!='AXESTRACKUSER'&& this.groupRoleType!='COMPANYUSER'">
                        <div class="ml-3" *ngFor="let item of role.roles; let i= index">
                          <mat-radio-group [(ngModel)]="role.accessId" (change)="selectedRole($event)">
                            <mat-radio-button class="roles" [value]="item.role_id" color="primary"
                              [checked]="selectedRoleId == item.role_id ">
                              {{item.role_name}} </mat-radio-button> </mat-radio-group>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="this.groupRoleType=='AXESTRACKUSER'|| this.groupRoleType=='COMPANYUSER'">
                        <div class="group_div" *ngFor="let item of role.roles; let i= index">
                          <input value="{{item.role_id}}" [checked]="getRoleStatus(item.role_id)"
                            (change)="selectedRole(item.role_id)" type="checkbox" color="primary">{{item.role_name}}
                        </div>
                      </ng-container>
                      <div class="error" *ngIf="roleValidator && roleValidator.error">
                        <span>{{roleValidator.msg}} </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row form-group action-block">
                  <div class="btn-block">
                    <button class="btn btn-warning" (click)="resetData()"> {{"reset" | customTranslate}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{"save" | customTranslate}}
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/@core/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends ApiService {


  saveCompany(params: any): Observable<any> {
    let apiSubUrl = "company/add_company";

    return super.post(apiSubUrl, params);
  }

  getCompany(): Observable<any> {
    let apiSubUrl = "company/all_company";

    return super.get(apiSubUrl);
  }

  getSector(): Observable<any> {
    let apiSubUrl = "company/all_sector";

    return super.get(apiSubUrl);
  }

}

<table class="custom-table" *ngIf="dataheading.length">
  <thead>
    <tr>
      <th *ngFor="let item of dataheading">{{item}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of datavalues">
      <td *ngFor="let value of item"> {{value}} </td>
    </tr>
  </tbody>
</table>

<!-- <app-data-not-found *ngIf="!dataheading.length"></app-data-not-found> -->

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { StageService } from '../stage/stage.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-vehicle-report',
  templateUrl: './vehicle-report.component.html',
  styleUrls: ['./vehicle-report.component.scss']
})
export class VehicleReportComponent implements OnInit {

  startDate: any = new Date(new Date().setDate(new Date().getDate() - 7));
  endDate: any = new Date();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Tat Report');
  listOfProcess: any = [];
  processName: any = null;
  processId: any = null;
  stageData: any = [];
  stageName: any = null;
  stageId: any = null;
  plantId:any=null;
  plantData:any[]=[];
  vehicleReportData:any[] = [];
  headers:any[] =[];
  expected_tat: any = '00:00';
  tripStageTatList:any[]=[];
  totalInbetweenTatList:any[]=[];

  constructor(
    public table: TableService,
    public common: CommonService,
    public api: ApiService,
    public alert: AlertService,
    public stageService: StageService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public modalService: NgbModal,
    public role: RoleService,
    public plantService: PlantService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Vehicle Report', link: '/user/vehicle-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit() {
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    // this.getTatReport(1);

    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.getAllProcess();
    }
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.getAllProcess();
  }

  selectedProcess(event: any) {
    this.processId = event.processId;
    this.processName = event.processName;
    this.getVehicleReport();

  }

  resetPage() {
    this.tripStageTatList = [];
    this.totalInbetweenTatList = [];
  }

  getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.plantId,
    };
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.listOfProcess = res.data;
        this.processId = this.listOfProcess[0].processId;
        this.processName = this.listOfProcess[0].processName;

        this.getVehicleReport();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getVehicleReport() {
    if (this.startDate > this.endDate) {
      this.alert.warn('Start Date Should be Smaller than End Date!!');
      return;
    }
    this.common.loading = true;
    let params = {
      fromDate: this.startDate,
      toDate: this.endDate,
      processId: this.processId,
    };

    this.api.get('report/get-vehicle-report-by-processId', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.resetPage();
        this.vehicleReportData = res.data.map(item => ({ ...item, stage_data: JSON.parse(item.stage_data) })) || [];
        this.headers = this.vehicleReportData[0].stage_data;
        this.dayWiseTat(this.vehicleReportData);
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    );
  }

  dayWiseTat(list: any) {
  console.log('list: ', list);
    this.tripStageTatList = [];
    list.forEach((el) => {
      let sum = 0;
      el.stage_data.forEach((e) => {
        let arr = e.actual_tat.split(':');
        let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
        sum = sum + tat;
      });
      let arr2 = el.trip_total_tat.split(':');
      let tat2 = Number(arr2[0]) * 3600 + Number(arr2[1]) * 60;
      let diffInSeconds = tat2- sum;
      let numOfHours2 = (diffInSeconds / 3600).toString().split('.');
      diffInSeconds = diffInSeconds % 3600;
      let numOfMinutes2 = (diffInSeconds / 60).toString().split('.');
      this.totalInbetweenTatList.push(numOfHours2[0].padStart(2, '0') + ':' + numOfMinutes2[0].padStart(2, '0'));
      console.log('this.totalInbetweenTatList: ', this.totalInbetweenTatList);

      let seconds = sum;
      let numOfHours = (seconds / 3600).toString().split('.');
      seconds = seconds % 3600; // Remaining second after getting the hours
      let numOfMinutes = (seconds / 60).toString().split('.');
      let total = numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
      this.tripStageTatList.push(total);
    });
    this.getExpectedTat();
  }

  getExpectedTat() {
    let sum = 0;
    this.vehicleReportData[0].stage_data.forEach((e) => {
      let arr = e.expected_tat.split(':');
      let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
      sum = sum + tat;
    });
    let seconds = sum;
    let numOfHours = (seconds / 3600).toString().split('.');
    seconds = seconds % 3600; // Remaining second after getting the hours
    let numOfMinutes = (seconds / 60).toString().split('.');
    let total =
      numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
    this.expected_tat = total;
  }

}

<div class="card">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{"Reserve"|customTranslate}}
      </h3>
    </div>
    <div class="button-class">
      <i class="fas fa-square blue-box mr-1"></i> {{"Assigned"|customTranslate}}
      <i class="fas fa-square green-box mr-1 ml-2"></i> {{"CheckedOut"|customTranslate}}
    </div>
  </div>
  <!--reservations-->
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <full-calendar [options]="calendarOptions">
        </full-calendar>
      </div>
    </div>
  </div>
</div>
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { MapService } from 'src/app/@core/services/map.service';
import _ from 'lodash';

declare var google: any;

@Component({
  selector: 'app-user_path_tracking',
  templateUrl: './user_path_tracking.component.html',
  styleUrls: ['./user_path_tracking.component.scss'],
})
export class User_path_trackingComponent implements OnInit {
  users: any[] = [];
  geofenceId: number = null;
  userLogs: any[] = [];
  dueDate = new Date().toISOString().split('T')[0];
  userId: number = null;
  userType: string;

  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    public table: TableService,
    public userService: UserService,
    public alertService: AlertService,
    public api: ApiService,
    public role: RoleService,
    private mapService: MapService
  ) {}

  @Input() extEntityId: any = null;
  @Input() extEntityType: any = null;

  ngOnInit() {
    if (this.extEntityId == null) {
      this.getUsers();
      this.common.sidebarToggled(this.common.menuToggled);
    }else {
      this.selectedUser({userId:this.extEntityId,userType:this.extEntityType});
    }


    // this.setCircularBar();
  }
  @ViewChild('detailModal') detailModal: ElementRef;
  @ViewChild('exampleModal') modal: ElementRef;
  @ViewChild('exampleModal2') modal2: ElementRef;
  @ViewChild('exampleModal3') modal3: ElementRef;

  total_user_card = {
    total_user: 0,
    present_user: 0,
    outside_user: 0,
    percentage: 0,
    outsideData: [],
  };

  detail_info_card = {
    unauthorized_movement: 0,
    resolved_alert: 0,
    total_alert: 0,
    total_visitors: 0,
    sos_alert: 0,
    unauthorisedData: [],
    visitorData: [],
  };





  getUsers() {
    this.common.loading = true;
    let apiUrl = 'user/all_user_global_company_plant';
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.users = res.data;
          this.getAllStaff();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllStaff() {
    this.common.loading = true;
    let apiUrl = 'staff/all-staff?allStaff=false';
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res?.data?.length) {
          res.data.forEach((el) => {
            let obj = {
              userId: el.staff_id,
              userName: el.first_name + ' ' + el.last_name,
              userType:'STAFF'
            };
            this.users.push(obj);
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

    selectedUser(event: any) {
      this.userType = event.userType != 'STAFF' && event.userType != 'ASSET' ? 'USER' : event.userType;
      this.getUserLogs(event.userId, this.dueDate,this.userType);
    }

    selectedDate(date:any){
      if(this.userId){
        this.getUserLogs(this.userId, date,this.userType);
      }
    }

  getUserLogs(entityId, dueDate,entityType) {
    this.common.loading = true;
    let params = {
      entity_id:entityId,
      dueDate:dueDate,
      Type:entityType
    }
    this.api
      .get(
        'user-tracking/get-User-asset-all-logs',params
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.userLogs = res.data;
            this.userLogs.map(ele=>{
              ele['zone_name']=this.convertToString(ele['zone_name']);
              return ele
            })
            // this.userLogs['zone_name']=this.convertToString(this.userLogs['zone_name']);
            this.createPathfromUserLogs(this.userLogs);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
        }
      );
  }

  convertToString(userLogs: any[]): string {
    if (userLogs.length === 0) {
      return 'N/A';
    }

    const string = userLogs.join(',');
    return string;
  }

  // from here map view

  allGeoFence: any;
  modalDetailTitle: string = '';
  detailData: any[] = [];
  detailAllData: any[] = [];
  detailType: any;
  path: any[];
  isTotalUserData: boolean = false;

  getGeoFence(hardwareData: any) {
    this.common.loading = true;
    let apiUrl = 'geofence/get-plant-geofence-by-processId';
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.allGeoFence = res.data || [];
        if (this.allGeoFence.length) {
          this.allGeoFence.forEach((element) => {
            element.plantLatLong = this.getSiteGeoFenceLatLong(
              element,
              hardwareData
            );
          });

          // setTimeout(() => {
          this.viewGeoFence(this.allGeoFence[0], hardwareData);
          // }, 2000);
        } else {
          this.mapService.clearAll();
        }
      },
      (err) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  ngAfterViewInit() {
    this.mapService.mapIntialize('map-2');
    setTimeout(() => {
      this.mapService.setMapType(0);
    }, 2000);
  }

    viewGeoFence(row: any, hardwareData:any,event = false) {
      let latLong = { lat: row.lat, lng: row.long };
      this.clearMapServices();
      this.mapService.centerAt(latLong);
      this.mapService.zoomAt(latLong, 14);
      this.mapService.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      this.LoadGeoFence(row.plantLatLong, event);
      // let plantGeoFence = row.plantLatLong.find((item) => item.type == 5).data;
      // plantGeoFence.forEach((element) => {
      //   this.mapService.setBounds(
      //     this.mapService.createLatLng(element.lat, element.lng)
      //   );
      // });
      let thisClass = this;
      this.mapService.markers.forEach((marker: any) => {
        this.mapService.addListerner(marker, 'click', function (event: any) {
          this.mapDetailId = marker.id;
          this.mapDetailLabel = marker.label.text;
          thisClass.modalDetailTitle = marker.label.text;
          thisClass.detailData = row.plantLatLong.find(
            (item) =>
              item.id == this.mapDetailId && item.label == this.mapDetailLabel
          ).trips;
          thisClass.detailAllData = row.plantLatLong.find(
            (item) =>
              item.id == this.mapDetailId && item.label == this.mapDetailLabel
          ).trips;
          thisClass.detailType = marker.type;

        setTimeout(() => {
          let modal = document.getElementById('detailModal');
          modal.classList.add('d-block');
        }, 50);
      });
    });
  }

  closeModal(res: boolean) {
    let modal = document.getElementById('detailModal');
    modal.classList.remove('d-block');
  }

  LoadGeoFence(data, event = false) {
    let latLngsArray = [];
    if (data.length > 0) {
      latLngsArray.push(data);
      this.mapService.createPlantGeoFence(data, event, false);
    }
  }

  latLng: any = [];
  markers: any = [];
  userPath: any;

  addArrowsBetweenMarkers(pathCoordinates) {
    for (let i = 0; i < pathCoordinates.length - 1; i++) {
      const arrow = new google.maps.Marker({
        position: this.calculateMidpoint(pathCoordinates[i + 1]),
        map: this.mapService.map,
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 4, // Adjust the size of the arrow
          fillColor: 'black',
          fillOpacity: 1,
          strokeColor: '#FFFFFF', // White border color
          strokeWeight: 1, // Thickness of the border
          strokeOpacity: 1,
          rotation: google.maps.geometry.spherical.computeHeading(
            this.mapService.createLatLng(
              pathCoordinates[i].lat,
              pathCoordinates[i].lng
            ),
            this.mapService.createLatLng(
              pathCoordinates[i + 1].lat,
              pathCoordinates[i + 1].lng
            )
          ), // Calculate the rotation angle for the arrow
        },
      });

      this.markers.push(arrow);
    }
  }

  calculateMidpoint(p2) {
    return {
      lat: p2.lat,
      lng: p2.lng,
    };
  }

  createPathfromUserLogs(userLogs){
    if (this.markers) {
      this.markers.forEach(marker => {
        marker.setMap(null);
      });
      this.markers = [];
    }

    if (this.userPath) {
      this.userPath.setMap(null);
      this.userPath=null;
    }

    const lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    };

      this.latLng=userLogs.map((log)=>({
        lat:parseFloat(log.latitude),
        lng:parseFloat(log.longitude)
      }));
      this.userPath = new google.maps.Polyline({
        path: [],
        geodesic: true,
        icons: [
              {
                icon: lineSymbol,
                offset: "100%",
              },
            ],
        map: this.mapService.map
      });



      // userPath.setMap(this.mapService.map);
      let index = 0;
      const interval = setInterval(() => {
        if (index < this.latLng.length) {
          this.userPath.getPath().push(this.mapService.createLatLng(this.latLng[index].lat,this.latLng[index].lng));
          index++;
        } else {
          clearInterval(interval); // Stop the interval once all points are added
        }
      }, 200);


      this.addArrowsBetweenMarkers(this.latLng);


      if(this.latLng.length>0){
        const firstLatLng=this.latLng[0];
        this.mapService.map.setCenter(firstLatLng);
        this.mapService.map.setZoom(14);
      }

      this.latLng.forEach((latlng,index)=>{
        const marker=new google.maps.Marker({
          position:latlng,
          map: this.mapService.map
        });

        const infowindow=new google.maps.InfoWindow({
          content:`
          <div style="font-family: Arial, sans-serif; font-size: 14px; margin: 0; padding: 0;">
          <div style="font-weight: bold; background-color: #f1f1f1; padding: 5px; border-bottom: 1px solid #ccc; margin: 0;">
            ${(this.userLogs[index].zone_name)!=''?(this.userLogs[index].zone_name):'N/A'}
          </div>
          <div style="padding: 5px; margin: 0;">
             ${this.userLogs[index].end_time}
          </div>
        </div>
          `

        });

        // marker.addListener('click',()=>{

        // })
        infowindow.open(this.mapService.map,marker);
        // marker.addListener('mouseout',()=>{
        //   infowindow.close();
        // })

        if(index==this.latLng.length-1){
          marker.setAnimation(google.maps.Animation.BOUNCE);
        }

        this.markers.push(marker);

      });
  }


  getSiteGeoFenceLatLong(data: any, user_data: any) {
    let latLongs = [];
    return latLongs;
  }

  getLatLongFromPolygon(str) {
    let latLong = [];

    if (!str.includes('EMPTY') && !str.includes('OLLECTION EMP')) {
      let latLongString = String(str).split(',');
      latLongString.forEach((item) => {
        let latLongs = item.split(' ').map((item) => Number(item));
        latLong.push({ lat: latLongs[0], lng: latLongs[1] });
      });
    }
    return latLong;
  }
  clearMapServices() {
    this.mapService.clearAll();
    this.mapService.resetMarker();
    this.mapService.resetPolygons();
    this.mapService.clearAll();
    this.mapService.resetMarker();
    this.path = [];
  }
}

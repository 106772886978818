import { Component, Input, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import lodash from 'lodash';
import { ChartDataSets} from 'chart.js';
import {  AssetDepreciation, depreciationData } from 'src/app/dataType/assetInterface';
import { validator } from 'src/app/dataType/commonInterface';
import { StaticChartOptions } from './chartData';
import Chart from 'chart.js';

declare var $ : any;

@Component({
  selector: 'app-asset-depreciation',
  templateUrl: './asset-depreciation.component.html',
  styleUrls: ['./asset-depreciation.component.scss']
})
export class AssetDepreciationComponent implements OnInit {
  @Input() public assetId:number;

  assetDepreciation:any = {
    id:null,
    depreciableCost:null,
    salvageValue:null,
    assetLifeInMonths:null,
    depreciationType:null,
    dateOfAcquired:null
  }
  assetDepreciationList :AssetDepreciation[] = [];
  mergedData:depreciationData[]=[];
  groupedData:Record<string,any[]>;
  public areaChartData: ChartDataSets[] = []
  depreciationChart?:Chart=null

  //validatory
  depreciableCostValidator: validator = null;
  salvagevalueValidator: validator = null;
  assetLifeInMonthsValidator: validator = null;
  depreciationTypeValidator: validator = null;
  dateOfAcquiredValidator: validator = null;


  constructor(
    private api: ApiService,
    public table: TableService,
    public common: CommonService,
    public modalService: NgbModal,
    public alertService :AlertService,
    ) {
   }

  ngOnInit() {
  }
  ngAfterViewInit(){
    this.getAssetDepreciationList();
  }

  saveAssetDepreciation(){
    this.common.loading=true
    let data = {
      depreciationId:this.assetDepreciation.id,
      assetId : this.assetId,
      depreciableCost:this.assetDepreciation.depreciableCost,
      salvageValue:this.assetDepreciation.salvageValue,
      assetLifeInMonths:this.assetDepreciation.assetLifeInMonths,
      depreciationType:this.assetDepreciation.depreciationType,
      dateOfAcquired:this.assetDepreciation.dateOfAcquired
    }
    this.api.post('asset-depreciation/save-asset-depreciation',data).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading=false;
          this.alertService.success(res.message);
          this.common.loading = false;
          this.reset();
          $('#exampleModal').modal('hide');
          this.getAssetDepreciationList();

        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getAssetDepreciationList(){
    this.common.loading=true;
    let params={
      assetId:this.assetId
    }
    this.api.get('asset-depreciation/get-asset-depreciation',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.assetDepreciationList = res.data;
          console.log('this.assetDepreciationList: ', this.assetDepreciationList);
          if(res.data.length){
          this.groupedData=lodash.groupBy(this.assetDepreciationList[0].depreciationData,'year');

          for(const year in this.groupedData){
              const yeardata=this.groupedData[year];
              const decData=yeardata.find((data:any)=>data.month==='DECEMBER')
              let totalDepreciationExp: number = 0
              yeardata.forEach(element => {
                totalDepreciationExp += element.depreciationExpense
              });
              if(decData){
                 this.mergedData.push({...decData ,depreciationExpense : parseFloat(totalDepreciationExp.toFixed(2))});
              }
              else{
                this.mergedData.push({...yeardata[yeardata.length-1] ,depreciationExpense :parseFloat(totalDepreciationExp.toFixed(2))});
              }
          }


        this.mergedData.splice(0, 0, {
          bookValue: this.assetDepreciationList[0].depreciationData[0].bookValue + this.assetDepreciationList[0].depreciationData[0].accumulatedDepreciation,
          month: this.assetDepreciationList[0].depreciationData[0].month,
          year: this.assetDepreciationList[0].depreciationData[0].year,
          depreciationExpense: 0,
          accumulatedDepreciation: 0,
        });
        console.log(this.mergedData,"this,merged data")
        this.loadchart(this.mergedData);
      }
      }
    },
    (err: any) => {
      this.common.loading = false;
      console.error('Error: ', err);
    }
  );
}


loadchart(mergedData){
  this.depreciationChart?.destroy();
  const config:any={
    type:'line',
    data:{
      datasets: [{
        label: 'Book Value',
        data: mergedData.map(item => ({ x: new Date(`${item.year}-${item.month}-31`), y: item.bookValue })),
        fill: true,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2
      }]
    },
    options:StaticChartOptions,
    legend:true
  };
  this.depreciationChart=new Chart(document.getElementById('depreciationChart') as HTMLCanvasElement,config)
}

onManage(row:any){
      this.assetDepreciation.id=row.depreciationId,
      this.assetDepreciation.assetId =row.assetId,
      this.assetDepreciation.depreciableCost=row.depriciableCost,
      this.assetDepreciation.salvageValue=row.salvageValue,
      this.assetDepreciation.assetLifeInMonths=row.assetLifeInMonths,
      this.assetDepreciation.depreciationType=row.depriciationType,
      this.assetDepreciation.dateOfAcquired=row.dateOfAcquired
  };

  reset(){
    this.assetDepreciation = {
      id:null,
      assetId:null,
      depreciableCost:null,
      salvageValue:null,
      assetLifeInMonths:null,
      depreciationType:null,
      dateOfAcquired:null,
    }
      this.depreciableCostValidator= null,
      this.salvagevalueValidator= null,
      this.assetLifeInMonthsValidator= null,
      this.depreciationTypeValidator= null,
      this.dateOfAcquiredValidator= null
      this.mergedData=[];
      this.groupedData={};
}




formValidator() {
  this.depreciableCostValidator = this.common.nullValidator(
    this.assetDepreciation.depreciableCost,
    'Depreciable Cost Mandatory'
    );
    console.log(' this.depreciableCostValidator: ',  this.depreciableCostValidator);
  this.salvagevalueValidator = this.common.nullValidator(
    this.assetDepreciation.salvageValue,
    'salvage Value Mandatory'
  );
  this.assetLifeInMonthsValidator = this.common.nullValidator(
    this.assetDepreciation.assetLifeInMonths,
    'Asset Life Mandatory'
  );
  this.depreciationTypeValidator = this.common.nullValidator(
    this.assetDepreciation.depreciationType,
    'Depreciation Type Mandatory'
  );
  this.dateOfAcquiredValidator = this.common.nullValidator(
    this.assetDepreciation.dateOfAcquired,
    'Date Of Acquired Mandatory'
  );
  if (!this.depreciableCostValidator.error && !this.salvagevalueValidator.error && !this.assetLifeInMonthsValidator.error && !this.depreciationTypeValidator.error && !this.dateOfAcquiredValidator.error) {
    this.saveAssetDepreciation();
  }
}

}

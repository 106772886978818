<div class="form_sec">
  <div class="row">
    <div class="col-md-3">
      <input type="name" class="form-control" placeholder="Employee Number" [(ngModel)]="mobile_number"
        [value]="mobile_number">
    </div>
    <div class="col-md-2">
      <button type="button" (click)="getVisitorReportByEmpNo(0)"> <i class="fa fa-search"></i> </button>
    </div>
  </div>
</div>

<div class="tbl_sec">
  <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
    <thead class="thead-light">
      <tr>
        <th>S.No.</th>
        <th>Name</th>
        <th>Mobile</th>
        <th>Meeth With</th>
        <th>Meeting Type</th>
        <th>In Time</th>
        <th>Out Time</th>
        <th>Status</th>
        <th>Meeting Start Time</th>
        <th>Meeting End Time</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of visitorListByEmpNo let i=index">
        <td>{{ i+1 }}</td>
        <td>{{row.name}}</td>
        <td>{{row.contact_no}}</td>
        <td>{{row.meet_with}}</td>
        <td>General</td>
        <td>{{row.start_time | date :'short'}}</td>
        <td>{{row.end_time | date :'short'}}</td>
        <td class="status_class">
          <span class="fa" [ngClass]="{
                              'fa-check class_green': row.stage_workflow_status === 'COMPLETED',
                              'fa-times class_red': row.stage_workflow_status === 'REJECTED',
                              'fa-times class_black': row.stage_workflow_status === 'CANCELLED',
                              'fa-user class_orange': row.stage_workflow_status === 'INPROGRESS'}">
            {{row.stage_workflow_status}}
          </span>
        </td>
        <td>{{row.meeting_start_time | date :'short'}}</td>
        <td>{{row.meeting_end_time | date :'short'}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Zone Type Management'|translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{('zone'|translate)+' '+('type'|translate)+'
                    '+('name'|translate)}}</label>
                  <input type="text" class="form-control" name="zoneTypeName" required
                    placeholder="Zone Type Name"
                    [(ngModel)]="zoneType.zoneTypeName" autocomplete="off">
                  <div class="error" *ngIf="zoneTypeValidator && zoneTypeValidator.error">
                    <span>{{zoneTypeValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required"> {{'status'|translate}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageDestinationCodeStatus"
                      [(ngModel)]="zoneType.status" id="stage_status_1"
                      value="ACTIVE">
                    <label for="exampleRadios1">
                      {{'active'|translate}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageDestinationCodeStatus"
                    [(ngModel)]="zoneType.status" id="stage_status_2"
                      value="INACTIVE">
                    <label for="exampleRadios2">
                      {{'inactive'|translate}}
                    </label>
                  </div>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">
                    {{'back'|translate}}</button>
                  <button class="btn btn-warning" (click)="resetDetail()"> {{'reset'|translate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | translate}} </button>
                </div>
              </div>
            </div>

          </div>
        </div>


      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{('zone'|translate)+' '+('type'|translate)+' '+('list'|translate)}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <!-- table -->
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{'serial_no'|translate}}</th>
                    <th>{{('type'|translate)+' '+('name'|translate)}}</th>
                    <th>{{'status'|translate}}</th>
                    <th class="action"> {{'Actions'|translate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of data, let i = index">
                    <td>{{i+1}}</td>
                    <td>{{row.typeName}}</td>
                    <td>{{row.status}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewDetails(row)">
                          {{'view'|translate}} </button>
                        <button class="btn btn-success" (click)="editZoneType(row)">
                          {{'edit'|translate}} </button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)">
                          {{'delete'|translate}} </button>
                      </div>

                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>
      <br>


    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ProcessService } from '../process/process.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zone-dashboard',
  templateUrl: './zone-dashboard.component.html',
  styleUrls: ['./zone-dashboard.component.scss'],
})
export class ZoneDashboardComponent implements OnInit {
  zones: any[] = [];
  constructor(
    public api: ApiService,
    public common: CommonService,
    public alertService: AlertService,
    public modal: NgbModal,
    public processService: ProcessService,
    public breadcrumbService: BreadcrumbService,
    public router: Router
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Zone', url: null },
      { name: 'Zone Dashboard', link: '/user/zone-dashboard' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }
  processStages: any[] = [];
  selectedOrderId: number = null;
  selectedOrderName: string = null;
  zoneColors: any[] = [];

  ngOnInit() {
    this.getParkingTypeStages();
  }

  selectedStage(event: any) {
    this.selectedOrderId = event.order_id;
    this.selectedOrderName = event.order_name;
    this.getZonesByStageId(this.selectedOrderId);
  }

  getParkingTypeStages() {
    this.common.loading = true;
    this.processService.getAllProcessStage().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.processStages = res.data.filter(
          (data) => data.stage_type_name == 'Parking'
        );
        this.selectedOrderId = this.processStages[0].order_id;
        this.selectedOrderName = this.processStages[0].order_name;
        this.getZonesByStageId(this.selectedOrderId);
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getZonesByStageId(processStageId: any) {
    this.common.loading = true;
    this.api
      .get(
        'zone/get_zones_occupancy_status_count?processStageId=' + processStageId
      )
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.zones = res.data;
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  openParkingDashboard(zoneId1: number) {
    const key1 = btoa(zoneId1.toString());
    const key2 = btoa(this.selectedOrderId.toString());
    this.router.navigate(['user/parking-dashboard'], {
      queryParams: { key1, key2 },
    });
  }
}

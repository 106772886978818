<div class="container-fluid">
  <div class="container-card group-card">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header d-flex align-items-start justify-content-between mb-1">
            <div class="header-block">
              <h3 class="card-title">
                {{ "Visitor Card Design" | customTranslate }}
              </h3>
            </div>
            <div>

              <!-- start of filter inputs -->
              <div class="d-flex gap-3 align-items-center justify-content-end">
                <div *ngIf="role.user_role == 'AXESTRACK'" class="form-group mb-0">
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                    display="companyName" [placeholder]="'select_company' | customTranslate "
                    [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                </div>
                <div *ngIf="role.user_role=='AXESTRACK' || role.user_role=='COMPANYADMIN'" class="form-group mb-0">
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                    [placeholder]="'select_plant' | customTranslate" [preSelected]="{ plantName: plantName }">
                  </ps-auto-suggestion>
                </div>
                <ps-auto-suggestion (onSelected)="selectedForm($event)" [data]="formsList" display="form_title"
                  [placeholder]="'select_form' | customTranslate" [preSelected]="{ form_title: formName }">
                </ps-auto-suggestion>
              </div>
              <!-- end of filter inputs -->

              <!-- start of note -->
              <div class="note my-1">
                <span class="star">*</span><span class="note_header">Note :</span><span
                  class="note_text">{{"visitor_card_mapping_note" | customTranslate}}</span>
              </div>
              <!-- end of note -->

            </div>
          </div>

          <div class="card-body">
            <div class="form_layout">
              <div class="row">

                <!-- start of left section -->
                <div class="col-md-3 bg-white">
                  <div class="fields-container">

                    <!-- start of field search input -->
                    <div class="fields_header d-flex align-items-center gap-2 px-2">
                      <i class="fa fa-search pointer-cursor text-lg" aria-hidden="true"></i>
                      <input class="search_input" name="fieldSearch" id="fieldSearch" type="text"
                        (input)="searchFieldName($event)" [placeholder]="'search_field_input' | customTranslate" />
                    </div>
                    <!-- end of field search input -->

                    <!-- start of field list -->
                    <ul class="list-group field_list" *ngIf="fieldList.length > 0; else noDataFound;">
                        <li class="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
                        [ngClass]="{'bg-light': !field.is_editable}"
                          (click)="checkField.click()" *ngFor="let field of fieldList;">
                          <div>
                            <input type="checkbox" name="" [disabled]="!field.is_editable" (change)="handleFieldChange($event, field)" #checkField
                              [value]="field.master_field_id" (click)="$event.stopPropagation();"
                              [checked]="field.is_mapped"> &nbsp;&nbsp;&nbsp;{{field.field_name}} <span
                              class="badge badge-secondary">{{field.database_field_name}}</span>
                          </div>
                          <i [class]="'fas fa-' + fieldTypes[field.field_type] + ' text-secondary'"></i>
                        </li>
                    </ul>
                    <!-- end of field list -->

                  </div>

                  <!-- start of action button -->
                  <div class="d-flex align-items justify-content-end gap-2 mb-2" *ngIf="fieldList.length > 0">
                    <button class="btn btn-warning"
                      (click)="attachVisitorImg = false; getVisitorFormFieldsList()">{{'reset' | customTranslate}}</button>
                    <button class="btn btn-success" (click)="saveMapping()">{{'save' | customTranslate}}</button>
                  </div>
                  <!-- end of action button -->

                </div>
                <!-- end of left section -->

                <!-- start of right section -->
                <div class="col-md-9 d-flex align-items-center justify-content-center">

                  <!-- start of visitor card -->
                  <div class="visitor_card" *ngIf="mappedFields.length; else noDataFound">
                    <div class="card_top">
                      <h5>{{'Visitor Card' | customTranslate}}</h5>
                    </div>
                    <div class="card_sec">
                      <div class="card_left" *ngIf="attachVisitorImg">
                        <img src="assets/images/visitor_img.png">
                      </div>

                      <div class="card_right">
                        <ng-container *ngFor="let field of mappedFields;">
                          <div class="data_line" *ngIf="field.database_field_name != 'visitor_image'">
                            <h6 class="ellipsis">{{field.field_name}}</h6>
                            <p>----</p>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <!-- end of visitor card -->

                </div>
                <!-- end of right section -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- start no record found -->
<ng-template #noDataFound>
  <div class="absolute data-notfound">
    <img src="assets/images/no-record.png" alt="" style="width: 200px;" alt="">
  </div>
</ng-template>
<!-- end no record found -->
import { UserService } from 'src/app/@core/services/user.service';
import { TmgDashboardService } from './../tmg-dashboard.service';
import { DetailService } from './../detail-modal/detail.service';
import { TmgchartService } from './../tmgchart.service';
import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';

@Component({
  selector: 'app-vehicle-dashboard',
  templateUrl: './vehicle-dashboard.component.html',
  styleUrls: [
    './vehicle-dashboard.component.scss',
    '../tmg-dashboard.component.scss',
  ],
})
export class VehicleDashboardComponent implements OnInit {
  // First Chart Var
  vehicleComplianceChartData: ChartDataSets[] = [];
  vehiclecompliancedata: any[] = [];
  public vehicleComplianceChartPlugins = [];
  public vehicleComplianceChartType: ChartType = 'bar';
  public vehicleComplianceChartLegend = true;
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Avg TAT',
            fontSize: 12,
            fontStyle: 'bold',
            fontFamily: ' sans-serif',
          },
          ticks: {
            callback: function (v) {
              return epoch_to_hh_mm_ss(v);
            },
            stepSize: 30 * 60,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ': ' +
            epoch_to_hh_mm_ss(tooltipItem.yLabel)
          );
        },
      },
    },
  };
  public barChartColors: Color[] = [
    { backgroundColor: this.common.graphsuccess },
    { backgroundColor: this.common.graphsecondary },
    { backgroundColor: this.common.graphdanger },
  ];
  public vehicleComplianceChartLabels: Label[] = [];
  // End

  // Second Char Var
  processCountChartData: ChartDataSets[] = [];
  processCountData: any[] = [];
  public processCountChartPlugins = [];
  public processCountChartType: ChartType = 'bar';
  public processCountChartLegend = true;
  public barChartsOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Trip Count',
            fontSize: 12,
            fontStyle: 'bold',
            fontFamily: ' sans-serif',
          },
          ticks: {
            beginAtZero: true,
            stepSize: 5,
          },
        },
      ],
    },
  };

  public barChartsColors: Color[] = [
    { backgroundColor: this.common.graphsuccess },
    { backgroundColor: this.common.graphsecondary },
    { backgroundColor: this.common.graphdanger },
  ];
  public processCountChartLabels: Label[] = [];

  // End

  constructor(
    public chart: TmgchartService,
    public detail: DetailService,
    public tmgdashboard: TmgDashboardService,
    public api: ApiService,
    public common: CommonService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.getVehicleDetails();
    this.getProCountDetails();
  }

  getVehicleDetails() {
    this.api.get('process/process-wise-tat').subscribe((item: any) => {
      if (item.status) {
        this.vehiclecompliancedata = JSON.parse(item.data);
        for (let i = 0; i < this.vehiclecompliancedata[0].values.length; i++) {
          this.vehicleComplianceChartData.push({
            data: [],
            label: '',
          });
        }
        this.vehiclecompliancedata.map((vehicles: any, index: number) => {
          vehicles.values.map((values: any, i: number) => {
            let abc = values.avg.split(':');
            let sec = parseInt(abc[0]) * 3600 + parseInt(abc[1]) * 60;
            this.vehicleComplianceChartData[i].data?.push(sec);
          });
          this.vehicleComplianceChartLabels.push(vehicles.date);
          this.vehiclecompliancedata = JSON.parse(item.data);
        });
        this.vehiclecompliancedata[0].values.map(
          (label: any, index: number) => {
            this.vehicleComplianceChartData[index].label = label.processName;
          }
        );
      }
    });
  }

  getProCountDetails() {
    this.common.loading = true;
    this.api
      .get('process/process-wise-trip-count')
      .toPromise()
      .then((res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.processCountData = JSON.parse(res.data);
          for (let i = 0; i < this.processCountData[0].value.length; i++) {
            this.processCountChartData.push({
              data: [],
              label: '',
            });
          }
          this.processCountData.map((vehicles: any, index: number) => {
            vehicles.value.map((values: any, i: number) => {
              let abc = values.count;
              this.processCountChartData[i].data?.push(abc);
            });
            this.processCountChartLabels.push(vehicles.date);
            this.processCountData = JSON.parse(res.data);
          });
          this.processCountData[0].value.map((label: any, index: number) => {
            this.processCountChartData[index].label = label.processName;
          });
        }
      });
  }
}
function epoch_to_hh_mm_ss(epoch) {
  // return new Date(epoch*1000).toISOString().match("T(.*).000Z")[1]
  let min = Math.floor(epoch / 60);
  return (
    String(Math.floor(min / 60)).padStart(2, '0') +
    ':' +
    String(min % 60).padStart(2, '0')
  );
}

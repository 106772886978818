<div class="container-fluid">
  <div class="top-breadcrumb" [hidden]="!breadcrumbService.getActiveBreadCrumb()">
    <nav aria-label="breadcrumb" class="breadcrumb-container">
      <button class="btn btn-primary back-btn" (click)="dashboardNavigate()" *ngIf="common.commondashboardNavigate"> <i
          class="fas fa-arrow-left"></i>
      </button>

      <ol class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbService.breadcrumbs; let i= index"><a
            [routerLink]="breadcrumb.url">{{breadcrumb.name | translate}}</a></li>
      </ol>
    </nav>
  </div>

</div>

import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { StageDestinationCodeService } from '../stage-destination-code/stage-destination-code.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { StageDestinationCodeTypeService } from 'src/app/components/stage-destination-code-type/stage-destination-code-type.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-stageDestinationCode',
  templateUrl: './stage-destination-code.component.html',
  styleUrls: ['./stage-destination-code.component.scss'],
})
export class StageDestinationCodeComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Stage Destination Code List');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAllStageDestinationCode();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  stageDestinationCode = {
    stageDestinationCodeId: '',
    stageDestinationCode: '',
    stageDestinationCodeStatus: 'ACTIVE',
    stageDestinationCodeDesc: '',
    stageDestinationCodeVehicleLimit: 0,
    stage: '',
    // process:[],
    destinationType: '',
  };
  buttonName = 'save';
  title = 'Add Destination';
  submitted = false;
  responseMessage = '';
  responseData: any[] = [];
  stageDestinationCodeList: any[] = [];
  stageList: any[] = [];
  process: any[] = [];
  processName: any = [];
  destinationTypeList: any = [];
  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    private stageDestinationCodeService: StageDestinationCodeService,
    private stageDestinationCodeTypeService: StageDestinationCodeTypeService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public alert: AlertService,
    public userService: UserService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Destinations', url: null },
      {
        name: 'Destination Management',
        link: '/user/stage_destination_code_list',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getAllStageDestinationCodeType();
  }

  getAllStageDestinationCodeType() {
    this.common.loading = true;
    let params = {
      listType: 'activeDestinationTypes',
    };
    this.stageDestinationCodeTypeService
      .getAllStageDestinationCodeType(params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.destinationTypeList = res.data.filter(
            (item) => item.stageDestinationCodeTypeName !== 'Slot Parking'
          );
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          //
        }
      );
  }

  getAllStageDestinationCode() {
    this.common.loading = true;
    this.stageDestinationCodeService
      .getActiveAndInactiveStageDestinationCode()
      .subscribe(
        (res: any) => {
          this.common.loading = false;

          this.responseData = res.data;
          this.renderTable();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  // Save Work Type
  saveStageDestinationCode(): void {
    let data = {
      stageDestinationCodeId: this.stageDestinationCode.stageDestinationCodeId,
      stageDestinationCode: this.stageDestinationCode.stageDestinationCode,
      stageDestinationCodeStatus:
        this.stageDestinationCode.stageDestinationCodeStatus,
      stageDestinationCodeDesc:
        this.stageDestinationCode.stageDestinationCodeDesc,
      stageDestinationCodeVehicleLimit:
        this.stageDestinationCode.stageDestinationCodeVehicleLimit,
      stageDestinationType: this.stageDestinationCode.destinationType,
    };
    this.stageDestinationCodeService.saveStageDestinationCode(data).subscribe(
      (response: any) => {
        this.submitted = true;
        this.alert.success(response.message);
        this.resetStageDestinationCode();
        this.getAllStageDestinationCode();
      },
      (error: any) => {
        console.error('error: ', error);
      }
    );
  }

  viewStageDestinationCode(row?: any) {
    this.common.params = {
      details: [row],
      title: 'Stage Destination Code Details',
    };

    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'xl',
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Stage Destination Code ',
      description:
        `<b>&nbsp;` + 'Are you sure, Do you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteStageDestinationCode(row);
      }
    });
  }

  deleteStageDestinationCode(row?: any) {
    this.common.loading = true;
    let data = {
      stageDestinationCodeId: row.stage_destination_code_id,
      stageDestinationCode: row.stage_destination_code,
      stageDestinationCodeStatus: 'DELETED',
      stageDestinationCodeDesc: row.stage_destination_code_desc,
      stageDestinationCodeVehicleLimit:
        row.stage_destination_code_vehicle_limit,
      destinationType: row.stage_destination_type,
    };

    this.stageDestinationCodeService.saveStageDestinationCode(data).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alert.success(res.message);
        this.getAllStageDestinationCode();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  editStageDestinationCode(row?: any) {
    this.common.gotoTop();
    this.resetStageDestinationCode();
    this.stageDestinationCode.stageDestinationCodeId =
      row.stage_destination_code_id;
    this.stageDestinationCode.stageDestinationCode = row.stage_destination_code;
    this.stageDestinationCode.stageDestinationCodeStatus =
      row.stage_destination_code_status;
    this.stageDestinationCode.stageDestinationCodeDesc =
      row.stage_destination_code_desc;
    this.stageDestinationCode.stageDestinationCodeVehicleLimit =
      row.stage_destination_code_vehicle_limit;
    this.stageDestinationCode.destinationType = row.stage_destination_type;
    this.buttonName = 'update';
    this.title = 'Edit Destination';
  }

  resetStageDestinationCode() {
    this.stageDestinationCode = {
      stageDestinationCodeId: '',
      stageDestinationCode: '',
      stageDestinationCodeStatus: 'ACTIVE',
      stageDestinationCodeDesc: '',
      stageDestinationCodeVehicleLimit: 0,
      stage: '',
      destinationType: '',
    };
    this.codeNameValidator = null;
    this.stageValidator = null;
    this.statusValidator = null;
    this.destinationTypeValidator = null;
    this.buttonName = 'save';
    this.title = 'Add Destination';
  }

  codeNameValidator: any;
  stageValidator: any;
  statusValidator: any;
  destinationTypeValidator: any;
  formValidator() {
    this.codeNameValidator = this.common.nullValidator(
      this.stageDestinationCode.stageDestinationCode,
      'Destination Code Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.stageDestinationCode.stageDestinationCodeStatus,
      'Destination Status Mandatory'
    );
    this.destinationTypeValidator = this.common.nullValidator(
      this.stageDestinationCode.destinationType,
      'Destination Type Mandatory'
    );
    if (
      !this.codeNameValidator.error &&
      !this.statusValidator.error &&
      !this.destinationTypeValidator.error
    ) {
      this.saveStageDestinationCode();
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';

@Component({
  selector: 'app-overriding-logs',
  templateUrl: './overriding-logs.component.html',
  styleUrls: ['./overriding-logs.component.scss'],
})
export class OverridingLogsComponent implements OnInit {
  responseData: any[];
  tabvalue: any = 'table';

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Overriding Logs');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  listOfLogs: any = [];
  startTime: any = null;
  endTime: any = null;
  response_value: any = null;

  constructor(
    public table: TableService,
    public common: CommonService,
    public api: ApiService,
    private modalService: NgbModal,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.getOverRidingLogs(1);
  }

  viewLog(row?: any) {
    let resp = row.response_value;
    let response = new Object();
    if (resp != null) {
      for (let i = 0; i < resp.length; i++) {
        let key = resp[i].name.toLowerCase();
        let value = resp[i].values.toString();
        response[key] = value;
      }
      this.common.params = { details: [response], title: 'Log Details' };
      const activeModal = this.modalService.open(ViewDetailsComponent, {
        size: 'lg',
      });
    } else {
      response['Message'] = 'No Data Found';
      this.common.params = { details: [response], title: 'Log Details' };
      const activeModal = this.modalService.open(ViewDetailsComponent, {
        size: 'lg',
      });
    }
  }

  getOverRidingLogs(flag: any) {
    let params;
    if (flag == 1) {
      this.startTime = new Date(new Date().setDate(new Date().getDate() - 7));
      this.startTime = this.common.dateFormatter(
        this.startTime,
        'YYYYMMdd',
        false,
        '-'
      );
      this.endTime = new Date();
      this.endTime = this.common.dateFormatter(
        this.endTime,
        'YYYYMMdd',
        false,
        '-'
      );
      params = {
        startTime: this.startTime,
        endTime: this.endTime,
      };
    } else {
      params = {
        startTime: this.startTime,
        endTime: this.endTime,
      };
    }
    this.common.loading = true;
    this.api.get('actions/get-overriding-logs', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.listOfLogs = res;
        this.listOfLogs.forEach((e) => {
          e.response_value = JSON.parse(e.response_value);
        });
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

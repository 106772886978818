<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="dock_dashboard">
                <!-- <div class="row top_view align-items-center" *ngIf="role.user_role == 'COMPANYADMIN'">
                  <div class="col-md-2">
                    <mat-form-field appearance="fill">
                      <mat-label>{{"Plant" | translate}}</mat-label>
                      <mat-select [(value)]="selectedPlant" (selectionChange)="onSelectPlant($event)">
                        <mat-option>
                          <ngx-mat-select-search placeholderLabel="Plant"
                            noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let plant of plantList" [value]="plant.plantId">
                          {{plant.plantName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div> -->

                <div class="row top_view align-items-center">
                  <div class="select_calender col-md-5">
                    <i class="fa fa-angle-left" (click)="switchDate(-1,'left')"></i>
                    <i class="fa fa-angle-double-left" (click)="switchDate(-1,'first')"></i>
                    <div class="select_date">
                      <div class="calender_date" *ngFor="let item of dates; let i = index"
                        [class.active]="currentTime == i" (click)="switchDate(i,'')">
                        <div class="up_day">{{item | date:'EEE'}}</div>
                        <div class="down_date">{{item | date:'MMM dd'}}</div>
                      </div>

                    </div>
                    <i class="fa fa-angle-double-right" (click)="switchDate(-1,'last')"></i>
                    <i class="fa fa-angle-right" (click)="switchDate(-1,'right')"></i>
                  </div>
                  <!-- <div class="toggle-switch col-md-3">
                    <span [class.active]="activeView=='History'" (click)="switchView('History')">History</span>
                    <span [class.active]="activeView=='Current'" (click)="switchView('Current')">Current</span>
                    <span [class.active]="activeView=='Late'" (click)="switchView('Late')">Delay</span>
                  </div> -->

                  <!-- <div class="process_sec col-md-2">
                    <mat-form-field appearance="fill">
                      <mat-label>{{"process" | translate}}</mat-label>
                      <mat-select [(value)]="selProcess" (selectionChange)="selectedProcess($event)">
                        <mat-option>
                          <ngx-mat-select-search placeholderLabel="Process"
                            noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let process of processList"
                          [value]="process.processId">{{process.processName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div> -->
                  <div class="product_sec col-md-3 offset-md-4" style="display: flex;">
                    <label style="width: 100%;justify-content: end; margin-right: 15px;">{{'Select Product:' |
                      translate}}</label>
                    <div style="width: 100%;">
                      <ps-auto-suggestion (onSelected)="selectedProduct($event)" [data]="productList"
                        style="width: 100%;" display="product_name" placeholder="Select Material"
                        name="selectedProductName" [preSelected]="{product_name:this.selectedProductName}">
                      </ps-auto-suggestion>
                    </div>
                  </div>

                  <!-- <div class="dash_box_sec col-md-2">
                    <div class="dash_box bg_clr_blue">
                      <div class="content_box">
                        <h6>Total</h6>
                        <p class="clr_yellow">{{dockCount.total}}</p>
                      </div>
                    </div>
                    <div class="dash_box bg_clr_yellow">
                      <div class="content_box">
                        <h6>Late Loading</h6>
                        <p class="clr_green">{{dockCount.late}}</p>
                      </div>
                    </div>
                    <div class="dash_box bg_clr_red">
                      <div class="content_box">
                        <h6>Delay Arrival</h6>
                        <p class="clr_red">{{dockCount.delay}}</p>
                      </div>
                    </div>
                  </div> -->
                </div>

                <div class="tab_view" *ngIf="dockData.length > 0">
                  <div class="tab" [class.active]="activeTab == i" (click)="switchDock(i)"
                    *ngFor="let item of dockData; let i = index">{{item.stage_destination_code}} <span
                      class="badge px-2 py-1"> {{item.availableSlots}}</span></div>
                </div>

                <div class="dock_sec">
                  <div class="tat_sec">
                    <!-- <div class="product_list">
                      <ps-auto-suggestion (onSelected)="selectProduct($event,activeTab)" class="required" [data]="productList"
                        display="product_name" placeholder="Choose Product"
                                    [preSelected]="{ product_name:dockData[activeTab].product_name}">
                      </ps-auto-suggestion>
                    </div> -->
                    <p>{{dockData[activeTab].stage_destination_code}}</p>
                    <div class="search_box">

                      <input type="text" placeholder="Search With Vehicle Number..." [(ngModel)]="searchValue"
                        (input)="searchVehicle()">
                      <i class="fa fa-search"></i>
                    </div>
                  </div>
                  <ng-container *ngIf="sliceIndex!=-1">
                    <div class="row dock_box m-0" attr.id="dock-{{l}}"
                      *ngFor="let item of dockData[activeTab].dailySlot.slice(sliceIndex); let l = index">
                      <div class="col-md-1 col-3 left_sec p-0">
                        <div class="time">
                          <p>{{item.start_time}} <br> To <br>{{item.end_time}}</p>
                        </div>
                      </div>

                      <div class="col-md-11 col-9 right_sec p-0">
                        <div class="block_row" [id]="'slot-block-'+l">
                          <!-- <ng-container *ngFor="let queue of item.slot_data; let i = index">
                            <div class="block" id="vehicle-box" [ngClass]="searchTrue ? 'blink' : ''">
                              <div class="info-container">
                                <div class="info-item">
                                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                                  <label>In:</label>
                                  <span>{{getLoaderTime(item,i)}}</span>
                                </div>
                                <div class="info-item">
                                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                                  <label>Out:</label>
                                  <span>{{getLoaderTime(item,i+1)}}</span>
                                </div>
                              </div>
                              <img src="assets/images/green_big_truck.png">
                              <div class="info-box" id="info-box">
                                <i class="fa fa-info">
                                  <ul class="children">
                                    <li>{{queue.driver_name}}</li>
                                    <li>{{queue.driver_phone}}</li>
                                  </ul>
                                </i>
                              </div>
                              <div class="inside_box">
                                <div class="title_box">
                                  <div class="vehicle_number">
                                    {{queue.rc_no}}
                                  </div>
                                  <span class="pill" [ngClass]="{'bg-blue-100 text-blue-800': queue.status == 'Booked',
                                  'bg-yellow-100 text-yellow-800':queue.status == 'Inprogress' ,'bg-green-100 text-green-800'
                                  :queue.status == 'Completed'}">
                                    {{queue.status}}
                                  </span>
                                  <i class="fa fa-times" *ngIf="isTimeValid(getLoaderTime(item,i))" aria-hidden="true"
                                    (click)="cancelBooking(queue.booking_id)"></i>
                                </div>
                                <div [ngClass]="'time bg_red'" style="left: 4%; right: 1%; width: max-content;"
                                  *ngIf="queue.loading_delay_time!='00:00' && queue.loading_delay_time!='0 min'">
                                  {{queue.loading_delay_time+" Late"}}
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container
                            *ngFor="let appoint of [].constructor(item.vehicle_limit-item.slot_data.length); let j=index">
                            <div class="block" id="vehicle-box" [ngClass]="searchTrue ? 'blink' : ''">
                              <div class="info-container">
                                <div class="info-item">
                                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                                  <label>In:</label>
                                  <span>{{getLoaderTime(item,j+item.slot_data.length)}}</span>
                                </div>
                                <div class="info-item">
                                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                                  <label>Out:</label>
                                  <span>{{getLoaderTime(item,j+1+item.slot_data.length)}}</span>
                                </div>
                              </div>
                              <div class="booking-box" *ngIf="isTimeValid(getLoaderTime(item,j))">
                                <button class="btn btn-add"
                                  (click)="openBookingModal(j,dockData[activeTab].destination_id,item)">
                                  <i class="fas fa-plus-circle"></i>
                                </button>
                              </div>
                            </div>
                          </ng-container> -->



                          <ng-container *ngFor="let appoint of [].constructor(item.vehicle_limit); let j=index">
                            <div class="block" id="vehicle-box" [ngClass]="searchTrue ? 'blink' : ''">
                              <div class="info-container">
                                <div class="info-item">
                                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                                  <label>In:</label>
                                  <span>{{getLoaderTime(item,j)}}</span>
                                </div>
                                <div class="info-item">
                                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                                  <label>Out:</label>
                                  <span>{{getLoaderTime(item,j+1)}}</span>
                                </div>
                              </div>
                              <div class="vehicle-container" *ngIf="findSlotDataByIndex(item.slot_data,j+1) as slot">
                                <div class="img-container">
                                  <img src="assets/images/green_big_truck.png">
                                  <div class="info-box" id="info-box"
                                    *ngIf="slot.transporter_login_id==userService._loggedInUser.id">
                                    <i class="fa fa-info">
                                      <ul class="children">
                                        <li>{{slot.driver_name}}</li>
                                        <li>{{slot.driver_phone}}</li>
                                      </ul>
                                    </i>
                                  </div>
                                  <div class="inside_box">
                                    <div class="title_box"
                                      *ngIf="slot.transporter_login_id==userService._loggedInUser.id">
                                      <div class="edit_veh_icon">
                                        <i class="fas fa-edit" *ngIf="slot?.status=='Booked' && isTimeValid(getLoaderTime(item,j))" (click)="openBookingModal(j,dockData[activeTab].destination_id,item,true,slot)"></i>
                                        <div class="vehicle_number" appEllipsisView >
                                          {{slot.rc_no}}
                                        </div>
                                      </div>

                                      <i class="fa fa-times close_icon" *ngIf="slot.status=='Booked' && isTimeValid(getLoaderTime(item,j))" aria-hidden="true"
                                          (click)="confirmAlert(slot.booking_id)"></i>

                                      <div class="veh_status">
                                        <span class="pill" [ngClass]="{'bg-blue-100 text-blue-800':slot.status=='Booked','bg-green-200 text-green-800':slot.status=='Completed','bg-yellow-200 text-yellow-800':slot.status=='Inprogress','bg-teal-200 text-teal-800':slot.status=='Loaded'}">
                                          {{slot.status}}
                                        </span>
                                      </div>
                                    </div>

                                    <div class="veh_status" *ngIf="slot.transporter_login_id==null || slot.transporter_login_id!=userService._loggedInUser.id">
                                      <span class="pill bg-red-100 text-red-800"> Occupied </span>
                                    </div>

                                    <div [ngClass]="'time bg_red'" style="left: 4%; right: 1%; width: max-content;"
                                      *ngIf="slot.loading_delay_time!='00:00' && slot.loading_delay_time!='0 min'">
                                      {{slot.loading_delay_time+" Late"}}
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div class="available-slot"
                                *ngIf="!findSlotDataByIndex(item.slot_data,j+1) && isTimeValid(getLoaderTime(item,j))">
                                <button class="btn btn-add"
                                  (click)="openBookingModal(j,dockData[activeTab].destination_id,item,false)">
                                  <img src="assets/images/transporter-appointment2.png" style="width: 20%;">
                                  <!-- <img  src="assets/images/transporter-appointment.png" style="width: 70%;" > -->
                                </button>
                              </div>
                            </div>

                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>











                  <ng-container *ngIf="sliceIndex == -1">
                    <div class="no-queue" [class.active]="true">
                      <div class="vehicle_number">
                        No Queue Available On This Dock !!
                      </div>
                    </div>
                  </ng-container>

                </div>
                <div class="dock_sec" *ngIf="dockData.length == 0">
                  <div class="no-queue" [class.active]="true" style="width: 99.2%;">
                    <div class="vehicle_number">
                      No Dock Slots Available on this Date !!
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

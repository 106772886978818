import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends CommonService {
  MAIN_URL: string = environment.mainUrl;
  UTILITY_URL: string = environment.utiltityUrl;
  HARDWARE_URL: string = environment.hardwareUrl;
  XSIGHT_URL: string = environment.xsightUrl;

      // baseUrl: string = 'http://13.233.225.219:8080/';
      // baseUrl: string = 'http://192.168.0.154:8080/';

  post(subURL: string, body: any, options?: any, baseUrl: any = "MAIN", authkey: any = null) {
    return this.http.post(this[baseUrl + "_URL"] + subURL, body, { headers: this.setHeaders(null,baseUrl, authkey) })
  }

  get(subURL: string, params?: any, baseUrl: any = "MAIN", authkey: any = null) {
    let finalStr = "";
    if (params != null || params != undefined) {
      let paramArray = Object.keys(params);
      let str = '?';
      for (let index = 0; index < paramArray.length; index++) {
        if(params[paramArray[index]] != null){
          str += paramArray[index] + "=" + params[paramArray[index]] + "&";
        }
      }
      finalStr = str.substr(0, str.length - 1);
    }
    return this.http.get(this[baseUrl + "_URL"] + subURL + finalStr, { headers: this.setHeaders(null,baseUrl, authkey) })
  }

  setHeaders(version?: any,baseUrl:any="MAIN", authkey: any = null) {
    if(baseUrl=="XSIGHT"){
      return new HttpHeaders({
        "authkey": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MzEzNjUsImZvaWQiOjEsIm5hbWUiOiJTYWxvbmkgQmFuc2FsIiwibW9iaWxlbm8iOjc3MzczNTk2OTUsImVtYWlsIjoic2Fsb25pLmJhbnNhbEBheGVzdHJhY2suY29tIiwidGltZSI6IjIwMjItMTItMDdUMTQ6Mzc6NTArMDU6MzAifQ.80BOFsdlr81fj_gV41BqcbjDQfPlqyKDDiyaqceBgRE",
        "entrymode":"1",
        "foAdminId":"0"
      })
    }else{
      authkey = authkey || this.user._token || 'null';
      let headers = new HttpHeaders({
        // 'Content-Type': 'application/json',
        'version': version || '1.0',
        'Access-Control-Allow-Origin': '*',
        'Authorization' : 'Bearer ' + authkey,
        'platformType' : 'WEB'
        // 'Set-Cookie': 'JSESSIONID='+this.user._sessionId
      });

      return headers;
    }
  }

}

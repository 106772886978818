import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { StagesettingService } from '../stage-setting/stage-setting.service';
import { CommonService } from '../../@core/services/common.service';
import { ApiService } from '../../@core/services/api.service';
import { StageService } from '../stage/stage.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { UserService } from '../../@core/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ProcessService } from '../process/process.service';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { ParkingDataList } from 'src/app/dataType/parkingInterface';

@Component({
  selector: 'app-parking-dashboard',
  templateUrl: './parking-dashboard.component.html',
  styleUrls: ['./parking-dashboard.component.scss'],
})
export class ParkingDashboardComponent implements OnInit {

  stagelist: any[] = [];
  processList: any[] = [];
  selectedProcessId: any = null;
  selectedType: any = 'STAGE';
  selectedZoneId: any = null;
  searchFieldText: any = '';
  selectedOrderName: string = null;
  process: any[] = [];
  parkingViewTypeList: any[] = [
    { name: 'Stage', id: '1' },
    { name: 'Zone', id: '2' },
  ];
  selectedDashboardTypeId: any='Slot_Parking';
  parkingDashboardData: any = null;
  activeIndex: number = 0;
  selectedParkingViewType: any='Stage';
  destinations: any[] = [];
  openParkingVehiclesList: any[] = [];
  slotParkingVehiclesList: any[] = [];
  plantParkingType: any;
  tabList: any = [];
  maintenanceSlotCount: number = 0;
  parkingSlotData: any = [];
  plantList: any[] = [];
  selectedPlantId: any;
  selectedPlantName: any;
  selectedProcessName: any;
  isInitialise:boolean=true;
  parkingDataList:ParkingDataList[]=[];

  parkingData=[
    {
      value:'FitnessExpired',
      key:'fitness_renewal_date'
    },
    {
      value:'InsuranceExpired',
      key:'insurance_expiry_date'
    },
    {
      value:'PUCExpired',
      key:'puc_date'
    },
    {
      value:'RCExpired',
      key:'rc_expiry_date'
    },
    {
      value:'Maintenance',
      key:'stage_workflow_status'
    },
    {
      value:'WithOrder',
      key:'extend_order_id'
    },
    {
      value:'WithoutOrder',
      key:'extend_order_id'
    }
  ]

  constructor(
    public Stage: StagesettingService,
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public stageService: StageService,
    public userService: UserService,
    private modalService: NgbModal,
    public alert: AlertService,
    public route: ActivatedRoute,
    private processService: ProcessService,
    public router: Router,
    public plantService: PlantService,
    public role: RoleService
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.getPlantSettingsByPlantId(params);
    });
    console.log("role",this.role.user_role)
    this.role.user_role == 'COMPANYADMIN' ? this.getPlantById() : true;
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantList = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  onSelectPlant(e: any) {
    this.selectedPlantId = e.plantId;
    this.selectedPlantName = e.plantName;
    this.getPlantSettingsByPlantId(null, this.selectedPlantId);
  }

  selectProcess(item: any) {
    this.tabList = [];
    if (this.selectedDashboardTypeId == 'Open_Parking') {
      this.openParkingVehiclesList = [];
      this.parkingDashboardData = null;
    }
    this.selectedProcessId = item.processId;
    this.getParkingStagesByProcessId(this.selectedProcessId);
  }

  selectParkingViewType(event: any) {
    this.tabList = [];
    this.parkingDashboardData = null;
    this.selectedParkingViewType = event.name;
    this.selectedParkingViewType == 'Stage'
      ? this.getAllProcess()
      : this.getAllProcessStage();
  }

  selectProcessStage(event: any) {
    this.tabList = [];
    this.parkingDashboardData = null;
    this.getZonesByProcessStageOrderId(event.order_id);
  }

  tabChange(item: any, index: any) {
    this.parkingSlotData = [];
    this.activeIndex = index;
    if (this.selectedDashboardTypeId == 'Slot_Parking') {
      this.slotParkingVehiclesList = [];
      this.destinations = item.subList != '' ? JSON.parse(item.subList) : [];
      this.maintenanceSlotCount = this.destinations.filter(
        (e) => e.destination_occupancy_status == 'MAINTENANCE'
      ).length;
    }
    this.getParkingDashboardData(item.id, item.zoneId);
  }

  getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.selectedPlantId,
    };
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.processList = res.data;
        if(this.isInitialise){
          this.selectedProcessId=res.data[0].processId;
          this.selectedProcessName=res.data[0].processName;
          this.getParkingStagesByProcessId(this.selectedProcessId);
          this.isInitialise=false;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getParkingStagesByProcessId(id?: any) {
    this.common.loading = true;
    let params;
    if(this.userService._loggedInUser.plantId==0){
       params = {
        processId: id,
        plantId:this.selectedPlantId
      };
    }else{
       params = {
        processId: id,
      };
    }

    this.api.get('parking/get-parking-stages-by-processId', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (this.selectedDashboardTypeId == 'Slot_Parking' && res.data.length) {
          this.tabList = res.data.map((d) => ({
            id: d.stage_id,
            name: d.stage_name,
            zoneId: -1,
            subList: d.destinations,
          }));
          this.tabChange(this.tabList[0], 0);
          console.log('this.tabList[0]: ', this.tabList[0]);
        } else {
          this.stagelist = res.data;

        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getDestinationsByProcessStageId(stageId: any) {
    this.common.loading = true;
    let params = {
      processId: this.selectedProcessId,
      stageId: stageId,
    };
    this.api
      .get('parking/get-parking-destinations-by-stageId', params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.tabList = res.data.map((d) => ({
            id: d.stage_destination_code_id,
            name: d.stage_destination_code,
            zoneId: -1,
          }));
          this.tabChange(this.tabList[0], 0);
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
        }
      );
  }

  getZonesByProcessStageOrderId(selectedOrderId: any) {
    this.api
      .get('zone/get_all_zones?processStageOrderId=' + selectedOrderId)
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.activeIndex =
              this.selectedZoneId != null
                ? res.data.findIndex((e) => e.id === this.selectedZoneId)
                : 0;
            this.tabList = res.data.map((d) => ({
              id: d.stageId,
              name: d.zoneName,
              zoneId: d.id,
              subList: d.destinations,
            }));
            this.tabChange(this.tabList[this.activeIndex], this.activeIndex);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getAllProcessStage(selectedOrderId: any = null) {
    this.common.loading = true;
    this.processService.getAllProcessStage().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.process = res.data.filter(
          (data) => data.stage_type_name == 'Parking'
        );
        if (selectedOrderId != null) {
          this.selectedOrderName = this.process.filter(
            (processStage) => processStage.order_id == selectedOrderId
          )[0].order_name;
          this.getZonesByProcessStageOrderId(selectedOrderId);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getParkingDashboardData(id: any, zoneId: any) {
    this.common.loading = true;
    let params = {
      dashboardType: this.selectedDashboardTypeId,
      id: id, // stageId in case of 'Slot Parking' and destinationId in case of 'Open Parking'
      zoneId: zoneId, // zoneId if viewType 'Zone' is selected else -1
      processId: zoneId==-1?this.selectedProcessId:-1
    };
    this.api.get('parking/get-parking-dashboard-data', params).subscribe(
      (res: any) => {
        this.parkingDashboardData = res.data[0] || [];
        this.parkingDashboardData = {
          Total:
            this.selectedDashboardTypeId == 'Slot_Parking'
              ? this.destinations.length
              : res.data[0].Total,
          Available:
            this.selectedDashboardTypeId == 'Slot_Parking'
              ? this.destinations.length -
                res.data[0].Occupied -
                this.maintenanceSlotCount
              : res.data[0].Available,
          Occupied: res.data[0].Occupied,
          AverageTAT: res.data[0].AverageTAT,
          MaintenanceCount: res.data[0].MaintenanceCount,
          OverTATCount: res.data[0].OverTATCount,
          RCExpired: res.data[0].RCExpired,
          FitnessExpired: res.data[0].FitnessExpired,
          PUCExpired: res.data[0].PUCExpired,
          InsuranceExpired: res.data[0].InsuranceExpired,
          WithOrder: res.data[0]['WithOrder'],
          WithoutOrder: res.data[0]['WithoutOrder'],
        };

        this.slotParkingVehiclesList=res.data[0]['parkingDataList'];
        this.parkingDataList=res.data[0]['parkingDataList'];
        this.common.loading = false;
        if (this.selectedDashboardTypeId == 'Slot_Parking') {
          this.createParkignSlotData();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  createParkignSlotData() {
    let arrLen = this.parkingRowCounter();
    for (let index = 0; index < arrLen; index++) {
      const parking = this.getParkings(index);
      this.parkingSlotData.push(parking);
    }
  }

  getPlantSettingsByPlantId(params: any, selectedPlantId?: any) {
    this.common.loading = true;
    let data = {
      plantId:
        selectedPlantId != null
          ? selectedPlantId
          : this.userService._loggedInUser.plantId,
    };
    this.api.get('plant/get-plant-setting-by-plant-id', data).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantParkingType = res.data[0].parkingType;
        this.selectedDashboardTypeId =
          res.data[0].parkingType == 'Both'
            ? 'Slot_Parking'
            : this.plantParkingType;
        if (
          this.plantParkingType != 'Open_Parking' &&
          params.get('key1') != null
        ) {
          this.selectedDashboardTypeId = 'Slot_Parking';
          this.selectedParkingViewType = 'Zone';
          this.getAllProcessStage(atob(params.get('key2')));
          this.selectedZoneId = +atob(params.get('key1'));
        } else {
          this.getAllProcess();
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  resetUrl() {
    if (this.plantParkingType == 'Both') {
      this.router.navigate(['user/parking-dashboard']);
    }
    this.selectedParkingViewType=null;
  }
  resetProcess(){
    this.selectedProcessId=null;
    this.selectedProcessName=null;
  }

  parkingRowCounter() {
    if (
      this.parkingDashboardData == null ||
      this.parkingDashboardData.Total == 0
    ) {
      return 0;
    } else {
      const val = Math.ceil(this.parkingDashboardData.Total / 40);
      return val;
    }
  }

  getParkings(startIndx) {
    let res = [];
    let temp = this.destinations.slice(startIndx * 40, startIndx * 40 + 40);
    for (let index = 0; index < temp.length; index++) {
      let existIndex = this.slotParkingVehiclesList.findIndex(
        (vehicle) =>
          vehicle.stage_destination_code_id ==
          temp[index]['stage_destination_code_id']
      );
      res.push({
        stage_destination_code_id: temp[index]['stage_destination_code_id'],
        stage_destination_code: temp[index]['stage_destination_code_name'],
        destination_occupancy_status:
          temp[index]['destination_occupancy_status'],
        vehicle_rc_no:
          existIndex == -1
            ? null
            : this.slotParkingVehiclesList[existIndex]['vehicle_rc_no'],
        stage_workflow_status:
          existIndex == -1
            ? 'FREE'
            : this.slotParkingVehiclesList[existIndex]['stage_workflow_status'],
        txnId:
          existIndex == -1
            ? null
            : this.slotParkingVehiclesList[existIndex]['ipa_transaction_id'],
        processName:
          existIndex == -1
            ? null
            : this.slotParkingVehiclesList[existIndex]['process_name'],
        tag_stage_detail_status:
          existIndex == -1
            ? null
            : this.slotParkingVehiclesList[existIndex][
                'tag_stage_detail_status'
              ],
      });
    }
    return res;
  }

  isMatchingStageDestinationCode(
    destination_id: string,
    vehicle_no: any
  ): boolean {
    if (this.searchFieldText.trim().length > 2) {
      return (
        destination_id
          ?.toLowerCase()
          .includes(this.searchFieldText.toLowerCase().trim()) ||
        vehicle_no
          ?.toLowerCase()
          .includes(this.searchFieldText.toLowerCase().trim())
      );
    } else {
      return false;
    }
  }

  tripDetailModal(txnId: any, processName: any) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: txnId,
      processName: processName,
    };
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Slot Management" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-2 col-lg-3 col-sm-12">
                  <label class="required">{{"Dock" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedDestination($event)" [data]="destinationList"
                    display="destinationName" placeholder="Choose Dock"
                    [preSelected]="{destinationName:destinationName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="destinationValidator && destinationValidator.error">
                    <span>{{destinationValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-2 col-lg-3 col-sm-12">
                  <label class="required">{{"Product" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedMaterial($event)" [data]="materialList"
                    display="product_name" placeholder="Choose Material"
                    [preSelected]="{product_name:materialName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="materialNameValidator && materialNameValidator.error">
                    <span>{{materialNameValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-2 col-lg-6 col-sm-12">
                  <label class="required">{{"Start Time" | customTranslate}}</label>
                  <input class="form-control" type="time" (change)="slot.startTime && createSlot()" required
                    [(ngModel)]="slot.startTime" #name="ngModel" [disabled]="!toShow">
                  <div class="error" *ngIf="startTimeValidator && startTimeValidator.error">
                    <span>{{startTimeValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-2 col-lg-6 col-sm-12">
                  <label class="required">{{"End Time" | customTranslate}} </label>
                  <input class="form-control" type="time" min="00:00" max="24:00"
                    (change)="slot.endTime && createSlot()" required [(ngModel)]="slot.endTime" #name="ngModel"
                    [disabled]="!toShow">
                  <div class="error" *ngIf="endTimeValidator && endTimeValidator.error">
                    <span>{{endTimeValidator.msg}} </span>
                  </div>
                </div>


                <div class="form-group col-xl-2 col-lg-6 col-sm-12">
                  <label class="required">{{"Vehicle Per Slot" | customTranslate}}</label>
                  <input class="form-control" type="number" min=0 required [(ngModel)]="slot.vehiclePerSlot"
                    #name="ngModel" (input)="createSlot()" [disabled]="!toShow || slot.startTime >= slot.endTime">
                  <div class="error" *ngIf="vehiclePerSlotValidator && vehiclePerSlotValidator.error">
                    <span>{{vehiclePerSlotValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-2 col-lg-6 col-sm-12">
                  <label class="required">{{"No. Of Slots" | customTranslate}} </label>
                  <input class="form-control" type="number" min=0 required [(ngModel)]="slot.slotCount" #name="ngModel"
                    (input)="createSlot()" [disabled]="!toShow || slot.startTime >= slot.endTime">
                  <div class="error" *ngIf="slotCountValidator && slotCountValidator.error">
                    <span>{{slotCountValidator.msg}} </span>
                  </div>
                </div>

              </div>

              <div class="row">
                <ng-container *ngIf="slot.startTime && slot.endTime && slot.slotCount && slot.vehiclePerSlot">
                  <div class="form-group col-xl-12 col-lg-6 col-sm-12 slot"
                    *ngFor="let item of slotData; let i = index">
                    <div class="slot-data">
                      <label class="required">{{"Start Time" | customTranslate}} </label>
                      <input class="form-control" type="time" (change)="slotChecker($event,'startTime',i,false)"
                        required [(ngModel)]="item.startTime" #name="ngModel" [disabled]="!toShow">
                    </div>
                    <div class="slot-data">
                      <label class="required">{{"End Time" | customTranslate}} </label>
                      <input class="form-control" type="time" (change)="slotChecker($event,'endTime',i,false)" required
                        [(ngModel)]="item.endTime" #name="ngModel" [disabled]="!toShow">
                    </div>
                    <div class="slot-data">
                      <label for="my-input">{{("Vehicle Limit" | customTranslate)}} </label>
                      <input class="form-control" type="number" (change)="item.vehicleLimit"
                        [(ngModel)]="item.vehicleLimit" [ngModelOptions]="{standalone: true}"
                        [value]="item.vehicleLimit" [disabled]="true">
                    </div>
                    <div class="slot-data">
                      <label for="my-input">{{("Slot TAT" | customTranslate)}} </label>
                      <input type="time" class="form-control" (change)="item.slotTat" [(ngModel)]="item.slotTat"
                        [value]="item.slotTat" [disabled]="true">
                    </div>
                  </div>
                </ng-container>



              </div>
              <div class="form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-muted" (click)="common.backClicked()"> {{"back" | customTranslate}} </button>
                  <button class="btn btn-warning" (click)="reset()"> {{"reset" | customTranslate}} </button>
                  <button type="submit" (click)="formValidator(null)" class="btn btn-submit" *ngIf="toShow"> {{btn |
                    customTranslate}} </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Slot List" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body slot_table">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{"s.no." | customTranslate}}</th>
                    <th>{{"dock_name" | customTranslate}}</th>
                    <th>{{"product_name" | customTranslate}}</th>
                    <th>{{"Start Time " | customTranslate}}</th>
                    <th>{{"End Time" | customTranslate}}</th>
                    <th>{{"No. Of Slots" | customTranslate}}</th>
                    <th>{{"Vehicle Per Slot" | customTranslate}}</th>
                    <th>{{"status" | customTranslate}}</th>
                    <th class="action"> {{"action" | customTranslate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor=" let item of slotTableData; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.destinationName}}</td>
                    <td>{{item.product_name}}</td>
                    <td>{{item.startTime}}</td>
                    <td>{{item.endTime}}</td>
                    <td>{{item.slotCount}}</td>
                    <td>{{item.vehiclePerSlot}}</td>
                    <td class="badge-status">
                      <span *ngIf="item.new_available" class="shrink-badge"
                        [ngStyle]="{'background-color' : item.status == 'New' ? 'rgb(185, 233, 250)' : 'rgb(255 200 200)'}">
                        {{(item.status == 'Active' ? "Ends at: " : "Starts at: " )+
                        (addDaysToDate(item.new_available,item.status == 'Active' ? item.allowed_future_booking_before : item.allowed_future_booking_before+1))}}
                      </span>
                      <span class="shrink-badge" *ngIf="!item.new_available">
                        Currently Active
                      </span>
                    </td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="view(item)"
                          *ngIf="item.status == 'Active' && item.new_available"> {{"view" | customTranslate}} </button>
                        <button class="btn btn-success" (click)="toShow=true;edit(item)"
                          *ngIf="item.status == 'New' || !item.new_available"> {{"edit" | customTranslate}} </button>
                        <button class="btn btn-danger" (click)="delete(item)" *ngIf="item.status == 'New'"> {{"delete" |
                          customTranslate}} </button>
                      </div>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

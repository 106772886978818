import { Router } from '@angular/router';
import { ApiService } from 'src/app/@core/services/api.service';
import { FormBuilder,FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  user = {
    userName: null,
    password: null,
    newPassword: null,
    checkPassword: null
  }
otpvalidated:boolean = false;
showmessage: boolean = false;
loginfail: boolean = false;
loginmessage: string = '';
resetpassword:any =  FormGroup;
message:string = '';
constructor(private formBuilder: FormBuilder,
  public router: Router,
  public breadcrumbService: BreadcrumbService,
  public api: ApiService,
  public userService : UserService
) {
/** breadcums implementation start*/
let breadcumbs = [
  { name: "Home", url: "/user/dashboard" },
  { name: "Change Password", url: "/user/reset-password" },
]
this.breadcrumbService.getBreadcrumbs(breadcumbs);
/** breadcums implementation end*/

  }



authorizeUser(){
  this.showmessage = false;
          this.loginfail = false;
          this.loginmessage = '';
    let params = {
      userName: localStorage.getItem('userName'),
      password: this.user.password,
    }
    let apiUrl = "user/verify-password";

    this.api.post(apiUrl, params)
      .subscribe((res:any) => {
    if(res.message == "Authorized")
        this.otpvalidated = true;
        else{
          this.showmessage = true;
          this.loginfail = true;
          this.loginmessage = "Incorrect Password";
        }
      }, err => {
        console.error('Error: ', err);
      });

}


savePassword(){

if(this.user.newPassword == this.user.checkPassword){
  this.message = '';
  let params = {
    userId : this.userService._loggedInUser.id,
    newPassword: this.user.newPassword,
  }
  let apiUrl = "user/update-password";
  this.message = '';
  this.api.post(apiUrl, params)
    .subscribe((res:any) => {
      this.router.navigate(['/user/dashboard']);
      this.otpvalidated = false
      this.user.password = null;
      this.user.newPassword = null;
      this.user.checkPassword = null;
    }, err => {
      console.error('Error: ', err);
    });
   }
   else
   {
     this.message = "Password does not match";
   }
}



  ngOnInit(): void {
  }

}

<div class="card main-card" *ngIf="userService.permissionMap.get('stage_dashboard_access')==true">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{"stage_dashboard" | customTranslate}} - <span>{{tmgdashboard.currentProcess}}</span>
      </h3>
    </div>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="card primary stage-card">
          <smart-header [title]="'Week wise TAT Trend (Average hr)' | customTranslate" [DataSet]="'chart'"
            (onSelected)="tatTrendSelect($event)" (onReset)="tatTrendData()">
          </smart-header>

          <div class="card-body">
            <div style="display: block; height: 250px;">
              <canvas baseChart height="inherit" [datasets]="weekWiseChartData" [labels]="tattrendChartLabels"
                [options]="tattrendChartOptions" [colors]="barChartColors" [legend]="tattrendChartLegend"
                [chartType]="tattrendChartType"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card success stage-card" #variation>
          <smart-header [title]="'Worst 3 Stages - Variation (7 days)' | customTranslate" [data]="worststagefilter"
            (onSelected)="stageVariation($event)" (onReset)="stageVariationData()">
          </smart-header>
          <div class="card-body">
          </div>
        </div>
      </div>

      <div class="col-md-6" #tatvariation>
        <div class="card danger stage-card">
          <smart-header [title]="'TAT Variation (7 days- average hrs)' | customTranslate" DataSet="chart"
            (onSelected)="stageVariationSelect($event)" (onReset)="StageTatVariationData()">
          </smart-header>

          <div class="card-body">
            <div style="display: block; height: 250px;">
              <canvas baseChart height="inherit" [datasets]="tatvariationChartData" [labels]="tatvariationChartLabels"
                [options]="tatvariationChartOptions" [colors]="tatvariationChartColors"
                [legend]="tatvariationChartLegend" [chartType]="tatvariationChartType"></canvas>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>

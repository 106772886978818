import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
breadcrumbs:any = [];
private showBreadcrumbs: boolean = true;
  constructor() {
  }

  getBreadcrumbs(data:any=[]){
    this.setActiveBreadCrumb(true);
    this.breadcrumbs = [];
    data.map((dt:any)=>{
      let b = {
        name:dt.name,
        url:dt.url
      }
      this.breadcrumbs.push(b);
    })
    // console.log("breadcrumbs",this.breadcrumbs);
  }

  setActiveBreadCrumb(val: boolean = true) {
    this.showBreadcrumbs = val;
  }

  getActiveBreadCrumb() {
    return this.showBreadcrumbs;
  }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import {
  COMMON_TRIPS_ACTION_BUTTON,
  TABLE_TYPE,
  TableActions,
  TableColumns,
} from 'src/app/dataType/customTableInterface';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { StageService } from '../stage/stage.service';
import { Process } from 'src/app/dataType/commonInterface';
import { TableService } from 'src/app/@core/services/table.service';

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.scss'],
})
export class OrderReportComponent implements OnInit {
  // Datatable Configs
  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  loading: boolean = false;
  columns: TableColumns[] = [];
  modalColumns: TableColumns[] = [];
  originalData: [] = [];
  originalModalData: [] = [];
  tableData: [] = [];
  modalData: [] = [];

  tabData = [
    {
      tabname: 'Trip Wise',
      url: 'get-tripwise-order-report',
      key: 'trip',
    },
    {
      tabname: 'Order Wise',
      url: 'get-dispatch-order-report',
      key: 'order',
    },
    {
      tabname: 'Invoice Wise',
      url: 'get-invoice-order-report',
      key: 'invoice',
    },
  ];

  activeTab: any;

  today: Date = this.common.dateFormatter(new Date(), 'YYYYMMDD', false);
  startDate: Date = this.common.dateFormatter(
    new Date(new Date().setDate(new Date().getDate() - 6)),
    'YYYYMMDD',
    false
  );
  endDate: Date = this.common.dateFormatter(new Date(), 'YYYYMMDD', false);

  processList: Process[] = [];
  processId: number = null;

  statusColors = {
    COMPLETED: 'bg-green-200 text-green-800',
    INPROGRESS: 'bg-yellow-200 text-yellow-800',
    CANCELLED: 'bg-pink-200 text-pink-800',
    MAINTAINENCE: 'bg-orange-200 text-orange-800',
    BOOKED: 'bg-blue-200 text-blue-800',
  };

  filterData: any[] = [];
  modalFilterData: any[] = [];

  @ViewChild('ModalView') contentTemplateRef: TemplateRef<any>;
  activeModal: any;

  constructor(
    public common: CommonService,
    public api: ApiService,
    public alert: AlertService,
    public stageService: StageService,
    public breadcrumbService: BreadcrumbService,
    public modalService: NgbModal,
    private translateService: TranslateService,
    private tableService: TableService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Order Report', link: '/user/order-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit() {
    this.activeTab = this.tabData[0];
    this.getReportData(this.activeTab.url);
    this.getAllProcess();
  }

  changeTab(selectedTab: any) {
    this.activeTab = selectedTab;
    this.resetReport();
  }

  selectProcess(event: any) {
    this.processId = event.processId;
    this.getReportData(this.activeTab.url);
  }

  resetReport() {
    this.processId = null;
    this.startDate = this.common.dateFormatter(
      new Date(new Date().setDate(new Date().getDate() - 6)),
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(new Date(), 'YYYYMMDD', false);
    this.getReportData(this.activeTab.url);
  }

  getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      processType: 'Trip',
    };
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.processList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getReportData(url: string, extraParams: {} = {}, isModal: boolean = false) {
    this.common.loading = true;

    let params = {
      startDate: this.startDate,
      endDate: this.endDate,
      processId: this.processId,
      ...extraParams,
    };
    this.api.get('report/' + url, params).subscribe(
      (res: any) => {
        this.common.loading = false;

        //intialize data
        if (isModal) {
          this.originalModalData = res.data;
          this.modalData = res.data;
          this.modalFilterData = this.createFilters(res.data);
          this.modalColumns = this.columnMaker('order');
        } else {
          this.originalData = res.data;
          this.tableData = this.originalData;
          this.filterData = this.createFilters(res.data);
          this.columns = this.columnMaker(this.activeTab.key);
        }

      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectFilter(
    event: any,
    filterIndex: number,
    filterName: string = 'filterData',
    tableName: string = 'tableData',
    rawData: string = 'originalData'
  ) {
    //Updates Selected Content to filter
    this[filterName][filterIndex].selected = event.map((res) => res.value);

    //filter the original data
    this[tableName] = this[rawData].filter((res) => {
      for (const value of this[filterName]) {
        //any filter doesn't match break & return
        if (value.selected.length && !value.selected.includes(res[value.key]))
          return false;
      }
      return true;
    });
  }

  createFilters(rawData: []) {
    let existMaintainer = {};

    //filter intialization
    let filterData = [
      {
        key: 'stage_workflow_status',
        values: [],
        selected: [],
        title: 'trip_status',
      },
      {
        key: 'vehicle_rc_no',
        values: [],
        selected: [],
        title: 'vehicle_number',
      },
      {
        key: 'order_name',
        values: [],
        selected: [],
        title: 'do_number',
      },
      {
        key: 'invoice_no',
        values: [],
        selected: [],
        title: 'invoice_no',
      },
      {
        key: 'order_type',
        values: [],
        selected: [],
        title: 'order_type',
      }
    ];

    //maintainer intialization
    filterData.forEach((element) => {
      existMaintainer[element.title] = [];
    });

    rawData.forEach((res) => {
      filterData.forEach((filter) => {

        //check value exist in maintainer
        if (
          res[filter.key] &&
          !existMaintainer[filter.title].includes(res[filter.key])
        ) {
          //doesn't exist -- update maintainer && add value to filter list
          existMaintainer[filter.title].push(res[filter.key]);
          filter.values.push({
            value: res[filter.key],
          });
        }
      });
    });

    return filterData;
  }

  columnMaker(makerKey: string) {
    //bool key to filter column
    let isInvoice = makerKey == 'invoice';

    //column intialization
    let columns = [
      !isInvoice && {
        columnLabel: this.translateService.instant('do_number'),
        columnName: 'order_name',
      },
      !isInvoice && {
        columnLabel: this.translateService.instant('vehicle_number'),
        columnName: 'vehicle_rc_no',
      },
      !isInvoice && {
        columnLabel: this.translateService.instant('token'),
        columnName: 'txn_token',
      },
      !isInvoice && {
        columnLabel: this.translateService.instant('start_time'),
        columnName: 'start_time',
      },
      !isInvoice && {
        columnLabel: this.translateService.instant('end_time'),
        columnName: 'end_time',
      },
      !isInvoice && {
        columnLabel: 'trip_status',
        columnName: 'stage_workflow_status',
        dataFormatter: (row: any, column: TableColumns, index: number) => {
          const pillClass = this.statusColors[row.stage_workflow_status];
          return `<span class="pill ${pillClass}">${row.stage_workflow_status}</span>`;
        },
      },
      {
        columnLabel: this.translateService.instant('invoice_no'),
        columnName: 'invoice_no',
      },
      {
        columnLabel: this.translateService.instant('client'),
        columnName: 'client',
      },
      {
        columnLabel: this.translateService.instant('client_email'),
        columnName: 'client_email',
      },
      {
        columnLabel: this.translateService.instant('product'),
        columnName: 'materials',
      },
      {
        columnLabel: this.translateService.instant('invoice_type'),
        columnName: 'order_type',
      },
      {
        columnLabel: this.translateService.instant('quantity'),
        columnName: isInvoice ? 'quantity' : 'order_weight',
      },
      isInvoice && {
        columnLabel: this.translateService.instant('quantity_unit'),
        columnName: 'quantity_unit',
      },
      !isInvoice && {
        columnLabel: this.translateService.instant('Gross Weight'),
        columnName: 'gross_weight',
      },
      !isInvoice && {
        columnLabel: this.translateService.instant('Tare Weight'),
        columnName: 'tare_weight',
      },
      !isInvoice && {
        columnLabel: this.translateService.instant('Net Weight'),
        columnName: 'net_weight',
      },
      isInvoice && {
        columnLabel: this.translateService.instant('do_orders'),
        columnName: '',
        extraData: 'do_orders',
        dataActions: [
          {
            actionName: '',
            actionFormatter: (
              column: TableColumns,
              row: any,
              index: number
            ) => {
              return row[column.extraData] > 0
                ? `${
                    row[column.extraData]
                  } <i class='fa fa-info-circle ml-2'></i>`
                : row[column.extraData];
            },
            handleClick: (
              column: TableColumns,
              row: any,
              index: number,
              actionName: string
            ) => {
              this.openModal(row.invoice_id);
            },
          },
        ],
      },
      isInvoice && {
        columnLabel: this.translateService.instant('do_order_weight'),
        columnName: 'do_order_weight',
      },
      isInvoice && {
        columnLabel: this.translateService.instant('remaining_weight'),
        columnName: 'remaining_weight',
      },
      {
        columnLabel: this.translateService.instant('validity_date'),
        columnName: 'validity_date',
      },
      !isInvoice && {
        columnLabel: ``,
        columnName: 'tat',
        columnFormatter: (column, index) => {
          return `Trip <small style="font-size: 10px;">(Actual / Expected) TAT</small>`;
        },
        dataFormatter: (row: any, column: TableColumns, index: number) => {
          return `<span class="${
            row.tat > row.expected_tat ? 'badge over' : 'badge'
          }">${row.tat}</span>
                       / <span class="badge info">${row.expected_tat}</span>`;
        },
      },
      !isInvoice && {
        columnLabel: this.translateService.instant('Action'),
        columnName: null,
        dataActions: [
          ...this.tableService.getTripsButtons([
            COMMON_TRIPS_ACTION_BUTTON.TRIP_DETAIL,
            COMMON_TRIPS_ACTION_BUTTON.TRIP_TRACKING,
            COMMON_TRIPS_ACTION_BUTTON.TRIP_REMARK,
          ]),
        ] as TableActions[],
      },
    ];

    //filter out false (i.e. columns which didn't got intialized)
    columns = columns.filter((res: any) => res != false);

    return columns;
  }

  openModal(invoiceId: number) {
    this.getReportData(
      'get-dispatch-order-report',
      { invoiceId: invoiceId },
      true
    );
    this.activeModal = this.modalService.open(this.contentTemplateRef, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';

@Component({
  selector: 'app-hardware-status-change',
  templateUrl: './hardware-status-change.component.html',
  styleUrls: ['./hardware-status-change.component.scss'],
})
export class HardwareStatusChangeComponent implements OnInit {
  @Input() public refData;
  allStatus = [
    { label: 'MAINTAINENCE' },
    { label: 'ACTIVE' },
    { label: 'INACTIVE' },
  ];
  vehicle_status: any = '';
  constructor(
    public api: ApiService,
    public common: CommonService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal
  ) {}
  ngOnInit() {
    this.vehicle_status = this.refData.status;
  }

  changeStatus() {
    let params = {
      hardwareId: this.refData.hardwareId,
      hardwareStatus: this.vehicle_status,
    };
    this.common.loading = true;
    this.api.post('hardware/update_hardware_status', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.closeModal();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="tab_section">
        <div class="tab" *ngFor="let tabitem of tabData" [class.active]="activeSection == tabitem.value"
          (click)="onTabChange(tabitem.value)">
          <p>{{tabitem.tabname|translate}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"user_group_association" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <!-- <section class="open_sec"> -->
                <div class="page_design">
                  <div class="row tab_sec">
                    <div class="col-md-12 date_div">
                      <div class="row">
                        <div *ngIf="role.user_role == 'AXESTRACK'||role.user_role == 'AXESTRACKUSER'"
                          class="form-group col-xl-3 col-lg-6 col-sm-12">
                          <label for="my-input">{{"company" | translate}}</label>
                          <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="companyData" display="companyName"
                            placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                          </ps-auto-suggestion>
                        </div>
                        <div
                          *ngIf="role.user_role == 'AXESTRACK'||role.user_role == 'AXESTRACKUSER'||role.user_role == 'COMPANYADMIN'"
                          class="form-group col-xl-3 col-lg-6 col-sm-12">
                          <label for="my-input">{{"plant" | translate}}</label>
                          <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                            placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                          </ps-auto-suggestion>
                        </div>



                        <div class="col-md-12 user_group_associ">
                          <table class="custom-table" style="width:100%">
                            <thead class="thead">
                              <tr>
                                <th>{{"group" | translate}}</th>
                                <th>{{activeSection=='user' ? "users" : "Staff"| translate}}</th>
                                <!-- <th *ngIf="activeSection=='staff'">{{"staff" | translate}}</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <ps-auto-suggestion (onSelected)="activeSection=='user' ? getUserList($event) : getStaffList($event)" [data]="groupList" display="groupName"
                                    placeholder="Select Group" [preSelected]="{groupName:groupName}" inputId="name">
                                  </ps-auto-suggestion>
                                </td>
                                <td >
                                  <div *ngFor="let user of users; let i=index" class="row" style="margin-bottom: 10px;">
                                    <div class="col-10 pt-0 pb-0">
                                      <ps-auto-suggestion [data]="userList" (onSelected)="activeSection=='user' ? user.userId = $event.userId : user.userId = $event.staff_id"
                                        display="userName" [placeholder]="activeSection === 'user' ? 'Select User' : 'Select Staff'"
                                        [preSelected]="{userName:user.userName}" inputId="name">
                                      </ps-auto-suggestion>
                                    </div>
                                    <div class="col-2 p-0">
                                      <div class="d-flex">
                                        <button class="btn btn-danger remove mr-2"
                                          (click)="users.splice(i,1); users.length==0?addUser():''">
                                          <i class="fa fa-trash"></i> </button>
                                        <button class="btn btn-success add" (click)="addUser()" *ngIf="i == users.length-1">
                                          <i class="fa fa-plus"></i> </button>
                                      </div>


                                    </div>
                                  </div>
                                </td>

                                <!-- <td *ngIf="activeSection=='staff'">
                                  <div *ngFor="let user of users; let i=index" class="row" style="margin-bottom: 10px;">
                                    <div class="col-10 pt-0 pb-0">
                                      <ps-auto-suggestion [data]="userList" (onSelected)="user.userId = $event.staff_id"
                                        display="userName" placeholder="Select Staff"
                                        [preSelected]="{userName:user.userName}" inputId="name">
                                      </ps-auto-suggestion>
                                    </div>
                                    <div class="col-2 p-0">
                                      <div class="d-flex">
                                        <button class="btn btn-danger remove mr-2"
                                          (click)="users.splice(i,1); users.length==0?addUser():''">
                                          <i class="fa fa-trash"></i> </button>
                                        <button class="btn btn-success add" (click)="addUser()" *ngIf="i == users.length-1">
                                          <i class="fa fa-plus"></i> </button>
                                      </div>


                                    </div>
                                  </div>
                                </td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="col-md-12 action-block">
                          <div class="btn-block">
                            <button class="btn btn-submit" (click)="saveAssociation()"
                              *ngIf="userService.permissionMap.get('add_user_group_association_access')==true">{{"save" |
                              translate}}</button>
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              <!-- </section> -->


            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Destination Stage Mapping" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"destination_code" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedDestination($event)" [data]="destinationCodes"
                    display="stage_destination_code" placeholder="Choose Destination Code"
                    [preSelected]="{stage_destination_code:destinationCode}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="destinationCodeValidator && destinationCodeValidator.error">
                    <span>{{destinationCodeValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"process->stage" | translate}} </label>
                  <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedProcess($event)" [data]="process"
                    display="order_name" placeholder="Choose Process" [preSelected]="processName">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processStageValidator && processStageValidator.error">
                    <span>{{processStageValidator.msg}} </span>
                  </div>
                </div>

                <ng-container *ngIf="processName.length">
                  <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngFor="let item of processName;index as i">
                    <label for="my-input">{{item.order_name+("tat" | translate)}} </label>
                    <input type="time" class="field_class" (change)="updatedTat($event,i)"
                      [(ngModel)]="item.tat">
                  </div>
                </ng-container>
              </div>


              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | translate}}
                  </button>
                  <button class="btn btn-warning" (click)="reset()"> {{"reset" | translate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{buttonName | translate}}
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>



      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"stage_destination mapping list" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"s.no." | translate}} </th>
                    <th>{{"destination" | translate}} </th>
                    <th>{{"process->stage" | translate}} </th>
                    <th class="action">{{"action" | translate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of mappings let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.stage_destination_code}}</td>
                    <td style="white-space: pre-line"><ng-container *ngFor="let processStage of row.mapping_data">
                      {{processStage.order_name +"\n"}}
                    </ng-container>
                    </td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="view(row)"> {{"view" | translate}} </button>
                        <button class="btn btn-success" (click)="edit(row)"> {{"edit" | translate}} </button>
                        <button class="btn btn-danger" (click)="confirmAlert(row)"> {{"delete" | translate}} </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body stageType-form">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Zone Management' | translate}}
                </h3>
              </div>
              <div class="col-md-5d-flex pull-right" class="ml-2"
            *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN' ">

            <mat-form-field appearance="fill" class="mr-4" *ngIf="role.user_role == 'AXESTRACK'">
              <mat-label>{{"select_company" | translate}}</mat-label>
              <mat-select [(ngModel)]="companyId">
                <mat-option *ngFor="let  item1 of companyList;index as i" (click)="selectCompany(item1)"
                  [value]="item1.companyId">
                  {{item1.companyName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{"select_plant" | translate}}</mat-label>
              <mat-select [value]="plantName">
                <mat-option *ngFor="let item of plantList;index as i" (click)="selectedPlant(item)"
                  [value]="item.plantName">
                  {{item.plantName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
            </div>
            <div class="card-body" *ngIf="plantId != null || this.role.user_role == 'PLANTADMIN' || this.role.user_role == 'PLANTUSER'">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"zone_name" | translate}}</label>
                  <input class="form-control" placeholder="Enter Zone Name" type="text" name="zoneName"
                    [(ngModel)]="zoneStage.zoneName">
                  <div class="error" *ngIf="zoneNameValidator && zoneNameValidator.error">
                    <span>{{zoneNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="col-md-3 coll">
                  <label class="required">{{'zone_type'|translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedZoneType($event)" [data]="zoneTypeList" display="typeName"
                    placeholder="Select Zone Type" [preSelected]="{typeName:zoneTypeName}" [(ngModel)]="zoneStage.zoneTypeId">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="zoneTypeIdValidator && zoneTypeIdValidator.error">
                    <span>{{zoneTypeIdValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"zone_people_limit" | translate}}</label>
                  <input class="form-control" type="number" min="0" placeholder="Enter Zone People Limit"
                    name="zoneSlotLimit" [(ngModel)]="zoneStage.zonePeopleLimit">
                  <div class="error" *ngIf="zonePeopleLimitValidator && zonePeopleLimitValidator.error">
                    <span>{{zonePeopleLimitValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"zone_vehicle_limit" | translate}}</label>
                  <input class="form-control" type="number" min="0" placeholder="Enter Zone Vehicle Limit"
                    name="zoneSlotLimit" [(ngModel)]="zoneStage.zoneVehicleLimit">
                  <div class="error" *ngIf="zoneVehicleLimitValidator && zoneVehicleLimitValidator.error">
                    <span>{{zoneVehicleLimitValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"zone_status" | translate}}</label>
                  <select name="zone_status" class="form-control" [(ngModel)]="zoneStage.status">
                    <option selected="ACTIVE" value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </select>
                </div>

                <div class="form-group col-md-4 col-sm-12">
                  <label for="my-input">{{"is_zone_restricted" | translate}}<input type="checkbox"
                      class="form-control set-checkbox" [(ngModel)]="zoneStage.isRestrictedZone">
                  </label>
                </div>

              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    translate}}</button>
                  <button class="btn btn-warning" (click)="reset(true)">{{"reset" | translate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | translate}} </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"zone_list" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"s.no" | translate}}</th>
                    <th>{{"plant_name" | translate}}</th>
                    <th>{{"zone_name" | translate}}</th>
                    <th>{{"zone_type_name" | translate}}</th>
                    <th>{{"zone_people_limit" | translate}}</th>
                    <th>{{"zone_vehicle_limit" | translate}}</th>
                    <th>{{"status" | translate}}</th>
                    <th class="action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of zones let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.plant_name}}</td>
                    <td>{{row.zone_name}}</td>
                    <td>{{row.type_name}}</td>
                    <td>{{row.zone_people_limit}}</td>
                    <td>{{row.zone_vehicle_limit}}</td>
                    <td>{{row.status}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="view(row)">{{"view" | translate}}</button>
                        <button class="btn btn-success" (click)="edit(row)">{{"edit" | translate}}</button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)">{{"delete" | translate}}</button>
                      </div>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</div>

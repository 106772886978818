import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';

@Component({
  selector: 'app-status-management',
  templateUrl: './status-management.component.html',
  styleUrls: ['./status-management.component.scss']
})
export class StatusManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'User Department Mapping');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  //global variable

  status = {
    statusId: null,
    statusName: '',
    description : null,
    statusType: 'Asset',
    status: 1,
    companyId: null,
    plantId: null,
  }

  title = 'Add Status';
  btn = 'save';

  statusScope : any = null;
  companyName: null;
  plantName: null;
  companyList: any = [];
  plantList: any = [];
  statusList :any =[];

  statusNameValidator: any;
  companyValidator: any;
  plantValidator: any;
  plantLevelRoles = ['AXESTRACKUSER' , 'COMPANYUSER' , 'PLANTADMIN', 'PLANTUSER'];



  // Constructor
  constructor(
    private alertService: AlertService,
    private api: ApiService,
    private plantService: PlantService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Status', url: null },
      { name: 'Status Management', url: '/user/users' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }



  ngOnInit() {
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    }else{
      if (this.role.user_role != 'AXESTRACK'){
        this.getPlantById(this.userService._loggedInUser.companyId);
      }
    }
    this.statusScope = this.role.user_role == 'AXESTRACK' ? 'GLOBAL' : 'COMPANY'
    this.getStatusList();
  }


  // custome functions

  selectedCompany(event: any) {
    this.status.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.status.plantId = null;
  }

  selectedPlant(event: any) {
    this.status.plantId = event.plantId;
    this.plantName = event.plantName;
  }

  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }


  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantList = res.data;
          resolve(this.plantList);
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
        reject(null);
      }
    );
  });
  }



  saveStatus(){
    this.common.loading = true;
    let params = {
      statusId : this.status.statusId,
      statusName : this.status.statusName.trim(),
      statusDescription : this.status.description!=null ? this.status.description.trim() : this.status.description,
      statusType : this.status.statusType,
      status : this.status.status,
      plantId : this.status.plantId,
      companyId : this.status.companyId,
      userRole : this.role.user_role
    };
    let apiUrl = 'status/save-status';
    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if(res.message=="Name Already Exists" || res.message=="Can't Inactive status , mapped to some entity"){
            this.alertService.warn(res.message);
          }
          else{
            this.alertService.success(res.message);
            this.resetDetail();
          }
          this.getStatusList();
        } else {
          this.common.loading = false;
          this.alertService.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );

  }

 async editRow(row:any){
    console.log('row: ', row);
    this.common.gotoTop();
    this.resetDetail();
    if(this.role.user_role == 'AXESTRACK' || this.role.user_role== 'COMPANYADMIN'){
      this.statusScope = row.plant_id == 1 ? 'GLOBAL' : row.plant_id == null ? 'COMPANY' : 'PLANT';
    }
    await this.getPlantById(row.company_id);
    this.companyName = this.companyList.find((company) => company.companyId == row.company_id)?.companyName;
    this.plantName = this.plantList.find(plant => plant.plantId == row.plant_id)?.plantName ;
    this.btn = 'Update';
    this.title = 'Edit Status';
    this.status.statusId = row.status_id;
    this.status.statusName =  row.status_name;
    this.status.statusType =  row.status_type;
    this.status.description = row.status_description;
    this.status.status = row.status;
    this.status.plantId = row.plant_id;
    this.plantList=null;
    this.status.companyId =  row.company_id;
  }

  viewDetails(row?:any){
  console.log('row: ', row);
  const statusDetails={
    company_name: row.company_name,
    plant_name: row.plant_name,
    status: row.status,
    status_description: row.status_description,
    status_name: row.status_name,
    status_type: row.status_type
  }
    this.common.params = { details: [statusDetails], title: 'Status Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }


  confirmAlert(row:any){
    this.common.params = {
      title: 'Delete Status ',
      description: `<b>&nbsp;` + 'Are you sure want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row.status_id, row.status_type);
      }
    });

  }

  delete(statusId:any , statusType:any) {
    let params = {
      statusId : statusId,
      statusType : statusType
    }
    this.common.loading = true;
    this.api.get('status/delete-status',params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.alertService.info(res.message);
          this.resetDetail();
          this.getStatusList();
        },
        (err) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  resetDetail(){
    this.btn = 'Save';
    this.status.statusId = null;
    this.status.statusName = null;
    this.status.description = null;
    this.status.statusType = 'Asset';
    this.status.status = 1;
    this.status.plantId= null;
    this.status.companyId = null;
    this.statusNameValidator = null;
    this.plantValidator = null;
    this.companyValidator = null;
    this.companyName = null;
    this.plantName = null;
    this.statusScope = this.role.user_role == 'AXESTRACK' ? 'GLOBAL' : 'COMPANY';
    this.plantList=[];
  }

  getStatusList(){
    this.common.loading = true;
    let params={
      allStatus:true
    }
    this.api
      .get('status/get-status-list',params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.statusList = res.data;
          this.renderTable();

        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );

  }

  formValidator() {
    this.statusNameValidator = this.common.nullValidator(
      this.status.statusName.trim(),
      'Status Name Mandatory'
    );
    this.companyValidator = this.common.nullValidator(
      this.status.companyId,
      'Company is Mandatory'
    );
    this.plantValidator = this.common.nullValidator(
      this.status.plantId,
      'Plant is Mandatory'
    );
    if(this.role.user_role == 'AXESTRACK' && this.statusScope == 'COMPANY'){
      if (!this.statusNameValidator.error && !this.companyValidator.error) {
        this.saveStatus();
      }
    }else if(this.role.user_role == 'AXESTRACK' && this.statusScope == 'PLANT'){
      if (!this.statusNameValidator.error && !this.companyValidator.error && !this.plantValidator.error) {
        this.saveStatus();
      }
    }
    else{
      if (!this.statusNameValidator.error) {
        this.saveStatus();
      }
    }

  }

}

<div class="modal-header" *ngIf="isModal">
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title text-uppercase font-weight-bold">Trip Tracking</h4>
</div>

<div [class.modal-body]="isModal" class="driver_tracker">
  <div class="container-fluid">
    <div class="container-card" style="margin-top: 5px;">
      <div class="container-card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card">

              <div class="card-header">
                <div class="header-block">
                  <h3 class="card-title">
                    {{(personIcon?"Visit Tracking":"trip_tracking" )| translate}}
                  </h3>
                </div>
              </div>

              <div class="search_block" *ngIf="showHeader==true">
                <div class="row m-0">
                  <div class="col-md-3">

                  </div>

                  <div class="col-md-3">
                    <div class="form-group" *ngIf="role.user_role == 'COMPANYADMIN'">
                      <div class="row align-items-center">
                        <div class="col-md-4">
                          <label for="my-input" class="float-right mb-0">{{"select_plant" | translate}}:</label>
                        </div>
                        <div class="col-md-8 pl-0">
                          <ps-auto-suggestion (onSelected)="selectPlant($event)" [data]="plantList" display="plantName"
                            placeholder="Select Plant" [preSelected]="{plantName:this.transaction.name}">
                          </ps-auto-suggestion>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col-md-3">
                    <div class="form-group">
                      <div class="row align-items-center">
                        <div class="col-md-4 padding_class" style="padding:0px !important">
                          <label for="my-input" class="mb-0">{{"trancation_id" | translate}}:</label>
                        </div>

                        <div class="col-md-8">
                          <ps-auto-suggestion (onSelected)="selecttransaction($event)" [data]="vehicleTransactionData"
                            display="txnid" placeholder="Select Transaction " name="selectedActionData"
                            inputId="selectedActionData" [preSelected]="{txnid:this.transaction.name}">
                          </ps-auto-suggestion>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <div class="row align-items-center ">
                        <div class="col-md-4 padding_class" style="padding:0px !important">
                          <label for="my-input" class="mb-0">{{"vehicle_id" | translate}}:</label>
                        </div>

                        <div class="col-md-8">
                          <ps-auto-suggestion (onSelected)="vechileData($event)" [data]="vehicleData"
                            display="vehicle_rsn" placeholder="Select Vehicle" name="selectedActionData"
                            inputId="selectedActionData" [preSelected]="{vehicle_rsn:this.vehicle.name}">
                          </ps-auto-suggestion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body" *ngIf="vehicleTransactionStatus && vehicleTransactionStatus.length">
                <h3 class="main-title">{{this.name}}</h3>
                <div class="status-tracker">
                  <div class="track-block" *ngFor="let item of vehicleTransactionStatus;index as i">
                    <div class="track-line"></div>

                    <div class="main-block"
                      [ngClass]="{'blink_box' : item.status === 'current', 'green_clr' :item.status === 'completed', 'yellow_clr' :item.status === 'current', 'gray_clr' :item.status === 'pending' || item.status === 'on_the_way'}">
                      <div class="row mr-0 ml-0">
                        <div class="col-md-3 left_sec">
                          <div class="tat">
                            <p>{{"expected_tat" | translate}}</p>
                            <div class="box">{{item.expected_tat}}</div>
                          </div>
                          <div class="tat mt-2">
                            <p>{{"actual_tat" | translate}}</p>
                            <div class="box"
                              [ngClass]="{'blink_tat_box' :item.actual_tat > item.expected_tat, 'red_clr' :item.actual_tat > item.expected_tat}">
                              {{item.actual_tat}}</div>
                          </div>
                        </div>
                        <div class="col-md-9 right_sec">
                          <h5 class="flex-view">{{item.stage_name}}
                            <div class="icon ml-3">
                              <img src="assets/images/tracking-icon-ai.png" style="height:19px;"
                                (click)="openTrackingLog(item.ipa_transaction_stage_detail_id)">
                            </div>
                          </h5>
                          <div class="row m-0 describe">
                            <div class="col-md-4 pr-0">
                              <div class="name">{{"destination" | translate}} :</div>
                            </div>

                            <div class="col-md-8">
                              <div class="value"> {{item.destination}}</div>
                            </div>
                          </div>

                          <div class="row m-0 describe">
                            <div class="col-md-4 pr-0">
                              <div class="name">{{"attendent_by" | translate}} :</div>
                            </div>

                            <div class="col-md-8">
                              <div class="value"> {{item.attend_by}}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="time_div">
                        <div class="in_time time" style="display:flex">
                          <p><b>In Time: </b>{{item.time_in | date:'dd-MM-yy hh:mm:ss a' }}<i class="fa fa-user ml-2"
                              *ngIf="item.time_in && !item.system_in && item.created_by != -2"
                              style="font-size:14px"></i><i
                              *ngIf="item.time_in && item.system_in && item.created_by != -2" class="fa fa-desktop ml-2"
                              style="height:16px"></i> <img src="assets/images/trip-ai.png"
                              *ngIf="item.time_in && item.created_by == -2"
                              style="height:18px;margin-left: 4px;scale: 1.15;"></p>
                        </div>

                        <div class="out_time time" style="display:flex">
                          <p><b>Out Time: </b>{{item.time_out| date:'dd-MM-yy hh:mm:ss a'}}<i class="fa fa-user ml-2"
                              *ngIf="item.time_out && !item.system_out && item.modified_by != -2"
                              style="font-size:14px"></i><i
                              *ngIf="item.time_out && item.system_out && item.modified_by != -2"
                              class="fa fa-desktop ml-2" style="height:16px"></i> <img src="assets/images/trip-ai.png"
                              *ngIf="item.time_out && item.modified_by == -2"
                              style="height:18px;margin-left: 4px;scale: 1.15;"></p>
                        </div>
                      </div>
                    </div>

                    <div class="middle-block">
                      <div class="circle"
                        [ngClass]="{'green_clr': item.status === 'completed', 'yellow_clr': (item.status === 'current' && !personIcon) || item.status === 'on_the_way', 'gray_clr': item.status === 'pending'}">
                      </div>
                    </div>


                    <div class="middle-block">
                      <div class="single_line" [ngClass]="{'line_blink': item.status === 'on_the_way', 'line_single': item.status === 'completed' || item.status === 'current',
                        'truck-icon': !personIcon,
                        'person-icon': personIcon
                        }">

                      </div>


                      <div class="middle-block">
                        <div class="current_vehicle" [ngClass]="{'current_vehicle': item.status === 'current',
                            'truck-icon': !personIcon,
                            'person-icon': personIcon
                        }">

                        </div>
                      </div>

                    </div>

                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>

import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WebcamImage } from 'ngx-webcam';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Base64ToFileService } from 'src/app/@core/services/base64-to-file-service.service';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-remark',
  templateUrl: './remark.component.html',
  styleUrls: ['./remark.component.scss'],
})
export class RemarkComponent implements OnInit {
  retakeImage: boolean = false;
  otherImageUpload: boolean = false;
  response = [];
  showCamera: any[] = [];
  showRemarkBox: any[] = [];
  fileEvent: any = [];
  refData: any;
  otherRemark: boolean = false;

  constructor(
    public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    private base64ToFileService: Base64ToFileService,
    private activeModal: NgbActiveModal,
    private imageCompress: NgxImageCompressService,
    private alert: AlertService
  ) {
    this.refData = this.common.params.refData;
    // this.refData = [{ actionId: 23, actionName: 'Machine Maintenance' }, { actionId: 24, actionName: 'Upload RC' }];
    this.addJsontoResponse(this.refData);
  }

  ngOnInit(): void {}

  addJsontoResponse(refData: any) {
    let size = refData.length;
    for (let i = 0; i < size; i++) {
      this.response.push({
        reference_id: refData[i].actionId,
        reference_type: 'action',
        remark: null,
        fileUrl: null,
      });
      this.showCamera.push(false);
      this.showRemarkBox.push(false);
    }
    this.response.push({
      reference_id: null,
      reference_type: 'other',
      remark: null,
      fileUrl: null,
    });
  }

  handleImage(webcamImage: WebcamImage, i: number) {
    this.onSelect(webcamImage, null, i);
  }

  enterResponse(event: any, item: any, index: number, type: string) {
    this.response[index].remark = event.target.value;
  }

  closeModal(response?: any, apiHit?: any, result?: any) {
    this.activeModal.close({
      response: response,
      apiHit: apiHit,
      result: result,
    });
  }

  saveRemark() {
    let count = this.response.filter((item) => item.remark !== null).length;
    if (count > 0) {
      this.closeModal(true, 1, this.response);
    } else {
      this.alert.info('Please enter the reason...');
    }
  }

  onSelect(e, methodId, i) {
    let frmData = new FormData();
    let imgResultAfterCompression: DataUrl;
    let file;
    this.imageCompress
      .compressFile(e._imageAsDataUrl, -2, 50, 50) // 50% ratio, 50% quality
      .then((compressedImage) => {
        imgResultAfterCompression = compressedImage;
        file = this.base64ToFileService.base64toFile(
          imgResultAfterCompression,
          'image' + i + '.jpg'
        );
        frmData.append('files', file);
        this.fileEvent.inProgress = true;
        this.uploadFile(frmData, i);
      });
  }

  uploadFile(frmData: any, i: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders();
      this.api
        .post('functional/upload-file?methodId=' + 17, frmData)
        .subscribe((res: any) => {
          switch (res.data.type) {
            case HttpEventType.UploadProgress:
              this.fileEvent.progress = Math.round(
                (res.data.loaded * 100) / res.data.total
              );
              break;
            case HttpEventType.Response:
              return res.data;
          }
          res.data.forEach((e) => {
            this.response[i].fileUrl = e.fileUrl;
          });
          resolve(res.data);
        });
      catchError((error: HttpErrorResponse) => {
        this.fileEvent.inProgress = false;
        reject(null);
        return `${this.fileEvent.data.name} upload failed.`;
      });
    });
  }
}

import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { AutomationService } from '../automation/automation.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { Component, OnInit } from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { CommonService } from 'src/app/@core/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: [
    '../automation/automation.component.scss',
    './actions.component.scss',
  ],
})
export class ActionsComponent implements OnInit {
  process = [] as any;
  state: any;
  selActionId: any = null;
  selActionOrderId: any = null;
  selActionStatus: any = null;
  processId: any;
  actionsList: any = [];
  actions = [];
  constructor(
    private action: AutomationService,
    public alertService: AlertService,
    public breadcrumbService: BreadcrumbService,
    public common: CommonService,
    private modalService: NgbModal
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Actions', url: '#' },
      { name: 'Process Stage Configuration', url: '/user/action' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit() {
    this.getProcess(0);
  }


  getEvent(event: any) {
    this.processId = this.process[event.index].id;
    this.getAllActions();
  }

  onActionAdd(event: any, order: any, order_id) {
    this.selActionId = event.action_id;
    this.selActionOrderId = order_id;
    this.selActionStatus = order;
    this.saveStage(0);
    this.setExitColour();
  }

  setExitColour() {
    setTimeout(() => {
      document.querySelectorAll('.ng-value').forEach((value) => {
        if (value.innerHTML.indexOf('(EXIT ACTION)') != -1) {
          value.setAttribute('style', 'background-color: #ca0000;');
        }
      });
    }, 500);
  }

  onActionRemove(event: any, order: any, order_id) {
    this.selActionId = event.value.action_id;
    this.selActionOrderId = order_id;
    this.selActionStatus = order;
    this.saveStage(0);
    this.setExitColour();
  }

  getProcess(id: number) {
    this.processId = id == 0 ? id : this.processId;
    this.common.loading = true;
    this.action.getAllProcess().subscribe((processes) => {
      this.actions = processes.data;
      this.actions.map((item: any) => {
        this.process.push({
          id: item.processId,
          name: item.processName,
        });
      });
      this.processId = this.process[0].id;
      this.getAllActions();

      this.common.loading = false;
    });
  }

  getAllActions() {
    this.common.loading = true;
    this.action
      .getAllActionsByProcessId(this.processId)
      .subscribe((item: any) => {
        this.actionsList = item.data;
        this.actionsList.forEach((e) => {
          if (e.action_map_on == 0) {
            e.action_name = e.action_name + '  (EXIT ACTION)';
          }
        });
        this.getAllStageActions();
        this.common.loading = false;
      });
  }

  getAllStageActions() {
    this.common.loading = true;
    this.action.getallstageaction(this.processId).subscribe((item: any) => {
      this.actions = item.data;
      this.setExitColour();
      this.common.loading = false;
    });
  }



  saveStage(deleteFlag: any) {
    let selActionStage = this.actions.filter(
      (a) => a.order_id == this.selActionOrderId
    );
    if (selActionStage.length > 0) {
      let data = {
        delete: deleteFlag,
        action_status: this.selActionStatus,
        order_id: this.selActionOrderId,
        action_ranking_index: this.selActionStatus == -1 ? selActionStage[0].pre_actions.length + 1 : this.selActionStatus == 0 ? selActionStage[0].current_actions.length + 1: selActionStage[0].post_actions.length + 1,
        action_id: this.selActionId,
      };
      this.common.loading = true;
      this.action.saveActions(data).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (!res.status) {
            if (res.type == 'INFO') {
              this.confirmDeleteAction(res.message);
            } else {
              this.alertService.error(res.message);
            }
          }
          this.getAllStageActions();
        },
        (err) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    }
  }

  confirmDeleteAction(status) {
    this.common.params = {
      title: 'Delete Action ',
      description: `<b>&nbsp;` + status + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.saveStage(1);
      }
    });
  }

  onDrop(event: CdkDragDrop<string[]>) {
    let arr = [] as any;
    moveItemInArray(this.process, event.previousIndex, event.currentIndex);
    this.process.forEach((item: any, idx: any) => {
      item.temp_id = idx + 1;
      arr.push(item);
    });

    this.process = arr;
  }


}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { DisplayImageComponent } from '../display-image/display-image.component';

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss'],
})
export class ViewDetailsComponent implements OnInit {
  numbers: any = [];
  data: any = [];
  items: any;
  apiURL: any;
  parameters = null;
  title = 'Detail';
  isBtn = false;
  show:boolean = true;
  imgUrl:string = null;
  constructor(
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    this.show = this.common.params.show != null ? false : true;
    if (this.common.params.details) {
      this.title = this.common.params.title;
      this.show ? this.viewData(this.common.params.details) : null;
    } else if (this.common.params.apiURL) {
      this.apiURL = this.common.params.apiURL;
      this.title = this.common.params.title;
      this.parameters = this.common.params.params;
      this.isBtn = this.common.params.isBtn;
    }
    if(this.common.params.imgUrl){
      this.imgUrl = this.common.params.imgUrl;
    }
  }
  ngOnDestroy() {}
  ngOnInit() {}
  getData() {
    this.common.loading = true;
    this.api.post(this.apiURL, this.parameters).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.viewData(res['data']);
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }
  viewData(detail: any) {
    let headings = Object.keys(detail[0]);

    for (let index = 0; index < headings.length; index++) {
      if (headings[index] != 'authtoken') {
        const header = headings[index];
        const value = header == 'password' ? '-----' : detail[0][header];
        const heading = this.camel2title(
          header.replace('workType', 'processType')
        );
        if (
          !header.startsWith('_') &&
          !header.endsWith('Id') &&
          !header.endsWith('By') &&
          !header.endsWith('Date') &&
          value
        ) {
          if (typeof value != 'object') {
            this.data.push({ head: header, value: value, heading: heading });
          } else if (typeof value == 'object') {
            let headings1 = Object.keys(value);
            for (let index = 0; index < headings1.length; index++) {
              const header1 = headings1[index];
              const value1 = header1 == 'password' ? '-----' : value[header1];
              const heading1 = this.camel2title(
                header1.replace('workType', 'processType')
              );
              if (
                !header1.startsWith('_') &&
                !header1.endsWith('Id') &&
                !header1.endsWith('By') &&
                !header1.endsWith('Date') &&
                value
              ) {
                if (typeof value1 != 'object') {
                  this.data.push({
                    head: header1,
                    value: value1,
                    heading: heading1,
                  });
                }
              }
            }
          }
        }
      }
    }
    this.items = detail[0]['_itemsdetails'];
    for (let index = 0; index < Math.ceil(this.data.length / 2); index++) {
      this.numbers.push(index);
    }
  }

  closeModal(isFatal: any) {
    this.activeModal.close({ response: isFatal });
  }
  camel2title(camelCase: any) {
    console.log(camelCase);
    console.log(camelCase
      // inject space before the upper case letters
      .replace(/(?<![A-Z])([A-Z])/g, function (match: any) {
        return ' ' + match;
      })
      // replace first char with upper case
      .replace(/^./, function (match: any) {
        return match.toUpperCase();
      }))
    // no side-effects
    return (
      camelCase
        // inject space before the upper case letters
        .replace(/(?<![A-Z])([A-Z])/g, function (match: any) {
          return ' ' + match;
        })
        // replace first char with upper case
        .replace(/^./, function (match: any) {
          return match.toUpperCase();
        })
    );
  }

  displayImage(url) {
    console.log(url)
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
    }
}

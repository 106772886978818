<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{('visitor'|customTranslate)+' '+('report'|customTranslate)}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <section class="open_sec">
                <div class="page_design">
                  <div class="row tab_sec m-0">
                    <div class="col-md-12 col-12 date_div p-0">
                      <div class="row">
                        <div class="col-md-3 date">
                          <label>{{'start_date:'|customTranslate}}</label>
                          <input type="date" class="form-control" [(ngModel)]="startDate" [max]="endDate">
                        </div>
                        <div class="col-md-3 date">
                          <label>{{'end_date:'|customTranslate}}</label>
                          <input type="date" class="form-control" [min]="startDate" [(ngModel)]="endDate">
                        </div>
                        <div class="col-md-1 btn-block text-right">
                          <button class="btn btn-primary"
                            (click)="getDateWiseVisitorReport()">{{"search" | customTranslate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="view_table">
                    <div class="filter_sec">
                      <div class="row" style="display: flex; align-items: flex-end;">
                        <div class="col-md-3 date">
                          <label>{{'select_process:'|customTranslate}}</label>
                          <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="listOfProcess"
                            isMultiSelect="true" display="processName" placeholder="Select Process">
                          </ps-auto-suggestion>
                        </div>
                        <h6>{{processFilterName}}</h6>
                        <div class="col-md-3">
                          <label>{{'status'|customTranslate}}</label>
                          <select class="form-control" name="status" [(ngModel)]="tripStatus"
                            (ngModelChange)="filterDataByCondition(tripStatus)">
                            <option value="ALL"> ALL </option>
                            <option value="ALERT">ALERT</option>
                            <option value="RESTRICTED">RESTRICTED</option>
                          </select>
                        </div>
                        <div class="form-group action-block mt-2" style="margin-right: 10px;">
                          <div class="btn-block">
                            <button class="btn btn-warning" (click)="reset()"> {{ "reset" | customTranslate }} </button>
                          </div>
                        </div>
                      </div>
                    </div>
                     <div class="table_sec">
                    </div>
                      <app-custom-table [data]="result" [columns]="tableColumns"  [tableType]="TABLE_TYPE"></app-custom-table>
                  </div>
                  </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row" *ngIf="alerts.length">
  <div class="col-md-12 alert-container">
    <div class="alert-column">
      <div *ngFor="let alert of alerts" class="{{cssClass(alert)}}">
        <a class="close" (click)="removeAlert(alert)">&times;</a>
        <i *ngIf="alert.type != types.Error" class="fa fa-check-circle"></i>
        <i *ngIf="alert.type == types.Error" class="fa fa-times-circle"></i>
        <span [innerHTML]="alert.message"></span>
      </div>
    </div>
  </div>
</div>

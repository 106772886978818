import { AlertService } from './../../theme/layout/header/alert-message/alert.service';
import { DriverNavigationComponent } from '../../modals/driver-navigation/driver-navigation.component';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-driver-live-url',
  templateUrl: './driver-live-url.component.html',
  styleUrls: ['./driver-live-url.component.scss'],
})
export class DriverLiveUrlComponent implements OnInit {
  response: any = [];
  destinationStatus: any;
  destinationName: any;
  vehicleNo: any;
  constructor(
    public api: ApiService,
    public common: CommonService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private alertService: AlertService,
    public http: HttpClient
  ) {}

  ngOnInit() {
    this.getTrackingDetails();
  }

  getTrackingDetails() {
    if (this.route.snapshot.queryParams.tripId) {
      let tripId = atob(this.route.snapshot.queryParams.tripId);
      this.http
        .get(this.api.MAIN_URL + 'trip/live-tracking-by-tripId?txnId=' + tripId)
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.response = res.data;
              this.vehicleNo = res.data[0].vehicle_rc_no;
              if (res.data[0].destination == null) {
                this.destinationName = 'Destination Not Assigned';
              } else {
                res.data[0].destination = JSON.parse(res.data[0].destination);
                this.destinationName = res.data[0].destination;
              }
            }
          },
          (err: any) => {
            console.error('Error: ', err);
          }
        );
    } else {
      this.alertService.error('TripId is incorrect....');
    }
  }

  openNavigation() {
    this.modalService.open(DriverNavigationComponent, { size: 'xl' });
  }
}

<div class="card main-card" *ngIf="userService.permissionMap.get('trip_dashboard_access')==true">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{"trip_dashboard" | translate}} - <span>{{tmgdashboard.currentProcess}}</span>
      </h3>
    </div>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="card primary trip-card">
          <smart-header [title]="'completed vs Canceled trip - Last 7 Days' | translate" DataSet="chart"
            (onSelected)="gettripchartdata($event)" (onReset)="gettripchartdetail()">
          </smart-header>
          <div class="card-body">
            <div style="display: block; height: 200px;">
              <canvas baseChart height="inherit" [datasets]="tripChartData" [labels]="tripChartLabels"
                [options]="tripChartOptions" [colors]="tripChartColors" [legend]="tripChartLegend"
                [chartType]="tripChartType"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card danger trip-card">
          <smart-header [DataSet]="'chart'" [title]="'TAT (HRs)+ Planned time'| translate"
            (onSelected)="getplannedtat($event)" (onReset)="getPlannedtatdata()">
          </smart-header>
          <div class="card-body">
            <div style="display: block; height: 200px;">
              <canvas baseChart height="inherit" [datasets]="TATChartData" [labels]="TATChartLabels"
                [options]="TATChartOptions" [colors]="TATChartColors" [legend]="TATChartLegend"
                [chartType]="TATChartType"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card sweet-warning trip-card" #worstthreetrips>
          <smart-header [title]="'Worst 3 trips - TAT wise (7 Days)'| translate" (onSelected)="getworstTrip($event)"
            (onReset)="getworstTrip($event)">
          </smart-header>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive pt-5">
                  <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                      <tr>
                        <th>{{"serial_no" | translate}}</th>
                        <th>{{"trip_name" | translate}}</th>
                        <th>{{"serial_no" | translate}}</th>
                        <th>{{"serial_no" | translate}}</th>
                        <th>{{"difference(expected-actual)" | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of worsttrip;index as i">
                        <td>{{i+1}}</td>
                        <td> {{item.Trip}}</td>
                        <td>{{item.ExpectedTat}}</td>
                        <td> {{item.ActualTAT}}</td>
                        <td>{{item.Difference}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>

import { processtrip } from './../tmg-dashboard/data';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { reject } from 'lodash';

@Component({
  selector: 'app-verify-visitor-modal',
  templateUrl: './verify-visitor-modal.component.html',
  styleUrls: ['./verify-visitor-modal.component.scss'],
})
export class VerifyVisitorModalComponent implements OnInit {
  @Input() refData;

  response = false;

  otp : string
  processId: number;
  processName: string;
  tagId: number;
  tagName: string;
  tagType: string;
  isGenerate = 'Generate OTP';
  plantId:number = null;
  stageId: number;
  userId: number = null
  isChecked=false
  isCreated=false
  intervalId: any;
  timeLeft: number = 120; // 2 minutes in seconds (120 seconds)
  minutes: number = 2;
  seconds: number = 0;
  canResend : boolean = true;


  constructor(
    private activeModal: NgbActiveModal,
    private api: ApiService,
    private common: CommonService,
    private user:UserService,
    private alertService: AlertService
  ) {

  }

  ngOnInit() {
    this.plantId = this.user._loggedInUser.plantId;
    this.userId = this.user._loggedInUser.id;
    this.generateOtp();

  }

 startTimer() {
  if (this.intervalId) {
    clearInterval(this.intervalId);

  }

  this.timeLeft = 120;
  this.canResend= false;

  this.intervalId = setInterval(() => {
    if (this.timeLeft > 0) {
      this.timeLeft--;

    } else {
      clearInterval(this.intervalId);
      console.log("Time is up!");
      this.canResend= true;
      return;

    }
  }, 1000);
}

getFormattedTime() {
  const minutes: number = Math.floor(this.timeLeft / 60);
  const seconds: number = this.timeLeft % 60;
  return `${this.padZero(minutes)}:${this.padZero(seconds)} s`;
}

padZero(num: number) {
  return num < 10 ? `0${num}` : num; + ''
}

  generateOtp() {
    let params = {
      mappingId: this.refData.id,
      staffId: this.refData.visitor_id
    };
    this.api
      .get('visitors/generate-otp-for-visitor', params)
      .subscribe((response: any) => {
        if(response.message=='Otp Generated')
        {
          this.alertService.success("OTP Sent!");
          this.startTimer()
        }
        else{
          this.alertService.error("Failed in Generating OTP!");
        }
      },
      (err: any) => {
        this.alertService.error("Failed in Generating OTP!");
        this.common.loading = false;
      });

    this.isGenerate = 'Regenarate OTP';
  }

  checkOtp() {

    if(this.otp==null)
    {
      this.alertService.error("Enter OTP!");
      return;
    }
    this.otp = this.otp.trim()
    let params = {
      id: this.refData.id,
      otp: this.otp,
    };

    this.api.get('visitors/check-otp', params).subscribe((response: any) => {
      this.response = response.status;

      if (response.data[0].otp == true) {
        this.isChecked= true

        this.closeModal();

      }

      else{
        this.alertService.error("Invalid OTP!");


      }
    },
    (err: any) => {
      this.common.loading = false;

    });
  }

  closeModal() {

    this.activeModal.close({ response: this.isChecked});
    clearInterval(this.intervalId);
  }
}

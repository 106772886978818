<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">


            <div class="card-body">
              <div class="row m-0">
                <div class="col-md-3 p-0">
                  <h4 class="heading">Plant Productivity</h4>
                    <div class="avg_sec">
                    <div id="specificChart" class="donut-size" style="transform: scale(0.8);">
                      <div class="pie-wrapper">
                        <span class="progress_label">
                          <span class="num">0</span><span class="smaller">%</span>
                        </span>
                        <div class="pie">
                          <div class="left-side half-circle"></div>
                          <div class="right-side half-circle"></div>
                        </div>
                        <div class="shadow"></div>
                      </div>
                    </div>



                    <div class="cards">
                      <div class="single_card" (click)="setModalTitle('Total On Duty Users')"  data-toggle="modal" data-target="#exampleModal"
                      (click)="tableModalData=onDutyData" >
                        <div class="top_row" style="display:flex;align-items: baseline;">
                          <h4 style="color: #3dc03d;">{{total_user_card.present_user}} / {{totalPlantUser}}
                          </h4>
                          <i class="fa fa-users"></i>
                        </div>
                        <div class="bottom_row" style="margin-top: 0px;">
                          <p>On Duty</p>
                        </div>
                      </div>

                      <div class="single_card" (click)="setModalTitle('Total Off Duty Users')" data-toggle="modal" data-target="#exampleModal2"
                      (click)="tableModalData=total_user_card.outsideData">
                        <div class="top_row">
                          <h4 style="color: #cd4040;">{{total_user_card.outside_user}} / {{totalPlantUser}}
                          </h4>
                          <i class="fa fa-users" ></i>
                        </div>
                        <div class="bottom_row">
                          <p>Out Of Assigned Zone</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="active_alerts">
                    <div class="subtitle">Active Alerts</div>
                    <div class="alert_boxes_sec">
                      <div *ngIf="pendingAlerts?.length==0" class="text-center mt-1 fade-in no-assets-tracked">No Active Alerts</div>
                      <div *ngIf="pendingAlerts?.length>0">
                      <div class="alert_box" *ngFor="let alert of pendingAlerts" (click)="viewAlert(alert.alert_data,alert.alert_condition,alert.type_name,alert.alert_name,alert)">
                        <div class="top_alert_row">
                          <h4>{{alert.title}}</h4>
                          <button [ngClass]="alert.type_name" [style.background]="alert.type_color">{{alert.type_name}}</button>
                        </div>
                        <div class="bottom_alert_row">
                          <div class="name"><i class="fa fa-user"></i> {{alert.user_name}}</div>
                          <div class="time">{{alert.created_at|date : 'dd-MM-YYYY hh-mm'}}</div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>


                <div class="col-md-9 pr-0 pl-1">
                  <div class="row m-0">
                    <div class="col-md-9 p-0">
                      <div class="row dash_cards">
                        <div class="col-md-3 single_card" (click)="setModalTitle('Users Restricted Zone Movement')" data-toggle="modal" data-target="#exampleModal"
                        (click)="tableModalData=detail_info_card.unauthorisedData" >
                          <div class="top_row">
                            <h4 class="addon-blinker-for-text"  style="color: #cd4040;">{{detail_info_card.unauthorized_movement}}</h4>
                            <i class="fa fa-user-times blinker-icon" style="color:#cd4040 ;"></i>
                          </div>
                          <div class="bottom_row">
                            <p>Restricted Zone Movement</p>
                          </div>
                        </div>

                        <div class="col-md-3 single_card" data-toggle="modal" data-target="#exampleModal4"
                        (click)="tableModalData=resolvedAlerts?resolvedAlerts:[];setModalTitle('Resolved Alerts')">
                          <div class="top_row">
                            <h4 style="color: #3d9d9d;">{{resolvedAlerts ? resolvedAlerts.length : 0}}/{{(resolvedAlerts ? resolvedAlerts.length : 0) + (pendingAlerts.length?pendingAlerts.length:0)}}</h4>
                            <i class="fa fa-exclamation-triangle" style="color: #3d9d9d;"></i>
                          </div>
                          <div class="bottom_row">
                            <p>Resolved/Total Alerts</p>
                          </div>
                        </div>

                        <div class="col-md-2 single_card" data-toggle="modal" data-target="#exampleModal"
                        (click)="tableModalData=detail_info_card.visitorData;setModalTitle('Total Visitors')">
                          <div class="top_row">
                            <h4 style="color: #191970;">{{detail_info_card.total_visitors}}</h4>
                            <i class="fa fa-users" style="color: #191970;;"></i>
                          </div>
                          <div class="bottom_row">
                            <p>Total Visitors</p>
                          </div>
                        </div>

                        <div class="col-md-2 single_card">
                          <div class="top_row">
                            <h4 style="color: #dddd48;">4</h4>
                            <i class="fa fa-exclamation-triangle" style="color: #dddd48;"></i>
                          </div>
                          <div class="bottom_row">
                            <p>SOS</p>
                          </div>
                        </div>
                        <div class="col-md-2 single_card" data-toggle="modal" data-target="#exampleModal4" (click)="tableModalData=pendingAlerts;setModalTitle('Pending Alerts')">
                          <div class="top_row">
                            <h4 class="addon-blinker-for-text" style="color: #e65050;">{{pendingAlerts.length}}</h4>
                            <i class="fa fa-exclamation-triangle blinker-icon" style="color:#e65050 ;"></i>
                          </div>
                          <div class="bottom_row">
                            <p> Pending Alerts</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 pr-0 pl-1">
                      <div class="up_down_card" style="background-color: #f0fff0;">
                        <div class="single_card">
                          <div class="top_row">
                            <h4 style="color: #378737;">Best Department <span>(Today)</span></h4>
                            <i class="fa fa-info-circle" (click)="setModalTitle(deparmentData[0]?.dept_name)" data-toggle="modal" data-target="#exampleModal"
                              (click)="tableModalData=deparmentData[0]?.users"></i>
                          </div>
                          <div class="bottom_row">
                            <p style="color:  #378737; margin: 1px 0px 6px 0px;">{{deparmentData[0]?.dept_name}} (productivity
                              {{deparmentData[0]?.new_productivity??0|number:'1.2-2'}}%) </p>
                          </div>
                        </div>
                        </div>

                      <div class="up_down_card" style="margin-top:2px ; background-color: #fff0f0;">
                        <div class="single_card">
                          <div class="top_row" >
                            <h4 style="color: #d20000;">Worst Department <span>(Today)</span></h4>
                            <i class="fa fa-info-circle" (click)="setModalTitle(deparmentData[deparmentData.length-1]?.dept_name)" data-toggle="modal" data-target="#exampleModal"
                              (click)="tableModalData=deparmentData[deparmentData.length-1]?.users"></i>
                          </div>
                          <div class="bottom_row">
                            <p style="color: #d20000;margin: 1px 0px 7px 0px;">{{deparmentData[deparmentData.length-1]?.dept_name}}(productivity
                              {{deparmentData[deparmentData.length-1]?.new_productivity??0}}%) </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="table_section">
                    <div class="subtitle">Worst Productive Users</div>
                    <div class="table-responsive" style="height:345px">
                      <div *ngIf="trackingData.length==0" class="text-center mt-1 fade-in no-assets-tracked">No User Tracked</div>
                      <table class="table table-bordered" *ngIf="trackingData?.length>0">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Productivity</th>
                            <th>Tracked Zone</th>
                            <!-- <th>Avgerage Productivity</th> -->
                            <th>Last Ping</th>
                            <th>Contact</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let user of worstProductiveUser;let i=index">
                          <tr *ngIf="i < 10">
                            <td>{{user.user_name}}</td>
                            <td>{{user.user_type=='Visitor'?'-':(user.productivity_percent|number:'1.2-2')+'%'}}</td>
                            <td><span [ngClass]="{'loading':!isUauthorised(user) && user.last_ping?.last_ping_zone_name!== null,'unloading':isUauthorised(user)&& user.last_ping?.last_ping_zone_name!== null}">{{user.last_ping?.last_ping_zone_name == null ? '-' : user.last_ping?.last_ping_zone_name}}</span></td>
                            <td>{{user.last_ping?.last_ping_at ? (user.last_ping?.last_ping_at | date:'dd-MM-yyyy hh:mm:ss a') : '-'}}</td>
                            <td class="contact_icon">
                              <i class="fa fa-whatsapp" (click)="openModal('Message')" data-dismiss="modal" data-toggle="modal" data-target="#exampleModal3"></i>
                              <i class="fa fa-info" style="color:blue" (click)="openUserTrackingModal(user.user_id,user.user_type,user)"></i>
                              <i class="fa fa-phone" (click)="openModal('Phone Call')" data-dismiss="modal" data-toggle="modal" data-target="#exampleModal3"></i>
                              <i class="fa fa-envelope" (click)="openModal('Email')" data-dismiss="modal" data-toggle="modal" data-target="#exampleModal3"></i>
                            </td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="zone_sec">
                <div class="subtitle">Zone Wise User Tracking</div>
                <div *ngIf="mapToArray(zoneData).length==0" class="text-center mt-1 fade-in no-assets-tracked" style="height: 58px;">No Zone Created</div>
                <div class="row zone_cards_sec" *ngIf="mapToArray(zoneData)?.length>0">
                  <div class="col-md-3 zone_card" *ngFor="let zone of mapToArray(zoneData);let i=index">
                    <div class="about_zone"  [style.background-color]="zoneColors[i % zoneColors.length]">
                      <div class="zone_name">{{zone.zone_name}}</div>
                      <div class="right_sec">
                        <div class="calculation">Allocated User: <button>{{zone?.users?.length??0}}</button></div>
                        <div class="calculation">Present: <button>{{(zone?.present_user??0) + (zone?.unauthorised_user??0)}}</button></div>
                      </div>
                    </div>

                    <div class="zone_strength">
                      <div class="strength_row" *ngFor="let userType of zone.user_data | keyvalue" data-toggle="modal" data-target="#exampleModal"  [ngStyle]="{'background-color': userType.key === 'Unauthorised' ? 'rgb(255 233 233)' : '#f5f5f5'}"
                      (click)="tableModalData=userType.value['users'];setModalTitle(userType.key+' '+'users')"
                      >
                      <ng-container *ngIf="userType.key!='Unauthorised'">
                        <i class="fa fa-male emp" ><span style="text-transform: capitalize;">{{userType.key}}</span></i>
                      </ng-container>
                      <ng-container *ngIf="userType.key=='Unauthorised'">
                        <i class="fa fa-male out_side"><span>{{userType.key}}</span></i>
                      </ng-container>
                        <button>{{returnMapKeyValue(userType.value,"count")}}</button>
                      </div>

                    </div>

                    <div class="zone_strength1 outside" data-toggle="modal" data-target="#exampleModal2"
                    (click)="tableModalData=mapToArray(zone.user_name_data);setModalTitle('Out of Zone Users')">
                      <div class="strength_row">
                        <i class="fa fa-male out_side"><span>Outside</span></i>
                        <button>{{mapToArray(zone.user_name_data).length}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               <div class="row m-0">
                <div class="col-md-6 pr-md-1 pl-0">
                  <div class="table_section">
                    <div class="subtitle">Zone Productivity</div>
                    <div class="table-responsive" style="height: 323px;">
                      <div *ngIf="worstzoneData?.length==0" class="text-center mt-1 fade-in no-assets-tracked">No Stage Data</div>
                      <table class="table table-bordered" *ngIf="worstzoneData?.length>0">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <!-- <th>Avgerage Productivity</th> -->
                              <th>Avg. Productivity</th>
                              <!-- <th>Zone</th> -->
                              <th>Current Users</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let zone of worstzoneData" [style.background-color]="(zone.new_productivity < 20||zone.new_productivity == undefined) ? '#ffcfcf' : 'inherit'">
                              <!-- <td>1</td> -->
                              <td>{{zone.zone_name}}</td>
                              <td>{{zone.new_productivity == undefined ? 0 : zone.new_productivity|number:'1.2-2' }}%</td>
                              <!-- <td><span class="loading">UnLoading Zone</span></td> -->
                              <td>{{zone.active_user == undefined ? 0 : zone.active_user.length}}</td>
                              <td><i class="fa fa-info-circle" data-toggle="modal" data-target="#exampleModal"
                                (click)="tableModalData=zone?.active_user??[ ];setModalTitle('zone Users')"></i></td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-0 pr-0">
                  <div class="table_section">
                    <div class="subtitle" style="display:flex;justify-content: space-between;flex-direction: row;">
                      Plant Productivity
                      <div class="right-side" *ngIf="dailyProductivity?.length>0" >
                        <select (change)="getDailyProdData()" [(ngModel)]="days">
                          <option [value]=7 selected> Last 7 days </option>
                          <option [value]=30> Last 30 days </option>
                        </select>
                      </div>
                    </div>

                    <div class="table-responsive">
                      <div *ngIf="dailyProductivity?.length==0" class="text-center mt-1 fade-in no-assets-tracked">No Productivity Data</div>
                      <canvas baseChart
                      [datasets]="areaChartData"
                      width="100%"
                      height="35px"
                      [options]="areaChartOptions"
                      [legend]="areaChartLegend"
                      [chartType]="areaChartType" *ngIf="dailyProductivity?.length>0">
              </canvas>
                    </div>
                  </div>
                </div>

               </div>

               <div class="col-md-12 pl-1 pr-0 mt-1">
                <div class="table_section">
                  <div class="subtitle">Map View</div>
                  <app-user-tracking-view [staffType]="'User'"></app-user-tracking-view>
                  <!-- <div class="table-responsive" style="height:650px">
                    <div class="row no-gutters">
                      <div class="col-md-12 mt-2 map-container">
                        <ps-auto-suggestion class="search" (onSelected)="selectedUser($event)" [data]="users" display="userName"
                        placeholder="Choose User">
                      </ps-auto-suggestion>
                        <div class="map" id="map-1"></div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade bd-example-modal-xl" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" (hidden)="handleModalClose()" style="width:1250px;">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="cards_row">
            <div class="table-responsive" *ngIf="tableModalData.length>0" >
              <table class="table-responsive-alert">
                <tr class="table-alert">
                  <th>Name</th>
                  <th *ngIf="modalTitle!=='Total Visitors'">User Type</th>
                  <th *ngIf="modalTitle!=='Total Visitors'&&modalTitle!=='Visitor users'">Productivity</th>
                  <th>Tracked Zone</th>
                  <th>Last Ping</th>
                  <th>Contact</th>
                  </tr>
              <tbody class="table-alert-list">
                <tr *ngFor="let user of tableModalData;">
                  <td>{{user.user_name}}</td>
                  <td *ngIf="modalTitle!=='Total Visitors'">{{user.user_type}}</td>
                  <td *ngIf="modalTitle!=='Total Visitors'&&modalTitle!=='Visitor users'">{{ user.user_type=='Visitor'?'-':(user.productivity_percent|number:'1.2-2')+'%'}}</td>   <!--here-->
                  <td *ngIf="modalTitle!=='zone Users'"><span [ngClass]="{'loading':modalTitle!=='Unauthorised users'&&modalTitle!=='Users Restricted Zone Movement'&&user.last_ping?.last_ping_zone_name!== null,'unloading':(modalTitle=='Unauthorised users'||modalTitle=='Users Restricted Zone Movement')&&user.last_ping?.last_ping_zone_name!== null}">{{user.last_ping?.last_ping_zone_name == null ? '-' : user.last_ping?.last_ping_zone_name}}</span></td>
                  <td *ngIf="modalTitle=='zone Users'"><span [ngClass]="{'loading':user.last_ping?.last_ping_zone_name!== null&&!isUauthorised(user),'unloading':user.last_ping?.last_ping_zone_name!== null &&isUauthorised(user)}">{{user.last_ping?.last_ping_zone_name == null ? '-' : user.last_ping?.last_ping_zone_name}}</span></td>
                  <td>{{user.last_ping?.last_ping_at ? (user.last_ping?.last_ping_at | date:'dd-MM-yyyy hh:mm:ss a') : '-'}}</td>
                  <td class="contact_icon">
                    <i class="fa fa-whatsapp" (click)="openModal('Message')" data-dismiss="modal" data-toggle="modal" data-target="#exampleModal3"></i>
                    <i class="fa fa-info" style="color:blue" (click)="openUserTrackingModal(user.user_id,user.user_type,user)"></i>
                    <i class="fa fa-phone" (click)="openModal('Phone Call')" data-dismiss="modal" data-toggle="modal" data-target="#exampleModal3"></i>
                    <i class="fa fa-envelope" (click)="openModal('Email')" data-dismiss="modal" data-toggle="modal" data-target="#exampleModal3"></i>
                  </td>
                </tr>
              </tbody>
              </table>
            </div>
            <div *ngIf="tableModalData?.length==0" class="text-center mt-1 fade-in" style="font-weight: 600; color: #cd4040;font-size: larger;">No User Tracked!</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade bd-example-modal-xl" id="exampleModal2" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" (hidden)="handleModalClose()">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="cards_row">
            <div class="table-responsive" *ngIf="tableModalData.length>0">
              <table class="table-responsive-alert">
                <tr class="table-alert">
                  <!-- <th>Sr. No.</th> -->
                  <th>User Name</th>
                  <th>User Type</th>
                  <th>Contact</th>
                </tr>
                <tbody class="table-alert-list">
                  <tr *ngFor="let user of tableModalData">
                    <!-- <td>1</td> -->
                    <td>{{user.name}}</td>
                    <td>{{user.type_name}}</td>
                    <td class="contact_icon">
                      <i class="fa fa-whatsapp"  (click)="closeModal(false);openModal('Message')" data-toggle="modal" data-target="#exampleModal3"></i>
                      <i class="fa fa-info" style="color:blue" (click)="openUserTrackingModal(user.id,user.user_type,user)"></i>
                      <i class="fa fa-phone" (click)="closeModal(false);openModal('Phone call')" data-toggle="modal" data-target="#exampleModal3"></i>
                      <i class="fa fa-envelope" (click)="closeModal(false);openModal('Email')" data-toggle="modal" data-target="#exampleModal3"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="tableModalData.length===0" class="text-center mt-1 fade-in" style="font-weight: 600; color: #cd4040;font-size: larger;">No User Tracked!</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade bd-example-modal-xl" id="exampleModal3" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" (hidden)="handleModalClose()">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <div class="modal-heading">
          <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
        </div> -->
        <div class="textarea-section">
          <textarea class="form-control" rows="5" [(ngModel)]="textArea"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Send</button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="detailModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content shadow">
      <div class="modal-header">
        <h4 class="modal-title">{{modalDetailTitle}} - {{detailType == 1 ? 'Plant': detailType == 2 ?
          'Stage':detailType == 5 ?'Zone':'Hardware' }} </h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <div class="col-md-12 text-right">
          </div>
        </div>
        <div class="cards_row">
          <div class="table-responsive" *ngIf="tableModalData.length>0" >
            <table class="table-responsive-alert">
              <tr class="table-alert">
                <!-- <th>Sr. No.</th> -->
                <th>User Name</th>
                <th>User Type</th>
                <th *ngIf="modalTitle!=='Total Visitors'&&modalTitle!=='Visitor users'">Productivity</th>
                <th>Last Ping</th>
                <th>Contact</th>
              </tr>
              <tbody class="table-alert-list">
                <tr *ngFor="let user of tableModalData">
                  <!-- <td>1</td> -->
                  <td *ngIf="isTotalUserData">{{user.name}}</td>
                  <td *ngIf="!isTotalUserData">{{user.user_name}}</td>
                  <td *ngIf="isTotalUserData">{{user.type_name}}</td>
                  <td *ngIf="!isTotalUserData">{{user.user_type}}</td>
                  <td *ngIf="modalTitle!=='Total Visitors'&&modalTitle!=='Visitor users'">{{user.user_type=='Visitor'?'-':(user.productivity_percent|number:'1.2-2')+'%'}}</td>
                  <td *ngIf="!isTotalUserData">{{user.last_ping?.last_ping_at ? (user.last_ping?.last_ping_at | date:'dd-MM-yyyy hh:mm:ss a') : '-'}}</td>
                  <td class="contact_icon">
                    <i class="fa fa-whatsapp"  (click)="closeModal(false);openModal('Message')" data-toggle="modal" data-target="#exampleModal3"></i>
                    <i class="fa fa-info" style="color:blue" (click)="closeModal(false);openUserTrackingModal(user.user_id,user.user_type,user)"></i>
                    <i class="fa fa-phone" (click)="closeModal(false);openModal('Phone call')" data-toggle="modal" data-target="#exampleModal3"></i>
                    <i class="fa fa-envelope" (click)="closeModal(false);openModal('Email')" data-toggle="modal" data-target="#exampleModal3"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="tableModalData.length===0" class="text-center mt-1 fade-in" style="font-weight: 600; color: #cd4040;font-size: larger;">No User Tracked!</div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right mt-3 mb-1">
            <button type="button" class="btn btn-danger" (click)="closeModal(false)">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade bd-example-modal-xl" id="exampleModal4" tabindex="-1" role="dialog"
aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-xl" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="cards_row">
        <div class="table-responsive" *ngIf="tableModalData.length>0" >
          <table class="table-responsive-alert">
            <tr class="table-alert">
              <!-- <th>Sr. No.</th> -->
              <th>Alert Name</th>
              <th>Alert On</th>
              <th>Alert Conditon</th>
              <th>Type</th>
              <th>Status</th>
              <th *ngIf="modalTitle=='Pending Alerts'">Created At</th>
              <ng-container *ngIf="modalTitle=='Resolved Alerts'">
              <th >Resolved At</th>
                <th>Resolved By</th>
                <th>Remarks</th>
               </ng-container>
              </tr>
              <tbody class="table-alert-list">
                <tr *ngFor="let alert of tableModalData">
                  <td>{{alert.title}}</td>
                  <td>{{alert.entity_type+' '+alert.user_name}}</td>
                  <td><span class="pill"
                    [ngClass]="{'bg-indigo-100 text-indigo-800':(alert.alert_condition=='User Productivity'),'bg-pink-100 text-pink-800':(alert.alert_condition=='Zone Over Crowded'),'bg-red-100 text-red-800':(alert.alert_condition=='Banned User Entered in Plant'),'bg-yellow-100 text-yellow-800':(alert.alert_condition=='On Entering Restricted Zone')}">
                    {{alert.alert_condition}}</span>
                    </td>
                  <td>
                     <button [ngClass]="alert.type_name" [style.background]="alert.type_color" style=" border: none;
                     color: white;
                     font-size: 10px;
                     padding: 5px 10px;
                     border-radius: 14px;
                     font-weight: 600;">{{alert.type_name}}</button>
                    </td>
                  <td><span class="pill"
                    [ngClass]="{'bg-yellow-100 text-yellow-800':(alert.status=='INPROGRESS'),'bg-blue-100 text-blue-800':(alert.status=='ACTIVE'),'bg-green-100 text-green-800':(alert.status=='COMPLETED')}">
                    {{alert.status}}</span>
                    </td>
                  <td>{{alert.created_at| date:'dd-MM-yyyy hh:mm:ss a'}}</td>
                  <ng-container *ngIf="modalTitle=='Resolved Alerts'">
                  <td>{{alert.user_name}}</td>
                  <td>{{alert.remark_data && alert.remark_data[0]?.remark!=null?alert.remark_data[0]?.remark:'-'}}</td>
                </ng-container>
                </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="tableModalData.length===0 && modalTitle=='Pending Alerts'" class="text-center mt-1 fade-in" style="font-weight: 600; color: #cd4040;font-size: larger;">No Recent Alerts!</div>
        <div *ngIf="tableModalData.length===0 && modalTitle=='Resolved Alerts'" class="text-center mt-1 fade-in" style="font-weight: 600; color: #cd4040;font-size: larger;">No Resolved Alerts!</div>
      </div>
    </div>
  </div>
</div>
</div>
</div>

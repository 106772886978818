import { TableService } from '../../@core/services/table.service';
import { AlertService } from './../../theme/layout/header/alert-message/alert.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { CompanyService } from '../companies/companies.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { PlantService } from './plant.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';
import { RoleService } from 'src/app/@core/services/role.service';

@Component({
  selector: 'app-plant',
  templateUrl: './plant.component.html',
  styleUrls: ['./plant.component.scss'],
})
export class PlantComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'PLANT LIST');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getPlant();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  plant = {
    plantId: '',
    name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    type: 'CEMENT',
    status: 'ACTIVE',
    company: '',
    description: '',
  };
  companyName: null;
  company: any[] = [];
  title = 'Add Plant';
  btn = 'Save';
  data: any[] = [];

  constructor(
    private alertService: AlertService,
    private plantService: PlantService,
    private companyService: CompanyService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Plant', url: null },
      { name: 'Plant Management', url: '/user/plant' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getCompany();
  }
  ngViewInit() {}

  selectedCompany(event: any) {
    this.plant.company = event.companyId;
    this.companyName = event.companyName;
  }

  getPlant() {
    this.common.loading = true;
    let params = {
      fetchAll: true,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.renderTable();
          this.data = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          res.data.forEach((element) => {
            if (element.companyStatus == 'ACTIVE') this.company.push(element);
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  savePlant() {
    let params: any;
    this.common.loading = true;
    if (this.role.user_role == 'AXESTRACK') {
      params = {
        plantId: this.plant.plantId,
        plantName: this.plant.name,
        plantAddress: this.plant.address,
        plantCity: this.plant.city,
        plantState: this.plant.state,
        plantCountry: this.plant.country,
        plantType: this.plant.type,
        plantStatus: this.plant.status,
        company: this.plant.company,
        plantDescription: this.plant.description,
      };
    } else {
      params = {
        plantId: this.plant.plantId,
        plantName: this.plant.name,
        plantAddress: this.plant.address,
        plantCity: this.plant.city,
        plantState: this.plant.state,
        plantCountry: this.plant.country,
        plantType: this.plant.type,
        plantStatus: this.plant.status,
        company: this.userService._loggedInUser.companyId,
        plantDescription: this.plant.description,
      };
    }
    this.plantService.savePlant(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.getPlant();
          this.resetDetail();
        } else {
          this.alertService.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  resetDetail() {
    this.plant = {
      plantId: '',
      name: '',
      address: '',
      city: '',
      state: '',
      country: '',
      type: 'CEMENT',
      status: 'ACTIVE',
      company: '',
      description: '',
    };
    this.companyName = null;
    this.planttypeValidator = null;
    this.statusValidator = null;
    this.companyValidator = null;
    this.plantNameValidator = null;
    this.btn = 'Save';
    this.title = 'Add Plant';
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Plant ',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deletePlant(row);
      }
    });
  }

  deletePlant(row?: any) {
    this.common.loading = true;
    let params = {
      plantId: row.plantId,
      plantName: row.plantName,
      plantAddress: row.plantAddress,
      plantCity: row.plantCity,
      plantState: row.plantState,
      plantCountry: row.plantCountry,
      plantType: row.plantType,
      plantStatus: 'DELETED',
      company: row.companyId,
      plantDescription: row.plantDescription,
    };

    this.plantService.savePlant(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.getPlant();
          this.alertService.info(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  editPlant(row?: any) {
    this.resetDetail();
    this.common.gotoTop();
    this.plant.plantId = row.plantId;
    this.plant.name = row.plantName;
    this.plant.address = row.plantAddress;
    this.plant.city = row.plantCity;
    this.plant.state = row.plantState;
    this.plant.country = row.plantCountry;
    this.plant.type = row.plantType;
    this.plant.status = row.plantStatus;
    (this.plant.company = row.companyId),
      (this.plant.description = row.plantDescription);
    this.btn = 'Update';
    this.title = 'Edit Plant Details';
    this.companyName = row.companyName;
  }

  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'info' };

    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  planttypeValidator: any;
  statusValidator: any;
  companyValidator: any;
  plantNameValidator: any;
  formValidator() {
    this.companyValidator = this.common.nullValidator(
      this.plant.company,
      'Company Mandatory'
    );
    this.plantNameValidator = this.common.nullValidator(
      this.plant.name,
      'Plant Name Mandatory'
    );
    this.planttypeValidator = this.common.nullValidator(
      this.plant.type,
      'Plant Type Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.plant.status,
      'Plant Status Mandatory'
    );
    if (this.role.user_role == 'AXESTRACK') {
      if (
        !this.companyValidator.error &&
        !this.plantNameValidator.error &&
        !this.planttypeValidator.error &&
        !this.statusValidator.error
      ) {
        this.savePlant();
      }
    } else {
      if (
        !this.plantNameValidator.error &&
        !this.planttypeValidator.error &&
        !this.statusValidator.error
      ) {
        this.savePlant();
      }
    }
  }
}

import { VehicleStatusChangeComponent } from '../../../../modals/vehicle-status-change/vehicle-status-change.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from './../alert-message/alert.service';
import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { TokenStorageService } from 'src/app/@core/services/token-storage.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginCredentialsComponent } from 'src/app/modals/login-credentials/login-credentials.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { LogoutModalComponent } from 'src/app/logout-modal/logout-modal.component';
import { PendingTasksForUserModalComponent } from 'src/app/components/pending-tasks-for-user-modal/pending-tasks-for-user-modal.component';
import { CustomCommunicationComponent } from 'src/app/modals/custom-communication/custom-communication.component';
import { ChecklistQrModalComponent } from 'src/app/components/checklist-qr-modal/checklist-qr-modal.component';
import { CryptoService } from 'src/app/@core/services/crypto.service';
import { environment } from 'src/environments/environment';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  isCollapsed: boolean = false;
  @ViewChild('someInput') someInput: ElementRef | any;

  @ViewChild('fileUpload') fileUpload: ElementRef | any;
  @Input() accept: any;
  @Input() multiple: any;
  @Input() deleteButtonLabel: any;
  file: any;
  files: any[] = [];
  fileType: any;
  inputFileName: any;

  searchvalue: any;
  taskId: null;
  taskDescription: any;
  taskTitle: any;
  taskType: any;
  userName: null;
  userData: any = [];
  taskTo: any;
  type: any;
  apiUrl: any;
  pendingTasksCount:number = 0;
  whatsappText:string = "whatsapp"
  emailText:string = "email"
  smsText:string = "sms"

  messages: any = [];
  visitorUrl:string
  msg = {
    title: null,
    description: null,
  };

  languages: any = [
    {
      title: 'EN',
      value: 'en',
      img: 'assets/images/united_states.png',
    },
    {
      title: 'HN',
      value: 'hn',
      img: 'assets/images/india.png',
    },
  ];
  shift: any = 'morning';
  shiftIcon = '';
  selLanguage: any = {
    img: '',
    value: '',
    title: '',
  };
  user_role = localStorage.getItem('user_role');
  user_type = localStorage.getItem('user_type');
  role = '1';
  isOnline: boolean = false;
  internetOffsBefore: boolean = false;
  removeAlert: boolean = false;
  constructor(
    public common: CommonService,
    public api: ApiService,
    public userService: UserService,
    public router: Router,
    public alert: AlertService,
    public token: TokenStorageService,
    public modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private cryptoService:CryptoService
  ) {
    this.checkConnectionStatus();
    var d = new Date();
    var currHour = d.getHours();

    if (currHour >= 5 && currHour <= 11) {
      this.shift = 'morning';
      this.shiftIcon = 'assets/images/good-morning.png';
    } else if (currHour >= 12 && currHour <= 15) {
      this.shift = 'afternoon';
      this.shiftIcon = 'assets/images/good-afternoon.png';
    } else if (currHour >= 16 && currHour <= 19) {
      this.shift = 'evening';
      this.shiftIcon = 'assets/images/good-night.png';
    } else if (currHour >= 20 && currHour <= 23) {
      this.shift = 'night';
      this.shiftIcon = 'assets/images/good-night.png';
    } else if (currHour >= 0 && currHour <= 4) {
      this.shift = 'night';
      this.shiftIcon = 'assets/images/good-night.png';
    }
    this.notificationService.newNotification.subscribe((res: any) => {
      this.fetchNewNotifications();
    });
    this.encrypt(this.userService._companyId,this.userService._plantId).then(res => {
      this.visitorUrl =  environment.websiteUrl +"auth/visitor-login?ref="+res;
      // console.log("This is Visitor url",this.visitorUrl)
    })

  }

  chooseLanguage(lang) {
    this.selLanguage = lang;
    this.common.setLanguage(lang.value);
  }

  fetchNewNotifications() {
    let notifications = this.notificationService.getNotifications();
    notifications.notifications.forEach((notification) => {
      this.notificationService.showNotification(notification);
    });
    this.messages.push(...notifications.notifications);
    this.notificationService.setNotifications();
  }

  getmessage() {
    this.api
      .get('navbar/get-unread-notification', {})
      .toPromise()
      .then((res: any) => {
        this.messages = res.data[0] == null ? [] : res.data;
      });
  }

  vehicleStatusChange() {
    let activeModal = this.modalService.open(VehicleStatusChangeComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
  }


  titleValidator: any;
  descriptionValidator: any;

  data: any = [];
  totalCount = 0;

  getCounts() {
    // this.totalCount = 0;
    // let userId = 1;
    // for (let index = 0; index < this.data.length; index++) {
    //   if (this.data[index].isBookMarked && this.data[index].status == "UNREAD") {
    //     this.messages[2].totalNumber++;
    //   } else if (this.data[index].toId == userId && this.data[index].status == "UNREAD") {
    //     this.messages[0].totalNumber++;
    //   } else if (this.data[index].toId != userId && this.data[index].status == "UNREAD") {
    //     this.messages[1].totalNumber++;
    //   }
    // }
    // this.totalCount = this.messages[0].totalNumber + this.messages[1].totalNumber + this.messages[2].totalNumber;
  }

  openLoginCredentialModal() {
    let activeModal = this.modalService.open(LoginCredentialsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
    });
    activeModal.result.then((res) => {
      if(res.response){
        window.location.reload();
      }
    });
  }

  logout(row?: any) {
    this.common.params = {
      title: 'Sign Out',
      description: `<b>&nbsp;` + 'Are you sure you want to sign out? ' + `<b>`,
    };
    const activeModal = this.modalService.open(LogoutModalComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.token.signOut();
      }
    });
  }

  getAssignedTxnForLoggedInUser() {
    this.common.loading = true;
    this.api.get('dashboard/get-pending-tasks-by-userId').subscribe(
      (res: any) => {
        this.common.loading = false;
        const result = res.data[0];
        console.log('result: ', result);
        this.pendingTasksCount = result['pendingTasksCount'] || [];
        this.pendingTasksCount > 0
          ? this.openPendingTasksForUserModal()
          : this.closeModal(true, 1);
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  openPendingTasksForUserModal() {
    let activeModal = this.modalService.open(PendingTasksForUserModalComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'l',
      });
      activeModal.result.then((result) => {
        if (result.apiHit == 1) {
          this.logout();
        }
      });
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
    this.logout();
  }

  messageRoute(index: number) {
    // this.router.navigate(['/user/message-list?'+this.messages[index].name]);
    this.router.navigate(['/user/message-list']);
  }

  gethelp() {
    return this.router.navigate(['/user/help']);
  }

  communicationComp() {
    return this.router.navigateByUrl('/user/communication-comp');
  }

  onClick(event: any) {
    if (this.fileUpload) this.fileUpload.nativeElement.click();
  }

  onFileSelected(event: any) {
    let files = event.dataTransfer
      ? event.dataTransfer.files
      : event.target.files;

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (this.validate(file)) {
        file.objectURL = this.sanitizer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(files[i])
        );
        if (!this.isMultiple()) {
          this.files = [];
        }
        this.files.push(files[i]);
      }
    }

    this.handleFileSelection(event);
  }

  handleFileSelection(event: any) {
    this.common.loading = true;
    this.common.getBase64(event.target.files[0]).then(
      (res) => {
        this.common.loading = false;
        let file = event.target.files[0];
        this.file = res;
        this.fileType = file.type;
      },
      (err) => {
        this.common.loading = false;
        console.error('Base Err: ', err);
      }
    );
  }

  removeFile(event: any, file: any) {
    let ix;
    if (this.files && -1 !== (ix = this.files.indexOf(file))) {
      this.files.splice(ix, 1);
      this.clearInputElement();
    }
  }

  validate(file: File) {
    for (const f of this.files) {
      if (
        f.name === file.name &&
        f.lastModified === file.lastModified &&
        f.size === f.size &&
        f.type === f.type
      ) {
        return false;
      }
    }
    return true;
  }

  clearInputElement() {
    this.fileUpload.nativeElement.value = '';
  }

  isMultiple(): boolean {
    return this.multiple;
  }

  readOldNotification() {
    // if(this.messages.length > 0)
    // this.api.get("navbar/read-notifications", null , "IPA").toPromise().then(() => {
    // this.getmessage();
    // })
  }

  checkConnectionStatus() {
    this.common.createOnline$().subscribe((isOnline) => {
      this.isOnline = isOnline;
      if (!this.isOnline) {
        this.internetOffsBefore = true;
      } else {
        this.removeAlert = true;
        setTimeout(() => {
          this.removeAlert = false;
          this.internetOffsBefore = false;
        }, 3000);
      }
    });
  }

  viewNotificationList() {
    this.router.navigateByUrl('/user/notification-list');
  }

  ngOnInit(): void {
    let language = localStorage.getItem('language');
    if (language != null) {
      language = this.languages.filter((lang) => lang.value == language)[0];
    } else {
      language = this.languages.filter((lang) => lang.value == 'en')[0];
    }
    this.selLanguage = language;
    this.getmessage();
    this.getCounts();
  }
  handleCommunication(communicationType:string){
    console.log("Inside handleCommunication",communicationType)
    const activeModal = this.modalService.open(CustomCommunicationComponent, {
      size: 'md',
    });
    activeModal.componentInstance.refData = {
      communicationType:communicationType,
      content:this.visitorUrl
    }
  }

  viewQRModal() {
    let activeModal = this.modalService.open(ChecklistQrModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      url:this.visitorUrl,
      title:"Visitor Login QR Code"
    };
  }

  redirectLogin(){
    window.open(this.visitorUrl, '_blank');
    }

  async encrypt(companyId:string,plantId:string) {
  return encodeURIComponent(await this.cryptoService.encryptData(companyId.toString())+"@@"+await this.cryptoService.encryptData(plantId));
  }
}

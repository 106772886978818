import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DockBookingModalComponent } from '../dock-booking-modal/dock-booking-modal.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-transporter-bookings',
  templateUrl: './transporter-bookings.component.html',
  styleUrls: ['./transporter-bookings.component.scss']
})
export class TransporterBookingsComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'TRANSPORTER BOOKINGS');
  responseData:any[]=[];
  bookingCount:any;
  activeTab: number = 0;
  activeTabName: string = 'Booked';
  //RowGroup[]=[];
  actionLabels: any[] = [
    {
      tabName: 'Booked',
      tab_icon: 'check-circle',
      tabBgColor: '#cae6fc',
      tabIconBg: '#125386',
    },
    {
      tabName: 'Inprogress',
      tab_icon: 'hourglass',
      tabBgColor: '#ebeb98',
      tabIconBg: '#a7a708',
    },
    {
      tabName: 'Completed',
      tab_icon: 'check',
      tabBgColor: '#b0f6b0',
      tabIconBg: '#00bd00',
    },
    {
      tabName: 'Cancelled',
      tab_icon: 'times',
      tabBgColor: '#f7d5d5',
      tabIconBg: '#d60000',
    },
  ];


  ngAfterViewInit() {
    this.getAllTransporterBookings();
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    public table: TableService,
    public breadcrumbService: BreadcrumbService,
    public api:ApiService,
    public common:CommonService,
    public userService : UserService,
    public modalService : NgbModal,
    public alert : AlertService
    ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Appointment', url: null },
      { name: 'My Bookings', url: '/user/transporter-bookings' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
   }

  ngOnInit(): void {
    this.getBookingCount();
  }

  changeTabs(i: any, tab: any) {
    // this.getActionsByStatus(tab.tabName == 'completed' ? 'PASS' : tab.tabName == 'rejected' ? 'FAIL' : tab.tabName.toUpperCase());
    this.activeTab = i;
    this.activeTabName = tab.tabName;
    this.getAllTransporterBookings();
  }

  getAllTransporterBookings() {
    this.common.loading = true;
    let params ={
      status : this.activeTabName
    }
    this.api.get('transporter/get-all-transporter-bookings',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          // res.data.data = JSON.parse(res.data.data);
          this.responseData = res.data.map(data1 => {
            data1.data = JSON.parse(data1.data)
            return data1;
          });
          // this.groupedData =
          // this.groupData(this.responseData);
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getBookingCount() {
    this.common.loading = true;
    this.api.get('transporter/get-transporter-booking-count').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.bookingCount = res.data[0];
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  tripTrackingModal(vehicle) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.transaction_id,
      vehicleName: vehicle.vehicle_rsn + '-' + vehicle.process_name,
    };
  }


  openBookingModal(row:any,data?:any){
    // let newDate = new Date(this.currentDateTime);
    // newDate.setDate(this.currentDateTime.getDate() + this.currentTime);
    // let date1 = new Date();
    // let date2 = new Date(row.booking_date);
    console.log("rtyui",row,data);
    let activeModal = this.modalService.open(DockBookingModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'l',
    });
    activeModal.componentInstance.refData = {
      destinationId : row.destination_id,
      slotIndex : row.queue_index,
      dailySlotId : row.daily_slot_id,
      startTime : row.start_time,
      endTime : row.end_time,
      productId:row.product_id,
      // activeDate:this.activeDate?this.activeDate:this.currentDateTime.toISOString().substring(0,10),
      activeDate: row.booking_date,
      editFlag:true,
      bookingsFlag:true,
      bookingId:row.id,
      slotQueueId:row.slot_queue_id,
      driverId:data.driver_id,
      driverName:data.driver_name,
      vehicleId:data.vehicle_id,
      vehicleName:data.vehicle_rsn,
      transaction_id:data.transaction_id
    };
    activeModal.result.then((data: any) => {
      if (data.response) {
      this.getAllTransporterBookings();
      }
    });
  }

  checkDateTime(dateTimeString){
    var parts = dateTimeString.split(/[- :]/);
    var isoString = `${parts[2]}-${parts[1]}-${parts[0]}T${parts[3]}:${parts[4]}`;
    let date1 = new Date(isoString);
    var date2 = new Date();
    return date2<=date1;
  }

  confirmAlert(bookingId:number) {
    this.common.params = {
      title: 'Cancel Booking',
      description: `<b>&nbsp;` + 'Are you sure you want to cancel your slot booking ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.cancelBooking(bookingId);
      }
    });
  }

  cancelBooking(bookingId:any){
    let params={
      bookingId : bookingId
    }
    this.api.get("dock/cancel-dock-booking",params).subscribe((res: any) => {
      if (res.status) {
        this.alert.success("Booking Cancelled Successfully");
      }
      this.getBookingCount();
      this.getAllTransporterBookings();
    });
  }

  // groupData(data1: RowData[]) {
  //   const groupedDataMap = new Map<string, RowData[]>();
  //   this.groupedData=[];
  //   data1.forEach(row => {
  //    if (row.transaction_id === null || row.status === 'Cancelled') {
  //       this.groupedData.push({ expanded: true, rows: [row] });
  //     } else {
  //       if (!groupedDataMap.has(row.transaction_id)) {
  //         groupedDataMap.set(row.transaction_id, []);
  //       }
  //       groupedDataMap.get(row.transaction_id).push(row);
  //     }
  //   });

  //   groupedDataMap.forEach((rows, transaction_id) => {
  //     this.groupedData.push({ expanded: true, rows });
  //   });
  //   // const groups: { [key: string]: RowGroup } = {};

  //   // data.forEach(row => {
  //   //   if (!groups[row.transaction_id]) {
  //   //     groups[row.transaction_id] = { transaction_id: row.transaction_id, rows: [] };
  //   //   }
  //   //   groups[row.transaction_id].rows.push(row);
  //   // });

  //   // return Object.values(groups);
  // }
}

<div class="card">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{"asset_request"|customTranslate}}
      </h3>
    </div>
  </div>
  <div class="card-body">
    <div class="status_wise">
      <div *ngFor="let row of statusCard" class="status">
        <div class="small_text" [style]="{color:row.cardColor}">{{row.cardName|customTranslate}}</div>

        <div class="status_num_graph">
          <span class="small_num">{{requestCount[row.cardCap]??0}}</span>
          <i class="fa fa-bar-chart" [style]="{color: row.cardColor}"></i>
        </div>
      </div>
    </div>

    <table class="custom-table"  *ngIf="responseData.length!=0;else notFound">
      <thead>
        <tr>
          <th>{{"from_date" | customTranslate}}</th>
          <th>{{"to_date" | customTranslate}}</th>
          <th>{{"status" | customTranslate}}</th>
          <th>{{'requested_by'| customTranslate}}</th>
          <th>{{"requested_on" | customTranslate}}</th>
          <th>{{"checkout_date" | customTranslate}}</th>
          <th>{{"return_date" | customTranslate}}</th>
          <th>{{"priority" | customTranslate}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let field of responseData">
          <td>{{field.from_date|date:'dd-MM-yyyy'}}</td>
          <td>{{field.end_date|date:'dd-MM-yyyy'}}</td>
          <td>
            <span class="pill"
                [ngClass]="{'bg-indigo-100 text-indigo-700':field.status_name=='CHECKEDIN','bg-blue-200 text-blue-800':field.status_name=='CHECKEDOUT','bg-green-200 text-green-800':field.status_name=='ASSIGNED','bg-yellow-200 text-yellow-800':field.status_name=='APPROVED','bg-teal-200 text-teal-800':field.status_name=='PENDING','bg-red-200 text-red-800':field.status_name=='REJECTED'}">{{field.status_name}}
                </span>
          </td>
          <td>
            {{field.entity_name}}
          </td>
          <td>{{field.addtime|date:'dd-MM-yyyy'}}</td>
          <td>{{field.checkout_date!=null?(field.checkout_date|date:'dd-MM-yyyy'):(field.from_date|date:'dd-MM-yyyy')}}</td>
          <td>{{field.return_date!=null?(field.return_date|date:'dd-MM-yyyy'):(field.end_date|date:'dd-MM-yyyy')}}</td>
          <td>{{field.priority}}</td>
          <td class="action">
            <div class="btn-block">
              <button class="btn btn-primary" (click)="openRemarkModal(field)">{{'view'|customTranslate}}</button>
              <button class="btn btn-info" (click)="linkCopy(field)">{{'copy'|customTranslate}}</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <ng-template #notFound >
      <div class="message">
      <img src="assets/images/icons8-nothing-found-80.png">
      <span style="margin-bottom: 10px;">{{"no_asset_requests_exists"|customTranslate}}</span>
      </div>
    </ng-template>
  </div>
</div>


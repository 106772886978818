
<div class="compliance_error">
  <div class="title_row">
    <p>{{'Compliance Error'|customTranslate}}</p>
    <i *ngIf="refData" class="fa fa-sync" matTooltip="Refresh List" matTooltipPosition="below"
      (click)="getComplianceAlertLogs()"></i>
  </div>
  <div class="error_div">
    <ng-container *ngFor="let compliance of complainceAlertLog">
      <div class="error_row" *ngIf="compliance.log_type!='prev_process'">
        <div class="error_left">{{compliance.title}}
          <span class="pill"
            [ngStyle]="{'background-color':compliance.type_color,'color':'white'}">{{compliance.severity_or_status_name}}</span>

        </div>
        <i class="fa fa-info-circle" (click)="view(compliance)"></i>
        <button type="button" class="error_btn"
          (click)="saveComplianceLog(compliance)">{{'ReActive'|customTranslate}}</button>
      </div>
      <div class="error_row" *ngIf="compliance.log_type=='prev_process' && !compliance.same_process" style="background-color: #ffffe1;animation: blinker 0.63s step-start infinite;">
        <div class="error_left"><span>Process Change
          <br>Prev Process : <span class="bg-red-200 text-red-800 pill">{{compliance.prev_process}}</span></span></div>
          <i class="fa fa-info-circle" (click)="tripModal(compliance)" style="margin-right: 1%;"></i>
      </div>
    </ng-container>
  </div>

  <div class="add_compliance">
    <button [disabled]="!IN || !refData" (click)="addCompliance()">+ {{'Add Compliance'|customTranslate}}</button>
  </div>
</div>



<div class="card main-card" *ngIf="userService.permissionMap.get('process_dashboard_access')==true">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{"process_dashboard" | customTranslate}} - <span>{{tmgdashboard.currentProcess}}</span>
      </h3>
    </div>
  </div>

  <div class="card-body">
    <div class="vehicle-compliance" *ngIf="vehicleComplianceChartData.length">
      <div style="width:100%;overflow-x: auto;height: 330px;">
        <canvas baseChart width="1000" height="250" [datasets]="vehicleComplianceChartData"
          [labels]="vehicleComplianceChartLabels" [options]="barChartOptions" [plugins]="vehicleComplianceChartPlugins"
          [legend]="vehicleComplianceChartLegend" [chartType]="vehicleComplianceChartType" [colors]="barChartColors">
        </canvas>
      </div>
    </div>
  </div>


  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{"process_trip_count" | customTranslate}}
      </h3>
    </div>
  </div>

  <div class="card-body">
    <div class="vehicle-compliance" *ngIf="processCountChartData.length">
      <div style="width:100%;overflow-x: auto;height: 330px;">
        <canvas baseChart width="1000" height="250" [datasets]="processCountChartData"
          [labels]="processCountChartLabels" [options]="barChartsOptions" [plugins]="processCountChartPlugins"
          [legend]="processCountChartLegend" [chartType]="processCountChartType" [colors]="barChartsColors">
        </canvas>
      </div>
    </div>
  </div>

</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="loader_section">
                <div class="row tab_title">
                  <div class="col-md-2 p-0">
                    <div class="title_page">
                      <p>{{"Loader View" | customTranslate}}</p>
                    </div>
                  </div>
                  <div class="col-md-2 p-0" *ngIf="role.user_role=='COMPANYADMIN' && refData == null">
                    <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                      placeholder="Choose Plant">
                    </ps-auto-suggestion>
                  </div>

                  <div class="col-md-10 p-0">
                    <div class="tab_view">
                      <p *ngFor="let data of tabData" [class.activeTab]="activeSection == data.process_id"
                        (click)="selectedTab(data)">
                        <i [class]="data.icon" style="transform: rotateY(180deg); text-transform:capitalize;"
                          onselect="selectedTab($event)"></i>
                        {{data.process_name}}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="kanban_view">
                  <div class="total_div">
                    {{selectedProcess}}
                    <span class="badge badge-primary ml-1">{{vehicleCount}}</span>
                  </div>

                  <div class="search_box">
                    <div class="search_input">
                      <input type="text" class="form-control search-input" [(ngModel)]="term"
                        (keyup)="searchVehicle(term)" placeholder="Search Vehicle">
                      <i class="fa fa-search"></i>
                    </div>
                  </div>

                  <div class="loader_div" *ngIf="loaderViewData.length">
                    <div class="column_view" [class]="'column_view-'+((i)%6)"
                      *ngFor="let item of loaderViewData;index as i">
                      <div class="title">
                        {{item.destinationName}} <span>({{item.vehicleCount}})</span>
                      </div>

                      <div class="box_column">
                        <ng-container *ngFor="let data of item.vehicleData">
                          <div class="box" *ngIf="data">
                            <div class="top">
                              <div class="image_hardware">
                                <img src="assets/images/truck-loading.gif">
                              </div>
                              <div class="data">
                                <span><i class="fa fa-truck"></i> {{data.vehicle_no}}</span>
                                <span><i class="fa fa-clock"></i> {{data.on_loader_time}}</span>
                              </div>
                            </div>
                            <div class="detail">
                              <span class="span-detail left" (click)="tripModal(data)">{{'Trip Detail'|customTranslate}}
                                <i class="fa fa-info-circle icon_design"></i>
                              </span>
                              <span class="span-detail right" (click)="tripTrackingModal(data)">
                                {{'Trip Tracking'|customTranslate}}
                                <i class="fa fa-map-marker icon_design"></i>
                              </span>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>

                  </div>
                  <app-data-not-found *ngIf="!loaderViewData.length" class="loader_div"></app-data-not-found>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ProcessStageService } from './../process-stage.service';
import { TableService } from '../../../@core/services/table.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/@core/services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { VehicleHistoryComponent } from 'src/app/modals/vehicle-history/vehicle-history.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-trip-detail',
  templateUrl: './trip-detail.component.html',
  styleUrls: ['./trip-detail.component.scss'],
})
export class TripDetailComponent implements OnInit {
  triphistory = [
    { stage: 'Gate In', time: '14-06-2021 05:00' },
    { stage: 'Yard In', time: '14-06-2021 05:25' },
    { stage: 'Tare Weight', time: '14-06-2021 05:35' },
    { stage: 'Loading', time: '14-06-2021 05:50' },
    { stage: 'Gross Weight', time: '14-06-2021 06:10' },
    { stage: 'Gate Out', time: '14-06-2021 06:25' },
  ];

  orderDetail: any = [{ type: 'Raw Material', transporter: 'Balaji' }];

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6);
  driverdetail: any = [];
  currentIndex: number = 0;
  stagestatus: any = [];
  currentProcess: any = '';
  currentStage: any = '';
  vehicle: any = [];
  triporder: any = [];
  tripdata: any = [];
  // txnDetails: any = [];
  // tripHistory: any = [];
  tripviewbase = localStorage.getItem('tripViewbase');
  txnDetailsAndHistory: any = [];
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  tripdetail: any = [];
  stagedetail: any = [];
  constructor(
    public common: CommonService,
    public table: TableService,
    public stage: ProcessStageService,
    private modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    private api: ApiService
  ) {
    this.getTripOrderAndDriverDetails();
    this.breadcrumbService.setActiveBreadCrumb(false);
  }

  ngOnInit(): void {}

  objectHandler() {
    this.tripdetail = this.stagedetail.trip;
    this.vehicle = this.tripdetail[this.currentIndex].vehicle;
    this.triporder = this.tripdetail[this.currentIndex].transporter;
  }

  vehicleHistoryModal() {
    let activeModal = this.modalService.open(VehicleHistoryComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      vehicleId: this.txnDetailsAndHistory[0].vehicle_id,
    };
  }

  triphandler() {
    let tripItem: any = localStorage.getItem('trip');
    this.currentProcess = localStorage.getItem('process');
    this.currentStage = localStorage.getItem('stage');
    this.stagedetail = JSON.parse(tripItem) ?? this.common.tripdetail;

    if (this.tripviewbase == 'table') {
      this.tripdetail = this.stagedetail.trip;
      this.vehicle = this.tripdetail.vehicle;
      this.triporder = this.tripdetail.transporter;
      this.orderDetail = this.common.objecttoarray(this.triporder);
    }

    if (this.tripviewbase == 'map') {
      this.objectHandler();
    }
  }

  getTripOrderAndDriverDetails() {
    let tripId = localStorage.getItem('tripId');
    this.common.loading = true;
    this.api
      .get(
        'trip/get-trip-vehicle-order-and-driver-details?txnId=' +
          parseInt(tripId)
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.txnDetailsAndHistory =
            res.data.map((item) => ({
              ...item,
              trip_history: JSON.parse(item.trip_history),
            })) || [];
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-assign-process',
  templateUrl: './assign-process.component.html',
  styleUrls: ['./assign-process.component.scss'],
})
export class AssignProcessComponent implements OnInit {
  processList: any = [];
  processId: any = null;
  txnId: any = null;
  @Input() public refData;

  constructor(
    public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal
  ) {
    this.getProcessList();
  }

  ngOnInit(): void {
  }
  getProcessList() {
    this.common.loading = true;
    this.api.get('process/get-all-process?processActive=true').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.processList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectProcess(event: any) {
    this.processId = event.processId;
  }


  saveProcess() {
    this.common.loading = true;
    let params = {
      txnId: this.refData.txnId,
      processId: this.processId,
    };
    this.api.post('transaction/update-txn-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.closeModal(true);
        }
      },
      (err: any) => {
        this.common.loading = false;
        this.closeModal(false);
      }
    );
  }


  changeProcess() {
    this.common.loading = true;
    let params = {
      txnId: this.refData.txnId,
      processId: this.processId,
      vehicleId : this.refData.vehicleId,
      stageId: this.refData.stageId,
      vehicleRc : this.refData.vehicleRc,
      destinationId : this.refData.destinationId
    };
    console.log('params: ', params);
    this.api.post('transaction/change-txn-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.closeModal(true, true);
        }
      },
      (err: any) => {
        this.common.loading = false;
        this.closeModal(false, true);
      }
    );
  }



  closeModal(response?: any, apiHit?: any , changeProcess?:any) {
    this.activeModal.close({ response: response, apiHit: apiHit ,changeProcess: changeProcess });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { RoleService } from 'src/app/@core/services/role.service';
import { CompanyService } from '../companies/companies.service';
import { PlantService } from '../plant/plant.service';

@Component({
  selector: 'app-hardware-mapping',
  templateUrl: './hardware-mapping.component.html',
  styleUrls: ['./hardware-mapping.component.scss'],
})
export class HardwareMappingComponent implements OnInit {
  hardwareTitle = 'Hardware Mapping';
  hardwareList: any = [];
  destinationList: any = [];
  btn = 'save';
  data: any = [];
  hardwareMappingData: any = [];
  hardwareNameMulti: any = [];
  hardwareId: any = [];
  tempData: any = [];
  destinationName = '';
  destinationId = '';
  previousDestinationId = '';
  mappingId: any = [];
  company: any = [];
  selectedCompanyName = '';
  selectedPlantName = '';
  companyId = '';
  plantId = '';
  plantsData: any[] = [];

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'COMPONENT LIST');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getCompany();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  constructor(
    public api: ApiService,
    private modalService: NgbModal,
    public common: CommonService,
    private activeModal: NgbActiveModal,
    public breadcrumbService: BreadcrumbService,
    private companyService: CompanyService,
    public alertService: AlertService,
    public table: TableService,
    private plantService: PlantService,
    public userService: UserService,
    public role: RoleService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Hardware', url: null },
      { name: 'Hardware Mapping', url: '/user/hardware-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);

    if (this.role.user_role != 'AXESTRACK') {
      this.getDestination();
      this.getHardware();
    }
    this.getHardwareMapping();
  }

  ngOnInit() {}

  selectedHardware(event) {
    let selectedLocalMethods: any = [];
    let selectedNameMulti: any = [];
    event.forEach((element) => {
      selectedLocalMethods.push(element.ipaHardwareId);
      selectedNameMulti.push(element);
    });
    this.hardwareNameMulti = selectedNameMulti;
    this.hardwareId = selectedLocalMethods;
  }

  selectCompany(event: any) {
    this.reset();
    this.companyId = event.companyId;
    this.getPlantById(this.companyId);
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.selectedPlantName = event.plantName;
    this.getDestination();
  }

  getPlantById(id?: any) {
    this.common.loading = true;

    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.plantsData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedDestination(event) {
    this.destinationName = event.stage_destination_code;
    this.destinationId = event.stage_destination_code_id;
  }

  saveHardwareMapping() {
    let params = {};
    if (this.btn == 'update') {
      params = {
        destinationId: this.destinationId,
        previousDestinationId: this.previousDestinationId,
        hardwareData: this.hardwareId,
        mappingId: this.mappingId,
      };
    } else {
      params = {
        destinationId: this.destinationId,
        hardwareData: this.hardwareId,
      };
    }
    this.common.loading = true;
    this.api.post('hardware/save_hardware_mapping', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.responseMessage);
          this.reset();
          this.selectedCompanyName = '';
          this.getHardwareMapping();
        } else {
          this.alertService.error(res.message);
        }
      },
      (err) => {
        console.error('Error: ', err);
        this.alertService.error(err.error.message);
        this.common.loading = false;
      }
    );
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          const data = res.data;
          data.forEach((element) => {
            if (element.companyStatus == 'ACTIVE') this.company.push(element);
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  reset() {
    this.btn = 'save';
    this.hardwareTitle = 'Hardware Mapping';
    this.hardwareId = null;
    this.hardwareNameMulti = null;
    this.destinationId = null;
    this.tempData = null;
    this.destinationName = null;
    this.mappingId = null;
    this.plantsData = null;
    this.selectedPlantName = null;
    this.companyId = null;
    if (this.role.user_role == 'AXESTRACK') {
      this.destinationList = null;
    }
  }

  deleteMapping(item) {
    this.api.post('hardware/delete_hardware_mapping', item).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.reset();
          this.getHardwareMapping();
        } else {
          this.alertService.error(res.message);
        }
      },
      (err) => {
        console.error('Error: ', err);
        this.alertService.error(err.error.message);
        this.common.loading = false;
      }
    );
  }

  editMapping(item) {
    this.btn = 'update';
    this.getHardware();
    this.previousDestinationId = item.destinationId;
    this.destinationName = item.destinationCode;
    this.tempData = item.hardwareName;
    this.hardwareId = item.hardwareId;
    this.destinationId = item.destinationId;
    this.mappingId = item.hardwareMappigId;
    this.selectedCompanyName = item.companyName;
    this.selectedPlantName = item.plantName;
  }

  getHardware() {
    this.common.loading = true;
    this.api.get('hardware/get_hardware').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.hardwareList = res.data;
        this.hardwareNameMulti = this.tempData;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }
  getDestination() {
    this.common.loading = true;
    if (this.role.user_role == 'AXESTRACK') {
      this.api
        .get(
          'destination/list_stage_destination_code?allStatus=false&plantId=' +
            this.plantId
        )
        .subscribe(
          (res: any) => {
            this.common.loading = false;
            this.destinationList = res.data;
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
          }
        );
    } else {
      this.api
        .get('destination/list_stage_destination_code?allStatus=false')
        .subscribe(
          (res: any) => {
            this.common.loading = false;
            this.destinationList = res.data;
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
          }
        );
    }
  }

  getHardwareMapping() {
    this.common.loading = true;
    this.api.get('hardware/get_hardware_mapping_data').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.data.length == 0) {
          this.hardwareMappingData = res.data;
          this.renderTable();
        } else {
          let data: any = [];
          res.data.forEach((element) => {
            let params = {
              hardwareId: [],
              hardwareName: [],
              hardwareNameTable: [],
              hardwareMappigId: [],
              destinationId: '',
              destinationCode: '',
              companyName: '',
              plantName: '',
            };
            JSON.parse(element.data).forEach((data) => {
              params.hardwareId.push(data.hardware_id);
              params.hardwareName.push({
                hardwareName: data.hardware,
                ipaHardwareId: data.hardware_id,
              });
              params.hardwareNameTable.push(data.hardware);
              params.hardwareMappigId.push(data.mapping_id);
            });
            params.destinationId = element.destination_id;
            params.destinationCode = element.stage_destination_code;
            params.companyName = element.company_name;
            params.plantName = element.plant_name;
            data.push(params);
          });
          this.hardwareMappingData = data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  setActiveComponent(item?: any) {
    this.common.params = { details: [item], title: 'Hardware Mapping Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  hardwareNameValidator: any;
  destinationValidator: any;
  formValidator() {
    this.hardwareNameValidator = this.common.nullValidator(
      this.hardwareNameMulti,
      'Hardware Name Mandatory'
    );
    this.destinationValidator = this.common.nullValidator(
      this.destinationName,
      'Destination Mandatory'
    );
    if (!this.destinationValidator.error && !this.hardwareNameValidator.error) {
      this.saveHardwareMapping();
    }
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="tab_section">
        <ng-container *ngFor="let tabitem of tabData">
          <div class="tab" [class.active]="activeTab.tabname == tabitem.tabname" (click)="changeTab(tabitem)">
            <p>{{tabitem.tabname|customTranslate}}</p>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{activeTab.tabname + ' Report'}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="page_design">
                <div class="row m-0">
                  <div class="col-md-6 col-12 p-0">
                    <div class="row">
                      <div class="col-md-5 date">
                        <label>{{'start_date:'|customTranslate}}</label>
                        <input type="date" [max]="today" class="form-control" [(ngModel)]="startDate">
                      </div>
                      <div class="col-md-5 date">
                        <label>{{'end_date:'|customTranslate}}</label>
                        <input type="date" class="form-control" [min]="startDate" [max]="today" [(ngModel)]="endDate">
                      </div>
                      <div class="col-md-2 btn-block text-right flex align-items-end">
                        <button class="btn btn-primary" (click)="getReportData(activeTab.url)">{{"search" |
                          customTranslate}}</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="view_table">
                  <div class="filter_sec">
                    <div class="row">
                      <div class="col-md-3 mb-1" *ngIf="activeTab.key == 'trip'">
                        <label>{{'select_process'|customTranslate}}</label>
                        <ps-auto-suggestion (onSelected)="selectProcess($event)" [data]="processList"
                          display="processName" [placeholder]="'select_process'| customTranslate">
                        </ps-auto-suggestion>
                      </div>

                      <ng-container *ngFor="let filter of filterData;let i = index">
                        <div class="col-md-3 mb-1" *ngIf="filter.values.length">
                          <label>{{ filter.title |customTranslate}}</label>
                          <ps-auto-suggestion (onSelected)="selectFilter($event,i)" [data]="filter.values"
                            display="value"
                            [placeholder]="('select' | customTranslate) + ' ' + (filter.title | customTranslate)"
                            isMultiSelect="true">
                          </ps-auto-suggestion>
                        </div>
                      </ng-container>

                      <div class="col-md-2 btn-block text-right flex align-items-end mb-2">
                        <button class="btn btn-primary" (click)="resetReport()">{{"reset" |
                          customTranslate}}</button>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <app-custom-table [data]="tableData" [columns]="columns"
                      [tableType]="TABLE_TYPE"></app-custom-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #ModalView>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Dispatch Orders' | customTranslate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="view_table">
      <div class="filter_sec">
        <div class="row">

          <ng-container *ngFor="let filter of modalFilterData; let j = index">
            <div class="col-md-3 col1"
              *ngIf="filter.values.length && filter.title != 'invoice_no' && filter.title != 'order_type'">
              <label>{{ filter.title |customTranslate}}</label>
              <ps-auto-suggestion
                (onSelected)="selectFilter($event,j,'modalFilterData','modalData','originalModalData')"
                [data]="filter.values" display="value"
                [placeholder]="('select' | customTranslate) + ' ' + (filter.title | customTranslate)"
                isMultiSelect="true">
              </ps-auto-suggestion>
            </div>
          </ng-container>

        </div>
      </div>
      <div class="mt-3" style="overflow-x: scroll;">
        <app-custom-table [data]="modalData" [columns]="modalColumns"
          [tableType]="TABLE_TYPE"></app-custom-table>
      </div>
    </div>
  </div>
</ng-template>

<!-- <section class="main_body">

</section> -->
<div class="login_page" >

  <div class="login_box">
    <div class="row" style="height: 100%;">
      <div class="col-md-7">
        <div class="left_side">
          <img src="assets/images/visitor_login_banner.jpg">
        </div>
      </div>

      <div class="col-md-5 login-container">
        <div class="right_side">
          <div style="display: flex">
            <img src="assets/images/vms_logo.png" class="vms_logo">
            <div class="form-group" style="margin-top: 20px;">
              <!-- <label for="my-input" style="margin-top: 5px;">{{"WalkIn" | translate}} </label> -->
              <!-- <a href="google.com" style="font-size: ;"> Exit WalkIn Mode</a> -->
              <!-- <input type="checkbox" class="form-control set-checkbox" (change)="handleWalkIn()"> -->
            </div>
          </div>
          <form class="form_field w-100" style="box-shadow: none;">

            <div class="title_login">Sign in to continue to our website</div>
            <div class="input_field">
              <input class="form-control" type="text" name="mobNo" id="mobNo" placeholder="Enter Mobile No"
                [(ngModel)]="mobileNumber" maxlength="10" [disabled]="disableMobileNumber">
            </div>

            <div class="otp_btn">
              <div class="input-field  flex" *ngIf="!userService._walkinMode && !hideWalkIn">
                <label for="my-input" style="margin: 4px;">{{"WalkIn Mode" | translate}} </label>
                <input type="checkbox" class="form-control set-checkbox" (change)="handleWalkIn()">
              </div>
              <!-- <div class="input-field  flex" *ngIf="userService._walkinMode" (click)="signOutWalkInMode()">
                <label for="my-input" style="text-decoration: underline;font-size: 13px;margin: 4px;color: #013e90;text-transform: uppercase;font-weight: 600;">{{"Exit WalkIn Mode" | translate}} <i class="fa fa-sign-in"> </i></label>
              </div> -->
              <button type="submit"  value="genOtp" (click)="generateAndResetOtp()"
                *ngIf="showGenerateOtp">Generate OTP</button>
            </div>

            <div class="input_field" *ngIf="showOtpInput">
              <input class="form-control" type="text" name="enterOtp" id="enterOtp" placeholder="Enter OTP"
                [(ngModel)]="enteredOtp" [disabled]="!isShow">
            </div>

            <div class="submit_btn" *ngIf="showVerifyOtp">

              <div class="verifyOtp">
                <button type="submit" type="submit" value="Verify" (click)="verifyOtp()" *ngIf="isShow">Verify OTP</button>
                <p class="timeLeft" style="display: inline;" *ngIf="isShow">Expires in : {{getFormattedTime()}}</p>
                <p class="timeLeft" style="display: inline;" *ngIf="!isShow">OTP Expired</p>
              </div>
              <div class="resend_otp" *ngIf="!isShow">Didn't receive a code? <button type="submit" class="resend"
                  (click)="generateAndResetOtp()">Resend OTP</button></div>

            </div>
          </form>

          <div class="d-flex align-items-center justify-content-center gap-2 login_options mt-3">
            <a routerLink="/auth/login" style="text-decoration: underline; color: black; font-size: 12px; cursor: pointer;">Plant Panel</a>
            |
            <a routerLink="/auth/port-login" style="text-decoration: underline; color: black; font-size: 12px; cursor: pointer;">Port Panel</a>
          </div>
          <div class="powered_view">
            <a href="https://axestrack.com/">powered by Axestrack</a>
          </div>
        </div>
          <!-- <div class="powered">
            <span>powered by CPL</span>
            <span *ngIf="!this.userService._walkinMode" (click)="routeNagivate()" class="powered" style="cursor: pointer ;text-decoration: underline;
            font-size: 12px;
            margin-top: 12px;
        ">CPL Panel</span>
          </div> -->
        <div class="walk-in-header" *ngIf="userService._walkinMode">
          <span style="font-size: 24px;font-weight: 600;  writing-mode: vertical-lr;    text-orientation: upright;">
            WALKIN MODE
          </span>
        </div>
      </div>

    </div>
  </div>
</div>

<app-alert-message></app-alert-message>

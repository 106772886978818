import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SignatureComponent } from '../signature/signature.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapModalComponent } from 'src/app/modals/map-modal/map-modal.component';
import { CommonService } from 'src/app/@core/services/common.service';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';

@Component({
  selector: 'app-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss']
})
export class CustomFieldComponent implements OnInit {
  @ViewChild(SignatureComponent, { static: false })
  signature: SignatureComponent;
  @Input() field: any;
  @Input() i: number;
  @Input() customFieldValues:any[]
  @Input() selvalue:any=null;
  @Input() notUniqueFieldId:number;
  @Input() manualStamping:boolean=false;
  @Input() readOnlyFlag:boolean=false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() editTrigger: EventEmitter<any> = new EventEmitter<any>();


  image_name: any = '';
  image_url: any = '';
  image_ext: any = '';
  retakeImage: boolean = false;
  readOnly:boolean = true;


  constructor(
    public common: CommonService,
    private modalService: NgbModal,
  ) {


  }

  ngOnInit(): void {
  }

  onValueChanged(event: any) {
    // Emit the value back to the parent component
    // this.valueChange.emit({ index: this.i, value: event.target.value });
    if(this.field.field_type=='SIGNATURE'){
      if (this.signature?.signatureImage) {
        this.signature.captureSignature();
        const signatureImage = this.signature.signatureImage;
        this.valueChange.emit(signatureImage);
      }
    }else{
    this.valueChange.emit(event);
    }
  }

  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  getPreSelected(label, i) {
    return {
      [label]: this.customFieldValues[i].values[0]
    }
  }


  getPreSelectedMulti( label,i) {
    return this.customFieldValues[i].values.map(item => {
      return {[label]:item} });
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }

  location(lat, lng) {
    this.common.params = {
      lat: lat,
      lng: lng,
    };
    const activeModal = this.modalService.open(MapModalComponent, {
      size: 'lg',
    });
  }

  displayImage(url) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  onValuesEntered(event:any , inputType:any){
  this.valueChange.emit({event:event , inputType:inputType});


  }

  editTriggerEmit(flag:boolean){
    this.editTrigger.emit(flag);
  }
}

import { ChecklistQrModalComponent } from 'src/app/components/checklist-qr-modal/checklist-qr-modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Checklist } from 'src/app/dataType/checklistInterface';
@Component({
  selector: 'app-add-checklist',
  templateUrl: './add-checklist.component.html',
  styleUrls: ['./add-checklist.component.scss'],
})
export class AddChecklistComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'CHECKLIST LIST');

// checklistObj.type:0 => FIXED and checklistObj.type:1 => SCHEDULAR
  checklistObj = {
    id: null,
    title: '',
    description: '',
    type: 0,
    tolerance: 0,
    performStartDate: null,
    performEndDate: null,
    intervalType: 'Daily',
    intervalCount: 0,
    data: [
      {
        startTime: null,
        endTime: null,
        dayOfWeek: null,
        dayOfMonth: null,
        monthOfYear:null,
      },
    ],
    status: 'ACTIVE',
  };
  checklistData:  Checklist[] = [];
  selectedDay: string = 'Monday';
  title = 'Add Checklist';
  btn = 'Save';
  checkListStartTime: string = '';
  checkListEndTime: string = '';
  minStartDate: any = new Date();
  //list of iteration type
  iterationType = [
    { type: 'Daily', value: 'Daily' },
    { type: 'Weekly', value: 'Weekly' },
    { type: 'Monthly', value: 'Monthly' },
    { type: 'Yearly', value: 'Yearly' }
  ];

  daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  monthOfYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  titleValidator:any;
  startDateValidator: any;
  endDateValidator: any;
  startTimeValidator: any = [];
  endTimeValidator: any = [];
  startEndDateValidator: any;
  checkListDayTypeValidator: any = false;
  startEndTimeValidator: any = [];

  constructor(
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public alert: AlertService,
    public table: TableService,
    public modalService: NgbModal,
    public alertService: AlertService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Checklist', url: null },
      { name: 'Checklist Management', link: '/user/add-checklist' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.minStartDate.setDate(this.minStartDate.getDate() + 1);
    this.minStartDate = `${this.minStartDate.getFullYear()}-${String(
      this.minStartDate.getMonth() + 1
    ).padStart(2, '0')}-${String(this.minStartDate.getDate()).padStart(
      2,
      '0'
    )}`;
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAllChecklists();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnInit(): void {}



  handleTypeChange(type: any) {
    this.checklistObj.type = type;
    if (type == 0) {
      this.checklistObj.data = [];
      this.checklistObj.data.push({
        startTime: null,
        endTime: null,
        dayOfMonth: null,
        dayOfWeek: null,
        monthOfYear:null
      });
      this.checklistObj.performEndDate = this.checklistObj.performStartDate;
    }
  }

  createSchedularData() {
    this.checklistObj.data = [];
    this.checklistObj.intervalCount =
      this.checklistObj.intervalType == 'Weekly'
        ? Math.min(this.checklistObj.intervalCount, 7)
        : this.checklistObj.intervalType == 'Monthly'
        ? Math.min(this.checklistObj.intervalCount, 31)
        : this.checklistObj.intervalCount;
    this.checklistObj.data = Array(this.checklistObj.intervalCount).fill({}); // Reset the schedularData array
    for (let i = 0; i < this.checklistObj.intervalCount; i++) {
      this.checklistObj.data[i] = {
        startTime: null,
        endTime: null,
        dayOfWeek: null,
        dayOfMonth: null,
        monthOfYear:null
      };
    }
  }

  saveCheckList() {
    this.common.loading = true;
    let params = {
      id: this.checklistObj.id,
      checklistTitle: this.checklistObj.title,
      checklistDescription: this.checklistObj.description,
      tolerance: this.checklistObj.tolerance,
      status: this.checklistObj.status,
      type: this.checklistObj.type,
      performStartDate: this.checklistObj.performStartDate,
      performEndDate: this.checklistObj.performEndDate,
      data: this.checklistObj.data,
      intervalCount:
        this.checklistObj.type == 0 ? 0 : this.checklistObj.intervalCount,
      intervalType:
        this.checklistObj.type == 0 ? 'Daily' : this.checklistObj.intervalType,
    };
    this.api.post('checklist/add-checklist', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alert.info(res.message);
          this.resetDetail();
          this.getAllChecklists();
        }
        this.common.loading = false;
      },
      (err) => {
        this.common.loading = false;
        console.error(err);
      }
    );
  }

  getAllChecklists() {
    this.common.loading = true;
    this.api
      .get('checklist/get-all-checklists?checklistActive=false')
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.checklistData = res.data;
            this.renderTable();
          }
          this.common.loading = false;
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  range(count: number): number[] {
    return Array(count)
      .fill(0)
      .map((x, i) => i);
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Checklist',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteChecklist(row);
      }
    });
  }

  deleteChecklist(row?: any) {
    let params = {
      checklistId: row.id,
    };
    this.api.get('checklist/delete-checklist', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.getAllChecklists();
          this.alertService.info(res.message);
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'info' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  editChecklist(row?: any) {
    this.resetDetail();
    this.common.gotoTop();
    this.btn = 'Update';
    this.title = 'Edit Checklist';
    this.checklistObj.id = row.id;
    this.checklistObj.title = row.checklist_title;
    this.checklistObj.description = row.checklist_description;
    this.checklistObj.type = row.checklist_type;
    this.checklistObj.tolerance = row.tolerance;
    this.checklistObj.performStartDate = row.start_date;
    this.checklistObj.performEndDate = row.end_date;
    this.checklistObj.intervalType = row.interval_type;
    this.checklistObj.intervalCount = row.interval_count;
    this.checklistObj.status = row.status;
    const dataArray = JSON.parse(row.data);
    if (dataArray == null) {
      this.checklistObj.data = [
        {
          startTime: null,
          endTime: null,
          dayOfMonth: null,
          dayOfWeek: null,
          monthOfYear:null
        },
      ];
    } else {
      this.checklistObj.data = dataArray;
    }
  }

  viewQRModal(row?: any) {
    let activeModal = this.modalService.open(ChecklistQrModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      encodedKey: btoa(row.id),
    };
  }

  resetDetail() {
    this.checklistObj.id = null;
    this.checklistObj.title = null;
    this.checklistObj.description = null;
    this.checklistObj.type = 0;
    this.checklistObj.tolerance = 0;
    this.checklistObj.intervalType = 'Daily';
    this.checklistObj.intervalCount = 0;
    this.checklistObj.performStartDate = null;
    this.checklistObj.performEndDate = null;
    this.checklistObj.data = [
      {
        startTime: null,
        endTime: null,
        dayOfMonth: null,
        dayOfWeek: null,
        monthOfYear:null
      },
    ];
    this.checklistObj.status = 'ACTIVE';
    this.btn = 'Save';
    this.title = 'Add Checklist';
    this.titleValidator = null;
    this.startEndDateValidator = null;
    this.startDateValidator = null;
    this.endDateValidator = null;
    this.startEndTimeValidator = [];
    this.startTimeValidator = [];
    this.endTimeValidator = [];
  }


  formValidator() {
    this.titleValidator = null;
    this.startEndDateValidator = null;
    this.startDateValidator = null;
    this.endDateValidator = null;
    this.startEndTimeValidator = [];
    this.startTimeValidator = [];
    this.endTimeValidator = [];
    this.startEndTimeValidator = [];

    this.titleValidator = this.common.nullValidator(
      this.checklistObj.title,
      'Checklist Title Mandatory'
    );
    this.startDateValidator = this.common.nullValidator(
      this.checklistObj.performStartDate,
      'Start Date Mandatory'
    );
    this.endDateValidator = this.common.nullValidator(
      this.checklistObj.performEndDate,
      'End Date Mandatory'
    );

    if (this.checklistObj.type != 0) {
      this.checklistObj.data.forEach((res) => {
        if (
          this.checklistObj.intervalType == 'Weekly' &&
          (res.dayOfWeek == null || res.dayOfWeek == '')
        ) {
          this.checkListDayTypeValidator = true;
          this.alertService.error('Week Day Value Should Not Be Null');
        } else if (
          this.checklistObj.intervalType == 'Monthly' &&
          (res.dayOfMonth == null || res.dayOfMonth == '')
        ) {
          this.checkListDayTypeValidator = true;
          this.alertService.error('Month Day Value Should Not Be Null');
        }else if(this.checklistObj.intervalType == 'Yearly' &&
          (res.monthOfYear == null || res.monthOfYear == '')
        ) {
          this.checkListDayTypeValidator = true;
          this.alertService.error('Month Value Should Not Be Null');
        }
      });
    }

    if (this.checklistObj.type == 0) {
      this.startEndDateValidator = this.common.startEndDateValidator(
        this.checklistObj.performStartDate,
        this.checklistObj.performEndDate,
        true
      );
    }
    if (this.checklistObj.type == 1) {
      this.startEndDateValidator = this.common.startEndDateValidator(
        this.checklistObj.performStartDate,
        this.checklistObj.performEndDate,
        false
      );
    }
    this.checklistObj.data.forEach((e, index) => {
      this.startTimeValidator[index] = this.common.nullValidator(
        e.startTime,
        'Start Time Mandatory'
      );
      this.endTimeValidator[index] = this.common.nullValidator(
        e.endTime,
        'End Time Mandatory'
      );
      this.startEndTimeValidator[index] = this.common.startEndTimeValidator(
        e.startTime,
        e.endTime,
        false
      );
    });
    if (
      !this.titleValidator.error &&
      !this.startDateValidator.error &&
      !this.endDateValidator.error &&
      !this.checkListDayTypeValidator.error
    ) {
      if (this.startEndDateValidator.error) {
        this.alert.error(this.startEndDateValidator.msg);
      } else if (
        !this.startTimeValidator.some((validator) => validator.error) &&
        !this.endTimeValidator.some((validator) => validator.error)
      ) {
        const invalidValidator = this.startEndTimeValidator.find(
          (validator) => validator.error
        );
        if (invalidValidator) {
          this.alert.error(invalidValidator.msg);
        } else {
          this.saveCheckList();
        }
      }
    }
  }

  checklistChecker(index: any, event: any, type: string) {
    if (this.checklistObj.intervalType == 'Daily') {
      let checkListSize =
        this.checklistObj.type == 0 ? 0 : this.checklistObj.intervalCount - 1;
      this.checkListStartTime = _.cloneDeep(
        this.checklistObj.data[0].startTime
      );
      this.checkListEndTime = _.cloneDeep(
        this.checklistObj.data[checkListSize].endTime
      );
      if (
        this.checkListStartTime > this.checkListEndTime ||
        this.checklistObj.data[index].startTime >
          this.checklistObj.data[index].endTime
      ) {
        this.checklistObj.data[index][type + 'Time'] = null;
        this.alertService.error(
          'Checklist Perform StartTime should be less than Checklist EndTime'
        );
      } else if (
        index != 0 &&
        this.checklistObj.data[index - 1].endTime > event.target.value &&
        type == 'start'
      ) {
        this.checklistObj.data[index][type + 'Time'] = null;
        this.alertService.error(
          'Checklist Perform StartTime should be greater than Previous Checklist EndTime'
        );
      } else if (
        index != checkListSize &&
        this.checklistObj.data[index + 1].startTime < event.target.value &&
        type == 'end'
      ) {
        this.checklistObj.data[index][type + 'Time'] = null;
        this.alertService.error(
          'Checklist Perform EndTime should be less than next Checklist StartTime'
        );
      }
    }
  }

  checklistIntervalCheck(day: any, index) {
    let count = 0;
    this.checklistObj.data.forEach((res) => {
      if (
        this.checklistObj.intervalType == 'Weekly' &&
        res.dayOfWeek == day.target.value
      ) {
        count += 1;
      } else if (
        this.checklistObj.intervalType == 'Monthly' &&
        res.dayOfMonth == day.target.value
      ) {
        count += 1;
      } else if (
        this.checklistObj.intervalType == 'Yearly' &&
        res.monthOfYear == day.target.value
      ) {
        count += 1;
      }
    });

    if (count > 1) {
      this.alertService.error(
        this.checklistObj.intervalType == 'Yearly'?'Month Should Be Different !!':(this.checklistObj.intervalType + ' Day Should Be Different !!')
      );
    }
    this.checklistObj.data[index][
      this.checklistObj.intervalType == 'Yearly'?'monthOfYear':(this.checklistObj.intervalType == 'Weekly' ? 'dayOfWeek' : 'dayOfMonth')
    ] = count == 1 ? day.target.value : '';
  }

  iterationChange(){
    this.checklistObj.intervalCount=0;
    this.checklistObj.data = [
      {
        startTime: null,
        endTime: null,
        dayOfMonth: null,
        dayOfWeek: null,
        monthOfYear:null
      },
    ];
  }
}

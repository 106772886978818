import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { TableService } from 'src/app/@core/services/table.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { approvalFlowResponseData } from 'src/app/dataType/approvalFlowInterface';
import { validator } from 'src/app/dataType/commonInterface';
declare var $: any;

@Component({
  selector: 'app-approval-flow',
  templateUrl: './approval-flow.component.html',
  styleUrls: ['./approval-flow.component.scss']
})
export class ApprovalFlowComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'Approval-Flow List');
  approvalFlow = {
    approvalFlowId: null,
    approvalFlowName: null,
    approvalFlowDesc: null,
    approvalFlowStatus: 'ACTIVE',
    removeLevels: [],
    levels: [{
      levelId: null,
      levelName: null,
      levelDesc: null,
      levelRank: 1,
      expTat: null,
      escallationCondition: null,
      joinCondition: null,
      entities: [{
        sublevelId: null,
        entityType: null,
        subEntity: null,
        entityId: null,
        entityName: null
      }]
    }]
  }

  entityType = [
    { name: 'User', value: 'USER' },
    { name: 'Department', value: 'DEPARTMENT' },
    { name: 'Department Head', value: 'DEPARTMENTHEAD' },
    { name: 'Group', value: 'GROUP' },
    { name: 'Group Head', value: 'GROUPHEAD' },
  ]

  escalationCondition = [
    { value: 'APPROVE' },
    { value: 'REJECT' },
    { value: 'BOTH' }
  ]

  subEntity1 = [
    { value: 'FIXED' },
    { value: 'DYNAMIC' }
  ]

  subEntity2 = [
    { value: 'ALL' },
    { value: 'ANY' }
  ]

  levelNameValidator: validator[] = [];
  entityTypeValidator: [validator[]] = [[]];
  subEntityValidator: [validator[]] = [[]];
  entityIdValidator: [validator[]] = [[]];
  levelIndex: number = 0
  approvalFlowlNameValidator: validator = null;
  responseData: approvalFlowResponseData[];
  userList = []
  groupList = []
  departmentList = []
  hideShow: boolean[] = [true];
  tatValidator: validator = null;
  escallationConditionValidator: validator = null;
  showTat = [false]
  editModalFlag: boolean = false;

  constructor(public common: CommonService, private breadcrumbService: BreadcrumbService, private modalService: NgbModal, public role: RoleService, public table: TableService, public api: ApiService, public alertService: AlertService, public activeModal: NgbActiveModal) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Approval-Flow', url: null },
      { name: 'Approval-Flow Management', url: '/user/approval-flow' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getApprovalFlowData();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  resetWorkType(): void {
    this.approvalFlow = {
      approvalFlowId: null,
      approvalFlowName: null,
      approvalFlowDesc: null,
      approvalFlowStatus: 'ACTIVE',
      removeLevels: [],
      levels: [{
        levelId: null,
        levelName: null,
        levelDesc: null,
        levelRank: 1,
        expTat: null,
        escallationCondition: null,
        joinCondition: null,
        entities: [{
          sublevelId: null,
          entityType: null,
          subEntity: null,
          entityId: null,
          entityName: null
        }]
      }]
    };
    this.levelIndex = 0;
    this.approvalFlowlNameValidator = null;
    this.hideShow = [true];
    this.tatValidator = null;
    this.escallationConditionValidator = null;
    this.showTat = [false];
    this.levelNameValidator = [];
    this.entityTypeValidator = [[]];
    this.subEntityValidator = [[]];
    this.entityIdValidator = [[]];
    this.editModalFlag = false;
  }


  getApprovalFlowData() {
    let params = {
      allApprovalFlow: true,
    }
    this.common.loading = true;
    this.api.get('approval-flow/get-all-approval-flow', params).subscribe((res: any) => {
      if (res.status) {
        this.common.loading = false;
        this.responseData = res.data;
        this.responseData.forEach((res) => {
          res.levelData = JSON.parse(res.levels);
        });
        this.renderTable();
      }
    }, (error) => {
      this.common.loading = false;
      console.log("Error:", error)
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Approval Flow',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row.flow_title +
        '  ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteApprovalFlow(row);
      }
    });
  }

  deleteApprovalFlow(row) {
    this.common.loading = true;
    let params = {
      approvalFlowId: row.approval_flow_id
    }
    this.api.get('approval-flow/delete-approval-flow', params).subscribe((response) => {
      this.common.loading = false;
      if (response['status']) {
        this.alertService.success(response['message']);
        this.resetWorkType();
        this.getApprovalFlowData();
      }
    }, (error) => {
      this.common.loading = false;
      console.log("Error:", error)
    });
  }

  edit(row: any) {
    this.approvalFlow.approvalFlowId = row.approval_flow_id;
    this.approvalFlow.approvalFlowName = row.flow_title;
    this.approvalFlow.approvalFlowDesc = row.flow_description;
    this.approvalFlow.approvalFlowStatus = row.status;
    this.approvalFlow.removeLevels = [];
    this.approvalFlow.levels = row.levelData;
    this.hideShow = new Array(this.approvalFlow.levels.length).fill(true);
    let obj: validator = {
      msg: null,
      error: false
    }
    this.approvalFlow.levels.forEach((level, i) => {
      this.entityIdValidator[i] = new Array(level.entities.length).fill(obj);
      this.subEntityValidator[i] = new Array(level.entities.length).fill(obj);
      this.entityTypeValidator[i] = new Array(level.entities.length).fill(obj);
    });
    if (this.userList.length == 0) this.getUserList();
    if (this.groupList.length == 0) this.getGroupList();
    if (this.departmentList.length == 0) this.getDepartmentList();
  }


  formValidator() {
    this.approvalFlowlNameValidator = this.common.nullValidator(
      this.approvalFlow.approvalFlowName != null && this.approvalFlow.approvalFlowName.trim(),
      'Approval Flow Title Mandatory'
    );
    this.approvalFlow.levels.forEach((level, i) => {
      this.levelNameValidator[i] = this.common.nullValidator(
        level.levelName != null && level.levelName.trim(),
        'Level Name Mandatory'
      );

      level.entities.forEach((entity, j) => {
        this.entityTypeValidator[i][j] = this.common.nullValidator(
          entity.entityType,
          'Entity Type Mandatory'
        );
        this.subEntityValidator[i][j] = this.common.nullValidator(
          entity.subEntity,
          'Entity Grouping Mandatory'
        );
        this.entityIdValidator[i][j] = entity.subEntity != 'DYNAMIC' ? this.common.nullValidator(
          entity.entityId,
          'Entity Mandatory'
        ) : { msg: null, error: false };
      })
    })
    if (!this.approvalFlowlNameValidator.error && !this.levelNameValidator.some(level => level.error == true) && !this.entityTypeValidator.some(level => level.some(validator => validator.error)) && !this.subEntityValidator.some((level) => level.some(validator => validator.error)) && !this.entityIdValidator.some((level) => level.some(validator => validator.error))) {
      this.saveApprovalFlow();
    }
    // && !this.levelJoinValidator.some(level => level.error == true)
  }

  saveApprovalFlow() {
    this.common.loading = true;
    this.api.post('approval-flow/save-approval-flow', this.approvalFlow).subscribe((response) => {
      this.common.loading = false;
      if (response['status']) {
        this.alertService.success(response['message']);
        this.resetWorkType();
        this.getApprovalFlowData();
      }
    }, (error) => {
      this.common.loading = false;
      console.log("Error:", error)
    });
  }

  selectEntityType(event, levelIndex, sublevelIndex) {
    this.approvalFlow.levels[levelIndex].entities[sublevelIndex].entityType = event.value;
    this.approvalFlow.levels[levelIndex].entities[sublevelIndex].entityId = null;
    this.approvalFlow.levels[levelIndex].entities[sublevelIndex].entityName = null;
    this.approvalFlow.levels[levelIndex].entities[sublevelIndex].subEntity = null;
  }

  selectSubEntity(event, levelIndex, sublevelIndex) {
    this.approvalFlow.levels[levelIndex].entities[sublevelIndex].subEntity = event.value;
    let val = this.approvalFlow.levels[levelIndex].entities[sublevelIndex].entityType
    if (event.value != 'DYNAMIC') {
      if (val == 'USER' && this.userList.length == 0) {
        this.getUserList();
      } else if ((val == 'GROUPHEAD' || val == 'GROUP') && this.groupList.length == 0) {
        this.getGroupList();
      } else if (this.departmentList.length == 0) {
        this.getDepartmentList();
      }
      this.approvalFlow.levels[levelIndex].entities[sublevelIndex].entityId = null;
      this.approvalFlow.levels[levelIndex].entities[sublevelIndex].entityName = null;
    }
  }

  selectEntity(event, levelIndex, sublevelIndex, key) {
    let id, name;
    if (key == 'GROUP') {
      id = 'groupId';
      name = 'groupName';
    } else if (key == 'DEPARTMENT') {
      id = 'dept_id';
      name = 'dept_name';
    } else {
      id = 'userId';
      name = 'name';
    }
    const exist = this.approvalFlow.levels[levelIndex].entities.some(entity => entity.entityId == event[id] && entity.entityType ==
      this.approvalFlow.levels[levelIndex].entities[sublevelIndex].entityType && this.approvalFlow.levels[levelIndex].entities[sublevelIndex].subEntity == entity.subEntity
    )
    if (exist) {
      this.alertService.error("Already Same Entity Mapped for this level !");
    }
    this.approvalFlow.levels[levelIndex].entities[sublevelIndex].entityName = event[name];
    this.approvalFlow.levels[levelIndex].entities[sublevelIndex].entityId = event[id];
  }


  getDepartmentList() {
    let params = { allDepartments: false }
    this.common.loading = true;
    this.api.get('department/get-department-list', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.departmentList = res.data;
        }
        this.common.loading = false;
      },
      (err) => {
        this.common.loading = false;
        console.log('Error:', err);
      }
    );
  }

  getGroupList() {
    let params = { plantType: false }
    this.common.loading = true;
    this.api.get('group/all-group', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.groupList = res.data;
        }
        this.common.loading = false;
      },
      (err) => {
        this.common.loading = false;
        console.log('Error:', err);
      }
    );
  }

  getUserList() {
    this.common.loading = true;
    this.api.get('user/all_user').subscribe(
      (res: any) => {
        if (res.status) {
          this.userList = res.data;
        }
        this.common.loading = false;
      },
      (err) => {
        this.common.loading = false;
        console.log('Error:', err);
      }
    );
  }


  addSublevel(levelIndex) {
    this.approvalFlow.levels[levelIndex].joinCondition==null?this.approvalFlow.levels[levelIndex].joinCondition='AND':null;
    this.approvalFlow.levels[levelIndex].entities.push({
      sublevelId: null,
      entityType: null,
      subEntity: null,
      entityId: null,
      entityName: null
    });
    this.entityIdValidator[levelIndex].push({
      msg: null,
      error: false
    });
    this.entityTypeValidator[levelIndex].push({
      msg: null,
      error: false
    });
    this.subEntityValidator[levelIndex].push({
      msg: null,
      error: false
    });
  }


  saveModal() {
    this.tatValidator = this.common.nullValidator(this.approvalFlow.levels[this.levelIndex].expTat, 'Expected TAT is mandatory');
    this.escallationConditionValidator = this.common.nullValidator(this.approvalFlow.levels[this.levelIndex].escallationCondition, 'Escalation Condition is mandatory');
    if (!this.tatValidator.error && !this.escallationConditionValidator.error && !this.editModalFlag) {
      $("#exampleModal").modal("hide");
      this.showTat[this.levelIndex] = true;
      this.showTat.push(false);
      this.approvalFlow.levels.push({
        levelId: null,
        levelName: null,
        levelDesc: null,
        levelRank: this.levelIndex + 2,
        expTat: null,
        escallationCondition: null,
        joinCondition: null,
        entities: [{
          sublevelId: null,
          entityType: null,
          subEntity: null,
          entityId: null,
          entityName: null
        }]
      })
      this.entityIdValidator.push([{
        msg: null,
        error: false
      }]);
      this.entityTypeValidator.push([{
        msg: null,
        error: false
      }]);
      this.subEntityValidator.push([{
        msg: null,
        error: false
      }]);
      this.hideShow.push(true);
    } else if (this.editModalFlag) {
      $("#exampleModal").modal("hide");
    }
    this.editModalFlag = false;
  }

  minusLevel(levelIndex) {
    if (this.approvalFlow.levels[levelIndex].levelId != null) {
      this.approvalFlow.removeLevels.push(this.approvalFlow.levels[levelIndex].levelId);
    }
    this.approvalFlow.levels.splice(levelIndex, 1);
    this.hideShow.splice(levelIndex, 1);
    this.approvalFlow.levels[levelIndex - 1].expTat = null;
    this.approvalFlow.levels[levelIndex - 1].escallationCondition = null;
    this.entityIdValidator.splice(levelIndex, 1);
    this.entityTypeValidator.splice(levelIndex, 1);
    this.subEntityValidator.splice(levelIndex, 1);
  }

  minusSublevel(levelIndex, sublevelIndex) {
    this.approvalFlow.levels[levelIndex].entities.splice(sublevelIndex, 1);
    if (this.approvalFlow.levels[levelIndex].entities.length == 1) {
      this.approvalFlow.levels[levelIndex].joinCondition = null;
    }
    this.entityIdValidator[levelIndex].splice(sublevelIndex, 1);
    this.subEntityValidator[levelIndex].splice(sublevelIndex, 1);
    this.entityTypeValidator[levelIndex].splice(sublevelIndex, 1);
  }

}

<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Action Action Mapping" | translate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"process" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="processList" display="processName"
                    placeholder="Choose process" [preSelected]="{processName:processName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error">
                    <span>{{processValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="processName!=null">
                  <label for="my-input" class="required">{{("select" | translate)+ " "+("action" | translate)}} </label>
                  <ps-auto-suggestion (onSelected)="selectedAction($event)" [data]="actionsList" display="actionname"
                    placeholder="Choose Action" [preSelected]="{actionname:actionname}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="actionNameValidator && actionNameValidator.error">
                    <span>{{actionNameValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="actionname!=null">
                  <label for="my-input">{{("select" | translate)+ " "+("pre_action" | translate)}} </label>
                  <ps-auto-suggestion  (onSelected)="selectPreActions($event)" [data]="prePostActionsList"
                    display="actionname" placeholder="Choose Pre Actions" isMultiSelect="true"
                    [preSelected]="selectedPreActionNames">
                  </ps-auto-suggestion>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="actionname!=null">
                  <label for="my-input">{{("select" | translate)+ " "+("post_action" | translate)}} </label>
                  <ps-auto-suggestion (onSelected)="selectPostActions($event)" [data]="prePostActionsList"
                    display="actionname" placeholder="Choose Post Actions" isMultiSelect="true"
                    [preSelected]="selectedPostActionNames">
                  </ps-auto-suggestion>
                </div>
              </div>
              <ng-container *ngFor="let preActions of selectedPreActionNames;">
                <div class="row">
                  <div class="col-lg-12 d-flex">
                    <input type="checkbox" name="showPreActionData" (change)="handleShowPreData($event, preActions.actionid)" [id]="'show-data-' + preActions.actionid" [checked]="showPreActionData.includes(preActions.actionid.toString())"> <label [for]="'show-data-' + preActions.actionid"> &nbsp;&nbsp;Show Pre data ({{preActions.actionname}}) </label>
                  </div>
                </div>

              </ng-container>
              <div class="row form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-warning" (click)="reset()"> {{("reset" | translate)}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"
                    *ngIf="userService.permissionMap.get('add_action_action_mapping_access')==true"> {{(btn |
                    translate)}} </button>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive pt-4">
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{("s.no" | translate)}}</th>
                          <th>{{("action_name" | translate)}}</th>
                          <th>{{("pre_action" | translate)}}</th>
                          <th>{{("post_action" | translate)}}</th>
                          <th class="action">{{("action" | translate)}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of actionactionMapping;index as i">
                          <td>{{i+1}}</td>
                          <td> {{item.actionname || "-"}}</td>
                          <td>{{item.preactionnames || "-"}}</td>
                          <td>{{item.postactionnames || "-"}}</td>
                          <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-primary" (click)="viewActionActionMapping(item)"
                                *ngIf="userService.permissionMap.get('view_action_action_mapping_access')==true">
                                {{("view" | translate)}} </button>
                              <button class="btn btn-success" (click)="editActionMapping(item)"
                                *ngIf="userService.permissionMap.get('edit_action_action_mapping_access')==true">
                                {{"edit" | translate}} </button>
                              <button class="btn btn-danger" (click)="confirmDeleteAction(item)"> {{("delete" |
                                translate)}} </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';

@Component({
  selector: 'app-employee-detail-modal',
  templateUrl: './employee-detail-modal.component.html',
  styleUrls: ['./employee-detail-modal.component.scss'],
})
export class EmployeeDetailModalComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  isdisable = false;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'Employee Details Table',[],3);
  employeeDetails: any[] = [];

  @Input() public refData;

  constructor(
    public table: TableService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    this.getEmployeeDetails(this.refData.countType);
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  getEmployeeDetails(countType: any) {
    let params = {
      countType: countType,
    };
    this.common.loading = true;
    this.api.get('dashboard/get-employee-details-by-plantId', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.employeeDetails = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  closeModal(res) {
    this.activeModal.close(res);
  }
}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss'],
})
export class AddVehicleComponent implements OnInit {
  stageData: any = [];
  showFields: any = true;
  stageId: number = null;
  rfIdEnabled: boolean;
  processId: number = null;
  processStageId: number = null;
  vehicle_no: any = null;
  isRegistered: any;
  isCommercial: any;
  getVehicleTag = [];
  vehicleTagName: any = null;
  tagType:string = null;
  availableVehicleList: any = [];
  currentVehicleId: number;
  currentStageId: number;
  currentProcessId: number;
  title: string = 'add_vehicle';
  button: string = 'Add';
  readOnly: boolean = false;
  label: string;

  constructor(
    public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal
  ) {
    this.stageData = this.common.params.stage_data;
    this.rfIdEnabled = this.common.params.rfIdEnabled;
    if (this.common.params.currentVehicleId) {
      this.title = 'assign_tag';
      this.button = 'Assign';
      this.currentVehicleId = this.common.params.currentVehicleId;
      this.vehicle_no = this.common.params.currentVehicleNumber;
      this.processId = this.common.params.currentProcessId;
      this.stageId = this.common.params.currentStageId;
      let temp = this.stageData.filter(
        (item) =>
          item.stageId === this.stageId && item.processId === this.processId
      );
      this.label = temp[0].label;
      this.readOnly = true;
    } else {
      this.getVehicleList();
    }
  }

  ngOnInit(): void {}

  selectedField(event) {
    this.vehicleTagName = event.tag_id;
    this.tagType = event.tag_type;
  }
  getVehicleMapping(processStageId:number) {
    this.common.loading = true;
    let params = {
      processStageId:processStageId
    }
    this.api.get('transaction/get-vehicle-tag-mapping',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.getVehicleTag = res.data;
        if (this.getVehicleTag.length == 1) {
          this.vehicleTagName = this.getVehicleTag[0].tag_id;
          this.tagType = this.getVehicleTag[0].tag_type;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  selectStage(event: any) {
    this.stageId = event.stageId;
    this.processId = event.processId;
    this.processStageId = event.processStageId;
    if(this.rfIdEnabled){
      this.getVehicleMapping(this.processStageId);
    }
  }

  Add() {
    if (this.button == 'Add') {
      if(this.vehicle_no == null) return this.alertService.warn("Please Select a Vehicle !!");
      if(this.processStageId == null) return this.alertService.warn("Please Select a Stage !!");
      if(this.rfIdEnabled && this.vehicleTagName == null) return this.alertService.warn("Please Select a Tag !!");
      this.AddVehicle();
    } else {
      if(this.vehicleTagName == null) return this.alertService.warn("Please Select a Tag !!");
      this.AddRFIDTagMapping();
    }
  }
  AddVehicle() {
    this.common.loading = true;
    let params = {
      VehicleNo : this.vehicle_no,
      processId : this.processId,
      stageId : this.stageId,
      rfId : this.vehicleTagName,
      tagType : this.tagType
    }
    this.api
      .get(
        'transaction/get-vehicle-verification',params
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status == false) {
            this.alertService.warn(res.message);
          }
          if (
            res.data[0].is_verified == true &&
            res.data[0].is_commercial_vehicle == true
          ) {
            this.closeModal(
              false,
              -1,
              res.data[0].destination_allocated,
              res.data[0].message
            );
          } else {
            this.showFields = false;
            this.isRegistered = res.data[0].is_verified;
            this.isCommercial = res.data[0].is_commercial_vehicle;
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  AddRFIDTagMapping() {
    this.common.loading = true;
    let params = {
      rfidTagId: this.vehicleTagName,
      vehicleId: this.currentVehicleId,
      tagType: this.tagType
    };
    this.api.get('transaction/get-rfid-vehicle-tag-mapping', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status == false) {
          this.alertService.warn(res.message);
        }
        if (
          res.data[0].is_verified == true &&
          res.data[0].is_commercial_vehicle == true
        ) {
          this.closeModal(
            false,
            -1,
            res.data[0].destination_allocated,
            res.data[0].message
          );
        } else {
          this.showFields = false;
          this.isRegistered = res.data[0].is_verified;
          this.isCommercial = res.data[0].is_commercial_vehicle;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getVehicleList() {
    this.common.loading = true;
    this.api.get('transaction/get-available-vehicles').subscribe(
      (res: any) => {
        this.availableVehicleList = res.data;
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectVehicle(event: any, type: string) {
    if (type == 'select') {
      this.vehicle_no = event.vehicle_rsn;
    } else {
      this.vehicle_no = event;
    }
  }

  closeModal(
    response?: any,
    apiHit?: any,
    destinationAlloted?: boolean,
    message?: any,
    vehicleNo?: string
  ) {
    this.activeModal.close({
      response: response,
      apiHit: apiHit,
      destinationAlloted: destinationAlloted,
      message: message,
      vehicleNo: this.vehicle_no,
    });
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Driver Details'|customTranslate}}
                </h3>
              </div>
              <div style="display: flex;">
                <ps-auto-suggestion class="search" (onSelected)="selectDriver($event)" [data]="driverList"
                  display="driverName" placeholder="Select Driver" [preSelected]="{driverName:entityName}">
                </ps-auto-suggestion>
                <button type="button" *ngIf="isBanned!=null" class="btn btn-success ml-2" style="font-size: 14px;"
                  [ngStyle]="{'background-color':isBanned?'green':'#e71212'}" (click)="confirmAlert()">
                  {{(isBanned?'Unban Driver':'Ban Driver')|customTranslate}}
                </button>
              </div>
            </div>

            <div class="card-body">
              <div class="details">
                <div class="row m-0">
                  <div class="col-md-10 pl-0">
                    <div class="row m-0">

                      <div class="col-md-4 px-1">
                        <div class="label_value">
                          <div class="label">{{'Driver Name'|customTranslate}}</div>
                          <div class="value">{{entityName}}</div>
                        </div>
                      </div>

                      <div class="col-md-4 px-1">
                        <div class="label_value">
                          <div class="label">{{'Driver Phone No.'|customTranslate}}</div>
                          <div class="value">{{entityPhoneNo}}</div>
                        </div>
                      </div>

                      <div class="col-md-4 px-1">
                        <div class="label_value">
                          <div class="label">{{'Vehicle Driven'|customTranslate}}</div>
                          <div class="value">{{lastTxnVehicle}}</div>
                        </div>
                      </div>

                      <div class="col-md-4 px-1">
                        <div class="label_value">
                          <div class="label">{{'Last Transporter'|customTranslate}}</div>
                          <div class="value">{{lastTxnTransporter}}</div>
                        </div>
                      </div>

                      <ng-container *ngFor="let doc of documentData">
                        <div class="col-md-4 px-1">
                          <div class="label_value">
                            <div class="label">{{doc.type_name|titlecase}} {{'Expiry'|customTranslate}}</div>
                            <div class="value">{{doc.expiry_date}}</div>
                          </div>
                      </div>
                    </ng-container>

                      <div class="col-md-4 px-1">
                        <div class="label_value">
                          <div class="label">{{'Challans count'|customTranslate}}</div>
                          <div class="value">0</div>
                        </div>
                      </div>

                      <div class="col-md-4 px-1">
                        <div class="label_value">
                          <div class="label">{{'Criminal Cases'|customTranslate}}</div>
                          <div class="value">0</div>
                        </div>
                      </div>

                      <div class="col-md-4 px-1">
                        <div class="label_value">
                          <div class="label">{{'Current Location'|customTranslate}}</div>
                          <div class="value">{{lastTxnLocation}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2"
                    style="padding-top: 6px; padding-bottom: 4px; padding-left: 0; padding-right: 5px;">
                    <div class="driver-image">
                      <ng-container *ngIf="driverImage; else defaultIcon">
                        <img [src]="driverImage ?? ''">
                      </ng-container>
                      <ng-template #defaultIcon>
                        <img src="assets/images/empty_image.jpg">
                      </ng-template>
                    </div>
                  </div>

                </div>
                <div class="user_dash_row row spacing">
                  <!-- Status wise count section -->
                  <div class="col-md-12 mb-0 padding_class">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-block">
                          <h3 class="card-title">
                            {{'Status Wise Trips' | customTranslate}}
                          </h3>
                        </div>
                      </div>


                      <div class="card-body">
                        <div class="stage_vehicle_row">

                          <div class="stage_vehicle_box" *ngFor="let item of dashboardStageCard"
                            (click)="getStatusWiseTripData(item.name)" [style.background-color]="item.bgColor">
                            <div class="top_part">
                              <h4>{{tripCount?.[item.name]}}</h4>
                              <i class="fa fa-truck" [style.color]="item.color"></i>
                            </div>

                            <div class="bottom_part">
                              <p [title]="item.name">{{item.name|customTranslate}}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="user_dash_row spacing">
                  <!-- Alerts overview section -->
                  <div class="card">
                    <div class="card-header">
                      <div class="header-block">
                        <h2 class="card-title">{{'Compliance'|customTranslate}} / {{"alerts_overview" | customTranslate}}</h2>
                      </div>
                    </div>

                    <div class="card-body">
                      <div class="row m-0">

                        <div class="col-md-3 m-0 pl-0 pr-0">
                          <div class="tab_sec" style="height:100%">
                            <ng-container *ngIf="alertData && alertData.length>0;else notFound">
                              <div class="tab_card" *ngFor="let alertType of alertTypeWiseCount | keyvalue; index as i"
                                [class.active]="activeAlert == alertType.key" (click)="selectAlert(alertType.key)">

                                <div class="gif_icon" [ngStyle]="{ 'background-color':alertType.value['color'] }">
                                  <img style="height: 20px; width: 20px;" src="assets/images/alert-gif.gif" />
                                </div>

                                <div class="alert-type">
                                  <p><strong>{{ alertType.key }}</strong></p>
                                </div>

                                <div class="alert-type-count"
                                  [ngStyle]="{ 'color': 'darken('+alertType.value['color']+',10)' }">
                                  {{ alertType.value['count'] }}
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>

                        <div class="col-md-6 m-0 pl-1 pr-1">
                          <div class="table_section">
                            <div class="table-responsive" style="height: 250px;">
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>{{'Alert Name'|customTranslate}}</th>
                                    <th>{{'Alert On'|customTranslate}}</th>
                                    <th>{{'Entity Type'|customTranslate}}</th>
                                    <th>{{'Action'|customTranslate}}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let alert of filteredAlertData; let i = index">
                                    <td>{{alert.title}}</td>
                                    <td class="btn red_clr">{{alert.alert_condition}}</td>
                                    <td>{{alert.map_on}}</td>
                                    <td><i class="fa fa-info-circle"
                                        style="font-size:16px !important; border:none;color:#0f50a3 "
                                        (click)="viewAlert(alert.alert_data)">
                                      </i></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3 m-0 pl-0 pr-0">
                          <!-- compliance Error Section -->
                          <div class="compliance_error box_out">
                            <div class="title_row">
                              <p>{{'Compliance Error'|customTranslate}}</p>
                              <i class="fa fa-sync" matTooltip="Refresh List" matTooltipPosition="below"
                                (click)="getComplianceData()"></i>
                            </div>
                            <div class="error_div" *ngIf="complianceData != null">
                              <ng-container *ngFor="let compliance of complianceData">
                                <div class="error_row">
                                  <div class="error_left">{{compliance.title}}
                                    <span class="pill"
                                      [ngStyle]="{'background-color':compliance.type_color,'color':'white'}">{{compliance.type_name}}</span>
                                  </div>
                                  <i class="fa fa-info-circle" (click)="viewCompliance(compliance)"></i>

                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="user_dash_row row spacing">
                  <!-- Date wise tat/count section -->
                  <div class="col-md-12 mb-0 padding_class">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-block">
                          <h3 class="card-title">
                            {{graphTitle | customTranslate}}
                          </h3>
                        </div>
                        <div class="tab_btnss">
                          <div class="tab_btn" [class.active]="tabvalue == 'tat'"
                            (click)="tabvalue = 'tat';changeTab() ">{{'tat'|customTranslate}}</div>
                          <div class="tab_btn" [class.active]="tabvalue == 'count'"
                            (click)="tabvalue = 'count';changeTab()">{{'Count'|customTranslate}}</div>
                        </div>
                      </div>


                      <div class="card-body">
                        <div class="vehicle-compliance">
                          <div id="myChart"></div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="user_dash_row row spacing" >
                  <!-- Order Data section ** inclusive of inprogress trip, counts do not include inprogress **-->
                  <div class="col-md-12 mb-0 pr-1 padding_class" style="display: flex;">
                    <div class="card" style="width:75%">
                      <div class="card-header">
                        <div class="header-block">
                          <h2 class="card-title">{{"Order Data" | customTranslate}}</h2>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="datatable_section">
                          <div class="datatable-responsive">
                            <app-custom-table [data]="orderData" [columns]="orderDataColumns"  [options]="tOptions"
                              [tableType]="TABLE_TYPE"></app-custom-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  <!-- </div> -->

                  <!-- Documents List section -->
                  <!-- <div class="col-md-3 mb-0 pl-0" style="padding-right: 0px;"> -->
                    <div class="card" style="width:25%">
                      <div class="card-header">
                        <div class="header-block">
                          <h2 class="card-title">{{"Documents" | customTranslate}}</h2>
                        </div>
                      </div>
                      <div class="card-body dock_overview_sec">

                        <div class="dock_over_left">
                          <ng-container *ngIf="documentData.length>0;else notFound">
                            <div class="dock_over_card" *ngFor="let doc of documentData">
                              <div class="doc_single"><span>{{doc.type_name|titlecase}}</span><button
                                  class="btn btn-secondary" (click)="viewDoc(doc)"><i class="fa fa-eye">
                                    {{'view'|customTranslate}}</i></button></div>
                            </div>
                          </ng-container>
                        </div>

                        <ng-template #notFound>
                          <div class="message">
                            <img src="assets/images/icons8-nothing-found-80.png">
                            <span style="margin-bottom: 10px;">{{'No data exists!'|customTranslate}}</span>
                          </div>
                        </ng-template>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{"Documents"}}
      </h3>
    </div>
    <button type="button" class="btn btn-success" data-toggle="modal" data-target="#exampleModal">+ Add New</button>
  </div>

  <div class="card-body">
    <table class="custom-table">
      <thead class="thead-dark">
        <tr>
          <th>{{ "s.no." | customTranslate }}</th>
          <th>{{ "document type" | customTranslate }}</th>
          <th>{{ "document url" | customTranslate }}</th>
          <th>{{ "upload by" | customTranslate }}</th>
          <th>{{ "upload date" | customTranslate }}</th>
          <th>{{ "action" | customTranslate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of assetDocumentList; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ row.document_type}}</td>
          <td>{{ row.document[0]}}</td>
          <td>{{ row.user_name}}</td>
          <td>{{ row.add_time}}</td>
          <td class="action">
            <div class="btn-block">
              <button class="btn btn-success">Download</button>
              <button class="btn btn-danger" (click)="confirmDeleteMapping(row)">Detach</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Document</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="form-group col-12 mb-0">
            <label for="my-input" class="required">{{"document_type" | customTranslate}} </label>
            <ps-auto-suggestion (onSelected)="selectedDocument($event)" [data]="documentTypeList" display="type_name"
              placeholder="Choose asset" [preSelected]="{type_name:documentName}">
            </ps-auto-suggestion>
          </div>
          <div class="form-group col-12 mb-0">
            <label for="my-input" class="required">{{"Upload Document" | customTranslate}} </label>
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="validatedInputGroupCustomFile"
                (change)="uploadFile($event)" required>
              <label class="custom-file-label" for="validatedInputGroupCustomFile">Choose file...</label>
            </div>
          </div>
          <div class="form-group col-12 mb-0">
            <label>{{'Expiry Date'}}</label>
            <input type="date" class="form-control" [(ngModel)]="expiryDate">
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="saveDocument()">Save changes</button>
      </div>
    </div>
  </div>
</div>

import { filter } from 'rxjs/operators';
import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { DriverTrackerComponent } from 'src/app/components/driver-tracker/driver-tracker.component';
import { VehicleDetailComponent } from 'src/app/components/vehicle-detail/vehicle-detail.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { XSightsCoreService, WidgetType } from 'x-sights-core';
import { COLUMN_ORDER, COMMON_TRIPS_ACTION_BUTTON, TABLE_TYPE, TableActions, TableColumns, TableOptions } from 'src/app/dataType/customTableInterface';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import * as filesaver from 'file-saver';
import _ from 'lodash';

@Component({
  selector: 'app-trip-report-modal',
  templateUrl: './trip-report-modal.component.html',
  styleUrls: ['./trip-report-modal.component.scss'],
})
export class TripReportModalComponent implements OnInit {
  // Datatable Configs
  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  loading: boolean = false;
  tableColumns: TableColumns[] = [];
  data: any = [];
  fromDate: any = null;
  toDate: any = null;

  statusColors = {
    "COMPLETED": "bg-green-200 text-green-800",
    "INPROGRESS": "bg-yellow-200 text-yellow-800",
    "CANCELLED": "bg-pink-200 text-pink-800",
    "AUTO CANCELLED": "bg-orange-200 text-orange-800"
  }

  tabvalue: any = 'table';
  graphtype: any;

  listOfProcess: any = [];
  processName: string = 'ALL';
  processId: any = 0;
  vehicle_rc_no: string = 'ALL';
  tripReports: any = [];
  tripStatus: string = 'ALL';
  vehicleId: number = null;
  allGraphIds: any = [464, 465, 466];
  selectedDate: any = '';
  plantId: number = null;
  @Input() public refData;
  tableOptions: TableOptions = {
    defaultLength: 10,
    exportColumns: 10,
    tableTitle: "Table",
    orderColumnIndx: 0,
    order: COLUMN_ORDER.ASC
  };

  constructor(
    public common: CommonService,
    public api: ApiService,
    public alertService: AlertService,
    public userService: UserService,
    private xsights: XSightsCoreService,
    private tableService: TableService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.fromDate = this.refData.fromDate;
    this.toDate = this.refData.toDate;
    this.plantId = this.refData.plantId;
    this.getAllProcess();
    this.getTripReport();
  }


  ngOnChanges(simple: SimpleChanges) {
    if (simple["refData"].currentValue != simple["refData"].previousValue) {
      this.fromDate = this.refData.fromDate;
      this.toDate = this.refData.toDate;
      this.plantId = this.refData.plantId;
      this.tableOptions = {
        defaultLength: 15,
        exportColumns: 10,
        tableTitle: "Table",
        orderColumnIndx: 0,
        order: COLUMN_ORDER.ASC
      };
      this.getTripReport()
    }
  }

  drawGraphs() {
    this.allGraphIds.forEach((graph) => {
      this.common.loading = true;
      this.api
        .get(
          'graphs/get-graph-by-id',
          {
            graphId: graph,
          },
          'XSIGHT'
        )
        .toPromise()
        .then((res: any) => {
          const structureJ = JSON.parse(res.data[0].structure);
          let data = {
            graphId: 'secgraph-' + res.data[0].id,
            graphTitle: res.data[0].name,
            rows: structureJ.xAxis,
            columns: structureJ.yAxis,
            graphTypes: structureJ.chartType,
            graphData: this.tripReports,
            aggregationFunctions: structureJ.aggreagations,
            filter: structureJ.filter,
            colors: structureJ.colColours,
            customVariable: structureJ.derivedVariables,
            dataFormat: [],
            lastLevelColumns: structureJ.lastLevelData,
          };
          this.xsights.build(WidgetType.GRAPH, data);
          this.common.loading = false;
        });
    });
  }

  reset() {
    this.processId = null;
    this.vehicleId = null;
    this.processName = 'ALL';
    this.vehicle_rc_no = 'ALL';
    this.tripStatus = 'ALL';
    this.getTripReport();
  }

  getAllProcess() {
    this.common.loading = true;
    this.api.get('process/get-all-process?processActive=true').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.listOfProcess = res.data;
        this.listOfProcess.unshift({ processId: 0, processName: 'ALL' });
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedProcess(event: any) {
    this.processId = event.processId;
    this.processName = event.processName;
    this.filterDataByCondition();
  }

  selectedVehicle(event: any) {
    this.vehicleId = event.vehicle_id;
    this.vehicle_rc_no = event.vehicle_rc_no;
    this.filterDataByCondition();
  }

  getTripReport() {

    if (this.fromDate > this.toDate) {
      this.alertService.warn('Start Date Should be Smaller than End Date!!');
      return;
    }
    this.common.loading = true;
    let params;
    params = {
      fromDate: this.common.dateFormatter(this.fromDate),
      toDate: this.common.dateFormatter(this.toDate),
      plantId: this.plantId,
    };
    this.api.post('report/get-trip-report', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.data = res.data;
        this.tripReports = res.data;
        console.log('this.columns: ', this.tableColumns);

        if (this.tabvalue != 'table') {
          this.drawGraphs();
          console.log('this.columns:sss ', this.tableColumns);

        } else {
          this.tableColumns = [
            {
              columnLabel: this.translateService.instant("vehicle_number"),
              columnName: "vehicle_rc_no"
            },
            {
              columnLabel: this.translateService.instant("transaction_id"),
              columnName: "transaction_name"
            },
            {
              columnLabel: this.translateService.instant("process"),
              columnName: "process_name"
            },
            {
              columnLabel: ``,
              columnName: "tat",
              columnFormatter: (column, index) => {
                return `Trip <small style="font-size: 10px;">(Actual / Expected) TAT</small>`;
              },
              dataFormatter: (row: any, column: TableColumns, index: number) => {
                return `<span class="${row.trip_tat > row.expected_tat ? 'badge over' : 'badge'}">${row.trip_tat}</span>
                             / <span class="badge info">${row.expected_tat}</span>`
              }
            },
            {
              columnLabel: this.translateService.instant("status"),
              columnName: "vehicle_rc_no",
              dataFormatter: (row: any, column: TableColumns, index: number) => {
                const pillClass = this.statusColors[row.stage_workflow_status]
                return `<span class="pill ${pillClass}">${row.stage_workflow_status}</span>`
              }
            },
            {
              columnLabel: this.translateService.instant("Start Time"),
              columnName: "trip_start_time"
            },
            {
              columnLabel: this.translateService.instant("End Time"),
              columnName: "trip_end_time"
            },
            {
              columnLabel: this.translateService.instant("Action"),
              columnName: null,
              dataActions: [
                ...this.tableService.getTripsButtons([
                  COMMON_TRIPS_ACTION_BUTTON.TRIP_DETAIL,
                  COMMON_TRIPS_ACTION_BUTTON.TRIP_TRACKING,
                  COMMON_TRIPS_ACTION_BUTTON.TRIP_REMARK
                ])
              ] as TableActions[]
            }

          ] as TableColumns[]
        }

      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;

      }
    );
  }

  filterDataByCondition() {

    this.data = this.tripReports.filter(
      row => (this.processName == 'ALL' || this.processName == row.process_name) && (this.vehicle_rc_no == 'ALL' || this.vehicle_rc_no == row.vehicle_rc_no) && (this.tripStatus == 'ALL' || this.tripStatus == row.stage_workflow_status)
    );
    setTimeout(() => {

    }, 100)
  }


  exportAsExcel(
    data: any[],
    fileName: string,
    allTrips: boolean = false
  ): void {
    let worksheet = null;
    let value = _.cloneDeep(data);

    let actionFailRemarks = JSON.parse(data[0].actionFailComments || '{}');
    let tripRejectComments = JSON.parse(data[0].tripRejectComments || '{}');
    // Create worksheet
    const filteredKeys = Object.keys(data[0]).filter(
      (key) => key !== 'actionFailComments' && key !== 'tripRejectComments'
    );
    const actionFailHeaders = Object.keys(actionFailRemarks).map(key => `${key} action failure remark`);
    const tripRejectHeaders = Object.keys(tripRejectComments).map(key => `${key} stage rejection remark`);
    worksheet = XLSX.utils.aoa_to_sheet([
      [
        ...filteredKeys,
        ...actionFailHeaders,
        ...tripRejectHeaders,
      ],
    ]);
    console.log('worksheet: ', worksheet);

    // Fill data
    data.forEach((rowData) => {
      const rowValues = [
        ...filteredKeys.map(key => rowData[key]),
        ...actionFailHeaders.map(header => {
          const key = header.replace(' Action Failure Remark', '');
          return JSON.parse(rowData.actionFailComments || '{}')[key] || '';
        }),
        ...tripRejectHeaders.map(header => {
          const key = header.replace(' Stage Rejection Remark', '');
          return JSON.parse(rowData.tripRejectComments || '{}')[key] || '';
        }),
      ];
      XLSX.utils.sheet_add_aoa(worksheet, [rowValues], { origin: -1 });
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Trip Report');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    filesaver.saveAs(blob, fileName + '.xlsx');
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_company_access')==true ||userService.permissionMap.get('edit_company_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"add_company" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="companyName" class="required">{{"company_name" | customTranslate}}</label>
                  <input class="form-control" type="text" name="companyName" id="companyName" required
                    placeholder="Enter Company Name" [(ngModel)]="companys.companyName" #name="ngModel"
                    autocomplete="off">
                  <div class="error" *ngIf="companyNameValidator && companyNameValidator.error">
                    <span>{{companyNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"company_sector" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedSector($event)" [data]="companySector" display="sectorName"
                    placeholder="Choose Sector" [preSelected]="{sectorName:sectorName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="sectorValidator && sectorValidator.error">
                    <span>{{sectorValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"company_status" | customTranslate}}</label>
                  <select name="companyStatus" id="#companyStatus" class="form-control"
                    [(ngModel)]="companys.companyStatus">
                    <option selected="ACTIVE" value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                    <!-- <option value="DELETED">Deleted</option> -->
                  </select>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-muted" (click)="common.backClicked()">{{"back" | customTranslate}}</button>
                  <button class="btn btn-warning" (click)="resetDetail()">{{"reset" | customTranslate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}}</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="header-block">
              <h3 class="card-title">
                {{"company_list" | customTranslate}}
              </h3>
            </div>
          </div>

          <div class="card-body">
            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead class="thead-light">
                <tr>
                  <th>{{"serial_no" | customTranslate}}</th>
                  <th>{{"company_name" | customTranslate}}</th>
                  <th>{{"sector" | customTranslate}}</th>
                  <th>{{"status" | customTranslate}}</th>
                  <th class="action">{{"action" | customTranslate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor=" let item of responseData; let i = index">
                  <td>{{i+1}}</td>
                  <td>{{item.companyName}}</td>
                  <td>{{item.sectorName ? item.sectorName : '-'}}</td>
                  <td>{{item.companyStatus}}</td>
                  <td class="action">
                    <div class="btn-block">
                      <button class="btn btn-primary" (click)="setActiveCompany(item)">{{"view" | customTranslate}}</button>
                      <button class="btn btn-success" (click)="editCompany(item)"
                        *ngIf="userService.permissionMap.get('edit_company_access')==true && item.companyId != -1">{{"edit"
                        | customTranslate}}</button>
                      <button class="btn btn-danger" (click)="openConfirmAlert(item)"
                        *ngIf="userService.permissionMap.get('delete_company_access')==true && item.companyId != -1">{{"delete"
                        | customTranslate}}</button>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

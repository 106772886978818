import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ChangeDetectorRef,
  ElementRef,
  HostListener,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

interface classMappings {
  classname: string;
  mappings: number[];
}

@Component({
  selector: 'ps-auto-suggestion',
  templateUrl: './auto-suggestion.component.html',
  styleUrls: ['./auto-suggestion.component.scss'],
})
export class AutoSuggestionComponent implements OnInit {
  @Output() onSelected = new EventEmitter();
  @Output() unSelected = new EventEmitter();
  @Output() noDataFound = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Output() notFound = new EventEmitter();

  @Input() url: string;
  @Input() display: any;
  @Input() isNewLabelAdd: boolean = false;
  @Input() className: string;
  @Input() tagInputClass: string;
  @Input() placeholder: string;
  @Input() preSelected: any;
  @Input() seperator: string;
  @Input() data: any;
  @Input() inputId: string;
  @Input() apiBase: string = 'I';
  @Input() name: string;
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() apiHitLimit: number;
  @Input() isNoDataFoundEmit: boolean;
  @Input() isMultiSelect: any;
  @Input() bGConditions: any[] = [];
  @Input() apiMethod: string = 'get';
  @Input() readonly: boolean = false;
  @Input() classMappings: number[] = [];
  @Input() onInputTrigger: boolean= false;
  counter = 0;
  searchText = '';
  showSuggestions = false;
  suggestions = [];
  selectedSuggestion = null;
  displayType = 'string';
  searchForm = null;
  activeSuggestion = -1;
  selectedSuggestions = [];
  isAllData = false;
  suggestionApiHitTimer: any = null;
  scrollIntoView: any;
  isSelect: boolean = false;

  @HostListener('document:click', ['$event.target'])
  onClick(target: any): void {
    const clickedInside = this.el.nativeElement.contains(target);
    if (!clickedInside && this.showSuggestions) {
      setTimeout(() => {
        this.showSuggestions = false;
      }, 20);
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    let activeElement: HTMLElement = this.el.nativeElement;
    let activeinput = activeElement.querySelector('.search-input');

    if (
      (event.ctrlKey || event.metaKey) &&
      activeinput == document.activeElement
    ) {
      this.activeSuggestion = 0;
      if (event.key === ' ' || event.key === 'Spacebar') {
        this.showSuggestions = !this.showSuggestions;
      }
    }
  }

  constructor(
    public api: ApiService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    public common: CommonService,
    public el: ElementRef,
    public alert: AlertService
  ) {
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      search: [''],
    });
  }

  shallowEqual(objA, objB) {
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);
    return (
      keysA.length === keysB.length &&
      keysA.every((key) => objA[key] === objB[key])
    );
  }

  getSelectedIndexes(mainData, selected): number[] {
    let selectedIndexs = [];
    mainData.forEach((item, index) => {
      selected.forEach((obj) => {
        if (this.shallowEqual(item, obj)) {
          selectedIndexs.push(index);
        }
      });
    });
    return selectedIndexs;
  }

  getUnselectedSuggestions(): any[] {
    const entriesNotInB = this.data.filter((itemA) => {
      return !this.selectedSuggestions.some((itemB) => {
        return Object.keys(itemA).every((key) => itemA[key] === itemB[key]);
      });
    });
    return entriesNotInB;
  }

  ngOnChanges(changes) {
    if (changes.preSelected) {
      this.preSelected = changes.preSelected.currentValue;
      this.preSelected && this.handlePreSelection();
      if (this.isMultiSelect) {
        setTimeout(() => {
          this.suggestions = this.getUnselectedSuggestions();
        }, 500);
        this.selectedSuggestions = this.preSelected || [];
      }
    } else {
      this.searchText = '';
      this.selectedSuggestions = [];
      this.preSelected && this.handlePreSelection();
    }
  }

  ngAfterViewInit() {
    if (this.preSelected) this.handlePreSelection();
    if (Array.isArray(this.display)) {
      this.displayType = 'array';
    }
    if (this.parentForm) {
      this.searchForm = this.parentForm;
    }
    this.cdr.detectChanges();
  }

  handlePreSelection() {
    this.selectedSuggestion = this.preSelected;
    if (typeof this.preSelected == 'object') {
      let keys = Object.keys(this.preSelected);
      this.isSelect = [null, 'null', ''].includes(this.preSelected[keys[0]])
        ? true
        : false;
    }
    this.searchText = '';
    if (typeof this.display != 'object')
      this.searchText = this.preSelected[this.display];
    else {
      let index = 0;
      for (const dis of this.display) {
        this.searchText +=
          (index != 0 ? ' ' + this.seperator + ' ' : ' ') +
          this.preSelected[dis];
        if (!this.searchText.replace(/[-]/g, '').trim()) {
          this.searchText = '';
        }
        index++;
      }
    }
  }

  getSuggestions() {
    let displayNames = this.data.map((item) =>
      item[this.display].toLowerCase()
    );
    if (!displayNames.includes(this.searchText)) {
      this.notFound.emit(this.searchText.toLowerCase());
    } else {
      this.notFound.emit(null);
    }

    this.onChange.emit(this.searchText);
    this.apiHitLimit = this.apiHitLimit ? this.apiHitLimit : 3;
    this.showSuggestions = true;
    if (this.data) {
      this.suggestions = this.getUnselectedSuggestions().filter(
        (suggestion) => {
          if (this.displayType === 'string') {
            return (suggestion[this.display] || '')
              .toLowerCase()
              .trim()
              .includes(this.searchText.toLowerCase());
          } else {
            return this.generateString(suggestion)
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          }
        }
      );
      return;
    }
    if (this.searchText.length < this.apiHitLimit) return;
    clearTimeout(this.suggestionApiHitTimer);
    this.suggestionApiHitTimer = setTimeout(
      this.getSuggestionsFromApi.bind(this, this.isNewLabelAdd),
      400
    );
  }

  getSuggestionsFromApi(isNewLabelAdd) {
    let params = '?';
    if (this.url.includes('?')) {
      params = '&';
    }
    params += 'search=' + this.searchText;
    this.api[this.apiMethod](this.url + params, this.apiBase).subscribe(
      (res) => {
        if (res['code'] === 0) {
          this.alert.error(res['msg']);
          return false;
        }
        this.suggestions = res['data'] || [];
        if (isNewLabelAdd) {
          this.suggestions.push({
            [this.display]: this.searchText + ' (Add New Value) ',
          });
        }
        if (this.isNoDataFoundEmit && !this.suggestions.length)
          this.noDataFound.emit({
            search: this.searchText,
          });
      },
      (err) => {
        console.error(err);
        this.alert.error();
      }
    );
  }

  selectSuggestion(suggestion, index = -1) {
    if (this.isMultiSelect) {
      this.selectedSuggestions.push(suggestion);
      let currentIndex = this.suggestions.findIndex((obj) => obj == suggestion);
      this.suggestions.splice(currentIndex, 1);
      this.activeSuggestion = 0;
      this.onSelected.emit(this.selectedSuggestions);
      this.searchText = '';
    } else {
      this.selectedSuggestion = suggestion;
      this.onSelected.emit(suggestion);
      this.searchText = this.generateString(suggestion);
    }
    this.showSuggestions = false;
  }

  generateString(suggestion) {
    let displayText = '';
    if (this.displayType == 'array') {
      this.display.map((display, index) => {
        if (index != this.display.length - 1) {
          displayText += suggestion[display] + ' ' + this.seperator + ' ';
        } else {
          displayText += suggestion[display];
        }
      });
    } else {
      displayText = suggestion[this.display];
    }
    return displayText;
  }

  handleKeyDown(event) {
    const key = event.key.toLowerCase();

    // if (!this.showSuggestions) return;
    if (this.suggestions.length && this.showSuggestions) {
      if (key == 'arrowdown') {
        if (this.activeSuggestion != this.suggestions.length - 1)
          this.activeSuggestion++;
        else this.activeSuggestion = 0;
        event.preventDefault();
        this.scrolintoView();
      } else if (key == 'arrowup') {
        if (this.activeSuggestion != 0) this.activeSuggestion--;
        else this.activeSuggestion = this.suggestions.length - 1;
        event.preventDefault();
        this.scrolintoView();
      } else if (key == 'enter' || key == 'tab') {
        if (this.activeSuggestion !== -1) {
          this.selectSuggestion(this.suggestions[this.activeSuggestion]);
        } else {
          this.selectSuggestion(this.suggestions[0]);
        }
      }
    }
  }

  scrolintoView() {
    let options: Object = {
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    };
    let suggestion = document.querySelectorAll('.suggestions .suggestion');
    if (this.suggestions.length) {
      if (this.activeSuggestion) {
        suggestion[this.activeSuggestion].scrollIntoView(options);
      } else {
        suggestion[this.activeSuggestion].scrollIntoView(false);
      }
    }
  }

  handleUnselected() {
    setTimeout(() => {
      let isSelected = false;
      this.suggestions.map((suggestion) => {
        if (
          this.searchText === this.generateString(suggestion) &&
          this.generateString(this.selectedSuggestion) ==
            this.generateString(suggestion)
        ) {
          isSelected = true;
        }
      });
      if (!isSelected) this.unSelected.emit(null);
    }, 100);
  }

  classFinder(suggestion) {
    let className = '';
    this.bGConditions.forEach((condition) => {
      if (condition.isExist && suggestion[condition.key]) {
        className = condition.class;
      } else if (
        !condition.isExist &&
        suggestion[condition.key] == condition.value
      ) {
        className = condition.class;
      }
    });
    return className;
  }

  showAllSuggestion(event) {
    this.activeSuggestion = -1;
    if (!this.readonly) {
      event.preventDefault();
      event.stopPropagation();
      this.showSuggestions = !this.showSuggestions;
      // this.showSuggestions = true;
      if (this.isMultiSelect) {
        this.suggestions = this.getUnselectedSuggestions();
      } else {
        this.suggestions = this.data;
      }
    }
  }

  clearData() {
    this.showSuggestions = false;
    this.suggestions = [];
  }

  removeSuggestion(index) {
    if (this.isMultiSelect) {
      this.selectedSuggestions.splice(index, 1);
      this.onSelected.emit(this.selectedSuggestions);
    }
  }

  removeSelectedSuggestion(suggestion, i) {
    if (this.isMultiSelect) {
      this.unSelected.emit(suggestion);
    }
    this.showSuggestions = false;
    this.selectedSuggestions.splice(i, 1);
    this.onSelected.emit(this.selectedSuggestions);
    this.suggestions.push(suggestion);
  }

  getPreSelected(obj: any) {
    return obj ? obj[Object.keys(obj)[0]] : null;
  }

  inputTrigger(event:any){
    if(this.onInputTrigger){
      if(event.target.value.trim() !== ''){
        this.selectedSuggestions.push({
          [this.display]: event.target.value,
        });
        this.onSelected.emit(this.selectedSuggestions);
        event.target.value = "";
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { StagesettingService } from '../stage-setting/stage-setting.service';
import { CommonService } from '../../@core/services/common.service';
import { ApiService } from '../../@core/services/api.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { StageActionService } from '../stage-action/stage-action.service';
import { StageService } from '../stage/stage.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { UserService } from '../../@core/services/user.service';
import * as _ from 'lodash';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Checklist, checklistAction } from 'src/app/dataType/checklistInterface';

@Component({
  selector: 'app-checklist-actions-mapping',
  templateUrl: './checklist-actions-mapping.component.html',
  styleUrls: ['./checklist-actions-mapping.component.scss'],
})
export class ChecklistActionsMappingComponent implements OnInit {
  checkList: Checklist[] = [];
  selectedCheckListId: number = null;
  actionsList: checklistAction[] = [];
  tempActions: checklistAction[] = [];
  checkListActions: checklistAction[] = [];
  constructor(
    public Stage: StagesettingService,
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    private stageActionService: StageActionService,
    public stageService: StageService,
    public userService: UserService,
    public alert: AlertService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'CheckList', url: null },
      {
        name: 'CheckList Action Mapping',
        link: '/user/checklist-actions-mapping',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getCheckList();
  }

  getCheckList() {
    this.common.loading = true;
    let params = {
      checklistActive: true,
    };
    this.api.get('checklist/get-all-checklists', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.checkList = res.data;
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedCheckList(event: any) {
    this.selectedCheckListId = event.id;
    this.getActionsOfCheckList();
    this.getCheckListActions();
  }

  searchActionName(event: any = '') {
    if (event.target.value.trim() != '') {
      this.actionsList = this.tempActions.filter((action) =>
        action.actionName
          .toLowerCase()
          .includes(
            event.target.value.trim().toLowerCase() ||
              action.actionName
                .toLowerCase()
                .includes(event.target.value.trim().toLowerCase())
          )
      );
    } else {
      this.actionsList = this.tempActions;
    }
  }

  getActionsOfCheckList() {
    this.common.loading = true;
    let params = {
      checkListId: this.selectedCheckListId,
    };
    this.api.get('checklist/list-checklist-actions', params).subscribe(
      (res: any) => {
        if (res.status) {
        this.checkListActions = res.data;
        }
        this.common.loading = false;
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getCheckListActions() {
    this.common.loading = true;
    this.stageActionService.getAllStageAction().subscribe(
      (res: any) => {
        if (res.status) {
          this.actionsList = res.data.filter((action) => {
            return !this.checkListActions.some(
              (data) => data.actionId == action.actionId );
          });
          this.tempActions = _.cloneDeep(this.actionsList);
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  removeField(index: number) {
    let value = this.checkListActions[index];
    this.actionsList.push(value);
    this.checkListActions.splice(index, 1);
  }

  drop(event: CdkDragDrop<checklistAction[]>) {
    this.tempActions = this.tempActions.filter(
      (res) => this.actionsList[event.previousIndex] != res
    );
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    if (this.actionsList.length != 0) {
      this.tempActions = _.cloneDeep(this.actionsList);
    }
  }

  resetData() {
    this.checkListActions = [];
    if (this.selectedCheckListId != null) this.getCheckListActions();
  }

  save() {
    console.log('this.checkListActions: ', this.checkListActions);
    let params = {
      checkListId: this.selectedCheckListId,
      checkListActionDTO: this.checkListActions.map(function (obj, index) {
        let mandatory = obj?.is_mandatory ? obj.is_mandatory : false;
        return {
          actionId: obj.actionId,
          mandatory: mandatory,
          order: index + 1,
        };
      }),
    };
    this.common.loading = true;
    console.log("params",params);
    this.api.post('checklist/save-checklist-actions-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
        this.alert.info(res.message);
        }
        this.common.loading = false;
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }
}

import { RoleService } from './@core/services/role.service';
import { Component } from '@angular/core';
import { UserService } from './@core/services/user.service';
import { CommonService } from './@core/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from './@core/services/location.service';
import { StrictHttpsService } from './@core/services/strict-https.service';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { ApiService } from './@core/services/api.service';
import { TokenStorageService } from './@core/services/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'In-Plant-Automation';
  nextElementSibling: any;
  items = [1, 2, 3, 4, 5, 6, 1, 2, 4, 5, 5, 6];
  auth: any;
  constructor(
    private locationService: LocationService,
    public userService: UserService,
    public translateService: TranslateService,
    public role: RoleService,
    private common: CommonService,
    public router : Router,
    public api:ApiService,
    private tokenService: TokenStorageService
  ) {
    console.log("wnbhefnwej")
    //Move to https
    // this.strictHttps.moveToHttps();

    //Setting Default Language
    console.log('this.common.language: ', this.common.language);
    let language = localStorage.getItem("language");
    translateService.addLangs(["en", "hn"])
    translateService.setDefaultLang(language == null ? "en" : language)

    this.common.language.subscribe((lang: any) => {
      this.translateService.use(lang);
    });
    console.log(this.translateService.instant("plant_setting"),"hoeghoegh")

    if (userService._details && userService._token) {
      this.role.user_role = this.userService._userType;
      //Start Location watch
      this.locationService.watchUserLocation();
      // this.locationService.checkLocationPermission();
    }
    // else {
    //   this.router.navigate(['/auth/login']);
    // }
    this.userService.setPermission();

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart))
        .subscribe(async (event: NavigationStart) => {
          // code goes here...
          if(this.isLoggedin()){
            // await this.checkRouteAllowed(event.url).then(res => {
            //   if(!res){
            //     this.tokenService.signOut();
            //   }
            // });
          }
        }
    )

  }

  ngOnInit() {
    // this.router.navigate(['']);
  }

  ngAfterViewInit() {}

  isLoggedin() {
    console.log("Check login temp")
    console.log("wjdnwdjw");
    return (
      localStorage.getItem('USER_TOKEN') != null && localStorage.getItem('USER_TOKEN') != 'null'
    );
  }

  checkRouteAllowed(url:string) {
  return new Promise((resolve, reject) => {
    console.log("Testing Commit 2")
      let params = {
        routeUrl: url
      };
      this.common.loading = true;
      this.api.get('component/check-component-access', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          resolve(res.status);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          resolve(true);
          console.log("tem")
        }
      );
    });
  }
}

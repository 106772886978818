<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">Vehicle History</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [hidden]="!vehicleHistory.length">
    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th>Trip Id</th>
          <th>ProcessName</th>
          <th>Trip Start Time</th>
          <th>Trip End Time</th>
          <th>Trip Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of vehicleHistory, let i = index">
          <td>{{row.transaction_name}}</td>
          <td>{{row.process_name}}</td>
          <td>{{row.start_time| date:'short'}}</td>
          <td>{{row.end_time|date:'short'}}</td>
          <td>{{row.trip_status}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="action_data_div" *ngIf="this.vehicleHistory.length==0">
    <p style="color:red;display: flex;justify-content: center;">No Record Found !!</p>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Form Management'|translate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div *ngIf="role.user_role=='AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{ "select_company" | translate }}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <!-- <div class="error" *ngIf="companyNameValidator && companyNameValidator.error">
                      <span>{{ companyNameValidator.msg }} </span>
                    </div> -->
                </div>
                <div *ngIf="role.user_role=='AXESTRACK' || role.user_role=='COMPANYADMIN'"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{ "select_plant" | translate }}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{ plantName: plantName }">
                  </ps-auto-suggestion>
                  <!-- <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                      <span>{{ plantNameValidator.msg }} </span>
                    </div> -->
                </div>
              </div>
              <div class="row">
                <!-- [preSelected]="{processName:processName}" -->
                <div class="col-md-4 form-group">
                  <label for="my-input" class="required">{{'select_form_type'|translate}}</label>
                  <ps-auto-suggestion [data]="formTypes" (onSelected)="selectedFormType($event)" display="form_type_name"
                  [preSelected]="{form_type_name:formTypeName}" placeholder="Select Form Type">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="formTypeValidator && formTypeValidator.error">
                    <span>{{formTypeValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'form_title'|translate}}</label>
                  <input class="form-control" type="text" required placeholder="Enter Form Title"
                    [(ngModel)]="formTitle" #name="ngModel" autocomplete="off">
                  <div class="error" *ngIf="formTitleValidator && formTitleValidator.error">
                    <span>{{formTitleValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" >{{'form_description'|translate}}</label>
                  <input class="form-control" type="text"  placeholder="Enter Form Description"
                    [(ngModel)]="formDescription" #name="ngModel" autocomplete="off">
                  <div class="error" *ngIf="formDescriptionValidator && formDescriptionValidator.error">
                    <span>{{formDescriptionValidator.msg}} </span>
                  </div>
                </div>

                <div class="col-md-4 form-group" *ngIf="formTypeName!='Visitor Form'">
                  <label for="my-input" class="required">{{'Select Process'|translate}}</label>
                  <ps-auto-suggestion [data]="processes" (onSelected)="selectedProcess($event)" display="processName"
                  [preSelected]="{processName:processName}"
                    placeholder="Choose Field">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error">
                    <span>{{processValidator.msg}} </span>
                  </div>
                </div>

                <div class="row form-group action-block">
                  <div class="btn-block">
                    <button class="btn btn-warning" (click)="reset()"> {{'reset'|translate}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{"Save"|translate}}
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive pt-4">
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{'serial_no'|translate}}</th>
                          <th>{{'Form Type'|translate}}</th>
                          <th>{{('form_title'|translate)}}</th>
                          <th>{{('form_description'|translate)}}</th>
                          <th>{{('Process'|translate)}}</th>
                          <th class="action">{{'Actions'|translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let customForm of customForms;index as i">
                          <td>{{i+1}}</td>
                          <td> {{customForm.form_type_name}}</td>
                          <td> {{customForm.form_title}}</td>
                          <td>{{customForm.form_description}}</td>
                          <td>{{customForm.process_name}}</td>
                          <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-primary"
                                (click)="viewForm(customForm)">{{'view'|translate}}</button>
                              <button class="btn btn-success" (click)="editForm(customForm)">
                                {{'edit'|translate}}</button>
                              <button class="btn btn-danger" (click)="confirmAlert(customForm)">
                                {{'delete'|translate}}</button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

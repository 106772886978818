import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { MapService } from '../../@core/services/map.service';
declare let google: any;

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss'],
})
export class MapModalComponent implements OnInit {
  lat: any = null;
  lng: any = null;
  map = null;
  markers: any = [];
  marker: any;
  bounds: any = null;
  mapZoomLevel: any = 12;
  constructor(
    private common: CommonService,
    private mapService: MapService,
    private api: ApiService,
    private activeModal: NgbActiveModal
  ) {
    this.lat = this.common.params.lat;
    this.lng = this.common.params.lng;
  }

  ngOnInit(): void {}
  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  ngAfterViewInit() {
    this.mapService.clearAll();
    this.mapService.mapIntialize('map');
    this.mapService.setMapType(0);
    this.mapService.map.setOptions({ draggableCursor: 'cursor' });
    this.mapService.createMarker(new google.maps.LatLng(this.lat, this.lng));
    this.mapService.zoomAt(new google.maps.LatLng(this.lat, this.lng), 18);
  }
}

<div class="card">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{user._userType | translate}} {{'Dashboard' | translate}}
      </h3>
    </div>
  </div>

  <div class="card-body">
    <div class="services fixedview">
      <div class="card_sec">
        <div class="card-column" *ngFor="let item of common.usermenu;index as i" [class]="'card-column-'+i%10">
          <div class="card" (click)="commonNavigate(i)">
            <div class="card-body">
              <div class="icon"><img [src]="item.img" alt=""></div>
              <h3 class="info"> {{item.name | translate}} </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  private readonly iv: Uint8Array = new Uint8Array([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]); // Fixed IV (must be 12 bytes)
  private readonly key: string = "CPLMMYSAN#2023CORE"; //Master Key
  constructor() {
  }

  // Generate private Key
  private async generateAesKey() {
    const passBytes = new TextEncoder().encode(this.key);

    const hash = await window.crypto.subtle.digest("SHA-256", passBytes);

    return await window.crypto.subtle.importKey(
      'raw',
      hash,
      { name: 'AES-GCM' },
      true,
      ['encrypt', 'decrypt']
    );
  }

  // Encrypts the given data using the provided key and salt
  async encryptData(dataStr: string): Promise<string> {

    const key = await this.generateAesKey(); // Getting private Key
    const ec = new TextEncoder();

    const ciphertext = await crypto.subtle.encrypt({
      name: 'AES-GCM',
      iv: this.iv,
    }, key, ec.encode(dataStr));

    return this.arrayBufferToBase64(ciphertext); // Converting Cipher in Text
  }

  // Decrypts the given encrypted data using the provided key and salt
  async decryptData(encrypted: string): Promise<string> {

    console.log("Her1")
    const key = await this.generateAesKey(); // Getting private Key
    console.log("Her2")
    const dec = new TextDecoder();
    console.log("Her3",encrypted,"9/1pSUhDULsI72DLgzathpIM"
    )

    const plaintext = await crypto.subtle.decrypt({
      name: 'AES-GCM',
      iv: this.iv,
    }, key, this.base64ToArrayBuffer(encrypted));
    console.log("Her4")
    return dec.decode(plaintext); // Converting Decoded Bytes in String
  }

  arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);  // Base64 encode
  }

  base64ToArrayBuffer(base64: string): Uint8Array {
    const binaryString = atob(base64);  // Base64 decode
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="tab_btnn_sec">
              <div class="tab_btnn" *ngFor="let tab of actionLabels;index as i">
                <button type="button" class="btn btn-block nav-buttons"
                  [ngStyle]="{'background-color': tab.key==status ? tab.tabIconBg :tab.tabBgColor,'scale':tab.key==status ? 1.08 : 1}"
                  (click)="filterDataByTab(tab.key)">
                  <div class="left_part">
                    <div class="tab_name">{{tab.tabName | translate}}</div>
                    <div class="tab_number">{{tripScheduleData[tab.key]}}</div>
                  </div>

                  <div class="right_part">
                    <div class="icon_div"
                      [ngStyle]="{'background-color': tab.key==status ? tab.tabBgColor : tab.tabIconBg}">
                      <i [class]="'fa fa-'+tab.tab_icon" [ngClass]="tab.tabName"
                        [ngStyle]="{'color': tab.key==status ? tab.tabIconBg :tab.color}"></i>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Trip Schedule" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table #ref class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{"s.no." | translate}} </th>
                    <th>{{"Trip Id" | translate}} </th>
                    <th>{{"Vehicle No." | translate}} </th>
                    <th>{{"Trip Status" | translate}} </th>
                    <th>{{"process_name" | translate}} </th>
                    <th>{{'stage_name'|translate}}</th>
                    <th>{{"estimated out" | translate}} </th>
                    <th>{{"actual out" | translate}} </th>
                    <th>{{"Trip Tat" | translate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of tripScheduleData['trip_data']; let i = index;">

                    <tr
                      *ngIf="status == 'all' || item.stage_workflow_status.toLowerCase() == status || (status == 'is_early' && item.is_early) || (status == 'is_delay' && !item.is_early)">
                      <td>{{i + 1}}</td>
                      <td>{{item.ipa_transaction_id}}</td>
                      <td appEllipsisView>{{item.vehicle_rc_no}}</td>
                      <td style="text-align: center;width: fit-content !important;">
                        <span [ngClass]="{'green-jellybean':item.stage_workflow_status=='INPROGRESS','yellow-jellybean':item.stage_workflow_status=='MAINTAINENCE','red-jellybean':(item.stage_workflow_status!='INPROGRESS' && item.stage_workflow_status!='MAINTAINENCE')}">
                          {{item.stage_workflow_status}}
                        </span>
                      </td>
                      <td style="text-align: center;"><span class="info-jellybean">{{item.process_name}}</span>
                      </td>
                      <td appEllipsisView>{{item.stage_name}}</td>
                      <td>{{item.estimated_out | date: 'dd-MM-yyyy HH:mm'}}</td>
                      <td>{{item.actual_out | date: 'dd-MM-yyyy HH:mm'}}</td>
                      <td style="width: 10%;text-align: center;">
                        <span [ngClass]="{'early':item.is_early,'delay':(!item.is_early)}">
                          {{(item.is_early ? 'Early by ' : 'Delay by ') + item.delay_time}}
                        </span>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #myTemplate>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">{{'Remarks'|customTranslate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="req-info" *ngIf="info.length">
      <div class="row">
        <div class="col-md-3">
          <div class="info">
            <div class="info-header">{{("From Date"|customTranslate)+':'}}</div>
            <div class="info-value">{{this.info[0].from_date}}</div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="info">
            <div class="info-header">{{("To Date"|customTranslate)+':'}}</div>
            <div class="info-value">{{this.info[0].end_date}}</div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="info">
            <div class="info-header">{{("Requesting For"|customTranslate)+':'}}</div>
            <div class="info-value">{{this.info[0].req_for_entity+' : '+this.info[0].ref_entity_name}}</div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="info">
            <div class="info-header">{{("priority"|customTranslate)+':'}}</div>
            <div class="info-value">{{this.info[0].priority}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom_sec">
      <div class="row">
        <div class="col-md-9 pr-0">
          <div class="remarks">
            <app-trip-comment-trail [tripId]="requestId" [entityType]="'ASSET'" [showModal]="true"
              [toggle]="toggle"></app-trip-comment-trail>
          </div>
        </div>

        <div class="col-md-3">
          <div class="assets">
            <div class="assets-header">
              <span class="asset-header-name">{{'Asset Requested'|customTranslate}}</span>
            </div>

            <div class="asset-name" *ngFor="let asset of assets;let i=index">
              <span class="asset-name-box">{{asset.asset_name}}</span>

              <button class="btn btn-success" style="margin-right: 5px" *ngIf="this.refData?.calling=='true'">
                <span *ngIf="availableValues[i]==true" (click)="updateAssetStatusInRequest(asset.availability,asset.id,i)">{{"unavailable"|customTranslate}}</span>
                <span *ngIf="availableValues[i]==false" (click)="updateAssetStatusInRequest(asset.availability,asset.id,i)">{{"available"|customTranslate}}</span>
              </button>

              <span [ngClass]="{'asset-available':asset.availability,'asset-unavailable':!asset.availability}"
                *ngIf="this.route.snapshot.queryParams.calling=='false'">{{asset.availability==true?("available"|customTranslate):("unavailable"|customTranslate)}}</span>

              <span class='badge badge-pill badge-primary compositeParent' *ngIf="asset.child_asset!=null">Composite
                <ul class="children">
                  <li *ngFor="let child of toolTipData(asset.child_asset);let ind=index">
                    {{ind+1}}.
                    {{child.child_asset_name}}
                  </li>
                </ul>
              </span>
            </div>
          </div>

          <div class="bottom-buttons" *ngIf="reqStatus=='CHECKEDOUT'">
            <button type="button" class="btn-child" (click)="openCheckInCheckOutModal(true,'CHECKEDIN')">{{'CheckIn'|customTranslate}}</button>
          </div>

          <div class="bottom-buttons" *ngIf="reqStatus=='ASSIGNED'">
            <button type="button" class="btn-child" (click)="openCheckInCheckOutModal(false,'CHECKEDOUT')">{{'CheckOut'|customTranslate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
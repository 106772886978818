<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header mb-1" style="align-items: baseline;">
              <div class="header-block">
                <h4 class="card-title">
                  {{'form_layout_setting'|translate}}
                </h4>
                <h6 class="card-sub-title">
                  {{'common_setting_for_form_layout'|translate}}
                </h6>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-6 col-sm-12 p-0 pr-2">
                  <label style="font-size: 12px;">{{'field_linked_with'|translate}}</label>
                  <ps-auto-suggestion (onSelected)="getCheckListActions($event)" [data]="linking" display="value"
                    placeholder="Select Field linked with" name="value">
                  </ps-auto-suggestion>
                </div>

                <div class="col-xl-4 col-lg-6 col-sm-12 p-0 pr-2" *ngIf="selectedField!='CHECKLIST'">
                  <label style="font-size: 12px;">{{'select_process'|translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectProcess($event)" [data]="processList" display="processName"
                    placeholder="Select Process" name="processName">
                  </ps-auto-suggestion>
                </div>

                <div class="col-xl-4 col-lg-6 col-sm-12 p-0 pr-2" *ngIf="selectedField=='STAGE'">
                  <label style="font-size: 12px;">{{'select_stage:'|translate}}</label>
                  <ps-auto-suggestion (onSelected)="getstageName($event)" [data]="stagelist" display="stageName"
                    placeholder="Select Stage" name="stageName" [readonly]="processId==null">
                  </ps-auto-suggestion>
                </div>

                <div class="col-xl-4 col-lg-6 col-sm-12 pl-0" *ngIf="selectedField=='ACTION'|| selectedField=='CHECKLIST'">
                  <label style="font-size: 12px;">{{'select_action'|translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedAction($event)" [data]="actionList" display="actionName"
                    placeholder="Select Action" name="actionName">
                  </ps-auto-suggestion>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <div class="form_layout text-center">

                    <div class="fields_header">
                      <i class="fa fa-search search_field" aria-hidden="true" ></i>
                      <input class="search_input" name="fieldSearch" id="fieldSearch" type="text"
                        (input)="searchFieldName($event,true)" placeholder="Field Name">
                    </div>

                    <div class="fields example-list" cdkDropList [cdkDropListData]="fields"
                      [cdkDropListConnectedTo]="[allItemsListLeft,allItemsListRight]"
                      *ngIf="fields.length" id="outer_list">
                      <div *ngFor="let data of fields;let i=index;last as islast" cdkDrag class="drag-element" [cdkDragData]="data">
                        <div class="fields_name"
                          [ngClass]="{'color-when-not-null' : data.database_linked_id!=null ,'color-when-null' : data.database_linked_id==null}">
                          {{data.field_name}}</div>
                      </div>

                    </div>
                    <img src="assets/images/no-record.png" alt="" style="width: 60%;margin-top: 35%;" alt=""
                      *ngIf="!fields.length">
                  </div>
                </div>
                <div class="col-md-9 pl-0">
                  <div class="form_layout">
                    <div cdkDropListGroup class="row m-0" style="min-height: 300px">
                      <div cdkDropList [cdkDropListData]="allItems" class="example-list col-md-6 p-0"
                        #allItemsListLeft="cdkDropList" (cdkDropListDropped)="drop($event, 'all-items','left')" id="left">
                        <ng-container *ngFor="let item of allItems; index as i,even as isEven; last as isLast">
                           <div *ngIf="isEven" cdkDrag [cdkDragData]="item"
                            [ngClass]="{'color-when-not-null': item.database_linked_id != null, 'color-when-null': item.database_linked_id == null}">
                            <div class="example-box example-box1 setting-box">
                            <span> {{i+1}}: </span>
                            <span style="width:45%;text-align: center;">{{item.field_name}}</span>
                            <span class="priority" *ngIf="selectedField=='ACTION' || 'CHECKLIST'"><input type="checkbox"
                                [checked]="item.mandatory==1 || !item.hasOwnProperty('mandatory')"
                                (change)="changeMandatoryStatus(item)" /><span class="input_check_box">{{'mandatory'|translate}}</span></span>
                            <i class="fa fa-times remove-element" aria-hidden="true"
                              [hidden]="selectedField=='ENTRY FORM' && item.database_field_name=='vechile_number'"
                              (click)="removeFields(i)">
                            </i>
                          </div>
                            <div class="example-box example-box1 setting-box" style="margin-top: -9px;display:block" *ngIf="item.field_type=='FIELDGROUP'">
                             <ng-container *ngFor="let group of item.subgroups;index as ind">
                              <div style="display: flex;flex-direction:column;margin: 5px 0px;margin-left: 16px;">
                              <div class="row">
                              <span> {{ind+1}}: </span>
                            <span style="width:45%;text-align: center;">{{group}}</span>
                            <span class="priority" ><input type="checkbox"
                              [checked]="item.savegroups && (item.savegroups.indexOf(ind) !== -1 || !item.hasOwnProperty('savegroups'))"
                                (change)="changeShowStatus(item,ind)" /><span class="input_check_box">{{'show'|translate}}</span></span>
                                <span class="priority" style="margin-left: 65px"><input type="checkbox"
                                  [checked]="item.uniqueGroups && (item.uniqueGroups.indexOf(ind) !== -1 || !item.hasOwnProperty('uniqueGroups'))"
                                    (change)="changeUniqueStatus(item,ind)" /><span class="input_check_box">{{'unique'|translate}}</span></span>
                                </div>
                            </div>
                          </ng-container>
                              </div>

                          </div>

                        </ng-container>
                      </div>
                      <div [hidden]="allItems.length == 0" cdkDropList [cdkDropListData]="allItems"
                        class="example-list col-md-6 pl-2" #allItemsListRight="cdkDropList" style="min-height: 300px;"
                        (cdkDropListDropped)="drop($event, 'all-items','right')" id="right">
                        <ng-container *ngFor="let item of allItems;index as i;last as islast;odd as isOdd">
                          <div *ngIf="isOdd" cdkDrag [cdkDragData]="item"
                            [ngClass]="{'color-when-not-null' : item.database_linked_id!=null ,'color-when-null' : item.database_linked_id==null}">
                            <div class="example-box example-box2 setting-box" >
                            <span> {{i+1}}: </span>
                            <span style="width:45%;text-align: center;">{{item.field_name}}</span>
                            <span class="priority" *ngIf="selectedField=='ACTION' || 'CHECKLIST'"><input type="checkbox"
                                [checked]="item.mandatory==1 || !item.hasOwnProperty('mandatory')"
                                (change)="changeMandatoryStatus(item)" /><span class="input_check_box">{{'mandatory'|translate}}</span></span>
                            <i class="fa fa-times remove-element" aria-hidden="true"
                              [hidden]="selectedField=='ENTRY FORM' && item.database_field_name=='vechile_number'"
                              (click)="removeFields(i)"></i>
                             </div>
                              <div class="example-box example-box1 setting-box" style="margin-top: -9px;display:block" *ngIf="item.field_type=='FIELDGROUP'">
                                <ng-container *ngFor="let group of item.subgroups;index as ind">
                                 <div style="display: flex;flex-direction:column;margin: 5px 0px;margin-left: 16px;">
                                 <div class="row">
                                 <span> {{ind+1}}: </span>
                               <span style="width:45%;text-align: center;">{{group}}</span>
                               <span class="priority" ><input type="checkbox"
                                [checked]="item.savegroups && (item.savegroups.indexOf(ind) !== -1 || !item.hasOwnProperty('savegroups'))"
                                   (change)="changeShowStatus(item,ind)" /><span class="input_check_box">{{'show'|translate}}</span></span>
                                   <span class="priority" style="margin-left: 65px"><input type="checkbox"
                                    [checked]="item.uniqueGroups && (item.uniqueGroups.indexOf(ind) !== -1 || !item.hasOwnProperty('uniqueGroups'))"
                                      (change)="changeUniqueStatus(item,ind)" /><span class="input_check_box">{{'unique'|translate}}</span></span>
                                   </div>
                               </div>
                             </ng-container>
                                 </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div *ngIf="allItems.length == 0"
                      style="position: relative;margin-top: -135px;z-index: 99999;">
                      <app-data-not-found></app-data-not-found>
                    </div>
                  </div>
                </div>
              </div>
              <div class="stage-setting-footer">
                <div class="btn-block d-flex  justify-content-end">
                  <button class="btn ml-2 btn-warning text-uppercase" (click)="resetData()"> {{'reset'|translate}}
                  </button>
                  <button class="btn ml-2 btn-success text-uppercase" (click)="savestagesetting()"
                    *ngIf="userService.permissionMap.get('save_stage_setting_access')==true">{{'save'|translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


</div>

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { NotificationService } from 'src/app/@core/services/notification.service';

@Component({
  selector: 'ngx-visitor',
  styleUrls: ["./visitor.component.scss"],
  template: `
    <app-notification></app-notification>
    <app-header [menu]="[]"></app-header>
    <main>
      <!-- <app-breadcrumb *ngIf="!common.driverView"></app-breadcrumb> -->
      <app-alert-message></app-alert-message>
      <app-screen-loader *ngIf="common.loading"></app-screen-loader>
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
  `,
})
export class VisitorComponent {
  menu = [];
  // menu = menu;
  constructor(
    private api: ApiService,
    public common: CommonService,
    private notificationService: NotificationService
  ) {
    // this.getMenu();
    this.notificationService.askPermission();
  }
}

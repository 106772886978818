<div class="container-fluid " [ngStyle]="report?{}:{padding:'0px'}">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
            <div class="card-header pb-2">
              <div class="header-block">
                <h3 class="card-title">
                  {{title}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="table-card">
                <div class="row date-filter">
                  <div class="col-md-3 " *ngIf="role.user_role=='COMPANYADMIN'">
                    <label>{{'plant:'|translate}}</label>
                    <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                      placeholder="Choose Plant">
                    </ps-auto-suggestion>
                  </div>
                  <div class="col-md-3 date">
                    <label>{{'start_date:'|translate}}</label>
                    <input class="form-control" type="date" name="startDate" [(ngModel)]="startDate">
                  </div>
                  <div class="col-md-3 date">
                    <label>{{'end_date:'|translate}}</label>
                    <input class="form-control" type="date" name="endDate" [(ngModel)]="endDate">
                  </div>
                  <div class="col-md-1">
                    <button class="btn btn-secondary" (click)="checklistHistory()">{{"search" | translate}}</button>
                  </div>
                  <div class="col-md-1 action-block">
                    <div class="btn-block">
                      <button class="btn btn-warning" (click)="onReset()">{{"Reset" | translate}}</button>
                    </div>
                  </div>
                </div>

                <div class="view_table" *ngIf="report==false">
                  <div class="filter_sec">
                    <div class="right-menu">
                      <div class="row">
                        <div class="form-group col-md-2 date">
                          <label for="my-input" style="min-width: 50px;">Assign To</label>
                          <ps-auto-suggestion
                            (onSelected)="selectedAssignToUser = $event.userName;renderTable()"
                            [preSelected]="{userName:selectedAssignToUser}" [data]="userList" display="userName"
                            placeholder="Choose User">
                          </ps-auto-suggestion>
                        </div>
                        <div class="form-group col-md-2 date">
                          <label for="my-input" style="min-width: 50px;">{{"status" | translate}}</label>
                          <select id="status" name="status" class="form-control" [(ngModel)]="selectedStatus"
                            (change)="renderTable()">
                            <option value="All">{{'All' | translate}}</option>
                            <option value="Pending">{{'Pending' | translate}}</option>
                            <option value="Approved">{{'Completed' | translate}}</option>
                            <option value="Rejected">{{'Rejected' | translate}}</option>
                          </select>
                        </div>
                        <div class="form-group col-md-2 date">
                          <label for="my-input" style="min-width: 50px;">Select Checklist</label>
                          <ps-auto-suggestion
                            (onSelected)="selectedcheckListName1 = $event.checklist_title;renderTable()"
                            [preSelected]="{checklist_title:selectedcheckListName1}" [data]="checkListData"
                            display="checklist_title" placeholder="Choose Checklist">
                          </ps-auto-suggestion>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table_section">
                    <div class="table_responsive">
                      <table class="table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                        *ngIf="report==false">
                        <thead>
                          <tr>
                            <th>{{"sr.no"}}</th>
                            <th>{{("Checklist" | translate)+' '+("title"|translate)}}</th>
                            <th>{{"assign_to" | translate}}</th>
                            <th>{{"checklist_date" | translate}}</th>
                            <th>{{"start_time" | translate}}</th>
                            <th>{{"end_time" | translate}}</th>
                            <th>{{"tolerance" | translate}}</th>
                            <th>{{"perform_date_time" | translate}}</th>
                            <th>{{"status" | translate}}</th>
                            <th>{{"perform_status" | translate}}</th>
                            <th>{{"Actions" | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let item of history;index as i">
                            <tr
                              *ngIf="(item.assigned_to_user == selectedAssignToUser || selectedAssignToUser == 'All') && (item.status == selectedStatus || selectedStatus == 'All') && (item.checklist_title == selectedcheckListName1 || selectedcheckListName1 == 'All')">
                              <td>{{i+1}}</td>
                              <td> {{item.checklist_title}} </td>
                              <td>{{item.assigned_to_user}}</td>
                              <td>{{item.checklist_date|date:'dd-MM-yyyy'}}</td>
                              <td>{{item.start_time}}</td>
                              <td>{{item.end_time}}</td>
                              <td>{{common.minutesToHours(item.tolerance)}}</td>
                              <td>{{item.status=='Pending'?'-':item.perform_time| date:'dd-MM-YYYY hh:mm'}}</td>
                              <td><span class="badge status-badge" style="background-color: #f7d5d5;"
                                  [ngClass]="{'badge-success':item.status == 'Approved','badge-danger':item.status== 'Rejected','badge-secondary':item.status== 'Pending'}">{{item.status=='Approved'?'Completed':item.status}}</span>
                              </td>
                              <td *ngIf="item.perform_status=='overdue'"><span class="badge status-badge"
                                  style="background-color: #f7d5d5;"
                                  [ngClass]="{'badge-success':item.perform_status == 'on-time','badge-danger':item.perform_status== 'delay','badge-overdue':item.status== 'Pending'}">Overdue
                                  By {{item.delay}} Hrs </span></td>
                              <td *ngIf="item.perform_status=='delay'"><span class="badge status-badge"
                                  style="background-color: #f7d5d5;"
                                  [ngClass]="{'badge-danger':item.perform_status== 'delay'}">delay by {{item.delay}}
                                  Hrs </span></td>
                              <td *ngIf="item.perform_status=='on-time'"><span class="badge status-badge"
                                  style="background-color: #f7d5d5;"
                                  [ngClass]="{'badge-success':item.perform_status=='on-time'}">Early by {{item.delay}}
                                  Hrs </span></td>
                              <td><span *ngIf="item.status=='Approved'" style="display: flex;"><button
                                    class="btn btn-primary"
                                    (click)="viewCheckList(item)">{{"view"|translate}}</button><button
                                    *ngIf="item.late_remark_id!=null" class="btn btn-danger" style="
                  background-color: #e85151; margin-left: 50px;" (click)="viewRemark(item)"> {{"remark" | translate}}
                                  </button></span>
                                <span *ngIf="item.status=='Rejected'" style="display: flex;"><button
                                    class="btn btn-info"
                                    (click)="viewRejectedRemark(item.log_id,item.checklist_title)">{{"Reject
                                    Remark"|translate}}</button><button *ngIf="item.late_remark_id!=null"
                                    class="btn btn-danger" style="
                  background-color: #e85151; margin-left: 11px;" (click)="viewRemark(item)"> {{"remark" | translate}}
                                  </button></span>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="view_table1" *ngIf="report==true">
                  <div class="filter_sec">
                    <div class="row date">
                      <div class="form-group col-md-2 date">
                        <label for="my-input" style="min-width: 50px;">{{("Select User:" | translate)}} </label>
                        <ps-auto-suggestion (onSelected)="selectedUser($event)"
                          [preSelected]="{userName:selecteduserName}" [data]="userList" display="userName"
                          placeholder="Choose User">
                        </ps-auto-suggestion>
                      </div>
                      <div class="form-group col-md-2 date">
                        <label for="my-input" style="min-width: 50px;">Select Checklist</label>
                        <ps-auto-suggestion (onSelected)="selectCheckList($event)"
                          [preSelected]="{checklist_title:selectedcheckListName}" [data]="checkListData"
                          display="checklist_title" placeholder="Choose Checklist">
                        </ps-auto-suggestion>
                      </div>
                      <div class="form-group col-md-2 date">
                        <label for="my-input" style="min-width: 50px;">{{"status" | translate}}</label>
                        <select id="status" name="status" class="form-control" [(ngModel)]="selectedStatus"
                          (change)="checklistHistory()">
                          <option value="All">{{'All' | translate}}</option>
                          <option value="Pending">{{'Pending' | translate}}</option>
                          <option value="Approved">{{'Completed' | translate}}</option>
                          <option value="Rejected">{{'Rejected' | translate}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="table_sec" *ngIf="report==true">
                    <table class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>{{"date" | translate}}</th>
                          <th>{{"Pending" | translate}}</th>
                          <th>{{"Completed"}}</th>
                          <th>{{"rejected" | translate}}</th>
                          <th>{{"Before Time" | translate}}</th>
                          <th>{{"In Time" | translate}}</th>
                          <th>{{"After Time" | translate}}</th>
                          <th>{{"Action" | translate}}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let item of history;index as i">
                          <td> {{item.date|date:'dd-MM-yyyy'}} </td>
                          <td><span (click)="item.pending_count!=0 && readCountData(item.date,item.date,'Pending')"
                              class="badge info">{{item.pending_count}}<i *ngIf="item.pending_count!=0"
                                class="fa fa-info-circle ml-2"></i></span></td>
                          <td><span (click)="item.approved_count!=0 && readCountData(item.date,item.date,'Approved')"
                              class="badge info">{{item.approved_count}}<i *ngIf="item.approved_count!=0"
                                class="fa fa-info-circle ml-2"></i></span></td>
                          <td><span (click)="item.rejected_count!=0 && readCountData(item.date,item.date,'Rejected')"
                              class="badge info">{{item.rejected_count}}<i *ngIf="item.rejected_count!=0"
                                class="fa fa-info-circle ml-2"></i></span></td>
                          <td><span (click)="item.before_tolerance!=0 && readCountData(item.date,item.date,'before')"
                              class="badge info">{{item.before_tolerance}}<i *ngIf="item.before_tolerance!=0"
                                class="fa fa-info-circle ml-2"></i></span></td>
                          <td><span (click)="item.in_tolerance!=0 && readCountData(item.date,item.date,'in')"
                              class="badge info">{{item.in_tolerance}}<i *ngIf="item.in_tolerance!=0"
                                class="fa fa-info-circle ml-2"></i></span></td>
                          <td><span (click)="item.after_tolerance!=0 && readCountData(item.date,item.date,'after')"
                              class="badge info">{{item.after_tolerance}}<i *ngIf="item.after_tolerance!=0"
                                class="fa fa-info-circle ml-2"></i></span></td>
                          <td><button class="btn btn-form" aria-hidden="true" title="Form"
                              style="background-color: #FF9914;font-style: none;"
                              (click)="item.total!=0 && readCountData(item.date,item.date,'All')">
                              <i class="fa fa-eye">{{'view'|translate}}</i>
                            </button></td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <td>{{'status_wise_total'|translate}}</td>
                          <td *ngFor="let status of statusWiseCountList | keyvalue">
                            <span *ngIf="status.key != '6All'"
                              (click)=" status.value!=0 && readCountData(startDate,endDate,status.key)"
                              class="badge info">{{status.value}}<i *ngIf="status.value!=0"
                                class="fa fa-info-circle ml-2"></i></span>
                            <button *ngIf="status.key == '6All'" class="btn btn-form" aria-hidden="true" title="Form"
                              style="background-color: #FF9914;font-style: none;"
                              (click)="status.value!=0 && readCountData(startDate,endDate,status.key.substring(1,status.key.length))"><i
                                class="fa fa-eye">{{'view'|translate}}</i></button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
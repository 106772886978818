<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Trip History" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="trip_history_page">
                <div class="top_sec">
                  <form>
                    <div class="row align-items-center">

                      <div class="col-md-4" *ngIf="role.user_role == 'COMPANYADMIN'">
                        <ps-auto-suggestion (onSelected)="onSelectPlant($event)" [data]="plantList" display="plantName"
                          placeholder="Choose Plant" [preSelected]="{plantName:selectedPlantName}">
                        </ps-auto-suggestion>
                      </div>

                      <div class="col-md-4">
                        <div class="row align-items-center">
                          <div class="col-md-4">
                            <label>{{"Search For" | translate}}:</label>
                          </div>
                          <div class="col-md-8">
                            <div class="radio_sec">
                              <div class="radio_btn" *ngFor="let value of radioValue">
                                <input type="radio" [value]="value.id" name="searchFor" [(ngModel)]="selectedradio"
                                  (change)="changeRadio()">
                                <span>{{(value.radio | lowercase) | translate}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="row align-items-center">
                          <div class="col-md-4">
                            <label *ngIf="selectedradio==1">{{"driver_name" | translate}}:</label>
                            <label *ngIf="selectedradio==2">{{"vehicle_number" | translate}}:</label>
                          </div>
                          <div class="col-md-8">
                            <ps-auto-suggestion *ngIf="selectedradio==1" (onSelected)="selectedDriver($event)"
                              [data]="driversList" display="driver_name" placeholder="Choose Driver"
                              [preSelected]="{driver_name:driverName}">
                            </ps-auto-suggestion>
                            <ps-auto-suggestion *ngIf="selectedradio==2" (onSelected)="selectedVehicle($event)"
                              [data]="vehiclesList" display="vehicle_rsn" placeholder="Choose Vehicle RC No"
                              [preSelected]="{vehicle_rsn:vehicleRcNo}">
                            </ps-auto-suggestion>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="cards_sec">


                  <div *ngIf="selectedradio==1 && driverDetails!=null">

                    <div class="row id-card">
                      <div class="col-md-8 left_sec">
                        <div class="id_card_img">
                          <img [src]="driverDetails?.image ?? 'assets/images/empty_image.jpg'">
                        </div>

                        <div class="id_card_content">
                          <div class="id_card_content_row">
                            <span><i class="fa fa-user mr-1"></i> Driver Name : </span> {{driverDetails.name}}
                          </div>

                          <div class="id_card_content_row">
                            <span><i class="fa fa-phone mr-1"></i>  {{'phone number' | translate }} : </span>
                            {{(driverDetails.phone_number==null || driverDetails.phone_number=='')?' -- ':driverDetails.phone_number}}
                          </div>

                          <div class="id_card_content_row">
                            <span><i class="fa fa-phone mr-1"></i>  {{'alternate mobile no' | translate }} : </span>
                            {{(driverDetails.alt_mobile_no==null || driverDetails.alt_mobile_no=='')?' -- ':driverDetails.alt_mobile_no}}
                          </div>

                          <div class="id_card_content_row">
                            <span><i class="fa fa-id-card-o mr-1"></i>  {{'driver license no' | translate }} : </span>
                            {{(driverDetails.driver_license_no==null || driverDetails.driver_license_no=='')?' -- ':driverDetails.driver_license_no}}
                          </div>

                          <div class="id_card_content_row">
                            <span><i class="fa fa-truck"></i>  {{'Total Trips' | translate }} : </span>
                            {{history.length}}
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 right_sec">
                        <div class="id_card_licence_img">
                          <img [src]="driverDetails?.driver_liscence_image ?? 'assets/images/empty_image.jpg'" alt="Barcode">
                        </div>
                      </div>

                      <!-- <div class="id-card-body">
                        <div class="photo">
                          <img [src]="driverDetails?.image ?? ''" alt="Profile Photo">
                        </div>
                        <div class="info">
                          <h2>{{driverDetails.name}}</h2>
                          <div class="details">
                            <div class="column">
                          <p><strong>{{'phone number' | translate }}</strong> {{(driverDetails.phone_number==null ||
                            driverDetails.phone_number=='')?' -- ':driverDetails.phone_number}}</p>
                          <p><strong>{{'alternate mobile no' | translate }}</strong>{{(driverDetails.alt_mobile_no==null ||
                            driverDetails.alt_mobile_no=='')?' -- ':driverDetails.alt_mobile_no}}</p>
                            </div>
                            <div class="column">
                          <p><strong>{{'driver license no' | translate }}</strong>{{(driverDetails.driver_license_no==null ||
                            driverDetails.driver_license_no=='')?' -- ':driverDetails.driver_license_no}}</p>
                          <p><strong>{{'Total Trips' | translate }}</strong> {{history.length}}</p>
                          </div>
                          </div>
                          <div class="barcode">
                            <img [src]="driverDetails?.driver_liscence_image ?? ''" alt="Barcode">
                          </div>
                        </div>
                      </div> -->
                    </div>


                  </div>

                  <div class="card_single"
                      *ngIf="selectedradio==2 && selectedVehicleId!=null && vehicleDetails.length>0">

                      <div class="card_title">
                        <div class="vehicle_num">
                          <span>Vehicle Details</span>
                        </div>
                      </div>
                      <span style="margin-left: 18%; font-size: larger"><strong><i class="fa fa-truck"></i> : </strong>
                        {{vehicleRcNo}}</span>
                      <div class="card_body" style="display:flex;">

                        <div class="col-8">
                          <div *ngFor="let data of vehicleDetails">
                            <!-- <div class="col-12"> -->
                            <div class="card" style="display: flow;padding: 1%;">
                              <span><strong>{{data.type_name|uppercase}}</strong></span>
                              <div style="display: flex; justify-content: space-evenly;">
                                <div style="margin: 0 auto 0 0;">
                                  <div *ngFor="let doc of data.res">
                                    <button style="color: blue; background:none; border:none;"
                                      *ngIf="doc.fieldType=='GEOCODEDIMAGE'"
                                      (click)="previewImage(doc.values[0].fileUrl)"><i i class="fa fa-file"
                                        aria-hidden="true"></i> {{data.type_name}}</button>
                                    <span *ngIf="doc.fieldType=='TEXTFIELD'"><small><strong> {{doc.name}} :
                                        </strong></small><span>{{doc.values[0]}}</span></span>
                                  </div>
                                </div>
                                <div style="margin: 0 auto 0 0;">
                                  <span><strong><i class="fa fa-calendar" aria-hidden="true"></i> Expiry Date :
                                    </strong>
                                    {{data.expiry_date}}</span>
                                </div>
                                <div style="margin: 0 auto 0 0;">
                                  <span *ngIf="data.ulip" style="color:rgb(7 236 49)">
                                    <i class="fa fa-check" aria-hidden="true"></i>ULIP Verified</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="column" style="max-width:40% !important">
                          <div style="display:flex; justify-content: center;">
                            <img style="width: 39%;" *ngIf="showImage" [src]="imgUrl??'assets/images/empty_image.jpg'">
                          </div>
                        </div>
                      </div>
                    </div>


                  <div class="card_single" *ngFor="let item of history">
                    <div class="card_title">
                      <div class="date">
                        <i class="fa fa-calendar-o"></i> <span>{{item.start_time | date: 'dd-MM-YYYY'}}</span>
                      </div>

                      <div class="vehicle_num">
                        <i class="fa fa-truck"></i> <span appEllipsisView>{{item.vehicle_rc_no}}</span>
                      </div>

                      <div class="image">
                        <img class="icon" src="assets/images/trip-details(3).png" alt=""
                          (click)="tripModal(item.ipa_transaction_id,item.process_name)">
                        <img class="icon" src="assets/images/trip-tracking(3).png" alt=""
                          (click)="tripTrackingModal(item)">
                        <app-trip-comment-trail [tripId]="item.ipa_transaction_id"></app-trip-comment-trail>

                      </div>

                    </div>

                    <div class="card_body">
                      <div class="in_out_sec">
                        <div class="time" style="background: #a4ffa4;"><b>{{"in_time" | translate}}:
                          </b><br>{{item.start_time | date:'shortTime'}}</div>
                        <div class="time" style="background: #ffb8b8;"><b>{{"out_time" | translate}}:
                          </b><br>{{item.end_time | date:'shortTime'}}</div>
                      </div>

                      <div class="box_sec">
                        <div class="box_single">
                          <div class="width_class">
                            <h6>{{"driver_name" | translate}}</h6>
                            <div class="box">
                              <div class="img">
                                <img src="assets/images/driver_icon.png">
                              </div>
                              <p>{{item.driver_name}}</p>
                            </div>
                          </div>
                        </div>

                        <div class="box_single">
                          <div class="width_class">
                            <h6>{{"process" | translate}}</h6>
                            <div class="box">
                              <div class="img">
                                <img src="assets/images/process_icon.png">
                              </div>
                              <p>{{item.process_name}}</p>
                            </div>
                          </div>
                        </div>

                        <div class="box_single">
                          <div class="width_class">
                            <h6>{{"destination" | translate}}</h6>
                            <div class="box">
                              <div class="img">
                                <img src="assets/images/destination_icon.png">
                              </div>
                              <p *ngFor="let loader of item.loaders">{{loader}}</p>
                            </div>
                          </div>
                        </div>

                        <div class="box_single">
                          <div class="width_class">
                            <h6>{{"order_id" | translate}}</h6>
                            <div class="box">
                              <div class="img">
                                <img src="assets/images/order_id_icon.png">
                              </div>
                              <p>{{item.extend_order_id}}</p>
                            </div>
                          </div>
                        </div>


                        <div class="box_single">
                          <div class="width_class">
                            <h6>{{"transaction_id" | translate}}</h6>
                            <div class="box">
                              <div class="img">
                                <img src="assets/images/trantion_id_icon.png">
                              </div>
                              <p>{{item.transaction_name}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <app-data-not-found *ngIf="history.length < 1"></app-data-not-found>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { ApiService } from 'src/app/@core/services/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../@core/services/common.service';
import { TableService } from '../../@core/services/table.service';
import { UserService } from '../../@core/services/user.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';

@Component({
  selector: 'app-hardware-status',
  templateUrl: './hardware-status.component.html',
  styleUrls: ['./hardware-status.component.scss'],
})
export class HardwareStatusComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(
    10,
    4,
    'Stage Destination Code Occupancy List'
  );
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  hardwareData: any[] = [];
  hardwareName: any = null;
  hardwareId: any = null;
  hardwareStatus: any = 'ACTIVE';
  btn: any = 'save';

  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    public table: TableService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public api: ApiService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Hardware', url: null },
      { name: 'Hardware Status', link: '/user/hardware-status' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
    this.getHardware();
  }

  getHardware() {
    this.common.loading = true;
    this.api.get('hardware/get_hardware').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.hardwareData = res.data;
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  selectedHardware(event: any) {
    this.hardwareId = event.ipaHardwareId;
    this.hardwareName = event.hardwareName;
  }

  reset() {
    this.btn = 'save';
    this.hardwareId = null;
    this.hardwareName = null;
    this.hardwareStatus = 'ACTIVE';
  }

  Save() {
    let params = {
      hardwareId: this.hardwareId,
      hardwareStatus: this.hardwareStatus,
    };
    this.common.loading = true;
    this.api.post('hardware/update_hardware_status', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.btn = 'save';
        this.reset();
        this.getHardware();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'Hardware Status Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  editStatus(row: any) {
    this.btn = 'update';
    this.hardwareId = row.ipaHardwareId;
    this.hardwareName = row.hardwareName;
    this.hardwareStatus = row.status;
  }
}

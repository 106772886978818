import { DatePipe } from '@angular/common';
import { DashboardService } from '../dashboard/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-average-tat',
  templateUrl: './average-tat.component.html',
  styleUrls: ['../dashboard/dashboard.component.scss', './average-tat.component.scss'],
})
export class AverageTatComponent implements OnInit {
  chartTypes = [{'chart':'Bar'}, {'chart':'Line'}];
  selectchartType = this.chartTypes[1].chart;
  public lineChartType: ChartType = 'line';
  reportType = [{'filter':'Daywise','type':'dayWise'}, {'filter':'Monthwise','type':'Monthwise'}];
  defaultreportType: string = '';
  tattype = this.reportType[0].type;
  tatType = 'Monthwise'
  months: number = 0;
  tatfilter: string = 'Monthwise';
  public lineChartLegend = true;

  tatdata: any[] = [];
  public lineChartData: ChartDataSets[] = [
    { data: [], label: 'TAT', lineTension: 0 },
  ];

  public lineChartColors: Color[] = [
    {
      // red
      backgroundColor: 'rgba(148,159,177,1)',
      borderColor: '#f64e60',
      pointBackgroundColor: '#3f7ac7',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'TAT Hours',
            fontSize: 12,
            fontStyle: 'bold',
            fontFamily: ' sans-serif',
          },
        },
      ],
    }, //END scales
    showLines: true,
    legend: {
      align: 'center',
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#3f7ac7',
        font: {
          weight: 'bold',
          size: 12,
        },
      },
    },
  };
  constructor(
    public workflow: DashboardService,
    public common: CommonService,
    public userService: UserService
  ) {}

  public lineChartLabels: Label[] = [];

  public changeChartType(event: any): void {
    this.lineChartType = event.chart.toLowerCase();
  }

  public barChartColors: Color[] = [
    { backgroundColor: "transparent",
      borderColor: '#f64e60',
      pointBackgroundColor: '#3f7ac7',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',},
    { backgroundColor: this.common.graphsuccess },
    { backgroundColor: this.common.graphdanger },
  ];

  gettatindex(event: any) {
    this.tatType = event.filter;
    this.tatfilter = event.type;
    this.gettatdata(event.type);
  }

  gettatdata(type:string=null) {
    this.defaultreportType = type == null ? this.reportType[0].type : type;
    this.workflow.getalltatdata(this.tatfilter).subscribe((item: any) => {
      if (item.status) {
        this.tatdata = item.data ?? [];
        this.lineChartData[0].data = [];
        if (this.tatdata != null && this.tatdata.length) {
          let data: any[] = [];
          let labels: string[] = [];
          this.tatdata.map((item: any) => {
            this.tatfilter == 'dayWise'
              ? labels.push(item.date)
              : labels.push(item.month);
            item.tat = item.tat ?? 0;
            data.push(item.tat.toFixed(0));
          });
          //fomart date
          const formattedLabels = [];
          if (this.tatfilter == 'dayWise') {
            labels.forEach((e) => {
              const date = new Date(e);
              const datePipe = new DatePipe('en');
              formattedLabels.push(datePipe.transform(date, 'dd-MM-yyyy'));
            });
            labels = formattedLabels;
          }
          this.lineChartData[0].data = data;
          this.lineChartLabels = labels;
        }
      }
    });
  }

  ngOnInit(): void {
    this.gettatdata();
  }
}

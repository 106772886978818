import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import * as XLSX from 'xlsx';
import * as filesaver from 'file-saver';
import _ from 'lodash';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';




@Component({
  selector: 'app-dock-appointment-report',
  templateUrl: './dock-appointment-report.component.html',
  styleUrls: ['./dock-appointment-report.component.scss']
})
export class DockAppointmentReportComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'DOCK APPOINTMENT REPORT');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getDockAppointmentReportData();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    public table: TableService,
    public common: CommonService,
    public api: ApiService,
    public alert: AlertService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public modalService: NgbModal,
    public role: RoleService,
    public plantService: PlantService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Dock Appointment Report', url: '/user/dock-appointment-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
   }

   dockReportData :any [] =[];
   startDate: any = new Date(new Date().setDate(new Date().getDate() - 7));
   endDate: any = new Date();
   transporterList :any[] = [];
   vehicleList : any[] = [];
   productList:any[] = [];
   driverList :any[] = [];
   transporterName : string ;
   driverName :string;
   vehicleRc :string;
   productName :string;
   status:string;
   transporterId : number = null;
   driverId :number = null;
   vehicleId :number = null;
   productId :number = null;


  ngOnInit() {
    this.getAllDriver();
    this.getAllTransporter();
    this.getAllVehicles();
    this.getProductList();
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);

  }

  onSelectTransporter(event:any){
    this.transporterId = event.id;
    this.transporterName = event.transporterName;
    this.getDockAppointmentReportData();
  }

  onSelectDriver(event:any){
    this.driverId = event.id;
    this.driverName = event.driverName;
    this.getDockAppointmentReportData();
  }

  onSelectProduct(event:any){
    this.productId = event.id;
    this.productName =  event.product_name;
    this.getDockAppointmentReportData();
  }

  onSelectVehicle(event:any){;
    this.vehicleId =  event.vehicle_id;
    this.vehicleRc =  event.vehicle_rsn;
    this.getDockAppointmentReportData();
  }

  getAllTransporter() {
    this.common.loading = true;
    this.api.get('transporter/get-all-transporters').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.transporterList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllVehicles() {
    this.common.loading = true;
    this.api.get('vehicle/get-all-vehicles').subscribe(
      (res: any) => {
        this.vehicleList = res.data;
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getProductList() {
    let params = {
      allProducts:false
    }
    this.api.get('product/get-product-list',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.productList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllDriver() {
    this.common.loading = true;
    this.api.get('driver/get-all-drivers').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.driverList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getDockAppointmentReportData() {
    this.common.loading = true;
    // let params ={
    //   startDate :'2024-05-31',
    //   endDate : '2024-06-01',
    //   productId : null,
    //   transporterId : null,
    //   vehicleId : null,
    //   driverId : null,
    //   status : null
    // }
    let params ={
      startDate :this.startDate,
      endDate : this.endDate,
      productId : this.productId,
      transporterId : this.transporterId,
      vehicleId : this.vehicleId,
      driverId : this.driverId,
      status : this.status
    }
    this.api.get('report/get-dock-appointment-report', params).subscribe((res: any) => {
      this.common.loading = false;
      if (res.status) {
        this.dockReportData = res.data;
        this.renderTable();
      }
    },
    (err: any) => {
      this.common.loading = false;
    });
  }


  reset(){
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.endDate = new Date();
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.transporterId = null;
    this.transporterName = null;
    this.driverId = null;
    this.driverName = null;
    this.productId = null;
    this.productName = null;
    this.status = null;
    this.dockReportData = [];
    this.vehicleId = null;
    this.vehicleRc = null;
    this.getDockAppointmentReportData();
    this.renderTable();
  }

    exportAsExcel(data: any[], fileName: string): void {
    // let value = data;
   let value = _.cloneDeep(data);
    // value.map(res => {
    //   res['Trip Date'] = res.trip_date;
    //   res['Total Trips'] = res.trip_count;
    //   res.trip_details.forEach(element => {
    //     res[element.process_name] = element.count;
    //   });
    //   delete res.trip_details;
    //   delete res.trip_date;
    //   delete res.trip_count;
    // });

    const worksheet = XLSX.utils.json_to_sheet(value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dock Appointment Report');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    filesaver.saveAs(blob, fileName + '.xlsx');
  }
}

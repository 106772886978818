<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Visitor Tracking View' | customTranslate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <app-user-tracking-view staffType='Visitor'></app-user-tracking-view>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="most_visit"> -->
<!-- <div class="subtitle">Visitor Tracking</div> -->
<!-- <app-user-tracking-view [staffType]="'Visitor'"></app-user-tracking-view> -->
<!-- </div> -->

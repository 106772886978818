import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  confirmPassword = '';
  newPassword = '';
  constructor(
    private api: ApiService,
    private common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public _location: Location
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Change Password', url: '/user/change-password' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {}
  ngViewInit() {}

  saveData() {
    this.common.loading = true;
    let params = {
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword,
    };
    let apiUrl = 'user/change-password';

    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.resetDetail();
        // alert(res['message']);
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  resetDetail() {
    this.newPassword = '';
    this.confirmPassword = '';
  }

  newPasswordValidator: any;
  confirmPasswordValidator: any;
  formValidator() {
    this.newPasswordValidator = this.common.nullValidator(
      this.newPassword,
      'New Password Mandatory'
    );
    this.confirmPasswordValidator = this.common.nullValidator(
      this.confirmPassword,
      'Confirm Password Mandatory'
    );
    if (
      !this.newPasswordValidator.error &&
      !this.confirmPasswordValidator.error
    ) {
      this.matchPassword();
    }
  }

  backClicked() {
    this._location.back();
  }

  matchPassword() {
    if (this.newPassword == this.confirmPassword) {
      this.saveData();
    } else {
      alert('New password and confirm Password should be same');
    }
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block temp_class" *ngIf="role.user_role=='COMPANYADMIN' && refData == null">
                <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                  placeholder="Choose Plant">
                </ps-auto-suggestion>
              </div>
              <div class="header-block temp_class" *ngIf="role.user_role!='COMPANYADMIN'">
                <h3 class="card-title">
                  {{"plant_hardware view" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">

              <div class="plant_div">
                <div class="plant_row" *ngFor="let item of hardwareMappingData,let i = index">
                  <div class="process_name">
                    <p> {{item.destination_code}}

                      <span class="badge badge-light pull-right m-1"
                        *ngFor="let stagename of item.stageName">{{stagename.stage_name}}</span>
                    </p>
                  </div>

                  <div class="process_list">
                    <div class="process_box" *ngFor="let vec of item.data">
                      <div class="manage_height">

                        <div class="box"
                          [ngClass]="{'green':vec.status == 'ACTIVE','yellow':vec.status == 'INACTIVE','red':vec.status == 'MAINTAINENCE'}">
                          <div class="status_div">
                            <div class="image_hardware">
                              <img [ngClass]="vec.hardware_type == 'DISPLAY'? 'display' : null"
                                [src]="vec.hardware_type == 'TRAFFIC_LIGHT'? 'assets/images/hardware_Traffic_light.gif' : vec.hardware_type == 'WEIGHING_MACHINE'? 'assets/images/Weighting-Bridge-gif.gif':vec.hardware_type == 'RFID_READER'? 'assets/images/rfid-gif.gif' : vec.hardware_type == 'DISPLAY'? 'assets/images/display-hardware.gif': vec.hardware_type == 'SPEAKER'? 'assets/images/Speaker-gif.gif' : vec.hardware_type == 'AT_CONTROLLER'? 'assets/images/hardware.png': 'assets/images/hardware.png'">
                            </div>
                            <i class="fa animate_icon"
                              [ngClass]="{'fa-check-circle':vec.status == 'ACTIVE','fa-sync':vec.status == 'INACTIVE','fa-times':vec.status == 'MAINTAINENCE'}"></i>
                            <span>{{(vec.status | lowercase) | customTranslate}}</span>
                            <img class="change_status" src="https://cdn-icons-png.flaticon.com/512/569/569681.png"
                              (click)="openModal(vec)">
                          </div>
                          <div class="name_div"
                            [ngClass]="{'green':vec.status == 'ACTIVE','yellow':vec.status == 'INACTIVE','red':vec.status == 'MAINTAINENCE'}">
                            <div class="all_sec">
                              <div class="content">
                                <span class="span1">{{"name" | customTranslate}}</span>
                                <span class="span2">: {{vec.hardware_name ? vec.hardware_name:0}}</span>
                              </div>
                              <div class="content">
                                <span class="span1">{{"company" | customTranslate}}</span>
                                <span class="span2">: {{vec.hardware_brand ? vec.hardware_brand:0}}</span>
                              </div>
                              <div class="content">
                                <span class="span1">{{"type" | customTranslate}}</span>
                                <span class="span2">: {{vec.hardware_type ? vec.hardware_type:0}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import _ from 'lodash';

@Component({
  selector: 'app-view-remark',
  templateUrl: './view-remark.component.html',
  styleUrls: ['./view-remark.component.scss'],
})
export class ViewRemarkComponent implements OnInit {
  actionsAndRemarks: any[] = [];
  checklist_title: string = '';
  image_name: string = '';
  rejectRemark:boolean=false;
  rejectMessage:string;
  colorFlag:any=null

  constructor(
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public user: UserService,
    public tableSerivice: TableService,
    private modalService: NgbModal,
    public role: RoleService,
    public alert: AlertService
  ) {}

  ngOnInit(): void {
    this.checklist_title = this.common.params.checklist_title;
    if(this.common.params.rejectedFlag && this.common.params.rejectedFlag==true){
      this.rejectRemark=true;
      this.getRejectedComments();
    }else this.getCheckListActionsAndRemarks();
    console.log("edfghbnjk",this.rejectRemark);
  }

  getCheckListActionsAndRemarks() {
    let params ={
      logId : this.common.params.log_id,
      remarkType : this.common.params.remarkType
    }
    this.common.loading = true;
    this.api.get('checklist-dashboard/view-remark',params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.actionsAndRemarks = _.cloneDeep(res.data);
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
        }
      );
  }

  displayImage(url) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  imageName(url: any) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
  }

  getRejectedComments(){
    this.common.loading = true;
    let params={
      checklistLogId : this.common.params.log_id
    }
    this.api.get('checklist/get-rejected-comments-of-checklist',params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.rejectMessage=res.data[0].comment;
          this.colorFlag= res.data[0].colorFlag;
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
        }
      );
  }
}

<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{'remark'|customTranslate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="remark_des" [innerHTML]="'remark_description'|customTranslate"></div>

<div class="modal-body">
  <div class="form-group" *ngFor="let item of refData;index as i">
    <div class="accorion_class">
      <label for="my-input">{{item.actionName}}</label>
      <div class="icon_right">
        <i class="fa fa-pencil-square-o" (click)="showRemarkBox[i]=!showRemarkBox[i]"></i>
        <i class="fa fa-upload" (click)="showCamera[i]=!showCamera[i]" *ngIf="response[i].fileUrl==null"></i>
      </div>
    </div>

    <div class="accordion_data">
      <div class="input_flex" *ngIf="showRemarkBox[i]==true">
        <textarea class="form-control" (change)="enterResponse($event,item,i,'action')"
          placeholder="Enter Remark..."></textarea>
      </div>

      <div class="webcam_class" *ngIf="showCamera[i]==true">
        <app-camera-component [retake]="retakeImage" (getPicture)="handleImage($event,i)">
        </app-camera-component>
        <div class="retake" *ngIf="response[i].fileUrl!=null">
          <p>Upload Done! <button class="btn btn-warning"
              (click)="retakeImage = !retakeImage;response[i].fileUrl=null">Retake</button>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="accorion_class">
      <label for="my-input">{{"Other"}}</label>
      <div class="icon_right">
        <i class="fa fa-pencil-square-o" (click)="otherRemark=!otherRemark"></i>
        <i class="fa fa-upload" (click)="otherImageUpload=!otherImageUpload"
          *ngIf="response[refData.length].fileUrl==null"></i>
      </div>
    </div>

    <div class="accordion_data">
      <div class="input_flex" *ngIf="otherRemark==true">
        <textarea class="form-control" (change)="enterResponse($event,null,refData.length,'other')"
          placeholder="Enter Remark..."></textarea>
      </div>

      <div class="webcam_class" *ngIf="otherImageUpload==true">
        <app-camera-component [retake]="retakeImage" (getPicture)="handleImage($event,refData.length)">
        </app-camera-component>
        <div class="retake" *ngIf="response[refData.length].fileUrl!=null">
          <p>Upload Done! <button class="btn btn-warning"
              (click)="retakeImage = !retakeImage;response[refData.length].fileUrl=null">Retake</button>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-success" type="button" outline (click)="saveRemark()"
    style="cursor: pointer;">{{'add_remark'|customTranslate}}</button>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_group_access')==true ||userService.permissionMap.get('edit_group_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"group_type" | translate}}</label>
                  <select name="user_type" id="#user_type" class="form-control" [(ngModel)]="group.groupType"
                    (change)="selectedGroupType(group.groupType)">
                    <option *ngFor="let type of groupTypesList[role.user_role]" [value]="type">{{type}}</option>
                  </select>
                  <div class="error" *ngIf="typeValidator && typeValidator.error">
                    <span>{{typeValidator.msg}} </span>
                  </div>
                </div>
                <div
                  *ngIf="(role.user_role == 'AXESTRACK') &&(group.groupType=='COMPANYADMIN'||group.groupType=='COMPANYUSER'||group.groupType=='PLANTADMIN'||group.groupType=='PLANTUSER')"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"company" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="companyData" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyValidator && companyValidator.error">
                    <span>{{companyValidator.msg}} </span>
                  </div>
                </div>

                <div
                  *ngIf="(role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN') && (group.groupType=='PLANTADMIN'||group.groupType=='PLANTUSER')"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"plant" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantValidator && plantValidator.error">
                    <span>{{plantValidator.msg}} </span>
                  </div>
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"group_name" | translate}}</label>
                  <input type="text" class="form-control" name="name" required placeholder="Enter Group Name"
                    [(ngModel)]="group.name" #name="ngModel" autocomplete="off">
                  <div class="error" *ngIf="groupNameValidator && groupNameValidator.error">
                    <span>{{groupNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input"> {{"Head of Group" | translate}} </label>
                  <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedUsers($event)"
                    [data]="userList" display="name" placeholder="Choose User"
                    [preSelected]="selectedUserNames">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"group_status" | translate}}</label>
                  <select name="group_status" id="#group_status" class="form-control" [(ngModel)]="group.status">
                    <option selected="ACTIVE" value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </select>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>
              </div>


              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    translate}}</button>
                  <button class="btn btn-warning" (click)="resetDetail()">{{"reset" | translate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | translate}} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"group_table" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{"serial_no" | translate}}</th>
                    <th>{{"group_name" | translate}}</th>
                    <th>{{"group_type" | translate}}</th>
                    <th>{{"group_status" | translate}}</th>
                    <th>{{"Head Of Group" | translate}}</th>
                    <th>{{"company" | translate}}</th>
                    <th>{{"plant" | translate}}</th>
                    <th class="action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of data; let i = index">
                    <td class="serial">{{i+1}}</td>
                    <td>{{row.groupName}}</td>
                    <td>{{row.groupType}}</td>
                    <td>{{row.groupStatus}}</td>
                    <td>{{formatNames(row.heads)}}</td>
                    <td>{{row.companyName}}</td>
                    <td>{{row.plantName}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewDetails(row)"
                          *ngIf="userService.permissionMap.get('view_group_access')==true">{{"view" |
                          translate}}</button>
                        <button class="btn btn-success" (click)="editRow(row)"
                          *ngIf="userService.permissionMap.get('edit_group_access')==true"> {{"edit" | translate}}
                        </button>
                        <button class="btn btn-danger" (click)="confirmAlert(row)"
                          *ngIf="userService.permissionMap.get('delete_group_access')==true"> {{"delete" | translate}}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { CommonService } from '../../@core/services/common.service';
import { Component } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CryptoService } from 'src/app/@core/services/crypto.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
@Component({
  selector: 'ngx-user',
  styleUrls: [],
  template: `
    <app-notification></app-notification>
    <app-header *ngIf="!common.driverView" [menu]="menu"></app-header>
    <main [ngClass]="{ 'driver-view': common.driverView }">
      <app-breadcrumb *ngIf="!common.driverView"></app-breadcrumb>
      <app-alert-message></app-alert-message>
      <app-screen-loader *ngIf="common.loading"></app-screen-loader>
      <router-outlet></router-outlet>
    </main>
    <app-footer *ngIf="!common.driverView"></app-footer>
  `,
})
export class UserComponent {
  menu = [];
  // menu = menu;
  constructor(
    private api: ApiService,
    public common: CommonService,
    private cryptoService: CryptoService,
    private notificationService: NotificationService
  ) {
    this.getMenu();
    this.notificationService.askPermission();
    this.encrypt()
  }
  getMenu() {
    this.common.loading = true;
    this.api.get('component/get-menu').subscribe(
      (res: any) => {
        if (res.status) {
          this.menu = res.data || [];
          this.common.usermenu = this.menu;
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  async encrypt() {
    let ciphertext = await this.cryptoService.encryptData("70");
    console.log('ciphertext: ', ciphertext);
    let decrypt = await this.cryptoService.decryptData(ciphertext);
    console.log('decrypt: ', decrypt);
  }
}

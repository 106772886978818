import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { AlertViewDetailsModalComponent } from '../alert-view-details-modal/alert-view-details-modal.component';
import { PlantService } from '../plant/plant.service';

@Component({
  selector: 'app-alert-report',
  templateUrl: './alert-report.component.html',
  styleUrls: ['./alert-report.component.scss'],
})
export class AlertReportComponent implements OnInit {
  @ViewChild(DataTableDirective) dataTableElement: DataTableDirective;
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 3, 'Alert Report');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    public table: TableService,
    public common: CommonService,
    public api: ApiService,
    public alert: AlertService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public modalService: NgbModal,
    public role: RoleService,
    public plantService: PlantService
    ) {
      let breadcumbs = [
        { name: 'Home', url: '/user/dashboard' },
        { name: 'Reports', url: null },
        { name: 'Alert Report', url: '/user/alert-report' },
      ];
      this.breadcrumbService.getBreadcrumbs(breadcumbs);
    }

    startDate: any = new Date(new Date().setDate(new Date().getDate() - 7));
    endDate: any = new Date();
    dateWiseAlerts: any[] = [];
    alertTypeList: any[] = [];
    alertConditionsList: any[] = [];
    alertTypeId: any = -1;
    alertTypeName: any = null;
    alertEntity: any = null;
    alertCondition: any = null;
    groupedDataByStatus: any[] = [];
    alertCountByStatus: any;
    totalAlerts: number[] = [0, 0, 0, 0, 0];
    alertEntityList: any;
    plantData: any = [];
    plantId: number = null;
    headers:any[]=[];
    headerFlag=true;

  ngOnInit() {
    this.findAlertEntity();
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.getAlertTypeList();
      this.getAlertCountByStatus();
    }
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.getAlertCountByStatus();
    this.getAlertTypeList();
  }

  onDateChange() {
    this.getAlertCountByStatus();
  }

  onSelectAlertType(event: any) {
    this.alertTypeId = event.id;
    this.alertTypeName = event.type_name;
    this.getAlertCountByStatus();
  }

  onSelectAlertEntity(event: any) {
    this.alertEntity = event.name;
    this.alertConditionsList = event.condition_data;
    this.alertCondition = null;
    this.getAlertCountByStatus();
  }

  onSelectAlertCondition(event: any) {
    this.alertCondition = event.value;
    this.getAlertCountByStatus();
  }

  getAlertTypeList() {
    let params = {
      plantId: this.role.user_role == 'AXESTRACK' ? -1 : this.plantId,
    };
    this.api.get('alert/get_alert_type', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertTypeList = res.data;
          this.common.loading = false;
        }
      },
      (err) => {
        this.common.loading = false;
      }
    );
  }

  getAlertCountByStatus() {
    if (this.startDate > this.endDate) {
      this.alert.warn('Start Date Should be Smaller than End Date!!');
      return;
    }
    this.common.loading = true;
    let params = {
      startDate: this.startDate,
      endDate: this.endDate,
      plantId: this.plantId,
      alertTypeId: this.alertTypeId != null ? this.alertTypeId : -1,
      alertEntity: this.alertEntity != null ? this.alertEntity : '',
      alertCondition: this.alertCondition != null ? this.alertCondition : '',
    };
    this.api.get('report/get-alerts-count-by-status', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        res.data.forEach((element) => {
          element.alertreportdata = JSON.parse(element.alertreportdata);
        });
        this.alertCountByStatus = res.data;
        if(this.headerFlag){
          this.headers=this.alertCountByStatus[0].alertreportdata[0].data;
          this.headerFlag=false;
        }
        this.totalAlerts = [0, 0, 0, 0, 0];
        for (const item of this.alertCountByStatus[0].alertreportdata) {
          this.totalAlerts[0] += item.data[0].count;
          this.totalAlerts[1] += item.data[1].count;
          this.totalAlerts[2] += item.data[2].count;
          this.totalAlerts[3] += item.data[3].count;
          this.totalAlerts[4] += item.data[4].count;
        }
        this.renderTable();
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    );
  }

  findAlertEntity(){
    this.api.get('alert/get_alert_conditions').subscribe((res : any) => {
      this.common.loading = false;
      this.alertEntityList = res.data;
    },
    (err: any) => {
      console.error('Error:', err);
      this.common.loading = false;
    })
  }
  viewDetails(row: any) {
    let activeModal = this.modalService.open(AlertViewDetailsModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      viewDate: row.date,
      data: row.data,
      alertTypeId: this.alertTypeId,
      alertEntity: this.alertEntity,
      alertCondition: this.alertCondition,
    };
  }

  reset() {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.endDate = new Date();
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.alertTypeId = -1;
    this.alertTypeName = null;
    this.alertEntity = null;
    this.alertCondition = null;
    this.dateWiseAlerts = [];
    this.getAlertCountByStatus();
  }
}

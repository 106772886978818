import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-field-tolerance-mapping',
  templateUrl: './field-tolerance-mapping.component.html',
  styleUrls: ['./field-tolerance-mapping.component.scss'],
})
export class FieldToleranceMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'List of Tolerance Mapping');
  title = 'Field Tolerance Mapping';
  btn = 'save';
  toleranceFieldMapping = [];
  toleranceMapping = {
    id: null,
    fieldName: '',
    fieldId: null,
    toleranceType: '0',
    toleranceValue: null,
  };
  getSelectionFields = [];
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  constructor(
    public common: CommonService,
    public api: ApiService,
    public table: TableService,
    public alertService: AlertService,
    public userService: UserService,
    public breadcrumbService: BreadcrumbService,
    public modalService: NgbModal
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Fields', url: null },
      { name: 'Field Tolerance Mapping', url: '/user/field-tolerance-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    this.getSelectionField();
    this.getToleranceFieldMapping();
  }

  ngOnInit(): void {}
  getSelectionField() {
    this.common.loading = true;
    this.api.post('fields/allMasterFields',{}).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.getSelectionFields = res.data;
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }
  selectedField(event) {
    (this.toleranceMapping.fieldId = event.master_field_id),
      (this.toleranceMapping.fieldName = event.field_name);
  }
  resetDetail() {
    this.btn = 'save';
    this.toleranceMapping = {
      id: null,
      fieldName: '',
      fieldId: null,
      toleranceType: '0',
      toleranceValue: null,
    };
  }

  saveToleranceMapping() {
    this.common.loading = true;
    let params = {
      id: this.toleranceMapping.id,
      selectField: this.toleranceMapping.fieldId,
      toleranceType: this.toleranceMapping.toleranceType,
      toleranceValue: this.toleranceMapping.toleranceValue,
    };
    this.api.post('fields/add-field-tolerance', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.resetDetail();
        this.getToleranceFieldMapping();
      },
      (err) => {
        this.common.loading = false;
      }
    );
  }

  getToleranceFieldMapping() {
    this.common.loading = true;
    this.api.get('fields/get-tolerance-field-mapping').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.toleranceFieldMapping = res.data;
        this.renderTable();
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  toleranceEdit(item) {
    this.btn = 'update';
    this.toleranceMapping.id = item._id;
    this.toleranceMapping.fieldName = item.field_name;
    this.toleranceMapping.toleranceType = item.tolerance_type;
    this.toleranceMapping.toleranceValue = item.tolerance;
    this.toleranceMapping.fieldId = item.master_field_id;
  }
  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'View Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }
  confirmDeleteAction(row?: any) {
    this.common.params = {
      title: 'Delete Action ',
      description:
        `<b>&nbsp;` + 'Are you sure, Do you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deletetolerance(row);
      }
    });
  }

  deletetolerance(item) {
    this.api
      .get('fields/delete-tolerance-field-mapping?id=' + item._id)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.getToleranceFieldMapping();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }
}

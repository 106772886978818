<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_shift_access')==true ||userService.permissionMap.get('edit_shift_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row shift_allocation">

                <div class="form-group col-xl-5 col-lg-5 col-sm-12">
                  <label for="my-input" class="required">{{"no._of shift" | customTranslate}} </label>
                  <ngx-slider [(value)]="shift.num" [options]="options" (mouseup)="getCount(shift.num)"></ngx-slider>

                </div>

                <div class="form-group col-xl-5 col-lg-5 col-sm-12 offset-xl-2 offset-lg-2">
                  <label for="my-input" class="required">{{"each_shift_duration" | customTranslate}}</label>
                  <ngx-slider [(value)]="shift.shiftDuration" [options]="durationOptions"
                    (mouseup)="getDuration(shift.shiftDuration)"></ngx-slider>
                  <div class="error" *ngIf="durationValidator && durationValidator.error">
                    <span>{{durationValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"process->stage" | customTranslate}} </label>
                  <ps-auto-suggestion isMultiSelect="true" (unSelected)="unselectedProcess($event)"
                    (onSelected)="selectedProcess($event)" [data]="process" display="order_name"
                    placeholder="Choose Process->Stage" [preSelected]="preSelectedProcess">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error && btn=='save'">
                    <span>{{processValidator.msg}} </span>
                  </div>
                  <div class="error" *ngIf="processValidatorUpdate && processValidatorUpdate.error && btn=='update'">
                    <span>{{processValidatorUpdate.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"shift_name" | customTranslate}}</label>
                  <input type="text" class="form-control" (onSelected)="selectshift($event)"
                    [(ngModel)]="shift.shiftName" name="shiftName" required placeholder="Enter Shift Name" />
                  <div class="error" *ngIf="shiftNameValidator && shiftNameValidator.error">
                    <span>{{shiftNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"shift_status" | customTranslate}}</label>
                  <select name="shift_status" id="#shift_status" class="form-control" [(ngModel)]="shift.shiftStatus">
                    <option selected="ACTIVE" value="ACTIVE">{{"active" | customTranslate}}</option>
                    <option value="INACTIVE">{{"inactive" | customTranslate}}</option>
                  </select>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <form action="/action_page.php">
                    <label for="appt">{{"select_shift start time" | customTranslate}}</label>
                    <input type="time" class="form-control" name="shiftStartTime" [(ngModel)]="shift.shiftStartTime">
                  </form>

                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="resetDetail()"> {{"reset" | customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}} </button>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"list_of shifts" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <!-- table -->
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{"s.no." | customTranslate}} </th>
                    <th>{{"name" | customTranslate}}</th>
                    <th>{{"shift_start at" | customTranslate}}</th>
                    <th>{{"shift_end at" | customTranslate}}</th>
                    <th>{{"mapped_on_stages"}}</th>
                    <th>{{"status" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of data; let i = index">
                    <td class="serial">{{i+1}}</td>
                    <td>{{row.shiftName}}</td>
                    <td>{{row.shiftStartTime}}</td>
                    <td>{{row.shiftEndTime}}</td>
                    <td>
                      <ng-container *ngFor="let item of row.info">
                        {{ item.order_name }} <br>
                      </ng-container>
                    </td>
                    <td>{{row.shiftStatus}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewDetails(row)"
                          *ngIf="userService.permissionMap.get('view_shift_access')==true"> {{"view" | customTranslate}}
                        </button>
                        <button class="btn btn-success" (click)="editShift(row)"
                          *ngIf="userService.permissionMap.get('edit_shift_access')==true"> {{"edit" | customTranslate}}
                        </button>
                        <button class="btn btn-danger" (click)="deleteShift(row.shiftId)"
                        *ngIf="userService.permissionMap.get('delete_shift_access')==true"> {{"delete" | customTranslate}}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header" style="align-items: baseline;">
              <div class="header-block">
                <h4 class="card-title">
                  {{'CheckList Action Mapping'|customTranslate}}
                </h4>
              </div>

              <div class="col-md-3 pb-2">
                <ps-auto-suggestion (onSelected)="selectedCheckList($event)" [data]="checkList"
                  display="checklist_title" placeholder="Select CheckList" name="checklist_title">
                </ps-auto-suggestion>
              </div>
            </div>
            <div class="card-body mx-2 my-2" style="background-color: #f1f1f1;border-radius: 5px;">
              <div class="row">
                <div class="col-md-3">
                  <div class="form_layout" style="text-align: center;">

                    <div class="fields_header">
                      <i class="fa fa-search search_field" aria-hidden="true"></i>
                      <input class="search_input" name="searchAction" id="searchAction" type="text"
                        (input)="searchActionName($event)" placeholder="Action Name" />
                    </div>

                    <div class="fields example-list" cdkDropList [cdkDropListData]="actionsList"
                      [cdkDropListConnectedTo]="[allActionsList]" *ngIf="actionsList.length">
                      <div *ngFor="let data of actionsList;let i=index;last as islast" cdkDrag class="drag-element">
                        <div class="fields_name">
                          {{data.actionName}}
                        </div>
                      </div>
                    </div>
                    <img src="assets/images/no-record.png" alt="" style="width: 60%;margin-top: 35%;" alt=""
                      *ngIf="!actionsList.length">
                  </div>
                </div>
                <div class="col-md-9 pl-0">
                  <div class="form_layout">
                    <div cdkDropListGroup class="row pb-3" style="min-height: 300px">
                      <div cdkDropList [cdkDropListData]="checkListActions" class="example-list col-md-12"
                        #allActionsList="cdkDropList" (cdkDropListDropped)="drop($event)">
                        <ng-container *ngFor="let item of checkListActions; index as i;last as islast">
                          <div class="example-box example-box1 setting-box" cdkDrag>
                            <!-- [ngClass]="{'color-when-not-null': item.database_linked_id != null, 'color-when-null': item.database_linked_id == null}" -->

                            <span> {{i+1}}: </span>
                            <span style="width:45%;text-align: center;">{{item.actionName}}</span>
                            <span class="priority"><input type="checkbox" [checked]="item.is_mandatory"
                                (change)="item.is_mandatory=!item.is_mandatory" />{{'mandatory'|customTranslate}}</span>
                            <i class="fa fa-times remove-element" aria-hidden="true" (click)="removeField(i)">
                            </i>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div *ngIf="checkListActions.length == 0" style="position: relative;margin-top: -135px;z-index: 99999;">
                      <app-data-not-found></app-data-not-found>
                    </div>
                  </div>
                </div>
              </div>
              <div class="stage-setting-footer">
                <div class="btn-block d-flex  justify-content-end">
                  <button class="btn ml-2 btn-warning text-uppercase" (click)="resetData()"> {{'reset'|customTranslate}}
                  </button>
                  <button class="btn ml-2 btn-success text-uppercase" (click)="save()">{{'save'|customTranslate}}
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

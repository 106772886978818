import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { PlantService } from '../plant/plant.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-method-communication-mapping',
  templateUrl: './method-communication-mapping.component.html',
  styleUrls: ['./method-communication-mapping.component.scss'],
})
export class MethodCommunicationMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'Method Management');
  methodsList: any = [];
  methodName = null;
  array = Array;
  companyId = null;
  companyName = null;
  plantId = null;
  plantName = null;
  details = {
    companyId: null,
    companyName: null,
    plantId: null,
    plantName: null,
  };
  methodCommunicationList: any = [];
  methodExecution = 0;
  communicationTypeId = null;
  communicationTypeName = null;
  communicationSettingId = null;
  communicationSettingName = null;
  communicationTemplateId = null;
  communicationTemplateName = null;
  status = 1;
  methodId = null;
  communicationTypeList: any = [];
  communicationSettingList: any = [];
  communicationTemplateList: any = [];
  methodNameValidator: any;
  methodType = 0;
  btnTxt = 'save';
  companyList: any = [];
  plantList: any = [];
  methodSettings: any = [];
  methodSettingName: any = null;
  methodSettingId: any = null;
  plantNameValidator: any = null;
  companyNameValidator: any = null;
  communicationTypeValidator: any = null;
  communicationTemplateValidator: any = null;
  communicationSettingValidator: any = null;
  masterField: any[] = [{}];
  masterList: any[] = [{}];
  dataMethodNameAction: any[] = [
    {
      style: 'background-color:#383535',
      border: 'border-color:rgb(148 142 142)',
    },
  ];

  constructor(
    public common: CommonService,
    private modalService: NgbModal,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public plantService: PlantService,
    public role: RoleService,
    public alert: AlertService,
    private table: TableService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Methods', url: null },
      {
        name: 'Method Communication Mapping',
        url: '/user/method-communication-mapping',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.getMethodCommunicationData();
    if (this.role.user_role != 'AXESTRACK') {
      this.companyId = this.userService._companyId;
      this.plantId = this.userService._loggedInUser.plantId;
      this.getAllCommunicationMethods();
    } else {
      this.plantId = this.userService._loggedInUser.plantId;
    }
  }

  ngOnInit(): void {}
  getActiveTab(event) {
    this.dtOptions = this.table.options(10, 8, 'Method Management');
    this.dtTrigger.unsubscribe();
    this.dtTrigger.next();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getCompanyList();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance:any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  addValues(index: any) {
    this.masterField.push({});
    this.masterList.push({});
  }

  reset() {
    if (this.role.user_role == 'AXESTRACK') {
      this.companyId = null;
      this.companyName = null;
      this.plantId = null;
      this.plantName = null;
    }
    this.masterField = [{}];
    this.masterList = [{}];
    this.methodExecution = 0;
    this.methodId = null;
    this.methodName = null;
    this.companyId == null ? (this.plantList = null) : true;
    this.communicationTypeId = null;
    this.communicationTypeName = null;
    this.communicationSettingId = null;
    this.communicationSettingName = null;
    this.communicationTemplateId = null;
    this.communicationTemplateName = null;
    this.status = 1;
    this.methodNameValidator = null;
    this.methodType = this.role.user_role == 'AXESTRACK' ? 1 : 0;
    this.btnTxt = 'save';
    this.communicationSettingList = [];
    this.communicationTemplateList = [];
    this.methodSettingId = null;
    this.methodSettingName = null;
    this.plantNameValidator = null;
    this.companyNameValidator = null;
    this.communicationTypeValidator = null;
    this.communicationTemplateValidator = null;
    this.communicationSettingValidator = null;
    this.methodNameValidator = null;
    this.communicationTypeList = null;
    this.plantId != null ? this.getAllCommunicationMethods() : true;
  }

  formValidator() {
    this.methodNameValidator = this.common.nullValidator(
      this.methodName,
      'Method Name is Mandatory'
    );
    this.plantNameValidator = this.common.nullValidator(
      this.plantId,
      'Plant Name is Mandatory'
    );
    this.companyNameValidator = this.common.nullValidator(
      this.companyName,
      'Company Name is Mandatory'
    );
    this.communicationTypeValidator = this.common.nullValidator(
      this.communicationTypeId,
      'Communication Type is mandatory'
    );
    this.communicationTemplateValidator = this.common.nullValidator(
      this.communicationTemplateId,
      'Communication Template is mandatory'
    );
    this.communicationSettingValidator = this.common.nullValidator(
      this.communicationSettingId,
      'Communication Setting is mandatory'
    );
    if (
      !this.methodNameValidator.error &&
      (this.role.user_role == 'AXESTRACK' || !this.plantNameValidator.error) &&
      !this.communicationSettingValidator.error &&
      !this.communicationTemplateValidator.error &&
      !this.communicationTypeValidator.error
    ) {
      this.save();
    } else if (
      (this.communicationSettingValidator.error ||
        this.communicationTemplateValidator.error ||
        this.communicationTypeValidator.error) &&
      !this.methodNameValidator.error
    ) {
      this.alert.warn(
        this.communicationTypeValidator.msg ||
          this.communicationSettingValidator.msg ||
          this.communicationTemplateValidator.msg
      );
    }
  }

  save() {
    let arr: any[] = [];
    this.masterField.forEach((item: any) => {
      item.plantId = this.plantId;
      item.methodId = this.methodId;
      arr.push(item);
    });

    let params = {
      methodId: this.methodId,
      communicationData: arr,
      plantId: this.plantId,
    };

    this.common.loading = true;
    this.api
      .post('communication/save_method_communication_mapping', params, null)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.getMethodCommunicationData();
            if (res.status) {
              this.alert.success(res.message);
            } else {
              this.alert.success(res.message);
            }
          }
          this.reset();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  selectMethodName(event) {
    this.methodId = event.methodid;
    this.methodName = event.methodname;
    this.getCommunicationType();
  }

  async selectCommunicationType(event, index) {
    this.communicationTypeId = event.communicationTypeId;
    this.communicationTypeName = event.communicationTypeName;
    this.masterField[index] = {
      communicationTypeName: this.communicationTypeName,
      commTypeId: this.communicationTypeId,
    };
    await this.getCommunicationTemplate(this.communicationTypeId);
    await this.getCommunicationSetting(this.communicationTypeId, index);
  }

  selectCommunicationSetting(event, index) {
    this.communicationSettingId = event.id;
    this.communicationSettingName = event.title;
    this.masterField[index] = {
      communicationTypeName: this.masterField[index].communicationTypeName,
      commTypeId: this.masterField[index].commTypeId,
      commSettingId: this.communicationSettingId,
      communicationSettingName: this.communicationSettingName,
      commTemplateId: this.masterField[index].commTemplateId,
      communicationTemplateName:
        this.masterField[index].communicationTemplateName,
    };
  }

  selectCommunicationTemplate(event, index) {
    this.communicationTemplateId = event.id;
    this.communicationTemplateName = event.templateTitle;
    this.masterField[index] = {
      communicationTypeName: this.masterField[index].communicationTypeName,
      commTypeId: this.masterField[index].commTypeId,
      commSettingId: this.masterField[index].commSettingId,
      communicationSettingName:
        this.masterField[index].communicationSettingName,
      commTemplateId: this.communicationTemplateId,
      communicationTemplateName: this.communicationTemplateName,
    };
  }

  selectCompany(event) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantListByCompanyId(this.companyId);
  }

  selectPlant(event) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getCommunicationType();
    this.getAllCommunicationMethods();
  }

  getCommunicationType(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api
        .get('communication/list_communicationType')
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.common.loading = false;
              this.communicationTypeList = res.data;
            }
            resolve(this.communicationSettingList);
          },
          (err) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
    });
  }

  getCommunicationSetting(communicationTypeId, index): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api
        .get(
          'communication/list-communication-setting?plantId=' + this.plantId,
          null
        )
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.common.loading = false;
              this.communicationSettingList = [];
              res.data.forEach((element) => {
                if (element.typeid == communicationTypeId) {
                  this.communicationSettingList.push(element);
                }
                this.masterList[index] = {
                  setting: this.communicationSettingList,
                  template: this.communicationTemplateList,
                };
              });
              resolve(this.communicationSettingList);
            }
          },
          (err) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
    });
  }

  getCommunicationTemplate(communicationTypeId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api
        .get(
          'communication/get-template-by-typeid?communicationTypeId=' +
            communicationTypeId +
            '&plantId=' +
            this.plantId
        )
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.common.loading = false;
              this.communicationTemplateList = res.data;
              resolve(this.communicationTemplateList);
            }
          },
          (err) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
    });
  }

  getAllCommunicationMethods() {
    this.common.loading = true;
    this.api
      .get(
        'communication/get_communication_methods?plantId=' + this.plantId)
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            if (res.data) {
              this.methodsList = res.data;
              // this.renderTable();
            }
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getMethodCommunicationData() {
    this.common.loading = true;
    this.api
      .get('communication/get_method_communication')
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            if (res.data) {
              this.methodCommunicationList = res.data;
              this.renderTable();
            }
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getCompanyList() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getPlantListByCompanyId(companyId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api.get('plant/all_plants?id=' + companyId).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.plantList = res.data;
          resolve(this.plantList);
        },
        (err) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    });
  }

  async editMethod(item) {
    this.common.gotoTop();
    this.reset();
    await this.getPlantListByCompanyId(item.companyId);
    this.plantId = item.plantId;
    for (let i = 0; i < item.data.length; i++) {
      await this.getCommunicationType();
      await this.getCommunicationTemplate(item.data[i].commTypeId);
      await this.getCommunicationSetting(item.data[i].commTypeId, i);
    }

    this.methodId = item.methodId;
    this.methodName = item.methodName;
    this.communicationTypeId = item.commTypeId;
    this.communicationTypeName = item.communicationTypeName;
    this.communicationSettingId = item.commSettingId;
    this.communicationSettingName = item.communicationSettingName;
    this.communicationTemplateId = item.commTemplateId;
    this.communicationTemplateName = item.communicationTemplateName;
    this.masterField = item.data;
    this.companyId = item.companyId;
    this.companyName = item.companyName;
    this.plantName = item.plantName;
    this.btnTxt = 'update';
  }

  viewMethod(item?: any) {
    let updatedItem = item;
    // delete updatedItem['id'];
    // delete updatedItem['userInfo'];
    item.data.forEach((res, index) => {
      updatedItem[index] = res;
    });
    this.common.params = { details: [updatedItem], title: 'Method Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Driver ',
      description: `<b>&nbsp;` + 'Are you sure want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteMethod(row);
      }
    });
  }
  deleteMethod(row) {
    this.plantId = row.plantId;
    this.methodId = row.methodId;
    this.common.loading = true;
    this.api
      .get(
        'communication/delete_method_communication_mapping?methodId=' +
          this.methodId +
          '&plantId=' +
          this.plantId
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.alert.success(res.message);
          this.getMethodCommunicationData();
          this.reset();
        },
        (err) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { local } from 'd3-selection';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private route: Router){}
  lastAllowedUrl:string
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(state.url.startsWith('/visitor')){
        return this.isVisitorLoggedin();
      }
      else if(this.isLoggedin() && localStorage.getItem('USER_TOKEN') != null){
        return true;
      }else if(localStorage.getItem('VISITOR_TOKEN')!='null' && localStorage.getItem('VISITOR_TOKEN')!=null){
        this.route.navigate(['/auth/visitor-login']);
      }
      else{
        this.route.navigate(['/auth/login'],{queryParams : {'re':state.url, 'authkey': route.queryParams.authkey || null}});
      return false;
    }
  }
  isLoggedin() {
    return (
      localStorage.getItem('USER_TOKEN') != 'null'
    );
  }
  isVisitorLoggedin() {
    return (
      localStorage.getItem('VISITOR_TOKEN') != 'null'
    );
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { ProcessService } from 'src/app/components/process/process.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-trip-tracking-logs',
  templateUrl: './trip-tracking-logs.component.html',
  styleUrls: ['./trip-tracking-logs.component.scss','../trip-comment-trail/trip-comments/trip-comments.component.scss']
})
export class TripTrackingLogsComponent implements OnInit {

  @Input()txnStageId;

  viewLogData:any=[];

  constructor(
    public api: ApiService,
    public common: CommonService,
    public table: TableService,
    public alertService: AlertService,
    public modal: NgbModal,
    public processService: ProcessService,
    public breadcrumbService: BreadcrumbService,
    public role: RoleService,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
    this.getTrackingLogs();
  }


  getTrackingLogs(){
    this.common.loading = true;
    this.api.get('hardware/get-transaction-hardware-log',{txnStageId:this.txnStageId}).subscribe(
      (res: any) => {
        if (res.status) {
          this.viewLogData = res.data.length == 0 ? [] :JSON.parse(res.data[0].data);
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  closeModal(){
    this.modalService.dismissAll();
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { PlantService } from '../plant/plant.service';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import lodash  from 'lodash';


@Component({
  selector: 'app-zone-stage-mapping',
  templateUrl: './zone-stage-mapping.component.html',
  styleUrls: ['./zone-stage-mapping.component.scss']
})

export class ZoneStageMappingComponent implements OnInit {

  //view child Table Properties

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Stage Zone Mapping List');

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }


  // Declaring Global variables
  plantId:any=null;
  companyId:any=null;
  zoneStageMappings:any[]=[];
  companyList:any[]=[];
  companyName:String='';
  plantList:any[] = [];
  plantName:String='';
  buttonName:any= 'save';
  zoneData:any[] = [];
  zoneName:String='';
  zoneId:number=null;
  stageList:any[]=[];
  selectedStage:any;
  isEdit:boolean=false;
  zoneStageMappingId:any=null;
  groupedData:Record<string,any[]>;
  groupedDataArray:any[]=[];


  //constructor

  constructor(
    public userService: UserService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public role: RoleService,
    public plantService : PlantService,
    public alert : AlertService,
    // public formateName:FormatNamesPipe
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Zone', url: null },
      { name: 'Zone Stage Mapping', link: '/user/zone-stage-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
   }

   //ng hooks

   ngOnInit(): void {
    this.getStageZoneMapping();
    if(this.role.user_role=='AXESTRACK'){
      this.getCompany();
    }
    else if(this.role.user_role=='COMPANYADMIN'){
      this.getPlants(this.userService._loggedInUser.companyId);
    }
    else if(this.role.user_role!='AXESTRACK'&&this.role.user_role!='COMPANYADMIN'){
      this.getZones(this.userService._loggedInUser.plantId);
      this.getStages(this.userService._loggedInUser.plantId);
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  //Custome Functions

  selectedCompany(event:any){
    this.companyId=event.companyId;
    this.companyName = event.companyName;
    this.getPlants(event.companyId);
  }

  selectedPlant(event:any){
    this.plantId=event.plantId;
    this.plantName = event.plantName;
    this.zoneId=null;
    this.zoneName=null;
    this.selectedStage=[];
    this.stageList=[];
    this.getZones(this.plantId);
    this.getStages(this.plantId);
  }

  selectedZone(event:any){
  console.log('event: ', event);
    this.zoneName=event.zone_name;
    this.zoneId=event.id;
  }

  selectedStages(event:any){
  console.log('event: ', event);
  this.selectedStage=event.map((e)=>({
    stageName:e.stageName,
    stageId:e.stageId
   }));
  console.log('this.selectedStageIds: ', this.selectedStage);
  }

  getCompany(){
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
      );
    }


    getPlants(id?: any): Promise<any> {
      return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        id: id,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.plantList = res.data;
            console.log('this.plantList: ', this.plantList);
            resolve(this.plantList);
          }
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
    }


  getZones(id?:any){
    this.common.loading = true;
    let params={
      plantId:this.plantId,
      allZones : false
    }
    this.api.get("zone/get-all-zone-stage",params).subscribe(
      (res:any)=>{
        this.common.loading = false;
        this.zoneData=res.data;
        console.log('this.zoneData: ', this.zoneData);
      },
      (err:any)=>{
        console.error('Error', err);
        this.common.loading = false;
      }
    );

  }

  getStages(id?:any): Promise<any>{
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    let params={
      plantId:this.plantId,
      allStage:false

    }
    this.api.get("stage/get-all-stage",params).subscribe(
      (res:any)=>{
        this.common.loading = false;
        this.stageList=res.data;
        console.log('this.stageList: ', this.stageList);
        resolve(this.stageList);
      },
      (err:any)=>{
        console.error('Error', err);
        this.common.loading = false;
        reject(null);
      }
    );
  });
}

  // formatNames(dataArray: any): string {
  //   if(dataArray && dataArray.length>0){
  //     return dataArray.map((item) => item.stage_name).join(', ');
  //   }
  //   else{
  //     return "";
  //   }
  // }

  getStageZoneMapping(){
    this.common.loading = true;

    this.api.get("zone/get-zone-stage-mapping").subscribe(
      (res:any)=>{
        this.common.loading = false;
        this.zoneStageMappings=res.data;
        console.log('this.zoneStageMappings: ', this.zoneStageMappings);
        this.groupedData=lodash.groupBy(this.zoneStageMappings,'zone_id');
        console.log('this.groupedData: ', this.groupedData);
        this.groupedDataArray=Object.values(this.groupedData);
        this.renderTable();
      },
      (err:any)=>{
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  saveStageZoneMapping(){
    const stageIds = this.selectedStage.map(stage => stage.stageId);
    let params= {
      id:this.zoneStageMappingId,
      zoneId:this.zoneId,
      stageIds:stageIds,
    }
    this.api.post('zone/save-zone-stage-mapping',params).subscribe(
      (res: any) => {
        if(res.status){
            this.alert.success(res.message);
            this.reset();
        }
        this.common.loading = false;
        this.getStageZoneMapping();
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );

  }

  reset(){
    this.plantId=null;
    this.companyId=null;
    this.plantList=null;
    this.companyName = null;
    this.plantName = null;
    this.buttonName='save';
    this.isEdit=false;
    if(this.role.user_role=='COMPANYADMIN' || this.role.user_role=='AXESTRACK'){
      this.zoneData= [];
    }
    this.zoneName='';
    this.zoneId=null;
    this.stageList=[];
    this.selectedStage=[];
    this.zoneStageMappingId=null;
    this.plantNameValidator=null;
    this.companyNameValidator=null;
    this.zoneValidator=null;
    this.stageValidator=null;
    this.role.user_role=='COMPANYADMIN'? this.getPlants(this.userService._loggedInUser.company):null;
    console.log('this.getPlants: ', this.plantList);

  }

    viewZoneStageMappingDetails(row?:any){
    console.log('row: ', row);
      const MappingDetails={
        company_name: row[0].company_name,
        plant_name: row[0].plant_name,
        stage_name: row[0].stage_name,
        manufacturer_description: row.manufacturer_description,
        manufacturer_name: row.manufacturer_name,
      }
      this.common.params = { details: [row], title: 'Zone Stage Mapping Details' };
      const activeModal = this.modalService.open(ViewDetailsComponent, {
        size: 'lg',
      });
    }


    async editZoneStageMapping(row?:any){
    console.log('row: ', row);
     this.reset();
     this.common.gotoTop();
     this.role.user_role=='AXESTRACK'? await this.getPlants(row[0].company_id):(this.role.user_role=='COMPANYADMIN'?this.getPlants(this.userService._loggedInUser.company_id):null);
     this.buttonName='update';
     this.isEdit=true;
     this.companyId=row[0].company_id;
     this.plantId=row[0].plant_id;
     this.plantName = row[0].plant_name;
     this.companyName = row[0].company_name;
     this.getZones(row[0].plant_id);
     this.zoneId=row[0].zone_id;
     this.zoneName=row[0].zone_name;
     await this.getStages(row[0].plant_id);
     this.selectedStage=row.map((e)=>({
      stageName:e.stage_name,
      stageId:e.stage_id
     }));
     this.zoneStageMappingId=row[0].zone_id;


  }

  confirmDeleteZoneStageMapping(row?:any){
    this.common.params = {
      title: 'Delete Zone Stage Mapping ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row[0].zone_name +
        ' ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });
  }

  delete(row: any) {
    let param={
      zoneId:row[0].zone_id
    }
    this.api.get("zone/delete-zone-stage-mapping",param).subscribe(
      (res:any)=>{
        this.common.loading=false;
        if(res.status){
        console.log('res.status: ', res.status);
          this.alert.success(res.message);
          this.getStageZoneMapping();
        }
      },
      (err:any)=>{
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }



  plantNameValidator:any=null;
  companyNameValidator:any=null;
  zoneValidator:any=null;
  stageValidator:any=null;

  formValidator(){
    this.plantNameValidator=this.common.nullValidator(
      this.plantId,'Plant Name Mandatory'
      );
      console.log('this.plantId: ', this.plantId);
    this.companyNameValidator=this.common.nullValidator(
      this.companyId,'Company Name Mandatory'
      );
      console.log('this.companyId: ', this.companyId);
    this.zoneValidator=this.common.nullValidator(
      this.zoneId,'Zone Name Mandatory'
      );
      console.log('this.zoneId: ', this.zoneId);
    this.stageValidator=this.common.nullValidator(
      this.selectedStage,'Select at least one Stage'
    );

    if(this.role.user_role=='AXESTRACK'){
      if(!this.plantNameValidator.error&&!this.companyNameValidator.error&&!this.zoneValidator.error&&!this.stageValidator.error){
        this.saveStageZoneMapping();
        }
    }
    else if(this.role.user_role=='COMPANYADMIN'){
      if(!this.plantNameValidator.error&&!this.zoneValidator.error&&!this.stageValidator.error){
        this.saveStageZoneMapping();
        }
    }
    else{
      console.log('!this.zoneValidator.error&&!this.stageValidator.error: ', !this.zoneValidator.error,!this.stageValidator.error);
      if(!this.zoneValidator.error&&!this.stageValidator.error){
        this.saveStageZoneMapping();
        }
    }

  }

}

import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { StageService } from '../stage/stage.service';
import { StageTypeService } from '../stage-type/stage-type.service';
import { ProcessService } from '../process/process.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { ApiService } from 'src/app/@core/services/api.service';

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss'],
})
export class StageComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Stage List');
  processName: any[] = [];
  selectedProcesses: any[] = [];
  ngAfterViewInit() {
    this.dtTrigger.next();
    if(this.role.user_role != 'COMPANYADMIN')this.getAllStage();
    else this.getPlantList();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  stage = {
    stageId: '',
    stageName: '',
    stageStatus: 'ACTIVE',
    stageDescription: '',
    stageType: '',
    status: '',
    minVechileCount: 0,
    maxVechileCount: 0,
    isGPSEnabled:false
  };
  isGPSEnabled:boolean=false;
  processId: any;
  buttonName = 'save';
  title = 'Add Stage';
  submitted = false;
  responseMessage = '';
  responseData: any[] = [];
  stageList: any[] = [];
  stageTypeList: any[] = [];
  processList: any[] = [];
  plantId:any;
  plantName:any;
  plantNameValidator:any;
  plantList:any[];
  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    private stageService: StageService,
    private stageTypeService: StageTypeService,
    private processService: ProcessService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public alertService: AlertService,
    public role:RoleService,
    public api:ApiService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Stage', url: null },
      { name: 'Stage Management', link: '/user/stage_list' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getAllStageType();
    // this.getAllStage();
    if(this.role.user_role != 'COMPANYADMIN')this.getAllProcess();
  }
  // Save Stage
  saveStage(): void {
    let data = {
      stageId: this.stage.stageId,
      stageName: this.stage.stageName,
      stageDescription: this.stage.stageDescription,
      stageStatus: this.stage.stageStatus,
      stageType: this.stage.stageType,
      processIds: this.processId,
      minVechileCount: this.stage.minVechileCount,
      maxVechileCount: this.stage.maxVechileCount,
      plantId:this.plantId,
      isGPSEnabled:this.isGPSEnabled
    };

    this.stageService.saveStage(data).subscribe(
      (response: any) => {
        this.submitted = true;
        this.alertService.success(response.message);
        if(this.role.user_role != 'COMPANYADMIN')this.getAllStage();
        else this.resetData();
        this.resetStage();
        this.getAllProcess();
      },

      (error: any) => {}
    );
  }

  resetData(){
    this.responseData=[];
    this.renderTable();
  }

  getAllStageType() {
    this.common.loading = true;
    this.stageTypeService.getAllStageType().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.stageTypeList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectPlant(event) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getAllStage(this.plantId);
    this.getAllProcess(this.plantId);
  }


  getPlantList() {
    this.common.loading = true;
    this.api.get('plant/all_plants?id=' + this.userService._loggedInUser.companyId).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantList = res.data;
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllProcess(plantId?) {
    this.common.loading = true;
    this.processService.getAllProcessList(plantId?plantId:null).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.processList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllStage(plantId?) {
    this.common.loading = true;
    this.stageService.getAllActiveAndInactiveStages(plantId?plantId:null).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.responseData = res.data != null ? res.data : [];
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  viewStage(row?: any) {
    const tempObj = { ...row };
    delete tempObj['plant_id'];
    delete tempObj['plant_name'];
    this.common.params = { details: [tempObj], title: 'Stage Details' };

    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Stage',
      description: `<b>&nbsp;` + 'Are you sure,you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteStage(row);
      }
    });
  }

  selectProcess(event: any) {
    this.processId = [];
    this.processName = [];
    this.selectedProcesses = [];
    event.forEach((e) => {
      this.processName.push(e.processName);
      this.processId.push(e.processId);
    });
    this.selectedProcesses = event;
  }

  deleteStage(row?: any) {
    this.common.loading = true;
    this.processId = [];
    var array = row.processId.split(',');
    array.forEach((e) => {
      this.processId.push(parseInt(e));
    });
    let data = {
      stageId: row.stageId,
      stageName: row.stageName,
      stageStatus: 'DELETED',
      stageDescription: row.stageDescription,
      stageType: row.stageTypeId,
      isStageMandatory: row.isStageMandatory,
      processIds: this.processId,
      plantId:this.plantId,
      isGPSEnabled:row.is_gps_enabled
    };

    this.stageService.saveStage(data).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alertService.success(res.message);
        if(this.role.user_role != 'COMPANYADMIN')this.getAllStage();
        else this.resetData();
        this.resetStage();
        this.getAllProcess();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  editStage(row?: any) {
    this.resetStage();
    this.processId = [];
    this.processName = [];
    var array = row.processId.split(',');
    array.forEach((e) => {
      this.processId.push(parseInt(e));
    });
    this.selectedProcesses = [];
    array.forEach((el) => {
      let obj = this.processList.find((x) => x.processId == el);
      if (obj) {
        this.selectedProcesses.push(obj);
      }
    });
    this.common.gotoTop();
    this.stage.stageId = row.stageId;
    this.stage.stageName = row.stageName;
    this.stage.stageStatus = row.stageStatus;
    this.stage.stageDescription = row.stageDescription;
    this.stage.minVechileCount = row.minVehicleCount;
    this.stage.maxVechileCount = row.maxVehicleCount;
    this.stage.stageType = row.stageTypeId;
    this.stage.isGPSEnabled = row.is_gps_enabled;
    this.plantId=row.plant_id;
    this.plantName=row.plant_name;
    this.isGPSEnabled = row.is_gps_enabled;
    this.buttonName = 'update';
    this.title = 'Edit Stage';
  }

  resetStage() {
    this.stage = {
      stageId: '',
      stageName: '',
      stageStatus: 'ACTIVE',
      stageDescription: '',
      stageType: '',
      status: '',
      maxVechileCount: 0,
      minVechileCount: 0,
      isGPSEnabled:false
    };
    this.processId = null;
    this.typeValidator = null;
    this.stageNameValidator = null;
    this.statusValidator = null;
    this.processValidator = null;
    this.buttonName = 'save';
    this.title = 'Add Stage';
    this.processId = [];
    this.processName = [];
    this.selectedProcesses = [];
    this.stageNameValidator = null;
    this.processValidator = null;
    this.statusValidator = null;
    this.typeValidator = null;
    this.plantId=null;
    this.plantName=null;
    this.plantNameValidator=null;
    this.isGPSEnabled=false;
  }

  typeValidator: any;
  stageNameValidator: any;
  statusValidator: any;
  processValidator: any;
  formValidator() {
    if(this.role.user_role == 'COMPANYADMIN'){
      this.plantNameValidator=this.common.nullValidator(
        this.plantId,
        'Plant Mandatory'
      );
    }
    this.stageNameValidator = this.common.nullValidator(
      this.stage.stageName,
      'Stage Name Mandatory'
    );
    this.typeValidator = this.common.nullValidator(
      this.stage.stageType,
      'Stage Type Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.stage.stageStatus,
      'Stage Status Mandatory'
    );
    this.processValidator = this.common.nullValidator(
      this.processId,
      'Process is mandatory'
    );
    if (
      (!this.stageNameValidator.error &&
      !this.typeValidator.error &&
      !this.statusValidator.error &&
      !this.processValidator.error)&&((this.role.user_role=='COMPANYADMIN' && !this.plantNameValidator.error) || this.role.user_role!='COMPANYADMIN')
    ) {
      this.saveStage();
    }
  }
}

import { GeofenceModalComponent } from './../../modals/geofence-modal/geofence-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { TableService } from 'src/app/@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { AlertService } from './../../theme/layout/header/alert-message/alert.service';
import { MapService } from '../../@core/services/map.service';
import { UserService } from 'src/app/@core/services/user.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import * as geolib from 'geolib';
import { LocationService } from 'src/app/@core/services/location.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { GoogleMapAutocompleteComponent } from 'src/app/directives/google-map-autocomplete/google-map-autocomplete.component';
import { MapViewModalComponent } from '../map-view-modal/map-view-modal.component';
import { ActivatedRoute } from '@angular/router';
declare var google: any;
@Component({
  selector: 'app-plant-geofence',
  templateUrl: './plant-geofence.component.html',
  styleUrls: ['./plant-geofence.component.scss'],
})
export class PlantGeofenceComponent implements OnInit, AfterViewInit {
  userLocation: any = {};
  circle: any;
  circleHasDrawn: boolean = false;
  mapBorderColor: string;
  mapFillColor: string;
  mapEditMode: boolean = false;
  plantListIndex: number[];
  companyUIState: any[];
  activePlantIds: number[];
  isGeofenceShow: boolean = false;
  updateItem: number = null;
  plantVisible: boolean;
  hardwareList: any = [];
  prevPlantId: any;
  positionLatLong:any;
  hardwareListener:any;
  // @Output() resetAddress: EventEmitter<any>=new EventEmitter<any>();
  //EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('autocomplete') autocomplete: GoogleMapAutocompleteComponent;

  @HostListener('click', ['$event'])
  onClick(event) {
    this.getMapZoomLevel(event);
  }

  @HostListener('document:mouseup', ['$event'])

  // getDragEvent(event) {
  //   if (
  //     this.site.geoFenceType == 2 &&
  //     this.mapService?.circle &&
  //     this.circleHasDrawn
  //   ) {
  //     setTimeout(() => {
  //       this.site.lat = this.getCircleCenter().lat;
  //       this.site.lng = this.getCircleCenter().long;
  //       this.site.radius = Math.trunc(this.getCircleCenter().radius);
  //     }, 10);
  //   }

  //   if (
  //     this.mapService.markers.length &&
  //     event.target.nodeName.toLowerCase() == 'area'
  //   ) {
  //     (this.site.lat = +this.mapService.markers[0].position.lat()),
  //       (this.site.lng = +this.mapService.markers[0].position.lng());
  //     this.placeMarkerRadius();
  //   }
  // }

  pathColors = {
    1: {
      colorBorder: '#FF0000',
      colorFill: '#ff5a3c',
    },
    2: {
      colorBorder: '#F1A41D',
      colorFill: '#ffcd30',
    },
    3: {
      colorBorder: '#159F29',
      colorFill: '#28ca3f',
    },
    4: {
      colorBorder: '#127d93',
      colorFill: '#219cb5',
    },
    5: {
      colorBorder: '#b03e7e',
      colorFill: '#ab5083',
    },
    6: {
      colorBorder: '#0a58f5',
      colorFill: '#327ef0',
    },
  };
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.tableService.options(10, 3, 'Leave Policy');
  latLongCenter: { lat: any; lng: any };
  geofenceData: any;
  mapZoomLevel: number = 17;
  latLongTrigger = new Subject();
  allPlantData: any;
  allGeoFenceData: any = [];
  tableIsActive: boolean = false;
  allPlantObject: any;
  path: any = [];
  companyId = null;
  addEnabled: boolean = true;
  selectedZone: any = null;
  zoneSelected: any = null;
  selectedHardware: any = null;

  site = {
    siteType: 0,
    lat: null,
    lng: null,
    geometryPos: null,
    plantId: null,
    stageId: null,
    destinationId: null,
    status: 1,
    location: null,
    geofenceId: null,
    geoFenceType: 2,
    siteId: null,
    radius: 0,
    parkingZoneId: null,
    hardwareId: null,
    zoneId: null,
    companyId: null
  };

  Location = null;
  typeIds = [
    {
      description: 'Plant',
      id: 1,
    },
    {
      description: 'Stage',
      id: 2,
    },
    {
      description: 'Destination',
      id: 3,
    },
    {
      description: 'Parking Zone',
      id: 4,
    },
    {
      description: 'Zone',
      id: 5,
    },
    {
      description: 'Hardware',
      id: 6,
    }
  ];

  geoFenceTypes = [
    { name: 'Custom', type: 1 },
    { name: 'Radius', type: 2 },
  ];

  typeID = null;
  allGeoFence: any = [];
  companyName: any;
  plant: any;
  allCompanies: any[];
  plantData: any[];
  isSuperAdmin: boolean;
  plantName: any;
  stages: any;
  stageName: any;
  allStages: any[];
  saveBtnText: string = 'save';
  allDestinations: any;
  stageDestinations: any;
  selectedDestination: string;
  editCase: boolean;
  plantIndex: number = 0;
  plantId = -1;
  circlesLength: number = 0;
  zoneList: any[];
  allZoneList: any = [];

  getMapZoomLevel(event) {
    let zoomOutButton =
      document.querySelector("[aria-label='Zoom out']") || null;
    let zoomInButton = document.querySelector("[aria-label='Zoom in']") || null;
    let zoomClicked = [zoomOutButton, zoomInButton].includes(event.target);
    if (zoomClicked) {
      this.mapZoomLevel = this.mapService.map.getZoom();
    }
  }

  constructor(
    public common: CommonService,
    public api: ApiService,
    public mapService: MapService,
    public alertService: AlertService,
    public user: UserService,
    public breadcrumbService: BreadcrumbService,
    private locationService: LocationService,
    public modalService: NgbModal,
    public tableService: TableService,
    public role: RoleService,
    public route:ActivatedRoute
  ) {

    // this.positionLatLong = this.route.snapshot.queryParams.latLong;
    // if(this.positionLatLong)this.placeCurrentLocMarker();
    // this.mapService.isDrawPolyGon = true;
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'geofence', url: '#' },
      { name: 'Plant Geofence', url: '/user/plant-geofence' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.companyId = this.user._loggedInUser.companyId;
    this.isSuperAdmin = !!(this.user._loggedInUser.companyId == 1);
    this.plantId = this.user._loggedInUser.plantId;

    if (this.isSuperAdmin) {
      this.getAllCompanies();
    }
    // if (!this.isSuperAdmin) {
    //   this.getAllStage(this.plantId);
    //   this.getAllDestinations(this.plantId);
    //   this.getPlantZone(this.plantId);
    //   this.getAllZones(this.plantId);
    //   this.getAllHardware(this.plantId);
    // }
    this.plantVisible = (this.role.user_role == 'PLANTADMIN' || this.role.user_role == 'PLANTUSER') ? false : true;

    if (this.plantVisible) this.getAllPlant(this.companyId);

    this.mapFillColor = this.pathColors[1].colorFill;
    this.mapBorderColor = this.pathColors[1].colorBorder;

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  renderTable() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        dtInstance.clear();
        this.dtTrigger.next();
      });
    }
  }

  resetTypes() {
    this.site.radius=0;
    this.mapFillColor = this.pathColors[this.site.siteType].colorFill;
    this.mapBorderColor = this.pathColors[this.site.siteType].colorBorder;
    this.site.stageId = null;
    this.site.destinationId = null;
    this.site.hardwareId = null;
    this.site.zoneId = null;
    this.site.parkingZoneId = null;
    this.stageName = null;
    this.selectedDestination = null;
    this.selectedZone = null;
    this.zoneSelected = null;
    this.selectedHardware = null;
    google.maps.event.removeListener(this.hardwareListener);
    this.changeGeoFenceType();

    if (this.site.siteType == 2) {
      this.getAllStage(this.site.plantId);
    }

    if (this.site.siteType == 3) {
      this.getAllDestinations(this.site.plantId);
    }
    if (this.site.siteType == 4) {
      this.getPlantZone(this.site.plantId);
    }

    if (this.site.siteType == 5) {
      this.getAllZones(this.site.plantId);
    }
    if (this.site.siteType == 6) {
      this.getAllHardware(this.site.plantId);
      this.selectHardware();
    }

  }

  getAllHardware(plantId: any) {
    let apiUrl = 'hardware/get_hardware?plantId=' + plantId;
    this.api.get(apiUrl).subscribe((res: any) => {
      this.hardwareList = res.data;
    });
  }

  getAllZones(plantId: any) {
    let apiUrl = 'zone/get-all-zone-stage?plantId=' + plantId + '&allZones=false';
    this.api.get(apiUrl).subscribe((res: any) => {
      this.allZoneList = res.data;
    });
  }

  siteTypeChange() {
    if (this.editCase) {
      this.parseEditData();
    }
  }

  selectHardware(latlng?){
    const markerT = this.mapService.returnMarker();
    if(latlng)markerT.setPosition(latlng);
    // .addEventListener('wheel', (event) => {
      this.hardwareListener=google.maps.event.addListener(this.mapService.getMap(), 'click', (event) => {
      // google.maps.event.addListener(markerT, 'click', (event) => {
        console.log("fyuhnm",event.latLng)
        markerT.setPosition(event.latLng);
        this.site.lat = markerT.getPosition().lat();
        this.site.lng = markerT.getPosition().lng();
        // console.log("latlnggg", this.site.lat, this.site.lng);
    // });
  })
  }

  changeGeoFenceType() {
    this.mapService.isDrawPolyGon = !!(this.site.geoFenceType == 1);
    this.mapService.isDrawMarker = !!(this.site.geoFenceType == 2);
    if(this.site.geoFenceType==1 && this.site.radius!=0){
      this.mapService.circle.setMap(null);
      this.mapService.circle=null;
      this.site.radius=0;
    }else{
      this.mapService.resetPolyPath();
    }
    // this.viewGeofence(this.allGeoFence[this.plantIndex], this.plantIndex, false);
  }

  parseEditData() {
    if (this.geofenceData?.length) {
      const parseTypedData = (type) =>
        this.geofenceData.filter((item) => item.type == type);
      const plant = parseTypedData(1);
      const stages = parseTypedData(2);
      const destinations = parseTypedData(3);
      this.site.geofenceId = null;
      if (this.site.siteType == 1) {
        this.site.geofenceId = plant[0].id;
      } else if (this.site.siteType == 2 && this.site.stageId) {
        this.site.geofenceId =
          stages.find((item) => item.stageId == this.site.stageId)?.id || null;
      } else if (this.site.siteType == 3 && this.site.destinationId) {
        this.site.geofenceId =
          destinations.find(
            (item) => item.stage_destination_code_id == this.site.destinationId
          )?.id || null;
      } else if (this.site.siteType == 4 && this.site.parkingZoneId) {
        this.site.geofenceId =
          this.zoneList.find((item) => item.id == this.site.parkingZoneId)?.id || null;
      } else if (this.site.siteType == 5 && this.site.zoneId) {
        this.site.geofenceId =
          this.allZoneList.find((item) => item.id == this.site.zoneId)?.id || null;
      } else if (this.site.siteType == 6 && this.site.hardwareId) {
        this.site.geofenceId =
          this.hardwareList.find((item) => item.ipaHardwareId == this.site.hardwareId)?.id || null;
      }
    }
  }

  selectPlant(event: any) {
    this.plant = event;
    this.plantName = event.plantName;
    this.site.plantId = event.plantId;
    this.site.siteType = 0;
    // this.plantId = event.plantId;
    if (event.plantId != 1) {
      this.plantIndex = 0;
      this.getGeoFence(event.plantId);
      this.renderTable();
      this.autocomplete.resetValue();
    }
    if (this.saveBtnText != 'update') this.site.siteType = 0;
  }

  selectedCompany(event: any) {
    // this.companyId = event.companyId;
    this.plant = null;
    // this.plantData = this.getCompanyPlants(this.companyId);
    this.site.siteType = 0;
    this.getAllPlant(event.companyId);
    this.plantName = '';
    this.site.plantId = null;
    this.site.companyId = event.companyId;
    this.clearMapServices();
    this.mapService.mapIntialize(
      'map',
      this.mapZoomLevel,
      this.site.lat || this.userLocation.lat,
      this.site.lng || this.userLocation.long
    );
  }

  // getActivePlantState() {
  //   this.activePlantIds = this.allGeoFence.map((item) => item.plant_id);
  // }

  // getCompanyUIState() {
  //   let plantIds = this.allGeoFence.map((item) => item.plant_id);
  //   let plantCompanies = this.allPlantData.reduce((a, c) => {
  //     if (plantIds.includes(c.plantId)) {
  //       a.push(c.companyId);
  //     }
  //     return a;
  //   }, []);

  //   this.companyUIState = [];
  //   this.allCompanies.forEach((c, i) => {
  //     if (plantCompanies.includes(c.companyId)) {
  //       this.companyUIState.push(i);
  //     }
  //   }, []);
  // }

  // autoSelectCompany(companyId) {
  //   return this.allCompanies.find((item) => item.companyId == companyId);
  // }

  // getPlantUiState() {
  //   this.plantListIndex = [];
  //   let geoplantIds = this.allGeoFence.map((item) => item.plant_id);

  //   this.plantData.forEach((item, index) => {
  //     if (geoplantIds.includes(item.plantId)) {
  //       this.plantListIndex.push(index);
  //     }
  //   });
  // }

  getAllDestinations(plantId: any) {
    let apiUrl = 'destination/list_stage_destination_code?allStatus=false&plantId=' + plantId;
    this.api.get(apiUrl).subscribe((res: any) => {
      this.allDestinations = res.data;
    });
  }

  // getCompanyPlants(companyId) {
  //   return (this.plantData = this.allPlantData.filter(
  //     (plant) => plant.companyId == companyId
  //   ));
  // }

  getAllCompanies() {
    this.common.loading = true;
    let apiSubUrl = 'company/all_company';
    this.api.get(apiSubUrl).subscribe(
      (res: any) => {
        this.allCompanies = res.data || [];
        // this.companyName = this.getCompanyName();
        // this.companyId = this.allCompanies.find(
        //   (company) => company.companyId == this.user._loggedInUser.id
        // ).companyId;
        this.common.loading = false;
      },
      (err) => {
        this.common.loading = false;
        console.error(err);
      }
    );
  }

  //no need for below can remove
  getCompanyName() {
    return this.allCompanies.find(
      (company) => company.companyId == this.user._loggedInUser.id
    ).companyName;
  }

  getAllStage(plantId: any) {
    this.common.loading = true;
    let apiUrl = 'stage/get-all-stage';
    if (plantId) {
      apiUrl = `stage/get-all-stage?plantId=${plantId}`;
    }
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res?.data?.length) {
          this.stages = res.data;
          this.allStages = res.data;
        } else {
          this.stages = [];
          this.alertService.info(
            `Stages not found for <b class="text-primary">${this.allPlantObject[plantId]}</b>`
          );
        }
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  getAllPlant(companyId: any) {
    this.common.loading = true;
    let apiUrl = 'plant/all_plants?id=' + companyId;
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data || [];
        // if (this.allPlantData.length) {
        //   this.allPlantObject = this.allPlantData.reduce((acc, current) => {
        //     acc[current.plantId] = current.plantName;
        //     return acc;
        //   }, {});
        // }
        // if (this.isSuperAdmin || this.plantId==0) {
        //   this.plantData = this.allPlantData;
        // }else {
        //   this.plantData = this.allPlantData.filter(
        //     (plant) => plant.plantId == this.plantId
        //   );
        // }
      },
      (err: any) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  //26.885498    75.842187

  moveMarkerRadius() {
    console.log("calling on input", this.site.lat);
    this.site.lat = Number(this.site.lat);
    this.site.lng = Number(this.site.lng);
    // this.placeradiusmarker();
    this.placeMarkerRadius();
  }

  placeradiusmarker(latLong?) {
    this.mapService.resetMarker();
    if (latLong) {
      this.mapService.placeSingleMarker(latLong);
    } else {
      this.mapService.placeSingleMarker(
        { lat: this.site.lat, lng: this.site.lng },
        true,
        null
      );
    }
    console.log("placeradiusmarkerrrr");
  }

  placeMarkerRadius() {
    console.log("fghjk",this.site.lat);
    this.mapFillColor = this.pathColors[this.site.siteType].colorFill;
    this.mapBorderColor = this.pathColors[this.site.siteType].colorBorder;
    const circleLength: number = this.mapService.circles.length;
    this.circleHasDrawn = false;
    if (circleLength > this.circlesLength) {
      this.mapService.circles[this.circlesLength].setMap(null);
      this.mapService.circles.pop();
    }

    if (this.site.radius > 0) {
      let center = { lat: +this.site.lat, lng: +this.site.lng };
      this.mapService.createCirclesOnPosition(
        center,
        this.site.radius,
        this.mapBorderColor,
        this.mapFillColor,
        0.1,
        1,
        true,
        true
      );
      // this.placeradiusmarker(center);
      this.circleHasDrawn = true;
    }
    console.log("jwdwoisd",this.site.lat);
  }

  // changeStatus(row) {
  //   this.site.siteType = 1;
  //   let params = {
  //     id: row.geofence_id,
  //     lat: row.lat,
  //     lng: row.long,
  //     remark: row.remark,
  //     position: this.getLatLongFromPolygon(row.pos),
  //     status: row.status == 1 ? 0 : 1,
  //     plantId: row.plant_id,
  //     stageId: null,
  //     destinationId: null,
  //     geofenceType: Number(this.site.geoFenceType),
  //   };

  //   this.common.loading = true;
  //   let apiUrl = 'geofence/save-geofence';
  //   this.api.post(apiUrl, params).subscribe(
  //     (res) => {
  //       this.common.loading = false;
  //       this.resetParams();
  //       this.getGeoFence(null);
  //     },
  //     (err) => {
  //       console.error(err);
  //       this.common.loading = false;
  //     }
  //   );
  // }

  getLatLongFromPolygon(str) {
    let latLong = [];
    if (str && !str.includes('EMPTY') && !str.includes('OLLECTION EMP')) {
      let latLongString = String(str).split(',');
      latLongString.forEach((item) => {
        let latLongs = item.split(' ').map((item) => Number(item));
        latLong.push({ lat: latLongs[0], lng: latLongs[1] });
      });
    }
    return latLong;
  }

  getGeoFence(plantId: any) {
    this.common.loading = true;
    let apiUrl = 'geofence/get-plant-geofence-by-processId?callingFromGeofence=true';
    if (plantId != null) {
      apiUrl = apiUrl + '&plantId=' + plantId;
      // console.log(apiUrl);
    }
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.allGeoFence = res.data || [];

        if ((this.plantVisible && plantId == null) || !this.plantVisible) this.allGeoFenceData = res.data || [];

        // this.renderTable();

        this.allGeoFence.forEach((element) => {
          element.plantLatLong = this.getSiteGeoFenceLatLong(element);
        });
        if (this.plantIndex >= 0) {

          if (this.allGeoFence.length) {


            if (this.plantVisible && plantId == null) {
              this.clearMapServices();
            } else {
              // // let GeoFenceIndex =  this.allGeoFence.findIndex(
              // //   (item) => (item.plant_id == this.site.plantId )
              // // );
              // // let existPlantGeoFence = GeoFenceIndex > -1;
              // // if (existPlantGeoFence) {
              // //   console.log("GeofenceIndex",GeoFenceIndex,"allgeofence",this.allGeoFence)
              this.isGeofenceShow = true;
              //   // this.viewGeofence(this.allGeoFence[GeoFenceIndex], GeoFenceIndex, false);
              // } else {
              //
              // }
              // if(plantId==null)this.resetParams();
              // if (!this.isGeofenceShow && this.plantVisible) {
              //   // this.mapService.mapIntialize("map", this.mapZoomLevel, this.site.lat || this.userLocation.lat, this.site.lng || this.userLocation.long);
              //   console.log("before Initialize");

              // } else {
              this.viewGeofence(
                this.allGeoFence[this.plantIndex],
                this.plantIndex,
                false
              );
            }
            // }
          } else {

            // this.initializeMap();
            this.clearMapServices();
          }

        }
      },
      (err) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  getSiteGeoFenceLatLong(data) {
    let latLongs = [];
    // let shorter = (s) =>
    //   s
    //     .split(' ')
    //     .map((item) => item[0])
    //     .join('');
    if (data.destinations && data.destinations.length) {
      data.destinations.forEach((destination) => {
        if (destination.pos) {
          latLongs.push({
            type: 3,
            label: destination.destination_name,
            lat: destination.lat,
            lng: destination.long,
            radius: destination.radius,
            data: this.getLatLongFromPolygon(destination.pos),
            id: destination.geofence_id,
            destinationId: destination.destination_id,
          });
        }
      });
    }

    if (data.stages && data.stages.length) {
      data.stages.forEach((stage) => {
        if (stage.pos) {
          latLongs.push({
            type: 2,
            label: stage.stage_name,
            lat: stage.lat,
            lng: stage.long,
            radius: stage.radius,
            data: this.getLatLongFromPolygon(stage.pos),
            id: stage.geofence_id,
            stageId: stage.stage_id,
          });
        }
      });
    }
    if (data.zones && data.zones.length) {
      data.zones.forEach((zone) => {
        if (zone.pos) {
          latLongs.push({
            type: 4,
            label: zone.zone_name,
            lat: zone.lat,
            lng: zone.long,
            radius: zone.radius,
            data: this.getLatLongFromPolygon(zone.pos),
            id: zone.geofence_id,
            parkingZoneId: zone.id,
          });
        }
      });
    }
    if (data.pzone && data.pzone.length) {
      data.pzone.forEach((zone) => {
        if (zone.pos) {
          latLongs.push({
            type: 5,
            label: zone.zone_name,
            lat: zone.lat,
            lng: zone.long,
            radius: zone.radius,
            data: this.getLatLongFromPolygon(zone.pos),
            id: zone.geofence_id,
            zoneId: zone.id,
          });
        }
      });
    }/// get verified after adding zone api of saloni branch
    if (data.hardware && data.hardware.length) {
      data.hardware.forEach((hardware) => {
        if (hardware.pos) {
          latLongs.push({
            type: 6,
            label: hardware.hardware_name,
            lat: hardware.lat,
            lng: hardware.long,
            radius: hardware.radius,
            data: this.getLatLongFromPolygon(hardware.pos),
            id: hardware.geofence_id,
            hardWareId: hardware.ipa_hardware_id,
          });
        }
      });
    }

    if (data.pos) {
      latLongs.push({
        type: 1,
        label: data.plant_name,
        lat: data.lat,
        lng: data.long,
        radius: data.radius,
        data: this.getLatLongFromPolygon(data.pos),
        id: data.geofence_id,
        plantId: data.plant_id,
      });
    }
    return latLongs;
  }

  ngOnInit() { }

  initializeMap() {
    this.getLocation();
    this.mapService.mapIntialize(
      'map',
      this.mapZoomLevel,
      this.site.lat || this.userLocation.lat,
      this.site.lng || this.userLocation.long
    );
    this.mapService.createPolygonPath();
    // console.log("userLocation",this.userLocation);
    // if ("geolocation" in navigator) {
    //   // Geolocation is available
    //   navigator.geolocation.getCurrentPosition(function (position) {
    //     let latLong = { lat: position.coords.latitude, lng: position.coords.longitude };
    //     console.log("latlongg", latLong);
    //     this.mapService.centerAt(latLong);
    //     this.mapService.placeSingleMarker(latLong);
    //     console.log(latLong);

    this.placeCurrentLocMarker();
    //     const markerT = this.mapService.returnMarker();
    //     google.maps.event.addListener(markerT, 'dragend', () => {
    //       this.site.lat = markerT.getPosition().lat();
    //       this.site.lng = markerT.getPosition().lng();
    //       console.log("latlnggg", this.site.lat, this.site.lng);
    //     });
    //   }, function (error) {
    //     // Handle geolocation error
    //     console.log("Error getting geolocation:", error.message);
    //   });
    // } else {
    //   // Geolocation is not available
    //   console.log("Geolocation is not supported in this browser.");
    // }

    // console.log("latlnggg",this.site.lat,this.site.lng);
    // this.invokeEvent(place);

    setTimeout(() => {
      this.mapService.setMapType(0);
    }, 200);
    document.getElementById('map').addEventListener('wheel', (event) => {
      this.mapZoomLevel = this.mapService.map.getZoom();
    });

    this.mapService.addListerner(this.mapService.map, 'click', (event) => {
      if (this.mapService.isDrawMarker) {
        this.mapService.markers = [];
        this.site.lat = event.latLng.lat();
        this.site.lng = event.latLng.lng();
        if (this.site.siteType != null) this.placeMarkerRadius();
        console.log("latttlng", this.site.lat, this.site.lng);

      }
      console.log("latttlng", this.site.lat, this.site.lng);
    });


    //   var latlng = new google.maps.LatLng(this.site.lat,this.site.lng);
    //   var map = new google.maps.Map(document.getElementById('map'), {
    //     center: latlng,
    //     zoom: 13
    //   });
    //   var marker = new google.maps.Marker({
    //     map: map,
    //     position: latlng,
    //     draggable: false,
    //     anchorPoint: new google.maps.Point(0, -29)
    //  });
  }

  placeCurrentLocMarker(latlongg?) {
    console.log("inplacecurrent");
    let latLong;
    if(latlongg){
      console.log("uhdhiosj")
      latLong = latlongg;
      this.mapService.centerAt(latLong);
      const markerT = this.mapService.returnMarker();
      markerT.setPosition(latLong);
      this.site.lat=latLong.lat;
      this.site.lng=latLong.lng;
      google.maps.event.addListener(markerT, 'dragend', () => {
        this.site.lat = markerT.getPosition().lat();
        this.site.lng = markerT.getPosition().lng();
        // console.log("latlnggg", this.site.lat, this.site.lng);
      });
    } else {
      // console.log("ghjjkwaw;odk");
      // if ("geolocation" in navigator) {
      //   // Geolocation is available
        // navigator.geolocation.getCurrentPosition( (position) => {
          // latLong = { lat: position.coords.latitude, lng: position.coords.longitude };
          console.log("myValueee",this.locationService._position.latitude,this.locationService._position.longitude);
          let latit;
          let longt;
          latit = this.locationService._position.latitude;
            longt = this.locationService._position.longitude;
            this.locationService.position.subscribe((position: any) => {
              latit = position.latitude;
              longt = position.longitude;
            });
          if(latit && longt){
          console.log("hkjl",this.locationService._position.latitude);
      latLong= { lat: latit, lng: longt };
      this.mapService.centerAt(latLong);
      this.mapService.placeSingleMarker(latLong);
      this.site.lat=latLong.lat;
      this.site.lng=latLong.lng;
          const markerT = this.mapService.returnMarker();
      console.log("hqwsiw",markerT.getPosition());
          google.maps.event.addListener(markerT, 'dragend', () => {
            this.site.lat = markerT.getPosition().lat();
            this.site.lng = markerT.getPosition().lng();
            // console.log("latlnggg", this.site.lat, this.site.lng);
          });
        }else{
          console.log("Geolocation is blocked in this browser");
          this.alertService.error("Geolocation is blocked in this browser");
        }
    //     }, function (error) {
    //       // Handle geolocation error
    //       console.log("Error getting geolocation:", error.message);
    //     });
    //   } else {
    //     // Geolocation is not available
    //     console.log("Geolocation is not supported in this browser.");
    //   }
    }
    //   latLong= { lat: this.locationService._position.latitude, lng: this.locationService._position.longitude };
    // console.log("latlong",latLong);
    // this.mapService.centerAt(latLong);
    // this.mapService.placeSingleMarker(latLong);
    // // console.log(latLong);
    // const markerT = this.mapService.returnMarker();
    // google.maps.event.addListener(markerT, 'dragend', () => {
    //   this.site.lat = markerT.getPosition().lat();
    //   this.site.lng = markerT.getPosition().lng();
    //   console.log("latlnggg", this.site.lat, this.site.lng);
    // });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.initializeMap();
    if (!this.plantVisible) {
      this.selectPlant({ plantId: this.user._loggedInUser.plantId, companyId: this.user._loggedInUser.companyId });
      this.siteTypeChange();
    }
    else {
      this.getGeoFence(null);
      this.renderTable();
    }
  }

  deleteGeofence(row) {
    this.common.loading = true;
    let apiUrl = 'geofence/delete-geofence';
    let params = {
      id: row.geofence_id,
      geofenceId: row.plant_id,
      siteType: 'plant'
    };
    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        if (res.status) this.alertService.success(res.message);
        this.common.loading = false;
        this.resetParams();
        this.getGeoFence(null);
        this.renderTable();
        // this.resetAddress.emit(true);
        this.autocomplete.resetValue();
        // this.placeCurrentLocMarker();
      },
      (err) => {
        this.common.loading = false;
        console.error(err);
      }
    );
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Geofence',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteGeofence(row);
      }
    });
  }

  viewGeofence(row: any, index: number = -1, event: boolean = false) {
    console.log("fhgjk", row);
    this.clearMapServices();
    console.log("viewgeo");
    this.site.geofenceId = row.geofence_id;
    this.site.geoFenceType = row.radius != null ? 2 : 1;
    this.mapEditMode = false;
    this.plantIndex = index;
    this.geofenceData = row.plantLatLong || [];
    this.site.geofenceId = row.geofence_id;
    this.editCase = event;
    this.site.geofenceId = row.geofence_id;
    if(row.lat!=null && row.lng!=null){this.site.lat = row.lat;
       this.site.lng = row.long;
      };
    this.path = this.getLatLongFromPolygon(row.pos);
    this.site.plantId = row.plant_id;//changed from .plantId to site.plantId
    this.plantName = row.plant_name;
    // this.site.remark = row.remark;
    let centreAtFlag = false;
    if (row.lat && row.long) {
      centreAtFlag = true;
      let latLong = { lat: row.lat, lng: row.long };
      this.mapService.centerAt(latLong);
      this.mapService.zoomAt(latLong, this.mapZoomLevel);
    }
    this.mapService.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    if (row.plantLatLong.length > 0) {
      if (!centreAtFlag) {
        let latLong = { lat: row.plantLatLong[0].lat, lng: row.plantLatLong[0].lng };
        this.mapService.centerAt(latLong);
        this.mapService.zoomAt(latLong, 13);
      }
      this.mapService.createPlantGeoFence(row.plantLatLong, event);
    }
    const plantLatLong = row.plantLatLong.find((item) => item.type == 1);
    if (plantLatLong && plantLatLong.data.length) {
      plantLatLong.data.forEach((element) => {
        this.mapService.setBounds(
          this.mapService.createLatLng(element.lat, element.lng)
        );
      });
    }
    if (plantLatLong && !plantLatLong.data.length && plantLatLong.radius) {
      let latLongs = { lat: plantLatLong.lat, lng: plantLatLong.lng };
      this.mapService.centerAtRadius(latLongs, plantLatLong.radius);
    }

    this.circlesLength = this.mapService.circles.length | 0;
    this.site.companyId = row.company_id;
    this.companyName = row.company_name;
    // if (this.isSuperAdmin && this.plantId) {
    //   let plant = this.plantData.find(
    //     (item) => item.plantId == this.site.plantId
    //   );
    //   console.log(plant, "companyname", this.plantData, "plantData");
    //   // let selectedCompany = this.autoSelectCompany(plant.companyId);
    //   // this.companyName = selectedCompany?.companyName;
    //   // this.companyId = selectedCompany?.companyName.companyId;
    //   // console.log(this.companyId,"dfghjkl");
    //   this.site.companyId = plant.companyId;
    //   // this.companyId = plant.companyId;
    //   this.companyName = plant.companyName;
    // }
  }

  resetParams() {

    this.isGeofenceShow = (this.plantVisible && !this.isSuperAdmin) ? false : true;
    this.prevPlantId = null;
    // this.isGeofenceShow=false;
    this.updateItem = null;
    this.clearMapServices();
    this.circlesLength = 0;
    // this.circleHasDrawn = false;
    this.mapService.isDrawPolyGon = !!(this.site.geoFenceType == 1);
    this.mapService.isDrawMarker = !!(this.site.geoFenceType == 2);
    this.editCase = true;
    this.site.status = 1;
    this.site.siteType = 0;
    this.site.geoFenceType = 2;
    this.saveBtnText = 'save';
    if (!this.plantVisible) this.site.plantId = this.plantId;
    else {
      this.site.plantId = null;
      this.site.companyId = null;
    }
    // this.site.companyId = this.companyId;
    this.site.stageId = null;
    this.site.destinationId = null;
    this.site.parkingZoneId = null;
    this.site.location = null;
    this.site.geometryPos = null;
    this.site.geofenceId = null;
    this.addEnabled = true;
    this.site.radius = 0;
    this.site.zoneId = null;
    this.site.hardwareId = null;
    this.plantIndex = 0;

    //  reset plant params

    // this.gettingPlantName();
    this.plantName = '';
    this.companyName = '';
    this.stageName = '';
    this.selectedDestination = '';
    this.selectedZone = '';
    this.zoneSelected = '';
    this.selectedHardware = '';
    // this.resetAddress.emit(true);
    this.autocomplete.resetValue();
    // this.placeCurrentLocMarker();
  }

  saveGeoFence() {
    let latLngs = [];
    if (this.isSuperAdmin && !this.site.companyId) {
      return this.alertService.error('Select Company');
    }
    if (this.plantVisible && !this.site.plantId) {
      return this.alertService.error('Select Plant');
    }

    if (!this.site.siteType || this.site.siteType == 0) {
      return this.alertService.error('Select Site Type');
    }

    if (this.site.siteType == 2 && !this.site.stageId) {
      return this.alertService.error('Select Stage');
    }

    if (this.site.siteType == 3 && !this.site.destinationId) {
      return this.alertService.error('Select Destination');
    }

    if (this.site.siteType == 4 && !this.site.parkingZoneId) {
      return this.alertService.error('Select Parking Zone');
    }

    if (this.site.siteType == 5 && !this.site.zoneId) {
      return this.alertService.error('Select Zone');
    }
    if (this.site.siteType == 6 && !this.site.hardwareId) {
      return this.alertService.error('Select Hardware');
    }

    if (this.site.geoFenceType == 1) {
      let flag = true;
      latLngs = this.mapService.polygonPath?.getPath()?.getArray();
      if (!this.mapService.polygonPath) {
        if (this.saveBtnText == 'update') {
          flag = false;
        }
        else return this.alertService.error('Draw polygon to mark territory');
      }
      if (flag) {
        if (latLngs.length < 4) {
          this.mapService.isDrawPolyGon = true;
          return this.alertService.error(
            'Site Should Have More Than 3 points at least'
          );
        }

        if (
          latLngs[latLngs.length - 1].lat != latLngs[0].lat &&
          latLngs[latLngs.length - 1].lng != latLngs[0].lng
        ) {
          latLngs.push(latLngs[0]);
        }
        this.path = [];
        for (const element of latLngs) {
          this.path.push({ lat: element.lat(), lng: element.lng() });
        }
      }

      let geofenceCenter: any = geolib.getCenter(this.path);
      this.site.lat = geofenceCenter.latitude;
      this.site.lng = geofenceCenter.longitude;
      this.site.radius = null;
    }

    if (this.site.geoFenceType == 2) {
      this.path = [];
      if (this.site.siteType != 6) {
        if (this.site.radius == 0) {
          return this.alertService.error(
            'Enter Valid Radius'
          );
        }

        this.site.lat = this.getCircleCenter().lat;
        this.site.lng = this.getCircleCenter().long;
      }
    }

    console.log("saving hardawre", this.site.lat, this.site.lng);
    let apiUrl = 'geofence/save-geofence';
    let params = {
      // id: this.site.geofenceId || null,
      id: this.updateItem,
      lat: this.site.lat,
      lng: this.site.lng,
      position: this.path,
      status: this.site.status,
      siteType: this.site.siteType,
      geofenceId: this.site.siteType == 1 ? this.site.plantId : (this.site.siteType == 2 ? this.site.stageId : (this.site.siteType == 3 ? this.site.destinationId : (this.site.siteType == 4 ? this.site.parkingZoneId : (this.site.siteType == 5 ? this.site.zoneId : this.site.hardwareId)))),
      radius: this.site.radius,
      geofenceType: Number(this.site.geoFenceType - 1),
    };

    this.common.loading = true;
    this.api.post(apiUrl, params).subscribe(
      (res:any) => {
        if(res.status){
        this.common.loading = false;
        this.alertService.success(res.message);
        if(this.plantVisible)this.getAllPlant(this.companyId);
        this.resetParams();
        this.getGeoFence(null);
        this.renderTable();
        // this.placeCurrentLocMarker();
        // this.resetAddress.emit(true);
        this.autocomplete.resetValue();
        }
      },
      (err) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  clearMapServices() {
    this.mapService.clearAll();
    this.mapService.resetMarker();
    this.mapService.resetCircles();
    this.mapService.resetBounds();
    if (this.saveBtnText == 'update') {
      this.path = [];
      this.mapService.resetPolygons();
    }
    this.initializeMap();
  }

  cancelMapServices() {
    this.path = '';
    this.mapService.resetPolygons();
    this.mapService.resetCircles();
    this.mapService.clearAll();
    this.Location = null;
    this.resetParams();
    document.getElementById('location')['value'] = '';
    this.typeID = null;
  }

  //Display Sites record in table

  selectLocation(res) {
    this.Location = res && res.location ? res.location : null;
    if (this.Location)
      this.mapService.zoomAt(
        this.mapService.createLatLng(res.lat, res.lng),
        12
      );
  }

  getAddress(place: any) {
    console.log("place changed");
    this.site.lat = place.obj['geometry'].location.lat();
    this.site.lng = place.obj['geometry'].location.lng();
    this.site.location = place.obj['formatted_address'];

    let latLong = {
      lat: this.site.lat,
      lng: this.site.lng,
      location: this.site.location,
    };
    this.selectLocation(latLong);
    this.placeCurrentLocMarker(place.latlong);
  }

  getLatLng() {
    if (!this.Location) {
      let ltlngString = (<HTMLInputElement>document.getElementById('moveto'))
        .value;
      let lat = ltlngString.split(',')[0];
      let lng = ltlngString.split(',')[1];
      this.mapService.zoomAt(this.mapService.createLatLng(lat, lng), 18);
    }
  }

  editGeoFence(row, index) {
    // console.log("curRow",row,"ind",index);
    // console.log("daata",this.allGeoFenceData[index]);
    this.circleHasDrawn = false;
    this.plantIndex = index;
    this.common.params = {
      index: index,
      geoFenceData: this.allGeoFenceData[index],
    };

    setTimeout(() => {
      const activeModal = this.modalService.open(GeofenceModalComponent, {
        size: 'xl',
        backdrop: 'static',
        windowClass: 'geofence-modal',
      });
      activeModal.result.then((res) => {
        if (res.data) {
          if (res.mode == 1) {
            this.resetParams();
            this.getGeoFence(null);
            this.autocomplete.resetValue();
            this.renderTable();
            // if(this.plantVisible)this.clearMapServices();
          }
          else if (res.mode == 2) {
            this.viewGeofence(row, index);
            this.setEditParams();
          }
          this.updateItem = res.geofenceId;
          // this.placeCurrentLocMarker();
        }
        // else{
        //   this.resetParams();
        // }
      });
    }, 200);
  }

  // openGeofenceModal(row, index) {
  //   this.common.params = { index: index };
  //   const activeModal = this.modalService.open(GeofenceModalComponent, {
  //     size: 'xl',
  //     backdrop: 'static',
  //     windowClass: 'geofence-modal',
  //   });
  //   activeModal.result.then((res) => {
  //     if (res.data) {
  //       if (res.mode == 1) {
  //         this.getGeoFence(null);
  //       }

  //       if (res.mode == 2) {
  //         this.setEditParams();
  //       }
  //     }
  //   });
  // }

  openModal(item: any, i: any) {
    this.prevPlantId = this.site.plantId;
    this.common.params = {
      rowData: item,
      ind: i
    }
    const activeModal = this.modalService.open(MapViewModalComponent, {
      size: 'lg',
    });
    activeModal.result.then(() => {
      this.plantIndex = 0;
      this.getGeoFence(this.prevPlantId);
    });
    // this.viewGeofence(item,i);

  }

  setEditParams() {
    let params = this.common.params.data;
    console.log("params", params);
    this.saveBtnText = 'update';
    let type = this.common.params.type;
    this.site.plantId = params.plant_id;
    this.plantName = params.plant_name;
    this.site.geofenceId = params.geofence_id;
    this.site.lat = params.lat;
    this.site.lng = params.long;
    this.site.geoFenceType = params.radius != null ? 2 : 1;
    this.mapService.isDrawPolyGon = !!(this.site.geoFenceType == 1);
    this.mapService.isDrawMarker = !!(this.site.geoFenceType == 2);
    this.site.radius = params.radius ?? 0;
    this.editCase = true;
    this.path = this.getLatLongFromPolygon(params.pos);

    if (type == 'plant') {
      this.site.siteType = 1;
      this.site.plantId = params.plant_id;
      this.plantName = params.name;
    }

    if (type == 'stage') {
      this.site.siteType = 2;
      this.site.stageId = params.stage_id;
      this.stageName = params.stage_name;
      this.getAllStage(params.plant_id);
    }

    if (type == 'destination') {
      this.site.siteType = 3;
      this.site.destinationId = params.destination_id;
      this.selectedDestination = params.destination_name;
      this.getAllDestinations(params.plant_id);
    }

    if (type == 'parking Zone') {
      this.site.siteType = 4;
      this.site.parkingZoneId = params.zone_id;
      this.selectedZone = params.zone_name;
      this.getPlantZone(params.plant_id);
    }

    if (type == 'zone') {
      this.site.siteType = 5;
      this.site.zoneId = params.pZone_id;
      this.zoneSelected = params.zone_name;
      this.getAllZones(params.plant_id);
    }

    if (type == 'hardware') {
      this.site.siteType = 6;
      this.site.hardwareId = params.hardware_id;
      this.selectedHardware = params.hardware_name;
      this.getAllHardware(params.plant_id);
      console.log("tyhjkm",params)
      this.selectHardware({lat:params.lat,lng:params.long});
    }
  }

  getLocation() {
    console.log("locationnnn",this.site.lat);
    this.site.lat = this.locationService._position.latitude;
    this.site.lng = this.locationService._position.longitude;
    this.locationService.position.subscribe((position: any) => {
      this.userLocation = {
        lat: position.latitude,
        lng: position.longitude,
      };
    });
    this.latLongTrigger.next();
  }

  getPlantZone(plantId: any) {
    this.common.loading = true;
    this.api.get('zone/get_all_zones?plantId=' + plantId).subscribe(
      //?allZones=false
      (res: any) => {
        this.common.loading = false;
        this.zoneList = res.data;
      },
      (err) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  getCircleCenter(): { lat: number; long: number; radius: number } {
    return {
      lat: this.mapService.circle?.getCenter().lat() || null,
      long: this.mapService.circle?.getCenter().lng() || null,
      radius: this.mapService.circle?.radius || null,
    };
  }

  // createCustomControl() {
  //   const divElement = document.createElement('div');
  //   divElement.classList.add('edit-control');

  //   // Create the first button
  //   const viewBtn = document.createElement('button');
  //   viewBtn.classList.add('map-edit-btn');
  //   const viewIcon = document.createElement('i');
  //   viewIcon.classList.add('fa', 'fa-eye');
  //   viewBtn.appendChild(viewIcon);

  //   // Create the second button
  //   const editBtn = document.createElement('button');
  //   editBtn.classList.add('map-edit-btn');
  //   const editIcon = document.createElement('i');
  //   editIcon.classList.add('fa', 'fa-edit');
  //   editBtn.appendChild(editIcon);
  //   divElement.appendChild(viewBtn);
  //   divElement.appendChild(editBtn);

  //   if (this.mapEditMode) {
  //     viewBtn.classList.remove('active');
  //     editBtn.classList.add('active');
  //   }

  //   if (!this.mapEditMode) {
  //     editBtn.classList.remove('active');
  //     viewBtn.classList.add('active');
  //   }

  //   viewBtn.addEventListener('click', () => {
  //     this.mapEditMode = false;
  //     editBtn.classList.remove('active');
  //     viewBtn.classList.add('active');
  //     this.viewGeofence(
  //       this.allGeoFence[this.plantIndex],
  //       this.plantIndex,
  //       false
  //     );
  //   });

  //   editBtn.addEventListener('click', () => {
  //     this.mapEditMode = true;
  //     viewBtn.classList.remove('active');
  //     editBtn.classList.add('active');
  //     this.viewGeofence(
  //       this.allGeoFence[this.plantIndex],
  //       this.plantIndex,
  //       true
  //     );
  //   });

  //   this.mapService.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
  //     divElement
  //   );

  //   // controlButton.title = 'Click to recenter the map';
  //   // let actionBtn = document.querySelector('[role=menubar].gmnoprint');
  //   // actionBtn.append(controlButton);

  //   // controlButton.addEventListener('click', () => {
  //   //   this.mapEditMode = !this.mapEditMode;
  //   //   controlButton.textContent  = this.mapEditMode ? 'View Map' : 'Edit Map';
  //   //   controlButton.classList.remove(!this.mapEditMode ? 'edit-mode' : 'view-mode')
  //   //   controlButton.classList.add(this.mapEditMode ? 'edit-mode':'view-mode');
  //   //   const icon = document.createElement('i');
  //   //   icon.classList.add('fa fa-edit');
  //   //   controlButton.appendChild(icon);
  //   // });
  // }
}

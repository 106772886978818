
<div class="container-fluid">
    <div class="container-card">
      <div class="container-card-body">
        <div class="tab_section">
          <div class="tab" *ngFor="let tabitem of tabData" [class.active]="staffType == tabitem.value"
            (click)="staffType = tabitem.value;onSelectStaff()">
            <p>{{tabitem.tabname|translate}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <!-- *ngIf="userService.permissionMap.get('add_user_access')==true ||userService.permissionMap.get('edit_user_access')==true "> -->
    <div class="card-header mb-1" style="margin-top: 3px;">
                <div class="header-block mb-1">
                  <h3 class="card-title">
                    {{title | translate}}
                  </h3>
                </div>
              </div>

              <div class="card-body">

                <!-- <section class="open_sec"> -->
                <div class="row">
                    <div *ngIf="role.user_role=='AXESTRACK'"
                      class="form-group col-xl-3 col-lg-6 col-sm-12">
                      <label for="my-input">{{"company" | translate}}</label>
                      <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                        display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                      </ps-auto-suggestion>

                    </div>

                    <div *ngIf="role.user_role=='AXESTRACK' || role.user_role=='COMPANYADMIN'"
                      class="form-group col-xl-3 col-lg-6 col-sm-12">
                      <label for="my-input">{{"plant" | translate}}</label>
                      <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantData"
                        display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                      </ps-auto-suggestion>

                    </div>

                    <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="staffType == 'USER'">
                      <label for="my-input" class="required"> {{"User" | translate}} </label>
                      <ps-auto-suggestion  (onSelected)="selectedUser($event)" [data]="userList"
                            display="username" placeholder="Choose User" [preSelected]="{username:userName}">
                      </ps-auto-suggestion>
                      <div class="error" *ngIf="typeValidator && typeValidator.error">
                        <span>{{typeValidator.msg}} </span>
                      </div>
                    </div>

                    <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="staffType == 'STAFF'">
                      <label for="my-input" class="required"> {{"Staff Type" | translate}} </label>
                      <ps-auto-suggestion  (onSelected)="onSelectEmployeeType($event)" [data]="staffTypeList"
                        display="type_name" placeholder="Choose Staff Type" [preSelected]="{type_name:staffTypeName}">
                      </ps-auto-suggestion>
                      <div class="error" *ngIf="typeValidator && typeValidator.error">
                        <span>{{typeValidator.msg}} </span>
                      </div>
                    </div>

                    <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="staffType == 'STAFF'">
                      <label for="my-input" class="required"> {{"Staff" | translate}} </label>
                      <ps-auto-suggestion  (onSelected)="onSelectEmployee($event)" [data]="staffList"
                        display="first_name" placeholder="Choose Staff" [preSelected]="{first_name:staffName}">
                      </ps-auto-suggestion>
                      <div class="error" *ngIf="staffNameValidator && staffNameValidator.error">
                        <span>{{staffNameValidator.msg}} </span>
                      </div>
                    </div>

                    <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                      <label for="my-input" class="required">{{'unique_tag_type'|translate}}</label>
                      <select class="form-control" [(ngModel)]="uniqueTagType">
                        <option *ngFor="let tag of tagType" [value]="tag">{{ tag }}</option>
                      </select>
                      <div class="error" *ngIf="uniqueTagTypeValidator && uniqueTagTypeValidator.error">
                        <span>{{uniqueTagTypeValidator.msg}} </span>
                      </div>
                    </div>

                    <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                        <label for="my-input" class="required">{{'unique_tag_code'|translate}}</label>
                        <input class="form-control" type="text" name="uniqueTagCode" [(ngModel)]="uniqueTagCode">
                        <div class="error" *ngIf="uniqueTagCodeValidator && uniqueTagCodeValidator.error">
                            <span>{{uniqueTagCodeValidator.msg}} </span>
                          </div>
                    </div>

                  </div>

                  <div class="form-group action-block">
                    <div class="btn-block">
                      <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                        translate}}</button>
                      <button class="btn btn-warning" (click)="resetDetail()">{{"reset" | translate}}</button>
                      <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn}} </button>
                    </div>
                  </div>

              </div>


                <div class="card-header" style="margin-top: 4px;margin-bottom: 4px;">
                  <div class="header-block" >
                    <h3 class="card-title" *ngIf="this.staffType=='USER'">
                      {{"user_list" | translate}}
                    </h3>
                    <h3 class="card-title" *ngIf="this.staffType=='STAFF'">
                      {{"Staff List" | translate}}
                    </h3>
                  </div>
                </div>

                <div class="card-body">
                  <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" *ngIf="this.staffType=='USER'">

                    <thead>
                      <tr>
                        <th>{{"s.no." | translate}}</th>
                        <th>{{"user_name" | translate}}</th>
                        <th>{{"Tag Type" | translate}}</th>
                        <th>{{"Tag code" | translate}}</th>
                        <th *ngIf="role.user_role=='AXESTRACK'">{{"company" | translate}}</th>
                        <th *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{"Plant"| translate}}</th>

                        <th class="action">{{"action" | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of userMappingList; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{ row.first_name }} {{ row.last_name }}</td>
                        <td>{{row.tag_type}}</td>
                        <td>{{row.tag_id}}</td>
                        <td *ngIf="role.user_role=='AXESTRACK'">{{row.company_name}}</td>
                        <td *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{row.plant_name}}</td>
                        <td class="action">
                          <div class="btn-block">
                            <button class="btn btn-success" (click)="editUser(row)">{{"edit" | translate}}
                              <!-- *ngIf="userService.permissionMap.get('edit_user_access')==true">  -->
                            </button>
                            <button class="btn btn-danger" (click)="confirmAlert(row)"
                              >{{"delete"
                              | translate}}</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" *ngIf="this.staffType=='STAFF'">

                    <thead>
                      <tr>
                        <th>{{"s.no." | translate}}</th>
                        <th>{{"Staff Name" | translate}}</th>
                        <th>{{"Staff Type" | translate}}</th>
                        <th>{{"Tag Type" | translate}}</th>
                        <th>{{"Tag code" | translate}}</th>
                        <th *ngIf="role.user_role=='AXESTRACK'">{{"company" | translate}}</th>
                        <th *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{"Plant"| translate}}</th>
                        <th class="action">{{"action" | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of staffMappingList; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{ row.first_name }} {{ row.last_name }}</td>
                        <td><span class="pill"
                          [ngClass]="{'bg-green-200 text-green-800':row.type_name=='Visitor','bg-yellow-200 text-yellow-800':row.type_name=='Contractual','bg-pink-200 text-pink-800':row.type_name=='Labour','bg-indigo-200 text-indigo-800':row.type_name=='Security'}">
                         {{row.type_name}}
                        </span></td>
                        <td>{{row.tag_type}}</td>
                        <td>{{row.tag_id}}</td>
                        <td *ngIf="role.user_role=='AXESTRACK'">{{row.company_name}}</td>
                        <td *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{row.plant_name}}</td>
                        <td class="action">
                          <div class="btn-block">

                            <button class="btn btn-success" (click)="editEmployee(row)"
                              *ngIf="userService.permissionMap.get('edit_user_access')==true"> {{"edit" | translate}}
                            </button>
                            <button class="btn btn-danger" (click)="confirmAlert(row)">{{"delete"
                              | translate}}</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
        <!-- </section> -->

            </div>
          </div>
        </div>



      </div>
    </div>
  </div>

<div class="container-fluid">

  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                {{"Alert Task Dashboard"| translate}}
              </h4>
            </div>

            <div class="card-body">
              <div class="tab_btnn_sec">
                <div class="tab_btnn" *ngFor="let tab of actionLabels;index as i">
                  <button type="button" class="btn btn-block nav-buttons" (click)="changeTabs(i , tab)"
                    [ngClass]="{'btn-inactive':activeTab != i,'btn-active':activeTab == i}"
                    [ngStyle]="{'background-color': tab.tabBgColor}">
                    <div class="left_part">
                      <div class="tab_name">{{tab.status | translate}}</div>
                      <div class="tab_number">{{tab.count}}</div>
                    </div>
                    <div class="icon_part">
                      <div class="gif_icon" [ngStyle]="{ 'background-color':tab.tabIconBg }">
                        <img src="assets/images/alert-gif.gif" />
                      </div>
                    </div>
                  </button>
                </div>
              </div>

              <div class="row" *ngIf="activeTabName!='ACTIVE'&& activeTabName!= 'INPROGRESS'"
                style="margin-bottom: 10px;">
                <div class="col-md-4 date">
                  <label>{{'start_date:'|translate}}</label>
                  <input type="date" class="form-control" [(ngModel)]="startDate">
                </div>
                <div class="col-md-4 date">
                  <label>{{'end_date:'|translate}}</label>
                  <input type="date" class="form-control" [min]="startDate" [(ngModel)]="endDate">
                </div>
                <button class="btn btn-primary search" aria-hidden="true" title="Form"
                  (click)="getAlertTasks()">{{'search'|translate}}</button>
              </div>

              <div class="row">

                <div class="col-md-12">
                  <div class="table-responsive pt-2">
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{'serial_no'|translate}}</th>
                          <th>{{'title'|translate}}</th>
                          <th>{{'condition'|translate}}</th>
                          <th>{{'range'|translate}}</th>
                          <th>{{'created_at'|translate}}</th>
                          <th>{{'modified_at'|translate}}</th>
                          <th>{{'status' | translate}}</th>
                          <th>{{'Actions'|translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of alertTasks;index as i">
                          <td>{{i+1}}</td>
                          <td> {{item.title ? item.title : "-"}}</td>
                          <td>{{item.alert_condition?item.alert_condition : "-"}}</td>
                          <td>{{item.range ? item.range:"-"}}</td>
                          <td>{{item.created_at ?
                            (common.dateFormatter(item.created_at,"ddMMYYYY")):"-"}}</td>
                          <td>{{item.modified_at ?
                            (common.dateFormatter(item.modified_at,"ddMMYYYY")):"-"}}</td>
                          <td> <span class="badge status-badge"
                              [ngClass]="{'badge-success':item.status == 'COMPLETED','badge-info':item.status == 'INPROGRESS','badge-danger':item.status == 'CANCELLED','badge-secondary':item.status == 'ACTIVE'}">{{(item.status|translate)}}</span>
                          </td>
                          <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-form" style="background-color: #3d9c26;" aria-hidden="true"
                                title="Form" *ngIf="activeTabName=='ACTIVE' && item.mapping_type=='TASK'"
                                (click)="updateAlertStatus(item,'START')">
                                {{'pick_alert'|translate}}
                              </button>

                              <button class="btn btn-form" aria-hidden="true" title="Form"
                                style="background-color: #FF9914;"
                                *ngIf="(activeTabName=='ACTIVE'|| activeTabName=='INPROGRESS') && item.mapping_type=='INFO'"
                                (click)="viewData(item)">
                                <i class="fa fa-eye">{{'view'|translate}}</i>
                              </button>

                              <button class="btn btn-form" style="background-color: #2c65b2;" aria-hidden="true"
                                title="Form"
                                *ngIf="(activeTabName=='ACTIVE'|| activeTabName=='INPROGRESS') && item.mapping_type=='TASK'"
                                (click)="openForm(item)">
                                {{'perform'|translate}}
                              </button>

                              <button class="btn btn-info-icon" style="background-color: #FF9914;" aria-hidden="true"
                                title="Form" *ngIf="activeTabName!='ACTIVE' && activeTabName!='INPROGRESS'"
                                (click)="viewData(item)">
                                <i class="fa fa-eye" aria-hidden="true">{{'view'|translate}}</i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import * as Highcharts from 'highcharts';
// import more from 'highcharts/highcharts-more';
// more(Highcharts);

@Component({
  selector: 'app-inventory-dashboard',
  templateUrl: './inventory-dashboard.component.html',
  styleUrls: ['./inventory-dashboard.component.scss'],
})
export class InventoryDashboardComponent implements OnInit {
  toggleAccordion: boolean = false;
  toggleAccordion1: boolean = false;
  toggleAccordion2: boolean = false;

  constructor(public breadcrumbService: BreadcrumbService) {}

  ngOnInit() {
    this.breadcrumbService.setActiveBreadCrumb(false);
    this.radialGraphFun();
    this.lineGraphFun();
    this.horizontalBarFun();
    this.verticalBarFun();
  }

  //test
  //test hot _fixes
  radialGraphFun(): void {
  const categories = [
    'Metal Pallet','Plastic Pallet','Wood Pallet','Fork Lift'
  ]


    const options: any = {
    chart: {
      type: 'bar',
      height:335
    },
    title: {
      text: '',
      align: 'left'
    },
    accessibility: {
      point: {
        valueDescriptionFormat: '{index}. Category {xDescription}, {value}.'
      }
    },

    xAxis: [{
      categories: categories,
      reversed: false,
      labels: {
        step: 1
      },
      accessibility: {
        description: 'Pallet Categories'
      }
    }, { // mirror axis on right side
      opposite: true,
      reversed: false,
      categories: categories,
      linkedTo: 0,
      labels: {
        step: 1
      },
      accessibility: {
        description: 'Pallet Categories'
      }
    }],
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        format: '{value}'
      },
      accessibility: {
        description: 'Pallet counts'
      }
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        borderRadius: '1%',
        pointWidth:15,
      }
    },
    tooltip: {
      format: '<b>{series.name}, Category {point.category}</b><br/>' +
        'Pallet Count: {point.y} pallets'
    },
    exporting: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
    series: [{
      dataLabels: {
        enabled: true,
        inside: true,
        align: 'right',
        verticalAlign: 'middle',
        x:30,
        formatter: function () {
          return this.point.category;
        },
        style: {
          fontWeight: 'bold',
        },
      },
      name: 'Out',
      color: '#ffd8d8',
      borderColor:'#ff9393',
      data: [
        -50, -70, -100, -120 // Example counts for Male
      ]
    }, {
      name: 'In',
      color: '#d0ffd0',
      borderColor: '#6eff6e',
      data: [
        60, 80, 90, 110 // Example counts for Female
      ]
    }]
  };
      // Initialize the chart in the 'radialgraph1' container
      Highcharts.chart('radialgraph1', options);
    }

  lineGraphFun(): void {
    const options: any = {
      title: {
        text: '',
        align: 'left',
      },

      subtitle: {
        text: '',
        align: 'left',
      },

      chart: {
        height: 335,
      },

      yAxis: {
        title: {
          text: 'Number of Assets',
        },
      },

      xAxis: {
        accessibility: {
          rangeDescription: 'Range: 2010 to 2022',
        },
      },

      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: 2010,
          // dataLabels:{
          //   enabled:true,
          //   formate:'{y}'
          // }
        },
      },

      series: [
        {
          name: 'Total',
          color:'#e3e345',
          data: [
            43934, 48656, 65165, 81827, 112143, 142383, 171533, 165174, 155157,
            161454, 154610, 168960, 171558,
          ],
        },
        {
          name: 'In',
          color:'#6eff6e',
          data: [
            24916, 37941, 29742, 29851, 32490, 30282, 38121, 36885, 33726,
            34243, 31050, 33099, 33473,
          ],
        },
        {
          name: 'Out',
          color:'#ff9393',
          data: [
            11744, 30000, 16005, 19771, 20185, 24377, 32147, 30912, 29243,
            29213, 25663, 28978, 30618,
          ],
        }
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },

      exporting: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },
    };

    // Initialize the chart in the 'trip-activity' container
    Highcharts.chart('linegraph1', options);
  }

  horizontalBarFun(): void {
    const options: any = {
      chart: {
        type: 'bar',
        height: 300,
      },
      title: {
        text: '',
        align: 'left',
      },
      xAxis: {
        categories: ['Small','Medium', 'Large','Double-sided'],
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: 'Other',
          data: [400, 520, 930, 5000],
        },
        {
          name: 'Inside',
          data: [1898, 2546, 2695, 4416],
        },
        {
          name: 'Outside',
          data: [10721, 12242, 13518, 20037],
        },
      ],

      exporting: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },
    };

    // Initialize the chart in the 'trip-activity' container
    Highcharts.chart('horizontalbargraph1', options);
  }

  verticalBarFun(): void {
    const options: any = {
      chart: {
        type: 'column',
        height: 300,
      },
      title: {
        align: 'left',
        text: ''
      },
      subtitle: {
        align: 'left',
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        categories: [
          'Metal Pallet',
          'Plastic Pallet',
          'Wood Pallet',
          'JCB',
          'Fork Lift',
          'Truck',
          'Other'
        ],
        title: {
          text: ''
        }
      },
      yAxis: {
        title: {
          text: 'Number of Inventory'
        }
      },
      legend: {
        enabled: true
      },
      plotOptions: {
        column: {
          grouping: true, // Ensures grouping of columns
          shadow: false,
          borderWidth: 0,
          pointWidth:30 ,
          // borderRadius:'1%'
        },
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.y}'
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>'
      },
      series: [
        {
          name: 'Total',
          data: [
            200, 100, 150, 90, 90, 35, 20
          ],
          // color: '#fbfbc6',
          // borderColor:'#e3e345',
          // borderWidth:2
        },
        {
          name: 'In',
          data: [
            100, 30, 120, 50, 60, 20, 15
          ],
          // color: '#ffd8d8',
          // borderColor:'#ff9393',
          // borderWidth:2

        },
        {
          name: 'Out',
          data: [
            100, 70, 30, 40, 30, 15, 5
          ],
          // color: 'red',
          // borderColor:'#6eff6e',
          // borderWidth:2
        }
      ],
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    };

    // Initialize the chart in the 'verticalbargraph1' container
    Highcharts.chart('verticalbargraph1', options);
  }
}

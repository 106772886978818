import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-visitor-reports',
  templateUrl: './visitor-reports.component.html',
  styleUrls: ['./visitor-reports.component.scss'],
})
export class VisitorReportsComponent implements OnInit {
  activeSection: string = 'visitor';
  selectedOption: any = 'meeting';
  visitorList: any = [];
  contact_no: any = null;

  constructor(
    public table: TableService,
    public common: CommonService,
    public api: ApiService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Visitors', url: null },
      { name: 'Visitor Dashboard', link: '/user/visitor-dashboard' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  @ViewChild(DataTableDirective) dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 10, 'VISITOR LIST');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  tabData: any = [
    { tabname: 'By Visitor', value: 'visitor' },
    { tabname: 'By Employee', value: 'employee' },
  ];

  dropdownData: any = [
    { type: 'Meeting Type', value: 'meeting' },
    { type: 'Meet With', value: 'meetwith' },
  ];

  ngOnInit(): void {
    this.dtTrigger.next();
    this.getVisitorReport(1);
  }

  getVisitorReport(flag: any) {
    console.log(this.contact_no);
    let params: any;
    if (flag == 1) {
      params = {
        contact_no: null,
      };
    } else {
      if (this.contact_no == '') {
        params = {
          contact_no: null,
        };
      } else {
        params = {
          contact_no: this.contact_no,
        };
      }
    }
    this.common.loading = true;
    this.api.post('api/get-visitor-report', params, null, 'VMS').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.visitorList = res;
        this.renderTable();
        console.log('visitor report', this.visitorList);
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    );
  }
}

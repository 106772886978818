<div class="container-fluid">

  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_stage_access')==true ||userService.permissionMap.get('edit_stage_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title"> {{title | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'COMPANYADMIN'">
                  <label for="my-input" class="required">{{"select_plant" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectPlant($event)" [data]="plantList"
                    display="plantName" placeholder="Choose Plant"
                    [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{plantNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"stage_name" | translate}}</label>
                  <input class="form-control" type="text" name="stageName" [(ngModel)]="stage.stageName">
                  <div class="error" *ngIf="stageNameValidator && stageNameValidator.error">
                    <span>{{stageNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"stage_description" | translate}}</label>
                  <input class="form-control" type="email" name="stageDescription" [(ngModel)]="stage.stageDescription">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="sel1" class="required">{{"stage_type" | translate}}</label>
                  <select class="form-control" id="sel1" name="stageType" [(ngModel)]="stage.stageType">
                    <option *ngFor="let st of stageTypeList; let i = index" [value]="st.stageTypeId">
                      {{st.stageTypeName}}
                    </option>
                  </select>
                  <div class="error" *ngIf="typeValidator && typeValidator.error">
                    <span>{{typeValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"select_process" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectProcess($event)" isMultiSelect="true" [data]="processList"
                    display="processName" placeholder="Choose process" [preSelected]="selectedProcesses">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error">
                    <span>{{processValidator.msg}} </span>
                  </div>
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"minimum_vehicle_count" | translate}}</label>
                  <input class="form-control" type="number" name="min_vechile_count"
                    [(ngModel)]="stage.minVechileCount">
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"maximum_vehicle_count" | translate}}</label>
                  <input class="form-control" type="number" [min]="stage.minVechileCount" name="max_vechile_count"
                    [(ngModel)]="stage.maxVechileCount">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required">{{"status"| translate}}</label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageStatus" [(ngModel)]="stage.stageStatus"
                      id="stage_status_1" value="ACTIVE" checked>
                    <label for="exampleRadios1">
                      {{"active" | translate}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageStatus" [(ngModel)]="stage.stageStatus"
                      id="stage_status_2" value="INACTIVE">
                    <label for="exampleRadios2">
                      {{"inactive" | translate}}
                    </label>
                  </div>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"Is GPS Enabled" | translate}}<input type="checkbox"
                      class="form-control set-checkbox" [(ngModel)]="isGPSEnabled">
                  </label>
                </div>
              </div>

              <div class="form-group  action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    translate}}</button>
                  <button class="btn btn-warning" (click)="resetStage();this.role.user_role=='COMPANYADMIN'?resetData():null">{{"reset" | translate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{buttonName | translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"stage_list" | translate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">

                <thead class="thead-light">
                  <tr>
                    <th>{{"serial_no" | translate}}.</th>
                    <th>{{"stage_name" | translate}}</th>
                    <th>{{"process_name" | translate}}</th>
                    <th>{{"stage_description" | translate}}</th>
                    <th>{{"stage_type" | translate}}</th>
                    <th>{{"minimum_vehicle_count" | translate}}</th>
                    <th>{{"maximum_vehicle_count" | translate}}</th>
                    <th>{{"status" | translate}}</th>
                    <th class="action stage-action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of responseData let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.stageName}}</td>
                    <td>{{row.processName}}</td>
                    <td>{{row.stageDescription}}</td>
                    <td>{{row.stageTypeName}}</td>
                    <td>{{row.minVehicleCount}}</td>
                    <td>{{row.maxVehicleCount}}</td>
                    <td>{{row.stageStatus}}</td>
                    <td class="action stage-action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewStage(row)"
                          *ngIf="userService.permissionMap.get('view_stage_access')==true">{{"view" |
                          translate}}</button>
                        <button class="btn btn-success" (click)="editStage(row)"
                          *ngIf="userService.permissionMap.get('edit_stage_access')==true">{{"edit" |
                          translate}}</button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)"
                          *ngIf="userService.permissionMap.get('delete_stage_access')==true">{{"delete" |
                          translate}}</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</div>

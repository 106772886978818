<div class="card">
    <div class="card-header">
        <div class="header-block">
            <h3 class="card-title">
                {{"History"}}
            </h3>
        </div>
    </div>

    <div class="card-body">
        <div class="timeline_area section_padding_130">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Timeline Area-->
                        <div class="apland-timeline-area">
                            <!-- Single Timeline Content-->
                            <div class="single-timeline-area" *ngFor="let data of history;let i=index">
                                <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s"
                                    style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                                    <p>{{data.createdAt| date:'medium'}}</p>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s"
                                            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                            <div class="timeline-icon"> <img
                                                    src="assets/images/icons8-gold-bars-32.png"></div>
                                            <div class="timeline-text">
                                                <h6>{{data.logMessage}}</h6>
                                                <ul *ngFor="let change of data.logData">
                                                    <li *ngIf="data.logType=='ASSET_UPDATE'">{{change.field_name+" is
                                                        updated from "+(change.from==""?null:change.from)+" to
                                                        "+(change.to==""?null:change.to)}}</li>
                                                    <li *ngIf="data.logType=='NEW_REQUEST'">{{"By User
                                                        "+change.user_name}}</li>
                                                    <li *ngIf="data.logType=='DOC_ADD'">{{"Document "+change.doc_name+"
                                                        has been added"}}</li>
                                                    <li *ngIf="data.logType=='DOC_REMOVE'">{{"Document
                                                        "+change.doc_name+" has been removed"}}</li>
                                                    <li *ngIf="data.logType=='WARRANTY_ADD'">{{"Warranty has been
                                                        added"}}</li>
                                                    <li *ngIf="data.logType=='WARRANTY_REMOVE'">{{"Warranty has been
                                                        removed"}}</li>
                                                    <li *ngIf="data.logType=='INSURANCE_ADD'">{{"Insurance has been
                                                        added"}}</li>
                                                    <li *ngIf="data.logType=='INSURANCE_REMOVE'">{{"Insurance has been
                                                        removed"}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { CompanyData, documentType, PlantData } from 'src/app/dataType/assetInterface';
import { validator } from 'src/app/dataType/commonInterface';

@Component({
  selector: 'app-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.scss']
})
export class DocumentTypeComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'Document Type List');


  documentType = {
    documentTypeId: '',
    documentTypeName: '',
    documentTypeStatus: 1,
    plantId:null,
    companyId:null
  };
  buttonName = 'save';
  title = 'Add Document Type';
  responseMessage = '';
  documentTypeList: documentType[] = [];

  statusScope : string = null;
  companyName: string=null;
  plantName: string=null;
  companyData: CompanyData[] = [];
  plantData: PlantData[] = [];

  companyValidator: validator;
  plantValidator: validator;
  documentTypeNameValidator: validator=null;
  statusValidator: validator=null;
  plantLevelRoles = ['AXESTRACKUSER' , 'COMPANYUSER' , 'PLANTADMIN', 'PLANTUSER'];

  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public alertService: AlertService,
    public apiService: ApiService,
    public role: RoleService,
    private plantService: PlantService,

  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/Document Type' },
      { name: 'Stage', url: null },
      { name: 'Document Type', link: '/user/list_stage_type' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    }else{
      if (this.role.user_role != 'AXESTRACK'){
        this.getPlantById(this.userService._loggedInUser.companyId);
      }
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAllDocumentType();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnInit(): void {
    this.statusScope = this.role.user_role == 'AXESTRACK' ? 'GLOBAL' : 'COMPANY'
  }

  getCompany() {
    this.apiService.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }


  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
          resolve(this.plantData);
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
        reject(null);
      }
    );
  });
  }

  selectedCompany(event: any) {
    this.documentType.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.documentType.plantId = null;
  }

  selectedPlant(event: any) {
    this.documentType.plantId = event.plantId;
    this.plantName = event.plantName;
  }

  // Save Work Type
  saveDocumentType(): void {
    let data = {
      documentTypeId: this.documentType.documentTypeId,
      documentTypeName: this.documentType.documentTypeName.trim(),
      documentTypeStatus: this.documentType.documentTypeStatus,
      plantId : this.documentType.plantId,
      companyId : this.documentType.companyId,
      userRole : this.role.user_role
    };

    this.apiService.post("document/save-document-type",data).subscribe(
      (response:any) => {
        if (response.status) {
          this.common.loading = false;
          this.alertService.success(response.message);
          this.resetDocument();
          this.getAllDocumentType();
        }
      },
      (error) => {
        this.common.loading = false;
        console.error(error);
      }
    );
  }

  getAllDocumentType() {
    this.common.loading = true;
    this.apiService.get("document/get-all-document").subscribe(
      (res: any) => {
        this.common.loading = false;
        this.documentTypeList = res.data;
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  viewDocument(row?: any) {
    this.common.params = { details: [row], title: 'Document Type Details' };
    this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Document Type ',
      description:
        `<b>&nbsp;` + 'Are you sure, Do you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteDocument(row);
      }
    });
  }

  deleteDocument(row?: any) {
    this.common.loading = true;
    let data = {
      documentTypeId: row.id,
      documentTypeName: row.type_name.trim(),
      documentTypeStatus: 0,
      plantId:row.plantId,
      companyId:row.companyId,
      userRole : this.role.user_role
    };

    this.apiService.post("document/save-document",data).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alertService.success(res.message);
        this.getAllDocumentType();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  async editDocument(row?: any) {
    this.common.gotoTop();
    this.resetDocument();
    if(this.role.user_role == 'AXESTRACK' || this.role.user_role== 'COMPANYADMIN'){
      this.statusScope = row.plant_id == 1 ? 'GLOBAL' : row.plant_id == null ? 'COMPANY' : 'PLANT';
    }
    await this.getPlantById(row.company_id);
    this.companyName = this.companyData.find((company) => company.companyId == row.company_id)?.companyName;
    this.plantName = this.plantData.find(plant => plant.plantId == row.plant_id)?.plantName ;
    this.buttonName = 'Update';

    this.documentType.documentTypeId = row.id;
    this.documentType.documentTypeName = row.type_name;
    this.documentType.documentTypeStatus = row.status;
    this.documentType.plantId = row.plant_id;
    this.documentType.companyId =  row.company_id;

    this.title = 'Edit Document Type';
  }

  resetDocument() {
      this.buttonName = 'Save';
      this.documentType.documentTypeId = null;
      this.documentType.documentTypeName = null;
      this.documentType.documentTypeStatus = 1;
      this.documentType.plantId= null;
      this.documentType.companyId = null;
      this.documentTypeNameValidator = null;
      this.plantValidator = null;
      this.companyValidator = null;
      this.companyName = null;
      this.plantName = null;
      this.statusScope = this.role.user_role == 'AXESTRACK' ? 'GLOBAL' : 'COMPANY';
      this.plantData=[];
  }


  formValidator() {
    this.documentTypeNameValidator = this.common.nullValidator(
      this.documentType.documentTypeName.trim(),
      'Document Name Mandatory'
    );
    this.companyValidator = this.common.nullValidator(
      this.documentType.companyId,
      'Company is Mandatory'
    );
    this.plantValidator = this.common.nullValidator(
      this.documentType.plantId,
      'Plant is Mandatory'
    );
    if(this.role.user_role == 'AXESTRACK' && this.statusScope == 'COMPANY'){
      if (!this.documentTypeNameValidator.error && !this.companyValidator.error) {
        this.saveDocumentType();
      }
    }else if(this.role.user_role == 'AXESTRACK' && this.statusScope == 'PLANT'){
      if (!this.documentTypeNameValidator.error && !this.companyValidator.error && !this.plantValidator.error) {
        this.saveDocumentType();
      }
    }
    else{
      if (!this.documentTypeNameValidator.error) {
        this.saveDocumentType();
      }
    }
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'My Bookings'|translate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="tab_btnn_sec">
                <div class="tab_btnn" *ngFor="let tab of actionLabels;index as i">
                  <button type="button" class="btn btn-block nav-buttons" (click)="changeTabs(i , tab)"
                    [ngClass]="{'btn-inactive':activeTab != i,'btn-active':activeTab == i}"
                    [ngStyle]="{'background-color': tab.tabBgColor}">
                    <div class="left_part">
                      <div class="tab_name">{{tab.tabName | translate}}</div>
                      <div class="tab_number">{{tab.tabName == 'Booked' ? bookingCount?.bookedcount : (tab.tabName
                        == "Inprogress" ? bookingCount?.inprogresscount : (tab.tabName == 'Completed' ?
                        bookingCount?.completedcount : bookingCount?.cancelledcount))}}</div>
                    </div>

                    <div class="right_part">
                      <div class="icon_div" [ngStyle]="{'background-color': tab.tabIconBg}">
                        <i [class]="'fa fa-'+tab.tab_icon" [ngClass]="tab.tabName"></i>
                      </div>
                    </div>
                  </button>
                </div>
              </div>


              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-dark">
                  <tr>
                    <th>{{'serial_no'|translate}}</th>
                    <th style="width: 11%;">{{'vehicle'| translate}}</th>
                    <th>{{'Driver'| translate}}</th>
                    <th>{{'status'|translate}}</th>
                    <th [hidden]="activeTabName != 'Inprogress'">{{ "Current Location" | translate }}</th>
                    <th>{{'Dock'| translate}}</th>
                    <th>{{'Dock Start time'|translate}}</th>
                    <th>{{'Dock End time'|translate}}</th>
                    <!-- <th>{{'plant'|translate}}</th> -->
                     <th>{{'product'|translate}}</th>
                    <th [hidden]="activeTabName != 'Booked'" class="action">{{'Actions'|translate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let group of responseData let ind=index">
                    <!-- <tr *ngFor="let row of responseData let i = index"> -->
                      <tr *ngFor="let row of group.data; let j = index">
                      <!-- <td>{{ i+1 }}</td> -->
                      <td [hidden]="j != 0" [attr.rowspan]="group.data.length">{{ ind + 1 }}</td>
                      <td [hidden]="j != 0" [attr.rowspan]="group.data.length">{{group.vehicle_rsn}}
                        <div class="image" *ngIf="group.status != 'Booked' && group.status != 'Cancelled'">
                          <img class="icon"
                            src="assets/images/trip-tracking(3).png" alt="" (click)="tripTrackingModal(group)">
                        </div>
                      </td>
                      <td [hidden]="j != 0" [attr.rowspan]="group.data.length">{{group.driver_name}}</td>
                      <td [hidden]="j != 0" [attr.rowspan]="group.data.length">
                      <!-- <td>{{row.vehicle_rsn}}</td> -->
                      <!-- <td>{{row.driver_name}}</td> -->
                      <!-- <td> -->
                        <span class="pill"
                          [ngClass]="{'bg-blue-100 text-blue-800':group.status=='Booked','bg-green-200 text-green-800':group.status=='Completed','bg-yellow-200 text-yellow-800':group.status=='Inprogress','bg-orange-100 text-orange-800':group.status=='Loaded','bg-red-200 text-red-800':group.status=='Cancelled'}">{{group.status}}
                        </span>
                      </td>
                      <td [hidden]="activeTabName != 'Inprogress'">{{row.stage_name}}</td>
                      <td>{{row.stage_destination_code}}</td>
                      <td>{{row.start_time}}</td>
                      <td>{{row.end_time}}</td>
                      <!-- <td>{{row.plant_name}}</td> -->
                       <td>{{row.product_name}}</td>

                      <td class="action" [hidden]="activeTabName != 'Booked'">
                        <div class="btn-block">

                          <!-- <button class="btn btn-primary" (click)="view(row)" > {{'view'|translate}}
                      </button> -->
                      <button class="btn btn-success" *ngIf="checkDateTime(row.start_time)" (click)="openBookingModal(row,group)"> {{'edit'|translate}}
                      </button>
                      <button class="btn btn-danger cancel-booking-btn" *ngIf="checkDateTime(row.start_time)" (click)="confirmAlert(row.slot_queue_id)"> {{'cancel'|translate}}
                      </button>
                      <span class="past-appointment" *ngIf="!checkDateTime(row.start_time)">Past Appointment</span>
                      <!-- <button class="btn btn-danger" *ngIf="checkEdit(row.company_id,row.plant_id)" (click)="confirmDelete(row)">
                        {{'delete'|translate}} </button> -->
                        </div>
                      </td>
                    </tr>
                  </ng-container>

                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

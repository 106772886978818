import { CommonService } from 'src/app/@core/services/common.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label, Color } from 'ng2-charts';
import { UserService } from 'src/app/@core/services/user.service';
@Component({
  selector: 'app-tripactivity',
  templateUrl: './tripactivity.component.html',
  styleUrls: [
    '../dashboard/dashboard.component.scss',
    '../tripactivity/tripactivity.component.scss',
  ],
})
export class TripactivityComponent implements OnInit {
  tripname: string = '';
  public tripChartData: any[] = [];
  public tripChartType: ChartType = 'doughnut';
  public pieChartPlugins = [];
  public pieChartLegend = true;
  filters: any[] = [{'filter':'today'}, {'filter':'last 7 days'}, {'filter':'last 30 days'}, {'filter':'last 365 days'}];
  defaulttripindex: string = 'last 7 days';
  tripitem: any[] = [];
  tripLabels: Label[] = [];
  tripData: SingleDataSet = [];
  selectedtrip: any;
  tripindex: string = 'last 7 days';
  allProcess: any = [];
  processIndex: number = 0;
  defaultprocess: string = '';
  public barChartColors: Color[] = [
    { backgroundColor: this.common.graphsecondary },
    { backgroundColor: this.common.graphsuccess },
    { backgroundColor: this.common.graphdanger },
  ];

  public piechartoptions: ChartOptions = {
    responsive: true, // Disable responsiveness
    maintainAspectRatio: false, // Allow chart to adjust dimensions
    legend: {
      align: 'center',
    },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#3f7ac7',
        font: {
          weight: 'bold',
          size: 12,
        },
      },
    },
  };
  public pieChartColors = [
    {
      backgroundColor: [
        'rgba(255,120,0,0.5)',
        'rgba(120,255,0,0.5)',
        'rgba(0,120,255,0.5)',
        'rgba(255,0,120,0.5)',
        'rgba(255,0,120,0.5)',
      ],
    },
  ];
  constructor(
    public workflow: DashboardService,
    public common: CommonService,
    public userService: UserService
  ) {
    this.getAllProcess();
  }

  getEvent(event: any) {
    this.tripindex = event.filter;
    this.getTrips(this.processIndex);
  }

  getTrips(processId) {
    this.defaulttripindex = this.filters[1].filter;
    this.tripChartData = [];
    this.tripLabels = this.tripLabels;
    this.workflow.getworkflowStatus(this.tripindex, processId).subscribe(
      (item: any) => {
        if (item.status) {
          if (item.data != null && item.data.length) {
            this.tripitem = item.data[0];
            let selected: any[] = this.tripitem;
            this.selectedtrip = this.tripitem;
            this.tripLabels = Object.keys(selected);
            this.tripData = Object.values(selected);
            this.tripChartData = this.tripData;
          }
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  ngOnInit(): void {}

  getAllProcess() {
    this.workflow.getAllProcess().subscribe((item: any) => {
      if (item.status) {
        this.allProcess = item.data;
        this.defaultprocess = this.allProcess[0].processName;
        this.processIndex = this.allProcess[0].processId;
        this.getTrips(this.allProcess[0].processId);
      }
    });
  }

  getprocessindex(event: any) {
    this.processIndex = event.processId;
    this.getTrips(this.processIndex);
  }
}

import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ApiService } from 'src/app/@core/services/api.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

@Component({
  selector: 'app-geofence-modal',
  templateUrl: './geofence-modal.component.html',
  styleUrls: ['./geofence-modal.component.scss'],
})
export class GeofenceModalComponent implements OnInit {
  dataIndex: number;
  allGeoFence: any;
  dataLength: number;
  modalAction: boolean;
  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private common: CommonService,
    private activeModal: NgbActiveModal,
    private alert:AlertService
  ) {
    this.dataIndex = this.common.params.index;
    this.getGeoFence();
  }
  ngOnInit(): void {}

  getGeoFence() {
    let allGeoFence = this.common.params.geoFenceData || {};
    const { stages, destinations, zones,pzone,hardware } = allGeoFence;
    allGeoFence.stages = stages || [];
    allGeoFence.destinations = destinations || [];
    allGeoFence.zones = zones || [];
    allGeoFence.pzone = pzone || [];
    allGeoFence.hardware = hardware || [];
    this.allGeoFence = this.getMergedGeoFences(
      allGeoFence,
      allGeoFence.stages,
      allGeoFence.destinations,
      allGeoFence.zones,
      allGeoFence.pzone,
      allGeoFence.hardware
    );
    this.dataLength = this.allGeoFence.reduce(
      (a: any, b: any) => a + b.data.length,
      0
    );
  }

  getMergedGeoFences(plant, stages, destinations, zones, pzone, hardware) {
    let plantdata = {
      plant_id : plant.plant_id,
      id: plant.geofence_id,
      name: plant.plant_name,
      ...plant,
    };

    let stageData = stages.length
      ? stages.map((item) => ({
          ...item,
          id: item.geofence_id,
          name: item.stage_name,
          plant_id:plant.plant_id,
          plant_name:plant.plant_name,
        }))
      : [];

    let destinationData = destinations.length
      ? destinations.map((item) => ({
          ...item,
          id: item.geofence_id,
          name: item.destination_name,
          plant_id:plant.plant_id,
          plant_name:plant.plant_name,
        }))
      : [];

    let zonesData = zones.length
      ? zones.map((item) => ({
          ...item,
          zone_id:item.id,
          id: item.geofence_id,
          name: item.zone_name,
          plant_id:plant.plant_id,
          plant_name:plant.plant_name,
        }))
      : [];

    let pZoneData = pzone.length
      ? pzone.map((item) => ({
          ...item,
          pZone_id:item.id,
          id: item.geofence_id,
          name: item.zone_name,
          plant_id:plant.plant_id,
          plant_name:plant.plant_name,
        }))
      : [];

    let hardwareData = hardware.length
      ? hardware.map((item) => ({
          ...item,
          hardware_id:item.ipa_hardware_id,
          id: item.geofence_id,
          name: item.hardware_name,
          plant_id:plant.plant_id,
          plant_name:plant.plant_name,
        }))
      : [];

    let mergedData = [
      { type: 'plant', data: [plantdata] },
      { type: 'stage', data: stageData },
      { type: 'destination', data: destinationData },
      { type: 'parking Zone', data: zonesData },
      { type: 'zone', data: pZoneData },
      { type: 'hardware', data: hardwareData }
    ];
    return mergedData;
  }

  deleteGeoFence(geoFence, type) {
    this.common.params = {
      title: 'Delete Geofence',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete this GeoFence ? ' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    let geofenceId=null;
    if (type == 'stage') {
      geofenceId = geoFence.stage_id;
    } else if (type == 'destination') {
      geofenceId = geoFence.destination_id;
    } else if (type == 'parking Zone') {
      geofenceId = geoFence.zone_id;
    }else if(type == 'zone'){
      geofenceId=geoFence.pZone_id;
    }else if(type == 'hardware'){
      geofenceId=geoFence.hardware_id;
    }

    let apiUrl = 'geofence/delete-geofence';
    let params = {
      id: geoFence.id,
      geofenceId:geofenceId,
      siteType:type,
    };
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.common.loading = true;
        this.api.post(apiUrl, params).subscribe(
          (res: any) => {
            if(res.status)this.alert.success(res.message);
            this.common.loading = false;
            this.modalAction = true;
            this.closeModal(true, 1);
          },
          (err) => {
            this.common.loading = false;
          }
        );
      }
    });
  }

  editGeoFence(geofence, type) {
    this.common.params.data = geofence;
    this.common.params.type = type;
    this.closeModal(true, 2,geofence.geofence_id);
  }

  closeModal(response = false, mode = 1,geofenceId=null) {
    this.activeModal.close({ data: response || this.modalAction, mode: mode,geofenceId});
  }
}

import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  ModalDismissReasons,
  NgbCalendar,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ActionFormComponent } from '../../../modals/action-form/action-form.component';
// import { NgxCaptureService } from 'ngx-capture';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ActionInfoModalComponent } from '../../../modals/action-info-modal/action-info-modal.component';
import { WebcamImage } from 'ngx-webcam';

@Component({
  selector: 'app-visitor-dashboard',
  templateUrl: './visitor-dashboard.component.html',
  styleUrls: ['./visitor-dashboard.component.scss'],
})
export class VisitorDashboardComponent implements OnInit {
  capturedImages: any = {};
  retakeImage: boolean = false;
  togglSec: boolean = false;

  activeForm: any = 0;
  closeResult = '';
  changeDiv: boolean = true;
  addVisitorActionsFields: any = [];
  stageIn: any[];
  stageOut: any[];
  selectedActionData: any = null;
  changeColor: any = [
    { id: 0, boolean: false },
    { id: 1, boolean: false },
    { id: 2, boolean: false },
    { id: 3, boolean: false },
    { id: 4, boolean: false },
    { id: 5, boolean: false },
  ];

  postActionData: any[];
  preActionData: any[];
  stageDetailId: any;
  index = 0;
  prePass: Boolean = false;
  allPass: Boolean = false;
  allAttend: Boolean = false;
  selectedRejectedNextStageId = null;
  selectedNextStageId = null;
  action_name = null;
  formValues: any = [
    {
      name: '',
      values: [],
      fieldType: '',
      database_field_name: '',
    },
  ];
  fileEvent: any = [];
  visitorDetails: any = [];z
  transactionId: any = null;
  txn_stageDetailId: any = null;
  action_data: any[];
  responses: any = [
    {
      name: '',
      value: [],
      action_id: null,
    },
  ];
  visitorCardDetails: any = [
    {
      name: '',
      mobileNO: '',
      meetWith: '',
      visitType: '',
      orderId: null,
    },
  ];
  showId: boolean = false;
  tempFieldName: any = null;
  visitorName: any = null;
  visitorMobileNo: any = null;
  visitorMeetingPurpose: any = null;
  visitorPhoto: any = null;
  approveBtnEnabled: boolean = true;
  rejectBtnEnabled: boolean = true;
  postActionsEnabled: boolean = true;
  checkPendingListener: any;
  totalVisitors: number = 0;
  activeVisitors: number = 0;
  waitingVisitors: number = 0;
  orderId: any = null;
  rejectedVisitors: number = 0;

  tableData: any = [];

  visitordata: any = [];

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 7, 'VISITOR LIST');
  model: NgbDateStruct;
  date: { year: number; month: number };
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  isLinear = false;
  meetWith: any = null;
  countVisitors: any = [{}];

  constructor(
    public table: TableService,
    private modalService: NgbModal,
    public common: CommonService,
    public userService: UserService,
    private calendar: NgbCalendar,
    public api: ApiService,
    private _formBuilder: FormBuilder,
    private alertService: AlertService,
    private breadcrumbService: BreadcrumbService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Visitors', url: null },
      { name: 'Visitor Dashboard', link: '/user/visitor-dashboard' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    // this.getUserData();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    clearInterval(this.checkPendingListener);
    this.dtTrigger.unsubscribe();
  }

  handleImage(webcamImage: WebcamImage, field: any, index: any) {
    this.capturedImages[field.database_field_name] = webcamImage;
    this.enterValues(webcamImage, field, index);
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getStageActions(content, item) {
    let param: any = {
      stageId: 83,
      actionStatus: 0,
      processId: 37,
    }; //First Stage According (TODO)

    if (item != null) {
      param.stageId = item.stageId;
      param.actionStatus = item.actionStatus;
      param.processId = item.processId;
      param.txnId = item.txnId;
    }

    this.common.loading = true;
    this.api.get('api/get-add-visitor-actions-and-fields', param).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (param.actionStatus == 1) {
          this.postActionData = res;
        } else if (param.actionStatus == -1) {
          this.prePass = true;
          //check pre Pass Here(Vinay)
          //prePass
          //done prePass
          this.preActionData = res;
          this.preActionData.forEach((e) => {
            if (e.response_status == 'PENDING') {
              this.prePass = false;
            }
          });
        } else {
          this.allAttend = true;
          this.allPass = true;

          this.addVisitorActionsFields = res;

          this.addVisitorActionsFields.forEach((e) => {
            if (e.response_status == 'PASS') {
            } else if (e.response_status == 'FAIL') {
              this.allPass = false;
            } else {
              this.allAttend = false;
              this.allPass = false;
            }
          });
          this.addJsonToFormValues(0);
        }
        // this.action_data = res;
        content != null ? this.addVisitorModel(content) : '';
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  //Approve Btn - allPass(true)
  //Reject Btn - allPass(true) || allAttend(true)
  //Current Action Enable - prePass(true)

  addVisitorModel(content: any) {
    console.log("Inside add vis model")
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.refreshVisitors();
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.refreshVisitors();
        }
      );
  }

  getUserPosition() {
    this.common.loading = true;
    let flag = false;
    this.api.get('dashboard/get-user-position').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.changeDiv = res[0].is_on_first_stage;
        this.refreshVisitors();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  getVisitorData(type): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = {
        type,
      };
      this.api
        .get('api/get-visitor-data', params, 'VMS')
        .subscribe((res: any) => {
          resolve(res);
          if (this.changeDiv) this.renderTable();
        });
    });
  }

  ngOnInit() {
    this.getUserPosition();
  }

  enterValues(event: any, field: any, i: any) {
    if (
      field.field_type != 'CHECKBOX' &&
      field.field_type != 'SELECTLISTMULTIPLE' &&
      field.field_type != 'FILE' &&
      field.field_type != 'SELECTLISTSINGLE'
    ) {
      if (field.database_field_name == 'v_name') {
        this.visitorName = event.target.value;
      }
      if (field.database_field_name == 'v_reason') {
        this.visitorMeetingPurpose = event.target.value;
      }
      if (field.database_field_name == 'v_mobile_no') {
        this.visitorMobileNo = event.target.value;
      }
      this.formValues[i].values = [];
      this.formValues[i].name = field.field_name;
      this.formValues[i].values.push(event.target.value);
      this.formValues[i].fieldType = field.field_type;
      this.formValues[i].database_field_name = field.database_field_name;
    }
    if (field.field_type == 'SELECTLISTSINGLE') {
      if (field.database_field_name == 'employee_list') {
        this.meetWith = event.name;
      }
      this.formValues[i].values = [];
      this.formValues[i].values.push(event[field.store_value]);
      this.formValues[i].name = field.field_name;
      this.formValues[i].fieldType = field.field_type;
      this.formValues[i].database_field_name = field.database_field_name;
    }
    if (field.field_type == 'CHECKBOX') {
      if (event.target.checked) {
        this.formValues[i].name = field.field_name;
        this.formValues[i].values.push(event.target.value);
        this.formValues[i].fieldType = field.field_type;
        this.formValues[i].database_field_name = field.database_field_name;
      } else {
        let index = this.formValues[i].values.indexOf(event.target.value);
        this.formValues[i].values.splice(index, 1);
      }
    }
    if (field.field_type == 'SELECTLISTMULTIPLE') {
      this.formValues[i].values = [];
      event.forEach((opt) => {
        this.formValues[i].values.push(opt[field.store_value]);
      });
      this.formValues[i].name = field.field_name;
      this.formValues[i].fieldType = field.field_type;
      this.formValues[i].values = [...new Set(this.formValues[i].values)];
      this.formValues[i].database_field_name = field.database_field_name;
    }

    if (field.field_type == 'FILE') {
      if (field.database_field_name == 'v_img_url') {
        this.visitorPhoto = event;
      }
      // let methodId = field.method_id;
      // this.onSelect(event, methodId, i);
      this.formValues[i].values = [];
      this.formValues[i].values.push(event);
      this.formValues[i].name = field.field_name;
      this.formValues[i].fieldType = field.field_type;
      this.formValues[i].database_field_name = field.database_field_name;
    }
  }

  insertLabel(field: any, index: any) {
    if (this.formValues[index].values.length == 0) {
      this.formValues[index].name = field.field_name;
      this.formValues[index].values.push(field.options[0].name);
      this.formValues[index].fieldType = field.field_type;
      this.formValues[index].database_field_name = field.database_field_name;
    }
  }

  // storeFieldName() {
  //   let tempField = null;
  //   this.visitorDetails.forEach((e) => {
  //     e.fields.forEach((el) => {
  //       if (el.database_field_name == 'v_name') {
  //         tempField = el.name;
  //         e.responses.forEach((res) => {
  //           if (res.name == tempField) {
  //             this.visitorName = res.value[0];
  //           }
  //         });
  //       }
  //       if (el.database_field_name == 'v_mobile_no') {
  //         tempField = el.name;
  //         e.responses.forEach((res) => {
  //           if (res.name == tempField) {
  //             this.visitorMobileNo = res.value[0];
  //           }
  //         });
  //       }
  //       if (el.database_field_name == 'v_reason') {
  //         tempField = el.name;
  //         e.responses.forEach((res) => {
  //           if (res.name == tempField) {
  //             this.visitorMeetingPurpose = res.value[0];
  //           }
  //         });
  //       }
  //       if (el.database_field_name == 'v_img_url') {
  //         tempField = el.name;
  //         e.responses.forEach((res) => {
  //           if (res.name == tempField) {
  //             this.visitorPhoto = res.value[0];
  //           }
  //         });
  //       }
  //     });
  //   });
  // }

  onSelect(e, methodId, i) {
    let frmData = new FormData();
    for (const file of e.target.files) {
      frmData.append('files', file);
      this.fileEvent.inProgress = true;
    }
    const headers = new HttpHeaders();
    this.api
      .post('functional/upload-file?methodId=' + 17, frmData)
      .subscribe((res: any) => {
        switch (res.type) {
          case HttpEventType.UploadProgress:
            this.fileEvent.progress = Math.round(
              (res.loaded * 100) / res.total
            );
            break;
          case HttpEventType.Response:
            return res;
        }
        res.forEach((e) => {
          this.formValues[i].values.push(e.fileUrl);
        });
      });
    catchError((error: HttpErrorResponse) => {
      this.fileEvent.inProgress = false;
      return `${this.fileEvent.data.name} upload failed.`;
    });
    // }
  }

  addJsonToFormValues(i) {
    this.formValues = [];
    if (this.addVisitorActionsFields[i].fields.length) {
      let size = this.addVisitorActionsFields[i].fields.length;
      for (let i = 0; i < size; i++) {
        this.formValues.push({
          name: '',
          values: [],
          fieldType: '',
          database_field_name: '',
        });
      }
    }
  }

  formVisitorDataJson(index: any, lastIndex: any) {
    let filled = true;
    let res = true;
    this.formValues.forEach((e) => {
      if (e.name == '') {
        filled = false;
      }
    });
    if (this.responses.length == 0) {
      res = false;
    } else {
      this.responses.forEach((e) => {
        if (
          e.name == '' ||
          (e.name != '' &&
            this.addVisitorActionsFields[index].action_id != e.action_id)
        ) {
          res = false;
        }
      });
    }
    if (filled == true || res == true) {
      if (lastIndex == false) {
        this.showContent(index + 1);
      }
      let action_obj = {
        action_id: null,
        method_id: null,
        fields: [],
        responses: [],
      };

      let present = false;
      //Update response in visitorDetails
      this.visitorDetails.forEach((visit) => {
        if (visit.action_id == this.addVisitorActionsFields[index].action_id) {
          present = true;
          this.formValues.forEach((e) => {
            if (e.name != '') {
              visit.responses.forEach((res) => {
                if (res.name == e.name) {
                  res.value = [];
                  e.values.forEach((value) => {
                    res.value.push(value);
                  });
                }
              });
            }
          });
        }
      });
      // Add response to visitorDetails
      if (present == false) {
        action_obj.action_id = this.addVisitorActionsFields[index].action_id;
        action_obj.method_id =
          this.addVisitorActionsFields[index].action_method_id;
        this.formValues.forEach((e) => {
          if (e.name != '') {
            let field_obj = {
              name: '',
              database_field_name: '',
            };
            field_obj.name = e.name;
            field_obj.database_field_name = e.database_field_name;
            action_obj.fields.push(field_obj);
            let res_obj = {
              name: '',
              value: [],
            };
            (res_obj.name = e.name),
              e.values.forEach((value) => {
                res_obj.value.push(value);
              });
            action_obj.responses.push(res_obj);
          }
        });
        this.visitorDetails.push(action_obj);
        if (lastIndex == false) {
          this.storeVisitorDetails(this.index, true, true);
          this.addJsonToFormValues(index + 1);
        }
      }
    } else {
      this.activeForm = this.activeForm - 1;
      this.index = index;
      this.alertService.error(
        this.addVisitorActionsFields[index].action_name + ' is missing...'
      );
    }
  }

  showContent(i: any) {
    let present = false;

    //update data of current action in responses
    this.visitorDetails.forEach((e) => {
      if (e.action_id == this.addVisitorActionsFields[i].action_id) {
        this.responses.forEach((rs) => {
          if (rs.action_id == e.action_id) {
            present = true;
            e.responses.forEach((res) => {
              (rs.name = res.name), (rs.value = []);
              res.value.forEach((e) => {
                rs.value.push(e);
              });
              rs.action_id = e.action_id;
            });
          }
        });
        // add data of current action in responses
        if (present == false) {
          this.responses = [];
          e.responses.forEach((res) => {
            let res_obj = {
              name: '',
              value: [],
              action_id: null,
            };
            (res_obj.name = res.name),
              res.value.forEach((e) => {
                res_obj.value.push(e);
              });

            res_obj.action_id = e.action_id;
            this.responses.push(res_obj);
          });
        }
      }
    });
  }

  storeVisitorDetails(index: any, type: any, firstStage: any) {
    if (index == 1 || type == false) {
      let lastActionId = null;
      if (type == false) {
        let i = this.addVisitorActionsFields.length - 1;
        lastActionId = this.addVisitorActionsFields[i].action_id;
      } else {
        this.transactionId = null;
        this.txn_stageDetailId = null;
      }
      let param = {
        transaction: type,
        visitorDetails: this.visitorDetails,
        processId: 37,
        stageId: 83,
        transactionId: this.transactionId,
        txn_stageDetailId: this.txn_stageDetailId,
        order_id: this.orderId,
        firstStage: firstStage,
        lastActionId: lastActionId,
      };
      this.api.post('api/store-visitor-details', param, '', 'VMS').subscribe(
        (res: any) => {
          this.common.loading = false;
          this.transactionId = res.transactionId;
          this.orderId = res.order_id;
          const _self = this;

          //Send Visitor Card
          setTimeout(() => {
            _self.sendVisitorCard(res.transactionId);
          }, 500);
          this.txn_stageDetailId = res.txn_stageDetailId;
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
      if (type == false) {
        this.transactionId = null;
        this.txn_stageDetailId = null;
        this.index = 0;
        this.activeForm = 0;
      }
    }
  }

  openActionForm(action: any) {
    let item = {
      processId: action.process_id,
      stageId: this.selectedActionData.stage_id,
      actionId: action.action_id,
      transactionDetailId: null,
      transactionStageDetailId: this.selectedActionData.txnStageId,
      actionName: action.action_name,
      transactionId: this.selectedActionData.txnId,
      actionType: action.action_type,
      transaction_type: 'visit',
      showVisitorCard: false,
    };
    this.common.params = {
      rowDetail: item,
    };
    const activeModal = this.modalService.open(ActionFormComponent, {
      size: 'lg',
    });
    activeModal.result.then((res) => {
      this.getActions(this.selectedActionData);
    });
  }

  getActions(vdata: any) {
    const status = [-1, 0, 1];
    this.selectedActionData = vdata;
    status.forEach((s) => {
      let item = {
        processId: vdata.process_id,
        stageId: vdata.stage_id,
        actionStatus: s,
        txnId: vdata.txnId,
      };
      this.getStageActions(null, item);
    });
    this.getVisitorCardDetails(vdata.txnId);
  }

  getVisitorCardDetails(txnId: any) {
    let param = {
      txnId: txnId,
    };
    this.common.loading = true;
    this.api.get('api/get-visitor-card-details', param, 'VMS').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.length != 0) {
          this.visitorCardDetails[0].name = res[0].name;
          this.visitorCardDetails[0].mobileNO = res[0].contact_no;
          this.visitorCardDetails[0].meetWith = res[0].user_name;
          this.visitorCardDetails[0].visitType = res[0].visit_type;
          this.visitorCardDetails[0].orderId = res[0].order_id;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  storeBooleanActionResult(curr: any, res: any) {
    let params = {
      stageActionId: curr.action_id,
      // tagStageWorkflowDetailId: null,
      stageDetailId: this.selectedActionData.txnStageId,
      status: res,
      transactionId: this.selectedActionData.txnId,
      actionType: curr.action_type,
      transactionType: 'visit',
      transactionDetailId: null,
    };
    this.api.post('transaction/save-transaction-detail', params).subscribe(
      (res: any) => {
        this.getActions(this.selectedActionData);
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  private captureVisitingCard(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      // this.captureService
      //   .getImage(document.getElementById('visitorcard'), true)
      //   .toPromise()
      //   .then((img: any) => {
      //     const splitDataURI = img.split(',');
      //     const byteString =
      //       splitDataURI[0].indexOf('base64') >= 0
      //         ? atob(splitDataURI[1])
      //         : decodeURI(splitDataURI[1]);
      //     const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

      //     const ia = new Uint8Array(byteString.length);
      //     for (let i = 0; i < byteString.length; i++)
      //       ia[i] = byteString.charCodeAt(i);
      //     resolve(
      //       new File([new Blob([ia], { type: mimeString })], 'Visitor.png')
      //     );
      //   });
    });
  }

  sendVisitorCard(txnId: any) {
    this.captureVisitingCard().then((res: Blob) => {
      let formData = new FormData();
      formData.append('txnId', txnId);
      formData.append('visitorCard', res);
      this.api
        .post('api/send-visitor-card', formData)
        .toPromise()
        .then((callRes: any) => {});
    });
  }

  stageApproved(content) {
    if (this.approveBtnEnabled == true) {
      if (
        this.selectedActionData.nextStages.length == 1 ||
        this.selectedActionData.nextStages.length == 0 ||
        this.selectedActionData.nextStages[0].stage_type_name == 'Visit Sites'
      ) {
        let postActions = [];
        this.postActionData.forEach((element) => {
          postActions.push(element.action_id);
        });
        let data = {
          postActionIds: postActions,
          txnStageId: this.selectedActionData.txnStageId,
          txnId: this.selectedActionData.txnId,
          currentStageId: this.selectedActionData.stage_id,
          permitVehicle: 1,
          isVisit: true,
        };
        this.api.post('transaction/assign-next-stage', data).subscribe(
          (res: any) => {
            this.common.loading = false;
            if (res[0].success == false) {
              this.alertService.error(res[0].status);
            } else {
              this.refreshVisitors();
              this.selectedActionData = null;
              this.changeColor.forEach((element) => {
                element.boolean = false;
              });
              this.preActionData = [];
              this.addVisitorActionsFields = [];
              this.postActionData = [];
              this.allPass = false;
              this.allAttend = false;
              this.alertService.success(res[0].status);
            }
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
          }
        );
      } else {
        this.modalService
          .open(content, { ariaLabelledBy: 'modal-basic-title' })
          .result.then(
            (result) => {
              this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
      }
    }
  }

  refreshVisitors() {
    if (this.changeDiv == false) {
      //On Second Stage
      this.getVisitorData(0).then((res: any) => {
        this.stageIn = res; //In Data
        this.getVisitorData(1).then((res: any) => {
          this.stageOut = res; //Out Data
        });
      });
    } else {
      //On First Stage
      this.getVisitorData(0).then((res: any) => {
        this.stageIn = res;
        this.totalVisitors = this.stageIn.length;
        this.rejectedVisitors = this.stageIn.filter(
          (e) => e.trip_Status == 'CANCELLED'
        ).length;
        this.waitingVisitors = this.stageIn.filter(
          (e) => e.trip_Status == 'INPROGRESS' && e.status == 'PENDING'
        ).length;
        this.activeVisitors = this.stageIn.filter(
          (e) => e.trip_Status == 'INPROGRESS' && e.status != 'PENDING'
        ).length;
        this.countVisitors = [
          {
            icon: 'fa fa-user',
            title: 'Total Visitors',
            count: this.totalVisitors,
          },
          {
            icon: 'fa fa-user',
            title: 'Current Active Visitors',
            count: this.activeVisitors,
          },
          {
            icon: 'fa fa-user',
            title: 'Waiting Visitors',
            count: this.waitingVisitors,
          },
          {
            icon: 'fa fa-user',
            title: 'Reject Visitors',
            count: this.rejectedVisitors,
          },
          {
            icon: 'fa fa-user',
            title: 'Completed Visits',
            count: this.stageIn.length ? this.stageIn[0].completed_visits : 0,
          },
        ];
      });
      this.checkPendingListener = setInterval(() => {
        this.getVisitorData(0);
      }, 10000);
    }
  }

  rejectStage(rejectContent: any) {
    if (this.allAttend == true) {
      if (this.selectedActionData.nextRejectStage.length <= 1) {
        let data = {
          postActionIds: [],
          txnStageId: this.selectedActionData.txnStageId,
          txnId: this.selectedActionData.txnId,
          currentStageId: this.selectedActionData.stage_id,
          permitVehicle: 0,
          isVisit: true,
        };
        this.api.post('transaction/assign-next-stage', data).subscribe(
          (res: any) => {
            this.common.loading = false;
            const alertMsg = res[0].status;
            if (res[0].success == false) {
              this.alertService.error(alertMsg);
            } else {
              this.refreshVisitors();
              this.selectedActionData = null;
              this.changeColor.forEach((element) => {
                element.boolean = false;
              });
              this.preActionData = [];
              this.addVisitorActionsFields = [];
              this.postActionData = [];
              this.allPass = false;
              this.allAttend = false;

              this.alertService.success(alertMsg);
            }
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
          }
        );
      } else {
        this.modalService
          .open(rejectContent, { ariaLabelledBy: 'modal-basic-title' })
          .result.then(
            (result) => {
              this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
      }
    }
  }

  nextRejectFlowStage(modal) {
    if (!this.selectedRejectedNextStageId)
      return this.alertService.error('Please select any Stage!!');
    modal.dismiss('Cross click');
    let data = {
      postActionIds: [],
      txnStageId: this.selectedActionData.txnStageId,
      txnId: this.selectedActionData.txnId,
      currentStageId: this.selectedActionData.stage_id,
      permitVehicle: 0,
      nextStageId: this.selectedRejectedNextStageId,
      isVisit: true,
    };
    this.common.loading = true;
    this.api.post('transaction/assign-next-stage', data).subscribe(
      (res: any) => {
        this.common.loading = false;
        let alertMsg = res[0].status;
        if (res[0].success == false) {
          this.alertService.error(alertMsg);
        } else {
          this.selectedActionData = null;
          this.preActionData = [];
          this.postActionData = [];
          this.addVisitorActionsFields = [];
          this.refreshVisitors();
          this.allPass = false;
          this.allAttend = false;
          this.alertService.success(alertMsg);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  async actionDetail(action) {
    let activeModal = this.modalService.open(ActionInfoModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });
    activeModal.componentInstance.refData = {
      actionName: action.action_name,
      actionType: 'curr',
      stageId: this.selectedActionData.stage_id,
      txnId: this.selectedActionData.txnId,
      actionId: action.action_id,
    };

    activeModal.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  resetStepper() {
    this.visitorDetails = [];
    this.responses = [];
    this.index = 0;
    this.activeForm = 0;
    this.showId = false;
    this.transactionId = null;
    this.txn_stageDetailId = null;
    this.meetWith = null;
    this.capturedImages = {};
  }
}

<div style="display: flex;flex-direction: column;align-items: center;" id="main-div">
  <video class="video" id="video" [height]="200" [width]="350" #video autoplay crossorigin="anonymous"
    style="margin-bottom: 10px;background-color: #484848;" [controls]="showControl" [hidden]="show || streamEnded"
    preload="auto" poster="../../../assets/images/loader-gif.webp">
  </video>
  <div style="width: 350px;height: 200px;background-color: #484848;display: flex;justify-content: center;align-items: center;margin-bottom: 10px;" [hidden]="!streamEnded">
    <span style="color: white;text-align: center;padding: 40px;font-size: 18px;">
      <button class="btn btn-info btn-lg" (click)="setupHLS()" *ngIf="streamEnded"> Restart Stream <i class="fa fa-repeat"></i></button>
    </span>
  </div>
  <img id="image" #image [height]="200" [width]="350" [hidden]="!show" style="margin-bottom: 10px;">

  <ng-container *ngIf="!streamEnded || (!isRecording && showControl) || (isCamera && show)">
    <div>
      <button class="btn btn-danger mr-2" type="button" disabled *ngIf="isRecording && !isCamera">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Recording... {{secondToShow}}
      </button>
      <button class="btn btn-success btn-lg" *ngIf="!isRecording && !isCamera && !showControl"
        (click)="startRecording()">Start Recording</button>
      <button class="btn btn-danger btn-lg" *ngIf="isRecording && !isCamera" (click)="stopRecording()">Stop</button>

      <button class="btn btn-primary btn-lg" *ngIf="(!isRecording && showControl)" (click)="setupHLS()">
        Record Again
      </button>
    </div>
    <button class="btn btn-primary btn-lg" (click)="captureImage()" *ngIf="isCamera && !show">capture Image</button>
    <button class="btn btn-primary btn-lg" (click)="setupHLS()" *ngIf="isCamera && show">Retake Image</button>
  </ng-container>
</div>

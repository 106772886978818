import { Directive, HostBinding, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[customDragScroll]',
})
export class CustomDragScrollDirective implements OnInit {
  @HostBinding('style.overflow-x') overflowX = 'scroll';
  @HostBinding('style.white-space') whiteSpace = 'nowrap';
  private isDragging = false;
  private startX: number;

  constructor() {}

  @HostListener('mousedown', ['$event'])
  onMousedown(event: any) {
    this.isDragging = true;
    this.startX = event.clientX - event.currentTarget.scrollLeft;
    event.preventDefault();
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event: any) {
    if (this.isDragging) {
      event.currentTarget.scrollLeft = event.clientX - this.startX;
      event.preventDefault();
    }
  }

  @HostListener('mouseup', ['$event'])
  onMouseup(event: MouseEvent) {
    this.isDragging = false;
  }

  @HostListener('mouseleave', ['$event'])
  onMouseleave(event: MouseEvent) {
    this.isDragging = false;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }
}

import { DetailModalComponent } from './detail-modal.component';
import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DetailService {
detaildata:any[] = [];
detailtitle:string = '';
modalsize:any[] = [];
className:any;

  constructor(public dialog: MatDialog) { }

 openDialog(data:any[],title:string,classval:any,size?:any) {
    this.modalsize = size;
    if(!size){
    this.modalsize = [1200,600];
    }

    if(!data.length){
      this.modalsize[1] = 200;
    }


    this.dialog.open(DetailModalComponent,{
    width: this.modalsize[0],
    height: this.modalsize[1],

    });
    this.detaildata = data;
    this.detailtitle = title;
    this.className = classval.classList[1];
    //console.log('this.className: ', this.className);

  }

}

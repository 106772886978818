import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import _ from 'lodash';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LocationService } from 'src/app/@core/services/location.service';
import { WebcamImage } from 'ngx-webcam';
import { Base64ToFileService } from 'src/app/@core/services/base64-to-file-service.service';
import { SignatureComponent } from 'src/app/components/signature/signature.component';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { throws } from 'assert';
import { N } from '@fullcalendar/core/internal-common';
import { ThemeService } from 'ng2-charts';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-action-form',
  templateUrl: './action-form.component.html',
  styleUrls: ['./action-form.component.scss']

})
export class ActionFormComponent implements OnInit {

  @ViewChild(SignatureComponent, { static: false })
  signature: SignatureComponent;
  formDetails: any;
  actionFormDetailList: any = [];

  formValues: any[] = [];
  actionName: string = '';
  retakeImage: boolean = false;

  formName: any = 'Form';
  txnId: any = null;
  fileURL: any = [];
  fileEvent: any = [];
  transactionType: any;
  visitorCardDetails: any = [
    {
      name: '',
      mobileNO: '',
      meetWith: '',
      visitType: '',
      meethWith: '',
    },
  ];
  showVisitorCard = false;
  visitorName: any = null;
  disabledFields: any = false;
  activeTab: any = null;
  showAssignDock: boolean = false;
  dockData: any = [];
  stage_destination_code: any = null;
  stage_destination_code_id: any = null;
  doc_stage_id: any = null;
  flowType: any = null;
  processId: any = null;
  longitude: number;
  latitude: number;
  image_name: any = '';
  image_url: any = '';
  image_ext: any = '';
  mobileFieldsCount: any = 0;
  showAssignParkingSlot: boolean = false;
  parkingSlotData: any[] = [];
  plantParkingType: string = null;
  parkingOn: string = 'stage';
  parking_stage_destination_code: any = null;
  parking_stage_destination_code_id: any = null;
  selectedParkingTypeId: number = null;
  selectedParkingTypeName: string = '';
  parkingType: any = 'Slot_Parking';
  parkingTypeList: any[] = [];
  availableParkings: any[] = [];
  selectedParking: number = null;
  currStageId: number = null;
  vehicleId: number = null;
  currentDockId: number = null;
  selectParkingName: any = '';
  selectDockName: any = '';
  dataUrl: string;
  isDockPriority: Boolean = false;
  selectedOption: string;
  nextStageId: number = null;
  stageType: string = null;
  currentStageDestination: number = null;
  remarkModal: boolean = false;
  commentMessage: string = '';
  stageName: string = '';
  stageId: number = null;


  //this for new dock assignment
   isEditTxn:boolean=false;
   isDelete:boolean=false;
   editCodeId:number=null;
   selectedDockId:number=null;
   assignedCount:number=null;
   unassignedCount:number=null;
   inStageDestinatonCodeId:number=null;
   extendOrderId:number=null;
   selectedProductId:number=null;
   selectedSlotQueueId:number=null;


  constructor(
    public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    private locationService: LocationService,
    private activeModal: NgbActiveModal,
    private base64ToFileService: Base64ToFileService,
    private imageCompress: NgxImageCompressService
  ) {
    if (this.common.params.rowDetail == 'Parking') {
      this.txnId = this.common.params.txnId;
      this.processId = this.common.params.processId;
      this.getParkingTypeOfPlant();
      this.formName = 'Assign Parking';
      this.showAssignParkingSlot = true;
      this.flowType = this.common.params.flowType;
      this.currStageId = this.common.params.stageId;
      this.vehicleId = this.common.params.vehicleId;
      this.nextStageId = this.common.params.nextStageId;
    } else if (this.common.params.rowDetail == 'Dock') {
      this.formName = 'Assign Dock';
      this.showAssignDock = true;
      this.dockData = this.common.params.dockData;
      this.txnId = this.common.params.txnId;
      this.flowType = this.common.params.flowType;
      this.processId = this.common.params.processId;
      this.stage_destination_code = this.common.params.dockName;
      this.stage_destination_code_id = this.common.params.dockId;
      this.currStageId = this.common.params.stageId;
      this.vehicleId = this.common.params.vehicleId;
      this.isDockPriority = this.common.params.isPriority;
      this.currentDockId = this.common.params.current_dock;
      this.nextStageId = this.common.params.nextStageId;
      this.stageType = this.common.params.stageType;
      this.currentStageDestination = this.common.params.stageDestinationCode;
      this.inStageDestinatonCodeId=this.common.params.inStageDestinationCodeId;
      this.extendOrderId=this.common.params.extendOrderId;
      this.assignedCount=this.dockData.length>0 ? this.dockData.filter((a)=>a.is_assigned).length : 0;
      console.log('this.assignedCount: ', this.assignedCount);
      this.unassignedCount=this.dockData.length-this.assignedCount;
      console.log('this.unassignedCount: ', this.unassignedCount);
      console.log('this.extendOrderId: ', this.extendOrderId);
      if (this.common.params.dockId != null) {
        let data = this.dockData.filter(
          (d) => d.stage_destination_code_id == this.common.params.dockId
        );
        if (data.length > 0) {
          this.doc_stage_id = data[0].stage_id;
        }
      }
    } else {
      this.formDetails = this.common.params.rowDetail;
      this.actionName = this.formDetails.actionName;
      this.vehicleId = this.formDetails.vehicleId;
      this.formName = this.formDetails.actionName;
      this.stageName = this.formDetails.stageName;
      this.stageId = this.formDetails.stageId;
      this.txnId = this.formDetails.transactionId;
      this.transactionType = this.formDetails.transaction_type;
      this.nextStageId = this.formDetails.nextStageId;
      if (this.common.params.hasOwnProperty('showVisitorCard')) {
        if (
          this.transactionType == 'visit' &&
          this.common.params.showVisitorCard == true
        ) {
          this.showVisitorCard = true;
          this.getVisitorCardDetails(this.txnId);
        }
      }
      if (this.common.params.hasOwnProperty('disabledField')) {
        this.disabledFields = this.common.params.disabledField;
      }
      if (this.common.params.hasOwnProperty('activeTab')) {
        this.activeTab = this.common.params.activeTab;
      }
      this.getActionFormDetails();
      this.showAssignDock = false;
    }
    console.log('dockData: ', this.dockData);
  }

  ngOnInit(): void { }


  selectEdit(codeId:number,slotQueue:number){

    if(this.selectedSlotQueueId==slotQueue){
      this.selectedSlotQueueId=null;
    }
    else this.selectedSlotQueueId=slotQueue;

    if(this.selectedDockId==codeId){
    console.log('this.selectedDockId: ', this.selectedDockId);
      this.selectedDockId=null;
    }
    else this.selectedDockId=codeId
    this.isEditTxn=!this.isEditTxn,
   console.log('this.isEditTxn: ', this.isEditTxn);
   this.editCodeId=codeId
   console.log('this.editCodeId: ', this.editCodeId);
  }
  selectDelete(isDelete:boolean,item:any){
  console.log('item: ', item);
    this.isDelete=isDelete;
    this.selectedDock(item);
    this.assignDock();
    this.isDelete=false;
  }


  changeParkingList(parkingOn: string) {
    if (parkingOn == 'stage' && this.parkingType == 'Slot_Parking') {
      this.getListOfParkingTypeSelected(parkingOn);
    } else if (parkingOn == 'zone' && this.parkingType == 'Slot_Parking') {
      this.getListOfParkingTypeSelected(parkingOn);
    }
  }

  getListOfParkingTypeSelected(parkingOn: string) {
    let apiUrl = '';
    if (parkingOn == null) {
      apiUrl =
        'parking/get_list_of_parking_type?processId=' +
        this.processId +
        '&type=' +
        this.parkingType;
    } else {
      apiUrl =
        'parking/get_list_of_parking_type?parkingOn=' +
        parkingOn +
        '&processId=' +
        this.processId +
        '&type=' +
        this.parkingType;
    }
    this.common.loading = true;
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.parkingTypeList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  draftDetails() {
    this.formValues = this.formValues.filter((item) => item.name !== '');
    this.formValues = this.formValues.map((obj) =>
      _.omit(obj, ['mandatory', 'preSelected'])
    );
    let params = {
      actionId: this.formDetails.actionId,
      stageId: this.formDetails.stageId,
      txnId: this.formDetails.transactionId,
      formData: JSON.stringify(this.formValues),
    }
    this.common.loading = true;
    this.api.post('transaction/draft-transaction-detail', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.data.responseMessage);
          this.closeModal('Action', 1, { status: 'PENDING' });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );

  }

  closeModal(response?: any, apiHit?: any, result?: any) {
    this.activeModal.close({
      response: response,
      apiHit: apiHit,
      result: result,
    });
  }

  selectedDock(event) {

    this.isDockPriority =event.is_priority,
    this.selectedProductId=event.product_id,
    console.log('this.selectedSlotQueueId: ', this.selectedSlotQueueId);
      // event.stage_destination_code_id == this.common.params.dockId
      //   ? this.common.params.isPriority
      //   : false;
    if (
      event.current_vehicle < event.vehicle_limit ||
      event.stage_destination_code_id == this.common.params.dockId || this.isDelete
    ) {
      this.stage_destination_code = event.stage_destination_code;
      this.stage_destination_code_id = event.stage_destination_code_id;
      this.doc_stage_id = event.stage_id;
    } else {
      this.alertService.warn('This Dock is Fully Occupied !!');
    }
  }

  selectedParkingType(event: any) {
    if (this.parkingOn == 'stage' || this.parkingType == 'Open_Parking') {
      this.selectedParkingTypeId = event.stage_id;
      this.selectedParkingTypeName = event.stage_name;
    } else {
      this.selectedParkingTypeId = event.id;
      this.selectedParkingTypeName = event.zone_name;
    }
    if (this.parkingType == 'Open_Parking') {
      this.getAvailableParkings(null);
    } else {
      this.getAvailableParkings(this.parkingOn);
    }
  }

  getAvailableParkings(parkingOn) {
    let apiUrl = '';
    if (parkingOn == null) {
      apiUrl =
        'parking/get_destinations_by_parking_type_id?parkingTypeId=' +
        this.selectedParkingTypeId +
        '&type=' +
        this.parkingType +
        '&processId=' +
        this.processId;
    } else {
      apiUrl =
        'parking/get_destinations_by_parking_type_id?parkingOn=' +
        parkingOn +
        '&parkingTypeId=' +
        this.selectedParkingTypeId +
        '&type=' +
        this.parkingType +
        '&processId=' +
        this.processId;
    }
    this.common.loading = true;
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.availableParkings = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getVisitorCardDetails(txnId: any) {
    let param = {
      txnId: txnId,
    };
    this.common.loading = true;
    this.api.get('api/get-visitor-card-details', param, 'VMS').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.visitorCardDetails[0].name = res.data[0].name;
          this.visitorCardDetails[0].mobileNO = res.data[0].contact_no;
          this.visitorCardDetails[0].meetWith = res.data[0].user_name;
          this.visitorCardDetails[0].visitType = res.data[0].visit_type;
          this.visitorCardDetails[0].meetWith = res.data[0].user_id;
          this.visitorName = res.data[0].user_name;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  async getActionFormDetails() {
    this.actionFormDetailList = [];
    this.common.loading = true;
    // let params = "&actionId=" + this.formDetails.actionId + "&tripId=" + this.txnId + "&actionType=" + this.formDetails.actionType + "&processId=" + this.formDetails.processId + "&stageId=" + this.formDetails.stageId;
    let params = {
      actionId: this.formDetails.actionId,
      tripId: this.txnId,
      actionType: this.formDetails.actionType,
      processId: this.formDetails.processId,
      stageId: this.formDetails.stageId,
      txnStageDetailId: this.formDetails.transactionStageDetailId,
      txnDetailId: this.formDetails.txnDetailId
    };
    console.log("params action",params)
    this.api
      .get('dashboard/get-dashboard-actions-form-fields', params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.actionFormDetailList = res.data;
            const conditionFieldTypes = [
              'RFIDCODE',
              'RFIDCODEWITHIMAGE',
              'BARCODEWITHIMAGE',
              'BARCODE',
            ];

            this.mobileFieldsCount = res.data.reduce((total, obj) => {
              let objCount = 0;
              if (conditionFieldTypes.includes(obj.field_type)) {
                objCount++;
              }
              if (obj.fields && Array.isArray(obj.fields)) {
                objCount += obj.fields.filter((field) =>
                  conditionFieldTypes.includes(field.field_type)
                ).length;
              }
              return total + objCount;
            }, 0);
          }
          //storing index for each field
          this.actionFormDetailList.forEach(async (field, index) => {
            const prevField = this.actionFormDetailList[index - 1]; //previous index field
            if (index != 0 && prevField.field_type == 'FIELDGROUP') {
              //if current field is not the first one and previous field type was FIELDGROUP
              field.index = prevField.index + prevField.fields.length; //current field index would be the addition of no. of fields in the previous field group to previous field index
            } else {
              //else if previous field was normal then
              field.index = index == 0 ? 0 : prevField.index + 1; //if current field is first then 0 else previous field index+1
            }
            if (field.field_type === 'FIELDGROUP') {
              field.group_fields =await this.modifyFieldGroupList(
                field,
                field.index
              );
            } else {
              await this.addJsonToFormValues(field,field.index);
            }
            if (field.draft_data != null) {
              field.draft_data = JSON.parse(field.draft_data);
            }
          });
          this.saveDataBaseLinked();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  async modifyFieldGroupList(field: any, index: number) {
    //modifying fields list in the form of list of lists where each sublist indicates a row of a table and no. of subllists indicates no. of columns
    const result: any[][] = [];
    const encounteredIndexes = new Set<number>();
    let currentIndex = index;
    let currentList: any[] = [];

    field.fields.forEach(async (obj) => {
      const subGroupIndex: number = obj.sub_group_index;

      if (encounteredIndexes.has(subGroupIndex)) {
        result.push(currentList);
        currentList = [];
        encounteredIndexes.clear();
      }
      obj.index = currentIndex;
      currentList.push(obj);
      encounteredIndexes.add(subGroupIndex);
      currentIndex++;
      if(obj.draft_data==null){
      this.formValues.push({
        name: '',
        values: [],
        fieldType: '',
        _dbField: '',
        mandatory: obj.is_mandatory,
        group_id: field.master_field_id,
        sub_group_index: obj.sub_group_index,
        sub_group_name: obj.sub_group_name,
        field_id:'',
      });
      }else{
        this.formValues.push({
          name:obj.field_name,
          values: JSON.parse(obj.draft_data),
          fieldType: obj.field_type,
          _dbField:obj.database_field_name,
          mandatory: obj.is_mandatory,
          group_id: field.master_field_id,
          sub_group_index: obj.sub_group_index,
          sub_group_name: obj.sub_group_name,
          field_id:obj.master_field_id
        });
        if(obj.field_type=='SELECTLISTMULTIPLE'){
          await this.getPreSelectedValueOfMultiSelect(this.formValues.length-1,JSON.parse(obj.draft_data));
        }
      }
      if (obj.draft_data != null) {
        obj.draft_data = JSON.parse(obj.draft_data);
      }
    });

    if (currentList.length > 0) {
      result.push(currentList);
    }
    return result;
  }
  async getPreSelectedValueOfMultiSelect( index: number, prev: any) {
    return new Promise((resolve, reject) => {
    let value = [];
      if (prev.length > 0) {
        prev.forEach((el, index) => {
          let obj = {
            id: index,
            name: el,
          };
          value.push(obj);
        });
      }
      resolve(this.formValues[index].preSelected = value);
    });
  }

  async addJsonToFormValues(field: any,fieldIndex:number) {
    if (field.draft_data == null) {
      this.formValues.push({
        name: '',
        values: [],
        fieldType: '',
        _dbField: '',
        mandatory: field.is_mandatory,
        group_id: null,
        sub_group_index: null,
        sub_group_name: null,
        field_id:null,
      });
    } else {
      this.formValues.push({
        name: field.field_name,
        values:JSON.parse(field.draft_data),
        fieldType:field.field_type,
        _dbField:field.database_field_name,
        mandatory: field.is_mandatory,
        group_id: null,
        sub_group_index: null,
        sub_group_name: field.sub_group_name,
        field_id:field.master_field_id
      });
      if(field.field_type=='SELECTLISTMULTIPLE'){
        await this.getPreSelectedValueOfMultiSelect(fieldIndex,JSON.parse(field.draft_data));
      }
    }
  }

  handleImage(webcamImage: WebcamImage, field: any, i: number) {
    this.formValues[i].values = [];
    this.formValues[i].name = field.field_name;
    this.formValues[i].field_id = field.master_field_id;
    this.onSelect(webcamImage, null, i, true, false, field.field_type);
    this.formValues[i].fieldType = field.field_type;
    this.formValues[i]._dbField = field.database_field_name;
  }

  handleStreaming(formData: any, field: any, i: number) {
    this.formValues[i].values = [];
    this.formValues[i].name = field.field_name;
    this.formValues[i].field_id = field.master_field_id;
    this.uploadFile(formData, field.field_type, i);
    this.formValues[i].fieldType = field.field_type;
    this.formValues[i]._dbField = field.database_field_name;
  }

  handleSingleSelectDBField(event: any, field: any, i: number) {
    this.formValues[i].values = [];
    this.formValues[i].values.push(event);
    this.formValues[i].name = field.field_name;
    this.formValues[i].field_id = field.master_field_id;
    this.formValues[i].fieldType = field.field_type;
    this.formValues[i]._dbField = field.database_field_name;
  }

  enterValues(
    event: any,
    field: any,
    i: any,
    parentField: any,
    mainIndex: number
  ) {
    if (this.checkValidation(event, field)) {
      if (
        parentField == null ||
        this.checkUnique(event, field, i, parentField)
      ) {
        if (
          field.field_type != 'CHECKBOX' &&
          field.field_type != 'SELECTLISTMULTIPLE' &&
          field.field_type != 'FILE' &&
          field.field_type != 'SELECTLISTSINGLE'
        ) {
          this.formValues[i].values = [];
          this.formValues[i].name = field.field_name;
          this.formValues[i].field_id = field.master_field_id;
          this.formValues[i].values.push(event.target.value);
          this.formValues[i].fieldType = field.field_type;
          this.formValues[i]._dbField = field.database_field_name;
        }
        if (field.field_type == 'SELECTLISTSINGLE') {
          this.formValues[i].values = [];
          this.formValues[i].values.push(event[field.store_value]);
          this.formValues[i].field_id = field.master_field_id;
          this.formValues[i].name = field.field_name;
          this.formValues[i].fieldType = field.field_type;
          this.formValues[i]._dbField = field.database_field_name;
        }

        if (field.field_type == 'CHECKBOX') {
          if (event.target.checked) {
            this.formValues[i].name = field.field_name;
            this.formValues[i].field_id = field.master_field_id;
            this.formValues[i].values.push(event.target.value);
            this.formValues[i].fieldType = field.field_type;
            this.formValues[i]._dbField = field.database_field_name;
          } else {
            let index = this.formValues[i].values.indexOf(event.target.value);
            this.formValues[i].values.splice(index, 1);
          }
        }
        if (field.field_type == 'SELECTLISTMULTIPLE') {
          this.formValues[i].values = [];
          event.forEach((opt) => {
            this.formValues[i].values.push(opt[field.store_value]);
          });
          this.formValues[i].name = field.field_name;
          this.formValues[i].field_id = field.master_field_id;
          this.formValues[i].fieldType = field.field_type;
          this.formValues[i].values = [...new Set(this.formValues[i].values)];
          this.formValues[i]._dbField = field.database_field_name;
        }

        if (field.field_type == 'FILE') {
          let methodId = field.method_id;
          this.onSelect(event, methodId, i, false, false, field.field_type);
          this.formValues[i].values = [];
          this.formValues[i].name = field.field_name;
          this.formValues[i].field_id = field.master_field_id;
          this.formValues[i].fieldType = field.field_type;
          this.formValues[i]._dbField = field.database_field_name;
        }
      } else {
        if (
          field.field_type != 'SELECTLISTSINGLE' &&
          field.field_type != 'SELECTLISTMULTIPLE'
        ) {
          this.alertService.error("Value isn't Unique !!");
          (document.getElementById('field' + i + mainIndex) as any).value =
            this.formValues[i].name;
        } else {
          let prev = [];
          if (
            field.field_type == 'SELECTLISTSINGLE' ||
            event.length >= this.formValues[i].values.length
          ) {
            this.alertService.error("Value isn't Unique !!");
            prev = this.formValues[i].values.slice();
            field.field_type == 'SELECTLISTSINGLE'
              ? this.formValues[i].values.push(event.name)
              : this.formValues[i].values.push(event[event.length - 1].name);
          } else {
            event.forEach((element) => {
              prev.push(element.name);
            });
          }
          setTimeout(() => {
            this.formValues[i].values = prev;
            this.getPreSelectedValue(null, i, prev, field.field_type);
          }, 100);
        }
      }
    } else {
      this.alertService.error('InValid Value Entered !!');
    }
    console.log("this.formValues",this.formValues)
  }

  checkValidation(event: any, field: any) {
  console.log('event: any, field: any: ', event, field);
    // Array of field types that require validation
    const includedFields = [
      'TEXTFIELD',
      'TEXTAREA',
      'NUMBERFIELD',
      'DATEPICKER',
      'DATETIMEPICKER',
      'TIME',
    ];

    // Check if the field type requires validation
    if (includedFields.includes(field.field_type)) {
      if (['TEXTFIELD', 'TEXTAREA', 'NUMBERFIELD'].includes(field.field_type)) {
        // Check for prefix, postfix, and regex
        if (
          field.pattern_prefix !== null &&
          field.pattern_prefix !== '' &&
          !event.target.value.startsWith(field.pattern_prefix.trim())
        )
          return false;
        if (
          field.pattern_postfix !== null &&
          field.pattern_postfix !== '' &&
          !event.target.value.endsWith(field.pattern_postfix.trim())
        )
          return false;
        let temp = event.target.value.trim();
        if (field.pattern_prefix !== null && field.pattern_prefix !== '') {
          temp = temp.replace(field.pattern_prefix, '');
        }
        if (field.pattern_postfix !== null && field.pattern_postfix !== '') {
          temp = temp.replace(field.pattern_postfix, '');
        }
        let result = temp.split(field.regex)[0];
        if (
          field.regex !== null &&
          !new RegExp(field.regex).test(result.trim())
        )
          return false;
      } else {
        // Check for regex only for other field types
        if (
          field.regex !== null &&
          !new RegExp(field.regex).test(event.target.value.trim())
        )
          return false;
      }
    } else {
      // If field type doesn't require validation, return true
      return true;
    }

    // If all conditions pass, return true
    return true;
  }

   getPreSelectedValue(label, index, prev, fieldType: string) {
    if (fieldType == 'SELECTLISTSINGLE') {
      return {
        [label]: this.formValues[index]?.values[0],
      };
    } else {
      let value = [];
      if (prev.length > 0) {
        prev.forEach((el, index) => {
          let obj = {
            id: index,
            name: el,
          };
          value.push(obj);
        });
        // return value;
      }
      return this.formValues[index].preSelected = value;
    }
  }

  checkUnique(event: any, field: any, i: any, parentField: any): boolean {
    let exculdedFieldTypes = [
      'GEOCODEDIMAGE',
      'FILE',
      'SIGNATURE',
      'RADIOBUTTON',
      'CHECKBOX',
    ]; //don't check unique for these field types
    if (parentField.field_type != 'FIELDGROUP') {
      return true;
    } else if (
      parentField.unique_subgroup_index.length == 0 ||
      !parentField.unique_subgroup_index.includes(field.sub_group_index) ||
      exculdedFieldTypes.indexOf(field.field_type) != -1
    ) {
      return true;
    } else {
      const isValueUnique = (
        formValues: any[],
        value: any,
        groupId: number,
        subGroupIndex: number
      ): boolean => {
        // Filter the formValues based on groupId and subGroupIndex
        const filteredValues = formValues.filter(
          (item) =>
            item.group_id === groupId && item.sub_group_index === subGroupIndex
        );

        // If no matching group_id and sub_group_index found, return true
        if (filteredValues.length === 0) {
          return true;
        }
        // Check if any of the filtered items have values[0] matching the given value
        if (field.field_type == 'SELECTLISTMULTIPLE') {
          let flag = true;
          filteredValues.some((item) => {
            if (item.values.length > 0 && item.values.includes(value)) {
              flag = false;
            }
          });
          return flag;
        } else {
          return !filteredValues.some(
            (item) =>
              item.values.length > 0 &&
              item.values[0].toLowerCase() === value.toLowerCase()
          );
        }
      };

      let value =
        field.field_type == 'SELECTLISTSINGLE'
          ? event.name
          : field.field_type == 'SELECTLISTMULTIPLE'
            ? event[event.length - 1].name
            : event.target.value;
      const isUnique = isValueUnique(
        this.formValues,
        value,
        parentField.master_field_id,
        field.sub_group_index
      );
      return isUnique;
    }
  }

  onSelect(
    e,
    methodId,
    i,
    webcamImageType: boolean,
    signature: boolean,
    fieldType: string
  ) {
    let frmData = new FormData();
    let imgResultAfterCompression: DataUrl;
    if (webcamImageType == false) {
      for (const file of e.target.files) {
        frmData.append('files', file);
        this.fileEvent.inProgress = true;
        this.uploadFile(frmData, fieldType, i);
      }
    } else {
      let file;
      if (signature == false) {
        this.imageCompress
          .compressFile(e._imageAsDataUrl, -2, 50, 50) // 50% ratio, 50% quality
          .then((compressedImage) => {
            imgResultAfterCompression = compressedImage;
            file = this.base64ToFileService.base64toFile(
              imgResultAfterCompression,
              'image' + i + '.jpg'
            );
            frmData.append('files', file);
            this.fileEvent.inProgress = true;
            this.uploadFile(frmData, fieldType, i);
          });
      } else {
        this.imageCompress
          .compressFile(e, -2, 50, 50) // 50% ratio, 50% quality
          .then((compressedImage) => {
            imgResultAfterCompression = compressedImage;
            file = this.base64ToFileService.base64toFile(
              imgResultAfterCompression,
              'signature.jpg'
            );
            frmData.append('files', file);
            this.fileEvent.inProgress = true;
            this.uploadFile(frmData, fieldType, i);

          })
          .catch((error) => {
            console.error("Error during image compression:", error);
          });
      }
    }
  }

  uploadFile(frmData: any, fieldType: string, i: number) {
    const headers = new HttpHeaders();
    // this.common.loading = true;
    this.api
      .post('functional/upload-file?methodId=' + 17, frmData)
      .subscribe((res: any) => {
        // this.common.loading = false;
        switch (res.data.type) {
          case HttpEventType.UploadProgress:
            this.fileEvent.progress = Math.round(
              (res.data.loaded * 100) / res.data.total
            );
            break;
          case HttpEventType.Response:
            return res.data;
        }
        res.data.forEach((e) => {
          if (fieldType != 'GEOCODEDIMAGE') {
            this.formValues[i].values.push(e.fileUrl);
          } else {
            const position = this.locationService._position;
            let obj = {
              lat: position.latitude,
              lng: position.longitude,
              time: new Date(),
              fileUrl: e.fileUrl,
            };
            this.formValues[i].values.push(obj);
          }
        });
      });
    catchError((error: HttpErrorResponse) => {
      this.fileEvent.inProgress = false;
      // this.common.loading = false;
      return `${this.fileEvent.data.name} upload failed.`;
    });
  }

  saveDataBaseLinked() {
    console.log('this.actionFormDetailList: ', this.actionFormDetailList);
    this.actionFormDetailList.forEach((element) => {
      if (element.sel_value != null) {
        let i = this.actionFormDetailList.indexOf(element);
        this.formValues[i].name = element.field_name;
        this.formValues[i].field_id = element.master_field_id;
        this.formValues[i].values = [element.sel_value];
        this.formValues[i].fieldType = element.field_type;
        this.formValues[i]._dbField = element.database_field_name;
      }
      element['multiValueOptions'] = [];
      if (element.field_type == 'SELECTLISTMULTIPLE') {
        element.options.forEach((ele) => {
          let obj = {
            option: ele.master_field_value,
          };
          element.multiValueOptions.push(obj);
        });
      }
    });
  }

  saveSignature(index: number, field: any) {
    if (this.signature) {
      this.signature.captureSignature();
      const signatureImage = this.signature.signatureImage;
      this.formValues[index].values = [];
      if(signatureImage)this.onSelect(signatureImage, null, index, true, true, 'SIGNATURE');
      this.formValues[index].name = field.field_name;
      this.formValues[index].field_id = field.master_field_id;
      this.formValues[index].fieldType = field.field_type;
      this.formValues[index]._dbField = field.database_field_name;
    }
  }

  saveDetails(status: string) {
    this.latitude = this.locationService._position.latitude;
    this.longitude = this.locationService._position.longitude;

    this.locationService.position.subscribe((position: any) => {
      this.latitude = position.latitude;
      this.longitude = position.longitude;
    });
    if (this.activeTab == null || this.activeTab == 'pending') {
      let i = this.formValues.findIndex(
        (e) => e.name == '' && e.field_type != 'LABEL' && e.mandatory == 1
      );
      if (i != -1 && status != 'FAIL') {
        this.alertService.error('Mandatory fields need to be filled...');
      } else {
        this.formValues = this.formValues.filter((item) => item.name !== '');
        this.formValues = this.formValues.map((obj) =>
          _.omit(obj, ['mandatory', 'preSelected'])
        );
        let params = {
          formData: JSON.stringify(this.formValues),
          status: status,
          transactionDetailsId: this.formDetails.txnDetailId,
          stageDetailId: this.formDetails.transactionStageDetailId,
          stageActionId: this.formDetails.actionId,
          transactionId: this.formDetails.transactionId,
          actionType: this.formDetails.actionType,
          transactionType: this.transactionType,
          meetWith: this.visitorCardDetails[0].meetWith,
          stageId: this.formDetails.stageId,
          flowType: this.formDetails.flowType || this.formDetails.flow_type,
          lat: this.latitude,
          lng: this.longitude,
          vehicleId: this.vehicleId,
          nextStageId: this.nextStageId
        };
        this.common.loading = true;
        this.api.post('transaction/save-transaction-detail', params).subscribe(
          (res: any) => {
            this.common.loading = false;
            if (res.status) {
              this.alertService.success(res.data.responseMessage);
              console.log("closeModal")
              this.closeModal('Action', 1, { status: status });


              if (res.message == 'NOT INSIDE GEOFENCE AREA') {
                this.alertService.error(res.message);
              }
            }
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
          }
        );
      }
    }
  }

  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  insertLabel(field: any, index: any) {
    if (this.formValues[index].values.length == 0) {
      this.formValues[index].name = field.field_name;
      this.formValues[index].field_id = field.master_field_id;
      this.formValues[index].values.push(field.options[0].name);
      this.formValues[index].fieldType = field.field_type;
    }
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }

  assignDock() {
    if (
      this.stage_destination_code_id != null ||
      this.selectedParking != null
    ) {
      let param;
      if (this.showAssignDock == true) {
        param = {
          txnId: this.txnId,
          docStageId: this.doc_stage_id,
          codeId: this.stage_destination_code_id,
          flowType: this.flowType,
          processId: this.processId,
          type: 'Dock',
          vehicleId: this.vehicleId,
          currStageId: this.currStageId,
          isPriority: this.isDockPriority,
          dockStatus:
            this.stageType == 'Dock Stage' &&
              this.currentStageDestination == this.stage_destination_code_id
              ? 'Loaded'
              : 'Inprogress',
          isEditTxn:this.isEditTxn,
          isDelete:this.isDelete,
          editCodeId:this.editCodeId,
          extendOrderId:this.extendOrderId,
          productId:this.selectedProductId,
          slotQueueId:this.selectedSlotQueueId
        };
      } else {
        param = {
          txnId: this.txnId,
          docStageId: this.selectedParkingTypeId,
          codeId: this.selectedParking,
          flowType: this.flowType,
          processId: this.processId,
          type: this.parkingType,
          parkingOn: this.parkingOn,
          vehicleId: this.vehicleId,
          currStageId: this.currStageId,
        };
      }
      this.common.loading = true;
      this.api.post('dashboard/assign-destination', param).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.data[0].Success == 'False') {
            this.alertService.error(res.data[0].message);
          } else {
            this.alertService.success('Assigned Successfully !!');
            this.showAssignDock == true
              ? this.closeModal('dockAssigned', 1, {
                dock_name: this.stage_destination_code,
                ispriority: this.isDockPriority,
                dock_id: this.stage_destination_code_id,
              })
              : this.closeModal('parkingSlotAssigned', -1, {
                parking_slot_id: this.selectedParking,
                parking_slot_name: this.selectParkingName,
              });
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    }
  }

  selectParking(item: any) {
    this.selectedParking = item.stage_destination_code_id;
    this.selectParkingName = item.stage_destination_code;
  }

  selectDock(item: any) {
    this.selectedDock = item.stage_destination_code_id;
  }

  changeParkingType() {
    this.availableParkings = [];
    if (this.parkingType == 'Open_Parking') {
      this.getListOfParkingTypeSelected(null);
      this.parkingOn = null;
    } else {
      this.getListOfParkingTypeSelected('stage');
    }
  }

  changeDockPriority(event: any) {
    this.isDockPriority = event.target.checked;
  }

  addCommentForTrip() {
    let params = {
      entityId: this.txnId,
      entityType: 'TRIP',
      commentType: 'ACTION FAIL',
      comment: this.commentMessage == '' ? 'N/A' : this.commentMessage,
      refData: JSON.stringify({
        title: '"'+this.formName + '" Failed !!',
        data: this.formValues,
        stageName: this.stageName,
        stageId: this.stageId,
        actionId : this.common.params.rowDetail.actionId,
        actionName : this.common.params.rowDetail.actionName
      }),
    };
    this.api.post('comment/add-comment', params).subscribe(
      (res: any) => {
        this.saveDetails('FAIL');
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getParkingTypeOfPlant() {
    this.api
      .get('dashboard/get_assigned_dock?txnId=' + this.txnId + '&parkingType=1')
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.parkingType = res.data[0].parkingType;
          }
          this.plantParkingType = res.data[0].parkingType;
          if (this.parkingType == 'Open_Parking') {
            this.getListOfParkingTypeSelected(null);
            this.parkingOn = null;
          } else {
            this.getListOfParkingTypeSelected('stage');
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }
  // getActionData() {
  //   this.common.loading = true;
  //   this.api
  //     .get(
  //       'dashboard/get-action-details?txnId=' +
  //         this.txnId +
  //         '&actionId=' +
  //         this.actionId +
  //         '&stageId=' +
  //         this.stageId +
  //         '&actionType=' +
  //         this.refData.action_type +
  //         '&txnStageDetailId=' +
  //         this.txnStageDetailId
  //     )
  //     .subscribe(
  //       (res: any) => {
  //         this.common.loading = false;
  //         this.response = res.data;
  //         this.preActionsData = res.data[0].preActionsData;
  //         this.currentActionData = res.data[0].currentActionData;
  //         this.hideShow1 = [];
  //         this.preActionsData.forEach((el) => {
  //           let obj = {
  //             id: this.hideShow1.length,
  //             boolean: false,
  //           };
  //           this.hideShow1.push(obj);
  //         });
  //       },
  //       (err: any) => {
  //         this.common.loading = false;
  //       }
  //     );
  // }
}

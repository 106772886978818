<div class="card">
  <div class="card-body">
    <div class="card-header">
      <div class="header-block" style="display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;">
        <h3 class="card-title">
          {{"Trip Tag Log"}}
        </h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <section class="timeline_area section_padding_130">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12"> -->
          <div class="main-timeline-area">
            <div class="single-timeline-area" *ngFor="let data of viewLogData;let i=index;let last = last"
              [style]="'--border-color: #8CC1FF;--color: #8CC1FF85'">
              <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s"
                style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                <button class="button-45" role="button">{{data.addtime}}</button>
              </div>
              <div class="row" style="margin-left:20px">
                <div class="col-12">
                  <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s"
                    style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                    <div class="timeline-text" style="display: flex;flex-direction: column;">
                      <span style="font-size: 12px;margin-bottom: 4px;width:fit-content"><span
                          style="font-size: 12px;margin-right:3px;font-weight: 600;">Destination :</span> {{data.destination}}</span>
                      <div style="display:flex">
                        <span
                          style="font-size: 12px;margin-right:3px;font-weight: 600;">Stages :</span><ng-container *ngFor="let stageData of data.stage_data"><span class="info-jellybean" style="font-size:10px;margin: 1px;">{{stageData.stage_name}}</span></ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">Add Dock Appointment</h5>
</div>

<div class="modal-body">
  <!-- Header Info -->
  <div class="header-info">
    <div class="info-item">
      <label>Booking Date</label>
      <div>
        <i class="fa fa-calendar" aria-hidden="true"></i> {{refData.activeDate | date:'dd-MM-yyyy'}}
      </div>
      <!-- <span>{{refData.activeDate}}</span> -->
    </div>

    <div class="info-item">
      <label>Dock In Time</label>
      <div>
        <i class="fa fa-clock-o" aria-hidden="true"></i> {{loaderInTime}}
      </div>
    </div>

    <div class="info-item">
      <label>Dock Out Time</label>
      <div>
        <i class="fa fa-clock-o" aria-hidden="true"></i> {{loaderOutTime}}
      </div>
    </div>
  </div>

  <div class="input-box">
    <mat-horizontal-stepper [selectedIndex]="currentInd" style="overflow: visible !important;" labelPosition="bottom" linear>
      <mat-step label="Select Vehicle"
                    [completed]="currentState">
    <div class="vehicle-input">
      <ps-auto-suggestion (onSelected)="onSelectVehicle($event,'select')" (onChange)="onSelectVehicle($event,'input')" [data]="vehicleList" display="vehicle_rsn" placeholder="Select Vehicle"
       name="selectedVehicleName"   [preSelected]="{vehicle_rsn:selectedVehicleName}">
      </ps-auto-suggestion>
      <div class="error" *ngIf="vehicleValidator && vehicleValidator.error">
        <span>{{vehicleValidator.msg}} </span>
      </div>
    </div>
    <div *ngIf="bookingFlag==2" class="transaction">
      <div class="top-content">
      <span class="message">Transaction already exists for the vehicle</span>
        <img src="assets/images/trip-tracking(3).png" matTooltip="Trip Tracking" matTooltipPosition="below" alt="" (click)="tripTrackingModal()" class="trip-icon">
        </div>
        <div class="buttons">
          <button type="button" [disabled]="!showButton" [title]="!showButton?'Booking for the same product present.':''" class="btn btn-primary" style="background-color: #418eec"  (click)="linktoExisting()">Link</button>
          <button type="button" class="btn btn-primary" style="background-color: #396e9f" matStepperNext (click)="onCreateNew()">Create New</button>
<!--
          <button class="btn btn-primary" (click)="linktoExisting()">Link</button>
          <button class="btn btn-secondary" mat-button matStepperNext>Create New</button> -->
        </div>
    </div>
    <div *ngIf="bookingFlag==1" class="transaction">
      <div class="top-content">
      <span class="message">Booking already exists for the vehicle</span>
        <!-- <img src="assets/images/trip-tracking(3).png" matTooltip="Trip Tracking" matTooltipPosition="below" alt="" (click)="tripTrackingModal()" class="trip-icon"> -->
        <i class="fa fa-info-circle compositeP">
          <ul class="children" style="color: white;">
            Products
            <li  *ngFor="let j of productList let ind=index">{{ind+1}}. {{j.product_name}}</li>
          </ul>
        </i>
        </div>

        <!-- mat-raised-button
        matTooltip="You cannot click this button"
        [matTooltipDisabled]="!isButtonDisabled"
        [disabled]="isButtonDisabled" -->
        <div class="buttons">
          <!-- <button type="button" *ngIf="showButton" class="btn btn-primary" style="background-color: #418eec"  (click)="linktoExisting()">Link</button> -->
          <!-- <div
          matTooltip="Info about why the button is disabled."
          [matTooltipDisabled]="showButton"> -->
            <button type="button" [disabled]="!showButton" [title]="!showButton?'Booking for the same product present.':''" class="btn btn-primary" style="background-color: #418eec;pointer-events: all" (click)="linktoExisting()">Link</button>
          <!-- </div> -->
          <button type="button" class="btn btn-primary" style="background-color: #396e9f" matStepperNext (click)="onCreateNew()">Create New</button>
<!--
          <button class="btn btn-primary" (click)="linktoExisting()">Link</button>
          <button class="btn btn-secondary" mat-button matStepperNext>Create New</button> -->
          </div>
        </div>
        <!-- <div class="bottom-button" *ngIf="showAddDriver==true">
    <button class="btn btn-secondary" matStepperNext>
      Next
</button>
</div> -->
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
  <button *ngIf="showAddDriver==true" class="btn btn-primary" matStepperNext (click)="onNext()">Next</button>
</div>
  </mat-step>
  <mat-step label="Select Driver" [completed]="false" >
    <div class="driver-input">
    <ps-auto-suggestion (onSelected)="onSelectDriver($event,'select')" (onChange)="onSelectDriver($event,'input')" [data]="driverList" display="driverName" placeholder="Select Driver"
     name="selectedDriverName" [preSelected]="{driverName:selectedDriverName}">
    </ps-auto-suggestion>
    <div class="error" *ngIf="driverValidator && driverValidator.error">
      <span>{{driverValidator.msg}} </span>
    </div>
    <div class="add_new_box">
      <div>
        <span class="driver_not_found">{{'Driver'|translate}}{{"not_found"|translate}}</span>
        <span class="add_new_driver" (click)="openAssignDriver(detail2,'Driver')">{{'add_new'|translate}} {{'Driver'|translate}}</span>
      </div>
    <!-- <button class="btn btn-secondary" style="padding: 1.5px 4px !important;" matStepperPrevious>
      Back
  </button> -->
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" matStepperPrevious>Back</button>
          <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
          <button type="button" class="btn btn-success" (click)="formValidator()">Book Appointment</button>

        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>


  <!-- <div class="flow-diagram">
    <div class="flow-box" *ngFor="let item of refData.history">
      <div class="box-content">
        <p>Assigned To: {{ item.assignedTo }}</p>
        <p>Assigned By: {{ item.assignedBy }}</p>
        <p>Assigned At: {{ item.addTime }}</p>
      </div>
    </div>

    <div class="arrow"></div>

  </div> -->
</div>
<!-- <div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
  {{currentInd}}
  <button *ngIf="currentInd==1" type="button" class="btn btn-primary" (click)="formValidator()">Book Appointment</button>
  <button *ngIf="showAddDriver==true && currentInd==0" (click)="currentInd=1" class="btn btn-secondary" matStepperNext>Next</button>
  <button *ngIf="currentInd==1" class="btn btn-secondary" matStepperPrevious>Back</button>
</div> -->
<ng-template #detail2 id="detail2" let-modal2>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{"Add"|translate}} {{assign|translate}} {{'Details'|translate}}</h6>
    <div aria-label="Close" (click)="modal2.dismiss('Cross click')"><i class="fa fa-times"></i></div>
  </div>
  <div class="modal-body" id="boxes">
    <app-add-driver-transporter (saveChange)="savingChanges($event);modal2.dismiss('Cross click')" [closeFlag]="true"
      [assign]="assign" [id]="null" [editable]="true"></app-add-driver-transporter>
  </div>
</ng-template>

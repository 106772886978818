<div class="admindashboard">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"general information" | customTranslate}}
            </h3>

          </div>
        </div>
        <div class="card-body main-info-card">
          <div class="common-info" [class]="item.class" *ngFor="let item of allCompany">
            <div class="left-block">
              <p class="info"> {{item.count}} </p>
              <p class="title"> {{item.name | customTranslate}} </p>
            </div>
            <div class="right-block">
              <i [ngClass]="item.icon"> </i>
            </div>
          </div>
          <app-data-not-found *ngIf="allCompany.length < 1"></app-data-not-found>

        </div>
      </div>

    </div>

    <div class="col-md-12 mt-4">

      <div class="card" *ngIf="userService.permissionMap.get('sector_wise_company_access_access')==true">

        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"SectorWise Companies" | customTranslate}}
            </h3>
          </div>
        </div>

        <div class="card-body">
          <table class="custom-table" datatable [dtOptions]="companyOptions" [dtTrigger]="CompanyTrigger">
            <thead>
              <tr>
                <th>{{ ("S.No." | lowercase)| customTranslate}}</th>
                <th>{{"Sector" | customTranslate}}</th>
                <th>{{"Total Company" | customTranslate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sector of allcompanySectors;index as i">
                <td> {{i+1}} </td>
                <td>{{sector.sectorName}}</td>
                <td>{{sector.value}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>

  </div>
</div>

import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { CommunicationService } from '../communication/communication.service';
import { UserActionServiceService } from './user-action-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ProcessService } from '../process/process.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { StageActionService } from '../stage-action/stage-action.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';

interface UpdateUserActionMapping {
  id: number;
  userId: number;
  actionId: number;
  orderId: number;
  isActive: string;
}

@Component({
  selector: 'app-user-action-mapping',
  templateUrl: './user-action-mapping.component.html',
  styleUrls: ['./user-action-mapping.component.scss'],
})
export class UserActionMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Stage Destination Code List');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    public userService: UserService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    private processService: ProcessService,
    private stageService: StageActionService,
    private communicationService: CommunicationService,
    private userActionService: UserActionServiceService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public alert : AlertService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Actions', url: null },
      { name: 'User Action Mapping', link: '/user/action-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.getAllProcess();
    this.getAllActions();
    this.getUser();
    this.getData();
  }

  ngOnInit() {
    this.status = 'true';
  }

  updateUserActionMapping: UpdateUserActionMapping = {
    id: 0,
    userId: 0,
    actionId: 0,
    orderId: 0,
    isActive: 'true',
  };

  title = 'Add User Action Mapping';
  submitted = false;
  process: [];
  responseData: any[] = [];
  selecteduser: any[] = [];
  isMultiSelect = true;
  preSelectedUser: any = [];
  action: any[] = [];
  filtered_action: any[] = [];
  user: any[] = [];
  status: any = 'true';
  processName: '';
  actionName: '';
  userName: '';
  orderId: 0;
  actionId: 0;
  buttonName = 'save';

  getAllProcess() {
    this.common.loading = true;
    this.processService.getAllProcessStage().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.process = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  getAllActions() {
    this.common.loading = true;
    this.stageService.getAllStageAction().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.action = res.data;
          this.filtered_action = this.action;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  getUser() {
    this.common.loading = true;
    this.communicationService.getUser().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.user = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedProcess(event: any) {
    if (event.process_id != null) {
      let Id = event.process_id;
      let actiondata = this.action.filter((person) => person.processId === Id);
      this.actionName = '';
      // this.filtered_action = actiondata;
      this.orderId = event.order_id;
      this.processName = event.order_name;
      this.updateUserActionMapping.orderId = event.order_id;
    } else {
      this.filtered_action = null;
      this.filtered_action = this.action;
      this.orderId = null;
    }
  }

  emptyprocess(event: any) {
    if (event == '') {
      this.processName = '';
      this.orderId = 0;
    }
    if (event.trim() == '') {
      this.filtered_action = this.action;
    }
  }

  selectedAction(event: any) {
    this.actionId = event.actionId;
    this.updateUserActionMapping.actionId = event.actionId;
  }
  selectedUser(event: any) {
    if (this.isMultiSelect == true) {
      let selectedUser: any = [];
      event.forEach((element) => {
        selectedUser.push(element.userId);
      });
      this.selecteduser = selectedUser;
    } else {
      this.updateUserActionMapping.userId = event.userId;
    }
  }

  viewUserActionMapping(row?: any) {
    this.common.params = { details: [row], title: 'View Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  editUserActionMapping(row?: any) {
    this.common.gotoTop();
    this.userValidator = null;
    this.actionValidator = null;
    this.statusValidator = null;
    if (row.processId != null) {
      let Id = row.processId;
      let actiondata = this.action.filter((person) => person.processId === Id);
      this.filtered_action = actiondata;
    } else {
      this.filtered_action = null;
      this.filtered_action = this.action;
    }
    this.updateUserActionMapping.id = row.id;
    this.processName = row.processOrderName;
    this.actionName = row.actionName;
    this.actionId = row.actionId;
    this.selecteduser = [row.userId];
    if (row.isActive == true) {
      this.status = 'true';
      this.updateUserActionMapping.isActive = 'true';
    }
    if (row.isActive == false) {
      this.status = 'false';
      this.updateUserActionMapping.isActive = 'false';
    }
    this.isMultiSelect = false;
    this.updateUserActionMapping.actionId = row.actionId;
    this.updateUserActionMapping.orderId = row.processStageOrderId;
    this.orderId = row.processStageOrderId;
    this.updateUserActionMapping.userId = row.userId;
    this.preSelectedUser = { name: row.userName };
    this.buttonName = 'update';
    this.title = 'Edit User Action Mapping';
  }

  saveUserAction(): void {
    let data = {
      Id: this.updateUserActionMapping.id,
      user: this.selecteduser,
      actionId: this.actionId,
      orderId: this.orderId,
      isActive: this.status,
    };
    this.userActionService.saveUserAction(data).subscribe(
      (res: any) => {
        if (res.status) {
          this.submitted = true;
          console.log('res.message: ', res.message);
          this.alert.success(res.message);
          this.reset();
        }
      },
      (error: any) => {
      }
    );
  }

  // updateUserAction(): void {
  //   let data = {
  //     Id: this.updateUserActionMapping.id,
  //     user: [this.updateUserActionMapping.userId],
  //     actionId: this.updateUserActionMapping.actionId,
  //     orderId: this.orderId,
  //     isActive: this.status,
  //   };
  //   this.userActionService.saveUserAction(data).subscribe(
  //     (res: any) => {
  //       if (res.status) {
  //         this.submitted = true;
  //         this.reset();
  //       }
  //     },
  //     (error: any) => {
  //     }
  //   );
  // }

  getData() {
    this.userActionService.getUserAction().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.responseData = res.data;
          this.renderTable();
        }
      },
      (err) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    );
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Action ',
      description:
        `<b>&nbsp;` + 'Are you sure, Do you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteAction(row);
      }
    });
  }

  deleteAction(row) {
    this.api
      .post('actions/delete-user-action-mapping?mappingId=' + row.id, null)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.getData();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  reset(callActionAPI: any = true) {
    this.status = 'true';
    this.isMultiSelect = true;
    this.actionId = null;
    this.actionName = '';
    this.actionValidator = null;
    this.userName = '';
    this.orderId = 0;
    this.selecteduser = [];
    this.processName = '';
    this.userValidator = null;
    this.statusValidator = null;
    this.actionValidator = null;
    this.updateUserActionMapping.actionId = 0;
    this.updateUserActionMapping.orderId = 0;
    this.updateUserActionMapping.userId = 0;
    this.updateUserActionMapping.id = 0;
    this.updateUserActionMapping.isActive = 'True';
    this.buttonName = 'save';
    this.title = 'Add User Action Mapping';
    this.preSelectedUser = [];
    this.getAllProcess();
    if (callActionAPI) this.getAllActions();
    this.getUser();
    this.getData();
    this.preSelectedUser = [];
  }

  processValidator: any;
  statusValidator: any;
  actionValidator: any;
  userValidator: any;
  formValidator() {
    this.actionValidator = this.common.nullValidator(
      this.actionId,
      'Action Mandatory'
    );
    this.userValidator = this.common.nullValidator(
      this.selecteduser,
      'User Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.status,
      'Status Mandatory'
    );
    if (
      !this.actionValidator.error &&
      !this.statusValidator.error &&
      !this.userValidator.error) {
      this.saveUserAction();
    }
    // if (
    //   !this.actionValidator.error &&
    //   !this.statusValidator.error &&
    //   !this.userValidator.error &&
    //   this.buttonName == 'update'
    // ) {
    //   this.updateUserAction();
    // }
  }
}

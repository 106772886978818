<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{'next_stage'|translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="list-group">
    <div class="list-group-item" *ngFor="let value of refData.next_stages">
      <input type="radio" [ngClass]="refData.active_next_stage_id == value.next_stage_id? 'active':'inactive'"
        [value]="value.next_stage_id" name="stage" [(ngModel)]="nextStageId">
      <span>{{value.stage_name}}</span>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-success" type="button" outline (click)="this.closeModal(true,1,nextStageId);"
    style="cursor: pointer;">{{'save'|translate}}</button>
</div>

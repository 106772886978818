<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-2">
              <div class="header-block">
                <h3 class="card-title">
                  {{'employee_report'|customTranslate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="tab_section">
                <div class="tab" *ngFor="let tabitem of tabData" [class.active]="activeSection == tabitem.value"
                  (click)="activeSection = tabitem.value">
                  <p>{{tabitem.tabname|customTranslate}}</p>
                </div>
              </div>
              <section class="open_sec" [hidden]="activeSection != 'attendance'">
                <div class="page_design">
                  <div class="row tab_sec">
                    <div class="col-md-12 date_div">
                      <div class="row">
                        <div class="col-md-4 date">
                          <label>{{'start_date:'|customTranslate}}</label>
                          <input class="form-control" type="date" name="startDate" [(ngModel)]="fromDate">                        </div>
                        <div class="col-md-4 date">
                          <label>{{'end_date:'|customTranslate}}</label>
                          <input class="form-control" type="date" name="endDate" [(ngModel)]="toDate">                        </div>
                        <div class="col-md-1 btn-block text-right">
                          <button class="btn btn-primary" (click)="getAttendanceReport(0)">{{"search" | customTranslate}}</button>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col-md-6 buttons text-right">
                      <button type="button" [class.active]="tabvalue == 'table'" (click)="tabvalue = 'table'"><i
                          class="fa fa-table"></i> &nbsp;{{'table_view'|customTranslate}}</button>
                    </div> -->
                  </div>

                  <div class="view_table" *ngIf="tabvalue == 'table'">
                    <div class="filter_sec">
                      <div class="row">
                        <div class="col-md-3 coll" *ngIf="role.user_role=='COMPANYADMIN'">
                          <label>{{'plant:'|customTranslate}}</label>
                          <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData"
                            display="plantName" placeholder="Choose Plant">
                          </ps-auto-suggestion>
                        </div>
                        <div class="col-md-3 col1">
                          <label>{{'select_username:'|customTranslate}}</label>
                          <ps-auto-suggestion [data]="userList" display="name" seperator="-"
                            placeholder="Select User" (onSelected)="selectedUser($event)" inputId="stageName"
                            name="stageName" isMultiSelect="true"></ps-auto-suggestion>
                        </div>
                      </div>
                    </div>

                    <div class="table_sec">
                      <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                          <tr>
                            <th>{{'serial_no'|customTranslate}}</th>
                            <th>{{'date'|customTranslate}}</th>
                            <th>{{'user_name'|customTranslate}}</th>
                            <th>{{'start_time'|customTranslate}}</th>
                            <th>{{'end_time'|customTranslate}}</th>
                            <th>{{'stage_workflow_status'|customTranslate}}</th>
                            <th>{{'punch_type'|customTranslate}}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let row of responseData;  let i = index">
                            <td>{{ i+1 }}</td>
                            <td>{{row.d | date:'dd-MM-yyyy'}}</td>
                            <td>{{row.user_name}}</td>
                            <td>{{row.start_time=='-'?row.start_time:row.start_time|date:'short'}}</td>
                            <td>{{row.end_time=='-'?row.end_time:row.end_time|date:'short'}}</td>
                            <td><span class="badge status-badge"
                                [ngClass]="{'badge-success':row.stage_workflow_status == 'COMPLETED','badge-warning':row.stage_workflow_status == 'INPROGRESS'}">{{row.stage_workflow_status!='-'
                                ? (row.stage_workflow_status|customTranslate) : "-"}}</span></td>
                            <td>
                              <ng-container *ngIf="row.punch_type==null">{{'-'}}</ng-container>
                              <img src="assets/images/face.png" alt="" height="30px"
                                width="30px"  *ngIf="row.punch_type==10">
                                <img src="assets/images/thumb.png" alt="" height="30px"
                                width="30px" *ngIf="row.punch_type==2">
                                <img src="assets/images/card.png" alt="" height="30px"
                                width="30px" *ngIf="row.punch_type==4">
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="view_graph" *ngIf="tabvalue == 'graph'">
                    <p class="text-center">Graph will be shown here.</p>
                  </div>
                </div>
              </section>
              <section class="open_sec" [hidden]="activeSection != 'shift'">
                <app-shift-report></app-shift-report>
              </section>
              <!-- <section class="open_sec" [hidden]="activeSection != 'checklist'">
                <app-checklist-history [report]="true"></app-checklist-history>
              </section> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

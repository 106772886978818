import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { ProcessService } from '../process/process.service';

@Component({
  selector: 'app-hardware-vehicle-log',
  templateUrl: './hardware-vehicle-log.component.html',
  styleUrls: ['./hardware-vehicle-log.component.scss','../../modals/trip-comment-trail/trip-comments/trip-comments.component.scss']
})
export class HardwareVehicleLogComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(20, 8, 'Hardware Vehicle List');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    public api: ApiService,
    public common: CommonService,
    public table: TableService,
    public alertService: AlertService,
    public modal: NgbModal,
    public processService: ProcessService,
    public breadcrumbService: BreadcrumbService,
    public role: RoleService,
    private userService: UserService,
    private plantService: PlantService,
    public modalService: NgbModal
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Hardware', url: null },
      { name: 'Hardware Health', link: '/user/hardware-health' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  companyId: number = null;
  plantId: number = null;
  companyName: null;
  plantName: null;
  companyData: any = [];
  plantData: any = [];
  logStartDate:string=null;
  logEndDate:string=null;
  hardwareData:any=[];
  viewLogData:any=[];
  activeModal:any;
  activeTime= new Date(new Date().getTime() - 15 * 60 * 1000);


  companyValidator: any = null;
  plantValidator: any = null;

  ngOnInit() {
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else if (this.role.user_role == 'COMPANYADMIN') {
      this.companyId = this.userService._loggedInUser.companyId;
      this.getPlantById();
    } else {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
    }
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = null;
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
  }

  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }


  getHardwareData() {
    let params = {
      plantId:this.plantId,
      logStartDate:this.logStartDate,
      logEndDate:this.logEndDate
    }
    this.api.get('hardware/get-vehicle-log-by-plant',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.hardwareData = res.data.map(ele => {
            ele.data = JSON.parse(ele.data);
            let first = ele.data[0];
            let last = ele.data[ele.data.length-1];
            ele.firstLog = first.addtime;
            ele.lastLog = last.addtime;
            ele.firstTrackedDestination = first.destination;
            ele.lastTrackedDestination = last.destination;
            return ele;
          });
          this.renderTable();
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        id: id,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.plantData = res.data;
            resolve(this.plantData);
          }
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  reset() {
    this.plantId = null;
    this.plantName = null;
    this.companyId = null;
    this.companyName = null;
    this.companyValidator = null;
    this.plantValidator = null;
  }

  validators(){
    if(this.logStartDate == null) return this.alertService.warn("Please select Start Date !!");
    if(this.logEndDate == null) return this.alertService.warn("Please select End Date !!");
    if(this.companyId == null) return this.alertService.warn("Please select Company !!");
    if(this.plantId == null) return this.alertService.warn("Please select Plant !!");
    this.getHardwareData();
  }

  closeModal(){
    this.modalService.dismissAll();
  }

  openLogData(content,row) {
    this.viewLogData = row.data;
      this.activeModal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' ,size:'xl'});
  }

  dateValidator(){
    if(this.logStartDate > this.logEndDate){
      this.alertService.warn("End Date should be greater than Start Date !!")
      this.logEndDate = null;
    }
  }
}

import { Component, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { StageService } from '../stage/stage.service';
import { VisitDateWiseReportModalComponent } from '../visit-date-wise-report-modal/visit-date-wise-report-modal.component';
import { DateWisVisitorReportData, ProcessList } from 'src/app/dataType/visitorInterface';
import { COMMON_ACTION_BUTTON, TABLE_TYPE, TableColumns,TableActions } from 'src/app/dataType/customTableInterface';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-visit-date-wise-component',
  templateUrl: './visit-date-wise-component.component.html',
  styleUrls: ['./visit-date-wise-component.component.scss']
})
export class VisitDateWiseComponentComponent implements OnInit {
  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  loading: boolean = false;
  tableColumns: TableColumns[] = [];
  startDate: Date = new Date(new Date().setDate(new Date().getDate() - 7));
  endDate: Date = new Date(new Date().setDate(new Date().getDate()));
  listOfProcess: ProcessList[]
  processName: string = null;
  plantId: number = null;
  dateWisVisitorReportData:DateWisVisitorReportData[] = [];
  tripStatus: string;
  processFilterList:number[]=[];
  processFilterName: string[]=[];
  reportDisplayList: DateWisVisitorReportData[]=[];

  constructor(
    public table: TableService,
    public common: CommonService,
    public api: ApiService,
    public alert: AlertService,
    public stageService: StageService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public modalService: NgbModal,
    public role: RoleService,
    public plantService: PlantService,
    private translateService: TranslateService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Visit Date Wise Report', link: '/user/visit-date-wise-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }



  ngOnInit() {
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.getAllProcess();
    this.getDateWiseVisitorReport();

  }

  selectedProcess(event: any) {


        this.processFilterList=event.map(e=>e.processId)
        this.getDateWiseVisitorReport()
  }

  filterDataByCondition(condition: string){

    if(condition=='ALERT')

      {this.reportDisplayList=this.dateWisVisitorReportData.filter(data=>data.alert_count>0)
      }

      if(condition=='RESTRICTED')

      this.reportDisplayList=this.dateWisVisitorReportData.filter(data=>data.restricted_count>0)

      if(condition=='ALL')
      this.reportDisplayList= this.dateWisVisitorReportData

  }



  reset(){


    this.startDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.endDate = new Date();
    this.tripStatus='ALL'
    this.filterDataByCondition(this.tripStatus)
    this.processFilterList=[]
    this.processFilterName=[]



    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);

    this.getDateWiseVisitorReport()
  }

  getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.plantId,
      processType:'Visit'
    };
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.listOfProcess = res.data;
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  getDateWiseVisitorReport() {

    this.loading= true
    if (this.startDate > this.endDate) {
      this.alert.warn('Start Date Should be Smaller than End Date!!');
      return;
    }
    this.common.loading = true;
    let params = {
      fromDate: this.startDate,
      toDate: this.endDate,
      processFilterList: this.processFilterList?.length==0?[-1]: this.processFilterList
    };

    this.api.get('visitors/get-visit-date-wise-report', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.common.loading = false;
          this.dateWisVisitorReportData = res.data.reduce((acc, item) => acc.concat(item.raw), []);
          this.dateWisVisitorReportData= this.dateWisVisitorReportData
          this.reportDisplayList= this.dateWisVisitorReportData
          this.tableColumns = [
            {
              columnLabel: "Visit Date",
              columnName: "date",
            },
            {
              columnLabel: "Alert Count",
              columnName: "alert_count",
            },
            {
              columnLabel: "Restricted Count",
              columnName: "restricted_count",
            },
             {
              columnLabel: "Over TAT",
              columnName: "",
              dataFormatter: (row: any, column: TableColumns, index: number) => {
                return (`<span class="${row.actual_plant_tat > row.expected_plant_tat ? 'badge over' : 'badge'}">${row.actual_plant_tat}</span> /
                          <span class="badge info">${row.expected_plant_tat}</span>
                        `)
              }

            },
            {
              columnLabel: this.translateService.instant("Action"),
              columnName: null,
              dataActions:[
                {
                  actionName: COMMON_ACTION_BUTTON.VIEW,
                  handleClick: (column: TableColumns, row: any, index: number) => {
                    this.viewVisitDetails(row);
                  },
                  actionFormatter: (column: TableColumns, row: any, index: number) => {
                    return `<div class="btn-block">
                      <button class="btn btn-info" style=" position: relative; color: #fff; border: none; font-size: 12px;">

                    ${this.translateService.instant("view")}</button>
                      </div>`
                  }
                }
              ]

            }
          ]

          this.loading = false;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  viewVisitDetails(visit: any){

    const activeModal = this.modalService.open(VisitDateWiseReportModalComponent,{
      size:'xl'
    });
    activeModal.componentInstance.refData = {visit:visit,processFilterList:this.processFilterList}
    activeModal.result.then(async (res) => {

    });
  }

}

<ng-container *ngIf="isEdit;else addAppointment">
  <ng-template [ngTemplateOutlet]="staticForm"></ng-template>
</ng-container>

<ng-template #addAppointment>
  <div [ngClass]="toggle ? 'col-md-12' :'col-md-12'" style="padding:0px">
    <div class="form_sec ">
      <div class="appointment_form">
        <app-form [formType]='"Visitor Form"' [toggle]="toggle" (saveTrigger)="handleSaveTrigger($event)"></app-form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #staticForm>

  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h5 class="modal-title">{{title | customTranslate}}</h5>
  </div>
              <div class="card-body" style="overflow: auto;">
                <div class="row">
                  <div class="col-md-12" *ngIf="isVisitor && refData == null">

                      <label class="required">{{"name"| customTranslate}}</label>
                      <input type="text" [(ngModel)]="newAppointment.name" class="form-control">
                      <div class="error" *ngIf="nameValidator && nameValidator.error">
                        <span>{{nameValidator.msg}} </span>
                      </div>
                    </div>

                  <div class="col-md-12 form-group">
                      <label class="required">{{"start_time"| customTranslate}}</label>
                      <input type="datetime-local" [value]="newAppointment.startTime"
                        [(ngModel)]="newAppointment.startTime" [min]="minStartDate" class="form-control"
                        (change)="validateStartTime()">
                      <div class="error" *ngIf="startTimeValidator && startTimeValidator.error">
                        <span>{{startTimeValidator.msg}} </span>
                      </div>
                    </div>
                  <div class="col-md-12 form-group">
                      <label class="required">{{"end_time"| customTranslate}}</label>
                      <input type="datetime-local" [(ngModel)]="newAppointment.endTime" [min]="newAppointment.startTime"
                        class="form-control"
                        (change)="validateDateTimeRange(newAppointment.startTime,newAppointment.endTime)">
                      <div class="error" *ngIf="endTimeValidator && endTimeValidator.error">
                        <span>{{endTimeValidator.msg}} </span>
                      </div>
                  </div>
                  <div class="col-md-12 form-group">
                      <label class="required">{{"purpose"| customTranslate}}</label>
                      <input type="text" [(ngModel)]="newAppointment.purpose" class="form-control">
                      <div class="error" *ngIf="purposeValidator && purposeValidator.error">
                        <span>{{purposeValidator.msg}} </span>
                      </div>
                    </div>
                  <div class="col-md-12 form-group">
                      <label class="required">{{"from"| customTranslate}}</label>
                      <input type="text" [(ngModel)]="newAppointment.from" class="form-control">
                      <div class="error" *ngIf="fromValidator && fromValidator.error">
                        <span>{{fromValidator.msg}} </span>
                      </div>
                  </div>
                  <div class="col-md-12 form-group">
                      <label class="required">{{"meet_with"| customTranslate}}</label>
                      <ps-auto-suggestion [data]="allUsers" (onSelected)="selectedUser($event)" display="name"
                        [preSelected]="{name:newAppointment.userName}"
                        [readonly]="true">
                      </ps-auto-suggestion>
                    </div>
                  <div class="col-md-12">
                      <label>{{"visitor_details"| customTranslate}}</label>
                      <div class="row mb-2" *ngFor="let visitor of newAppointment.visitors; let i = index">
                        <div class="col-lg-5 form-group mb-0 p-2">
                          <input type="text" class="form-control" [placeholder]="'mobile_no.'|customTranslate" [(ngModel)]="visitor.mobile_number"
                            maxlength="10">
                            <div class="error" *ngIf="staffMobileValidator && staffMobileValidator.error && i == ind">
                              <span>{{staffMobileValidator.msg}} </span>
                            </div>
                        </div>
                        <div class="col-lg-7 p-2 d-flex justify-content-between gap-2 align-items-center">
                          <div class="form-group mb-0">
                            <input type="text" class="form-control" [placeholder]="'Name'|customTranslate" [(ngModel)]="visitor.staff_name"
                            [disabled]="visitor.isNameDisabled">
                            <div class="error" *ngIf="staffNameValidator && staffNameValidator.error && i == this.ind">
                              <span>{{staffNameValidator.msg}} </span>
                            </div>
                          </div>
                          <div class="d-flex align-items-start gap-2 justify-content-end">
                            <div class="plus_minus_icon">
                              <span
                                class="remove_class "
                                (click)="removeRow(i)"
                                *ngIf="newAppointment.visitors.length > 1">
                                <i class="fa fa-minus" (click)="removeRow(i)"></i>
                              </span>
                            </div>
                            <div class="plus_minus_icon" *ngIf="i==newAppointment.visitors.length-1">
                              <span
                                class="add_class"
                                  (click)="addValues()">
                                <i class="fa fa-plus"></i>
                              </span>
                            </div>
                          </div>
                        </div>


                      <!-- <div class="other_visitor">
                        <div class="loop_row" *ngFor="let visitor of newAppointment.visitors; let i = index">
                          <div class="number_field">
                            <input type="text" class="form-control" placeholder="Number" [(ngModel)]="visitor.mobile_number"
                              maxlength="10" (input)="handleMobileNumber(visitor,visitor.mobile_number)">
                          </div>
                          <div class="error" *ngIf="staffNameValidator && staffNameValidator.error && i == this.ind">
                            <span>{{staffNameValidator.msg}} </span>
                          </div>
                          <div class="name_field">
                            <input type="text" class="form-control" placeholder="Name" [(ngModel)]="visitor.staff_name"
                              [disabled]="visitor.isNameDisabled">
                          </div>
                          <div class="error" *ngIf="staffMobileValidator && staffMobileValidator.error && i == this.ind">
                            <span>{{staffMobileValidator.msg}} </span>
                          </div>
                          <div style="display: fle;  margin: 10px 0;">
                            <div class="plus_minus_icon">
                              <span
                                class="remove_class "
                                (click)="removeRow(i)"
                                *ngIf="newAppointment.visitors.length > 1">
                                <i class="fa fa-minus" (click)="removeRow(i)"></i>
                              </span>
                            </div>
                            <div class="plus_minus_icon" *ngIf="i==newAppointment.visitors.length-1">
                              <span
                                class="add_class"
                                  (click)="addValues()">
                                <i class="fa fa-plus"></i>
                              </span>
                            </div>
                          </div>

                      </div> -->
                    </div>

                </div>
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-success " (click)="onAppointmentUpdate()">{{'save'|customTranslate}}</button>
                <button type="button" class="btn btn-warning" (click)="reset()">{{'reset'|customTranslate}}</button>
              </div>
</ng-template>

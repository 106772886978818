import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthVisitorGuard implements CanActivate {

  constructor(private route: Router){
  }
  lastAllowedUrl:string;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(state.url.startsWith('/user')){
        return this.isUserLoggedin();
      } else if(this.isLoggedin() && localStorage.getItem('walkin_mode').toLowerCase() == 'true'){
        this.route.navigate(['/auth/visitor-login']); //redirect url path
        return false;
      }
      else if(this.isLoggedin()){
        return true;
      }
      else{
        this.route.navigate(['/auth/visitor-login'],{queryParams : {'re':state.url, 'authkey': route.queryParams.authkey || null}}); //redirect url path
        return false;
        }
    }
  isLoggedin() {
    return (
      localStorage.getItem('VISITOR_TOKEN') != 'null'
    );
  }

  isUserLoggedin() {
    return (
      localStorage.getItem('USER_TOKEN') != 'null'
    );
  }

}

<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{'select_range'|translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="field">
    <select [(ngModel)]="range" class="field_class">
      <option *ngFor="let item of rangeList" [value]="item">{{ item }}</option>
    </select>
  </div>
  <div class="button_logout">
    <button class="btn_cancel" type="button" outline (click)="closeModal(false,0)" id="cancel"
      style="cursor: pointer;">{{"Cancel"|translate}}</button>
    <button class="btn_confirm" type="button" outline (click)="closeModal(true,1)" id="submit"
      style="cursor: pointer;">{{"ok"|translate}}</button>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="tab_section">
        <ng-container *ngFor="let tabitem of tabData" >
          <div class="tab" *ngIf="tabitem.permission" [class.active]="staffType == tabitem.value"
              (click)="staffType = tabitem.value;onSelectEmployee()">
          <p>{{tabitem.tabname|customTranslate}}</p>
        </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-md-12">


          <ng-container *ngIf="staffType=='VISITOR'">

            <app-add-visitor></app-add-visitor>

          </ng-container>

          <ng-container *ngIf="staffType!='VISITOR'">


          <div class="card"
            *ngIf="userService.permissionMap.get('add_user_access')==true ||userService.permissionMap.get('edit_user_access')==true ">
            <div class="card-header mb-1">
              <div class="header-block mb-2">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="this.staffType=='USER'">
                  <label for="my-input">{{"user_type" | customTranslate}}</label>
                  <select name="user_type" id="#user_type" class="form-control" [(ngModel)]="user.userType">
                    <option value="" disabled selected>Choose User Type</option>
                    <option *ngFor="let type of userTypesList[role.user_role]" [value]="type"
                      placeholder="Choose User Type">{{type}}</option>
                  </select>

                  <div class="error" *ngIf="typeValidator && typeValidator.error">
                    <span>{{typeValidator.msg}} </span>
                  </div>

                </div>

                <div *ngIf="(role.user_role == 'AXESTRACK') && (user.userType=='COMPANYADMIN' || user.userType=='COMPANYUSER'
                || user.userType=='PLANTADMIN' || user.userType=='PLANTUSER'|| staffType=='STAFF'||staffType=='TRANSPORTER')"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"company" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyValidator && companyValidator.error">
                    <span>{{companyValidator.msg}} </span>
                  </div>
                </div>

                <div *ngIf="(role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN')
                && (user.userType=='PLANTADMIN' || user.userType=='PLANTUSER'|| staffType=='STAFF' || staffType=='TRANSPORTER')"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantData"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantValidator && plantValidator.error">
                    <span>{{plantValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="staffType == 'STAFF'">
                  <label for="my-input" class="required"> {{"Staff Type" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="onSelectStaffType($event)" [data]="staffTypeList"
                    display="type_name" placeholder="Choose Staff Type" [preSelected]="{type_name:staffTypeName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="typeValidator && typeValidator.error">
                    <span>{{typeValidator.msg}} </span>
                  </div>
                </div>

                <!-- <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" [class.required]="staffType !== 'USER'">{{"first_name" | customTranslate}}</label>
                  <input type="text" class="form-control" name="firstName" required placeholder="Enter First Name"
                  [(ngModel)]="user.firstName"   #firstName="ngModel" autocomplete="off" (change)="setUserName()">
                  <div class="error" *ngIf="staffFirstNameValidator && staffFirstNameValidator.error">
                    <span>{{staffFirstNameValidator.msg}} </span>
                  </div>
                </div> -->

                <!-- <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" [class.required]="staffType !== 'USER'">{{"last_name" | customTranslate}}</label>
                  <input type="text" class="form-control" name="lastName" placeholder="Enter Last Name"
                  [(ngModel)]="user.lastName" #name="ngModel" autocomplete="off" (change)="setUserName()">
                  <div class="error" *ngIf="staffLastNameValidator && staffLastNameValidator.error">
                    <span>{{staffLastNameValidator.msg}} </span>
                  </div>
                </div> -->


                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" [class.required]="staffType !== 'USER'">{{"first_name" | customTranslate}}</label>
                  <ng-container *ngIf="staffType === 'USER' || staffType==='TRANSPORTER'; else elseBlockForFirstName">
                    <input type="text" class="form-control" name="firstName" required placeholder="Enter First Name"
                      [(ngModel)]="user.firstName" autocomplete="off" (change)="setUserName()">
                  </ng-container>
                  <ng-template #elseBlockForFirstName>
                    <input type="text" class="form-control" name="staffFirstName" required
                      placeholder="Enter First Name" [(ngModel)]="staffFirstName" autocomplete="off">
                  </ng-template>
                  <div class="error" *ngIf="staffFirstNameValidator && staffFirstNameValidator.error">
                    <span>{{staffFirstNameValidator.msg}}</span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" [class.required]="staffType !== 'USER'">{{"last_name" | customTranslate}}</label>
                  <ng-container *ngIf="staffType === 'USER' || staffType==='TRANSPORTER'; else elseBlockForLastName">
                    <input type="text" class="form-control" name="lastName" placeholder="Enter Last Name"
                      [(ngModel)]="user.lastName" autocomplete="off" (change)="setUserName()">
                  </ng-container>
                  <ng-template #elseBlockForLastName>
                    <input type="text" class="form-control" name="staffLastName" placeholder="Enter Last Name"
                      [(ngModel)]="staffLastName" autocomplete="off">
                  </ng-template>
                  <div class="error" *ngIf="staffLastNameValidator && staffLastNameValidator.error">
                    <span>{{staffLastNameValidator.msg}}</span>
                  </div>
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"Gender" | customTranslate}}</label>
                  <select name="gender" id="#gender" class="form-control" [disabled]="editFlag"
                    [(ngModel)]="user.gender">
                    <option selected="Male" value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"mobile_no." | customTranslate}}</label>
                  <input type="tel" class="form-control" name="mobile" placeholder="Enter Mobile No."
                    [(ngModel)]="user.mobile" required #mobile="ngModel" autocomplete="off" maxlength="10">
                  <div class="error" *ngIf="mobileValidator && mobileValidator.error">
                    <span>{{mobileValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"email_id" | customTranslate}}</label>
                  <input type="email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" class="form-control"
                    autocomplete="off" placeholder="Enter Email" [(ngModel)]="user.email" required name="email"
                    #email=ngModel>
                  <div class="error" *ngIf="emailValidator && emailValidator.error">
                    <span>{{emailValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="this.staffType=='USER' || staffType=='TRANSPORTER'">
                  <label for="my-input" class="required">{{"user_name" | customTranslate}}</label>
                  <input type="text" class="form-control" name="userName" required placeholder="Enter User Name"
                    disabled=true [(ngModel)]="user.userName" #userName="ngModel" autocomplete="off" disabled>
                    <!-- [disabled]="user.userId?'true':'false'"> -->
                  <div class="error" *ngIf="userNameValidator && userNameValidator.error">
                    <span>{{userNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="this.staffType=='USER' || this.staffType=='TRANSPORTER'">
                  <label for="my-input" class="required">{{"password" | customTranslate}}</label>
                  <input [type]="user.userId ? 'text' : 'password'" class="form-control" name="password" required placeholder="Enter Password "
                    [(ngModel)]="user.password" #password="ngModel" autocomplete="off"
                    [disabled]="user.userId?'true':'false'">
                  <div class="error" *ngIf="passwordValidator && passwordValidator.error">
                    <span>{{passwordValidator.msg}} </span>
                  </div>

                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="this.staffType=='USER'">
                  <label for="my-input" class="required">{{"status" | customTranslate}}</label>
                  <select name="user_status" id="#user_status" class="form-control" [disabled]="editFlag"
                    [(ngModel)]="user.status">
                    <option selected="ACTIVE" value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </select>
                </div>
                <div class="error" *ngIf="statusValidator && statusValidator.error">
                  <span>{{statusValidator.msg}} </span>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="this.staffType=='STAFF'">
                  <label for="my-input" class="required">{{"status" | customTranslate}}</label>
                  <select name="user_status" id="#user_status" class="form-control" [disabled]="editFlag"
                    [(ngModel)]="user.status">
                    <option selected="ACTIVE" value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </select>
                </div>
                <div class="error" *ngIf="statusValidator && statusValidator.error">
                  <span>{{statusValidator.msg}} </span>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="this.staffType=='TRANSPORTER'">
                  <label for="my-input" class="required">{{"status" | customTranslate}}</label>
                  <select name="user_status" id="#user_status" class="form-control" [disabled]="editFlag"
                    [(ngModel)]="user.status">
                    <option selected="ACTIVE" value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </select>
                </div>
                <div class="error" *ngIf="statusValidator && statusValidator.error">
                  <span>{{statusValidator.msg}} </span>
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="this.staffType=='USER'">
                  <label for="my-input" class="required">{{"employee_id" | customTranslate}}</label>
                  <input type="number" class="form-control" autocomplete="off" min="0" placeholder="Enter Employee Id"
                    [(ngModel)]="user.empId" name="EmpId">
                  <div class="error" *ngIf="empIdValidator && empIdValidator.error">
                    <span>{{empIdValidator.msg}} </span>
                  </div>
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="staffType!='TRANSPORTER'">
                  <label for="my-input"> {{"Department" | customTranslate}} </label>
                  <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedDepts($event)" [data]="deptList"
                    display="dept_name" placeholder="Choose Department" [preSelected]="selectedDeptNames">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="this.staffType=='USER'">
                  <label for="my-input">{{"select_vehicle" | customTranslate}} </label>
                  <div *ngFor="let item of user.vehicles; let i=index" class="mb-2">
                    <div class="row">
                      <div class="col-md-7">
                        <ps-auto-suggestion (onSelected)="item.vehicleRsn= $event.vehicleRsn"
                          (onChange)="item.vehicleRsn= $event" [data]="vehiclesList" display="vehicleRsn"
                          placeholder="Choose Vehicle" [preSelected]="{vehicleRsn:item.vehicleRsn}">

                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 plus_minus_icon">
                        <span class="input-group-text remove-field" id="my-addon" (click)="removeVehicle(i)">
                          <i class="fa fa-trash remove"></i>
                        </span>
                        <span class="input-group-text add-field" *ngIf="i == user.vehicles.length-1" id="my-addon"
                          (click)="addVehicle(i)">
                          <i class="fa fa-plus add"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    customTranslate}}</button>
                  <button class="btn btn-warning" (click)="resetDetail()">{{"reset" | customTranslate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn}} </button>
                </div>
              </div>
            </div>



            <!-- <div class="row">
        <div class="col-md-12">
          <div class="card"> -->
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title" *ngIf="this.staffType=='USER'">
                  {{"user_list" | customTranslate}}
                </h3>
                <h3 class="card-title" *ngIf="this.staffType=='STAFF'">
                  {{"Staff List" | customTranslate}}
                </h3>
                <h3 class="card-title" *ngIf="this.staffType=='TRANSPORTER'">
                  {{"Transporter List" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <!-- table -->
              <!-- <div  [hidden]="this.staffType!='USER'"> -->
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{staffType=='STAFF'|| staffType=='TRANSPORTER' ? 's.no.' : "employee_id" | customTranslate}}</th>
                    <th>{{"name" | customTranslate}}</th>
                    <th>{{"mobile_no." | customTranslate}}</th>
                    <th>{{staffType!='TRANSPORTER'? "department" : "UserName" |customTranslate}}</th>
                    <th>{{staffType!='TRANSPORTER'?"type" : "email id"| customTranslate}}</th>
                    <th>{{"status" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody >
                  <tr *ngFor="let row of tableData let i = index">
                    <td>{{staffType=='STAFF' || staffType=='TRANSPORTER' ? i+1 : row.employeeId}}</td>
                    <td>{{staffType=='STAFF' || staffType=='TRANSPORTER' ? row.first_name +" "+row.last_name :row.name}}</td>
                    <td>{{staffType=='STAFF' || staffType=='TRANSPORTER' ? row.mobile_number : row.mobileNumber}}</td>
                    <td *ngIf="staffType!='TRANSPORTER'">{{formatZoneNames(row.departments)}}</td>
                    <td *ngIf="staffType=='USER'"><span class="pill"
                        [ngClass]="{'bg-blue-100 text-blue-800':(row.userType=='AXESTRACK'||row.userType=='AXESTRACKUSER'),'bg-yellow-100 text-yellow-800':(row.userType=='COMPANYADMIN'||row.userType=='COMPANYUSER'),'bg-green-100 text-green-800':(row.userType=='PLANTADMIN'||row.userType=='PLANTUSER')}">
                        {{row.userType}}</span></td>
                    <td *ngIf="staffType=='STAFF'"><span class="pill"
                        [ngClass]="{'bg-pink-100 text-pink-800':row.userType=='TRANSPORTER','bg-blue-100 text-blue-800':row.type_name=='Visitor','bg-yellow-100 text-yellow-800':row.type_name=='Contractual','bg-green-100 text-green-800':row.type_name=='Labour','bg-indigo-100 text-indigo-800':row.type_name=='Security'}">
                        {{row.type_name}}</span></td>
                    <td *ngIf="staffType=='TRANSPORTER'">{{row.user_name}}</td>
                    <td *ngIf="staffType=='TRANSPORTER'">{{row.email}}</td>
                    <td><span class="pill"
                      [ngClass]="{'bg-yellow-100 text-yellow-800':row[statusKey]=='INACTIVE','bg-green-100 text-green-800':row[statusKey]=='ACTIVE','bg-red-100 text-red-800':row[statusKey]=='BLOCKED'}">
                      <!-- {{staffType=='TRANSPORTER'?row.user_status:(staffType=='STAFF' ? row.staff_status : row.userStatus)}} -->
                      {{row[statusKey]}}
                    </span></td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" [style]='"background-color:rgb(216 3 3)"'
                          *ngIf="staffType!='STAFF' && row[statusKey]!='BLOCKED'" (click)="staffType=='USER' ? confirmAlertForUserBan(row.userId,'block') : confirmAlertForTransporter(row,'BLOCKED')">{{"BLOCK" |
                          customTranslate}}</button>
                        <button class="btn btn-primary" [style]='"background-color:rgb(216 3 3)"'
                          *ngIf="staffType!='STAFF' && row[statusKey]=='BLOCKED'" (click)="staffType=='USER' ? confirmAlertForUserBan(row.userId,'unblock') : confirmAlertForTransporter(row,'ACTIVE')">{{"UNBLOCK" |
                          customTranslate}}</button>
                        <button class="btn btn-success" (click)="staffType=='TRANSPORTER'?editTransporter(row):(staffType=='STAFF' ? editEmployee(row) : editUser(row))"
                          *ngIf="userService.permissionMap.get('edit_user_access')==true"> {{"edit" | customTranslate}}
                        </button>
                        <button class="btn btn-danger" (click)="confirmAlert(row)"
                          *ngIf="row.userStatus!='DELETED' && userService.permissionMap.get('delete_user_access')==true && userId!=row.userId">{{"delete"
                          | customTranslate}}</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- </div> -->

            </div>
            <!-- </div>
        </div>
      </div> -->
          </div>
        </ng-container>
        </div>




      </div>

    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { CompanyService } from '../companies/companies.service';
import { PlantService } from '../plant/plant.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';

@Component({
  selector: 'app-alert-type',
  templateUrl: './alert-type.component.html',
  styleUrls: ['./alert-type.component.scss'],
})
export class AlertTypeComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  isdisable = false;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'Alert Type table');

  companyId: any;
  companyName: any;
  plantName: null;
  plantId: null;
  plantData: any;
  company: any[] = [];
  typeName: any;
  typeColor: any;
  alertTypeList: any[];
  btn = 'save';
  alertId: any;
  constructor(
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public alert: AlertService,
    public table: TableService,
    public userService: UserService,
    public api: ApiService,
    public role: RoleService,
    public companyService: CompanyService,
    public plantService: PlantService
  ) {
    this.getAlertList();
    if (this.role.user_role != 'AXESTRACK') {
      this.plantId = this.userService._loggedInUser.plantId;
      this.companyId = this.userService._loggedInUser.companyId;
    }
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'alert', url: null },
      { name: 'Alert Type', link: '/user/alert-type-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit() {
    this.getCompany();
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    if (event.companyId) {
      this.getPlantById(event.companyId);
    }
    this.plantName = null;
    this.plantId = null;
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
  }
  getPlantById(id?: any) {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        id: id,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.plantData = res.data;
            resolve(this.plantData);
          }
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
        }
      );
    });
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          res.data.forEach((element) => {
            if (element.companyStatus == 'ACTIVE') this.company.push(element);
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  reset() {
    this.typeColor = null;
    this.typeName = null;
    this.plantId = null;
    this.companyId = null;
    this.companyName = null;
    this.plantName = null;
    this.plantData = [];
    this.alertId = null;
    this.alertTypeColorValidator = null;
    this.alertTypeNameValidator = null;
    this.getAlertList();
    this.btn = 'save';
  }

  saveAlert() {
    let params;
    if (!this.alertId) {
      params = {
        typeName: this.typeName,
        typeColor: this.typeColor,
        companyId: this.companyId,
        plantId: this.plantId,
      };
    } else {
      params = {
        typeName: this.typeName,
        typeColor: this.typeColor,
        companyId: this.companyId,
        plantId: this.plantId,
        alertTypeId: this.alertId,
      };
    }

    this.api.post('alert/save_alert_type', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alert.info('Data Successfully Saved');
          this.common.loading = false;
          this.reset();
        }
      },
      (err) => {
        this.common.loading = false;
      }
    );
  }

  getAlertList() {
    let apiUrl = 'alert/get_alert_type';
    if (this.role.user_role == 'AXESTRACK') {
      apiUrl = 'alert/get_alert_type?plantId=-1';
    }
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        if (res.status) {
          // this.alert.info("Data Successfully Get");
          this.alertTypeList = res.data;
          this.common.loading = false;
          // this.reset();
        }
      },
      (err) => {
        this.common.loading = false;
      }
    );
  }

  async editAlert(event: any) {
    this.typeName = event.type_name;
    this.typeColor = event.type_color;
    this.companyId = event.company_id;
    this.alertId = event.id;
    this.companyName = event.company_name;
    await this.getPlantById(this.companyId);
    this.plantId = event.plant_id;
    this.plantName = event.plant_name;
    this.btn = 'update';
  }

  deleteAlert(row) {
    this.alertId = row.id;
    this.api.get('alert/delete_alert_type?alertId=' + this.alertId).subscribe(
      (res: any) => {
        if (res.status) {
          this.alert.info('Data Successfully Deleted');
          this.common.loading = false;
          this.reset();
        }
      },
      (err) => {
        this.common.loading = false;
      }
    );
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Driver ',
      description: `<b>&nbsp;` + 'Are you sure want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteAlert(row);
      }
    });
  }

  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'View Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  alertTypeNameValidator: any = null;
  alertTypeColorValidator: any = null;
  formValidator() {
    this.alertTypeNameValidator = this.common.nullValidator(
      this.typeName,
      'Alert Type Name Mandatory'
    );
    this.alertTypeColorValidator = this.common.nullValidator(
      this.typeColor,
      'Alert Type Color Mandatory'
    );
    if (
      !this.alertTypeNameValidator.error &&
      !this.alertTypeColorValidator.error
    ) {
      this.saveAlert();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { SharedService } from 'src/app/components/shared.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  notification: any = null;

  constructor(private notificationService: NotificationService, private shared: SharedService) {}

  ngOnInit(): void {
    this.notificationService.notification.subscribe((message: any) => {
      if(message.notificationType != "OPERATION") {
        this.notification = message;
        setTimeout(() => {
          this.notification = null;
        }, 3000);
      } else {
        this.shared.updateVehicleData(message.extraData)
      }
    });
  }
}

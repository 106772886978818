<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"dock_management" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-4 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"Process" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="process" display="processName"
                    placeholder="Choose Process" [preSelected]="{processName:processName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error">
                    <span>{{processValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-4 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"Stage" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedStage($event)" [data]="stages" display="stage_name"
                    placeholder="Choose Stage" [preSelected]="{stage_name:stageName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="stageValidator && stageValidator.error">
                    <span>{{stageValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-4 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"Dock Count" | customTranslate}}</label>
                  <input type="number" min=0 [(ngModel)]="dockCount" [ngModelOptions]="{standalone: true}"
                    class="form-control" (change)="createDock($event)">
                  <div class="error" *ngIf="dockCountValidator && dockCountValidator.error">
                    <span>{{dockCountValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="dockCount  && stageName">
                <div class="table_dock">
                  <table>
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Dock Name
                        </th>
                        <th>Vehicle Limit <input type="checkbox" name="applyAllVehicleLimit"
                            [checked]="applyAllVehicleLimit" (change)="applyAllVehicleLimit=!applyAllVehicleLimit"></th>
                        <th>Dock TAT <input type="checkbox" name="applyAllDockTAT" [checked]="applyAllDockTAT"
                            (change)="applyAllDockTAT=!applyAllDockTAT"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let item of dockData.slice(0,dockCount); let i = index">
                        <td>
                          {{i+1}}
                        </td>
                        <td>
                          <input type="text" class="field_class" [value]="item.dockName" [(ngModel)]="item.dockName">
                        </td>
                        <td>
                          <input type="number" class="field_class"
                            (input)="item.vehicleLimit;saveLatestData(item.vehicleLimit,-1)"
                            [(ngModel)]="item.vehicleLimit" [ngModelOptions]="{standalone: true}"
                            [value]="item.vehicleLimit">
                        </td>
                        <td>
                          <input type="time" class="field_class" (input)="item.tat;saveLatestData(-1,item.tat)"
                            [(ngModel)]="item.tat" [value]="item.tat">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-container>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="reset()"> {{"reset" | customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{buttonName | customTranslate}}
                  </button>
                </div>
              </div>

            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"s.no." | customTranslate}} </th>
                    <th>{{"process" | customTranslate}} </th>
                    <th>{{"stage" | customTranslate}} </th>
                    <th>{{"no_of_dock_slots" | customTranslate}} </th>
                    <th class="action">{{"action" | customTranslate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of dockDataTable let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.process_name}}</td>
                    <td>{{row.stage_name}}</td>
                    <td>{{row.dock_data.length}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="view(row)"> {{"view" | customTranslate}} </button>
                        <button class="btn btn-success" (click)="edit(row)"> {{"edit" | customTranslate}} </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>



      </div>
    </div>
  </div>
</div>

import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { Component, OnInit} from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { StageService } from '../stage/stage.service';
import { UserService } from 'src/app/@core/services/user.service';
import { SingleDataSet, Label, Color } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { ProcessVehicleViewComponent } from 'src/app/modals/process-vehicle-view/process-vehicle-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { TABLE_TYPE, TableActions, TableColumns } from 'src/app/dataType/customTableInterface';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { DatePipe } from '@angular/common';
import { Process,Stage, Vehicle } from 'src/app/dataType/commonInterface';
import { PlantData,CompanyData } from 'src/app/dataType/assetInterface';
import * as XLSX from 'xlsx';
import * as filesaver from 'file-saver';

@Component({
  selector: 'app-tat-reports',
  templateUrl: './tat-reports.component.html',
  styleUrls: ['./tat-reports.component.scss'],
})
export class TatReportsComponent implements OnInit {
    // Datatable Configs
  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  loading: boolean = false;
  columns: TableColumns[] = [];
  data: any = [];

  tabvalue: String = 'table';
  startDate: Date = new Date(new Date().setDate(new Date().getDate() - 6));
  endDate: Date = new Date();
  today: string;
  listOfProcess: Process[] = [];
  stageData: Stage[] = [];
  sumOfTotalProcessWiseTat: string = '';
  sumOfTotalProcessWiseExpectedTat: string = '';
  responseData: any[];
  dayWiseTatList: any = [];
  totalProcessWiseTatList: any = [];
  totalProcessWiseExpectedTatList: any = [];
  headers: any[] = [];
  plantData: PlantData[] = [];
  plantId: number = null;
  plantName: string = null;
  reportType: string = 'Process Wise';
  vehicleList:Vehicle[] = [];
  showAutocancelledFlag : boolean = true;
  showInactiveProcessFlag : boolean = false;
  selectedVehicleIds: number[] = [];
  selectedVehicleNames : any [] = [];
  selectedStatuses : string [] = [];
  selectedStatusNames : any [] = [];
  selectedProcessIds : number[] = [];
  selectedProcessNames : any[] = [];
  selectedStageIds : number[] = [];
  selectedStageNames : any[] = [];
  companyList : CompanyData[] = [];
  companyId: number = null;
  companyName: string = null;

  dateLabels: Label[] = [];
  public pieChartType: ChartType = 'doughnut';
  public barChartType: ChartType = 'bar';
  public pieChartLegend = true;

  dateWiseTotalTat: ChartDataSets[] = [];
  processLabels: Label[] = [];
  processAverageTat: any[] = [];
  processAverageTatData: SingleDataSet = [];
  dateWiseProcessLabels: Label[] = [];
  dateWisePerProcessChartData: ChartDataSets[] = [];

  dateWiseStageTotalTat: ChartDataSets[] = [];
  stageAverageTat: any[] = [];
  stageLabels: Label[] = [];
  stageAverageTatData: SingleDataSet = [];
  dateWisePerStageChartData: ChartDataSets[] = [];
  dateWiseStageLabels: Label[] = [];

  dateWiseDestinationTotalTat: ChartDataSets[] = [];
  destinationAverageTat: any = [];
  destinationLabels: Label[] = [];
  destinationAverageTatData: SingleDataSet = [];
  dateWisePerDestinationChartData: ChartDataSets[] = [];
  dateWiseDestinationLabels: Label[] = [];
  newPlantTatData: any = [{}];
  totalInbetweenTotal: any = '00:00';
  totalInbetweenExpected: any = '00:00';
  tabData: any = [
    { tabname: 'Process Wise', value: 'Process Wise' , permission : this.userService.permissionMap.get('process_wise_tat_access') },
    { tabname: 'Stage Wise', value: 'Stage Wise' , permission : this.userService.permissionMap.get('stage_wise_tat_access')},
    { tabname: 'Destination Wise', value: 'Destination Wise' , permission : this.userService.permissionMap.get('destination_wise_tat_access')},
    { tabname: 'InBetween', value: 'InBetween',permission : this.userService.permissionMap.get('inbetween_tat_access') },
    { tabname: 'Tag Destination', value: 'Tag Destination' ,permission : this.userService.permissionMap.get('tag_destination_tat_access') },

  ];
  statusList = [{ id: 1, status: 'INPROGRESS'},
                { id: 2, status: 'COMPLETED'},
                { id: 3, status: 'CANCELLED'},
                { id: 4, status: 'AUTO CANCELLED'}];





  public dateWiseChartLegend = true;
  // public dateWiseChartPlugins = [pluginDataLabels]
  public dateWiseChartPlugins = [];

  public barChartOptions: ChartOptions = {
    animation: {
      duration: 1000,
      easing: 'linear',
      animateRotate: true,
      animateScale: true,
    },
    plugins: {
      datalabels: {
        formatter(value, context) {
          const hours = Math.floor(value / (60 * 60));
          const min = String(value / 60).substring(0, 2);
          return hours == 0 && Number(min) == 0
            ? '0 hr'
            : hours + ':' + min + ' hr';
        },
      },
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Total TAT per date',
            fontSize: 12,
            fontStyle: 'bold',
            fontFamily: ' sans-serif',
          },
          ticks: {
            callback: function (v) {
              return epoch_to_hh_mm_ss(v);
            },
            // stepSize: 60 * 60
          },
        },
      ],
    },

    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ': ' +
            epoch_to_hh_mm_ss(tooltipItem.yLabel) +
            ' hr'
          );
        },
      },
    },
  };

  public barChartColors: Color[] = [
    { backgroundColor: this.common.graphsecondary },
    { backgroundColor: this.common.graphsuccess },
    { backgroundColor: this.common.graphdanger },
  ];

  public piechartoptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    legend: {
      align: 'center',
    },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#3f7ac7',
        font: {
          weight: 'bold',
          size: 12,
        },
        formatter(value, context) {
          const hours = Math.floor(value / (60 * 60));
          const min = String(value / 60).substring(0, 2);
          return hours == 0 && Number(min) == 0
            ? '0 hr'
            : hours + ':' + min + ' hr';
        },
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return (
            data.labels[tooltipItem.index] +
            ': ' +
            epoch_to_hh_mm_ss(value) +
            ' hr'
          );
        },
      },
    },
  };

  constructor(
    private tableService: TableService,
    public common: CommonService,
    public api: ApiService,
    public alert: AlertService,
    public stageService: StageService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public modalService: NgbModal,
    public plantService: PlantService,
    public role: RoleService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Tat Report', url: '/user/tat-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }



  ngOnInit() {
    const currentDate = new Date();
    this.today = currentDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    this.startDate = this.common.dateFormatter(this.startDate,'YYYYMMDD',false);
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    if(this.role.user_role == 'AXESTRACK'){
      this. getCompany();
    }
    else if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
    else {
      this.getAllProcess();
      this.getTatReport();
    }
    this.getAllVehicles();
  }

  getCompany(){
    this.common.loading=true;
    let params={
      all_company:false
    }
    this.api.get('company/all_company',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
      );
    }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    if (event.companyId) {
      this.getPlantById(event.companyId);
    }
    this.plantName = null;
    this.plantId = null;
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getAllProcess();
    this.getTatReport();
  }

  selectedProcess(event: any) {
    this.selectedProcessIds = event.length ? event.map(item => item.processId) : [];
    this.selectedProcessNames = event.map((item) => ({
      processId: item.processId,
      processName: item.processName,
    }));
    this.getTatReport();
  }

  selectedStage(event: any) {
    this.selectedStageIds = event.length ? event.map(item => item.stageId) : [];
    this.selectedProcessNames = event.map((item) => ({
      stageId: item.stageId,
      stageName: item.stageName,
    }));
    this.getTatReport();
  }

  async selectVehicle(event: any) {
    if (event.length) {//if any selected Vehicle present
      this.selectedVehicleIds = event.map(item => item.vehicle_id);
      this.selectedVehicleNames = event.map((item) => ({
        vehicle_id: item.vehicle_id,
        vehicle_rsn: item.vehicle_rsn,
      }));
    } else {
      this.selectedVehicleIds = [];
    }
    this.getTatReport();
  }

  async selectStatus(event: any) {
    if (event.length) {//if any selected status present
      this.selectedStatuses = event.map(item => item.status);
      this.selectedStatusNames = event.map((item) => ({
        id: item.id,
        status: item.status,
      }));
    } else {
      this.selectedStatuses = [];
    }
    this.getTatReport();
  }


  resetPage() {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.endDate = new Date();
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.resetFilters();
    this.getTatReport();
  }

  resetFilters(){
    this.selectedStatuses = [];
    this.selectedVehicleIds = [];
    this.selectedProcessIds = [];
    this.selectedStageIds = [];
    this.selectedStatusNames = [];
    this.selectedVehicleNames = [];
    this.selectedProcessNames = [];
    this.selectedStageNames = [];
  }

  getAllStage() {
    this.common.loading = true;
    let params = {
      plantId: this.plantId,
    };
    this.stageService.getStageById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.stageData = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.plantId,
      processType : 'Trip'
    };
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.listOfProcess = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getTatReport() {
    this.loading = true;
    this.data = [];
    if (this.startDate > this.endDate) {
      this.alert.warn('Start Date Should be Smaller than End Date!!');
      return;
    }
    this.common.loading = true;
    let params ={
      fromDate: this.startDate,
      toDate: this.endDate,
      showAutoCancelled:this.showAutocancelledFlag,
      showInactive: this.showInactiveProcessFlag,
      statusList: this.selectedStatuses,
      vehicleIds : this.selectedVehicleIds,
      processIds: this.selectedProcessIds,
      stageIds: this.selectedStageIds,
      reportType : this.reportType,
      plantId : this.plantId
    }
    this.api.post('report/get-tat-report', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.responseData = res.data ?? [];
        console.log(' this.responseData: ',  this.responseData);
        // this.headers = this.responseData[0].data;
        // console.log('this.headers: ', this.headers);
        if (this.reportType == 'InBetween') {
          this.getInbetweenTATData(this.responseData);
          return; // Stop further execution
        }

        let groupingKeyName = this.reportType == 'Process Wise' ? 'process_name' : this.reportType == 'Stage Wise' ? 'stage_name' :'destination_name'
        let statusKey = this.reportType == 'Process Wise' ? 'process_status' : this.reportType == 'Stage Wise' ? 'stage_status' :'destination_status'
        let _self = this
        this.data = this.responseData[0].actualTATData.map(d => {
          d = {
            ...d,
          }
          return d;
        });
        this.columns = [
          {
            columnLabel: "Date",
            columnName: "date",
            dataFormatter(row, column, index) {
              return _self.datePipe.transform(row[column.columnName], 'dd-MM-yyyy');

            }
          },
          ...this.responseData[0].expectedTATData.map(el => {
            const expectedTat = el.expected_tat;
            return {
              columnLabel: el[groupingKeyName],
              columnName: this.reportType == 'Process Wise' ? el.process_id : el.stage_id + "_" + el.process_id,
              extraData: el,
              columnFormatter: (column: TableColumns, index: number) => {
                const processAbbv = this.listOfProcess.find(dataItem => dataItem['processId'] === el['process_id'])?.process_abbreviation || '';
                return `${column.columnLabel} ${processAbbv ? `(${processAbbv})` : ''} ${el?.[statusKey] !== 'ACTIVE' ? `<span class="badge badge-danger">Inactive</span>` : ''}`;
              },
              dataFormatter: (row: any, column: TableColumns, index: number) => {
                const actualTat = row.data[column.columnName] ?? '00:00'
                return (
                  `<span class="${actualTat > expectedTat ? 'badge over' : 'badge'}">${actualTat}</span> /
                   <span class="badge info">${expectedTat}</span>`
                );
              },
              dataActions: [
                {
                  actionName: "",
                  actionFormatter: (column: TableColumns, row: any, index: number) => {
                    const actualTat = row.data[column.columnName] ?? '00:00'
                    return actualTat > expectedTat
                    ? `<i class="fa fa-info-circle" style="margin-left: 5px;color: #2d2d81;font-size: 15px"></i>`: '';
                  },
                  handleClick:(column: TableColumns, row: any, index: number,actionName: string) => {
                    this.openModal(row.date,el)
                  }
                }
              ] as TableActions[]
            } as TableColumns;
          }) as TableColumns[],
          {
            columnLabel: "Plant TAT",
            columnName: "",
            columnFormatter: (column: TableColumns, index: number) => {
              return `<span class="total-label">${column.columnLabel}</span>`
            },
            dataFormatter : (row: any, column: TableColumns, index: number) => {
              const totalActualTatMinutes = Object.values(row.data).reduce((sum:number, time:string) => {
                return sum + this.timeToMinutes(time || '00:00');
              }, 0);
              const avgActualTat = this.minutesToTime(Math.floor(parseInt(totalActualTatMinutes.toString()) / this.responseData[0].expectedTATData.length));
              return (`<span class="badge info">${avgActualTat}</span>`)
            }
          },
          {
            columnLabel: "Date Wise TAT",
            columnName: "",
            columnFormatter: (column: TableColumns, index: number) => {
              return `<span class="total-label">${column.columnLabel}</span>`
            },
            dataFormatter: (row: any, column: TableColumns, index: number) => {
              const totalActualTatMinutes = Object.values(row.data).reduce((sum:number, time:string) => {
                const match = this.responseData[0].actualTATData.some(dataItem => dataItem[column.columnName] === row.data[column.columnName]);
                if (match) {
                  return sum + this.timeToMinutes(time || '00:00');
                }
                return sum;
              }, 0);
              const avgActualTat = this.minutesToTime(Math.floor(parseInt(totalActualTatMinutes.toString()) / this.responseData[0].expectedTATData.length));
              const totalExpectedTatMinutes = this.responseData[0].expectedTATData.reduce((sum, stage) => sum + this.timeToMinutes(stage.expected_tat || '00:00'), 0);
              const avgExpectedTat = this.minutesToTime(Math.floor(totalExpectedTatMinutes / this.responseData[0].expectedTATData.length));
              return (
                  `<span class="${avgActualTat > avgExpectedTat ? 'badge over' : 'badge'}">${avgActualTat}</span> /
                   <span class="badge info">${avgExpectedTat}</span>`
                );
            }
        }
        ]
        console.log("columns", this.columns);



















        // this.dayWiseTat(this.responseData);
        // this.totalProcessWiseTat(this.responseData);
        // this.totalProcessWiseExpectedTat(this.responseData);
        // this.totalInbetweenTotal = '00:00';
        // this.totalInbetweenExpected = '00:00';
        // if (this.reportType == 'Stage Wise') {
        //   this.getTotalInbetweenTat(this.responseData);
        //   this.getTotalInbetweeneExpectedTat(this.responseData);
        // }
        // this.sumTotalProcessWiseTat();
        // this.sumOfTotalProcessWiseExpectedTatList();
        // // this.getDateWisePerProcessTatGraphData();
        // this.getDateWisePerStageTatGraphData();
        // this.getDateWisePerDestinationTatGraphData();
        // let groupingKeyName = this.reportType == 'Process Wise' ? 'process_name' : this.reportType == 'Stage Wise' ? 'stage_name' :'destination_name'
        // let statusKey = this.reportType == 'Process Wise' ? 'process_status' : this.reportType == 'Stage Wise' ? 'stage_status' :'destination_status'


        // let _self = this
        // this.data = res.data.map(d => {
        //   d = {
        //     ...d,
        //     ..._.groupBy(d.data, groupingKeyName)
        //   }
        //   return d;
        // });
        // console.log('this.data: ', this.data);
        // this.columns = [
        //   {
        //     columnLabel: "Date",
        //     columnName: "date",
        //     dataFormatter(row, column, index) {
        //       return _self.datePipe.transform(row[column.columnName], 'dd-MM-yyyy');

        //     }
        //   },
        //   ...this.data[0].data.map(el => ({
        //     columnLabel: el[groupingKeyName],
        //     columnName: "",
        //     columnFormatter: (column: TableColumns, index: number) => {
        //       const processAbbv = this.listOfProcess.find(dataItem => dataItem['processId'] === el['process_id'])?.process_abbreviation || '';
        //       return `${column.columnLabel} ${processAbbv ? `(${processAbbv})` : ''} ${el?.[statusKey] !== 'ACTIVE' ? `<span class="badge badge-danger">Inactive</span>` : ''}`;
        //     },
        //     dataFormatter: (row: any, column: TableColumns, index: number) => {
        //       let expectedTAT =  row[column.columnLabel] ? row[column.columnLabel][0].expected_tat : '00:00';
        //       let actualTAT = row[column.columnLabel]? row[column.columnLabel][0].tat : '00:00';
        //       return (`<span class="${actualTAT > expectedTAT ? 'badge over' : 'badge'}">${actualTAT}</span> /
        //                 <span class="badge info">${expectedTAT}</span>`)
        //     },
        //     dataActions: [
        //       {
        //         actionName: "",
        //         actionFormatter: (column: TableColumns, row: any, index: number) => {
        //           let expectedTAT =  row[column.columnLabel] ? row[column.columnLabel][0].expected_tat : '00:00';
        //           let actualTAT = row[column.columnLabel]? row[column.columnLabel][0].tat : '00:00';
        //           return `<i *ngIf="${actualTAT > expectedTAT}" class="fa fa-info-circle"
        //                   style="margin-left: 5px;color: #2d2d81;font-size: 15px"></i>`;
        //         },
        //         handleClick:(column: TableColumns, row: any, index: number,actionName: string) => {
        //           this.openModal(row.date,row[column.columnLabel][0])
        //         }
        //       }
        //     ] as TableActions[]
        //   } as TableColumns)) as TableColumns[],

        //   {
        //     columnLabel: "Plant TAT",
        //     columnName: "",
        //     columnFormatter: (column: TableColumns, index: number) => {
        //       return `<span class="total-label">${column.columnLabel}</span>`
        //     },
        //     dataFormatter : (row: any, column: TableColumns, index: number) => {
        //       return (`<span class="${this.data[index]?.actual_plant_tat > this.data[index]?.expected_plant_tat ? 'badge over' : 'badge'}">${this.data[index]?.actual_plant_tat  }</span> /
        //                 <span class="badge info">${this.data[index]?.expected_plant_tat}</span>`)
        //     }
        //   },
        //   {
        //     columnLabel: "Stage Inbetween Total TAT",
        //     columnName: "",
        //     columnFormatter: (column: TableColumns, index: number) => {
        //       return `<span class="total-label">${column.columnLabel}</span>`
        //     },
        //     dataFormatter : (row: any, column: TableColumns, index: number) => {
        //       return (`<span class="${this.data[index]?.inbetween_tat > this.data[index]?.inbetween_expected_tat ? 'badge over' : 'badge'}">${this.data[index]?.inbetween_tat  }</span> /
        //                 <span class="badge info">${this.data[index]?.inbetween_expected_tat}</span>`)
        //       }
        //   },
        //   {
        //     columnLabel: "Date Wise TAT",
        //     columnName: "",
        //     columnFormatter: (column: TableColumns, index: number) => {
        //       return `<span class="total-label">${column.columnLabel}</span>`
        //     },
        //     dataFormatter: (row: any, column: TableColumns, index: number) => {
        //          const totalActualTatMinutes = row.data.reduce((sum, stage) => sum + this.timeToMinutes(stage.tat || '00:00'), 0);
        //          const totalExpectedTatMinutes = row.data.reduce((sum, stage) => sum + this.timeToMinutes(stage.expected_tat || '00:00'), 0);
        //           const avgActualTat = this.minutesToTime(Math.floor(totalActualTatMinutes / row.data.length));
        //          const avgExpectedTat = this.minutesToTime(Math.floor(totalExpectedTatMinutes / row.data.length));
        //          return (
        //             `<span class="${avgActualTat > avgExpectedTat ? 'badge over' : 'badge'}">${avgActualTat}</span> /
        //              <span class="badge info">${avgExpectedTat}</span>`
        //         );
        //     }
        // }
        // ]
        let tempColumns = _.cloneDeep(this.columns);
        if (this.reportType == 'Process Wise') {
          this.columns = tempColumns.filter(e => e.columnLabel!== 'Date Wise TAT');
        }
        else if(this.reportType == 'Stage Wise' || this.reportType == 'Destination Wise'){
          this.columns = tempColumns.filter(e => e.columnLabel !== 'Plant TAT');
        }
        this.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        this.loading = false;
      }
    );
  }

timeToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
}

minutesToTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
}


  getInbetweenTATData(res){
    this.data = [];
    console.log('res: ', res);
    let _self = this
        this.data = res[0].actualTATData.map(d => {
          d = {
            ...d,
            ..._.groupBy(d.data, 'stage_name')
          }
          return d;
        });
        this.columns = [
          {
            columnLabel: "Date",
            columnName: "date",
            dataFormatter(row, column, index) {
              return _self.datePipe.transform(row[column.columnName], 'dd-MM-yyyy');

            }
          },
          ...res[0].expectedTATData.map(el => {
            const expectedTat = el.expected_tat;
            const item = this.data[0].data.find(dataItem => dataItem['stage_code'] === el['stage_code']);
            return {
              columnLabel: el['stage_name'],
              columnName: "",
              dataFormatter: (row: any, column: TableColumns, index: number) => {
                const actualTat = this.data[0].data.find(dataItem => dataItem['stage_code'] === el['stage_code'])?.tat ?? '00:00';
                return (
                  `<span class="${actualTat > expectedTat ? 'badge over' : 'badge'}">${actualTat}</span> /
                   <span class="badge info">${expectedTat}</span>`
                );
              },
              dataActions: [
                {
                  actionName: "",
                  actionFormatter: (column: TableColumns, row: any, index: number) => {
                    const actualTat = item?.tat ?? '00:00'
                    return actualTat > expectedTat
                    ? `<i class="fa fa-info-circle" style="margin-left: 5px;color: #2d2d81;font-size: 15px"></i>`: '';
                  },
                  handleClick:(column: TableColumns, row: any, index: number,actionName: string) => {
                    this.openModal(row.date,item)
                  }
                }
              ] as TableActions[]
            } as TableColumns;
          }) as TableColumns[],
          {
            columnLabel: "Date Wise TAT",
            columnName: "",
            columnFormatter: (column: TableColumns, index: number) => {
              return `<span class="total-label">${column.columnLabel}</span>`
            },
            dataFormatter: (row: any, column: TableColumns, index: number) => {
              const totalActualTatMinutes = row.data.reduce((sum, stage) => {
                const match = this.data[0].data.some(dataItem => dataItem['stage_code'] === stage.stagecode);
                if (match) {
                  return sum + this.timeToMinutes(stage.tat || '00:00');
                }
                return sum;
              }, 0);
              const totalExpectedTatMinutes = row.data.reduce((sum, stage) => sum + this.timeToMinutes(stage.expected_tat || '00:00'), 0);
              const avgActualTat = this.minutesToTime(Math.floor(totalActualTatMinutes / res[0].expectedTATData.length));
              const avgExpectedTat = this.minutesToTime(Math.floor(totalExpectedTatMinutes / res[0].expectedTATData.length));
              return (
                  `<span class="${avgActualTat > avgExpectedTat ? 'badge over' : 'badge'}">${avgActualTat}</span> /
                   <span class="badge info">${avgExpectedTat}</span>`
                );
            }
        }
        ]
        this.loading =  false;
        return true;
  }

  dayWiseTat(list: any) {
    this.common.loading = true;
    this.dayWiseTatList = [];
    list.forEach((el) => {
      let sum = 0;
      if (this.reportType == 'Stage Wise') {
        let tempTat = el.inbetween_tat.split(':');
        sum = Number(tempTat[0]) * 3600 + Number(tempTat[1]) * 60;
      }
      let t=0;
      el.data.forEach((e) => {
        let arr = e.tat.split(':');
        let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
        sum = sum + tat;
        t++;
      });
      let seconds = sum/t;
      let numOfHours = (seconds / 3600).toString().split('.');
      seconds = seconds % 3600; // Remaining second after getting the hours
      let numOfMinutes = (seconds / 60).toString().split('.');
      let total =
        numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
      this.dayWiseTatList.push(total);
    });
    this.getDateWiseProcessTat();
    this.getDateWiseStageTat();
    this.getDateWiseDestinationTat();
    this.common.loading = false;
  }

  totalProcessWiseTat(data: any) {
    this.totalProcessWiseTatList = [];
    let count = 0;
    while (count != data[0].data.length) {
      let sum = 0;
      let t=0;
      data.forEach((e) => {
        let arr = e.data[count].tat.split(':');
        let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
        sum = sum + tat;
        t++;
      });
      // let seconds = sum;
      let seconds = sum/t;
      let numOfHours = (seconds / 3600).toString().split('.');
      seconds = seconds % 3600; // Remaining second after getting the hours
      let numOfMinutes = (seconds / 60).toString().split('.');
      let total =
        numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
      this.totalProcessWiseTatList.push(total);
      count++;
    }
    this.getProcessWiseAverageTatGraph();
    this.getStageWiseAverageTatGraph();
    this.getDestinationWiseAverageTatGraph();
  }

  totalProcessWiseExpectedTat(data:any){
    this.totalProcessWiseExpectedTatList = [];
    let count = 0;
    while (count != data[0].data.length) {
      let sum = 0;
      let t=0;
      data.forEach((e) => {
        let arr = e.data[count].expected_tat.split(':');
        let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
        sum = sum + tat;
        t++;
      });
      // let seconds = sum;
      let seconds = sum/t;
      let numOfHours = (seconds / 3600).toString().split('.');
      seconds = seconds % 3600; // Remaining second after getting the hours
      let numOfMinutes = (seconds / 60).toString().split('.');
      let total =
        numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
      this.totalProcessWiseExpectedTatList.push(total);
      count++;
    }
  }



  sumTotalProcessWiseTat() {
    let tempTat = this.totalInbetweenTotal.split(':');
    let sum = Number(tempTat[0]) * 3600 + Number(tempTat[1]) * 60;
    let t=0;
    this.totalProcessWiseTatList.forEach((e) => {
      let arr = e.split(':');
      let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
      sum = sum + tat;
      t++;
    });
    let seconds = sum/t;
    let numOfHours = (seconds / 3600).toString().split('.');
    seconds = seconds % 3600; // Remaining second after getting the hours
    let numOfMinutes = (seconds / 60).toString().split('.');
    let total =
      numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
    this.sumOfTotalProcessWiseTat = total;
  }

sumOfTotalProcessWiseExpectedTatList(){
  let tempTat = this.totalInbetweenExpected.split(':');
    let sum = Number(tempTat[0]) * 3600 + Number(tempTat[1]) * 60;
    let t=0;
    this.totalProcessWiseExpectedTatList.forEach((e) => {
      let arr = e.split(':');
      let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
      sum = sum + tat;
      t++;
    });
    let seconds = sum/t;
    let numOfHours = (seconds / 3600).toString().split('.');
    seconds = seconds % 3600; // Remaining second after getting the hours
    let numOfMinutes = (seconds / 60).toString().split('.');
    let total =
      numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
    this.sumOfTotalProcessWiseExpectedTat = total;
    console.log('dft',this.sumOfTotalProcessWiseExpectedTat);
}

  getTotalInbetweenTat(data: any) {
    let sum = 0;
    this.totalInbetweenExpected = data[0].inbetween_expected_tat;
    let t=0;
    data.forEach((e) => {
      let arr = e.inbetween_tat.split(':');
      let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
      sum = sum + tat;
      t++;
    });
    let seconds = sum/t;
    let numOfHours = (seconds / 3600).toString().split('.');
    seconds = seconds % 3600; // Remaining second after getting the hours
    let numOfMinutes = (seconds / 60).toString().split('.');
    let total =
      numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
    this.totalInbetweenTotal = total;
    console.log('this.totalInbetweenTotal : ', this.totalInbetweenTotal );
  }
  getTotalInbetweeneExpectedTat(data: any) {
    let sum = 0;
    let t=0;
    data.forEach((e) => {
      let arr = e.inbetween_expected_tat.split(':');
      let tat = Number(arr[0]) * 3600 + Number(arr[1]) * 60;
      sum = sum + tat;
      t++;
    });
    let seconds = sum/t;
    let numOfHours = (seconds / 3600).toString().split('.');
    seconds = seconds % 3600; // Remaining second after getting the hours
    let numOfMinutes = (seconds / 60).toString().split('.');
    let total =
      numOfHours[0].padStart(2, '0') + ':' + numOfMinutes[0].padStart(2, '0');
    this.totalInbetweenExpected = total;
    console.log('this.totalInbetweenExpected: ', this.totalInbetweenExpected);
  }

  getDateWiseProcessTat() {
    this.dateLabels = [];
    this.dateWiseTotalTat = [];
    //Labels for processWise Total TAT
    this.responseData.forEach((element) => {
      this.dateLabels.push(element['date']);
    });

    //Data for processWise Total TAT
    this.dateWiseTotalTat.push({
      data: [],
      label: 'All Processes',
    });

    this.dayWiseTatList.forEach((element) => {
      let abc = element.split(':');
      let sec = parseInt(abc[0]) * 3600 + parseInt(abc[1]) * 60;
      this.dateWiseTotalTat[0].data?.push(sec);
    });
    console.log('this.dateWiseTotalTat: ', this.dateWiseTotalTat);
  }

  getProcessWiseAverageTatGraph() {
    this.processAverageTat = [];
    this.processLabels = [];
    this.processAverageTatData = [];

    //Labels for processwise average tat graph
    this.headers.forEach((element) => {
      this.processLabels.push(element.process_name);
    });

    //Data for processwise average tat graph
    this.totalProcessWiseTatList.forEach((element) => {
      let arr = element.split(':');
      let sec = arr[0] * 3600 + '.' + arr[1] * 60;
      this.processAverageTat.push(parseFloat(sec));
    });
    this.processAverageTat.forEach((element) => {
      this.processAverageTatData.push(element / this.dayWiseTatList.length);
    });
  }

  getDateWisePerProcessTatGraphData() {
    this.dateWisePerProcessChartData = [];
    this.dateWiseProcessLabels = [];
    let backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(201, 203, 207, 0.2)'
    ];
    let borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)'
    ];
    for (let i = 0; i < this.responseData[0].data.length; i++) {
      this.dateWisePerProcessChartData.push({
        data: [],
        label: '',
        borderWidth: 1,
      });
    }
    this.responseData.map((element: any, index: number) => {
      element.data.map((values: any, i: number) => {
        let abc = values.tat.split(':');
        let sec = parseInt(abc[0]) * 3600 + parseInt(abc[1]) * 60;
        this.dateWisePerProcessChartData[i].data?.push(sec);
      });
      this.dateWiseProcessLabels.push(element.date);
    });

    this.responseData[0].data.map((label: any, index: number) => {
      this.dateWisePerProcessChartData[index].label = label.process_name;
    });
  }

  getDateWiseStageTat() {
    this.dateLabels = [];
    this.dateWiseStageTotalTat = [];
    //Labels for processWise Total TAT
    this.responseData.forEach((element) => {
      this.dateLabels.push(element['date']);
    });

    //Data for processWise Total TAT
    this.dateWiseStageTotalTat.push({
      data: [],
      label: 'All Stages',
    });

    this.dayWiseTatList.forEach((element) => {
      let abc = element.split(':');
      let sec = parseInt(abc[0]) * 3600 + parseInt(abc[1]) * 60;
      this.dateWiseStageTotalTat[0].data?.push(sec);
    });
  }

  getStageWiseAverageTatGraph() {
    this.stageAverageTat = [];
    this.stageLabels = [];
    this.stageAverageTatData = [];

    //Labels for stagewise average tat graph
    this.headers.forEach((element) => {
      this.stageLabels.push(element.stage_name);
    });
    //Data for stagewise average tat graph
    this.totalProcessWiseTatList.forEach((element) => {
      let arr = element.split(':');
      let sec = arr[0] * 3600 + '.' + arr[1] * 60;
      this.stageAverageTat.push(parseFloat(sec));
    });
    this.stageAverageTat.forEach((element) => {
      this.stageAverageTatData.push(element / this.dayWiseTatList.length);
    });
  }

  getDateWisePerStageTatGraphData() {
    this.dateWisePerStageChartData = [];
    this.dateWiseStageLabels = [];
    for (let i = 0; i < this.responseData[0].data.length; i++) {
      this.dateWisePerStageChartData.push({
        data: [],
        label: '',
      });
    }
    this.responseData.map((element: any, index: number) => {
      element.data.map((values: any, i: number) => {
        let abc = values.tat.split(':');
        let sec = parseInt(abc[0]) * 3600 + parseInt(abc[1]) * 60;
        this.dateWisePerStageChartData[i].data?.push(sec);
      });
      this.dateWiseStageLabels.push(element.date);
    });

    this.responseData[0].data.map((label: any, index: number) => {
      this.dateWisePerStageChartData[index].label = label.stage_name;
    });
  }

  getDateWiseDestinationTat() {
    this.dateLabels = [];
    this.dateWiseDestinationTotalTat = [];
    //Labels for processWise Total TAT
    this.responseData.forEach((element) => {
      this.dateLabels.push(element['date']);
    });

    //Data for processWise Total TAT
    this.dateWiseDestinationTotalTat.push({
      data: [],
      label: 'All Destinations',
    });

    this.dayWiseTatList.forEach((element) => {
      let abc = element.split(':');
      let sec = parseInt(abc[0]) * 3600 + parseInt(abc[1]) * 60;
      this.dateWiseDestinationTotalTat[0].data?.push(sec);
    });
  }

  getDestinationWiseAverageTatGraph() {
    this.destinationAverageTat = [];
    this.destinationLabels = [];
    this.destinationAverageTatData = [];

    //Labels for DestinationWise average tat graph
    this.headers.forEach((element) => {
      this.destinationLabels.push(element.destination_name);
    });

    //Data for DestinationWise average tat graph
    this.totalProcessWiseTatList.forEach((element) => {
      let arr = element.split(':');
      let sec = arr[0] + '.' + arr[1];
      this.destinationAverageTat.push(parseFloat(sec));
    });
    this.destinationAverageTat.forEach((element) => {
      this.destinationAverageTatData.push(element / this.dayWiseTatList.length);
    });
  }

  getDateWisePerDestinationTatGraphData() {
    this.dateWisePerDestinationChartData = [];
    this.dateWiseDestinationLabels = [];
    for (let i = 0; i < this.responseData[0].data.length; i++) {
      this.dateWisePerDestinationChartData.push({
        data: [],
        label: '',
      });
    }
    this.responseData.map((element: any, index: number) => {
      element.data.map((values: any, i: number) => {
        let abc = values.tat.split(':');
        let sec = parseInt(abc[0]) * 3600 + parseInt(abc[1]) * 60;
        this.dateWisePerDestinationChartData[i].data?.push(sec);
      });
      this.dateWiseDestinationLabels.push(element.date);
    });

    this.responseData[0].data.map((label: any, index: number) => {
      this.dateWisePerDestinationChartData[index].label =
        label.destination_name;
    });
  }

  openModal(date: any, process: any) {
    console.log("inside",date,process);
    const processName = this.listOfProcess.find(dataItem => dataItem['processId'] === process?.process_id)?.processName || '';
    let activeModal = this.modalService.open(ProcessVehicleViewComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });
    activeModal.componentInstance.refData = {
      processId: process.process_id,
      stageId:process.stage_id!=null ? process.stage_id : -1,
      destinationId: process.destination_id != null ? process.destination_id : -1,
      processName:processName,
      date: this.common.dateFormatter(date, 'YYYY-MM-DD', false),
      expectedTat: process.expected_tat,
      prevStageId : process.prev_stage_id,
      showAutoCancelled:this.showAutocancelledFlag,
      showInactive: this.showInactiveProcessFlag,
      statusList: this.selectedStatuses,
      vehicleIds : this.selectedVehicleIds
    };
    console.log('date: ', date);
  }

  changeTab(value) {
    this.resetFilters();
    this.reportType = value;
    if(this.reportType == 'Tag Destination'){
      return
    }
    if(this.reportType == 'Destination Wise'){
      this.getAllStage()
    }
    this.getTatReport();
  }

  getAllVehicles() {
    this.common.loading = true;
    this.api.get('vehicle/get-all-vehicles').subscribe(
      (res: any) => {
        this.vehicleList = res.data;
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  exportAsExcel2(data: any[],fileName: string,): void {
    console.log('data excel: ', data);
    let worksheet = null;
    let value = _.cloneDeep(data);
    if (this.reportType == 'Process Wise') {
      value.map((res) => {
        const totalActualTatMinutes = Object.values(res.data).reduce((sum:number, time:string) => {
          return sum + this.timeToMinutes(time || '00:00');
        }, 0);
        const avgActualTat = this.minutesToTime(Math.floor(parseInt(totalActualTatMinutes.toString()) / Object.keys(res.data).length));
        res['Trip Date'] = res.date;
        res['Plant TAT'] = avgActualTat;
        this.responseData[0].expectedTATData.forEach(element => {
          Object.values(res.data).forEach(value => {
            res[element.process_name] = value;
          });
         });
        delete res.data;
        delete res.date;
      });
    }
    else if(this.reportType == 'Stage Wise'){
      value.map((res) => {
       const totalActualTatMinutes = Object.values(res.data).reduce((sum:number, time:string) => {
                const match = this.responseData[0].actualTATData.some(dataItem => dataItem['stage_id'] === res.data['stage_id']);
                if (match) {
                  return sum + this.timeToMinutes(time || '00:00');
                }
                return sum;
              }, 0);
              const avgActualTat = this.minutesToTime(Math.floor(parseInt(totalActualTatMinutes.toString()) / this.responseData[0].expectedTATData.length));
              const totalExpectedTatMinutes = this.responseData[0].expectedTATData.reduce((sum, stage) => sum + this.timeToMinutes(stage.expected_tat || '00:00'), 0);
              const avgExpectedTat = this.minutesToTime(Math.floor(totalExpectedTatMinutes / this.responseData[0].expectedTATData.length));
        res['Trip Date'] = res.date;
        res['Date Wise TAT'] = avgActualTat + '/' + avgExpectedTat;
        this.responseData[0].expectedTATData.forEach(element => {
          Object.values(res.data).forEach(value => {
            res[element.stage_name] = value + '/'  + element.expected_tat;
          });
         });
        delete res.data;
        delete res.date;
      });
    }
    else if(this.reportType == 'Destination Wise'){
      value.map((res) => {
        res['Trip Date'] = res.date;
        res.data.forEach((element) => {
          res[element.destination_name] = element.tat + '/'  + element.expected_tat;
        });
        delete res.data;
        delete res.date;
      });
    }
    worksheet = XLSX.utils.json_to_sheet(value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    filesaver.saveAs(blob, fileName + '.xlsx');
  }

}
function epoch_to_hh_mm_ss(epoch) {
  const hours = Math.floor(epoch / (60 * 60));
  const min = String(epoch / 60).substring(0, 2);
  return hours + ':' + min;
}

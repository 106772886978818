<div class="modal-header" >
  <h4 class="modal-title" id="modal-basic-title" *ngIf="refData.showAlertData">{{ refData.alert_type_name }}</h4>
  <h4 class="modal-title" id="modal-basic-title" *ngIf="!refData.showAlertData">{{refData.alert_name}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="background-color: #f3f3f3b5;" *ngIf="!refData.showAlertData">
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive pt-2">
        <table class="table-responsive-alert">
          <tr class="table-alert">
            <th>{{ "serial_no" | translate }}</th>
            <th>{{ "alert_title" | translate }}</th>
            <th>{{ "entity" | translate }}</th>
            <th>{{ "alert_condition" | translate }}</th>
            <th>{{ "created_at" | translate }}</th>
            <th>{{ "reference_title" | translate}}</th>
            <th>{{ "action" | translate}}</th>
          </tr>
          <tbody class="table-alert-list">
            <tr *ngFor="let item of refData.alert_data; index as i">
              <td>{{i+1}}</td>
              <td>{{ item.alert_title }}</td>
              <td>{{ item.entity }}</td>
              <td>{{ item.alert_condition }}</td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.ref_title}}</td>
              <td>
                <img *ngIf="item.entity == 'Stage' || item.entity == 'Process' || item.entity == 'Destination'"
                  class="icon img-icon" src="assets/images/trip-details(3).png" alt=""
                  (click)="viewPlantLiveView(item.process_name)">
                <img *ngIf="item.entity == 'Vehicle' || item.entity == 'Transaction'" class="icon img-icon"
                  src="assets/images/trip-tracking(3).png" alt="" (click)="tripTrackingModal(item)">
                  <app-trip-comment-trail *ngIf="item.entity == 'Vehicle' || item.entity == 'Transaction'" [tripId]="item.ref_id"></app-trip-comment-trail>
                <img
                  *ngIf="item.entity == 'Vehicle' || item.entity == 'Action' || item.entity == 'Driver' || item.entity == 'Transaction'"
                  class="icon img-icon" src="assets/images/trip-details(3).png" alt="" (click)="tripModal(item)">
                <img *ngIf="item.entity == 'Audit'" class="icon img-icon" src="assets/images/trip-details(3).png" alt=""
                  (click)="viewDetails(item)">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="modal-body modal-div" *ngIf="refData.showAlertData">
    <div class="table-responsive pt-2 px-1">
      <div class="main-div-class">
        <div style="text-align: center;font-size: large;font-weight: 600;padding:5px">
          Alert Remark : {{refData.alert_remark}}
          <button type="button" class="close" aria-label="Close" (click)="closeModal(false)" *ngIf="!(refData.zone || refData.asset)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <table class="table-responsive-alert">
          <tr class="table-alert" style="border:1px solid #7a7a7a !important">
            <ng-container *ngFor="let value of refData.alert_data | keyvalue">
              <th *ngIf="isArray(value.value)">{{value.key == 'response' ? 'Value' : value.key}}</th>
            </ng-container>
            <th>Alert Conditon</th>
            <th>Alert Type</th>
          </tr>
          <tbody class="table-alert-list">
            <tr style="border:1px solid #7a7a7a !important">
              <ng-container *ngFor="let value of refData.alert_data | keyvalue">
                <td *ngIf="isArray(value.value)">{{value.value}}</td>
              </ng-container>
              <td *ngIf="refData.zone"><span class="pill"
                [ngClass]="{'bg-indigo-100 text-indigo-800':(refData.alert_condition=='User Productivity'),'bg-pink-100 text-pink-800':(refData.alert_condition=='Zone Over Crowded')}">
                {{refData.alert_condition}}</span>
                </td>
                <td *ngIf="refData.asset"><span class="pill"
                  [ngClass]="{'bg-blue-100 text-blue-800':(refData.alert_condition=='CheckIn Due'),'bg-pink-100 text-pink-800':(refData.alert_condition=='Warranty Expiry'),'bg-indigo-100 text-indigo-800':(refData.alert_condition=='Insurance Expiry'),'bg-yellow-100 text-yellow-800':(refData.alert_condition=='Asset Maintenance Overdue'),'bg-teal-100 text-teal-800':(refData.alert_condition=='Asset Depreciation'),'bg-cyan-100 text-cyan-800':(refData.alert_condition=='CheckOut Due'),'bg-orange-100 text-orange-800':(refData.alert_condition=='Status')}">
                  {{refData.alert_condition=='Status'?('Status changed to '+ (refData.alert.new_alert_data?.data?.Status??'')):refData.alert_condition}}</span>
                  </td>
              <td>
                <button [ngClass]="refData?.type_name" [style.background]="refData?.alert?.type_color" style="border: none;
                color: white;
                font-size: 10px;
                padding: 5px 10px;
                border-radius: 14px;
                font-weight: 600;">{{refData?.type_name}}</button>
                </td>
            </tr>
          </tbody>
        </table>
        <div style="display: flex;overflow-y: auto;background-color: white;">
          <div class="condtion-card-modal" *ngFor="let element of refDataChild">
            <ng-container *ngFor=" let item of element | keyvalue">
            <div class="type-row-div-modal"  *ngIf="checkObject(item.value,item.key)">
                <span class="span-left">{{ item.key }}</span>
                <span class="span-right">: {{item.value}}</span>
            </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
</div>

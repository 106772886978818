import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';

import { AddFormFieldModalComponent } from '../../modals/add-form-field-modal/add-form-field-modal.component';
import { RoleService } from 'src/app/@core/services/role.service';
import _ from 'lodash';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { UserService } from 'src/app/@core/services/user.service';
import { companyData, plantsData } from 'src/app/dataType/formInterface';

@Component({
  selector: 'app-form-field-mapping',
  templateUrl: './form-field-mapping.component.html',
  styleUrls: ['./form-field-mapping.component.scss'],
})
export class FormFieldMappingComponent implements OnInit {
  formTypesData: any;
  customFormsData: any;

  customformFieldData: any;
  formTypeName: string = null;
  customFormName: string;
  customFormId: number = null;
  lastRankingIndex: number = null;
  allSelectedItems: any[] = [];
  allItems: any[] = [];
  customformData: any[] = [];
  tempFields: any[] = [];
  removedFieldsMappingId: number[] = [];
  mappedFields = {
    FIXED: [],
    REMOVABLE: [],
  };
  companyId: number = null;
  companyName: string = null;
  plantId: number = null;
  plantName: string = null;
  companyData: companyData[] = [];
  plantData: plantsData[] = [];
  searchText: string = '';
  isDisabled:boolean = true
  constructor(
    public userService: UserService,
    public common: CommonService,
    public api: ApiService,
    public modalService: NgbModal,
    public role: RoleService,
    public alert: AlertService,
    public breadcrumbService: BreadcrumbService,
    public plantService: PlantService
  ) {}

  ngOnInit(): void {
    this.getAllFormTypes();
    // this.getAllMasterFields();
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'form', url: null },
      {
        name: 'Form Field Mapping',
        link: null,
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    }
    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlants(this.userService._loggedInUser.companyId);
    }
    this.getAllMasterFields();
  }


getAllMasterFields() {
  let body = {
    isActive:true,
    fieldEntity:"CUSTOM FORM"
  }
  this.common.loading = true;
  this.api.post('fields/allMasterFields',body).subscribe(
    (res: any) => {
      this.common.loading = false;
      this.allSelectedItems = res.data;
      console.log("cfghj",this.allSelectedItems, this.customformFieldData)
      if(this.customFormId!=null){
        this.allSelectedItems = this.allSelectedItems.filter(
          (field) =>
            !this.customformFieldData.some(
              (setting) => setting.field_id == field.master_field_id
            )
        );

      }
      this.tempFields = this.allSelectedItems;
      console.log(this.allSelectedItems.length,"Helloooooo")
    },
    (err: any) => {
      console.error('Error: ', err);
      this.common.loading = false;
    }
  );
}
  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlants(event.companyId);
    this.plantId = null;
    this.plantName = null;
    if (this.formTypeName != null) {
      this.getAllCustomForms();
    }
  }
  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    if (this.formTypeName != null) {
      this.getAllCustomForms();
    }
  }
  selectedFormtype(selectedFormType: any) {
    this.formTypeName = selectedFormType.form_type_name;
    this.customFormName = null;
    this.getAllCustomForms();
  }
  selectedCustomForm(selectedCustomForm: any) {
    this.customFormName = selectedCustomForm.form_title;
    console.log(selectedCustomForm);
    this.customFormId = selectedCustomForm.id;
    this.isDisabled = false
    this.getLastRankingIndex();
    this.getFormFields();
  }
  getLastRankingIndex() {
    this.common.loading = true;
    let params = {
      formId: this.customFormId,
    };
    this.api
      .get('form/get-last-ranking-index-by-custom-form-id', params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          console.log(res.data[0]);
          this.lastRankingIndex = res.data[0];
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlants(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }
  addEditField(editData: any) {
    console.log('editData: ', editData);
    const activeModal = this.modalService.open(AddFormFieldModalComponent, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
    });
    // console.log("plantId companyId",this.plantId, this.companyId);
    activeModal.componentInstance.refData = {
      // field: editData != null ? this.editFieldData : null,
      // plantId: editData != null ? this.editFieldData.editedPlantId : this.plantId,
      // companyId: editData != null ? this.editFieldData.editedCompanyId : this.companyId
      userid: this.role.user_role_id,
      plantId: this.plantId,
      companyId: this.companyId,
      formId: this.customFormId,
      lastRankingIndex: this.lastRankingIndex,
    };
    activeModal.closed.subscribe((res: any) => {
      // this.getCatagoryFieldmapping();
      this.getFormFields();
      this.getAllMasterFields();
    });
  }
  getAllFormTypes() {
    let params ={
      isActive:true
    }
    this.common.loading = true;
    this.api.get('form/get-all-form-types',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.formTypesData = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  getAllCustomForms() {
    let params = {
      formType: this.formTypeName,
      companyId: this.companyId,
      plantId: this.plantId,
    };
    console.log('In CustomForms', params);
    this.common.loading = true;
    this.api.get('form/get-custom-forms', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.customFormsData = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  getFormFields() {
    let params = {
      formId: this.customFormId,
    };
    this.common.loading = true;
    this.api.get('customForm/get-form-fields', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.customformFieldData = res.data[0].fields;
        this.groupingFormFieldsByFixedandRemovable(res.data[0].fields);
        this.getAllMasterFields();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  groupingFormFieldsByFixedandRemovable(formFields: any) {
    this.mappedFields = {
      FIXED: [],
      REMOVABLE: [],
    };
    formFields.forEach((field) => {
      if (field.type == 'FIXED') {
        this.mappedFields.FIXED.push(field);
      } else {
        this.mappedFields.REMOVABLE.push(field);
      }
    });
    this.allItems = this.mappedFields.REMOVABLE;
  }

  drop(event: CdkDragDrop<string[]>, id: string, from: string = '') {
    const draggedItem = event.item.data; // Access the dragged item

    //Storing Removed Mapping Ids if Exist
    if (this.removedFieldsMappingId.indexOf(draggedItem.form_mapping_id) != -1) this.removedFieldsMappingId.splice(this.removedFieldsMappingId.indexOf(draggedItem.form_mapping_id),1);

    //Find Index based on single list
    let currIndex =
      event.currentIndex * 2 + (event.container.id == 'left' ? 0 : 1);
    let preVindex =
      event.previousIndex * 2 + (event.previousContainer.id == 'left' ? 0 : 1);

    //Removing Element from previous container
    if (event.previousContainer.id == 'outer_list') {
      this.tempFields.splice(this.tempFields.indexOf(draggedItem), 1);
      this.searchFieldName();
    } else {
      this.allItems.splice(preVindex, 1);
    }

    //Adding Element to container
    this.allItems.splice(currIndex, 0, draggedItem);
  }

  removeFields(item: any, index: number) {
    let value = this.allItems[index];
    this.removedFieldsMappingId.push(item.form_mapping_id);
    this.tempFields.push(value);
    this.searchFieldName();
    this.allItems.splice(index, 1);
  }

  searchFieldName(event: any = '', eventTrigger: boolean = false) {
    this.searchText = eventTrigger
      ? event.target.value.trim()
      : this.searchText;
    if (this.searchText != '') {
      this.allSelectedItems = this.tempFields.filter((field) =>
        field.field_name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    } else {
      this.allSelectedItems = this.tempFields;
    }
  }

  changeStatus(item: any, key: string) {
    item[key] = !item[key];
  }

  changeShowStatus(item: any, ind: number) {
    let i = item.savegroups.indexOf(ind);
    if (i == -1) {
      item.savegroups.push(ind);
    } else {
      item.savegroups = item.savegroups.filter((element) => element !== ind);
    }
  }

  changeUniqueStatus(item: any, ind: number) {
    let i = item.uniqueGroups.indexOf(ind);
    if (i == -1) {
      item.uniqueGroups.push(ind);
    } else {
      item.uniqueGroups = item.uniqueGroups.filter(
        (element) => element !== ind
      );
    }
  }

  resetData() {
    this.mappedFields = {
      FIXED: [],
      REMOVABLE: [],
    };
    this.allItems = [];
    this.tempFields = [];
    this.customFormId = null;
    this.customFormName = null;
    this.removedFieldsMappingId = [];
    this.formTypeName = null;
    this.companyName = null;
    this.plantName = null;
    this.allSelectedItems = [];
    // this.getAllMasterFields();
  }

  saveAPI() {

    if(this.customFormId == null) return this.alert.warn("Please Select Form First !!");

    let params = {
      formFieldMappings: this.allItems.map((item, index) => {
        return {
          fieldRankingIndex: this.mappedFields.FIXED.length + index + 1,
          type: 'REMOVABLE',
          isMandatory: item.mandatory,
          isUnique: item.unique,
          fieldId: item.master_field_id ?? item.field_id,
          formId: this.customFormId,
        };
      }),
      removeMappingIds: this.removedFieldsMappingId,
      companyId: null,
      plantId: null,
      formId: this.customFormId,
    };
    this.api.post('form/save-multi-form-field-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alert.success(res.message);
        } else {
          this.alert.info(res.message);
        }
        this.common.loading = false;
        this.resetData();
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  resetCompanyPlant() {
    this.plantId = null;
    this.plantName = null;
    this.plantData = [];
    this.companyId = null;
    this.companyName = null;
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import _ from 'lodash';
import { dA } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-slot-management',
  templateUrl: './slot-management.component.html',
  styleUrls: ['./slot-management.component.scss'],
})
export class SlotManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.tableSerivice.options(10, 8, 'Slot List');

  slot: any = {
    id: null,
    startTime: null,
    endTime: null,
    slotCount: null,
    destinationId: null,
    productId:null,
    vehiclePerSlot: null,
    slotData: '',
    status: 'Active',
    saveType: 'Save',
  };
  previousSlotValue: string = '';
  timeSlots: any[] = [];
  slotData: any[] = [];

  destinationList: any = [];
  destinationName: string = '';

  materialList: any = [];
  materialName:string = '';

  slotTableData: any = [];
  btn: string = 'save';
  slotId: number = -1;
  toShow: Boolean = true;

  processList: any = [];
  @Input() processId: string = '';
  @Input() processName: string = '';
  sliceIndex: number = -1;

  constructor(
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public alertService: AlertService,
    public user: UserService,
    public tableSerivice: TableService,
    public role: RoleService
  ) {
    this.getDestinationList();
    this.getProductList();
    this.getSlotData();
  }

  ngOnInit() {}

  selectedDestination(data: any) {
    this.slot.destinationId = data.destinationId;
  }

  selectedMaterial(data: any) {
    this.materialName = data.product_name;
    this.slot.productId = data.id;
  }

  createSlot() {
    if (this.slot.startTime > this.slot.endTime) {
      this.alertService.warn('Start Time is Greater than End Time !!');
      return;
    }
    if (
      this.slot.slotCount != 0 &&
      this.slot.vehiclePerSlot != 0 &&
      this.slot.startTime != '' &&
      this.slot.endTime != ''
    )
      this.generateTimeSlots(
        this.slot.startTime,
        this.slot.endTime,
        this.slot.slotCount
      );
    let arr = [];
    for (var i = 0; i < this.slot.slotCount; i++) {
      arr.push({
        startTime: this.timeSlots[i].start,
        endTime: this.timeSlots[i].end,
        vehicleLimit: this.slot.vehiclePerSlot,
        slotTat: this.timeSlots[i].slotTat,
      });
    }
    this.slotData = arr;
  }

  reset() {
    this.destinationName = null;
    this.slot = {
      id: null,
      startTime: null,
      endTime: null,
      slotCount: null,
      productId:null,
      destinationId: null,
      vehiclePerSlot: null,
      slotData: '',
      status: 'Active',
      saveType: 'Save',
    };
    this.slotId = -1;
    this.timeSlots = [];
    this.slotData = [];
    this.toShow = true;
    this.btn = 'save';
    this.previousSlotValue = '';
    this.startTimeValidator = '';
    this.endTimeValidator = '';
    this.vehiclePerSlotValidator = '';
    this.slotCountValidator = '';
    this.materialName = '';
    this.destinationValidator = '';
  }

  save(deleteData: any) {
    let params = {};
    if (this.slot.saveType != 'Delete') {
      this.slot.slotData = JSON.stringify(this.slotData);
      params = this.slot;
      this.slot.status = 'Active';
    } else {
      params = deleteData;
    }
    this.common.loading = true;
    this.api.post('dock/add-slots', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertService.success(
            `Data Successfully ${this.slot.saveType}d !!`
          );
          this.getSlotData();
        }
        this.reset();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.alertService.error(err.message);
      }
    );
    this.common.loading = false;
  }

  edit(data: any) {
    this.slotData = JSON.parse(data.slotData);
    this.slot = _.cloneDeep(data);
    this.slot.slotData = this.slotData;
    this.destinationName = data.destinationName;
    this.materialName = data.product_name;
    this.slot.saveType = 'Update';
    this.btn = 'Update';
  }

  delete(data: any) {
    data.saveType = 'Delete';
    this.slot.saveType = 'Delete';
    this.save(data);
  }

  view(data: any) {
    this.toShow = false;
    this.edit(data);
  }

  getDestinationList() {
    this.api.get('dock/get-dock-destination').subscribe(
      (res: any) => {
        if (res.status) {
          this.destinationList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getProductList() {
    let params = {
      allProducts:false
    }
    this.api.get('product/get-product-list',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.materialList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getSlotData() {
    this.api.get('dock/get-slot-data').subscribe(
      (res: any) => {
        if (res.status) {
          this.slotTableData = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  generateTimeSlots(startTime: string, endTime: string, numberOfSlots: number) {
    if (startTime != null && endTime != null && numberOfSlots != null) {
      const slots: { start: string; end: string; slotTat: string }[] = [];
      const totalTimes = this.totalMinute(startTime, endTime);
      const slotDuration = totalTimes[2] / numberOfSlots;
      const slotTat = this.minutesToHours(
        slotDuration / this.slot.vehiclePerSlot
      );

      let currentHour = totalTimes[0];
      let currentMinute = totalTimes[1];

      for (let i = 0; i < numberOfSlots; i++) {
        const slotStartHour = Math.floor(currentHour);
        const slotStartMinute = Math.floor(currentMinute);
        const slotStartTime = `${slotStartHour
          .toString()
          .padStart(2, '0')}:${slotStartMinute.toString().padStart(2, '0')}`;

        currentMinute += slotDuration;

        if (currentMinute >= 60) {
          currentHour += Math.floor(currentMinute / 60);
          currentMinute %= 60;
        }

        const slotEndHour = Math.floor(currentHour);
        const slotEndMinute = Math.floor(currentMinute);
        const slotEndTime = `${slotEndHour
          .toString()
          .padStart(2, '0')}:${slotEndMinute.toString().padStart(2, '0')}`;

        slots.push({
          start: slotStartTime,
          end: slotEndTime,
          slotTat: slotTat,
        });
      }

      const lastSlotIndex = slots.length - 1;
      slots[lastSlotIndex].end = endTime;

      this.timeSlots = slots;
    }
  }

  minutesToHours(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(minutes % 60);
    const remainingSeconds = Math.floor(minutes * 60 - remainingMinutes * 60);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  changeSlotTime(event: any, type: string, index: number) {
    if (
      (event.target.value < this.slotData[index][type] ||
        event.target.value > this.slotData[index].endTime) &&
      type == 'startTime'
    ) {
      this.slotData[index][type] =
        index == 0 ? this.slot.startTime : this.slotData[index + 1].endTime;
      this.alertService.warn(
        `The Slot Start Time should not differ from Initial Start Time`
      );
    } else if (
      (event.target.value > this.slotData[index][type] ||
        event.target.value < this.slotData[index].startTime) &&
      type == 'endTime'
    ) {
      this.slotData[index][type] =
        index == this.slotData.length - 1
          ? this.slot.endTime
          : this.slotData[index - 1].startTime;
      this.alertService.warn(
        `The Slot End Time should not differ from Initial End Time`
      );
    } else {
      this.slotData[index][type] = event.target.value;
      if (index != 0 && type == 'startTime') {
        this.slotData[index - 1].endTime = event.target.value;
      } else if (index != this.slotData.length - 1) {
        this.slotData[index + 1].startTime = event.target.value;
      }
    }
  }

  slotChecker(event: any, type: string, index: number, nested: Boolean) {
    let flag = false;
    switch (type) {
      case 'startTime':
        flag =
          event.target.value < this.slot.startTime
            ? true
            : event.target.value >= this.slotData[index]['endTime']
            ? true
            : false;
        this.slotMapper(flag, index, event, type, nested);
        break;
      case 'endTime':
        flag =
          event.target.value > this.slot.endTime
            ? true
            : event.target.value <= this.slotData[index]['startTime']
            ? true
            : false;
        this.slotMapper(flag, index, event, type, nested);
        break;
    }
  }

  slotMapper(
    flag: boolean,
    index: number,
    event: any,
    type: string,
    nested: Boolean
  ) {
    if (flag) {
      if (
        (index == 0 && type == 'startTime') ||
        (index == this.slotData.length - 1 && 'endTime')
      ) {
        this.slotData[index][type] = this.slot[type];
      } else {
        this.slotData[index][type] =
          type == 'startTime'
            ? nested
              ? this.previousSlotValue
              : this.slotData[index - 1]['endTime']
            : nested
            ? this.previousSlotValue
            : this.slotData[index + 1]['startTime'];
      }
      this.alertService.warn(
        `The Slot Start or End Time should not differ from Initial Start & End Time`
      );
    } else {
      this.slotData[index][type] = event.target.value;
      let tatTime =
        this.totalMinute(
          this.slotData[index].startTime,
          this.slotData[index].endTime
        )[2] / this.slot.vehiclePerSlot;
      if (index != 0 && type == 'startTime' && !nested) {
        this.previousSlotValue = _.cloneDeep(this.slotData[index - 1].endTime);
        this.slotData[index - 1].endTime = event.target.value;
        this.slotChecker(event, 'endTime', index - 1, true);
        this.slotData[index][type] = this.slotData[index - 1].endTime;
      } else if (index != this.slotData.length - 1 && !nested) {
        this.previousSlotValue = _.cloneDeep(
          this.slotData[index + 1].startTime
        );
        this.slotData[index + 1].startTime = event.target.value;
        this.slotChecker(event, 'startTime', index + 1, true);
        this.slotData[index][type] = this.slotData[index + 1].startTime;
      }
      tatTime =
        this.totalMinute(
          this.slotData[index].startTime,
          this.slotData[index].endTime
        )[2] / this.slot.vehiclePerSlot;
      this.slotData[index].slotTat = this.minutesToHours(tatTime);
    }
  }

  totalMinute(startTime: string, endTime: string) {
    if (startTime != null && endTime != null) {
      const startParts = startTime.split(':');
      const endParts = endTime.split(':');

      const startHour = parseInt(startParts[0]);
      const startMinute = parseInt(startParts[1]);

      const endHour = parseInt(endParts[0]);
      const endMinute = parseInt(endParts[1]);

      const totalMinutes =
        (endHour - startHour) * 60 + (endMinute - startMinute);
      return [startHour, startMinute, totalMinutes];
    }
  }

  addDaysToDate(originalDate: string, numberOfDays: number): string {
    const dateParts = originalDate.split('-');
    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Month in JavaScript's Date object is zero-based
    const year = parseInt(dateParts[2]);

    const date = new Date(year, month, day);
    date.setDate(date.getDate() + numberOfDays);

    const updatedDay = date.getDate().toString().padStart(2, '0');
    const updatedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const updatedYear = date.getFullYear();

    return `${updatedDay}-${updatedMonth}-${updatedYear}`;
  }

  startTimeValidator: any = '';
  endTimeValidator: any = '';
  vehiclePerSlotValidator: any = '';
  slotCountValidator: any = '';
  destinationValidator: any = '';
  materialNameValidator:any = '';

  formValidator(data: any) {
    console.log(data,"Hello")
    this.startTimeValidator = this.common.nullValidator(
      this.slot.startTime,
      'Start Time is Mandatory'
    );
    this.endTimeValidator = this.common.nullValidator(
      this.slot.endTime,
      'End Time is Mandatory'
    );
    this.vehiclePerSlotValidator = this.common.nullValidator(
      this.slot.vehiclePerSlot,
      'Vehicle Per Slot is Mandatory'
    );
    this.slotCountValidator = this.common.nullValidator(
      this.slot.slotCount,
      'Number of Slot is Mandatory'
    );
    this.destinationValidator = this.common.nullValidator(
      this.slot.destinationId,
      'Destination is Mandatory'
    );
    this.materialNameValidator = this.common.nullValidator(
      this.slot.productId,
      'Product is Mandatory'
    );
    if (
      !this.startTimeValidator.error &&
      !this.endTimeValidator.error &&
      !this.vehiclePerSlotValidator.error &&
      !this.slotCountValidator.error &&
      !this.destinationValidator.error &&
      !this.materialNameValidator.error
    ) {
      if (this.slot.startTime < this.slot.endTime) {
        this.save(data);
      } else {
        this.alertService.error('The Start time should be less than End Time.');
      }
    }
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}

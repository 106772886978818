<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"shift_calendar" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="box_calender">
                <div class="left_sec">
                  <div class="process_sec" *ngIf="this.roleService.user_role == 'COMPANYADMIN'">
                    <div class="form-group col-xl-12 col-lg-12 col-sm-12 px-1" style="margin-bottom: 0px;">
                      <label style="font-size: 12px;">{{'Plant'|translate}}</label>
                      <ps-auto-suggestion (onSelected)="onSelectPlant($event)" [data]="plantList" display="plantName"
                        placeholder="Select Process" [preSelected]="{plantName:selectedPlantName}">
                      </ps-auto-suggestion>
                    </div>
                  </div>
                  <div class="process_sec">
                    <div class="form-group col-xl-12 col-lg-12 col-sm-12 px-1" style="margin-bottom: 0px;">
                      <label style="font-size: 12px;">{{'process'|translate}}</label>
                      <ps-auto-suggestion (onSelected)="setSelProcess($event)" [data]="processList" display="processName"
                        placeholder="Select Process" [preSelected]="{processName:selProcessName}">
                      </ps-auto-suggestion>
                    </div>
                  </div>

                  <div class="calender_sec">
                    <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="1" [minDate]="currDate"
                      [dayTemplate]="t" outsideDays="hidden">
                    </ngb-datepicker>

                    <ng-template #t let-date let-focused="focused">
                      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </div>

                  <div class="user_sec" *ngIf="userService.permissionMap.get('view_all_shifts_access')==true">
                    <ul>
                      <label *ngFor="let ucheck of allUsersList;let i = index" style="background-color: white;"  [for]="'field'+i">
                        <span> {{ ucheck.firstName }} {{ucheck.lastName}} </span>
                        <input type="checkbox" [id]="'field'+i" (change)="setSelectedUser($event, ucheck.userId)">
                      </label>
                    </ul>
                  </div>
                </div>

                <div class="right_sec">
                  <span class="loader" *ngIf="this.showLoader==true  && this.reportData!=[]"></span>
                  <div class="datewise_box" *ngFor="let dates of reportData | keyvalue; let j = index">
                    <div class="date_sec">
                      <p class="date">{{ dates.key| date:'dd-MM-yyyy'}}</p>
                    </div>
                    <div class="boxes" *ngIf="dates.value.length == 0">
                      <p class="empty-list-msg">0 Process Found...</p>
                    </div>
                    <div *ngFor="let process of dates.value; let i  = index;">
                      <div class="boxes">
                        <div class="box" *ngIf="process.stages.length == 0">
                          <p class="empty-list-msg">0 Stage Found...</p>
                        </div>

                        <div class="box" *ngFor="let data of process.stages; let z = index">
                          <h6>
                            <p class="stage_name_para">{{data.stage_name}} <span
                                (click)="openLg(addUser, data,dates.key,process)"><i class="fa fa-plus-circle"
                                  aria-hidden="true"
                                  *ngIf="userService.permissionMap.get('shift_assignment_access')==true"></i></span></p>
                          </h6>
                          <div class="shift_name_box" *ngIf="data['shifts'].length == 0">
                            <p class="empty-list-msg">0 Shift Found...</p>
                          </div>
                          <div class="shift_name_box" *ngFor="let shift of data['shifts']">
                            <div class="shift_box">
                              <div class="shift" style="font-weight: 700;font-size: 12px;"> {{ shift.shift_name }}
                              </div>
                              <div class="shift" style="font-size: 9px;"> ({{ shift.shift_time }}) </div>
                            </div>
                            <div class="name_box">
                              <span class="name" *ngFor="let user of shift['users']" [title]="user.user_name"
                                [ngStyle]="{'background-color': user.active ? '#8fe784' : '#fa9696'}"> <i
                                  class="fa fa-user"></i> {{ user.alias }} </span>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-template #addUser let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Shift Assignment</h6>
      <div aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-times"></i></div>
    </div>
    <div class="modal-body">

      <div class="shift_div">
        <mat-label>Select Shift</mat-label>
        <ps-auto-suggestion [data]="shiftList" display="shift_name" seperator="-" placeholder="Select Shift"
          (onSelected)="selectedShift($event)" inputId="stageName" name="stageName" [preSelected]="null"
          [preSelected]="{shift_name:shiftName}"></ps-auto-suggestion>
      </div>
      <div class="user_div">
        <mat-label>Select User</mat-label>
        <ps-auto-suggestion [data]="userList" display="name" seperator="-" placeholder="Select User"
          (onSelected)="selectedUser($event)" inputId="stageName" name="stageName" [preSelected]="null"
          isMultiSelect="true" [preSelected]="preSelectedUser"></ps-auto-suggestion>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="saveShiftData()">Assign</button>
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
    </div>
  </ng-template>

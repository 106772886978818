import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class  UserService {
  permissionMap = new Map<string, Boolean>();
  _token: any;
  _details: any;
  _name: any;
  _loginTime: any;
  _permission :any;
  _sessionId: any;
  _companyId: any;
  _plantId: any;
  _externalPlantId:any;
  _isLoggined: any;
  _isStaffLoggined:boolean = false;
  _externalCompanyId:any;
  _tokenExpiryTime: any;
  _userType: any;
  _walkinMode:any;
  menuList: any = [];
  _loggedInUser: any = {
    id: null,
    companyId: null,
    plantId: null,
    username: null
  };
  _plantType : string | null;
  constructor(private http: HttpClient) {
    this._token = localStorage.getItem('USER_TOKEN') != 'null' && localStorage.getItem('USER_TOKEN') != null ?
    localStorage.getItem('USER_TOKEN') : ( (localStorage.getItem('VISITOR_TOKEN') != 'null' && localStorage.getItem('VISITOR_TOKEN') != null)
    ? localStorage.getItem('VISITOR_TOKEN') : null);
    this._details = JSON.parse(localStorage.getItem('USER_DETAILS')) || null;
    this._name = localStorage.getItem('userName') || null;
    this._loginTime = localStorage.getItem('loginTime') || null;
    this._sessionId = localStorage.getItem('JSESSIONID') || null;
    this._companyId = this._details?.companyId;
    this._plantId = this._details?.plantId;
    this._tokenExpiryTime = localStorage.getItem('expiryTime') || null;
    this._userType = this._details?.userType;
    this._plantType = this._details?.plantType;
    // console.log((localStorage.getItem('VISITOR_TOKEN') != 'null' || localStorage.getItem('VISITOR_TOKEN') != null) ? true : false,localStorage.getItem('VISITOR_TOKEN'))
    this._isStaffLoggined = (localStorage.getItem('VISITOR_TOKEN') != 'null' && localStorage.getItem('VISITOR_TOKEN') != null) ? true : false;
    // this._permission = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('permission')))) || [];
    // this._permission = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('permission')))) || [];


    let user = this._details;
    // console.log('user: ', user);
    if (user) {
      this._loggedInUser = {
        id: user.id,
        companyId: Number(user.companyId),
        plantId: Number(user.plantId),
        username: user.username,
        externalCompanyId: Number(user.externalCompanyId),
        externalPlantId: Number(user.externalPlantId),
      }
    }

    this.setPermission();
        //setting walkin mode
        this._walkinMode =  localStorage.getItem('walkin_mode')==='true'
  }

  setUserDetail(){
    this._token = localStorage.getItem('USER_TOKEN') != 'null' && localStorage.getItem('USER_TOKEN') != null ?
    localStorage.getItem('USER_TOKEN') : ( (localStorage.getItem('VISITOR_TOKEN') != 'null' && localStorage.getItem('VISITOR_TOKEN') != null)
    ? localStorage.getItem('VISITOR_TOKEN') : null);
    this._details = JSON.parse(localStorage.getItem('USER_DETAILS')) || null;
    this._name = localStorage.getItem('userName') || null;
    this._loginTime = localStorage.getItem('loginTime') || null;
    this._sessionId = localStorage.getItem('JSESSIONID') || null;
    this._companyId = this._details?.companyId;
    this._plantId = this._details?.plantId;
    this._tokenExpiryTime = localStorage.getItem('expiryTime') || null;
    this._userType = this._details?.userType;
    this._plantType = this._details.plantType;
    this._isStaffLoggined = (localStorage.getItem('VISITOR_TOKEN') != 'null' && localStorage.getItem('VISITOR_TOKEN') != null) ? true : false;

    this._details = JSON.parse(localStorage.getItem('USER_DETAILS')) ||null;
    this._walkinMode =  (localStorage.getItem('walkin_mode') != 'null' && localStorage.getItem('walkin_mode') != null) ? true : false;

    let user = this._details;
    if (user) {
      this._loggedInUser = {
        externalCompanyId: Number(user.externalCompanyId),
        externalPlantId: Number(user.externalPlantId),
        id: user.id,
        companyId: user.companyId == null ?  Number(user.externalCompanyId) :  Number(user.companyId),
        plantId: user.plantId == null ?  Number(user.externalPlantId) :  Number(user.plantId),
        username: user.username
      }
    }
    //setting walkin mode
    //  this._walkinMode =  localStorage.getItem('walkin_mode');

  }

  openGeofenceModal(){

  }

  setPermission() {
    this._permission = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('permission')))) || [];
    // console.log("this._permission", this._permission[0]);
    // this.permissionMap = new Map<string, Boolean>();
    if(this._permission.length > 0){
      // console.log(new Map(Object.entries(this._permission[0])),"hello");

      // Object.entries(this._permission[0]).forEach((entry, value)=>{
      //   this.permissionMap.set(entry[0],entry[1] == true);
      // })
      this.permissionMap = new Map(Object.entries(this._permission[0]));
    }
    // this.permissionMap = this._permission[0];
    // for (let index = 0; index < this._permission.length; index++) {
    //   let permission = this._permission[index];
    //   let key = Object.keys(permission)[0];
    //   let value = permission[key];
    //   this.permissionMap.set(key, value);
    // }
    //  console.log("permissionMap",this.permissionMap.get("home_access"),this.permissionMap);
  }

}

<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1,false)">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">{{ title | customTranslate}}</h5>
</div>

<div class="modal-body">
  <div class="title">{{"field_types"|customTranslate}}</div>

  <div class="field-type">
    <button *ngFor="let field of fieldTypes" (click)="selectFieldType(field.fieldType)"
      [ngClass]="{'selected': field.fieldType === customField.type}">
      <i [class]="'fas fa-'+field.fieldIcon" [ngClass]="field.fieldType"></i>
      <span>{{ field.fieldType|customTranslate }}</span>
    </button>
  </div>

  <div class="row">
    <div class="form-group col-3">
      <label for="my-input" class="required">{{'Field Name'|customTranslate}} </label>
      <input type="text" class="form-control" name="name" required [placeholder]="'Enter Master Field Name'|customTranslate"
        [(ngModel)]="customField.name" #name="ngModel" autocomplete="off">
      <div class="error" *ngIf="fieldNameValidator && fieldNameValidator.error">
        <span>{{fieldNameValidator.msg}} </span>
      </div>
    </div>

    <!-- <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
      <div class="form-group">
        <label for="formFieldType" class="required">{{("Form Field Type" | customTranslate)}}</label>
        <select id="actionStatus" class="form-control" name="formFieldType"
          [(ngModel)]="formFieldType">
          <option value="FIXED">{{("FIXED" | customTranslate)}}</option>
          <option value="REMOVABLE">{{("REMOVABLE" | customTranslate)}}</option>
        </select>
      </div>
    </div> -->

    <div class="form-group col-3">
      <label for="my-input">{{"Select Database Field"| customTranslate}}</label>
      <ps-auto-suggestion (onSelected)="selectedDBField($event)" [data]='databaseFields' display="databaseFieldName"
        [preSelected]="{databaseFieldName:databaseFieldName}" [placeholder]="'Select Database Field'|customTranslate">
      </ps-auto-suggestion>
      <div class="error" *ngIf="databaseFieldValidator && databaseFieldValidator.error">
        <span>{{databaseFieldValidator.msg}} </span>
      </div>
    </div>

    <!-- //use of ng class for disabled-div class because no direct negation; -->
    <div class="form-group col-3"
      *ngIf="customField.type =='SELECTLISTSINGLE' || customField.type == 'SELECTLISTMULTIPLE' || customField.type == 'RADIOBUTTON' || customField.type == 'CHECKBOX'">
      <label for="my-input">{{"Choices"| customTranslate}}</label>
      <ps-auto-suggestion (onSelected)="createdField($event)" [data]='[]' display="name"
        [preSelected]="customField.values" [placeholder]="'Press enter to add choices'|customTranslate" isMultiSelect="true"
        [onInputTrigger]="true">
      </ps-auto-suggestion>
      <div class="error" *ngIf="choiceValidator && choiceValidator.error">
        <span>{{choiceValidator.msg}} </span>
      </div>
    </div>

    <div class="form-group col-3">
      <label for="fieldValidation">{{'Field Validation'|customTranslate}} </label>
      <div class="boxes" style="display: flex; flex-wrap: nowrap;width: 100%;">
        <div class="pattern" style="width:25%;">
          <input type="text" class="form-control" name="prefixPattern" required [placeholder]="'Prefix'|customTranslate"
            [(ngModel)]="customField.pattern_prefix" #prefixPattern="ngModel" autocomplete="off"
            style="border-radius: 0cm;">
        </div>

        <div class="regEx" style="width:50%;">
          <input type="text" class="form-control" name="regexPattern" required
            [placeholder]="'Regular Expression'|customTranslate" [(ngModel)]="customField.regex_pattern"
            #regexPattern="ngModel" autocomplete="off" style="border-radius: 0cm;">
        </div>

        <div class="pattern" style="width:25%;">
          <input type="text" class="form-control" name="postfixPattern" required [placeholder]="'Postfix'|customTranslate"
            [(ngModel)]="customField.pattern_postfix" #postfixPattern="ngModel" autocomplete="off"
            style="border-radius: 0cm;">
        </div>
      </div>
    </div>

    <div class="form-group col-3" *ngIf="customField.type=='FIELDGROUP'">
      <label>{{"num_of_subgroups"| customTranslate}}</label>
      <input type="number" name="subgroups" class="form-control" [value]="subgroupsCount" [min]="1" id="subgroupscount"
        [max]="4" (change)="changeNoOfSubGroups($event)">
    </div>
  </div>

  <div class="row" *ngIf="customField.type=='FIELDGROUP'">
    <div *ngFor="let subgroup of subgroupCountRange;index as id" class="form-group col-3">
      <label for="my-input">{{'subgroup_name'|customTranslate}}</label>
      <input type="text" [placeholder]="'Enter SubGroup Name'|customTranslate" name="subgroupname" class="form-control"
        (change)="enterSubGroupName(id,$event)" [id]="'sub'+id" [value]="subgroupsName[0][id].name">
      <label for="my-input">{{'select_fields'|customTranslate}}</label>
      <div *ngFor="let value of subgroupsName; let i=index;" class="row">
        <ps-auto-suggestion (onSelected)="selectedField($event,i,id)" [data]="filteredMasterFields" display="field_name"
          [placeholder]="'Choose Field'|customTranslate" [inputId]="'sub'+id+i"
          [preSelected]="{field_name:this.subgroupsName[i][id].values.fieldName}" [class]="id + 1 == this.subgroupsCount ? (i == 0 || i != subgroupsName.length-1 ? 'col-10 pr-0' : 'col-8 pr-0') : 'col-12'">
        </ps-auto-suggestion>
        <div [class]="i == 0 || i != subgroupsName.length-1 ? 'col-2 pl-0 form-group' : 'col-4 pl-0 form-group d-flex justify-content-end m-0'" *ngIf="id + 1 == this.subgroupsCount">
          <span class="button remove-field ml-1" id="my-addon"
            *ngIf="(i !== 0 && id + 1 == this.subgroupsCount)||(i==0 && subgroupsName.length>1)"
            (click)="spliceRow(i,id)"> <i class="fa fa-trash remove"></i></span>
          <span class="button add-field ml-1" id="my-addon"
            *ngIf="i == subgroupsName.length-1" (click)="addSubGroupValues(id)">
            <i class="fa fa-plus add"></i></span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12" *ngIf="customField.type=='LABEL'">
      <label for="my-input">{{('field_value'|customTranslate)+' '+('label'|customTranslate)}}</label>
      <ckeditor [editor]="Editor" [(ngModel)]="detail"></ckeditor>
    </div>
    <div class="form-group col-3">
      <label for="" class="d-block"> {{'Multivalue'|customTranslate}} </label>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id='customSwitchesMultivalue'
          [(ngModel)]="customField.isMultiValue" (click)="changeStatus(customField.isMultiValue)">
        <label class="custom-control-label" for="customSwitchesMultivalue"></label>
      </div>
    </div>
  </div>

  <div class="note" *ngIf="customField.type=='FIELDGROUP'">
    <span class="star">*</span><span class="note_header">{{'note'|customTranslate}} :</span><span class="note_text">{{ 'Only 4
      Sub Groups are recommended.'|customTranslate}}</span>
  </div>
  <div class="note" *ngIf="customField.type =='SELECTLISTSINGLE' || customField.type == 'SELECTLISTMULTIPLE' || customField.type == 'RADIOBUTTON' || customField.type == 'CHECKBOX'">
    <span class="star">*</span><span class="note_header">{{'note'|customTranslate}} :</span><span class="note_text">{{ 'Press enter to add choices'|customTranslate}} </span>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-warning" (click)="resetDetail()"> {{ "reset" | customTranslate }} </button>
  <button type="button" class="btn btn-success" (click)="formValidator()">{{btn | customTranslate}}</button>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessService extends ApiService{

  saveProcess(data: any): Observable<any> {
    let apiSubUrl = "process/save_process";

    return super.post(apiSubUrl, data);
  }

  getAllProcessList(plantId?): Observable<any> { // active processes list
    let apiSubUrl = "process/get-all-process?processActive=true";
    if(plantId!=null){
      apiSubUrl=apiSubUrl+'&plantId='+plantId;
    }
    return super.get(apiSubUrl);
  }

  getAllActiveAndInactiveProcessList(plantId?): Observable<any> { // all active and inactive processes list
    let apiSubUrl = "process/get-all-process?processActive=false";
    if(plantId){
      apiSubUrl = apiSubUrl + '&plantId='+plantId;
    }
    return super.get(apiSubUrl);
  }

  getAllProcessStage(plantId:any=null): Observable<any> {
    let apiSubUrl = "process/get-process-stage";
    return super.get(apiSubUrl,{plantId:plantId});
  }

  deleteProcess(data: any): Observable<any> {
    let apiSubUrl =  "process/delete_process";
    return super.post(apiSubUrl, data);
  }
}

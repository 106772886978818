<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">

      <div class="row">
        <div class="col-md-12">
          <div class="card">


            <div class="card-body">

              <!-- main daba -->
              <div>
                <div class="phl-dabz">
                  <h1 style="color: white;">Can we help you?</h1>
                  <div class="search_box">
                    <input type="text" placeholder="Search">
                    <button>Search</button>
                  </div>
                </div>

                <div class="second-box">
                  <h1 class="article-1">What are Freepik Fonts?</h1>
                  <div class="article-2">
                    <span>
                      <p>A font is a set of characters with a common design, color or size. You can download Freepik
                        fonts from here and you can use them for your projects according to our usage rules
                        from here and according to our usage rules.
                        A font is a set of characters with a common design, color or size. You can download Freepik
                        fonts from here and you can use them for your projects according to our usage rules
                        from here and according to our usage rules.
                        A font is a set of characters with a common design, color or size. You can download Freepik
                        fonts from here and you can use them for your projects according to our usage rules
                        from here and according to our usage rules.
                      </p>
                    </span>
                    <img src="assets/images/rtaImage.jpg" alt="My Image">
                    <p>A font is a set of characters with a common design, color or size. You can download Freepik fonts
                      from here and you can use them for your projects according to our usage rules
                      from here usage rules .
                      A font is a set of characters with a common design, color or size. You can download Freepik fonts
                      from here and you can use them for your projects according to our usage rules
                      from here usage rules .
                      A font is a set of characters with a common design, color or size. You can download Freepik fonts
                      from here and you can use them for your projects according to our usage rules
                      from here usage rules .
                      A font is a set of characters with a common design, color or size. You can download Freepik fonts
                      from here and you can use them for your projects according to our usage rules
                      from here usage rules .
                      A font is a set of characters with a common design, color or size. You can download Freepik fonts
                      from here and you can use them for your projects according to our usage rules
                      from here usage rules .
                      A font is a set of characters with a common design, color or size. You can download Freepik fonts
                      from here and you can use them for your projects according to our usage rules
                      from here usage rules .
                      A font is a set of characters with a common design, color or size. You can download Freepik fonts
                      from here and you can use them for your projects according to our usage rules
                      from here usage rules .
                      A font is a set of characters with a common design, color or size. You can download Freepik fonts
                      from here and you can use them for your projects according to our usage rules
                      from here usage rules .
                      A font is a set of characters with a common design, color or size. You can download Freepik fonts
                      from here and you can use them for your projects according to our usage rules
                      from here usage rules .
                    </p>
                    <img src="assets/images/rtaImage (1).jpg" alt="My Image">
                  </div>

                  <div class="container">
                    <hr class="grey-line" />
                  </div>
                </div>

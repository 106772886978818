<div class="container-fluid pr-0 pl-0 form_appointment">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card" style="box-shadow: none">
            <div class="card-header pb-2">
              <div class="header-block">
                <h4 class="card-title">
                  {{ "Form" | translate }}
                </h4>
                <h5 class="card-sub-title">
                  {{ formDetails.formTitle }}
                </h5>
              </div>
            </div>
            <div class="card-body" style="background-color: #f1f1f1">
              <div class="form_layout" *ngIf="mappedFields.length">
                <div class="row">
                  <ng-container *ngFor="let field of mappedFields; index as i">
                    <div class="field col-12" *ngIf="isArray(field)">
                      <ng-container *ngIf="field[0].field_type !== 'FIELDGROUP'">
                        <div *ngFor="let item of field ; index as j" class="row">
                          <app-custom-field-new class="col-11" [field]="{
                            field_id : item.field_id,
                            field_name: item.field_name,
                            field_type: item.field_type,
                            field_ranking_index : item.field_ranking_index,
                            label: item.label,
                            store_value : item.store_value,
                            options: item.options,
                            database_field_name : item.database_field_name,
                            mandatory: item?.mandatory,
                            unique: item?.unique,
                            values: j==0 ? (item?.sel_value ? [item.sel_value] : []) : [] ,
                            isDisabled: j==0 ? (item?.sel_value ? true : false) : false,
                            isEditable: false,
                            regex: item?.regex,
                            pattern_prefix: item?.pattern_prefix,
                            pattern_postfix: item?.pattern_postfix,
                            extra_data : {
                              rowIndex : j
                            }
                          }"
                          [i]="i" (editTrigger)="onEdit($event);"
                          (valueChange)="onValueChange(i,0,j,0, $event)">
                          </app-custom-field-new>
                          <div class="form-group col-1" style="margin: auto">
                            <div class="plus_minus_icon">
                              <span
                                class="input-group-text remove-field"
                                id="my-addon"
                                *ngIf="j > 0"
                                (click)="removeValue(i, j)"
                              >
                                <i class="fa fa-trash remove"></i>
                              </span>
                              <span
                                class="input-group-text add-field"
                                id="my-addon"
                                *ngIf="j == field.length - 1"
                                (click)="addValue(item, i, j)"
                              >
                                <i class="fa fa-plus add"></i
                              ></span>
                              <!-- *ngIf="i == mappedFields[i].length-1" -->
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container
                        *ngIf="field[0].field_type === 'FIELDGROUP'"
                      >
                      <label [ngClass]="{'required':field?.mandatory==true}">{{field[0].field_name}} </label>
                        <div class="fieldgroup-row">
                          <div *ngFor="let item of field; let j = index" class="plus_minus_row">
                            <div *ngFor="let grpField of item.group_fields;let k = index" class="seprate_column">
                              <app-custom-field-new
                                [field]="{
                                  field_id: grpField.field_id,
                                  field_name: grpField.field_name,
                                  field_type: grpField.field_type,
                                  field_ranking_index:
                                    grpField.field_ranking_index,
                                  label: grpField.label,
                                  store_value: grpField.store_value,
                                  options: grpField.options,
                                  database_field_name:grpField.database_field_name,
                                  mandatory: item?.mandatory,
                                  unique: grpField?.unique,
                                  values: j==0 ? (grpField?.sel_value ? [grpField.sel_value] : []) : [] ,
                                  isDisabled: j==0 ? (grpField?.sel_value ? true : false) : false,
                                  isEditable: false,
                                  regex: grpField?.regex,
                                  pattern_prefix: grpField?.pattern_prefix,
                                  pattern_postfix: grpField?.pattern_postfix,
                                  extra_data: {
                                    group_id: grpField?.group_id,
                                    sub_group_index: grpField?.sub_group_index,
                                    sub_group_name: grpField?.sub_group_name,
                                    rowIndex: j
                                  }
                                }"
                                [i]="i"
                                (editTrigger)="onEdit($event)"
                                (valueChange)="
                                  onValueChange(i,k,j,item.group_fields.length,$event)">
                              </app-custom-field-new>
                            </div>
                            <div class="form-group fixed_icons">
                              <div class="plus_minus_icon">
                                <span
                                  class="input-group-text remove-field"
                                  id="my-addon"
                                  *ngIf="j > 0"
                                  (click)="removeValue(i, j)"
                                >
                                  <i class="fa fa-trash remove"></i>
                                </span>
                                <span
                                  class="input-group-text add-field"
                                  id="my-addon"
                                  *ngIf="j == field.length - 1"
                                  (click)="addValue(item, i, j)"
                                >
                                  <i class="fa fa-plus add"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>

                    <div class="field col-12" *ngIf="!isArray(field)">
                      <ng-container *ngIf="field.field_type !== 'FIELDGROUP'">
                        <app-custom-field-new
                          [field]="{
                            field_id: field.field_id,
                            field_name: field.field_name,
                            field_type: field.field_type,
                            field_ranking_index: field.field_ranking_index,
                            label: field.label,
                            store_value: field.store_value,
                            options: field.options,
                            database_field_name: field.database_field_name,
                            mandatory: field?.mandatory,
                            unique: field?.unique,
                           values: field?.sel_value ? [field.sel_value] : [],
                          isDisabled: field?.sel_value ? true : false,
                            isEditable: false,
                            regex: field?.regex,
                            pattern_prefix: field?.pattern_prefix,
                            pattern_postfix: field?.pattern_postfix
                          }"
                          [i]="i"
                          (editTrigger)="onEdit($event)"
                          (valueChange)="onValueChange(i, 0, 0, 0, $event)"
                        >
                        </app-custom-field-new>
                      </ng-container>
                      <ng-container *ngIf="field.field_type === 'FIELDGROUP'">
                        <label [ngClass]="{'required':field?.mandatory==true}">{{field.field_name}} </label>
                        <ng-container
                          *ngFor="
                            let grpField of field.group_fields;
                            let k = index
                          "
                        >
                          <app-custom-field-new
                            [field]="{
                              field_id: grpField.field_id,
                              field_name: grpField.field_name,
                              field_type: grpField.field_type,
                              field_ranking_index: grpField.field_ranking_index,
                              label: grpField.label,
                              store_value: grpField.store_value,
                              options: grpField.options,
                              database_field_name: grpField.database_field_name,
                              mandatory: field?.mandatory,
                              unique: grpField?.unique,
                              values: grpField?.sel_value ? [grpField.sel_value] : [],
                              isDisabled: grpField?.sel_value ? true : false,
                              isEditable: false,
                              regex: grpField?.regex,
                              pattern_prefix: grpField?.pattern_prefix,
                              pattern_postfix: grpField?.pattern_postfix,
                              extra_data: {
                                group_id: grpField?.group_id,
                                sub_group_index: grpField?.sub_group_index,
                                sub_group_name: grpField?.sub_group_name
                              }
                            }"
                            [i]="i"
                            (editTrigger)="onEdit(grpField)"
                            (valueChange)="
                              onValueChange(
                                i,
                                k,
                                0,
                                field.group_fields.length,
                                $event
                              )
                            "
                          >
                          </app-custom-field-new>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>

              <ng-container *ngIf="mappedFields.length == 0">
                <app-data-not-found></app-data-not-found>
              </ng-container>
            </div>

            <div class="stage-setting-footer col-12" *ngIf="mappedFields.length">
              <div class="btn-block d-flex  justify-content-end">
                <button class="btn ml-2 btn-success text-uppercase"
                  (click)="saveFormResponse()">{{'save'|translate}} </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

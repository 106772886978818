<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="tab_section">
        <div class="tab" *ngFor="let tabitem of tabData" [class.active]="reportType == tabitem.value"
          (click)="changeTab(tabitem.value)">
          <p>{{tabitem.tabname|customTranslate}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <!-- <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'trip_report'|customTranslate}}
                </h3>
              </div>
            </div> -->

            <div class="card-body">
              <div class="page_design">
                <div class="row date_div" style="margin-bottom: 15px;">
                  <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                    <label for="my-input">{{"select_company" | customTranslate}}</label>
                    <ps-auto-suggestion (onChange)="selectedCompany($event)" (onSelected)="selectedCompany($event)"
                      [data]="companyList" display="companyName" placeholder="Choose Company"
                      [preSelected]="{companyName:companyName}">
                    </ps-auto-suggestion>
                  </div>
                  <div class="col-md-3 date" *ngIf="role.user_role=='COMPANYADMIN'|| role.user_role == 'AXESTRACK'">
                    <label for="my-input" class="required">{{"select_plant" | customTranslate}}</label>
                    <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                      placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                    </ps-auto-suggestion>
                  </div>
                  <div class="col-md-3 date">
                    <label>{{'start_date:'|customTranslate}}</label>
                    <input class="form-control"
                      [type]="(tabvalue == 'graph' || this.reportType=='All Trips') ? 'datetime-local' : 'date'"
                      name="startDate" [(ngModel)]="fromDate">
                  </div>
                  <div class="col-md-3 date">
                    <label>{{'end_date:'|customTranslate}}</label>
                    <input class="form-control"
                      [type]="(tabvalue == 'graph' || this.reportType=='All Trips') ? 'datetime-local' : 'date'"
                      [min]="fromDate" name="endDate" [(ngModel)]="toDate">
                  </div>
                  <div class="col-md-1 btn-block text-right">
                    <button class="btn btn-primary" (click)="searchAction(tabvalue)">{{"search" | customTranslate}}</button>
                  </div>
                </div>
                <ng-container *ngIf="reportType == 'All Trips'; else dateWiseTable;">
                  <app-trip-report-modal [refData]="{
                    fromDate: fromDate,
                    toDate: toDate,
                    plantId: plantId
                  }"></app-trip-report-modal>
                </ng-container>
                <ng-template #dateWiseTable>
                  <div class="view_table">
                    <div class="row tab_sec mt-2">
                      <div class="col-md-12 col-12 buttons text-right">
                        <!-- <button type="button" [class.active]="tabvalue == 'table'"
                          (click)="tabvalue = 'table';"><i class="fa fa-table"></i>
                          &nbsp;{{'table_view'|customTranslate}}</button>
                        <button type="button" *ngIf="this.reportType=='Date Wise Trips'" [class.active]="tabvalue == 'graph'"
                          (click)="tabvalue = 'graph';"><i class="fa fa-chart-pie"></i>
                          &nbsp;{{'graph_view'|customTranslate}}</button> -->
                        <button type="button"
                          (click)="exportAsExcel(data,'Trip Report from '+this.fromDate+' to '+this.toDate)"
                          class="fa fa-file-excel"
                          style="margin-left: 10px;color:rgb(57 117 22);cursor: pointer;border:.8px green solid;font-size:18px"
                          *ngIf="tabvalue != 'graph'"></button>
                      </div>
                    </div>

                    <div class="table_sec" [hidden]="(tabvalue != 'table')" *ngIf="data?.length > 0" style="overflow: scroll;">
                      <app-custom-table [data]="data" [columns]="columns"  [tableType]="TABLE_TYPE"></app-custom-table>
                    </div>

                    <div class="view_graph" [hidden]="tabvalue != 'graph'">
                      <div class="row">
                        <div class="col-lg-6 col-12 mt-3 pt-0 pb-0">
                          <div style="position: relative;" id='graph-464'></div>
                        </div>
                        <div class="col-lg-6 col-12 mt-3 pt-0 pb-0">
                          <div style="position: relative;" id='graph-465'></div>
                        </div>

                      </div>
                      <div class="row" style="margin-top: -15px;">
                        <div class="col-lg-12 col-md-12">
                          <div style="position: relative; margin-top:30px;" id='graph-466'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
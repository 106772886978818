import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { MapService } from '../../@core/services/map.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { UserService } from '../../@core/services/user.service';
import { RoleService } from '../../@core/services/role.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { StageActionService } from '../stage-action/stage-action.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { TableService } from 'src/app/@core/services/table.service';
import _ from 'lodash';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StagesettingService } from '../stage-setting/stage-setting.service';
import { AssetCategoryServiceService } from '../asset-category/asset-category-service.service';

@Component({
  selector: 'app-alert-management',
  templateUrl: './alert-management.component.html',
  styleUrls: ['./alert-management.component.scss'],
})
export class AlertManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.tableSerivice.options(10, 4, 'alert List');
  selectedAlerton: any = 'Process';
  selectedIteration: any = 'Minute';
  hideShow: boolean[] = [true];
  runningTime: string = '00:00';
  currentDate: any = new Date();
  iterationCount: number = 5;
  allDestinations: any = [];
  processData: any = [];
  stageList: any = [];
  alertTypeList: any = [];
  selectedAlerttType: any = 'Schedular';
  alertcount: any[] = [
    Array(1)
      .fill(0)
      .map((x, i) => i),
  ];
  alertcounter = 1;
  alertTitle: string = null;
  fromNow: boolean = false;
  alertRunningCondition: any[] = [
    {
      variable: null,
      alertOn: 0,
      processId: null,
      stageId: null,
      categoryId: null,
      assetId: null,
      alertType: [
        {
          alertType: 0,
          alertTypeName: null,
          min: 0,
          max: 0,
          externalAlertId: null,
        },
      ],
      issue_type:1,
      is_compliance:false,
      processName: null,
      stageName: null,
      categoryName: null,
      assetName: null,
      destination_code: null,
      processStageName: null,
      actionName: null,
      fieldName: null,
      statusName: null,
      zoneName: null,
      userName: null,
      staffName: null,
      subFieldName: null,
      checkListName: null,
    },
  ];
  actionList: any[] = [];
  processWiseStages: any[] = [];
  stageWiseDestinations: any[] = [];
  stageWiseActions: any[] = [];
  actionWiseField: any[] = [];
  fieldSubGroups: any[] = [];
  alerts: any[] = [];
  table: any;
  btn: string = 'save';
  alertId: number = null;
  alertDeleteUpdate: any = { iac_id: [], iar_id: [] };
  processStageOrder: any = [];
  formValues: any[] = [];
  alertOn: any;
  assets: any[] = [];
  category: any[] = [];
  status: any[] = [];
  zones: any[] = [];
  staff: any[] = [];
  users: any[] = [];
  categoryWiseAssets: any[] = [];
  checklists: any[] = [];

  constructor(
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public mapService: MapService,
    public alertService: AlertService,
    private stageActionService: StageActionService,
    public user: UserService,
    public tableSerivice: TableService,
    public role: RoleService,
    public alert: AlertService,
    private modalService: NgbModal,
    private Stage: StagesettingService,
    private assestCategoryService: AssetCategoryServiceService
  ) {
    this.getAlertConditions();
    this.getProcess();
    this.getAlertList();
    this.getAllAlerts();
    // this.getAlertEntities();
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'alert', url: null },
      { name: 'Alert Management', link: '/user/alert-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  checkStepSize() {
    if (this.iterationCount % 5 != 0 && this.selectedIteration == 'Minute') {
      this.alertService.warn('Minutes Should be Multiple of 5 !!');
      this.iterationCount = 5;
    }
  }

  //reseting data..
  resetData() {
    this.alertcount = [
      Array(1)
        .fill(0)
        .map((x, i) => i),
    ];
    this.alertcounter = 1;
    this.alertTitle = null;
    this.alertRunningCondition = [
      {
        variable: null,
        alertOn: 0,
        processId: null,
        stageId: null,
        categoryId: null,
        assetId: null,
        alertType: [{ alertType: 0, min: 0, max: 0, externalAlertId: null }],
        is_compliance:false,
        issue_type:1
      },
    ];
    this.selectedIteration = 'Day';
    this.hideShow = [true];
    this.runningTime = '00:00';
    this.currentDate = new Date();
    this.iterationCount = 0;
    this.titleValidator = null;
    this.fromNow = false;
    this.processWiseStages = [];
    this.stageWiseDestinations = [];
    this.stageWiseActions = [];
    this.actionList = [];
    this.allDestinations = [];
    this.stageList = [];
    this.actionWiseField = [];
    this.fieldSubGroups = [];
  }

  //reseting data according to alertType
  resetDetail(alertType: string) {
    this.btn = 'save';
    this.alertId = null;
    if (alertType == 'Event') {
      this.selectedAlerton = 'Vehicle';
      this.selectedAlerttType = 'Event';
    } else {
      this.selectedAlerton = 'Process';
      this.selectedAlerttType = 'Schedular';
    }
    this.resetData();
    this.common.gotoTop();
  }

  //to get alertType List ex-(low,medium)
  getAlertList() {
    this.common.loading = true;
    let apiUrl = 'alert/get_alert_type';
    if (this.role.user_role == 'AXESTRACK') {
      apiUrl = 'alert/get_alert_type?plantId=-1';
    }
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertTypeList = res.data;
          this.common.loading = false;
        }
      },
      (err) => {
        this.common.loading = false;
      }
    );
  }

  getAllChecklists() {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api
        .get('checklist/get-all-checklists?checklistActive=false')
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.common.loading = false;
              this.checklists = res.data;
              this.renderTable();
              resolve(this.checklists);
            }
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(false);
          }
        );
    });
  }

  //Get field Sub Group by field GroupId
  getFieldSubGroups(fieldGroupId: number, index: number) {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        fieldGroupId: fieldGroupId,
      };
      this.api.get('fields/get-field-sub-group', params).subscribe(
        (item: any) => {
          if (item.status) {
            if (this.fieldSubGroups.length < index) {
              this.fieldSubGroups.push(item.data ?? []);
            } else {
              this.fieldSubGroups[index] = item.data ?? [];
            }
            resolve(this.fieldSubGroups);
            this.common.loading = false;
          }
        },
        (err) => {
          this.common.loading = false;
          reject(false);
        }
      );
    });
  }

  getAlertConditions() {
    this.common.loading = true;
    this.api.get('alert/get_alert_conditions').subscribe(
      (res: any) => {
        this.common.loading = false;
        res.data.forEach((element) => {
          if (element.alert_type == 1)
            this.alertVariableEvent[element.name] = element.condition_data;
          else if (element.alert_type == 0)
            this.alertVariable[element.name] = element.condition_data;
          else {
            this.alertVariableEvent[element.name] =
              element.condition_data.filter(
                (res) => res.mapping_on == 1 || res.mapping_on == -1
              );
            this.alertVariable[element.name] = element.condition_data.filter(
              (res) => res.mapping_on == -1 || res.mapping_on == 0
            );
          }
        });
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  //returns selected process name to autosuggestion
  selectedProcessAlertName(id: number, index: number) {
    let processName;
    if (this.selectedAlerton == 'Process' && id != 0) {
      processName = this.processData.filter((p) => p.processId == id)[0]
        .processName;
    } else if (
      (this.selectedAlerton == 'Stage' ||
        this.selectedAlerton == 'Destination' ||
        this.selectedAlerton == 'Action') &&
      this.alertRunningCondition[index].processId != null
    ) {
      processName = this.processData.filter(
        (p) => p.processId == this.alertRunningCondition[index].processId
      )[0].processName;
    }
    return processName;
  }

  selectedStatusAlertName(event: any) {
    if (event.alertOn != 0 && this.status.length) {
      let statusName = this.status.find((p) => p.status_id == event.alertOn);
      return statusName ? statusName.status_name : '';
    }
  }

  selectedUserAlertName(event: any) {
    if (event.alertOn != 0 && this.users.length) {
      let userName = this.users.find((p) => p.userId == event.alertOn);
      return userName ? userName.userName : '';
    }
  }

  selectedStaffAlertName(event: any) {
    if (event.alertOn != 0 && this.staff.length) {
      let staffName = this.staff.find((p) => p.staff_id == event.alertOn);
      return staffName ? staffName.first_name : '';
    }
  }

  selectedZoneAlertName(event: any) {
    if (event.alertOn != 0 && this.zones.length) {
      let zoneName = this.zones.find((p) => p.id == event.alertOn);
      return zoneName ? zoneName.zone_name : '';
    }
  }

  selectedCategoryAlertName(event: any) {
    if (event.categoryId != null && this.category.length) {
      let categoryName = this.category.find(
        (p) => p.asset_category_id == event.categoryId
      );
      return categoryName ? categoryName.asset_category_name : '';
    }
  }

  selectedAssetAlertName(event: any, index: number) {
    if (event.assetId != null && this.categoryWiseAssets[index].length) {
      let assetName = this.categoryWiseAssets[index].find(
        (p) => p.asset_id == event.assetId
      );
      return assetName ? assetName.asset_name : '';
    }
  }

  //returns selected stage name to autosuggestion
  selectedStageAlertName(id: number, index: number) {
    let stageName;
    if (
      this.processWiseStages.indexOf(this.processWiseStages[index + 1]) != -1 ||
      (this.selectedAlerton == 'Action' &&
        this.alertRunningCondition[index].stageId != null)
    ) {
      if (this.selectedAlerton == 'Stage' && id != 0) {
        stageName = this.processWiseStages[index + 1].filter(
          (p) => p.stage_id == id
        )[0].stage_name;
      } else if (
        (this.selectedAlerton == 'Destination' ||
          this.selectedAlerton == 'Action') &&
        this.alertRunningCondition[index].stageId != null
      ) {
        index =
          this.selectedAlerton == 'Action' &&
          this.alertRunningCondition[index].stageId != null
            ? index
            : index + 1;
        stageName = this.processWiseStages[index].filter(
          (p) => p.stage_id == this.alertRunningCondition[index].stageId
        )[0].stage_name;
      }
    }
    return stageName;
  }

  //returns selected destination name to autosuggestion
  selectedDestinationAlertName(id: number, index: number) {
    if (
      id != 0 &&
      this.selectedAlerton == 'Destination' &&
      this.stageWiseDestinations.indexOf(
        this.stageWiseDestinations[index + 1]
      ) != -1
    ) {
      let destinationName = this.stageWiseDestinations[index + 1].filter(
        (p) => p.stage_destination_code_id == id
      )[0].stage_destination_code;
      return destinationName;
    }
  }

  //returns selected action name to autosuggestion
  selectedActionAlertName(id: number, index: number) {
    index =
      this.selectedAlerton == 'Field' || this.selectedAlerton == 'Field Group'
        ? index
        : this.alertRunningCondition[index].processId == null
        ? 0
        : index + 1;
    if (
      id != 0 &&
      this.selectedAlerton == 'Action' &&
      this.stageWiseActions.indexOf(this.stageWiseActions[index]) != -1
    ) {
      let actionName = this.stageWiseActions[index].filter(
        (p) => p.actionId == id
      )[0].actionName;
      return actionName;
    } else if (
      this.stageWiseActions.length > index &&
      (this.selectedAlerton == 'Field' || this.selectedAlerton == 'Field Group')
    ) {
      let actionName = this.stageWiseActions[index].filter(
        (p) => p.actionId == this.alertRunningCondition[index].actionId
      );
      if (actionName.length > 0) return actionName[0].actionName;
    }
  }

  //returns selected alert type name to autosuggestion(ex-low,medium)
  selectedAlertTypeName(id: any) {
    if (id != 0) {
      let alertTypeName = this.alertTypeList.filter((p) => p.id == id)[0]
        .type_name;
      return alertTypeName;
    }
  }

  //returns processStage List for Field Type Alert
  getProcessStage() {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api.get('process/get-process-stage').subscribe(
        (res: any) => {
          this.common.loading = false;
          this.processStageOrder = res.data;
          resolve(this.processStageOrder);
        },
        (err: any) => {
          console.error('Error: ', err);
          reject(null);
          this.common.loading = false;
        }
      );
    });
  }

  //list of alertTypes
  alertTypes = [
    { type: 'Schedular', value: 'Schedular' },
    { type: 'Event', value: 'Event' },
  ];

  //list of variables for selected alerton
  alertVariable: any = {};

  alertVariableEvent: any = {};

  //list of iteration type
  iterationType = [
    { type: 'Day', value: 'Day' },
    { type: 'Hour', value: 'Hour' },
    { type: 'Minute', value: 'Minute' },
  ];

  //setting alert conditions boxes accordian
  accordian(i: number) {
    if (this.hideShow[i] == true) {
      this.hideShow[i] = false;
    } else {
      this.hideShow[i] = true;
    }
  }

  //to call required api's when a selected alert on is switched to another
  async callApi() {
    this.alertRunningCondition = [
      {
        variable: null,
        alertOn: 0,
        processId: null,
        stageId: null,
        categoryId: null,
        assetId: null,
        alertType: [{ alertType: 0, min: 0, max: 0 }],
        is_compliance:false,
        issue_type:1
      },
    ];
    this.alertcount = [
      Array(1)
        .fill(0)
        .map((x, i) => i),
    ];
    this.alertcounter = 1;
    if (this.selectedAlerton == 'Process') {
      await this.getProcess();
    } else if (this.selectedAlerton == 'Stage') {
      await this.getProcess();
      await this.getAllStageByProcess(-1, -1);
    } else if (this.selectedAlerton == 'Destination') {
      await this.getProcess();
      await this.getAllStageByProcess(-1, -1);
      await this.getAllDestinations();
    } else if (this.selectedAlerton == 'Action') {
      await this.getProcess();
      await this.getAllStageByProcess(-1, -1);
      await this.getAllStageAction();
    } else if (
      this.selectedAlerton == 'Field' ||
      this.selectedAlerton == 'Field Group'
    ) {
      await this.getProcessStage();
    } else if (this.selectedAlerton == 'Asset') {
      await this.getAllCategories();
      await this.getAllAssets(-1, -1);
    } else if (this.selectedAlerton == 'Zone') {
      await this.getAllZones();
    } else if (this.selectedAlerton == 'User') {
      await this.getAllUsers();
    } else if (this.selectedAlerton == 'Staff') {
      await this.getAllStaff(0);
    } else if (this.selectedAlerton == 'Checklist') {
      await this.getAllChecklists();
    } else if (this.selectedAlerton == 'Visitor') {
      await this.getAllZones();
      await this.getAllStageByType('Visit Sites',-1,-1);
    }
  }

  getAllUsers() {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let apiUrl = 'user/all_active_and_blocked_users';
      this.api.get(apiUrl).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res?.data?.length) {
            this.users = res.data;
          } else {
            this.alert.info(`Users not found`);
          }
          resolve(this.users);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  getAllStaff(staffTypeId: number) {
    let params = {
      allStaff: false,
      staffTypeId: staffTypeId,
    };
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let apiUrl = 'staff/all-staff';
      this.api.get(apiUrl, params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res?.data?.length) {
            this.staff = res.data;
          } else {
            this.alert.info(`Staff not found`);
          }
          resolve(this.staff);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  getAllZones() {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let apiUrl = 'zone/get-all-zone-stage?allZones=false';
      this.api.get(apiUrl).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res?.data?.length) {
            this.zones = res.data;
          } else {
            this.alert.info(`Zones not found`);
          }
          resolve(this.zones);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  getAllCategories() {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        allCategories: false,
      };
      this.assestCategoryService.getAssetCategory(params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.data?.length) {
            this.category = res.data;
          } else {
            this.alert.info(`Categories not found`);
          }
          resolve(true);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  getAllStatus() {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let apiUrl = 'status/get-status-list?statusType=Asset&allStatus=false';
      this.api.get(apiUrl).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res?.data?.length) {
            this.status = res.data.filter(
              (status) =>
                status.status_name == 'ASSIGNED' ||
                status.status_name == 'CHECKEDIN' ||
                status.status_name == 'CHECKEDOUT' ||
                status.status_name == 'REJECTED'
            );
          } else {
            this.alert.info(`Status not found`);
          }
          resolve(this.status);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  async getAllAssets(categoryId: number, index: number) {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let apiUrl =
        'assets/get-all-assets?allAssets=false&categoryId=' + categoryId;
      this.api.get(apiUrl).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.data.length) {
            this.assets = res.data;
            if (index == -1) {
              //when api is called for the first time ....getting all stages
              this.categoryWiseAssets.push(this.assets); //pushing at index 0
            } else {
              //when filtered according to the process Id
              //pushing on the same index with respect to the index of the condition box ,so that when selected process is changed on that index of condition box new stages list will be added on the same index acco. to condition box
              this.categoryWiseAssets.splice(index, 1, this.assets);
            }
          } else {
            this.categoryWiseAssets.splice(index, 1, []);
            this.alert.info(`Assets not found`);
          }
          resolve(this.assets);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  //get all stages list by passing -1 and filtered list by passing processId
  getAllStageByProcess(processId: number, index: number) {
    return new Promise((resolve, reject) => {
      if (processId != null) {
        this.common.loading = true;
        let apiUrl = `stage/stage_by_process?processId=${processId}`;
        this.api.get(apiUrl).subscribe(
          (res: any) => {
            this.common.loading = false;
            if (res?.data?.length) {
              this.stageList = res.data;
              if (index == -1) {
                //when api is called for the first time ....getting all stages
                this.processWiseStages.push(this.stageList); //pushing at index 0
              } else {
                //when filtered according to the process Id
                //pushing on the same index with respect to the index of the condition box ,so that when selected process is changed on that index of condition box new stages list will be added on the same index acco. to condition box
                this.processWiseStages.splice(index + 1, 1, this.stageList);
              }
            } else {
              this.alert.info(`Stages not found`);
            }
            resolve(this.stageList);
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
      } else {
        resolve(true);
      }
    });

  }


  getAllStageByType(stageType:string,processId: number, index: number) {
    return new Promise((resolve, reject) => {
      let params={
        processId:processId,
        stageType:stageType
      }
      if (processId != null) {
        this.common.loading = true;
        let apiUrl = `stage/stage_by_process`;
        this.api.get(apiUrl,params).subscribe(
          (res: any) => {
            this.common.loading = false;
            if (res?.data?.length) {
              this.stageList = res.data;
              if (index == -1) {
                //when api is called for the first time ....getting all stages
                this.processWiseStages.push(this.stageList); //pushing at index 0
              } else {
                //when filtered according to the process Id
                //pushing on the same index with respect to the index of the condition box ,so that when selected process is changed on that index of condition box new stages list will be added on the same index acco. to condition box
                this.processWiseStages.splice(index + 1, 1, this.stageList);
              }
            } else {
              this.alert.info(`Stages not found`);
            }
            resolve(this.stageList);
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  //get all destinations list by passing stageId and processId
  getDestinationCodeByStageId(
    processId: number,
    stageId: number,
    index: number
  ) {
    return new Promise((resolve, reject) => {
      let params;
      if (stageId != null) {
        params = {
          processId: processId,
          stageId: stageId,
        };
      } else {
        params = {
          processId: processId,
        };
      }
      if (processId != null) {
        this.common.loading = true;
        this.api
          .get('destination/destination_code_by_stageid', params)
          .subscribe(
            (res: any) => {
              this.common.loading = false;
              this.allDestinations = res.data;
              //pushing on the same index with respect to the index of the condition box ,so that when selected process or stage is changed on that index of condition box new destinations list will be added on the same index acco. to condition box
              resolve(
                this.stageWiseDestinations.splice(
                  index + 1,
                  1,
                  this.allDestinations
                )
              );
            },
            (err: any) => {
              console.error('Error', err);
              this.common.loading = false;
              reject(null);
            }
          );
      } else {
        resolve(true);
      }
    });
  }

  //get all actions list by passing stageId and processId
  getActionsByStageId(processId: number, stageId: number, index: number) {
    return new Promise((resolve, reject) => {
      let params;
      if (processId != null && stageId != null) {
        params = {
          processId: processId,
          stageId: stageId,
        };
      } else {
        params = {
          processId: processId,
        };
      }
      if (processId != null) {
        this.common.loading = true;
        this.api
          .get('dashboard/get-dashboard-current-actions', params)
          .subscribe(
            (res: any) => {
              this.common.loading = false;
              this.actionList = res.data.map((res) => {
                res.actionName = res.action_name;
                res.actionId = res.action_id;
                return res;
              });
              //pushing on the same index with respect to the index of the condition box ,so that when selected process or stage is changed on that index of condition box new actions list will be added on the same index acco. to condition box
              let indexId =
                this.selectedAlerton == 'Field' ||
                this.selectedAlerton == 'Field Group'
                  ? index
                  : index + 1;
              resolve(
                this.stageWiseActions.splice(indexId, 1, this.actionList)
              );
            },
            (err: any) => {
              console.error('Error', err);
              this.common.loading = false;
              reject(null);
            }
          );
      } else {
        resolve(true);
      }
    });
  }

  //get all Field list by passing action and processId
  getActionFieldsByActionId(
    actionId: number,
    processId: number,
    stageId: number,
    index: number
  ) {
    this.common.loading = true;
    return new Promise((resolve, reject) => {
      this.Stage.getFormFieldByActionIdAndStageIdAndProcessId(
        actionId,
        processId,
        stageId
      ).subscribe(
        async (item) => {
          item.data = item.data.filter((ele) =>
            this.selectedAlerton == 'Field'
              ? ele.field_type != 'FIELDGROUP'
              : ele.field_type == 'FIELDGROUP'
          );
          if (this.actionWiseField.length < index) {
            this.actionWiseField.push(item.data ?? []);
          } else {
            this.actionWiseField[index] = item.data ?? [];
          }
          this.common.loading = false;
          resolve(true);
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  //get all destinations list
  getAllDestinations() {
    return new Promise((resolve, reject) => {
      let apiUrl = 'destination/list_stage_destination_code?allStatus=false';
      this.api.get(apiUrl).subscribe(
        (res: any) => {
          this.allDestinations = res.data;

          //when api is called for the first time ....getting all destinations
          this.stageWiseDestinations.push(this.allDestinations);
          resolve(this.stageWiseDestinations); //pushing at index 0
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  //get all actions list
  getAllStageAction() {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.stageActionService.getAllStageAction().subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.actionList = res.data;
            //when api is called for the first time ....getting all destinations
            this.stageWiseActions.push(this.actionList); //pushing at index 0
            resolve(this.stageWiseActions);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(err);
        }
      );
    });
  }

  //get all process list
  getProcess() {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api.get('process/get-all-process?processActive=true').subscribe(
        (res: any) => {
          this.common.loading = false;
          resolve((this.processData = res.data));
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
          reject(err);
        }
      );
    });
  }

  getAllAlerts() {
    this.common.loading = true;
    this.api.get('alert/get_all_alerts').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alerts = res.data;
        this.alerts.forEach((element) => {
          element.alertRunningTime = JSON.parse(element.alertRunningTime);
          element.alertRunningCondition = JSON.parse(
            element.alertRunningCondition
          );
        });
        this.renderTable();
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  async edit(testvalue: any) {
    this.common.gotoTop();
    this.btn = 'update';
    this.alertId = testvalue.alert_id;
    this.selectedAlerttType = testvalue.alertExecutionType;
    this.alertTitle = testvalue.alertTitle;
    this.selectedAlerton = testvalue.alertOn;
    // if (this.selectedAlerton != 'Asset') {
    await this.callApi();
    // }
    let index = 0;
    let conditionLength = testvalue.alertRunningCondition.length;
    if (this.selectedAlerttType == 'Schedular') {
      this.iterationCount = testvalue.alertRunningTime.iterationCount;
      this.selectedIteration = testvalue.alertRunningTime.iterationType;
      this.fromNow = testvalue.alertRunningTime.fromNow;
      this.runningTime = testvalue.alertRunningTime.runningTime;
    }

    for (const element of testvalue.alertRunningCondition) {
      this.alertRunningCondition[index].variable = _.cloneDeep(
        element.variable
      );
      this.alertRunningCondition[index].condition_id = _.cloneDeep(
        element.condition_id
      );
      this.alertRunningCondition[index].issue_type = _.cloneDeep(element.issue_type);
      this.alertRunningCondition[index].is_compliance = _.cloneDeep(element.is_compliance);
      this.alertRunningCondition[index].iac_id = element.iac_id;
      await this.selectedProcess(_.cloneDeep(element), index);
      if (
        this.selectedAlerton == 'Field' ||
        this.selectedAlerton == 'Field Group'
      ) {
        await this.selectedProcessStage(
          { process_id: element.processId, stageId: element.stageId },
          index
        );
      }
      if (
        this.selectedAlerton == 'Stage' ||
        this.selectedAlerton == 'Destination' ||
        this.selectedAlerton == 'Action'
      ) {
        await this.selectedStage(_.cloneDeep(element), index);
      }
      if (this.selectedAlerton == 'Destination') {
        await this.selectedDestination(_.cloneDeep(element), index);
      }
      if (this.selectedAlerttType != 'Schedular') {
        this.selectedAction(
          {
            actionId:
              this.selectedAlerton == 'Field' ||
              this.selectedAlerton == 'Field Group'
                ? _.cloneDeep(element.actionId)
                : _.cloneDeep(element.alertOn),
          },
          index
        );
      }
      if (
        this.selectedAlerton == 'Field' ||
        this.selectedAlerton == 'Field Group'
      ) {
        await this.selectedField(
          {
            master_field_id:
              this.selectedAlerton == 'Field Group'
                ? element.fieldId
                : element.alertOn,
            index,
          },
          index
        );
        this.selectedSubField({ sub_group_index: element.alertOn }, index);
      }
      this.alertRunningCondition[index].alertOn = _.cloneDeep(element.alertOn);
      if (this.selectedAlerton == 'Asset') {
        if (element.variable == 'Status') {
          await this.getAllStatus();
          this.selectedStatusAlertName(_.cloneDeep(element));
        }
        // await this.getAllCategories();
        this.selectedCategoryAlertName(_.cloneDeep(element));
        if (element.categoryId != null) {
          this.alertRunningCondition[index].categoryId = _.cloneDeep(
            element.categoryId
          );
          await this.getAllAssets(_.cloneDeep(element.categoryId), index);
        }
        this.alertRunningCondition[index].assetId = _.cloneDeep(
          element.assetId
        );
        this.selectedAssetAlertName(_.cloneDeep(element), index);
      } else if (this.selectedAlerton == 'Zone') {
        this.selectedZoneAlertName(_.cloneDeep(element));
      } else if (this.selectedAlerton == 'User') {
        this.selectedUserAlertName(_.cloneDeep(element));
      } else if (this.selectedAlerton == 'Staff') {
        this.selectedStaffAlertName(_.cloneDeep(element));
      }

      let alertIndex = 0;
      let alertLength = element.alertType.length;
      element.alertType.forEach((alert) => {
        this.selectedAlertType({ id: alert.alertType }, index, alertIndex);
        this.alertRunningCondition[index].alertType[alertIndex].iar_id =
          alert.iar_id;
        this.alertRunningCondition[index].alertType[alertIndex].min =
          _.cloneDeep(alert.min);
        this.alertRunningCondition[index].alertType[alertIndex].max =
          _.cloneDeep(alert.max);
        this.alertRunningCondition[index].fieldValue = Number(alert.field_value)
          ? parseInt(alert.field_value)
          : alert.field_value;
        this.alertRunningCondition[index].alertType[
          alertIndex
        ].externalAlertId = _.cloneDeep(alert.externalAlertId);
        alertIndex += 1;
        alertIndex != alertLength ? this.plusAlertType(index) : true;
      });
      index += 1;
      index != conditionLength ? this.plus() : true;
    }
  }

  //adding selected variable to alert conditions list on required index
  selectedVariable(event, index) {
    this.alertRunningCondition[index].variable = event.value;
    this.alertRunningCondition[index].condition_id = event.condition_id;
    this.alertRunningCondition[index].issue_type = event.issue_type
    if (
      !(
        this.selectedAlerttType == 'Schedular' &&
        this.selectedAlerton != 'Vehicle' &&
        this.selectedAlerton != 'Transaction'
      ) &&
      !(
        this.selectedAlerton == 'Field Group' &&
        this.alertRunningCondition[index].variable != 'Value'
      )
    ) {
      this.alertcount[index] = [0];
      this.alertRunningCondition[index].alertType = this.alertRunningCondition[
        index
      ].alertType.splice(0, 1);
    }
    if (event.value == 'Status') {
      this.getAllStatus();
    }
  }

  async selectedStatus(event: any, index: number) {
    return new Promise(async (resolve, reject) => {
      this.alertRunningCondition[index].alertOn = event.status_id;
      this.alertRunningCondition[index].statusName =
        this.selectedStatusAlertName(this.alertRunningCondition[index]);
      resolve(true);
    });
  }

  async selectedUser(event: any, index: number) {
    return new Promise(async (resolve, reject) => {
      this.alertRunningCondition[index].alertOn = event.userId;
      this.alertRunningCondition[index].userName = this.selectedUserAlertName(
        this.alertRunningCondition[index]
      );
      resolve(true);
    });
  }

  async selectedStaff(event: any, index: number) {
    return new Promise(async (resolve, reject) => {
      this.alertRunningCondition[index].alertOn = event.staff_id;
      this.alertRunningCondition[index].staffName = this.selectedStaffAlertName(
        this.alertRunningCondition[index]
      );
      resolve(true);
    });
  }

  async selectedZone(event: any, index: number) {
    return new Promise(async (resolve, reject) => {
      this.alertRunningCondition[index].alertOn = event.id;
      this.alertRunningCondition[index].zoneName = this.selectedZoneAlertName(
        this.alertRunningCondition[index]
      );
      resolve(true);
    });
  }

  async selectedCategory(event: any, index: number) {
    return new Promise(async (resolve, reject) => {
      this.alertRunningCondition[index].categoryId = event.asset_category_id;
      this.alertRunningCondition[index].categoryName =
        this.selectedCategoryAlertName(this.alertRunningCondition[index]);
      this.getAllAssets(event.asset_category_id, index);
      resolve(true);
    });
  }

  async selectedAsset(event: any, index: number) {
    return new Promise(async (resolve, reject) => {
      this.alertRunningCondition[index].assetId = event.asset_id;
      this.alertRunningCondition[index].assetName = this.selectedAssetAlertName(
        this.alertRunningCondition[index],
        index
      );
      resolve(true);
    });
  }

  //adding selected process to alert conditions list on required index
  async selectedProcess(event: any, index: number) {
    return new Promise(async (resolve, reject) => {
      if (this.selectedAlerton == 'Process') {
        this.alertRunningCondition[index].alertOn =
          event.processId || event.alertOn;
        resolve(true);
      } else {
        this.alertRunningCondition[index].processId = event.processId;
        //removing selected stage from the index in case selected already and then the processId is changed again
        this.stageList = [];
        this.processWiseStages.splice(index + 1, 1);
        this.alertRunningCondition[index].stageId =
          event.stageId || event.stage_Id;
        this.alertRunningCondition[index].alertOn = 0;
        resolve(this.getAllStageByProcess(event.processId, index));
        if (this.selectedAlerton == 'Destination') {
          this.allDestinations = [];
          this.stageWiseDestinations.splice(index + 1, 1);
          await this.getDestinationCodeByStageId(event.processId, null, index);
        }
        if (this.selectedAlerton == 'Action') {
          this.actionList = [];
          this.stageWiseActions.splice(index + 1, 1);
          await this.getActionsByStageId(event.processId, null, index);
        }
      }

      this.alertRunningCondition[index].processName =
        this.selectedProcessAlertName(
          this.alertRunningCondition[index].alertOn,
          index
        );
    });
  }

  //adding selected stage to alert conditions list on required index
  selectedStage(event: any, index: number) {
    return new Promise(async (resolve, reject) => {
      if (
        this.selectedAlerton == 'Stage' ||
        this.selectedAlerton == 'Visitor'
      ) {
        resolve(
          (this.alertRunningCondition[index].alertOn =
            event.stageId || event.alertOn || event.stage_id)
        );
      } else {
        this.alertRunningCondition[index].stageId =
          event.stage_id || event.stageId;
        //removing selected destination and action from the index in case selected already and then the processId or stageid is changed again
        this.allDestinations = [];
        this.stageWiseDestinations.splice(index + 1, 1);
        this.actionList = [];
        this.stageWiseActions.splice(index + 1, 1);
        this.alertRunningCondition[index].alertOn = 0;
        await this.getDestinationCodeByStageId(
          this.alertRunningCondition[index].processId,
          event.stage_id || event.stageId,
          index
        );
        await this.getActionsByStageId(
          this.alertRunningCondition[index].processId,
          event.stage_id || event.stageId,
          index
        );
        resolve(true);
      }
      this.alertRunningCondition[index].stageName = this.selectedStageAlertName(
        this.alertRunningCondition[index].alertOn,
        index
      );
    });
  }

  //adding selected destination to alert conditions list on required index
  selectedProcessStage(event: any, index: number) {
    return new Promise(async (resolve, reject) => {
      this.alertRunningCondition[index].processId = event.process_id;
      this.alertRunningCondition[index].stageId = event.stageId;
      this.alertRunningCondition[index].processStageName =
        this.selectedAlertProcessStage(
          this.alertRunningCondition[index].alertOn,
          index
        );
      await this.getActionsByStageId(event.process_id, event.stageId, index);
      resolve(true);
    });
  }
  //adding selected destination to alert conditions list on required index
  selectedAlertProcessStage(event: any, index: number) {
    if (this.processStageOrder.length > 0) {
      let data = this.processStageOrder.filter(
        (pso) =>
          pso.process_id == this.alertRunningCondition[index].processId &&
          this.alertRunningCondition[index].stageId == pso.stageId
      );
      if (data.length > 0) return data[0].order_name;
    }
  }

  //adding selected destination to alert conditions list on required index
  selectedDestination(event: any, index: number) {
    this.alertRunningCondition[index].alertOn =
      event.stage_destination_code_id || event.alertOn;
    this.alertRunningCondition[index].destination_code =
      this.selectedDestinationAlertName(
        this.alertRunningCondition[index].alertOn,
        index
      );
  }

  //adding selected action to alert conditions list on required index
  selectedAction(event: any, index: number) {
    if (
      this.selectedAlerton != 'Field' &&
      this.selectedAlerton != 'Field Group'
    ) {
      this.alertRunningCondition[index].alertOn = event.actionId;
    } else {
      this.alertRunningCondition[index].actionId = event.actionId;
      this.getActionFieldsByActionId(
        event.actionId,
        this.alertRunningCondition[index].processId,
        this.alertRunningCondition[index].stageId,
        index
      );
    }
    this.alertRunningCondition[index].actionName = this.selectedActionAlertName(
      this.alertRunningCondition[index].alertOn,
      index
    );
  }

  //adding selected Field to alert conditions list on required index
  async selectedField(event: any, index: number) {
    if (this.selectedAlerton == 'Field Group') {
      await this.getFieldSubGroups(event.master_field_id, index);
      this.alertRunningCondition[index].fieldId = event.master_field_id;
    } else if (this.actionWiseField.length >= index) {
      this.alertRunningCondition[index].alertOn = event.master_field_id;
    }
    this.alertRunningCondition[index].fieldName = this.selectedFieldAlertName(
      this.selectedAlerton == 'Field Group'
        ? this.alertRunningCondition[index].fieldId
        : this.alertRunningCondition[index].alertOn,
      index
    );
  }

  //adding selected Sub Field to alert conditions list on required index
  selectedSubField(event: any, index: number) {
    if (this.fieldSubGroups.length >= index) {
      this.alertRunningCondition[index].alertOn = event.sub_group_index;
      this.alertRunningCondition[index].subFieldName =
        this.selectedSubFieldAlertName(
          this.alertRunningCondition[index].alertOn,
          index
        );
    }
  }

  //adding selected Checklist to alert conditions list on required index
  selectedChecklist(event: any, index: number) {
    this.alertRunningCondition[index].alertOn = event.id;
    this.alertRunningCondition[index].checkListName =
      this.selectedChecklistAlertName(
        this.alertRunningCondition[index].alertOn,
        index
      );
  }

  //returns selected field name to autosuggestion
  selectedFieldAlertName(id: number, index: number) {
    let data = this.actionWiseField[index];
    if (
      this.actionWiseField[index] != null &&
      id != 0 &&
      (this.selectedAlerton == 'Field' ||
        this.selectedAlerton == 'Field Group') &&
      this.actionWiseField.indexOf(this.actionWiseField[index]) != -1
    ) {
      let fieldName = data.filter((p) => p.master_field_id == id);
      this.alertRunningCondition[index].field_value =
        this.alertRunningCondition[index].variable == 'Field Value'
          ? fieldName
          : null;
      if (fieldName.length > 0) {
        return fieldName[0].field_name;
      }
    }
  }

  //returns selected field name to autosuggestion
  selectedSubFieldAlertName(id: number, index: number) {
    let data = this.fieldSubGroups[index];
    if (
      this.fieldSubGroups[index] != null &&
      this.selectedAlerton == 'Field Group' &&
      this.fieldSubGroups.indexOf(this.fieldSubGroups[index]) != -1
    ) {
      let fieldName = data.filter((p) => p.sub_group_index == id);
      this.alertRunningCondition[index].field_value =
        this.alertRunningCondition[index].variable == 'Value'
          ? fieldName
          : null;
      if (fieldName.length > 0) {
        return fieldName[0].field_sub_group;
      }
    }
  }

  selectedChecklistAlertName(id: number, index: number) {
    if (id != 0 && this.checklists.length) {
      let checklistName = this.checklists.find((p) => p.id == id);
      return checklistName ? checklistName.checklist_title : '';
    }
  }

  //adding selected alert type to alert conditions list on required index(ex-low,medium)
  selectedAlertType(event: any, index: number, alertTypeIndex: number) {
    this.alertRunningCondition[index].alertType[alertTypeIndex].alertType =
      event.id;
    this.alertRunningCondition[index].alertType[alertTypeIndex].alertTypeName =
      this.selectedAlertTypeName(
        this.alertRunningCondition[index].alertType[alertTypeIndex].alertType
      );
  }

  //adding selected min range value to alert conditions list on required index
  selectedMin(event, index, alertTypeIndex) {
    this.alertRunningCondition[index].alertType[alertTypeIndex].min = Number(
      event.target.value
    );
  }

  //adding selected max range value to alert conditions list on required index
  selectedMax(event, index, alertTypeIndex) {
    this.alertRunningCondition[index].alertType[alertTypeIndex].max = Number(
      event.target.value
    );
  }

  //adding selected External Alert Id range value to alert conditions list on required index
  selectedExternalAlertId(event, index, alertTypeIndex) {
    this.alertRunningCondition[index].alertType[
      alertTypeIndex
    ].externalAlertId = Number(event.target.value);
  }

  enterValues(event: any, field: any, i: any) {
    if (field == 'Field Group') {
      this.alertRunningCondition[i].fieldValue = event.target.value;
    } else {
      if (
        field.field_type != 'CHECKBOX' &&
        field.field_type != 'SELECTLISTMULTIPLE' &&
        field.field_type != 'FILE' &&
        field.field_type != 'SELECTLISTSINGLE'
      ) {
        this.alertRunningCondition[i].fieldValue = event.target.value;
      }
      if (
        field.field_type == 'SELECTLISTSINGLE' ||
        field.field_type == 'SELECTLISTMULTIPLE'
      ) {
        this.alertRunningCondition[i].fieldValue = event.label;
      }
      if (field.field_type == 'CHECKBOX') {
        this.alertRunningCondition[i].fieldValue = event.target.checked;
      }
    }
  }

  //adding 1 more alert condition box
  plus() {
    this.alertcount.push(
      Array(1)
        .fill(0)
        .map((x, i) => i)
    );
    this.alertRunningCondition.push({
      variable: null,
      alertOn: 0,
      processId: null,
      stageId: null,
      actionId: null,
      categoryId: null,
      assetId: null,
      alertType: [{ alertType: 0, min: 0, max: 0, extrnalAlertId: null }],
    });
    this.hideShow.push(true);
    this.getAllAssets(-1, -1);
  }

  //removing alert condition box
  minus(index: number) {
    this.alertcounter = this.alertcounter - 1;
    this.alertcount.splice(index, 1);
    if (this.alertRunningCondition[index].iac_id != null) {
      this.alertDeleteUpdate.iac_id.push(
        this.alertRunningCondition[index].iac_id
      );
    }
    this.alertRunningCondition.splice(index, 1);
    this.hideShow.splice(index, 1);
    if (this.selectedAlerton == 'Stage') {
      this.processWiseStages.splice(index + 1, 1);
    } else if (this.selectedAlerton == 'Destination') {
      this.stageWiseDestinations.splice(index + 1, 1);
      this.processWiseStages.splice(index + 1, 1);
    } else if (this.selectedAlerton == 'Action') {
      this.processWiseStages.splice(index + 1, 1);
      this.stageWiseActions.splice(index + 1, 1);
    } else if (this.selectedAlerton == 'Asset') {
      this.categoryWiseAssets.splice(index, 1);
    }
  }

  //adding alert Type box into specified alert condition box
  plusAlertType(index: number) {
    this.alertcount[index].push(this.alertcount[index].length + 1);
    this.alertRunningCondition[index].alertType.push({
      alertType: 0,
      min: 0,
      max: 0,
      externalAlertId: null,
    });
  }

  //removing alert Type box from specified alert condition box at typeIndex
  minusAlertType(index: number, typeIndex: number) {
    this.alertcount[index].splice(typeIndex, 1);
    if (this.alertRunningCondition[index].alertType[typeIndex].iar_id != null) {
      this.alertDeleteUpdate.iar_id.push(
        this.alertRunningCondition[index].alertType[typeIndex].iar_id
      );
    }
    this.alertRunningCondition[index].alertType.splice(typeIndex, 1);
  }

  //saving alert...
  saveAlert() {
    let alertRunningTime = {};
    let params = {};

    this.alertRunningCondition = this.alertRunningCondition.map((res) => {
      delete res.processName,
        delete res.stageName,
        delete res.categoryName,
        delete res.assetName,
        delete res.destination_code,
        delete res.processStageName,
        delete res.actionName,
        delete res.fieldName,
        delete res.statusName,
        delete res.zoneName,
        delete res.userName,
        delete res.staffName,
        delete res.subFieldName,
        delete res.checkListName;
      res.alertType = res.alertType.map((value) => {
        delete value.alertTypeName;
        return value;
      });
      return res;
    });
    if (this.selectedAlerttType == 'Schedular') {
      alertRunningTime = {
        fromNow: this.fromNow,
        runningTime: this.runningTime,
        iterationType: this.selectedIteration,
        iterationCount: Number(this.iterationCount),
      };
      params = {
        alertId: this.btn == 'update' ? this.alertId : null,
        alertTitle: this.alertTitle,
        alertExecutionType: this.selectedAlerttType,
        alertOn: this.selectedAlerton,
        alertRunningTime: alertRunningTime,
        alertRunningCondition: this.alertRunningCondition,
        alertDeleteCondition: this.alertDeleteUpdate,
      };
    } else {
      params = {
        alertId: this.btn == 'update' ? this.alertId : null,
        alertTitle: this.alertTitle,
        alertExecutionType: this.selectedAlerttType,
        alertOn: this.selectedAlerton,
        alertRunningCondition: this.alertRunningCondition,
        alertDeleteCondition: this.alertDeleteUpdate,
      };
    }
    this.common.loading = true;

    this.api.post('alert/save_alert', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.type == 'Success') {
          this.alertService.success(res.message);
          this.resetDetail('Schedular');
          this.getAllAlerts();
        } else {
          this.alertService.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  titleValidator: any;
  formValidator() {
    let saveFlag: any = false;
    let iterationCountFlag = false;
    this.titleValidator = this.common.nullValidator(
      this.alertTitle,
      'Alert Title Mandatory'
    );
    if (this.selectedAlerttType == 'Schedular' && this.iterationCount == 0) {
      this.alertService.error("Iteration Count Can't be 0");
      iterationCountFlag = true;
    }
    if (!this.titleValidator.error && iterationCountFlag == false) {
      let i = this.alertRunningCondition.findIndex((e) => e.alertOn == 0);
      if (
        i != -1 &&
        ((this.selectedAlerttType == 'Schedular' &&
          this.selectedAlerton != 'Vehicle' &&
          this.selectedAlerton != 'Trip' &&
          this.selectedAlerton == 'Asset' &&
          this.alertRunningCondition[i].variable == 'Status' &&
          this.selectedAlerton != 'Staff' &&
          this.selectedAlerton != 'User' &&
          this.selectedAlerton != 'Zone' &&
          this.selectedAlerton != 'Visitor') ||
          (this.selectedAlerttType == 'Event' &&
            this.selectedAlerton == 'Action'))
      ) {
        this.alertService.error('Please Select ' + this.selectedAlerton);
        saveFlag = false;
        return;
      } else {
        this.alertRunningCondition.forEach((el) => {
          let i = el.alertType.findIndex((p) => p.alertType == 0);
          if (i != -1) {
            this.alertService.error('Please Select alert Type...');
            saveFlag = false;
            return;
          } else {
            let i = el.alertType.findIndex((p) => p.min == 0 && p.max == 0);
            if (
              i != -1 &&
              this.selectedAlerttType == 'Schedular' &&
              this.selectedAlerton != 'Vehicle' &&
              this.selectedAlerton != 'Trip' &&
              this.selectedAlerton != 'Checklist' &&
              this.selectedAlerton != 'Asset' &&
              this.selectedAlerton != 'Zone' &&
              this.selectedAlerton != 'Visitor'
            ) {
              this.alertService.error('Please Select Min and Max...');
              saveFlag = false;
              return;
            } else {
              let i = this.alertRunningCondition.findIndex(
                (p) => p.variable == 'Total TAT'
              );
              let ind = this.alertRunningCondition.findIndex(
                (p) => p.variable == 'Status' && p.alertOn == 0
              );
              let in_d = this.alertRunningCondition.findIndex(
                (p) => p.variable == null
              );
              if (
                i != -1 &&
                this.selectedAlerton != 'Process' &&
                this.selectedAlerton != 'Stage' &&
                this.selectedAlerton != 'Destination'
              ) {
                this.alertService.error('Please Select Alert Condition...');
                saveFlag = false;
                return;
              } else if (
                this.selectedAlerton == 'Asset' &&
                el.variable == 'Status' &&
                ind != -1
              ) {
                this.alertService.error('Please Select Status...');
                saveFlag = false;
                return;
              } else if (
                (this.selectedAlerton == 'Visitor' ||
                  this.selectedAlerton == 'Checklist' ||
                  this.selectedAlerton == 'Asset' ||
                  this.selectedAlerton == 'Zone' ||
                  this.selectedAlerton == 'User' ||
                  this.selectedAlerton == 'Staff' ||
                  this.selectedAlerton == 'Visitor') &&
                in_d != -1
              ) {
                this.alertService.error('Please Select Alert Condition...');
                saveFlag = false;
                return;
              } else {
                saveFlag = true;
              }
            }
          }
        });
        if (saveFlag == true) {
          this.saveAlert();
        }
      }
    }
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Driver ',
      description: `<b>&nbsp;` + 'Are you sure want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteAlert(row.alert_id);
      }
    });
  }

  deleteAlert(alertId) {
    this.common.loading = true;
    let params = {
      alertId: alertId,
    };

    this.api.get('alert/delete_alert', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.type == 'Success') {
          this.alertService.success(res.message);
          this.resetDetail('Schedular');
          this.getAllAlerts();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

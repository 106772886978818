import { UserService } from 'src/app/@core/services/user.service';
import { TmgService } from './../tmg.service';
import { TmgDashboardService } from './../tmg-dashboard.service';
import {
  worstages,
  canceledTrip,
  optionFilter,
  optionmenu,
  stageHeigheseDrops,
  monthTatFilter,
  worststagefilter,
} from './../data';
import { TmgchartService } from './../tmgchart.service';
import { Label, Color } from 'ng2-charts';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { DetailService } from './../detail-modal/detail.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { DashboardService } from '../../dashboard/dashboard.service';

@Component({
  selector: 'app-stage-dashboard',
  templateUrl: './stage-dashboard.component.html',
  styleUrls: [
    './stage-dashboard.component.scss',
    '../tmg-dashboard.component.scss',
  ],
})
export class StageDashboardComponent implements OnInit {
  worstages = worstages;
  canceledTrip = canceledTrip;
  optionFilter = optionFilter;
  optionmenu = optionmenu;
  stageHeigheseDrops = stageHeigheseDrops;
  monthTatFilter = monthTatFilter;
  worststagefilter = worststagefilter;
  processId: number = 5;
  TatTrendData: any[] = [];
  stageTatVariation: any = [];
  weekbystageTat: any = [];
  TatVariation: any = [];
  ConsistentStageTaT: any = [];

  weekbyStageDetail: any = [];
  stageariationDetail: any[] = [];

  weekbyStageParam: any;
  stageVariationParam: any;
  leastconsistantParam: any;

  leastconsistantDetail: any = [];
  public barChartColors: Color[] = [
    { backgroundColor: this.common.graphsuccess },
    { backgroundColor: this.common.graphsecondary },
    { backgroundColor: this.common.graphdanger },
  ];

  public weekWiseChartData: ChartDataSets[] = [
    // { data: [65, 59, 80, 81, 56, 55, 40], label: 'January' },
    // { data: [28, 48, 40, 19, 86, 27, 90], label: 'February' },
  ];

  public tattrendChartData: ChartDataSets[] = [];

  public tattrendChartLabels: Label[] = [];
  public tattrendChartOptions: ChartOptions & {} = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            precision: 0,
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      position: 'bottom',
    },
  };
  public tattrendChartColors: Color[] = [
    this.chart.barChartcolors.primary,
    this.chart.barChartcolors.danger,
  ];
  public lineChartcolors: Color[] = [
    this.chart.lineChartcolors.primary,
    this.chart.lineChartcolors.danger,
    this.chart.lineChartcolors.info,
    this.chart.lineChartcolors.secondary,
    this.chart.lineChartcolors.success,
  ];
  public tattrendChartLegend = true;
  //  public tattrendChartPlugins = [pluginDataLabels];
  public tattrendChartType: ChartType = 'line';
  public tatvariationChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Actual Tat',
    },
    {
      data: [],
      label: 'Exepcted Tat',
    },
  ];
  public tatvariationChartLabels: Label[] = [];
  public tatvariationChartOptions: ChartOptions & {} = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            precision: 0,
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      position: 'bottom',
    },
  };
  public tatvariationChartColors: Color[] = [
    this.chart.barChartcolors.primary,
    this.chart.barChartcolors.danger,
  ];
  public tatvariationChartLegend = true;
  public tatvariationChartType: ChartType = 'bar';

  constructor(
    public chart: TmgchartService,
    public detail: DetailService,
    public tmg: TmgService,
    public tmgdashboard: TmgDashboardService,
    public common: CommonService,
    public dashboard: DashboardService,
    public api: ApiService,
    public userService: UserService
  ) {}

  dateparse(type: any, number: number) {}

  selectedate(event: any) {}

  //  Month wise TAT Trend (Median hr)
  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 15; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  tatTrendData() {
    let param = this.tmg.DefaultDateRangeCompare('Week');
    delete param.type;
    this.weekWiseChartData = [
      {
        data: [],
        label: '',
        backgroundColor: this.getRandomColor(),
      },
    ];
    this.tatTrendSelect(param);
  }

  tatTrendDataWeekWise: any = [];
  tatTrendSelect(event: any) {
    delete event.type;
    this.tatTrendDataWeekWise = [];
    this.tattrendChartLabels = [];
    //  this.tattrendChartData = [];
    this.tmgdashboard.getMonthWiseTatTrend(event).subscribe((item: any) => {
      if (item.status) {
        this.tatTrendDataWeekWise = item.data;
        let noofStages = this.tatTrendDataWeekWise[0].value.length;
        for (let i = 0; i < noofStages - 1; i++) {
          this.weekWiseChartData.push({
            data: [],
            label: '',
          });
        }
        this.tatTrendDataWeekWise.forEach((element: any, index: number) => {
          element.value.map((ele: any, i: number) => {
            this.weekWiseChartData[i].data?.push(ele.tat);
          });
          this.tattrendChartLabels.push(element.date);
          this.tatTrendDataWeekWise = item.data;
        });

        this.tatTrendDataWeekWise[0].value.map((label: any, index: number) => {
          this.weekWiseChartData[index].label = label.name;
          this.weekWiseChartData[index].backgroundColor = this.getRandomColor();
        });
      }
    });
  }

  //  Worst 3 Stages - Variation (10 days)

  stageVariationData() {
    let { fromDate, toDate, processId } = this.tmg.DateResponse('Week');
    let param = { fromDate, toDate, processId };
    this.stageVariation(param);
  }

  stageVariation(event: any) {
    this.stageVariationParam = event;
    let params = 'processId=' + event.processId;
    this.api
      .get('api/worst_three_stage_planned_vs_actual?' + params)
      .subscribe((item: any) => {
        if (item.status) {
          this.stageTatVariation = item.data ?? [];
        }
      });
  }

  //  Stage TAT Variation

  WeekyByWeekStageTatData() {
    let params = this.tmg.DefaultDateRangeCompare('Week');
    this.WeekyByWeekStageTat(params);
  }

  WeekyByWeekStageTat(event: any) {
    delete event.type;
    this.weekbyStageParam = event;
    this.tmgdashboard.getWeekyByWeekStageTat(event).subscribe((item: any) => {
      if (item.status) {
        this.weekbystageTat = item.data ?? [];
      }
    });
  }

  // StageDropsView(selector: any){
  //   this.weekbyStageParam;
  //   this.weekbyStageParam.type = 'all';
  //   this.tmgdashboard.getWeekyByWeekStageTat(this.weekbyStageParam).subscribe((item: any) => {
  //     this.weekbyStageDetail = item ?? [];
  //     this.detail.openDialog(
  //       this.weekbyStageDetail, 'Week over week Stages Highest Drops Detail', selector);

  //   })

  // }

  //  TAT Variation
  StageTatVariationData() {
    let { fromDate, toDate, processId } = this.tmg.DateResponse('Days', 7);
    let dayFilter = 'last 7 days';
    let param = { processId };
    this.stageVariationSelect(param.processId);
  }

  stageVariationSelect(event: any) {
    let dayFilter = 'last 7 days';
    this.dashboard.getstagetat(dayFilter, event).subscribe((item: any) => {
      if (item.status) {
        this.TatVariation = item.data ?? [];

        this.tatvariationChartLabels = [];
        // this.tatvariationChartData = [];
        if (this.TatVariation.length) {
          this.TatVariation.forEach((element) => {
            this.tatvariationChartData[0].data.push(element.tat);
            this.tatvariationChartData[1].data.push(element.expected_tat);
            this.tatvariationChartLabels.push(element.name);
          });
        }
      }
    });
  }

  stageVariationDetail(selector: any) {
    this.stageVariationParam.type = 'all';
    this.tmgdashboard
      .getWorstStageTatCalc(this.stageVariationParam)
      .subscribe((item: any) => {
        if (item.status) {
          this.stageariationDetail = item.data ?? [];
          this.detail.openDialog(
            this.stageariationDetail,
            'Worst Stages Variation Detail',
            selector
          );
        }
      });
  }

  // leastConsistantTatData(){
  // let {fromDate,toDate,processId} = this.tmg.DateResponse('Days',10);
  // let param = {fromDate,toDate,processId};
  // this.leastConsistantTat(param);

  // }

  // leastConsistantTat(event:any){
  // this.leastconsistantParam = event;
  // this.tmgdashboard.getConsistentStageTat(event).subscribe((item:any)=>{
  // this.ConsistentStageTaT = item ?? [];
  // })
  // }

  // ResetConsistantDetail(selector:any){
  // this.leastconsistantParam.type = 'all';
  // this.tmgdashboard.getConsistentStageTat(this.leastconsistantParam).subscribe((item:any)=>{
  // this.leastconsistantDetail = item ?? [];
  // this.detail.openDialog(this.leastconsistantDetail,'Least Consistent Stage Detail',selector);
  // });

  // }

  // worstcanceltripDetail(selector:any){
  // this.detail.openDialog(this.canceledTrip,'Worst cancel - Last 2 days Detail',selector);
  // }

  ngOnInit(): void {
    setTimeout(() => {
      this.tatTrendData();
      this.stageVariationData();
      this.WeekyByWeekStageTatData();
      this.StageTatVariationData();
      // this.leastConsistantTatData();
    }, 500);
  }
}

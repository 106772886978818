<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Destination Type Management'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{('destination'|customTranslate)+' '+('type'|customTranslate)+'
                    '+('name'|customTranslate)}}</label>
                  <input type="text" class="form-control" name="stageDestinationCodeTypeName" required
                    placeholder="Destination Code Type Name"
                    [(ngModel)]="stageDestinationCodeType.stageDestinationCodeTypeName"
                    #stageDestinationCodeTypeName="ngModel" autocomplete="off">
                  <div class="error" *ngIf="codeTypeKeyValidator && codeTypeKeyValidator.error">
                    <span>{{codeTypeKeyValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required"> {{'status'|customTranslate}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageDestinationCodeStatus"
                      [(ngModel)]="stageDestinationCodeType.stageDestinationCodeTypeStatus" id="stage_status_1"
                      value="ACTIVE">
                    <label for="exampleRadios1">
                      {{'active'|customTranslate}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageDestinationCodeStatus"
                      [(ngModel)]="stageDestinationCodeType.stageDestinationCodeTypeStatus" id="stage_status_2"
                      value="INACTIVE">
                    <label for="exampleRadios2">
                      {{'inactive'|customTranslate}}
                    </label>
                  </div>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">
                    {{'back'|customTranslate}}</button>
                  <button class="btn btn-warning" (click)="resetDetail()"> {{'reset'|customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}} </button>
                </div>
              </div>
            </div>

          </div>
        </div>


      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{('destination'|customTranslate)+' '+('type'|customTranslate)+' '+('list'|customTranslate)}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <!-- table -->
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{'serial_no'|customTranslate}}</th>
                    <th>{{('destination'|customTranslate)+' '+('type'|customTranslate)+' '+('name'|customTranslate)}}</th>
                    <th>{{'status'|customTranslate}}</th>
                    <th class="action"> {{'Actions'|customTranslate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of data, let i = index">
                    <td>{{i+1}}</td>
                    <td>{{row.stageDestinationCodeTypeName}}</td>
                    <td>{{row.stageDestinationCodeTypeStatus}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewDetails(row)"
                          *ngIf="userService.permissionMap.get('view_stage_destination_code_type_access')==true">
                          {{'view'|customTranslate}} </button>
                        <button class="btn btn-success" (click)="editStageDestinationCodeType(row)"
                          *ngIf="userService.permissionMap.get('edit_stage_destination_code_type_access')==true">
                          {{'edit'|customTranslate}} </button>
                      </div>

                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>
      <br>


    </div>
  </div>
</div>

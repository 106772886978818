import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { AssetChild, childAssetList, ParentAsset } from 'src/app/dataType/assetInterface';
import { validator } from 'src/app/dataType/commonInterface';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
declare var $: any;
@Component({
  selector: 'app-asset-linking',
  templateUrl: './asset-linking.component.html',
  styleUrls: ['./asset-linking.component.scss'],
})
export class AssetLinkingComponent implements OnInit {
  parentAssetValidator: validator;
  labelValidator = [];
  currentAsset: string =null;
  parentAssetData: ParentAsset[] = [];
  childAssets = [];
  assetName: string=null;
  childAssetList: childAssetList[];
  parentAssetName: string=null;
  childAssetData: AssetChild[] = [];
  pAssetId: number;
  assetStatus: string;
  parentLinkingValidator = [];

  constructor(
    public api: ApiService,
    public common: CommonService,
    public alert: AlertService
  ) {}
  @Input() assetId: number;

  ngOnInit(): void {
    this.getAssetFamily();
  }

  getParentAssets() {
    this.common.loading = true;
    this.api.get('assets/get-parent-assets').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.parentAssetData = res.data.filter(
          (option) => option.asset_id !== this.assetId
        );
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getAssetFamily() {
    this.common.loading = true;
    let params = {
      assetId: this.assetId,
    };
    this.api.get('assets/get-asset-family', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.assetName = res.data[0].asset_name;
        this.currentAsset = res.data[0].mapping_type;
        this.childAssetList = JSON.parse(res.data[0].child_assets);
        console.log('this.childAssetList: ', this.childAssetList);
        this.childAssets = JSON.parse(res.data[0].child_assets);
        if (this.childAssetList == null) {
          this.childAssets = [];
          this.childAssets.push({ asset_id: null, asset_name: null });
        }
        this.parentAssetName =
          this.currentAsset == 'CHILD'
            ? res.data[0].parent_asset_name
            : this.currentAsset == 'PARENT'
            ? this.assetName
            : null;
        this.pAssetId =
          this.currentAsset == 'CHILD' ? res.data[0].parent_asset_id : null;
        this.assetStatus = this.currentAsset;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getChildAssets() {
    this.common.loading = true;
    this.api.get('assets/get-child-assets').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.childAssetData = res.data;
        this.childAssetData = this.childAssetData.filter(
          (option) => option.asset_id !== this.assetId
        );
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedParent(event: any) {
    this.childAssets = [];
    this.pAssetId = event.asset_id;
    this.parentAssetName = event.asset_name;
  }

  selectedChildAsset(event: any, index: any) {
    const exists = this.childAssets.some(
      (label) => label.asset_id === event.asset_id
    );
    if (exists) {
      this.alert.error('Mappping Already Exist, Please Select Another !!');
      this.childAssets.splice(index, 1);
      this.childAssets.splice(index, 0, { asset_id: null, asset_name: null });
    } else {
      this.childAssets[index].asset_id = event.asset_id;
      this.childAssets[index].asset_name = event.asset_name;
      if (event.parent_asset_id != null) {
        this.parentLinkingValidator[index] =
          'This asset has been assigned to ' + event.parent_asset_name;
      }
    }
  }

  addValues() {
    this.childAssets.push({ asset_id: null, asset_name: null });
  }

  formValidator() {
    if (this.assetStatus == 'CHILD') {
      this.parentAssetValidator = this.common.nullValidator(
        this.pAssetId,
        'Parent Asset is Mandatory'
      );
    }
    this.childAssets.forEach((e, index) => {
      this.labelValidator[index] = this.common.nullValidator(
        e.asset_id,
        'Child Asset Mandatory'
      );
    });

    if (
      (this.assetStatus == 'CHILD' && !this.parentAssetValidator.error) ||
      (this.assetStatus == 'PARENT' &&
        !this.labelValidator.some((validator) => validator.error))
    ) {
      this.saveMapping();
    }
  }

  saveMapping() {
    if (this.assetStatus == 'CHILD') this.childAssetList = [];
    this.common.loading = true;
    let params = {
      childAssets: this.childAssets,
      assetId: this.assetId,
      mappingType: this.assetStatus,
      parentAssetId: this.pAssetId,
    };

    this.api.post('assets/save-parent-child-mapping', params).subscribe(
      (response: any) => {
        this.common.loading = false;
        if (response.status) {
          this.alert.success(response.message);
          $('#exampleModal').modal('hide');
          this.getAssetFamily();
        } else {
          this.alert.error(response.message);
        }
      },
      (error) => {
        this.common.loading = false;
      }
    );
  }
  resetMapping() {
    this.assetStatus = null;
    this.parentAssetValidator = null;
    this.parentLinkingValidator = [];
    this.labelValidator = [];
    this.getAssetFamily();
  }
}

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DashboardService } from '../dashboard/dashboard.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PlantService } from '../plant/plant.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { UserService } from 'src/app/@core/services/user.service';
import { TableService } from 'src/app/@core/services/table.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';

@Component({
  selector: 'app-company-admin-dashboard',
  templateUrl: './company-admin-dashboard.component.html',
  styleUrls: ['../dashboard/dashboard.component.scss'],
})
export class CompanyAdminDashboardComponent implements OnInit {
  public lineChartType: ChartType = 'line';
  tatfilter: string = 'Weekly';
  classname = '';
  allCompany: any[] = [];
  public lineChartLegend = true;
  companyinfoColor = [
    'bg-primary',
    'bg-success',
    'bg-warning',
    'bg-info',
    'bg-danger',
  ];
  companyinfoicons: any = [
    {
      'Total Plants': 'fa fa-industry',
      'Total Stages': 'fa fa-signal',
      'Total Companies': 'fas fa-building',
      'Total Vehicle': 'fa fa-truck',
      'Total Process': 'fa fa-cog',
    },
  ];

  public lineChartData: ChartDataSets[] = [];

  plantprocesstat: any[] = [];
  bestPlantTAT: any[] = [];
  plantprocesstatcolors = [
    '#047654',
    '#497a69',
    '#002564',
    '#006580',
    '#00196a',
  ];
  bestPlantTATcolors = ['#dc3545', '#3f7ac7', '#28a745', '#ffc107', '#17a2b8'];
  allcompanySectors: any[] = [];
  allPlantOrders: any[] = [];
  vehicleList: any[] = [];
  plantTatList: any[] = [];
  sectorWisePlantData: any[] = [];
  reportType = ['Weekly', 'Monthly', 'Yearly'];
  tattype = this.reportType[0];
  defaultreportType: string = '';
  @ViewChild(DataTableDirective, {
    static: false,
  })
  CompanyElement: any;
  plantElement: any;
  CompanyTrigger: any = new Subject();
  PlantTrigger: any = new Subject();
  companyOptions: any = this.table.options(5, 3);
  PlantOptions: any = this.table.options(5, 3);
  tatdata: any[] = [];
  public lineChartLabels: Label[] = [];
  ngAfterViewInit() {
    this.CompanyTrigger.next();
    this.PlantTrigger.next();
  }
  ngOnDestroy(): void {
    this.CompanyTrigger.unsubscribe();
    this.PlantTrigger.unsubscribe();
  }
  renderCompanyTable() {
    this.CompanyElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.CompanyTrigger.next();
    });
  }

  renderPlantTable() {
    this.plantElement.dtInstance1.then((dtInstance1: any) => {
      dtInstance1.destroy();
      this.PlantTrigger.next();
    });
  }

  public lineChartColors: Color[] = [
    {
      // red
      backgroundColor: 'rgba(246, 78, 96, 0.5)',
      borderColor: '#f64e60',
      pointBackgroundColor: '#3f7ac7',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'TAT Hours',
            fontSize: 12,
            fontStyle: 'bold',
            fontFamily: ' sans-serif',
          },
        },
      ],
    },
    showLines: true,
    legend: {
      align: 'center',
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#3f7ac7',
        font: {
          weight: 'bold',
          size: 12,
        },
      },
    },
  };

  constructor(
    public workflow: DashboardService,
    public userService: UserService,
    public plantService: PlantService,
    public table: TableService,
    public common: CommonService,
    public api: ApiService
  ) {
    this.getVehicleCount();
    this.getPlantTat();
    this.getSectorWise();
    this.getDateWisePlantAvgTat();
  }

  public barChartColors: Color[] = [
    { backgroundColor: this.common.graphsecondary },
    { backgroundColor: this.common.graphsuccess },
    { backgroundColor: this.common.graphdanger },
  ];

  getallcommoncount() {
    this.workflow.getallcommoncount().subscribe((item: any) => {
      if (item.status) {
        this.allCompany = item.data ?? [];
        if (this.allCompany.length) {
          this.allCompany.map((i: any, index: number) => {
            (i.class = this.companyinfoColor[index]),
              (i.icon = this.companyinfoicons[0][i.name]);
          });
        }
      }
    });
  }

  getVehicleCount() {
    this.api.get('dashboard/get_vehicle_count_by_company').subscribe(
      (res: any) => {
        this.vehicleList = res.data;
      },
      (err: any) => {
        console.error('Error', err);
      }
    );
  }

  getPlantTat() {
    this.api.get('dashboard/get_plant_avg_tat_by_company').subscribe(
      (res: any) => {
        this.plantTatList = res.data;
      },
      (err: any) => {
        console.error('Error', err);
      }
    );
  }

  getSectorWise() {
    this.api.get('dashboard/get_sector_wise_plant').subscribe(
      (res: any) => {
        this.sectorWisePlantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
      }
    );
  }

  ngOnInit(): void {
    this.getallcommoncount();
  }

  gettatindex(i: number) {
    this.tatfilter = i == 0 ? 'Weekly' : i == 1 ? 'Monthly' : 'Yearly';
    this.getDateWisePlantAvgTat();
  }

  getDateWisePlantAvgTat() {
    this.lineChartData = [];
    this.lineChartLabels = [];
    this.defaultreportType = this.reportType[0];
    let filter =
      this.tatfilter == 'Weekly' ? 1 : this.tatfilter == 'Monthly' ? 2 : 3;
    this.api
      .get('dashboard/get_datewise_plant_avg_tat_by_company?range=' + filter)
      .subscribe((item: any) => {
        if (item.status) {
          this.tatdata = item.data ?? [];
          if (this.tatdata != null && this.tatdata.length) {
            let data: any[] = [];
            let labels: string[] = [];
            let index = 0;
            while (index <= this.tatdata[0].tats.length - 1) {
              let obj = { data: [], label: 'TAT', lineTension: 0 };
              this.tatdata.forEach((item: any) => {
                obj.data.push(Number(item.tats[index].tat));
                obj.label = item.tats[index].plant_name;
              });
              index++;
              this.lineChartData.push(obj);
              data = [];
            }
            this.tatdata.map((el: any) => {
              labels.push(el.date);
            });
            this.lineChartLabels = labels;
          }

        }
      });
  }
}

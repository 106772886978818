<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Alert Communication Mapping" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div *ngIf="role.user_role == 'AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"select_company" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectCompany($event)" [data]="companyList" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyNameValidator && companyNameValidator.error">
                    <span>{{companyNameValidator.msg}} </span>
                  </div>
                </div>
                <div *ngIf="role.user_role == 'AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"select_plant" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectPlant($event)" [data]="plantList" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{plantNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group test col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"Mapping On" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectMapping($event)" [data]="alertMapping" display="map_on"
                    placeholder="Choose ALert Mapping" [preSelected]="{map_on:mapOn}">
                  </ps-auto-suggestion>
                </div>
                <div class="form-group test col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"alert_name" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectAlertName($event)" [data]="alertList" display="alertTitle"
                    placeholder="Choose Alert Name" [preSelected]="{alertTitle:alertName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="alertNameValidator && alertNameValidator.error">
                    <span>{{alertNameValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group test col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"alert condition" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectAlertCondition($event)" [data]="alertConditionData" display="alert_condition"
                    placeholder="Choose Alert Condition" [preSelected]="{alert_condition:alertConditionName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="alertConditionValidator && alertConditionValidator.error">
                    <span>{{alertConditionValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group test col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"alert type" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectAlertType($event)" [data]="alertConditionType" display="alert_type"
                    placeholder="Choose Alert Type" [preSelected]="{alert_type:alertTypeName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="alertTypeValidator && alertTypeValidator.error">
                    <span>{{alertTypeValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12" *ngIf="mapOnId==1">
                  <div class="row yard_div">
                    <ng-container *ngFor="let item of dataMethodNameAction ; let j = index">
                      <div class="col-lg-12">
                        <div class="column_view">
                          <div class="box_column" [style]="item.border" for="my-input">
                            <div class="box mb-1 " *ngFor="let value of masterField; let i=index">
                              <ps-auto-suggestion class="custom_input" (onSelected)="selectCommunicationType($event,i)"
                                [data]="communicationTypeList" display="communicationTypeName"
                                placeholder="Select Communication Type "
                                [preSelected]="{communicationTypeName:masterField[i].communicationTypeName}">
                              </ps-auto-suggestion>
                              <ps-auto-suggestion class="custom_input"
                                (onSelected)="selectCommunicationSetting($event,i)" [data]="masterList[i].setting"
                                display="title" placeholder="Select Communication Setting"
                                [preSelected]="{title:masterField[i].communicationSettingName}">
                              </ps-auto-suggestion>
                              <ps-auto-suggestion class="custom_input"
                                (onSelected)="selectCommunicationTemplate($event,i)" [data]="masterList[i].template"
                                display="templateTitle" placeholder="Select Communication Template"
                                [preSelected]="{templateTitle:masterField[i].communicationTemplateName}">
                              </ps-auto-suggestion>
                              <div class="d-flex">
                                <button class="btn btn-danger remove mr-2" *ngIf="masterField.length > 1"
                                  (click)="masterField.splice(i,1);masterList.splice(i,1)">
                                  <i class="fa fa-trash"></i> </button>
                                <button class="btn btn-success add" *ngIf="i == masterField.length-1 && alertId"
                                  (click)="addValues(j)">
                                  <i class="fa fa-plus"></i> </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="form-group test col-xl-3 col-lg-6 col-sm-12"  *ngIf="mapOnId==0">
                  <label for="my-input" class="required">{{"alert method" | translate}} </label>
                  <ps-auto-suggestion class="custom_input" (onSelected)="selectedMethod($event)" [data]="methodsList" display="methodName" placeholder="Select Method"
                    [preSelected]="{methodName:methodName}"></ps-auto-suggestion>
                  <div class="error" *ngIf="alertMethodValidator && alertMethodValidator.error">
                    <span>{{alertMethodValidator.msg}} </span>
                  </div>
                </div>

                <div class="row form-group action-block">
                  <div class="btn-block">
                    <button class="btn btn-warning" (click)="reset()"> {{"reset" | translate}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btnTxt | translate}}
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive pt-4">
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{"s.no." | translate}}</th>
                          <th>{{"alert_name" | translate}}</th>
                          <th>{{"plant_name" | translate}}</th>
                          <th>{{"company_name" | translate}}</th>
                          <th>{{"condition name" | translate}}</th>
                          <th>{{"type name" | translate}}</th>
                          <th>{{"map on" | translate}}</th>
                          <th>{{("communication_type" | translate) + " "+( "name" | translate)}}</th>
                          <th>{{("communication_setting" | translate).toLowerCase() + " "+( "name" | translate)}}</th>
                          <th>{{'template_name'|translate}}</th>
                          <th>{{'method name'|translate}}</th>
                          <th class="action">{{"action" | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of alertCommunicationList;index as i">
                          <td>{{i+1}}</td>
                          <td> {{item.alertName}}</td>
                          <td>{{item.plantName?item.plantName : "-"}}</td>
                          <td> {{item.companyName ? item.companyName : "-"}}</td>
                          <td> {{item.alertConditionName}}</td>
                          <td> {{item.alertTypeName}}</td>
                          <td> {{item.mapOn == 0 ? "Method" : "Communication"}}</td>
                          <td><ng-container
                              *ngFor="let value of item.data;index as k">{{value.communicationTypeName+(item.data.length-1
                              == k ? "":",")}}</ng-container></td>
                          <td><ng-container
                              *ngFor="let value of item.data;index as k">{{value.communicationSettingName+(item.data.length-1
                              == k ? "":",")}}</ng-container></td>
                          <td><ng-container
                              *ngFor="let value of item.data;index as k">{{value.communicationTemplateName+(item.data.length-1
                              == k ? "":",")}}</ng-container></td>
                          <td><ng-container
                              *ngFor="let value of item.data;index as k">{{value.methodName}}</ng-container></td>
                          <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-primary" (click)="viewAlert(item)"> {{"view" | translate}}
                              </button>
                              <button class="btn btn-success" (click)="editAlert(item)"> {{"edit" | translate}}
                              </button>
                              <button class="btn btn-danger" (click)="confirmAlert(item)">{{"delete" |
                                translate}}</button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

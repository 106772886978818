import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import _ from 'lodash';
import { companyData, plantsData } from 'src/app/dataType/formInterface';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-visitor-card-field-mapping',
  templateUrl: './visitor-card-field-mapping.component.html',
  styleUrls: ['./visitor-card-field-mapping.component.scss']
})
export class VisitorCardFieldMappingComponent implements OnInit {

  companyId:number = null
  companyName:string = null
  plantId:number = null
  plantName:string = null
  companyData:companyData[] = []
  plantData:plantsData[] = []
  allFields: any[] = [];
  allItems: any[] = [];
  visitorCardMappedFields: any[]= [];
  tempFields: any[] = [];
  // mappedFields = {
  //   FIXED: [],
  //   REMOVABLE: [],
  // };
  selectedfieldIds:number[] = [];




  constructor(
    public userService: UserService,
    public common: CommonService,
    public api: ApiService,
    public modalService: NgbModal,
    public role: RoleService,
    public alert : AlertService,
    public breadcrumbService:BreadcrumbService,
    public plantService: PlantService,
  ) { }

  ngOnInit() {
    let breadcumbs = [
      { name: 'Visitor', url: null },
      {
        name: 'Visitor Card Template',
        link: null,
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.getVisitorFormFieldsList();
    this.getVisitorCardFieldsByPlantId();
  }

  getPlants(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlants(event.companyId);
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
  }

  getVisitorFormFieldsList() { // only FIXED type fields can be mapped on visitor card which is mapped to plant's default visitor form
    this.common.loading = true;
    let params = {
      formType : 'Visitor Form'
    }
    this.api.get('customForm/get-form-fields',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.allFields = res.data[0].fields;
        console.log("fields bfore", this.allFields);
        // first filterering it with mapping type FIXED
        this.allFields = this.allFields.filter(e => e.type === 'FIXED');
        console.log("fields", this.allFields);

        // Then Filtering it with selected items of visitor card
        if(this.visitorCardMappedFields.length){
          this.allFields = this.allFields.filter(
            (field) =>
              !this.visitorCardMappedFields.some(
                (mappedField) => mappedField.field_id == field.field_id
              )
          );
        }
        console.log("fields after selected", this.allFields);
        this.tempFields = _.cloneDeep(this.allFields);
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  changeStatus(item: any,key:string) {
    item[key] = !item[key];
}

  getVisitorCardFieldsByPlantId() {
    let params={
      plantId : this.plantId
    }
    this.common.loading = true;
    this.api.get('visitors/get-mapped-visitor-card-fields',params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.visitorCardMappedFields = res.data;
        console.log('this.visitorCardMappedFields: ', this.visitorCardMappedFields);
        this.visitorCardMappedFields.forEach(element => {
          this.selectedfieldIds.push(element.field_id);
          console.log('this.selectedfieldIds: ', this.selectedfieldIds);
        });
        this.getVisitorFormFieldsList();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  drop(event: CdkDragDrop<string[]>, id: string, from: string = '') {
    const draggedItem = event.item.data; // Access the dragged item
    // console.log(draggedItem,this.removedFieldsMappingId.indexOf(draggedItem.form_mapping_id));
    // if(this.removedFieldsMappingId.indexOf(draggedItem.form_mapping_id) != -1) this.removedFieldsMappingId.splice(this.removedFieldsMappingId.indexOf(draggedItem.form_mapping_id),1);
    let currIndex =
      from == 'left' ? event.currentIndex * 2 + 0 : event.currentIndex * 2 + 1;
    let preVindex =
      from == 'left'
        ? event.previousIndex * 2 + 0
        : event.previousIndex * 2 + 1;

    this.tempFields = this.tempFields.filter(
      (res) => this.allFields[event.previousIndex] != res
    );
    console.log('Hello', preVindex, currIndex,this.tempFields);

    if (event.previousContainer != event.container) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        currIndex
      );
    } else {
      console.log('Hello', preVindex, currIndex)[
        (this.allItems[preVindex], this.allItems[currIndex])
      ] = [this.allItems[currIndex], this.allItems[preVindex]];
    }
    console.log("allItems", this.allItems);
    const newFieldIds = this.allItems
    .map(item => parseInt(item.field_id))
    .filter(id => !this.selectedfieldIds.includes(id));
    this.selectedfieldIds.push(...newFieldIds);
      console.log('this.selectedfieldIds: ', this.selectedfieldIds);

  }

  removeFields(item: any, index: number) {
    let value = this.allItems[index];
    console.log(value);
    // this.removedFieldsMappingId.push(item.form_mapping_id);
    this.allFields.push(value);
    this.tempFields.push(value);
    this.allItems.splice(index, 1);
  }

  searchFieldName(event: any = '') {
    if (event.target.value.trim() != '') {
      this.allFields = this.tempFields.filter((field) =>
        field.field_name
          .toLowerCase()
          .includes(
            event.target.value.trim().toLowerCase() ||
              field.fieldName
                .toLowerCase()
                .includes(event.target.value.trim().toLowerCase())
          )
      );
    } else {
      this.allFields = this.tempFields;
    }
  }

  reset(){
    this.visitorCardMappedFields= [];
    this.allItems = [];
    this.selectedfieldIds = [];
    this.companyId = null;
    this.plantId = null;
    this.companyName = null;
    this.plantName = null;
    this.getVisitorFormFieldsList();
  }

  saveMapping(){
    let params = {
      fieldIds : this.selectedfieldIds,
      companyId: this.companyId,
      plantId:this.plantId,
    }
    this.api.post('visitors/save-visitor-card-fields-mapping',params).subscribe(
      (res: any) => {
        if(res.status){
          this.alert.success(res.message);
        }else{
          this.alert.info(res.message);
        }
        this.common.loading = false;
        this.reset();
        // this.getVisitorFormFieldsList();
        this.getVisitorCardFieldsByPlantId();
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

}

<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{"verify_visitor"| customTranslate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="body_section">
      <div class="otp_field" *ngIf="!isChecked">
        <div class="field">
          <input type="text" class="form-control" [(ngModel)]="otp" placeholder="Enter OTP" (ngModelChange)="otp.trim()==''?otp=null:null">
        </div>

        <div class="field text-right">
          <button *ngIf="!canResend" (click)="checkOtp()" >{{"verify"|customTranslate}}</button>
          <div *ngIf="canResend" class="resend_otp">{{"Didnt_recieve_a_code"|customTranslate}} <button class="resend" (click)="generateOtp()"> {{"resend_otp"| customTranslate}}</button></div>
          <div class="resend_otp" *ngIf="!canResend"> {{"otp_expires_in"| customTranslate}} {{getFormattedTime()}}</div>
        </div>
      </div>
  </div>
</div>









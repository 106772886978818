import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';

@Component({
  selector: 'app-vehicle-whitelist-blacklist',
  templateUrl: './vehicle-whitelist-blacklist.component.html',
  styleUrls: ['./vehicle-whitelist-blacklist.component.scss'],
})
export class VehicleWhitelistBlacklistComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(
    10,
    4,
    'Vehicle Restriction Allowance List'
  );
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  title = 'Add Vehicle Restriction';
  btn = 'save';
  data: any[] = [];

  restrictionScope: any = null;
  companyName: String = null;
  plantName: String = null;
  statusName: null;
  companyList: any = [];
  plantList: any = [];
  companyId: number = null;
  plantId: number = null;
  stageId: number = null;
  stageName: string = null;
  stageList: any[] = [];
  destinationList: any[] = [];
  vehicleList: any[] = [];
  whiteListedvehicleNo: string = null;
  whiteListedvehicleId: string = null;
  blackListedvehicleNo: string = null;
  blackListedvehicleId: string = null;
  whiteListVehicle: any[] = [
    {
      vehicleId: null,
      vehicleRsn: null,
    },
  ];
  blackListVehicle: any[] = [
    {
      vehicleId: null,
      vehicleRsn: null,
    },
  ];
  destinationData: any[] = [];
  stageData: any[] = [];
  editEnabled: boolean = false;
  existData = {
    whiteList: [],
    blackList: [],
  };
  editData = {
    companyId: -1,
    plantId: -1,
    stageId: -1,
    destinationId: -1,
  };
  restrictionVehicleTableData: any[] = [];

  constructor(
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public api: ApiService,
    public alertService: AlertService,
    public role: RoleService,
    private plantService: PlantService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Zone', url: null },
      {
        name: 'Vehicle Resctriction',
        link: '/user/vehicle-restriction-allowance',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  ngOnInit(): void {
    this.getVehicleList();
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
      this.restrictionScope = 'COMPANY';
      this.getAllVehicleRestrictionData();
    } else if (
      this.role.user_role == 'COMPANYADMIN' ||
      this.role.user_role == 'COMPANYUSER'
    ) {
      this.companyId = this.userService._loggedInUser.companyId;
      this.getPlantById(this.userService._loggedInUser.companyId);
      this.restrictionScope = 'COMPANY';
      this.getAllVehicleRestrictionData();
    } else {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
      this.restrictionScope = 'PLANT';
      this.getStagesByPlant();
      this.getAllVehicleRestrictionData();
    }
  }

  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        id: id,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.plantList = res.data;
            console.log('this.plantList: ', this.plantList);
            resolve(this.plantList);
          }
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = null;
    this.stageId = null;
    this.stageName = null;
    this.stageData = [];
    this.destinationData = [];
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getStagesByPlant();
    this.stageId = null;
    this.stageName = null;
    this.stageData = [];
    this.destinationData = [];
  }

  getStagesByPlant(): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = {
        plantId: this.plantId,
        allStage: false,
      };
      this.api.get('stage/get-all-stage', params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.stageList = res.data;
            resolve(true);
          }
        },
        (err: any) => {
          reject(false);
          this.common.loading = false;
          console.error('Error: ', err);
        }
      );
    });
  }

  getDestinationByStage(): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = {
        stageId: this.stageId,
      };
      this.api.get('destination/get_destination_by_stage_id', params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.destinationList = res.data;
            resolve(true);
          }
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
          reject(false);
        }
      );
    });
  }

  selectedStage(event: any) {
    if (this.restrictionScope == 'DESTINATION') {
      this.stageId = event.stageId;
      this.stageName = event.stageName;
      this.destinationData = [] ;
      this.getDestinationByStage();
    } else {
      this.stageData = event;
    }
  }

  selectedDestination(event: any) {
    this.destinationData = event;
  }

  getVehicleList() {
    this.common.loading = true;
    this.api.get('vehicle/get-all-vehicles').subscribe(
      (res: any) => {
        this.vehicleList = res.data;
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedRestriction(scope: any) {
    scope = scope.target.value;
    if (scope == 'COMPANY' && this.role.user_role == 'AXESTRACK') {
      this.plantId = null;
      this.plantName = null;
      this.plantList = null;
    } else if (
      scope == 'PLANT' &&
      this.role.user_role != 'PLANTADMIN' &&
      this.role.user_role != 'PLANTUSER'
    ) {
      this.stageList = null;
      this.stageId = null;
      this.stageName = null;
      this.stageData = [];
    } else if (scope == 'STAGE') {
      this.stageId = null;
      this.stageName = '';
      this.stageData = [];
      this.destinationData = [];
      this.destinationList = [];
    } else if (scope == 'DESTINATION') {
      this.stageData = [];
    }
    this.restrictionScope = scope;
  }

  selectVehicle(event: any, type: string, restrictionType: string) {
    let vehicleNo;
    let vehicleId = null;
    if (type == 'select') {
      vehicleNo = event.vehicle_rsn;
      vehicleId = event.vehicle_id;
    } else {
      vehicleNo = event;
    }
    if (restrictionType == 'WHITELISTED') {
      this.whiteListedvehicleId = vehicleId;
      this.whiteListedvehicleNo = vehicleNo;
    } else {
      this.blackListedvehicleNo = vehicleNo;
      this.blackListedvehicleId = vehicleId;
    }
  }

  updateVehicleOnInput(index: number, type: string, event: any) {
    if (type == 'WHITELISTED') {
      if (event.length > 6 && this.existData.blackList.includes(event)) {
        return this.alertService.warn('Vehicle Present in BlackList');
      }
      if (event.length > 6 && this.existData.whiteList.includes(event)) {
        return this.alertService.warn('Vehicle Already Present in Whitelist');
      }
      this.whiteListVehicle[index].vehicleId = null;
      this.whiteListVehicle[index].vehicleRsn = event;
      if (event.length > 6) this.existData.whiteList[index] = event;
    } else {
      if (event.length > 6 && this.existData.whiteList.includes(event)) {
        return this.alertService.warn('Vehicle Present in WhiteList');
      }
      if (event.length > 6 && this.existData.blackList.includes(event)) {
        return this.alertService.warn('Vehicle Already Present in Blacklist');
      }
      this.blackListVehicle[index].vehicleId = null;
      this.blackListVehicle[index].vehicleRsn = event;
      if (event.length > 6) this.existData.blackList[index] = event;
    }
  }

  updateVehicle(index: number, type: string, event: any) {
    if (type == 'WHITELISTED') {
      if (this.existData.blackList.includes(event.vehicle_rsn)) {
        this.whiteListVehicle[index].vehicleId = null;
        this.whiteListVehicle[index].vehicleRsn = null;
        return this.alertService.warn('Vehicle Present in BlackList');
      }
      if (this.existData.whiteList.includes(event.vehicle_rsn)) {
        this.whiteListVehicle[index].vehicleId = null;
        this.whiteListVehicle[index].vehicleRsn = null;
        return this.alertService.warn('Vehicle Already Present in Whitelist');
      }
      this.whiteListVehicle[index].vehicleId = event.vehicle_id;
      this.whiteListVehicle[index].vehicleRsn = event.vehicle_rsn;
      if (event.vehicle_rsn != null)
        this.existData.whiteList.push(event.vehicle_rsn);
    } else {
      if (this.existData.whiteList.includes(event.vehicle_rsn)) {
        this.blackListVehicle[index].vehicleId = null;
        this.blackListVehicle[index].vehicleRsn = null;
        return this.alertService.warn('Vehicle Present in WhiteList');
      }
      if (this.existData.blackList.includes(event.vehicle_rsn)) {
        this.blackListVehicle[index].vehicleId = null;
        this.blackListVehicle[index].vehicleRsn = null;
        return this.alertService.warn('Vehicle Already Present in Blacklist');
      }
      this.blackListVehicle[index].vehicleId = event.vehicle_id;
      this.blackListVehicle[index].vehicleRsn = event.vehicle_rsn;
      if (event.vehicle_rsn != null)
        this.existData.blackList.push(event.vehicle_rsn);
    }
  }

  addVehicle(type: string) {
    if (type == 'WHITELISTED') {
      this.whiteListVehicle.push({
        vehicleId: null,
        vehicleRsn: null,
      });
    } else {
      this.blackListVehicle.push({
        vehicleId: null,
        vehicleRsn: null,
      });
    }
  }

  removeVehicle(index: number, type: string) {
    if (type == 'WHITELISTED') {
      this.whiteListVehicle.splice(index, 1);
      this.existData.whiteList.splice(index, 1);
    } else {
      this.blackListVehicle.splice(index, 1);
      this.existData.blackList.splice(index, 1);
    }
  }

  saveVehicleRestriction() {
    this.common.loading = true;
    let whitelistData = {
      existVehicle: [],
      newVehicle: [],
    };
    let blacklistData = {
      existVehicle: [],
      newVehicle: [],
    };
    this.whiteListVehicle.forEach((data) => {
      if (data.vehicleId != null)
        whitelistData.existVehicle.push(data.vehicleId);
      else if (data.vehicleRsn != null)
        whitelistData.newVehicle.push(data.vehicleRsn);
    });
    this.blackListVehicle.forEach((data) => {
      if (data.vehicleId != null)
        blacklistData.existVehicle.push(data.vehicleId);
      else if (data.vehicleRsn != null)
        blacklistData.newVehicle.push(data.vehicleRsn);
    });
    let params = {
      plantId: this.restrictionScope == 'COMPANY' ? null : this.plantId,
      companyId: this.companyId,
      restrictionScope: this.restrictionScope,
      restriction_data: {
        WHITELIST: whitelistData.existVehicle,
        BLACKLIST: blacklistData.existVehicle,
      },
      newVehicle_restriction_data: {
        WHITELIST: whitelistData.newVehicle,
        BLACKLIST: blacklistData.newVehicle,
      },
      stageIds:
        this.restrictionScope == 'DESTINATION'
          ? [this.stageId]
          : this.stageData.map((item) => item.stageId),
      destinationIds: this.destinationData.map(
        (item) => item.stage_destination_code_id
      ),
      deleteData: this.editEnabled ? this.editData : null,
    };

    this.api.post('vehicle/save-vehicle-restriction', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alertService.success(res.message);
        this.getAllVehicleRestrictionData();
        this.resetDetail();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllVehicleRestrictionData() {
    let params = {
      plantId: this.plantId,
      companyId: this.companyId,
      userRole: this.role.user_role,
    };
    this.api.get('vehicle/get-vehicle-restriction-list', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.restrictionVehicleTableData = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  deleteVehicleRestrictionData(row: any) {
    this.editData.companyId = row.company_id;
    this.editData.plantId = row.plant_id == null ? -1 : row.plant_id;
    this.editData.stageId = row.stage_id == null ? -1 : row.stage_id;
    this.editData.destinationId =
      row.stage_destination_code_id == null
        ? -1
        : row.stage_destination_code_id;
    let params = {
      deleteData: this.editData,
      restrictionScope: 'DELETED',
    };
    this.api.post('vehicle/save-vehicle-restriction', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.getAllVehicleRestrictionData();
          this.resetDetail();
          this.renderTable();
          this.alertService.success('Data Deleted SuccessFully!!');
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Vehicle Restrction',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteVehicleRestrictionData(row);
      }
    });
  }

  async resetDetail() {
    this.btn = 'save';
    if (this.role.user_role == 'AXESTRACK') {
      this.restrictionScope = 'COMPANY';
      this.plantId = null;
      this.plantName = null;
      this.plantList = null;
    }
     if (
      this.role.user_role == 'COMPANYADMIN' ||
      this.role.user_role == 'COMPANYUSER' || this.role.user_role == 'AXESTRACK'
    ) {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = null;
      this.plantName = null;
      this.plantList = null;
      await this.getPlantById(this.userService._loggedInUser.companyId);
      this.restrictionScope = 'COMPANY';
    } else {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
      this.restrictionScope = 'PLANT';
      await this.getStagesByPlant();
    }
    this.whiteListVehicle = [
      {
        vehicleId: null,
        vehicleRsn: null,
      },
    ];
    this.blackListVehicle = [
      {
        vehicleId: null,
        vehicleRsn: null,
      },
    ];
    this.whiteListedvehicleId = null;
    this.whiteListedvehicleNo = null;
    this.blackListedvehicleId = null;
    this.blackListedvehicleNo = null;
    this.editEnabled = false;
    this.existData = {
      whiteList: [],
      blackList: [],
    };
    this.editData = {
      companyId: -1,
      plantId: -1,
      stageId: -1,
      destinationId: -1,
    };
    this.destinationData = [];
    this.stageData = [];
    this.editEnabled = false;
  }

  async editVehicleRestriction(row?: any) {
    await this.resetDetail();
    this.editData.companyId = row.company_id;
    this.editData.plantId = row.plant_id == null ? -1 : row.plant_id;
    this.editData.stageId = row.stage_id == null ? -1 : row.stage_id;
    this.editData.destinationId =
      row.stage_destination_code_id == null
        ? -1
        : row.stage_destination_code_id;
    this.editEnabled = true;
    this.restrictionScope = row.restriction_scope;
    this.companyId = row.company_id;
    this.companyName = row.company_name;

    if (
      this.restrictionScope == 'PLANT' &&
      (this.role.user_role == 'COMPANYADMIN' ||
        this.role.user_role == 'COMPANYUSER')
    ) {
      await this.getPlantById(this.companyId);
    }
    this.plantId = row.plant_id;
    this.plantName = row.plant_name;

    if (
      this.restrictionScope == 'STAGE' ||
      this.restrictionScope == 'DESTINATION'
    ) {
      await this.getStagesByPlant();
      this.stageData = [{ stageName: row.stage_name, stageId: row.stage_id }];
      this.stageId = row.stage_id;
      this.stageName = row.stage_name;
    }
     if (this.restrictionScope == 'DESTINATION') {
      await this.getDestinationByStage();

    this.destinationData = [
      {
        stage_destination_code: row.stage_destination_code,
        stage_destination_code_id: row.stage_destination_code_id,
      },
    ];
  }
    this.whiteListVehicle =
      row.whitelist_vehicle_data.length == 0
        ? [
            {
              vehicleId: null,
              vehicleRsn: null,
            },
          ]
        : row.whitelist_vehicle_data;
    this.blackListVehicle =
      row.blacklist_vehicle_data.length == 0
        ? [
            {
              vehicleId: null,
              vehicleRsn: null,
            },
          ]
        : row.blacklist_vehicle_data;

    this.existData.whiteList = this.whiteListVehicle.map(
      (item) => item.vehicleRsn
    );
    this.existData.blackList = this.blackListVehicle.map(
      (item) => item.vehicleRsn
    );
  }

  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'info' };

    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  saveValidator() {
    if (this.role.user_role == 'AXESTRACK' && this.companyId == null)
      return this.alertService.warn('Company is not selected');
    if (
      (this.role.user_role == 'COMPANYADMIN' ||
        this.role.user_role == 'COMPANYUSER' ||
        this.role.user_role == 'AXESTRACK') &&
      this.plantId == null &&
      this.restrictionScope != 'COMPANY'
    )
      return this.alertService.warn('Plant is not selected');
    if (this.restrictionScope == 'STAGE' && this.stageData.length == 0)
      return this.alertService.warn('Stage is not selected');
    if (this.restrictionScope == 'DESTINATION' && this.stageId == null)
      return this.alertService.warn('Stage is not selected');
    if (
      this.restrictionScope == 'DESTINATION' &&
      this.destinationData.length == 0
    )
      return this.alertService.warn('Destination is not selected');
    if (
      this.whiteListVehicle.length == 1 &&
      this.whiteListVehicle[0].vehicleRsn == null &&
      this.blackListVehicle.length == 1 &&
      this.blackListVehicle[0].vehicleRsn == null
    )
      return this.alertService.warn(
        'No Vehicle Selected for WhiteListing or BlackListing'
      );
    this.saveVehicleRestriction();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { ProcessService } from '../process/process.service';
import { CommonService } from '../../@core/services/common.service';
import { TableService } from '../../@core/services/table.service';
import { ApiService } from '../../@core/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-parking-creation',
  templateUrl: './parking-slot-management.component.html',
  styleUrls: ['./parking-slot-management.component.scss'],
})
export class ParkingCreationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(
    10,
    4,
    'Stage Destination Code Occupancy List'
  );
  process: any[] = [];
  selectedOrderId: number = null;
  selectedOrderName: string = null;
  parkingSlotsCountByProcessStage: any[] = [];
  editButton: boolean = false;
  btn = 'save';
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  slotNumbers: number = null;
  slotTat: any = '';
  constructor(
    public breadcrumbService: BreadcrumbService,
    private processService: ProcessService,
    public common: CommonService,
    public table: TableService,
    private api: ApiService,
    private modalService: NgbModal,
    private alert: AlertService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Plant', url: null },
      { name: 'Slot Parking Management', link: '/user/parking-creation' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }
  ngOnInit(): void {
    this.getAllProcess();
    this.getParkingSlotsCountByProcessStage();
  }

  getAllProcess() {
    this.common.loading = true;
    this.processService.getAllProcessStage().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.process = res.data.filter(
          (data) => data.stage_type_name == 'Parking'
        );
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedProcess(event: any) {
    this.selectedOrderId = event.order_id;
    this.selectedOrderName = event.order_name;
  }

  getParkingSlotsCountByProcessStage() {
    this.common.loading = true;
    this.api.get('parking/get_parking_slots_count_by_process_stage').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.parkingSlotsCountByProcessStage = res.data;
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  view(row?: any) {
    let newObj = {
      process_name: row.process_name,
      count: row.count,
      stage_name: row.stage_name,
    };
    this.common.params = {
      details: [newObj],
      title: 'Number of Slot Parking Details',
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  edit(row: any) {
    this.btn = 'update';
    this.editButton = true;
    this.selectedOrderId = row.process_stage_order_id;
    this.selectedOrderName = row.process_name + '->' + row.stage_name;
    this.slotNumbers = row.count;
    this.slotTat = row.tat.substring(0, 5);
  }

  reset() {
    this.btn = 'save';
    this.processStageValidator = null;
    this.slotNumberValidator = null;
    this.slotTatValidator = null;
    this.selectedOrderId = null;
    this.selectedOrderName = null;
    this.slotNumbers = null;
    this.slotTat = null;
    this.editButton = false;
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Slot Parking',
      description:
        `<b>&nbsp;` + 'Are you sure you want to delete Slots?' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteParkingSlots(row);
      }
    });
  }

  deleteParkingSlots(row: any) {
    this.common.loading = true;
    this.api
      .get(
        'parking/delete_parking_slots?processStageOrderId=' +
          row.process_stage_order_id
      )
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.getParkingSlotsCountByProcessStage();
            if (res.data[0] == 'Slots Parking deleted Successfully') {
              this.alert.info(res.data[0]);
            } else {
              this.alert.error(res.data[0]);
            }
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  save() {
    let params = {
      processStageOrderId: this.selectedOrderId,
      slotNumbers: this.slotNumbers,
      slotsTat: this.slotTat,
    };
    let apiUrl = 'parking/edit_parking_slots';
    this.common.loading = true;
    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if (this.editButton == true) {
            if (
              res.data[0].isSuccess == true

            ) {
              this.alert.info(res.data[0].message);
            } else {
              this.alert.error(res.data[0].message);
            }
          } else {
            this.alert.info('Slots Created Successfully...');
          }
          this.reset();
          this.getParkingSlotsCountByProcessStage();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  processStageValidator: any = null;
  slotNumberValidator: any = null;
  slotTatValidator: any = null;
  formValidator() {
    this.slotNumberValidator = this.common.nullValidator(
      this.slotNumbers,
      'Number of Slots Mandatory'
    );
    this.processStageValidator = this.common.nullValidator(
      this.selectedOrderId,
      'Process -> Stage Mandatory'
    );
    this.slotTatValidator = this.common.nullValidator(
      this.slotTat,
      'Slots TAT Mandatory'
    );
    if (
      !this.slotNumberValidator.error &&
      !this.processStageValidator.error &&
      !this.slotTatValidator.error
    ) {
      this.save();
    }
  }
}

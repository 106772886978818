<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">Driver Navigation</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div id="map" style="width: 100%; height:75vh">
  </div>
</div>
<div class="modal-footer">
</div>

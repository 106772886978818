
import { ChartOptions } from 'chart.js';

export const StaticChartOptions:ChartOptions={
        responsive: true,
        title: {
          display: true,
          text: 'Book Value Over Years'
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'year',
              displayFormats: {
                year: 'YYYY'
              }
            },
            ticks: {
              source: 'data'
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Book Value',
              fontSize: 12,
              fontStyle: 'bold',
              fontFamily: ' sans-serif',
            },
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">Visitor Reports</h3>
              </div>
            </div>

            <div class="card-body">
              <div class="tab_section">
                <div class="tab" *ngFor="let tabitem of tabData" [class.active]="activeSection == tabitem.value"
                  (click)="activeSection = tabitem.value">
                  <p>{{tabitem.tabname}}</p>
                </div>
              </div>

              <section class="open_sec" [hidden]="activeSection != 'visitor'">
                <div class="form_sec">
                  <div class="row">
                    <div class="col-md-3">
                      <input type="name" class="form-control" placeholder="Visitor Number" [(ngModel)]="contact_no"
                        [value]="contact_no">
                    </div>
                    <div class="col-md-2">
                      <button type="button" (click)="getVisitorReport(0)"> <i class="fa fa-search"></i> </button>
                    </div>
                  </div>
                </div>

                <div class="tbl_sec">
                  <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead class="thead-light">
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Meeth With</th>
                        <!-- <th>Department</th> -->
                        <th>Meeting Type</th>
                        <th>In Time</th>
                        <th>Out Time</th>
                        <th>Status</th>
                        <th>Meeting Start Time</th>
                        <th>Meeting End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of visitorList let i = index">
                        <td>{{ i+1 }}</td>
                        <td>{{row.name}}</td>
                        <td>{{row.contact_no}}</td>
                        <td>{{row.meet_with}}</td>
                        <td>General</td>
                        <!-- <td>{{tbldata.department}}</td> -->
                        <td>{{row.start_time | date :'short'}}</td>
                        <td>{{row.end_time | date :'short'}}</td>
                        <td class="status_class">
                          <span class="fa" [ngClass]="{
                            'fa-check class_green': row.stage_workflow_status === 'COMPLETED',
                            'fa-times class_red': row.stage_workflow_status === 'REJECTED',
                            'fa-times class_black': row.stage_workflow_status === 'CANCELLED',
                            'fa-user class_orange': row.stage_workflow_status === 'INPROGRESS'}">
                            {{row.stage_workflow_status}}
                          </span>
                        </td>
                        <td>{{row.meeting_start_time | date :'short'}}</td>
                        <td>{{row.meeting_end_time | date :'short'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <section class="open_sec" [hidden]="activeSection != 'employee'">
                <app-visitor-report-by-emp-no></app-visitor-report-by-emp-no>
              </section>

              <section class="open_sec" [hidden]="activeSection != 'type'">
                <div class="form_sec">
                  <div class="row">
                    <div class="col-md-3">
                      <select class="form-control" [(ngModel)]="selectedOption">
                        <option *ngFor="let ddata of dropdownData" [value]="ddata.value">{{ddata.type}}</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <button type="button"> <i class="fa fa-search"></i> </button>
                    </div>
                  </div>
                </div>

                <div class="tbl_sec" *ngIf="selectedOption == 'meeting'">
                  <table class="custom-table">
                    <thead class="thead-light">
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Gender</th>
                        <th>Meeth With</th>
                        <th>Department</th>
                        <th>Meeting Type</th>
                        <th>In Time</th>
                        <th>Out Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div class="tbl_sec" *ngIf="selectedOption == 'meetwith'">
                  <table class="custom-table">
                    <thead class="thead-light">
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Meeth With</th>
                        <th>Department</th>
                        <th>Meeting Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

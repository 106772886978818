import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';

@Component({
  selector: 'app-visitor-report-by-emp-no',
  templateUrl: './visitor-report-by-emp-no.component.html',
  styleUrls: ['./visitor-report-by-emp-no.component.scss'],
})
export class VisitorReportByEmpNoComponent implements OnInit {
  activeSection: string = 'employee';
  selectedOption: any = 'meeting';
  visitorListByEmpNo: any = [];
  mobile_number: any = null;

  constructor(
    public table: TableService,
    public common: CommonService,
    public api: ApiService,
    public modalService: NgbModal
  ) {}

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'VISITOR LIST');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  tabData: any = [
    { tabname: 'By Visitor', value: 'visitor' },
    { tabname: 'By Employee', value: 'employee' },
    { tabname: 'By Type', value: 'type' },
  ];

  dropdownData: any = [
    { type: 'Meeting Type', value: 'meeting' },
    { type: 'Meet With', value: 'meetwith' },
  ];

  ngOnInit(): void {
    this.getVisitorReportByEmpNo(1);
  }

  getVisitorReportByEmpNo(flag: any) {
    console.log(this.mobile_number);
    let params: any;
    if (flag == 1) {
      params = {
        mobile_number: null,
      };
    } else {
      if (this.mobile_number == '') {
        params = {
          mobile_number: null,
        };
      } else {
        params = {
          mobile_number: this.mobile_number,
        };
      }
    }
    this.common.loading = true;
    this.api
      .post('api/get-visitor-report-by-emp-no', params, null, 'VMS')
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.visitorListByEmpNo = res;
          this.renderTable();
          console.log('visitor report by emp no', this.visitorListByEmpNo);
        },
        (err: any) => {
          console.error('Error:', err);
          this.common.loading = false;
        }
      );
  }
}

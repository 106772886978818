<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Slot Parking Management" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"process->stage" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="process" display="order_name"
                    placeholder="Choose Process" [preSelected]="{order_name:selectedOrderName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processStageValidator && processStageValidator.error">
                    <span>{{processStageValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"no_of_parking_slots" | customTranslate}} </label>
                  <input type="number" min="0" class="field_class" [(ngModel)]="slotNumbers">
                  <div class="error" *ngIf="slotNumberValidator && slotNumberValidator.error">
                    <span>{{slotNumberValidator.msg}} </span>
                  </div>
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"tat" | customTranslate}} </label>
                  <input type="time" class="field_class" [(ngModel)]="slotTat">
                  <div class="error" *ngIf="slotTatValidator && slotTatValidator.error">
                    <span>{{slotTatValidator.msg}} </span>
                  </div>
                </div>

              </div>
              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="reset()"> {{"reset" | customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}}
                  </button>
                </div>
              </div>
            </div>



          </div>

        </div>
      </div>





      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{("no_of_parking_slots" | customTranslate) +" "+("list"|customTranslate)}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"s.no." | customTranslate}} </th>
                    <th>{{"process" | customTranslate}} </th>
                    <th>{{"stage" | customTranslate}} </th>
                    <th>{{"no_of_parking_slots" | customTranslate}} </th>
                    <th class="action">{{"action" | customTranslate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of parkingSlotsCountByProcessStage let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.process_name}}</td>
                    <td>{{row.stage_name}}</td>
                    <td>{{row.count}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="view(row)"> {{"view" | customTranslate}} </button>
                        <button class="btn btn-success" (click)="edit(row)"> {{"edit" | customTranslate}} </button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)"> {{'delete'|customTranslate}} </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

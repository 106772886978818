import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class TmgDashboardService extends ApiService {
  currentProcess: string = '';
  dashboardProcess: any = [];
  currentProcessIndex: number = 0;

  getBestVendors(filter: any): Observable<any> {
    let apiSubUrl = 'api/vendor-wise-successfull-trip';

    return super.get(apiSubUrl, filter);
  }

  getcompleteVscanceled(params: any) {
    let apiSubUrl = 'api/complete_vs_cancelled_trips_weekly';
    return super.get(apiSubUrl, params);
  }

  getWorstTrip(params: any) {
    let apiSubUrl = 'api/worst_trip_status';
    return super.get(apiSubUrl, params);
  }

  getPlannedeTat(params: any) {
    let apiSubUrl = 'api/tat_vs_planned_weekly';
    return super.get(apiSubUrl, params);
  }

  gettripstatusDaywise(params: any) {
    let apiSubUrl = 'api/trips_status_day_wise';
    return super.get(apiSubUrl, params);
  }

  gettripStatus(params: any) {
    let apiSubUrl = 'api/trips_status';
    return super.get(apiSubUrl, params);
  }
  getAllProcess(): Observable<any> {
    let apiSubUrl = 'process/get-all-process?processActive=true';
    return super.get(apiSubUrl);
  }

  getWorstTransporterTripPerVehicle(filter: any): Observable<any> {
    let apiSubUrl = 'api/worst-transporter';
    return super.get(apiSubUrl, filter);
  }

  getWorstTransporterTripWise(filter: any): Observable<any> {
    let apiSubUrl = 'api/worst-transporter-trip';
    return super.get(apiSubUrl, filter);
  }

  // getWorstTransporterLoadWise(filter: any) : Observable < any >{
  //   let apiSubUrl = "api/worst-transporter-trip";
  //   return super.get(apiSubUrl, filter);
  // }

  getWorstTransporterTAT(filter: any): Observable<any> {
    let apiSubUrl = 'api/vendor-wise-tat';
    return super.get(apiSubUrl, filter);
  }

  //   stage dashboard apis

  getWeekyByWeekStageTat(param: any) {
    let apiSubUrl = 'api/week_over_week_highest_drop_stage_tat';
    return super.get(apiSubUrl, param);
  }

  getMonthWiseTatTrend(param: any) {
    let apiSubUrl = 'api/month-wise_stage_tat_trend';
    return super.get(apiSubUrl, param);
  }

  getConsistentStageTat(param: any) {
    let apiSubUrl = 'api/least_consistent_stage_tat_wise';
    return super.get(apiSubUrl, param);
  }

  getStageTatVariation(param: any) {
    let apiSubUrl = 'api/stage_tat_variation';
    return super.get(apiSubUrl, param);
  }

  getWorstStageTatCalc(param: any) {
    let apiSubUrl = 'api/worst_three_stage_planned_vs_actual';
    return super.get(apiSubUrl, param);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-next-stage',
  templateUrl: './next-stage.component.html',
  styleUrls: ['./next-stage.component.scss'],
})
export class NextStageComponent implements OnInit {
  nextStageId: any = null;
  @Input() public refData;
  constructor(
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.nextStageId = this.refData.active_next_stage_id;
  }

  closeModal(response?: any, apiHit?: any, nextStageId?: any) {
    this.activeModal.close({
      response: response,
      apiHit: apiHit,
      nextStageId: nextStageId,
    });
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
  @Input() stepCount: number = 60;
  @Input() defaultValue: any = '00:00:00';
  @Output() onChange = new EventEmitter();
  showPicker: boolean = false;
  hours: string[] = [];
  mins: string[] = [];
  seconds: string[] = [];
  hrsLabelArr: any[] = [];
  minLabelArr: any[] = [];
  secLabelArr: any[] = [];
  hrsActiveIndex: number = 0;
  minActiveIndex: number = 0;
  secActiveIndex: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.createPicker();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (
      changes.defaultValue.currentValue != changes.defaultValue.previousValue
    ) {
      this.defaultValue = changes.defaultValue.currentValue;
      this.createPicker();
    }
  }

  createPicker() {
    let [hour, min, sec] = this.defaultValue.split(':');
    let hours = Math.floor(this.stepCount / 3600);
    let minutes = Math.floor((this.stepCount % 3600) / 60);
    let seconds = (this.stepCount % 3600) % 60;
    for (let index = 0; index < 60; index++) {
      if (index < 24 && (index % hours == 0 || hours == 0)) {
        if (hour && parseInt(hour) == index) {
          this.hrsActiveIndex = this.hrsLabelArr.length;
        }
        this.hrsLabelArr.push(String(index).padStart(2, '0'));
      }
      if (index % minutes == 0 || minutes == 0) {
        if (min && parseInt(min) == index) {
          this.minActiveIndex = this.minLabelArr.length;
        }
        this.minLabelArr.push(String(index).padStart(2, '0'));
      }
      if (index % seconds == 0) {
        if (sec && parseInt(sec) == index) {
          this.secActiveIndex = this.secLabelArr.length;
        }
        this.secLabelArr.push(String(index).padStart(2, '0'));
      }
    }
  }

  emitOutput() {
    this.onChange.emit(
      `${this.hrsLabelArr[this.hrsActiveIndex]}:${
        this.minLabelArr[this.minActiveIndex]
      }${
        this.secLabelArr.length
          ? `:${this.secLabelArr[this.secActiveIndex]}`
          : ``
      }`
    );
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  Visitor Checklist Dashboard
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="top_row">
                <button *ngIf="processStage?.is_on_first_stage" (click)="openAddVisitor()">
                  <i class="fa fa-plus" aria-hidden="true"></i> Add New Visit
                </button>
                <button *ngIf="movableDiv" style="background: #013e90; color: white;" (click)="show('OUT')">
                  <i class="fa fa-caret-right" aria-hidden="true"></i>
                </button>
              </div>

              <div class="checklist_section">
                <div class="row" style="padding-left: 5px; padding-left: 5px;">
                  <div class="col-md-4 p-0 ">
                    <div class="left_sec">
                      <h6 style="background-color: #ecebeb;border-radius: 5px;padding:2%">
                        <div>
                          <i class="fa search_vehicle" matTooltip="Search Visitor" matTooltipPosition="below"
                            (click)="activeSearchBar = !activeSearchBar;searchVisitor(null)"
                            [ngClass]="activeSearchBar ? 'fa-close':'fa-search'"></i>
                          <span *ngIf="!activeSearchBar">{{'Search Visitor'|translate}}</span><i class="fa fa-sync"
                            aria-hidden="true" matTooltip="Refresh List" matTooltipPosition="below"
                            style="font-size: 18px; float: right; padding-top: 3px; padding-right: 8px;"
                            (click)="show('IN');activeIndex=-1"></i>
                        </div>
                        <div>
                          <input class="search_input" name="inVehicleSearch" id="inVehicleSearch" type="text"
                            (keyup)="searchVisitor($event)" *ngIf="activeSearchBar" placeholder="Vistor Name" />
                        </div>
                      </h6>
                      <div class="checklist_cards">
                        <ng-container *ngFor="let visit of tempList; let i = index">

                          <div class="single_card"
                            [class.active]="activeIndex == visit.mapping_id"
                            (click)="onVisitorClick(visit)">
                            <div class="show_data">
                              <div class="start">
                                <div class="image"
                                  (click)="displayImage(visit.visitor_image?visit.visitor_image:'assets/images/user_whatsapp.jpg')">
                                  <img [src]="visit.visitor_image?visit.visitor_image:'assets/images/user_whatsapp.jpg'"
                                    style="aspect-ratio: 1 / 1 !important;">
                                </div>

                                <button *ngIf="visit.visitor_status=='IN'" class="verified_btn">
                                  <i class="fa fa-check completed"></i>
                                </button>
                                <button *ngIf="visit.visitor_status=='BOOKED'" class="booked">Booked</button>
                                <button *ngIf="visit.visitor_status=='APPROVED'" class="approved">APPROVED
                                </button>
                              </div>

                              <div class="mid">
                                <div class="name">{{(visit.name?visit.name:"")}}</div>
                                <div class="to"><strong>To</strong> : {{visit.user_name}}</div>
                              </div>

                              <div class="end">
                                <div class="time"><i class="fa fa-clock"></i>
                                  <span>{{ visit.start_time | date: 'dd-MM-yyyy' }}</span>
                                  <br>
                                  <span>{{ visit.start_time | date: 'hh:mm a' }}</span>

                                </div>
                                <div class="last_bottom">
                                  <div class="stage_name">Stage: {{visit.stage_name?visit.stage_name:'-'}}</div>
                                  <div class="ellipsis_sec">
                                    <ng-container
                                      *ngTemplateOutlet="ellipseContainer; context: { $implicit: visit, index: i }">
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="hide_data" *ngIf="activeIndex==visit.mapping_id">
                              <div class="purpose"><strong>Purpose</strong> : {{visit.purpose}}</div>
                              <div class="mobile"><strong>Phone Number</strong> : {{visit.mobile_number}}</div>
                            </div>
                          </div>

                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5 p-0">
                    <div class="right_sec">
                      <div class="gif_icon" *ngIf="currentVisitor == null; else visitPannel;">
                        <img src="assets/images/please_select.gif">
                      </div>
                      <ng-template #visitPannel>
                        <div class="confirmation_sec p-0">
                          <ng-container
                            *ngTemplateOutlet="currentVisitor?.visitor_status == 'APPROVED' || (isStepperVisible && currentVisitor?.visitor_status == 'IN') ? stepper : actionListTemp">
                          </ng-container>
                        </div>
                      </ng-template>
                    </div>


                  </div>

                  <div class="col-md-3">

                    <app-compliance [refData]="currentVisitorCompliance?currentVisitorCompliance: null"></app-compliance>

                  <div class="modal_sec" *ngIf="!movableDiv">
                    <div class="modal_div ">
                      <i class="fa fa-times" (click)="movableDiv = !movableDiv; activeIndex = -1">
                      </i>
                      <div class="checklist_section">

                        <div class="row">
                          <div class="col-md-12">
                            <div class="left_sec">
                              <div class="checklist_cards">

                                <ng-container *ngFor="let visit of outVisitorDetails; let i = index">

                                  <div class="single_card"
                                    [class.active]="activeIndex == visit.mapping_id"
                                    (click)="activeIndex=visit.mapping_id;">
                                    <div class="show_data">
                                      <div class="start">
                                        <div class="image"
                                          (click)="displayImage(visit.visitor_image?visit.visitor_image:'assets/images/user_whatsapp.jpg')">
                                          <img
                                            [src]="visit.visitor_image?visit.visitor_image:'assets/images/user_whatsapp.jpg'"
                                            style="aspect-ratio: 1 / 1 !important;">
                                        </div>
                                      </div>

                                      <div class="mid">
                                        <div class="name">{{(visit.name?visit.name:"")}}</div>
                                        <div class="to"><strong>To</strong> : {{visit.user_name}}</div>
                                      </div>

                                      <div class="end">
                                        <div class="time"><i class="fa fa-clock"></i>
                                          <span>{{ visit.start_time | date: 'dd-MM-yyyy' }}</span>
                                          <br>
                                          <span>{{ visit.start_time | date: 'hh:mm a' }}</span>
                                        </div>
                                        <div class="last_bottom">

                                          <div class="ellipsis_sec">
                                            <ng-container
                                              *ngTemplateOutlet="ellipseContainer; context: { $implicit: visit, index: i }">
                                            </ng-container>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="hide_data"
                                      *ngIf="activeIndex==visit.mapping_id">
                                      <div class="purpose"><strong>Purpose</strong> : {{visit.purpose}}</div>
                                      <div class="mobile"><strong>Phone Number</strong> : {{visit.mobile_number}}</div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                   </div>




                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #actionListTemp>
    <div class="full_box">

      <ng-container *ngFor="let curr of actionList;let i = index">

        <div class="box_row" style="margin-top: 10px; margin-bottom: 10px;"
          [ngStyle]="{'background-color':curr.response_status.toLowerCase()=='pass'?'#d1ffd1':(curr.response_status.toLowerCase()=='pending' ||curr.response_status.toLowerCase()=='prePending')?'white':curr.response_status.toLowerCase()=='fail'?'#ff9e9e':'rgb(248 226 187)'}">
          <div class="left_content">
            <h6>{{curr.action_name}}</h6>

          </div>

          <div style="    display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;">
            <span *ngIf="!curr.isMandatory" class="pill bg-yellow-100 text-yellow-800 mr-1">
              Optional</span>
            <div class="right_content mr-2"
              *ngIf="curr.action_operation_type=='boolean'&& (userLocationState.enableCurrentActions==true && (curr.response_status=='pending')  || editable==true)">
              <i class="fa fa-check" style="color: green;" (click)="storeBooleanActionResult(curr,'PASS'); editable = false"></i>
              <i class="fa fa-times" style="color: #d91919;" (click)="currentAction=curr;editable = false" data-toggle="modal"
                data-target="#exampleModal"></i>
              <!-- <i class="fa fa-info-circle icon_info" matTooltip="Action Data" matTooltipPosition="below" (click)="actionDetail(curr,'pre',1,false)"></i> -->
            </div>
            <i  *ngIf="curr.response_status.toLowerCase() != 'overrided' && curr.isEditable && editable==false && curr.response_status!='pending'" class="fa fa-edit icon_edit" style="margin-right:5px"matTooltip="Edit Action" matTooltipPosition="below"
            (click)="curr.response_status='pending';curr.action_operation_type=='form'?openAction(curr):null"></i>
            <i class="fa fa-window-restore icon_override" style="margin-right:5px"
              *ngIf="curr.response_status.toLowerCase()=='fail's" matTooltip="Action Overriding"
              matTooltipPosition="below" (click)="overrideAction(curr)"></i>
            <i *ngIf="curr.action_operation_type=='form'&& userLocationState.enableCurrentActions && curr.response_status=='pending'"
              class="fa fa-clipboard clip_pass mr-2" matTooltip="Form" matTooltipPosition="below" aria-hidden="true"
              (click)="openAction(curr)"></i>
            <i class="fa fa-info-circle icon_info" matTooltip="Action Data" matTooltipPosition="below"
              (click)="actionData(curr)"></i>
          </div>

        </div>
      </ng-container>
    </div>

    <div class="final_btn" [style]="'width: 89% !important;position: absolute;bottom: 18px;'">
      <button (click)="openRemarkModal(); txnFailRemark = true" class="reject_btn">Reject</button>
      <button [disabled]="!userLocationState.canApprove" [class.active]="userLocationState.canApprove"
        (click)="checkNextStages('next_stages',content)" class="approve_btn">Approve</button>
    </div>
  </ng-template>



  <ng-template #stepper>
    <mat-horizontal-stepper class="p-0"  [linear]="true" #stepperView [selectedIndex]="currentInd" >
      <mat-step class="p-0">
        <ng-template matStepLabel>Visitor Verification</ng-template>
        <div>
          <div class="details p-0">
            <div class="row m-0 p-0">
              <div class="col-md-12 pl-0">
                <div class="row m-0">
                  <div class="col-md-6 px-1">
                    <div class="label_value">
                      <div class="label">Name:</div>
                      <div class="value">{{(currentVisitor.name?currentVisitor.name:"")}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 px-1">
                    <div class="label_value">
                      <div class="label">Phone No.:</div>
                      <div class="value">{{currentVisitor.mobile_number}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 px-1">
                    <div class="label_value">
                      <div class="label">Meet With:</div>
                      <div class="value">{{currentVisitor.user_name}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 px-1">
                    <div class="label_value">
                      <div class="label">From:</div>
                      <div class="value">{{currentVisitor.visit_from}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 px-1">
                    <div class="label_value">
                      <div class="label">Purpose</div>
                      <div class="value">{{currentVisitor.purpose}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 px-1">
                    <div class="label_value">
                      <div class="label">Visit Date</div>
                      <div class="value">{{currentVisitor.start_time |date :"dd:MM:yyyy" }}</div>
                    </div>
                  </div>



                </div>
              </div>

              <div class="col-md-12 p-0" >
                <div class="image">
                  <ng-container *ngIf="currentVisitor?.visitor_image; else defaultIcon">
                    <img [src]="currentVisitor?.visitor_image ?? ''">
                  </ng-container>
                  <ng-template #defaultIcon>
                    <!-- <div *ngIf="ifDeptWiseData()" class="text-center mt-1 fade-in no-assets-tracked">No Department wise Asset</div> -->
                    <img src="assets/images/empty_image.jpg">
                  </ng-template>
                </div>
              </div>


            </div>
          </div>
        </div>

        <div>
          <button mat-button (click)="openVerifyVisitorModal(currentVisitor);"
            style="background-color: #3f51b5 !important;color:white !important">Verify</button>
        </div>
      </mat-step>

      <mat-step class="p-0">
        <ng-template matStepLabel>Process & Tag Assignment</ng-template>
        <div class="form-group">
          <label for="my-input" class="required">{{'select_process'|translate}}</label>
          <ps-auto-suggestion (onSelected)="selectProcess($event,'select')" (onChange)="selectProcess($event,'input')"
            [data]="processStage.stage_data" display="processName" placeholder="Choose Process">
          </ps-auto-suggestion>
          <div class="error" *ngIf="ProcessValidator && ProcessValidator.error">
            <span>{{ ProcessValidator.msg }} </span>
          </div>
        </div>
        <div class="form-group" *ngIf="processStage.visitor_tracking_enable">
          <label for="my-input" class="required">{{'Select Tag'|translate}}</label>
          <ps-auto-suggestion  (onSelected)="selectTag($event,'select')" (onChange)="selectTag($event,'input')"
            [data]="tagList" display="tag_id" placeholder="Choose Tag">
          </ps-auto-suggestion>
          <div class="error" *ngIf="TagValidator && TagValidator.error">
            <span>{{ TagValidator.msg }} </span>
          </div>
        </div>

        <div>
          <button mat-button (click)="formValidator()"
            style="background-color: #3f51b5 !important;color:white !important">Assign</button>
        </div>
      </mat-step>

      <mat-step class="p-0">
        <ng-template matStepLabel>Checklist</ng-template>
        <ng-container *ngTemplateOutlet="actionListTemp">
        </ng-container>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-template>

  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" #remarkModal
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{!txnFailRemark ? (currentAction?.action_name + ' Fail Remark')
            : 'Transaction Fail Remark' }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="message-text" class="col-form-label">Message:</label>
              <textarea class="form-control" id="message-text" type="text" [(ngModel)]="commentMessage"
                name="message-text" #name="ngModel" placeholder="Enter Remark"></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="addCommentForVisit(currentAction,!txnFailRemark,txnFailRemark,content)">Skip</button>
          <button type="button" class="btn btn-primary"
            (click)="addCommentForVisit(currentAction,!txnFailRemark,txnFailRemark,content)"
            data-dismiss="modal">Save</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Stage Selection in case of Multi next stage -->
  <ng-template #content let-modal>
    <div class="modal-header">
      <h6 class="modal-title" id="modal-basic-title">{{'next_stage'|translate}}</h6>
      <div aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-times"></i></div>
    </div>
    <div class="row modal-body" id="boxes">
      <div class="stage_boxx">
        <div class="stageBox" *ngFor="let stage of currentVisitor[visitStageFlow]; let i=index">
          <div class="stageName" [ngClass]="{'active_stage': currentVisitor.next_stage_id==stage.next_stage_id}"
            (click)="currentVisitor.next_stage_id=stage.next_stage_id">{{stage.stage_name}}</div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button [disabled]="!currentVisitor.next_stage_id" type="button" class="btn btn-success" *ngIf="visitStageFlow == 'next_stages'"
        (click)="assignNextStage();modal.dismiss('Cross click');">{{'approve' | translate}}</button>
      <button [disabled]="!currentVisitor.next_stage_id" type="button" class="btn btn-danger" style="background-color: red!important;" *ngIf="visitStageFlow == 'reject_next_stages'"
        (click)="assignNextStage(0);modal.dismiss('Cross click');">{{'reject' | translate}}</button>
    </div>
  </ng-template>


  <!-- Ellipse Continer -->
  <ng-template #ellipseContainer let-visit let-i="index">
    <ng-container *ngIf="visit.txn_id == null; else ellipse;">
      <i class="fa fa-ellipsis-v" style="opacity: 0.5;"></i>
    </ng-container>
    <ng-template #ellipse>
      <i class="fa fa-ellipsis-v"
        (click)="ellipse.checked ? ellipse.checked = false : ellipse.click(); $event.stopPropagation(); "></i>
      <input [hidden]="true" #ellipse (click)="$event.stopPropagation();" type="radio" name="ellipse"
        [value]="'visit.visitor_id' + '@' + i" id="">
      <ul class="ellipsis_data" *ngIf="ellipse.checked">
        <li (click)="visitDetailModal(visit)"><img src="assets/images/trip-details(3).png" alt="" title="Trip Detail"
            style="height: 15px; width: 15px; margin-right: 5px;" matTooltipPosition="below">Trip Details</li>
        <li (click)="visitTrackingModal(visit) "><img src="assets/images/trip-tracking(3).png" alt=""
            title="Trip Tracking" style="height: 15px; width: 15px; margin-right: 5px;" matTooltipPosition="below">Trip
          Tracking
        </li>
        <li (click)="pathTrackingModal(visit.visitor_id)">
          <img src="assets/images/user-path-tracking.png" alt="" title="Trip Detail" style="height: 15px; width: 15px;"
            matTooltipPosition="below"> Visit Tracking
        </li>
        <li (click)="tripCommentTrail(visit.txn_id)">
          <img class="main-icon" style="height: 15px; width: 15px;" src="assets/images/chat-icon.png" alt=""
            matTooltip="Trip Remark History" matTooltipPosition="below">
          Comment Trail
        </li>
      </ul>
    </ng-template>
  </ng-template>

import { UserService } from 'src/app/@core/services/user.service';
import { Router } from '@angular/router';
import { TmgDashboardService } from './tmg-dashboard.service';
import { TmgService } from './tmg.service';
import { dashboard } from './data';
import {
  Component,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
  OnInit,
} from '@angular/core';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
@Component({
  selector: 'app-tmg-dashboard',
  templateUrl: './tmg-dashboard.component.html',
  styleUrls: [
    './tmg-dashboard.component.scss',
    '././tmg-dashboard.component.scss',
  ],
})
export class TMGDashboardComponent implements OnInit {
  componentRef: any;
  @ViewChild('dashboard', { read: ViewContainerRef }) container: any;

  dashboard = dashboard;
  // dashboardComponents = dashboardComponents;
  Allprocess: any = [];
  componentId: number = -1;
  alldashboards = [{ route: 'app-dashboard-loader' }];
  currentDashboard: string = '';
  currentDashboardindex: number = -1;
  TimeoutDelay: boolean = false;

  constructor(
    public resolver: ComponentFactoryResolver,
    public tmg: TmgService,
    public tmgdashboard: TmgDashboardService,
    public router: Router,
    public userService: UserService,
    public breadcrumbService: BreadcrumbService
  ) {
    let breadcumbs = [
      {
        name: 'Home',
        url: '/user/dashboard',
      },
      {
        name: 'TMG Dashboard',
        url: '/user/tmg-dashboard',
      },
    ];

    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  dashboardChange(event: any) {
    this.componentId = event.index;
  }

  getprocess() {
    this.tmgdashboard.getAllProcess().subscribe((item) => {
      this.Allprocess = [];
      item.data.map((process: any) => {
        let { processId, processName } = process;
        this.Allprocess.push({
          processId,
          processName,
        });

        this.tmg.processArray = this.Allprocess;
      });

      this.dashboard.map((item: any, index: number) => {
        this.tmgdashboard.dashboardProcess[index] = 0;
      });
      this.tmg.processId = this.Allprocess[0].processId;
      this.tmgdashboard.currentProcess = this.Allprocess[0].processName;
    });
  }

  processChange(event: any) {
    this.tmg.processId = this.Allprocess[event.index].processId;
    this.tmgdashboard.currentProcess = this.Allprocess[event.index].processName;
  }

  componentLoader(index: number) {
    this.container.clear();
    let currentComponent: any = this.dashboard[index].component;
    const factory = this.resolver.resolveComponentFactory(currentComponent);
    this.componentRef = this.container.createComponent(factory);
  }

  ngOnInit() {
    this.getprocess();
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label for="my-input" class="required">{{"checklist_title" | customTranslate}}</label>
                    <input type="text" name="cheklistTitle" class="form-control" placeholder="Enter Checklist Title"
                      [(ngModel)]="checklistObj.title">
                    <div class="error" *ngIf="titleValidator && titleValidator.error">
                      <span>{{titleValidator.msg}}</span>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label for="my-input">{{"checklist_description" | customTranslate}}</label>
                    <input type="text" name="cheklistDescription" class="form-control"
                      placeholder="Enter Checklist Description" [(ngModel)]="checklistObj.description">
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label for="my-input">{{"Additional Time (in mins)" | customTranslate}}</label>
                    <input type="number" name="tolerance" min=0 class="form-control"
                      placeholder="Enter Tolerance (in minutes)" [(ngModel)]="checklistObj.tolerance">
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label for="actionStatus" class="required">{{("status" | customTranslate)}}</label>
                    <select id="actionStatus" class="form-control" name="actionStatus"
                      [(ngModel)]="checklistObj.status">
                      <option value="ACTIVE">{{("active" | customTranslate)}}</option>
                      <option value="INACTIVE">{{("inactive" | customTranslate)}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label for="checklistType" class="required">{{"checklist_type" | customTranslate}}</label>
                    <select id="checklistType" (change)="handleTypeChange(checklistObj.type)" class="form-control"
                      name="is_scheduled" [(ngModel)]="checklistObj.type">
                      <option value="0">{{"ONE TIME" | customTranslate}}</option>
                      <option value="1">{{"REPEATING" | customTranslate}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label style="width: 79px;" class="required">{{ (checklistObj.type == 0 ? "perform_date" :
                      "perform_start_date") | customTranslate}}</label>
                    <input type="date" [min]="minStartDate" class="form-control" name="perform_start_date"
                      [(ngModel)]="checklistObj.performStartDate" (change)="checklistObj.type == 0 ? checklistObj.performEndDate = checklistObj.performStartDate : null">
                    <div class="error" *ngIf="startDateValidator && startDateValidator.error">
                      <span>{{startDateValidator.msg}}</span>
                    </div>
                  </div>
                </div>

                <div *ngIf="checklistObj.type == 1" class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label style="width: 79px;" class="required">{{"perform_end_date" | customTranslate}}</label>
                    <input type="date" [min]="checklistObj.performStartDate" class="form-control"
                      name="perform_end_date" [(ngModel)]="checklistObj.performEndDate">

                    <div class="error" *ngIf="endDateValidator && endDateValidator.error">
                      <span>{{endDateValidator.msg}}</span>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6" *ngIf="checklistObj.type==0">
                  <div class="form-group">
                    <label style="width: 250px;" class="required">{{"perform_start_time" | customTranslate}}</label>
                    <input type="time" class="form-control" name="startTime"
                      [(ngModel)]="checklistObj.data[0].startTime" (change)="checklistChecker(0,$event,'start')">
                    <div class="error" *ngIf="startTimeValidator[0] && startTimeValidator[0].error">
                      <span>{{ startTimeValidator[0]?.msg }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6" *ngIf="checklistObj.type==0">
                  <div class="form-group">
                    <label style="width: 250px;" class="required">{{"perform_end_time" | customTranslate}}</label>
                    <input type="time" class="form-control" name="endTime" [(ngModel)]="checklistObj.data[0].endTime"
                      (change)="checklistChecker(0,$event,'end')">
                    <div class="error" *ngIf="endTimeValidator[0] && endTimeValidator[0].error">
                      <span>{{ endTimeValidator[0]?.msg }}</span>
                    </div>
                  </div>
                </div>
              </div>



              <div class="col-xl-12 col-lg-12 col-sm-12" *ngIf="checklistObj.type==1">
                <div class="row">
                  <div class="col-xl-2 col-lg-6 col-sm-12">
                    <label class="required">{{"checklist_execution_time" | customTranslate}}</label>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-xl-4 col-lg-6 col-sm-12" style="margin-top: 20px;">
                    <form class="d-flex">
                      <label style="width: 250px;" class="required">{{"repeating_type" | customTranslate}}</label>
                      <select class="form-control" [(ngModel)]="checklistObj.intervalType" name="intervalType" (change)="iterationChange()">
                        <option *ngFor="let item of iterationType" [value]="item.value">{{item.type}}</option>
                      </select>
                    </form>
                  </div>

                  <div class="col-xl-4 col-lg-6 col-sm-12" style="margin-top: 20px;">
                    <form class="d-flex">
                      <label style="width: 250px;" class="required">{{"repeat_count" | customTranslate}}</label>
                      <input type="number" name="intervalCount" min=0
                        [max]="checklistObj.intervalType == 'Yearly'?12:(checklistObj.intervalType == 'Weekly' ? 7 :checklistObj.intervalType == 'Monthly' ? 31 : 100 )"
                        class="form-control" [(ngModel)]="checklistObj.intervalCount" (change)="createSchedularData()">
                    </form>
                  </div>


                </div>

                <div class="row mt-3" *ngFor="let i of range(checklistObj.intervalCount); let rowIndex=index"
                  style="display: flex;">

                  <!--for weekly-->
                  <ng-container *ngIf="checklistObj.data[i]">
                    <div class="col-xl-4 col-lg-6 col-sm-12" *ngIf="checklistObj.intervalType=='Weekly'">
                      <form class="d-flex">
                        <label style="width: 250px;" class="required">{{"day_of_week" | customTranslate}}</label>
                        <select name="dayOfWeek" [(ngModel)]="checklistObj.data[i].dayOfWeek"
                          style="width: -webkit-fill-available;" (change)="checklistIntervalCheck($event,i)">
                          <option disabled [value]="''">Select Day</option>
                          <option *ngFor="let day of daysOfWeek" [value]="day">{{ day }}</option>
                        </select>
                      </form>
                    </div>

                    <!--for monthly-->
                    <div class="col-xl-4 col-lg-6 col-sm-12" *ngIf="checklistObj.intervalType=='Monthly'">
                      <form class="d-flex">
                        <label style="width: 250px;" class="required">{{"day_of_month" | customTranslate}}</label>
                        <input type="number" max=31 min=1 class="form-control" name="dayOfMonth"
                          [(ngModel)]="checklistObj.data[i].dayOfMonth" (change)="checklistIntervalCheck($event,i)">
                      </form>
                    </div>

                    <!-- for-yearly -->
                    <div class="col-xl-4 col-lg-6 col-sm-12" *ngIf="checklistObj.intervalType=='Yearly'">
                      <form class="d-flex">
                        <label style="width: 250px;" class="required">{{"month of year" | customTranslate}}</label>
                        <select name="dayOfWeek" [(ngModel)]="checklistObj.data[i].monthOfYear"
                          style="width: -webkit-fill-available;" (change)="checklistIntervalCheck($event,i)">
                          <option disabled [value]="''">Select Month</option>
                          <option *ngFor="let month of monthOfYear" [value]="month">{{ month }}</option>
                        </select>
                      </form>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-sm-12">
                      <form class="d-flex">
                        <label style="width: 250px;" class="required">{{"perform_start_time" | customTranslate}}</label>
                        <input type="time" class="form-control" name="startTime"
                          [(ngModel)]="checklistObj.data[i].startTime" (change)="checklistChecker(i,$event,'start')">
                      </form>
                    </div>
                    <div class="error" *ngIf="startTimeValidator[i] && startTimeValidator[i].error">
                      <span>{{ startTimeValidator[i]?.msg }}</span>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-sm-12">
                      <form class="d-flex">
                        <label style="width: 250px;" class="required">{{"perform_end_time" | customTranslate}}</label>
                        <input type="time" class="form-control" name="endTime"
                          [(ngModel)]="checklistObj.data[i].endTime" (change)="checklistChecker(i,$event,'end')">
                      </form>
                    </div>
                    <div class="error" *ngIf="endTimeValidator[i] && endTimeValidator[i].error">
                      <span>{{ endTimeValidator[i]?.msg }}</span>
                    </div>
                  </ng-container>
                </div>
              </div>


              <div class="form-group action-block">
                <div class="btn-block" style="margin-top: 10px;">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" | customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="resetDetail()"> {{"reset" | customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"checklist_list" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="card-body">
                <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                  <thead>
                    <tr>
                      <th>{{ "serial_no" | customTranslate }}</th>
                      <th>{{ "checklist_title" | customTranslate }}</th>
                      <th>{{ "checklist_type" | customTranslate }}</th>
                      <th>{{ "Additional Time" | customTranslate }}</th>
                      <th>{{ "perform_start_date" | customTranslate }}</th>
                      <th>{{ "perform_end_date" | customTranslate }}</th>
                      <th>{{ "status" | customTranslate }}</th>
                      <th>{{ "plant" | customTranslate }}</th>
                      <th>{{"action" | customTranslate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of checklistData, let i = index">
                      <td>{{i+1}}</td>
                      <td>{{row.checklist_title}}</td>
                      <td>{{row.checklist_type == 1 ? "Scheduled" : "OneTime"}}</td>
                      <td>{{row.tolerance}} mins</td>
                      <td>{{row.start_date | date:'dd-MM-yyyy'}}</td>
                      <td>{{row.end_date | date:'dd-MM-yyyy'}}</td>
                      <td>{{row.status}}</td>
                      <td>{{row.plant_name}}</td>
                      <td class="action">
                        <div class="btn-block">
                          <button class="btn btn-primary" (click)="viewDetails(row)"> {{"view" | customTranslate}} </button>
                          <button class="btn btn-success" (click)="editChecklist(row)"> {{"edit" | customTranslate}} </button>
                          <button class="btn btn-danger" (click)="confirmAlert(row)"> {{"delete" | customTranslate}} </button>
                          <button class="btn btn-info active_class" (click)="viewQRModal(row)"
                            style="background-color: #337ab7;"> {{"view_qr" | customTranslate}} </button>

                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>
          </div>

        </div>

      </div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';

@Component({
  selector: 'app-manage-hardware',
  templateUrl: './manage-hardware.component.html',
  styleUrls: ['./manage-hardware.component.scss'],
})
export class ManageHardwareComponent implements OnInit {
  Hardware = {
    hardwareId: '',
    hardwareName: '',
    hardwareDescription: '',
    hardwareCompanyName: '',
    status: '',
    hardwareCurrentStatus: '',
    ipAddress: '0.0.0.0',
    port: '',
    macAddress: '',
    connectionId: '',
    antennaNumber: '',
    readType: '',
    rssi: '',
    hardwareType: '',
    hardwareUniqueId: '',
    createdBy: '',
    createdAt: '',
    version: '',
  };

  verisonList: any[];
  hardwareTitle = 'Add Hardware';
  btn = 'save';
  HardwareData: any = [];

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'HARDWARE LIST');

  constructor(
    public api: ApiService,
    private modalService: NgbModal,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public alertService: AlertService,
    public table: TableService,
    public userService: UserService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Hardware', url: null },
      { name: 'Hardware Management', url: '/user/manage-hardware' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.getHardware();
    this.getAppVersions();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  saveHardware() {
    let validIP =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        this.Hardware.ipAddress
      );
    if (validIP == false) {
      this.alertService.error('IP Address is Invalid...');
    } else {
      let params = {};
      if (this.btn == 'update') {
        params = {
          hardwareId: this.Hardware.hardwareId,
          name: this.Hardware.hardwareName,
          description: this.Hardware.hardwareDescription,
          companyName: this.Hardware.hardwareCompanyName,
          hardwarestatus: this.Hardware.status,
          currentStatus: this.Hardware.hardwareCurrentStatus,
          hardwareipAddress: this.Hardware.ipAddress,
          hardwareport: this.Hardware.port,
          hardwaremacAddress: this.Hardware.macAddress,
          hardwareconnectionId: this.Hardware.connectionId,
          hardwareantennaNumber: this.Hardware.antennaNumber,
          hardwarereadType: this.Hardware.readType,
          hardwareRssi: this.Hardware.rssi,
          hardwareType: this.Hardware.hardwareType,
          hardwareUniqueId: this.Hardware.hardwareUniqueId,
          hardwarecreatedBy: this.Hardware.createdBy,
          hardwarecreatedAt: this.Hardware.createdAt,
          hardwareVersion: this.Hardware.version,
        };
      } else {
        params = {
          name: this.Hardware.hardwareName,
          description: this.Hardware.hardwareDescription,
          companyName: this.Hardware.hardwareCompanyName,
          hardwarestatus: this.Hardware.status,
          currentStatus: this.Hardware.hardwareCurrentStatus,
          hardwareipAddress: this.Hardware.ipAddress,
          hardwareport: this.Hardware.port,
          hardwaremacAddress: this.Hardware.macAddress,
          hardwareconnectionId: this.Hardware.connectionId,
          hardwareantennaNumber: this.Hardware.antennaNumber,
          hardwarereadType: this.Hardware.readType,
          hardwareRssi: this.Hardware.rssi,
          hardwareType: this.Hardware.hardwareType,
          hardwareUniqueId: this.Hardware.hardwareUniqueId,
          hardwareVersion: this.Hardware.version,
        };
      }
      this.common.loading = true;
      this.api.post('hardware/save_hardware', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.alertService.success(res.message);
          } else {
            this.alertService.error(res.message);
          }
          this.reset();
          this.getHardware();
        },
        (err) => {
          console.error('Error: ', err);
          this.alertService.error(err.error.message);
          this.common.loading = false;
        }
      );
    }
  }

  getAppVersions() {
    this.common.loading = true;
    let params = {
      versionType: 'CONTROLLER',
    };
    this.api.get('apk/get-app-details', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.verisonList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectVersion(event: any) {
    this.Hardware.version = event.appVersion;
  }

  reset() {
    this.Hardware.hardwareId = '';
    this.Hardware.hardwareName = '';
    this.Hardware.hardwareDescription = '';
    this.Hardware.hardwareCompanyName = '';
    this.Hardware.status = '';
    this.Hardware.hardwareCurrentStatus = '';
    this.Hardware.ipAddress = '';
    this.Hardware.port = '';
    this.Hardware.macAddress = '';
    this.Hardware.connectionId = '';
    this.Hardware.antennaNumber = '';
    this.Hardware.readType = '';
    this.Hardware.rssi = '';
    this.Hardware.hardwareType = '';
    this.Hardware.hardwareUniqueId = '';
    this.Hardware.createdBy = '';
    this.Hardware.createdAt = '';
    this.Hardware.version = '';
    this.btn = 'save';
    this.hardwareTitle = 'Add Hardware';
    this.hardwareCurrentStatusValidator = '';
    this.hardwareStatusValidator = '';
    this.hardwareCompanyNameValidator = '';
    this.hardwareNameValidator = '';
    this.hardwareTypeValidator = '';
    this.readTypeValidator = '';
    this.ipAddressValidator = '';
    this.hardwareMacAddress = '';
  }

  getHardware() {
    this.common.loading = true;
    this.api.get('hardware/get_hardware').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.HardwareData = res.data;
        }
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  editHardware(item) {
    this.btn = 'update';
    this.hardwareTitle = 'Edit Hardware';
    this.Hardware.hardwareId = item.ipaHardwareId;
    this.Hardware.hardwareName = item.hardwareName;
    this.Hardware.hardwareDescription = item.hardwareDescription;
    this.Hardware.hardwareCompanyName = item.hardwareCompanyName;
    this.Hardware.status = item.status;
    this.Hardware.hardwareCurrentStatus = item.hardwareCurrentStatus;
    this.Hardware.ipAddress = item.ipAddress;
    this.Hardware.port = item.port;
    this.Hardware.macAddress = item.macAddress;
    this.Hardware.connectionId = item.connectionId;
    this.Hardware.antennaNumber = item.antennaNumber;
    this.Hardware.readType = item.readType;
    this.Hardware.rssi = item.rssi;
    this.Hardware.hardwareType = item.hardwareType;
    this.Hardware.hardwareUniqueId = item.hardwareUniqueId;
    this.Hardware.createdBy = item.createdBy;
    this.Hardware.createdAt = item.createdAt;
    this.Hardware.version = item.currentControllerVersion;
  }

  deleteHardware(item) {
    this.api.post('hardware/delete_hardware', item).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
        } else {
          this.alertService.error(res.message);
        }
        this.reset();
        this.getHardware();
      },
      (err) => {
        console.error('Error: ', err);
        this.alertService.error(err.error.message);
        this.common.loading = false;
      }
    );
  }

  setActiveComponent(item?: any) {
    this.common.params = { details: [item], title: 'Hardware Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  hardwareNameValidator: any;
  hardwareStatusValidator: any;
  hardwareCurrentStatusValidator: any;
  hardwareCompanyNameValidator: any;
  hardwareTypeValidator: any;
  ipAddressValidator: any;
  readTypeValidator: any;
  hardwareMacAddress:any;
  formValidator() {
    this.hardwareNameValidator = this.common.nullValidator(
      this.Hardware.hardwareName,
      'Hardware Name Mandatory'
    );
    this.hardwareStatusValidator = this.common.nullValidator(
      this.Hardware.status,
      'Hardware Status Mandatory'
    );
    this.hardwareCurrentStatusValidator = this.common.nullValidator(
      this.Hardware.hardwareCurrentStatus,
      'Hardware Current Status Mandatory'
    );
    this.hardwareCompanyNameValidator = this.common.nullValidator(
      this.Hardware.hardwareCompanyName,
      'Hardware Company Name Mandatory'
    );
    this.hardwareTypeValidator = this.common.nullValidator(
      this.Hardware.hardwareType,
      'Hardware Type Mandatory'
    );
    this.ipAddressValidator = this.common.nullValidator(
      this.Hardware.ipAddress,
      'Hardware IP Address is Mandatory'
    );
    this.readTypeValidator = this.common.nullValidator(
      this.Hardware.readType,
      'Please Select ReadType'
    );
    this.hardwareMacAddress = this.common.nullValidator(
      this.Hardware.macAddress,
      'Please Enter Mac Address'
    );
    if (
      !this.hardwareCompanyNameValidator.error &&
      !this.hardwareNameValidator.error &&
      !this.hardwareStatusValidator.error &&
      !this.hardwareCurrentStatusValidator.error &&
      !this.hardwareTypeValidator.error &&
      !this.ipAddressValidator.error &&
      !this.readTypeValidator.error &&
      !this.hardwareMacAddress.error
    ) {
      this.saveHardware();
    }
  }

  ngOnInit() {}
}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../@core/services/common.service';

@Component({
  selector: 'app-display-image',
  templateUrl: './display-image.component.html',
  styleUrls: ['./display-image.component.scss'],
})
export class DisplayImageComponent implements OnInit {
  image_name = '';
  image_url: any = '';
  constructor(
    private common: CommonService,
    private activeModal: NgbActiveModal
  ) {
    this.image_name = this.common.params.name;
    this.image_url = this.common.params.url;
  }

  ngOnInit(): void {}

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { local } from 'd3-selection';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private route: Router){}
  lastAllowedUrl:string
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const lastLoginPortal = localStorage.getItem("login_mode");
      if(state.url.startsWith("/visitor") && !this.isVisitorLoggedin()) {
        this.route.navigate(['/auth/visitor-login'],{queryParams : {'re':state.url, 'authkey': route.queryParams.authkey || null}});
        return false;
      } else if (state.url.startsWith("/user") && !this.isLoggedin()) {
        if (lastLoginPortal == 'port') {
          this.route.navigate(['/auth/port-login'],{queryParams : {'re':state.url, 'authkey': route.queryParams.authkey || null}});
        } else {
          this.route.navigate(['/auth/login'],{queryParams : {'re':state.url, 'authkey': route.queryParams.authkey || null}});
        }
        return false;
      } else if (state.url.startsWith("/auth")) {
        if(lastLoginPortal == 'user' && !state.url.startsWith("/auth/login")) {
          this.route.navigate(['/auth/login']);
          return false;
        } else if (lastLoginPortal == 'port' && !state.url.startsWith("/auth/port-login")) {
          this.route.navigate(['/auth/port-login']);
          return false;
        } else if (lastLoginPortal == 'visitor' && !state.url.startsWith("/auth/visitor-login")) {
          this.route.navigate(['/auth/visitor-login']);
          return false;
        }
      }
      return true;
  }

  isWalkinMode() {
    return localStorage.getItem('walkin_mode').toLowerCase() == 'true';
  }

  isLoggedin() {
    const userToken = localStorage.getItem('USER_TOKEN');
    return userToken != 'null' && userToken != null
  }

  isVisitorLoggedin() {
    const visitorToken = localStorage.getItem('VISITOR_TOKEN');
    return visitorToken != 'null' && visitorToken != null
  }

}

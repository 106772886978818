import { event } from 'jquery';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ApiService } from 'src/app/@core/services/api.service';
import { WebcamImage } from 'ngx-webcam';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { Base64ToFileService } from 'src/app/@core/services/base64-to-file-service.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-add-visit-modal',
  templateUrl: './add-visit-modal.component.html',
  styleUrls: ['./add-visit-modal.component.scss'],
})
export class AddVisitModalComponent implements OnInit {

@Input() title = 'add_new_visit';
@Input() isEdit = false
@Input() toggle = false;
@Input() refData = null;
@Input() isVisitor = false
@Output() visitSaved = new EventEmitter<void>();

showCamera: boolean = false;
retakeImage: boolean = false;
fileEvent: any = [];
  // Add Visit object
  newAppointment = {
    appointmentId: null,
    name: null,
    startTime: null,
    endTime: null,
    purpose: null,
    from: null,
    to: null,
    refType: null,
    visitorId: null,
    visitors: ([] = [
      {
        staff_id: null,
        staff_name: null,
        mobile_number: null,
        isNameDisabled: false,
      },
    ]),
    refId: null,
    userName: '',
    visitorImage: null,
  };
  nameValidator: any;
  startTimeValidator: any;
  endTimeValidator: any;
  purposeValidator: any;
  fromValidator: any;
  toValidator: any;
  allUsers: null;
  staffType: String = null;
  visitorId: number;
  plantId: number = null;
  companyId:number = null
  minStartDate: Date = new Date();
  originalEndTime:Date  = new Date();
  originalStartTime:Date = new Date();
  staffNameValidator:any
  staffMobileValidator: any
  constructor(
    private common:CommonService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private api:ApiService,
    private imageCompress: NgxImageCompressService,
    private alertService: AlertService,
    private base64ToFileService: Base64ToFileService,
    private userService:UserService
  ) {
  }
   ngOnInit() {
    if(this.refData!=null){

      this.isEdit = this.refData.isEdit
      if(this.isEdit){
        this.title = 'Update Visit'
        this.isVisitor = this.refData.isVisitor
        this.setAppointment(this.refData.visitDetails)
      }
    }
    this.staffType = this.userService._userType;
    this.isVisitor = this.staffType == 'Visitor';
    this.newAppointment.visitorId = this.userService._loggedInUser.id;
    this.visitorId = this.userService._loggedInUser.id;
    this.newAppointment.name = this.userService._name;
    this.getAllUsers();
  }

  handleSaveTrigger(event:any): void {
    this.visitSaved.emit(event);
  }
confirmCloseModalInWalkinMode() {
  this.common.params = {
    title: 'Are You Sure?',
    description:'All filled data will be erased',
  };
  const activeModal = this.modalService.open(ConfirmationDialogComponent, {
    size: 'md',
  });
  activeModal.result.then((data: any) => {
    if (data.response) {
      this.closeModal();
    }
  });
}
handleImage(webcamImage: WebcamImage, name: string) {
  this.onSelect(webcamImage, name);
}
  validateStartTime(): void {
    const selectedTime = new Date(this.newAppointment.startTime);
    const currentTime = new Date();
    let newSelectedTime = selectedTime.toISOString().split('T')[0];
    let newCurrentTime = currentTime.toISOString().split('T')[0];
    if (newSelectedTime < newCurrentTime) {
      this.alertService.error(
        'Start time cannot be in the past. Please select a valid time.'
      );
      this.newAppointment.startTime = this.originalStartTime;
    }
   if(this.newAppointment.endTime!=null){
      this.validateDateTimeRange(this.newAppointment.startTime,this.newAppointment.endTime)
   }
  }
onSelect(e, name) {

  let frmData = new FormData();
  let imgResultAfterCompression: DataUrl;
  let file;
  this.imageCompress
    .compressFile(e._imageAsDataUrl, -2, 50, 50) // 50% ratio, 50% quality
    .then((compressedImage) => {
      imgResultAfterCompression = compressedImage;
      file = this.base64ToFileService.base64toFile(
        imgResultAfterCompression,
        name + '.jpg'
      );
      frmData.append('files', file);
      this.fileEvent.inProgress = true;
      this.uploadFile(frmData, name);
    });
}

uploadFile(frmData: any, name: string) {
  const headers = new HttpHeaders();
  this.api
    .post('functional/upload-file?methodId=' + 17, frmData)
    .subscribe((res: any) => {
      switch (res.data.type) {
        case HttpEventType.UploadProgress:
          this.fileEvent.progress = Math.round(
            (res.data.loaded * 100) / res.data.total
          );
          break;
        case HttpEventType.Response:
          return res.data;
      }
      res.data.forEach((e) => {


        this.newAppointment.visitorImage = e.fileUrl;
      });
    });
  catchError((error: HttpErrorResponse) => {
    this.fileEvent.inProgress = false;
    this.common.loading = false;
    return `${this.fileEvent.data.name} upload failed.`;
  });
}
  //check wheter the endtime is greater than starttime or not
  validateDateTimeRange(startDateTime: any, endDateTime: string) {
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      throw new Error('Invalid date format');
    }
    if (!(end >= start)) {
      this.alertService.error(
        'Visit End Time should be greater than Visit start time.'
      );
      this.newAppointment.endTime = this.originalEndTime;
      this.newAppointment.startTime = this.originalStartTime
    }
  }
  selectedUser(selectedUser: { id: number; name: string; type_name: string }) {
    this.newAppointment.refId = selectedUser.id;
    this.newAppointment.refType = selectedUser.type_name;
    this.newAppointment.userName = selectedUser.name;
  }

  // handleMobileNumber(
  //   visitor: {
  //     id: number;
  //     mobile_number: string;
  //     staff_name: string;
  //     isNameDisabled: boolean;
  //   },
  //   mobile: number
  // ) {

  //   if (new String(mobile).length == 10) {
  //     let params = {
  //       mobileNumber: mobile,
  //     };
  //     this.api.get('visitors/check-visitor-mobile-number', params).subscribe(
  //       (res: any) => {
  //         this.common.loading = false;
  //         if (res.status) {
  //           this.fillNameIfStaff(visitor, res.data[0]);
  //         }
  //       },
  //       (err: any) => {
  //         this.common.loading = false;
  //       }
  //     );
  //   }
  //   if (new String(mobile).length == 0) {
  //     visitor.staff_name = null;
  //     visitor.isNameDisabled = false;
  //     visitor.id = null;
  //   }
  // }
    //prefill name if staff exists and checks for banned,non-staff-type
    fillNameIfStaff(visitor: any, existingStaff: any) {
      if (existingStaff != null) {
        if (existingStaff.is_banned) {
          visitor.mobile = null;
          visitor.id = null;

          return this.alertService.error('Visitor Is Banned !!');
        }
        if (existingStaff.same_staff_type) {
          visitor.id = existingStaff.staff_id;
          if (existingStaff.staff_name != null) {
            visitor.staff_name = existingStaff.staff_name;
            visitor.isNameDisabled = true;
          }
        } else {
          this.alertService.error(
            'Visitor already exist with another staff type'
          );
          visitor.mobile = null;
          visitor.id = null;
          visitor.staff_name = null;
        }
        //when a existing staff mob no is entered ,name is prefilled and partially removing the existing mobno. and entering new mobileno
      } else {
        visitor.staff_name = null;
        visitor.isNameDisabled = false;
      }
    }

    removeRow(index: number) {
      this.staffNameValidator=null
      this.staffMobileValidator=null
      console.log('err',this.staffMobileValidator)
      if (this.newAppointment.visitors.length > 1) {
        this.newAppointment.visitors.splice(index, 1);
      }
    }
    ind : number=0

    addValues(){

      if(this.formValidator()){

      this.newAppointment.visitors.push({
        staff_id: null,
        staff_name: null,
        mobile_number: null,
        isNameDisabled: false,
      });
    }

    }
    getAllUsers() {
      let params = {
        staffType: 'Visitor',
      };
      this.api.get('visitors/get-all-users', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.allUsers = res.data;
          }
        },
        (err: any) => {
          this.common.loading = false;
        }
      );
    }
    formValidator() {

      this.nameValidator = this.common.nullValidator(
        this.newAppointment.name,
        'Name  Mandatory'
      );
      this.startTimeValidator = this.common.nullValidator(
        this.newAppointment.startTime,
        'Start Time Mandatory'
      );
      this.endTimeValidator = this.common.nullValidator(
        this.newAppointment.endTime,
        'End Time Mandatory'
      );

      this.purposeValidator = this.common.nullValidator(
        this.newAppointment.purpose,
        'Purpose Mandatory'
      );
      this.fromValidator = this.common.nullValidator(
        this.newAppointment.from,
        'From Mandatory'
      );

      this.staffNameValidator=null
      this.staffMobileValidator=null
      this.ind =-1
      console.log('this.newAppointment.visitors: ', this.newAppointment.visitors);
      this.newAppointment.visitors.forEach((visitor, index)=> {
        this.staffNameValidator = this.common.nullValidator(
          visitor.staff_name,
          'Name  Mandatory'
        )


        this.staffMobileValidator = this.common.mobileNoValidator(
          visitor.mobile_number,
          'Mobile Number  Mandatory'
        )

        if(this.staffNameValidator.error || this.staffMobileValidator.error) {
          this.ind = index;
        }

      });



      if (
        (!this.nameValidator.error || !this.isVisitor) &&
        !this.startTimeValidator.error &&
        !this.endTimeValidator.error &&
        !this.purposeValidator.error &&
        !this.fromValidator.error &&
        !this.staffNameValidator.error &&
        !this.staffMobileValidator.error
      ) {
        return true
        this.bookAppointment();
      }
      return false

    }
    bookAppointment() {

      let body = {
        appointmentId: this.newAppointment.appointmentId
          ? this.newAppointment.appointmentId
          : null,
        name: this.newAppointment.name,
        startTime:this.newAppointment.startTime,
        endTime:this.newAppointment.endTime,
        refId: this.newAppointment.refId,
        visitFrom: this.newAppointment.from,
        purpose: this.newAppointment.purpose,
        refType: this.newAppointment.refType=='USER'?'USER':'STAFF',
        visitors: this.newAppointment.visitors.filter((res) => res.staff_name != null),
        visitorId: this.isVisitor ? this.visitorId : null,
        visitorImage: this.newAppointment.visitorImage,
        plantId: this.plantId,
        companyId: this.companyId
      };
      if(body.startTime>body.endTime){
        return this.alertService.error(
          'StartTime is greater than EndTime'
        );
      }
      if (
        !this.isVisitor &&
        (body.visitors == null || body.visitors.length == 0)
      ) {
        return this.alertService.error(
          'Please Add Visitor for Appoinment Booking !!'
        );
      }
      this.common.loading = true;
      this.api.post('visitors/book_appointment', body).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.alertService.success('Visit Added Successfully');
            this.reset();
              this.closeModal();
          }else{
            this.alertService.error(res.message);
          }
        },
        (err: any) => {
          this.common.loading = false;
        }
      );
    }

    reset() {
      this.newAppointment = {
        appointmentId: null,
        name: null,
        startTime: null,
        endTime: null,
        purpose: null,
        from: null,
        to: null,
        refType: null,
        visitorId: null,
        visitors: ([] = [
          {
            staff_id: null,
            staff_name: null,
            mobile_number: null,
            isNameDisabled: false,
          },
        ]),
        refId: null,
        userName: '',
        visitorImage: null,
      };
      this.showCamera = false;
      this.nameValidator = null;
      this.startTimeValidator = null;
      this.endTimeValidator = null;
      this.purposeValidator = null;
      this.fromValidator = null;
      this.toValidator = null;
    }
closeModal(response?: any, apiHit?: any) {
  this.activeModal.close({ response: response, apiHit: apiHit });
}
convertDateFormat(dateString: string): string {
  const [datePart, timePart] = dateString.split(' ');

  const [day, month, year] = datePart.split('-');

  const formattedDate = `${year}-${month}-${day}T${timePart}`;

  return formattedDate;
}

parseDateTimeForInput = (dateString: string): string | null => {
  if (dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('-');
    const timeWithoutSeconds = timePart.slice(0, 5); // Trim to HH:mm
    return `${year}-${month}-${day}T${timeWithoutSeconds}`; // Format for datetime-local
  }
  return null;
};



setAppointment(row){
  this.newAppointment.appointmentId= row.id
  this.newAppointment.name = row.name
  this.newAppointment.from = row.visit_from
  this.newAppointment.purpose = row.purpose
  this.newAppointment.userName = row.host_name
  this.newAppointment.startTime = this.parseDateTimeForInput(row.visit_start_time);
  this.newAppointment.endTime = this.parseDateTimeForInput(row.visit_end_time);
  this.newAppointment.visitors = row.visit_users
  this.newAppointment.refId = row.ref_id
  this.newAppointment.userName = row.host_name
  this.newAppointment.refType = row.ref_type
  this.originalEndTime = this.newAppointment.endTime
  this.originalStartTime = this.newAppointment.startTime
}

onAppointmentUpdate(){

  if(this.formValidator()){

    this.bookAppointment();
  }
}
}

  <div class="modal-header" >
    <h6 class="modal-title" id="modal-basic-title">{{refData.actionName}}</h6>
    <div aria-label="Close" (click)="closeModal()"><i class="fa fa-times"></i></div>
  </div>
  <div class="row modal-body" id="action_id">
  <div class="action_name_div" *ngIf="this.currentActionData!=[]">
    <div *ngFor="let data of currentActionData">
      <!-- <div class="action_title_div"> -->
        <!-- <h5>{{refData.actionName}}</h5> -->
        <!-- <span class="badge status-badge"
          [ngClass]="{'badge-success':data.response_status == 'PASS','badge-warning':data.response_status == 'OVERRIDED','badge-danger':data.response_status == 'FAIL','badge-secondary':data.response_status == 'PENDING' || data.response_status == 'PREPENDING'}">{{data.response_status!='PREPENDING'
          ? (data.response_status|customTranslate) : "PENDING"}}</span>
          <span class="badge status-badge" *ngIf="data.response_status==null"
          [ngClass]="{'badge-secondary':'PENDING'}">{{'PENDING'|customTranslate}}</span> -->
      <!-- </div> -->

      <div class="form_sec">
        <!-- <ng-container *ngFor="let field of data.response_value; index as i"> -->
          <div *ngFor="let field of data.response_value; index as i" [ngClass]="!readOnlyArray[i] ? 'cross-div input-container row' : 'input-container row'">
            <app-custom-field class="col-12" style="padding-right: 5%" [field]="field" [i]="i" [selvalue]="null" [customFieldValues]="fieldValues" [notUniqueFieldId]=-1 [manualStamping]=true [readOnlyFlag]="readOnlyArray[i]"
            (valueChange)="field.field_type=='GEOCODEDIMAGE' || field.field_type=='BARCODEWITHIMAGE' || field.field_type=='RFIDCODEWITHIMAGE' || field.field_type=='BARCODE' || field.field_type=='RFIDCODE'? handleImage($event, field, i) : (field.field_type=='SIGNATURE' ? saveSignature($event, i, field) : (field.field_type=='LABEL' ? insertLabel(field, i) : enterValues($event, field, i)))"
            (editTrigger)="onEdit(i,$event,field);">
          </app-custom-field>
          <i *ngIf="!readOnlyArray[i]" (click)="onEdit(i,true,null)" class="fa fa-times"></i>
        </div>
        <!-- </ng-container> -->
      </div>

    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" outline id="cancel" (click)="closeModal()"
  style="cursor: pointer;" >{{'Cancel'|customTranslate}}</button>
  <button class="btn btn-success" type="button" outline (click)="saveChanges()" id="submit"
  style="cursor: pointer;" >{{'save'|customTranslate}}</button>
</div>

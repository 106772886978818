import { Component, Input, OnInit, SimpleChanges} from '@angular/core';
import { TableColumns, TableOptions, COLUMN_ORDER, TABLE_TYPE } from 'src/app/dataType/customTableInterface';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit {
  public TABLE_TYPE = TABLE_TYPE;
  loading: boolean = null;
  @Input() columns: TableColumns[] = [];
  @Input() data: any[] = [];
  @Input() options: TableOptions = {
    defaultLength: 15,
    exportColumns: 10,
    tableTitle: "Table",
    orderColumnIndx: 0,
    order: COLUMN_ORDER.ASC
  };
  @Input() tableType: TABLE_TYPE = TABLE_TYPE.DATATABLE;
  @Input() showSerialNo: boolean = true;

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnChanges(simple: SimpleChanges){
    if(this.columns.length != 0) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 100)
    // } else {
    //   this.loading = true;
    }
  }
}

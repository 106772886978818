import {
  LiveDashboardComponent
} from './live-dashboard/live-dashboard.component';
import {
  VehicleDashboardComponent
} from './vehicle-dashboard/vehicle-dashboard.component';
import {
  StageDashboardComponent
} from './stage-dashboard/stage-dashboard.component';
import {
  TripDashboardComponent
} from './trip-dashboard/trip-dashboard.component';

export const dashboard = [{
    name: 'Live Dashboard',
    component: LiveDashboardComponent
  },
  {
    name: 'Stage Dashboard',
    component: StageDashboardComponent
  },
  {
    name: 'Process Dashboard',
    component: VehicleDashboardComponent
  },
  {
    name: 'Trip Dashboard',
    component: TripDashboardComponent
  },
  // {
  //   name: 'Vendor Dashboard',
  //   component: VendorDashboardComponent
  // },
  // {
  //   name: 'Product Dashboard',
  //   component: ProductDashboardComponent
  // },
];


export const dashboardComponents = [
  LiveDashboardComponent,
  StageDashboardComponent,
  VehicleDashboardComponent,
  TripDashboardComponent
];



export const vendors = [{
    vendor: 'Vendor A',
    vehicles: 250
  },
  {
    vendor: 'Vendor B',
    vehicles: 750
  },
  {
    vendor: 'Vendor C',
    vehicles: 50
  },
];


export const topvendors = [{
    vendor: 'Vendor A',
    trips: 750,
    success: 700,
    cancel: 50
  },
  {
    vendor: 'Vendor B',
    trips: 250,
    success: 200,
    cancel: 50
  },
  {
    vendor: 'Vendor C',
    trips: 50,
    success: 40,
    cancel: 10
  }
];


export const worstvendors = [{
    vendor: 'T1',
    trips: 750,
    success: 200,
    cancel: 5
  },
  {
    vendor: 'T2',
    trips: 650,
    success: 200,
    cancel: 6
  },
  {
    vendor: 'T3',
    trips: 850,
    success: 200,
    cancel: 7
  },

];



export const vendorstat = [{
    vendor: 'Vendor A',
    tat: '02:20'
  },
  {
    vendor: 'Vendor B',
    tat: '01:50'
  },
  {
    vendor: 'Vendor C',
    tat: '00:50'
  }
];



export const vendorcanceltrip = [{
    vendor: 'Vendor A',
    canceled: 50
  },
  {
    vendor: 'Vendor B',
    canceled: 150
  },
  {
    vendor: 'Vendor C',
    canceled: 200
  }
];



//  trip dashboard

export const triplist = [

  {
    name: 'open',
    value: 250
  },
  {
    name: 'completed',
    value: 200
  },
  {
    name: 'canceled',
    value: 30
  },
  {
    name: 'halted',
    value: 20
  },

];


export const vendortrip = [{
    name: 'vendor a',
    value: 250
  },
  {
    name: 'vendor b',
    value: 200
  },
  {
    name: 'vendor c',
    value: 30
  },
  {
    name: 'vendor d',
    value: 20
  },
];


export const besttrips = [{
    name: 'trip21521',
    tat: '00:20',
    process: 'Inbound'
  },
  {
    name: 'trip53552',
    tat: '00:30',
    process: 'Outbound'
  },
  {
    name: 'trip18282',
    tat: '00:25',
    process: 'Inbound'
  }
];


export const producttripoptions = [
  '30 Day', '60 Day', '90 Day'
];

export const tripcountfilter = [
  'Day Wise', 'Month Wise'
];


export const productwisetrip = [{
    product: 'Product A',
    trip: 20
  },
  {
    product: 'Product B',
    trip: 30
  },
  {
    product: 'Product C',
    trip: 50
  },
  {
    product: 'Product D',
    trip: 100
  },
  {
    product: 'Product E',
    trip: 150
  },
  {
    product: 'Product F',
    trip: 200
  },
];




export const processvehile = [{
    process: 'Inbound',
    vehicles: 258
  },
  {
    process: 'Outbound',
    vehicles: 210
  },
];



export const processtrip = [{
    process: 'Inbound',
    completed: 258,
    halted: 50
  },
  {
    process: 'Outbound',
    completed: 210,
    halted: 45
  },
];




//  stage tab data
export const worstages = [{
    stage: 'S1',
    planned: 0.5,
    actual: 1,
    variation: 1
  },
  {
    stage: 'S2',
    planned: 0.5,
    actual: 0.75,
    variation: 0.5
  },
  {
    stage: 'S3',
    planned: 1,
    actual: 1.5,
    variation: 0.5
  }
];


export const canceledTrip = [{
    stage: 'S1',
    completed: '200',
    canceled: '50',
    canceledPercent: '25%'
  },
  {
    stage: 'S2',
    completed: '150',
    canceled: '30',
    canceledPercent: '20%'
  },
  {
    stage: 'S3',
    completed: '100',
    canceled: '15',
    canceledPercent: '15%'
  },

];


export const optionFilter = [{
    name: 'Last 10 days',
    type: 'Days',
    number: 10
  },
  {
    name: 'Last 20 days',
    type: 'Days',
    number: 20
  },
];



export const optionmenu = [{
    name: 'last Month',
    type: 'Month',
    number: 1
  },
  {
    name: 'last 30 Days',
    type: 'Days',
    number: 30
  },
];


//  products table


export const product = [{
    name: 'product 1',
    TAT: '02:00',
    Trips: 200
  },
  {
    name: 'product 2',
    TAT: '01:00',
    Trips: 150
  },
  {
    name: 'product 3',
    TAT: '00:50',
    Trips: 300
  },

];


export const productTrips = [{
    name: 'product 1',
    Trip: 200
  },
  {
    name: 'product 2',
    Trip: 220
  },
  {
    name: 'product 3',
    Trip: 150
  },

];

export const tripoptionmenu = [{
    name: 'last Month',
    type: 'Month',
    number: 1
  },
  {
    name: 'last 30 Days',
    type: 'Days',
    number: 60
  },
];

export const tripoptionFilter = [{
    name: 'Last 7 Days',
    type: 'Days',
    number: 14
  },
  {
    name: 'Last Week',
    type: 'Week',
    number: 2
  }
];


export const worsttransporter = [{
    Transporter: 'T1',
    Feb: 200,
    Unique: 200,
    'Trip / Vehicle': 1
  },
  {
    Transporter: 'T2',
    Feb: 100,
    Unique: 200,
    'Trip / Vehicle': 1
  },
  {
    Transporter: 'T3',
    Feb: 50,
    Unique: 200,
    'Trip / Vehicle': 2
  }

];

export const worstthreetransporter = [{
    transporter: 'T1',
    Feb: 400,
    March: 150,
    '%Diff': '-63%'
  },
  {
    transporter: 'T2',
    Feb: 350,
    March: 200,
    '%Diff': '-43%'
  },
  {
    transporter: 'T3',
    Feb: 200,
    March: 150,
    '%Diff': '-25%'
  },

];


export const TAToptionFilter = [{
    name: '6 Month',
    type: 'Month',
    number: 6
  },
  {
    name: '9 Month',
    type: 'Month',
    number: 9
  }
];

export const transporterfilter = [{
    name: 'Week-Wise',
    type: 'Week',
    number: 2
  },
  {
    name: 'Month-Wise',
    type: 'Month',
    number: 2
  },
];


export const transporteroptiion = [{
    name: 'last 30 Days',
    type: 'Days',
    number: 60
  },
  {
    name: 'last 7 Days',
    type: 'Days',
    number: 14
  },
];

export const monthTatFilter = [{
    name: '6 Month',
    type: 'Month',
    number: 6
  },
  {
    name: '9 Month',
    type: 'Month',
    number: 9
  }
];

export const worsttransporterFilter = [{
    name: '6 Month',
    type: 'Month',
    number: 6
  },
  {
    name: '9 Month',
    type: 'Month',
    number: 9
  }
];

export const worststagefilter = [{
    name: 'Last 20 Days',
    type: 'Days',
    number: 20
  },
  {
    name: 'Last 30 Days',
    type: 'Days',
    number: 30
  }
]


//   stage dashboards

export const stageHeigheseDrops = [{
    'Stage name': 's5',
    'Last Week TAT': 1,
    ' 2nd Last Week TAT': 0.5,
    Drop: -1
  },
  {
    'Stage name': 's4',
    'Last Week TAT': 0.5,
    ' 2nd Last Week TAT': 0.35,
    Drop: -0.25
  },
  {
    'Stage name': 's3',
    'Last Week TAT': 0.5,
    ' 2nd Last Week TAT': 0.4,
    Drop: -0.1
  },
];



export const ReportData = [
    {
      "name": "Yard In",
      "id": "40",
      "trip_number": "Trip-38",
      "start_time": "2021-05-20T16:00:54.000+00:00",
      "vehicle_number": "RJ23-TT-4567",
      "transporter": {
        "code": "CRW",
        "name": "Cisco Raw Materials",
        "id": 10
      },
      "end_time": null,
      "tag_stage_workflow_id": 38,
      "vehicle": {
        "name": "Crane",
        "description": "This is a helping crane",
        "rsn": "RJ23-TT-4567",
        "type": "Helpers",
        "capacity": 34.0
      }
    },
    {
      "name": "Yard Out",
      "id": "40",
      "trip_number": "Trip-38",
      "start_time": "2021-05-20T16:00:54.000+00:00",
      "vehicle_number": "RJ23-TT-4567",
      "transporter": {
        "code": "CRW",
        "name": "Cisco Raw Materials",
        "id": 10
      },
      "end_time": null,
      "tag_stage_workflow_id": 38,
      "vehicle": {
        "name": "Crane",
        "description": "This is a helping crane",
        "rsn": "RJ23-TT-4567",
        "type": "Helpers",
        "capacity": 34.0
      }
    },
    {
      "name": "Get Out",
      "id": "40",
      "trip_number": "Trip-38",
      "start_time": "2021-05-20T16:00:54.000+00:00",
      "vehicle_number": "RJ23-TT-4567",
      "transporter": {
        "code": "CRW",
        "name": "Cisco Raw Materials",
        "id": 10
      },
      "end_time": null,
      "tag_stage_workflow_id": 38,
      "vehicle": {
        "name": "Crane",
        "description": "This is a helping crane",
        "rsn": "RJ23-TT-4567",
        "type": "Helpers",
        "capacity": 34.0
      }
    }
];

import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { Component, OnInit } from '@angular/core';
import { AlertService } from './../../theme/layout/header/alert-message/alert.service';
import { NgbCalendar, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import * as _ from 'lodash';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';

interface UserShifts {
  Date: Date;
  processId: number;
  StageId: number;
  ShiftId: number;
  UserId: any;
}
@Component({
  selector: 'app-shift-calendar',
  templateUrl: './shift-calendar.component.html',
  styleUrls: ['./shift-calendar.component.scss'],
})
export class ShiftCalendarComponent implements OnInit {
  states: string[] = [];

  // model: NgbDateStruct;
  date: { year: number; month: number };

  reportData = [];
  reportKeys = [];

  users: any = [];

  userCheck: any = [];
  stageName = [];

  stageData: any = [];

  currDate = new NgbDate(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    new Date().getDate()
  );
  shifts: any = [];

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  userList: any[] = [];
  processList: any[] = [];
  tempProcessDataList: any[] = [];
  shiftList: any[] = [];
  preSelectedUser: any = [];
  preSelectedShifts: any = [];
  selProcess: any;
  selProcessName: any;
  shiftName = '';
  selUser: any[] = [];

  usershifts: UserShifts = {
    Date: null,
    processId: 0,
    StageId: 0,
    ShiftId: 0,
    UserId: [],
  };
  showLoader: boolean = false;
  allUsersList: any[] = [];
  plantList: any[] = [];
  selectedPlant: any;
  selectedPlantName: any;

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private calendar: NgbCalendar,
    public common: CommonService,
    private alertService: AlertService,
    public userService: UserService,
    public breadcrumbService: BreadcrumbService,
    public plantService: PlantService,
    public roleService: RoleService
  ) {
    let breadcumbs = [
      {
        name: 'Home',
        url: '/user/dashboard',
      },
      {
        name: 'Shift Calendar',
        url: '/user/shift-calendar',
      },
    ];

    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.userCheck.forEach((element) => {
      element.status = false;
    });
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit() {
    this.common.sidebarToggled(true);

    if (this.userService.permissionMap.get('view_all_shifts_access') != true) {
      this.selUser.push(parseInt(this.userService._loggedInUser.id));
    }
    this.roleService.user_role == 'COMPANYADMIN'
      ? this.getPlantById()
      : this.getProcessList();
    this.getUserList();
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.common.sidebarToggled(false);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.getUserData();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  openLg(addUser, stageData, date, process) {
    this.usershifts.Date = date;
    this.usershifts.processId = process.process_id;
    this.usershifts.StageId = stageData.stage_id;
    this.shiftList = stageData.shifts;

    this.preSelectedUser = [];
    this.modalService.open(addUser, { size: 'lg' });
    document.getElementsByClassName('modal-lg')[0]['style'].maxWidth = '500px';
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantList = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  onSelectPlant(e: any) {
    this.selectedPlant = e.plantId;
    this.selectedPlantName = e.plantName;
    this.getProcessList();
  }

  getProcessList() {
    let params = {
      processActive: true,
      plantId: this.selectedPlant,
    };
    this.api
      .get('process/get-all-process', params)
      .toPromise()
      .then((res: any) => {
        if (res.status) {
          this.processList = res.data;
          this.tempProcessDataList = res.data;
          this.selProcess = res.data[0].processId;
          this.selProcessName = res.data[0].processName;
          this.getUserData();
        }
      });
  }

  setSelProcess(e: any) {
    this.selProcessName = e.processName;
    this.selProcess = e.processId;
    this.getUserData();
  }

  selectedUser(event: any) {
    this.usershifts.UserId = event.map((user) => user.userId);
  }
  selectedShift(event: any) {
    this.usershifts.ShiftId = event.shift_id;
    this.preSelectedUser = [];
    this.usershifts.UserId = [];
    event.users.forEach((user) => {
      this.preSelectedUser.push({
        userId: user.user_id,
        name: user.user_name,
      });
      this.usershifts.UserId.push(user.user_id);
    });
  }

  getUserList() {
    let apiUrl =
      this.roleService.user_role == 'COMPANYADMIN'
        ? 'user/all_user?userType=COMPANYADMIN'
        : 'user/all_user';
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        if (res.status) {
          this.userList = res.data;
          this.allUsersList = _.cloneDeep(this.userList);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  getUserData() {
    this.reportData = [];
    this.reportKeys = [];
    let apiUrl = 'shift/all-allocated-shifts';
    let params = {
      processId: [this.selProcess],
      startDate:
        this.fromDate.year +
        '-' +
        this.fromDate.month +
        '-' +
        this.fromDate.day,
      endDate:
        this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day,
      userIds: this.selUser,
      plantId: this.selectedPlant,
    };
    this.showLoader = true;
    this.api.post(apiUrl, params).subscribe((item: any) => {
      this.showLoader = false;
      if (item.status) {
        this.reportData = item.data[0];
        Object.keys(item.data).forEach((key, index) => {
          this.reportKeys.push(key);
        });
      }
    });
  }

  setSelectedUser(e: any, id: any) {
    if (e.target.checked) {
      this.selUser.push(id);
    } else {
      this.selUser = this.selUser.filter((user) => user != id);
    }
    this.getUserData();
  }

  saveShiftData(): void {
    let data = {
      shiftDate: this.usershifts.Date,
      processId: this.usershifts.processId,
      stageId: this.usershifts.StageId,
      shiftId: this.usershifts.ShiftId,
      userIds: this.usershifts.UserId,
    };

    this.api.post('shift/add-user-wise-shift', data).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.preSelectedUser = [];
          this.modalService.dismissAll();
          this.alertService.success('Shift Updated Succesfully');
          this.getUserData();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  filterProcess(event: any) {
    this.processList = this.tempProcessDataList.filter((p) =>
      p.processName.toLowerCase().includes(event.trim().toLowerCase())
    );
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CommonService } from '../../@core/services/common.service';
import { RoleService } from '../../@core/services/role.service';
import { TableService } from '../../@core/services/table.service';
import { UserService } from '../../@core/services/user.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from '../../theme/layout/header/breadcrumb/breadcrumb.service';
import { ApiService } from '../../@core/services/api.service';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import {
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-app-management',
  templateUrl: './app-management.component.html',
  styleUrls: ['./app-management.component.scss'],
})
export class AppManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'APP DETAILS LIST');
  fileEvent: any = [];
  ngAfterViewInit() {
    this.dtTrigger.next();
    // this.getPlant();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  btn = 'save';
  appDetails: any[] = [];
  appData: any = {
    appId: null,
    appVersion: '',
    appUrl: '',
    appUrlType: 1,
    releaseDate: '',
  };
  versionType: any = 'APK';
  appVersionValidator: any;
  appUrlValidator: any;

  constructor(
    private alertService: AlertService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService,
    public api: ApiService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'App', url: null },
      { name: 'Version Management', url: '/user/app-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
    this.getAppDetails();
  }

  saveAppDetails() {
    let params = {
      appId: this.appData.appId,
      appVersion: this.appData.appVersion,
      appUrl: this.appData.appUrl,
      appUrlType: this.versionType == 'APK' ? this.appData.appUrlType : 1,
      releaseDate: this.appData.releaseDate,
      versionType: this.versionType,
    };
    this.common.loading = true;
    this.api.post('apk/save-app-details', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertService.info(res.message);
          this.resetDetail();
          this.getAppDetails();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAppDetails() {
    this.common.loading = true;
    let params = {
      versionType: 'All',
    };
    this.api.get('apk/get-app-details', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.appDetails = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  deleteAppDetails(row) {
    this.common.loading = true;
    let params = 'appId=' + row.appId;
    this.api.get('apk/delete-app-details?' + params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.getAppDetails();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  resetDetail() {
    this.appData.appVersion = null;
    this.appData.appUrl = null;
    this.appData.appUrlType = 1;
    this.versionType = 'APK';
    this.appVersionValidator = null;
    this.appUrlValidator = null;
  }

  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'info' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  editRow(row?: any) {
    this.common.gotoTop();
    this.appData.appId = row.appId;
    this.appData.appVersion = row.appVersion;
    this.appData.appUrl = row.appUrl;
    this.appData.appUrlType = row.appUrlType;
    this.appData.releaseDate = row.releaseDate;
    this.versionType = row.versionType;
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete App Details ',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteAppDetails(row);
      }
    });
  }

  formValidator() {
    this.appVersionValidator = this.common.nullValidator(
      this.appData.appVersion,
      'App Version Mandatory'
    );
    this.appUrlValidator = this.common.nullValidator(
      this.appData.appUrl,
      'File Upload Mandatory'
    );
    if (!this.appVersionValidator.error && !this.appUrlValidator.error) {
      this.saveAppDetails();
    }
  }

uploadFile(event: any) {
    let frmData = new FormData();
    for (const file of event.target.files) {
      frmData.append('files', file);
      this.fileEvent.inProgress = true;
      this.api
        .post('functional/upload-file?methodId=' + 17, frmData)
        .subscribe((res: any) => {
          switch (res.data.type) {
            case HttpEventType.UploadProgress:
              this.fileEvent.progress = Math.round(
                (res.data.loaded * 100) / res.data.total
              );
              break;
            case HttpEventType.Response:
              return res.data;
          }
          res.data.forEach((e) => {
            this.appData.appUrl = e.fileUrl;
          });
        });
      catchError((error: HttpErrorResponse) => {
        this.fileEvent.inProgress = false;
        return `${this.fileEvent.data.name} upload failed.`;
      });
    }
  }

}

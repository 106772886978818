import { Component, OnInit } from '@angular/core';
import { ApiSelectorModifier } from 'datatables.net';
import { AlertService } from '../theme/layout/header/alert-message/alert.service';
import { ApiService } from '../@core/services/api.service';
import { CommonService } from '../@core/services/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-assign-material',
  templateUrl: './assign-material.component.html',
  styleUrls: ['./assign-material.component.scss']
})
export class AssignMaterialComponent implements OnInit {
  productList:any[]=[];
  productId:number=null;
  productName:string=null;
  productNames: any[]=[];
  constructor( public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal) {
    console.log("params",this.common.params);
    // this.productId=this.common.params.id;
    // this.productName=this.common.params.name;
    this.getProductsAssigned();
  }

  ngOnInit(): void {
  this.getAllProducts();
  }

  getProductsAssigned(){
    this.api.get("transaction/get-products-assigned?txnId="+this.common.params.transaction_id).subscribe((res: any) => {
      if (res.status) {
        this.productNames = res.data;
      }
    });
  }

  getAllProducts(){
    let params = {
      allProducts:false
    }
    this.api.get("product/get-product-list",params).subscribe((res: any) => {
      if (res.status) {
        this.productList = res.data;
      }
    });

  }

  selectProduct(event: any) {
    this.productId = event.id;
    this.productName=event.product_name;
  }
  assignProduct() {
    this.common.loading = true;
    this.api.get('transaction/assign-product?productId='+this.productId+'&txnId='+this.common.params.transaction_id).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.closeModal(this.productName,this.productId);
        }
      },
      (err: any) => {
        this.common.loading = false;
        this.closeModal(null);
      }
    );
  }

  closeModal(response?: any, id?: any) {
    this.activeModal.close({ response: response, id: id });
  }

  productValidator: any;
  formValidator() {
    this.productValidator = this.common.nullValidator(
      this.productId,
      'Product Mandatory'
    );
    if (!this.productValidator.error) {
      this.assignProduct();
    }
  }

}

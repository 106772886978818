export const environment = {
  production: true,

  // mainUrl: 'http://localhost:8115/api/main/',
  mainUrl: "https://cpldemo.axestrack.com/api/main/",
  utiltityUrl: "https://cpldemo.axestrack.com/api/utility/",
  hardwareUrl: "https://cpldemo.axestrack.com/api/hardware/",
  xsightUrl: "https://xsight.xswift.biz/x-sight/api/v1/",
  websiteUrl:"https://cpldemo.axestrack.com/#/",
  // websiteUrl:"http://localhost:4200/#/",
  firebase: {
    apiKey: "AIzaSyCssLabYqBlWhwcrt286RkJettVVlMy4wg",
    authDomain: "api-project-102453438543.firebaseapp.com",
    databaseURL: "https://api-project-102453438543.firebaseio.com",
    projectId: "api-project-102453438543",
    storageBucket: "api-project-102453438543.appspot.com",
    messagingSenderId: "102453438543",
    appId: "1:102453438543:web:48128ef68cf1c7427ee23a",
    measurementId: "G-HFKL0EWLZL"
  },

};

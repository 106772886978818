<div class="container-fluid">
    <div class="container-card">
      <div class="container-card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="header-block">
                  <h3 class="card-title">
                    {{'product_type'|translate}}
                  </h3>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                    <label for="my-input" class="required">{{'product_type name'|translate}}</label>
                    <input class="form-control" type="text" name="productTypeName" [(ngModel)]="productType.productTypeName">
                    <div class="error" *ngIf="productTypeNameValidator && productTypeNameValidator.error">
                      <span>{{productTypeNameValidator.msg}} </span>
                    </div>
                  </div>

                  <!-- <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                    <label for="" class="d-block" class="required"> {{'status'|translate}} </label>
                    <div class="form-check-inline">
                      <input class="form-check-input" type="radio" name="productTypeStatus"
                        [(ngModel)]="productType.productTypeStatus" id="product_type_status_1" value="ACTIVE" checked>
                      <label for="rpoduct_type_status_radios1">
                        {{'active'|translate}}
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <input class="form-check-input" type="radio" name="productTypeStatus"
                        [(ngModel)]="productType.productTypeStatus" id="productTypeStatus" value="INACTIVE">
                      <label for="product_type_status_radio2">
                        {{'inactive'|translate}}
                      </label>
                    </div>
                    <div class="error" *ngIf="statusValidator && statusValidator.error">
                      <span>{{statusValidator.msg}} </span>
                    </div>
                  </div> -->
                </div>

                <div class="form-group action-block">
                  <div class="btn-block">
                    <button type="submit" class="btn btn-muted" (click)=" common.backClicked()"> {{'back'|translate}}
                    </button>
                    <button class="btn btn-warning" (click)="resetproductType()"> {{'reset'|translate}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{buttonName|translate}}
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="header-block">
                  <h3 class="card-title">
                    {{'product_type list'|translate}}
                  </h3>
                </div>
              </div>
              <div class="card-body">
                <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                  <thead class="thead-light">
                    <tr>
                      <th>{{'serial_no'|translate}}</th>
                      <th>{{'product_type'|translate}}</th>
                      <th>{{'created_date'|translate}}</th>
                      <th class="action">{{'Actions'|translate}} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of responseData let i = index">
                      <td>{{ i+1 }}</td>
                      <td>{{row.label}}</td>
                      <td>{{row.created_date | date:'dd-MM-yyyy'}}</td>
                      <td class="action">
                        <div class="btn-block">
                          <!-- <button class="btn btn-primary" (click)="viewproductType(row)"
                            *ngIf="userService.permissionMap.get('view_process_type_access')==true"> {{'view'|translate}}
                          </button> -->
                          <button class="btn btn-success" (click)="editproductType(row)"
                            *ngIf="userService.permissionMap.get('edit_process_type_access')==true"> {{'edit'|translate}}
                          </button>
                          <button class="btn btn-danger" (click)="confirmDelete(row)"
                            *ngIf="userService.permissionMap.get('delete_process_type_access')==true">
                            {{'delete'|translate}} </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

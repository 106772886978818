import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { MapService } from 'src/app/@core/services/map.service';
declare let google: any;

@Component({
  selector: 'app-google-map-autocomplete',
  templateUrl: './google-map-autocomplete.component.html',
  styleUrls: ['./google-map-autocomplete.component.scss'],
})
export class GoogleMapAutocompleteComponent implements OnInit {
  @Input() addressType: string;
  @Input() class: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;

  constructor(private mapService: MapService, private router:Router) {}

  ngOnInit() {}

  ngAfterViewInit() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // If geolocation is enabled, call the function to get place autocomplete
        this.getPlaceAutocomplete();
        console.log("called")
      },
      (error) => {
        // If geolocation is disabled or blocked, handle the error here
        console.log('Geolocation is disabled or blocked.');
      }
    );
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        types: 'address', // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      let lat = place.geometry.location.lat();
      let lng = place.geometry.location.lng();
      let latLong = { lat: lat, lng: lng };
      // this.router.navigate(['user/plant-geofence'],{
      //   queryParams:latLong
      // })
      // this.mapService.centerAt(latLong);
      // this.mapService.placeSingleMarker(latLong);
      // console.log(latLong);
      // console.log("placing marker",place)
      this.invokeEvent({obj:place,latlong:latLong});
    });
  }

  invokeEvent(place: any) {
    this.setAddress.emit(place);
  }
  resetValue(){
    this.autocompleteInput='';
    this.addresstext.nativeElement.value='';
  }
}

<div class="card">
  <div class="card-header">
    <h3 class="card-title">
      {{ "insurance" | translate }}
    </h3>
    <button type="button" class="btn btn-success" (click)="addInsurance()">+ {{"add_new"}}</button>
  </div>

  <div class="card-body">
    <div class="message" *ngIf="insuranceList.length==0  && !common.loading"
      style="display: flex;text-align: center;font-weight: bold;color: #337ab7;justify-content: space-around;">
      Add Insurance For Asset!
    </div>

    <table class="custom-table" *ngIf="insuranceList.length>0">
      <thead>
        <tr>
          <th>{{ "policy_no" | translate }}</th>
          <th>{{ "insurance_company" | translate }}</th>
          <th>{{ "contact_person" | translate }}</th>

          <th>{{ "start_date" | translate }}</th>
          <th>{{ "end_date" | translate }}</th>
          <th class="action">{{ "action" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of insuranceList; let i = index">
          <td>#{{ row.policy_no }}</td>
          <td>{{ row.insurance_company_name }}</td>
          <td>{{ row.contact_person_name }} - {{ row.contact_person_no }}</td>

          <td>{{ row.start_date }}</td>
          <td>{{ row.end_date }}</td>
          <td class="action">
            <div class="btn-block">
              <button class="btn btn-primary" (click)="viewInsuranceDetails(row)">{{"view" | translate}}</button>
              <button class="btn btn-danger" (click)="confirmDelete(row)">{{"detach" | translate}}</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
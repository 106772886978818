<div class="container-fluid">

  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_stage_destination_code_type_access')==true ||userService.permissionMap.get('edit_stage_destination_code_type_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{(title | lowercase) | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"process->stage" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="process" display="order_name"
                    placeholder="Choose Process Stage" [preSelected]="{order_name:processName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error">
                    <span>{{processValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"destination_code" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedDestination($event)" [data]="filtered_destination"
                    display="stage_destination_code" placeholder="Choose Destination Code"
                    [preSelected]="{stage_destination_code:destinationName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="destinationValidator && destinationValidator.error">
                    <span>{{destinationValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required">{{"occupancy_status" | customTranslate}}</label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageDestinationCodeOccupancyStatus"
                      [(ngModel)]="stageDestinationCodeOccupancy.stageDestinationCodeOccupancyStatus"
                      id="stage_status_1" value="FREE" checked>
                    <label for="exampleRadios1">
                      Free
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="stageDestinationCodeOccupancyStatus"
                      [(ngModel)]="stageDestinationCodeOccupancy.stageDestinationCodeOccupancyStatus"
                      id="stage_status_4" value="MAINTENANCE">
                    <label for="exampleRadios2">
                      In Maintenance
                    </label>
                  </div>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | customTranslate}}
                  </button>
                  <button class="btn btn-warning" (click)="resetStageDestinationCodeOccupancy()"> {{"reset" |
                    customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{buttonName |
                    customTranslate}}</button>
                </div>
              </div>

            </div>

          </div>
        </div>


      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"stage_destination code occupancy list" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"s.no." | customTranslate}}</th>
                    <th>{{"stage_name" | customTranslate}}</th>
                    <th>{{"process_name" | customTranslate}}</th>
                    <th>{{"destination_code" | customTranslate}}</th>
                    <th>{{"status" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of occupancyData let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.stage_name}}</td>
                    <td>{{row.process_name}}</td>
                    <td>{{row.code_name}}</td>
                    <td>{{row.status}}</td>

                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewStageDestinationCodeOccupancy(row)"
                          *ngIf="userService.permissionMap.get('view_stage_destination_code_type_access')==true">
                          {{"view" | customTranslate}}</button>
                        <button class="btn btn-success" (click)="editStageDestinationCodeOccupancy(row)"
                          *ngIf="userService.permissionMap.get('edit_stage_destination_code_type_access')==true">
                          {{"edit" | customTranslate}} </button>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>



    </div>
  </div>
</div>

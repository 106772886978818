<div class="container-fluid">
  <div class="container-card group-card">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header" style="display: flex; align-items: center; justify-content: space-between;">
            <div class="header-block" style>
              <h3 class="card-title">
                {{ "Custom Fields" | customTranslate }}
              </h3>
            </div>
            <button type="submit" class="btn btn-success" (click)="addEditField(null)">{{"Add Custom Field" |
              customTranslate}}</button>
          </div>

          <div class="card-body">
            <div class="row">
              <div *ngIf="role.user_role=='AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input">{{ "select_company" | customTranslate }}</label>
                <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                  display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                </ps-auto-suggestion>
                <!-- <div class="error" *ngIf="companyNameValidator && companyNameValidator.error">
                    <span>{{ companyNameValidator.msg }} </span>
                  </div> -->
              </div>
              <div *ngIf="role.user_role=='AXESTRACK' || role.user_role=='COMPANYADMIN'"
                class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input">{{ "select_plant" | customTranslate }}</label>
                <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                  placeholder="Choose Plant" [preSelected]="{ plantName: plantName }">
                </ps-auto-suggestion>
                <!-- <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{ plantNameValidator.msg }} </span>
                  </div> -->
              </div>
              <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex align-items-end"
                *ngIf="role.user_role=='AXESTRACK' || role.user_role=='COMPANYADMIN'">
                <button class="btn btn-warning" (click)="resetCompanyPlant()"> {{ "reset" | customTranslate }} </button>
              </div>
            </div>
            <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead class="thead-dark">
                <tr>
                  <th>{{ "s.no." | customTranslate }}</th>
                  <th>{{ "Field Name" | customTranslate }}</th>
                  <th>{{ "Field Type" | customTranslate }}</th>
                  <th>{{ "Categories" | customTranslate }}</th>
                  <th *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{ "plant_name" | customTranslate }}
                  </th>
                  <th *ngIf="role.user_role=='AXESTRACK'">{{ "company_name" | customTranslate }}</th>
                  <th class="action">{{ "" | customTranslate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of groupedDataByCategoryName | keyvalue; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ row.key }}</td>
                  <td>{{ row.value[0].field_type }}</td>
                  <td>{{row.value | formatNames:{key:'asset_category_name'} }}</td>
                  <td *ngIf="role.user_role=='AXESTRACK'||role.user_role=='COMPANYADMIN'">{{ row.value[0].plant_name }}
                  </td>
                  <td *ngIf="role.user_role=='AXESTRACK'">{{ row.value[0].company_name }}</td>
                  <td class="action">
                    <div class="btn-block" style="text-align: right">
                      <button class="btn btn-success" style="padding: 13px !important;" (click)="editMapping(row)"
                        *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.value[0].plant_id!=1)
                        || (plantLevelRoles.includes(role.user_role) && (row.value[0].plant_id != null && row.value[0].plant_id!=1))">
                      </button>
                      <button class="btn btn-danger" style="padding: 13px !important;"
                        (click)="confirmDeleteMapping(row)"
                        *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.value[0].plant_id!=1)
                        || (plantLevelRoles.includes(role.user_role) && (row.value[0].plant_id != null && row.value[0].plant_id!=1))">
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ refData.countType}} {{'Employees' | customTranslate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive pt-2">
        <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th>{{"Employee Id" | customTranslate}}</th>
              <th>{{"user_name" | customTranslate}}</th>
              <th>{{"mobile_no." | customTranslate}}</th>
              <th *ngIf="refData.countType != 'out'">{{"Punch In Time" | customTranslate}}</th>
              <th>{{"status" | customTranslate}}</th>
              <th *ngIf="refData.countType != 'out'">{{'punch_type'|customTranslate}}</th>
              <th *ngIf="refData.countType == 'total'">{{'user status'|customTranslate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of employeeDetails; index as i">
              <td>{{row.employee_id}}</td>
              <td>{{row.user_name}}</td>
              <td>{{row.mobile_number}}</td>
              <td *ngIf="refData.countType != 'out'"> <span [ngClass]="{'info-jellybean':row.start_time != null}">{{row.start_time ?? '-'}}</span></td>
              <td><span class="green-jellybean">{{row.user_status}}</span></td>
              <td *ngIf="refData.countType != 'out'">
                <ng-container *ngIf="row.punch_type==null">{{'-'}}</ng-container>
                <img src="assets/images/face.png" alt="" height="30px"
                  width="30px"  *ngIf="row.punch_type==10">
                  <img src="assets/images/thumb.png" alt="" height="30px"
                  width="30px" *ngIf="row.punch_type==2">
                  <img src="assets/images/card.png" alt="" height="30px"
                  width="30px" *ngIf="row.punch_type==4">
              </td>
              <td *ngIf="refData.countType == 'total'">
                <span [ngClass]="{'early':row.start_time != null,'delay':row.start_time == null}">
                  {{row.start_time == null ? 'Outside Plant' : 'Inside Plant'}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="action_data_div" *ngIf="this.employeeDetails.length == 0 && !common.loading">
        <p style="color: red; display: flex; justify-content: center">
          No Record Found !!
        </p>
      </div>
    </div>
  </div>
</div>

<table class="custom-table" id="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="trigger">
  <!-- <ng-container *ngIf="!toggleTable"> -->
  <thead>
    <tr>
      <th *ngIf="showSerialNo">{{'serial_no' | customTranslate}}</th>
      <th *ngFor="let col of columns;let i = index;">
        <div class="table-header">
          <ng-container *ngIf="col.columnFormatter; else colTemplate;">
            <div [innerHTML]="col.columnFormatter(col, i) | htmlSanitize:'html'"></div>
          </ng-container>
          <ng-template #colTemplate>
            {{col.columnLabel | customTranslate}}
          </ng-template>
          <ng-container *ngFor="let action of col.headerActions;">
            <div [innerHTML]="action.actionFormatter(col, null, i) | htmlSanitize:'html'"
              (click)="action?.handleClick ? action.handleClick(col, null, i, action.actionName) : null"
              (mouseover)="action?.handleMouseOver ? action.handleMouseOver(col, null, i, action.actionName) : null"
              (mouseleave)="action?.handleMouseLeave ? action.handleMouseLeave(col, null, i, action.actionName) : null"></div>
          </ng-container>
        </div>
      </th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let row of data;let i = index;">
      <td *ngIf="showSerialNo">{{i+1}}</td>
      <td *ngFor="let col of columns;let colIdx = index;">
        <div class="table-data">
          <ng-container *ngIf="col.dataFormatter; else dataTemplate;">
            <div [innerHTML]="col.dataFormatter(row, col, i) | htmlSanitize:'html'"></div>
          </ng-container>

          <ng-template #dataTemplate>
            {{row[col.columnName]}}
          </ng-template>
          <div *ngIf="col.dataActions && col.dataActions.length" class="table_data_actions">
            <ng-container *ngFor="let action of col.dataActions;">
              <div [innerHTML]="action.actionFormatter(col, row, i) | htmlSanitize:'html'" *ngIf="!action.handleVisibility || action.handleVisibility(col, row, i, action.actionName)"
                (click)="action?.handleClick ? action.handleClick(col, row, i, action.actionName) : null"
                (mouseover)="action?.handleMouseOver ? action.handleMouseOver(col, row, i, action.actionName) : null"
                (mouseleave)="action?.handleMouseLeave ? action.handleMouseLeave(col, row, i, action.actionName) : null"></div>
            </ng-container>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
  <!-- </ng-container> -->
</table>
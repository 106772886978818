<div class="container-fluid">

  <div c  lass="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                {{"action_dashboard"| translate}}
              </h4>
            </div>

            <div class="card-body">
              <div class="tab_btnn_sec">
                <div class="tab_btnn" *ngFor="let tab of actionLabels;index as i">
                  <button type="button" class="btn btn-block nav-buttons" (click)="changeTabs(i , tab)"
                    [ngClass]="{'btn-inactive':activeTab != i,'btn-active':activeTab == i}" *ngIf="tab=='pending'?userService.permissionMap.get('view_pending_access')==
                    true:tab=='completed'?userService.permissionMap.get('view_completed_access')==
                    true:userService.permissionMap.get('view_rejected_access')==true"
                    [ngStyle]="{'background-color': tab.tabBgColor}">
                    <div class="left_part">
                      <div class="tab_name">{{tab.tabName | translate}}</div>
                      <div class="tab_number">{{tab.tabName == 'pending' ? listActionCount?.pendingcount : (tab.tabName
                        == "completed" ? listActionCount?.passcount : listActionCount?.failcount)}}</div>
                    </div>

                    <div class="right_part">
                      <div class="icon_div" [ngStyle]="{'background-color': tab.tabIconBg}">
                        <i [class]="'fa fa-'+tab.tab_icon" [ngClass]="tab.tabName"></i>
                      </div>
                    </div>
                  </button>
                </div>
              </div>

              <div class="row" *ngIf="userService.permissionMap.get('view_pending_access')==true">
                <div class="col-md-12">
                  <div class="table-responsive pt-2">
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{'serial_no'|translate}}</th>
                          <th>{{'title'|translate}}</th>
                          <th>{{'Stage'|translate}}</th>
                          <th>{{'name'|translate}}</th>
                          <th>{{'Process'|translate}}</th>
                          <th>{{'created_at'|translate}}</th>
                          <th>{{(activeTabName == 'pending' ? 'current_status' : 'previous_status') | translate}}</th>
                          <th>{{'Overriding Status'|translate}}</th>
                          <th>{{'Trip Type'|translate}}</th>
                          <th>{{'Actions'|translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of filterList;index as i">
                          <td>{{i+1}}</td>
                          <td> {{item.actionName ? item.actionName : "-"}}</td>
                          <td>{{item.stageName?item.stageName : "-"}}</td>
                          <td class="trip_detail">
                            <div class="v_number">
                              {{item.vehicleNo!=null? item.vehicleNo : item.name}}
                            </div>
                            <div class="image">
                              <img class="icon" src="assets/images/trip-details(3).png" alt=""
                                (click)="tripModal(item)">
                              <img class="icon" src="assets/images/trip-tracking(3).png" alt=""
                                (click)="tripTrackingModal(item)">
                                <app-trip-comment-trail [tripId]="item.transactionId"></app-trip-comment-trail>
                            </div>
                          </td>
                          <td>{{item.processName ? item.processName:"-"}}</td>
                          <td>{{item.created_date ? (common.dateFormatter(item.created_date,"ddMMYYYY")):"-"}}</td>
                          <td> <span [ngClass]="{'green-jellybean':item.responseStatus == 'PASS','red-jellybean':item.responseStatus == 'FAIL','yellow-jellybean':item.responseStatus == 'PENDING'}">{{(item.responseStatus|translate)}}</span>
                          </td>

                          <td> <span [ngClass]="{'green-jellybean':item.overridingStatus == 'PASS','red-jellybean':item.overridingStatus == 'FAIL','yellow-jellybean':item.overridingStatus == 'PENDING'}">{{item.overridingStatus
                              ? (item.overridingStatus|translate) : "-"}}</span>
                          </td>
                          <td> <span [ngClass]="{'info-jellybean-2':item.transaction_type == 'visit','info-jellybean':item.transaction_type == 'trip'}">{{item.transaction_type
                              ? (item.transaction_type|translate) : "-"}}</span>
                          </td>
                          <td class="action">
                            <div class="btn-block">
                              <ng-container *ngIf="activeTabName == 'pending'">
                                <ng-container *ngIf="item.actionOperationType!='form'">
                                  <button class="btn btn-approve" aria-hidden="true" title="Approve"
                                    *ngIf="item.responseStatus=='PENDING'" (click)="updateStatus(item , 'PASS')">
                                    <i class="fa fa-thumbs-up"></i> {{'approve'|translate}}
                                  </button>

                                  <button class="btn btn-reject" aria-hidden="true" title="Reject"
                                    *ngIf="item.responseStatus=='PENDING'" (click)="updateStatus(item , 'FAIL')">
                                    <i class="fa fa-window-close"></i> {{'reject'|translate}}
                                  </button>
                                </ng-container>

                                <!-- (click)="updateOverridingStatus(item)" -->
                                <button class="btn btn-override" aria-hidden="true" title="Overriding"
                                  *ngIf="item.responseStatus=='FAIL' && item.overridingStatus=='PENDING'" (click)="updateOverridingStatus(item)">
                                  <i class="fa fa-exclamation-circle"></i> {{'override'|translate}}
                                </button>

                                <button class="btn btn-form" aria-hidden="true" title="Form"
                                  *ngIf="item.actionOperationType=='form' && item.overridingStatus==null"
                                  (click)="openForm(item)" style="background-color: rgb(3, 167, 3);">
                                  <i class="fa fa-clipboard clip_pass"></i> {{'perform'|translate}}
                                </button>
                              </ng-container>

                              <button class="btn btn-info-icon" aria-hidden="true" title="Form"
                                (click)="actionDetail(item,'pre',item.stageId,item.transactionId)">
                                <i class="fa fa-info-circle"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

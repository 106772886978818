import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { UserService } from 'src/app/@core/services/user.service';
import { TableService } from '../../@core/services/table.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { BreadcrumbService } from './../../theme/layout/header/breadcrumb/breadcrumb.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { AlertRemarkComponent } from 'src/app/modals/alert-remark/alert-remark.component';
import { AlertLogInfoModalComponent } from '../alert-log-info-modal/alert-log-info-modal.component';

@Component({
  selector: 'app-alert-task-dashboard',
  templateUrl: './alert-task-dashboard.component.html',
  styleUrls: ['./alert-task-dashboard.component.scss'],
})
export class AlertTaskDashboardComponent implements OnInit {
  // actionLabels: any[] = ['pending', 'completed', 'rejected'];
  activeTab: number = 0;
  filtervalue: string = '';
  actionsByUserList: any = [];
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 3, 'Alert Task Dashboard');
  filterList: any = [];
  activeIndex: number = -1;
  disabled = false;
  latitude: any = null;
  longitude: any = null;
  activeTabName: string = 'ACTIVE';
  closeResult: string = '';
  listActionCount: any;
  startDate: any = new Date(new Date().setDate(new Date().getDate() - 7));
  endDate: any = new Date();
  actionLabels: any[] = [];
  alertTasks: any[] = [];

  activeColors: any[] = [
    { bgColor: '#88cefa', iconColor: '#109cf5' },
    { bgColor: '#bfd5d2', iconColor: '#7faba4' },
    { bgColor: '#93edf1', iconColor: '#27dbe2' },
    { bgColor: '#ffcac5', iconColor: '#ff958a' },
    { bgColor: '#eff38f', iconColor: '#dfe71f' },
  ];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private table: TableService,
    public common: CommonService,
    public api: ApiService,
    private modalService: NgbModal,
    public userService: UserService,
    public alert: AlertService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Alert Task Dashboard', link: '/user/alert-task-dashboard' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.breadcrumbService.setActiveBreadCrumb(false);
    /** breadcums implementation end*/
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.getAlertTasks();
    this.getStatusWithCount();
  }

  getActiveTab(event) {
    this.dtOptions = this.table.options(10, 3, 'Alert Task Dashboard');
    this.dtTrigger.unsubscribe();
    this.dtTrigger.next();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {}

  getStatusWithCount() {
    this.common.loading = true;
    this.api.get('alert/list-alert-status-count').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.actionLabels = res.data;
          // Add tabBgColor and tabIconBg to each object
          for (let i = 0; i < this.actionLabels.length; i++) {
            this.actionLabels[i].tabIconBg = this.activeColors[i].iconColor;
            this.actionLabels[i].tabBgColor = this.activeColors[i].bgColor;
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  changeTabs(i: any, tab: any) {
    this.activeTab = i;
    this.startDate = this.common.dateFormatter(
      new Date(new Date().setDate(new Date().getDate() - 7)),
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(new Date(), 'YYYYMMDD', false);
    this.activeTabName = tab.status;
    this.getAlertTasks();
    this.getStatusWithCount();
  }

  getAlertTasks() {
    this.common.loading = true;
    let url = '';
    if (this.activeTabName == 'ACTIVE' || this.activeTabName == 'INPROGRESS') {
      url = this.activeTabName;
    } else {
      url =
        this.activeTabName +
        '&startDate=' +
        this.startDate +
        '&endDate=' +
        this.endDate;
    }
    this.api.get('alert/list-alert-tasks?status=' + url).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertTasks = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  viewData(item: any) {
    const activeModal = this.modalService.open(AlertLogInfoModalComponent, {
      size: 'lg',
    });
    activeModal.componentInstance.refData = {
      alertLogId: item.alert_log_id,
      alertTitle: item.title,
      entity: item.map_on,
      condition: item.alert_condition,
    };
    activeModal.result.then((data: any) => {
      this.getAlertTasks();
      this.getStatusWithCount();
    });
  }

  openForm(item: any) {
    this.common.params = {
      item: item,
      alertStatus: this.actionLabels,
      type: 'PERFORM',
    };
    const activeModal = this.modalService.open(AlertRemarkComponent, {
      size: 'lg',
    });
    activeModal.result.then((data: any) => {
      this.getAlertTasks();
      this.getStatusWithCount();
    });
  }

  updateAlertStatus(item: any, type: string) {
    console.log(this.actionLabels)
    let statusId = this.actionLabels.filter(
      (action) => action.status == 'INPROGRESS'
    )[0].id;
    let params = {
      type: type,
      alertLogId: item.alert_log_id,
      // updatedStatus: 'INPROGRESS',
      statusId: statusId,
    };
    this.api.post('alert/update-alert-status', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.getAlertTasks();
          this.getStatusWithCount();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

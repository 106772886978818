<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1,false)">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">{{ title | customTranslate}}</h5>
</div>

<div class="modal-body">
  <div class="title">{{"field_types" |customTranslate}}</div>

  <div class="field-type">
    <button *ngFor="let field of fieldTypes" (click)="selectFieldType(field.fieldType)"
      [ngClass]="{'selected': field.fieldType === customField.type}">
      <i [class]="'fas fa-'+field.fieldIcon" [ngClass]="field.fieldType"></i>
      <span>{{ field.fieldType }}</span>
    </button>
  </div>

  <div class="row">
    <div class="form-group col-xl-4 col-lg-4 col-sm-12">
      <label for="my-input" class="required">{{'field_name'|customTranslate}} </label>
      <input type="text" class="form-control" name="name" required placeholder="Enter Master Field Name"
        [(ngModel)]="customField.name" #name="ngModel" autocomplete="off">
      <div class="error" *ngIf="fieldNameValidator && fieldNameValidator.error">
        <span>{{fieldNameValidator.msg}} </span>
      </div>
    </div>

    <div class="form-group col-xl-4 col-lg-4 col-sm-12">
      <label for="my-input">{{"category" | customTranslate}}</label>
      <select name="category" id="#category" class="form-control" [(ngModel)]="categoryValue"
        (click)="selectedCategoryType()">
        <option value="0">{{"selective_category" | customTranslate}}</option>
        <option value="1" selected>{{"all_category" | customTranslate}}</option>
      </select>
    </div>

    <!-- //use of ng class for disabled-div class because no direct negation; -->
    <div class="form-group col-xl-4 col-lg-4 col-sm-12"
      [ngClass]="{'disabled-div': customField.type !== 'SELECTLISTSINGLE' && customField.type !== 'SELECTLISTMULTIPLE' && customField.type !== 'RADIOBUTTON' && customField.type !== 'CHECKBOX'}">
      <label for="my-input">{{"choices"| customTranslate}}</label>
      <ps-auto-suggestion (onSelected)="createdField($event)" [data]='[]' display="name"
        [preSelected]="customField.values" placeholder="Enter Choices" isMultiSelect="true" [onInputTrigger]="true">
      </ps-auto-suggestion>
      <div class="error" *ngIf="choiceValidator && choiceValidator.error">
        <span>{{choiceValidator.msg}} </span>
      </div>
    </div>

    <div class="form-group col-xl-12 col-lg-12 col-sm-12" *ngIf="showCategory">
      <label for="my-input">{{"select_category"| customTranslate}}</label>
      <ps-auto-suggestion (onSelected)="selectedCategory($event)" [data]='categoryList' display="asset_category_name"
        [preSelected]="categ" placeholder="Choose category" isMultiSelect="true" id="enter-div">
      </ps-auto-suggestion>
      <div class="error" *ngIf="selectiveCategoryValidator && selectiveCategoryValidator.error">
        <span>{{selectiveCategoryValidator.msg}} </span>
      </div>
    </div>

    <div class="form-group col-xl-4 col-lg-4 col-sm-12 d-flex justify-content-between" *ngIf="!showCategory">
      <div>
        <label for="" class="d-block"> {{"mandatory" |customTranslate}} </label>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id='customSwitchesMandatory'
            [(ngModel)]="categories[0].is_mandatory">
          <label class="custom-control-label" for="customSwitchesMandatory"></label>
        </div>
      </div>

      <div>
        <label for="" class="d-block"> {{"unique"|customTranslate}} </label>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id='customSwitchesUnique'
            [(ngModel)]="categories[0].is_unique">
          <label class="custom-control-label" for="customSwitchesUnique"></label>
        </div>
      </div>
    </div>
  </div>

  <div class="scrollable_table">
    <table class="custom-table" *ngIf="categ.length>0">
      <thead>
        <tr>
          <th>{{"category" | customTranslate}}</th>
          <th>{{"mandatory" |customTranslate}}
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id='customSwitchesm' [(ngModel)]="allMandatory"
                (change)="onAllMandatory(allMandatory)">
              <label class="custom-control-label" for="customSwitchesm"></label>
            </div>
          </th>
          <th>{{"unique"|customTranslate}}
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id='customSwitchesU' [(ngModel)]="allUnique"
                (change)="onAllUnique(allUnique)">
              <label class="custom-control-label" for="customSwitchesU"></label>
            </div>
          </th>
          <th>{{"field_index"|customTranslate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of categ; let i = index">
          <td>
            <span>{{ item.asset_category_name }}</span>
            <div class="error" *ngIf="categoryListValidator[i] && categoryListValidator[i].error">
              <span>{{ categoryListValidator[i].msg }} </span>
            </div>
          </td>
          <td>
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" [id]="'customSwitchesmand'+i"
                [(ngModel)]="item.is_mandatory" (change)="onMandatoryFalse($event)">
              <label class="custom-control-label" [for]="'customSwitchesmand'+i"></label>
            </div>
          </td>
          <td>
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" [id]="'customSwitches'+i"
                [(ngModel)]="item.is_unique" (change)="onUniqueFalse($event)">
              <label class="custom-control-label" [for]="'customSwitches'+i"></label>
            </div>
          </td>
          <td>
            <input type="number" class="form-control" placeholder="Ranking Index" [(ngModel)]="item.ranking_index"
              min="1" (input)="checkChanged(item)">
            <div class="error" *ngIf="rankingIndexValidator[i] && rankingIndexValidator[i].error">
              <span>{{rankingIndexValidator[i].msg}} </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-warning" (click)="resetDetail()"> {{ "reset" | customTranslate }} </button>
  <button type="button" class="btn btn-success" (click)="formValidator()">{{"save" | customTranslate}}</button>
</div>

import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DashboardService } from '../dashboard/dashboard.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { TripTrackingLogsComponent } from 'src/app/modals/trip-tracking-logs/trip-tracking-logs.component';
@Component({
  selector: 'app-driver-tracker',
  templateUrl: './driver-tracker.component.html',
  styleUrls: ['./driver-tracker.component.scss'],
})
export class DriverTrackerComponent implements OnInit {
  tripstatus: any[] = [];
  statusColors: any[] = [
    'bg-primary',
    'bg-success',
    'bg-warning',
    'bg-dangder',
    'bg-info',
    'bg-muted',
    'bg-primary',
  ];
  vehiclelist: any[] = [];
  vehicleID: number = 0;
  vehicletimeline: any[] = [];
  selPlantId = null;
  plantList: any[] = [];
  vehicleData = [];
  @Input() breadcrumbs: boolean;
  @Input() public refData;

  transaction = {
    id: null,
    name: '',
  };
  name = '';
  vehicleTransactionData = [];
  vehicleTransactionStatus = [];
  vehicle = {
    id: null,
    name: null,
  };
  showHeader: boolean = true;
  personIcon:boolean = false
  isModal: boolean = false;
  constructor(
    public breadcrumbService: BreadcrumbService,
    public workflow: DashboardService,
    public role: RoleService,
    public api: ApiService,
    private activeModal: NgbActiveModal,
    public common: CommonService,
    private plantService: PlantService,
    private cdr: ChangeDetectorRef,
    public modalService: NgbModal,
  ) {
      let breadcumbs = [
        { name: 'Home', url: '/user/dashboard' },
        { name: 'Trips', url: '#' },
        { name: 'Trip Tracking', url: '/user/trip-activity' },
      ];
      this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnDestroy(): void {
    this.common.sidebarToggled(false);
  }

  vechileData(event: any) {
    this.vehicle.id = event.ipa_transaction_id;
    this.vehicle.name = event.vehicle_rsn;
    this.transaction.id = event.ipa_transaction_id;
    this.transaction.name = event.txnid;
    this.name = event.vehicle_rsn;
    this.getVehicleTransactionStatus(event);
    console.log("vehicle",this.vehicle.name,this.transaction.name)
  }

  selecttransaction(event) {
    this.transaction.id = event.ipa_transaction_id;
    this.transaction.name = event.txnid;
    this.vehicle.id = event.ipa_transaction_id;
    this.vehicle.name = event.vehicle_rsn;
    this.name = event.vehicle_rsn;
    this.getVehicleTransactionStatus(event);
    console.log("vehicle",this.vehicle.name,this.transaction.name)
  }
  selectPlant(event) {
    this.selPlantId = event.plantId;
    this.getVehicleTransaction();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    // this.common.sidebarToggled(true);
    if(this.refData!=null && this.refData.personIcon!=null){
    this.personIcon = this.refData.personIcon
    }

    this.breadcrumbService.setActiveBreadCrumb(this.breadcrumbs)
    if (this.role.user_role == 'COMPANYADMIN' && this.refData == null) {
      let params = {
        fetchAll: false,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.plantList = res.data;
          }
        },
        (err: any) => {
          console.error('Error: ', err);
        }
      );
    } else {
      if (this.refData != null) {
        this.isModal = this.refData.isModal;
        this.getVehicleTransactionStatus({
          ipa_transaction_id: this.refData.txnId,
        });
        this.showHeader = this.refData.showHeader;
        this.name = this.refData.vehicleName;
      } else {
        this.getVehicleTransaction();
      }
    }
    if(!this.showHeader){
      this.breadcrumbService.setActiveBreadCrumb(false)
    }
  }

  getVehicleTransaction() {
    this.common.loading = true;
    let params = {
      plantId: this.selPlantId,
    };
    this.api.get('transaction/get-vehicle-transaction-list', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.vehicleTransactionData = res.data;
          this.vehicleData = res.data;
          this.vehicle.id = res.data[0].ipa_transaction_id;
          this.vehicle.name = res.data[0].vehicle_rsn;
          this.transaction.id = res.data[0].ipa_transaction_id;
          this.transaction.name = res.data[0].txnid;
          this.name = res.data[0].vehicle_rsn;
          this.getVehicleTransactionStatus(res.data[0]);
          console.log("vehicle",this.vehicle.name,this.transaction.name,res.data[0])
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  reset() {}
  getVehicleTransactionStatus(event) {
    let params = {
      id: event.ipa_transaction_id,
    };
    this.common.loading = true;
    this.api
      .get('transaction/get-vehicle-transaction-status', params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.vehicleTransactionStatus = res.data;

            this.vehicleTransactionStatus.forEach((element) => {
              // element.attend_by=JSON.parse(element.attend_by);
              // element.attended_by.join(',')
              // if (element.attend_by == ' ' && element.modified_by == -1) {
              //   element.attend_by = 'System';
              // }
            });
            this.reset();
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  closeModal(res) {
    this.activeModal.close();
  }

  openTrackingLog(txnStageId:number) {
    const activeModal = this.modalService.open(TripTrackingLogsComponent, {
      size: 'xl',
    });
    activeModal.componentInstance.txnStageId =txnStageId;
    activeModal.result.then((res) => {
      // this.getQueueData(null);
    });

  }
}

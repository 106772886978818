<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">Audit Logs</h5>
</div>
<div class="modal-body">
  <div class="trip-card">
    <div class="card_sec" *ngFor="let item of auditInfo | keyvalue">
      <div class="left_sec">
        {{item.key}}:-
      </div>

      <div class="right_sec">
        {{item.value}}
      </div>
    </div>
  </div>

  <h5 class="stage-compare"> Compare Stages </h5>

  <div class="row audit-container">
    <div class="col-md-6 card" *ngFor="let item of logsReport;index as i">
      <div class="card-header">
        <h6>{{auditStages[i]['stage_name']}}</h6>
      </div>
      <div class="card-body" *ngFor="let log of item |keyvalue">
        {{checkImageType(log.value)}}
        <div class="key"> {{camel2title(log.key)}}</div>
        <ng-container *ngIf="image==false">
          <div class="value">{{log.value}}</div>
        </ng-container>
        <ng-container *ngIf="image==true">

          {{imageName(log.value)}}
          <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'" style="margin-left: 10px;">
            <div class="left_img">
              <img [src]="image_url" alt="">
            </div>
            <div style="width: 40%;">
              <span class="value left_img_text" (click)="displayImage(log.value.toString())">{{"
                "+this.image_name}}</span>
              <ng-container *ngFor="let item of latlongList">
                <div *ngIf="item.fieldName==log.key && item.stageId==auditStageIds[i]['stage_id']">
                  <img (click)="location(item.lat,item.lng)"
                    style="cursor:pointer; padding: 0px; background-color: transparent; width: auto;" height="15"
                    width="15" src="assets/images/location.png" />
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
            <div style="width: 40%;">
              <i class="fa fa-file" style="color:royalblue;margin-left: 5px;
              margin-top: 3px;"></i><span (click)="newTab(log.value.toString())" style="font-size:small;color: blue;
              text-decoration-line: underline;margin-left: 10px;">{{" "+this.image_name}}</span>
              <ng-container *ngFor="let item of latlongList">
                <div *ngIf="item.fieldName==log.key && item.stageId==auditStageIds[i]['stage_id']">
                  <img (click)="location(item.lat,item.lng)"
                    style="cursor:pointer; padding: 0px; background-color: transparent; width: auto;" height="15"
                    width="15" src="assets/images/location.png" />
                </div>
              </ng-container>
            </div>
          </ng-container>

        </ng-container>

      </div>
    </div>
    <div class="modal-footer" style="width: 100%">
      <div class="approve-btn pull-right"
        *ngIf="refData.status!='APPROVED' && auditInfo.status=='Completed' && refData.displayBtn==true">
        <button class="btn btn-success" type="button" outline (click)="updateAuditedActionStatus('APPROVED')"
          id="cancel" style="cursor: pointer;">{{"approve" | translate}}</button>
      </div>
      <div class="raiseConflict-btn pull-right"
        *ngIf="refData.status!='REJECTED' && auditInfo.status=='Completed'  && refData.displayBtn==true">
        <button class="btn btn-danger" type="button" outline (click)="updateAuditedActionStatus('REJECTED')" id="submit"
          style="cursor: pointer;">{{"raise_conflict" | translate}}</button>
      </div>


    </div>
    <app-data-not-found class="w-100" *ngIf="!logsReport?.length"></app-data-not-found>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { environment } from 'src/environments/environment';
import { ViewRemarkComponent } from 'src/app/modals/view-remark/view-remark.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { checklistDashboardData,checklistStatusCount } from 'src/app/dataType/checklistInterface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checklist-dashbaord',
  templateUrl: './checklist-dashbaord.component.html',
  styleUrls: ['./checklist-dashbaord.component.scss'],
})
export class ChecklistDashbaordComponent implements OnInit {
  activeTab: number = 0;
  userChecklistData: checklistDashboardData[] = [];
  startDate: any = new Date();
  endDate: any = new Date();
  pageUrl: string;
  statusWiseChecklistCount: checklistStatusCount;
  currentTime: any = new Date().toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });

  actionLabels: any[] = [
    {
      tabName: 'Pending',
      status: 'Pending',
      tab_icon: 'clock-o',
      tabBgColor: '#b9e9fa',
      tabIconBg: '#1da6e0',
    },
    {
      tabName: 'Completed',
      status: 'Approved',
      tab_icon: 'thumbs-up',
      tabBgColor: '#b0f6b0',
      tabIconBg: '#00bd00',
    },
    {
      tabName: 'Late Completed',
      status: 'Approved',
      tab_icon: 'check',
      tabBgColor: '#ebeb98',
      tabIconBg: '#a7a708',
    },
    {
      tabName: 'Rejected',
      status: 'Rejected',
      tab_icon: 'times',
      tabBgColor: '#f7d5d5',
      tabIconBg: '#d60000',
    }
  ];

  constructor(
    private breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public api: ApiService,
    public userService: UserService,
    public alert: AlertService,
    private modalService: NgbModal,
    public router : Router,
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);

  }

  ngOnInit(): void {
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );
    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.getUserChecklistData('Pending');
    this.getUserChecklistCount();
  }

  getUserChecklistCount() {
    this.common.loading = true;
    this.api.get('checklist-dashboard/get-user-checklist-count').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.statusWiseChecklistCount = res.data[0];
          console.log('this.statusWiseChecklistCount: ', this.statusWiseChecklistCount);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getUserChecklistData(checklistStatus: any){
    this.common.loading = true;
    let params = {
      status: checklistStatus,
      allPending: this.activeTab == 2 || this.activeTab == 1? this.activeTab: 0,
      startTime: this.startDate,
      endTime: this.endDate,
    };
    this.api.get('checklist-dashboard/get-user-checklist', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.userChecklistData = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

   changeTab(tab: any, i: any) {
    this.userChecklistData = [];
    this.activeTab = i;
    this.startDate = this.common.dateFormatter(new Date(), 'YYYYMMDD', false);
    this.endDate = this.common.dateFormatter(new Date(), 'YYYYMMDD', false);
    this.getUserChecklistData(tab);
  }

  performChecklistAction(data: any, type: any) {
    let combinedDate = `${data.scheduled_date}T${data.end_time}:00`;
    // let url =
    //   type == 'perform'
    //     ? environment.websiteUrl + 'user/checklist?permissionFlag=true&extraData='+ btoa(combinedDate)+'&secret='
    //     : environment.websiteUrl + 'user/view-checklist?secret=';
    // this.pageUrl = url + btoa(data.checklist_id) + '&secretLog=' + btoa(data.log_id);
    // console.log('this.pageUrl: ', this.pageUrl);
    // window.open(this.pageUrl, '_self');

    let baseUrl = type == 'perform' ? 'user/checklist': 'user/view-checklist';
    // Building query parameters
    let queryParams: any = {
      permissionFlag: type == 'perform' ? true : undefined,
      extraData: type == 'perform' ? btoa(combinedDate) : undefined,
      secret: btoa(data.checklist_id),
      secretLog: btoa(data.log_id)
    };
    // Filter out undefined query parameters
    queryParams = Object.keys(queryParams).filter(key => queryParams[key] !== undefined).reduce((obj, key) => {
      obj[key] = queryParams[key];
      return obj;
    }, {});
    this.router.navigate([baseUrl], { queryParams });
    console.log('Navigating to: ', baseUrl, 'with queryParams:', queryParams);
  }

  compareTargetTime(targetTimeValue: string,targetDate:string) {
    let combinedDate = `${targetDate}T${targetTimeValue}:00`;
    const targetDateTime = new Date(combinedDate);
    const currentTime = new Date();
    return targetDateTime>currentTime?false:true;
  }

  viewRemark(logId: number, checklist_title: string) {
    this.common.params = {
      log_id: logId,
      checklist_title: checklist_title,
      remarkType: 1,
    };
    this.modalService.open(ViewRemarkComponent, { size: 'xl' });
  }

  viewRejectedRemark(logId: number, checklist_title: string) {
    this.common.params = {
      log_id: logId,
      checklist_title: checklist_title,
      rejectedFlag:true,
    };
    this.modalService.open(ViewRemarkComponent, { size: 'xl' });
  }
}

import { TableService } from '../../@core/services/table.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { DataTableDirective } from 'angular-datatables';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { StageActionService } from '../stage-action/stage-action.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { Subject } from 'rxjs';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ApiService } from 'src/app/@core/services/api.service';
@Component({
  selector: 'app-stage',
  templateUrl: './stage-action.component.html',
  styleUrls: ['./stage-action.component.scss'],
})
export class StageActionComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Action List');
  ngAfterViewInit() {
    this.dtTrigger.next();
    if(this.role.user_role!='COMPANYADMIN')this.getAllStageAction();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  stageAction = {
    actionScope: 'local',
    actionId: '',
    actionName: '',
    actionStatus: 'ACTIVE',
    actionDescription: '',
    actionType: 0,
    actionResponseType: '0',
    processName: '',
    process: '',
    isGeoBounded: false,
    actionMapping: true,
    isMandatory:true,
    isVisible:true,
    isEditable:false
  };

  processId: number = 0;
  buttonName = 'save';
  title = 'Add Action';
  responseData: any[] = [];
  processList: any[] = [];
  companyName: any;
  plantName: any;
  companyId: any;
  plantId: any;
  plantList: any;
  companyList: any;
  methodsList: any = [];
  methodName:any=[];
  methodId:number=null;


  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    private stageActionService: StageActionService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService,
    public alertService: AlertService,
    public api: ApiService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Actions', url: null },
      { name: 'Action-Management', link: '/user/list_stage_action' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    if (this.role.user_role == 'AXESTRACK') {
      this.stageAction.actionScope = 'global';
    }else if(role.user_role == 'COMPANYADMIN'){
      // this.companyId=userService._loggedInUser.companyId;
      this.getPlantListByCompanyId(userService._loggedInUser.companyId);
    }
    this.plantId = this.userService._loggedInUser.plantId;
  }

  ngOnInit(): void {
    if (this.stageAction.actionScope == 'local') {
      this.getAllProcess();
    }
    this.getCompanyList();
    this.getAllMethods();
  }

  selectedMethod(event:any){
  console.log('event: ', event);
  this.methodName=event.methodName,
  this.methodId=event.methodId

  }

  selectProcess(event) {
    this.stageAction.process = event.processId;
    this.stageAction.processName = event.processName;
  }

  selectCompany(event) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantListByCompanyId(this.companyId);
  }

  selectPlant(event) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getAllProcess();
    this.getAllStageAction(this.plantId);
  }

  getCompanyList() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getPlantListByCompanyId(companyId) {
    this.common.loading = true;
    this.api.get('plant/all_plants?id=' + companyId).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantList = res.data;
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  // Save Work Type
  saveStageAction(): void {
    let data = {
      actionId: this.stageAction.actionId,
      actionName: this.stageAction.actionName,
      actionStatus: this.stageAction.actionStatus,
      actionDescription: this.stageAction.actionDescription,
      process:
        this.stageAction.actionScope == 'global'
          ? -1
          : this.stageAction.processName == 'All Process'
          ? null
          : this.stageAction.process,
      actionType: this.stageAction.actionType,
      actionResponseType: this.stageAction.actionResponseType,
      isGeoBounded: this.stageAction.isGeoBounded,
      actionMapping: this.stageAction.actionMapping,
      plantId: this.plantId,
      checker: this.buttonName,
      isMandatory:this.stageAction.isMandatory,
      isVisible:this.stageAction.isVisible,
      methodName:!this.stageAction.isVisible?this.methodName:null,
      methodId:!this.stageAction.isVisible?this.methodId:null,
      isEditable:this.stageAction.isEditable,
    };
    this.common.loading = true;
    this.stageActionService.saveStageAction(data).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.resetStageAction();
          if(this.role.user_role!='COMPANYADMIN')this.getAllStageAction();
        } else {
          this.alertService.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.plantId,
    };
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.processList = res.data;
          this.processList.unshift({
            processName: 'All Process',
            processId: -1,
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllStageAction(plantId?) {
    this.common.loading = true;
    let apiUrl = 'actions/get-all-actions?flag=false';
    if(plantId!=null){
      apiUrl = apiUrl + '&plantId=' + plantId;
    }
    this.api.get(apiUrl).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.responseData = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllMethods() : Promise<any> {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    let params = {
      plantId:this.plantId
    }
    this.api.get('method/get-all-methods-by-type', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if (res.data) {
            this.methodsList = res.data;
          }
          resolve(true)
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        reject(false)
      }
    );
  } );
}
  viewStageAction(row?: any) {
    this.common.params = { details: [row], title: 'Stage Action Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmDeleteAction(row?: any) {
    this.common.params = {
      title: 'Delete Action ',
      description:
        `<b>&nbsp;` + 'Are you sure, Do you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteStageAction(row);
      }
    });
  }

  deleteStageAction(row?: any) {
    if (row.actionOperationType == 'form') {
      row.actionOperationType = '1';
    } else {
      row.actionOperationType = '0';
    }
    if (row.actionMapping == 1) {
      row.actionMapping = true;
    } else {
      row.actionMapping = false;
    }
    this.common.loading = true;
    let data = {
      actionId: row.actionId,
      actionName: row.actionName,
      actionStatus: 'DELETED',
      actionDescription: row.actionDescription,
      process: row.processId,
      actionType: row.actionType,
      actionResponseType: row.actionOperationType,
      actionMethod: row.actionMethod,
      isGeoBounded: row.isGeoBounded,
      actionMapping: row.actionMapping,
      isMandatory:row.is_mandatory,
      isVisible:row.is_visible,
      methodName:row.method_name,
      methodId:row.visibility_method_id,
      isEditable:row.is_editable,
    };

    this.stageActionService.saveStageAction(data).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.getAllStageAction();
          this.alertService.success(res.message);
          }else {
            this.alertService.error(res.message);
          }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  editStageAction(row?: any) {
    this.common.gotoTop();
    this.common.gotoTop();
    this.resetStageAction();

    this.stageAction.actionId = row.actionId;
    this.stageAction.isGeoBounded = row.isGeoBounded;
    this.stageAction.actionName = row.actionName;
    this.stageAction.actionStatus = row.actionStatus;
    this.stageAction.actionDescription = row.actionDescription;
    this.stageAction.actionScope = row.processId == -1 ? 'global' : 'local';
    this.companyId = row.companyId;
    this.plantId = row.plantId;
    this.companyName = row.companyName;
    this.plantName = row.plantName;
    this.methodId=row.visibility_method_id;
    this.methodName=row.method_name;
    this.stageAction.isMandatory=row.is_mandatory;
    this.stageAction.isVisible=row.is_visible;
    this.stageAction.isEditable=row.is_editable;
    if (row.actionOperationType == 'form') {
      this.stageAction.actionResponseType = '1';
    } else {
      this.stageAction.actionResponseType = '0';
    }
    if (row.actionMapping == 1) {
      this.stageAction.actionMapping = true;
    } else {
      this.stageAction.actionMapping = false;
    }
    this.stageAction.actionType = row.actionType;
    this.stageAction.processName =
      row.processName == null ? 'All Process' : row.processName;
    this.stageAction.process = row.processId == null ? -1 : row.processId;
    this.stageAction.actionType = row.actionType;
    this.buttonName = 'update';
    this.title = 'Edit Action';
  }

  resetStageAction() {
    this.stageAction = {
      actionScope: this.role.user_role == 'AXESTRACK' ? 'global' : 'local',
      actionId: '',
      actionName: '',
      actionStatus: 'ACTIVE',
      actionDescription: '',
      actionType: 0,
      actionResponseType: '0',
      processName: '',
      process: '',
      isGeoBounded: false,
      actionMapping: true,
      isMandatory:true,
      isVisible:true,
      isEditable:false
    };
    this.companyId = null;
    this.plantId = null;
    this.companyName = null;
    this.plantName = null;
    this.actionNameValidator = null;
    this.processValidator = null;
    this.actionMethodValidator = null;
    this.buttonName = 'save';
    this.title = 'Add Action';
    this.companyNameValidator = null;
    this.plantNameValidator = null;
    this.methodNameValidator=null;
    this.methodName=null;
    this.methodId=null;
    this.methodsList=[];
    this.getAllMethods();
    if(this.role.user_role=='COMPANYADMIN'){
      this.responseData=[];
    }
  }

  selectActionType() {
    if (this.stageAction.actionType == 1) {
      this.actionMethodValidator = null;
    }
  }

  actionNameValidator: any;
  actionMethodValidator: any;
  processValidator: any;
  companyNameValidator: any;
  plantNameValidator: any;
  methodNameValidator:any;
  formValidator() {
    this.actionNameValidator = this.common.nullValidator(this.stageAction.actionName,'Action Name Mandatory');
    this.companyNameValidator = this.common.nullValidator(this.companyName,'Company Name is Mandatory');
    this.plantNameValidator = this.common.nullValidator(this.plantName,'Plant Name is Mandatory');
    this.methodNameValidator = this.common.nullValidator(this.methodId,'Method Mandatory');
    if (this.stageAction.actionScope == 'local') {
      this.processValidator = this.common.nullValidator(this.stageAction.process,'Process is Mandatory');
    }
    if(this.stageAction.isVisible)
    {if (!this.actionNameValidator.error && (this.processValidator == null || !this.processValidator.error) &&
        (this.  actionMethodValidator == null || !this.actionMethodValidator.error)) {
      this.saveStageAction();
    }}
    else{
      if (!this.actionNameValidator.error && (this.processValidator == null || !this.processValidator.error) &&
        (this.actionMethodValidator == null || !this.actionMethodValidator.error) && !this.methodNameValidator.error) {
      this.saveStageAction();
    }
    }
  }
}

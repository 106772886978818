import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { MapService } from 'src/app/@core/services/map.service';
declare var google: any;

@Component({
  selector: 'app-map-view-modal',
  templateUrl: './map-view-modal.component.html',
  styleUrls: ['./map-view-modal.component.scss']
})
export class MapViewModalComponent implements OnInit {


  path: any[];
  mapZoomLevel: number = 17;
  circlesLength: number = 0;
  rowData:any;
  constructor(
    private mapService: MapService,
    private common: CommonService,
    private activeModal: NgbActiveModal
  ) {
    this.rowData=this.common.params.rowData;

  }

  ngOnInit(): void {


  }


  // clearMapServices() {
  //   this.mapService.clearAll();
  //   this.mapService.resetMarker();
  //   this.mapService.resetCircles();
  //   this.mapService.resetBounds();
  //   this.initializeMap();
  // }

  // initializeMap() {
  //   // this.getLocation();
  //   // this.mapService.mapIntialize(
  //   //   'map',
  //   //   this.mapZoomLevel,
  //   //   this.site.lat || this.userLocation.lat,
  //   //   this.site.lng || this.userLocation.long
  //   // );
  //   this.mapService.createPolygonPath();

  //   setTimeout(() => {
  //     this.mapService.setMapType(0);
  //   }, 200);
  //   document.getElementById('map').addEventListener('wheel', (event) => {
  //     this.mapZoomLevel = this.mapService.map.getZoom();
  //   });

  //   // this.mapService.addListerner(this.mapService.map, 'click', (event) => {
  //   //   if (this.mapService.isDrawMarker) {
  //   //     this.mapService.markers = [];
  //   //     this.site.lat = event.latLng.lat();
  //   //     this.site.lng = event.latLng.lng();
  //   //     this.placeMarkerRadius();
  //   //   }
  //   // });
  // }

  // getMapZoomLevel(event) {
  //   let zoomOutButton =
  //     document.querySelector("[aria-label='Zoom out']") || null;
  //   let zoomInButton = document.querySelector("[aria-label='Zoom in']") || null;
  //   let zoomClicked = [zoomOutButton, zoomInButton].includes(event.target);
  //   if (zoomClicked) {
  //     this.mapZoomLevel = this.mapService.map.getZoom();
  //   }
  // }
  // // getGeoFence() {
  // //   this.common.loading = true;
  // //   let apiUrl = 'geofence/get-plant-geofence-by-processId';
  // //   this.api.get(apiUrl).subscribe(
  // //     (res: any) => {
  // //       this.common.loading = false;
  // //       this.allGeoFence = res.data || [];
  // //       if (this.allGeoFence.length) {
  // //         this.allGeoFence.forEach((element) => {
  // //           element.plantLatLong = this.getSiteGeoFenceLatLong(element);
  // //         });
  // //         setTimeout(() => {
  // //           this.viewGeoFence(this.allGeoFence[0]);
  // //         }, 100);
  // //       } else {
  // //         this.mapService.clearAll();
  // //       }
  // //     },
  // //     (err) => {
  // //       console.error(err);
  // //       this.common.loading = false;
  // //     }
  // //   );
  // // }

  closeModal(){
    this.activeModal.close();
  }

  // viewGeofence(row: any, index: number = -1, event: boolean = false) {
  //   console.log(row);
  //   this.clearMapServices();
  //   let centreAtFlag = false;
  //   if(row.lat && row.long){
  //     centreAtFlag = true;
  //     let latLong = { lat: row.lat, lng: row.long };
  //   this.mapService.centerAt(latLong);
  //   this.mapService.zoomAt(latLong, this.mapZoomLevel);
  //  }
  //   this.mapService.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
  //   if (row.plantLatLong.length > 0) {
  //     if(!centreAtFlag){
  //       let latLong = { lat: row.plantLatLong[0].lat, lng: row.plantLatLong[0].lng };
  //     this.mapService.centerAt(latLong);
  //     this.mapService.zoomAt(latLong, 13);
  //     }
  //     this.mapService.createPlantGeoFence(row.plantLatLong, event);
  //   }
  //   const plantLatLong = row.plantLatLong.find((item) => item.type == 1);
  //   if (plantLatLong && plantLatLong.data.length) {
  //     plantLatLong.data.forEach((element) => {
  //       this.mapService .setBounds(
  //         this.mapService.createLatLng(element.lat, element.lng)
  //       );
  //     });
  //   }
  //   if (plantLatLong && !plantLatLong.data.length && plantLatLong.radius) {
  //     let latLongs = { lat: plantLatLong.lat, lng: plantLatLong.lng };
  //     this.mapService.centerAtRadius(latLongs, plantLatLong.radius);
  //   }

  //   this.circlesLength = this.mapService.circles.length | 0;

  // }


  viewGeoFence(row: any, event = false) {
    let latLong = { lat: row.lat, lng: row.long };
    this.clearMapServices();
    this.mapService.centerAt(latLong);
    this.mapService.zoomAt(latLong, 12);
    this.mapService.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    this.LoadGeoFence(row.plantLatLong, event);
    let plantGeoFence = row.plantLatLong.find((item) => item.type == 1).data;

    plantGeoFence.forEach((element) => {
      this.mapService.setBounds(
        this.mapService.createLatLng(element.lat, element.lng)
      );
    });
  }

  getLatLongFromPolygon(str) {
    let latLong = [];

    if (!str.includes('EMPTY') && !str.includes('OLLECTION EMP')) {
      let latLongString = String(str).split(',');
      latLongString.forEach((item) => {
        let latLongs = item.split(' ').map((item) => Number(item));
        latLong.push({ lat: latLongs[0], lng: latLongs[1] });
      });
    }
    return latLong;
  }

  LoadGeoFence(data, event = false) {
    let latLngsArray = [];
    if (data.length > 0) {
      latLngsArray.push(data);
      this.mapService.createPlantGeoFence(data, event, false);
    }
  }

  ngAfterViewInit() {

    // this.mapService.clearAll();
    this.mapService.mapIntialize('mapp');
    this.mapService.setMapType(0);
    this.viewGeoFence(this.rowData);
  }

  clearMapServices() {
    this.mapService.clearAll();
    this.mapService.resetMarker();
    this.mapService.resetPolygons();
    this.mapService.clearAll();
    this.mapService.resetMarker();
    this.path = [];
  }


}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { LocationService } from './location.service';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(
    public api: ApiService,
    private locationService: LocationService,
    public user: UserService,
    private router: Router
  ) {}

  signOut(): void {
    if(this.user._isStaffLoggined) {
      this.user._token = null;
      this.user._details = null;
      localStorage.clear();
      window.sessionStorage.clear();
      window.localStorage.clear();
      this.router.navigate(['/auth/visitor-login']);
      this.deleteAllCookies();
      this.locationService.stopLocationWatch();
    } else {
      let params = {
        userName: this.user._name,
      };
      this.api.get('user/logout', params).subscribe((res: any) => {
        this.user._token = null;
        this.user._details = null;
        localStorage.clear();
        window.sessionStorage.clear();
        window.localStorage.clear();
        this.router.navigate(['/auth/login']);
        this.deleteAllCookies();
        this.locationService.stopLocationWatch();
      }, (err: any) => {
        this.user._token = null;
        this.user._details = null;
        localStorage.clear();
        window.sessionStorage.clear();
        window.localStorage.clear();
        this.router.navigate(['/auth/login']);
        this.deleteAllCookies();
        this.locationService.stopLocationWatch();

      });
    }

  }



  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken() {
    // return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    // return JSON.parse(sessionStorage.getItem(USER_KEY));
  }
  deleteAllCookies() {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }
}

import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { VerifyVisitorModalComponent } from 'src/app/components/verify-visitor-modal/verify-visitor-modal.component';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { CommonService } from 'src/app/@core/services/common.service';
import {
  Visitor,
  Action,
  ProcessStage,
  TagList,
} from 'src/app/dataType/visitorInterface';
import { ActionInfoModalComponent } from 'src/app/modals/action-info-modal/action-info-modal.component';
import { ActionFormComponent } from 'src/app/modals/action-form/action-form.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { UserTrackingInfoModalComponent } from 'src/app/modals/user-tracking-info-modal/user-tracking-info-modal.component';
import { VisitorAppointmentComponent } from '../visitor-appointment/visitor-appointment.component';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { TripCommentsComponent } from 'src/app/modals/trip-comment-trail/trip-comments/trip-comments.component';
import { complainceAlertLog } from 'src/app/dataType/complianceInterface';
import { AddComplianceModalComponent } from 'src/app/modals/add-compliance-modal/add-compliance-modal.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';

declare var $: any;

@Component({
  selector: 'app-visitor-visits',
  templateUrl: './visitor-visits.component.html',
  styleUrls: ['./visitor-visits.component.scss'],
})
export class VisitorVisitsComponent implements OnInit {
  movableDiv: boolean = true;
  activeIndex: number = -1;
  visitDetails: Visitor[];
  tempList: Visitor[];
  actionList: Action[];
  processStage: ProcessStage;
  visitStageFlow : string;
  content: any;
  userLocationState = {
    canApprove: true,
    canReject: true,
    enableCurrentActions: true,
    canEditProcess: true,
    rfidEnabled: true,
    tagAssigned: true,
  };

  activeSearchBar: boolean = false;

  currentVisitor: Visitor;
  currentAction: Action;
  txnFailRemark: boolean = false;
  commentMessage: string = "";
  outVisitorDetails: Visitor[];
  processId: number;
  processName: string;
  stageId: number;
  tagList: TagList;
  tagId: number;
  tagName: string;
  tagType: string;
  plantId: number;
  userId: number;
  currentInd: number = 0;
  TagValidator: any = null;
  ProcessValidator: any = null;
  complainceAlertLog:complainceAlertLog[]=[];
  currentVisitorCompliance: {
    refType: string,
    processName: string,
    stageName: string,
    stageId: number,
    processId: number,
    vehicleId: number,
    txnId: number,
    entityType: string,
    visitor_status: string,
    name : string
  };
  editable= false;
  isStepperVisible:boolean= false;

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    public table: TableService,
    public userService: UserService,
    private common: CommonService,
    public alertService: AlertService,
    public breadcrumbService: BreadcrumbService,
    private user: UserService
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
  }

  ngOnInit() {
    this.plantId = this.user._loggedInUser.plantId;
    this.userId = this.user._loggedInUser.id;
    this.show();
    this.getProcessStage();

  }

  getProcessStage() {
    this.common.loading = true;
    this.api
      .get('dashboard/get-user-position', { processType: 'Visit' })
      .subscribe(
        (response: any) => {
          if (response.status) {
            this.processStage = response.data[0];
            this.processStage.visitor_tracking_enable=true
          }
          this.common.loading = false;
        },
        (err) => {
          this.common.loading = false;
        }
      );
  }

  show(stageStatus: string = 'IN') {
    try {
      this.api
        .get('visitors/get-approved-visitor-list', { stageStatus })
        .subscribe((response: any) => {
          this.common.loading = false
          if (stageStatus == 'IN') {
            this.actionList = [];
            this.currentVisitorCompliance= null
            this.complainceAlertLog= null;
            this.visitDetails = response.data;
            this.tempList = this.visitDetails;
            this.activeSearchBar = false;
            if (this.currentVisitor != null) {

              this.currentVisitor = this.visitDetails.find((visitor) => {
                return visitor.mapping_id == this.currentVisitor.mapping_id;
              });
              if(this.currentVisitor.visitor_status=='APPROVED')
              {
                this.activeIndex= -1
                this.currentInd=0
              }

              if(this.currentVisitor.visitor_status=='IN')
              {
              this.activeIndex= this.currentVisitor.mapping_id
              this.getDashboardActions(this.currentVisitor, true);
              this.currentVisitorCompliance=
              {refType: 'VISIT',
              processName:  this.currentVisitor.process_name,
              stageName : this.currentVisitor.stage_name,
              stageId: this.currentVisitor.stage_id,
              processId: this.currentVisitor.process_id,
              vehicleId: this.currentVisitor.visitor_id,
              txnId:this.currentVisitor.txn_id,
              entityType: 'Visitor',
              visitor_status: this.currentVisitor.visitor_status,
              name : this.currentVisitor.name
              }
              }


            }
          } else {
            this.movableDiv = false;
            this.activeIndex = -1;
            this.outVisitorDetails = response.data;
          }

        });
    } catch (error) {
      this.alertService.error('Something went wrong');
    }
  }

  openVerifyVisitorModal(visit: Visitor) {
    const activeModal = this.modalService.open(VerifyVisitorModalComponent, {
      size: 'md',
    });
    activeModal.componentInstance.refData = {
      id: visit.mapping_id,
      mobileNumber: visit.mobile_number,
      visitor_id: visit.visitor_id,
      appointmentId: visit.appointment_id,
    };
    activeModal.result.then(async (res) => {
      if (res.response) {
        if(this.processStage.visitor_tracking_enable)
           {
            this.getAllTags();
           }

        this.currentInd = 1;
      }
    });
  }

  onVisitorClick(visit: Visitor) {
    this.actionList = [];
    this.currentInd = 0;
    this.activeIndex = visit.mapping_id;
    this.currentVisitor = null;
    this.currentVisitorCompliance=null
    if (visit.visitor_status == 'BOOKED')
      return this.alertService.error('Appointment Not Approved!');

    this.isStepperVisible = (visit.visitor_status == 'APPROVED');
    this.currentVisitor = visit;

    if (visit.txn_id != null) {
      this.getDashboardActions(visit);
      this.currentVisitorCompliance=
      {refType: 'VISIT',
      processName:  this.currentVisitor.process_name,
      stageName : this.currentVisitor.stage_name,
      stageId: this.currentVisitor.stage_id,
      processId: this.currentVisitor.process_id,
      vehicleId: this.currentVisitor.visitor_id,
      txnId:this.currentVisitor.txn_id,
      entityType: 'Visitor',
      visitor_status: this.currentVisitor.visitor_status,
      name : this.currentVisitor.name
      }
    }



  }

  getDashboardActions(visit: Visitor, autoPass: boolean = false) {
    let params = {
      processId: visit.process_id,
      stageId: visit.stage_id,
      stageActionStatus: 0,
      flowType: visit.flow_type ? visit.flow_type : 1,
      visitorId: visit.visitor_id,
      transactionId: visit.txn_id,
    };


    this.api.get('dashboard/get-dashboard-actions', params).subscribe(
      (res: any) => {
        this.common.loading= false
        if (res.status) {
          if (res.data.length == 0 && autoPass) {
            this.assignNextStage();
          } else {
            this.actionList = res.data;
            this.checkActionState();
          }
        }
      },
      (err: any) => {
        this.common.loading= false
      }
    );
  }

  checkActionState() {
    this.userLocationState.enableCurrentActions = true;
    this.userLocationState.canApprove = true;
    this.userLocationState.canReject = true;

    this.actionList.forEach((action) => {
      const responseStatus = action.response_status.toLowerCase();
      if (responseStatus === 'pass' || responseStatus === 'fail') {
        this.userLocationState.canEditProcess = false;
      }
      if (
        responseStatus != 'pass' &&
        (action.isMandatory ||
          (!action.isMandatory && responseStatus === 'fail'))
      ) {
        this.userLocationState.canApprove = false;
        if (responseStatus.includes('pending')) {
          this.userLocationState.canReject = false;
        }
      }
    });
  }

  async actionData(currentAction: Action) {
    let activeModal = this.modalService.open(ActionInfoModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
    });
    activeModal.componentInstance.refData = {
      actionName: currentAction.action_name,
      actionType: 'pre',
      stageId: this.currentVisitor.stage_id,
      txnId: this.currentVisitor.txn_id,
      actionId: currentAction.action_id,
      action_type: currentAction.action_type,
      txnStageDetailId: currentAction.stageDetailId,
    };
  }

  storeBooleanActionResult(currentAction: Action, res: string) {
    let params = {
      stageActionId: currentAction.action_id,
      tagStageWorkflowDetailId: null,
      stageDetailId: currentAction.stageDetailId,
      status: res,
      transactionId: this.currentVisitor.txn_id,
      actionType: currentAction.audittype,
      stageId: currentAction.actionStageId,
      flowType: this.currentVisitor.flow_type,
      lat: -1,
      lng: -1,
      nextStageId: this.currentVisitor.stage_id,
      vehicleId: this.currentVisitor.visitor_id,
      txnType: 'visit',
    };

    this.api.post('transaction/save-transaction-detail', params).subscribe(
      (result: any) => {
        if (result.message == 'NOT INSIDE GEOFENCE AREA') {
          this.alertService.error(result.message);
        } else {
          this.actionList = this.actionList.map((action) => {
            if (action.action_id == currentAction.action_id) {
              action.response_status = res;
            }
            return action;
          });
        }
        this.getDashboardActions(this.currentVisitor);
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  openAction(action: Action) {
    this.common.params = {
      rowDetail: {
        processId: this.currentVisitor.process_id,
        stageId: action.actionStageId,
        stageName: this.currentVisitor.stage_name,
        actionId: action.action_id,
        transactionDetailId: null,
        transactionStageDetailId: action.stageDetailId,
        actionName: action.action_name,
        stageActionId: action.stage_action_configuration_id,
        transactionId: this.currentVisitor.txn_id,
        actionType: action.audittype,
        flowType: 1,
        transaction_type: 'visit',
        meetWith: null,
        vehicleId: this.currentVisitor.visitor_id,
        nextStageId: this.currentVisitor.stage_id,
        stageType: this.currentVisitor.stage_type_name,
        stageDestinationCode: this.currentVisitor.stage_destination_code_id,
        txnDetailId: action.save
      },
    };
    this.openActionForm();
  }

  openActionForm() {
    const activeModal = this.modalService.open(ActionFormComponent, {
      size: this.common.params.rowDetail == 'Dock' ? 'lg' : 'lg',
    });
    activeModal.result.then((res) => {
      this.getDashboardActions(this.currentVisitor);
    });
  }

  assignNextStage(permitVehicle: number = 1) {
    this.isStepperVisible = false;
    let data = {
      vehicleId: this.currentVisitor.visitor_id,
      permitVehicle: permitVehicle,
      currStageId: this.currentVisitor.stage_id,
      txnType: 'visit',
      isVisit: true,
      nextStageId: this.currentVisitor.next_stage_id,
      //nextStageId
    };

    this.api.post('transaction/assign-next-stage', data).subscribe(
      (res: any) => {
        if (res.data[0].success == false) {
          this.alertService.error(res.data[0].status);
        } else {
          this.show();
          this.alertService.success(res.data[0].status);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  visitTrackingModal(currentVisitor) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      personIcon:true,
      showHeader: false,
      txnId: currentVisitor.txn_id,
      vehicleName: currentVisitor.process_name + '-' + currentVisitor.name,
    };
  }

  overrideAction(action: any) {
    this.common.loading = true;
    let params = {
      transactionDetailId: action.save,
      status: 'PENDING',
      actionId: action.action_id,
      transactionId: this.currentVisitor.txn_id,
    };
    this.api.get('dashboard/update-overriding-status', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success('Overriding Permission Alloted...');
          this.getDashboardActions(this.currentVisitor);
        }
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  visitDetailModal(currentVisitor) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: currentVisitor.txn_id,
      processName: currentVisitor.process_name,
      txnType: 'Visit',
      visitorName: currentVisitor.name,
      meetWith: currentVisitor.user_name,
      purpose: currentVisitor.purpose,
    };
  }

  pathTrackingModal(staffId: number) {
    this.common.params = {
      userId: staffId,
      userType: 'STAFF',
    };
    this.modalService.open(UserTrackingInfoModalComponent, {
      size: 'xl',
    });
  }

  searchVisitor(event: any) {
    if (event == null || event.target.value == '')
      this.tempList = this.visitDetails; // Reseting Vehicle List
    else {
      this.tempList = this.visitDetails.filter((visitor) =>
        visitor.name?.toLowerCase()
          .includes(event.target.value.trim().toLowerCase())
      ); // Filtering vehicle List based on user input
    }
  }

  openAddVisitor() {
    let activeModal = this.modalService.open(VisitorAppointmentComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'l',
    });
    activeModal.componentInstance.toggle = true;

    activeModal.result.then((data: any) => {
      this.show();
    });
  }

  displayImage(url: string) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    this.common.params = {
      url: url,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  // TODO
  selectProcess(event: any, type: string) {
    if (type == 'select') {
      this.processId = event.processId;
      this.processName = event.processName;
      this.stageId = event.stageId;
    } else {
      this.processId = null;
      this.processName = null;
      this.stageId = null;
    }
  }

  getAllTags() {
    this.api.get('vehicle/get-all-tags').subscribe(
      (res: any) => {
        this.tagList = res.data;
      },
      (err: any) => {}
    );
  }

  selectTag(event: any, type: string) {
    if (type == 'select') {
      this.tagId = event.id;
      this.tagName = event.tag_id;
      this.tagType = event.tag_type;
    } else {
      this.tagId = null;
      this.tagName = null;
      this.tagType = null;
    }
  }

  saveTagMapping(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        entityId: this.currentVisitor.visitor_id,
        tagId: this.tagId,
        entityType: 'STAFF',
        tagType: this.tagType,
        userId: 1,
        plantId: null,
      };

      this.api.post('tag-mapping/save-tag-mapping', params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
          } else {
            this.common.loading = false;
          }
          resolve(true);
        },
        (err: any) => {
          this.common.loading = false;
          reject(err);
        }
      );
    });
  }

  createNewTransaction() {
    let params = {
      processId: this.processId,
      plantId: this.plantId,
      userId: this.userId,
      stageId: this.stageId,
      txnType: 'visit',
      vechileId: this.currentVisitor.visitor_id,
      appointmentId: this.currentVisitor.appointment_id,
    };

    this.api
      .post('transaction/create-new-transaction', params)
      .subscribe((res: any) => {
        if (res.status) {
          if (res.data[0].exist == 1) {
            this.alertService.error('Transaction already exists!');
          } else {
            this.alertService.success(res.messsage);
            this.currentInd = 2;
            this.saveTagMapping()
            this.show();
          }
        }
      });
  }

  formValidator() {
    this.ProcessValidator = this.common.nullValidator(
      this.processId,
      'Process Mandatory!'
    );
    this.TagValidator = this.common.nullValidator(this.tagId, 'Tag Mandatory!');
    if (this.ProcessValidator)
      if(this.processStage.visitor_tracking_enable)
     { if (!this.TagValidator.error && !this.ProcessValidator.error) {
        this.createNewTransaction();
      }}
      else
     { if (!this.ProcessValidator.error)
         this.createNewTransaction();}
  }

  tripCommentTrail(txnId: number) {
    let activeModal = this.modalService.open(TripCommentsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
    });
    activeModal.componentInstance.tripId = txnId;
    activeModal.componentInstance.entityType = 'VISIT';
    activeModal.componentInstance.complainceData={
      refType: 'VISIT',
      processName : this.currentVisitor.process_name,
      stageName : this.currentVisitor.stage_name,
      stageId: this.currentVisitor.stage_id,
      processId: this.currentVisitor.process_id,
      vehicleId: this.currentVisitor.visitor_id,
      txnId:this.currentVisitor.txn_id,
      entityType: 'VISIT'
    }

    activeModal.result.then((res) => {
      if (res.response) {
        this.alertService.success(res.response);
      }
    });
  }

  addCommentForVisit(
    curr: any,
    action: boolean = false,
    txnFailRemark: Boolean = false,
    content: any = false
  ) {
    this.commentMessage = this.commentMessage.trim();
    if (action && !txnFailRemark) this.storeBooleanActionResult(curr, 'FAIL');
    let params = {
      entityId: this.currentVisitor.txn_id,
      entityType: 'VISIT',
      commentType:
        action && !txnFailRemark
          ? 'ACTION FAIL'
          : !action && txnFailRemark
          ? 'TRANSACTION REJECTED'
          : 'ACTION OVERRIDED',
      comment:
        action || txnFailRemark
          ? this.commentMessage == ''
            ? 'N/A'
            : this.commentMessage
          : this.userService._name +
            ' requested Overriding for ' +
            curr.action_name +
            ' !!',
      refData:
        action && !txnFailRemark
          ? JSON.stringify({
              stageName: this.currentVisitor.stage_name,
              stageId: this.currentVisitor.stage_id,
              title: '"' + curr.action_name + '" Failed !!',
              actionId: curr.action_id,
              actionName: curr.action_name,
            })
          : !action && txnFailRemark
          ? JSON.stringify({
              stageName: this.currentVisitor.stage_name,
              stageId: this.currentVisitor.stage_id,
              title: 'Transaction is Cancelled',
            })
          : JSON.stringify({
              stageName: this.currentVisitor.stage_name,
              stageId: this.currentVisitor.stage_id,
            }),
    };
    this.api.post('comment/add-comment', params).subscribe(
      (res: any) => {
        this.commentMessage = '';
        if (txnFailRemark) {
          this.checkNextStages('reject_next_stages', content);
          this.txnFailRemark= false
        }
      },
      (err: any) => {
        this.common.loading = false;
      }
    )
  }

  openRemarkModal() {
    $('#exampleModal').modal('show');
  }

  checkNextStages(flow: string, content) {
    this.visitStageFlow = flow;
    if (this.currentVisitor[flow]?.length > 1 && this.currentVisitor[flow][0]?.next_stage_id) {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
      return;
    }

  else{
  this.visitStageFlow=='reject_next_stages'?this.assignNextStage(0): this.assignNextStage();
  }
}}

<div class="card">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{"maintenance" | translate}}
      </h3>
    </div>
    <button type="button" class="btn btn-success" data-toggle="modal" data-target="#exampleModal" (click)="this.reset()">+ {{"add_new"| translate}}</button>
  </div>

  <div class="card-body">
    <div class="tab_section">
      <div class="tab" *ngFor="let tabitem of tabData" [class.active]="statusType == tabitem.value"
        (click)="statusType = tabitem.value">
        <p>{{tabitem.tabname|translate}}</p>
      </div>
    </div>

    <div class="tab_content">
      <table class="custom-table">
        <thead class="thead-dark">
          <tr>
            <th>{{ "title" | translate }}</th>
            <th>{{ "due_date" | translate }}</th>
            <th>{{ "duration_type" | translate }}</th>
            <th>{{ "maintained_by" | translate }}</th>
            <th *ngIf="statusType!=='SCHEDULED'">{{ "status" | translate }}</th>
            <th>{{ "completion_date" | translate}}</th>
            <th *ngIf="statusType!=='SCHEDULED'">{{ "maintenance_cost" | translate }}</th>
            <th>{{ "action" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let row of assetMaintenanceList">
            <tr>
              <td>{{ row.title }}</td>
              <td>{{ row.due_date | date:'dd-MM-yyyy' }}</td>
              <td>
                <span class="pill" [ngClass]="{'bg-green-200 text-green-800':row.duration_type===1,'bg-yellow-200 text-yellow-800':row.duration_type===0}">
                  {{ row.duration_type === 0 ? 'FIXED' : 'SCHEDULAR' }}
                </span>
              </td>
              <td *ngIf="statusType!=='SCHEDULED'">
                <span class="pill" [ngClass]="{'bg-blue-200 text-blue-800':row.status_name1=='COMPLETED','bg-yellow-200 text-yellow-800':row.status_name1=='SCHEDULED'}">
                  {{ row.status_name1 }}
                </span>
              </td>
              <td>{{ row.entity_type }} - {{ row.entity_name }}</td>
              <td>{{ row.complete_at | date:'dd-MM-yyyy' }}</td>
              <td *ngIf="statusType!=='SCHEDULED'">0</td>
              <td class="action">
                <div class="btn-block">
                  <button class="btn btn-primary" (click)="viewDetails(row)">{{"view" | translate}}</button>
                  <button class="btn btn-success" data-toggle="modal" data-target="#exampleModal" (click)="editLog(row)" *ngIf="statusType=='SCHEDULED'">{{"edit" | translate}}</button>
                  <button class="btn btn-danger" (click)="confirmAlert(row)">{{"delete" | translate}}</button>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalabel">{{"add_maintenance"  | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="form-group col-12 mb-0">
            <label for="my-input" class="required">{{'title'|translate}}</label>
            <input class="form-control" type="text" name="MaintenancePlan" [(ngModel)]="assetMaintenance.title">
            <div class="error" *ngIf="titleValidator && titleValidator.error">
              <span>{{titleValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-12 mb-0">
            <label for="my-input" >{{ "description" | translate }}</label>
            <textarea rows="2" cols="30"  class="form-control" type="text" name="notes" id="notes"
            [(ngModel)]="assetMaintenance.description" #name="ngModel" placeholder="Enter Notes">
            </textarea>
          </div>

          <div class="form-group col-12 mb-0">
            <label  for="my-input" class="required">{{"due_date" | translate}}</label>
            <input type="date" [min]="" class="form-control" name="dueDate" [(ngModel)]="assetMaintenance.dueDate">
            <div class="error" *ngIf="dueDateValidator && dueDateValidator.error">
              <span>{{dueDateValidator.msg}} </span>
            </div>
          </div>

          <div  class="form-group col-12 mb-0">
              <label for="checklistType" class="required">{{"maintained_by" | translate}}</label>
              <select id="checklistType" (change)="handleEntityTypeChange(assetMaintenance.entityType)" class="form-control"
                name="is_scheduled" [(ngModel)]="assetMaintenance.entityType">
                <option value="USER">{{"User" | translate}}</option>
                <option value="STAFF">{{"Staff" | translate}}</option>
          </select>
          </div>


          <div class="form-group col-12 mb-0" *ngIf="assetMaintenance.entityType == 'USER'">
            <label for="my-input" class="required"> {{"user" | translate}} </label>
            <ps-auto-suggestion (onSelected)="selectedUsers($event)"
              [data]="userList" display="name" placeholder="Choose User"
              [preSelected]="{name:userName}">
            </ps-auto-suggestion>
            <div class="error" *ngIf="entityIdValidator && entityIdValidator.error">
              <span>{{entityIdValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-12 mb-0" *ngIf="assetMaintenance.entityType == 'STAFF'" [@fadeInOut]>
            <label for="my-input" class="required"> {{"staff_type"| translate}} </label>
            <ps-auto-suggestion  (onSelected)="selectedStaffType($event)" [data]="staffTypeList"
              display="type_name" placeholder="Choose Staff" [preSelected]="{type_name:staffTypeName}">
            </ps-auto-suggestion>
            <div class="error" *ngIf="staffTypeIdValidator && staffTypeIdValidator.error">
              <span>{{staffTypeIdValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-12 mb-0" *ngIf="assetMaintenance.entityType == 'STAFF'" [@fadeInOut]>
            <label for="my-input" class="required"> {{"staff" | translate}} </label>
            <ps-auto-suggestion  (onSelected)="selectedStaff($event)" [data]="staffList"
              display="staff_name" placeholder="Choose Staff" [preSelected]="{staff_name:staffName}">
            </ps-auto-suggestion>
            <div class="error" *ngIf="entityIdValidator && entityIdValidator.error">
              <span>{{entityIdValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-12 mb-0" >
            <label for="my-input">{{"tolerance" | translate}}</label>
            <input type="number" name="tolerance" min=0 class="form-control"
                 placeholder="Enter Tolerance (in days)" [(ngModel)]="assetMaintenance.tolerance">
          </div>

          <div  class="form-group col-12 mb-0">
              <label for="checklistType" class="required">{{"duration_type" | translate}}</label>
              <select id="checklistType" (change)="handleTypeChange(assetMaintenance.durationType)" class="form-control"
                name="is_scheduled" [(ngModel)]="assetMaintenance.durationType">
                <option value="0">{{"FIXED" | translate}}</option>
                <option value="1">{{"SCHEDULAR" | translate}}</option>
              </select>
          </div>

          <div class="form-group col-12 mb-0" *ngIf="assetMaintenance.durationType == 1" [@fadeInOut]>
            <label style="width: 250px;" class="required">{{"interval_type" | translate}}</label>
                      <select class="form-control" [(ngModel)]="assetMaintenance.intervalType" name="intervalType" (change)="iterationChange()">
                        <option *ngFor="let item of iterationType" [value]="item.value">{{item.type}}</option>
            </select>
          </div>

          <div class="form-group col-12 mb-0" *ngIf="assetMaintenance.durationType == 1" [@fadeInOut]>
            <label style="width: 250px;" class="required">{{"interval_count" | translate}}</label>
            <input type="number" name="intervalCount" min=0
              [max]="assetMaintenance.intervalType=='Yearly'?12:(assetMaintenance.intervalType == 'Weekly' ? 7 :assetMaintenance.intervalType == 'Monthly' ? 31 : 100) "
              class="form-control" [(ngModel)]="assetMaintenance.intervalCount" (change)="createSchedularData()">
              <div class="error" *ngIf="intervalCountValidator && intervalCountValidator.error">
                <span>{{intervalCountValidator.msg}} </span>
              </div>
          </div>

          <ng-container *ngIf="assetMaintenance.intervalType != 'Daily'">
          <div class="form-group col-12 mb-0 " *ngFor="let data of assetMaintenance.intervalData; let i=index">

                  <!--for weekly-->
                  <ng-container>
                    <div class="form-group col-12 mb-0" *ngIf="assetMaintenance.intervalType=='Weekly'">
                      <form class="d-flex">
                        <label style="width: 250px;" class="required">{{"day_of_week" | translate}}</label>
                        <select name="dayOfWeek" [(ngModel)]="assetMaintenance.intervalData[i].dayOfWeek"
                          style="width: -webkit-fill-available;" (change)="checkIntervalCheck($event,i)">
                          <option disabled [value]="''">Select Day</option>
                          <option *ngFor="let day of daysOfWeek" [value]="day">{{ day }}</option>
                        </select>
                      </form>
                      <div class="error" *ngIf="dayOfWeekValidator[i] && dayOfWeekValidator[i].error">
                        <span>{{dayOfWeekValidator[i].msg}} </span>
                      </div>
                    </div>

                    <!--for monthly-->
                    <div class="form-group col-12 mb-0" *ngIf="assetMaintenance.intervalType=='Monthly'">
                      <form class="d-flex">
                        <label style="width: 250px;" class="required">{{"day_of_month" | translate}}</label>
                        <input type="number" max=31 min=0 class="form-control" name="dayOfMonth"
                          [(ngModel)]="assetMaintenance.intervalData[i].dayOfMonth" (change)="checkIntervalCheck($event,i)">
                      </form>
                      <div class="error" *ngIf="dayOfMonthValidator[i] && dayOfMonthValidator[i].error">
                        <span>{{dayOfMonthValidator[i].msg}} </span>
                      </div>
                    </div>

                    <!-- for-yearly -->
                    <div class="form-group col-12 mb-0" *ngIf="assetMaintenance.intervalType=='Yearly'">
                      <form class="d-flex">
                        <label style="width: 250px;" class="required">{{"month_of_year" | translate}}</label>
                        <select name="dayOfWeek" [(ngModel)]="assetMaintenance.intervalData[i].monthOfYear"
                          style="width: -webkit-fill-available;" (change)="checkIntervalCheck($event,i)">
                          <option disabled [value]="''">Select Month</option>
                          <option *ngFor="let month of monthOfYear" [value]="month">{{ month }}</option>
                        </select>
                      </form>
                      <div class="error" *ngIf="monthOfYearValidator[i] && monthOfYearValidator[i].error">
                        <span>{{monthOfYearValidator[i].msg}} </span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="reset()">{{"close" | translate}}</button>
        <button type="button" class="btn btn-warning" style="background-color: #337ab7;" (click)="reset()">{{"reset" | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="formValidator()">{{"save_maintenance" | translate}}</button>
      </div>
    </div>
  </div>
</div>

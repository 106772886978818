import { DashboardService } from '../dashboard/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-vendor-table',
  templateUrl: './stagetat-table.component.html',
  styleUrls: [
    '../dashboard/dashboard.component.scss',
    '../stage-tat/stagetat-table.component.scss',
  ],
})
export class stagetatTableComponent implements OnInit {
  processIndex: number = 0;
  filters: any[] = [{'filter':'today'}, {'filter':'last 7 days'}, {'filter':'last 30 days'}, {'filter':'last 365 days'}];
  defaultFilter: string = 'last 7 days';
  defaultprocess: string = 'Loading Process';
  dayFilter: string = 'last 7 days';
  stagetat: any[] = [];
  stagetatresponse: any[] = [];
  allProcess: any = [];
  selectedProcess:any = '';
  constructor(
    public workflow: DashboardService,
    public userService: UserService
  ) {
    this.getAllProcess();
  }
  ngOnDestroy(): void {}

  getfilter(index: any) {
    this.dayFilter = index.filter;
    this.getstagetat(this.processIndex);
  }

  getstagetat(processId) {
    this.defaultFilter = this.filters[1].filter;
    this.workflow
      .getstagetat(this.dayFilter, processId)
      .subscribe((item: any) => {
        if (item.status) {
          this.stagetat = item.data ?? [];
        }
      });
  }

  getAllProcess() {
    this.workflow.getAllProcess().subscribe((item: any) => {
      if (item.status) {
        this.allProcess = item.data;
        this.defaultprocess = this.allProcess[0].processName;
        this.processIndex = this.allProcess[0].processId;
        this.selectedProcess = this.allProcess[0].processName;
        this.getstagetat(this.allProcess[0].processId);
      }
    });
  }

  getprocessindex(event: any) {
    this.processIndex = event.processId;
    this.getstagetat(this.processIndex);
  }

  ngOnInit(): void {
  }

}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header" *ngIf="!toggle">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Visitor Appointment"}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div [ngClass]="'col-md-9'" *ngIf="!toggle">
                  <div class="tab_table_sec">
                    <div class="tab_card_sec">
                      <div class="tab_card" *ngFor="let tab of actionLabels;index as i"
                        (click)="showVisits(tab.tabName); activeIndex = i" [class.active]="activeIndex == i">
                        <div class="left_content">
                          <div class="tab_name">{{tab.tabName | translate}}</div>
                          <div class="tab_number">{{data[tab.tabName]}}</div>
                        </div>

                        <div class="right_icon">
                          <img [src]="tab.tab_icon">
                        </div>

                      </div>
                    </div>


                    <div class="row filter_row"
                      *ngIf="activeTab=='completed' || activeTab=='rejected' || activeTab=='total'">
                      <div class="col-md-4 date pl-0">
                        <label>{{'start_date:'|translate}}</label>
                        <input type="date" class="form-control" [(ngModel)]="startDate"
                          (change)="showVisits(activeTab)">
                      </div>
                      <div class="col-md-4 date">
                        <label>{{'end_date:'|translate}}</label>
                        <input type="date" class="form-control" [min]="startDate" [(ngModel)]="endDate"
                          (change)="showVisits(activeTab)">
                      </div>
                    </div>

                    <div class="table_sec">
                      <!-- <div class="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th>{{'serial_no'|translate}}</th>
                              <th>{{'Visitor Name'|translate}}</th>
                              <th>{{'Visitor mobile no.' | translate}}</th>
                              <th>{{'No of Visitors'|translate}}</th>
                              <th>{{'Visit Start'|translate}}</th>
                              <th>{{'Visit End'|translate}}</th>
                              <th>{{'No. of days' | translate}}</th>
                              <th *ngIf="isVisitor" >{{ 'Meet With'|translate}}</th>
                              <th>{{'Purpose'|translate}}</th>
                              <th>{{'Status'|translate}}</th>
                              <th style="text-align:center;" *ngIf="activeTab=='booked'">{{'Actions'|translate}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of appointments;index as i">
                              <td>{{i+1}}</td>
                              <td>{{data.name}}</td>
                              <td>{{data.mobileNumber}}</td>
                              <td>{{data.visit_users.length}}</td>
                              <td>{{data.visit_start_time}}</td>
                              <td>{{data.visit_end_time}}</td>
                              <td>{{data.visit_days == 0 ? '01' : data.visit_days}}</td>
                              <td *ngIf="isVisitor">{{data.host_name}}</td>
                              <td>{{data.purpose}}</td>
                              <td>
                              </td><span class="pill"
                                  [ngClass]="{'bg-green-200 text-green-800':data.status=='APPROVED','bg-blue-200 text-blue-800':data.status=='BOOKED','bg-pink-200 text-pink-800':(data.status=='REJECTED'),'bg-yellow-200 text-yellow-800':(data.status=='INPROGRESS')}">{{data.status}}</span>
                              <td class="action"
                                *ngIf="(data.status == 'BOOKED' || data.status == 'APPROVED') && activeTab=='booked'">
                                <div class="btn-block">
                                  <div class="right_content" style="display: flex; flex-direction: row;">
                                    <i *ngIf=" data.status == 'BOOKED' && !isVisitor" class="fa fa-check"
                                      style="color: green; border-radius: 50%;" (click)="updateVisitStatusByUser(data,approve)"></i>
                                    <i *ngIf="!isVisitor" class="fa fa-times " style="color: red; border-radius: 50%;"
                                      (click)="updateVisitStatusByUser(data,reject)"> </i>
                                    <i class="fa fa-info-circle icon_info" data-toggle="modal"
                                      data-target="#exampleModal" (click)="modalData = data.visit_users"
                                      style="color:#013e90;"></i>
                                      <i *ngIf="isVisitor || data.isPrimary" class="fas fa-trash"
                                      (click)="confirmDeleteAppointment(data.created_by,data.visit_users, data.id,data.isPrimary)"
                                      style="color: red;"></i>
                                    <i *ngIf="data.isPrimary" class="fa fa-edit" (click)="openAddVisitor(data)"
                                      style="color: green;"></i>
                                  </div>
                                  <div>

                                  </div>

                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div> -->
                      <app-custom-table [data]="appointments" [columns]="tableColumns" [loading]="loading" [tableType]="TABLE_TYPE"></app-custom-table>
                    </div>
                  </div>
                </div>
                <ng-container *ngTemplateOutlet="addAppointment"></ng-container>

                <div class="modal fade" #detail id="exampleModal" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: 100%">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Visitor Info</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">

                        <ng-container *ngFor="let visit of modalData; let i = index">

                          <div class="single_card">
                            <div class="show_data">
                              <div class="start">
                                <div class="image1 icon">
                                  <img
                                    [src]="visit.visitor_image?visit.visitor_image:'assets/images/user_whatsapp.jpg'" (click)="openVisitorImage(visit.visitor_image)" style="aspect-ratio: 1 ;">

                                </div>
                              </div>

                              <div class="mid">
                                <div class="name"><strong>Name : </strong>{{visit.staff_name}}</div>
                                <div class="name"><strong>In : </strong>{{visit.in_time|date:'dd-MM-yyyy hh:mm'}}</div>
                                <div><strong>Phone : </strong>{{visit.mobile_number}}</div>
                              </div>

                              <div class="end">
                                <div class="name"><strong>Status : </strong> <span class="pill"
                                    [ngClass]="{'bg-green-200 text-green-800':visit.visitor_status=='APPROVED','bg-yellow-200 text-yellow-800':visit.visitor_status=='IN','bg-indigo-100 text-indigo-800':(visit.visitor_status=='OUT'),'bg-red-200 text-red-800':(visit.visitor_status=='REJECTED'),'bg-blue-200 text-blue-800':(visit.visitor_status=='BOOKED')}">{{visit.visitor_status}}</span>
                                </div>
                                <div class="name" style="font-size: 14px; font-weight: 600;"><strong>Out:
                                  </strong>{{visit.out_time|date:'dd-MM-yyyy hh:mm'}}</div>
                                <div class="last_bottom">

                                </div>
                              </div>

                            </div>
                          </div>
                        </ng-container>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-alert-message></app-alert-message>
<ng-template #addAppointment>
  <div [ngClass]="toggle ? 'col-md-12' :'col-md-3 pl-0'">
    <!-- <div class="visitModal">
      <label style="    font-size: 16px;
            font-weight: 700;">{{ title | translate}}
      </label>
      <i class="fa fa-times" *ngIf = "toggle" (click)="confirmCloseModalInWalkinMode()">
      </i>
          </div> -->


          <div class="form_sec">
             <div class="row form_sec_row">

              <div class="col-md-12" *ngIf="isVisitor && refData == null">
                <div class="field camera_option">
                  <label>Capture Image</label>
                  <div class="upload_img" *ngIf="!showCamera">
                    <img src="assets/images/camera_plus.png" (click)="showCamera=true">
                  </div>

                  <div *ngIf="showCamera==true">
                    <app-camera-component [retake]="retakeImage" [id]="'field'"
                      (getPicture)="handleImage($event,'driver_image')" *ngIf="newAppointment.visitorImage==null">
                    </app-camera-component>
                    <div style="display: flex; flex-direction: column; align-items:center;"
                      *ngIf="newAppointment.visitorImage!=null">
                      <img class="circle-frame" style="height: 200px; margin-bottom: 5px;"
                        [src]="newAppointment.visitorImage" />
                      <p>Upload Done! <button class="btn btn-warning"
                          (click)="retakeImage = !retakeImage;newAppointment.visitorImage=null">Retake</button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12" *ngIf="isVisitor && refData == null">
                <div class="field">
                  <label class="required">Name</label>
                  <input type="text" [(ngModel)]="newAppointment.name" class="form-control">
                  <div class="error" *ngIf="nameValidator && nameValidator.error">
                    <span>{{nameValidator.msg}} </span>
                  </div>
                </div>

              </div>

              <div class="col-md-12">
                <div class="field">
                  <label class="required">Start Time</label>
                  <input type="datetime-local" [value]="newAppointment.startTime" [(ngModel)]="newAppointment.startTime"
                    [min]="minStartDate" class="form-control" (change)="validateStartTime()">
                  <div class="error" *ngIf="startTimeValidator && startTimeValidator.error">
                    <span>{{startTimeValidator.msg}} </span>
                  </div>
                </div>

              </div>

              <div class="col-md-12">
                <div class="field">
                  <label class="required">End Time</label>
                  <input type="datetime-local" [(ngModel)]="newAppointment.endTime" [min]="newAppointment.startTime"
                    class="form-control" (change)="validateDateTimeRange(newAppointment.startTime,newAppointment.endTime)">
                  <div class="error" *ngIf="endTimeValidator && endTimeValidator.error">
                    <span>{{endTimeValidator.msg}} </span>
                  </div>
                </div>

              </div>

              <div class="col-md-12">
                <div class="field">
                  <label class="required">Purpose</label>
                  <input type="text" [(ngModel)]="newAppointment.purpose" class="form-control">
                  <div class="error" *ngIf="purposeValidator && purposeValidator.error">
                    <span>{{purposeValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="field">
                  <label class="required">From</label>
                  <input type="text" [(ngModel)]="newAppointment.from" class="form-control">
                  <div class="error" *ngIf="fromValidator && fromValidator.error">
                    <span>{{fromValidator.msg}} </span>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="field">
                  <label class="required">Meet With</label>
                  <ps-auto-suggestion [data]="allUsers" (onSelected)="selectedUser($event)" display="name"
                    [preSelected]="{name:newAppointment.userName}" placeholder="Choose User" [readonly]="this.refData!=null">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="toValidator && toValidator.error">
                    <span>{{toValidator.msg}} </span>
                  </div>
                </div>

              </div>

              <div class="col-md-12">
                <div class="field">
                  <label>Visitor Details</label>
                  <div class="other_visitor">
                    <div class="loop_row" *ngFor="let visitor of newAppointment.visitors; let i = index">
                      <div class="number_field">
                        <input type="text" class="form-control" placeholder="Number" [(ngModel)]="visitor.mobile"
                          maxlength="10" (input)="handleMobileNumber(visitor,visitor.mobile)">
                      </div>
                      <div class="name_field">
                        <input type="text" class="form-control" placeholder="Name" [(ngModel)]="visitor.name"
                          [disabled]="visitor.isNameDisabled">
                      </div>
                      <div class="minus_btn">
                        <button class="btn" (click)="removeRow(i)" *ngIf="newAppointment.visitors.length > 1"><i
                            class="fa fa-minus"></i></button>
                      </div>
                    </div>
                    <div class="plus_btn">
                      <button class="btn" (click)="addValues()"><i class="fa fa-plus"></i></button>
                    </div>
                  </div>
                </div>
              </div>
    <div class="right_form_title">
      <label>{{ title | translate}}</label>
      <i class="fa fa-times closeModal" *ngIf = "toggle" (click)="confirmCloseModalInWalkinMode()">
      </i>
    </div>

    <div class="appointment_form">
      <app-form [formType]='"Visitor Form"'></app-form>
    </div>
  </div>
  </div>
  </div>

</ng-template>

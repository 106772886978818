<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-2">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Productivity Report'|translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="page_design">
                <div class="row tab_sec m-0">
                  <div class="col-md-10 col-12 date_div p-0">
                    <div class="row">
                      <div class="col-md-5 date">
                        <label>{{'start_date:'|translate}}</label>
                        <input class="form-control" type="date" name="startDate" [(ngModel)]="fromDate" [max]="toDate">
                      </div>
                      <div class="col-md-5 date">
                        <label>{{'end_date:'|translate}}</label>
                        <input class="form-control" type="date" name="endDate" [(ngModel)]="toDate" [min]="fromDate">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1 btn-block text-right">
                    <button class="btn btn-primary" (click)="getEmpProductivity(0)">{{"search" | translate}}</button>
                  </div>
                </div>

                <div class="view_table" *ngIf="tabvalue == 'table'">
                  <div class="filter_sec">
                    <div class="row">
                      <div class="col-md-3 coll" *ngIf="role.user_role=='COMPANYADMIN'">
                        <label>{{'plant:'|translate}}</label>
                        <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                          placeholder="Choose Plant">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'select_user:'|translate}}</label>
                        <ps-auto-suggestion [data]="userList" display="name" seperator="-" placeholder="Select User"
                          (onSelected)="selectedUser($event)" inputId="stageName" name="stageName" [preSelected]="null"
                          isMultiSelect="true"></ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'select_process:'|translate}}</label>
                        <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedProcess($event)"
                          [data]="listOfProcess" display="processName" placeholder="Select Process"
                          name="selectedActionData" inputId="selectedActionData">
                        </ps-auto-suggestion>
                      </div>
                      <div class="col-md-3 coll">
                        <label>{{'select_stage:'|translate}}</label>
                        <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedStage($event)" [data]="stageList"
                          display="stageName" placeholder="Choose Stage">
                        </ps-auto-suggestion>
                      </div>
                    </div>
                  </div>

                  <div class="table_sec">
                    <table class="table table-bordered table-striped" datatable [dtOptions]="dtOptions"
                      [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{'serial_no'|translate}}</th>
                          <th>{{'date'|translate}}</th>
                          <th>{{'user_name'|translate}}</th>
                          <th>{{'shift_name'|translate}}</th>
                          <th>{{'Process'|translate}}</th>
                          <th>{{'Stage'|translate}}</th>
                          <th>{{'total_actual_tat'|translate}}</th>
                          <th>{{'total_expected_tat'|translate}}</th>
                          <th>{{('tat'|translate)+' '+('status'|translate)}}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let row of responseData  let i = index">
                          <td>{{ i+1 }}</td>
                          <td>{{row.date | date:'dd-MM-yyyy'}}</td>
                          <td>{{row.user_name}}</td>
                          <td>{{row.shift_name}}</td>
                          <td>{{row.process_name}}</td>
                          <td>{{row.stage_name}}</td>
                          <td>{{row.total_actual_tat==null ? '00:00:00' :row.total_actual_tat}}</td>
                          <td>{{row.total_exp_tat}}</td>
                          <td><span class="badge status-badge"
                              [ngClass]="{'badge-success':row.is_over_tat == false,'badge-danger':row.is_over_tat == true}">{{row.is_over_tat==true
                              ? 'OVER TAT' : 'UNDER TAT'}}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="view_graph" *ngIf="tabvalue == 'graph'">
                  <p class="text-center">Graph will be shown here.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { CompanyService } from '../companies/companies.service';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';

@Component({
  selector: 'app-zone-management',
  templateUrl: './zone-management.component.html',
  styleUrls: ['./zone-management.component.scss'],
})
export class ZoneManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'zone List');
  zones: any[] = [];
  btn = 'save';
  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public alertService: AlertService,
    public api: ApiService,
    private companyService: CompanyService,
    private plantService: PlantService,
    public role: RoleService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Zone', url: null },
      { name: 'Zone Management', link: '/user/zone-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }
  zoneVehicleLimitValidator: any = null;
  zonePeopleLimitValidator: any = null;
  zoneTypeIdValidator: any = null;
  zoneNameValidator: any = null;

  zoneTypeName: string = null;
  zoneTypeList:any[] = [];

  companyList: any[] = [];
  companyId: any = null;
  plantList: any[] = [];
  plantId: any = null;
  plantName: any = null;

  zoneStage = {
    zoneId: null,
    zoneName: null,
    zoneTypeId: null,
    zoneVehicleLimit: null,
    zonePeopleLimit: null,
    status: 'ACTIVE',
    plantId: null,
    isRestrictedZone: false,
  };

  ngOnInit(): void {
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }else{
      this.getAllZones();
    }
    this.getZoneTypes();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  reset(all:boolean=false) {
    this.btn = 'save';
    this.zoneStage = {
      zoneId: null,
      zoneName: null,
      zoneTypeId: null,
      zoneVehicleLimit: null,
      zonePeopleLimit: null,
      status: 'ACTIVE',
      plantId: null,
      isRestrictedZone: false,
    };
    this.zoneTypeName = null;
    console.log(this.zoneStage.zoneTypeId, "fghjk");
    this.zoneNameValidator = null;
    this.zonePeopleLimitValidator = null;
    this.zoneVehicleLimitValidator = null;
    this.zoneTypeIdValidator = null;
    if(all){
      this.plantId = null;
      this.plantName = null;
    }
  }

  save() {
    this.zoneStage.plantId = this.plantId;
    let params = this.zoneStage;
    this.api.post('zone/save-zone-stage', params).subscribe(
      (res: any) => {
        if (res.status) {
          if (res.type == 'INFO') {
            this.alertService.error(res.message);
          } else {
            this.alertService.success(res.message);
            this.reset();
          }
          this.getAllZones();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getZoneTypes(){
    this.api.get('zone/get-all-zone-type').subscribe(
      (res: any) => {
        if (res.status) {
          this.zoneTypeList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  edit(row: any) {
    this.zoneStage = this.setZoneRow(row);
    this.zoneTypeName = row.type_name;
    this.btn = 'update';
  }

  deleteZone(row: any) {
    row.status = 'DELETED';
    this.api.post('zone/save-zone-stage',this.setZoneRow(row)).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertService.info(res.message);
          this.getAllZones();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Zone ',
      description:
        `<b>&nbsp;` + 'Are you sure you want to delete zone?' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteZone(row);
      }
    });
  }

  setZoneRow(row:any){
    let zoneStage:any = {};
    zoneStage.zoneId = row.id;
    zoneStage.zoneName = row.zone_name;
    zoneStage.zoneTypeId = row.zone_type_id;
    zoneStage.zoneVehicleLimit = row.zone_vehicle_limit;
    zoneStage.zonePeopleLimit = row.zone_people_limit;
    zoneStage.zonePlantId = row.plant_id;
    zoneStage.isRestrictedZone = row.is_restricted_zone;
    zoneStage.status = row.status;
    return zoneStage;
  }

  view(row?: any) {
    let newObj = {
      zoneName: row.zoneName,
      slotLimit: row.slotLimit,
      zoneStatus: row.zoneStatus,
    };
    this.common.params = { details: [newObj], title: 'Zone Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  getAllZones() {
    this.api.get('zone/get-all-zone-stage',{plantId:this.plantId,allZones:true}).subscribe(
      (res: any) => {
        if (res.status) {
          this.zones = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedZoneType(event: any) {
    this.zoneStage.zoneTypeId = event.id;
    this.zoneTypeName = event.typeName;
  }

  selectCompany(event: any) {
    this.reset();
    this.companyId = event.companyId;
    this.getPlantById(this.companyId);
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getAllZones();
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantList = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        this.common.loading = false;
        res.data.forEach((element) => {
          if (element.companyStatus == 'ACTIVE') this.companyList.push(element);
        });
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  formValidator() {
    this.zoneStage.zoneName = this.zoneStage.zoneName!=null ? this.zoneStage.zoneName.trim() : this.zoneStage.zoneName;
    this.zoneStage.zonePeopleLimit = this.zoneStage.zonePeopleLimit < 0 ? null : this.zoneStage.zonePeopleLimit;
    this.zoneStage.zoneVehicleLimit = this.zoneStage.zoneVehicleLimit < 0 ? null : this.zoneStage.zoneVehicleLimit;

    this.zoneNameValidator = this.common.nullValidator(
      this.zoneStage.zoneName,
      'Zone Name Mandatory'
    );
    this.zonePeopleLimitValidator = this.common.nullValidator(
      this.zoneStage.zonePeopleLimit,
      'Zone People Limit Mandatory'
    );
    this.zoneVehicleLimitValidator = this.common.nullValidator(
      this.zoneStage.zoneVehicleLimit,
      'Zone Vehicle Limit Mandatory'
    );
    this.zoneTypeIdValidator = this.common.nullValidator(
      this.zoneStage.zoneTypeId,
      'Zone Type Mandatory'
    );
    if (
      !this.zoneNameValidator.error &&
      !this.zonePeopleLimitValidator.error &&
      !this.zoneVehicleLimitValidator.error &&
      !this.zoneTypeIdValidator.error
    ) {
      this.save();
    }
  }
}

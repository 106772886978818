import { Component, OnInit ,ViewChild } from '@angular/core';
import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { PlantService } from '../plant/plant.service';
import { UserService } from 'src/app/@core/services/user.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/@core/services/token-storage.service';

@Component({
  selector: 'app-add-visitor',
  templateUrl: './add-visitor.component.html',
  styleUrls: ['./add-visitor.component.scss']
})
export class AddVisitorComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 7, 'user list');
  userTypesList: any = {
    AXESTRACK: [
      'AXESTRACK',
      'AXESTRACKUSER',
      'COMPANYADMIN',
      'COMPANYUSER',
      'PLANTADMIN',
      'PLANTUSER',
    ],
    AXESTRACKUSER: ['PLANTADMIN', 'PLANTUSER'],
    COMPANYADMIN: ['COMPANYADMIN', 'COMPANYUSER', 'PLANTADMIN', 'PLANTUSER'],
    COMPANYUSER: ['PLANTADMIN', 'PLANTUSER'],
    PLANTADMIN: ['PLANTADMIN', 'PLANTUSER'],
    PLANTUSER: ['PLANTUSER'],
  };

  constructor(
    private alertService: AlertService,
    private api: ApiService,
    private plantService: PlantService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService,
    public route: ActivatedRoute,
    public router:Router,
    public tokenService : TokenStorageService
  ) {
    this.getCompany();
    this.btn = 'save';
    /** breadcums implementation start*/


    if (this.role.user_role != 'AXESTRACK') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
  }

  visitor = {
    staffId: null,
    firstName: '',
    lastName: '',
    gender: 'Male',
    email: null,
    mobile: null,
    status: 'ACTIVE',
    companyId: null,
    plantId: null,
    processId: null,
    visitorName:''
  };

  title = 'Add Visitor';
  btn = 'save';
  data: any = [];
  processName: null;
  companyName: null;
  plantName: null;
  companyData: any = [];
  plantData: any = [];
  processData: any = [];
  userId = null;
  editFlag: boolean = false;
  deptList: any[] = [];
  selectedDeptIds: any[] = [];
  selectedDeptNames: any[] = [];
  showTable:boolean=true;
  staffType: any = 'VISITOR';
  staffTypeId: number;
  staffTypeName: String = null;
  visitorList: any[] = [];
  tableData: any = [];
  tabData: any[] = [];




  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getData();
  }

  ngOnDestroy(): void {

  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnInit(): void {
    this.dtTrigger.next();
    this.getCompany();
    this.getDepartmentList();

  }

  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        id: id,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.plantData = res.data;
            resolve(this.plantData);
          }
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  getDepartmentList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        companyId: this.visitor.companyId,
        plantId: this.visitor.plantId,
        allDepartments: false,
      };
      this.api.get('department/get-department-list', params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.deptList = res.data;
            resolve(this.deptList);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }


  selectedDepts(event: any) {
    this.selectedDeptIds = event.map((e) => e.dept_id);
    console.log('this.selectedDeptIds: ', this.selectedDeptIds);
  }

  setUserName() {
    console.log("Getting User Name")
    this.visitor.firstName=this.visitor.firstName?this.visitor.firstName.trim():null;
    this.visitor.lastName=this.visitor.lastName?this.visitor.lastName.trim():null;
    console.log(this.visitor.firstName)
    if(this.visitor.firstName || this.visitor.lastName){
      console.log(this.visitor.lastName)
    console.log("name", this.visitor.firstName , this.visitor.lastName)


        console.log('this.plantData: ', this.plantData);
        console.log('this.userService._loggedInUser.plantId: ', this.userService._loggedInUser.plantId);
        this.plantName = this.plantData.filter(
          (p) => p.plantId == this.userService._loggedInUser.plantId
        )[0].plantName;
        this.visitor.plantId = this.userService._loggedInUser.plantId;


      this.api
        .get(
          'user/generate-username?firstName=' +
            this.visitor.firstName +
            '&lastName=' +
            this.visitor.lastName +
            '&plantId=' +
            this.visitor.plantId +
            '&acronym=' +
            this.acronymGenerator(this.plantName)
        )
        .subscribe(
          (res: any) => {
            this.visitor.visitorName = res.data[0].username;
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
          }
        );

  }
  }

  acronymGenerator(text: any) {
    var words, acronym, nextWord;
    words = text.split(" ");
    acronym = '';
    let index = 0;
    while (index < words.length) {
      nextWord = words[index];
      acronym = acronym + nextWord.charAt(0);
      index = index + 1;
    }
    return acronym;
  }

  getData() {

    this.common.loading = true;
    this.showTable = false;
      let params = {
        allStaff: true,
        staffTypeName: 'Visitor',
      };
      this.api.get('staff/get-staff-list-by-staff-type', params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.visitorList =
              res.data.map((item) => ({
                ...item,
                departments: JSON.parse(item.departments),
              })) || [];
            this.tableData = this.visitorList;
            this.staffTypeId= res.data[0].staff_type_id
          }
          this.showTable = true;
          this.renderTable();
        },
        (err: any) => {
          console.error('Error:', err);
          this.common.loading = false;
        }
      );

  }



  saveData() {
    this.common.loading = true;
    let params: any;
    let apiUrl: any;


    console.log("savedata")
      params = {
        staffId: this.visitor.staffId,
        firstName: this.visitor.firstName,
        lastName: this.visitor.lastName,
        gender: this.visitor.gender,
        email: this.visitor.email,
        mobileNo: this.visitor.mobile,
        status: this.visitor.status,
        companyId: this.visitor.companyId,
        plantId: this.visitor.plantId,
        staffType: 'Visitor',
        deptIds: this.selectedDeptIds,
        userRole: this.role.user_role,
        staffTypeId: this.staffTypeId,
      };
      apiUrl = 'staff/create-staff';
      console.log(params)

    console.log(apiUrl, 'this is apiurl');
    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        if (res.status) {
          if(res.message=="Name Already Exists"){
            this.alertService.warn(res.message);
          }
          else{
            this.alertService.success(res.message);
          }
          this.common.loading = false;
          this.resetDetail();
          this.getData();
        } else {
          this.common.loading = false;
          this.alertService.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  resetDetail() {

    // if (this.staffType == 'USER') {
      this.visitor = {
        staffId: null,
        firstName: '',
        lastName: '',
        gender: 'Male',
        email: null,
        mobile: null,
        companyId: null,
        plantId: null,
        processId: null,
        status: 'ACTIVE',
        visitorName:''
      };

      this.selectedDeptIds = [];
      this.selectedDeptNames = [];
      console.log('deptIds', this.selectedDeptIds);

      this.companyName = null;
      this.plantName = null;
      this.processName = null;
      this.companyData = [];
      this.plantData = [];
      if(this.role.user_role=='AXESTRACK')this.getCompany();
      else this.getPlantById(this.userService._loggedInUser.companyId);
      this.btn = 'save';
      this.title = 'Add Visitor';
      this.userNameValidator = null;
      this.passwordValidator = null;
      this.statusValidator = null;
      this.mobileValidator = null;
      this.companyValidator = null;
      this.plantValidator = null;
      this.processValidator = null;
      this.typeValidator = null;
      this.empIdValidator = null;
      this.staffType = 'VISITOR';
      this.emailValidator=null;

      this.visitor = {
        staffId: null,
        firstName: '',
        lastName: '',
        gender: 'Male',
        email: null,
        mobile: null,
        companyId: null,
        plantId: null,
        processId: null,
        status: 'ACTIVE',
        visitorName:''

      };

      this.staffType = 'VISITOR';
      this.selectedDeptIds = [];
      this.selectedDeptNames = [];
      this.title = 'Add Visitor';
      console.log('deptIds', this.selectedDeptIds);
      this.companyName = null;
      this.plantName = null;
      this.companyData = [];
      this.plantData = [];
      this.getCompany();

      this.btn = 'save';
      this.userNameValidator = null;
      this.statusValidator = null;
      this.mobileValidator = null;
      this.typeValidator = null;
      this.empIdValidator = null;
      this.staffFirstNameValidator = null;
      this.staffLastNameValidator = null;

      this.staffTypeId = 5,
      this.staffTypeName = 'Visitor';
       this.visitorList=[];

  }

  confirmAlert(row?: any) {
    console.log('delete row: ', row);
    this.common.params = {
      title: 'Delete Visitor',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteData(row);
      }
    });
  }

  confirmAlertForUserBan(staffId: any, status:any) {

  console.log('status: ', status);
    this.common.params = {
      title: status + ' User',
      description:
        `<b>&nbsp;` + 'Are you sure you want to ' + status +' this user ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.blockUser(staffId,status);
      }
    });
  }



  deleteData(row?: any) {
    console.log(row);
    this.common.loading = true;
    let params: any;
    let apiURL: any;
     if(this.staffType == 'VISITOR'){
      params = {

        staffId: row.staff_id,
        firstName: row.first_name,
        lastName: row.last_name,
        gender: row.gender,
        email: row.email,
        mobileNo: row.mobile_number,
        status: 'DELETED',
        companyId: row.company_id,
        plantId: row.plant_id,
        staffType: row.type_name,
        deptIds: this.selectedDeptIds,
        userRole: this.role.user_role,
        staffTypeId: row.staff_type_id,
      };
      console.log(this.selectedDeptIds, 'this is in save');
      apiURL = 'staff/create-staff';
    }


    console.log('params: ', apiURL);

    this.api.post(apiURL, params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertService.success(res.message);
          this.getData();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  blockUser(staffId: any,status:any) {
   // console.log(userId)
    this.common.loading = true;
    let params = {
      staffId: staffId,
      status : status
    };
    this.api.get('staff/block-unblock-staff', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertService.success(res.message);
          this.getData();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  async editUser(row?: any) {
    this.editFlag = false;
    this.common.gotoTop();
    this.resetDetail();
    await this.getPlantById(row.company);
    this.companyName = this.companyData.find(
      (company) => company.companyId == row.company
    )?.companyName;
    this.plantName = this.plantData.find(
      (plant) => plant.plantId == row.plant
    )?.plantName;
    this.visitor.firstName = row.firstName;
    this.visitor.lastName = row.lastName;
    this.visitor.staffId = row.staff_id;
    this.visitor.gender = row.gender;

    this.visitor.email = row.email;
    this.visitor.mobile = row.mobileNumber;
    this.visitor.companyId = row.company;

    this.visitor.status = row.userStatus;
    this.visitor.plantId = row.plant;

    this.title = 'Edit Visitor Details';




    this.btn = 'update';
    this.title = 'Edit Visitor';


    await this.getDepartmentList();
    this.selectedDeptIds = row.departments.map((dept) => dept.deptId);
    console.log('this.selectedDeptIds: ', this.selectedDeptIds);
    this.selectedDeptNames = row.departments.map((dept) => ({
      dept_name: dept.deptName,
      dept_id: dept.deptId,
    }));
    console.log('this.selectedDeptNames: ', this.selectedDeptNames);
  }

  async editEmployee(row?: any) {
    this.resetDetail();
    // this.staffType='STAFF';
    await this.getPlantById(row.company_id);
    this.visitor.staffId= row.staff_id,
    this.visitor.companyId = row.company_id;
    this.visitor.plantId = row.plant_id;
    this.companyName = row.company_name;
    this.plantName = row.plant_name;
    this.staffTypeId = row.staff_type_id;
    this.visitor.firstName = row.first_name;
    this.visitor.lastName = row.last_name;
    this.visitor.gender = row.gender;
    this.visitor.email = row.email;
    this.visitor.mobile = row.mobile_number;
    this.visitor.status = row.staff_status;
    this.staffTypeName = row.type_name;
    this.title = 'Edit Visitor Details';
    await this.getDepartmentList();
    this.selectedDeptNames = row.departments.map((dept) => ({
      dept_name: dept.deptName,
      dept_id: dept.deptId,
    }));
  }



  staffFirstNameValidator: any;
  staffLastNameValidator: any;

  userNameValidator: any;
  passwordValidator: any;
  statusValidator: any;
  mobileValidator: any;
  companyValidator: any;
  plantValidator: any;
  processValidator: any;
  typeValidator: any;
  empIdValidator: any;
  emailValidator: any = false;

  formatZoneNames(dataArray: any): string {
    return dataArray.map((item) => item.deptName).join(', ');
  }
  formValidator() {
    console.log('sdfg', this.staffType);

      console.log('1', this.staffType);
      if (this.visitor.email != null && this.visitor.email != '') {
        this.emailValidator = this.common.emailValidator(
          this.visitor.email,
          'Invalid Email Id'
        );
      }
      console.log('2', this.staffType);
      this.staffFirstNameValidator = this.common.nullValidator(
        this.visitor.firstName,
        'Staff First Name Mandatory'
      );
      this.staffLastNameValidator = this.common.nullValidator(
        this.visitor.lastName,
        'Staff Last Name Mandatory'
      );
      console.log('3', this.staffType);
      this.staffTypeName='Visitor'
      this.typeValidator = this.common.nullValidator(
        this.staffTypeName,
        'Staff Type Mandatory'
      );
      this.mobileValidator = this.common.mobileNoValidator(
        this.visitor.mobile,
        'Mobile No. invalid'
      );
      console.log("xwhiujxw",this.emailValidator);
      if (
        !this.staffFirstNameValidator.error &&
        !this.staffLastNameValidator.error &&
        !this.mobileValidator.error &&
        ((this.emailValidator && !this.emailValidator.error)||this.emailValidator==null)
      ) {
        console.log("hxiwjxo");
        this.saveData();


  }





  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';

@Component({
  selector: 'app-audit-management',
  templateUrl: './audit-management.component.html',
  styleUrls: ['./audit-management.component.scss'],
})
export class AuditManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'List of Shifts');
  title = 'Audit Management';
  stageName: any = [];
  stageIds: any = [];
  actionData: any = [];
  actionData1: any = [];
  tableData: any = [];
  btn = 'save';
  auditEditData = [];
  auditAllTableData = [];
  listOfProcess = [];
  auditMapping = {
    id: null,
    actionId: null,
    avg: null,
    actionName: '',
    frequency: null,
    stageId: [],
    processId: null,
    processName: '',
  };
  processDisable: boolean = false;
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    private breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public alertService: AlertService,
    public userService: UserService,
    private modalService: NgbModal
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Audit', url: null },
      { name: 'Audit Management', link: '/user/audit-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    this.getAuditAllData();
    this.getAllProcess();
  }

  ngOnInit(): void {}

  reset() {
    this.auditMapping = {
      id: null,
      actionId: null,
      actionName: '',
      avg: null,
      frequency: null,
      stageId: [],
      processId: null,
      processName: '',
    };
    this.stageName = [];
    this.btn = 'save';
    this.actionData1 = [];
    this.processDisable = false;
    this.actionData = [];
    this.stageIds = [];
  }

  selectedUser(event: any) {
    this.auditMapping.actionId = event.tag_action_id;
    this.auditMapping.actionName = event.tag_action_name;
    this.actionData1 = [];
    this.actionData.forEach((item) => {
      if (item.tag_action_id != event.tag_action_id) {
        this.actionData1.push(item);
      }
    });
  }
  selectedProcess(event: any) {
    this.auditMapping.processId = event.processId;
    this.auditMapping.processName = event.processName;
    this.getAuditAction(event.processId);
  }
  getAllProcess() {
    this.common.loading = true;
    this.api.get('process/get-all-process?processActive=true').subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.listOfProcess = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAuditAction(processid) {
    return new Promise((resolve, reject) => {
      let params = '?id=' + processid;
      this.api.get('audit/get-audit-action' + params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.actionData = res.data;
          resolve(true);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(true);
        }
      );
    });
  }

  getAuditAllData() {
    this.common.loading = true;
    this.api.get('audit/get-audit-alldata').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.auditAllTableData = res.data;
        this.auditAllTableData.forEach((element) => {
          element.tag_actions = JSON.parse(element.tag_actions);
        });
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAuditEdit(item) {
    let params = '?id=' + item.id;
    this.api.get('audit/get-audit-edit' + params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.auditEditData = res.data;
        this.auditEditData.forEach((element) => {
          element.tag_actions = JSON.parse(element.tag_actions);
        });
        this.editData(res);
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  async editData(res) {
    this.common.gotoTop();
    this.getAuditAction(res.data[0].process_id).then((data) => {
      this.btn = 'update';
      this.processDisable = true;
      this.auditMapping.id = res.data[0].id;
      this.auditMapping.frequency = res.data[0].frequency;
      this.auditMapping.actionId = res.data[0].audit_action_id;
      this.auditMapping.processId = res.data[0].process_id;
      this.auditMapping.processName = res.data[0].process_name;
      this.stageIds = [];
      this.stageName = [];
      if (this.auditEditData.length > 0) {
        res.data[0].tag_actions.forEach((element) => {
          this.stageName.push(element);
        });
      }
      this.auditMapping.actionName = res.data[0].audit_action_name;
      this.actionData.forEach((item) => {
        if (item.tag_action_id != res.data[0].audit_action_id) {
          this.actionData1.push(item);
        }
      });
    });
  }

  confirmDeleteAction(row?: any) {
    this.common.params = {
      title: 'Delete Action ',
      description:
        `<b>&nbsp;` + 'Are you sure, Do you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteAudit(row);
      }
    });
  }

  deleteAudit(id) {
    this.api.get('audit/delete-audit?id=' + id).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.info(res.message);
          this.getAuditAllData();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  saveAction() {
    this.stageName.forEach((el) => {
      this.stageIds.push(el.tag_action_id);
    });
    this.common.loading = true;
    let params = {
      id: this.auditMapping.id,
      actionId: this.auditMapping.actionId,
      frequency: this.auditMapping.frequency,
      stageIds: this.stageIds,
    };
    this.api.post('audit/save-audit-management', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.getAuditAllData();
          this.reset();
        }
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
        // this.common.showError();
      }
    );
  }

  viewUserActionMapping(row?: any) {
    this.common.params = { details: [row], title: 'View Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  actionAuditValidator: any;
  frequencyValidator: any;
  stageValidator: any;
  formValidator() {
    if (this.auditMapping.frequency > 100) {
      return this.alertService.error(
        'Frequency should be less than or equal to 100...'
      );
    }
    this.actionAuditValidator = this.common.nullValidator(
      this.auditMapping.actionName,
      'Action Audit Mandatory'
    );
    this.frequencyValidator = this.common.nullValidator(
      this.auditMapping.frequency,
      'Frequency Mandatory'
    );
    this.stageValidator = this.common.nullValidator(
      this.stageName,
      'Stages Mandatory'
    );
    if (
      !this.actionAuditValidator.error &&
      !this.frequencyValidator.error &&
      !this.stageValidator.error
    ) {
      this.saveAction();
    }
  }
}

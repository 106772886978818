<div class="modal-body">
  <div class="text_logout">
    <h2>{{title|translate}}</h2>
    <p [innerHTML]="description|translate"></p>
  </div>

  <div class="button_logout">
    <button class="btn_cancel" type="button" outline (click)="closeModal(false,0)" id="cancel"
      style="cursor: pointer;">{{btn2|translate}}</button>
    <button class="btn_confirm" type="button" outline (click)="closeModal(true,1)" id="submit"
      style="cursor: pointer;">{{btn1|translate}}</button>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body" #dataToExport>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="header-block w-100" style="display: flex; justify-content:flex-start;flex-direction: row;align-items: center;">
              <h3 class="card-title" style="font-size: 20px;font-weight: 700;padding-top: 10px;padding-left: 10px;">
                {{'Sealing Report'|translate}}
              </h3>
              <i class="fa fa-download" style="margin-left: 10px;font-size: 20px;color:rgb(38, 38, 145);cursor: pointer;" (click)="downloadAsPdf()"></i>
            </div>

            <div class="top-boxes">
              <div class="top-box" [ngClass]="{'highlighted-box-red': reportData?.sealing_count !=reportData?.unsealing_count ,
              'highlighted-box-green': reportData?.sealing_count == reportData?.unsealing_count}">
              <div class="box-column">
                <h4>Sealing</h4>
                <p>{{reportData?.sealing_count}}</p>
              </div>
              <div class="box-column">
                <h4>Unsealing</h4>
                <p>{{reportData?.unsealing_count}}</p>
              </div>
            </div>


              <div class="top-box" [ngClass]="{'highlighted-box-red': reportData?.mismatchData && reportData?.mismatchData.length > 0,
                'highlighted-box-green': !reportData?.mismatchData || reportData?.mismatchData.length === 0 }">
                <div class="box-column">
                  <h4>Mismatch Count</h4>
                  <p>{{reportData?.mismatch_seal_count}}</p>
                </div>
              </div>

              <div class="top-box"  [ngClass]="{'highlighted-box-red': reportData?.broken_seal_count > 0,
                'highlighted-box-green': reportData?.broken_seal_count == 0 }" style="margin-right: 10px;">
                <div class="box-column">
                  <h4>Broken Seal Count</h4>
                  <p>{{reportData?.broken_seal_count}}</p>
                </div>
              </div>
            </div>
            <div class="vehicle_detail" width="100%" style="margin-top: 0px;">
              <div class="row">
                <div class="col-md-3 row_col">
                  <div class="key_value">
                    <span class="key">Trip Id: </span>
                    <span class="value">{{reportData?.extTripId}}</span>
                  </div>
                </div>
                <div class="col-md-3 row_col">
                  <div class="key_value">
                    <span class="key">Invoice No.: </span>
                    <span class="value">{{invoiceTripNumber}}</span>
                  </div>
                </div>
                <div class="col-md-3 row_col">
                  <div class="key_value">
                    <span class="key">Vehicle No.: </span>
                    <span class="value">{{reportData?.vehicleRCNo}}</span>
                  </div>
                </div>
                <div class="col-md-3 row_col">
                  <div class="key_value" [ngClass]="{'bg-green-200 text-green-800':reportData?.trip_status=='COMPLETED','bg-yellow-200 text-yellow-800':reportData?.trip_status=='INPROGRESS','bg-pink-200 text-pink-800':(reportData?.trip_status!='INPROGRESS' && reportData?.trip_status!='COMPLETED')}">
                    <span class="key" style="color:black;width:60%;">{{reportData?.processName}} Status: </span>
                    <span style="font-weight: 600;font-size: 15px;width:40%;margin-left: 5%">
                      {{reportData?.trip_status}}
                    </span>
                  </div>
                </div>
                <!-- <div class="col-md-3 row_col">
                  <div class="key_value">
                    <span class="key">{{'Start Time'}}: </span>
                    <span class="value">{{reportData?.startTime}}</span>
                  </div>
                </div> -->
                <!-- <div class="col-md-3 row_col">
                  <div class="key_value">
                    <span class="key">{{'End Time'}}: </span>
                    <span class="value">{{reportData?.endTime}}</span>
                  </div>
                </div> -->
              </div>
            </div>

            <div class="card-body">
              <div class="trip_detail">
                <div class="field_audit_report">

                  <div class="tabel_audit_report">
                    <div class="table-responsive">
                      <table class="custom-table">
                        <thead>
                          <tr style="text-align: center;">
                            <th>Seal Name</th>
                            <th *ngFor="let stage of stages">{{stage}}</th>
                            <th>Is Broken?</th>
                          </tr>
                        </thead>
                        <tbody>

                          <ng-container  *ngIf="reportData?.data.length == 0 && (reportData?.trip_status == 'COMPLETED' || reportData?.trip_status == 'CANCELLED' )">
                            <tr >
                              <td style="background-color: #2c65b2;font-size: 18px;color: white;text-align: center;">
                              </td>

                                <td >
                                  <div style="display: flex;margin-left: 5px;">
                                    <div style="display: flex; flex-direction: column;width: 80%;justify-content: center;">
                                      <span style="font-size: 16px; color: red;font-weight: 700;  align-self: center;">Forceful Closure - Loading Seals are unavailable</span>
                                    </div>
                                  </div>
                                </td>

                                <td >
                                  <div style="display: flex;margin-left: 5px;">
                                    <div style="display: flex; flex-direction: column;width: 80%;justify-content: center;">
                                      <span style="font-size: 16px; color: red;font-weight: 700;  align-self: center;">Forceful Closure - Unloading Seals are unavailable</span>
                                    </div>
                                  </div>
                                </td>
                                <td style="font-size:18px;text-align: center;">
                                </td>
                            </tr>
                          </ng-container>

                          <ng-container  *ngIf="reportData?.data.length == 0 && (reportData?.trip_status == 'INPROGRESS' || reportData?.trip_status == 'MAINTAINENCE') ">
                            <tr >
                              <td style="background-color: #2c65b2;font-size: 18px;color: white;text-align: center;">
                              </td>

                                <td >
                                  <div style="display: flex;margin-left: 5px;">
                                    <div style="display: flex; flex-direction: column;width: 80%;justify-content: center;">
                                      <span style="font-size: 16px; color: #f3b600;font-weight: 700; align-self: center;">Sealing Pending</span>
                                    </div>
                                  </div>
                                </td>

                                <td >
                                  <div style="display: flex;margin-left: 5px;">
                                    <div style="display: flex; flex-direction: column;width: 80%;justify-content: center;">
                                      <span style="font-size: 16px; color: #f3b600;font-weight: 700;align-self: center;">Unsealing Pending</span>
                                    </div>
                                  </div>
                                </td>




                              <td style="font-size:18px;text-align: center;">
                              </td>
                            </tr>
                          </ng-container>


                          <ng-container  *ngIf="reportData?.data.length > 0 ">
                            <tr *ngFor="let item of reportData?.data">
                              <td style="background-color: #2c65b2;font-size: 18px;color: white;text-align: center;">
                                {{item.fieldName}}
                                <img class="compare_icon" src="assets/images/compare.png"
                                   title="Compare"(click)="contentModal(content, item)"
                                   >
                              </td>
                              <ng-container *ngIf="reportData?.data.length > 0">
                                <td *ngFor="let element of item.response">
                                  <div style="display: flex;margin-left: 5px;">
                                    <div style="display: flex; flex-direction: column;width: 80%;justify-content: center;" *ngIf="element.barcodeValue!=''">
                                      <div style="padding: 5px;width: 100%;border-top-left-radius: 5px;border-top-right-radius: 5px;">
                                        <span style="font-size: 16px;font-weight: 500;width: 100%;display: inline-flex;padding:2px;border-radius: 5px;"> Value : {{element.barcodeValue}}</span>
                                      </div>
                                      <div style="padding: 5px;width: 100%;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;">
                                        <span style="font-size: 16px;font-weight: 500;width: 100%;display: inline-flex;padding:2px;border-radius: 5px;"> Location :
                                        <img *ngIf="element.lat!=''" (click)="openMap(element.lat, element.lng)"
                                      style="cursor:pointer;float: right; padding: 0px; background-color: transparent; width: auto;margin-left: 5px;"
                                      height="30" width="30" src="assets/icons/map.png" />
                                    </span>
                                      </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column;width: 80%;justify-content: center;" *ngIf="element.barcodeValue=='' && reportData?.unsealing_count==0 &&  (reportData?.trip_status == 'INPROGRESS' || reportData?.trip_status == 'MAINTAINENCE')">
                                      <span style="font-size: 16px; color: #f3b600;font-weight: 700;align-self: center; ">Unsealing Pending</span>
                                    </div>
                                    <div style="display: flex; flex-direction: column;width: 80%;justify-content: center;" *ngIf="element.barcodeValue=='' && reportData?.unsealing_count==0 && (reportData?.trip_status == 'COMPLETED' || reportData?.trip_status == 'CANCELLED' )">
                                      <span style="font-size: 16px; color: red;font-weight: 700; align-self: center;">Forceful Closure - Unloading seals are not present</span>
                                    </div>
                                    <div style="margin-right: 3px;width: 20%;margin-left: 10px;">
                                      <!-- <img *ngIf="isImgTypeFile(element.barcodeUrl)" [src]="element.barcodeUrl  | urltobase64 | async" (click)="displayImage(element.barcodeUrl)" style="width: 90px;height:90px;border-radius: 5px"> -->
                                      <img *ngIf="element.barcodeUrl !=null" [src]="element.barcodeUrl" (click)="displayImage(element.barcodeUrl)" style="width: 90px;height:90px;border-radius: 5px" crossorigin="anonymous">
                                    </div>
                                  </div>
                                </td>
                              </ng-container>
                              <td style="font-size:18px;text-align: center;">
                                <span [ngClass]="item.isBroken.toLowerCase() == 'yes' ? 'badge badge-danger' : 'badge badge-success '" style="padding:5px">
                                  {{item.isBroken}}
                                </span>
                              </td>
                            </tr>
                          </ng-container>











                          <tr *ngFor="let item of reportData?.mismatchData">
                            <td style="background-color:rgb(255, 137, 137) ;font-size: 18px;color: black;text-align: center;">Mis-matched Seal</td>
                            <td></td>
                            <td>
                              <div style="display: flex;margin-left: 5px;">
                                <div style="display: flex; flex-direction: column;width: 80%;justify-content: center;" *ngIf="item.values[0].value!=''">
                                  <div style="padding: 5px;width: 100%;border-top-left-radius: 5px;border-top-right-radius: 5px;">
                                    <span style="font-size: 16px;font-weight: 500;width: 100%;display: inline-flex;padding:2px;border-radius: 5px;"> Value : {{item.values[0].value}}</span>
                                  </div>
                                  <div style="padding: 5px;width: 100%;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;">
                                    <span style="font-size: 16px;font-weight: 500;width: 100%;display: inline-flex;padding:2px;border-radius: 5px;"> Location :
                                    <img *ngIf="item.values[0].lat!=''" (click)="openMap(item.values[0].lat, item.values[0].lng)"
                                  style="cursor:pointer;float: right; padding: 0px; background-color: transparent; width: auto;margin-left: 5px;"
                                  height="30" width="30" src="assets/icons/map.png" />
                                </span>
                                  </div>
                                </div>
                                <div style="margin-right: 3px;width: 20%;margin-left: 10px;">
                                  <!-- <img *ngIf="isImgTypeFile(item.values[0].fileUrl)" [src]="item.values[0].fileUrl  | urltobase64 | async" (click)="displayImage(item.values[0].fileUrl)" style="width: 90px;height:90px;border-radius: 5px"> -->
                                  <img *ngIf="item.values[0].fileUrl != null" [src]="item.values[0].fileUrl" (click)="displayImage(item.values[0].fileUrl)" style="width: 90px;height:90px;border-radius: 5px" crossorigin="anonymous">
                                </div>
                              </div>
                            </td>
                            <td style="font-size:18px;text-align: center;">
                              <span [ngClass]="item.isBroken.toLowerCase() == 'yes' ? 'badge badge-danger' : 'badge badge-success '" style="padding:5px">
                                {{item.isBroken}}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Compare {{fieldName}}</h6>
    <div aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-times"></i></div>
  </div>
  <div class="modal-body">
    <div class="row images_row">
      <ng-container *ngFor="let data of modalData;">
        <ng-container>
          <div class="col-md-4 image_div"
            *ngIf="isImgTypeFile(data.barcodeUrl)">
            <h6 style="font-weight: bold; text-align: center;">{{data.stageName}}</h6>
            <img [src]="data.barcodeUrl">
          </div>
        </ng-container>
      </ng-container>

    </div>
  </div>
</ng-template>

<div id="download">
  <img #canvas>
  <a #downloadLink></a>
</div>

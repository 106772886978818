<div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title" *ngIf="this.common.params.checkInFlag">{{'Check-In'|customTranslate}}</h5>
    <h5 class="modal-title" id="modal-basic-title" *ngIf="!this.common.params.checkInFlag">{{'Check-Out'|customTranslate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-lg-6 col-sm-12" *ngIf="this.common.params.checkInFlag">
        <label for="my-input" class="required">{{'Return Date'|customTranslate}}</label>
        <input class="form-control" type="date" name="returnDate" min="{{minimumTimestamp | date:'yyyy-MM-dd'}}"
          [(ngModel)]="returnDate">
        <div class="error" *ngIf="returnDateValidator && returnDateValidator.error">
          <span>{{returnDateValidator.msg}} </span>
        </div>
      </div>

      <div class="form-group col-lg-6 col-sm-12" *ngIf="!this.common.params.checkInFlag && (common.params.userType!=null && (common.params.userType=='Group' || common.params.userType=='Department'))">
        <label for="my-input" class="required">{{'Checkout By'|customTranslate}}</label>
        <ps-auto-suggestion (onSelected)="onSelectAssignee($event)" [data]="dataList" display="name" placeholder="Select User"
          [preSelected]="{name:selectedAssigneeName}">
      </ps-auto-suggestion>
        <div class="error" *ngIf="assigneeValidator && assigneeValidator.error">
          <span>{{assigneeValidator.msg}} </span>
        </div>
      </div>

      <div class="form-group col-sm-12">
        <label for="my-input">{{'Remarks'|customTranslate}}</label>
        <textarea class="form-control" id="remarks" name="remarks" rows="4" cols="50"
          [(ngModel)]="remarks"></textarea>
      </div>


  </div>
  <div class="modal-footer">
    <button class="btn btn-success" type="button" outline (click)="formValidator()"
      style="cursor: pointer;">{{'Submit'|customTranslate}}</button>
  </div>

<div class="form-group">
  <label [ngClass]="{'required':field?.mandatory==true}">{{field.field_name}}
  <i *ngIf="field.isEditable && field.field_type!='LABEL'" (click)="editTriggerEmit(field)" class="fa fa-edit"></i>
  </label>
  <ng-container *ngIf="inputTypes.includes(field.field_type); else otherFields;">
    <input [type]="inputType[field.field_type]" class="form-control" [id]="'field'+i" (input)="enterValues($event,field,i)"
    [value]="field.values?.length?field?.values[0]:''" [disabled]="field.isDisabled"
    [min]="field.field_type === 'DATETIMEPICKER' || field.field_type === 'DATEPICKER' || field.field_type === 'TIME' ? getMinDate(field.field_type) : null">
  </ng-container>

  <ng-template #otherFields>
    <ng-container *ngIf="field.field_type == 'LABEL'">
      <div class="field">
        <p style="font-size: small" [innerHTML]="field.options[0][field.store_value]"></p>
      </div>
    </ng-container>

    <ng-container *ngIf="field.field_type == 'TEXTAREA'">
      <textarea rows="1" cols="50" [id]="'field'+i"
      [value]="field?.values?.length?field?.values[0]:''" (input)="enterValues($event,field,i)"
      class="form-control" [disabled]="field.isDisabled"></textarea>
    </ng-container>


    <!-- Merge Radio button and checkbox -->
    <ng-container *ngIf="field.field_type=='RADIOBUTTON'">
      <div *ngIf="field.options && !field.isDisabled">
        <ng-container *ngFor="let opt of field.options;index as j">
          <input type="radio" [name]="'field-' + i + '-' + field?.master_field_id" [id]="'field'+i" value="{{opt.name}}"
            [checked]="field?.values?.length ?field?.values[0] == opt.name:false"
            (input)="enterValues($event,field,i)">&nbsp;<label [for]="'field-' + j + field?.master_field_id"
            style="margin-right:3px">{{opt.name}}</label>
        </ng-container>
      </div>
      <input type="text" class="form-control" *ngIf="field.isDisabled"
      [value]="field?.values" disabled>
    </ng-container>


    <ng-container *ngIf="field.field_type=='CHECKBOX'">
      <div *ngIf="field.options && !field.isDisabled" style="display: flex;">
        <ng-container *ngFor="let opt of field.options;index as j">
          <input type="checkbox" value="{{opt.name}}" [name]="'field'+i + field?.master_field_id" [id]="'field'+i"
            [checked]="field?.values?.length ?field?.values.includes(opt.name):false"
            (input)="enterValues($event,field,i)" [disabled]="field.isDisabled" style="font-size: 16px;border-radius: 10px;">&nbsp;
          <label [for]="'field'+i + field?.master_field_id" style="margin-right: 3px;">{{opt.name}}</label>
        </ng-container>
      </div>
      <input type="text" class="form-control" *ngIf="field.isDisabled"
        [value]="field?.values" disabled>
    </ng-container>

    <ng-container *ngIf="field.field_type=='SELECTLISTSINGLE'">
      <ps-auto-suggestion (onSelected)="enterValues($event,field,i)" [data]="field.options==null ? [] : field.options" [display]="field.label"
      [preSelected]="getPreSelected(field.label,i)" placeholder="Choose option" [readonly]="field.isDisabled">
    </ps-auto-suggestion>
    </ng-container>

    <ng-container *ngIf="field.field_type=='SELECTLISTMULTIPLE'">
      <ps-auto-suggestion isMultiSelect="true" (onSelected)="enterValues($event,field,i)"
      [data]="field.options==null ? [] : field.options" [display]="field.label" placeholder="Choose option"
      [preSelected]="getPreSelectedMulti(field.label,i)" [readonly]="field.isDisabled">
    </ps-auto-suggestion>
    </ng-container>

    <ng-container *ngIf="field.field_type=='FILE'  || field.field_type =='IMAGE'">
      <div *ngIf="field?.values?.length==0 || !field.isDisabled">
        <input type="file" [accept]="allowedExtensions[field.field_type]"  class="field_class file_class" multiple
          [id]="'field'+i" (input)="enterValues($event,field,i)">
      </div>
      <div class="image_div" *ngIf="field?.values?.length  && field.isDisabled">
        {{imageName(field?.values[0])}}
        <ng-container *ngIf="image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg'">
          <img [src]="image_url" alt="" style="height: 50px; width: 50px;border-radius:10px"><span
            style="font-size:small">{{"
            "+this.image_name}}</span>
        </ng-container>
        <ng-container *ngIf="image_ext!='png'&& image_ext!='jpeg'&& image_ext!='jpg'">
          <i class="fa fa-file" style="color:royalblue"></i><span style="font-size:small;color: blue;
                  text-decoration-line: underline;" (click)="newTab(image_url)">{{"
            "+this.image_name}}</span>
        </ng-container>
      </div>

    </ng-container>

    <ng-container *ngIf="(field.field_type=='RFIDCODE' || field.field_type=='BARCODE')">
      <div class="text_div" style="display:flex">
        <input type="text" class="form-control mr-2" [value]="field?.values[0]?.value"
          [disabled]="field.isDisabled" (input)="enterValues($event,field,i)">
        <img (click)="location(field?.values[0]?.lat,field?.values[0]?.lng)"
          style="height: 20px; width: 20px;" src="assets/images/google_map_icon.png" />
      </div>
    </ng-container>

    <ng-container *ngIf="field.field_type=='GEOCODEDIMAGE' && !field.isDisabled">
      <div class="field camera_option">
        <!-- <label>Capture Image</label> -->
        <div class="upload_img" *ngIf="!showCamera">
          <img src="assets/images/camera_plus.png" (click)="openCamera(field,i)" style="height:100px; width: 100px;">
        </div>

        <div *ngIf="showCamera">
          <div style="display: flex; flex-direction: column; align-items:center;"
          *ngIf="fieldValueObj.values?.length">
          <img class="circle-frame" style="height: 30px; margin-bottom: 5px;"
          [src]="fieldValueObj.values[0].fileUrl" />
          <p style="font-size: 12px;font-weight: 400;">Upload Done! <button class="btn btn-warning" (click)="retakeImage = !retakeImage;showCamera = !showCamera; fieldValueObj.values=[];">Retake</button></p>
        </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="(field.field_type=='RFIDCODEWITHIMAGE'|| field.field_type=='BARCODEWITHIMAGE' || field.field_type == 'GEOCODEDIMAGE')">
      <div class = "display-image" *ngIf="field.isDisabled">
        {{imageName(field?.values[0]?.fileUrl)}}
        <div class="image" *ngIf="field?.values?.length" (click)="displayImage(field?.values[0]?.fileUrl)">
          <img [src]="image_url" alt="" style="height: 50px; width: 50px;">
        </div>
      </div>
      <div class="image_div" *ngIf="!field.isDisabled && (image_ext=='png'||image_ext=='jpeg'||image_ext=='jpg')">
          <div class="text_div" style="display:flex">
            <input *ngIf="field.field_type != 'GEOCODEDIMAGE' && !field.isEditable" type="text" class="form-control mr-2" [value]="field?.values[0]?.value"
              [disabled]="field.isDisabled" (input)="onValuesEntered($event, 'text',field)">
            <img (click)="location(field?.values[0]?.lat,field?.values[0]?.lng)"
              style="height: 20px; width: 20px;" src="assets/images/google_map_icon.png" />
          </div>
          <div class="image-div" *ngIf="!field?.values?.length || !field.isEditable">
            <input type="file" accept=".jpg,.png,.jpeg" class="field_class file_class" multiple [id]="'field'+i"
              (input)="onValuesEntered($event, 'file',field)">
          </div>
      </div>
    </ng-container>

    <ng-container *ngIf="field.field_type=='SIGNATURE'">
      <div class="image_div" *ngIf="field?.values?.length &&  field.isDisabled">
        <img [src]="field?.values" alt="" style="width:150px;height:50px;" [id]="'field'+i">
      </div>
      <div *ngIf="!field?.values?.length || !field.isDisabled" (mouseleave)="enterValues($event,field,i)" >
        <app-signature (getSignature)="getSignature=$event"></app-signature>
      </div>
    </ng-container>

    <ng-container *ngIf="(field.field_type=='CAMERASTREAMING' || field.field_type == 'VIDEOSTREAMING')&& !field.isDisabled">
      <app-live-streaming [isCamera]="field.field_type=='CAMERASTREAMING' ? true : false" [fieldId]="field?.field_id"
        (getStreamValue)="enterValues($event, field,field.index)">
      </app-live-streaming>
    </ng-container>
  </ng-template>
</div>


import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { ActionFormComponent } from 'src/app/modals/action-form/action-form.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { ActivatedRoute } from '@angular/router';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ThemeService } from 'ng2-charts';
import { resolve } from 'dns';

@Component({
  selector: 'app-dock-dashboard',
  templateUrl: './dock-dashboard.component.html',
  styleUrls: ['./dock-dashboard.component.scss'],
})
export class DockDashboardComponent implements OnInit {
  activeTab: number = 0;
  plantId: number = null;
  dockData: any = [{}];
  dockCount: any = {
    late: 0,
    delay: 0,
    total: 0,
  };
  dates: any = [];
  currentTime: number = 3;
  activeDate: any = null;
  searchValue: string = '';
  dockFilteredData: any = [{}];
  searchTrue: boolean = false;
  processList: any = [];
  selProcess: string = '';
  dockList: any[] = [];
  activeView: string = 'Current';
  sliceIndex: number = -1;
  processName: string = '';
  urlAuthKey: string = '';
  plantList: any[] = [];
  selectedPlant: any;
  mapping={
    productId:null,
    destinationId:null
  }

  constructor(
    public breadcrumbService: BreadcrumbService,
    private api: ApiService,
    private modalService: NgbModal,
    public common: CommonService,
    private activatedRoute: ActivatedRoute,
    public plantService: PlantService,
    public role: RoleService,
    public alert:AlertService
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.urlAuthKey = params['authkey'] || null;
    });
    let fromDate = new Date(new Date().setDate(new Date().getDate() - 3));
    let toDate = new Date(new Date().setDate(new Date().getDate() + 3));
    while (fromDate.getTime() <= toDate.getTime()) {
      this.dates.push(_.clone(fromDate));
      fromDate.setDate(fromDate.getDate() + 1);
    }
    this.role.user_role == 'COMPANYADMIN'
      ? this.getPlantById()
      : this.getProcessList();
  }



  saveMapping(){
    this.api.post("dock/save-product-dock-mapping",this.mapping).subscribe((response:any) => {
      if (response.status) {
        this.alert.success("Product assigned successfully");
      } else {
        this.alert.error(response.message);
      }

    }, (error) => {
      console.log(error);
    });
  }

  selectedProcess(e: any) {
    this.selProcess = e.value;
    this.activeTab = 0;
    this.processName = this.processList.filter(
      (item) => item.processId == e.value
    )[0].processName;
    this.getQueueData(null);
    // this.getDockCount();
  }

  getQueueData(slotDate: any) {
    let params =
      this.plantId != null
        ? { plantId: this.plantId, date: slotDate, processId: this.selProcess }
        : { date: slotDate, processId: this.selProcess };
    this.api.get('dock/get-queue-data', params).subscribe((res: any) => {
      if (res.status) {
        this.dockData = res.data;
        this.dockFilteredData = res.data;
        this.activeView = 'Current';
        if (res.data.length > 0) {
          this.getQueueCount();
          this.sliceIndex =
            this.currentTime == 3 ? this.getSliceIndex(0) : false;
        } else {
          this.dockCount = {
            late: 0,
            delay: 0,
            total: 0,
          };
        }
      }
      // let scrollIndex = 0;
      if (this.dockData.length >= this.activeTab)
        this.switchDock(this.activeTab);
    });
  }

  getQueueCount() {
    this.dockCount = {
      late: 0,
      delay: 0,
      total: 0,
    };
    this.dockData.forEach((element) => {
      element['dockCount'] = 0;
      element.dailySlot.forEach((value) => {
        this.dockCount.total += value.slot_data.length;
        let temp = _.groupBy(value.slot_data, 'vehicle_status');
        this.dockCount.delay += temp.Delay ? temp.Delay.length : 0;
        let temp2 = _.groupBy(value.slot_data, 'loading_status');
        this.dockCount.late += temp2.Delay ? temp2.Delay.length : 0;
        let tempData = _.groupBy(value.slot_data, 'status');
        element['dockCount'] +=
          value.slot_data.length -
          (tempData.Completed != null ? tempData.Completed.length : 0);
      });
    });
  }

  getCurrentDockCount(dockData: any) {
    let temp = 0;
    dockData.dailySlot.forEach((value) => {
      this.dockCount.total += value.slot_data.length;
      let tempData = _.groupBy(value.slot_data, 'status');
      let tempData2 = _.groupBy(value.slot_data, 'destination_code');
      temp +=
        tempData2[dockData.destination_id].length -
        (tempData.Completed != null ? tempData.Completed.length : 0);
    });;
    return temp;
  }

  switchDock(index: any) {
    this.activeTab = index;
    this.sliceIndex =
      this.currentTime == 3 &&
      this.activeView == 'Current' &&
      this.getSliceIndex(index);
  }

  switchDate(index: any, shift: string) {
    if (index == -1) {
      if (
        (shift == 'left' && this.currentTime != 0) ||
        (shift == 'right' && this.currentTime != 6) ||
        shift == 'first' ||
        shift == 'last'
      ) {
        this.currentTime =
          shift == 'left'
            ? this.currentTime - 1
            : shift == 'first'
            ? 0
            : shift == 'right'
            ? this.currentTime + 1
            : 6;
      }
    } else {
      this.currentTime = index;
    }

    let year = this.dates[this.currentTime].getFullYear();
    let month = this.dates[this.currentTime].getMonth();
    let date = this.dates[this.currentTime].getDate();
    this.activeDate = `${date}-${month + 1}-${year}`;
    // this.switchDock(0)
    this.getQueueData(this.activeDate);
  }

  searchVehicle() {
    this.dockData = JSON.parse(JSON.stringify(this.dockFilteredData));
    this.searchTrue = false;
    if (this.searchValue.length > 3) {
      for (let [index, item] of [
        ...this.dockData[this.activeTab].dailySlot,
      ].entries()) {
        if (item.slot_data.length) {
          this.dockData[this.activeTab].dailySlot[index].slot_data = [
            ...item.slot_data,
          ].filter((vehicle) =>
            vehicle.rc_no
              .toLowerCase()
              .includes(this.searchValue.trim().toLowerCase())
          );
        }
        this.searchTrue = true;
      }
    }
  }

  isTimeInRange(startTime: string, endTime: string): boolean {
    const start = this.extractTimeFromDate(new Date(`1970-01-01T${startTime}`));
    const end = this.extractTimeFromDate(new Date(`1970-01-01T${endTime}`));
    const check = this.extractTimeFromDate(new Date());
    return (check >= start && check <= end) || (check <= start && check <= end);
  }

  extractTimeFromDate(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;

    return formattedTime;
  }

  getSliceIndex(activeTab: number) {
    for (let [index, item] of this.dockData[activeTab].dailySlot.entries()) {
      if (this.isTimeInRange(item.start_time, item.end_time)) {
        return index;
      }
    }
    return -1;
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantList = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  onSelectPlant(e: any) {
    this.selectedPlant = e.value;
    this.getProcessList();
  }

  getProcessList() {
    let params = {
      processActive: true,
      plantId: this.selectedPlant,
    };
    this.api.get('process/get-all-process', params).subscribe((res: any) => {
      if (res.status) {
        this.processList = res.data;
        this.selProcess = res.data[0].processId;
        this.processName = this.processList.filter(
          (item) => item.processId == this.selProcess
        )[0].processName;

        this.getQueueData(null);
        // this.getDockCount();
      }
    });
  }

  getCurrentSlotVehicle(dataSet: any) {
    dataSet.forEach((res) => {});
  }

  async openModal(res: any) {
    await this.getDockCount(res.transaction_id);
    this.common.params = {
      rowDetail: 'Dock',
      dockData: this.dockList,
      txnId: res.transaction_id,
      processId: this.selProcess,
      stageId: res.stage_id,
      vehicleId: res.vid_rsn,
      dockId: res.destination_code,
      dockName: res.destination_name,
      isPriority: res.is_priority,
      flowType: 1,
      stageType: res.status == 'Loaded' ? 'Dock Stage' : 'Normal Stage',
    };
    this.assignDock();
  }

  assignDock() {
    const activeModal = this.modalService.open(ActionFormComponent, {
      size: 'lg',
    });
    activeModal.result.then((res) => {
      this.getQueueData(null);
    });
  }

  getDockCount(txnId:number):Promise<any> {
    return new Promise((resolve,reject)=>{

    let params={
      processId:this.selProcess,
      txnId:txnId
    }
    this.api
      .get('dashboard/check-dock-type-destination',params)
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.dockList = res.data;
          }
          resolve(this.dockList);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    })
  }

  switchView(view: string) {
    this.activeView = view;
    this.sliceIndex =
      this.currentTime == 3 &&
      this.activeView == 'Current' &&
      this.getSliceIndex(this.activeTab);
  }

  getFilteredDockData(data: any, index: number) {
    let dockDataFiltered;
    let node = document.getElementById('no-vehicle-found-' + index);
    if (this.activeView == 'Current') {
      dockDataFiltered = data.filter((res) => res.status != 'Completed');
      if (dockDataFiltered.length == 0) {
        node.hidden = false;
        document.getElementById('slot-block-' + index).appendChild(node);
      } else {
        node.hidden = true;
        document.getElementById('slot-block-' + index).appendChild(node);
      }
      return dockDataFiltered;
    } else if (this.activeView == 'Late') {
      dockDataFiltered = data.filter((res) => res.vehicle_status == 'Delay');
      if (dockDataFiltered.length == 0) {
        node.hidden = false;
        document.getElementById('slot-block-' + index).appendChild(node);
      }
      return dockDataFiltered;
    } else {
      return data.reduce((acc, map) => {
        if (map.status === 'Completed') {
          acc.unshift(map); // Insert at the beginning
        } else {
          acc.push(map); // Append at the end
        }
        return acc;
      }, []);
    }
  }

  tripModal(vehicle) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.transaction_id,
      processName: this.processName,
    };
  }

  tripTrackingModal(vehicle) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.transaction_id,
      vehicleName: vehicle.rc_no + '-' + this.processName,
    };
  }
}

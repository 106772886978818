<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{'Add Request'|translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="form-group col-lg-6 col-sm-12">
      <label for="my-input" class="required">{{'From Date'|translate}}</label>
      <input class="form-control" type="date" name="fromDate" min="{{minimumTimestamp | date:'yyyy-MM-dd'}}"
        [(ngModel)]="assetRequest.fromDate">
      <div class="error" *ngIf="fromDateValidator && fromDateValidator.error">
        <span>{{fromDateValidator.msg}} </span>
      </div>
    </div>

    <div class="form-group col-lg-6 col-sm-12" *ngIf="assetRequest.fromDate!=null">
      <label for="my-input" class="required">{{'To Date'|translate}}</label>
      <input class="form-control" type="date" name="toDate" min="{{assetRequest.fromDate| date:'yyyy-MM-dd'}}"
        [(ngModel)]="assetRequest.toDate" (change)="getRequestedAssets()">
      <div class="error" *ngIf="toDateValidator && toDateValidator.error">
        <span>{{toDateValidator.msg}} </span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="(assetRequest.fromDate!=null && assetRequest.toDate!=null)">
    <div class="row">
      <div class="form-group col-lg-12 col-sm-12">
        <label for="" class="d-block" class="required"> {{'Choose Asset'|translate}} </label>
        <ps-auto-suggestion (onSelected)="selectedAssets($event)" class="required" [data]="responseData"
          isMultiSelect="true" display="asset_name" placeholder="Choose Asset"
          [preSelected]="{asset_name:assetRequest.assetList}">
        </ps-auto-suggestion>
        <div class="error" *ngIf="assetValidator && assetValidator.error">
          <span>{{assetValidator.msg}} </span>
        </div>
      </div>

      <div class="form-group col-lg-12 col-sm-12" *ngIf="requestingForList.length<=7">
        <label for="" class="d-block" class="required"> {{'Requesting For'|translate}} </label>
        <div class="row ml-0">
          <div class="form-check-inline" *ngFor="let req of requestingForList">
            <input class="form-check-input" type="radio" name="requestingFor" [(ngModel)]="assetRequest.requestingForName"
              id={{req.name}} value={{req.name}} (change)="selectedRequestFor(req)">
            <label for={{req.name}}>
              {{req.name}}
            </label>
          </div>
        </div>
        <div class="error" *ngIf="requestingForValidator && requestingForValidator.error">
          <span>{{requestingForValidator.msg}} </span>
        </div>
      </div>

      <div class="form-group col-lg-12 col-sm-12" *ngIf="requestingForList.length>7">
        <label for="" class="d-block" class="required"> {{'Requesting For'|translate}} </label>
        <ps-auto-suggestion (onSelected)="selectedRequestFor($event)" [data]="requestingForList" display='name'
          placeholder="Choose Requesting for" [preSelected]="{name:assetRequest.requestingForName}">
        </ps-auto-suggestion>
        <div class="error" *ngIf="requestingForValidator && requestingForValidator.error">
          <span>{{requestingForValidator.msg}} </span>
        </div>
      </div>

      <div class="form-group col-lg-6 col-sm-12" *ngIf="assetRequest.requestingForName!=null">
        <label for="" class="d-block" class="required"> {{labelText|translate}}</label>
        <ps-auto-suggestion (onSelected)="selectedRequest($event)" class="required" [data]="requestForList"
          display={{requestForDisplayName}} placeholder={{labelText}} [preSelected]="requestForIdLabel()">
        </ps-auto-suggestion>
        <div class="error" *ngIf="requestForValidator && requestForValidator.error">
          <span>{{requestForValidator.msg}} </span>
        </div>
      </div>

      <div class="form-group col-lg-6 col-sm-12">
        <label for="my-input" class="required">{{'priority'|translate}}</label>
        <ps-auto-suggestion (onSelected)="assetRequest.criticality=$event.level" class="required" [data]="crticalityList"
          display="level" placeholder="Choose crticality" [preSelected]="{level:assetRequest.criticality}">
        </ps-auto-suggestion>
        <div class="error" *ngIf="criticalityValidator && criticalityValidator.error">
          <span>{{criticalityValidator.msg}} </span>
        </div>
      </div>

      <div class="form-group col-lg-12 col-sm-12">
        <label for="my-input">{{'Remarks'|translate}}</label>
        <textarea class="form-control" id="remarks" name="remarks" rows="6" cols="50"
          [(ngModel)]="assetRequest.remarks"></textarea>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" outline (click)="formValidator()"
    style="cursor: pointer;">{{'Submit'|translate}}</button>
</div>
<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{(title | lowercase) | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body" >
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="sel1" class="required">{{"action_scope" | translate}}:</label>
                  <select class="form-control" id="sel1" name="processId" [(ngModel)]="stageAction.actionScope">
                    <option value="local">Local</option>
                    <option value="global">Global</option>
                  </select>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK' && stageAction.actionScope == 'local'">
                  <label for="my-input" class="required">{{"select_company" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectCompany($event)" [data]="companyList"
                    display="companyName" placeholder="Choose Company"
                    [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyNameValidator && companyNameValidator.error">
                    <span>{{companyNameValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="(role.user_role == 'AXESTRACK' && stageAction.actionScope == 'local')||(role.user_role == 'COMPANYADMIN')">
                  <label for="my-input" class="required">{{"select_plant" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectPlant($event)" [data]="plantList"
                    display="plantName" placeholder="Choose Plant"
                    [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{plantNameValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="stageAction.actionScope == 'local'">
                  <label for="sel1" class="required">{{"process" | translate}}:</label>
                  <ps-auto-suggestion (onSelected)="selectProcess($event)" [data]="processList" display="processName"
                    placeholder="Choose Process" [preSelected]="{processName:stageAction.processName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error">
                    <span>{{processValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{("action_name" | translate)}}</label>
                  <input class="form-control" type="text" name="stageActionName" [(ngModel)]="stageAction.actionName">
                  <div class="error" *ngIf="actionNameValidator && actionNameValidator.error">
                    <span>{{actionNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{("action_description" | translate)}}</label>
                  <input class="form-control" type="text" name="actionDescription"
                    [(ngModel)]="stageAction.actionDescription">
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="sel1" class="required">{{("action_type" | translate)}}:</label>
                  <select class="form-control" id="sel1" name="actionType" [(ngModel)]="stageAction.actionType" (change)="selectActionType()">
                    <option value="1">Manual</option>
                    <option value="0">Auto</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="stageAction.actionType == 1">
                  <label for="sel1" class="required">{{("action_response type" | translate)}}:</label>
                  <select class="form-control" id="sel1" name="actionType" [(ngModel)]="stageAction.actionResponseType">
                    <option value="0">Boolean</option>
                    <option value="1">Form</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required"> {{("status" | translate)}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="actionStatus"
                      [(ngModel)]="stageAction.actionStatus" id="stage_status_1" value="ACTIVE" checked>
                    <label for="exampleRadios1">
                      {{("active" | translate)}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="actionStatus"
                      [(ngModel)]="stageAction.actionStatus" value="INACTIVE" id="stage_status_2">
                    <label for="exampleRadios2">
                      {{("inactive" | translate)}}
                    </label>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required">{{("geofence" | translate) + " "+("bounded" | translate)}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isGeoBounded"
                      [(ngModel)]="stageAction.isGeoBounded" id="exampleRadios3" [value]="true">
                    <label for="exampleRadios3">
                      Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isGeoBounded"
                      [(ngModel)]="stageAction.isGeoBounded" [value]="false" id="exampleRadios4" checked>
                    <label for="exampleRadios4">
                      No
                    </label>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required">{{("action_mapping" | translate)}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isNormal"
                      [(ngModel)]="stageAction.actionMapping" id="exampleRadios3" [value]="true">
                    <label for="exampleRadios3">
                      OnApprove
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isError"
                      [(ngModel)]="stageAction.actionMapping" [value]="false" id="exampleRadios4" checked>
                    <label for="exampleRadios4">
                      OnReject
                    </label>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required">Is Mandatory</label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isMandatory"
                      [(ngModel)]="stageAction.isMandatory" id="exampleRadios5" [value]="true" checked>
                    <label for="exampleRadios5">
                      Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isMandatory"
                      [(ngModel)]="stageAction.isMandatory" [value]="false" id="exampleRadios6">
                    <label for="exampleRadios6">
                      No
                    </label>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required">Is Visible</label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="Isvisible"
                      [(ngModel)]="stageAction.isVisible" id="exampleRadios7" [value]="true" checked>
                    <label for="exampleRadios7">
                      Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="Isvisible"
                      [(ngModel)]="stageAction.isVisible" [value]="false" id="exampleRadios8">
                    <label for="exampleRadios8">
                      No
                    </label>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required">Is Editable</label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="IsEditable"
                      [(ngModel)]="stageAction.isEditable" id="IsEditable_Yes" [value]="true" checked>
                    <label for="IsEditable_Yes">
                      Yes
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="IsEditable"
                      [(ngModel)]="stageAction.isEditable" [value]="false" id="IsEditable_No">
                    <label for="IsEditable_No">
                      No
                    </label>
                  </div>
                </div>

                <div *ngIf="!stageAction.isVisible" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{ "Select Methods" | translate }}</label>
                  <ps-auto-suggestion (onSelected)="selectedMethod($event)" class="required" [data]="methodsList"
                  display="methodName" placeholder="Choose Method" [preSelected]="{methodName:methodName}">
                </ps-auto-suggestion>
                <div class="error" *ngIf="methodNameValidator && methodNameValidator.error">
                  <span>{{methodNameValidator.msg}} </span>
                </div>
                </div>
              </div>





              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{("back" | translate)}} </button>
                  <button class="btn btn-warning" (click)="resetStageAction()"> {{("reset" | translate)}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{buttonName | translate}} </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{("action_list" | translate)}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{("s.no." | translate)}}</th>
                    <th>{{("action_name" | translate)}}</th>
                    <th>{{("action_scope" | translate)}}</th>
                    <th>{{("process" | translate)}}</th>
                    <th>{{("status" | translate)}}</th>
                    <th>{{("action_type" | translate)}}</th>
                    <th>{{("action_mapping" | translate)}}</th>
                    <th class="action">{{("action" | translate)}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of responseData let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.actionName}}</td>
                    <td>{{row.processId==-1?'Global':'Local'}}</td>
                    <td>{{row.processId==null?'All Process':row.processName}}</td>
                    <td>{{row.actionStatus}}</td>
                    <td>{{row.actionType == 1.0 ? 'Manual' : 'Auto' }}</td>
                    <td>{{row.actionMapping == 1.0 ? 'On Approve' : 'On Reject'}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewStageAction(row)" *ngIf="userService.permissionMap.get('view_stage_actions_access')==true"> {{("view" | translate)}} </button>
                        <button class="btn btn-success" (click)="editStageAction(row)" *ngIf="userService.permissionMap.get('edit_stage_actions_access')==true && (row.processId != -1 || role.user_role == 'AXESTRACK')"> {{("edit" | translate)}} </button>
                        <button class="btn btn-danger" (click)="confirmDeleteAction(row)" *ngIf="userService.permissionMap.get('delete_stage_actions_access')==true && (row.processId != -1 || role.user_role == 'AXESTRACK')"> {{("delete" | translate)}} </button>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

import { SmartTableComponent } from './smart-table/smart-table.component';
import { NgDateTimePickerComponent } from './ng-date-time-picker/ng-date-time-picker.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutoSuggestionComponent } from './auto-suggestion/auto-suggestion.component';
import { GoogleMapAutocompleteComponent } from './google-map-autocomplete/google-map-autocomplete.component';
import { CustomDragScrollDirective } from './drag-scroll/drag-scroll.component';
import { CommonService } from '../@core/services/common.service';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { EllipsisViewDirective } from './ellipsis-view.directive';
import { CustomDatatableComponent } from '../@core/components/custom-table/tables/custom-datatable/custom-datatable.component';


@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule,],
    exports: [
        CommonModule,
        FormsModule,
        AutoSuggestionComponent,
        NgDateTimePickerComponent,
        GoogleMapAutocompleteComponent,
        SmartTableComponent,
        CustomDragScrollDirective,
        AutoSuggestionComponent,
        TimePickerComponent,
        EllipsisViewDirective

    ],
    declarations: [
        AutoSuggestionComponent, NgDateTimePickerComponent, GoogleMapAutocompleteComponent, SmartTableComponent, CustomDragScrollDirective, TimePickerComponent,
        EllipsisViewDirective
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class DirectiveModule { }

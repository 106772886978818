
export const STATUS_CARD = [
    {
        cardName: 'Total Visits',
        cardIcon: 'assets/images/total_visitor.png',
        cardKey: 'total_visitor',
        cardStatus: 'Total'
    },
    {
        cardName: 'Pending Visits',
        cardIcon: 'assets/images/pending_visitor.png',
        cardKey: 'pending_visitor',
        cardStatus: 'Pending'

    },
    {
        cardName: 'Rejected Visitor',
        cardIcon: 'assets/images/rejected_visitor.png',
        cardKey: 'rejected_visitors',
        cardStatus: 'Rejected'

    },
    {
        cardName: 'Restricted Zone',
        cardIcon: 'assets/images/restricted_zone.png',
        cardKey: 'restricted_visitors',
        cardStatus: 'Restricted'

    },
    {
        cardName: 'Overstay Visitor',
        cardIcon: 'assets/images/overtime_visitor.png',
        cardKey: 'overtat_visitor',
        cardStatus: 'Overtat'

    },
    {
        cardName: 'Inside Visitor',
        cardIcon: 'assets/images/completed_visitor.png',
        cardKey: 'inside_visitors',
        cardStatus: 'Inside'

    }
]

export const StaticChartData={
    backgroundColors: [
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(255, 159, 64, 0.5)',
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(255, 159, 64, 0.5)',
        'rgba(119, 65, 215, 0.5)',
        'rgba(51, 187, 185, 0.5)',
        'rgba(171, 136, 0, 0.5)',
        'rgba(195, 57, 121, 0.5)',
        'rgba(55, 135, 55, 0.5)',
        'rgba(201, 203, 207, 0.5)',
        'rgba(255, 192, 203, 0.5)',
        'rgba(255, 165, 0, 0.5)',
        'rgba(64, 224, 208, 0.5)',
        'rgba(60, 179, 113, 0.5)',
        'rgba(123, 104, 238, 0.5)',
        'rgba(210, 105, 30, 0.5)',
        'rgba(72, 61, 139, 0.5)',
        'rgba(255, 215, 0, 0.5)',
        'rgba(199, 21, 133, 0.5)',
        'rgba(34, 139, 34, 0.5)',
        'rgba(70, 130, 180, 0.5)',
        'rgba(139, 69, 19, 0.5)'
      ],
      borderColors: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(119, 65, 215, 1)',
        'rgba(51, 187, 185, 1)',
        'rgba(171, 136, 0, 1)',
        'rgba(195, 57, 121, 1)',
        'rgba(55, 135, 55, 1)',
        'rgba(201, 203, 207, 1)',
        'rgba(255, 192, 203, 1)',
        'rgba(255, 165, 0, 1)',
        'rgba(64, 224, 208, 1)',
        'rgba(60, 179, 113, 1)',
        'rgba(123, 104, 238, 1)',
        'rgba(210, 105, 30, 1)',
        'rgba(72, 61, 139, 1)',
        'rgba(255, 215, 0, 1)',
        'rgba(199, 21, 133, 1)',
        'rgba(34, 139, 34, 1)',
        'rgba(70, 130, 180, 1)',
        'rgba(139, 69, 19, 1)'
      ],
      options:{
        scales: {
            x: {
                ticks: {
                    autoSkip: false,
                    maxRotation: 45,
                    minRotation: 0,
                    font: {
                        size: 12
                    }
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1, // Step size of 1 on the Y-axis
                    font: {
                        size: 12
                    },
                    padding: 10
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false, // Allow chart to resize with its container
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 14
                    }
                }
            },
            tooltip: {
                enabled: true
            }
        },
        hover: {
            mode: 'index',
            intersect: false, // Avoid bar scaling on hover
        },
        categoryPercentage: 0.5, // Space between bars in the same category
        barPercentage: 0.5 // Space between different categories of bars
      }
}

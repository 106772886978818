<div class="card">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{'Asset Linking'|customTranslate}}
      </h3>
    </div>
    <div class="button-class">
      <button type="button" *ngIf=" common.loading==false" class="btn btn-success"
      style="float:right;" data-toggle="modal" data-target="#exampleModal"
      (click)="assetStatus='CHILD';getParentAssets()" style="margin-right: 4px;">
      + {{(assetStatus=='CHILD'? 'Add Parent Linking' : 'Update Parent Linking')|customTranslate}}
    </button>
      <button type="button" class="btn btn-success" style="float:right;"
        *ngIf="common.loading==false" data-toggle="modal" data-target="#exampleModal"
        (click)="assetStatus='PARENT';getChildAssets()">
        + {{(assetStatus=='PARENT'? 'Add Child Linking' : 'Update Child Linking')|customTranslate}}
      </button>
    </div>
  </div>

  <div class="card-body">
    <div class="message" *ngIf="currentAsset==null && common.loading==false">
      <img src="assets/images/icons8-nothing-found-80.png">
      <span>{{'No Asset Linking exists!'|customTranslate}}</span>
    </div>
    <table class="custom-table" *ngIf="currentAsset=='PARENT' || currentAsset=='CHILD'">
      <thead>
        <tr>
          <th>{{"Parent Asset" | customTranslate}}</th>
          <th>{{"Child Assets" | customTranslate}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td *ngIf="parentAssetName!=null">{{parentAssetName}}</td>
          <td>
            <div *ngFor="let asset of childAssetList"><span class="pill"
                [ngClass]="{'bg-yellow-200 text-yellow-800':asset.asset_id==assetId,'bg-blue-200 text-blue-800':asset.asset_id!=assetId}">{{asset.asset_name}}
                <span *ngIf="asset.asset_id==assetId" style="padding:2px">(current)</span> </span></div>
          </td>
          <td>
            <button type="button" class="btn-child" *ngIf="currentAsset=='PARENT'"
              (click)="childAssets.length=0;assetStatus='PARENT';saveMapping()">
              <i class="fas fa-unlink"></i>
              <span>{{'Unlink Child Assets'|customTranslate}}</span>
            </button>
            <button type="button" class="btn-child" *ngIf="currentAsset=='CHILD'"
              (click)="assetStatus='CHILD';pAssetId=null;saveMapping()">
              <i class="fas fa-unlink"></i>
              <span>{{'Unlink Parent Asset'|customTranslate}}</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 130%">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{'Add Asset Linking'|customTranslate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetMapping()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="form-group col-xl-12 col-lg-12 col-sm-12" *ngIf="assetStatus=='CHILD' || currentAsset=='CHILD'">
            <label for="my-input" class="required">{{"Choose Parent Asset" | customTranslate}} </label>
            <ps-auto-suggestion (onSelected)="selectedParent($event)" [data]="parentAssetData" display="asset_name"
              placeholder="Choose Parent asset" [preSelected]="{asset_name:parentAssetName}">
            </ps-auto-suggestion>
            <div class="error" *ngIf="parentAssetValidator && parentAssetValidator.error">
              <span>{{parentAssetValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-xl-12 col-lg-12 col-sm-12" *ngIf="currentAsset=='PARENT' || assetStatus=='PARENT'">
            <div class="field-selection">
              <label for="my-input" class="required">{{"Select Child Assets" | customTranslate}} </label>
              <div *ngFor="let value of childAssets; let i=index" class="mb-2">
                <div class="row">
                  <div class="col-md-10">
                    <ps-auto-suggestion (onSelected)="selectedChildAsset($event,i)" [data]="childAssetData"
                      display="asset_name" placeholder="Choose Child Asset"
                      [preSelected]="{asset_name:value.asset_name}">
                    </ps-auto-suggestion>
                    <div class="error" *ngIf="labelValidator[i] && labelValidator[i].error">
                      <span>{{ labelValidator[i]?.msg }}</span>
                    </div>
                    <div class="error" *ngIf="parentLinkingValidator[i]!=null">
                      <!--if child asset is already linked with some other asset-->
                      <span>{{parentLinkingValidator[i]}}</span>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="plus_minus_icon">
                      <span class="input-group-text remove-field" id="my-addon"
                        *ngIf="(currentAsset==null && i!=0)||currentAsset!=null"
                        (click)="childAssets.splice(i,1);labelValidator.splice(i,1);childAssets.length==0?addValues():null">
                        <i class="fa fa-trash remove"></i>
                      </span>
                      <span class="input-group-text add-field" id="my-addon" *ngIf="i == childAssets.length-1"
                        (click)="addValues()">
                        <i class="fa fa-plus add"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          (click)="resetMapping()">{{'close'|customTranslate}}</button>
        <button type="button" class="btn btn-primary" (click)="formValidator()">{{'save'|customTranslate}}</button>
      </div>
    </div>
  </div>
</div>
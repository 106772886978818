<div class="card main-card" *ngIf="userService.permissionMap.get('live_dashboard_access')==true">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{"process_dashboard" | customTranslate}} - <span>{{tmgdashboard.currentProcess}}</span>
      </h3>
    </div>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="card success  live-card" #livesnapshot>
          <smart-header DataSet="live" [title]="'Live Snapshot -24 hours' | customTranslate"
            (onDetailView)="gettripstatusDetail(livesnapshot)">
          </smart-header>
          <div class="card-body">
            <!-- <ng-smart-table [data]="tripstatus">
            </ng-smart-table> -->
          </div>
        </div>
      </div>


     <div class="col-md-6">
       <div class="card warning live-card" #worsttrips>
         <smart-header DataSet="live" [title]="'Worst 3 trips - TAT wise (7 Days)' | customTranslate"
           (onDetailView)="getworstTripDetail(worsttrips)">
         </smart-header>
         <div class="card-body">
           <!-- <ng-smart-table [data]="worsttrip">
           </ng-smart-table> -->
         </div>
       </div>
     </div>

    </div>
  </div>

</div>

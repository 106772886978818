import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private currentPermission: any = 'default';
  notifications: any = [];
  notificationCount: any = 0;
  webToken = null;
  newNotification = new Subject();
  notification = new Subject();

  constructor(
    public angularFireMessaging: AngularFireMessaging,
    private baseService: ApiService,
    private alertService: AlertService
  ) {}

  handleForeGroundMessage() {
    this.angularFireMessaging.onMessage((newMessage) => {
      // console.log('newMessage: ', newMessage);
      this.notifications.push(newMessage.data);
      this.notificationCount++;
      this.newNotification.next();
    });
  }

  showNotification(notification) {
    this.notification.next(notification);
  }

  isLoggedin() {
    return localStorage.getItem('USER_TOKEN') != null;
  }

  askPermission(force: boolean = false) {
    this.currentPermission =
      localStorage.getItem('cpl-notification-permission') ?? null;
    console.log("Fetching Notifications")
    if ('serviceWorker' in navigator && 'Notification' in window) {
      console.log('this.currentPermission: ', this.currentPermission);
      if (this.currentPermission != Notification.permission) {
        this.currentPermission = null;
      }
      if ('Notification' in window) {
        if (
          (Notification.permission != 'granted' &&
            this.currentPermission == null) ||
          force
        ) {
          if (Notification.permission == 'denied') {
            this.alertService.error(
              'Notification Blocked! You have to provide notification permission'
            );
          } else {
            Notification.requestPermission().then((permission) => {
              localStorage.setItem('cpl-notification-permission', permission);
              if (permission == 'granted') {
                window.location.reload();
              }
            });
          }
        } else {
          if (this.currentPermission == null) {
            console.log('this.currentPermission: ', this.currentPermission);
            this.currentPermission = 'granted';
            localStorage.setItem('cpl-notification-permission', 'granted');
            //Requesting Firebase Permission
          }
          this.requestFirebasePermission();
          this.handleForeGroundMessage();
        }
      }
    }
  }
  requestFirebasePermission() {
    if (Notification && 'Notification' in window) {
      //Register Notification Service Worker
      navigator?.serviceWorker
        ?.register('./firebase-messaging-sw.js')
        .then((registration) => {
          this.angularFireMessaging.useServiceWorker(registration);

          //Request Firebase token for Notifications
          this.angularFireMessaging.requestToken.subscribe(
            (token) => {
              console.log('token: ', token);
              this.webToken = token;
              localStorage.setItem('cpl_device_token', token);
              this.updateWebToken();
            },
            (err) => {}
          );
          //Attach Message Listener
          this.messageListener(registration);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private messageListener(registration: ServiceWorkerRegistration) {
    let fcmChannel = new BroadcastChannel('fcm-channel');
    fcmChannel.onmessage = (message) => {
      console.log('message: ', message);
      if (this.isLoggedin()) {
        //Reading RoomData from notification
        //New Message
        this.notifications.push(message.data.data);
        this.newNotification.next();
        //Operational Message
        registration.showNotification(message.data.data.title, {
          body: message.data.data.message,
          // requireInteraction: true,
          icon: 'assets/Prime chat logo.png',
        });

        this.notificationCount++;
      }
    };
  }

  getCurrentPermission() {
    this.currentPermission =
      localStorage.getItem('cpl-notification-permission') ?? null;
    if (Notification && 'Notification' in window) {
      if (this.currentPermission != Notification.permission) {
        this.currentPermission = null;
      }
    }
    return this.currentPermission;
  }

  getNotifications() {
    return {
      notifications: this.notifications,
      count: this.notificationCount,
    };
  }

  setNotifications() {
    this.notificationCount = 0;
    this.notifications = [];
  }

  updateWebToken(setNull: boolean = false) {
    if (this.isLoggedin()) {
      let params = {
        token: setNull ? null : this.webToken,
      };
      this.baseService.post('navbar/update_device_token', params).subscribe(
        (res) => {},
        (err) => {}
      );
    }
  }
}

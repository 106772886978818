import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class StageTypeService extends ApiService{

  saveStageType(data: any): Observable<any> {
    let apiSubUrl = "stage/save_stage_type";

    return super.post(apiSubUrl, data);
  }
  getAllStageType(): Observable<any> {
    let apiSubUrl = "stage/list_stage_type";

    return super.get(apiSubUrl);
  }

}

import { ApiService } from 'src/app/@core/services/api.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { AssetCategoryServiceService } from './asset-category-service.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { CompanyService } from '../companies/companies.service';
import { PlantService } from '../plant/plant.service';
import { UserService } from 'src/app/@core/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { Subject } from 'rxjs';
import { TableService } from 'src/app/@core/services/table.service';
import { DataTableDirective } from 'angular-datatables';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import {
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AssetCategory, CompanyData, PlantData } from 'src/app/dataType/assetInterface';
import { validator } from 'src/app/dataType/commonInterface';

@Component({
  selector: 'app-asset-category',
  templateUrl: './asset-category.component.html',
  styleUrls: ['./asset-category.component.scss'],
})
export class AssetCategoryComponent implements OnInit {
  @ViewChild('myInput') myInputVariable: ElementRef;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'ASSET CATEGORY LIST');



  plantLevelRoles = ['AXESTRACKUSER' , 'COMPANYUSER' , 'PLANTADMIN', 'PLANTUSER'];
  assetCategory = {
    assetCategoryId: null,
    assetCategoryName: null,
    assetCategoryDesc: null,
    assetCategoryStatus: 'ACTIVE',
    assetImage: null,
    companyId: null,
    plantId: null,
    deleteFlag: false,
  };
  company: CompanyData[] = [];
  plantData: PlantData[] = [];
  companyName: string=null;
  plantName: string =null;
  assetCategoryNameValidator: validator;
  responseData: AssetCategory[] =[];
  fileEvent: any = [];


  constructor(
    public api: ApiService,
    public common: CommonService,
    private userService: UserService,
    public breadcrumbService: BreadcrumbService,
    private assetCategoryService: AssetCategoryServiceService,
    private modalService: NgbModal,
    private alertService: AlertService,
    public role: RoleService,
    private companyService: CompanyService,
    private plantService: PlantService,
    public table: TableService
  ) {}

  ngOnInit(): void {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'assets', url: null },
      { name: 'Category', url: '/user/asset-category' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getAssetCategories();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  resetWorkType(): void {
    this.assetCategory.assetCategoryName = null;
    this.assetCategory.assetCategoryDesc = null;
    this.assetCategory.assetCategoryStatus = 'ACTIVE';
    this.assetCategory.deleteFlag = false;
    this.assetCategory.companyId = null;
    this.assetCategory.assetImage = null;
    this.companyName = null;
    this.plantName = null;
    this.assetCategory.plantId = null;
    (this.assetCategory.assetCategoryId = null),
      (this.assetCategoryNameValidator = null);
    if (this.role.user_role == 'AXESTRACK') {
      this.plantData = [];
    }
  }

  selectedCompany(event: any) {
    this.assetCategory.companyId = event.companyId;
    this.companyName = event.companyName;
    this.plantName = null;
    this.assetCategory.plantId = null;
    this.getPlantById(event.companyId);
  }

  selectedPlant(event: any) {
    this.assetCategory.plantId = event.plantId;
    this.plantName = event.plantName;
  }

  getAssetCategories() {
    this.common.loading=true;
    let params = {
      allCategories: true,
    };
    this.assetCategoryService.getAssetCategory(params).subscribe(
      (res: any) => {
      if (res.status) {
        this.common.loading=false
        this.responseData = res.data;
        this.renderTable();
      }
    },
      (err:any)=>{
        console.log('err: ', err);
        this.common.loading=false;
      }
  );
  }

  getCompany(){
    this.common.loading=true;
    let params={
      all_company:false
    }
    this.api.get('company/all_company',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.company = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
      );
    }


  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  view(row?: any) {
    let obj = {
      'Asset Category Name': row.asset_category_name,
      'Asset Category Description': row.asset_category_description,
      'Company Name': row.company_name,
      'Plant Name': row.plant_name,
    };

    this.common.params = {
      details: [obj],
      title: 'Asset Category Details',
    };
    this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Category',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row.asset_category_name +
        '  ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteCategory(row);
      }
    });
  }

  deleteCategory(row) {
    this.assetCategory.assetCategoryId = row.asset_category_id;
    this.assetCategory.assetCategoryName = row.asset_category_name;
    this.assetCategory.assetCategoryDesc = row.asset_category_description;
    this.assetCategory.assetImage = row.asset_category_image;
    this.assetCategory.assetCategoryStatus = 'DELETED';
    this.assetCategory.companyId = row.company_id;
    this.assetCategory.plantId = row.plant_id;
    this.assetCategory.deleteFlag = true;
    this.saveCategory();
  }

  onSelect(event: any) {
    let frmData = new FormData();
    for (const file of event.target.files) {
      frmData.append('files', file);
      this.fileEvent.inProgress = true;
      this.uploadFile(frmData);
    }
  }

  uploadFile(frmData: any) {
    this.common.loading = true;
    this.api
      .post('functional/upload-file?methodId=' + 17, frmData)
      .subscribe((res: any) => {
        this.common.loading = false;
        switch (res.data.type) {
          case HttpEventType.UploadProgress:
            this.fileEvent.progress = Math.round(
              (res.data.loaded * 100) / res.data.total
            );
            break;
          case HttpEventType.Response:
            document.querySelector('.file_class').setAttribute('value', '');
            return res.data;
        }
        res.data.forEach((e) => {
          this.assetCategory.assetImage = e.fileUrl;
        });
      });
    catchError((error: HttpErrorResponse) => {
      this.fileEvent.inProgress = false;
      this.common.loading = false;
      return `${this.fileEvent.data.name} upload failed.`;
    });
    // this.assetCategory.assetImage="assets/images/good-afternoon.png";
    this.myInputVariable.nativeElement.value = '';
  }

  edit(row: any) {
    this.assetCategory.assetCategoryId = row.asset_category_id;
    this.assetCategory.assetCategoryName = row.asset_category_name;
    this.assetCategory.assetCategoryDesc = row.asset_category_description;
    this.assetCategory.assetCategoryStatus = row.status_name;
    this.assetCategory.assetImage = row.asset_category_image;
    this.assetCategory.companyId = row.company_id;
    this.assetCategory.plantId = row.plant_id;
    if (!(row.company_id == 1 && row.plant_id == 1)) {
      this.plantName = row.plant_name;
      this.companyName = row.company_name;
    } else {
      this.plantName = null;
      this.companyName = null;
    }
    this.role.user_role == 'AXESTRACK'
      ? this.getPlantById(row.company_id)
      : this.role.user_role == 'COMPANYADMIN'
      ? this.getPlantById(this.userService._loggedInUser.companyId)
      : null;
    this.assetCategory.deleteFlag = false;
  }

  formValidator() {
    this.assetCategoryNameValidator = this.common.nullValidator(
      this.assetCategory.assetCategoryName != null &&
        this.assetCategory.assetCategoryName.trim(),
      'Asset Category Name Mandatory'
    );
    if (!this.assetCategoryNameValidator.error) {
      this.saveCategory();
    }
  }

  saveCategory() {
    this.common.loading = true;
    this.assetCategoryService.saveAssetCategory(this.assetCategory).subscribe(
      (response) => {
        if (response.status) {
          this.common.loading = false;
          this.alertService.success(response.message);
          this.resetWorkType();
          this.getAssetCategories();
        } else {
          this.alertService.error(response.message);
        }
      },
       (error:any) => {
        console.log('error: ', error);
        this.common.loading = false;
      }
    );
  }

  imageName(url: any) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    return arrays[arrays.length - 1];
  }
}

import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import {Component,OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})

export class HelpComponent implements OnInit {
  help: any[] = []
  menu= [];
  colors = [{
  step_1:'red',
  step_2:'green',
  step_3:'blue'
  }];

  className:number = 1;
  constructor(
    public breadcrumbService:BreadcrumbService,
    public router: Router,
    public common:CommonService,
    public api:ApiService
    ){

    /** breadcums implementation start*/
    let breadcumbs = [
      { name: "Home", url: "/user/dashboard" },
      { name: "Help", url: '/user/help' },
      // { name: 'Zone Dashboard', link: '/user/zone-dashboard' },
    ]
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/

  }


  setclass(i:number){
  let elemvalue = 10;
  this.className = i%10;
  return this.className;
    }

  // getHelp() {
  //   this.help = help;
  // }

  ngOnInit(): void {
    this.getMenu();
    //  this.getHelp();
  }

  getMenu(){
    this.common.loading = true;
    this.api.get("component/get-menu")
    .subscribe((res:any)=>
    {
      if(res.status){
        this.menu=res.data || [];
        this.common.usermenu = this.menu;
      }
      this.common.loading = false;
    },
    (err: any) => {
      console.error('Error: ', err);
       this.common.loading = false;
    })
  }

  openHelpSubMenuPage() {
    this.router.navigate(['user/help-submenu']);
  }
}

import { TableService } from '../../@core/services/table.service';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { StageService } from '../stage/stage.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
@Component({
  selector: 'app-communication-type',
  templateUrl: './communication-type.component.html',
  styleUrls: ['./communication-type.component.scss'],
})
export class CommunicationTypeComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'communication type table');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getData();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  communicationType = {
    communicationTypeId: null,
    communicationTypeName: null,
    communicationTypeDesc: null,
    communicationTypeStatus: 'ACTIVE',
  };

  processName: null;
  stageName: null;

  title = 'Add Communication Type';
  btn = 'save';
  responseData: any[] = [];
  processData = [];
  stageData = [];

  constructor(
    private api: ApiService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public stageService: StageService,
    public table: TableService,
    public userService: UserService,
    public alertService: AlertService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Communication', url: null },
      { name: 'Communication Type', url: '/user/communication-type' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    this.getProcess();

    // this.getData();
  }

  ngOnInit(): void {}
  ngViewInit() {}

  getProcess() {
    this.common.loading = true;
    this.api
      .get('process/get-all-process?processActive=true', null) // list of active processes only
      .subscribe(
        (res: any) => {
          this.common.loading = false;

          this.processData = res.data;
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
        }
      );
  }

  getStageById(id?: any) {
    this.common.loading = true;

    let params = {
      id: id,
    };
    this.stageService.getStageById(params).subscribe(
      (res: any) => {
        this.common.loading = false;

        this.stageData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getData() {
    this.common.loading = true;
    this.api.get('communication/list_communicationType', null).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.responseData = res.data;
        this.renderTable();
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  saveData() {
    this.common.loading = true;
    let params = {
      communicationTypeId: this.communicationType.communicationTypeId,
      communicationTypeName: this.communicationType.communicationTypeName,
      communicationTypeDesc: this.communicationType.communicationTypeDesc,
      communicationTypeStatus: this.communicationType.communicationTypeStatus,
    };
    let apiUrl = 'communication/save_communicationType';

    this.api.post(apiUrl, params, null).subscribe(
      (res) => {
        this.common.loading = false;
        this.alertService.info('Data Saved Successfully');
        this.getData();
        this.resetDetail();
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  resetDetail() {
    this.communicationType = {
      communicationTypeId: null,
      communicationTypeName: null,
      communicationTypeDesc: null,
      communicationTypeStatus: 'ACTIVE',
      // processId: null,
      // stageId: null,
    };
    this.processName = null;
    this.stageName = null;
    this.btn = 'save';
    this.title = 'Add Communication Type';
    this.communicationTypeNameValidator = null;
    this.statusValidator = null;
    // this.processValidator= null;
    // this.stageValidator= null;
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Communication Type ',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteData(row);
      }
    });
  }

  deleteData(row?: any) {
    this.common.loading = true;
    let params = {
      communicationTypeId: row.communicationTypeId,
      communicationTypeName: row.communicationTypeName,
      communicationTypeDesc: row.communicationTypeDesc,
      communicationTypeStatus: 'DELETED'
    };

    this.api
      .post('communication/save_communicationType', params, null)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.alertService.info('Data Deleted Successfully');
          this.getData();
        },
        (err) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  editRow(row?: any) {
    this.common.gotoTop();
    this.resetDetail();
    this.communicationType.communicationTypeId = row.communicationTypeId;
    this.communicationType.communicationTypeName = row.communicationTypeName;
    this.communicationType.communicationTypeDesc = row.communicationTypeDesc;
    this.communicationType.communicationTypeStatus =row.communicationTypeStatus;
    this.btn = 'update';
    this.title = 'Edit Communication Type';
  }
  viewDetails(row?: any) {
    this.common.params = { details: [row], title: 'View Details' };

    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  communicationTypeNameValidator: any;
  statusValidator: any;
  formValidator() {
    this.communicationTypeNameValidator = this.common.nullValidator(
      this.communicationType.communicationTypeName,
      'Communication Type Name Mandatory'
    );
    this.statusValidator = this.common.nullValidator(
      this.communicationType.communicationTypeStatus,
      'Communication Type Status Mandatory'
    );
    if (
      !this.communicationTypeNameValidator.error &&
      !this.statusValidator.error
    ) {
      this.saveData();
    }
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AlertLogInfoModalComponent } from '../alert-log-info-modal/alert-log-info-modal.component';

@Component({
  selector: 'app-alert-view-details-modal',
  templateUrl: './alert-view-details-modal.component.html',
  styleUrls: ['./alert-view-details-modal.component.scss'],
})
export class AlertViewDetailsModalComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  isdisable = false;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'ViewDetails Table');

  @Input() public refData;

  actionLabels: any[] = [
    {
      tabName: 'active',
      tab_icon: 'check',
      tabBgColor: '#ebeb98',
      tabIconBg: '#a7a708',
    },
    {
      tabName: 'inprogress',
      tab_icon: 'clock-o',
      tabBgColor: '#b9e9fa',
      tabIconBg: '#1da6e0',
    },
    {
      tabName: 'completed',
      tab_icon: 'thumbs-up',
      tabBgColor: '#b0f6b0',
      tabIconBg: '#00bd00',
    },
    {
      tabName: 'inactive',
      tab_icon: 'times-circle',
      tabBgColor: '#cdcdc7',
      tabIconBg: '#4a4a4a',
    },
    {
      tabName: 'cancelled',
      tab_icon: 'times',
      tabBgColor: '#f7d5d5',
      tabIconBg: '#d60000',
    },
  ];

  activeTab: number = 0;
  activeTabName: string = 'active';
  dateWiseAlertDetails: any[] = [];

  constructor(
    public table: TableService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.getAlertDetailByDate('ACTIVE');
    for (const label of this.actionLabels) {
      const status = label.tabName.toUpperCase();
      const statusData = this.refData.data.find(
        (item) => item.status === status
      );
      label.count = statusData ? statusData.count : 0;
    }
  }

  closeModal(res) {
    this.activeModal.close(res);
  }

  getCountByStatus(status: string): number {
    const auditStatus = this.refData.data.find(
      (item: any) => item.status === status
    );
    return auditStatus ? auditStatus.count : 0;
  }

  changeTabs(i: any, tab: any) {
    this.activeTab = i;
    this.activeTabName = tab.tabName;
    this.getAlertDetailByDate(tab.tabName.toUpperCase());
  }

  getAlertDetailByDate(status: any) {
    this.common.loading = true;
    let params = {
      viewDate: this.refData.viewDate,
      plantId: null,
      status: status,
      alertTypeId: this.refData.alertTypeId,
      alertEntity:
        this.refData.alertEntity != null ? this.refData.alertEntity : '',
      alertCondition:
        this.refData.alertCondition != null ? this.refData.alertCondition : '',
    };
    this.api.get('report/get-alert-data-by-date', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.dateWiseAlertDetails = res.data;
      },
      (err: any) => {
        console.error('Error:', err);
        this.common.loading = false;
      }
    );
  }

  openAlertLogInfoModal(item: any) {
    let activeModal = this.modalService.open(AlertLogInfoModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      alertLogId: item.alert_log_id,
      alertTitle: item.title,
      entity: item.map_on,
      condition: item.alert_condition,
    };
  }
}

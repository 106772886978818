import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
@Injectable({
  providedIn: 'root'
})
export class CommunicationService extends ApiService {
  saveCommunicationSetting(data:any) {
    let apiSubUrl = "communication/save-communication-setting";
    return super.post(apiSubUrl, data, null);
  }

    getAllCommunicationSettingData(){
      let apiSubUrl = "communication/get-communication-setting-data";
      return super.get(apiSubUrl,null)
    }

  getCommunicationSetting(){
    let apiSubUrl = "communication/get-all-communication-setting";
    return super.get(apiSubUrl,null);
  }

  getCommunication(){
    let apiSubUrl = "communication/list_communicationType";
    return super.get(apiSubUrl,null);
  }
  getGroup(){
    let apiSubUrl = "group/all-group?plantType=false";
    return super.get(apiSubUrl);
  }

  getUser(){
    let apiSubUrl = "user/all_user";
    return super.get(apiSubUrl);
  }

  getPlant() {
    let apiSubUrl = "plant/all_plants";


  }

  getGroupByPlantId(params:any){
    let apiSubUrl= "group/all-group";
    return super.get(apiSubUrl,params);
  }

}



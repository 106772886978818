import { StageService } from './../stage/stage.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';

@Component({
  selector: 'app-productivity-report',
  templateUrl: './productivity-report.component.html',
  styleUrls: ['./productivity-report.component.scss'],
})
export class ProductivityReportComponent implements OnInit {
  fromDate: any = new Date(new Date().setDate(new Date().getDate() - 7));
  toDate: any = new Date();
  responseData: any = [];
  processId: any = [];
  stageId = [];
  listOfProcess: any = [];
  stageList: any = [];
  userList: any = [];
  userId: any = [];
  tabvalue: any = 'table';
  plantData: any = [];
  plantId: number = null;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'TRIP REPORT LIST');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    private breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public alertService: AlertService,
    public userService: UserService,
    private plantService: PlantService,
    private stage: StageService,
    public role: RoleService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'Productivity Report', link: '/user/productivity-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.getAllProcess();
      this.getUserList();
      this.getAllStage();
      this.getEmpProductivity(1);
    }
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.getAllProcess();
    this.getUserList();
    this.getAllStage();
    this.getEmpProductivity(1);
  }

  selectedProcess(event: any) {
    this.processId = [];
    event.forEach((e) => {
      this.processId.push(e.processId);
    });
    this.getEmpProductivity(0);
    this.getAllStage();
  }

  selectedStage(event: any) {
    this.stageId = [];
    event.forEach((e) => {
      this.stageId.push(e.stageId);
    });
    this.getEmpProductivity(0);
  }

  selectedUser(event: any) {
    this.userId = [];
    event.forEach((e) => {
      this.userId.push(e.userId);
    });
    this.getEmpProductivity(0);
  }

  getUserList() {
    let params = {
      plantId: this.plantId,
    };
    this.api.get('user/all_user', params).subscribe(
      (res: any) => {
        this.userList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.plantId,
    };
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.listOfProcess = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllStage() {
    this.common.loading = true;
    if (this.processId == null) return;
    let params = {
      processIds: this.processId.length == 0 ? null : this.processId,
      plantId: this.plantId,
      processId: -1,
    };
    this.stage.getStageByProcessAndPlant(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.stageList = res.data.map((stage) => {
          stage.stageName = stage.stage_name;
          stage.stageId = stage.stage_id;
          return stage;
        });
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getEmpProductivity(flag: any) {
    let params;
    if (
      this.processId.length == 0 &&
      this.userId.length == 0 &&
      this.stageId.length == 0
    ) {
      if (flag == 1) {
        this.fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
        this.fromDate = this.common.dateFormatter(
          this.fromDate,
          'YYYYMMdd',
          false,
          '-'
        );
        this.toDate = new Date();
        this.toDate = this.common.dateFormatter(
          this.toDate,
          'YYYYMMdd',
          false,
          '-'
        );
      }
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        plantId: this.plantId,
      };
    } else if (
      this.processId.length == 0 &&
      this.userId.length == 0 &&
      this.stageId.length != 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        stageIds: this.stageId,
        plantId: this.plantId,
      };
    } else if (
      this.processId.length == 0 &&
      this.userId.length != 0 &&
      this.stageId.length == 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        userIds: this.userId,
        plantId: this.plantId,
      };
    } else if (
      this.processId.length == 0 &&
      this.userId.length != 0 &&
      this.stageId.length != 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        userIds: this.userId,
        stageIds: this.stageId,
        plantId: this.plantId,
      };
    } else if (
      this.processId.length != 0 &&
      this.userId.length == 0 &&
      this.stageId.length == 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        processIds: this.processId,
        plantId: this.plantId,
      };
    } else if (
      this.processId.length != 0 &&
      this.userId.length == 0 &&
      this.stageId.length != 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        processIds: this.processId,
        stageIds: this.stageId,
        plantId: this.plantId,
      };
    } else if (
      this.processId.length != 0 &&
      this.userId.length != 0 &&
      this.stageId.length == 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        processIds: this.processId,
        userIds: this.userId,
        plantId: this.plantId,
      };
    } else if (
      this.processId.length != 0 &&
      this.userId.length != 0 &&
      this.stageId.length != 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        processIds: this.processId,
        userIds: this.userId,
        stageIds: this.stageId,
        plantId: this.plantId,
      };
    }
    this.common.loading = true;
    this.api.post('report/get-emp-production-details', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.responseData = res.data;
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { WidgetType, XSightsCoreService } from 'x-sights-core';
import { PlantService } from '../plant/plant.service';
import { StageService } from '../stage/stage.service';

@Component({
  selector: 'app-shift-report',
  templateUrl: './shift-report.component.html',
  styleUrls: ['./shift-report.component.scss'],
})
export class ShiftReportComponent implements OnInit {
  tabvalue: any = 'table';

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'SHIFT REPORT LIST');
  graphtype: any;

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  listOfStageReports: any = [];
  processIds: any = [];
  userIds: any = [];
  stageIds: any = [];
  listOfProcess: any = [];
  stageList: any = [];
  userList: any = [];
  fromDate: any = null;
  toDate: any = null;
  empShiftReports: any = [];
  allGraphIds: any = [467, 468, 469];
  processId: number = null;
  plantData: any = [];
  plantId: number = null;

  constructor(
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public alertService: AlertService,
    public userService: UserService,
    private xsights: XSightsCoreService,
    private plantService: PlantService,
    private stage: StageService,
    public role: RoleService
  ) {}

  ngOnInit(): void {
    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.getAllProcess();
      this.getUserList();
      this.getAllStage();
      this.getEmpShiftReport(1);
    }
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.getAllProcess();
    this.getUserList();
    this.getAllStage();
    this.getEmpShiftReport(1);
  }

  selectedProcess(event: any) {
    this.processIds = [];
    event.forEach((e) => {
      this.processIds.push(e.processId);
    });
    this.getEmpShiftReport(0);
  }

  selectedStage(event: any) {
    this.stageIds = [];
    event.forEach((e) => {
      this.stageIds.push(e.stageId);
    });
    this.getEmpShiftReport(0);
  }

  selectedUser(event: any) {
    this.userIds = [];
    event.forEach((e) => {
      this.userIds.push(e.userId);
    });
    this.getEmpShiftReport(0);
  }

  drawGraphs() {
    this.allGraphIds.forEach((graph) => {
      this.api
        .get(
          'graphs/get-graph-by-id',
          {
            graphId: graph,
          },
          'XSIGHT'
        )
        .toPromise()
        .then((res: any) => {
          const structureJ = JSON.parse(res.data[0].structure);
          let data = {
            graphId: 'graph-' + res.data[0].id,
            graphTitle: res.data[0].name,
            rows: structureJ.xAxis,
            columns: structureJ.yAxis,
            graphTypes: structureJ.chartType,
            graphData: this.empShiftReports,
            aggregationFunctions: structureJ.aggreagations,
            filter: structureJ.filter,
            colors: structureJ.colColours,
            customVariable: structureJ.derivedVariables,
            dataFormat: [],
            lastLevelColumns: structureJ.lastLevelData,
          };
          this.xsights.build(WidgetType.GRAPH, data);
        });
    });
  }

  getUserList() {
    this.api.get('user/all_user').subscribe(
      (res: any) => {
        this.userList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  getAllProcess() {
    this.common.loading = true;
    let params = {
      processActive: true,
      plantId: this.plantId,
    };
    this.api.get('process/get-all-process', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.listOfProcess = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllStage() {
    this.common.loading = true;
    let params = {
      plantId: this.plantId,
    };
    this.stage.getStageById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.stageList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getEmpShiftReport(flag: any) {
    let params;
    if (
      this.processIds.length == 0 &&
      this.userIds.length == 0 &&
      this.stageIds.length == 0
    ) {
      if (flag == 1) {
        this.fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
        this.fromDate = this.common.dateFormatter(
          this.fromDate,
          'YYYYMMdd',
          false,
          '-'
        );
        this.toDate = new Date();
        this.toDate = this.common.dateFormatter(
          this.toDate,
          'YYYYMMdd',
          false,
          '-'
        );
      }
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
      };
    } else if (
      this.processIds.length == 0 &&
      this.userIds.length == 0 &&
      this.stageIds.length != 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        stageIds: this.stageIds,
      };
    } else if (
      this.processIds.length == 0 &&
      this.userIds.length != 0 &&
      this.stageIds.length == 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        userIds: this.userIds,
      };
    } else if (
      this.processIds.length == 0 &&
      this.userIds.length != 0 &&
      this.stageIds.length != 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        userIds: this.userIds,
        stageIds: this.stageIds,
      };
    } else if (
      this.processIds.length != 0 &&
      this.userIds.length == 0 &&
      this.stageIds.length == 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        processIds: this.processIds,
      };
    } else if (
      this.processIds.length != 0 &&
      this.userIds.length == 0 &&
      this.stageIds.length != 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        processIds: this.processIds,
        stageIds: this.stageIds,
      };
    } else if (
      this.processIds.length != 0 &&
      this.userIds.length != 0 &&
      this.stageIds.length == 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        processIds: this.processIds,
        userIds: this.userIds,
      };
    } else if (
      this.processIds.length != 0 &&
      this.userIds.length != 0 &&
      this.stageIds.length != 0
    ) {
      params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        processIds: this.processIds,
        userIds: this.userIds,
        stageIds: this.stageIds,
      };
    }

    this.common.loading = true;
    this.api
      .post('report/get-shiftwise-report', params)
      .subscribe((res: any) => {
        this.common.loading = false;
        this.empShiftReports = res.data;
        if (this.tabvalue == 'table') {
          this.renderTable();
        } else {
          this.drawGraphs();
        }(err: any) => {
            console.error('Error:', err);
            this.common.loading = false;
          };
      });
  }
}

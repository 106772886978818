import { TmgService } from '../tmg.service';
import { FormGroup, FormControl } from '@angular/forms';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
} from '@angular/core';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'smart-header',
  templateUrl: './smart-header.component.html',
  styleUrls: ['../tmg-dashboard.component.scss'],
})
export class SmartHeaderComponent implements OnInit {
  fromDate: any;
  ToDate: any;

  btnactiveIndex: number = -1;
  optionactiveindex: number = -1;
  dateactive: boolean = false;

  @Output() onSelected = new EventEmitter();
  @Output() onReset = new EventEmitter();
  @Output() onDetailView = new EventEmitter();

  @Input() data: any;
  @Input() optionmenu: any;
  @Input() title: any;
  @Input() DataSet: any;
  @Input() ParamType: any;

  ResetData: boolean = false;
  options: boolean = false;
  menuoptions: boolean = false;
  btnOptions: boolean = false;
  datePicker: boolean = false;
  monthpicker: boolean = false;
  selectHeader: boolean = false;

  resetView: boolean = false;
  detailbtnView: boolean = false;

  dateRangepicker: boolean = false;
  dateCompareRangepicker: boolean = false;

  campaignOne: FormGroup;
  campaignTwo: FormGroup;

  month1: any;
  month2: any;

  fromDate1: string = '';
  toDate1: string = '';

  toDate2: string = '';
  fromDate2: string = '';
  date1 = new FormControl(moment());
  date2 = new FormControl(moment());

  @ViewChild(MatDatepicker) dp1: any;
  @ViewChild(MatDatepicker) dp2: any;

  // date = new FormControl();

  constructor(public tmg: TmgService) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.campaignOne = new FormGroup({
      start: new FormControl(''),
      end: new FormControl(''),
    });

    this.campaignTwo = new FormGroup({
      start: new FormControl(''),
      end: new FormControl(''),
    });
  }

  monthSelected1(params: any) {
    this.date1.setValue(params);
    this.dp1.close();
  }

  monthSelected2(params: any) {
    this.date2.setValue(params);
    this.dp2.close();
  }

  resetdataValues() {
    if (this.ResetData) {
      this.optionactiveindex = -1;
      this.btnactiveIndex = -1;
      this.dateactive = false;
      this.fromDate = '';
      this.ToDate = '';
      this.fromDate1 = '';
      this.toDate1 = '';
      this.fromDate2 = '';
      this.toDate2 = '';
    }
  }

  resetData() {
    this.ResetData = true;
    this.resetdataValues();
    this.onReset.emit();
  }

  detailView() {
    this.onDetailView.emit();
  }

  startdate(event: MatDatepickerInputEvent<Date>) {
    this.fromDate = event.value;
  }

  endDate(event: MatDatepickerInputEvent<Date>) {
    this.ResetData = false;
    this.ToDate = event.value;
    this.tmg.startdate = this.fromDate;
    this.tmg.EndDate = this.ToDate;

    if (this.tmg.EndDate != null) {
      this.DateExtract('DateRange');
      this.dateactive = true;
      this.btnactiveIndex = -1;
      this.optionactiveindex = -1;
    }
  }

  dateparse(type: any, number: number, index: number) {
    this.ResetData = false;
    this.btnactiveIndex = index;
    this.optionactiveindex = -1;
    this.dateactive = false;
    this.fromDate = '';
    this.ToDate = '';
    this.DateExtract(type, number);
  }

  menudateParse(type: any, number: number, index: number) {
    this.ResetData = false;
    this.fromDate = '';
    this.ToDate = '';
    this.dateactive = false;
    this.btnactiveIndex = -1;
    this.optionactiveindex = index;
    this.DateExtract(type, number);
  }

  DateExtract(type: any, number?: number) {
    let responseObject = this.tmg.DateResponse(type, number);
    this.onSelected.emit(responseObject);
  }

  DateCompareExtract(
    type: any,
    fromDate1: any,
    toDate1: any,
    fromDate2: any,
    toDate2: any
  ) {
    let responseObject = this.tmg.DateResponseCompare(
      type,
      fromDate1,
      toDate1,
      fromDate2,
      toDate2
    );
    this.onSelected.emit(responseObject);
  }

  fromDate1Change(event: any) {
    this.fromDate1 = event.value;
  }

  toDate1Change(event: any) {
    this.toDate1 = event.value;

    // if(this.toDate1 != null && this.toDate2 != null){
    //  this.DateCompareExtract('datecompare',this.fromDate1,this.toDate1,this.fromDate2,this.toDate2);
    // }
  }

  fromDate2Change(event: any) {
    this.fromDate2 = event.value;
  }

  toDate2Change(event: any) {
    this.toDate2 = event.value;

    if (this.toDate1 != null && this.toDate2 != null) {
      this.DateCompareExtract(
        'datecompare',
        this.fromDate1,
        this.toDate1,
        this.fromDate2,
        this.toDate2
      );
    }
  }

  renderdatepicker() {
    this.menuoptions = this.optionmenu == null ? true : false;
    this.btnOptions = this.data == null ? false : true;

    if (this.DataSet == 'live') {
      this.detailbtnView = true;
      this.resetView = false;
      this.selectHeader = false;
    }

    if (this.DataSet == 'chart') {
      this.detailbtnView = false;
      this.resetView = true;
      this.selectHeader = true;
    }

    if (this.DataSet == 'table') {
      this.detailbtnView = true;
      this.resetView = true;
      this.selectHeader = true;
    }

    if (this.DataSet == null) {
      this.detailbtnView = true;
      this.resetView = true;
      this.selectHeader = true;
    }

    if (this.ParamType == 'compare') {
      this.dateCompareRangepicker = true;
      this.dateRangepicker = false;
      this.monthpicker = false;
    }

    if (this.ParamType == 'month-picker') {
      this.dateCompareRangepicker = false;
      this.dateRangepicker = false;
      this.monthpicker = true;
    }

    if (this.ParamType == null) {
      this.dateCompareRangepicker = false;
      this.dateRangepicker = true;
      this.monthpicker = false;
    }
  }

  ngOnInit(): void {
    this.renderdatepicker();
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body stageType-form">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Zone Slot Mapping' | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"process->stage" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="process" display="order_name"
                    placeholder="Choose Process" [preSelected]="{order_name:selectedOrderName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processStageValidator && processStageValidator.error">
                    <span>{{processStageValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"Zone" | translate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedZone($event)" [data]="zones" display="zoneName"
                    placeholder="Choose Zone" [preSelected]="{zoneName:selectedZoneName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="zoneValidator && zoneValidator.error">
                    <span>{{zoneValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"starting_slot_number" | translate}} </label>
                  <input type="number" min=0 name="from" class="form-control" placeholder="Enter Starting Slot Number"
                    [(ngModel)]="fromHere">
                  <div class="error" *ngIf="startingSlotNoValidator && startingSlotNoValidator.error">
                    <span>{{startingSlotNoValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" min=0 class="required"> {{"ending_slot_number" | translate}} </label>
                  <input type="number" name="to" class="form-control" placeholder="Enter Ending Slot Number"
                    [(ngModel)]="toHere">
                  <div class="error" *ngIf="endingSlotNoValidator && endingSlotNoValidator.error">
                    <span>{{endingSlotNoValidator.msg}} </span>
                  </div>
                </div>
              </div>


              <div class="form-group action-block">
                <div class="btn-block">
                  <div style="display:flex">
                    <label class="required-label">*</label>
                    <div class="note">
                      <div><label>{{"note"|translate}}</label></div>
                      <div class="note_text">{{"zone_slot_mapping_note"|translate}}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div>
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    translate}}</button>
                  <button class="btn btn-warning" (click)="reset()">{{"reset" | translate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | translate}} </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"zone_slot_mapping_list" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"serial_no" | translate}}</th>
                    <th>{{"zone_name" | translate}}</th>
                    <th>{{"slots_range" | translate}}</th>
                    <th>{{"process_name" | translate}}</th>
                    <th>{{"stage_name" | translate}}</th>
                    <th class="action">{{"action" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of zoneSlotMappings let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.zone_name}}</td>
                    <td>{{row.stage_destination_codes}}</td>
                    <td>{{row.process_name}}</td>
                    <td>{{row.stage_name}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="view(row)">{{"view" | translate}}</button>
                        <button class="btn btn-success" (click)="edit(row)">{{"edit" | translate}}</button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)">{{"delete" | translate}}</button>
                      </div>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</div>


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { ActionFormComponent } from 'src/app/modals/action-form/action-form.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { PlantService } from '../plant/plant.service';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { DockBookingModalComponent } from '../dock-booking-modal/dock-booking-modal.component';
import lodash from 'lodash';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-dock-appointment',
  templateUrl: './dock-appointment.component.html',
  styleUrls: ['./dock-appointment.component.scss']
})
export class DockAppointmentComponent implements OnInit {

  activeTab: number = 0;
  plantId: number = null;
  dockData: any = [{}];
  dockCount: any = {
    late: 0,
    delay: 0,
    total: 0,
  };
  dates: any = [];
  currentTime: number = 0;
  activeDate: string;
  searchValue: string = '';
  dockFilteredData: any = [{}];
  searchTrue: boolean = false;
  processList: any = [];
  selProcessId: number;
  dockList: any[] = [];
  activeView: string = 'Current';
  sliceIndex: number = -1;
  processName: string = '';
  urlAuthKey: string = '';
  plantList: any[] = [];
  selectedPlant: any;
  productList:any=[];
  productName:any;

  mapping={
    productId:null,
    destinationId:null
  }
  selectedProductId:any;
  selectedProductName: string = '';
  currentDateTime: Date;
  groupedSlotDataByQueueIndex:any
  allowedFutureBookingDays:number = 0;


  constructor(
    public breadcrumbService: BreadcrumbService,
    private api: ApiService,
    private modalService: NgbModal,
    public common: CommonService,
    private activatedRoute: ActivatedRoute,
    public plantService: PlantService,
    public role: RoleService,
    public alert:AlertService,
    public userService : UserService
  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
    this.getProductList();
  }

  ngOnInit(): void {
    this.getAllowedFutureBookingDays();
    this.role.user_role == 'COMPANYADMIN'
      ? this.getPlantById()
      : this.getProductList();
  }

  getDates(){
    this.currentDateTime = new Date();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.urlAuthKey = params['authkey'] || null;
    });
    let fromDate = new Date(new Date().setDate(new Date().getDate()));
    let toDate = new Date(new Date().setDate(new Date().getDate() + this.allowedFutureBookingDays));
    while (fromDate.getTime() <= toDate.getTime()) {
      this.dates.push(_.clone(fromDate));
      fromDate.setDate(fromDate.getDate() + 1);
    }
  }

  getAllowedFutureBookingDays(){
    this.common.loading = true;
    this.api.get('plant/get-plant-setting-by-plant-id?plantId=' + this.userService._loggedInUser.plantId)
      .subscribe(
        (response: any) => {
          this.common.loading = false;
          const res = response.data;
          this.allowedFutureBookingDays = res[0].allowedFutureBookingBefore;
          this.getDates();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
      return this.allowedFutureBookingDays;
    }

  isTimeValid(endTime: string): boolean {
    const [hours, minutes] = endTime.split(':').map(Number);
    const endDateTime = new Date(this.currentDateTime);
    endDateTime.setHours(hours, minutes,0, 0);
    return this.currentTime> 0 ? true : endDateTime >= this.currentDateTime;
  }


  getProductList(){
    this.api.get("product/get-product-list?allProducts=false").subscribe((res: any) => {
      if (res.status) {
        this.productList = res.data;
        if(res.data.length){
          this.selectedProductId = this.productList[0].id;
          this.selectedProductName = this.productList[0].product_name;
        }
      }
      this.getQueueData(this.activeDate);
    });
  }

  selectProduct(event:any,i:any){
    this.mapping.productId=event.id;
    this.mapping.destinationId=this.dockData[i].destination_id;
    this.dockData[i].product_name=event.product_name;
    this.dockData[i].product_id=event.id;
    this.productName=event.product_name;
    this.saveMapping();
  }

  saveMapping(){
    this.api.post("dock/save-product-dock-mapping",this.mapping).subscribe((response:any) => {
      if (response.status) {
        this.alert.success("Product assigned successfully");
      } else {
        this.alert.error(response.message);
      }

    }, (error) => {
      console.log(error);
    });
  }

  selectedProcess(e: any) {
    this.selProcessId = e.value;
    this.activeTab = 0;
    this.processName = this.processList.filter(
      (item) => item.processId == e.value
    )[0].processName;
    this.getQueueData(null);
    // this.getDockCount();
  }

  selectedProduct(e: any) {
    this.selectedProductId = e.id;
    this.selectedProductName =  e.product_name;
    this.activeTab = 0;
    this.getQueueData(this.activeDate);
    // this.getDockCount();
  }

  getGroupDataByKey(data: any, key: any) {
    return lodash.groupBy(data, key);
  }


  getQueueData(slotDate: any) {
    this.common.loading = true;
    let params ={
      plantId : this.plantId,
      date: slotDate,
      productId : this.selectedProductId
    }
    this.api.get('dock/get-queue-data', params).subscribe((res: any) => {
      this.common.loading = false;
      if (res.status) {
        this.dockData = res.data;
        this.dockFilteredData = res.data;
        this.activeView = 'Current';
        if (res.data.length > 0) {
          this.getQueueCount();
          this.sliceIndex =
          this.currentTime == 0 ? this.getSliceIndex(0) : false;
        } else {
          this.dockCount = {
            late: 0,
            delay: 0,
            total: 0,
          };
        }
        this.dockData.forEach(element => {
          element.dailySlot.forEach(item => {
            // item.item.slot_data = this.groupedSlotDataByQueueIndex = this.getGroupDataByKey(item.slot_data,'queue_index');
            item.slot_data = item.slot_data.filter(e=> e.is_priority == false);
          });
        });
      }
      // let scrollIndex = 0;
      if (this.dockData.length >= this.activeTab)
        this.switchDock(this.activeTab);
    });
  }

  findSlotDataByIndex(slotData:any, index :number){
    // return slotData.some(element => element.queue_index === index);
    return slotData.find(slot => slot.queue_index === index) || null;
  }

  getQueueCount() {
    // this.dockCount = {
    //   late: 0,
    //   delay: 0,
    //   total: 0,
    // };
    // this.dockData.forEach((element) => {
    //   element['dockCount'] = 0;
    //   element.dailySlot.forEach((value) => {
    //     this.dockCount.total += value.slot_data.length;
    //     let temp = _.groupBy(value.slot_data, 'vehicle_status');
    //     this.dockCount.delay += temp.Delay ? temp.Delay.length : 0;
    //     let temp2 = _.groupBy(value.slot_data, 'loading_status');
    //     this.dockCount.late += temp2.Delay ? temp2.Delay.length : 0;
    //     let tempData = _.groupBy(value.slot_data, 'status');
    //     element['dockCount'] +=
    //       value.slot_data.length -
    //       (tempData.Completed != null ? tempData.Completed.length : 0);
    //   });
    // });
    this.dockData.forEach((element,index) => {
      let sliceIndex = this.getSliceIndex(index);
      element['availableSlots'] = 0;
      let dailySlots = element.dailySlot.slice(sliceIndex);
      let availableSlots = 0;
      dailySlots.forEach((value) => {
        for (let index = 0; index < value.vehicle_limit; index++) {
          let validTimeFlag = this.isTimeValid(this.getLoaderTime(value,index))
          let filledSlotFlag = this.findSlotDataByIndex(value.slot_data,index+1)!=null ? true : false;
          if(validTimeFlag && !filledSlotFlag) {
            availableSlots+=1;
          }
        }
      });
      element['availableSlots'] = availableSlots;
    });
  }

  getCurrentDockCount(dockData: any) {
    let temp = 0;
    dockData.dailySlot.forEach((value) => {
      this.dockCount.total += value.slot_data.length;
      let tempData = _.groupBy(value.slot_data, 'status');
      let tempData2 = _.groupBy(value.slot_data, 'destination_code');
      temp +=
        tempData2[dockData.destination_id].length -
        (tempData.Completed != null ? tempData.Completed.length : 0);
    });;
    return temp;
  }

  switchDock(index: any) {
    this.currentDateTime = new Date();
    this.activeTab = index;
    this.sliceIndex =
    this.currentTime == 0 &&
    this.activeView == 'Current' &&
    this.getSliceIndex(index);
  }

  switchDate(index: any, shift: string) {
    this.currentDateTime = new Date();
    if (index == -1) {
      if (
        (shift == 'left' && this.currentTime != 0) ||
        (shift == 'right' && this.currentTime != this.allowedFutureBookingDays) ||
        shift == 'first' ||
        shift == 'last'
      ) {
        this.currentTime =
          shift == 'left' ? this.currentTime - 1 : shift == 'first' ? 0 : shift == 'right' ? this.currentTime + 1 : this.allowedFutureBookingDays;
      }
    } else {
      this.currentTime = index;
    }

    let year = this.dates[this.currentTime].getFullYear();
    let month = this.dates[this.currentTime].getMonth();
    let date = this.dates[this.currentTime].getDate();
    this.activeDate = `${date}-${month + 1}-${year}`;

    // this.switchDock(0)
    this.getQueueData(this.activeDate);
  }

  searchVehicle() {
    this.dockData = JSON.parse(JSON.stringify(this.dockFilteredData));
    this.searchTrue = false;
    if (this.searchValue.length > 3) {
      for (let [index, item] of [
        ...this.dockData[this.activeTab].dailySlot,
      ].entries()) {
        if (item.slot_data.length) {
          this.dockData[this.activeTab].dailySlot[index].slot_data = [
            ...item.slot_data,
          ].filter((vehicle) =>
            vehicle.rc_no
              .toLowerCase()
              .includes(this.searchValue.trim().toLowerCase())
          );
        }
        this.searchTrue = true;
      }
    }
  }

  isTimeInRange(startTime: string, endTime: string): any {
    const start = this.extractTimeFromDate(new Date(`1970-01-01T${startTime}`));
    const end = this.extractTimeFromDate(new Date(`1970-01-01T${endTime}`));
    const check = this.extractTimeFromDate(new Date());
    return (check >= start && check <= end) || (check <= start && check <= end);
  }

  extractTimeFromDate(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;

    return formattedTime;
  }

  getSliceIndex(activeTab: number) {
    if(this.dockData[activeTab]){
      for (let [index, item] of this.dockData[activeTab].dailySlot.entries()) {
        if (this.isTimeInRange(item.start_time, item.end_time)) {
          return index;
        }
      }
    }
    return -1;
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantList = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  onSelectPlant(e: any) {
    this.selectedPlant = e.value;
    this.getProcessList();
  }

  getProcessList() {
    let params = {
      processActive: true,
      plantId: this.selectedPlant,
    };
    this.api.get('process/get-all-process', params).subscribe((res: any) => {
      if (res.status) {
        this.processList = res.data;
        this.selProcessId = res.data[0].processId;
        this.processName = this.processList.filter(
          (item) => item.processId == this.selProcessId
        )[0].processName;

        this.getQueueData(null);
        // this.getDockCount();
      }
    });
  }

  getCurrentSlotVehicle(dataSet: any) {
    dataSet.forEach((res) => {});
  }

  openModal(res: any) {
    this.common.params = {
      rowDetail: 'Dock',
      dockData: this.dockList,
      txnId: res.transaction_id,
      processId: this.selProcessId,
      stageId: res.stage_id,
      vehicleId: res.vid_rsn,
      dockId: res.destination_code,
      dockName: res.destination_name,
      isPriority: res.is_priority,
      flowType: 1,
      stageType: res.status == 'Loaded' ? 'Dock Stage' : 'Normal Stage',
    };
    this.assignDock();
  }

  assignDock() {
    const activeModal = this.modalService.open(ActionFormComponent, {
      size: 'lg',
    });
    activeModal.result.then((res) => {
      this.getQueueData(null);
    });
  }

  getDockCount() {
    this.api
      .get(
        'dashboard/check-dock-type-destination?processId=' +
          this.selProcessId
      )
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.dockList = res.data;
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  switchView(view: string) {
    this.activeView = view;
    this.sliceIndex =
      this.currentTime == 3 &&
      this.activeView == 'Current' &&
      this.getSliceIndex(this.activeTab);
  }

  getFilteredDockData(data: any, index: number) {
    // let dockDataFiltered;
    // let node = document.getElementById('no-vehicle-found-' + index);
    // if (this.activeView == 'Current') {
    //   dockDataFiltered = data.filter((res) => res.status != 'Completed');
    //   if (dockDataFiltered.length == 0) {
    //     node.hidden = false;
    //     document.getElementById('slot-block-' + index).appendChild(node);
    //   } else {
    //     node.hidden = true;
    //     document.getElementById('slot-block-' + index).appendChild(node);
    //   }
    //   return dockDataFiltered;
    // } else if (this.activeView == 'Late') {
    //   dockDataFiltered = data.filter((res) => res.vehicle_status == 'Delay');
    //   if (dockDataFiltered.length == 0) {
    //     node.hidden = false;
    //     document.getElementById('slot-block-' + index).appendChild(node);
    //   }
    //   return dockDataFiltered;
    // } else {
      return data.reduce((acc, map) => {
        if (map.status === 'Completed') {
          acc.unshift(map); // Insert at the beginning
        } else {
          acc.push(map); // Append at the end
        }
        return acc;
      }, []);
    // }
  }

  tripModal(vehicle) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.transaction_id,
      processName: this.processName,
    };
  }

  tripTrackingModal(vehicle) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.transaction_id,
      vehicleName: vehicle.rc_no + '-' + this.processName,
    };
  }

  openBookingModal(j,destinationId,item:any,flag,slot?){
    let newDate = new Date(this.currentDateTime);
    newDate.setDate(this.currentDateTime.getDate() + this.currentTime);
    let activeModal = this.modalService.open(DockBookingModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'l',
    });
    activeModal.componentInstance.refData = {
      destinationId : destinationId,
      slotIndex : j,
      dailySlotId : item.daily_slot_id,
      startTime : item.start_time,
      endTime : item.end_time,
      tat : item.tat,
      // activeDate:this.activeDate?this.activeDate:this.currentDateTime.toISOString().substring(0,10),
      activeDate: newDate.toISOString().substring(0,10),
      editFlag:flag,
      bookingId:slot?.dock_appointment_id,
      driverId:slot?.driver_id,
      driverName:slot?.driver_name,
      vehicleId:slot?.vid_rsn,
      vehicleName:slot?.rc_no,
      slotQueueId:slot?.booking_id,
      transaction_id:slot?.transaction_id,
      productId:this.selectedProductId
    };
    activeModal.result.then((data: any) => {
      this.getQueueData(this.activeDate);
    });
  }

  getLoaderTime(item:any,j){
    const toSeconds = (time: string) => time.split(':').reduce((acc, time) => 60 * acc + +time, 0);
    const toTimeString = (seconds: number) => new Date(seconds * 1000).toISOString().substr(11, 5);

    const initialSeconds = toSeconds(item.start_time);
    const factorSeconds = toSeconds(item.tat);
    const multipliedSeconds = factorSeconds * j;
    const totalSeconds = initialSeconds + multipliedSeconds;
    return toTimeString(totalSeconds);
    // this.loaderInTime=this.refData.startTime+(this.refData.slotIndex*this.refData.tat);
  }

  confirmAlert(bookingId:number,txnId? : number) {
    this.common.params = {
      title: 'Cancel Booking',
      description: `<b>&nbsp;` + 'Are you sure you want to cancel your slot booking ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.cancelBooking(bookingId, txnId);
      }
    });
  }

  cancelBooking(bookingId:any, txnId:number){
    let params={
      bookingId : bookingId,
      txnId : txnId
    }
    this.api.get("dock/cancel-dock-booking",params).subscribe((res: any) => {
      // if (res.status) {
      //   this.alert.success("Booking Cancelled Successfully");
      // }
      if (res.status) {
        this.alert.success(res.message);
        this.getQueueData(this.activeDate);
      } else {
        // this.confirmDeleteAfterCheck(txnId, bookingId, res.message);
      }
    });
  }

}

<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">Alert Log Info</h5>
</div>

<div class="modal-body">
  <div class="trip-card">
    <div class="card_sec">
      <div class="left_sec">Alert Title:</div>
      <div class="right_sec">{{ refData.alertTitle }}</div>
    </div>

    <div class="card_sec">
      <div class="left_sec">Alert Entity:</div>
      <div class="right_sec">{{ refData.entity }}</div>
    </div>

    <div class="card_sec">
      <div class="left_sec">Alert Condition:</div>
      <div class="right_sec">{{ refData.condition }}</div>
    </div>

    <div class="card_sec">
      <div class="left_sec">Picked By:</div>
      <div class="right_sec">{{ this.alertLogDetails[0].pickedbyuser.user_name }}</div>
    </div>

  </div>

  <div class="card card-row2 header-info">
    <div class="image-and-remark">
      <ng-container *ngIf="this.alertLogDetails[0].remark_data != null">
        <div class="image-section" *ngIf="this.alertLogDetails[0].remark_data[0].fileUrl != null">
          <label class="field-label">Uploaded Image</label>
          {{ imageName(this.alertLogDetails[0].remark_data[0].fileUrl) }}
          <div class="image-div" *ngIf="image_ext=='png' || image_ext=='jpeg' || image_ext=='jpg'">
            <div class="image" (click)="displayImage(this.alertLogDetails[0].remark_data[0].fileUrl)">
              <img [src]="image_url" alt="">
            </div>
          </div>
          <div class="image-div" *ngIf="image_ext != 'png' && image_ext != 'jpeg' && image_ext != 'jpg'">
            <div class="image" (click)="newTab(this.alertLogDetails[0].remark_data[0].fileUrl)">
              <img src="assets/images/document_img.png">
            </div>
          </div>
        </div>
        <div class="remark-section">
          <label class="field-label">{{ 'remark' | translate }}</label>
          <div class="text-div">
            <input type="text" class="form-control" [value]="this.alertLogDetails[0].remark_data[0].remark" disabled>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="assigned-users">
      <label class="user-label">Assigned Users</label>
      <ul class="image-div" style="flex-direction: column;">
        <li *ngFor="let user of this.alertLogDetails[0].assigned_users">{{ user.user_name }}
          <span class="badge status-badge log-info-badge"
            [style.background-color]="user.mapping_type === 'INFO' ? '#FF9914' : '#0ba907'"
            style="font-size: 12px;color: white; padding: 4px 5px 4px 5px; float: right;">{{user.mapping_type}}</span>
        </li>
      </ul>
    </div>

  </div>
</div>

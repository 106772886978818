import { DriverLiveUrlComponent } from 'src/app/components/driver-live-url/driver-live-url.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { DriverTrackerComponent } from './components/driver-tracker/driver-tracker.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth/auth.component';
import { LoginComponent } from './auth/login/login.component';

import { CommunicationComponent } from './components/communication/communication.component';
import { PublicVechileDetailComponent } from './components/public-vechile-detail/public-vechile-detail.component';
import { AuthGuard } from './@core/guards/auth.guard';
import { PublicFieldReportComponent } from './components/public-field-report/public-field-report.component';
import { SealFieldReportComponent } from './components/seal-field-report/seal-field-report.component';
import { SealFieldReportOldComponent } from './components/seal-field-report-old/seal-field-report-old.component';
import { AuthVisitorGuard } from './@core/guards/authVisitor.guard';
import { VisitorLoginComponent } from './components/visitor-login/visitor-login.component';

const routes: Routes = [
  {
    path: 'user',
    loadChildren: () =>
      import('../app/modules/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'visitor',
    loadChildren: () =>
      import('../app/modules/visitor/visitor.module').then((m) => m.VisitorModule),
    canActivate: [AuthVisitorGuard,AuthGuard],
  },
  {
    path: 'driver-tracking',
    component: DriverTrackerComponent,
  },
  {
    path: 'trip-tracking',
    component: PublicVechileDetailComponent,
  },
  {
    path: 'live-tracking',
    component: DriverLiveUrlComponent,
  },
  {
    path: 'public-field-audit-report',
    component: SealFieldReportComponent,
  },
  {
    path: 'public-field-audit-report-old',
    component: SealFieldReportOldComponent,
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: 'visitor-login',
        component: VisitorLoginComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
    ],
  },
  {
    path: '',
    redirectTo: 'user',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    redirectTo: 'user',
  },
  {
    path: 'Communication_comp',
    component: CommunicationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ProcessService } from '../process/process.service';
import { SlotRangeModalComponent } from 'src/app/modals/slot-range-modal/slot-range-modal.component';

@Component({
  selector: 'app-zone-slot-mapping',
  templateUrl: './zone-slot-mapping.component.html',
  styleUrls: ['./zone-slot-mapping.component.scss'],
})
export class ZoneSlotMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'zone List');
  zones: any[] = [];
  selectedZoneId: number = null;
  selectedZoneName: string = null;
  selectedZoneSlotLimit: any = null;
  zoneSlotMappings: any[] = [];
  fromHere: number = null;
  toHere: number = null;
  zoneStageId: number = null;
  selectedOrderId: number = null;
  selectedOrderName: string = null;
  process: any[] = [];
  oldRangeFrom: number = null;
  oldRangeTo: number = null;
  btn = 'save';
  editBtn: boolean = false;
  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public alertService: AlertService,
    private processService: ProcessService,
    public api: ApiService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Zone', url: null },
      { name: 'Zone Slot Mapping', link: '/user/zone-slot-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }

  zoneValidator: any = null;

  ngOnInit(): void {
    this.getAllProcess();
    this.getAllZoneSlotMappings();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  selectedZone(event: any) {
    this.selectedZoneId = event.id;
    this.selectedZoneName = event.zoneName;
    this.selectedZoneSlotLimit = event.slotLimit;
    this.zoneStageId = event.stageId;
  }

  getAllZones() {
    return new Promise((resolve, reject) => {
      this.api
        .get('zone/get_all_zones?processStageOrderId=' + this.selectedOrderId)
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.zones = res.data;
              resolve(this.zones);
            }
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(err);
          }
        );
    });
  }

  reset() {
    this.btn = 'save';
    this.zoneValidator = null;
    this.startingSlotNoValidator = null;
    this.endingSlotNoValidator = null;
    this.selectedZoneId = null;
    this.selectedZoneName = null;
    this.selectedZoneSlotLimit = null;
    this.fromHere = null;
    this.toHere = null;
    this.zoneStageId = null;
    this.editBtn = false;
    this.selectedOrderId = null;
    this.selectedOrderName = null;
    this.oldRangeFrom = null;
    this.oldRangeTo = null;
  }

  getAllZoneSlotMappings() {
    this.api.get('zone/get_all_zone_slot_mappings').subscribe(
      (res: any) => {
        if (res.status) {
          this.zoneSlotMappings = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  save() {
    let params = {
      zoneId: this.selectedZoneId,
      from: this.fromHere,
      to: this.toHere,
      zoneSlotLimit: this.selectedZoneSlotLimit,
      stageId: this.zoneStageId,
      processStageOrderId: this.selectedOrderId,
      isEdit: this.editBtn,
      oldRangeFrom: this.oldRangeFrom,
      oldRangeTo: this.oldRangeTo,
    };

    this.common.loading = true;
    this.api.post('zone/save_zone_slot_mapping', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.info(res.message);
          this.reset();
          this.getAllZoneSlotMappings();
        } else {
          this.alertService.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  view(row?: any) {
    const {
      process_stage_order_id,
      stage_id,
      zone_id,
      stage_destination_codes: slot_range,
      ...updatedItem
    } = row;
    this.common.params = {
      details: [updatedItem],
      title: 'Zone Slot Mapping Details',
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  getAllProcess() {
    this.common.loading = true;
    this.processService.getAllProcessStage().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.process = res.data.filter(
          (data) => data.stage_type_name == 'Parking'
        );
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedProcess(event: any) {
    this.selectedOrderId = event.order_id;
    this.selectedOrderName = event.order_name;
    this.getAllZones();
  }

  async edit(row: any) {
    this.reset();
    this.btn = 'update';
    if (row.stage_destination_codes.split(',').length > 1) {
      this.chooseSlotRangeModal(row);
    } else {
      let array = row.stage_destination_codes.split('-');
      this.fromHere = parseInt(array[0]);
      this.oldRangeFrom = parseInt(array[0]);
      this.oldRangeTo = parseInt(array[1]);
      this.toHere = parseInt(array[1]);
    }
    this.selectedOrderId = row.process_stage_order_id;
    await this.getAllZones();
    this.editBtn = true;
    this.zoneStageId = row.stage_id;
    this.selectedZoneSlotLimit = row.slot_limit;
    this.selectedOrderName = row.process_stage_order;
    this.selectedZoneId = row.zone_id;
    this.selectedZoneName = row.zone_name;
  }

  chooseSlotRangeModal(row: any) {
    this.common.params = {
      stage_destination_codes: row.stage_destination_codes.split(','),
    };
    const activeModal = this.modalService.open(SlotRangeModalComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response && data.range != null) {
        let array = data.range.split('-');
        this.fromHere = parseInt(array[0]);
        this.toHere = parseInt(array[1]);
        this.oldRangeFrom = parseInt(array[0]);
        this.oldRangeTo = parseInt(array[1]);
      }
    });
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Zone Slot Mapping ',
      description:
        `<b>&nbsp;` + 'Are you sure you want to delete Mapping?' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });
  }

  delete(row: any) {
    this.api
      .get(
        'zone/delete_zone_slot_mapping_id?zoneId=' +
          row.zone_id +
          '&orderId=' +
          row.process_stage_order_id
      )
      .subscribe(
        (res: any) => {
          if (res.status) {
            if (res.data[0] == 'Mapping Deleted Successfully !!') {
              this.alertService.info(res.data[0]);
              this.getAllZoneSlotMappings();
            } else {
              this.alertService.error(res.data[0]);
            }
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  processStageValidator: any = null;
  startingSlotNoValidator: any = null;
  endingSlotNoValidator: any = null;

  formValidator() {
    this.zoneValidator = this.common.nullValidator(
      this.selectedZoneId,
      'Zone Mandatory'
    );
    this.processStageValidator = this.common.nullValidator(
      this.selectedOrderId,
      'Process -> Stage Mandatory'
    );
    this.startingSlotNoValidator = this.common.nullValidator(
      this.fromHere,
      'Starting Slot Number Mandatory'
    );
    this.endingSlotNoValidator = this.common.nullValidator(
      this.toHere,
      'Ending Slot Number Mandatory'
    );

    if (
      !this.zoneValidator.error &&
      !this.processStageValidator.error &&
      !this.startingSlotNoValidator.error &&
      !this.endingSlotNoValidator.error
    ) {
      this.save();
    }
  }
}

<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">Transaction History</h5>
</div>

<div class="modal-body">
  <!-- Header Info -->
  <div class="header-info">
    <div class="info-item">
      <label>Process Name:</label>
      <span>{{ refData.processName }}</span>
    </div>
    <div class="info-item">
      <label>Stage Name:</label>
      <span>{{ refData.stageName }}</span>
    </div>
    <div class="info-item">
      <label>Transaction ID:</label>
      <span>{{ refData.txnId }}</span>
    </div>
  </div>

  <!-- Flow Diagram -->
  <div class="flow-diagram">
    <!-- Flow Boxes -->
    <div class="flow-box" *ngFor="let item of refData.history">
      <div class="box-content">
        <p>Assigned To: {{ item.assignedTo }}</p>
        <p>Assigned By: {{ item.assignedBy }}</p>
        <p>Assigned At: {{ item.addTime }}</p>
      </div>
    </div>

    <!-- Arrows -->
    <div class="arrow"></div>

  </div>
</div>






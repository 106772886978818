<div class="modal-header" *ngIf="isModal">
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title text-uppercase font-weight-bold">{{'trip_detail'|translate}}</h4>
</div>
<div class="tab_section">
  <div class="tab" *ngFor="let tabitem of tabData" [class.active]="tripType == tabitem.value"
    (click)="tripType = tabitem.value;tripType=='Current '+txnType?tripDetail():getPreviousTripReport()">
    <p>{{tabitem.tabname|translate}}</p>
  </div>
</div>
<div [class.modal-body]="isModal">
  <div class="container-fluid">
    <div class="container-card">
      <div class="container-card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">

                <div class="header-block">
                  <h3 class="card-title mr-2" style="width: 70%;">
                    {{(txnType=='Visit'?'Visit Detail':'trip_detail')|translate}}
                  </h3>

                  <div class="info_back" style="width: 50%;">
                    <img src="assets/images/chat-icon.png"
                      style="width:20px;height:20px;filter: brightness(0) saturate(100%) invert(31%) sepia(94%) saturate(512%) hue-rotate(174deg) brightness(97%) contrast(88%);"
                      (click)="openCommentTrail()">
                  </div>

                  <div class="btn-block" *ngIf="isPublic">
                    <button class="btn pull-right"
                      style="background:transparent;background: transparent;color: black !important;border: 0.8px solid #ccc;"
                      [routerLink]="'../public-field-audit-report'" [queryParams]="{tripId:txnId}">
                      {{'field_report_view'|translate}}</button>
                  </div>
                </div>

                <div class="row auto_sugg_class">
                  <!-- <div *ngIf="role.user_role  == 'COMPANYADMIN'" class="col-md-6"> </div> -->
                  <!-- <div  class="col-md-8"> </div> -->
                  <div class="col-md-3 pr-0" *ngIf="role.user_role  == 'COMPANYADMIN' && refData == null">
                    <mat-form-field appearance="fill">
                      <mat-label>{{"select_plant" | translate}}</mat-label>
                      <mat-select [(value)]="selectedPlant" (selectionChange)="onSelectPlant($event)">
                        <mat-option>
                          <ngx-mat-select-search placeholderLabel="Plant" ngModel
                            noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let plant of  plantList"
                          [value]="plant.plantId">{{plant.plantName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-md-3 pr-0">
                    <mat-form-field appearance="fill" *ngIf="showHeader && !isPublic">
                      <mat-label> {{'select_vehicle'| translate}} </mat-label>
                      <mat-select [(value)]="vehicleRCNo">
                        <mat-option>
                          <ngx-mat-select-search placeholderLabel="Select Vehicle" ngModel
                            (ngModelChange)="filterVehicle($event)" noEntriesFoundLabel="No Data Found">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let  item of transactionVehicles;index as i" (click)="selectVehicle(item)"
                          [value]="item.vehiclersn">
                          {{item.vehiclersn}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-md-3">
                    <mat-form-field *ngIf="showHeader && !isPublic" appearance="fill">
                      <mat-label>{{'select_transaction'|translate}}</mat-label>
                      <mat-select [(value)]="txnId">
                        <mat-option>
                          <ngx-mat-select-search placeholderLabel="Select Transaction" ngModel
                            (ngModelChange)="filterTxn($event)" noEntriesFoundLabel="No Data Found">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let  item of transactionVehicles;index as i" (click)="selectVehicle(item)"
                          [value]="item.ipa_transaction_id">
                          {{item.txnid}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>



              </div>


              <div class="card-body">
                <div class="trip_detail">
                  <!-- <div class="action_data_div" *ngIf="tripDetails.length == 0">
                      <p style="color:red;display: flex;justify-content: center;">No Record Found !!</p>
                    </div> -->
                  <div class="process_div" *ngIf="tripDetails.length">
                    <h5>{{processName}}
                    </h5>
                    <div class="vehicle_detail" width="100%" style="margin-top: 0px;">
                      <div class="row">
                        <div class="col-md-3 row_col">
                          <div class="key_value">
                            <span class="key">{{(txnType=='Visit'?'visitor_name':'vehicle_number')|translate}}: </span>
                            <span class="value" appEllipsisView
                              style="display:inline-flex">{{tripDetails[0].vehicle_rsn}}</span>
                          </div>
                        </div>

                        <div class="col-md-2 row_col">
                          <div class="key_value">
                            <span class="key">{{(txnType=='Visit'?'Visit ID':'txn_id')|translate}}: </span>
                            <span class="value" appEllipsisView>{{tripDetails[0].txnid}}</span>
                          </div>
                        </div>

                        <div class="col-md-3 row_col">
                          <div class="key_value">
                            <span class="key">{{(txnType=='Visit'?'meet_with':'driver_name')|translate}}: </span>
                            <span class="value"
                              appEllipsisView>{{txnType=='Visit'?tripDetails[0].meet_with:tripDetails[0].driver_name}}</span>
                          </div>
                        </div>

                        <div class="col-md-4 row_col">
                          <div class="key_value">
                            <span class="key">{{(txnType=='Visit'?'Visit Purpose':'transporter_name')|translate}}:
                            </span>
                            <span class="value"
                              appEllipsisView>{{txnType=='Visit'?tripDetails[0].purpose:tripDetails[0].transporter_name}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container>
                      <div class="process_detail" *ngFor="let trip of tripDetails">
                        <div class="process_name">
                          <span>{{trip.stage_name}}&nbsp;
                            <i *ngIf="trip.flow_type==1" style="color: #00b500;" class="fa fa-check-circle"></i>
                            <i *ngIf="trip.flow_type==0" style="color: #fd0000;" class="fa fa-times-circle"></i>
                          </span>
                        </div>

                        <div class="row process_opt">
                          <div class="col-md-3" *ngIf="isVisible == 'true'">
                            <div class="common_con pre_post_con">
                              <p>{{'required_condition'|translate}}</p>
                              <ul>
                                <li *ngFor="let req of trip.pre_action"
                                  [ngStyle]="{'background-color':req.response=='PASS'?'#c3f6c3':req.response=='PENDING'?'#fafbfc':req.response=='FAIL'?'#ff9e9e':'rgb(248 226 187)'}">
                                  <span>{{req.action_name}}</span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div [ngClass]="isVisible == 'true' ? 'col-md-6 pl-0 pr-0' : 'col-md-12 l-0 pr-0'">
                            <div class="common_con my_con">
                              <div class="date_time" style="display:flex;justify-content:center;align-items: baseline;">
                                <p style="background-color:white" class="pill1 bg-green-100 text-green-800"
                                  *ngIf="trip.time_in"><i class="fa fa-user mr-2"
                                    *ngIf="trip.time_in && !trip.system_in && trip.created_by != -2"></i><i
                                    *ngIf="trip.time_in && trip.system_in && trip.created_by != -2"
                                    class="fa fa-desktop mr-2" style="height:16px;"></i> <img
                                    *ngIf="trip.time_in && trip.created_by == -2" src="assets/images/trip-ai.png"
                                    style="height:18px;margin-right: 4px;">{{trip.time_in| date:"dd/MM/yyyy hh:mm:ss
                                  a"}}</p>
                                <p style="background-color:white">{{'TO'|translate}}</p>
                                <p style="background-color:white" class="pill1 bg-green-100 text-green-800"
                                  *ngIf="trip.time_out"><i class="fa fa-user mr-2"
                                    *ngIf="trip.time_out && !trip.system_out && trip.modified_by != -2"></i><i
                                    *ngIf="trip.time_out && trip.system_out && trip.modified_by != -2"
                                    class="fa fa-desktop mr-2" style="height:16px;"></i> <img
                                    src="assets/images/trip-ai.png" *ngIf="trip.time_out && trip.modified_by == -2"
                                    style="height:18px;margin-right: 4px;">{{trip.time_out| date:"dd/MM/yyyy hh:mm:ss
                                  a"}}</p>
                              </div>
                              <div class="stage_info">
                                <div><span class="stage_info_label">{{'expected_tat'|translate}}{{':'}}</span>
                                  {{trip.expected_tat}}</div>
                                <div><span class="stage_info_label">{{'actual_tat'|translate}}{{':'}}</span>
                                  {{trip.actual_tat}}
                                </div>
                                <div class="icon">
                                  <img src="assets/images/tracking-icon-ai.png" style="height:19px;"
                                    (click)="openTrackingLog(trip.ipa_transaction_stage_detail_id)">
                                </div>
                                <div *ngIf="trip.over_tat==true"
                                  style="background-color: red;color: white;height:fit-content" class="badge">
                                  {{'over_tat'|translate}}</div>
                                <div>
                                  <button *ngIf="trip.late_remark_id!=null" class="shrink-badge button-color"
                                    (click)="viewRemark(trip.late_remark_id)"
                                    style="background-color: #dd3128db;">{{'remark'|translate}}</button>
                                </div>
                              </div>
                              <p class="center_div_title">{{'my_action'|translate}}</p>
                              <ul>
                                <li *ngFor="let my of trip.curr_action"
                                  [ngStyle]="{'background-color':my.response=='PASS'?'#c3f6c3':(my.response=='PENDING'?'#fafbfc':my.response=='FAIL'?'#ff9e9e':'rgb(248 226 187)')}">
                                  <div class="con_title">
                                    <div
                                      style="display: flex; flex-direction: row;justify-content: space-between; width: 100%;">
                                      <span>{{my.action_name}}</span>
                                      <span style="background-color: #007bff;color: white;" *ngIf="my.action_type==1"
                                        class="badge">{{'audited'|translate}}</span>
                                      <span *ngIf="my.is_override=='PASS' && my.hasOwnProperty('override_by')==true"
                                        class="badge bg-success">{{'OVERRIDE-PASS'|translate}}{{'('+
                                        my.override_by+')'}}</span>
                                      <span *ngIf="my.is_override=='PASS' && my.hasOwnProperty('override_by')==false"
                                        class="badge bg-success">{{'OVERRIDE-PASS'|translate}}{{'('+
                                        my.created_by+')'}}</span>
                                      <span style="background-color: red;color: white;" *ngIf="my.is_override=='FAIL'"
                                        class="badge">{{'OVERRIDE-FAIL'|translate}}{{'('+ my.override_by+')'}}</span>
                                      <span *ngIf="my.is_override=='PENDING'"
                                        class="badge bg-warning text-dark">{{'OVERRIDE-PENDING'|translate}}</span>
                                    </div>
                                    <div class="w_icon_class">
                                      <!-- <i class="fa fa-eye" *ngIf="my.action_type==1"
                                          (click)="viewAuditLogs(my,trip.stage_name)"></i> -->
                                      <i *ngIf="my.action_operation_type=='form'" class="fa fa-clipboard"
                                        style="cursor: pointer;" (click)="actionDetail(my,'pre',trip,1)"></i>

                                      <i *ngIf="checkExist(my.action_id , trip.stage_id)==true && my.response=='FAIL'"
                                        style="cursor: pointer;" (click)="openEditModal(trip , my)"
                                        class="fa fa-edit"></i>

                                      <i *ngIf="userService.permissionMap.get('manual-stamping_access')==true && refData?.isManualStamping"
                                        (click)="openForm(trip,my)" class="fa fa-outdent" style="cursor: pointer;"></i>
                                    </div>
                                  </div>

                                  <div class="con_content" *ngIf="my.response!='PENDING'">
                                    <div class="w_con_class"><i class="fa fa-user"></i>{{": "}}{{my.created_by}}</div>
                                    <div class="w_con_class text-right"><i
                                        class="fa fa-clock"></i>{{":"}}{{my.created_date| date:"dd/MM/yyyy hh:mm a"}}
                                    </div>
                                  </div>

                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class="col-md-3" *ngIf="isVisible == 'true'">
                            <div class="common_con pre_post_con">
                              <p>{{'post_action'|translate}}</p>
                              <ul>
                                <li *ngFor="let post of trip.post_action"
                                  [ngStyle]="{'background-color':post.response=='PASS'?'#c3f6c3':post.response=='PENDING'?'#fafbfc':post.response=='FAIL'?'#ff9e9e':'rgb(248 226 187)'}">
                                  <span>{{post.action_name}}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                  </div>
                  <app-data-not-found *ngIf="tripDetails.length==0 && common.loading==false"></app-data-not-found>
                </div>
              </div>

            </div>

            <!-- <app-data-not-found *ngIf="transactionVehicles.length==0"></app-data-not-found> -->
          </div>

        </div>
      </div>
    </div>
  </div>

</div>

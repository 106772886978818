<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="card" style="box-shadow: none;">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"Select User" | customTranslate}}</label>
                  <ps-auto-suggestion class="search" [data]="filterRow.userFilter" display="entity_name"
                    (onSelected)="filterUser($event)" placeholder="Choose User" isMultiSelect="true">
                  </ps-auto-suggestion>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"Select Zone" | customTranslate}}</label>
                  <ps-auto-suggestion class="search" [data]="filterRow.zoneFilter" display="zone_name"
                    (onSelected)="filterZone($event)" placeholder="Choose Zone" isMultiSelect="true">
                  </ps-auto-suggestion>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"Select Stage" | customTranslate}}</label>
                  <ps-auto-suggestion class="search" [data]="filterRow.stageFilter" display="stage_name"
                    (onSelected)="filterStage($event)" placeholder="Choose Stage" isMultiSelect="true">
                  </ps-auto-suggestion>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-6 d-flex" style="height: min-content;">
                  <div style="width: 75%;height: min-content;">
                    <label for="my-input">{{"Select Date" | customTranslate}}</label>
                    <input type="date" class="search-date search" name="dueDate" [(ngModel)]="dueDate"
                      placeholder="Choose Date" (change)="selectedDate(dueDate)">
                  </div>
                  <div style="width: 25%;" class="d-flex align-items-end justify-content-end">
                    <button class="btn btn-warning ml-1" style="padding:7px !important" (click)="resetDetails()"><i
                        class="fa fa-repeat mr-2"></i>reset</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body checklist_admin_dash" style="margin-top: -15px;">
              <div class="row my-2 mx-0">
                <div class="col-12 p-0"
                  style="box-sizing: border-box;display: inline-flex;margin: 4px 0px;box-sizing: border-box;border-radius: 5px;padding-right:10px !important;height:60vh">
                  <div class="dock_over_left entity-list">
                    <ng-container *ngFor="let item of userTrackingData ;let i = index">
                      <div class='dock_over_card'
                        [ngClass]="{'active_card_dock': userSelected != null && item.entity_name == userSelected.entity_name}"
                        *ngIf="(filterData.userNames.length && filterData.userNames.includes(item.entity_name) || !filterData.userNames.length) &&
                      (filterData.zoneIds.length && filterData.zoneIds.includes(item.zone_id) || !filterData.zoneIds.length) &&
                      (filterData.stageIds.length && filterData.stageIds.includes(item.stage_id) || !filterData.stageIds.length)"
                        (click)="selectedUser(item)">
                        <div class="dock_row">
                          <div class="image" (click)="item.entity_image?displayImage(item.entity_image):null">
                            <img [src]="item.entity_image?item.entity_image:'assets/images/user_whatsapp.jpg'"
                              style="aspect-ratio: 1 / 1 !important;">
                          </div>
                          <div style="display: flex;width: 80%;flex-direction: column;flex-wrap: wrap;">
                            <div
                              style="display: flex;width: 100%;justify-content: space-between;font-size: 11px!important;align-items: center;">
                              <span style="font-size:14px;font-weight:600">{{item.entity_name}}</span>
                              <span>Tracked Time :
                                <span
                                  [ngClass]="{'early mr-2':item.prod_hour != null,'delay mr-2':item.prod_hour == null}"
                                  style="font-size: 11px!important;"> {{item.prod_hour == null ? '00:00' :
                                  item.prod_hour}}
                                  <i class="fa fa-clock"></i></span>
                              </span>
                            </div>
                            <span style="font-size:11px"><i class="fa fa-phone"></i> {{item.mobile_no}} </span>
                          </div>
                          <div class="ellipsis_sec">
                            <ng-container *ngTemplateOutlet="ellipseContainer; context: { $implicit: item, index: i }">
                            </ng-container>
                          </div>
                        </div>

                        <div class="dock_row mt-1" style="border-top: .5px #d1d1d1 solid;padding-top: 5px;"
                          *ngIf="item.last_log != null">
                          <span style="font-size:11px">Last Log : <span class="info-jellybean ml-1"
                              style="font-size: 10px !important;padding: 1px 4px !important;">{{item.last_log}}
                              <i class="fa fa-clock ml-1"></i></span>
                          </span>
                          <!-- <span *ngIf="item.last_log == null" style="font-size:11px !important"> Last Log :<span
                              class="badge status-badge badge-overdue ml-2"> N/A</span> </span> -->
                          <span style="font-size:10px !important"> Tracked Zone :
                            <span class="info-jellybean badge-overdue"
                              style="font-size: 10px !important;padding: 1px 4px !important;">{{item.stage_name}}
                              ({{item.zone_name}})</span>
                          </span>
                        </div>
                      </div>
                    </ng-container>
                  </div>

                  <div class="dock_over_right col-9 py-0">
                    <div class="map" id="map-tracking" style="width: 98%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="modal" id="detailModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">{{'User Details'|customTranslate}}</h5>
        </div>
        <div class="modal-body p-0">
          <div class="cards_row">
            <!-- <h5 class="modal-title" id="modal-basic-title">{{'User Details"'|customTranslate}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
              <span>&times;</span>
            </button> -->
            <div class="table-responsive" *ngIf="viewMarkerData.length>0">
              <table class="table-responsive-alert" style="max-height: 450px;overflow-y: scroll;display: block;">
                <tr class="table-alert" style="position: sticky;top:0">
                  <th>User Name</th>
                  <th>User Type</th>
                  <th>Productive Hours</th>
                  <th>Last Ping</th>
                </tr>
                <tbody class="table-alert-list">
                  <tr *ngFor="let user of viewMarkerData">
                    <td>{{user.entity_name}}</td>
                    <td>{{user.entity_type}}</td>
                    <td>{{user.prod_hour == null ? '00:00' : user.prod_hour}}</td>
                    <td>{{user.last_log}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="viewMarkerData.length===0" class="text-center mt-1 fade-in"
              style="font-weight: 600; color: #cd4040;font-size: larger;">No User Tracked!</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #ellipseContainer let-visit let-i="index">
    <i class="fa fa-ellipsis-v"
      (click)="ellipseOn.checked ? ellipseOn.checked = false : ellipseOn.click(); $event.stopPropagation(); "></i>
    <input [hidden]="true" #ellipseOn (click)="$event.stopPropagation();" type="radio" name="ellipse"
      [value]="'visit.entity_id' + '@' + i" id="">
    <ul class="ellipsis_data" *ngIf="ellipseOn.checked">
      <li (click)="openUserTrackingModal(visit.entity_id,visit.entity_type)">
        <img src="assets/images/user-path-tracking.png" alt="" title="User Tracking" style="height: 15px; width: 15px;"
          matTooltipPosition="below"> Tracking Logs
      </li>
    </ul>
  </ng-template>

export const FIELD_TYPE_ICONS = {
    'TEXTFIELD': 'font',
    'NUMBERFIELD': 'hashtag',
    'TEXTAREA': 'font',
    'DATEPICKER': 'calendar days',
    'DATETIMEPICKER': 'calendar days',
    'TIME': 'clock-o',
    'SELECTLISTSINGLE': 'check',
    'SELECTLISTMULTIPLE': 'check-double',
    'CHECKBOX': 'check-square',
    'FILE': 'file',
    'RADIOBUTTON': 'circle',
    'URL': 'link',
    'GEOCODEDIMAGE': 'image',
    'SIGNATURE': 'signature',
    'FIELDGROUP': 'th-list',
    'LABEL': 'tags',
    'IMAGE': 'image',
    'BARCODE': 'barcode',
    'BARCODEWITHIMAGE': 'image',
    'RFIDCODE': 'signal',
    'RFIDCODEWITHIMAGE': 'image',
    'CAMERASTREAMING': 'camera',
    'VIDEOSTREAMING': 'video',
}
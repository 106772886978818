<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">

          <div class="card-body checklist_admin_dash">
            <div class="stage_vehicle_row seprate_box_design">
              <div class="stage_vehicle_box" *ngFor="let item of countData;let i=index"
                [style.background-color]="bgColorArray[i % 8]" (click)="readCountData(item.flag,false,-1,-1)">
                <div class="top_part" *ngIf="history && !common.loading">
                  <h4> {{history[item.key]}}</h4>
                  <i [class]="iconArray[i%8]" [style.color]="colorArray[i % 8]"></i>
                </div>

                <div class="bottom_part">
                  <p class="font-size:18px;font-weight:600">{{item.name}}</p>
                </div>
              </div>
            </div>

            <!-- <div class="info-group">
              <div class="circle-col" *ngFor="let item of countData;let i=index"
                (click)="readCountData(item.flag,false,-1)">
                <div class="info-circle" [ngClass]="'bg-'+colorArray[i % 8]"
                  [style.background-color]="colorArray[i % 8]">
                  <div class="info-block">
                    <h3 class="info-number">
                      {{history[item.key]}}
                    </h3>

                    <div class="info">
                      <p>{{item.name}}</p>
                    </div>
                  </div>

                  <div class="info-block">
                    <div class="icon" [ngClass]="'bg-'+colorArray[i % 8]" style="margin-bottom:18px;">
                      <img src="assets/images/sidebar/check-list3.png" height="55px" />
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <app-data-not-found *ngIf="!history && !common.loading"></app-data-not-found>

            <div class="row mx-0 my-1">
              <div class="col-md-8 pl-0 pr-0" *ngIf="groupedLists.length>0">
                <div class="pending_checklist seprate_box_design">
                  <div class="subtitle">Pending Checklist</div>
                  <div class="checklist_tender">
                      <div *ngIf="groupedLists.length>0" class="dock_over_left">
                        <div class="dock_over_card" *ngFor="let item of groupedLists;let i = index" [class.active]="i==selectedIndex" (click)="selectedIndex=i">
                          <div class="dock_top">
                            <p>{{item.checklist_title}}</p>
                          </div>

                          <div class="dock_bottom">
                            <span class="early mr-2" style="font-size: 10px !important;">{{item.completed}} Completed<i
                                class="fa fa-check-circle ml-1" style="color: #3dc03d;"></i></span>
                            <span class="delay mr-2" style="font-size: 10px !important;">{{item.rejected}} Rejected<i
                                class="fa fa-times-circle ml-1" style="color: #c0533d;"></i> </span>
                            <span class="jelly-pending" style="font-size: 10px !important;">{{item.pending}} Pending<i
                                class="fa fa-clock ml-1" style="color: #c0af3d;"></i></span>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="groupedLists.length>0" class="dock_over_right">
                        <div class="dock_right_top_row">
                          <div class="top_left">
                            <p>{{groupedLists[selectedIndex].checklist_title}}</p>
                          </div>

                          <div class="top_right">
                            <div class="top_right_content">
                              <p><i class="fa fa-check-circle" style="color: #22ad30;"></i> Completed</p>
                              <h4>{{groupedLists[selectedIndex].completed}}</h4>
                            </div>

                            <div class="top_right_content">
                              <p><i class="fa fa-times-circle" style="color: #b32d21;"></i> Rejected</p>
                              <h4>{{groupedLists[selectedIndex].rejected}}</h4>
                            </div>

                            <div class="top_right_content">
                              <p><i class="fa fa-clock" style="color: #b38521;"></i> Pending</p>
                              <h4>{{groupedLists[selectedIndex].pending}}</h4>
                            </div>
                          </div>
                        </div>

                        <div class="table-responsive" style="height: 170px;">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Completed</th>
                                <th>Rejected</th>
                                <th>Pending</th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngFor="let user of groupedLists[selectedIndex].checklist_data;let i=index">
                                <tr>
                                  <td>{{user.user_name}}</td>
                                  <td><span class="jelly-bean  bean early"
                                      (click)="readCountData('Approved',false,user.user_id,user.id)">{{user.completed}}</span>
                                  </td>
                                  <td><span class="jelly-bean bean delay"
                                      (click)="readCountData('Rejected',false,user.user_id,user.id)">{{user.rejected}}</span>
                                  </td>
                                  <td><span class="jelly-bean bean jelly-pending"
                                      (click)="readCountData('Pending',false,user.user_id,user.id)">{{user.pending}} </span>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </div>
                        <!-- <div class='user-box' *ngFor="let users of groupedLists[selectedIndex].checklist_data" (click)="readCountData('All',false,users.user_id,users.id)">
                          <span>{{users.alias}}</span>
                          <div>
                            <span class="early mr-2" style="font-size: 11px !important;">{{users.completed}} <i
                                class="fa fa-check-circle ml-1" style="color: #3dc03d;"></i></span>
                            <span class="delay mr-2" style="font-size: 11px !important;">{{users.rejected}} <i
                                class="fa fa-times-circle ml-1" style="color: #c0533d;"></i> </span>
                            <span class="jelly-pending" style="font-size: 11px !important;">{{users.pending}} <i
                                class="fa fa-clock ml-1" style="color: #c0af3d;"></i></span>
                          </div>
                        </div> -->
                      </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8 pl-0 pr-0" *ngIf="groupedLists.length==0">
                <div class="checklist_tender seprate_box_design">
                  <div class="text-center mt-3 fade-in" style="font-weight: 600; color: #000000;font-size: larger;">No Checklist Today</div>
                </div>
              </div>

              <div class="col-md-4 pr-0 pl-1">
                <div class="active_alerts seprate_box_design">
                  <div class="subtitle">Active Alerts</div>
                  <div class="alert_boxes_sec">
                    <div *ngIf="alerts?.length==0" class="text-center mt-1 fade-in no-assets-tracked">No Active Alerts
                    </div>
                    <div *ngIf="alerts?.length>0">
                      <div class="alert_box" *ngFor="let alert of alerts"
                        (click)="viewAlert(alert.alert_data,alert.alert_condition,alert.type_name,alert.alert_name,alert)">
                        <div class="top_alert_row">
                          <h4>{{alert.title}}</h4>
                          <button [ngClass]="alert.type_name"
                            [style.background]="alert.type_color">{{alert.type_name}}</button>
                        </div>
                        <div class="bottom_alert_row">
                          <div class="name"><i class="fa fa-user"></i> {{alert.checklist_title}}</div>
                          <div class="time">{{alert.created_at|date : 'dd-MM-YYYY hh-mm'}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-0 pr-0">
                <div class="card table-card" style="max-height: 465px;">
                  <div class="card-header">
                    <div class="header-block">
                      <h3 class="card-title">{{"Best Users" | translate}}</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Completed</th>
                            <th>Rejected</th>
                            <th>Before Time</th>
                            <th>On Time</th>
                            <th>After Time</th>
                            <th>Total </th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let user of bestUsers;let i=index">
                            <tr>
                              <td>{{user.user_name}}</td>
                              <td><span
                                  (click)="readCountData('Approved',true,user.user_id,-1)">{{user.approved_percent}}%</span>
                              </td>
                              <td><span class="jelly-bean bean delay"
                                  (click)="readCountData('Rejected',true,user.user_id,-1)">{{user.rejected_percent}}%</span>
                              </td>
                              <td><span
                                  (click)="readCountData('before',true,user.user_id,-1)">{{user.before_tolerance_percent}}%</span>
                              </td>
                              <td><span
                                  (click)="readCountData('in',true,user.user_id,-1)">{{user.in_tolerance_percent}}%</span></td>
                              <td><span
                                  (click)="readCountData('after',true,user.user_id,-1)">{{user.after_tolerance_percent}}%</span>
                              </td>
                              <td *ngIf="user.log_count!=0"><span (click)="readCountData('All',true,user.user_id,-1)"
                                  class="jelly-bean bean pending">{{user.approved_count+user.rejected_count}} / {{user.total}}</span></td>
                              <td *ngIf="user.log_count==0">{{user.log_count}}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div *ngIf="bestUsers.length==0 && !common.loading" style="margin-left: 40%;">
                  <app-data-not-found></app-data-not-found>
                </div>


              </div>

              <div class="col-md-6 mb-0 pr-3 pl-1">
                <div class="card table-card" style="max-height: 465px;">
                  <div class="card-header">
                    <div class="header-block">
                      <h3 class="card-title">{{"Worst Users" | translate}}</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Completed</th>
                            <th>Rejected</th>
                            <th>Before Time</th>
                            <th>On Time</th>
                            <th>After Time</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let user of worstUsers;let i=index">
                            <tr>
                              <td>{{user.user_name}}</td>
                              <td><span
                                  (click)="readCountData('Approved',true,user.user_id,-1)">{{user.approved_percent}}%</span>
                              </td>
                              <td><span class="jelly-bean bean delay"
                                (click)="readCountData('Rejected',true,user.user_id,-1)">{{user.rejected_percent}}%</span>
                            </td>
                              <td><span
                                  (click)="readCountData('before',true,user.user_id,-1)">{{user.before_tolerance_percent}}%</span>
                              </td>
                              <td><span
                                  (click)="readCountData('in',true,user.user_id,-1)">{{user.in_tolerance_percent}}%</span>
                              </td>
                              <td><span
                                  (click)="readCountData('after',true,user.user_id,-1)">{{user.after_tolerance_percent}}%</span>
                              </td>
                              <td *ngIf="user.log_count!=0"><span (click)="readCountData('All',true,user.user_id,-1)"
                                  class="jelly-bean bean pending">{{user.approved_count+user.rejected_count}} / {{user.total}}</span></td>
                              <td *ngIf="user.log_count==0">{{user.log_count}}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div *ngIf="worstUsers.length==0 && !common.loading" style="margin-left: 40%;">
                  <app-data-not-found></app-data-not-found>
                </div>
              </div>
            </div>
          </div>

          <div class="checklist_history">
            <app-checklist-history [report]="false"></app-checklist-history>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

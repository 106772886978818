import { Component, OnInit } from '@angular/core';
import { ApiService } from '../@core/services/api.service';
import { CommonService } from '../@core/services/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
})
export class LogoutModalComponent implements OnInit {
  title = '';
  description = '';
  btn1 = 'Confirm';
  btn2 = 'Cancel';

  constructor(
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal
  ) {
    if (this.common.params) {
      this.title = this.common.params.title
        ? this.common.params.title
        : 'Confirm';
      this.description = this.common.params.description || 'Are you sure?';
      this.btn1 = this.common.params.btn1 || 'Confirm';
      this.btn2 = this.common.params.btn2 || 'Cancel';
    }
  }

  ngOnDestroy() {}
  ngOnInit() {}

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }
}

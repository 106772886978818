<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body stageType-form">
      <div class="tab_section">
        <div class="tab" *ngFor="let tabitem of tabData" [class.active]="activeSection == tabitem.value"
          (click)="onTabChange(tabitem.value)">
          <p>{{tabitem.tabname|translate}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'User Department Mapping' | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">


              <!-- <section class="open_sec"> -->
                <!-- <div class="page_design"> -->
                  <div class="row tab_sec">
                    <div class="col-md-12 date_div">

                      <div class="row">
                        <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                          <label for="my-input" class="required">{{"Department Scope" | translate}}</label>
                          <select name="status_scope" id="#status_scope" class="form-control"
                            [(ngModel)]="departmentScope">
                            <option selected="GLOBAL" value="GLOBAL">GLOBAL</option>
                            <option value="COMPANY">COMPANY</option>
                            <option value="PLANT">PLANT</option>
                          </select>
                        </div>

                        <div *ngIf="departmentScope != 'GLOBAL'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                          <label for="my-input">{{"company" | translate}}</label>
                          <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required"
                            [data]="companyData" display="companyName" placeholder="Choose Company"
                            [preSelected]="{companyName:companyName}">
                          </ps-auto-suggestion>
                          <div class="error" *ngIf="companyValidator && companyValidator.error">
                            <span>{{companyValidator.msg}} </span>
                          </div>
                        </div>

                        <div *ngIf="(departmentScope == 'PLANT')  || (role.user_role == 'COMPANYADMIN')"
                          class="form-group col-xl-3 col-lg-6 col-sm-12">
                          <label for="my-input">{{"plant" | translate}}</label>
                          <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantData"
                            display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                          </ps-auto-suggestion>
                          <div class="error" *ngIf="plantValidator && plantValidator.error">
                            <span>{{plantValidator.msg}} </span>
                          </div>
                        </div>

                        <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                          <label for="my-input" class="required"> {{"Department" | translate}} </label>
                          <ps-auto-suggestion (onSelected)="selectedDepartment($event)" [data]="deptList"
                            display="dept_name" placeholder="Choose Department"
                            [preSelected]="{dept_name:selectedDeptName}" [readonly]="isEdit">
                          </ps-auto-suggestion>
                          <div class="error" *ngIf="deptValidator && deptValidator.error">
                            <span>{{deptValidator.msg}} </span>
                          </div>
                        </div>

                        <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="activeSection == 'user'">
                          <label for="my-input" class="required"> {{"User" | translate}} </label>
                          <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedUsers($event)"
                            [data]="userList" display="name" placeholder="Choose User"
                            [preSelected]="selectedUserNames">
                          </ps-auto-suggestion>
                          <div class="error" *ngIf="userValidator && userValidator.error">
                            <span>{{userValidator.msg}} </span>
                          </div>
                        </div>


                        <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="activeSection == 'staff'">
                          <label for="my-input" class="required"> {{"Staff" | translate}} </label>
                          <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedStaff($event)" [data]="userList"
                            display="staff_name" placeholder="Choose Staff" [preSelected]="selectedStaffNames">
                          </ps-auto-suggestion>
                          <div class="error" *ngIf="userValidator && userValidator.error">
                            <span>{{userValidator.msg}} </span>
                          </div>
                        </div>

                        <div class="form-group action-block">
                          <div class="btn-block">
                            <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                              translate}}</button>
                            <button class="btn btn-warning" (click)="reset()">{{"reset" | translate}}</button>
                            <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | translate}}
                            </button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <!-- <div class="row">
                    <div class="col-md-12">
                      <div class="card"> -->
                        <div class="card-header">
                          <div class="header-block">
                            <h3 class="card-title">
                              {{"User Department Mapping List" | translate}}
                            </h3>
                          </div>
                        </div>

                        <div class="card-body">
                          <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead class="thead-light">
                              <tr>
                                <th>{{"serial_no" | translate}}</th>
                                <th>{{"Department Name" | translate}}</th>
                                <th>{{ activeSection === 'user' ? 'Users' : 'Staff' | translate }}</th>
                                <th>{{"Plant" | translate}}</th>
                                <th>{{"Company" | translate}}</th>
                                <th class="action">{{"action" | translate}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let row of groupedDataByDeptName | keyvalue; let i = index">
                                <td>{{ i+1 }}</td>
                                <td>{{row.key}}</td>
                                <td>{{formatNames(row.value)}}</td>
                                <td>{{row.value[0]?.plant_name}}</td>
                                <td>{{row.value[0]?.company_name}}</td>
                                <td class="action">
                                  <div class="btn-block">
                                    <button class="btn btn-primary" (click)="view(row)">{{"view" | translate}}</button>
                                    <button class="btn btn-success" (click)="edit(row)">{{"edit" | translate}}</button>
                                    <button class="btn btn-danger" (click)="confirmDelete(row)">{{"delete" |
                                      translate}}</button>
                                  </div>
                              </tr>
                            </tbody>
                          </table>
                        </div>
<!--
                      </div>
                    </div>
                  </div> -->



                <!-- </div> -->
              <!-- </section> -->







            </div>

          </div>
        </div>
      </div>



    </div>
  </div>
</div>

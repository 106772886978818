<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Method Management'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input">{{('method'|customTranslate)+' '+('type'|customTranslate)}}</label>
                  <select class="form-control" id="methodType" name="methodType" [(ngModel)]="methodType" [value]=0>
                    <option [value]=0>Local</option>
                    <option [value]=1>Global</option>
                  </select>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12"
                  *ngIf="role.user_role == 'AXESTRACK' && methodType == 0">
                  <label for="my-input" class="required">{{'select_company'|customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectCompany($event)" [data]="companyList" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyNameValidator && companyNameValidator.error">
                    <span>{{companyNameValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12"
                  *ngIf="role.user_role == 'AXESTRACK' && methodType == 0">
                  <label for="my-input" class="required">{{'select_plant'|customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectPlant($event)" [data]="plantList" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{plantNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{('method'|customTranslate)+' '+('name'|customTranslate)}}</label>
                  <input class="form-control" type="text" [(ngModel)]="methodName">
                  <div class="error" *ngIf="methodNameValidator && methodNameValidator.error">
                    <span>{{methodNameValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="sel1" class="required">{{'method_execution'|customTranslate}}</label>
                  <select class="form-control" id="methodExecution" [(ngModel)]="methodExecution">
                    <option [value]=0>Push Method</option>
                    <option [value]=1>Pull Method</option>
                    <option [value]=2>Entry Method</option>
                    <option [value]=3>Commmunication Method</option>
                    <option [value]=4>Verification Method</option>
                    <option [value]=5>Username Generation Method</option>
                    <option [value]=6>Auto Stage Completion Method</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{'status'|customTranslate}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="status" [(ngModel)]="status" id="status"
                      [value]=1>
                    <label for="status">
                      {{'active'|customTranslate}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="status" [(ngModel)]="status" id="status"
                      [value]=0>
                    <label for="status">
                      {{'inactive'|customTranslate}}
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-warning" (click)="reset()"> {{'reset'|customTranslate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"
                    *ngIf="userService.permissionMap.get('add_method_management_access')==true"> {{btnTxt|customTranslate}}
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive pt-4">
                    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>{{'serial_no'|customTranslate}}</th>
                          <th>{{('method'|customTranslate)+' '+('name'|customTranslate)}}</th>
                          <th>{{'plant_name'|customTranslate}}</th>
                          <th>{{'company_name'|customTranslate}}</th>
                          <th>{{('method'|customTranslate)+' '+('type'|customTranslate)}}</th>
                          <th>{{'status'|customTranslate}}</th>
                          <th class="action">{{'Actions'|customTranslate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of methodsList;index as i">
                          <td>{{i+1}}</td>
                          <td> {{item.methodName}}</td>
                          <td>{{item.plantName?item.plantName : "-"}}</td>
                          <td> {{item.companyName ? item.companyName : "-"}}</td>
                          <td>{{item.methodType == 0 ? 'Local' : 'Global'}}</td>
                          <td>{{item.status == 1 ? 'Active' : 'Inactive'}}</td>
                          <td class="action">
                            <div class="btn-block">
                              <button class="btn btn-primary" (click)="viewMethod(item)"
                                *ngIf="userService.permissionMap.get('view_method_management_access')==true">
                                {{'view'|customTranslate}} </button>
                              <button class="btn btn-success" (click)="editMethod(item)"
                                *ngIf="(role.user_role == 'AXESTRACK' || item.methodType == 0) && userService.permissionMap.get('edit_method_management_access')==true">
                                {{'edit'|customTranslate}} </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

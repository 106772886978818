export interface TableColumns {
    columnLabel: string;
    columnName: string;
    columnFormatter?(column: TableColumns, index: number): string;
    dataFormatter?(row: any, column: TableColumns, index: number): string;
    extraData?: any;
    dataActions?: TableActions[];
    headerActions?: TableActions[];
}

export interface TableActions {
    actionName: string | COMMON_ACTION_BUTTON | COMMON_TRIPS_ACTION_BUTTON;
    actionFormatter(column: TableColumns, row: any, index: number): string;
    handleClick?: TableActionCallback;
    handleVisibility?: TableActionCallback;
    handleMouseOver?: TableActionCallback;
    handleMouseLeave?: TableActionCallback;
}

export interface TableOptions {
    defaultLength: number;
    exportColumns: number;
    tableTitle: string;
    orderColumnIndx: number;
    order: COLUMN_ORDER;
}

export enum COLUMN_ORDER {
    ASC = 'asc',
    DESC = 'desc'
}

export enum TABLE_TYPE {
    DATATABLE,
    HTMLTABLE
}

export type TableActionCallback = (column: TableColumns, row: any, index: number, actionName: string | COMMON_ACTION_BUTTON | COMMON_TRIPS_ACTION_BUTTON) => void | boolean;

export enum COMMON_ACTION_BUTTON {
    VIEW,
    DELETE,
    EDIT,
    BAN,
    UNBAN
}

export enum COMMON_TRIPS_ACTION_BUTTON {
    TRIP_DETAIL,
    TRIP_REMARK,
    TRIP_TRACKING
}
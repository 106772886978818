<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{ "visitor_admin_dashboard" | customTranslate }}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="visitor_admin_dash">
                <div class="top_row">
                  <div class="graph_sec">
                    <div class="subtitle">{{ "department_wise_visits" | customTranslate }}</div>
                    <div *ngIf="!this.deptWiseData.length" class="text-center mt-1 fade-in no-assets-tracked">{{"no_department_wise_visits"| customTranslate}}</div>
                    <div class="dept_row">
                      <div class="chart_img">
                        <div #deptChartContainer></div>
                      </div>

                      <div class="dept_boxes">
                        <div class="dept_box" *ngFor="let row of deptWiseData" (click)="getDeptGrpWisevisitordata(row.dept_id,'Department Data','department')">
                          <i class="fa fa-circle" [style.color]="deptColors[row.dept_name]"></i><span
                            class="small_num">{{row.visitor_count}}</span>
                          <div class="small_text">{{row.dept_name}}</div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="card_sec">
                    <div class="single_card" *ngFor="let item of cardData;" (click)="getStatusWisevisitordata(item.cardStatus,'Visit Details')">
                      <div class="left_sec">
                        <img [src]="item.cardIcon">
                      </div>

                      <div class="right_sec">
                        <div class="text">{{item.cardName | customTranslate}}</div>
                        <div class="number">{{statusWiseVisitorCount[item.cardKey]}}</div>
                      </div>
                    </div>
                  </div>

                  <div class="graph_sec">
                    <div class="subtitle">{{ "group_wise_visits" | customTranslate }}</div>
                    <div *ngIf="!this.grpWiseData.length" class="text-center mt-1 fade-in no-assets-tracked">{{"no_group_wise_visits"| customTranslate}}</div>
                    <div class="dept_row">
                      <div class="chart_img">
                        <div #grpChartContainer></div>
                      </div>

                      <div class="dept_boxes">
                        <div class="dept_box" *ngFor="let row of grpWiseData" (click)="getDeptGrpWisevisitordata(row.group_id,'Group Data','group')">
                          <i class="fa fa-circle" [style.color]="grpColors[row.group_name]"></i><span
                            class="small_num">{{row.visitor_count}}</span>
                          <div class="small_text">{{row.group_name}}</div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="user_dash_row" *ngIf="userService.permissionMap.get('view_alert_card_access')">
                  <!-- Alerts overview section -->
                  <div class="card">
                    <div class="card-header">
                      <div class="header-block">
                        <h2 class="card-title">{{"alerts_overview" | customTranslate}}</h2>
                      </div>
                    </div>

                    <div class="card-body">
                      <div class="row m-0">
                        <div class="col-md-3 m-0 pl-0 pr-0">
                          <div id="alert-donut"> </div>
                        </div>

                        <div class="col-md-3 m-0 pl-1 pr-1">
                          <div class="tab_sec" style="height:100%">
                            <div class="tab_card" *ngFor="let cards of groupedDataByType; index as i"
                              [class.active]="activeAlert == cards.type_name" (click)="selectedAlert(cards,i)">

                              <div class="gif_icon" [ngStyle]="{ 'background-color':cards.type_color }">
                                <img src="assets/images/alert-gif.gif" />
                              </div>

                              <div class="alert-type">
                                <p>{{cards.type_name}}</p>
                              </div>

                              <div class="alert-type-count" [ngStyle]="{ 'color': 'darken('+cards.type_color+',10)' }">
                                {{ cards.type_name_count }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 m-0 pl-0 pr-0">
                          <div class="table_section" style="height:100%">
                            <div class="table-responsive">
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>{{"alert_name" | customTranslate}}</th>
                                    <th>{{"alert_on" | customTranslate}}</th>
                                    <th>{{"violation" | customTranslate}}</th>
                                    <th>{{"created_at" | customTranslate}}</th>
                                    <th>{{"action" | customTranslate}}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let calert of cAlertArray; let i = index">
                                    <td>{{calert.alert_title}}</td>
                                    <td class="btn red_clr">{{calert.alert_condition}}</td>
                                    <td>{{calert.ranges}}</td>
                                    <td>{{calert.created_at}}</td>
                                    <td><i class="fa fa-info-circle" style="font-size:16px !important; border:none;color:#0f50a3 " (click)="viewAlert(calert.alert_data)">
                                    </i></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="most_visit">
                  <div class="subtitle">{{MostVisitTitle | customTranslate}}</div>
                  <div class="tab_section">

                    <div class="tab_btnss">
                      <div class="tab_btn" [class.active]="tabvalue == 'staff'" (click)="changeTab('staff')">{{"staff" | customTranslate}}</div>
                      <div class="tab_btn" [class.active]="tabvalue == 'visitor'" (click)="changeTab('visitor')">{{"visitor" | customTranslate}}</div>
                    </div>

                    <div class="tab_content">
                      <div class="staff_visitor">
                        <div class="left_tab_sec">
                          <div *ngFor="let data of mostVisitData;let i =index " class="people_row" (click)="loadChart(data['graph_data']); activeSection=i" [class.active]="activeSection == i">
                            <div class="name"><i class="fa fa-user"></i>{{data.name}}</div>
                            <div class="total_visit">{{"Total Visits" | customTranslate}} <span>{{data.count}}</span></div>
                          </div>
                        </div>

                        <div class="right_content_sec">
                          <div class="bar_chart">
                            <canvas id="visitorsChart"></canvas>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="user_dash_row">
                  <div class="card">
                    <div class="card-header">
                      <div class="header-block">
                        <h3 class="card-title" style="font-weight: 600;font-size: 16;text-transform: none;">
                          {{"stage_wise_tat" | customTranslate}}
                        </h3>
                      </div>
                      <div class="input_class">
                        <div class="form-group">
                          <div style="display:flex">
                            <label style="font-size: 13px;margin-right: 3px;margin-bottom: 0px;">{{'select_process'|customTranslate}} :</label>
                          <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="allProcess"
                            display="processName" placeholder="Select Process" [preSelected]="{processName:parkingDefaultProcess}">
                          </ps-auto-suggestion>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-body">
                        <canvas  width="1000" height="300px" id="stagewisetat" >
                        </canvas>
                    </div>

                  </div>
                </div>
                <div class="most_visit">
                  <div class="subtitle">Visitor Tracking</div>
                  <app-user-tracking-view [staffType]="'Visitor'"></app-user-tracking-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

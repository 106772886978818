<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"Approval-Flow Management" | customTranslate}}
            </h3>
          </div>
        </div>


        <div class="card-body">
          <div class="row" style="margin-bottom:1%">
            <div class="col-xl-4 col-lg-6 col-sm-12">
              <label style="width: 250px;" class="required">{{"Approval-Flow Title" | customTranslate}}</label>
              <input type="text" name="alertTitle" class="form-control" placeholder="Approval-Flow Title"
                [(ngModel)]="approvalFlow.approvalFlowName">
              <div class="error" *ngIf="approvalFlowlNameValidator && approvalFlowlNameValidator.error">
                <span>{{approvalFlowlNameValidator.msg}} </span>
              </div>
            </div>
            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
              <label>{{"Approval-Flow Description" | customTranslate}}</label>
              <input type="text" name="alertTitle" class="form-control" placeholder="Approval-Flow Description"
                [(ngModel)]="approvalFlow.approvalFlowDesc">
            </div>

            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
              <label for="" class="d-block" class="required"> {{'status'|customTranslate}} </label>
              <div class="form-check-inline">
                <input class="form-check-input" type="radio" name="approvalstatus_1"
                  [(ngModel)]="approvalFlow.approvalFlowStatus" id="approvalstatus_1" value="ACTIVE" checked>
                <label for="approvalstatus_1">
                  {{'active'|customTranslate}}
                </label>
              </div>
              <div class="form-check-inline">
                <input class="form-check-input" type="radio" name="approvalstatus_2"
                  [(ngModel)]="approvalFlow.approvalFlowStatus" id="approvalstatus_2" value="INACTIVE">
                <label for="approvalstatus_2">
                  {{'inactive'|customTranslate}}
                </label>
              </div>
            </div>

          </div>

          <div class="row modal-body" id="action_id" style="background-color: #ededed; margin: 0;border-radius: 5px;">
            <div style="width: 100%;">
              <label for="my-input" class="mb-3">{{"Levels of Approval-Flow" | customTranslate}}</label>
              <div>
                <ng-container *ngFor="let level of approvalFlow.levels;index as i">
                  <div class="accordionn">
                    <div class="accordion_sec">
                      <div class="click_accordion" (click)="hideShow[i] = !hideShow[i];">
                        <label for="my-input">{{"Level" | customTranslate}} {{i+1}}
                          <span style="padding:'5px'">{{'Approval' | customTranslate}}</span></label>
                        <i class="fas fa-caret-down"></i>
                      </div>

                      <div class="accordion_open" *ngIf="hideShow[i]==true">
                        <div class="row" style="padding: 10px;">
                          <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label class="required">{{"Level Name" | customTranslate}}</label>
                            <input type="text" name="alertTitle" class="form-control" placeholder="Level Name"
                              [(ngModel)]="level.levelName">
                            <div class="error"
                              *ngIf="levelNameValidator.length>0 && levelNameValidator[i] && levelNameValidator[i].error">
                              <span>{{levelNameValidator[i].msg}} </span>
                            </div>
                          </div>
                          <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label>{{"Level Description" | customTranslate}}</label>
                            <input type="text" name="alertTitle" class="form-control" placeholder="Level Description"
                              [(ngModel)]="level.levelDesc">
                          </div>

                        </div>
                        <div class="accordion_box" *ngFor="let sublevel of level.entities;index as j">
                          <div class="row">
                            <div class="form-group col-xl-2 col-lg-4 col-sm-12">
                              <label class="required">{{"Select Entity Type" | customTranslate}}</label>
                              <ps-auto-suggestion (onSelected)="selectEntityType($event,i,j)" [data]="entityType"
                                display="name" placeholder="Choose Entity Type"
                                [preSelected]="{name:sublevel.entityType}">
                              </ps-auto-suggestion>
                              <div class="error"
                                *ngIf="entityTypeValidator.length>0 && entityTypeValidator[i][j] && entityTypeValidator[i][j].error">
                                <span>{{entityTypeValidator[i][j].msg}} </span>
                              </div>
                            </div>

                            <div class="form-group col-xl-2 col-lg-4 col-sm-12">
                              <label class="required">{{"Select Entity Grouping" | customTranslate}}</label>
                              <ps-auto-suggestion (onSelected)="selectSubEntity($event,i,j)" [data]="(sublevel.entityType=='GROUP'||sublevel.entityType=='DEPARTMENT')?subEntity2:subEntity1"
                                display="value" placeholder="Choose Entity Grouping"
                                [preSelected]="{value:sublevel.subEntity}">
                              </ps-auto-suggestion>
                              <div class="error"
                                *ngIf="subEntityValidator.length>0 && subEntityValidator[i][j] && subEntityValidator[i][j].error">
                                <span>{{subEntityValidator[i][j].msg}} </span>
                              </div>
                            </div>

                            <div class="form-group col-xl-2 col-lg-4 col-sm-12"
                              *ngIf="(sublevel.subEntity=='FIXED' && sublevel.entityType=='GROUPHEAD') || sublevel.entityType=='GROUP'">
                              <label class="required">{{"select" | customTranslate}} {{'group'|customTranslate}}</label>
                              <ps-auto-suggestion (onSelected)="selectEntity($event,i,j,'GROUP')" [data]="groupList"
                                display="groupName" placeholder="Choose Group"
                                [preSelected]="{groupName:sublevel.entityName}">
                              </ps-auto-suggestion>
                              <div class="error"
                                *ngIf="entityIdValidator.length>0 && entityIdValidator[i][j] && entityIdValidator[i][j].error">
                                <span>{{entityIdValidator[i][j].msg}} </span>
                              </div>
                            </div>

                            <div class="form-group col-xl-2 col-lg-4 col-sm-12"
                              *ngIf="(sublevel.subEntity=='FIXED' && sublevel.entityType=='DEPARTMENTHEAD') || sublevel.entityType=='DEPARTMENT'">
                              <label class="required">{{"select" | customTranslate}} {{'department'|customTranslate}}</label>
                              <ps-auto-suggestion (onSelected)="selectEntity($event,i,j,'DEPARTMENT')" [data]="departmentList"
                                display="dept_name" placeholder="Choose Department"
                                [preSelected]="{dept_name:sublevel.entityName}">
                              </ps-auto-suggestion>
                              <div class="error"
                                *ngIf="entityIdValidator.length>0 && entityIdValidator[i][j] && entityIdValidator[i][j].error">
                                <span>{{entityIdValidator[i][j].msg}} </span>
                              </div>
                            </div>

                            <div class="form-group col-xl-2 col-lg-4 col-sm-12"
                              *ngIf="sublevel.subEntity=='FIXED' && sublevel.entityType=='USER'">
                              <label class="required">{{"select" | customTranslate}} {{'user'|customTranslate}}</label>
                              <ps-auto-suggestion (onSelected)="selectEntity($event,i,j,'USER')" [data]="userList" display="name"
                                placeholder="Choose User" [preSelected]="{name:sublevel.entityName}">
                              </ps-auto-suggestion>
                              <div class="error"
                                *ngIf="entityIdValidator.length>0 && entityIdValidator[i][j] && entityIdValidator[i][j].error">
                                <span>{{entityIdValidator[i][j].msg}} </span>
                              </div>
                            </div>


                            <div class="form-group col-xl-2 col-lg-4 col-sm-12" *ngIf="level.entities.length>1 && j==0">
                              <label for="" class="d-block" class="required"> {{'Join Condition'|customTranslate}} </label>
                              <div class="form-check-inline">
                                <input class="form-check-input" type="radio" name="{{'joinCond_1_' + i}}"
                                  [(ngModel)]="level.joinCondition" id="{{'joinCond_1_' + i}}" value="AND" checked>
                                <label for="{{'joinCond_1_' + i}}">
                                  {{'AND'|customTranslate}}
                                </label>
                              </div>
                              <div class="form-check-inline">
                                <input class="form-check-input" type="radio" name="{{'joinCond_2_' + i}}"
                                  [(ngModel)]="level.joinCondition" id="{{'joinCond_2_' + i}}" value="OR">
                                <label for="{{'joinCond_2_' + i}}">
                                  {{'OR'|customTranslate}}
                                </label>
                              </div>
                            </div>

                            <div class="form-group col-xl-2 col-lg-4 col-sm-12">
                              <div class="plus_minus">
                                <button *ngIf="j==level.entities.length-1"
                                  style="border: 1px solid #00b400;margin-top: 28px;"><i class="fa fa-plus"
                                    aria-hidden="true" (click)="addSublevel(i)"></i></button>
                                <button *ngIf="j!=0" style="border: 1px solid #f90000;margin-top: 28px;"><i
                                    class="fa fa-minus" aria-hidden="true" (click)="minusSublevel(i,j)"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" *ngIf="showTat[i] || level.escallationCondition!=null"
                          style="padding: 10px;align-items: center;">
                          <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label>{{"Expected TAT" | customTranslate}}</label>
                            <span class="badge info"
                              style="background-color:aliceblue;border:solid 1px black">{{level.expTat}}</span>
                          </div>
                          <div class="col-xl-3 col-lg-6 col-sm-12">
                            <label>{{"Escalation Condition" | customTranslate}}</label>
                            <span class="pill"
                              [ngClass]="{'bg-blue-200 text-blue-800':level.escallationCondition=='BOTH','bg-green-200 text-green-800':level.escallationCondition=='APPROVE','bg-red-200 text-red-800':level.escallationCondition=='REJECT'}">{{level.escallationCondition}}</span>
                          </div>
                          <div class="col-xl-3 col-lg-6 col-sm-12">
                            <button class="btn btn-primary" data-toggle="modal" data-target="#exampleModal"
                              (click)="levelIndex = i;editModalFlag=true">{{"update"|customTranslate}}</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="increase_row_icons">
                      <div class="plus_minus">
                        <button *ngIf="i==approvalFlow.levels.length-1" style="border: 1px solid #00b400;" (click)="levelIndex = i;"
                          data-toggle="modal" data-target="#exampleModal"><i class="fa fa-plus" aria-hidden="true"
                            ></i></button>
                        <button *ngIf="i!=0" style="border: 1px solid #f90000;"><i class="fa fa-minus"
                            aria-hidden="true" (click)="minusLevel(i)"></i></button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="form-group action-block mt-3">
            <div class="btn-block">
              <button type="submit" class="btn btn-muted" (click)="common.backClicked()">
                {{"back" | customTranslate}} </button>
              <button class="btn btn-warning" (click)="resetWorkType()"> {{"reset" | customTranslate}} </button>
              <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{'save' | customTranslate}} </button>
            </div>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Approval-Flow List" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"serial_no" | customTranslate}}</th>
                    <th>{{"Approval-Flow Title" | customTranslate}}</th>
                    <th>{{"Approval-Flow Description" | customTranslate}}</th>
                    <th>{{"status" | customTranslate}}</th>
                    <th>{{"Level" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of responseData let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.flow_title}}</td>
                    <td>{{row.flow_description}}</td>
                    <td>{{row.status}}</td>
                    <td><span class="badge badge-success"
                        *ngFor="let label of row.levelData">{{label.levelRank + ' - ' + label.levelName}}</span></td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-success" (click)="edit(row)">{{"edit" | customTranslate}}</button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)">{{"delete" | customTranslate}}</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" #detail id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 100%">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{'Add Level Configurations'|customTranslate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="form-group col-xl-12 col-lg-12 col-sm-12">
            <label for="my-input" class="required">{{"Expected TAT" | customTranslate}} </label>
            <input type="time" name="alertTitle" class="form-control" placeholder="Alert Title"
              [(ngModel)]="approvalFlow.levels[levelIndex].expTat">
            <div class="error" *ngIf="tatValidator && tatValidator.error">
              <span>{{ tatValidator?.msg }}</span>
            </div>
          </div>
          <div class="form-group col-xl-6 col-lg-6 col-sm-12">
            <label for="" class="d-block" class="required"> {{'Escalation Condition'|customTranslate}} </label>
            <ps-auto-suggestion (onSelected)="approvalFlow.levels[levelIndex].escallationCondition=$event.value"
              [data]="escalationCondition" display="value" placeholder="Move To Next Level On"
              [preSelected]="{value:approvalFlow.levels[levelIndex].escallationCondition}">
            </ps-auto-suggestion>
            <div class="error" *ngIf="escallationConditionValidator && escallationConditionValidator.error">
              <span>{{ escallationConditionValidator?.msg }}</span>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="saveModal()">Save changes</button>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"stages" | customTranslate}}
            </h3>
          </div>
        </div>

        <div class="card-body">
          <div *ngIf="processAction.length">
            <mat-tab-group animationDuration="10ms" (selectedTabChange)="getEvent($event)">
              <mat-tab [label]="item.name" *ngFor="let item of processAction" class="mb-3">
                <div class="row">
                  <div class="col-md-12 option-container">
                  </div>
                </div>
                <table class="custom-table automation-table" *ngIf="process.length">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div class="d-flex align-items-center justify-content-center">{{"order" | customTranslate}}</div>
                      </th>
                      <th scope="col">
                        <div class="d-flex align-items-center justify-content-center">{{"previous_stage" | customTranslate}}
                        </div>
                      </th>
                      <th scope="col">
                        <div class="d-flex align-items-center justify-content-center">{{"current_stage" | customTranslate}}
                        </div>
                      </th>
                      <th scope="col">
                        <div class="d-flex align-items-center justify-content-center">{{"next_stage" | customTranslate}}</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
                    <tr *ngFor="let item of process let i = index" cdkDrag cdkDragLockAxis="y" class="example-list">
                      <th class="col-xs no-bg">
                        <div class="drag-handle">
                          {{ item.temp_id }}
                        </div>
                      </th>
                      <td class="col-md stage column first_td">
                        <div class="multiselect column_row1">
                          <ng-select (remove)="onRemove($event,item,'prev',i)" [hideSelected]="true" [items]="itemArray"
                            bindValue="id" bindLabel="name" (add)="anAdd($event, item,'prev')"
                            placeholder="Select stage" [closeOnSelect]="true" dropdownPosition="bottom" appendTo="body"
                            (open)="sortData(i)" [multiple]="true" [(ngModel)]="item.preState">
                          </ng-select>
                        </div>
                        <div class="multiselect column_row2">
                          <ng-select (remove)="onRemove($event,item,'prevRejected',i)" [hideSelected]="true"
                            [items]="itemArray" bindValue="id" bindLabel="name"
                            (add)="rejectAdd($event, item,'prevRejected')" placeholder="Select stage"
                            [closeOnSelect]="true" dropdownPosition="bottom" appendTo="body" (open)="sortData(i)"
                            [multiple]="true" [(ngModel)]="item.preRejectedStateArray">
                          </ng-select>
                        </div>

                        <div class="on_approve">
                          <p style="background-color: #9fe89f;">{{"on_approve" | customTranslate}}</p>
                        </div>

                        <div class="on_reject">
                          <p style="background-color: #f09292;">{{"on_reject" | customTranslate}}</p>
                        </div>

                      </td>
                      <td class="col-md column">
                        <div class="column_row1 align-items-center" [ngStyle]="{'padding-bottom': item.autoOut ? '30px' : 'unset'}">
                          <label class="stage-header-label"> {{item.name}}</label>
                        </div>

                        <div class="common_block">
                          <div class="container">
                            <div class="tat-block">
                              <label for="">{{"tat"|customTranslate}}</label>
                              <input type="number" class="form-control" [(ngModel)]="item.tat"
                                (ngModelChange)="this.tatUpdate.next($event)">
                            </div>
                            <div class="auto-check">
                              <div style="margin-left: 2px;">
                              <label class="check-label">{{"auto_in"|customTranslate}}</label>
                              <input class="form-control" type="checkbox" [(ngModel)]="item.autoIn"
                                (ngModelChange)="changeStagefunctionality(item,true)">
                              </div>

                              <div style="margin-left: 2px;">
                              <label class="check-label">{{"auto_out"|customTranslate}}</label>
                              <input class="form-control" type="checkbox" [(ngModel)]="item.autoOut"
                                (ngModelChange)="changeStagefunctionality(item,true)">
                              </div>

                              <div style="margin-left: 2px;">
                                <label class="check-label">{{"quick_out"|customTranslate}}</label>
                                <input class="form-control" type="checkbox" [(ngModel)]="item.quickOut"
                                  (ngModelChange)="changeStagefunctionality(item,false)">
                                </div>
                            </div>

                          </div>
                          <div class="method_input" style="display: flex;justify-content: center;">
                            <div class="form-group" *ngIf="item.autoOut==true" style="width:90%">
                              <ps-auto-suggestion (onSelected)="onSelectedAutoMethod($event, item)"
                                [data]="autoMethodsList" display="methodName" placeholder="Choose Method"
                                [preSelected]="{methodName:getComepletionMethodName(item.autoCompletionMethodId)}"
                                [(ngModel)]="item.autoCompletionMethodId">
                              </ps-auto-suggestion>
                            </div>
                          </div>
                        </div>

                        <i class="fas fa-arrows-alt-v drag_drop_box"></i>

                        <div class="column_row2 align-items-center" [ngStyle]="{'padding-top': item.autoOut ? '30px' : 'unset'}">
                          <label class="stage-header-label"> {{item.name}} </label>
                        </div>

                        <i class="fas fa-clock tat_icon" title="InBetween TAT" (click)="modalShow(item)"></i>
                      </td>

                      <td class="col-md my-table column">
                        <div class="multiselect column_row1">
                          <ng-select [hideSelected]="true" (add)="anAdd($event, item,'next')"
                            (remove)="onRemove($event,item,'next',i)" [items]="itemArray" bindValue="id"
                            bindLabel="name" placeholder="Select stage" [closeOnSelect]="true" dropdownPosition="bottom"
                            appendTo="body" [multiple]="true" [(ngModel)]="item.lastState">
                          </ng-select>

                        </div>
                        <div class="multiselect column_row2">
                          <ng-select [hideSelected]="true" (add)="rejectAdd($event, item,'reject')"
                            (remove)="onRemove($event,item,'reject',i)" [items]="itemArray" bindValue="id"
                            bindLabel="name" placeholder="Select stage" [closeOnSelect]="true" dropdownPosition="bottom"
                            appendTo="body" [multiple]="true" [(ngModel)]="item.rejectedState">
                          </ng-select>

                        </div>
                      </td>
                    </tr>

                  </tbody>

                </table>
                <app-data-not-found *ngIf="!process.length"> </app-data-not-found>
              </mat-tab>
            </mat-tab-group>
          </div>
          <app-data-not-found *ngIf="!processAction.length && !common.loading"></app-data-not-found>
        </div>

      </div>
    </div>
  </div>
</div>


<div class="modal_design " *ngIf="openModal">
  <div class="modal_div">
    <div class="modal-header">
      <h5>InBetween TAT</h5>
      <button type="button" class="close"><span aria-hidden="true" (click)="modalHide()">×</span></button>
    </div>
    <div class="modal-body">
      <div class="betweentat_sec">
        <div class="vertical_div">
          <div class="div_title" style="background: #9fe89f;">
            <p>On Approve</p>
          </div>
          <div class="div_content">
            <table >
              <thead>
                <tr>
                  <th>From Stage</th>
                  <th>To Stage</th>
                  <th>TAT (in Min)</th>
                  <th>Vehicle Limit</th>
                </tr>
              </thead>
              <tbody *ngIf="lastStateArray.length">
                <tr *ngFor="let data of lastStateArray">
                  <td>{{modalData[0].name}}</td>
                  <td>{{data.name}}</td>
                  <td><input type="text" class="form-control" [value]="getSavedExpectedTat(1,data.id,'expectedTAT')"
                      (change)="getModalDataSave(1,data.id,$event)"></td>
                  <td><input type="text"  class="form-control" (change)="getModalDataSave(1,data.id,$event)"
                    [value]="getSavedExpectedTat(1,data.id,'vehicleLimit')"></td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="!lastStateArray || lastStateArray.length === 0" class="message">
              <p>Next stage not found!</p>
            </div>
          </div>
        </div>

        <div class="vertical_div">
          <div class="div_title" style="background: #f09292;">
            <p>On Reject</p>
          </div>
          <div class="div_content">
            <table>
              <thead>
                <tr>
                  <th>From Stage</th>
                  <th>To Stage</th>
                  <th>TAT</th>
                  <th>Vehicle Limit</th>
                </tr>
              </thead>
              <tbody *ngIf="rejectedStateArray.length">
                <tr *ngFor="let data of rejectedStateArray">
                  <td>{{modalData[0].name}}</td>
                  <td>{{data.name}}</td>
                  <td><input type="text" class="form-control" [value]="getSavedExpectedTat(0,data.id,'expectedTAT')"
                      (change)="getModalDataSave(0,data.id,$event)"></td>
                      <td><input type="text"  class="form-control" (change)="getModalDataSave(0,data.id,$event)"
                        [value]="getSavedExpectedTat(0,data.id,'vehicleLimit')"></td>
              </tbody>
            </table>
            <div *ngIf="!lastStateArray || lastStateArray.length === 0" class="message">
              <p>Next stage not found!</p>
            </div>
          </div>
        </div>
        <div class="btn-block" style="display:flex; justify-content: flex-end;">
          <button type="submit" class="btn btn-success mt-2" style="font-weight: bold;" (click)="saveModalData()"> Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

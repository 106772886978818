<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title" appEllipsisView>{{refData.vehicle.vehicle_rc_no}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="btn_tab">
    <button type="button" [class.active]="activeTab==1" class="" (click)="activeTab=1;">{{"custom" |
      customTranslate}}</button>
    <button type="button" [class.active]="activeTab==2" (click)="activeTab=2;">{{"recents" | customTranslate}}</button>
  </div>

  <div class="open_tab_sec" *ngIf="activeTab==1">
    <div class="field">
      <label for="my-input">{{'message'|customTranslate}}</label>
      <textarea class="field_class textarea" name="message" [(ngModel)]="message" id="message" type="textarea"
        rows="5"></textarea>
    </div>
    <div class="templates">
      <ng-container *ngFor="let suggestion of suggestions;">
        <span [class]="message == suggestion ? 'active-msg' : ''" (click)="message = suggestion">{{suggestion}}</span>
      </ng-container>
    </div>
  </div>

  <div class="open_tab_sec" *ngIf="activeTab==2">
    <div class="templates">
      <ng-container *ngFor="let suggestion of previousMsg;">
        <span [class]="message == suggestion ? 'active-msg' : ''" (click)="message = suggestion">{{suggestion}}</span>
      </ng-container>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-success" type="button" outline (click)="sendIvrCall()"
      style="cursor: pointer;">{{'call_driver'|customTranslate}}</button>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urltobase64'
})
export class Urltobase64Pipe implements PipeTransform {
  base64Url:any = '';
  async transform(fileurl:string) {
        return await this.getBase64ImageFromUrl(fileurl);
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

}

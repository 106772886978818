import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-dock-management',
  templateUrl: './dock-management.component.html',
  styleUrls: ['./dock-management.component.scss'],
})
export class DockManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(
    10,
    4,
    'Stage Destination Code Occupancy List'
  );
  destinationTat: any[] = [];
  order_name: any = '';
  selectedOrder: any[] = [];
  isEdit: boolean = false;
  editRowId: any[] = [];
  editDestinationId: any = null;
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  process: any[] = [];
  stages: any[] = [];
  stageName: any = '';
  dockCount: any = null;
  processName: any = '';
  buttonName = 'save';
  processValidator: any = null;
  dockCountValidator: any = null;
  stageValidator: any = null;
  processId: any = null;
  expectedTat: any[] = [];
  dockData: any[] = [];
  stageId: any = null;
  applyAllFilter: any = 1;
  processStageOrderId: any = null;
  dockAllotementPrefix: any = '';
  latestSavedTat: any = -1;
  latestSavedVehicleLimit: any = -1;
  value = false;
  applyAllDockTAT: Boolean = false;
  applyAllDockName: Boolean = false;
  applyAllVehicleLimit: Boolean = false;
  dockDataTable: any[] = [];
  currentEditData: any[] = [];

  constructor(
    public breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public table: TableService,
    private api: ApiService,
    private modalService: NgbModal,
    private alert: AlertService,
    private userService: UserService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Dock', url: null },
      { name: 'Dock Management', link: '/user/dock-management' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.getPlantSettingByPlantId();
  }

  ngOnInit(): void {
    this.getAllProcess();
    this.getDockData();
  }

  getAllProcess() {
    this.common.loading = true;
    this.api.get('process/get-all-process?processActive=true').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.process = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getStageByProcess(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        processId: this.processId,
        stageType: 'Dock Stage',
      };
      this.api.get('stage/stage_by_process', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.stages = res.data;
          resolve(this.stages);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(true);
        }
      );
    });
  }

  selectedProcess(event: any) {
    //this.processList=[];
    this.processName = event.processName;
    this.processId = event.processId;
    this.stageId = null;
    this.stageName = null;
    this.getStageByProcess();
  }

  createDock(event) {
    let arr = this.buttonName == 'update' ? this.dockData : [];
    let lenght = arr.length;
    if (arr.length < event.target.value) {
      for (var i = 0; i < event.target.value - lenght; i++) {
        arr.push({
          dockId: null,
          mappingId: null,
          status: 'ACTIVE',
          dockName: this.dockAllotementPrefix + (i + 1),
          vehicleLimit: 0,
          tat: '00:10',
          processStageOrderId: this.processStageOrderId,
        });
      }
    } else if (arr.length == event.target.value) {
      arr.forEach((res) => {
        res.status = 'ACTIVE';
      });
    } else {
      arr.slice(event.target.value).forEach((res) => {
        res.status = 'INACTIVE';
      });
    }
    this.dockData = arr;
  }

  reset() {
    this.processValidator = null;
    this.stageValidator = null;
    this.dockCountValidator = null;
    this.buttonName = 'save';
    this.processId = null;
    this.processName = '';
    this.isEdit = false;
    this.dockData = [];
    this.stages = [];
    this.dockCount = null;
    this.stageName = '';
    this.stageId = null;
  }

  save() {
    let params = this.dockData;
    this.common.loading = true;
    this.api.post('dock/save-dock-destination', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.buttonName = 'save';
          this.reset();
          this.getDockData();
          if (res.data.length) {
            this.alert.error(res.data[0]);
          } else {
            this.alert.success(res.message);
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  view(row?: any) {
    let newObj = {
      process_name: row.process_name,
      stage_destination_code: row.stage_destination_code,
      stage_name: row.stage_name,
    };
    this.common.params = {
      details: [newObj],
      title: 'Destination Stage Mapping Details',
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  string_to_array = function (str) {
    str = str.replace(/[\[\]']+/g, '');
    return str.trim().split(',');
  };

  async edit(row: any) {
    this.reset();
    this.currentEditData = row.dock_data;
    this.isEdit = true;
    this.buttonName = 'update';
    this.processId = row.process_id;
    this.processName = row.process_name;
    this.processStageOrderId = row.process_stage_order_id;
    await this.getStageByProcess();
    this.dockCount = row.dock_data.length;
    this.stageId = row.stage_id;
    this.stageName = row.stage_name;
    this.dockData = row.dock_data;
  }

  selectedStage(event: any) {
    this.stageName = event.stage_name;
    this.stageId = event.stage_id;
    this.processStageOrderId = event.order_id;
  }

  getPlantSettingByPlantId() {
    this.common.loading = true;
    this.api
      .get(
        'plant/get-plant-setting-by-plant-id?plantId=' +
          this.userService._loggedInUser.plantId
      )
      .subscribe(
        (response: any) => {
          this.common.loading = false;
          const res = response.data;
          this.dockAllotementPrefix = res[0].dockPrefix;
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  chnageAllData() {
    if (this.applyAllVehicleLimit || this.applyAllDockTAT) {
      this.dockData.forEach((res) => {
        res.vehicleLimit = this.applyAllVehicleLimit
          ? this.latestSavedVehicleLimit
          : res.vehicleLimit;
        res.tat = this.applyAllDockTAT ? this.latestSavedTat : res.tat;
        // res.dockName =  this.applyAllDockName ?  this.latestSavedTat : res.tat;
      });
    }
  }
  saveLatestData(vehicleLimit: any, tat: any) {
    this.latestSavedVehicleLimit =
      vehicleLimit == -1 ? this.latestSavedVehicleLimit : vehicleLimit;
    this.latestSavedTat = tat == -1 ? this.latestSavedTat : tat;
    this.chnageAllData();
  }

  getDockData() {
    this.common.loading = true;
    this.api.get('dock/get-dock-data').subscribe(
      (res: any) => {
        this.common.loading = false;
        this.dockDataTable = res.data;
        this.dockDataTable.forEach((res) => {
          res.dock_data = JSON.parse(res.dock_data);
        });
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  formValidator() {
    this.stageValidator = this.common.nullValidator(
      this.stageName,
      'Stage is Mandatory'
    );
    this.processValidator = this.common.nullValidator(
      this.processName,
      'Process is Mandatory'
    );
    this.dockCountValidator = this.common.nullValidator(
      this.dockCount,
      'Dock Count is Mandatory'
    );
    if (
      !this.stageValidator.error &&
      !this.processValidator.error &&
      this.buttonName == 'save' &&
      !this.dockCountValidator.error
    ) {
      this.save();
    } else if (
      !this.stageValidator.error &&
      !this.processValidator.error &&
      this.buttonName == 'update' &&
      !this.dockCountValidator.error
    ) {
      this.save();
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import lodash from 'lodash';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';

@Component({
  selector: 'app-department-user-mapping',
  templateUrl: './department-user-mapping.component.html',
  styleUrls: ['./department-user-mapping.component.scss']
})
export class DepartmentUserMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'User Department Mapping');
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getDepartmentList();
    this.getUserList();
    this.getAllUserDeptMapping();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }


  userList:any = [];
  deptList :any = [];
  id:number = null;
  selectedDeptId: number = null;
  selectedDeptName: string = null;
  selectedUserIds: any[] = [];
  selectedUserNames: any[] = [];
  isEdit: boolean = false;
  title = 'Add User Department Mapping';
  btn = 'Save';
  userDeptMappings:any = [];
  groupedDataByDeptName : any = [];

  companyId:number = null;
  plantId : number = null;
  companyName:String = null;
  plantName:String =  null;
  companyData: any = [];
  plantData: any = [];
  departmentScope: any = 'GLOBAL';
  activeSection: string = 'user';
  tabData: any = [
    { tabname: 'user', value: 'user' },
    { tabname: 'Staff', value: 'staff' },
  ];
  selectedStaffIds: any[] = [];
  selectedStaffNames: any[] = [];




  constructor(
    public userService: UserService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public modal: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public alert: AlertService,
    public role : RoleService,
    private plantService: PlantService,
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Department', url: null },
      { name: 'Department User Mapping', url: '/user/users' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }



  ngOnInit() {
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else {
      if (this.role.user_role != 'AXESTRACK') {
        this.getPlantById(this.userService._loggedInUser.companyId);
      }
    }
    this.getDepartmentList();
  }

  onTabChange(tabValue:any){
    this.reset();
    this.activeSection = tabValue
    this.activeSection == 'user' ? this.getUserList() : this.getStaffList();
    this.getAllUserDeptMapping();
  }

  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = null;
    this.selectedDeptId = null;
    this.selectedDeptName =null;
    this.selectedUserIds = [];
    this.selectedUserNames = [];
    this.activeSection  == 'user' ? this.getUserList() : this.getStaffList();
    this.getDepartmentList();
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.selectedDeptId = null;
    this.selectedDeptName =null;
    this.selectedUserIds = [];
    this.selectedUserNames = [];
    this.activeSection  == 'user' ? this.getUserList() : this.getStaffList();
    this.getDepartmentList();
  }


  selectedDepartment(event:any){
  this.selectedDeptId = event.dept_id;
  this.selectedDeptName =  event.dept_name;
}

  selectedUsers(event: any) {
    this.selectedUserIds = event.map((e) => e.userid);
  }

  selectedStaff(event: any) {
  console.log('event: ', event);
    this.selectedStaffIds = event.map((e) => e.staff_id);
    console.log('this.selectedStaffIds: ', this.selectedStaffIds);
  }

  getUserList() {
    let params = {
      companyId:this.companyId,
      plantId: this.plantId,
      userRole : this.role.user_role
    };
    this.common.loading = true;
    this.api.get('user/get-user-list', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.userList = res.data;
          console.log('this.userList: ', this.userList);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getStaffList() {
    let params = {
      companyId : this.companyId,
      plantId: this.plantId,
      allStaff : false
    };
    this.common.loading = true;
    this.api.get('staff/all-staff', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.userList = res.data;
          this.userList.forEach(user => {
            user.staff_name = user.first_name + ' ' + user.last_name;
          });

        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getDepartmentList() {
    this.common.loading = true;
    let params = {
      companyId : this.companyId,
      plantId : this.plantId,
      allDepartments: false
    };
    this.api.get('department/get-department-list',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.deptList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  saveUserDeptMapping() {
    this.common.loading = true;
    let params = {
      id: this.id,
      userIds: this.activeSection == 'user' ? this.selectedUserIds : this.selectedStaffIds,
      deptIds: [this.selectedDeptId],
      isEdit: this.isEdit,
      mappingType : this.activeSection.toUpperCase()
    };
    console.log('params: ', params);
      this.api.post('department/save-user-department-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alert.info(res.message);
          this.reset();
          this.isEdit = false;
          this.getAllUserDeptMapping();
          this.common.loading = false;
        }
      },
      (err) => {
        this.common.loading = false;
        console.error(err);
      }
    );
  }

  getAllUserDeptMapping() {
    this.common.loading = true;
    let params ={
      listType : this.activeSection.toUpperCase()
    }
    this.api.get('department/get-all-user-department-mapping',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.userDeptMappings = res.data;
          this.groupedDataByDeptName = lodash.groupBy(
            this.userDeptMappings,
            'dept_name'
            );
            console.log('this.groupedDataByDeptName: ', this.groupedDataByDeptName);
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  formatNames(dataArray: any): string {
    return this.activeSection =='user'?  dataArray.map((item) => item.user_name).join(', ') :  dataArray.map((item) => item.staff_name).join(', ');
  }

  reset() {
    this.selectedDeptId = null;
    this.selectedDeptName = null;
    this.selectedUserIds = [];
    this.selectedUserNames = [];
    this.deptValidator = null;
    this.userValidator = null;
    this.btn = 'Save';
    this.companyValidator = null;
    this.plantValidator = null;
    this.selectedStaffIds =[];
    this.selectedStaffNames = [];
    this.companyId=null;
    this.plantId=null;
    this.companyName = null;
    this.plantName = null;
  }

  view(row?: any) {
    let viewObj = {
      processStage: row.key,
      zone: this.formatNames(row.value),
      plant: row.value[0]?.plant_name,
    };
    this.common.params = {
      details: [viewObj],
      title: 'Zone Slot Mapping Details',
    };
    const activeModal = this.modal.open(ViewDetailsComponent, { size: 'lg' });
  }

  edit(row: any) {
  console.log('row: ', row);
    this.reset();
    this.common.gotoTop();
    this.btn = 'Update';
    this.isEdit = true;
    this.title = 'Edit User Department Mapping';
    this.id = row.value[0].id;
    this.selectedDeptId = row.value[0].dept_id;
    this.selectedDeptName = row.key;
    this.companyId = row.value[0].company_id;
    this.companyName = row.value[0].company_name;
    this.plantId = row.value[0].plant_id;
    this.plantName = row.value[0].plant_name;
    if(this.activeSection == 'user'){
      this.selectedUserIds = row.value.map((user) => user.user_id);
      this.selectedUserNames = row.value.map((user) => ({
        name: user.user_name,
        userid: user.user_id,
      }));
    }else{
      this.selectedStaffIds = row.value.map((staff) => staff.staff_id);
      this.selectedStaffNames = row.value.map((staff) => ({
        staff_name: staff.staff_name,
        staff_id: staff.staff_id,
      }));
    }
  }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete User Department Mapping ',
      description:
        `<b>&nbsp;` + 'Are you sure you want to delete Mapping?' + `<b>`,
    };
    const activeModal = this.modal.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });
  }

  delete(row?: any) {
    let params = {
      deptId: row.value[0].dept_id,
    };
    this.api.get('department/delete-user-department-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.reset();
          this.getAllUserDeptMapping();
          this.alert.info(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  deptValidator: any = null;
  userValidator: any = null;
  companyValidator: any = null;
  plantValidator: any = null;


  formValidator() {
    this.companyValidator = this.common.nullValidator(this.companyId,'Company Mandatory');
    this.plantValidator = this.common.nullValidator(this.plantId,'Plant Mandatory');
    this.userValidator = this.activeSection == 'user' ? this.common.nullValidator(this.selectedUserIds,'User Mandatory') : this.common.nullValidator(this.selectedStaffIds , 'Staff Mandatory');
    this.deptValidator = this.common.nullValidator(this.selectedDeptId,'Department Mandatory');
      if(this.role.user_role == 'AXESTRACK' && this.departmentScope == 'COMPANY'){
        if (!this.companyValidator.error && !this.deptValidator.error && !this.userValidator.error) {
          this.saveUserDeptMapping();
        }
      }else if(this.role.user_role == 'AXESTRACK' && this.departmentScope == 'PLANT'){
        if (!this.companyValidator.error && !this.plantValidator.error && !this.deptValidator.error && !this.userValidator.error) {
          this.saveUserDeptMapping();
        }
      }else{
        if (!this.userValidator.error && !this.deptValidator.error) {
          this.saveUserDeptMapping();
        }
      }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() menu: any[] = [];
  constructor(public userService: UserService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: any) {
    if(!this.userService._isStaffLoggined) {
      if (changes.menu) this.menu = changes.menu.currentValue;
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import * as filesaver from 'file-saver';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TableService } from 'src/app/@core/services/table.service';
import _ from 'lodash';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';

@Component({
  selector: 'app-user-tracking-report',
  templateUrl: './user-tracking-report.component.html',
  styleUrls: ['./user-tracking-report.component.scss']
})
export class UserTrackingReportComponent implements OnInit {

  currentDateTime: Date;
  currentTime: number = 6;
  dates: any = [];
  allowedPreviousDays:number = 6;
  activeDate: any = new Date();
  responseData:any[]=[];
  headers:any[]=[];
  zoneList:any[]=[];
  stageList:any[]=[];
  selectedZoneId:any[]=[];
  selectedStageId:any[]=[];
  headerKey:any='zone_name';
  valueKey:any='id';
  temp:any[]=[];
  temp1:any[]=[];
  // urlAuthKey: string = '';
  // @ViewChild(DataTableDirective, { static: false })
  // dtElement: any;
  // dtTrigger: any = new Subject();
  // dtOptions: any = this.table.options(10, 5, 'USER TRACKING REPORT');
  destinationList:any[]=[]

  ngAfterViewInit() {
    this.getUserTrackingLogData();
  }
  // ngOnDestroy(): void {
  //   this.dtTrigger.unsubscribe();
  // }
  // renderTable() {
  //   this.dtElement.dtInstance.then((dtInstance: any) => {
  //     dtInstance.destroy();
  //     this.dtTrigger.next();
  //   });
  // }
  constructor(private table:TableService, private breadcrumbService:BreadcrumbService, private activatedRoute: ActivatedRoute,private common:CommonService,private api:ApiService) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Reports', url: null },
      { name: 'User Tracking Report', url: '/user/user-tracking-report' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {

    // this.getDates();
    this.currentDateTime = new Date();
    this.activeDate = this.common.dateFormatter(this.activeDate, 'YYYYMMDD', false)
    this.getAllZones();
  }


  selectZone(event){
    this.selectedZoneId=event.map(e => e.id);
    this.selectedStageId=[];
    this.temp1=[];
    if(this.selectedZoneId!=null && this.selectedZoneId.length>0)this.getAllStages();
    else this.getAllZones();
    this.getUserTrackingLogData();
  }
  selectStage(event){
    this.selectedStageId=event.map(e => e.stage_id);
    if(this.selectedStageId!=null && this.selectedStageId.length>0)this.getAllDestinations();
    else this.getAllStages();
    this.getUserTrackingLogData();
  }

  exportAsExcel(data: any[],allTrips:boolean=false): void {
    let value = _.cloneDeep(data);
    if(!allTrips){
     value.map(res => {
       res['User Name']=res.username;
       res['Plant In']=res.start_time;
     this.headers.forEach((header)=>{
       res[header[this.headerKey]]=res.tat_data[header[this.valueKey]]?.spend_hours ?? '00:00:00';
     })
     res['Productive Hours']=res.productive_hours;
     res['Non-Productive Hours']=res.non_productive_hours;
     res['Plant Out']=res.end_time;
     res['Total TAT']=res.total_tat;
       delete res.username;
       delete res.start_time;
       delete res.tat_data;
       delete res.productive_hours;
       delete res.non_productive_hours;
       delete res.end_time;
       delete res.total_tat;
       delete res.entity_id;
     });
    }
     const worksheet = XLSX.utils.json_to_sheet(value);
     const workbook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(workbook, worksheet, 'User Tracking Report');
     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
     const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     filesaver.saveAs(blob, 'User Tracking Report.xlsx');
   }

  resetPage(){
    this.activeDate = this.common.dateFormatter(new Date(), 'YYYYMMDD', false);
    this.selectedStageId=[];
    this.selectedZoneId =[];
    this.temp=[];
    this.temp1=[];
    this.getAllZones();
    this.getUserTrackingLogData();
  }

  getAllZones(){
    this.common.loading = true;
    this.api.get("zone/get-all-zone-stage?allZones=false").subscribe((res: any) => {
      if (res.status) {
        this.zoneList = res.data;
        this.headerKey='zone_name';
        this.valueKey='id';
        this.headers = res.data;
        // this.dtTrigger.next();
        this.common.loading = false;
      }
    },(err: any) => {
      console.error('Error', err);
      this.common.loading = false;
    });
  }

  getUserTrackingLogData(){
    this.common.loading = true;
    let mapping=this.selectedStageId.length>0?'Destination':(this.selectedZoneId.length>0?'Stage':'Zone');
    let params = {
      mappingType:mapping,
      stageId:this.selectedStageId,
      zoneId:this.selectedZoneId,
      date:this.activeDate
    }
    this.api.get("report/get-user-location-log-report",params).subscribe((res: any) => {
      if (res.status) {
        this.common.loading = false;
        this.responseData = res.data;
        // setTimeout(()=>{
          // this.renderTable();
        // },500);
      }
    },(err: any) => {
      console.error('Error', err);
      this.common.loading = false;
    });
  }

  getAllStages(){
    this.common.loading = true;
    this.api.get("zone/get-zone-stage-mapping?zoneId="+this.selectedZoneId).subscribe((res: any) => {
      if (res.status) {
        this.common.loading = false;
        this.headerKey='stage_name';
        this.stageList = res.data;
        this.headers=this.stageList;
        this.valueKey='stage_id';
        // this.dtTrigger.next();
      }
    },(err: any) => {
      console.error('Error', err);
      this.common.loading = false;
    });
  }

  getAllDestinations(){
    this.common.loading = true;
    this.api.get("destination/get_destination_by_stage_id?stageId="+this.selectedStageId+"&allDestinations=true").subscribe((res: any) => {
      if (res.status) {
        this.destinationList = res.data;
        this.common.loading = false;
        this.headers = this.destinationList;
        this.headerKey='stage_destination_code';
        this.valueKey='stage_destination_code_id';
        // this.dtTrigger.next();
      }
    },(err: any) => {
      console.error('Error', err);
      this.common.loading = false;
    });
  }
}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'notification'|translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="notificationlist_page">
                <div class="notification_list">
                  <div class="days">
                    <div class="notification_row" *ngFor="let message of messages"
                      [ngStyle]="{'background-color': message.notification_type == 'WARNING' ? '#fbbf2e25' : (message.notification_type == 'ALERT' ? message.alert_color+'25' : '')}">
                      <div class="left_sec">
                        <i class="fa fa-circle"
                          [ngStyle]="{'color': message.notification_type == 'WARNING' ? '#fbbf2e' : (message.notification_type == 'ALERT' ? message.alert_color : '')}"></i>
                      </div>
                      <div class="right_sec">
                        <div class="content_div">
                          <div class="noti_title"
                            [ngStyle]="{'color': message.notification_type == 'WARNING' ? '#fbbf2e' : (message.notification_type == 'ALERT' ? message.alert_color : '')}">
                            {{message.title}} <span class="badge badge-primary"
                              [ngStyle]="{'background-color': message.notification_type == 'WARNING' ? '#fbbf2e' : (message.notification_type == 'ALERT' ? message.alert_color : '')}">{{message.notification_type}}</span>
                          </div>
                          <div class="noti_message">{{message.message}}</div>
                        </div>
                        <div class="time_div">
                          <p>{{message.send_time | date: "dd-MM-yyyy hh:mm a"}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card process-card">

            <div class="tab_section" style="margin-top:0px;" *ngIf="entityType==='trip'">

              <div class="plant-input" *ngIf="role.user_role == 'COMPANYADMIN' && viewPage == 'Kanban View'">
                <mat-form-field appearance="fill">
                  <mat-label>{{"plant" | customTranslate}}</mat-label>
                  <mat-select [(value)]="selectedPlant" (selectionChange)="onSelectPlant($event)">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Plant"
                        noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let plant of  plantList"
                      [value]="plant.plantId">{{plant.plantName}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="mobile_scroll" *ngIf="common.dashboardparam == '' && showTabs">
                <div class="toggler">
                  <ng-container *ngFor="let item of buttonView | keyvalue">
                    <button class="btn" *ngIf="user.permissionMap.get(item.value)"
                      (click)="getButtonIndex(item.key.substring(1,item.key.length))"
                      [ngClass]="activeButton == item.key.substring(1,item.key.length) ? 'btn-active':'btn-inactive'">{{item.key.substring(1,item.key.length)
                      | customTranslate}}</button>
                  </ng-container>
                </div>
              </div>

            </div>


            <div class="card-body" *ngIf="updatedViewArray?.length">
              <mat-tab-group animationDuration="100ms" (selectedTabChange)="onProcessChange($event)"
                [ngStyle]="{'display': viewPage=='Plant View'||viewPage=='Yard View'||viewPage=='Hardware View'||viewPage=='Map View' ||viewPage=='Loader View'? 'none': 'block'}">
                <mat-tab label="{{item.processName}}" [style.width.%]="20" *ngFor="let item of processList">

                  <div *ngIf="viewPage=='Kanban View'" style="display:flex;flex-direction:row;float: right">
                    <div class="box1" style="display: flex;">
                      <mat-label for="" style="margin-right:6px;margin-top:3px !important;font-size: 14px;"> {{'Show
                        InBetween'|customTranslate}} </mat-label>
                      <div class="custom-control custom-switch" style="margin-top: 3px;">
                        <input type="checkbox" class="custom-control-input" id='customSwitchesMandatory'
                          [(ngModel)]="showOnTheWay">
                        <label class="custom-control-label" for="customSwitchesMandatory"></label>
                      </div>
                    </div>
                  </div>

                  <div class="fancy_view" *ngIf="viewPage == 'Kanban View'" style="margin-top: 25px;">
                    <div class="stage-row" #stageBox style="margin-left: -79px">
                      <div class="stagebox">
                        <ng-container *ngFor="let stage of updatedViewArray;let a = index">
                          <div class="stage" [ngClass]="'stage-'+(a%7)" [style.width.px]="stageBoxWidth">
                            <div>
                              <div class="tat_section">
                                <div class="left_tat" *ngIf="a!=0">
                                  <p><i class="fa fa-clock"></i> {{"tat" | customTranslate}}:
                                    <span>{{inBetweenTat(stage)}}</span>
                                  </p>
                                </div>
                                <div class="right_tat">
                                  <p><i class="fa fa-clock"></i> {{("tat" | customTranslate) }}:
                                    <span>{{stage.expected_tat}}</span>
                                  </p>
                                </div>
                              </div>
                              <h4 class="stage-title">
                                <p class="mb-0" *ngIf="showOnTheWay"> {{stage.stage_name}} ({{stage?.trips?.length}})
                                </p>
                                <p class="mb-0" *ngIf="!showOnTheWay">{{stage.stage_name}} ({{stage?.inEntries}})</p>
                                <div class="icon" *ngIf="stage.next_stages.length>1"><img
                                    src="assets/images/switch_img.png" alt=""
                                    style="width: 16px;cursor:pointer;filter: brightness(0) saturate(100%) invert(100%) sepia(95%) saturate(22%) hue-rotate(307deg) brightness(104%) contrast(106%);"
                                    (click)="switchStage(a)">
                                </div>
                              </h4>
                              <div class="vehicle">
                                <ng-container *ngFor="let vehicle of stage.trips;index as i">
                                  <div class="card vehicle-card" *ngIf="vehicle.time_in != null || showOnTheWay==true"
                                    [ngClass]="currentIndex == i ?'active':''">
                                    <!-- (click)="StagetripUpdater(1,a,i)" -->

                                    <div class="card-body" [class.active]="vehicle.time_in == null">

                                      <div class="loader_class">
                                        <!-- <img src="assets/images/destination.svg" alt="" width="28px" height="14px"> -->
                                        <div class="vehicle_detail">
                                          <span class="vehicle-icon">
                                            <ng-container
                                              *ngIf="vehicle.stage_workflow_status != 'MAINTAINENCE' && entityType=='trip'">
                                              <img src="assets/images/truck.png" alt="">
                                            </ng-container>
                                            <ng-container
                                              *ngIf="vehicle.stage_workflow_status == 'MAINTAINENCE'&& entityType=='trip'">
                                              <img src="assets/images/maintain_truck.png" title="maintenance">
                                            </ng-container>
                                            <ng-container
                                              *ngIf="vehicle.stage_workflow_status != 'CANCELLED' && entityType=='visit'">
                                              <i class="fa fa-solid fa-user" style="color: green;"></i>
                                            </ng-container>
                                            <ng-container
                                              *ngIf="vehicle.stage_workflow_status == 'CANCELLED' && entityType=='visit'">
                                              <i class="fa fa-solid fa-user" style="color:red  "></i>
                                            </ng-container>
                                          </span>
                                          <span class="vehicle-number"
                                            matTooltip="{{vehicle.trip_number}} is in {{vehicle.stage_workflow_status}}"
                                            appEllipsisView style="width:80px">
                                            {{vehicle.vehicle_rc_no}}
                                          </span>

                                        </div>
                                        <div class="serial-number" [class]="sizeHandler(i)">
                                          <span>{{vehicle.queue_index}}</span>
                                        </div>
                                      </div>
                                      <div class="trip_tat">
                                        <div class="vehicle_tat">
                                          <p [ngClass]="{'blink_clr': isOverTat(vehicle.tat, stage.expected_tat)}"><i
                                              class="fa fa-clock"></i> CTAT-
                                            <span>{{vehicle.tat}}</span>
                                          </p>
                                          <p
                                            [ngClass]="{'blink_clr': isOverTat(vehicle.trip_total_tat, stage.stage_total_tat)}">
                                            <i class="fa fa-clock"></i> TTAT-
                                            <span>{{vehicle.trip_total_tat}}</span>
                                          </p>
                                        </div>

                                        <div class="vehicle-trip">
                                          <p class="number"> {{vehicle.transaction_name}}</p>
                                          <div class="v_trip_image" *ngIf="entityType=='trip'">
                                            <img class="icon" src="assets/images/trip-details(3).png" alt=""
                                              (click)="tripModalKanbanView(vehicle)">
                                            <img class="icon" src="assets/images/trip-tracking(3).png" alt=""
                                              (click)="tripTrackingModalKanbanView(vehicle)">
                                          </div>
                                          <!-- for visits 3dots icon -->
                                          <div class="last_bottom" *ngIf="entityType=='visit'">
                                            <div class="ellipsis_sec">
                                              <ng-container
                                                *ngTemplateOutlet="ellipseContainer; context: { $implicit: vehicle, index: i }">
                                              </ng-container>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>

                              </div>

                              <div class="road_design" *ngIf="a!=0">
                                <div class="down_arrow">
                                  <img src="assets/images/arrow_down.png">
                                </div>

                                <div class="small_road_up">
                                  <div class="border_bottom_line"></div>
                                  <div class="line_road"></div>
                                  <div class="line_road2"></div>
                                </div>

                                <div class="road">
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                  <div class="box"></div>
                                </div>

                                <div class="small_road_down">
                                  <div class="border_bottom_line"></div>
                                  <div class="line_road"></div>
                                  <div class="line_road2"></div>
                                </div>

                                <div class="right_arrow">
                                  <img src="assets/images/arrow_right.png">
                                </div>

                                <div class="vehicle_div">
                                  <div class="vehicle_img"
                                    *ngIf="a <= updatedViewArray.length - 1 && getOnTheWayTrips(stage.trips).length">
                                    <img
                                      [src]="entityType === 'visit' ? '../../../assets/images/person1.png' : 'assets/images/truck_icon.png'"
                                      (click)="openModal(getOnTheWayTrips(stage.trips),inBetweenTat(stage))"
                                      [ngClass]="{'rotateImageforTruck':entityType=='trip'}">
                                    <p class="vehicle_flag"
                                      (click)="openModal(getOnTheWayTrips(stage.trips),inBetweenTat(stage))">
                                      {{getOnTheWayTrips(stage.trips).length}}</p>

                                    <!-- <div class="vehicle_onthe_way">
                                      <ul>
                                        <li *ngFor="let v of getOnTheWayTrips(stage.trips); let i = index;" appEllipsisView>
                                          {{i + 1}}.
                                          {{v.vehicle_rc_no}}</li>
                                      </ul>
                                    </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </ng-container>
                        <app-data-not-found *ngIf="!vehicleFlowData.length"></app-data-not-found>
                      </div>
                    </div>
                  </div>

                  <div class="table_view mt-3" *ngIf="viewPage == 'Table View'">
                    <div class="row m-0">
                      <div class="col-md-12 col-sm-12">
                        <table class="custom-table" datatable [dtOptions]="dtOptions">
                          <thead>
                            <tr>
                              <th>{{"s.no." | customTranslate}}</th>
                              <th>{{("entry" | customTranslate)+" " +("time" | customTranslate)}}</th>
                              <th>{{"trip_number" | customTranslate}}</th>
                              <th> {{"current_stage" | customTranslate}} </th>
                              <th> {{"vehicle_number" | customTranslate}}</th>
                              <th> {{"action" | customTranslate}} </th>
                            </tr>
                          </thead>
                          <tbody>

                            <tr *ngFor="let vehicle of allVehiclesOfProcess ;index as v">
                              <td class="serial"> {{v+1}}</td>
                              <td class="entry-time"> {{vehicle.time_in | date:'dd-MM-YYYY hh:mm:ss' }} </td>
                              <td> {{vehicle.transaction_name}}</td>
                              <td> {{vehicle.stage_name}}</td>
                              <td class="vehicle_number">
                                <div class="v_number" appEllipsisView>
                                  {{vehicle.vehicle_rc_no}}
                                </div>
                                <div class="image">
                                  <img class="icon" src="assets/images/trip-details(3).png" alt=""
                                    (click)="tripModalKanbanView(vehicle)">
                                  <img class="icon" src="assets/images/trip-tracking(3).png" alt=""
                                    (click)="tripTrackingModalKanbanView(vehicle)">
                                  <app-trip-comment-trail [tripId]="vehicle.trip_id"></app-trip-comment-trail>
                                </div>
                              </td>
                              <td>
                                <div class="btn-block"> <button class="btn btn-primary" (click)="ViewDetail(v,vehicle)"
                                    *ngIf="user.permissionMap.get('view_action_table_view_access')==true"> View
                                  </button> </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                      </div>
                      <div class="col-md-12 col-sm-12 mt-4">
                        <table class="custom-table vehicle-table" datatable [dtOptions]="stageoption">
                          <thead>
                            <tr>
                              <th> Stage</th>
                              <th> Total vehicles</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let stage of vehicleFlowData;index as i">
                              <td> {{stage?.stage_name}}</td>
                              <td> {{stage?.trips?.length}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
            <app-trip-flow *ngIf="viewPage == 'Trips View'|| triggerTripsView==true"
              [tripViewData]="vehicleFlowData"></app-trip-flow>
            <app-trip-map-view *ngIf="viewPage == 'Map View' " [processList]="processList"
              [plantId]="selectedPlant"></app-trip-map-view>
            <app-data-not-found style="height:234px"
              *ngIf="!vehicleFlowData?.length && viewPage == 'Kanban View'"></app-data-not-found>
            <app-plant-live-view *ngIf="viewPage == 'Plant View'"
              [refData]="{plantId : this.selectedPlant}"></app-plant-live-view>

            <app-Plant-Hardware-View *ngIf="viewPage == 'Hardware View'"
              [refData]="{flag : true}"></app-Plant-Hardware-View>

            <!-- <app-yard-view *ngIf="viewPage == 'Yard View'"></app-yard-view> -->

            <app-loader-view *ngIf="viewPage == 'Loader View'" [refData]="{flag : true}"></app-loader-view>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>


<!-- ellipse container -->
<ng-template #ellipseContainer let-visit let-i="index">
  <ng-container *ngIf="visit.trip_id == null; else ellipse;">
    <i class="fa fa-ellipsis-v" style="opacity: 0.5;"></i>
  </ng-container>
  <ng-template #ellipse>
    <i class="fa fa-ellipsis-v"
      (click)="ellipse.checked ? ellipse.checked = false : ellipse.click(); $event.stopPropagation(); "></i>
    <input [hidden]="true" #ellipse (click)="$event.stopPropagation();" type="radio" name="ellipse"
      [value]="'visit.visitor_id' + '@' + i" id="">
    <ul class="ellipsis_data" *ngIf="ellipse.checked">
      <li (click)="tripModalKanbanView(visit)"><img src="assets/images/trip-details(3).png" alt="trip detail image"
          title="Visit Detail" style=" margin-right: 5px;">Visit Details
      </li>
      <li (click)="tripTrackingModalKanbanView(visit) "><img src="assets/images/trip-tracking(3).png"
          alt="trip tracking image" title="Visit Tracking" style=" margin-right: 5px;">Visit Tracking
      </li>
      <li (click)="pathTrackingModal(visit.visitor_id)">
        <img src="assets/images/user-path-tracking.png" alt="path tracking image" title="Path Tracking"> Path
        Tracking
      </li>
      <li (click)="tripCommentTrail(visit.trip_id)">
        <img class="main-icon" src="assets/images/chat-icon.png" alt="comment image" matTooltip="Visit Remark History">
        Comment Trail
      </li>
    </ul>
  </ng-template>
</ng-template>

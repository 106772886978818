import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { UserService } from '../../@core/services/user.service';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: [
    '../dashboard/dashboard.component.scss',
    './customer-dashboard.component.scss',
  ],
})
export class CustomerDashboardComponent implements OnInit {
  classname = '';
  constructor(
    public common: CommonService,
    public user: UserService,
    public router: Router,
    public alert: AlertService,
    public breadcrumbService: BreadcrumbService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Dashboard', url: '/user/dashboard' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  layout() {
    let services = document.querySelectorAll('.services .row .card-column');
    let length = services.length;

    if (length <= 15) {
      this.classname = 'fixedview';
    } else {
      this.classname = 'maxview';
    }
  }

  commonNavigate(index: number) {
    this.alert.clear();
    this.common.commondashboardSubmenu = this.common.usermenu[index];
    if (this.common.commondashboardSubmenu.childs.length) {
      this.router.navigate(['/user/common-dashboard']);
      localStorage.setItem(
        'commonSubmenu',
        JSON.stringify(this.common.commondashboardSubmenu)
      );
      this.common.commondashboardNavigate = true;
    } else {
      this.alert.error(
        `<b>${this.common.commondashboardSubmenu.name}</b> has no redirect link`
      );
    }
  }

  ngOnInit(): void {
    this.layout();
  }

  ngOnDestroy(): void {
  }
}

import { ParkingZoneManagementComponent } from './../../components/parking-zone-management/parking-zone-management.component';
import { DepartmentManagementComponent } from './../../components/department-management/department-management.component';
import { OtaManagementComponent } from '../../components/ota-management/ota-management.component';
import { FieldAuditReportComponent } from './../../components/field-audit-report/field-audit-report.component';
import { VehicleStatusChangeComponent } from '../../modals/vehicle-status-change/vehicle-status-change.component';
import { HardwareStatusComponent } from '../../components/hardware-status/hardware-status.component';
import { DestinationStageMappingComponent } from '../../components/destination-stage-mapping/destination-stage-mapping.component';
import { EmployeeReportComponent } from 'src/app/components/employee-report/employee-report.component';
import { AuditLogsComponent } from './../../components/audit-logs/audit-logs.component';
import { PlantGeofenceComponent } from './../../components/plant-geofence/plant-geofence.component';
import { ActionDashboardComponent } from './../../components/action-dashboard/action-dashboard.component';

import { ResetPasswordComponent } from '../../auth/reset-password/reset-password.component';

import { TripDetailComponent } from './../../components/process-stage/trip-detail/trip-detail.component';
import { DriverTrackerComponent } from './../../components/driver-tracker/driver-tracker.component';
import { HelpComponent } from 'src/app/components/help/help.component';
import { ProcessStageComponent } from './../../components/process-stage/process-stage.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UserComponent } from './user.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { DatatableComponent } from 'src/app/directives/datatable/datatable.component';
import { ProcessComponent } from 'src/app/components/process/process.component';
import { WorkTypeComponent } from 'src/app/components/work-type/work-type.component';
import { CompaniesComponent } from 'src/app/components/companies/companies.component';
import { UserManagementComponent } from 'src/app/components/user-management/user-management.component';
import { ActionsComponent } from 'src/app/components/actions/actions.component';
import { StagesComponent } from 'src/app/components/stages/stages.component';
import { GroupManagementComponent } from 'src/app/components/group-management/group-management.component';
import { StageComponent } from 'src/app/components/stage/stage.component';
import { StageTypeComponent } from 'src/app/components/stage-type/stage-type.component';
import { StageActionComponent } from 'src/app/components/stage-action/stage-action.component';
import { UserGroupAssociationComponent } from 'src/app/components/user-group-association/user-group-association.component';
import { PlantComponent } from 'src/app/components/plant/plant.component';
import { SetUserPermissionComponent } from 'src/app/components/set-user-permission/set-user-permission.component';
import { StageDestinationCodeComponent } from 'src/app/components/stage-destination-code/stage-destination-code.component';
import { StageDestinationCodeOccupancyComponent } from 'src/app/components/stage-destination-code-occupancy/stage-destination-code-occupancy.component';
import { MasterFieldComponent } from 'src/app/components/master-field/master-field.component';
import { CommunicationTypeComponent } from 'src/app/components/communication-type/communication-type.component';
import { CommunicationTemplateComponent } from 'src/app/components/communication-template/communication-template.component';
import { StageDestinationCodeTypeComponent } from 'src/app/components/stage-destination-code-type/stage-destination-code-type.component';
import { ChangePasswordComponent } from 'src/app/modals/change-password/change-password.component';
import { StageSettingComponent } from 'src/app/components/stage-setting/stage-form-setting/stage-setting.component';
import { GroupRoleMappingComponent } from 'src/app/components/group-role-mapping/group-role-mapping.component';
import { ComponenttComponent } from 'src/app/components/componentt/componentt.component';
import { ShiftMasterComponent } from 'src/app/components/shift-master/shift-master.component';
import { TMGDashboardComponent } from 'src/app/components/tmg-dashboard/tmg-dashboard.component';
import { StageWiseUserDashboardComponent } from 'src/app/components/stage-wise-user-dashboard/stage-wise-user-dashboard.component';
import { ActionActionMappingComponent } from 'src/app/components/action-action-mapping/action-action-mapping.component';
import { UserShiftMappingComponent } from 'src/app/components/user-shift-mapping/user-shift-mapping.component';
import { CommunicationComponent } from 'src/app/components/communication/communication.component';
import { AuditManagementComponent } from 'src/app/components/audit-management/audit-management.component';
import { ShiftCalendarComponent } from 'src/app/components/shift-calendar/shift-calendar.component';
import { UserActionMappingComponent } from 'src/app/components/user-action-mapping/user-action-mapping.component';
import { MethodManagementComponent } from 'src/app/components/method-management/method-management.component';
import { PlantSettingComponent } from 'src/app/components/plant-setting/plant-setting.component';
import { PlantLiveViewComponent } from 'src/app/components/plant-live-view/plant-live-view.component';
import { VehicleDetailComponent } from 'src/app/components/vehicle-detail/vehicle-detail.component';
import { OverridingActionsComponent } from 'src/app/components/overriding-actions/overriding-actions.component';
import { PlantFieldMappingComponent } from 'src/app/components/field-method-mapping/plant-field-method-mapping.component';
import { VisitorDashboardComponent } from 'src/app/components/vms/visitor-dashboard/visitor-dashboard.component';
import { VisitorReportsComponent } from 'src/app/components/visitor-reports/visitor-reports.component';
import { TatReportsComponent } from 'src/app/components/tat-reports/tat-reports.component';
import { TripReportComponent } from 'src/app/components/trip-report/trip-report.component';
import { OverridingLogsComponent } from 'src/app/components/overriding-logs/overriding-logs.component';
import { ManageHardwareComponent } from 'src/app/components/manage-hardware/manage-hardware.component';
import { HardwareMappingComponent } from 'src/app/components/hardware-mapping/hardware-mapping.component';
import { PlantHardwareViewComponent } from 'src/app/components/Plant-Hardware-View/Plant-Hardware-View.component';
import { FieldToleranceMappingComponent } from 'src/app/components/field-tolerance-mapping/field-tolerance-mapping.component';
import { MethodCommunicationMappingComponent } from 'src/app/components/method-communication-mapping/method-communication-mapping.component';
import { LoaderViewComponent } from 'src/app/components/loader-view/loader-view.component';
import { MethodMappingComponent } from 'src/app/components/method-mapping/method-mapping.component';
import { HardwareSettingComponent } from 'src/app/components/hardware-setting/hardware-setting.component';
import { AlertManagementComponent } from 'src/app/components/alert-management/alert-management.component';
import { AlertTypeComponent } from 'src/app/components/alert-type/alert-type.component';
import { AlertCommunicationMappingComponent } from 'src/app/components/alert-communication-mapping/alert-communication-mapping.component';
import { AlertDashboardComponent } from 'src/app/components/alert-dashboard/alert-dashboard.component';
import { TripHistoryComponent } from 'src/app/components/trip-history/trip-history.component';
import { AppManagementComponent } from 'src/app/components/app-management/app-management.component';
import { NotificationListComponent } from 'src/app/components/notification-list/notification-list.component';
import { AppVersionDataComponent } from 'src/app/components/app-version-data/app-version-data.component';
import { DriverIvrCallComponent } from 'src/app/components/driver-ivr-call/driver-ivr-call.component';
import { LogoutModalComponent } from 'src/app/logout-modal/logout-modal.component';
import { HardwareOTALogsComponent } from 'src/app/components/HardwareOTALogs/HardwareOTALogs.component';
import { ParkingCreationComponent } from 'src/app/components/parking-slot-managment/parking-slot-management.component';
import { DockManagementComponent } from 'src/app/components/dock-management/dock-management.component';
import { ParkingDashboardComponent } from 'src/app/components/parking-dashboard/parking-dashboard.component';
import { AlertCardModalComponent } from 'src/app/components/alert-card-modal/alert-card-modal.component';
import { UserChecklistMappingComponent } from 'src/app/components/user-checklist-mapping/user-checklist-mapping.component';
import { AddChecklistComponent } from 'src/app/components/add-checklist/add-checklist.component';
import { ChecklistActionsMappingComponent } from 'src/app/components/checklist-actions-mapping/checklist-actions-mapping.component';
import { ChecklistComponent } from 'src/app/components/checklist/checklist.component';
import { ChecklistDashbaordComponent } from 'src/app/components/checklist-dashbaord/checklist-dashbaord.component';
import { ZoneSlotMappingComponent } from 'src/app/components/zone-slot-mapping/zone-slot-mapping.component';
import { ZoneManagementComponent } from 'src/app/components/zone-management/zone-management.component';

import { ViewChecklistComponent } from 'src/app/components/view-checklist/view-checklist.component';
import { DockDashboardComponent } from 'src/app/components/dock-dashboard/dock-dashboard.component';
import { SlotManagementComponent } from 'src/app/components/slot-management/slot-management.component';
import { StageZoneMappingComponent } from 'src/app/components/stage-zone-mapping/stage-zone-mapping.component';
import { ZoneDashboardComponent } from 'src/app/components/zone-dashboard/zone-dashboard.component';
import { HelpSubmenuComponent } from 'src/app/components/help-submenu/help-submenu.component';
import { HelpInfoComponent } from 'src/app/components/help-info/help-info.component';
import { EmployeeDetailModalComponent } from 'src/app/components/employee-detail-modal/employee-detail-modal.component';
import { ProductivityReportComponent } from 'src/app/components/productivity-report/productivity-report.component';
import { TatInbetweenReportComponent } from 'src/app/components/tat-inbetween-report/tat-inbetween-report.component';
import { ChecklistAdminDashboardComponent } from 'src/app/components/checklist-admin-dashboard/checklist-admin-dashboard.component';
import { AssignedTripsDashboardComponent } from 'src/app/components/assigned-trips-dashboard/assigned-trips-dashboard.component';
import { EntryFormComponent } from 'src/app/components/entry-form/entry-form.component';
import { AlertUserMappingComponent } from 'src/app/components/alert-user-mapping/alert-user-mapping.component';
import { AlertTaskDashboardComponent } from 'src/app/components/alert-task-dashboard/alert-task-dashboard.component';
import { AlertReportComponent } from 'src/app/components/alert-report/alert-report.component';
import { VehicleReportComponent } from 'src/app/components/vehicle-report/vehicle-report.component';
import { TripScheduleComponent } from 'src/app/components/trip-schedule/trip-schedule.component';
import { ManufacturerComponent } from 'src/app/components/manufacturer/manufacturer.component';
import { SupplierComponent } from 'src/app/components/supplier/supplier.component';
import { AssetCategoryComponent } from 'src/app/components/asset-category/asset-category.component';
import { AssetLabelComponent } from 'src/app/components/asset-label/asset-label.component';
import { AssetCategoryFieldComponent } from 'src/app/components/asset-category-field/asset-category-field.component';
import { AddFieldModalComponent } from 'src/app/modals/add-field-modal/add-field-modal.component';
import { AssetsComponent } from 'src/app/components/assets/assets.component';
import { AssetLabelMappingComponent } from 'src/app/components/asset-label-mapping/asset-label-mapping.component';

import { StatusManagementComponent } from 'src/app/components/status-management/status-management.component';
import { DepartmentUserMappingComponent } from 'src/app/components/department-user-mapping/department-user-mapping.component';
import { InventoryManagerChecklistComponent } from 'src/app/components/inventory-manager-checklist/inventory-manager-checklist.component';
import { DocumentTypeComponent } from 'src/app/components/document-type/document-type.component';
import { AssetDocumentComponent } from 'src/app/components/asset-document/asset-document.component';
import { AssetViewComponent } from 'src/app/components/asset-view/asset-view.component';
import { UserTagMappingComponent } from 'src/app/components/user-tag-mapping/user-tag-mapping.component';
import { AddAssetRequestComponent } from 'src/app/components/add-asset-request/add-asset-request.component';
import { AssetInsuranceComponent } from 'src/app/components/asset-insurance/asset-insurance.component';
import { AddInsuranceComponent } from 'src/app/components/add-insurance/add-insurance.component';
import { AssetRequestRemarkComponent } from 'src/app/components/asset-request-remark/asset-request-remark.component';
import { AssetDetailComponent } from 'src/app/components/asset-detail/asset-detail.component';
import { ZoneStageMappingComponent } from 'src/app/components/zone-stage-mapping/zone-stage-mapping.component';
import { ZoneAllocationComponent } from 'src/app/components/zone-allocation/zone-allocation.component';
import { ZoneTypeComponent } from 'src/app/components/zone-type/zone-type.component';
import { AssetLinkingComponent } from 'src/app/components/asset-linking/asset-linking.component';
import { AssetDepreciationComponent } from 'src/app/components/asset-depreciation/asset-depreciation.component';
import { AssetEventsComponent } from 'src/app/components/asset-events/asset-events.component';
import { UserTrackingDashboardComponent } from 'src/app/components/user-tracking-dashboard/user-tracking-dashboard.component';
import { AssetsTrackingDasboardComponent } from 'src/app/components/assets-tracking-dasboard/assets-tracking-dasboard.component';
import { VehicleWhitelistBlacklistComponent } from 'src/app/components/vehicle-whitelist-blacklist/vehicle-whitelist-blacklist.component';
import { VehicleTagMappingComponent } from 'src/app/components/vehicle-tag-mapping/vehicle-tag-mapping.component';
import { MapViewModalComponent } from 'src/app/components/map-view-modal/map-view-modal.component';
import { ProductTypeComponent } from 'src/app/components/product-type/product-type.component';
import { ProductManagementComponent } from 'src/app/components/product-management/product-management.component';
import { ProductDockLinkingComponent } from 'src/app/components/product-dock-linking/product-dock-linking.component';
import { MisReportComponent } from 'src/app/mis-report/mis-report.component';
import { VehicleRouteConfigurationComponent } from 'src/app/components/vehicle-route-configuration/vehicle-route-configuration.component';
import { DockAppointmentComponent } from 'src/app/components/dock-appointment/dock-appointment.component';
import { HardwareHealthComponent } from 'src/app/components/hardware-health/hardware-health.component';
import { TransporterBookingsComponent } from 'src/app/components/transporter-bookings/transporter-bookings.component';
import { HardwareVehicleLogComponent } from 'src/app/components/hardware-vehicle-log/hardware-vehicle-log.component';
import { DockAppointmentReportComponent } from 'src/app/components/dock-appointment-report/dock-appointment-report.component';
import { User_path_trackingComponent } from 'src/app/components/user_path_tracking/user_path_tracking.component';
import { ChecklistHistoryComponent } from 'src/app/components/checklist-history/checklist-history.component';
import { TagDestinationTatReportComponent } from 'src/app/components/tag-destination-tat-report/tag-destination-tat-report.component';
import { UserTrackingReportComponent } from 'src/app/components/user-tracking-report/user-tracking-report.component';
import { AssetTrackingReportComponent } from 'src/app/components/asset-tracking-report/asset-tracking-report.component';
import { VisitorVisitsComponent } from 'src/app/components/visitor-visits/visitor-visits.component';
import { DriverBanComponent } from 'src/app/components/driver-ban/driver-ban.component';
import { VehicleBanComponent } from 'src/app/components/vehicle-ban/vehicle-ban.component';
import { UserTrackingViewComponent } from 'src/app/components/user-tracking-view/user-tracking-view.component';
import { AssetsTrackingViewComponent } from 'src/app/components/assets-tracking-view/assets-tracking-view.component';
import { BanTransporterComponent } from 'src/app/components/ban-transporter/ban-transporter.component';
import { ApprovalFlowComponent } from 'src/app/components/approval-flow/approval-flow.component';
import { VisitorAppointmentComponent } from 'src/app/components/visitor-appointment/visitor-appointment.component';
import { FormTypeManagementComponent } from 'src/app/components/form-type-management/form-type-management.component';
import { FormManagementComponent } from 'src/app/components/form-management/form-management.component';
import { FormFieldMappingComponent } from 'src/app/components/form-field-mapping/form-field-mapping.component';
import { VisitorReportComponent } from 'src/app/components/visitor-report/visitor-report.component';
import { VisitorAdminDashboardComponent } from 'src/app/components/visitor-admin-dashboard/visitor-admin-dashboard.component';
import { VisitorBanComponent } from 'src/app/components/visitor-ban/visitor-ban.component';
import { ComplianceSeverityMappingComponent } from 'src/app/components/compliance-severity-mapping/compliance-severity-mapping.component';
import { FormComponent } from 'src/app/components/form/form.component';
import { VehicleDetailsComponent } from 'src/app/components/vehicle-details/vehicle-details.component';
import { DriverDetailsComponent } from 'src/app/components/driver-details/driver-details.component';
import { TransporterDetailsComponent } from 'src/app/components/transporter-details/transporter-details.component';
import { ActiveVisitsComponent } from 'src/app/components/active-visits/active-visits.component';
import { VisitorTrackingComponent } from 'src/app/components/visitor-tracking/visitor-tracking.component';
import { VisitDateWiseComponentComponent } from 'src/app/components/visit-date-wise-component/visit-date-wise-component.component';
import { VisitorCardFieldMappingComponent } from 'src/app/components/visitor-card-field-mapping/visitor-card-field-mapping.component';
import { InventoryDashboardComponent } from 'src/app/components/inventory-dashboard/inventory-dashboard.component';
import { DataEntryComponent } from 'src/app/components/data-entry/data-entry.component';
import { OrderReportComponent } from 'src/app/components/order-report/order-report.component';

// import { FormComponent } from 'src/app/components/form/form.component';


const routes: Routes = [
  {
    path: '',
    component: UserComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      { path: 'communication-comp', component: CommunicationComponent },
      {
        path: 'stage_destination_code_list',
        component: StageDestinationCodeComponent,
      },
      {
        path: 'stage_destination_code_occupancy_list',
        component: StageDestinationCodeOccupancyComponent,
      },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'stage-dashboard', component: StageWiseUserDashboardComponent },
      { path: 'company', component: CompaniesComponent },
      { path: 'datatable', component: DatatableComponent },
      { path: 'process-management', component: ProcessComponent },
      { path: 'list_work_type', component: WorkTypeComponent },
      { path: 'users',redirectTo:'users/user',pathMatch:'full'},
      { path: 'list_stage_configuration', component: StagesComponent },
      { path: 'action-mapping', component: UserActionMappingComponent },
      { path: 'action', component: ActionsComponent },
      { path: 'groups', component: GroupManagementComponent },
      { path: 'stage_list', component: StageComponent },
      { path: 'stage-form-setting', component: StageSettingComponent },
      { path: 'list_stage_type', component: StageTypeComponent },
      { path: 'list_stage_action', component: StageActionComponent },
      {
        path: 'user-group-association',
        component: UserGroupAssociationComponent,
      },
      {
        path: 'asset-category',
        component: AssetCategoryComponent,
      },
      { path: 'plants', component: PlantComponent },
      { path: 'set-user-permission', component: SetUserPermissionComponent },
      { path: 'active-trips', component: ProcessStageComponent },
      { path: 'plant', component: PlantComponent },
      { path: 'process-stage', component: ProcessStageComponent },
      { path: 'plant', component: PlantComponent },
      { path: 'set-user-permission', component: SetUserPermissionComponent },
      { path: 'master-field', component: MasterFieldComponent },
      { path: 'master-field', component: MasterFieldComponent },
      { path: 'communication-type', component: CommunicationTypeComponent },
      {
        path: 'communication-template',
        component: CommunicationTemplateComponent,
      },
      { path: 'trip-activity', component: DriverTrackerComponent },
      { path: 'help', component: HelpComponent },
      {
        path: 'asset-management',
        component: AssetsComponent,
      },
      {
        path: 'asset-request',
        component: AddAssetRequestComponent,
      },

      {
        path: 'stage_destination_code_type_list',
        component: StageDestinationCodeTypeComponent,
      },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'group-role-mapping', component: GroupRoleMappingComponent },
      { path: 'component', component: ComponenttComponent },
      { path: 'shift-management', component: ShiftMasterComponent },
      { path: 'trip-detail', component: TripDetailComponent },
      { path: 'tmg-dashboard', component: TMGDashboardComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      {
        path: 'stage-user-dashboard',
        component: StageWiseUserDashboardComponent,
      },
      {
        path: 'action-action-mapping',
        component: ActionActionMappingComponent,
      },
      { path: 'user-shift-mapping', component: UserShiftMappingComponent },
      { path: 'action-dashboard', component: ActionDashboardComponent },
      { path: 'plant-geofence', component: PlantGeofenceComponent },
      { path: 'audit-management', component: AuditManagementComponent },
      { path: 'shift-calendar', component: ShiftCalendarComponent },
      { path: 'method-management', component: MethodManagementComponent },
      { path: 'plant-live-view', component: PlantLiveViewComponent },
      { path: 'vehicle-detail', component: VehicleDetailComponent },
      { path: 'plant-setting', component: PlantSettingComponent },
      { path: 'audit-logs', component: AuditLogsComponent },
      { path: 'overriding-actions', component: OverridingActionsComponent },
      { path: 'method-field-mapping', component: PlantFieldMappingComponent },
      { path: 'visitor-dashboard', component: VisitorDashboardComponent },
      { path: 'visitor-report', component: VisitorReportsComponent },
      { path: 'visits',component: VisitorVisitsComponent},
      { path: 'tat-report', component: TatReportsComponent },
      { path: 'trip-report', component: TripReportComponent },
      { path: 'overriding-logs', component: OverridingLogsComponent },
      { path: 'employee-report', component: EmployeeReportComponent },
      { path: 'hardware-status', component: HardwareStatusComponent },
      { path: 'manage-hardware', component: ManageHardwareComponent },
      { path: 'hardware-mapping', component: HardwareMappingComponent },
      { path: 'plant-hardware-view', component: PlantHardwareViewComponent },
      {
        path: 'destination-stage-mapping',
        component: DestinationStageMappingComponent,
      },
      {
        path: 'field-tolerance-mapping',
        component: FieldToleranceMappingComponent,
      },
      {
        path: 'vehicle-status-change',
        component: VehicleStatusChangeComponent,
      },
      {
        path: 'method-communication-mapping',
        component: MethodCommunicationMappingComponent,
      },
      { path: 'loader-view', component: LoaderViewComponent },
      { path: 'field-audit-report', component: FieldAuditReportComponent },
      { path: 'method-mapping', component: MethodMappingComponent },
      { path: 'hardware-setting', component: HardwareSettingComponent },
      { path: 'hardware-ota-management', component: OtaManagementComponent },
      { path: 'alert-type-management', component: AlertTypeComponent },
      { path: 'alert-management', component: AlertManagementComponent },
      {
        path: 'alert-communication-mapping',
        component: AlertCommunicationMappingComponent,
      },
      { path: 'alert-dashboard', component: AlertDashboardComponent },
      { path: 'trip-history', component: TripHistoryComponent },
      { path: 'app-version-check', component: AppVersionDataComponent },
      { path: 'app-management', component: AppManagementComponent },
      { path: 'notification-list', component: NotificationListComponent },
      { path: 'driver-ivr-call', component: DriverIvrCallComponent },
      { path: 'logout-modal', component: LogoutModalComponent },
      { path: 'hardware-ota-logs', component: HardwareOTALogsComponent },
      { path: 'parking-creation', component: ParkingCreationComponent },
      { path: 'dock-management', component: DockManagementComponent },
      { path: 'parking-dashboard', component: ParkingDashboardComponent },
      { path: 'zone-management', component: ParkingZoneManagementComponent },
      { path: 'zone-slot-mapping', component: ZoneSlotMappingComponent },
      { path: 'alert-card-modal', component: AlertCardModalComponent },
      {
        path: 'user-checklist-mapping',
        component: UserChecklistMappingComponent,
      },
      { path: 'add-checklist', component: AddChecklistComponent },
      {
        path: 'checklist-actions-mapping',
        component: ChecklistActionsMappingComponent,
      },
      { path: 'checklist', component: ChecklistComponent },
      { path: 'checklist-dashboard', component: ChecklistDashbaordComponent },
      { path: 'view-checklist', component: ViewChecklistComponent },
      { path: 'dock-dashboard', component: DockDashboardComponent },
      { path: 'stage-zone-mapping', component: StageZoneMappingComponent },
      { path: 'zone-dashboard', component: ZoneDashboardComponent },
      { path: 'slot-management', component: SlotManagementComponent },
      { path: 'help-submenu', component: HelpSubmenuComponent },
      { path: 'help-info', component: HelpInfoComponent },
      {
        path: 'employee-detail-modal',
        component: EmployeeDetailModalComponent,
      },
      { path: 'productivity-report', component: ProductivityReportComponent },
      { path: 'tat-inbetween-report', component: TatInbetweenReportComponent },
      {
        path: 'checklist-admin-dashboard',
        component: ChecklistAdminDashboardComponent,
      },
      {
        path: 'assigned-trips-dashboard',
        component: AssignedTripsDashboardComponent,
      },
      { path: 'entry-form', component: EntryFormComponent },
      { path: 'alert-user-mapping', component: AlertUserMappingComponent },
      { path: 'alert-task-dashboard', component: AlertTaskDashboardComponent },
      { path: 'alert-report', component: AlertReportComponent },
      { path: 'vehicle-report', component: VehicleReportComponent },
      { path: 'trip-schedule', component: TripScheduleComponent },
      { path: 'status-management', component: StatusManagementComponent },
      {
        path: 'department-management',
        component: DepartmentManagementComponent,
      },
      {
        path: 'department-user-mapping',
        component: DepartmentUserMappingComponent,
      },
      { path: 'manufacturer', component: ManufacturerComponent },
      { path: 'supplier', component: SupplierComponent },
      { path: 'asset-label', component: AssetLabelComponent },
      { path: 'asset-category', component: AssetCategoryComponent },
      { path: 'asset-category-fields', component: AssetCategoryFieldComponent },
      { path: 'asset-label-mapping', component: AssetLabelMappingComponent },
      {
        path: 'inventory-manager-checklist',
        component: InventoryManagerChecklistComponent,
      },
      { path: 'user-tag-mapping', component: UserTagMappingComponent },
      { path: 'request-info', component: AssetRequestRemarkComponent },
      { path: 'document-type', component: DocumentTypeComponent },
      { path: 'asset-view', component: AssetViewComponent },
      { path: 'asset-insurance', component: AssetInsuranceComponent },
      { path: 'add-insurance', component: AddInsuranceComponent },
      { path: 'asset-details', component: AssetDetailComponent },
      { path: 'asset-depreciation', component: AssetDepreciationComponent },
      { path: 'zone-stage-mapping', component: ZoneStageMappingComponent },
      { path: 'zone-allocation', component: ZoneAllocationComponent },
      { path: 'zone-stage-management', component: ZoneManagementComponent },
      { path: 'zone-type-management', component: ZoneTypeComponent },
      { path: 'asset-linking', component: AssetLinkingComponent },
      { path: 'asset-events', component: AssetEventsComponent },
      { path: 'user-tracking', component: UserTrackingDashboardComponent },
      { path: 'assets-tracking', component: AssetsTrackingDasboardComponent },
      { path: 'asset-depreciation', component:AssetDepreciationComponent},
      { path: 'zone-stage-management',component: ZoneManagementComponent},
      {path:'zone-type-management',component:ZoneTypeComponent},
      {path : 'zone-stage-mapping' , component:ZoneStageMappingComponent},
      { path: 'zone-allocation', component:ZoneAllocationComponent},
      { path: 'user-tracking', component:UserTrackingDashboardComponent},
      { path: 'assets-tracking', component:AssetsTrackingDasboardComponent},
      {path:'vehicle-restriction-allowance',component:VehicleWhitelistBlacklistComponent},
      {path:'vehicle-tag-mapping',component:VehicleTagMappingComponent},
      {path:'map-view-modal', component:MapViewModalComponent},
      {path:'product-type',component:ProductTypeComponent},
      {path:'product-management',component:ProductManagementComponent},
      {path:'product-dock-linking',component:ProductDockLinkingComponent},
      {path:'mis-report',component:MisReportComponent},
      {path:'vehicle-route-configuration',component:VehicleRouteConfigurationComponent},
      {path:'dock-appointment',component:DockAppointmentComponent},
      {path:'hardware-health',component:HardwareHealthComponent},
      {path:'transporter-bookings',component:TransporterBookingsComponent},
      {path:'hardware-vehicle-log',component:HardwareVehicleLogComponent},
      {path:'dock-appointment-report',component:DockAppointmentReportComponent},
      {path:'users/:type',component:UserManagementComponent},
      {path:'user_path_tracking',component:User_path_trackingComponent},
      {path:'asset-view',component:AssetViewComponent},
      {path:'app-checklist-history',component:ChecklistHistoryComponent},
      {path:'tag-destination-tat-report',component:TagDestinationTatReportComponent},
      {path:'user-tracking-report',component:UserTrackingReportComponent},
      {path:'asset-tracking-report',component:AssetTrackingReportComponent},
      {path:'ban-transporter',component:BanTransporterComponent},
      {path:'ban-driver',component:DriverBanComponent},
      {path:'ban-vehicle',component:VehicleBanComponent},
      {path:'tracking-view',component:UserTrackingViewComponent},
      {path:'tracking-asset',component:AssetsTrackingViewComponent},
      {path:'approval-flow',component:ApprovalFlowComponent},
      {path:'visits', component:VisitorVisitsComponent},
      {path:'visitor-appointment',component:VisitorAppointmentComponent},
      {path:'form-type-management',component:FormTypeManagementComponent},
      {path:'form-management',component:FormManagementComponent},
      {path:'form-field-mapping',component:FormFieldMappingComponent},
      {path:'visitor-admin-dashboard',component:VisitorAdminDashboardComponent},
      {path:'visitor-visits-report', component:VisitorReportComponent },
      {path:'ban-visitor',component:VisitorBanComponent},
      {path:'compliance-severity-mapping',component:ComplianceSeverityMappingComponent},
      {path:'form',component:FormComponent},
      {path:'vehicle-details',component:VehicleDetailsComponent},
      {path:'driver-details',component:DriverDetailsComponent},
      {path:'transporter-details',component:TransporterDetailsComponent},
      {path:'active-visits',component:ActiveVisitsComponent},
      {path:'visitor-tracking',component:VisitorTrackingComponent},
      {path:'visit-date-wise-report',component:VisitDateWiseComponentComponent},
      {path:'visitor-card-field-mapping',component:VisitorCardFieldMappingComponent},
      {path:'inventory-dashboard',component:InventoryDashboardComponent},
      {path:'data-entry',component:DataEntryComponent},
      {path:'order-report',component:OrderReportComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}

import { TableService } from '../../@core/services/table.service';
import { AlertService } from './../../theme/layout/header/alert-message/alert.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { CompanyService } from './companies.service';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {
  companys = {
    companyId: '',
    companyName: '',
    companySector: '',
    companyStatus: 'ACTIVE',
  };

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'COMPANY LIST');

  sectorName: '';
  companySector: any[] = [];
  title = 'Add company';
  responseData: any[] = [];
  btn = 'save';

  constructor(
    private companyService: CompanyService,
    private modalService: NgbModal,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public alert: AlertService,
    public table: TableService,
    public userService: UserService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Company', url: null },
      { name: 'Company Management', url: '/user/company' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getCompany();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnInit(): void {
    this.getSector();
  }

  ngViewInit() {}

  selectedSector(event: any) {
    this.companys.companySector = event.sectorId;
    this.sectorName = event.sectorName;
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.responseData = res.data;
        this.renderTable();
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  getSector() {
    this.common.loading = true;
    this.companyService.getSector().subscribe(
      (res: any) => {
        this.common.loading = false;
        this.companySector = res.data;
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
        //
      }
    );
  }

  saveCompany(): void {
    let params = {
      companyId: this.companys.companyId,
      companyName: this.companys.companyName,
      companySector: this.companys.companySector,
      companyStatus: this.companys.companyStatus,
    };
    this.common.loading = true;
    this.companyService.saveCompany(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alert.success(res.message);
        } else {
          this.alert.error(res.message);
        }
        this.resetDetail();
        this.getCompany();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  resetDetail() {
    this.companys = {
      companyId: '',
      companyName: '',
      companySector: '',
      companyStatus: 'ACTIVE',
    };
    this.btn = 'save';
    this.title = 'Add Company';
    this.sectorName = '';
    this.companyNameValidator = null;
    this.sectorValidator = null;
  }

  setActiveCompany(item?: any) {
    this.common.params = { details: [item], title: 'Company Details' };

    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  openConfirmAlert(item?: any) {
    this.common.params = {
      title: 'Delete Company',
      description:
        `<b>&nbsp;` +
        'Are you sure to delete ' +
        item.companyName +
        ' ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteCompany(item);
      }
    });
  }

  deleteCompany(item?: any) {
    this.common.loading = true;
    let params = {
      companyId: item.companyId,
      companyName: item.companyName,
      companySector: item.sectorId,
      companyStatus: 'DELETED',
    };
    this.companyService.saveCompany(params).subscribe(
      (response: any) => {
        this.common.loading = false;
        if (response.status) {
          this.alert.success(response.message);
        } else {
          this.alert.error(response.message);
        }
        this.getCompany();
      },
      (error) => {
        console.error('Error: ', error);
        this.common.loading = false;
      }
    );
  }

  editCompany(item?: any) {
    this.common.gotoTop();
    this.resetDetail();
    this.companys.companyId = item.companyId;
    this.companys.companyName = item.companyName;
    this.companys.companySector = item.sectorId;
    this.companys.companyStatus = item.companyStatus;
    this.btn = 'update';
    this.title = 'Edit Company Details';
    this.sectorName = item.sectorName;
  }

  companyNameValidator: any;
  sectorValidator: any;
  formValidator() {
    this.companyNameValidator = this.common.nullValidator(
      this.companys.companyName,
      'Company Name Mandatory'
    );
    this.sectorValidator = this.common.nullValidator(
      this.companys.companySector,
      'Company Sector Mandatory'
    );
    if (!this.companyNameValidator.error && !this.sectorValidator.error) {
      this.saveCompany();
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/@core/services/common.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ApiService } from 'src/app/@core/services/api.service';

@Component({
  selector: 'app-product-type',
  templateUrl: './product-type.component.html',
  styleUrls: ['./product-type.component.scss']
})
export class ProductTypeComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  responseMessage: any = '';
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'PRODUCT TYPE LIST');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  productType = {
    productTypeId: null,
    productTypeName: null,
  };

  buttonName = 'save';
  title = 'Add Product Type';
  submitted = false;
  responseData: any[] = [];

  constructor(
    private alertService: AlertService,
    private modalService: NgbModal,
    public common: CommonService,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public api:ApiService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Product', url: null },
      { name: 'Product Type', url: '/user/product-type' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.getAllProductType();
    /** breadcums implementation end*/
  }

  ngOnInit(): void {}
  // Save product Type
  saveproductType(): void {
    let data = {
      productTypeId: this.productType.productTypeId,
      productTypeName: this.productType.productTypeName,
    };
    this.common.loading = true;
    this.api.post("product/save_product-type",data).subscribe(
      (response:any) => {
        this.common.loading = false;
        if (response.status) {
          this.alertService.success(response.message);
        } else {
          this.alertService.error(response.message);
        }
        this.submitted = true;
        this.getAllProductType();
        this.resetproductType();
      },
      (error) => {
        this.common.loading = false;
      }
    );
  }

  getAllProductType() {
    this.common.loading = true;

    this.api.get("product/get-product-type").subscribe(
      (res: any) => {
        this.common.loading = false;

        this.responseData = res.data ?? [];
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  // viewproductType(row?: any) {
  //   let rowUpdated = row;
  //   // delete rowUpdated['productTypeColor'];
  //   // delete rowUpdated['plant'];
  //   this.common.params = {
  //     details: [rowUpdated],
  //     title: 'Process Type Details',
  //   };
  //   const activeModal = this.modalService.open(ViewDetailsComponent, {
  //     size: 'lg',
  //   });
  // }

  confirmDelete(row?: any) {
    this.common.params = {
      title: 'Delete Process Type ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row.label +
        '  ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteproductType(row);
      }
    });
  }

  deleteproductType(row?: any) {
    this.common.loading = true;
    this.api.get("product/delete-product-type?productTypeId="+row.value).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
        } else {
          this.alertService.error(res.message);
        }
        this.getAllProductType();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  editproductType(row?: any) {
    this.common.gotoTop();
    this.resetproductType();
    this.productType.productTypeId = row.value;
    this.productType.productTypeName = row.label;
    this.buttonName = 'update';
    this.title = 'Edit Product Type';
  }

  resetproductType() {
    this.productType = {
      productTypeId: null,
      productTypeName: null,
    };
    this.productTypeNameValidator = null;
    this.buttonName = 'save';
    this.title = 'Add Product Type';
  }

  productTypeNameValidator: any;
  formValidator() {
    this.productTypeNameValidator = this.common.nullValidator(
      this.productType.productTypeName && this.productType.productTypeName.trim(),
      'Product Type Name Mandatory'
    );
    if (!this.productTypeNameValidator.error) {
      this.saveproductType();
    }
  }
}



   <div class="card-header card-main-header">
     <div class="header-block">
       <h3 class="card-title">
         {{title}}
       </h3>
     </div>

     <div>
       <button *ngIf="resetView" class="reset-btn" (click)="resetData()">
         Reset </button>
       <button *ngIf="detailbtnView" class="detail" (click)="detailView()">
       </button>
     </div>

   </div>


   <div class="card-header card-select-header" *ngIf="selectHeader">
     <div class="filters">
       <div class="filter-block" *ngIf="btnOptions">
         <button class="filter-btn" *ngFor="let item of data;index as i" [class]="btnactiveIndex == i ? 'active' : ''"
           (click)="dateparse(item.type,item.number,i)"> {{item.name}}
         </button>
       </div>
       <div class="date-filter" [class]="dateactive ? 'active':''" *ngIf="dateRangepicker">
         <mat-form-field appearance="fill">
           <mat-label>Enter a date range</mat-label>
           <mat-date-range-input [rangePicker]="tripcancelpicker">
             <input matStartDate placeholder="Start date" (dateChange)="startdate($event)" [(ngModel)]="fromDate">
             <input matEndDate placeholder="End date" (dateChange)="endDate($event)" [(ngModel)]="ToDate">
           </mat-date-range-input>
           <mat-datepicker-toggle matSuffix [for]="tripcancelpicker"></mat-datepicker-toggle>
           <mat-date-range-picker #tripcancelpicker></mat-date-range-picker>
         </mat-form-field>
         <div class="option-filter" *ngIf="menuoptions">
           <button mat-button [matMenuTriggerFor]="menu" class="select-menu"><i class="fa fa-bars"
               aria-hidden="true"></i></button>
           <mat-menu #menu="matMenu" class="select-menu-options">
             <button mat-menu-item *ngFor="let item of optionmenu;index as i"
               (click)="menudateParse(item.type,item.number,i)"
               [class]="optionactiveindex == i ? 'active':''">{{item.name}}</button>
           </mat-menu>
         </div>
       </div>

       <div class="date-filter date-compare-filter" *ngIf="dateCompareRangepicker">
         <mat-form-field class="example-form-field" appearance="fill">
           <mat-label>First Date Range</mat-label>
           <mat-date-range-input [rangePicker]="campaignOnePicker" [comparisonStart]="campaignTwo.value.start"
             [comparisonEnd]="campaignTwo.value.end">
             <input matStartDate placeholder="Start date" (dateChange)="fromDate1Change($event)"
               [(ngModel)]="fromDate1">
             <input matEndDate placeholder="End date" (dateChange)="toDate1Change($event)" [(ngModel)]="toDate1">
           </mat-date-range-input>
           <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
           <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
         </mat-form-field>

         <mat-form-field class="example-form-field ml-2" appearance="fill">
           <mat-label>Second Date Range</mat-label>
           <mat-date-range-input [rangePicker]="campaignTwoPicker" [comparisonStart]="campaignOne.value.start"
             [comparisonEnd]="campaignOne.value.end">
             <input matStartDate placeholder="Start date" (dateChange)="fromDate2Change($event)"
               [(ngModel)]="fromDate2">
             <input matEndDate placeholder="End date" (dateChange)="toDate2Change($event)" [(ngModel)]="toDate2">
           </mat-date-range-input>
           <mat-datepicker-toggle matSuffix [for]="campaignTwoPicker"></mat-datepicker-toggle>
           <mat-date-range-picker #campaignTwoPicker></mat-date-range-picker>
         </mat-form-field>
       </div>


       <div class="date-filter month-picker" *ngIf="monthpicker">
         <!-- <mat-form-field>
           <input matInput [matDatepicker]="picker" placeholder="Choose a month" [formControl]="date">
           <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
           <mat-datepicker #picker (monthSelected)="monthSelected($event)" startView="multi-year"></mat-datepicker>
         </mat-form-field> -->


         <mat-form-field appearance="fill">
           <mat-label>Month and Year</mat-label>
           <input matInput [matDatepicker]="dp1">
           <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
           <mat-datepicker #dp1 startView="multi-year" [(ngModel)]="month1" (monthSelected)="monthSelected1($event)"
             panelClass="example-month-picker">
           </mat-datepicker>
         </mat-form-field>

         <mat-form-field appearance="fill">
           <mat-label>Month and Year</mat-label>
           <input matInput [matDatepicker]="dp2">
           <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
           <mat-datepicker #dp2 startView="multi-year" [(ngModel)]="month2" (monthSelected)="monthSelected2($event)"
             panelClass="example-month-picker">
           </mat-datepicker>
         </mat-form-field>

       </div>




     </div>
   </div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { MapModalComponent } from 'src/app/modals/map-modal/map-modal.component';
import { ActionInfoModalComponent } from 'src/app/modals/action-info-modal/action-info-modal.component';
import { AlertCardModalComponent } from '../alert-card-modal/alert-card-modal.component';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-seal-field-report',
  templateUrl: './seal-field-report.component.html',
  styleUrls: ['./seal-field-report.component.scss'],
})
export class SealFieldReportComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  tripId: any = null;
  objTypeValue = [
    'BARCODEWITHIMAGE',
    'RFIDCODEWITHIMAGE',
    'GEOCODEDIMAGE',
    'RFIDCODE',
    'BARCODE',
  ];
  fieldName: any = '';
  modalData: any[] = [];
  reportData: any = {};
  stages: any[] = [];
  dtOptions: any = this.table.options(10, 4, 'Action Dashboard');
  closeResult: string = '';
  selectedTab: any = null;
  sealingCount: any = 0;
  unSealingCount: any = 0;
  barcodeExist:boolean = false;
  filteredActions: any[] = [];
  txnCompletionTime: any;
  invoiceTripNumber: any = '';
  base64Image: any = '';
  fileName:string='';

  constructor(
    public table: TableService,
    public common: CommonService,
    private modalService: NgbModal,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.route.snapshot.queryParams.tripId) {
      this.invoiceTripNumber = this.route.snapshot.queryParams.invNo;
      this.tripId = this.route.snapshot.queryParams.tripId;
      this.getTxnCompletionTime(this.tripId);
    }
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  actionLabels: any[] = [];

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  getTxnCompletionTime(txnID: any) {
    this.common.loading = true;
    this.apiService
      .get('transaction/get-txn-completion-time?txnId=' + txnID)
      .subscribe(
        (res: any) => {
          if (res.status) {
            this.txnCompletionTime = res.data[0];
            if (this.txnCompletionTime != '') {
              const specificDate = new Date('2024-02-28T01:30:00.000Z');
              const txnCompletionDate = new Date(this.txnCompletionTime);
              if (txnCompletionDate < specificDate) {
                const tripId = this.tripId;
                this.router.navigate(['public-field-audit-report-old'], {
                  queryParams: {tripId: tripId,invNo:this.invoiceTripNumber },
                });
              } else {
                this.getAuditReport();
              }
            } else {
              this.getAuditReport();
            }
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  hasOtherActions() {
    return this.reportData.actionsWithoutPreActions
      ? Object.keys(this.reportData.actionsWithoutPreActions).length != 0
      : false;
  }

  contentModal(content, row: any, preActionName: any, actionName: any) {
    this.modalData = [];
    if (
      row[preActionName] &&
      row[preActionName]['values'] &&
      row[preActionName]['values'][0]
    ) {
      this.modalData.push({
        ...row[preActionName]['values'][0],
        actionName: preActionName,
      });
    }
    if (
      row[actionName] &&
      row[actionName]['values'] &&
      row[actionName]['values'][0]
    ) {
      this.modalData.push({
        ...row[actionName]['values'][0],
        actionName: actionName,
      });
    }
    this.fieldName = row['title'];
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  getAuditReport() {
    this.common.loading = true;
    let params = {
      txnId: parseInt(this.tripId),
    };
    this.apiService.get('report/get-seal-field-report', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.reportData = res.data[0];
          this.fileName = this.reportData.processName+"-"+this.reportData.extTripId;
          this.reportData.actionsWithPreActions.forEach((element) => {
            if (
              element.actionName == 'Unsealing' &&
              element.preActionName == 'Sealing'
            ) {
              element.table_data.forEach((item) => {
                if(item['Sealing']['fieldType']?.includes('BARCODE') || item['Unsealing']['fieldType']?.includes('BARCODE')) this.barcodeExist = true;
                if (
                  item['Sealing'] != null &&
                  item['Sealing'] != '-' &&
                  item['Sealing']['sub_group_index'] != null &&
                  item['Sealing']['fieldType']?.includes('BARCODE')
                ) {
                  // if(item['Sealing'] != null && item['Sealing'] != '-' && item['Sealing']['sub_group_index']  && item['Sealing']['sub_group_index']!=null)
                  this.sealingCount++;
                }
                if (
                  item['Unsealing'] != null &&
                  item['Unsealing'] != '-' &&
                  item['Unsealing']['sub_group_index'] != null &&
                  item['Unsealing']['fieldType']?.includes('BARCODE')
                ) {
                  this.unSealingCount++;
                }
              });
            }
          });
          this.reportData.alertCards.forEach((element) => {
            element.alert_data = JSON.parse(element.alert_data);
          });
          if (this.reportData.actionsWithoutPreActions) {
            const keys = Object.keys(this.reportData.actionsWithoutPreActions);
            if (keys.length > 0) {
              const firstKey = keys.find(
                (key) => key in this.reportData.actionsWithoutPreActions
              );
              if (firstKey) {
                this.selectedTab = firstKey;
                this.getFilteredActions(
                  this.reportData.actionsWithoutPreActions[this.selectedTab]
                );
              }
            }
          }
          this.common.loading = false;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  async getFilteredActions(actionsList: any): Promise<void> {
    return new Promise((resolve) => {
      this.filteredActions = actionsList.filter(
        (e) => e.txnStageDetailId != null
      );
      resolve();
    });
  }

  isImgTypeFile(fileUrl: any) {
    const ext = fileUrl.substring(fileUrl.lastIndexOf('.') + 1);
    return ext == 'png' || ext == 'jpeg' || ext == 'jpg'  || ext == 'gif' || ext == 'webg' || ext == 'svg'  ;
  }

  displayImage(url) {
    let newUrl = decodeURI(typeof url == 'string' ? url : url.fileUrl);
    const arrays = newUrl.split('/');
    // let name = arrays[arrays.length - 1];
    let name = "Image Preview";
    this.common.params = {
      url: newUrl,
      name: name,
    };
    const activeModal = this.modalService.open(DisplayImageComponent, {
      size: 'lg',
    });
  }

  openMap(lat, long) {
    this.common.params = {
      lat: lat,
      lng: long,
    };
    const activeModal = this.modalService.open(MapModalComponent, {
      size: 'lg',
    });
  }

  getFileName(url){
    let newUrl = decodeURI(typeof url == 'string' ? url : url.fileUrl);
    const arrays = newUrl.split('/');
    let name = arrays[arrays.length - 1];
    return name;
  }

  openFileInNewTab(url: string): void {
    window.open(url, '_blank');
}

  viewAlert(data) {
    // let data = JSON.parse(alertData);
    const activeModal = this.modalService.open(AlertCardModalComponent, {
      size: 'xl',
      modalDialogClass: 'Saloni',
    });
    activeModal.componentInstance.refData = {
      alert_type_name: data.title,
      alert_remark: data.remark,
      alert_data: data.data,
      showAlertData: true,
    };
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  async changeTabs(event: any) {
    this.selectedTab = event.tab.textLabel;
    await this.getFilteredActions(
      this.reportData.actionsWithoutPreActions[this.selectedTab]
    );
  }

  // getSelectedTabActions(): any[] {
  //   if (this.reportData.actionsWithoutPreActions && this.reportData.actionsWithoutPreActions[this.selectedTab]) {
  //     return this.reportData.actionsWithoutPreActions[this.selectedTab];
  //   }
  //   return [];
  // }

  actionDetail(action, actionType: any, txnId: any) {
    let activeModal = this.modalService.open(ActionInfoModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });
    activeModal.componentInstance.refData = {
      actionName: action.actionName,
      actionType: actionType,
      stageId: action.stageId,
      txnId: txnId,
      actionId: action.actionId,
      txnStageDetailId: action.txnStageDetailId,
      action_type: 1,
    };
    activeModal.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  @ViewChild('dataToExport', { static: false }) public dataToExport: ElementRef;
  public downloadAsPdf(): void {
    const width = this.dataToExport.nativeElement.scrollWidth;
    const height = this.dataToExport.nativeElement.scrollHeight + 40;

    html2canvas(this.dataToExport.nativeElement, {
      width: width,
      height: height,
      scale: 2, // Increase the scale for higher resolution
      logging: false, // Disable logging for better performance
      allowTaint: true, // Allow tainting of canvas (may help with image loading)
      useCORS: true, // Use CORS to load images (may help with external resources)
      backgroundColor: '#ffffff' // Set background color if needed
       })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg',1.0);

        let doc = new jsPDF({
          orientation: width > height ? 'l' : 'p',
          unit: 'px',
          format: [width, height]
        });

        doc.addImage(imgData, 'JPEG', 0, 0, width, height);
        doc.save(this.fileName+'.pdf');
      })
      .catch((error) => {
        console.error('Error exporting to PDF:', error);
      });
  }

}

import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../@core/services/common.service';
import { ApiService } from '../../@core/services/api.service';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { UserService } from '../../@core/services/user.service';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';

@Component({
  selector: 'app-trip-history',
  templateUrl: './trip-history.component.html',
  styleUrls: ['./trip-history.component.scss'],
})
export class TripHistoryComponent implements OnInit {
  selectedradio: number = 1;
  selectedDriverId: number = null;
  selectedVehicleId: number = null;
  driverDetails:any=null;
  history: any[] = [];
  driversList: any[] = [];
  vehiclesList: any[] = [];
  driverName: string = null;
  vehicleRcNo: string = null;
  plantList: any[] = [];
  selectedPlantId: any;
  selectedPlantName: any;
  vehicleDetails:any[]=[];
  showImage=false;
  imgUrl:string=null;

  constructor(
    private modalService: NgbModal,
    public common: CommonService,
    public api: ApiService,
    public alertService: AlertService,
    public user: UserService,
    public datePipe: DatePipe,
    private breadcrumbService: BreadcrumbService,
    public role: RoleService,
    public plantService: PlantService
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Trips', url: null },
      { name: 'Trip History', url: '/user/trip-history' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.role.user_role == 'COMPANYADMIN'
      ? this.getPlantById()
      : this.getAllDrivers();
  }

  ngOnInit(): void {}

  radioValue = [
    { radio: 'Driver', id: 1 },
    { radio: 'Vehicle', id: 2 },
  ];

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantList = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  onSelectPlant(e: any) {
    this.selectedPlantId = e.plantId;
    this.selectedPlantName = e.plantName;
    this.selectedradio == 1 ? this.getAllDrivers() : this.getAllVehicles();
  }

  selectedDriver(event: any) {
    this.selectedDriverId = event.id;
    this.driverName = event.driver_name;
    this.getDriverHistory();
    this.getDriverDetails();
  }

  selectedVehicle(event: any) {
    this.selectedVehicleId = event.vehicle_id;
    this.vehicleRcNo = event.vehicle_rsn;
    this.showImage=false;
    this.getVehicleHistory();
    this.getVehicleDetails();
  }

  changeRadio() {
    // this.selectedPlantId =null;
    // this.selectedPlantName = '';
    if (this.selectedradio == 1) {
      this.selectedDriverId = null;
      this.driverName = null;
      this.role.user_role != 'COMPANYADMIN' ? this.getAllDrivers() : true;
    } else {
      this.selectedVehicleId = null;
      this.vehicleRcNo = null;
      this.role.user_role != 'COMPANYADMIN' ? this.getAllVehicles() : true;
    }
  }

  getAllDrivers() {
    this.common.loading = true;
    let params = {
      plantId: this.selectedPlantId,
    };
    this.api.get('trip/get-all-drivers', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.driversList = res.data;
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getAllVehicles() {
    this.common.loading = true;
    let params = {
      plantId: this.selectedPlantId,
    };
    this.api.get('trip/get-all-vehicles', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.vehiclesList = res.data;
          this.common.loading = false;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  tripModal(txnid: any, processName: string) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: txnid,
      processName: processName,
    };
  }

  tripTrackingModal(vehicle) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.ipa_transaction_id,
      vehicleName: vehicle.vehicle_rc_no + '-' + vehicle.process_name,
    };
  }

  getDriverHistory() {
    let params = {
      driverId: this.selectedDriverId,
      vehicleId: -1,
    };
    this.common.loading = true;
    this.api.get('trip/view-vehicle-or-driver-history', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.history = [];
        this.history = res.data;
        this.parseLoaders(this.history);
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getVehicleHistory() {
    let params = {
      vehicleId: this.selectedVehicleId,
      driverId: -1,
    };
    this.common.loading = true;
    this.api.get('trip/view-vehicle-or-driver-history', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.history = [];
        this.history = res.data;
        this.parseLoaders(this.history);
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  parseLoaders(list: any) {
    list.forEach((el) => {
      el.loaders = JSON.parse(el.loaders);
    });
  }

  getDriverDetails(){
    let params = {
      driverId: this.selectedDriverId,
    };
    this.common.loading = true;
    this.api.get('driver/get-driver-details', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.driverDetails = res.data[0];
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  banDriver(status:boolean,driverId:number){
    let params = {
      driverId: driverId,
      ban:status
    };
    this.common.loading = true;
    this.api.get('driver/ban-driver', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alertService.info(!status ? "Driver successfully Blocked" : "Driver successfully Unblocked");
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }



  getVehicleDetails(){
    let params = {
      vehicleId: this.selectedVehicleId,
    };
    this.common.loading = true;
    this.api.get('vehicle/get-vehicle-details', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.vehicleDetails=[];
        if(res.data[0].res){
          this.vehicleDetails = JSON.parse(res.data[0].res);
          this.vehicleDetails.forEach((data)=>{
             data.res = JSON.parse(data.res);
          })
        // console.log("fgyu",this.vehicleDetails)
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  previewImage(url){
    console.log("drtghuijk",url)
    this.imgUrl = url;
    this.showImage=true;
  }

  getData(data){
    return JSON.parse(data);
  }
}

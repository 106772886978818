
import { Component, OnInit, ChangeDetectorRef, Input, Output, HostListener, EventEmitter, ChangeDetectionStrategy, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import * as _ from "lodash";
// import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CommonService } from 'src/app/@core/services/common.service';

@Component({
  selector: 'smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class SmartTableComponent implements OnInit {
  @ViewChild('table', { static: false }) pdfTable: ElementRef;
  @Input() titleHeader: string = '';
  @Input() data: any;
  @Input() settings: any;
  @Input() searchFilter:boolean = true;
  @Input() exportIcons:boolean = true;
  @Input() filename: string = 'Table';
  @Output() action = new EventEmitter();
  objectKeys = Object.keys;
  objectValues = Object.values;
  headings = null;
  columns = [];
  sortType = '';
  activeRow = -1;
  activeRows = [];
  customPagevalue = true;
  search = {
    key: '',
    txt: ''
  };

  pages = {
    count: 0,
    active: 1,
    limit: 200,
  };
  isTableHide = false;
  edit = {
    row: -1,
    column: null,
    heading: ''
  };
  selectedRow = 0;
  selectionHeading: any;
  showDropDown: boolean;
  allSelected: boolean = true;
  columnData: any[];
  headingData: any;
  filterLength: boolean = false;
  exportColumns: any[];
  tableId: string;
  searchText: any;
  activeActionRow: number = -1;
  filterKeys: any = {};
  globalFilter: string;
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event) {
    this.keyHandler(event);
  }
  constructor(public common: CommonService
    ,private cdr: ChangeDetectorRef,
    ) {

    }

  ngOnInit() {
    this.tableId = this.filename.replace(/\s+/g, '-') || 'table'
  }


  openPDF(): void {
    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('angular-demo.pdf');
    });
  }

generatePdf() {
  // const doc = require('jspdf');
  const pdfTable = this.pdfTable.nativeElement;
  const doc:any = new jsPDF();
  const filename = String(this.filename);
  // doc.text(85, 14, filename);
  doc.autoTable(
    {
      theme:'grid',
      startY: 15, html: pdfTable,
      styles: { halign: 'left', fontSize: 5, cellWidth: 'auto', valign: 'middle', lineColor:[220,220,220] },
      headStyles: { fillColor: '#4090bd', textColor:'#ffffff' },
      alternateRowStyles: { fillColor: [240, 240, 240] }, tableLineColor: [210,210, 210], tableLineWidth: 0.05,
      body: [
        [{ content: 'Text', colSpan: 2, rowSpan: 2, styles: { halign: 'left' } }],
      ],
    });
  // doc.text(70, doc.autoTable.previous.finalY + lineHeight + offsetY, "Axestrack Software Solutions")
  doc.save(`${this.filename}.pdf`);

}



  getBase64Image(img) {
    var canvas = document.createElement( "canvas" );
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL;
  }

  download() {
    const doc = require('jspdf');
    // const doc = new jsPDF();
    const pdfTable = this.pdfTable.nativeElement;

    doc.autoTable({ startY: 15, html: pdfTable, styles: { halign: 'center' }, headStyles: { fillColor: [124, 95, 240] }, alternateRowStyles: { fillColor: [231, 215, 252] }, tableLineColor: [124, 95, 240], tableLineWidth: 0.1 });

    doc.save(`${this.filename}.pdf`);
  }

  downloadAsPDF() {
    // const doc = new jsPDF();
    const doc = require('jspdf');
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const pdfTable = this.pdfTable.nativeElement;

    doc.fromHTML(pdfTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });

    doc.save(`${this.filename}.pdf`);
  }
  ngOnChanges(changes) {
    this.data = changes.data.currentValue;
    if (changes.settings)
      this.settings = changes.settings.currentValue;

    this.setData();
    this.activeRow = -1;
  }

  ngAfterViewInit() {
    this.setData();
    this.selectedRow = (this.settings && this.settings.arrow) ? 0 : -20;

  }

  keyHandler(event) {
    const key = event.key.toLowerCase();
    let activeId = document.activeElement.id;

    if (this.columns.length && (this.settings && this.settings.arrow)) {
      //
      if ((key.includes('arrowup') || key.includes('arrowdown'))) {
        if (key == 'arrowup' && this.selectedRow != 0) this.selectedRow--;
        else if (this.selectedRow != this.columns.length - 1) this.selectedRow++;
      }
      if (key.includes('enter')) {
        let selectRow = this.columns[this.selectedRow];
        this.action.emit({ 'data': this.columns[this.selectedRow], 'rowcount': this.selectedRow, 'smartId': selectRow._smartId });
      }
    }
  }
  setData() {

    this.columnData = [...this.data.columns];
    this.headings = this.data.headings;
    this.headingData = { ...this.headings };
    if(this.headingData){
      for(let key in this.headingData){
        this.headingData[key].placeholder = this.headingData[key].placeholder.replace(/\s/g, "").replace(/([A-Z])/g, ' $1').trim();
      }
    }
    this.handlePagination(this.pages.active);
    // this.columns = this.data.columns

    this.selectionHeading = this.getSelectionHeaders(this.headings);
    this.cdr.detectChanges();
    if (Object.keys(this.filterKeys).length) {
      for (let key in this.headings) {
        if (key in this.filterKeys) {
          this.headings[key].value = this.filterKeys[key];
        }
      }

      if (this.filterKeys) {
        this.filterData();
      }
    }

    this.pages.count = Math.floor(this.data.columns.length / this.pages.limit);
    if (this.data.columns.length % this.pages.limit) {
      this.pages.count++;
    }

    this.columns.map((column, index) => column._smartId = index);
    this.getExportCOlumns();
  }


  debounceFilter(headings: any) {
    const debounce_filter = _.debounce(() => {
      this.filterData(headings);
    }, 200);
    debounce_filter()
  }


  filterDataOld(key) {
    this.selectedRow = (this.settings && this.settings.arrow) ? 0 : -20;

    let search = this.headings[key].value.toLowerCase();
    this.search = { key, txt: search };
    this.columns = this.data.columns.filter(column => {
      if (!search.length) return true;
      let value = column[key].value;
      if (search.includes('>') || search.includes('<') || search.includes('!')) {
        if (search.length == 1) return true;
        if (search[0] == '>') return value && value > search.split('>')[1]
        else if (search[0] == '<') return value && value < search.split('<')[1];
        else if (search[0] == '!') return value && value != search.split('!')[1];
      } else if (value && value.toString().toLowerCase().includes(search.toLowerCase())) return true;
      return false;
    });

    if (search.includes('>') || search.includes('<') || search.includes('!')) {
      if (search.includes('>')) this.sortColumn(key, 'asc')
      else this.sortColumn(key, 'desc')
    }
    if (!search.length) this.setData();
  }


  filterData(headingsData: any = null) {
    let title = headingsData['title'];
    this.filterKeys[title] = headingsData ? headingsData['value'] : null;
    this.filterLength = !!Object.values(this.filterKeys).filter(Boolean).length;

    var filters = '';
    let valueIndex = -1;
    Object.entries(this.headings).forEach(([key, data]: any, index) => {
      if (data.value) {
        if (!['!', '>', '<'].includes(data.value.trim()[0])) {
          valueIndex += 1;
          filters += `${valueIndex == 0 ? '' : ' &&'} (list['${key}'].value || '').toString().toLowerCase().indexOf('${data.value.toLowerCase()}') !== -1`;
        }


        else {
          let search = data.value[0];
          let searchString = data.value.split(search)[1].toLowerCase();
          if (searchString.trim()) {
            valueIndex += 1;
            filters += `${valueIndex == 0 ? '' : ' &&'} (list['${key}'].value || '') ${search == '!' ? '!=' : search}  '${searchString.toString().toLowerCase()}'`;
          }

          else {
            filters += ``;
          }

        }
      }
    });


    if (filters) {
      this.filterLength = true;
      let filtered: any = Object.entries(this.data.columns).filter(([key, list]: any) => {
        return eval(filters);
      });
      this.columns = this.convertToArray(filtered);
    }

    else {
      this.filterLength = false;
      this.columns = this.columnData
    }

    this.getExportCOlumns();
    this.selectedRow = (this.settings && this.settings.arrow) ? 0 : -20;
    this.cdr.detectChanges();
  }


  // getCSVFromTableId(){
  //   this.common.getCSVFromTableId(this.tableId, null, null, null, null, null, (this.filename || 'table'));
  // }


  comparisonFilter(key) {


    //? another filter  */
    //     if(this.columns.length>0){
    //     this.columns = this.columns.filter(column => {
    //       if (!search.length) return true;
    //       let value = column[key].value;
    //       if (search.includes('>') || search.includes('<') || search.includes('!')) {
    //         if (search.length == 1) return true;
    //         if (search[0] == '>') return value && value > search.split('>')[1]
    //         else if (search[0] == '<') return value && value < search.split('<')[1];
    //         else if (search[0] == '!') return value && value != search.split('!')[1];
    //       } else if (value && value.toString().toLowerCase().includes(search.toLowerCase())) return true;
    //       return false;
    //     });
    // }


    // else{
    let search = this.headings[key].value.toLowerCase();
    this.search = { key, txt: search };
    this.columns = this.data.columns.filter(column => {
      if (!search.length) return true;
      let value = column[key].value;
      if (search.includes('>') || search.includes('<') || search.includes('!')) {
        if (search.length == 1) return true;
        if (search[0] == '>') return value && value > search.split('>')[1]
        else if (search[0] == '<') return value && value < search.split('<')[1];
        else if (search[0] == '!') return value && value != search.split('!')[1];
      } else if (value && value.toString().toLowerCase().includes(search.toLowerCase())) return true;
      return false;
    });

    if (search.includes('>') || search.includes('<') || search.includes('!')) {
      if (search.includes('>')) this.sortColumn(key, 'asc')
      else this.sortColumn(key, 'desc')
    }
    if (!search.length) this.setData();

    //? another filter  ends */
  }


  sortColumn(key, sortType?) {
    let counts = {
      object: 0,
      string: 0,
      number: 0,
      time: 0,
      date: 0
    };

    const numberPattern = new RegExp(/^[+-]?\d+(\.\d+)?$/);
    // const numberPattern = new RegExp(/^([0-9])*(\.)([0-9])*$/);
    const datePattern = new RegExp(/([0-2][0-9]|(3)[0-1])( |\/|-|)([a-zA-Z]{3})( |\/|-|)(([0-1][0-9])|([2][0-3]){2})(:)([0-5][0-9])$/);
    const timePattern = new RegExp(/^([0-9])*(\:)([0-9])*$/);

    this.columns.forEach(column => {
      let value = column[key].value
      if (datePattern.test(value)) counts.date++
      else if (numberPattern.test(value)) counts.number++;
      else if (timePattern.test(value)) counts.time++;
      else if (typeof value == 'string') counts.string++;
      else counts.object++;
    });



    this.columns = this.data.columns.sort((a, b) => {
      if (this.headings[key].type === 'date') {
        let firstDate: any = a[key].value ? this.common.dateFormatter(a[key].value) : 0;
        let secondDate: any = b[key].value ? this.common.dateFormatter(b[key].value) : 0;
        return firstDate > secondDate ? 1 : -1;
      } else if (counts.time > counts.number) {
        let firstValue = a[key].value ? parseFloat(a[key].value.replace(':', '.')) : 0;
        let secondValue = b[key].value ? parseFloat(b[key].value.replace(':', '.')) : 0;
        return firstValue - secondValue;
      } else if (!counts.number) {
        let firstValue = a[key].value ? a[key].value.toLowerCase() : '';
        let secondValue = b[key].value ? b[key].value.toLowerCase() : '';
        if (firstValue < secondValue) //sort string ascending
          return -1
        if (firstValue > secondValue)
          return 1
        return 0
      } else {
        return a[key].value - b[key].value;
      }
    });
    this.setData();
    if (sortType == 'desc' || this.sortType == 'desc') this.columns.reverse();
    this.sortType = this.sortType == 'desc' ? 'asc' : 'desc';
  }

  handleRowClick(event, column, index) {
    if (column.rowActions.click == 'selectRow') this.activeRow = column._smartId;
    else if (column.rowActions.click == 'selectMultiRow') {
      if (this.activeRows.indexOf(column._smartId) === -1) {
        this.activeRows.push(column._smartId);
      } else {
        this.activeRows.splice(this.activeRows.indexOf(column._smartId), 1);
      }
    } else {
      if (column.rowActions && column.rowActions.stopPropagation) {
        event.stopPropagation();
      }
      column.rowActions.click();
    }
  }

  isItActive(column) {
    if (column && column.rowActions)
      if (column.rowActions.click == 'selectRow' && column._smartId === this.activeRow)
        return true;
      else if (column.rowActions.click == 'selectMultiRow' && this.activeRows.indexOf(column._smartId) !== -1)
        return true;
    return false;
  }


  handleColDoubleClick(column, heading) {

    if (column[heading].colActions && column[heading].colActions.dblclick) {
      column[heading].colActions.dblclick()
    }
  }

  handleMouseHover(column, heading) {
    if (column[heading] && column[heading].colActions && column[heading].colActions.mouseover) {
      column[heading].colActions.mouseover()
    }
  }

  /**
   *
   * @param column Previous Column
   * @param heading Column key
   */
  handleMouseOut(column, heading) {
    if (column[heading] && column[heading].colActions && column[heading].colActions.mouseout) {
      column[heading].colActions.mouseout()
    }
  }

  /**
   * @param page Clicked Page
   */
  handlePagination(page) {
    this.pages.active = page;
    let startIndex = this.pages.limit * (this.pages.active - 1);
    let lastIndex = (this.pages.limit * this.pages.active);
    this.columns = this.data.columns.slice(startIndex, lastIndex);
  }

  customPage() {
    this.common.loading = true;
    this.isTableHide = true;
    this.setData();
    setTimeout(() => {
      this.common.loading = false;
      this.isTableHide = false;
    }, 100);
  }

  /**
   * @param column Table Column
   * @param heading Table Heading Name
   * @param rowIndex Clicked row index
   */
  handleColumnClick(event, column: any, heading: string, rowIndex: number) {
    if (column[heading].isCheckbox || column[heading].isAutoSuggestion) {
      event.stopPropagation();
      return;
    };
    if (column[heading].action) {
      event.stopPropagation();
      column[heading].action();
    } else if (this.settings.editable) {
      event.stopPropagation();
      this.edit.row = rowIndex;
      this.edit.column = JSON.parse(JSON.stringify(column));
      this.edit.heading = heading;
    } else if (heading.toLowerCase() === 'action') {
      event.stopPropagation();
    }
  }

  /**
   * @param column Current Value
   */
  resetColumn(column?) {
    this.columns[this.edit.row] = column || this.edit.column;
    this.edit.row = -1;
    this.edit.column = null;
    this.edit.heading = '';
  }

  /**
   * @param editedColumn Current Values of column
   */
  saveEdit(editedColumn: any) {
    this.settings.editableAction({ current: editedColumn, old: this.edit.column });
    this.resetColumn(editedColumn);
  }

  /**
   * Hanle row selection
   * @param event - Checkbox change event
   * @param action - Action to perform on checkbox click
   */
  handleCheckboxChange(event, action) {
    action(event.target.checked);
    event.stopPropagation();
  }

  isEventBinding(column, property, event) {
    column[property] && column[property](event);
  }

  isPropertyBinding(column, property, byDefault = '') {
    if (column[property]) return column[property];
    return byDefault;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }


  getSelectionHeaders(headerData: any) {
    let headers = [];
    for (let item in headerData) {
      headers.push({
        key: item,
        checked: true,
        show: true,
        value: item.replace(/_/g, ' ')
      })
    };

    return headers;
  }


  handleSelection(selected: boolean) {
    this.selectionHeading.forEach(item => item.checked = selected);
    let keys = this.selectionHeading.filter(item => item.checked).map(header => header.key);
    keys.push('rowActions', '_smartId');
    this.toggleColumns(keys);
    this.filterData();
    this.cdr.detectChanges();
  }

  toggleColumns(keys: string[]) {
    let headingArr = Object.entries(this.headingData).filter(([key]) => keys.includes(key));
    this.headings = this.arrayToObject(headingArr);
    [...this.data.columns].forEach((item: any, index: number) => {
      let filteredColumns = Object.entries(item).filter(([key, value]: any) => keys.includes(key));
      this.columns[index] = this.arrayToObject(filteredColumns)
    });

    this.getExportCOlumns();
    this.filterData();
    this.cdr.detectChanges();
  }



  shareCheckedList(event: any) {
    this.allSelected = this.selectionHeading.every(item => item.checked);
    let keys = this.selectionHeading.filter(item => item.checked).map(header => header.key);
    keys.push('rowActions', '_smartId');
    this.toggleColumns(keys);
  }

  arrayToObject(data: any) {
    let obj = data.reduce((acc, current) => {
      acc[current[0]] = current[1];
      return acc;
    }, {});
    return obj;
  }


  getExportCOlumns() {
    this.exportColumns = [];
    this.columns.forEach((item) => {
      let column = {};
      for (let heading in item) {
        if (heading != '_smartId' && heading != 'rowActions') {
          column[heading] = item[heading].value;
        }
      }
      this.exportColumns.push(column);
    });
  }


  convertToArray(data: any) {
    let dataArr = [];
    data.forEach(([_, value]) => {
      dataArr.push(value);
    });
    return dataArr;
  }

  exportCSV() {
    if (!this.columns.length) {
      // this.common.showError('No Data Found')
    } else {
      this.common.getCSVFromDataArray(this.exportColumns, this.headings, this.tableId)
    }
  }

  exportExcel(){
    let data = this.exportColumns;
    this.common.exportExcel(data,this.filename);
  }

  onClick(event) {
    let smartTableTarget = document.querySelector('.smart-table-toggle');
    let toggle = document.querySelector('.dropdown-toggle');
    if (this.showDropDown && event.target != toggle && !smartTableTarget.contains(event.target)) {
      this.showDropDown = false;
    }

    if (!event.target.matches('.tableFixHead table, .tableFixHead table *')) {
      this.activeActionRow = -1;
    }

  }


  toggleFilter() {
    this.selectionHeading.forEach((item) =>
      item.show = item.value.toLowerCase().includes(this.searchText.trim().toLowerCase()));
  }

  iconCheck(iconClass: any) {
    return (
      iconClass.includes('check-square') ||
      iconClass.includes('comments') ||
      iconClass.includes('hand-lizard') ||
      iconClass.includes('thumbs-up'))
  }


  clearFilters() {
    this.filterKeys = {};
    this.filterLength = false;
    this.columns = this.columnData
    for (let key in this.headings) {
      delete this.headings[key].value;
    }
  }

  getValueString(data: any) {
    let dataValues = [];
    for (let key in data) {
      if (key !== '_smartId') {
        dataValues.push(data[key].value);
      }
    }
    return dataValues.join('').toLowerCase();
  }


  clearAllFilter() {
    this.globalFilter = '';
    this.columns = this.data.columns
  }

  allFilter() {
    let filtered: any = Object.entries(this.data.columns).filter(([key, list]: any) => {
      return this.getValueString(list).indexOf(this.globalFilter.trim().toLowerCase()) !== -1;
    });
    this.columns = this.convertToArray(filtered);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { AddFieldModalComponent } from 'src/app/modals/add-field-modal/add-field-modal.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import lodash from 'lodash';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { AssetCategoryField, CompanyData, EditFieldData, groupedDataByCategoryName, PlantData } from 'src/app/dataType/assetInterface';

@Component({
  selector: 'app-asset-category-field',
  templateUrl: './asset-category-field.component.html',
  styleUrls: ['./asset-category-field.component.scss']
})
export class AssetCategoryFieldComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Field List');

  assetCategoryFields = {
    assetCategoryId: null,
    masterFieldId: null,
    rankingId: null,
    isMandatory: 0,
    isUnique: 0,
  };

  plantLevelRoles = ['AXESTRACKUSER', 'COMPANYUSER', 'PLANTADMIN', 'PLANTUSER'];
  plantId: number =null;
  companyId: number =null;
  isEdit: boolean = false;
  categoryValue: number = 1;
  companyData: CompanyData[] = [];
  companyName: String = '';
  plantData: PlantData[] = [];
  plantName: String = '';
  showCategory: boolean = false;
  catagoryFieldMappingList: AssetCategoryField[] = [];
  buttonName: string = 'save';
  categories= [
    {
      asset_category_id: -1,
      asset_category_name: null,
      is_mandatory: false,
      is_unique: false,
      ranking_index: 1,
    },
  ];
  hideCategoryType: boolean = false;
  editCategory: string = null;
  groupedDataByCategoryName: groupedDataByCategoryName;
  editFieldData: EditFieldData = null;
  confirmDelete: boolean = false;

  constructor(
    public userService: UserService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public role: RoleService,
    public plantService: PlantService,
    public alert: AlertService
  ) {
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    }
    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlants(this.userService._loggedInUser.companyId);
    }

    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Assets', url: null },
      { name: 'Category Field Mapping', link: '/user/asset-category-fields' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
    if (this.plantLevelRoles.includes(this.role.user_role)) {
      this.plantId = this.userService._loggedInUser.plantId;
    }
    this.getCatagoryFieldmapping();
  }


  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }



  getCompany() {
    this.common.loading=true
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlants(event.companyId);
    this.getCatagoryFieldmapping();
  }

  getPlants(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getCatagoryFieldmapping();
  }


  //asset category id =-1 for asset category name =All category
  getCatagoryFieldmapping() {
    this.common.loading = true;
    let params = {
      companyId: this.companyId,
      plantId: this.plantId,
    };
    this.api
      .get('asset_categories/get-all-category-field-mapping', params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.catagoryFieldMappingList = res.data;
          this.groupedDataByCategoryName = lodash.groupBy(
            this.catagoryFieldMappingList,
            'field_name'
          );
          console.log('this.groupedDataByCategoryName: ', this.groupedDataByCategoryName);
          const keys = Object.keys(this.groupedDataByCategoryName);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (
              this.groupedDataByCategoryName[key][0].asset_category_id == -1
            ) {
              this.groupedDataByCategoryName[key][0].asset_category_name ='All Categories';
            }
          }
          this.renderTable();
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
        }
      );
  }

  formatNames(dataArray: any): string {
    return dataArray.map((item) => item.asset_category_name).join(', ');
  }

  editMapping(row?: any) {
  console.log('row: ', row);
    this.categories = [];
    if (!(row.value[0].company_id == 1 && row.value[0].plant_id == 1)) {
      this.plantName = row.value[0].plant_name;
      this.companyName = row.value[0].company_name;
    }
    this.isEdit = true;
    this.hideCategoryType = true;
    this.editCategory = row.key;
    this.buttonName = 'update';
    this.showCategory = false;
    row.value.forEach((element) => {
      let category = {
        asset_category_id: element.asset_category_id,
        asset_category_name: element.asset_category_name,
        is_mandatory: element.is_mandatory,
        is_unique: element.is_unique,
        ranking_index: element.ranking_index,
        is_ranking_changed: false,
        suggested_index: element.ranking_index,
      };
      this.categories.push(category);
    });

    let masterFieldDto = {
      masterFieldId: row.value[0].master_field_id,
      fieldName: row.value[0].field_name,
      fieldStatus: 'ACTIVE',
      fieldType: row.value[0].field_type,
      masterFieldValues: row.value[0].master_field_values,
      hardwareResponse: null,
      databaseLinkedId: null,
      fieldValue: 'USERINPUT',
      subGroups: null,
      fieldEntity: 'ASSET',
    };

    this.editFieldData = {
      isEdit: true,
      categories: this.categories,
      masterFieldDTO: masterFieldDto,
      editedCompanyId:row.value[0].company_id,
      editedPlantId:row.value[0].plant_id,
    };
    this.addEditField(this.editFieldData);
  }

  confirmDeleteMapping(row?: any) {
    this.common.params = {
      title: 'Delete Mapping ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete ' +
        row.key +
        ' ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });
  }

  delete(row?: any) {
    let param = {
      fieldId: row.value[0].master_field_id,
      confirmDelete: this.confirmDelete,
    };
    this.api
      .get('asset_categories/delete-category-field-mapping', param)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.alert.success(res.message);
            this.getCatagoryFieldmapping();
          } else {
            this.confirmDeleteMappingAfterCheck(row, res.message);
          }
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
        }
      );
  }

  confirmDeleteMappingAfterCheck(row?: any, msg?: any) {
    this.common.params = {
      title: 'Delete Mapping ',
      description:
        `<b>&nbsp;` +
        msg +
        'Do you still want to delete ' +
        row.key +
        ' ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.confirmDelete = true;
        this.delete(row);
      }
    });
  }

  addEditField(editData: any) {
    const activeModal = this.modalService.open(AddFieldModalComponent, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
    });
    activeModal.componentInstance.refData = {
      field: editData != null ? this.editFieldData : null,
      plantId: editData != null ? this.editFieldData.editedPlantId : this.plantId,
      companyId: editData != null ? this.editFieldData.editedCompanyId : this.companyId
    };
    activeModal.closed.subscribe((res: any) => {
        this.getCatagoryFieldmapping();
    });
  }

  resetCompanyPlant() {
    this.plantId = null;
    this.plantName = null;
    this.plantData = [];
    this.companyId = null;
    this.companyName = null;
    this.getCatagoryFieldmapping();
  }
}

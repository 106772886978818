import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { PlantService } from '../plant/plant.service';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { InsuranceDetail } from 'src/app/dataType/assetInterface';
import { validator } from 'src/app/dataType/commonInterface';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-insurance',
  templateUrl: './add-insurance.component.html',
  styleUrls: ['./add-insurance.component.scss'],
})
export class AddInsuranceComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 6, 'Insurance List');


  // declaring variables

  @Input() public refData;

  insurance={
    insuranceId:null,
    insuranceCompanyName:'',
    policyNo:'',
    contactPersonName:'',
    contactPersonNo:null,
    startDate:null,
    endDate:null,
    coverage:'',
    limit:'',
    premiumAmount:null,
    deductableAmount:null,
    status:'ACTIVE',
  }
    insuranceList:InsuranceDetail[]=[];
    buttonName:string= 'save';
    isModalOpen:boolean=false;
    assetId:number=null;
    showFields:boolean=false;
    insuranceCompanyName1:String='';
    minStartTime:string=this.datePipe.transform(new Date(),'dd-mm-YYYY')

    //validators
    insuranceIdValidator:validator=null;
    companyNameValidator:validator=null;
    policyNoValidator:validator=null;
    contactPersonValidator:validator=null;
    contactPersonNoValidator:validator=null;
    startDateValidator:validator=null;
    endDateValidator:validator=null;
    deductableAmountValidator:validator=null;
    primumAmountValidator:validator=null;


    // Consutructor
  constructor(
    public userService: UserService,
    public common: CommonService,
    public table: TableService,
    public api: ApiService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public role: RoleService,
    public plantService : PlantService,
    public alert : AlertService,
    public activeModal:NgbActiveModal,
    public datePipe:DatePipe
  ) {
    // first calling the company
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Assets', url: null },
      { name: 'Add-Insurance', link: '/user/add-insurance' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
   }


  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnInit(): void {
    if(this.refData!=null && this.refData!=undefined){
      this.isModalOpen = this.refData?.isModalOpen ?? null;
      this.assetId=this.refData?.assetId??null;
    }
    this.getInsuranceList();
  }



  getCurrentDate(){
    return new Date();
  }
  onSelectInsurance(event:any){
    this.insuranceCompanyName1=event.insurance_company_name;
    this.insurance.insuranceId=event.id;
  }
  onToggle(){
    this.showFields=!this.showFields;
    this.reset();
  }
  closeModal() {
    this.activeModal.close();
  }
  getInsuranceList(){
    this.common.loading = true;
    let params={
      allInsurance:!this.isModalOpen
    }
    this.api.get("asset-insurance/get-insurance-list",params).subscribe(
      (res:any)=>{
        this.common.loading = false;
        this.insuranceList=res.data;
        if(!this.isModalOpen){
          this.renderTable();
        }
      },
      (err:any)=>{
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  saveInsurance(){
    this.common.loading=true;
    let params= {
      assetId:this.assetId,
      insuranceId : this.insurance.insuranceId,
      insuranceCompanyName:this.insurance.insuranceCompanyName.trim(),
      policyNo:this.insurance.policyNo,
      contactPersonName :  this.insurance.contactPersonName,
      contactPersonNo :  this.insurance.contactPersonNo,
      startDate :  this.insurance.startDate,
      endDate :  this.insurance.endDate,
      coverage :  this.insurance.coverage,
      limit :  this.insurance.limit,
      premiumAmount :  this.insurance.premiumAmount,
      deductableAmount :  this.insurance.deductableAmount,
      status :  this.insurance.status
    }
    this.api.post('asset-insurance/save-insurance',params).subscribe(
      (res: any) => {
        if(res.status){
           this.alert.success(res.message);
        }
        this.common.loading = false;
        this.reset();
        this.closeModal();
        this.getInsuranceList();
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );

  }

  saveMapping(){
    this.common.loading=true;
    let params= {
      assetId:this.assetId,
      insuranceId : this.insurance.insuranceId,
    }
    this.api.get('asset-insurance/save-insurance-mapping',params).subscribe(
      (res: any) => {
        if(res.status){
           this.alert.success(res.message);
        }
        this.common.loading = false;
        this.reset();
        this.closeModal();
        this.getInsuranceList();
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  reset(){
    this.insurance={
      insuranceId:null,
      insuranceCompanyName:'',
      policyNo:'',
      contactPersonName:'',
      contactPersonNo:null,
      startDate:null,
      endDate:null,
      coverage:'',
      limit:'',
      premiumAmount:null,
      deductableAmount:null,
      status:'ACTIVE',
    }
    this.buttonName='save';
    this.insuranceCompanyName1='';
    this.companyNameValidator=null;
    this.policyNoValidator=null;
    this.contactPersonValidator=null;
    this.contactPersonNoValidator=null;
    this.startDateValidator=null;
    this.endDateValidator=null;
    this.deductableAmountValidator=null;
    this.primumAmountValidator=null;
    this.insuranceIdValidator = null
  }

  viewInsuranceDetails(row?:any){
  const InsuranceDetails = {
    company_name: row.company_name,
    contact_person_name: row.contact_person_name,
    contact_person_no: row.contact_person_no,
    coverage: row.coverage,
    deductable_amount: row.deductable_amount,
    end_date: row.end_date,
    insurance_company_name: row.insurance_company_name,
    insurance_limit: row.insurance_limit,
    plant_name: row.plant_name,
    policy_no: row.policy_no,
    premium_amount: row.premium_amount,
    start_date: row.start_date,
    status: row.status
  };

    this.common.params = { details: [InsuranceDetails], title: 'Insurance Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

    editManufacturer(row?:any){
     this.reset();
     this.common.gotoTop();
     this.buttonName='update';
     this.insurance.insuranceId=row.id,
     this.insurance.insuranceCompanyName=row.insurance_company_name,
     this.insurance.policyNo=row.policy_no,
     this.insurance.contactPersonName=row.contact_person_name,
     this.insurance.contactPersonNo=row.contact_person_no,
     this.insurance.startDate=row.start_date,
     this.insurance.endDate=row.end_date,
     this.insurance.coverage=row.coverage,
     this.insurance.limit=row.insurance_limit,
     this.insurance.premiumAmount=row.premium_amount,
     this.insurance.deductableAmount=row.deductable_amount,
     this.insurance.status='ACTIVE'
  }

  confirmDeleteManufacturer(row?:any){
    this.common.params = {
      title: 'Delete Manufacturer ',
      description:
        `<b>&nbsp;` +
        'Are you sure you want to delete Insurance' +
        ' ?' +
        `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });
  }

  delete(row: any) {
    let param={
      assetId:this.assetId==null?-1:this.assetId,
      insuranceId:row.id,
      status:'DELETED'
    }
    this.api.post("asset-insurance/save-insurance",param).subscribe(
      (res:any)=>{
        this.common.loading=false;
        if(res.status){
          this.alert.success(res.message);
          this.getInsuranceList();
        }
      },
      (err:any)=>{
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }



  formValidator(){
    if(!this.showFields && this.isModalOpen){
      this.insuranceIdValidator=this.common.nullValidator(
        this.insurance.insuranceId,'Select Insurance Mandatory'
        );
        if(!this.insuranceIdValidator.error){
            this.saveMapping();
        }
    }
    else{
    this.companyNameValidator=this.common.nullValidator(
        this.insurance.insuranceCompanyName,'Insurance Compnay Name Mandatory'
        );
    this.policyNoValidator=this.common.nullValidator(
        this.insurance.policyNo,'Policy No  Mandatory'
        );
    this.contactPersonValidator=this.common.nullValidator(
        this.insurance.contactPersonName,'Contact Person Name Mandatory'
        );
    this.contactPersonNoValidator=this.common.mobileNoValidator(
        this.insurance.contactPersonNo,'Contact Person No  Mandatory'
        );
    this.startDateValidator=this.common.nullValidator(
        this.insurance.startDate,'Start Date  Mandatory'
        );
    this.endDateValidator=this.common.nullValidator(
        this.insurance.endDate,'End Date Mandatory'
        );
    this.deductableAmountValidator=this.common.nullValidator(
        this.insurance.deductableAmount,'Deductable Amount Mandatory'
        );
    this.primumAmountValidator=this.common.nullValidator(
        this.insurance.premiumAmount,'Premium Amount Mandatory'
        );
        if(!this.companyNameValidator.error&&
          !this.contactPersonNoValidator.error&&
          !this.contactPersonValidator.error&&
          !this.startDateValidator.error&&
          !this.endDateValidator.error&&
          !this.deductableAmountValidator.error&&
          !this.primumAmountValidator.error){
             this.saveInsurance();
          }
    }
    }

}

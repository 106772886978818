<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_component_access')==true ||userService.permissionMap.get('edit_component_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Component'|customTranslate}} {{'management'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="componentName" class="required"> {{('Component'|customTranslate)+' '+('name'|customTranslate)}}
                  </label>
                  <input class="form-control" type="text" name="componentName" id="componentName" required
                    placeholder="Enter Component Name" [(ngModel)]="component.componentName" #name="ngModel"
                    autocomplete="off" (keyup)="setComponentCode();">
                  <div class="error" *ngIf="componentNameValidator && componentNameValidator.error">
                    <span>{{componentNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{('Component'|customTranslate)+' '+('type'|customTranslate)}} </label>
                  <select name="componentType" id="#componentType" class="form-control"
                    [(ngModel)]="component.componentType" (change)="onComponentTypeChange($event)">
                    <option selected="SUB_MENU" value="SUB_MENU">Sub-Menu</option>
                    <option value="MENU">Menu</option>
                    <option value="FUNCTIONALITY">Functionality</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'platform'|customTranslate}} </label>


                  <div class="form-check form-check-inline">
                    <label class="form-check-label mr-3" for="web_platform">
                      <input class="form-check-input" [(ngModel)]="component.platform"
                        (ngModelChange)="setComponentCode()" type="radio" id="web_platform" value="WEB">
                      {{'web'|customTranslate}}
                    </label>

                    <label class="form-check-label" for="app_platform">
                      <input class="form-check-input" [(ngModel)]="component.platform"
                        (ngModelChange)="setComponentCode()" type="radio" id="app_platform" value="APP">
                      {{'app'|customTranslate}}
                    </label>
                  </div>

                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'parent_component'|customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedParentComponent($event)" [data]="componentData"
                    display="componentName" placeholder="Choose Parent Component"
                    [preSelected]="{componentName:componentName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="parentValidator && parentValidator.error">
                    <span>{{parentValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{('Component'|customTranslate)+' '+('api_url'|customTranslate)}}</label>
                  <input class="form-control" type="text" name="componentApiUrl" [(ngModel)]="component.componentApiUrl"
                    [readOnly]="component.platform == 'APP'" (ngModelChange)="apiUrlChange()"
                    placeholder="Enter Component Path">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{('Component'|customTranslate)+' '+('status'|customTranslate)}} </label>
                  <select name="componentStatus" id="#componentStatus" class="form-control"
                    [(ngModel)]="component.componentStatus">
                    <option selected="ACTIVE" value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{('Component'|customTranslate)+' '+('code'|customTranslate)}}</label>
                  <input type="text" class="form-control" name="componentCode" required readonly
                    placeholder="Enter Component Code" [(ngModel)]="component.componentCode" #componentCode="ngModel"
                    autocomplete="off">
                  <div class="error" *ngIf="componentCodeValidator && componentCodeValidator.error">
                    <span>{{componentCodeValidator.msg}} </span>
                  </div>
                </div>


                <div class="row form-group action-block">
                  <div class="btn-block">
                    <button class="btn btn-muted" (click)="common.backClicked()"> {{'back'|customTranslate}} </button>
                    <button class="btn btn-warning" (click)="resetDetail()"> {{'reset'|customTranslate}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn|customTranslate}} </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{('Component'|customTranslate)+' '+('list'|customTranslate)}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{'serial_no'|customTranslate}}</th>
                    <th>{{'name'|customTranslate}}</th>
                    <th>{{('Component'|customTranslate)+' '+('code'|customTranslate)}}</th>
                    <th>{{'type'|customTranslate}} </th>
                    <th>{{'parent_component'|customTranslate}}</th>
                    <th>{{'platform'|customTranslate}}</th>
                    <th>{{'status'|customTranslate}}</th>
                    <th class="action"> {{'Actions'|customTranslate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor=" let item of allComponentData; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.componentName}}</td>
                    <td>{{item.componentCode}}</td>
                    <td>{{item.componentType}}</td>
                    <td>{{item.parentComponentName}}</td>
                    <td>{{item.platformType}}</td>
                    <td>{{item.componentStatus}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="setActiveComponent(item)"
                          *ngIf="userService.permissionMap.get('view_component_access')==true"> {{'view'|customTranslate}}
                        </button>
                        <button class="btn btn-success" (click)="editComponent(item)"
                          *ngIf="userService.permissionMap.get('edit_component_access')==true"> {{'edit'|customTranslate}}
                        </button>
                        <button class="btn btn-danger" (click)="openConfirmAlert(item)"
                          *ngIf="userService.permissionMap.get('delete_component_access')==true"> {{'delete'|customTranslate}}
                        </button>
                      </div>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="container-card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <div class="header-block">
                    <h3 class="card-title">
                      {{'asset_label_mapping'|translate}}
                    </h3>
                  </div>
                  <div>
                    <button type="button" class="btn btn-success" (click)="resetMappings() ;editFlag=false;goToLabel()"
                      style="margin-right:5px">
                      + {{'Add Label'|translate}}
                    </button>
                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal"
                      (click)="resetMappings();editFlag=false">
                      + {{'add_label_mapping'|translate}}
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                      <label for="my-input">{{"company" | translate}}</label>
                      <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="company" display="companyName"
                        placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                      </ps-auto-suggestion>
                    </div>

                    <div *ngIf="(role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN')"
                      class="form-group col-xl-3 col-lg-6 col-sm-12">
                      <label for="my-input">{{"plant" | translate}}</label>
                      <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantData"
                        display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                      </ps-auto-suggestion>
                    </div>
                  </div>
                  <div class="form-group action-block"
                    *ngIf="(role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN')">
                    <div class="btn-block">
                      <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{"back" | translate}}
                      </button>
                      <button class="btn btn-warning" (click)="resetDetail()"> {{"reset" | translate}} </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="card-body">
          <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th>{{"s.no." | translate}}</th>
                <th>{{"asset_name" | translate}}</th>
                <th>{{"asset_label" | translate}}</th>
                <th class="action">{{"action" | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of assetLabelMappings; let i = index">
                <td>{{i+1}}</td>
                <td>{{row.asset_name}}</td>
                <td>
                  <span class="badge badge-success"
                    *ngFor="let label of getAssetLabelMappings(row.labels_data).split(',')">{{label}}</span>
                </td>
                <td class="action">
                  <div class="btn-block">
                    <button class="btn btn-success" data-toggle="modal" data-target="#exampleModal"
                      (click)="edit(row);editFlag=true"> {{'edit'|translate}}
                    </button>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 130%">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{'add_label_mapping'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="form-group col-xl-12 col-lg-12 col-sm-12">
            <label for="my-input" class="required">{{"choose_asset" | translate}} </label>
            <ps-auto-suggestion (onSelected)="selectAsset($event)" [data]="assets" display="asset_name"
              placeholder="Choose asset" [preSelected]="{asset_name:assetName}">
            </ps-auto-suggestion>
            <div class="error" *ngIf="assetValidator && assetValidator.error">
              <span>{{ assetValidator?.msg }}</span>
            </div>
          </div>

          <div class="form-group col-xl-12 col-lg-12 col-sm-12">
            <div class="field-selection">
              <label for="my-input" class="required">{{"select_labels" | translate}} </label>
              <div *ngFor="let value of mappings.labels; let i=index" class="mb-2">
                <div class="row">
                  <div class="col-md-10">
                    <ps-auto-suggestion (onSelected)="selectedLabel($event,i)" [data]="assetLabels"
                      display="asset_label_name" placeholder="Choose Label"
                      [preSelected]="{asset_label_name:mappings.labels[i].labelName}"
                      [readonly]="!(checkLabels(value))">
                    </ps-auto-suggestion>
                    <div class="error" *ngIf="labelValidator[i] && labelValidator[i].error">
                      <span>{{ labelValidator[i]?.msg }}</span>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="plus_minus_icon">
                      <span class="input-group-text remove-field" id="my-addon"
                        *ngIf="(role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && value.plantId!=1)
                        || (value.mappingId==null || value.plantId==userService._loggedInUser.plantId)) && ((editFlag==false && i!=0) || (editFlag==true))"
                        (click)="(value.mappingId == null ? true : removeMappings.push(value.mappingId));mappings.labels.splice(i,1);labelValidator.splice(i,1)">
                        <i class="fa fa-trash remove"></i>
                      </span>
                      <span class="input-group-text add-field" id="my-addon" *ngIf="i == mappings.labels.length-1"
                        (click)="addValues()">
                        <i class="fa fa-plus add"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{"close" | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="formValidator()">{{"save_changes" | translate}}</button>
      </div>
    </div>
  </div>
</div>

import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { CommonService } from '../@core/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EllipsisModalComponent } from '../modals/ellipsis-modal/ellipsis-modal.component';

@Directive({
  selector: '[appEllipsisView]',
})

export class EllipsisViewDirective {

  constructor(private el: ElementRef,private renderer:Renderer2,private common:CommonService,private modalService:NgbModal) {}

  ngOnInit() {
    // Apply initial styles (replace with your desired styles)
    this.renderer.addClass(this.el.nativeElement,"ellipsis");
    this.renderer.setAttribute(this.el.nativeElement, 'data-toggle', 'tooltip');
    // Set title attribute based on the input property
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.renderer.setAttribute(this.el.nativeElement, 'title', this.el.nativeElement.textContent);
  }

  @HostListener('click', ['$event']) onClick(event: any) {
    // Access ellipsis element's text content
    const textContent = this.el.nativeElement.textContent;
    this.common.params = {
      details: [textContent],
      title: 'View Data',
      show:false
    };
    const activeModal = this.modalService.open(EllipsisModalComponent, {
      size: 'dynamic',
    });
  }

}

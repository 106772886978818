import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class WorkTypeService extends ApiService{

  saveWorkType(data: any): Observable<any> {
    let apiSubUrl = "process/save_worktype";

    return super.post(apiSubUrl, data);
  }
  getAllWorkType(): Observable<any> {
    let apiSubUrl = "process/list_work_type";

    return super.get(apiSubUrl);
  }




}

<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{title}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="userService._userType == 'AXESTRACKUSER'" class="form-group">
    <label for="my-input">Company </label>
    <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="companyData" display="companyName"
      placeholder="Choose Company" [preSelected]="{companyName:companyName}">
    </ps-auto-suggestion>
  </div>
  <div class="form-group">
    <label for="my-input">Plant</label>
    <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
      placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
    </ps-auto-suggestion>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" outline (click)="saveExternalLoginIds()"
    style="cursor: pointer;">Proceed</button>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { UserService } from 'src/app/@core/services/user.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { flatMap } from 'lodash';

@Component({
  selector: 'app-alert-communication-mapping',
  templateUrl: './alert-communication-mapping.component.html',
  styleUrls: ['./alert-communication-mapping.component.scss'],
})
export class AlertCommunicationMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'Method Management');
  alertList: any = [];
  alertName = null;
  alertConditionName:string = null;
  alertConditionId:number = null;
  alertTypeName:string = null;
  alertTypeId:number = null;
  alertConditionType:any = [];
  companyId = null;
  companyName = null;
  plantId = null;
  plantName = null;
  alertCommunicationList: any = [];
  alertExecution = 0;
  communicationTypeId = null;
  communicationTypeName = null;
  communicationSettingId = null;
  communicationSettingName = null;
  communicationTemplateId = null;
  communicationTemplateName = null;
  status = 1;
  alertId = null;
  communicationTypeList: any = [];
  communicationSettingList: any = [];
  communicationTemplateList: any = [];
  alertNameValidator: any;
  alertConditionValidator: any;
  alertMethodValidator: any;
  alertTypeValidator: any;
  mapOn:string = null;
  mapOnId:number = null;
  alertMapping:any = [{"map_on":"Method","map_id":0},{"map_on":"Communication","map_id":1}];
  alertType = 0;
  btnTxt = 'save';
  companyList: any = [];
  plantList: any = [];
  alertSettings: any = [];
  alertSettingName: any = null;
  alertSettingId: any = null;
  plantNameValidator: any = null;
  companyNameValidator: any = null;
  communicationTypeValidator: any = null;
  communicationTemplateValidator: any = null;
  communicationSettingValidator: any = null;
  masterField: any[] = [{}];
  masterList: any[] = [{}];
  alertConditionData:any = [];
  dataMethodNameAction: any[] = [
    {
      style: 'background-color:#383535',
      border: 'border-color:rgb(148 142 142)',
    },
  ];
  methodsList: any = [];
  methodId: any;
  methodName: any;

  constructor(
    public common: CommonService,
    private modalService: NgbModal,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public plantService: PlantService,
    public role: RoleService,
    public alert: AlertService,
    private table: TableService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Alert', url: null },
      {
        name: 'Alert Communication Mapping',
        url: '/user/alert-communication-mapping',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.getAlertCommunicationData();
    if (this.role.user_role != 'AXESTRACK') {
      this.companyId = this.userService._companyId;
      this.plantId = this.userService._loggedInUser.plantId;
      this.getAllAlerts();
    } else {
      this.plantId = this.userService._loggedInUser.plantId;
    }
  }

  ngOnInit(): void {}
  getActiveTab(event) {
    this.dtOptions = this.table.options(10, 8, 'Method Management');
    this.dtTrigger.unsubscribe();
    this.dtTrigger.next();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getCompanyList();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  addValues(index: any) {
    this.masterField.push({});
    this.masterList.push({});
  }

  reset() {
    if (this.role.user_role == 'AXESTRACK') {
      this.companyId = null;
      this.companyName = null;
      this.plantId = null;
      this.plantName = null;
    }
    this.masterField = [{}];
    this.masterList = [{}];
    this.alertExecution = 0;
    this.alertId = null;
    this.alertName = null;
    this.alertConditionName = null;
    this.alertConditionId = null;
    this.alertTypeName = null;
    this.alertTypeId = null;
    this.alertConditionType = [];
    this.companyId == null ? (this.plantList = null) : true;
    this.communicationTypeId = null;
    this.communicationTypeName = null;
    this.communicationSettingId = null;
    this.communicationSettingName = null;
    this.communicationTemplateId = null;
    this.communicationTemplateName = null;
    this.status = 1;
    this.alertNameValidator = null;
    this.alertConditionValidator = null;
    this.alertTypeValidator = null;
    this.alertType = this.role.user_role == 'AXESTRACK' ? 1 : 0;
    this.btnTxt = 'save';
    this.communicationSettingList = [];
    this.communicationTemplateList = [];
    this.alertSettingId = null;
    this.alertSettingName = null;
    this.plantNameValidator = null;
    this.companyNameValidator = null;
    this.communicationTypeValidator = null;
    this.communicationTemplateValidator = null;
    this.communicationSettingValidator = null;
    this.alertNameValidator = null;
    this.communicationTypeList = null;
    this.mapOnId = null;
    this.mapOn = null;
    this.plantId != null ? this.getAllAlerts() : true;
  }

  formValidator() {
    this.alertNameValidator = this.common.nullValidator(
      this.alertName,
      'Alert Name Mandatory'
    );
    this.alertConditionValidator = this.common.nullValidator(
      this.alertConditionName,
      'Alert Condition Mandatory'
    );
    this.alertTypeValidator = this.common.nullValidator(
      this.alertTypeName,
      'Alert Type Mandatory'
    );
    this.alertMethodValidator = this.common.nullValidator(
      this.methodName,
      'Alert Method Mandatory'
    );
    this.plantNameValidator = this.common.nullValidator(
      this.plantId,
      'Plant Name Mandatory'
    );
    this.companyNameValidator = this.common.nullValidator(
      this.companyName,
      'Company Name Mandatory'
    );
    this.communicationTypeValidator = this.common.nullValidator(
      this.communicationTypeId,
      'Communication Type mandatory'
    );
    this.communicationTemplateValidator = this.common.nullValidator(
      this.communicationTemplateId,
      'Communication Template mandatory'
    );
    this.communicationSettingValidator = this.common.nullValidator(
      this.communicationSettingId,
      'Communication Setting mandatory'
    );
    if (
      !this.alertNameValidator.error && !this.alertConditionValidator.error && !this.alertTypeValidator.error &&
      (this.role.user_role == 'AXESTRACK' || !this.plantNameValidator.error) &&
      ((!this.communicationSettingValidator.error &&
      !this.communicationTemplateValidator.error &&
      !this.communicationTypeValidator.error && this.mapOnId == 1) || (!this.alertMethodValidator.error && this.mapOnId == 0))
    ) {
      this.save();
    } else if (
      ((!this.communicationSettingValidator.error &&
        !this.communicationTemplateValidator.error &&
        !this.communicationTypeValidator.error && this.mapOnId == 1) || (!this.alertMethodValidator.error && this.mapOnId == 0)) &&
      !this.alertNameValidator.error && !this.alertConditionValidator.error && !this.alertTypeValidator.error
    ) {
      this.alert.warn(
        this.alertMethodValidator.msg ||
        this.communicationTypeValidator.msg ||
          this.communicationSettingValidator.msg ||
          this.communicationTemplateValidator.msg
      );
    }
  }

  save() {
    let arr: any[] = [];
    this.masterField.forEach((item: any) => {
      item.plantId = this.plantId;
      item.alertId = this.alertId;
      item.alertConditionId = this.alertConditionId,
      item.alertTypeId = this.alertTypeId
      arr.push(item);
    });

    let params = {
      alertId: this.alertId,
      communicationData: arr,
      plantId: this.plantId,
      alertConditionId:this.alertConditionId,
      alertTypeId:this.alertTypeId,
      methodId:this.methodId,
      mapOn:this.mapOnId
    };

    this.common.loading = true;
    this.api
      .post('alert/save_alert_communication_mapping', params, null)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.getAlertCommunicationData();
            if (res.status) {
              this.alert.success(res.message);
            } else {
              this.alert.success(res.message);
            }
          }
          this.reset();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  selectMapping(event){
    this.mapOn = event.map_on;
    this.mapOnId = event.map_id
    if (event.map_id == 0) {
      this.getAllMethods();
    }
  }

  selectAlertName(event) {
    this.alertId = event.alert_id;
    this.alertName = event.alertTitle;
    console.log(this.alertName)
    this.getAlertConditionByAlertId(this.alertId);
    this.getCommunicationType();
  }

  selectAlertCondition(event){
    this.alertConditionId = event.id;
    this.alertConditionName = event.alert_condition;
    this.alertConditionType = event.alert_type;
  }

  selectAlertType(event){
    this.alertTypeName = event.alert_type;
    this.alertTypeId = event.alert_type_id;
  }

  selectedMethod(event){
    this.methodName = event.methodName;
    this.methodId = event.methodId;
  }

  async selectCommunicationType(event, index) {
    this.communicationTypeId = event.communicationTypeId;
    this.communicationTypeName = event.communicationTypeName;
    this.masterField[index] = {
      communicationTypeName: this.communicationTypeName,
      commTypeId: this.communicationTypeId,
    };
    await this.getCommunicationTemplate(this.communicationTypeId);
    await this.getCommunicationSetting(this.communicationTypeId, index);
  }

  selectCommunicationSetting(event, index) {
    this.communicationSettingId = event.id;
    this.communicationSettingName = event.title;
    this.masterField[index] = {
      communicationTypeName: this.masterField[index].communicationTypeName,
      commTypeId: this.masterField[index].commTypeId,
      commSettingId: this.communicationSettingId,
      communicationSettingName: this.communicationSettingName,
      commTemplateId: this.masterField[index].commTemplateId,
      communicationTemplateName:
        this.masterField[index].communicationTemplateName,
    };
  }

  selectCommunicationTemplate(event, index) {
    this.communicationTemplateId = event.id;
    this.communicationTemplateName = event.templateTitle;
    this.masterField[index] = {
      communicationTypeName: this.masterField[index].communicationTypeName,
      commTypeId: this.masterField[index].commTypeId,
      commSettingId: this.masterField[index].commSettingId,
      communicationSettingName:
        this.masterField[index].communicationSettingName,
      commTemplateId: this.communicationTemplateId,
      communicationTemplateName: this.communicationTemplateName,
    };
  }

  selectCompany(event) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantListByCompanyId(this.companyId);
  }

  selectPlant(event) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getCommunicationType();
    this.getAllAlerts();
  }

  getCommunicationType(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api
        .get('communication/list_communicationType')
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.common.loading = false;
              this.communicationTypeList = res.data;
            }
            resolve(this.communicationSettingList);
          },
          (err) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
    });
  }

  getCommunicationSetting(communicationTypeId, index): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api
        .get(
          'communication/list-communication-setting?plantId=' + this.plantId,
          null
        )
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.common.loading = false;
              this.communicationSettingList = [];
              res.data.forEach((element) => {
                if (element.typeid == communicationTypeId) {
                  this.communicationSettingList.push(element);
                }
                this.masterList[index] = {
                  setting: this.communicationSettingList,
                  template: this.communicationTemplateList,
                };
              });
              resolve(this.communicationSettingList);
            }
          },
          (err) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
    });
  }

  getCommunicationTemplate(communicationTypeId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api
        .get(
          'communication/get-template-by-typeid?communicationTypeId=' +
            communicationTypeId +
            '&plantId=' +
            this.plantId
        )
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.common.loading = false;
              this.communicationTemplateList = res.data;
              resolve(this.communicationTemplateList);
            }
          },
          (err) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
    });
  }

  getAllAlerts() {
    this.common.loading = true;
    this.api.get('alert/get_all_alerts').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if (res.data) {
            this.alertList = res.data;
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAlertCommunicationData() {
    this.common.loading = true;
    this.api.get('alert/get_alert_communication_data', null).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if (res.data) {
            this.alertCommunicationList = res.data;
            this.renderTable();
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getCompanyList() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAlertConditionByAlertId(alertId:number) : Promise<any> {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    let params = {
      "alertId":alertId
    }
    this.api.get('alert/get_alert_condition_by_alertId',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertConditionData = res.data;
        }
        resolve(true);
      },
      (err) => {
        console.error('Error: ', err);
        this.common.loading = false;
        reject(false);
      }
    );
  });
}

  getPlantListByCompanyId(companyId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.api.get('plant/all_plants?id=' + companyId).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.plantList = res.data;
          resolve(this.plantList);
        },
        (err) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    });
  }

  async editAlert(item) {
    this.common.gotoTop();
    this.reset();
    await this.getPlantListByCompanyId(item.companyId);
    this.plantId = item.plantId;
    this.mapOnId = item.mapOn;
    this.mapOn = (this.mapOnId == 0 ? "Method" : "Communication");

    if(item.mapOn == 1){
      for (let i = 0; i < item.data.length; i++) {
        await this.getCommunicationType();
        await this.getCommunicationTemplate(item.data[i].commTypeId);
        await this.getCommunicationSetting(item.data[i].commTypeId, i);
      }
    } else {
      await this.getAllMethods();
    }

    this.alertId = item.alertId;
    this.alertName = item.alertName;
    await this.getAlertConditionByAlertId(this.alertId);
    this.communicationTypeId = item.commTypeId;
    this.communicationTypeName = item.communicationTypeName;
    this.communicationSettingId = item.commSettingId;
    this.communicationSettingName = item.communicationSettingName;
    this.communicationTemplateId = item.commTemplateId;
    this.communicationTemplateName = item.communicationTemplateName;
    this.plantId = item.plantId;
    this.masterField = item.data;
    this.companyId = item.companyId;
    this.companyName = item.companyName;
    this.plantName = item.plantName;
    this.alertConditionId = item.alertConditionId;
    this.alertConditionName = item.alertConditionName;
    this.alertTypeId = item.alertTypeId;
    this.alertTypeName = item.alertTypeName;
    this.methodId = item.data[0].methodId;
    this.methodName = item.data[0].methodName;
    this.btnTxt = 'update';
  }

  viewAlert(item?: any) {
    let updatedItem = item;
    item.data.forEach((res, index) => {
      updatedItem[index] = res;
    });
    this.common.params = { details: [updatedItem], title: 'Method Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  confirmAlert(row?: any) {
    this.common.params = {
      title: 'Delete Driver ',
      description: `<b>&nbsp;` + 'Are you sure want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteAlert(row.mappingid);
      }
    });
  }
  deleteAlert(mappingIds) {
    let params = {
      alertMappingId : mappingIds
    }
    this.common.loading = true;
    this.api
      .get(
        'alert/delete_alert_communication_mapping',params
      )
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.alert.success(res.message);
          this.reset();
          this.getAlertCommunicationData();
        },
        (err) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  getAllMethods(plantId:number=null) : Promise<any> {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    let params = {
      plantId:plantId
    }
    this.api.get('method/get-all-methods-by-type', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if (res.data) {
            this.methodsList = res.data;
          }
          resolve(true)
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
        reject(false)
      }
    );
  } );
}
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserActionServiceService extends ApiService {

  saveUserAction(data: any): Observable<any> {
    let apiSubUrl = "actions/save-user-action-mapping";
    return super.post(apiSubUrl, data);
  }
  getUserAction(): Observable<any>{
    let apiSubUrl = "actions/get-user-action-mapping";
    return super.get(apiSubUrl);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../services/user.service';

@Pipe({
  name: 'customTranslate',
  pure: false
})
export class CustomTranslatePipe implements PipeTransform {


  constructor(public translateService: TranslateService,
    private userService: UserService
  ) {}

  transform(value: any, args?: any): any {
   let translatedValue = this.translateService.instant(value)
   let obj={
      plant:"Port",
      PLANT : "PORT",
      Plant : "Port",
      प्लांट: "पोर्ट"
    }
    const condition = this.userService._plantType == 'PORT';

    if(condition){
      Object.keys(obj).forEach((key) => {
        if (translatedValue.includes(key)) {
          translatedValue = translatedValue.replace(key, obj[key]);
        }
      });
    }

    return translatedValue
  }}

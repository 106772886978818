<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">

        <div class="col-md-12">
          <div class="card">
            <!-- <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  Change Password
                </h3>
              </div>
            </div> -->

            <div class="card-body">
              <form class="login-form  reset-password">
                <div class="row">

                  <div class="col-lg-6 col-md-6 mx-auto pass_box">
                    <div class="title_pass">
                      <h5>Change Password</h5>
                    </div>

                    <div class="password_div">
                      <div class="row_class" *ngIf="!otpvalidated">
                        <label for=""> Current Password : </label>
                        <input type="password" class="form-control" [(ngModel)]="user.password" name="password"
                          placeholder="Enter Current Password">
                      </div>

                      <div class="row_class" *ngIf="showmessage">
                        <label></label>
                        <div class="alert" [ngClass]="{'alert-danger':loginfail,'alert-success':!loginfail}"
                          role="alert">
                          <p class="mb-0"> {{loginmessage}} </p>
                        </div>
                      </div>

                      <div class="row_class mb-0" *ngIf="!otpvalidated">
                        <label></label>
                        <div class="button_div">
                          <button type="button" class="btn btn-success" (click)="authorizeUser()"> Verify </button>
                        </div>
                      </div>

                      <div class="row_class" *ngIf="otpvalidated">
                        <label for=""> New Password : </label>
                        <input type="password" class="form-control" [(ngModel)]="user.newPassword" name="newPassword"
                          placeholder="Enter New Password">
                      </div>


                      <div class="row_class" *ngIf="otpvalidated">
                        <label for=""> Confirm New Password : </label>
                        <input type="password" class="form-control" [(ngModel)]="user.checkPassword"
                          name="checkPassword" placeholder="Re-enter New Password">
                      </div>

                      <div class="error" *ngIf="message">
                        <span>{{message}} </span>
                      </div>

                      <div class="row_class mb-0" *ngIf="otpvalidated">
                        <label></label>
                        <div class="button_div">
                          <button button type="button" class="btn btn-success" (click)="savePassword()"> Save
                            Password</button>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>

              </form>

            </div>

          </div>
        </div>



      </div>

    </div>
  </div>

</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="status_wise" style="display: flex;justify-content: space-between;">
                <div class="left" style="display: flex;">
                  <div class="status">
                    <div data-toggle="modal" data-target="#exampleModall"
                      (click)="tableModalData=this.totalAssetsList;modalTitle='Total Assets'">
                      <div class="small_text" style="color: #212529;;">TOTAL</div>

                      <div class="status_num_graph">
                        <span class="small_num">{{totalAssets}}</span>
                        <i class="fa fa-bar-chart" style="color: #212529;;"></i>
                      </div>
                    </div>
                  </div>

                  <div class="status" *ngFor="let statusData of statusWiseData | keyvalue" data-toggle="modal"
                    data-target="#exampleModall"
                    (click)="tableModalData=statusData?.value;modalTitle=statusData?.key+' '+'Assets'">
                    <div class="small_text" [style.color]="statusColors[statusData?.key][0]">
                      {{ statusData.key }}
                    </div>
                    <div class="status_num_graph">
                      <span class="small_num">{{ statusData.value.length }}</span>
                      <i [ngClass]="statusColors[statusData?.key][1]"
                        [style.color]="statusColors[statusData?.key][0]"></i>
                    </div>
                  </div>
                </div>

                <div class="right mr-3" style="display: flex;">
                  <div class="status">
                    <div data-toggle="modal" data-target="#exampleModal4"
                      (click)="tableModalData=resolvedAlerts?resolvedAlerts:[];modalTitle='Resolved Alerts'">
                      <div class="small_text" style="color: teal;">Resolved Alerts</div>

                      <div class="status_num_graph">
                        <span class="small_num">{{resolvedAlerts ? resolvedAlerts.length : 0}}/{{resolvedAlerts ?
                          resolvedAlerts.length+pendingAlerts.length : pendingAlerts.length }}</span>
                        <i class="fa fa-exclamation-triangle" style="color: teal;"></i>
                      </div>
                    </div>
                  </div>

                  <div class="status">
                    <div data-toggle="modal" data-target="#exampleModal4"
                      (click)="tableModalData=this.pendingAlerts;modalTitle='Pending Alerts'">
                      <div class="small_text" style="color:#e65050;;">Pending Alerts</div>

                      <div class="status_num_graph">
                        <span class="small_num">{{pendingAlerts.length}}</span>
                        <i class="fa fa-exclamation-triangle" style="color:#e65050;;"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row m-0 mt-1">
                <div class="col-md-4 p-0">
                  <div class="department_wise">
                    <div class="subtitle">Department Wise</div>
                    <div *ngIf="ifDeptWiseData()" class="text-center mt-1 fade-in no-assets-tracked">No Department wise
                      Asset</div>
                    <div class="dept_row">
                      <div class="chart_img">
                        <div #chartContainer1></div>
                      </div>

                      <div class="dept_boxes">
                        <div class="dept_box" *ngFor="let row of deptWiseData | keyvalue" data-toggle="modal"
                          data-target="#exampleModal5"
                          (click)="tableModalData=row?.value;modalTitle=row?.key+' '+'Assets'">
                          <i class="fa fa-circle" [style.color]="deptColors[row.key]"></i><span
                            class="small_num">{{row.value.length}}</span>
                          <div class="small_text">{{row.key}}</div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 pl-1 pr-1">
                  <div class="category_wise">
                    <div class="subtitle">Category Wise</div>
                    <div class="cat_top">
                      <div class="cat_left">
                        <div class="cat_num">{{categoryWiseData?.length}}</div>
                        <div class="small_text">Total Categories</div>
                      </div>

                      <div class="cat_right">
                        <input type="text" [(ngModel)]="searchTerm" (input)="onSearchTermChange()" class="form-control">
                        <i class="fa fa-search"></i>
                      </div>
                    </div>

                    <hr>

                    <div class="cat_bottom">
                      <div *ngIf="noCategoryFound" class="no-result-message fade-in">No Categories With This Name</div>
                      <div class="cat_box  " *ngFor="let category of filteredCategories| keyvalue;let i = index"
                        data-toggle="modal" data-target="#exampleModall"
                        (click)="tableModalData=category?.value['asset'];modalTitle=category?.key+' '+'Assets'">
                        <ng-container *ngIf="category?.value?.asset_category_image; else defaultIcon">
                          <img [src]="category?.value?.asset_category_image ?? ''" [alt]="category?.key"
                            class="category-image">
                        </ng-container>
                        <ng-template #defaultIcon>
                          <img src="assets/images/empty_image.jpg">
                        </ng-template>
                        <div class="box_detail">
                          <div class="small_num">{{ category?.value['asset']?.length??0 }}</div>
                          <div class="small_text">{{ category.key }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 p-0">
                  <div class="department_wise">
                    <div class="subtitle">Group Wise</div>
                    <div *ngIf="ifGrpWiseData()" class="text-center mt-1 fade-in no-assets-tracked">No Group wise Asset
                    </div>
                    <div class="dept_row">
                      <div class="chart_img">
                        <div #chartContainer2></div>
                      </div>

                      <div class="dept_boxes">
                        <div class="dept_box" *ngFor="let row of grpWiseData | keyvalue" data-toggle="modal"
                          data-target="#exampleModal5"
                          (click)="tableModalData=row?.value;modalTitle=row?.key+' '+'Assets'">
                          <i class="fa fa-square" [style.color]="grpColors[row.key]"></i><span
                            class="small_num">{{row.value.length}}</span>
                          <div class="small_text">{{row.key}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="zone_sec">
                <div class="subtitle mb-2">Zone Wise Assets Tracking</div>
                <div class="row zone_cards_sec">
                  <div class="col-md-3 zone_card" *ngFor="let zone of assetLocationLog; let i = index">
                    <div class="about_zone" [style.background-color]="zoneColors[i % zoneColors.length]">
                      <div class="zone_name">{{zone.zone_name}}</div>
                      <div class="right_sec">
                        <div class="calculation">Total: <button>{{zone.total_asset}}</button></div>
                      </div>
                    </div>

                    <div class="zone_strength">
                      <ng-container *ngIf="!isZoneDataEmpty(zone)">
                        <div class="strength_row" *ngFor="let category of zone['zone_data']|keyvalue"
                          data-toggle="modal" data-target="#exampleModal5"
                          (click)="tableModalData=category?.value['asset'];modalTitle=category?.key;">
                          <ng-container *ngIf="category?.value['asset_category_image']; else defaultIcon">
                            <img [src]="category?.value['asset_category_image']?? ''" [alt]="category?.key"><span
                              style="font-size: 14px;">{{category.key}}</span>
                          </ng-container>
                          <ng-template #defaultIcon>
                            <img src="assets/images/empty_image.jpg" /><span
                              style="font-size: 14px;">{{category.key}}</span>
                          </ng-template>
                          <button>{{returnMapKeyValue(category.value['asset']).length}}</button>
                        </div>
                      </ng-container>
                      <div *ngIf="isZoneDataEmpty(zone)" class="text-center mt-1 fade-in no-assets-tracked">No Assets In
                        This Zone</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row m-0 mt-1">
                <div class="col-md-3 p-0">
                  <div class="card_boxes">
                    <div class="row m-0" style="height: 330px;">
                      <div class="col-md-6" style="padding-left: 0px; padding-right: 2px; margin-bottom: 4px;">
                        <div class="card_box">
                          <div style="display: flex;flex-direction: column;">
                            <div class="small_num">Maintenance</div>
                            <div style="display: flex;flex-direction: row;justify-content: space-between;">
                              <div style="display: flex;flex-direction: column;align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModal6"
                                (click)="tableModalData=miantenanceExpiredData;modalTitle='Maintenance Overdue'">
                                <div class="number" style="background: #004586;">{{maintenanceData?.length}}</div>
                                <div class="small_num_adv">Overdue</div>
                              </div>
                              <div class="vertical-line"></div>
                              <div style="display: flex;flex-direction: column; align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModal7"
                                (click)="tableModalData=maintenanceUpcomingData;modalTitle='Maintenance Upcoming'"
                                matTooltip="Maintenance Expiry in Upcoming 7 days ">
                                <div class="number" style="background: #004586;">{{maintenanceUpcomingData?.length}}
                                </div>
                                <div class="small_num_adv">Upcoming</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6" style="padding-left: 0px; padding-right: 2px; margin-bottom: 4px;">
                        <div class="card_box">
                          <div style="display: flex;flex-direction: column;">
                            <div class="small_num">Warranty</div>
                            <div style="display: flex;flex-direction: row;justify-content: space-between;">
                              <div style="display: flex;flex-direction: column;align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModal6"
                                (click)="tableModalData=warrantyExpiredData;modalTitle='Warranty Expired'">
                                <div class="number" style="background: #529f1c">{{warrantyData?.length}}</div>
                                <div class="small_num_adv">Expired</div>
                              </div>
                              <div class="vertical-line"></div>
                              <div style="display: flex;flex-direction: column; align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModal7"
                                (click)="tableModalData=warrantyUpcomingData;modalTitle='Warranty Upcoming'"
                                matTooltip="Warranty Expiry in Upcoming 7 days ">
                                <div class="number" style="background: #529f1c;">{{warrantyUpcomingData?.length}}</div>
                                <div class="small_num_adv">Upcoming</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6" style="padding-left: 0px; padding-right: 2px; margin-bottom: 4px;">
                        <div class="card_box">
                          <div style="display: flex;flex-direction: column;">
                            <div class="small_num">Insurance</div>
                            <div style="display: flex;flex-direction: row;justify-content: space-between;">
                              <div style="display: flex;flex-direction: column;align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModal6"
                                (click)="tableModalData=insuranceExpiredData;modalTitle='Insurance Expired'">
                                <div class="number" style="background: #7d0020">{{insuranceData?.length}}</div>
                                <div class="small_num_adv">Expired</div>
                              </div>
                              <div class="vertical-line"></div>
                              <div style="display: flex;flex-direction: column; align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModal7"
                                (click)="tableModalData=insuranceUpcomingData;modalTitle='Insurance Upcoming'"
                                matTooltip="Insurance Expiry in Upcoming 7 days ">
                                <div class="number" style="background: #7d0020;">{{insuranceUpcomingData?.length}}</div>
                                <div class="small_num_adv">Upcoming</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6" style="padding-left: 0px; padding-right: 2px; margin-bottom: 4px;">
                        <div class="card_box">
                          <div style="display: flex;flex-direction: column;">
                            <div class="small_num">Depreciation</div>
                            <div style="display: flex;flex-direction: row;justify-content: space-between;">
                              <div style="display: flex;flex-direction: column;align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModall"
                                (click)="tableModalData=depreciationData;modalTitle='Depreciatede Assets'">
                                <div class="number" style="background: #ffd320;">{{depreciationData?.length}}</div>
                                <div class="small_num_adv">Depreciated</div>
                              </div>
                              <div class="vertical-line"></div>
                              <div style="display: flex;flex-direction: column; align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModal8"
                                (click)="tableModalData=depreciationUpcomingData;modalTitle='Depreciation Upcoming'"
                                matTooltip="Depreciation in Upcoming 7 days ">
                                <div class="number" style="background: #ffd320;;">{{depreciationUpcomingData?.length}}
                                </div>
                                <div class="small_num_adv">Upcoming</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6" style="padding-left: 0px; padding-right: 2px; margin-bottom: 0px;">
                        <div class="card_box">
                          <div style="display: flex;flex-direction: column;">
                            <div class="small_num">CheckOut</div>
                            <div style="display: flex;flex-direction: row;justify-content: space-between;">
                              <div style="display: flex;flex-direction: column;align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModall"
                                (click)="tableModalData=checkedOutPending;modalTitle='CheckOut Pending Assets'">
                                <div class="number" style="background: #83cafe;">{{checkedOutPending?.length}}</div>
                                <div class="small_num_adv">Pending</div>
                              </div>
                              <div class="vertical-line"></div>
                              <div style="display: flex;flex-direction: column; align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModal8"
                                (click)="tableModalData=checkedOutUpcoming;modalTitle='Checkout Upcoming'"
                                matTooltip="CheckOut in Upcoming 7 days ">
                                <div class="number" style="background: #83cafe;">{{checkedOutUpcoming?.length}}</div>
                                <div class="small_num_adv">Upcoming</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6" style="padding-left: 0px; padding-right: 2px; margin-bottom: 0px;">
                        <div class="card_box">
                          <div style="display: flex;flex-direction: column;">
                            <div class="small_num">CheckIn</div>
                            <div style="display: flex;flex-direction: row;justify-content: space-between;">
                              <div style="display: flex;flex-direction: column;align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModall"
                                (click)="tableModalData=checkedInPending;modalTitle='checkedIn Pending Assets'">
                                <div class="number" style="background: #ff420e;">{{checkedInPending?.length}}</div>
                                <div class="small_num_adv">Pending</div>
                              </div>
                              <div class="vertical-line"></div>
                              <div style="display: flex;flex-direction: column; align-items: baseline;"
                                data-toggle="modal" data-target="#exampleModal8"
                                (click)="tableModalData=checkedInUpcoming;modalTitle='checkin Upcoming'"
                                matTooltip="CheckIn in Upcoming 7 days ">
                                <div class="number" style="background: #ff420e;">{{checkedInUpcoming?.length}}</div>
                                <div class="small_num_adv">Upcoming</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-9 pr-0 pl-0">
                  <div class="table_section">
                    <div class="subtitle">Most Used Assets</div>
                    <div class="table-responsive" style="height:290px">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Asset Name</th>
                            <th>Asset Category</th>
                            <th>Avg. Usage</th>
                            <th>Tracked Zone</th>
                            <th>Last Ping</th>
                            <th>Info</th>
                          </tr>
                        </thead>
                        <tbody *ngFor="let item of mostUsedAssets; let i = index">
                          <tr>
                            <td>{{item.asset_name}}</td>
                            <td><span class="category">{{item.asset_category}}</span></td>
                            <td>{{item.avg_usage}}</td>
                            <td><span [ngClass]="{'loading':item.zone_name!=='-'}">{{item.zone_name}}</span></td>
                            <td>{{item.lastping=='-'?'-':(item.lastping| date:'dd-MM-yyyy hh:mm:ss a')}}</td>
                            <td (click)="viewAsset(item)">
                              <i class="fa fa-info-circle" alt=""></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div *ngIf="!mostUsedAssets.length" class="text-center mt-1 fade-in no-assets-tracked">No Most
                        used Assets</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row m-0 mt-1">
                <div class="col-md-6 pl-0 pr-0">
                  <div class="table_section">
                    <div class="subtitle">Least Used Assets</div>
                    <div class="table-responsive" style="height:246px">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Asset Name</th>
                            <th>Asset Category</th>
                            <th>Avg. Usage</th>
                            <th>Tracked Zone</th>
                            <th>Last Ping</th>
                            <th>Info</th>
                          </tr>
                        </thead>
                        <tbody *ngFor="let item of leastUsedAssets; let i = index">
                          <tr>
                            <td>{{item.asset_name}}</td>
                            <td><span class="category">{{item.asset_category}}</span></td>
                            <td>{{item.avg_usage}}</td>
                            <td><span [ngClass]="{'loading':item.zone_name!=='-'}">{{item.zone_name}}</span></td>
                            <td>{{item.lastping=='-'?'-':(item.lastping| date:'dd-MM-yyyy hh:mm:ss a')}}</td>
                            <td (click)="viewAsset(item)">
                              <i class="fa fa-info-circle" alt=""></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div *ngIf="!leastUsedAssets.length" class="text-center mt-1 fade-in no-assets-tracked">No Least
                        used Assets</div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 pl-1 pr-0">
                  <div class="status_wise_maintanance">
                    <div class="subtitle">Asset Maintenance Status</div>
                    <hr>

                    <div class="maintanance_row">
                      <div class="main_boxes">
                        <div class="main_box" *ngFor="let maintenance of statusWiseMaintenance;let i=index">
                          <i class="fa fa-square-o" style="color:rgba(255, 99, 132, 1)"></i><span
                            class="small_num">{{maintenance.count}}</span>
                          <div class="small_text">{{maintenance.maintenance_status}}</div>
                        </div>
                      </div>

                      <div class="line_graph_img">
                        <div class="table_section">
                          <div class="table-responsive" style="height: 205px;">
                            <div *ngIf="statusWiseMaintenance==null" class="text-center mt-1 fade-in no-assets-tracked">
                              No Asset Maintenance Data</div>
                            <canvas baseChart width="1000" height="300px" [datasets]="areaChartData1"
                              [options]="areaChartOptions1" [legend]="areaChartLegend1" [chartType]="areaChartType1"
                              [labels]="areaChartLabels1" *ngIf="statusWiseMaintenance!=null">
                            </canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-0 mt-0">
                <div class="col-md-3 pl-0 pr-0 mt-0">
                  <div class="active_alerts">
                    <div class="subtitle">Active Alerts</div>
                    <div *ngIf="!pendingAlerts.length" class="text-center mt-1 fade-in no-assets-tracked">No Pending
                      Alerts</div>
                    <div class="alert_boxes_sec">
                      <div class="alert_box" *ngFor="let alert of pendingAlerts"
                        (click)="viewAlert(alert.alert_data,alert.alert_condition,alert.type_name,alert,alert.alert_name)">
                        <div class="top_alert_row">
                          <h4>{{alert.title}}</h4>
                          <button [ngClass]="alert.type_name"
                            [style.background]="alert.type_color">{{alert.type_name}}</button>
                        </div>
                        <div class="bottom_alert_row">
                          <div class="name"><img src="assets\asset_icon2.png"> {{alert.asset_name}}</div>
                          <div class="time">{{alert.created_at|date : "dd-MM-yyyy hh:mm"}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-9 pl-1 pr-0 mt-1">
                  <div class="table_section">
                    <div class="subtitle" style="display:flex;justify-content: space-between;flex-direction: row;">
                      Asset Allocation Trend
                      <div class="right-side">
                        <select (change)="getTrendChartData()" [(ngModel)]="days">
                          <option [value]=7 selected> Last 7 days </option>
                          <option [value]=30> Last 30 days </option>
                        </select>
                      </div>
                    </div>
                    <div class="table-responsive" style="height:322px">
                      <canvas baseChart [datasets]="areaChartData" width="100%" height="23%"
                        [options]="areaChartOptions" [legend]="areaChartLegend" [chartType]="areaChartType">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 pl-1 pr-0 mt-1">
                <div class="table_section">
                  <div class="subtitle">Map View</div>
                  <app-assets-tracking-view></app-assets-tracking-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-xl" id="exampleModall" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true" (hidden)="handleModalClose()">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table_section">
              <div class="table-responsive" *ngIf="tableModalData.length>0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Asset category</th>
                      <th>Tracked Zone</th>
                      <th>Last Ping</th>
                      <ng-container *ngIf="modalTitle=='Depreciatede Assets'">
                        <th>Depreciated On</th>
                        <th>Salvage Value</th>
                        <th>Depreciation Method</th>
                      </ng-container>
                      <th
                        *ngIf="modalTitle=='ASSIGNED Assets'||modalTitle=='CHECKEDOUT Assets'||modalTitle=='CheckOut Pending Assets'">
                        Assign To</th>
                      <th *ngIf="modalTitle=='checkedIn Pending Assets'">CheckedOut By</th>
                      <th *ngIf="modalTitle=='checkedIn Pending Assets'">Expected CkeckIn Date</th>
                      <th *ngIf="modalTitle=='CheckOut Pending Assets'">Expected Checkout Date</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let asset of tableModalData">
                      <td>{{asset.asset_name}}</td>
                      <td><span class="category">{{asset.asset_category_name}}</span></td>
                      <td><span [ngClass]="{'loading':asset.zone_name!=='-'}">{{asset.zone_name}}</span></td>
                      <td>{{asset.lastping=='-'?'-':(asset.lastping| date:'dd-MM-yyyy hh:mm:ss a')}}</td>
                      <ng-container *ngIf="modalTitle=='Depreciatede Assets'">
                        <td>{{asset.depreciated_on}}</td>
                        <td>{{asset.salvage_value}}</td>
                        <td>{{asset.depreciation_method}}</td>
                      </ng-container>
                      <td
                        *ngIf="modalTitle=='ASSIGNED Assets'||modalTitle=='CHECKEDOUT Assets'||modalTitle=='checkedIn Pending Assets'||modalTitle=='CheckOut Pending Assets'">
                        {{asset.user_name}}</td>
                      <td *ngIf="modalTitle=='checkedIn Pending Assets'">{{asset.end_date|date:'dd-MM-yyyy'}}</td>
                      <td *ngIf="modalTitle=='CheckOut Pending Assets'">{{asset.from_date|date:'dd-MM-yyyy'}}</td>
                      <td (click)="viewAsset(asset)" data-dismiss="modal">
                        <i class="fa fa-info-circle" alt=""></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="tableModalData.length===0" class="text-center mt-1 fade-in"
                style="font-weight: 600; color: #cd4040;font-size: larger;">No Assets Tracked!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-xl" id="exampleModal5" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true" (hidden)="handleModalClose()">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table_section">
              <div class="table-responsive" *ngIf="tableModalData.length>0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Asset category</th>
                      <th *ngIf="modalTitle!=='Zone Wise Assets'">Tracked Zone</th>
                      <th *ngIf="modalTitle!=='Zone Wise Assets'">Last Ping</th>
                      <th>Assign To</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let asset of tableModalData">
                      <td>{{asset.asset_name}}</td>
                      <td><span class="category">{{asset.asset_category_name}}</span></td>
                      <td *ngIf="modalTitle!=='Zone Wise Assets'"><span
                          [ngClass]="{'loading':asset.zone_name!=='-'}">{{asset.zone_name}}</span></td>
                      <td *ngIf="modalTitle!=='Zone Wise Assets'">{{asset.lastping=='-'?'-':(asset.lastping|
                        date:'dd-MM-yyyy hh:mm:ss a')}}</td>
                      <td>{{asset.user_name!=' '? asset.user_name:'-'}}</td>
                      <td (click)="viewAsset(asset)" data-dismiss="modal">
                        <i class="fa fa-info-circle" alt=""></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="tableModalData.length===0" class="text-center mt-1 fade-in"
                style="font-weight: 600; color: #cd4040;font-size: larger;">No Assets Tracked!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="detailModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content shadow">
          <div class="modal-header">
            <h4 class="modal-title">{{modalDetailTitle}} - {{detailType == 1 ? 'Plant': detailType == 2 ?
              'Stage':detailType == 5 ?'Zone':'Hardware' }} </h4>
            <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-md-12 text-right">
              </div>
            </div>
            <div class="table_section">
              <div class="table-responsive" *ngIf="tableModalData.length>0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Asset category</th>
                      <th>Zone</th>
                      <th>Last Ping</th>
                      <th>Assigned To</th>
                      <th>info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let asset of tableModalData">
                      <td>{{asset.asset_name}}</td>
                      <td><span class="category">{{asset.asset_category_name}}</span></td>
                      <td><span class="loading">{{asset.zone_name}}</span></td>
                      <td>{{asset.lastping=='-'?'-':(asset.lastping| date:'dd-MM-yyyy hh:mm:ss a')}}</td>
                      <td>{{asset.user_name!=' '? asset.user_name:'-'}}</td>
                      <td (click)="viewAsset(asset)" data-dismiss="modal">
                        <i class="fa fa-info-circle" alt=""></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="tableModalData.length===0" class="text-center mt-1 fade-in"
                style="font-weight: 600; color: #cd4040;font-size: larger;">No Assets Tracked!</div>
            </div>

            <div class="row">
              <div class="col-md-12 text-right mt-3 mb-1">
                <button type="button" class="btn btn-danger" (click)="closeModal(false)">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-xl" id="exampleModal4" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true" (hidden)="handleModalClose()">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table_section">
              <div class="table-responsive" *ngIf="tableModalData.length>0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Alert Name</th>
                      <th>Asset Name</th>
                      <th>Alert Conditon</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th *ngIf="modalTitle=='Pending Alerts'">Created At</th>
                      <ng-container *ngIf="modalTitle=='Resolved Alerts'">
                        <th>Resolved At</th>
                        <th>Resolved By</th>
                        <th>Remarks</th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let alert of tableModalData">
                      <td>{{alert.title}}</td>
                      <td>{{alert.asset_name}} </td>
                      <td><span class="pill"
                          [ngClass]="{'bg-blue-100 text-blue-800':(alert.alert_condition=='CheckIn Due'),'bg-pink-100 text-pink-800':(alert.alert_condition=='Warranty Expiry'),'bg-indigo-100 text-indigo-800':(alert.alert_condition=='Insurance Expiry'),'bg-yellow-100 text-yellow-800':(alert.alert_condition=='Asset Maintenance Overdue'),'bg-teal-100 text-teal-800':(alert.alert_condition=='Asset Depreciation'),'bg-cyan-100 text-cyan-800':(alert.alert_condition=='CheckOut Due'),'bg-orange-100 text-orange-800':(alert.alert_condition=='Status')}">
                          {{alert.alert_condition=='Status'?'Status changed to
                          '+alert.new_alert_data?.data?.Status??'':alert.alert_condition}}</span>
                      </td>
                      <td>
                        <button [ngClass]="alert.type_name" [style.background]="alert.type_color" style="border: none;
                  color: white;
                  font-size: 10px;
                  padding: 5px 10px;
                  border-radius: 14px;
                  font-weight: 600;">{{alert.type_name}}</button>
                      </td>
                      <td><span class="pill"
                          [ngClass]="{'bg-yellow-100 text-yellow-800':(alert.status=='INPROGRESS'),'bg-blue-100 text-blue-800':(alert.status=='ACTIVE'),'bg-green-100 text-green-800':(alert.status=='COMPLETED')}">
                          {{alert.status}}</span>
                      </td>
                      <td>{{alert.created_at| date:'dd-MM-yyyy hh:mm:ss a'}}</td>
                      <ng-container *ngIf="modalTitle=='Resolved Alerts'">
                        <td>{{alert.user_name}}</td>
                        <td>{{alert.remark_data[0]?.remark}}</td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="tableModalData.length===0 && modalTitle=='Pending Alerts'" class="text-center mt-1 fade-in"
                style="font-weight: 600; color: #cd4040;font-size: larger;">No Recent Alerts!</div>
              <div *ngIf="tableModalData.length===0 && modalTitle=='Resolved Alerts'" class="text-center mt-1 fade-in"
                style="font-weight: 600; color: #cd4040;font-size: larger;">No Resolved Alerts!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-xl" id="exampleModal6" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true" (hidden)="handleModalClose()">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table_section">
              <div class="table-responsive" *ngIf="tableModalData.length>0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Asset category</th>
                      <th *ngIf="modalTitle!=='Zone Wise Assets'">Tracked Zone</th>
                      <th *ngIf="modalTitle!=='Zone Wise Assets'">Last Ping</th>
                      <th>{{modalTitle+' Name'}}</th>
                      <th *ngIf="modalTitle=='Maintenance Overdue'">Due Date</th>
                      <th *ngIf="modalTitle=='Maintenance Overdue'">Assign To</th>
                      <th *ngIf="modalTitle=='Warranty Expired'|| modalTitle=='Insurance Expired'">Expiry Date</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let asset of tableModalData">
                      <td>{{asset.asset_name}}</td>
                      <td><span class="category">{{asset.asset_category_name}}</span></td>
                      <td *ngIf="modalTitle!=='Zone Wise Assets'"><span
                          [ngClass]="{'loading':asset.zone_name!=='-'}">{{asset.zone_name}}</span></td>
                      <td *ngIf="modalTitle!=='Zone Wise Assets'">{{asset.lastping=='-'?'-':(asset.lastping|
                        date:'dd-MM-yyyy hh:mm:ss a')}}</td>
                      <td>{{asset.name}}</td>
                      <td>{{asset.due_date=='-'?'-':(asset.due_date|date:'dd-MM-yyyy')}}</td>
                      <td *ngIf="modalTitle=='Maintenance Overdue'">{{asset.user_name}}</td>
                      <td (click)="viewAsset(asset)" data-dismiss="modal">
                        <i class="fa fa-info-circle" alt=""></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="tableModalData.length===0" class="text-center mt-1 fade-in"
                style="font-weight: 600; color: #cd4040;font-size: larger;">No Assets Tracked!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-xl" id="exampleModal7" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true" (hidden)="handleModalClose()">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table_section">
              <div class="table-responsive" *ngIf="tableModalData.length>0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Asset category</th>
                      <th *ngIf="modalTitle!=='Zone Wise Assets'">Tracked Zone</th>
                      <th *ngIf="modalTitle!=='Zone Wise Assets'">Last Ping</th>
                      <th>{{modalTitle.slice(0,-9)+' Name'}}</th>
                      <th *ngIf="modalTitle=='Maintenance Upcoming'">Due Date</th>
                      <th *ngIf="modalTitle=='Maintenance Upcoming'">Assign To</th>
                      <th *ngIf="modalTitle=='Warranty Upcoming'|| modalTitle=='Insurance Upcoming'">Expiry Date</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let asset of tableModalData">
                      <td>{{asset.asset_name}}</td>
                      <td><span class="category">{{asset.asset_category_name}}</span></td>
                      <td *ngIf="modalTitle!=='Zone Wise Assets'"><span
                          [ngClass]="{'loading':asset.zone_name!=='-'}">{{asset.zone_name}}</span></td>
                      <td *ngIf="modalTitle!=='Zone Wise Assets'">{{asset.lastping=='-'?'-':(asset.lastping|
                        date:'dd-MM-yyyy hh:mm:ss a')}}</td>
                      <td>{{asset.name}}</td>
                      <td>{{asset.due_date=='-'?'-':(asset.due_date|date:'dd-MM-yyyy')}}</td>
                      <td *ngIf="modalTitle=='Maintenance Upcoming'">{{asset.user_name}}</td>
                      <td (click)="viewAsset(asset)" data-dismiss="modal">
                        <i class="fa fa-info-circle" alt=""></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="tableModalData.length===0" class="text-center mt-1 fade-in"
                style="font-weight: 600; color: #cd4040;font-size: larger;">No Assets Tracked!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-xl" id="exampleModal8" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true" (hidden)="handleModalClose()">
      <div class="modal-dialog mzodal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table_section">
              <div class="table-responsive" *ngIf="tableModalData.length>0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Asset category</th>
                      <th>Tracked Zone</th>
                      <th>Last Ping</th>
                      <ng-container *ngIf="modalTitle=='Depreciation Upcoming'">
                        <th>Depreciated On</th>
                        <th>Salvage Value</th>
                        <th>Depreciation Method</th>
                      </ng-container>
                      <th *ngIf="modalTitle=='Checkout Upcoming'">Assign To</th>
                      <th *ngIf="modalTitle=='checkin Upcoming'">CheckedOut By</th>
                      <th *ngIf="modalTitle=='Checkout Upcoming'">Expected Checkout Date</th>
                      <th *ngIf="modalTitle=='checkin Upcoming'">Expected Return Date</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let asset of tableModalData">
                      <td>{{asset.asset_name}}</td>
                      <td><span class="category">{{asset.asset_category_name}}</span></td>
                      <td><span [ngClass]="{'loading':asset.zone_name!=='-'}">{{asset.zone_name}}</span></td>
                      <td>{{asset.lastping=='-'?'-':(asset.lastping| date:'dd-MM-yyyy hh:mm:ss a')}}</td>
                      <ng-container *ngIf="modalTitle=='Depreciation Upcoming'">
                        <td>{{asset.depreciation_date}}</td>
                        <td>{{asset.salvage_value}}</td>
                        <td>{{asset.depriciation_type}}</td>
                      </ng-container>
                      <td *ngIf="modalTitle=='checkin Upcoming'||modalTitle=='Checkout Upcoming'">{{asset.user_name}}
                      </td>
                      <td *ngIf="modalTitle=='checkin Upcoming'">{{asset.end_date|date:'dd-MM-yyyy'}}</td>
                      <td *ngIf="modalTitle=='Checkout Upcoming'">{{asset.from_date|date:'dd-MM-yyyy'}}</td>
                      <td (click)="viewAsset(asset)" data-dismiss="modal">
                        <i class="fa fa-info-circle" alt=""></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="tableModalData.length===0" class="text-center mt-1 fade-in"
                style="font-weight: 600; color: #cd4040;font-size: larger;">No Assets Tracked!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body stageType-form">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Hardware Health" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required"> {{"hardware_type" | translate}} </label>
                  <select name="hardwareType" id="#hardwareType" class="form-control" [(ngModel)]="hardwareType"
                    #name="ngModel" placeholder="Select Hardware Type">
                    <option selected="RFID_READER" value="RFID_READER">RFID Reader</option>
                    <!-- <option selected="ACTIVE_READER" value="ACTIVE_READER">Active Reader Controller</option>
                    <option selected="BLE_GATEWAY" value="BLE_GATEWAY">BLE Gateway</option>
                    <option value="WEIGHING_SENSOR">Weighing Sensor</option>
                    <option value="TRAFFIC_LIGHT">Traffic Light</option>
                    <option value="SPEAKER">Speaker</option>
                    <option value="DISPLAY">Display</option>
                    <option value="AT_CONTROLLER">AT Controller</option>
                    <option value="BOOM_BARRIER">Boom Barrier</option> -->
                    <option value="BIOMETRIC_CONTROLLER">Biometric Controller</option>
                  </select>
                  <!-- <div class="error" *ngIf="hardwareTypeValidator && hardwareTypeValidator.error">
                    <span>{{hardwareTypeValidator.msg}} </span>
                  </div> -->
                </div>

                <div *ngIf="role.user_role == 'AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"company" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                </div>

                <div *ngIf="(role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN')"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantData"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                </div>
              </div>


              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-warning" (click)="validators()">{{"Find" |
                    translate}}</button>
                </div>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"Hardware Id" | translate}}</th>
                    <th>{{"Connection Id" | translate}}</th>
                    <th>{{"Last Readed Tag" | translate}}</th>
                    <th>{{"Last Tag Time" | translate}}</th>
                    <th>{{"On first Stage" | translate}}</th>
                    <th>{{"Process Stage" | translate}}</th>
                    <th>{{ "Status" | translate}}</th>
                    <th>{{"Connection Time" | translate}}</th>
                    <th>{{"Last Ping Time" | translate}}</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of hardwareData">
                    <td>{{ row.hardwareId}}</td>
                    <td>{{row.connectionId}}</td>
                    <td>{{ row.lastReadTag }}</td>
                    <td>{{ row.updateTime | date: 'dd-MM-yyyy HH:mm' }}</td>
                    <td>{{ row.firstStage }}</td>
                    <td style="border:none"><ng-container *ngFor="let data of row.stageData"><span class="info-jellybean" style="font-size:10px;margin: 1px;">{{data.stage_name}}</span></ng-container></td>
                    <td> <span [ngClass]="{'early':row.status,'delay':!row.status}">{{ row.status }} </span></td>
                    <td>{{row.connectionTime | date: 'dd-MM-yyyy HH:mm'}}</td>
                    <td>{{ row.lastPingTime | date: 'dd-MM-yyyy HH:mm'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"Active Hardware List" | translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"Hardware Id" | translate}}</th>
                    <th>{{"Connection Id" | translate}}</th>
                    <th>{{"Last Readed Tag Type" | translate}}</th>
                    <th>{{"On first Stage" | translate}}</th>
                    <th>{{"Stage Id" | translate}}</th>
                    <th>{{"Update Time" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of hardwareData">
                    <td>{{ row.hardwareId}}</td>
                    <td>{{row.connectionId}}</td>
                    <td>{{ row.lastReadTagType }}</td>
                    <td>{{ row.firstStage }}</td>
                    <td>{{row.stageIds}}</td>
                    <td>{{ row.updateTime | date: 'dd-MM-yyyy HH:mm' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>

import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from './../../@core/services/api.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/@core/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { TableService } from 'src/app/@core/services/table.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { AlertCardModalComponent } from '../alert-card-modal/alert-card-modal.component';
import { ChecklistHistoryDataComponent } from 'src/app/modals/checklist-history-data/checklist-history-data.component';
import { Checklist, GroupedList, History, ChecklistData1 } from 'src/app/dataType/checklistInterface';

@Component({
  selector: 'app-checklist-admin-dashboard',
  templateUrl: './checklist-admin-dashboard.component.html',
  styleUrls: ['./checklist-admin-dashboard.component.scss'],
})

export class ChecklistAdminDashboardComponent implements OnInit {
  // allCounts: any[] = [];
  statusWiseLogs: any[] = [];
  activePendingChecklists: any[] = [];
  history: History;
  countData = [
    {
      'name': 'Total',
      'flag': 'All',
      'key': 'total'
    },
    {
      'name': 'Pending',
      'flag': 'Pending',
      'key': 'pending_count'
    },
    {
      'name': 'Completed',
      'flag': 'Approved',
      'key': 'approved_count'
    },
    {
      'name': 'Rejected',
      'flag': 'Rejected',
      'key': 'rejected_count'
    },
    {
      'name': 'Before Time',
      'flag': 'before',
      'key': 'before_tolerance'
    },
    {
      'name': 'In Time',
      'flag': 'in',
      'key': 'in_tolerance'
    },
    {
      'name': 'Late',
      'flag': 'after',
      'key': 'after_tolerance'
    },
  ]

  colorArray = [
    '#4076bd',
    '#c3c339',
    '#3dc03d',
    '#cd4040',
    '#33bbb9',
    '#7741d7',
    '#c33979',
    '#3ab962',
  ];

  bgColorArray = [
    '#f1f7ff',
    '#ffffef',
    '#efffef',
    '#fff2f2',
    '#eeffff',
    '#f5efff',
    '#fff1f8',
    '#f0fff5',
  ];

  iconArray = [
    'fa fa-list-alt',
    'fa fa-clock',
    'fa fa-check-circle',
    'fa fa-times-circle',
    'fa fa-calendar-check-o',
    'fa fa-thumbs-o-up',
    'fa fa-calendar-times-o'
  ]
  selectedIndex: number = 0;
  checkList: Checklist[] = [];
  userList: any[] = [];
  groupedLists: any = [];
  bestUsers: any[] = [];
  worstUsers: any[] = [];
  alerts: any[] = [];
  //

  constructor(
    public common: CommonService,
    public userService: UserService,
    public api: ApiService,
    public table: TableService,
    private modalService: NgbModal,
    public breadCrumbsService: BreadcrumbService,
  ) {
    this.breadCrumbsService.setActiveBreadCrumb(false);
    // this.getDashboardCounts();
  }

  ngOnInit(): void {
    this.checklistHistoryCount();
    this.getActivePendingChecklists();
    this.getCheckList();
    // this.getUserList();
    this.getBestWorstChecklistUsers(true);
    this.getBestWorstChecklistUsers(false);
    this.getActiveAlerts();
    this.common.sidebarToggled(this.common.menuToggled);
  }

  getActiveAlerts() {
    this.common.loading = true;
    this.api.get('checklist-dashboard/get-checklist-active-alerts').subscribe(
      (res: any) => {
        if (res.status) {
          this.alerts = res.data;
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getBestWorstChecklistUsers(isBestUsers) {
    this.common.loading = true;
    let params = {
      bestUsers: isBestUsers
    }
    this.api.get('checklist-dashboard/get-best-worst-checklist-users', params).subscribe(
      (res: any) => {
        if (res.status) {
          isBestUsers == true ? this.bestUsers = res.data : this.worstUsers = res.data;
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getCheckList() {
    this.common.loading = true;
    let params = {
      checklistActive: true
    }
    this.api.get('checklist/get-all-checklists', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.checkList = res.data;
          this.checkList.unshift({
            id: 0, checklist_title: 'All',
            checklist_description: '',
            checklist_type: 0,
            data: [],
            end_date: '',
            interval_count: 0,
            interval_type: '',
            plant_id: 0,
            plant_name: '',
            start_date: '',
            status: '',
            tolerance: 0
          });
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }


  // getUserList() {
  //   this.api.get('user/all_user').subscribe(
  //     (res: any) => {
  //       this.userList = res.data;
  //       this.userList.unshift({ userId: 0, userName: 'All' });
  //     },
  //     (err: any) => {
  //       console.error('Error: ', err);
  //     }
  //   );
  // }

  getdate(): Date {
    let date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }

  readCountData(flag: string, bool: boolean, user_id: number, checklistId: number) {
    this.common.params = {
      startDate: this.common.dateFormatter(
        bool == false ? new Date : this.getdate(),
        'YYYYMMdd',
        false,
        '-'
      ),
      endDate: this.common.dateFormatter(
        new Date,
        'YYYYMMdd',
        false,
        '-'
      ),
      flag: flag,
      type: 'normal',
      userId: user_id,
      checklistId: checklistId
    };
    const activeModal = this.modalService.open(ChecklistHistoryDataComponent, {
      size: 'xl',
      windowClass: 'custom-modal-width'
    });
  }

  checklistHistoryCount() {
    let startDate = this.common.dateFormatter(
      new Date,
      'YYYYMMdd',
      false,
      '-'
    );
    let endDate = this.common.dateFormatter(
      new Date,
      'YYYYMMdd',
      false,
      '-'
    );
    this.common.loading = true;
    let params = {
      startDate: startDate,
      endDate: endDate,
      userId: -1,
      checklistId: -1
    };
    this.api.get('checklist-dashboard/checklist-history-counts', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.history = res.data[0];
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getActivePendingChecklists() {
    this.common.loading = true;
    this.api.get('checklist-dashboard/get-active-pending-checklists').subscribe((res: any) => {
      if (res.status) {
        this.groupedLists = res.data.map(ele => {
          ele.checklist_data = JSON.parse(ele.checklist_data) as ChecklistData1;
          return ele as GroupedList;
        });
      }
      this.common.loading = false;
    },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  // groupedList() {
  //   const groupedData = {};

  //   for (const item of this.activePendingChecklists) {
  //     if (!groupedData[item.checklist_title]) {
  //       groupedData[item.checklist_title] = {
  //         checklist_title: item.checklist_title,
  //         tolerance: item.tolerance,
  //         startTime: item.checklist_perform_start_time,
  //         endTime: item.checklist_perform_end_time,
  //         checklist_id: item.checklist_id,
  //         log_id: item.log_id,
  //         user_names: [],
  //       };
  //     }
  //     groupedData[item.checklist_title].user_names.push({
  //       user_name: item.user_name,
  //       status: item.status,
  //       log_id: item.log_id,
  //       late_remark_id: item.late_remark_id,
  //     });
  //   }

  //   this.groupedLists = Object.values(groupedData);
  // }

  viewAlert(alertData, alert_condition, type_name, alert_name, alert) {
    let data = JSON.parse(alertData);
    const activeModal = this.modalService.open(AlertCardModalComponent, {
      size: 'xl',
      windowClass: 'custom-modal-width'

    });
    activeModal.componentInstance.refData = {
      alert_type_name: data.title,
      alert_remark: data.remark,
      alert_data: data.data,
      showAlertData: true,
      alert_condition: alert_condition,
      type_name: type_name,
      zone: true,
      alert_name: alert_name,
      alert: alert
    };
  }
}

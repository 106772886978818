<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body stageType-form">
      <div class="row">
        <div class="col-md-12">
          <div class="card" *ngIf="userService.permissionMap.get('edit_document_type_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{title | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input" class="required">{{"Status Scope" | customTranslate}}</label>
                  <select name="status_scope" id="#status_scope" class="form-control" [(ngModel)]="statusScope">
                    <option selected="GLOBAL" value="GLOBAL">GLOBAL</option>
                    <option value="COMPANY">COMPANY</option>
                    <option value="PLANT">PLANT</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'COMPANYADMIN'">
                  <label for="my-input" class="required">{{"Status Scope" | customTranslate}}</label>
                  <select name="status_scope" id="#status_scope" class="form-control" [(ngModel)]="statusScope">
                    <option selected="COMPANY" value="COMPANY">COMPANY</option>
                    <option value="PLANT">PLANT</option>
                  </select>
                </div>

                <div *ngIf="role.user_role == 'AXESTRACK' && statusScope != 'GLOBAL'"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"company" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                    display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyValidator && companyValidator.error">
                    <span>{{companyValidator.msg}} </span>
                  </div>
                </div>

                <div *ngIf="statusScope == 'PLANT'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantData"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantValidator && plantValidator.error">
                    <span>{{plantValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"document_type_name" | customTranslate}}</label>
                  <input class="form-control" type="text" name="documentTypeName"
                    [(ngModel)]="documentType.documentTypeName">
                  <div class="error" *ngIf="documentTypeNameValidator && documentTypeNameValidator.error">
                    <span>{{documentTypeNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{"status" | customTranslate}}</label>
                  <select name="user_status" id="#user_status" class="form-control"
                    [(ngModel)]="documentType.documentTypeStatus">
                    <option selected="ACTIVE" value="1">ACTIVE</option>
                    <option value="0">INACTIVE</option>
                  </select>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)="common.backClicked()">{{"back" |
                    customTranslate}}</button>
                  <button class="btn btn-warning" (click)="resetDocument()">{{"reset" | customTranslate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{buttonName | customTranslate}}
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"document_type_list" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"serial_no" | customTranslate}}</th>
                    <th>{{"document_type_name" | customTranslate}}</th>
                    <th>{{"status" | customTranslate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">{{"plant" | customTranslate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK'">{{"company" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of documentTypeList let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.type_name}}</td>
                    <td>{{row.status == 1 ? 'ACTIVE' : 'INACTIVE'}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN'">{{row.plant_name}}
                    </td>
                    <td *ngIf="role.user_role == 'AXESTRACK'">{{row.company_name}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewDocument(row)"
                          *ngIf="userService.permissionMap.get('view_document_type_access')==true">{{"view" |
                          customTranslate}}</button>
                        <button class="btn btn-success" (click)="editDocument(row)"
                        *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                        || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1)) && userService.permissionMap.get('edit_document_type_access')==true">{{"edit" |
                          customTranslate}}</button>
                        <button class="btn btn-danger" (click)="confirmDelete(row)"
                          *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                          || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1)) && row.status!=0 && userService.permissionMap.get('delete_document_type_access')==true">{{"delete" |
                          customTranslate}}</button>
                      </div>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { UserService } from 'src/app/@core/services/user.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { environment } from 'src/environments/environment';
import { Subscription, interval } from 'rxjs';


@Component({
  selector: 'app-live-streaming',
  templateUrl: './live-streaming.component.html',
  styleUrls: ['./live-streaming.component.scss'],
})
export class LiveStreamingComponent implements OnInit {
  @Output() getStreamValue = new EventEmitter<any>();
  @Input() isCamera:boolean;
  @Input() fieldId:any;


  @ViewChild('video') videoPlayer: ElementRef;
  @ViewChild('image') imageViewer: ElementRef;
  recordedBlobs: any[];
  mediaRecorder: MediaRecorder;
  stream: MediaStream;
  recordVideoElement: HTMLVideoElement;
  isRecording: boolean;
  downloadUrl: string;
  show:boolean=false;
  showControl:boolean=false;
  seconds: number = 0;
  secondToShow:string;
  streamEnded:boolean=false;
  private subscription: Subscription;


  constructor(
    public table: TableService,
    public common: CommonService,
    private userService:UserService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.setupHLS();
    // this.fetchStreamUrl();
  }

  ngOnDestroy(): void {
    const video = this.videoPlayer.nativeElement;
    video.src = "../../../assets/images/loader-gif.webp";
    video.load();
  }

  setupHLS() {
    this.seconds = 0;
    this.secondToShow = '00:00'
    this.showControl = false;
    this.show = false;
    this.streamEnded = false;

    const video = this.videoPlayer.nativeElement;
    // video.src = 'http://localhost:8116/video/playmp4'; // Replace with your HLS stream URL
    video.src = environment.hardwareUrl+'cctv/live/'+btoa(this.fieldId.toString())+'?auth='+encodeURIComponent(this.userService._token); // Replace with your HLS stream URL
    video.addEventListener('loadedmetadata', () => {
      video.play();
      this.stream = video.captureStream();
    });

    video.addEventListener('ended', () => {
      if(this.isRecording){
        this.stopRecording();
        this.streamEnded = true;
        video.src = "../../../assets/images/loader-gif.webp"
      }
    });
  }

  startRecording() {
    this.recordedBlobs = [];
    let options: any = { mimeType: 'video/webm' };

    try {
      this.mediaRecorder = new MediaRecorder(this.stream, options);
    } catch (err) {
      console.log(err);
    }
    const secondCounter = interval(1000);

    this.mediaRecorder.start(); // collect 100ms of data
    this.isRecording = !this.isRecording;
    this.onDataAvailableEvent();
    this.onStopRecordingEvent();

    this.subscription = secondCounter.subscribe(() => {
      this.seconds++; // Increment the seconds counter
      this.secondToShow = this.formatSeconds(this.seconds)
    });

  }

  stopRecording() {
    this.subscription?.unsubscribe();
    this.mediaRecorder.stop();
    this.isRecording = !this.isRecording;
  }

  onDataAvailableEvent() {
    try {
      this.mediaRecorder.ondataavailable = (event: any) => {
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  onStopRecordingEvent() {
    try {
      this.mediaRecorder.onstop = (event: Event) => {
        const videoBuffer = new Blob(this.recordedBlobs, {
          type: 'video/webm',
        });
        this.downloadUrl = window.URL.createObjectURL(videoBuffer); // you can download with <a> tag
        this.showControl = true;
        this.recordVideoElement = this.videoPlayer.nativeElement;
        this.recordVideoElement.src = this.downloadUrl;
        this.createFormData(videoBuffer,"mp4");
      };
    } catch (error) {
      console.log(error);
    }
  }

  captureImage() {
    let canvas = document.createElement('canvas');
    let image: any = '';
    canvas.width = 1920;
    canvas.height = 1080;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(this.videoPlayer.nativeElement,0,0,canvas.width,canvas.height);
    image = canvas.toDataURL('image/jpeg');
    // document.getElementById("video").pause()
    const video = this.videoPlayer.nativeElement;
    video.pause();
    const imageEle = this.imageViewer.nativeElement;
    this.show = true;
    imageEle.src = image;
    this.createFormData(image,"jpg");
  }

  createFormData(data:any,type:string){
    let blob: Blob;
    if(type=='jpg'){
      const mimeType = data.split(',')[0].slice(5); // Extract MIME type
      const byteString = atob(data.split(',')[1]); // Decode base64 string
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      blob = new Blob([arrayBuffer], { type: mimeType });
    }else{
      blob = data;
    }
    let formData = new FormData();
    formData.append('files', blob, 'hello.'+type);
    this.getStreamValue.emit(formData);
  }

  formatSeconds(totalSeconds: number): string {
    const minutes: number = Math.floor(totalSeconds / 60);
    const seconds: number = totalSeconds % 60;
    const formattedMinutes: string = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds: string = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

  @Pipe({
    name: 'formatNames'
  })
  export class FormatNamesPipe implements PipeTransform {

    transform(dataArray: any, args: any): any {
      if (args && args.key) {
        const key = args.key;
        return dataArray.map((item) => item[key]).join(', ');
    } else {
        return dataArray.join(', ');
    }
    }

  }

import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { UserService } from 'src/app/@core/services/user.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { TableService } from '../../@core/services/table.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewDetailsComponent } from '../../modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ProcessService } from '../process/process.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-action-action-mapping',
  templateUrl: './action-action-mapping.component.html',
  styleUrls: ['./action-action-mapping.component.scss'],
})
export class ActionActionMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 3, 'Action Dashboard');

  processList: any = [];
  processName: any = null;
  processId: any = null;
  actionsList: any = [];
  prePostActionsList: any = [];
  actionid: any = null;
  actionname: any = null;
  selectedPreActionIds: any = [];
  selectedPostActionIds = [];
  selectedPreActionNames: any = [];
  showPreActionData: any = [];
  selectedPostActionNames = [];
  actionactionMapping: any = [];
  btn = 'Save';

  constructor(
    public alertService: AlertService,
    public common: CommonService,
    private modalService: NgbModal,
    private processService: ProcessService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public userService: UserService,
    public plantService: PlantService,
    public role: RoleService,
    private table: TableService
  ) {
    this.getAllProcess();

    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Actions', url: null },
      { name: 'Action Action Mapping', url: '/user/action-action-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {}
  getActiveTab(event) {
    this.dtOptions = this.table.options(10, 3, 'Action Action Mapping');
    this.dtTrigger.unsubscribe();
    this.dtTrigger.next();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getActionActionMapping();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  selectedProcess(event: any) {
    this.processId = event.processId;
    this.processName = event.processName;
    this.getActionsList(event.processId);
  }

  getActionsList(processId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        processId: processId,
      };
      this.api.get('actions/action_by_process', params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.actionsList = res.data || [];
            resolve(this.actionsList);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  selectedAction(event: any) {
    this.actionid = event.actionid;
    this.actionname = event.actionname;
    this.prePostActionsList = this.actionsList.filter(
      (val) => val.actionid != this.actionid
    );
    const existingMapping = this.actionactionMapping.find(
      (mapping) => mapping.actionid === this.actionid
    );
    if (existingMapping) {
      this.getPrePostActionIdsAndNames(existingMapping.preactionids, existingMapping.postactionids, existingMapping.showpredata);
    }
  }

  selectPreActions(event: any) {
    this.selectedPreActionIds = event.map((e) => e.actionid);
  }

  selectPostActions(event: any) {
    this.selectedPostActionIds = event.map((e) => e.actionid);
  }

  save() {
    if (
      this.selectedPreActionIds.length == 0 &&
      this.selectedPostActionIds.length == 0
    ) {
      this.alertService.error('Please select any pre or post action');
    } else {
      let params = {
        selectedPreActionIds: this.selectedPreActionIds,
        selectedPostActionIds: this.selectedPostActionIds,
        selectedActionId: this.actionid,
        processId: this.processId,
        showDataFor: this.showPreActionData
      };
      this.common.loading = true;
      this.api.post('actions/save-action-action-mapping', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.alertService.success(res.message);
            this.reset();
            this.getActionActionMapping();
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    }
  }

  getActionActionMapping() {
    this.common.loading = true;
    this.api.get('actions/get-action-action-mapping').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if (res.data) {
            this.actionactionMapping = res.data;
            this.renderTable();
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getAllProcess() {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      this.processService.getAllProcessList().subscribe(
        (res: any) => {
          this.common.loading = false;
          this.processList = res.data;
          resolve(this.processList);
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  reset() {
    this.btn = 'Save';
    this.actionid = null;
    this.actionname = null;
    this.selectedPreActionIds = [];
    this.selectedPostActionIds = [];
    this.selectedPreActionNames = [];
    this.selectedPostActionNames = [];
    this.prePostActionsList = [];
    this.actionNameValidator = null;
    this.processName = null;
    this.processId = null;
    this.actionsList = [];
    this.actionname = null;
    this.actionid = null;
  }

  async editActionMapping(row: any) {
    this.reset();
    this.common.gotoTop();
    this.btn = 'Update';
    this.processId = row.processid;
    this.processName = row.processname;
    await this.getActionsList(this.processId);
    this.actionid = row.actionid;
    this.actionname = row.actionname;
    this.prePostActionsList = this.actionsList.filter(
      (val) => val.actionid != this.actionid
    );
    this.getPrePostActionIdsAndNames(row.preactionids, row.postactionids, row.showpredata);
  }

  getPrePostActionIdsAndNames(preActionIds, postActionIds, showPreData) {
    this.selectedPreActionIds = (preActionIds && preActionIds.split(", ")) || []
    this.selectedPostActionIds = (postActionIds && postActionIds.split(", ")) || [];
    console.log('this.selectedPreActionIds: ', this.selectedPreActionIds);

    this.selectedPreActionNames = this.actionsList.filter(a => this.selectedPreActionIds.includes(a.actionid.toString()));
    console.log('this.selectedPreActionNames: ', this.selectedPreActionNames);
    this.selectedPostActionNames = this.actionsList.filter(a => this.selectedPostActionIds.includes(a.actionid.toString()));
    this.showPreActionData = showPreData.split(", ");
  }

  viewActionActionMapping(item?: any) {
    if (!item) {
      return;
    }
    const {
      actionId,
      postActionsIds,
      preActionsIds,
      processId,
      ...updatedItem
    } = item;
    this.common.params = {
      details: [updatedItem],
      title: 'Action-Action-Mapping Details',
    };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }

  deleteActionActionMapping(item?: any) {
    let params = {
      actionId: item.actionid,
      processId: item.processid,
    };
    this.api.get('actions/delete-action-action-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertService.info(res.message);
          this.reset();
          this.getActionActionMapping();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  confirmDeleteAction(row?: any) {
    this.common.params = {
      title: 'Delete Action ',
      description:
        `<b>&nbsp;` + 'Are you sure, Do you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'lg',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteActionActionMapping(row);
      }
    });
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  actionNameValidator: any = null;
  processValidator: any = null;


  handleShowPreData(event, actionId){
    if(event.target.checked){
      this.showPreActionData.push(actionId);
    } else {
      this.showPreActionData = this.showPreActionData.filter(a => a != actionId);
    }
  }
  formValidator() {
    this.actionNameValidator = this.common.nullValidator(
      this.actionname,
      'Action Name Mandatory'
    );
    this.processValidator = this.common.nullValidator(
      this.processId,
      'Process is Mandatory'
    );
    if (!this.actionNameValidator.error && !this.processValidator.error) {
      this.save();
    }
  }
}

import { TableService } from '../../@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { PlantService } from '../plant/plant.service';
import { UserService } from 'src/app/@core/services/user.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/@core/services/token-storage.service';
import { stat } from 'fs';
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  // @ViewChildren(DataTableDirective)
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  // @ViewChild("table2") dtElement1:DataTableDirective
  // dtElement: any;
  // dtElement: QueryList<DataTableDirective>;
  dtTrigger: any = new Subject();
  // dtTrigger1: any = new Subject();
  dtOptions: any = this.table.options(10, 7, 'user list');
  // dtOptions1: any = this.table.options(10, 9, 'staff  list');
  userTypesList: any = {
    AXESTRACK: [
      'AXESTRACK',
      'AXESTRACKUSER',
      'COMPANYADMIN',
      'COMPANYUSER',
      'PLANTADMIN',
      'PLANTUSER',
    ],
    AXESTRACKUSER: ['PLANTADMIN', 'PLANTUSER'],
    COMPANYADMIN: ['COMPANYADMIN', 'COMPANYUSER', 'PLANTADMIN', 'PLANTUSER'],
    COMPANYUSER: ['PLANTADMIN', 'PLANTUSER'],
    PLANTADMIN: ['PLANTADMIN', 'PLANTUSER'],
    PLANTUSER: ['PLANTUSER'],
  };

  constructor(
    private alertService: AlertService,
    private api: ApiService,
    private plantService: PlantService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService,
    public route: ActivatedRoute,
    public router:Router,
    public tokenService : TokenStorageService
  ) {
    this.getCompany();
    this.btn = 'save';
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'User-Group', url: null },
      { name: 'Manage User', url: '/user/users' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    this.userDetails = JSON.parse(localStorage.getItem('USER_DETAILS'));
    this.userId = this.userDetails.id;

    if (this.role.user_role != 'AXESTRACK') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
  }

  user = {
    userId: null,
    firstName: '',
    lastName: '',
    gender: 'Male',
    userName: null,
    password: null,
    email: null,
    mobile: null,
    status: 'ACTIVE',
    companyId: null,
    plantId: null,
    processId: null,
    userType: '',
    empId: null,
    vehicles: [{ vehicleId: null, vehicleRsn: null }],
  };

  fileEvent: any = [];
  title = 'Add User';
  btn = 'save';
  data: any = [];
  processName: null;
  companyName: null;
  plantName: null;
  companyData: any = [];
  plantData: any = [];
  processData: any = [];
  userDetails: any = [];
  userId = null;
  editFlag: boolean = false;
  vehiclesList: any[] = [];
  selectedVehicleNo: any[] = [];
  deptList: any[] = [];
  selectedDeptIds: any[] = [];
  selectedDeptNames: any[] = [];
  showTable:boolean=true;
  staffType: any = 'USER';
  staffTypeList: any[] = [];
  staffTypeId: number;
  staffTypeName: String = null;
  staffList: any[] = [];
  staffFirstName: any = null;
  staffLastName: any = null;
  tableData: any = [];
  tabData: any[] = [];
  statusKey:string = 'userStatus';




  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getData();
  }

  ngOnDestroy(): void {
    // this.dtTrigger.unsubscribe();
    // this.dtTrigger1.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  renderStaffTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }




  ngOnInit(): void {
    console.log("gvbhnjm,.", this.userService.permissionMap);
    console.log("permissions",    this.userService.permissionMap.get('add_user_access'),    this.userService.permissionMap.get('add_staff_access'),
    this.userService.permissionMap.get('add_transporter_access')), this.userService.permissionMap.get('add_visitor_access')
    this.tabData= [
      { tabname: 'User', value: 'USER' , permission : this.userService.permissionMap.get('add_user_access')},
      { tabname: 'Staff', value: 'STAFF' , permission : this.userService.permissionMap.get('add_staff_access')},
      { tabname: 'Transporter', value: 'TRANSPORTER', permission : this.userService.permissionMap.get('add_transporter_access')},
      { tabname: 'Visitor', value: 'VISITOR', permission : this.userService.permissionMap.get('add_visitor_access')}
    ];
    console.log("this ",this.userService.permissionMap.get('add_visitor_access'))
    this.route.paramMap.subscribe(params => {
      this.staffType = params.get('type')=='transporter'?'TRANSPORTER':(params.get('type')=='staff'?'STAFF': params.get('type')=='visitor' ? 'VISITOR' : 'USER');
      this.onSelectEmployee();
    });
    this.dtTrigger.next();
    this.getCompany();
    this.getDepartmentList();
    if (this.staffType == 'USER') this.getAllVehicles();
  }

  // onTabChangeCall() {
  //   this.dtTrigger.next();
  //   this.getCompany();
  //   this.getDepartmentList();
  //   if (this.staffType == 'USER') this.getAllVehicles();
  //   this.dtTrigger.next();
  //   this.getData();
  // }
  isVisitor=false



  formatNames(dataArray: any): string {
    if (dataArray && dataArray.length > 0) {
      return dataArray.map((item) => item.deptName).join(', ');
    } else {
      return '';
    }
  }
  onSelectStaffType(event: any) {
    console.log('event: ', event);
    this.staffTypeName = event.type_name;
    console.log('this.staffTypeName: ', this.staffTypeName);
    this.staffTypeId = event.id;
    console.log('this.staffTypeId: ', this.staffTypeId);
  }

  onTabChange(tabitem){
    this.staffType = tabitem.value;
    let tab = tabitem.value=='TRANSPORTER'?'transporter':(tabitem.value=='STAFF'?'staff':'user')
    this.router.navigate(['/user/users/'+tab]);
    // this.onSelectEmployee();
  }
  onSelectEmployee(){
     this.statusKey = this.staffType== 'STAFF' ? 'staff_status' : this.staffType == 'TRANSPORTER' ? 'user_status' :this.staffType=='VISITOR'?'visitor_status': 'userStatus';
     console.log("stafftype is " ,this.staffType )
    //  if(this.staffType=='VISITOR')
    //   this.statusKey= 'visitor_status'
    if(this.staffType!='VISITOR')
     {this.resetDetail();
    if(this.staffType!='TRANSPORTER') this.getStaffTypeList();
     this.getData();
     }

  }
  getStaffTypeList() {
    this.common.loading = true;
    let params = {
      allTypes: true,
    };
    this.api.get('staff/get-staff-type-list', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.staffTypeList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        id: id,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.plantData = res.data;
            resolve(this.plantData);
          }
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  getDepartmentList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        companyId: this.user.companyId,
        plantId: this.user.plantId,
        allDepartments: false,
      };
      this.api.get('department/get-department-list', params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.deptList = res.data;
            resolve(this.deptList);
          }
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  selectedCompany(event: any) {
    this.user.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.getDepartmentList();
    this.plantName = null;
    this.user.plantId = null;
  }

  selectedProcess(event: any) {
    this.user.processId = event.processId;
  }

  selectedPlant(event: any) {
    this.user.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getDepartmentList();
  }

  selectedDepts(event: any) {
    this.selectedDeptIds = event.map((e) => e.dept_id);
    console.log('this.selectedDeptIds: ', this.selectedDeptIds);
  }

  setUserName() {
    console.log("Getting User Name")
    this.user.firstName=this.user.firstName?this.user.firstName.trim():null;
    this.user.lastName=this.user.lastName?this.user.lastName.trim():null;
    if(this.user.firstName || this.user.lastName){
    console.log("name", this.user.firstName , this.user.lastName)
    if (
      this.user.userType == 'AXESTRACK' ||
      this.user.userType == 'AXESTRACKUSER'
    ) {
      this.user.userName =
        this.user.firstName + '_' + this.user.lastName + '_' + 'AT';
    } else if (
      this.user.userType == 'COMPANYADMIN' ||
      this.user.userType == 'COMPANYUSER'
    ) {
      this.user.userName =
        this.user.firstName + '_' + this.user.lastName + '_' + 'C';
    } else {
      if (
        this.role.user_role != 'AXESTRACK' &&
        this.role.user_role != 'COMPANYADMIN'
      ) {
        console.log('this.plantData: ', this.plantData);
        console.log('this.userService._loggedInUser.plantId: ', this.userService._loggedInUser.plantId);
        this.plantName = this.plantData.filter(
          (p) => p.plantId == this.userService._loggedInUser.plantId
        )[0].plantName;
        this.user.plantId = this.userService._loggedInUser.plantId;
      }

      this.api
        .get(
          'user/generate-username?firstName=' +
            this.user.firstName +
            '&lastName=' +
            this.user.lastName +
            '&plantId=' +
            this.user.plantId +
            '&acronym=' +
            this.acronymGenerator(this.plantName)
        )
        .subscribe(
          (res: any) => {
            this.user.userName = res.data[0].username;
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
          }
        );
    }
  }
  }

  acronymGenerator(text: any) {
    var words, acronym, nextWord;
    words = text.split(" ");
    acronym = '';
    let index = 0;
    while (index < words.length) {
      nextWord = words[index];
      acronym = acronym + nextWord.charAt(0);
      index = index + 1;
    }
    return acronym;
  }

  getData() {
    console.log("t3stingg")
    this.common.loading = true;
    this.showTable = false;
    if (this.staffType == 'USER') {
      this.api.get('user/all_user?allUser=true').subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.data =
              res.data.map((item) => ({
                ...item,
                vehicles: JSON.parse(item.vehicles),
                departments: JSON.parse(item.departments),
              })) || [];
            console.log(this.data, 'this. si the data');
            this.tableData = this.data;
          }
          this.showTable = true;
          this.renderTable();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
    } else if (this.staffType == 'STAFF'){
      let params = {
        allStaff: true,
        staffTypeId: null,
      };
      this.api.get('staff/all-staff', params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.staffList =
              res.data.map((item) => ({
                ...item,
                departments: JSON.parse(item.departments),
              })) || [];
            this.tableData = this.staffList;
            console.log('this.staffList', this.staffList);
          }
          this.showTable = true;
          this.renderTable();
        },
        (err: any) => {
          console.error('Error:', err);
          this.common.loading = false;
        }
      );
    }else{
      let params = {
        companyId:this.user.companyId,
        plantId:this.user.plantId
      };
      this.api.get('transporter/get-all-transports-by-plant-company', params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            // this.staffList =
            //   res.data.map((item) => ({
            //     ...item,
            //     departments: JSON.parse(item.departments),
            //   })) || [];
            this.tableData = res.data;
            // console.log('this.staffList', this.staffList);
          }
          this.showTable = true;
          this.renderTable();
        },
        (err: any) => {
          console.error('Error:', err);
          this.common.loading = false;
        }
      );
    }
  }

  formatZoneNames(dataArray: any): string {
    return dataArray.map((item) => item.deptName).join(', ');
  }

  saveData() {
    this.common.loading = true;
    let params: any;
    let apiUrl: any;
    if (this.staffType == 'USER') {
      params = {
        userId: this.user.userId,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        gender: this.user.gender,
        userName: this.user.userName,
        password: this.user.password,
        email: this.user.email,
        mobileNumber: this.user.mobile,
        userStatus: this.user.status,
        company:
          this.user.companyId == null
            ? this.userService._loggedInUser.companyId
            : this.user.companyId,
        plant:
          this.user.plantId == null
            ? this.userService._loggedInUser.plantId
            : this.user.plantId,
        userType: this.user.userType,
        employeeId: this.user.empId,
        vehicles: this.user.vehicles,
        deptIds: this.selectedDeptIds,
      };
      apiUrl = 'user/create_user';
    } else if(this.staffType == 'STAFF'){
      params = {
        staffId: this.user.userId,
        firstName: this.staffFirstName,
        lastName: this.staffLastName,
        gender: this.user.gender,
        email: this.user.email,
        mobileNo: this.user.mobile,
        status: this.user.status,
        companyId: this.user.companyId,
        plantId: this.user.plantId,
        staffType: this.user.userType,
        deptIds: this.selectedDeptIds,
        userRole: this.role.user_role,
        staffTypeId: this.staffTypeId,
      };
      apiUrl = 'staff/create-staff';
    }else{
      params={
        companyId:this.user.companyId?this.user.companyId:this.userService._loggedInUser.companyId,
        plantId:this.user.plantId?this.user.plantId:this.userService._loggedInUser.plantId,
        username:this.user.userName,
        status:this.user.status,
        password:this.user.password,
        mobileNo:this.user.mobile,
        gender:this.user.gender,
        lastName:this.user.lastName,
        firstName:this.user.firstName,
        email:this.user.email,
        transporterId:this.user.userId,
      };
      apiUrl = 'transporter/save-transporter';
    }
    console.log(apiUrl, 'this is apiurl');
    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        if (res.status) {
          if(res.message=="Name Already Exists"){
            this.alertService.warn(res.message);
          }
          else{
            this.alertService.success(res.message);
          }
          this.common.loading = false;
          this.resetDetail();
          this.getData();
        } else {
          this.common.loading = false;
          this.alertService.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  resetDetail() {
    console.log('deptIds', this.selectedDeptIds);
    console.log('this.user.companyIddfgbhn', this.user.companyId);
    console.log('this.ScompanyNamedfghj', this.companyName);
    if (this.staffType == 'USER') {
      this.user = {
        userId: null,
        firstName: '',
        lastName: '',
        gender: 'Male',
        userName: null,
        password: null,
        email: null,
        mobile: null,
        companyId: null,
        plantId: null,
        processId: null,
        status: 'ACTIVE',
        userType: '',
        empId: null,
        vehicles: [{ vehicleId: null, vehicleRsn: null }],
      };

      this.selectedDeptIds = [];
      this.selectedDeptNames = [];
      console.log('deptIds', this.selectedDeptIds);

      this.companyName = null;
      this.plantName = null;
      this.processName = null;
      this.companyData = [];
      this.plantData = [];
      if(this.role.user_role=='AXESTRACK')this.getCompany();
      else this.getPlantById(this.userService._loggedInUser.companyId);
      this.btn = 'save';
      this.title = 'Add User';
      this.userNameValidator = null;
      this.passwordValidator = null;
      this.statusValidator = null;
      this.mobileValidator = null;
      this.companyValidator = null;
      this.plantValidator = null;
      this.processValidator = null;
      this.typeValidator = null;
      this.empIdValidator = null;
      this.staffType = 'USER';
      this.emailValidator=null;
    } else if (this.staffType == 'STAFF') {
      this.user = {
        userId: null,
        firstName: '',
        lastName: '',
        gender: 'Male',
        userName: null,
        password: null,
        email: null,
        mobile: null,
        companyId: null,
        plantId: null,
        processId: null,
        status: 'ACTIVE',
        userType: '',
        empId: null,
        vehicles: [{ vehicleId: null, vehicleRsn: null }],
      };

      this.staffType = 'STAFF';
      this.selectedDeptIds = [];
      this.selectedDeptNames = [];
      this.title = 'Add Staff';
      console.log('deptIds', this.selectedDeptIds);
      this.companyName = null;
      this.plantName = null;
      this.companyData = [];
      this.plantData = [];
      this.getCompany();
      console.log('this.user.companyId', this.user.companyId);
      console.log('this.ScompanyName', this.companyName);
      this.btn = 'save';
      this.userNameValidator = null;
      this.statusValidator = null;
      this.mobileValidator = null;
      this.typeValidator = null;
      this.empIdValidator = null;
      this.staffFirstNameValidator = null;
      this.staffLastNameValidator = null;
      this.staffFirstName = null;
      this.staffLastName = null;
      // this.staffTypeList=[];
      this.staffTypeId = null;
      this.staffTypeName = null;
      // this.staffList=[];
    }else{

      this.user = {
        userId: null,
        firstName: '',
        lastName: '',
        gender: 'Male',
        userName: null,
        password: null,
        email: null,
        mobile: null,
        companyId: null,
        plantId: null,
        processId: null,
        status: 'ACTIVE',
        userType: '',
        empId: null,
        vehicles: [{ vehicleId: null, vehicleRsn: null }],
      };

      this.selectedDeptIds = [];
      this.selectedDeptNames = [];
      this.companyName = null;
      this.plantName = null;
      this.processName = null;
      this.companyData = [];
      this.plantData = [];
      if(this.role.user_role=='AXESTRACK')this.getCompany();
      else this.getPlantById(this.userService._loggedInUser.companyId);
      this.btn = 'save';
      this.title = 'Add Transporter';
      this.userNameValidator = null;
      this.passwordValidator = null;
      this.statusValidator = null;
      this.mobileValidator = null;
      this.companyValidator = null;
      this.plantValidator = null;
      this.processValidator = null;
      this.typeValidator = null;
      this.empIdValidator = null;
      this.emailValidator=null;
      this.staffType = 'TRANSPORTER';
      this.staffFirstNameValidator=null;
      this.staffLastNameValidator=null;
    }
  }

  confirmAlert(row?: any) {
    console.log('delete row: ', row);
    this.common.params = {
      title: 'Delete User',
      description: `<b>&nbsp;` + 'Are you sure you want to delete ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteData(row);
      }
    });
  }

  confirmAlertForUserBan(userId: any, status:any) {
  console.log('status: ', status);
    this.common.params = {
      title: status + ' User',
      description:
        `<b>&nbsp;` + 'Are you sure you want to ' + status +' this user ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.blockUser(userId,status);
      }
    });
  }

  confirmAlertForTransporter(row,status:any){
    this.common.params = {
      title: (status=='BLOCKED' ? 'Block ' : 'Unblock ') +  ' Transporter',
      description:
        `<b>&nbsp;` + 'Are you sure you want to ' + (status=='BLOCKED' ? 'Block' : 'Unblock') + ' this Transporter ? ' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.blockTransporter(row,status);
      }
    });
  }

  blockTransporter(row,status:any){
    let params={
      companyId:row.company_id,
      plantId:row.plant_id,
      username:row.user_name,
      status:status,
      mobileNo:row.mobile_number,
      gender:row.gender,
      lastName:row.last_name,
      firstName:row.first_name,
      transporterId:row.id
    };

  this.api.post('transporter/save-transporter', params).subscribe(
    (res: any) => {
      if (res.status) {
        this.common.loading = false;
        this.alertService.success(res.message);
        this.getData();
      }
    },
    (err: any) => {
      console.error('Error: ', err);
      this.common.loading = false;
    }
  );
  }

  deleteData(row?: any) {
    console.log(row);
    this.common.loading = true;
    let params: any;
    let apiURL: any;
    if (this.staffType == 'USER') {
      params = {
        userId: row.userId,
        firstName: row.firstName,
        lastName: row.lastName,
        gender: row.gender,
        userName: row.userName,
        email: row.email,
        mobileNumber: row.mobileNumber,
        userStatus: 'DELETED',
        company: row.company,
        plant: row.plant,
        userType: row.userType,
        employeeId: row.employeeId,
        vehicles: row.vehicles,
      };
      apiURL = 'user/create_user';
    } else if(this.staffType == 'STAFF'){
      params = {
        staffId: row.staff_id,
        firstName: row.first_name,
        lastName: row.last_name,
        gender: row.gender,
        email: row.email,
        mobileNo: row.mobile_number,
        status: 'DELETED',
        companyId: row.company_id,
        plantId: row.plant_id,
        staffType: row.type_name,
        deptIds: this.selectedDeptIds,
        userRole: this.role.user_role,
        staffTypeId: row.staff_type_id,
      };
      console.log(this.selectedDeptIds, 'this is in save');
      apiURL = 'staff/create-staff';
    }else{
      params={
        companyId:row.company_id,
        plantId:row.plant_id,
        username:row.user_name,
        status:'DELETED',
        mobileNo:row.mobile_number,
        gender:row.gender,
        lastName:row.last_name,
        firstName:row.first_name,
        transporterId:row.id
      };
      apiURL = 'transporter/save-transporter';
    }

    console.log('params: ', apiURL);

    this.api.post(apiURL, params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertService.success(res.message);
          this.getData();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  blockUser(userId: any,status:any) {
    this.common.loading = true;
    let params = {
      userId: userId,
      status : status
    };
    this.api.get('user/block-unblock-user', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alertService.success(res.message);
          this.getData();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  async editUser(row?: any) {
    this.editFlag = false;
    this.common.gotoTop();
    this.resetDetail();
    await this.getPlantById(row.company);
    this.companyName = this.companyData.find(
      (company) => company.companyId == row.company
    )?.companyName;
    this.plantName = this.plantData.find(
      (plant) => plant.plantId == row.plant
    )?.plantName;
    this.user.userId = row.userId;
    this.user.firstName = row.firstName;
    this.user.lastName = row.lastName;
    this.user.gender = row.gender;
    this.user.userName = row.userName;
    this.user.email = row.email;
    this.user.mobile = row.mobileNumber;
    this.user.status = row.userStatus;
    this.user.companyId = row.company;
    this.user.password = '*************'
    this.user.plantId = row.plant;
    this.user.empId = row.employeeId;
    this.title = 'Edit User Details';

    if (row.vehicles.length > 0) {
      this.user.vehicles = row.vehicles;
    }
    //   this.user.processId = row['process'].processId;
    // this.companyName = row['company'].companyName;
    // this.plantName = row['plant'].plantName;
    //  this.processName = row['process'].processName;
    this.user.userType = row.userType;
    this.btn = 'update';
    this.title = 'Edit User';
    // this.common.gotoTop();
    if (row.userId == this.userId) {
      this.editFlag = true;
    }
    await this.getDepartmentList();
    this.selectedDeptIds = row.departments.map((dept) => dept.deptId);
    console.log('this.selectedDeptIds: ', this.selectedDeptIds);
    this.selectedDeptNames = row.departments.map((dept) => ({
      dept_name: dept.deptName,
      dept_id: dept.deptId,
    }));
    console.log('this.selectedDeptNames: ', this.selectedDeptNames);
  }

  async editEmployee(row?: any) {
    this.common.gotoTop();
    this.resetDetail();
    // this.staffType='STAFF';
    await this.getPlantById(row.company_id);
    this.user.companyId = row.company_id;
    this.user.plantId = row.plant_id;
    this.companyName = row.company_name;
    this.plantName = row.plant_name;
    this.user.userId = row.staff_id;
    this.staffTypeId = row.staff_type_id;
    this.staffFirstName = row.first_name;
    this.staffLastName = row.last_name;
    this.user.gender = row.gender;
    this.user.email = row.email;
    this.user.mobile = row.mobile_number;
    this.user.status = row.staff_status;
    this.staffTypeName = row.type_name;
    this.title = 'Edit Staff Details';
    await this.getDepartmentList();
    this.selectedDeptNames = row.departments.map((dept) => ({
      dept_name: dept.deptName,
      dept_id: dept.deptId,
    }));
  }

  getAllVehicles() {
    this.common.loading = true;
    this.api.get('user/get-vehicles-list').subscribe(
      (res: any) => {
        if (res.status) {
          this.vehiclesList = res.data;
          console.log('this.vehiclesList: ', this.vehiclesList);
          this.common.loading = false;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  onSelectVehicle(event: any, type: string, value: any) {
    if (type == 'select') {
      value = event.vehicleRsn;
    } else {
      value = event;
    }
  }

  addVehicle(index: any) {
    console.log('Adding Vehicle');
    this.user.vehicles.push({
      vehicleId: null,
      vehicleRsn: null,
    });
  }

  removeVehicle(index: any) {
    console.log('Removing Vehicle');
    if (index != 0) {
      this.user.vehicles.splice(index, 1);
    } else {
      this.user.vehicles[0].vehicleId = null;
      this.user.vehicles[0].vehicleRsn = null;
    }
  }

  staffFirstNameValidator: any;
  staffLastNameValidator: any;

  userNameValidator: any;
  passwordValidator: any;
  statusValidator: any;
  mobileValidator: any;
  companyValidator: any;
  plantValidator: any;
  processValidator: any;
  typeValidator: any;
  empIdValidator: any;
  emailValidator: any = false;

  formValidator() {
    console.log('sdfg', this.staffType);

    if (this.staffType == 'STAFF') {
      console.log('1', this.staffType);
      if (this.user.email != null && this.user.email != '') {
        this.emailValidator = this.common.emailValidator(
          this.user.email,
          'Invalid Email Id'
        );
      }
      console.log('2', this.staffType);
      this.staffFirstNameValidator = this.common.nullValidator(
        this.staffFirstName,
        'Staff First Name Mandatory'
      );
      this.staffLastNameValidator = this.common.nullValidator(
        this.staffLastName,
        'Staff Last Name Mandatory'
      );
      console.log('3', this.staffType);
      this.typeValidator = this.common.nullValidator(
        this.staffTypeName,
        'Staff Type Mandatory'
      );
      this.mobileValidator = this.common.mobileNoValidator(
        this.user.mobile,
        'Mobile No. invalid'
      );
      console.log("xwhiujxw",this.emailValidator);
      if (
        !this.staffFirstNameValidator.error &&
        !this.staffLastNameValidator.error &&
        !this.typeValidator.error &&
        !this.mobileValidator.error &&
        ((this.emailValidator && !this.emailValidator.error)||this.emailValidator==null)
      ) {
        console.log("hxiwjxo");
        this.saveData();
      }
    // } else if(this.staffType == 'USER'){
    //   this.user.companyId =
    //     this.user.companyId == null
    //       ? this.userService._loggedInUser.companyId
    //       : this.user.companyId;
    //   this.user.plantId =
    //     this.user.plantId == null
    //       ? this.userService._loggedInUser.plantId
    //       : this.user.plantId;
    //   if (this.user.email != null && this.user.email != '') {
    //     this.emailValidator = this.common.emailValidator(
    //       this.user.email,
    //       'Invalid Email Id'
    //     );
    //   }
    //   if (
    //     this.role.user_role == 'COMPANYADMIN' ||
    //     this.role.user_role == 'COMPANYUSER'
    //   ) {
    //     this.user.companyId = this.userDetails.companyId;
    //   }
    //   this.userNameValidator = this.common.nullValidator(
    //     this.user.userName,
    //     'User Name Mandatory'
    //   );
    //   this.typeValidator = this.common.nullValidator(
    //     this.user.userType,
    //     'User Type Mandatory'
    //   );
    //   this.statusValidator = this.common.nullValidator(
    //     this.user.status,
    //     'User Status Mandatory'
    //   );
    //   this.mobileValidator = this.common.mobileNoValidator(
    //     this.user.mobile,
    //     'Mobile No. invalid'
    //   );
    //   this.companyValidator = this.common.nullValidator(
    //     this.user.companyId,
    //     'Company Mandatory'
    //   );
    //   this.plantValidator = this.common.nullValidator(
    //     this.user.plantId,
    //     'Plant Mandatory'
    //   );
    //   this.userNameValidator = this.common.nullValidator(
    //     this.user.userName,
    //     'User Name Mandatory'
    //   );
    //   this.empIdValidator = this.common.nullValidator(
    //     this.user.empId,
    //     'Employee Id Mandatory'
    //   );
    //   if (this.user.userId) {
    //     if (
    //       this.user.userType == 'AXESTRACK' ||
    //       this.user.userType == 'AXESTRACKUSER'
    //     ) {
    //       if (
    //         !this.userNameValidator.error &&
    //         !this.statusValidator.error &&
    //         !this.mobileValidator.error &&
    //         !this.typeValidator.error &&
    //         !this.empIdValidator.error &&
    //         ((this.emailValidator && !this.emailValidator.error)||this.emailValidator==null)
    //       ) {
    //         this.saveData();
    //       }
    //     } else if (
    //       this.user.userType == 'COMPANYADMIN' ||
    //       this.user.userType == 'COMPANYUSER'
    //     ) {
    //       if (
    //         !this.companyValidator.error &&
    //         !this.userNameValidator.error &&
    //         !this.statusValidator.error &&
    //         !this.mobileValidator.error &&
    //         !this.typeValidator.error &&
    //         !this.empIdValidator.error &&
    //         ((this.emailValidator && !this.emailValidator.error)||this.emailValidator==null)
    //       ) {
    //         this.saveData();
    //       }
    //     } else {
    //       console.log()
    //       if (
    //         !this.companyValidator.error &&
    //         !this.plantValidator.error &&
    //         !this.userNameValidator.error &&
    //         !this.statusValidator.error &&
    //         !this.mobileValidator.error &&
    //         !this.typeValidator.error &&
    //         !this.empIdValidator.error &&
    //         ((this.emailValidator && !this.emailValidator.error)||this.emailValidator==null)
    //       ) {
    //         this.saveData();
    //       }
    //     }
    //   } else {
    //     this.passwordValidator = this.common.nullValidator(
    //       this.user.password,
    //       'Password Mandatory'
    //     );
    //     if (
    //       this.user.userType == 'AXESTRACK' ||
    //       this.user.userType == 'AXESTRACKUSER'
    //     ) {
    //       if (
    //         !this.userNameValidator.error &&
    //         !this.statusValidator.error &&
    //         !this.mobileValidator.error &&
    //         !this.typeValidator.error &&
    //         !this.passwordValidator.error &&
    //         !this.empIdValidator.error &&
    //         ((this.emailValidator && !this.emailValidator.error)||this.emailValidator==null)
    //       ) {
    //         this.saveData();
    //       }
    //     } else if (
    //       this.user.userType == 'COMPANYADMIN' ||
    //       this.user.userType == 'COMPANYUSER'
    //     ) {
    //       if (
    //         !this.companyValidator.error &&
    //         !this.userNameValidator.error &&
    //         !this.statusValidator.error &&
    //         !this.mobileValidator.error &&
    //         !this.typeValidator.error &&
    //         !this.passwordValidator.error &&
    //         !this.empIdValidator.error &&
    //         ((this.emailValidator && !this.emailValidator.error)||this.emailValidator==null)
    //       ) {
    //         this.saveData();
    //       }
    //     } else {
    //       if (
    //         !this.companyValidator.error &&
    //         !this.plantValidator.error &&
    //         !this.userNameValidator.error &&
    //         !this.statusValidator.error &&
    //         !this.mobileValidator.error &&
    //         !this.typeValidator.error &&
    //         !this.passwordValidator.error &&
    //         !this.empIdValidator.error &&
    //         ((this.emailValidator && !this.emailValidator.error)||this.emailValidator==null)
    //       ) {
    //         this.saveData();
    //       }
    //     }
    //   }
    // }else{
    //   if(this.role.user_role=='AXESTRACK'){
    //     this.companyValidator = this.common.nullValidator(
    //       this.user.companyId,
    //       'Company Mandatory'
    //     );
    //   }
    //   if(this.role.user_role=='AXESTRACK' || this.role.user_role=='COMPANYADMIN'){
    //     this.plantValidator = this.common.nullValidator(
    //       this.user.plantId,
    //       'Plant Mandatory'
    //     );
    //   }
    //   this.staffFirstNameValidator = this.common.nullValidator(
    //     this.user.firstName && this.user.firstName.trim(),
    //     'Transporter First Name Mandatory'
    //   );
    //   this.staffLastNameValidator = this.common.nullValidator(
    //     this.user.lastName && this.user.lastName.trim(),
    //     'Transporter Last Name Mandatory'
    //   );
    //   this.mobileValidator = this.common.mobileNoValidator(
    //     this.user.mobile,
    //     'Mobile No. invalid'
    //   );
    //   if (this.user.email != null && this.user.email != '') {
    //     this.emailValidator = this.common.emailValidator(
    //       this.user.email,
    //       'Invalid Email Id'
    //     );
    //   }
    //   if(this.user.userId==null)this.passwordValidator = this.common.nullValidator(
    //     this.user.password && this.user.password.trim(),
    //     'Password Mandatory'
    //   );
    //   if(((this.role.user_role=='AXESTRACK' && !this.companyValidator.error && this.plantValidator)||(this.role.user_role=='COMPANYADMIN' && this.plantValidator)||(this.role.user_role!='AXESTRACK' && this.role.user_role!='COMPANYADMIN')) && !this.staffFirstNameValidator.error && !this.staffLastNameValidator.error && !this.mobileValidator.error && ((this.emailValidator && !this.emailValidator.error)||this.emailValidator==null) && ((this.passwordValidator && !this.passwordValidator.error)||this.passwordValidator==null)){
    //     this.saveData();
    //   }
    }
  }

  editTransporter(row){
    this.user.firstName = row.first_name;
    this.user.lastName = row.last_name;
    this.user.gender=row.gender;
    this.user.email=row.email;
    this.user.userId = row.id;
    this.user.mobile=row.mobile_number;
    this.user.userName=row.user_name;
    this.user.status=row.user_status;
    this.user.companyId=row.company_id;
    this.user.plantId=row.plant_id;
    this.user.password = '************'
    this.btn = 'update';
    this.title = 'Edit Transporter';
    this.companyName=row.company_name;
    this.plantName=row.plant_name;
    console.log("wsiqw",this.plantName);
    if(this.role.user_role=='AXESTRACK'){
      this.getCompany();
      this.getPlantById(row.company_id);
    }
    else if(this.role.user_role=='COMPANYADMIN')this.getPlantById(this.user.companyId);

  }

}

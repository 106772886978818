import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { MapService } from '../../@core/services/map.service';

@Component({
  selector: 'app-driver-navigation',
  templateUrl: './driver-navigation.component.html',
  styleUrls: ['./driver-navigation.component.scss'],
})
export class DriverNavigationComponent implements OnInit {
  constructor(
    public api: ApiService,
    public common: CommonService,
    public mapService: MapService,
    private activeModal: NgbActiveModal
  ) {}
  ngOnInit() {
    this.mapService.mapIntialize('map');
  }

  ngAfterViewInit() {
    this.mapService.clearAll();
    this.mapService.setMapType(0);
    this.mapService.map.setOptions({ draggableCursor: 'cursor' });
    const flightPlanCoordinates = [
      { lat: 37.772, lng: -122.214 },
      { lat: 21.291, lng: -157.821 },
      { lat: -18.142, lng: 178.431 },
      { lat: -27.467, lng: 153.027 },
    ];
    flightPlanCoordinates.forEach((element) => {
      this.mapService.createPolyPathManual(
        this.mapService.createLatLng(element.lat, element.lng)
      );
    });
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }
}

<div class="container-fluid">

  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                {{"checklist_dashboard"| customTranslate}}
              </h4>
            </div>

            <div class="card-body">
              <div class="tab_btnn_sec">
                <div class="tab_btnn" *ngFor="let tab of actionLabels;index as i">
                  <button type="button" class="btn btn-block nav-buttons" (click)="changeTab(tab.status,i)"
                    [ngClass]="{'btn-inactive':activeTab != i,'btn-active':activeTab == i}"
                    [ngStyle]="{'background-color': tab.tabBgColor}">
                    <div class="left_part">
                      <div class="tab_name">{{tab.tabName | customTranslate}}</div>
                      <div class="tab_number">{{tab.tabName == 'Pending' ? statusWiseChecklistCount?.Pending : tab.tabName ==
                        'Late Completed' ? (activeTab == 2 && common.loading==false ? userChecklistData.length :
                        statusWiseChecklistCount?.LateCompleted) : (tab.tabName == "Completed" ? (activeTab == 1 &&
                        common.loading==false ? userChecklistData.length : statusWiseChecklistCount?.Approved) : (activeTab == 3
                        && common.loading==false ?
                        userChecklistData.length : statusWiseChecklistCount?.Rejected))}}</div>
                    </div>

                    <div class="right_part">
                      <div class="icon_div" [ngStyle]="{'background-color': tab.tabIconBg}">
                        <i [class]="'fa fa-'+tab.tab_icon" [ngClass]="tab.tabName"></i>
                      </div>
                    </div>
                  </button>
                </div>
              </div>

              <div class="checklist_sec">
                <div class="row filter_row" *ngIf="activeTab !=0">
                  <div class="col-md-3 date pr-0 pl-0">
                    <label>{{'start_date:'|customTranslate}}</label>
                    <input type="date" class="form-control"
                      (change)="getUserChecklistData(actionLabels[activeTab]['status'])" [(ngModel)]="startDate">
                  </div>
                  <div class="col-md-3 date">
                    <label>{{'end_date:'|customTranslate}}</label>
                    <input type="date" class="form-control"
                      (change)="getUserChecklistData(actionLabels[activeTab]['status'])" [min]="startDate"
                      [(ngModel)]="endDate">
                  </div>
                </div>

                <div class="no-data-found" *ngIf="userChecklistData.length == 0">
                  <img src="assets/images/no-checklist.png">{{"no_checklist_available"|customTranslate}}
                </div>

                <div *ngFor="let row of userChecklistData,index as i" class="checklist"
                  [class]="'checklist-'+((activeTab))">
                  <div class="checklist-data">
                    <div class="data">
                      <div class="middle title_row">
                        <div class="dark">
                          <img src="assets/images/checklist_icon.png">
                          {{row.checklist_title}}
                        </div>
                        <div class="title_badge" *ngIf="activeTab==0 && compareTargetTime(row.start_time,row.scheduled_date)">
                          <div class="shrink-badge"
                            [ngStyle]="{'color': compareTargetTime(row.last_time,row.scheduled_date)?'white': 'black','background-color': compareTargetTime(row.last_time,row.scheduled_date) && !compareTargetTime(row.end_time,row.scheduled_date) ? '#fbb2aedb' : compareTargetTime(row.last_time,row.scheduled_date) && compareTargetTime(row.end_time,row.scheduled_date)?'red': 'none','border-style': compareTargetTime(row.last_time,row.scheduled_date) ? 'none':'solid'}">
                            <app-time-counter [targetDate]="row.scheduled_date+'T'+row.end_time+':00'" ></app-time-counter>
                          </div>
                        </div>
                      </div>
                      <div class="middle" *ngIf="activeTab!=0">
                        <div class="dark">{{row.perform_date| date:'dd-MM-yyyy'}}</div>
                        <div class="light"><i class="fa fa-calendar"></i>{{'Perform Date'|customTranslate}}:</div>
                      </div>
                      <div class="middle">
                        <div class="dark">{{row.scheduled_date| date:'dd-MM-yyyy'}}</div>
                        <div class="light"><i class="fa fa-calendar"></i>{{'Scheduled Date'|customTranslate}}:</div>
                      </div>
                      <div class="middle" *ngIf="activeTab==0">
                        <div class="dark">{{row.start_time}}</div>
                        <div class="light"><i class="fa fa-clock-o"></i>{{'Start Time'|customTranslate}}:</div>
                      </div>
                      <div class="middle" *ngIf="activeTab==0">
                        <div class="dark">{{row.end_time}}</div>
                        <div class="light"><i class="fa fa-clock-o"></i>{{'End Time'|customTranslate}}:</div>
                      </div>

                      <div class="middle" *ngIf="activeTab!=0">
                        <div class="dark">{{row.perform_time}}</div>
                        <div class="light"><i class="fa fa-clock-o"></i>{{'Perform Time'|customTranslate}}:</div>
                      </div>

                      <div class="middle">
                        <div class="dark">{{row.added_by}}</div>
                        <div class="light"><i class="fa fa-user-o"></i>{{'Assigned By'|customTranslate}}:</div>
                      </div>
                    </div>
                  </div>

                  <div class="left-side" *ngIf="activeTab==0 && !compareTargetTime(row.start_time,row.scheduled_date)">
                    <div class="shrink-badge"
                      [ngStyle]="{'color': 'black','background-color': 'white','border-style': 'solid','border-color':'red'}">
                      {{'available_at'|customTranslate}}: {{row.start_time}}
                    </div>
                  </div>

                  <div class="left-side" *ngIf="activeTab==0 && compareTargetTime(row.start_time,row.scheduled_date)">
                    <button class="shrink-badge button-color" (click)="performChecklistAction(row,'perform')">
                      {{'perform'|customTranslate}} <i
                        class="fa fa-chevron-right"></i></button>
                  </div>

                  <div class="left-side" *ngIf="activeTab!=0">
                    <div class="shrink-badge" *ngIf="activeTab==1 && row.delayed" style="color:black;background-color:#fdecd5;border-style:solid;border-color: rgb(234 167 52);">Within Tolerence Period</div>
                    <button *ngIf="activeTab==3" class="shrink-badge button-color"
                      (click)="viewRejectedRemark(row.log_id,row.checklist_title)"
                      style="background-color: #395793db;">{{'reject remark'|customTranslate}}</button>
                    <button *ngIf="row.late_remark_id!=null" class="shrink-badge button-color"
                      (click)="viewRemark(row.log_id,row.checklist_title)"
                      style="background-color: #dd3128db;;">{{'remark'|customTranslate}}</button>
                    <button *ngIf="activeTab!=3" class="shrink-badge button-color" (click)="performChecklistAction(row,'view')"
                      style="background-color: #395793db;">{{'view'|customTranslate}}</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body process-form">

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'asset_label'|translate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input">{{"company" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="company" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                </div>

                <div *ngIf="(role.user_role == 'AXESTRACK' || role.user_role == 'COMPANYADMIN')"
                  class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"plant" | translate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" class="required" [data]="plantData"
                    display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'asset_label_name'|translate}}</label>
                  <input class="form-control" type="text" name="assetLabelName" [(ngModel)]="assetLabel.assetLabelName">
                  <div class="error" *ngIf="assetLabelNameValidator && assetLabelNameValidator.error">
                    <span>{{assetLabelNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{'asset_label_desc'|translate}}</label>
                  <input class="form-control" type="text" name="assetLabelDesc" [(ngModel)]="assetLabel.assetLabelDesc">
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="" class="d-block" class="required"> {{'status'|translate}} </label>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="assetLabelStatus"
                      [(ngModel)]="assetLabel.assetLabelStatus" id="asset_label_status_1" value="ACTIVE" checked>
                    <label for="asset_label_status_radios1">
                      {{'active'|translate}}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <input class="form-check-input" type="radio" name="assetLabelStatus"
                      [(ngModel)]="assetLabel.assetLabelStatus" id="assetLabelStatus" value="INACTIVE">
                    <label for="asset_label_status_radio2">
                      {{'inactive'|translate}}
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group action-block">
                <div class="btn-block">
                  <button type="submit" class="btn btn-muted" (click)=" common.backClicked()"> {{'back'|translate}}
                  </button>
                  <button class="btn btn-warning" (click)="resetWorkType()"> {{'reset'|translate}} </button>
                  <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{'save'|translate}}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'asset_label_list'|translate}}
                </h3>
              </div>
            </div>
            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{'serial_no'|translate}}</th>
                    <th>{{'plant'| translate}}</th>
                    <th>{{'company'| translate}}</th>
                    <th>{{'asset_label_name'|translate}}</th>
                    <th>{{'asset_label_desc'|translate}}</th>
                    <th>{{'status'|translate}}</th>
                    <th class="action">{{'Actions'|translate}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of responseData let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.plant_name}}</td>
                    <td>{{row.company_name}}</td>
                    <td>{{row.asset_label_name}}</td>
                    <td>{{row.asset_label_description}}</td>
                    <td>{{row.status_name}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="view(row)"> {{'view'|translate}}
                        </button>
                        <button class="btn btn-success" *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                        || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1))"
                          (click)="edit(row)"> {{'edit'|translate}}
                        </button>
                        <button class="btn btn-danger" *ngIf="role.user_role== 'AXESTRACK' || (role.user_role== 'COMPANYADMIN' && row.plant_id!=1)
                        || (plantLevelRoles.includes(role.user_role) && (row.plant_id != null && row.plant_id!=1))"
                          (click)="confirmDelete(row)">
                          {{'delete'|translate}} </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

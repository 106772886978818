import { CommonService } from 'src/app/@core/services/common.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
declare let google: any;
declare let MarkerClusterer: any;
import * as geolib from 'geolib';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  events: Subject<any> = new Subject();
  poly = null;
  polyVertices = [];
  map = null;
  mapDiv = null;
  markers = [];
  customMarkers: any[] = [];
  bounds = null;
  infoStart = null;
  infoWindow = null;
  polygon = null;
  polygons = [];
  isMapLoaded = false;
  mapLoadDiv = null;
  cluster = null;
  lineSymbol = {
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
  };
  lineSymbolBack = {
    path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
  };

  polygonPath = null;
  polygonPathVertices = [];
  isDrawPolyGon = false;
  designsDefaults = [
    'M  0,0,  0,-5,  -5,-5,-5,-13 , 5,-13 ,5,-5, 0,-5 z', ///Rect
    'M  0,0,  0,-5,  -5,-13 , 5,-13 , 0,-5 z', //Pin
  ];
  options = null;
  heatmap = null;
  polygonPaths = [];
  polygonPathsVertices = [[]];
  circles = [];
  circle = null;
  marker: any;
  isDrawMarker: boolean;
  constructor(public common: CommonService) {}

  autoSuggestion(elementId, setLocation?) {
    let options = {
      types: ['(cities)'],
      componentRestrictions: { country: 'in' },
    };
    let ref = document.getElementById(elementId); //.getElementsByTagName('input')[0];
    let autocomplete = new google.maps.places.Autocomplete(ref, options);
    google.maps.event.addListener(
      autocomplete,
      'place_changed',
      this.updateLocation.bind(this, elementId, autocomplete, setLocation)
    );
  }

  updateLocation(elementId, autocomplete, setLocation?) {
    let placeFull = autocomplete.getPlace();
    //console.log('placeFullNAme', placeFull);
    let lat = placeFull.geometry.location.lat();
    let lng = placeFull.geometry.location.lng();
    let place = placeFull.formatted_address.split(',')[0];
    setLocation && setLocation(place, lat, lng, placeFull.formatted_address);
  }

  zoomAt(latLng, level = 18) {
    this.map.panTo(latLng);
    if (level != this.map.getZoom()) this.zoomMap(level);
  }

  centerAt(latLng) {
    this.map.panTo(latLng);
  }

  zoomMap(zoomValue) {
    console.log('zoomValue: ', zoomValue);
    console.log(this.map);
    this.map.setZoom(zoomValue);
    if (zoomValue <= 14) {
      this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    } else if (zoomValue > 14) {
      this.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
    }
  }

  createPolygonsWithMainlatlng(latLngsMulti, mainLatLngs?, options?) {
    // strokeColor = '#', fillColor = '#') {
    if (this.polygons.length > 0) {
      this.polygons.forEach((polygon) => {
        polygon.setMap(null);
      });
      this.polygons = [];
    }
    latLngsMulti.forEach((latLngs) => {
      let colorBorder = '#228B22';
      let colorFill = '#ADFF2F';
      if (mainLatLngs != latLngs) {
        colorBorder = '#550000';
        colorFill = '#ff7f7f';
      }
      const defaultOptions = {
        paths: latLngs,
        strokeColor: colorBorder,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        clickable: false,
        fillColor: colorFill,
        fillOpacity: 0.35,
      };
      this.polygons.push(new google.maps.Polygon(options || defaultOptions));
    });
    this.polygons.forEach((polygon) => {
      polygon.setMap(this.map);
    });
  }

  initToolTip() {
    var locations = [
      ['Country 1', 39.690642467918366, 39.07426848448813],
      ['Country 2', 39.68279, 19.764394],
    ];
    var bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < locations.length; i++) {
      var marker = this.createMarkerTooltip(locations[i], this.map, null);
      bounds.extend(marker.getPosition());
    }
    this.map.fitBounds(bounds);
  }

  createMarkerTooltip(location, map, myIcon) {
    const marker = new google.maps.Marker({
      content: location[0],
      icon: myIcon,
      position: new google.maps.LatLng(location[1], location[2]),
      animation: google.maps.Animation.DROP,
      optimized: false,
      map: map,
    });
    var infowindow = new google.maps.InfoWindow({
      content: 'Loading...',
    });
    google.maps.event.addListener(marker, 'mouseover', function () {
      infowindow.setContent(this.content);
      infowindow.open(map, this);
    });
    google.maps.event.trigger(marker, 'mouseover');
    return marker;
  }

  placeMarkerRadius(center, type, radius) {
    const pathColors = {
      1: {
        colorBorder: '#FF0000',
        colorFill: '#ff5a3c',
      },
      2: {
        colorBorder: '#F1A41D',
        colorFill: '#ffcd30',
      },
      3: {
        colorBorder: '#159F29',
        colorFill: '#28ca3f',
      },
      4: {
        colorBorder: '#127d93',
        colorFill: '#219cb5',
      },
      5: {
        colorBorder: '#C83BCE',
        colorFill: '#F489F8',
      },
      6: {
        colorBorder: '#49B3FB',
        colorFill: '#128ADC',
      },
    };

    let fillColor = pathColors[type].colorFill;
    let borderColor = pathColors[type].colorBorder;
    this.createCirclesOnPosition(center, radius, borderColor, fillColor, 0.2);
  }

  createPlantGeoFence(
    latLngsMulti,
    event = true,
    center = true,
    options?,
    customeLabel: boolean = false
  ) {
    let defaultOptions: any = null;
    this.markers = [];
    if (this.polygons.length > 0) {
      this.polygons.forEach((polygon) => {
        polygon.setMap(null);
      });
      this.polygons = [];
    }
    this.resetCircles();
    // console.log(latLngsMulti,"hhh")
    latLngsMulti.forEach((latLngs) => {
      const latLong = { lat: latLngs.lat, lng: latLngs.lng };
      let colorBorder = '#228B22';
      let colorFill = '#ADFF2F';
      let type = latLngs.type || 0;
      let centerLatLongData: any = latLngs.data.length
        ? geolib.getCenterOfBounds(latLngs.data)
        : null;
      let geoFenceId = latLngs.id;
      let centerLatLongs = latLngs.data.length
        ? { lat: centerLatLongData.latitude, lng: centerLatLongData.longitude }
        : latLong;

      const pathColors = {
        1: {
          colorBorder: '#FF0000',
          colorFill: '#ff5a3c',
        },
        2: {
          colorBorder: '#F1A41D',
          colorFill: '#ffcd30',
        },
        3: {
          colorBorder: '#159F29',
          colorFill: '#28ca3f',
        },
        4: {
          colorBorder: '#127d93',
          colorFill: '#219cb5',
        },
        5: {
          colorBorder: '#C83BCE',
          colorFill: '#F489F8',
        },
        6: {
          colorBorder: '#49B3FB',
          colorFill: '#128ADC',
        },
        7: {
          colorBorder: '#0d54fc',
          colorFill: '#5382f1;',
        },
      };

      if (latLngs.data.length) {
        defaultOptions = {
          paths: latLngs.data,
          strokeColor: [1, 2, 3, 4, 5, 6, 7].includes(type)
            ? pathColors[type].colorBorder
            : colorBorder,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          clickable: event,
          // editable:true,
          geodesic: true,
          map: this.map,
          zIndex: 0,
          fillColor: [1, 2, 3, 4, 5, 6, 7].includes(type)
            ? pathColors[type].colorFill
            : colorFill,
          fillOpacity: 0.35,
          indexID: geoFenceId,
        };

        this.polygons.push(new google.maps.Polygon(options || defaultOptions));
      }

      if (!latLngs.data.length && latLngs.radius) {
        this.placeMarkerRadius(latLong, latLngs.type, latLngs.radius);
      }

      if (!center) {
        centerLatLongs = latLngs.data.length ? latLngs.data[0] : latLong;
      }

      if (!event) {
        // console.log('fukigjehrbf');
        if (customeLabel) {
          this.addCustomLabelMarkers(
            centerLatLongs,
            latLngs.label,
            geoFenceId,
            latLngs.type,
            latLngs.ref_id,
            latLngs.ref_data,
            latLngs.labelName
          );
        } else {
          this.addLabelMarkers(
            centerLatLongs,
            latLngs.label,
            geoFenceId,
            latLngs.type,
            latLngs.ref_id,
            latLngs.ref_data,
            latLngs.labelName
          );
        }
      }
    });

    this.polygons.forEach((polygon) => {
      polygon.setMap(this.map);
      this.addListerner(polygon, 'click', function (evt) {
        // console.log('evt:', evt)
      });
    });
    this.addListerner(this.polygon, 'click', function (event) {
      // console.log('event:', event)
    });
  }

  setMultiBounds(bounds, isReset = false) {
    if (isReset) this.resetBounds();
    for (let index = 0; index < bounds.length; index++) {
      const thisPoint = bounds[index];
      this.setBounds(this.createLatLng(thisPoint.lat, thisPoint.lng));
    }
  }

  createSingleMarker(latLng, defaultIcon = false) {
    var icon = {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 4,
      fillColor: '#000000',
      fillOpacity: 1,
      strokeWeight: 1,
    };
    var marker = new google.maps.Marker({
      icon: defaultIcon ? google.maps.Animation.DROP : icon,
      position: latLng,
      map: this.map,
      // animation: google.maps.Animation.DROP,
    });
    return marker;
  }

  mapIntialize1(
    div = 'map',
    zoom = 15,
    lat = 25,
    long = 75,
    showUI = false,
    usePrevious = false,
    options?: any
  ) {
    let latLng = new google.maps.LatLng(lat, long);
    this.clearAll();
    this.mapDiv = document.getElementById(div);
    if (this.mapLoadDiv & this.map) {
      this.map.panTo(latLng);
      if (options) {
        this.map.set(options);
      }
      this.mapDiv.appendChild(this.mapLoadDiv);
      //console.log('JRx: map not re-initialized!');
    } else {
      let opt = {
        center: latLng,
        zoom: zoom,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scaleControl: true,
        disableDefaultUI: showUI,
        styles: [
          {
            featureType: 'all',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
        ],
      };

      opt = Object.assign({}, opt, options || {});
      //console.log('------------------opt', opt);
      const mapElement = document.createElement('div');
      mapElement.classList.add('google-map');

      this.map = new google.maps.Map(mapElement, opt);
      this.mapLoadDiv = this.map.getDiv();
      this.mapDiv.appendChild(this.mapLoadDiv);
      this.bounds = new google.maps.LatLngBounds();
      this.isMapLoaded = true;
    }
    this.events.next({ type: 'initialize' });
    return this.map;
  }
  mapIntialize(div = 'map', zoom = 12, lat = 25, long = 75, showUI = false) {
    if (this.isMapLoaded) {
      // document.getElementById(div).innerHTML="";
      // document.getElementById(div).append(this.mapLoadDiv.innerHTML);
      // this.setMapType(0);
      // return;
    }
    let mapTypeId =
      zoom < 17 ? google.maps.MapTypeId.ROADMAP : google.maps.MapTypeId.HYBRID;
    //console.log("mapTypeId", mapTypeId);
    this.mapDiv = document.getElementById(div);
    let latlng = new google.maps.LatLng(lat, long);
    let opt = {
      center: latlng,
      zoom: zoom,
      mapTypeId: mapTypeId,
      scaleControl: true,
      disableDefaultUI: showUI,
      styles: [
        {
          featureType: 'all',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },{
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit.station',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        }
      ],
    };
    //console.log("options", opt);
    //let ("#"+mapId).heigth(height);
    console.log('hellllllllllllllllllllo');
    this.map = new google.maps.Map(this.mapDiv, opt);
    this.mapLoadDiv = this.map.getDiv();
    this.bounds = new google.maps.LatLngBounds();
    this.isMapLoaded = true;
    return this.map;
  }
  createLatLng(lat, lng) {
    return new google.maps.LatLng(lat, lng);
  }
  createInfoWindow() {
    return new google.maps.InfoWindow();
  }

  createPolygon(latLngs, options?) {
    // strokeColor = '#', fillColor = '#') {
    const defaultOptions = {
      paths: latLngs,
      strokeColor: '#228B22',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      clickable: false,
      fillColor: '#ADFF2F',
      fillOpacity: 0.35,
    };
    this.polygon = new google.maps.Polygon(options || defaultOptions);
    this.polygon.setMap(this.map);
  }
  createPolygons(latLngsMulti, options?) {
    // strokeColor = '#', fillColor = '#') {
    let index = 0;

    latLngsMulti.forEach((latLngs) => {
      let colorBorder;
      let colorFill;
      let isMain = false;
      if (latLngs.isSec) {
        colorBorder = '#f00';
        colorFill = '#f88';
      } else if (latLngs.isMain) {
        colorBorder = '#0f0';
        colorFill = '#8f8';
      } else {
        colorBorder = '#00f';
        colorFill = '#88f';
      }
      const defaultOptions = {
        paths: latLngs.data,
        strokeColor: colorBorder,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        clickable: !latLngs.isMain,
        fillColor: colorFill,
        fillOpacity: 0.35,
        // indexID: 5000
      };
      let polygon = new google.maps.Polygon(options || defaultOptions);
      this.polygons.push(polygon);
      polygon.setMap(this.map);
      let infoWindow = new google.maps.InfoWindow();
      infoWindow.opened = false;
      let showContent = latLngs.show;
      google.maps.event.addListener(polygon, 'mouseover', function (evt) {
        infoWindow.setContent('Info: ' + showContent);
        infoWindow.setPosition(evt.latLng); // or evt.latLng
        infoWindow.open(this.map);
      });
      google.maps.event.addListener(polygon, 'mouseout', function (evt) {
        infoWindow.close();
        infoWindow.opened = false;
      });
      google.maps.event.addListener(polygon, 'click', function (evt) {
        // console.log('evt:', evt)
        // infoWindow.close();
        // infoWindow.opened = false;
      });
      index++;
    });
  }

  addListerner(element, event, callback) {
    if (element) google.maps.event.addListener(element, event, callback);
  }

  getLatLngValue(markerData) {
    let latLng = { lat: 0, lng: 0 };
    let keys = Object.keys(markerData);
    latLng.lat = parseFloat(
      markerData[
        keys.find((element) => {
          return (
            element == 'lat' ||
            element == 'y_lat' ||
            element == 'x_lat' ||
            element == 'x_tlat' ||
            element == '_lat' ||
            element == 'base_lat' ||
            element == '_base_lat'
          );
        })
      ]
    );
    latLng.lng = parseFloat(
      markerData[
        keys.find((element) => {
          return (
            element == 'lng' ||
            element == 'long' ||
            element == 'x_long' ||
            element == 'x_tlong' ||
            element == '_long' ||
            element == 'base_long' ||
            element == '_base_long'
          );
        })
      ]
    );
    return latLng;
  }

  createMarkers(
    markers,
    dropPoly = false,
    changeBounds = true,
    infoKeys?,
    afterClick?,
    infoOnMouse = false
  ) {
    let thisMarkers = [];
    let infoWindows = [];
    //console.log("Markers", markers);
    for (let index = 0; index < markers.length; index++) {
      let subType = markers[index]['subType'];
      let design =
        markers[index]['type'] == 'site'
          ? this.designsDefaults[0]
          : markers[index]['type'] == 'subSite'
          ? this.designsDefaults[1]
          : null;
      let text = markers[index]['text'] ? markers[index]['text'] : ' ';
      let pinColor = markers[index]['color']
        ? markers[index]['color']
        : 'FFFF00';
      let latLng = this.getLatLngValue(markers[index]);
      let lat = latLng.lat;
      let lng = latLng.lng;
      let title = markers[index]['title']
        ? markers[index]['title']
        : 'Untitled';
      let latlng = new google.maps.LatLng(lat, lng);
      let pinImage;
      //pin Image
      if (design) {
        pinImage = {
          path: design,
          // set custom fillColor on each iteration
          fillColor: '#' + pinColor,
          fillOpacity: 1,
          scale: 1.3,
          strokeColor: pinColor,
          strokeWeight: 2,
        };
      } else {
        if (subType == 'marker')
          pinImage =
            'http://chart.apis.google.com/chart?chst=d_map_xpin_letter&chld=pin|' +
            (index + 1) +
            '|' +
            pinColor +
            '|000000';
        //if(subType=='circle')
        else
          pinImage = {
            path: google.maps.SymbolPath.CIRCLE,
            scale: this.options ? this.options.circle.scale : 6,
            fillColor: '#' + pinColor,
            fillOpacity: 0.8,
            strokeWeight: 1,
          };
      }

      let marker = null;
      if (dropPoly) this.drawPolyMF(latlng);
      if (lat && lng) {
        marker = new google.maps.Marker({
          position: latlng,
          flat: true,
          icon: pinImage,
          map: this.map,
          title: title,
        });
        let displayText = '';
        if (infoKeys) {
          let infoWindow = this.createInfoWindow();
          infoWindows.push(infoWindow);
          infoWindow.opened = false;
          //console.log(infoWindow);
          if (typeof infoKeys == 'object') {
            infoKeys.map((display, indexx) => {
              if (indexx != infoKeys.length - 1) {
                displayText +=
                  this.common.ucWords(display) +
                  ' : ' +
                  markers[index][display] +
                  ' <br> ';
              } else {
                displayText +=
                  this.common.ucWords(display) +
                  ' : ' +
                  markers[index][display];
              }
            });
          } else {
            displayText =
              this.common.ucWords(infoKeys) + ' : ' + markers[index][infoKeys];
          }
          google.maps.event.addListener(marker, 'click', function (evt) {
            this.infoStart = new Date().getTime();
            for (
              let infoIndex = 0;
              infoIndex < infoWindows.length;
              infoIndex++
            ) {
              const element = infoWindows[infoIndex];
              if (element) element.close();
            }
            infoWindow.setContent(
              "<span style='color:blue'>Info</span> <br> " + displayText
            );
            infoWindow.setPosition(evt.latLng); // or evt.latLng
            infoWindow.open(this.map);
            afterClick(markers[index]);
          });

          if (infoOnMouse) {
            // start:showInfowindow on mouserover by sunil-26-02-2020
            google.maps.event.addListener(marker, 'mouseover', function (evt) {
              this.infoStart = new Date().getTime();
              for (
                let infoIndex = 0;
                infoIndex < infoWindows.length;
                infoIndex++
              ) {
                const element = infoWindows[infoIndex];
                if (element) element.close();
              }
              infoWindow.setContent(
                "<span style='color:blue'>Info</span> <br> " + displayText
              );
              infoWindow.setPosition(evt.latLng); // or evt.latLng
              infoWindow.open(this.map);
            });
            google.maps.event.addListener(marker, 'mouseout', function (evt) {
              // this.infoStart = new Date().getTime();
              infoWindow.close();
              infoWindow.opened = false;
            });
          }
        }
        if (changeBounds) this.setBounds(latlng);
      }
      this.markers.push(marker);
      thisMarkers.push(marker);

      //  marker.addListener('mouseover', this.infoWindow.bind(this, marker, show ));

      //  marker.addListener('click', fillSite.bind(this,item.lat,item.long,item.name,item.id,item.city,item.time,item.type,item.type_id));
      //  marker.addListener('mouseover', showInfoWindow.bind(this, marker, show ));
    }
    return thisMarkers;
  }

  getMap(){
    return this.map;
  }



  createCirclesOnPosition(center, radius, stokecolor = '#FF0000', fillcolor = '#FF0000', fillOpacity = 0.1, strokeOpacity = 1,draggable = false,editable = false) {
    this.circle = new google.maps.Circle({
      strokeColor: stokecolor,
      strokeOpacity: strokeOpacity,
      strokeWeight: 2,
      fillColor: fillcolor,
      fillOpacity: fillOpacity,
      map: this.map,
      center: center,
      radius: radius,
      clickable: editable,
      draggable: draggable,
      zIndex: 0,
      editable: editable,
    });
    this.circles.push(this.circle);
    return this.circle;
  }

  resetCircle() {
    this.circle.setMap(null);
  }

  resetCircles() {
    try {
      for (let i = 0; i < this.circles.length; i++) {
        if (this.circles[i]) this.circles[i].setMap(null);
      }
      this.circles = [];
    } catch (e) {
      console.error('Exception in resetCircles:', e);
    }
  }

  callingToggleBounce(geofenceId: number, type = 1) {
    const index = this.markers.findIndex((marker) => marker.id === geofenceId);
    console.log('index', index);
    this.toggleBounceMF(index, type);
  }

  toggleBounceMF(id, evtype = 1) {
    console.log('markers', this.markers, id);
    if (this.markers[id]) {
      if (this.markers[id].getAnimation() == null && evtype == 1) {
        this.markers[id].setAnimation(google.maps.Animation.BOUNCE);
      } else if (evtype == 2 && this.markers[id].getAnimation() != null) {
        this.markers[id].setAnimation(null);
      }
    }
  }

  createPoint(x, y) {
    return new google.maps.Point(x, y);
  }

  clearAll(
    reset = true,
    boundsReset = true,
    resetParams = { marker: true, polygons: true, polypath: true }
  ) {
    resetParams.marker && this.resetMarker(reset, boundsReset);
    resetParams.polygons && this.resetPolygons();
    resetParams.polypath && this.resetPolyPath();
    this.options ? this.options.polypaths && this.resetPolyPaths() : '';
    this.options ? this.options.clearHeat && this.resetHeatMap() : '';
  }
  resetHeatMap() {
    if (this.heatmap) {
      this.heatmap.setMap(null);
      this.heatmap = null;
    }
  }

  resetMarker(reset = true, boundsReset = true, markers?) {
    let actualMarker = markers || this.markers;
    for (let i = 0; i < actualMarker.length; i++) {
      if (actualMarker[i]) actualMarker[i].setMap(null);
    }
    if (reset) actualMarker = [];
    if (boundsReset) {
      this.bounds = new google.maps.LatLngBounds();
    }
  }

  resetBounds() {
    this.bounds = new google.maps.LatLngBounds();
    for (let index = 0; index < this.markers.length; index++) {
      if (this.markers[index]) {
        let pos = this.markers[index].position;
        if (pos?.lat() != 0 && this.markers[index]?.getMap())
          this.setBounds(pos);
      }
    }
  }
  resetPolygons() {
    if (this.polygon) {
      this.polygon.setMap(null);
      this.polygon = null;
    }
    if (this.polygons.length > 0) {
      this.polygons.forEach((polygon) => {
        polygon.setMap(null);
      });
      this.polygons = [];
    }
  }
  resetPolyPaths() {
    if (this.polygonPaths.length > 0) {
      this.polygonPaths.forEach((path) => {
        path.setMap(null);
      });
      this.polygonPaths = [];
    }
    if (this.polygonPathsVertices.length > 0) {
      this.polygonPathsVertices.forEach((polyPathVertices) => {
        if (polyPathVertices.length > 0) {
          polyPathVertices.forEach((polyPathVertix) => {
            polyPathVertix.setMap(null);
          });
        }
      });
      this.polygonPathsVertices = [[]];
    }
  }
  resetPolyPath() {
    if (this.polygonPath) {
      this.polygonPath.setMap(null);
      this.polygonPath = null;
    }
    if (this.polygonPathVertices.length > 0) {
      this.polygonPathVertices.forEach((polyPathVertix) => {
        polyPathVertix.setMap(null);
      });
      this.polygonPathVertices = [];
    }
  }

  createPolygonPath(polygonOptions?, drawVertix?) {
    google.maps.event.addListener(this.map, 'click', (event) => {
      if (this.isDrawPolyGon) {
        this.createPolyPathManual(event.latLng, polygonOptions, drawVertix);
      }
    });
  }
  createPolyPathManual(latLng, polygonOptions?, drawVertix?) {
    if (!this.polygonPath) {
      const defaultPolygonOptions = {
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 3,
        zIndex: 2,
        icons: [
          {
            icon: this.lineSymbol,
            offset: '100%',
          },
        ],
      };
      this.polygonPath = new google.maps.Polyline(
        polygonOptions || defaultPolygonOptions
      );
      this.polygonPath.setMap(this.map);
    }
    let path = this.polygonPath.getPath();
    path.push(latLng);
    drawVertix &&
      this.polygonPathVertices.push(this.createSingleMarker(latLng));
    return this.polygonPath;
  }
  createPolyPathDetached(latLng, polygonOptions?, drawVertix?) {
    if (!this.poly) {
      const defaultPolygonOptions = {
        strokeColor: '#FF0000',
        strokeOpacity: 1,
        strokeWeight: 3,
        icons: [
          {
            icon: this.lineSymbol,
            offset: '100%',
          },
        ],
      };
      this.poly = new google.maps.Polyline(
        polygonOptions || defaultPolygonOptions
      );
      this.poly.setMap(this.map);
    }
    let path = this.poly.getPath();
    path.push(this.createLatLng(latLng.lat, latLng.lng));
    drawVertix && this.polyVertices.push(this.createSingleMarker(latLng));
    return this.poly;
  }
  undoPolyPath(polyLine?) {
    let path = polyLine ? polyLine.getPath() : this.polygonPath.getPath();
    path.pop();
  }

  createPolyPathsManual(latLngsAll, afterClick?, drawVertix?) {
    latLngsAll.forEach((latLngAll) => {
      //console.log("hereout");
      this.poly = null;
      this.polyVertices = [];
      latLngAll.latLngs.forEach((latLng) => {
        //console.log("herein");
        this.createPolyPathDetached(latLng);
      });
      this.polygonPaths.push(this.poly);
      drawVertix && this.polygonPathsVertices.push(this.polyVertices);
      this.addListerner(this.poly, 'click', function (event) {
        afterClick(latLngAll, event);
      });
      //console.log(this.polygonPaths);
    });
  }

  setMapType(typeIndex) {
    let types = ['roadmap', 'satellite', 'hybrid', 'terrain'];
    this.map.setMapTypeId(types[typeIndex]);
  }

  getArrayBounds(polyArray) {
    var bounds = this.map.LatLngBounds();
    let path, paths;
    for (let polys = 0; polys < polyArray.length; polys++) {
      paths = polyArray[polys].getPaths();
      for (var i = 0; i < paths.getLength(); i++) {
        path = paths.getAt(i);
        for (var ii = 0; ii < path.getLength(); ii++) {
          bounds.extend(path.getAt(ii));
        }
      }
    }
    return bounds;
  }

  setBounds(latLng, reset = false) {
    if (!this.bounds) this.bounds = this.map.getBounds();
    this.bounds.extend(latLng);
    this.map.fitBounds(this.bounds);
  }
  getMapBounds() {
    if (this.map) {
      let boundsx = this.map.getBounds();
      let ne = boundsx.getNorthEast(); // LatLng of the north-east corner
      let sw = boundsx.getSouthWest(); // LatLng of the south-west corder
      let lat2 = ne.lat();
      let lat1 = sw.lat();
      let lng2 = ne.lng();
      let lng1 = sw.lng();
      return { lat1: lat1, lat2: lat2, lng1: lng1, lng2: lng2 };
    }
  }
  drawPolyMF(to) {
    if (!this.polygonPath) {
      this.polygonPath = new google.maps.Polyline({
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 2,
        icons: [
          {
            icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
            offset: '100%',
            repeat: '20px',
          },
        ],
      });
      this.polygonPath.setMap(this.map);
    }
    var path = this.polygonPath.getPath();
    path.push(to);
  }
  createHeatMap(points, changeBounds = true) {
    var googlePoints = [];
    for (const point in points) {
      if (points.hasOwnProperty(point)) {
        const thisPoint = points[point];
        var latLng = this.getLatLngValue(thisPoint);
        var googleLatLng = this.createLatLng(latLng.lat, latLng.lng);
        googlePoints.push(googleLatLng);
        if (changeBounds) this.setBounds(googleLatLng);
      }
    }
    if (googlePoints.length != 0) {
      this.heatmap = new google.maps.visualization.HeatmapLayer({
        data: googlePoints,
        map: this.map,
      });
    }
  }

  distanceBtTwoPoint(startLat, startLong, endLat, endLong) {
    return new Promise((resolve, reject) => {
      let origin = new google.maps.LatLng(startLat, startLong);
      let destination = new google.maps.LatLng(endLat, endLong);
      let service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: 'DRIVING',
          avoidHighways: false,
          avoidTolls: false,
        },
        (response, status) => {
          //console.log("response:", response);

          if (status != google.maps.DistanceMatrixStatus.OK) {
            reject(-1);
          } else {
            resolve(response.rows[0].elements[0].distance.value);
          }
        }
      );
    });
  }

  createMarkerCluster(markers, isShow: boolean, infoKeys) {
    let marker = [];
    for (let index = 0; index < markers.length; index++) {
      let displayText = '';
      let latLng = this.getLatLngValue(markers[index]);
      // let markerT = new google.maps.marker(latLng);
      if (typeof infoKeys == 'object') {
        infoKeys.map((display, indexx) => {
          if (indexx != infoKeys.length - 1) {
            displayText +=
              this.common.ucWords(display) +
              ' : ' +
              markers[index][display] +
              ' <br> ';
          } else {
            displayText +=
              this.common.ucWords(display) + ' : ' + markers[index][display];
          }
        });
      } else {
        displayText =
          this.common.ucWords(infoKeys) + ' : ' + markers[index][infoKeys];
      }

      var icon = {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 6,
        fillColor: '#FFFF00',
        fillOpacity: 0.8,
        strokeWeight: 1,
      };

      let markert = new google.maps.Marker({
        position: latLng,
        title: displayText,
        // flat: true,
        icon: icon,
      });
      marker.push(markert);
    }
    if (this.cluster) this.cluster.clearMarkers();
    // if (!isShow) {
    //   this.markers.map(marker => marker.marker.setMap(this.mapService.map));
    //   return;
    // }
    let options = {
      gridSize: 40,
      maxZoom: 18,
      zoomOnClick: false,
      minimumClusterSize: 2,
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    };

    this.cluster = new MarkerClusterer(this.map, marker, options);
    google.maps.event.addListener(this.cluster, 'clusterclick', (cluster) => {
      //console.log("cluster:", cluster.getMarkers());
      let content =
        '<div style="color:#000">' +
        cluster
          .getMarkers()
          .map((maker, index) => `${index + 1}. ${maker.title}`)
          .join('&nbsp;&nbsp;') +
        '</div>';
      //console.log('content:', content);
      if (this.map.getZoom() <= this.cluster.getMaxZoom()) {
        if (!this.infoWindow)
          this.infoWindow = new google.maps.InfoWindow({ content });

        this.infoWindow.setContent(content);
        this.infoWindow.setPosition(cluster.getCenter());
        this.infoWindow.open(this.map, '');
      }
    });
  }

  async getAddressByLatLng(latLng) {
    //by skk
    let geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: latLng }, (results) => {
        let tempAddress = null;
        if (results[0]) {
          let place = results[0];
          tempAddress = place.formatted_address.split(',');
          tempAddress.pop();
          tempAddress.pop();
          tempAddress = tempAddress.join(',');
          resolve(tempAddress);
        } else {
          reject(null);
        }
      });
    });
  }

  afterDragLat = null;
  afterDragLng = null;
  createDraggableMarker(
    lat = 26.9124336,
    lng = 75.78727090000007,
    distanceLimit = null
  ) {
    //console.log("lat=", lat, "long", lng)
    let marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: new google.maps.LatLng(lat, lng),
      draggable: true,
      scale: 1,
      strokeWeight: 1,
      icon:
        'http://chart.apis.google.com/chart?chst=d_map_xpin_letter&chld=pin|' +
        ' ' +
        '|' +
        'ff0000' +
        '|000000',
    });
    this.setBounds(new google.maps.LatLng(lat, lng));
    this.zoomMap(16);
    google.maps.event.addListener(marker, 'dragend', (evt) => {
      if (distanceLimit) {
        let dist = this.common.distanceFromAToB(
          lat,
          lng,
          evt.latLng.lat(),
          evt.latLng.lng(),
          'Mt'
        );
        if (dist > distanceLimit) {
          var position = marker.getPosition();
          alert(
            'You can move marker here distance is more than' + distanceLimit
          );
          let newpos = new google.maps.LatLng(lat, lng);
          marker.setPosition(newpos);
        } else {
          this.afterDragLat = evt.latLng.lat();
          this.afterDragLng = evt.latLng.lng();
        }
      } else {
        this.afterDragLat = evt.latLng.lat();
        this.afterDragLng = evt.latLng.lng();
      }

      //console.log(evt.latLng, this.afterDragLat, this.afterDragLng);
    });
  }
  changeColor(index, evtype = 1) {
    //console.log("markers======", this.markers);
    if (evtype == 1) {
      this.markers[index]['oldIcon'] = this.markers[index].icon;
      this.markers[index].setIcon(
        'http://chart.apis.google.com/chart?chst=d_map_xpin_letter&chld=pin|' +
          (index + 1) +
          '|000000|0088ff'
      );
    } else {
      this.markers[index].setIcon(this.markers[index]['oldIcon']);
    }
  }

  polylines = [];

  createPolyLines(latLngsMulti, options?) {
    // strokeColor = '#', fillColor = '#') {
    let index = 0;

    latLngsMulti.forEach((latLngs, i) => {
      const defaultOptions = {
        path: latLngs.data,
        strokeColor: latLngs.color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        clickable: !latLngs.isMain,
        fillColor: latLngs.color,
        fillOpacity: 0.35,
      };
      let polyline = new google.maps.Polyline(options || defaultOptions);
      this.polylines.push(polyline);
      polyline.setMap(this.map);

      index++;
    });
    return this.polygons;
  }

  resetPolyLines() {
    this.polylines.map((ployline) => {
      ployline.setMap(null);
    });
    this.polylines = [];
  }

  createPolyline(coordinates, options?) {
    const defaultOptions = {
      strokeColor: 'red',
      strokeOpacity: 1,
      strokeWeight: 3,
    };

    const polyline = new google.maps.Polyline({
      path: coordinates,
      ...(options || defaultOptions),
    });
    polyline.setMap(this.map);
    return polyline;
  }

  clearMarkerEvents(marker) {
    try {
      google.maps.event.clearListeners(marker, 'click');
    } catch (e) {}
  }

  createMarker(latlng, options?: any) {
    const defaultOptions = {};
    const marker = new google.maps.Marker({
      position: latlng,
      ...(options || defaultOptions),
    });

    marker.setMap(this.map);
    this.customMarkers.push(marker);
    return marker;
  }

  createCluster(markers, ismake?) {
    let infoWindows = [];

    if (ismake) {
      this.cluster = new MarkerClusterer(
        this.map,
        markers.filter(
          (marker) =>
            marker &&
            marker.position &&
            marker.position.lat() &&
            marker.position.lng()
        ),
        {
          gridSize: 40,
          maxZoom: 16,
          zoomOnClick: false,
          minimumClusterSize: 2,
          imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        }
      );
      let infoWindow = this.createInfoWindow();
      infoWindows.push(infoWindow);
      infoWindow.opened = false;
      google.maps.event.addListener(this.cluster, 'clusterclick', (cluster) => {
        let infoStr = '';
        cluster.markers_.map((mrk) => {
          infoStr += mrk.title + ', ';
        });
        this.infoStart = new Date().getTime();
        for (let infoIndex = 0; infoIndex < infoWindows.length; infoIndex++) {
          const element = infoWindows[infoIndex];
          if (element) element.close();
        }
        infoWindow.setContent(infoStr);
        infoWindow.setPosition(cluster.center_); // or evt.latLng
        infoWindow.open(this.map);
      });
    } else {
      if (this.cluster) this.cluster.clearMarkers();
      markers.map((marker) => marker && marker.setMap(this.map));
    }
    return this.cluster;
  }

  addCustomLabelMarkers(
    location: any,
    label: any,
    id = -1,
    type = 0,
    ref_id: any,
    ref_data: any = [],
    labelName?: string
  ) {
    // console.log(label,id,type)
    let markerClass = {
      1: 'plant-custom-label custom-label fa',
      2: 'stage-label custom-label fa',
      3: 'destination-label custom-label fa',
      4: 'zone-label custom-label fa',
      5: 'pzone-label custom-label fa',
      6: 'hardware-label custom-label fa',
    };

    const marker = new google.maps.Marker({
      ref_data: ref_data,
      position: location,
      opacity: 0.85,
      // width: 64,
      // height: 64,
      type: type,
      clickable: true,
      id: id,
      optimized: true,
      // background: '#523625',
      className: 'marker-tooltip',
      animation: google.maps.Animation.DROP,
      icon: 'assets/images/transparent.png',
      // size: new google.maps.Size(64, 64),
      zIndex: 1,
      // scaledSize: new google.maps.Size(64,64),
      label: {
        text: label.toString(),
        className: markerClass[type],
        // backgroundColor: 'green',
        // color: '#fff'
      },
      // labelName: labelName ? labelName.toString() : null,
    });

    marker.setMap(this.map);
    this.markers.push(marker);
    return marker;
  }

  addLabelMarkers(
    location: any,
    label: any,
    id = -1,
    type = 0,
    ref_id: any,
    ref_data: any = [],
    labelName?: string
  ) {
    // console.log(label,id,type)
    let markerImages = {
      1: 'assets/images/plant.svg',
      2: 'assets/images/stage.svg',
      3: 'assets/images/destination.svg',
      4: 'assets/images/zone.svg',
      5: 'assets/images/pzone_geofence.svg',
      6: 'assets/images/hardware_geofence.svg',
      // 7: "assets/images/image.svg",
      // 6:"assets/images/zone.svg",
    };

    const marker = new google.maps.Marker({
      ref_data: ref_data,
      position: location,
      opacity: 0.85,
      width: 64,
      height: 64,
      type: type,
      clickable: true,
      id: id,
      optimized: true,
      background: '#523625',
      className: 'marker-tooltip',
      animation: google.maps.Animation.DROP,
      icon: [1, 2, 3, 4, 5, 6, 7].includes(type) ? markerImages[type] : null,
      size: new google.maps.Size(64, 64),
      zIndex: 1,
      // scaledSize: new google.maps.Size(64,64),
      label: {
        text: label.toString(),
        className: type == 1 ? 'plant-label' : 'marker-label',
        backgroundColor: 'green',
        color: '#fff',
      },
      labelName: labelName ? labelName.toString() : null,
    });

    marker.setMap(this.map);
    this.markers.push(marker);
    return marker;
  }

  placeSingleMarker(position, draggable = true, title = 'Drag marker') {
    this.resetMarker();
    const marker = new google.maps.Marker({
      position: position,
      map: this.map,
      draggable: draggable,
      title: title,
      zIndex: 99999999,
    });
    this.markers.push(marker);
    this.marker = marker;
    // this.map.panTo(position);
  }

  returnMarker() {
    return this.marker;
  }

  centerAtRadius(center, radius) {
    let bounds = new google.maps.LatLngBounds();

    let east = google.maps.geometry.spherical.computeOffset(center, radius, 90);
    let west = google.maps.geometry.spherical.computeOffset(
      center,
      radius,
      -90
    );
    let north = google.maps.geometry.spherical.computeOffset(center, radius, 0);
    let south = google.maps.geometry.spherical.computeOffset(
      center,
      radius,
      180
    );

    bounds.extend(east);
    bounds.extend(west);
    bounds.extend(north);
    bounds.extend(south);

    this.map.fitBounds(bounds);
    this.map.setCenter(bounds.getCenter());
  }

  resetlabals() {
    if (this.markers.length > 0) {
      this.markers.forEach((marker) => {
        marker.setMap(null);
      });
      this.markers = [];
    }
  }

  setMapOnAll(map = null) {
    this.markers.forEach((marker) => marker.setMap(map));
  }

  getLatLongsfromRadius(center, radius, numPoints = 4) {
    let latLngs = [];
    for (let i = 0; i < numPoints; i++) {
      let angle = (i * 2 * Math.PI) / numPoints;
      let offset = google.maps.geometry.spherical.computeOffset(
        center,
        radius,
        angle
      );
      let lat = offset.lat();
      let lng = offset.lng();
      latLngs.push({ lat: lat, lng: lng });
    }
    return latLngs;
  }
}

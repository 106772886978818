<div *ngIf="isCameraExist; else noCameraExist">
  <div class="camera-container">
    <webcam [height]="200" [width]="400" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
      *ngIf="showWebcam" [switchCamera]="nextWebcamObservable"></webcam>
    <div class="btn-group" *ngIf="showWebcam">
      <button class="btn btn-secondary" (click)="takeSnapshot()"><i class="fa fa-camera"></i> Take Picture</button>
    </div> <br />
  </div>

  <div *ngIf="errors.length > 0">
    <h4>Error Messages:</h4>
    <ul *ngFor="let error of errors">
      <li>{{ error | json }}</li>
    </ul>
  </div>
</div>

<ng-template #noCameraExist>
  Camera device not available
</ng-template>

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { VehicleDetailComponent } from 'src/app/components/vehicle-detail/vehicle-detail.component';
import { DriverTrackerComponent } from 'src/app/components/driver-tracker/driver-tracker.component';
@Component({
  selector: 'app-process-vehicle-view',
  templateUrl: './process-vehicle-view.component.html',
  styleUrls: ['./process-vehicle-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessVehicleViewComponent implements OnInit {
  allProcesses: any[]=[];
  currentProcess:any;
  stageId:any = null;
  processId:any = "";
  destinationId:any = -1;
  plantView:number = 1;
  @Input() public refData;
  expected_tat:string = "";

  constructor(private api: ApiService,
    public modalService: NgbModal,
    private common: CommonService,
    private changeDetector : ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
      this.getRunningWorkflow();
      this.expected_tat = this.refData.expectedTat;
      this.cdr.detectChanges();
  }

  getRunningWorkflow() {
    this.common.loading = true;
    let apiUrl = 'plant/get-process-stage-vehicle';
    let params = {
      processId: this.refData.processId,
      stageId : this.refData.stageId,
      destinationId: this.refData.destinationId,
      date:this.refData.date,
      prevStageId:this.refData.prevStageId,
      showAutoCancelled:this.refData.showAutoCancelled,
      showInactive: this.refData.showInactive,
      statusList: this.refData.statusList,
      vehicleIds : this.refData.vehicleIds,
    }
    this.api.post(apiUrl,params).subscribe((res: any) => {
      this.common.loading = false;
      this.allProcesses = res.data;
      console.log('this.allProcesses: ', this.allProcesses);
    }, err => {
      this.common.loading = false;
      console.error(err);
    })
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  ngAfterViewInit() {
    this.expected_tat = this.refData.expectedTat;
  }

  tripModal(vehicle) {
    let processName=this.refData.processName;
    let activeModal = this.modalService.open(VehicleDetailComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
    activeModal.componentInstance.refData = {
    showHeader: false,
    txnId: vehicle.transaction_id,
    processName:processName
  };
}

tripTrackingModal(vehicle) {
  let processName=this.refData.processName;
  let activeModal = this.modalService.open(DriverTrackerComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
  activeModal.componentInstance.refData = {
    showHeader: false,
    txnId: vehicle.transaction_id,
    vehicleName:vehicle.vehicle_rc_no+'-'+ processName
  };
}

}

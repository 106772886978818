import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from './../../@core/services/api.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/@core/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TableService } from 'src/app/@core/services/table.service';
import { ChecklistHistoryDataComponent } from 'src/app/modals/checklist-history-data/checklist-history-data.component';
import { environment } from 'src/environments/environment';
import { ViewRemarkComponent } from 'src/app/modals/view-remark/view-remark.component';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ViewChecklistComponent } from '../view-checklist/view-checklist.component';
import { Checklist } from 'src/app/dataType/checklistInterface';

@Component({
  selector: 'app-checklist-history',
  templateUrl: './checklist-history.component.html',
  styleUrls: ['./checklist-history.component.scss'],
})
export class ChecklistHistoryComponent implements OnInit {
  @Input() report: boolean = true;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 10, 'TRIP REPORT LIST');
  tempActivePendingChecklists: any[] = [];

  startDate = new Date(new Date().setDate(new Date().getDate() - 7));
  endDate = new Date();
  history: any[] = [];
  dayWiseCountList: number[] = [];
  statusWiseCountList = {
    "0Pending": 0,
    "1Approved": 0,
    "2Rejected": 0,
    "3before": 0,
    "4after": 0,
    "5in": 0,
    "6All": 0
  };

  plantData: any = [];
  plantId: number = null;
  checkListData: Checklist[] = [];
  selecteduser: number = -1;
  selecteduserName: string = '';
  selectedCheckList: number = -1;
  selectedcheckListName: string = '';
  title = '';
  userList: any[] = [];
  selectedStatus: string = 'All';
  selectedAssignToUser = 'All';
  selectedcheckListName1 = 'All';

  constructor(
    private breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public userService: UserService,
    public api: ApiService,
    private modalService: NgbModal,
    public table: TableService,
    private plantService: PlantService,
    public role: RoleService
  ) {

  }

  ngOnInit(): void {
    this.getCheckListData();
    this.getUserList();
    this.report ? this.title = 'CHECKLIST REPORT' : this.title = 'CHECKLIST HISTORY'
    if (this.report == true) {
      let breadcumbs = [
        { name: 'Home', url: '/user/dashboard' },
        { name: 'Reports', url: null },
        { name: 'Checklist Report', link: '/user/app-checklist-history' },
      ];
      this.breadcrumbService.getBreadcrumbs(breadcumbs);
    } else {
      this.breadcrumbService.setActiveBreadCrumb(false);
    }
    if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      // for checklist history count
      this.checklistHistory();
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  viewRemark(item: any) {
    this.common.params = {
      log_id: item.log_id,
      checklist_title: item.checklist_title,
      remarkType: 1,
    };
    this.modalService.open(ViewRemarkComponent, { size: 'xl' });
  }

  viewRejectedRemark(logId: number, checklist_title: string) {
    this.common.params = {
      log_id: logId,
      checklist_title: checklist_title,
      rejectedFlag: true,
    };
    this.modalService.open(ViewRemarkComponent, { size: 'xl' });
  }

  selectedUser(event: any) {
    this.selecteduser = event.userId;
    this.selecteduserName = event.userName;
    this.checklistHistory();
  }

  selectCheckList(event: any) {
    this.selectedCheckList = event.id;
    this.selectedcheckListName = event.checklist_title;
    this.checklistHistory();
  }

  getdate(): Date {
    let date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  }


  onReset() {
    this.selecteduser = -1;
    this.selecteduserName = '';
    this.selectedCheckList = -1;
    this.selectedcheckListName = '';
    this.selectedStatus = 'All';
    this.selectedAssignToUser = 'All'
    this.selectedcheckListName1 = 'All'
    this.endDate = new Date();
    this.startDate = this.getdate();
    this.checklistHistory();
  }

  getCheckListData() {
    this.common.loading = true;
    let params = {
      checklistActive: true
    }
    this.api.get('checklist/get-all-checklists', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.checkListData = res.data;
          !this.report ? this.checkListData.unshift({
            id: 0, checklist_title: 'All',
            checklist_description: '',
            checklist_type: 0,
            data: [],
            end_date: '',
            interval_count: 0,
            interval_type: '',
            plant_id: 0,
            plant_name: '',
            start_date: '',
            status: '',
            tolerance: 0
          }) : true;

        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getUserList() {
    this.api.get('user/all_user').subscribe(
      (res: any) => {
        this.userList = res.data;
        !this.report ? this.userList.unshift({ userId: 0, userName: 'All' }) : true;
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }


  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.plantData = res.data;
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.checklistHistory();
  }

  checklistHistory() {
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMdd',
      false,
      '-'
    );
    this.endDate = this.common.dateFormatter(
      this.endDate,
      'YYYYMMdd',
      false,
      '-'
    );
    this.common.loading = true;
    let url;
    let params = {
      startDate: this.startDate,
      endDate: this.endDate,
      plantId: this.plantId,
      userId: this.selecteduser,
      status: this.selectedStatus,
      checklistId: this.selectedCheckList
    };
    if (this.report == false) {
      url = 'checklist-dashboard/checklist-history';
    } else {
      url = 'checklist-dashboard/checklist-history-counts';
    }
    this.api.get(url, params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.history = res.data;
        if (this.report == true) {
          this.dayWiseCount();

          this.history.forEach((item) => {
            this.statusWiseCountList['0Pending'] += item.pending_count;
            this.statusWiseCountList['1Approved'] += item.approved_count;
            this.statusWiseCountList['2Rejected'] += item.rejected_count;
            this.statusWiseCountList['3before'] += item.before_tolerance;
            this.statusWiseCountList['4after'] += item.after_tolerance;
            this.statusWiseCountList['5in'] += item.in_tolerance;
            this.statusWiseCountList['6All'] += item.total;
          });

          console.log(this.statusWiseCountList, 'thsi')
        }
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  dayWiseCount() {
    this.dayWiseCountList = [];
    let sum = 0;
    this.history.forEach((e) => {
      sum = sum + e.pending_count + e.approved_count + e.rejected_count;
      this.dayWiseCountList.push(sum);
      sum = 0;
    });
  }

  readCountData(startDate: Date, endDate: Date, flag: any) {
    this.common.params = {
      startDate: startDate,
      endDate: endDate,
      flag: flag,
      type: 'normal',
      userId: this.selecteduser,
      checklistId: this.selectedCheckList
    };
    const activeModal = this.modalService.open(ChecklistHistoryDataComponent, {
      size: 'xl',
      windowClass: 'custom-modal-width'
    });
  }


  viewCheckList(item: any) {
    this.common.params = item.log_id;
    const activeModal = this.modalService.open(ViewChecklistComponent, {
      size: 'xl',
    });
  }
}

<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{"vehicle_status_change" | customTranslate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="vehicle_form_box">
    <ng-container class="col-md-6 modal_col">

      <div class="field" *ngIf="user._loggedInUser.plantId==0">
        <label for="">{{"select_plant" | customTranslate}}</label>
        <ps-auto-suggestion (onSelected)="selectPlant($event);" [data]="plantData"
          display="plantName" placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
        </ps-auto-suggestion>
      </div>

      <div class="field">
        <label>{{"select_vehicle" | customTranslate}}:</label>
        <ps-auto-suggestion (onSelected)="selectedVehicle($event)" [data]="data" display="vehicle_name"
          placeholder="Choose Vehicle" [preSelected]="{vehicle_name:vehicle_name}">
        </ps-auto-suggestion>
      </div>

      <div class="field">
        <label for="sel1" class="required">{{"vehicle_status" | customTranslate}}:</label>
        <select class="form-control" id="sel1" name="status" [(ngModel)]="vehicle_status">
          <option *ngFor="let opt of allStatus" [selected]="opt['value']==vehicle_status">{{opt['label']}}</option>
        </select>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" outline (click)="changeStatus()" id="submit"
    style="cursor: pointer;">{{'save'|customTranslate}}</button>
  <button class="btn btn-danger" type="button" outline (click)="closeModal()" id="cancel"
    style="cursor: pointer;">{{'Cancel'|customTranslate}}</button>
</div>

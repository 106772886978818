import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public dataSubject = new BehaviorSubject<any>(null);
  public vehicleSubject = new BehaviorSubject<any>(null);
  constructor() { }

  updateData(data: any) {
    this.dataSubject.next(data);
  }

  updateVehicleData(data: any) {
    this.vehicleSubject.next(data);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class StageDestinationCodeService extends ApiService{
  push(stage_destination_code_id: any) {
    throw new Error('Method not implemented.');
  }

  saveStageDestinationCode(data: any): Observable<any> {
    let apiSubUrl = "destination/save_stage_destination_code";
    return super.post(apiSubUrl, data);
  }

  getActiveAndInactiveStageDestinationCode(): Observable<any> {
    let apiSubUrl = "destination/list_stage_destination_code?allStatus=true";
    return super.get(apiSubUrl);
  }


  getAllProcess(): Observable<any> {
    let apiSubUrl = "process/get-process-stage";
    return super.get(apiSubUrl);
  }

  deleteStageDestinationCode(data: any): Observable<any> {
    let apiSubUrl =  "destination/delete_stage_destination_code";

    return super.post(apiSubUrl, data);
  }



}

import { dA } from '@fullcalendar/core/internal-common';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { Router } from '@angular/router';
import { ActionInfoModalComponent } from '../../modals/action-info-modal/action-info-modal.component';
import { AuditModalComponent } from 'src/app/modals/audit-modal/audit-modal.component';
import { ViewRemarkComponent } from 'src/app/modals/view-remark/view-remark.component';
import { PlantService } from '../plant/plant.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { ActionFormComponent } from 'src/app/modals/action-form/action-form.component';
import { ManualStampingFormComponent } from '../manual-stamping-form/manual-stamping-form.component';
import { UserService } from 'src/app/@core/services/user.service';
import { TripCommentTrailComponent } from 'src/app/modals/trip-comment-trail/trip-comment-trail.component';
import { TripTrackingLogsComponent } from 'src/app/modals/trip-tracking-logs/trip-tracking-logs.component';

@Component({
  selector: 'app-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.scss'],
})
export class VehicleDetailComponent implements OnInit {
  txnType:string='Trip';
  closeResult = '';
  transactionVehicles: any = [];
  vehicleId: any = null;
  vehicleRCNo: any = null;
  processId: any = null;
  tripDetails: any = [];
  processName: any = null;
  userDetails: any = [];
  overRidingPermissions: any = [];
  showHeader: boolean = true;
  userId = null;
  actionName: any = null;
  actionTypeData: any = null;
  actionData: any = [];
  currentActionData: any[];
  preActionsData: any = [];
  hideShow1: any = [];
  vehicleNo: any = null;
  @Input() public refData;
  @Input() public isVisible1;
  isPublic: boolean = false;
  txnId: any = null;
  outVehicleFlowType: any = null;
  isVisible: any = 'true';
  driverName: string = '';
  isModal: boolean = false;
  tempTxnList: any[] = [];
  plantList: any[] = [];
  selectedPlant: any;
  plantId:number=null;
  showTrail:boolean= false;
  visitorInfo={
    purpose:null,
    meetWith:null,
    visitorName:null
  }
  tabData: any = [
    { tabname: 'Current Trip', value: 'Current Trip' },
    { tabname: 'Previous Trip', value: 'Previous Trip' },
  ];
  tripType:any='Current Trip';

  constructor(
    private api: ApiService,
    public modalService: NgbModal,
    public alertService: AlertService,
    private router: Router,
    public common: CommonService,
    private activeModal: NgbActiveModal,
    public breadcrumbService: BreadcrumbService,
    public plantService: PlantService,
    public role: RoleService,
    public userService : UserService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Trips', url: null },
      { name: 'Trip Detail', url: '/user/vehicle-detail' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.userDetails = JSON.parse(localStorage.getItem('USER_DETAILS'));
    this.common.swiftTripId.subscribe((res) => {
      if (res != 'Basic Approval is required!') {
        this.txnId = res;
        this.isPublic = true;
      }
    });
    this.common.actionVisible.subscribe((res) => {
      if (res != 'Post & Require Action Visibility') {
        this.isVisible = res;
      }
    });
    // }
    if (!this.isPublic) {
      this.userId = this.userDetails.id;
      breadcrumbService.setActiveBreadCrumb(true);
      if (
        this.router.getCurrentNavigation() != null &&
        this.router.getCurrentNavigation().extras != null
      ) {
        let data = this.router.getCurrentNavigation().extras.state;
        this.txnId = data ? data.txnId : null;
      }
    }
  }

  ngOnInit() {
    if (!this.isPublic) {
      if (this.refData != null) {
        this.txnId = this.refData.txnId;
        this.showHeader = this.refData.showHeader;
        this.processName = this.refData.processName;
        this.isModal = this.refData.isModal;
        this.processId = this.refData.processId;
        this.refData.txnType? this.txnType=this.refData.txnType:null;
        if(this.txnType=='Visit'){
          this.visitorInfo.meetWith=this.refData.meetWith;
          this.visitorInfo.purpose=this.refData.purpose;
          this.visitorInfo.visitorName=this.refData.visitorName;
          this.tabData = [
            { tabname: 'Current Visit', value: 'Current Visit' },
            { tabname: 'Previous Visit', value: 'Previous Visit' },
          ];
        }
        this.tripType = 'Current Visit'
        console.log("refdataaa",this.refData,this.txnType)
        this.getOverridingPermissions();
      }
      if(!this.showHeader){
        this.breadcrumbService.setActiveBreadCrumb(false)
      }
      if (this.showHeader && this.txnId == null) {
        this.role.user_role == 'COMPANYADMIN'
        ? this.getPlantById()
        : this.getVehicleTransaction();
      }
      if (this.txnId != null) {
        this.tripDetail();
      }
    } else {
      this.tripDetail();
    }
  }

  getPreviousTripReport(){
    this.common.loading = true;
    this.api.get('trip/get-vehicle-trip-detail?txnId=' + this.txnId+'&oldTrip=true').subscribe(
      (res: any) => {
        if (res.status) {
          this.tripDetails = res.data;
          if(res.data.length){
          this.processName = res.data[0].process_name;
          this.plantId = res.data[0].plant_id;
          }
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantList = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  onSelectPlant(e: any) {
    this.selectedPlant = e.value;
    this.getVehicleTransaction();
  }

  getVehicleTransaction() {
    this.common.loading = true;
    let params = {
      plantId: this.selectedPlant,
    };
    this.api.get('transaction/get-vehicle-transaction-list', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.transactionVehicles = res.data;
        this.tempTxnList = res.data;
        if (this.transactionVehicles.length > 0) {
          let temp = null;
          if (this.txnId == null) {
            temp = res.data[0];
          }else {
            temp = res.data.filter((d) => d.ipa_transaction_id == this.txnId)[0];
          }
          this.vehicleRCNo = temp.vehicle_rsn.substring(0,temp.vehicle_rsn.lastIndexOf('-'));
          this.processId = temp.process_id;
          this.vehicleId = temp.txnid;
          this.txnId = temp.ipa_transaction_id;
          this.processName = temp.process_name;
          this.driverName = temp.driver_name;
          this.tripDetail();
          this.getOverridingPermissions();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectVehicle(vehicle: any) {
    this.txnId = vehicle.ipa_transaction_id;
    this.vehicleId = vehicle.txnid;
    this.vehicleRCNo = vehicle.vehicle_rsn.substring(0,vehicle.vehicle_rsn.lastIndexOf('-'));
    this.processId = vehicle.process_id;
    this.processName = vehicle.process_name;
    this.driverName = vehicle.driver_name;
    this.tripDetail();
    this.getOverridingPermissions();
  }

  tripDetail() {
    this.common.loading = true;
    this.api.get('trip/get-vehicle-trip-detail?txnId=' + this.txnId).subscribe(
      (res: any) => {
        if (res.status) {
          this.tripDetails = res.data;
          console.log('this.tripDetails: ', this.tripDetails);
          this.processName = res.data[0].process_name;
          this.plantId = res.data[0].plant_id;
        }
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  viewRemark(logId: number) {
    this.common.params = {
      log_id: logId,
      checklist_title: this.vehicleRCNo,
      remarkType: 0,
    };
    this.modalService.open(ViewRemarkComponent, { size: 'lg' });
  }

  getOverridingPermissions() {
    let params = {
      userId: this.userId,
      processId: this.processId,
    };
    this.api.get('trip/get-overriding-permissions', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.overRidingPermissions = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  checkExist(actionId: any, stageId: any) {
    let flag = false;
    if (this.overRidingPermissions.length > 0) {
      this.overRidingPermissions.forEach((e) => {
        if (e.processid == null && flag == false) {
          flag = true;
        } else if (e.stageid == null && flag == false) {
          flag = true;
        } else if (
          e.stageid == stageId &&
          (e.actionid == null || (e.actionid == actionId && flag == false))
        ) {
          flag = true;
        }
      });
      return flag;
    }
  }

  openEditModal(trip: any, action: any) {
    this.common.params = {
      title: 'Override Action',
      description:
        'Action Name - ' +
        action.action_name +
        '<br>' +
        'Current Response - ' +
        action.response +
        '<br>' +
        'Done by - ' +
        action.created_by,
      btn1: 'Approve',
      btn2: 'Fail',
    };

    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.updateStatus(
          action.transaction_detail_id,
          'PASS',
          trip.transaction_id,
          action.action_id
        );
      } else if (!data.response && data.apiHit == 0) {
        this.updateStatus(
          action.transaction_detail_id,
          'FAIL',
          trip.transaction_id,
          action.action_id
        );
      }
      this.tripDetail();
      this.getVehicleTransaction();
    });
  }

  updateStatus(
    transactionDetailId: any,
    status: any,
    txnId: any,
    actionId: number
  ) {
    this.common.loading = true;
    let params =
      'transactionDetailId=' +
      transactionDetailId +
      '&status=' +
      status +
      '&actionId=' +
      actionId +
      '&transactionId=' +
      txnId;
    this.api.get('dashboard/update-overriding-status?' + params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.data.responseMessage);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  actionDetail(action, actionType: any, data: any, action_type: number) {
    let activeModal = this.modalService.open(ActionInfoModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });
    activeModal.componentInstance.refData = {
      actionName: action.action_name,
      actionType: actionType,
      stageId: data.stage_id,
      txnStageDetailId: data.ipa_transaction_stage_detail_id,
      txnId: this.txnId,
      actionId: action.action_id,
      action_type: action_type,
    };
    activeModal.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  viewAuditLogs(action: any, stage: any) {
    let data = {
      process: this.processName,
      stage: stage,
      vehicleNumber: this.vehicleRCNo,
      param: {
        actionId: action.action_id,
        tripId: this.txnId,
      },
    };

    this.common.params = data;
    const activeModal = this.modalService.open(AuditModalComponent, {
      size: 'xl',
      backdrop: 'static',
      windowClass: 'audit-modal',
    });
  }

  closeModal(res) {
    this.activeModal.close(res);
  }

  filterTxn(event: any) {
    this.transactionVehicles = this.tempTxnList.filter((p) =>
      p.txnid.toLowerCase().includes(event.trim().toLowerCase())
    );
  }

  filterVehicle(event: any) {
    this.transactionVehicles = this.tempTxnList.filter((p) =>
      p.vehiclersn.toLowerCase().includes(event.trim().toLowerCase())
    );
  }


  openForm(trip:any, action:any) {
    const activeModal = this.modalService.open(ManualStampingFormComponent, {
      size: 'lg',
    });
    activeModal.componentInstance.refData = {
      processId : this.processId,
      txnId: trip.transaction_id,
      stageId: trip.stage_id,
      txnStageDetailId: trip.ipa_transaction_stage_detail_id,
      actionId: action.action_id,
      actionName: action.action_name,
      actionType: action.action_type,
      responseStatus : action.response,
      vehicleId : this.refData.vehicleId,
      txnDetailId : action.transaction_detail_id,
      plantId:this.plantId
    };
    activeModal.result.then((res) => {
      // this.getQueueData(null);
    });
  }


  openCommentTrail() {
    const activeModal = this.modalService.open(TripCommentTrailComponent, {
      size: 'lg',
    });
    activeModal.componentInstance.tripId = this.txnId;
    activeModal.componentInstance.showModal = true;
    if(this.txnType=='Visit'){
    activeModal.componentInstance.entityType = 'Visit'
    }
    activeModal.result.then((res) => {
      // this.getQueueData(null);
    });

  }

  openTrackingLog(txnStageId:number) {
    const activeModal = this.modalService.open(TripTrackingLogsComponent, {
      size: 'xl',
    });
    activeModal.componentInstance.txnStageId =txnStageId;
    activeModal.result.then((res) => {
      // this.getQueueData(null);
    });

  }
}

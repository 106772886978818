import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class StageActionService extends ApiService{

  saveStageAction(data: any): Observable<any> {
    let apiSubUrl = "actions/save_stage_action";

    return super.post(apiSubUrl, data);
  }
  getAllStageAction(): Observable<any> {
    let apiSubUrl = "actions/get-all-actions?flag=true";
    return super.get(apiSubUrl);
  }

}

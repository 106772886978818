import { Component, Input, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
@Component({
  selector: 'app-custom-communication',
  templateUrl: './custom-communication.component.html',
  styleUrls: ['./custom-communication.component.scss'],
})
export class CustomCommunicationComponent implements OnInit {
  @Input() public refData: any;
  @Input() communicationType: string;

  public Editor = ClassicEditor;
  public detail: any;
  content:string = ""

  contactInfoValidator:any
  contentValidator:any
  i: number;

  contactInfo: any[]

  switch:boolean = false;
  constructor(
    public alertService: AlertService,
    public api:ApiService,
    public common:CommonService
  ) {}

  ngOnInit(): void {
    this.communicationType = this.refData.communicationType;
  }

  send(){
    let body = {
      communicationType:this.communicationType,
      contactInfo:this.contactInfo.map((detail) => detail.name),
      communicationContent:this.content
    };
    this.common.loading = true;
    this.api.post('visitors/send-custom-communication', body).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.reset();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  onValueChanged(event: any) {
    console.log(event)
    // this.switch = !this.switch
    let lastAdded:any;
    if (event && event.length > 0) {
      lastAdded = event[event.length - 1];
    }
    if (this.communicationType != 'email') {
      let isNonNumericPresent = /[^0-9]/.test(lastAdded.name);
      if (isNonNumericPresent) {
        this.contactInfo.splice(event.length-1,1);
        return this.alertService.error('Invalid Mobile Number');
      }
    }
    this.contactInfo = event;
  }

  formValidator() {
    this.contactInfoValidator = this.common.nullValidator(
      this.contactInfo,
      'Contact Information Mandatory'
    );

    this.contentValidator = this.common.nullValidator(
      this.content,
      'Message Content Mandatory'
    );

    if (
      !this.contactInfoValidator.error &&
      !this.contentValidator.error
    ) {
      this.send();
    }else{
      console.log("else of formValidator")
    }
  }

  reset(){
    this.contactInfo = []
    this.content = ""
    this.contactInfoValidator=null
    this.contentValidator = null;
  }
}

import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { AssetsService } from './../assets/assets.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { UserService } from 'src/app/@core/services/user.service';
import { CompanyService } from '../companies/companies.service';
import { PlantService } from '../plant/plant.service';
import { TableService } from 'src/app/@core/services/table.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AssetLabelService } from '../asset-label/asset-label.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { AssetLabelMappingService } from './asset-label-mapping.service';
import { Router } from '@angular/router';
import { Asset, AssetLabel, AssetLabelMapping, CompanyData, PlantData } from 'src/app/dataType/assetInterface';
import { validator } from 'src/app/dataType/commonInterface';

//for jquery
declare var $: any;
@Component({
  selector: 'app-asset-label-mapping',
  templateUrl: './asset-label-mapping.component.html',
  styleUrls: ['./asset-label-mapping.component.scss'],
})
export class AssetLabelMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'ASSET LABEL MAPPPING LIST');


  labelValidator: validator[] = [];
  assetValidator: validator;
  company: CompanyData[] = [];
  plantData: PlantData[] = [];
  companyName: string =null;
  plantName: string=null;
  assetName: string=null;
  assetLabelMappings:AssetLabelMapping[]= [];
  editFlag = false;
  removeMappings = [];
  assetId: number = null;
  mappings = {
    labels: [
      {
        labelId: null,
        labelName: null,
        companyId: null,
        plantId: null,
        mappingId: null,
      },
    ],
  };
  companyId: number = null;
  plantId: number = null;
  assetLabels: AssetLabel[] = [];
  assets: Asset[] = [];

  constructor(
    public common: CommonService,
    private alert: AlertService,
    private breadcrumbService: BreadcrumbService,
    private assetsService: AssetsService,
    public userService: UserService,
    private assetLabelService: AssetLabelService,
    public role: RoleService,
    private companyService: CompanyService,
    private plantService: PlantService,
    public table: TableService,
    private assetLabelMappingService: AssetLabelMappingService,
    private alertService: AlertService,
    public router: Router
  ) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'assets', url: null },
      { name: 'Asset Label Mapping', url: '/user/asset-label-mapping' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }



  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnInit(): void {
    this.getAssetLabels();
    this.getAsset();
    this.getAssetLabelMapping();
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else if (this.role.user_role == 'COMPANYADMIN') {
      this.companyId = this.userService._loggedInUser.companyId;
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
    }
  }

  goToLabel() {
    this.router.navigate(['user/asset-label']);
  }

  selectAsset(event: any) {
    this.assetId = event.asset_id;
    this.assetLabelMappings.forEach((mapping, index) => {
      if (mapping.asset_id == event.asset_id) {
        this.edit(mapping);
        this.editFlag = true;
      }
    });
    if (!this.editFlag) {
      this.mappings.labels = [];
      this.mappings.labels[0] = {
        labelId: null,
        labelName: null,
        companyId: null,
        plantId: null,
        mappingId: null,
      };
    }
    this.assetName = event.asset_name;
  }

  getAssetLabelMappings(list: any) {
    let assetLabelNames = JSON.parse(list)
      .map((mapping) => mapping.asset_label_name)
      .filter((labelName) => labelName && labelName.trim() !== '');
    return assetLabelNames.join(', ');
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.company = this.company.concat(
            res.data.filter((element) => element.companyStatus == 'ACTIVE')
          );
        }
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  getAssetLabelMapping() {
    this.common.loading = true;
    let params = {
      companyId: this.companyId,
      plantId: this.plantId,
    };
    this.assetLabelMappingService.getAssetLabelMapping(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.assetLabelMappings = res.data;
          this.renderTable();
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  checkLabels(row: any) {
    if (this.role.user_role == 'COMPANYADMIN'){
      return row.plantId == 1 ? false : true; }
    else if (this.role.user_role == 'AXESTRACK') {
      return true;}
    else{
      return row.mappingId == null ||
        row.plantId == this.userService._loggedInUser.plantId
        ? true
        : false;}
  }

  getPlantById(id?: any) {
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.plantData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
      }
    );
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.plantName = null;
    this.plantId = null;
    this.getPlantById(event.companyId);
    this.getAssetLabelMapping();
    this.getAsset();
    this.getAssetLabels();
  }

  edit(row: any) {
    this.assetId = row.asset_id;
    this.mappings.labels = [];
    this.removeMappings = [];
    JSON.parse(row.labels_data).forEach((obj) => {
      this.mappings.labels.push({
        labelId: obj.label_id,
        labelName: obj.asset_label_name,
        companyId: obj.company_id,
        plantId: obj.plant_id,
        mappingId: obj.id,
      });
    });
    this.assetName = row.asset_name;
  }

  resetMappings() {
    this.mappings.labels = [
      {
        labelId: null,
        labelName: null,
        companyId: null,
        plantId: null,
        mappingId: null,
      },
    ];
    this.removeMappings = [];
    this.assetId = null;
    this.assetName = null;
    this.assetValidator = null;
    this.labelValidator = [];
  }

  getAsset() {
    this.common.loading = true;
    let params = {
      allAssets: false,
      companyId: this.companyId,
      plantId: this.plantId,
      callingForLabelMapping: true,
    };
    this.assetsService.getAsset(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.assets = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  addValues() {
    this.mappings.labels.push({
      labelId: null,
      labelName: null,
      companyId: null,
      plantId: null,
      mappingId: null,
    });
  }

  getAssetLabels() {
    let params = {
      status: false,
      companyId: this.companyId,
      plantId: this.plantId,
    };
    this.assetLabelService.getAssetLabel(params).subscribe((res: any) => {
      if (res.status) {
        this.assetLabels = res.data;
      }
    });
  }

  selectedLabel(event: any, index: any) {
    const exists = this.mappings.labels.some(
      (label) => label.labelId === event.asset_label_id
    );
    if (exists) {
      this.alert.error('Mappping Already Exist, Please Select Another !!');
      this.mappings.labels.splice(index, 1);
      this.mappings.labels.splice(index, 0, {
        labelId: null,
        labelName: null,
        companyId: null,
        plantId: null,
        mappingId: null,
      });
    } else {
      this.mappings.labels[index].labelId = event.asset_label_id;
      this.mappings.labels[index].labelName = event.asset_label_name;
      this.mappings.labels[index].companyId = this.companyId;
      this.mappings.labels[index].plantId = this.plantId;
      this.mappings.labels[index].mappingId = null;
    }
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getAssetLabelMapping();
    this.getAsset();
    this.getAssetLabels();
  }

  saveMapping() {
    this.common.loading = true;
    this.mappings.labels = this.mappings.labels.filter(
      (res) => res.mappingId == null
    );
    let params = {
      assetId: this.assetId,
      mappings: this.mappings.labels,
      removeMappings: this.removeMappings,
      plantId: this.plantId,
      companyId: this.companyId,
    };
    this.assetLabelMappingService.saveMapping(params).subscribe(
      (response) => {
        this.common.loading = false;
        if (response.status) {
          this.alertService.success(response.message);
          $('#exampleModal').modal('hide');
          this.resetMappings();
          this.getAssetLabelMapping();
        } else {
          this.alertService.error(response.message);
        }
        this.getAssetLabelMapping();
      },
      (error) => {
        this.common.loading = false;
      }
    );
  }

  formValidator() {
    this.assetValidator = this.common.nullValidator(
      this.assetId,
      'Asset is Mandatory'
    );
    if (this.editFlag == false) {
      this.mappings.labels.forEach((e, index) => {
        this.labelValidator[index] = this.common.nullValidator(
          e.labelId,
          'Label is Mandatory'
        );
      });
    }
    if (
      !this.assetValidator.error &&
      !this.labelValidator.some((validator) => validator.error)
    ) {
      this.saveMapping();
    }
  }

  resetDetail() {
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
      this.companyId = null;
      this.plantId = null;
    } else if (this.role.user_role == 'COMPANYADMIN') {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = null;
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
    }
    this.plantName = null;
    this.companyName = null;
    this.resetMappings();

    this.getAssetLabels();
    this.getAsset();
    this.getAssetLabelMapping();
    if (this.role.user_role == 'AXESTRACK') {
      this.plantData = [];
    } else if (this.role.user_role == 'COMPANYADMIN') {
      this.getPlantById(this.userService._loggedInUser.companyId);
    }
  }
}

<div class="card">
    <div class="card-body">
      <div class="card-header">
        <div *ngIf="entityType!='ASSET'" class="header-block" style="display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;">
          <h3 class="card-title">
            {{ (entityType==='Visit'?entityType:'Trip')+" Remark History"|translate}}
          </h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <section class="timeline_area section_padding_130">
        <div class="container">
          <div class="row">
            <!-- <div class="col-12"> -->
            <div class="main-timeline-area">
              <div class="single-timeline-area" *ngFor="let data of tripCommentTrail;let i=index;let last = last"
                [style]="'--border-color:'+data.comment_color+';--color:'+data.comment_color+'85'">
                <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s"
                  style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                  <button class="button-45" role="button">{{data.comment_type}}</button>
                </div>
                <div class="row" style="margin-left:20px" [id]="last?'lastMsg' : 'Msg'">
                  <div class="col-12">
                    <div class="time-box" *ngIf="data.ref_data!= null && data.ref_data.stageName != null">
                      {{data.ref_data.stageName}}</div>
                    <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s"
                      style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                      <div class="timeline-text" style="display: flex;flex-direction: column;">
                        <span style="font-size: 12px;margin-bottom: 4px;width:fit-content"
                          *ngIf="data.ref_data!= null && data.ref_data.title != null"><i
                            class="fa fa-chevron-circle-right" aria-hidden="true"
                            style="font-size: 12px;margin-right:3px;"></i> {{data.ref_data.title}}</span>
                        <span style="font-size: 16px;margin-bottom: 5px;"><i class="fa fa-comments"
                            style="font-size: 16px;"></i> : {{data.comment}}</span>
                            <p *ngIf="entityType!=null && entityType=='ASSET' && data.ref_data!=null && data.ref_data.Remarks!=null"><b>Remarks</b>   :   {{data.ref_data.Remarks}}</p>
                        <div style="font-size:10px">
                          <span style="margin-right: 10px;"><i class="fa fa-user" style="font-size: 10px;"></i> :
                            {{data.user_name}}</span>
                          <span><i class="fa fa-clock" style="font-size: 10px;"></i> : {{data.addtime}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 px-2">
              <form>
                <div class="form-group">
                  <textarea class="form-control" id="message-text" type="text" [(ngModel)]="commentMessage"
                    name="message-text" #name="ngModel" placeholder="Enter Remark for this {{(entityType==='Visit'?entityType:'Trip')}}"></textarea>
                </div>
              </form>
              <button type="button" class="btn btn-secondary" *ngIf="entityType && (entityType=='TRIP'  || entityType=='VISIT')" (click)="addCompliance()"
                data-dismiss="modal">Add Compliance</button>
              <button type="button" class="btn btn-primary" style="float:right" (click)="addCommentForTrip()"
                data-dismiss="modal">Add Remark</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

<div class="container-fluid">
  <div class="container-card group-card">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header" style="display: flex; align-items: center; justify-content: space-between;">
            <div class="header-block" style>
              <h3 class="card-title">
                {{ "Visitor Card Template" | translate }}
              </h3>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div *ngIf="role.user_role=='AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input">{{ "select_company" | translate }}</label>
                <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                  display="companyName" placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                </ps-auto-suggestion>
                <!-- <div class="error" *ngIf="companyNameValidator && companyNameValidator.error">
                    <span>{{ companyNameValidator.msg }} </span>
                  </div> -->
              </div>
              <div *ngIf="role.user_role=='AXESTRACK' || role.user_role=='COMPANYADMIN'"
                class="form-group col-xl-3 col-lg-6 col-sm-12">
                <label for="my-input">{{ "select_plant" | translate }}</label>
                <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                  placeholder="Choose Plant" [preSelected]="{ plantName: plantName }">
                </ps-auto-suggestion>
                <!-- <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{ plantNameValidator.msg }} </span>
                  </div> -->
              </div>
            </div>
          </div>

          <div class="card-body" style="margin-top:-25px !important">
            <div class="row">
              <div class="col-md-8">
                <div class="form_layout" style="min-height: 300px;">
                  <div class="row m-0">
                    <div [ngClass]="isLast && isEven ? 'example-list col-md-12 pl-1' : 'example-list col-md-6 pl-1'"
                      *ngFor="let item of visitorCardMappedFields; index as i;last as isLast;even as isEven">
                      <!-- <ng-container *ngFor="let item of visitorCardMappedFields; index as i"> -->
                      <div
                        [ngClass]="{'color-when-not-null': item.database_linked_id != null, 'color-when-null': item.database_linked_id == null}">
                        <div class="example-box example-box1 setting-box">
                          <span> {{i+1}}: </span>
                          <span style="width:45%;text-align: center;">{{item.field_name}}</span>
                        </div>
                      </div>
                      <!-- </ng-container> -->
                    </div>
                  </div>
                  <div cdkDropListGroup class="row m-0" style="min-height: 300px">
                    <div [hidden]="allItems.length == 0 && visitorCardMappedFields.length %2 != 0" cdkDropList
                      [cdkDropListData]="allItems" class="example-list col-md-6 pl-1" #allItemsListLeft="cdkDropList"
                      (cdkDropListDropped)="drop($event, 'all-items','left')">
                      <ng-container *ngFor="let item of allItems; index as i,even as isEven; last as isLast">
                        <div *ngIf="isEven" cdkDrag [cdkDragData]="item"
                          [ngClass]="{'color-when-not-null': item.database_linked_id != null, 'color-when-null': item.database_linked_id == null}">
                          <div class="example-box example-box1 setting-box">
                            <span> {{visitorCardMappedFields.length + i + 1}}: </span>
                            <span style="width:45%;text-align: center;">{{item.field_name}}</span>
                            <i class="fa fa-times remove-element" aria-hidden="true" (click)="removeFields(item,i)">
                            </i>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div [hidden]="allItems.length == 0 && visitorCardMappedFields.length %2 == 0" cdkDropList
                      [cdkDropListData]="allItems" class="example-list col-md-6 pl-1" #allItemsListRight="cdkDropList"
                      (cdkDropListDropped)="drop($event, 'all-items','right')">
                      <ng-container *ngFor="let item of allItems;index as i;last as islast;odd as isOdd">
                        <div *ngIf="isOdd" cdkDrag [cdkDragData]="item"
                          [ngClass]="{'color-when-not-null' : item.database_linked_id!=null ,'color-when-null' : item.database_linked_id==null}">
                          <div class="example-box example-box2 setting-box">
                            <span> {{visitorCardMappedFields.length + i + 1}}: </span>
                            <span style="width:45%;text-align: center;">{{item.field_name}}</span>
                            <i class="fa fa-times remove-element" aria-hidden="true" (click)="removeFields(item,i)"></i>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div *ngIf="visitorCardMappedFields.length == 0 && allItems.length == 0"
                    style="position: relative;bottom: 20%;">
                    <app-data-not-found></app-data-not-found>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="card">

                  <!-- all FIXED type fields mapped to Visitor Form of that plant -->
                  <div class="allFields">
                    <div class="form_layout text-center">
                      <div class="fields_header">
                        <i class="fa fa-search search_field" aria-hidden="true"></i>
                        <input class="search_input" name="fieldSearch" id="fieldSearch" type="text"
                          (input)="searchFieldName($event)" placeholder="Field Name" />
                      </div>
                      <div class="fields example-list" cdkDropList [cdkDropListData]="allFields"
                        [cdkDropListConnectedTo]="[allItemsListLeft,allItemsListRight]" *ngIf="allFields.length">
                        <div *ngFor="let data of allFields;let i=index;last as islast" cdkDrag [cdkDragData]="data"
                          class="drag-element">
                          <div class="fields_name"
                            [ngClass]="{'color-when-not-null' : data.database_linked_id!=null ,'color-when-null' : data.database_linked_id==null}">
                            {{data.field_name}}</div>
                        </div>
                      </div>
                      <img src="assets/images/no-record.png" alt="" style="width: 60%;margin-top: 35%;" alt=""
                        *ngIf="!allFields.length">
                    </div>
                  </div>
                </div>
              </div>
              <div class="stage-setting-footer col-8">
                <div class="btn-block d-flex  justify-content-end">
                  <button class="btn ml-2 btn-warning text-uppercase" (click)="reset()"> {{'reset'|translate}}
                  </button>
                  <button class="btn ml-2 btn-success text-uppercase" (click)="saveMapping()">{{'save'|translate}}
                  </button>
                </div>
              </div>

              <div class="visitor_card_sec col-md-6">
                <div class="card_design" id="visitorcard">
                  <div class="card_top">
                    <h5>VISITOR CARD</h5>
                  </div>


                  <div class="card_sec">
                    <div class="card_left" [ngStyle]="{'width': '100%'}">
                      <img src="assets/images/visitor_img.png">
                    </div>


                    <div class="card_right" >
                      <ng-container *ngIf="visitorCardMappedFields.length">
                        <div class="data_line" *ngFor="let item of visitorCardMappedFields; let i=index">
                          <h6>{{item.field_name}}</h6>
                          <p>--------</p>
                        </div>
                      </ng-container>
                      <div class="data_line" *ngFor="let item of allItems; let i=index">
                        <h6>{{item.field_name}}</h6>
                        <p>--------</p>
                      </div>
                    </div>
                  </div>


                  <!-- <div class="card_bottom">
                    <p>VID:- {{visitorCardDetails[0]?.orderId}}</p>
                  </div> -->
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>

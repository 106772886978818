<div class="card dashboard-card trip_activity" *ngIf="userService.permissionMap.get('view_trip_activity_access')==true"
  style="height: 228px;">
  <!-- <div class="card-header">
    <h3 class="card-title text-uppercase">{{"trip_activity" | customTranslate}} <span class="card-sub-title"> {{tripname ?(tripname) :''}} </span></h3>

    <div class="right-menu">
      <div class="input_class">
        <div class="form-group">
          <label style="font-size: 12px;">{{'process'|customTranslate}}</label>
          <ps-auto-suggestion (onSelected)="getprocessindex($event)" [data]="allProcess" display="processName"
            placeholder="Select Process" [preSelected]="{processName:defaultprocess}">
          </ps-auto-suggestion>
        </div>
        <div class="form-group">
          <label style="font-size: 12px;">{{'filter'|customTranslate}}</label>
          <ps-auto-suggestion (onSelected)="getEvent($event)" [data]="filters" display="filter"
            placeholder="Select Filter"  [preSelected]="{filter:defaulttripindex}">
          </ps-auto-suggestion>
        </div>
      </div>
    </div>
  </div> -->
  <div class="card-body" style="overflow: hidden;">
    <canvas baseChart [data]="tripChartData" [labels]="tripLabels"
      [chartType]="tripChartType" [options]="piechartoptions" [legend]="pieChartLegend" [colors]="pieChartColors"
      class="pie-chart" style="background-color: white;">
    </canvas>
  </div>
</div>

import { TableService } from 'src/app/@core/services/table.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DetailService } from './detail.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-detail-modal',
  templateUrl: './detail-modal.component.html',
  styleUrls: ['./detail-modal.component.scss','../tmg-dashboard.component.scss']
})
export class DetailModalComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any;

  ngAfterViewInit() {
    this.dtTrigger.next();
    // this.openmodal();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }



title:string = '';
data:any = [];
values:any[] = [];
keys:any = [];
filterdata:any = [];
className:string = '';
tbodyheight:number = 0;
tbodywidth:number = 0;

constructor(public detail:DetailService, public table:TableService) { }

openmodal(){
this.title = this.detail.detailtitle;
this.data = this.detail.detaildata ?? [];
this.className = this.detail.className;
if(this.data.length){
this.keys.push(Object.keys(this.data[0]));
this.data.map((item:any)=>{
this.values.push(Object.values(item));
this.filterdata.push(Object.values(item));
this.tbodyheight = ((this.detail.modalsize[1] / 2) - 10);
this.tbodywidth = this.detail.modalsize[0] - 14;
this.dtOptions = this.table.options(5,this.keys.length,this.title)
});
// this.renderTable();

}



}


filter(data:any){
this.values =  this.filterdata.filter((item:any)=> {
return item.toString().includes(data.value.trim());
});
}

  ngOnInit(): void {
    this.openmodal();
  }



}

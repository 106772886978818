<div class="modal-header">
  <h5 class="modal-title text-capitalize" id="modal-basic-title">{{title}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" *ngIf="show">
  <div *ngIf="imgUrl!=null" class="image modal-info" (click)="displayImage(imgUrl)" style="justify-content: space-evenly;">
    <div class="both_sec" style="width: 50%">
      <img [src]="imgUrl" style="width:100%">
    </div>
  </div>
  <div class="modal-info" *ngFor="let number of numbers;">
    <div class="both_sec">
      <div class="head">
        <label>{{data[number*2]['heading']+':'}}</label>
      </div>
      <div class="value">
        <p class="mb-0"> {{data[number*2]['value']}} </p>
      </div>
    </div>

    <div class="both_sec">
      <div *ngIf="data[number*2+1]" class="head">
        <label>{{data[number*2+1]?data[number*2+1]['heading']+':':''}}</label>
      </div>
      <div *ngIf="data[number*2+1]" class="value">
        <p class="mb-0"> {{data[number*2+1]?data[number*2+1]['value']:''}} </p>
      </div>
    </div>
  </div>
</div>
<div class="modal-body" *ngIf="!show">
  <div class="modal-info">
    {{common.params.details}}
  </div>
</div>

<div class="modal-footer" *ngIf="isBtn">
  <div class="row">
    <button class="btn btn-secondary" (click)="closeModal(false)">Back</button>
    <button class="btn btn-success ml-2" (click)="closeModal(true)">SAVE</button>
  </div>
</div>
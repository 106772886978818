import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { PlantService } from '../plant/plant.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';

@Component({
  selector: 'app-department-management',
  templateUrl: './department-management.component.html',
  styleUrls: ['./department-management.component.scss']
})

export class DepartmentManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 8, 'department list');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  //global variables

  department = {
    deptId: null,
    deptName: '',
    deptDescription: null,
    statusId: null,
    companyId: null,
    plantId: null,
  };

  title = 'Add Department';
  btn = 'save';

  departmentScope: any = null;
  companyName:String = null;
  plantName:String =  null;
  statusName:null;
  companyList: any = [];
  plantList: any = [];
  deptStatusList :any = [];
  departmentList: any = [];
  userList:any[]=[];
  selectedUser: any[]=[];

  deptNameValidator: any;
  companyValidator: any;
  plantValidator: any;
  deptStatusValidator:any;



  // constructor
  constructor(
    private alertService: AlertService,
    private api: ApiService,
    private plantService: PlantService,
    public common: CommonService,
    public modalService: NgbModal,
    public breadcrumbService: BreadcrumbService,
    public table: TableService,
    public userService: UserService,
    public role: RoleService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Department', url: null },
      { name: 'Department Management', url: '/user/users' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
   }



  ngOnInit() {
    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else {
      if (this.role.user_role != 'AXESTRACK') {
        this.getPlantById(this.userService._loggedInUser.companyId);
      }
    }
    this.departmentScope = this.role.user_role == 'AXESTRACK' ? 'GLOBAL' : 'COMPANY'
    this.getStatusList();
    this.getDepartmentList();
    this.getUserList();
  }



  //custom functions

  selectedCompany(event: any) {
    this.department.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.department.plantId = null;
    this.getStatusList();
    this.getUserList();
  }

  selectedPlant(event: any) {
    this.department.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getStatusList();
    this.getUserList();
  }

  selectedStatus(event: any) {
    this.department.statusId = event.status_id;
    this.statusName = event.status_name;
  }


  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantList = res.data;
          console.log('this.plantList: ', this.plantList);
          resolve(this.plantList);
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
        reject(null);
      }
    );
  });
  }

  getStatusList() {
    let params ={
      companyId : this.department.companyId,
      plantId : this.department.plantId,
      statusType : 'Department',
      allStatus : false
    }
    this.api.get('status/get-status-list',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.deptStatusList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }


  saveDepartment() {
    let headIds = [];
    if(this.selectedUser.length){
      headIds= this.selectedUser.map(head=>head.userid)
    }
    this.common.loading = true;
    let params = {
      deptId: this.department.deptId,
      deptName: this.department.deptName.trim(),
      deptDescription: this.department.deptDescription!=null ? this.department.deptDescription.trim() :this.department.deptDescription,
      statusId: this.department.statusId,
      plantId: this.department.plantId,
      companyId: this.department.companyId,
      userRole : this.role.user_role,
      headIds : headIds
    };
    let apiUrl = 'department/save-department';
    this.api.post(apiUrl, params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          if(res.message=="Name Already Exists"){
            this.alertService.warn(res.message);
          }
          else{
            this.alertService.success(res.message);
            this.resetDetail();
          }
          this.getDepartmentList();
        } else {
          this.common.loading = false;
          this.alertService.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getUserList() {
    let params = {
      companyId:this.department.companyId,
      plantId: this.department.plantId,
      userRole : this.role.user_role
    };
    this.common.loading = true;
    this.api.get('user/get-user-list', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.userList = res.data;
          console.log('this.userList: ', this.userList);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectedUsers(event: any) {
  console.log('event: ', event);
    this.selectedUser = event.heads.map((user) => ({
      userid: event.userid,
      name: event.name
    }));
  }

  formatNames(dataArray: any): string {
    return dataArray.map((item) => item.headName).join(', ');
  }

  async editRow(row: any) {
    console.log('row: ', row);
    this.common.gotoTop();
    this.resetDetail();
    await this.getPlantById(row.company_id);
    this.companyName = this.companyList.find((company) => company.companyId == row.company_id)?.companyName;
    console.log('this.companyName: ', this.companyName);
    this.plantName = this.plantList.find(plant => plant.plantId == row.plant_id)?.plantName ;
    console.log(' this.plantName: ',  this.plantName);
    this.btn = 'Update';
    this.title = 'Edit Department';
    this.department.deptId = row.dept_id;
    this.department.deptName = row.dept_name;
    this.department.deptDescription = row.dept_description;
    this.department.statusId = row.status_id;
    this.statusName = row.status_name;
    this.department.plantId = row.plant_id;
    this.department.companyId = row.company_id;
    if(this.role.user_role == 'AXESTRACK' || this.role.user_role== 'COMPANYADMIN'){
      this.departmentScope = row.plant_id == 1 ? 'GLOBAL' : row.plant_id == null ? 'COMPANY' : 'PLANT';
    }
    // this.selectedUserIds = row.heads.map((user) => user.headId);
    this.selectedUser = row.heads.map((user) => ({
      userid: user.headId,
      name: user.headName
    }));
  }

  viewDetails(row?:any){
  console.log('row: ', row);
  const DepartmentDetails={
    company_name: row.company_name,
    dept_description: row.dept_description,
    dept_name: row.dept_name,
    heads: this.formatNames(row.heads),
    plant_name: row.plant_name,
    status_name: row.status_name,
    modified_at:row.modified_at,
    created_at:row.created_at
  }
    this.common.params = { details: [DepartmentDetails], title: 'Departmanet Details' };
    const activeModal = this.modalService.open(ViewDetailsComponent, {
      size: 'lg',
    });
  }


  confirmAlert(row: any) {
    this.common.params = {
      title: 'Delete  Department',
      description:
        `<b>&nbsp;` + 'Are you sure you want to delete department?' + `<b>`,
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.delete(row);
      }
    });

  }

  delete(row?: any) {
  console.log('row: ', row);
    let params = {
      deptId: row.dept_id,
    };
    this.api.get('department/delete-department', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.resetDetail();
          this.getDepartmentList();
          this.alertService.info(res.message);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  resetDetail() {
    this.department.deptId = null;
    this.department.deptName = null;
    this.department.deptDescription = null;
    this.department.statusId = null;
    this.department.plantId = null;
    this.department.companyId = null;
    this.statusName = null ;
    this.plantList=null;
    this.deptNameValidator = null;
    this.plantValidator = null;
    this.companyValidator = null;
    this.deptStatusValidator=null;
    this.departmentScope = this.role.user_role == 'AXESTRACK' ? 'GLOBAL' : 'COMPANY'
    this.plantList=[];
    this.selectedUser=[];
    this.btn = 'save';
    this.title = 'Add Department';
  }

  getDepartmentList() {
    this.common.loading = true;
    let params = {
      allDepartments: true
    };
    this.api.get('department/get-department-list', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.departmentList = res.data.map(item => ({ ...item, heads: JSON.parse(item.heads)})) || [];
          this.renderTable();
        },
        (err: any) => {
          console.error('Error: ', err);
          this.common.loading = false;
        }
      );
  }

  formValidator() {
    this.deptNameValidator = this.common.nullValidator(
      this.department.deptName.trim(),
      'Department Name Mandatory'
    );
    this.deptStatusValidator = this.common.nullValidator(
      this.department.statusId,
      'Department Status  Mandatory'
    );
    this.companyValidator = this.common.nullValidator(
      this.department.companyId,
      'Company Mandatory'
    );
    this.plantValidator = this.common.nullValidator(
      this.department.plantId,
      'Plant Mandatory'
    );
    if(this.role.user_role == 'AXESTRACK' && this.departmentScope == 'COMPANY'){
      if (!this.companyValidator.error && !this.deptNameValidator.error && !this.deptStatusValidator.error) {
        this.saveDepartment();
      }
    }else if(this.role.user_role == 'AXESTRACK' && this.departmentScope == 'PLANT'){
      if (!this.companyValidator.error && !this.plantValidator.error && !this.deptNameValidator.error && !this.deptStatusValidator.error) {
        this.saveDepartment();
      }
    }
    else {
      if (!this.deptNameValidator.error && !this.deptStatusValidator.error) {
        this.saveDepartment();
      }
    }
  }

}

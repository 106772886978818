import {
  Component,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { WebcamImage } from 'ngx-webcam';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { catchError } from 'rxjs/operators';
import { Base64ToFileService } from 'src/app/@core/services/base64-to-file-service.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { UserService } from 'src/app/@core/services/user.service';
import { TABLE_TYPE, TableColumns} from 'src/app/dataType/customTableInterface';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { DisplayImageComponent } from 'src/app/modals/display-image/display-image.component';
import { AddVisitModalComponent } from 'src/app/modals/add-visit-modal/add-visit-modal.component';
declare var $: any;
@Component({
  selector: 'app-visitor-appointment',
  templateUrl: './visitor-appointment.component.html',
  styleUrls: ['././visitor-appointment.component.scss'],
})
export class VisitorAppointmentComponent implements OnInit {
  status: string[];
  tempTableData: any = [];
  activeIndex: number = 0;
  appointments: any[];
  visitorId: number;
  data: any = [];
  activeTab: string = 'booked';
  startDate: Date = new Date();
  endDate: Date = new Date();

  modalData: any;

  isVisitor: boolean = false;
  userType: string = '';
  allUsers: null;
  openModal: boolean

  // Add Visit object
  newAppointment = {
    appointmentId: null,
    name: null,
    startTime: null,
    endTime: null,
    purpose: null,
    from: null,
    to: null,
    refType: null,
    visitorId: null,
    visitors: ([] = [
      {
        id: null,
        staff_name: null,
        mobile_number: null,
        isNameDisabled: false,
      },
    ]),
    refId: null,
    userName: '',
    visitorImage: null,
  };

  showCamera: boolean = false;
  retakeImage: boolean = false;
  fileEvent: any = [];
  image: any = null;
  nameValidator: any;
  startTimeValidator: any;
  endTimeValidator: any;
  purposeValidator: any;
  fromValidator: any;
  toValidator: any;

  minStartDate: any = new Date();
  companyId: number = null;
  staffType: String = null;
  plantId: number = null;
  staffId: number = null;
  names: string = null;
  mobileNumberList: number[];
  nameList: string[];
  actionLabels: any[] = [
    {
      tabName: 'booked',
      tab_icon: 'assets/images/pending_visitor.png',
    },
    {
      tabName: 'approved',
      tab_icon: 'assets/images/pending_visitor.png',
    },
    {
      tabName: 'inprogress',
      tab_icon: 'assets/images/total_visitor.png',
    },
    {
      tabName: 'completed',
      tab_icon: 'assets/images/completed_visitor.png',
    },
    {
      tabName: 'rejected',
      tab_icon: 'assets/images/rejected_visitor.png',
    },
    {
      tabName: 'total',
      tab_icon: 'assets/images/total_visitor.png',
    },
  ];
  approve: string = 'Approve';
  reject: string = 'Reject';
  // title:string = 'Add New Visit'
  formId : number ;
  isEdit = false
  @ViewChild('addAppointment') contentTemplateRef: TemplateRef<any>;
  @ViewChild('staticForm') staticForm!: TemplateRef<any>;


  @Input() refData = null;
  @Input() toggle = false;
  @Input() title = 'Add New Visit';
  @Input() showReset = true;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent
  ) {
    if(this.modalClose)
    {
      $("#exampleModal").modal("show");
      this.modalClose= false
    }
  }
  modalClose = false
  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  loading: boolean = false;
  tableColumns: TableColumns[] = [];

  constructor(
    private api: ApiService,
    private common: CommonService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private base64ToFileService: Base64ToFileService,
    private imageCompress: NgxImageCompressService,
    private breadcrumbService: BreadcrumbService,
    private userService: UserService,
    private activeModal: NgbActiveModal,
    private translateService: TranslateService
  ) {
    this.startDate = new Date();
    this.endDate = new Date();

    this.staffType = this.userService._userType;
    this.companyId = this.userService._companyId;
    this.plantId = this.userService._plantId;
    this.staffId = this.userService._loggedInUser.id;
    let breadcumbs = [
      { name: 'visitor', url: null },
      {
        name: 'visitor_appointment',
        link: null,
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  async ngOnInit() {
    this.endDate.setDate(this.startDate.getDate() + 7);
    this.startDate = this.common.dateFormatter(
      this.startDate,
      'YYYYMMDD',
      false
    );

    this.endDate = this.common.dateFormatter(this.endDate, 'YYYYMMDD', false);
    this.minStartDate = this.minStartDate.toISOString().slice(0, 16);

    this.isVisitor = this.staffType == 'Visitor';
    this.newAppointment.visitorId = this.userService._loggedInUser.id;
    this.visitorId = this.userService._loggedInUser.id;
    this.newAppointment.name = this.userService._name;
    if (this.refData != null) {
      this.newAppointment = this.refData;
    }

    this.showVisits(this.activeTab);
    this.getAppointmentsCount();
    this.getAllUsers();
  }
  //check wheter the endtime is greater than starttime or not
  validateDateTimeRange(startDateTime: any, endDateTime: string) {
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      throw new Error('Invalid date format');
    }
    if (!(end > start)) {
      this.alertService.error(
        'Visit End Time should be greater than Visit start time.'
      );
      this.newAppointment.endTime = null;
    }
  }
  // checks whether the start time is greater than now()
  validateStartTime(): void {
    const selectedTime = new Date(this.newAppointment.startTime);
    const currentTime = new Date();

    if (selectedTime < currentTime) {
      this.alertService.error(
        'Start time cannot be in the past. Please select a valid time.'
      );
      this.newAppointment.startTime = null;
    }
  }
  // update the status of visit and is only for user
  updateVisitStatusByUser(visit: any, action: string) {
    this.common.params = {
      title: action==='Approve'?'approve_visit':'reject_visit',
      description:
      action==='Approve' ?'are_you_sure_you_want_to_approve':'are_you_sure_you_want_to_reject'
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        let body = {
          id: visit.id,
          updatedStatus: action === 'Approve' ? 'APPROVED' : 'REJECTED',
        };
        this.api
          .post('visitors/update-visitor-appointment-status', body)
          .subscribe(
            (res: any) => {
              if (res.status) {
                this.alertService.success(`VISIT ` + `${body.updatedStatus}`);
              } else {
                this.alertService.info(res.message);
              }
              this.common.loading = false;
              this.showVisits(this.activeTab);
              this. getAppointmentsCount()
            },
            (err: any) => {
              this.common.loading = false;
            }
          );
      }
    });
  }

  confirmCloseModalInWalkinMode() {
    this.common.params = {
      title: 'Are You Sure?',
      description:'All filled data will be erased',
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'md',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.closeModal();
      }
    });
  }
  // meet with list
  getAllUsers() {
    let params = {
      staffType: 'Visitor',
    };
    this.api.get('visitors/get-all-users', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.allUsers = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  // selects meet with
  selectedUser(selectedUser: { id: number; name: string; type_name: string }) {
    this.newAppointment.refId = selectedUser.id;
    this.newAppointment.refType = selectedUser.type_name;
    this.newAppointment.userName = selectedUser.name;
  }

  getAppointmentsCount() {
    let params = {
      visitorId: this.visitorId,
      startDate: (this.startDate = this.common.dateFormatter(
        this.startDate,
        'YYYYMMDD',
        false
      )),
      endDate: (this.endDate = this.common.dateFormatter(
        this.endDate,
        'YYYYMMDD',
        false
      )),
      userType: this.userService._isStaffLoggined ? 'STAFF' : 'USER',
    };
    this.api.get('visitors/get_appointments_count', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.data = res.data[0];
        }
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  showVisits(filter: string) {
    this.loading = true
    this.activeTab = filter;
    let params = {
      visitorId: this.visitorId,
      filter: filter.toLowerCase(),
      startDate: (this.startDate = this.common.dateFormatter(
        this.startDate,
        'YYYYMMDD',
        false
      )),
      endDate: (this.endDate = this.common.dateFormatter(
        this.endDate,
        'YYYYMMDD',
        false
      )),
      userType: this.userService._isStaffLoggined ? 'STAFF' : 'USER',
    };
    this.common.loading = true;
    this.api.get('visitors/get_appointments', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.appointments = res.data == null ? [] : res.data;

          this.appointments.map((appointment) => {
            appointment.mobileNumber = this.findVisitorDetails(
              appointment.visit_users,
              appointment.created_by,
              true
            );
            appointment.name = this.findVisitorDetails(
              appointment.visit_users,
              appointment.created_by,
              false
            );

            appointment.isPrimary =
              this.userService._loggedInUser.id == appointment.created_by;

            appointment.length = appointment.visit_users.length

            appointment.visit_days= appointment.visit_days=='00'?'01':appointment.visit_days
          });

          if (this.data.length != 0)
            this.data[this.activeTab] = this.appointments.length;

          this.tableColumns = [
            {
              columnLabel: this.translateService.instant("visit_start_time"),
              columnName: "visit_start_time",
            },
            {
              columnLabel: this.translateService.instant("visit_end_time"),
              columnName: "visit_end_time",
             }

            ,
            {
              columnLabel: this.translateService.instant("visitor_name"),
              columnName: "name",
            },
            {
              columnLabel: this.translateService.instant("visitor_mobile_no"),
              columnName: "mobileNumber",
            },
            {
              columnLabel: this.translateService.instant("no_of_visitors"),
              columnName: "length",
            },
            {
              columnLabel:  this.translateService.instant("meet_with"),
              columnName:"host_name",
            },
            {
              columnLabel: this.translateService.instant("no_of_days"),
              columnName: "visit_days"
            },
            {
              columnLabel: this.translateService.instant("purpose"),
              columnName: "purpose",
            },
            {
              columnLabel: this.translateService.instant("status"),
              columnName: "status",
              dataFormatter: (row: any, column: TableColumns, index: number) => {
                return (`<span  style=" display: inline-block;
                                        padding:  0.5px 6px !important;
                                        border-radius: 9999px;
                                        font-size: 10.5px !important;
                                        font-weight: bold !important;
                                        border: none;"  class="${row.status=='APPROVED' ? 'pill bg-green-200 text-green-800' : (row.status=='BOOKED'?'pill bg-blue-200 text-blue-800 ':(row.status=='REJECTED'?'pill bg-pink-200 text-pink-800':(row.status=='INPROGRESS'?'pill bg-yellow-200 text-yellow-800':row.status =='OUT'?'pill bg-red-200 text-red-800':'')))}">
                                        ${row.status}</span>
                                        `)
              }
            },
            {
              columnLabel: this.translateService.instant("Action"),
              columnName: null,
              dataActions:[
                {
                  actionName:null,
                  handleClick: (column: TableColumns, row: any, index: number) => {
                    this.updateVisitStatusByUser(row,this.approve)
                  },
                  actionFormatter: (column: TableColumns, row: any, index: number) => {
                    return (row.status == 'BOOKED' && !this.isVisitor)?`  <i class="fa fa-check"
                                      style="color: green; border-radius: 50%;" ></i>`:``
                  }
                },
                {
                  actionName:null,
                  handleClick: (column: TableColumns, row: any, index: number) => {
                    this.updateVisitStatusByUser(row,this.reject)
                  },
                  actionFormatter: (column: TableColumns, row: any, index: number) => {
                    return (!this.isVisitor && ( row.status =='APPROVED' || row.status =='BOOKED'))?` <i class="fa fa-times " style="color: red; border-radius: 50%;"
                                      (click)="updateVisitStatusByUser(data,reject)"></i>`:``
                  }
                },
                {
                  actionName:null,
                  handleClick: (column: TableColumns, row: any, index: number) => {
                    this.modalData = row.visit_users; this.openModal = true
                  },
                  actionFormatter: (column: TableColumns, row: any, index: number) => {
                    return this.openModal?` <i class="fa fa-info-circle icon_info" data-toggle="modal"
                                      data-target="#exampleModal"
                                      style="color:#013e90;"></i>`:`<i class="fa fa-info-circle icon_info" data-toggle="modal"
                                      style="color:#013e90;">`
                  }
                },
                {
                  actionName:null,
                  handleClick: (column: TableColumns, row: any, index: number) => {
                    this.confirmDeleteAppointment(row.created_by,row.visit_users, row.id,row.isPrimary)
                  },
                  actionFormatter: (column: TableColumns, row: any, index: number) => {
                    return (this.isVisitor || row.isPrimary && (row.status =='BOOKED'))?`<i class="fas fa-trash"
                            (click)="confirmDeleteAppointment(data.created_by,data.visit_users, data.id,data.isPrimary)"
                            style="color: red;"></i>`:``
                  }
                },
                {
                  actionName:null,
                  handleClick: (column: TableColumns, row: any, index: number) => {
                    const activeModal = this.modalService.open(AddVisitModalComponent,{
                      ariaLabelledBy: 'modal-basic-title' ,
                        size:'md'
                      });
                    activeModal.componentInstance.refData = {
                      isEdit:true,
                      visitDetails:row,
                      isVisitor:this.isVisitor
                    };
                    activeModal.result.then((data: any) => {
                      this.showVisits(this.activeTab);
                      this.getAppointmentsCount();
                    });
                  },
                  actionFormatter: (column: TableColumns, row: any, index: number) => {
                    return (row.isPrimary &&(row.status =='BOOKED'))?`<i class="fa fa-edit"
                                      style="color: green;"></i>`:``
                  }
                }
              ]
            }
          ]
          let tempColumns = _.cloneDeep(this.tableColumns);
          if (!this.isVisitor) {
            this.tableColumns = tempColumns.filter(e => e.columnLabel !== 'Meet With');
          }

        }
      },
      (err) => {
        this.common.loading = false;
      }
    );
  }

  bookAppointment() {
    let body = {
      appointmentId: this.newAppointment.appointmentId
        ? this.newAppointment.appointmentId
        : null,
      name: this.newAppointment.name,
      startTime: this.common.dateFormatter(
        this.newAppointment.startTime,
        'YYYYMMDD',
        false
      ),

      endTime: this.common.dateFormatter(this.newAppointment.endTime, 'YYYYMMDD', false),
      refId: this.newAppointment.refId,
      visitFrom: this.newAppointment.from,
      purpose: this.newAppointment.purpose,
      refType: this.newAppointment.refType=='USER'?'USER':'STAFF',
      visitors: this.newAppointment.visitors.filter((res) => res.staff_name != null),
      visitorId: this.isVisitor ? this.visitorId : null,
      visitorImage: this.newAppointment.visitorImage,
      plantId: this.plantId,
      companyId: this.companyId,

    };
    if (
      !this.isVisitor &&
      (body.visitors == null || body.visitors.length == 0)
    ) {
      return this.alertService.error(
        'Please Add Visitor for Appoinment Booking !!'
      );
    }
    this.common.loading = true;
    this.api.post('visitors/book_appointment', body).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success('Visit Updated Successfully');
          this.reset();
            this.closeModal();
            this.showVisits(this.activeTab);
            this.getAppointmentsCount();

        }
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  addValues() {

    this.newAppointment.visitors.push({
      id: null,
      staff_name: null,
      mobile_number: null,
      isNameDisabled: false,
    });
  }
  // visitor "-" (remove)
  removeRow(index: number) {
    if (this.newAppointment.visitors.length > 1) {
      this.newAppointment.visitors.splice(index, 1);
    }
  }
  //prefill name if staff exists and checks for banned,non-staff-type
  fillNameIfStaff(visitor: any, existingStaff: any) {
    if (existingStaff != null) {
      if (existingStaff.is_banned) {
        visitor.mobile = null;
        visitor.id = null;

        return this.alertService.error('Visitor Is Banned !!');
      }
      if (existingStaff.same_staff_type) {
        visitor.id = existingStaff.staff_id;
        if (existingStaff.staff_name != null) {
          visitor.staff_name = existingStaff.staff_name;
          visitor.isNameDisabled = true;
        }
      } else {
        this.alertService.error(
          'Visitor already exist with another staff type'
        );
        visitor.mobile = null;
        visitor.id = null;
        visitor.staff_name = null;
      }
      //when a existing staff mob no is entered ,name is prefilled and partially removing the existing mobno. and entering new mobileno
    } else {
      visitor.staff_name = null;
      visitor.isNameDisabled = false;
    }
  }


  handleMobileNumber(
    visitor: {
      id: number;
      mobile_number: string;
      staff_name: string;
      isNameDisabled: boolean;
    },
    mobile: number
  ) {

    if (new String(mobile).length == 10) {
      let params = {
        mobileNumber: mobile,
      };
      this.api.get('visitors/check-visitor-mobile-number', params).subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.status) {
            this.fillNameIfStaff(visitor, res.data[0]);
          }
        },
        (err: any) => {
          this.common.loading = false;
        }
      );
    }
    if (new String(mobile).length == 0) {
      visitor.staff_name = null;
      visitor.isNameDisabled = false;
      visitor.id = null;
    }
  }

  handleImage(webcamImage: WebcamImage, name: string) {
    this.onSelect(webcamImage, name);
  }

  onSelect(e, name) {

    let frmData = new FormData();
    let imgResultAfterCompression: DataUrl;
    let file;
    this.imageCompress
      .compressFile(e._imageAsDataUrl, -2, 50, 50) // 50% ratio, 50% quality
      .then((compressedImage) => {
        imgResultAfterCompression = compressedImage;
        file = this.base64ToFileService.base64toFile(
          imgResultAfterCompression,
          name + '.jpg'
        );
        frmData.append('files', file);
        this.fileEvent.inProgress = true;
        this.uploadFile(frmData, name);
      });
  }

  uploadFile(frmData: any, name: string) {
    const headers = new HttpHeaders();
    this.api
      .post('functional/upload-file?methodId=' + 17, frmData)
      .subscribe((res: any) => {
        switch (res.data.type) {
          case HttpEventType.UploadProgress:
            this.fileEvent.progress = Math.round(
              (res.data.loaded * 100) / res.data.total
            );
            break;
          case HttpEventType.Response:
            return res.data;
        }
        res.data.forEach((e) => {

          this.newAppointment.visitorImage = e.fileUrl;
        });
      });
    catchError((error: HttpErrorResponse) => {
      this.fileEvent.inProgress = false;
      this.common.loading = false;
      return `${this.fileEvent.data.name} upload failed.`;
    });
  }
  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  deleteAppointment(
    created_by: number,
    visit_users: [],
    appointment_id: number,
    isPrimary: boolean
  ) {
    const visitor_id: number[] = visit_users.map((visitor: any) => {
      return visitor.staff_id;
    });

    let params = {
      createdBy: created_by,
      appointmentId: appointment_id,
      isPrimary: isPrimary,
    };
    this.api.get('visitors/delete-appointment', params).subscribe(
      (res: any) => {
        if (res.status) {
        }
        this.showVisits(this.activeTab);
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  getNumberOfDays(date1: Date, date2: Date): number {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const timeDifference = Math.abs(d2.getTime() - d1.getTime());

    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysDifference;
  }

  findVisitorDetails(
    listOfVisitors,
    createdBy: number,
    mobileNumberOrName: boolean
  ) {
    if (mobileNumberOrName == true) {
      for (const visitor of listOfVisitors) {
        if (visitor.staff_id == createdBy) {
          return visitor.mobile_number;
        }
      }
      return listOfVisitors[0].mobile_number;
    } else {
      for (const visitor of listOfVisitors) {
        if (visitor.staff_id == createdBy) {
          return visitor.staff_name;
        }
      }

      return listOfVisitors[0].staff_name;
    }
  }
  openAddVisitor(data: any = null) {

    let visitorData = [];
    data.visit_users.forEach((element) => {
      visitorData.push({
        id: element.staff_id,
        name: element.staff_name,
        mobile: element.mobile_number,
        isNameDisabled: true,
      });
    });
    let tempData = {
      appointmentId: data.id,
      startTime: this.convertDateFormat(data.visit_start_time),
      endTime: this.convertDateFormat(data.visit_end_time),
      purpose: data.purpose,
      from: data.visit_from,
      refType: data.ref_type,
      refId: data.ref_id,
      userName: data.host_name,
      visitors: visitorData,
    };

    let activeModal = this.modalService.open(VisitorAppointmentComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'l',
      backdrop: 'static',
    });

    activeModal.componentInstance.refData = tempData;
    activeModal.componentInstance.title = 'UPDATE VISIT';
    activeModal.componentInstance.toggle = true;
    activeModal.componentInstance.showReset = false;

    activeModal.result.then((data: any) => {
      this.showVisits(this.activeTab);
    });
  }

  convertDateFormat(dateString: string): string {
    const [datePart, timePart] = dateString.split(' ');

    const [day, month, year] = datePart.split('-');

    const formattedDate = `${year}-${month}-${day}T${timePart}`;

    return formattedDate;
  }

  confirmDeleteAppointment(
    created_by: number,
    visit_users:any,
    id: number,
    isPrimary: boolean
  ) {
    this.common.params = {
      title: 'delete_visit',
      description:
        'are_you_sure_you_want_to_delete_visit',
    };
    const activeModal = this.modalService.open(ConfirmationDialogComponent, {
      size: 'lg',
    });
    activeModal.result.then((data: any) => {
      if (data.response) {
        this.deleteAppointment(created_by, visit_users, id, isPrimary);
      }
    });
  }

  getPlantSettingByPlantId(plantId: any) {
    return new Promise((resolve, reject) => {
      let params ={
        plantId : plantId
      }
      this.common.loading = true;
      this.api
        .get('plant/get-plant-setting-by-plant-id',params)
        .subscribe(
          (response: any) => {
            this.common.loading = false;
            if(response.status){
              this.formId = response.data[0].defaultVisitorFormId;
              resolve(this.formId);
            }
          },
          (err: any) => {
            console.error('Error: ', err);
            this.common.loading = false;
            reject(null);
          }
        );
    });
  }

  openVisitorImage(image_url){
    $("#exampleModal").modal("hide");
    this.common.params = { url: image_url,name:"Visitor Image"};
    if(image_url!=null){
    let activeModal=this.modalService.open(DisplayImageComponent,{
      ariaLabelledBy: 'modal-basic-title',
      size: 'md'
    });

    activeModal.result.finally(() => {
      this.modalClose = true

      $("#exampleModal").modal("show");

    });
  }


  }
  reset() {
    this.newAppointment = {
      appointmentId: null,
      name: null,
      startTime: null,
      endTime: null,
      purpose: null,
      from: null,
      to: null,
      refType: null,
      visitorId: null,
      visitors: ([] = [
        {
          id: null,
          staff_name: null,
          mobile_number: null,
          isNameDisabled: false,
        },
      ]),
      refId: null,
      userName: '',
      visitorImage: null,
    };
    this.showCamera = false;
    this.nameValidator = null;
    this.startTimeValidator = null;
    this.endTimeValidator = null;
    this.purposeValidator = null;
    this.fromValidator = null;
    this.toValidator = null;
  }

  formValidator() {

    this.nameValidator = this.common.nullValidator(
      this.newAppointment.name,
      'Name  Mandatory'
    );
    this.startTimeValidator = this.common.nullValidator(
      this.newAppointment.startTime,
      'Start Time Mandatory'
    );
    this.endTimeValidator = this.common.nullValidator(
      this.newAppointment.endTime,
      'End Time Mandatory'
    );

    this.purposeValidator = this.common.nullValidator(
      this.newAppointment.purpose,
      'Purpose Mandatory'
    );
    this.fromValidator = this.common.nullValidator(
      this.newAppointment.from,
      'From Mandatory'
    );

    if(this.refData!=null)
      {
        this.nameValidator.error= false
      }

    if (
      (!this.nameValidator.error || !this.isVisitor) &&
      !this.startTimeValidator.error &&
      !this.endTimeValidator.error &&
      !this.purposeValidator.error &&
      !this.fromValidator.error
    ) {
      this.bookAppointment();
    }
  }

  setAppointment(row){
    this.newAppointment.appointmentId= row.id
    this.newAppointment.name = row.name
    this.newAppointment.from = row.visit_from
    this.newAppointment.purpose = row.purpose
    this.newAppointment.userName = row.host_name
    this.newAppointment.endTime =  this.common.dateFormatter(
      this.convertDateFormat(row.visit_end_time),
      'YYYYMMDD',
      false
    );
    this.newAppointment.startTime =  this.common.dateFormatter(
      this.convertDateFormat(row.visit_start_time),
      'YYYYMMDD',
      false
    );
    this.newAppointment.visitors = row.visit_users
    this.newAppointment.refId = row.ref_id
    this.newAppointment.userName = row.host_name
    this.newAppointment.refType = row.ref_type
  }

  refreshDashboard(event:any){

    this.showVisits(this.activeTab);
    this.getAppointmentsCount();
  }
}




import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-user-group-association',
  templateUrl: './user-group-association.component.html',
  styleUrls: ['./user-group-association.component.scss'],
})
export class UserGroupAssociationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = {
    pagingType: 'full_numbers',
    pageLength: 5,
    lengthMenu: [5, 10, 25],
    processing: false,
    searching: false,
    paging: false,
  };
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.getCompany();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  userList = [];
  groupList = [];
  groupId: null;
  groupName: null;

  users = [
    {
      userId: null,
      userName: '',
    },
  ];

  companyId = null;
  plantId = null;
  processId = null;

  processName: null;
  companyName: null;
  plantName: null;
  companyData: any = [];
  plantData: any = [];
  activeSection: string = 'user';
  tabData: any = [
    { tabname: 'User', value: 'user' },
    { tabname: 'Staff', value: 'staff' },
  ];

  constructor(
    private alertService: AlertService,
    public common: CommonService,
    public api: ApiService,
    private plantService: PlantService,
    public breadcrumbService: BreadcrumbService,
    public role: RoleService,
    public userService: UserService
  ) {
    if (
      this.role.user_role == 'AXESTRACK' ||
      this.role.user_role == 'AXESTRACKUSER'
    ) {
      this.getCompany();
    }
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'User-Group', url: null },
      { name: 'User Group Association', url: '/user/user-group-association' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    this.getInitialLists();
  }

  ngOnInit(): void {}

  onTabChange(tabValue:any){
    this.resetData();
    this.getInitialLists();
    this.activeSection = tabValue
  }

  getInitialLists() {
    if (this.role.user_role == 'COMPANYADMIN') {
      this.companyId = this.userService._loggedInUser.companyId;
      this.getPlantById(this.companyId);
    } else if (
      this.role.user_role == 'AXESTRACKUSER' ||
      this.role.user_role == 'COMPANYUSER' ||
      this.role.user_role == 'PLANTADMIN' ||
      this.role.user_role == 'PLANTUSER'
    ) {
      this.companyId = this.userService._loggedInUser.companyId;
      this.plantId = this.userService._loggedInUser.plantId;
    }

    if (this.role.user_role != 'PLANTUSER') {
      this.getGroupsByLoginUserType();
    } else {
      this.plantId = this.userService._loggedInUser.plantId;
      this.getGroupList();
    }
  }

  getGroupsByLoginUserType() {
    this.common.loading = true;
    let params: any;
    params = {
      userType: this.role.user_role,
      company: this.companyId,
      plant: this.plantId,
    };
    this.api.get('group/groups-by-group-role-type', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.groupList = [];
          this.groupList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getCompany() {
    this.common.loading = true;
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyData = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.getPlantById(event.companyId);
    this.getGroupsByLoginUserType();
    this.userList = [];
    this.plantName = null;
    this.plantId = null;
    this.groupList = [];
    this.groupId = null;
    this.groupName = null;
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantData = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.groupId = null;
    this.userList = [];
    this.groupName = null;
    this.users = [
      {
        userId: null,
        userName: '',
      },
    ];
    if (this.role.user_role != 'PLANTUSER') {
      this.getGroupsByLoginUserType();
    } else {
      this.getGroupList();
    }
  }

  getUserList(event: any) {
    this.common.loading = true;
    let params: any;
    params = {
      groupType: event.groupType,
      companyId: this.companyId,
      plantId: this.plantId,
      userType: this.role.user_role,
    };
    this.api.get('user/user-by-plant-and-group', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.userList = res.data || [
            {
              id: null,
              name: '',
              mobileno: '',
            },
          ];
          this.getGroupUsers(event);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getStaffList(event:any) {
    let params = {
      companyId : this.companyId,
      plantId: this.plantId,
      allStaff : false
    };
    this.common.loading = true;
    this.api.get('staff/all-staff', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.userList = res.data;
          this.userList.forEach(user => {
            user.staff_name = user.first_name + ' ' + user.last_name;
            user.userName = user.first_name + ' ' + user.last_name;
            // user.userId = user.staff_id;
          });
          this.getGroupUsers(event);
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }


  getGroupList() {
    this.common.loading = true;
    let params = {
      plantId: this.plantId,
      plantType: true,
    };
    this.api.get('group/all-group', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.groupList = res.data || [];
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getGroupUsers(event: any) {
    this.groupId = event.groupId;
    this.groupName = event.groupName;
    this.common.loading = true;
    let params = {
      groupId: this.groupId,
      mappingType : this.activeSection.toUpperCase()
    };
    this.api.get('group/get-group-users-by-group-id', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.users = [
            {
              userId: null,
              userName: '',
            },
          ];
          if (res.data) {
            this.setUserDetails(res.data);
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
    if (
      event.groupType == 'PLANTUSER' ||
      event.groupType == 'COMPANYUSER' ||
      event.groupType == 'AXESTRACKUSER'
    ) {
      this.userList = this.userList.filter((p) => p.userType == event.groupType);
    }
  }

  setUserDetails(res: any = []) {
    let a: any = [];
    for (let index = 0; index < res.length; index++) {
      a[index] = {
        userId: this.activeSection=='user' ? res[index].userId : res[index].staff_id,
        userName: this.activeSection=='user' ? res[index].userName : res[index].staff_name,
      };
    }
    if (a.length)
    this.users = a;
  }
  saveAssociation() {
    let users_ids = [];
    // for (let index = 0; index < this.users.length; index++) {
    //   if (this.users[index].userId){
    //     users_ids[index] = this.users[index].userId;
    //   }
    //   else{
    //     this.alertService.error("Blank user cannot be added");
    //     return;
    //   }
    // }

    for (let index = 0; index < this.users.length; index++) {
      if (this.users[index].userId) {
          // Check if the userId already exists in the users_ids array
          if ( users_ids.includes(this.users[index].userId)) {
              this.alertService.error("Same user cannot be added to the group multiple times");
              return;
          } else {
              users_ids.push(this.users[index].userId);
          }
      } else {
          this.alertService.error("Blank user cannot be added");
          return;
      }
  }
    let params = {
      groupId: this.groupId,
      userIds: users_ids,
      mappingType : this.activeSection.toUpperCase()
    };
    this.common.loading = true;
    this.api.post('group/create_user_group_mapping', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.alertService.success(res.message);
          this.resetData();
          this.getInitialLists();
        } else {
          this.alertService.error(res.message);
        }
      },
      (err: any) => {
        this.common.loading = false;
      }
    );
  }

  addUser() {
    let user = {
      userId: null,
      userName: '',
    };
    this.users.push(user);
  }

  resetData() {
    this.groupId = null;
    this.groupName = null;
    this.users = [
      {
        userId: null,
        userName: '',
      },
    ];

    if (
      this.role.user_role == 'AXESTRACK' ||
      this.role.user_role == 'AXESTRACKUSER'
    ) {
      this.companyId = null;
    }
    if (
      this.role.user_role == 'AXESTRACK' ||
      this.role.user_role == 'AXESTRACKUSER' ||
      this.role.user_role == 'COMPANYADMIN'
    ) {
      this.companyId = null;
      this.plantId = null;
    }
    this.processId = null;
    this.processName = null;
    this.companyName = null;
    this.plantName = null;
    this.plantData = [];
    this.userList = [];
    if (this.role.user_role == 'AXESTRACK') {
      this.groupList = [];
    }
  }
}

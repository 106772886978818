<div class="login_page port_login_page">
  <video autoplay muted playsinline loop id="port_login_video">
    <source src="../../../assets/images/port-login.mp4" type="video/mp4">
    Your browser does not support HTML5 video.
  </video>

  <div class="login_block">
    <div class="login_section">
      <div class="login_box">
        <div class="port_logo">
          <img src="assets/images/logo.png">
        </div>
        <div class="welcome">Welcome Back</div>
        <!-- <div class="cpl_name">Connected Port Logistics</div> -->

        <div class="alert" [ngClass]="{'alert-danger':loginfail,'alert-success':!loginfail}" role="alert"
          *ngIf="showmessage">
          <p class="mb-0"> {{loginmessage}} </p>
        </div>

        <div class="login_form">
          <mat-form-field class="form-group col-sm-12">
            <div class="icon">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>

            <input matInput type="text" placeholder="Username" aria-label="username" aria-describedby="email"
              [(ngModel)]="user.userName">
            <div class="error" *ngIf="userNameValidator && userNameValidator.error">
              <span>{{userNameValidator.msg}} </span>
            </div>
          </mat-form-field>

          <mat-form-field class="form-group col-sm-12">
            <div class="icon">
              <i class="fa fa-key" aria-hidden="true"></i>
            </div>

            <input matInput type="password" placeholder="Password" aria-label="password" aria-describedby="password"
              [(ngModel)]="user.password">
            <div class="error" *ngIf="passwordValidator && passwordValidator.error">
              <span>{{passwordValidator.msg}} </span>
            </div>
          </mat-form-field>

          <a class="send_link" routerLink="/auth/forgot-password">forgot password</a>

          <div class="auth-action">
            <button type="submit" class="btn btn-submit" [disabled]="btndisabled" (click)="formValidator()"> login
            </button>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-center gap-2 login_options mt-3">
          <a routerLink="/auth/visitor-login" style="text-decoration: underline; color: black; font-size: 12px; cursor: pointer;">VMS Panel</a>
          |
          <a routerLink="/auth/login" style="text-decoration: underline; color: black; font-size: 12px; cursor: pointer;">Plant Panel</a>
        </div>

        <div class="powered_view ">
          <a href="https://axestrack.com/">powered by Axestrack</a>
        </div>
      </div>
    </div>

    <div class="type_sec">
      <div class="typewriter">
        <h1 class="first_line">Connected</h1>
        <h1 class="second_line">Port</h1>
        <h1 class="third_line">Logistics</h1>
      </div>
    </div>
  </div>
</div>

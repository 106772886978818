import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../theme/layout/header/alert-message/alert.service';
import { ApiService } from '../../@core/services/api.service';
import { CommonService } from '../../@core/services/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-slot-range-modal',
  templateUrl: './slot-range-modal.component.html',
  styleUrls: ['./slot-range-modal.component.scss'],
})
export class SlotRangeModalComponent implements OnInit {
  rangeList: any = [];
  range: string = null;

  constructor(
    public alertService: AlertService,
    public api: ApiService,
    public common: CommonService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.rangeList = this.common.params.stage_destination_codes;
    this.range = this.rangeList[0];
  }

  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({
      response: response,
      apiHit: apiHit,
      range: this.range,
    });
  }
}

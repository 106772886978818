import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plant-live-view-modal',
  templateUrl: './plant-live-view-modal.component.html',
  styleUrls: ['./plant-live-view-modal.component.scss'],
})
export class PlantLiveViewModalComponent implements OnInit {
  @Input() modalProcessName: string;

  constructor() {}

  ngOnInit(): void {}
}

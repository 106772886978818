<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">

            <div class="card-header header_div">
              <div class="row">

                <div class="col-md-2" *ngIf="role.user_role =='COMPANYADMIN' ">
                  <ps-auto-suggestion (onSelected)="onSelectPlant($event)" [data]="plantList" display="plantName"
                    placeholder="Select Plant" [preSelected]="{plantName:selectedPlantName}">
                  </ps-auto-suggestion>
                </div>
                <div [ngClass]="role.user_role =='COMPANYADMIN' ? 'col-md-2' : 'col-md-3'">
                  <select class="form-control" [(ngModel)]="selectedDashboardTypeId"
                    (change)="tabList=[];stagelist=[];resetUrl();resetProcess()" [ngModelOptions]="{standalone: true}"
                    [disabled]="plantParkingType!='Both'">
                    <option value="Slot_Parking">Slot Parking</option>
                    <option value="Open_Parking" >Open Parking</option>
                  </select>
                </div>
                <!-- </div> -->


                <div [ngClass]="role.user_role =='COMPANYADMIN' ? 'col-md-8' : 'col-md-9'">
                  <div class="right_suggestion">
                    <!-- slot parking inputs -->
                    <ng-container *ngIf="selectedDashboardTypeId == 'Slot_Parking'">
                      <div class="auto_suggestion">
                        <ps-auto-suggestion (onSelected)="selectParkingViewType($event);resetProcess()" [data]="parkingViewTypeList"
                          display="name" placeholder="Select Parking View Type"
                          [preSelected]="{name:selectedParkingViewType}">
                        </ps-auto-suggestion>
                      </div>

                      <div class="auto_suggestion" *ngIf="selectedParkingViewType == 'Stage'">
                        <ps-auto-suggestion (onSelected)="selectProcess($event)" [data]="processList"
                          display="processName" placeholder="Select Process" [preSelected]="{processName:selectedProcessName}">
                        </ps-auto-suggestion>
                      </div>

                      <div class="auto_suggestion" *ngIf="selectedParkingViewType == 'Zone'">
                        <ps-auto-suggestion (onSelected)="selectProcessStage($event)" [data]="process"
                          display="order_name" placeholder="Select Process -> Stage"
                          [preSelected]="{order_name : selectedOrderName}">
                        </ps-auto-suggestion>
                      </div>
                    </ng-container>

                    <!-- open parking inputs -->
                    <ng-container *ngIf="selectedDashboardTypeId == 'Open_Parking'">
                      <div class="auto_suggestion">
                        <ps-auto-suggestion (onSelected)="selectProcess($event)" [data]="processList"
                          display="processName" placeholder="Select Process" [preSelected]="">
                        </ps-auto-suggestion>
                      </div>

                      <div class="auto_suggestion">
                        <ps-auto-suggestion (onSelected)="getDestinationsByProcessStageId($event.stage_id)"
                          [data]="stagelist" display="stage_name" placeholder="Select Stage" [preSelected]="">
                        </ps-auto-suggestion>
                      </div>

                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body body_div" *ngIf="tabList.length">
              <ng-container>

                <div class="search_box">
                  <div class="search_input">
                    <input type="text" class="form-control" placeholder="Search by Vehicle No/Slot"
                      [(ngModel)]="searchFieldText">
                    <i class="fa fa-search"></i>
                  </div>
                </div>

                <div class="tab_sec">
                  <div class="tab" *ngFor="let item of tabList, index as i" (click)="tabChange(item,i);"
                    [class.active]="activeIndex == i">
                    {{item.name}}
                  </div>
                </div>

                <div class="tab_content">
                  <div class="status_wise">
                    <div class="status">
                      <div class="small_text" style="color: #3dc03d;">
                        {{ selectedDashboardTypeId === 'Open_Parking' ? 'Total Space' : 'Total Slots' }}
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.Total}} <i class="fa fa-truck" style="color: #3dc03d;"></i>
                      </div>
                    </div>

                    <div class="status">
                      <div class="small_text" style="color: #4076bd;">
                        {{ selectedDashboardTypeId === 'Open_Parking' ? 'Available Space' : 'Available Slots' }}
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.Available}} <i class="fa fa-truck" style="color: #4076bd;"></i>
                      </div>
                    </div>

                    <div class="status">
                      <div class="small_text" style="color: #c3c339;">
                        {{ selectedDashboardTypeId === 'Open_Parking' ? 'Occupied Space' : 'Occupied Slots' }}
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.Occupied}} <i class="fa fa-truck" style="color: #c3c339;"></i>
                      </div>
                    </div>

                    <div class="status">
                      <div class="small_text" style="color: #cd4040;">
                        {{'Maintenance Vehicles' }}
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.MaintenanceCount}} <i class="fa fa-truck" style="color: #cd4040;"></i>
                      </div>
                    </div>

                    <div class="status" *ngIf="selectedDashboardTypeId == 'Slot_Parking'">
                      <div class="small_text" style="color: #33bbb9;">
                        {{'Maintenance Slots' }}
                      </div>

                      <div class="status_num_graph">
                        {{maintenanceSlotCount}} <i class="fa fa-truck" style="color: #33bbb9;"></i>
                      </div>
                    </div>

                    <div class="status">
                      <div class="small_text" style="color: #608b17;">
                        OverTat Vehicles
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.OverTATCount}} <i class="fa fa-truck" style="color: #608b17;"></i>
                      </div>
                    </div>

                    <div class="status">
                      <div class="small_text" style="color: #7741d7;">
                        Average TAT
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.AverageTAT}} <i class="fa fa-truck" style="color: #7741d7;"></i>
                      </div>
                    </div>

                    <div class="status">
                      <div class="small_text" style="color: #c33979;">
                        Fitness Expired
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.FitnessExpired}} <i class="fa fa-truck" style="color: #c33979;"></i>
                      </div>
                    </div>

                    <div class="status">
                      <div class="small_text" style="color: #3ab962;">
                        Insurance Expired
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.InsuranceExpired}} <i class="fa fa-truck" style="color: #3ab962;"></i>
                      </div>
                    </div>

                    <div class="status">
                      <div class="small_text" style="color: #ab8800;">
                        PUC Expired
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.PUCExpired}} <i class="fa fa-truck" style="color: #ab8800;"></i>
                      </div>
                    </div>

                    <div class="status">
                      <div class="small_text" style="color: #c33979;">
                        RC Expired
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.RCExpired}} <i class="fa fa-truck" style="color: #c33979;"></i>
                      </div>
                    </div>

                    <div class="status">
                      <div class="small_text" style="color: #33bbb9;">
                        With Order
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.WithOrder}} <i class="fa fa-truck" style="color: #33bbb9;"></i>
                      </div>
                    </div>

                    <div class="status">
                      <div class="small_text" style="color: #815b14;">
                        Without Order
                      </div>

                      <div class="status_num_graph">
                        {{parkingDashboardData?.WithoutOrder}} <i class="fa fa-truck" style="color: #815b14;"></i>
                      </div>
                    </div>
                  </div>

                  <ng-container *ngIf="selectedDashboardTypeId == 'Slot_Parking'">
                    <div class="slot-parking" *ngFor="let parkings of parkingSlotData;let i = index;">
                      <div class="parking_row">
                        <!-- matTooltip="{{item.destination_occupancy_status}}" -->
                        <div class="vehicle_img" *ngFor="let item of parkings;"
                          [ngClass]="{
                                                    'booked_class':item.stage_workflow_status == 'INPROGRESS' && item.tag_stage_detail_status == 'IN',
                                                    'available_class': item.stage_workflow_status == 'INPROGRESS' || item.stage_workflow_status != 'MAINTAINENCE',
                                                    'maintenanceslots_class': item.destination_occupancy_status == 'MAINTENANCE',
                                                    'notavailable_class':item.stage_workflow_status == 'INPROGRESS' && (item.tag_stage_detail_status == 'HALT' || item.tag_stage_detail_status == 'IN'),
                                                    'blink_class': isMatchingStageDestinationCode(item.stage_destination_code,item.vehicle_rc_no)}"
                          [title]="item.vehicle_rc_no || ''">
                          <img src='assets/images/parking_truck_icon_green2.png'
                            *ngIf="item.stage_workflow_status == 'INPROGRESS' && item.tag_stage_detail_status == 'IN'">
                          <img src='assets/images/parking_truck_icon_red2.png'
                            *ngIf="item.stage_workflow_status == 'MAINTAINENCE'"
                            (click)="tripDetailModal(item.txnId, item.processName)">
                          <img src='assets/images/grey-3.png'
                            *ngIf="item.stage_workflow_status == 'INPROGRESS' && item.tag_stage_detail_status == 'HALT'">
                          <div class="sloat_number">{{item.stage_destination_code}}</div>
                          <div class="vehicle_num" *ngIf="item.vehicle_rc_no != null"
                            (click)="tripDetailModal(item.txnId, item.processName)">{{item.vehicle_rc_no.slice(-6)}}
                          </div>
                          <div class="vehicle_num" *ngIf="item.vehicle_rc_no == null"></div>
                        </div>

                        <div class="road">
                          <div class="border_road"></div>
                        </div>

                        <div class="instruction instruction_top"></div>

                        <div class="instruction instruction_bottom"></div>
                      </div>

                    </div>
                  </ng-container>
                  <ng-container *ngIf="selectedDashboardTypeId == 'Open_Parking'">
                    <div class="open-parking">
                      <div *ngFor="let category of parkingData; index as i"
                        [ngClass]="'notification-'+(i+1)" class="yard_col">
                        <div class="category-name">
                          <p>{{ category.value}}</p>
                        </div>

                        <div class="vehicle-boxes">
                          <ng-container *ngFor="let vehicle of parkingDataList">
                            <div class="vehicle-box" *ngIf="vehicle[category.key]==true || vehicle[category.key]=='MAINTAINENCE' || (vehicle[category.key]==null && category.value=='WithoutOrder') || (vehicle[category.key]!=null && category.value=='WithOrder') "
                              [ngClass]="{'blink_class': isMatchingStageDestinationCode(vehicle.stage_destination_code,vehicle.vehicle_rc_no)}"
                              (click)="tripDetailModal(vehicle.ipa_transaction_id, vehicle.process_name)">
                              <div class="vehicle-registration" appEllipsisView><i class="fa fa-truck"></i> {{ vehicle.vehicle_rc_no }}
                              </div>
                              <div class="vehicle-name">{{ vehicle.avg_tat }}</div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                  </ng-container>

                </div>
              </ng-container>

              <div *ngIf="selectedDashboardTypeId == 1 && !destinations.length">
                <app-data-not-found></app-data-not-found>
              </div>

              <div *ngFor="let category of openParkingVehiclesList | keyvalue">
                <div *ngIf="selectedDashboardTypeId == 0 && !category.value.length">
                  <app-data-not-found></app-data-not-found>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

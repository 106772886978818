import { transferArrayItem } from '@angular/cdk/drag-drop';
import {HttpErrorResponse,HttpEventType} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { WebcamImage } from 'ngx-webcam';
import { Base64ToFileService } from 'src/app/@core/services/base64-to-file-service.service';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemarkComponent } from 'src/app/modals/remark/remark.component';
import { LocationService } from 'src/app/@core/services/location.service';
declare var $: any;


@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
})
export class ChecklistComponent implements OnInit {
  // @ViewChild(SignatureComponent, { static: false }) signature: SignatureComponent;
  actionsAndFields: any[] = [];
  checkListTitle: string = '';
  image_url: any = '';
  image_name: string = '';
  image_ext: string = '';
  formValuesList: any = [];
  fileEvent: any = [];
  checklist_description: string = null;
  disableApprove: boolean = false;
  alertMsg: any = '';
  checklist_id: any = null;
  retakeImage: boolean = false;
  signatureImage: any = null;
  getSignature: any = null;
  selectedOption: string;
  log_id: any = null;
  perform_end_time: any = null;
  commentMessage: string = '';


  constructor(
    public common: CommonService,
    public api: ApiService,
    public breadcrumbService: BreadcrumbService,
    public user: UserService,
    public tableSerivice: TableService,
    public role: RoleService,
    public alert: AlertService,
    private route: ActivatedRoute,
    private base64ToFileService: Base64ToFileService,
    private router: Router,
    private imageCompress: NgxImageCompressService,
    private modalService: NgbModal,
    private locationService: LocationService

  ) {
    this.breadcrumbService.setActiveBreadCrumb(false);
  }

  async ngOnInit() {
    if (
      this.route.snapshot.queryParams.secret &&
      this.route.snapshot.queryParams.secretLog
    ) {
      this.checklist_id = atob(this.route.snapshot.queryParams.secret);
      this.log_id = atob(this.route.snapshot.queryParams.secretLog);
      if(!this.route.snapshot.queryParams.permissionFlag)this.checkCheckListPermission();
      else{
        let resData = await this.getCheckListActionsAndFields();
        this.checkListTitle = resData.length>0?resData[0].checklist_title:null;
        this.checklist_description = resData.length>0?resData[0].checklist_description:null;
        this.perform_end_time = atob(this.route.snapshot.queryParams.extraData);
      }
    } else {
      this.alert.error('secret key is incorrect....');
    }
  }

  checkCheckListPermission() {
    this.common.loading = true;
    let params={
      checkListId : this.checklist_id
    }
    this.api.get('checklist-dashboard/check-checklist-permission',params) .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.data[0].permission == true) {
            this.checkListTitle = res.data[0].checklist_title;
            this.checklist_description = res.data[0].checklist_description;
            this.perform_end_time = res.data[0].perform_end_time;
            this.getCheckListActionsAndFields();
          } else {
            this.alertMsg = res.data[0].message;
          }
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
        }
      );
  }

  imageName(url: any) {
    this.image_url = url;
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    this.image_name = arrays[arrays.length - 1];
    const name_array = this.image_name.split('.');
    this.image_ext = name_array[name_array.length - 1];
  }

  newTab(url: any) {
    window.open(url, '_blank');
  }

  insertLabel(field: any, index: number, ind: number) {
    if (this.formValuesList[ind].formValues[index].values.length == 0) {
      this.formValuesList[ind].formValues[index].name = field.field_name;
      this.formValuesList[ind].formValues[index].values.push(
        field.options[0].name
      );
      this.formValuesList[ind].formValues[index].fieldType = field.field_type;
      this.formValuesList[ind].formValues[index].isMandatory = field.is_mandatory;
    }
  }

  enterBooleanValue(res: string, ind: number) {
    this.formValuesList[ind].formValues[0].values = [];
    this.formValuesList[ind].formValues[0].values.push(res);
    const hasFail = this.formValuesList.some(
      (obj) =>
        obj.formValues != null &&
      obj.formValues.some(
        (formValue) =>
          formValue.values.length != 0 && formValue.values.includes('FAIL')
      )
    );
    // if (hasFail) {
    //   this.disableApprove = true;
    // } else {
    //   this.disableApprove = false;
    // }
  }

  handleImage(webcamImage: WebcamImage, field: any, i: number, ind: number) {
    this.formValuesList[ind].formValues[i].values = [];
    this.formValuesList[ind].formValues[i].name = field.field_name;
    this.onSelect(webcamImage, null, i, ind, true);
    this.formValuesList[ind].formValues[i].fieldType = field.field_type;
    this.formValuesList[ind].formValues[i]._dbField = field.database_field_name;
    this.formValuesList[ind].formValues[i].isMandatory = field.is_mandatory;
  }

  handleSingleSelectDBField(event: any, field: any, i: number, ind: number) {
    this.formValuesList[ind].formValues[i].values = [];
    this.formValuesList[ind].formValues[i].values.push(event);
    this.formValuesList[ind].formValues[i].name = field.field_name;
    this.formValuesList[ind].formValues[i].fieldType = field.field_type;
    this.formValuesList[ind].formValues[i]._dbField = field.database_field_name;
    this.formValuesList[ind].formValues[i].isMandatory = field.is_mandatory;
  }

  enterValues(event: any, field: any, i: number, ind: number) {
    this.formValuesList[ind].formValues[i].name = field.field_name;
    this.formValuesList[ind].formValues[i].fieldType = field.field_type;
    this.formValuesList[ind].formValues[i]._dbField = field.database_field_name;
    this.formValuesList[ind].formValues[i].isMandatory = field.is_mandatory;
    if (
      field.field_type != 'CHECKBOX' &&
      field.field_type != 'SELECTLISTMULTIPLE' &&
      field.field_type != 'FILE' &&
      field.field_type != 'SELECTLISTSINGLE'
    ) {
      this.formValuesList[ind].formValues[i].values = [];
      this.formValuesList[ind].formValues[i].values.push(event.target.value);

    }
    if (field.field_type == 'SELECTLISTSINGLE') {
      this.formValuesList[ind].formValues[i].values = [];
      this.formValuesList[ind].formValues[i].values.push(
        event[field.store_value]
      );
    }

    if (field.field_type == 'CHECKBOX') {
      if (event.target.checked) {
        this.formValuesList[ind].formValues[i].values.push(event.target.value);
      } else {
        let index = this.formValuesList[ind].formValues[i].values.indexOf(
          event.target.value
        );
        this.formValuesList[ind].formValues[i].values.splice(index, 1);
      }
    }

    if (field.field_type == 'SELECTLISTMULTIPLE') {
      this.formValuesList[ind].formValues[i].values = [];
      event.forEach((opt) => {
        this.formValuesList[ind].formValues[i].values.push(
          opt[field.store_value]
        );
      });
      this.formValuesList[ind].formValues[i].values = [
        ...new Set(this.formValuesList[ind].formValues[i].values),
      ];
    }

    if (field.field_type == 'FILE') {
      let methodId = field.method_id;
      this.onSelect(event, methodId, i, ind, false);
      this.formValuesList[ind].formValues[i].values = [];
    }
  }

  addJsonToFormValues(
    fields: any,
    actionId: number,
    index: number,
    action_operation_type: string,
    is_mandatory: boolean
  ) {
    let formValues: any = [];
    if (action_operation_type == 'form') {
      let size = fields.length;
      for (let i = 0; i < size; i++) {
        formValues.push({
          masterFieldId : fields[i].master_field_id,
          name: fields[i].field_name,
          fieldType: fields[i].field_type,
          isMandatory : fields[i].is_mandatory,
          values: [],
          _dbField: '',
        });
      }
    } else {
      formValues.push({
        values: [],
      });
    }
    let obj = {
      actionId: actionId,
      isMandatory: is_mandatory,
      actionOperationType: action_operation_type,
      formValues: formValues,
    };
    this.formValuesList.push(obj);
    this.saveDataBaseLinked(fields, index);
  }

  saveDataBaseLinked(fields: any, index: number) {
    fields.forEach((element) => {
      if (element.sel_value != null) {
        let i = fields.indexOf(element);
        this.formValuesList[index].formValues[i].name = element.field_name;
        this.formValuesList[index].formValues[i].values.push(element.sel_value);
        this.formValuesList[index].formValues[i].fieldType = element.field_type;
        this.formValuesList[index].formValues[i]._dbField =
          element.database_field_name;
      }
      element['multiValueOptions'] = [];
      if (element.field_type == 'SELECTLISTMULTIPLE') {
        element.options.forEach((ele) => {
          let obj = {
            option: ele.master_field_value,
          };
          element.multiValueOptions.push(obj);
        });
      }
    });
  }

  async onSelect(e, methodId, i, ind, webcamImageType) {
    let frmData = new FormData();
    let imgResultAfterCompression: DataUrl;
    if (webcamImageType == false) {
      for (const file of e.target.files) {
        frmData.append('files', file);
        this.fileEvent.inProgress = true;
        await this.uploadFile(frmData, i, ind);
      }
    } else {
      let file;
      if (ind != null) {
        let compressImg = await this.imageCompress.compressFile(
          e._imageAsDataUrl,
          -2,
          50,
          50
        ); // 50% ratio, 50% quality
        imgResultAfterCompression = compressImg;
        file = this.base64ToFileService.base64toFile(
          imgResultAfterCompression,
          'image' + i + '.jpg'
        );
        frmData.append('files', file);
        this.fileEvent.inProgress = true;
        await this.uploadFile(frmData, i, ind);
      } else {
        let compressImg = await this.imageCompress.compressFile(e, -2, 50, 50); // 50% ratio, 50% quality
        imgResultAfterCompression = compressImg;
        file = this.base64ToFileService.base64toFile(
          imgResultAfterCompression,
          'signature.jpg'
        );
        frmData.append('files', file);
        this.fileEvent.inProgress = true;
        await this.uploadFile(frmData, i, ind);
      }
    }
    // }
  }

  uploadFile(frmData: any, i: number, ind: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.post('functional/upload-file?methodId=' + 17, frmData).subscribe(
        (res: any) => {
          switch (res.data.type) {
            case HttpEventType.UploadProgress:
              this.fileEvent.progress = Math.round(
                (res.data.loaded * 100) / res.data.total
              );
              break;
            case HttpEventType.Response:
              return res.data;
          }
          res.data.forEach((e) => {
            if (ind == null) {
              this.signatureImage = e.fileUrl;
            } else {
              if (this.formValuesList[ind].formValues[i].fieldType != 'GEOCODEDIMAGE') {
                this.formValuesList[ind].formValues[i].values.push(e.fileUrl);
                // this.formValues[i].values.push(e.fileUrl);
              } else {
                const position = this.locationService._position;
                let obj = {
                  lat: position.latitude,
                  lng: position.longitude,
                  time: new Date(),
                  fileUrl: e.fileUrl,
                };
                this.formValuesList[ind].formValues[i].values.push(obj);
              }
            }
          });
          resolve(true);
        },
        (err) => {
          console.error('Error: ', err);
          this.common.loading = false;
          reject(null);
        }
      );
      catchError((error: HttpErrorResponse) => {
        this.fileEvent.inProgress = false;
        return `${this.fileEvent.data.name} upload failed.`;
      });
    });
  }

  getCheckListActionsAndFields() :Promise<any>{
    this.common.loading = true;
    let params = {
      checkListId : this.checklist_id,
    }
    return new Promise((resolve, reject) => {
    this.api.get('checklist-dashboard/get-checklist-actions-and-fields',params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          this.actionsAndFields = res.data;
          this.actionsAndFields.forEach((e, i) => {
            this.addJsonToFormValues(
              e.fields,
              e.action_id,
              i,
              e.action_operation_type,
              e.is_mandatory
            );
          });
          resolve(this.actionsAndFields);
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
          reject(null);
        }
      );
    });
  }

  save(response: string, filteredList: any, remarkResponse: any) {
    let transformedList:any;
    if(filteredList!=null){
      transformedList = filteredList.map((item) => {
        if (item.actionOperationType === 'form') {
          return {
            ...item,
            responseValue: JSON.stringify(item.formValues),
          };
        } else {
          return {
            ...item,
            responseValue:
            item.formValues != null ? item.formValues[0].values[0] : null,
          };
        }
      });
    }
    let params = {
      checkListId: this.checklist_id,
      checkListResponse: response,
      checkListActionResponsesDTO: transformedList,
      signature: this.signatureImage,
      logId: this.log_id,
      endTime: this.perform_end_time,
      lateRemark:
        remarkResponse == null ? null : JSON.stringify(remarkResponse),
    };
    this.common.loading = true;
    this.api
      .post('checklist-dashboard/save_checklist_response', params)
      .subscribe(
        (res: any) => {
          this.common.loading = false;
          if (res.data[0].saved == true) {
            this.router.navigate(['/user/checklist-dashboard']);
            this.alert.info(res.data[0].message);
          } else {
            this.alert.error(res.data[0].message);
          }
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
        }
      );
  }

  saveSignature(i: number, index: number, field: any) {
    if (this.getSignature != null) {
      this.formValuesList[index].formValues[i].values = [];
      this.onSelect(this.getSignature, null, i, index, false);
      this.formValuesList[index].formValues[i].name = field.field_name;
      this.formValuesList[index].formValues[i].fieldType = field.field_type;
      this.formValuesList[index].formValues[i]._dbField =
        field.database_field_name;
      this.formValuesList[index].formValues[i].isMandatory = field.is_mandatory;

    }
  }

  async formValidator(res: string) {
    let filteredList:any = [];
    let flag = true;
    if(res == 'PASS'){
      if(this.getSignature==null){
        this.signatureImage=null;
      }else{
        await this.onSelect(this.getSignature, null, null, null, true);
      }
      if (this.signatureImage == null) {
        this.alert.error('Please get your signature done...');
        flag = false;
        return ;
      }
      this.formValuesList.forEach((item) => {
        const isBooleanActionInvalid = item.actionOperationType === 'boolean' && item.isMandatory === 1 && item.formValues != null && item.formValues[0].values != null && item.formValues[0].values.length === 0;
        const isFormActionInvalid = item.actionOperationType === 'form' && item.isMandatory === 1 && item.formValues != null && item.formValues.some((formValue) => formValue.values.length === 0 && formValue.isMandatory === 1);
        if (isBooleanActionInvalid || isFormActionInvalid) {
            this.alert.error('Please Complete all the Fields of Mandatory Actions...');
            flag = false;
            return;
          }
        // return flag;
      });
    }else {
      this.save(res, this.formValuesList, null);
    }
  // Update formValues to null when size becomes 0
  // console.log('filteredList: ', filteredList);
  //     filteredList.forEach((item) => {
  //       if (item.formValues != null && item.formValues.length == 0) {
  //         item.formValues = null;
  //       }
  //     });
      if((res== 'PASS' && flag == true))  {
        let currentDate = new Date();
        if (currentDate > new Date(this.perform_end_time)) {
        let remarkResponse = this.actionsAndFields.map((item) => ({
          actionId: item.action_id,
          actionName: item.action_name,
        }));
        this.common.params = {
          refData: remarkResponse,
        };
        const activeModal = this.modalService.open(RemarkComponent, {
          size: 'xl',
        });
        activeModal.result.then((result) => {
          if (result.apiHit == 1) {
            this.save(res, this.formValuesList, result.result);
          }
        });
      }
      else {
        this.save(res, this.formValuesList, null);
      }
    }

  }

  openRemarkModal(){
    $('#exampleModal').modal('show');
  }

  addCommentForTrip() {
    let params = {
      entityId: this.log_id,
      entityType: 'CHECKLIST',
      commentType: 'CHECKLIST REJECTED',
      comment: this.commentMessage == '' ? 'N/A' : this.commentMessage,
      refData: JSON.stringify({
        title: '"'+this.checkListTitle + '" Failed !!',
        checklistId : this.checklist_id,
        checklistTitle : this.checkListTitle
      }),
    };
    this.api.post('comment/add-comment', params).subscribe(
      (res: any) => {
        this.formValidator('FAIL');
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
  onuploadSignature(event){
    this.getSignature = event;
  }
}

import { Router } from '@angular/router';
import { CommonService } from '../../../../@core/services/common.service';
import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  constructor(
    public breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public route: Router
  ) {}

  dashboardNavigate() {
    let url = this.route.url;
    if (url != '/user/common-dashboard' && url != '/user/dashboard') {
      this.route.navigate(['/user/common-dashboard']);
    }

    if (url == '/user/common-dashboard') {
      this.common.commondashboardNavigate = false;
      this.route.navigate(['/user/dashboard']);
    }
  }

  ngOnInit(): void {}
}

<div class="timelog">
  <div class="sidebar-label">
    <p> {{'powered_by_axestrack'|translate}} </p>
  </div>
  <form class="form-inline" action="/action_page.php">
    <div class="form-group">
      <label for="text">{{'current_time'|translate}}</label>
      <input type="text" class="form-control" autocomplete="off" readonly id="time"
        value="{{ dt1| date:'dd-MM-yyyy hh:mm:ss a'}}">
    </div>
    <div class="form-group">
      <label for="text">{{'login_time'|translate}}</label>
      <input type="text" class="form-control" autocomplete="off" readonly id="logintime"
        value="{{ userService._loginTime| date:'dd-MM-yyyy hh:mm:ss a'}}">
    </div>


  </form>

</div>

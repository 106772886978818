

<div class="modal-header" >
  <h5 class="modal-title" id="modal-basic-title">{{"checklist_history"|translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [hidden]="!checklistHistoryData.length">
    <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th>{{"serial_no" | translate}}</th>
          <th>{{("Checklist" | translate)+' '+("title"|translate)}}</th>
          <th>{{"assign_to" | translate}}</th>
          <th>{{"checklist_date" | translate}}</th>
          <th>{{"start_time" | translate}}</th>
          <th>{{"end_time" | translate}}</th>
          <th>{{"tolerance" | translate}}</th>
          <th>{{"perform_date_time" | translate}}</th>
          <th>{{"status" | translate}}</th>
          <th>{{"perform_status" | translate}}</th>
          <th>{{"Actions" | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of checklistHistoryData;index as i">
          <td>{{i+1}}</td>
          <td> {{item.checklist_title}} </td>
          <td>{{item.assigned_to_user}}</td>
          <td>{{item.checklist_date}}</td>
          <td>{{item.start_time}}</td>
          <td>{{item.end_time}}</td>
          <td>{{common.minutesToHours(item.tolerance)}}</td>
          <td>{{item.status=='Pending'?'-':item.perform_time| date:'short'}}</td>
          <td><span class="badge status-badge" style="background-color: #f7d5d5;"
                [ngClass]="{'badge-success':item.status == 'Approved','badge-danger':item.status== 'Rejected','badge-secondary':item.status== 'Pending'}">{{item.status=='Approved'?'Completed':item.status}}</span></td>
                <td *ngIf="item.perform_status=='overdue'" ><span class="badge status-badge" style="background-color: #f7d5d5;"
                  [ngClass]="{'badge-success':item.perform_status == 'on-time','badge-danger':item.perform_status== 'delay','badge-overdue':item.status== 'Pending'}">Overdue By {{item.delay}} Hrs</span></td>
                <td *ngIf="item.perform_status=='delay'" ><span class="badge status-badge" style="background-color: #f7d5d5;"
                  [ngClass]="{'badge-danger':item.perform_status== 'delay'}">delay by {{item.delay}} Hrs</span></td>
                <td *ngIf="item.perform_status=='on-time'" ><span class="badge status-badge" style="background-color: #f7d5d5;"
                  [ngClass]="{'badge-success':item.perform_status=='on-time'}">Early by {{item.delay}} Hrs</span></td>
              <td ><span *ngIf="item.status=='Approved'" style="display: flex;"><button class="btn btn-primary" (click)="viewCheckList(item)">{{"view"|translate}}</button><button *ngIf="item.late_remark_id!=null" class="btn btn-danger" style="
                background-color: #e85151; margin-left: 50px;" (click)="viewRemark(item)"> {{"remark" | translate}} </button></span>
                <span *ngIf="item.status=='Rejected'"  style="display: flex;"><button class="btn btn-info" (click)="viewRejectedRemark(item.log_id,item.checklist_title)">{{"Reject Remark"|translate}}</button><button *ngIf="item.late_remark_id!=null" class="btn btn-danger" style="
                background-color: #e85151; margin-left: 11px;" (click)="viewRemark(item)"> {{" remark" | translate}} </button></span></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="action_data_div" *ngIf="this.checklistHistoryData.length==0">
    <p style="color:red;display: flex;justify-content: center;">No Record Found !!</p>
  </div>
</div>

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="tab_section">
        <ng-container *ngFor="let tabitem of tabData">
          <div class="tab" [class.active]="activeTab.value == tabitem.value" (click)="onTabSelect(tabitem)">
            <p>{{tabitem.tabname|customTranslate}}</p>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header mb-1">
              <div class="header-block">
                <h3 class="card-title">
                  {{activeTab.title | customTranslate}}
                </h3>
              </div>
              <div class="form-group col-3 flex justify-content-end">
                <input type="file" class="custom-file-input" id="validatedInputGroupCustomFile" accept=".csv"
                  (input)="uploadFile($event)" required #uploadFileDiv style="width:0px!important;height:0px!important">
                <button type="submit" class="btn btn-success mr-1" style="float: right;" (click)="uploadFileDiv.click()">
                  <i class="fa fa-upload add"></i> {{"Upload Bulk Data" | customTranslate}} </button>

                <a [href]="activeTab.sample" download>
                  <button class="btn btn-warning">Sample
                    <i class="fa fa-download"></i>
                  </button>
                </a>
              </div>
            </div>
            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="activeTab.value == 'DO'">
                  <label for="my-input" [class.required]="true">{{"Vehicle No." | customTranslate}}</label>
                  <input type="text" class="form-control" name="vehicle_rc" placeholder="Enter Vehicle No."
                    [(ngModel)]="dataEntry.vehicleRc" autocomplete="off">
                  <!-- <div class="error" *ngIf="staffLastNameValidator && staffLastNameValidator.error">
                    <span>{{staffLastNameValidator.msg}}</span>
                  </div> -->
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="activeTab.value == 'DO'">
                  <label for="my-input" [class.required]="true">{{"DO No." | customTranslate}}</label>
                  <input type="text" class="form-control" name="DO_NO" placeholder="Enter DO No."
                    [(ngModel)]="dataEntry.dispatchOrder" autocomplete="off">
                  <!-- <div class="error" *ngIf="staffLastNameValidator && staffLastNameValidator.error">
                    <span>{{staffLastNameValidator.msg}}</span>
                  </div> -->
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" [class.required]="true">{{"Invoice No." | customTranslate}}</label>
                  <input type="text" class="form-control" name="invoice_no" placeholder="Enter Invoice No."
                    [(ngModel)]="dataEntry.invoice" autocomplete="off">
                  <!-- <div class="error" *ngIf="staffLastNameValidator && staffLastNameValidator.error">
                    <span>{{staffLastNameValidator.msg}}</span>
                  </div> -->
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" [class.required]="true">{{"Product" | customTranslate}}</label>
                  <input type="text" class="form-control" name="product" placeholder="Enter Product"
                    [(ngModel)]="dataEntry.material" autocomplete="off">
                  <!-- <div class="error" *ngIf="staffLastNameValidator && staffLastNameValidator.error">
                    <span>{{staffLastNameValidator.msg}}</span>
                  </div> -->
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="activeTab.value != 'DO'">
                  <label for="my-input" [class.required]="true">{{"Quantity Unit" | customTranslate}}</label>
                  <input type="text" class="form-control" name="quantityUnit" placeholder="Enter Quantity Unit"
                    [(ngModel)]="dataEntry.quantityUnit" autocomplete="off">
                  <!-- <div class="error" *ngIf="staffLastNameValidator && staffLastNameValidator.error">
                    <span>{{staffLastNameValidator.msg}}</span>
                  </div> -->
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" [class.required]="true">{{"Quantity" | customTranslate}}</label>
                  <input type="number" class="form-control" name="qauntity" placeholder="Enter Quantity"
                    [(ngModel)]="dataEntry.quantity">
                  <!-- <div class="error" *ngIf="staffLastNameValidator && staffLastNameValidator.error">
                    <span>{{staffLastNameValidator.msg}}</span>
                  </div> -->
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label class="required">{{"Validity Date" | customTranslate}}</label>
                  <input class="form-control" type="date" required [(ngModel)]="dataEntry.validityDate"
                    name="validityDate">
                  <!-- <div class="error" *ngIf="startTimeValidator && startTimeValidator.error">
                    <span>{{startTimeValidator.msg}} </span>
                  </div> -->
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" [class.required]="true">{{"Client Name" | customTranslate}}</label>
                  <input type="text" class="form-control" name="client" placeholder="Enter Client Name"
                    [(ngModel)]="dataEntry.client" autocomplete="off">
                  <!-- <div class="error" *ngIf="staffLastNameValidator && staffLastNameValidator.error">
                    <span>{{staffLastNameValidator.msg}}</span>
                  </div> -->
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input">{{"client_email" | customTranslate}}</label>
                  <input type="email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" class="form-control"
                    autocomplete="off" placeholder="Enter Email" [(ngModel)]="dataEntry.email" required name="email"
                    #email=ngModel>
                  <!-- <div class="error" *ngIf="emailValidator && emailValidator.error">
                    <span>{{emailValidator.msg}} </span>
                  </div> -->
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{activeTab.value +" Type" | customTranslate}}</label>
                  <select name="invoicetype" id="#invoicetype" class="form-control" [(ngModel)]="dataEntry.type">
                    <option selected="INDIVIDUAL" value="INDIVIDUAL">INDIVIDUAL</option>
                    <option value="AGGREGATED">AGGREGATED</option>
                  </select>
                </div>
              </div>
              <div class="form-group action-block">
                <div class="btn-block">
                  <button class="btn btn-warning" (click)="resetDetail()">{{"reset" | customTranslate}}</button>
                  <button type="submit" class="btn btn-submit" (click)="saveDataEntry()"> {{btn}} </button>
                </div>
              </div>
            </div>
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">{{activeTab.value +" List" | customTranslate}}</h3>
                <!-- <div class="table_sec"> -->
                <!-- </div> -->
              </div>
            </div>
            <div class="card-body">
              <app-custom-table [data]="data" [columns]="columns"
                [tableType]="TABLE_TYPE"></app-custom-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

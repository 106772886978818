import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { DateWiseVisitorReportDataByVisit } from 'src/app/dataType/visitorInterface';
import { TABLE_TYPE, TableColumns} from 'src/app/dataType/customTableInterface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-visit-date-wise-report-modal',
  templateUrl: './visit-date-wise-report-modal.component.html',
  styleUrls: ['./visit-date-wise-report-modal.component.scss','../visit-date-wise-component/visit-date-wise-component.component.scss']
})
export class VisitDateWiseReportModalComponent implements OnInit {

  @Input() refData;
  TABLE_TYPE = TABLE_TYPE.DATATABLE;
  loading: boolean = false;
  tableColumns: TableColumns[] = [];
  dateWiseVisitorReportDataByVisit: DateWiseVisitorReportDataByVisit[];
  reportDisplayList: DateWiseVisitorReportDataByVisit[]
  processName: string = null;
  processId: number = -1;
  plantId: number = null;
  tripStatus: string;
  processFilterName:string;
  modalprocessFilterList:DateWiseVisitorReportDataByVisit;
  constructor(public common: CommonService,
             public api: ApiService,
             private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.getVisitorReport()
  }

  getVisitorReport() {
    this.loading = true;
    let now = new Date();
    let dateOnly = now.toISOString().split('T')[0];

   let params={
      fromDate: this.refData.visit.date,
      processFilterList: (this.refData.processFilterList==null || this.refData.processFilterList.length==0)?[-1]:this.refData.processFilterList
    }

    this.api.get('visitors/get-visit-date-wise-report-by-visit', params).subscribe(
      (res: any) => {
        this.common.loading = false;
          this.dateWiseVisitorReportDataByVisit=res.data
          this.reportDisplayList= this.dateWiseVisitorReportDataByVisit
          console.log('this.reportDisplayList: ', this.reportDisplayList);

          this.tableColumns = [
            {
              columnLabel: "Visit Start Date",
              columnName: "start_time",
            },
            {
              columnLabel: "Visit End Date",
              columnName: "end_time",
              columnFormatter: (column: TableColumns, index: number) => {
                return column.columnLabel
              }
             }

            ,
            {
              columnLabel: "Visitor Name",
              columnName: "name",
            },
            {
              columnLabel: "Process Name",
              columnName: "process_name",
            },
            {
              columnLabel: "Alert Count",
              columnName: "alert_count",
            },
            {
              columnLabel: "Restricted Count",
              columnName: "restricted_count",
            },
             {
              columnLabel: "Over TAT",
              columnName: "",
              dataFormatter: (row: any, column: TableColumns, index: number) => {
                return (`<span class="${row.trip_tat > row.expected_tat ? 'badge over' : 'badge'}">${row.trip_tat}</span> /
                          <span class="badge info">${row.expected_tat}</span>
                        `)
              }

            }
          ]
          this.loading = false;
console.log('rrr',this.tableColumns)
      },
      (err: any) => {
        this.common.loading = false;
      }
    );


  }

  filterDataByCondition(condition: string){
    if(condition=='ALERT')

    {this.reportDisplayList=this.dateWiseVisitorReportDataByVisit.filter(data=>data.alert_count>0)
    }

    if(condition=='RESTRICTED')

    this.reportDisplayList=this.dateWiseVisitorReportDataByVisit.filter(data=>data.restricted_count>0)

    if(condition=='ALL')
    this.reportDisplayList= this.dateWiseVisitorReportDataByVisit

  }
}


import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { DriverTrackerComponent } from '../driver-tracker/driver-tracker.component';
import { VehicleDetailComponent } from '../vehicle-detail/vehicle-detail.component';
import { UserTrackingInfoModalComponent } from 'src/app/modals/user-tracking-info-modal/user-tracking-info-modal.component';



@Component({
  selector: 'app-dashboard-modal',
  templateUrl: './dashboard-modal.component.html',
  styleUrls: ['./dashboard-modal.component.scss']
})
export class DashboardModalComponent implements OnInit {

  @Input() public refData;


  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any;

  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  constructor(
    public common: CommonService,
    public table: TableService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal

  ) {

  }

  ngOnInit() {
    this.dtOptions=this.table.options(15, this.refData.header.length, 'Manufacturer List');
  }

  closeModal() {
    this.activeModal.close();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  modalTitle:string='';
  tableModalData:any[]=[];
  modalHide:boolean=false;



  tripTrackingModal(vehicle) {
    let activeModal = this.modalService.open(DriverTrackerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    this.modalHide=true;
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.txnid,
      vehicleName: vehicle.vehicle_rc_no + '-' + vehicle.process_name,
    };
    activeModal.result.finally(()=>{
      this.modalHide=false;
    }).catch((e)=>{
    })
  }

  tripModal(vehicle) {
    let activeModal = this.modalService.open(VehicleDetailComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
    });
    this.modalHide=true;
    activeModal.componentInstance.refData = {
      showHeader: false,
      txnId: vehicle.txnid,
      processName: vehicle.process_name,
      processId: vehicle.process_id,
    };

    activeModal.result.finally(()=>{
      this.modalHide=false;
    }).catch((e)=>{
    })

  }

  openUserTrackingModal(userId:number,userType:string){
    this.common.params = {
      userId:userId,
      userType:userType
  }
    let activeModal = this.modalService.open(UserTrackingInfoModalComponent, {
      size: 'xl',
    });
    this.modalHide=true;
    activeModal.result.finally(()=>{
      this.modalHide=false;
    }).catch((e)=>{
    })
}





}

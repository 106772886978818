import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';

@Injectable({
  providedIn: 'root'
})

export class AssetCategoryServiceService extends ApiService {

  saveAssetCategory(data:any):Observable<any>{
    return super.post("asset_categories/create-category",data);
  }

  getAssetCategory(params:any):any{
    return super.get("asset_categories/get-all-categories",params);
  }
}

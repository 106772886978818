import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from 'ngx-qrcode2';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { CustomCommunicationComponent } from 'src/app/modals/custom-communication/custom-communication.component';

@Component({
  selector: 'app-checklist-qr-modal',
  templateUrl: './checklist-qr-modal.component.html',
  styleUrls: ['./checklist-qr-modal.component.scss'],
})
export class ChecklistQrModalComponent implements OnInit {
  @ViewChild('qrcode', { static: false }) qrcode: ElementRef;
  @Input() public refData;
  pageUrl: string;
  qrCodeImage: string;
  qrCodeType: NgxQrcodeElementTypes = NgxQrcodeElementTypes.CANVAS;
  error: NgxQrcodeErrorCorrectionLevels = NgxQrcodeErrorCorrectionLevels.HIGH;
  title:string = "Checklist QR";
  constructor(
    public activeModal: NgbActiveModal,
    public common: CommonService,
    public api : ApiService,
    public modalService: NgbModal
  ) {}
  handleCommunication(communicationType:string){
    console.log("Inside handleCommunication",communicationType)
    this.closeModal();
    const activeModal = this.modalService.open(CustomCommunicationComponent, {
      size: 'md',
    });
    activeModal.componentInstance.refData = {
      communicationType:communicationType,
      content: this.refData.url
    }
  }
  closeModal(response?: any, apiHit?: any) {
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  printQRCode() {
    const qrCodeElement = this.qrcode.nativeElement;
    this.qrCodeImage = qrCodeElement
      .querySelector('canvas')
      .toDataURL('image/png');

    const windowContent = '<!DOCTYPE html>';
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.open();
    printWindow.document.write(
      `${windowContent}<html><head><title>Print Q R Code</title></head><body><img src="${this.qrCodeImage}" style="width: 100%;"></body></html>`
    );
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      printWindow.close();
    };
  }

  ngOnInit(): void {
    if(this.refData.url == null){
      this.pageUrl =this.api.MAIN_URL + 'checklist?secret=' + this.refData.encodedKey;
    }else {
      this.pageUrl = this.refData.url;
      this.title = this.refData.title == null ? "QR Code" : this.refData.title;
    }
  }
  ngAfterViewInit(): void {}
}

import { ApiService } from 'src/app/@core/services/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { TableService } from 'src/app/@core/services/table.service';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/@core/services/common.service';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AssetRequestComponent } from 'src/app/components/asset-request/asset-request.component';
import { AssetRequestRemarkComponent } from 'src/app/components/asset-request-remark/asset-request-remark.component';
import { Request } from 'src/app/dataType/assetInterface';

@Component({
  selector: 'app-add-asset-request',
  templateUrl: './add-asset-request.component.html',
  styleUrls: ['./add-asset-request.component.scss']
})
export class AddAssetRequestComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'ASSET REQUESTS LIST');



  startDate: any = new Date(new Date().setDate(new Date().getDate() - 7));
  endDate: any = new Date();
  requests: Request[] = [];
  activeTab: number = 3;
  activeStatus: string = 'PENDING';
  listActionCount: Record<string,any>;


  constructor(public api: ApiService, public router: Router, public common: CommonService, private alert: AlertService, private modalService: NgbModal, public table: TableService, private breadcrumbService: BreadcrumbService) {
    /** breadcums implementation start*/
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'assets', url: null },
      { name: 'Asset Request', url: '/user/asset-request' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    this.getAssetRequestCount()
  }

  ngOnInit(): void {
    this.getAssetRequests(this.activeStatus);
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  openAssetRequestModal() {
    const activeModal = this.modalService.open(AssetRequestComponent, {
      size: 'lg',
    });
    activeModal.result.then(() => {
      this.getAssetRequests(this.activeStatus);
      this.getAssetRequestCount();
    });
  }

  getAssetRequests(status: any) {
    this.common.loading=true;
    let params={
      status:status,
      startDate:this.startDate,
      endDate:this.endDate
    }
    this.api.get('asset_requests/get-asset-requests-of-user',params).subscribe(
      (res: any) => {
        this.common.loading=false;
        this.requests = res.data;
        this.renderTable();
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  updateRequestStatus(reqId: number) {
    this.common.loading=true;
    let params = {
      status:'DELETED',
      requestId:reqId
    }
    this.api.post('asset_requests/update-request-status',params).subscribe(
      (res: any) => {
        this.common.loading=false;
        this.alert.info("Request Declined Successfully !!")
        this.getAssetRequests(this.activeStatus);
        this.getAssetRequestCount();
      },
      (err: any) => {
        console.error('Error: ', err);
      }
    );
  }

  viewRemarks(row: any) {
    const activeModal=this.modalService.open(AssetRequestRemarkComponent,{
      size:'xl'
    })
    activeModal.componentInstance.refData={
      request_id:row.asset_request_id,
      calling:false,
      from_page:true
    }
  }


  linkCopy(row:any){
    const secretKey = btoa(row.asset_request_id);
    const calling = this.router.url;
    const link=`${window.location.origin}/#/user/request-info?secretKey=${secretKey}&calling=${calling}`;
    navigator.clipboard.writeText(link).then(() => {
      this.alert.success('Link copied to clipboard');
      console.log('Link copied to clipboard:', link);
    }).catch(err => {
      this.alert.error('Failed to copy the link');
      console.error('Failed to copy the link:', err);
    });
  }


  getAssetRequestCount() {
    this.common.loading = true;
    let params = {
      status: true
    }
    this.api.get('asset_requests/get-asset-request-count', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.listActionCount = res.data.reduce((acc,ord)=>{
            acc[ord.status_name]=ord.count;
            return acc
          },{} as Record<string,any> );
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }
}

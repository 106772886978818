<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'method_mapping'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'AXESTRACK'">
                  <label for="my-input" class="required">{{'company'|customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedCompany($event)" [data]="company" display="companyName"
                    placeholder="Choose Company" [preSelected]="{companyName:companyName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="companyValidator && companyValidator.error">
                    <span>{{companyValidator.msg}} </span>
                  </div>
                </div>

                <div *ngIf="role.user_role == 'AXESTRACK'||role.user_role == 'COMPANYADMIN'" class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'plant'|customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                    placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{plantNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-4 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'mapping_type'|customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedMapping($event)" [data]="mappingData" display="mappingName"
                    placeholder="Choose Mapping" [preSelected]="{mappingName:selectedMappingName}">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-4 col-lg-6 col-sm-12" *ngIf="selectedMappingName == 'Stage'">
                  <label for="my-input" class="required">{{'process'|customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectedProcess($event)" [data]="processList" display="processName"
                    placeholder="Choose process" [preSelected]="{processName:processName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error">
                    <span>{{processValidator.msg}} </span>
                  </div>
                </div>


                <div class="form-group col-xl-4 col-lg-6 col-sm-12" *ngIf="selectedMappingName == 'Stage'">
                  <label for="my-input">{{'Stage'|customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedStage($event)" [data]="stages" display="stage_name"
                    placeholder="Choose Stage" [preSelected]="{stage_name:selectedStageName}">
                  </ps-auto-suggestion>
                </div>

                <div class="form-group col-xl-4 col-lg-6 col-sm-12" *ngIf="selectedMappingName == 'Action'">
                  <label for="my-input" class="required"> {{"process->stage" | customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedProcessStage($event)" [data]="processStageOrder"
                    display="order_name" placeholder="Choose Process Stage"
                    [preSelected]="{order_name:processOrderName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error">
                    <span>{{processValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-4 col-lg-6 col-sm-12" *ngIf="selectedMappingName == 'Action'">
                  <label for="my-input">{{'action'|customTranslate}}</label>
                  <ps-auto-suggestion (onSelected)="selectedAction($event)"
                    [data]="actionsList" display="actionname" placeholder="Choose  Action"
                    [preSelected]="{actionname:selectedActionName}">
                  </ps-auto-suggestion>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="row yard_div">
                    <ng-container
                      *ngFor="let item of (selectedMappingName == 'Stage' ? dataMethodNameStage : dataMethodNameAction); let j = index">
                      <div [class]="'col-lg-' + (selectedMappingName == 'Stage' ? 6 : 4)">
                        <div class="column_view">
                          <div class="title" [style]="item.style">
                            {{item.methodName|customTranslate}}
                          </div>

                          <div class="box_column" [style]="item.border" for="my-input" cdkDropList
                            (cdkDropListDropped)="onDrop($event,j)">
                            <div class="box mb-1 " *ngFor="let value of masterField[j]; let i=index" cdkDragLockAxis="y"
                              cdkDrag>
                              <ps-auto-suggestion class="custom_input" (onSelected)="selectedMethod($event,item,i,j)"
                                [data]="methodsList" display="methodName" placeholder="Select  Method"
                                [preSelected]="{methodName:(value.methodName)}">
                              </ps-auto-suggestion>
                              <div class="plus_minus_icon">
                                <span class="icon_minus fa fa-trash remove" id="my-addon"
                                  *ngIf="masterField[j].length > 1" (click)="masterField[j].splice(i,1)"></span>
                                <span class="icon_plus fa fa-plus add" id="my-addon"
                                  *ngIf="i == masterField[j].length-1" (click)="addValues(j)"></span>
                                <span class="fas fa-arrows-alt-v drag_drop_box"
                                  *ngIf="masterField[j].length > 1"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="col-lg-12 form-group action-block">
                  <div class="btn-block">
                    <button type="submit" class="btn btn-muted" (click)="common.backClicked()"> {{'back'|customTranslate}}
                    </button>
                    <button class="btn btn-warning" (click)="resetDetail()"> {{'reset'|customTranslate}} </button>
                    <button type="submit" class="btn btn-submit" (click)="saveData()"> {{btn | customTranslate}} </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'method_mapping'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <!-- table -->
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{'serial_no'|customTranslate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK'">{{'company_name' | customTranslate}}</th>
                    <th *ngIf="role.user_role == 'AXESTRACK'">{{'plant_name' | customTranslate}}</th>
                    <th>{{('process'|customTranslate)+' '+('name'|customTranslate)}}</th>
                    <th>{{'stage_name'|customTranslate}}</th>
                    <th>{{'action_name'|customTranslate}}</th>
                    <th>{{'pre_methods'|customTranslate}}</th>
                    <th>{{'on_approve method'|customTranslate}}</th>
                    <th>{{'on_reject method'|customTranslate}}</th>
                    <th class="action"> {{'Actions'|customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of methodMappingList;let i=index">
                    <td>{{i+1}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK'">{{row.company_name}}</td>
                    <td *ngIf="role.user_role == 'AXESTRACK'">{{row.plant_name}}</td>
                    <td>{{row.process_name}}</td>
                    <td>{{row.stage_name}}</td>
                    <td>{{row.action_name}}</td>
                    <td><ng-container *ngFor="let item of row.on_pre;let k=index">{{item.methodName+(row.on_pre.length-1
                        == k ? "":",")}}</ng-container>{{row.on_pre.length == 0 ? '-'
                      : ""}}</td>
                    <td><ng-container
                        *ngFor="let item of row.on_approve;let k=index">{{item.methodName+(row.on_approve.length-1 == k
                        ? "":",")}}</ng-container>{{row.on_approve.length ==
                      0 ? '-' : ""}}</td>
                    <td><ng-container
                        *ngFor="let item of row.on_reject;let k=index">{{item.methodName+(row.on_reject.length-1 == k ?
                        "":",")}}</ng-container>{{row.on_reject.length == 0
                      ? '-' : ""}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewDetails(row)"> {{'view'|customTranslate}} </button>
                        <button class="btn btn-success" (click)="editRow(row)"> {{'edit'|customTranslate}} </button>
                        <button class="btn btn-danger" (click)="confirmAlert(row)"> {{'delete'|customTranslate}}</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

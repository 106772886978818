import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class StageDestinationCodeTypeService extends ApiService {

  saveStageDestinationCodeType(data: any): Observable<any> {
    let apiSubUrl = "destination/save_stage_destination_code_type";

    return super.post(apiSubUrl, data);
  }
  getAllStageDestinationCodeType(data:any): Observable<any> {
    let apiSubUrl = "destination/list_stage_destination_code_type";

    return super.get(apiSubUrl,data);
  }

}

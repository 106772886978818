<div class="container-fluid">
    <div class="container-card">
      <div class="container-card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="header-block">
                  <h3 class="card-title">
                    {{'user tracking report'|customTranslate}}
                  </h3>
                </div>
              </div>

              <div class="card-body">
                <div class="page_design">
                  <div class="row tab_sec m-0">
                    <div class="col-md-6 col-12 date_div p-0">
                      <div class="row">
                          <div class="form-group col-lg-6 col-sm-12">
                            <label for="my-input">{{'Date'|customTranslate}}</label>
                            <input class="form-control" type="date" name="fromDate" max="{{currentDateTime | date:'yyyy-MM-dd'}}"
                              [(ngModel)]="activeDate" (change)="getUserTrackingLogData()">
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="view_table">
                    <div class="filter_sec">
                      <div class="row">
                        <!-- <div class="col-md-3 coll" *ngIf="role.user_role=='COMPANYADMIN'" class="form-group col-md-3">
                          <label for="my-input" class="required">{{"plant" | customTranslate}}</label>
                          <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                            placeholder="Choose Plant" [preSelected]="{plantName:plantName}">
                          </ps-auto-suggestion>
                        </div> -->
                        <div class="col-md-3 coll">
                          <label>{{'select zone:'|customTranslate}}</label>
                          <ps-auto-suggestion (onSelected)="selectZone($event)" [data]="zoneList" isMultiSelect="true"
                            display="zone_name" placeholder="Select Zone" [preSelected]="temp">
                          </ps-auto-suggestion>
                        </div>
                        <div class="col-md-3 coll">
                          <label>{{'select stage:'|customTranslate}}</label>
                          <ps-auto-suggestion (onSelected)="selectStage($event)" [data]="stageList" display="stage_name" isMultiSelect="true"  placeholder="Select Stage" [preSelected]="temp1">
                          </ps-auto-suggestion>
                        </div>
                      </div>
                    </div>

                    <div class="form-group action-block mt-2">
                      <div class="btn-block">
                        <button type="button" class="excel-btn excel"
                        (click)="exportAsExcel(responseData)">
                        <i class="fas fa-file-excel"></i>
                        </button>
                        <button class="btn btn-warning" (click)="resetPage()"> {{'reset'|customTranslate}} </button>
                      </div>
                    </div>

                    <div class="table_sec">
                      <table class="custom-table" >
                        <thead>
                          <tr>
                            <th>S. No.</th>
                            <th>{{'username'|customTranslate}}</th>
                            <th>{{'Plant In'|customTranslate}}</th>
                              <th *ngFor="let item of headers">{{item[headerKey]}}</th>
                              <th>{{'N/A'}}</th>
                            <th>{{'Plant Out'|customTranslate}}</th>
                            <th>{{'Productive Hours'|customTranslate}}</th>
                            <th>{{'Non-Productive Hours'|customTranslate}}</th>
                            <th>{{'Untracked Hours'|customTranslate}}</th>
                            <th>{{'Total TAT'|customTranslate}}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr *ngFor="let item of responseData;let i=index">
                             <td>{{i+1}}</td>
                             <td>{{item.username}}</td>
                             <td>{{item.start_time}}</td>
                               <td *ngFor="let dest of headers">
                                 <span [ngClass]="item.tat_data[dest[valueKey]]?.zone_allocation=='not assigned'?'badge over': 'badge info'">{{item.tat_data[dest[valueKey]]?.spend_hours ?? '00:00:00'}}</span>
                                </td>
                                <td> <span class="badge info">{{item.tat_data[0]?.spend_hours ?? '00:00:00'}}</span></td>
                            <td>{{item.end_time}}</td>
                            <td><span class="badge info">{{item.productive_hours}}</span></td>
                            <td><span [ngClass]="item.non_productive_hours!='00:00:00'?'badge over': 'badge info'">{{item.non_productive_hours}}</span></td>
                            <td><span class="badge lightGray">{{item.untracked_hours}}</span></td>
                             <td>{{item.total_tat}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

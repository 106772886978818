
<div class="modal-content" [hidden]="modalHide">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{refData.modalTitle}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table_section">
      <div class="table-responsive" *ngIf="refData.tableModalData.length > 0">
        <table class="table table-bordered" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th style="color: black !important;" *ngFor="let headers of refData.header">{{headers}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let vehicle of refData.tableModalData">
              <ng-container *ngFor="let keys of refData.keys">
              <td *ngIf="keys == 'stage_workflow_status'"><span [ngClass]="[vehicle[keys].replace(' ','_')] + ' jelly-bean'" >{{vehicle[keys]}}</span></td>
              <td *ngIf="keys == 'status'"><span [ngClass]="[vehicle[keys].replace(' ','_')] + ' jelly-bean'" >{{vehicle[keys]}}</span></td>
              <td *ngIf="keys == 'tat'"><span class="info-jellybean">{{vehicle[keys]}}</span></td>
              <td *ngIf="keys != 'stage_workflow_status' && keys!='tat' && keys!='status'">{{vehicle[keys]}}</td>
              </ng-container>
              <!-- <td *ngFor="let keys of refData.keys">{{vehicle[keys]}}</td> -->
              <td>
                <div>

                  <img src="assets/images/trip-details(3).png" alt="" title="Trip Detail" matTooltipPosition="below" (click)="tripModal(vehicle)">
                  <img src="assets/images/trip-tracking(3).png" alt="" title="Trip Tracking" matTooltipPosition="below" (click)="tripTrackingModal(vehicle)" style="margin-left: 10px;">
                  <img  *ngIf="refData.modalType=='Visit'" src="assets/images/tracking-log.png"  alt="" title="Trip Tracking" matTooltipPosition="below" (click)="openUserTrackingModal(vehicle.user_id,vehicle.user_type)" style="margin-left: 10px;height:18px;margin-right: 4px;">
                  <!-- <i class="fa fa-location-arrow" alt="" title="Trip Tracking" matTooltipPosition="below" (click)="tripTrackingModal(vehicle)"></i>
                  <i class="fa fa-info-circle" alt="" title="Trip Detail" matTooltipPosition="below" (click)="tripModal(vehicle)"></i> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="refData.tableModalData.length === 0 && refData.modalType=='User'" class="text-center mt-1 fade-in" style="font-weight: 600; color: #cd4040; font-size: larger;">
        No Trips Available!
      </div>
      <div *ngIf="refData.tableModalData.length === 0 && refData.modalType=='Visit'" class="text-center mt-1 fade-in" style="font-weight: 600; color: #cd4040; font-size: larger;">
        No visits Available!
      </div>
    </div>
  </div>
</div>

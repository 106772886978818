import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DriverTrackerComponent } from 'src/app/components/driver-tracker/driver-tracker.component';
import { VehicleDetailComponent } from 'src/app/components/vehicle-detail/vehicle-detail.component';
import { COMMON_ACTION_BUTTON, COMMON_TRIPS_ACTION_BUTTON, TableActionCallback, TableActions, TableColumns } from 'src/app/dataType/customTableInterface';
import { TripCommentsComponent } from 'src/app/modals/trip-comment-trail/trip-comments/trip-comments.component';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(private translateService: TranslateService,
    private modalService: NgbModal) { }

  options(length: number, exportnumber: number, title?: any, columnDefs: any = [], orderByColumnRank: number = 0, order: string = "asc") {
    let tableTitle = title ? title.toUpperCase() : 'CPL';
    let arr: number[] = [];
    let i: number = 0;
    for (i = 0; i < exportnumber; i++) { arr.push(i); }
    let exportsOptions = {
      pagingType: 'full_numbers',
      pageLength: length,
      order: [[orderByColumnRank, order]],
      lengthMenu: [5, 10, 15, 25, 50],
      processing: true,
      dom: 'Blfrtip',
      buttons: [
        {
          extend: 'copy',
          text: '<i class="fa fa-clipboard" aria-hidden="true"></i>',
          title: tableTitle,
          exportOptions: {
            columns: arr,
          }
        },
        {
          extend: 'csv',
          text: '<i class="fas fa-file-csv"></i>',
          title: tableTitle,
          exportOptions: {
            columns: arr
          }
        },
        {
          extend: 'excel',
          text: '<i class="fa fa-file-excel" aria-hidden="true"></i>',
          title: tableTitle,
          exportOptions: {
            columns: arr
          }
        },
        {
          extend: 'print',
          text: '<i class="fa fa-print" aria-hidden="true"></i>',
          title: tableTitle,
          exportOptions: {
            columns: arr
          }
        },
        {
          extend: 'colvis',
          text: '<i class="fa fa-bars" aria-hidden="true"></i>',
          columns: ':not(:first)', // Ensure at least one column always visible,
        }
      ]
    }
    return exportsOptions;
  }

  tmgoptions(length: number, exportnumber: number) {
    let arr: number[] = [];
    let i: number = 0;
    for (i = 0; i < exportnumber; i++) { arr.push(i); }
    let exportsOptions = {
      pagingType: 'full_numbers',
      pageLength: length,
      lengthMenu: [5, 10, 25, 50],
      processing: true,
      // dom: 'Bfrt',
      dom: '',
      buttons: [
        {
          extend: 'copy',
          text: '<i class="fa fa-clipboard" aria-hidden="true"></i>',
          exportOptions: {
            columns: arr,
          }
        },
        {
          extend: 'csv',
          text: '<i class="fas fa-file-csv"></i>',
          exportOptions: {
            columns: arr
          }
        },
        {
          extend: 'excel',
          text: '<i class="fa fa-file-excel" aria-hidden="true"></i>',
          exportOptions: {
            columns: arr
          }
        },
        {
          extend: 'print',
          text: '<i class="fa fa-print" aria-hidden="true"></i>',
          exportOptions: {
            columns: arr
          }
        },
      ],
    }
    return exportsOptions;
  }

  getTableActionButtons(actions: COMMON_ACTION_BUTTON[], clickCallback?: TableActionCallback, mouseOverCallback?: TableActionCallback, mouseLeaveCallback?: TableActionCallback) {
    const actionButton = [];
    let _self = this;
    actions.forEach(action => {
      switch (action) {
        case COMMON_ACTION_BUTTON.VIEW: actionButton.push({
          actionName: action,
          actionFormatter: (column: TableColumns, row: any, index: number) => {
            return `<div class="btn-block">
                          <button class="btn btn-primary">${_self.translateService.instant("view")}</button>
                        </div>`;
          },
          handleClick: clickCallback,
          handleMouseOver: mouseOverCallback,
          handleMouseLeave: mouseLeaveCallback
        } as TableActions);
          break;
        case COMMON_ACTION_BUTTON.EDIT: actionButton.push({
          actionName: action,
          actionFormatter: (column: TableColumns, row: any, index: number) => {
            return `<div class="btn-block">
                          <button class="btn btn-success">${_self.translateService.instant("edit")}</button>
                        </div>`;
          },
          handleClick: clickCallback,
          handleMouseOver: mouseOverCallback,
          handleMouseLeave: mouseLeaveCallback
        } as TableActions);
          break;
        case COMMON_ACTION_BUTTON.BAN: actionButton.push({
          actionName: action,
          actionFormatter: (column: TableColumns, row: any, index: number) => {
            return `<div class="btn-block">
                      <button class="btn btn-primary" [style]='"background-color:rgb(216 3 3)"'>${_self.translateService.instant("ban")}</button>
                    </div>`;
          },
          handleClick: clickCallback,
          handleMouseOver: mouseOverCallback,
          handleMouseLeave: mouseLeaveCallback
        } as TableActions);
          break;
        case COMMON_ACTION_BUTTON.DELETE: actionButton.push({
          actionName: action,
          actionFormatter: (column: TableColumns, row: any, index: number) => {
            return `<div class="btn-block">
                      <button class="btn btn-danger">${_self.translateService.instant("delete")}</button>
                    </div>`;
          },
          handleClick: clickCallback,
          handleMouseOver: mouseOverCallback,
          handleMouseLeave: mouseLeaveCallback
        } as TableActions);
          break;
        case COMMON_ACTION_BUTTON.UNBAN: actionButton.push({
          actionName: action,
          actionFormatter: (column: TableColumns, row: any, index: number) => {
            return `<div class="btn-block">
                      <button class="btn btn-primary" [style]='"background-color:rgb(216 3 3)"'>${_self.translateService.instant("unban")}</button>
                    </div>`;
          },
          handleClick: clickCallback,
          handleMouseOver: mouseOverCallback,
          handleMouseLeave: mouseLeaveCallback
        } as TableActions);
          break;
      }
    })
    return actionButton;
  }

  getTripsButtons(actions: COMMON_TRIPS_ACTION_BUTTON[]) {
    const actionButton = [];
    actions.forEach(action => {
      switch (action) {
        case COMMON_TRIPS_ACTION_BUTTON.TRIP_DETAIL:
          actionButton.push({
            actionName: "Trip Details",
            actionFormatter: (column: TableColumns, row: any, index: number) => {
              return `<img class="action-icon" src="assets/images/trip-details(3).png" alt="">`;
            },
            handleClick: (column: TableColumns, row: any, index: number) => {
              let activeModal = this.modalService.open(VehicleDetailComponent, {
                ariaLabelledBy: 'modal-basic-title',
                size: 'xl',
              });
              activeModal.componentInstance.refData = {
                showHeader: false,
                txnId: row.ipa_transaction_id,
                processName: row.process_name,
                processId: row.process_id,
                vehicleId: row.vehicle_id,
                isManualStamping: true,
              };
            }
          })
          break;
        case COMMON_TRIPS_ACTION_BUTTON.TRIP_TRACKING:
          actionButton.push({
            actionName: "Trip Tracking",
            actionFormatter: (column: TableColumns, row: any, index: number) => {
              return `<img class="action-icon" src="assets/images/trip-tracking(3).png" alt="">`;
            },
            handleClick: (column: TableColumns, row: any, index: number) => {
              let activeModal = this.modalService.open(DriverTrackerComponent, {
                ariaLabelledBy: 'modal-basic-title',
                size: 'xl',
              });
              activeModal.componentInstance.refData = {
                showHeader: false,
                txnId: row.ipa_transaction_id,
                vehicleName: row.vehicle_rc_no + '-' + row.process_name,
              };
            }
          })
          break;
        case COMMON_TRIPS_ACTION_BUTTON.TRIP_REMARK:
          actionButton.push({
            actionName: "Trip Remarks",
            actionFormatter: (column: TableColumns, row: any, index: number) => {
              return `<img class="main-icon action-icon" src="assets/images/chat-icon.png" alt="" matTooltip="Trip Remark History">`;
            },
            handleClick: (column: TableColumns, row: any, index: number) => {
              let activeModal = this.modalService.open(TripCommentsComponent, {
                ariaLabelledBy: 'modal-basic-title',
                size: 'xl',
              });
              activeModal.componentInstance.tripId = row.ipa_transaction_id;
              activeModal.componentInstance.showModal = true;
            }
          })
          break;
      }
    })
    return actionButton;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help-submenu',
  templateUrl: './help-submenu.component.html',
  styleUrls: ['./help-submenu.component.scss'],
})
export class HelpSubmenuComponent implements OnInit {
  accordionSections = [
    {
      title: 'Section 1',
      options: ['Option 1', 'Option 2', 'Option 3'],
      active: false,
    },
    {
      title: 'Section 2',
      options: ['Option A', 'Option B', 'Option C'],
      active: false,
    },
    // Add more accordion sections as needed
  ];

  toggleAccordion(section: any) {
    section.active = !section.active;
  }

  constructor(public router: Router) {}

  ngOnInit() {}

  openHelpInfo() {
    this.router.navigate(['user/help-info']);
  }
}

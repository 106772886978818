<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header mb-1" style="align-items: baseline;">
              <div class="header-block">
                <h3 class="card-title">
                  {{'Form Management'|customTranslate}}
                </h3>
              </div>
            <div class="row card-body" style="justify-content: flex-end;">
              <div *ngIf="role.user_role=='AXESTRACK'" class="form-group col-xl-3 col-lg-6 col-sm-12 m-0">
                <ps-auto-suggestion (onSelected)="selectedCompany($event)" class="required" [data]="companyData"
                  display="companyName" [placeholder]="'Choose Company'|customTranslate"
                  [preSelected]="{companyName:companyName}">
                </ps-auto-suggestion>
              </div>
              <div *ngIf="role.user_role=='AXESTRACK' || role.user_role=='COMPANYADMIN'"
                class="form-group col-xl-3 col-lg-6 col-sm-12 m-0">
                <ps-auto-suggestion (onSelected)="selectedPlant($event)" [data]="plantData" display="plantName"
                  [placeholder]="'Choose Plant'|customTranslate" [preSelected]="{ plantName: plantName }">
                </ps-auto-suggestion>
              </div>
            </div>
          </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-3 form-group">
                  <label for="my-input" class="required">{{'select_form_type'|customTranslate}}</label>
                  <ps-auto-suggestion [data]="formTypes" (onSelected)="selectedFormType($event)"
                    display="form_type_name" [preSelected]="{form_type_name:formTypeName}"
                    placeholder="Select Form Type">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="formTypeValidator && formTypeValidator.error">
                    <span>{{formTypeValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-md-3">
                  <label for="my-input" class="required">{{'form_title'|customTranslate}}</label>
                  <input class="form-control" type="text" required [placeholder]="'Enter Form Title'|customTranslate"
                    [(ngModel)]="formTitle" #name="ngModel" autocomplete="off">
                  <div class="error" *ngIf="formTitleValidator && formTitleValidator.error">
                    <span>{{formTitleValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="my-input" class="required">{{'status'|customTranslate}}</label>
                  <select class="form-control" id="methodType" name="methodType" [(ngModel)]="formStatus">
                    <option value="INACTIVE">INACTIVE</option>
                    <option value="ACTIVE">ACTIVE</option>
                  </select>
                  <div class="error" *ngIf="formStatusValidator && formStatusValidator.error">
                    <span>{{formStatusValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label for="my-input">{{'form_description'|customTranslate}}</label>
                  <input class="form-control" type="text" [placeholder]="'Enter Form Description'|customTranslate"
                    [(ngModel)]="formDescription" #name="ngModel" autocomplete="off">
                  <div class="error" *ngIf="formDescriptionValidator && formDescriptionValidator.error">
                    <span>{{formDescriptionValidator.msg}} </span>
                  </div>
                </div>

                <div class="col-md-3 form-group" *ngIf="formTypeName!='Visitor Form'">
                  <label for="my-input" class="required">{{'Select Process'|customTranslate}}</label>
                  <ps-auto-suggestion [data]="processes" (onSelected)="selectedProcess($event)" display="processName"
                    [preSelected]="{processName:processName}" [placeholder]="'select_process'|customTranslate">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="processValidator && processValidator.error">
                    <span>{{processValidator.msg}} </span>
                  </div>
                </div>

                <div class="row form-group action-block">
                  <div class="btn-block">
                    <button class="btn btn-warning" (click)="reset()"> {{'reset'|customTranslate}} </button>
                    <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{"Save"|customTranslate}}
                    </button>
                  </div>
                </div>
              </div>

              <div class="table_sec" style="margin-top:10px">
                <app-custom-table [data]="customForms" [columns]="columns"
                  [tableType]="TABLE_TYPE"></app-custom-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

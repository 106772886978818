<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card"
            *ngIf="userService.permissionMap.get('add_field_access')==true ||userService.permissionMap.get('edit_field_access')==true ">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'field_management'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <div class="row">

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="role.user_role == 'COMPANYADMIN'">
                  <label for="my-input" class="required">{{"select_plant" | customTranslate}} </label>
                  <ps-auto-suggestion (onSelected)="selectPlant($event)" [data]="plantList"
                    display="plantName" placeholder="Choose Plant"
                    [preSelected]="{plantName:plantName}">
                  </ps-auto-suggestion>
                  <div class="error" *ngIf="plantNameValidator && plantNameValidator.error">
                    <span>{{plantNameValidator.msg}} </span>
                  </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'Field Name'|customTranslate}} </label>
                  <input type="text" class="form-control" name="name" required placeholder="Enter Master Field Name"
                    [(ngModel)]="masterField.name" #name="ngModel" autocomplete="off">
                  <div class="error" *ngIf="fieldNameValidator && fieldNameValidator.error">
                    <span>{{fieldNameValidator.msg}} </span>
                  </div>
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'field_type'|customTranslate}} </label>
                  <select name="masterField_status" id="#masterField_status" class="form-control"
                    [(ngModel)]="masterField.type">
                    <option selected="TEXTFIELD" value="TEXTFIELD">TEXTFIELD</option>
                    <option value="CHECKBOX">CHECKBOX</option>
                    <option value="DATEPICKER">DATEPICKER</option>
                    <option value="FILE">FILE</option>
                    <option value="RADIOBUTTON">RADIOBUTTON</option>
                    <option value="TIME">TIME</option>
                    <option value="DATETIMEPICKER">DATETIMEPICKER</option>
                    <option value="TEXTAREA">TEXTAREA</option>
                    <option value="NUMBERFIELD">NUMBERFIELD</option>
                    <option value="SELECTLISTSINGLE">SINGLE SELECT DROPDOWN</option>
                    <option value="SELECTLISTMULTIPLE">MULTI SELECT DROPDOWN</option>
                    <option value="LABEL">LABEL</option>
                    <option value="URL">URL</option>
                    <option value="GEOCODEDIMAGE">GEOCODEDIMAGE</option>
                    <option value="IMAGE">IMAGE</option>
                    <option value="BARCODE">BARCODE</option>
                    <option value="BARCODEWITHIMAGE">BARCODEWITHIMAGE</option>
                    <option value="RFIDCODE">RFIDCODE</option>
                    <option value="RFIDCODEWITHIMAGE">RFIDCODEWITHIMAGE</option>
                    <option value="SIGNATURE">SIGNATURE</option>
                    <option value="FIELDGROUP">FIELDGROUP</option>
                    <option value="CAMERASTREAMING">CAMERA STREAMING</option>
                    <option value="VIDEOSTREAMING">VIDEO STREAMING</option>

                  </select>
                  <div class="error" *ngIf="fieldTypeValidator && fieldTypeValidator.error">
                    <span>{{fieldTypeValidator.msg}} </span>
                  </div>
                </div>


                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="masterField.type!='FIELDGROUP'">
                  <label for="my-input">{{'field_value'|customTranslate}} </label>
                  <select name="hardwareType" id="#fieldType" class="form-control" [(ngModel)]="masterField.fieldValue">
                    <option value="USERINPUT">User Input</option>
                    <option value="DATABASELINKED">Database Linked</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label for="my-input" class="required">{{'field_status'|customTranslate}} </label>
                  <select name="masterField_status" id="#masterField_status" class="form-control"
                    [(ngModel)]="masterField.status">
                    <option selected="ACTIVE" value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </select>
                  <div class="error" *ngIf="statusValidator && statusValidator.error">
                    <span>{{statusValidator.msg}} </span>
                  </div>
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="masterField.fieldValue=='HardwareLinked'">
                  <label for="my-input">{{'hardware_response'|customTranslate}}</label>
                  <select name="hardwareType" id="#hardwareReponse" class="form-control"
                    [(ngModel)]="masterField.hardwareResponse">
                    <option [value]="hr" *ngFor="let hr of hardwareResponsesData">{{hr}}</option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="masterField.fieldValue=='DATABASELINKED'">
                  <label for="my-input">{{'database_field'|customTranslate}} </label>
                  <select name="hardwareType" id="#databaseField" class="form-control"
                    [(ngModel)]="masterField.databaseField">
                    <option [value]="db.databaseFieldName" *ngFor="let db of databaseFields">{{db.databaseFieldName}}
                    </option>
                  </select>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="masterField.type=='FIELDGROUP'">
                    <label>{{"num_of_subgroups"| customTranslate}}</label>
                    <input type="number" name="subgroups" class="form-control" [value]="subgroupsCount" [min]="1" id="subgroupscount" [max]="4" (change)="changeNoOfSubGroups($event)">
                </div>

                <ng-container *ngIf="masterField.fieldValue!='DATABASELINKED'">
                  <div class="form-group col-md-12" style="background-color:#eee;margin: 0px 12px 0px 20;"
                    *ngIf="masterField.type=='SELECTLISTSINGLE'||masterField.type=='SELECTLISTMULTIPLE'||masterField.type=='RADIOBUTTON'||masterField.type=='CHECKBOX'">
                    <label for="my-input">{{'field_value'|customTranslate}}</label>
                    <div class="row" *ngFor="let value of masterField.values; let i=index">
                      <div class="col-md-4 form-group">
                        <div class="input-group-append">
                          <input type="text" class="form-control" name="name" required placeholder="Enter Field Value"
                            [(ngModel)]="value.name" autocomplete="off">
                          <span class="input-group-text remove-field" id="my-addon" *ngIf="i !== 0"
                            (click)="masterField.values.splice(i,1)"> <i class="fa fa-trash remove"></i></span>
                          <span class="input-group-text add-field" id="my-addon"
                            *ngIf="i == masterField.values.length-1" (click)="addValues()">
                            <i class="fa fa-plus add"></i></span>
                        </div>
                      </div>

                    </div>
                  </div>
                    <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="masterField.type=='CAMERASTREAMING'||masterField.type=='VIDEOSTREAMING'">
                      <label for="my-input" class="required">{{'Field Value'|customTranslate}} </label>
                      <input type="text" class="form-control" name="name" required placeholder="Enter Master Field Value"
                        [(ngModel)]="masterField.values[0].name" #name="ngModel" autocomplete="off">
                      <div class="error" *ngIf="fieldNameValidator && fieldNameValidator.error">
                        <span>{{fieldNameValidator.msg}} </span>
                      </div>
                    </div>
                </ng-container>

                <div class="form-group col-xl-4 col-lg-6 col-sm-12" *ngIf="masterField.type=='LABEL'">
                  <label for="my-input">{{('field_value'|customTranslate)+' '+('label'|customTranslate)}}</label>
                  <ckeditor [editor]="Editor" [(ngModel)]="detail"></ckeditor>
                </div>

                <!-- <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="masterField.type!='FIELDGROUP'">
                  <label for="my-input">{{'Prefix Pattern'|customTranslate}} </label>
                  <input type="text" class="form-control" name="name" required placeholder="Enter Prefix Pattern"
                    [(ngModel)]="masterField.pattern_prefix" #name="ngModel" autocomplete="off">
                </div>
                <div class="form-group col-xl-6 col-lg-4 col-sm-12" *ngIf="masterField.type!='FIELDGROUP'">
                  <label for="regexPattern">{{'Regular Expression'|customTranslate}} </label>
                  <input type="text" class="form-control" name="regexPattern" required placeholder="Enter Regular Expression"
                    [(ngModel)]="masterField.regex_pattern" #regexPattern="ngModel" autocomplete="off">
                </div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="masterField.type!='FIELDGROUP'">
                  <label for="my-input">{{'Postfix Pattern'|customTranslate}} </label>
                  <input type="text" class="form-control" name="name" required placeholder="Enter Postfix Pattern"
                    [(ngModel)]="masterField.pattern_postfix" #name="ngModel" autocomplete="off">
                </div> -->

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="fieldValidationGrp.includes(masterField.type)">
                  <label for="fieldValidation">{{'Field Validation'|customTranslate}} </label>
                  <div class="boxes" style="display: flex; flex-wrap: nowrap;width: 100%;">
                  <div class="pattern" style="width:25%;" *ngIf="!regExValidationGrp.includes(masterField.type)">
                    <input type="text" class="form-control" name="prefixPattern" required placeholder="Prefix"
                      [(ngModel)]="masterField.pattern_prefix" #prefixPattern="ngModel" autocomplete="off" style="border-radius: 0cm;">
                  </div>

                  <div class="regEx" style="width:50%;">
                    <input type="text" class="form-control" name="regexPattern" required placeholder="Regular Expression"
                      [(ngModel)]="masterField.regex_pattern" #regexPattern="ngModel" autocomplete="off" style="border-radius: 0cm;">
                  </div>

                  <div class="pattern" style="width:25%;" *ngIf="!regExValidationGrp.includes(masterField.type)">
                    <input type="text" class="form-control" name="postfixPattern" required placeholder="Postfix"
                      [(ngModel)]="masterField.pattern_postfix" #postfixPattern="ngModel" autocomplete="off" style="border-radius: 0cm;">
                  </div>
                </div>
                </div>

                <div class="form-group col-xl-3 col-lg-6 col-sm-12" *ngIf="masterField.type=='FIELDGROUP'">
                  <label for="my-input">{{'matching_method'|customTranslate}} </label>
                  <select name="hardwareType" id="#fieldType" class="form-control" [(ngModel)]="masterField.matchingMethod">
                    <option value="random" selected>Random</option>
                    <option value="sequential">Sequential</option>
                  </select>
                </div>

              </div>

              <div *ngIf="masterField.type=='FIELDGROUP'"style="display: flex;">
                <div *ngFor="let subgroup of subgroupCountRange;index as id" [ngStyle]="{'margin-left.px':id != 0 ? -132 : 0}">
                  <div>
                    <label for="my-input">{{'subgroup_name'|customTranslate}}</label>
                  <input type="text" style="width: 295px;" placeholder="Enter SubGroup Name" name="subgroupname" class="form-control" (change)="enterSubGroupName(id,$event)" [id]="'sub'+id" [value]="subgroupsName[0][id].name">
                <div class="form-group col-md-8" style="background-color:#eee;margin: 0px 12px 0px 20;">
                  <label for="my-input">{{'select_fields'|customTranslate}}</label>
                  <div class="row" *ngFor="let value of subgroupsName; let i=index;">
                    <div class="col-md-4 form-group">
                      <div class="input-group-append">
                          <ps-auto-suggestion (onSelected)="selectedField($event,i,id)" [data]="filteredMasterFields"
                          display="field_name" placeholder="Choose Field" [inputId]="'sub'+id+i"
                          [preSelected]="{field_name:this.subgroupsName[i][id].values.fieldName}">
                        </ps-auto-suggestion>
                        <span class="input-group-text remove-field" id="my-addon" *ngIf="(i !== 0 && showDelete(id))||(i==0 && subgroupsName.length>1 && showDelete(id))"
                        (click)="spliceRow(i,id)"> <i class="fa fa-trash remove"></i></span>
                      <span class="input-group-text add-field" id="my-addon" [ngStyle]="{'margin-left.px':i== 0 ? 110 : 0}"
                        *ngIf="i == subgroupsName.length-1 && showAdd(id)" (click)="addSubGroupValues(id)">
                        <i class="fa fa-plus add"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              </div>
              <div class="note" *ngIf="masterField.type=='FIELDGROUP'">
                <span class="star">*</span><span class="note_header">Note :</span><span class="note_text">{{" Only 4 Sub Groups are recommended."}}</span>
            </div>
            </div>

            <div class="row form-group action-block mb-3">
              <div class="btn-block">
                <button class="btn btn-muted" (click)="common.backClicked()"> {{'back'|customTranslate}} </button>
                <button class="btn btn-warning" (click)="resetDetail();this.role.user_role=='COMPANYADMIN'?resetData():null"> {{'reset'|customTranslate}} </button>
                <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}} </button>
              </div>
            </div>

          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{'master_fields'|customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                  <tr>
                    <th>{{'serial_no'|customTranslate}}</th>
                    <th>{{'Field Name'|customTranslate}}</th>
                    <th>{{'status'|customTranslate}}</th>
                    <th>{{'type'|customTranslate}}</th>
                    <th>{{'field_value'|customTranslate}}</th>
                    <th class="action master-field">{{'Actions'|customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of data, let i = index">
                    <td>{{i+1}}</td>
                    <td>{{row.field_name}}</td>
                    <td>{{row.field_status}}</td>
                    <td>{{row.field_type}}</td>
                    <td *ngIf="row.master_field_values==null">-</td>
                    <td *ngIf="row.master_field_values!=null">{{row.master_field_values}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-primary" (click)="viewDetails(row)"
                          *ngIf="userService.permissionMap.get('view_field__access')==true"> {{'view'|customTranslate}}
                        </button>
                        <button class="btn btn-success" (click)="editRow(row)"
                          *ngIf="userService.permissionMap.get('edit_field_access')==true"> {{'edit'|customTranslate}}
                        </button>
                        <button class="btn btn-danger" (click)="confirmAlert(row)"
                          *ngIf="userService.permissionMap.get('delete_field_access')==true"> {{'delete'|customTranslate}}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>


  <!-- table -->

</div>

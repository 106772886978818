import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-open-camera-modal',
  templateUrl: './open-camera-modal.component.html',
  styleUrls: ['./open-camera-modal.component.scss']
})
export class OpenCameraModalComponent implements OnInit {

  @Input() refData: any;
  @Output() pictureClicked: EventEmitter<any> = new EventEmitter<any>();
  imageValues : string[] = [];



  constructor(
    public activeModal: NgbActiveModal,

  ) { }

  ngOnInit() {
    console.log("fghjk",this.refData)
  }

  closeModal(response?: any, apiHit?: any) {
  console.log('response: ', response);
    this.activeModal.close({ response: response, apiHit: apiHit });
  }

  // onPictureClick(event){
  //   this.pictureClicked.emit(event);
  // }
}

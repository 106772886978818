import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { TableService } from 'src/app/@core/services/table.service';
import { ConfirmationDialogComponent } from 'src/app/modals/confirmation-dialog/confirmation-dialog.component';
import { ViewDetailsComponent } from 'src/app/modals/view-details/view-details.component';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { ProcessService } from '../process/process.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-vehicle-tag-mapping',
  templateUrl: './vehicle-tag-mapping.component.html',
  styleUrls: ['./vehicle-tag-mapping.component.scss'],
})
export class VehicleTagMappingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 4, 'Vehicle Tag Mapping List');
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  processStageList: any[] = [];
  processStageName: string= null;
  processStageId: number = null;
  buttonName = 'save';
  vehicleList: any = [];
  vehicleRsn: any = [];
  vehicleId: any = null;
  tagId: any = null;
  tagType: any = null;
  tagData: any = [];
  vehicleMappingData: any[] = [];

  companyName: String = null;
  plantName: String = null;
  companyList: any = [];
  plantList: any = [];
  companyId: number = null;
  plantId: number = null;

  constructor(
    public breadcrumbService: BreadcrumbService,
    private processService: ProcessService,
    public common: CommonService,
    public table: TableService,
    private api: ApiService,
    private alert: AlertService,
    public role: RoleService,
    private plantService: PlantService,
    public userService: UserService,

  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Vehicles', url: null },
      {
        name: 'Vehicle Tag Mapping',
        link: '/user/vehicle-tag-mapping',
      },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
  }

  ngOnInit(): void {
    this.getAllProcessStage();
    this.getVehicleList();

    if (this.role.user_role == 'AXESTRACK') {
      this.getCompany();
    } else if (
      this.role.user_role == 'COMPANYADMIN' ||
      this.role.user_role == 'COMPANYUSER'
    ) {
      this.companyId = this.userService._loggedInUser.companyId;
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.getAllProcessStage();
    }
  }


  getCompany() {
    this.api.get('company/all_company').subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.companyList = res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  getPlantById(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.common.loading = true;
      let params = {
        id: id,
      };
      this.plantService.getPlantById(params).subscribe(
        (res: any) => {
          if (res.status) {
            this.common.loading = false;
            this.plantList = res.data;
            console.log('this.plantList: ', this.plantList);
            resolve(this.plantList);
          }
        },
        (err: any) => {
          console.error('Error', err);
          this.common.loading = false;
          reject(null);
        }
      );
    });
  }

  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.getPlantById(event.companyId);
    this.plantName = null;
    this.plantId = null;
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getAllProcessStage(this.plantId);
  }


  getAllProcessStage(plantId:any=null) {
    this.tagData = null;
    this.tagId = null;
    this.tagType = null;
    this.common.loading = true;
    this.processService.getAllProcessStage(plantId).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.processStageList = res.data;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getVehicleList() {
    this.common.loading = true;
    this.api.get('vehicle/get-all-vehicles').subscribe(
      (res: any) => {
        this.vehicleList = res.data;
        this.common.loading = false;
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  selectVehicle(event: any, type: string) {
    if (type == 'select') {
      this.vehicleRsn = event.vehicle_rsn;
      this.vehicleId = event.vehicle_id;
    } else {
      this.vehicleRsn = event;
    }
  }

  selectedProcessStage(event: any) {
    this.processStageName = event.order_name;
    this.processStageId = event.order_id;
    this.tagData = null;
    this.tagId = null;
    this.tagType = null;
    this.getVehicleMapping(this.processStageId);

  }

  reset() {
    this.buttonName = 'save';
    this.processStageId = null;
    this.processStageName = null;
    this.vehicleId = null;
    this.vehicleRsn = null;
    this.tagId = null;
    this.tagType = null;
    if (this.role.user_role == 'AXESTRACK') {
      this.companyId = null;
      this.companyName = null;
      this.plantList = null;
      this.plantId = null;
      this.plantName = null;
      this.processStageId = null;
      this.processStageName = null;
      this.processStageList = null;
    } else if (
      this.role.user_role == 'COMPANYADMIN' ||
      this.role.user_role == 'COMPANYUSER'
    ) {
      this.processStageId = null;
      this.processStageName = null;
      this.processStageList = null;
      this.plantId = null;
      this.plantName = null;
      this.getPlantById(this.userService._loggedInUser.companyId);
    } else {
      this.getAllProcessStage();
    }
    this.tagData = null;
  }

  save() {
    if (this.processStageName == null)return this.alert.error('Please select Process->Stage...');
    if (this.vehicleRsn == null)return this.alert.error('Please select Vehicle No....');
    if (this.tagId == null) return this.alert.error('Please select tagId...');
    let params = {
      processStageId: this.processStageId,
      vehicleId: this.vehicleId,
      vehicleRsn: this.vehicleRsn,
      tagId: this.tagId,
      tagType:this.tagType
    };
    this.common.loading = true;
    this.api.post('vehicle/save-vehicle-tag-mapping', params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.buttonName = 'save';
          this.reset();
          if (res.data.length) {
            this.alert.warn(res.message);
          } else {
            this.alert.success(res.message);
          }
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  edit(row: any) {
    this.reset();
    this.buttonName = 'update';
    this.vehicleId = row.vehicleId;
    this.vehicleRsn = row.vehicleRsn;
    this.processStageId = row.process;
    this.vehicleId = row.vehicleId;
    this.processStageId = row.process_stage_order_id;
    this.processStageName = row.processStageName;
    this.tagId = this.tagId;
  }

  getVehicleMapping(processStageId: number) {
    this.common.loading = true;
    let params = {
      processStageId: processStageId,
    };
    this.api.get('transaction/get-vehicle-tag-mapping', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.tagData = res.data;
        if (res.data.length == 1) {
          this.tagId = res.data[0].tag_id;
          this.tagType = res.data[0].tag_type;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
    );
  }

  selectedField(event) {
    this.tagId = event.tag_id;
    this.tagType = event.tag_type;
  }
}

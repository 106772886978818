<ng-container *ngIf="loading; else customtable;">
  <div style="text-align: center;">Loading Data...</div>
</ng-container>

<ng-template #customtable>
  <ng-container *ngIf="tableType == TABLE_TYPE.DATATABLE">
    <app-custom-datatable [data]="data" [columns]="columns" [showSerialNo]="showSerialNo" [options]="options" [showSerialNo]="showSerialNo"></app-custom-datatable>
  </ng-container>
  <ng-container *ngIf="tableType == TABLE_TYPE.HTMLTABLE">
    <app-html-table [data]="data" [columns]="columns" [showSerialNo]="showSerialNo" [options]="options" [showSerialNo]="showSerialNo"></app-html-table>
  </ng-container>
</ng-template>
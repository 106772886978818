<div class="container-fluid">
    <div class="container-card">
      <div class="container-card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card" style='margin-top: 15px; margin-bottom: 5px;'>
              <div class="card-header">
                <div class="header-block">
                  <h3 class="card-title">
                    {{'In Between '+ (this.extraData.entityType=='visit'?'Visits':'Vehicles')|customTranslate}}
                  </h3>
                </div>
              </div>
              <div class="card-body">
                <table class="custom-table" datatable [dtOptions]="dtOptions"
                      [dtTrigger]="dtTrigger">
                      <thead class="thead-light">
                        <tr>
                          <th>{{'serial_no'|customTranslate}}</th>
                          <th>{{this.extraData.entityType=='visit'? 'Visitor Name':'vehicle_number'|customTranslate}}</th>
                          <th>{{this.extraData.entityType=='visit'? 'Visit ID':'transaction_name'|customTranslate}}</th>
                          <th>{{'In-Between TAT'|customTranslate}}   <small style="font-size: 10px;">(Actual / Expected)</small></th>
                          <th>{{'status'|customTranslate}}</th>
                          <th>{{'info' | customTranslate}}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let row of refData let i = index">
                          <td>{{ i+1 }}</td>
                          <td >{{row.vehicle_rc_no}}</td>
                          <td>{{row.transaction_name}}</td>
                          <td width="120px">
                            <span [ngClass]="row.tat > extraData.inBetweenTat ? 'badge over' :  'badge'">{{row.tat}}</span>
                            / <span class="badge info">{{extraData.inBetweenTat}}</span></td>
                             <!-- <span class="badge over"> -->
                              <!-- {{row.trip_total_tat}}</span></td> -->
                          <td><span class="pill"
                              [ngClass]="{'bg-indigo-100 text-indigo-700':row.stage_workflow_status=='CANCELLED','bg-yellow-200 text-yellow-800':row.stage_workflow_status=='INPROGRESS','bg-red-100 text-red-800':(row.stage_workflow_status!='INPROGRESS' && row.stage_workflow_status!='CANCELLED')}">
                              {{row.stage_workflow_status}}
                            </span></td>
                          <td>
                            <div style="display: flex;">
                              <img class="icon" src="assets/images/trip-details(3).png" alt="" (click)="tripModal(row)">
                              <img class="icon" src="assets/images/trip-tracking(3).png" alt=""
                                (click)="tripTrackingModal(row)">
                                <app-trip-comment-trail [tripId]="row.trip_id"></app-trip-comment-trail>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/@core/services/common.service';
import { RoleService } from 'src/app/@core/services/role.service';
import { CompanyService } from '../companies/companies.service';
import { PlantService } from '../plant/plant.service';
import { List } from 'lodash';
import { ApiService } from 'src/app/@core/services/api.service';
import { Transporter} from 'src/app/dataType/transporterInterface';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TableService } from 'src/app/@core/services/table.service';
import { UserService } from 'src/app/@core/services/user.service';


@Component({
  selector: 'app-ban-transporter',
  templateUrl: './ban-transporter.component.html',
  styleUrls: ['./ban-transporter.component.scss']
})
export class BanTransporterComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtTrigger: any = new Subject();
  dtOptions: any = this.table.options(10, 5, 'TRANSPORTER BOOKINGS');
  renderTable() {
    this.dtElement.dtInstance.then((dtInstance: any) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  companyList:any[] = [];
  plantList:any[] = [];
  companyId: Number = null ;
  companyName : String = null
  plantId : Number =null;
  plantName : String = null;
  trasporterList : Transporter []  = [];
  transporterId : number = null;
  transporterName : String = null;
  bannedTransporters : Transporter[] = [];
  restrictionScope: String = 'GLOBAL';
  plantLevelRoles = ['AXESTRACKUSER' , 'COMPANYUSER', 'PLANTADMIN' , 'PLANTUSER']


  constructor(
    public role: RoleService,
    public common : CommonService,
    public companyService : CompanyService,
    public plantService : PlantService,
    public api : ApiService,
    public alert : AlertService,
    public table : TableService,
    public userService : UserService
  ) { }


  ngAfterViewInit() {
    if(this.role.user_role == 'AXESTRACK'){
      this.getCompany();
    }
    if(this.role.user_role == 'AXESTRACK' || this.role.user_role == 'COMPANYADMIN'){
      this.getPlantById();
    }
    this.getBannedTransporterList();
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit() {
    if(this.role.user_role == 'AXESTRACKUSER' || this.role.user_role == 'COMPANYUSER'){
      this.companyId = this.userService._loggedInUser.externalCompanyId;
      this.plantId = this.userService._loggedInUser.externalPlantId;
    }else if (this.role.user_role != 'AXESTRACK'){
      this.companyId = this.userService._loggedInUser.companyId
      this.plantId = this.userService._loggedInUser.plantId
    }
  }


  // ban trnasporter
  // generateBanCompliance(tranporterId,tranporterName){
  //   let params={
  //     refId:tranporterId,
  //     complianceData:{
  //         tranporter_name:tranporterName,
  //         remark:'Transporter Banned'
  //     },
  //     complianceCategoryName:'Transporter',
  //     complianceSubCategoryName:'Ban'
  //   }
  //   this.api.post('compliance/save_compliance', params).subscribe(
  //     (res: any) => {
  //       console.log('Success')
  //     },
  //     (err: any) => {
  //       console.error('Error: ', err);
  //     }
  //   );
  // }


  // deleteBanCompliance(tranporterId){
  //   let params={
  //     refId:tranporterId,
  //     complianceCategoryName:'Transporter',
  //     complianceSubCategoryName:'Unban'
  //   }
  //   this.api.post('compliance/save_compliance', params).subscribe(
  //     (res: any) => {
  //       console.log('Success')
  //     },
  //     (err: any) => {
  //       console.error('Error: ', err);
  //     }
  //   );
  // }



  selectedCompany(event: any) {
    this.companyId = event.companyId;
    this.companyName = event.companyName;
    this.plantName = null;
    this.plantId = null;
    this.getPlantById(event.companyId);
    this.getBannedTransporterList();
  }


  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.plantName = event.plantName;
    this.getBannedTransporterList();
  }

  getCompany() {
    this.common.loading = true;
    this.companyService.getCompany().subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          res.data.forEach((element) => {
            if (element.companyStatus == 'ACTIVE') this.companyList.push(element);
          });
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    };
    this.plantService.getPlantById(params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.plantList = res.data;
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  banTransporter(transporterId:number,status:string,transporterName:string){
    this.common.loading = true;
    let params = {
      companyId: this.companyId,
      plantId : this.plantId,
      transporterId : transporterId,
      updateStatus : status
    };
    this.api.get('transporter/ban-transporter',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.alert.success(res.message);
          this.getBannedTransporterList();
          // if(status=='ban'){
          //   this.generateBanCompliance(transporterId,transporterName)
          // }
          // else if(status=='unban'){
          //   this.deleteBanCompliance(transporterId)
          // }
        }else{
          this.alert.error(res.message);
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }

  getBannedTransporterList() {
    this.common.loading = true;
    let params = {
      companyId: this.companyId,
      plantId : this.plantId
    };
    this.api.get('transporter/get-banned-transporters-list',params).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.bannedTransporters = res.data;
          this.renderTable()
        }
      },
      (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      }
    );
  }
}

import { AlertService } from './../../theme/layout/header/alert-message/alert.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/@core/services/common.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckInCheckOutModalComponent } from '../check-in-check-out-modal/check-in-check-out-modal.component';
import { AddAssetRemarkRequest, AddRemarkAsset, RequestOfUser } from 'src/app/dataType/assetInterface';
@Component({
  selector: 'app-asset-request-remark',
  templateUrl: './asset-request-remark.component.html',
  styleUrls: ['./asset-request-remark.component.scss'],
})
export class AssetRequestRemarkComponent implements OnInit,AfterViewInit {
  @ViewChild('myTemplate', { static: true }) modalContent: ElementRef<any>;
  @Input() public refData;
  toggle:boolean=false;
  assets: AddRemarkAsset[]=[]
  assetList: number[] = [];
  availableValues: boolean[] = [];
  info: AddAssetRemarkRequest[] = [];
  requestId: number=null;
  reqStatus:string;
  activeInsatnce:any;
  constructor(public router: Router, private modalService: NgbModal, public common: CommonService, public http: HttpClient, public route: ActivatedRoute, private alertService: AlertService, private api: ApiService, private activeModal: NgbActiveModal) {}
  comment: string = null;
  calling:boolean;
  from_page:boolean=false;
  ngOnInit(): void {
    this.from_page=this.refData?.from_page??false;
  }

  ngAfterViewInit() {
    this.getAssetRequestData();
    this.modalService.open(this.modalContent, { size: 'xl' }).result.then((result) => {
    }, (reason) => {
      this.closeModal();
    });
  }

  closeModal() {
    this.modalService.dismissAll();
    if(this.from_page==false){
      const secretKey = this.route.snapshot.queryParams.assetId;
      if(secretKey){
        this.router.navigate([this.route.snapshot.queryParams.calling], {
        queryParams: {secretKey},
      })}
      else if(this.route.snapshot.queryParams.calling) this.router.navigate([this.route.snapshot.queryParams.calling]);
    }
  }

  getAssetRequestData() {
    if ( (this.refData?.request_id??null) || this.route.snapshot.queryParams.secretKey ) {
      console.log('this.from_page==true: ', this.from_page==true);
      this.requestId = this.from_page==true? this.refData.request_id : atob(this.route.snapshot.queryParams.secretKey);
      let params={
        requestId:this.requestId
      }
      this.api.get('asset_requests/get-request-data',params)
        .subscribe(
          (res: any) => {
            if (res.status) {
              this.info = res.data[0].assets;
              this.assets = JSON.parse(res.data[0].assets[0].assets)
              this.availableValues = this.assets.map(asset => asset.availability);
              this.reqStatus = res.data[0].status;
              this.scrollToBottom();
            }
          },
          (err: any) => {
            console.error('Error: ', err);
          }
        );
    } else {
      this.alertService.error('secretKey is incorrect....');
    }
  }

  toolTipData(data) {
    return JSON.parse(data);
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        document.getElementById('lastMsg')?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }, 10);
    } catch (err) { }
  }



  updateAssetStatusInRequest(availability: boolean, id: number, index: number) {
    let params={
      id:id,
      status:!availability
    }
    this.api.get('asset_requests/update-asset-status-in-request',params).subscribe((res: any) => {
      if (res.status) {
        this.availableValues[index] = !this.availableValues[index]
      }
    }, (error) => {
      console.log(error);
    });
  }

  openCheckInCheckOutModal(flag:any, curStatus: any) {
    this.toggle=false;
    let assetList=[];
    this.assets.forEach(element => {
      assetList.push(element.asset_id);
    });
    this.common.params={
      checkInFlag:flag,
      reqId:this.requestId,
      curStatus:curStatus,
      assetList:assetList,
      userType:this.info[0].req_for_entity,
      entityId:this.info[0].ref_id
    }
    const activeModal = this.modalService.open(CheckInCheckOutModalComponent, {
      size: 'lg',
    });
    activeModal.result.then(() => {
      this.getAssetRequestData();
      this.toggle=true;
    });
  }

}

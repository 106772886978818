<div class="container-fluid">
  <div class="container-card group-card">
    <div class="container-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card">

            <div class="card-body">
              <div class="dock_dashboard">

                <div class="row top_view align-items-center" *ngIf="role.user_role == 'COMPANYADMIN'">
                  <div class="col-md-2">
                    <mat-form-field appearance="fill">
                      <mat-label>{{"Plant" | translate}}</mat-label>
                      <mat-select [(value)]="selectedPlant" (selectionChange)="onSelectPlant($event)">
                        <mat-option>
                          <ngx-mat-select-search placeholderLabel="Plant"
                            noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let plant of plantList" [value]="plant.plantId">
                          {{plant.plantName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row top_view align-items-center">
                  <div class="select_calender col-md-5">
                    <i class="fa fa-angle-left" (click)="switchDate(-1,'left')"></i>
                    <i class="fa fa-angle-double-left" (click)="switchDate(-1,'first')"></i>
                    <div class="select_date">
                      <div class="calender_date" *ngFor="let item of dates; let i = index"
                        [class.active]="currentTime == i" (click)="switchDate(i,'')">
                        <div class="up_day">{{item | date:'EEE'}}</div>
                        <div class="down_date">{{item | date:'MMM dd'}}</div>
                      </div>

                    </div>
                    <i class="fa fa-angle-double-right" (click)="switchDate(-1,'last')"></i>
                    <i class="fa fa-angle-right" (click)="switchDate(-1,'right')"></i>
                  </div>
                  <div class="toggle-switch col-md-3">
                    <span [class.active]="activeView=='History'" (click)="switchView('History')">History</span>
                    <span [class.active]="activeView=='Current'" (click)="switchView('Current')">Current</span>
                    <span [class.active]="activeView=='Late'" (click)="switchView('Late')">Delay</span>
                  </div>

                  <div class="process_sec col-md-2">
                    <mat-form-field appearance="fill">
                      <mat-label>{{"process" | translate}}</mat-label>
                      <!-- {{states | json}} -->
                      <mat-select [(value)]="selProcess" (selectionChange)="selectedProcess($event)">
                        <mat-option>
                          <ngx-mat-select-search placeholderLabel="Process"
                            noEntriesFoundLabel="No Data Found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let process of processList"
                          [value]="process.processId">{{process.processName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="dash_box_sec col-md-2">
                    <div class="dash_box bg_clr_blue">
                      <div class="content_box">
                        <h6>Total</h6>
                        <p class="clr_yellow">{{dockCount.total}}</p>
                      </div>
                    </div>
                    <div class="dash_box bg_clr_yellow">
                      <div class="content_box">
                        <h6>Late Loading</h6>
                        <p class="clr_green">{{dockCount.late}}</p>
                      </div>
                    </div>
                    <div class="dash_box bg_clr_red">
                      <div class="content_box">
                        <h6>Delay Arrival</h6>
                        <p class="clr_red">{{dockCount.delay}}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tab_view" *ngIf="dockData.length > 0">
                  <div class="tab" [class.active]="activeTab == i" (click)="switchDock(i)"
                    *ngFor="let item of dockData; let i = index">{{item.stage_destination_code}} <span
                      class="badge px-2 py-1"> {{item.dockCount}}</span></div>
                </div>

                <div class="dock_sec" *ngIf="dockData.length > 0">
                  <div class="tat_sec">
                    <div class="product_list">
                      <p>{{ dockData[activeTab].product_name }}</p>
                    </div>
                    <p>{{dockData[activeTab].stage_destination_code}}</p>
                    <div class="search_box">

                      <input type="text" placeholder="Search With Vehicle Number..." [(ngModel)]="searchValue"
                        (input)="searchVehicle()">
                      <i class="fa fa-search"></i>
                    </div>
                  </div>
                  <ng-container *ngIf="sliceIndex!=-1">
                    <div class="row dock_box m-0" attr.id="dock-{{l}}"
                      *ngFor="let item of dockData[activeTab].dailySlot.slice(sliceIndex); let l = index">
                      <div class="col-md-1 col-3 left_sec p-0">
                        <div class="time">
                          <p>{{item.start_time}} <br> To <br>{{item.end_time}}</p>
                        </div>
                      </div>

                      <div class="col-md-11 col-9 right_sec p-0">
                        <div class="block_row" [id]="'slot-block-'+l">
                          <div class="block" [id]="'no-vehicle-found-'+l" [hidden]="item.slot_data != 0"
                            [class.active]="true">
                            <div class="vehicle_number">
                              <img src="assets/images/no-vehicle-found_2.gif">
                            </div>
                          </div>
                          <ng-container *ngFor="let queue of getFilteredDockData(item.slot_data,l); let i = index">
                            <div class="block" id="vehicle-box" [ngClass]="searchTrue ? 'blink' : ''">
                              <img
                                [src]="queue.vehicle_status == 'On Time' || queue.status == 'Completed' || queue.status == 'Booked' ? 'assets/images/green_big_truck.png' : queue.vehicle_status == 'Delay' ? 'assets/images/red_big_truck.png' : 'assets/images/yellow_big_truck.png'">
                              <div class="info-box" id="info-box" [ngStyle]="{'background-color':queue.status != 'Booked'? '#396498':null,'border': queue.status != 'Booked'?'0.5px solid #396498':null}">
                                <ul class="children">
                                  <li>Transporter Name - {{queue.transporter_name}}</li>
                                  <li>Transporter Phone No. - {{queue.transporter_phone}}</li>
                                  <li>Driver Name - {{queue.driver_name}}</li>
                                  <li>Driver Phone No. - {{queue.driver_phone}}</li>
                                  <li *ngIf="queue.dock_appointment_id!=null">Booking Time - {{queue.booking_time|date:'dd-MM-yyyy hh:mm'}}</li>
                                </ul>
                                <ng-container *ngIf="queue.status != 'Booked'">
                                <i class="fa fa-info" (click)="tripModal(queue)"></i>
                                <i class="fa fa-map-marker" (click)="tripTrackingModal(queue)"></i>
                              </ng-container>
                              </div>
                              <div class="inside_box">
                                <div class="title_box">
                                  <div class="vehicle_number" appEllipsisView>
                                    {{queue.rc_no}}
                                  </div>
                                  <i class="fa fa-repeat" width="30px" style="margin-right: 8px;"
                                    (click)="openModal(queue)" *ngIf="queue.status != 'Booked'"></i>
                                  <span class="product badge badge-warning" *ngIf="queue.is_priority">
                                    Priority
                                  </span>
                                </div>
                                <div [ngClass]="'time bg_red'" style="left: 4%; right: 1%; width: max-content;"
                                  *ngIf="queue.loading_delay_time!='00:00' && queue.loading_delay_time!='0 min' && queue.status != 'Booked'">
                                  {{queue.loading_delay_time+" Late"}}
                                </div>
                                <div [ngClass]="'time bg_blue'" style="left: 4%; right: 1%; width: max-content;"
                                  *ngIf="queue.status == 'Booked'">
                                  {{queue.status}}
                                </div>
                                <div
                                  [ngClass]="queue.status == 'Completed' ? 'time bg_green' :queue.vehicle_status == 'On Time' ? 'time bg_green' : queue.vehicle_status == 'Delay' ? 'time bg_red' : 'time bg_yellow'">
                                  {{queue.status == 'Completed' ? "Completed" : (queue.vehicle_status != "On Time" ?
                                  queue.travel_duration+" "+queue.vehicle_status : queue.vehicle_status)}}
                                </div>
                                <div class="count_num">
                                  {{queue.queue_index}}
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="sliceIndex == -1">
                    <div class="no-queue" [class.active]="true">
                      <div class="vehicle_number">
                        No Queue Available On This Dock !!
                      </div>
                    </div>
                  </ng-container>

                </div>
                <div class="dock_sec" *ngIf="dockData.length == 0">
                  <div class="no-queue" [class.active]="true" style="width: 99.2%;">
                    <div class="vehicle_number">
                      No Dock Slots Available on this Date !!
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

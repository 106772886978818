import { DemoMaterialModule } from './../material.module';
import { AlertComponent } from './layout/header/alert-message/alert-message.component';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './layout/footer/footer.component';
import { BreadcrumbComponent } from './layout/header/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './layout/header/header.component';
import { NavbarComponent } from './layout/header/navbar/navbar.component';
import { SidebarComponent } from './layout/header/sidebar/sidebar.component';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { ScreenLoaderComponent } from './layout/screen-loader/screen-loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationComponent } from './layout/header/notification/notification.component';

const PAGES_COMPONENTS = [
  FooterComponent,
  NavbarComponent,
  BreadcrumbComponent,
  HeaderComponent,
  SidebarComponent,
  ScreenLoaderComponent,
  AlertComponent,
  NotificationComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    TranslateModule.forChild(),
    NgSelectModule,
    DemoMaterialModule,
  ],
  declarations: [
    ...PAGES_COMPONENTS,
    ScreenLoaderComponent,
    NotificationComponent,
  ],
  exports: [...PAGES_COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ThemeModule {}

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { AutomationService } from '../automation/automation.service';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { ApiService } from 'src/app/@core/services/api.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-stages',
  templateUrl: './stages.component.html',
  styleUrls: [
    '../automation/automation.component.scss',
    './stages.component.scss',
  ],
})
export class StagesComponent implements OnInit {
  stegeorder: any;
  currentid: any;
  targetid: any;
  loading = true;
  itemArray: any[] = [];
  process: any[] = [];
  processAction: any[] = [];
  processId: number = 0;
  actions: any[] = [];
  nextStageTat: any;
  previousStageTat: any;
  selectedCityIds = [1, 2, 3, 4];
  exportTime = { hour: 7, minute: 15, meriden: 'PM', format: 24 };
  tatUpdate = new Subject<string>();
  checkAuto: any;
  openModal: boolean = false;
  modalData: any[] = [];
  lastStateArray: any[] = [];
  rejectedStateArray: any[] = [];
  expectedTat: any;
  dataSave: any[] = [];
  stageConfigurationModalData: any[] = [];
  autoMethodsList: any[] = [];
  autoCompletionMethodId: any;
  autoCompletionMethodName: any;

  constructor(
    private automationService: AutomationService,
    public breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public alert: AlertService,
    public api: ApiService,
    public userService: UserService
  ) {
    let breadcumbs = [
      { name: 'Home', url: '/user/dashboard' },
      { name: 'Stage', url: null },
      { name: 'Stage Setting', link: 'user/list_stage_configuration' },
    ];
    this.breadcrumbService.getBreadcrumbs(breadcumbs);
    /** breadcums implementation end*/
    this.tatUpdate
      .pipe(debounceTime(2000), distinctUntilChanged())
      .subscribe((value) => {
        this.saveData();
      });
  }

  getProcess() {
    this.common.loading = true;
    this.automationService.getAllProcess().subscribe((processes) => {
      this.process = processes.data ?? [];
      this.common.loading = false;
      if (this.process.length) {
        this.process.map((item: any) => {
          this.processAction.push({
            id: item.processId,
            name: item.processName,
          });
        });
      }
      this.processId = this.processAction[0].id;
      this.getStage();
    });
  }

  sortData(i: number) {
    this.itemArray = this.process.filter((item: any) => {
      return item.temp_id != i + 1;
    });
  }

  anAdd(i: any, item: any, order: string) {
    let prev = this.process[i.temp_id - 1].preState;
    let last = this.process[i.temp_id - 1].lastState;
    if (order == 'prev') {
      if (last.indexOf(item.id == -1)) {
        last.push(item.id);
      }
    } else {
      if (prev.indexOf(item.id) == -1) {
        prev.push(item.id);
      }
    }
    this.saveData();
  }

  rejectAdd(i: any, item: any, order: string) {
    let prevRejected = this.process[i.temp_id - 1].preRejectedStateArray;
    let reject = this.process[i.temp_id - 1].rejectedState;
    if (order == 'prevRejected') {
      if (reject.indexOf(item.id == -1)) {
        reject.push(item.id);
      }
    } else {
      if (prevRejected.indexOf(item.id) == -1) {
        prevRejected.push(item.id);
      }
    }
    this.saveData();
  }
  onRemove(i: any, item: any, order: string, index: number) {
    const prx = this.process.find(
      (item: any) => item.temp_id === i.value.temp_id
    );
    let filter = (x: any) => x != item.id;
    if (order == 'prev') {
      prx.lastState = prx.lastState.filter(filter);
    } else if (order == 'next') {
      prx.preState = prx.preState.filter(filter);
    } else if (order == 'prevRejected') {
      prx.preRejectedStateArray = prx.preRejectedStateArray.filter(filter);
      prx.rejectedState = prx.rejectedState.filter(filter);
    } else {
      prx.rejectedState = prx.rejectedState.filter(filter);
      prx.preRejectedStateArray = prx.preRejectedStateArray.filter(filter);
    }
    this.saveData();
  }

  resetData() {
    setTimeout(() => {
      this.getStage();
    }, 200);
  }

  onDrop(event: CdkDragDrop<string[]>) {
    let arr: any[] = [];
    moveItemInArray(this.process, event.previousIndex, event.currentIndex);
    this.process.forEach((item: any, idx: any) => {
      item.temp_id = idx + 1;
      arr.push(item);
    });

    this.process = arr;
    this.saveData();
  }

  saveData() {
    this.common.loading = true;
    let processData = this.process.map((stage) => ({
      ...stage,
      tat: stage.tat || 0,
    }));
    let params = [
      {
        processId: this.processId,
        postData: processData,
      },
    ];
    this.automationService.saveStage(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.resetData();
      },
      (err) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  getStage() {
    this.common.loading = true;
    this.automationService
      .getAllStages(this.processId)
      .subscribe((processes) => {
        this.common.loading = false;
        this.process = processes.data ?? [];
        if (this.process.length) {
          this.itemArray = this.process.map((process: any) => {
            return {
              id: process.id,
              temp_id: process.temp_id,
              name: process.name,
            };
          });
        }
      });
  }
  getComepletionMethodName(methodId) {
    let method = this.autoMethodsList.filter((method) => method.id == methodId);
    return method.length > 0 ? method[0]['methodName'] : '';
  }
  getEvent(event: any) {
    this.processId = this.processAction[event.index].id;
    this.getStage();
  }
  ngOnInit(): void {
    this.getProcess();
    this.getAutoStageCompletionMethods();
  }

  modalShow(item: any) {
    this.getModalData(item);
    this.openModal = true;
    this.modalData[0] = item;
    this.modalData[0].lastState.forEach((element) => {
      this.itemArray.forEach((i) => {
        if (element == i.id) {
          this.lastStateArray.push({
            id: i.id,
            name: i.name,
          });
        }
      });
    });
    this.modalData[0].rejectedState.forEach((element) => {
      this.itemArray.forEach((i) => {
        if (element == i.id) {
          this.rejectedStateArray.push({
            id: i.id,
            name: i.name,
          });
        }
      });
    });
  }

  modalHide() {
    this.openModal = false;
    this.modalData = [];
    this.lastStateArray = [];
    this.rejectedStateArray = [];
    this.dataSave = [];
  }

  getModalDataSave(mappingType: number, nextStageId: number, event: any) {
  console.log('event: ', event);
    this.dataSave.push({
      mappingType: mappingType,
      nextStageId: nextStageId,
      expectedTat: event.target.value,
      vehicleLimit : parseInt(event.target.value)
    });
    console.log('dataSave: ', this.dataSave);
    return this.dataSave;
  }

  saveModalData() {
    this.common.loading = true;
    let params: any;
    params = {
      processId: this.processId,
      stageId: this.modalData[0].id,
      data: this.dataSave,
    };
    this.automationService.saveStageConfigurationModalData(params).subscribe(
      (res: any) => {
        this.common.loading = false;
        this.alert.info(res.message);
        this.modalHide();
      },
      (err) => {
        console.error(err);
        this.common.loading = false;
      }
    );
  }

  getModalData(item: any) {
    this.common.loading = true;
    let params = {
      processId: this.processId,
      stageId: item.id,
    };
    this.automationService
      .getStageConfigurationModalData(params.processId, params.stageId)
      .subscribe(
        (res: any) => {
          this.stageConfigurationModalData = res.data;
          console.log('this.stageConfigurationModalData: ', this.stageConfigurationModalData);
          this.common.loading = false;
          // this.alert.info(res.message);
        },
        (err) => {
          console.error(err);
          this.common.loading = false;
        }
      );
  }

  getSavedExpectedTat(mappingType: number, nextStageId: number, dataKey: string) {
    let dataList: any[];
    dataList = mappingType == 1 ? this.stageConfigurationModalData[0]?.onApprovedList : this.stageConfigurationModalData[0]?.onRejectedList
    const modalData = dataList?.filter((e) => e.next_stage_id == nextStageId);
    return modalData ? (dataKey == 'expectedTAT' ? modalData[0]?.expected_tat : modalData[0]?.vehicle_limit) : 0;
  }

  getAutoStageCompletionMethods() {
    let params = {
      plantId: this.userService._loggedInUser.plantId,
      methodType: 6,
    };
    this.common.loading = true;
    this.api.get('method/get-all-methods-by-method-type', params).subscribe(
      (res: any) => {
        this.common.loading = false;
        if (res.status) {
          this.autoMethodsList = res.data;
        }
      },
      (err: any) => {
        console.error('Error: ', err);
        this.common.loading = false;
      }
    );
  }

  onSelectedAutoMethod(event: any, item: any) {
    item.autoCompletionMethodId = event.id;
    this.saveData();
  }

  changeStagefunctionality(item: any, typeAuto: boolean) {
    if (typeAuto) {
      item.quickOut = false;
    } else {
      item.autoIn = false;
      item.autoOut = false;
    }
    this.saveData();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'app-asset-detail',
  templateUrl: './asset-detail.component.html',
  styleUrls: ['./asset-detail.component.scss']
})
export class AssetDetailComponent implements OnInit {
  @Input() public customFieldData:any;
  constructor() {
  }

  customFields: any[] = [];
  groupedFields:any;
  groupedValues:any[]=[];
  ngOnInit(): void {
    this.customFields = JSON.parse(this.customFieldData);
    let validField = this.customFields.filter((field)=>field.field_id!=null);
    this.groupedFields = _.groupBy(validField, 'field_id');

    this.groupedValues = _.map(this.groupedFields, (group, fieldId) => {
      const fieldName = group[0].field_name;
      const fieldData = _.map(group, 'value').filter(Boolean).join(', ');
      return { field_id: fieldId, field_name: fieldName, value: fieldData };
    });
  }

}

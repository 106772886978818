<div class="suggestion-container" [formGroup]="parentForm || searchForm" (keydown)="handleKeyDown($event)">


  <div class="tagsinput_main" *ngIf="(data && data.length) || getPreSelected(preSelected) || onInputTrigger;else nodata">
    <div class="tagsinput">
      <div class="selected" *ngIf="selectedSuggestions.length">
        <p *ngFor="let selectedSuggestion of selectedSuggestions; let i= index;">
          <!-- <input [value]="generateString(selectedSuggestion)" readonly> -->
          <span>{{generateString(selectedSuggestion)}}</span>
          <i class="far fa-times-circle" style="cursor: pointer" (click)="removeSelectedSuggestion(selectedSuggestion,i)"></i>
        </p>
      </div>

      <span>
        <input type="text" autocomplete="off" [formControlName]="controlName || 'search'" name="suggestion-input"
          class="form-control search-input tjr-input" (input)="getSuggestions()"
          [ngClass]="className || 'custom-input-1'" [(ngModel)]="searchText"
          [placeholder]="placeholder || 'Enter search text'" [id]="inputId || 'suggestion'"
          [name]="name || 'suggestion'" [readOnly]="readonly" required (ngModelChange)="handleUnselected()" (keydown.enter)="inputTrigger($event)">

        <i class="fas fa-sort-down"
          *ngIf="data && data.length && !readonly && data.length != selectedSuggestions.length" style="cursor: pointer"
          (click)="showAllSuggestion($event)"></i>
      </span>

    </div>
  </div>


  <ng-template #nodata>
    <span #nodata>
      <input type="text" class="form-control" disabled placeholder="Options are not Available">
    </span>
  </ng-template>

  <div class="suggestions" *ngIf="showSuggestions && suggestions.length">
    <p *ngFor="let suggestion of suggestions; let i = index;" class="suggestion" [ngClass]="{
      'active-suggestion': activeSuggestion === i,
      'list-highlight': (classMappings?.length && classMappings?.includes(i)) && activeSuggestion !== i
      }" [ngStyle]="{'opacity':(suggestion.status_name=='ASSIGNED')?'0.5':'1'}" (click)="selectSuggestion(suggestion,i)">
      {{generateString(suggestion)}}
    </p>
  </div>


</div>

<!-- How To Use -->

<!-- <ps-auto-suggestion (onSelected)="selectedUser = $event" url=" Suggestion/getFoAdminList" display="employeename"
placeholder="Enter User Name" [preSelected]="null"></ps-auto-suggestion> -->

import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { BreadcrumbService } from 'src/app/theme/layout/header/breadcrumb/breadcrumb.service';
import { interval, Subscription } from 'rxjs';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RoleService } from 'src/app/@core/services/role.service';
import { PlantService } from '../plant/plant.service';
import { UserService } from 'src/app/@core/services/user.service';


@Component({
  selector: 'app-plant-live-view',
  templateUrl: './plant-live-view.component.html',
  styleUrls: ['./plant-live-view.component.scss'],
})
export class PlantLiveViewComponent implements OnInit {
  @ViewChild('carousel') carousel:NgbCarousel;
  @ViewChild('process') carousel1:NgbCarousel;
  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  dialogRef!: MatDialogRef<any>;


  processData:any[]=[];
  processList:any[]=[];
  carouselInterval:  Subscription;
  processId:number=-1;
  selectedProcessName:string=null;
  runCarousel:boolean=true;
  carouselTime:number=5000;
  slideIn: boolean = true;
  slideOut: boolean = false;
  animationState: string = 'in'; // Add this line
  animationState1: string = 'out'; // Add this line
  openDialog:boolean=false;
  refreshTime:number=60000;
  activeSlideIndex:number=0;
  plantId:number= null;
  plantList:any[] = [];

  @Input() modalDisplay: boolean;
  @Input() modalProcessName: string;
  @Input() refData: any;



  constructor(
    public breadcrumbService: BreadcrumbService,
    public common: CommonService,
    public api: ApiService,
    public dialog:MatDialog,
    public role:RoleService,
    public plantService:PlantService,
    public userService:UserService


  ){
    this.breadcrumbService.setActiveBreadCrumb(false);

    this.common.sidebarToggled(true);
    if (this.role.user_role == 'COMPANYADMIN') {
      if(this.refData!=null){
        this.plantId = this.refData.plantId;
        this.getProcess();
      }else{
        this.getPlantById(this.userService._loggedInUser.companyId);
      }
    }
    else {
      this.getProcess();
    }
  }


  ngOnInit(): void {
    this.getProcess();
  }
  ngAfterViewInit():void{
    this.synchronizeCarousels();
  }

  ngOnDestroy(): void{
    if(this.carouselInterval){
      this.carouselInterval.unsubscribe();
    }
  }

  getPlantById(id?: any) {
    this.common.loading = true;
    let params = {
      id: id,
    }
    this.plantService.getPlantById(params)
      .subscribe((res: any) => {
        this.common.loading = false;
        this.plantList = res.data;
      }, (err: any) => {
        console.error('Error', err);
        this.common.loading = false;
      });
  }

  selectedPlant(event: any) {
    this.plantId = event.plantId;
    this.getProcess()
  }i


  synchronizeCarousels() {
    // Listen for slide change events and synchronize the carousels
    this.carousel.slide.subscribe(event => {
      this.carousel1.select(event.current);
    });

    this.carousel1.slide.subscribe(event => {
      this.carousel.select(event.current);
    });
  }

  onSlide(event: any) {
    // Prevent infinite loop of synchronizing both carousels
    if (event.source === this.carousel) {
      this.carousel1.select(event.current);
    } else if (event.source === this.carousel1) {
      this.carousel.select(event.current);
    }
  }

  toggleDialog(){
    this.openDialog=!this.openDialog
    if(this.openDialog){
      this.dialogRef = this.dialog.open(this.dialogTemplate, {
        width: '230px',
        hasBackdrop: false,  // Disable backdrop
        position: { right: '27px', top: '7%' },  // Position the dialog to the right and slightly down from the top
        // panelClass: 'custom-dialog-container'  // Optional: Custom class to style dialog
      });
    }
    else{
      this.dialogRef.close();
    }
  }


  selectProcess(event){
  // console.log('event: ', event);
    this.processId=event.processId;
    // this.selectedProcessName=event.processName;
    if(this.carouselInterval){
      this.carouselInterval.unsubscribe();
    }
    const selectedIndex=this.processData.findIndex(p=>p.process_id===this.processId);
    if(selectedIndex!=-1){
      this.carousel.select(`ngb-slide-${selectedIndex}`)
    }
    if(selectedIndex!=-1){
      this.carousel1.select(`ngb-slide-${selectedIndex}`)
    }
    this.carousel.pause();
    this.carousel1.pause();
    this.runCarousel=false;

  }
   //get all process list
   getProcess() {
      this.common.loading = true;
      let params = {
        processActive:true,
        plantId:this.plantId
      }
      this.api.get('process/get-all-process',params).subscribe(
        (res: any) => {
          this.common.loading = false;
          this.processList=res.data;
          this.getPlantLiveView();
          this.startCarousel();
        },
        (err: any) => {
          this.common.loading = false;
          console.error('Error: ', err);
          }
      );

  }



  getPlantLiveView(){
    this.api.get('plant/get-plant-view',{plantId:this.plantId}).subscribe(
      (res: any) => {
        if (res.status) {
          this.common.loading = false;
          this.processData=res.data;
        }
      },
      (err: any) => {
        this.common.loading = false;
        console.error('Error: ', err);
      }
      );
  }


  UpdateRefreshTime(event){
    const selectedValue=event.target.value
    if(selectedValue.endsWith('s')){
      this.refreshTime=parseInt(selectedValue)*1000;
    }
    else if(selectedValue.endsWith('m')){
      this.refreshTime=parseInt(selectedValue)*60000;
    }
  }

  updateCarousel(event){
    const selectedValue=event.target.value
    if(selectedValue.endsWith('s')){
      this.carouselTime=parseInt(selectedValue)*1000;
    }
    else if(selectedValue.endsWith('m')){
      this.carouselTime=parseInt(selectedValue)*60000;
    }
    this.startCarousel();
  }


  callNextProcess(){
    this.getPlantLiveView();
  }


  startCarousel(){
    this.selectedProcessName=null
    if(this.carouselInterval){
      this.carouselInterval.unsubscribe();
    }
    console.log('this.runCarousel: ', this.runCarousel);
    if(this.runCarousel){
      this.carouselInterval= interval(this.refreshTime).subscribe(()=>{
        this.callNextProcess();
      })


      this.carousel.cycle();
      this.carousel1.cycle();
    }
    else{
      this.carousel.pause();
      this.carousel1.pause();
    }
  }


}

<div class="container-fluid">
  <div class="container-card">
    <div class="container-card-body">
      <div class="card">
        <div class="card-header">
          <div class="header-block">
            <h3 class="card-title">
              {{"Alert Management" | customTranslate}}
            </h3>
          </div>
        </div>


        <div class="card-body">
          <div class="row">
            <div class="col-xl-4 col-lg-6 col-sm-12">
              <label style="width: 250px;">{{"alert_execution_type" | customTranslate}}</label>
              <select class="form-control" name="alertt_type" [(ngModel)]="selectedAlerttType"
                [disabled]="btn == 'update'" (change)="resetDetail(selectedAlerttType)">
                <option *ngFor="let item of alertTypes" [value]="item.value">{{item.type | customTranslate}}</option>
              </select>
            </div>
            <div class="form-group col-xl-3 col-lg-6 col-sm-12">
              <label class="required">{{"alert_title" | customTranslate}}</label>
              <input type="text" name="alertTitle" class="form-control" placeholder="Alert Title"
                [(ngModel)]="alertTitle">
              <div class="error" *ngIf="titleValidator && titleValidator.error">
                <span>{{titleValidator.msg}} </span>
              </div>
            </div>


          </div>

          <div class="row"
            style="background-color: #ededed; margin: 0; margin-top: 5px; margin-bottom: 16px; padding: 15px 0px;border-radius: 5px;"
            *ngIf="selectedAlerttType=='Schedular'">
            <div class="col-xl-12 col-lg-12 col-sm-12">
              <div class="row">
                <div class="col-xl-2 col-lg-6 col-sm-12">
                  <label class="required">{{"alert_running time" | customTranslate}}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-6 col-sm-12" style="margin-top: 20px;">
                  <form class="d-flex">
                    <label style="width: 250px;">{{"for_every" | customTranslate}}</label>
                    <input type="number" name="forevery" class="form-control" [(ngModel)]="iterationCount" min="1"
                      [step]="'5'" (change)="checkStepSize()">
                  </form>
                </div>

                <div class="col-xl-4 col-lg-6 col-sm-12" style="margin-top: 20px;">
                  <form class="d-flex">
                    <select class="form-control" name="iterationType" [(ngModel)]="selectedIteration"
                      (change)="checkStepSize()">
                      <option *ngFor="let item of iterationType" [value]="item.value">{{item.type}}</option>
                    </select>
                  </form>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-xl-4 col-lg-6 col-sm-12" *ngIf="fromNow==false">

                  <form class="d-flex">
                    <label style="width: 250px;">{{"start_at" | customTranslate}}</label>
                    <time-picker style="width: 200px;" [stepCount]="300" [defaultValue]="runningTime"
                      (onChange)="runningTime=$event"></time-picker>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="row modal-body" id="action_id" style="background-color: #ededed; margin: 0;border-radius: 5px;">
            <div style="width: 100%;">
              <label for="my-input" class="mb-3">{{"alert_running condition" | customTranslate}}</label>
              <div>
                <div class="form-group col-xl-3 col-lg-6 col-sm-12">
                  <label class="required">{{"alert_on" | customTranslate}}</label>
                  <select class="form-control" name="alertOn" [(ngModel)]="selectedAlerton" (change)="callApi()"
                    [disabled]="btn == 'update'">
                    <option
                      *ngFor="let item of (selectedAlerttType == 'Schedular' ? alertVariable : alertVariableEvent) | keyvalue"
                      [value]="item.key">{{item.key}}</option>
                  </select>
                </div>
              </div>
              <div>
                <ng-container *ngFor="let item of alertcount;index as i">
                  <div class="accordionn">
                    <div class="accordion_sec">
                      <div class="click_accordion" (click)="accordian(i)">
                        <label for="my-input">{{"select" | customTranslate}}
                          <span style="padding:'5px'">{{'Alert Conditions' | customTranslate}}</span></label>
                        <i class="fas fa-caret-down"></i>
                      </div>

                      <div class="accordion_open" *ngIf="hideShow[i]==true">
                        <div class="row" style="padding: 10px;">
                          <div class="col-xl-3 col-lg-6 col-sm-12" *ngIf="this.selectedAlerttType=='Schedular'">
                            <ps-auto-suggestion (onSelected)="selectedVariable($event,i)"
                              [data]="alertVariable[selectedAlerton]" display="value" placeholder="Select Condition"
                              name="value" [preSelected]="{value:alertRunningCondition[i].variable}">
                            </ps-auto-suggestion>
                            <label class="required-label">*</label>
                          </div>
                          <div class="col-xl-3 col-lg-6 col-sm-12" *ngIf="this.selectedAlerttType=='Event'">
                            <ps-auto-suggestion (onSelected)="selectedVariable($event,i)"
                              [data]="alertVariableEvent[selectedAlerton]" display="value"
                              placeholder="Select Condition" name="value"
                              [preSelected]="{value:alertRunningCondition[i].variable}">
                            </ps-auto-suggestion>
                            <label class="required-label">*</label>
                          </div>
                          <ng-container class="col-xl-3 col-lg-6 col-sm-12"
                            *ngIf="processData.length||stageList.length||allDestinations.length||status.length||assets.length||category.length">
                            <div class="col-xl-3 col-lg-6 col-sm-12"
                              *ngIf="selectedAlerton=='Process'||selectedAlerton=='Stage'||selectedAlerton=='Destination'||selectedAlerton=='Action' || (selectedAlerton=='Visitor' && alertRunningCondition[i].variable=='Visitor Stage Overcrowded') || (selectedAlerton=='Visitor' && alertRunningCondition[i].variable=='Visitor Overtat')">
                              <ps-auto-suggestion (onSelected)="selectedProcess($event,i)" [data]="processData"
                                display="processName" placeholder="Select Process" name="processName"
                                inputId="processName"
                                [preSelected]="{processName:alertRunningCondition[i].processName}">
                              </ps-auto-suggestion>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-sm-12"
                              *ngIf="selectedAlerton=='Stage'||selectedAlerton=='Destination'||selectedAlerton=='Action' || (selectedAlerton=='Visitor' && alertRunningCondition[i].variable=='Visitor Stage Overcrowded')">
                              <ps-auto-suggestion (onSelected)="selectedStage($event,i)"
                                [data]="alertRunningCondition[i].processId==null?processWiseStages[0]:alertRunningCondition[i].stageId!=null && alertRunningCondition[i].processId!=null && selectedAlerton == 'Action' ? processWiseStages[i] : processWiseStages[i+1]"
                                display="stage_name" placeholder="Choose Stage"
                                [preSelected]="{stage_name:alertRunningCondition[i].stageName}">
                              </ps-auto-suggestion>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-sm-12" *ngIf="selectedAlerton=='Destination'">
                              <ps-auto-suggestion (onSelected)="selectedDestination($event,i)"
                                [data]="(alertRunningCondition[i].stageId==null && alertRunningCondition[i].processId==null)?stageWiseDestinations[0]:stageWiseDestinations[i+1]"
                                display="stage_destination_code" placeholder="Choose Destination"
                                [preSelected]="{stage_destination_code:alertRunningCondition[i].destination_code}">
                              </ps-auto-suggestion>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-sm-12"
                              *ngIf="selectedAlerton=='Field' || selectedAlerton=='Field Group'">
                              <ps-auto-suggestion (onSelected)="selectedProcessStage($event,i)"
                                [data]="processStageOrder" display="order_name" placeholder="Choose Process Stage"
                                [preSelected]="{order_name:alertRunningCondition[i].processStageName}">
                              </ps-auto-suggestion>
                            </div>
                            <div class="select_stage_drop"
                              *ngIf="selectedAlerton=='Action' || selectedAlerton=='Field' || selectedAlerton=='Field Group'">
                              <ps-auto-suggestion (onSelected)="selectedAction($event,i)"
                                [data]="alertRunningCondition[i].stageId==null && alertRunningCondition[i].processId==null?stageWiseActions[i]:((selectedAlerton == 'Field' || selectedAlerton=='Field Group') ? stageWiseActions[i] : stageWiseActions[i+1])"
                                display="actionName" placeholder="Choose Action"
                                [preSelected]="{actionName:alertRunningCondition[i].actionName}">
                              </ps-auto-suggestion>
                            </div>
                            <div class="col-lg-2 select_stage_drop"
                              *ngIf="selectedAlerton=='Field' || selectedAlerton=='Field Group'">
                              <ps-auto-suggestion (onSelected)="selectedField($event,i)" [data]="actionWiseField[i]"
                                display="field_name" placeholder="Choose Field"
                                [preSelected]="{field_name:alertRunningCondition[i].fieldName}">
                              </ps-auto-suggestion>
                            </div>

                            <!-- Assets alerts -->
                            <div class="col-xl-3 col-lg-6 col-sm-12"
                              *ngIf="selectedAlerton=='Asset' && alertRunningCondition[i].variable=='Status'">
                              <div><ps-auto-suggestion (onSelected)="selectedStatus($event,i)" [data]="status"
                                  display="status_name" placeholder="Select Status" name="status_name"
                                  inputId="status_name"
                                  [preSelected]="{status_name:alertRunningCondition[i].statusName}">
                                </ps-auto-suggestion></div>
                              <div style="margin-left: 15%">
                                <label class="required-label" style="margin-left: 335px;
                            margin-top: -43px;">*</label>
                              </div>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-sm-12" *ngIf="selectedAlerton=='Asset'">
                              <ps-auto-suggestion (onSelected)="selectedCategory($event,i)" [data]="category"
                                display="asset_category_name" placeholder="Select Category" name="asset_category_name"
                                inputId="asset_category_name"
                                [preSelected]="{asset_category_name:this.alertRunningCondition[i].categoryName}">
                              </ps-auto-suggestion>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-sm-12" *ngIf="selectedAlerton=='Asset'">
                              <ps-auto-suggestion (onSelected)="selectedAsset($event,i)" [data]="categoryWiseAssets[i]"
                                display="asset_name" placeholder="Select Asset" name="asset_name" inputId="asset_name"
                                [preSelected]="{asset_name:this.alertRunningCondition[i].assetName}">
                              </ps-auto-suggestion>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-sm-12" *ngIf="selectedAlerton=='Zone' || (selectedAlerton=='Visitor' && alertRunningCondition[i].variable=='Visitor Entering Restricted Zone')">
                              <ps-auto-suggestion (onSelected)="selectedZone($event,i)" [data]="zones"
                                display="zone_name" placeholder="Select Zone" name="zone_name" inputId="zone_name"
                                [preSelected]="{zone_name:this.alertRunningCondition[i].zoneName}">
                              </ps-auto-suggestion>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-sm-12"
                              *ngIf="selectedAlerton=='User' && selectedAlerttType=='Event'">
                              <ps-auto-suggestion (onSelected)="selectedUser($event,i)" [data]="users"
                                display="name" placeholder="Select User" name="userName" inputId="userName"
                                [preSelected]="{userName:this.alertRunningCondition[i].userName}">
                              </ps-auto-suggestion>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-sm-12"
                              *ngIf="selectedAlerton=='Staff' && selectedAlerttType=='Event'">
                              <ps-auto-suggestion (onSelected)="selectedStaff($event,i)" [data]="staff"
                                display="first_name" placeholder="Select Staff" name="first_name" inputId="first_name"
                                [preSelected]="{first_name:this.alertRunningCondition[i].staffName}">
                              </ps-auto-suggestion>
                            </div>
                            <label class="required-label" style="margin-left: -9px;"
                              *ngIf="selectedAlerton=='Process'||selectedAlerton=='Stage'||selectedAlerton=='Destination'||selectedAlerton=='Action'">*</label>
                            <div class="col-lg-2 select_stage_drop" *ngIf="selectedAlerton=='Field Group'">
                              <ps-auto-suggestion (onSelected)="selectedSubField($event,i)" [data]="fieldSubGroups[i]"
                                display="field_sub_group" placeholder="Choose Sub Field Group"
                                [preSelected]="{field_sub_group:alertRunningCondition[i].subFieldName}">
                              </ps-auto-suggestion>
                            </div>
                            <div class="col-lg-2 select_stage_drop" *ngIf="selectedAlerton=='Checklist'">
                              <ps-auto-suggestion (onSelected)="selectedChecklist($event,i)" [data]="checklists"
                                display="checklist_title" placeholder="Choose CheckList"
                                [preSelected]="{checklist_title:alertRunningCondition[i].checkListName}">
                              </ps-auto-suggestion>
                            </div>
                            <label class="required-label" style="margin-left: -9px;"
                              *ngIf="selectedAlerton=='Process'||selectedAlerton=='Stage'||selectedAlerton=='Destination'||selectedAlerton=='Action'">*</label>
                          </ng-container>
                          <div class="col-xl-3 col-lg-6 col-sm-12" *ngIf="alertRunningCondition[i].issue_type==-1">
                            <label for="my-input">{{"Add Compliance" | customTranslate}}<input type="checkbox" style="width: 5.5%;margin: 2%;"
                                class="form-control set-checkbox" [(ngModel)]="alertRunningCondition[i].is_compliance">
                            </label>
                          </div>
                        </div>
                        <div class="accordion_box" *ngFor="let data of item;index as ind">
                          <div class="row">
                            <div class="form-group col-xl-2 col-lg-4 col-sm-12">
                              <label class="required">{{"Alert Type" | customTranslate}}</label>
                              <ps-auto-suggestion (onSelected)="selectedAlertType($event,i,ind)" [data]="alertTypeList"
                                display="type_name" placeholder="Choose Alert Type"
                                [preSelected]="{type_name:this.alertRunningCondition[i].alertType[ind].alertTypeName}">
                              </ps-auto-suggestion>
                            </div>

                            <div class="form-group col-xl-2 col-lg-4 col-sm-12"
                              *ngIf="(selectedAlerttType=='Schedular'&& this.selectedAlerton!='Vehicle' && this.selectedAlerton!='Checklist' && this.selectedAlerton!='Trip' && this.selectedAlerton!='Asset'  && this.selectedAlerton!='Zone' && this.selectedAlerton!='Visitor')  || (this.selectedAlerton=='Field Group' && alertRunningCondition[i].variable != 'Value')||(this.selectedAlerton=='User' && this.selectedAlerttType!='Event')">
                              <label class="required">{{"Min" | customTranslate}}</label>
                              <input type="number" name="minValue" class="form-control" placeholder="Min"
                                (change)="selectedMin($event,i,ind)"
                                [(ngModel)]="alertRunningCondition[i].alertType[ind].min">
                            </div>
                            <div class="form-group col-xl-2 col-lg-4 col-sm-12"
                              *ngIf="(selectedAlerttType=='Schedular'&& this.selectedAlerton!='Vehicle' && this.selectedAlerton!='Checklist' && this.selectedAlerton!='Trip' && this.selectedAlerton!='Asset'  && this.selectedAlerton!='Zone' && this.selectedAlerton!='Visitor')  || (this.selectedAlerton=='Field Group' && alertRunningCondition[i].variable != 'Value')||(this.selectedAlerton=='User' && this.selectedAlerttType!='Event') ">
                              <label class="required">{{"Max" | customTranslate}}</label>
                              <input type="number" name="maxValue" class="form-control" placeholder="Max"
                                (change)="selectedMax($event,i,ind)"
                                [(ngModel)]="alertRunningCondition[i].alertType[ind].max">
                            </div>
                            <div class="form-group col-xl-6 col-lg-6 col-sm-6"
                              *ngIf="selectedAlerttType=='Event' && this.selectedAlerton=='Field Group' && alertRunningCondition[i].variable == 'Value'">
                              <div class="field">
                                <label>{{"Enter Value"}}</label>
                                <input type="text" class="field_class" [(ngModel)]="alertRunningCondition[i].fieldValue"
                                  (change)="enterValues($event , 'Field Group' , i)">
                              </div>
                            </div>
                            <div class="form-group col-xl-6 col-lg-6 col-sm-6"
                              *ngIf="selectedAlerttType=='Event' && this.selectedAlerton=='Field' && alertRunningCondition[i].variable == 'Field Value'">
                              <div class="row" style="width: 100%; margin: auto;">
                                <ng-container *ngFor="let field of alertRunningCondition[i].field_value">
                                  <div class="col-md-6 modal_col" *ngIf="field.field_type!='LABEL'">
                                    <div *ngIf="field.mandatory==1" style="color:red">*</div>
                                    <div class="field" *ngIf="field.field_type=='TEXTFIELD'">
                                      <label>{{field.field_name}}</label>
                                      <input type="text" class="field_class"
                                        [(ngModel)]="alertRunningCondition[i].fieldValue"
                                        (change)="enterValues($event , field , i)">
                                    </div>
                                    <div class="field" *ngIf="field.field_type=='NUMBERFIELD'">
                                      <label>{{field.field_name}}</label>
                                      <input type="number" class="field_class"
                                        (change)="enterValues($event , field , i)"
                                        [(ngModel)]="alertRunningCondition[i].fieldValue">
                                    </div>

                                    <div class="field" *ngIf="field.field_type=='TIME'">
                                      <label>{{field.field_name}}</label>
                                      <input type="time" class="field_class" (change)="enterValues($event , field , i)"
                                        [(ngModel)]="alertRunningCondition[i].fieldValue">
                                    </div>

                                    <div class="field" *ngIf="field.field_type=='DATETIMEPICKER'">
                                      <label>{{field.field_name}}</label>
                                      <input type="datetime-local" class="field_class"
                                        (change)="enterValues($event , field , i)"
                                        [(ngModel)]="alertRunningCondition[i].fieldValue">
                                    </div>

                                    <div class="field" *ngIf="field.field_type=='TEXTAREA'">
                                      <label>{{field.field_name}}</label>
                                      <textarea rows="3" cols="50" (change)="enterValues($event , field , i)"
                                        class="field_class"></textarea>
                                    </div>
                                    <div class="field" *ngIf="field.field_type=='DATEPICKER'">
                                      <label>{{field.field_name}}</label>
                                      <input type="date" class="field_class" (change)="enterValues($event , field , i)"
                                        [(ngModel)]="alertRunningCondition[i].fieldValue">
                                    </div>
                                    <div class="field r_field" *ngIf="field.field_type=='RADIOBUTTON'">
                                      <label>{{field.field_name}}</label>
                                      <div style="display:flex">
                                        <div *ngFor="let opt of field.options;index as j" class="radio_check">
                                          <input type="radio" [name]="'field-' + field.master_field_id"
                                            [id]="'field-' + j + field.master_field_id" value="{{opt.name}}"
                                            (change)="enterValues($event , field , i)"
                                            [checked]="alertRunningCondition[i].fieldValue == opt.name">&nbsp;<label
                                            [for]="'field-' + j + field.master_field_id">{{opt.name}}</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="field c_field" *ngIf="field.field_type=='CHECKBOX'">
                                      <label>{{field.field_name}}</label>
                                      <div style="display:flex">
                                        <div *ngFor="let opt of field.options" class="radio_check">
                                          <input type="checkbox" value="{{opt.name}}"
                                            [name]="'field'+i + field.master_field_id"
                                            [id]="'field'+i + field.master_field_id"
                                            (change)="enterValues($event  , field , i)"
                                            [checked]="alertRunningCondition[i].fieldValue == opt.name">&nbsp;<label
                                            [for]="'field'+i + field.master_field_id">{{opt.name}}</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="field"
                                      *ngIf="field.field_type=='SELECTLISTSINGLE' || field.field_type=='SELECTLISTMULTIPLE'">
                                      <label>{{field.field_name}}</label>
                                      <ps-auto-suggestion (onSelected)="enterValues($event, field, i)"
                                        [data]="field.options" [display]="field.label" placeholder="Choose option"
                                        [(ngModel)]="alertRunningCondition[i].fieldValue">
                                      </ps-auto-suggestion>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                            <div class="form-group col-xl-2 col-lg-4 col-sm-12">
                              <label>{{"External Alert Id" | customTranslate}}</label>
                              <input type="number" name="extrnalAlertId" class="form-control"
                                placeholder="External Alert Id" (change)="selectedExternalAlertId($event,i,ind)"
                                [(ngModel)]="alertRunningCondition[i].alertType[ind].externalAlertId">
                            </div>
                            <div class="form-group col-xl-2 col-lg-4 col-sm-12"
                              *ngIf="(selectedAlerttType=='Schedular'&& this.selectedAlerton!='Vehicle' && this.selectedAlerton!='Transaction') || (this.selectedAlerton=='Field Group' && alertRunningCondition[i].variable != 'Value')">
                              <div class="plus_minus">
                                <button style="border: 1px solid #00b400;margin-top: 28px;"><i class="fa fa-plus"
                                    aria-hidden="true" (click)="plusAlertType(i)"></i></button>
                                <button *ngIf="item.length>1" style="border: 1px solid #f90000;margin-top: 28px;"><i
                                    class="fa fa-minus" aria-hidden="true" (click)="minusAlertType(i,ind)"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="increase_row_icons">
                      <div class="plus_minus">
                        <button style="border: 1px solid #00b400;"><i class="fa fa-plus" aria-hidden="true"
                            (click)="alertcounter=alertcounter + 1;plus()"></i></button>
                        <button *ngIf="alertcount.length>1" style="border: 1px solid #f90000;"><i class="fa fa-minus"
                            aria-hidden="true" (click)="minus(i)"></i></button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="form-group action-block mt-3">
            <div class="btn-block">
              <button type="submit" class="btn btn-muted" (click)="common.backClicked()">
                {{"back" | customTranslate}} </button>
              <button class="btn btn-warning" (click)="resetDetail('Schedular')"> {{"reset" | customTranslate}} </button>
              <button type="submit" class="btn btn-submit" (click)="formValidator()"> {{btn | customTranslate}} </button>
            </div>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="header-block">
                <h3 class="card-title">
                  {{"alert_list" | customTranslate}}
                </h3>
              </div>
            </div>

            <div class="card-body">
              <table class="custom-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead class="thead-light">
                  <tr>
                    <th>{{"serial_no" | customTranslate}}</th>
                    <th>{{"title" | customTranslate}}</th>
                    <th>{{"map_on" | customTranslate}}</th>
                    <th>{{"duration_type" | customTranslate}}</th>
                    <th class="action">{{"action" | customTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of alerts let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{row.alertTitle}}</td>
                    <td>{{row.alertOn}}</td>
                    <td>{{row.alertExecutionType}}</td>
                    <td class="action">
                      <div class="btn-block">
                        <button class="btn btn-success" (click)="edit(row)">{{"edit" | customTranslate}}</button>
                        <button class="btn btn-danger" (click)="confirmAlert(row)">{{"delete" | customTranslate}}</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container-fluid">
    <div class="container-card">
        <div class="container-card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header pb-2">
                            <div class="header-block">
                                <h3 class="card-title">
                                    {{'mis_report'|translate}}
                                </h3>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row tab_sec">
                                <div class="col-md-12 date_div">
                                    <div class="row">
                                        <div class="col-md-4 date">
                                            <label>{{'start_date:'|translate}}</label>
                                            <input class="form-control" type="date"
                                                [(ngModel)]="fromDate">
                                        </div>
                                        <div class="col-md-4 date">
                                            <label>{{'end_date:'|translate}}</label>
                                            <input class="form-control" type="date" [min]="fromDate" [(ngModel)]="toDate">
                                        </div>
                                        <div class="col-md-1 btn-block text-right">
                                            <button class="btn btn-primary" style="margin-top: 25px;" (click)="getTripsReport()">{{"search" |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="justify-content: space-between;">
                            <div class="tab_section">
                                <div class="tab" *ngFor="let tabitem of tabData"
                                    [class.active]="activeSection == tabitem.value"
                                    (click)="activeSection = tabitem.value;tabChange()">
                                    <p>{{tabitem.tabname|translate}}</p>
                                </div>
                            </div>
                            <div class="icons">
                                <i (click)="exportAsExcel(data,headers,'MIS_'+activeSection+'_Report1')" class="fa fa-file-excel" style="margin-left: 10px;color:rgb(57 117 22);cursor: pointer;"></i>
                                <i class="fa fa-file-pdf" style="margin-left: 10px;color:rgb(231 87 53);cursor: pointer;" (click)="downloadPdf()"></i>
                            </div>
                           </div>

                            <section class="open_sec" *ngIf="activeSection == 'Trips'">
                                <div class="page_design">
                                    <div class="view_table">
                                        <div class="filter_sec">
                                            <div class="row">
                                                <div class="col-md-3 coll">
                                                    <label for="my-input">{{"process" | translate}}
                                                    </label>
                                                    <ps-auto-suggestion (onSelected)="selectedProcess($event)"
                                                        [data]="processList" display="processName"
                                                        placeholder="Choose process"
                                                        [preSelected]="processSelected" isMultiSelect="true">
                                                    </ps-auto-suggestion>
                                                </div>
                                                <div class="col-md-3 col1">
                                                    <label>{{'stage:'|translate}}</label>
                                                    <ps-auto-suggestion (onSelected)="selectedStage($event)"
                                                        [data]="stageList" display="stage_name"
                                                        placeholder="Choose Stage" isMultiSelect="true"
                                                        [preSelected]="stageSelected">
                                                    </ps-auto-suggestion>
                                                </div>
                                                <div class="col-md-3 col1">
                                                    <label>{{'vehicle_number'|translate}}</label>
                                                    <ps-auto-suggestion (onSelected)="selectVehicle($event)" [data]="vehicleList" display="vehicle_rsn"
                                                    placeholder="Select Vehicle" isMultiSelect="true" >
                                                    </ps-auto-suggestion>
                                                </div>
                                                <div class="col-md-3 col1">
                                                    <label>{{'status'|translate}}</label>
                                                    <ps-auto-suggestion (onSelected)="selectStatus($event)" [data]="statusList" display="status"
                                                    placeholder="Select status" isMultiSelect="true" >
                                                    </ps-auto-suggestion>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="table_sec" *ngIf="responseData?.length > 0" >
                                            <table class="table table-bordered table-striped" #tableToExport >
                                                <thead>
                                                    <tr>
                                                        <th style="width:120px !important">{{'vehicle_number'|translate}}</th>
                                                        <th>{{'txn_id'|translate}}</th>
                                                        <th>{{'process'|translate}}</th>
                                                        <th>{{'trip_status'|translate}}</th>
                                                        <ng-container *ngFor="let action of headers">
                                                            <th>{{action.action_name}}</th>
                                                        </ng-container>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr *ngFor="let row of data;  let i = index">
                                                        <td appEllipsisView style="width:120px !important">{{row.vehicle_rc_no}}</td>
                                                        <td>{{row.transaction_name }}</td>
                                                        <td>{{row.process_name}}</td>
                                                        <td
                                                        [ngClass]="{'bg-green-200 text-green-800':row.trip_status=='COMPLETED','bg-yellow-200 text-yellow-800':row.trip_status=='INPROGRESS','bg-pink-200 text-pink-800':(row.trip_status=='CANCELLED'),'bg-orange-200 text-orange-800':(row.trip_status=='AUTO CANCELLED')}">
                                                        >{{row.trip_status}}</td>
                                                        <ng-container *ngFor="let response of headers, let ind = index">
                                                            <!-- <td >{{getTripActionResponse(headers[ind].action_id,row.actions)}}
                                                            </td> -->
                                                            <td ><span class="status-badge" [ngClass]="{'badge-warning':row[headers[ind].action_id]=='OVERRIDED PASS','badge-success':row[headers[ind].action_id]=='PASS','badge-danger':row[headers[ind].action_id]=='FAIL'||row[headers[ind].action_id]=='OVERRIDED FAIL'}">{{(row[headers[ind].action_id]=='PASS' || row[headers[ind].action_id]=='FAIL'||row[headers[ind].action_id]=='OVERRIDED PASS'||row[headers[ind].action_id]=='OVERRIDED FAIL')?row[headers[ind].action_id]:'-'}}</span>
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="open_sec" *ngIf="activeSection == 'Transporter'">
                                <div class="page_design">
                                    <div class="view_table">
                                        <div class="filter_sec">
                                            <div class="row">
                                                <div class="col-md-3 coll">
                                                    <label for="my-input">{{"process" | translate}}
                                                    </label>
                                                    <ps-auto-suggestion (onSelected)="selectedProcess($event)"
                                                        [data]="processList" display="processName"
                                                        placeholder="Choose process"
                                                        [preSelected]="processSelected" isMultiSelect="true">
                                                    </ps-auto-suggestion>
                                                </div>
                                                <div class="col-md-3 col1">
                                                    <label>{{'stage:'|translate}}</label>
                                                    <ps-auto-suggestion (onSelected)="selectedStage($event)"
                                                        [data]="stageList" display="stage_name"
                                                        placeholder="Choose Stage" isMultiSelect="true"
                                                        [preSelected]="stageSelected">
                                                    </ps-auto-suggestion>
                                                </div>
                                                <div class="col-md-3 col1">
                                                    <label>{{'transporter'|translate}}</label>
                                                    <ps-auto-suggestion (onSelected)="selectTransporter($event)" [data]="transporters" display="transporterName"
                                                    placeholder="Select Transporter" isMultiSelect="true" >
                                                    </ps-auto-suggestion>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="table_sec" *ngIf="responseData?.length > 0 && data.length">
                                            <table class="table table-bordered table-striped" #tableToExport>
                                                <thead>
                                                    <tr>
                                                        <th>{{'Transporter'|translate}}{{'name'|translate}}</th>
                                                        <th>{{'Total Vehicle'|translate}}</th>
                                                        <th>{{'inprogress'|translate}}</th>
                                                        <th>{{'Cancelled'|translate}}</th>
                                                        <th>{{'completed'|translate}}</th>
                                                        <ng-container *ngFor="let action of headers">
                                                            <th>{{action.action_name}}</th>
                                                        </ng-container>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr *ngFor="let row of data;  let i = index">
                                                        <td>{{row.transporter_name}}</td>
                                                        <td>{{row.total_vehicles }}</td>
                                                        <td>{{row.inprogress}}</td>
                                                        <td>{{row.cancelled}}</td>
                                                        <td>{{row.completed}}</td>
                                                        <ng-container *ngFor="let response of headers, let ind = index">
                                                            <!-- <td >{{getActionFailPercentage(headers[ind].action_id,row.actions)+"%"}}
                                                            </td> -->
                                                            <td >{{row.action[headers[ind].action_id]+'%'}}
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="open_sec" *ngIf="activeSection == 'checklist'">
                                <div class="page_design">
                                    <div class="view_table">
                                        <div class="filter_sec">
                                            <div class="row">
                                                <div class="col-md-3 coll">
                                                    <label for="my-input">{{"process" | translate}}
                                                    </label>
                                                    <ps-auto-suggestion (onSelected)="selectedProcess($event)"
                                                        [data]="processList" display="processName"
                                                        placeholder="Choose process"
                                                        [preSelected]="processSelected" isMultiSelect="true">
                                                    </ps-auto-suggestion>
                                                </div>
                                                <div class="col-md-3 col1">
                                                    <label>{{'stage:'|translate}}</label>
                                                    <ps-auto-suggestion (onSelected)="selectedStage($event)"
                                                        [data]="stageList" display="stage_name"
                                                        placeholder="Choose Stage" isMultiSelect="true"
                                                        [preSelected]="stageSelected">
                                                    </ps-auto-suggestion>
                                                </div>
                                                <div class="col-md-3 col1">
                                                    <label>{{'checklist'|translate}}</label>
                                                    <ps-auto-suggestion (onSelected)="selectChecklist($event)" [data]="checklistData" display="action_name"
                                                    placeholder="Select Checklist" isMultiSelect="true" >
                                                    </ps-auto-suggestion>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="table_sec" *ngIf="responseData?.length > 0">
                                            <table class="table table-bordered table-striped" #tableToExport>
                                                <thead>
                                                    <tr>
                                                        <th>{{'action_name'|translate}}</th>
                                                        <th>{{'compliance_pass'|translate}}</th>
                                                        <th>{{'compliance_fail'|translate}}</th>
                                                        <th>{{'compliance_pass_count'|translate}}</th>
                                                        <th>{{'compliance_fail_count'|translate}}</th>
                                                        <th>{{'Total Vehicle'|translate}}</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr *ngFor="let row of data;  let i = index">
                                                        <td>{{row.action_name}}</td>
                                                        <td ><span class="status-badge badge-success">{{row.pass_percentage }}</span></td>
                                                        <td ><span class="status-badge badge-danger">{{row.fail_percentage}}</span></td>
                                                        <td>{{row.total_pass}}</td>
                                                        <td>{{row.total_fail}}</td>
                                                        <td>{{row.total_actions}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card">
  <div class="card-header">
    <div class="header-block">
      <h3 class="card-title">
        {{"warranty" | translate}}

      </h3>
    </div>
    <button type="button" class="btn btn-success" data-toggle="modal" data-target="#exampleModal">+ {{"add_new" | translate}}</button>
  </div>

  <div class="card-body">
    <div class="message" *ngIf="assetWarrantyList.length==0  && !common.loading" style="display: flex;text-align: center;font-weight: bold;color: #337ab7;justify-content: space-around;">
      {{"add_warranty_for_asset" | translate}}
    </div>

    <table class="custom-table" *ngIf="assetWarrantyList.length>0">
      <thead class="thead-dark">
        <tr>
          <th>{{ "s.no." | translate }}</th>
          <th>{{"warranty_plan" | translate}}</th>
          <th>{{"warranty_due_date" | translate}}</th>
          <th>{{"deductable_amount" | translate}}</th>
          <th>{{"renewable_amount" | translate}}</th>
          <th>{{"notes" | translate}}</th>
          <th>{{"action" | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of assetWarrantyList; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ row.warranty_plan}}</td>
          <td>{{ row.warranty_due_date | date:'dd-MM-yyyy'}}</td>
          <td>{{ row.deductable_amount}}</td>
          <td>{{ row.renewal_amount}}</td>
          <td>{{ row.notes}}</td>
          <td class="action">
            <div class="btn-block">
              <button class="btn btn-primary" (click)="viewDetails(row)">{{"view" | translate}}</button>
              <button class="btn btn-danger" (click)="confirmAlert(row)">{{"detach" | translate}}</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{"add_warranty" | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="reset()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">

          <div class="form-group col-12 mb-0">
            <label for="my-input" class="required">{{"warranty_plan" | translate}}</label>
            <input class="form-control" type="text" name="warrantyPlan" [(ngModel)]="assetWarranty.warrantyPlan">
            <div class="error" *ngIf="warrantyPlanValidator && warrantyPlanValidator.error">
              <span>{{warrantyPlanValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-12 mb-0">
            <label>{{"warranty_due_date" | translate}}</label>
            <input type="date" [min]="minStartDate" class="form-control" name="dueDate" [(ngModel)]="assetWarranty.dueDate" >
            <div class="error" *ngIf="dueDateValidator && dueDateValidator.error">
              <span>{{dueDateValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-12 mb-0">
            <label for="my-input" class="required">{{"renewable_amount" | translate}}</label>
            <input class="form-control" type="number" step="0.01" min="0" name="renewalAmount" [(ngModel)]="assetWarranty.renewalAmount">
            <div class="error" *ngIf="renewalAmountValidator && renewalAmountValidator.error">
              <span>{{renewalAmountValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-12 mb-0">
            <label for="my-input" class="required">{{"deductable_amount" | translate}}</label>
            <input class="form-control" type="number"  step="0.01" min="0" name="deductableAmount" [(ngModel)]="assetWarranty.deductableAmount">
            <div class="error" *ngIf="deductableAmountValidator && deductableAmountValidator.error">
              <span>{{deductableAmountValidator.msg}} </span>
            </div>
          </div>

          <div class="form-group col-12 mb-0">
            <label for="my-input" >{{ "notes" | translate }}</label>
                  <textarea rows="2" cols="30"  class="form-control" type="text" name="notes" id="notes"
                   [(ngModel)]="assetWarranty.notes" #name="ngModel" placeholder="Enter Notes">
                  </textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="reset()">{{"close" | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="formValidator()">{{"save_warranty" | translate}}</button>
      </div>
    </div>
  </div>
</div>

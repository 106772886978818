import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ComponenttService extends ApiService {
  saveComponent(params: any): Observable<any> {
    let apiSubUrl = "component/add_component";

    return super.post(apiSubUrl, params);
  }

  getComponent(params: any): Observable<any> {
    let apiSubUrl = "component/get_component";

    return super.get(apiSubUrl,params);
  }
}


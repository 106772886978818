<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false,-1,false)">
    <span aria-hidden="true">&times;</span>
  </button>
  <h5 class="modal-title">{{'assign_user' | translate}}</h5>
</div>
<div class="modal-body">

  <div class="shift_div">
    <mat-label>{{'select_stage:'| translate}}</mat-label>
    <ps-auto-suggestion [data]="stagelist" display="stageName" placeholder="Select Stage"
      (onSelected)="selectedStage($event)" name="stageName" [preSelected]="{stageName: selectedStageName}"
      [readonly]="refData.assignedUserId!=null"></ps-auto-suggestion>
    <div class="error" *ngIf="stageValidator && stageValidator.error">
      <span>{{stageValidator.msg}} </span>
    </div>
  </div>
  <div class="user_div">
    <mat-label>{{'select_user:'| translate}}</mat-label>
    <ps-auto-suggestion isMultiSelect="true" (onSelected)="selectedUser($event)" [data]="shiftUsersList"
      display="user_name_with_shift" placeholder="Select User" [preSelected]="">
    </ps-auto-suggestion>
    <div class="error" *ngIf="usersValidator && usersValidator.error">
      <span>{{usersValidator.msg}} </span>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="formValidator();">{{'assign' | translate}}</button>
</div>

import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/@core/services/api.service';
import { CommonService } from 'src/app/@core/services/common.service';
import { validator } from 'src/app/dataType/commonInterface';
import { complianceList } from 'src/app/dataType/complianceInterface';
import { AlertService } from 'src/app/theme/layout/header/alert-message/alert.service';
@Component({
  selector: 'app-add-compliance-modal',
  templateUrl: './add-compliance-modal.component.html',
  styleUrls: ['./add-compliance-modal.component.scss']
})
export class AddComplianceModalComponent implements OnInit {

  @Input() public refData;
  @ViewChild('myInput') myInputVariable: ElementRef;
  complianceError = {
    complianceCategoryId:null,
    refId:null,
    refType: null,
    title: null,
    complianceSeverityId: null,
    processId: null,
    stageId: null,
    txnId: null,
    saveLogFlag:true,
    complianceData: {
      driverName: null,
      transporterName:null,
      vehicleRsn:null,
      remark: null,
      stageName: null,
      processName: null,
      imageFile:null,
      name: null
    }
  }
  complianceList: complianceList[]=[];
  complianceValidator: validator;
  titleValidator: validator;
  remarkValidator: validator;
  fileEvent: any = [];


  constructor(public common: CommonService, public api: ApiService, public alertService: AlertService, public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.getComplianceList();
    this.complianceError.processId = this.refData.processId;
    this.complianceError.stageId = this.refData.stageId;
    this.complianceError.txnId = this.refData.txnId;
    this.complianceError.complianceData.stageName = this.refData.stageName;
    this.complianceError.complianceData.processName = this.refData.processName;
    this.complianceError.complianceData.driverName = this.refData.driverName;
    this.complianceError.complianceData.transporterName = this.refData.transporterName;
    this.complianceError.complianceData.vehicleRsn = this.refData.vehicleRsn;
    this.complianceError.refType= this.refData.refType?this.refData.refType:null;
    this.complianceError.complianceData.name= this.refData.name?this.refData.name: null;
    this.complianceError.refId = this.refData.vehicleId;
  }


  selectCompliance(event,type:string) {
    if(type=='select' && event.id!=null){
      this.complianceError.complianceCategoryId = event.id;
      this.complianceError.title = event.title;
      this.complianceError.complianceSeverityId = event.severity_id;
    }
    else{
      this.complianceError.title = event;
      this.complianceError.complianceCategoryId = null;
      this.complianceError.complianceSeverityId = null;
    }
    console.log("fgyuhjkl0",this.complianceError,event);
  }

  saveComplianceError() {
    this.complianceError.title = this.complianceError.title?this.complianceError.title.trim():null;
    this.complianceError.complianceData.remark = this.complianceError.complianceData.remark?this.complianceError.complianceData.remark.trim():null;

    this.complianceValidator = this.common.nullValidator(
      this.complianceError.title, 'Compliance Title is Mandatory');

    this.remarkValidator=this.common.nullValidator(
      this.complianceError.complianceData.remark, 'Remark is Mandatory'
    )
    console.log("compliancee",this.complianceError)
    if (!this.complianceValidator.error && !this.remarkValidator.error) {
      this.common.loading = true;
      this.api.post('compliance/save-plant-complaince', this.complianceError).subscribe(
        (res: any) => {
          if (res.status) {
            this.alertService.success(res.message);
            this.activeModal.close();
          }else{
            this.alertService.error(res.message);
          }
          this.common.loading = false;
        },
        (err) => {
          this.common.loading = false;
          console.log("Error: " + err);
        }
      );
    }
  }

  getComplianceList() {
    this.common.loading = true;
    this.api.get('compliance/get-complaince-errors').subscribe(
      (res: any) => {
        if (res.status) {
          this.complianceList = res.data;
        }
        this.common.loading = false;
      },
      (err) => {
        this.common.loading = false;
        console.log("Error: " + err);
      }
    );
  }


  onSelect(event: any) {
    let frmData = new FormData();
    for (const file of event.target.files) {
      frmData.append('files', file);
      this.fileEvent.inProgress = true;
      this.uploadFile(frmData);
    }
  }

  uploadFile(frmData: any) {
    this.common.loading = true;
    this.api
      .post('functional/upload-file?methodId=' + 17, frmData)
      .subscribe((res: any) => {
        this.common.loading = false;
        switch (res.data.type) {
          case HttpEventType.UploadProgress:
            this.fileEvent.progress = Math.round(
              (res.data.loaded * 100) / res.data.total
            );
            break;
          case HttpEventType.Response:
            document.querySelector('.file_class').setAttribute('value', '');
            return res.data;
        }
        res.data.forEach((e) => {
          this.complianceError.complianceData.imageFile = e.fileUrl;
        });
      });
    catchError((error: HttpErrorResponse) => {
      this.fileEvent.inProgress = false;
      this.common.loading = false;
      return `${this.fileEvent.data.name} upload failed.`;
    });
    this.myInputVariable.nativeElement.value = '';
  }

  imageName(url: any) {
    let newUrl = decodeURI(url);
    const arrays = newUrl.split('/');
    let val = arrays[arrays.length - 1].split('.');
    return ' .'+val[val.length-1];
  }
}
